import { frameHandler } from "@host";
import { isCLC, isCarDoor } from "./customers";

const REFINANCE_DOMAIN = ["https://refi.carloanscanada.com"].includes(frameHandler.domain);
export const BREEZE_DOMAINS = [
    "https://throttlepowersports.com",
    "https://breezefinancialsolutions.com",
    "https://breezepowersportsfinancing.com",
].includes(frameHandler.domain);

// HELPER TO GENERATE MANY STEPS
export const order = BREEZE_DOMAINS
    ? [
        "vehicle",
        "name",
        "email",
        "date-of-birth",
        "address",
        "phone",
        "eid",
    ].filter(Boolean)
    : REFINANCE_DOMAIN
        ? [
            "vehicle-trade-in",
            "interest-rate",
            "date-of-birth",
            "employment",
            "income",
            "employment-duration",
            "budget",
            "employer",
            "address",
            "address-duration",
            "name",
            "email",
            "phone",
            (isCLC || isCarDoor) ? "phone-verify" : "",
            "eid",
        ].filter(Boolean)
        : [
            "vehicle",
            "date-of-birth",
            "employment",
            "income",
            "employment-duration",
            "budget",
            "employer",
            "address",
            "address-duration",
            "name",
            "email",
            "phone",
            (isCLC || isCarDoor) ? "phone-verify" : "",
            "eid",
        ].filter(Boolean);

export const MAX_QUESTIONS = order.length;

export const getStepNumber = (search: string) => {
    return order.findIndex((v) => search === v) + 1;
};

export const steps = order.reduce((agg, step, i) => ({
    ...agg,
    [step]: { next: order[i + 1], back: !!i },
}), {});