export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  FullText: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};


/** The input of the addAuditLog mutation */
export type AddAuditLogInput = {
  parentType?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  eventType: AuditEventType;
  userId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['JSON']>;
  newValue?: Maybe<Scalars['JSON']>;
  eventTimestamp?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['ID']>;
};

/** The payload of the addAuditLog mutation */
export type AddAuditLogResponse = {
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type AddDistributorRelationshipInput = {
  distributorCompanyId: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type AddDistributorRelationshipResponse = {
  success: Scalars['Boolean'];
  distributorCompanyId: Scalars['Int'];
  companyId: Scalars['Int'];
  companyChannelId: Scalars['Int'];
  distributionId: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
};

export enum AdvancedStatus {
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
  Pending = 'PENDING',
  NotRun = 'NOT_RUN'
}

export type Analytic = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  clientId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  referrerUrl?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `Analytic`. */
  company?: Maybe<Company>;
};

export type AnalyticAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AnalyticSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AnalyticDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AnalyticMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AnalyticMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AnalyticAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AnalyticStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AnalyticStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AnalyticVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AnalyticVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Analytic` object types. */
export type AnalyticAggregatesFilter = {
  /** A filter that must pass for the relevant `Analytic` object to be included within the aggregate. */
  filter?: Maybe<AnalyticFilter>;
  /** Sum aggregate over matching `Analytic` objects. */
  sum?: Maybe<AnalyticSumAggregateFilter>;
  /** Distinct count aggregate over matching `Analytic` objects. */
  distinctCount?: Maybe<AnalyticDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Analytic` objects. */
  min?: Maybe<AnalyticMinAggregateFilter>;
  /** Maximum aggregate over matching `Analytic` objects. */
  max?: Maybe<AnalyticMaxAggregateFilter>;
  /** Mean average aggregate over matching `Analytic` objects. */
  average?: Maybe<AnalyticAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Analytic` objects. */
  stddevSample?: Maybe<AnalyticStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Analytic` objects. */
  stddevPopulation?: Maybe<AnalyticStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Analytic` objects. */
  varianceSample?: Maybe<AnalyticVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Analytic` objects. */
  variancePopulation?: Maybe<AnalyticVariancePopulationAggregateFilter>;
};

/** The fields on `analytic` to look up the row to connect. */
export type AnalyticAnalyticsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `analytic` to look up the row to delete. */
export type AnalyticAnalyticsPkeyDelete = {
  id: Scalars['Int'];
};

export type AnalyticAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type AnalyticAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Analytic` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AnalyticCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `clientId` field. */
  clientId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `requestId` field. */
  requestId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `eventCategory` field. */
  eventCategory?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `eventAction` field. */
  eventAction?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sourceUrl` field. */
  sourceUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `referrerUrl` field. */
  referrerUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userAgent` field. */
  userAgent?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userIp` field. */
  userIp?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `context` field. */
  context?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type AnalyticDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  clientId?: Maybe<BigIntFilter>;
  requestId?: Maybe<BigIntFilter>;
  eventCategory?: Maybe<BigIntFilter>;
  eventAction?: Maybe<BigIntFilter>;
  sourceUrl?: Maybe<BigIntFilter>;
  referrerUrl?: Maybe<BigIntFilter>;
  userAgent?: Maybe<BigIntFilter>;
  userIp?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  context?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type AnalyticDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of clientId across the matching connection */
  clientId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of requestId across the matching connection */
  requestId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventCategory across the matching connection */
  eventCategory?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventAction across the matching connection */
  eventAction?: Maybe<Scalars['BigInt']>;
  /** Distinct count of sourceUrl across the matching connection */
  sourceUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of referrerUrl across the matching connection */
  referrerUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userAgent across the matching connection */
  userAgent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userIp across the matching connection */
  userIp?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of context across the matching connection */
  context?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Analytic` object types. All fields are combined with a logical ‘and.’ */
export type AnalyticFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `clientId` field. */
  clientId?: Maybe<StringFilter>;
  /** Filter by the object’s `requestId` field. */
  requestId?: Maybe<StringFilter>;
  /** Filter by the object’s `eventCategory` field. */
  eventCategory?: Maybe<StringFilter>;
  /** Filter by the object’s `eventAction` field. */
  eventAction?: Maybe<StringFilter>;
  /** Filter by the object’s `sourceUrl` field. */
  sourceUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `referrerUrl` field. */
  referrerUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `userAgent` field. */
  userAgent?: Maybe<StringFilter>;
  /** Filter by the object’s `userIp` field. */
  userIp?: Maybe<StringFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `context` field. */
  context?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AnalyticFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AnalyticFilter>>;
  /** Negates the expression. */
  not?: Maybe<AnalyticFilter>;
};

/** An input for mutations affecting `Analytic` */
export type AnalyticInput = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  referrerUrl?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<AnalyticsCompanyIdFkeyInput>;
};

export type AnalyticMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type AnalyticMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type AnalyticMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type AnalyticMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AnalyticNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `analytic` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AnalyticNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `analytic` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AnalyticOnAnalyticForAnalyticsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `analytic` to look up the row to update. */
export type AnalyticOnAnalyticForAnalyticsCompanyIdFkeyUsingAnalyticsPkeyUpdate = {
  /** An object where the defined keys will be set on the `analytic` being updated. */
  patch: UpdateAnalyticOnAnalyticForAnalyticsCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Analytic`. Fields that are set will be updated. */
export type AnalyticPatch = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  referrerUrl?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<AnalyticsCompanyIdFkeyInput>;
};

export type AnalyticStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type AnalyticStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type AnalyticStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
};

export type AnalyticSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

export type AnalyticVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type AnalyticVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type AnalyticVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** The `analytic` to be created by this mutation. */
export type AnalyticsCompanyIdFkeyAnalyticsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  referrerUrl?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<AnalyticsCompanyIdFkeyInput>;
};

/** The `company` to be created by this mutation. */
export type AnalyticsCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `AnalyticInput` mutation. */
export type AnalyticsCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<AnalyticOnAnalyticForAnalyticsCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<AnalyticsCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `analytic` in the `CompanyInput` mutation. */
export type AnalyticsCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `analytic` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `analytic` for the far side of the relationship. */
  connectById?: Maybe<Array<AnalyticAnalyticsPkeyConnect>>;
  /** The primary key(s) for `analytic` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AnalyticNodeIdConnect>>;
  /** The primary key(s) for `analytic` for the far side of the relationship. */
  deleteById?: Maybe<Array<AnalyticAnalyticsPkeyDelete>>;
  /** The primary key(s) for `analytic` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AnalyticNodeIdDelete>>;
  /** The primary key(s) and patch data for `analytic` for the far side of the relationship. */
  updateById?: Maybe<Array<AnalyticOnAnalyticForAnalyticsCompanyIdFkeyUsingAnalyticsPkeyUpdate>>;
  /** The primary key(s) and patch data for `analytic` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnAnalyticForAnalyticsCompanyIdFkeyNodeIdUpdate>>;
  /** A `AnalyticInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AnalyticsCompanyIdFkeyAnalyticsCreateInput>>;
};

/** A connection to a list of `Analytic` values. */
export type AnalyticsConnection = {
  /** A list of `Analytic` objects. */
  nodes: Array<Analytic>;
  /** A list of edges which contains the `Analytic` and cursor to aid in pagination. */
  edges: Array<AnalyticsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Analytic` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AnalyticAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AnalyticAggregates>>;
};


/** A connection to a list of `Analytic` values. */
export type AnalyticsConnectionGroupedAggregatesArgs = {
  groupBy: Array<AnalyticsGroupBy>;
  having?: Maybe<AnalyticsHavingInput>;
};

export type AnalyticsContext = {
  timestamp?: Maybe<Scalars['String']>;
  /** Custom Dimensions */
  ctaId?: Maybe<Scalars['String']>;
  ctaProduct?: Maybe<Scalars['String']>;
  ctaType?: Maybe<Scalars['String']>;
  ctaVersion?: Maybe<Scalars['String']>;
  ctaTheme?: Maybe<Scalars['String']>;
  /** @deprecated - will be constructed on the backend */
  ctaDetail?: Maybe<Scalars['String']>;
  widgetVersion?: Maybe<Scalars['String']>;
  widgetProduct?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  widgetCtaProduct?: Maybe<Scalars['String']>;
  widgetCtaType?: Maybe<Scalars['String']>;
  widgetCtaVersion?: Maybe<Scalars['String']>;
  widgetCtaTheme?: Maybe<Scalars['String']>;
  /** @deprecated - will be constructed on the backend */
  widgetCtaDetail?: Maybe<Scalars['String']>;
  /** Custom Metrics */
  ctaLoad?: Maybe<Scalars['Int']>;
  ctaOpened?: Maybe<Scalars['Int']>;
  userEngaged?: Maybe<Scalars['Int']>;
};

export type AnalyticsCta = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  eventId: Scalars['Int'];
  ctaProduct?: Maybe<Scalars['String']>;
  ctaType?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type AnalyticsCtaAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AnalyticsCtaSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AnalyticsCtaDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AnalyticsCtaMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AnalyticsCtaMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AnalyticsCtaAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AnalyticsCtaStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AnalyticsCtaStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AnalyticsCtaVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AnalyticsCtaVariancePopulationAggregates>;
};

export type AnalyticsCtaAverageAggregates = {
  /** Mean average of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `AnalyticsCta` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AnalyticsCtaCondition = {
  /** Checks for equality with the object’s `eventId` field. */
  eventId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `ctaProduct` field. */
  ctaProduct?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ctaType` field. */
  ctaType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `eventAction` field. */
  eventAction?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `clientId` field. */
  clientId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type AnalyticsCtaDistinctCountAggregates = {
  /** Distinct count of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaProduct across the matching connection */
  ctaProduct?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaType across the matching connection */
  ctaType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventAction across the matching connection */
  eventAction?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of clientId across the matching connection */
  clientId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `AnalyticsCta` object types. All fields are combined with a logical ‘and.’ */
export type AnalyticsCtaFilter = {
  /** Filter by the object’s `eventId` field. */
  eventId?: Maybe<IntFilter>;
  /** Filter by the object’s `ctaProduct` field. */
  ctaProduct?: Maybe<StringFilter>;
  /** Filter by the object’s `ctaType` field. */
  ctaType?: Maybe<StringFilter>;
  /** Filter by the object’s `eventAction` field. */
  eventAction?: Maybe<StringFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `clientId` field. */
  clientId?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AnalyticsCtaFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AnalyticsCtaFilter>>;
  /** Negates the expression. */
  not?: Maybe<AnalyticsCtaFilter>;
};

/** Grouping methods for `AnalyticsCta` for usage during aggregation. */
export enum AnalyticsCtaGroupBy {
  CtaProduct = 'CTA_PRODUCT',
  CtaType = 'CTA_TYPE',
  EventAction = 'EVENT_ACTION',
  CompanyId = 'COMPANY_ID',
  ClientId = 'CLIENT_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY'
}

export type AnalyticsCtaHavingAverageInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingDistinctCountInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `AnalyticsCta` aggregates. */
export type AnalyticsCtaHavingInput = {
  AND?: Maybe<Array<AnalyticsCtaHavingInput>>;
  OR?: Maybe<Array<AnalyticsCtaHavingInput>>;
  sum?: Maybe<AnalyticsCtaHavingSumInput>;
  distinctCount?: Maybe<AnalyticsCtaHavingDistinctCountInput>;
  min?: Maybe<AnalyticsCtaHavingMinInput>;
  max?: Maybe<AnalyticsCtaHavingMaxInput>;
  average?: Maybe<AnalyticsCtaHavingAverageInput>;
  stddevSample?: Maybe<AnalyticsCtaHavingStddevSampleInput>;
  stddevPopulation?: Maybe<AnalyticsCtaHavingStddevPopulationInput>;
  varianceSample?: Maybe<AnalyticsCtaHavingVarianceSampleInput>;
  variancePopulation?: Maybe<AnalyticsCtaHavingVariancePopulationInput>;
};

export type AnalyticsCtaHavingMaxInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingMinInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingStddevPopulationInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingStddevSampleInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingSumInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingVariancePopulationInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingVarianceSampleInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `AnalyticsCta` */
export type AnalyticsCtaInput = {
  eventId: Scalars['Int'];
  ctaProduct?: Maybe<Scalars['String']>;
  ctaType?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type AnalyticsCtaMaxAggregates = {
  /** Maximum of eventId across the matching connection */
  eventId?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type AnalyticsCtaMinAggregates = {
  /** Minimum of eventId across the matching connection */
  eventId?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `AnalyticsCta`. Fields that are set will be updated. */
export type AnalyticsCtaPatch = {
  eventId?: Maybe<Scalars['Int']>;
  ctaProduct?: Maybe<Scalars['String']>;
  ctaType?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type AnalyticsCtaStddevPopulationAggregates = {
  /** Population standard deviation of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaStddevSampleAggregates = {
  /** Sample standard deviation of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaSumAggregates = {
  /** Sum of eventId across the matching connection */
  eventId: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

/** A connection to a list of `AnalyticsCtaSummary` values. */
export type AnalyticsCtaSummariesConnection = {
  /** A list of `AnalyticsCtaSummary` objects. */
  nodes: Array<AnalyticsCtaSummary>;
  /** A list of edges which contains the `AnalyticsCtaSummary` and cursor to aid in pagination. */
  edges: Array<AnalyticsCtaSummariesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AnalyticsCtaSummary` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AnalyticsCtaSummaryAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AnalyticsCtaSummaryAggregates>>;
};


/** A connection to a list of `AnalyticsCtaSummary` values. */
export type AnalyticsCtaSummariesConnectionGroupedAggregatesArgs = {
  groupBy: Array<AnalyticsCtaSummaryGroupBy>;
  having?: Maybe<AnalyticsCtaSummaryHavingInput>;
};

/** A `AnalyticsCtaSummary` edge in the connection. */
export type AnalyticsCtaSummariesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AnalyticsCtaSummary` at the end of the edge. */
  node: AnalyticsCtaSummary;
};

/** Methods to use when ordering `AnalyticsCtaSummary`. */
export enum AnalyticsCtaSummariesOrderBy {
  Natural = 'NATURAL',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CtaProductAsc = 'CTA_PRODUCT_ASC',
  CtaProductDesc = 'CTA_PRODUCT_DESC',
  CtaTypeAsc = 'CTA_TYPE_ASC',
  CtaTypeDesc = 'CTA_TYPE_DESC',
  HourAsc = 'HOUR_ASC',
  HourDesc = 'HOUR_DESC',
  CtaLoadedAsc = 'CTA_LOADED_ASC',
  CtaLoadedDesc = 'CTA_LOADED_DESC',
  CtaImpressionsAsc = 'CTA_IMPRESSIONS_ASC',
  CtaImpressionsDesc = 'CTA_IMPRESSIONS_DESC',
  WidgetOpenedAsc = 'WIDGET_OPENED_ASC',
  WidgetOpenedDesc = 'WIDGET_OPENED_DESC',
  WidgetClosedAsc = 'WIDGET_CLOSED_ASC',
  WidgetClosedDesc = 'WIDGET_CLOSED_DESC',
  UserEngagementAsc = 'USER_ENGAGEMENT_ASC',
  UserEngagementDesc = 'USER_ENGAGEMENT_DESC',
  PartialLeadsAsc = 'PARTIAL_LEADS_ASC',
  PartialLeadsDesc = 'PARTIAL_LEADS_DESC',
  CreditLeadsAsc = 'CREDIT_LEADS_ASC',
  CreditLeadsDesc = 'CREDIT_LEADS_DESC',
  CreditAppLeadsAsc = 'CREDIT_APP_LEADS_ASC',
  CreditAppLeadsDesc = 'CREDIT_APP_LEADS_DESC',
  CreditVerifiedLeadsAsc = 'CREDIT_VERIFIED_LEADS_ASC',
  CreditVerifiedLeadsDesc = 'CREDIT_VERIFIED_LEADS_DESC',
  TradeLeadsAsc = 'TRADE_LEADS_ASC',
  TradeLeadsDesc = 'TRADE_LEADS_DESC'
}

export type AnalyticsCtaSummary = {
  companyId?: Maybe<Scalars['Int']>;
  ctaProduct?: Maybe<Scalars['String']>;
  ctaType?: Maybe<Scalars['String']>;
  hour?: Maybe<Scalars['Datetime']>;
  ctaLoaded?: Maybe<Scalars['BigInt']>;
  ctaImpressions?: Maybe<Scalars['BigInt']>;
  widgetOpened?: Maybe<Scalars['BigInt']>;
  widgetClosed?: Maybe<Scalars['BigInt']>;
  userEngagement?: Maybe<Scalars['BigInt']>;
  partialLeads?: Maybe<Scalars['BigInt']>;
  creditLeads?: Maybe<Scalars['BigInt']>;
  creditAppLeads?: Maybe<Scalars['BigInt']>;
  creditVerifiedLeads?: Maybe<Scalars['BigInt']>;
  tradeLeads?: Maybe<Scalars['BigInt']>;
};

export type AnalyticsCtaSummaryAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AnalyticsCtaSummarySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AnalyticsCtaSummaryDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AnalyticsCtaSummaryMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AnalyticsCtaSummaryMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AnalyticsCtaSummaryAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AnalyticsCtaSummaryStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AnalyticsCtaSummaryStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AnalyticsCtaSummaryVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AnalyticsCtaSummaryVariancePopulationAggregates>;
};

export type AnalyticsCtaSummaryAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigFloat']>;
  /** Mean average of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigFloat']>;
  /** Mean average of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigFloat']>;
  /** Mean average of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigFloat']>;
  /** Mean average of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigFloat']>;
  /** Mean average of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigFloat']>;
  /** Mean average of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `AnalyticsCtaSummary` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type AnalyticsCtaSummaryCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `ctaProduct` field. */
  ctaProduct?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ctaType` field. */
  ctaType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `hour` field. */
  hour?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `ctaLoaded` field. */
  ctaLoaded?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `ctaImpressions` field. */
  ctaImpressions?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `widgetOpened` field. */
  widgetOpened?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `widgetClosed` field. */
  widgetClosed?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `userEngagement` field. */
  userEngagement?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `partialLeads` field. */
  partialLeads?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `creditLeads` field. */
  creditLeads?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `creditAppLeads` field. */
  creditAppLeads?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `creditVerifiedLeads` field. */
  creditVerifiedLeads?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `tradeLeads` field. */
  tradeLeads?: Maybe<Scalars['BigInt']>;
};

export type AnalyticsCtaSummaryDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaProduct across the matching connection */
  ctaProduct?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaType across the matching connection */
  ctaType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hour across the matching connection */
  hour?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigInt']>;
  /** Distinct count of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `AnalyticsCtaSummary` object types. All fields are combined with a logical ‘and.’ */
export type AnalyticsCtaSummaryFilter = {
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `ctaProduct` field. */
  ctaProduct?: Maybe<StringFilter>;
  /** Filter by the object’s `ctaType` field. */
  ctaType?: Maybe<StringFilter>;
  /** Filter by the object’s `hour` field. */
  hour?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `ctaLoaded` field. */
  ctaLoaded?: Maybe<BigIntFilter>;
  /** Filter by the object’s `ctaImpressions` field. */
  ctaImpressions?: Maybe<BigIntFilter>;
  /** Filter by the object’s `widgetOpened` field. */
  widgetOpened?: Maybe<BigIntFilter>;
  /** Filter by the object’s `widgetClosed` field. */
  widgetClosed?: Maybe<BigIntFilter>;
  /** Filter by the object’s `userEngagement` field. */
  userEngagement?: Maybe<BigIntFilter>;
  /** Filter by the object’s `partialLeads` field. */
  partialLeads?: Maybe<BigIntFilter>;
  /** Filter by the object’s `creditLeads` field. */
  creditLeads?: Maybe<BigIntFilter>;
  /** Filter by the object’s `creditAppLeads` field. */
  creditAppLeads?: Maybe<BigIntFilter>;
  /** Filter by the object’s `creditVerifiedLeads` field. */
  creditVerifiedLeads?: Maybe<BigIntFilter>;
  /** Filter by the object’s `tradeLeads` field. */
  tradeLeads?: Maybe<BigIntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AnalyticsCtaSummaryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AnalyticsCtaSummaryFilter>>;
  /** Negates the expression. */
  not?: Maybe<AnalyticsCtaSummaryFilter>;
};

/** Grouping methods for `AnalyticsCtaSummary` for usage during aggregation. */
export enum AnalyticsCtaSummaryGroupBy {
  CompanyId = 'COMPANY_ID',
  CtaProduct = 'CTA_PRODUCT',
  CtaType = 'CTA_TYPE',
  Hour = 'HOUR',
  HourTruncatedToHour = 'HOUR_TRUNCATED_TO_HOUR',
  HourTruncatedToDay = 'HOUR_TRUNCATED_TO_DAY',
  CtaLoaded = 'CTA_LOADED',
  CtaImpressions = 'CTA_IMPRESSIONS',
  WidgetOpened = 'WIDGET_OPENED',
  WidgetClosed = 'WIDGET_CLOSED',
  UserEngagement = 'USER_ENGAGEMENT',
  PartialLeads = 'PARTIAL_LEADS',
  CreditLeads = 'CREDIT_LEADS',
  CreditAppLeads = 'CREDIT_APP_LEADS',
  CreditVerifiedLeads = 'CREDIT_VERIFIED_LEADS',
  TradeLeads = 'TRADE_LEADS'
}

export type AnalyticsCtaSummaryHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

/** Conditions for `AnalyticsCtaSummary` aggregates. */
export type AnalyticsCtaSummaryHavingInput = {
  AND?: Maybe<Array<AnalyticsCtaSummaryHavingInput>>;
  OR?: Maybe<Array<AnalyticsCtaSummaryHavingInput>>;
  sum?: Maybe<AnalyticsCtaSummaryHavingSumInput>;
  distinctCount?: Maybe<AnalyticsCtaSummaryHavingDistinctCountInput>;
  min?: Maybe<AnalyticsCtaSummaryHavingMinInput>;
  max?: Maybe<AnalyticsCtaSummaryHavingMaxInput>;
  average?: Maybe<AnalyticsCtaSummaryHavingAverageInput>;
  stddevSample?: Maybe<AnalyticsCtaSummaryHavingStddevSampleInput>;
  stddevPopulation?: Maybe<AnalyticsCtaSummaryHavingStddevPopulationInput>;
  varianceSample?: Maybe<AnalyticsCtaSummaryHavingVarianceSampleInput>;
  variancePopulation?: Maybe<AnalyticsCtaSummaryHavingVariancePopulationInput>;
};

export type AnalyticsCtaSummaryHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigInt']>;
  /** Maximum of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigInt']>;
  /** Maximum of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigInt']>;
  /** Maximum of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigInt']>;
  /** Maximum of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigInt']>;
  /** Maximum of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigInt']>;
  /** Maximum of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigInt']>;
  /** Maximum of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigInt']>;
  /** Maximum of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigInt']>;
  /** Maximum of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigInt']>;
};

export type AnalyticsCtaSummaryMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigInt']>;
  /** Minimum of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigInt']>;
  /** Minimum of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigInt']>;
  /** Minimum of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigInt']>;
  /** Minimum of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigInt']>;
  /** Minimum of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigInt']>;
  /** Minimum of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigInt']>;
  /** Minimum of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigInt']>;
  /** Minimum of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigInt']>;
  /** Minimum of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigInt']>;
};

export type AnalyticsCtaSummaryStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaSummaryStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaSummarySumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of ctaLoaded across the matching connection */
  ctaLoaded: Scalars['BigFloat'];
  /** Sum of ctaImpressions across the matching connection */
  ctaImpressions: Scalars['BigFloat'];
  /** Sum of widgetOpened across the matching connection */
  widgetOpened: Scalars['BigFloat'];
  /** Sum of widgetClosed across the matching connection */
  widgetClosed: Scalars['BigFloat'];
  /** Sum of userEngagement across the matching connection */
  userEngagement: Scalars['BigFloat'];
  /** Sum of partialLeads across the matching connection */
  partialLeads: Scalars['BigFloat'];
  /** Sum of creditLeads across the matching connection */
  creditLeads: Scalars['BigFloat'];
  /** Sum of creditAppLeads across the matching connection */
  creditAppLeads: Scalars['BigFloat'];
  /** Sum of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads: Scalars['BigFloat'];
  /** Sum of tradeLeads across the matching connection */
  tradeLeads: Scalars['BigFloat'];
};

export type AnalyticsCtaSummaryVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigFloat']>;
  /** Population variance of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigFloat']>;
  /** Population variance of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigFloat']>;
  /** Population variance of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigFloat']>;
  /** Population variance of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigFloat']>;
  /** Population variance of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigFloat']>;
  /** Population variance of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaSummaryVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaVariancePopulationAggregates = {
  /** Population variance of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaVarianceSampleAggregates = {
  /** Sample variance of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `AnalyticsCta` values. */
export type AnalyticsCtasConnection = {
  /** A list of `AnalyticsCta` objects. */
  nodes: Array<AnalyticsCta>;
  /** A list of edges which contains the `AnalyticsCta` and cursor to aid in pagination. */
  edges: Array<AnalyticsCtasEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AnalyticsCta` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AnalyticsCtaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AnalyticsCtaAggregates>>;
};


/** A connection to a list of `AnalyticsCta` values. */
export type AnalyticsCtasConnectionGroupedAggregatesArgs = {
  groupBy: Array<AnalyticsCtaGroupBy>;
  having?: Maybe<AnalyticsCtaHavingInput>;
};

/** A `AnalyticsCta` edge in the connection. */
export type AnalyticsCtasEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AnalyticsCta` at the end of the edge. */
  node: AnalyticsCta;
};

/** Methods to use when ordering `AnalyticsCta`. */
export enum AnalyticsCtasOrderBy {
  Natural = 'NATURAL',
  EventIdAsc = 'EVENT_ID_ASC',
  EventIdDesc = 'EVENT_ID_DESC',
  CtaProductAsc = 'CTA_PRODUCT_ASC',
  CtaProductDesc = 'CTA_PRODUCT_DESC',
  CtaTypeAsc = 'CTA_TYPE_ASC',
  CtaTypeDesc = 'CTA_TYPE_DESC',
  EventActionAsc = 'EVENT_ACTION_ASC',
  EventActionDesc = 'EVENT_ACTION_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A `Analytic` edge in the connection. */
export type AnalyticsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Analytic` at the end of the edge. */
  node: Analytic;
};

export enum AnalyticsEventAction {
  PageView = 'pageView',
  CtaLoaded = 'ctaLoaded',
  CtaImpression = 'ctaImpression',
  WidgetOpened = 'widgetOpened',
  WidgetClosed = 'widgetClosed',
  UserEngaged = 'userEngaged',
  CreditReportReceived = 'creditReportReceived',
  PartialLeadSubmit = 'partialLeadSubmit',
  CreditToolSubmit = 'creditToolSubmit',
  CreditLiteToolSubmit = 'creditLiteToolSubmit',
  TradeToolSubmit = 'tradeToolSubmit',
  CreditAppSubmit = 'creditAppSubmit',
  LenderToolSubmit = 'lenderToolSubmit',
  LenderToolOfferSelect = 'lenderToolOfferSelect'
}

export type AnalyticsEventResponse = {
  success: Scalars['Boolean'];
  eventAction: Scalars['String'];
  eventCategory: Scalars['String'];
  /** @deprecated Goes into the itesm in the analyticsPayload array */
  eventType?: Maybe<Scalars['String']>;
  analyticsPayload?: Maybe<Array<Scalars['JSON']>>;
};

/** Grouping methods for `Analytic` for usage during aggregation. */
export enum AnalyticsGroupBy {
  ClientId = 'CLIENT_ID',
  RequestId = 'REQUEST_ID',
  EventCategory = 'EVENT_CATEGORY',
  EventAction = 'EVENT_ACTION',
  SourceUrl = 'SOURCE_URL',
  ReferrerUrl = 'REFERRER_URL',
  UserAgent = 'USER_AGENT',
  UserIp = 'USER_IP',
  CompanyId = 'COMPANY_ID',
  Context = 'CONTEXT',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type AnalyticsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Analytic` aggregates. */
export type AnalyticsHavingInput = {
  AND?: Maybe<Array<AnalyticsHavingInput>>;
  OR?: Maybe<Array<AnalyticsHavingInput>>;
  sum?: Maybe<AnalyticsHavingSumInput>;
  distinctCount?: Maybe<AnalyticsHavingDistinctCountInput>;
  min?: Maybe<AnalyticsHavingMinInput>;
  max?: Maybe<AnalyticsHavingMaxInput>;
  average?: Maybe<AnalyticsHavingAverageInput>;
  stddevSample?: Maybe<AnalyticsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<AnalyticsHavingStddevPopulationInput>;
  varianceSample?: Maybe<AnalyticsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<AnalyticsHavingVariancePopulationInput>;
};

export type AnalyticsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Analytic`. */
export enum AnalyticsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  RequestIdAsc = 'REQUEST_ID_ASC',
  RequestIdDesc = 'REQUEST_ID_DESC',
  EventCategoryAsc = 'EVENT_CATEGORY_ASC',
  EventCategoryDesc = 'EVENT_CATEGORY_DESC',
  EventActionAsc = 'EVENT_ACTION_ASC',
  EventActionDesc = 'EVENT_ACTION_DESC',
  SourceUrlAsc = 'SOURCE_URL_ASC',
  SourceUrlDesc = 'SOURCE_URL_DESC',
  ReferrerUrlAsc = 'REFERRER_URL_ASC',
  ReferrerUrlDesc = 'REFERRER_URL_DESC',
  UserAgentAsc = 'USER_AGENT_ASC',
  UserAgentDesc = 'USER_AGENT_DESC',
  UserIpAsc = 'USER_IP_ASC',
  UserIpDesc = 'USER_IP_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ContextAsc = 'CONTEXT_ASC',
  ContextDesc = 'CONTEXT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type AnalyticsParamsEvents = {
  eventAction: AnalyticsEventAction;
  context?: Maybe<AnalyticsContext>;
  internalCall?: Maybe<Scalars['Boolean']>;
  timeStamp?: Maybe<Scalars['Datetime']>;
};

/** @deprecated */
export type AnalyticsParamsInput = {
  eventAction: AnalyticsEventAction;
  widgetId: Scalars['String'];
  sourceUrl: Scalars['String'];
  referrer: Scalars['String'];
  internalCall?: Maybe<Scalars['Boolean']>;
  context?: Maybe<AnalyticsContext>;
  sessionId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
};

export type AnalyticsParamsInput2 = {
  widgetId: Scalars['String'];
  sourceUrl: Scalars['String'];
  referrer: Scalars['String'];
};

export type AnalyticsResponse = {
  success: Scalars['Boolean'];
  events: Array<AnalyticsEventResponse>;
};

export enum AnalyticsType {
  GoogleUa = 'GOOGLE_UA',
  GoogleA4 = 'GOOGLE_A4',
  MetaPixel = 'META_PIXEL'
}

/** A filter to be used against AnalyticsType fields. All fields are combined with a logical ‘and.’ */
export type AnalyticsTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<AnalyticsType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<AnalyticsType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<AnalyticsType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<AnalyticsType>;
  /** Included in the specified list. */
  in?: Maybe<Array<AnalyticsType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<AnalyticsType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<AnalyticsType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<AnalyticsType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<AnalyticsType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<AnalyticsType>;
};

export type Appointment = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt: Scalars['Datetime'];
  confirmedAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `Appointment`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `Appointment`. */
  vehicleListing?: Maybe<VehicleListing>;
};

export type AppointmentAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AppointmentSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AppointmentDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AppointmentMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AppointmentMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AppointmentAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AppointmentStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AppointmentStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AppointmentVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AppointmentVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Appointment` object types. */
export type AppointmentAggregatesFilter = {
  /** A filter that must pass for the relevant `Appointment` object to be included within the aggregate. */
  filter?: Maybe<AppointmentFilter>;
  /** Sum aggregate over matching `Appointment` objects. */
  sum?: Maybe<AppointmentSumAggregateFilter>;
  /** Distinct count aggregate over matching `Appointment` objects. */
  distinctCount?: Maybe<AppointmentDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Appointment` objects. */
  min?: Maybe<AppointmentMinAggregateFilter>;
  /** Maximum aggregate over matching `Appointment` objects. */
  max?: Maybe<AppointmentMaxAggregateFilter>;
  /** Mean average aggregate over matching `Appointment` objects. */
  average?: Maybe<AppointmentAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Appointment` objects. */
  stddevSample?: Maybe<AppointmentStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Appointment` objects. */
  stddevPopulation?: Maybe<AppointmentStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Appointment` objects. */
  varianceSample?: Maybe<AppointmentVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Appointment` objects. */
  variancePopulation?: Maybe<AppointmentVariancePopulationAggregateFilter>;
};

/** The fields on `appointment` to look up the row to connect. */
export type AppointmentAppointmentsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `appointment` to look up the row to delete. */
export type AppointmentAppointmentsPkeyDelete = {
  id: Scalars['Int'];
};

export type AppointmentAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type AppointmentAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Appointment` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AppointmentCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `vehicleListingId` field. */
  vehicleListingId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `additionalInformation` field. */
  additionalInformation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `appointmentAt` field. */
  appointmentAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `confirmedAt` field. */
  confirmedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type AppointmentDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  vehicleListingId?: Maybe<BigIntFilter>;
  additionalInformation?: Maybe<BigIntFilter>;
  appointmentAt?: Maybe<BigIntFilter>;
  confirmedAt?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type AppointmentDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of additionalInformation across the matching connection */
  additionalInformation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of appointmentAt across the matching connection */
  appointmentAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of confirmedAt across the matching connection */
  confirmedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Appointment` object types. All fields are combined with a logical ‘and.’ */
export type AppointmentFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `vehicleListingId` field. */
  vehicleListingId?: Maybe<IntFilter>;
  /** Filter by the object’s `additionalInformation` field. */
  additionalInformation?: Maybe<StringFilter>;
  /** Filter by the object’s `appointmentAt` field. */
  appointmentAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `confirmedAt` field. */
  confirmedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Filter by the object’s `vehicleListing` relation. */
  vehicleListing?: Maybe<VehicleListingFilter>;
  /** A related `vehicleListing` exists. */
  vehicleListingExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AppointmentFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AppointmentFilter>>;
  /** Negates the expression. */
  not?: Maybe<AppointmentFilter>;
};

/** Grouping methods for `Appointment` for usage during aggregation. */
export enum AppointmentGroupBy {
  LeadId = 'LEAD_ID',
  VehicleListingId = 'VEHICLE_LISTING_ID',
  AdditionalInformation = 'ADDITIONAL_INFORMATION',
  AppointmentAt = 'APPOINTMENT_AT',
  AppointmentAtTruncatedToHour = 'APPOINTMENT_AT_TRUNCATED_TO_HOUR',
  AppointmentAtTruncatedToDay = 'APPOINTMENT_AT_TRUNCATED_TO_DAY',
  ConfirmedAt = 'CONFIRMED_AT',
  ConfirmedAtTruncatedToHour = 'CONFIRMED_AT_TRUNCATED_TO_HOUR',
  ConfirmedAtTruncatedToDay = 'CONFIRMED_AT_TRUNCATED_TO_DAY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type AppointmentHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Appointment` aggregates. */
export type AppointmentHavingInput = {
  AND?: Maybe<Array<AppointmentHavingInput>>;
  OR?: Maybe<Array<AppointmentHavingInput>>;
  sum?: Maybe<AppointmentHavingSumInput>;
  distinctCount?: Maybe<AppointmentHavingDistinctCountInput>;
  min?: Maybe<AppointmentHavingMinInput>;
  max?: Maybe<AppointmentHavingMaxInput>;
  average?: Maybe<AppointmentHavingAverageInput>;
  stddevSample?: Maybe<AppointmentHavingStddevSampleInput>;
  stddevPopulation?: Maybe<AppointmentHavingStddevPopulationInput>;
  varianceSample?: Maybe<AppointmentHavingVarianceSampleInput>;
  variancePopulation?: Maybe<AppointmentHavingVariancePopulationInput>;
};

export type AppointmentHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Appointment` */
export type AppointmentInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

export type AppointmentMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  vehicleListingId?: Maybe<IntFilter>;
};

export type AppointmentMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['Int']>;
};

export type AppointmentMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  vehicleListingId?: Maybe<IntFilter>;
};

export type AppointmentMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AppointmentNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `appointment` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AppointmentNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `appointment` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppointmentOnAppointmentForAppointmentsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `appointment` to look up the row to update. */
export type AppointmentOnAppointmentForAppointmentsLeadIdFkeyUsingAppointmentsPkeyUpdate = {
  /** An object where the defined keys will be set on the `appointment` being updated. */
  patch: UpdateAppointmentOnAppointmentForAppointmentsLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `vehicleListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `vehicleListing` being updated. */
  patch: VehicleListingPatch;
};

/** The fields on `appointment` to look up the row to update. */
export type AppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyUsingAppointmentsPkeyUpdate = {
  /** An object where the defined keys will be set on the `appointment` being updated. */
  patch: UpdateAppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Appointment`. Fields that are set will be updated. */
export type AppointmentPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

export type AppointmentStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type AppointmentStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type AppointmentStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type AppointmentStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type AppointmentSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  vehicleListingId?: Maybe<BigIntFilter>;
};

export type AppointmentSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of vehicleListingId across the matching connection */
  vehicleListingId: Scalars['BigInt'];
};

export type AppointmentVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type AppointmentVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type AppointmentVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type AppointmentVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Appointment` values. */
export type AppointmentsConnection = {
  /** A list of `Appointment` objects. */
  nodes: Array<Appointment>;
  /** A list of edges which contains the `Appointment` and cursor to aid in pagination. */
  edges: Array<AppointmentsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Appointment` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AppointmentAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AppointmentAggregates>>;
};


/** A connection to a list of `Appointment` values. */
export type AppointmentsConnectionGroupedAggregatesArgs = {
  groupBy: Array<AppointmentGroupBy>;
  having?: Maybe<AppointmentHavingInput>;
};

/** A `Appointment` edge in the connection. */
export type AppointmentsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Appointment` at the end of the edge. */
  node: Appointment;
};

/** The `appointment` to be created by this mutation. */
export type AppointmentsLeadIdFkeyAppointmentCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `AppointmentInput` mutation. */
export type AppointmentsLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnAppointmentForAppointmentsLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppointmentOnAppointmentForAppointmentsLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<AppointmentsLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `appointment` in the `LeadInput` mutation. */
export type AppointmentsLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `appointment` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  connectById?: Maybe<Array<AppointmentAppointmentsPkeyConnect>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppointmentNodeIdConnect>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppointmentAppointmentsPkeyDelete>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppointmentNodeIdDelete>>;
  /** The primary key(s) and patch data for `appointment` for the far side of the relationship. */
  updateById?: Maybe<Array<AppointmentOnAppointmentForAppointmentsLeadIdFkeyUsingAppointmentsPkeyUpdate>>;
  /** The primary key(s) and patch data for `appointment` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnAppointmentForAppointmentsLeadIdFkeyNodeIdUpdate>>;
  /** A `AppointmentInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppointmentsLeadIdFkeyAppointmentCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type AppointmentsLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** Methods to use when ordering `Appointment`. */
export enum AppointmentsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  VehicleListingIdAsc = 'VEHICLE_LISTING_ID_ASC',
  VehicleListingIdDesc = 'VEHICLE_LISTING_ID_DESC',
  AdditionalInformationAsc = 'ADDITIONAL_INFORMATION_ASC',
  AdditionalInformationDesc = 'ADDITIONAL_INFORMATION_DESC',
  AppointmentAtAsc = 'APPOINTMENT_AT_ASC',
  AppointmentAtDesc = 'APPOINTMENT_AT_DESC',
  ConfirmedAtAsc = 'CONFIRMED_AT_ASC',
  ConfirmedAtDesc = 'CONFIRMED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `appointment` to be created by this mutation. */
export type AppointmentsVehicleListingsIdFkeyAppointmentCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

/** Input for the nested mutation of `vehicleListing` in the `AppointmentInput` mutation. */
export type AppointmentsVehicleListingsIdFkeyInput = {
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  connectById?: Maybe<VehicleListingVehicleListingsPkeyConnect>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<VehicleListingNodeIdConnect>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  deleteById?: Maybe<VehicleListingVehicleListingsPkeyDelete>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<VehicleListingNodeIdDelete>;
  /** The primary key(s) and patch data for `vehicleListing` for the far side of the relationship. */
  updateById?: Maybe<VehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyUsingVehicleListingsPkeyUpdate>;
  /** The primary key(s) and patch data for `vehicleListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyNodeIdUpdate>;
  /** A `VehicleListingInput` object that will be created and connected to this object. */
  create?: Maybe<AppointmentsVehicleListingsIdFkeyVehicleListingCreateInput>;
};

/** Input for the nested mutation of `appointment` in the `VehicleListingInput` mutation. */
export type AppointmentsVehicleListingsIdFkeyInverseInput = {
  /** Flag indicating whether all other `appointment` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  connectById?: Maybe<Array<AppointmentAppointmentsPkeyConnect>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppointmentNodeIdConnect>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppointmentAppointmentsPkeyDelete>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppointmentNodeIdDelete>>;
  /** The primary key(s) and patch data for `appointment` for the far side of the relationship. */
  updateById?: Maybe<Array<AppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyUsingAppointmentsPkeyUpdate>>;
  /** The primary key(s) and patch data for `appointment` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<VehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyNodeIdUpdate>>;
  /** A `AppointmentInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppointmentsVehicleListingsIdFkeyAppointmentCreateInput>>;
};

/** The `vehicleListing` to be created by this mutation. */
export type AppointmentsVehicleListingsIdFkeyVehicleListingCreateInput = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

export type ArchiveCompanyInput = {
  companyId: Scalars['Int'];
  archive: Scalars['Boolean'];
};

export type ArchiveCompanyResponse = {
  companyId: Scalars['Int'];
  archived: Scalars['Boolean'];
  usersModified: Array<UserDetails>;
};

export type AssessmentComplete = {
  passed?: Maybe<Scalars['Boolean']>;
  reasons?: Maybe<Array<Maybe<AssessmentCompleteReasons>>>;
};

export type AssessmentCompleteReasons = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum AuditEventType {
  Insert = 'INSERT',
  Update = 'UPDATE',
  Delete = 'DELETE',
  View = 'VIEW',
  LeadExport = 'LEAD_EXPORT'
}

/** A filter to be used against AuditEventType fields. All fields are combined with a logical ‘and.’ */
export type AuditEventTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<AuditEventType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<AuditEventType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<AuditEventType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<AuditEventType>;
  /** Included in the specified list. */
  in?: Maybe<Array<AuditEventType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<AuditEventType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<AuditEventType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<AuditEventType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<AuditEventType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<AuditEventType>;
};

export type AuditLog = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  eventId: Scalars['Int'];
  parentType?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  eventType?: Maybe<AuditEventType>;
  dbRole?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['JSON']>;
  newValue?: Maybe<Scalars['JSON']>;
  eventTimestamp?: Maybe<Scalars['Datetime']>;
  leadId?: Maybe<Scalars['UUID']>;
  /** Reads a single `User` that is related to this `AuditLog`. */
  user?: Maybe<User>;
  /** Reads a single `Lead` that is related to this `AuditLog`. */
  lead?: Maybe<Lead>;
};

export type AuditLogAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AuditLogSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuditLogDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AuditLogMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AuditLogMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AuditLogAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AuditLogStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AuditLogStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AuditLogVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AuditLogVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `AuditLog` object types. */
export type AuditLogAggregatesFilter = {
  /** A filter that must pass for the relevant `AuditLog` object to be included within the aggregate. */
  filter?: Maybe<AuditLogFilter>;
  /** Sum aggregate over matching `AuditLog` objects. */
  sum?: Maybe<AuditLogSumAggregateFilter>;
  /** Distinct count aggregate over matching `AuditLog` objects. */
  distinctCount?: Maybe<AuditLogDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `AuditLog` objects. */
  min?: Maybe<AuditLogMinAggregateFilter>;
  /** Maximum aggregate over matching `AuditLog` objects. */
  max?: Maybe<AuditLogMaxAggregateFilter>;
  /** Mean average aggregate over matching `AuditLog` objects. */
  average?: Maybe<AuditLogAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `AuditLog` objects. */
  stddevSample?: Maybe<AuditLogStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `AuditLog` objects. */
  stddevPopulation?: Maybe<AuditLogStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `AuditLog` objects. */
  varianceSample?: Maybe<AuditLogVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `AuditLog` objects. */
  variancePopulation?: Maybe<AuditLogVariancePopulationAggregateFilter>;
};

/** The fields on `auditLog` to look up the row to connect. */
export type AuditLogAuditLogPkeyConnect = {
  eventId: Scalars['Int'];
};

export type AuditLogAverageAggregateFilter = {
  eventId?: Maybe<BigFloatFilter>;
  parentId?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type AuditLogAverageAggregates = {
  /** Mean average of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `AuditLog` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AuditLogCondition = {
  /** Checks for equality with the object’s `eventId` field. */
  eventId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `parentType` field. */
  parentType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `eventType` field. */
  eventType?: Maybe<AuditEventType>;
  /** Checks for equality with the object’s `dbRole` field. */
  dbRole?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `email` field. */
  email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `oldValue` field. */
  oldValue?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `newValue` field. */
  newValue?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `eventTimestamp` field. */
  eventTimestamp?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
};

export type AuditLogDistinctCountAggregateFilter = {
  eventId?: Maybe<BigIntFilter>;
  parentType?: Maybe<BigIntFilter>;
  parentId?: Maybe<BigIntFilter>;
  eventType?: Maybe<BigIntFilter>;
  dbRole?: Maybe<BigIntFilter>;
  userId?: Maybe<BigIntFilter>;
  email?: Maybe<BigIntFilter>;
  oldValue?: Maybe<BigIntFilter>;
  newValue?: Maybe<BigIntFilter>;
  eventTimestamp?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
};

export type AuditLogDistinctCountAggregates = {
  /** Distinct count of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parentType across the matching connection */
  parentType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventType across the matching connection */
  eventType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dbRole across the matching connection */
  dbRole?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']>;
  /** Distinct count of oldValue across the matching connection */
  oldValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of newValue across the matching connection */
  newValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventTimestamp across the matching connection */
  eventTimestamp?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `AuditLog` object types. All fields are combined with a logical ‘and.’ */
export type AuditLogFilter = {
  /** Filter by the object’s `eventId` field. */
  eventId?: Maybe<IntFilter>;
  /** Filter by the object’s `parentType` field. */
  parentType?: Maybe<StringFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: Maybe<IntFilter>;
  /** Filter by the object’s `eventType` field. */
  eventType?: Maybe<AuditEventTypeFilter>;
  /** Filter by the object’s `dbRole` field. */
  dbRole?: Maybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<IntFilter>;
  /** Filter by the object’s `email` field. */
  email?: Maybe<StringFilter>;
  /** Filter by the object’s `oldValue` field. */
  oldValue?: Maybe<JsonFilter>;
  /** Filter by the object’s `newValue` field. */
  newValue?: Maybe<JsonFilter>;
  /** Filter by the object’s `eventTimestamp` field. */
  eventTimestamp?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `user` relation. */
  user?: Maybe<UserFilter>;
  /** A related `user` exists. */
  userExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** A related `lead` exists. */
  leadExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AuditLogFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AuditLogFilter>>;
  /** Negates the expression. */
  not?: Maybe<AuditLogFilter>;
};

/** Grouping methods for `AuditLog` for usage during aggregation. */
export enum AuditLogGroupBy {
  ParentType = 'PARENT_TYPE',
  ParentId = 'PARENT_ID',
  EventType = 'EVENT_TYPE',
  DbRole = 'DB_ROLE',
  UserId = 'USER_ID',
  Email = 'EMAIL',
  OldValue = 'OLD_VALUE',
  NewValue = 'NEW_VALUE',
  EventTimestamp = 'EVENT_TIMESTAMP',
  EventTimestampTruncatedToHour = 'EVENT_TIMESTAMP_TRUNCATED_TO_HOUR',
  EventTimestampTruncatedToDay = 'EVENT_TIMESTAMP_TRUNCATED_TO_DAY',
  LeadId = 'LEAD_ID'
}

export type AuditLogHavingAverageInput = {
  eventId?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  eventTimestamp?: Maybe<HavingDatetimeFilter>;
};

export type AuditLogHavingDistinctCountInput = {
  eventId?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  eventTimestamp?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `AuditLog` aggregates. */
export type AuditLogHavingInput = {
  AND?: Maybe<Array<AuditLogHavingInput>>;
  OR?: Maybe<Array<AuditLogHavingInput>>;
  sum?: Maybe<AuditLogHavingSumInput>;
  distinctCount?: Maybe<AuditLogHavingDistinctCountInput>;
  min?: Maybe<AuditLogHavingMinInput>;
  max?: Maybe<AuditLogHavingMaxInput>;
  average?: Maybe<AuditLogHavingAverageInput>;
  stddevSample?: Maybe<AuditLogHavingStddevSampleInput>;
  stddevPopulation?: Maybe<AuditLogHavingStddevPopulationInput>;
  varianceSample?: Maybe<AuditLogHavingVarianceSampleInput>;
  variancePopulation?: Maybe<AuditLogHavingVariancePopulationInput>;
};

export type AuditLogHavingMaxInput = {
  eventId?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  eventTimestamp?: Maybe<HavingDatetimeFilter>;
};

export type AuditLogHavingMinInput = {
  eventId?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  eventTimestamp?: Maybe<HavingDatetimeFilter>;
};

export type AuditLogHavingStddevPopulationInput = {
  eventId?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  eventTimestamp?: Maybe<HavingDatetimeFilter>;
};

export type AuditLogHavingStddevSampleInput = {
  eventId?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  eventTimestamp?: Maybe<HavingDatetimeFilter>;
};

export type AuditLogHavingSumInput = {
  eventId?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  eventTimestamp?: Maybe<HavingDatetimeFilter>;
};

export type AuditLogHavingVariancePopulationInput = {
  eventId?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  eventTimestamp?: Maybe<HavingDatetimeFilter>;
};

export type AuditLogHavingVarianceSampleInput = {
  eventId?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  eventTimestamp?: Maybe<HavingDatetimeFilter>;
};

/** Input for the nested mutation of `lead` in the `AuditLogInput` mutation. */
export type AuditLogLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnAuditLogForAuditLogLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<AuditLogOnAuditLogForAuditLogLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<AuditLogLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `auditLog` in the `LeadInput` mutation. */
export type AuditLogLeadIdFkeyInverseInput = {
  /** The primary key(s) for `auditLog` for the far side of the relationship. */
  connectByEventId?: Maybe<Array<AuditLogAuditLogPkeyConnect>>;
  /** The primary key(s) for `auditLog` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AuditLogNodeIdConnect>>;
  /** The primary key(s) and patch data for `auditLog` for the far side of the relationship. */
  updateByEventId?: Maybe<Array<AuditLogOnAuditLogForAuditLogLeadIdFkeyUsingAuditLogPkeyUpdate>>;
  /** The primary key(s) and patch data for `auditLog` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnAuditLogForAuditLogLeadIdFkeyNodeIdUpdate>>;
};

/** The `lead` to be created by this mutation. */
export type AuditLogLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export type AuditLogMaxAggregateFilter = {
  eventId?: Maybe<IntFilter>;
  parentId?: Maybe<IntFilter>;
  userId?: Maybe<IntFilter>;
};

export type AuditLogMaxAggregates = {
  /** Maximum of eventId across the matching connection */
  eventId?: Maybe<Scalars['Int']>;
  /** Maximum of parentId across the matching connection */
  parentId?: Maybe<Scalars['Int']>;
  /** Maximum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
};

export type AuditLogMinAggregateFilter = {
  eventId?: Maybe<IntFilter>;
  parentId?: Maybe<IntFilter>;
  userId?: Maybe<IntFilter>;
};

export type AuditLogMinAggregates = {
  /** Minimum of eventId across the matching connection */
  eventId?: Maybe<Scalars['Int']>;
  /** Minimum of parentId across the matching connection */
  parentId?: Maybe<Scalars['Int']>;
  /** Minimum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AuditLogNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `auditLog` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AuditLogOnAuditLogForAuditLogLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `auditLog` to look up the row to update. */
export type AuditLogOnAuditLogForAuditLogLeadIdFkeyUsingAuditLogPkeyUpdate = {
  /** An object where the defined keys will be set on the `auditLog` being updated. */
  patch: UpdateAuditLogOnAuditLogForAuditLogLeadIdFkeyPatch;
  eventId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type AuditLogOnAuditLogForAuditLogUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `auditLog` to look up the row to update. */
export type AuditLogOnAuditLogForAuditLogUserIdFkeyUsingAuditLogPkeyUpdate = {
  /** An object where the defined keys will be set on the `auditLog` being updated. */
  patch: UpdateAuditLogOnAuditLogForAuditLogUserIdFkeyPatch;
  eventId: Scalars['Int'];
};

/** Represents an update to a `AuditLog`. Fields that are set will be updated. */
export type AuditLogPatch = {
  eventId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  eventType?: Maybe<AuditEventType>;
  dbRole?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['JSON']>;
  newValue?: Maybe<Scalars['JSON']>;
  eventTimestamp?: Maybe<Scalars['Datetime']>;
  leadId?: Maybe<Scalars['UUID']>;
  userToUserId?: Maybe<AuditLogUserIdFkeyInput>;
  leadToLeadId?: Maybe<AuditLogLeadIdFkeyInput>;
};

export type AuditLogStddevPopulationAggregateFilter = {
  eventId?: Maybe<BigFloatFilter>;
  parentId?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type AuditLogStddevPopulationAggregates = {
  /** Population standard deviation of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type AuditLogStddevSampleAggregateFilter = {
  eventId?: Maybe<BigFloatFilter>;
  parentId?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type AuditLogStddevSampleAggregates = {
  /** Sample standard deviation of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type AuditLogSumAggregateFilter = {
  eventId?: Maybe<BigIntFilter>;
  parentId?: Maybe<BigIntFilter>;
  userId?: Maybe<BigIntFilter>;
};

export type AuditLogSumAggregates = {
  /** Sum of eventId across the matching connection */
  eventId: Scalars['BigInt'];
  /** Sum of parentId across the matching connection */
  parentId: Scalars['BigInt'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt'];
};

/** Input for the nested mutation of `user` in the `AuditLogInput` mutation. */
export type AuditLogUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnAuditLogForAuditLogUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<AuditLogOnAuditLogForAuditLogUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditLog` in the `UserInput` mutation. */
export type AuditLogUserIdFkeyInverseInput = {
  /** The primary key(s) for `auditLog` for the far side of the relationship. */
  connectByEventId?: Maybe<Array<AuditLogAuditLogPkeyConnect>>;
  /** The primary key(s) for `auditLog` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AuditLogNodeIdConnect>>;
  /** The primary key(s) and patch data for `auditLog` for the far side of the relationship. */
  updateByEventId?: Maybe<Array<AuditLogOnAuditLogForAuditLogUserIdFkeyUsingAuditLogPkeyUpdate>>;
  /** The primary key(s) and patch data for `auditLog` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnAuditLogForAuditLogUserIdFkeyNodeIdUpdate>>;
};

export type AuditLogVariancePopulationAggregateFilter = {
  eventId?: Maybe<BigFloatFilter>;
  parentId?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type AuditLogVariancePopulationAggregates = {
  /** Population variance of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type AuditLogVarianceSampleAggregateFilter = {
  eventId?: Maybe<BigFloatFilter>;
  parentId?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type AuditLogVarianceSampleAggregates = {
  /** Sample variance of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `AuditLog` values. */
export type AuditLogsConnection = {
  /** A list of `AuditLog` objects. */
  nodes: Array<AuditLog>;
  /** A list of edges which contains the `AuditLog` and cursor to aid in pagination. */
  edges: Array<AuditLogsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditLog` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuditLogAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuditLogAggregates>>;
};


/** A connection to a list of `AuditLog` values. */
export type AuditLogsConnectionGroupedAggregatesArgs = {
  groupBy: Array<AuditLogGroupBy>;
  having?: Maybe<AuditLogHavingInput>;
};

/** A `AuditLog` edge in the connection. */
export type AuditLogsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AuditLog` at the end of the edge. */
  node: AuditLog;
};

/** Methods to use when ordering `AuditLog`. */
export enum AuditLogsOrderBy {
  Natural = 'NATURAL',
  EventIdAsc = 'EVENT_ID_ASC',
  EventIdDesc = 'EVENT_ID_DESC',
  ParentTypeAsc = 'PARENT_TYPE_ASC',
  ParentTypeDesc = 'PARENT_TYPE_DESC',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  EventTypeAsc = 'EVENT_TYPE_ASC',
  EventTypeDesc = 'EVENT_TYPE_DESC',
  DbRoleAsc = 'DB_ROLE_ASC',
  DbRoleDesc = 'DB_ROLE_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  OldValueAsc = 'OLD_VALUE_ASC',
  OldValueDesc = 'OLD_VALUE_DESC',
  NewValueAsc = 'NEW_VALUE_ASC',
  NewValueDesc = 'NEW_VALUE_DESC',
  EventTimestampAsc = 'EVENT_TIMESTAMP_ASC',
  EventTimestampDesc = 'EVENT_TIMESTAMP_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The auth challenge details */
export type AuthChallenge = {
  session?: Maybe<Scalars['String']>;
  challengeName: AuthChallengeType;
  challengeParameters?: Maybe<Array<Maybe<AuthChallengeParameter>>>;
};

/** Auth challenge parameters to use in your challenge response */
export type AuthChallengeParameter = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AuthChallengeResponse = {
  NEW_PASSWORD_REQUIRED?: Maybe<AuthNewPasswordRequired>;
  SMS_MFA?: Maybe<AuthSmsMfa>;
  PASSWORD_VERIFIER?: Maybe<AuthPasswordVerifier>;
  SOFTWARE_TOKEN_MFA?: Maybe<AuthSoftwareTokenMfa>;
  DEVICE_SRP_AUTH?: Maybe<AuthDeviceSrpAuth>;
  DEVICE_PASSWORD_VERIFIER?: Maybe<AuthDevicePasswordVerifier>;
  MFA_SETUP?: Maybe<AuthMfaSetup>;
};

/** The name of the challenge which you are responding to with this call. */
export enum AuthChallengeType {
  SmaMfa = 'SMA_MFA',
  SoftwareTokenMfa = 'SOFTWARE_TOKEN_MFA',
  SelectMfaType = 'SELECT_MFA_TYPE',
  AdminNoSrpAuth = 'ADMIN_NO_SRP_AUTH',
  PasswordVerifier = 'PASSWORD_VERIFIER',
  CustomChallenge = 'CUSTOM_CHALLENGE',
  DeviceSrpAuth = 'DEVICE_SRP_AUTH',
  DevicePasswordVerifier = 'DEVICE_PASSWORD_VERIFIER',
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  MfaSetup = 'MFA_SETUP',
  NotSupported = 'NOT_SUPPORTED'
}

export type AuthDevicePasswordVerifier = {
  USERNAME: Scalars['String'];
  PASSWORD_CLAIM_SIGNATURE: Scalars['String'];
  PASSWORD_CLAIM_SECRET_BLOCK: Scalars['String'];
  TIMESTAMP: Scalars['String'];
  DEVICE_KEY: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AuthDeviceSrpAuth = {
  USERNAME: Scalars['String'];
  SRP_A: Scalars['String'];
  DEVICE_KEY: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

/** Auth error */
export type AuthError = {
  code?: Maybe<AuthErrorCode>;
  statusCode?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export enum AuthErrorCode {
  InternalErrorException = 'InternalErrorException',
  InvalidLambdaResponseException = 'InvalidLambdaResponseException',
  InvalidParameterException = 'InvalidParameterException',
  InvalidSmsRoleAccessPolicyException = 'InvalidSmsRoleAccessPolicyException',
  InvalidSmsRoleTrustRelationshipException = 'InvalidSmsRoleTrustRelationshipException',
  InvalidUserPoolConfigurationException = 'InvalidUserPoolConfigurationException',
  NotAuthorizedException = 'NotAuthorizedException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  ResourceNotFoundException = 'ResourceNotFoundException',
  TooManyRequestsException = 'TooManyRequestsException',
  UnexpectedLambdaException = 'UnexpectedLambdaException',
  UserLambdaValidationException = 'UserLambdaValidationException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  UserNotFoundException = 'UserNotFoundException'
}

export type AuthForgotPasswordResult = {
  success: Scalars['Boolean'];
  destination?: Maybe<Scalars['String']>;
  deliveryMedium?: Maybe<Scalars['String']>;
  attributeName?: Maybe<Scalars['String']>;
};

export type AuthMfaSetup = {
  USERNAME: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AuthNewDeviceMeta = {
  deviceKey?: Maybe<Scalars['String']>;
  deviceGroupKey?: Maybe<Scalars['String']>;
};

export type AuthNewPasswordRequired = {
  USERNAME: Scalars['String'];
  NEW_PASSWORD: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AuthPasswordVerifier = {
  USERNAME: Scalars['String'];
  PASSWORD_CLAIM_SIGNATURE: Scalars['String'];
  PASSWORD_CLAIM_SECRET_BLOCK: Scalars['String'];
  TIMESTAMP: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AuthPayload = AuthError | AuthResult;

/** Authentication results */
export type AuthResult = {
  /** Refresh token. */
  refreshToken?: Maybe<Scalars['String']>;
  /** Access token. */
  accessToken?: Maybe<Scalars['String']>;
  /** ID token. */
  idToken?: Maybe<Scalars['String']>;
  /** Token type. */
  tokenType?: Maybe<Scalars['String']>;
  /** Expiration. */
  expiresIn?: Maybe<Scalars['Int']>;
  /** The new device metadata from an authentication result */
  newDeviceMetadata?: Maybe<AuthNewDeviceMeta>;
  /** Challenge name type */
  challenge?: Maybe<AuthChallenge>;
};

export type AuthSignOutResult = {
  success: Scalars['Boolean'];
};

export type AuthSignUpInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AuthSmsMfa = {
  USERNAME: Scalars['String'];
  SMS_MFA_CODE: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AuthSoftwareTokenMfa = {
  USERNAME: Scalars['String'];
  SOFTWARE_TOKEN_MFA_CODE: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AutoBasicInfo = {
  styleId?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['String']>;
  invoice?: Maybe<Scalars['String']>;
  delivery?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  styleName?: Maybe<Scalars['String']>;
  trimName?: Maybe<Scalars['String']>;
  trimSubStyleName?: Maybe<Scalars['String']>;
  makeName?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  modelYear?: Maybe<Scalars['String']>;
  marketClass?: Maybe<Scalars['String']>;
  bodyType?: Maybe<Scalars['String']>;
  driveTrain?: Maybe<Scalars['String']>;
};

/** Vehicle configuration information */
export type AutoConfiguration = {
  coreVehicleData?: Maybe<AutoBasicInfo>;
  standardFeatures?: Maybe<Array<Maybe<AutoStdEquipment>>>;
  optionalFeatures?: Maybe<Array<Maybe<AutoOptions>>>;
  genericFeatures?: Maybe<Array<Maybe<AutoFeatureData>>>;
  techSpecs?: Maybe<Array<Maybe<AutoFeatureData>>>;
  warranty?: Maybe<Array<Maybe<AutoFeatureData>>>;
  bodyTypeHints?: Maybe<BodyTypeHints>;
  numberOfSeats?: Maybe<Scalars['String']>;
  numberOfDoors?: Maybe<Scalars['String']>;
  fuelType?: Maybe<Scalars['String']>;
  styleGroupIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AutoFeatureData = {
  featureId?: Maybe<Scalars['String']>;
  featureName?: Maybe<Scalars['String']>;
  headerName?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  measurementUnit?: Maybe<Scalars['String']>;
};

export type AutoImageWithMetaData = {
  href?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  shotCode?: Maybe<Scalars['String']>;
};

export type AutoImages = {
  whiteBg?: Maybe<Array<Maybe<AutoImageWithMetaData>>>;
  transparentBg?: Maybe<Array<Maybe<AutoImageWithMetaData>>>;
};

export type AutoOptions = {
  headerId?: Maybe<Scalars['String']>;
  headerName?: Maybe<Scalars['String']>;
  featureName?: Maybe<Scalars['String']>;
  selectionState?: Maybe<Scalars['String']>;
};

export type AutoStdEquipment = {
  headerId?: Maybe<Scalars['String']>;
  headerName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featureName?: Maybe<Scalars['String']>;
  upgrade?: Maybe<Scalars['String']>;
};

export enum AutocorpPermissions {
  None = 'NONE',
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN'
}

export type AvaApp = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Datetime'];
  creditRange?: Maybe<Scalars['String']>;
  /** Reads a single `Lead` that is related to this `AvaApp`. */
  lead?: Maybe<Lead>;
  /** Reads and enables pagination through a set of `CreditBureau`. */
  creditBureaus: CreditBureausConnection;
};


export type AvaAppCreditBureausArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CreditBureausOrderBy>>;
  condition?: Maybe<CreditBureauCondition>;
  filter?: Maybe<CreditBureauFilter>;
};

export type AvaAppAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AvaAppSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AvaAppDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AvaAppMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AvaAppMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AvaAppAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AvaAppStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AvaAppStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AvaAppVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AvaAppVariancePopulationAggregates>;
};

/** The fields on `avaApp` to look up the row to connect. */
export type AvaAppAvaAppLeadIdKeyConnect = {
  leadId: Scalars['UUID'];
};

/** The fields on `avaApp` to look up the row to connect. */
export type AvaAppAvaAppPkeyConnect = {
  id: Scalars['Int'];
};

export type AvaAppAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `AvaApp` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AvaAppCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `creditVerified` field. */
  creditVerified?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `dealerCreditConsent` field. */
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `creditScore` field. */
  creditScore?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `creditRange` field. */
  creditRange?: Maybe<Scalars['String']>;
};

export type AvaAppDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditVerified across the matching connection */
  creditVerified?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dealerCreditConsent across the matching connection */
  dealerCreditConsent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditRange across the matching connection */
  creditRange?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `AvaApp` object types. All fields are combined with a logical ‘and.’ */
export type AvaAppFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `creditVerified` field. */
  creditVerified?: Maybe<BooleanFilter>;
  /** Filter by the object’s `dealerCreditConsent` field. */
  dealerCreditConsent?: Maybe<BooleanFilter>;
  /** Filter by the object’s `creditScore` field. */
  creditScore?: Maybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `creditRange` field. */
  creditRange?: Maybe<StringFilter>;
  /** Filter by the object’s `creditBureaus` relation. */
  creditBureaus?: Maybe<AvaAppToManyCreditBureauFilter>;
  /** Some related `creditBureaus` exist. */
  creditBureausExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** A related `lead` exists. */
  leadExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AvaAppFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AvaAppFilter>>;
  /** Negates the expression. */
  not?: Maybe<AvaAppFilter>;
};

/** Grouping methods for `AvaApp` for usage during aggregation. */
export enum AvaAppGroupBy {
  CreditVerified = 'CREDIT_VERIFIED',
  DealerCreditConsent = 'DEALER_CREDIT_CONSENT',
  CreditScore = 'CREDIT_SCORE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  CreditRange = 'CREDIT_RANGE'
}

export type AvaAppHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `AvaApp` aggregates. */
export type AvaAppHavingInput = {
  AND?: Maybe<Array<AvaAppHavingInput>>;
  OR?: Maybe<Array<AvaAppHavingInput>>;
  sum?: Maybe<AvaAppHavingSumInput>;
  distinctCount?: Maybe<AvaAppHavingDistinctCountInput>;
  min?: Maybe<AvaAppHavingMinInput>;
  max?: Maybe<AvaAppHavingMaxInput>;
  average?: Maybe<AvaAppHavingAverageInput>;
  stddevSample?: Maybe<AvaAppHavingStddevSampleInput>;
  stddevPopulation?: Maybe<AvaAppHavingStddevPopulationInput>;
  varianceSample?: Maybe<AvaAppHavingVarianceSampleInput>;
  variancePopulation?: Maybe<AvaAppHavingVariancePopulationInput>;
};

export type AvaAppHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `AvaApp` */
export type AvaAppInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

/** The `avaApp` to be created by this mutation. */
export type AvaAppLeadIdFkeyAvaAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `lead` in the `AvaAppInput` mutation. */
export type AvaAppLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnAvaAppForAvaAppLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<AvaAppOnAvaAppForAvaAppLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<AvaAppLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `avaApp` in the `LeadInput` mutation. */
export type AvaAppLeadIdFkeyInverseInput = {
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectById?: Maybe<AvaAppAvaAppPkeyConnect>;
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectByLeadId?: Maybe<AvaAppAvaAppLeadIdKeyConnect>;
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<AvaAppNodeIdConnect>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateById?: Maybe<AvaAppOnAvaAppForAvaAppLeadIdFkeyUsingAvaAppPkeyUpdate>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateByLeadId?: Maybe<AvaAppOnAvaAppForAvaAppLeadIdFkeyUsingAvaAppLeadIdKeyUpdate>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnAvaAppForAvaAppLeadIdFkeyNodeIdUpdate>;
  /** A `AvaAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AvaAppLeadIdFkeyAvaAppCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type AvaAppLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export type AvaAppMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['Int']>;
};

export type AvaAppMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AvaAppNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `avaApp` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AvaAppOnAvaAppForAvaAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `avaApp` to look up the row to update. */
export type AvaAppOnAvaAppForAvaAppLeadIdFkeyUsingAvaAppLeadIdKeyUpdate = {
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: UpdateAvaAppOnAvaAppForAvaAppLeadIdFkeyPatch;
  leadId: Scalars['UUID'];
};

/** The fields on `avaApp` to look up the row to update. */
export type AvaAppOnAvaAppForAvaAppLeadIdFkeyUsingAvaAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: UpdateAvaAppOnAvaAppForAvaAppLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `creditBureau` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `creditBureau` being updated. */
  patch: CreditBureauPatch;
};

/** The fields on `avaApp` to look up the row to update. */
export type AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyUsingAvaAppLeadIdKeyUpdate = {
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: UpdateAvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyPatch;
  leadId: Scalars['UUID'];
};

/** The fields on `avaApp` to look up the row to update. */
export type AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyUsingAvaAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: UpdateAvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `AvaApp`. Fields that are set will be updated. */
export type AvaAppPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

export type AvaAppStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type AvaAppStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type AvaAppSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of creditScore across the matching connection */
  creditScore: Scalars['BigInt'];
};

/** A filter to be used against many `CreditBureau` object types. All fields are combined with a logical ‘and.’ */
export type AvaAppToManyCreditBureauFilter = {
  /** Every related `CreditBureau` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CreditBureauFilter>;
  /** Some related `CreditBureau` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CreditBureauFilter>;
  /** No related `CreditBureau` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CreditBureauFilter>;
  /** Aggregates across related `CreditBureau` match the filter criteria. */
  aggregates?: Maybe<CreditBureauAggregatesFilter>;
};

export type AvaAppVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type AvaAppVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `AvaApp` values. */
export type AvaAppsConnection = {
  /** A list of `AvaApp` objects. */
  nodes: Array<AvaApp>;
  /** A list of edges which contains the `AvaApp` and cursor to aid in pagination. */
  edges: Array<AvaAppsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AvaApp` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AvaAppAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AvaAppAggregates>>;
};


/** A connection to a list of `AvaApp` values. */
export type AvaAppsConnectionGroupedAggregatesArgs = {
  groupBy: Array<AvaAppGroupBy>;
  having?: Maybe<AvaAppHavingInput>;
};

/** A `AvaApp` edge in the connection. */
export type AvaAppsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AvaApp` at the end of the edge. */
  node: AvaApp;
};

/** Methods to use when ordering `AvaApp`. */
export enum AvaAppsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  CreditVerifiedAsc = 'CREDIT_VERIFIED_ASC',
  CreditVerifiedDesc = 'CREDIT_VERIFIED_DESC',
  DealerCreditConsentAsc = 'DEALER_CREDIT_CONSENT_ASC',
  DealerCreditConsentDesc = 'DEALER_CREDIT_CONSENT_DESC',
  CreditScoreAsc = 'CREDIT_SCORE_ASC',
  CreditScoreDesc = 'CREDIT_SCORE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  CreditRangeAsc = 'CREDIT_RANGE_ASC',
  CreditRangeDesc = 'CREDIT_RANGE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CreditBureausCountAsc = 'CREDIT_BUREAUS_COUNT_ASC',
  CreditBureausCountDesc = 'CREDIT_BUREAUS_COUNT_DESC',
  CreditBureausSumIdAsc = 'CREDIT_BUREAUS_SUM_ID_ASC',
  CreditBureausSumIdDesc = 'CREDIT_BUREAUS_SUM_ID_DESC',
  CreditBureausSumAvaAppIdAsc = 'CREDIT_BUREAUS_SUM_AVA_APP_ID_ASC',
  CreditBureausSumAvaAppIdDesc = 'CREDIT_BUREAUS_SUM_AVA_APP_ID_DESC',
  CreditBureausSumTypeAsc = 'CREDIT_BUREAUS_SUM_TYPE_ASC',
  CreditBureausSumTypeDesc = 'CREDIT_BUREAUS_SUM_TYPE_DESC',
  CreditBureausSumCreatedAtAsc = 'CREDIT_BUREAUS_SUM_CREATED_AT_ASC',
  CreditBureausSumCreatedAtDesc = 'CREDIT_BUREAUS_SUM_CREATED_AT_DESC',
  CreditBureausSumCreditScoreAsc = 'CREDIT_BUREAUS_SUM_CREDIT_SCORE_ASC',
  CreditBureausSumCreditScoreDesc = 'CREDIT_BUREAUS_SUM_CREDIT_SCORE_DESC',
  CreditBureausSumRawBureauAsc = 'CREDIT_BUREAUS_SUM_RAW_BUREAU_ASC',
  CreditBureausSumRawBureauDesc = 'CREDIT_BUREAUS_SUM_RAW_BUREAU_DESC',
  CreditBureausSumUpdatedAtAsc = 'CREDIT_BUREAUS_SUM_UPDATED_AT_ASC',
  CreditBureausSumUpdatedAtDesc = 'CREDIT_BUREAUS_SUM_UPDATED_AT_DESC',
  CreditBureausSumRawExpiredAsc = 'CREDIT_BUREAUS_SUM_RAW_EXPIRED_ASC',
  CreditBureausSumRawExpiredDesc = 'CREDIT_BUREAUS_SUM_RAW_EXPIRED_DESC',
  CreditBureausSumXmlAsc = 'CREDIT_BUREAUS_SUM_XML_ASC',
  CreditBureausSumXmlDesc = 'CREDIT_BUREAUS_SUM_XML_DESC',
  CreditBureausSumUploadedAsc = 'CREDIT_BUREAUS_SUM_UPLOADED_ASC',
  CreditBureausSumUploadedDesc = 'CREDIT_BUREAUS_SUM_UPLOADED_DESC',
  CreditBureausDistinctCountIdAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_ID_ASC',
  CreditBureausDistinctCountIdDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_ID_DESC',
  CreditBureausDistinctCountAvaAppIdAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_AVA_APP_ID_ASC',
  CreditBureausDistinctCountAvaAppIdDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_AVA_APP_ID_DESC',
  CreditBureausDistinctCountTypeAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_TYPE_ASC',
  CreditBureausDistinctCountTypeDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_TYPE_DESC',
  CreditBureausDistinctCountCreatedAtAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_CREATED_AT_ASC',
  CreditBureausDistinctCountCreatedAtDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_CREATED_AT_DESC',
  CreditBureausDistinctCountCreditScoreAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_CREDIT_SCORE_ASC',
  CreditBureausDistinctCountCreditScoreDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_CREDIT_SCORE_DESC',
  CreditBureausDistinctCountRawBureauAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_RAW_BUREAU_ASC',
  CreditBureausDistinctCountRawBureauDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_RAW_BUREAU_DESC',
  CreditBureausDistinctCountUpdatedAtAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CreditBureausDistinctCountUpdatedAtDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CreditBureausDistinctCountRawExpiredAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_RAW_EXPIRED_ASC',
  CreditBureausDistinctCountRawExpiredDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_RAW_EXPIRED_DESC',
  CreditBureausDistinctCountXmlAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_XML_ASC',
  CreditBureausDistinctCountXmlDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_XML_DESC',
  CreditBureausDistinctCountUploadedAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_UPLOADED_ASC',
  CreditBureausDistinctCountUploadedDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_UPLOADED_DESC',
  CreditBureausMinIdAsc = 'CREDIT_BUREAUS_MIN_ID_ASC',
  CreditBureausMinIdDesc = 'CREDIT_BUREAUS_MIN_ID_DESC',
  CreditBureausMinAvaAppIdAsc = 'CREDIT_BUREAUS_MIN_AVA_APP_ID_ASC',
  CreditBureausMinAvaAppIdDesc = 'CREDIT_BUREAUS_MIN_AVA_APP_ID_DESC',
  CreditBureausMinTypeAsc = 'CREDIT_BUREAUS_MIN_TYPE_ASC',
  CreditBureausMinTypeDesc = 'CREDIT_BUREAUS_MIN_TYPE_DESC',
  CreditBureausMinCreatedAtAsc = 'CREDIT_BUREAUS_MIN_CREATED_AT_ASC',
  CreditBureausMinCreatedAtDesc = 'CREDIT_BUREAUS_MIN_CREATED_AT_DESC',
  CreditBureausMinCreditScoreAsc = 'CREDIT_BUREAUS_MIN_CREDIT_SCORE_ASC',
  CreditBureausMinCreditScoreDesc = 'CREDIT_BUREAUS_MIN_CREDIT_SCORE_DESC',
  CreditBureausMinRawBureauAsc = 'CREDIT_BUREAUS_MIN_RAW_BUREAU_ASC',
  CreditBureausMinRawBureauDesc = 'CREDIT_BUREAUS_MIN_RAW_BUREAU_DESC',
  CreditBureausMinUpdatedAtAsc = 'CREDIT_BUREAUS_MIN_UPDATED_AT_ASC',
  CreditBureausMinUpdatedAtDesc = 'CREDIT_BUREAUS_MIN_UPDATED_AT_DESC',
  CreditBureausMinRawExpiredAsc = 'CREDIT_BUREAUS_MIN_RAW_EXPIRED_ASC',
  CreditBureausMinRawExpiredDesc = 'CREDIT_BUREAUS_MIN_RAW_EXPIRED_DESC',
  CreditBureausMinXmlAsc = 'CREDIT_BUREAUS_MIN_XML_ASC',
  CreditBureausMinXmlDesc = 'CREDIT_BUREAUS_MIN_XML_DESC',
  CreditBureausMinUploadedAsc = 'CREDIT_BUREAUS_MIN_UPLOADED_ASC',
  CreditBureausMinUploadedDesc = 'CREDIT_BUREAUS_MIN_UPLOADED_DESC',
  CreditBureausMaxIdAsc = 'CREDIT_BUREAUS_MAX_ID_ASC',
  CreditBureausMaxIdDesc = 'CREDIT_BUREAUS_MAX_ID_DESC',
  CreditBureausMaxAvaAppIdAsc = 'CREDIT_BUREAUS_MAX_AVA_APP_ID_ASC',
  CreditBureausMaxAvaAppIdDesc = 'CREDIT_BUREAUS_MAX_AVA_APP_ID_DESC',
  CreditBureausMaxTypeAsc = 'CREDIT_BUREAUS_MAX_TYPE_ASC',
  CreditBureausMaxTypeDesc = 'CREDIT_BUREAUS_MAX_TYPE_DESC',
  CreditBureausMaxCreatedAtAsc = 'CREDIT_BUREAUS_MAX_CREATED_AT_ASC',
  CreditBureausMaxCreatedAtDesc = 'CREDIT_BUREAUS_MAX_CREATED_AT_DESC',
  CreditBureausMaxCreditScoreAsc = 'CREDIT_BUREAUS_MAX_CREDIT_SCORE_ASC',
  CreditBureausMaxCreditScoreDesc = 'CREDIT_BUREAUS_MAX_CREDIT_SCORE_DESC',
  CreditBureausMaxRawBureauAsc = 'CREDIT_BUREAUS_MAX_RAW_BUREAU_ASC',
  CreditBureausMaxRawBureauDesc = 'CREDIT_BUREAUS_MAX_RAW_BUREAU_DESC',
  CreditBureausMaxUpdatedAtAsc = 'CREDIT_BUREAUS_MAX_UPDATED_AT_ASC',
  CreditBureausMaxUpdatedAtDesc = 'CREDIT_BUREAUS_MAX_UPDATED_AT_DESC',
  CreditBureausMaxRawExpiredAsc = 'CREDIT_BUREAUS_MAX_RAW_EXPIRED_ASC',
  CreditBureausMaxRawExpiredDesc = 'CREDIT_BUREAUS_MAX_RAW_EXPIRED_DESC',
  CreditBureausMaxXmlAsc = 'CREDIT_BUREAUS_MAX_XML_ASC',
  CreditBureausMaxXmlDesc = 'CREDIT_BUREAUS_MAX_XML_DESC',
  CreditBureausMaxUploadedAsc = 'CREDIT_BUREAUS_MAX_UPLOADED_ASC',
  CreditBureausMaxUploadedDesc = 'CREDIT_BUREAUS_MAX_UPLOADED_DESC',
  CreditBureausAverageIdAsc = 'CREDIT_BUREAUS_AVERAGE_ID_ASC',
  CreditBureausAverageIdDesc = 'CREDIT_BUREAUS_AVERAGE_ID_DESC',
  CreditBureausAverageAvaAppIdAsc = 'CREDIT_BUREAUS_AVERAGE_AVA_APP_ID_ASC',
  CreditBureausAverageAvaAppIdDesc = 'CREDIT_BUREAUS_AVERAGE_AVA_APP_ID_DESC',
  CreditBureausAverageTypeAsc = 'CREDIT_BUREAUS_AVERAGE_TYPE_ASC',
  CreditBureausAverageTypeDesc = 'CREDIT_BUREAUS_AVERAGE_TYPE_DESC',
  CreditBureausAverageCreatedAtAsc = 'CREDIT_BUREAUS_AVERAGE_CREATED_AT_ASC',
  CreditBureausAverageCreatedAtDesc = 'CREDIT_BUREAUS_AVERAGE_CREATED_AT_DESC',
  CreditBureausAverageCreditScoreAsc = 'CREDIT_BUREAUS_AVERAGE_CREDIT_SCORE_ASC',
  CreditBureausAverageCreditScoreDesc = 'CREDIT_BUREAUS_AVERAGE_CREDIT_SCORE_DESC',
  CreditBureausAverageRawBureauAsc = 'CREDIT_BUREAUS_AVERAGE_RAW_BUREAU_ASC',
  CreditBureausAverageRawBureauDesc = 'CREDIT_BUREAUS_AVERAGE_RAW_BUREAU_DESC',
  CreditBureausAverageUpdatedAtAsc = 'CREDIT_BUREAUS_AVERAGE_UPDATED_AT_ASC',
  CreditBureausAverageUpdatedAtDesc = 'CREDIT_BUREAUS_AVERAGE_UPDATED_AT_DESC',
  CreditBureausAverageRawExpiredAsc = 'CREDIT_BUREAUS_AVERAGE_RAW_EXPIRED_ASC',
  CreditBureausAverageRawExpiredDesc = 'CREDIT_BUREAUS_AVERAGE_RAW_EXPIRED_DESC',
  CreditBureausAverageXmlAsc = 'CREDIT_BUREAUS_AVERAGE_XML_ASC',
  CreditBureausAverageXmlDesc = 'CREDIT_BUREAUS_AVERAGE_XML_DESC',
  CreditBureausAverageUploadedAsc = 'CREDIT_BUREAUS_AVERAGE_UPLOADED_ASC',
  CreditBureausAverageUploadedDesc = 'CREDIT_BUREAUS_AVERAGE_UPLOADED_DESC',
  CreditBureausStddevSampleIdAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_ID_ASC',
  CreditBureausStddevSampleIdDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_ID_DESC',
  CreditBureausStddevSampleAvaAppIdAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_AVA_APP_ID_ASC',
  CreditBureausStddevSampleAvaAppIdDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_AVA_APP_ID_DESC',
  CreditBureausStddevSampleTypeAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_TYPE_ASC',
  CreditBureausStddevSampleTypeDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_TYPE_DESC',
  CreditBureausStddevSampleCreatedAtAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CreditBureausStddevSampleCreatedAtDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CreditBureausStddevSampleCreditScoreAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_CREDIT_SCORE_ASC',
  CreditBureausStddevSampleCreditScoreDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_CREDIT_SCORE_DESC',
  CreditBureausStddevSampleRawBureauAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_RAW_BUREAU_ASC',
  CreditBureausStddevSampleRawBureauDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_RAW_BUREAU_DESC',
  CreditBureausStddevSampleUpdatedAtAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CreditBureausStddevSampleUpdatedAtDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CreditBureausStddevSampleRawExpiredAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_RAW_EXPIRED_ASC',
  CreditBureausStddevSampleRawExpiredDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_RAW_EXPIRED_DESC',
  CreditBureausStddevSampleXmlAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_XML_ASC',
  CreditBureausStddevSampleXmlDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_XML_DESC',
  CreditBureausStddevSampleUploadedAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_UPLOADED_ASC',
  CreditBureausStddevSampleUploadedDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_UPLOADED_DESC',
  CreditBureausStddevPopulationIdAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_ID_ASC',
  CreditBureausStddevPopulationIdDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_ID_DESC',
  CreditBureausStddevPopulationAvaAppIdAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_AVA_APP_ID_ASC',
  CreditBureausStddevPopulationAvaAppIdDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_AVA_APP_ID_DESC',
  CreditBureausStddevPopulationTypeAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_TYPE_ASC',
  CreditBureausStddevPopulationTypeDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_TYPE_DESC',
  CreditBureausStddevPopulationCreatedAtAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_CREATED_AT_ASC',
  CreditBureausStddevPopulationCreatedAtDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_CREATED_AT_DESC',
  CreditBureausStddevPopulationCreditScoreAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_CREDIT_SCORE_ASC',
  CreditBureausStddevPopulationCreditScoreDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_CREDIT_SCORE_DESC',
  CreditBureausStddevPopulationRawBureauAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_RAW_BUREAU_ASC',
  CreditBureausStddevPopulationRawBureauDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_RAW_BUREAU_DESC',
  CreditBureausStddevPopulationUpdatedAtAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CreditBureausStddevPopulationUpdatedAtDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CreditBureausStddevPopulationRawExpiredAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_RAW_EXPIRED_ASC',
  CreditBureausStddevPopulationRawExpiredDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_RAW_EXPIRED_DESC',
  CreditBureausStddevPopulationXmlAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_XML_ASC',
  CreditBureausStddevPopulationXmlDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_XML_DESC',
  CreditBureausStddevPopulationUploadedAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_UPLOADED_ASC',
  CreditBureausStddevPopulationUploadedDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_UPLOADED_DESC',
  CreditBureausVarianceSampleIdAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_ID_ASC',
  CreditBureausVarianceSampleIdDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_ID_DESC',
  CreditBureausVarianceSampleAvaAppIdAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_AVA_APP_ID_ASC',
  CreditBureausVarianceSampleAvaAppIdDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_AVA_APP_ID_DESC',
  CreditBureausVarianceSampleTypeAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_TYPE_ASC',
  CreditBureausVarianceSampleTypeDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_TYPE_DESC',
  CreditBureausVarianceSampleCreatedAtAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CreditBureausVarianceSampleCreatedAtDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CreditBureausVarianceSampleCreditScoreAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_CREDIT_SCORE_ASC',
  CreditBureausVarianceSampleCreditScoreDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_CREDIT_SCORE_DESC',
  CreditBureausVarianceSampleRawBureauAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_RAW_BUREAU_ASC',
  CreditBureausVarianceSampleRawBureauDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_RAW_BUREAU_DESC',
  CreditBureausVarianceSampleUpdatedAtAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CreditBureausVarianceSampleUpdatedAtDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CreditBureausVarianceSampleRawExpiredAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_RAW_EXPIRED_ASC',
  CreditBureausVarianceSampleRawExpiredDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_RAW_EXPIRED_DESC',
  CreditBureausVarianceSampleXmlAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_XML_ASC',
  CreditBureausVarianceSampleXmlDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_XML_DESC',
  CreditBureausVarianceSampleUploadedAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_UPLOADED_ASC',
  CreditBureausVarianceSampleUploadedDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_UPLOADED_DESC',
  CreditBureausVariancePopulationIdAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_ID_ASC',
  CreditBureausVariancePopulationIdDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_ID_DESC',
  CreditBureausVariancePopulationAvaAppIdAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_AVA_APP_ID_ASC',
  CreditBureausVariancePopulationAvaAppIdDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_AVA_APP_ID_DESC',
  CreditBureausVariancePopulationTypeAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_TYPE_ASC',
  CreditBureausVariancePopulationTypeDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_TYPE_DESC',
  CreditBureausVariancePopulationCreatedAtAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CreditBureausVariancePopulationCreatedAtDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CreditBureausVariancePopulationCreditScoreAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_CREDIT_SCORE_ASC',
  CreditBureausVariancePopulationCreditScoreDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_CREDIT_SCORE_DESC',
  CreditBureausVariancePopulationRawBureauAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_RAW_BUREAU_ASC',
  CreditBureausVariancePopulationRawBureauDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_RAW_BUREAU_DESC',
  CreditBureausVariancePopulationUpdatedAtAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CreditBureausVariancePopulationUpdatedAtDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CreditBureausVariancePopulationRawExpiredAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_RAW_EXPIRED_ASC',
  CreditBureausVariancePopulationRawExpiredDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_RAW_EXPIRED_DESC',
  CreditBureausVariancePopulationXmlAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_XML_ASC',
  CreditBureausVariancePopulationXmlDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_XML_DESC',
  CreditBureausVariancePopulationUploadedAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_UPLOADED_ASC',
  CreditBureausVariancePopulationUploadedDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_UPLOADED_DESC'
}

export type Bankruptcy = {
  dateFiled?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  assignedDate?: Maybe<Scalars['String']>;
  court?: Maybe<IdName>;
  caseNumberAndTrustee?: Maybe<Scalars['String']>;
  type?: Maybe<CodeDescription>;
  filer?: Maybe<CodeDescription>;
  intentOrDisposition?: Maybe<CodeDescription>;
  liabilityAmount?: Maybe<Scalars['String']>;
  assetAmount?: Maybe<Scalars['String']>;
  updateSource?: Maybe<CodeDescription>;
};

export type BasicCompanyDetails = {
  id: Scalars['Int'];
  name: Scalars['String'];
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

export type BasicDeliveryMethodDetails = {
  value?: Maybe<Scalars['String']>;
  type?: Maybe<DeliveryMethodType>;
};

export type BasicProductDetails = {
  displayName: Scalars['String'];
  name: Scalars['String'];
};


/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['BigFloat']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['BigFloat']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['BigFloat']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['BigFloat']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['BigFloat']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
};


/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['BigInt']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['BigInt']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['BigInt']>;
};

export type BillableMetric = {
  aggregationType?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BillableMetricLog = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  data: Scalars['JSON'];
  sentAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type BillableMetricLogAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<BillableMetricLogSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BillableMetricLogDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<BillableMetricLogMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<BillableMetricLogMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<BillableMetricLogAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<BillableMetricLogStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<BillableMetricLogStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<BillableMetricLogVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<BillableMetricLogVariancePopulationAggregates>;
};

export type BillableMetricLogAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `BillableMetricLog` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BillableMetricLogCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `data` field. */
  data?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `sentAt` field. */
  sentAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type BillableMetricLogDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of data across the matching connection */
  data?: Maybe<Scalars['BigInt']>;
  /** Distinct count of sentAt across the matching connection */
  sentAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `BillableMetricLog` object types. All fields are combined with a logical ‘and.’ */
export type BillableMetricLogFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `data` field. */
  data?: Maybe<JsonFilter>;
  /** Filter by the object’s `sentAt` field. */
  sentAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<BillableMetricLogFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<BillableMetricLogFilter>>;
  /** Negates the expression. */
  not?: Maybe<BillableMetricLogFilter>;
};

/** An input for mutations affecting `BillableMetricLog` */
export type BillableMetricLogInput = {
  id?: Maybe<Scalars['Int']>;
  data: Scalars['JSON'];
  sentAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type BillableMetricLogMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type BillableMetricLogMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `BillableMetricLog`. Fields that are set will be updated. */
export type BillableMetricLogPatch = {
  id?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['JSON']>;
  sentAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type BillableMetricLogStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type BillableMetricLogStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type BillableMetricLogSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type BillableMetricLogVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type BillableMetricLogVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `BillableMetricLog` values. */
export type BillableMetricLogsConnection = {
  /** A list of `BillableMetricLog` objects. */
  nodes: Array<BillableMetricLog>;
  /** A list of edges which contains the `BillableMetricLog` and cursor to aid in pagination. */
  edges: Array<BillableMetricLogsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillableMetricLog` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BillableMetricLogAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BillableMetricLogAggregates>>;
};


/** A connection to a list of `BillableMetricLog` values. */
export type BillableMetricLogsConnectionGroupedAggregatesArgs = {
  groupBy: Array<BillableMetricLogsGroupBy>;
  having?: Maybe<BillableMetricLogsHavingInput>;
};

/** A `BillableMetricLog` edge in the connection. */
export type BillableMetricLogsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BillableMetricLog` at the end of the edge. */
  node: BillableMetricLog;
};

/** Grouping methods for `BillableMetricLog` for usage during aggregation. */
export enum BillableMetricLogsGroupBy {
  Data = 'DATA',
  SentAt = 'SENT_AT',
  SentAtTruncatedToHour = 'SENT_AT_TRUNCATED_TO_HOUR',
  SentAtTruncatedToDay = 'SENT_AT_TRUNCATED_TO_DAY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY'
}

export type BillableMetricLogsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  sentAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type BillableMetricLogsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  sentAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `BillableMetricLog` aggregates. */
export type BillableMetricLogsHavingInput = {
  AND?: Maybe<Array<BillableMetricLogsHavingInput>>;
  OR?: Maybe<Array<BillableMetricLogsHavingInput>>;
  sum?: Maybe<BillableMetricLogsHavingSumInput>;
  distinctCount?: Maybe<BillableMetricLogsHavingDistinctCountInput>;
  min?: Maybe<BillableMetricLogsHavingMinInput>;
  max?: Maybe<BillableMetricLogsHavingMaxInput>;
  average?: Maybe<BillableMetricLogsHavingAverageInput>;
  stddevSample?: Maybe<BillableMetricLogsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<BillableMetricLogsHavingStddevPopulationInput>;
  varianceSample?: Maybe<BillableMetricLogsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<BillableMetricLogsHavingVariancePopulationInput>;
};

export type BillableMetricLogsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  sentAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type BillableMetricLogsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  sentAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type BillableMetricLogsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  sentAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type BillableMetricLogsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  sentAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type BillableMetricLogsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  sentAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type BillableMetricLogsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  sentAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type BillableMetricLogsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  sentAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `BillableMetricLog`. */
export enum BillableMetricLogsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  DataAsc = 'DATA_ASC',
  DataDesc = 'DATA_DESC',
  SentAtAsc = 'SENT_AT_ASC',
  SentAtDesc = 'SENT_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Billing = {
  invoices: Array<CompanyInvoice>;
  subscriptions: Array<CompanySubscription>;
  paymentProviders: Array<PaymentProvider>;
  salesOrders: Array<CompanySalesOrder>;
};

export type BillingAddress = {
  company?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type BodyTypeHints = {
  marketClassId?: Maybe<Scalars['String']>;
  marketClassName?: Maybe<Scalars['String']>;
  explicitlyStatedBodyTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Boolean']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Boolean']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
};

export type ChangeUserPasswordResponse = {
  success?: Maybe<Scalars['Boolean']>;
  temporaryPassword?: Maybe<Scalars['String']>;
};

export type Channel = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  name: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  displayName: Scalars['String'];
  enabled: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannels: CompanyChannelsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyChannelChannelIdAndCompanyId: ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyConnection;
  fieldConfigs?: Maybe<FieldConfigsConnection>;
};


export type ChannelCompanyChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type ChannelFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

export type ChannelAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ChannelSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ChannelDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ChannelMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ChannelMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ChannelAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ChannelStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ChannelStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ChannelVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ChannelVariancePopulationAggregates>;
};

export type ChannelAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `channel` to look up the row to connect. */
export type ChannelChannelPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `channel` to look up the row to delete. */
export type ChannelChannelPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `Company` values, with data from `CompanyChannel`. */
export type ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyChannel`, and the cursor to aid in pagination. */
  edges: Array<ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyChannel`. */
export type ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyChannel`. */
export type ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannels: CompanyChannelsConnection;
};


/** A `Company` edge in the connection, with data from `CompanyChannel`. */
export type ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyEdgeCompanyChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};

/** A condition to be used against `Channel` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ChannelCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
};

export type ChannelDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Channel` object types. All fields are combined with a logical ‘and.’ */
export type ChannelFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `companyChannels` relation. */
  companyChannels?: Maybe<ChannelToManyCompanyChannelFilter>;
  /** Some related `companyChannels` exist. */
  companyChannelsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ChannelFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ChannelFilter>>;
  /** Negates the expression. */
  not?: Maybe<ChannelFilter>;
};

/** Grouping methods for `Channel` for usage during aggregation. */
export enum ChannelGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  DisplayName = 'DISPLAY_NAME',
  Enabled = 'ENABLED'
}

export type ChannelHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Channel` aggregates. */
export type ChannelHavingInput = {
  AND?: Maybe<Array<ChannelHavingInput>>;
  OR?: Maybe<Array<ChannelHavingInput>>;
  sum?: Maybe<ChannelHavingSumInput>;
  distinctCount?: Maybe<ChannelHavingDistinctCountInput>;
  min?: Maybe<ChannelHavingMinInput>;
  max?: Maybe<ChannelHavingMaxInput>;
  average?: Maybe<ChannelHavingAverageInput>;
  stddevSample?: Maybe<ChannelHavingStddevSampleInput>;
  stddevPopulation?: Maybe<ChannelHavingStddevPopulationInput>;
  varianceSample?: Maybe<ChannelHavingVarianceSampleInput>;
  variancePopulation?: Maybe<ChannelHavingVariancePopulationInput>;
};

export type ChannelHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Channel` */
export type ChannelInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyChannelsUsingId?: Maybe<CompanyChannelChannelIdFkeyInverseInput>;
};

export type ChannelMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type ChannelMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ChannelNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `channel` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ChannelNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `channel` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ChannelOnCompanyChannelForCompanyChannelChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** The fields on `channel` to look up the row to update. */
export type ChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `channel` being updated. */
  patch: UpdateChannelOnCompanyChannelForCompanyChannelChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `channel` to look up the row to update. */
export type ChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingUniqueChannelNameUpdate = {
  /** An object where the defined keys will be set on the `channel` being updated. */
  patch: UpdateChannelOnCompanyChannelForCompanyChannelChannelIdFkeyPatch;
  name: Scalars['String'];
};

/** Represents an update to a `Channel`. Fields that are set will be updated. */
export type ChannelPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyChannelsUsingId?: Maybe<CompanyChannelChannelIdFkeyInverseInput>;
};

export type ChannelStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ChannelStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ChannelSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyChannel` object types. All fields are combined with a logical ‘and.’ */
export type ChannelToManyCompanyChannelFilter = {
  /** Every related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyChannelFilter>;
  /** Some related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyChannelFilter>;
  /** No related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyChannelFilter>;
  /** Aggregates across related `CompanyChannel` match the filter criteria. */
  aggregates?: Maybe<CompanyChannelAggregatesFilter>;
};

/** The fields on `channel` to look up the row to connect. */
export type ChannelUniqueChannelNameConnect = {
  name: Scalars['String'];
};

/** The fields on `channel` to look up the row to delete. */
export type ChannelUniqueChannelNameDelete = {
  name: Scalars['String'];
};

export type ChannelVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ChannelVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Channel` values. */
export type ChannelsConnection = {
  /** A list of `Channel` objects. */
  nodes: Array<Channel>;
  /** A list of edges which contains the `Channel` and cursor to aid in pagination. */
  edges: Array<ChannelsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Channel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ChannelAggregates>>;
};


/** A connection to a list of `Channel` values. */
export type ChannelsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ChannelGroupBy>;
  having?: Maybe<ChannelHavingInput>;
};

/** A `Channel` edge in the connection. */
export type ChannelsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Channel` at the end of the edge. */
  node: Channel;
};

/** Methods to use when ordering `Channel`. */
export enum ChannelsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyChannelsCountAsc = 'COMPANY_CHANNELS_COUNT_ASC',
  CompanyChannelsCountDesc = 'COMPANY_CHANNELS_COUNT_DESC',
  CompanyChannelsSumIdAsc = 'COMPANY_CHANNELS_SUM_ID_ASC',
  CompanyChannelsSumIdDesc = 'COMPANY_CHANNELS_SUM_ID_DESC',
  CompanyChannelsSumWidgetIdAsc = 'COMPANY_CHANNELS_SUM_WIDGET_ID_ASC',
  CompanyChannelsSumWidgetIdDesc = 'COMPANY_CHANNELS_SUM_WIDGET_ID_DESC',
  CompanyChannelsSumChannelIdAsc = 'COMPANY_CHANNELS_SUM_CHANNEL_ID_ASC',
  CompanyChannelsSumChannelIdDesc = 'COMPANY_CHANNELS_SUM_CHANNEL_ID_DESC',
  CompanyChannelsSumCompanyIdAsc = 'COMPANY_CHANNELS_SUM_COMPANY_ID_ASC',
  CompanyChannelsSumCompanyIdDesc = 'COMPANY_CHANNELS_SUM_COMPANY_ID_DESC',
  CompanyChannelsSumEnabledAsc = 'COMPANY_CHANNELS_SUM_ENABLED_ASC',
  CompanyChannelsSumEnabledDesc = 'COMPANY_CHANNELS_SUM_ENABLED_DESC',
  CompanyChannelsSumCreatedAtAsc = 'COMPANY_CHANNELS_SUM_CREATED_AT_ASC',
  CompanyChannelsSumCreatedAtDesc = 'COMPANY_CHANNELS_SUM_CREATED_AT_DESC',
  CompanyChannelsSumUpdatedAtAsc = 'COMPANY_CHANNELS_SUM_UPDATED_AT_ASC',
  CompanyChannelsSumUpdatedAtDesc = 'COMPANY_CHANNELS_SUM_UPDATED_AT_DESC',
  CompanyChannelsSumNameAsc = 'COMPANY_CHANNELS_SUM_NAME_ASC',
  CompanyChannelsSumNameDesc = 'COMPANY_CHANNELS_SUM_NAME_DESC',
  CompanyChannelsSumDisplayNameAsc = 'COMPANY_CHANNELS_SUM_DISPLAY_NAME_ASC',
  CompanyChannelsSumDisplayNameDesc = 'COMPANY_CHANNELS_SUM_DISPLAY_NAME_DESC',
  CompanyChannelsSumDeletedAtAsc = 'COMPANY_CHANNELS_SUM_DELETED_AT_ASC',
  CompanyChannelsSumDeletedAtDesc = 'COMPANY_CHANNELS_SUM_DELETED_AT_DESC',
  CompanyChannelsDistinctCountIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ID_ASC',
  CompanyChannelsDistinctCountIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ID_DESC',
  CompanyChannelsDistinctCountWidgetIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CompanyChannelsDistinctCountWidgetIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CompanyChannelsDistinctCountChannelIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CHANNEL_ID_ASC',
  CompanyChannelsDistinctCountChannelIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CHANNEL_ID_DESC',
  CompanyChannelsDistinctCountCompanyIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyChannelsDistinctCountCompanyIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyChannelsDistinctCountEnabledAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyChannelsDistinctCountEnabledDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyChannelsDistinctCountCreatedAtAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyChannelsDistinctCountCreatedAtDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyChannelsDistinctCountUpdatedAtAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyChannelsDistinctCountUpdatedAtDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyChannelsDistinctCountNameAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_NAME_ASC',
  CompanyChannelsDistinctCountNameDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_NAME_DESC',
  CompanyChannelsDistinctCountDisplayNameAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_DISPLAY_NAME_ASC',
  CompanyChannelsDistinctCountDisplayNameDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_DISPLAY_NAME_DESC',
  CompanyChannelsDistinctCountDeletedAtAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_DELETED_AT_ASC',
  CompanyChannelsDistinctCountDeletedAtDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_DELETED_AT_DESC',
  CompanyChannelsMinIdAsc = 'COMPANY_CHANNELS_MIN_ID_ASC',
  CompanyChannelsMinIdDesc = 'COMPANY_CHANNELS_MIN_ID_DESC',
  CompanyChannelsMinWidgetIdAsc = 'COMPANY_CHANNELS_MIN_WIDGET_ID_ASC',
  CompanyChannelsMinWidgetIdDesc = 'COMPANY_CHANNELS_MIN_WIDGET_ID_DESC',
  CompanyChannelsMinChannelIdAsc = 'COMPANY_CHANNELS_MIN_CHANNEL_ID_ASC',
  CompanyChannelsMinChannelIdDesc = 'COMPANY_CHANNELS_MIN_CHANNEL_ID_DESC',
  CompanyChannelsMinCompanyIdAsc = 'COMPANY_CHANNELS_MIN_COMPANY_ID_ASC',
  CompanyChannelsMinCompanyIdDesc = 'COMPANY_CHANNELS_MIN_COMPANY_ID_DESC',
  CompanyChannelsMinEnabledAsc = 'COMPANY_CHANNELS_MIN_ENABLED_ASC',
  CompanyChannelsMinEnabledDesc = 'COMPANY_CHANNELS_MIN_ENABLED_DESC',
  CompanyChannelsMinCreatedAtAsc = 'COMPANY_CHANNELS_MIN_CREATED_AT_ASC',
  CompanyChannelsMinCreatedAtDesc = 'COMPANY_CHANNELS_MIN_CREATED_AT_DESC',
  CompanyChannelsMinUpdatedAtAsc = 'COMPANY_CHANNELS_MIN_UPDATED_AT_ASC',
  CompanyChannelsMinUpdatedAtDesc = 'COMPANY_CHANNELS_MIN_UPDATED_AT_DESC',
  CompanyChannelsMinNameAsc = 'COMPANY_CHANNELS_MIN_NAME_ASC',
  CompanyChannelsMinNameDesc = 'COMPANY_CHANNELS_MIN_NAME_DESC',
  CompanyChannelsMinDisplayNameAsc = 'COMPANY_CHANNELS_MIN_DISPLAY_NAME_ASC',
  CompanyChannelsMinDisplayNameDesc = 'COMPANY_CHANNELS_MIN_DISPLAY_NAME_DESC',
  CompanyChannelsMinDeletedAtAsc = 'COMPANY_CHANNELS_MIN_DELETED_AT_ASC',
  CompanyChannelsMinDeletedAtDesc = 'COMPANY_CHANNELS_MIN_DELETED_AT_DESC',
  CompanyChannelsMaxIdAsc = 'COMPANY_CHANNELS_MAX_ID_ASC',
  CompanyChannelsMaxIdDesc = 'COMPANY_CHANNELS_MAX_ID_DESC',
  CompanyChannelsMaxWidgetIdAsc = 'COMPANY_CHANNELS_MAX_WIDGET_ID_ASC',
  CompanyChannelsMaxWidgetIdDesc = 'COMPANY_CHANNELS_MAX_WIDGET_ID_DESC',
  CompanyChannelsMaxChannelIdAsc = 'COMPANY_CHANNELS_MAX_CHANNEL_ID_ASC',
  CompanyChannelsMaxChannelIdDesc = 'COMPANY_CHANNELS_MAX_CHANNEL_ID_DESC',
  CompanyChannelsMaxCompanyIdAsc = 'COMPANY_CHANNELS_MAX_COMPANY_ID_ASC',
  CompanyChannelsMaxCompanyIdDesc = 'COMPANY_CHANNELS_MAX_COMPANY_ID_DESC',
  CompanyChannelsMaxEnabledAsc = 'COMPANY_CHANNELS_MAX_ENABLED_ASC',
  CompanyChannelsMaxEnabledDesc = 'COMPANY_CHANNELS_MAX_ENABLED_DESC',
  CompanyChannelsMaxCreatedAtAsc = 'COMPANY_CHANNELS_MAX_CREATED_AT_ASC',
  CompanyChannelsMaxCreatedAtDesc = 'COMPANY_CHANNELS_MAX_CREATED_AT_DESC',
  CompanyChannelsMaxUpdatedAtAsc = 'COMPANY_CHANNELS_MAX_UPDATED_AT_ASC',
  CompanyChannelsMaxUpdatedAtDesc = 'COMPANY_CHANNELS_MAX_UPDATED_AT_DESC',
  CompanyChannelsMaxNameAsc = 'COMPANY_CHANNELS_MAX_NAME_ASC',
  CompanyChannelsMaxNameDesc = 'COMPANY_CHANNELS_MAX_NAME_DESC',
  CompanyChannelsMaxDisplayNameAsc = 'COMPANY_CHANNELS_MAX_DISPLAY_NAME_ASC',
  CompanyChannelsMaxDisplayNameDesc = 'COMPANY_CHANNELS_MAX_DISPLAY_NAME_DESC',
  CompanyChannelsMaxDeletedAtAsc = 'COMPANY_CHANNELS_MAX_DELETED_AT_ASC',
  CompanyChannelsMaxDeletedAtDesc = 'COMPANY_CHANNELS_MAX_DELETED_AT_DESC',
  CompanyChannelsAverageIdAsc = 'COMPANY_CHANNELS_AVERAGE_ID_ASC',
  CompanyChannelsAverageIdDesc = 'COMPANY_CHANNELS_AVERAGE_ID_DESC',
  CompanyChannelsAverageWidgetIdAsc = 'COMPANY_CHANNELS_AVERAGE_WIDGET_ID_ASC',
  CompanyChannelsAverageWidgetIdDesc = 'COMPANY_CHANNELS_AVERAGE_WIDGET_ID_DESC',
  CompanyChannelsAverageChannelIdAsc = 'COMPANY_CHANNELS_AVERAGE_CHANNEL_ID_ASC',
  CompanyChannelsAverageChannelIdDesc = 'COMPANY_CHANNELS_AVERAGE_CHANNEL_ID_DESC',
  CompanyChannelsAverageCompanyIdAsc = 'COMPANY_CHANNELS_AVERAGE_COMPANY_ID_ASC',
  CompanyChannelsAverageCompanyIdDesc = 'COMPANY_CHANNELS_AVERAGE_COMPANY_ID_DESC',
  CompanyChannelsAverageEnabledAsc = 'COMPANY_CHANNELS_AVERAGE_ENABLED_ASC',
  CompanyChannelsAverageEnabledDesc = 'COMPANY_CHANNELS_AVERAGE_ENABLED_DESC',
  CompanyChannelsAverageCreatedAtAsc = 'COMPANY_CHANNELS_AVERAGE_CREATED_AT_ASC',
  CompanyChannelsAverageCreatedAtDesc = 'COMPANY_CHANNELS_AVERAGE_CREATED_AT_DESC',
  CompanyChannelsAverageUpdatedAtAsc = 'COMPANY_CHANNELS_AVERAGE_UPDATED_AT_ASC',
  CompanyChannelsAverageUpdatedAtDesc = 'COMPANY_CHANNELS_AVERAGE_UPDATED_AT_DESC',
  CompanyChannelsAverageNameAsc = 'COMPANY_CHANNELS_AVERAGE_NAME_ASC',
  CompanyChannelsAverageNameDesc = 'COMPANY_CHANNELS_AVERAGE_NAME_DESC',
  CompanyChannelsAverageDisplayNameAsc = 'COMPANY_CHANNELS_AVERAGE_DISPLAY_NAME_ASC',
  CompanyChannelsAverageDisplayNameDesc = 'COMPANY_CHANNELS_AVERAGE_DISPLAY_NAME_DESC',
  CompanyChannelsAverageDeletedAtAsc = 'COMPANY_CHANNELS_AVERAGE_DELETED_AT_ASC',
  CompanyChannelsAverageDeletedAtDesc = 'COMPANY_CHANNELS_AVERAGE_DELETED_AT_DESC',
  CompanyChannelsStddevSampleIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ID_ASC',
  CompanyChannelsStddevSampleIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ID_DESC',
  CompanyChannelsStddevSampleWidgetIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CompanyChannelsStddevSampleWidgetIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CompanyChannelsStddevSampleChannelIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CHANNEL_ID_ASC',
  CompanyChannelsStddevSampleChannelIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CHANNEL_ID_DESC',
  CompanyChannelsStddevSampleCompanyIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyChannelsStddevSampleCompanyIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyChannelsStddevSampleEnabledAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyChannelsStddevSampleEnabledDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyChannelsStddevSampleCreatedAtAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyChannelsStddevSampleCreatedAtDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyChannelsStddevSampleUpdatedAtAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelsStddevSampleUpdatedAtDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelsStddevSampleNameAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_NAME_ASC',
  CompanyChannelsStddevSampleNameDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_NAME_DESC',
  CompanyChannelsStddevSampleDisplayNameAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_DISPLAY_NAME_ASC',
  CompanyChannelsStddevSampleDisplayNameDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_DISPLAY_NAME_DESC',
  CompanyChannelsStddevSampleDeletedAtAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_DELETED_AT_ASC',
  CompanyChannelsStddevSampleDeletedAtDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_DELETED_AT_DESC',
  CompanyChannelsStddevPopulationIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ID_ASC',
  CompanyChannelsStddevPopulationIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ID_DESC',
  CompanyChannelsStddevPopulationWidgetIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CompanyChannelsStddevPopulationWidgetIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CompanyChannelsStddevPopulationChannelIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CHANNEL_ID_ASC',
  CompanyChannelsStddevPopulationChannelIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CHANNEL_ID_DESC',
  CompanyChannelsStddevPopulationCompanyIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyChannelsStddevPopulationCompanyIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyChannelsStddevPopulationEnabledAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyChannelsStddevPopulationEnabledDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyChannelsStddevPopulationCreatedAtAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyChannelsStddevPopulationCreatedAtDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyChannelsStddevPopulationUpdatedAtAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyChannelsStddevPopulationUpdatedAtDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyChannelsStddevPopulationNameAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_NAME_ASC',
  CompanyChannelsStddevPopulationNameDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_NAME_DESC',
  CompanyChannelsStddevPopulationDisplayNameAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_DISPLAY_NAME_ASC',
  CompanyChannelsStddevPopulationDisplayNameDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_DISPLAY_NAME_DESC',
  CompanyChannelsStddevPopulationDeletedAtAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_DELETED_AT_ASC',
  CompanyChannelsStddevPopulationDeletedAtDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_DELETED_AT_DESC',
  CompanyChannelsVarianceSampleIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ID_ASC',
  CompanyChannelsVarianceSampleIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ID_DESC',
  CompanyChannelsVarianceSampleWidgetIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CompanyChannelsVarianceSampleWidgetIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CompanyChannelsVarianceSampleChannelIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CHANNEL_ID_ASC',
  CompanyChannelsVarianceSampleChannelIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CHANNEL_ID_DESC',
  CompanyChannelsVarianceSampleCompanyIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyChannelsVarianceSampleCompanyIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyChannelsVarianceSampleEnabledAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyChannelsVarianceSampleEnabledDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyChannelsVarianceSampleCreatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyChannelsVarianceSampleCreatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyChannelsVarianceSampleUpdatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelsVarianceSampleUpdatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelsVarianceSampleNameAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_NAME_ASC',
  CompanyChannelsVarianceSampleNameDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_NAME_DESC',
  CompanyChannelsVarianceSampleDisplayNameAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_DISPLAY_NAME_ASC',
  CompanyChannelsVarianceSampleDisplayNameDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_DISPLAY_NAME_DESC',
  CompanyChannelsVarianceSampleDeletedAtAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  CompanyChannelsVarianceSampleDeletedAtDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  CompanyChannelsVariancePopulationIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ID_ASC',
  CompanyChannelsVariancePopulationIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ID_DESC',
  CompanyChannelsVariancePopulationWidgetIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CompanyChannelsVariancePopulationWidgetIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CompanyChannelsVariancePopulationChannelIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CHANNEL_ID_ASC',
  CompanyChannelsVariancePopulationChannelIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CHANNEL_ID_DESC',
  CompanyChannelsVariancePopulationCompanyIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyChannelsVariancePopulationCompanyIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyChannelsVariancePopulationEnabledAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyChannelsVariancePopulationEnabledDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyChannelsVariancePopulationCreatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyChannelsVariancePopulationCreatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyChannelsVariancePopulationUpdatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyChannelsVariancePopulationUpdatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyChannelsVariancePopulationNameAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_NAME_ASC',
  CompanyChannelsVariancePopulationNameDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_NAME_DESC',
  CompanyChannelsVariancePopulationDisplayNameAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_DISPLAY_NAME_ASC',
  CompanyChannelsVariancePopulationDisplayNameDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_DISPLAY_NAME_DESC',
  CompanyChannelsVariancePopulationDeletedAtAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_DELETED_AT_ASC',
  CompanyChannelsVariancePopulationDeletedAtDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_DELETED_AT_DESC'
}

export type Charge = {
  chargeModel?: Maybe<Scalars['String']>;
  invoiceDisplayName?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
};

export type ChargeGroup = {
  amount?: Maybe<Scalars['Float']>;
  eventsCount?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  lagoId?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ChargeUsage = {
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  billableMetric?: Maybe<BillableMetric>;
  charge?: Maybe<Charge>;
  eventsCount?: Maybe<Scalars['Int']>;
  groups?: Maybe<Array<Maybe<ChargeGroup>>>;
  units?: Maybe<Scalars['String']>;
};

export type CodeDescription = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type Collection = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  assignedDate?: Maybe<Scalars['String']>;
  agency?: Maybe<IdName>;
  originalAmount?: Maybe<Scalars['String']>;
  balanceAmount?: Maybe<Scalars['String']>;
  dateOfLastPayment?: Maybe<Scalars['String']>;
  updateSource?: Maybe<CodeDescription>;
};

/** A connection to a list of `Company` values. */
export type CompaniesConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company` and cursor to aid in pagination. */
  edges: Array<CompaniesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values. */
export type CompaniesConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection. */
export type CompaniesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
};

/** Methods to use when ordering `Company`. */
export enum CompaniesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  EquifaxCustomerNumberAsc = 'EQUIFAX_CUSTOMER_NUMBER_ASC',
  EquifaxCustomerNumberDesc = 'EQUIFAX_CUSTOMER_NUMBER_DESC',
  SecurityCodeAsc = 'SECURITY_CODE_ASC',
  SecurityCodeDesc = 'SECURITY_CODE_DESC',
  ExternalIdAsc = 'EXTERNAL_ID_ASC',
  ExternalIdDesc = 'EXTERNAL_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  ZohoIdAsc = 'ZOHO_ID_ASC',
  ZohoIdDesc = 'ZOHO_ID_DESC',
  DeliverToAsc = 'DELIVER_TO_ASC',
  DeliverToDesc = 'DELIVER_TO_DESC',
  PrimaryGroupAsc = 'PRIMARY_GROUP_ASC',
  PrimaryGroupDesc = 'PRIMARY_GROUP_DESC',
  RawMetaAsc = 'RAW_META_ASC',
  RawMetaDesc = 'RAW_META_DESC',
  BillableCompanyIdAsc = 'BILLABLE_COMPANY_ID_ASC',
  BillableCompanyIdDesc = 'BILLABLE_COMPANY_ID_DESC',
  RawRequestedAccessAsc = 'RAW_REQUESTED_ACCESS_ASC',
  RawRequestedAccessDesc = 'RAW_REQUESTED_ACCESS_DESC',
  LenderDealerCodeAsc = 'LENDER_DEALER_CODE_ASC',
  LenderDealerCodeDesc = 'LENDER_DEALER_CODE_DESC',
  GooglePlacesIdAsc = 'GOOGLE_PLACES_ID_ASC',
  GooglePlacesIdDesc = 'GOOGLE_PLACES_ID_DESC',
  LegalConsentAsc = 'LEGAL_CONSENT_ASC',
  LegalConsentDesc = 'LEGAL_CONSENT_DESC',
  LagoIdAsc = 'LAGO_ID_ASC',
  LagoIdDesc = 'LAGO_ID_DESC',
  StripeCustomerIdAsc = 'STRIPE_CUSTOMER_ID_ASC',
  StripeCustomerIdDesc = 'STRIPE_CUSTOMER_ID_DESC',
  SalesOrderIdAsc = 'SALES_ORDER_ID_ASC',
  SalesOrderIdDesc = 'SALES_ORDER_ID_DESC',
  IndustryAsc = 'INDUSTRY_ASC',
  IndustryDesc = 'INDUSTRY_DESC',
  ManufacturerAsc = 'MANUFACTURER_ASC',
  ManufacturerDesc = 'MANUFACTURER_DESC',
  CrmAsc = 'CRM_ASC',
  CrmDesc = 'CRM_DESC',
  AccountManagerAsc = 'ACCOUNT_MANAGER_ASC',
  AccountManagerDesc = 'ACCOUNT_MANAGER_DESC',
  SupportAgentAsc = 'SUPPORT_AGENT_ASC',
  SupportAgentDesc = 'SUPPORT_AGENT_DESC',
  OpportunityTypeAsc = 'OPPORTUNITY_TYPE_ASC',
  OpportunityTypeDesc = 'OPPORTUNITY_TYPE_DESC',
  NumberOfLocationsAsc = 'NUMBER_OF_LOCATIONS_ASC',
  NumberOfLocationsDesc = 'NUMBER_OF_LOCATIONS_DESC',
  WebsiteAsc = 'WEBSITE_ASC',
  WebsiteDesc = 'WEBSITE_DESC',
  DmsAsc = 'DMS_ASC',
  DmsDesc = 'DMS_DESC',
  WebProviderAsc = 'WEB_PROVIDER_ASC',
  WebProviderDesc = 'WEB_PROVIDER_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  LeadsCountAsc = 'LEADS_COUNT_ASC',
  LeadsCountDesc = 'LEADS_COUNT_DESC',
  LeadsSumIdAsc = 'LEADS_SUM_ID_ASC',
  LeadsSumIdDesc = 'LEADS_SUM_ID_DESC',
  LeadsSumCompanyIdAsc = 'LEADS_SUM_COMPANY_ID_ASC',
  LeadsSumCompanyIdDesc = 'LEADS_SUM_COMPANY_ID_DESC',
  LeadsSumCreatedAtAsc = 'LEADS_SUM_CREATED_AT_ASC',
  LeadsSumCreatedAtDesc = 'LEADS_SUM_CREATED_AT_DESC',
  LeadsSumFirstNameAsc = 'LEADS_SUM_FIRST_NAME_ASC',
  LeadsSumFirstNameDesc = 'LEADS_SUM_FIRST_NAME_DESC',
  LeadsSumLastNameAsc = 'LEADS_SUM_LAST_NAME_ASC',
  LeadsSumLastNameDesc = 'LEADS_SUM_LAST_NAME_DESC',
  LeadsSumDateOfBirthAsc = 'LEADS_SUM_DATE_OF_BIRTH_ASC',
  LeadsSumDateOfBirthDesc = 'LEADS_SUM_DATE_OF_BIRTH_DESC',
  LeadsSumStreetAsc = 'LEADS_SUM_STREET_ASC',
  LeadsSumStreetDesc = 'LEADS_SUM_STREET_DESC',
  LeadsSumStreetNumberAsc = 'LEADS_SUM_STREET_NUMBER_ASC',
  LeadsSumStreetNumberDesc = 'LEADS_SUM_STREET_NUMBER_DESC',
  LeadsSumUnitAsc = 'LEADS_SUM_UNIT_ASC',
  LeadsSumUnitDesc = 'LEADS_SUM_UNIT_DESC',
  LeadsSumCityAsc = 'LEADS_SUM_CITY_ASC',
  LeadsSumCityDesc = 'LEADS_SUM_CITY_DESC',
  LeadsSumCountryAsc = 'LEADS_SUM_COUNTRY_ASC',
  LeadsSumCountryDesc = 'LEADS_SUM_COUNTRY_DESC',
  LeadsSumPostalCodeAsc = 'LEADS_SUM_POSTAL_CODE_ASC',
  LeadsSumPostalCodeDesc = 'LEADS_SUM_POSTAL_CODE_DESC',
  LeadsSumProvinceCodeAsc = 'LEADS_SUM_PROVINCE_CODE_ASC',
  LeadsSumProvinceCodeDesc = 'LEADS_SUM_PROVINCE_CODE_DESC',
  LeadsSumEmailAddressAsc = 'LEADS_SUM_EMAIL_ADDRESS_ASC',
  LeadsSumEmailAddressDesc = 'LEADS_SUM_EMAIL_ADDRESS_DESC',
  LeadsSumPhoneNumberAsc = 'LEADS_SUM_PHONE_NUMBER_ASC',
  LeadsSumPhoneNumberDesc = 'LEADS_SUM_PHONE_NUMBER_DESC',
  LeadsSumSourceUrlAsc = 'LEADS_SUM_SOURCE_URL_ASC',
  LeadsSumSourceUrlDesc = 'LEADS_SUM_SOURCE_URL_DESC',
  LeadsSumTransactionKeyAsc = 'LEADS_SUM_TRANSACTION_KEY_ASC',
  LeadsSumTransactionKeyDesc = 'LEADS_SUM_TRANSACTION_KEY_DESC',
  LeadsSumLeadspediaIdAsc = 'LEADS_SUM_LEADSPEDIA_ID_ASC',
  LeadsSumLeadspediaIdDesc = 'LEADS_SUM_LEADSPEDIA_ID_DESC',
  LeadsSumUpdatedAtAsc = 'LEADS_SUM_UPDATED_AT_ASC',
  LeadsSumUpdatedAtDesc = 'LEADS_SUM_UPDATED_AT_DESC',
  LeadsSumSearchTextAsc = 'LEADS_SUM_SEARCH_TEXT_ASC',
  LeadsSumSearchTextDesc = 'LEADS_SUM_SEARCH_TEXT_DESC',
  LeadsSumCommentAsc = 'LEADS_SUM_COMMENT_ASC',
  LeadsSumCommentDesc = 'LEADS_SUM_COMMENT_DESC',
  LeadsSumStateAsc = 'LEADS_SUM_STATE_ASC',
  LeadsSumStateDesc = 'LEADS_SUM_STATE_DESC',
  LeadsSumReadAsc = 'LEADS_SUM_READ_ASC',
  LeadsSumReadDesc = 'LEADS_SUM_READ_DESC',
  LeadsSumExternalIdsAsc = 'LEADS_SUM_EXTERNAL_IDS_ASC',
  LeadsSumExternalIdsDesc = 'LEADS_SUM_EXTERNAL_IDS_DESC',
  LeadsSumMetaAsc = 'LEADS_SUM_META_ASC',
  LeadsSumMetaDesc = 'LEADS_SUM_META_DESC',
  LeadsSumAdditionalInformationAsc = 'LEADS_SUM_ADDITIONAL_INFORMATION_ASC',
  LeadsSumAdditionalInformationDesc = 'LEADS_SUM_ADDITIONAL_INFORMATION_DESC',
  LeadsSumPhoneVerifiedAtAsc = 'LEADS_SUM_PHONE_VERIFIED_AT_ASC',
  LeadsSumPhoneVerifiedAtDesc = 'LEADS_SUM_PHONE_VERIFIED_AT_DESC',
  LeadsSumIpAddressAsc = 'LEADS_SUM_IP_ADDRESS_ASC',
  LeadsSumIpAddressDesc = 'LEADS_SUM_IP_ADDRESS_DESC',
  LeadsSumClientIdAsc = 'LEADS_SUM_CLIENT_ID_ASC',
  LeadsSumClientIdDesc = 'LEADS_SUM_CLIENT_ID_DESC',
  LeadsSumDeletedAsc = 'LEADS_SUM_DELETED_ASC',
  LeadsSumDeletedDesc = 'LEADS_SUM_DELETED_DESC',
  LeadsSumPriorityAsc = 'LEADS_SUM_PRIORITY_ASC',
  LeadsSumPriorityDesc = 'LEADS_SUM_PRIORITY_DESC',
  LeadsSumAssigneeAsc = 'LEADS_SUM_ASSIGNEE_ASC',
  LeadsSumAssigneeDesc = 'LEADS_SUM_ASSIGNEE_DESC',
  LeadsSumShortIdAsc = 'LEADS_SUM_SHORT_ID_ASC',
  LeadsSumShortIdDesc = 'LEADS_SUM_SHORT_ID_DESC',
  LeadsSumCompanyChannelIdAsc = 'LEADS_SUM_COMPANY_CHANNEL_ID_ASC',
  LeadsSumCompanyChannelIdDesc = 'LEADS_SUM_COMPANY_CHANNEL_ID_DESC',
  LeadsSumNeighborhoodViewIdAsc = 'LEADS_SUM_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsSumNeighborhoodViewIdDesc = 'LEADS_SUM_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsSumMaritalStatusAsc = 'LEADS_SUM_MARITAL_STATUS_ASC',
  LeadsSumMaritalStatusDesc = 'LEADS_SUM_MARITAL_STATUS_DESC',
  LeadsSumDesiredVehicleAsc = 'LEADS_SUM_DESIRED_VEHICLE_ASC',
  LeadsSumDesiredVehicleDesc = 'LEADS_SUM_DESIRED_VEHICLE_DESC',
  LeadsSumJobTitleAsc = 'LEADS_SUM_JOB_TITLE_ASC',
  LeadsSumJobTitleDesc = 'LEADS_SUM_JOB_TITLE_DESC',
  LeadsSumIncomePeriodAsc = 'LEADS_SUM_INCOME_PERIOD_ASC',
  LeadsSumIncomePeriodDesc = 'LEADS_SUM_INCOME_PERIOD_DESC',
  LeadsSumSalaryHourlyAsc = 'LEADS_SUM_SALARY_HOURLY_ASC',
  LeadsSumSalaryHourlyDesc = 'LEADS_SUM_SALARY_HOURLY_DESC',
  LeadsSumIncomeSourceAsc = 'LEADS_SUM_INCOME_SOURCE_ASC',
  LeadsSumIncomeSourceDesc = 'LEADS_SUM_INCOME_SOURCE_DESC',
  LeadsSumIncomeTimeAsc = 'LEADS_SUM_INCOME_TIME_ASC',
  LeadsSumIncomeTimeDesc = 'LEADS_SUM_INCOME_TIME_DESC',
  LeadsSumPreferredContactMethodAsc = 'LEADS_SUM_PREFERRED_CONTACT_METHOD_ASC',
  LeadsSumPreferredContactMethodDesc = 'LEADS_SUM_PREFERRED_CONTACT_METHOD_DESC',
  LeadsSumCompanyNameAsc = 'LEADS_SUM_COMPANY_NAME_ASC',
  LeadsSumCompanyNameDesc = 'LEADS_SUM_COMPANY_NAME_DESC',
  LeadsSumCreditConcernAsc = 'LEADS_SUM_CREDIT_CONCERN_ASC',
  LeadsSumCreditConcernDesc = 'LEADS_SUM_CREDIT_CONCERN_DESC',
  LeadsSumResidenceTimeAsc = 'LEADS_SUM_RESIDENCE_TIME_ASC',
  LeadsSumResidenceTimeDesc = 'LEADS_SUM_RESIDENCE_TIME_DESC',
  LeadsSumRentOrOwnAsc = 'LEADS_SUM_RENT_OR_OWN_ASC',
  LeadsSumRentOrOwnDesc = 'LEADS_SUM_RENT_OR_OWN_DESC',
  LeadsSumEmploymentStatusAsc = 'LEADS_SUM_EMPLOYMENT_STATUS_ASC',
  LeadsSumEmploymentStatusDesc = 'LEADS_SUM_EMPLOYMENT_STATUS_DESC',
  LeadsSumIncomeAmountAsc = 'LEADS_SUM_INCOME_AMOUNT_ASC',
  LeadsSumIncomeAmountDesc = 'LEADS_SUM_INCOME_AMOUNT_DESC',
  LeadsSumMonthlyHomePaymentAsc = 'LEADS_SUM_MONTHLY_HOME_PAYMENT_ASC',
  LeadsSumMonthlyHomePaymentDesc = 'LEADS_SUM_MONTHLY_HOME_PAYMENT_DESC',
  LeadsSumSmsConsentAsc = 'LEADS_SUM_SMS_CONSENT_ASC',
  LeadsSumSmsConsentDesc = 'LEADS_SUM_SMS_CONSENT_DESC',
  LeadsSumIncomeTimeYearsAsc = 'LEADS_SUM_INCOME_TIME_YEARS_ASC',
  LeadsSumIncomeTimeYearsDesc = 'LEADS_SUM_INCOME_TIME_YEARS_DESC',
  LeadsSumIncomeTimeMonthsAsc = 'LEADS_SUM_INCOME_TIME_MONTHS_ASC',
  LeadsSumIncomeTimeMonthsDesc = 'LEADS_SUM_INCOME_TIME_MONTHS_DESC',
  LeadsSumResidenceTimeYearsAsc = 'LEADS_SUM_RESIDENCE_TIME_YEARS_ASC',
  LeadsSumResidenceTimeYearsDesc = 'LEADS_SUM_RESIDENCE_TIME_YEARS_DESC',
  LeadsSumResidenceTimeMonthsAsc = 'LEADS_SUM_RESIDENCE_TIME_MONTHS_ASC',
  LeadsSumResidenceTimeMonthsDesc = 'LEADS_SUM_RESIDENCE_TIME_MONTHS_DESC',
  LeadsDistinctCountIdAsc = 'LEADS_DISTINCT_COUNT_ID_ASC',
  LeadsDistinctCountIdDesc = 'LEADS_DISTINCT_COUNT_ID_DESC',
  LeadsDistinctCountCompanyIdAsc = 'LEADS_DISTINCT_COUNT_COMPANY_ID_ASC',
  LeadsDistinctCountCompanyIdDesc = 'LEADS_DISTINCT_COUNT_COMPANY_ID_DESC',
  LeadsDistinctCountCreatedAtAsc = 'LEADS_DISTINCT_COUNT_CREATED_AT_ASC',
  LeadsDistinctCountCreatedAtDesc = 'LEADS_DISTINCT_COUNT_CREATED_AT_DESC',
  LeadsDistinctCountFirstNameAsc = 'LEADS_DISTINCT_COUNT_FIRST_NAME_ASC',
  LeadsDistinctCountFirstNameDesc = 'LEADS_DISTINCT_COUNT_FIRST_NAME_DESC',
  LeadsDistinctCountLastNameAsc = 'LEADS_DISTINCT_COUNT_LAST_NAME_ASC',
  LeadsDistinctCountLastNameDesc = 'LEADS_DISTINCT_COUNT_LAST_NAME_DESC',
  LeadsDistinctCountDateOfBirthAsc = 'LEADS_DISTINCT_COUNT_DATE_OF_BIRTH_ASC',
  LeadsDistinctCountDateOfBirthDesc = 'LEADS_DISTINCT_COUNT_DATE_OF_BIRTH_DESC',
  LeadsDistinctCountStreetAsc = 'LEADS_DISTINCT_COUNT_STREET_ASC',
  LeadsDistinctCountStreetDesc = 'LEADS_DISTINCT_COUNT_STREET_DESC',
  LeadsDistinctCountStreetNumberAsc = 'LEADS_DISTINCT_COUNT_STREET_NUMBER_ASC',
  LeadsDistinctCountStreetNumberDesc = 'LEADS_DISTINCT_COUNT_STREET_NUMBER_DESC',
  LeadsDistinctCountUnitAsc = 'LEADS_DISTINCT_COUNT_UNIT_ASC',
  LeadsDistinctCountUnitDesc = 'LEADS_DISTINCT_COUNT_UNIT_DESC',
  LeadsDistinctCountCityAsc = 'LEADS_DISTINCT_COUNT_CITY_ASC',
  LeadsDistinctCountCityDesc = 'LEADS_DISTINCT_COUNT_CITY_DESC',
  LeadsDistinctCountCountryAsc = 'LEADS_DISTINCT_COUNT_COUNTRY_ASC',
  LeadsDistinctCountCountryDesc = 'LEADS_DISTINCT_COUNT_COUNTRY_DESC',
  LeadsDistinctCountPostalCodeAsc = 'LEADS_DISTINCT_COUNT_POSTAL_CODE_ASC',
  LeadsDistinctCountPostalCodeDesc = 'LEADS_DISTINCT_COUNT_POSTAL_CODE_DESC',
  LeadsDistinctCountProvinceCodeAsc = 'LEADS_DISTINCT_COUNT_PROVINCE_CODE_ASC',
  LeadsDistinctCountProvinceCodeDesc = 'LEADS_DISTINCT_COUNT_PROVINCE_CODE_DESC',
  LeadsDistinctCountEmailAddressAsc = 'LEADS_DISTINCT_COUNT_EMAIL_ADDRESS_ASC',
  LeadsDistinctCountEmailAddressDesc = 'LEADS_DISTINCT_COUNT_EMAIL_ADDRESS_DESC',
  LeadsDistinctCountPhoneNumberAsc = 'LEADS_DISTINCT_COUNT_PHONE_NUMBER_ASC',
  LeadsDistinctCountPhoneNumberDesc = 'LEADS_DISTINCT_COUNT_PHONE_NUMBER_DESC',
  LeadsDistinctCountSourceUrlAsc = 'LEADS_DISTINCT_COUNT_SOURCE_URL_ASC',
  LeadsDistinctCountSourceUrlDesc = 'LEADS_DISTINCT_COUNT_SOURCE_URL_DESC',
  LeadsDistinctCountTransactionKeyAsc = 'LEADS_DISTINCT_COUNT_TRANSACTION_KEY_ASC',
  LeadsDistinctCountTransactionKeyDesc = 'LEADS_DISTINCT_COUNT_TRANSACTION_KEY_DESC',
  LeadsDistinctCountLeadspediaIdAsc = 'LEADS_DISTINCT_COUNT_LEADSPEDIA_ID_ASC',
  LeadsDistinctCountLeadspediaIdDesc = 'LEADS_DISTINCT_COUNT_LEADSPEDIA_ID_DESC',
  LeadsDistinctCountUpdatedAtAsc = 'LEADS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LeadsDistinctCountUpdatedAtDesc = 'LEADS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LeadsDistinctCountSearchTextAsc = 'LEADS_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  LeadsDistinctCountSearchTextDesc = 'LEADS_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  LeadsDistinctCountCommentAsc = 'LEADS_DISTINCT_COUNT_COMMENT_ASC',
  LeadsDistinctCountCommentDesc = 'LEADS_DISTINCT_COUNT_COMMENT_DESC',
  LeadsDistinctCountStateAsc = 'LEADS_DISTINCT_COUNT_STATE_ASC',
  LeadsDistinctCountStateDesc = 'LEADS_DISTINCT_COUNT_STATE_DESC',
  LeadsDistinctCountReadAsc = 'LEADS_DISTINCT_COUNT_READ_ASC',
  LeadsDistinctCountReadDesc = 'LEADS_DISTINCT_COUNT_READ_DESC',
  LeadsDistinctCountExternalIdsAsc = 'LEADS_DISTINCT_COUNT_EXTERNAL_IDS_ASC',
  LeadsDistinctCountExternalIdsDesc = 'LEADS_DISTINCT_COUNT_EXTERNAL_IDS_DESC',
  LeadsDistinctCountMetaAsc = 'LEADS_DISTINCT_COUNT_META_ASC',
  LeadsDistinctCountMetaDesc = 'LEADS_DISTINCT_COUNT_META_DESC',
  LeadsDistinctCountAdditionalInformationAsc = 'LEADS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_ASC',
  LeadsDistinctCountAdditionalInformationDesc = 'LEADS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_DESC',
  LeadsDistinctCountPhoneVerifiedAtAsc = 'LEADS_DISTINCT_COUNT_PHONE_VERIFIED_AT_ASC',
  LeadsDistinctCountPhoneVerifiedAtDesc = 'LEADS_DISTINCT_COUNT_PHONE_VERIFIED_AT_DESC',
  LeadsDistinctCountIpAddressAsc = 'LEADS_DISTINCT_COUNT_IP_ADDRESS_ASC',
  LeadsDistinctCountIpAddressDesc = 'LEADS_DISTINCT_COUNT_IP_ADDRESS_DESC',
  LeadsDistinctCountClientIdAsc = 'LEADS_DISTINCT_COUNT_CLIENT_ID_ASC',
  LeadsDistinctCountClientIdDesc = 'LEADS_DISTINCT_COUNT_CLIENT_ID_DESC',
  LeadsDistinctCountDeletedAsc = 'LEADS_DISTINCT_COUNT_DELETED_ASC',
  LeadsDistinctCountDeletedDesc = 'LEADS_DISTINCT_COUNT_DELETED_DESC',
  LeadsDistinctCountPriorityAsc = 'LEADS_DISTINCT_COUNT_PRIORITY_ASC',
  LeadsDistinctCountPriorityDesc = 'LEADS_DISTINCT_COUNT_PRIORITY_DESC',
  LeadsDistinctCountAssigneeAsc = 'LEADS_DISTINCT_COUNT_ASSIGNEE_ASC',
  LeadsDistinctCountAssigneeDesc = 'LEADS_DISTINCT_COUNT_ASSIGNEE_DESC',
  LeadsDistinctCountShortIdAsc = 'LEADS_DISTINCT_COUNT_SHORT_ID_ASC',
  LeadsDistinctCountShortIdDesc = 'LEADS_DISTINCT_COUNT_SHORT_ID_DESC',
  LeadsDistinctCountCompanyChannelIdAsc = 'LEADS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  LeadsDistinctCountCompanyChannelIdDesc = 'LEADS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  LeadsDistinctCountNeighborhoodViewIdAsc = 'LEADS_DISTINCT_COUNT_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsDistinctCountNeighborhoodViewIdDesc = 'LEADS_DISTINCT_COUNT_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsDistinctCountMaritalStatusAsc = 'LEADS_DISTINCT_COUNT_MARITAL_STATUS_ASC',
  LeadsDistinctCountMaritalStatusDesc = 'LEADS_DISTINCT_COUNT_MARITAL_STATUS_DESC',
  LeadsDistinctCountDesiredVehicleAsc = 'LEADS_DISTINCT_COUNT_DESIRED_VEHICLE_ASC',
  LeadsDistinctCountDesiredVehicleDesc = 'LEADS_DISTINCT_COUNT_DESIRED_VEHICLE_DESC',
  LeadsDistinctCountJobTitleAsc = 'LEADS_DISTINCT_COUNT_JOB_TITLE_ASC',
  LeadsDistinctCountJobTitleDesc = 'LEADS_DISTINCT_COUNT_JOB_TITLE_DESC',
  LeadsDistinctCountIncomePeriodAsc = 'LEADS_DISTINCT_COUNT_INCOME_PERIOD_ASC',
  LeadsDistinctCountIncomePeriodDesc = 'LEADS_DISTINCT_COUNT_INCOME_PERIOD_DESC',
  LeadsDistinctCountSalaryHourlyAsc = 'LEADS_DISTINCT_COUNT_SALARY_HOURLY_ASC',
  LeadsDistinctCountSalaryHourlyDesc = 'LEADS_DISTINCT_COUNT_SALARY_HOURLY_DESC',
  LeadsDistinctCountIncomeSourceAsc = 'LEADS_DISTINCT_COUNT_INCOME_SOURCE_ASC',
  LeadsDistinctCountIncomeSourceDesc = 'LEADS_DISTINCT_COUNT_INCOME_SOURCE_DESC',
  LeadsDistinctCountIncomeTimeAsc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_ASC',
  LeadsDistinctCountIncomeTimeDesc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_DESC',
  LeadsDistinctCountPreferredContactMethodAsc = 'LEADS_DISTINCT_COUNT_PREFERRED_CONTACT_METHOD_ASC',
  LeadsDistinctCountPreferredContactMethodDesc = 'LEADS_DISTINCT_COUNT_PREFERRED_CONTACT_METHOD_DESC',
  LeadsDistinctCountCompanyNameAsc = 'LEADS_DISTINCT_COUNT_COMPANY_NAME_ASC',
  LeadsDistinctCountCompanyNameDesc = 'LEADS_DISTINCT_COUNT_COMPANY_NAME_DESC',
  LeadsDistinctCountCreditConcernAsc = 'LEADS_DISTINCT_COUNT_CREDIT_CONCERN_ASC',
  LeadsDistinctCountCreditConcernDesc = 'LEADS_DISTINCT_COUNT_CREDIT_CONCERN_DESC',
  LeadsDistinctCountResidenceTimeAsc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_ASC',
  LeadsDistinctCountResidenceTimeDesc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_DESC',
  LeadsDistinctCountRentOrOwnAsc = 'LEADS_DISTINCT_COUNT_RENT_OR_OWN_ASC',
  LeadsDistinctCountRentOrOwnDesc = 'LEADS_DISTINCT_COUNT_RENT_OR_OWN_DESC',
  LeadsDistinctCountEmploymentStatusAsc = 'LEADS_DISTINCT_COUNT_EMPLOYMENT_STATUS_ASC',
  LeadsDistinctCountEmploymentStatusDesc = 'LEADS_DISTINCT_COUNT_EMPLOYMENT_STATUS_DESC',
  LeadsDistinctCountIncomeAmountAsc = 'LEADS_DISTINCT_COUNT_INCOME_AMOUNT_ASC',
  LeadsDistinctCountIncomeAmountDesc = 'LEADS_DISTINCT_COUNT_INCOME_AMOUNT_DESC',
  LeadsDistinctCountMonthlyHomePaymentAsc = 'LEADS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_ASC',
  LeadsDistinctCountMonthlyHomePaymentDesc = 'LEADS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_DESC',
  LeadsDistinctCountSmsConsentAsc = 'LEADS_DISTINCT_COUNT_SMS_CONSENT_ASC',
  LeadsDistinctCountSmsConsentDesc = 'LEADS_DISTINCT_COUNT_SMS_CONSENT_DESC',
  LeadsDistinctCountIncomeTimeYearsAsc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_YEARS_ASC',
  LeadsDistinctCountIncomeTimeYearsDesc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_YEARS_DESC',
  LeadsDistinctCountIncomeTimeMonthsAsc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_MONTHS_ASC',
  LeadsDistinctCountIncomeTimeMonthsDesc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_MONTHS_DESC',
  LeadsDistinctCountResidenceTimeYearsAsc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_YEARS_ASC',
  LeadsDistinctCountResidenceTimeYearsDesc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_YEARS_DESC',
  LeadsDistinctCountResidenceTimeMonthsAsc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_MONTHS_ASC',
  LeadsDistinctCountResidenceTimeMonthsDesc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_MONTHS_DESC',
  LeadsMinIdAsc = 'LEADS_MIN_ID_ASC',
  LeadsMinIdDesc = 'LEADS_MIN_ID_DESC',
  LeadsMinCompanyIdAsc = 'LEADS_MIN_COMPANY_ID_ASC',
  LeadsMinCompanyIdDesc = 'LEADS_MIN_COMPANY_ID_DESC',
  LeadsMinCreatedAtAsc = 'LEADS_MIN_CREATED_AT_ASC',
  LeadsMinCreatedAtDesc = 'LEADS_MIN_CREATED_AT_DESC',
  LeadsMinFirstNameAsc = 'LEADS_MIN_FIRST_NAME_ASC',
  LeadsMinFirstNameDesc = 'LEADS_MIN_FIRST_NAME_DESC',
  LeadsMinLastNameAsc = 'LEADS_MIN_LAST_NAME_ASC',
  LeadsMinLastNameDesc = 'LEADS_MIN_LAST_NAME_DESC',
  LeadsMinDateOfBirthAsc = 'LEADS_MIN_DATE_OF_BIRTH_ASC',
  LeadsMinDateOfBirthDesc = 'LEADS_MIN_DATE_OF_BIRTH_DESC',
  LeadsMinStreetAsc = 'LEADS_MIN_STREET_ASC',
  LeadsMinStreetDesc = 'LEADS_MIN_STREET_DESC',
  LeadsMinStreetNumberAsc = 'LEADS_MIN_STREET_NUMBER_ASC',
  LeadsMinStreetNumberDesc = 'LEADS_MIN_STREET_NUMBER_DESC',
  LeadsMinUnitAsc = 'LEADS_MIN_UNIT_ASC',
  LeadsMinUnitDesc = 'LEADS_MIN_UNIT_DESC',
  LeadsMinCityAsc = 'LEADS_MIN_CITY_ASC',
  LeadsMinCityDesc = 'LEADS_MIN_CITY_DESC',
  LeadsMinCountryAsc = 'LEADS_MIN_COUNTRY_ASC',
  LeadsMinCountryDesc = 'LEADS_MIN_COUNTRY_DESC',
  LeadsMinPostalCodeAsc = 'LEADS_MIN_POSTAL_CODE_ASC',
  LeadsMinPostalCodeDesc = 'LEADS_MIN_POSTAL_CODE_DESC',
  LeadsMinProvinceCodeAsc = 'LEADS_MIN_PROVINCE_CODE_ASC',
  LeadsMinProvinceCodeDesc = 'LEADS_MIN_PROVINCE_CODE_DESC',
  LeadsMinEmailAddressAsc = 'LEADS_MIN_EMAIL_ADDRESS_ASC',
  LeadsMinEmailAddressDesc = 'LEADS_MIN_EMAIL_ADDRESS_DESC',
  LeadsMinPhoneNumberAsc = 'LEADS_MIN_PHONE_NUMBER_ASC',
  LeadsMinPhoneNumberDesc = 'LEADS_MIN_PHONE_NUMBER_DESC',
  LeadsMinSourceUrlAsc = 'LEADS_MIN_SOURCE_URL_ASC',
  LeadsMinSourceUrlDesc = 'LEADS_MIN_SOURCE_URL_DESC',
  LeadsMinTransactionKeyAsc = 'LEADS_MIN_TRANSACTION_KEY_ASC',
  LeadsMinTransactionKeyDesc = 'LEADS_MIN_TRANSACTION_KEY_DESC',
  LeadsMinLeadspediaIdAsc = 'LEADS_MIN_LEADSPEDIA_ID_ASC',
  LeadsMinLeadspediaIdDesc = 'LEADS_MIN_LEADSPEDIA_ID_DESC',
  LeadsMinUpdatedAtAsc = 'LEADS_MIN_UPDATED_AT_ASC',
  LeadsMinUpdatedAtDesc = 'LEADS_MIN_UPDATED_AT_DESC',
  LeadsMinSearchTextAsc = 'LEADS_MIN_SEARCH_TEXT_ASC',
  LeadsMinSearchTextDesc = 'LEADS_MIN_SEARCH_TEXT_DESC',
  LeadsMinCommentAsc = 'LEADS_MIN_COMMENT_ASC',
  LeadsMinCommentDesc = 'LEADS_MIN_COMMENT_DESC',
  LeadsMinStateAsc = 'LEADS_MIN_STATE_ASC',
  LeadsMinStateDesc = 'LEADS_MIN_STATE_DESC',
  LeadsMinReadAsc = 'LEADS_MIN_READ_ASC',
  LeadsMinReadDesc = 'LEADS_MIN_READ_DESC',
  LeadsMinExternalIdsAsc = 'LEADS_MIN_EXTERNAL_IDS_ASC',
  LeadsMinExternalIdsDesc = 'LEADS_MIN_EXTERNAL_IDS_DESC',
  LeadsMinMetaAsc = 'LEADS_MIN_META_ASC',
  LeadsMinMetaDesc = 'LEADS_MIN_META_DESC',
  LeadsMinAdditionalInformationAsc = 'LEADS_MIN_ADDITIONAL_INFORMATION_ASC',
  LeadsMinAdditionalInformationDesc = 'LEADS_MIN_ADDITIONAL_INFORMATION_DESC',
  LeadsMinPhoneVerifiedAtAsc = 'LEADS_MIN_PHONE_VERIFIED_AT_ASC',
  LeadsMinPhoneVerifiedAtDesc = 'LEADS_MIN_PHONE_VERIFIED_AT_DESC',
  LeadsMinIpAddressAsc = 'LEADS_MIN_IP_ADDRESS_ASC',
  LeadsMinIpAddressDesc = 'LEADS_MIN_IP_ADDRESS_DESC',
  LeadsMinClientIdAsc = 'LEADS_MIN_CLIENT_ID_ASC',
  LeadsMinClientIdDesc = 'LEADS_MIN_CLIENT_ID_DESC',
  LeadsMinDeletedAsc = 'LEADS_MIN_DELETED_ASC',
  LeadsMinDeletedDesc = 'LEADS_MIN_DELETED_DESC',
  LeadsMinPriorityAsc = 'LEADS_MIN_PRIORITY_ASC',
  LeadsMinPriorityDesc = 'LEADS_MIN_PRIORITY_DESC',
  LeadsMinAssigneeAsc = 'LEADS_MIN_ASSIGNEE_ASC',
  LeadsMinAssigneeDesc = 'LEADS_MIN_ASSIGNEE_DESC',
  LeadsMinShortIdAsc = 'LEADS_MIN_SHORT_ID_ASC',
  LeadsMinShortIdDesc = 'LEADS_MIN_SHORT_ID_DESC',
  LeadsMinCompanyChannelIdAsc = 'LEADS_MIN_COMPANY_CHANNEL_ID_ASC',
  LeadsMinCompanyChannelIdDesc = 'LEADS_MIN_COMPANY_CHANNEL_ID_DESC',
  LeadsMinNeighborhoodViewIdAsc = 'LEADS_MIN_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsMinNeighborhoodViewIdDesc = 'LEADS_MIN_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsMinMaritalStatusAsc = 'LEADS_MIN_MARITAL_STATUS_ASC',
  LeadsMinMaritalStatusDesc = 'LEADS_MIN_MARITAL_STATUS_DESC',
  LeadsMinDesiredVehicleAsc = 'LEADS_MIN_DESIRED_VEHICLE_ASC',
  LeadsMinDesiredVehicleDesc = 'LEADS_MIN_DESIRED_VEHICLE_DESC',
  LeadsMinJobTitleAsc = 'LEADS_MIN_JOB_TITLE_ASC',
  LeadsMinJobTitleDesc = 'LEADS_MIN_JOB_TITLE_DESC',
  LeadsMinIncomePeriodAsc = 'LEADS_MIN_INCOME_PERIOD_ASC',
  LeadsMinIncomePeriodDesc = 'LEADS_MIN_INCOME_PERIOD_DESC',
  LeadsMinSalaryHourlyAsc = 'LEADS_MIN_SALARY_HOURLY_ASC',
  LeadsMinSalaryHourlyDesc = 'LEADS_MIN_SALARY_HOURLY_DESC',
  LeadsMinIncomeSourceAsc = 'LEADS_MIN_INCOME_SOURCE_ASC',
  LeadsMinIncomeSourceDesc = 'LEADS_MIN_INCOME_SOURCE_DESC',
  LeadsMinIncomeTimeAsc = 'LEADS_MIN_INCOME_TIME_ASC',
  LeadsMinIncomeTimeDesc = 'LEADS_MIN_INCOME_TIME_DESC',
  LeadsMinPreferredContactMethodAsc = 'LEADS_MIN_PREFERRED_CONTACT_METHOD_ASC',
  LeadsMinPreferredContactMethodDesc = 'LEADS_MIN_PREFERRED_CONTACT_METHOD_DESC',
  LeadsMinCompanyNameAsc = 'LEADS_MIN_COMPANY_NAME_ASC',
  LeadsMinCompanyNameDesc = 'LEADS_MIN_COMPANY_NAME_DESC',
  LeadsMinCreditConcernAsc = 'LEADS_MIN_CREDIT_CONCERN_ASC',
  LeadsMinCreditConcernDesc = 'LEADS_MIN_CREDIT_CONCERN_DESC',
  LeadsMinResidenceTimeAsc = 'LEADS_MIN_RESIDENCE_TIME_ASC',
  LeadsMinResidenceTimeDesc = 'LEADS_MIN_RESIDENCE_TIME_DESC',
  LeadsMinRentOrOwnAsc = 'LEADS_MIN_RENT_OR_OWN_ASC',
  LeadsMinRentOrOwnDesc = 'LEADS_MIN_RENT_OR_OWN_DESC',
  LeadsMinEmploymentStatusAsc = 'LEADS_MIN_EMPLOYMENT_STATUS_ASC',
  LeadsMinEmploymentStatusDesc = 'LEADS_MIN_EMPLOYMENT_STATUS_DESC',
  LeadsMinIncomeAmountAsc = 'LEADS_MIN_INCOME_AMOUNT_ASC',
  LeadsMinIncomeAmountDesc = 'LEADS_MIN_INCOME_AMOUNT_DESC',
  LeadsMinMonthlyHomePaymentAsc = 'LEADS_MIN_MONTHLY_HOME_PAYMENT_ASC',
  LeadsMinMonthlyHomePaymentDesc = 'LEADS_MIN_MONTHLY_HOME_PAYMENT_DESC',
  LeadsMinSmsConsentAsc = 'LEADS_MIN_SMS_CONSENT_ASC',
  LeadsMinSmsConsentDesc = 'LEADS_MIN_SMS_CONSENT_DESC',
  LeadsMinIncomeTimeYearsAsc = 'LEADS_MIN_INCOME_TIME_YEARS_ASC',
  LeadsMinIncomeTimeYearsDesc = 'LEADS_MIN_INCOME_TIME_YEARS_DESC',
  LeadsMinIncomeTimeMonthsAsc = 'LEADS_MIN_INCOME_TIME_MONTHS_ASC',
  LeadsMinIncomeTimeMonthsDesc = 'LEADS_MIN_INCOME_TIME_MONTHS_DESC',
  LeadsMinResidenceTimeYearsAsc = 'LEADS_MIN_RESIDENCE_TIME_YEARS_ASC',
  LeadsMinResidenceTimeYearsDesc = 'LEADS_MIN_RESIDENCE_TIME_YEARS_DESC',
  LeadsMinResidenceTimeMonthsAsc = 'LEADS_MIN_RESIDENCE_TIME_MONTHS_ASC',
  LeadsMinResidenceTimeMonthsDesc = 'LEADS_MIN_RESIDENCE_TIME_MONTHS_DESC',
  LeadsMaxIdAsc = 'LEADS_MAX_ID_ASC',
  LeadsMaxIdDesc = 'LEADS_MAX_ID_DESC',
  LeadsMaxCompanyIdAsc = 'LEADS_MAX_COMPANY_ID_ASC',
  LeadsMaxCompanyIdDesc = 'LEADS_MAX_COMPANY_ID_DESC',
  LeadsMaxCreatedAtAsc = 'LEADS_MAX_CREATED_AT_ASC',
  LeadsMaxCreatedAtDesc = 'LEADS_MAX_CREATED_AT_DESC',
  LeadsMaxFirstNameAsc = 'LEADS_MAX_FIRST_NAME_ASC',
  LeadsMaxFirstNameDesc = 'LEADS_MAX_FIRST_NAME_DESC',
  LeadsMaxLastNameAsc = 'LEADS_MAX_LAST_NAME_ASC',
  LeadsMaxLastNameDesc = 'LEADS_MAX_LAST_NAME_DESC',
  LeadsMaxDateOfBirthAsc = 'LEADS_MAX_DATE_OF_BIRTH_ASC',
  LeadsMaxDateOfBirthDesc = 'LEADS_MAX_DATE_OF_BIRTH_DESC',
  LeadsMaxStreetAsc = 'LEADS_MAX_STREET_ASC',
  LeadsMaxStreetDesc = 'LEADS_MAX_STREET_DESC',
  LeadsMaxStreetNumberAsc = 'LEADS_MAX_STREET_NUMBER_ASC',
  LeadsMaxStreetNumberDesc = 'LEADS_MAX_STREET_NUMBER_DESC',
  LeadsMaxUnitAsc = 'LEADS_MAX_UNIT_ASC',
  LeadsMaxUnitDesc = 'LEADS_MAX_UNIT_DESC',
  LeadsMaxCityAsc = 'LEADS_MAX_CITY_ASC',
  LeadsMaxCityDesc = 'LEADS_MAX_CITY_DESC',
  LeadsMaxCountryAsc = 'LEADS_MAX_COUNTRY_ASC',
  LeadsMaxCountryDesc = 'LEADS_MAX_COUNTRY_DESC',
  LeadsMaxPostalCodeAsc = 'LEADS_MAX_POSTAL_CODE_ASC',
  LeadsMaxPostalCodeDesc = 'LEADS_MAX_POSTAL_CODE_DESC',
  LeadsMaxProvinceCodeAsc = 'LEADS_MAX_PROVINCE_CODE_ASC',
  LeadsMaxProvinceCodeDesc = 'LEADS_MAX_PROVINCE_CODE_DESC',
  LeadsMaxEmailAddressAsc = 'LEADS_MAX_EMAIL_ADDRESS_ASC',
  LeadsMaxEmailAddressDesc = 'LEADS_MAX_EMAIL_ADDRESS_DESC',
  LeadsMaxPhoneNumberAsc = 'LEADS_MAX_PHONE_NUMBER_ASC',
  LeadsMaxPhoneNumberDesc = 'LEADS_MAX_PHONE_NUMBER_DESC',
  LeadsMaxSourceUrlAsc = 'LEADS_MAX_SOURCE_URL_ASC',
  LeadsMaxSourceUrlDesc = 'LEADS_MAX_SOURCE_URL_DESC',
  LeadsMaxTransactionKeyAsc = 'LEADS_MAX_TRANSACTION_KEY_ASC',
  LeadsMaxTransactionKeyDesc = 'LEADS_MAX_TRANSACTION_KEY_DESC',
  LeadsMaxLeadspediaIdAsc = 'LEADS_MAX_LEADSPEDIA_ID_ASC',
  LeadsMaxLeadspediaIdDesc = 'LEADS_MAX_LEADSPEDIA_ID_DESC',
  LeadsMaxUpdatedAtAsc = 'LEADS_MAX_UPDATED_AT_ASC',
  LeadsMaxUpdatedAtDesc = 'LEADS_MAX_UPDATED_AT_DESC',
  LeadsMaxSearchTextAsc = 'LEADS_MAX_SEARCH_TEXT_ASC',
  LeadsMaxSearchTextDesc = 'LEADS_MAX_SEARCH_TEXT_DESC',
  LeadsMaxCommentAsc = 'LEADS_MAX_COMMENT_ASC',
  LeadsMaxCommentDesc = 'LEADS_MAX_COMMENT_DESC',
  LeadsMaxStateAsc = 'LEADS_MAX_STATE_ASC',
  LeadsMaxStateDesc = 'LEADS_MAX_STATE_DESC',
  LeadsMaxReadAsc = 'LEADS_MAX_READ_ASC',
  LeadsMaxReadDesc = 'LEADS_MAX_READ_DESC',
  LeadsMaxExternalIdsAsc = 'LEADS_MAX_EXTERNAL_IDS_ASC',
  LeadsMaxExternalIdsDesc = 'LEADS_MAX_EXTERNAL_IDS_DESC',
  LeadsMaxMetaAsc = 'LEADS_MAX_META_ASC',
  LeadsMaxMetaDesc = 'LEADS_MAX_META_DESC',
  LeadsMaxAdditionalInformationAsc = 'LEADS_MAX_ADDITIONAL_INFORMATION_ASC',
  LeadsMaxAdditionalInformationDesc = 'LEADS_MAX_ADDITIONAL_INFORMATION_DESC',
  LeadsMaxPhoneVerifiedAtAsc = 'LEADS_MAX_PHONE_VERIFIED_AT_ASC',
  LeadsMaxPhoneVerifiedAtDesc = 'LEADS_MAX_PHONE_VERIFIED_AT_DESC',
  LeadsMaxIpAddressAsc = 'LEADS_MAX_IP_ADDRESS_ASC',
  LeadsMaxIpAddressDesc = 'LEADS_MAX_IP_ADDRESS_DESC',
  LeadsMaxClientIdAsc = 'LEADS_MAX_CLIENT_ID_ASC',
  LeadsMaxClientIdDesc = 'LEADS_MAX_CLIENT_ID_DESC',
  LeadsMaxDeletedAsc = 'LEADS_MAX_DELETED_ASC',
  LeadsMaxDeletedDesc = 'LEADS_MAX_DELETED_DESC',
  LeadsMaxPriorityAsc = 'LEADS_MAX_PRIORITY_ASC',
  LeadsMaxPriorityDesc = 'LEADS_MAX_PRIORITY_DESC',
  LeadsMaxAssigneeAsc = 'LEADS_MAX_ASSIGNEE_ASC',
  LeadsMaxAssigneeDesc = 'LEADS_MAX_ASSIGNEE_DESC',
  LeadsMaxShortIdAsc = 'LEADS_MAX_SHORT_ID_ASC',
  LeadsMaxShortIdDesc = 'LEADS_MAX_SHORT_ID_DESC',
  LeadsMaxCompanyChannelIdAsc = 'LEADS_MAX_COMPANY_CHANNEL_ID_ASC',
  LeadsMaxCompanyChannelIdDesc = 'LEADS_MAX_COMPANY_CHANNEL_ID_DESC',
  LeadsMaxNeighborhoodViewIdAsc = 'LEADS_MAX_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsMaxNeighborhoodViewIdDesc = 'LEADS_MAX_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsMaxMaritalStatusAsc = 'LEADS_MAX_MARITAL_STATUS_ASC',
  LeadsMaxMaritalStatusDesc = 'LEADS_MAX_MARITAL_STATUS_DESC',
  LeadsMaxDesiredVehicleAsc = 'LEADS_MAX_DESIRED_VEHICLE_ASC',
  LeadsMaxDesiredVehicleDesc = 'LEADS_MAX_DESIRED_VEHICLE_DESC',
  LeadsMaxJobTitleAsc = 'LEADS_MAX_JOB_TITLE_ASC',
  LeadsMaxJobTitleDesc = 'LEADS_MAX_JOB_TITLE_DESC',
  LeadsMaxIncomePeriodAsc = 'LEADS_MAX_INCOME_PERIOD_ASC',
  LeadsMaxIncomePeriodDesc = 'LEADS_MAX_INCOME_PERIOD_DESC',
  LeadsMaxSalaryHourlyAsc = 'LEADS_MAX_SALARY_HOURLY_ASC',
  LeadsMaxSalaryHourlyDesc = 'LEADS_MAX_SALARY_HOURLY_DESC',
  LeadsMaxIncomeSourceAsc = 'LEADS_MAX_INCOME_SOURCE_ASC',
  LeadsMaxIncomeSourceDesc = 'LEADS_MAX_INCOME_SOURCE_DESC',
  LeadsMaxIncomeTimeAsc = 'LEADS_MAX_INCOME_TIME_ASC',
  LeadsMaxIncomeTimeDesc = 'LEADS_MAX_INCOME_TIME_DESC',
  LeadsMaxPreferredContactMethodAsc = 'LEADS_MAX_PREFERRED_CONTACT_METHOD_ASC',
  LeadsMaxPreferredContactMethodDesc = 'LEADS_MAX_PREFERRED_CONTACT_METHOD_DESC',
  LeadsMaxCompanyNameAsc = 'LEADS_MAX_COMPANY_NAME_ASC',
  LeadsMaxCompanyNameDesc = 'LEADS_MAX_COMPANY_NAME_DESC',
  LeadsMaxCreditConcernAsc = 'LEADS_MAX_CREDIT_CONCERN_ASC',
  LeadsMaxCreditConcernDesc = 'LEADS_MAX_CREDIT_CONCERN_DESC',
  LeadsMaxResidenceTimeAsc = 'LEADS_MAX_RESIDENCE_TIME_ASC',
  LeadsMaxResidenceTimeDesc = 'LEADS_MAX_RESIDENCE_TIME_DESC',
  LeadsMaxRentOrOwnAsc = 'LEADS_MAX_RENT_OR_OWN_ASC',
  LeadsMaxRentOrOwnDesc = 'LEADS_MAX_RENT_OR_OWN_DESC',
  LeadsMaxEmploymentStatusAsc = 'LEADS_MAX_EMPLOYMENT_STATUS_ASC',
  LeadsMaxEmploymentStatusDesc = 'LEADS_MAX_EMPLOYMENT_STATUS_DESC',
  LeadsMaxIncomeAmountAsc = 'LEADS_MAX_INCOME_AMOUNT_ASC',
  LeadsMaxIncomeAmountDesc = 'LEADS_MAX_INCOME_AMOUNT_DESC',
  LeadsMaxMonthlyHomePaymentAsc = 'LEADS_MAX_MONTHLY_HOME_PAYMENT_ASC',
  LeadsMaxMonthlyHomePaymentDesc = 'LEADS_MAX_MONTHLY_HOME_PAYMENT_DESC',
  LeadsMaxSmsConsentAsc = 'LEADS_MAX_SMS_CONSENT_ASC',
  LeadsMaxSmsConsentDesc = 'LEADS_MAX_SMS_CONSENT_DESC',
  LeadsMaxIncomeTimeYearsAsc = 'LEADS_MAX_INCOME_TIME_YEARS_ASC',
  LeadsMaxIncomeTimeYearsDesc = 'LEADS_MAX_INCOME_TIME_YEARS_DESC',
  LeadsMaxIncomeTimeMonthsAsc = 'LEADS_MAX_INCOME_TIME_MONTHS_ASC',
  LeadsMaxIncomeTimeMonthsDesc = 'LEADS_MAX_INCOME_TIME_MONTHS_DESC',
  LeadsMaxResidenceTimeYearsAsc = 'LEADS_MAX_RESIDENCE_TIME_YEARS_ASC',
  LeadsMaxResidenceTimeYearsDesc = 'LEADS_MAX_RESIDENCE_TIME_YEARS_DESC',
  LeadsMaxResidenceTimeMonthsAsc = 'LEADS_MAX_RESIDENCE_TIME_MONTHS_ASC',
  LeadsMaxResidenceTimeMonthsDesc = 'LEADS_MAX_RESIDENCE_TIME_MONTHS_DESC',
  LeadsAverageIdAsc = 'LEADS_AVERAGE_ID_ASC',
  LeadsAverageIdDesc = 'LEADS_AVERAGE_ID_DESC',
  LeadsAverageCompanyIdAsc = 'LEADS_AVERAGE_COMPANY_ID_ASC',
  LeadsAverageCompanyIdDesc = 'LEADS_AVERAGE_COMPANY_ID_DESC',
  LeadsAverageCreatedAtAsc = 'LEADS_AVERAGE_CREATED_AT_ASC',
  LeadsAverageCreatedAtDesc = 'LEADS_AVERAGE_CREATED_AT_DESC',
  LeadsAverageFirstNameAsc = 'LEADS_AVERAGE_FIRST_NAME_ASC',
  LeadsAverageFirstNameDesc = 'LEADS_AVERAGE_FIRST_NAME_DESC',
  LeadsAverageLastNameAsc = 'LEADS_AVERAGE_LAST_NAME_ASC',
  LeadsAverageLastNameDesc = 'LEADS_AVERAGE_LAST_NAME_DESC',
  LeadsAverageDateOfBirthAsc = 'LEADS_AVERAGE_DATE_OF_BIRTH_ASC',
  LeadsAverageDateOfBirthDesc = 'LEADS_AVERAGE_DATE_OF_BIRTH_DESC',
  LeadsAverageStreetAsc = 'LEADS_AVERAGE_STREET_ASC',
  LeadsAverageStreetDesc = 'LEADS_AVERAGE_STREET_DESC',
  LeadsAverageStreetNumberAsc = 'LEADS_AVERAGE_STREET_NUMBER_ASC',
  LeadsAverageStreetNumberDesc = 'LEADS_AVERAGE_STREET_NUMBER_DESC',
  LeadsAverageUnitAsc = 'LEADS_AVERAGE_UNIT_ASC',
  LeadsAverageUnitDesc = 'LEADS_AVERAGE_UNIT_DESC',
  LeadsAverageCityAsc = 'LEADS_AVERAGE_CITY_ASC',
  LeadsAverageCityDesc = 'LEADS_AVERAGE_CITY_DESC',
  LeadsAverageCountryAsc = 'LEADS_AVERAGE_COUNTRY_ASC',
  LeadsAverageCountryDesc = 'LEADS_AVERAGE_COUNTRY_DESC',
  LeadsAveragePostalCodeAsc = 'LEADS_AVERAGE_POSTAL_CODE_ASC',
  LeadsAveragePostalCodeDesc = 'LEADS_AVERAGE_POSTAL_CODE_DESC',
  LeadsAverageProvinceCodeAsc = 'LEADS_AVERAGE_PROVINCE_CODE_ASC',
  LeadsAverageProvinceCodeDesc = 'LEADS_AVERAGE_PROVINCE_CODE_DESC',
  LeadsAverageEmailAddressAsc = 'LEADS_AVERAGE_EMAIL_ADDRESS_ASC',
  LeadsAverageEmailAddressDesc = 'LEADS_AVERAGE_EMAIL_ADDRESS_DESC',
  LeadsAveragePhoneNumberAsc = 'LEADS_AVERAGE_PHONE_NUMBER_ASC',
  LeadsAveragePhoneNumberDesc = 'LEADS_AVERAGE_PHONE_NUMBER_DESC',
  LeadsAverageSourceUrlAsc = 'LEADS_AVERAGE_SOURCE_URL_ASC',
  LeadsAverageSourceUrlDesc = 'LEADS_AVERAGE_SOURCE_URL_DESC',
  LeadsAverageTransactionKeyAsc = 'LEADS_AVERAGE_TRANSACTION_KEY_ASC',
  LeadsAverageTransactionKeyDesc = 'LEADS_AVERAGE_TRANSACTION_KEY_DESC',
  LeadsAverageLeadspediaIdAsc = 'LEADS_AVERAGE_LEADSPEDIA_ID_ASC',
  LeadsAverageLeadspediaIdDesc = 'LEADS_AVERAGE_LEADSPEDIA_ID_DESC',
  LeadsAverageUpdatedAtAsc = 'LEADS_AVERAGE_UPDATED_AT_ASC',
  LeadsAverageUpdatedAtDesc = 'LEADS_AVERAGE_UPDATED_AT_DESC',
  LeadsAverageSearchTextAsc = 'LEADS_AVERAGE_SEARCH_TEXT_ASC',
  LeadsAverageSearchTextDesc = 'LEADS_AVERAGE_SEARCH_TEXT_DESC',
  LeadsAverageCommentAsc = 'LEADS_AVERAGE_COMMENT_ASC',
  LeadsAverageCommentDesc = 'LEADS_AVERAGE_COMMENT_DESC',
  LeadsAverageStateAsc = 'LEADS_AVERAGE_STATE_ASC',
  LeadsAverageStateDesc = 'LEADS_AVERAGE_STATE_DESC',
  LeadsAverageReadAsc = 'LEADS_AVERAGE_READ_ASC',
  LeadsAverageReadDesc = 'LEADS_AVERAGE_READ_DESC',
  LeadsAverageExternalIdsAsc = 'LEADS_AVERAGE_EXTERNAL_IDS_ASC',
  LeadsAverageExternalIdsDesc = 'LEADS_AVERAGE_EXTERNAL_IDS_DESC',
  LeadsAverageMetaAsc = 'LEADS_AVERAGE_META_ASC',
  LeadsAverageMetaDesc = 'LEADS_AVERAGE_META_DESC',
  LeadsAverageAdditionalInformationAsc = 'LEADS_AVERAGE_ADDITIONAL_INFORMATION_ASC',
  LeadsAverageAdditionalInformationDesc = 'LEADS_AVERAGE_ADDITIONAL_INFORMATION_DESC',
  LeadsAveragePhoneVerifiedAtAsc = 'LEADS_AVERAGE_PHONE_VERIFIED_AT_ASC',
  LeadsAveragePhoneVerifiedAtDesc = 'LEADS_AVERAGE_PHONE_VERIFIED_AT_DESC',
  LeadsAverageIpAddressAsc = 'LEADS_AVERAGE_IP_ADDRESS_ASC',
  LeadsAverageIpAddressDesc = 'LEADS_AVERAGE_IP_ADDRESS_DESC',
  LeadsAverageClientIdAsc = 'LEADS_AVERAGE_CLIENT_ID_ASC',
  LeadsAverageClientIdDesc = 'LEADS_AVERAGE_CLIENT_ID_DESC',
  LeadsAverageDeletedAsc = 'LEADS_AVERAGE_DELETED_ASC',
  LeadsAverageDeletedDesc = 'LEADS_AVERAGE_DELETED_DESC',
  LeadsAveragePriorityAsc = 'LEADS_AVERAGE_PRIORITY_ASC',
  LeadsAveragePriorityDesc = 'LEADS_AVERAGE_PRIORITY_DESC',
  LeadsAverageAssigneeAsc = 'LEADS_AVERAGE_ASSIGNEE_ASC',
  LeadsAverageAssigneeDesc = 'LEADS_AVERAGE_ASSIGNEE_DESC',
  LeadsAverageShortIdAsc = 'LEADS_AVERAGE_SHORT_ID_ASC',
  LeadsAverageShortIdDesc = 'LEADS_AVERAGE_SHORT_ID_DESC',
  LeadsAverageCompanyChannelIdAsc = 'LEADS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  LeadsAverageCompanyChannelIdDesc = 'LEADS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  LeadsAverageNeighborhoodViewIdAsc = 'LEADS_AVERAGE_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsAverageNeighborhoodViewIdDesc = 'LEADS_AVERAGE_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsAverageMaritalStatusAsc = 'LEADS_AVERAGE_MARITAL_STATUS_ASC',
  LeadsAverageMaritalStatusDesc = 'LEADS_AVERAGE_MARITAL_STATUS_DESC',
  LeadsAverageDesiredVehicleAsc = 'LEADS_AVERAGE_DESIRED_VEHICLE_ASC',
  LeadsAverageDesiredVehicleDesc = 'LEADS_AVERAGE_DESIRED_VEHICLE_DESC',
  LeadsAverageJobTitleAsc = 'LEADS_AVERAGE_JOB_TITLE_ASC',
  LeadsAverageJobTitleDesc = 'LEADS_AVERAGE_JOB_TITLE_DESC',
  LeadsAverageIncomePeriodAsc = 'LEADS_AVERAGE_INCOME_PERIOD_ASC',
  LeadsAverageIncomePeriodDesc = 'LEADS_AVERAGE_INCOME_PERIOD_DESC',
  LeadsAverageSalaryHourlyAsc = 'LEADS_AVERAGE_SALARY_HOURLY_ASC',
  LeadsAverageSalaryHourlyDesc = 'LEADS_AVERAGE_SALARY_HOURLY_DESC',
  LeadsAverageIncomeSourceAsc = 'LEADS_AVERAGE_INCOME_SOURCE_ASC',
  LeadsAverageIncomeSourceDesc = 'LEADS_AVERAGE_INCOME_SOURCE_DESC',
  LeadsAverageIncomeTimeAsc = 'LEADS_AVERAGE_INCOME_TIME_ASC',
  LeadsAverageIncomeTimeDesc = 'LEADS_AVERAGE_INCOME_TIME_DESC',
  LeadsAveragePreferredContactMethodAsc = 'LEADS_AVERAGE_PREFERRED_CONTACT_METHOD_ASC',
  LeadsAveragePreferredContactMethodDesc = 'LEADS_AVERAGE_PREFERRED_CONTACT_METHOD_DESC',
  LeadsAverageCompanyNameAsc = 'LEADS_AVERAGE_COMPANY_NAME_ASC',
  LeadsAverageCompanyNameDesc = 'LEADS_AVERAGE_COMPANY_NAME_DESC',
  LeadsAverageCreditConcernAsc = 'LEADS_AVERAGE_CREDIT_CONCERN_ASC',
  LeadsAverageCreditConcernDesc = 'LEADS_AVERAGE_CREDIT_CONCERN_DESC',
  LeadsAverageResidenceTimeAsc = 'LEADS_AVERAGE_RESIDENCE_TIME_ASC',
  LeadsAverageResidenceTimeDesc = 'LEADS_AVERAGE_RESIDENCE_TIME_DESC',
  LeadsAverageRentOrOwnAsc = 'LEADS_AVERAGE_RENT_OR_OWN_ASC',
  LeadsAverageRentOrOwnDesc = 'LEADS_AVERAGE_RENT_OR_OWN_DESC',
  LeadsAverageEmploymentStatusAsc = 'LEADS_AVERAGE_EMPLOYMENT_STATUS_ASC',
  LeadsAverageEmploymentStatusDesc = 'LEADS_AVERAGE_EMPLOYMENT_STATUS_DESC',
  LeadsAverageIncomeAmountAsc = 'LEADS_AVERAGE_INCOME_AMOUNT_ASC',
  LeadsAverageIncomeAmountDesc = 'LEADS_AVERAGE_INCOME_AMOUNT_DESC',
  LeadsAverageMonthlyHomePaymentAsc = 'LEADS_AVERAGE_MONTHLY_HOME_PAYMENT_ASC',
  LeadsAverageMonthlyHomePaymentDesc = 'LEADS_AVERAGE_MONTHLY_HOME_PAYMENT_DESC',
  LeadsAverageSmsConsentAsc = 'LEADS_AVERAGE_SMS_CONSENT_ASC',
  LeadsAverageSmsConsentDesc = 'LEADS_AVERAGE_SMS_CONSENT_DESC',
  LeadsAverageIncomeTimeYearsAsc = 'LEADS_AVERAGE_INCOME_TIME_YEARS_ASC',
  LeadsAverageIncomeTimeYearsDesc = 'LEADS_AVERAGE_INCOME_TIME_YEARS_DESC',
  LeadsAverageIncomeTimeMonthsAsc = 'LEADS_AVERAGE_INCOME_TIME_MONTHS_ASC',
  LeadsAverageIncomeTimeMonthsDesc = 'LEADS_AVERAGE_INCOME_TIME_MONTHS_DESC',
  LeadsAverageResidenceTimeYearsAsc = 'LEADS_AVERAGE_RESIDENCE_TIME_YEARS_ASC',
  LeadsAverageResidenceTimeYearsDesc = 'LEADS_AVERAGE_RESIDENCE_TIME_YEARS_DESC',
  LeadsAverageResidenceTimeMonthsAsc = 'LEADS_AVERAGE_RESIDENCE_TIME_MONTHS_ASC',
  LeadsAverageResidenceTimeMonthsDesc = 'LEADS_AVERAGE_RESIDENCE_TIME_MONTHS_DESC',
  LeadsStddevSampleIdAsc = 'LEADS_STDDEV_SAMPLE_ID_ASC',
  LeadsStddevSampleIdDesc = 'LEADS_STDDEV_SAMPLE_ID_DESC',
  LeadsStddevSampleCompanyIdAsc = 'LEADS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  LeadsStddevSampleCompanyIdDesc = 'LEADS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  LeadsStddevSampleCreatedAtAsc = 'LEADS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LeadsStddevSampleCreatedAtDesc = 'LEADS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LeadsStddevSampleFirstNameAsc = 'LEADS_STDDEV_SAMPLE_FIRST_NAME_ASC',
  LeadsStddevSampleFirstNameDesc = 'LEADS_STDDEV_SAMPLE_FIRST_NAME_DESC',
  LeadsStddevSampleLastNameAsc = 'LEADS_STDDEV_SAMPLE_LAST_NAME_ASC',
  LeadsStddevSampleLastNameDesc = 'LEADS_STDDEV_SAMPLE_LAST_NAME_DESC',
  LeadsStddevSampleDateOfBirthAsc = 'LEADS_STDDEV_SAMPLE_DATE_OF_BIRTH_ASC',
  LeadsStddevSampleDateOfBirthDesc = 'LEADS_STDDEV_SAMPLE_DATE_OF_BIRTH_DESC',
  LeadsStddevSampleStreetAsc = 'LEADS_STDDEV_SAMPLE_STREET_ASC',
  LeadsStddevSampleStreetDesc = 'LEADS_STDDEV_SAMPLE_STREET_DESC',
  LeadsStddevSampleStreetNumberAsc = 'LEADS_STDDEV_SAMPLE_STREET_NUMBER_ASC',
  LeadsStddevSampleStreetNumberDesc = 'LEADS_STDDEV_SAMPLE_STREET_NUMBER_DESC',
  LeadsStddevSampleUnitAsc = 'LEADS_STDDEV_SAMPLE_UNIT_ASC',
  LeadsStddevSampleUnitDesc = 'LEADS_STDDEV_SAMPLE_UNIT_DESC',
  LeadsStddevSampleCityAsc = 'LEADS_STDDEV_SAMPLE_CITY_ASC',
  LeadsStddevSampleCityDesc = 'LEADS_STDDEV_SAMPLE_CITY_DESC',
  LeadsStddevSampleCountryAsc = 'LEADS_STDDEV_SAMPLE_COUNTRY_ASC',
  LeadsStddevSampleCountryDesc = 'LEADS_STDDEV_SAMPLE_COUNTRY_DESC',
  LeadsStddevSamplePostalCodeAsc = 'LEADS_STDDEV_SAMPLE_POSTAL_CODE_ASC',
  LeadsStddevSamplePostalCodeDesc = 'LEADS_STDDEV_SAMPLE_POSTAL_CODE_DESC',
  LeadsStddevSampleProvinceCodeAsc = 'LEADS_STDDEV_SAMPLE_PROVINCE_CODE_ASC',
  LeadsStddevSampleProvinceCodeDesc = 'LEADS_STDDEV_SAMPLE_PROVINCE_CODE_DESC',
  LeadsStddevSampleEmailAddressAsc = 'LEADS_STDDEV_SAMPLE_EMAIL_ADDRESS_ASC',
  LeadsStddevSampleEmailAddressDesc = 'LEADS_STDDEV_SAMPLE_EMAIL_ADDRESS_DESC',
  LeadsStddevSamplePhoneNumberAsc = 'LEADS_STDDEV_SAMPLE_PHONE_NUMBER_ASC',
  LeadsStddevSamplePhoneNumberDesc = 'LEADS_STDDEV_SAMPLE_PHONE_NUMBER_DESC',
  LeadsStddevSampleSourceUrlAsc = 'LEADS_STDDEV_SAMPLE_SOURCE_URL_ASC',
  LeadsStddevSampleSourceUrlDesc = 'LEADS_STDDEV_SAMPLE_SOURCE_URL_DESC',
  LeadsStddevSampleTransactionKeyAsc = 'LEADS_STDDEV_SAMPLE_TRANSACTION_KEY_ASC',
  LeadsStddevSampleTransactionKeyDesc = 'LEADS_STDDEV_SAMPLE_TRANSACTION_KEY_DESC',
  LeadsStddevSampleLeadspediaIdAsc = 'LEADS_STDDEV_SAMPLE_LEADSPEDIA_ID_ASC',
  LeadsStddevSampleLeadspediaIdDesc = 'LEADS_STDDEV_SAMPLE_LEADSPEDIA_ID_DESC',
  LeadsStddevSampleUpdatedAtAsc = 'LEADS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LeadsStddevSampleUpdatedAtDesc = 'LEADS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LeadsStddevSampleSearchTextAsc = 'LEADS_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  LeadsStddevSampleSearchTextDesc = 'LEADS_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  LeadsStddevSampleCommentAsc = 'LEADS_STDDEV_SAMPLE_COMMENT_ASC',
  LeadsStddevSampleCommentDesc = 'LEADS_STDDEV_SAMPLE_COMMENT_DESC',
  LeadsStddevSampleStateAsc = 'LEADS_STDDEV_SAMPLE_STATE_ASC',
  LeadsStddevSampleStateDesc = 'LEADS_STDDEV_SAMPLE_STATE_DESC',
  LeadsStddevSampleReadAsc = 'LEADS_STDDEV_SAMPLE_READ_ASC',
  LeadsStddevSampleReadDesc = 'LEADS_STDDEV_SAMPLE_READ_DESC',
  LeadsStddevSampleExternalIdsAsc = 'LEADS_STDDEV_SAMPLE_EXTERNAL_IDS_ASC',
  LeadsStddevSampleExternalIdsDesc = 'LEADS_STDDEV_SAMPLE_EXTERNAL_IDS_DESC',
  LeadsStddevSampleMetaAsc = 'LEADS_STDDEV_SAMPLE_META_ASC',
  LeadsStddevSampleMetaDesc = 'LEADS_STDDEV_SAMPLE_META_DESC',
  LeadsStddevSampleAdditionalInformationAsc = 'LEADS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  LeadsStddevSampleAdditionalInformationDesc = 'LEADS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  LeadsStddevSamplePhoneVerifiedAtAsc = 'LEADS_STDDEV_SAMPLE_PHONE_VERIFIED_AT_ASC',
  LeadsStddevSamplePhoneVerifiedAtDesc = 'LEADS_STDDEV_SAMPLE_PHONE_VERIFIED_AT_DESC',
  LeadsStddevSampleIpAddressAsc = 'LEADS_STDDEV_SAMPLE_IP_ADDRESS_ASC',
  LeadsStddevSampleIpAddressDesc = 'LEADS_STDDEV_SAMPLE_IP_ADDRESS_DESC',
  LeadsStddevSampleClientIdAsc = 'LEADS_STDDEV_SAMPLE_CLIENT_ID_ASC',
  LeadsStddevSampleClientIdDesc = 'LEADS_STDDEV_SAMPLE_CLIENT_ID_DESC',
  LeadsStddevSampleDeletedAsc = 'LEADS_STDDEV_SAMPLE_DELETED_ASC',
  LeadsStddevSampleDeletedDesc = 'LEADS_STDDEV_SAMPLE_DELETED_DESC',
  LeadsStddevSamplePriorityAsc = 'LEADS_STDDEV_SAMPLE_PRIORITY_ASC',
  LeadsStddevSamplePriorityDesc = 'LEADS_STDDEV_SAMPLE_PRIORITY_DESC',
  LeadsStddevSampleAssigneeAsc = 'LEADS_STDDEV_SAMPLE_ASSIGNEE_ASC',
  LeadsStddevSampleAssigneeDesc = 'LEADS_STDDEV_SAMPLE_ASSIGNEE_DESC',
  LeadsStddevSampleShortIdAsc = 'LEADS_STDDEV_SAMPLE_SHORT_ID_ASC',
  LeadsStddevSampleShortIdDesc = 'LEADS_STDDEV_SAMPLE_SHORT_ID_DESC',
  LeadsStddevSampleCompanyChannelIdAsc = 'LEADS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  LeadsStddevSampleCompanyChannelIdDesc = 'LEADS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  LeadsStddevSampleNeighborhoodViewIdAsc = 'LEADS_STDDEV_SAMPLE_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsStddevSampleNeighborhoodViewIdDesc = 'LEADS_STDDEV_SAMPLE_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsStddevSampleMaritalStatusAsc = 'LEADS_STDDEV_SAMPLE_MARITAL_STATUS_ASC',
  LeadsStddevSampleMaritalStatusDesc = 'LEADS_STDDEV_SAMPLE_MARITAL_STATUS_DESC',
  LeadsStddevSampleDesiredVehicleAsc = 'LEADS_STDDEV_SAMPLE_DESIRED_VEHICLE_ASC',
  LeadsStddevSampleDesiredVehicleDesc = 'LEADS_STDDEV_SAMPLE_DESIRED_VEHICLE_DESC',
  LeadsStddevSampleJobTitleAsc = 'LEADS_STDDEV_SAMPLE_JOB_TITLE_ASC',
  LeadsStddevSampleJobTitleDesc = 'LEADS_STDDEV_SAMPLE_JOB_TITLE_DESC',
  LeadsStddevSampleIncomePeriodAsc = 'LEADS_STDDEV_SAMPLE_INCOME_PERIOD_ASC',
  LeadsStddevSampleIncomePeriodDesc = 'LEADS_STDDEV_SAMPLE_INCOME_PERIOD_DESC',
  LeadsStddevSampleSalaryHourlyAsc = 'LEADS_STDDEV_SAMPLE_SALARY_HOURLY_ASC',
  LeadsStddevSampleSalaryHourlyDesc = 'LEADS_STDDEV_SAMPLE_SALARY_HOURLY_DESC',
  LeadsStddevSampleIncomeSourceAsc = 'LEADS_STDDEV_SAMPLE_INCOME_SOURCE_ASC',
  LeadsStddevSampleIncomeSourceDesc = 'LEADS_STDDEV_SAMPLE_INCOME_SOURCE_DESC',
  LeadsStddevSampleIncomeTimeAsc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_ASC',
  LeadsStddevSampleIncomeTimeDesc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_DESC',
  LeadsStddevSamplePreferredContactMethodAsc = 'LEADS_STDDEV_SAMPLE_PREFERRED_CONTACT_METHOD_ASC',
  LeadsStddevSamplePreferredContactMethodDesc = 'LEADS_STDDEV_SAMPLE_PREFERRED_CONTACT_METHOD_DESC',
  LeadsStddevSampleCompanyNameAsc = 'LEADS_STDDEV_SAMPLE_COMPANY_NAME_ASC',
  LeadsStddevSampleCompanyNameDesc = 'LEADS_STDDEV_SAMPLE_COMPANY_NAME_DESC',
  LeadsStddevSampleCreditConcernAsc = 'LEADS_STDDEV_SAMPLE_CREDIT_CONCERN_ASC',
  LeadsStddevSampleCreditConcernDesc = 'LEADS_STDDEV_SAMPLE_CREDIT_CONCERN_DESC',
  LeadsStddevSampleResidenceTimeAsc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_ASC',
  LeadsStddevSampleResidenceTimeDesc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_DESC',
  LeadsStddevSampleRentOrOwnAsc = 'LEADS_STDDEV_SAMPLE_RENT_OR_OWN_ASC',
  LeadsStddevSampleRentOrOwnDesc = 'LEADS_STDDEV_SAMPLE_RENT_OR_OWN_DESC',
  LeadsStddevSampleEmploymentStatusAsc = 'LEADS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LeadsStddevSampleEmploymentStatusDesc = 'LEADS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LeadsStddevSampleIncomeAmountAsc = 'LEADS_STDDEV_SAMPLE_INCOME_AMOUNT_ASC',
  LeadsStddevSampleIncomeAmountDesc = 'LEADS_STDDEV_SAMPLE_INCOME_AMOUNT_DESC',
  LeadsStddevSampleMonthlyHomePaymentAsc = 'LEADS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LeadsStddevSampleMonthlyHomePaymentDesc = 'LEADS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LeadsStddevSampleSmsConsentAsc = 'LEADS_STDDEV_SAMPLE_SMS_CONSENT_ASC',
  LeadsStddevSampleSmsConsentDesc = 'LEADS_STDDEV_SAMPLE_SMS_CONSENT_DESC',
  LeadsStddevSampleIncomeTimeYearsAsc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_YEARS_ASC',
  LeadsStddevSampleIncomeTimeYearsDesc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_YEARS_DESC',
  LeadsStddevSampleIncomeTimeMonthsAsc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_MONTHS_ASC',
  LeadsStddevSampleIncomeTimeMonthsDesc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_MONTHS_DESC',
  LeadsStddevSampleResidenceTimeYearsAsc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_YEARS_ASC',
  LeadsStddevSampleResidenceTimeYearsDesc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_YEARS_DESC',
  LeadsStddevSampleResidenceTimeMonthsAsc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_MONTHS_ASC',
  LeadsStddevSampleResidenceTimeMonthsDesc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_MONTHS_DESC',
  LeadsStddevPopulationIdAsc = 'LEADS_STDDEV_POPULATION_ID_ASC',
  LeadsStddevPopulationIdDesc = 'LEADS_STDDEV_POPULATION_ID_DESC',
  LeadsStddevPopulationCompanyIdAsc = 'LEADS_STDDEV_POPULATION_COMPANY_ID_ASC',
  LeadsStddevPopulationCompanyIdDesc = 'LEADS_STDDEV_POPULATION_COMPANY_ID_DESC',
  LeadsStddevPopulationCreatedAtAsc = 'LEADS_STDDEV_POPULATION_CREATED_AT_ASC',
  LeadsStddevPopulationCreatedAtDesc = 'LEADS_STDDEV_POPULATION_CREATED_AT_DESC',
  LeadsStddevPopulationFirstNameAsc = 'LEADS_STDDEV_POPULATION_FIRST_NAME_ASC',
  LeadsStddevPopulationFirstNameDesc = 'LEADS_STDDEV_POPULATION_FIRST_NAME_DESC',
  LeadsStddevPopulationLastNameAsc = 'LEADS_STDDEV_POPULATION_LAST_NAME_ASC',
  LeadsStddevPopulationLastNameDesc = 'LEADS_STDDEV_POPULATION_LAST_NAME_DESC',
  LeadsStddevPopulationDateOfBirthAsc = 'LEADS_STDDEV_POPULATION_DATE_OF_BIRTH_ASC',
  LeadsStddevPopulationDateOfBirthDesc = 'LEADS_STDDEV_POPULATION_DATE_OF_BIRTH_DESC',
  LeadsStddevPopulationStreetAsc = 'LEADS_STDDEV_POPULATION_STREET_ASC',
  LeadsStddevPopulationStreetDesc = 'LEADS_STDDEV_POPULATION_STREET_DESC',
  LeadsStddevPopulationStreetNumberAsc = 'LEADS_STDDEV_POPULATION_STREET_NUMBER_ASC',
  LeadsStddevPopulationStreetNumberDesc = 'LEADS_STDDEV_POPULATION_STREET_NUMBER_DESC',
  LeadsStddevPopulationUnitAsc = 'LEADS_STDDEV_POPULATION_UNIT_ASC',
  LeadsStddevPopulationUnitDesc = 'LEADS_STDDEV_POPULATION_UNIT_DESC',
  LeadsStddevPopulationCityAsc = 'LEADS_STDDEV_POPULATION_CITY_ASC',
  LeadsStddevPopulationCityDesc = 'LEADS_STDDEV_POPULATION_CITY_DESC',
  LeadsStddevPopulationCountryAsc = 'LEADS_STDDEV_POPULATION_COUNTRY_ASC',
  LeadsStddevPopulationCountryDesc = 'LEADS_STDDEV_POPULATION_COUNTRY_DESC',
  LeadsStddevPopulationPostalCodeAsc = 'LEADS_STDDEV_POPULATION_POSTAL_CODE_ASC',
  LeadsStddevPopulationPostalCodeDesc = 'LEADS_STDDEV_POPULATION_POSTAL_CODE_DESC',
  LeadsStddevPopulationProvinceCodeAsc = 'LEADS_STDDEV_POPULATION_PROVINCE_CODE_ASC',
  LeadsStddevPopulationProvinceCodeDesc = 'LEADS_STDDEV_POPULATION_PROVINCE_CODE_DESC',
  LeadsStddevPopulationEmailAddressAsc = 'LEADS_STDDEV_POPULATION_EMAIL_ADDRESS_ASC',
  LeadsStddevPopulationEmailAddressDesc = 'LEADS_STDDEV_POPULATION_EMAIL_ADDRESS_DESC',
  LeadsStddevPopulationPhoneNumberAsc = 'LEADS_STDDEV_POPULATION_PHONE_NUMBER_ASC',
  LeadsStddevPopulationPhoneNumberDesc = 'LEADS_STDDEV_POPULATION_PHONE_NUMBER_DESC',
  LeadsStddevPopulationSourceUrlAsc = 'LEADS_STDDEV_POPULATION_SOURCE_URL_ASC',
  LeadsStddevPopulationSourceUrlDesc = 'LEADS_STDDEV_POPULATION_SOURCE_URL_DESC',
  LeadsStddevPopulationTransactionKeyAsc = 'LEADS_STDDEV_POPULATION_TRANSACTION_KEY_ASC',
  LeadsStddevPopulationTransactionKeyDesc = 'LEADS_STDDEV_POPULATION_TRANSACTION_KEY_DESC',
  LeadsStddevPopulationLeadspediaIdAsc = 'LEADS_STDDEV_POPULATION_LEADSPEDIA_ID_ASC',
  LeadsStddevPopulationLeadspediaIdDesc = 'LEADS_STDDEV_POPULATION_LEADSPEDIA_ID_DESC',
  LeadsStddevPopulationUpdatedAtAsc = 'LEADS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LeadsStddevPopulationUpdatedAtDesc = 'LEADS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LeadsStddevPopulationSearchTextAsc = 'LEADS_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  LeadsStddevPopulationSearchTextDesc = 'LEADS_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  LeadsStddevPopulationCommentAsc = 'LEADS_STDDEV_POPULATION_COMMENT_ASC',
  LeadsStddevPopulationCommentDesc = 'LEADS_STDDEV_POPULATION_COMMENT_DESC',
  LeadsStddevPopulationStateAsc = 'LEADS_STDDEV_POPULATION_STATE_ASC',
  LeadsStddevPopulationStateDesc = 'LEADS_STDDEV_POPULATION_STATE_DESC',
  LeadsStddevPopulationReadAsc = 'LEADS_STDDEV_POPULATION_READ_ASC',
  LeadsStddevPopulationReadDesc = 'LEADS_STDDEV_POPULATION_READ_DESC',
  LeadsStddevPopulationExternalIdsAsc = 'LEADS_STDDEV_POPULATION_EXTERNAL_IDS_ASC',
  LeadsStddevPopulationExternalIdsDesc = 'LEADS_STDDEV_POPULATION_EXTERNAL_IDS_DESC',
  LeadsStddevPopulationMetaAsc = 'LEADS_STDDEV_POPULATION_META_ASC',
  LeadsStddevPopulationMetaDesc = 'LEADS_STDDEV_POPULATION_META_DESC',
  LeadsStddevPopulationAdditionalInformationAsc = 'LEADS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_ASC',
  LeadsStddevPopulationAdditionalInformationDesc = 'LEADS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_DESC',
  LeadsStddevPopulationPhoneVerifiedAtAsc = 'LEADS_STDDEV_POPULATION_PHONE_VERIFIED_AT_ASC',
  LeadsStddevPopulationPhoneVerifiedAtDesc = 'LEADS_STDDEV_POPULATION_PHONE_VERIFIED_AT_DESC',
  LeadsStddevPopulationIpAddressAsc = 'LEADS_STDDEV_POPULATION_IP_ADDRESS_ASC',
  LeadsStddevPopulationIpAddressDesc = 'LEADS_STDDEV_POPULATION_IP_ADDRESS_DESC',
  LeadsStddevPopulationClientIdAsc = 'LEADS_STDDEV_POPULATION_CLIENT_ID_ASC',
  LeadsStddevPopulationClientIdDesc = 'LEADS_STDDEV_POPULATION_CLIENT_ID_DESC',
  LeadsStddevPopulationDeletedAsc = 'LEADS_STDDEV_POPULATION_DELETED_ASC',
  LeadsStddevPopulationDeletedDesc = 'LEADS_STDDEV_POPULATION_DELETED_DESC',
  LeadsStddevPopulationPriorityAsc = 'LEADS_STDDEV_POPULATION_PRIORITY_ASC',
  LeadsStddevPopulationPriorityDesc = 'LEADS_STDDEV_POPULATION_PRIORITY_DESC',
  LeadsStddevPopulationAssigneeAsc = 'LEADS_STDDEV_POPULATION_ASSIGNEE_ASC',
  LeadsStddevPopulationAssigneeDesc = 'LEADS_STDDEV_POPULATION_ASSIGNEE_DESC',
  LeadsStddevPopulationShortIdAsc = 'LEADS_STDDEV_POPULATION_SHORT_ID_ASC',
  LeadsStddevPopulationShortIdDesc = 'LEADS_STDDEV_POPULATION_SHORT_ID_DESC',
  LeadsStddevPopulationCompanyChannelIdAsc = 'LEADS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  LeadsStddevPopulationCompanyChannelIdDesc = 'LEADS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  LeadsStddevPopulationNeighborhoodViewIdAsc = 'LEADS_STDDEV_POPULATION_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsStddevPopulationNeighborhoodViewIdDesc = 'LEADS_STDDEV_POPULATION_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsStddevPopulationMaritalStatusAsc = 'LEADS_STDDEV_POPULATION_MARITAL_STATUS_ASC',
  LeadsStddevPopulationMaritalStatusDesc = 'LEADS_STDDEV_POPULATION_MARITAL_STATUS_DESC',
  LeadsStddevPopulationDesiredVehicleAsc = 'LEADS_STDDEV_POPULATION_DESIRED_VEHICLE_ASC',
  LeadsStddevPopulationDesiredVehicleDesc = 'LEADS_STDDEV_POPULATION_DESIRED_VEHICLE_DESC',
  LeadsStddevPopulationJobTitleAsc = 'LEADS_STDDEV_POPULATION_JOB_TITLE_ASC',
  LeadsStddevPopulationJobTitleDesc = 'LEADS_STDDEV_POPULATION_JOB_TITLE_DESC',
  LeadsStddevPopulationIncomePeriodAsc = 'LEADS_STDDEV_POPULATION_INCOME_PERIOD_ASC',
  LeadsStddevPopulationIncomePeriodDesc = 'LEADS_STDDEV_POPULATION_INCOME_PERIOD_DESC',
  LeadsStddevPopulationSalaryHourlyAsc = 'LEADS_STDDEV_POPULATION_SALARY_HOURLY_ASC',
  LeadsStddevPopulationSalaryHourlyDesc = 'LEADS_STDDEV_POPULATION_SALARY_HOURLY_DESC',
  LeadsStddevPopulationIncomeSourceAsc = 'LEADS_STDDEV_POPULATION_INCOME_SOURCE_ASC',
  LeadsStddevPopulationIncomeSourceDesc = 'LEADS_STDDEV_POPULATION_INCOME_SOURCE_DESC',
  LeadsStddevPopulationIncomeTimeAsc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_ASC',
  LeadsStddevPopulationIncomeTimeDesc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_DESC',
  LeadsStddevPopulationPreferredContactMethodAsc = 'LEADS_STDDEV_POPULATION_PREFERRED_CONTACT_METHOD_ASC',
  LeadsStddevPopulationPreferredContactMethodDesc = 'LEADS_STDDEV_POPULATION_PREFERRED_CONTACT_METHOD_DESC',
  LeadsStddevPopulationCompanyNameAsc = 'LEADS_STDDEV_POPULATION_COMPANY_NAME_ASC',
  LeadsStddevPopulationCompanyNameDesc = 'LEADS_STDDEV_POPULATION_COMPANY_NAME_DESC',
  LeadsStddevPopulationCreditConcernAsc = 'LEADS_STDDEV_POPULATION_CREDIT_CONCERN_ASC',
  LeadsStddevPopulationCreditConcernDesc = 'LEADS_STDDEV_POPULATION_CREDIT_CONCERN_DESC',
  LeadsStddevPopulationResidenceTimeAsc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_ASC',
  LeadsStddevPopulationResidenceTimeDesc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_DESC',
  LeadsStddevPopulationRentOrOwnAsc = 'LEADS_STDDEV_POPULATION_RENT_OR_OWN_ASC',
  LeadsStddevPopulationRentOrOwnDesc = 'LEADS_STDDEV_POPULATION_RENT_OR_OWN_DESC',
  LeadsStddevPopulationEmploymentStatusAsc = 'LEADS_STDDEV_POPULATION_EMPLOYMENT_STATUS_ASC',
  LeadsStddevPopulationEmploymentStatusDesc = 'LEADS_STDDEV_POPULATION_EMPLOYMENT_STATUS_DESC',
  LeadsStddevPopulationIncomeAmountAsc = 'LEADS_STDDEV_POPULATION_INCOME_AMOUNT_ASC',
  LeadsStddevPopulationIncomeAmountDesc = 'LEADS_STDDEV_POPULATION_INCOME_AMOUNT_DESC',
  LeadsStddevPopulationMonthlyHomePaymentAsc = 'LEADS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LeadsStddevPopulationMonthlyHomePaymentDesc = 'LEADS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LeadsStddevPopulationSmsConsentAsc = 'LEADS_STDDEV_POPULATION_SMS_CONSENT_ASC',
  LeadsStddevPopulationSmsConsentDesc = 'LEADS_STDDEV_POPULATION_SMS_CONSENT_DESC',
  LeadsStddevPopulationIncomeTimeYearsAsc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_YEARS_ASC',
  LeadsStddevPopulationIncomeTimeYearsDesc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_YEARS_DESC',
  LeadsStddevPopulationIncomeTimeMonthsAsc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_MONTHS_ASC',
  LeadsStddevPopulationIncomeTimeMonthsDesc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_MONTHS_DESC',
  LeadsStddevPopulationResidenceTimeYearsAsc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_YEARS_ASC',
  LeadsStddevPopulationResidenceTimeYearsDesc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_YEARS_DESC',
  LeadsStddevPopulationResidenceTimeMonthsAsc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_MONTHS_ASC',
  LeadsStddevPopulationResidenceTimeMonthsDesc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_MONTHS_DESC',
  LeadsVarianceSampleIdAsc = 'LEADS_VARIANCE_SAMPLE_ID_ASC',
  LeadsVarianceSampleIdDesc = 'LEADS_VARIANCE_SAMPLE_ID_DESC',
  LeadsVarianceSampleCompanyIdAsc = 'LEADS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  LeadsVarianceSampleCompanyIdDesc = 'LEADS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  LeadsVarianceSampleCreatedAtAsc = 'LEADS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LeadsVarianceSampleCreatedAtDesc = 'LEADS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LeadsVarianceSampleFirstNameAsc = 'LEADS_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  LeadsVarianceSampleFirstNameDesc = 'LEADS_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  LeadsVarianceSampleLastNameAsc = 'LEADS_VARIANCE_SAMPLE_LAST_NAME_ASC',
  LeadsVarianceSampleLastNameDesc = 'LEADS_VARIANCE_SAMPLE_LAST_NAME_DESC',
  LeadsVarianceSampleDateOfBirthAsc = 'LEADS_VARIANCE_SAMPLE_DATE_OF_BIRTH_ASC',
  LeadsVarianceSampleDateOfBirthDesc = 'LEADS_VARIANCE_SAMPLE_DATE_OF_BIRTH_DESC',
  LeadsVarianceSampleStreetAsc = 'LEADS_VARIANCE_SAMPLE_STREET_ASC',
  LeadsVarianceSampleStreetDesc = 'LEADS_VARIANCE_SAMPLE_STREET_DESC',
  LeadsVarianceSampleStreetNumberAsc = 'LEADS_VARIANCE_SAMPLE_STREET_NUMBER_ASC',
  LeadsVarianceSampleStreetNumberDesc = 'LEADS_VARIANCE_SAMPLE_STREET_NUMBER_DESC',
  LeadsVarianceSampleUnitAsc = 'LEADS_VARIANCE_SAMPLE_UNIT_ASC',
  LeadsVarianceSampleUnitDesc = 'LEADS_VARIANCE_SAMPLE_UNIT_DESC',
  LeadsVarianceSampleCityAsc = 'LEADS_VARIANCE_SAMPLE_CITY_ASC',
  LeadsVarianceSampleCityDesc = 'LEADS_VARIANCE_SAMPLE_CITY_DESC',
  LeadsVarianceSampleCountryAsc = 'LEADS_VARIANCE_SAMPLE_COUNTRY_ASC',
  LeadsVarianceSampleCountryDesc = 'LEADS_VARIANCE_SAMPLE_COUNTRY_DESC',
  LeadsVarianceSamplePostalCodeAsc = 'LEADS_VARIANCE_SAMPLE_POSTAL_CODE_ASC',
  LeadsVarianceSamplePostalCodeDesc = 'LEADS_VARIANCE_SAMPLE_POSTAL_CODE_DESC',
  LeadsVarianceSampleProvinceCodeAsc = 'LEADS_VARIANCE_SAMPLE_PROVINCE_CODE_ASC',
  LeadsVarianceSampleProvinceCodeDesc = 'LEADS_VARIANCE_SAMPLE_PROVINCE_CODE_DESC',
  LeadsVarianceSampleEmailAddressAsc = 'LEADS_VARIANCE_SAMPLE_EMAIL_ADDRESS_ASC',
  LeadsVarianceSampleEmailAddressDesc = 'LEADS_VARIANCE_SAMPLE_EMAIL_ADDRESS_DESC',
  LeadsVarianceSamplePhoneNumberAsc = 'LEADS_VARIANCE_SAMPLE_PHONE_NUMBER_ASC',
  LeadsVarianceSamplePhoneNumberDesc = 'LEADS_VARIANCE_SAMPLE_PHONE_NUMBER_DESC',
  LeadsVarianceSampleSourceUrlAsc = 'LEADS_VARIANCE_SAMPLE_SOURCE_URL_ASC',
  LeadsVarianceSampleSourceUrlDesc = 'LEADS_VARIANCE_SAMPLE_SOURCE_URL_DESC',
  LeadsVarianceSampleTransactionKeyAsc = 'LEADS_VARIANCE_SAMPLE_TRANSACTION_KEY_ASC',
  LeadsVarianceSampleTransactionKeyDesc = 'LEADS_VARIANCE_SAMPLE_TRANSACTION_KEY_DESC',
  LeadsVarianceSampleLeadspediaIdAsc = 'LEADS_VARIANCE_SAMPLE_LEADSPEDIA_ID_ASC',
  LeadsVarianceSampleLeadspediaIdDesc = 'LEADS_VARIANCE_SAMPLE_LEADSPEDIA_ID_DESC',
  LeadsVarianceSampleUpdatedAtAsc = 'LEADS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LeadsVarianceSampleUpdatedAtDesc = 'LEADS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LeadsVarianceSampleSearchTextAsc = 'LEADS_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  LeadsVarianceSampleSearchTextDesc = 'LEADS_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  LeadsVarianceSampleCommentAsc = 'LEADS_VARIANCE_SAMPLE_COMMENT_ASC',
  LeadsVarianceSampleCommentDesc = 'LEADS_VARIANCE_SAMPLE_COMMENT_DESC',
  LeadsVarianceSampleStateAsc = 'LEADS_VARIANCE_SAMPLE_STATE_ASC',
  LeadsVarianceSampleStateDesc = 'LEADS_VARIANCE_SAMPLE_STATE_DESC',
  LeadsVarianceSampleReadAsc = 'LEADS_VARIANCE_SAMPLE_READ_ASC',
  LeadsVarianceSampleReadDesc = 'LEADS_VARIANCE_SAMPLE_READ_DESC',
  LeadsVarianceSampleExternalIdsAsc = 'LEADS_VARIANCE_SAMPLE_EXTERNAL_IDS_ASC',
  LeadsVarianceSampleExternalIdsDesc = 'LEADS_VARIANCE_SAMPLE_EXTERNAL_IDS_DESC',
  LeadsVarianceSampleMetaAsc = 'LEADS_VARIANCE_SAMPLE_META_ASC',
  LeadsVarianceSampleMetaDesc = 'LEADS_VARIANCE_SAMPLE_META_DESC',
  LeadsVarianceSampleAdditionalInformationAsc = 'LEADS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  LeadsVarianceSampleAdditionalInformationDesc = 'LEADS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  LeadsVarianceSamplePhoneVerifiedAtAsc = 'LEADS_VARIANCE_SAMPLE_PHONE_VERIFIED_AT_ASC',
  LeadsVarianceSamplePhoneVerifiedAtDesc = 'LEADS_VARIANCE_SAMPLE_PHONE_VERIFIED_AT_DESC',
  LeadsVarianceSampleIpAddressAsc = 'LEADS_VARIANCE_SAMPLE_IP_ADDRESS_ASC',
  LeadsVarianceSampleIpAddressDesc = 'LEADS_VARIANCE_SAMPLE_IP_ADDRESS_DESC',
  LeadsVarianceSampleClientIdAsc = 'LEADS_VARIANCE_SAMPLE_CLIENT_ID_ASC',
  LeadsVarianceSampleClientIdDesc = 'LEADS_VARIANCE_SAMPLE_CLIENT_ID_DESC',
  LeadsVarianceSampleDeletedAsc = 'LEADS_VARIANCE_SAMPLE_DELETED_ASC',
  LeadsVarianceSampleDeletedDesc = 'LEADS_VARIANCE_SAMPLE_DELETED_DESC',
  LeadsVarianceSamplePriorityAsc = 'LEADS_VARIANCE_SAMPLE_PRIORITY_ASC',
  LeadsVarianceSamplePriorityDesc = 'LEADS_VARIANCE_SAMPLE_PRIORITY_DESC',
  LeadsVarianceSampleAssigneeAsc = 'LEADS_VARIANCE_SAMPLE_ASSIGNEE_ASC',
  LeadsVarianceSampleAssigneeDesc = 'LEADS_VARIANCE_SAMPLE_ASSIGNEE_DESC',
  LeadsVarianceSampleShortIdAsc = 'LEADS_VARIANCE_SAMPLE_SHORT_ID_ASC',
  LeadsVarianceSampleShortIdDesc = 'LEADS_VARIANCE_SAMPLE_SHORT_ID_DESC',
  LeadsVarianceSampleCompanyChannelIdAsc = 'LEADS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  LeadsVarianceSampleCompanyChannelIdDesc = 'LEADS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  LeadsVarianceSampleNeighborhoodViewIdAsc = 'LEADS_VARIANCE_SAMPLE_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsVarianceSampleNeighborhoodViewIdDesc = 'LEADS_VARIANCE_SAMPLE_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsVarianceSampleMaritalStatusAsc = 'LEADS_VARIANCE_SAMPLE_MARITAL_STATUS_ASC',
  LeadsVarianceSampleMaritalStatusDesc = 'LEADS_VARIANCE_SAMPLE_MARITAL_STATUS_DESC',
  LeadsVarianceSampleDesiredVehicleAsc = 'LEADS_VARIANCE_SAMPLE_DESIRED_VEHICLE_ASC',
  LeadsVarianceSampleDesiredVehicleDesc = 'LEADS_VARIANCE_SAMPLE_DESIRED_VEHICLE_DESC',
  LeadsVarianceSampleJobTitleAsc = 'LEADS_VARIANCE_SAMPLE_JOB_TITLE_ASC',
  LeadsVarianceSampleJobTitleDesc = 'LEADS_VARIANCE_SAMPLE_JOB_TITLE_DESC',
  LeadsVarianceSampleIncomePeriodAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_PERIOD_ASC',
  LeadsVarianceSampleIncomePeriodDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_PERIOD_DESC',
  LeadsVarianceSampleSalaryHourlyAsc = 'LEADS_VARIANCE_SAMPLE_SALARY_HOURLY_ASC',
  LeadsVarianceSampleSalaryHourlyDesc = 'LEADS_VARIANCE_SAMPLE_SALARY_HOURLY_DESC',
  LeadsVarianceSampleIncomeSourceAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_SOURCE_ASC',
  LeadsVarianceSampleIncomeSourceDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_SOURCE_DESC',
  LeadsVarianceSampleIncomeTimeAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_ASC',
  LeadsVarianceSampleIncomeTimeDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_DESC',
  LeadsVarianceSamplePreferredContactMethodAsc = 'LEADS_VARIANCE_SAMPLE_PREFERRED_CONTACT_METHOD_ASC',
  LeadsVarianceSamplePreferredContactMethodDesc = 'LEADS_VARIANCE_SAMPLE_PREFERRED_CONTACT_METHOD_DESC',
  LeadsVarianceSampleCompanyNameAsc = 'LEADS_VARIANCE_SAMPLE_COMPANY_NAME_ASC',
  LeadsVarianceSampleCompanyNameDesc = 'LEADS_VARIANCE_SAMPLE_COMPANY_NAME_DESC',
  LeadsVarianceSampleCreditConcernAsc = 'LEADS_VARIANCE_SAMPLE_CREDIT_CONCERN_ASC',
  LeadsVarianceSampleCreditConcernDesc = 'LEADS_VARIANCE_SAMPLE_CREDIT_CONCERN_DESC',
  LeadsVarianceSampleResidenceTimeAsc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_ASC',
  LeadsVarianceSampleResidenceTimeDesc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_DESC',
  LeadsVarianceSampleRentOrOwnAsc = 'LEADS_VARIANCE_SAMPLE_RENT_OR_OWN_ASC',
  LeadsVarianceSampleRentOrOwnDesc = 'LEADS_VARIANCE_SAMPLE_RENT_OR_OWN_DESC',
  LeadsVarianceSampleEmploymentStatusAsc = 'LEADS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LeadsVarianceSampleEmploymentStatusDesc = 'LEADS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LeadsVarianceSampleIncomeAmountAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_AMOUNT_ASC',
  LeadsVarianceSampleIncomeAmountDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_AMOUNT_DESC',
  LeadsVarianceSampleMonthlyHomePaymentAsc = 'LEADS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LeadsVarianceSampleMonthlyHomePaymentDesc = 'LEADS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LeadsVarianceSampleSmsConsentAsc = 'LEADS_VARIANCE_SAMPLE_SMS_CONSENT_ASC',
  LeadsVarianceSampleSmsConsentDesc = 'LEADS_VARIANCE_SAMPLE_SMS_CONSENT_DESC',
  LeadsVarianceSampleIncomeTimeYearsAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_YEARS_ASC',
  LeadsVarianceSampleIncomeTimeYearsDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_YEARS_DESC',
  LeadsVarianceSampleIncomeTimeMonthsAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_MONTHS_ASC',
  LeadsVarianceSampleIncomeTimeMonthsDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_MONTHS_DESC',
  LeadsVarianceSampleResidenceTimeYearsAsc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_YEARS_ASC',
  LeadsVarianceSampleResidenceTimeYearsDesc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_YEARS_DESC',
  LeadsVarianceSampleResidenceTimeMonthsAsc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_MONTHS_ASC',
  LeadsVarianceSampleResidenceTimeMonthsDesc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_MONTHS_DESC',
  LeadsVariancePopulationIdAsc = 'LEADS_VARIANCE_POPULATION_ID_ASC',
  LeadsVariancePopulationIdDesc = 'LEADS_VARIANCE_POPULATION_ID_DESC',
  LeadsVariancePopulationCompanyIdAsc = 'LEADS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  LeadsVariancePopulationCompanyIdDesc = 'LEADS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  LeadsVariancePopulationCreatedAtAsc = 'LEADS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LeadsVariancePopulationCreatedAtDesc = 'LEADS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LeadsVariancePopulationFirstNameAsc = 'LEADS_VARIANCE_POPULATION_FIRST_NAME_ASC',
  LeadsVariancePopulationFirstNameDesc = 'LEADS_VARIANCE_POPULATION_FIRST_NAME_DESC',
  LeadsVariancePopulationLastNameAsc = 'LEADS_VARIANCE_POPULATION_LAST_NAME_ASC',
  LeadsVariancePopulationLastNameDesc = 'LEADS_VARIANCE_POPULATION_LAST_NAME_DESC',
  LeadsVariancePopulationDateOfBirthAsc = 'LEADS_VARIANCE_POPULATION_DATE_OF_BIRTH_ASC',
  LeadsVariancePopulationDateOfBirthDesc = 'LEADS_VARIANCE_POPULATION_DATE_OF_BIRTH_DESC',
  LeadsVariancePopulationStreetAsc = 'LEADS_VARIANCE_POPULATION_STREET_ASC',
  LeadsVariancePopulationStreetDesc = 'LEADS_VARIANCE_POPULATION_STREET_DESC',
  LeadsVariancePopulationStreetNumberAsc = 'LEADS_VARIANCE_POPULATION_STREET_NUMBER_ASC',
  LeadsVariancePopulationStreetNumberDesc = 'LEADS_VARIANCE_POPULATION_STREET_NUMBER_DESC',
  LeadsVariancePopulationUnitAsc = 'LEADS_VARIANCE_POPULATION_UNIT_ASC',
  LeadsVariancePopulationUnitDesc = 'LEADS_VARIANCE_POPULATION_UNIT_DESC',
  LeadsVariancePopulationCityAsc = 'LEADS_VARIANCE_POPULATION_CITY_ASC',
  LeadsVariancePopulationCityDesc = 'LEADS_VARIANCE_POPULATION_CITY_DESC',
  LeadsVariancePopulationCountryAsc = 'LEADS_VARIANCE_POPULATION_COUNTRY_ASC',
  LeadsVariancePopulationCountryDesc = 'LEADS_VARIANCE_POPULATION_COUNTRY_DESC',
  LeadsVariancePopulationPostalCodeAsc = 'LEADS_VARIANCE_POPULATION_POSTAL_CODE_ASC',
  LeadsVariancePopulationPostalCodeDesc = 'LEADS_VARIANCE_POPULATION_POSTAL_CODE_DESC',
  LeadsVariancePopulationProvinceCodeAsc = 'LEADS_VARIANCE_POPULATION_PROVINCE_CODE_ASC',
  LeadsVariancePopulationProvinceCodeDesc = 'LEADS_VARIANCE_POPULATION_PROVINCE_CODE_DESC',
  LeadsVariancePopulationEmailAddressAsc = 'LEADS_VARIANCE_POPULATION_EMAIL_ADDRESS_ASC',
  LeadsVariancePopulationEmailAddressDesc = 'LEADS_VARIANCE_POPULATION_EMAIL_ADDRESS_DESC',
  LeadsVariancePopulationPhoneNumberAsc = 'LEADS_VARIANCE_POPULATION_PHONE_NUMBER_ASC',
  LeadsVariancePopulationPhoneNumberDesc = 'LEADS_VARIANCE_POPULATION_PHONE_NUMBER_DESC',
  LeadsVariancePopulationSourceUrlAsc = 'LEADS_VARIANCE_POPULATION_SOURCE_URL_ASC',
  LeadsVariancePopulationSourceUrlDesc = 'LEADS_VARIANCE_POPULATION_SOURCE_URL_DESC',
  LeadsVariancePopulationTransactionKeyAsc = 'LEADS_VARIANCE_POPULATION_TRANSACTION_KEY_ASC',
  LeadsVariancePopulationTransactionKeyDesc = 'LEADS_VARIANCE_POPULATION_TRANSACTION_KEY_DESC',
  LeadsVariancePopulationLeadspediaIdAsc = 'LEADS_VARIANCE_POPULATION_LEADSPEDIA_ID_ASC',
  LeadsVariancePopulationLeadspediaIdDesc = 'LEADS_VARIANCE_POPULATION_LEADSPEDIA_ID_DESC',
  LeadsVariancePopulationUpdatedAtAsc = 'LEADS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LeadsVariancePopulationUpdatedAtDesc = 'LEADS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LeadsVariancePopulationSearchTextAsc = 'LEADS_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  LeadsVariancePopulationSearchTextDesc = 'LEADS_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  LeadsVariancePopulationCommentAsc = 'LEADS_VARIANCE_POPULATION_COMMENT_ASC',
  LeadsVariancePopulationCommentDesc = 'LEADS_VARIANCE_POPULATION_COMMENT_DESC',
  LeadsVariancePopulationStateAsc = 'LEADS_VARIANCE_POPULATION_STATE_ASC',
  LeadsVariancePopulationStateDesc = 'LEADS_VARIANCE_POPULATION_STATE_DESC',
  LeadsVariancePopulationReadAsc = 'LEADS_VARIANCE_POPULATION_READ_ASC',
  LeadsVariancePopulationReadDesc = 'LEADS_VARIANCE_POPULATION_READ_DESC',
  LeadsVariancePopulationExternalIdsAsc = 'LEADS_VARIANCE_POPULATION_EXTERNAL_IDS_ASC',
  LeadsVariancePopulationExternalIdsDesc = 'LEADS_VARIANCE_POPULATION_EXTERNAL_IDS_DESC',
  LeadsVariancePopulationMetaAsc = 'LEADS_VARIANCE_POPULATION_META_ASC',
  LeadsVariancePopulationMetaDesc = 'LEADS_VARIANCE_POPULATION_META_DESC',
  LeadsVariancePopulationAdditionalInformationAsc = 'LEADS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_ASC',
  LeadsVariancePopulationAdditionalInformationDesc = 'LEADS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_DESC',
  LeadsVariancePopulationPhoneVerifiedAtAsc = 'LEADS_VARIANCE_POPULATION_PHONE_VERIFIED_AT_ASC',
  LeadsVariancePopulationPhoneVerifiedAtDesc = 'LEADS_VARIANCE_POPULATION_PHONE_VERIFIED_AT_DESC',
  LeadsVariancePopulationIpAddressAsc = 'LEADS_VARIANCE_POPULATION_IP_ADDRESS_ASC',
  LeadsVariancePopulationIpAddressDesc = 'LEADS_VARIANCE_POPULATION_IP_ADDRESS_DESC',
  LeadsVariancePopulationClientIdAsc = 'LEADS_VARIANCE_POPULATION_CLIENT_ID_ASC',
  LeadsVariancePopulationClientIdDesc = 'LEADS_VARIANCE_POPULATION_CLIENT_ID_DESC',
  LeadsVariancePopulationDeletedAsc = 'LEADS_VARIANCE_POPULATION_DELETED_ASC',
  LeadsVariancePopulationDeletedDesc = 'LEADS_VARIANCE_POPULATION_DELETED_DESC',
  LeadsVariancePopulationPriorityAsc = 'LEADS_VARIANCE_POPULATION_PRIORITY_ASC',
  LeadsVariancePopulationPriorityDesc = 'LEADS_VARIANCE_POPULATION_PRIORITY_DESC',
  LeadsVariancePopulationAssigneeAsc = 'LEADS_VARIANCE_POPULATION_ASSIGNEE_ASC',
  LeadsVariancePopulationAssigneeDesc = 'LEADS_VARIANCE_POPULATION_ASSIGNEE_DESC',
  LeadsVariancePopulationShortIdAsc = 'LEADS_VARIANCE_POPULATION_SHORT_ID_ASC',
  LeadsVariancePopulationShortIdDesc = 'LEADS_VARIANCE_POPULATION_SHORT_ID_DESC',
  LeadsVariancePopulationCompanyChannelIdAsc = 'LEADS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  LeadsVariancePopulationCompanyChannelIdDesc = 'LEADS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  LeadsVariancePopulationNeighborhoodViewIdAsc = 'LEADS_VARIANCE_POPULATION_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsVariancePopulationNeighborhoodViewIdDesc = 'LEADS_VARIANCE_POPULATION_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsVariancePopulationMaritalStatusAsc = 'LEADS_VARIANCE_POPULATION_MARITAL_STATUS_ASC',
  LeadsVariancePopulationMaritalStatusDesc = 'LEADS_VARIANCE_POPULATION_MARITAL_STATUS_DESC',
  LeadsVariancePopulationDesiredVehicleAsc = 'LEADS_VARIANCE_POPULATION_DESIRED_VEHICLE_ASC',
  LeadsVariancePopulationDesiredVehicleDesc = 'LEADS_VARIANCE_POPULATION_DESIRED_VEHICLE_DESC',
  LeadsVariancePopulationJobTitleAsc = 'LEADS_VARIANCE_POPULATION_JOB_TITLE_ASC',
  LeadsVariancePopulationJobTitleDesc = 'LEADS_VARIANCE_POPULATION_JOB_TITLE_DESC',
  LeadsVariancePopulationIncomePeriodAsc = 'LEADS_VARIANCE_POPULATION_INCOME_PERIOD_ASC',
  LeadsVariancePopulationIncomePeriodDesc = 'LEADS_VARIANCE_POPULATION_INCOME_PERIOD_DESC',
  LeadsVariancePopulationSalaryHourlyAsc = 'LEADS_VARIANCE_POPULATION_SALARY_HOURLY_ASC',
  LeadsVariancePopulationSalaryHourlyDesc = 'LEADS_VARIANCE_POPULATION_SALARY_HOURLY_DESC',
  LeadsVariancePopulationIncomeSourceAsc = 'LEADS_VARIANCE_POPULATION_INCOME_SOURCE_ASC',
  LeadsVariancePopulationIncomeSourceDesc = 'LEADS_VARIANCE_POPULATION_INCOME_SOURCE_DESC',
  LeadsVariancePopulationIncomeTimeAsc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_ASC',
  LeadsVariancePopulationIncomeTimeDesc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_DESC',
  LeadsVariancePopulationPreferredContactMethodAsc = 'LEADS_VARIANCE_POPULATION_PREFERRED_CONTACT_METHOD_ASC',
  LeadsVariancePopulationPreferredContactMethodDesc = 'LEADS_VARIANCE_POPULATION_PREFERRED_CONTACT_METHOD_DESC',
  LeadsVariancePopulationCompanyNameAsc = 'LEADS_VARIANCE_POPULATION_COMPANY_NAME_ASC',
  LeadsVariancePopulationCompanyNameDesc = 'LEADS_VARIANCE_POPULATION_COMPANY_NAME_DESC',
  LeadsVariancePopulationCreditConcernAsc = 'LEADS_VARIANCE_POPULATION_CREDIT_CONCERN_ASC',
  LeadsVariancePopulationCreditConcernDesc = 'LEADS_VARIANCE_POPULATION_CREDIT_CONCERN_DESC',
  LeadsVariancePopulationResidenceTimeAsc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_ASC',
  LeadsVariancePopulationResidenceTimeDesc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_DESC',
  LeadsVariancePopulationRentOrOwnAsc = 'LEADS_VARIANCE_POPULATION_RENT_OR_OWN_ASC',
  LeadsVariancePopulationRentOrOwnDesc = 'LEADS_VARIANCE_POPULATION_RENT_OR_OWN_DESC',
  LeadsVariancePopulationEmploymentStatusAsc = 'LEADS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_ASC',
  LeadsVariancePopulationEmploymentStatusDesc = 'LEADS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_DESC',
  LeadsVariancePopulationIncomeAmountAsc = 'LEADS_VARIANCE_POPULATION_INCOME_AMOUNT_ASC',
  LeadsVariancePopulationIncomeAmountDesc = 'LEADS_VARIANCE_POPULATION_INCOME_AMOUNT_DESC',
  LeadsVariancePopulationMonthlyHomePaymentAsc = 'LEADS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LeadsVariancePopulationMonthlyHomePaymentDesc = 'LEADS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LeadsVariancePopulationSmsConsentAsc = 'LEADS_VARIANCE_POPULATION_SMS_CONSENT_ASC',
  LeadsVariancePopulationSmsConsentDesc = 'LEADS_VARIANCE_POPULATION_SMS_CONSENT_DESC',
  LeadsVariancePopulationIncomeTimeYearsAsc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_YEARS_ASC',
  LeadsVariancePopulationIncomeTimeYearsDesc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_YEARS_DESC',
  LeadsVariancePopulationIncomeTimeMonthsAsc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_MONTHS_ASC',
  LeadsVariancePopulationIncomeTimeMonthsDesc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_MONTHS_DESC',
  LeadsVariancePopulationResidenceTimeYearsAsc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_YEARS_ASC',
  LeadsVariancePopulationResidenceTimeYearsDesc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_YEARS_DESC',
  LeadsVariancePopulationResidenceTimeMonthsAsc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_MONTHS_ASC',
  LeadsVariancePopulationResidenceTimeMonthsDesc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_MONTHS_DESC',
  CompanyGroupsCountAsc = 'COMPANY_GROUPS_COUNT_ASC',
  CompanyGroupsCountDesc = 'COMPANY_GROUPS_COUNT_DESC',
  CompanyGroupsSumCompanyIdAsc = 'COMPANY_GROUPS_SUM_COMPANY_ID_ASC',
  CompanyGroupsSumCompanyIdDesc = 'COMPANY_GROUPS_SUM_COMPANY_ID_DESC',
  CompanyGroupsSumGroupIdAsc = 'COMPANY_GROUPS_SUM_GROUP_ID_ASC',
  CompanyGroupsSumGroupIdDesc = 'COMPANY_GROUPS_SUM_GROUP_ID_DESC',
  CompanyGroupsSumCreatedAtAsc = 'COMPANY_GROUPS_SUM_CREATED_AT_ASC',
  CompanyGroupsSumCreatedAtDesc = 'COMPANY_GROUPS_SUM_CREATED_AT_DESC',
  CompanyGroupsSumUpdatedAtAsc = 'COMPANY_GROUPS_SUM_UPDATED_AT_ASC',
  CompanyGroupsSumUpdatedAtDesc = 'COMPANY_GROUPS_SUM_UPDATED_AT_DESC',
  CompanyGroupsDistinctCountCompanyIdAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyGroupsDistinctCountCompanyIdDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyGroupsDistinctCountGroupIdAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyGroupsDistinctCountGroupIdDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyGroupsDistinctCountCreatedAtAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyGroupsDistinctCountCreatedAtDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyGroupsDistinctCountUpdatedAtAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyGroupsDistinctCountUpdatedAtDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyGroupsMinCompanyIdAsc = 'COMPANY_GROUPS_MIN_COMPANY_ID_ASC',
  CompanyGroupsMinCompanyIdDesc = 'COMPANY_GROUPS_MIN_COMPANY_ID_DESC',
  CompanyGroupsMinGroupIdAsc = 'COMPANY_GROUPS_MIN_GROUP_ID_ASC',
  CompanyGroupsMinGroupIdDesc = 'COMPANY_GROUPS_MIN_GROUP_ID_DESC',
  CompanyGroupsMinCreatedAtAsc = 'COMPANY_GROUPS_MIN_CREATED_AT_ASC',
  CompanyGroupsMinCreatedAtDesc = 'COMPANY_GROUPS_MIN_CREATED_AT_DESC',
  CompanyGroupsMinUpdatedAtAsc = 'COMPANY_GROUPS_MIN_UPDATED_AT_ASC',
  CompanyGroupsMinUpdatedAtDesc = 'COMPANY_GROUPS_MIN_UPDATED_AT_DESC',
  CompanyGroupsMaxCompanyIdAsc = 'COMPANY_GROUPS_MAX_COMPANY_ID_ASC',
  CompanyGroupsMaxCompanyIdDesc = 'COMPANY_GROUPS_MAX_COMPANY_ID_DESC',
  CompanyGroupsMaxGroupIdAsc = 'COMPANY_GROUPS_MAX_GROUP_ID_ASC',
  CompanyGroupsMaxGroupIdDesc = 'COMPANY_GROUPS_MAX_GROUP_ID_DESC',
  CompanyGroupsMaxCreatedAtAsc = 'COMPANY_GROUPS_MAX_CREATED_AT_ASC',
  CompanyGroupsMaxCreatedAtDesc = 'COMPANY_GROUPS_MAX_CREATED_AT_DESC',
  CompanyGroupsMaxUpdatedAtAsc = 'COMPANY_GROUPS_MAX_UPDATED_AT_ASC',
  CompanyGroupsMaxUpdatedAtDesc = 'COMPANY_GROUPS_MAX_UPDATED_AT_DESC',
  CompanyGroupsAverageCompanyIdAsc = 'COMPANY_GROUPS_AVERAGE_COMPANY_ID_ASC',
  CompanyGroupsAverageCompanyIdDesc = 'COMPANY_GROUPS_AVERAGE_COMPANY_ID_DESC',
  CompanyGroupsAverageGroupIdAsc = 'COMPANY_GROUPS_AVERAGE_GROUP_ID_ASC',
  CompanyGroupsAverageGroupIdDesc = 'COMPANY_GROUPS_AVERAGE_GROUP_ID_DESC',
  CompanyGroupsAverageCreatedAtAsc = 'COMPANY_GROUPS_AVERAGE_CREATED_AT_ASC',
  CompanyGroupsAverageCreatedAtDesc = 'COMPANY_GROUPS_AVERAGE_CREATED_AT_DESC',
  CompanyGroupsAverageUpdatedAtAsc = 'COMPANY_GROUPS_AVERAGE_UPDATED_AT_ASC',
  CompanyGroupsAverageUpdatedAtDesc = 'COMPANY_GROUPS_AVERAGE_UPDATED_AT_DESC',
  CompanyGroupsStddevSampleCompanyIdAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyGroupsStddevSampleCompanyIdDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyGroupsStddevSampleGroupIdAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyGroupsStddevSampleGroupIdDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyGroupsStddevSampleCreatedAtAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyGroupsStddevSampleCreatedAtDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyGroupsStddevSampleUpdatedAtAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyGroupsStddevSampleUpdatedAtDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyGroupsStddevPopulationCompanyIdAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyGroupsStddevPopulationCompanyIdDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyGroupsStddevPopulationGroupIdAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyGroupsStddevPopulationGroupIdDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyGroupsStddevPopulationCreatedAtAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyGroupsStddevPopulationCreatedAtDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyGroupsStddevPopulationUpdatedAtAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyGroupsStddevPopulationUpdatedAtDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyGroupsVarianceSampleCompanyIdAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyGroupsVarianceSampleCompanyIdDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyGroupsVarianceSampleGroupIdAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyGroupsVarianceSampleGroupIdDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyGroupsVarianceSampleCreatedAtAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyGroupsVarianceSampleCreatedAtDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyGroupsVarianceSampleUpdatedAtAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyGroupsVarianceSampleUpdatedAtDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyGroupsVariancePopulationCompanyIdAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyGroupsVariancePopulationCompanyIdDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyGroupsVariancePopulationGroupIdAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyGroupsVariancePopulationGroupIdDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyGroupsVariancePopulationCreatedAtAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyGroupsVariancePopulationCreatedAtDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyGroupsVariancePopulationUpdatedAtAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyGroupsVariancePopulationUpdatedAtDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyProductsCountAsc = 'COMPANY_PRODUCTS_COUNT_ASC',
  CompanyProductsCountDesc = 'COMPANY_PRODUCTS_COUNT_DESC',
  CompanyProductsSumCompanyIdAsc = 'COMPANY_PRODUCTS_SUM_COMPANY_ID_ASC',
  CompanyProductsSumCompanyIdDesc = 'COMPANY_PRODUCTS_SUM_COMPANY_ID_DESC',
  CompanyProductsSumProductIdAsc = 'COMPANY_PRODUCTS_SUM_PRODUCT_ID_ASC',
  CompanyProductsSumProductIdDesc = 'COMPANY_PRODUCTS_SUM_PRODUCT_ID_DESC',
  CompanyProductsSumCreatedAtAsc = 'COMPANY_PRODUCTS_SUM_CREATED_AT_ASC',
  CompanyProductsSumCreatedAtDesc = 'COMPANY_PRODUCTS_SUM_CREATED_AT_DESC',
  CompanyProductsSumUpdatedAtAsc = 'COMPANY_PRODUCTS_SUM_UPDATED_AT_ASC',
  CompanyProductsSumUpdatedAtDesc = 'COMPANY_PRODUCTS_SUM_UPDATED_AT_DESC',
  CompanyProductsSumIdAsc = 'COMPANY_PRODUCTS_SUM_ID_ASC',
  CompanyProductsSumIdDesc = 'COMPANY_PRODUCTS_SUM_ID_DESC',
  CompanyProductsSumEnabledAsc = 'COMPANY_PRODUCTS_SUM_ENABLED_ASC',
  CompanyProductsSumEnabledDesc = 'COMPANY_PRODUCTS_SUM_ENABLED_DESC',
  CompanyProductsDistinctCountCompanyIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyProductsDistinctCountCompanyIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyProductsDistinctCountProductIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  CompanyProductsDistinctCountProductIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  CompanyProductsDistinctCountCreatedAtAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyProductsDistinctCountCreatedAtDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyProductsDistinctCountUpdatedAtAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyProductsDistinctCountUpdatedAtDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyProductsDistinctCountIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ID_ASC',
  CompanyProductsDistinctCountIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ID_DESC',
  CompanyProductsDistinctCountEnabledAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyProductsDistinctCountEnabledDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyProductsMinCompanyIdAsc = 'COMPANY_PRODUCTS_MIN_COMPANY_ID_ASC',
  CompanyProductsMinCompanyIdDesc = 'COMPANY_PRODUCTS_MIN_COMPANY_ID_DESC',
  CompanyProductsMinProductIdAsc = 'COMPANY_PRODUCTS_MIN_PRODUCT_ID_ASC',
  CompanyProductsMinProductIdDesc = 'COMPANY_PRODUCTS_MIN_PRODUCT_ID_DESC',
  CompanyProductsMinCreatedAtAsc = 'COMPANY_PRODUCTS_MIN_CREATED_AT_ASC',
  CompanyProductsMinCreatedAtDesc = 'COMPANY_PRODUCTS_MIN_CREATED_AT_DESC',
  CompanyProductsMinUpdatedAtAsc = 'COMPANY_PRODUCTS_MIN_UPDATED_AT_ASC',
  CompanyProductsMinUpdatedAtDesc = 'COMPANY_PRODUCTS_MIN_UPDATED_AT_DESC',
  CompanyProductsMinIdAsc = 'COMPANY_PRODUCTS_MIN_ID_ASC',
  CompanyProductsMinIdDesc = 'COMPANY_PRODUCTS_MIN_ID_DESC',
  CompanyProductsMinEnabledAsc = 'COMPANY_PRODUCTS_MIN_ENABLED_ASC',
  CompanyProductsMinEnabledDesc = 'COMPANY_PRODUCTS_MIN_ENABLED_DESC',
  CompanyProductsMaxCompanyIdAsc = 'COMPANY_PRODUCTS_MAX_COMPANY_ID_ASC',
  CompanyProductsMaxCompanyIdDesc = 'COMPANY_PRODUCTS_MAX_COMPANY_ID_DESC',
  CompanyProductsMaxProductIdAsc = 'COMPANY_PRODUCTS_MAX_PRODUCT_ID_ASC',
  CompanyProductsMaxProductIdDesc = 'COMPANY_PRODUCTS_MAX_PRODUCT_ID_DESC',
  CompanyProductsMaxCreatedAtAsc = 'COMPANY_PRODUCTS_MAX_CREATED_AT_ASC',
  CompanyProductsMaxCreatedAtDesc = 'COMPANY_PRODUCTS_MAX_CREATED_AT_DESC',
  CompanyProductsMaxUpdatedAtAsc = 'COMPANY_PRODUCTS_MAX_UPDATED_AT_ASC',
  CompanyProductsMaxUpdatedAtDesc = 'COMPANY_PRODUCTS_MAX_UPDATED_AT_DESC',
  CompanyProductsMaxIdAsc = 'COMPANY_PRODUCTS_MAX_ID_ASC',
  CompanyProductsMaxIdDesc = 'COMPANY_PRODUCTS_MAX_ID_DESC',
  CompanyProductsMaxEnabledAsc = 'COMPANY_PRODUCTS_MAX_ENABLED_ASC',
  CompanyProductsMaxEnabledDesc = 'COMPANY_PRODUCTS_MAX_ENABLED_DESC',
  CompanyProductsAverageCompanyIdAsc = 'COMPANY_PRODUCTS_AVERAGE_COMPANY_ID_ASC',
  CompanyProductsAverageCompanyIdDesc = 'COMPANY_PRODUCTS_AVERAGE_COMPANY_ID_DESC',
  CompanyProductsAverageProductIdAsc = 'COMPANY_PRODUCTS_AVERAGE_PRODUCT_ID_ASC',
  CompanyProductsAverageProductIdDesc = 'COMPANY_PRODUCTS_AVERAGE_PRODUCT_ID_DESC',
  CompanyProductsAverageCreatedAtAsc = 'COMPANY_PRODUCTS_AVERAGE_CREATED_AT_ASC',
  CompanyProductsAverageCreatedAtDesc = 'COMPANY_PRODUCTS_AVERAGE_CREATED_AT_DESC',
  CompanyProductsAverageUpdatedAtAsc = 'COMPANY_PRODUCTS_AVERAGE_UPDATED_AT_ASC',
  CompanyProductsAverageUpdatedAtDesc = 'COMPANY_PRODUCTS_AVERAGE_UPDATED_AT_DESC',
  CompanyProductsAverageIdAsc = 'COMPANY_PRODUCTS_AVERAGE_ID_ASC',
  CompanyProductsAverageIdDesc = 'COMPANY_PRODUCTS_AVERAGE_ID_DESC',
  CompanyProductsAverageEnabledAsc = 'COMPANY_PRODUCTS_AVERAGE_ENABLED_ASC',
  CompanyProductsAverageEnabledDesc = 'COMPANY_PRODUCTS_AVERAGE_ENABLED_DESC',
  CompanyProductsStddevSampleCompanyIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyProductsStddevSampleCompanyIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyProductsStddevSampleProductIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  CompanyProductsStddevSampleProductIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  CompanyProductsStddevSampleCreatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyProductsStddevSampleCreatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyProductsStddevSampleUpdatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyProductsStddevSampleUpdatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyProductsStddevSampleIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ID_ASC',
  CompanyProductsStddevSampleIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ID_DESC',
  CompanyProductsStddevSampleEnabledAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyProductsStddevSampleEnabledDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyProductsStddevPopulationCompanyIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyProductsStddevPopulationCompanyIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyProductsStddevPopulationProductIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  CompanyProductsStddevPopulationProductIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  CompanyProductsStddevPopulationCreatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyProductsStddevPopulationCreatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyProductsStddevPopulationUpdatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyProductsStddevPopulationUpdatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyProductsStddevPopulationIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ID_ASC',
  CompanyProductsStddevPopulationIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ID_DESC',
  CompanyProductsStddevPopulationEnabledAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyProductsStddevPopulationEnabledDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyProductsVarianceSampleCompanyIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyProductsVarianceSampleCompanyIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyProductsVarianceSampleProductIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  CompanyProductsVarianceSampleProductIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  CompanyProductsVarianceSampleCreatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyProductsVarianceSampleCreatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyProductsVarianceSampleUpdatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyProductsVarianceSampleUpdatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyProductsVarianceSampleIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ID_ASC',
  CompanyProductsVarianceSampleIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ID_DESC',
  CompanyProductsVarianceSampleEnabledAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyProductsVarianceSampleEnabledDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyProductsVariancePopulationCompanyIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyProductsVariancePopulationCompanyIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyProductsVariancePopulationProductIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  CompanyProductsVariancePopulationProductIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  CompanyProductsVariancePopulationCreatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyProductsVariancePopulationCreatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyProductsVariancePopulationUpdatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyProductsVariancePopulationUpdatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyProductsVariancePopulationIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ID_ASC',
  CompanyProductsVariancePopulationIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ID_DESC',
  CompanyProductsVariancePopulationEnabledAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyProductsVariancePopulationEnabledDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyLocationsCountAsc = 'COMPANY_LOCATIONS_COUNT_ASC',
  CompanyLocationsCountDesc = 'COMPANY_LOCATIONS_COUNT_DESC',
  CompanyLocationsSumIdAsc = 'COMPANY_LOCATIONS_SUM_ID_ASC',
  CompanyLocationsSumIdDesc = 'COMPANY_LOCATIONS_SUM_ID_DESC',
  CompanyLocationsSumCompanyIdAsc = 'COMPANY_LOCATIONS_SUM_COMPANY_ID_ASC',
  CompanyLocationsSumCompanyIdDesc = 'COMPANY_LOCATIONS_SUM_COMPANY_ID_DESC',
  CompanyLocationsSumAddress1Asc = 'COMPANY_LOCATIONS_SUM_ADDRESS1_ASC',
  CompanyLocationsSumAddress1Desc = 'COMPANY_LOCATIONS_SUM_ADDRESS1_DESC',
  CompanyLocationsSumAddress2Asc = 'COMPANY_LOCATIONS_SUM_ADDRESS2_ASC',
  CompanyLocationsSumAddress2Desc = 'COMPANY_LOCATIONS_SUM_ADDRESS2_DESC',
  CompanyLocationsSumCityAsc = 'COMPANY_LOCATIONS_SUM_CITY_ASC',
  CompanyLocationsSumCityDesc = 'COMPANY_LOCATIONS_SUM_CITY_DESC',
  CompanyLocationsSumPostalCodeAsc = 'COMPANY_LOCATIONS_SUM_POSTAL_CODE_ASC',
  CompanyLocationsSumPostalCodeDesc = 'COMPANY_LOCATIONS_SUM_POSTAL_CODE_DESC',
  CompanyLocationsSumProvinceAsc = 'COMPANY_LOCATIONS_SUM_PROVINCE_ASC',
  CompanyLocationsSumProvinceDesc = 'COMPANY_LOCATIONS_SUM_PROVINCE_DESC',
  CompanyLocationsSumLatAsc = 'COMPANY_LOCATIONS_SUM_LAT_ASC',
  CompanyLocationsSumLatDesc = 'COMPANY_LOCATIONS_SUM_LAT_DESC',
  CompanyLocationsSumLongAsc = 'COMPANY_LOCATIONS_SUM_LONG_ASC',
  CompanyLocationsSumLongDesc = 'COMPANY_LOCATIONS_SUM_LONG_DESC',
  CompanyLocationsSumCreatedAtAsc = 'COMPANY_LOCATIONS_SUM_CREATED_AT_ASC',
  CompanyLocationsSumCreatedAtDesc = 'COMPANY_LOCATIONS_SUM_CREATED_AT_DESC',
  CompanyLocationsSumUpdatedAtAsc = 'COMPANY_LOCATIONS_SUM_UPDATED_AT_ASC',
  CompanyLocationsSumUpdatedAtDesc = 'COMPANY_LOCATIONS_SUM_UPDATED_AT_DESC',
  CompanyLocationsDistinctCountIdAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ID_ASC',
  CompanyLocationsDistinctCountIdDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ID_DESC',
  CompanyLocationsDistinctCountCompanyIdAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyLocationsDistinctCountCompanyIdDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyLocationsDistinctCountAddress1Asc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ADDRESS1_ASC',
  CompanyLocationsDistinctCountAddress1Desc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ADDRESS1_DESC',
  CompanyLocationsDistinctCountAddress2Asc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ADDRESS2_ASC',
  CompanyLocationsDistinctCountAddress2Desc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ADDRESS2_DESC',
  CompanyLocationsDistinctCountCityAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_CITY_ASC',
  CompanyLocationsDistinctCountCityDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_CITY_DESC',
  CompanyLocationsDistinctCountPostalCodeAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_POSTAL_CODE_ASC',
  CompanyLocationsDistinctCountPostalCodeDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_POSTAL_CODE_DESC',
  CompanyLocationsDistinctCountProvinceAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_PROVINCE_ASC',
  CompanyLocationsDistinctCountProvinceDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_PROVINCE_DESC',
  CompanyLocationsDistinctCountLatAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_LAT_ASC',
  CompanyLocationsDistinctCountLatDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_LAT_DESC',
  CompanyLocationsDistinctCountLongAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_LONG_ASC',
  CompanyLocationsDistinctCountLongDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_LONG_DESC',
  CompanyLocationsDistinctCountCreatedAtAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyLocationsDistinctCountCreatedAtDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyLocationsDistinctCountUpdatedAtAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyLocationsDistinctCountUpdatedAtDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyLocationsMinIdAsc = 'COMPANY_LOCATIONS_MIN_ID_ASC',
  CompanyLocationsMinIdDesc = 'COMPANY_LOCATIONS_MIN_ID_DESC',
  CompanyLocationsMinCompanyIdAsc = 'COMPANY_LOCATIONS_MIN_COMPANY_ID_ASC',
  CompanyLocationsMinCompanyIdDesc = 'COMPANY_LOCATIONS_MIN_COMPANY_ID_DESC',
  CompanyLocationsMinAddress1Asc = 'COMPANY_LOCATIONS_MIN_ADDRESS1_ASC',
  CompanyLocationsMinAddress1Desc = 'COMPANY_LOCATIONS_MIN_ADDRESS1_DESC',
  CompanyLocationsMinAddress2Asc = 'COMPANY_LOCATIONS_MIN_ADDRESS2_ASC',
  CompanyLocationsMinAddress2Desc = 'COMPANY_LOCATIONS_MIN_ADDRESS2_DESC',
  CompanyLocationsMinCityAsc = 'COMPANY_LOCATIONS_MIN_CITY_ASC',
  CompanyLocationsMinCityDesc = 'COMPANY_LOCATIONS_MIN_CITY_DESC',
  CompanyLocationsMinPostalCodeAsc = 'COMPANY_LOCATIONS_MIN_POSTAL_CODE_ASC',
  CompanyLocationsMinPostalCodeDesc = 'COMPANY_LOCATIONS_MIN_POSTAL_CODE_DESC',
  CompanyLocationsMinProvinceAsc = 'COMPANY_LOCATIONS_MIN_PROVINCE_ASC',
  CompanyLocationsMinProvinceDesc = 'COMPANY_LOCATIONS_MIN_PROVINCE_DESC',
  CompanyLocationsMinLatAsc = 'COMPANY_LOCATIONS_MIN_LAT_ASC',
  CompanyLocationsMinLatDesc = 'COMPANY_LOCATIONS_MIN_LAT_DESC',
  CompanyLocationsMinLongAsc = 'COMPANY_LOCATIONS_MIN_LONG_ASC',
  CompanyLocationsMinLongDesc = 'COMPANY_LOCATIONS_MIN_LONG_DESC',
  CompanyLocationsMinCreatedAtAsc = 'COMPANY_LOCATIONS_MIN_CREATED_AT_ASC',
  CompanyLocationsMinCreatedAtDesc = 'COMPANY_LOCATIONS_MIN_CREATED_AT_DESC',
  CompanyLocationsMinUpdatedAtAsc = 'COMPANY_LOCATIONS_MIN_UPDATED_AT_ASC',
  CompanyLocationsMinUpdatedAtDesc = 'COMPANY_LOCATIONS_MIN_UPDATED_AT_DESC',
  CompanyLocationsMaxIdAsc = 'COMPANY_LOCATIONS_MAX_ID_ASC',
  CompanyLocationsMaxIdDesc = 'COMPANY_LOCATIONS_MAX_ID_DESC',
  CompanyLocationsMaxCompanyIdAsc = 'COMPANY_LOCATIONS_MAX_COMPANY_ID_ASC',
  CompanyLocationsMaxCompanyIdDesc = 'COMPANY_LOCATIONS_MAX_COMPANY_ID_DESC',
  CompanyLocationsMaxAddress1Asc = 'COMPANY_LOCATIONS_MAX_ADDRESS1_ASC',
  CompanyLocationsMaxAddress1Desc = 'COMPANY_LOCATIONS_MAX_ADDRESS1_DESC',
  CompanyLocationsMaxAddress2Asc = 'COMPANY_LOCATIONS_MAX_ADDRESS2_ASC',
  CompanyLocationsMaxAddress2Desc = 'COMPANY_LOCATIONS_MAX_ADDRESS2_DESC',
  CompanyLocationsMaxCityAsc = 'COMPANY_LOCATIONS_MAX_CITY_ASC',
  CompanyLocationsMaxCityDesc = 'COMPANY_LOCATIONS_MAX_CITY_DESC',
  CompanyLocationsMaxPostalCodeAsc = 'COMPANY_LOCATIONS_MAX_POSTAL_CODE_ASC',
  CompanyLocationsMaxPostalCodeDesc = 'COMPANY_LOCATIONS_MAX_POSTAL_CODE_DESC',
  CompanyLocationsMaxProvinceAsc = 'COMPANY_LOCATIONS_MAX_PROVINCE_ASC',
  CompanyLocationsMaxProvinceDesc = 'COMPANY_LOCATIONS_MAX_PROVINCE_DESC',
  CompanyLocationsMaxLatAsc = 'COMPANY_LOCATIONS_MAX_LAT_ASC',
  CompanyLocationsMaxLatDesc = 'COMPANY_LOCATIONS_MAX_LAT_DESC',
  CompanyLocationsMaxLongAsc = 'COMPANY_LOCATIONS_MAX_LONG_ASC',
  CompanyLocationsMaxLongDesc = 'COMPANY_LOCATIONS_MAX_LONG_DESC',
  CompanyLocationsMaxCreatedAtAsc = 'COMPANY_LOCATIONS_MAX_CREATED_AT_ASC',
  CompanyLocationsMaxCreatedAtDesc = 'COMPANY_LOCATIONS_MAX_CREATED_AT_DESC',
  CompanyLocationsMaxUpdatedAtAsc = 'COMPANY_LOCATIONS_MAX_UPDATED_AT_ASC',
  CompanyLocationsMaxUpdatedAtDesc = 'COMPANY_LOCATIONS_MAX_UPDATED_AT_DESC',
  CompanyLocationsAverageIdAsc = 'COMPANY_LOCATIONS_AVERAGE_ID_ASC',
  CompanyLocationsAverageIdDesc = 'COMPANY_LOCATIONS_AVERAGE_ID_DESC',
  CompanyLocationsAverageCompanyIdAsc = 'COMPANY_LOCATIONS_AVERAGE_COMPANY_ID_ASC',
  CompanyLocationsAverageCompanyIdDesc = 'COMPANY_LOCATIONS_AVERAGE_COMPANY_ID_DESC',
  CompanyLocationsAverageAddress1Asc = 'COMPANY_LOCATIONS_AVERAGE_ADDRESS1_ASC',
  CompanyLocationsAverageAddress1Desc = 'COMPANY_LOCATIONS_AVERAGE_ADDRESS1_DESC',
  CompanyLocationsAverageAddress2Asc = 'COMPANY_LOCATIONS_AVERAGE_ADDRESS2_ASC',
  CompanyLocationsAverageAddress2Desc = 'COMPANY_LOCATIONS_AVERAGE_ADDRESS2_DESC',
  CompanyLocationsAverageCityAsc = 'COMPANY_LOCATIONS_AVERAGE_CITY_ASC',
  CompanyLocationsAverageCityDesc = 'COMPANY_LOCATIONS_AVERAGE_CITY_DESC',
  CompanyLocationsAveragePostalCodeAsc = 'COMPANY_LOCATIONS_AVERAGE_POSTAL_CODE_ASC',
  CompanyLocationsAveragePostalCodeDesc = 'COMPANY_LOCATIONS_AVERAGE_POSTAL_CODE_DESC',
  CompanyLocationsAverageProvinceAsc = 'COMPANY_LOCATIONS_AVERAGE_PROVINCE_ASC',
  CompanyLocationsAverageProvinceDesc = 'COMPANY_LOCATIONS_AVERAGE_PROVINCE_DESC',
  CompanyLocationsAverageLatAsc = 'COMPANY_LOCATIONS_AVERAGE_LAT_ASC',
  CompanyLocationsAverageLatDesc = 'COMPANY_LOCATIONS_AVERAGE_LAT_DESC',
  CompanyLocationsAverageLongAsc = 'COMPANY_LOCATIONS_AVERAGE_LONG_ASC',
  CompanyLocationsAverageLongDesc = 'COMPANY_LOCATIONS_AVERAGE_LONG_DESC',
  CompanyLocationsAverageCreatedAtAsc = 'COMPANY_LOCATIONS_AVERAGE_CREATED_AT_ASC',
  CompanyLocationsAverageCreatedAtDesc = 'COMPANY_LOCATIONS_AVERAGE_CREATED_AT_DESC',
  CompanyLocationsAverageUpdatedAtAsc = 'COMPANY_LOCATIONS_AVERAGE_UPDATED_AT_ASC',
  CompanyLocationsAverageUpdatedAtDesc = 'COMPANY_LOCATIONS_AVERAGE_UPDATED_AT_DESC',
  CompanyLocationsStddevSampleIdAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ID_ASC',
  CompanyLocationsStddevSampleIdDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ID_DESC',
  CompanyLocationsStddevSampleCompanyIdAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyLocationsStddevSampleCompanyIdDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyLocationsStddevSampleAddress1Asc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ADDRESS1_ASC',
  CompanyLocationsStddevSampleAddress1Desc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ADDRESS1_DESC',
  CompanyLocationsStddevSampleAddress2Asc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ADDRESS2_ASC',
  CompanyLocationsStddevSampleAddress2Desc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ADDRESS2_DESC',
  CompanyLocationsStddevSampleCityAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_CITY_ASC',
  CompanyLocationsStddevSampleCityDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_CITY_DESC',
  CompanyLocationsStddevSamplePostalCodeAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_POSTAL_CODE_ASC',
  CompanyLocationsStddevSamplePostalCodeDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_POSTAL_CODE_DESC',
  CompanyLocationsStddevSampleProvinceAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_PROVINCE_ASC',
  CompanyLocationsStddevSampleProvinceDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_PROVINCE_DESC',
  CompanyLocationsStddevSampleLatAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_LAT_ASC',
  CompanyLocationsStddevSampleLatDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_LAT_DESC',
  CompanyLocationsStddevSampleLongAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_LONG_ASC',
  CompanyLocationsStddevSampleLongDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_LONG_DESC',
  CompanyLocationsStddevSampleCreatedAtAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyLocationsStddevSampleCreatedAtDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyLocationsStddevSampleUpdatedAtAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyLocationsStddevSampleUpdatedAtDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyLocationsStddevPopulationIdAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ID_ASC',
  CompanyLocationsStddevPopulationIdDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ID_DESC',
  CompanyLocationsStddevPopulationCompanyIdAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyLocationsStddevPopulationCompanyIdDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyLocationsStddevPopulationAddress1Asc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ADDRESS1_ASC',
  CompanyLocationsStddevPopulationAddress1Desc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ADDRESS1_DESC',
  CompanyLocationsStddevPopulationAddress2Asc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ADDRESS2_ASC',
  CompanyLocationsStddevPopulationAddress2Desc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ADDRESS2_DESC',
  CompanyLocationsStddevPopulationCityAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_CITY_ASC',
  CompanyLocationsStddevPopulationCityDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_CITY_DESC',
  CompanyLocationsStddevPopulationPostalCodeAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_POSTAL_CODE_ASC',
  CompanyLocationsStddevPopulationPostalCodeDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_POSTAL_CODE_DESC',
  CompanyLocationsStddevPopulationProvinceAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_PROVINCE_ASC',
  CompanyLocationsStddevPopulationProvinceDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_PROVINCE_DESC',
  CompanyLocationsStddevPopulationLatAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_LAT_ASC',
  CompanyLocationsStddevPopulationLatDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_LAT_DESC',
  CompanyLocationsStddevPopulationLongAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_LONG_ASC',
  CompanyLocationsStddevPopulationLongDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_LONG_DESC',
  CompanyLocationsStddevPopulationCreatedAtAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyLocationsStddevPopulationCreatedAtDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyLocationsStddevPopulationUpdatedAtAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyLocationsStddevPopulationUpdatedAtDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyLocationsVarianceSampleIdAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ID_ASC',
  CompanyLocationsVarianceSampleIdDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ID_DESC',
  CompanyLocationsVarianceSampleCompanyIdAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyLocationsVarianceSampleCompanyIdDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyLocationsVarianceSampleAddress1Asc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ADDRESS1_ASC',
  CompanyLocationsVarianceSampleAddress1Desc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ADDRESS1_DESC',
  CompanyLocationsVarianceSampleAddress2Asc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ADDRESS2_ASC',
  CompanyLocationsVarianceSampleAddress2Desc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ADDRESS2_DESC',
  CompanyLocationsVarianceSampleCityAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_CITY_ASC',
  CompanyLocationsVarianceSampleCityDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_CITY_DESC',
  CompanyLocationsVarianceSamplePostalCodeAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_POSTAL_CODE_ASC',
  CompanyLocationsVarianceSamplePostalCodeDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_POSTAL_CODE_DESC',
  CompanyLocationsVarianceSampleProvinceAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_PROVINCE_ASC',
  CompanyLocationsVarianceSampleProvinceDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_PROVINCE_DESC',
  CompanyLocationsVarianceSampleLatAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_LAT_ASC',
  CompanyLocationsVarianceSampleLatDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_LAT_DESC',
  CompanyLocationsVarianceSampleLongAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_LONG_ASC',
  CompanyLocationsVarianceSampleLongDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_LONG_DESC',
  CompanyLocationsVarianceSampleCreatedAtAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyLocationsVarianceSampleCreatedAtDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyLocationsVarianceSampleUpdatedAtAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyLocationsVarianceSampleUpdatedAtDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyLocationsVariancePopulationIdAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ID_ASC',
  CompanyLocationsVariancePopulationIdDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ID_DESC',
  CompanyLocationsVariancePopulationCompanyIdAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyLocationsVariancePopulationCompanyIdDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyLocationsVariancePopulationAddress1Asc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ADDRESS1_ASC',
  CompanyLocationsVariancePopulationAddress1Desc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ADDRESS1_DESC',
  CompanyLocationsVariancePopulationAddress2Asc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ADDRESS2_ASC',
  CompanyLocationsVariancePopulationAddress2Desc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ADDRESS2_DESC',
  CompanyLocationsVariancePopulationCityAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_CITY_ASC',
  CompanyLocationsVariancePopulationCityDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_CITY_DESC',
  CompanyLocationsVariancePopulationPostalCodeAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_POSTAL_CODE_ASC',
  CompanyLocationsVariancePopulationPostalCodeDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_POSTAL_CODE_DESC',
  CompanyLocationsVariancePopulationProvinceAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_PROVINCE_ASC',
  CompanyLocationsVariancePopulationProvinceDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_PROVINCE_DESC',
  CompanyLocationsVariancePopulationLatAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_LAT_ASC',
  CompanyLocationsVariancePopulationLatDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_LAT_DESC',
  CompanyLocationsVariancePopulationLongAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_LONG_ASC',
  CompanyLocationsVariancePopulationLongDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_LONG_DESC',
  CompanyLocationsVariancePopulationCreatedAtAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyLocationsVariancePopulationCreatedAtDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyLocationsVariancePopulationUpdatedAtAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyLocationsVariancePopulationUpdatedAtDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyAnalyticsCountAsc = 'COMPANY_ANALYTICS_COUNT_ASC',
  CompanyAnalyticsCountDesc = 'COMPANY_ANALYTICS_COUNT_DESC',
  CompanyAnalyticsSumIdAsc = 'COMPANY_ANALYTICS_SUM_ID_ASC',
  CompanyAnalyticsSumIdDesc = 'COMPANY_ANALYTICS_SUM_ID_DESC',
  CompanyAnalyticsSumCompanyIdAsc = 'COMPANY_ANALYTICS_SUM_COMPANY_ID_ASC',
  CompanyAnalyticsSumCompanyIdDesc = 'COMPANY_ANALYTICS_SUM_COMPANY_ID_DESC',
  CompanyAnalyticsSumTypeAsc = 'COMPANY_ANALYTICS_SUM_TYPE_ASC',
  CompanyAnalyticsSumTypeDesc = 'COMPANY_ANALYTICS_SUM_TYPE_DESC',
  CompanyAnalyticsSumAnalyticsIdAsc = 'COMPANY_ANALYTICS_SUM_ANALYTICS_ID_ASC',
  CompanyAnalyticsSumAnalyticsIdDesc = 'COMPANY_ANALYTICS_SUM_ANALYTICS_ID_DESC',
  CompanyAnalyticsSumAnalyticsSecretAsc = 'COMPANY_ANALYTICS_SUM_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsSumAnalyticsSecretDesc = 'COMPANY_ANALYTICS_SUM_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsSumDomainAsc = 'COMPANY_ANALYTICS_SUM_DOMAIN_ASC',
  CompanyAnalyticsSumDomainDesc = 'COMPANY_ANALYTICS_SUM_DOMAIN_DESC',
  CompanyAnalyticsSumCreatedAtAsc = 'COMPANY_ANALYTICS_SUM_CREATED_AT_ASC',
  CompanyAnalyticsSumCreatedAtDesc = 'COMPANY_ANALYTICS_SUM_CREATED_AT_DESC',
  CompanyAnalyticsSumUpdatedAtAsc = 'COMPANY_ANALYTICS_SUM_UPDATED_AT_ASC',
  CompanyAnalyticsSumUpdatedAtDesc = 'COMPANY_ANALYTICS_SUM_UPDATED_AT_DESC',
  CompanyAnalyticsDistinctCountIdAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ID_ASC',
  CompanyAnalyticsDistinctCountIdDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ID_DESC',
  CompanyAnalyticsDistinctCountCompanyIdAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyAnalyticsDistinctCountCompanyIdDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyAnalyticsDistinctCountTypeAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_TYPE_ASC',
  CompanyAnalyticsDistinctCountTypeDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_TYPE_DESC',
  CompanyAnalyticsDistinctCountAnalyticsIdAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ANALYTICS_ID_ASC',
  CompanyAnalyticsDistinctCountAnalyticsIdDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ANALYTICS_ID_DESC',
  CompanyAnalyticsDistinctCountAnalyticsSecretAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsDistinctCountAnalyticsSecretDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsDistinctCountDomainAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_DOMAIN_ASC',
  CompanyAnalyticsDistinctCountDomainDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_DOMAIN_DESC',
  CompanyAnalyticsDistinctCountCreatedAtAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyAnalyticsDistinctCountCreatedAtDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyAnalyticsDistinctCountUpdatedAtAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyAnalyticsDistinctCountUpdatedAtDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyAnalyticsMinIdAsc = 'COMPANY_ANALYTICS_MIN_ID_ASC',
  CompanyAnalyticsMinIdDesc = 'COMPANY_ANALYTICS_MIN_ID_DESC',
  CompanyAnalyticsMinCompanyIdAsc = 'COMPANY_ANALYTICS_MIN_COMPANY_ID_ASC',
  CompanyAnalyticsMinCompanyIdDesc = 'COMPANY_ANALYTICS_MIN_COMPANY_ID_DESC',
  CompanyAnalyticsMinTypeAsc = 'COMPANY_ANALYTICS_MIN_TYPE_ASC',
  CompanyAnalyticsMinTypeDesc = 'COMPANY_ANALYTICS_MIN_TYPE_DESC',
  CompanyAnalyticsMinAnalyticsIdAsc = 'COMPANY_ANALYTICS_MIN_ANALYTICS_ID_ASC',
  CompanyAnalyticsMinAnalyticsIdDesc = 'COMPANY_ANALYTICS_MIN_ANALYTICS_ID_DESC',
  CompanyAnalyticsMinAnalyticsSecretAsc = 'COMPANY_ANALYTICS_MIN_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsMinAnalyticsSecretDesc = 'COMPANY_ANALYTICS_MIN_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsMinDomainAsc = 'COMPANY_ANALYTICS_MIN_DOMAIN_ASC',
  CompanyAnalyticsMinDomainDesc = 'COMPANY_ANALYTICS_MIN_DOMAIN_DESC',
  CompanyAnalyticsMinCreatedAtAsc = 'COMPANY_ANALYTICS_MIN_CREATED_AT_ASC',
  CompanyAnalyticsMinCreatedAtDesc = 'COMPANY_ANALYTICS_MIN_CREATED_AT_DESC',
  CompanyAnalyticsMinUpdatedAtAsc = 'COMPANY_ANALYTICS_MIN_UPDATED_AT_ASC',
  CompanyAnalyticsMinUpdatedAtDesc = 'COMPANY_ANALYTICS_MIN_UPDATED_AT_DESC',
  CompanyAnalyticsMaxIdAsc = 'COMPANY_ANALYTICS_MAX_ID_ASC',
  CompanyAnalyticsMaxIdDesc = 'COMPANY_ANALYTICS_MAX_ID_DESC',
  CompanyAnalyticsMaxCompanyIdAsc = 'COMPANY_ANALYTICS_MAX_COMPANY_ID_ASC',
  CompanyAnalyticsMaxCompanyIdDesc = 'COMPANY_ANALYTICS_MAX_COMPANY_ID_DESC',
  CompanyAnalyticsMaxTypeAsc = 'COMPANY_ANALYTICS_MAX_TYPE_ASC',
  CompanyAnalyticsMaxTypeDesc = 'COMPANY_ANALYTICS_MAX_TYPE_DESC',
  CompanyAnalyticsMaxAnalyticsIdAsc = 'COMPANY_ANALYTICS_MAX_ANALYTICS_ID_ASC',
  CompanyAnalyticsMaxAnalyticsIdDesc = 'COMPANY_ANALYTICS_MAX_ANALYTICS_ID_DESC',
  CompanyAnalyticsMaxAnalyticsSecretAsc = 'COMPANY_ANALYTICS_MAX_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsMaxAnalyticsSecretDesc = 'COMPANY_ANALYTICS_MAX_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsMaxDomainAsc = 'COMPANY_ANALYTICS_MAX_DOMAIN_ASC',
  CompanyAnalyticsMaxDomainDesc = 'COMPANY_ANALYTICS_MAX_DOMAIN_DESC',
  CompanyAnalyticsMaxCreatedAtAsc = 'COMPANY_ANALYTICS_MAX_CREATED_AT_ASC',
  CompanyAnalyticsMaxCreatedAtDesc = 'COMPANY_ANALYTICS_MAX_CREATED_AT_DESC',
  CompanyAnalyticsMaxUpdatedAtAsc = 'COMPANY_ANALYTICS_MAX_UPDATED_AT_ASC',
  CompanyAnalyticsMaxUpdatedAtDesc = 'COMPANY_ANALYTICS_MAX_UPDATED_AT_DESC',
  CompanyAnalyticsAverageIdAsc = 'COMPANY_ANALYTICS_AVERAGE_ID_ASC',
  CompanyAnalyticsAverageIdDesc = 'COMPANY_ANALYTICS_AVERAGE_ID_DESC',
  CompanyAnalyticsAverageCompanyIdAsc = 'COMPANY_ANALYTICS_AVERAGE_COMPANY_ID_ASC',
  CompanyAnalyticsAverageCompanyIdDesc = 'COMPANY_ANALYTICS_AVERAGE_COMPANY_ID_DESC',
  CompanyAnalyticsAverageTypeAsc = 'COMPANY_ANALYTICS_AVERAGE_TYPE_ASC',
  CompanyAnalyticsAverageTypeDesc = 'COMPANY_ANALYTICS_AVERAGE_TYPE_DESC',
  CompanyAnalyticsAverageAnalyticsIdAsc = 'COMPANY_ANALYTICS_AVERAGE_ANALYTICS_ID_ASC',
  CompanyAnalyticsAverageAnalyticsIdDesc = 'COMPANY_ANALYTICS_AVERAGE_ANALYTICS_ID_DESC',
  CompanyAnalyticsAverageAnalyticsSecretAsc = 'COMPANY_ANALYTICS_AVERAGE_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsAverageAnalyticsSecretDesc = 'COMPANY_ANALYTICS_AVERAGE_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsAverageDomainAsc = 'COMPANY_ANALYTICS_AVERAGE_DOMAIN_ASC',
  CompanyAnalyticsAverageDomainDesc = 'COMPANY_ANALYTICS_AVERAGE_DOMAIN_DESC',
  CompanyAnalyticsAverageCreatedAtAsc = 'COMPANY_ANALYTICS_AVERAGE_CREATED_AT_ASC',
  CompanyAnalyticsAverageCreatedAtDesc = 'COMPANY_ANALYTICS_AVERAGE_CREATED_AT_DESC',
  CompanyAnalyticsAverageUpdatedAtAsc = 'COMPANY_ANALYTICS_AVERAGE_UPDATED_AT_ASC',
  CompanyAnalyticsAverageUpdatedAtDesc = 'COMPANY_ANALYTICS_AVERAGE_UPDATED_AT_DESC',
  CompanyAnalyticsStddevSampleIdAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ID_ASC',
  CompanyAnalyticsStddevSampleIdDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ID_DESC',
  CompanyAnalyticsStddevSampleCompanyIdAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyAnalyticsStddevSampleCompanyIdDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyAnalyticsStddevSampleTypeAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_TYPE_ASC',
  CompanyAnalyticsStddevSampleTypeDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_TYPE_DESC',
  CompanyAnalyticsStddevSampleAnalyticsIdAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ANALYTICS_ID_ASC',
  CompanyAnalyticsStddevSampleAnalyticsIdDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ANALYTICS_ID_DESC',
  CompanyAnalyticsStddevSampleAnalyticsSecretAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsStddevSampleAnalyticsSecretDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsStddevSampleDomainAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_DOMAIN_ASC',
  CompanyAnalyticsStddevSampleDomainDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_DOMAIN_DESC',
  CompanyAnalyticsStddevSampleCreatedAtAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyAnalyticsStddevSampleCreatedAtDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyAnalyticsStddevSampleUpdatedAtAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyAnalyticsStddevSampleUpdatedAtDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyAnalyticsStddevPopulationIdAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ID_ASC',
  CompanyAnalyticsStddevPopulationIdDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ID_DESC',
  CompanyAnalyticsStddevPopulationCompanyIdAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyAnalyticsStddevPopulationCompanyIdDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyAnalyticsStddevPopulationTypeAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_TYPE_ASC',
  CompanyAnalyticsStddevPopulationTypeDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_TYPE_DESC',
  CompanyAnalyticsStddevPopulationAnalyticsIdAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ANALYTICS_ID_ASC',
  CompanyAnalyticsStddevPopulationAnalyticsIdDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ANALYTICS_ID_DESC',
  CompanyAnalyticsStddevPopulationAnalyticsSecretAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsStddevPopulationAnalyticsSecretDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsStddevPopulationDomainAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_DOMAIN_ASC',
  CompanyAnalyticsStddevPopulationDomainDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_DOMAIN_DESC',
  CompanyAnalyticsStddevPopulationCreatedAtAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyAnalyticsStddevPopulationCreatedAtDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyAnalyticsStddevPopulationUpdatedAtAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyAnalyticsStddevPopulationUpdatedAtDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyAnalyticsVarianceSampleIdAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ID_ASC',
  CompanyAnalyticsVarianceSampleIdDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ID_DESC',
  CompanyAnalyticsVarianceSampleCompanyIdAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyAnalyticsVarianceSampleCompanyIdDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyAnalyticsVarianceSampleTypeAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_TYPE_ASC',
  CompanyAnalyticsVarianceSampleTypeDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_TYPE_DESC',
  CompanyAnalyticsVarianceSampleAnalyticsIdAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ANALYTICS_ID_ASC',
  CompanyAnalyticsVarianceSampleAnalyticsIdDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ANALYTICS_ID_DESC',
  CompanyAnalyticsVarianceSampleAnalyticsSecretAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsVarianceSampleAnalyticsSecretDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsVarianceSampleDomainAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_DOMAIN_ASC',
  CompanyAnalyticsVarianceSampleDomainDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_DOMAIN_DESC',
  CompanyAnalyticsVarianceSampleCreatedAtAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyAnalyticsVarianceSampleCreatedAtDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyAnalyticsVarianceSampleUpdatedAtAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyAnalyticsVarianceSampleUpdatedAtDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyAnalyticsVariancePopulationIdAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ID_ASC',
  CompanyAnalyticsVariancePopulationIdDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ID_DESC',
  CompanyAnalyticsVariancePopulationCompanyIdAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyAnalyticsVariancePopulationCompanyIdDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyAnalyticsVariancePopulationTypeAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_TYPE_ASC',
  CompanyAnalyticsVariancePopulationTypeDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_TYPE_DESC',
  CompanyAnalyticsVariancePopulationAnalyticsIdAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ANALYTICS_ID_ASC',
  CompanyAnalyticsVariancePopulationAnalyticsIdDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ANALYTICS_ID_DESC',
  CompanyAnalyticsVariancePopulationAnalyticsSecretAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsVariancePopulationAnalyticsSecretDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsVariancePopulationDomainAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_DOMAIN_ASC',
  CompanyAnalyticsVariancePopulationDomainDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_DOMAIN_DESC',
  CompanyAnalyticsVariancePopulationCreatedAtAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyAnalyticsVariancePopulationCreatedAtDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyAnalyticsVariancePopulationUpdatedAtAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyAnalyticsVariancePopulationUpdatedAtDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsCountAsc = 'COMPANY_DOMAINS_COUNT_ASC',
  CompanyDomainsCountDesc = 'COMPANY_DOMAINS_COUNT_DESC',
  CompanyDomainsSumIdAsc = 'COMPANY_DOMAINS_SUM_ID_ASC',
  CompanyDomainsSumIdDesc = 'COMPANY_DOMAINS_SUM_ID_DESC',
  CompanyDomainsSumCompanyIdAsc = 'COMPANY_DOMAINS_SUM_COMPANY_ID_ASC',
  CompanyDomainsSumCompanyIdDesc = 'COMPANY_DOMAINS_SUM_COMPANY_ID_DESC',
  CompanyDomainsSumDomainAsc = 'COMPANY_DOMAINS_SUM_DOMAIN_ASC',
  CompanyDomainsSumDomainDesc = 'COMPANY_DOMAINS_SUM_DOMAIN_DESC',
  CompanyDomainsSumCreatedAtAsc = 'COMPANY_DOMAINS_SUM_CREATED_AT_ASC',
  CompanyDomainsSumCreatedAtDesc = 'COMPANY_DOMAINS_SUM_CREATED_AT_DESC',
  CompanyDomainsSumUpdatedAtAsc = 'COMPANY_DOMAINS_SUM_UPDATED_AT_ASC',
  CompanyDomainsSumUpdatedAtDesc = 'COMPANY_DOMAINS_SUM_UPDATED_AT_DESC',
  CompanyDomainsDistinctCountIdAsc = 'COMPANY_DOMAINS_DISTINCT_COUNT_ID_ASC',
  CompanyDomainsDistinctCountIdDesc = 'COMPANY_DOMAINS_DISTINCT_COUNT_ID_DESC',
  CompanyDomainsDistinctCountCompanyIdAsc = 'COMPANY_DOMAINS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyDomainsDistinctCountCompanyIdDesc = 'COMPANY_DOMAINS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyDomainsDistinctCountDomainAsc = 'COMPANY_DOMAINS_DISTINCT_COUNT_DOMAIN_ASC',
  CompanyDomainsDistinctCountDomainDesc = 'COMPANY_DOMAINS_DISTINCT_COUNT_DOMAIN_DESC',
  CompanyDomainsDistinctCountCreatedAtAsc = 'COMPANY_DOMAINS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyDomainsDistinctCountCreatedAtDesc = 'COMPANY_DOMAINS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyDomainsDistinctCountUpdatedAtAsc = 'COMPANY_DOMAINS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyDomainsDistinctCountUpdatedAtDesc = 'COMPANY_DOMAINS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyDomainsMinIdAsc = 'COMPANY_DOMAINS_MIN_ID_ASC',
  CompanyDomainsMinIdDesc = 'COMPANY_DOMAINS_MIN_ID_DESC',
  CompanyDomainsMinCompanyIdAsc = 'COMPANY_DOMAINS_MIN_COMPANY_ID_ASC',
  CompanyDomainsMinCompanyIdDesc = 'COMPANY_DOMAINS_MIN_COMPANY_ID_DESC',
  CompanyDomainsMinDomainAsc = 'COMPANY_DOMAINS_MIN_DOMAIN_ASC',
  CompanyDomainsMinDomainDesc = 'COMPANY_DOMAINS_MIN_DOMAIN_DESC',
  CompanyDomainsMinCreatedAtAsc = 'COMPANY_DOMAINS_MIN_CREATED_AT_ASC',
  CompanyDomainsMinCreatedAtDesc = 'COMPANY_DOMAINS_MIN_CREATED_AT_DESC',
  CompanyDomainsMinUpdatedAtAsc = 'COMPANY_DOMAINS_MIN_UPDATED_AT_ASC',
  CompanyDomainsMinUpdatedAtDesc = 'COMPANY_DOMAINS_MIN_UPDATED_AT_DESC',
  CompanyDomainsMaxIdAsc = 'COMPANY_DOMAINS_MAX_ID_ASC',
  CompanyDomainsMaxIdDesc = 'COMPANY_DOMAINS_MAX_ID_DESC',
  CompanyDomainsMaxCompanyIdAsc = 'COMPANY_DOMAINS_MAX_COMPANY_ID_ASC',
  CompanyDomainsMaxCompanyIdDesc = 'COMPANY_DOMAINS_MAX_COMPANY_ID_DESC',
  CompanyDomainsMaxDomainAsc = 'COMPANY_DOMAINS_MAX_DOMAIN_ASC',
  CompanyDomainsMaxDomainDesc = 'COMPANY_DOMAINS_MAX_DOMAIN_DESC',
  CompanyDomainsMaxCreatedAtAsc = 'COMPANY_DOMAINS_MAX_CREATED_AT_ASC',
  CompanyDomainsMaxCreatedAtDesc = 'COMPANY_DOMAINS_MAX_CREATED_AT_DESC',
  CompanyDomainsMaxUpdatedAtAsc = 'COMPANY_DOMAINS_MAX_UPDATED_AT_ASC',
  CompanyDomainsMaxUpdatedAtDesc = 'COMPANY_DOMAINS_MAX_UPDATED_AT_DESC',
  CompanyDomainsAverageIdAsc = 'COMPANY_DOMAINS_AVERAGE_ID_ASC',
  CompanyDomainsAverageIdDesc = 'COMPANY_DOMAINS_AVERAGE_ID_DESC',
  CompanyDomainsAverageCompanyIdAsc = 'COMPANY_DOMAINS_AVERAGE_COMPANY_ID_ASC',
  CompanyDomainsAverageCompanyIdDesc = 'COMPANY_DOMAINS_AVERAGE_COMPANY_ID_DESC',
  CompanyDomainsAverageDomainAsc = 'COMPANY_DOMAINS_AVERAGE_DOMAIN_ASC',
  CompanyDomainsAverageDomainDesc = 'COMPANY_DOMAINS_AVERAGE_DOMAIN_DESC',
  CompanyDomainsAverageCreatedAtAsc = 'COMPANY_DOMAINS_AVERAGE_CREATED_AT_ASC',
  CompanyDomainsAverageCreatedAtDesc = 'COMPANY_DOMAINS_AVERAGE_CREATED_AT_DESC',
  CompanyDomainsAverageUpdatedAtAsc = 'COMPANY_DOMAINS_AVERAGE_UPDATED_AT_ASC',
  CompanyDomainsAverageUpdatedAtDesc = 'COMPANY_DOMAINS_AVERAGE_UPDATED_AT_DESC',
  CompanyDomainsStddevSampleIdAsc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_ID_ASC',
  CompanyDomainsStddevSampleIdDesc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_ID_DESC',
  CompanyDomainsStddevSampleCompanyIdAsc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsStddevSampleCompanyIdDesc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsStddevSampleDomainAsc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_DOMAIN_ASC',
  CompanyDomainsStddevSampleDomainDesc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_DOMAIN_DESC',
  CompanyDomainsStddevSampleCreatedAtAsc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsStddevSampleCreatedAtDesc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsStddevSampleUpdatedAtAsc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsStddevSampleUpdatedAtDesc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsStddevPopulationIdAsc = 'COMPANY_DOMAINS_STDDEV_POPULATION_ID_ASC',
  CompanyDomainsStddevPopulationIdDesc = 'COMPANY_DOMAINS_STDDEV_POPULATION_ID_DESC',
  CompanyDomainsStddevPopulationCompanyIdAsc = 'COMPANY_DOMAINS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsStddevPopulationCompanyIdDesc = 'COMPANY_DOMAINS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsStddevPopulationDomainAsc = 'COMPANY_DOMAINS_STDDEV_POPULATION_DOMAIN_ASC',
  CompanyDomainsStddevPopulationDomainDesc = 'COMPANY_DOMAINS_STDDEV_POPULATION_DOMAIN_DESC',
  CompanyDomainsStddevPopulationCreatedAtAsc = 'COMPANY_DOMAINS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyDomainsStddevPopulationCreatedAtDesc = 'COMPANY_DOMAINS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyDomainsStddevPopulationUpdatedAtAsc = 'COMPANY_DOMAINS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsStddevPopulationUpdatedAtDesc = 'COMPANY_DOMAINS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsVarianceSampleIdAsc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_ID_ASC',
  CompanyDomainsVarianceSampleIdDesc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_ID_DESC',
  CompanyDomainsVarianceSampleCompanyIdAsc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsVarianceSampleCompanyIdDesc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsVarianceSampleDomainAsc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_DOMAIN_ASC',
  CompanyDomainsVarianceSampleDomainDesc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_DOMAIN_DESC',
  CompanyDomainsVarianceSampleCreatedAtAsc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsVarianceSampleCreatedAtDesc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsVarianceSampleUpdatedAtAsc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsVarianceSampleUpdatedAtDesc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsVariancePopulationIdAsc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_ID_ASC',
  CompanyDomainsVariancePopulationIdDesc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_ID_DESC',
  CompanyDomainsVariancePopulationCompanyIdAsc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsVariancePopulationCompanyIdDesc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsVariancePopulationDomainAsc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_DOMAIN_ASC',
  CompanyDomainsVariancePopulationDomainDesc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_DOMAIN_DESC',
  CompanyDomainsVariancePopulationCreatedAtAsc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyDomainsVariancePopulationCreatedAtDesc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyDomainsVariancePopulationUpdatedAtAsc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsVariancePopulationUpdatedAtDesc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsApprovedsCountAsc = 'COMPANY_DOMAINS_APPROVEDS_COUNT_ASC',
  CompanyDomainsApprovedsCountDesc = 'COMPANY_DOMAINS_APPROVEDS_COUNT_DESC',
  CompanyDomainsApprovedsSumCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_SUM_COMPANY_ID_ASC',
  CompanyDomainsApprovedsSumCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_SUM_COMPANY_ID_DESC',
  CompanyDomainsApprovedsSumDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_SUM_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsSumDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_SUM_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsSumCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_SUM_CREATED_AT_ASC',
  CompanyDomainsApprovedsSumCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_SUM_CREATED_AT_DESC',
  CompanyDomainsApprovedsSumUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_SUM_UPDATED_AT_ASC',
  CompanyDomainsApprovedsSumUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_SUM_UPDATED_AT_DESC',
  CompanyDomainsApprovedsDistinctCountCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyDomainsApprovedsDistinctCountCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyDomainsApprovedsDistinctCountDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsDistinctCountDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsDistinctCountCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyDomainsApprovedsDistinctCountCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyDomainsApprovedsDistinctCountUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyDomainsApprovedsDistinctCountUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyDomainsApprovedsMinCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_MIN_COMPANY_ID_ASC',
  CompanyDomainsApprovedsMinCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_MIN_COMPANY_ID_DESC',
  CompanyDomainsApprovedsMinDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_MIN_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsMinDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_MIN_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsMinCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_MIN_CREATED_AT_ASC',
  CompanyDomainsApprovedsMinCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_MIN_CREATED_AT_DESC',
  CompanyDomainsApprovedsMinUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_MIN_UPDATED_AT_ASC',
  CompanyDomainsApprovedsMinUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_MIN_UPDATED_AT_DESC',
  CompanyDomainsApprovedsMaxCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_MAX_COMPANY_ID_ASC',
  CompanyDomainsApprovedsMaxCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_MAX_COMPANY_ID_DESC',
  CompanyDomainsApprovedsMaxDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_MAX_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsMaxDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_MAX_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsMaxCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_MAX_CREATED_AT_ASC',
  CompanyDomainsApprovedsMaxCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_MAX_CREATED_AT_DESC',
  CompanyDomainsApprovedsMaxUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_MAX_UPDATED_AT_ASC',
  CompanyDomainsApprovedsMaxUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_MAX_UPDATED_AT_DESC',
  CompanyDomainsApprovedsAverageCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsAverageCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsAverageDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsAverageDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsAverageCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_CREATED_AT_ASC',
  CompanyDomainsApprovedsAverageCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_CREATED_AT_DESC',
  CompanyDomainsApprovedsAverageUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsAverageUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsStddevSampleCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsStddevSampleCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsStddevSampleDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsStddevSampleDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsStddevSampleCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsApprovedsStddevSampleCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsApprovedsStddevSampleUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsStddevSampleUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsStddevPopulationCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsApprovedsStddevPopulationCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsApprovedsStddevPopulationDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsStddevPopulationDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsStddevPopulationCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyDomainsApprovedsStddevPopulationCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyDomainsApprovedsStddevPopulationUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsApprovedsStddevPopulationUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsApprovedsVarianceSampleCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsVarianceSampleCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsVarianceSampleDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsVarianceSampleDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsVarianceSampleCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsApprovedsVarianceSampleCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsApprovedsVarianceSampleUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsVarianceSampleUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsVariancePopulationCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsApprovedsVariancePopulationCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsApprovedsVariancePopulationDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsVariancePopulationDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsVariancePopulationCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyDomainsApprovedsVariancePopulationCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyDomainsApprovedsVariancePopulationUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsApprovedsVariancePopulationUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsPathsCountAsc = 'COMPANY_DOMAINS_PATHS_COUNT_ASC',
  CompanyDomainsPathsCountDesc = 'COMPANY_DOMAINS_PATHS_COUNT_DESC',
  CompanyDomainsPathsSumIdAsc = 'COMPANY_DOMAINS_PATHS_SUM_ID_ASC',
  CompanyDomainsPathsSumIdDesc = 'COMPANY_DOMAINS_PATHS_SUM_ID_DESC',
  CompanyDomainsPathsSumCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_SUM_COMPANY_ID_ASC',
  CompanyDomainsPathsSumCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_SUM_COMPANY_ID_DESC',
  CompanyDomainsPathsSumDomainIdAsc = 'COMPANY_DOMAINS_PATHS_SUM_DOMAIN_ID_ASC',
  CompanyDomainsPathsSumDomainIdDesc = 'COMPANY_DOMAINS_PATHS_SUM_DOMAIN_ID_DESC',
  CompanyDomainsPathsSumPathAsc = 'COMPANY_DOMAINS_PATHS_SUM_PATH_ASC',
  CompanyDomainsPathsSumPathDesc = 'COMPANY_DOMAINS_PATHS_SUM_PATH_DESC',
  CompanyDomainsPathsSumCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_SUM_CREATED_AT_ASC',
  CompanyDomainsPathsSumCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_SUM_CREATED_AT_DESC',
  CompanyDomainsPathsSumUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_SUM_UPDATED_AT_ASC',
  CompanyDomainsPathsSumUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_SUM_UPDATED_AT_DESC',
  CompanyDomainsPathsDistinctCountIdAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_ID_ASC',
  CompanyDomainsPathsDistinctCountIdDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_ID_DESC',
  CompanyDomainsPathsDistinctCountCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyDomainsPathsDistinctCountCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyDomainsPathsDistinctCountDomainIdAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_DOMAIN_ID_ASC',
  CompanyDomainsPathsDistinctCountDomainIdDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_DOMAIN_ID_DESC',
  CompanyDomainsPathsDistinctCountPathAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_PATH_ASC',
  CompanyDomainsPathsDistinctCountPathDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_PATH_DESC',
  CompanyDomainsPathsDistinctCountCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyDomainsPathsDistinctCountCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyDomainsPathsDistinctCountUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyDomainsPathsDistinctCountUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyDomainsPathsMinIdAsc = 'COMPANY_DOMAINS_PATHS_MIN_ID_ASC',
  CompanyDomainsPathsMinIdDesc = 'COMPANY_DOMAINS_PATHS_MIN_ID_DESC',
  CompanyDomainsPathsMinCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_MIN_COMPANY_ID_ASC',
  CompanyDomainsPathsMinCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_MIN_COMPANY_ID_DESC',
  CompanyDomainsPathsMinDomainIdAsc = 'COMPANY_DOMAINS_PATHS_MIN_DOMAIN_ID_ASC',
  CompanyDomainsPathsMinDomainIdDesc = 'COMPANY_DOMAINS_PATHS_MIN_DOMAIN_ID_DESC',
  CompanyDomainsPathsMinPathAsc = 'COMPANY_DOMAINS_PATHS_MIN_PATH_ASC',
  CompanyDomainsPathsMinPathDesc = 'COMPANY_DOMAINS_PATHS_MIN_PATH_DESC',
  CompanyDomainsPathsMinCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_MIN_CREATED_AT_ASC',
  CompanyDomainsPathsMinCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_MIN_CREATED_AT_DESC',
  CompanyDomainsPathsMinUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_MIN_UPDATED_AT_ASC',
  CompanyDomainsPathsMinUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_MIN_UPDATED_AT_DESC',
  CompanyDomainsPathsMaxIdAsc = 'COMPANY_DOMAINS_PATHS_MAX_ID_ASC',
  CompanyDomainsPathsMaxIdDesc = 'COMPANY_DOMAINS_PATHS_MAX_ID_DESC',
  CompanyDomainsPathsMaxCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_MAX_COMPANY_ID_ASC',
  CompanyDomainsPathsMaxCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_MAX_COMPANY_ID_DESC',
  CompanyDomainsPathsMaxDomainIdAsc = 'COMPANY_DOMAINS_PATHS_MAX_DOMAIN_ID_ASC',
  CompanyDomainsPathsMaxDomainIdDesc = 'COMPANY_DOMAINS_PATHS_MAX_DOMAIN_ID_DESC',
  CompanyDomainsPathsMaxPathAsc = 'COMPANY_DOMAINS_PATHS_MAX_PATH_ASC',
  CompanyDomainsPathsMaxPathDesc = 'COMPANY_DOMAINS_PATHS_MAX_PATH_DESC',
  CompanyDomainsPathsMaxCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_MAX_CREATED_AT_ASC',
  CompanyDomainsPathsMaxCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_MAX_CREATED_AT_DESC',
  CompanyDomainsPathsMaxUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_MAX_UPDATED_AT_ASC',
  CompanyDomainsPathsMaxUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_MAX_UPDATED_AT_DESC',
  CompanyDomainsPathsAverageIdAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_ID_ASC',
  CompanyDomainsPathsAverageIdDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_ID_DESC',
  CompanyDomainsPathsAverageCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_COMPANY_ID_ASC',
  CompanyDomainsPathsAverageCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_COMPANY_ID_DESC',
  CompanyDomainsPathsAverageDomainIdAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_DOMAIN_ID_ASC',
  CompanyDomainsPathsAverageDomainIdDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_DOMAIN_ID_DESC',
  CompanyDomainsPathsAveragePathAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_PATH_ASC',
  CompanyDomainsPathsAveragePathDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_PATH_DESC',
  CompanyDomainsPathsAverageCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_CREATED_AT_ASC',
  CompanyDomainsPathsAverageCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_CREATED_AT_DESC',
  CompanyDomainsPathsAverageUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_UPDATED_AT_ASC',
  CompanyDomainsPathsAverageUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_UPDATED_AT_DESC',
  CompanyDomainsPathsStddevSampleIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_ID_ASC',
  CompanyDomainsPathsStddevSampleIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_ID_DESC',
  CompanyDomainsPathsStddevSampleCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsPathsStddevSampleCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsPathsStddevSampleDomainIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsPathsStddevSampleDomainIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsPathsStddevSamplePathAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_PATH_ASC',
  CompanyDomainsPathsStddevSamplePathDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_PATH_DESC',
  CompanyDomainsPathsStddevSampleCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsPathsStddevSampleCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsPathsStddevSampleUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsPathsStddevSampleUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsPathsStddevPopulationIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_ID_ASC',
  CompanyDomainsPathsStddevPopulationIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_ID_DESC',
  CompanyDomainsPathsStddevPopulationCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsPathsStddevPopulationCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsPathsStddevPopulationDomainIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsPathsStddevPopulationDomainIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsPathsStddevPopulationPathAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_PATH_ASC',
  CompanyDomainsPathsStddevPopulationPathDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_PATH_DESC',
  CompanyDomainsPathsStddevPopulationCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyDomainsPathsStddevPopulationCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyDomainsPathsStddevPopulationUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsPathsStddevPopulationUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsPathsVarianceSampleIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_ID_ASC',
  CompanyDomainsPathsVarianceSampleIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_ID_DESC',
  CompanyDomainsPathsVarianceSampleCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsPathsVarianceSampleCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsPathsVarianceSampleDomainIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsPathsVarianceSampleDomainIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsPathsVarianceSamplePathAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_PATH_ASC',
  CompanyDomainsPathsVarianceSamplePathDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_PATH_DESC',
  CompanyDomainsPathsVarianceSampleCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsPathsVarianceSampleCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsPathsVarianceSampleUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsPathsVarianceSampleUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsPathsVariancePopulationIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_ID_ASC',
  CompanyDomainsPathsVariancePopulationIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_ID_DESC',
  CompanyDomainsPathsVariancePopulationCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsPathsVariancePopulationCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsPathsVariancePopulationDomainIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsPathsVariancePopulationDomainIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsPathsVariancePopulationPathAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_PATH_ASC',
  CompanyDomainsPathsVariancePopulationPathDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_PATH_DESC',
  CompanyDomainsPathsVariancePopulationCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyDomainsPathsVariancePopulationCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyDomainsPathsVariancePopulationUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsPathsVariancePopulationUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ReviewsCountAsc = 'REVIEWS_COUNT_ASC',
  ReviewsCountDesc = 'REVIEWS_COUNT_DESC',
  ReviewsSumCompanyIdAsc = 'REVIEWS_SUM_COMPANY_ID_ASC',
  ReviewsSumCompanyIdDesc = 'REVIEWS_SUM_COMPANY_ID_DESC',
  ReviewsSumProviderAsc = 'REVIEWS_SUM_PROVIDER_ASC',
  ReviewsSumProviderDesc = 'REVIEWS_SUM_PROVIDER_DESC',
  ReviewsSumAuthorNameAsc = 'REVIEWS_SUM_AUTHOR_NAME_ASC',
  ReviewsSumAuthorNameDesc = 'REVIEWS_SUM_AUTHOR_NAME_DESC',
  ReviewsSumAuthorPhotoUrlAsc = 'REVIEWS_SUM_AUTHOR_PHOTO_URL_ASC',
  ReviewsSumAuthorPhotoUrlDesc = 'REVIEWS_SUM_AUTHOR_PHOTO_URL_DESC',
  ReviewsSumAuthorUrlAsc = 'REVIEWS_SUM_AUTHOR_URL_ASC',
  ReviewsSumAuthorUrlDesc = 'REVIEWS_SUM_AUTHOR_URL_DESC',
  ReviewsSumLanguageAsc = 'REVIEWS_SUM_LANGUAGE_ASC',
  ReviewsSumLanguageDesc = 'REVIEWS_SUM_LANGUAGE_DESC',
  ReviewsSumRatingAsc = 'REVIEWS_SUM_RATING_ASC',
  ReviewsSumRatingDesc = 'REVIEWS_SUM_RATING_DESC',
  ReviewsSumTextAsc = 'REVIEWS_SUM_TEXT_ASC',
  ReviewsSumTextDesc = 'REVIEWS_SUM_TEXT_DESC',
  ReviewsSumTimestampAsc = 'REVIEWS_SUM_TIMESTAMP_ASC',
  ReviewsSumTimestampDesc = 'REVIEWS_SUM_TIMESTAMP_DESC',
  ReviewsSumCreatedAtAsc = 'REVIEWS_SUM_CREATED_AT_ASC',
  ReviewsSumCreatedAtDesc = 'REVIEWS_SUM_CREATED_AT_DESC',
  ReviewsSumUpdatedAtAsc = 'REVIEWS_SUM_UPDATED_AT_ASC',
  ReviewsSumUpdatedAtDesc = 'REVIEWS_SUM_UPDATED_AT_DESC',
  ReviewsDistinctCountCompanyIdAsc = 'REVIEWS_DISTINCT_COUNT_COMPANY_ID_ASC',
  ReviewsDistinctCountCompanyIdDesc = 'REVIEWS_DISTINCT_COUNT_COMPANY_ID_DESC',
  ReviewsDistinctCountProviderAsc = 'REVIEWS_DISTINCT_COUNT_PROVIDER_ASC',
  ReviewsDistinctCountProviderDesc = 'REVIEWS_DISTINCT_COUNT_PROVIDER_DESC',
  ReviewsDistinctCountAuthorNameAsc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_NAME_ASC',
  ReviewsDistinctCountAuthorNameDesc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_NAME_DESC',
  ReviewsDistinctCountAuthorPhotoUrlAsc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_PHOTO_URL_ASC',
  ReviewsDistinctCountAuthorPhotoUrlDesc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_PHOTO_URL_DESC',
  ReviewsDistinctCountAuthorUrlAsc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_URL_ASC',
  ReviewsDistinctCountAuthorUrlDesc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_URL_DESC',
  ReviewsDistinctCountLanguageAsc = 'REVIEWS_DISTINCT_COUNT_LANGUAGE_ASC',
  ReviewsDistinctCountLanguageDesc = 'REVIEWS_DISTINCT_COUNT_LANGUAGE_DESC',
  ReviewsDistinctCountRatingAsc = 'REVIEWS_DISTINCT_COUNT_RATING_ASC',
  ReviewsDistinctCountRatingDesc = 'REVIEWS_DISTINCT_COUNT_RATING_DESC',
  ReviewsDistinctCountTextAsc = 'REVIEWS_DISTINCT_COUNT_TEXT_ASC',
  ReviewsDistinctCountTextDesc = 'REVIEWS_DISTINCT_COUNT_TEXT_DESC',
  ReviewsDistinctCountTimestampAsc = 'REVIEWS_DISTINCT_COUNT_TIMESTAMP_ASC',
  ReviewsDistinctCountTimestampDesc = 'REVIEWS_DISTINCT_COUNT_TIMESTAMP_DESC',
  ReviewsDistinctCountCreatedAtAsc = 'REVIEWS_DISTINCT_COUNT_CREATED_AT_ASC',
  ReviewsDistinctCountCreatedAtDesc = 'REVIEWS_DISTINCT_COUNT_CREATED_AT_DESC',
  ReviewsDistinctCountUpdatedAtAsc = 'REVIEWS_DISTINCT_COUNT_UPDATED_AT_ASC',
  ReviewsDistinctCountUpdatedAtDesc = 'REVIEWS_DISTINCT_COUNT_UPDATED_AT_DESC',
  ReviewsMinCompanyIdAsc = 'REVIEWS_MIN_COMPANY_ID_ASC',
  ReviewsMinCompanyIdDesc = 'REVIEWS_MIN_COMPANY_ID_DESC',
  ReviewsMinProviderAsc = 'REVIEWS_MIN_PROVIDER_ASC',
  ReviewsMinProviderDesc = 'REVIEWS_MIN_PROVIDER_DESC',
  ReviewsMinAuthorNameAsc = 'REVIEWS_MIN_AUTHOR_NAME_ASC',
  ReviewsMinAuthorNameDesc = 'REVIEWS_MIN_AUTHOR_NAME_DESC',
  ReviewsMinAuthorPhotoUrlAsc = 'REVIEWS_MIN_AUTHOR_PHOTO_URL_ASC',
  ReviewsMinAuthorPhotoUrlDesc = 'REVIEWS_MIN_AUTHOR_PHOTO_URL_DESC',
  ReviewsMinAuthorUrlAsc = 'REVIEWS_MIN_AUTHOR_URL_ASC',
  ReviewsMinAuthorUrlDesc = 'REVIEWS_MIN_AUTHOR_URL_DESC',
  ReviewsMinLanguageAsc = 'REVIEWS_MIN_LANGUAGE_ASC',
  ReviewsMinLanguageDesc = 'REVIEWS_MIN_LANGUAGE_DESC',
  ReviewsMinRatingAsc = 'REVIEWS_MIN_RATING_ASC',
  ReviewsMinRatingDesc = 'REVIEWS_MIN_RATING_DESC',
  ReviewsMinTextAsc = 'REVIEWS_MIN_TEXT_ASC',
  ReviewsMinTextDesc = 'REVIEWS_MIN_TEXT_DESC',
  ReviewsMinTimestampAsc = 'REVIEWS_MIN_TIMESTAMP_ASC',
  ReviewsMinTimestampDesc = 'REVIEWS_MIN_TIMESTAMP_DESC',
  ReviewsMinCreatedAtAsc = 'REVIEWS_MIN_CREATED_AT_ASC',
  ReviewsMinCreatedAtDesc = 'REVIEWS_MIN_CREATED_AT_DESC',
  ReviewsMinUpdatedAtAsc = 'REVIEWS_MIN_UPDATED_AT_ASC',
  ReviewsMinUpdatedAtDesc = 'REVIEWS_MIN_UPDATED_AT_DESC',
  ReviewsMaxCompanyIdAsc = 'REVIEWS_MAX_COMPANY_ID_ASC',
  ReviewsMaxCompanyIdDesc = 'REVIEWS_MAX_COMPANY_ID_DESC',
  ReviewsMaxProviderAsc = 'REVIEWS_MAX_PROVIDER_ASC',
  ReviewsMaxProviderDesc = 'REVIEWS_MAX_PROVIDER_DESC',
  ReviewsMaxAuthorNameAsc = 'REVIEWS_MAX_AUTHOR_NAME_ASC',
  ReviewsMaxAuthorNameDesc = 'REVIEWS_MAX_AUTHOR_NAME_DESC',
  ReviewsMaxAuthorPhotoUrlAsc = 'REVIEWS_MAX_AUTHOR_PHOTO_URL_ASC',
  ReviewsMaxAuthorPhotoUrlDesc = 'REVIEWS_MAX_AUTHOR_PHOTO_URL_DESC',
  ReviewsMaxAuthorUrlAsc = 'REVIEWS_MAX_AUTHOR_URL_ASC',
  ReviewsMaxAuthorUrlDesc = 'REVIEWS_MAX_AUTHOR_URL_DESC',
  ReviewsMaxLanguageAsc = 'REVIEWS_MAX_LANGUAGE_ASC',
  ReviewsMaxLanguageDesc = 'REVIEWS_MAX_LANGUAGE_DESC',
  ReviewsMaxRatingAsc = 'REVIEWS_MAX_RATING_ASC',
  ReviewsMaxRatingDesc = 'REVIEWS_MAX_RATING_DESC',
  ReviewsMaxTextAsc = 'REVIEWS_MAX_TEXT_ASC',
  ReviewsMaxTextDesc = 'REVIEWS_MAX_TEXT_DESC',
  ReviewsMaxTimestampAsc = 'REVIEWS_MAX_TIMESTAMP_ASC',
  ReviewsMaxTimestampDesc = 'REVIEWS_MAX_TIMESTAMP_DESC',
  ReviewsMaxCreatedAtAsc = 'REVIEWS_MAX_CREATED_AT_ASC',
  ReviewsMaxCreatedAtDesc = 'REVIEWS_MAX_CREATED_AT_DESC',
  ReviewsMaxUpdatedAtAsc = 'REVIEWS_MAX_UPDATED_AT_ASC',
  ReviewsMaxUpdatedAtDesc = 'REVIEWS_MAX_UPDATED_AT_DESC',
  ReviewsAverageCompanyIdAsc = 'REVIEWS_AVERAGE_COMPANY_ID_ASC',
  ReviewsAverageCompanyIdDesc = 'REVIEWS_AVERAGE_COMPANY_ID_DESC',
  ReviewsAverageProviderAsc = 'REVIEWS_AVERAGE_PROVIDER_ASC',
  ReviewsAverageProviderDesc = 'REVIEWS_AVERAGE_PROVIDER_DESC',
  ReviewsAverageAuthorNameAsc = 'REVIEWS_AVERAGE_AUTHOR_NAME_ASC',
  ReviewsAverageAuthorNameDesc = 'REVIEWS_AVERAGE_AUTHOR_NAME_DESC',
  ReviewsAverageAuthorPhotoUrlAsc = 'REVIEWS_AVERAGE_AUTHOR_PHOTO_URL_ASC',
  ReviewsAverageAuthorPhotoUrlDesc = 'REVIEWS_AVERAGE_AUTHOR_PHOTO_URL_DESC',
  ReviewsAverageAuthorUrlAsc = 'REVIEWS_AVERAGE_AUTHOR_URL_ASC',
  ReviewsAverageAuthorUrlDesc = 'REVIEWS_AVERAGE_AUTHOR_URL_DESC',
  ReviewsAverageLanguageAsc = 'REVIEWS_AVERAGE_LANGUAGE_ASC',
  ReviewsAverageLanguageDesc = 'REVIEWS_AVERAGE_LANGUAGE_DESC',
  ReviewsAverageRatingAsc = 'REVIEWS_AVERAGE_RATING_ASC',
  ReviewsAverageRatingDesc = 'REVIEWS_AVERAGE_RATING_DESC',
  ReviewsAverageTextAsc = 'REVIEWS_AVERAGE_TEXT_ASC',
  ReviewsAverageTextDesc = 'REVIEWS_AVERAGE_TEXT_DESC',
  ReviewsAverageTimestampAsc = 'REVIEWS_AVERAGE_TIMESTAMP_ASC',
  ReviewsAverageTimestampDesc = 'REVIEWS_AVERAGE_TIMESTAMP_DESC',
  ReviewsAverageCreatedAtAsc = 'REVIEWS_AVERAGE_CREATED_AT_ASC',
  ReviewsAverageCreatedAtDesc = 'REVIEWS_AVERAGE_CREATED_AT_DESC',
  ReviewsAverageUpdatedAtAsc = 'REVIEWS_AVERAGE_UPDATED_AT_ASC',
  ReviewsAverageUpdatedAtDesc = 'REVIEWS_AVERAGE_UPDATED_AT_DESC',
  ReviewsStddevSampleCompanyIdAsc = 'REVIEWS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ReviewsStddevSampleCompanyIdDesc = 'REVIEWS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ReviewsStddevSampleProviderAsc = 'REVIEWS_STDDEV_SAMPLE_PROVIDER_ASC',
  ReviewsStddevSampleProviderDesc = 'REVIEWS_STDDEV_SAMPLE_PROVIDER_DESC',
  ReviewsStddevSampleAuthorNameAsc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_NAME_ASC',
  ReviewsStddevSampleAuthorNameDesc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_NAME_DESC',
  ReviewsStddevSampleAuthorPhotoUrlAsc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_PHOTO_URL_ASC',
  ReviewsStddevSampleAuthorPhotoUrlDesc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_PHOTO_URL_DESC',
  ReviewsStddevSampleAuthorUrlAsc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_URL_ASC',
  ReviewsStddevSampleAuthorUrlDesc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_URL_DESC',
  ReviewsStddevSampleLanguageAsc = 'REVIEWS_STDDEV_SAMPLE_LANGUAGE_ASC',
  ReviewsStddevSampleLanguageDesc = 'REVIEWS_STDDEV_SAMPLE_LANGUAGE_DESC',
  ReviewsStddevSampleRatingAsc = 'REVIEWS_STDDEV_SAMPLE_RATING_ASC',
  ReviewsStddevSampleRatingDesc = 'REVIEWS_STDDEV_SAMPLE_RATING_DESC',
  ReviewsStddevSampleTextAsc = 'REVIEWS_STDDEV_SAMPLE_TEXT_ASC',
  ReviewsStddevSampleTextDesc = 'REVIEWS_STDDEV_SAMPLE_TEXT_DESC',
  ReviewsStddevSampleTimestampAsc = 'REVIEWS_STDDEV_SAMPLE_TIMESTAMP_ASC',
  ReviewsStddevSampleTimestampDesc = 'REVIEWS_STDDEV_SAMPLE_TIMESTAMP_DESC',
  ReviewsStddevSampleCreatedAtAsc = 'REVIEWS_STDDEV_SAMPLE_CREATED_AT_ASC',
  ReviewsStddevSampleCreatedAtDesc = 'REVIEWS_STDDEV_SAMPLE_CREATED_AT_DESC',
  ReviewsStddevSampleUpdatedAtAsc = 'REVIEWS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ReviewsStddevSampleUpdatedAtDesc = 'REVIEWS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ReviewsStddevPopulationCompanyIdAsc = 'REVIEWS_STDDEV_POPULATION_COMPANY_ID_ASC',
  ReviewsStddevPopulationCompanyIdDesc = 'REVIEWS_STDDEV_POPULATION_COMPANY_ID_DESC',
  ReviewsStddevPopulationProviderAsc = 'REVIEWS_STDDEV_POPULATION_PROVIDER_ASC',
  ReviewsStddevPopulationProviderDesc = 'REVIEWS_STDDEV_POPULATION_PROVIDER_DESC',
  ReviewsStddevPopulationAuthorNameAsc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_NAME_ASC',
  ReviewsStddevPopulationAuthorNameDesc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_NAME_DESC',
  ReviewsStddevPopulationAuthorPhotoUrlAsc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_PHOTO_URL_ASC',
  ReviewsStddevPopulationAuthorPhotoUrlDesc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_PHOTO_URL_DESC',
  ReviewsStddevPopulationAuthorUrlAsc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_URL_ASC',
  ReviewsStddevPopulationAuthorUrlDesc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_URL_DESC',
  ReviewsStddevPopulationLanguageAsc = 'REVIEWS_STDDEV_POPULATION_LANGUAGE_ASC',
  ReviewsStddevPopulationLanguageDesc = 'REVIEWS_STDDEV_POPULATION_LANGUAGE_DESC',
  ReviewsStddevPopulationRatingAsc = 'REVIEWS_STDDEV_POPULATION_RATING_ASC',
  ReviewsStddevPopulationRatingDesc = 'REVIEWS_STDDEV_POPULATION_RATING_DESC',
  ReviewsStddevPopulationTextAsc = 'REVIEWS_STDDEV_POPULATION_TEXT_ASC',
  ReviewsStddevPopulationTextDesc = 'REVIEWS_STDDEV_POPULATION_TEXT_DESC',
  ReviewsStddevPopulationTimestampAsc = 'REVIEWS_STDDEV_POPULATION_TIMESTAMP_ASC',
  ReviewsStddevPopulationTimestampDesc = 'REVIEWS_STDDEV_POPULATION_TIMESTAMP_DESC',
  ReviewsStddevPopulationCreatedAtAsc = 'REVIEWS_STDDEV_POPULATION_CREATED_AT_ASC',
  ReviewsStddevPopulationCreatedAtDesc = 'REVIEWS_STDDEV_POPULATION_CREATED_AT_DESC',
  ReviewsStddevPopulationUpdatedAtAsc = 'REVIEWS_STDDEV_POPULATION_UPDATED_AT_ASC',
  ReviewsStddevPopulationUpdatedAtDesc = 'REVIEWS_STDDEV_POPULATION_UPDATED_AT_DESC',
  ReviewsVarianceSampleCompanyIdAsc = 'REVIEWS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ReviewsVarianceSampleCompanyIdDesc = 'REVIEWS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ReviewsVarianceSampleProviderAsc = 'REVIEWS_VARIANCE_SAMPLE_PROVIDER_ASC',
  ReviewsVarianceSampleProviderDesc = 'REVIEWS_VARIANCE_SAMPLE_PROVIDER_DESC',
  ReviewsVarianceSampleAuthorNameAsc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_NAME_ASC',
  ReviewsVarianceSampleAuthorNameDesc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_NAME_DESC',
  ReviewsVarianceSampleAuthorPhotoUrlAsc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_PHOTO_URL_ASC',
  ReviewsVarianceSampleAuthorPhotoUrlDesc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_PHOTO_URL_DESC',
  ReviewsVarianceSampleAuthorUrlAsc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_URL_ASC',
  ReviewsVarianceSampleAuthorUrlDesc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_URL_DESC',
  ReviewsVarianceSampleLanguageAsc = 'REVIEWS_VARIANCE_SAMPLE_LANGUAGE_ASC',
  ReviewsVarianceSampleLanguageDesc = 'REVIEWS_VARIANCE_SAMPLE_LANGUAGE_DESC',
  ReviewsVarianceSampleRatingAsc = 'REVIEWS_VARIANCE_SAMPLE_RATING_ASC',
  ReviewsVarianceSampleRatingDesc = 'REVIEWS_VARIANCE_SAMPLE_RATING_DESC',
  ReviewsVarianceSampleTextAsc = 'REVIEWS_VARIANCE_SAMPLE_TEXT_ASC',
  ReviewsVarianceSampleTextDesc = 'REVIEWS_VARIANCE_SAMPLE_TEXT_DESC',
  ReviewsVarianceSampleTimestampAsc = 'REVIEWS_VARIANCE_SAMPLE_TIMESTAMP_ASC',
  ReviewsVarianceSampleTimestampDesc = 'REVIEWS_VARIANCE_SAMPLE_TIMESTAMP_DESC',
  ReviewsVarianceSampleCreatedAtAsc = 'REVIEWS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ReviewsVarianceSampleCreatedAtDesc = 'REVIEWS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ReviewsVarianceSampleUpdatedAtAsc = 'REVIEWS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ReviewsVarianceSampleUpdatedAtDesc = 'REVIEWS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ReviewsVariancePopulationCompanyIdAsc = 'REVIEWS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ReviewsVariancePopulationCompanyIdDesc = 'REVIEWS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ReviewsVariancePopulationProviderAsc = 'REVIEWS_VARIANCE_POPULATION_PROVIDER_ASC',
  ReviewsVariancePopulationProviderDesc = 'REVIEWS_VARIANCE_POPULATION_PROVIDER_DESC',
  ReviewsVariancePopulationAuthorNameAsc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_NAME_ASC',
  ReviewsVariancePopulationAuthorNameDesc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_NAME_DESC',
  ReviewsVariancePopulationAuthorPhotoUrlAsc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_PHOTO_URL_ASC',
  ReviewsVariancePopulationAuthorPhotoUrlDesc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_PHOTO_URL_DESC',
  ReviewsVariancePopulationAuthorUrlAsc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_URL_ASC',
  ReviewsVariancePopulationAuthorUrlDesc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_URL_DESC',
  ReviewsVariancePopulationLanguageAsc = 'REVIEWS_VARIANCE_POPULATION_LANGUAGE_ASC',
  ReviewsVariancePopulationLanguageDesc = 'REVIEWS_VARIANCE_POPULATION_LANGUAGE_DESC',
  ReviewsVariancePopulationRatingAsc = 'REVIEWS_VARIANCE_POPULATION_RATING_ASC',
  ReviewsVariancePopulationRatingDesc = 'REVIEWS_VARIANCE_POPULATION_RATING_DESC',
  ReviewsVariancePopulationTextAsc = 'REVIEWS_VARIANCE_POPULATION_TEXT_ASC',
  ReviewsVariancePopulationTextDesc = 'REVIEWS_VARIANCE_POPULATION_TEXT_DESC',
  ReviewsVariancePopulationTimestampAsc = 'REVIEWS_VARIANCE_POPULATION_TIMESTAMP_ASC',
  ReviewsVariancePopulationTimestampDesc = 'REVIEWS_VARIANCE_POPULATION_TIMESTAMP_DESC',
  ReviewsVariancePopulationCreatedAtAsc = 'REVIEWS_VARIANCE_POPULATION_CREATED_AT_ASC',
  ReviewsVariancePopulationCreatedAtDesc = 'REVIEWS_VARIANCE_POPULATION_CREATED_AT_DESC',
  ReviewsVariancePopulationUpdatedAtAsc = 'REVIEWS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ReviewsVariancePopulationUpdatedAtDesc = 'REVIEWS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  AnalyticsCountAsc = 'ANALYTICS_COUNT_ASC',
  AnalyticsCountDesc = 'ANALYTICS_COUNT_DESC',
  AnalyticsSumIdAsc = 'ANALYTICS_SUM_ID_ASC',
  AnalyticsSumIdDesc = 'ANALYTICS_SUM_ID_DESC',
  AnalyticsSumClientIdAsc = 'ANALYTICS_SUM_CLIENT_ID_ASC',
  AnalyticsSumClientIdDesc = 'ANALYTICS_SUM_CLIENT_ID_DESC',
  AnalyticsSumRequestIdAsc = 'ANALYTICS_SUM_REQUEST_ID_ASC',
  AnalyticsSumRequestIdDesc = 'ANALYTICS_SUM_REQUEST_ID_DESC',
  AnalyticsSumEventCategoryAsc = 'ANALYTICS_SUM_EVENT_CATEGORY_ASC',
  AnalyticsSumEventCategoryDesc = 'ANALYTICS_SUM_EVENT_CATEGORY_DESC',
  AnalyticsSumEventActionAsc = 'ANALYTICS_SUM_EVENT_ACTION_ASC',
  AnalyticsSumEventActionDesc = 'ANALYTICS_SUM_EVENT_ACTION_DESC',
  AnalyticsSumSourceUrlAsc = 'ANALYTICS_SUM_SOURCE_URL_ASC',
  AnalyticsSumSourceUrlDesc = 'ANALYTICS_SUM_SOURCE_URL_DESC',
  AnalyticsSumReferrerUrlAsc = 'ANALYTICS_SUM_REFERRER_URL_ASC',
  AnalyticsSumReferrerUrlDesc = 'ANALYTICS_SUM_REFERRER_URL_DESC',
  AnalyticsSumUserAgentAsc = 'ANALYTICS_SUM_USER_AGENT_ASC',
  AnalyticsSumUserAgentDesc = 'ANALYTICS_SUM_USER_AGENT_DESC',
  AnalyticsSumUserIpAsc = 'ANALYTICS_SUM_USER_IP_ASC',
  AnalyticsSumUserIpDesc = 'ANALYTICS_SUM_USER_IP_DESC',
  AnalyticsSumCompanyIdAsc = 'ANALYTICS_SUM_COMPANY_ID_ASC',
  AnalyticsSumCompanyIdDesc = 'ANALYTICS_SUM_COMPANY_ID_DESC',
  AnalyticsSumContextAsc = 'ANALYTICS_SUM_CONTEXT_ASC',
  AnalyticsSumContextDesc = 'ANALYTICS_SUM_CONTEXT_DESC',
  AnalyticsSumCreatedAtAsc = 'ANALYTICS_SUM_CREATED_AT_ASC',
  AnalyticsSumCreatedAtDesc = 'ANALYTICS_SUM_CREATED_AT_DESC',
  AnalyticsSumUpdatedAtAsc = 'ANALYTICS_SUM_UPDATED_AT_ASC',
  AnalyticsSumUpdatedAtDesc = 'ANALYTICS_SUM_UPDATED_AT_DESC',
  AnalyticsDistinctCountIdAsc = 'ANALYTICS_DISTINCT_COUNT_ID_ASC',
  AnalyticsDistinctCountIdDesc = 'ANALYTICS_DISTINCT_COUNT_ID_DESC',
  AnalyticsDistinctCountClientIdAsc = 'ANALYTICS_DISTINCT_COUNT_CLIENT_ID_ASC',
  AnalyticsDistinctCountClientIdDesc = 'ANALYTICS_DISTINCT_COUNT_CLIENT_ID_DESC',
  AnalyticsDistinctCountRequestIdAsc = 'ANALYTICS_DISTINCT_COUNT_REQUEST_ID_ASC',
  AnalyticsDistinctCountRequestIdDesc = 'ANALYTICS_DISTINCT_COUNT_REQUEST_ID_DESC',
  AnalyticsDistinctCountEventCategoryAsc = 'ANALYTICS_DISTINCT_COUNT_EVENT_CATEGORY_ASC',
  AnalyticsDistinctCountEventCategoryDesc = 'ANALYTICS_DISTINCT_COUNT_EVENT_CATEGORY_DESC',
  AnalyticsDistinctCountEventActionAsc = 'ANALYTICS_DISTINCT_COUNT_EVENT_ACTION_ASC',
  AnalyticsDistinctCountEventActionDesc = 'ANALYTICS_DISTINCT_COUNT_EVENT_ACTION_DESC',
  AnalyticsDistinctCountSourceUrlAsc = 'ANALYTICS_DISTINCT_COUNT_SOURCE_URL_ASC',
  AnalyticsDistinctCountSourceUrlDesc = 'ANALYTICS_DISTINCT_COUNT_SOURCE_URL_DESC',
  AnalyticsDistinctCountReferrerUrlAsc = 'ANALYTICS_DISTINCT_COUNT_REFERRER_URL_ASC',
  AnalyticsDistinctCountReferrerUrlDesc = 'ANALYTICS_DISTINCT_COUNT_REFERRER_URL_DESC',
  AnalyticsDistinctCountUserAgentAsc = 'ANALYTICS_DISTINCT_COUNT_USER_AGENT_ASC',
  AnalyticsDistinctCountUserAgentDesc = 'ANALYTICS_DISTINCT_COUNT_USER_AGENT_DESC',
  AnalyticsDistinctCountUserIpAsc = 'ANALYTICS_DISTINCT_COUNT_USER_IP_ASC',
  AnalyticsDistinctCountUserIpDesc = 'ANALYTICS_DISTINCT_COUNT_USER_IP_DESC',
  AnalyticsDistinctCountCompanyIdAsc = 'ANALYTICS_DISTINCT_COUNT_COMPANY_ID_ASC',
  AnalyticsDistinctCountCompanyIdDesc = 'ANALYTICS_DISTINCT_COUNT_COMPANY_ID_DESC',
  AnalyticsDistinctCountContextAsc = 'ANALYTICS_DISTINCT_COUNT_CONTEXT_ASC',
  AnalyticsDistinctCountContextDesc = 'ANALYTICS_DISTINCT_COUNT_CONTEXT_DESC',
  AnalyticsDistinctCountCreatedAtAsc = 'ANALYTICS_DISTINCT_COUNT_CREATED_AT_ASC',
  AnalyticsDistinctCountCreatedAtDesc = 'ANALYTICS_DISTINCT_COUNT_CREATED_AT_DESC',
  AnalyticsDistinctCountUpdatedAtAsc = 'ANALYTICS_DISTINCT_COUNT_UPDATED_AT_ASC',
  AnalyticsDistinctCountUpdatedAtDesc = 'ANALYTICS_DISTINCT_COUNT_UPDATED_AT_DESC',
  AnalyticsMinIdAsc = 'ANALYTICS_MIN_ID_ASC',
  AnalyticsMinIdDesc = 'ANALYTICS_MIN_ID_DESC',
  AnalyticsMinClientIdAsc = 'ANALYTICS_MIN_CLIENT_ID_ASC',
  AnalyticsMinClientIdDesc = 'ANALYTICS_MIN_CLIENT_ID_DESC',
  AnalyticsMinRequestIdAsc = 'ANALYTICS_MIN_REQUEST_ID_ASC',
  AnalyticsMinRequestIdDesc = 'ANALYTICS_MIN_REQUEST_ID_DESC',
  AnalyticsMinEventCategoryAsc = 'ANALYTICS_MIN_EVENT_CATEGORY_ASC',
  AnalyticsMinEventCategoryDesc = 'ANALYTICS_MIN_EVENT_CATEGORY_DESC',
  AnalyticsMinEventActionAsc = 'ANALYTICS_MIN_EVENT_ACTION_ASC',
  AnalyticsMinEventActionDesc = 'ANALYTICS_MIN_EVENT_ACTION_DESC',
  AnalyticsMinSourceUrlAsc = 'ANALYTICS_MIN_SOURCE_URL_ASC',
  AnalyticsMinSourceUrlDesc = 'ANALYTICS_MIN_SOURCE_URL_DESC',
  AnalyticsMinReferrerUrlAsc = 'ANALYTICS_MIN_REFERRER_URL_ASC',
  AnalyticsMinReferrerUrlDesc = 'ANALYTICS_MIN_REFERRER_URL_DESC',
  AnalyticsMinUserAgentAsc = 'ANALYTICS_MIN_USER_AGENT_ASC',
  AnalyticsMinUserAgentDesc = 'ANALYTICS_MIN_USER_AGENT_DESC',
  AnalyticsMinUserIpAsc = 'ANALYTICS_MIN_USER_IP_ASC',
  AnalyticsMinUserIpDesc = 'ANALYTICS_MIN_USER_IP_DESC',
  AnalyticsMinCompanyIdAsc = 'ANALYTICS_MIN_COMPANY_ID_ASC',
  AnalyticsMinCompanyIdDesc = 'ANALYTICS_MIN_COMPANY_ID_DESC',
  AnalyticsMinContextAsc = 'ANALYTICS_MIN_CONTEXT_ASC',
  AnalyticsMinContextDesc = 'ANALYTICS_MIN_CONTEXT_DESC',
  AnalyticsMinCreatedAtAsc = 'ANALYTICS_MIN_CREATED_AT_ASC',
  AnalyticsMinCreatedAtDesc = 'ANALYTICS_MIN_CREATED_AT_DESC',
  AnalyticsMinUpdatedAtAsc = 'ANALYTICS_MIN_UPDATED_AT_ASC',
  AnalyticsMinUpdatedAtDesc = 'ANALYTICS_MIN_UPDATED_AT_DESC',
  AnalyticsMaxIdAsc = 'ANALYTICS_MAX_ID_ASC',
  AnalyticsMaxIdDesc = 'ANALYTICS_MAX_ID_DESC',
  AnalyticsMaxClientIdAsc = 'ANALYTICS_MAX_CLIENT_ID_ASC',
  AnalyticsMaxClientIdDesc = 'ANALYTICS_MAX_CLIENT_ID_DESC',
  AnalyticsMaxRequestIdAsc = 'ANALYTICS_MAX_REQUEST_ID_ASC',
  AnalyticsMaxRequestIdDesc = 'ANALYTICS_MAX_REQUEST_ID_DESC',
  AnalyticsMaxEventCategoryAsc = 'ANALYTICS_MAX_EVENT_CATEGORY_ASC',
  AnalyticsMaxEventCategoryDesc = 'ANALYTICS_MAX_EVENT_CATEGORY_DESC',
  AnalyticsMaxEventActionAsc = 'ANALYTICS_MAX_EVENT_ACTION_ASC',
  AnalyticsMaxEventActionDesc = 'ANALYTICS_MAX_EVENT_ACTION_DESC',
  AnalyticsMaxSourceUrlAsc = 'ANALYTICS_MAX_SOURCE_URL_ASC',
  AnalyticsMaxSourceUrlDesc = 'ANALYTICS_MAX_SOURCE_URL_DESC',
  AnalyticsMaxReferrerUrlAsc = 'ANALYTICS_MAX_REFERRER_URL_ASC',
  AnalyticsMaxReferrerUrlDesc = 'ANALYTICS_MAX_REFERRER_URL_DESC',
  AnalyticsMaxUserAgentAsc = 'ANALYTICS_MAX_USER_AGENT_ASC',
  AnalyticsMaxUserAgentDesc = 'ANALYTICS_MAX_USER_AGENT_DESC',
  AnalyticsMaxUserIpAsc = 'ANALYTICS_MAX_USER_IP_ASC',
  AnalyticsMaxUserIpDesc = 'ANALYTICS_MAX_USER_IP_DESC',
  AnalyticsMaxCompanyIdAsc = 'ANALYTICS_MAX_COMPANY_ID_ASC',
  AnalyticsMaxCompanyIdDesc = 'ANALYTICS_MAX_COMPANY_ID_DESC',
  AnalyticsMaxContextAsc = 'ANALYTICS_MAX_CONTEXT_ASC',
  AnalyticsMaxContextDesc = 'ANALYTICS_MAX_CONTEXT_DESC',
  AnalyticsMaxCreatedAtAsc = 'ANALYTICS_MAX_CREATED_AT_ASC',
  AnalyticsMaxCreatedAtDesc = 'ANALYTICS_MAX_CREATED_AT_DESC',
  AnalyticsMaxUpdatedAtAsc = 'ANALYTICS_MAX_UPDATED_AT_ASC',
  AnalyticsMaxUpdatedAtDesc = 'ANALYTICS_MAX_UPDATED_AT_DESC',
  AnalyticsAverageIdAsc = 'ANALYTICS_AVERAGE_ID_ASC',
  AnalyticsAverageIdDesc = 'ANALYTICS_AVERAGE_ID_DESC',
  AnalyticsAverageClientIdAsc = 'ANALYTICS_AVERAGE_CLIENT_ID_ASC',
  AnalyticsAverageClientIdDesc = 'ANALYTICS_AVERAGE_CLIENT_ID_DESC',
  AnalyticsAverageRequestIdAsc = 'ANALYTICS_AVERAGE_REQUEST_ID_ASC',
  AnalyticsAverageRequestIdDesc = 'ANALYTICS_AVERAGE_REQUEST_ID_DESC',
  AnalyticsAverageEventCategoryAsc = 'ANALYTICS_AVERAGE_EVENT_CATEGORY_ASC',
  AnalyticsAverageEventCategoryDesc = 'ANALYTICS_AVERAGE_EVENT_CATEGORY_DESC',
  AnalyticsAverageEventActionAsc = 'ANALYTICS_AVERAGE_EVENT_ACTION_ASC',
  AnalyticsAverageEventActionDesc = 'ANALYTICS_AVERAGE_EVENT_ACTION_DESC',
  AnalyticsAverageSourceUrlAsc = 'ANALYTICS_AVERAGE_SOURCE_URL_ASC',
  AnalyticsAverageSourceUrlDesc = 'ANALYTICS_AVERAGE_SOURCE_URL_DESC',
  AnalyticsAverageReferrerUrlAsc = 'ANALYTICS_AVERAGE_REFERRER_URL_ASC',
  AnalyticsAverageReferrerUrlDesc = 'ANALYTICS_AVERAGE_REFERRER_URL_DESC',
  AnalyticsAverageUserAgentAsc = 'ANALYTICS_AVERAGE_USER_AGENT_ASC',
  AnalyticsAverageUserAgentDesc = 'ANALYTICS_AVERAGE_USER_AGENT_DESC',
  AnalyticsAverageUserIpAsc = 'ANALYTICS_AVERAGE_USER_IP_ASC',
  AnalyticsAverageUserIpDesc = 'ANALYTICS_AVERAGE_USER_IP_DESC',
  AnalyticsAverageCompanyIdAsc = 'ANALYTICS_AVERAGE_COMPANY_ID_ASC',
  AnalyticsAverageCompanyIdDesc = 'ANALYTICS_AVERAGE_COMPANY_ID_DESC',
  AnalyticsAverageContextAsc = 'ANALYTICS_AVERAGE_CONTEXT_ASC',
  AnalyticsAverageContextDesc = 'ANALYTICS_AVERAGE_CONTEXT_DESC',
  AnalyticsAverageCreatedAtAsc = 'ANALYTICS_AVERAGE_CREATED_AT_ASC',
  AnalyticsAverageCreatedAtDesc = 'ANALYTICS_AVERAGE_CREATED_AT_DESC',
  AnalyticsAverageUpdatedAtAsc = 'ANALYTICS_AVERAGE_UPDATED_AT_ASC',
  AnalyticsAverageUpdatedAtDesc = 'ANALYTICS_AVERAGE_UPDATED_AT_DESC',
  AnalyticsStddevSampleIdAsc = 'ANALYTICS_STDDEV_SAMPLE_ID_ASC',
  AnalyticsStddevSampleIdDesc = 'ANALYTICS_STDDEV_SAMPLE_ID_DESC',
  AnalyticsStddevSampleClientIdAsc = 'ANALYTICS_STDDEV_SAMPLE_CLIENT_ID_ASC',
  AnalyticsStddevSampleClientIdDesc = 'ANALYTICS_STDDEV_SAMPLE_CLIENT_ID_DESC',
  AnalyticsStddevSampleRequestIdAsc = 'ANALYTICS_STDDEV_SAMPLE_REQUEST_ID_ASC',
  AnalyticsStddevSampleRequestIdDesc = 'ANALYTICS_STDDEV_SAMPLE_REQUEST_ID_DESC',
  AnalyticsStddevSampleEventCategoryAsc = 'ANALYTICS_STDDEV_SAMPLE_EVENT_CATEGORY_ASC',
  AnalyticsStddevSampleEventCategoryDesc = 'ANALYTICS_STDDEV_SAMPLE_EVENT_CATEGORY_DESC',
  AnalyticsStddevSampleEventActionAsc = 'ANALYTICS_STDDEV_SAMPLE_EVENT_ACTION_ASC',
  AnalyticsStddevSampleEventActionDesc = 'ANALYTICS_STDDEV_SAMPLE_EVENT_ACTION_DESC',
  AnalyticsStddevSampleSourceUrlAsc = 'ANALYTICS_STDDEV_SAMPLE_SOURCE_URL_ASC',
  AnalyticsStddevSampleSourceUrlDesc = 'ANALYTICS_STDDEV_SAMPLE_SOURCE_URL_DESC',
  AnalyticsStddevSampleReferrerUrlAsc = 'ANALYTICS_STDDEV_SAMPLE_REFERRER_URL_ASC',
  AnalyticsStddevSampleReferrerUrlDesc = 'ANALYTICS_STDDEV_SAMPLE_REFERRER_URL_DESC',
  AnalyticsStddevSampleUserAgentAsc = 'ANALYTICS_STDDEV_SAMPLE_USER_AGENT_ASC',
  AnalyticsStddevSampleUserAgentDesc = 'ANALYTICS_STDDEV_SAMPLE_USER_AGENT_DESC',
  AnalyticsStddevSampleUserIpAsc = 'ANALYTICS_STDDEV_SAMPLE_USER_IP_ASC',
  AnalyticsStddevSampleUserIpDesc = 'ANALYTICS_STDDEV_SAMPLE_USER_IP_DESC',
  AnalyticsStddevSampleCompanyIdAsc = 'ANALYTICS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  AnalyticsStddevSampleCompanyIdDesc = 'ANALYTICS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  AnalyticsStddevSampleContextAsc = 'ANALYTICS_STDDEV_SAMPLE_CONTEXT_ASC',
  AnalyticsStddevSampleContextDesc = 'ANALYTICS_STDDEV_SAMPLE_CONTEXT_DESC',
  AnalyticsStddevSampleCreatedAtAsc = 'ANALYTICS_STDDEV_SAMPLE_CREATED_AT_ASC',
  AnalyticsStddevSampleCreatedAtDesc = 'ANALYTICS_STDDEV_SAMPLE_CREATED_AT_DESC',
  AnalyticsStddevSampleUpdatedAtAsc = 'ANALYTICS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  AnalyticsStddevSampleUpdatedAtDesc = 'ANALYTICS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  AnalyticsStddevPopulationIdAsc = 'ANALYTICS_STDDEV_POPULATION_ID_ASC',
  AnalyticsStddevPopulationIdDesc = 'ANALYTICS_STDDEV_POPULATION_ID_DESC',
  AnalyticsStddevPopulationClientIdAsc = 'ANALYTICS_STDDEV_POPULATION_CLIENT_ID_ASC',
  AnalyticsStddevPopulationClientIdDesc = 'ANALYTICS_STDDEV_POPULATION_CLIENT_ID_DESC',
  AnalyticsStddevPopulationRequestIdAsc = 'ANALYTICS_STDDEV_POPULATION_REQUEST_ID_ASC',
  AnalyticsStddevPopulationRequestIdDesc = 'ANALYTICS_STDDEV_POPULATION_REQUEST_ID_DESC',
  AnalyticsStddevPopulationEventCategoryAsc = 'ANALYTICS_STDDEV_POPULATION_EVENT_CATEGORY_ASC',
  AnalyticsStddevPopulationEventCategoryDesc = 'ANALYTICS_STDDEV_POPULATION_EVENT_CATEGORY_DESC',
  AnalyticsStddevPopulationEventActionAsc = 'ANALYTICS_STDDEV_POPULATION_EVENT_ACTION_ASC',
  AnalyticsStddevPopulationEventActionDesc = 'ANALYTICS_STDDEV_POPULATION_EVENT_ACTION_DESC',
  AnalyticsStddevPopulationSourceUrlAsc = 'ANALYTICS_STDDEV_POPULATION_SOURCE_URL_ASC',
  AnalyticsStddevPopulationSourceUrlDesc = 'ANALYTICS_STDDEV_POPULATION_SOURCE_URL_DESC',
  AnalyticsStddevPopulationReferrerUrlAsc = 'ANALYTICS_STDDEV_POPULATION_REFERRER_URL_ASC',
  AnalyticsStddevPopulationReferrerUrlDesc = 'ANALYTICS_STDDEV_POPULATION_REFERRER_URL_DESC',
  AnalyticsStddevPopulationUserAgentAsc = 'ANALYTICS_STDDEV_POPULATION_USER_AGENT_ASC',
  AnalyticsStddevPopulationUserAgentDesc = 'ANALYTICS_STDDEV_POPULATION_USER_AGENT_DESC',
  AnalyticsStddevPopulationUserIpAsc = 'ANALYTICS_STDDEV_POPULATION_USER_IP_ASC',
  AnalyticsStddevPopulationUserIpDesc = 'ANALYTICS_STDDEV_POPULATION_USER_IP_DESC',
  AnalyticsStddevPopulationCompanyIdAsc = 'ANALYTICS_STDDEV_POPULATION_COMPANY_ID_ASC',
  AnalyticsStddevPopulationCompanyIdDesc = 'ANALYTICS_STDDEV_POPULATION_COMPANY_ID_DESC',
  AnalyticsStddevPopulationContextAsc = 'ANALYTICS_STDDEV_POPULATION_CONTEXT_ASC',
  AnalyticsStddevPopulationContextDesc = 'ANALYTICS_STDDEV_POPULATION_CONTEXT_DESC',
  AnalyticsStddevPopulationCreatedAtAsc = 'ANALYTICS_STDDEV_POPULATION_CREATED_AT_ASC',
  AnalyticsStddevPopulationCreatedAtDesc = 'ANALYTICS_STDDEV_POPULATION_CREATED_AT_DESC',
  AnalyticsStddevPopulationUpdatedAtAsc = 'ANALYTICS_STDDEV_POPULATION_UPDATED_AT_ASC',
  AnalyticsStddevPopulationUpdatedAtDesc = 'ANALYTICS_STDDEV_POPULATION_UPDATED_AT_DESC',
  AnalyticsVarianceSampleIdAsc = 'ANALYTICS_VARIANCE_SAMPLE_ID_ASC',
  AnalyticsVarianceSampleIdDesc = 'ANALYTICS_VARIANCE_SAMPLE_ID_DESC',
  AnalyticsVarianceSampleClientIdAsc = 'ANALYTICS_VARIANCE_SAMPLE_CLIENT_ID_ASC',
  AnalyticsVarianceSampleClientIdDesc = 'ANALYTICS_VARIANCE_SAMPLE_CLIENT_ID_DESC',
  AnalyticsVarianceSampleRequestIdAsc = 'ANALYTICS_VARIANCE_SAMPLE_REQUEST_ID_ASC',
  AnalyticsVarianceSampleRequestIdDesc = 'ANALYTICS_VARIANCE_SAMPLE_REQUEST_ID_DESC',
  AnalyticsVarianceSampleEventCategoryAsc = 'ANALYTICS_VARIANCE_SAMPLE_EVENT_CATEGORY_ASC',
  AnalyticsVarianceSampleEventCategoryDesc = 'ANALYTICS_VARIANCE_SAMPLE_EVENT_CATEGORY_DESC',
  AnalyticsVarianceSampleEventActionAsc = 'ANALYTICS_VARIANCE_SAMPLE_EVENT_ACTION_ASC',
  AnalyticsVarianceSampleEventActionDesc = 'ANALYTICS_VARIANCE_SAMPLE_EVENT_ACTION_DESC',
  AnalyticsVarianceSampleSourceUrlAsc = 'ANALYTICS_VARIANCE_SAMPLE_SOURCE_URL_ASC',
  AnalyticsVarianceSampleSourceUrlDesc = 'ANALYTICS_VARIANCE_SAMPLE_SOURCE_URL_DESC',
  AnalyticsVarianceSampleReferrerUrlAsc = 'ANALYTICS_VARIANCE_SAMPLE_REFERRER_URL_ASC',
  AnalyticsVarianceSampleReferrerUrlDesc = 'ANALYTICS_VARIANCE_SAMPLE_REFERRER_URL_DESC',
  AnalyticsVarianceSampleUserAgentAsc = 'ANALYTICS_VARIANCE_SAMPLE_USER_AGENT_ASC',
  AnalyticsVarianceSampleUserAgentDesc = 'ANALYTICS_VARIANCE_SAMPLE_USER_AGENT_DESC',
  AnalyticsVarianceSampleUserIpAsc = 'ANALYTICS_VARIANCE_SAMPLE_USER_IP_ASC',
  AnalyticsVarianceSampleUserIpDesc = 'ANALYTICS_VARIANCE_SAMPLE_USER_IP_DESC',
  AnalyticsVarianceSampleCompanyIdAsc = 'ANALYTICS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  AnalyticsVarianceSampleCompanyIdDesc = 'ANALYTICS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  AnalyticsVarianceSampleContextAsc = 'ANALYTICS_VARIANCE_SAMPLE_CONTEXT_ASC',
  AnalyticsVarianceSampleContextDesc = 'ANALYTICS_VARIANCE_SAMPLE_CONTEXT_DESC',
  AnalyticsVarianceSampleCreatedAtAsc = 'ANALYTICS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  AnalyticsVarianceSampleCreatedAtDesc = 'ANALYTICS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  AnalyticsVarianceSampleUpdatedAtAsc = 'ANALYTICS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  AnalyticsVarianceSampleUpdatedAtDesc = 'ANALYTICS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  AnalyticsVariancePopulationIdAsc = 'ANALYTICS_VARIANCE_POPULATION_ID_ASC',
  AnalyticsVariancePopulationIdDesc = 'ANALYTICS_VARIANCE_POPULATION_ID_DESC',
  AnalyticsVariancePopulationClientIdAsc = 'ANALYTICS_VARIANCE_POPULATION_CLIENT_ID_ASC',
  AnalyticsVariancePopulationClientIdDesc = 'ANALYTICS_VARIANCE_POPULATION_CLIENT_ID_DESC',
  AnalyticsVariancePopulationRequestIdAsc = 'ANALYTICS_VARIANCE_POPULATION_REQUEST_ID_ASC',
  AnalyticsVariancePopulationRequestIdDesc = 'ANALYTICS_VARIANCE_POPULATION_REQUEST_ID_DESC',
  AnalyticsVariancePopulationEventCategoryAsc = 'ANALYTICS_VARIANCE_POPULATION_EVENT_CATEGORY_ASC',
  AnalyticsVariancePopulationEventCategoryDesc = 'ANALYTICS_VARIANCE_POPULATION_EVENT_CATEGORY_DESC',
  AnalyticsVariancePopulationEventActionAsc = 'ANALYTICS_VARIANCE_POPULATION_EVENT_ACTION_ASC',
  AnalyticsVariancePopulationEventActionDesc = 'ANALYTICS_VARIANCE_POPULATION_EVENT_ACTION_DESC',
  AnalyticsVariancePopulationSourceUrlAsc = 'ANALYTICS_VARIANCE_POPULATION_SOURCE_URL_ASC',
  AnalyticsVariancePopulationSourceUrlDesc = 'ANALYTICS_VARIANCE_POPULATION_SOURCE_URL_DESC',
  AnalyticsVariancePopulationReferrerUrlAsc = 'ANALYTICS_VARIANCE_POPULATION_REFERRER_URL_ASC',
  AnalyticsVariancePopulationReferrerUrlDesc = 'ANALYTICS_VARIANCE_POPULATION_REFERRER_URL_DESC',
  AnalyticsVariancePopulationUserAgentAsc = 'ANALYTICS_VARIANCE_POPULATION_USER_AGENT_ASC',
  AnalyticsVariancePopulationUserAgentDesc = 'ANALYTICS_VARIANCE_POPULATION_USER_AGENT_DESC',
  AnalyticsVariancePopulationUserIpAsc = 'ANALYTICS_VARIANCE_POPULATION_USER_IP_ASC',
  AnalyticsVariancePopulationUserIpDesc = 'ANALYTICS_VARIANCE_POPULATION_USER_IP_DESC',
  AnalyticsVariancePopulationCompanyIdAsc = 'ANALYTICS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  AnalyticsVariancePopulationCompanyIdDesc = 'ANALYTICS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  AnalyticsVariancePopulationContextAsc = 'ANALYTICS_VARIANCE_POPULATION_CONTEXT_ASC',
  AnalyticsVariancePopulationContextDesc = 'ANALYTICS_VARIANCE_POPULATION_CONTEXT_DESC',
  AnalyticsVariancePopulationCreatedAtAsc = 'ANALYTICS_VARIANCE_POPULATION_CREATED_AT_ASC',
  AnalyticsVariancePopulationCreatedAtDesc = 'ANALYTICS_VARIANCE_POPULATION_CREATED_AT_DESC',
  AnalyticsVariancePopulationUpdatedAtAsc = 'ANALYTICS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  AnalyticsVariancePopulationUpdatedAtDesc = 'ANALYTICS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyIntegrationsCountAsc = 'COMPANY_INTEGRATIONS_COUNT_ASC',
  CompanyIntegrationsCountDesc = 'COMPANY_INTEGRATIONS_COUNT_DESC',
  CompanyIntegrationsSumIdAsc = 'COMPANY_INTEGRATIONS_SUM_ID_ASC',
  CompanyIntegrationsSumIdDesc = 'COMPANY_INTEGRATIONS_SUM_ID_DESC',
  CompanyIntegrationsSumIntegrationIdAsc = 'COMPANY_INTEGRATIONS_SUM_INTEGRATION_ID_ASC',
  CompanyIntegrationsSumIntegrationIdDesc = 'COMPANY_INTEGRATIONS_SUM_INTEGRATION_ID_DESC',
  CompanyIntegrationsSumCompanyIdAsc = 'COMPANY_INTEGRATIONS_SUM_COMPANY_ID_ASC',
  CompanyIntegrationsSumCompanyIdDesc = 'COMPANY_INTEGRATIONS_SUM_COMPANY_ID_DESC',
  CompanyIntegrationsSumEnabledAsc = 'COMPANY_INTEGRATIONS_SUM_ENABLED_ASC',
  CompanyIntegrationsSumEnabledDesc = 'COMPANY_INTEGRATIONS_SUM_ENABLED_DESC',
  CompanyIntegrationsSumCreatedAtAsc = 'COMPANY_INTEGRATIONS_SUM_CREATED_AT_ASC',
  CompanyIntegrationsSumCreatedAtDesc = 'COMPANY_INTEGRATIONS_SUM_CREATED_AT_DESC',
  CompanyIntegrationsSumUpdatedAtAsc = 'COMPANY_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  CompanyIntegrationsSumUpdatedAtDesc = 'COMPANY_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  CompanyIntegrationsSumNameAsc = 'COMPANY_INTEGRATIONS_SUM_NAME_ASC',
  CompanyIntegrationsSumNameDesc = 'COMPANY_INTEGRATIONS_SUM_NAME_DESC',
  CompanyIntegrationsSumDisplayNameAsc = 'COMPANY_INTEGRATIONS_SUM_DISPLAY_NAME_ASC',
  CompanyIntegrationsSumDisplayNameDesc = 'COMPANY_INTEGRATIONS_SUM_DISPLAY_NAME_DESC',
  CompanyIntegrationsDistinctCountIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  CompanyIntegrationsDistinctCountIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  CompanyIntegrationsDistinctCountIntegrationIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  CompanyIntegrationsDistinctCountIntegrationIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  CompanyIntegrationsDistinctCountCompanyIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyIntegrationsDistinctCountCompanyIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyIntegrationsDistinctCountEnabledAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyIntegrationsDistinctCountEnabledDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyIntegrationsDistinctCountCreatedAtAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyIntegrationsDistinctCountCreatedAtDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyIntegrationsDistinctCountUpdatedAtAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyIntegrationsDistinctCountUpdatedAtDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyIntegrationsDistinctCountNameAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_NAME_ASC',
  CompanyIntegrationsDistinctCountNameDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_NAME_DESC',
  CompanyIntegrationsDistinctCountDisplayNameAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_DISPLAY_NAME_ASC',
  CompanyIntegrationsDistinctCountDisplayNameDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_DISPLAY_NAME_DESC',
  CompanyIntegrationsMinIdAsc = 'COMPANY_INTEGRATIONS_MIN_ID_ASC',
  CompanyIntegrationsMinIdDesc = 'COMPANY_INTEGRATIONS_MIN_ID_DESC',
  CompanyIntegrationsMinIntegrationIdAsc = 'COMPANY_INTEGRATIONS_MIN_INTEGRATION_ID_ASC',
  CompanyIntegrationsMinIntegrationIdDesc = 'COMPANY_INTEGRATIONS_MIN_INTEGRATION_ID_DESC',
  CompanyIntegrationsMinCompanyIdAsc = 'COMPANY_INTEGRATIONS_MIN_COMPANY_ID_ASC',
  CompanyIntegrationsMinCompanyIdDesc = 'COMPANY_INTEGRATIONS_MIN_COMPANY_ID_DESC',
  CompanyIntegrationsMinEnabledAsc = 'COMPANY_INTEGRATIONS_MIN_ENABLED_ASC',
  CompanyIntegrationsMinEnabledDesc = 'COMPANY_INTEGRATIONS_MIN_ENABLED_DESC',
  CompanyIntegrationsMinCreatedAtAsc = 'COMPANY_INTEGRATIONS_MIN_CREATED_AT_ASC',
  CompanyIntegrationsMinCreatedAtDesc = 'COMPANY_INTEGRATIONS_MIN_CREATED_AT_DESC',
  CompanyIntegrationsMinUpdatedAtAsc = 'COMPANY_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  CompanyIntegrationsMinUpdatedAtDesc = 'COMPANY_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  CompanyIntegrationsMinNameAsc = 'COMPANY_INTEGRATIONS_MIN_NAME_ASC',
  CompanyIntegrationsMinNameDesc = 'COMPANY_INTEGRATIONS_MIN_NAME_DESC',
  CompanyIntegrationsMinDisplayNameAsc = 'COMPANY_INTEGRATIONS_MIN_DISPLAY_NAME_ASC',
  CompanyIntegrationsMinDisplayNameDesc = 'COMPANY_INTEGRATIONS_MIN_DISPLAY_NAME_DESC',
  CompanyIntegrationsMaxIdAsc = 'COMPANY_INTEGRATIONS_MAX_ID_ASC',
  CompanyIntegrationsMaxIdDesc = 'COMPANY_INTEGRATIONS_MAX_ID_DESC',
  CompanyIntegrationsMaxIntegrationIdAsc = 'COMPANY_INTEGRATIONS_MAX_INTEGRATION_ID_ASC',
  CompanyIntegrationsMaxIntegrationIdDesc = 'COMPANY_INTEGRATIONS_MAX_INTEGRATION_ID_DESC',
  CompanyIntegrationsMaxCompanyIdAsc = 'COMPANY_INTEGRATIONS_MAX_COMPANY_ID_ASC',
  CompanyIntegrationsMaxCompanyIdDesc = 'COMPANY_INTEGRATIONS_MAX_COMPANY_ID_DESC',
  CompanyIntegrationsMaxEnabledAsc = 'COMPANY_INTEGRATIONS_MAX_ENABLED_ASC',
  CompanyIntegrationsMaxEnabledDesc = 'COMPANY_INTEGRATIONS_MAX_ENABLED_DESC',
  CompanyIntegrationsMaxCreatedAtAsc = 'COMPANY_INTEGRATIONS_MAX_CREATED_AT_ASC',
  CompanyIntegrationsMaxCreatedAtDesc = 'COMPANY_INTEGRATIONS_MAX_CREATED_AT_DESC',
  CompanyIntegrationsMaxUpdatedAtAsc = 'COMPANY_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  CompanyIntegrationsMaxUpdatedAtDesc = 'COMPANY_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  CompanyIntegrationsMaxNameAsc = 'COMPANY_INTEGRATIONS_MAX_NAME_ASC',
  CompanyIntegrationsMaxNameDesc = 'COMPANY_INTEGRATIONS_MAX_NAME_DESC',
  CompanyIntegrationsMaxDisplayNameAsc = 'COMPANY_INTEGRATIONS_MAX_DISPLAY_NAME_ASC',
  CompanyIntegrationsMaxDisplayNameDesc = 'COMPANY_INTEGRATIONS_MAX_DISPLAY_NAME_DESC',
  CompanyIntegrationsAverageIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_ID_ASC',
  CompanyIntegrationsAverageIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_ID_DESC',
  CompanyIntegrationsAverageIntegrationIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_INTEGRATION_ID_ASC',
  CompanyIntegrationsAverageIntegrationIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_INTEGRATION_ID_DESC',
  CompanyIntegrationsAverageCompanyIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_COMPANY_ID_ASC',
  CompanyIntegrationsAverageCompanyIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_COMPANY_ID_DESC',
  CompanyIntegrationsAverageEnabledAsc = 'COMPANY_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  CompanyIntegrationsAverageEnabledDesc = 'COMPANY_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  CompanyIntegrationsAverageCreatedAtAsc = 'COMPANY_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  CompanyIntegrationsAverageCreatedAtDesc = 'COMPANY_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  CompanyIntegrationsAverageUpdatedAtAsc = 'COMPANY_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  CompanyIntegrationsAverageUpdatedAtDesc = 'COMPANY_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  CompanyIntegrationsAverageNameAsc = 'COMPANY_INTEGRATIONS_AVERAGE_NAME_ASC',
  CompanyIntegrationsAverageNameDesc = 'COMPANY_INTEGRATIONS_AVERAGE_NAME_DESC',
  CompanyIntegrationsAverageDisplayNameAsc = 'COMPANY_INTEGRATIONS_AVERAGE_DISPLAY_NAME_ASC',
  CompanyIntegrationsAverageDisplayNameDesc = 'COMPANY_INTEGRATIONS_AVERAGE_DISPLAY_NAME_DESC',
  CompanyIntegrationsStddevSampleIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  CompanyIntegrationsStddevSampleIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  CompanyIntegrationsStddevSampleIntegrationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  CompanyIntegrationsStddevSampleIntegrationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  CompanyIntegrationsStddevSampleCompanyIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyIntegrationsStddevSampleCompanyIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyIntegrationsStddevSampleEnabledAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyIntegrationsStddevSampleEnabledDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyIntegrationsStddevSampleCreatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyIntegrationsStddevSampleCreatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyIntegrationsStddevSampleUpdatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyIntegrationsStddevSampleUpdatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyIntegrationsStddevSampleNameAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_NAME_ASC',
  CompanyIntegrationsStddevSampleNameDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_NAME_DESC',
  CompanyIntegrationsStddevSampleDisplayNameAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_DISPLAY_NAME_ASC',
  CompanyIntegrationsStddevSampleDisplayNameDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_DISPLAY_NAME_DESC',
  CompanyIntegrationsStddevPopulationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  CompanyIntegrationsStddevPopulationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  CompanyIntegrationsStddevPopulationIntegrationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  CompanyIntegrationsStddevPopulationIntegrationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  CompanyIntegrationsStddevPopulationCompanyIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyIntegrationsStddevPopulationCompanyIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyIntegrationsStddevPopulationEnabledAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyIntegrationsStddevPopulationEnabledDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyIntegrationsStddevPopulationCreatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyIntegrationsStddevPopulationCreatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyIntegrationsStddevPopulationUpdatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyIntegrationsStddevPopulationUpdatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyIntegrationsStddevPopulationNameAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_NAME_ASC',
  CompanyIntegrationsStddevPopulationNameDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_NAME_DESC',
  CompanyIntegrationsStddevPopulationDisplayNameAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_DISPLAY_NAME_ASC',
  CompanyIntegrationsStddevPopulationDisplayNameDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_DISPLAY_NAME_DESC',
  CompanyIntegrationsVarianceSampleIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  CompanyIntegrationsVarianceSampleIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  CompanyIntegrationsVarianceSampleIntegrationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  CompanyIntegrationsVarianceSampleIntegrationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  CompanyIntegrationsVarianceSampleCompanyIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyIntegrationsVarianceSampleCompanyIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyIntegrationsVarianceSampleEnabledAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyIntegrationsVarianceSampleEnabledDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyIntegrationsVarianceSampleCreatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyIntegrationsVarianceSampleCreatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyIntegrationsVarianceSampleUpdatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyIntegrationsVarianceSampleUpdatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyIntegrationsVarianceSampleNameAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_NAME_ASC',
  CompanyIntegrationsVarianceSampleNameDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_NAME_DESC',
  CompanyIntegrationsVarianceSampleDisplayNameAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_DISPLAY_NAME_ASC',
  CompanyIntegrationsVarianceSampleDisplayNameDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_DISPLAY_NAME_DESC',
  CompanyIntegrationsVariancePopulationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  CompanyIntegrationsVariancePopulationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  CompanyIntegrationsVariancePopulationIntegrationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  CompanyIntegrationsVariancePopulationIntegrationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  CompanyIntegrationsVariancePopulationCompanyIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyIntegrationsVariancePopulationCompanyIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyIntegrationsVariancePopulationEnabledAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyIntegrationsVariancePopulationEnabledDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyIntegrationsVariancePopulationCreatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyIntegrationsVariancePopulationCreatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyIntegrationsVariancePopulationUpdatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyIntegrationsVariancePopulationUpdatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyIntegrationsVariancePopulationNameAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_NAME_ASC',
  CompanyIntegrationsVariancePopulationNameDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_NAME_DESC',
  CompanyIntegrationsVariancePopulationDisplayNameAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_DISPLAY_NAME_ASC',
  CompanyIntegrationsVariancePopulationDisplayNameDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_DISPLAY_NAME_DESC',
  CompanyChannelsCountAsc = 'COMPANY_CHANNELS_COUNT_ASC',
  CompanyChannelsCountDesc = 'COMPANY_CHANNELS_COUNT_DESC',
  CompanyChannelsSumIdAsc = 'COMPANY_CHANNELS_SUM_ID_ASC',
  CompanyChannelsSumIdDesc = 'COMPANY_CHANNELS_SUM_ID_DESC',
  CompanyChannelsSumWidgetIdAsc = 'COMPANY_CHANNELS_SUM_WIDGET_ID_ASC',
  CompanyChannelsSumWidgetIdDesc = 'COMPANY_CHANNELS_SUM_WIDGET_ID_DESC',
  CompanyChannelsSumChannelIdAsc = 'COMPANY_CHANNELS_SUM_CHANNEL_ID_ASC',
  CompanyChannelsSumChannelIdDesc = 'COMPANY_CHANNELS_SUM_CHANNEL_ID_DESC',
  CompanyChannelsSumCompanyIdAsc = 'COMPANY_CHANNELS_SUM_COMPANY_ID_ASC',
  CompanyChannelsSumCompanyIdDesc = 'COMPANY_CHANNELS_SUM_COMPANY_ID_DESC',
  CompanyChannelsSumEnabledAsc = 'COMPANY_CHANNELS_SUM_ENABLED_ASC',
  CompanyChannelsSumEnabledDesc = 'COMPANY_CHANNELS_SUM_ENABLED_DESC',
  CompanyChannelsSumCreatedAtAsc = 'COMPANY_CHANNELS_SUM_CREATED_AT_ASC',
  CompanyChannelsSumCreatedAtDesc = 'COMPANY_CHANNELS_SUM_CREATED_AT_DESC',
  CompanyChannelsSumUpdatedAtAsc = 'COMPANY_CHANNELS_SUM_UPDATED_AT_ASC',
  CompanyChannelsSumUpdatedAtDesc = 'COMPANY_CHANNELS_SUM_UPDATED_AT_DESC',
  CompanyChannelsSumNameAsc = 'COMPANY_CHANNELS_SUM_NAME_ASC',
  CompanyChannelsSumNameDesc = 'COMPANY_CHANNELS_SUM_NAME_DESC',
  CompanyChannelsSumDisplayNameAsc = 'COMPANY_CHANNELS_SUM_DISPLAY_NAME_ASC',
  CompanyChannelsSumDisplayNameDesc = 'COMPANY_CHANNELS_SUM_DISPLAY_NAME_DESC',
  CompanyChannelsSumDeletedAtAsc = 'COMPANY_CHANNELS_SUM_DELETED_AT_ASC',
  CompanyChannelsSumDeletedAtDesc = 'COMPANY_CHANNELS_SUM_DELETED_AT_DESC',
  CompanyChannelsDistinctCountIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ID_ASC',
  CompanyChannelsDistinctCountIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ID_DESC',
  CompanyChannelsDistinctCountWidgetIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CompanyChannelsDistinctCountWidgetIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CompanyChannelsDistinctCountChannelIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CHANNEL_ID_ASC',
  CompanyChannelsDistinctCountChannelIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CHANNEL_ID_DESC',
  CompanyChannelsDistinctCountCompanyIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyChannelsDistinctCountCompanyIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyChannelsDistinctCountEnabledAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyChannelsDistinctCountEnabledDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyChannelsDistinctCountCreatedAtAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyChannelsDistinctCountCreatedAtDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyChannelsDistinctCountUpdatedAtAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyChannelsDistinctCountUpdatedAtDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyChannelsDistinctCountNameAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_NAME_ASC',
  CompanyChannelsDistinctCountNameDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_NAME_DESC',
  CompanyChannelsDistinctCountDisplayNameAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_DISPLAY_NAME_ASC',
  CompanyChannelsDistinctCountDisplayNameDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_DISPLAY_NAME_DESC',
  CompanyChannelsDistinctCountDeletedAtAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_DELETED_AT_ASC',
  CompanyChannelsDistinctCountDeletedAtDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_DELETED_AT_DESC',
  CompanyChannelsMinIdAsc = 'COMPANY_CHANNELS_MIN_ID_ASC',
  CompanyChannelsMinIdDesc = 'COMPANY_CHANNELS_MIN_ID_DESC',
  CompanyChannelsMinWidgetIdAsc = 'COMPANY_CHANNELS_MIN_WIDGET_ID_ASC',
  CompanyChannelsMinWidgetIdDesc = 'COMPANY_CHANNELS_MIN_WIDGET_ID_DESC',
  CompanyChannelsMinChannelIdAsc = 'COMPANY_CHANNELS_MIN_CHANNEL_ID_ASC',
  CompanyChannelsMinChannelIdDesc = 'COMPANY_CHANNELS_MIN_CHANNEL_ID_DESC',
  CompanyChannelsMinCompanyIdAsc = 'COMPANY_CHANNELS_MIN_COMPANY_ID_ASC',
  CompanyChannelsMinCompanyIdDesc = 'COMPANY_CHANNELS_MIN_COMPANY_ID_DESC',
  CompanyChannelsMinEnabledAsc = 'COMPANY_CHANNELS_MIN_ENABLED_ASC',
  CompanyChannelsMinEnabledDesc = 'COMPANY_CHANNELS_MIN_ENABLED_DESC',
  CompanyChannelsMinCreatedAtAsc = 'COMPANY_CHANNELS_MIN_CREATED_AT_ASC',
  CompanyChannelsMinCreatedAtDesc = 'COMPANY_CHANNELS_MIN_CREATED_AT_DESC',
  CompanyChannelsMinUpdatedAtAsc = 'COMPANY_CHANNELS_MIN_UPDATED_AT_ASC',
  CompanyChannelsMinUpdatedAtDesc = 'COMPANY_CHANNELS_MIN_UPDATED_AT_DESC',
  CompanyChannelsMinNameAsc = 'COMPANY_CHANNELS_MIN_NAME_ASC',
  CompanyChannelsMinNameDesc = 'COMPANY_CHANNELS_MIN_NAME_DESC',
  CompanyChannelsMinDisplayNameAsc = 'COMPANY_CHANNELS_MIN_DISPLAY_NAME_ASC',
  CompanyChannelsMinDisplayNameDesc = 'COMPANY_CHANNELS_MIN_DISPLAY_NAME_DESC',
  CompanyChannelsMinDeletedAtAsc = 'COMPANY_CHANNELS_MIN_DELETED_AT_ASC',
  CompanyChannelsMinDeletedAtDesc = 'COMPANY_CHANNELS_MIN_DELETED_AT_DESC',
  CompanyChannelsMaxIdAsc = 'COMPANY_CHANNELS_MAX_ID_ASC',
  CompanyChannelsMaxIdDesc = 'COMPANY_CHANNELS_MAX_ID_DESC',
  CompanyChannelsMaxWidgetIdAsc = 'COMPANY_CHANNELS_MAX_WIDGET_ID_ASC',
  CompanyChannelsMaxWidgetIdDesc = 'COMPANY_CHANNELS_MAX_WIDGET_ID_DESC',
  CompanyChannelsMaxChannelIdAsc = 'COMPANY_CHANNELS_MAX_CHANNEL_ID_ASC',
  CompanyChannelsMaxChannelIdDesc = 'COMPANY_CHANNELS_MAX_CHANNEL_ID_DESC',
  CompanyChannelsMaxCompanyIdAsc = 'COMPANY_CHANNELS_MAX_COMPANY_ID_ASC',
  CompanyChannelsMaxCompanyIdDesc = 'COMPANY_CHANNELS_MAX_COMPANY_ID_DESC',
  CompanyChannelsMaxEnabledAsc = 'COMPANY_CHANNELS_MAX_ENABLED_ASC',
  CompanyChannelsMaxEnabledDesc = 'COMPANY_CHANNELS_MAX_ENABLED_DESC',
  CompanyChannelsMaxCreatedAtAsc = 'COMPANY_CHANNELS_MAX_CREATED_AT_ASC',
  CompanyChannelsMaxCreatedAtDesc = 'COMPANY_CHANNELS_MAX_CREATED_AT_DESC',
  CompanyChannelsMaxUpdatedAtAsc = 'COMPANY_CHANNELS_MAX_UPDATED_AT_ASC',
  CompanyChannelsMaxUpdatedAtDesc = 'COMPANY_CHANNELS_MAX_UPDATED_AT_DESC',
  CompanyChannelsMaxNameAsc = 'COMPANY_CHANNELS_MAX_NAME_ASC',
  CompanyChannelsMaxNameDesc = 'COMPANY_CHANNELS_MAX_NAME_DESC',
  CompanyChannelsMaxDisplayNameAsc = 'COMPANY_CHANNELS_MAX_DISPLAY_NAME_ASC',
  CompanyChannelsMaxDisplayNameDesc = 'COMPANY_CHANNELS_MAX_DISPLAY_NAME_DESC',
  CompanyChannelsMaxDeletedAtAsc = 'COMPANY_CHANNELS_MAX_DELETED_AT_ASC',
  CompanyChannelsMaxDeletedAtDesc = 'COMPANY_CHANNELS_MAX_DELETED_AT_DESC',
  CompanyChannelsAverageIdAsc = 'COMPANY_CHANNELS_AVERAGE_ID_ASC',
  CompanyChannelsAverageIdDesc = 'COMPANY_CHANNELS_AVERAGE_ID_DESC',
  CompanyChannelsAverageWidgetIdAsc = 'COMPANY_CHANNELS_AVERAGE_WIDGET_ID_ASC',
  CompanyChannelsAverageWidgetIdDesc = 'COMPANY_CHANNELS_AVERAGE_WIDGET_ID_DESC',
  CompanyChannelsAverageChannelIdAsc = 'COMPANY_CHANNELS_AVERAGE_CHANNEL_ID_ASC',
  CompanyChannelsAverageChannelIdDesc = 'COMPANY_CHANNELS_AVERAGE_CHANNEL_ID_DESC',
  CompanyChannelsAverageCompanyIdAsc = 'COMPANY_CHANNELS_AVERAGE_COMPANY_ID_ASC',
  CompanyChannelsAverageCompanyIdDesc = 'COMPANY_CHANNELS_AVERAGE_COMPANY_ID_DESC',
  CompanyChannelsAverageEnabledAsc = 'COMPANY_CHANNELS_AVERAGE_ENABLED_ASC',
  CompanyChannelsAverageEnabledDesc = 'COMPANY_CHANNELS_AVERAGE_ENABLED_DESC',
  CompanyChannelsAverageCreatedAtAsc = 'COMPANY_CHANNELS_AVERAGE_CREATED_AT_ASC',
  CompanyChannelsAverageCreatedAtDesc = 'COMPANY_CHANNELS_AVERAGE_CREATED_AT_DESC',
  CompanyChannelsAverageUpdatedAtAsc = 'COMPANY_CHANNELS_AVERAGE_UPDATED_AT_ASC',
  CompanyChannelsAverageUpdatedAtDesc = 'COMPANY_CHANNELS_AVERAGE_UPDATED_AT_DESC',
  CompanyChannelsAverageNameAsc = 'COMPANY_CHANNELS_AVERAGE_NAME_ASC',
  CompanyChannelsAverageNameDesc = 'COMPANY_CHANNELS_AVERAGE_NAME_DESC',
  CompanyChannelsAverageDisplayNameAsc = 'COMPANY_CHANNELS_AVERAGE_DISPLAY_NAME_ASC',
  CompanyChannelsAverageDisplayNameDesc = 'COMPANY_CHANNELS_AVERAGE_DISPLAY_NAME_DESC',
  CompanyChannelsAverageDeletedAtAsc = 'COMPANY_CHANNELS_AVERAGE_DELETED_AT_ASC',
  CompanyChannelsAverageDeletedAtDesc = 'COMPANY_CHANNELS_AVERAGE_DELETED_AT_DESC',
  CompanyChannelsStddevSampleIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ID_ASC',
  CompanyChannelsStddevSampleIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ID_DESC',
  CompanyChannelsStddevSampleWidgetIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CompanyChannelsStddevSampleWidgetIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CompanyChannelsStddevSampleChannelIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CHANNEL_ID_ASC',
  CompanyChannelsStddevSampleChannelIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CHANNEL_ID_DESC',
  CompanyChannelsStddevSampleCompanyIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyChannelsStddevSampleCompanyIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyChannelsStddevSampleEnabledAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyChannelsStddevSampleEnabledDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyChannelsStddevSampleCreatedAtAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyChannelsStddevSampleCreatedAtDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyChannelsStddevSampleUpdatedAtAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelsStddevSampleUpdatedAtDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelsStddevSampleNameAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_NAME_ASC',
  CompanyChannelsStddevSampleNameDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_NAME_DESC',
  CompanyChannelsStddevSampleDisplayNameAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_DISPLAY_NAME_ASC',
  CompanyChannelsStddevSampleDisplayNameDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_DISPLAY_NAME_DESC',
  CompanyChannelsStddevSampleDeletedAtAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_DELETED_AT_ASC',
  CompanyChannelsStddevSampleDeletedAtDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_DELETED_AT_DESC',
  CompanyChannelsStddevPopulationIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ID_ASC',
  CompanyChannelsStddevPopulationIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ID_DESC',
  CompanyChannelsStddevPopulationWidgetIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CompanyChannelsStddevPopulationWidgetIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CompanyChannelsStddevPopulationChannelIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CHANNEL_ID_ASC',
  CompanyChannelsStddevPopulationChannelIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CHANNEL_ID_DESC',
  CompanyChannelsStddevPopulationCompanyIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyChannelsStddevPopulationCompanyIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyChannelsStddevPopulationEnabledAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyChannelsStddevPopulationEnabledDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyChannelsStddevPopulationCreatedAtAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyChannelsStddevPopulationCreatedAtDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyChannelsStddevPopulationUpdatedAtAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyChannelsStddevPopulationUpdatedAtDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyChannelsStddevPopulationNameAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_NAME_ASC',
  CompanyChannelsStddevPopulationNameDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_NAME_DESC',
  CompanyChannelsStddevPopulationDisplayNameAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_DISPLAY_NAME_ASC',
  CompanyChannelsStddevPopulationDisplayNameDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_DISPLAY_NAME_DESC',
  CompanyChannelsStddevPopulationDeletedAtAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_DELETED_AT_ASC',
  CompanyChannelsStddevPopulationDeletedAtDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_DELETED_AT_DESC',
  CompanyChannelsVarianceSampleIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ID_ASC',
  CompanyChannelsVarianceSampleIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ID_DESC',
  CompanyChannelsVarianceSampleWidgetIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CompanyChannelsVarianceSampleWidgetIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CompanyChannelsVarianceSampleChannelIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CHANNEL_ID_ASC',
  CompanyChannelsVarianceSampleChannelIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CHANNEL_ID_DESC',
  CompanyChannelsVarianceSampleCompanyIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyChannelsVarianceSampleCompanyIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyChannelsVarianceSampleEnabledAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyChannelsVarianceSampleEnabledDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyChannelsVarianceSampleCreatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyChannelsVarianceSampleCreatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyChannelsVarianceSampleUpdatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelsVarianceSampleUpdatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelsVarianceSampleNameAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_NAME_ASC',
  CompanyChannelsVarianceSampleNameDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_NAME_DESC',
  CompanyChannelsVarianceSampleDisplayNameAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_DISPLAY_NAME_ASC',
  CompanyChannelsVarianceSampleDisplayNameDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_DISPLAY_NAME_DESC',
  CompanyChannelsVarianceSampleDeletedAtAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  CompanyChannelsVarianceSampleDeletedAtDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  CompanyChannelsVariancePopulationIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ID_ASC',
  CompanyChannelsVariancePopulationIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ID_DESC',
  CompanyChannelsVariancePopulationWidgetIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CompanyChannelsVariancePopulationWidgetIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CompanyChannelsVariancePopulationChannelIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CHANNEL_ID_ASC',
  CompanyChannelsVariancePopulationChannelIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CHANNEL_ID_DESC',
  CompanyChannelsVariancePopulationCompanyIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyChannelsVariancePopulationCompanyIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyChannelsVariancePopulationEnabledAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyChannelsVariancePopulationEnabledDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyChannelsVariancePopulationCreatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyChannelsVariancePopulationCreatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyChannelsVariancePopulationUpdatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyChannelsVariancePopulationUpdatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyChannelsVariancePopulationNameAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_NAME_ASC',
  CompanyChannelsVariancePopulationNameDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_NAME_DESC',
  CompanyChannelsVariancePopulationDisplayNameAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_DISPLAY_NAME_ASC',
  CompanyChannelsVariancePopulationDisplayNameDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_DISPLAY_NAME_DESC',
  CompanyChannelsVariancePopulationDeletedAtAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_DELETED_AT_ASC',
  CompanyChannelsVariancePopulationDeletedAtDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_DELETED_AT_DESC',
  FieldsCountAsc = 'FIELDS_COUNT_ASC',
  FieldsCountDesc = 'FIELDS_COUNT_DESC',
  FieldsSumIdAsc = 'FIELDS_SUM_ID_ASC',
  FieldsSumIdDesc = 'FIELDS_SUM_ID_DESC',
  FieldsSumCompanyIdAsc = 'FIELDS_SUM_COMPANY_ID_ASC',
  FieldsSumCompanyIdDesc = 'FIELDS_SUM_COMPANY_ID_DESC',
  FieldsSumCompanyProductIdAsc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_ASC',
  FieldsSumCompanyProductIdDesc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_DESC',
  FieldsSumCompanyChannelIdAsc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_ASC',
  FieldsSumCompanyChannelIdDesc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_DESC',
  FieldsSumCompanyIntegrationIdAsc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_ASC',
  FieldsSumCompanyIntegrationIdDesc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_DESC',
  FieldsSumConfigAsc = 'FIELDS_SUM_CONFIG_ASC',
  FieldsSumConfigDesc = 'FIELDS_SUM_CONFIG_DESC',
  FieldsSumTopicAsc = 'FIELDS_SUM_TOPIC_ASC',
  FieldsSumTopicDesc = 'FIELDS_SUM_TOPIC_DESC',
  FieldsSumValueAsc = 'FIELDS_SUM_VALUE_ASC',
  FieldsSumValueDesc = 'FIELDS_SUM_VALUE_DESC',
  FieldsSumCreatedAtAsc = 'FIELDS_SUM_CREATED_AT_ASC',
  FieldsSumCreatedAtDesc = 'FIELDS_SUM_CREATED_AT_DESC',
  FieldsSumUpdatedAtAsc = 'FIELDS_SUM_UPDATED_AT_ASC',
  FieldsSumUpdatedAtDesc = 'FIELDS_SUM_UPDATED_AT_DESC',
  FieldsDistinctCountIdAsc = 'FIELDS_DISTINCT_COUNT_ID_ASC',
  FieldsDistinctCountIdDesc = 'FIELDS_DISTINCT_COUNT_ID_DESC',
  FieldsDistinctCountCompanyIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_ASC',
  FieldsDistinctCountCompanyIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_DESC',
  FieldsDistinctCountCompanyProductIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  FieldsDistinctCountCompanyProductIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  FieldsDistinctCountCompanyChannelIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  FieldsDistinctCountCompanyChannelIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  FieldsDistinctCountCompanyIntegrationIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  FieldsDistinctCountCompanyIntegrationIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  FieldsDistinctCountConfigAsc = 'FIELDS_DISTINCT_COUNT_CONFIG_ASC',
  FieldsDistinctCountConfigDesc = 'FIELDS_DISTINCT_COUNT_CONFIG_DESC',
  FieldsDistinctCountTopicAsc = 'FIELDS_DISTINCT_COUNT_TOPIC_ASC',
  FieldsDistinctCountTopicDesc = 'FIELDS_DISTINCT_COUNT_TOPIC_DESC',
  FieldsDistinctCountValueAsc = 'FIELDS_DISTINCT_COUNT_VALUE_ASC',
  FieldsDistinctCountValueDesc = 'FIELDS_DISTINCT_COUNT_VALUE_DESC',
  FieldsDistinctCountCreatedAtAsc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldsDistinctCountCreatedAtDesc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldsDistinctCountUpdatedAtAsc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldsDistinctCountUpdatedAtDesc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldsMinIdAsc = 'FIELDS_MIN_ID_ASC',
  FieldsMinIdDesc = 'FIELDS_MIN_ID_DESC',
  FieldsMinCompanyIdAsc = 'FIELDS_MIN_COMPANY_ID_ASC',
  FieldsMinCompanyIdDesc = 'FIELDS_MIN_COMPANY_ID_DESC',
  FieldsMinCompanyProductIdAsc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_ASC',
  FieldsMinCompanyProductIdDesc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_DESC',
  FieldsMinCompanyChannelIdAsc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_ASC',
  FieldsMinCompanyChannelIdDesc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_DESC',
  FieldsMinCompanyIntegrationIdAsc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_ASC',
  FieldsMinCompanyIntegrationIdDesc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_DESC',
  FieldsMinConfigAsc = 'FIELDS_MIN_CONFIG_ASC',
  FieldsMinConfigDesc = 'FIELDS_MIN_CONFIG_DESC',
  FieldsMinTopicAsc = 'FIELDS_MIN_TOPIC_ASC',
  FieldsMinTopicDesc = 'FIELDS_MIN_TOPIC_DESC',
  FieldsMinValueAsc = 'FIELDS_MIN_VALUE_ASC',
  FieldsMinValueDesc = 'FIELDS_MIN_VALUE_DESC',
  FieldsMinCreatedAtAsc = 'FIELDS_MIN_CREATED_AT_ASC',
  FieldsMinCreatedAtDesc = 'FIELDS_MIN_CREATED_AT_DESC',
  FieldsMinUpdatedAtAsc = 'FIELDS_MIN_UPDATED_AT_ASC',
  FieldsMinUpdatedAtDesc = 'FIELDS_MIN_UPDATED_AT_DESC',
  FieldsMaxIdAsc = 'FIELDS_MAX_ID_ASC',
  FieldsMaxIdDesc = 'FIELDS_MAX_ID_DESC',
  FieldsMaxCompanyIdAsc = 'FIELDS_MAX_COMPANY_ID_ASC',
  FieldsMaxCompanyIdDesc = 'FIELDS_MAX_COMPANY_ID_DESC',
  FieldsMaxCompanyProductIdAsc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_ASC',
  FieldsMaxCompanyProductIdDesc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_DESC',
  FieldsMaxCompanyChannelIdAsc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_ASC',
  FieldsMaxCompanyChannelIdDesc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_DESC',
  FieldsMaxCompanyIntegrationIdAsc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_ASC',
  FieldsMaxCompanyIntegrationIdDesc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_DESC',
  FieldsMaxConfigAsc = 'FIELDS_MAX_CONFIG_ASC',
  FieldsMaxConfigDesc = 'FIELDS_MAX_CONFIG_DESC',
  FieldsMaxTopicAsc = 'FIELDS_MAX_TOPIC_ASC',
  FieldsMaxTopicDesc = 'FIELDS_MAX_TOPIC_DESC',
  FieldsMaxValueAsc = 'FIELDS_MAX_VALUE_ASC',
  FieldsMaxValueDesc = 'FIELDS_MAX_VALUE_DESC',
  FieldsMaxCreatedAtAsc = 'FIELDS_MAX_CREATED_AT_ASC',
  FieldsMaxCreatedAtDesc = 'FIELDS_MAX_CREATED_AT_DESC',
  FieldsMaxUpdatedAtAsc = 'FIELDS_MAX_UPDATED_AT_ASC',
  FieldsMaxUpdatedAtDesc = 'FIELDS_MAX_UPDATED_AT_DESC',
  FieldsAverageIdAsc = 'FIELDS_AVERAGE_ID_ASC',
  FieldsAverageIdDesc = 'FIELDS_AVERAGE_ID_DESC',
  FieldsAverageCompanyIdAsc = 'FIELDS_AVERAGE_COMPANY_ID_ASC',
  FieldsAverageCompanyIdDesc = 'FIELDS_AVERAGE_COMPANY_ID_DESC',
  FieldsAverageCompanyProductIdAsc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  FieldsAverageCompanyProductIdDesc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  FieldsAverageCompanyChannelIdAsc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  FieldsAverageCompanyChannelIdDesc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  FieldsAverageCompanyIntegrationIdAsc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  FieldsAverageCompanyIntegrationIdDesc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  FieldsAverageConfigAsc = 'FIELDS_AVERAGE_CONFIG_ASC',
  FieldsAverageConfigDesc = 'FIELDS_AVERAGE_CONFIG_DESC',
  FieldsAverageTopicAsc = 'FIELDS_AVERAGE_TOPIC_ASC',
  FieldsAverageTopicDesc = 'FIELDS_AVERAGE_TOPIC_DESC',
  FieldsAverageValueAsc = 'FIELDS_AVERAGE_VALUE_ASC',
  FieldsAverageValueDesc = 'FIELDS_AVERAGE_VALUE_DESC',
  FieldsAverageCreatedAtAsc = 'FIELDS_AVERAGE_CREATED_AT_ASC',
  FieldsAverageCreatedAtDesc = 'FIELDS_AVERAGE_CREATED_AT_DESC',
  FieldsAverageUpdatedAtAsc = 'FIELDS_AVERAGE_UPDATED_AT_ASC',
  FieldsAverageUpdatedAtDesc = 'FIELDS_AVERAGE_UPDATED_AT_DESC',
  FieldsStddevSampleIdAsc = 'FIELDS_STDDEV_SAMPLE_ID_ASC',
  FieldsStddevSampleIdDesc = 'FIELDS_STDDEV_SAMPLE_ID_DESC',
  FieldsStddevSampleCompanyIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FieldsStddevSampleCompanyIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FieldsStddevSampleCompanyProductIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevSampleCompanyProductIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevSampleCompanyChannelIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevSampleCompanyChannelIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevSampleCompanyIntegrationIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevSampleCompanyIntegrationIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevSampleConfigAsc = 'FIELDS_STDDEV_SAMPLE_CONFIG_ASC',
  FieldsStddevSampleConfigDesc = 'FIELDS_STDDEV_SAMPLE_CONFIG_DESC',
  FieldsStddevSampleTopicAsc = 'FIELDS_STDDEV_SAMPLE_TOPIC_ASC',
  FieldsStddevSampleTopicDesc = 'FIELDS_STDDEV_SAMPLE_TOPIC_DESC',
  FieldsStddevSampleValueAsc = 'FIELDS_STDDEV_SAMPLE_VALUE_ASC',
  FieldsStddevSampleValueDesc = 'FIELDS_STDDEV_SAMPLE_VALUE_DESC',
  FieldsStddevSampleCreatedAtAsc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldsStddevSampleCreatedAtDesc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldsStddevSampleUpdatedAtAsc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldsStddevSampleUpdatedAtDesc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldsStddevPopulationIdAsc = 'FIELDS_STDDEV_POPULATION_ID_ASC',
  FieldsStddevPopulationIdDesc = 'FIELDS_STDDEV_POPULATION_ID_DESC',
  FieldsStddevPopulationCompanyIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_ASC',
  FieldsStddevPopulationCompanyIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_DESC',
  FieldsStddevPopulationCompanyProductIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevPopulationCompanyProductIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevPopulationCompanyChannelIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevPopulationCompanyChannelIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevPopulationCompanyIntegrationIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevPopulationCompanyIntegrationIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevPopulationConfigAsc = 'FIELDS_STDDEV_POPULATION_CONFIG_ASC',
  FieldsStddevPopulationConfigDesc = 'FIELDS_STDDEV_POPULATION_CONFIG_DESC',
  FieldsStddevPopulationTopicAsc = 'FIELDS_STDDEV_POPULATION_TOPIC_ASC',
  FieldsStddevPopulationTopicDesc = 'FIELDS_STDDEV_POPULATION_TOPIC_DESC',
  FieldsStddevPopulationValueAsc = 'FIELDS_STDDEV_POPULATION_VALUE_ASC',
  FieldsStddevPopulationValueDesc = 'FIELDS_STDDEV_POPULATION_VALUE_DESC',
  FieldsStddevPopulationCreatedAtAsc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldsStddevPopulationCreatedAtDesc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldsStddevPopulationUpdatedAtAsc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldsStddevPopulationUpdatedAtDesc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldsVarianceSampleIdAsc = 'FIELDS_VARIANCE_SAMPLE_ID_ASC',
  FieldsVarianceSampleIdDesc = 'FIELDS_VARIANCE_SAMPLE_ID_DESC',
  FieldsVarianceSampleCompanyIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FieldsVarianceSampleCompanyIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FieldsVarianceSampleCompanyProductIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsVarianceSampleCompanyProductIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsVarianceSampleCompanyChannelIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsVarianceSampleCompanyChannelIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsVarianceSampleCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsVarianceSampleCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsVarianceSampleConfigAsc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_ASC',
  FieldsVarianceSampleConfigDesc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_DESC',
  FieldsVarianceSampleTopicAsc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_ASC',
  FieldsVarianceSampleTopicDesc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_DESC',
  FieldsVarianceSampleValueAsc = 'FIELDS_VARIANCE_SAMPLE_VALUE_ASC',
  FieldsVarianceSampleValueDesc = 'FIELDS_VARIANCE_SAMPLE_VALUE_DESC',
  FieldsVarianceSampleCreatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldsVarianceSampleCreatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldsVarianceSampleUpdatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldsVarianceSampleUpdatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldsVariancePopulationIdAsc = 'FIELDS_VARIANCE_POPULATION_ID_ASC',
  FieldsVariancePopulationIdDesc = 'FIELDS_VARIANCE_POPULATION_ID_DESC',
  FieldsVariancePopulationCompanyIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FieldsVariancePopulationCompanyIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FieldsVariancePopulationCompanyProductIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsVariancePopulationCompanyProductIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsVariancePopulationCompanyChannelIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsVariancePopulationCompanyChannelIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsVariancePopulationCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsVariancePopulationCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsVariancePopulationConfigAsc = 'FIELDS_VARIANCE_POPULATION_CONFIG_ASC',
  FieldsVariancePopulationConfigDesc = 'FIELDS_VARIANCE_POPULATION_CONFIG_DESC',
  FieldsVariancePopulationTopicAsc = 'FIELDS_VARIANCE_POPULATION_TOPIC_ASC',
  FieldsVariancePopulationTopicDesc = 'FIELDS_VARIANCE_POPULATION_TOPIC_DESC',
  FieldsVariancePopulationValueAsc = 'FIELDS_VARIANCE_POPULATION_VALUE_ASC',
  FieldsVariancePopulationValueDesc = 'FIELDS_VARIANCE_POPULATION_VALUE_DESC',
  FieldsVariancePopulationCreatedAtAsc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldsVariancePopulationCreatedAtDesc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldsVariancePopulationUpdatedAtAsc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldsVariancePopulationUpdatedAtDesc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  VehicleStocksCountAsc = 'VEHICLE_STOCKS_COUNT_ASC',
  VehicleStocksCountDesc = 'VEHICLE_STOCKS_COUNT_DESC',
  VehicleStocksSumIdAsc = 'VEHICLE_STOCKS_SUM_ID_ASC',
  VehicleStocksSumIdDesc = 'VEHICLE_STOCKS_SUM_ID_DESC',
  VehicleStocksSumCompanyIdAsc = 'VEHICLE_STOCKS_SUM_COMPANY_ID_ASC',
  VehicleStocksSumCompanyIdDesc = 'VEHICLE_STOCKS_SUM_COMPANY_ID_DESC',
  VehicleStocksSumCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_SUM_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksSumCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_SUM_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksSumProviderIdAsc = 'VEHICLE_STOCKS_SUM_PROVIDER_ID_ASC',
  VehicleStocksSumProviderIdDesc = 'VEHICLE_STOCKS_SUM_PROVIDER_ID_DESC',
  VehicleStocksSumVinAsc = 'VEHICLE_STOCKS_SUM_VIN_ASC',
  VehicleStocksSumVinDesc = 'VEHICLE_STOCKS_SUM_VIN_DESC',
  VehicleStocksSumStockNumberAsc = 'VEHICLE_STOCKS_SUM_STOCK_NUMBER_ASC',
  VehicleStocksSumStockNumberDesc = 'VEHICLE_STOCKS_SUM_STOCK_NUMBER_DESC',
  VehicleStocksSumYearAsc = 'VEHICLE_STOCKS_SUM_YEAR_ASC',
  VehicleStocksSumYearDesc = 'VEHICLE_STOCKS_SUM_YEAR_DESC',
  VehicleStocksSumMakeAsc = 'VEHICLE_STOCKS_SUM_MAKE_ASC',
  VehicleStocksSumMakeDesc = 'VEHICLE_STOCKS_SUM_MAKE_DESC',
  VehicleStocksSumModelAsc = 'VEHICLE_STOCKS_SUM_MODEL_ASC',
  VehicleStocksSumModelDesc = 'VEHICLE_STOCKS_SUM_MODEL_DESC',
  VehicleStocksSumTrimAsc = 'VEHICLE_STOCKS_SUM_TRIM_ASC',
  VehicleStocksSumTrimDesc = 'VEHICLE_STOCKS_SUM_TRIM_DESC',
  VehicleStocksSumBodyStyleAsc = 'VEHICLE_STOCKS_SUM_BODY_STYLE_ASC',
  VehicleStocksSumBodyStyleDesc = 'VEHICLE_STOCKS_SUM_BODY_STYLE_DESC',
  VehicleStocksSumExteriorColourAsc = 'VEHICLE_STOCKS_SUM_EXTERIOR_COLOUR_ASC',
  VehicleStocksSumExteriorColourDesc = 'VEHICLE_STOCKS_SUM_EXTERIOR_COLOUR_DESC',
  VehicleStocksSumInteriorColourAsc = 'VEHICLE_STOCKS_SUM_INTERIOR_COLOUR_ASC',
  VehicleStocksSumInteriorColourDesc = 'VEHICLE_STOCKS_SUM_INTERIOR_COLOUR_DESC',
  VehicleStocksSumMileageAsc = 'VEHICLE_STOCKS_SUM_MILEAGE_ASC',
  VehicleStocksSumMileageDesc = 'VEHICLE_STOCKS_SUM_MILEAGE_DESC',
  VehicleStocksSumDoorsAsc = 'VEHICLE_STOCKS_SUM_DOORS_ASC',
  VehicleStocksSumDoorsDesc = 'VEHICLE_STOCKS_SUM_DOORS_DESC',
  VehicleStocksSumSeatsAsc = 'VEHICLE_STOCKS_SUM_SEATS_ASC',
  VehicleStocksSumSeatsDesc = 'VEHICLE_STOCKS_SUM_SEATS_DESC',
  VehicleStocksSumEngineCapacityAsc = 'VEHICLE_STOCKS_SUM_ENGINE_CAPACITY_ASC',
  VehicleStocksSumEngineCapacityDesc = 'VEHICLE_STOCKS_SUM_ENGINE_CAPACITY_DESC',
  VehicleStocksSumTransmissionAsc = 'VEHICLE_STOCKS_SUM_TRANSMISSION_ASC',
  VehicleStocksSumTransmissionDesc = 'VEHICLE_STOCKS_SUM_TRANSMISSION_DESC',
  VehicleStocksSumCylindersAsc = 'VEHICLE_STOCKS_SUM_CYLINDERS_ASC',
  VehicleStocksSumCylindersDesc = 'VEHICLE_STOCKS_SUM_CYLINDERS_DESC',
  VehicleStocksSumDescriptionAsc = 'VEHICLE_STOCKS_SUM_DESCRIPTION_ASC',
  VehicleStocksSumDescriptionDesc = 'VEHICLE_STOCKS_SUM_DESCRIPTION_DESC',
  VehicleStocksSumWarrantyTextAsc = 'VEHICLE_STOCKS_SUM_WARRANTY_TEXT_ASC',
  VehicleStocksSumWarrantyTextDesc = 'VEHICLE_STOCKS_SUM_WARRANTY_TEXT_DESC',
  VehicleStocksSumCarProofAsc = 'VEHICLE_STOCKS_SUM_CAR_PROOF_ASC',
  VehicleStocksSumCarProofDesc = 'VEHICLE_STOCKS_SUM_CAR_PROOF_DESC',
  VehicleStocksSumIsNewAsc = 'VEHICLE_STOCKS_SUM_IS_NEW_ASC',
  VehicleStocksSumIsNewDesc = 'VEHICLE_STOCKS_SUM_IS_NEW_DESC',
  VehicleStocksSumCertifiedAsc = 'VEHICLE_STOCKS_SUM_CERTIFIED_ASC',
  VehicleStocksSumCertifiedDesc = 'VEHICLE_STOCKS_SUM_CERTIFIED_DESC',
  VehicleStocksSumSpecialAsc = 'VEHICLE_STOCKS_SUM_SPECIAL_ASC',
  VehicleStocksSumSpecialDesc = 'VEHICLE_STOCKS_SUM_SPECIAL_DESC',
  VehicleStocksSumDriveTrainAsc = 'VEHICLE_STOCKS_SUM_DRIVE_TRAIN_ASC',
  VehicleStocksSumDriveTrainDesc = 'VEHICLE_STOCKS_SUM_DRIVE_TRAIN_DESC',
  VehicleStocksSumFuelTypeAsc = 'VEHICLE_STOCKS_SUM_FUEL_TYPE_ASC',
  VehicleStocksSumFuelTypeDesc = 'VEHICLE_STOCKS_SUM_FUEL_TYPE_DESC',
  VehicleStocksSumMainImageAsc = 'VEHICLE_STOCKS_SUM_MAIN_IMAGE_ASC',
  VehicleStocksSumMainImageDesc = 'VEHICLE_STOCKS_SUM_MAIN_IMAGE_DESC',
  VehicleStocksSumImagesAsc = 'VEHICLE_STOCKS_SUM_IMAGES_ASC',
  VehicleStocksSumImagesDesc = 'VEHICLE_STOCKS_SUM_IMAGES_DESC',
  VehicleStocksSumVideosAsc = 'VEHICLE_STOCKS_SUM_VIDEOS_ASC',
  VehicleStocksSumVideosDesc = 'VEHICLE_STOCKS_SUM_VIDEOS_DESC',
  VehicleStocksSumFeaturesAsc = 'VEHICLE_STOCKS_SUM_FEATURES_ASC',
  VehicleStocksSumFeaturesDesc = 'VEHICLE_STOCKS_SUM_FEATURES_DESC',
  VehicleStocksSumStockStatusAsc = 'VEHICLE_STOCKS_SUM_STOCK_STATUS_ASC',
  VehicleStocksSumStockStatusDesc = 'VEHICLE_STOCKS_SUM_STOCK_STATUS_DESC',
  VehicleStocksSumPriceAsc = 'VEHICLE_STOCKS_SUM_PRICE_ASC',
  VehicleStocksSumPriceDesc = 'VEHICLE_STOCKS_SUM_PRICE_DESC',
  VehicleStocksSumCostAsc = 'VEHICLE_STOCKS_SUM_COST_ASC',
  VehicleStocksSumCostDesc = 'VEHICLE_STOCKS_SUM_COST_DESC',
  VehicleStocksSumEntryDateAsc = 'VEHICLE_STOCKS_SUM_ENTRY_DATE_ASC',
  VehicleStocksSumEntryDateDesc = 'VEHICLE_STOCKS_SUM_ENTRY_DATE_DESC',
  VehicleStocksSumLastChangeDateAsc = 'VEHICLE_STOCKS_SUM_LAST_CHANGE_DATE_ASC',
  VehicleStocksSumLastChangeDateDesc = 'VEHICLE_STOCKS_SUM_LAST_CHANGE_DATE_DESC',
  VehicleStocksSumCreatedAtAsc = 'VEHICLE_STOCKS_SUM_CREATED_AT_ASC',
  VehicleStocksSumCreatedAtDesc = 'VEHICLE_STOCKS_SUM_CREATED_AT_DESC',
  VehicleStocksSumUpdatedAtAsc = 'VEHICLE_STOCKS_SUM_UPDATED_AT_ASC',
  VehicleStocksSumUpdatedAtDesc = 'VEHICLE_STOCKS_SUM_UPDATED_AT_DESC',
  VehicleStocksSumSearchTextAsc = 'VEHICLE_STOCKS_SUM_SEARCH_TEXT_ASC',
  VehicleStocksSumSearchTextDesc = 'VEHICLE_STOCKS_SUM_SEARCH_TEXT_DESC',
  VehicleStocksDistinctCountIdAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ID_ASC',
  VehicleStocksDistinctCountIdDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ID_DESC',
  VehicleStocksDistinctCountCompanyIdAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COMPANY_ID_ASC',
  VehicleStocksDistinctCountCompanyIdDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COMPANY_ID_DESC',
  VehicleStocksDistinctCountCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksDistinctCountCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksDistinctCountProviderIdAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_PROVIDER_ID_ASC',
  VehicleStocksDistinctCountProviderIdDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_PROVIDER_ID_DESC',
  VehicleStocksDistinctCountVinAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_VIN_ASC',
  VehicleStocksDistinctCountVinDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_VIN_DESC',
  VehicleStocksDistinctCountStockNumberAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_STOCK_NUMBER_ASC',
  VehicleStocksDistinctCountStockNumberDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_STOCK_NUMBER_DESC',
  VehicleStocksDistinctCountYearAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_YEAR_ASC',
  VehicleStocksDistinctCountYearDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_YEAR_DESC',
  VehicleStocksDistinctCountMakeAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MAKE_ASC',
  VehicleStocksDistinctCountMakeDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MAKE_DESC',
  VehicleStocksDistinctCountModelAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MODEL_ASC',
  VehicleStocksDistinctCountModelDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MODEL_DESC',
  VehicleStocksDistinctCountTrimAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_TRIM_ASC',
  VehicleStocksDistinctCountTrimDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_TRIM_DESC',
  VehicleStocksDistinctCountBodyStyleAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_BODY_STYLE_ASC',
  VehicleStocksDistinctCountBodyStyleDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_BODY_STYLE_DESC',
  VehicleStocksDistinctCountExteriorColourAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_EXTERIOR_COLOUR_ASC',
  VehicleStocksDistinctCountExteriorColourDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_EXTERIOR_COLOUR_DESC',
  VehicleStocksDistinctCountInteriorColourAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_INTERIOR_COLOUR_ASC',
  VehicleStocksDistinctCountInteriorColourDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_INTERIOR_COLOUR_DESC',
  VehicleStocksDistinctCountMileageAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MILEAGE_ASC',
  VehicleStocksDistinctCountMileageDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MILEAGE_DESC',
  VehicleStocksDistinctCountDoorsAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DOORS_ASC',
  VehicleStocksDistinctCountDoorsDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DOORS_DESC',
  VehicleStocksDistinctCountSeatsAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SEATS_ASC',
  VehicleStocksDistinctCountSeatsDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SEATS_DESC',
  VehicleStocksDistinctCountEngineCapacityAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ENGINE_CAPACITY_ASC',
  VehicleStocksDistinctCountEngineCapacityDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ENGINE_CAPACITY_DESC',
  VehicleStocksDistinctCountTransmissionAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_TRANSMISSION_ASC',
  VehicleStocksDistinctCountTransmissionDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_TRANSMISSION_DESC',
  VehicleStocksDistinctCountCylindersAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CYLINDERS_ASC',
  VehicleStocksDistinctCountCylindersDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CYLINDERS_DESC',
  VehicleStocksDistinctCountDescriptionAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DESCRIPTION_ASC',
  VehicleStocksDistinctCountDescriptionDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DESCRIPTION_DESC',
  VehicleStocksDistinctCountWarrantyTextAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_WARRANTY_TEXT_ASC',
  VehicleStocksDistinctCountWarrantyTextDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_WARRANTY_TEXT_DESC',
  VehicleStocksDistinctCountCarProofAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CAR_PROOF_ASC',
  VehicleStocksDistinctCountCarProofDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CAR_PROOF_DESC',
  VehicleStocksDistinctCountIsNewAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_IS_NEW_ASC',
  VehicleStocksDistinctCountIsNewDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_IS_NEW_DESC',
  VehicleStocksDistinctCountCertifiedAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CERTIFIED_ASC',
  VehicleStocksDistinctCountCertifiedDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CERTIFIED_DESC',
  VehicleStocksDistinctCountSpecialAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SPECIAL_ASC',
  VehicleStocksDistinctCountSpecialDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SPECIAL_DESC',
  VehicleStocksDistinctCountDriveTrainAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DRIVE_TRAIN_ASC',
  VehicleStocksDistinctCountDriveTrainDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DRIVE_TRAIN_DESC',
  VehicleStocksDistinctCountFuelTypeAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_FUEL_TYPE_ASC',
  VehicleStocksDistinctCountFuelTypeDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_FUEL_TYPE_DESC',
  VehicleStocksDistinctCountMainImageAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MAIN_IMAGE_ASC',
  VehicleStocksDistinctCountMainImageDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MAIN_IMAGE_DESC',
  VehicleStocksDistinctCountImagesAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_IMAGES_ASC',
  VehicleStocksDistinctCountImagesDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_IMAGES_DESC',
  VehicleStocksDistinctCountVideosAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_VIDEOS_ASC',
  VehicleStocksDistinctCountVideosDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_VIDEOS_DESC',
  VehicleStocksDistinctCountFeaturesAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_FEATURES_ASC',
  VehicleStocksDistinctCountFeaturesDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_FEATURES_DESC',
  VehicleStocksDistinctCountStockStatusAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_STOCK_STATUS_ASC',
  VehicleStocksDistinctCountStockStatusDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_STOCK_STATUS_DESC',
  VehicleStocksDistinctCountPriceAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_PRICE_ASC',
  VehicleStocksDistinctCountPriceDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_PRICE_DESC',
  VehicleStocksDistinctCountCostAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COST_ASC',
  VehicleStocksDistinctCountCostDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COST_DESC',
  VehicleStocksDistinctCountEntryDateAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ENTRY_DATE_ASC',
  VehicleStocksDistinctCountEntryDateDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ENTRY_DATE_DESC',
  VehicleStocksDistinctCountLastChangeDateAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_LAST_CHANGE_DATE_ASC',
  VehicleStocksDistinctCountLastChangeDateDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_LAST_CHANGE_DATE_DESC',
  VehicleStocksDistinctCountCreatedAtAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CREATED_AT_ASC',
  VehicleStocksDistinctCountCreatedAtDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CREATED_AT_DESC',
  VehicleStocksDistinctCountUpdatedAtAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_UPDATED_AT_ASC',
  VehicleStocksDistinctCountUpdatedAtDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_UPDATED_AT_DESC',
  VehicleStocksDistinctCountSearchTextAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  VehicleStocksDistinctCountSearchTextDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  VehicleStocksMinIdAsc = 'VEHICLE_STOCKS_MIN_ID_ASC',
  VehicleStocksMinIdDesc = 'VEHICLE_STOCKS_MIN_ID_DESC',
  VehicleStocksMinCompanyIdAsc = 'VEHICLE_STOCKS_MIN_COMPANY_ID_ASC',
  VehicleStocksMinCompanyIdDesc = 'VEHICLE_STOCKS_MIN_COMPANY_ID_DESC',
  VehicleStocksMinCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_MIN_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksMinCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_MIN_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksMinProviderIdAsc = 'VEHICLE_STOCKS_MIN_PROVIDER_ID_ASC',
  VehicleStocksMinProviderIdDesc = 'VEHICLE_STOCKS_MIN_PROVIDER_ID_DESC',
  VehicleStocksMinVinAsc = 'VEHICLE_STOCKS_MIN_VIN_ASC',
  VehicleStocksMinVinDesc = 'VEHICLE_STOCKS_MIN_VIN_DESC',
  VehicleStocksMinStockNumberAsc = 'VEHICLE_STOCKS_MIN_STOCK_NUMBER_ASC',
  VehicleStocksMinStockNumberDesc = 'VEHICLE_STOCKS_MIN_STOCK_NUMBER_DESC',
  VehicleStocksMinYearAsc = 'VEHICLE_STOCKS_MIN_YEAR_ASC',
  VehicleStocksMinYearDesc = 'VEHICLE_STOCKS_MIN_YEAR_DESC',
  VehicleStocksMinMakeAsc = 'VEHICLE_STOCKS_MIN_MAKE_ASC',
  VehicleStocksMinMakeDesc = 'VEHICLE_STOCKS_MIN_MAKE_DESC',
  VehicleStocksMinModelAsc = 'VEHICLE_STOCKS_MIN_MODEL_ASC',
  VehicleStocksMinModelDesc = 'VEHICLE_STOCKS_MIN_MODEL_DESC',
  VehicleStocksMinTrimAsc = 'VEHICLE_STOCKS_MIN_TRIM_ASC',
  VehicleStocksMinTrimDesc = 'VEHICLE_STOCKS_MIN_TRIM_DESC',
  VehicleStocksMinBodyStyleAsc = 'VEHICLE_STOCKS_MIN_BODY_STYLE_ASC',
  VehicleStocksMinBodyStyleDesc = 'VEHICLE_STOCKS_MIN_BODY_STYLE_DESC',
  VehicleStocksMinExteriorColourAsc = 'VEHICLE_STOCKS_MIN_EXTERIOR_COLOUR_ASC',
  VehicleStocksMinExteriorColourDesc = 'VEHICLE_STOCKS_MIN_EXTERIOR_COLOUR_DESC',
  VehicleStocksMinInteriorColourAsc = 'VEHICLE_STOCKS_MIN_INTERIOR_COLOUR_ASC',
  VehicleStocksMinInteriorColourDesc = 'VEHICLE_STOCKS_MIN_INTERIOR_COLOUR_DESC',
  VehicleStocksMinMileageAsc = 'VEHICLE_STOCKS_MIN_MILEAGE_ASC',
  VehicleStocksMinMileageDesc = 'VEHICLE_STOCKS_MIN_MILEAGE_DESC',
  VehicleStocksMinDoorsAsc = 'VEHICLE_STOCKS_MIN_DOORS_ASC',
  VehicleStocksMinDoorsDesc = 'VEHICLE_STOCKS_MIN_DOORS_DESC',
  VehicleStocksMinSeatsAsc = 'VEHICLE_STOCKS_MIN_SEATS_ASC',
  VehicleStocksMinSeatsDesc = 'VEHICLE_STOCKS_MIN_SEATS_DESC',
  VehicleStocksMinEngineCapacityAsc = 'VEHICLE_STOCKS_MIN_ENGINE_CAPACITY_ASC',
  VehicleStocksMinEngineCapacityDesc = 'VEHICLE_STOCKS_MIN_ENGINE_CAPACITY_DESC',
  VehicleStocksMinTransmissionAsc = 'VEHICLE_STOCKS_MIN_TRANSMISSION_ASC',
  VehicleStocksMinTransmissionDesc = 'VEHICLE_STOCKS_MIN_TRANSMISSION_DESC',
  VehicleStocksMinCylindersAsc = 'VEHICLE_STOCKS_MIN_CYLINDERS_ASC',
  VehicleStocksMinCylindersDesc = 'VEHICLE_STOCKS_MIN_CYLINDERS_DESC',
  VehicleStocksMinDescriptionAsc = 'VEHICLE_STOCKS_MIN_DESCRIPTION_ASC',
  VehicleStocksMinDescriptionDesc = 'VEHICLE_STOCKS_MIN_DESCRIPTION_DESC',
  VehicleStocksMinWarrantyTextAsc = 'VEHICLE_STOCKS_MIN_WARRANTY_TEXT_ASC',
  VehicleStocksMinWarrantyTextDesc = 'VEHICLE_STOCKS_MIN_WARRANTY_TEXT_DESC',
  VehicleStocksMinCarProofAsc = 'VEHICLE_STOCKS_MIN_CAR_PROOF_ASC',
  VehicleStocksMinCarProofDesc = 'VEHICLE_STOCKS_MIN_CAR_PROOF_DESC',
  VehicleStocksMinIsNewAsc = 'VEHICLE_STOCKS_MIN_IS_NEW_ASC',
  VehicleStocksMinIsNewDesc = 'VEHICLE_STOCKS_MIN_IS_NEW_DESC',
  VehicleStocksMinCertifiedAsc = 'VEHICLE_STOCKS_MIN_CERTIFIED_ASC',
  VehicleStocksMinCertifiedDesc = 'VEHICLE_STOCKS_MIN_CERTIFIED_DESC',
  VehicleStocksMinSpecialAsc = 'VEHICLE_STOCKS_MIN_SPECIAL_ASC',
  VehicleStocksMinSpecialDesc = 'VEHICLE_STOCKS_MIN_SPECIAL_DESC',
  VehicleStocksMinDriveTrainAsc = 'VEHICLE_STOCKS_MIN_DRIVE_TRAIN_ASC',
  VehicleStocksMinDriveTrainDesc = 'VEHICLE_STOCKS_MIN_DRIVE_TRAIN_DESC',
  VehicleStocksMinFuelTypeAsc = 'VEHICLE_STOCKS_MIN_FUEL_TYPE_ASC',
  VehicleStocksMinFuelTypeDesc = 'VEHICLE_STOCKS_MIN_FUEL_TYPE_DESC',
  VehicleStocksMinMainImageAsc = 'VEHICLE_STOCKS_MIN_MAIN_IMAGE_ASC',
  VehicleStocksMinMainImageDesc = 'VEHICLE_STOCKS_MIN_MAIN_IMAGE_DESC',
  VehicleStocksMinImagesAsc = 'VEHICLE_STOCKS_MIN_IMAGES_ASC',
  VehicleStocksMinImagesDesc = 'VEHICLE_STOCKS_MIN_IMAGES_DESC',
  VehicleStocksMinVideosAsc = 'VEHICLE_STOCKS_MIN_VIDEOS_ASC',
  VehicleStocksMinVideosDesc = 'VEHICLE_STOCKS_MIN_VIDEOS_DESC',
  VehicleStocksMinFeaturesAsc = 'VEHICLE_STOCKS_MIN_FEATURES_ASC',
  VehicleStocksMinFeaturesDesc = 'VEHICLE_STOCKS_MIN_FEATURES_DESC',
  VehicleStocksMinStockStatusAsc = 'VEHICLE_STOCKS_MIN_STOCK_STATUS_ASC',
  VehicleStocksMinStockStatusDesc = 'VEHICLE_STOCKS_MIN_STOCK_STATUS_DESC',
  VehicleStocksMinPriceAsc = 'VEHICLE_STOCKS_MIN_PRICE_ASC',
  VehicleStocksMinPriceDesc = 'VEHICLE_STOCKS_MIN_PRICE_DESC',
  VehicleStocksMinCostAsc = 'VEHICLE_STOCKS_MIN_COST_ASC',
  VehicleStocksMinCostDesc = 'VEHICLE_STOCKS_MIN_COST_DESC',
  VehicleStocksMinEntryDateAsc = 'VEHICLE_STOCKS_MIN_ENTRY_DATE_ASC',
  VehicleStocksMinEntryDateDesc = 'VEHICLE_STOCKS_MIN_ENTRY_DATE_DESC',
  VehicleStocksMinLastChangeDateAsc = 'VEHICLE_STOCKS_MIN_LAST_CHANGE_DATE_ASC',
  VehicleStocksMinLastChangeDateDesc = 'VEHICLE_STOCKS_MIN_LAST_CHANGE_DATE_DESC',
  VehicleStocksMinCreatedAtAsc = 'VEHICLE_STOCKS_MIN_CREATED_AT_ASC',
  VehicleStocksMinCreatedAtDesc = 'VEHICLE_STOCKS_MIN_CREATED_AT_DESC',
  VehicleStocksMinUpdatedAtAsc = 'VEHICLE_STOCKS_MIN_UPDATED_AT_ASC',
  VehicleStocksMinUpdatedAtDesc = 'VEHICLE_STOCKS_MIN_UPDATED_AT_DESC',
  VehicleStocksMinSearchTextAsc = 'VEHICLE_STOCKS_MIN_SEARCH_TEXT_ASC',
  VehicleStocksMinSearchTextDesc = 'VEHICLE_STOCKS_MIN_SEARCH_TEXT_DESC',
  VehicleStocksMaxIdAsc = 'VEHICLE_STOCKS_MAX_ID_ASC',
  VehicleStocksMaxIdDesc = 'VEHICLE_STOCKS_MAX_ID_DESC',
  VehicleStocksMaxCompanyIdAsc = 'VEHICLE_STOCKS_MAX_COMPANY_ID_ASC',
  VehicleStocksMaxCompanyIdDesc = 'VEHICLE_STOCKS_MAX_COMPANY_ID_DESC',
  VehicleStocksMaxCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_MAX_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksMaxCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_MAX_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksMaxProviderIdAsc = 'VEHICLE_STOCKS_MAX_PROVIDER_ID_ASC',
  VehicleStocksMaxProviderIdDesc = 'VEHICLE_STOCKS_MAX_PROVIDER_ID_DESC',
  VehicleStocksMaxVinAsc = 'VEHICLE_STOCKS_MAX_VIN_ASC',
  VehicleStocksMaxVinDesc = 'VEHICLE_STOCKS_MAX_VIN_DESC',
  VehicleStocksMaxStockNumberAsc = 'VEHICLE_STOCKS_MAX_STOCK_NUMBER_ASC',
  VehicleStocksMaxStockNumberDesc = 'VEHICLE_STOCKS_MAX_STOCK_NUMBER_DESC',
  VehicleStocksMaxYearAsc = 'VEHICLE_STOCKS_MAX_YEAR_ASC',
  VehicleStocksMaxYearDesc = 'VEHICLE_STOCKS_MAX_YEAR_DESC',
  VehicleStocksMaxMakeAsc = 'VEHICLE_STOCKS_MAX_MAKE_ASC',
  VehicleStocksMaxMakeDesc = 'VEHICLE_STOCKS_MAX_MAKE_DESC',
  VehicleStocksMaxModelAsc = 'VEHICLE_STOCKS_MAX_MODEL_ASC',
  VehicleStocksMaxModelDesc = 'VEHICLE_STOCKS_MAX_MODEL_DESC',
  VehicleStocksMaxTrimAsc = 'VEHICLE_STOCKS_MAX_TRIM_ASC',
  VehicleStocksMaxTrimDesc = 'VEHICLE_STOCKS_MAX_TRIM_DESC',
  VehicleStocksMaxBodyStyleAsc = 'VEHICLE_STOCKS_MAX_BODY_STYLE_ASC',
  VehicleStocksMaxBodyStyleDesc = 'VEHICLE_STOCKS_MAX_BODY_STYLE_DESC',
  VehicleStocksMaxExteriorColourAsc = 'VEHICLE_STOCKS_MAX_EXTERIOR_COLOUR_ASC',
  VehicleStocksMaxExteriorColourDesc = 'VEHICLE_STOCKS_MAX_EXTERIOR_COLOUR_DESC',
  VehicleStocksMaxInteriorColourAsc = 'VEHICLE_STOCKS_MAX_INTERIOR_COLOUR_ASC',
  VehicleStocksMaxInteriorColourDesc = 'VEHICLE_STOCKS_MAX_INTERIOR_COLOUR_DESC',
  VehicleStocksMaxMileageAsc = 'VEHICLE_STOCKS_MAX_MILEAGE_ASC',
  VehicleStocksMaxMileageDesc = 'VEHICLE_STOCKS_MAX_MILEAGE_DESC',
  VehicleStocksMaxDoorsAsc = 'VEHICLE_STOCKS_MAX_DOORS_ASC',
  VehicleStocksMaxDoorsDesc = 'VEHICLE_STOCKS_MAX_DOORS_DESC',
  VehicleStocksMaxSeatsAsc = 'VEHICLE_STOCKS_MAX_SEATS_ASC',
  VehicleStocksMaxSeatsDesc = 'VEHICLE_STOCKS_MAX_SEATS_DESC',
  VehicleStocksMaxEngineCapacityAsc = 'VEHICLE_STOCKS_MAX_ENGINE_CAPACITY_ASC',
  VehicleStocksMaxEngineCapacityDesc = 'VEHICLE_STOCKS_MAX_ENGINE_CAPACITY_DESC',
  VehicleStocksMaxTransmissionAsc = 'VEHICLE_STOCKS_MAX_TRANSMISSION_ASC',
  VehicleStocksMaxTransmissionDesc = 'VEHICLE_STOCKS_MAX_TRANSMISSION_DESC',
  VehicleStocksMaxCylindersAsc = 'VEHICLE_STOCKS_MAX_CYLINDERS_ASC',
  VehicleStocksMaxCylindersDesc = 'VEHICLE_STOCKS_MAX_CYLINDERS_DESC',
  VehicleStocksMaxDescriptionAsc = 'VEHICLE_STOCKS_MAX_DESCRIPTION_ASC',
  VehicleStocksMaxDescriptionDesc = 'VEHICLE_STOCKS_MAX_DESCRIPTION_DESC',
  VehicleStocksMaxWarrantyTextAsc = 'VEHICLE_STOCKS_MAX_WARRANTY_TEXT_ASC',
  VehicleStocksMaxWarrantyTextDesc = 'VEHICLE_STOCKS_MAX_WARRANTY_TEXT_DESC',
  VehicleStocksMaxCarProofAsc = 'VEHICLE_STOCKS_MAX_CAR_PROOF_ASC',
  VehicleStocksMaxCarProofDesc = 'VEHICLE_STOCKS_MAX_CAR_PROOF_DESC',
  VehicleStocksMaxIsNewAsc = 'VEHICLE_STOCKS_MAX_IS_NEW_ASC',
  VehicleStocksMaxIsNewDesc = 'VEHICLE_STOCKS_MAX_IS_NEW_DESC',
  VehicleStocksMaxCertifiedAsc = 'VEHICLE_STOCKS_MAX_CERTIFIED_ASC',
  VehicleStocksMaxCertifiedDesc = 'VEHICLE_STOCKS_MAX_CERTIFIED_DESC',
  VehicleStocksMaxSpecialAsc = 'VEHICLE_STOCKS_MAX_SPECIAL_ASC',
  VehicleStocksMaxSpecialDesc = 'VEHICLE_STOCKS_MAX_SPECIAL_DESC',
  VehicleStocksMaxDriveTrainAsc = 'VEHICLE_STOCKS_MAX_DRIVE_TRAIN_ASC',
  VehicleStocksMaxDriveTrainDesc = 'VEHICLE_STOCKS_MAX_DRIVE_TRAIN_DESC',
  VehicleStocksMaxFuelTypeAsc = 'VEHICLE_STOCKS_MAX_FUEL_TYPE_ASC',
  VehicleStocksMaxFuelTypeDesc = 'VEHICLE_STOCKS_MAX_FUEL_TYPE_DESC',
  VehicleStocksMaxMainImageAsc = 'VEHICLE_STOCKS_MAX_MAIN_IMAGE_ASC',
  VehicleStocksMaxMainImageDesc = 'VEHICLE_STOCKS_MAX_MAIN_IMAGE_DESC',
  VehicleStocksMaxImagesAsc = 'VEHICLE_STOCKS_MAX_IMAGES_ASC',
  VehicleStocksMaxImagesDesc = 'VEHICLE_STOCKS_MAX_IMAGES_DESC',
  VehicleStocksMaxVideosAsc = 'VEHICLE_STOCKS_MAX_VIDEOS_ASC',
  VehicleStocksMaxVideosDesc = 'VEHICLE_STOCKS_MAX_VIDEOS_DESC',
  VehicleStocksMaxFeaturesAsc = 'VEHICLE_STOCKS_MAX_FEATURES_ASC',
  VehicleStocksMaxFeaturesDesc = 'VEHICLE_STOCKS_MAX_FEATURES_DESC',
  VehicleStocksMaxStockStatusAsc = 'VEHICLE_STOCKS_MAX_STOCK_STATUS_ASC',
  VehicleStocksMaxStockStatusDesc = 'VEHICLE_STOCKS_MAX_STOCK_STATUS_DESC',
  VehicleStocksMaxPriceAsc = 'VEHICLE_STOCKS_MAX_PRICE_ASC',
  VehicleStocksMaxPriceDesc = 'VEHICLE_STOCKS_MAX_PRICE_DESC',
  VehicleStocksMaxCostAsc = 'VEHICLE_STOCKS_MAX_COST_ASC',
  VehicleStocksMaxCostDesc = 'VEHICLE_STOCKS_MAX_COST_DESC',
  VehicleStocksMaxEntryDateAsc = 'VEHICLE_STOCKS_MAX_ENTRY_DATE_ASC',
  VehicleStocksMaxEntryDateDesc = 'VEHICLE_STOCKS_MAX_ENTRY_DATE_DESC',
  VehicleStocksMaxLastChangeDateAsc = 'VEHICLE_STOCKS_MAX_LAST_CHANGE_DATE_ASC',
  VehicleStocksMaxLastChangeDateDesc = 'VEHICLE_STOCKS_MAX_LAST_CHANGE_DATE_DESC',
  VehicleStocksMaxCreatedAtAsc = 'VEHICLE_STOCKS_MAX_CREATED_AT_ASC',
  VehicleStocksMaxCreatedAtDesc = 'VEHICLE_STOCKS_MAX_CREATED_AT_DESC',
  VehicleStocksMaxUpdatedAtAsc = 'VEHICLE_STOCKS_MAX_UPDATED_AT_ASC',
  VehicleStocksMaxUpdatedAtDesc = 'VEHICLE_STOCKS_MAX_UPDATED_AT_DESC',
  VehicleStocksMaxSearchTextAsc = 'VEHICLE_STOCKS_MAX_SEARCH_TEXT_ASC',
  VehicleStocksMaxSearchTextDesc = 'VEHICLE_STOCKS_MAX_SEARCH_TEXT_DESC',
  VehicleStocksAverageIdAsc = 'VEHICLE_STOCKS_AVERAGE_ID_ASC',
  VehicleStocksAverageIdDesc = 'VEHICLE_STOCKS_AVERAGE_ID_DESC',
  VehicleStocksAverageCompanyIdAsc = 'VEHICLE_STOCKS_AVERAGE_COMPANY_ID_ASC',
  VehicleStocksAverageCompanyIdDesc = 'VEHICLE_STOCKS_AVERAGE_COMPANY_ID_DESC',
  VehicleStocksAverageCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksAverageCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksAverageProviderIdAsc = 'VEHICLE_STOCKS_AVERAGE_PROVIDER_ID_ASC',
  VehicleStocksAverageProviderIdDesc = 'VEHICLE_STOCKS_AVERAGE_PROVIDER_ID_DESC',
  VehicleStocksAverageVinAsc = 'VEHICLE_STOCKS_AVERAGE_VIN_ASC',
  VehicleStocksAverageVinDesc = 'VEHICLE_STOCKS_AVERAGE_VIN_DESC',
  VehicleStocksAverageStockNumberAsc = 'VEHICLE_STOCKS_AVERAGE_STOCK_NUMBER_ASC',
  VehicleStocksAverageStockNumberDesc = 'VEHICLE_STOCKS_AVERAGE_STOCK_NUMBER_DESC',
  VehicleStocksAverageYearAsc = 'VEHICLE_STOCKS_AVERAGE_YEAR_ASC',
  VehicleStocksAverageYearDesc = 'VEHICLE_STOCKS_AVERAGE_YEAR_DESC',
  VehicleStocksAverageMakeAsc = 'VEHICLE_STOCKS_AVERAGE_MAKE_ASC',
  VehicleStocksAverageMakeDesc = 'VEHICLE_STOCKS_AVERAGE_MAKE_DESC',
  VehicleStocksAverageModelAsc = 'VEHICLE_STOCKS_AVERAGE_MODEL_ASC',
  VehicleStocksAverageModelDesc = 'VEHICLE_STOCKS_AVERAGE_MODEL_DESC',
  VehicleStocksAverageTrimAsc = 'VEHICLE_STOCKS_AVERAGE_TRIM_ASC',
  VehicleStocksAverageTrimDesc = 'VEHICLE_STOCKS_AVERAGE_TRIM_DESC',
  VehicleStocksAverageBodyStyleAsc = 'VEHICLE_STOCKS_AVERAGE_BODY_STYLE_ASC',
  VehicleStocksAverageBodyStyleDesc = 'VEHICLE_STOCKS_AVERAGE_BODY_STYLE_DESC',
  VehicleStocksAverageExteriorColourAsc = 'VEHICLE_STOCKS_AVERAGE_EXTERIOR_COLOUR_ASC',
  VehicleStocksAverageExteriorColourDesc = 'VEHICLE_STOCKS_AVERAGE_EXTERIOR_COLOUR_DESC',
  VehicleStocksAverageInteriorColourAsc = 'VEHICLE_STOCKS_AVERAGE_INTERIOR_COLOUR_ASC',
  VehicleStocksAverageInteriorColourDesc = 'VEHICLE_STOCKS_AVERAGE_INTERIOR_COLOUR_DESC',
  VehicleStocksAverageMileageAsc = 'VEHICLE_STOCKS_AVERAGE_MILEAGE_ASC',
  VehicleStocksAverageMileageDesc = 'VEHICLE_STOCKS_AVERAGE_MILEAGE_DESC',
  VehicleStocksAverageDoorsAsc = 'VEHICLE_STOCKS_AVERAGE_DOORS_ASC',
  VehicleStocksAverageDoorsDesc = 'VEHICLE_STOCKS_AVERAGE_DOORS_DESC',
  VehicleStocksAverageSeatsAsc = 'VEHICLE_STOCKS_AVERAGE_SEATS_ASC',
  VehicleStocksAverageSeatsDesc = 'VEHICLE_STOCKS_AVERAGE_SEATS_DESC',
  VehicleStocksAverageEngineCapacityAsc = 'VEHICLE_STOCKS_AVERAGE_ENGINE_CAPACITY_ASC',
  VehicleStocksAverageEngineCapacityDesc = 'VEHICLE_STOCKS_AVERAGE_ENGINE_CAPACITY_DESC',
  VehicleStocksAverageTransmissionAsc = 'VEHICLE_STOCKS_AVERAGE_TRANSMISSION_ASC',
  VehicleStocksAverageTransmissionDesc = 'VEHICLE_STOCKS_AVERAGE_TRANSMISSION_DESC',
  VehicleStocksAverageCylindersAsc = 'VEHICLE_STOCKS_AVERAGE_CYLINDERS_ASC',
  VehicleStocksAverageCylindersDesc = 'VEHICLE_STOCKS_AVERAGE_CYLINDERS_DESC',
  VehicleStocksAverageDescriptionAsc = 'VEHICLE_STOCKS_AVERAGE_DESCRIPTION_ASC',
  VehicleStocksAverageDescriptionDesc = 'VEHICLE_STOCKS_AVERAGE_DESCRIPTION_DESC',
  VehicleStocksAverageWarrantyTextAsc = 'VEHICLE_STOCKS_AVERAGE_WARRANTY_TEXT_ASC',
  VehicleStocksAverageWarrantyTextDesc = 'VEHICLE_STOCKS_AVERAGE_WARRANTY_TEXT_DESC',
  VehicleStocksAverageCarProofAsc = 'VEHICLE_STOCKS_AVERAGE_CAR_PROOF_ASC',
  VehicleStocksAverageCarProofDesc = 'VEHICLE_STOCKS_AVERAGE_CAR_PROOF_DESC',
  VehicleStocksAverageIsNewAsc = 'VEHICLE_STOCKS_AVERAGE_IS_NEW_ASC',
  VehicleStocksAverageIsNewDesc = 'VEHICLE_STOCKS_AVERAGE_IS_NEW_DESC',
  VehicleStocksAverageCertifiedAsc = 'VEHICLE_STOCKS_AVERAGE_CERTIFIED_ASC',
  VehicleStocksAverageCertifiedDesc = 'VEHICLE_STOCKS_AVERAGE_CERTIFIED_DESC',
  VehicleStocksAverageSpecialAsc = 'VEHICLE_STOCKS_AVERAGE_SPECIAL_ASC',
  VehicleStocksAverageSpecialDesc = 'VEHICLE_STOCKS_AVERAGE_SPECIAL_DESC',
  VehicleStocksAverageDriveTrainAsc = 'VEHICLE_STOCKS_AVERAGE_DRIVE_TRAIN_ASC',
  VehicleStocksAverageDriveTrainDesc = 'VEHICLE_STOCKS_AVERAGE_DRIVE_TRAIN_DESC',
  VehicleStocksAverageFuelTypeAsc = 'VEHICLE_STOCKS_AVERAGE_FUEL_TYPE_ASC',
  VehicleStocksAverageFuelTypeDesc = 'VEHICLE_STOCKS_AVERAGE_FUEL_TYPE_DESC',
  VehicleStocksAverageMainImageAsc = 'VEHICLE_STOCKS_AVERAGE_MAIN_IMAGE_ASC',
  VehicleStocksAverageMainImageDesc = 'VEHICLE_STOCKS_AVERAGE_MAIN_IMAGE_DESC',
  VehicleStocksAverageImagesAsc = 'VEHICLE_STOCKS_AVERAGE_IMAGES_ASC',
  VehicleStocksAverageImagesDesc = 'VEHICLE_STOCKS_AVERAGE_IMAGES_DESC',
  VehicleStocksAverageVideosAsc = 'VEHICLE_STOCKS_AVERAGE_VIDEOS_ASC',
  VehicleStocksAverageVideosDesc = 'VEHICLE_STOCKS_AVERAGE_VIDEOS_DESC',
  VehicleStocksAverageFeaturesAsc = 'VEHICLE_STOCKS_AVERAGE_FEATURES_ASC',
  VehicleStocksAverageFeaturesDesc = 'VEHICLE_STOCKS_AVERAGE_FEATURES_DESC',
  VehicleStocksAverageStockStatusAsc = 'VEHICLE_STOCKS_AVERAGE_STOCK_STATUS_ASC',
  VehicleStocksAverageStockStatusDesc = 'VEHICLE_STOCKS_AVERAGE_STOCK_STATUS_DESC',
  VehicleStocksAveragePriceAsc = 'VEHICLE_STOCKS_AVERAGE_PRICE_ASC',
  VehicleStocksAveragePriceDesc = 'VEHICLE_STOCKS_AVERAGE_PRICE_DESC',
  VehicleStocksAverageCostAsc = 'VEHICLE_STOCKS_AVERAGE_COST_ASC',
  VehicleStocksAverageCostDesc = 'VEHICLE_STOCKS_AVERAGE_COST_DESC',
  VehicleStocksAverageEntryDateAsc = 'VEHICLE_STOCKS_AVERAGE_ENTRY_DATE_ASC',
  VehicleStocksAverageEntryDateDesc = 'VEHICLE_STOCKS_AVERAGE_ENTRY_DATE_DESC',
  VehicleStocksAverageLastChangeDateAsc = 'VEHICLE_STOCKS_AVERAGE_LAST_CHANGE_DATE_ASC',
  VehicleStocksAverageLastChangeDateDesc = 'VEHICLE_STOCKS_AVERAGE_LAST_CHANGE_DATE_DESC',
  VehicleStocksAverageCreatedAtAsc = 'VEHICLE_STOCKS_AVERAGE_CREATED_AT_ASC',
  VehicleStocksAverageCreatedAtDesc = 'VEHICLE_STOCKS_AVERAGE_CREATED_AT_DESC',
  VehicleStocksAverageUpdatedAtAsc = 'VEHICLE_STOCKS_AVERAGE_UPDATED_AT_ASC',
  VehicleStocksAverageUpdatedAtDesc = 'VEHICLE_STOCKS_AVERAGE_UPDATED_AT_DESC',
  VehicleStocksAverageSearchTextAsc = 'VEHICLE_STOCKS_AVERAGE_SEARCH_TEXT_ASC',
  VehicleStocksAverageSearchTextDesc = 'VEHICLE_STOCKS_AVERAGE_SEARCH_TEXT_DESC',
  VehicleStocksStddevSampleIdAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ID_ASC',
  VehicleStocksStddevSampleIdDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ID_DESC',
  VehicleStocksStddevSampleCompanyIdAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  VehicleStocksStddevSampleCompanyIdDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  VehicleStocksStddevSampleCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksStddevSampleCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksStddevSampleProviderIdAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_PROVIDER_ID_ASC',
  VehicleStocksStddevSampleProviderIdDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_PROVIDER_ID_DESC',
  VehicleStocksStddevSampleVinAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_VIN_ASC',
  VehicleStocksStddevSampleVinDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_VIN_DESC',
  VehicleStocksStddevSampleStockNumberAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_STOCK_NUMBER_ASC',
  VehicleStocksStddevSampleStockNumberDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_STOCK_NUMBER_DESC',
  VehicleStocksStddevSampleYearAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_YEAR_ASC',
  VehicleStocksStddevSampleYearDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_YEAR_DESC',
  VehicleStocksStddevSampleMakeAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MAKE_ASC',
  VehicleStocksStddevSampleMakeDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MAKE_DESC',
  VehicleStocksStddevSampleModelAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MODEL_ASC',
  VehicleStocksStddevSampleModelDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MODEL_DESC',
  VehicleStocksStddevSampleTrimAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_TRIM_ASC',
  VehicleStocksStddevSampleTrimDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_TRIM_DESC',
  VehicleStocksStddevSampleBodyStyleAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_BODY_STYLE_ASC',
  VehicleStocksStddevSampleBodyStyleDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_BODY_STYLE_DESC',
  VehicleStocksStddevSampleExteriorColourAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_EXTERIOR_COLOUR_ASC',
  VehicleStocksStddevSampleExteriorColourDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_EXTERIOR_COLOUR_DESC',
  VehicleStocksStddevSampleInteriorColourAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_INTERIOR_COLOUR_ASC',
  VehicleStocksStddevSampleInteriorColourDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_INTERIOR_COLOUR_DESC',
  VehicleStocksStddevSampleMileageAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MILEAGE_ASC',
  VehicleStocksStddevSampleMileageDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MILEAGE_DESC',
  VehicleStocksStddevSampleDoorsAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DOORS_ASC',
  VehicleStocksStddevSampleDoorsDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DOORS_DESC',
  VehicleStocksStddevSampleSeatsAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SEATS_ASC',
  VehicleStocksStddevSampleSeatsDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SEATS_DESC',
  VehicleStocksStddevSampleEngineCapacityAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ENGINE_CAPACITY_ASC',
  VehicleStocksStddevSampleEngineCapacityDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ENGINE_CAPACITY_DESC',
  VehicleStocksStddevSampleTransmissionAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_TRANSMISSION_ASC',
  VehicleStocksStddevSampleTransmissionDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_TRANSMISSION_DESC',
  VehicleStocksStddevSampleCylindersAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CYLINDERS_ASC',
  VehicleStocksStddevSampleCylindersDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CYLINDERS_DESC',
  VehicleStocksStddevSampleDescriptionAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DESCRIPTION_ASC',
  VehicleStocksStddevSampleDescriptionDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DESCRIPTION_DESC',
  VehicleStocksStddevSampleWarrantyTextAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_WARRANTY_TEXT_ASC',
  VehicleStocksStddevSampleWarrantyTextDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_WARRANTY_TEXT_DESC',
  VehicleStocksStddevSampleCarProofAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CAR_PROOF_ASC',
  VehicleStocksStddevSampleCarProofDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CAR_PROOF_DESC',
  VehicleStocksStddevSampleIsNewAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_IS_NEW_ASC',
  VehicleStocksStddevSampleIsNewDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_IS_NEW_DESC',
  VehicleStocksStddevSampleCertifiedAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CERTIFIED_ASC',
  VehicleStocksStddevSampleCertifiedDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CERTIFIED_DESC',
  VehicleStocksStddevSampleSpecialAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SPECIAL_ASC',
  VehicleStocksStddevSampleSpecialDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SPECIAL_DESC',
  VehicleStocksStddevSampleDriveTrainAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DRIVE_TRAIN_ASC',
  VehicleStocksStddevSampleDriveTrainDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DRIVE_TRAIN_DESC',
  VehicleStocksStddevSampleFuelTypeAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_FUEL_TYPE_ASC',
  VehicleStocksStddevSampleFuelTypeDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_FUEL_TYPE_DESC',
  VehicleStocksStddevSampleMainImageAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MAIN_IMAGE_ASC',
  VehicleStocksStddevSampleMainImageDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MAIN_IMAGE_DESC',
  VehicleStocksStddevSampleImagesAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_IMAGES_ASC',
  VehicleStocksStddevSampleImagesDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_IMAGES_DESC',
  VehicleStocksStddevSampleVideosAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_VIDEOS_ASC',
  VehicleStocksStddevSampleVideosDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_VIDEOS_DESC',
  VehicleStocksStddevSampleFeaturesAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_FEATURES_ASC',
  VehicleStocksStddevSampleFeaturesDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_FEATURES_DESC',
  VehicleStocksStddevSampleStockStatusAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_STOCK_STATUS_ASC',
  VehicleStocksStddevSampleStockStatusDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_STOCK_STATUS_DESC',
  VehicleStocksStddevSamplePriceAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_PRICE_ASC',
  VehicleStocksStddevSamplePriceDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_PRICE_DESC',
  VehicleStocksStddevSampleCostAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COST_ASC',
  VehicleStocksStddevSampleCostDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COST_DESC',
  VehicleStocksStddevSampleEntryDateAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ENTRY_DATE_ASC',
  VehicleStocksStddevSampleEntryDateDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ENTRY_DATE_DESC',
  VehicleStocksStddevSampleLastChangeDateAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_LAST_CHANGE_DATE_ASC',
  VehicleStocksStddevSampleLastChangeDateDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_LAST_CHANGE_DATE_DESC',
  VehicleStocksStddevSampleCreatedAtAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CREATED_AT_ASC',
  VehicleStocksStddevSampleCreatedAtDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CREATED_AT_DESC',
  VehicleStocksStddevSampleUpdatedAtAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  VehicleStocksStddevSampleUpdatedAtDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  VehicleStocksStddevSampleSearchTextAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  VehicleStocksStddevSampleSearchTextDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  VehicleStocksStddevPopulationIdAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ID_ASC',
  VehicleStocksStddevPopulationIdDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ID_DESC',
  VehicleStocksStddevPopulationCompanyIdAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COMPANY_ID_ASC',
  VehicleStocksStddevPopulationCompanyIdDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COMPANY_ID_DESC',
  VehicleStocksStddevPopulationCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksStddevPopulationCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksStddevPopulationProviderIdAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_PROVIDER_ID_ASC',
  VehicleStocksStddevPopulationProviderIdDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_PROVIDER_ID_DESC',
  VehicleStocksStddevPopulationVinAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_VIN_ASC',
  VehicleStocksStddevPopulationVinDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_VIN_DESC',
  VehicleStocksStddevPopulationStockNumberAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_STOCK_NUMBER_ASC',
  VehicleStocksStddevPopulationStockNumberDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_STOCK_NUMBER_DESC',
  VehicleStocksStddevPopulationYearAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_YEAR_ASC',
  VehicleStocksStddevPopulationYearDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_YEAR_DESC',
  VehicleStocksStddevPopulationMakeAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MAKE_ASC',
  VehicleStocksStddevPopulationMakeDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MAKE_DESC',
  VehicleStocksStddevPopulationModelAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MODEL_ASC',
  VehicleStocksStddevPopulationModelDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MODEL_DESC',
  VehicleStocksStddevPopulationTrimAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_TRIM_ASC',
  VehicleStocksStddevPopulationTrimDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_TRIM_DESC',
  VehicleStocksStddevPopulationBodyStyleAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_BODY_STYLE_ASC',
  VehicleStocksStddevPopulationBodyStyleDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_BODY_STYLE_DESC',
  VehicleStocksStddevPopulationExteriorColourAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_EXTERIOR_COLOUR_ASC',
  VehicleStocksStddevPopulationExteriorColourDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_EXTERIOR_COLOUR_DESC',
  VehicleStocksStddevPopulationInteriorColourAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_INTERIOR_COLOUR_ASC',
  VehicleStocksStddevPopulationInteriorColourDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_INTERIOR_COLOUR_DESC',
  VehicleStocksStddevPopulationMileageAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MILEAGE_ASC',
  VehicleStocksStddevPopulationMileageDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MILEAGE_DESC',
  VehicleStocksStddevPopulationDoorsAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DOORS_ASC',
  VehicleStocksStddevPopulationDoorsDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DOORS_DESC',
  VehicleStocksStddevPopulationSeatsAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SEATS_ASC',
  VehicleStocksStddevPopulationSeatsDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SEATS_DESC',
  VehicleStocksStddevPopulationEngineCapacityAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ENGINE_CAPACITY_ASC',
  VehicleStocksStddevPopulationEngineCapacityDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ENGINE_CAPACITY_DESC',
  VehicleStocksStddevPopulationTransmissionAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_TRANSMISSION_ASC',
  VehicleStocksStddevPopulationTransmissionDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_TRANSMISSION_DESC',
  VehicleStocksStddevPopulationCylindersAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CYLINDERS_ASC',
  VehicleStocksStddevPopulationCylindersDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CYLINDERS_DESC',
  VehicleStocksStddevPopulationDescriptionAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DESCRIPTION_ASC',
  VehicleStocksStddevPopulationDescriptionDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DESCRIPTION_DESC',
  VehicleStocksStddevPopulationWarrantyTextAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_WARRANTY_TEXT_ASC',
  VehicleStocksStddevPopulationWarrantyTextDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_WARRANTY_TEXT_DESC',
  VehicleStocksStddevPopulationCarProofAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CAR_PROOF_ASC',
  VehicleStocksStddevPopulationCarProofDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CAR_PROOF_DESC',
  VehicleStocksStddevPopulationIsNewAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_IS_NEW_ASC',
  VehicleStocksStddevPopulationIsNewDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_IS_NEW_DESC',
  VehicleStocksStddevPopulationCertifiedAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CERTIFIED_ASC',
  VehicleStocksStddevPopulationCertifiedDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CERTIFIED_DESC',
  VehicleStocksStddevPopulationSpecialAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SPECIAL_ASC',
  VehicleStocksStddevPopulationSpecialDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SPECIAL_DESC',
  VehicleStocksStddevPopulationDriveTrainAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DRIVE_TRAIN_ASC',
  VehicleStocksStddevPopulationDriveTrainDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DRIVE_TRAIN_DESC',
  VehicleStocksStddevPopulationFuelTypeAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_FUEL_TYPE_ASC',
  VehicleStocksStddevPopulationFuelTypeDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_FUEL_TYPE_DESC',
  VehicleStocksStddevPopulationMainImageAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MAIN_IMAGE_ASC',
  VehicleStocksStddevPopulationMainImageDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MAIN_IMAGE_DESC',
  VehicleStocksStddevPopulationImagesAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_IMAGES_ASC',
  VehicleStocksStddevPopulationImagesDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_IMAGES_DESC',
  VehicleStocksStddevPopulationVideosAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_VIDEOS_ASC',
  VehicleStocksStddevPopulationVideosDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_VIDEOS_DESC',
  VehicleStocksStddevPopulationFeaturesAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_FEATURES_ASC',
  VehicleStocksStddevPopulationFeaturesDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_FEATURES_DESC',
  VehicleStocksStddevPopulationStockStatusAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_STOCK_STATUS_ASC',
  VehicleStocksStddevPopulationStockStatusDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_STOCK_STATUS_DESC',
  VehicleStocksStddevPopulationPriceAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_PRICE_ASC',
  VehicleStocksStddevPopulationPriceDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_PRICE_DESC',
  VehicleStocksStddevPopulationCostAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COST_ASC',
  VehicleStocksStddevPopulationCostDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COST_DESC',
  VehicleStocksStddevPopulationEntryDateAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ENTRY_DATE_ASC',
  VehicleStocksStddevPopulationEntryDateDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ENTRY_DATE_DESC',
  VehicleStocksStddevPopulationLastChangeDateAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_LAST_CHANGE_DATE_ASC',
  VehicleStocksStddevPopulationLastChangeDateDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_LAST_CHANGE_DATE_DESC',
  VehicleStocksStddevPopulationCreatedAtAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CREATED_AT_ASC',
  VehicleStocksStddevPopulationCreatedAtDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CREATED_AT_DESC',
  VehicleStocksStddevPopulationUpdatedAtAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_UPDATED_AT_ASC',
  VehicleStocksStddevPopulationUpdatedAtDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_UPDATED_AT_DESC',
  VehicleStocksStddevPopulationSearchTextAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  VehicleStocksStddevPopulationSearchTextDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  VehicleStocksVarianceSampleIdAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ID_ASC',
  VehicleStocksVarianceSampleIdDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ID_DESC',
  VehicleStocksVarianceSampleCompanyIdAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  VehicleStocksVarianceSampleCompanyIdDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  VehicleStocksVarianceSampleCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksVarianceSampleCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksVarianceSampleProviderIdAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_PROVIDER_ID_ASC',
  VehicleStocksVarianceSampleProviderIdDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_PROVIDER_ID_DESC',
  VehicleStocksVarianceSampleVinAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_VIN_ASC',
  VehicleStocksVarianceSampleVinDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_VIN_DESC',
  VehicleStocksVarianceSampleStockNumberAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_STOCK_NUMBER_ASC',
  VehicleStocksVarianceSampleStockNumberDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_STOCK_NUMBER_DESC',
  VehicleStocksVarianceSampleYearAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_YEAR_ASC',
  VehicleStocksVarianceSampleYearDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_YEAR_DESC',
  VehicleStocksVarianceSampleMakeAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MAKE_ASC',
  VehicleStocksVarianceSampleMakeDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MAKE_DESC',
  VehicleStocksVarianceSampleModelAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MODEL_ASC',
  VehicleStocksVarianceSampleModelDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MODEL_DESC',
  VehicleStocksVarianceSampleTrimAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_TRIM_ASC',
  VehicleStocksVarianceSampleTrimDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_TRIM_DESC',
  VehicleStocksVarianceSampleBodyStyleAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_BODY_STYLE_ASC',
  VehicleStocksVarianceSampleBodyStyleDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_BODY_STYLE_DESC',
  VehicleStocksVarianceSampleExteriorColourAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_EXTERIOR_COLOUR_ASC',
  VehicleStocksVarianceSampleExteriorColourDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_EXTERIOR_COLOUR_DESC',
  VehicleStocksVarianceSampleInteriorColourAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_INTERIOR_COLOUR_ASC',
  VehicleStocksVarianceSampleInteriorColourDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_INTERIOR_COLOUR_DESC',
  VehicleStocksVarianceSampleMileageAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MILEAGE_ASC',
  VehicleStocksVarianceSampleMileageDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MILEAGE_DESC',
  VehicleStocksVarianceSampleDoorsAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DOORS_ASC',
  VehicleStocksVarianceSampleDoorsDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DOORS_DESC',
  VehicleStocksVarianceSampleSeatsAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SEATS_ASC',
  VehicleStocksVarianceSampleSeatsDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SEATS_DESC',
  VehicleStocksVarianceSampleEngineCapacityAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ENGINE_CAPACITY_ASC',
  VehicleStocksVarianceSampleEngineCapacityDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ENGINE_CAPACITY_DESC',
  VehicleStocksVarianceSampleTransmissionAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_TRANSMISSION_ASC',
  VehicleStocksVarianceSampleTransmissionDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_TRANSMISSION_DESC',
  VehicleStocksVarianceSampleCylindersAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CYLINDERS_ASC',
  VehicleStocksVarianceSampleCylindersDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CYLINDERS_DESC',
  VehicleStocksVarianceSampleDescriptionAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  VehicleStocksVarianceSampleDescriptionDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  VehicleStocksVarianceSampleWarrantyTextAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_WARRANTY_TEXT_ASC',
  VehicleStocksVarianceSampleWarrantyTextDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_WARRANTY_TEXT_DESC',
  VehicleStocksVarianceSampleCarProofAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CAR_PROOF_ASC',
  VehicleStocksVarianceSampleCarProofDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CAR_PROOF_DESC',
  VehicleStocksVarianceSampleIsNewAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_IS_NEW_ASC',
  VehicleStocksVarianceSampleIsNewDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_IS_NEW_DESC',
  VehicleStocksVarianceSampleCertifiedAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CERTIFIED_ASC',
  VehicleStocksVarianceSampleCertifiedDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CERTIFIED_DESC',
  VehicleStocksVarianceSampleSpecialAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SPECIAL_ASC',
  VehicleStocksVarianceSampleSpecialDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SPECIAL_DESC',
  VehicleStocksVarianceSampleDriveTrainAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DRIVE_TRAIN_ASC',
  VehicleStocksVarianceSampleDriveTrainDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DRIVE_TRAIN_DESC',
  VehicleStocksVarianceSampleFuelTypeAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_FUEL_TYPE_ASC',
  VehicleStocksVarianceSampleFuelTypeDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_FUEL_TYPE_DESC',
  VehicleStocksVarianceSampleMainImageAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MAIN_IMAGE_ASC',
  VehicleStocksVarianceSampleMainImageDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MAIN_IMAGE_DESC',
  VehicleStocksVarianceSampleImagesAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_IMAGES_ASC',
  VehicleStocksVarianceSampleImagesDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_IMAGES_DESC',
  VehicleStocksVarianceSampleVideosAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_VIDEOS_ASC',
  VehicleStocksVarianceSampleVideosDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_VIDEOS_DESC',
  VehicleStocksVarianceSampleFeaturesAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_FEATURES_ASC',
  VehicleStocksVarianceSampleFeaturesDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_FEATURES_DESC',
  VehicleStocksVarianceSampleStockStatusAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_STOCK_STATUS_ASC',
  VehicleStocksVarianceSampleStockStatusDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_STOCK_STATUS_DESC',
  VehicleStocksVarianceSamplePriceAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_PRICE_ASC',
  VehicleStocksVarianceSamplePriceDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_PRICE_DESC',
  VehicleStocksVarianceSampleCostAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COST_ASC',
  VehicleStocksVarianceSampleCostDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COST_DESC',
  VehicleStocksVarianceSampleEntryDateAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ENTRY_DATE_ASC',
  VehicleStocksVarianceSampleEntryDateDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ENTRY_DATE_DESC',
  VehicleStocksVarianceSampleLastChangeDateAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_LAST_CHANGE_DATE_ASC',
  VehicleStocksVarianceSampleLastChangeDateDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_LAST_CHANGE_DATE_DESC',
  VehicleStocksVarianceSampleCreatedAtAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  VehicleStocksVarianceSampleCreatedAtDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  VehicleStocksVarianceSampleUpdatedAtAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  VehicleStocksVarianceSampleUpdatedAtDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  VehicleStocksVarianceSampleSearchTextAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  VehicleStocksVarianceSampleSearchTextDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  VehicleStocksVariancePopulationIdAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ID_ASC',
  VehicleStocksVariancePopulationIdDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ID_DESC',
  VehicleStocksVariancePopulationCompanyIdAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  VehicleStocksVariancePopulationCompanyIdDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  VehicleStocksVariancePopulationCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksVariancePopulationCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksVariancePopulationProviderIdAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_PROVIDER_ID_ASC',
  VehicleStocksVariancePopulationProviderIdDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_PROVIDER_ID_DESC',
  VehicleStocksVariancePopulationVinAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_VIN_ASC',
  VehicleStocksVariancePopulationVinDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_VIN_DESC',
  VehicleStocksVariancePopulationStockNumberAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_STOCK_NUMBER_ASC',
  VehicleStocksVariancePopulationStockNumberDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_STOCK_NUMBER_DESC',
  VehicleStocksVariancePopulationYearAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_YEAR_ASC',
  VehicleStocksVariancePopulationYearDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_YEAR_DESC',
  VehicleStocksVariancePopulationMakeAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MAKE_ASC',
  VehicleStocksVariancePopulationMakeDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MAKE_DESC',
  VehicleStocksVariancePopulationModelAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MODEL_ASC',
  VehicleStocksVariancePopulationModelDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MODEL_DESC',
  VehicleStocksVariancePopulationTrimAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_TRIM_ASC',
  VehicleStocksVariancePopulationTrimDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_TRIM_DESC',
  VehicleStocksVariancePopulationBodyStyleAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_BODY_STYLE_ASC',
  VehicleStocksVariancePopulationBodyStyleDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_BODY_STYLE_DESC',
  VehicleStocksVariancePopulationExteriorColourAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_EXTERIOR_COLOUR_ASC',
  VehicleStocksVariancePopulationExteriorColourDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_EXTERIOR_COLOUR_DESC',
  VehicleStocksVariancePopulationInteriorColourAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_INTERIOR_COLOUR_ASC',
  VehicleStocksVariancePopulationInteriorColourDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_INTERIOR_COLOUR_DESC',
  VehicleStocksVariancePopulationMileageAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MILEAGE_ASC',
  VehicleStocksVariancePopulationMileageDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MILEAGE_DESC',
  VehicleStocksVariancePopulationDoorsAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DOORS_ASC',
  VehicleStocksVariancePopulationDoorsDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DOORS_DESC',
  VehicleStocksVariancePopulationSeatsAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SEATS_ASC',
  VehicleStocksVariancePopulationSeatsDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SEATS_DESC',
  VehicleStocksVariancePopulationEngineCapacityAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ENGINE_CAPACITY_ASC',
  VehicleStocksVariancePopulationEngineCapacityDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ENGINE_CAPACITY_DESC',
  VehicleStocksVariancePopulationTransmissionAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_TRANSMISSION_ASC',
  VehicleStocksVariancePopulationTransmissionDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_TRANSMISSION_DESC',
  VehicleStocksVariancePopulationCylindersAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CYLINDERS_ASC',
  VehicleStocksVariancePopulationCylindersDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CYLINDERS_DESC',
  VehicleStocksVariancePopulationDescriptionAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DESCRIPTION_ASC',
  VehicleStocksVariancePopulationDescriptionDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DESCRIPTION_DESC',
  VehicleStocksVariancePopulationWarrantyTextAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_WARRANTY_TEXT_ASC',
  VehicleStocksVariancePopulationWarrantyTextDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_WARRANTY_TEXT_DESC',
  VehicleStocksVariancePopulationCarProofAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CAR_PROOF_ASC',
  VehicleStocksVariancePopulationCarProofDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CAR_PROOF_DESC',
  VehicleStocksVariancePopulationIsNewAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_IS_NEW_ASC',
  VehicleStocksVariancePopulationIsNewDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_IS_NEW_DESC',
  VehicleStocksVariancePopulationCertifiedAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CERTIFIED_ASC',
  VehicleStocksVariancePopulationCertifiedDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CERTIFIED_DESC',
  VehicleStocksVariancePopulationSpecialAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SPECIAL_ASC',
  VehicleStocksVariancePopulationSpecialDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SPECIAL_DESC',
  VehicleStocksVariancePopulationDriveTrainAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DRIVE_TRAIN_ASC',
  VehicleStocksVariancePopulationDriveTrainDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DRIVE_TRAIN_DESC',
  VehicleStocksVariancePopulationFuelTypeAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_FUEL_TYPE_ASC',
  VehicleStocksVariancePopulationFuelTypeDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_FUEL_TYPE_DESC',
  VehicleStocksVariancePopulationMainImageAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MAIN_IMAGE_ASC',
  VehicleStocksVariancePopulationMainImageDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MAIN_IMAGE_DESC',
  VehicleStocksVariancePopulationImagesAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_IMAGES_ASC',
  VehicleStocksVariancePopulationImagesDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_IMAGES_DESC',
  VehicleStocksVariancePopulationVideosAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_VIDEOS_ASC',
  VehicleStocksVariancePopulationVideosDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_VIDEOS_DESC',
  VehicleStocksVariancePopulationFeaturesAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_FEATURES_ASC',
  VehicleStocksVariancePopulationFeaturesDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_FEATURES_DESC',
  VehicleStocksVariancePopulationStockStatusAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_STOCK_STATUS_ASC',
  VehicleStocksVariancePopulationStockStatusDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_STOCK_STATUS_DESC',
  VehicleStocksVariancePopulationPriceAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_PRICE_ASC',
  VehicleStocksVariancePopulationPriceDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_PRICE_DESC',
  VehicleStocksVariancePopulationCostAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COST_ASC',
  VehicleStocksVariancePopulationCostDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COST_DESC',
  VehicleStocksVariancePopulationEntryDateAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ENTRY_DATE_ASC',
  VehicleStocksVariancePopulationEntryDateDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ENTRY_DATE_DESC',
  VehicleStocksVariancePopulationLastChangeDateAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_LAST_CHANGE_DATE_ASC',
  VehicleStocksVariancePopulationLastChangeDateDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_LAST_CHANGE_DATE_DESC',
  VehicleStocksVariancePopulationCreatedAtAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CREATED_AT_ASC',
  VehicleStocksVariancePopulationCreatedAtDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CREATED_AT_DESC',
  VehicleStocksVariancePopulationUpdatedAtAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  VehicleStocksVariancePopulationUpdatedAtDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  VehicleStocksVariancePopulationSearchTextAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  VehicleStocksVariancePopulationSearchTextDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  CompanyUsersCountAsc = 'COMPANY_USERS_COUNT_ASC',
  CompanyUsersCountDesc = 'COMPANY_USERS_COUNT_DESC',
  CompanyUsersSumIdAsc = 'COMPANY_USERS_SUM_ID_ASC',
  CompanyUsersSumIdDesc = 'COMPANY_USERS_SUM_ID_DESC',
  CompanyUsersSumCompanyIdAsc = 'COMPANY_USERS_SUM_COMPANY_ID_ASC',
  CompanyUsersSumCompanyIdDesc = 'COMPANY_USERS_SUM_COMPANY_ID_DESC',
  CompanyUsersSumGroupIdAsc = 'COMPANY_USERS_SUM_GROUP_ID_ASC',
  CompanyUsersSumGroupIdDesc = 'COMPANY_USERS_SUM_GROUP_ID_DESC',
  CompanyUsersSumUserIdAsc = 'COMPANY_USERS_SUM_USER_ID_ASC',
  CompanyUsersSumUserIdDesc = 'COMPANY_USERS_SUM_USER_ID_DESC',
  CompanyUsersSumRoleAsc = 'COMPANY_USERS_SUM_ROLE_ASC',
  CompanyUsersSumRoleDesc = 'COMPANY_USERS_SUM_ROLE_DESC',
  CompanyUsersSumCreatedAtAsc = 'COMPANY_USERS_SUM_CREATED_AT_ASC',
  CompanyUsersSumCreatedAtDesc = 'COMPANY_USERS_SUM_CREATED_AT_DESC',
  CompanyUsersSumUpdatedAtAsc = 'COMPANY_USERS_SUM_UPDATED_AT_ASC',
  CompanyUsersSumUpdatedAtDesc = 'COMPANY_USERS_SUM_UPDATED_AT_DESC',
  CompanyUsersDistinctCountIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_ID_ASC',
  CompanyUsersDistinctCountIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_ID_DESC',
  CompanyUsersDistinctCountCompanyIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyUsersDistinctCountCompanyIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyUsersDistinctCountGroupIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyUsersDistinctCountGroupIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyUsersDistinctCountUserIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_USER_ID_ASC',
  CompanyUsersDistinctCountUserIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_USER_ID_DESC',
  CompanyUsersDistinctCountRoleAsc = 'COMPANY_USERS_DISTINCT_COUNT_ROLE_ASC',
  CompanyUsersDistinctCountRoleDesc = 'COMPANY_USERS_DISTINCT_COUNT_ROLE_DESC',
  CompanyUsersDistinctCountCreatedAtAsc = 'COMPANY_USERS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyUsersDistinctCountCreatedAtDesc = 'COMPANY_USERS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyUsersDistinctCountUpdatedAtAsc = 'COMPANY_USERS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyUsersDistinctCountUpdatedAtDesc = 'COMPANY_USERS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyUsersMinIdAsc = 'COMPANY_USERS_MIN_ID_ASC',
  CompanyUsersMinIdDesc = 'COMPANY_USERS_MIN_ID_DESC',
  CompanyUsersMinCompanyIdAsc = 'COMPANY_USERS_MIN_COMPANY_ID_ASC',
  CompanyUsersMinCompanyIdDesc = 'COMPANY_USERS_MIN_COMPANY_ID_DESC',
  CompanyUsersMinGroupIdAsc = 'COMPANY_USERS_MIN_GROUP_ID_ASC',
  CompanyUsersMinGroupIdDesc = 'COMPANY_USERS_MIN_GROUP_ID_DESC',
  CompanyUsersMinUserIdAsc = 'COMPANY_USERS_MIN_USER_ID_ASC',
  CompanyUsersMinUserIdDesc = 'COMPANY_USERS_MIN_USER_ID_DESC',
  CompanyUsersMinRoleAsc = 'COMPANY_USERS_MIN_ROLE_ASC',
  CompanyUsersMinRoleDesc = 'COMPANY_USERS_MIN_ROLE_DESC',
  CompanyUsersMinCreatedAtAsc = 'COMPANY_USERS_MIN_CREATED_AT_ASC',
  CompanyUsersMinCreatedAtDesc = 'COMPANY_USERS_MIN_CREATED_AT_DESC',
  CompanyUsersMinUpdatedAtAsc = 'COMPANY_USERS_MIN_UPDATED_AT_ASC',
  CompanyUsersMinUpdatedAtDesc = 'COMPANY_USERS_MIN_UPDATED_AT_DESC',
  CompanyUsersMaxIdAsc = 'COMPANY_USERS_MAX_ID_ASC',
  CompanyUsersMaxIdDesc = 'COMPANY_USERS_MAX_ID_DESC',
  CompanyUsersMaxCompanyIdAsc = 'COMPANY_USERS_MAX_COMPANY_ID_ASC',
  CompanyUsersMaxCompanyIdDesc = 'COMPANY_USERS_MAX_COMPANY_ID_DESC',
  CompanyUsersMaxGroupIdAsc = 'COMPANY_USERS_MAX_GROUP_ID_ASC',
  CompanyUsersMaxGroupIdDesc = 'COMPANY_USERS_MAX_GROUP_ID_DESC',
  CompanyUsersMaxUserIdAsc = 'COMPANY_USERS_MAX_USER_ID_ASC',
  CompanyUsersMaxUserIdDesc = 'COMPANY_USERS_MAX_USER_ID_DESC',
  CompanyUsersMaxRoleAsc = 'COMPANY_USERS_MAX_ROLE_ASC',
  CompanyUsersMaxRoleDesc = 'COMPANY_USERS_MAX_ROLE_DESC',
  CompanyUsersMaxCreatedAtAsc = 'COMPANY_USERS_MAX_CREATED_AT_ASC',
  CompanyUsersMaxCreatedAtDesc = 'COMPANY_USERS_MAX_CREATED_AT_DESC',
  CompanyUsersMaxUpdatedAtAsc = 'COMPANY_USERS_MAX_UPDATED_AT_ASC',
  CompanyUsersMaxUpdatedAtDesc = 'COMPANY_USERS_MAX_UPDATED_AT_DESC',
  CompanyUsersAverageIdAsc = 'COMPANY_USERS_AVERAGE_ID_ASC',
  CompanyUsersAverageIdDesc = 'COMPANY_USERS_AVERAGE_ID_DESC',
  CompanyUsersAverageCompanyIdAsc = 'COMPANY_USERS_AVERAGE_COMPANY_ID_ASC',
  CompanyUsersAverageCompanyIdDesc = 'COMPANY_USERS_AVERAGE_COMPANY_ID_DESC',
  CompanyUsersAverageGroupIdAsc = 'COMPANY_USERS_AVERAGE_GROUP_ID_ASC',
  CompanyUsersAverageGroupIdDesc = 'COMPANY_USERS_AVERAGE_GROUP_ID_DESC',
  CompanyUsersAverageUserIdAsc = 'COMPANY_USERS_AVERAGE_USER_ID_ASC',
  CompanyUsersAverageUserIdDesc = 'COMPANY_USERS_AVERAGE_USER_ID_DESC',
  CompanyUsersAverageRoleAsc = 'COMPANY_USERS_AVERAGE_ROLE_ASC',
  CompanyUsersAverageRoleDesc = 'COMPANY_USERS_AVERAGE_ROLE_DESC',
  CompanyUsersAverageCreatedAtAsc = 'COMPANY_USERS_AVERAGE_CREATED_AT_ASC',
  CompanyUsersAverageCreatedAtDesc = 'COMPANY_USERS_AVERAGE_CREATED_AT_DESC',
  CompanyUsersAverageUpdatedAtAsc = 'COMPANY_USERS_AVERAGE_UPDATED_AT_ASC',
  CompanyUsersAverageUpdatedAtDesc = 'COMPANY_USERS_AVERAGE_UPDATED_AT_DESC',
  CompanyUsersStddevSampleIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_ID_ASC',
  CompanyUsersStddevSampleIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_ID_DESC',
  CompanyUsersStddevSampleCompanyIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyUsersStddevSampleCompanyIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyUsersStddevSampleGroupIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyUsersStddevSampleGroupIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyUsersStddevSampleUserIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_USER_ID_ASC',
  CompanyUsersStddevSampleUserIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_USER_ID_DESC',
  CompanyUsersStddevSampleRoleAsc = 'COMPANY_USERS_STDDEV_SAMPLE_ROLE_ASC',
  CompanyUsersStddevSampleRoleDesc = 'COMPANY_USERS_STDDEV_SAMPLE_ROLE_DESC',
  CompanyUsersStddevSampleCreatedAtAsc = 'COMPANY_USERS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyUsersStddevSampleCreatedAtDesc = 'COMPANY_USERS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyUsersStddevSampleUpdatedAtAsc = 'COMPANY_USERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyUsersStddevSampleUpdatedAtDesc = 'COMPANY_USERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyUsersStddevPopulationIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_ID_ASC',
  CompanyUsersStddevPopulationIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_ID_DESC',
  CompanyUsersStddevPopulationCompanyIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyUsersStddevPopulationCompanyIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyUsersStddevPopulationGroupIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyUsersStddevPopulationGroupIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyUsersStddevPopulationUserIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_USER_ID_ASC',
  CompanyUsersStddevPopulationUserIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_USER_ID_DESC',
  CompanyUsersStddevPopulationRoleAsc = 'COMPANY_USERS_STDDEV_POPULATION_ROLE_ASC',
  CompanyUsersStddevPopulationRoleDesc = 'COMPANY_USERS_STDDEV_POPULATION_ROLE_DESC',
  CompanyUsersStddevPopulationCreatedAtAsc = 'COMPANY_USERS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyUsersStddevPopulationCreatedAtDesc = 'COMPANY_USERS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyUsersStddevPopulationUpdatedAtAsc = 'COMPANY_USERS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyUsersStddevPopulationUpdatedAtDesc = 'COMPANY_USERS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyUsersVarianceSampleIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_ID_ASC',
  CompanyUsersVarianceSampleIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_ID_DESC',
  CompanyUsersVarianceSampleCompanyIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyUsersVarianceSampleCompanyIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyUsersVarianceSampleGroupIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyUsersVarianceSampleGroupIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyUsersVarianceSampleUserIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_USER_ID_ASC',
  CompanyUsersVarianceSampleUserIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_USER_ID_DESC',
  CompanyUsersVarianceSampleRoleAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_ROLE_ASC',
  CompanyUsersVarianceSampleRoleDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_ROLE_DESC',
  CompanyUsersVarianceSampleCreatedAtAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyUsersVarianceSampleCreatedAtDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyUsersVarianceSampleUpdatedAtAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyUsersVarianceSampleUpdatedAtDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyUsersVariancePopulationIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_ID_ASC',
  CompanyUsersVariancePopulationIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_ID_DESC',
  CompanyUsersVariancePopulationCompanyIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyUsersVariancePopulationCompanyIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyUsersVariancePopulationGroupIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyUsersVariancePopulationGroupIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyUsersVariancePopulationUserIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_USER_ID_ASC',
  CompanyUsersVariancePopulationUserIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_USER_ID_DESC',
  CompanyUsersVariancePopulationRoleAsc = 'COMPANY_USERS_VARIANCE_POPULATION_ROLE_ASC',
  CompanyUsersVariancePopulationRoleDesc = 'COMPANY_USERS_VARIANCE_POPULATION_ROLE_DESC',
  CompanyUsersVariancePopulationCreatedAtAsc = 'COMPANY_USERS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyUsersVariancePopulationCreatedAtDesc = 'COMPANY_USERS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyUsersVariancePopulationUpdatedAtAsc = 'COMPANY_USERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyUsersVariancePopulationUpdatedAtDesc = 'COMPANY_USERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  MediasCountAsc = 'MEDIAS_COUNT_ASC',
  MediasCountDesc = 'MEDIAS_COUNT_DESC',
  MediasSumIdAsc = 'MEDIAS_SUM_ID_ASC',
  MediasSumIdDesc = 'MEDIAS_SUM_ID_DESC',
  MediasSumCompanyIdAsc = 'MEDIAS_SUM_COMPANY_ID_ASC',
  MediasSumCompanyIdDesc = 'MEDIAS_SUM_COMPANY_ID_DESC',
  MediasSumLabelAsc = 'MEDIAS_SUM_LABEL_ASC',
  MediasSumLabelDesc = 'MEDIAS_SUM_LABEL_DESC',
  MediasSumTypeAsc = 'MEDIAS_SUM_TYPE_ASC',
  MediasSumTypeDesc = 'MEDIAS_SUM_TYPE_DESC',
  MediasSumUploaderAsc = 'MEDIAS_SUM_UPLOADER_ASC',
  MediasSumUploaderDesc = 'MEDIAS_SUM_UPLOADER_DESC',
  MediasSumS3KeyAsc = 'MEDIAS_SUM_S3_KEY_ASC',
  MediasSumS3KeyDesc = 'MEDIAS_SUM_S3_KEY_DESC',
  MediasSumParentIdAsc = 'MEDIAS_SUM_PARENT_ID_ASC',
  MediasSumParentIdDesc = 'MEDIAS_SUM_PARENT_ID_DESC',
  MediasSumParentTypeAsc = 'MEDIAS_SUM_PARENT_TYPE_ASC',
  MediasSumParentTypeDesc = 'MEDIAS_SUM_PARENT_TYPE_DESC',
  MediasSumMetaAsc = 'MEDIAS_SUM_META_ASC',
  MediasSumMetaDesc = 'MEDIAS_SUM_META_DESC',
  MediasSumContentTypeAsc = 'MEDIAS_SUM_CONTENT_TYPE_ASC',
  MediasSumContentTypeDesc = 'MEDIAS_SUM_CONTENT_TYPE_DESC',
  MediasSumUploadCompletedAtAsc = 'MEDIAS_SUM_UPLOAD_COMPLETED_AT_ASC',
  MediasSumUploadCompletedAtDesc = 'MEDIAS_SUM_UPLOAD_COMPLETED_AT_DESC',
  MediasSumCreatedAtAsc = 'MEDIAS_SUM_CREATED_AT_ASC',
  MediasSumCreatedAtDesc = 'MEDIAS_SUM_CREATED_AT_DESC',
  MediasSumUpdatedAtAsc = 'MEDIAS_SUM_UPDATED_AT_ASC',
  MediasSumUpdatedAtDesc = 'MEDIAS_SUM_UPDATED_AT_DESC',
  MediasSumDeletedAtAsc = 'MEDIAS_SUM_DELETED_AT_ASC',
  MediasSumDeletedAtDesc = 'MEDIAS_SUM_DELETED_AT_DESC',
  MediasSumFileNameAsc = 'MEDIAS_SUM_FILE_NAME_ASC',
  MediasSumFileNameDesc = 'MEDIAS_SUM_FILE_NAME_DESC',
  MediasDistinctCountIdAsc = 'MEDIAS_DISTINCT_COUNT_ID_ASC',
  MediasDistinctCountIdDesc = 'MEDIAS_DISTINCT_COUNT_ID_DESC',
  MediasDistinctCountCompanyIdAsc = 'MEDIAS_DISTINCT_COUNT_COMPANY_ID_ASC',
  MediasDistinctCountCompanyIdDesc = 'MEDIAS_DISTINCT_COUNT_COMPANY_ID_DESC',
  MediasDistinctCountLabelAsc = 'MEDIAS_DISTINCT_COUNT_LABEL_ASC',
  MediasDistinctCountLabelDesc = 'MEDIAS_DISTINCT_COUNT_LABEL_DESC',
  MediasDistinctCountTypeAsc = 'MEDIAS_DISTINCT_COUNT_TYPE_ASC',
  MediasDistinctCountTypeDesc = 'MEDIAS_DISTINCT_COUNT_TYPE_DESC',
  MediasDistinctCountUploaderAsc = 'MEDIAS_DISTINCT_COUNT_UPLOADER_ASC',
  MediasDistinctCountUploaderDesc = 'MEDIAS_DISTINCT_COUNT_UPLOADER_DESC',
  MediasDistinctCountS3KeyAsc = 'MEDIAS_DISTINCT_COUNT_S3_KEY_ASC',
  MediasDistinctCountS3KeyDesc = 'MEDIAS_DISTINCT_COUNT_S3_KEY_DESC',
  MediasDistinctCountParentIdAsc = 'MEDIAS_DISTINCT_COUNT_PARENT_ID_ASC',
  MediasDistinctCountParentIdDesc = 'MEDIAS_DISTINCT_COUNT_PARENT_ID_DESC',
  MediasDistinctCountParentTypeAsc = 'MEDIAS_DISTINCT_COUNT_PARENT_TYPE_ASC',
  MediasDistinctCountParentTypeDesc = 'MEDIAS_DISTINCT_COUNT_PARENT_TYPE_DESC',
  MediasDistinctCountMetaAsc = 'MEDIAS_DISTINCT_COUNT_META_ASC',
  MediasDistinctCountMetaDesc = 'MEDIAS_DISTINCT_COUNT_META_DESC',
  MediasDistinctCountContentTypeAsc = 'MEDIAS_DISTINCT_COUNT_CONTENT_TYPE_ASC',
  MediasDistinctCountContentTypeDesc = 'MEDIAS_DISTINCT_COUNT_CONTENT_TYPE_DESC',
  MediasDistinctCountUploadCompletedAtAsc = 'MEDIAS_DISTINCT_COUNT_UPLOAD_COMPLETED_AT_ASC',
  MediasDistinctCountUploadCompletedAtDesc = 'MEDIAS_DISTINCT_COUNT_UPLOAD_COMPLETED_AT_DESC',
  MediasDistinctCountCreatedAtAsc = 'MEDIAS_DISTINCT_COUNT_CREATED_AT_ASC',
  MediasDistinctCountCreatedAtDesc = 'MEDIAS_DISTINCT_COUNT_CREATED_AT_DESC',
  MediasDistinctCountUpdatedAtAsc = 'MEDIAS_DISTINCT_COUNT_UPDATED_AT_ASC',
  MediasDistinctCountUpdatedAtDesc = 'MEDIAS_DISTINCT_COUNT_UPDATED_AT_DESC',
  MediasDistinctCountDeletedAtAsc = 'MEDIAS_DISTINCT_COUNT_DELETED_AT_ASC',
  MediasDistinctCountDeletedAtDesc = 'MEDIAS_DISTINCT_COUNT_DELETED_AT_DESC',
  MediasDistinctCountFileNameAsc = 'MEDIAS_DISTINCT_COUNT_FILE_NAME_ASC',
  MediasDistinctCountFileNameDesc = 'MEDIAS_DISTINCT_COUNT_FILE_NAME_DESC',
  MediasMinIdAsc = 'MEDIAS_MIN_ID_ASC',
  MediasMinIdDesc = 'MEDIAS_MIN_ID_DESC',
  MediasMinCompanyIdAsc = 'MEDIAS_MIN_COMPANY_ID_ASC',
  MediasMinCompanyIdDesc = 'MEDIAS_MIN_COMPANY_ID_DESC',
  MediasMinLabelAsc = 'MEDIAS_MIN_LABEL_ASC',
  MediasMinLabelDesc = 'MEDIAS_MIN_LABEL_DESC',
  MediasMinTypeAsc = 'MEDIAS_MIN_TYPE_ASC',
  MediasMinTypeDesc = 'MEDIAS_MIN_TYPE_DESC',
  MediasMinUploaderAsc = 'MEDIAS_MIN_UPLOADER_ASC',
  MediasMinUploaderDesc = 'MEDIAS_MIN_UPLOADER_DESC',
  MediasMinS3KeyAsc = 'MEDIAS_MIN_S3_KEY_ASC',
  MediasMinS3KeyDesc = 'MEDIAS_MIN_S3_KEY_DESC',
  MediasMinParentIdAsc = 'MEDIAS_MIN_PARENT_ID_ASC',
  MediasMinParentIdDesc = 'MEDIAS_MIN_PARENT_ID_DESC',
  MediasMinParentTypeAsc = 'MEDIAS_MIN_PARENT_TYPE_ASC',
  MediasMinParentTypeDesc = 'MEDIAS_MIN_PARENT_TYPE_DESC',
  MediasMinMetaAsc = 'MEDIAS_MIN_META_ASC',
  MediasMinMetaDesc = 'MEDIAS_MIN_META_DESC',
  MediasMinContentTypeAsc = 'MEDIAS_MIN_CONTENT_TYPE_ASC',
  MediasMinContentTypeDesc = 'MEDIAS_MIN_CONTENT_TYPE_DESC',
  MediasMinUploadCompletedAtAsc = 'MEDIAS_MIN_UPLOAD_COMPLETED_AT_ASC',
  MediasMinUploadCompletedAtDesc = 'MEDIAS_MIN_UPLOAD_COMPLETED_AT_DESC',
  MediasMinCreatedAtAsc = 'MEDIAS_MIN_CREATED_AT_ASC',
  MediasMinCreatedAtDesc = 'MEDIAS_MIN_CREATED_AT_DESC',
  MediasMinUpdatedAtAsc = 'MEDIAS_MIN_UPDATED_AT_ASC',
  MediasMinUpdatedAtDesc = 'MEDIAS_MIN_UPDATED_AT_DESC',
  MediasMinDeletedAtAsc = 'MEDIAS_MIN_DELETED_AT_ASC',
  MediasMinDeletedAtDesc = 'MEDIAS_MIN_DELETED_AT_DESC',
  MediasMinFileNameAsc = 'MEDIAS_MIN_FILE_NAME_ASC',
  MediasMinFileNameDesc = 'MEDIAS_MIN_FILE_NAME_DESC',
  MediasMaxIdAsc = 'MEDIAS_MAX_ID_ASC',
  MediasMaxIdDesc = 'MEDIAS_MAX_ID_DESC',
  MediasMaxCompanyIdAsc = 'MEDIAS_MAX_COMPANY_ID_ASC',
  MediasMaxCompanyIdDesc = 'MEDIAS_MAX_COMPANY_ID_DESC',
  MediasMaxLabelAsc = 'MEDIAS_MAX_LABEL_ASC',
  MediasMaxLabelDesc = 'MEDIAS_MAX_LABEL_DESC',
  MediasMaxTypeAsc = 'MEDIAS_MAX_TYPE_ASC',
  MediasMaxTypeDesc = 'MEDIAS_MAX_TYPE_DESC',
  MediasMaxUploaderAsc = 'MEDIAS_MAX_UPLOADER_ASC',
  MediasMaxUploaderDesc = 'MEDIAS_MAX_UPLOADER_DESC',
  MediasMaxS3KeyAsc = 'MEDIAS_MAX_S3_KEY_ASC',
  MediasMaxS3KeyDesc = 'MEDIAS_MAX_S3_KEY_DESC',
  MediasMaxParentIdAsc = 'MEDIAS_MAX_PARENT_ID_ASC',
  MediasMaxParentIdDesc = 'MEDIAS_MAX_PARENT_ID_DESC',
  MediasMaxParentTypeAsc = 'MEDIAS_MAX_PARENT_TYPE_ASC',
  MediasMaxParentTypeDesc = 'MEDIAS_MAX_PARENT_TYPE_DESC',
  MediasMaxMetaAsc = 'MEDIAS_MAX_META_ASC',
  MediasMaxMetaDesc = 'MEDIAS_MAX_META_DESC',
  MediasMaxContentTypeAsc = 'MEDIAS_MAX_CONTENT_TYPE_ASC',
  MediasMaxContentTypeDesc = 'MEDIAS_MAX_CONTENT_TYPE_DESC',
  MediasMaxUploadCompletedAtAsc = 'MEDIAS_MAX_UPLOAD_COMPLETED_AT_ASC',
  MediasMaxUploadCompletedAtDesc = 'MEDIAS_MAX_UPLOAD_COMPLETED_AT_DESC',
  MediasMaxCreatedAtAsc = 'MEDIAS_MAX_CREATED_AT_ASC',
  MediasMaxCreatedAtDesc = 'MEDIAS_MAX_CREATED_AT_DESC',
  MediasMaxUpdatedAtAsc = 'MEDIAS_MAX_UPDATED_AT_ASC',
  MediasMaxUpdatedAtDesc = 'MEDIAS_MAX_UPDATED_AT_DESC',
  MediasMaxDeletedAtAsc = 'MEDIAS_MAX_DELETED_AT_ASC',
  MediasMaxDeletedAtDesc = 'MEDIAS_MAX_DELETED_AT_DESC',
  MediasMaxFileNameAsc = 'MEDIAS_MAX_FILE_NAME_ASC',
  MediasMaxFileNameDesc = 'MEDIAS_MAX_FILE_NAME_DESC',
  MediasAverageIdAsc = 'MEDIAS_AVERAGE_ID_ASC',
  MediasAverageIdDesc = 'MEDIAS_AVERAGE_ID_DESC',
  MediasAverageCompanyIdAsc = 'MEDIAS_AVERAGE_COMPANY_ID_ASC',
  MediasAverageCompanyIdDesc = 'MEDIAS_AVERAGE_COMPANY_ID_DESC',
  MediasAverageLabelAsc = 'MEDIAS_AVERAGE_LABEL_ASC',
  MediasAverageLabelDesc = 'MEDIAS_AVERAGE_LABEL_DESC',
  MediasAverageTypeAsc = 'MEDIAS_AVERAGE_TYPE_ASC',
  MediasAverageTypeDesc = 'MEDIAS_AVERAGE_TYPE_DESC',
  MediasAverageUploaderAsc = 'MEDIAS_AVERAGE_UPLOADER_ASC',
  MediasAverageUploaderDesc = 'MEDIAS_AVERAGE_UPLOADER_DESC',
  MediasAverageS3KeyAsc = 'MEDIAS_AVERAGE_S3_KEY_ASC',
  MediasAverageS3KeyDesc = 'MEDIAS_AVERAGE_S3_KEY_DESC',
  MediasAverageParentIdAsc = 'MEDIAS_AVERAGE_PARENT_ID_ASC',
  MediasAverageParentIdDesc = 'MEDIAS_AVERAGE_PARENT_ID_DESC',
  MediasAverageParentTypeAsc = 'MEDIAS_AVERAGE_PARENT_TYPE_ASC',
  MediasAverageParentTypeDesc = 'MEDIAS_AVERAGE_PARENT_TYPE_DESC',
  MediasAverageMetaAsc = 'MEDIAS_AVERAGE_META_ASC',
  MediasAverageMetaDesc = 'MEDIAS_AVERAGE_META_DESC',
  MediasAverageContentTypeAsc = 'MEDIAS_AVERAGE_CONTENT_TYPE_ASC',
  MediasAverageContentTypeDesc = 'MEDIAS_AVERAGE_CONTENT_TYPE_DESC',
  MediasAverageUploadCompletedAtAsc = 'MEDIAS_AVERAGE_UPLOAD_COMPLETED_AT_ASC',
  MediasAverageUploadCompletedAtDesc = 'MEDIAS_AVERAGE_UPLOAD_COMPLETED_AT_DESC',
  MediasAverageCreatedAtAsc = 'MEDIAS_AVERAGE_CREATED_AT_ASC',
  MediasAverageCreatedAtDesc = 'MEDIAS_AVERAGE_CREATED_AT_DESC',
  MediasAverageUpdatedAtAsc = 'MEDIAS_AVERAGE_UPDATED_AT_ASC',
  MediasAverageUpdatedAtDesc = 'MEDIAS_AVERAGE_UPDATED_AT_DESC',
  MediasAverageDeletedAtAsc = 'MEDIAS_AVERAGE_DELETED_AT_ASC',
  MediasAverageDeletedAtDesc = 'MEDIAS_AVERAGE_DELETED_AT_DESC',
  MediasAverageFileNameAsc = 'MEDIAS_AVERAGE_FILE_NAME_ASC',
  MediasAverageFileNameDesc = 'MEDIAS_AVERAGE_FILE_NAME_DESC',
  MediasStddevSampleIdAsc = 'MEDIAS_STDDEV_SAMPLE_ID_ASC',
  MediasStddevSampleIdDesc = 'MEDIAS_STDDEV_SAMPLE_ID_DESC',
  MediasStddevSampleCompanyIdAsc = 'MEDIAS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  MediasStddevSampleCompanyIdDesc = 'MEDIAS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  MediasStddevSampleLabelAsc = 'MEDIAS_STDDEV_SAMPLE_LABEL_ASC',
  MediasStddevSampleLabelDesc = 'MEDIAS_STDDEV_SAMPLE_LABEL_DESC',
  MediasStddevSampleTypeAsc = 'MEDIAS_STDDEV_SAMPLE_TYPE_ASC',
  MediasStddevSampleTypeDesc = 'MEDIAS_STDDEV_SAMPLE_TYPE_DESC',
  MediasStddevSampleUploaderAsc = 'MEDIAS_STDDEV_SAMPLE_UPLOADER_ASC',
  MediasStddevSampleUploaderDesc = 'MEDIAS_STDDEV_SAMPLE_UPLOADER_DESC',
  MediasStddevSampleS3KeyAsc = 'MEDIAS_STDDEV_SAMPLE_S3_KEY_ASC',
  MediasStddevSampleS3KeyDesc = 'MEDIAS_STDDEV_SAMPLE_S3_KEY_DESC',
  MediasStddevSampleParentIdAsc = 'MEDIAS_STDDEV_SAMPLE_PARENT_ID_ASC',
  MediasStddevSampleParentIdDesc = 'MEDIAS_STDDEV_SAMPLE_PARENT_ID_DESC',
  MediasStddevSampleParentTypeAsc = 'MEDIAS_STDDEV_SAMPLE_PARENT_TYPE_ASC',
  MediasStddevSampleParentTypeDesc = 'MEDIAS_STDDEV_SAMPLE_PARENT_TYPE_DESC',
  MediasStddevSampleMetaAsc = 'MEDIAS_STDDEV_SAMPLE_META_ASC',
  MediasStddevSampleMetaDesc = 'MEDIAS_STDDEV_SAMPLE_META_DESC',
  MediasStddevSampleContentTypeAsc = 'MEDIAS_STDDEV_SAMPLE_CONTENT_TYPE_ASC',
  MediasStddevSampleContentTypeDesc = 'MEDIAS_STDDEV_SAMPLE_CONTENT_TYPE_DESC',
  MediasStddevSampleUploadCompletedAtAsc = 'MEDIAS_STDDEV_SAMPLE_UPLOAD_COMPLETED_AT_ASC',
  MediasStddevSampleUploadCompletedAtDesc = 'MEDIAS_STDDEV_SAMPLE_UPLOAD_COMPLETED_AT_DESC',
  MediasStddevSampleCreatedAtAsc = 'MEDIAS_STDDEV_SAMPLE_CREATED_AT_ASC',
  MediasStddevSampleCreatedAtDesc = 'MEDIAS_STDDEV_SAMPLE_CREATED_AT_DESC',
  MediasStddevSampleUpdatedAtAsc = 'MEDIAS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  MediasStddevSampleUpdatedAtDesc = 'MEDIAS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  MediasStddevSampleDeletedAtAsc = 'MEDIAS_STDDEV_SAMPLE_DELETED_AT_ASC',
  MediasStddevSampleDeletedAtDesc = 'MEDIAS_STDDEV_SAMPLE_DELETED_AT_DESC',
  MediasStddevSampleFileNameAsc = 'MEDIAS_STDDEV_SAMPLE_FILE_NAME_ASC',
  MediasStddevSampleFileNameDesc = 'MEDIAS_STDDEV_SAMPLE_FILE_NAME_DESC',
  MediasStddevPopulationIdAsc = 'MEDIAS_STDDEV_POPULATION_ID_ASC',
  MediasStddevPopulationIdDesc = 'MEDIAS_STDDEV_POPULATION_ID_DESC',
  MediasStddevPopulationCompanyIdAsc = 'MEDIAS_STDDEV_POPULATION_COMPANY_ID_ASC',
  MediasStddevPopulationCompanyIdDesc = 'MEDIAS_STDDEV_POPULATION_COMPANY_ID_DESC',
  MediasStddevPopulationLabelAsc = 'MEDIAS_STDDEV_POPULATION_LABEL_ASC',
  MediasStddevPopulationLabelDesc = 'MEDIAS_STDDEV_POPULATION_LABEL_DESC',
  MediasStddevPopulationTypeAsc = 'MEDIAS_STDDEV_POPULATION_TYPE_ASC',
  MediasStddevPopulationTypeDesc = 'MEDIAS_STDDEV_POPULATION_TYPE_DESC',
  MediasStddevPopulationUploaderAsc = 'MEDIAS_STDDEV_POPULATION_UPLOADER_ASC',
  MediasStddevPopulationUploaderDesc = 'MEDIAS_STDDEV_POPULATION_UPLOADER_DESC',
  MediasStddevPopulationS3KeyAsc = 'MEDIAS_STDDEV_POPULATION_S3_KEY_ASC',
  MediasStddevPopulationS3KeyDesc = 'MEDIAS_STDDEV_POPULATION_S3_KEY_DESC',
  MediasStddevPopulationParentIdAsc = 'MEDIAS_STDDEV_POPULATION_PARENT_ID_ASC',
  MediasStddevPopulationParentIdDesc = 'MEDIAS_STDDEV_POPULATION_PARENT_ID_DESC',
  MediasStddevPopulationParentTypeAsc = 'MEDIAS_STDDEV_POPULATION_PARENT_TYPE_ASC',
  MediasStddevPopulationParentTypeDesc = 'MEDIAS_STDDEV_POPULATION_PARENT_TYPE_DESC',
  MediasStddevPopulationMetaAsc = 'MEDIAS_STDDEV_POPULATION_META_ASC',
  MediasStddevPopulationMetaDesc = 'MEDIAS_STDDEV_POPULATION_META_DESC',
  MediasStddevPopulationContentTypeAsc = 'MEDIAS_STDDEV_POPULATION_CONTENT_TYPE_ASC',
  MediasStddevPopulationContentTypeDesc = 'MEDIAS_STDDEV_POPULATION_CONTENT_TYPE_DESC',
  MediasStddevPopulationUploadCompletedAtAsc = 'MEDIAS_STDDEV_POPULATION_UPLOAD_COMPLETED_AT_ASC',
  MediasStddevPopulationUploadCompletedAtDesc = 'MEDIAS_STDDEV_POPULATION_UPLOAD_COMPLETED_AT_DESC',
  MediasStddevPopulationCreatedAtAsc = 'MEDIAS_STDDEV_POPULATION_CREATED_AT_ASC',
  MediasStddevPopulationCreatedAtDesc = 'MEDIAS_STDDEV_POPULATION_CREATED_AT_DESC',
  MediasStddevPopulationUpdatedAtAsc = 'MEDIAS_STDDEV_POPULATION_UPDATED_AT_ASC',
  MediasStddevPopulationUpdatedAtDesc = 'MEDIAS_STDDEV_POPULATION_UPDATED_AT_DESC',
  MediasStddevPopulationDeletedAtAsc = 'MEDIAS_STDDEV_POPULATION_DELETED_AT_ASC',
  MediasStddevPopulationDeletedAtDesc = 'MEDIAS_STDDEV_POPULATION_DELETED_AT_DESC',
  MediasStddevPopulationFileNameAsc = 'MEDIAS_STDDEV_POPULATION_FILE_NAME_ASC',
  MediasStddevPopulationFileNameDesc = 'MEDIAS_STDDEV_POPULATION_FILE_NAME_DESC',
  MediasVarianceSampleIdAsc = 'MEDIAS_VARIANCE_SAMPLE_ID_ASC',
  MediasVarianceSampleIdDesc = 'MEDIAS_VARIANCE_SAMPLE_ID_DESC',
  MediasVarianceSampleCompanyIdAsc = 'MEDIAS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  MediasVarianceSampleCompanyIdDesc = 'MEDIAS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  MediasVarianceSampleLabelAsc = 'MEDIAS_VARIANCE_SAMPLE_LABEL_ASC',
  MediasVarianceSampleLabelDesc = 'MEDIAS_VARIANCE_SAMPLE_LABEL_DESC',
  MediasVarianceSampleTypeAsc = 'MEDIAS_VARIANCE_SAMPLE_TYPE_ASC',
  MediasVarianceSampleTypeDesc = 'MEDIAS_VARIANCE_SAMPLE_TYPE_DESC',
  MediasVarianceSampleUploaderAsc = 'MEDIAS_VARIANCE_SAMPLE_UPLOADER_ASC',
  MediasVarianceSampleUploaderDesc = 'MEDIAS_VARIANCE_SAMPLE_UPLOADER_DESC',
  MediasVarianceSampleS3KeyAsc = 'MEDIAS_VARIANCE_SAMPLE_S3_KEY_ASC',
  MediasVarianceSampleS3KeyDesc = 'MEDIAS_VARIANCE_SAMPLE_S3_KEY_DESC',
  MediasVarianceSampleParentIdAsc = 'MEDIAS_VARIANCE_SAMPLE_PARENT_ID_ASC',
  MediasVarianceSampleParentIdDesc = 'MEDIAS_VARIANCE_SAMPLE_PARENT_ID_DESC',
  MediasVarianceSampleParentTypeAsc = 'MEDIAS_VARIANCE_SAMPLE_PARENT_TYPE_ASC',
  MediasVarianceSampleParentTypeDesc = 'MEDIAS_VARIANCE_SAMPLE_PARENT_TYPE_DESC',
  MediasVarianceSampleMetaAsc = 'MEDIAS_VARIANCE_SAMPLE_META_ASC',
  MediasVarianceSampleMetaDesc = 'MEDIAS_VARIANCE_SAMPLE_META_DESC',
  MediasVarianceSampleContentTypeAsc = 'MEDIAS_VARIANCE_SAMPLE_CONTENT_TYPE_ASC',
  MediasVarianceSampleContentTypeDesc = 'MEDIAS_VARIANCE_SAMPLE_CONTENT_TYPE_DESC',
  MediasVarianceSampleUploadCompletedAtAsc = 'MEDIAS_VARIANCE_SAMPLE_UPLOAD_COMPLETED_AT_ASC',
  MediasVarianceSampleUploadCompletedAtDesc = 'MEDIAS_VARIANCE_SAMPLE_UPLOAD_COMPLETED_AT_DESC',
  MediasVarianceSampleCreatedAtAsc = 'MEDIAS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  MediasVarianceSampleCreatedAtDesc = 'MEDIAS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  MediasVarianceSampleUpdatedAtAsc = 'MEDIAS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  MediasVarianceSampleUpdatedAtDesc = 'MEDIAS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  MediasVarianceSampleDeletedAtAsc = 'MEDIAS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  MediasVarianceSampleDeletedAtDesc = 'MEDIAS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  MediasVarianceSampleFileNameAsc = 'MEDIAS_VARIANCE_SAMPLE_FILE_NAME_ASC',
  MediasVarianceSampleFileNameDesc = 'MEDIAS_VARIANCE_SAMPLE_FILE_NAME_DESC',
  MediasVariancePopulationIdAsc = 'MEDIAS_VARIANCE_POPULATION_ID_ASC',
  MediasVariancePopulationIdDesc = 'MEDIAS_VARIANCE_POPULATION_ID_DESC',
  MediasVariancePopulationCompanyIdAsc = 'MEDIAS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  MediasVariancePopulationCompanyIdDesc = 'MEDIAS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  MediasVariancePopulationLabelAsc = 'MEDIAS_VARIANCE_POPULATION_LABEL_ASC',
  MediasVariancePopulationLabelDesc = 'MEDIAS_VARIANCE_POPULATION_LABEL_DESC',
  MediasVariancePopulationTypeAsc = 'MEDIAS_VARIANCE_POPULATION_TYPE_ASC',
  MediasVariancePopulationTypeDesc = 'MEDIAS_VARIANCE_POPULATION_TYPE_DESC',
  MediasVariancePopulationUploaderAsc = 'MEDIAS_VARIANCE_POPULATION_UPLOADER_ASC',
  MediasVariancePopulationUploaderDesc = 'MEDIAS_VARIANCE_POPULATION_UPLOADER_DESC',
  MediasVariancePopulationS3KeyAsc = 'MEDIAS_VARIANCE_POPULATION_S3_KEY_ASC',
  MediasVariancePopulationS3KeyDesc = 'MEDIAS_VARIANCE_POPULATION_S3_KEY_DESC',
  MediasVariancePopulationParentIdAsc = 'MEDIAS_VARIANCE_POPULATION_PARENT_ID_ASC',
  MediasVariancePopulationParentIdDesc = 'MEDIAS_VARIANCE_POPULATION_PARENT_ID_DESC',
  MediasVariancePopulationParentTypeAsc = 'MEDIAS_VARIANCE_POPULATION_PARENT_TYPE_ASC',
  MediasVariancePopulationParentTypeDesc = 'MEDIAS_VARIANCE_POPULATION_PARENT_TYPE_DESC',
  MediasVariancePopulationMetaAsc = 'MEDIAS_VARIANCE_POPULATION_META_ASC',
  MediasVariancePopulationMetaDesc = 'MEDIAS_VARIANCE_POPULATION_META_DESC',
  MediasVariancePopulationContentTypeAsc = 'MEDIAS_VARIANCE_POPULATION_CONTENT_TYPE_ASC',
  MediasVariancePopulationContentTypeDesc = 'MEDIAS_VARIANCE_POPULATION_CONTENT_TYPE_DESC',
  MediasVariancePopulationUploadCompletedAtAsc = 'MEDIAS_VARIANCE_POPULATION_UPLOAD_COMPLETED_AT_ASC',
  MediasVariancePopulationUploadCompletedAtDesc = 'MEDIAS_VARIANCE_POPULATION_UPLOAD_COMPLETED_AT_DESC',
  MediasVariancePopulationCreatedAtAsc = 'MEDIAS_VARIANCE_POPULATION_CREATED_AT_ASC',
  MediasVariancePopulationCreatedAtDesc = 'MEDIAS_VARIANCE_POPULATION_CREATED_AT_DESC',
  MediasVariancePopulationUpdatedAtAsc = 'MEDIAS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  MediasVariancePopulationUpdatedAtDesc = 'MEDIAS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  MediasVariancePopulationDeletedAtAsc = 'MEDIAS_VARIANCE_POPULATION_DELETED_AT_ASC',
  MediasVariancePopulationDeletedAtDesc = 'MEDIAS_VARIANCE_POPULATION_DELETED_AT_DESC',
  MediasVariancePopulationFileNameAsc = 'MEDIAS_VARIANCE_POPULATION_FILE_NAME_ASC',
  MediasVariancePopulationFileNameDesc = 'MEDIAS_VARIANCE_POPULATION_FILE_NAME_DESC',
  DistributorRelationshipsByDistributorCompanyIdCountAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_COUNT_ASC',
  DistributorRelationshipsByDistributorCompanyIdCountDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_COUNT_DESC',
  DistributorRelationshipsByDistributorCompanyIdSumIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdSumIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdSumDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdSumDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdSumCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdSumCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdSumCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdSumCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdSumCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_CREATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdSumCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_CREATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdSumUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_UPDATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdSumUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_UPDATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdSumStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_STRATEGY_ASC',
  DistributorRelationshipsByDistributorCompanyIdSumStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_STRATEGY_DESC',
  DistributorRelationshipsByDistributorCompanyIdSumMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsByDistributorCompanyIdSumMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_SUM_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_STRATEGY_ASC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_STRATEGY_DESC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsByDistributorCompanyIdDistinctCountMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_DISTINCT_COUNT_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsByDistributorCompanyIdMinIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdMinIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdMinDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdMinDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdMinCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdMinCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdMinCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdMinCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdMinCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_CREATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdMinCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_CREATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdMinUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_UPDATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdMinUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_UPDATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdMinStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_STRATEGY_ASC',
  DistributorRelationshipsByDistributorCompanyIdMinStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_STRATEGY_DESC',
  DistributorRelationshipsByDistributorCompanyIdMinMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsByDistributorCompanyIdMinMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MIN_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsByDistributorCompanyIdMaxIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdMaxIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdMaxDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdMaxDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdMaxCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdMaxCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdMaxCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdMaxCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdMaxCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_CREATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdMaxCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_CREATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdMaxUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_UPDATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdMaxUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_UPDATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdMaxStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_STRATEGY_ASC',
  DistributorRelationshipsByDistributorCompanyIdMaxStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_STRATEGY_DESC',
  DistributorRelationshipsByDistributorCompanyIdMaxMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsByDistributorCompanyIdMaxMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_MAX_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsByDistributorCompanyIdAverageIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdAverageIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdAverageDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdAverageDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdAverageCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdAverageCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdAverageCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdAverageCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdAverageCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_CREATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdAverageCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_CREATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdAverageUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_UPDATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdAverageUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_UPDATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdAverageStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_STRATEGY_ASC',
  DistributorRelationshipsByDistributorCompanyIdAverageStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_STRATEGY_DESC',
  DistributorRelationshipsByDistributorCompanyIdAverageMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsByDistributorCompanyIdAverageMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_AVERAGE_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_STRATEGY_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_STRATEGY_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevSampleMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_SAMPLE_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_STRATEGY_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_STRATEGY_DESC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsByDistributorCompanyIdStddevPopulationMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_STDDEV_POPULATION_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_STRATEGY_ASC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_STRATEGY_DESC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsByDistributorCompanyIdVarianceSampleMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_SAMPLE_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_STRATEGY_ASC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_STRATEGY_DESC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsByDistributorCompanyIdVariancePopulationMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_BY_DISTRIBUTOR_COMPANY_ID_VARIANCE_POPULATION_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsCountAsc = 'DISTRIBUTOR_RELATIONSHIPS_COUNT_ASC',
  DistributorRelationshipsCountDesc = 'DISTRIBUTOR_RELATIONSHIPS_COUNT_DESC',
  DistributorRelationshipsSumIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_ID_ASC',
  DistributorRelationshipsSumIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_ID_DESC',
  DistributorRelationshipsSumDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsSumDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsSumCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_COMPANY_ID_ASC',
  DistributorRelationshipsSumCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_COMPANY_ID_DESC',
  DistributorRelationshipsSumCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsSumCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsSumCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_CREATED_AT_ASC',
  DistributorRelationshipsSumCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_CREATED_AT_DESC',
  DistributorRelationshipsSumUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_UPDATED_AT_ASC',
  DistributorRelationshipsSumUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_UPDATED_AT_DESC',
  DistributorRelationshipsSumStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_STRATEGY_ASC',
  DistributorRelationshipsSumStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_STRATEGY_DESC',
  DistributorRelationshipsSumMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsSumMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsDistinctCountIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_ID_ASC',
  DistributorRelationshipsDistinctCountIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_ID_DESC',
  DistributorRelationshipsDistinctCountDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsDistinctCountDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsDistinctCountCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_COMPANY_ID_ASC',
  DistributorRelationshipsDistinctCountCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_COMPANY_ID_DESC',
  DistributorRelationshipsDistinctCountCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsDistinctCountCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsDistinctCountCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_CREATED_AT_ASC',
  DistributorRelationshipsDistinctCountCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_CREATED_AT_DESC',
  DistributorRelationshipsDistinctCountUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  DistributorRelationshipsDistinctCountUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  DistributorRelationshipsDistinctCountStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_STRATEGY_ASC',
  DistributorRelationshipsDistinctCountStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_STRATEGY_DESC',
  DistributorRelationshipsDistinctCountMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsDistinctCountMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsMinIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_ID_ASC',
  DistributorRelationshipsMinIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_ID_DESC',
  DistributorRelationshipsMinDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsMinDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsMinCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_COMPANY_ID_ASC',
  DistributorRelationshipsMinCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_COMPANY_ID_DESC',
  DistributorRelationshipsMinCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsMinCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsMinCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_CREATED_AT_ASC',
  DistributorRelationshipsMinCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_CREATED_AT_DESC',
  DistributorRelationshipsMinUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_UPDATED_AT_ASC',
  DistributorRelationshipsMinUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_UPDATED_AT_DESC',
  DistributorRelationshipsMinStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_STRATEGY_ASC',
  DistributorRelationshipsMinStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_STRATEGY_DESC',
  DistributorRelationshipsMinMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsMinMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsMaxIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_ID_ASC',
  DistributorRelationshipsMaxIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_ID_DESC',
  DistributorRelationshipsMaxDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsMaxDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsMaxCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_COMPANY_ID_ASC',
  DistributorRelationshipsMaxCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_COMPANY_ID_DESC',
  DistributorRelationshipsMaxCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsMaxCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsMaxCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_CREATED_AT_ASC',
  DistributorRelationshipsMaxCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_CREATED_AT_DESC',
  DistributorRelationshipsMaxUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_UPDATED_AT_ASC',
  DistributorRelationshipsMaxUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_UPDATED_AT_DESC',
  DistributorRelationshipsMaxStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_STRATEGY_ASC',
  DistributorRelationshipsMaxStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_STRATEGY_DESC',
  DistributorRelationshipsMaxMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsMaxMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsAverageIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_ID_ASC',
  DistributorRelationshipsAverageIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_ID_DESC',
  DistributorRelationshipsAverageDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsAverageDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsAverageCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_COMPANY_ID_ASC',
  DistributorRelationshipsAverageCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_COMPANY_ID_DESC',
  DistributorRelationshipsAverageCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsAverageCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsAverageCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_CREATED_AT_ASC',
  DistributorRelationshipsAverageCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_CREATED_AT_DESC',
  DistributorRelationshipsAverageUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_UPDATED_AT_ASC',
  DistributorRelationshipsAverageUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_UPDATED_AT_DESC',
  DistributorRelationshipsAverageStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_STRATEGY_ASC',
  DistributorRelationshipsAverageStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_STRATEGY_DESC',
  DistributorRelationshipsAverageMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsAverageMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsStddevSampleIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_ID_ASC',
  DistributorRelationshipsStddevSampleIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_ID_DESC',
  DistributorRelationshipsStddevSampleDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsStddevSampleDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsStddevSampleCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  DistributorRelationshipsStddevSampleCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  DistributorRelationshipsStddevSampleCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsStddevSampleCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsStddevSampleCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  DistributorRelationshipsStddevSampleCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  DistributorRelationshipsStddevSampleUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DistributorRelationshipsStddevSampleUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DistributorRelationshipsStddevSampleStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_STRATEGY_ASC',
  DistributorRelationshipsStddevSampleStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_STRATEGY_DESC',
  DistributorRelationshipsStddevSampleMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsStddevSampleMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsStddevPopulationIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_ID_ASC',
  DistributorRelationshipsStddevPopulationIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_ID_DESC',
  DistributorRelationshipsStddevPopulationDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsStddevPopulationDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsStddevPopulationCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_COMPANY_ID_ASC',
  DistributorRelationshipsStddevPopulationCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_COMPANY_ID_DESC',
  DistributorRelationshipsStddevPopulationCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsStddevPopulationCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsStddevPopulationCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_CREATED_AT_ASC',
  DistributorRelationshipsStddevPopulationCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_CREATED_AT_DESC',
  DistributorRelationshipsStddevPopulationUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  DistributorRelationshipsStddevPopulationUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  DistributorRelationshipsStddevPopulationStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_STRATEGY_ASC',
  DistributorRelationshipsStddevPopulationStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_STRATEGY_DESC',
  DistributorRelationshipsStddevPopulationMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsStddevPopulationMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsVarianceSampleIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_ID_ASC',
  DistributorRelationshipsVarianceSampleIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_ID_DESC',
  DistributorRelationshipsVarianceSampleDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsVarianceSampleDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsVarianceSampleCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  DistributorRelationshipsVarianceSampleCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  DistributorRelationshipsVarianceSampleCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsVarianceSampleCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsVarianceSampleCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DistributorRelationshipsVarianceSampleCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DistributorRelationshipsVarianceSampleUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DistributorRelationshipsVarianceSampleUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DistributorRelationshipsVarianceSampleStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_STRATEGY_ASC',
  DistributorRelationshipsVarianceSampleStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_STRATEGY_DESC',
  DistributorRelationshipsVarianceSampleMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsVarianceSampleMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsVariancePopulationIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_ID_ASC',
  DistributorRelationshipsVariancePopulationIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_ID_DESC',
  DistributorRelationshipsVariancePopulationDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsVariancePopulationDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsVariancePopulationCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  DistributorRelationshipsVariancePopulationCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  DistributorRelationshipsVariancePopulationCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsVariancePopulationCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsVariancePopulationCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  DistributorRelationshipsVariancePopulationCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  DistributorRelationshipsVariancePopulationUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DistributorRelationshipsVariancePopulationUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DistributorRelationshipsVariancePopulationStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_STRATEGY_ASC',
  DistributorRelationshipsVariancePopulationStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_STRATEGY_DESC',
  DistributorRelationshipsVariancePopulationMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsVariancePopulationMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_MAX_DISTANCE_KM_DESC',
  DistributedLeadsByFromCompanyIdCountAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_COUNT_ASC',
  DistributedLeadsByFromCompanyIdCountDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_COUNT_DESC',
  DistributedLeadsByFromCompanyIdSumIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_ID_ASC',
  DistributedLeadsByFromCompanyIdSumIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_ID_DESC',
  DistributedLeadsByFromCompanyIdSumLeadIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_LEAD_ID_ASC',
  DistributedLeadsByFromCompanyIdSumLeadIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_LEAD_ID_DESC',
  DistributedLeadsByFromCompanyIdSumFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_FROM_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdSumFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_FROM_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdSumToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_TO_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdSumToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_TO_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdSumOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByFromCompanyIdSumOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByFromCompanyIdSumCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_CREATED_AT_ASC',
  DistributedLeadsByFromCompanyIdSumCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_CREATED_AT_DESC',
  DistributedLeadsByFromCompanyIdSumUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_UPDATED_AT_ASC',
  DistributedLeadsByFromCompanyIdSumUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_SUM_UPDATED_AT_DESC',
  DistributedLeadsByFromCompanyIdDistinctCountIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  DistributedLeadsByFromCompanyIdDistinctCountIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  DistributedLeadsByFromCompanyIdDistinctCountLeadIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_LEAD_ID_ASC',
  DistributedLeadsByFromCompanyIdDistinctCountLeadIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_LEAD_ID_DESC',
  DistributedLeadsByFromCompanyIdDistinctCountFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_FROM_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdDistinctCountFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_FROM_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdDistinctCountToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_TO_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdDistinctCountToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_TO_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdDistinctCountOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByFromCompanyIdDistinctCountOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByFromCompanyIdDistinctCountCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  DistributedLeadsByFromCompanyIdDistinctCountCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  DistributedLeadsByFromCompanyIdDistinctCountUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  DistributedLeadsByFromCompanyIdDistinctCountUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  DistributedLeadsByFromCompanyIdMinIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_ID_ASC',
  DistributedLeadsByFromCompanyIdMinIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_ID_DESC',
  DistributedLeadsByFromCompanyIdMinLeadIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_LEAD_ID_ASC',
  DistributedLeadsByFromCompanyIdMinLeadIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_LEAD_ID_DESC',
  DistributedLeadsByFromCompanyIdMinFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_FROM_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdMinFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_FROM_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdMinToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_TO_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdMinToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_TO_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdMinOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByFromCompanyIdMinOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByFromCompanyIdMinCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_CREATED_AT_ASC',
  DistributedLeadsByFromCompanyIdMinCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_CREATED_AT_DESC',
  DistributedLeadsByFromCompanyIdMinUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_UPDATED_AT_ASC',
  DistributedLeadsByFromCompanyIdMinUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MIN_UPDATED_AT_DESC',
  DistributedLeadsByFromCompanyIdMaxIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_ID_ASC',
  DistributedLeadsByFromCompanyIdMaxIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_ID_DESC',
  DistributedLeadsByFromCompanyIdMaxLeadIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_LEAD_ID_ASC',
  DistributedLeadsByFromCompanyIdMaxLeadIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_LEAD_ID_DESC',
  DistributedLeadsByFromCompanyIdMaxFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_FROM_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdMaxFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_FROM_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdMaxToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_TO_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdMaxToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_TO_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdMaxOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByFromCompanyIdMaxOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByFromCompanyIdMaxCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_CREATED_AT_ASC',
  DistributedLeadsByFromCompanyIdMaxCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_CREATED_AT_DESC',
  DistributedLeadsByFromCompanyIdMaxUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_UPDATED_AT_ASC',
  DistributedLeadsByFromCompanyIdMaxUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_MAX_UPDATED_AT_DESC',
  DistributedLeadsByFromCompanyIdAverageIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_ID_ASC',
  DistributedLeadsByFromCompanyIdAverageIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_ID_DESC',
  DistributedLeadsByFromCompanyIdAverageLeadIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_LEAD_ID_ASC',
  DistributedLeadsByFromCompanyIdAverageLeadIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_LEAD_ID_DESC',
  DistributedLeadsByFromCompanyIdAverageFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_FROM_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdAverageFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_FROM_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdAverageToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_TO_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdAverageToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_TO_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdAverageOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByFromCompanyIdAverageOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByFromCompanyIdAverageCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_CREATED_AT_ASC',
  DistributedLeadsByFromCompanyIdAverageCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_CREATED_AT_DESC',
  DistributedLeadsByFromCompanyIdAverageUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_UPDATED_AT_ASC',
  DistributedLeadsByFromCompanyIdAverageUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_AVERAGE_UPDATED_AT_DESC',
  DistributedLeadsByFromCompanyIdStddevSampleIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  DistributedLeadsByFromCompanyIdStddevSampleIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  DistributedLeadsByFromCompanyIdStddevSampleLeadIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_LEAD_ID_ASC',
  DistributedLeadsByFromCompanyIdStddevSampleLeadIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_LEAD_ID_DESC',
  DistributedLeadsByFromCompanyIdStddevSampleFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_FROM_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdStddevSampleFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_FROM_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdStddevSampleToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_TO_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdStddevSampleToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_TO_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdStddevSampleOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByFromCompanyIdStddevSampleOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByFromCompanyIdStddevSampleCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  DistributedLeadsByFromCompanyIdStddevSampleCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  DistributedLeadsByFromCompanyIdStddevSampleUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DistributedLeadsByFromCompanyIdStddevSampleUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DistributedLeadsByFromCompanyIdStddevPopulationIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  DistributedLeadsByFromCompanyIdStddevPopulationIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  DistributedLeadsByFromCompanyIdStddevPopulationLeadIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_LEAD_ID_ASC',
  DistributedLeadsByFromCompanyIdStddevPopulationLeadIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_LEAD_ID_DESC',
  DistributedLeadsByFromCompanyIdStddevPopulationFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_FROM_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdStddevPopulationFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_FROM_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdStddevPopulationToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_TO_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdStddevPopulationToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_TO_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdStddevPopulationOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByFromCompanyIdStddevPopulationOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByFromCompanyIdStddevPopulationCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  DistributedLeadsByFromCompanyIdStddevPopulationCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  DistributedLeadsByFromCompanyIdStddevPopulationUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  DistributedLeadsByFromCompanyIdStddevPopulationUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  DistributedLeadsByFromCompanyIdVarianceSampleIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  DistributedLeadsByFromCompanyIdVarianceSampleIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  DistributedLeadsByFromCompanyIdVarianceSampleLeadIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_LEAD_ID_ASC',
  DistributedLeadsByFromCompanyIdVarianceSampleLeadIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_LEAD_ID_DESC',
  DistributedLeadsByFromCompanyIdVarianceSampleFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_FROM_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdVarianceSampleFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_FROM_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdVarianceSampleToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_TO_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdVarianceSampleToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_TO_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdVarianceSampleOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByFromCompanyIdVarianceSampleOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByFromCompanyIdVarianceSampleCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DistributedLeadsByFromCompanyIdVarianceSampleCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DistributedLeadsByFromCompanyIdVarianceSampleUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DistributedLeadsByFromCompanyIdVarianceSampleUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DistributedLeadsByFromCompanyIdVariancePopulationIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  DistributedLeadsByFromCompanyIdVariancePopulationIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  DistributedLeadsByFromCompanyIdVariancePopulationLeadIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_LEAD_ID_ASC',
  DistributedLeadsByFromCompanyIdVariancePopulationLeadIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_LEAD_ID_DESC',
  DistributedLeadsByFromCompanyIdVariancePopulationFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_FROM_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdVariancePopulationFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_FROM_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdVariancePopulationToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_TO_COMPANY_ID_ASC',
  DistributedLeadsByFromCompanyIdVariancePopulationToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_TO_COMPANY_ID_DESC',
  DistributedLeadsByFromCompanyIdVariancePopulationOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByFromCompanyIdVariancePopulationOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByFromCompanyIdVariancePopulationCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  DistributedLeadsByFromCompanyIdVariancePopulationCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  DistributedLeadsByFromCompanyIdVariancePopulationUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DistributedLeadsByFromCompanyIdVariancePopulationUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_FROM_COMPANY_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DistributedLeadsByToCompanyIdCountAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_COUNT_ASC',
  DistributedLeadsByToCompanyIdCountDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_COUNT_DESC',
  DistributedLeadsByToCompanyIdSumIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_ID_ASC',
  DistributedLeadsByToCompanyIdSumIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_ID_DESC',
  DistributedLeadsByToCompanyIdSumLeadIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_LEAD_ID_ASC',
  DistributedLeadsByToCompanyIdSumLeadIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_LEAD_ID_DESC',
  DistributedLeadsByToCompanyIdSumFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_FROM_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdSumFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_FROM_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdSumToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_TO_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdSumToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_TO_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdSumOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByToCompanyIdSumOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByToCompanyIdSumCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_CREATED_AT_ASC',
  DistributedLeadsByToCompanyIdSumCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_CREATED_AT_DESC',
  DistributedLeadsByToCompanyIdSumUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_UPDATED_AT_ASC',
  DistributedLeadsByToCompanyIdSumUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_SUM_UPDATED_AT_DESC',
  DistributedLeadsByToCompanyIdDistinctCountIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  DistributedLeadsByToCompanyIdDistinctCountIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  DistributedLeadsByToCompanyIdDistinctCountLeadIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_LEAD_ID_ASC',
  DistributedLeadsByToCompanyIdDistinctCountLeadIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_LEAD_ID_DESC',
  DistributedLeadsByToCompanyIdDistinctCountFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_FROM_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdDistinctCountFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_FROM_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdDistinctCountToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_TO_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdDistinctCountToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_TO_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdDistinctCountOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByToCompanyIdDistinctCountOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByToCompanyIdDistinctCountCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  DistributedLeadsByToCompanyIdDistinctCountCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  DistributedLeadsByToCompanyIdDistinctCountUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  DistributedLeadsByToCompanyIdDistinctCountUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  DistributedLeadsByToCompanyIdMinIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_ID_ASC',
  DistributedLeadsByToCompanyIdMinIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_ID_DESC',
  DistributedLeadsByToCompanyIdMinLeadIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_LEAD_ID_ASC',
  DistributedLeadsByToCompanyIdMinLeadIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_LEAD_ID_DESC',
  DistributedLeadsByToCompanyIdMinFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_FROM_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdMinFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_FROM_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdMinToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_TO_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdMinToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_TO_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdMinOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByToCompanyIdMinOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByToCompanyIdMinCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_CREATED_AT_ASC',
  DistributedLeadsByToCompanyIdMinCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_CREATED_AT_DESC',
  DistributedLeadsByToCompanyIdMinUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_UPDATED_AT_ASC',
  DistributedLeadsByToCompanyIdMinUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MIN_UPDATED_AT_DESC',
  DistributedLeadsByToCompanyIdMaxIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_ID_ASC',
  DistributedLeadsByToCompanyIdMaxIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_ID_DESC',
  DistributedLeadsByToCompanyIdMaxLeadIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_LEAD_ID_ASC',
  DistributedLeadsByToCompanyIdMaxLeadIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_LEAD_ID_DESC',
  DistributedLeadsByToCompanyIdMaxFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_FROM_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdMaxFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_FROM_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdMaxToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_TO_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdMaxToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_TO_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdMaxOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByToCompanyIdMaxOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByToCompanyIdMaxCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_CREATED_AT_ASC',
  DistributedLeadsByToCompanyIdMaxCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_CREATED_AT_DESC',
  DistributedLeadsByToCompanyIdMaxUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_UPDATED_AT_ASC',
  DistributedLeadsByToCompanyIdMaxUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_MAX_UPDATED_AT_DESC',
  DistributedLeadsByToCompanyIdAverageIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_ID_ASC',
  DistributedLeadsByToCompanyIdAverageIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_ID_DESC',
  DistributedLeadsByToCompanyIdAverageLeadIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_LEAD_ID_ASC',
  DistributedLeadsByToCompanyIdAverageLeadIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_LEAD_ID_DESC',
  DistributedLeadsByToCompanyIdAverageFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_FROM_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdAverageFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_FROM_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdAverageToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_TO_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdAverageToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_TO_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdAverageOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByToCompanyIdAverageOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByToCompanyIdAverageCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_CREATED_AT_ASC',
  DistributedLeadsByToCompanyIdAverageCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_CREATED_AT_DESC',
  DistributedLeadsByToCompanyIdAverageUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_UPDATED_AT_ASC',
  DistributedLeadsByToCompanyIdAverageUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_AVERAGE_UPDATED_AT_DESC',
  DistributedLeadsByToCompanyIdStddevSampleIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  DistributedLeadsByToCompanyIdStddevSampleIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  DistributedLeadsByToCompanyIdStddevSampleLeadIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_LEAD_ID_ASC',
  DistributedLeadsByToCompanyIdStddevSampleLeadIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_LEAD_ID_DESC',
  DistributedLeadsByToCompanyIdStddevSampleFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_FROM_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdStddevSampleFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_FROM_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdStddevSampleToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_TO_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdStddevSampleToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_TO_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdStddevSampleOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByToCompanyIdStddevSampleOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByToCompanyIdStddevSampleCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  DistributedLeadsByToCompanyIdStddevSampleCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  DistributedLeadsByToCompanyIdStddevSampleUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DistributedLeadsByToCompanyIdStddevSampleUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DistributedLeadsByToCompanyIdStddevPopulationIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  DistributedLeadsByToCompanyIdStddevPopulationIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  DistributedLeadsByToCompanyIdStddevPopulationLeadIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_LEAD_ID_ASC',
  DistributedLeadsByToCompanyIdStddevPopulationLeadIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_LEAD_ID_DESC',
  DistributedLeadsByToCompanyIdStddevPopulationFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_FROM_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdStddevPopulationFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_FROM_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdStddevPopulationToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_TO_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdStddevPopulationToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_TO_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdStddevPopulationOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByToCompanyIdStddevPopulationOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByToCompanyIdStddevPopulationCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  DistributedLeadsByToCompanyIdStddevPopulationCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  DistributedLeadsByToCompanyIdStddevPopulationUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  DistributedLeadsByToCompanyIdStddevPopulationUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  DistributedLeadsByToCompanyIdVarianceSampleIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  DistributedLeadsByToCompanyIdVarianceSampleIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  DistributedLeadsByToCompanyIdVarianceSampleLeadIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_LEAD_ID_ASC',
  DistributedLeadsByToCompanyIdVarianceSampleLeadIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_LEAD_ID_DESC',
  DistributedLeadsByToCompanyIdVarianceSampleFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_FROM_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdVarianceSampleFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_FROM_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdVarianceSampleToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_TO_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdVarianceSampleToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_TO_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdVarianceSampleOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByToCompanyIdVarianceSampleOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByToCompanyIdVarianceSampleCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DistributedLeadsByToCompanyIdVarianceSampleCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DistributedLeadsByToCompanyIdVarianceSampleUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DistributedLeadsByToCompanyIdVarianceSampleUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DistributedLeadsByToCompanyIdVariancePopulationIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  DistributedLeadsByToCompanyIdVariancePopulationIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  DistributedLeadsByToCompanyIdVariancePopulationLeadIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_LEAD_ID_ASC',
  DistributedLeadsByToCompanyIdVariancePopulationLeadIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_LEAD_ID_DESC',
  DistributedLeadsByToCompanyIdVariancePopulationFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_FROM_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdVariancePopulationFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_FROM_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdVariancePopulationToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_TO_COMPANY_ID_ASC',
  DistributedLeadsByToCompanyIdVariancePopulationToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_TO_COMPANY_ID_DESC',
  DistributedLeadsByToCompanyIdVariancePopulationOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByToCompanyIdVariancePopulationOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByToCompanyIdVariancePopulationCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  DistributedLeadsByToCompanyIdVariancePopulationCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  DistributedLeadsByToCompanyIdVariancePopulationUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DistributedLeadsByToCompanyIdVariancePopulationUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_TO_COMPANY_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DeliveryMethodsInternalsCountAsc = 'DELIVERY_METHODS_INTERNALS_COUNT_ASC',
  DeliveryMethodsInternalsCountDesc = 'DELIVERY_METHODS_INTERNALS_COUNT_DESC',
  DeliveryMethodsInternalsSumIdAsc = 'DELIVERY_METHODS_INTERNALS_SUM_ID_ASC',
  DeliveryMethodsInternalsSumIdDesc = 'DELIVERY_METHODS_INTERNALS_SUM_ID_DESC',
  DeliveryMethodsInternalsSumCompanyIdAsc = 'DELIVERY_METHODS_INTERNALS_SUM_COMPANY_ID_ASC',
  DeliveryMethodsInternalsSumCompanyIdDesc = 'DELIVERY_METHODS_INTERNALS_SUM_COMPANY_ID_DESC',
  DeliveryMethodsInternalsSumNameAsc = 'DELIVERY_METHODS_INTERNALS_SUM_NAME_ASC',
  DeliveryMethodsInternalsSumNameDesc = 'DELIVERY_METHODS_INTERNALS_SUM_NAME_DESC',
  DeliveryMethodsInternalsSumTypeAsc = 'DELIVERY_METHODS_INTERNALS_SUM_TYPE_ASC',
  DeliveryMethodsInternalsSumTypeDesc = 'DELIVERY_METHODS_INTERNALS_SUM_TYPE_DESC',
  DeliveryMethodsInternalsSumCreatedAtAsc = 'DELIVERY_METHODS_INTERNALS_SUM_CREATED_AT_ASC',
  DeliveryMethodsInternalsSumCreatedAtDesc = 'DELIVERY_METHODS_INTERNALS_SUM_CREATED_AT_DESC',
  DeliveryMethodsInternalsSumUpdatedAtAsc = 'DELIVERY_METHODS_INTERNALS_SUM_UPDATED_AT_ASC',
  DeliveryMethodsInternalsSumUpdatedAtDesc = 'DELIVERY_METHODS_INTERNALS_SUM_UPDATED_AT_DESC',
  DeliveryMethodsInternalsSumConfigAsc = 'DELIVERY_METHODS_INTERNALS_SUM_CONFIG_ASC',
  DeliveryMethodsInternalsSumConfigDesc = 'DELIVERY_METHODS_INTERNALS_SUM_CONFIG_DESC',
  DeliveryMethodsInternalsSumValuesAsc = 'DELIVERY_METHODS_INTERNALS_SUM_VALUES_ASC',
  DeliveryMethodsInternalsSumValuesDesc = 'DELIVERY_METHODS_INTERNALS_SUM_VALUES_DESC',
  DeliveryMethodsInternalsSumRuleAsc = 'DELIVERY_METHODS_INTERNALS_SUM_RULE_ASC',
  DeliveryMethodsInternalsSumRuleDesc = 'DELIVERY_METHODS_INTERNALS_SUM_RULE_DESC',
  DeliveryMethodsInternalsDistinctCountIdAsc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_ID_ASC',
  DeliveryMethodsInternalsDistinctCountIdDesc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_ID_DESC',
  DeliveryMethodsInternalsDistinctCountCompanyIdAsc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_COMPANY_ID_ASC',
  DeliveryMethodsInternalsDistinctCountCompanyIdDesc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_COMPANY_ID_DESC',
  DeliveryMethodsInternalsDistinctCountNameAsc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_NAME_ASC',
  DeliveryMethodsInternalsDistinctCountNameDesc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_NAME_DESC',
  DeliveryMethodsInternalsDistinctCountTypeAsc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_TYPE_ASC',
  DeliveryMethodsInternalsDistinctCountTypeDesc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_TYPE_DESC',
  DeliveryMethodsInternalsDistinctCountCreatedAtAsc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_CREATED_AT_ASC',
  DeliveryMethodsInternalsDistinctCountCreatedAtDesc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_CREATED_AT_DESC',
  DeliveryMethodsInternalsDistinctCountUpdatedAtAsc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_UPDATED_AT_ASC',
  DeliveryMethodsInternalsDistinctCountUpdatedAtDesc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_UPDATED_AT_DESC',
  DeliveryMethodsInternalsDistinctCountConfigAsc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_CONFIG_ASC',
  DeliveryMethodsInternalsDistinctCountConfigDesc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_CONFIG_DESC',
  DeliveryMethodsInternalsDistinctCountValuesAsc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_VALUES_ASC',
  DeliveryMethodsInternalsDistinctCountValuesDesc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_VALUES_DESC',
  DeliveryMethodsInternalsDistinctCountRuleAsc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_RULE_ASC',
  DeliveryMethodsInternalsDistinctCountRuleDesc = 'DELIVERY_METHODS_INTERNALS_DISTINCT_COUNT_RULE_DESC',
  DeliveryMethodsInternalsMinIdAsc = 'DELIVERY_METHODS_INTERNALS_MIN_ID_ASC',
  DeliveryMethodsInternalsMinIdDesc = 'DELIVERY_METHODS_INTERNALS_MIN_ID_DESC',
  DeliveryMethodsInternalsMinCompanyIdAsc = 'DELIVERY_METHODS_INTERNALS_MIN_COMPANY_ID_ASC',
  DeliveryMethodsInternalsMinCompanyIdDesc = 'DELIVERY_METHODS_INTERNALS_MIN_COMPANY_ID_DESC',
  DeliveryMethodsInternalsMinNameAsc = 'DELIVERY_METHODS_INTERNALS_MIN_NAME_ASC',
  DeliveryMethodsInternalsMinNameDesc = 'DELIVERY_METHODS_INTERNALS_MIN_NAME_DESC',
  DeliveryMethodsInternalsMinTypeAsc = 'DELIVERY_METHODS_INTERNALS_MIN_TYPE_ASC',
  DeliveryMethodsInternalsMinTypeDesc = 'DELIVERY_METHODS_INTERNALS_MIN_TYPE_DESC',
  DeliveryMethodsInternalsMinCreatedAtAsc = 'DELIVERY_METHODS_INTERNALS_MIN_CREATED_AT_ASC',
  DeliveryMethodsInternalsMinCreatedAtDesc = 'DELIVERY_METHODS_INTERNALS_MIN_CREATED_AT_DESC',
  DeliveryMethodsInternalsMinUpdatedAtAsc = 'DELIVERY_METHODS_INTERNALS_MIN_UPDATED_AT_ASC',
  DeliveryMethodsInternalsMinUpdatedAtDesc = 'DELIVERY_METHODS_INTERNALS_MIN_UPDATED_AT_DESC',
  DeliveryMethodsInternalsMinConfigAsc = 'DELIVERY_METHODS_INTERNALS_MIN_CONFIG_ASC',
  DeliveryMethodsInternalsMinConfigDesc = 'DELIVERY_METHODS_INTERNALS_MIN_CONFIG_DESC',
  DeliveryMethodsInternalsMinValuesAsc = 'DELIVERY_METHODS_INTERNALS_MIN_VALUES_ASC',
  DeliveryMethodsInternalsMinValuesDesc = 'DELIVERY_METHODS_INTERNALS_MIN_VALUES_DESC',
  DeliveryMethodsInternalsMinRuleAsc = 'DELIVERY_METHODS_INTERNALS_MIN_RULE_ASC',
  DeliveryMethodsInternalsMinRuleDesc = 'DELIVERY_METHODS_INTERNALS_MIN_RULE_DESC',
  DeliveryMethodsInternalsMaxIdAsc = 'DELIVERY_METHODS_INTERNALS_MAX_ID_ASC',
  DeliveryMethodsInternalsMaxIdDesc = 'DELIVERY_METHODS_INTERNALS_MAX_ID_DESC',
  DeliveryMethodsInternalsMaxCompanyIdAsc = 'DELIVERY_METHODS_INTERNALS_MAX_COMPANY_ID_ASC',
  DeliveryMethodsInternalsMaxCompanyIdDesc = 'DELIVERY_METHODS_INTERNALS_MAX_COMPANY_ID_DESC',
  DeliveryMethodsInternalsMaxNameAsc = 'DELIVERY_METHODS_INTERNALS_MAX_NAME_ASC',
  DeliveryMethodsInternalsMaxNameDesc = 'DELIVERY_METHODS_INTERNALS_MAX_NAME_DESC',
  DeliveryMethodsInternalsMaxTypeAsc = 'DELIVERY_METHODS_INTERNALS_MAX_TYPE_ASC',
  DeliveryMethodsInternalsMaxTypeDesc = 'DELIVERY_METHODS_INTERNALS_MAX_TYPE_DESC',
  DeliveryMethodsInternalsMaxCreatedAtAsc = 'DELIVERY_METHODS_INTERNALS_MAX_CREATED_AT_ASC',
  DeliveryMethodsInternalsMaxCreatedAtDesc = 'DELIVERY_METHODS_INTERNALS_MAX_CREATED_AT_DESC',
  DeliveryMethodsInternalsMaxUpdatedAtAsc = 'DELIVERY_METHODS_INTERNALS_MAX_UPDATED_AT_ASC',
  DeliveryMethodsInternalsMaxUpdatedAtDesc = 'DELIVERY_METHODS_INTERNALS_MAX_UPDATED_AT_DESC',
  DeliveryMethodsInternalsMaxConfigAsc = 'DELIVERY_METHODS_INTERNALS_MAX_CONFIG_ASC',
  DeliveryMethodsInternalsMaxConfigDesc = 'DELIVERY_METHODS_INTERNALS_MAX_CONFIG_DESC',
  DeliveryMethodsInternalsMaxValuesAsc = 'DELIVERY_METHODS_INTERNALS_MAX_VALUES_ASC',
  DeliveryMethodsInternalsMaxValuesDesc = 'DELIVERY_METHODS_INTERNALS_MAX_VALUES_DESC',
  DeliveryMethodsInternalsMaxRuleAsc = 'DELIVERY_METHODS_INTERNALS_MAX_RULE_ASC',
  DeliveryMethodsInternalsMaxRuleDesc = 'DELIVERY_METHODS_INTERNALS_MAX_RULE_DESC',
  DeliveryMethodsInternalsAverageIdAsc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_ID_ASC',
  DeliveryMethodsInternalsAverageIdDesc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_ID_DESC',
  DeliveryMethodsInternalsAverageCompanyIdAsc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_COMPANY_ID_ASC',
  DeliveryMethodsInternalsAverageCompanyIdDesc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_COMPANY_ID_DESC',
  DeliveryMethodsInternalsAverageNameAsc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_NAME_ASC',
  DeliveryMethodsInternalsAverageNameDesc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_NAME_DESC',
  DeliveryMethodsInternalsAverageTypeAsc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_TYPE_ASC',
  DeliveryMethodsInternalsAverageTypeDesc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_TYPE_DESC',
  DeliveryMethodsInternalsAverageCreatedAtAsc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_CREATED_AT_ASC',
  DeliveryMethodsInternalsAverageCreatedAtDesc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_CREATED_AT_DESC',
  DeliveryMethodsInternalsAverageUpdatedAtAsc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_UPDATED_AT_ASC',
  DeliveryMethodsInternalsAverageUpdatedAtDesc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_UPDATED_AT_DESC',
  DeliveryMethodsInternalsAverageConfigAsc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_CONFIG_ASC',
  DeliveryMethodsInternalsAverageConfigDesc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_CONFIG_DESC',
  DeliveryMethodsInternalsAverageValuesAsc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_VALUES_ASC',
  DeliveryMethodsInternalsAverageValuesDesc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_VALUES_DESC',
  DeliveryMethodsInternalsAverageRuleAsc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_RULE_ASC',
  DeliveryMethodsInternalsAverageRuleDesc = 'DELIVERY_METHODS_INTERNALS_AVERAGE_RULE_DESC',
  DeliveryMethodsInternalsStddevSampleIdAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_ID_ASC',
  DeliveryMethodsInternalsStddevSampleIdDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_ID_DESC',
  DeliveryMethodsInternalsStddevSampleCompanyIdAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  DeliveryMethodsInternalsStddevSampleCompanyIdDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  DeliveryMethodsInternalsStddevSampleNameAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_NAME_ASC',
  DeliveryMethodsInternalsStddevSampleNameDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_NAME_DESC',
  DeliveryMethodsInternalsStddevSampleTypeAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_TYPE_ASC',
  DeliveryMethodsInternalsStddevSampleTypeDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_TYPE_DESC',
  DeliveryMethodsInternalsStddevSampleCreatedAtAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_CREATED_AT_ASC',
  DeliveryMethodsInternalsStddevSampleCreatedAtDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_CREATED_AT_DESC',
  DeliveryMethodsInternalsStddevSampleUpdatedAtAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DeliveryMethodsInternalsStddevSampleUpdatedAtDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DeliveryMethodsInternalsStddevSampleConfigAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_CONFIG_ASC',
  DeliveryMethodsInternalsStddevSampleConfigDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_CONFIG_DESC',
  DeliveryMethodsInternalsStddevSampleValuesAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_VALUES_ASC',
  DeliveryMethodsInternalsStddevSampleValuesDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_VALUES_DESC',
  DeliveryMethodsInternalsStddevSampleRuleAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_RULE_ASC',
  DeliveryMethodsInternalsStddevSampleRuleDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_SAMPLE_RULE_DESC',
  DeliveryMethodsInternalsStddevPopulationIdAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_ID_ASC',
  DeliveryMethodsInternalsStddevPopulationIdDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_ID_DESC',
  DeliveryMethodsInternalsStddevPopulationCompanyIdAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_COMPANY_ID_ASC',
  DeliveryMethodsInternalsStddevPopulationCompanyIdDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_COMPANY_ID_DESC',
  DeliveryMethodsInternalsStddevPopulationNameAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_NAME_ASC',
  DeliveryMethodsInternalsStddevPopulationNameDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_NAME_DESC',
  DeliveryMethodsInternalsStddevPopulationTypeAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_TYPE_ASC',
  DeliveryMethodsInternalsStddevPopulationTypeDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_TYPE_DESC',
  DeliveryMethodsInternalsStddevPopulationCreatedAtAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_CREATED_AT_ASC',
  DeliveryMethodsInternalsStddevPopulationCreatedAtDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_CREATED_AT_DESC',
  DeliveryMethodsInternalsStddevPopulationUpdatedAtAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_UPDATED_AT_ASC',
  DeliveryMethodsInternalsStddevPopulationUpdatedAtDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_UPDATED_AT_DESC',
  DeliveryMethodsInternalsStddevPopulationConfigAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_CONFIG_ASC',
  DeliveryMethodsInternalsStddevPopulationConfigDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_CONFIG_DESC',
  DeliveryMethodsInternalsStddevPopulationValuesAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_VALUES_ASC',
  DeliveryMethodsInternalsStddevPopulationValuesDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_VALUES_DESC',
  DeliveryMethodsInternalsStddevPopulationRuleAsc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_RULE_ASC',
  DeliveryMethodsInternalsStddevPopulationRuleDesc = 'DELIVERY_METHODS_INTERNALS_STDDEV_POPULATION_RULE_DESC',
  DeliveryMethodsInternalsVarianceSampleIdAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_ID_ASC',
  DeliveryMethodsInternalsVarianceSampleIdDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_ID_DESC',
  DeliveryMethodsInternalsVarianceSampleCompanyIdAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  DeliveryMethodsInternalsVarianceSampleCompanyIdDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  DeliveryMethodsInternalsVarianceSampleNameAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_NAME_ASC',
  DeliveryMethodsInternalsVarianceSampleNameDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_NAME_DESC',
  DeliveryMethodsInternalsVarianceSampleTypeAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_TYPE_ASC',
  DeliveryMethodsInternalsVarianceSampleTypeDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_TYPE_DESC',
  DeliveryMethodsInternalsVarianceSampleCreatedAtAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DeliveryMethodsInternalsVarianceSampleCreatedAtDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DeliveryMethodsInternalsVarianceSampleUpdatedAtAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DeliveryMethodsInternalsVarianceSampleUpdatedAtDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DeliveryMethodsInternalsVarianceSampleConfigAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_CONFIG_ASC',
  DeliveryMethodsInternalsVarianceSampleConfigDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_CONFIG_DESC',
  DeliveryMethodsInternalsVarianceSampleValuesAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_VALUES_ASC',
  DeliveryMethodsInternalsVarianceSampleValuesDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_VALUES_DESC',
  DeliveryMethodsInternalsVarianceSampleRuleAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_RULE_ASC',
  DeliveryMethodsInternalsVarianceSampleRuleDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_SAMPLE_RULE_DESC',
  DeliveryMethodsInternalsVariancePopulationIdAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_ID_ASC',
  DeliveryMethodsInternalsVariancePopulationIdDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_ID_DESC',
  DeliveryMethodsInternalsVariancePopulationCompanyIdAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  DeliveryMethodsInternalsVariancePopulationCompanyIdDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  DeliveryMethodsInternalsVariancePopulationNameAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_NAME_ASC',
  DeliveryMethodsInternalsVariancePopulationNameDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_NAME_DESC',
  DeliveryMethodsInternalsVariancePopulationTypeAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_TYPE_ASC',
  DeliveryMethodsInternalsVariancePopulationTypeDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_TYPE_DESC',
  DeliveryMethodsInternalsVariancePopulationCreatedAtAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_CREATED_AT_ASC',
  DeliveryMethodsInternalsVariancePopulationCreatedAtDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_CREATED_AT_DESC',
  DeliveryMethodsInternalsVariancePopulationUpdatedAtAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DeliveryMethodsInternalsVariancePopulationUpdatedAtDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DeliveryMethodsInternalsVariancePopulationConfigAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_CONFIG_ASC',
  DeliveryMethodsInternalsVariancePopulationConfigDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_CONFIG_DESC',
  DeliveryMethodsInternalsVariancePopulationValuesAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_VALUES_ASC',
  DeliveryMethodsInternalsVariancePopulationValuesDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_VALUES_DESC',
  DeliveryMethodsInternalsVariancePopulationRuleAsc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_RULE_ASC',
  DeliveryMethodsInternalsVariancePopulationRuleDesc = 'DELIVERY_METHODS_INTERNALS_VARIANCE_POPULATION_RULE_DESC'
}

export type Company = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  archived: Scalars['Boolean'];
  zohoId: Scalars['String'];
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup: Scalars['Int'];
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry: Scalars['String'];
  manufacturer: Scalars['String'];
  crm: Scalars['String'];
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType: Scalars['String'];
  numberOfLocations: Scalars['String'];
  website: Scalars['String'];
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  /** Reads a single `Group` that is related to this `Company`. */
  groupByPrimaryGroup?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Company`. */
  userByAccountManager?: Maybe<User>;
  /** Reads a single `User` that is related to this `Company`. */
  userBySupportAgent?: Maybe<User>;
  /** Reads and enables pagination through a set of `Lead`. */
  leads: LeadsConnection;
  /** Reads and enables pagination through a set of `CompanyGroup`. */
  companyGroups: CompanyGroupsConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProducts: CompanyProductsConnection;
  /** Reads and enables pagination through a set of `CompanyLocation`. */
  companyLocations: CompanyLocationsConnection;
  /** Reads and enables pagination through a set of `CompanyAnalytic`. */
  companyAnalytics: CompanyAnalyticsConnection;
  /** Reads and enables pagination through a set of `CompanyDomain`. */
  companyDomains: CompanyDomainsConnection;
  /** Reads and enables pagination through a set of `CompanyDomainsApproved`. */
  companyDomainsApproveds: CompanyDomainsApprovedsConnection;
  /** Reads and enables pagination through a set of `CompanyDomainsPath`. */
  companyDomainsPaths: CompanyDomainsPathsConnection;
  /** Reads and enables pagination through a set of `Review`. */
  reviews: ReviewsConnection;
  /** Reads and enables pagination through a set of `Analytic`. */
  analytics: AnalyticsConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrations: CompanyIntegrationsConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannels: CompanyChannelsConnection;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
  /** Reads and enables pagination through a set of `VehicleStock`. */
  vehicleStocks: VehicleStocksConnection;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsers: CompanyUsersConnection;
  /** Reads and enables pagination through a set of `Media`. */
  medias: MediaConnection;
  /** Reads and enables pagination through a set of `DistributorRelationship`. */
  distributorRelationshipsByDistributorCompanyId: DistributorRelationshipsConnection;
  /** Reads and enables pagination through a set of `DistributorRelationship`. */
  distributorRelationships: DistributorRelationshipsConnection;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByFromCompanyId: DistributedLeadsConnection;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByToCompanyId: DistributedLeadsConnection;
  /** Reads and enables pagination through a set of `DeliveryMethodsInternal`. */
  deliveryMethodsInternals: DeliveryMethodsInternalsConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groups: GroupsConnection;
  /** Reads and enables pagination through a set of `Widget`. */
  widgets: WidgetsConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByLeadCompanyIdAndCompanyChannelId: CompanyCompanyChannelsByLeadCompanyIdAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `NeighborhoodView`. */
  neighborhoodViewsByLeadCompanyIdAndNeighborhoodViewId: CompanyNeighborhoodViewsByLeadCompanyIdAndNeighborhoodViewIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Product`. */
  productsByCompanyProductCompanyIdAndProductId: CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyDomain`. */
  companyDomainsByCompanyDomainsApprovedCompanyIdAndDomainId: CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyDomain`. */
  companyDomainsByCompanyDomainsPathCompanyIdAndDomainId: CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Integration`. */
  integrationsByCompanyIntegrationCompanyIdAndIntegrationId: CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByCompanyChannelCompanyIdAndChannelId: CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProductsByFieldCompanyIdAndCompanyProductId: CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByFieldCompanyIdAndCompanyChannelId: CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByFieldCompanyIdAndCompanyIntegrationId: CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigsByFieldCompanyIdAndConfig: CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByVehicleStockCompanyIdAndCompanyIntegrationId: CompanyCompanyIntegrationsByVehicleStockCompanyIdAndCompanyIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCompanyUserCompanyIdAndGroupId: CompanyGroupsByCompanyUserCompanyIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByCompanyUserCompanyIdAndUserId: CompanyUsersByCompanyUserCompanyIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByMediaCompanyIdAndUploader: CompanyUsersByMediaCompanyIdAndUploaderManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDistributorRelationshipDistributorCompanyIdAndCompanyId: CompanyCompaniesByDistributorRelationshipDistributorCompanyIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByDistributorRelationshipDistributorCompanyIdAndCompanyChannelId: CompanyCompanyChannelsByDistributorRelationshipDistributorCompanyIdAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDistributorRelationshipCompanyIdAndDistributorCompanyId: CompanyCompaniesByDistributorRelationshipCompanyIdAndDistributorCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByDistributorRelationshipCompanyIdAndCompanyChannelId: CompanyCompanyChannelsByDistributorRelationshipCompanyIdAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByDistributedLeadFromCompanyIdAndLeadId: CompanyLeadsByDistributedLeadFromCompanyIdAndLeadIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDistributedLeadFromCompanyIdAndToCompanyId: CompanyCompaniesByDistributedLeadFromCompanyIdAndToCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByDistributedLeadFromCompanyIdAndOriginalCompanyChannelId: CompanyCompanyChannelsByDistributedLeadFromCompanyIdAndOriginalCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByDistributedLeadToCompanyIdAndLeadId: CompanyLeadsByDistributedLeadToCompanyIdAndLeadIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDistributedLeadToCompanyIdAndFromCompanyId: CompanyCompaniesByDistributedLeadToCompanyIdAndFromCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByDistributedLeadToCompanyIdAndOriginalCompanyChannelId: CompanyCompanyChannelsByDistributedLeadToCompanyIdAndOriginalCompanyChannelIdManyToManyConnection;
  meta?: Maybe<CompanyMeta>;
  requestedAccess?: Maybe<Array<CompanyRequestedAccess>>;
  icon?: Maybe<Media>;
  logo?: Maybe<Media>;
};


export type CompanyLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type CompanyCompanyGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
  condition?: Maybe<CompanyGroupCondition>;
  filter?: Maybe<CompanyGroupFilter>;
};


export type CompanyCompanyProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type CompanyCompanyLocationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyLocationsOrderBy>>;
  condition?: Maybe<CompanyLocationCondition>;
  filter?: Maybe<CompanyLocationFilter>;
};


export type CompanyCompanyAnalyticsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyAnalyticsOrderBy>>;
  condition?: Maybe<CompanyAnalyticCondition>;
  filter?: Maybe<CompanyAnalyticFilter>;
};


export type CompanyCompanyDomainsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
  condition?: Maybe<CompanyDomainCondition>;
  filter?: Maybe<CompanyDomainFilter>;
};


export type CompanyCompanyDomainsApprovedsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
  condition?: Maybe<CompanyDomainsApprovedCondition>;
  filter?: Maybe<CompanyDomainsApprovedFilter>;
};


export type CompanyCompanyDomainsPathsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
  condition?: Maybe<CompanyDomainsPathCondition>;
  filter?: Maybe<CompanyDomainsPathFilter>;
};


export type CompanyReviewsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReviewsOrderBy>>;
  condition?: Maybe<ReviewCondition>;
  filter?: Maybe<ReviewFilter>;
};


export type CompanyAnalyticsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AnalyticsOrderBy>>;
  condition?: Maybe<AnalyticCondition>;
  filter?: Maybe<AnalyticFilter>;
};


export type CompanyCompanyIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type CompanyCompanyChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


export type CompanyVehicleStocksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleStocksOrderBy>>;
  condition?: Maybe<VehicleStockCondition>;
  filter?: Maybe<VehicleStockFilter>;
};


export type CompanyCompanyUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyUsersOrderBy>>;
  condition?: Maybe<CompanyUserCondition>;
  filter?: Maybe<CompanyUserFilter>;
};


export type CompanyMediasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaOrderBy>>;
  condition?: Maybe<MediaCondition>;
  filter?: Maybe<MediaFilter>;
};


export type CompanyDistributorRelationshipsByDistributorCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributorRelationshipsOrderBy>>;
  condition?: Maybe<DistributorRelationshipCondition>;
  filter?: Maybe<DistributorRelationshipFilter>;
};


export type CompanyDistributorRelationshipsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributorRelationshipsOrderBy>>;
  condition?: Maybe<DistributorRelationshipCondition>;
  filter?: Maybe<DistributorRelationshipFilter>;
};


export type CompanyDistributedLeadsByFromCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};


export type CompanyDistributedLeadsByToCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};


export type CompanyDeliveryMethodsInternalsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DeliveryMethodsInternalsOrderBy>>;
  condition?: Maybe<DeliveryMethodsInternalCondition>;
  filter?: Maybe<DeliveryMethodsInternalFilter>;
};


export type CompanyGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<GroupFilter>;
};


export type CompanyWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<WidgetFilter>;
};


export type CompanyCompanyChannelsByLeadCompanyIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyNeighborhoodViewsByLeadCompanyIdAndNeighborhoodViewIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NeighborhoodViewsOrderBy>>;
  condition?: Maybe<NeighborhoodViewCondition>;
  filter?: Maybe<NeighborhoodViewFilter>;
};


export type CompanyProductsByCompanyProductCompanyIdAndProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


export type CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
  condition?: Maybe<CompanyDomainCondition>;
  filter?: Maybe<CompanyDomainFilter>;
};


export type CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
  condition?: Maybe<CompanyDomainCondition>;
  filter?: Maybe<CompanyDomainFilter>;
};


export type CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
  condition?: Maybe<IntegrationCondition>;
  filter?: Maybe<IntegrationFilter>;
};


export type CompanyChannelsByCompanyChannelCompanyIdAndChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
  condition?: Maybe<ChannelCondition>;
  filter?: Maybe<ChannelFilter>;
};


export type CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type CompanyFieldConfigsByFieldCompanyIdAndConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};


export type CompanyCompanyIntegrationsByVehicleStockCompanyIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type CompanyGroupsByCompanyUserCompanyIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


export type CompanyUsersByCompanyUserCompanyIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type CompanyUsersByMediaCompanyIdAndUploaderArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type CompanyCompaniesByDistributorRelationshipDistributorCompanyIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyCompanyChannelsByDistributorRelationshipDistributorCompanyIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyCompaniesByDistributorRelationshipCompanyIdAndDistributorCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyCompanyChannelsByDistributorRelationshipCompanyIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyLeadsByDistributedLeadFromCompanyIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type CompanyCompaniesByDistributedLeadFromCompanyIdAndToCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyCompanyChannelsByDistributedLeadFromCompanyIdAndOriginalCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyLeadsByDistributedLeadToCompanyIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type CompanyCompaniesByDistributedLeadToCompanyIdAndFromCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyCompanyChannelsByDistributedLeadToCompanyIdAndOriginalCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};

/** The `company` to be created by this mutation. */
export type CompanyAccountManagerFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `user` in the `CompanyInput` mutation. */
export type CompanyAccountManagerFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnCompanyForCompanyAccountManagerFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyOnCompanyForCompanyAccountManagerFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `company` in the `UserInput` mutation. */
export type CompanyAccountManagerFkeyInverseInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyCompanyPkeyConnect>>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<Array<CompanyCompanyNameKeyConnect>>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<Array<CompanyCompanyExternalIdKeyConnect>>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<Array<CompanyCompanyPrimaryGroupKeyConnect>>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<Array<CompanyCompanyLagoIdKeyConnect>>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyNodeIdConnect>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyOnCompanyForCompanyAccountManagerFkeyUsingCompanyPkeyUpdate>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<Array<CompanyOnCompanyForCompanyAccountManagerFkeyUsingCompanyNameKeyUpdate>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<Array<CompanyOnCompanyForCompanyAccountManagerFkeyUsingCompanyExternalIdKeyUpdate>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<Array<CompanyOnCompanyForCompanyAccountManagerFkeyUsingCompanyPrimaryGroupKeyUpdate>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<Array<CompanyOnCompanyForCompanyAccountManagerFkeyUsingCompanyLagoIdKeyUpdate>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnCompanyForCompanyAccountManagerFkeyNodeIdUpdate>>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyAccountManagerFkeyCompanyCreateInput>>;
};

export type CompanyAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Company` object types. */
export type CompanyAggregatesFilter = {
  /** A filter that must pass for the relevant `Company` object to be included within the aggregate. */
  filter?: Maybe<CompanyFilter>;
  /** Sum aggregate over matching `Company` objects. */
  sum?: Maybe<CompanySumAggregateFilter>;
  /** Distinct count aggregate over matching `Company` objects. */
  distinctCount?: Maybe<CompanyDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Company` objects. */
  min?: Maybe<CompanyMinAggregateFilter>;
  /** Maximum aggregate over matching `Company` objects. */
  max?: Maybe<CompanyMaxAggregateFilter>;
  /** Mean average aggregate over matching `Company` objects. */
  average?: Maybe<CompanyAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Company` objects. */
  stddevSample?: Maybe<CompanyStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Company` objects. */
  stddevPopulation?: Maybe<CompanyStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Company` objects. */
  varianceSample?: Maybe<CompanyVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Company` objects. */
  variancePopulation?: Maybe<CompanyVariancePopulationAggregateFilter>;
};

export type CompanyAnalytic = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  type: AnalyticsType;
  analyticsId: Scalars['String'];
  analyticsSecret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Company` that is related to this `CompanyAnalytic`. */
  company?: Maybe<Company>;
};

export type CompanyAnalyticAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyAnalyticSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyAnalyticDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyAnalyticMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyAnalyticMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyAnalyticAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyAnalyticStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyAnalyticStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyAnalyticVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyAnalyticVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyAnalytic` object types. */
export type CompanyAnalyticAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyAnalytic` object to be included within the aggregate. */
  filter?: Maybe<CompanyAnalyticFilter>;
  /** Sum aggregate over matching `CompanyAnalytic` objects. */
  sum?: Maybe<CompanyAnalyticSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyAnalytic` objects. */
  distinctCount?: Maybe<CompanyAnalyticDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyAnalytic` objects. */
  min?: Maybe<CompanyAnalyticMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyAnalytic` objects. */
  max?: Maybe<CompanyAnalyticMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyAnalytic` objects. */
  average?: Maybe<CompanyAnalyticAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyAnalytic` objects. */
  stddevSample?: Maybe<CompanyAnalyticStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyAnalytic` objects. */
  stddevPopulation?: Maybe<CompanyAnalyticStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyAnalytic` objects. */
  varianceSample?: Maybe<CompanyAnalyticVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyAnalytic` objects. */
  variancePopulation?: Maybe<CompanyAnalyticVariancePopulationAggregateFilter>;
};

export type CompanyAnalyticAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyAnalyticAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `companyAnalytic` to look up the row to connect. */
export type CompanyAnalyticCompanyAnalyticsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyAnalytic` to look up the row to delete. */
export type CompanyAnalyticCompanyAnalyticsPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyAnalytic` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyAnalyticCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<AnalyticsType>;
  /** Checks for equality with the object’s `analyticsId` field. */
  analyticsId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `analyticsSecret` field. */
  analyticsSecret?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `domain` field. */
  domain?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** The source url to find the most relevant domain match for */
  mostRelevantDomains?: Maybe<Scalars['String']>;
  /** The source url to find the most relevant domain match for */
  mostRelevantDomainOnly?: Maybe<Scalars['String']>;
};

export type CompanyAnalyticDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  analyticsId?: Maybe<BigIntFilter>;
  analyticsSecret?: Maybe<BigIntFilter>;
  domain?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyAnalyticDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of analyticsId across the matching connection */
  analyticsId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of analyticsSecret across the matching connection */
  analyticsSecret?: Maybe<Scalars['BigInt']>;
  /** Distinct count of domain across the matching connection */
  domain?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyAnalytic` object types. All fields are combined with a logical ‘and.’ */
export type CompanyAnalyticFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<AnalyticsTypeFilter>;
  /** Filter by the object’s `analyticsId` field. */
  analyticsId?: Maybe<StringFilter>;
  /** Filter by the object’s `analyticsSecret` field. */
  analyticsSecret?: Maybe<StringFilter>;
  /** Filter by the object’s `domain` field. */
  domain?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyAnalyticFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyAnalyticFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyAnalyticFilter>;
};

/** An input for mutations affecting `CompanyAnalytic` */
export type CompanyAnalyticInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  type: AnalyticsType;
  analyticsId: Scalars['String'];
  analyticsSecret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FkCompanyAnalyticsCompanyIdInput>;
};

export type CompanyAnalyticMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyAnalyticMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type CompanyAnalyticMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyAnalyticMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyAnalyticNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyAnalytic` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyAnalyticNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyAnalytic` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyAnalytic` to look up the row to update. */
export type CompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyAnalyticsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyAnalytic` being updated. */
  patch: UpdateCompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyAnalytic`. Fields that are set will be updated. */
export type CompanyAnalyticPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  type?: Maybe<AnalyticsType>;
  analyticsId?: Maybe<Scalars['String']>;
  analyticsSecret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FkCompanyAnalyticsCompanyIdInput>;
};

export type CompanyAnalyticStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyAnalyticStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyAnalyticStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyAnalyticStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyAnalyticSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
};

export type CompanyAnalyticSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

export type CompanyAnalyticVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyAnalyticVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyAnalyticVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyAnalyticVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyAnalytic` values. */
export type CompanyAnalyticsConnection = {
  /** A list of `CompanyAnalytic` objects. */
  nodes: Array<CompanyAnalytic>;
  /** A list of edges which contains the `CompanyAnalytic` and cursor to aid in pagination. */
  edges: Array<CompanyAnalyticsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyAnalytic` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAnalyticAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAnalyticAggregates>>;
};


/** A connection to a list of `CompanyAnalytic` values. */
export type CompanyAnalyticsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyAnalyticsGroupBy>;
  having?: Maybe<CompanyAnalyticsHavingInput>;
};

/** A `CompanyAnalytic` edge in the connection. */
export type CompanyAnalyticsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyAnalytic` at the end of the edge. */
  node: CompanyAnalytic;
};

/** Grouping methods for `CompanyAnalytic` for usage during aggregation. */
export enum CompanyAnalyticsGroupBy {
  CompanyId = 'COMPANY_ID',
  Type = 'TYPE',
  AnalyticsId = 'ANALYTICS_ID',
  AnalyticsSecret = 'ANALYTICS_SECRET',
  Domain = 'DOMAIN',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyAnalyticsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyAnalytic` aggregates. */
export type CompanyAnalyticsHavingInput = {
  AND?: Maybe<Array<CompanyAnalyticsHavingInput>>;
  OR?: Maybe<Array<CompanyAnalyticsHavingInput>>;
  sum?: Maybe<CompanyAnalyticsHavingSumInput>;
  distinctCount?: Maybe<CompanyAnalyticsHavingDistinctCountInput>;
  min?: Maybe<CompanyAnalyticsHavingMinInput>;
  max?: Maybe<CompanyAnalyticsHavingMaxInput>;
  average?: Maybe<CompanyAnalyticsHavingAverageInput>;
  stddevSample?: Maybe<CompanyAnalyticsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyAnalyticsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyAnalyticsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyAnalyticsHavingVariancePopulationInput>;
};

export type CompanyAnalyticsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CompanyAnalytic`. */
export enum CompanyAnalyticsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  AnalyticsIdAsc = 'ANALYTICS_ID_ASC',
  AnalyticsIdDesc = 'ANALYTICS_ID_DESC',
  AnalyticsSecretAsc = 'ANALYTICS_SECRET_ASC',
  AnalyticsSecretDesc = 'ANALYTICS_SECRET_DESC',
  DomainAsc = 'DOMAIN_ASC',
  DomainDesc = 'DOMAIN_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompanyAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  primaryGroup?: Maybe<BigFloatFilter>;
  billableCompanyId?: Maybe<BigFloatFilter>;
  accountManager?: Maybe<BigFloatFilter>;
  supportAgent?: Maybe<BigFloatFilter>;
};

export type CompanyAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigFloat']>;
  /** Mean average of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of accountManager across the matching connection */
  accountManager?: Maybe<Scalars['BigFloat']>;
  /** Mean average of supportAgent across the matching connection */
  supportAgent?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannel = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  widgetId: Scalars['String'];
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Channel` that is related to this `CompanyChannel`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Company` that is related to this `CompanyChannel`. */
  company?: Maybe<Company>;
  /** Reads and enables pagination through a set of `Lead`. */
  leads: LeadsConnection;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
  /** Reads and enables pagination through a set of `CompanyChannelMedia`. */
  companyChannelMedias: CompanyChannelMediaConnection;
  /** Reads and enables pagination through a set of `DistributorRelationship`. */
  distributorRelationships: DistributorRelationshipsConnection;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByOriginalCompanyChannelId: DistributedLeadsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByLeadCompanyChannelIdAndCompanyId: CompanyChannelCompaniesByLeadCompanyChannelIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `NeighborhoodView`. */
  neighborhoodViewsByLeadCompanyChannelIdAndNeighborhoodViewId: CompanyChannelNeighborhoodViewsByLeadCompanyChannelIdAndNeighborhoodViewIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByFieldCompanyChannelIdAndCompanyId: CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProductsByFieldCompanyChannelIdAndCompanyProductId: CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationId: CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigsByFieldCompanyChannelIdAndConfig: CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigManyToManyConnection;
  /** Reads and enables pagination through a set of `Media`. */
  mediaByCompanyChannelMediaCompanyChannelIdAndMediaId: CompanyChannelMediaByCompanyChannelMediaCompanyChannelIdAndMediaIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByCompanyChannelMediaCompanyChannelIdAndAssigneeId: CompanyChannelUsersByCompanyChannelMediaCompanyChannelIdAndAssigneeIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDistributorRelationshipCompanyChannelIdAndDistributorCompanyId: CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndDistributorCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDistributorRelationshipCompanyChannelIdAndCompanyId: CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByDistributedLeadOriginalCompanyChannelIdAndLeadId: CompanyChannelLeadsByDistributedLeadOriginalCompanyChannelIdAndLeadIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDistributedLeadOriginalCompanyChannelIdAndFromCompanyId: CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndFromCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDistributedLeadOriginalCompanyChannelIdAndToCompanyId: CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndToCompanyIdManyToManyConnection;
};


export type CompanyChannelLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type CompanyChannelFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


export type CompanyChannelCompanyChannelMediasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelMediaOrderBy>>;
  condition?: Maybe<CompanyChannelMediaCondition>;
  filter?: Maybe<CompanyChannelMediaFilter>;
};


export type CompanyChannelDistributorRelationshipsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributorRelationshipsOrderBy>>;
  condition?: Maybe<DistributorRelationshipCondition>;
  filter?: Maybe<DistributorRelationshipFilter>;
};


export type CompanyChannelDistributedLeadsByOriginalCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};


export type CompanyChannelCompaniesByLeadCompanyChannelIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyChannelNeighborhoodViewsByLeadCompanyChannelIdAndNeighborhoodViewIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NeighborhoodViewsOrderBy>>;
  condition?: Maybe<NeighborhoodViewCondition>;
  filter?: Maybe<NeighborhoodViewFilter>;
};


export type CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};


export type CompanyChannelMediaByCompanyChannelMediaCompanyChannelIdAndMediaIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaOrderBy>>;
  condition?: Maybe<MediaCondition>;
  filter?: Maybe<MediaFilter>;
};


export type CompanyChannelUsersByCompanyChannelMediaCompanyChannelIdAndAssigneeIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndDistributorCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyChannelLeadsByDistributedLeadOriginalCompanyChannelIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndFromCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndToCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};

export type CompanyChannelAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyChannelSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyChannelDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyChannelMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyChannelMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyChannelAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyChannelStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyChannelStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyChannelVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyChannelVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyChannel` object types. */
export type CompanyChannelAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyChannel` object to be included within the aggregate. */
  filter?: Maybe<CompanyChannelFilter>;
  /** Sum aggregate over matching `CompanyChannel` objects. */
  sum?: Maybe<CompanyChannelSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyChannel` objects. */
  distinctCount?: Maybe<CompanyChannelDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyChannel` objects. */
  min?: Maybe<CompanyChannelMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyChannel` objects. */
  max?: Maybe<CompanyChannelMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyChannel` objects. */
  average?: Maybe<CompanyChannelAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyChannel` objects. */
  stddevSample?: Maybe<CompanyChannelStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyChannel` objects. */
  stddevPopulation?: Maybe<CompanyChannelStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyChannel` objects. */
  varianceSample?: Maybe<CompanyChannelVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyChannel` objects. */
  variancePopulation?: Maybe<CompanyChannelVariancePopulationAggregateFilter>;
};

export type CompanyChannelAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** The `channel` to be created by this mutation. */
export type CompanyChannelChannelIdFkeyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyChannelsUsingId?: Maybe<CompanyChannelChannelIdFkeyInverseInput>;
};

/** The `companyChannel` to be created by this mutation. */
export type CompanyChannelChannelIdFkeyCompanyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** Input for the nested mutation of `channel` in the `CompanyChannelInput` mutation. */
export type CompanyChannelChannelIdFkeyInput = {
  /** The primary key(s) for `channel` for the far side of the relationship. */
  connectById?: Maybe<ChannelChannelPkeyConnect>;
  /** The primary key(s) for `channel` for the far side of the relationship. */
  connectByName?: Maybe<ChannelUniqueChannelNameConnect>;
  /** The primary key(s) for `channel` for the far side of the relationship. */
  connectByNodeId?: Maybe<ChannelNodeIdConnect>;
  /** The primary key(s) for `channel` for the far side of the relationship. */
  deleteById?: Maybe<ChannelChannelPkeyDelete>;
  /** The primary key(s) for `channel` for the far side of the relationship. */
  deleteByName?: Maybe<ChannelUniqueChannelNameDelete>;
  /** The primary key(s) for `channel` for the far side of the relationship. */
  deleteByNodeId?: Maybe<ChannelNodeIdDelete>;
  /** The primary key(s) and patch data for `channel` for the far side of the relationship. */
  updateById?: Maybe<ChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingChannelPkeyUpdate>;
  /** The primary key(s) and patch data for `channel` for the far side of the relationship. */
  updateByName?: Maybe<ChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingUniqueChannelNameUpdate>;
  /** The primary key(s) and patch data for `channel` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyNodeIdUpdate>;
  /** A `ChannelInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyChannelChannelIdFkeyChannelCreateInput>;
};

/** Input for the nested mutation of `companyChannel` in the `ChannelInput` mutation. */
export type CompanyChannelChannelIdFkeyInverseInput = {
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyChannelCompanyChannelPkeyConnect>>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByCompanyIdAndNameAndChannelId?: Maybe<Array<CompanyChannelUnqCompanyChannelConnect>>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyChannelNodeIdConnect>>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingCompanyChannelPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByCompanyIdAndNameAndChannelId?: Maybe<Array<CompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingUnqCompanyChannelUpdate>>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<ChannelOnCompanyChannelForCompanyChannelChannelIdFkeyNodeIdUpdate>>;
  /** A `CompanyChannelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyChannelChannelIdFkeyCompanyChannelCreateInput>>;
};

/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndFromCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndFromCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndFromCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndFromCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByFromCompanyId: DistributedLeadsConnection;
};


/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndFromCompanyIdManyToManyEdgeDistributedLeadsByFromCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndToCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndToCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndToCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndToCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByToCompanyId: DistributedLeadsConnection;
};


/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type CompanyChannelCompaniesByDistributedLeadOriginalCompanyChannelIdAndToCompanyIdManyToManyEdgeDistributedLeadsByToCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

/** A connection to a list of `Company` values, with data from `DistributorRelationship`. */
export type CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `DistributorRelationship`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `DistributorRelationship`. */
export type CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `DistributorRelationship`. */
export type CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `DistributorRelationship`. */
  distributorRelationships: DistributorRelationshipsConnection;
};


/** A `Company` edge in the connection, with data from `DistributorRelationship`. */
export type CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndCompanyIdManyToManyEdgeDistributorRelationshipsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributorRelationshipsOrderBy>>;
  condition?: Maybe<DistributorRelationshipCondition>;
  filter?: Maybe<DistributorRelationshipFilter>;
};

/** A connection to a list of `Company` values, with data from `DistributorRelationship`. */
export type CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndDistributorCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `DistributorRelationship`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndDistributorCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `DistributorRelationship`. */
export type CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndDistributorCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `DistributorRelationship`. */
export type CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndDistributorCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `DistributorRelationship`. */
  distributorRelationshipsByDistributorCompanyId: DistributorRelationshipsConnection;
};


/** A `Company` edge in the connection, with data from `DistributorRelationship`. */
export type CompanyChannelCompaniesByDistributorRelationshipCompanyChannelIdAndDistributorCompanyIdManyToManyEdgeDistributorRelationshipsByDistributorCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributorRelationshipsOrderBy>>;
  condition?: Maybe<DistributorRelationshipCondition>;
  filter?: Maybe<DistributorRelationshipFilter>;
};

/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `Company` values, with data from `Lead`. */
export type CompanyChannelCompaniesByLeadCompanyChannelIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `Lead`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelCompaniesByLeadCompanyChannelIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `Lead`. */
export type CompanyChannelCompaniesByLeadCompanyChannelIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `Lead`. */
export type CompanyChannelCompaniesByLeadCompanyChannelIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `Lead`. */
  leads: LeadsConnection;
};


/** A `Company` edge in the connection, with data from `Lead`. */
export type CompanyChannelCompaniesByLeadCompanyChannelIdAndCompanyIdManyToManyEdgeLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

/** The fields on `companyChannel` to look up the row to connect. */
export type CompanyChannelCompanyChannelPkeyConnect = {
  id: Scalars['Int'];
};

/** The `companyChannel` to be created by this mutation. */
export type CompanyChannelCompanyIdFkeyCompanyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** The `company` to be created by this mutation. */
export type CompanyChannelCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `CompanyChannelInput` mutation. */
export type CompanyChannelCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyChannelCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyChannel` in the `CompanyInput` mutation. */
export type CompanyChannelCompanyIdFkeyInverseInput = {
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyChannelCompanyChannelPkeyConnect>>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByCompanyIdAndNameAndChannelId?: Maybe<Array<CompanyChannelUnqCompanyChannelConnect>>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyChannelNodeIdConnect>>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyChannelPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByCompanyIdAndNameAndChannelId?: Maybe<Array<CompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingUnqCompanyChannelUpdate>>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyChannelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyChannelCompanyIdFkeyCompanyChannelCreateInput>>;
};

/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/**
 * A condition to be used against `CompanyChannel` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyChannelCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `widgetId` field. */
  widgetId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `channelId` field. */
  channelId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyChannelDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  widgetId?: Maybe<BigIntFilter>;
  channelId?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  displayName?: Maybe<BigIntFilter>;
  deletedAt?: Maybe<BigIntFilter>;
};

export type CompanyChannelDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetId across the matching connection */
  widgetId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigManyToManyConnection = {
  /** A list of `FieldConfig` objects. */
  nodes: Array<FieldConfig>;
  /** A list of edges which contains the `FieldConfig`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldConfigAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldConfigAggregates>>;
};


/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldConfigGroupBy>;
  having?: Maybe<FieldConfigHavingInput>;
};

/** A `FieldConfig` edge in the connection, with data from `Field`. */
export type CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldConfig` at the end of the edge. */
  node: FieldConfig;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A filter to be used against `CompanyChannel` object types. All fields are combined with a logical ‘and.’ */
export type CompanyChannelFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `widgetId` field. */
  widgetId?: Maybe<StringFilter>;
  /** Filter by the object’s `channelId` field. */
  channelId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `leads` relation. */
  leads?: Maybe<CompanyChannelToManyLeadFilter>;
  /** Some related `leads` exist. */
  leadsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fields` relation. */
  fields?: Maybe<CompanyChannelToManyFieldFilter>;
  /** Some related `fields` exist. */
  fieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyChannelMedias` relation. */
  companyChannelMedias?: Maybe<CompanyChannelToManyCompanyChannelMediaFilter>;
  /** Some related `companyChannelMedias` exist. */
  companyChannelMediasExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `distributorRelationships` relation. */
  distributorRelationships?: Maybe<CompanyChannelToManyDistributorRelationshipFilter>;
  /** Some related `distributorRelationships` exist. */
  distributorRelationshipsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `distributedLeadsByOriginalCompanyChannelId` relation. */
  distributedLeadsByOriginalCompanyChannelId?: Maybe<CompanyChannelToManyDistributedLeadFilter>;
  /** Some related `distributedLeadsByOriginalCompanyChannelId` exist. */
  distributedLeadsByOriginalCompanyChannelIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `channel` relation. */
  channel?: Maybe<ChannelFilter>;
  /** A related `channel` exists. */
  channelExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyChannelFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyChannelFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyChannelFilter>;
};

/** Grouping methods for `CompanyChannel` for usage during aggregation. */
export enum CompanyChannelGroupBy {
  ChannelId = 'CHANNEL_ID',
  CompanyId = 'COMPANY_ID',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Name = 'NAME',
  DisplayName = 'DISPLAY_NAME',
  DeletedAt = 'DELETED_AT',
  DeletedAtTruncatedToHour = 'DELETED_AT_TRUNCATED_TO_HOUR',
  DeletedAtTruncatedToDay = 'DELETED_AT_TRUNCATED_TO_DAY'
}

export type CompanyChannelHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyChannel` aggregates. */
export type CompanyChannelHavingInput = {
  AND?: Maybe<Array<CompanyChannelHavingInput>>;
  OR?: Maybe<Array<CompanyChannelHavingInput>>;
  sum?: Maybe<CompanyChannelHavingSumInput>;
  distinctCount?: Maybe<CompanyChannelHavingDistinctCountInput>;
  min?: Maybe<CompanyChannelHavingMinInput>;
  max?: Maybe<CompanyChannelHavingMaxInput>;
  average?: Maybe<CompanyChannelHavingAverageInput>;
  stddevSample?: Maybe<CompanyChannelHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyChannelHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyChannelHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyChannelHavingVariancePopulationInput>;
};

export type CompanyChannelHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyChannel` */
export type CompanyChannelInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** A connection to a list of `Lead` values, with data from `DistributedLead`. */
export type CompanyChannelLeadsByDistributedLeadOriginalCompanyChannelIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelLeadsByDistributedLeadOriginalCompanyChannelIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `DistributedLead`. */
export type CompanyChannelLeadsByDistributedLeadOriginalCompanyChannelIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `DistributedLead`. */
export type CompanyChannelLeadsByDistributedLeadOriginalCompanyChannelIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeads: DistributedLeadsConnection;
};


/** A `Lead` edge in the connection, with data from `DistributedLead`. */
export type CompanyChannelLeadsByDistributedLeadOriginalCompanyChannelIdAndLeadIdManyToManyEdgeDistributedLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

export type CompanyChannelMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  channelId?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyChannelMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of channelId across the matching connection */
  channelId?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type CompanyChannelMedia = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyChannelId?: Maybe<Scalars['Int']>;
  mediaId: Scalars['Int'];
  assigneeId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `CompanyChannel` that is related to this `CompanyChannelMedia`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `Media` that is related to this `CompanyChannelMedia`. */
  media?: Maybe<Media>;
  /** Reads a single `User` that is related to this `CompanyChannelMedia`. */
  assignee?: Maybe<User>;
};

export type CompanyChannelMediaAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyChannelMediaSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyChannelMediaDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyChannelMediaMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyChannelMediaMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyChannelMediaAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyChannelMediaStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyChannelMediaStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyChannelMediaVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyChannelMediaVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyChannelMedia` object types. */
export type CompanyChannelMediaAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyChannelMedia` object to be included within the aggregate. */
  filter?: Maybe<CompanyChannelMediaFilter>;
  /** Sum aggregate over matching `CompanyChannelMedia` objects. */
  sum?: Maybe<CompanyChannelMediaSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyChannelMedia` objects. */
  distinctCount?: Maybe<CompanyChannelMediaDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyChannelMedia` objects. */
  min?: Maybe<CompanyChannelMediaMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyChannelMedia` objects. */
  max?: Maybe<CompanyChannelMediaMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyChannelMedia` objects. */
  average?: Maybe<CompanyChannelMediaAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyChannelMedia` objects. */
  stddevSample?: Maybe<CompanyChannelMediaStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyChannelMedia` objects. */
  stddevPopulation?: Maybe<CompanyChannelMediaStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyChannelMedia` objects. */
  varianceSample?: Maybe<CompanyChannelMediaVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyChannelMedia` objects. */
  variancePopulation?: Maybe<CompanyChannelMediaVariancePopulationAggregateFilter>;
};

/** The `companyChannelMedia` to be created by this mutation. */
export type CompanyChannelMediaAssigneeIdFkeyCompanyChannelMediaCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  assigneeId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  companyChannelToCompanyChannelId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInput>;
  mediaToMediaId?: Maybe<CompanyChannelMediaMediaIdFkeyInput>;
  userToAssigneeId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInput>;
};

/** Input for the nested mutation of `user` in the `CompanyChannelMediaInput` mutation. */
export type CompanyChannelMediaAssigneeIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `companyChannelMedia` in the `UserInput` mutation. */
export type CompanyChannelMediaAssigneeIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyChannelMedia` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaPkeyConnect>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  connectByCompanyChannelIdAndMediaId?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaUniqueConnect>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyChannelMediaNodeIdConnect>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaPkeyDelete>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  deleteByCompanyChannelIdAndMediaId?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaUniqueDelete>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyChannelMediaNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyChannelMedia` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyUsingCompanyChannelMediaPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyChannelMedia` for the far side of the relationship. */
  updateByCompanyChannelIdAndMediaId?: Maybe<Array<CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyUsingCompanyChannelMediaUniqueUpdate>>;
  /** The primary key(s) and patch data for `companyChannelMedia` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyNodeIdUpdate>>;
  /** A `CompanyChannelMediaInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyChannelMediaAssigneeIdFkeyCompanyChannelMediaCreateInput>>;
};

export type CompanyChannelMediaAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  mediaId?: Maybe<BigFloatFilter>;
  assigneeId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelMediaAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of assigneeId across the matching connection */
  assigneeId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Media` values, with data from `CompanyChannelMedia`. */
export type CompanyChannelMediaByCompanyChannelMediaCompanyChannelIdAndMediaIdManyToManyConnection = {
  /** A list of `Media` objects. */
  nodes: Array<Media>;
  /** A list of edges which contains the `Media`, info from the `CompanyChannelMedia`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelMediaByCompanyChannelMediaCompanyChannelIdAndMediaIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Media` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MediaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MediaAggregates>>;
};


/** A connection to a list of `Media` values, with data from `CompanyChannelMedia`. */
export type CompanyChannelMediaByCompanyChannelMediaCompanyChannelIdAndMediaIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<MediaGroupBy>;
  having?: Maybe<MediaHavingInput>;
};

/** A `Media` edge in the connection, with data from `CompanyChannelMedia`. */
export type CompanyChannelMediaByCompanyChannelMediaCompanyChannelIdAndMediaIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Media` at the end of the edge. */
  node: Media;
  id: Scalars['Int'];
  assigneeId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
};

/** The `companyChannel` to be created by this mutation. */
export type CompanyChannelMediaCompanyChannelIdFkeyCompanyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** The `companyChannelMedia` to be created by this mutation. */
export type CompanyChannelMediaCompanyChannelIdFkeyCompanyChannelMediaCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  assigneeId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  companyChannelToCompanyChannelId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInput>;
  mediaToMediaId?: Maybe<CompanyChannelMediaMediaIdFkeyInput>;
  userToAssigneeId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInput>;
};

/** Input for the nested mutation of `companyChannel` in the `CompanyChannelMediaInput` mutation. */
export type CompanyChannelMediaCompanyChannelIdFkeyInput = {
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectById?: Maybe<CompanyChannelCompanyChannelPkeyConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByCompanyIdAndNameAndChannelId?: Maybe<CompanyChannelUnqCompanyChannelConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyChannelNodeIdConnect>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateById?: Maybe<CompanyChannelOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByCompanyIdAndNameAndChannelId?: Maybe<CompanyChannelOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyUsingUnqCompanyChannelUpdate>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyNodeIdUpdate>;
  /** A `CompanyChannelInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyCompanyChannelCreateInput>;
};

/** Input for the nested mutation of `companyChannelMedia` in the `CompanyChannelInput` mutation. */
export type CompanyChannelMediaCompanyChannelIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyChannelMedia` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaPkeyConnect>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  connectByCompanyChannelIdAndMediaId?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaUniqueConnect>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyChannelMediaNodeIdConnect>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaPkeyDelete>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  deleteByCompanyChannelIdAndMediaId?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaUniqueDelete>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyChannelMediaNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyChannelMedia` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyUsingCompanyChannelMediaPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyChannelMedia` for the far side of the relationship. */
  updateByCompanyChannelIdAndMediaId?: Maybe<Array<CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyUsingCompanyChannelMediaUniqueUpdate>>;
  /** The primary key(s) and patch data for `companyChannelMedia` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyChannelOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyNodeIdUpdate>>;
  /** A `CompanyChannelMediaInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyChannelMediaCompanyChannelIdFkeyCompanyChannelMediaCreateInput>>;
};

/** The fields on `companyChannelMedia` to look up the row to connect. */
export type CompanyChannelMediaCompanyChannelMediaPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyChannelMedia` to look up the row to delete. */
export type CompanyChannelMediaCompanyChannelMediaPkeyDelete = {
  id: Scalars['Int'];
};

/** The fields on `companyChannelMedia` to look up the row to connect. */
export type CompanyChannelMediaCompanyChannelMediaUniqueConnect = {
  companyChannelId: Scalars['Int'];
  mediaId: Scalars['Int'];
};

/** The fields on `companyChannelMedia` to look up the row to delete. */
export type CompanyChannelMediaCompanyChannelMediaUniqueDelete = {
  companyChannelId: Scalars['Int'];
  mediaId: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyChannelMedia` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CompanyChannelMediaCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyChannelId` field. */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `mediaId` field. */
  mediaId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `assigneeId` field. */
  assigneeId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `CompanyChannelMedia` values. */
export type CompanyChannelMediaConnection = {
  /** A list of `CompanyChannelMedia` objects. */
  nodes: Array<CompanyChannelMedia>;
  /** A list of edges which contains the `CompanyChannelMedia` and cursor to aid in pagination. */
  edges: Array<CompanyChannelMediaEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannelMedia` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelMediaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelMediaAggregates>>;
};


/** A connection to a list of `CompanyChannelMedia` values. */
export type CompanyChannelMediaConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelMediaGroupBy>;
  having?: Maybe<CompanyChannelMediaHavingInput>;
};

export type CompanyChannelMediaDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyChannelId?: Maybe<BigIntFilter>;
  mediaId?: Maybe<BigIntFilter>;
  assigneeId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  deletedAt?: Maybe<BigIntFilter>;
};

export type CompanyChannelMediaDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of assigneeId across the matching connection */
  assigneeId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
};

/** A `CompanyChannelMedia` edge in the connection. */
export type CompanyChannelMediaEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannelMedia` at the end of the edge. */
  node: CompanyChannelMedia;
};

/** A filter to be used against `CompanyChannelMedia` object types. All fields are combined with a logical ‘and.’ */
export type CompanyChannelMediaFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyChannelId` field. */
  companyChannelId?: Maybe<IntFilter>;
  /** Filter by the object’s `mediaId` field. */
  mediaId?: Maybe<IntFilter>;
  /** Filter by the object’s `assigneeId` field. */
  assigneeId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `companyChannel` relation. */
  companyChannel?: Maybe<CompanyChannelFilter>;
  /** A related `companyChannel` exists. */
  companyChannelExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `media` relation. */
  media?: Maybe<MediaFilter>;
  /** Filter by the object’s `assignee` relation. */
  assignee?: Maybe<UserFilter>;
  /** A related `assignee` exists. */
  assigneeExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyChannelMediaFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyChannelMediaFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyChannelMediaFilter>;
};

/** Grouping methods for `CompanyChannelMedia` for usage during aggregation. */
export enum CompanyChannelMediaGroupBy {
  CompanyChannelId = 'COMPANY_CHANNEL_ID',
  MediaId = 'MEDIA_ID',
  AssigneeId = 'ASSIGNEE_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  DeletedAt = 'DELETED_AT',
  DeletedAtTruncatedToHour = 'DELETED_AT_TRUNCATED_TO_HOUR',
  DeletedAtTruncatedToDay = 'DELETED_AT_TRUNCATED_TO_DAY'
}

export type CompanyChannelMediaHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  assigneeId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelMediaHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  assigneeId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyChannelMedia` aggregates. */
export type CompanyChannelMediaHavingInput = {
  AND?: Maybe<Array<CompanyChannelMediaHavingInput>>;
  OR?: Maybe<Array<CompanyChannelMediaHavingInput>>;
  sum?: Maybe<CompanyChannelMediaHavingSumInput>;
  distinctCount?: Maybe<CompanyChannelMediaHavingDistinctCountInput>;
  min?: Maybe<CompanyChannelMediaHavingMinInput>;
  max?: Maybe<CompanyChannelMediaHavingMaxInput>;
  average?: Maybe<CompanyChannelMediaHavingAverageInput>;
  stddevSample?: Maybe<CompanyChannelMediaHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyChannelMediaHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyChannelMediaHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyChannelMediaHavingVariancePopulationInput>;
};

export type CompanyChannelMediaHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  assigneeId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelMediaHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  assigneeId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelMediaHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  assigneeId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelMediaHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  assigneeId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelMediaHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  assigneeId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelMediaHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  assigneeId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelMediaHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  assigneeId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyChannelMedia` */
export type CompanyChannelMediaInput = {
  id?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  assigneeId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  companyChannelToCompanyChannelId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInput>;
  mediaToMediaId?: Maybe<CompanyChannelMediaMediaIdFkeyInput>;
  userToAssigneeId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInput>;
};

export type CompanyChannelMediaMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyChannelId?: Maybe<IntFilter>;
  mediaId?: Maybe<IntFilter>;
  assigneeId?: Maybe<IntFilter>;
};

export type CompanyChannelMediaMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Maximum of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['Int']>;
  /** Maximum of assigneeId across the matching connection */
  assigneeId?: Maybe<Scalars['Int']>;
};

/** The `companyChannelMedia` to be created by this mutation. */
export type CompanyChannelMediaMediaIdFkeyCompanyChannelMediaCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  assigneeId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  companyChannelToCompanyChannelId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInput>;
  mediaToMediaId?: Maybe<CompanyChannelMediaMediaIdFkeyInput>;
  userToAssigneeId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInput>;
};

/** Input for the nested mutation of `media` in the `CompanyChannelMediaInput` mutation. */
export type CompanyChannelMediaMediaIdFkeyInput = {
  /** The primary key(s) for `media` for the far side of the relationship. */
  connectById?: Maybe<MediaMediaPkeyConnect>;
  /** The primary key(s) for `media` for the far side of the relationship. */
  connectByNodeId?: Maybe<MediaNodeIdConnect>;
  /** The primary key(s) and patch data for `media` for the far side of the relationship. */
  updateById?: Maybe<MediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyUsingMediaPkeyUpdate>;
  /** The primary key(s) and patch data for `media` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `companyChannelMedia` in the `MediaInput` mutation. */
export type CompanyChannelMediaMediaIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyChannelMedia` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaPkeyConnect>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  connectByCompanyChannelIdAndMediaId?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaUniqueConnect>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyChannelMediaNodeIdConnect>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaPkeyDelete>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  deleteByCompanyChannelIdAndMediaId?: Maybe<Array<CompanyChannelMediaCompanyChannelMediaUniqueDelete>>;
  /** The primary key(s) for `companyChannelMedia` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyChannelMediaNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyChannelMedia` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyUsingCompanyChannelMediaPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyChannelMedia` for the far side of the relationship. */
  updateByCompanyChannelIdAndMediaId?: Maybe<Array<CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyUsingCompanyChannelMediaUniqueUpdate>>;
  /** The primary key(s) and patch data for `companyChannelMedia` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<MediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyNodeIdUpdate>>;
  /** A `CompanyChannelMediaInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyChannelMediaMediaIdFkeyCompanyChannelMediaCreateInput>>;
};

export type CompanyChannelMediaMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyChannelId?: Maybe<IntFilter>;
  mediaId?: Maybe<IntFilter>;
  assigneeId?: Maybe<IntFilter>;
};

export type CompanyChannelMediaMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Minimum of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['Int']>;
  /** Minimum of assigneeId across the matching connection */
  assigneeId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyChannelMediaNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyChannelMedia` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyChannelMediaNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyChannelMedia` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `companyChannelMedia` to look up the row to update. */
export type CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyUsingCompanyChannelMediaPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
  patch: UpdateCompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `companyChannelMedia` to look up the row to update. */
export type CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyUsingCompanyChannelMediaUniqueUpdate = {
  /** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
  patch: UpdateCompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyPatch;
  companyChannelId: Scalars['Int'];
  mediaId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** The fields on `companyChannelMedia` to look up the row to update. */
export type CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyUsingCompanyChannelMediaPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
  patch: UpdateCompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `companyChannelMedia` to look up the row to update. */
export type CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyUsingCompanyChannelMediaUniqueUpdate = {
  /** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
  patch: UpdateCompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyPatch;
  companyChannelId: Scalars['Int'];
  mediaId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `media` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `media` being updated. */
  patch: MediaPatch;
};

/** The fields on `companyChannelMedia` to look up the row to update. */
export type CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyUsingCompanyChannelMediaPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
  patch: UpdateCompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `companyChannelMedia` to look up the row to update. */
export type CompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyUsingCompanyChannelMediaUniqueUpdate = {
  /** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
  patch: UpdateCompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyPatch;
  companyChannelId: Scalars['Int'];
  mediaId: Scalars['Int'];
};

/** Methods to use when ordering `CompanyChannelMedia`. */
export enum CompanyChannelMediaOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyChannelIdAsc = 'COMPANY_CHANNEL_ID_ASC',
  CompanyChannelIdDesc = 'COMPANY_CHANNEL_ID_DESC',
  MediaIdAsc = 'MEDIA_ID_ASC',
  MediaIdDesc = 'MEDIA_ID_DESC',
  AssigneeIdAsc = 'ASSIGNEE_ID_ASC',
  AssigneeIdDesc = 'ASSIGNEE_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Represents an update to a `CompanyChannelMedia`. Fields that are set will be updated. */
export type CompanyChannelMediaPatch = {
  id?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  assigneeId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  companyChannelToCompanyChannelId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInput>;
  mediaToMediaId?: Maybe<CompanyChannelMediaMediaIdFkeyInput>;
  userToAssigneeId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInput>;
};

export type CompanyChannelMediaStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  mediaId?: Maybe<BigFloatFilter>;
  assigneeId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelMediaStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of assigneeId across the matching connection */
  assigneeId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannelMediaStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  mediaId?: Maybe<BigFloatFilter>;
  assigneeId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelMediaStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of assigneeId across the matching connection */
  assigneeId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannelMediaSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyChannelId?: Maybe<BigIntFilter>;
  mediaId?: Maybe<BigIntFilter>;
  assigneeId?: Maybe<BigIntFilter>;
};

export type CompanyChannelMediaSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyChannelId across the matching connection */
  companyChannelId: Scalars['BigInt'];
  /** Sum of mediaId across the matching connection */
  mediaId: Scalars['BigInt'];
  /** Sum of assigneeId across the matching connection */
  assigneeId: Scalars['BigInt'];
};

export type CompanyChannelMediaVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  mediaId?: Maybe<BigFloatFilter>;
  assigneeId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelMediaVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of assigneeId across the matching connection */
  assigneeId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannelMediaVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  mediaId?: Maybe<BigFloatFilter>;
  assigneeId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelMediaVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of assigneeId across the matching connection */
  assigneeId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannelMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  channelId?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyChannelMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of channelId across the matching connection */
  channelId?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `NeighborhoodView` values, with data from `Lead`. */
export type CompanyChannelNeighborhoodViewsByLeadCompanyChannelIdAndNeighborhoodViewIdManyToManyConnection = {
  /** A list of `NeighborhoodView` objects. */
  nodes: Array<NeighborhoodView>;
  /** A list of edges which contains the `NeighborhoodView`, info from the `Lead`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelNeighborhoodViewsByLeadCompanyChannelIdAndNeighborhoodViewIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NeighborhoodView` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NeighborhoodViewAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NeighborhoodViewAggregates>>;
};


/** A connection to a list of `NeighborhoodView` values, with data from `Lead`. */
export type CompanyChannelNeighborhoodViewsByLeadCompanyChannelIdAndNeighborhoodViewIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<NeighborhoodViewGroupBy>;
  having?: Maybe<NeighborhoodViewHavingInput>;
};

/** A `NeighborhoodView` edge in the connection, with data from `Lead`. */
export type CompanyChannelNeighborhoodViewsByLeadCompanyChannelIdAndNeighborhoodViewIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NeighborhoodView` at the end of the edge. */
  node: NeighborhoodView;
  /** Reads and enables pagination through a set of `Lead`. */
  leads: LeadsConnection;
};


/** A `NeighborhoodView` edge in the connection, with data from `Lead`. */
export type CompanyChannelNeighborhoodViewsByLeadCompanyChannelIdAndNeighborhoodViewIdManyToManyEdgeLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyChannelNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `channel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `channel` being updated. */
  patch: ChannelPatch;
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingCompanyChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingUnqCompanyChannelUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyPatch;
  companyId: Scalars['Int'];
  name: Scalars['String'];
  channelId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingUnqCompanyChannelUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  companyId: Scalars['Int'];
  name: Scalars['String'];
  channelId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannelMedia` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
  patch: CompanyChannelMediaPatch;
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyUsingUnqCompanyChannelUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyPatch;
  companyId: Scalars['Int'];
  name: Scalars['String'];
  channelId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `distributedLead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `distributedLead` being updated. */
  patch: DistributedLeadPatch;
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyUsingUnqCompanyChannelUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyPatch;
  companyId: Scalars['Int'];
  name: Scalars['String'];
  channelId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `distributorRelationship` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `distributorRelationship` being updated. */
  patch: DistributorRelationshipPatch;
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyUsingUnqCompanyChannelUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyPatch;
  companyId: Scalars['Int'];
  name: Scalars['String'];
  channelId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelOnFieldForFieldCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: FieldPatch;
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnFieldForFieldCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnFieldForFieldCompanyChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnFieldForFieldCompanyChannelIdFkeyUsingUnqCompanyChannelUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnFieldForFieldCompanyChannelIdFkeyPatch;
  companyId: Scalars['Int'];
  name: Scalars['String'];
  channelId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelOnLeadForLeadCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnLeadForLeadCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnLeadForLeadCompanyChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnLeadForLeadCompanyChannelIdFkeyUsingUnqCompanyChannelUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnLeadForLeadCompanyChannelIdFkeyPatch;
  companyId: Scalars['Int'];
  name: Scalars['String'];
  channelId: Scalars['Int'];
};

/** Represents an update to a `CompanyChannel`. Fields that are set will be updated. */
export type CompanyChannelPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

export type CompanyChannelStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannelStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannelSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  channelId?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
};

export type CompanyChannelSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of channelId across the matching connection */
  channelId: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyChannelMedia` object types. All fields are combined with a logical ‘and.’ */
export type CompanyChannelToManyCompanyChannelMediaFilter = {
  /** Every related `CompanyChannelMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyChannelMediaFilter>;
  /** Some related `CompanyChannelMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyChannelMediaFilter>;
  /** No related `CompanyChannelMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyChannelMediaFilter>;
  /** Aggregates across related `CompanyChannelMedia` match the filter criteria. */
  aggregates?: Maybe<CompanyChannelMediaAggregatesFilter>;
};

/** A filter to be used against many `DistributedLead` object types. All fields are combined with a logical ‘and.’ */
export type CompanyChannelToManyDistributedLeadFilter = {
  /** Every related `DistributedLead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<DistributedLeadFilter>;
  /** Some related `DistributedLead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<DistributedLeadFilter>;
  /** No related `DistributedLead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<DistributedLeadFilter>;
  /** Aggregates across related `DistributedLead` match the filter criteria. */
  aggregates?: Maybe<DistributedLeadAggregatesFilter>;
};

/** A filter to be used against many `DistributorRelationship` object types. All fields are combined with a logical ‘and.’ */
export type CompanyChannelToManyDistributorRelationshipFilter = {
  /** Every related `DistributorRelationship` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<DistributorRelationshipFilter>;
  /** Some related `DistributorRelationship` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<DistributorRelationshipFilter>;
  /** No related `DistributorRelationship` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<DistributorRelationshipFilter>;
  /** Aggregates across related `DistributorRelationship` match the filter criteria. */
  aggregates?: Maybe<DistributorRelationshipAggregatesFilter>;
};

/** A filter to be used against many `Field` object types. All fields are combined with a logical ‘and.’ */
export type CompanyChannelToManyFieldFilter = {
  /** Every related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldFilter>;
  /** Some related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldFilter>;
  /** No related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldFilter>;
  /** Aggregates across related `Field` match the filter criteria. */
  aggregates?: Maybe<FieldAggregatesFilter>;
};

/** A filter to be used against many `Lead` object types. All fields are combined with a logical ‘and.’ */
export type CompanyChannelToManyLeadFilter = {
  /** Every related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeadFilter>;
  /** Some related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeadFilter>;
  /** No related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeadFilter>;
  /** Aggregates across related `Lead` match the filter criteria. */
  aggregates?: Maybe<LeadAggregatesFilter>;
};

/** The fields on `companyChannel` to look up the row to connect. */
export type CompanyChannelUnqCompanyChannelConnect = {
  companyId: Scalars['Int'];
  name: Scalars['String'];
  channelId: Scalars['Int'];
};

/** A connection to a list of `User` values, with data from `CompanyChannelMedia`. */
export type CompanyChannelUsersByCompanyChannelMediaCompanyChannelIdAndAssigneeIdManyToManyConnection = {
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `CompanyChannelMedia`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelUsersByCompanyChannelMediaCompanyChannelIdAndAssigneeIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
};


/** A connection to a list of `User` values, with data from `CompanyChannelMedia`. */
export type CompanyChannelUsersByCompanyChannelMediaCompanyChannelIdAndAssigneeIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having?: Maybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `CompanyChannelMedia`. */
export type CompanyChannelUsersByCompanyChannelMediaCompanyChannelIdAndAssigneeIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `CompanyChannelMedia`. */
  companyChannelMediasByAssigneeId: CompanyChannelMediaConnection;
};


/** A `User` edge in the connection, with data from `CompanyChannelMedia`. */
export type CompanyChannelUsersByCompanyChannelMediaCompanyChannelIdAndAssigneeIdManyToManyEdgeCompanyChannelMediasByAssigneeIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelMediaOrderBy>>;
  condition?: Maybe<CompanyChannelMediaCondition>;
  filter?: Maybe<CompanyChannelMediaFilter>;
};

export type CompanyChannelVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannelVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Channel` values, with data from `CompanyChannel`. */
export type CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyConnection = {
  /** A list of `Channel` objects. */
  nodes: Array<Channel>;
  /** A list of edges which contains the `Channel`, info from the `CompanyChannel`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Channel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ChannelAggregates>>;
};


/** A connection to a list of `Channel` values, with data from `CompanyChannel`. */
export type CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ChannelGroupBy>;
  having?: Maybe<ChannelHavingInput>;
};

/** A `Channel` edge in the connection, with data from `CompanyChannel`. */
export type CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Channel` at the end of the edge. */
  node: Channel;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannels: CompanyChannelsConnection;
};


/** A `Channel` edge in the connection, with data from `CompanyChannel`. */
export type CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyEdgeCompanyChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};

/** A connection to a list of `CompanyChannel` values. */
export type CompanyChannelsConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel` and cursor to aid in pagination. */
  edges: Array<CompanyChannelsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values. */
export type CompanyChannelsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection. */
export type CompanyChannelsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
};

/** Methods to use when ordering `CompanyChannel`. */
export enum CompanyChannelsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  WidgetIdAsc = 'WIDGET_ID_ASC',
  WidgetIdDesc = 'WIDGET_ID_DESC',
  ChannelIdAsc = 'CHANNEL_ID_ASC',
  ChannelIdDesc = 'CHANNEL_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  LeadsCountAsc = 'LEADS_COUNT_ASC',
  LeadsCountDesc = 'LEADS_COUNT_DESC',
  LeadsSumIdAsc = 'LEADS_SUM_ID_ASC',
  LeadsSumIdDesc = 'LEADS_SUM_ID_DESC',
  LeadsSumCompanyIdAsc = 'LEADS_SUM_COMPANY_ID_ASC',
  LeadsSumCompanyIdDesc = 'LEADS_SUM_COMPANY_ID_DESC',
  LeadsSumCreatedAtAsc = 'LEADS_SUM_CREATED_AT_ASC',
  LeadsSumCreatedAtDesc = 'LEADS_SUM_CREATED_AT_DESC',
  LeadsSumFirstNameAsc = 'LEADS_SUM_FIRST_NAME_ASC',
  LeadsSumFirstNameDesc = 'LEADS_SUM_FIRST_NAME_DESC',
  LeadsSumLastNameAsc = 'LEADS_SUM_LAST_NAME_ASC',
  LeadsSumLastNameDesc = 'LEADS_SUM_LAST_NAME_DESC',
  LeadsSumDateOfBirthAsc = 'LEADS_SUM_DATE_OF_BIRTH_ASC',
  LeadsSumDateOfBirthDesc = 'LEADS_SUM_DATE_OF_BIRTH_DESC',
  LeadsSumStreetAsc = 'LEADS_SUM_STREET_ASC',
  LeadsSumStreetDesc = 'LEADS_SUM_STREET_DESC',
  LeadsSumStreetNumberAsc = 'LEADS_SUM_STREET_NUMBER_ASC',
  LeadsSumStreetNumberDesc = 'LEADS_SUM_STREET_NUMBER_DESC',
  LeadsSumUnitAsc = 'LEADS_SUM_UNIT_ASC',
  LeadsSumUnitDesc = 'LEADS_SUM_UNIT_DESC',
  LeadsSumCityAsc = 'LEADS_SUM_CITY_ASC',
  LeadsSumCityDesc = 'LEADS_SUM_CITY_DESC',
  LeadsSumCountryAsc = 'LEADS_SUM_COUNTRY_ASC',
  LeadsSumCountryDesc = 'LEADS_SUM_COUNTRY_DESC',
  LeadsSumPostalCodeAsc = 'LEADS_SUM_POSTAL_CODE_ASC',
  LeadsSumPostalCodeDesc = 'LEADS_SUM_POSTAL_CODE_DESC',
  LeadsSumProvinceCodeAsc = 'LEADS_SUM_PROVINCE_CODE_ASC',
  LeadsSumProvinceCodeDesc = 'LEADS_SUM_PROVINCE_CODE_DESC',
  LeadsSumEmailAddressAsc = 'LEADS_SUM_EMAIL_ADDRESS_ASC',
  LeadsSumEmailAddressDesc = 'LEADS_SUM_EMAIL_ADDRESS_DESC',
  LeadsSumPhoneNumberAsc = 'LEADS_SUM_PHONE_NUMBER_ASC',
  LeadsSumPhoneNumberDesc = 'LEADS_SUM_PHONE_NUMBER_DESC',
  LeadsSumSourceUrlAsc = 'LEADS_SUM_SOURCE_URL_ASC',
  LeadsSumSourceUrlDesc = 'LEADS_SUM_SOURCE_URL_DESC',
  LeadsSumTransactionKeyAsc = 'LEADS_SUM_TRANSACTION_KEY_ASC',
  LeadsSumTransactionKeyDesc = 'LEADS_SUM_TRANSACTION_KEY_DESC',
  LeadsSumLeadspediaIdAsc = 'LEADS_SUM_LEADSPEDIA_ID_ASC',
  LeadsSumLeadspediaIdDesc = 'LEADS_SUM_LEADSPEDIA_ID_DESC',
  LeadsSumUpdatedAtAsc = 'LEADS_SUM_UPDATED_AT_ASC',
  LeadsSumUpdatedAtDesc = 'LEADS_SUM_UPDATED_AT_DESC',
  LeadsSumSearchTextAsc = 'LEADS_SUM_SEARCH_TEXT_ASC',
  LeadsSumSearchTextDesc = 'LEADS_SUM_SEARCH_TEXT_DESC',
  LeadsSumCommentAsc = 'LEADS_SUM_COMMENT_ASC',
  LeadsSumCommentDesc = 'LEADS_SUM_COMMENT_DESC',
  LeadsSumStateAsc = 'LEADS_SUM_STATE_ASC',
  LeadsSumStateDesc = 'LEADS_SUM_STATE_DESC',
  LeadsSumReadAsc = 'LEADS_SUM_READ_ASC',
  LeadsSumReadDesc = 'LEADS_SUM_READ_DESC',
  LeadsSumExternalIdsAsc = 'LEADS_SUM_EXTERNAL_IDS_ASC',
  LeadsSumExternalIdsDesc = 'LEADS_SUM_EXTERNAL_IDS_DESC',
  LeadsSumMetaAsc = 'LEADS_SUM_META_ASC',
  LeadsSumMetaDesc = 'LEADS_SUM_META_DESC',
  LeadsSumAdditionalInformationAsc = 'LEADS_SUM_ADDITIONAL_INFORMATION_ASC',
  LeadsSumAdditionalInformationDesc = 'LEADS_SUM_ADDITIONAL_INFORMATION_DESC',
  LeadsSumPhoneVerifiedAtAsc = 'LEADS_SUM_PHONE_VERIFIED_AT_ASC',
  LeadsSumPhoneVerifiedAtDesc = 'LEADS_SUM_PHONE_VERIFIED_AT_DESC',
  LeadsSumIpAddressAsc = 'LEADS_SUM_IP_ADDRESS_ASC',
  LeadsSumIpAddressDesc = 'LEADS_SUM_IP_ADDRESS_DESC',
  LeadsSumClientIdAsc = 'LEADS_SUM_CLIENT_ID_ASC',
  LeadsSumClientIdDesc = 'LEADS_SUM_CLIENT_ID_DESC',
  LeadsSumDeletedAsc = 'LEADS_SUM_DELETED_ASC',
  LeadsSumDeletedDesc = 'LEADS_SUM_DELETED_DESC',
  LeadsSumPriorityAsc = 'LEADS_SUM_PRIORITY_ASC',
  LeadsSumPriorityDesc = 'LEADS_SUM_PRIORITY_DESC',
  LeadsSumAssigneeAsc = 'LEADS_SUM_ASSIGNEE_ASC',
  LeadsSumAssigneeDesc = 'LEADS_SUM_ASSIGNEE_DESC',
  LeadsSumShortIdAsc = 'LEADS_SUM_SHORT_ID_ASC',
  LeadsSumShortIdDesc = 'LEADS_SUM_SHORT_ID_DESC',
  LeadsSumCompanyChannelIdAsc = 'LEADS_SUM_COMPANY_CHANNEL_ID_ASC',
  LeadsSumCompanyChannelIdDesc = 'LEADS_SUM_COMPANY_CHANNEL_ID_DESC',
  LeadsSumNeighborhoodViewIdAsc = 'LEADS_SUM_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsSumNeighborhoodViewIdDesc = 'LEADS_SUM_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsSumMaritalStatusAsc = 'LEADS_SUM_MARITAL_STATUS_ASC',
  LeadsSumMaritalStatusDesc = 'LEADS_SUM_MARITAL_STATUS_DESC',
  LeadsSumDesiredVehicleAsc = 'LEADS_SUM_DESIRED_VEHICLE_ASC',
  LeadsSumDesiredVehicleDesc = 'LEADS_SUM_DESIRED_VEHICLE_DESC',
  LeadsSumJobTitleAsc = 'LEADS_SUM_JOB_TITLE_ASC',
  LeadsSumJobTitleDesc = 'LEADS_SUM_JOB_TITLE_DESC',
  LeadsSumIncomePeriodAsc = 'LEADS_SUM_INCOME_PERIOD_ASC',
  LeadsSumIncomePeriodDesc = 'LEADS_SUM_INCOME_PERIOD_DESC',
  LeadsSumSalaryHourlyAsc = 'LEADS_SUM_SALARY_HOURLY_ASC',
  LeadsSumSalaryHourlyDesc = 'LEADS_SUM_SALARY_HOURLY_DESC',
  LeadsSumIncomeSourceAsc = 'LEADS_SUM_INCOME_SOURCE_ASC',
  LeadsSumIncomeSourceDesc = 'LEADS_SUM_INCOME_SOURCE_DESC',
  LeadsSumIncomeTimeAsc = 'LEADS_SUM_INCOME_TIME_ASC',
  LeadsSumIncomeTimeDesc = 'LEADS_SUM_INCOME_TIME_DESC',
  LeadsSumPreferredContactMethodAsc = 'LEADS_SUM_PREFERRED_CONTACT_METHOD_ASC',
  LeadsSumPreferredContactMethodDesc = 'LEADS_SUM_PREFERRED_CONTACT_METHOD_DESC',
  LeadsSumCompanyNameAsc = 'LEADS_SUM_COMPANY_NAME_ASC',
  LeadsSumCompanyNameDesc = 'LEADS_SUM_COMPANY_NAME_DESC',
  LeadsSumCreditConcernAsc = 'LEADS_SUM_CREDIT_CONCERN_ASC',
  LeadsSumCreditConcernDesc = 'LEADS_SUM_CREDIT_CONCERN_DESC',
  LeadsSumResidenceTimeAsc = 'LEADS_SUM_RESIDENCE_TIME_ASC',
  LeadsSumResidenceTimeDesc = 'LEADS_SUM_RESIDENCE_TIME_DESC',
  LeadsSumRentOrOwnAsc = 'LEADS_SUM_RENT_OR_OWN_ASC',
  LeadsSumRentOrOwnDesc = 'LEADS_SUM_RENT_OR_OWN_DESC',
  LeadsSumEmploymentStatusAsc = 'LEADS_SUM_EMPLOYMENT_STATUS_ASC',
  LeadsSumEmploymentStatusDesc = 'LEADS_SUM_EMPLOYMENT_STATUS_DESC',
  LeadsSumIncomeAmountAsc = 'LEADS_SUM_INCOME_AMOUNT_ASC',
  LeadsSumIncomeAmountDesc = 'LEADS_SUM_INCOME_AMOUNT_DESC',
  LeadsSumMonthlyHomePaymentAsc = 'LEADS_SUM_MONTHLY_HOME_PAYMENT_ASC',
  LeadsSumMonthlyHomePaymentDesc = 'LEADS_SUM_MONTHLY_HOME_PAYMENT_DESC',
  LeadsSumSmsConsentAsc = 'LEADS_SUM_SMS_CONSENT_ASC',
  LeadsSumSmsConsentDesc = 'LEADS_SUM_SMS_CONSENT_DESC',
  LeadsSumIncomeTimeYearsAsc = 'LEADS_SUM_INCOME_TIME_YEARS_ASC',
  LeadsSumIncomeTimeYearsDesc = 'LEADS_SUM_INCOME_TIME_YEARS_DESC',
  LeadsSumIncomeTimeMonthsAsc = 'LEADS_SUM_INCOME_TIME_MONTHS_ASC',
  LeadsSumIncomeTimeMonthsDesc = 'LEADS_SUM_INCOME_TIME_MONTHS_DESC',
  LeadsSumResidenceTimeYearsAsc = 'LEADS_SUM_RESIDENCE_TIME_YEARS_ASC',
  LeadsSumResidenceTimeYearsDesc = 'LEADS_SUM_RESIDENCE_TIME_YEARS_DESC',
  LeadsSumResidenceTimeMonthsAsc = 'LEADS_SUM_RESIDENCE_TIME_MONTHS_ASC',
  LeadsSumResidenceTimeMonthsDesc = 'LEADS_SUM_RESIDENCE_TIME_MONTHS_DESC',
  LeadsDistinctCountIdAsc = 'LEADS_DISTINCT_COUNT_ID_ASC',
  LeadsDistinctCountIdDesc = 'LEADS_DISTINCT_COUNT_ID_DESC',
  LeadsDistinctCountCompanyIdAsc = 'LEADS_DISTINCT_COUNT_COMPANY_ID_ASC',
  LeadsDistinctCountCompanyIdDesc = 'LEADS_DISTINCT_COUNT_COMPANY_ID_DESC',
  LeadsDistinctCountCreatedAtAsc = 'LEADS_DISTINCT_COUNT_CREATED_AT_ASC',
  LeadsDistinctCountCreatedAtDesc = 'LEADS_DISTINCT_COUNT_CREATED_AT_DESC',
  LeadsDistinctCountFirstNameAsc = 'LEADS_DISTINCT_COUNT_FIRST_NAME_ASC',
  LeadsDistinctCountFirstNameDesc = 'LEADS_DISTINCT_COUNT_FIRST_NAME_DESC',
  LeadsDistinctCountLastNameAsc = 'LEADS_DISTINCT_COUNT_LAST_NAME_ASC',
  LeadsDistinctCountLastNameDesc = 'LEADS_DISTINCT_COUNT_LAST_NAME_DESC',
  LeadsDistinctCountDateOfBirthAsc = 'LEADS_DISTINCT_COUNT_DATE_OF_BIRTH_ASC',
  LeadsDistinctCountDateOfBirthDesc = 'LEADS_DISTINCT_COUNT_DATE_OF_BIRTH_DESC',
  LeadsDistinctCountStreetAsc = 'LEADS_DISTINCT_COUNT_STREET_ASC',
  LeadsDistinctCountStreetDesc = 'LEADS_DISTINCT_COUNT_STREET_DESC',
  LeadsDistinctCountStreetNumberAsc = 'LEADS_DISTINCT_COUNT_STREET_NUMBER_ASC',
  LeadsDistinctCountStreetNumberDesc = 'LEADS_DISTINCT_COUNT_STREET_NUMBER_DESC',
  LeadsDistinctCountUnitAsc = 'LEADS_DISTINCT_COUNT_UNIT_ASC',
  LeadsDistinctCountUnitDesc = 'LEADS_DISTINCT_COUNT_UNIT_DESC',
  LeadsDistinctCountCityAsc = 'LEADS_DISTINCT_COUNT_CITY_ASC',
  LeadsDistinctCountCityDesc = 'LEADS_DISTINCT_COUNT_CITY_DESC',
  LeadsDistinctCountCountryAsc = 'LEADS_DISTINCT_COUNT_COUNTRY_ASC',
  LeadsDistinctCountCountryDesc = 'LEADS_DISTINCT_COUNT_COUNTRY_DESC',
  LeadsDistinctCountPostalCodeAsc = 'LEADS_DISTINCT_COUNT_POSTAL_CODE_ASC',
  LeadsDistinctCountPostalCodeDesc = 'LEADS_DISTINCT_COUNT_POSTAL_CODE_DESC',
  LeadsDistinctCountProvinceCodeAsc = 'LEADS_DISTINCT_COUNT_PROVINCE_CODE_ASC',
  LeadsDistinctCountProvinceCodeDesc = 'LEADS_DISTINCT_COUNT_PROVINCE_CODE_DESC',
  LeadsDistinctCountEmailAddressAsc = 'LEADS_DISTINCT_COUNT_EMAIL_ADDRESS_ASC',
  LeadsDistinctCountEmailAddressDesc = 'LEADS_DISTINCT_COUNT_EMAIL_ADDRESS_DESC',
  LeadsDistinctCountPhoneNumberAsc = 'LEADS_DISTINCT_COUNT_PHONE_NUMBER_ASC',
  LeadsDistinctCountPhoneNumberDesc = 'LEADS_DISTINCT_COUNT_PHONE_NUMBER_DESC',
  LeadsDistinctCountSourceUrlAsc = 'LEADS_DISTINCT_COUNT_SOURCE_URL_ASC',
  LeadsDistinctCountSourceUrlDesc = 'LEADS_DISTINCT_COUNT_SOURCE_URL_DESC',
  LeadsDistinctCountTransactionKeyAsc = 'LEADS_DISTINCT_COUNT_TRANSACTION_KEY_ASC',
  LeadsDistinctCountTransactionKeyDesc = 'LEADS_DISTINCT_COUNT_TRANSACTION_KEY_DESC',
  LeadsDistinctCountLeadspediaIdAsc = 'LEADS_DISTINCT_COUNT_LEADSPEDIA_ID_ASC',
  LeadsDistinctCountLeadspediaIdDesc = 'LEADS_DISTINCT_COUNT_LEADSPEDIA_ID_DESC',
  LeadsDistinctCountUpdatedAtAsc = 'LEADS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LeadsDistinctCountUpdatedAtDesc = 'LEADS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LeadsDistinctCountSearchTextAsc = 'LEADS_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  LeadsDistinctCountSearchTextDesc = 'LEADS_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  LeadsDistinctCountCommentAsc = 'LEADS_DISTINCT_COUNT_COMMENT_ASC',
  LeadsDistinctCountCommentDesc = 'LEADS_DISTINCT_COUNT_COMMENT_DESC',
  LeadsDistinctCountStateAsc = 'LEADS_DISTINCT_COUNT_STATE_ASC',
  LeadsDistinctCountStateDesc = 'LEADS_DISTINCT_COUNT_STATE_DESC',
  LeadsDistinctCountReadAsc = 'LEADS_DISTINCT_COUNT_READ_ASC',
  LeadsDistinctCountReadDesc = 'LEADS_DISTINCT_COUNT_READ_DESC',
  LeadsDistinctCountExternalIdsAsc = 'LEADS_DISTINCT_COUNT_EXTERNAL_IDS_ASC',
  LeadsDistinctCountExternalIdsDesc = 'LEADS_DISTINCT_COUNT_EXTERNAL_IDS_DESC',
  LeadsDistinctCountMetaAsc = 'LEADS_DISTINCT_COUNT_META_ASC',
  LeadsDistinctCountMetaDesc = 'LEADS_DISTINCT_COUNT_META_DESC',
  LeadsDistinctCountAdditionalInformationAsc = 'LEADS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_ASC',
  LeadsDistinctCountAdditionalInformationDesc = 'LEADS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_DESC',
  LeadsDistinctCountPhoneVerifiedAtAsc = 'LEADS_DISTINCT_COUNT_PHONE_VERIFIED_AT_ASC',
  LeadsDistinctCountPhoneVerifiedAtDesc = 'LEADS_DISTINCT_COUNT_PHONE_VERIFIED_AT_DESC',
  LeadsDistinctCountIpAddressAsc = 'LEADS_DISTINCT_COUNT_IP_ADDRESS_ASC',
  LeadsDistinctCountIpAddressDesc = 'LEADS_DISTINCT_COUNT_IP_ADDRESS_DESC',
  LeadsDistinctCountClientIdAsc = 'LEADS_DISTINCT_COUNT_CLIENT_ID_ASC',
  LeadsDistinctCountClientIdDesc = 'LEADS_DISTINCT_COUNT_CLIENT_ID_DESC',
  LeadsDistinctCountDeletedAsc = 'LEADS_DISTINCT_COUNT_DELETED_ASC',
  LeadsDistinctCountDeletedDesc = 'LEADS_DISTINCT_COUNT_DELETED_DESC',
  LeadsDistinctCountPriorityAsc = 'LEADS_DISTINCT_COUNT_PRIORITY_ASC',
  LeadsDistinctCountPriorityDesc = 'LEADS_DISTINCT_COUNT_PRIORITY_DESC',
  LeadsDistinctCountAssigneeAsc = 'LEADS_DISTINCT_COUNT_ASSIGNEE_ASC',
  LeadsDistinctCountAssigneeDesc = 'LEADS_DISTINCT_COUNT_ASSIGNEE_DESC',
  LeadsDistinctCountShortIdAsc = 'LEADS_DISTINCT_COUNT_SHORT_ID_ASC',
  LeadsDistinctCountShortIdDesc = 'LEADS_DISTINCT_COUNT_SHORT_ID_DESC',
  LeadsDistinctCountCompanyChannelIdAsc = 'LEADS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  LeadsDistinctCountCompanyChannelIdDesc = 'LEADS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  LeadsDistinctCountNeighborhoodViewIdAsc = 'LEADS_DISTINCT_COUNT_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsDistinctCountNeighborhoodViewIdDesc = 'LEADS_DISTINCT_COUNT_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsDistinctCountMaritalStatusAsc = 'LEADS_DISTINCT_COUNT_MARITAL_STATUS_ASC',
  LeadsDistinctCountMaritalStatusDesc = 'LEADS_DISTINCT_COUNT_MARITAL_STATUS_DESC',
  LeadsDistinctCountDesiredVehicleAsc = 'LEADS_DISTINCT_COUNT_DESIRED_VEHICLE_ASC',
  LeadsDistinctCountDesiredVehicleDesc = 'LEADS_DISTINCT_COUNT_DESIRED_VEHICLE_DESC',
  LeadsDistinctCountJobTitleAsc = 'LEADS_DISTINCT_COUNT_JOB_TITLE_ASC',
  LeadsDistinctCountJobTitleDesc = 'LEADS_DISTINCT_COUNT_JOB_TITLE_DESC',
  LeadsDistinctCountIncomePeriodAsc = 'LEADS_DISTINCT_COUNT_INCOME_PERIOD_ASC',
  LeadsDistinctCountIncomePeriodDesc = 'LEADS_DISTINCT_COUNT_INCOME_PERIOD_DESC',
  LeadsDistinctCountSalaryHourlyAsc = 'LEADS_DISTINCT_COUNT_SALARY_HOURLY_ASC',
  LeadsDistinctCountSalaryHourlyDesc = 'LEADS_DISTINCT_COUNT_SALARY_HOURLY_DESC',
  LeadsDistinctCountIncomeSourceAsc = 'LEADS_DISTINCT_COUNT_INCOME_SOURCE_ASC',
  LeadsDistinctCountIncomeSourceDesc = 'LEADS_DISTINCT_COUNT_INCOME_SOURCE_DESC',
  LeadsDistinctCountIncomeTimeAsc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_ASC',
  LeadsDistinctCountIncomeTimeDesc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_DESC',
  LeadsDistinctCountPreferredContactMethodAsc = 'LEADS_DISTINCT_COUNT_PREFERRED_CONTACT_METHOD_ASC',
  LeadsDistinctCountPreferredContactMethodDesc = 'LEADS_DISTINCT_COUNT_PREFERRED_CONTACT_METHOD_DESC',
  LeadsDistinctCountCompanyNameAsc = 'LEADS_DISTINCT_COUNT_COMPANY_NAME_ASC',
  LeadsDistinctCountCompanyNameDesc = 'LEADS_DISTINCT_COUNT_COMPANY_NAME_DESC',
  LeadsDistinctCountCreditConcernAsc = 'LEADS_DISTINCT_COUNT_CREDIT_CONCERN_ASC',
  LeadsDistinctCountCreditConcernDesc = 'LEADS_DISTINCT_COUNT_CREDIT_CONCERN_DESC',
  LeadsDistinctCountResidenceTimeAsc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_ASC',
  LeadsDistinctCountResidenceTimeDesc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_DESC',
  LeadsDistinctCountRentOrOwnAsc = 'LEADS_DISTINCT_COUNT_RENT_OR_OWN_ASC',
  LeadsDistinctCountRentOrOwnDesc = 'LEADS_DISTINCT_COUNT_RENT_OR_OWN_DESC',
  LeadsDistinctCountEmploymentStatusAsc = 'LEADS_DISTINCT_COUNT_EMPLOYMENT_STATUS_ASC',
  LeadsDistinctCountEmploymentStatusDesc = 'LEADS_DISTINCT_COUNT_EMPLOYMENT_STATUS_DESC',
  LeadsDistinctCountIncomeAmountAsc = 'LEADS_DISTINCT_COUNT_INCOME_AMOUNT_ASC',
  LeadsDistinctCountIncomeAmountDesc = 'LEADS_DISTINCT_COUNT_INCOME_AMOUNT_DESC',
  LeadsDistinctCountMonthlyHomePaymentAsc = 'LEADS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_ASC',
  LeadsDistinctCountMonthlyHomePaymentDesc = 'LEADS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_DESC',
  LeadsDistinctCountSmsConsentAsc = 'LEADS_DISTINCT_COUNT_SMS_CONSENT_ASC',
  LeadsDistinctCountSmsConsentDesc = 'LEADS_DISTINCT_COUNT_SMS_CONSENT_DESC',
  LeadsDistinctCountIncomeTimeYearsAsc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_YEARS_ASC',
  LeadsDistinctCountIncomeTimeYearsDesc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_YEARS_DESC',
  LeadsDistinctCountIncomeTimeMonthsAsc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_MONTHS_ASC',
  LeadsDistinctCountIncomeTimeMonthsDesc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_MONTHS_DESC',
  LeadsDistinctCountResidenceTimeYearsAsc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_YEARS_ASC',
  LeadsDistinctCountResidenceTimeYearsDesc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_YEARS_DESC',
  LeadsDistinctCountResidenceTimeMonthsAsc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_MONTHS_ASC',
  LeadsDistinctCountResidenceTimeMonthsDesc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_MONTHS_DESC',
  LeadsMinIdAsc = 'LEADS_MIN_ID_ASC',
  LeadsMinIdDesc = 'LEADS_MIN_ID_DESC',
  LeadsMinCompanyIdAsc = 'LEADS_MIN_COMPANY_ID_ASC',
  LeadsMinCompanyIdDesc = 'LEADS_MIN_COMPANY_ID_DESC',
  LeadsMinCreatedAtAsc = 'LEADS_MIN_CREATED_AT_ASC',
  LeadsMinCreatedAtDesc = 'LEADS_MIN_CREATED_AT_DESC',
  LeadsMinFirstNameAsc = 'LEADS_MIN_FIRST_NAME_ASC',
  LeadsMinFirstNameDesc = 'LEADS_MIN_FIRST_NAME_DESC',
  LeadsMinLastNameAsc = 'LEADS_MIN_LAST_NAME_ASC',
  LeadsMinLastNameDesc = 'LEADS_MIN_LAST_NAME_DESC',
  LeadsMinDateOfBirthAsc = 'LEADS_MIN_DATE_OF_BIRTH_ASC',
  LeadsMinDateOfBirthDesc = 'LEADS_MIN_DATE_OF_BIRTH_DESC',
  LeadsMinStreetAsc = 'LEADS_MIN_STREET_ASC',
  LeadsMinStreetDesc = 'LEADS_MIN_STREET_DESC',
  LeadsMinStreetNumberAsc = 'LEADS_MIN_STREET_NUMBER_ASC',
  LeadsMinStreetNumberDesc = 'LEADS_MIN_STREET_NUMBER_DESC',
  LeadsMinUnitAsc = 'LEADS_MIN_UNIT_ASC',
  LeadsMinUnitDesc = 'LEADS_MIN_UNIT_DESC',
  LeadsMinCityAsc = 'LEADS_MIN_CITY_ASC',
  LeadsMinCityDesc = 'LEADS_MIN_CITY_DESC',
  LeadsMinCountryAsc = 'LEADS_MIN_COUNTRY_ASC',
  LeadsMinCountryDesc = 'LEADS_MIN_COUNTRY_DESC',
  LeadsMinPostalCodeAsc = 'LEADS_MIN_POSTAL_CODE_ASC',
  LeadsMinPostalCodeDesc = 'LEADS_MIN_POSTAL_CODE_DESC',
  LeadsMinProvinceCodeAsc = 'LEADS_MIN_PROVINCE_CODE_ASC',
  LeadsMinProvinceCodeDesc = 'LEADS_MIN_PROVINCE_CODE_DESC',
  LeadsMinEmailAddressAsc = 'LEADS_MIN_EMAIL_ADDRESS_ASC',
  LeadsMinEmailAddressDesc = 'LEADS_MIN_EMAIL_ADDRESS_DESC',
  LeadsMinPhoneNumberAsc = 'LEADS_MIN_PHONE_NUMBER_ASC',
  LeadsMinPhoneNumberDesc = 'LEADS_MIN_PHONE_NUMBER_DESC',
  LeadsMinSourceUrlAsc = 'LEADS_MIN_SOURCE_URL_ASC',
  LeadsMinSourceUrlDesc = 'LEADS_MIN_SOURCE_URL_DESC',
  LeadsMinTransactionKeyAsc = 'LEADS_MIN_TRANSACTION_KEY_ASC',
  LeadsMinTransactionKeyDesc = 'LEADS_MIN_TRANSACTION_KEY_DESC',
  LeadsMinLeadspediaIdAsc = 'LEADS_MIN_LEADSPEDIA_ID_ASC',
  LeadsMinLeadspediaIdDesc = 'LEADS_MIN_LEADSPEDIA_ID_DESC',
  LeadsMinUpdatedAtAsc = 'LEADS_MIN_UPDATED_AT_ASC',
  LeadsMinUpdatedAtDesc = 'LEADS_MIN_UPDATED_AT_DESC',
  LeadsMinSearchTextAsc = 'LEADS_MIN_SEARCH_TEXT_ASC',
  LeadsMinSearchTextDesc = 'LEADS_MIN_SEARCH_TEXT_DESC',
  LeadsMinCommentAsc = 'LEADS_MIN_COMMENT_ASC',
  LeadsMinCommentDesc = 'LEADS_MIN_COMMENT_DESC',
  LeadsMinStateAsc = 'LEADS_MIN_STATE_ASC',
  LeadsMinStateDesc = 'LEADS_MIN_STATE_DESC',
  LeadsMinReadAsc = 'LEADS_MIN_READ_ASC',
  LeadsMinReadDesc = 'LEADS_MIN_READ_DESC',
  LeadsMinExternalIdsAsc = 'LEADS_MIN_EXTERNAL_IDS_ASC',
  LeadsMinExternalIdsDesc = 'LEADS_MIN_EXTERNAL_IDS_DESC',
  LeadsMinMetaAsc = 'LEADS_MIN_META_ASC',
  LeadsMinMetaDesc = 'LEADS_MIN_META_DESC',
  LeadsMinAdditionalInformationAsc = 'LEADS_MIN_ADDITIONAL_INFORMATION_ASC',
  LeadsMinAdditionalInformationDesc = 'LEADS_MIN_ADDITIONAL_INFORMATION_DESC',
  LeadsMinPhoneVerifiedAtAsc = 'LEADS_MIN_PHONE_VERIFIED_AT_ASC',
  LeadsMinPhoneVerifiedAtDesc = 'LEADS_MIN_PHONE_VERIFIED_AT_DESC',
  LeadsMinIpAddressAsc = 'LEADS_MIN_IP_ADDRESS_ASC',
  LeadsMinIpAddressDesc = 'LEADS_MIN_IP_ADDRESS_DESC',
  LeadsMinClientIdAsc = 'LEADS_MIN_CLIENT_ID_ASC',
  LeadsMinClientIdDesc = 'LEADS_MIN_CLIENT_ID_DESC',
  LeadsMinDeletedAsc = 'LEADS_MIN_DELETED_ASC',
  LeadsMinDeletedDesc = 'LEADS_MIN_DELETED_DESC',
  LeadsMinPriorityAsc = 'LEADS_MIN_PRIORITY_ASC',
  LeadsMinPriorityDesc = 'LEADS_MIN_PRIORITY_DESC',
  LeadsMinAssigneeAsc = 'LEADS_MIN_ASSIGNEE_ASC',
  LeadsMinAssigneeDesc = 'LEADS_MIN_ASSIGNEE_DESC',
  LeadsMinShortIdAsc = 'LEADS_MIN_SHORT_ID_ASC',
  LeadsMinShortIdDesc = 'LEADS_MIN_SHORT_ID_DESC',
  LeadsMinCompanyChannelIdAsc = 'LEADS_MIN_COMPANY_CHANNEL_ID_ASC',
  LeadsMinCompanyChannelIdDesc = 'LEADS_MIN_COMPANY_CHANNEL_ID_DESC',
  LeadsMinNeighborhoodViewIdAsc = 'LEADS_MIN_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsMinNeighborhoodViewIdDesc = 'LEADS_MIN_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsMinMaritalStatusAsc = 'LEADS_MIN_MARITAL_STATUS_ASC',
  LeadsMinMaritalStatusDesc = 'LEADS_MIN_MARITAL_STATUS_DESC',
  LeadsMinDesiredVehicleAsc = 'LEADS_MIN_DESIRED_VEHICLE_ASC',
  LeadsMinDesiredVehicleDesc = 'LEADS_MIN_DESIRED_VEHICLE_DESC',
  LeadsMinJobTitleAsc = 'LEADS_MIN_JOB_TITLE_ASC',
  LeadsMinJobTitleDesc = 'LEADS_MIN_JOB_TITLE_DESC',
  LeadsMinIncomePeriodAsc = 'LEADS_MIN_INCOME_PERIOD_ASC',
  LeadsMinIncomePeriodDesc = 'LEADS_MIN_INCOME_PERIOD_DESC',
  LeadsMinSalaryHourlyAsc = 'LEADS_MIN_SALARY_HOURLY_ASC',
  LeadsMinSalaryHourlyDesc = 'LEADS_MIN_SALARY_HOURLY_DESC',
  LeadsMinIncomeSourceAsc = 'LEADS_MIN_INCOME_SOURCE_ASC',
  LeadsMinIncomeSourceDesc = 'LEADS_MIN_INCOME_SOURCE_DESC',
  LeadsMinIncomeTimeAsc = 'LEADS_MIN_INCOME_TIME_ASC',
  LeadsMinIncomeTimeDesc = 'LEADS_MIN_INCOME_TIME_DESC',
  LeadsMinPreferredContactMethodAsc = 'LEADS_MIN_PREFERRED_CONTACT_METHOD_ASC',
  LeadsMinPreferredContactMethodDesc = 'LEADS_MIN_PREFERRED_CONTACT_METHOD_DESC',
  LeadsMinCompanyNameAsc = 'LEADS_MIN_COMPANY_NAME_ASC',
  LeadsMinCompanyNameDesc = 'LEADS_MIN_COMPANY_NAME_DESC',
  LeadsMinCreditConcernAsc = 'LEADS_MIN_CREDIT_CONCERN_ASC',
  LeadsMinCreditConcernDesc = 'LEADS_MIN_CREDIT_CONCERN_DESC',
  LeadsMinResidenceTimeAsc = 'LEADS_MIN_RESIDENCE_TIME_ASC',
  LeadsMinResidenceTimeDesc = 'LEADS_MIN_RESIDENCE_TIME_DESC',
  LeadsMinRentOrOwnAsc = 'LEADS_MIN_RENT_OR_OWN_ASC',
  LeadsMinRentOrOwnDesc = 'LEADS_MIN_RENT_OR_OWN_DESC',
  LeadsMinEmploymentStatusAsc = 'LEADS_MIN_EMPLOYMENT_STATUS_ASC',
  LeadsMinEmploymentStatusDesc = 'LEADS_MIN_EMPLOYMENT_STATUS_DESC',
  LeadsMinIncomeAmountAsc = 'LEADS_MIN_INCOME_AMOUNT_ASC',
  LeadsMinIncomeAmountDesc = 'LEADS_MIN_INCOME_AMOUNT_DESC',
  LeadsMinMonthlyHomePaymentAsc = 'LEADS_MIN_MONTHLY_HOME_PAYMENT_ASC',
  LeadsMinMonthlyHomePaymentDesc = 'LEADS_MIN_MONTHLY_HOME_PAYMENT_DESC',
  LeadsMinSmsConsentAsc = 'LEADS_MIN_SMS_CONSENT_ASC',
  LeadsMinSmsConsentDesc = 'LEADS_MIN_SMS_CONSENT_DESC',
  LeadsMinIncomeTimeYearsAsc = 'LEADS_MIN_INCOME_TIME_YEARS_ASC',
  LeadsMinIncomeTimeYearsDesc = 'LEADS_MIN_INCOME_TIME_YEARS_DESC',
  LeadsMinIncomeTimeMonthsAsc = 'LEADS_MIN_INCOME_TIME_MONTHS_ASC',
  LeadsMinIncomeTimeMonthsDesc = 'LEADS_MIN_INCOME_TIME_MONTHS_DESC',
  LeadsMinResidenceTimeYearsAsc = 'LEADS_MIN_RESIDENCE_TIME_YEARS_ASC',
  LeadsMinResidenceTimeYearsDesc = 'LEADS_MIN_RESIDENCE_TIME_YEARS_DESC',
  LeadsMinResidenceTimeMonthsAsc = 'LEADS_MIN_RESIDENCE_TIME_MONTHS_ASC',
  LeadsMinResidenceTimeMonthsDesc = 'LEADS_MIN_RESIDENCE_TIME_MONTHS_DESC',
  LeadsMaxIdAsc = 'LEADS_MAX_ID_ASC',
  LeadsMaxIdDesc = 'LEADS_MAX_ID_DESC',
  LeadsMaxCompanyIdAsc = 'LEADS_MAX_COMPANY_ID_ASC',
  LeadsMaxCompanyIdDesc = 'LEADS_MAX_COMPANY_ID_DESC',
  LeadsMaxCreatedAtAsc = 'LEADS_MAX_CREATED_AT_ASC',
  LeadsMaxCreatedAtDesc = 'LEADS_MAX_CREATED_AT_DESC',
  LeadsMaxFirstNameAsc = 'LEADS_MAX_FIRST_NAME_ASC',
  LeadsMaxFirstNameDesc = 'LEADS_MAX_FIRST_NAME_DESC',
  LeadsMaxLastNameAsc = 'LEADS_MAX_LAST_NAME_ASC',
  LeadsMaxLastNameDesc = 'LEADS_MAX_LAST_NAME_DESC',
  LeadsMaxDateOfBirthAsc = 'LEADS_MAX_DATE_OF_BIRTH_ASC',
  LeadsMaxDateOfBirthDesc = 'LEADS_MAX_DATE_OF_BIRTH_DESC',
  LeadsMaxStreetAsc = 'LEADS_MAX_STREET_ASC',
  LeadsMaxStreetDesc = 'LEADS_MAX_STREET_DESC',
  LeadsMaxStreetNumberAsc = 'LEADS_MAX_STREET_NUMBER_ASC',
  LeadsMaxStreetNumberDesc = 'LEADS_MAX_STREET_NUMBER_DESC',
  LeadsMaxUnitAsc = 'LEADS_MAX_UNIT_ASC',
  LeadsMaxUnitDesc = 'LEADS_MAX_UNIT_DESC',
  LeadsMaxCityAsc = 'LEADS_MAX_CITY_ASC',
  LeadsMaxCityDesc = 'LEADS_MAX_CITY_DESC',
  LeadsMaxCountryAsc = 'LEADS_MAX_COUNTRY_ASC',
  LeadsMaxCountryDesc = 'LEADS_MAX_COUNTRY_DESC',
  LeadsMaxPostalCodeAsc = 'LEADS_MAX_POSTAL_CODE_ASC',
  LeadsMaxPostalCodeDesc = 'LEADS_MAX_POSTAL_CODE_DESC',
  LeadsMaxProvinceCodeAsc = 'LEADS_MAX_PROVINCE_CODE_ASC',
  LeadsMaxProvinceCodeDesc = 'LEADS_MAX_PROVINCE_CODE_DESC',
  LeadsMaxEmailAddressAsc = 'LEADS_MAX_EMAIL_ADDRESS_ASC',
  LeadsMaxEmailAddressDesc = 'LEADS_MAX_EMAIL_ADDRESS_DESC',
  LeadsMaxPhoneNumberAsc = 'LEADS_MAX_PHONE_NUMBER_ASC',
  LeadsMaxPhoneNumberDesc = 'LEADS_MAX_PHONE_NUMBER_DESC',
  LeadsMaxSourceUrlAsc = 'LEADS_MAX_SOURCE_URL_ASC',
  LeadsMaxSourceUrlDesc = 'LEADS_MAX_SOURCE_URL_DESC',
  LeadsMaxTransactionKeyAsc = 'LEADS_MAX_TRANSACTION_KEY_ASC',
  LeadsMaxTransactionKeyDesc = 'LEADS_MAX_TRANSACTION_KEY_DESC',
  LeadsMaxLeadspediaIdAsc = 'LEADS_MAX_LEADSPEDIA_ID_ASC',
  LeadsMaxLeadspediaIdDesc = 'LEADS_MAX_LEADSPEDIA_ID_DESC',
  LeadsMaxUpdatedAtAsc = 'LEADS_MAX_UPDATED_AT_ASC',
  LeadsMaxUpdatedAtDesc = 'LEADS_MAX_UPDATED_AT_DESC',
  LeadsMaxSearchTextAsc = 'LEADS_MAX_SEARCH_TEXT_ASC',
  LeadsMaxSearchTextDesc = 'LEADS_MAX_SEARCH_TEXT_DESC',
  LeadsMaxCommentAsc = 'LEADS_MAX_COMMENT_ASC',
  LeadsMaxCommentDesc = 'LEADS_MAX_COMMENT_DESC',
  LeadsMaxStateAsc = 'LEADS_MAX_STATE_ASC',
  LeadsMaxStateDesc = 'LEADS_MAX_STATE_DESC',
  LeadsMaxReadAsc = 'LEADS_MAX_READ_ASC',
  LeadsMaxReadDesc = 'LEADS_MAX_READ_DESC',
  LeadsMaxExternalIdsAsc = 'LEADS_MAX_EXTERNAL_IDS_ASC',
  LeadsMaxExternalIdsDesc = 'LEADS_MAX_EXTERNAL_IDS_DESC',
  LeadsMaxMetaAsc = 'LEADS_MAX_META_ASC',
  LeadsMaxMetaDesc = 'LEADS_MAX_META_DESC',
  LeadsMaxAdditionalInformationAsc = 'LEADS_MAX_ADDITIONAL_INFORMATION_ASC',
  LeadsMaxAdditionalInformationDesc = 'LEADS_MAX_ADDITIONAL_INFORMATION_DESC',
  LeadsMaxPhoneVerifiedAtAsc = 'LEADS_MAX_PHONE_VERIFIED_AT_ASC',
  LeadsMaxPhoneVerifiedAtDesc = 'LEADS_MAX_PHONE_VERIFIED_AT_DESC',
  LeadsMaxIpAddressAsc = 'LEADS_MAX_IP_ADDRESS_ASC',
  LeadsMaxIpAddressDesc = 'LEADS_MAX_IP_ADDRESS_DESC',
  LeadsMaxClientIdAsc = 'LEADS_MAX_CLIENT_ID_ASC',
  LeadsMaxClientIdDesc = 'LEADS_MAX_CLIENT_ID_DESC',
  LeadsMaxDeletedAsc = 'LEADS_MAX_DELETED_ASC',
  LeadsMaxDeletedDesc = 'LEADS_MAX_DELETED_DESC',
  LeadsMaxPriorityAsc = 'LEADS_MAX_PRIORITY_ASC',
  LeadsMaxPriorityDesc = 'LEADS_MAX_PRIORITY_DESC',
  LeadsMaxAssigneeAsc = 'LEADS_MAX_ASSIGNEE_ASC',
  LeadsMaxAssigneeDesc = 'LEADS_MAX_ASSIGNEE_DESC',
  LeadsMaxShortIdAsc = 'LEADS_MAX_SHORT_ID_ASC',
  LeadsMaxShortIdDesc = 'LEADS_MAX_SHORT_ID_DESC',
  LeadsMaxCompanyChannelIdAsc = 'LEADS_MAX_COMPANY_CHANNEL_ID_ASC',
  LeadsMaxCompanyChannelIdDesc = 'LEADS_MAX_COMPANY_CHANNEL_ID_DESC',
  LeadsMaxNeighborhoodViewIdAsc = 'LEADS_MAX_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsMaxNeighborhoodViewIdDesc = 'LEADS_MAX_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsMaxMaritalStatusAsc = 'LEADS_MAX_MARITAL_STATUS_ASC',
  LeadsMaxMaritalStatusDesc = 'LEADS_MAX_MARITAL_STATUS_DESC',
  LeadsMaxDesiredVehicleAsc = 'LEADS_MAX_DESIRED_VEHICLE_ASC',
  LeadsMaxDesiredVehicleDesc = 'LEADS_MAX_DESIRED_VEHICLE_DESC',
  LeadsMaxJobTitleAsc = 'LEADS_MAX_JOB_TITLE_ASC',
  LeadsMaxJobTitleDesc = 'LEADS_MAX_JOB_TITLE_DESC',
  LeadsMaxIncomePeriodAsc = 'LEADS_MAX_INCOME_PERIOD_ASC',
  LeadsMaxIncomePeriodDesc = 'LEADS_MAX_INCOME_PERIOD_DESC',
  LeadsMaxSalaryHourlyAsc = 'LEADS_MAX_SALARY_HOURLY_ASC',
  LeadsMaxSalaryHourlyDesc = 'LEADS_MAX_SALARY_HOURLY_DESC',
  LeadsMaxIncomeSourceAsc = 'LEADS_MAX_INCOME_SOURCE_ASC',
  LeadsMaxIncomeSourceDesc = 'LEADS_MAX_INCOME_SOURCE_DESC',
  LeadsMaxIncomeTimeAsc = 'LEADS_MAX_INCOME_TIME_ASC',
  LeadsMaxIncomeTimeDesc = 'LEADS_MAX_INCOME_TIME_DESC',
  LeadsMaxPreferredContactMethodAsc = 'LEADS_MAX_PREFERRED_CONTACT_METHOD_ASC',
  LeadsMaxPreferredContactMethodDesc = 'LEADS_MAX_PREFERRED_CONTACT_METHOD_DESC',
  LeadsMaxCompanyNameAsc = 'LEADS_MAX_COMPANY_NAME_ASC',
  LeadsMaxCompanyNameDesc = 'LEADS_MAX_COMPANY_NAME_DESC',
  LeadsMaxCreditConcernAsc = 'LEADS_MAX_CREDIT_CONCERN_ASC',
  LeadsMaxCreditConcernDesc = 'LEADS_MAX_CREDIT_CONCERN_DESC',
  LeadsMaxResidenceTimeAsc = 'LEADS_MAX_RESIDENCE_TIME_ASC',
  LeadsMaxResidenceTimeDesc = 'LEADS_MAX_RESIDENCE_TIME_DESC',
  LeadsMaxRentOrOwnAsc = 'LEADS_MAX_RENT_OR_OWN_ASC',
  LeadsMaxRentOrOwnDesc = 'LEADS_MAX_RENT_OR_OWN_DESC',
  LeadsMaxEmploymentStatusAsc = 'LEADS_MAX_EMPLOYMENT_STATUS_ASC',
  LeadsMaxEmploymentStatusDesc = 'LEADS_MAX_EMPLOYMENT_STATUS_DESC',
  LeadsMaxIncomeAmountAsc = 'LEADS_MAX_INCOME_AMOUNT_ASC',
  LeadsMaxIncomeAmountDesc = 'LEADS_MAX_INCOME_AMOUNT_DESC',
  LeadsMaxMonthlyHomePaymentAsc = 'LEADS_MAX_MONTHLY_HOME_PAYMENT_ASC',
  LeadsMaxMonthlyHomePaymentDesc = 'LEADS_MAX_MONTHLY_HOME_PAYMENT_DESC',
  LeadsMaxSmsConsentAsc = 'LEADS_MAX_SMS_CONSENT_ASC',
  LeadsMaxSmsConsentDesc = 'LEADS_MAX_SMS_CONSENT_DESC',
  LeadsMaxIncomeTimeYearsAsc = 'LEADS_MAX_INCOME_TIME_YEARS_ASC',
  LeadsMaxIncomeTimeYearsDesc = 'LEADS_MAX_INCOME_TIME_YEARS_DESC',
  LeadsMaxIncomeTimeMonthsAsc = 'LEADS_MAX_INCOME_TIME_MONTHS_ASC',
  LeadsMaxIncomeTimeMonthsDesc = 'LEADS_MAX_INCOME_TIME_MONTHS_DESC',
  LeadsMaxResidenceTimeYearsAsc = 'LEADS_MAX_RESIDENCE_TIME_YEARS_ASC',
  LeadsMaxResidenceTimeYearsDesc = 'LEADS_MAX_RESIDENCE_TIME_YEARS_DESC',
  LeadsMaxResidenceTimeMonthsAsc = 'LEADS_MAX_RESIDENCE_TIME_MONTHS_ASC',
  LeadsMaxResidenceTimeMonthsDesc = 'LEADS_MAX_RESIDENCE_TIME_MONTHS_DESC',
  LeadsAverageIdAsc = 'LEADS_AVERAGE_ID_ASC',
  LeadsAverageIdDesc = 'LEADS_AVERAGE_ID_DESC',
  LeadsAverageCompanyIdAsc = 'LEADS_AVERAGE_COMPANY_ID_ASC',
  LeadsAverageCompanyIdDesc = 'LEADS_AVERAGE_COMPANY_ID_DESC',
  LeadsAverageCreatedAtAsc = 'LEADS_AVERAGE_CREATED_AT_ASC',
  LeadsAverageCreatedAtDesc = 'LEADS_AVERAGE_CREATED_AT_DESC',
  LeadsAverageFirstNameAsc = 'LEADS_AVERAGE_FIRST_NAME_ASC',
  LeadsAverageFirstNameDesc = 'LEADS_AVERAGE_FIRST_NAME_DESC',
  LeadsAverageLastNameAsc = 'LEADS_AVERAGE_LAST_NAME_ASC',
  LeadsAverageLastNameDesc = 'LEADS_AVERAGE_LAST_NAME_DESC',
  LeadsAverageDateOfBirthAsc = 'LEADS_AVERAGE_DATE_OF_BIRTH_ASC',
  LeadsAverageDateOfBirthDesc = 'LEADS_AVERAGE_DATE_OF_BIRTH_DESC',
  LeadsAverageStreetAsc = 'LEADS_AVERAGE_STREET_ASC',
  LeadsAverageStreetDesc = 'LEADS_AVERAGE_STREET_DESC',
  LeadsAverageStreetNumberAsc = 'LEADS_AVERAGE_STREET_NUMBER_ASC',
  LeadsAverageStreetNumberDesc = 'LEADS_AVERAGE_STREET_NUMBER_DESC',
  LeadsAverageUnitAsc = 'LEADS_AVERAGE_UNIT_ASC',
  LeadsAverageUnitDesc = 'LEADS_AVERAGE_UNIT_DESC',
  LeadsAverageCityAsc = 'LEADS_AVERAGE_CITY_ASC',
  LeadsAverageCityDesc = 'LEADS_AVERAGE_CITY_DESC',
  LeadsAverageCountryAsc = 'LEADS_AVERAGE_COUNTRY_ASC',
  LeadsAverageCountryDesc = 'LEADS_AVERAGE_COUNTRY_DESC',
  LeadsAveragePostalCodeAsc = 'LEADS_AVERAGE_POSTAL_CODE_ASC',
  LeadsAveragePostalCodeDesc = 'LEADS_AVERAGE_POSTAL_CODE_DESC',
  LeadsAverageProvinceCodeAsc = 'LEADS_AVERAGE_PROVINCE_CODE_ASC',
  LeadsAverageProvinceCodeDesc = 'LEADS_AVERAGE_PROVINCE_CODE_DESC',
  LeadsAverageEmailAddressAsc = 'LEADS_AVERAGE_EMAIL_ADDRESS_ASC',
  LeadsAverageEmailAddressDesc = 'LEADS_AVERAGE_EMAIL_ADDRESS_DESC',
  LeadsAveragePhoneNumberAsc = 'LEADS_AVERAGE_PHONE_NUMBER_ASC',
  LeadsAveragePhoneNumberDesc = 'LEADS_AVERAGE_PHONE_NUMBER_DESC',
  LeadsAverageSourceUrlAsc = 'LEADS_AVERAGE_SOURCE_URL_ASC',
  LeadsAverageSourceUrlDesc = 'LEADS_AVERAGE_SOURCE_URL_DESC',
  LeadsAverageTransactionKeyAsc = 'LEADS_AVERAGE_TRANSACTION_KEY_ASC',
  LeadsAverageTransactionKeyDesc = 'LEADS_AVERAGE_TRANSACTION_KEY_DESC',
  LeadsAverageLeadspediaIdAsc = 'LEADS_AVERAGE_LEADSPEDIA_ID_ASC',
  LeadsAverageLeadspediaIdDesc = 'LEADS_AVERAGE_LEADSPEDIA_ID_DESC',
  LeadsAverageUpdatedAtAsc = 'LEADS_AVERAGE_UPDATED_AT_ASC',
  LeadsAverageUpdatedAtDesc = 'LEADS_AVERAGE_UPDATED_AT_DESC',
  LeadsAverageSearchTextAsc = 'LEADS_AVERAGE_SEARCH_TEXT_ASC',
  LeadsAverageSearchTextDesc = 'LEADS_AVERAGE_SEARCH_TEXT_DESC',
  LeadsAverageCommentAsc = 'LEADS_AVERAGE_COMMENT_ASC',
  LeadsAverageCommentDesc = 'LEADS_AVERAGE_COMMENT_DESC',
  LeadsAverageStateAsc = 'LEADS_AVERAGE_STATE_ASC',
  LeadsAverageStateDesc = 'LEADS_AVERAGE_STATE_DESC',
  LeadsAverageReadAsc = 'LEADS_AVERAGE_READ_ASC',
  LeadsAverageReadDesc = 'LEADS_AVERAGE_READ_DESC',
  LeadsAverageExternalIdsAsc = 'LEADS_AVERAGE_EXTERNAL_IDS_ASC',
  LeadsAverageExternalIdsDesc = 'LEADS_AVERAGE_EXTERNAL_IDS_DESC',
  LeadsAverageMetaAsc = 'LEADS_AVERAGE_META_ASC',
  LeadsAverageMetaDesc = 'LEADS_AVERAGE_META_DESC',
  LeadsAverageAdditionalInformationAsc = 'LEADS_AVERAGE_ADDITIONAL_INFORMATION_ASC',
  LeadsAverageAdditionalInformationDesc = 'LEADS_AVERAGE_ADDITIONAL_INFORMATION_DESC',
  LeadsAveragePhoneVerifiedAtAsc = 'LEADS_AVERAGE_PHONE_VERIFIED_AT_ASC',
  LeadsAveragePhoneVerifiedAtDesc = 'LEADS_AVERAGE_PHONE_VERIFIED_AT_DESC',
  LeadsAverageIpAddressAsc = 'LEADS_AVERAGE_IP_ADDRESS_ASC',
  LeadsAverageIpAddressDesc = 'LEADS_AVERAGE_IP_ADDRESS_DESC',
  LeadsAverageClientIdAsc = 'LEADS_AVERAGE_CLIENT_ID_ASC',
  LeadsAverageClientIdDesc = 'LEADS_AVERAGE_CLIENT_ID_DESC',
  LeadsAverageDeletedAsc = 'LEADS_AVERAGE_DELETED_ASC',
  LeadsAverageDeletedDesc = 'LEADS_AVERAGE_DELETED_DESC',
  LeadsAveragePriorityAsc = 'LEADS_AVERAGE_PRIORITY_ASC',
  LeadsAveragePriorityDesc = 'LEADS_AVERAGE_PRIORITY_DESC',
  LeadsAverageAssigneeAsc = 'LEADS_AVERAGE_ASSIGNEE_ASC',
  LeadsAverageAssigneeDesc = 'LEADS_AVERAGE_ASSIGNEE_DESC',
  LeadsAverageShortIdAsc = 'LEADS_AVERAGE_SHORT_ID_ASC',
  LeadsAverageShortIdDesc = 'LEADS_AVERAGE_SHORT_ID_DESC',
  LeadsAverageCompanyChannelIdAsc = 'LEADS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  LeadsAverageCompanyChannelIdDesc = 'LEADS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  LeadsAverageNeighborhoodViewIdAsc = 'LEADS_AVERAGE_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsAverageNeighborhoodViewIdDesc = 'LEADS_AVERAGE_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsAverageMaritalStatusAsc = 'LEADS_AVERAGE_MARITAL_STATUS_ASC',
  LeadsAverageMaritalStatusDesc = 'LEADS_AVERAGE_MARITAL_STATUS_DESC',
  LeadsAverageDesiredVehicleAsc = 'LEADS_AVERAGE_DESIRED_VEHICLE_ASC',
  LeadsAverageDesiredVehicleDesc = 'LEADS_AVERAGE_DESIRED_VEHICLE_DESC',
  LeadsAverageJobTitleAsc = 'LEADS_AVERAGE_JOB_TITLE_ASC',
  LeadsAverageJobTitleDesc = 'LEADS_AVERAGE_JOB_TITLE_DESC',
  LeadsAverageIncomePeriodAsc = 'LEADS_AVERAGE_INCOME_PERIOD_ASC',
  LeadsAverageIncomePeriodDesc = 'LEADS_AVERAGE_INCOME_PERIOD_DESC',
  LeadsAverageSalaryHourlyAsc = 'LEADS_AVERAGE_SALARY_HOURLY_ASC',
  LeadsAverageSalaryHourlyDesc = 'LEADS_AVERAGE_SALARY_HOURLY_DESC',
  LeadsAverageIncomeSourceAsc = 'LEADS_AVERAGE_INCOME_SOURCE_ASC',
  LeadsAverageIncomeSourceDesc = 'LEADS_AVERAGE_INCOME_SOURCE_DESC',
  LeadsAverageIncomeTimeAsc = 'LEADS_AVERAGE_INCOME_TIME_ASC',
  LeadsAverageIncomeTimeDesc = 'LEADS_AVERAGE_INCOME_TIME_DESC',
  LeadsAveragePreferredContactMethodAsc = 'LEADS_AVERAGE_PREFERRED_CONTACT_METHOD_ASC',
  LeadsAveragePreferredContactMethodDesc = 'LEADS_AVERAGE_PREFERRED_CONTACT_METHOD_DESC',
  LeadsAverageCompanyNameAsc = 'LEADS_AVERAGE_COMPANY_NAME_ASC',
  LeadsAverageCompanyNameDesc = 'LEADS_AVERAGE_COMPANY_NAME_DESC',
  LeadsAverageCreditConcernAsc = 'LEADS_AVERAGE_CREDIT_CONCERN_ASC',
  LeadsAverageCreditConcernDesc = 'LEADS_AVERAGE_CREDIT_CONCERN_DESC',
  LeadsAverageResidenceTimeAsc = 'LEADS_AVERAGE_RESIDENCE_TIME_ASC',
  LeadsAverageResidenceTimeDesc = 'LEADS_AVERAGE_RESIDENCE_TIME_DESC',
  LeadsAverageRentOrOwnAsc = 'LEADS_AVERAGE_RENT_OR_OWN_ASC',
  LeadsAverageRentOrOwnDesc = 'LEADS_AVERAGE_RENT_OR_OWN_DESC',
  LeadsAverageEmploymentStatusAsc = 'LEADS_AVERAGE_EMPLOYMENT_STATUS_ASC',
  LeadsAverageEmploymentStatusDesc = 'LEADS_AVERAGE_EMPLOYMENT_STATUS_DESC',
  LeadsAverageIncomeAmountAsc = 'LEADS_AVERAGE_INCOME_AMOUNT_ASC',
  LeadsAverageIncomeAmountDesc = 'LEADS_AVERAGE_INCOME_AMOUNT_DESC',
  LeadsAverageMonthlyHomePaymentAsc = 'LEADS_AVERAGE_MONTHLY_HOME_PAYMENT_ASC',
  LeadsAverageMonthlyHomePaymentDesc = 'LEADS_AVERAGE_MONTHLY_HOME_PAYMENT_DESC',
  LeadsAverageSmsConsentAsc = 'LEADS_AVERAGE_SMS_CONSENT_ASC',
  LeadsAverageSmsConsentDesc = 'LEADS_AVERAGE_SMS_CONSENT_DESC',
  LeadsAverageIncomeTimeYearsAsc = 'LEADS_AVERAGE_INCOME_TIME_YEARS_ASC',
  LeadsAverageIncomeTimeYearsDesc = 'LEADS_AVERAGE_INCOME_TIME_YEARS_DESC',
  LeadsAverageIncomeTimeMonthsAsc = 'LEADS_AVERAGE_INCOME_TIME_MONTHS_ASC',
  LeadsAverageIncomeTimeMonthsDesc = 'LEADS_AVERAGE_INCOME_TIME_MONTHS_DESC',
  LeadsAverageResidenceTimeYearsAsc = 'LEADS_AVERAGE_RESIDENCE_TIME_YEARS_ASC',
  LeadsAverageResidenceTimeYearsDesc = 'LEADS_AVERAGE_RESIDENCE_TIME_YEARS_DESC',
  LeadsAverageResidenceTimeMonthsAsc = 'LEADS_AVERAGE_RESIDENCE_TIME_MONTHS_ASC',
  LeadsAverageResidenceTimeMonthsDesc = 'LEADS_AVERAGE_RESIDENCE_TIME_MONTHS_DESC',
  LeadsStddevSampleIdAsc = 'LEADS_STDDEV_SAMPLE_ID_ASC',
  LeadsStddevSampleIdDesc = 'LEADS_STDDEV_SAMPLE_ID_DESC',
  LeadsStddevSampleCompanyIdAsc = 'LEADS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  LeadsStddevSampleCompanyIdDesc = 'LEADS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  LeadsStddevSampleCreatedAtAsc = 'LEADS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LeadsStddevSampleCreatedAtDesc = 'LEADS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LeadsStddevSampleFirstNameAsc = 'LEADS_STDDEV_SAMPLE_FIRST_NAME_ASC',
  LeadsStddevSampleFirstNameDesc = 'LEADS_STDDEV_SAMPLE_FIRST_NAME_DESC',
  LeadsStddevSampleLastNameAsc = 'LEADS_STDDEV_SAMPLE_LAST_NAME_ASC',
  LeadsStddevSampleLastNameDesc = 'LEADS_STDDEV_SAMPLE_LAST_NAME_DESC',
  LeadsStddevSampleDateOfBirthAsc = 'LEADS_STDDEV_SAMPLE_DATE_OF_BIRTH_ASC',
  LeadsStddevSampleDateOfBirthDesc = 'LEADS_STDDEV_SAMPLE_DATE_OF_BIRTH_DESC',
  LeadsStddevSampleStreetAsc = 'LEADS_STDDEV_SAMPLE_STREET_ASC',
  LeadsStddevSampleStreetDesc = 'LEADS_STDDEV_SAMPLE_STREET_DESC',
  LeadsStddevSampleStreetNumberAsc = 'LEADS_STDDEV_SAMPLE_STREET_NUMBER_ASC',
  LeadsStddevSampleStreetNumberDesc = 'LEADS_STDDEV_SAMPLE_STREET_NUMBER_DESC',
  LeadsStddevSampleUnitAsc = 'LEADS_STDDEV_SAMPLE_UNIT_ASC',
  LeadsStddevSampleUnitDesc = 'LEADS_STDDEV_SAMPLE_UNIT_DESC',
  LeadsStddevSampleCityAsc = 'LEADS_STDDEV_SAMPLE_CITY_ASC',
  LeadsStddevSampleCityDesc = 'LEADS_STDDEV_SAMPLE_CITY_DESC',
  LeadsStddevSampleCountryAsc = 'LEADS_STDDEV_SAMPLE_COUNTRY_ASC',
  LeadsStddevSampleCountryDesc = 'LEADS_STDDEV_SAMPLE_COUNTRY_DESC',
  LeadsStddevSamplePostalCodeAsc = 'LEADS_STDDEV_SAMPLE_POSTAL_CODE_ASC',
  LeadsStddevSamplePostalCodeDesc = 'LEADS_STDDEV_SAMPLE_POSTAL_CODE_DESC',
  LeadsStddevSampleProvinceCodeAsc = 'LEADS_STDDEV_SAMPLE_PROVINCE_CODE_ASC',
  LeadsStddevSampleProvinceCodeDesc = 'LEADS_STDDEV_SAMPLE_PROVINCE_CODE_DESC',
  LeadsStddevSampleEmailAddressAsc = 'LEADS_STDDEV_SAMPLE_EMAIL_ADDRESS_ASC',
  LeadsStddevSampleEmailAddressDesc = 'LEADS_STDDEV_SAMPLE_EMAIL_ADDRESS_DESC',
  LeadsStddevSamplePhoneNumberAsc = 'LEADS_STDDEV_SAMPLE_PHONE_NUMBER_ASC',
  LeadsStddevSamplePhoneNumberDesc = 'LEADS_STDDEV_SAMPLE_PHONE_NUMBER_DESC',
  LeadsStddevSampleSourceUrlAsc = 'LEADS_STDDEV_SAMPLE_SOURCE_URL_ASC',
  LeadsStddevSampleSourceUrlDesc = 'LEADS_STDDEV_SAMPLE_SOURCE_URL_DESC',
  LeadsStddevSampleTransactionKeyAsc = 'LEADS_STDDEV_SAMPLE_TRANSACTION_KEY_ASC',
  LeadsStddevSampleTransactionKeyDesc = 'LEADS_STDDEV_SAMPLE_TRANSACTION_KEY_DESC',
  LeadsStddevSampleLeadspediaIdAsc = 'LEADS_STDDEV_SAMPLE_LEADSPEDIA_ID_ASC',
  LeadsStddevSampleLeadspediaIdDesc = 'LEADS_STDDEV_SAMPLE_LEADSPEDIA_ID_DESC',
  LeadsStddevSampleUpdatedAtAsc = 'LEADS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LeadsStddevSampleUpdatedAtDesc = 'LEADS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LeadsStddevSampleSearchTextAsc = 'LEADS_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  LeadsStddevSampleSearchTextDesc = 'LEADS_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  LeadsStddevSampleCommentAsc = 'LEADS_STDDEV_SAMPLE_COMMENT_ASC',
  LeadsStddevSampleCommentDesc = 'LEADS_STDDEV_SAMPLE_COMMENT_DESC',
  LeadsStddevSampleStateAsc = 'LEADS_STDDEV_SAMPLE_STATE_ASC',
  LeadsStddevSampleStateDesc = 'LEADS_STDDEV_SAMPLE_STATE_DESC',
  LeadsStddevSampleReadAsc = 'LEADS_STDDEV_SAMPLE_READ_ASC',
  LeadsStddevSampleReadDesc = 'LEADS_STDDEV_SAMPLE_READ_DESC',
  LeadsStddevSampleExternalIdsAsc = 'LEADS_STDDEV_SAMPLE_EXTERNAL_IDS_ASC',
  LeadsStddevSampleExternalIdsDesc = 'LEADS_STDDEV_SAMPLE_EXTERNAL_IDS_DESC',
  LeadsStddevSampleMetaAsc = 'LEADS_STDDEV_SAMPLE_META_ASC',
  LeadsStddevSampleMetaDesc = 'LEADS_STDDEV_SAMPLE_META_DESC',
  LeadsStddevSampleAdditionalInformationAsc = 'LEADS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  LeadsStddevSampleAdditionalInformationDesc = 'LEADS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  LeadsStddevSamplePhoneVerifiedAtAsc = 'LEADS_STDDEV_SAMPLE_PHONE_VERIFIED_AT_ASC',
  LeadsStddevSamplePhoneVerifiedAtDesc = 'LEADS_STDDEV_SAMPLE_PHONE_VERIFIED_AT_DESC',
  LeadsStddevSampleIpAddressAsc = 'LEADS_STDDEV_SAMPLE_IP_ADDRESS_ASC',
  LeadsStddevSampleIpAddressDesc = 'LEADS_STDDEV_SAMPLE_IP_ADDRESS_DESC',
  LeadsStddevSampleClientIdAsc = 'LEADS_STDDEV_SAMPLE_CLIENT_ID_ASC',
  LeadsStddevSampleClientIdDesc = 'LEADS_STDDEV_SAMPLE_CLIENT_ID_DESC',
  LeadsStddevSampleDeletedAsc = 'LEADS_STDDEV_SAMPLE_DELETED_ASC',
  LeadsStddevSampleDeletedDesc = 'LEADS_STDDEV_SAMPLE_DELETED_DESC',
  LeadsStddevSamplePriorityAsc = 'LEADS_STDDEV_SAMPLE_PRIORITY_ASC',
  LeadsStddevSamplePriorityDesc = 'LEADS_STDDEV_SAMPLE_PRIORITY_DESC',
  LeadsStddevSampleAssigneeAsc = 'LEADS_STDDEV_SAMPLE_ASSIGNEE_ASC',
  LeadsStddevSampleAssigneeDesc = 'LEADS_STDDEV_SAMPLE_ASSIGNEE_DESC',
  LeadsStddevSampleShortIdAsc = 'LEADS_STDDEV_SAMPLE_SHORT_ID_ASC',
  LeadsStddevSampleShortIdDesc = 'LEADS_STDDEV_SAMPLE_SHORT_ID_DESC',
  LeadsStddevSampleCompanyChannelIdAsc = 'LEADS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  LeadsStddevSampleCompanyChannelIdDesc = 'LEADS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  LeadsStddevSampleNeighborhoodViewIdAsc = 'LEADS_STDDEV_SAMPLE_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsStddevSampleNeighborhoodViewIdDesc = 'LEADS_STDDEV_SAMPLE_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsStddevSampleMaritalStatusAsc = 'LEADS_STDDEV_SAMPLE_MARITAL_STATUS_ASC',
  LeadsStddevSampleMaritalStatusDesc = 'LEADS_STDDEV_SAMPLE_MARITAL_STATUS_DESC',
  LeadsStddevSampleDesiredVehicleAsc = 'LEADS_STDDEV_SAMPLE_DESIRED_VEHICLE_ASC',
  LeadsStddevSampleDesiredVehicleDesc = 'LEADS_STDDEV_SAMPLE_DESIRED_VEHICLE_DESC',
  LeadsStddevSampleJobTitleAsc = 'LEADS_STDDEV_SAMPLE_JOB_TITLE_ASC',
  LeadsStddevSampleJobTitleDesc = 'LEADS_STDDEV_SAMPLE_JOB_TITLE_DESC',
  LeadsStddevSampleIncomePeriodAsc = 'LEADS_STDDEV_SAMPLE_INCOME_PERIOD_ASC',
  LeadsStddevSampleIncomePeriodDesc = 'LEADS_STDDEV_SAMPLE_INCOME_PERIOD_DESC',
  LeadsStddevSampleSalaryHourlyAsc = 'LEADS_STDDEV_SAMPLE_SALARY_HOURLY_ASC',
  LeadsStddevSampleSalaryHourlyDesc = 'LEADS_STDDEV_SAMPLE_SALARY_HOURLY_DESC',
  LeadsStddevSampleIncomeSourceAsc = 'LEADS_STDDEV_SAMPLE_INCOME_SOURCE_ASC',
  LeadsStddevSampleIncomeSourceDesc = 'LEADS_STDDEV_SAMPLE_INCOME_SOURCE_DESC',
  LeadsStddevSampleIncomeTimeAsc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_ASC',
  LeadsStddevSampleIncomeTimeDesc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_DESC',
  LeadsStddevSamplePreferredContactMethodAsc = 'LEADS_STDDEV_SAMPLE_PREFERRED_CONTACT_METHOD_ASC',
  LeadsStddevSamplePreferredContactMethodDesc = 'LEADS_STDDEV_SAMPLE_PREFERRED_CONTACT_METHOD_DESC',
  LeadsStddevSampleCompanyNameAsc = 'LEADS_STDDEV_SAMPLE_COMPANY_NAME_ASC',
  LeadsStddevSampleCompanyNameDesc = 'LEADS_STDDEV_SAMPLE_COMPANY_NAME_DESC',
  LeadsStddevSampleCreditConcernAsc = 'LEADS_STDDEV_SAMPLE_CREDIT_CONCERN_ASC',
  LeadsStddevSampleCreditConcernDesc = 'LEADS_STDDEV_SAMPLE_CREDIT_CONCERN_DESC',
  LeadsStddevSampleResidenceTimeAsc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_ASC',
  LeadsStddevSampleResidenceTimeDesc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_DESC',
  LeadsStddevSampleRentOrOwnAsc = 'LEADS_STDDEV_SAMPLE_RENT_OR_OWN_ASC',
  LeadsStddevSampleRentOrOwnDesc = 'LEADS_STDDEV_SAMPLE_RENT_OR_OWN_DESC',
  LeadsStddevSampleEmploymentStatusAsc = 'LEADS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LeadsStddevSampleEmploymentStatusDesc = 'LEADS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LeadsStddevSampleIncomeAmountAsc = 'LEADS_STDDEV_SAMPLE_INCOME_AMOUNT_ASC',
  LeadsStddevSampleIncomeAmountDesc = 'LEADS_STDDEV_SAMPLE_INCOME_AMOUNT_DESC',
  LeadsStddevSampleMonthlyHomePaymentAsc = 'LEADS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LeadsStddevSampleMonthlyHomePaymentDesc = 'LEADS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LeadsStddevSampleSmsConsentAsc = 'LEADS_STDDEV_SAMPLE_SMS_CONSENT_ASC',
  LeadsStddevSampleSmsConsentDesc = 'LEADS_STDDEV_SAMPLE_SMS_CONSENT_DESC',
  LeadsStddevSampleIncomeTimeYearsAsc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_YEARS_ASC',
  LeadsStddevSampleIncomeTimeYearsDesc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_YEARS_DESC',
  LeadsStddevSampleIncomeTimeMonthsAsc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_MONTHS_ASC',
  LeadsStddevSampleIncomeTimeMonthsDesc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_MONTHS_DESC',
  LeadsStddevSampleResidenceTimeYearsAsc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_YEARS_ASC',
  LeadsStddevSampleResidenceTimeYearsDesc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_YEARS_DESC',
  LeadsStddevSampleResidenceTimeMonthsAsc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_MONTHS_ASC',
  LeadsStddevSampleResidenceTimeMonthsDesc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_MONTHS_DESC',
  LeadsStddevPopulationIdAsc = 'LEADS_STDDEV_POPULATION_ID_ASC',
  LeadsStddevPopulationIdDesc = 'LEADS_STDDEV_POPULATION_ID_DESC',
  LeadsStddevPopulationCompanyIdAsc = 'LEADS_STDDEV_POPULATION_COMPANY_ID_ASC',
  LeadsStddevPopulationCompanyIdDesc = 'LEADS_STDDEV_POPULATION_COMPANY_ID_DESC',
  LeadsStddevPopulationCreatedAtAsc = 'LEADS_STDDEV_POPULATION_CREATED_AT_ASC',
  LeadsStddevPopulationCreatedAtDesc = 'LEADS_STDDEV_POPULATION_CREATED_AT_DESC',
  LeadsStddevPopulationFirstNameAsc = 'LEADS_STDDEV_POPULATION_FIRST_NAME_ASC',
  LeadsStddevPopulationFirstNameDesc = 'LEADS_STDDEV_POPULATION_FIRST_NAME_DESC',
  LeadsStddevPopulationLastNameAsc = 'LEADS_STDDEV_POPULATION_LAST_NAME_ASC',
  LeadsStddevPopulationLastNameDesc = 'LEADS_STDDEV_POPULATION_LAST_NAME_DESC',
  LeadsStddevPopulationDateOfBirthAsc = 'LEADS_STDDEV_POPULATION_DATE_OF_BIRTH_ASC',
  LeadsStddevPopulationDateOfBirthDesc = 'LEADS_STDDEV_POPULATION_DATE_OF_BIRTH_DESC',
  LeadsStddevPopulationStreetAsc = 'LEADS_STDDEV_POPULATION_STREET_ASC',
  LeadsStddevPopulationStreetDesc = 'LEADS_STDDEV_POPULATION_STREET_DESC',
  LeadsStddevPopulationStreetNumberAsc = 'LEADS_STDDEV_POPULATION_STREET_NUMBER_ASC',
  LeadsStddevPopulationStreetNumberDesc = 'LEADS_STDDEV_POPULATION_STREET_NUMBER_DESC',
  LeadsStddevPopulationUnitAsc = 'LEADS_STDDEV_POPULATION_UNIT_ASC',
  LeadsStddevPopulationUnitDesc = 'LEADS_STDDEV_POPULATION_UNIT_DESC',
  LeadsStddevPopulationCityAsc = 'LEADS_STDDEV_POPULATION_CITY_ASC',
  LeadsStddevPopulationCityDesc = 'LEADS_STDDEV_POPULATION_CITY_DESC',
  LeadsStddevPopulationCountryAsc = 'LEADS_STDDEV_POPULATION_COUNTRY_ASC',
  LeadsStddevPopulationCountryDesc = 'LEADS_STDDEV_POPULATION_COUNTRY_DESC',
  LeadsStddevPopulationPostalCodeAsc = 'LEADS_STDDEV_POPULATION_POSTAL_CODE_ASC',
  LeadsStddevPopulationPostalCodeDesc = 'LEADS_STDDEV_POPULATION_POSTAL_CODE_DESC',
  LeadsStddevPopulationProvinceCodeAsc = 'LEADS_STDDEV_POPULATION_PROVINCE_CODE_ASC',
  LeadsStddevPopulationProvinceCodeDesc = 'LEADS_STDDEV_POPULATION_PROVINCE_CODE_DESC',
  LeadsStddevPopulationEmailAddressAsc = 'LEADS_STDDEV_POPULATION_EMAIL_ADDRESS_ASC',
  LeadsStddevPopulationEmailAddressDesc = 'LEADS_STDDEV_POPULATION_EMAIL_ADDRESS_DESC',
  LeadsStddevPopulationPhoneNumberAsc = 'LEADS_STDDEV_POPULATION_PHONE_NUMBER_ASC',
  LeadsStddevPopulationPhoneNumberDesc = 'LEADS_STDDEV_POPULATION_PHONE_NUMBER_DESC',
  LeadsStddevPopulationSourceUrlAsc = 'LEADS_STDDEV_POPULATION_SOURCE_URL_ASC',
  LeadsStddevPopulationSourceUrlDesc = 'LEADS_STDDEV_POPULATION_SOURCE_URL_DESC',
  LeadsStddevPopulationTransactionKeyAsc = 'LEADS_STDDEV_POPULATION_TRANSACTION_KEY_ASC',
  LeadsStddevPopulationTransactionKeyDesc = 'LEADS_STDDEV_POPULATION_TRANSACTION_KEY_DESC',
  LeadsStddevPopulationLeadspediaIdAsc = 'LEADS_STDDEV_POPULATION_LEADSPEDIA_ID_ASC',
  LeadsStddevPopulationLeadspediaIdDesc = 'LEADS_STDDEV_POPULATION_LEADSPEDIA_ID_DESC',
  LeadsStddevPopulationUpdatedAtAsc = 'LEADS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LeadsStddevPopulationUpdatedAtDesc = 'LEADS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LeadsStddevPopulationSearchTextAsc = 'LEADS_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  LeadsStddevPopulationSearchTextDesc = 'LEADS_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  LeadsStddevPopulationCommentAsc = 'LEADS_STDDEV_POPULATION_COMMENT_ASC',
  LeadsStddevPopulationCommentDesc = 'LEADS_STDDEV_POPULATION_COMMENT_DESC',
  LeadsStddevPopulationStateAsc = 'LEADS_STDDEV_POPULATION_STATE_ASC',
  LeadsStddevPopulationStateDesc = 'LEADS_STDDEV_POPULATION_STATE_DESC',
  LeadsStddevPopulationReadAsc = 'LEADS_STDDEV_POPULATION_READ_ASC',
  LeadsStddevPopulationReadDesc = 'LEADS_STDDEV_POPULATION_READ_DESC',
  LeadsStddevPopulationExternalIdsAsc = 'LEADS_STDDEV_POPULATION_EXTERNAL_IDS_ASC',
  LeadsStddevPopulationExternalIdsDesc = 'LEADS_STDDEV_POPULATION_EXTERNAL_IDS_DESC',
  LeadsStddevPopulationMetaAsc = 'LEADS_STDDEV_POPULATION_META_ASC',
  LeadsStddevPopulationMetaDesc = 'LEADS_STDDEV_POPULATION_META_DESC',
  LeadsStddevPopulationAdditionalInformationAsc = 'LEADS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_ASC',
  LeadsStddevPopulationAdditionalInformationDesc = 'LEADS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_DESC',
  LeadsStddevPopulationPhoneVerifiedAtAsc = 'LEADS_STDDEV_POPULATION_PHONE_VERIFIED_AT_ASC',
  LeadsStddevPopulationPhoneVerifiedAtDesc = 'LEADS_STDDEV_POPULATION_PHONE_VERIFIED_AT_DESC',
  LeadsStddevPopulationIpAddressAsc = 'LEADS_STDDEV_POPULATION_IP_ADDRESS_ASC',
  LeadsStddevPopulationIpAddressDesc = 'LEADS_STDDEV_POPULATION_IP_ADDRESS_DESC',
  LeadsStddevPopulationClientIdAsc = 'LEADS_STDDEV_POPULATION_CLIENT_ID_ASC',
  LeadsStddevPopulationClientIdDesc = 'LEADS_STDDEV_POPULATION_CLIENT_ID_DESC',
  LeadsStddevPopulationDeletedAsc = 'LEADS_STDDEV_POPULATION_DELETED_ASC',
  LeadsStddevPopulationDeletedDesc = 'LEADS_STDDEV_POPULATION_DELETED_DESC',
  LeadsStddevPopulationPriorityAsc = 'LEADS_STDDEV_POPULATION_PRIORITY_ASC',
  LeadsStddevPopulationPriorityDesc = 'LEADS_STDDEV_POPULATION_PRIORITY_DESC',
  LeadsStddevPopulationAssigneeAsc = 'LEADS_STDDEV_POPULATION_ASSIGNEE_ASC',
  LeadsStddevPopulationAssigneeDesc = 'LEADS_STDDEV_POPULATION_ASSIGNEE_DESC',
  LeadsStddevPopulationShortIdAsc = 'LEADS_STDDEV_POPULATION_SHORT_ID_ASC',
  LeadsStddevPopulationShortIdDesc = 'LEADS_STDDEV_POPULATION_SHORT_ID_DESC',
  LeadsStddevPopulationCompanyChannelIdAsc = 'LEADS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  LeadsStddevPopulationCompanyChannelIdDesc = 'LEADS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  LeadsStddevPopulationNeighborhoodViewIdAsc = 'LEADS_STDDEV_POPULATION_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsStddevPopulationNeighborhoodViewIdDesc = 'LEADS_STDDEV_POPULATION_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsStddevPopulationMaritalStatusAsc = 'LEADS_STDDEV_POPULATION_MARITAL_STATUS_ASC',
  LeadsStddevPopulationMaritalStatusDesc = 'LEADS_STDDEV_POPULATION_MARITAL_STATUS_DESC',
  LeadsStddevPopulationDesiredVehicleAsc = 'LEADS_STDDEV_POPULATION_DESIRED_VEHICLE_ASC',
  LeadsStddevPopulationDesiredVehicleDesc = 'LEADS_STDDEV_POPULATION_DESIRED_VEHICLE_DESC',
  LeadsStddevPopulationJobTitleAsc = 'LEADS_STDDEV_POPULATION_JOB_TITLE_ASC',
  LeadsStddevPopulationJobTitleDesc = 'LEADS_STDDEV_POPULATION_JOB_TITLE_DESC',
  LeadsStddevPopulationIncomePeriodAsc = 'LEADS_STDDEV_POPULATION_INCOME_PERIOD_ASC',
  LeadsStddevPopulationIncomePeriodDesc = 'LEADS_STDDEV_POPULATION_INCOME_PERIOD_DESC',
  LeadsStddevPopulationSalaryHourlyAsc = 'LEADS_STDDEV_POPULATION_SALARY_HOURLY_ASC',
  LeadsStddevPopulationSalaryHourlyDesc = 'LEADS_STDDEV_POPULATION_SALARY_HOURLY_DESC',
  LeadsStddevPopulationIncomeSourceAsc = 'LEADS_STDDEV_POPULATION_INCOME_SOURCE_ASC',
  LeadsStddevPopulationIncomeSourceDesc = 'LEADS_STDDEV_POPULATION_INCOME_SOURCE_DESC',
  LeadsStddevPopulationIncomeTimeAsc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_ASC',
  LeadsStddevPopulationIncomeTimeDesc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_DESC',
  LeadsStddevPopulationPreferredContactMethodAsc = 'LEADS_STDDEV_POPULATION_PREFERRED_CONTACT_METHOD_ASC',
  LeadsStddevPopulationPreferredContactMethodDesc = 'LEADS_STDDEV_POPULATION_PREFERRED_CONTACT_METHOD_DESC',
  LeadsStddevPopulationCompanyNameAsc = 'LEADS_STDDEV_POPULATION_COMPANY_NAME_ASC',
  LeadsStddevPopulationCompanyNameDesc = 'LEADS_STDDEV_POPULATION_COMPANY_NAME_DESC',
  LeadsStddevPopulationCreditConcernAsc = 'LEADS_STDDEV_POPULATION_CREDIT_CONCERN_ASC',
  LeadsStddevPopulationCreditConcernDesc = 'LEADS_STDDEV_POPULATION_CREDIT_CONCERN_DESC',
  LeadsStddevPopulationResidenceTimeAsc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_ASC',
  LeadsStddevPopulationResidenceTimeDesc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_DESC',
  LeadsStddevPopulationRentOrOwnAsc = 'LEADS_STDDEV_POPULATION_RENT_OR_OWN_ASC',
  LeadsStddevPopulationRentOrOwnDesc = 'LEADS_STDDEV_POPULATION_RENT_OR_OWN_DESC',
  LeadsStddevPopulationEmploymentStatusAsc = 'LEADS_STDDEV_POPULATION_EMPLOYMENT_STATUS_ASC',
  LeadsStddevPopulationEmploymentStatusDesc = 'LEADS_STDDEV_POPULATION_EMPLOYMENT_STATUS_DESC',
  LeadsStddevPopulationIncomeAmountAsc = 'LEADS_STDDEV_POPULATION_INCOME_AMOUNT_ASC',
  LeadsStddevPopulationIncomeAmountDesc = 'LEADS_STDDEV_POPULATION_INCOME_AMOUNT_DESC',
  LeadsStddevPopulationMonthlyHomePaymentAsc = 'LEADS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LeadsStddevPopulationMonthlyHomePaymentDesc = 'LEADS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LeadsStddevPopulationSmsConsentAsc = 'LEADS_STDDEV_POPULATION_SMS_CONSENT_ASC',
  LeadsStddevPopulationSmsConsentDesc = 'LEADS_STDDEV_POPULATION_SMS_CONSENT_DESC',
  LeadsStddevPopulationIncomeTimeYearsAsc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_YEARS_ASC',
  LeadsStddevPopulationIncomeTimeYearsDesc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_YEARS_DESC',
  LeadsStddevPopulationIncomeTimeMonthsAsc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_MONTHS_ASC',
  LeadsStddevPopulationIncomeTimeMonthsDesc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_MONTHS_DESC',
  LeadsStddevPopulationResidenceTimeYearsAsc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_YEARS_ASC',
  LeadsStddevPopulationResidenceTimeYearsDesc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_YEARS_DESC',
  LeadsStddevPopulationResidenceTimeMonthsAsc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_MONTHS_ASC',
  LeadsStddevPopulationResidenceTimeMonthsDesc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_MONTHS_DESC',
  LeadsVarianceSampleIdAsc = 'LEADS_VARIANCE_SAMPLE_ID_ASC',
  LeadsVarianceSampleIdDesc = 'LEADS_VARIANCE_SAMPLE_ID_DESC',
  LeadsVarianceSampleCompanyIdAsc = 'LEADS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  LeadsVarianceSampleCompanyIdDesc = 'LEADS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  LeadsVarianceSampleCreatedAtAsc = 'LEADS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LeadsVarianceSampleCreatedAtDesc = 'LEADS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LeadsVarianceSampleFirstNameAsc = 'LEADS_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  LeadsVarianceSampleFirstNameDesc = 'LEADS_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  LeadsVarianceSampleLastNameAsc = 'LEADS_VARIANCE_SAMPLE_LAST_NAME_ASC',
  LeadsVarianceSampleLastNameDesc = 'LEADS_VARIANCE_SAMPLE_LAST_NAME_DESC',
  LeadsVarianceSampleDateOfBirthAsc = 'LEADS_VARIANCE_SAMPLE_DATE_OF_BIRTH_ASC',
  LeadsVarianceSampleDateOfBirthDesc = 'LEADS_VARIANCE_SAMPLE_DATE_OF_BIRTH_DESC',
  LeadsVarianceSampleStreetAsc = 'LEADS_VARIANCE_SAMPLE_STREET_ASC',
  LeadsVarianceSampleStreetDesc = 'LEADS_VARIANCE_SAMPLE_STREET_DESC',
  LeadsVarianceSampleStreetNumberAsc = 'LEADS_VARIANCE_SAMPLE_STREET_NUMBER_ASC',
  LeadsVarianceSampleStreetNumberDesc = 'LEADS_VARIANCE_SAMPLE_STREET_NUMBER_DESC',
  LeadsVarianceSampleUnitAsc = 'LEADS_VARIANCE_SAMPLE_UNIT_ASC',
  LeadsVarianceSampleUnitDesc = 'LEADS_VARIANCE_SAMPLE_UNIT_DESC',
  LeadsVarianceSampleCityAsc = 'LEADS_VARIANCE_SAMPLE_CITY_ASC',
  LeadsVarianceSampleCityDesc = 'LEADS_VARIANCE_SAMPLE_CITY_DESC',
  LeadsVarianceSampleCountryAsc = 'LEADS_VARIANCE_SAMPLE_COUNTRY_ASC',
  LeadsVarianceSampleCountryDesc = 'LEADS_VARIANCE_SAMPLE_COUNTRY_DESC',
  LeadsVarianceSamplePostalCodeAsc = 'LEADS_VARIANCE_SAMPLE_POSTAL_CODE_ASC',
  LeadsVarianceSamplePostalCodeDesc = 'LEADS_VARIANCE_SAMPLE_POSTAL_CODE_DESC',
  LeadsVarianceSampleProvinceCodeAsc = 'LEADS_VARIANCE_SAMPLE_PROVINCE_CODE_ASC',
  LeadsVarianceSampleProvinceCodeDesc = 'LEADS_VARIANCE_SAMPLE_PROVINCE_CODE_DESC',
  LeadsVarianceSampleEmailAddressAsc = 'LEADS_VARIANCE_SAMPLE_EMAIL_ADDRESS_ASC',
  LeadsVarianceSampleEmailAddressDesc = 'LEADS_VARIANCE_SAMPLE_EMAIL_ADDRESS_DESC',
  LeadsVarianceSamplePhoneNumberAsc = 'LEADS_VARIANCE_SAMPLE_PHONE_NUMBER_ASC',
  LeadsVarianceSamplePhoneNumberDesc = 'LEADS_VARIANCE_SAMPLE_PHONE_NUMBER_DESC',
  LeadsVarianceSampleSourceUrlAsc = 'LEADS_VARIANCE_SAMPLE_SOURCE_URL_ASC',
  LeadsVarianceSampleSourceUrlDesc = 'LEADS_VARIANCE_SAMPLE_SOURCE_URL_DESC',
  LeadsVarianceSampleTransactionKeyAsc = 'LEADS_VARIANCE_SAMPLE_TRANSACTION_KEY_ASC',
  LeadsVarianceSampleTransactionKeyDesc = 'LEADS_VARIANCE_SAMPLE_TRANSACTION_KEY_DESC',
  LeadsVarianceSampleLeadspediaIdAsc = 'LEADS_VARIANCE_SAMPLE_LEADSPEDIA_ID_ASC',
  LeadsVarianceSampleLeadspediaIdDesc = 'LEADS_VARIANCE_SAMPLE_LEADSPEDIA_ID_DESC',
  LeadsVarianceSampleUpdatedAtAsc = 'LEADS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LeadsVarianceSampleUpdatedAtDesc = 'LEADS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LeadsVarianceSampleSearchTextAsc = 'LEADS_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  LeadsVarianceSampleSearchTextDesc = 'LEADS_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  LeadsVarianceSampleCommentAsc = 'LEADS_VARIANCE_SAMPLE_COMMENT_ASC',
  LeadsVarianceSampleCommentDesc = 'LEADS_VARIANCE_SAMPLE_COMMENT_DESC',
  LeadsVarianceSampleStateAsc = 'LEADS_VARIANCE_SAMPLE_STATE_ASC',
  LeadsVarianceSampleStateDesc = 'LEADS_VARIANCE_SAMPLE_STATE_DESC',
  LeadsVarianceSampleReadAsc = 'LEADS_VARIANCE_SAMPLE_READ_ASC',
  LeadsVarianceSampleReadDesc = 'LEADS_VARIANCE_SAMPLE_READ_DESC',
  LeadsVarianceSampleExternalIdsAsc = 'LEADS_VARIANCE_SAMPLE_EXTERNAL_IDS_ASC',
  LeadsVarianceSampleExternalIdsDesc = 'LEADS_VARIANCE_SAMPLE_EXTERNAL_IDS_DESC',
  LeadsVarianceSampleMetaAsc = 'LEADS_VARIANCE_SAMPLE_META_ASC',
  LeadsVarianceSampleMetaDesc = 'LEADS_VARIANCE_SAMPLE_META_DESC',
  LeadsVarianceSampleAdditionalInformationAsc = 'LEADS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  LeadsVarianceSampleAdditionalInformationDesc = 'LEADS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  LeadsVarianceSamplePhoneVerifiedAtAsc = 'LEADS_VARIANCE_SAMPLE_PHONE_VERIFIED_AT_ASC',
  LeadsVarianceSamplePhoneVerifiedAtDesc = 'LEADS_VARIANCE_SAMPLE_PHONE_VERIFIED_AT_DESC',
  LeadsVarianceSampleIpAddressAsc = 'LEADS_VARIANCE_SAMPLE_IP_ADDRESS_ASC',
  LeadsVarianceSampleIpAddressDesc = 'LEADS_VARIANCE_SAMPLE_IP_ADDRESS_DESC',
  LeadsVarianceSampleClientIdAsc = 'LEADS_VARIANCE_SAMPLE_CLIENT_ID_ASC',
  LeadsVarianceSampleClientIdDesc = 'LEADS_VARIANCE_SAMPLE_CLIENT_ID_DESC',
  LeadsVarianceSampleDeletedAsc = 'LEADS_VARIANCE_SAMPLE_DELETED_ASC',
  LeadsVarianceSampleDeletedDesc = 'LEADS_VARIANCE_SAMPLE_DELETED_DESC',
  LeadsVarianceSamplePriorityAsc = 'LEADS_VARIANCE_SAMPLE_PRIORITY_ASC',
  LeadsVarianceSamplePriorityDesc = 'LEADS_VARIANCE_SAMPLE_PRIORITY_DESC',
  LeadsVarianceSampleAssigneeAsc = 'LEADS_VARIANCE_SAMPLE_ASSIGNEE_ASC',
  LeadsVarianceSampleAssigneeDesc = 'LEADS_VARIANCE_SAMPLE_ASSIGNEE_DESC',
  LeadsVarianceSampleShortIdAsc = 'LEADS_VARIANCE_SAMPLE_SHORT_ID_ASC',
  LeadsVarianceSampleShortIdDesc = 'LEADS_VARIANCE_SAMPLE_SHORT_ID_DESC',
  LeadsVarianceSampleCompanyChannelIdAsc = 'LEADS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  LeadsVarianceSampleCompanyChannelIdDesc = 'LEADS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  LeadsVarianceSampleNeighborhoodViewIdAsc = 'LEADS_VARIANCE_SAMPLE_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsVarianceSampleNeighborhoodViewIdDesc = 'LEADS_VARIANCE_SAMPLE_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsVarianceSampleMaritalStatusAsc = 'LEADS_VARIANCE_SAMPLE_MARITAL_STATUS_ASC',
  LeadsVarianceSampleMaritalStatusDesc = 'LEADS_VARIANCE_SAMPLE_MARITAL_STATUS_DESC',
  LeadsVarianceSampleDesiredVehicleAsc = 'LEADS_VARIANCE_SAMPLE_DESIRED_VEHICLE_ASC',
  LeadsVarianceSampleDesiredVehicleDesc = 'LEADS_VARIANCE_SAMPLE_DESIRED_VEHICLE_DESC',
  LeadsVarianceSampleJobTitleAsc = 'LEADS_VARIANCE_SAMPLE_JOB_TITLE_ASC',
  LeadsVarianceSampleJobTitleDesc = 'LEADS_VARIANCE_SAMPLE_JOB_TITLE_DESC',
  LeadsVarianceSampleIncomePeriodAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_PERIOD_ASC',
  LeadsVarianceSampleIncomePeriodDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_PERIOD_DESC',
  LeadsVarianceSampleSalaryHourlyAsc = 'LEADS_VARIANCE_SAMPLE_SALARY_HOURLY_ASC',
  LeadsVarianceSampleSalaryHourlyDesc = 'LEADS_VARIANCE_SAMPLE_SALARY_HOURLY_DESC',
  LeadsVarianceSampleIncomeSourceAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_SOURCE_ASC',
  LeadsVarianceSampleIncomeSourceDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_SOURCE_DESC',
  LeadsVarianceSampleIncomeTimeAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_ASC',
  LeadsVarianceSampleIncomeTimeDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_DESC',
  LeadsVarianceSamplePreferredContactMethodAsc = 'LEADS_VARIANCE_SAMPLE_PREFERRED_CONTACT_METHOD_ASC',
  LeadsVarianceSamplePreferredContactMethodDesc = 'LEADS_VARIANCE_SAMPLE_PREFERRED_CONTACT_METHOD_DESC',
  LeadsVarianceSampleCompanyNameAsc = 'LEADS_VARIANCE_SAMPLE_COMPANY_NAME_ASC',
  LeadsVarianceSampleCompanyNameDesc = 'LEADS_VARIANCE_SAMPLE_COMPANY_NAME_DESC',
  LeadsVarianceSampleCreditConcernAsc = 'LEADS_VARIANCE_SAMPLE_CREDIT_CONCERN_ASC',
  LeadsVarianceSampleCreditConcernDesc = 'LEADS_VARIANCE_SAMPLE_CREDIT_CONCERN_DESC',
  LeadsVarianceSampleResidenceTimeAsc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_ASC',
  LeadsVarianceSampleResidenceTimeDesc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_DESC',
  LeadsVarianceSampleRentOrOwnAsc = 'LEADS_VARIANCE_SAMPLE_RENT_OR_OWN_ASC',
  LeadsVarianceSampleRentOrOwnDesc = 'LEADS_VARIANCE_SAMPLE_RENT_OR_OWN_DESC',
  LeadsVarianceSampleEmploymentStatusAsc = 'LEADS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LeadsVarianceSampleEmploymentStatusDesc = 'LEADS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LeadsVarianceSampleIncomeAmountAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_AMOUNT_ASC',
  LeadsVarianceSampleIncomeAmountDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_AMOUNT_DESC',
  LeadsVarianceSampleMonthlyHomePaymentAsc = 'LEADS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LeadsVarianceSampleMonthlyHomePaymentDesc = 'LEADS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LeadsVarianceSampleSmsConsentAsc = 'LEADS_VARIANCE_SAMPLE_SMS_CONSENT_ASC',
  LeadsVarianceSampleSmsConsentDesc = 'LEADS_VARIANCE_SAMPLE_SMS_CONSENT_DESC',
  LeadsVarianceSampleIncomeTimeYearsAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_YEARS_ASC',
  LeadsVarianceSampleIncomeTimeYearsDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_YEARS_DESC',
  LeadsVarianceSampleIncomeTimeMonthsAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_MONTHS_ASC',
  LeadsVarianceSampleIncomeTimeMonthsDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_MONTHS_DESC',
  LeadsVarianceSampleResidenceTimeYearsAsc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_YEARS_ASC',
  LeadsVarianceSampleResidenceTimeYearsDesc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_YEARS_DESC',
  LeadsVarianceSampleResidenceTimeMonthsAsc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_MONTHS_ASC',
  LeadsVarianceSampleResidenceTimeMonthsDesc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_MONTHS_DESC',
  LeadsVariancePopulationIdAsc = 'LEADS_VARIANCE_POPULATION_ID_ASC',
  LeadsVariancePopulationIdDesc = 'LEADS_VARIANCE_POPULATION_ID_DESC',
  LeadsVariancePopulationCompanyIdAsc = 'LEADS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  LeadsVariancePopulationCompanyIdDesc = 'LEADS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  LeadsVariancePopulationCreatedAtAsc = 'LEADS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LeadsVariancePopulationCreatedAtDesc = 'LEADS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LeadsVariancePopulationFirstNameAsc = 'LEADS_VARIANCE_POPULATION_FIRST_NAME_ASC',
  LeadsVariancePopulationFirstNameDesc = 'LEADS_VARIANCE_POPULATION_FIRST_NAME_DESC',
  LeadsVariancePopulationLastNameAsc = 'LEADS_VARIANCE_POPULATION_LAST_NAME_ASC',
  LeadsVariancePopulationLastNameDesc = 'LEADS_VARIANCE_POPULATION_LAST_NAME_DESC',
  LeadsVariancePopulationDateOfBirthAsc = 'LEADS_VARIANCE_POPULATION_DATE_OF_BIRTH_ASC',
  LeadsVariancePopulationDateOfBirthDesc = 'LEADS_VARIANCE_POPULATION_DATE_OF_BIRTH_DESC',
  LeadsVariancePopulationStreetAsc = 'LEADS_VARIANCE_POPULATION_STREET_ASC',
  LeadsVariancePopulationStreetDesc = 'LEADS_VARIANCE_POPULATION_STREET_DESC',
  LeadsVariancePopulationStreetNumberAsc = 'LEADS_VARIANCE_POPULATION_STREET_NUMBER_ASC',
  LeadsVariancePopulationStreetNumberDesc = 'LEADS_VARIANCE_POPULATION_STREET_NUMBER_DESC',
  LeadsVariancePopulationUnitAsc = 'LEADS_VARIANCE_POPULATION_UNIT_ASC',
  LeadsVariancePopulationUnitDesc = 'LEADS_VARIANCE_POPULATION_UNIT_DESC',
  LeadsVariancePopulationCityAsc = 'LEADS_VARIANCE_POPULATION_CITY_ASC',
  LeadsVariancePopulationCityDesc = 'LEADS_VARIANCE_POPULATION_CITY_DESC',
  LeadsVariancePopulationCountryAsc = 'LEADS_VARIANCE_POPULATION_COUNTRY_ASC',
  LeadsVariancePopulationCountryDesc = 'LEADS_VARIANCE_POPULATION_COUNTRY_DESC',
  LeadsVariancePopulationPostalCodeAsc = 'LEADS_VARIANCE_POPULATION_POSTAL_CODE_ASC',
  LeadsVariancePopulationPostalCodeDesc = 'LEADS_VARIANCE_POPULATION_POSTAL_CODE_DESC',
  LeadsVariancePopulationProvinceCodeAsc = 'LEADS_VARIANCE_POPULATION_PROVINCE_CODE_ASC',
  LeadsVariancePopulationProvinceCodeDesc = 'LEADS_VARIANCE_POPULATION_PROVINCE_CODE_DESC',
  LeadsVariancePopulationEmailAddressAsc = 'LEADS_VARIANCE_POPULATION_EMAIL_ADDRESS_ASC',
  LeadsVariancePopulationEmailAddressDesc = 'LEADS_VARIANCE_POPULATION_EMAIL_ADDRESS_DESC',
  LeadsVariancePopulationPhoneNumberAsc = 'LEADS_VARIANCE_POPULATION_PHONE_NUMBER_ASC',
  LeadsVariancePopulationPhoneNumberDesc = 'LEADS_VARIANCE_POPULATION_PHONE_NUMBER_DESC',
  LeadsVariancePopulationSourceUrlAsc = 'LEADS_VARIANCE_POPULATION_SOURCE_URL_ASC',
  LeadsVariancePopulationSourceUrlDesc = 'LEADS_VARIANCE_POPULATION_SOURCE_URL_DESC',
  LeadsVariancePopulationTransactionKeyAsc = 'LEADS_VARIANCE_POPULATION_TRANSACTION_KEY_ASC',
  LeadsVariancePopulationTransactionKeyDesc = 'LEADS_VARIANCE_POPULATION_TRANSACTION_KEY_DESC',
  LeadsVariancePopulationLeadspediaIdAsc = 'LEADS_VARIANCE_POPULATION_LEADSPEDIA_ID_ASC',
  LeadsVariancePopulationLeadspediaIdDesc = 'LEADS_VARIANCE_POPULATION_LEADSPEDIA_ID_DESC',
  LeadsVariancePopulationUpdatedAtAsc = 'LEADS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LeadsVariancePopulationUpdatedAtDesc = 'LEADS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LeadsVariancePopulationSearchTextAsc = 'LEADS_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  LeadsVariancePopulationSearchTextDesc = 'LEADS_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  LeadsVariancePopulationCommentAsc = 'LEADS_VARIANCE_POPULATION_COMMENT_ASC',
  LeadsVariancePopulationCommentDesc = 'LEADS_VARIANCE_POPULATION_COMMENT_DESC',
  LeadsVariancePopulationStateAsc = 'LEADS_VARIANCE_POPULATION_STATE_ASC',
  LeadsVariancePopulationStateDesc = 'LEADS_VARIANCE_POPULATION_STATE_DESC',
  LeadsVariancePopulationReadAsc = 'LEADS_VARIANCE_POPULATION_READ_ASC',
  LeadsVariancePopulationReadDesc = 'LEADS_VARIANCE_POPULATION_READ_DESC',
  LeadsVariancePopulationExternalIdsAsc = 'LEADS_VARIANCE_POPULATION_EXTERNAL_IDS_ASC',
  LeadsVariancePopulationExternalIdsDesc = 'LEADS_VARIANCE_POPULATION_EXTERNAL_IDS_DESC',
  LeadsVariancePopulationMetaAsc = 'LEADS_VARIANCE_POPULATION_META_ASC',
  LeadsVariancePopulationMetaDesc = 'LEADS_VARIANCE_POPULATION_META_DESC',
  LeadsVariancePopulationAdditionalInformationAsc = 'LEADS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_ASC',
  LeadsVariancePopulationAdditionalInformationDesc = 'LEADS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_DESC',
  LeadsVariancePopulationPhoneVerifiedAtAsc = 'LEADS_VARIANCE_POPULATION_PHONE_VERIFIED_AT_ASC',
  LeadsVariancePopulationPhoneVerifiedAtDesc = 'LEADS_VARIANCE_POPULATION_PHONE_VERIFIED_AT_DESC',
  LeadsVariancePopulationIpAddressAsc = 'LEADS_VARIANCE_POPULATION_IP_ADDRESS_ASC',
  LeadsVariancePopulationIpAddressDesc = 'LEADS_VARIANCE_POPULATION_IP_ADDRESS_DESC',
  LeadsVariancePopulationClientIdAsc = 'LEADS_VARIANCE_POPULATION_CLIENT_ID_ASC',
  LeadsVariancePopulationClientIdDesc = 'LEADS_VARIANCE_POPULATION_CLIENT_ID_DESC',
  LeadsVariancePopulationDeletedAsc = 'LEADS_VARIANCE_POPULATION_DELETED_ASC',
  LeadsVariancePopulationDeletedDesc = 'LEADS_VARIANCE_POPULATION_DELETED_DESC',
  LeadsVariancePopulationPriorityAsc = 'LEADS_VARIANCE_POPULATION_PRIORITY_ASC',
  LeadsVariancePopulationPriorityDesc = 'LEADS_VARIANCE_POPULATION_PRIORITY_DESC',
  LeadsVariancePopulationAssigneeAsc = 'LEADS_VARIANCE_POPULATION_ASSIGNEE_ASC',
  LeadsVariancePopulationAssigneeDesc = 'LEADS_VARIANCE_POPULATION_ASSIGNEE_DESC',
  LeadsVariancePopulationShortIdAsc = 'LEADS_VARIANCE_POPULATION_SHORT_ID_ASC',
  LeadsVariancePopulationShortIdDesc = 'LEADS_VARIANCE_POPULATION_SHORT_ID_DESC',
  LeadsVariancePopulationCompanyChannelIdAsc = 'LEADS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  LeadsVariancePopulationCompanyChannelIdDesc = 'LEADS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  LeadsVariancePopulationNeighborhoodViewIdAsc = 'LEADS_VARIANCE_POPULATION_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsVariancePopulationNeighborhoodViewIdDesc = 'LEADS_VARIANCE_POPULATION_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsVariancePopulationMaritalStatusAsc = 'LEADS_VARIANCE_POPULATION_MARITAL_STATUS_ASC',
  LeadsVariancePopulationMaritalStatusDesc = 'LEADS_VARIANCE_POPULATION_MARITAL_STATUS_DESC',
  LeadsVariancePopulationDesiredVehicleAsc = 'LEADS_VARIANCE_POPULATION_DESIRED_VEHICLE_ASC',
  LeadsVariancePopulationDesiredVehicleDesc = 'LEADS_VARIANCE_POPULATION_DESIRED_VEHICLE_DESC',
  LeadsVariancePopulationJobTitleAsc = 'LEADS_VARIANCE_POPULATION_JOB_TITLE_ASC',
  LeadsVariancePopulationJobTitleDesc = 'LEADS_VARIANCE_POPULATION_JOB_TITLE_DESC',
  LeadsVariancePopulationIncomePeriodAsc = 'LEADS_VARIANCE_POPULATION_INCOME_PERIOD_ASC',
  LeadsVariancePopulationIncomePeriodDesc = 'LEADS_VARIANCE_POPULATION_INCOME_PERIOD_DESC',
  LeadsVariancePopulationSalaryHourlyAsc = 'LEADS_VARIANCE_POPULATION_SALARY_HOURLY_ASC',
  LeadsVariancePopulationSalaryHourlyDesc = 'LEADS_VARIANCE_POPULATION_SALARY_HOURLY_DESC',
  LeadsVariancePopulationIncomeSourceAsc = 'LEADS_VARIANCE_POPULATION_INCOME_SOURCE_ASC',
  LeadsVariancePopulationIncomeSourceDesc = 'LEADS_VARIANCE_POPULATION_INCOME_SOURCE_DESC',
  LeadsVariancePopulationIncomeTimeAsc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_ASC',
  LeadsVariancePopulationIncomeTimeDesc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_DESC',
  LeadsVariancePopulationPreferredContactMethodAsc = 'LEADS_VARIANCE_POPULATION_PREFERRED_CONTACT_METHOD_ASC',
  LeadsVariancePopulationPreferredContactMethodDesc = 'LEADS_VARIANCE_POPULATION_PREFERRED_CONTACT_METHOD_DESC',
  LeadsVariancePopulationCompanyNameAsc = 'LEADS_VARIANCE_POPULATION_COMPANY_NAME_ASC',
  LeadsVariancePopulationCompanyNameDesc = 'LEADS_VARIANCE_POPULATION_COMPANY_NAME_DESC',
  LeadsVariancePopulationCreditConcernAsc = 'LEADS_VARIANCE_POPULATION_CREDIT_CONCERN_ASC',
  LeadsVariancePopulationCreditConcernDesc = 'LEADS_VARIANCE_POPULATION_CREDIT_CONCERN_DESC',
  LeadsVariancePopulationResidenceTimeAsc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_ASC',
  LeadsVariancePopulationResidenceTimeDesc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_DESC',
  LeadsVariancePopulationRentOrOwnAsc = 'LEADS_VARIANCE_POPULATION_RENT_OR_OWN_ASC',
  LeadsVariancePopulationRentOrOwnDesc = 'LEADS_VARIANCE_POPULATION_RENT_OR_OWN_DESC',
  LeadsVariancePopulationEmploymentStatusAsc = 'LEADS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_ASC',
  LeadsVariancePopulationEmploymentStatusDesc = 'LEADS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_DESC',
  LeadsVariancePopulationIncomeAmountAsc = 'LEADS_VARIANCE_POPULATION_INCOME_AMOUNT_ASC',
  LeadsVariancePopulationIncomeAmountDesc = 'LEADS_VARIANCE_POPULATION_INCOME_AMOUNT_DESC',
  LeadsVariancePopulationMonthlyHomePaymentAsc = 'LEADS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LeadsVariancePopulationMonthlyHomePaymentDesc = 'LEADS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LeadsVariancePopulationSmsConsentAsc = 'LEADS_VARIANCE_POPULATION_SMS_CONSENT_ASC',
  LeadsVariancePopulationSmsConsentDesc = 'LEADS_VARIANCE_POPULATION_SMS_CONSENT_DESC',
  LeadsVariancePopulationIncomeTimeYearsAsc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_YEARS_ASC',
  LeadsVariancePopulationIncomeTimeYearsDesc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_YEARS_DESC',
  LeadsVariancePopulationIncomeTimeMonthsAsc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_MONTHS_ASC',
  LeadsVariancePopulationIncomeTimeMonthsDesc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_MONTHS_DESC',
  LeadsVariancePopulationResidenceTimeYearsAsc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_YEARS_ASC',
  LeadsVariancePopulationResidenceTimeYearsDesc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_YEARS_DESC',
  LeadsVariancePopulationResidenceTimeMonthsAsc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_MONTHS_ASC',
  LeadsVariancePopulationResidenceTimeMonthsDesc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_MONTHS_DESC',
  FieldsCountAsc = 'FIELDS_COUNT_ASC',
  FieldsCountDesc = 'FIELDS_COUNT_DESC',
  FieldsSumIdAsc = 'FIELDS_SUM_ID_ASC',
  FieldsSumIdDesc = 'FIELDS_SUM_ID_DESC',
  FieldsSumCompanyIdAsc = 'FIELDS_SUM_COMPANY_ID_ASC',
  FieldsSumCompanyIdDesc = 'FIELDS_SUM_COMPANY_ID_DESC',
  FieldsSumCompanyProductIdAsc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_ASC',
  FieldsSumCompanyProductIdDesc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_DESC',
  FieldsSumCompanyChannelIdAsc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_ASC',
  FieldsSumCompanyChannelIdDesc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_DESC',
  FieldsSumCompanyIntegrationIdAsc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_ASC',
  FieldsSumCompanyIntegrationIdDesc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_DESC',
  FieldsSumConfigAsc = 'FIELDS_SUM_CONFIG_ASC',
  FieldsSumConfigDesc = 'FIELDS_SUM_CONFIG_DESC',
  FieldsSumTopicAsc = 'FIELDS_SUM_TOPIC_ASC',
  FieldsSumTopicDesc = 'FIELDS_SUM_TOPIC_DESC',
  FieldsSumValueAsc = 'FIELDS_SUM_VALUE_ASC',
  FieldsSumValueDesc = 'FIELDS_SUM_VALUE_DESC',
  FieldsSumCreatedAtAsc = 'FIELDS_SUM_CREATED_AT_ASC',
  FieldsSumCreatedAtDesc = 'FIELDS_SUM_CREATED_AT_DESC',
  FieldsSumUpdatedAtAsc = 'FIELDS_SUM_UPDATED_AT_ASC',
  FieldsSumUpdatedAtDesc = 'FIELDS_SUM_UPDATED_AT_DESC',
  FieldsDistinctCountIdAsc = 'FIELDS_DISTINCT_COUNT_ID_ASC',
  FieldsDistinctCountIdDesc = 'FIELDS_DISTINCT_COUNT_ID_DESC',
  FieldsDistinctCountCompanyIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_ASC',
  FieldsDistinctCountCompanyIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_DESC',
  FieldsDistinctCountCompanyProductIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  FieldsDistinctCountCompanyProductIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  FieldsDistinctCountCompanyChannelIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  FieldsDistinctCountCompanyChannelIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  FieldsDistinctCountCompanyIntegrationIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  FieldsDistinctCountCompanyIntegrationIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  FieldsDistinctCountConfigAsc = 'FIELDS_DISTINCT_COUNT_CONFIG_ASC',
  FieldsDistinctCountConfigDesc = 'FIELDS_DISTINCT_COUNT_CONFIG_DESC',
  FieldsDistinctCountTopicAsc = 'FIELDS_DISTINCT_COUNT_TOPIC_ASC',
  FieldsDistinctCountTopicDesc = 'FIELDS_DISTINCT_COUNT_TOPIC_DESC',
  FieldsDistinctCountValueAsc = 'FIELDS_DISTINCT_COUNT_VALUE_ASC',
  FieldsDistinctCountValueDesc = 'FIELDS_DISTINCT_COUNT_VALUE_DESC',
  FieldsDistinctCountCreatedAtAsc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldsDistinctCountCreatedAtDesc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldsDistinctCountUpdatedAtAsc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldsDistinctCountUpdatedAtDesc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldsMinIdAsc = 'FIELDS_MIN_ID_ASC',
  FieldsMinIdDesc = 'FIELDS_MIN_ID_DESC',
  FieldsMinCompanyIdAsc = 'FIELDS_MIN_COMPANY_ID_ASC',
  FieldsMinCompanyIdDesc = 'FIELDS_MIN_COMPANY_ID_DESC',
  FieldsMinCompanyProductIdAsc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_ASC',
  FieldsMinCompanyProductIdDesc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_DESC',
  FieldsMinCompanyChannelIdAsc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_ASC',
  FieldsMinCompanyChannelIdDesc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_DESC',
  FieldsMinCompanyIntegrationIdAsc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_ASC',
  FieldsMinCompanyIntegrationIdDesc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_DESC',
  FieldsMinConfigAsc = 'FIELDS_MIN_CONFIG_ASC',
  FieldsMinConfigDesc = 'FIELDS_MIN_CONFIG_DESC',
  FieldsMinTopicAsc = 'FIELDS_MIN_TOPIC_ASC',
  FieldsMinTopicDesc = 'FIELDS_MIN_TOPIC_DESC',
  FieldsMinValueAsc = 'FIELDS_MIN_VALUE_ASC',
  FieldsMinValueDesc = 'FIELDS_MIN_VALUE_DESC',
  FieldsMinCreatedAtAsc = 'FIELDS_MIN_CREATED_AT_ASC',
  FieldsMinCreatedAtDesc = 'FIELDS_MIN_CREATED_AT_DESC',
  FieldsMinUpdatedAtAsc = 'FIELDS_MIN_UPDATED_AT_ASC',
  FieldsMinUpdatedAtDesc = 'FIELDS_MIN_UPDATED_AT_DESC',
  FieldsMaxIdAsc = 'FIELDS_MAX_ID_ASC',
  FieldsMaxIdDesc = 'FIELDS_MAX_ID_DESC',
  FieldsMaxCompanyIdAsc = 'FIELDS_MAX_COMPANY_ID_ASC',
  FieldsMaxCompanyIdDesc = 'FIELDS_MAX_COMPANY_ID_DESC',
  FieldsMaxCompanyProductIdAsc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_ASC',
  FieldsMaxCompanyProductIdDesc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_DESC',
  FieldsMaxCompanyChannelIdAsc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_ASC',
  FieldsMaxCompanyChannelIdDesc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_DESC',
  FieldsMaxCompanyIntegrationIdAsc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_ASC',
  FieldsMaxCompanyIntegrationIdDesc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_DESC',
  FieldsMaxConfigAsc = 'FIELDS_MAX_CONFIG_ASC',
  FieldsMaxConfigDesc = 'FIELDS_MAX_CONFIG_DESC',
  FieldsMaxTopicAsc = 'FIELDS_MAX_TOPIC_ASC',
  FieldsMaxTopicDesc = 'FIELDS_MAX_TOPIC_DESC',
  FieldsMaxValueAsc = 'FIELDS_MAX_VALUE_ASC',
  FieldsMaxValueDesc = 'FIELDS_MAX_VALUE_DESC',
  FieldsMaxCreatedAtAsc = 'FIELDS_MAX_CREATED_AT_ASC',
  FieldsMaxCreatedAtDesc = 'FIELDS_MAX_CREATED_AT_DESC',
  FieldsMaxUpdatedAtAsc = 'FIELDS_MAX_UPDATED_AT_ASC',
  FieldsMaxUpdatedAtDesc = 'FIELDS_MAX_UPDATED_AT_DESC',
  FieldsAverageIdAsc = 'FIELDS_AVERAGE_ID_ASC',
  FieldsAverageIdDesc = 'FIELDS_AVERAGE_ID_DESC',
  FieldsAverageCompanyIdAsc = 'FIELDS_AVERAGE_COMPANY_ID_ASC',
  FieldsAverageCompanyIdDesc = 'FIELDS_AVERAGE_COMPANY_ID_DESC',
  FieldsAverageCompanyProductIdAsc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  FieldsAverageCompanyProductIdDesc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  FieldsAverageCompanyChannelIdAsc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  FieldsAverageCompanyChannelIdDesc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  FieldsAverageCompanyIntegrationIdAsc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  FieldsAverageCompanyIntegrationIdDesc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  FieldsAverageConfigAsc = 'FIELDS_AVERAGE_CONFIG_ASC',
  FieldsAverageConfigDesc = 'FIELDS_AVERAGE_CONFIG_DESC',
  FieldsAverageTopicAsc = 'FIELDS_AVERAGE_TOPIC_ASC',
  FieldsAverageTopicDesc = 'FIELDS_AVERAGE_TOPIC_DESC',
  FieldsAverageValueAsc = 'FIELDS_AVERAGE_VALUE_ASC',
  FieldsAverageValueDesc = 'FIELDS_AVERAGE_VALUE_DESC',
  FieldsAverageCreatedAtAsc = 'FIELDS_AVERAGE_CREATED_AT_ASC',
  FieldsAverageCreatedAtDesc = 'FIELDS_AVERAGE_CREATED_AT_DESC',
  FieldsAverageUpdatedAtAsc = 'FIELDS_AVERAGE_UPDATED_AT_ASC',
  FieldsAverageUpdatedAtDesc = 'FIELDS_AVERAGE_UPDATED_AT_DESC',
  FieldsStddevSampleIdAsc = 'FIELDS_STDDEV_SAMPLE_ID_ASC',
  FieldsStddevSampleIdDesc = 'FIELDS_STDDEV_SAMPLE_ID_DESC',
  FieldsStddevSampleCompanyIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FieldsStddevSampleCompanyIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FieldsStddevSampleCompanyProductIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevSampleCompanyProductIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevSampleCompanyChannelIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevSampleCompanyChannelIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevSampleCompanyIntegrationIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevSampleCompanyIntegrationIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevSampleConfigAsc = 'FIELDS_STDDEV_SAMPLE_CONFIG_ASC',
  FieldsStddevSampleConfigDesc = 'FIELDS_STDDEV_SAMPLE_CONFIG_DESC',
  FieldsStddevSampleTopicAsc = 'FIELDS_STDDEV_SAMPLE_TOPIC_ASC',
  FieldsStddevSampleTopicDesc = 'FIELDS_STDDEV_SAMPLE_TOPIC_DESC',
  FieldsStddevSampleValueAsc = 'FIELDS_STDDEV_SAMPLE_VALUE_ASC',
  FieldsStddevSampleValueDesc = 'FIELDS_STDDEV_SAMPLE_VALUE_DESC',
  FieldsStddevSampleCreatedAtAsc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldsStddevSampleCreatedAtDesc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldsStddevSampleUpdatedAtAsc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldsStddevSampleUpdatedAtDesc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldsStddevPopulationIdAsc = 'FIELDS_STDDEV_POPULATION_ID_ASC',
  FieldsStddevPopulationIdDesc = 'FIELDS_STDDEV_POPULATION_ID_DESC',
  FieldsStddevPopulationCompanyIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_ASC',
  FieldsStddevPopulationCompanyIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_DESC',
  FieldsStddevPopulationCompanyProductIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevPopulationCompanyProductIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevPopulationCompanyChannelIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevPopulationCompanyChannelIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevPopulationCompanyIntegrationIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevPopulationCompanyIntegrationIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevPopulationConfigAsc = 'FIELDS_STDDEV_POPULATION_CONFIG_ASC',
  FieldsStddevPopulationConfigDesc = 'FIELDS_STDDEV_POPULATION_CONFIG_DESC',
  FieldsStddevPopulationTopicAsc = 'FIELDS_STDDEV_POPULATION_TOPIC_ASC',
  FieldsStddevPopulationTopicDesc = 'FIELDS_STDDEV_POPULATION_TOPIC_DESC',
  FieldsStddevPopulationValueAsc = 'FIELDS_STDDEV_POPULATION_VALUE_ASC',
  FieldsStddevPopulationValueDesc = 'FIELDS_STDDEV_POPULATION_VALUE_DESC',
  FieldsStddevPopulationCreatedAtAsc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldsStddevPopulationCreatedAtDesc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldsStddevPopulationUpdatedAtAsc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldsStddevPopulationUpdatedAtDesc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldsVarianceSampleIdAsc = 'FIELDS_VARIANCE_SAMPLE_ID_ASC',
  FieldsVarianceSampleIdDesc = 'FIELDS_VARIANCE_SAMPLE_ID_DESC',
  FieldsVarianceSampleCompanyIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FieldsVarianceSampleCompanyIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FieldsVarianceSampleCompanyProductIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsVarianceSampleCompanyProductIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsVarianceSampleCompanyChannelIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsVarianceSampleCompanyChannelIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsVarianceSampleCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsVarianceSampleCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsVarianceSampleConfigAsc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_ASC',
  FieldsVarianceSampleConfigDesc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_DESC',
  FieldsVarianceSampleTopicAsc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_ASC',
  FieldsVarianceSampleTopicDesc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_DESC',
  FieldsVarianceSampleValueAsc = 'FIELDS_VARIANCE_SAMPLE_VALUE_ASC',
  FieldsVarianceSampleValueDesc = 'FIELDS_VARIANCE_SAMPLE_VALUE_DESC',
  FieldsVarianceSampleCreatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldsVarianceSampleCreatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldsVarianceSampleUpdatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldsVarianceSampleUpdatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldsVariancePopulationIdAsc = 'FIELDS_VARIANCE_POPULATION_ID_ASC',
  FieldsVariancePopulationIdDesc = 'FIELDS_VARIANCE_POPULATION_ID_DESC',
  FieldsVariancePopulationCompanyIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FieldsVariancePopulationCompanyIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FieldsVariancePopulationCompanyProductIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsVariancePopulationCompanyProductIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsVariancePopulationCompanyChannelIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsVariancePopulationCompanyChannelIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsVariancePopulationCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsVariancePopulationCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsVariancePopulationConfigAsc = 'FIELDS_VARIANCE_POPULATION_CONFIG_ASC',
  FieldsVariancePopulationConfigDesc = 'FIELDS_VARIANCE_POPULATION_CONFIG_DESC',
  FieldsVariancePopulationTopicAsc = 'FIELDS_VARIANCE_POPULATION_TOPIC_ASC',
  FieldsVariancePopulationTopicDesc = 'FIELDS_VARIANCE_POPULATION_TOPIC_DESC',
  FieldsVariancePopulationValueAsc = 'FIELDS_VARIANCE_POPULATION_VALUE_ASC',
  FieldsVariancePopulationValueDesc = 'FIELDS_VARIANCE_POPULATION_VALUE_DESC',
  FieldsVariancePopulationCreatedAtAsc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldsVariancePopulationCreatedAtDesc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldsVariancePopulationUpdatedAtAsc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldsVariancePopulationUpdatedAtDesc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyChannelMediasCountAsc = 'COMPANY_CHANNEL_MEDIAS_COUNT_ASC',
  CompanyChannelMediasCountDesc = 'COMPANY_CHANNEL_MEDIAS_COUNT_DESC',
  CompanyChannelMediasSumIdAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_ID_ASC',
  CompanyChannelMediasSumIdDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_ID_DESC',
  CompanyChannelMediasSumCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasSumCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasSumMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_MEDIA_ID_ASC',
  CompanyChannelMediasSumMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_MEDIA_ID_DESC',
  CompanyChannelMediasSumAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_ASSIGNEE_ID_ASC',
  CompanyChannelMediasSumAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_ASSIGNEE_ID_DESC',
  CompanyChannelMediasSumCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_CREATED_AT_ASC',
  CompanyChannelMediasSumCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_CREATED_AT_DESC',
  CompanyChannelMediasSumUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_UPDATED_AT_ASC',
  CompanyChannelMediasSumUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_UPDATED_AT_DESC',
  CompanyChannelMediasSumDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_DELETED_AT_ASC',
  CompanyChannelMediasSumDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_DELETED_AT_DESC',
  CompanyChannelMediasDistinctCountIdAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_ID_ASC',
  CompanyChannelMediasDistinctCountIdDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_ID_DESC',
  CompanyChannelMediasDistinctCountCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasDistinctCountCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasDistinctCountMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_MEDIA_ID_ASC',
  CompanyChannelMediasDistinctCountMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_MEDIA_ID_DESC',
  CompanyChannelMediasDistinctCountAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  CompanyChannelMediasDistinctCountAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  CompanyChannelMediasDistinctCountCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyChannelMediasDistinctCountCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyChannelMediasDistinctCountUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyChannelMediasDistinctCountUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyChannelMediasDistinctCountDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_DELETED_AT_ASC',
  CompanyChannelMediasDistinctCountDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_DELETED_AT_DESC',
  CompanyChannelMediasMinIdAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_ID_ASC',
  CompanyChannelMediasMinIdDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_ID_DESC',
  CompanyChannelMediasMinCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasMinCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasMinMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_MEDIA_ID_ASC',
  CompanyChannelMediasMinMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_MEDIA_ID_DESC',
  CompanyChannelMediasMinAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_ASSIGNEE_ID_ASC',
  CompanyChannelMediasMinAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_ASSIGNEE_ID_DESC',
  CompanyChannelMediasMinCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_CREATED_AT_ASC',
  CompanyChannelMediasMinCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_CREATED_AT_DESC',
  CompanyChannelMediasMinUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_UPDATED_AT_ASC',
  CompanyChannelMediasMinUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_UPDATED_AT_DESC',
  CompanyChannelMediasMinDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_DELETED_AT_ASC',
  CompanyChannelMediasMinDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_DELETED_AT_DESC',
  CompanyChannelMediasMaxIdAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_ID_ASC',
  CompanyChannelMediasMaxIdDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_ID_DESC',
  CompanyChannelMediasMaxCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasMaxCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasMaxMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_MEDIA_ID_ASC',
  CompanyChannelMediasMaxMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_MEDIA_ID_DESC',
  CompanyChannelMediasMaxAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_ASSIGNEE_ID_ASC',
  CompanyChannelMediasMaxAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_ASSIGNEE_ID_DESC',
  CompanyChannelMediasMaxCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_CREATED_AT_ASC',
  CompanyChannelMediasMaxCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_CREATED_AT_DESC',
  CompanyChannelMediasMaxUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_UPDATED_AT_ASC',
  CompanyChannelMediasMaxUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_UPDATED_AT_DESC',
  CompanyChannelMediasMaxDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_DELETED_AT_ASC',
  CompanyChannelMediasMaxDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_DELETED_AT_DESC',
  CompanyChannelMediasAverageIdAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_ID_ASC',
  CompanyChannelMediasAverageIdDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_ID_DESC',
  CompanyChannelMediasAverageCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasAverageCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasAverageMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_MEDIA_ID_ASC',
  CompanyChannelMediasAverageMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_MEDIA_ID_DESC',
  CompanyChannelMediasAverageAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_ASSIGNEE_ID_ASC',
  CompanyChannelMediasAverageAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_ASSIGNEE_ID_DESC',
  CompanyChannelMediasAverageCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_CREATED_AT_ASC',
  CompanyChannelMediasAverageCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_CREATED_AT_DESC',
  CompanyChannelMediasAverageUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_UPDATED_AT_ASC',
  CompanyChannelMediasAverageUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_UPDATED_AT_DESC',
  CompanyChannelMediasAverageDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_DELETED_AT_ASC',
  CompanyChannelMediasAverageDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_DELETED_AT_DESC',
  CompanyChannelMediasStddevSampleIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_ID_ASC',
  CompanyChannelMediasStddevSampleIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_ID_DESC',
  CompanyChannelMediasStddevSampleCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasStddevSampleCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasStddevSampleMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_MEDIA_ID_ASC',
  CompanyChannelMediasStddevSampleMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_MEDIA_ID_DESC',
  CompanyChannelMediasStddevSampleAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  CompanyChannelMediasStddevSampleAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  CompanyChannelMediasStddevSampleCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyChannelMediasStddevSampleCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyChannelMediasStddevSampleUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelMediasStddevSampleUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelMediasStddevSampleDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_DELETED_AT_ASC',
  CompanyChannelMediasStddevSampleDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_DELETED_AT_DESC',
  CompanyChannelMediasStddevPopulationIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_ID_ASC',
  CompanyChannelMediasStddevPopulationIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_ID_DESC',
  CompanyChannelMediasStddevPopulationCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasStddevPopulationCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasStddevPopulationMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_MEDIA_ID_ASC',
  CompanyChannelMediasStddevPopulationMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_MEDIA_ID_DESC',
  CompanyChannelMediasStddevPopulationAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  CompanyChannelMediasStddevPopulationAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  CompanyChannelMediasStddevPopulationCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyChannelMediasStddevPopulationCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyChannelMediasStddevPopulationUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyChannelMediasStddevPopulationUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyChannelMediasStddevPopulationDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_DELETED_AT_ASC',
  CompanyChannelMediasStddevPopulationDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_DELETED_AT_DESC',
  CompanyChannelMediasVarianceSampleIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_ID_ASC',
  CompanyChannelMediasVarianceSampleIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_ID_DESC',
  CompanyChannelMediasVarianceSampleCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasVarianceSampleCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasVarianceSampleMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_MEDIA_ID_ASC',
  CompanyChannelMediasVarianceSampleMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_MEDIA_ID_DESC',
  CompanyChannelMediasVarianceSampleAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  CompanyChannelMediasVarianceSampleAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  CompanyChannelMediasVarianceSampleCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyChannelMediasVarianceSampleCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyChannelMediasVarianceSampleUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelMediasVarianceSampleUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelMediasVarianceSampleDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  CompanyChannelMediasVarianceSampleDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  CompanyChannelMediasVariancePopulationIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_ID_ASC',
  CompanyChannelMediasVariancePopulationIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_ID_DESC',
  CompanyChannelMediasVariancePopulationCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasVariancePopulationCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasVariancePopulationMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_MEDIA_ID_ASC',
  CompanyChannelMediasVariancePopulationMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_MEDIA_ID_DESC',
  CompanyChannelMediasVariancePopulationAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  CompanyChannelMediasVariancePopulationAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  CompanyChannelMediasVariancePopulationCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyChannelMediasVariancePopulationCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyChannelMediasVariancePopulationUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyChannelMediasVariancePopulationUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyChannelMediasVariancePopulationDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_DELETED_AT_ASC',
  CompanyChannelMediasVariancePopulationDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_DELETED_AT_DESC',
  DistributorRelationshipsCountAsc = 'DISTRIBUTOR_RELATIONSHIPS_COUNT_ASC',
  DistributorRelationshipsCountDesc = 'DISTRIBUTOR_RELATIONSHIPS_COUNT_DESC',
  DistributorRelationshipsSumIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_ID_ASC',
  DistributorRelationshipsSumIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_ID_DESC',
  DistributorRelationshipsSumDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsSumDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsSumCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_COMPANY_ID_ASC',
  DistributorRelationshipsSumCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_COMPANY_ID_DESC',
  DistributorRelationshipsSumCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsSumCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsSumCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_CREATED_AT_ASC',
  DistributorRelationshipsSumCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_CREATED_AT_DESC',
  DistributorRelationshipsSumUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_UPDATED_AT_ASC',
  DistributorRelationshipsSumUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_UPDATED_AT_DESC',
  DistributorRelationshipsSumStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_STRATEGY_ASC',
  DistributorRelationshipsSumStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_STRATEGY_DESC',
  DistributorRelationshipsSumMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsSumMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_SUM_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsDistinctCountIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_ID_ASC',
  DistributorRelationshipsDistinctCountIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_ID_DESC',
  DistributorRelationshipsDistinctCountDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsDistinctCountDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsDistinctCountCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_COMPANY_ID_ASC',
  DistributorRelationshipsDistinctCountCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_COMPANY_ID_DESC',
  DistributorRelationshipsDistinctCountCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsDistinctCountCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsDistinctCountCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_CREATED_AT_ASC',
  DistributorRelationshipsDistinctCountCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_CREATED_AT_DESC',
  DistributorRelationshipsDistinctCountUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  DistributorRelationshipsDistinctCountUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  DistributorRelationshipsDistinctCountStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_STRATEGY_ASC',
  DistributorRelationshipsDistinctCountStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_STRATEGY_DESC',
  DistributorRelationshipsDistinctCountMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsDistinctCountMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_DISTINCT_COUNT_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsMinIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_ID_ASC',
  DistributorRelationshipsMinIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_ID_DESC',
  DistributorRelationshipsMinDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsMinDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsMinCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_COMPANY_ID_ASC',
  DistributorRelationshipsMinCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_COMPANY_ID_DESC',
  DistributorRelationshipsMinCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsMinCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsMinCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_CREATED_AT_ASC',
  DistributorRelationshipsMinCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_CREATED_AT_DESC',
  DistributorRelationshipsMinUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_UPDATED_AT_ASC',
  DistributorRelationshipsMinUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_UPDATED_AT_DESC',
  DistributorRelationshipsMinStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_STRATEGY_ASC',
  DistributorRelationshipsMinStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_STRATEGY_DESC',
  DistributorRelationshipsMinMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsMinMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_MIN_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsMaxIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_ID_ASC',
  DistributorRelationshipsMaxIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_ID_DESC',
  DistributorRelationshipsMaxDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsMaxDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsMaxCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_COMPANY_ID_ASC',
  DistributorRelationshipsMaxCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_COMPANY_ID_DESC',
  DistributorRelationshipsMaxCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsMaxCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsMaxCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_CREATED_AT_ASC',
  DistributorRelationshipsMaxCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_CREATED_AT_DESC',
  DistributorRelationshipsMaxUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_UPDATED_AT_ASC',
  DistributorRelationshipsMaxUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_UPDATED_AT_DESC',
  DistributorRelationshipsMaxStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_STRATEGY_ASC',
  DistributorRelationshipsMaxStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_STRATEGY_DESC',
  DistributorRelationshipsMaxMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsMaxMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_MAX_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsAverageIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_ID_ASC',
  DistributorRelationshipsAverageIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_ID_DESC',
  DistributorRelationshipsAverageDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsAverageDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsAverageCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_COMPANY_ID_ASC',
  DistributorRelationshipsAverageCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_COMPANY_ID_DESC',
  DistributorRelationshipsAverageCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsAverageCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsAverageCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_CREATED_AT_ASC',
  DistributorRelationshipsAverageCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_CREATED_AT_DESC',
  DistributorRelationshipsAverageUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_UPDATED_AT_ASC',
  DistributorRelationshipsAverageUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_UPDATED_AT_DESC',
  DistributorRelationshipsAverageStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_STRATEGY_ASC',
  DistributorRelationshipsAverageStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_STRATEGY_DESC',
  DistributorRelationshipsAverageMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsAverageMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_AVERAGE_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsStddevSampleIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_ID_ASC',
  DistributorRelationshipsStddevSampleIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_ID_DESC',
  DistributorRelationshipsStddevSampleDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsStddevSampleDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsStddevSampleCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  DistributorRelationshipsStddevSampleCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  DistributorRelationshipsStddevSampleCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsStddevSampleCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsStddevSampleCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  DistributorRelationshipsStddevSampleCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  DistributorRelationshipsStddevSampleUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DistributorRelationshipsStddevSampleUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DistributorRelationshipsStddevSampleStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_STRATEGY_ASC',
  DistributorRelationshipsStddevSampleStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_STRATEGY_DESC',
  DistributorRelationshipsStddevSampleMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsStddevSampleMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_SAMPLE_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsStddevPopulationIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_ID_ASC',
  DistributorRelationshipsStddevPopulationIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_ID_DESC',
  DistributorRelationshipsStddevPopulationDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsStddevPopulationDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsStddevPopulationCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_COMPANY_ID_ASC',
  DistributorRelationshipsStddevPopulationCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_COMPANY_ID_DESC',
  DistributorRelationshipsStddevPopulationCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsStddevPopulationCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsStddevPopulationCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_CREATED_AT_ASC',
  DistributorRelationshipsStddevPopulationCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_CREATED_AT_DESC',
  DistributorRelationshipsStddevPopulationUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  DistributorRelationshipsStddevPopulationUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  DistributorRelationshipsStddevPopulationStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_STRATEGY_ASC',
  DistributorRelationshipsStddevPopulationStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_STRATEGY_DESC',
  DistributorRelationshipsStddevPopulationMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsStddevPopulationMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_STDDEV_POPULATION_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsVarianceSampleIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_ID_ASC',
  DistributorRelationshipsVarianceSampleIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_ID_DESC',
  DistributorRelationshipsVarianceSampleDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsVarianceSampleDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsVarianceSampleCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  DistributorRelationshipsVarianceSampleCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  DistributorRelationshipsVarianceSampleCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsVarianceSampleCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsVarianceSampleCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DistributorRelationshipsVarianceSampleCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DistributorRelationshipsVarianceSampleUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DistributorRelationshipsVarianceSampleUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DistributorRelationshipsVarianceSampleStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_STRATEGY_ASC',
  DistributorRelationshipsVarianceSampleStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_STRATEGY_DESC',
  DistributorRelationshipsVarianceSampleMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsVarianceSampleMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_SAMPLE_MAX_DISTANCE_KM_DESC',
  DistributorRelationshipsVariancePopulationIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_ID_ASC',
  DistributorRelationshipsVariancePopulationIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_ID_DESC',
  DistributorRelationshipsVariancePopulationDistributorCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorRelationshipsVariancePopulationDistributorCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_DISTRIBUTOR_COMPANY_ID_DESC',
  DistributorRelationshipsVariancePopulationCompanyIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  DistributorRelationshipsVariancePopulationCompanyIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  DistributorRelationshipsVariancePopulationCompanyChannelIdAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  DistributorRelationshipsVariancePopulationCompanyChannelIdDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  DistributorRelationshipsVariancePopulationCreatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  DistributorRelationshipsVariancePopulationCreatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  DistributorRelationshipsVariancePopulationUpdatedAtAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DistributorRelationshipsVariancePopulationUpdatedAtDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DistributorRelationshipsVariancePopulationStrategyAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_STRATEGY_ASC',
  DistributorRelationshipsVariancePopulationStrategyDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_STRATEGY_DESC',
  DistributorRelationshipsVariancePopulationMaxDistanceKmAsc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_MAX_DISTANCE_KM_ASC',
  DistributorRelationshipsVariancePopulationMaxDistanceKmDesc = 'DISTRIBUTOR_RELATIONSHIPS_VARIANCE_POPULATION_MAX_DISTANCE_KM_DESC',
  DistributedLeadsByOriginalCompanyChannelIdCountAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_COUNT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdCountDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_COUNT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdSumIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdSumIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdSumLeadIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_LEAD_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdSumLeadIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_LEAD_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdSumFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_FROM_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdSumFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_FROM_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdSumToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_TO_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdSumToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_TO_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdSumOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdSumOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdSumCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_CREATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdSumCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_CREATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdSumUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_UPDATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdSumUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_SUM_UPDATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountLeadIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_LEAD_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountLeadIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_LEAD_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_FROM_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_FROM_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_TO_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_TO_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdDistinctCountUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMinIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMinIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMinLeadIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_LEAD_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMinLeadIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_LEAD_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMinFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_FROM_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMinFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_FROM_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMinToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_TO_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMinToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_TO_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMinOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMinOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMinCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_CREATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMinCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_CREATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMinUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_UPDATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMinUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MIN_UPDATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMaxIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMaxIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMaxLeadIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_LEAD_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMaxLeadIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_LEAD_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMaxFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_FROM_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMaxFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_FROM_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMaxToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_TO_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMaxToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_TO_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMaxOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMaxOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMaxCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_CREATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMaxCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_CREATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdMaxUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_UPDATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdMaxUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_MAX_UPDATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdAverageIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdAverageIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdAverageLeadIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_LEAD_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdAverageLeadIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_LEAD_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdAverageFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_FROM_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdAverageFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_FROM_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdAverageToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_TO_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdAverageToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_TO_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdAverageOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdAverageOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdAverageCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_CREATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdAverageCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_CREATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdAverageUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_UPDATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdAverageUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_AVERAGE_UPDATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleLeadIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_LEAD_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleLeadIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_LEAD_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_FROM_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_FROM_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_TO_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_TO_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevSampleUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationLeadIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_LEAD_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationLeadIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_LEAD_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_FROM_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_FROM_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_TO_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_TO_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdStddevPopulationUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleLeadIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_LEAD_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleLeadIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_LEAD_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_FROM_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_FROM_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_TO_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_TO_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVarianceSampleUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationLeadIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_LEAD_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationLeadIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_LEAD_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationFromCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_FROM_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationFromCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_FROM_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationToCompanyIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_TO_COMPANY_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationToCompanyIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_TO_COMPANY_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationCreatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationCreatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationUpdatedAtAsc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DistributedLeadsByOriginalCompanyChannelIdVariancePopulationUpdatedAtDesc = 'DISTRIBUTED_LEADS_BY_ORIGINAL_COMPANY_CHANNEL_ID_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type CompanyCompaniesByDistributedLeadFromCompanyIdAndToCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompaniesByDistributedLeadFromCompanyIdAndToCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type CompanyCompaniesByDistributedLeadFromCompanyIdAndToCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type CompanyCompaniesByDistributedLeadFromCompanyIdAndToCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByToCompanyId: DistributedLeadsConnection;
};


/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type CompanyCompaniesByDistributedLeadFromCompanyIdAndToCompanyIdManyToManyEdgeDistributedLeadsByToCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type CompanyCompaniesByDistributedLeadToCompanyIdAndFromCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompaniesByDistributedLeadToCompanyIdAndFromCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type CompanyCompaniesByDistributedLeadToCompanyIdAndFromCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type CompanyCompaniesByDistributedLeadToCompanyIdAndFromCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByFromCompanyId: DistributedLeadsConnection;
};


/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type CompanyCompaniesByDistributedLeadToCompanyIdAndFromCompanyIdManyToManyEdgeDistributedLeadsByFromCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

/** A connection to a list of `Company` values, with data from `DistributorRelationship`. */
export type CompanyCompaniesByDistributorRelationshipCompanyIdAndDistributorCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `DistributorRelationship`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompaniesByDistributorRelationshipCompanyIdAndDistributorCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `DistributorRelationship`. */
export type CompanyCompaniesByDistributorRelationshipCompanyIdAndDistributorCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `DistributorRelationship`. */
export type CompanyCompaniesByDistributorRelationshipCompanyIdAndDistributorCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  id: Scalars['Int'];
  companyChannelId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  strategy: DistributionStrategy;
  maxDistanceKm: Scalars['Int'];
};

/** A connection to a list of `Company` values, with data from `DistributorRelationship`. */
export type CompanyCompaniesByDistributorRelationshipDistributorCompanyIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `DistributorRelationship`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompaniesByDistributorRelationshipDistributorCompanyIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `DistributorRelationship`. */
export type CompanyCompaniesByDistributorRelationshipDistributorCompanyIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `DistributorRelationship`. */
export type CompanyCompaniesByDistributorRelationshipDistributorCompanyIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  id: Scalars['Int'];
  companyChannelId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  strategy: DistributionStrategy;
  maxDistanceKm: Scalars['Int'];
};

/** A connection to a list of `CompanyChannel` values, with data from `DistributedLead`. */
export type CompanyCompanyChannelsByDistributedLeadFromCompanyIdAndOriginalCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyChannelsByDistributedLeadFromCompanyIdAndOriginalCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `DistributedLead`. */
export type CompanyCompanyChannelsByDistributedLeadFromCompanyIdAndOriginalCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `DistributedLead`. */
export type CompanyCompanyChannelsByDistributedLeadFromCompanyIdAndOriginalCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByOriginalCompanyChannelId: DistributedLeadsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `DistributedLead`. */
export type CompanyCompanyChannelsByDistributedLeadFromCompanyIdAndOriginalCompanyChannelIdManyToManyEdgeDistributedLeadsByOriginalCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `DistributedLead`. */
export type CompanyCompanyChannelsByDistributedLeadToCompanyIdAndOriginalCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyChannelsByDistributedLeadToCompanyIdAndOriginalCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `DistributedLead`. */
export type CompanyCompanyChannelsByDistributedLeadToCompanyIdAndOriginalCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `DistributedLead`. */
export type CompanyCompanyChannelsByDistributedLeadToCompanyIdAndOriginalCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByOriginalCompanyChannelId: DistributedLeadsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `DistributedLead`. */
export type CompanyCompanyChannelsByDistributedLeadToCompanyIdAndOriginalCompanyChannelIdManyToManyEdgeDistributedLeadsByOriginalCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `DistributorRelationship`. */
export type CompanyCompanyChannelsByDistributorRelationshipCompanyIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `DistributorRelationship`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyChannelsByDistributorRelationshipCompanyIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `DistributorRelationship`. */
export type CompanyCompanyChannelsByDistributorRelationshipCompanyIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `DistributorRelationship`. */
export type CompanyCompanyChannelsByDistributorRelationshipCompanyIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `DistributorRelationship`. */
  distributorRelationships: DistributorRelationshipsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `DistributorRelationship`. */
export type CompanyCompanyChannelsByDistributorRelationshipCompanyIdAndCompanyChannelIdManyToManyEdgeDistributorRelationshipsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributorRelationshipsOrderBy>>;
  condition?: Maybe<DistributorRelationshipCondition>;
  filter?: Maybe<DistributorRelationshipFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `DistributorRelationship`. */
export type CompanyCompanyChannelsByDistributorRelationshipDistributorCompanyIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `DistributorRelationship`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyChannelsByDistributorRelationshipDistributorCompanyIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `DistributorRelationship`. */
export type CompanyCompanyChannelsByDistributorRelationshipDistributorCompanyIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `DistributorRelationship`. */
export type CompanyCompanyChannelsByDistributorRelationshipDistributorCompanyIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `DistributorRelationship`. */
  distributorRelationships: DistributorRelationshipsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `DistributorRelationship`. */
export type CompanyCompanyChannelsByDistributorRelationshipDistributorCompanyIdAndCompanyChannelIdManyToManyEdgeDistributorRelationshipsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributorRelationshipsOrderBy>>;
  condition?: Maybe<DistributorRelationshipCondition>;
  filter?: Maybe<DistributorRelationshipFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `Lead`. */
export type CompanyCompanyChannelsByLeadCompanyIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `Lead`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyChannelsByLeadCompanyIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `Lead`. */
export type CompanyCompanyChannelsByLeadCompanyIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `Lead`. */
export type CompanyCompanyChannelsByLeadCompanyIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `Lead`. */
  leads: LeadsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `Lead`. */
export type CompanyCompanyChannelsByLeadCompanyIdAndCompanyChannelIdManyToManyEdgeLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

/** A connection to a list of `CompanyDomain` values, with data from `CompanyDomainsApproved`. */
export type CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdManyToManyConnection = {
  /** A list of `CompanyDomain` objects. */
  nodes: Array<CompanyDomain>;
  /** A list of edges which contains the `CompanyDomain`, info from the `CompanyDomainsApproved`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyDomain` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyDomainAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyDomainAggregates>>;
};


/** A connection to a list of `CompanyDomain` values, with data from `CompanyDomainsApproved`. */
export type CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyDomainsGroupBy>;
  having?: Maybe<CompanyDomainsHavingInput>;
};

/** A `CompanyDomain` edge in the connection, with data from `CompanyDomainsApproved`. */
export type CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyDomain` at the end of the edge. */
  node: CompanyDomain;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `CompanyDomain` values, with data from `CompanyDomainsPath`. */
export type CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyConnection = {
  /** A list of `CompanyDomain` objects. */
  nodes: Array<CompanyDomain>;
  /** A list of edges which contains the `CompanyDomain`, info from the `CompanyDomainsPath`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyDomain` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyDomainAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyDomainAggregates>>;
};


/** A connection to a list of `CompanyDomain` values, with data from `CompanyDomainsPath`. */
export type CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyDomainsGroupBy>;
  having?: Maybe<CompanyDomainsHavingInput>;
};

/** A `CompanyDomain` edge in the connection, with data from `CompanyDomainsPath`. */
export type CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyDomain` at the end of the edge. */
  node: CompanyDomain;
  /** Reads and enables pagination through a set of `CompanyDomainsPath`. */
  companyDomainsPathsByDomainId: CompanyDomainsPathsConnection;
};


/** A `CompanyDomain` edge in the connection, with data from `CompanyDomainsPath`. */
export type CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyEdgeCompanyDomainsPathsByDomainIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
  condition?: Maybe<CompanyDomainsPathCondition>;
  filter?: Maybe<CompanyDomainsPathFilter>;
};

/** The fields on `company` to look up the row to connect. */
export type CompanyCompanyExternalIdKeyConnect = {
  externalId: Scalars['String'];
};

/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `CompanyIntegration` values, with data from `VehicleStock`. */
export type CompanyCompanyIntegrationsByVehicleStockCompanyIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `VehicleStock`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyIntegrationsByVehicleStockCompanyIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `VehicleStock`. */
export type CompanyCompanyIntegrationsByVehicleStockCompanyIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `VehicleStock`. */
export type CompanyCompanyIntegrationsByVehicleStockCompanyIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `VehicleStock`. */
  vehicleStocks: VehicleStocksConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `VehicleStock`. */
export type CompanyCompanyIntegrationsByVehicleStockCompanyIdAndCompanyIntegrationIdManyToManyEdgeVehicleStocksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleStocksOrderBy>>;
  condition?: Maybe<VehicleStockCondition>;
  filter?: Maybe<VehicleStockFilter>;
};

/** The fields on `company` to look up the row to connect. */
export type CompanyCompanyLagoIdKeyConnect = {
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to connect. */
export type CompanyCompanyNameKeyConnect = {
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to connect. */
export type CompanyCompanyPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to connect. */
export type CompanyCompanyPrimaryGroupKeyConnect = {
  primaryGroup: Scalars['Int'];
};

/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A condition to be used against `Company` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CompanyCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `equifaxCustomerNumber` field. */
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `securityCode` field. */
  securityCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `externalId` field. */
  externalId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `zohoId` field. */
  zohoId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deliverTo` field. */
  deliverTo?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `primaryGroup` field. */
  primaryGroup?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rawMeta` field. */
  rawMeta?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `billableCompanyId` field. */
  billableCompanyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rawRequestedAccess` field. */
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `lenderDealerCode` field. */
  lenderDealerCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `googlePlacesId` field. */
  googlePlacesId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `legalConsent` field. */
  legalConsent?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `lagoId` field. */
  lagoId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `stripeCustomerId` field. */
  stripeCustomerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salesOrderId` field. */
  salesOrderId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `industry` field. */
  industry?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `manufacturer` field. */
  manufacturer?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `crm` field. */
  crm?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `accountManager` field. */
  accountManager?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `supportAgent` field. */
  supportAgent?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `opportunityType` field. */
  opportunityType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `numberOfLocations` field. */
  numberOfLocations?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `website` field. */
  website?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dms` field. */
  dms?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `webProvider` field. */
  webProvider?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `country` field. */
  country?: Maybe<Scalars['String']>;
};

export type CompanyCta = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  ctaId: Scalars['String'];
  groupId: Scalars['Int'];
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme: Scalars['String'];
  /** User friendly name of this override. */
  name: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  compatibleMajor: Scalars['Int'];
  ctaDetails?: Maybe<Scalars['JSON']>;
  /** Reads a single `Cta` that is related to this `CompanyCta`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Group` that is related to this `CompanyCta`. */
  group?: Maybe<Group>;
};

export type CompanyCtaAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyCtaSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyCtaDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyCtaMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyCtaMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyCtaAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyCtaStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyCtaStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyCtaVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyCtaVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyCta` object types. */
export type CompanyCtaAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyCta` object to be included within the aggregate. */
  filter?: Maybe<CompanyCtaFilter>;
  /** Sum aggregate over matching `CompanyCta` objects. */
  sum?: Maybe<CompanyCtaSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyCta` objects. */
  distinctCount?: Maybe<CompanyCtaDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyCta` objects. */
  min?: Maybe<CompanyCtaMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyCta` objects. */
  max?: Maybe<CompanyCtaMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyCta` objects. */
  average?: Maybe<CompanyCtaAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyCta` objects. */
  stddevSample?: Maybe<CompanyCtaStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyCta` objects. */
  stddevPopulation?: Maybe<CompanyCtaStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyCta` objects. */
  varianceSample?: Maybe<CompanyCtaVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyCta` objects. */
  variancePopulation?: Maybe<CompanyCtaVariancePopulationAggregateFilter>;
};

export type CompanyCtaAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  compatibleMajor?: Maybe<BigFloatFilter>;
};

export type CompanyCtaAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `companyCta` to look up the row to connect. */
export type CompanyCtaCompanyCtasPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyCta` to look up the row to delete. */
export type CompanyCtaCompanyCtasPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyCta` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CompanyCtaCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `ctaId` field. */
  ctaId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `theme` field. */
  theme?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locked` field. */
  locked?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `default` field. */
  default?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `templateDef` field. */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `themeDef` field. */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `compatibleMajor` field. */
  compatibleMajor?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `ctaDetails` field. */
  ctaDetails?: Maybe<Scalars['JSON']>;
};

export type CompanyCtaDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  ctaId?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
  theme?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  locked?: Maybe<BigIntFilter>;
  default?: Maybe<BigIntFilter>;
  templateDef?: Maybe<BigIntFilter>;
  themeDef?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  compatibleMajor?: Maybe<BigIntFilter>;
  ctaDetails?: Maybe<BigIntFilter>;
};

export type CompanyCtaDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaId across the matching connection */
  ctaId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of theme across the matching connection */
  theme?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of locked across the matching connection */
  locked?: Maybe<Scalars['BigInt']>;
  /** Distinct count of default across the matching connection */
  default?: Maybe<Scalars['BigInt']>;
  /** Distinct count of templateDef across the matching connection */
  templateDef?: Maybe<Scalars['BigInt']>;
  /** Distinct count of themeDef across the matching connection */
  themeDef?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaDetails across the matching connection */
  ctaDetails?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyCta` object types. All fields are combined with a logical ‘and.’ */
export type CompanyCtaFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `ctaId` field. */
  ctaId?: Maybe<StringFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<IntFilter>;
  /** Filter by the object’s `theme` field. */
  theme?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `locked` field. */
  locked?: Maybe<BooleanFilter>;
  /** Filter by the object’s `default` field. */
  default?: Maybe<BooleanFilter>;
  /** Filter by the object’s `templateDef` field. */
  templateDef?: Maybe<JsonFilter>;
  /** Filter by the object’s `themeDef` field. */
  themeDef?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `compatibleMajor` field. */
  compatibleMajor?: Maybe<IntFilter>;
  /** Filter by the object’s `ctaDetails` field. */
  ctaDetails?: Maybe<JsonFilter>;
  /** Filter by the object’s `cta` relation. */
  cta?: Maybe<CtaFilter>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<GroupFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyCtaFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyCtaFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyCtaFilter>;
};

/** An input for mutations affecting `CompanyCta` */
export type CompanyCtaInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor: Scalars['Int'];
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

export type CompanyCtaMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
  compatibleMajor?: Maybe<IntFilter>;
};

export type CompanyCtaMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
  /** Maximum of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['Int']>;
};

export type CompanyCtaMeta = {
  theme?: Maybe<Scalars['String']>;
};

export type CompanyCtaMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
  compatibleMajor?: Maybe<IntFilter>;
};

export type CompanyCtaMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
  /** Minimum of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyCtaNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyCta` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyCtaNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyCta` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `cta` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: CtaPatch;
};

/** The fields on `companyCta` to look up the row to update. */
export type CompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCompanyCtasPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyCta` being updated. */
  patch: UpdateCompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `companyCta` to look up the row to update. */
export type CompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyUsingCompanyCtasPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyCta` being updated. */
  patch: UpdateCompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyPatch;
  id: Scalars['Int'];
};

export type CompanyCtaOverrides = {
  theme: Scalars['String'];
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
};

/** Represents an update to a `CompanyCta`. Fields that are set will be updated. */
export type CompanyCtaPatch = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name?: Maybe<Scalars['String']>;
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef?: Maybe<Scalars['JSON']>;
  themeDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor?: Maybe<Scalars['Int']>;
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

export type CompanyCtaStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  compatibleMajor?: Maybe<BigFloatFilter>;
};

export type CompanyCtaStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CompanyCtaStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  compatibleMajor?: Maybe<BigFloatFilter>;
};

export type CompanyCtaStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CompanyCtaSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
  compatibleMajor?: Maybe<BigIntFilter>;
};

export type CompanyCtaSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt'];
  /** Sum of compatibleMajor across the matching connection */
  compatibleMajor: Scalars['BigInt'];
};

export type CompanyCtaVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  compatibleMajor?: Maybe<BigFloatFilter>;
};

export type CompanyCtaVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CompanyCtaVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  compatibleMajor?: Maybe<BigFloatFilter>;
};

export type CompanyCtaVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyCta` values. */
export type CompanyCtasConnection = {
  /** A list of `CompanyCta` objects. */
  nodes: Array<CompanyCta>;
  /** A list of edges which contains the `CompanyCta` and cursor to aid in pagination. */
  edges: Array<CompanyCtasEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyCta` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyCtaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyCtaAggregates>>;
};


/** A connection to a list of `CompanyCta` values. */
export type CompanyCtasConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyCtasGroupBy>;
  having?: Maybe<CompanyCtasHavingInput>;
};

/** The `companyCta` to be created by this mutation. */
export type CompanyCtasCtaIdFkeyCompanyCtasCreateInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor: Scalars['Int'];
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

/** The `cta` to be created by this mutation. */
export type CompanyCtasCtaIdFkeyCtasCreateInput = {
  id?: Maybe<Scalars['String']>;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree: Scalars['JSON'];
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec: Scalars['JSON'];
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef: Scalars['JSON'];
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec: Scalars['JSON'];
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef: Scalars['JSON'];
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

/** Input for the nested mutation of `cta` in the `CompanyCtaInput` mutation. */
export type CompanyCtasCtaIdFkeyInput = {
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectById?: Maybe<CtaCtasPkeyConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectByProductAndTypeAndVersion?: Maybe<CtaCtasProductTypeVersionKeyConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectByNodeId?: Maybe<CtaNodeIdConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteById?: Maybe<CtaCtasPkeyDelete>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteByProductAndTypeAndVersion?: Maybe<CtaCtasProductTypeVersionKeyDelete>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CtaNodeIdDelete>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateById?: Maybe<CtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCtasPkeyUpdate>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateByProductAndTypeAndVersion?: Maybe<CtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCtasProductTypeVersionKeyUpdate>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyNodeIdUpdate>;
  /** A `CtaInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyCtasCtaIdFkeyCtasCreateInput>;
};

/** Input for the nested mutation of `companyCta` in the `CtaInput` mutation. */
export type CompanyCtasCtaIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyCta` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyCtaCompanyCtasPkeyConnect>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyCtaNodeIdConnect>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyCtaCompanyCtasPkeyDelete>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyCtaNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyCta` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCompanyCtasPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyCta` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CtaOnCompanyCtaForCompanyCtasCtaIdFkeyNodeIdUpdate>>;
  /** A `CompanyCtaInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyCtasCtaIdFkeyCompanyCtasCreateInput>>;
};

/** A `CompanyCta` edge in the connection. */
export type CompanyCtasEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyCta` at the end of the edge. */
  node: CompanyCta;
};

/** Grouping methods for `CompanyCta` for usage during aggregation. */
export enum CompanyCtasGroupBy {
  CtaId = 'CTA_ID',
  GroupId = 'GROUP_ID',
  Theme = 'THEME',
  Name = 'NAME',
  Locked = 'LOCKED',
  Default = 'DEFAULT',
  TemplateDef = 'TEMPLATE_DEF',
  ThemeDef = 'THEME_DEF',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  CompatibleMajor = 'COMPATIBLE_MAJOR',
  CtaDetails = 'CTA_DETAILS'
}

/** The `companyCta` to be created by this mutation. */
export type CompanyCtasGroupIdFkeyCompanyCtasCreateInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor: Scalars['Int'];
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

/** The `group` to be created by this mutation. */
export type CompanyCtasGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `CompanyCtaInput` mutation. */
export type CompanyCtasGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCompanyCtaForCompanyCtasGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnCompanyCtaForCompanyCtasGroupIdFkeyUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyCtasGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `companyCta` in the `GroupInput` mutation. */
export type CompanyCtasGroupIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyCta` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyCtaCompanyCtasPkeyConnect>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyCtaNodeIdConnect>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyCtaCompanyCtasPkeyDelete>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyCtaNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyCta` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyUsingCompanyCtasPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyCta` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnCompanyCtaForCompanyCtasGroupIdFkeyNodeIdUpdate>>;
  /** A `CompanyCtaInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyCtasGroupIdFkeyCompanyCtasCreateInput>>;
};

export type CompanyCtasHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

/** Conditions for `CompanyCta` aggregates. */
export type CompanyCtasHavingInput = {
  AND?: Maybe<Array<CompanyCtasHavingInput>>;
  OR?: Maybe<Array<CompanyCtasHavingInput>>;
  sum?: Maybe<CompanyCtasHavingSumInput>;
  distinctCount?: Maybe<CompanyCtasHavingDistinctCountInput>;
  min?: Maybe<CompanyCtasHavingMinInput>;
  max?: Maybe<CompanyCtasHavingMaxInput>;
  average?: Maybe<CompanyCtasHavingAverageInput>;
  stddevSample?: Maybe<CompanyCtasHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyCtasHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyCtasHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyCtasHavingVariancePopulationInput>;
};

export type CompanyCtasHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

/** Methods to use when ordering `CompanyCta`. */
export enum CompanyCtasOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CtaIdAsc = 'CTA_ID_ASC',
  CtaIdDesc = 'CTA_ID_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  ThemeAsc = 'THEME_ASC',
  ThemeDesc = 'THEME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  LockedAsc = 'LOCKED_ASC',
  LockedDesc = 'LOCKED_DESC',
  DefaultAsc = 'DEFAULT_ASC',
  DefaultDesc = 'DEFAULT_DESC',
  TemplateDefAsc = 'TEMPLATE_DEF_ASC',
  TemplateDefDesc = 'TEMPLATE_DEF_DESC',
  ThemeDefAsc = 'THEME_DEF_ASC',
  ThemeDefDesc = 'THEME_DEF_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  CompatibleMajorAsc = 'COMPATIBLE_MAJOR_ASC',
  CompatibleMajorDesc = 'COMPATIBLE_MAJOR_DESC',
  CtaDetailsAsc = 'CTA_DETAILS_ASC',
  CtaDetailsDesc = 'CTA_DETAILS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompanyDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  equifaxCustomerNumber?: Maybe<BigIntFilter>;
  securityCode?: Maybe<BigIntFilter>;
  externalId?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  archived?: Maybe<BigIntFilter>;
  zohoId?: Maybe<BigIntFilter>;
  deliverTo?: Maybe<BigIntFilter>;
  primaryGroup?: Maybe<BigIntFilter>;
  rawMeta?: Maybe<BigIntFilter>;
  billableCompanyId?: Maybe<BigIntFilter>;
  rawRequestedAccess?: Maybe<BigIntFilter>;
  lenderDealerCode?: Maybe<BigIntFilter>;
  googlePlacesId?: Maybe<BigIntFilter>;
  legalConsent?: Maybe<BigIntFilter>;
  lagoId?: Maybe<BigIntFilter>;
  stripeCustomerId?: Maybe<BigIntFilter>;
  salesOrderId?: Maybe<BigIntFilter>;
  industry?: Maybe<BigIntFilter>;
  manufacturer?: Maybe<BigIntFilter>;
  crm?: Maybe<BigIntFilter>;
  accountManager?: Maybe<BigIntFilter>;
  supportAgent?: Maybe<BigIntFilter>;
  opportunityType?: Maybe<BigIntFilter>;
  numberOfLocations?: Maybe<BigIntFilter>;
  website?: Maybe<BigIntFilter>;
  dms?: Maybe<BigIntFilter>;
  webProvider?: Maybe<BigIntFilter>;
  country?: Maybe<BigIntFilter>;
};

export type CompanyDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of equifaxCustomerNumber across the matching connection */
  equifaxCustomerNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of securityCode across the matching connection */
  securityCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of externalId across the matching connection */
  externalId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of archived across the matching connection */
  archived?: Maybe<Scalars['BigInt']>;
  /** Distinct count of zohoId across the matching connection */
  zohoId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deliverTo across the matching connection */
  deliverTo?: Maybe<Scalars['BigInt']>;
  /** Distinct count of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawMeta across the matching connection */
  rawMeta?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawRequestedAccess across the matching connection */
  rawRequestedAccess?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderDealerCode across the matching connection */
  lenderDealerCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of googlePlacesId across the matching connection */
  googlePlacesId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of legalConsent across the matching connection */
  legalConsent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lagoId across the matching connection */
  lagoId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stripeCustomerId across the matching connection */
  stripeCustomerId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesOrderId across the matching connection */
  salesOrderId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of industry across the matching connection */
  industry?: Maybe<Scalars['BigInt']>;
  /** Distinct count of manufacturer across the matching connection */
  manufacturer?: Maybe<Scalars['BigInt']>;
  /** Distinct count of crm across the matching connection */
  crm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accountManager across the matching connection */
  accountManager?: Maybe<Scalars['BigInt']>;
  /** Distinct count of supportAgent across the matching connection */
  supportAgent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of opportunityType across the matching connection */
  opportunityType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of numberOfLocations across the matching connection */
  numberOfLocations?: Maybe<Scalars['BigInt']>;
  /** Distinct count of website across the matching connection */
  website?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dms across the matching connection */
  dms?: Maybe<Scalars['BigInt']>;
  /** Distinct count of webProvider across the matching connection */
  webProvider?: Maybe<Scalars['BigInt']>;
  /** Distinct count of country across the matching connection */
  country?: Maybe<Scalars['BigInt']>;
};

export type CompanyDomain = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  domain: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `CompanyDomain`. */
  company?: Maybe<Company>;
  /** Reads and enables pagination through a set of `CompanyDomainsApproved`. */
  companyDomainsApprovedsByDomainId: CompanyDomainsApprovedsConnection;
  /** Reads and enables pagination through a set of `CompanyDomainsPath`. */
  companyDomainsPathsByDomainId: CompanyDomainsPathsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyDomainsApprovedDomainIdAndCompanyId: CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyDomainsPathDomainIdAndCompanyId: CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyConnection;
};


export type CompanyDomainCompanyDomainsApprovedsByDomainIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
  condition?: Maybe<CompanyDomainsApprovedCondition>;
  filter?: Maybe<CompanyDomainsApprovedFilter>;
};


export type CompanyDomainCompanyDomainsPathsByDomainIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
  condition?: Maybe<CompanyDomainsPathCondition>;
  filter?: Maybe<CompanyDomainsPathFilter>;
};


export type CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};

export type CompanyDomainAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyDomainSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyDomainDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyDomainMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyDomainMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyDomainAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyDomainStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyDomainStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyDomainVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyDomainVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyDomain` object types. */
export type CompanyDomainAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyDomain` object to be included within the aggregate. */
  filter?: Maybe<CompanyDomainFilter>;
  /** Sum aggregate over matching `CompanyDomain` objects. */
  sum?: Maybe<CompanyDomainSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyDomain` objects. */
  distinctCount?: Maybe<CompanyDomainDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyDomain` objects. */
  min?: Maybe<CompanyDomainMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyDomain` objects. */
  max?: Maybe<CompanyDomainMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyDomain` objects. */
  average?: Maybe<CompanyDomainAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyDomain` objects. */
  stddevSample?: Maybe<CompanyDomainStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyDomain` objects. */
  stddevPopulation?: Maybe<CompanyDomainStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyDomain` objects. */
  varianceSample?: Maybe<CompanyDomainVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyDomain` objects. */
  variancePopulation?: Maybe<CompanyDomainVariancePopulationAggregateFilter>;
};

export type CompanyDomainAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Company` values, with data from `CompanyDomainsApproved`. */
export type CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyDomainsApproved`, and the cursor to aid in pagination. */
  edges: Array<CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyDomainsApproved`. */
export type CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyDomainsApproved`. */
export type CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `Company` values, with data from `CompanyDomainsPath`. */
export type CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyDomainsPath`, and the cursor to aid in pagination. */
  edges: Array<CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyDomainsPath`. */
export type CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyDomainsPath`. */
export type CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `CompanyDomainsPath`. */
  companyDomainsPaths: CompanyDomainsPathsConnection;
};


/** A `Company` edge in the connection, with data from `CompanyDomainsPath`. */
export type CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyEdgeCompanyDomainsPathsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
  condition?: Maybe<CompanyDomainsPathCondition>;
  filter?: Maybe<CompanyDomainsPathFilter>;
};

/** The fields on `companyDomain` to look up the row to connect. */
export type CompanyDomainCompanyDomainsDomainKeyConnect = {
  domain: Scalars['String'];
};

/** The fields on `companyDomain` to look up the row to delete. */
export type CompanyDomainCompanyDomainsDomainKeyDelete = {
  domain: Scalars['String'];
};

/** The fields on `companyDomain` to look up the row to connect. */
export type CompanyDomainCompanyDomainsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyDomain` to look up the row to delete. */
export type CompanyDomainCompanyDomainsPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyDomain` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyDomainCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `domain` field. */
  domain?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** The source url to find the most relevant domain match for */
  mostRelevantDomains?: Maybe<Scalars['String']>;
  /** The source url to find the most relevant domain match for */
  mostRelevantDomainOnly?: Maybe<Scalars['String']>;
};

export type CompanyDomainDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  domain?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyDomainDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of domain across the matching connection */
  domain?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyDomain` object types. All fields are combined with a logical ‘and.’ */
export type CompanyDomainFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `domain` field. */
  domain?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `companyDomainsApprovedsByDomainId` relation. */
  companyDomainsApprovedsByDomainId?: Maybe<CompanyDomainToManyCompanyDomainsApprovedFilter>;
  /** Some related `companyDomainsApprovedsByDomainId` exist. */
  companyDomainsApprovedsByDomainIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyDomainsPathsByDomainId` relation. */
  companyDomainsPathsByDomainId?: Maybe<CompanyDomainToManyCompanyDomainsPathFilter>;
  /** Some related `companyDomainsPathsByDomainId` exist. */
  companyDomainsPathsByDomainIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyDomainFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyDomainFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyDomainFilter>;
};

/** An input for mutations affecting `CompanyDomain` */
export type CompanyDomainInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

export type CompanyDomainMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyDomainMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type CompanyDomainMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyDomainMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyDomainNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyDomain` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyDomainNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyDomain` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyDomainsDomainKeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  domain: Scalars['String'];
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyDomainsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomainsApproved` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
  patch: CompanyDomainsApprovedPatch;
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsDomainKeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyPatch;
  domain: Scalars['String'];
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomainsPath` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: CompanyDomainsPathPatch;
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsDomainKeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch;
  domain: Scalars['String'];
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyDomain`. Fields that are set will be updated. */
export type CompanyDomainPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

export type CompanyDomainStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
};

export type CompanyDomainSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyDomainsApproved` object types. All fields are combined with a logical ‘and.’ */
export type CompanyDomainToManyCompanyDomainsApprovedFilter = {
  /** Every related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyDomainsApprovedFilter>;
  /** Some related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyDomainsApprovedFilter>;
  /** No related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyDomainsApprovedFilter>;
  /** Aggregates across related `CompanyDomainsApproved` match the filter criteria. */
  aggregates?: Maybe<CompanyDomainsApprovedAggregatesFilter>;
};

/** A filter to be used against many `CompanyDomainsPath` object types. All fields are combined with a logical ‘and.’ */
export type CompanyDomainToManyCompanyDomainsPathFilter = {
  /** Every related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyDomainsPathFilter>;
  /** Some related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyDomainsPathFilter>;
  /** No related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyDomainsPathFilter>;
  /** Aggregates across related `CompanyDomainsPath` match the filter criteria. */
  aggregates?: Maybe<CompanyDomainsPathAggregatesFilter>;
};

export type CompanyDomainVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsApproved = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `CompanyDomainsApproved`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsApproved`. */
  domain?: Maybe<CompanyDomain>;
};

export type CompanyDomainsApprovedAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyDomainsApprovedSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyDomainsApprovedDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyDomainsApprovedMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyDomainsApprovedMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyDomainsApprovedAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyDomainsApprovedStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyDomainsApprovedStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyDomainsApprovedVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyDomainsApprovedVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyDomainsApproved` object types. */
export type CompanyDomainsApprovedAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyDomainsApproved` object to be included within the aggregate. */
  filter?: Maybe<CompanyDomainsApprovedFilter>;
  /** Sum aggregate over matching `CompanyDomainsApproved` objects. */
  sum?: Maybe<CompanyDomainsApprovedSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyDomainsApproved` objects. */
  distinctCount?: Maybe<CompanyDomainsApprovedDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyDomainsApproved` objects. */
  min?: Maybe<CompanyDomainsApprovedMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyDomainsApproved` objects. */
  max?: Maybe<CompanyDomainsApprovedMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyDomainsApproved` objects. */
  average?: Maybe<CompanyDomainsApprovedAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyDomainsApproved` objects. */
  stddevSample?: Maybe<CompanyDomainsApprovedStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyDomainsApproved` objects. */
  stddevPopulation?: Maybe<CompanyDomainsApprovedStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyDomainsApproved` objects. */
  varianceSample?: Maybe<CompanyDomainsApprovedVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyDomainsApproved` objects. */
  variancePopulation?: Maybe<CompanyDomainsApprovedVariancePopulationAggregateFilter>;
};

export type CompanyDomainsApprovedAverageAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsApprovedAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `companyDomainsApproved` to look up the row to connect. */
export type CompanyDomainsApprovedCompanyDomainsApprovedPkeyConnect = {
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** The fields on `companyDomainsApproved` to look up the row to delete. */
export type CompanyDomainsApprovedCompanyDomainsApprovedPkeyDelete = {
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** The `company` to be created by this mutation. */
export type CompanyDomainsApprovedCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `companyDomainsApproved` to be created by this mutation. */
export type CompanyDomainsApprovedCompanyIdFkeyCompanyDomainsApprovedCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `CompanyDomainsApprovedInput` mutation. */
export type CompanyDomainsApprovedCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyDomainsApprovedCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyDomainsApproved` in the `CompanyInput` mutation. */
export type CompanyDomainsApprovedCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyDomainsApproved` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  connectByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedCompanyDomainsApprovedPkeyConnect>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyDomainsApprovedNodeIdConnect>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  deleteByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedCompanyDomainsApprovedPkeyDelete>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyDomainsApprovedNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyDomainsApproved` for the far side of the relationship. */
  updateByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyDomainsApprovedPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsApproved` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyDomainsApprovedInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyDomainsApprovedCompanyIdFkeyCompanyDomainsApprovedCreateInput>>;
};

/**
 * A condition to be used against `CompanyDomainsApproved` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CompanyDomainsApprovedCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `domainId` field. */
  domainId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyDomainsApprovedDistinctCountAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  domainId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyDomainsApprovedDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The `companyDomainsApproved` to be created by this mutation. */
export type CompanyDomainsApprovedDomainIdFkeyCompanyDomainsApprovedCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

/** The `companyDomain` to be created by this mutation. */
export type CompanyDomainsApprovedDomainIdFkeyCompanyDomainsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** Input for the nested mutation of `companyDomain` in the `CompanyDomainsApprovedInput` mutation. */
export type CompanyDomainsApprovedDomainIdFkeyInput = {
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectById?: Maybe<CompanyDomainCompanyDomainsPkeyConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByDomain?: Maybe<CompanyDomainCompanyDomainsDomainKeyConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyDomainNodeIdConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteById?: Maybe<CompanyDomainCompanyDomainsPkeyDelete>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByDomain?: Maybe<CompanyDomainCompanyDomainsDomainKeyDelete>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyDomainNodeIdDelete>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateById?: Maybe<CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsPkeyUpdate>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByDomain?: Maybe<CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsDomainKeyUpdate>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyNodeIdUpdate>;
  /** A `CompanyDomainInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyDomainsApprovedDomainIdFkeyCompanyDomainsCreateInput>;
};

/** Input for the nested mutation of `companyDomainsApproved` in the `CompanyDomainInput` mutation. */
export type CompanyDomainsApprovedDomainIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyDomainsApproved` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  connectByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedCompanyDomainsApprovedPkeyConnect>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyDomainsApprovedNodeIdConnect>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  deleteByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedCompanyDomainsApprovedPkeyDelete>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyDomainsApprovedNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyDomainsApproved` for the far side of the relationship. */
  updateByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsApprovedPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsApproved` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyNodeIdUpdate>>;
  /** A `CompanyDomainsApprovedInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyDomainsApprovedDomainIdFkeyCompanyDomainsApprovedCreateInput>>;
};

/** A filter to be used against `CompanyDomainsApproved` object types. All fields are combined with a logical ‘and.’ */
export type CompanyDomainsApprovedFilter = {
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `domainId` field. */
  domainId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Filter by the object’s `domain` relation. */
  domain?: Maybe<CompanyDomainFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyDomainsApprovedFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyDomainsApprovedFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyDomainsApprovedFilter>;
};

/** Grouping methods for `CompanyDomainsApproved` for usage during aggregation. */
export enum CompanyDomainsApprovedGroupBy {
  CompanyId = 'COMPANY_ID',
  DomainId = 'DOMAIN_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyDomainsApprovedHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyDomainsApproved` aggregates. */
export type CompanyDomainsApprovedHavingInput = {
  AND?: Maybe<Array<CompanyDomainsApprovedHavingInput>>;
  OR?: Maybe<Array<CompanyDomainsApprovedHavingInput>>;
  sum?: Maybe<CompanyDomainsApprovedHavingSumInput>;
  distinctCount?: Maybe<CompanyDomainsApprovedHavingDistinctCountInput>;
  min?: Maybe<CompanyDomainsApprovedHavingMinInput>;
  max?: Maybe<CompanyDomainsApprovedHavingMaxInput>;
  average?: Maybe<CompanyDomainsApprovedHavingAverageInput>;
  stddevSample?: Maybe<CompanyDomainsApprovedHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyDomainsApprovedHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyDomainsApprovedHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyDomainsApprovedHavingVariancePopulationInput>;
};

export type CompanyDomainsApprovedHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyDomainsApproved` */
export type CompanyDomainsApprovedInput = {
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

export type CompanyDomainsApprovedMaxAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  domainId?: Maybe<IntFilter>;
};

export type CompanyDomainsApprovedMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of domainId across the matching connection */
  domainId?: Maybe<Scalars['Int']>;
};

export type CompanyDomainsApprovedMinAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  domainId?: Maybe<IntFilter>;
};

export type CompanyDomainsApprovedMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of domainId across the matching connection */
  domainId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyDomainsApprovedNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyDomainsApproved` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyDomainsApprovedNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyDomainsApproved` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyDomainsApproved` to look up the row to update. */
export type CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyDomainsApprovedPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
  patch: UpdateCompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch;
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomain` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: CompanyDomainPatch;
};

/** The fields on `companyDomainsApproved` to look up the row to update. */
export type CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsApprovedPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
  patch: UpdateCompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyPatch;
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** Represents an update to a `CompanyDomainsApproved`. Fields that are set will be updated. */
export type CompanyDomainsApprovedPatch = {
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

export type CompanyDomainsApprovedStddevPopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsApprovedStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsApprovedStddevSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsApprovedStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsApprovedSumAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  domainId?: Maybe<BigIntFilter>;
};

export type CompanyDomainsApprovedSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of domainId across the matching connection */
  domainId: Scalars['BigInt'];
};

export type CompanyDomainsApprovedVariancePopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsApprovedVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsApprovedVarianceSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsApprovedVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyDomainsApproved` values. */
export type CompanyDomainsApprovedsConnection = {
  /** A list of `CompanyDomainsApproved` objects. */
  nodes: Array<CompanyDomainsApproved>;
  /** A list of edges which contains the `CompanyDomainsApproved` and cursor to aid in pagination. */
  edges: Array<CompanyDomainsApprovedsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyDomainsApproved` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyDomainsApprovedAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyDomainsApprovedAggregates>>;
};


/** A connection to a list of `CompanyDomainsApproved` values. */
export type CompanyDomainsApprovedsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyDomainsApprovedGroupBy>;
  having?: Maybe<CompanyDomainsApprovedHavingInput>;
};

/** A `CompanyDomainsApproved` edge in the connection. */
export type CompanyDomainsApprovedsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyDomainsApproved` at the end of the edge. */
  node: CompanyDomainsApproved;
};

/** Methods to use when ordering `CompanyDomainsApproved`. */
export enum CompanyDomainsApprovedsOrderBy {
  Natural = 'NATURAL',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  DomainIdAsc = 'DOMAIN_ID_ASC',
  DomainIdDesc = 'DOMAIN_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `company` to be created by this mutation. */
export type CompanyDomainsCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `companyDomain` to be created by this mutation. */
export type CompanyDomainsCompanyIdFkeyCompanyDomainsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `CompanyDomainInput` mutation. */
export type CompanyDomainsCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyDomainsCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyDomain` in the `CompanyInput` mutation. */
export type CompanyDomainsCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyDomain` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyDomainCompanyDomainsPkeyConnect>>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByDomain?: Maybe<Array<CompanyDomainCompanyDomainsDomainKeyConnect>>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyDomainNodeIdConnect>>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyDomainCompanyDomainsPkeyDelete>>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByDomain?: Maybe<Array<CompanyDomainCompanyDomainsDomainKeyDelete>>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyDomainNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyDomainsPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByDomain?: Maybe<Array<CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyDomainsDomainKeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyDomainInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyDomainsCompanyIdFkeyCompanyDomainsCreateInput>>;
};

/** A connection to a list of `CompanyDomain` values. */
export type CompanyDomainsConnection = {
  /** A list of `CompanyDomain` objects. */
  nodes: Array<CompanyDomain>;
  /** A list of edges which contains the `CompanyDomain` and cursor to aid in pagination. */
  edges: Array<CompanyDomainsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyDomain` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyDomainAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyDomainAggregates>>;
};


/** A connection to a list of `CompanyDomain` values. */
export type CompanyDomainsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyDomainsGroupBy>;
  having?: Maybe<CompanyDomainsHavingInput>;
};

/** A `CompanyDomain` edge in the connection. */
export type CompanyDomainsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyDomain` at the end of the edge. */
  node: CompanyDomain;
};

/** Grouping methods for `CompanyDomain` for usage during aggregation. */
export enum CompanyDomainsGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyDomainsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyDomain` aggregates. */
export type CompanyDomainsHavingInput = {
  AND?: Maybe<Array<CompanyDomainsHavingInput>>;
  OR?: Maybe<Array<CompanyDomainsHavingInput>>;
  sum?: Maybe<CompanyDomainsHavingSumInput>;
  distinctCount?: Maybe<CompanyDomainsHavingDistinctCountInput>;
  min?: Maybe<CompanyDomainsHavingMinInput>;
  max?: Maybe<CompanyDomainsHavingMaxInput>;
  average?: Maybe<CompanyDomainsHavingAverageInput>;
  stddevSample?: Maybe<CompanyDomainsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyDomainsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyDomainsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyDomainsHavingVariancePopulationInput>;
};

export type CompanyDomainsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CompanyDomain`. */
export enum CompanyDomainsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  DomainAsc = 'DOMAIN_ASC',
  DomainDesc = 'DOMAIN_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyDomainsApprovedsByDomainIdCountAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_COUNT_ASC',
  CompanyDomainsApprovedsByDomainIdCountDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_COUNT_DESC',
  CompanyDomainsApprovedsByDomainIdSumCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdSumCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdSumDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdSumDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdSumCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdSumCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdSumUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdSumUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdDistinctCountCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdDistinctCountCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdDistinctCountDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdDistinctCountDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdDistinctCountCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdDistinctCountCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdDistinctCountUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdDistinctCountUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdMinCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdMinCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdMinDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdMinDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdMinCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdMinCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdMinUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdMinUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdMaxCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdMaxCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdMaxDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdMaxDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdMaxCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdMaxCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdMaxUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdMaxUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdAverageCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdAverageCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdAverageDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdAverageDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdAverageCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdAverageCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdAverageUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdAverageUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdStddevSampleCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdStddevSampleCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdStddevSampleDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdStddevSampleDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdStddevSampleCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdStddevSampleCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdStddevSampleUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdStddevSampleUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdCountAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_COUNT_ASC',
  CompanyDomainsPathsByDomainIdCountDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_COUNT_DESC',
  CompanyDomainsPathsByDomainIdSumIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_ID_ASC',
  CompanyDomainsPathsByDomainIdSumIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_ID_DESC',
  CompanyDomainsPathsByDomainIdSumCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdSumCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdSumDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdSumDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdSumPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_PATH_ASC',
  CompanyDomainsPathsByDomainIdSumPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_PATH_DESC',
  CompanyDomainsPathsByDomainIdSumCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdSumCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdSumUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdSumUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_ID_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_ID_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_PATH_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_PATH_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdMinIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_ID_ASC',
  CompanyDomainsPathsByDomainIdMinIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_ID_DESC',
  CompanyDomainsPathsByDomainIdMinCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdMinCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdMinDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdMinDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdMinPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_PATH_ASC',
  CompanyDomainsPathsByDomainIdMinPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_PATH_DESC',
  CompanyDomainsPathsByDomainIdMinCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdMinCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdMinUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdMinUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdMaxIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_ID_ASC',
  CompanyDomainsPathsByDomainIdMaxIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_ID_DESC',
  CompanyDomainsPathsByDomainIdMaxCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdMaxCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdMaxDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdMaxDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdMaxPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_PATH_ASC',
  CompanyDomainsPathsByDomainIdMaxPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_PATH_DESC',
  CompanyDomainsPathsByDomainIdMaxCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdMaxCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdMaxUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdMaxUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdAverageIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_ID_ASC',
  CompanyDomainsPathsByDomainIdAverageIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_ID_DESC',
  CompanyDomainsPathsByDomainIdAverageCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdAverageCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdAverageDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdAverageDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdAveragePathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_PATH_ASC',
  CompanyDomainsPathsByDomainIdAveragePathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_PATH_DESC',
  CompanyDomainsPathsByDomainIdAverageCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdAverageCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdAverageUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdAverageUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdStddevSampleIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevSampleIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevSampleCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevSampleCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevSampleDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevSampleDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevSamplePathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_PATH_ASC',
  CompanyDomainsPathsByDomainIdStddevSamplePathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_PATH_DESC',
  CompanyDomainsPathsByDomainIdStddevSampleCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdStddevSampleCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdStddevSampleUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdStddevSampleUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_PATH_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_PATH_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdVarianceSampleIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_ID_ASC',
  CompanyDomainsPathsByDomainIdVarianceSampleIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_ID_DESC',
  CompanyDomainsPathsByDomainIdVarianceSampleCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdVarianceSampleCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdVarianceSampleDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdVarianceSampleDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdVarianceSamplePathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_PATH_ASC',
  CompanyDomainsPathsByDomainIdVarianceSamplePathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_PATH_DESC',
  CompanyDomainsPathsByDomainIdVarianceSampleCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdVarianceSampleCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdVarianceSampleUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdVarianceSampleUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_ID_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_ID_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_PATH_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_PATH_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type CompanyDomainsPath = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `CompanyDomainsPath`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsPath`. */
  domain?: Maybe<CompanyDomain>;
};

export type CompanyDomainsPathAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyDomainsPathSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyDomainsPathDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyDomainsPathMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyDomainsPathMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyDomainsPathAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyDomainsPathStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyDomainsPathStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyDomainsPathVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyDomainsPathVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyDomainsPath` object types. */
export type CompanyDomainsPathAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyDomainsPath` object to be included within the aggregate. */
  filter?: Maybe<CompanyDomainsPathFilter>;
  /** Sum aggregate over matching `CompanyDomainsPath` objects. */
  sum?: Maybe<CompanyDomainsPathSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyDomainsPath` objects. */
  distinctCount?: Maybe<CompanyDomainsPathDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyDomainsPath` objects. */
  min?: Maybe<CompanyDomainsPathMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyDomainsPath` objects. */
  max?: Maybe<CompanyDomainsPathMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyDomainsPath` objects. */
  average?: Maybe<CompanyDomainsPathAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyDomainsPath` objects. */
  stddevSample?: Maybe<CompanyDomainsPathStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyDomainsPath` objects. */
  stddevPopulation?: Maybe<CompanyDomainsPathStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyDomainsPath` objects. */
  varianceSample?: Maybe<CompanyDomainsPathVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyDomainsPath` objects. */
  variancePopulation?: Maybe<CompanyDomainsPathVariancePopulationAggregateFilter>;
};

export type CompanyDomainsPathAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsPathAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `companyDomainsPath` to look up the row to connect. */
export type CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyConnect = {
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** The fields on `companyDomainsPath` to look up the row to delete. */
export type CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyDelete = {
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** The fields on `companyDomainsPath` to look up the row to connect. */
export type CompanyDomainsPathCompanyDomainsPathPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyDomainsPath` to look up the row to delete. */
export type CompanyDomainsPathCompanyDomainsPathPkeyDelete = {
  id: Scalars['Int'];
};

/** The `company` to be created by this mutation. */
export type CompanyDomainsPathCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `companyDomainsPath` to be created by this mutation. */
export type CompanyDomainsPathCompanyIdFkeyCompanyDomainsPathCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `CompanyDomainsPathInput` mutation. */
export type CompanyDomainsPathCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyDomainsPathCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyDomainsPath` in the `CompanyInput` mutation. */
export type CompanyDomainsPathCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyDomainsPath` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathPkeyConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyDomainsPathNodeIdConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathPkeyDelete>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyDelete>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyDomainsPathNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyDomainsPathPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyDomainsPathDomainIdPathKeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyDomainsPathInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyDomainsPathCompanyIdFkeyCompanyDomainsPathCreateInput>>;
};

/**
 * A condition to be used against `CompanyDomainsPath` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyDomainsPathCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `domainId` field. */
  domainId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `path` field. */
  path?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyDomainsPathDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  domainId?: Maybe<BigIntFilter>;
  path?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyDomainsPathDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The `companyDomain` to be created by this mutation. */
export type CompanyDomainsPathDomainIdFkeyCompanyDomainsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** The `companyDomainsPath` to be created by this mutation. */
export type CompanyDomainsPathDomainIdFkeyCompanyDomainsPathCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

/** Input for the nested mutation of `companyDomain` in the `CompanyDomainsPathInput` mutation. */
export type CompanyDomainsPathDomainIdFkeyInput = {
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectById?: Maybe<CompanyDomainCompanyDomainsPkeyConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByDomain?: Maybe<CompanyDomainCompanyDomainsDomainKeyConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyDomainNodeIdConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteById?: Maybe<CompanyDomainCompanyDomainsPkeyDelete>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByDomain?: Maybe<CompanyDomainCompanyDomainsDomainKeyDelete>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyDomainNodeIdDelete>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateById?: Maybe<CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPkeyUpdate>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByDomain?: Maybe<CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsDomainKeyUpdate>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyNodeIdUpdate>;
  /** A `CompanyDomainInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyDomainsPathDomainIdFkeyCompanyDomainsCreateInput>;
};

/** Input for the nested mutation of `companyDomainsPath` in the `CompanyDomainInput` mutation. */
export type CompanyDomainsPathDomainIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyDomainsPath` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathPkeyConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyDomainsPathNodeIdConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathPkeyDelete>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyDelete>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyDomainsPathNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPathPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPathDomainIdPathKeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyNodeIdUpdate>>;
  /** A `CompanyDomainsPathInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyDomainsPathDomainIdFkeyCompanyDomainsPathCreateInput>>;
};

/** A filter to be used against `CompanyDomainsPath` object types. All fields are combined with a logical ‘and.’ */
export type CompanyDomainsPathFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `domainId` field. */
  domainId?: Maybe<IntFilter>;
  /** Filter by the object’s `path` field. */
  path?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `domain` relation. */
  domain?: Maybe<CompanyDomainFilter>;
  /** A related `domain` exists. */
  domainExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyDomainsPathFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyDomainsPathFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyDomainsPathFilter>;
};

/** Grouping methods for `CompanyDomainsPath` for usage during aggregation. */
export enum CompanyDomainsPathGroupBy {
  CompanyId = 'COMPANY_ID',
  DomainId = 'DOMAIN_ID',
  Path = 'PATH',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyDomainsPathHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyDomainsPath` aggregates. */
export type CompanyDomainsPathHavingInput = {
  AND?: Maybe<Array<CompanyDomainsPathHavingInput>>;
  OR?: Maybe<Array<CompanyDomainsPathHavingInput>>;
  sum?: Maybe<CompanyDomainsPathHavingSumInput>;
  distinctCount?: Maybe<CompanyDomainsPathHavingDistinctCountInput>;
  min?: Maybe<CompanyDomainsPathHavingMinInput>;
  max?: Maybe<CompanyDomainsPathHavingMaxInput>;
  average?: Maybe<CompanyDomainsPathHavingAverageInput>;
  stddevSample?: Maybe<CompanyDomainsPathHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyDomainsPathHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyDomainsPathHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyDomainsPathHavingVariancePopulationInput>;
};

export type CompanyDomainsPathHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyDomainsPath` */
export type CompanyDomainsPathInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

export type CompanyDomainsPathMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  domainId?: Maybe<IntFilter>;
};

export type CompanyDomainsPathMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of domainId across the matching connection */
  domainId?: Maybe<Scalars['Int']>;
};

export type CompanyDomainsPathMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  domainId?: Maybe<IntFilter>;
};

export type CompanyDomainsPathMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of domainId across the matching connection */
  domainId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyDomainsPathNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyDomainsPath` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyDomainsPathNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyDomainsPath` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyDomainsPath` to look up the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyDomainsPathDomainIdPathKeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** The fields on `companyDomainsPath` to look up the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyDomainsPathPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomain` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: CompanyDomainPatch;
};

/** The fields on `companyDomainsPath` to look up the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPathDomainIdPathKeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch;
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** The fields on `companyDomainsPath` to look up the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPathPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyDomainsPath`. Fields that are set will be updated. */
export type CompanyDomainsPathPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

export type CompanyDomainsPathStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsPathStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsPathStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsPathStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsPathSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  domainId?: Maybe<BigIntFilter>;
};

export type CompanyDomainsPathSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of domainId across the matching connection */
  domainId: Scalars['BigInt'];
};

export type CompanyDomainsPathVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsPathVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsPathVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsPathVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyDomainsPath` values. */
export type CompanyDomainsPathsConnection = {
  /** A list of `CompanyDomainsPath` objects. */
  nodes: Array<CompanyDomainsPath>;
  /** A list of edges which contains the `CompanyDomainsPath` and cursor to aid in pagination. */
  edges: Array<CompanyDomainsPathsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyDomainsPath` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyDomainsPathAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyDomainsPathAggregates>>;
};


/** A connection to a list of `CompanyDomainsPath` values. */
export type CompanyDomainsPathsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyDomainsPathGroupBy>;
  having?: Maybe<CompanyDomainsPathHavingInput>;
};

/** A `CompanyDomainsPath` edge in the connection. */
export type CompanyDomainsPathsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyDomainsPath` at the end of the edge. */
  node: CompanyDomainsPath;
};

/** Methods to use when ordering `CompanyDomainsPath`. */
export enum CompanyDomainsPathsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  DomainIdAsc = 'DOMAIN_ID_ASC',
  DomainIdDesc = 'DOMAIN_ID_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyConnection = {
  /** A list of `FieldConfig` objects. */
  nodes: Array<FieldConfig>;
  /** A list of edges which contains the `FieldConfig`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldConfigAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldConfigAggregates>>;
};


/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldConfigGroupBy>;
  having?: Maybe<FieldConfigHavingInput>;
};

/** A `FieldConfig` edge in the connection, with data from `Field`. */
export type CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldConfig` at the end of the edge. */
  node: FieldConfig;
  /** Reads and enables pagination through a set of `Field`. */
  fieldsByConfig: FieldsConnection;
};


/** A `FieldConfig` edge in the connection, with data from `Field`. */
export type CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyEdgeFieldsByConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A filter to be used against `Company` object types. All fields are combined with a logical ‘and.’ */
export type CompanyFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `equifaxCustomerNumber` field. */
  equifaxCustomerNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `securityCode` field. */
  securityCode?: Maybe<StringFilter>;
  /** Filter by the object’s `externalId` field. */
  externalId?: Maybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `zohoId` field. */
  zohoId?: Maybe<StringFilter>;
  /** Filter by the object’s `deliverTo` field. */
  deliverTo?: Maybe<BooleanFilter>;
  /** Filter by the object’s `primaryGroup` field. */
  primaryGroup?: Maybe<IntFilter>;
  /** Filter by the object’s `rawMeta` field. */
  rawMeta?: Maybe<JsonFilter>;
  /** Filter by the object’s `billableCompanyId` field. */
  billableCompanyId?: Maybe<IntFilter>;
  /** Filter by the object’s `rawRequestedAccess` field. */
  rawRequestedAccess?: Maybe<JsonFilter>;
  /** Filter by the object’s `lenderDealerCode` field. */
  lenderDealerCode?: Maybe<StringFilter>;
  /** Filter by the object’s `googlePlacesId` field. */
  googlePlacesId?: Maybe<StringFilter>;
  /** Filter by the object’s `legalConsent` field. */
  legalConsent?: Maybe<BooleanFilter>;
  /** Filter by the object’s `lagoId` field. */
  lagoId?: Maybe<UuidFilter>;
  /** Filter by the object’s `stripeCustomerId` field. */
  stripeCustomerId?: Maybe<StringFilter>;
  /** Filter by the object’s `salesOrderId` field. */
  salesOrderId?: Maybe<StringFilter>;
  /** Filter by the object’s `industry` field. */
  industry?: Maybe<StringFilter>;
  /** Filter by the object’s `manufacturer` field. */
  manufacturer?: Maybe<StringFilter>;
  /** Filter by the object’s `crm` field. */
  crm?: Maybe<StringFilter>;
  /** Filter by the object’s `accountManager` field. */
  accountManager?: Maybe<IntFilter>;
  /** Filter by the object’s `supportAgent` field. */
  supportAgent?: Maybe<IntFilter>;
  /** Filter by the object’s `opportunityType` field. */
  opportunityType?: Maybe<StringFilter>;
  /** Filter by the object’s `numberOfLocations` field. */
  numberOfLocations?: Maybe<StringFilter>;
  /** Filter by the object’s `website` field. */
  website?: Maybe<StringFilter>;
  /** Filter by the object’s `dms` field. */
  dms?: Maybe<StringFilter>;
  /** Filter by the object’s `webProvider` field. */
  webProvider?: Maybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  country?: Maybe<StringFilter>;
  /** Filter by the object’s `leads` relation. */
  leads?: Maybe<CompanyToManyLeadFilter>;
  /** Some related `leads` exist. */
  leadsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyGroups` relation. */
  companyGroups?: Maybe<CompanyToManyCompanyGroupFilter>;
  /** Some related `companyGroups` exist. */
  companyGroupsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyProducts` relation. */
  companyProducts?: Maybe<CompanyToManyCompanyProductFilter>;
  /** Some related `companyProducts` exist. */
  companyProductsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyLocations` relation. */
  companyLocations?: Maybe<CompanyToManyCompanyLocationFilter>;
  /** Some related `companyLocations` exist. */
  companyLocationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyAnalytics` relation. */
  companyAnalytics?: Maybe<CompanyToManyCompanyAnalyticFilter>;
  /** Some related `companyAnalytics` exist. */
  companyAnalyticsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyDomains` relation. */
  companyDomains?: Maybe<CompanyToManyCompanyDomainFilter>;
  /** Some related `companyDomains` exist. */
  companyDomainsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyDomainsApproveds` relation. */
  companyDomainsApproveds?: Maybe<CompanyToManyCompanyDomainsApprovedFilter>;
  /** Some related `companyDomainsApproveds` exist. */
  companyDomainsApprovedsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyDomainsPaths` relation. */
  companyDomainsPaths?: Maybe<CompanyToManyCompanyDomainsPathFilter>;
  /** Some related `companyDomainsPaths` exist. */
  companyDomainsPathsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `reviews` relation. */
  reviews?: Maybe<CompanyToManyReviewFilter>;
  /** Some related `reviews` exist. */
  reviewsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `analytics` relation. */
  analytics?: Maybe<CompanyToManyAnalyticFilter>;
  /** Some related `analytics` exist. */
  analyticsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyIntegrations` relation. */
  companyIntegrations?: Maybe<CompanyToManyCompanyIntegrationFilter>;
  /** Some related `companyIntegrations` exist. */
  companyIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyChannels` relation. */
  companyChannels?: Maybe<CompanyToManyCompanyChannelFilter>;
  /** Some related `companyChannels` exist. */
  companyChannelsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fields` relation. */
  fields?: Maybe<CompanyToManyFieldFilter>;
  /** Some related `fields` exist. */
  fieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `vehicleStocks` relation. */
  vehicleStocks?: Maybe<CompanyToManyVehicleStockFilter>;
  /** Some related `vehicleStocks` exist. */
  vehicleStocksExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyUsers` relation. */
  companyUsers?: Maybe<CompanyToManyCompanyUserFilter>;
  /** Some related `companyUsers` exist. */
  companyUsersExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `medias` relation. */
  medias?: Maybe<CompanyToManyMediaFilter>;
  /** Some related `medias` exist. */
  mediasExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `distributorRelationshipsByDistributorCompanyId` relation. */
  distributorRelationshipsByDistributorCompanyId?: Maybe<CompanyToManyDistributorRelationshipFilter>;
  /** Some related `distributorRelationshipsByDistributorCompanyId` exist. */
  distributorRelationshipsByDistributorCompanyIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `distributorRelationships` relation. */
  distributorRelationships?: Maybe<CompanyToManyDistributorRelationshipFilter>;
  /** Some related `distributorRelationships` exist. */
  distributorRelationshipsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `distributedLeadsByFromCompanyId` relation. */
  distributedLeadsByFromCompanyId?: Maybe<CompanyToManyDistributedLeadFilter>;
  /** Some related `distributedLeadsByFromCompanyId` exist. */
  distributedLeadsByFromCompanyIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `distributedLeadsByToCompanyId` relation. */
  distributedLeadsByToCompanyId?: Maybe<CompanyToManyDistributedLeadFilter>;
  /** Some related `distributedLeadsByToCompanyId` exist. */
  distributedLeadsByToCompanyIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `deliveryMethodsInternals` relation. */
  deliveryMethodsInternals?: Maybe<CompanyToManyDeliveryMethodsInternalFilter>;
  /** Some related `deliveryMethodsInternals` exist. */
  deliveryMethodsInternalsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `groupByPrimaryGroup` relation. */
  groupByPrimaryGroup?: Maybe<GroupFilter>;
  /** Filter by the object’s `userByAccountManager` relation. */
  userByAccountManager?: Maybe<UserFilter>;
  /** A related `userByAccountManager` exists. */
  userByAccountManagerExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userBySupportAgent` relation. */
  userBySupportAgent?: Maybe<UserFilter>;
  /** A related `userBySupportAgent` exists. */
  userBySupportAgentExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyFilter>;
};

export type CompanyGroup = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `CompanyGroup`. */
  company?: Maybe<Company>;
  /** Reads a single `Group` that is related to this `CompanyGroup`. */
  group?: Maybe<Group>;
};

export type CompanyGroupAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyGroupSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyGroupDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyGroupMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyGroupMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyGroupAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyGroupStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyGroupStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyGroupVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyGroupVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyGroup` object types. */
export type CompanyGroupAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyGroup` object to be included within the aggregate. */
  filter?: Maybe<CompanyGroupFilter>;
  /** Sum aggregate over matching `CompanyGroup` objects. */
  sum?: Maybe<CompanyGroupSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyGroup` objects. */
  distinctCount?: Maybe<CompanyGroupDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyGroup` objects. */
  min?: Maybe<CompanyGroupMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyGroup` objects. */
  max?: Maybe<CompanyGroupMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyGroup` objects. */
  average?: Maybe<CompanyGroupAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyGroup` objects. */
  stddevSample?: Maybe<CompanyGroupStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyGroup` objects. */
  stddevPopulation?: Maybe<CompanyGroupStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyGroup` objects. */
  varianceSample?: Maybe<CompanyGroupVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyGroup` objects. */
  variancePopulation?: Maybe<CompanyGroupVariancePopulationAggregateFilter>;
};

export type CompanyGroupAverageAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyGroupAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/** Grouping methods for `Company` for usage during aggregation. */
export enum CompanyGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  EquifaxCustomerNumber = 'EQUIFAX_CUSTOMER_NUMBER',
  SecurityCode = 'SECURITY_CODE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Archived = 'ARCHIVED',
  ZohoId = 'ZOHO_ID',
  DeliverTo = 'DELIVER_TO',
  RawMeta = 'RAW_META',
  BillableCompanyId = 'BILLABLE_COMPANY_ID',
  RawRequestedAccess = 'RAW_REQUESTED_ACCESS',
  LenderDealerCode = 'LENDER_DEALER_CODE',
  GooglePlacesId = 'GOOGLE_PLACES_ID',
  LegalConsent = 'LEGAL_CONSENT',
  StripeCustomerId = 'STRIPE_CUSTOMER_ID',
  SalesOrderId = 'SALES_ORDER_ID',
  Industry = 'INDUSTRY',
  Manufacturer = 'MANUFACTURER',
  Crm = 'CRM',
  AccountManager = 'ACCOUNT_MANAGER',
  SupportAgent = 'SUPPORT_AGENT',
  OpportunityType = 'OPPORTUNITY_TYPE',
  NumberOfLocations = 'NUMBER_OF_LOCATIONS',
  Website = 'WEBSITE',
  Dms = 'DMS',
  WebProvider = 'WEB_PROVIDER',
  Country = 'COUNTRY'
}

/** The fields on `companyGroup` to look up the row to connect. */
export type CompanyGroupCompanyGroupPkeyConnect = {
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** The fields on `companyGroup` to look up the row to delete. */
export type CompanyGroupCompanyGroupPkeyDelete = {
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** The `company` to be created by this mutation. */
export type CompanyGroupCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `companyGroup` to be created by this mutation. */
export type CompanyGroupCompanyIdFkeyCompanyGroupCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `CompanyGroupInput` mutation. */
export type CompanyGroupCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyGroupCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyGroup` in the `CompanyInput` mutation. */
export type CompanyGroupCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  connectByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupCompanyGroupPkeyConnect>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyGroupNodeIdConnect>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  deleteByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupCompanyGroupPkeyDelete>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyGroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyGroup` for the far side of the relationship. */
  updateByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyGroupCompanyIdFkeyCompanyGroupCreateInput>>;
};

/**
 * A condition to be used against `CompanyGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyGroupCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyGroupDistinctCountAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyGroupDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyGroup` object types. All fields are combined with a logical ‘and.’ */
export type CompanyGroupFilter = {
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<GroupFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyGroupFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyGroupFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyGroupFilter>;
};

/** Grouping methods for `CompanyGroup` for usage during aggregation. */
export enum CompanyGroupGroupBy {
  CompanyId = 'COMPANY_ID',
  GroupId = 'GROUP_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

/** The `companyGroup` to be created by this mutation. */
export type CompanyGroupGroupIdFkeyCompanyGroupCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

/** The `group` to be created by this mutation. */
export type CompanyGroupGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `CompanyGroupInput` mutation. */
export type CompanyGroupGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyGroupGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `companyGroup` in the `GroupInput` mutation. */
export type CompanyGroupGroupIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  connectByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupCompanyGroupPkeyConnect>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyGroupNodeIdConnect>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  deleteByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupCompanyGroupPkeyDelete>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyGroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyGroup` for the far side of the relationship. */
  updateByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingCompanyGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnCompanyGroupForCompanyGroupGroupIdFkeyNodeIdUpdate>>;
  /** A `CompanyGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyGroupGroupIdFkeyCompanyGroupCreateInput>>;
};

export type CompanyGroupHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyGroup` aggregates. */
export type CompanyGroupHavingInput = {
  AND?: Maybe<Array<CompanyGroupHavingInput>>;
  OR?: Maybe<Array<CompanyGroupHavingInput>>;
  sum?: Maybe<CompanyGroupHavingSumInput>;
  distinctCount?: Maybe<CompanyGroupHavingDistinctCountInput>;
  min?: Maybe<CompanyGroupHavingMinInput>;
  max?: Maybe<CompanyGroupHavingMaxInput>;
  average?: Maybe<CompanyGroupHavingAverageInput>;
  stddevSample?: Maybe<CompanyGroupHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyGroupHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyGroupHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyGroupHavingVariancePopulationInput>;
};

export type CompanyGroupHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyGroup` */
export type CompanyGroupInput = {
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

export type CompanyGroupMaxAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
};

export type CompanyGroupMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

export type CompanyGroupMinAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
};

export type CompanyGroupMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyGroupNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyGroup` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyGroupNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyGroup` to look up the row to update. */
export type CompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyGroup` being updated. */
  patch: UpdateCompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch;
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `companyGroup` to look up the row to update. */
export type CompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingCompanyGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyGroup` being updated. */
  patch: UpdateCompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyPatch;
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** Represents an update to a `CompanyGroup`. Fields that are set will be updated. */
export type CompanyGroupPatch = {
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

export type CompanyGroupStddevPopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyGroupStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyGroupStddevSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyGroupStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyGroupSumAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
};

export type CompanyGroupSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt'];
};

export type CompanyGroupVariancePopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyGroupVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyGroupVarianceSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyGroupVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Group` values, with data from `CompanyUser`. */
export type CompanyGroupsByCompanyUserCompanyIdAndGroupIdManyToManyConnection = {
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `CompanyUser`, and the cursor to aid in pagination. */
  edges: Array<CompanyGroupsByCompanyUserCompanyIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GroupAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GroupAggregates>>;
};


/** A connection to a list of `Group` values, with data from `CompanyUser`. */
export type CompanyGroupsByCompanyUserCompanyIdAndGroupIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<GroupGroupBy>;
  having?: Maybe<GroupHavingInput>;
};

/** A `Group` edge in the connection, with data from `CompanyUser`. */
export type CompanyGroupsByCompanyUserCompanyIdAndGroupIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsers: CompanyUsersConnection;
};


/** A `Group` edge in the connection, with data from `CompanyUser`. */
export type CompanyGroupsByCompanyUserCompanyIdAndGroupIdManyToManyEdgeCompanyUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyUsersOrderBy>>;
  condition?: Maybe<CompanyUserCondition>;
  filter?: Maybe<CompanyUserFilter>;
};

/** A connection to a list of `CompanyGroup` values. */
export type CompanyGroupsConnection = {
  /** A list of `CompanyGroup` objects. */
  nodes: Array<CompanyGroup>;
  /** A list of edges which contains the `CompanyGroup` and cursor to aid in pagination. */
  edges: Array<CompanyGroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyGroupAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyGroupAggregates>>;
};


/** A connection to a list of `CompanyGroup` values. */
export type CompanyGroupsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupGroupBy>;
  having?: Maybe<CompanyGroupHavingInput>;
};

/** A `CompanyGroup` edge in the connection. */
export type CompanyGroupsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyGroup` at the end of the edge. */
  node: CompanyGroup;
};

/** Methods to use when ordering `CompanyGroup`. */
export enum CompanyGroupsOrderBy {
  Natural = 'NATURAL',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompanyHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
  accountManager?: Maybe<HavingIntFilter>;
  supportAgent?: Maybe<HavingIntFilter>;
};

export type CompanyHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
  accountManager?: Maybe<HavingIntFilter>;
  supportAgent?: Maybe<HavingIntFilter>;
};

/** Conditions for `Company` aggregates. */
export type CompanyHavingInput = {
  AND?: Maybe<Array<CompanyHavingInput>>;
  OR?: Maybe<Array<CompanyHavingInput>>;
  sum?: Maybe<CompanyHavingSumInput>;
  distinctCount?: Maybe<CompanyHavingDistinctCountInput>;
  min?: Maybe<CompanyHavingMinInput>;
  max?: Maybe<CompanyHavingMaxInput>;
  average?: Maybe<CompanyHavingAverageInput>;
  stddevSample?: Maybe<CompanyHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyHavingVariancePopulationInput>;
};

export type CompanyHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
  accountManager?: Maybe<HavingIntFilter>;
  supportAgent?: Maybe<HavingIntFilter>;
};

export type CompanyHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
  accountManager?: Maybe<HavingIntFilter>;
  supportAgent?: Maybe<HavingIntFilter>;
};

export type CompanyHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
  accountManager?: Maybe<HavingIntFilter>;
  supportAgent?: Maybe<HavingIntFilter>;
};

export type CompanyHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
  accountManager?: Maybe<HavingIntFilter>;
  supportAgent?: Maybe<HavingIntFilter>;
};

export type CompanyHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
  accountManager?: Maybe<HavingIntFilter>;
  supportAgent?: Maybe<HavingIntFilter>;
};

export type CompanyHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
  accountManager?: Maybe<HavingIntFilter>;
  supportAgent?: Maybe<HavingIntFilter>;
};

export type CompanyHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
  accountManager?: Maybe<HavingIntFilter>;
  supportAgent?: Maybe<HavingIntFilter>;
};

export type CompanyIdToolMeta = {
  autoSendSms?: Maybe<Scalars['Boolean']>;
  templateType?: Maybe<IdToolTemplateType>;
  idCountry?: Maybe<IdSupportedCountries>;
  autoSendSmsForTrade?: Maybe<Scalars['Boolean']>;
  idProvider?: Maybe<Scalars['String']>;
  stripeDocumentOptions?: Maybe<StripeIdentityDocumentOptions>;
};

/** An input for mutations affecting `Company` */
export type CompanyInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

export type CompanyInstallationMeta = {
  problemUrl?: Maybe<Scalars['String']>;
};

export type CompanyIntegration = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  /** Reads a single `Integration` that is related to this `CompanyIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Company` that is related to this `CompanyIntegration`. */
  company?: Maybe<Company>;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
  /** Reads and enables pagination through a set of `CompanyProductIntegration`. */
  companyProductIntegrations: CompanyProductIntegrationsConnection;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
  /** Reads and enables pagination through a set of `VehicleStock`. */
  vehicleStocks: VehicleStocksConnection;
  /** Reads and enables pagination through a set of `Deal`. */
  deals: DealsConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByLenderQuoteCompanyIntegrationIdAndLeadId: CompanyIntegrationLeadsByLenderQuoteCompanyIntegrationIdAndLeadIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderAppsByLenderQuoteCompanyIntegrationIdAndLenderAppId: CompanyIntegrationLenderAppsByLenderQuoteCompanyIntegrationIdAndLenderAppIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lender`. */
  lendersByLenderQuoteCompanyIntegrationIdAndLenderId: CompanyIntegrationLendersByLenderQuoteCompanyIntegrationIdAndLenderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductId: CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByFieldCompanyIntegrationIdAndCompanyId: CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProductsByFieldCompanyIntegrationIdAndCompanyProductId: CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByFieldCompanyIntegrationIdAndCompanyChannelId: CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigsByFieldCompanyIntegrationIdAndConfig: CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByVehicleStockCompanyIntegrationIdAndCompanyId: CompanyIntegrationCompaniesByVehicleStockCompanyIntegrationIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByDealCompanyIntegrationIdAndLeadId: CompanyIntegrationLeadsByDealCompanyIntegrationIdAndLeadIdManyToManyConnection;
};


export type CompanyIntegrationLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};


export type CompanyIntegrationCompanyProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
  condition?: Maybe<CompanyProductIntegrationCondition>;
  filter?: Maybe<CompanyProductIntegrationFilter>;
};


export type CompanyIntegrationFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


export type CompanyIntegrationVehicleStocksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleStocksOrderBy>>;
  condition?: Maybe<VehicleStockCondition>;
  filter?: Maybe<VehicleStockFilter>;
};


export type CompanyIntegrationDealsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DealsOrderBy>>;
  condition?: Maybe<DealCondition>;
  filter?: Maybe<DealFilter>;
};


export type CompanyIntegrationLeadsByLenderQuoteCompanyIntegrationIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type CompanyIntegrationLenderAppsByLenderQuoteCompanyIntegrationIdAndLenderAppIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};


export type CompanyIntegrationLendersByLenderQuoteCompanyIntegrationIdAndLenderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LendersOrderBy>>;
  condition?: Maybe<LenderCondition>;
  filter?: Maybe<LenderFilter>;
};


export type CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};


export type CompanyIntegrationCompaniesByVehicleStockCompanyIntegrationIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyIntegrationLeadsByDealCompanyIntegrationIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

export type CompanyIntegrationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyIntegrationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyIntegrationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyIntegrationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyIntegrationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyIntegrationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyIntegrationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyIntegrationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyIntegrationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyIntegrationVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyIntegration` object types. */
export type CompanyIntegrationAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyIntegration` object to be included within the aggregate. */
  filter?: Maybe<CompanyIntegrationFilter>;
  /** Sum aggregate over matching `CompanyIntegration` objects. */
  sum?: Maybe<CompanyIntegrationSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyIntegration` objects. */
  distinctCount?: Maybe<CompanyIntegrationDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyIntegration` objects. */
  min?: Maybe<CompanyIntegrationMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyIntegration` objects. */
  max?: Maybe<CompanyIntegrationMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyIntegration` objects. */
  average?: Maybe<CompanyIntegrationAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyIntegration` objects. */
  stddevSample?: Maybe<CompanyIntegrationStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyIntegration` objects. */
  stddevPopulation?: Maybe<CompanyIntegrationStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyIntegration` objects. */
  varianceSample?: Maybe<CompanyIntegrationVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyIntegration` objects. */
  variancePopulation?: Maybe<CompanyIntegrationVariancePopulationAggregateFilter>;
};

export type CompanyIntegrationAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyIntegrationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `Company` values, with data from `VehicleStock`. */
export type CompanyIntegrationCompaniesByVehicleStockCompanyIntegrationIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `VehicleStock`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationCompaniesByVehicleStockCompanyIntegrationIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `VehicleStock`. */
export type CompanyIntegrationCompaniesByVehicleStockCompanyIntegrationIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `VehicleStock`. */
export type CompanyIntegrationCompaniesByVehicleStockCompanyIntegrationIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `VehicleStock`. */
  vehicleStocks: VehicleStocksConnection;
};


/** A `Company` edge in the connection, with data from `VehicleStock`. */
export type CompanyIntegrationCompaniesByVehicleStockCompanyIntegrationIdAndCompanyIdManyToManyEdgeVehicleStocksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleStocksOrderBy>>;
  condition?: Maybe<VehicleStockCondition>;
  filter?: Maybe<VehicleStockFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** The `company` to be created by this mutation. */
export type CompanyIntegrationCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `companyIntegration` to be created by this mutation. */
export type CompanyIntegrationCompanyIdFkeyCompanyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `CompanyIntegrationInput` mutation. */
export type CompanyIntegrationCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyIntegrationCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyIntegration` in the `CompanyInput` mutation. */
export type CompanyIntegrationCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyIntegrationCompanyIntegrationPkeyConnect>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyIntegrationNodeIdConnect>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyIntegrationCompanyIntegrationPkeyDelete>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyIntegrationCompanyIdFkeyCompanyIntegrationCreateInput>>;
};

/** The fields on `companyIntegration` to look up the row to connect. */
export type CompanyIntegrationCompanyIntegrationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyIntegration` to look up the row to delete. */
export type CompanyIntegrationCompanyIntegrationPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `CompanyProduct` values, with data from `CompanyProductIntegration`. */
export type CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct`, info from the `CompanyProductIntegration`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values, with data from `CompanyProductIntegration`. */
export type CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection, with data from `CompanyProductIntegration`. */
export type CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
  /** Reads and enables pagination through a set of `CompanyProductIntegration`. */
  companyProductIntegrations: CompanyProductIntegrationsConnection;
};


/** A `CompanyProduct` edge in the connection, with data from `CompanyProductIntegration`. */
export type CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyEdgeCompanyProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
  condition?: Maybe<CompanyProductIntegrationCondition>;
  filter?: Maybe<CompanyProductIntegrationFilter>;
};

/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/**
 * A condition to be used against `CompanyIntegration` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyIntegrationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
};

export type CompanyIntegrationDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  displayName?: Maybe<BigIntFilter>;
};

export type CompanyIntegrationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigManyToManyConnection = {
  /** A list of `FieldConfig` objects. */
  nodes: Array<FieldConfig>;
  /** A list of edges which contains the `FieldConfig`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldConfigAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldConfigAggregates>>;
};


/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldConfigGroupBy>;
  having?: Maybe<FieldConfigHavingInput>;
};

/** A `FieldConfig` edge in the connection, with data from `Field`. */
export type CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldConfig` at the end of the edge. */
  node: FieldConfig;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A filter to be used against `CompanyIntegration` object types. All fields are combined with a logical ‘and.’ */
export type CompanyIntegrationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `integrationId` field. */
  integrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `lenderQuotes` relation. */
  lenderQuotes?: Maybe<CompanyIntegrationToManyLenderQuoteFilter>;
  /** Some related `lenderQuotes` exist. */
  lenderQuotesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyProductIntegrations` relation. */
  companyProductIntegrations?: Maybe<CompanyIntegrationToManyCompanyProductIntegrationFilter>;
  /** Some related `companyProductIntegrations` exist. */
  companyProductIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fields` relation. */
  fields?: Maybe<CompanyIntegrationToManyFieldFilter>;
  /** Some related `fields` exist. */
  fieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `vehicleStocks` relation. */
  vehicleStocks?: Maybe<CompanyIntegrationToManyVehicleStockFilter>;
  /** Some related `vehicleStocks` exist. */
  vehicleStocksExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `deals` relation. */
  deals?: Maybe<CompanyIntegrationToManyDealFilter>;
  /** Some related `deals` exist. */
  dealsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `integration` relation. */
  integration?: Maybe<IntegrationFilter>;
  /** A related `integration` exists. */
  integrationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyIntegrationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyIntegrationFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyIntegrationFilter>;
};

/** Grouping methods for `CompanyIntegration` for usage during aggregation. */
export enum CompanyIntegrationGroupBy {
  IntegrationId = 'INTEGRATION_ID',
  CompanyId = 'COMPANY_ID',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Name = 'NAME',
  DisplayName = 'DISPLAY_NAME'
}

export type CompanyIntegrationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyIntegration` aggregates. */
export type CompanyIntegrationHavingInput = {
  AND?: Maybe<Array<CompanyIntegrationHavingInput>>;
  OR?: Maybe<Array<CompanyIntegrationHavingInput>>;
  sum?: Maybe<CompanyIntegrationHavingSumInput>;
  distinctCount?: Maybe<CompanyIntegrationHavingDistinctCountInput>;
  min?: Maybe<CompanyIntegrationHavingMinInput>;
  max?: Maybe<CompanyIntegrationHavingMaxInput>;
  average?: Maybe<CompanyIntegrationHavingAverageInput>;
  stddevSample?: Maybe<CompanyIntegrationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyIntegrationHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyIntegrationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyIntegrationHavingVariancePopulationInput>;
};

export type CompanyIntegrationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyIntegration` */
export type CompanyIntegrationInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** The `companyIntegration` to be created by this mutation. */
export type CompanyIntegrationIntegrationIdFkeyCompanyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `integration` in the `CompanyIntegrationInput` mutation. */
export type CompanyIntegrationIntegrationIdFkeyInput = {
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectById?: Maybe<IntegrationIntegrationPkeyConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectByName?: Maybe<IntegrationIntegrationNameUniqueConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectByNodeId?: Maybe<IntegrationNodeIdConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteById?: Maybe<IntegrationIntegrationPkeyDelete>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteByName?: Maybe<IntegrationIntegrationNameUniqueDelete>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateById?: Maybe<IntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyUsingIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateByName?: Maybe<IntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyUsingIntegrationNameUniqueUpdate>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyNodeIdUpdate>;
  /** A `IntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyIntegrationIntegrationIdFkeyIntegrationCreateInput>;
};

/** The `integration` to be created by this mutation. */
export type CompanyIntegrationIntegrationIdFkeyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `companyIntegration` in the `IntegrationInput` mutation. */
export type CompanyIntegrationIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyIntegrationCompanyIntegrationPkeyConnect>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyIntegrationNodeIdConnect>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyIntegrationCompanyIntegrationPkeyDelete>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<IntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyNodeIdUpdate>>;
  /** A `CompanyIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyIntegrationIntegrationIdFkeyCompanyIntegrationCreateInput>>;
};

/** A connection to a list of `Lead` values, with data from `Deal`. */
export type CompanyIntegrationLeadsByDealCompanyIntegrationIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `Deal`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationLeadsByDealCompanyIntegrationIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `Deal`. */
export type CompanyIntegrationLeadsByDealCompanyIntegrationIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `Deal`. */
export type CompanyIntegrationLeadsByDealCompanyIntegrationIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `Deal`. */
  deals: DealsConnection;
};


/** A `Lead` edge in the connection, with data from `Deal`. */
export type CompanyIntegrationLeadsByDealCompanyIntegrationIdAndLeadIdManyToManyEdgeDealsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DealsOrderBy>>;
  condition?: Maybe<DealCondition>;
  filter?: Maybe<DealFilter>;
};

/** A connection to a list of `Lead` values, with data from `LenderQuote`. */
export type CompanyIntegrationLeadsByLenderQuoteCompanyIntegrationIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationLeadsByLenderQuoteCompanyIntegrationIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `LenderQuote`. */
export type CompanyIntegrationLeadsByLenderQuoteCompanyIntegrationIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `LenderQuote`. */
export type CompanyIntegrationLeadsByLenderQuoteCompanyIntegrationIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `Lead` edge in the connection, with data from `LenderQuote`. */
export type CompanyIntegrationLeadsByLenderQuoteCompanyIntegrationIdAndLeadIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

/** A connection to a list of `LenderApp` values, with data from `LenderQuote`. */
export type CompanyIntegrationLenderAppsByLenderQuoteCompanyIntegrationIdAndLenderAppIdManyToManyConnection = {
  /** A list of `LenderApp` objects. */
  nodes: Array<LenderApp>;
  /** A list of edges which contains the `LenderApp`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationLenderAppsByLenderQuoteCompanyIntegrationIdAndLenderAppIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LenderApp` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderAppAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderAppAggregates>>;
};


/** A connection to a list of `LenderApp` values, with data from `LenderQuote`. */
export type CompanyIntegrationLenderAppsByLenderQuoteCompanyIntegrationIdAndLenderAppIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderAppGroupBy>;
  having?: Maybe<LenderAppHavingInput>;
};

/** A `LenderApp` edge in the connection, with data from `LenderQuote`. */
export type CompanyIntegrationLenderAppsByLenderQuoteCompanyIntegrationIdAndLenderAppIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LenderApp` at the end of the edge. */
  node: LenderApp;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `LenderApp` edge in the connection, with data from `LenderQuote`. */
export type CompanyIntegrationLenderAppsByLenderQuoteCompanyIntegrationIdAndLenderAppIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

/** A connection to a list of `Lender` values, with data from `LenderQuote`. */
export type CompanyIntegrationLendersByLenderQuoteCompanyIntegrationIdAndLenderIdManyToManyConnection = {
  /** A list of `Lender` objects. */
  nodes: Array<Lender>;
  /** A list of edges which contains the `Lender`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationLendersByLenderQuoteCompanyIntegrationIdAndLenderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lender` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderAggregates>>;
};


/** A connection to a list of `Lender` values, with data from `LenderQuote`. */
export type CompanyIntegrationLendersByLenderQuoteCompanyIntegrationIdAndLenderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderGroupBy>;
  having?: Maybe<LenderHavingInput>;
};

/** A `Lender` edge in the connection, with data from `LenderQuote`. */
export type CompanyIntegrationLendersByLenderQuoteCompanyIntegrationIdAndLenderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lender` at the end of the edge. */
  node: Lender;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `Lender` edge in the connection, with data from `LenderQuote`. */
export type CompanyIntegrationLendersByLenderQuoteCompanyIntegrationIdAndLenderIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

export type CompanyIntegrationMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyIntegrationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type CompanyIntegrationMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyIntegrationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyIntegrationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyIntegrationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyIntegration` to look up the row to update. */
export type CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: UpdateCompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `integration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: IntegrationPatch;
};

/** The fields on `companyIntegration` to look up the row to update. */
export type CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: UpdateCompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProductIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
  patch: CompanyProductIntegrationPatch;
};

/** The fields on `companyIntegration` to look up the row to update. */
export type CompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: UpdateCompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyIntegrationOnDealForDealsCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `deal` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `deal` being updated. */
  patch: DealPatch;
};

/** The fields on `companyIntegration` to look up the row to update. */
export type CompanyIntegrationOnDealForDealsCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: UpdateCompanyIntegrationOnDealForDealsCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: FieldPatch;
};

/** The fields on `companyIntegration` to look up the row to update. */
export type CompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: UpdateCompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyIntegrationOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderQuote` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: LenderQuotePatch;
};

/** The fields on `companyIntegration` to look up the row to update. */
export type CompanyIntegrationOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: UpdateCompanyIntegrationOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyIntegrationOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `vehicleStock` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `vehicleStock` being updated. */
  patch: VehicleStockPatch;
};

/** The fields on `companyIntegration` to look up the row to update. */
export type CompanyIntegrationOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: UpdateCompanyIntegrationOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyIntegration`. Fields that are set will be updated. */
export type CompanyIntegrationPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

export type CompanyIntegrationStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyIntegrationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyIntegrationStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyIntegrationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyIntegrationSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
};

export type CompanyIntegrationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of integrationId across the matching connection */
  integrationId: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type CompanyIntegrationToManyCompanyProductIntegrationFilter = {
  /** Every related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyProductIntegrationFilter>;
  /** Some related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyProductIntegrationFilter>;
  /** No related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyProductIntegrationFilter>;
  /** Aggregates across related `CompanyProductIntegration` match the filter criteria. */
  aggregates?: Maybe<CompanyProductIntegrationAggregatesFilter>;
};

/** A filter to be used against many `Deal` object types. All fields are combined with a logical ‘and.’ */
export type CompanyIntegrationToManyDealFilter = {
  /** Every related `Deal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<DealFilter>;
  /** Some related `Deal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<DealFilter>;
  /** No related `Deal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<DealFilter>;
  /** Aggregates across related `Deal` match the filter criteria. */
  aggregates?: Maybe<DealAggregatesFilter>;
};

/** A filter to be used against many `Field` object types. All fields are combined with a logical ‘and.’ */
export type CompanyIntegrationToManyFieldFilter = {
  /** Every related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldFilter>;
  /** Some related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldFilter>;
  /** No related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldFilter>;
  /** Aggregates across related `Field` match the filter criteria. */
  aggregates?: Maybe<FieldAggregatesFilter>;
};

/** A filter to be used against many `LenderQuote` object types. All fields are combined with a logical ‘and.’ */
export type CompanyIntegrationToManyLenderQuoteFilter = {
  /** Every related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderQuoteFilter>;
  /** Some related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderQuoteFilter>;
  /** No related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderQuoteFilter>;
  /** Aggregates across related `LenderQuote` match the filter criteria. */
  aggregates?: Maybe<LenderQuoteAggregatesFilter>;
};

/** A filter to be used against many `VehicleStock` object types. All fields are combined with a logical ‘and.’ */
export type CompanyIntegrationToManyVehicleStockFilter = {
  /** Every related `VehicleStock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<VehicleStockFilter>;
  /** Some related `VehicleStock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<VehicleStockFilter>;
  /** No related `VehicleStock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<VehicleStockFilter>;
  /** Aggregates across related `VehicleStock` match the filter criteria. */
  aggregates?: Maybe<VehicleStockAggregatesFilter>;
};

export type CompanyIntegrationVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyIntegrationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyIntegrationVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyIntegrationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Integration` values, with data from `CompanyIntegration`. */
export type CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyConnection = {
  /** A list of `Integration` objects. */
  nodes: Array<Integration>;
  /** A list of edges which contains the `Integration`, info from the `CompanyIntegration`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Integration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationAggregates>>;
};


/** A connection to a list of `Integration` values, with data from `CompanyIntegration`. */
export type CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationGroupBy>;
  having?: Maybe<IntegrationHavingInput>;
};

/** A `Integration` edge in the connection, with data from `CompanyIntegration`. */
export type CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Integration` at the end of the edge. */
  node: Integration;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrations: CompanyIntegrationsConnection;
};


/** A `Integration` edge in the connection, with data from `CompanyIntegration`. */
export type CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyEdgeCompanyIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};

/** A connection to a list of `CompanyIntegration` values. */
export type CompanyIntegrationsConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration` and cursor to aid in pagination. */
  edges: Array<CompanyIntegrationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values. */
export type CompanyIntegrationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection. */
export type CompanyIntegrationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
};

/** Methods to use when ordering `CompanyIntegration`. */
export enum CompanyIntegrationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  LenderQuotesCountAsc = 'LENDER_QUOTES_COUNT_ASC',
  LenderQuotesCountDesc = 'LENDER_QUOTES_COUNT_DESC',
  LenderQuotesSumIdAsc = 'LENDER_QUOTES_SUM_ID_ASC',
  LenderQuotesSumIdDesc = 'LENDER_QUOTES_SUM_ID_DESC',
  LenderQuotesSumLeadIdAsc = 'LENDER_QUOTES_SUM_LEAD_ID_ASC',
  LenderQuotesSumLeadIdDesc = 'LENDER_QUOTES_SUM_LEAD_ID_DESC',
  LenderQuotesSumLenderAppIdAsc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_ASC',
  LenderQuotesSumLenderAppIdDesc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_DESC',
  LenderQuotesSumProviderAsc = 'LENDER_QUOTES_SUM_PROVIDER_ASC',
  LenderQuotesSumProviderDesc = 'LENDER_QUOTES_SUM_PROVIDER_DESC',
  LenderQuotesSumQuoteJsonAsc = 'LENDER_QUOTES_SUM_QUOTE_JSON_ASC',
  LenderQuotesSumQuoteJsonDesc = 'LENDER_QUOTES_SUM_QUOTE_JSON_DESC',
  LenderQuotesSumCreatedAtAsc = 'LENDER_QUOTES_SUM_CREATED_AT_ASC',
  LenderQuotesSumCreatedAtDesc = 'LENDER_QUOTES_SUM_CREATED_AT_DESC',
  LenderQuotesSumUpdatedAtAsc = 'LENDER_QUOTES_SUM_UPDATED_AT_ASC',
  LenderQuotesSumUpdatedAtDesc = 'LENDER_QUOTES_SUM_UPDATED_AT_DESC',
  LenderQuotesSumTypeAsc = 'LENDER_QUOTES_SUM_TYPE_ASC',
  LenderQuotesSumTypeDesc = 'LENDER_QUOTES_SUM_TYPE_DESC',
  LenderQuotesSumExpiredAsc = 'LENDER_QUOTES_SUM_EXPIRED_ASC',
  LenderQuotesSumExpiredDesc = 'LENDER_QUOTES_SUM_EXPIRED_DESC',
  LenderQuotesSumLenderIdAsc = 'LENDER_QUOTES_SUM_LENDER_ID_ASC',
  LenderQuotesSumLenderIdDesc = 'LENDER_QUOTES_SUM_LENDER_ID_DESC',
  LenderQuotesSumCompanyIntegrationIdAsc = 'LENDER_QUOTES_SUM_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesSumCompanyIntegrationIdDesc = 'LENDER_QUOTES_SUM_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesSumStipsAsc = 'LENDER_QUOTES_SUM_STIPS_ASC',
  LenderQuotesSumStipsDesc = 'LENDER_QUOTES_SUM_STIPS_DESC',
  LenderQuotesSumApplicationLinkAsc = 'LENDER_QUOTES_SUM_APPLICATION_LINK_ASC',
  LenderQuotesSumApplicationLinkDesc = 'LENDER_QUOTES_SUM_APPLICATION_LINK_DESC',
  LenderQuotesDistinctCountIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_ASC',
  LenderQuotesDistinctCountIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_DESC',
  LenderQuotesDistinctCountLeadIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_ASC',
  LenderQuotesDistinctCountLeadIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_DESC',
  LenderQuotesDistinctCountLenderAppIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_ASC',
  LenderQuotesDistinctCountLenderAppIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_DESC',
  LenderQuotesDistinctCountProviderAsc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_ASC',
  LenderQuotesDistinctCountProviderDesc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_DESC',
  LenderQuotesDistinctCountQuoteJsonAsc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_ASC',
  LenderQuotesDistinctCountQuoteJsonDesc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_DESC',
  LenderQuotesDistinctCountCreatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderQuotesDistinctCountCreatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderQuotesDistinctCountUpdatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderQuotesDistinctCountUpdatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderQuotesDistinctCountTypeAsc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_ASC',
  LenderQuotesDistinctCountTypeDesc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_DESC',
  LenderQuotesDistinctCountExpiredAsc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_ASC',
  LenderQuotesDistinctCountExpiredDesc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_DESC',
  LenderQuotesDistinctCountLenderIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_ID_ASC',
  LenderQuotesDistinctCountLenderIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_ID_DESC',
  LenderQuotesDistinctCountCompanyIntegrationIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesDistinctCountCompanyIntegrationIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesDistinctCountStipsAsc = 'LENDER_QUOTES_DISTINCT_COUNT_STIPS_ASC',
  LenderQuotesDistinctCountStipsDesc = 'LENDER_QUOTES_DISTINCT_COUNT_STIPS_DESC',
  LenderQuotesDistinctCountApplicationLinkAsc = 'LENDER_QUOTES_DISTINCT_COUNT_APPLICATION_LINK_ASC',
  LenderQuotesDistinctCountApplicationLinkDesc = 'LENDER_QUOTES_DISTINCT_COUNT_APPLICATION_LINK_DESC',
  LenderQuotesMinIdAsc = 'LENDER_QUOTES_MIN_ID_ASC',
  LenderQuotesMinIdDesc = 'LENDER_QUOTES_MIN_ID_DESC',
  LenderQuotesMinLeadIdAsc = 'LENDER_QUOTES_MIN_LEAD_ID_ASC',
  LenderQuotesMinLeadIdDesc = 'LENDER_QUOTES_MIN_LEAD_ID_DESC',
  LenderQuotesMinLenderAppIdAsc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_ASC',
  LenderQuotesMinLenderAppIdDesc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_DESC',
  LenderQuotesMinProviderAsc = 'LENDER_QUOTES_MIN_PROVIDER_ASC',
  LenderQuotesMinProviderDesc = 'LENDER_QUOTES_MIN_PROVIDER_DESC',
  LenderQuotesMinQuoteJsonAsc = 'LENDER_QUOTES_MIN_QUOTE_JSON_ASC',
  LenderQuotesMinQuoteJsonDesc = 'LENDER_QUOTES_MIN_QUOTE_JSON_DESC',
  LenderQuotesMinCreatedAtAsc = 'LENDER_QUOTES_MIN_CREATED_AT_ASC',
  LenderQuotesMinCreatedAtDesc = 'LENDER_QUOTES_MIN_CREATED_AT_DESC',
  LenderQuotesMinUpdatedAtAsc = 'LENDER_QUOTES_MIN_UPDATED_AT_ASC',
  LenderQuotesMinUpdatedAtDesc = 'LENDER_QUOTES_MIN_UPDATED_AT_DESC',
  LenderQuotesMinTypeAsc = 'LENDER_QUOTES_MIN_TYPE_ASC',
  LenderQuotesMinTypeDesc = 'LENDER_QUOTES_MIN_TYPE_DESC',
  LenderQuotesMinExpiredAsc = 'LENDER_QUOTES_MIN_EXPIRED_ASC',
  LenderQuotesMinExpiredDesc = 'LENDER_QUOTES_MIN_EXPIRED_DESC',
  LenderQuotesMinLenderIdAsc = 'LENDER_QUOTES_MIN_LENDER_ID_ASC',
  LenderQuotesMinLenderIdDesc = 'LENDER_QUOTES_MIN_LENDER_ID_DESC',
  LenderQuotesMinCompanyIntegrationIdAsc = 'LENDER_QUOTES_MIN_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesMinCompanyIntegrationIdDesc = 'LENDER_QUOTES_MIN_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesMinStipsAsc = 'LENDER_QUOTES_MIN_STIPS_ASC',
  LenderQuotesMinStipsDesc = 'LENDER_QUOTES_MIN_STIPS_DESC',
  LenderQuotesMinApplicationLinkAsc = 'LENDER_QUOTES_MIN_APPLICATION_LINK_ASC',
  LenderQuotesMinApplicationLinkDesc = 'LENDER_QUOTES_MIN_APPLICATION_LINK_DESC',
  LenderQuotesMaxIdAsc = 'LENDER_QUOTES_MAX_ID_ASC',
  LenderQuotesMaxIdDesc = 'LENDER_QUOTES_MAX_ID_DESC',
  LenderQuotesMaxLeadIdAsc = 'LENDER_QUOTES_MAX_LEAD_ID_ASC',
  LenderQuotesMaxLeadIdDesc = 'LENDER_QUOTES_MAX_LEAD_ID_DESC',
  LenderQuotesMaxLenderAppIdAsc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_ASC',
  LenderQuotesMaxLenderAppIdDesc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_DESC',
  LenderQuotesMaxProviderAsc = 'LENDER_QUOTES_MAX_PROVIDER_ASC',
  LenderQuotesMaxProviderDesc = 'LENDER_QUOTES_MAX_PROVIDER_DESC',
  LenderQuotesMaxQuoteJsonAsc = 'LENDER_QUOTES_MAX_QUOTE_JSON_ASC',
  LenderQuotesMaxQuoteJsonDesc = 'LENDER_QUOTES_MAX_QUOTE_JSON_DESC',
  LenderQuotesMaxCreatedAtAsc = 'LENDER_QUOTES_MAX_CREATED_AT_ASC',
  LenderQuotesMaxCreatedAtDesc = 'LENDER_QUOTES_MAX_CREATED_AT_DESC',
  LenderQuotesMaxUpdatedAtAsc = 'LENDER_QUOTES_MAX_UPDATED_AT_ASC',
  LenderQuotesMaxUpdatedAtDesc = 'LENDER_QUOTES_MAX_UPDATED_AT_DESC',
  LenderQuotesMaxTypeAsc = 'LENDER_QUOTES_MAX_TYPE_ASC',
  LenderQuotesMaxTypeDesc = 'LENDER_QUOTES_MAX_TYPE_DESC',
  LenderQuotesMaxExpiredAsc = 'LENDER_QUOTES_MAX_EXPIRED_ASC',
  LenderQuotesMaxExpiredDesc = 'LENDER_QUOTES_MAX_EXPIRED_DESC',
  LenderQuotesMaxLenderIdAsc = 'LENDER_QUOTES_MAX_LENDER_ID_ASC',
  LenderQuotesMaxLenderIdDesc = 'LENDER_QUOTES_MAX_LENDER_ID_DESC',
  LenderQuotesMaxCompanyIntegrationIdAsc = 'LENDER_QUOTES_MAX_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesMaxCompanyIntegrationIdDesc = 'LENDER_QUOTES_MAX_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesMaxStipsAsc = 'LENDER_QUOTES_MAX_STIPS_ASC',
  LenderQuotesMaxStipsDesc = 'LENDER_QUOTES_MAX_STIPS_DESC',
  LenderQuotesMaxApplicationLinkAsc = 'LENDER_QUOTES_MAX_APPLICATION_LINK_ASC',
  LenderQuotesMaxApplicationLinkDesc = 'LENDER_QUOTES_MAX_APPLICATION_LINK_DESC',
  LenderQuotesAverageIdAsc = 'LENDER_QUOTES_AVERAGE_ID_ASC',
  LenderQuotesAverageIdDesc = 'LENDER_QUOTES_AVERAGE_ID_DESC',
  LenderQuotesAverageLeadIdAsc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_ASC',
  LenderQuotesAverageLeadIdDesc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_DESC',
  LenderQuotesAverageLenderAppIdAsc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_ASC',
  LenderQuotesAverageLenderAppIdDesc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_DESC',
  LenderQuotesAverageProviderAsc = 'LENDER_QUOTES_AVERAGE_PROVIDER_ASC',
  LenderQuotesAverageProviderDesc = 'LENDER_QUOTES_AVERAGE_PROVIDER_DESC',
  LenderQuotesAverageQuoteJsonAsc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_ASC',
  LenderQuotesAverageQuoteJsonDesc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_DESC',
  LenderQuotesAverageCreatedAtAsc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_ASC',
  LenderQuotesAverageCreatedAtDesc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_DESC',
  LenderQuotesAverageUpdatedAtAsc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_ASC',
  LenderQuotesAverageUpdatedAtDesc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_DESC',
  LenderQuotesAverageTypeAsc = 'LENDER_QUOTES_AVERAGE_TYPE_ASC',
  LenderQuotesAverageTypeDesc = 'LENDER_QUOTES_AVERAGE_TYPE_DESC',
  LenderQuotesAverageExpiredAsc = 'LENDER_QUOTES_AVERAGE_EXPIRED_ASC',
  LenderQuotesAverageExpiredDesc = 'LENDER_QUOTES_AVERAGE_EXPIRED_DESC',
  LenderQuotesAverageLenderIdAsc = 'LENDER_QUOTES_AVERAGE_LENDER_ID_ASC',
  LenderQuotesAverageLenderIdDesc = 'LENDER_QUOTES_AVERAGE_LENDER_ID_DESC',
  LenderQuotesAverageCompanyIntegrationIdAsc = 'LENDER_QUOTES_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesAverageCompanyIntegrationIdDesc = 'LENDER_QUOTES_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesAverageStipsAsc = 'LENDER_QUOTES_AVERAGE_STIPS_ASC',
  LenderQuotesAverageStipsDesc = 'LENDER_QUOTES_AVERAGE_STIPS_DESC',
  LenderQuotesAverageApplicationLinkAsc = 'LENDER_QUOTES_AVERAGE_APPLICATION_LINK_ASC',
  LenderQuotesAverageApplicationLinkDesc = 'LENDER_QUOTES_AVERAGE_APPLICATION_LINK_DESC',
  LenderQuotesStddevSampleIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_ASC',
  LenderQuotesStddevSampleIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_DESC',
  LenderQuotesStddevSampleLeadIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_ASC',
  LenderQuotesStddevSampleLeadIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_DESC',
  LenderQuotesStddevSampleLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesStddevSampleLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesStddevSampleProviderAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_ASC',
  LenderQuotesStddevSampleProviderDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_DESC',
  LenderQuotesStddevSampleQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesStddevSampleQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesStddevSampleCreatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderQuotesStddevSampleCreatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderQuotesStddevSampleUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesStddevSampleUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesStddevSampleTypeAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_ASC',
  LenderQuotesStddevSampleTypeDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_DESC',
  LenderQuotesStddevSampleExpiredAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_ASC',
  LenderQuotesStddevSampleExpiredDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_DESC',
  LenderQuotesStddevSampleLenderIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_ID_ASC',
  LenderQuotesStddevSampleLenderIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_ID_DESC',
  LenderQuotesStddevSampleCompanyIntegrationIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesStddevSampleCompanyIntegrationIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesStddevSampleStipsAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_STIPS_ASC',
  LenderQuotesStddevSampleStipsDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_STIPS_DESC',
  LenderQuotesStddevSampleApplicationLinkAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_APPLICATION_LINK_ASC',
  LenderQuotesStddevSampleApplicationLinkDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_APPLICATION_LINK_DESC',
  LenderQuotesStddevPopulationIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_ASC',
  LenderQuotesStddevPopulationIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_DESC',
  LenderQuotesStddevPopulationLeadIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_ASC',
  LenderQuotesStddevPopulationLeadIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_DESC',
  LenderQuotesStddevPopulationLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesStddevPopulationLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesStddevPopulationProviderAsc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_ASC',
  LenderQuotesStddevPopulationProviderDesc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_DESC',
  LenderQuotesStddevPopulationQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesStddevPopulationQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesStddevPopulationCreatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderQuotesStddevPopulationCreatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderQuotesStddevPopulationUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderQuotesStddevPopulationUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderQuotesStddevPopulationTypeAsc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_ASC',
  LenderQuotesStddevPopulationTypeDesc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_DESC',
  LenderQuotesStddevPopulationExpiredAsc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_ASC',
  LenderQuotesStddevPopulationExpiredDesc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_DESC',
  LenderQuotesStddevPopulationLenderIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_ID_ASC',
  LenderQuotesStddevPopulationLenderIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_ID_DESC',
  LenderQuotesStddevPopulationCompanyIntegrationIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesStddevPopulationCompanyIntegrationIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesStddevPopulationStipsAsc = 'LENDER_QUOTES_STDDEV_POPULATION_STIPS_ASC',
  LenderQuotesStddevPopulationStipsDesc = 'LENDER_QUOTES_STDDEV_POPULATION_STIPS_DESC',
  LenderQuotesStddevPopulationApplicationLinkAsc = 'LENDER_QUOTES_STDDEV_POPULATION_APPLICATION_LINK_ASC',
  LenderQuotesStddevPopulationApplicationLinkDesc = 'LENDER_QUOTES_STDDEV_POPULATION_APPLICATION_LINK_DESC',
  LenderQuotesVarianceSampleIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_ASC',
  LenderQuotesVarianceSampleIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_DESC',
  LenderQuotesVarianceSampleLeadIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LenderQuotesVarianceSampleLeadIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LenderQuotesVarianceSampleLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesVarianceSampleLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesVarianceSampleProviderAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_ASC',
  LenderQuotesVarianceSampleProviderDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_DESC',
  LenderQuotesVarianceSampleQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesVarianceSampleQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesVarianceSampleCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderQuotesVarianceSampleCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderQuotesVarianceSampleUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesVarianceSampleUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesVarianceSampleTypeAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_ASC',
  LenderQuotesVarianceSampleTypeDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_DESC',
  LenderQuotesVarianceSampleExpiredAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_ASC',
  LenderQuotesVarianceSampleExpiredDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_DESC',
  LenderQuotesVarianceSampleLenderIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_ID_ASC',
  LenderQuotesVarianceSampleLenderIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_ID_DESC',
  LenderQuotesVarianceSampleCompanyIntegrationIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesVarianceSampleCompanyIntegrationIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesVarianceSampleStipsAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_STIPS_ASC',
  LenderQuotesVarianceSampleStipsDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_STIPS_DESC',
  LenderQuotesVarianceSampleApplicationLinkAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_APPLICATION_LINK_ASC',
  LenderQuotesVarianceSampleApplicationLinkDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_APPLICATION_LINK_DESC',
  LenderQuotesVariancePopulationIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_ASC',
  LenderQuotesVariancePopulationIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_DESC',
  LenderQuotesVariancePopulationLeadIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_ASC',
  LenderQuotesVariancePopulationLeadIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_DESC',
  LenderQuotesVariancePopulationLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesVariancePopulationLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesVariancePopulationProviderAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_ASC',
  LenderQuotesVariancePopulationProviderDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_DESC',
  LenderQuotesVariancePopulationQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesVariancePopulationQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesVariancePopulationCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderQuotesVariancePopulationCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderQuotesVariancePopulationUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderQuotesVariancePopulationUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderQuotesVariancePopulationTypeAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_ASC',
  LenderQuotesVariancePopulationTypeDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_DESC',
  LenderQuotesVariancePopulationExpiredAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_ASC',
  LenderQuotesVariancePopulationExpiredDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_DESC',
  LenderQuotesVariancePopulationLenderIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_ID_ASC',
  LenderQuotesVariancePopulationLenderIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_ID_DESC',
  LenderQuotesVariancePopulationCompanyIntegrationIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesVariancePopulationCompanyIntegrationIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesVariancePopulationStipsAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_STIPS_ASC',
  LenderQuotesVariancePopulationStipsDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_STIPS_DESC',
  LenderQuotesVariancePopulationApplicationLinkAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_APPLICATION_LINK_ASC',
  LenderQuotesVariancePopulationApplicationLinkDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_APPLICATION_LINK_DESC',
  CompanyProductIntegrationsCountAsc = 'COMPANY_PRODUCT_INTEGRATIONS_COUNT_ASC',
  CompanyProductIntegrationsCountDesc = 'COMPANY_PRODUCT_INTEGRATIONS_COUNT_DESC',
  CompanyProductIntegrationsSumIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ID_ASC',
  CompanyProductIntegrationsSumIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ID_DESC',
  CompanyProductIntegrationsSumCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsSumCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsSumCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsSumCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsSumEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ENABLED_ASC',
  CompanyProductIntegrationsSumEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ENABLED_DESC',
  CompanyProductIntegrationsSumCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_CREATED_AT_ASC',
  CompanyProductIntegrationsSumCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_CREATED_AT_DESC',
  CompanyProductIntegrationsSumUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  CompanyProductIntegrationsSumUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  CompanyProductIntegrationsDistinctCountIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  CompanyProductIntegrationsDistinctCountIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  CompanyProductIntegrationsDistinctCountCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsDistinctCountCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsDistinctCountCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsDistinctCountCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsDistinctCountEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyProductIntegrationsDistinctCountEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyProductIntegrationsDistinctCountCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyProductIntegrationsDistinctCountCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyProductIntegrationsDistinctCountUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyProductIntegrationsDistinctCountUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyProductIntegrationsMinIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ID_ASC',
  CompanyProductIntegrationsMinIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ID_DESC',
  CompanyProductIntegrationsMinCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsMinCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsMinCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsMinCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsMinEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ENABLED_ASC',
  CompanyProductIntegrationsMinEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ENABLED_DESC',
  CompanyProductIntegrationsMinCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_CREATED_AT_ASC',
  CompanyProductIntegrationsMinCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_CREATED_AT_DESC',
  CompanyProductIntegrationsMinUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  CompanyProductIntegrationsMinUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  CompanyProductIntegrationsMaxIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ID_ASC',
  CompanyProductIntegrationsMaxIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ID_DESC',
  CompanyProductIntegrationsMaxCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsMaxCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsMaxCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsMaxCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsMaxEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ENABLED_ASC',
  CompanyProductIntegrationsMaxEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ENABLED_DESC',
  CompanyProductIntegrationsMaxCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_CREATED_AT_ASC',
  CompanyProductIntegrationsMaxCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_CREATED_AT_DESC',
  CompanyProductIntegrationsMaxUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  CompanyProductIntegrationsMaxUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  CompanyProductIntegrationsAverageIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ID_ASC',
  CompanyProductIntegrationsAverageIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ID_DESC',
  CompanyProductIntegrationsAverageCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsAverageCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsAverageCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsAverageCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsAverageEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  CompanyProductIntegrationsAverageEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  CompanyProductIntegrationsAverageCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  CompanyProductIntegrationsAverageCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  CompanyProductIntegrationsAverageUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  CompanyProductIntegrationsAverageUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  CompanyProductIntegrationsStddevSampleIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  CompanyProductIntegrationsStddevSampleIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  CompanyProductIntegrationsStddevSampleCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsStddevSampleCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsStddevSampleCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsStddevSampleCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsStddevSampleEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyProductIntegrationsStddevSampleEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyProductIntegrationsStddevSampleCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyProductIntegrationsStddevSampleCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyProductIntegrationsStddevSampleUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyProductIntegrationsStddevSampleUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyProductIntegrationsStddevPopulationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  CompanyProductIntegrationsStddevPopulationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  CompanyProductIntegrationsStddevPopulationCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsStddevPopulationCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsStddevPopulationCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsStddevPopulationCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsStddevPopulationEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyProductIntegrationsStddevPopulationEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyProductIntegrationsStddevPopulationCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyProductIntegrationsStddevPopulationCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyProductIntegrationsStddevPopulationUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyProductIntegrationsStddevPopulationUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyProductIntegrationsVarianceSampleIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  CompanyProductIntegrationsVarianceSampleIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  CompanyProductIntegrationsVarianceSampleCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsVarianceSampleCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsVarianceSampleCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsVarianceSampleCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsVarianceSampleEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyProductIntegrationsVarianceSampleEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyProductIntegrationsVarianceSampleCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyProductIntegrationsVarianceSampleCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyProductIntegrationsVarianceSampleUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyProductIntegrationsVarianceSampleUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyProductIntegrationsVariancePopulationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  CompanyProductIntegrationsVariancePopulationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  CompanyProductIntegrationsVariancePopulationCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsVariancePopulationCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsVariancePopulationCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsVariancePopulationCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsVariancePopulationEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyProductIntegrationsVariancePopulationEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyProductIntegrationsVariancePopulationCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyProductIntegrationsVariancePopulationCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyProductIntegrationsVariancePopulationUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyProductIntegrationsVariancePopulationUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FieldsCountAsc = 'FIELDS_COUNT_ASC',
  FieldsCountDesc = 'FIELDS_COUNT_DESC',
  FieldsSumIdAsc = 'FIELDS_SUM_ID_ASC',
  FieldsSumIdDesc = 'FIELDS_SUM_ID_DESC',
  FieldsSumCompanyIdAsc = 'FIELDS_SUM_COMPANY_ID_ASC',
  FieldsSumCompanyIdDesc = 'FIELDS_SUM_COMPANY_ID_DESC',
  FieldsSumCompanyProductIdAsc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_ASC',
  FieldsSumCompanyProductIdDesc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_DESC',
  FieldsSumCompanyChannelIdAsc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_ASC',
  FieldsSumCompanyChannelIdDesc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_DESC',
  FieldsSumCompanyIntegrationIdAsc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_ASC',
  FieldsSumCompanyIntegrationIdDesc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_DESC',
  FieldsSumConfigAsc = 'FIELDS_SUM_CONFIG_ASC',
  FieldsSumConfigDesc = 'FIELDS_SUM_CONFIG_DESC',
  FieldsSumTopicAsc = 'FIELDS_SUM_TOPIC_ASC',
  FieldsSumTopicDesc = 'FIELDS_SUM_TOPIC_DESC',
  FieldsSumValueAsc = 'FIELDS_SUM_VALUE_ASC',
  FieldsSumValueDesc = 'FIELDS_SUM_VALUE_DESC',
  FieldsSumCreatedAtAsc = 'FIELDS_SUM_CREATED_AT_ASC',
  FieldsSumCreatedAtDesc = 'FIELDS_SUM_CREATED_AT_DESC',
  FieldsSumUpdatedAtAsc = 'FIELDS_SUM_UPDATED_AT_ASC',
  FieldsSumUpdatedAtDesc = 'FIELDS_SUM_UPDATED_AT_DESC',
  FieldsDistinctCountIdAsc = 'FIELDS_DISTINCT_COUNT_ID_ASC',
  FieldsDistinctCountIdDesc = 'FIELDS_DISTINCT_COUNT_ID_DESC',
  FieldsDistinctCountCompanyIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_ASC',
  FieldsDistinctCountCompanyIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_DESC',
  FieldsDistinctCountCompanyProductIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  FieldsDistinctCountCompanyProductIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  FieldsDistinctCountCompanyChannelIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  FieldsDistinctCountCompanyChannelIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  FieldsDistinctCountCompanyIntegrationIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  FieldsDistinctCountCompanyIntegrationIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  FieldsDistinctCountConfigAsc = 'FIELDS_DISTINCT_COUNT_CONFIG_ASC',
  FieldsDistinctCountConfigDesc = 'FIELDS_DISTINCT_COUNT_CONFIG_DESC',
  FieldsDistinctCountTopicAsc = 'FIELDS_DISTINCT_COUNT_TOPIC_ASC',
  FieldsDistinctCountTopicDesc = 'FIELDS_DISTINCT_COUNT_TOPIC_DESC',
  FieldsDistinctCountValueAsc = 'FIELDS_DISTINCT_COUNT_VALUE_ASC',
  FieldsDistinctCountValueDesc = 'FIELDS_DISTINCT_COUNT_VALUE_DESC',
  FieldsDistinctCountCreatedAtAsc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldsDistinctCountCreatedAtDesc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldsDistinctCountUpdatedAtAsc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldsDistinctCountUpdatedAtDesc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldsMinIdAsc = 'FIELDS_MIN_ID_ASC',
  FieldsMinIdDesc = 'FIELDS_MIN_ID_DESC',
  FieldsMinCompanyIdAsc = 'FIELDS_MIN_COMPANY_ID_ASC',
  FieldsMinCompanyIdDesc = 'FIELDS_MIN_COMPANY_ID_DESC',
  FieldsMinCompanyProductIdAsc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_ASC',
  FieldsMinCompanyProductIdDesc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_DESC',
  FieldsMinCompanyChannelIdAsc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_ASC',
  FieldsMinCompanyChannelIdDesc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_DESC',
  FieldsMinCompanyIntegrationIdAsc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_ASC',
  FieldsMinCompanyIntegrationIdDesc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_DESC',
  FieldsMinConfigAsc = 'FIELDS_MIN_CONFIG_ASC',
  FieldsMinConfigDesc = 'FIELDS_MIN_CONFIG_DESC',
  FieldsMinTopicAsc = 'FIELDS_MIN_TOPIC_ASC',
  FieldsMinTopicDesc = 'FIELDS_MIN_TOPIC_DESC',
  FieldsMinValueAsc = 'FIELDS_MIN_VALUE_ASC',
  FieldsMinValueDesc = 'FIELDS_MIN_VALUE_DESC',
  FieldsMinCreatedAtAsc = 'FIELDS_MIN_CREATED_AT_ASC',
  FieldsMinCreatedAtDesc = 'FIELDS_MIN_CREATED_AT_DESC',
  FieldsMinUpdatedAtAsc = 'FIELDS_MIN_UPDATED_AT_ASC',
  FieldsMinUpdatedAtDesc = 'FIELDS_MIN_UPDATED_AT_DESC',
  FieldsMaxIdAsc = 'FIELDS_MAX_ID_ASC',
  FieldsMaxIdDesc = 'FIELDS_MAX_ID_DESC',
  FieldsMaxCompanyIdAsc = 'FIELDS_MAX_COMPANY_ID_ASC',
  FieldsMaxCompanyIdDesc = 'FIELDS_MAX_COMPANY_ID_DESC',
  FieldsMaxCompanyProductIdAsc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_ASC',
  FieldsMaxCompanyProductIdDesc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_DESC',
  FieldsMaxCompanyChannelIdAsc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_ASC',
  FieldsMaxCompanyChannelIdDesc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_DESC',
  FieldsMaxCompanyIntegrationIdAsc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_ASC',
  FieldsMaxCompanyIntegrationIdDesc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_DESC',
  FieldsMaxConfigAsc = 'FIELDS_MAX_CONFIG_ASC',
  FieldsMaxConfigDesc = 'FIELDS_MAX_CONFIG_DESC',
  FieldsMaxTopicAsc = 'FIELDS_MAX_TOPIC_ASC',
  FieldsMaxTopicDesc = 'FIELDS_MAX_TOPIC_DESC',
  FieldsMaxValueAsc = 'FIELDS_MAX_VALUE_ASC',
  FieldsMaxValueDesc = 'FIELDS_MAX_VALUE_DESC',
  FieldsMaxCreatedAtAsc = 'FIELDS_MAX_CREATED_AT_ASC',
  FieldsMaxCreatedAtDesc = 'FIELDS_MAX_CREATED_AT_DESC',
  FieldsMaxUpdatedAtAsc = 'FIELDS_MAX_UPDATED_AT_ASC',
  FieldsMaxUpdatedAtDesc = 'FIELDS_MAX_UPDATED_AT_DESC',
  FieldsAverageIdAsc = 'FIELDS_AVERAGE_ID_ASC',
  FieldsAverageIdDesc = 'FIELDS_AVERAGE_ID_DESC',
  FieldsAverageCompanyIdAsc = 'FIELDS_AVERAGE_COMPANY_ID_ASC',
  FieldsAverageCompanyIdDesc = 'FIELDS_AVERAGE_COMPANY_ID_DESC',
  FieldsAverageCompanyProductIdAsc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  FieldsAverageCompanyProductIdDesc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  FieldsAverageCompanyChannelIdAsc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  FieldsAverageCompanyChannelIdDesc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  FieldsAverageCompanyIntegrationIdAsc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  FieldsAverageCompanyIntegrationIdDesc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  FieldsAverageConfigAsc = 'FIELDS_AVERAGE_CONFIG_ASC',
  FieldsAverageConfigDesc = 'FIELDS_AVERAGE_CONFIG_DESC',
  FieldsAverageTopicAsc = 'FIELDS_AVERAGE_TOPIC_ASC',
  FieldsAverageTopicDesc = 'FIELDS_AVERAGE_TOPIC_DESC',
  FieldsAverageValueAsc = 'FIELDS_AVERAGE_VALUE_ASC',
  FieldsAverageValueDesc = 'FIELDS_AVERAGE_VALUE_DESC',
  FieldsAverageCreatedAtAsc = 'FIELDS_AVERAGE_CREATED_AT_ASC',
  FieldsAverageCreatedAtDesc = 'FIELDS_AVERAGE_CREATED_AT_DESC',
  FieldsAverageUpdatedAtAsc = 'FIELDS_AVERAGE_UPDATED_AT_ASC',
  FieldsAverageUpdatedAtDesc = 'FIELDS_AVERAGE_UPDATED_AT_DESC',
  FieldsStddevSampleIdAsc = 'FIELDS_STDDEV_SAMPLE_ID_ASC',
  FieldsStddevSampleIdDesc = 'FIELDS_STDDEV_SAMPLE_ID_DESC',
  FieldsStddevSampleCompanyIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FieldsStddevSampleCompanyIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FieldsStddevSampleCompanyProductIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevSampleCompanyProductIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevSampleCompanyChannelIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevSampleCompanyChannelIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevSampleCompanyIntegrationIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevSampleCompanyIntegrationIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevSampleConfigAsc = 'FIELDS_STDDEV_SAMPLE_CONFIG_ASC',
  FieldsStddevSampleConfigDesc = 'FIELDS_STDDEV_SAMPLE_CONFIG_DESC',
  FieldsStddevSampleTopicAsc = 'FIELDS_STDDEV_SAMPLE_TOPIC_ASC',
  FieldsStddevSampleTopicDesc = 'FIELDS_STDDEV_SAMPLE_TOPIC_DESC',
  FieldsStddevSampleValueAsc = 'FIELDS_STDDEV_SAMPLE_VALUE_ASC',
  FieldsStddevSampleValueDesc = 'FIELDS_STDDEV_SAMPLE_VALUE_DESC',
  FieldsStddevSampleCreatedAtAsc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldsStddevSampleCreatedAtDesc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldsStddevSampleUpdatedAtAsc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldsStddevSampleUpdatedAtDesc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldsStddevPopulationIdAsc = 'FIELDS_STDDEV_POPULATION_ID_ASC',
  FieldsStddevPopulationIdDesc = 'FIELDS_STDDEV_POPULATION_ID_DESC',
  FieldsStddevPopulationCompanyIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_ASC',
  FieldsStddevPopulationCompanyIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_DESC',
  FieldsStddevPopulationCompanyProductIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevPopulationCompanyProductIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevPopulationCompanyChannelIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevPopulationCompanyChannelIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevPopulationCompanyIntegrationIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevPopulationCompanyIntegrationIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevPopulationConfigAsc = 'FIELDS_STDDEV_POPULATION_CONFIG_ASC',
  FieldsStddevPopulationConfigDesc = 'FIELDS_STDDEV_POPULATION_CONFIG_DESC',
  FieldsStddevPopulationTopicAsc = 'FIELDS_STDDEV_POPULATION_TOPIC_ASC',
  FieldsStddevPopulationTopicDesc = 'FIELDS_STDDEV_POPULATION_TOPIC_DESC',
  FieldsStddevPopulationValueAsc = 'FIELDS_STDDEV_POPULATION_VALUE_ASC',
  FieldsStddevPopulationValueDesc = 'FIELDS_STDDEV_POPULATION_VALUE_DESC',
  FieldsStddevPopulationCreatedAtAsc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldsStddevPopulationCreatedAtDesc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldsStddevPopulationUpdatedAtAsc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldsStddevPopulationUpdatedAtDesc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldsVarianceSampleIdAsc = 'FIELDS_VARIANCE_SAMPLE_ID_ASC',
  FieldsVarianceSampleIdDesc = 'FIELDS_VARIANCE_SAMPLE_ID_DESC',
  FieldsVarianceSampleCompanyIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FieldsVarianceSampleCompanyIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FieldsVarianceSampleCompanyProductIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsVarianceSampleCompanyProductIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsVarianceSampleCompanyChannelIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsVarianceSampleCompanyChannelIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsVarianceSampleCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsVarianceSampleCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsVarianceSampleConfigAsc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_ASC',
  FieldsVarianceSampleConfigDesc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_DESC',
  FieldsVarianceSampleTopicAsc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_ASC',
  FieldsVarianceSampleTopicDesc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_DESC',
  FieldsVarianceSampleValueAsc = 'FIELDS_VARIANCE_SAMPLE_VALUE_ASC',
  FieldsVarianceSampleValueDesc = 'FIELDS_VARIANCE_SAMPLE_VALUE_DESC',
  FieldsVarianceSampleCreatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldsVarianceSampleCreatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldsVarianceSampleUpdatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldsVarianceSampleUpdatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldsVariancePopulationIdAsc = 'FIELDS_VARIANCE_POPULATION_ID_ASC',
  FieldsVariancePopulationIdDesc = 'FIELDS_VARIANCE_POPULATION_ID_DESC',
  FieldsVariancePopulationCompanyIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FieldsVariancePopulationCompanyIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FieldsVariancePopulationCompanyProductIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsVariancePopulationCompanyProductIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsVariancePopulationCompanyChannelIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsVariancePopulationCompanyChannelIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsVariancePopulationCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsVariancePopulationCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsVariancePopulationConfigAsc = 'FIELDS_VARIANCE_POPULATION_CONFIG_ASC',
  FieldsVariancePopulationConfigDesc = 'FIELDS_VARIANCE_POPULATION_CONFIG_DESC',
  FieldsVariancePopulationTopicAsc = 'FIELDS_VARIANCE_POPULATION_TOPIC_ASC',
  FieldsVariancePopulationTopicDesc = 'FIELDS_VARIANCE_POPULATION_TOPIC_DESC',
  FieldsVariancePopulationValueAsc = 'FIELDS_VARIANCE_POPULATION_VALUE_ASC',
  FieldsVariancePopulationValueDesc = 'FIELDS_VARIANCE_POPULATION_VALUE_DESC',
  FieldsVariancePopulationCreatedAtAsc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldsVariancePopulationCreatedAtDesc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldsVariancePopulationUpdatedAtAsc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldsVariancePopulationUpdatedAtDesc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  VehicleStocksCountAsc = 'VEHICLE_STOCKS_COUNT_ASC',
  VehicleStocksCountDesc = 'VEHICLE_STOCKS_COUNT_DESC',
  VehicleStocksSumIdAsc = 'VEHICLE_STOCKS_SUM_ID_ASC',
  VehicleStocksSumIdDesc = 'VEHICLE_STOCKS_SUM_ID_DESC',
  VehicleStocksSumCompanyIdAsc = 'VEHICLE_STOCKS_SUM_COMPANY_ID_ASC',
  VehicleStocksSumCompanyIdDesc = 'VEHICLE_STOCKS_SUM_COMPANY_ID_DESC',
  VehicleStocksSumCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_SUM_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksSumCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_SUM_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksSumProviderIdAsc = 'VEHICLE_STOCKS_SUM_PROVIDER_ID_ASC',
  VehicleStocksSumProviderIdDesc = 'VEHICLE_STOCKS_SUM_PROVIDER_ID_DESC',
  VehicleStocksSumVinAsc = 'VEHICLE_STOCKS_SUM_VIN_ASC',
  VehicleStocksSumVinDesc = 'VEHICLE_STOCKS_SUM_VIN_DESC',
  VehicleStocksSumStockNumberAsc = 'VEHICLE_STOCKS_SUM_STOCK_NUMBER_ASC',
  VehicleStocksSumStockNumberDesc = 'VEHICLE_STOCKS_SUM_STOCK_NUMBER_DESC',
  VehicleStocksSumYearAsc = 'VEHICLE_STOCKS_SUM_YEAR_ASC',
  VehicleStocksSumYearDesc = 'VEHICLE_STOCKS_SUM_YEAR_DESC',
  VehicleStocksSumMakeAsc = 'VEHICLE_STOCKS_SUM_MAKE_ASC',
  VehicleStocksSumMakeDesc = 'VEHICLE_STOCKS_SUM_MAKE_DESC',
  VehicleStocksSumModelAsc = 'VEHICLE_STOCKS_SUM_MODEL_ASC',
  VehicleStocksSumModelDesc = 'VEHICLE_STOCKS_SUM_MODEL_DESC',
  VehicleStocksSumTrimAsc = 'VEHICLE_STOCKS_SUM_TRIM_ASC',
  VehicleStocksSumTrimDesc = 'VEHICLE_STOCKS_SUM_TRIM_DESC',
  VehicleStocksSumBodyStyleAsc = 'VEHICLE_STOCKS_SUM_BODY_STYLE_ASC',
  VehicleStocksSumBodyStyleDesc = 'VEHICLE_STOCKS_SUM_BODY_STYLE_DESC',
  VehicleStocksSumExteriorColourAsc = 'VEHICLE_STOCKS_SUM_EXTERIOR_COLOUR_ASC',
  VehicleStocksSumExteriorColourDesc = 'VEHICLE_STOCKS_SUM_EXTERIOR_COLOUR_DESC',
  VehicleStocksSumInteriorColourAsc = 'VEHICLE_STOCKS_SUM_INTERIOR_COLOUR_ASC',
  VehicleStocksSumInteriorColourDesc = 'VEHICLE_STOCKS_SUM_INTERIOR_COLOUR_DESC',
  VehicleStocksSumMileageAsc = 'VEHICLE_STOCKS_SUM_MILEAGE_ASC',
  VehicleStocksSumMileageDesc = 'VEHICLE_STOCKS_SUM_MILEAGE_DESC',
  VehicleStocksSumDoorsAsc = 'VEHICLE_STOCKS_SUM_DOORS_ASC',
  VehicleStocksSumDoorsDesc = 'VEHICLE_STOCKS_SUM_DOORS_DESC',
  VehicleStocksSumSeatsAsc = 'VEHICLE_STOCKS_SUM_SEATS_ASC',
  VehicleStocksSumSeatsDesc = 'VEHICLE_STOCKS_SUM_SEATS_DESC',
  VehicleStocksSumEngineCapacityAsc = 'VEHICLE_STOCKS_SUM_ENGINE_CAPACITY_ASC',
  VehicleStocksSumEngineCapacityDesc = 'VEHICLE_STOCKS_SUM_ENGINE_CAPACITY_DESC',
  VehicleStocksSumTransmissionAsc = 'VEHICLE_STOCKS_SUM_TRANSMISSION_ASC',
  VehicleStocksSumTransmissionDesc = 'VEHICLE_STOCKS_SUM_TRANSMISSION_DESC',
  VehicleStocksSumCylindersAsc = 'VEHICLE_STOCKS_SUM_CYLINDERS_ASC',
  VehicleStocksSumCylindersDesc = 'VEHICLE_STOCKS_SUM_CYLINDERS_DESC',
  VehicleStocksSumDescriptionAsc = 'VEHICLE_STOCKS_SUM_DESCRIPTION_ASC',
  VehicleStocksSumDescriptionDesc = 'VEHICLE_STOCKS_SUM_DESCRIPTION_DESC',
  VehicleStocksSumWarrantyTextAsc = 'VEHICLE_STOCKS_SUM_WARRANTY_TEXT_ASC',
  VehicleStocksSumWarrantyTextDesc = 'VEHICLE_STOCKS_SUM_WARRANTY_TEXT_DESC',
  VehicleStocksSumCarProofAsc = 'VEHICLE_STOCKS_SUM_CAR_PROOF_ASC',
  VehicleStocksSumCarProofDesc = 'VEHICLE_STOCKS_SUM_CAR_PROOF_DESC',
  VehicleStocksSumIsNewAsc = 'VEHICLE_STOCKS_SUM_IS_NEW_ASC',
  VehicleStocksSumIsNewDesc = 'VEHICLE_STOCKS_SUM_IS_NEW_DESC',
  VehicleStocksSumCertifiedAsc = 'VEHICLE_STOCKS_SUM_CERTIFIED_ASC',
  VehicleStocksSumCertifiedDesc = 'VEHICLE_STOCKS_SUM_CERTIFIED_DESC',
  VehicleStocksSumSpecialAsc = 'VEHICLE_STOCKS_SUM_SPECIAL_ASC',
  VehicleStocksSumSpecialDesc = 'VEHICLE_STOCKS_SUM_SPECIAL_DESC',
  VehicleStocksSumDriveTrainAsc = 'VEHICLE_STOCKS_SUM_DRIVE_TRAIN_ASC',
  VehicleStocksSumDriveTrainDesc = 'VEHICLE_STOCKS_SUM_DRIVE_TRAIN_DESC',
  VehicleStocksSumFuelTypeAsc = 'VEHICLE_STOCKS_SUM_FUEL_TYPE_ASC',
  VehicleStocksSumFuelTypeDesc = 'VEHICLE_STOCKS_SUM_FUEL_TYPE_DESC',
  VehicleStocksSumMainImageAsc = 'VEHICLE_STOCKS_SUM_MAIN_IMAGE_ASC',
  VehicleStocksSumMainImageDesc = 'VEHICLE_STOCKS_SUM_MAIN_IMAGE_DESC',
  VehicleStocksSumImagesAsc = 'VEHICLE_STOCKS_SUM_IMAGES_ASC',
  VehicleStocksSumImagesDesc = 'VEHICLE_STOCKS_SUM_IMAGES_DESC',
  VehicleStocksSumVideosAsc = 'VEHICLE_STOCKS_SUM_VIDEOS_ASC',
  VehicleStocksSumVideosDesc = 'VEHICLE_STOCKS_SUM_VIDEOS_DESC',
  VehicleStocksSumFeaturesAsc = 'VEHICLE_STOCKS_SUM_FEATURES_ASC',
  VehicleStocksSumFeaturesDesc = 'VEHICLE_STOCKS_SUM_FEATURES_DESC',
  VehicleStocksSumStockStatusAsc = 'VEHICLE_STOCKS_SUM_STOCK_STATUS_ASC',
  VehicleStocksSumStockStatusDesc = 'VEHICLE_STOCKS_SUM_STOCK_STATUS_DESC',
  VehicleStocksSumPriceAsc = 'VEHICLE_STOCKS_SUM_PRICE_ASC',
  VehicleStocksSumPriceDesc = 'VEHICLE_STOCKS_SUM_PRICE_DESC',
  VehicleStocksSumCostAsc = 'VEHICLE_STOCKS_SUM_COST_ASC',
  VehicleStocksSumCostDesc = 'VEHICLE_STOCKS_SUM_COST_DESC',
  VehicleStocksSumEntryDateAsc = 'VEHICLE_STOCKS_SUM_ENTRY_DATE_ASC',
  VehicleStocksSumEntryDateDesc = 'VEHICLE_STOCKS_SUM_ENTRY_DATE_DESC',
  VehicleStocksSumLastChangeDateAsc = 'VEHICLE_STOCKS_SUM_LAST_CHANGE_DATE_ASC',
  VehicleStocksSumLastChangeDateDesc = 'VEHICLE_STOCKS_SUM_LAST_CHANGE_DATE_DESC',
  VehicleStocksSumCreatedAtAsc = 'VEHICLE_STOCKS_SUM_CREATED_AT_ASC',
  VehicleStocksSumCreatedAtDesc = 'VEHICLE_STOCKS_SUM_CREATED_AT_DESC',
  VehicleStocksSumUpdatedAtAsc = 'VEHICLE_STOCKS_SUM_UPDATED_AT_ASC',
  VehicleStocksSumUpdatedAtDesc = 'VEHICLE_STOCKS_SUM_UPDATED_AT_DESC',
  VehicleStocksSumSearchTextAsc = 'VEHICLE_STOCKS_SUM_SEARCH_TEXT_ASC',
  VehicleStocksSumSearchTextDesc = 'VEHICLE_STOCKS_SUM_SEARCH_TEXT_DESC',
  VehicleStocksDistinctCountIdAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ID_ASC',
  VehicleStocksDistinctCountIdDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ID_DESC',
  VehicleStocksDistinctCountCompanyIdAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COMPANY_ID_ASC',
  VehicleStocksDistinctCountCompanyIdDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COMPANY_ID_DESC',
  VehicleStocksDistinctCountCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksDistinctCountCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksDistinctCountProviderIdAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_PROVIDER_ID_ASC',
  VehicleStocksDistinctCountProviderIdDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_PROVIDER_ID_DESC',
  VehicleStocksDistinctCountVinAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_VIN_ASC',
  VehicleStocksDistinctCountVinDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_VIN_DESC',
  VehicleStocksDistinctCountStockNumberAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_STOCK_NUMBER_ASC',
  VehicleStocksDistinctCountStockNumberDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_STOCK_NUMBER_DESC',
  VehicleStocksDistinctCountYearAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_YEAR_ASC',
  VehicleStocksDistinctCountYearDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_YEAR_DESC',
  VehicleStocksDistinctCountMakeAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MAKE_ASC',
  VehicleStocksDistinctCountMakeDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MAKE_DESC',
  VehicleStocksDistinctCountModelAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MODEL_ASC',
  VehicleStocksDistinctCountModelDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MODEL_DESC',
  VehicleStocksDistinctCountTrimAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_TRIM_ASC',
  VehicleStocksDistinctCountTrimDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_TRIM_DESC',
  VehicleStocksDistinctCountBodyStyleAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_BODY_STYLE_ASC',
  VehicleStocksDistinctCountBodyStyleDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_BODY_STYLE_DESC',
  VehicleStocksDistinctCountExteriorColourAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_EXTERIOR_COLOUR_ASC',
  VehicleStocksDistinctCountExteriorColourDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_EXTERIOR_COLOUR_DESC',
  VehicleStocksDistinctCountInteriorColourAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_INTERIOR_COLOUR_ASC',
  VehicleStocksDistinctCountInteriorColourDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_INTERIOR_COLOUR_DESC',
  VehicleStocksDistinctCountMileageAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MILEAGE_ASC',
  VehicleStocksDistinctCountMileageDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MILEAGE_DESC',
  VehicleStocksDistinctCountDoorsAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DOORS_ASC',
  VehicleStocksDistinctCountDoorsDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DOORS_DESC',
  VehicleStocksDistinctCountSeatsAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SEATS_ASC',
  VehicleStocksDistinctCountSeatsDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SEATS_DESC',
  VehicleStocksDistinctCountEngineCapacityAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ENGINE_CAPACITY_ASC',
  VehicleStocksDistinctCountEngineCapacityDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ENGINE_CAPACITY_DESC',
  VehicleStocksDistinctCountTransmissionAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_TRANSMISSION_ASC',
  VehicleStocksDistinctCountTransmissionDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_TRANSMISSION_DESC',
  VehicleStocksDistinctCountCylindersAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CYLINDERS_ASC',
  VehicleStocksDistinctCountCylindersDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CYLINDERS_DESC',
  VehicleStocksDistinctCountDescriptionAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DESCRIPTION_ASC',
  VehicleStocksDistinctCountDescriptionDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DESCRIPTION_DESC',
  VehicleStocksDistinctCountWarrantyTextAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_WARRANTY_TEXT_ASC',
  VehicleStocksDistinctCountWarrantyTextDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_WARRANTY_TEXT_DESC',
  VehicleStocksDistinctCountCarProofAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CAR_PROOF_ASC',
  VehicleStocksDistinctCountCarProofDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CAR_PROOF_DESC',
  VehicleStocksDistinctCountIsNewAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_IS_NEW_ASC',
  VehicleStocksDistinctCountIsNewDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_IS_NEW_DESC',
  VehicleStocksDistinctCountCertifiedAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CERTIFIED_ASC',
  VehicleStocksDistinctCountCertifiedDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CERTIFIED_DESC',
  VehicleStocksDistinctCountSpecialAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SPECIAL_ASC',
  VehicleStocksDistinctCountSpecialDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SPECIAL_DESC',
  VehicleStocksDistinctCountDriveTrainAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DRIVE_TRAIN_ASC',
  VehicleStocksDistinctCountDriveTrainDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_DRIVE_TRAIN_DESC',
  VehicleStocksDistinctCountFuelTypeAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_FUEL_TYPE_ASC',
  VehicleStocksDistinctCountFuelTypeDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_FUEL_TYPE_DESC',
  VehicleStocksDistinctCountMainImageAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MAIN_IMAGE_ASC',
  VehicleStocksDistinctCountMainImageDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_MAIN_IMAGE_DESC',
  VehicleStocksDistinctCountImagesAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_IMAGES_ASC',
  VehicleStocksDistinctCountImagesDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_IMAGES_DESC',
  VehicleStocksDistinctCountVideosAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_VIDEOS_ASC',
  VehicleStocksDistinctCountVideosDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_VIDEOS_DESC',
  VehicleStocksDistinctCountFeaturesAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_FEATURES_ASC',
  VehicleStocksDistinctCountFeaturesDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_FEATURES_DESC',
  VehicleStocksDistinctCountStockStatusAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_STOCK_STATUS_ASC',
  VehicleStocksDistinctCountStockStatusDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_STOCK_STATUS_DESC',
  VehicleStocksDistinctCountPriceAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_PRICE_ASC',
  VehicleStocksDistinctCountPriceDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_PRICE_DESC',
  VehicleStocksDistinctCountCostAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COST_ASC',
  VehicleStocksDistinctCountCostDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_COST_DESC',
  VehicleStocksDistinctCountEntryDateAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ENTRY_DATE_ASC',
  VehicleStocksDistinctCountEntryDateDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_ENTRY_DATE_DESC',
  VehicleStocksDistinctCountLastChangeDateAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_LAST_CHANGE_DATE_ASC',
  VehicleStocksDistinctCountLastChangeDateDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_LAST_CHANGE_DATE_DESC',
  VehicleStocksDistinctCountCreatedAtAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CREATED_AT_ASC',
  VehicleStocksDistinctCountCreatedAtDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_CREATED_AT_DESC',
  VehicleStocksDistinctCountUpdatedAtAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_UPDATED_AT_ASC',
  VehicleStocksDistinctCountUpdatedAtDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_UPDATED_AT_DESC',
  VehicleStocksDistinctCountSearchTextAsc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  VehicleStocksDistinctCountSearchTextDesc = 'VEHICLE_STOCKS_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  VehicleStocksMinIdAsc = 'VEHICLE_STOCKS_MIN_ID_ASC',
  VehicleStocksMinIdDesc = 'VEHICLE_STOCKS_MIN_ID_DESC',
  VehicleStocksMinCompanyIdAsc = 'VEHICLE_STOCKS_MIN_COMPANY_ID_ASC',
  VehicleStocksMinCompanyIdDesc = 'VEHICLE_STOCKS_MIN_COMPANY_ID_DESC',
  VehicleStocksMinCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_MIN_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksMinCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_MIN_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksMinProviderIdAsc = 'VEHICLE_STOCKS_MIN_PROVIDER_ID_ASC',
  VehicleStocksMinProviderIdDesc = 'VEHICLE_STOCKS_MIN_PROVIDER_ID_DESC',
  VehicleStocksMinVinAsc = 'VEHICLE_STOCKS_MIN_VIN_ASC',
  VehicleStocksMinVinDesc = 'VEHICLE_STOCKS_MIN_VIN_DESC',
  VehicleStocksMinStockNumberAsc = 'VEHICLE_STOCKS_MIN_STOCK_NUMBER_ASC',
  VehicleStocksMinStockNumberDesc = 'VEHICLE_STOCKS_MIN_STOCK_NUMBER_DESC',
  VehicleStocksMinYearAsc = 'VEHICLE_STOCKS_MIN_YEAR_ASC',
  VehicleStocksMinYearDesc = 'VEHICLE_STOCKS_MIN_YEAR_DESC',
  VehicleStocksMinMakeAsc = 'VEHICLE_STOCKS_MIN_MAKE_ASC',
  VehicleStocksMinMakeDesc = 'VEHICLE_STOCKS_MIN_MAKE_DESC',
  VehicleStocksMinModelAsc = 'VEHICLE_STOCKS_MIN_MODEL_ASC',
  VehicleStocksMinModelDesc = 'VEHICLE_STOCKS_MIN_MODEL_DESC',
  VehicleStocksMinTrimAsc = 'VEHICLE_STOCKS_MIN_TRIM_ASC',
  VehicleStocksMinTrimDesc = 'VEHICLE_STOCKS_MIN_TRIM_DESC',
  VehicleStocksMinBodyStyleAsc = 'VEHICLE_STOCKS_MIN_BODY_STYLE_ASC',
  VehicleStocksMinBodyStyleDesc = 'VEHICLE_STOCKS_MIN_BODY_STYLE_DESC',
  VehicleStocksMinExteriorColourAsc = 'VEHICLE_STOCKS_MIN_EXTERIOR_COLOUR_ASC',
  VehicleStocksMinExteriorColourDesc = 'VEHICLE_STOCKS_MIN_EXTERIOR_COLOUR_DESC',
  VehicleStocksMinInteriorColourAsc = 'VEHICLE_STOCKS_MIN_INTERIOR_COLOUR_ASC',
  VehicleStocksMinInteriorColourDesc = 'VEHICLE_STOCKS_MIN_INTERIOR_COLOUR_DESC',
  VehicleStocksMinMileageAsc = 'VEHICLE_STOCKS_MIN_MILEAGE_ASC',
  VehicleStocksMinMileageDesc = 'VEHICLE_STOCKS_MIN_MILEAGE_DESC',
  VehicleStocksMinDoorsAsc = 'VEHICLE_STOCKS_MIN_DOORS_ASC',
  VehicleStocksMinDoorsDesc = 'VEHICLE_STOCKS_MIN_DOORS_DESC',
  VehicleStocksMinSeatsAsc = 'VEHICLE_STOCKS_MIN_SEATS_ASC',
  VehicleStocksMinSeatsDesc = 'VEHICLE_STOCKS_MIN_SEATS_DESC',
  VehicleStocksMinEngineCapacityAsc = 'VEHICLE_STOCKS_MIN_ENGINE_CAPACITY_ASC',
  VehicleStocksMinEngineCapacityDesc = 'VEHICLE_STOCKS_MIN_ENGINE_CAPACITY_DESC',
  VehicleStocksMinTransmissionAsc = 'VEHICLE_STOCKS_MIN_TRANSMISSION_ASC',
  VehicleStocksMinTransmissionDesc = 'VEHICLE_STOCKS_MIN_TRANSMISSION_DESC',
  VehicleStocksMinCylindersAsc = 'VEHICLE_STOCKS_MIN_CYLINDERS_ASC',
  VehicleStocksMinCylindersDesc = 'VEHICLE_STOCKS_MIN_CYLINDERS_DESC',
  VehicleStocksMinDescriptionAsc = 'VEHICLE_STOCKS_MIN_DESCRIPTION_ASC',
  VehicleStocksMinDescriptionDesc = 'VEHICLE_STOCKS_MIN_DESCRIPTION_DESC',
  VehicleStocksMinWarrantyTextAsc = 'VEHICLE_STOCKS_MIN_WARRANTY_TEXT_ASC',
  VehicleStocksMinWarrantyTextDesc = 'VEHICLE_STOCKS_MIN_WARRANTY_TEXT_DESC',
  VehicleStocksMinCarProofAsc = 'VEHICLE_STOCKS_MIN_CAR_PROOF_ASC',
  VehicleStocksMinCarProofDesc = 'VEHICLE_STOCKS_MIN_CAR_PROOF_DESC',
  VehicleStocksMinIsNewAsc = 'VEHICLE_STOCKS_MIN_IS_NEW_ASC',
  VehicleStocksMinIsNewDesc = 'VEHICLE_STOCKS_MIN_IS_NEW_DESC',
  VehicleStocksMinCertifiedAsc = 'VEHICLE_STOCKS_MIN_CERTIFIED_ASC',
  VehicleStocksMinCertifiedDesc = 'VEHICLE_STOCKS_MIN_CERTIFIED_DESC',
  VehicleStocksMinSpecialAsc = 'VEHICLE_STOCKS_MIN_SPECIAL_ASC',
  VehicleStocksMinSpecialDesc = 'VEHICLE_STOCKS_MIN_SPECIAL_DESC',
  VehicleStocksMinDriveTrainAsc = 'VEHICLE_STOCKS_MIN_DRIVE_TRAIN_ASC',
  VehicleStocksMinDriveTrainDesc = 'VEHICLE_STOCKS_MIN_DRIVE_TRAIN_DESC',
  VehicleStocksMinFuelTypeAsc = 'VEHICLE_STOCKS_MIN_FUEL_TYPE_ASC',
  VehicleStocksMinFuelTypeDesc = 'VEHICLE_STOCKS_MIN_FUEL_TYPE_DESC',
  VehicleStocksMinMainImageAsc = 'VEHICLE_STOCKS_MIN_MAIN_IMAGE_ASC',
  VehicleStocksMinMainImageDesc = 'VEHICLE_STOCKS_MIN_MAIN_IMAGE_DESC',
  VehicleStocksMinImagesAsc = 'VEHICLE_STOCKS_MIN_IMAGES_ASC',
  VehicleStocksMinImagesDesc = 'VEHICLE_STOCKS_MIN_IMAGES_DESC',
  VehicleStocksMinVideosAsc = 'VEHICLE_STOCKS_MIN_VIDEOS_ASC',
  VehicleStocksMinVideosDesc = 'VEHICLE_STOCKS_MIN_VIDEOS_DESC',
  VehicleStocksMinFeaturesAsc = 'VEHICLE_STOCKS_MIN_FEATURES_ASC',
  VehicleStocksMinFeaturesDesc = 'VEHICLE_STOCKS_MIN_FEATURES_DESC',
  VehicleStocksMinStockStatusAsc = 'VEHICLE_STOCKS_MIN_STOCK_STATUS_ASC',
  VehicleStocksMinStockStatusDesc = 'VEHICLE_STOCKS_MIN_STOCK_STATUS_DESC',
  VehicleStocksMinPriceAsc = 'VEHICLE_STOCKS_MIN_PRICE_ASC',
  VehicleStocksMinPriceDesc = 'VEHICLE_STOCKS_MIN_PRICE_DESC',
  VehicleStocksMinCostAsc = 'VEHICLE_STOCKS_MIN_COST_ASC',
  VehicleStocksMinCostDesc = 'VEHICLE_STOCKS_MIN_COST_DESC',
  VehicleStocksMinEntryDateAsc = 'VEHICLE_STOCKS_MIN_ENTRY_DATE_ASC',
  VehicleStocksMinEntryDateDesc = 'VEHICLE_STOCKS_MIN_ENTRY_DATE_DESC',
  VehicleStocksMinLastChangeDateAsc = 'VEHICLE_STOCKS_MIN_LAST_CHANGE_DATE_ASC',
  VehicleStocksMinLastChangeDateDesc = 'VEHICLE_STOCKS_MIN_LAST_CHANGE_DATE_DESC',
  VehicleStocksMinCreatedAtAsc = 'VEHICLE_STOCKS_MIN_CREATED_AT_ASC',
  VehicleStocksMinCreatedAtDesc = 'VEHICLE_STOCKS_MIN_CREATED_AT_DESC',
  VehicleStocksMinUpdatedAtAsc = 'VEHICLE_STOCKS_MIN_UPDATED_AT_ASC',
  VehicleStocksMinUpdatedAtDesc = 'VEHICLE_STOCKS_MIN_UPDATED_AT_DESC',
  VehicleStocksMinSearchTextAsc = 'VEHICLE_STOCKS_MIN_SEARCH_TEXT_ASC',
  VehicleStocksMinSearchTextDesc = 'VEHICLE_STOCKS_MIN_SEARCH_TEXT_DESC',
  VehicleStocksMaxIdAsc = 'VEHICLE_STOCKS_MAX_ID_ASC',
  VehicleStocksMaxIdDesc = 'VEHICLE_STOCKS_MAX_ID_DESC',
  VehicleStocksMaxCompanyIdAsc = 'VEHICLE_STOCKS_MAX_COMPANY_ID_ASC',
  VehicleStocksMaxCompanyIdDesc = 'VEHICLE_STOCKS_MAX_COMPANY_ID_DESC',
  VehicleStocksMaxCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_MAX_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksMaxCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_MAX_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksMaxProviderIdAsc = 'VEHICLE_STOCKS_MAX_PROVIDER_ID_ASC',
  VehicleStocksMaxProviderIdDesc = 'VEHICLE_STOCKS_MAX_PROVIDER_ID_DESC',
  VehicleStocksMaxVinAsc = 'VEHICLE_STOCKS_MAX_VIN_ASC',
  VehicleStocksMaxVinDesc = 'VEHICLE_STOCKS_MAX_VIN_DESC',
  VehicleStocksMaxStockNumberAsc = 'VEHICLE_STOCKS_MAX_STOCK_NUMBER_ASC',
  VehicleStocksMaxStockNumberDesc = 'VEHICLE_STOCKS_MAX_STOCK_NUMBER_DESC',
  VehicleStocksMaxYearAsc = 'VEHICLE_STOCKS_MAX_YEAR_ASC',
  VehicleStocksMaxYearDesc = 'VEHICLE_STOCKS_MAX_YEAR_DESC',
  VehicleStocksMaxMakeAsc = 'VEHICLE_STOCKS_MAX_MAKE_ASC',
  VehicleStocksMaxMakeDesc = 'VEHICLE_STOCKS_MAX_MAKE_DESC',
  VehicleStocksMaxModelAsc = 'VEHICLE_STOCKS_MAX_MODEL_ASC',
  VehicleStocksMaxModelDesc = 'VEHICLE_STOCKS_MAX_MODEL_DESC',
  VehicleStocksMaxTrimAsc = 'VEHICLE_STOCKS_MAX_TRIM_ASC',
  VehicleStocksMaxTrimDesc = 'VEHICLE_STOCKS_MAX_TRIM_DESC',
  VehicleStocksMaxBodyStyleAsc = 'VEHICLE_STOCKS_MAX_BODY_STYLE_ASC',
  VehicleStocksMaxBodyStyleDesc = 'VEHICLE_STOCKS_MAX_BODY_STYLE_DESC',
  VehicleStocksMaxExteriorColourAsc = 'VEHICLE_STOCKS_MAX_EXTERIOR_COLOUR_ASC',
  VehicleStocksMaxExteriorColourDesc = 'VEHICLE_STOCKS_MAX_EXTERIOR_COLOUR_DESC',
  VehicleStocksMaxInteriorColourAsc = 'VEHICLE_STOCKS_MAX_INTERIOR_COLOUR_ASC',
  VehicleStocksMaxInteriorColourDesc = 'VEHICLE_STOCKS_MAX_INTERIOR_COLOUR_DESC',
  VehicleStocksMaxMileageAsc = 'VEHICLE_STOCKS_MAX_MILEAGE_ASC',
  VehicleStocksMaxMileageDesc = 'VEHICLE_STOCKS_MAX_MILEAGE_DESC',
  VehicleStocksMaxDoorsAsc = 'VEHICLE_STOCKS_MAX_DOORS_ASC',
  VehicleStocksMaxDoorsDesc = 'VEHICLE_STOCKS_MAX_DOORS_DESC',
  VehicleStocksMaxSeatsAsc = 'VEHICLE_STOCKS_MAX_SEATS_ASC',
  VehicleStocksMaxSeatsDesc = 'VEHICLE_STOCKS_MAX_SEATS_DESC',
  VehicleStocksMaxEngineCapacityAsc = 'VEHICLE_STOCKS_MAX_ENGINE_CAPACITY_ASC',
  VehicleStocksMaxEngineCapacityDesc = 'VEHICLE_STOCKS_MAX_ENGINE_CAPACITY_DESC',
  VehicleStocksMaxTransmissionAsc = 'VEHICLE_STOCKS_MAX_TRANSMISSION_ASC',
  VehicleStocksMaxTransmissionDesc = 'VEHICLE_STOCKS_MAX_TRANSMISSION_DESC',
  VehicleStocksMaxCylindersAsc = 'VEHICLE_STOCKS_MAX_CYLINDERS_ASC',
  VehicleStocksMaxCylindersDesc = 'VEHICLE_STOCKS_MAX_CYLINDERS_DESC',
  VehicleStocksMaxDescriptionAsc = 'VEHICLE_STOCKS_MAX_DESCRIPTION_ASC',
  VehicleStocksMaxDescriptionDesc = 'VEHICLE_STOCKS_MAX_DESCRIPTION_DESC',
  VehicleStocksMaxWarrantyTextAsc = 'VEHICLE_STOCKS_MAX_WARRANTY_TEXT_ASC',
  VehicleStocksMaxWarrantyTextDesc = 'VEHICLE_STOCKS_MAX_WARRANTY_TEXT_DESC',
  VehicleStocksMaxCarProofAsc = 'VEHICLE_STOCKS_MAX_CAR_PROOF_ASC',
  VehicleStocksMaxCarProofDesc = 'VEHICLE_STOCKS_MAX_CAR_PROOF_DESC',
  VehicleStocksMaxIsNewAsc = 'VEHICLE_STOCKS_MAX_IS_NEW_ASC',
  VehicleStocksMaxIsNewDesc = 'VEHICLE_STOCKS_MAX_IS_NEW_DESC',
  VehicleStocksMaxCertifiedAsc = 'VEHICLE_STOCKS_MAX_CERTIFIED_ASC',
  VehicleStocksMaxCertifiedDesc = 'VEHICLE_STOCKS_MAX_CERTIFIED_DESC',
  VehicleStocksMaxSpecialAsc = 'VEHICLE_STOCKS_MAX_SPECIAL_ASC',
  VehicleStocksMaxSpecialDesc = 'VEHICLE_STOCKS_MAX_SPECIAL_DESC',
  VehicleStocksMaxDriveTrainAsc = 'VEHICLE_STOCKS_MAX_DRIVE_TRAIN_ASC',
  VehicleStocksMaxDriveTrainDesc = 'VEHICLE_STOCKS_MAX_DRIVE_TRAIN_DESC',
  VehicleStocksMaxFuelTypeAsc = 'VEHICLE_STOCKS_MAX_FUEL_TYPE_ASC',
  VehicleStocksMaxFuelTypeDesc = 'VEHICLE_STOCKS_MAX_FUEL_TYPE_DESC',
  VehicleStocksMaxMainImageAsc = 'VEHICLE_STOCKS_MAX_MAIN_IMAGE_ASC',
  VehicleStocksMaxMainImageDesc = 'VEHICLE_STOCKS_MAX_MAIN_IMAGE_DESC',
  VehicleStocksMaxImagesAsc = 'VEHICLE_STOCKS_MAX_IMAGES_ASC',
  VehicleStocksMaxImagesDesc = 'VEHICLE_STOCKS_MAX_IMAGES_DESC',
  VehicleStocksMaxVideosAsc = 'VEHICLE_STOCKS_MAX_VIDEOS_ASC',
  VehicleStocksMaxVideosDesc = 'VEHICLE_STOCKS_MAX_VIDEOS_DESC',
  VehicleStocksMaxFeaturesAsc = 'VEHICLE_STOCKS_MAX_FEATURES_ASC',
  VehicleStocksMaxFeaturesDesc = 'VEHICLE_STOCKS_MAX_FEATURES_DESC',
  VehicleStocksMaxStockStatusAsc = 'VEHICLE_STOCKS_MAX_STOCK_STATUS_ASC',
  VehicleStocksMaxStockStatusDesc = 'VEHICLE_STOCKS_MAX_STOCK_STATUS_DESC',
  VehicleStocksMaxPriceAsc = 'VEHICLE_STOCKS_MAX_PRICE_ASC',
  VehicleStocksMaxPriceDesc = 'VEHICLE_STOCKS_MAX_PRICE_DESC',
  VehicleStocksMaxCostAsc = 'VEHICLE_STOCKS_MAX_COST_ASC',
  VehicleStocksMaxCostDesc = 'VEHICLE_STOCKS_MAX_COST_DESC',
  VehicleStocksMaxEntryDateAsc = 'VEHICLE_STOCKS_MAX_ENTRY_DATE_ASC',
  VehicleStocksMaxEntryDateDesc = 'VEHICLE_STOCKS_MAX_ENTRY_DATE_DESC',
  VehicleStocksMaxLastChangeDateAsc = 'VEHICLE_STOCKS_MAX_LAST_CHANGE_DATE_ASC',
  VehicleStocksMaxLastChangeDateDesc = 'VEHICLE_STOCKS_MAX_LAST_CHANGE_DATE_DESC',
  VehicleStocksMaxCreatedAtAsc = 'VEHICLE_STOCKS_MAX_CREATED_AT_ASC',
  VehicleStocksMaxCreatedAtDesc = 'VEHICLE_STOCKS_MAX_CREATED_AT_DESC',
  VehicleStocksMaxUpdatedAtAsc = 'VEHICLE_STOCKS_MAX_UPDATED_AT_ASC',
  VehicleStocksMaxUpdatedAtDesc = 'VEHICLE_STOCKS_MAX_UPDATED_AT_DESC',
  VehicleStocksMaxSearchTextAsc = 'VEHICLE_STOCKS_MAX_SEARCH_TEXT_ASC',
  VehicleStocksMaxSearchTextDesc = 'VEHICLE_STOCKS_MAX_SEARCH_TEXT_DESC',
  VehicleStocksAverageIdAsc = 'VEHICLE_STOCKS_AVERAGE_ID_ASC',
  VehicleStocksAverageIdDesc = 'VEHICLE_STOCKS_AVERAGE_ID_DESC',
  VehicleStocksAverageCompanyIdAsc = 'VEHICLE_STOCKS_AVERAGE_COMPANY_ID_ASC',
  VehicleStocksAverageCompanyIdDesc = 'VEHICLE_STOCKS_AVERAGE_COMPANY_ID_DESC',
  VehicleStocksAverageCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksAverageCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksAverageProviderIdAsc = 'VEHICLE_STOCKS_AVERAGE_PROVIDER_ID_ASC',
  VehicleStocksAverageProviderIdDesc = 'VEHICLE_STOCKS_AVERAGE_PROVIDER_ID_DESC',
  VehicleStocksAverageVinAsc = 'VEHICLE_STOCKS_AVERAGE_VIN_ASC',
  VehicleStocksAverageVinDesc = 'VEHICLE_STOCKS_AVERAGE_VIN_DESC',
  VehicleStocksAverageStockNumberAsc = 'VEHICLE_STOCKS_AVERAGE_STOCK_NUMBER_ASC',
  VehicleStocksAverageStockNumberDesc = 'VEHICLE_STOCKS_AVERAGE_STOCK_NUMBER_DESC',
  VehicleStocksAverageYearAsc = 'VEHICLE_STOCKS_AVERAGE_YEAR_ASC',
  VehicleStocksAverageYearDesc = 'VEHICLE_STOCKS_AVERAGE_YEAR_DESC',
  VehicleStocksAverageMakeAsc = 'VEHICLE_STOCKS_AVERAGE_MAKE_ASC',
  VehicleStocksAverageMakeDesc = 'VEHICLE_STOCKS_AVERAGE_MAKE_DESC',
  VehicleStocksAverageModelAsc = 'VEHICLE_STOCKS_AVERAGE_MODEL_ASC',
  VehicleStocksAverageModelDesc = 'VEHICLE_STOCKS_AVERAGE_MODEL_DESC',
  VehicleStocksAverageTrimAsc = 'VEHICLE_STOCKS_AVERAGE_TRIM_ASC',
  VehicleStocksAverageTrimDesc = 'VEHICLE_STOCKS_AVERAGE_TRIM_DESC',
  VehicleStocksAverageBodyStyleAsc = 'VEHICLE_STOCKS_AVERAGE_BODY_STYLE_ASC',
  VehicleStocksAverageBodyStyleDesc = 'VEHICLE_STOCKS_AVERAGE_BODY_STYLE_DESC',
  VehicleStocksAverageExteriorColourAsc = 'VEHICLE_STOCKS_AVERAGE_EXTERIOR_COLOUR_ASC',
  VehicleStocksAverageExteriorColourDesc = 'VEHICLE_STOCKS_AVERAGE_EXTERIOR_COLOUR_DESC',
  VehicleStocksAverageInteriorColourAsc = 'VEHICLE_STOCKS_AVERAGE_INTERIOR_COLOUR_ASC',
  VehicleStocksAverageInteriorColourDesc = 'VEHICLE_STOCKS_AVERAGE_INTERIOR_COLOUR_DESC',
  VehicleStocksAverageMileageAsc = 'VEHICLE_STOCKS_AVERAGE_MILEAGE_ASC',
  VehicleStocksAverageMileageDesc = 'VEHICLE_STOCKS_AVERAGE_MILEAGE_DESC',
  VehicleStocksAverageDoorsAsc = 'VEHICLE_STOCKS_AVERAGE_DOORS_ASC',
  VehicleStocksAverageDoorsDesc = 'VEHICLE_STOCKS_AVERAGE_DOORS_DESC',
  VehicleStocksAverageSeatsAsc = 'VEHICLE_STOCKS_AVERAGE_SEATS_ASC',
  VehicleStocksAverageSeatsDesc = 'VEHICLE_STOCKS_AVERAGE_SEATS_DESC',
  VehicleStocksAverageEngineCapacityAsc = 'VEHICLE_STOCKS_AVERAGE_ENGINE_CAPACITY_ASC',
  VehicleStocksAverageEngineCapacityDesc = 'VEHICLE_STOCKS_AVERAGE_ENGINE_CAPACITY_DESC',
  VehicleStocksAverageTransmissionAsc = 'VEHICLE_STOCKS_AVERAGE_TRANSMISSION_ASC',
  VehicleStocksAverageTransmissionDesc = 'VEHICLE_STOCKS_AVERAGE_TRANSMISSION_DESC',
  VehicleStocksAverageCylindersAsc = 'VEHICLE_STOCKS_AVERAGE_CYLINDERS_ASC',
  VehicleStocksAverageCylindersDesc = 'VEHICLE_STOCKS_AVERAGE_CYLINDERS_DESC',
  VehicleStocksAverageDescriptionAsc = 'VEHICLE_STOCKS_AVERAGE_DESCRIPTION_ASC',
  VehicleStocksAverageDescriptionDesc = 'VEHICLE_STOCKS_AVERAGE_DESCRIPTION_DESC',
  VehicleStocksAverageWarrantyTextAsc = 'VEHICLE_STOCKS_AVERAGE_WARRANTY_TEXT_ASC',
  VehicleStocksAverageWarrantyTextDesc = 'VEHICLE_STOCKS_AVERAGE_WARRANTY_TEXT_DESC',
  VehicleStocksAverageCarProofAsc = 'VEHICLE_STOCKS_AVERAGE_CAR_PROOF_ASC',
  VehicleStocksAverageCarProofDesc = 'VEHICLE_STOCKS_AVERAGE_CAR_PROOF_DESC',
  VehicleStocksAverageIsNewAsc = 'VEHICLE_STOCKS_AVERAGE_IS_NEW_ASC',
  VehicleStocksAverageIsNewDesc = 'VEHICLE_STOCKS_AVERAGE_IS_NEW_DESC',
  VehicleStocksAverageCertifiedAsc = 'VEHICLE_STOCKS_AVERAGE_CERTIFIED_ASC',
  VehicleStocksAverageCertifiedDesc = 'VEHICLE_STOCKS_AVERAGE_CERTIFIED_DESC',
  VehicleStocksAverageSpecialAsc = 'VEHICLE_STOCKS_AVERAGE_SPECIAL_ASC',
  VehicleStocksAverageSpecialDesc = 'VEHICLE_STOCKS_AVERAGE_SPECIAL_DESC',
  VehicleStocksAverageDriveTrainAsc = 'VEHICLE_STOCKS_AVERAGE_DRIVE_TRAIN_ASC',
  VehicleStocksAverageDriveTrainDesc = 'VEHICLE_STOCKS_AVERAGE_DRIVE_TRAIN_DESC',
  VehicleStocksAverageFuelTypeAsc = 'VEHICLE_STOCKS_AVERAGE_FUEL_TYPE_ASC',
  VehicleStocksAverageFuelTypeDesc = 'VEHICLE_STOCKS_AVERAGE_FUEL_TYPE_DESC',
  VehicleStocksAverageMainImageAsc = 'VEHICLE_STOCKS_AVERAGE_MAIN_IMAGE_ASC',
  VehicleStocksAverageMainImageDesc = 'VEHICLE_STOCKS_AVERAGE_MAIN_IMAGE_DESC',
  VehicleStocksAverageImagesAsc = 'VEHICLE_STOCKS_AVERAGE_IMAGES_ASC',
  VehicleStocksAverageImagesDesc = 'VEHICLE_STOCKS_AVERAGE_IMAGES_DESC',
  VehicleStocksAverageVideosAsc = 'VEHICLE_STOCKS_AVERAGE_VIDEOS_ASC',
  VehicleStocksAverageVideosDesc = 'VEHICLE_STOCKS_AVERAGE_VIDEOS_DESC',
  VehicleStocksAverageFeaturesAsc = 'VEHICLE_STOCKS_AVERAGE_FEATURES_ASC',
  VehicleStocksAverageFeaturesDesc = 'VEHICLE_STOCKS_AVERAGE_FEATURES_DESC',
  VehicleStocksAverageStockStatusAsc = 'VEHICLE_STOCKS_AVERAGE_STOCK_STATUS_ASC',
  VehicleStocksAverageStockStatusDesc = 'VEHICLE_STOCKS_AVERAGE_STOCK_STATUS_DESC',
  VehicleStocksAveragePriceAsc = 'VEHICLE_STOCKS_AVERAGE_PRICE_ASC',
  VehicleStocksAveragePriceDesc = 'VEHICLE_STOCKS_AVERAGE_PRICE_DESC',
  VehicleStocksAverageCostAsc = 'VEHICLE_STOCKS_AVERAGE_COST_ASC',
  VehicleStocksAverageCostDesc = 'VEHICLE_STOCKS_AVERAGE_COST_DESC',
  VehicleStocksAverageEntryDateAsc = 'VEHICLE_STOCKS_AVERAGE_ENTRY_DATE_ASC',
  VehicleStocksAverageEntryDateDesc = 'VEHICLE_STOCKS_AVERAGE_ENTRY_DATE_DESC',
  VehicleStocksAverageLastChangeDateAsc = 'VEHICLE_STOCKS_AVERAGE_LAST_CHANGE_DATE_ASC',
  VehicleStocksAverageLastChangeDateDesc = 'VEHICLE_STOCKS_AVERAGE_LAST_CHANGE_DATE_DESC',
  VehicleStocksAverageCreatedAtAsc = 'VEHICLE_STOCKS_AVERAGE_CREATED_AT_ASC',
  VehicleStocksAverageCreatedAtDesc = 'VEHICLE_STOCKS_AVERAGE_CREATED_AT_DESC',
  VehicleStocksAverageUpdatedAtAsc = 'VEHICLE_STOCKS_AVERAGE_UPDATED_AT_ASC',
  VehicleStocksAverageUpdatedAtDesc = 'VEHICLE_STOCKS_AVERAGE_UPDATED_AT_DESC',
  VehicleStocksAverageSearchTextAsc = 'VEHICLE_STOCKS_AVERAGE_SEARCH_TEXT_ASC',
  VehicleStocksAverageSearchTextDesc = 'VEHICLE_STOCKS_AVERAGE_SEARCH_TEXT_DESC',
  VehicleStocksStddevSampleIdAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ID_ASC',
  VehicleStocksStddevSampleIdDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ID_DESC',
  VehicleStocksStddevSampleCompanyIdAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  VehicleStocksStddevSampleCompanyIdDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  VehicleStocksStddevSampleCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksStddevSampleCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksStddevSampleProviderIdAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_PROVIDER_ID_ASC',
  VehicleStocksStddevSampleProviderIdDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_PROVIDER_ID_DESC',
  VehicleStocksStddevSampleVinAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_VIN_ASC',
  VehicleStocksStddevSampleVinDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_VIN_DESC',
  VehicleStocksStddevSampleStockNumberAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_STOCK_NUMBER_ASC',
  VehicleStocksStddevSampleStockNumberDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_STOCK_NUMBER_DESC',
  VehicleStocksStddevSampleYearAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_YEAR_ASC',
  VehicleStocksStddevSampleYearDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_YEAR_DESC',
  VehicleStocksStddevSampleMakeAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MAKE_ASC',
  VehicleStocksStddevSampleMakeDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MAKE_DESC',
  VehicleStocksStddevSampleModelAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MODEL_ASC',
  VehicleStocksStddevSampleModelDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MODEL_DESC',
  VehicleStocksStddevSampleTrimAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_TRIM_ASC',
  VehicleStocksStddevSampleTrimDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_TRIM_DESC',
  VehicleStocksStddevSampleBodyStyleAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_BODY_STYLE_ASC',
  VehicleStocksStddevSampleBodyStyleDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_BODY_STYLE_DESC',
  VehicleStocksStddevSampleExteriorColourAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_EXTERIOR_COLOUR_ASC',
  VehicleStocksStddevSampleExteriorColourDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_EXTERIOR_COLOUR_DESC',
  VehicleStocksStddevSampleInteriorColourAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_INTERIOR_COLOUR_ASC',
  VehicleStocksStddevSampleInteriorColourDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_INTERIOR_COLOUR_DESC',
  VehicleStocksStddevSampleMileageAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MILEAGE_ASC',
  VehicleStocksStddevSampleMileageDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MILEAGE_DESC',
  VehicleStocksStddevSampleDoorsAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DOORS_ASC',
  VehicleStocksStddevSampleDoorsDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DOORS_DESC',
  VehicleStocksStddevSampleSeatsAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SEATS_ASC',
  VehicleStocksStddevSampleSeatsDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SEATS_DESC',
  VehicleStocksStddevSampleEngineCapacityAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ENGINE_CAPACITY_ASC',
  VehicleStocksStddevSampleEngineCapacityDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ENGINE_CAPACITY_DESC',
  VehicleStocksStddevSampleTransmissionAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_TRANSMISSION_ASC',
  VehicleStocksStddevSampleTransmissionDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_TRANSMISSION_DESC',
  VehicleStocksStddevSampleCylindersAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CYLINDERS_ASC',
  VehicleStocksStddevSampleCylindersDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CYLINDERS_DESC',
  VehicleStocksStddevSampleDescriptionAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DESCRIPTION_ASC',
  VehicleStocksStddevSampleDescriptionDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DESCRIPTION_DESC',
  VehicleStocksStddevSampleWarrantyTextAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_WARRANTY_TEXT_ASC',
  VehicleStocksStddevSampleWarrantyTextDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_WARRANTY_TEXT_DESC',
  VehicleStocksStddevSampleCarProofAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CAR_PROOF_ASC',
  VehicleStocksStddevSampleCarProofDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CAR_PROOF_DESC',
  VehicleStocksStddevSampleIsNewAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_IS_NEW_ASC',
  VehicleStocksStddevSampleIsNewDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_IS_NEW_DESC',
  VehicleStocksStddevSampleCertifiedAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CERTIFIED_ASC',
  VehicleStocksStddevSampleCertifiedDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CERTIFIED_DESC',
  VehicleStocksStddevSampleSpecialAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SPECIAL_ASC',
  VehicleStocksStddevSampleSpecialDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SPECIAL_DESC',
  VehicleStocksStddevSampleDriveTrainAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DRIVE_TRAIN_ASC',
  VehicleStocksStddevSampleDriveTrainDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_DRIVE_TRAIN_DESC',
  VehicleStocksStddevSampleFuelTypeAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_FUEL_TYPE_ASC',
  VehicleStocksStddevSampleFuelTypeDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_FUEL_TYPE_DESC',
  VehicleStocksStddevSampleMainImageAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MAIN_IMAGE_ASC',
  VehicleStocksStddevSampleMainImageDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_MAIN_IMAGE_DESC',
  VehicleStocksStddevSampleImagesAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_IMAGES_ASC',
  VehicleStocksStddevSampleImagesDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_IMAGES_DESC',
  VehicleStocksStddevSampleVideosAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_VIDEOS_ASC',
  VehicleStocksStddevSampleVideosDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_VIDEOS_DESC',
  VehicleStocksStddevSampleFeaturesAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_FEATURES_ASC',
  VehicleStocksStddevSampleFeaturesDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_FEATURES_DESC',
  VehicleStocksStddevSampleStockStatusAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_STOCK_STATUS_ASC',
  VehicleStocksStddevSampleStockStatusDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_STOCK_STATUS_DESC',
  VehicleStocksStddevSamplePriceAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_PRICE_ASC',
  VehicleStocksStddevSamplePriceDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_PRICE_DESC',
  VehicleStocksStddevSampleCostAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COST_ASC',
  VehicleStocksStddevSampleCostDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_COST_DESC',
  VehicleStocksStddevSampleEntryDateAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ENTRY_DATE_ASC',
  VehicleStocksStddevSampleEntryDateDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_ENTRY_DATE_DESC',
  VehicleStocksStddevSampleLastChangeDateAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_LAST_CHANGE_DATE_ASC',
  VehicleStocksStddevSampleLastChangeDateDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_LAST_CHANGE_DATE_DESC',
  VehicleStocksStddevSampleCreatedAtAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CREATED_AT_ASC',
  VehicleStocksStddevSampleCreatedAtDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_CREATED_AT_DESC',
  VehicleStocksStddevSampleUpdatedAtAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  VehicleStocksStddevSampleUpdatedAtDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  VehicleStocksStddevSampleSearchTextAsc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  VehicleStocksStddevSampleSearchTextDesc = 'VEHICLE_STOCKS_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  VehicleStocksStddevPopulationIdAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ID_ASC',
  VehicleStocksStddevPopulationIdDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ID_DESC',
  VehicleStocksStddevPopulationCompanyIdAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COMPANY_ID_ASC',
  VehicleStocksStddevPopulationCompanyIdDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COMPANY_ID_DESC',
  VehicleStocksStddevPopulationCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksStddevPopulationCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksStddevPopulationProviderIdAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_PROVIDER_ID_ASC',
  VehicleStocksStddevPopulationProviderIdDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_PROVIDER_ID_DESC',
  VehicleStocksStddevPopulationVinAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_VIN_ASC',
  VehicleStocksStddevPopulationVinDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_VIN_DESC',
  VehicleStocksStddevPopulationStockNumberAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_STOCK_NUMBER_ASC',
  VehicleStocksStddevPopulationStockNumberDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_STOCK_NUMBER_DESC',
  VehicleStocksStddevPopulationYearAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_YEAR_ASC',
  VehicleStocksStddevPopulationYearDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_YEAR_DESC',
  VehicleStocksStddevPopulationMakeAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MAKE_ASC',
  VehicleStocksStddevPopulationMakeDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MAKE_DESC',
  VehicleStocksStddevPopulationModelAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MODEL_ASC',
  VehicleStocksStddevPopulationModelDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MODEL_DESC',
  VehicleStocksStddevPopulationTrimAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_TRIM_ASC',
  VehicleStocksStddevPopulationTrimDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_TRIM_DESC',
  VehicleStocksStddevPopulationBodyStyleAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_BODY_STYLE_ASC',
  VehicleStocksStddevPopulationBodyStyleDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_BODY_STYLE_DESC',
  VehicleStocksStddevPopulationExteriorColourAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_EXTERIOR_COLOUR_ASC',
  VehicleStocksStddevPopulationExteriorColourDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_EXTERIOR_COLOUR_DESC',
  VehicleStocksStddevPopulationInteriorColourAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_INTERIOR_COLOUR_ASC',
  VehicleStocksStddevPopulationInteriorColourDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_INTERIOR_COLOUR_DESC',
  VehicleStocksStddevPopulationMileageAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MILEAGE_ASC',
  VehicleStocksStddevPopulationMileageDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MILEAGE_DESC',
  VehicleStocksStddevPopulationDoorsAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DOORS_ASC',
  VehicleStocksStddevPopulationDoorsDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DOORS_DESC',
  VehicleStocksStddevPopulationSeatsAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SEATS_ASC',
  VehicleStocksStddevPopulationSeatsDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SEATS_DESC',
  VehicleStocksStddevPopulationEngineCapacityAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ENGINE_CAPACITY_ASC',
  VehicleStocksStddevPopulationEngineCapacityDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ENGINE_CAPACITY_DESC',
  VehicleStocksStddevPopulationTransmissionAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_TRANSMISSION_ASC',
  VehicleStocksStddevPopulationTransmissionDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_TRANSMISSION_DESC',
  VehicleStocksStddevPopulationCylindersAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CYLINDERS_ASC',
  VehicleStocksStddevPopulationCylindersDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CYLINDERS_DESC',
  VehicleStocksStddevPopulationDescriptionAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DESCRIPTION_ASC',
  VehicleStocksStddevPopulationDescriptionDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DESCRIPTION_DESC',
  VehicleStocksStddevPopulationWarrantyTextAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_WARRANTY_TEXT_ASC',
  VehicleStocksStddevPopulationWarrantyTextDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_WARRANTY_TEXT_DESC',
  VehicleStocksStddevPopulationCarProofAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CAR_PROOF_ASC',
  VehicleStocksStddevPopulationCarProofDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CAR_PROOF_DESC',
  VehicleStocksStddevPopulationIsNewAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_IS_NEW_ASC',
  VehicleStocksStddevPopulationIsNewDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_IS_NEW_DESC',
  VehicleStocksStddevPopulationCertifiedAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CERTIFIED_ASC',
  VehicleStocksStddevPopulationCertifiedDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CERTIFIED_DESC',
  VehicleStocksStddevPopulationSpecialAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SPECIAL_ASC',
  VehicleStocksStddevPopulationSpecialDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SPECIAL_DESC',
  VehicleStocksStddevPopulationDriveTrainAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DRIVE_TRAIN_ASC',
  VehicleStocksStddevPopulationDriveTrainDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_DRIVE_TRAIN_DESC',
  VehicleStocksStddevPopulationFuelTypeAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_FUEL_TYPE_ASC',
  VehicleStocksStddevPopulationFuelTypeDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_FUEL_TYPE_DESC',
  VehicleStocksStddevPopulationMainImageAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MAIN_IMAGE_ASC',
  VehicleStocksStddevPopulationMainImageDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_MAIN_IMAGE_DESC',
  VehicleStocksStddevPopulationImagesAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_IMAGES_ASC',
  VehicleStocksStddevPopulationImagesDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_IMAGES_DESC',
  VehicleStocksStddevPopulationVideosAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_VIDEOS_ASC',
  VehicleStocksStddevPopulationVideosDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_VIDEOS_DESC',
  VehicleStocksStddevPopulationFeaturesAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_FEATURES_ASC',
  VehicleStocksStddevPopulationFeaturesDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_FEATURES_DESC',
  VehicleStocksStddevPopulationStockStatusAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_STOCK_STATUS_ASC',
  VehicleStocksStddevPopulationStockStatusDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_STOCK_STATUS_DESC',
  VehicleStocksStddevPopulationPriceAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_PRICE_ASC',
  VehicleStocksStddevPopulationPriceDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_PRICE_DESC',
  VehicleStocksStddevPopulationCostAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COST_ASC',
  VehicleStocksStddevPopulationCostDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_COST_DESC',
  VehicleStocksStddevPopulationEntryDateAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ENTRY_DATE_ASC',
  VehicleStocksStddevPopulationEntryDateDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_ENTRY_DATE_DESC',
  VehicleStocksStddevPopulationLastChangeDateAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_LAST_CHANGE_DATE_ASC',
  VehicleStocksStddevPopulationLastChangeDateDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_LAST_CHANGE_DATE_DESC',
  VehicleStocksStddevPopulationCreatedAtAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CREATED_AT_ASC',
  VehicleStocksStddevPopulationCreatedAtDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_CREATED_AT_DESC',
  VehicleStocksStddevPopulationUpdatedAtAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_UPDATED_AT_ASC',
  VehicleStocksStddevPopulationUpdatedAtDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_UPDATED_AT_DESC',
  VehicleStocksStddevPopulationSearchTextAsc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  VehicleStocksStddevPopulationSearchTextDesc = 'VEHICLE_STOCKS_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  VehicleStocksVarianceSampleIdAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ID_ASC',
  VehicleStocksVarianceSampleIdDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ID_DESC',
  VehicleStocksVarianceSampleCompanyIdAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  VehicleStocksVarianceSampleCompanyIdDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  VehicleStocksVarianceSampleCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksVarianceSampleCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksVarianceSampleProviderIdAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_PROVIDER_ID_ASC',
  VehicleStocksVarianceSampleProviderIdDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_PROVIDER_ID_DESC',
  VehicleStocksVarianceSampleVinAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_VIN_ASC',
  VehicleStocksVarianceSampleVinDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_VIN_DESC',
  VehicleStocksVarianceSampleStockNumberAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_STOCK_NUMBER_ASC',
  VehicleStocksVarianceSampleStockNumberDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_STOCK_NUMBER_DESC',
  VehicleStocksVarianceSampleYearAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_YEAR_ASC',
  VehicleStocksVarianceSampleYearDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_YEAR_DESC',
  VehicleStocksVarianceSampleMakeAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MAKE_ASC',
  VehicleStocksVarianceSampleMakeDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MAKE_DESC',
  VehicleStocksVarianceSampleModelAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MODEL_ASC',
  VehicleStocksVarianceSampleModelDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MODEL_DESC',
  VehicleStocksVarianceSampleTrimAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_TRIM_ASC',
  VehicleStocksVarianceSampleTrimDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_TRIM_DESC',
  VehicleStocksVarianceSampleBodyStyleAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_BODY_STYLE_ASC',
  VehicleStocksVarianceSampleBodyStyleDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_BODY_STYLE_DESC',
  VehicleStocksVarianceSampleExteriorColourAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_EXTERIOR_COLOUR_ASC',
  VehicleStocksVarianceSampleExteriorColourDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_EXTERIOR_COLOUR_DESC',
  VehicleStocksVarianceSampleInteriorColourAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_INTERIOR_COLOUR_ASC',
  VehicleStocksVarianceSampleInteriorColourDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_INTERIOR_COLOUR_DESC',
  VehicleStocksVarianceSampleMileageAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MILEAGE_ASC',
  VehicleStocksVarianceSampleMileageDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MILEAGE_DESC',
  VehicleStocksVarianceSampleDoorsAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DOORS_ASC',
  VehicleStocksVarianceSampleDoorsDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DOORS_DESC',
  VehicleStocksVarianceSampleSeatsAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SEATS_ASC',
  VehicleStocksVarianceSampleSeatsDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SEATS_DESC',
  VehicleStocksVarianceSampleEngineCapacityAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ENGINE_CAPACITY_ASC',
  VehicleStocksVarianceSampleEngineCapacityDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ENGINE_CAPACITY_DESC',
  VehicleStocksVarianceSampleTransmissionAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_TRANSMISSION_ASC',
  VehicleStocksVarianceSampleTransmissionDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_TRANSMISSION_DESC',
  VehicleStocksVarianceSampleCylindersAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CYLINDERS_ASC',
  VehicleStocksVarianceSampleCylindersDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CYLINDERS_DESC',
  VehicleStocksVarianceSampleDescriptionAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  VehicleStocksVarianceSampleDescriptionDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  VehicleStocksVarianceSampleWarrantyTextAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_WARRANTY_TEXT_ASC',
  VehicleStocksVarianceSampleWarrantyTextDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_WARRANTY_TEXT_DESC',
  VehicleStocksVarianceSampleCarProofAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CAR_PROOF_ASC',
  VehicleStocksVarianceSampleCarProofDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CAR_PROOF_DESC',
  VehicleStocksVarianceSampleIsNewAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_IS_NEW_ASC',
  VehicleStocksVarianceSampleIsNewDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_IS_NEW_DESC',
  VehicleStocksVarianceSampleCertifiedAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CERTIFIED_ASC',
  VehicleStocksVarianceSampleCertifiedDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CERTIFIED_DESC',
  VehicleStocksVarianceSampleSpecialAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SPECIAL_ASC',
  VehicleStocksVarianceSampleSpecialDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SPECIAL_DESC',
  VehicleStocksVarianceSampleDriveTrainAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DRIVE_TRAIN_ASC',
  VehicleStocksVarianceSampleDriveTrainDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_DRIVE_TRAIN_DESC',
  VehicleStocksVarianceSampleFuelTypeAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_FUEL_TYPE_ASC',
  VehicleStocksVarianceSampleFuelTypeDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_FUEL_TYPE_DESC',
  VehicleStocksVarianceSampleMainImageAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MAIN_IMAGE_ASC',
  VehicleStocksVarianceSampleMainImageDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_MAIN_IMAGE_DESC',
  VehicleStocksVarianceSampleImagesAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_IMAGES_ASC',
  VehicleStocksVarianceSampleImagesDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_IMAGES_DESC',
  VehicleStocksVarianceSampleVideosAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_VIDEOS_ASC',
  VehicleStocksVarianceSampleVideosDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_VIDEOS_DESC',
  VehicleStocksVarianceSampleFeaturesAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_FEATURES_ASC',
  VehicleStocksVarianceSampleFeaturesDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_FEATURES_DESC',
  VehicleStocksVarianceSampleStockStatusAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_STOCK_STATUS_ASC',
  VehicleStocksVarianceSampleStockStatusDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_STOCK_STATUS_DESC',
  VehicleStocksVarianceSamplePriceAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_PRICE_ASC',
  VehicleStocksVarianceSamplePriceDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_PRICE_DESC',
  VehicleStocksVarianceSampleCostAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COST_ASC',
  VehicleStocksVarianceSampleCostDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_COST_DESC',
  VehicleStocksVarianceSampleEntryDateAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ENTRY_DATE_ASC',
  VehicleStocksVarianceSampleEntryDateDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_ENTRY_DATE_DESC',
  VehicleStocksVarianceSampleLastChangeDateAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_LAST_CHANGE_DATE_ASC',
  VehicleStocksVarianceSampleLastChangeDateDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_LAST_CHANGE_DATE_DESC',
  VehicleStocksVarianceSampleCreatedAtAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  VehicleStocksVarianceSampleCreatedAtDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  VehicleStocksVarianceSampleUpdatedAtAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  VehicleStocksVarianceSampleUpdatedAtDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  VehicleStocksVarianceSampleSearchTextAsc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  VehicleStocksVarianceSampleSearchTextDesc = 'VEHICLE_STOCKS_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  VehicleStocksVariancePopulationIdAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ID_ASC',
  VehicleStocksVariancePopulationIdDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ID_DESC',
  VehicleStocksVariancePopulationCompanyIdAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  VehicleStocksVariancePopulationCompanyIdDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  VehicleStocksVariancePopulationCompanyIntegrationIdAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  VehicleStocksVariancePopulationCompanyIntegrationIdDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  VehicleStocksVariancePopulationProviderIdAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_PROVIDER_ID_ASC',
  VehicleStocksVariancePopulationProviderIdDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_PROVIDER_ID_DESC',
  VehicleStocksVariancePopulationVinAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_VIN_ASC',
  VehicleStocksVariancePopulationVinDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_VIN_DESC',
  VehicleStocksVariancePopulationStockNumberAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_STOCK_NUMBER_ASC',
  VehicleStocksVariancePopulationStockNumberDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_STOCK_NUMBER_DESC',
  VehicleStocksVariancePopulationYearAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_YEAR_ASC',
  VehicleStocksVariancePopulationYearDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_YEAR_DESC',
  VehicleStocksVariancePopulationMakeAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MAKE_ASC',
  VehicleStocksVariancePopulationMakeDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MAKE_DESC',
  VehicleStocksVariancePopulationModelAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MODEL_ASC',
  VehicleStocksVariancePopulationModelDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MODEL_DESC',
  VehicleStocksVariancePopulationTrimAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_TRIM_ASC',
  VehicleStocksVariancePopulationTrimDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_TRIM_DESC',
  VehicleStocksVariancePopulationBodyStyleAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_BODY_STYLE_ASC',
  VehicleStocksVariancePopulationBodyStyleDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_BODY_STYLE_DESC',
  VehicleStocksVariancePopulationExteriorColourAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_EXTERIOR_COLOUR_ASC',
  VehicleStocksVariancePopulationExteriorColourDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_EXTERIOR_COLOUR_DESC',
  VehicleStocksVariancePopulationInteriorColourAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_INTERIOR_COLOUR_ASC',
  VehicleStocksVariancePopulationInteriorColourDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_INTERIOR_COLOUR_DESC',
  VehicleStocksVariancePopulationMileageAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MILEAGE_ASC',
  VehicleStocksVariancePopulationMileageDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MILEAGE_DESC',
  VehicleStocksVariancePopulationDoorsAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DOORS_ASC',
  VehicleStocksVariancePopulationDoorsDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DOORS_DESC',
  VehicleStocksVariancePopulationSeatsAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SEATS_ASC',
  VehicleStocksVariancePopulationSeatsDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SEATS_DESC',
  VehicleStocksVariancePopulationEngineCapacityAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ENGINE_CAPACITY_ASC',
  VehicleStocksVariancePopulationEngineCapacityDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ENGINE_CAPACITY_DESC',
  VehicleStocksVariancePopulationTransmissionAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_TRANSMISSION_ASC',
  VehicleStocksVariancePopulationTransmissionDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_TRANSMISSION_DESC',
  VehicleStocksVariancePopulationCylindersAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CYLINDERS_ASC',
  VehicleStocksVariancePopulationCylindersDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CYLINDERS_DESC',
  VehicleStocksVariancePopulationDescriptionAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DESCRIPTION_ASC',
  VehicleStocksVariancePopulationDescriptionDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DESCRIPTION_DESC',
  VehicleStocksVariancePopulationWarrantyTextAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_WARRANTY_TEXT_ASC',
  VehicleStocksVariancePopulationWarrantyTextDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_WARRANTY_TEXT_DESC',
  VehicleStocksVariancePopulationCarProofAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CAR_PROOF_ASC',
  VehicleStocksVariancePopulationCarProofDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CAR_PROOF_DESC',
  VehicleStocksVariancePopulationIsNewAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_IS_NEW_ASC',
  VehicleStocksVariancePopulationIsNewDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_IS_NEW_DESC',
  VehicleStocksVariancePopulationCertifiedAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CERTIFIED_ASC',
  VehicleStocksVariancePopulationCertifiedDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CERTIFIED_DESC',
  VehicleStocksVariancePopulationSpecialAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SPECIAL_ASC',
  VehicleStocksVariancePopulationSpecialDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SPECIAL_DESC',
  VehicleStocksVariancePopulationDriveTrainAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DRIVE_TRAIN_ASC',
  VehicleStocksVariancePopulationDriveTrainDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_DRIVE_TRAIN_DESC',
  VehicleStocksVariancePopulationFuelTypeAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_FUEL_TYPE_ASC',
  VehicleStocksVariancePopulationFuelTypeDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_FUEL_TYPE_DESC',
  VehicleStocksVariancePopulationMainImageAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MAIN_IMAGE_ASC',
  VehicleStocksVariancePopulationMainImageDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_MAIN_IMAGE_DESC',
  VehicleStocksVariancePopulationImagesAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_IMAGES_ASC',
  VehicleStocksVariancePopulationImagesDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_IMAGES_DESC',
  VehicleStocksVariancePopulationVideosAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_VIDEOS_ASC',
  VehicleStocksVariancePopulationVideosDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_VIDEOS_DESC',
  VehicleStocksVariancePopulationFeaturesAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_FEATURES_ASC',
  VehicleStocksVariancePopulationFeaturesDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_FEATURES_DESC',
  VehicleStocksVariancePopulationStockStatusAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_STOCK_STATUS_ASC',
  VehicleStocksVariancePopulationStockStatusDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_STOCK_STATUS_DESC',
  VehicleStocksVariancePopulationPriceAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_PRICE_ASC',
  VehicleStocksVariancePopulationPriceDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_PRICE_DESC',
  VehicleStocksVariancePopulationCostAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COST_ASC',
  VehicleStocksVariancePopulationCostDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_COST_DESC',
  VehicleStocksVariancePopulationEntryDateAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ENTRY_DATE_ASC',
  VehicleStocksVariancePopulationEntryDateDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_ENTRY_DATE_DESC',
  VehicleStocksVariancePopulationLastChangeDateAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_LAST_CHANGE_DATE_ASC',
  VehicleStocksVariancePopulationLastChangeDateDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_LAST_CHANGE_DATE_DESC',
  VehicleStocksVariancePopulationCreatedAtAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CREATED_AT_ASC',
  VehicleStocksVariancePopulationCreatedAtDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_CREATED_AT_DESC',
  VehicleStocksVariancePopulationUpdatedAtAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  VehicleStocksVariancePopulationUpdatedAtDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  VehicleStocksVariancePopulationSearchTextAsc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  VehicleStocksVariancePopulationSearchTextDesc = 'VEHICLE_STOCKS_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  DealsCountAsc = 'DEALS_COUNT_ASC',
  DealsCountDesc = 'DEALS_COUNT_DESC',
  DealsSumIdAsc = 'DEALS_SUM_ID_ASC',
  DealsSumIdDesc = 'DEALS_SUM_ID_DESC',
  DealsSumLeadIdAsc = 'DEALS_SUM_LEAD_ID_ASC',
  DealsSumLeadIdDesc = 'DEALS_SUM_LEAD_ID_DESC',
  DealsSumCompanyIntegrationIdAsc = 'DEALS_SUM_COMPANY_INTEGRATION_ID_ASC',
  DealsSumCompanyIntegrationIdDesc = 'DEALS_SUM_COMPANY_INTEGRATION_ID_DESC',
  DealsSumFileTypeAsc = 'DEALS_SUM_FILE_TYPE_ASC',
  DealsSumFileTypeDesc = 'DEALS_SUM_FILE_TYPE_DESC',
  DealsSumDvDealerIdAsc = 'DEALS_SUM_DV_DEALER_ID_ASC',
  DealsSumDvDealerIdDesc = 'DEALS_SUM_DV_DEALER_ID_DESC',
  DealsSumVendorDealerIdAsc = 'DEALS_SUM_VENDOR_DEALER_ID_ASC',
  DealsSumVendorDealerIdDesc = 'DEALS_SUM_VENDOR_DEALER_ID_DESC',
  DealsSumDmsTypeAsc = 'DEALS_SUM_DMS_TYPE_ASC',
  DealsSumDmsTypeDesc = 'DEALS_SUM_DMS_TYPE_DESC',
  DealsSumDealNumberAsc = 'DEALS_SUM_DEAL_NUMBER_ASC',
  DealsSumDealNumberDesc = 'DEALS_SUM_DEAL_NUMBER_DESC',
  DealsSumCustomerNumberAsc = 'DEALS_SUM_CUSTOMER_NUMBER_ASC',
  DealsSumCustomerNumberDesc = 'DEALS_SUM_CUSTOMER_NUMBER_DESC',
  DealsSumFullNameAsc = 'DEALS_SUM_FULL_NAME_ASC',
  DealsSumFullNameDesc = 'DEALS_SUM_FULL_NAME_DESC',
  DealsSumSalutationAsc = 'DEALS_SUM_SALUTATION_ASC',
  DealsSumSalutationDesc = 'DEALS_SUM_SALUTATION_DESC',
  DealsSumFirstNameAsc = 'DEALS_SUM_FIRST_NAME_ASC',
  DealsSumFirstNameDesc = 'DEALS_SUM_FIRST_NAME_DESC',
  DealsSumMiddleNameAsc = 'DEALS_SUM_MIDDLE_NAME_ASC',
  DealsSumMiddleNameDesc = 'DEALS_SUM_MIDDLE_NAME_DESC',
  DealsSumLastNameAsc = 'DEALS_SUM_LAST_NAME_ASC',
  DealsSumLastNameDesc = 'DEALS_SUM_LAST_NAME_DESC',
  DealsSumSuffixAsc = 'DEALS_SUM_SUFFIX_ASC',
  DealsSumSuffixDesc = 'DEALS_SUM_SUFFIX_DESC',
  DealsSumAddressLine_1Asc = 'DEALS_SUM_ADDRESS_LINE_1_ASC',
  DealsSumAddressLine_1Desc = 'DEALS_SUM_ADDRESS_LINE_1_DESC',
  DealsSumAddressLine_2Asc = 'DEALS_SUM_ADDRESS_LINE_2_ASC',
  DealsSumAddressLine_2Desc = 'DEALS_SUM_ADDRESS_LINE_2_DESC',
  DealsSumCityAsc = 'DEALS_SUM_CITY_ASC',
  DealsSumCityDesc = 'DEALS_SUM_CITY_DESC',
  DealsSumStateAsc = 'DEALS_SUM_STATE_ASC',
  DealsSumStateDesc = 'DEALS_SUM_STATE_DESC',
  DealsSumZipAsc = 'DEALS_SUM_ZIP_ASC',
  DealsSumZipDesc = 'DEALS_SUM_ZIP_DESC',
  DealsSumCountyAsc = 'DEALS_SUM_COUNTY_ASC',
  DealsSumCountyDesc = 'DEALS_SUM_COUNTY_DESC',
  DealsSumHomePhoneAsc = 'DEALS_SUM_HOME_PHONE_ASC',
  DealsSumHomePhoneDesc = 'DEALS_SUM_HOME_PHONE_DESC',
  DealsSumCellPhoneAsc = 'DEALS_SUM_CELL_PHONE_ASC',
  DealsSumCellPhoneDesc = 'DEALS_SUM_CELL_PHONE_DESC',
  DealsSumWorkPhoneAsc = 'DEALS_SUM_WORK_PHONE_ASC',
  DealsSumWorkPhoneDesc = 'DEALS_SUM_WORK_PHONE_DESC',
  DealsSumWorkExtensionAsc = 'DEALS_SUM_WORK_EXTENSION_ASC',
  DealsSumWorkExtensionDesc = 'DEALS_SUM_WORK_EXTENSION_DESC',
  DealsSumEmail_1Asc = 'DEALS_SUM_EMAIL_1_ASC',
  DealsSumEmail_1Desc = 'DEALS_SUM_EMAIL_1_DESC',
  DealsSumEmail_2Asc = 'DEALS_SUM_EMAIL_2_ASC',
  DealsSumEmail_2Desc = 'DEALS_SUM_EMAIL_2_DESC',
  DealsSumEmail_3Asc = 'DEALS_SUM_EMAIL_3_ASC',
  DealsSumEmail_3Desc = 'DEALS_SUM_EMAIL_3_DESC',
  DealsSumBirthDateAsc = 'DEALS_SUM_BIRTH_DATE_ASC',
  DealsSumBirthDateDesc = 'DEALS_SUM_BIRTH_DATE_DESC',
  DealsSumIndividualBusinessFlagAsc = 'DEALS_SUM_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsSumIndividualBusinessFlagDesc = 'DEALS_SUM_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsSumOptOutAsc = 'DEALS_SUM_OPT_OUT_ASC',
  DealsSumOptOutDesc = 'DEALS_SUM_OPT_OUT_DESC',
  DealsSumBlockEmailAsc = 'DEALS_SUM_BLOCK_EMAIL_ASC',
  DealsSumBlockEmailDesc = 'DEALS_SUM_BLOCK_EMAIL_DESC',
  DealsSumBlockPhoneAsc = 'DEALS_SUM_BLOCK_PHONE_ASC',
  DealsSumBlockPhoneDesc = 'DEALS_SUM_BLOCK_PHONE_DESC',
  DealsSumBlockMailAsc = 'DEALS_SUM_BLOCK_MAIL_ASC',
  DealsSumBlockMailDesc = 'DEALS_SUM_BLOCK_MAIL_DESC',
  DealsSumLanguageAsc = 'DEALS_SUM_LANGUAGE_ASC',
  DealsSumLanguageDesc = 'DEALS_SUM_LANGUAGE_DESC',
  DealsSumCustomerCreateDateAsc = 'DEALS_SUM_CUSTOMER_CREATE_DATE_ASC',
  DealsSumCustomerCreateDateDesc = 'DEALS_SUM_CUSTOMER_CREATE_DATE_DESC',
  DealsSumCustomerLastActivityDateAsc = 'DEALS_SUM_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsSumCustomerLastActivityDateDesc = 'DEALS_SUM_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsSumCoBuyerCustomerNumberAsc = 'DEALS_SUM_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsSumCoBuyerCustomerNumberDesc = 'DEALS_SUM_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsSumCoBuyerFullNameAsc = 'DEALS_SUM_CO_BUYER_FULL_NAME_ASC',
  DealsSumCoBuyerFullNameDesc = 'DEALS_SUM_CO_BUYER_FULL_NAME_DESC',
  DealsSumCoBuyerSalutationAsc = 'DEALS_SUM_CO_BUYER_SALUTATION_ASC',
  DealsSumCoBuyerSalutationDesc = 'DEALS_SUM_CO_BUYER_SALUTATION_DESC',
  DealsSumCoBuyerFirstNameAsc = 'DEALS_SUM_CO_BUYER_FIRST_NAME_ASC',
  DealsSumCoBuyerFirstNameDesc = 'DEALS_SUM_CO_BUYER_FIRST_NAME_DESC',
  DealsSumCoBuyerMiddleNameAsc = 'DEALS_SUM_CO_BUYER_MIDDLE_NAME_ASC',
  DealsSumCoBuyerMiddleNameDesc = 'DEALS_SUM_CO_BUYER_MIDDLE_NAME_DESC',
  DealsSumCoBuyerLastNameAsc = 'DEALS_SUM_CO_BUYER_LAST_NAME_ASC',
  DealsSumCoBuyerLastNameDesc = 'DEALS_SUM_CO_BUYER_LAST_NAME_DESC',
  DealsSumCoBuyerSuffixAsc = 'DEALS_SUM_CO_BUYER_SUFFIX_ASC',
  DealsSumCoBuyerSuffixDesc = 'DEALS_SUM_CO_BUYER_SUFFIX_DESC',
  DealsSumCoBuyerAddressLine_1Asc = 'DEALS_SUM_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsSumCoBuyerAddressLine_1Desc = 'DEALS_SUM_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsSumCoBuyerAddressLine_2Asc = 'DEALS_SUM_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsSumCoBuyerAddressLine_2Desc = 'DEALS_SUM_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsSumCoBuyerCityAsc = 'DEALS_SUM_CO_BUYER_CITY_ASC',
  DealsSumCoBuyerCityDesc = 'DEALS_SUM_CO_BUYER_CITY_DESC',
  DealsSumCoBuyerStateAsc = 'DEALS_SUM_CO_BUYER_STATE_ASC',
  DealsSumCoBuyerStateDesc = 'DEALS_SUM_CO_BUYER_STATE_DESC',
  DealsSumCoBuyerZipAsc = 'DEALS_SUM_CO_BUYER_ZIP_ASC',
  DealsSumCoBuyerZipDesc = 'DEALS_SUM_CO_BUYER_ZIP_DESC',
  DealsSumCoBuyerCountyAsc = 'DEALS_SUM_CO_BUYER_COUNTY_ASC',
  DealsSumCoBuyerCountyDesc = 'DEALS_SUM_CO_BUYER_COUNTY_DESC',
  DealsSumCoBuyerHomePhoneAsc = 'DEALS_SUM_CO_BUYER_HOME_PHONE_ASC',
  DealsSumCoBuyerHomePhoneDesc = 'DEALS_SUM_CO_BUYER_HOME_PHONE_DESC',
  DealsSumCoBuyerCellPhoneAsc = 'DEALS_SUM_CO_BUYER_CELL_PHONE_ASC',
  DealsSumCoBuyerCellPhoneDesc = 'DEALS_SUM_CO_BUYER_CELL_PHONE_DESC',
  DealsSumCoBuyerWorkPhoneAsc = 'DEALS_SUM_CO_BUYER_WORK_PHONE_ASC',
  DealsSumCoBuyerWorkPhoneDesc = 'DEALS_SUM_CO_BUYER_WORK_PHONE_DESC',
  DealsSumCoBuyerWorkExtensionAsc = 'DEALS_SUM_CO_BUYER_WORK_EXTENSION_ASC',
  DealsSumCoBuyerWorkExtensionDesc = 'DEALS_SUM_CO_BUYER_WORK_EXTENSION_DESC',
  DealsSumCoBuyerEmail_1Asc = 'DEALS_SUM_CO_BUYER_EMAIL_1_ASC',
  DealsSumCoBuyerEmail_1Desc = 'DEALS_SUM_CO_BUYER_EMAIL_1_DESC',
  DealsSumCoBuyerEmail_2Asc = 'DEALS_SUM_CO_BUYER_EMAIL_2_ASC',
  DealsSumCoBuyerEmail_2Desc = 'DEALS_SUM_CO_BUYER_EMAIL_2_DESC',
  DealsSumCoBuyerEmail_3Asc = 'DEALS_SUM_CO_BUYER_EMAIL_3_ASC',
  DealsSumCoBuyerEmail_3Desc = 'DEALS_SUM_CO_BUYER_EMAIL_3_DESC',
  DealsSumCoBuyerBirthDateAsc = 'DEALS_SUM_CO_BUYER_BIRTH_DATE_ASC',
  DealsSumCoBuyerBirthDateDesc = 'DEALS_SUM_CO_BUYER_BIRTH_DATE_DESC',
  DealsSumCoBuyerIndividualBusinessFlagAsc = 'DEALS_SUM_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsSumCoBuyerIndividualBusinessFlagDesc = 'DEALS_SUM_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsSumCoBuyerOptOutAsc = 'DEALS_SUM_CO_BUYER_OPT_OUT_ASC',
  DealsSumCoBuyerOptOutDesc = 'DEALS_SUM_CO_BUYER_OPT_OUT_DESC',
  DealsSumCoBuyerBlockEmailAsc = 'DEALS_SUM_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsSumCoBuyerBlockEmailDesc = 'DEALS_SUM_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsSumCoBuyerBlockPhoneAsc = 'DEALS_SUM_CO_BUYER_BLOCK_PHONE_ASC',
  DealsSumCoBuyerBlockPhoneDesc = 'DEALS_SUM_CO_BUYER_BLOCK_PHONE_DESC',
  DealsSumCoBuyerBlockMailAsc = 'DEALS_SUM_CO_BUYER_BLOCK_MAIL_ASC',
  DealsSumCoBuyerBlockMailDesc = 'DEALS_SUM_CO_BUYER_BLOCK_MAIL_DESC',
  DealsSumVinAsc = 'DEALS_SUM_VIN_ASC',
  DealsSumVinDesc = 'DEALS_SUM_VIN_DESC',
  DealsSumYearAsc = 'DEALS_SUM_YEAR_ASC',
  DealsSumYearDesc = 'DEALS_SUM_YEAR_DESC',
  DealsSumMakeAsc = 'DEALS_SUM_MAKE_ASC',
  DealsSumMakeDesc = 'DEALS_SUM_MAKE_DESC',
  DealsSumModelAsc = 'DEALS_SUM_MODEL_ASC',
  DealsSumModelDesc = 'DEALS_SUM_MODEL_DESC',
  DealsSumModelNumberAsc = 'DEALS_SUM_MODEL_NUMBER_ASC',
  DealsSumModelNumberDesc = 'DEALS_SUM_MODEL_NUMBER_DESC',
  DealsSumMileageAsc = 'DEALS_SUM_MILEAGE_ASC',
  DealsSumMileageDesc = 'DEALS_SUM_MILEAGE_DESC',
  DealsSumDescriptionAsc = 'DEALS_SUM_DESCRIPTION_ASC',
  DealsSumDescriptionDesc = 'DEALS_SUM_DESCRIPTION_DESC',
  DealsSumExteriorColorAsc = 'DEALS_SUM_EXTERIOR_COLOR_ASC',
  DealsSumExteriorColorDesc = 'DEALS_SUM_EXTERIOR_COLOR_DESC',
  DealsSumNewUsedAsc = 'DEALS_SUM_NEW_USED_ASC',
  DealsSumNewUsedDesc = 'DEALS_SUM_NEW_USED_DESC',
  DealsSumStockNumberAsc = 'DEALS_SUM_STOCK_NUMBER_ASC',
  DealsSumStockNumberDesc = 'DEALS_SUM_STOCK_NUMBER_DESC',
  DealsSumTransmissionAsc = 'DEALS_SUM_TRANSMISSION_ASC',
  DealsSumTransmissionDesc = 'DEALS_SUM_TRANSMISSION_DESC',
  DealsSumEngineConfigurationAsc = 'DEALS_SUM_ENGINE_CONFIGURATION_ASC',
  DealsSumEngineConfigurationDesc = 'DEALS_SUM_ENGINE_CONFIGURATION_DESC',
  DealsSumTrimAsc = 'DEALS_SUM_TRIM_ASC',
  DealsSumTrimDesc = 'DEALS_SUM_TRIM_DESC',
  DealsSumEngineNumberAsc = 'DEALS_SUM_ENGINE_NUMBER_ASC',
  DealsSumEngineNumberDesc = 'DEALS_SUM_ENGINE_NUMBER_DESC',
  DealsSumChassisNumberAsc = 'DEALS_SUM_CHASSIS_NUMBER_ASC',
  DealsSumChassisNumberDesc = 'DEALS_SUM_CHASSIS_NUMBER_DESC',
  DealsSumLicensePlateNumberAsc = 'DEALS_SUM_LICENSE_PLATE_NUMBER_ASC',
  DealsSumLicensePlateNumberDesc = 'DEALS_SUM_LICENSE_PLATE_NUMBER_DESC',
  DealsSumDeliveryDateAsc = 'DEALS_SUM_DELIVERY_DATE_ASC',
  DealsSumDeliveryDateDesc = 'DEALS_SUM_DELIVERY_DATE_DESC',
  DealsSumDeliveryMileageAsc = 'DEALS_SUM_DELIVERY_MILEAGE_ASC',
  DealsSumDeliveryMileageDesc = 'DEALS_SUM_DELIVERY_MILEAGE_DESC',
  DealsSumInventoryDateAsc = 'DEALS_SUM_INVENTORY_DATE_ASC',
  DealsSumInventoryDateDesc = 'DEALS_SUM_INVENTORY_DATE_DESC',
  DealsSumInServiceDateAsc = 'DEALS_SUM_IN_SERVICE_DATE_ASC',
  DealsSumInServiceDateDesc = 'DEALS_SUM_IN_SERVICE_DATE_DESC',
  DealsSumVinExplosionYearAsc = 'DEALS_SUM_VIN_EXPLOSION_YEAR_ASC',
  DealsSumVinExplosionYearDesc = 'DEALS_SUM_VIN_EXPLOSION_YEAR_DESC',
  DealsSumVinExplosionMakeAsc = 'DEALS_SUM_VIN_EXPLOSION_MAKE_ASC',
  DealsSumVinExplosionMakeDesc = 'DEALS_SUM_VIN_EXPLOSION_MAKE_DESC',
  DealsSumVinExplosionModelAsc = 'DEALS_SUM_VIN_EXPLOSION_MODEL_ASC',
  DealsSumVinExplosionModelDesc = 'DEALS_SUM_VIN_EXPLOSION_MODEL_DESC',
  DealsSumVinExplosionTrimAsc = 'DEALS_SUM_VIN_EXPLOSION_TRIM_ASC',
  DealsSumVinExplosionTrimDesc = 'DEALS_SUM_VIN_EXPLOSION_TRIM_DESC',
  DealsSumVinExplosionTransmissionTypeAsc = 'DEALS_SUM_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsSumVinExplosionTransmissionTypeDesc = 'DEALS_SUM_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsSumVinExplosionFuelTypeAsc = 'DEALS_SUM_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsSumVinExplosionFuelTypeDesc = 'DEALS_SUM_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsSumVinExplosionEngineSizeAsc = 'DEALS_SUM_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsSumVinExplosionEngineSizeDesc = 'DEALS_SUM_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsSumVinExplosionGvwRangeAsc = 'DEALS_SUM_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsSumVinExplosionGvwRangeDesc = 'DEALS_SUM_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsSumTrade_1VinAsc = 'DEALS_SUM_TRADE_1_VIN_ASC',
  DealsSumTrade_1VinDesc = 'DEALS_SUM_TRADE_1_VIN_DESC',
  DealsSumTrade_1YearAsc = 'DEALS_SUM_TRADE_1_YEAR_ASC',
  DealsSumTrade_1YearDesc = 'DEALS_SUM_TRADE_1_YEAR_DESC',
  DealsSumTrade_1MakeAsc = 'DEALS_SUM_TRADE_1_MAKE_ASC',
  DealsSumTrade_1MakeDesc = 'DEALS_SUM_TRADE_1_MAKE_DESC',
  DealsSumTrade_1ModelAsc = 'DEALS_SUM_TRADE_1_MODEL_ASC',
  DealsSumTrade_1ModelDesc = 'DEALS_SUM_TRADE_1_MODEL_DESC',
  DealsSumTrade_1OdometerAsc = 'DEALS_SUM_TRADE_1_ODOMETER_ASC',
  DealsSumTrade_1OdometerDesc = 'DEALS_SUM_TRADE_1_ODOMETER_DESC',
  DealsSumTrade_1ActualCashValueAsc = 'DEALS_SUM_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsSumTrade_1ActualCashValueDesc = 'DEALS_SUM_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsSumTrade_1GrossAsc = 'DEALS_SUM_TRADE_1_GROSS_ASC',
  DealsSumTrade_1GrossDesc = 'DEALS_SUM_TRADE_1_GROSS_DESC',
  DealsSumTrade_1PayoffAsc = 'DEALS_SUM_TRADE_1_PAYOFF_ASC',
  DealsSumTrade_1PayoffDesc = 'DEALS_SUM_TRADE_1_PAYOFF_DESC',
  DealsSumTrade_2VinAsc = 'DEALS_SUM_TRADE_2_VIN_ASC',
  DealsSumTrade_2VinDesc = 'DEALS_SUM_TRADE_2_VIN_DESC',
  DealsSumTrade_2YearAsc = 'DEALS_SUM_TRADE_2_YEAR_ASC',
  DealsSumTrade_2YearDesc = 'DEALS_SUM_TRADE_2_YEAR_DESC',
  DealsSumTrade_2MakeAsc = 'DEALS_SUM_TRADE_2_MAKE_ASC',
  DealsSumTrade_2MakeDesc = 'DEALS_SUM_TRADE_2_MAKE_DESC',
  DealsSumTrade_2ModelAsc = 'DEALS_SUM_TRADE_2_MODEL_ASC',
  DealsSumTrade_2ModelDesc = 'DEALS_SUM_TRADE_2_MODEL_DESC',
  DealsSumTrade_2OdometerAsc = 'DEALS_SUM_TRADE_2_ODOMETER_ASC',
  DealsSumTrade_2OdometerDesc = 'DEALS_SUM_TRADE_2_ODOMETER_DESC',
  DealsSumTrade_2ActualCashValueAsc = 'DEALS_SUM_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsSumTrade_2ActualCashValueDesc = 'DEALS_SUM_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsSumTrade_2GrossAsc = 'DEALS_SUM_TRADE_2_GROSS_ASC',
  DealsSumTrade_2GrossDesc = 'DEALS_SUM_TRADE_2_GROSS_DESC',
  DealsSumTrade_2PayoffAsc = 'DEALS_SUM_TRADE_2_PAYOFF_ASC',
  DealsSumTrade_2PayoffDesc = 'DEALS_SUM_TRADE_2_PAYOFF_DESC',
  DealsSumSalesman_1NumberAsc = 'DEALS_SUM_SALESMAN_1_NUMBER_ASC',
  DealsSumSalesman_1NumberDesc = 'DEALS_SUM_SALESMAN_1_NUMBER_DESC',
  DealsSumSalesman_1NameAsc = 'DEALS_SUM_SALESMAN_1_NAME_ASC',
  DealsSumSalesman_1NameDesc = 'DEALS_SUM_SALESMAN_1_NAME_DESC',
  DealsSumSalesman_2NumberAsc = 'DEALS_SUM_SALESMAN_2_NUMBER_ASC',
  DealsSumSalesman_2NumberDesc = 'DEALS_SUM_SALESMAN_2_NUMBER_DESC',
  DealsSumSalesman_2NameAsc = 'DEALS_SUM_SALESMAN_2_NAME_ASC',
  DealsSumSalesman_2NameDesc = 'DEALS_SUM_SALESMAN_2_NAME_DESC',
  DealsSumSalesman_3NumberAsc = 'DEALS_SUM_SALESMAN_3_NUMBER_ASC',
  DealsSumSalesman_3NumberDesc = 'DEALS_SUM_SALESMAN_3_NUMBER_DESC',
  DealsSumSalesman_3NameAsc = 'DEALS_SUM_SALESMAN_3_NAME_ASC',
  DealsSumSalesman_3NameDesc = 'DEALS_SUM_SALESMAN_3_NAME_DESC',
  DealsSumClosingManagerNumberAsc = 'DEALS_SUM_CLOSING_MANAGER_NUMBER_ASC',
  DealsSumClosingManagerNumberDesc = 'DEALS_SUM_CLOSING_MANAGER_NUMBER_DESC',
  DealsSumClosingManagerNameAsc = 'DEALS_SUM_CLOSING_MANAGER_NAME_ASC',
  DealsSumClosingManagerNameDesc = 'DEALS_SUM_CLOSING_MANAGER_NAME_DESC',
  DealsSumFinanceManagerNumberAsc = 'DEALS_SUM_FINANCE_MANAGER_NUMBER_ASC',
  DealsSumFinanceManagerNumberDesc = 'DEALS_SUM_FINANCE_MANAGER_NUMBER_DESC',
  DealsSumFinanceManagerNameAsc = 'DEALS_SUM_FINANCE_MANAGER_NAME_ASC',
  DealsSumFinanceManagerNameDesc = 'DEALS_SUM_FINANCE_MANAGER_NAME_DESC',
  DealsSumSalesmanManagerNumberAsc = 'DEALS_SUM_SALESMAN_MANAGER_NUMBER_ASC',
  DealsSumSalesmanManagerNumberDesc = 'DEALS_SUM_SALESMAN_MANAGER_NUMBER_DESC',
  DealsSumSalesmanManagerNameAsc = 'DEALS_SUM_SALESMAN_MANAGER_NAME_ASC',
  DealsSumSalesmanManagerNameDesc = 'DEALS_SUM_SALESMAN_MANAGER_NAME_DESC',
  DealsSumMsrpAsc = 'DEALS_SUM_MSRP_ASC',
  DealsSumMsrpDesc = 'DEALS_SUM_MSRP_DESC',
  DealsSumListPriceAsc = 'DEALS_SUM_LIST_PRICE_ASC',
  DealsSumListPriceDesc = 'DEALS_SUM_LIST_PRICE_DESC',
  DealsSumSalesPriceAsc = 'DEALS_SUM_SALES_PRICE_ASC',
  DealsSumSalesPriceDesc = 'DEALS_SUM_SALES_PRICE_DESC',
  DealsSumJournalPriceAsc = 'DEALS_SUM_JOURNAL_PRICE_ASC',
  DealsSumJournalPriceDesc = 'DEALS_SUM_JOURNAL_PRICE_DESC',
  DealsSumCostAsc = 'DEALS_SUM_COST_ASC',
  DealsSumCostDesc = 'DEALS_SUM_COST_DESC',
  DealsSumJournalCostAsc = 'DEALS_SUM_JOURNAL_COST_ASC',
  DealsSumJournalCostDesc = 'DEALS_SUM_JOURNAL_COST_DESC',
  DealsSumAdjustmentsAsc = 'DEALS_SUM_ADJUSTMENTS_ASC',
  DealsSumAdjustmentsDesc = 'DEALS_SUM_ADJUSTMENTS_DESC',
  DealsSumAdjustedCostAsc = 'DEALS_SUM_ADJUSTED_COST_ASC',
  DealsSumAdjustedCostDesc = 'DEALS_SUM_ADJUSTED_COST_DESC',
  DealsSumIncentivesAsc = 'DEALS_SUM_INCENTIVES_ASC',
  DealsSumIncentivesDesc = 'DEALS_SUM_INCENTIVES_DESC',
  DealsSumPackAmountAsc = 'DEALS_SUM_PACK_AMOUNT_ASC',
  DealsSumPackAmountDesc = 'DEALS_SUM_PACK_AMOUNT_DESC',
  DealsSumSaleNetAsc = 'DEALS_SUM_SALE_NET_ASC',
  DealsSumSaleNetDesc = 'DEALS_SUM_SALE_NET_DESC',
  DealsSumTotalTradeActualCashValueAsc = 'DEALS_SUM_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsSumTotalTradeActualCashValueDesc = 'DEALS_SUM_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsSumTotalTradeGrossAsc = 'DEALS_SUM_TOTAL_TRADE_GROSS_ASC',
  DealsSumTotalTradeGrossDesc = 'DEALS_SUM_TOTAL_TRADE_GROSS_DESC',
  DealsSumWeOweFrontAsc = 'DEALS_SUM_WE_OWE_FRONT_ASC',
  DealsSumWeOweFrontDesc = 'DEALS_SUM_WE_OWE_FRONT_DESC',
  DealsSumTotalFrontFeesAftermarketProfitAsc = 'DEALS_SUM_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsSumTotalFrontFeesAftermarketProfitDesc = 'DEALS_SUM_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsSumTotalFrontCommissionAsc = 'DEALS_SUM_TOTAL_FRONT_COMMISSION_ASC',
  DealsSumTotalFrontCommissionDesc = 'DEALS_SUM_TOTAL_FRONT_COMMISSION_DESC',
  DealsSumTotalFrontSalesAsc = 'DEALS_SUM_TOTAL_FRONT_SALES_ASC',
  DealsSumTotalFrontSalesDesc = 'DEALS_SUM_TOTAL_FRONT_SALES_DESC',
  DealsSumTotalFrontCostAsc = 'DEALS_SUM_TOTAL_FRONT_COST_ASC',
  DealsSumTotalFrontCostDesc = 'DEALS_SUM_TOTAL_FRONT_COST_DESC',
  DealsSumFrontGrossAsc = 'DEALS_SUM_FRONT_GROSS_ASC',
  DealsSumFrontGrossDesc = 'DEALS_SUM_FRONT_GROSS_DESC',
  DealsSumFinanceProfitAsc = 'DEALS_SUM_FINANCE_PROFIT_ASC',
  DealsSumFinanceProfitDesc = 'DEALS_SUM_FINANCE_PROFIT_DESC',
  DealsSumTotalWarrantyProfitAsc = 'DEALS_SUM_TOTAL_WARRANTY_PROFIT_ASC',
  DealsSumTotalWarrantyProfitDesc = 'DEALS_SUM_TOTAL_WARRANTY_PROFIT_DESC',
  DealsSumWeOweBackAsc = 'DEALS_SUM_WE_OWE_BACK_ASC',
  DealsSumWeOweBackDesc = 'DEALS_SUM_WE_OWE_BACK_DESC',
  DealsSumInsuranceProfitAsc = 'DEALS_SUM_INSURANCE_PROFIT_ASC',
  DealsSumInsuranceProfitDesc = 'DEALS_SUM_INSURANCE_PROFIT_DESC',
  DealsSumTotalBackFeesAftermarketProfitAsc = 'DEALS_SUM_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsSumTotalBackFeesAftermarketProfitDesc = 'DEALS_SUM_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsSumFinanceReserveAsc = 'DEALS_SUM_FINANCE_RESERVE_ASC',
  DealsSumFinanceReserveDesc = 'DEALS_SUM_FINANCE_RESERVE_DESC',
  DealsSumTotalBackCommissionAsc = 'DEALS_SUM_TOTAL_BACK_COMMISSION_ASC',
  DealsSumTotalBackCommissionDesc = 'DEALS_SUM_TOTAL_BACK_COMMISSION_DESC',
  DealsSumTotalBackSalesAsc = 'DEALS_SUM_TOTAL_BACK_SALES_ASC',
  DealsSumTotalBackSalesDesc = 'DEALS_SUM_TOTAL_BACK_SALES_DESC',
  DealsSumTotalBackCostAsc = 'DEALS_SUM_TOTAL_BACK_COST_ASC',
  DealsSumTotalBackCostDesc = 'DEALS_SUM_TOTAL_BACK_COST_DESC',
  DealsSumBackGrossAsc = 'DEALS_SUM_BACK_GROSS_ASC',
  DealsSumBackGrossDesc = 'DEALS_SUM_BACK_GROSS_DESC',
  DealsSumTotalProfitAsc = 'DEALS_SUM_TOTAL_PROFIT_ASC',
  DealsSumTotalProfitDesc = 'DEALS_SUM_TOTAL_PROFIT_DESC',
  DealsSumGrossProfitAsc = 'DEALS_SUM_GROSS_PROFIT_ASC',
  DealsSumGrossProfitDesc = 'DEALS_SUM_GROSS_PROFIT_DESC',
  DealsSumGrossPayableAsc = 'DEALS_SUM_GROSS_PAYABLE_ASC',
  DealsSumGrossPayableDesc = 'DEALS_SUM_GROSS_PAYABLE_DESC',
  DealsSumDealStatusAsc = 'DEALS_SUM_DEAL_STATUS_ASC',
  DealsSumDealStatusDesc = 'DEALS_SUM_DEAL_STATUS_DESC',
  DealsSumEntryDateAsc = 'DEALS_SUM_ENTRY_DATE_ASC',
  DealsSumEntryDateDesc = 'DEALS_SUM_ENTRY_DATE_DESC',
  DealsSumBookedDateAsc = 'DEALS_SUM_BOOKED_DATE_ASC',
  DealsSumBookedDateDesc = 'DEALS_SUM_BOOKED_DATE_DESC',
  DealsSumFinalizedDateAsc = 'DEALS_SUM_FINALIZED_DATE_ASC',
  DealsSumFinalizedDateDesc = 'DEALS_SUM_FINALIZED_DATE_DESC',
  DealsSumContractDateAsc = 'DEALS_SUM_CONTRACT_DATE_ASC',
  DealsSumContractDateDesc = 'DEALS_SUM_CONTRACT_DATE_DESC',
  DealsSumAccountingDateAsc = 'DEALS_SUM_ACCOUNTING_DATE_ASC',
  DealsSumAccountingDateDesc = 'DEALS_SUM_ACCOUNTING_DATE_DESC',
  DealsSumStatusChangeDateAsc = 'DEALS_SUM_STATUS_CHANGE_DATE_ASC',
  DealsSumStatusChangeDateDesc = 'DEALS_SUM_STATUS_CHANGE_DATE_DESC',
  DealsSumFirstPayDateAsc = 'DEALS_SUM_FIRST_PAY_DATE_ASC',
  DealsSumFirstPayDateDesc = 'DEALS_SUM_FIRST_PAY_DATE_DESC',
  DealsSumDealTypeAsc = 'DEALS_SUM_DEAL_TYPE_ASC',
  DealsSumDealTypeDesc = 'DEALS_SUM_DEAL_TYPE_DESC',
  DealsSumSaleTypeAsc = 'DEALS_SUM_SALE_TYPE_ASC',
  DealsSumSaleTypeDesc = 'DEALS_SUM_SALE_TYPE_DESC',
  DealsSumBankIdAsc = 'DEALS_SUM_BANK_ID_ASC',
  DealsSumBankIdDesc = 'DEALS_SUM_BANK_ID_DESC',
  DealsSumBankNameAsc = 'DEALS_SUM_BANK_NAME_ASC',
  DealsSumBankNameDesc = 'DEALS_SUM_BANK_NAME_DESC',
  DealsSumBankAddressAsc = 'DEALS_SUM_BANK_ADDRESS_ASC',
  DealsSumBankAddressDesc = 'DEALS_SUM_BANK_ADDRESS_DESC',
  DealsSumTermAsc = 'DEALS_SUM_TERM_ASC',
  DealsSumTermDesc = 'DEALS_SUM_TERM_DESC',
  DealsSumAmountFinancedAsc = 'DEALS_SUM_AMOUNT_FINANCED_ASC',
  DealsSumAmountFinancedDesc = 'DEALS_SUM_AMOUNT_FINANCED_DESC',
  DealsSumAprAsc = 'DEALS_SUM_APR_ASC',
  DealsSumAprDesc = 'DEALS_SUM_APR_DESC',
  DealsSumMonthlyPaymentAsc = 'DEALS_SUM_MONTHLY_PAYMENT_ASC',
  DealsSumMonthlyPaymentDesc = 'DEALS_SUM_MONTHLY_PAYMENT_DESC',
  DealsSumPaymentTotalAsc = 'DEALS_SUM_PAYMENT_TOTAL_ASC',
  DealsSumPaymentTotalDesc = 'DEALS_SUM_PAYMENT_TOTAL_DESC',
  DealsSumRebatesAsc = 'DEALS_SUM_REBATES_ASC',
  DealsSumRebatesDesc = 'DEALS_SUM_REBATES_DESC',
  DealsSumDepositAsc = 'DEALS_SUM_DEPOSIT_ASC',
  DealsSumDepositDesc = 'DEALS_SUM_DEPOSIT_DESC',
  DealsSumDownPaymentAsc = 'DEALS_SUM_DOWN_PAYMENT_ASC',
  DealsSumDownPaymentDesc = 'DEALS_SUM_DOWN_PAYMENT_DESC',
  DealsSumTotalNetTradesAsc = 'DEALS_SUM_TOTAL_NET_TRADES_ASC',
  DealsSumTotalNetTradesDesc = 'DEALS_SUM_TOTAL_NET_TRADES_DESC',
  DealsSumTotalDownAsc = 'DEALS_SUM_TOTAL_DOWN_ASC',
  DealsSumTotalDownDesc = 'DEALS_SUM_TOTAL_DOWN_DESC',
  DealsSumBalloonAmountAsc = 'DEALS_SUM_BALLOON_AMOUNT_ASC',
  DealsSumBalloonAmountDesc = 'DEALS_SUM_BALLOON_AMOUNT_DESC',
  DealsSumAdjustedBalloonAmountAsc = 'DEALS_SUM_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsSumAdjustedBalloonAmountDesc = 'DEALS_SUM_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsSumHoldbackAmountAsc = 'DEALS_SUM_HOLDBACK_AMOUNT_ASC',
  DealsSumHoldbackAmountDesc = 'DEALS_SUM_HOLDBACK_AMOUNT_DESC',
  DealsSumTotalDriveOffAmountAsc = 'DEALS_SUM_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsSumTotalDriveOffAmountDesc = 'DEALS_SUM_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsSumLicenseFeeAsc = 'DEALS_SUM_LICENSE_FEE_ASC',
  DealsSumLicenseFeeDesc = 'DEALS_SUM_LICENSE_FEE_DESC',
  DealsSumRegistrationFeeAsc = 'DEALS_SUM_REGISTRATION_FEE_ASC',
  DealsSumRegistrationFeeDesc = 'DEALS_SUM_REGISTRATION_FEE_DESC',
  DealsSumDocumentationFeeAsc = 'DEALS_SUM_DOCUMENTATION_FEE_ASC',
  DealsSumDocumentationFeeDesc = 'DEALS_SUM_DOCUMENTATION_FEE_DESC',
  DealsSumFinanceChargeAsc = 'DEALS_SUM_FINANCE_CHARGE_ASC',
  DealsSumFinanceChargeDesc = 'DEALS_SUM_FINANCE_CHARGE_DESC',
  DealsSumTotalPickupPaymentsAsc = 'DEALS_SUM_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsSumTotalPickupPaymentsDesc = 'DEALS_SUM_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsSumSellRateAsc = 'DEALS_SUM_SELL_RATE_ASC',
  DealsSumSellRateDesc = 'DEALS_SUM_SELL_RATE_DESC',
  DealsSumBuyRateAsc = 'DEALS_SUM_BUY_RATE_ASC',
  DealsSumBuyRateDesc = 'DEALS_SUM_BUY_RATE_DESC',
  DealsSumResidualRateAsc = 'DEALS_SUM_RESIDUAL_RATE_ASC',
  DealsSumResidualRateDesc = 'DEALS_SUM_RESIDUAL_RATE_DESC',
  DealsSumResidualAmountAsc = 'DEALS_SUM_RESIDUAL_AMOUNT_ASC',
  DealsSumResidualAmountDesc = 'DEALS_SUM_RESIDUAL_AMOUNT_DESC',
  DealsSumAllowedMilesAsc = 'DEALS_SUM_ALLOWED_MILES_ASC',
  DealsSumAllowedMilesDesc = 'DEALS_SUM_ALLOWED_MILES_DESC',
  DealsSumEstimatedMilesAsc = 'DEALS_SUM_ESTIMATED_MILES_ASC',
  DealsSumEstimatedMilesDesc = 'DEALS_SUM_ESTIMATED_MILES_DESC',
  DealsSumMileageRateAsc = 'DEALS_SUM_MILEAGE_RATE_ASC',
  DealsSumMileageRateDesc = 'DEALS_SUM_MILEAGE_RATE_DESC',
  DealsSumAcquisitionFeeAsc = 'DEALS_SUM_ACQUISITION_FEE_ASC',
  DealsSumAcquisitionFeeDesc = 'DEALS_SUM_ACQUISITION_FEE_DESC',
  DealsSumBasePaymentAsc = 'DEALS_SUM_BASE_PAYMENT_ASC',
  DealsSumBasePaymentDesc = 'DEALS_SUM_BASE_PAYMENT_DESC',
  DealsSumSecurityDepositAsc = 'DEALS_SUM_SECURITY_DEPOSIT_ASC',
  DealsSumSecurityDepositDesc = 'DEALS_SUM_SECURITY_DEPOSIT_DESC',
  DealsSumTotalCapitalReductionAsc = 'DEALS_SUM_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsSumTotalCapitalReductionDesc = 'DEALS_SUM_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsSumNetCapitalCostAsc = 'DEALS_SUM_NET_CAPITAL_COST_ASC',
  DealsSumNetCapitalCostDesc = 'DEALS_SUM_NET_CAPITAL_COST_DESC',
  DealsSumLeaseDepreciationValueAsc = 'DEALS_SUM_LEASE_DEPRECIATION_VALUE_ASC',
  DealsSumLeaseDepreciationValueDesc = 'DEALS_SUM_LEASE_DEPRECIATION_VALUE_DESC',
  DealsSumDealerFeesAsc = 'DEALS_SUM_DEALER_FEES_ASC',
  DealsSumDealerFeesDesc = 'DEALS_SUM_DEALER_FEES_DESC',
  DealsSumGovernmentFeesAsc = 'DEALS_SUM_GOVERNMENT_FEES_ASC',
  DealsSumGovernmentFeesDesc = 'DEALS_SUM_GOVERNMENT_FEES_DESC',
  DealsSumTotalTaxAsc = 'DEALS_SUM_TOTAL_TAX_ASC',
  DealsSumTotalTaxDesc = 'DEALS_SUM_TOTAL_TAX_DESC',
  DealsSumRegistrationStateAsc = 'DEALS_SUM_REGISTRATION_STATE_ASC',
  DealsSumRegistrationStateDesc = 'DEALS_SUM_REGISTRATION_STATE_DESC',
  DealsSumReportOfSaleNumberAsc = 'DEALS_SUM_REPORT_OF_SALE_NUMBER_ASC',
  DealsSumReportOfSaleNumberDesc = 'DEALS_SUM_REPORT_OF_SALE_NUMBER_DESC',
  DealsSumSalesman_1TotalCommissionAsc = 'DEALS_SUM_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsSumSalesman_1TotalCommissionDesc = 'DEALS_SUM_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsSumSalesman_1FrontCommissionAsc = 'DEALS_SUM_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsSumSalesman_1FrontCommissionDesc = 'DEALS_SUM_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsSumSalesman_1BackCommissionAsc = 'DEALS_SUM_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsSumSalesman_1BackCommissionDesc = 'DEALS_SUM_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsSumSalesman_2TotalCommissionAsc = 'DEALS_SUM_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsSumSalesman_2TotalCommissionDesc = 'DEALS_SUM_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsSumSalesman_2FrontCommissionAsc = 'DEALS_SUM_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsSumSalesman_2FrontCommissionDesc = 'DEALS_SUM_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsSumSalesman_2BackCommissionAsc = 'DEALS_SUM_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsSumSalesman_2BackCommissionDesc = 'DEALS_SUM_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsSumSalesman_3TotalCommissionAsc = 'DEALS_SUM_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsSumSalesman_3TotalCommissionDesc = 'DEALS_SUM_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsSumSalesman_3FrontCommissionAsc = 'DEALS_SUM_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsSumSalesman_3FrontCommissionDesc = 'DEALS_SUM_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsSumSalesman_3BackCommissionAsc = 'DEALS_SUM_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsSumSalesman_3BackCommissionDesc = 'DEALS_SUM_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsSumWarranty_1NameAsc = 'DEALS_SUM_WARRANTY_1_NAME_ASC',
  DealsSumWarranty_1NameDesc = 'DEALS_SUM_WARRANTY_1_NAME_DESC',
  DealsSumWarranty_1SaleAsc = 'DEALS_SUM_WARRANTY_1_SALE_ASC',
  DealsSumWarranty_1SaleDesc = 'DEALS_SUM_WARRANTY_1_SALE_DESC',
  DealsSumWarranty_1CostAsc = 'DEALS_SUM_WARRANTY_1_COST_ASC',
  DealsSumWarranty_1CostDesc = 'DEALS_SUM_WARRANTY_1_COST_DESC',
  DealsSumWarranty_1MilesAsc = 'DEALS_SUM_WARRANTY_1_MILES_ASC',
  DealsSumWarranty_1MilesDesc = 'DEALS_SUM_WARRANTY_1_MILES_DESC',
  DealsSumWarranty_1TermAsc = 'DEALS_SUM_WARRANTY_1_TERM_ASC',
  DealsSumWarranty_1TermDesc = 'DEALS_SUM_WARRANTY_1_TERM_DESC',
  DealsSumWarranty_2NameAsc = 'DEALS_SUM_WARRANTY_2_NAME_ASC',
  DealsSumWarranty_2NameDesc = 'DEALS_SUM_WARRANTY_2_NAME_DESC',
  DealsSumWarranty_2SaleAsc = 'DEALS_SUM_WARRANTY_2_SALE_ASC',
  DealsSumWarranty_2SaleDesc = 'DEALS_SUM_WARRANTY_2_SALE_DESC',
  DealsSumWarranty_2CostAsc = 'DEALS_SUM_WARRANTY_2_COST_ASC',
  DealsSumWarranty_2CostDesc = 'DEALS_SUM_WARRANTY_2_COST_DESC',
  DealsSumWarranty_2MilesAsc = 'DEALS_SUM_WARRANTY_2_MILES_ASC',
  DealsSumWarranty_2MilesDesc = 'DEALS_SUM_WARRANTY_2_MILES_DESC',
  DealsSumWarranty_2TermAsc = 'DEALS_SUM_WARRANTY_2_TERM_ASC',
  DealsSumWarranty_2TermDesc = 'DEALS_SUM_WARRANTY_2_TERM_DESC',
  DealsSumWarranty_3NameAsc = 'DEALS_SUM_WARRANTY_3_NAME_ASC',
  DealsSumWarranty_3NameDesc = 'DEALS_SUM_WARRANTY_3_NAME_DESC',
  DealsSumWarranty_3SaleAsc = 'DEALS_SUM_WARRANTY_3_SALE_ASC',
  DealsSumWarranty_3SaleDesc = 'DEALS_SUM_WARRANTY_3_SALE_DESC',
  DealsSumWarranty_3CostAsc = 'DEALS_SUM_WARRANTY_3_COST_ASC',
  DealsSumWarranty_3CostDesc = 'DEALS_SUM_WARRANTY_3_COST_DESC',
  DealsSumWarranty_3MilesAsc = 'DEALS_SUM_WARRANTY_3_MILES_ASC',
  DealsSumWarranty_3MilesDesc = 'DEALS_SUM_WARRANTY_3_MILES_DESC',
  DealsSumWarranty_3TermAsc = 'DEALS_SUM_WARRANTY_3_TERM_ASC',
  DealsSumWarranty_3TermDesc = 'DEALS_SUM_WARRANTY_3_TERM_DESC',
  DealsSumWarranty_4NameAsc = 'DEALS_SUM_WARRANTY_4_NAME_ASC',
  DealsSumWarranty_4NameDesc = 'DEALS_SUM_WARRANTY_4_NAME_DESC',
  DealsSumWarranty_4SaleAsc = 'DEALS_SUM_WARRANTY_4_SALE_ASC',
  DealsSumWarranty_4SaleDesc = 'DEALS_SUM_WARRANTY_4_SALE_DESC',
  DealsSumWarranty_4CostAsc = 'DEALS_SUM_WARRANTY_4_COST_ASC',
  DealsSumWarranty_4CostDesc = 'DEALS_SUM_WARRANTY_4_COST_DESC',
  DealsSumWarranty_4MilesAsc = 'DEALS_SUM_WARRANTY_4_MILES_ASC',
  DealsSumWarranty_4MilesDesc = 'DEALS_SUM_WARRANTY_4_MILES_DESC',
  DealsSumWarranty_4TermAsc = 'DEALS_SUM_WARRANTY_4_TERM_ASC',
  DealsSumWarranty_4TermDesc = 'DEALS_SUM_WARRANTY_4_TERM_DESC',
  DealsSumWarranty_5NameAsc = 'DEALS_SUM_WARRANTY_5_NAME_ASC',
  DealsSumWarranty_5NameDesc = 'DEALS_SUM_WARRANTY_5_NAME_DESC',
  DealsSumWarranty_5SaleAsc = 'DEALS_SUM_WARRANTY_5_SALE_ASC',
  DealsSumWarranty_5SaleDesc = 'DEALS_SUM_WARRANTY_5_SALE_DESC',
  DealsSumWarranty_5CostAsc = 'DEALS_SUM_WARRANTY_5_COST_ASC',
  DealsSumWarranty_5CostDesc = 'DEALS_SUM_WARRANTY_5_COST_DESC',
  DealsSumWarranty_5MilesAsc = 'DEALS_SUM_WARRANTY_5_MILES_ASC',
  DealsSumWarranty_5MilesDesc = 'DEALS_SUM_WARRANTY_5_MILES_DESC',
  DealsSumWarranty_5TermAsc = 'DEALS_SUM_WARRANTY_5_TERM_ASC',
  DealsSumWarranty_5TermDesc = 'DEALS_SUM_WARRANTY_5_TERM_DESC',
  DealsSumTotalFeeAftermarketSaleAsc = 'DEALS_SUM_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsSumTotalFeeAftermarketSaleDesc = 'DEALS_SUM_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsSumTotalFeeAftermarketCostAsc = 'DEALS_SUM_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsSumTotalFeeAftermarketCostDesc = 'DEALS_SUM_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsSumFeeAftermarket_1NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_1_NAME_ASC',
  DealsSumFeeAftermarket_1NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_1_NAME_DESC',
  DealsSumFeeAftermarket_1SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_1_SALE_ASC',
  DealsSumFeeAftermarket_1SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_1_SALE_DESC',
  DealsSumFeeAftermarket_1CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_1_COST_ASC',
  DealsSumFeeAftermarket_1CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_1_COST_DESC',
  DealsSumFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_2NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_2_NAME_ASC',
  DealsSumFeeAftermarket_2NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_2_NAME_DESC',
  DealsSumFeeAftermarket_2SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_2_SALE_ASC',
  DealsSumFeeAftermarket_2SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_2_SALE_DESC',
  DealsSumFeeAftermarket_2CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_2_COST_ASC',
  DealsSumFeeAftermarket_2CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_2_COST_DESC',
  DealsSumFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_3NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_3_NAME_ASC',
  DealsSumFeeAftermarket_3NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_3_NAME_DESC',
  DealsSumFeeAftermarket_3SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_3_SALE_ASC',
  DealsSumFeeAftermarket_3SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_3_SALE_DESC',
  DealsSumFeeAftermarket_3CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_3_COST_ASC',
  DealsSumFeeAftermarket_3CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_3_COST_DESC',
  DealsSumFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_4NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_4_NAME_ASC',
  DealsSumFeeAftermarket_4NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_4_NAME_DESC',
  DealsSumFeeAftermarket_4SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_4_SALE_ASC',
  DealsSumFeeAftermarket_4SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_4_SALE_DESC',
  DealsSumFeeAftermarket_4CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_4_COST_ASC',
  DealsSumFeeAftermarket_4CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_4_COST_DESC',
  DealsSumFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_5NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_5_NAME_ASC',
  DealsSumFeeAftermarket_5NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_5_NAME_DESC',
  DealsSumFeeAftermarket_5SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_5_SALE_ASC',
  DealsSumFeeAftermarket_5SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_5_SALE_DESC',
  DealsSumFeeAftermarket_5CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_5_COST_ASC',
  DealsSumFeeAftermarket_5CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_5_COST_DESC',
  DealsSumFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_6NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_6_NAME_ASC',
  DealsSumFeeAftermarket_6NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_6_NAME_DESC',
  DealsSumFeeAftermarket_6SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_6_SALE_ASC',
  DealsSumFeeAftermarket_6SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_6_SALE_DESC',
  DealsSumFeeAftermarket_6CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_6_COST_ASC',
  DealsSumFeeAftermarket_6CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_6_COST_DESC',
  DealsSumFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_7NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_7_NAME_ASC',
  DealsSumFeeAftermarket_7NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_7_NAME_DESC',
  DealsSumFeeAftermarket_7SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_7_SALE_ASC',
  DealsSumFeeAftermarket_7SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_7_SALE_DESC',
  DealsSumFeeAftermarket_7CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_7_COST_ASC',
  DealsSumFeeAftermarket_7CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_7_COST_DESC',
  DealsSumFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_8NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_8_NAME_ASC',
  DealsSumFeeAftermarket_8NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_8_NAME_DESC',
  DealsSumFeeAftermarket_8SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_8_SALE_ASC',
  DealsSumFeeAftermarket_8SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_8_SALE_DESC',
  DealsSumFeeAftermarket_8CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_8_COST_ASC',
  DealsSumFeeAftermarket_8CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_8_COST_DESC',
  DealsSumFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_9NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_9_NAME_ASC',
  DealsSumFeeAftermarket_9NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_9_NAME_DESC',
  DealsSumFeeAftermarket_9SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_9_SALE_ASC',
  DealsSumFeeAftermarket_9SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_9_SALE_DESC',
  DealsSumFeeAftermarket_9CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_9_COST_ASC',
  DealsSumFeeAftermarket_9CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_9_COST_DESC',
  DealsSumFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_10NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_10_NAME_ASC',
  DealsSumFeeAftermarket_10NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_10_NAME_DESC',
  DealsSumFeeAftermarket_10SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_10_SALE_ASC',
  DealsSumFeeAftermarket_10SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_10_SALE_DESC',
  DealsSumFeeAftermarket_10CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_10_COST_ASC',
  DealsSumFeeAftermarket_10CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_10_COST_DESC',
  DealsSumFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_11NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_11_NAME_ASC',
  DealsSumFeeAftermarket_11NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_11_NAME_DESC',
  DealsSumFeeAftermarket_11SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_11_SALE_ASC',
  DealsSumFeeAftermarket_11SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_11_SALE_DESC',
  DealsSumFeeAftermarket_11CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_11_COST_ASC',
  DealsSumFeeAftermarket_11CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_11_COST_DESC',
  DealsSumFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_12NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_12_NAME_ASC',
  DealsSumFeeAftermarket_12NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_12_NAME_DESC',
  DealsSumFeeAftermarket_12SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_12_SALE_ASC',
  DealsSumFeeAftermarket_12SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_12_SALE_DESC',
  DealsSumFeeAftermarket_12CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_12_COST_ASC',
  DealsSumFeeAftermarket_12CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_12_COST_DESC',
  DealsSumFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_13NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_13_NAME_ASC',
  DealsSumFeeAftermarket_13NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_13_NAME_DESC',
  DealsSumFeeAftermarket_13SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_13_SALE_ASC',
  DealsSumFeeAftermarket_13SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_13_SALE_DESC',
  DealsSumFeeAftermarket_13CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_13_COST_ASC',
  DealsSumFeeAftermarket_13CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_13_COST_DESC',
  DealsSumFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_14NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_14_NAME_ASC',
  DealsSumFeeAftermarket_14NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_14_NAME_DESC',
  DealsSumFeeAftermarket_14SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_14_SALE_ASC',
  DealsSumFeeAftermarket_14SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_14_SALE_DESC',
  DealsSumFeeAftermarket_14CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_14_COST_ASC',
  DealsSumFeeAftermarket_14CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_14_COST_DESC',
  DealsSumFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_15NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_15_NAME_ASC',
  DealsSumFeeAftermarket_15NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_15_NAME_DESC',
  DealsSumFeeAftermarket_15SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_15_SALE_ASC',
  DealsSumFeeAftermarket_15SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_15_SALE_DESC',
  DealsSumFeeAftermarket_15CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_15_COST_ASC',
  DealsSumFeeAftermarket_15CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_15_COST_DESC',
  DealsSumFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_16NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_16_NAME_ASC',
  DealsSumFeeAftermarket_16NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_16_NAME_DESC',
  DealsSumFeeAftermarket_16SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_16_SALE_ASC',
  DealsSumFeeAftermarket_16SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_16_SALE_DESC',
  DealsSumFeeAftermarket_16CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_16_COST_ASC',
  DealsSumFeeAftermarket_16CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_16_COST_DESC',
  DealsSumFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_17NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_17_NAME_ASC',
  DealsSumFeeAftermarket_17NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_17_NAME_DESC',
  DealsSumFeeAftermarket_17SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_17_SALE_ASC',
  DealsSumFeeAftermarket_17SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_17_SALE_DESC',
  DealsSumFeeAftermarket_17CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_17_COST_ASC',
  DealsSumFeeAftermarket_17CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_17_COST_DESC',
  DealsSumFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_18NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_18_NAME_ASC',
  DealsSumFeeAftermarket_18NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_18_NAME_DESC',
  DealsSumFeeAftermarket_18SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_18_SALE_ASC',
  DealsSumFeeAftermarket_18SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_18_SALE_DESC',
  DealsSumFeeAftermarket_18CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_18_COST_ASC',
  DealsSumFeeAftermarket_18CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_18_COST_DESC',
  DealsSumFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_19NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_19_NAME_ASC',
  DealsSumFeeAftermarket_19NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_19_NAME_DESC',
  DealsSumFeeAftermarket_19SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_19_SALE_ASC',
  DealsSumFeeAftermarket_19SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_19_SALE_DESC',
  DealsSumFeeAftermarket_19CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_19_COST_ASC',
  DealsSumFeeAftermarket_19CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_19_COST_DESC',
  DealsSumFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_20NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_20_NAME_ASC',
  DealsSumFeeAftermarket_20NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_20_NAME_DESC',
  DealsSumFeeAftermarket_20SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_20_SALE_ASC',
  DealsSumFeeAftermarket_20SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_20_SALE_DESC',
  DealsSumFeeAftermarket_20CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_20_COST_ASC',
  DealsSumFeeAftermarket_20CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_20_COST_DESC',
  DealsSumFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsSumInsurance_1TypeAsc = 'DEALS_SUM_INSURANCE_1_TYPE_ASC',
  DealsSumInsurance_1TypeDesc = 'DEALS_SUM_INSURANCE_1_TYPE_DESC',
  DealsSumInsurance_1NameAsc = 'DEALS_SUM_INSURANCE_1_NAME_ASC',
  DealsSumInsurance_1NameDesc = 'DEALS_SUM_INSURANCE_1_NAME_DESC',
  DealsSumInsurance_1SaleAsc = 'DEALS_SUM_INSURANCE_1_SALE_ASC',
  DealsSumInsurance_1SaleDesc = 'DEALS_SUM_INSURANCE_1_SALE_DESC',
  DealsSumInsurance_1CostAsc = 'DEALS_SUM_INSURANCE_1_COST_ASC',
  DealsSumInsurance_1CostDesc = 'DEALS_SUM_INSURANCE_1_COST_DESC',
  DealsSumInsurance_1TermAsc = 'DEALS_SUM_INSURANCE_1_TERM_ASC',
  DealsSumInsurance_1TermDesc = 'DEALS_SUM_INSURANCE_1_TERM_DESC',
  DealsSumInsurance_2TypeAsc = 'DEALS_SUM_INSURANCE_2_TYPE_ASC',
  DealsSumInsurance_2TypeDesc = 'DEALS_SUM_INSURANCE_2_TYPE_DESC',
  DealsSumInsurance_2NameAsc = 'DEALS_SUM_INSURANCE_2_NAME_ASC',
  DealsSumInsurance_2NameDesc = 'DEALS_SUM_INSURANCE_2_NAME_DESC',
  DealsSumInsurance_2SaleAsc = 'DEALS_SUM_INSURANCE_2_SALE_ASC',
  DealsSumInsurance_2SaleDesc = 'DEALS_SUM_INSURANCE_2_SALE_DESC',
  DealsSumInsurance_2CostAsc = 'DEALS_SUM_INSURANCE_2_COST_ASC',
  DealsSumInsurance_2CostDesc = 'DEALS_SUM_INSURANCE_2_COST_DESC',
  DealsSumInsurance_2TermAsc = 'DEALS_SUM_INSURANCE_2_TERM_ASC',
  DealsSumInsurance_2TermDesc = 'DEALS_SUM_INSURANCE_2_TERM_DESC',
  DealsSumInsurance_3TypeAsc = 'DEALS_SUM_INSURANCE_3_TYPE_ASC',
  DealsSumInsurance_3TypeDesc = 'DEALS_SUM_INSURANCE_3_TYPE_DESC',
  DealsSumInsurance_3NameAsc = 'DEALS_SUM_INSURANCE_3_NAME_ASC',
  DealsSumInsurance_3NameDesc = 'DEALS_SUM_INSURANCE_3_NAME_DESC',
  DealsSumInsurance_3SaleAsc = 'DEALS_SUM_INSURANCE_3_SALE_ASC',
  DealsSumInsurance_3SaleDesc = 'DEALS_SUM_INSURANCE_3_SALE_DESC',
  DealsSumInsurance_3CostAsc = 'DEALS_SUM_INSURANCE_3_COST_ASC',
  DealsSumInsurance_3CostDesc = 'DEALS_SUM_INSURANCE_3_COST_DESC',
  DealsSumInsurance_3TermAsc = 'DEALS_SUM_INSURANCE_3_TERM_ASC',
  DealsSumInsurance_3TermDesc = 'DEALS_SUM_INSURANCE_3_TERM_DESC',
  DealsSumAccidentalHealthTypeAsc = 'DEALS_SUM_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsSumAccidentalHealthTypeDesc = 'DEALS_SUM_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsSumAccidentalHealthNameAsc = 'DEALS_SUM_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsSumAccidentalHealthNameDesc = 'DEALS_SUM_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsSumAccidentalHealthSaleAsc = 'DEALS_SUM_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsSumAccidentalHealthSaleDesc = 'DEALS_SUM_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsSumAccidentalHealthCostAsc = 'DEALS_SUM_ACCIDENTAL_HEALTH_COST_ASC',
  DealsSumAccidentalHealthCostDesc = 'DEALS_SUM_ACCIDENTAL_HEALTH_COST_DESC',
  DealsSumAccidentalHealthTermAsc = 'DEALS_SUM_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsSumAccidentalHealthTermDesc = 'DEALS_SUM_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsSumCreditLifeTypeAsc = 'DEALS_SUM_CREDIT_LIFE_TYPE_ASC',
  DealsSumCreditLifeTypeDesc = 'DEALS_SUM_CREDIT_LIFE_TYPE_DESC',
  DealsSumCreditLifeNameAsc = 'DEALS_SUM_CREDIT_LIFE_NAME_ASC',
  DealsSumCreditLifeNameDesc = 'DEALS_SUM_CREDIT_LIFE_NAME_DESC',
  DealsSumCreditLifeSaleAsc = 'DEALS_SUM_CREDIT_LIFE_SALE_ASC',
  DealsSumCreditLifeSaleDesc = 'DEALS_SUM_CREDIT_LIFE_SALE_DESC',
  DealsSumCreditLifeCostAsc = 'DEALS_SUM_CREDIT_LIFE_COST_ASC',
  DealsSumCreditLifeCostDesc = 'DEALS_SUM_CREDIT_LIFE_COST_DESC',
  DealsSumCreditLifeTermAsc = 'DEALS_SUM_CREDIT_LIFE_TERM_ASC',
  DealsSumCreditLifeTermDesc = 'DEALS_SUM_CREDIT_LIFE_TERM_DESC',
  DealsSumLevelizedLifeTypeAsc = 'DEALS_SUM_LEVELIZED_LIFE_TYPE_ASC',
  DealsSumLevelizedLifeTypeDesc = 'DEALS_SUM_LEVELIZED_LIFE_TYPE_DESC',
  DealsSumLevelizedLifeNameAsc = 'DEALS_SUM_LEVELIZED_LIFE_NAME_ASC',
  DealsSumLevelizedLifeNameDesc = 'DEALS_SUM_LEVELIZED_LIFE_NAME_DESC',
  DealsSumLevelizedLifeSaleAsc = 'DEALS_SUM_LEVELIZED_LIFE_SALE_ASC',
  DealsSumLevelizedLifeSaleDesc = 'DEALS_SUM_LEVELIZED_LIFE_SALE_DESC',
  DealsSumLevelizedLifeCostAsc = 'DEALS_SUM_LEVELIZED_LIFE_COST_ASC',
  DealsSumLevelizedLifeCostDesc = 'DEALS_SUM_LEVELIZED_LIFE_COST_DESC',
  DealsSumLevelizedLifeTermAsc = 'DEALS_SUM_LEVELIZED_LIFE_TERM_ASC',
  DealsSumLevelizedLifeTermDesc = 'DEALS_SUM_LEVELIZED_LIFE_TERM_DESC',
  DealsSumLossOfEmploymentTypeAsc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsSumLossOfEmploymentTypeDesc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsSumLossOfEmploymentNameAsc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsSumLossOfEmploymentNameDesc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsSumLossOfEmploymentSaleAsc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsSumLossOfEmploymentSaleDesc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsSumLossOfEmploymentCostAsc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsSumLossOfEmploymentCostDesc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsSumLossOfEmploymentTermAsc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsSumLossOfEmploymentTermDesc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsSumGuaranteedAutoProtectionTypeAsc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsSumGuaranteedAutoProtectionTypeDesc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsSumGuaranteedAutoProtectionNameAsc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsSumGuaranteedAutoProtectionNameDesc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsSumGuaranteedAutoProtectionSaleAsc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsSumGuaranteedAutoProtectionSaleDesc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsSumGuaranteedAutoProtectionCostAsc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsSumGuaranteedAutoProtectionCostDesc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsSumGuaranteedAutoProtectionTermAsc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsSumGuaranteedAutoProtectionTermDesc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsSumSaleCommentsAsc = 'DEALS_SUM_SALE_COMMENTS_ASC',
  DealsSumSaleCommentsDesc = 'DEALS_SUM_SALE_COMMENTS_DESC',
  DealsSumCassStdLine1Asc = 'DEALS_SUM_CASS_STD_LINE1_ASC',
  DealsSumCassStdLine1Desc = 'DEALS_SUM_CASS_STD_LINE1_DESC',
  DealsSumCassStdLine2Asc = 'DEALS_SUM_CASS_STD_LINE2_ASC',
  DealsSumCassStdLine2Desc = 'DEALS_SUM_CASS_STD_LINE2_DESC',
  DealsSumCassStdCityAsc = 'DEALS_SUM_CASS_STD_CITY_ASC',
  DealsSumCassStdCityDesc = 'DEALS_SUM_CASS_STD_CITY_DESC',
  DealsSumCassStdStateAsc = 'DEALS_SUM_CASS_STD_STATE_ASC',
  DealsSumCassStdStateDesc = 'DEALS_SUM_CASS_STD_STATE_DESC',
  DealsSumCassStdZipAsc = 'DEALS_SUM_CASS_STD_ZIP_ASC',
  DealsSumCassStdZipDesc = 'DEALS_SUM_CASS_STD_ZIP_DESC',
  DealsSumCassStdZip4Asc = 'DEALS_SUM_CASS_STD_ZIP4_ASC',
  DealsSumCassStdZip4Desc = 'DEALS_SUM_CASS_STD_ZIP4_DESC',
  DealsSumCassStdDpbcAsc = 'DEALS_SUM_CASS_STD_DPBC_ASC',
  DealsSumCassStdDpbcDesc = 'DEALS_SUM_CASS_STD_DPBC_DESC',
  DealsSumCassStdChkdgtAsc = 'DEALS_SUM_CASS_STD_CHKDGT_ASC',
  DealsSumCassStdChkdgtDesc = 'DEALS_SUM_CASS_STD_CHKDGT_DESC',
  DealsSumCassStdCartAsc = 'DEALS_SUM_CASS_STD_CART_ASC',
  DealsSumCassStdCartDesc = 'DEALS_SUM_CASS_STD_CART_DESC',
  DealsSumCassStdLotAsc = 'DEALS_SUM_CASS_STD_LOT_ASC',
  DealsSumCassStdLotDesc = 'DEALS_SUM_CASS_STD_LOT_DESC',
  DealsSumCassStdLotordAsc = 'DEALS_SUM_CASS_STD_LOTORD_ASC',
  DealsSumCassStdLotordDesc = 'DEALS_SUM_CASS_STD_LOTORD_DESC',
  DealsSumCassStdUrbAsc = 'DEALS_SUM_CASS_STD_URB_ASC',
  DealsSumCassStdUrbDesc = 'DEALS_SUM_CASS_STD_URB_DESC',
  DealsSumCassStdFipsAsc = 'DEALS_SUM_CASS_STD_FIPS_ASC',
  DealsSumCassStdFipsDesc = 'DEALS_SUM_CASS_STD_FIPS_DESC',
  DealsSumCassStdEwsAsc = 'DEALS_SUM_CASS_STD_EWS_ASC',
  DealsSumCassStdEwsDesc = 'DEALS_SUM_CASS_STD_EWS_DESC',
  DealsSumCassStdLacsAsc = 'DEALS_SUM_CASS_STD_LACS_ASC',
  DealsSumCassStdLacsDesc = 'DEALS_SUM_CASS_STD_LACS_DESC',
  DealsSumCassStdZipmovAsc = 'DEALS_SUM_CASS_STD_ZIPMOV_ASC',
  DealsSumCassStdZipmovDesc = 'DEALS_SUM_CASS_STD_ZIPMOV_DESC',
  DealsSumCassStdZ4LomAsc = 'DEALS_SUM_CASS_STD_Z4LOM_ASC',
  DealsSumCassStdZ4LomDesc = 'DEALS_SUM_CASS_STD_Z4LOM_DESC',
  DealsSumCassStdNdiaptAsc = 'DEALS_SUM_CASS_STD_NDIAPT_ASC',
  DealsSumCassStdNdiaptDesc = 'DEALS_SUM_CASS_STD_NDIAPT_DESC',
  DealsSumCassStdNdirrAsc = 'DEALS_SUM_CASS_STD_NDIRR_ASC',
  DealsSumCassStdNdirrDesc = 'DEALS_SUM_CASS_STD_NDIRR_DESC',
  DealsSumCassStdLacsrtAsc = 'DEALS_SUM_CASS_STD_LACSRT_ASC',
  DealsSumCassStdLacsrtDesc = 'DEALS_SUM_CASS_STD_LACSRT_DESC',
  DealsSumCassStdErrorCdAsc = 'DEALS_SUM_CASS_STD_ERROR_CD_ASC',
  DealsSumCassStdErrorCdDesc = 'DEALS_SUM_CASS_STD_ERROR_CD_DESC',
  DealsDistinctCountIdAsc = 'DEALS_DISTINCT_COUNT_ID_ASC',
  DealsDistinctCountIdDesc = 'DEALS_DISTINCT_COUNT_ID_DESC',
  DealsDistinctCountLeadIdAsc = 'DEALS_DISTINCT_COUNT_LEAD_ID_ASC',
  DealsDistinctCountLeadIdDesc = 'DEALS_DISTINCT_COUNT_LEAD_ID_DESC',
  DealsDistinctCountCompanyIntegrationIdAsc = 'DEALS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  DealsDistinctCountCompanyIntegrationIdDesc = 'DEALS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  DealsDistinctCountFileTypeAsc = 'DEALS_DISTINCT_COUNT_FILE_TYPE_ASC',
  DealsDistinctCountFileTypeDesc = 'DEALS_DISTINCT_COUNT_FILE_TYPE_DESC',
  DealsDistinctCountDvDealerIdAsc = 'DEALS_DISTINCT_COUNT_DV_DEALER_ID_ASC',
  DealsDistinctCountDvDealerIdDesc = 'DEALS_DISTINCT_COUNT_DV_DEALER_ID_DESC',
  DealsDistinctCountVendorDealerIdAsc = 'DEALS_DISTINCT_COUNT_VENDOR_DEALER_ID_ASC',
  DealsDistinctCountVendorDealerIdDesc = 'DEALS_DISTINCT_COUNT_VENDOR_DEALER_ID_DESC',
  DealsDistinctCountDmsTypeAsc = 'DEALS_DISTINCT_COUNT_DMS_TYPE_ASC',
  DealsDistinctCountDmsTypeDesc = 'DEALS_DISTINCT_COUNT_DMS_TYPE_DESC',
  DealsDistinctCountDealNumberAsc = 'DEALS_DISTINCT_COUNT_DEAL_NUMBER_ASC',
  DealsDistinctCountDealNumberDesc = 'DEALS_DISTINCT_COUNT_DEAL_NUMBER_DESC',
  DealsDistinctCountCustomerNumberAsc = 'DEALS_DISTINCT_COUNT_CUSTOMER_NUMBER_ASC',
  DealsDistinctCountCustomerNumberDesc = 'DEALS_DISTINCT_COUNT_CUSTOMER_NUMBER_DESC',
  DealsDistinctCountFullNameAsc = 'DEALS_DISTINCT_COUNT_FULL_NAME_ASC',
  DealsDistinctCountFullNameDesc = 'DEALS_DISTINCT_COUNT_FULL_NAME_DESC',
  DealsDistinctCountSalutationAsc = 'DEALS_DISTINCT_COUNT_SALUTATION_ASC',
  DealsDistinctCountSalutationDesc = 'DEALS_DISTINCT_COUNT_SALUTATION_DESC',
  DealsDistinctCountFirstNameAsc = 'DEALS_DISTINCT_COUNT_FIRST_NAME_ASC',
  DealsDistinctCountFirstNameDesc = 'DEALS_DISTINCT_COUNT_FIRST_NAME_DESC',
  DealsDistinctCountMiddleNameAsc = 'DEALS_DISTINCT_COUNT_MIDDLE_NAME_ASC',
  DealsDistinctCountMiddleNameDesc = 'DEALS_DISTINCT_COUNT_MIDDLE_NAME_DESC',
  DealsDistinctCountLastNameAsc = 'DEALS_DISTINCT_COUNT_LAST_NAME_ASC',
  DealsDistinctCountLastNameDesc = 'DEALS_DISTINCT_COUNT_LAST_NAME_DESC',
  DealsDistinctCountSuffixAsc = 'DEALS_DISTINCT_COUNT_SUFFIX_ASC',
  DealsDistinctCountSuffixDesc = 'DEALS_DISTINCT_COUNT_SUFFIX_DESC',
  DealsDistinctCountAddressLine_1Asc = 'DEALS_DISTINCT_COUNT_ADDRESS_LINE_1_ASC',
  DealsDistinctCountAddressLine_1Desc = 'DEALS_DISTINCT_COUNT_ADDRESS_LINE_1_DESC',
  DealsDistinctCountAddressLine_2Asc = 'DEALS_DISTINCT_COUNT_ADDRESS_LINE_2_ASC',
  DealsDistinctCountAddressLine_2Desc = 'DEALS_DISTINCT_COUNT_ADDRESS_LINE_2_DESC',
  DealsDistinctCountCityAsc = 'DEALS_DISTINCT_COUNT_CITY_ASC',
  DealsDistinctCountCityDesc = 'DEALS_DISTINCT_COUNT_CITY_DESC',
  DealsDistinctCountStateAsc = 'DEALS_DISTINCT_COUNT_STATE_ASC',
  DealsDistinctCountStateDesc = 'DEALS_DISTINCT_COUNT_STATE_DESC',
  DealsDistinctCountZipAsc = 'DEALS_DISTINCT_COUNT_ZIP_ASC',
  DealsDistinctCountZipDesc = 'DEALS_DISTINCT_COUNT_ZIP_DESC',
  DealsDistinctCountCountyAsc = 'DEALS_DISTINCT_COUNT_COUNTY_ASC',
  DealsDistinctCountCountyDesc = 'DEALS_DISTINCT_COUNT_COUNTY_DESC',
  DealsDistinctCountHomePhoneAsc = 'DEALS_DISTINCT_COUNT_HOME_PHONE_ASC',
  DealsDistinctCountHomePhoneDesc = 'DEALS_DISTINCT_COUNT_HOME_PHONE_DESC',
  DealsDistinctCountCellPhoneAsc = 'DEALS_DISTINCT_COUNT_CELL_PHONE_ASC',
  DealsDistinctCountCellPhoneDesc = 'DEALS_DISTINCT_COUNT_CELL_PHONE_DESC',
  DealsDistinctCountWorkPhoneAsc = 'DEALS_DISTINCT_COUNT_WORK_PHONE_ASC',
  DealsDistinctCountWorkPhoneDesc = 'DEALS_DISTINCT_COUNT_WORK_PHONE_DESC',
  DealsDistinctCountWorkExtensionAsc = 'DEALS_DISTINCT_COUNT_WORK_EXTENSION_ASC',
  DealsDistinctCountWorkExtensionDesc = 'DEALS_DISTINCT_COUNT_WORK_EXTENSION_DESC',
  DealsDistinctCountEmail_1Asc = 'DEALS_DISTINCT_COUNT_EMAIL_1_ASC',
  DealsDistinctCountEmail_1Desc = 'DEALS_DISTINCT_COUNT_EMAIL_1_DESC',
  DealsDistinctCountEmail_2Asc = 'DEALS_DISTINCT_COUNT_EMAIL_2_ASC',
  DealsDistinctCountEmail_2Desc = 'DEALS_DISTINCT_COUNT_EMAIL_2_DESC',
  DealsDistinctCountEmail_3Asc = 'DEALS_DISTINCT_COUNT_EMAIL_3_ASC',
  DealsDistinctCountEmail_3Desc = 'DEALS_DISTINCT_COUNT_EMAIL_3_DESC',
  DealsDistinctCountBirthDateAsc = 'DEALS_DISTINCT_COUNT_BIRTH_DATE_ASC',
  DealsDistinctCountBirthDateDesc = 'DEALS_DISTINCT_COUNT_BIRTH_DATE_DESC',
  DealsDistinctCountIndividualBusinessFlagAsc = 'DEALS_DISTINCT_COUNT_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsDistinctCountIndividualBusinessFlagDesc = 'DEALS_DISTINCT_COUNT_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsDistinctCountOptOutAsc = 'DEALS_DISTINCT_COUNT_OPT_OUT_ASC',
  DealsDistinctCountOptOutDesc = 'DEALS_DISTINCT_COUNT_OPT_OUT_DESC',
  DealsDistinctCountBlockEmailAsc = 'DEALS_DISTINCT_COUNT_BLOCK_EMAIL_ASC',
  DealsDistinctCountBlockEmailDesc = 'DEALS_DISTINCT_COUNT_BLOCK_EMAIL_DESC',
  DealsDistinctCountBlockPhoneAsc = 'DEALS_DISTINCT_COUNT_BLOCK_PHONE_ASC',
  DealsDistinctCountBlockPhoneDesc = 'DEALS_DISTINCT_COUNT_BLOCK_PHONE_DESC',
  DealsDistinctCountBlockMailAsc = 'DEALS_DISTINCT_COUNT_BLOCK_MAIL_ASC',
  DealsDistinctCountBlockMailDesc = 'DEALS_DISTINCT_COUNT_BLOCK_MAIL_DESC',
  DealsDistinctCountLanguageAsc = 'DEALS_DISTINCT_COUNT_LANGUAGE_ASC',
  DealsDistinctCountLanguageDesc = 'DEALS_DISTINCT_COUNT_LANGUAGE_DESC',
  DealsDistinctCountCustomerCreateDateAsc = 'DEALS_DISTINCT_COUNT_CUSTOMER_CREATE_DATE_ASC',
  DealsDistinctCountCustomerCreateDateDesc = 'DEALS_DISTINCT_COUNT_CUSTOMER_CREATE_DATE_DESC',
  DealsDistinctCountCustomerLastActivityDateAsc = 'DEALS_DISTINCT_COUNT_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsDistinctCountCustomerLastActivityDateDesc = 'DEALS_DISTINCT_COUNT_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsDistinctCountCoBuyerCustomerNumberAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsDistinctCountCoBuyerCustomerNumberDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsDistinctCountCoBuyerFullNameAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_FULL_NAME_ASC',
  DealsDistinctCountCoBuyerFullNameDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_FULL_NAME_DESC',
  DealsDistinctCountCoBuyerSalutationAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_SALUTATION_ASC',
  DealsDistinctCountCoBuyerSalutationDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_SALUTATION_DESC',
  DealsDistinctCountCoBuyerFirstNameAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_FIRST_NAME_ASC',
  DealsDistinctCountCoBuyerFirstNameDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_FIRST_NAME_DESC',
  DealsDistinctCountCoBuyerMiddleNameAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_MIDDLE_NAME_ASC',
  DealsDistinctCountCoBuyerMiddleNameDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_MIDDLE_NAME_DESC',
  DealsDistinctCountCoBuyerLastNameAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_LAST_NAME_ASC',
  DealsDistinctCountCoBuyerLastNameDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_LAST_NAME_DESC',
  DealsDistinctCountCoBuyerSuffixAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_SUFFIX_ASC',
  DealsDistinctCountCoBuyerSuffixDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_SUFFIX_DESC',
  DealsDistinctCountCoBuyerAddressLine_1Asc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsDistinctCountCoBuyerAddressLine_1Desc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsDistinctCountCoBuyerAddressLine_2Asc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsDistinctCountCoBuyerAddressLine_2Desc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsDistinctCountCoBuyerCityAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CITY_ASC',
  DealsDistinctCountCoBuyerCityDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CITY_DESC',
  DealsDistinctCountCoBuyerStateAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_STATE_ASC',
  DealsDistinctCountCoBuyerStateDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_STATE_DESC',
  DealsDistinctCountCoBuyerZipAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ZIP_ASC',
  DealsDistinctCountCoBuyerZipDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ZIP_DESC',
  DealsDistinctCountCoBuyerCountyAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_COUNTY_ASC',
  DealsDistinctCountCoBuyerCountyDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_COUNTY_DESC',
  DealsDistinctCountCoBuyerHomePhoneAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_HOME_PHONE_ASC',
  DealsDistinctCountCoBuyerHomePhoneDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_HOME_PHONE_DESC',
  DealsDistinctCountCoBuyerCellPhoneAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CELL_PHONE_ASC',
  DealsDistinctCountCoBuyerCellPhoneDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CELL_PHONE_DESC',
  DealsDistinctCountCoBuyerWorkPhoneAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_WORK_PHONE_ASC',
  DealsDistinctCountCoBuyerWorkPhoneDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_WORK_PHONE_DESC',
  DealsDistinctCountCoBuyerWorkExtensionAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_WORK_EXTENSION_ASC',
  DealsDistinctCountCoBuyerWorkExtensionDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_WORK_EXTENSION_DESC',
  DealsDistinctCountCoBuyerEmail_1Asc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_1_ASC',
  DealsDistinctCountCoBuyerEmail_1Desc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_1_DESC',
  DealsDistinctCountCoBuyerEmail_2Asc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_2_ASC',
  DealsDistinctCountCoBuyerEmail_2Desc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_2_DESC',
  DealsDistinctCountCoBuyerEmail_3Asc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_3_ASC',
  DealsDistinctCountCoBuyerEmail_3Desc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_3_DESC',
  DealsDistinctCountCoBuyerBirthDateAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BIRTH_DATE_ASC',
  DealsDistinctCountCoBuyerBirthDateDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BIRTH_DATE_DESC',
  DealsDistinctCountCoBuyerIndividualBusinessFlagAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsDistinctCountCoBuyerIndividualBusinessFlagDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsDistinctCountCoBuyerOptOutAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_OPT_OUT_ASC',
  DealsDistinctCountCoBuyerOptOutDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_OPT_OUT_DESC',
  DealsDistinctCountCoBuyerBlockEmailAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsDistinctCountCoBuyerBlockEmailDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsDistinctCountCoBuyerBlockPhoneAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_PHONE_ASC',
  DealsDistinctCountCoBuyerBlockPhoneDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_PHONE_DESC',
  DealsDistinctCountCoBuyerBlockMailAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_MAIL_ASC',
  DealsDistinctCountCoBuyerBlockMailDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_MAIL_DESC',
  DealsDistinctCountVinAsc = 'DEALS_DISTINCT_COUNT_VIN_ASC',
  DealsDistinctCountVinDesc = 'DEALS_DISTINCT_COUNT_VIN_DESC',
  DealsDistinctCountYearAsc = 'DEALS_DISTINCT_COUNT_YEAR_ASC',
  DealsDistinctCountYearDesc = 'DEALS_DISTINCT_COUNT_YEAR_DESC',
  DealsDistinctCountMakeAsc = 'DEALS_DISTINCT_COUNT_MAKE_ASC',
  DealsDistinctCountMakeDesc = 'DEALS_DISTINCT_COUNT_MAKE_DESC',
  DealsDistinctCountModelAsc = 'DEALS_DISTINCT_COUNT_MODEL_ASC',
  DealsDistinctCountModelDesc = 'DEALS_DISTINCT_COUNT_MODEL_DESC',
  DealsDistinctCountModelNumberAsc = 'DEALS_DISTINCT_COUNT_MODEL_NUMBER_ASC',
  DealsDistinctCountModelNumberDesc = 'DEALS_DISTINCT_COUNT_MODEL_NUMBER_DESC',
  DealsDistinctCountMileageAsc = 'DEALS_DISTINCT_COUNT_MILEAGE_ASC',
  DealsDistinctCountMileageDesc = 'DEALS_DISTINCT_COUNT_MILEAGE_DESC',
  DealsDistinctCountDescriptionAsc = 'DEALS_DISTINCT_COUNT_DESCRIPTION_ASC',
  DealsDistinctCountDescriptionDesc = 'DEALS_DISTINCT_COUNT_DESCRIPTION_DESC',
  DealsDistinctCountExteriorColorAsc = 'DEALS_DISTINCT_COUNT_EXTERIOR_COLOR_ASC',
  DealsDistinctCountExteriorColorDesc = 'DEALS_DISTINCT_COUNT_EXTERIOR_COLOR_DESC',
  DealsDistinctCountNewUsedAsc = 'DEALS_DISTINCT_COUNT_NEW_USED_ASC',
  DealsDistinctCountNewUsedDesc = 'DEALS_DISTINCT_COUNT_NEW_USED_DESC',
  DealsDistinctCountStockNumberAsc = 'DEALS_DISTINCT_COUNT_STOCK_NUMBER_ASC',
  DealsDistinctCountStockNumberDesc = 'DEALS_DISTINCT_COUNT_STOCK_NUMBER_DESC',
  DealsDistinctCountTransmissionAsc = 'DEALS_DISTINCT_COUNT_TRANSMISSION_ASC',
  DealsDistinctCountTransmissionDesc = 'DEALS_DISTINCT_COUNT_TRANSMISSION_DESC',
  DealsDistinctCountEngineConfigurationAsc = 'DEALS_DISTINCT_COUNT_ENGINE_CONFIGURATION_ASC',
  DealsDistinctCountEngineConfigurationDesc = 'DEALS_DISTINCT_COUNT_ENGINE_CONFIGURATION_DESC',
  DealsDistinctCountTrimAsc = 'DEALS_DISTINCT_COUNT_TRIM_ASC',
  DealsDistinctCountTrimDesc = 'DEALS_DISTINCT_COUNT_TRIM_DESC',
  DealsDistinctCountEngineNumberAsc = 'DEALS_DISTINCT_COUNT_ENGINE_NUMBER_ASC',
  DealsDistinctCountEngineNumberDesc = 'DEALS_DISTINCT_COUNT_ENGINE_NUMBER_DESC',
  DealsDistinctCountChassisNumberAsc = 'DEALS_DISTINCT_COUNT_CHASSIS_NUMBER_ASC',
  DealsDistinctCountChassisNumberDesc = 'DEALS_DISTINCT_COUNT_CHASSIS_NUMBER_DESC',
  DealsDistinctCountLicensePlateNumberAsc = 'DEALS_DISTINCT_COUNT_LICENSE_PLATE_NUMBER_ASC',
  DealsDistinctCountLicensePlateNumberDesc = 'DEALS_DISTINCT_COUNT_LICENSE_PLATE_NUMBER_DESC',
  DealsDistinctCountDeliveryDateAsc = 'DEALS_DISTINCT_COUNT_DELIVERY_DATE_ASC',
  DealsDistinctCountDeliveryDateDesc = 'DEALS_DISTINCT_COUNT_DELIVERY_DATE_DESC',
  DealsDistinctCountDeliveryMileageAsc = 'DEALS_DISTINCT_COUNT_DELIVERY_MILEAGE_ASC',
  DealsDistinctCountDeliveryMileageDesc = 'DEALS_DISTINCT_COUNT_DELIVERY_MILEAGE_DESC',
  DealsDistinctCountInventoryDateAsc = 'DEALS_DISTINCT_COUNT_INVENTORY_DATE_ASC',
  DealsDistinctCountInventoryDateDesc = 'DEALS_DISTINCT_COUNT_INVENTORY_DATE_DESC',
  DealsDistinctCountInServiceDateAsc = 'DEALS_DISTINCT_COUNT_IN_SERVICE_DATE_ASC',
  DealsDistinctCountInServiceDateDesc = 'DEALS_DISTINCT_COUNT_IN_SERVICE_DATE_DESC',
  DealsDistinctCountVinExplosionYearAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_YEAR_ASC',
  DealsDistinctCountVinExplosionYearDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_YEAR_DESC',
  DealsDistinctCountVinExplosionMakeAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_MAKE_ASC',
  DealsDistinctCountVinExplosionMakeDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_MAKE_DESC',
  DealsDistinctCountVinExplosionModelAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_MODEL_ASC',
  DealsDistinctCountVinExplosionModelDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_MODEL_DESC',
  DealsDistinctCountVinExplosionTrimAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_TRIM_ASC',
  DealsDistinctCountVinExplosionTrimDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_TRIM_DESC',
  DealsDistinctCountVinExplosionTransmissionTypeAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsDistinctCountVinExplosionTransmissionTypeDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsDistinctCountVinExplosionFuelTypeAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsDistinctCountVinExplosionFuelTypeDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsDistinctCountVinExplosionEngineSizeAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsDistinctCountVinExplosionEngineSizeDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsDistinctCountVinExplosionGvwRangeAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsDistinctCountVinExplosionGvwRangeDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsDistinctCountTrade_1VinAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_VIN_ASC',
  DealsDistinctCountTrade_1VinDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_VIN_DESC',
  DealsDistinctCountTrade_1YearAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_YEAR_ASC',
  DealsDistinctCountTrade_1YearDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_YEAR_DESC',
  DealsDistinctCountTrade_1MakeAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_MAKE_ASC',
  DealsDistinctCountTrade_1MakeDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_MAKE_DESC',
  DealsDistinctCountTrade_1ModelAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_MODEL_ASC',
  DealsDistinctCountTrade_1ModelDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_MODEL_DESC',
  DealsDistinctCountTrade_1OdometerAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_ODOMETER_ASC',
  DealsDistinctCountTrade_1OdometerDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_ODOMETER_DESC',
  DealsDistinctCountTrade_1ActualCashValueAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsDistinctCountTrade_1ActualCashValueDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsDistinctCountTrade_1GrossAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_GROSS_ASC',
  DealsDistinctCountTrade_1GrossDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_GROSS_DESC',
  DealsDistinctCountTrade_1PayoffAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_PAYOFF_ASC',
  DealsDistinctCountTrade_1PayoffDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_PAYOFF_DESC',
  DealsDistinctCountTrade_2VinAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_VIN_ASC',
  DealsDistinctCountTrade_2VinDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_VIN_DESC',
  DealsDistinctCountTrade_2YearAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_YEAR_ASC',
  DealsDistinctCountTrade_2YearDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_YEAR_DESC',
  DealsDistinctCountTrade_2MakeAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_MAKE_ASC',
  DealsDistinctCountTrade_2MakeDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_MAKE_DESC',
  DealsDistinctCountTrade_2ModelAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_MODEL_ASC',
  DealsDistinctCountTrade_2ModelDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_MODEL_DESC',
  DealsDistinctCountTrade_2OdometerAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_ODOMETER_ASC',
  DealsDistinctCountTrade_2OdometerDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_ODOMETER_DESC',
  DealsDistinctCountTrade_2ActualCashValueAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsDistinctCountTrade_2ActualCashValueDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsDistinctCountTrade_2GrossAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_GROSS_ASC',
  DealsDistinctCountTrade_2GrossDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_GROSS_DESC',
  DealsDistinctCountTrade_2PayoffAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_PAYOFF_ASC',
  DealsDistinctCountTrade_2PayoffDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_PAYOFF_DESC',
  DealsDistinctCountSalesman_1NumberAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_NUMBER_ASC',
  DealsDistinctCountSalesman_1NumberDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_NUMBER_DESC',
  DealsDistinctCountSalesman_1NameAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_NAME_ASC',
  DealsDistinctCountSalesman_1NameDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_NAME_DESC',
  DealsDistinctCountSalesman_2NumberAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_NUMBER_ASC',
  DealsDistinctCountSalesman_2NumberDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_NUMBER_DESC',
  DealsDistinctCountSalesman_2NameAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_NAME_ASC',
  DealsDistinctCountSalesman_2NameDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_NAME_DESC',
  DealsDistinctCountSalesman_3NumberAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_NUMBER_ASC',
  DealsDistinctCountSalesman_3NumberDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_NUMBER_DESC',
  DealsDistinctCountSalesman_3NameAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_NAME_ASC',
  DealsDistinctCountSalesman_3NameDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_NAME_DESC',
  DealsDistinctCountClosingManagerNumberAsc = 'DEALS_DISTINCT_COUNT_CLOSING_MANAGER_NUMBER_ASC',
  DealsDistinctCountClosingManagerNumberDesc = 'DEALS_DISTINCT_COUNT_CLOSING_MANAGER_NUMBER_DESC',
  DealsDistinctCountClosingManagerNameAsc = 'DEALS_DISTINCT_COUNT_CLOSING_MANAGER_NAME_ASC',
  DealsDistinctCountClosingManagerNameDesc = 'DEALS_DISTINCT_COUNT_CLOSING_MANAGER_NAME_DESC',
  DealsDistinctCountFinanceManagerNumberAsc = 'DEALS_DISTINCT_COUNT_FINANCE_MANAGER_NUMBER_ASC',
  DealsDistinctCountFinanceManagerNumberDesc = 'DEALS_DISTINCT_COUNT_FINANCE_MANAGER_NUMBER_DESC',
  DealsDistinctCountFinanceManagerNameAsc = 'DEALS_DISTINCT_COUNT_FINANCE_MANAGER_NAME_ASC',
  DealsDistinctCountFinanceManagerNameDesc = 'DEALS_DISTINCT_COUNT_FINANCE_MANAGER_NAME_DESC',
  DealsDistinctCountSalesmanManagerNumberAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_MANAGER_NUMBER_ASC',
  DealsDistinctCountSalesmanManagerNumberDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_MANAGER_NUMBER_DESC',
  DealsDistinctCountSalesmanManagerNameAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_MANAGER_NAME_ASC',
  DealsDistinctCountSalesmanManagerNameDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_MANAGER_NAME_DESC',
  DealsDistinctCountMsrpAsc = 'DEALS_DISTINCT_COUNT_MSRP_ASC',
  DealsDistinctCountMsrpDesc = 'DEALS_DISTINCT_COUNT_MSRP_DESC',
  DealsDistinctCountListPriceAsc = 'DEALS_DISTINCT_COUNT_LIST_PRICE_ASC',
  DealsDistinctCountListPriceDesc = 'DEALS_DISTINCT_COUNT_LIST_PRICE_DESC',
  DealsDistinctCountSalesPriceAsc = 'DEALS_DISTINCT_COUNT_SALES_PRICE_ASC',
  DealsDistinctCountSalesPriceDesc = 'DEALS_DISTINCT_COUNT_SALES_PRICE_DESC',
  DealsDistinctCountJournalPriceAsc = 'DEALS_DISTINCT_COUNT_JOURNAL_PRICE_ASC',
  DealsDistinctCountJournalPriceDesc = 'DEALS_DISTINCT_COUNT_JOURNAL_PRICE_DESC',
  DealsDistinctCountCostAsc = 'DEALS_DISTINCT_COUNT_COST_ASC',
  DealsDistinctCountCostDesc = 'DEALS_DISTINCT_COUNT_COST_DESC',
  DealsDistinctCountJournalCostAsc = 'DEALS_DISTINCT_COUNT_JOURNAL_COST_ASC',
  DealsDistinctCountJournalCostDesc = 'DEALS_DISTINCT_COUNT_JOURNAL_COST_DESC',
  DealsDistinctCountAdjustmentsAsc = 'DEALS_DISTINCT_COUNT_ADJUSTMENTS_ASC',
  DealsDistinctCountAdjustmentsDesc = 'DEALS_DISTINCT_COUNT_ADJUSTMENTS_DESC',
  DealsDistinctCountAdjustedCostAsc = 'DEALS_DISTINCT_COUNT_ADJUSTED_COST_ASC',
  DealsDistinctCountAdjustedCostDesc = 'DEALS_DISTINCT_COUNT_ADJUSTED_COST_DESC',
  DealsDistinctCountIncentivesAsc = 'DEALS_DISTINCT_COUNT_INCENTIVES_ASC',
  DealsDistinctCountIncentivesDesc = 'DEALS_DISTINCT_COUNT_INCENTIVES_DESC',
  DealsDistinctCountPackAmountAsc = 'DEALS_DISTINCT_COUNT_PACK_AMOUNT_ASC',
  DealsDistinctCountPackAmountDesc = 'DEALS_DISTINCT_COUNT_PACK_AMOUNT_DESC',
  DealsDistinctCountSaleNetAsc = 'DEALS_DISTINCT_COUNT_SALE_NET_ASC',
  DealsDistinctCountSaleNetDesc = 'DEALS_DISTINCT_COUNT_SALE_NET_DESC',
  DealsDistinctCountTotalTradeActualCashValueAsc = 'DEALS_DISTINCT_COUNT_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsDistinctCountTotalTradeActualCashValueDesc = 'DEALS_DISTINCT_COUNT_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsDistinctCountTotalTradeGrossAsc = 'DEALS_DISTINCT_COUNT_TOTAL_TRADE_GROSS_ASC',
  DealsDistinctCountTotalTradeGrossDesc = 'DEALS_DISTINCT_COUNT_TOTAL_TRADE_GROSS_DESC',
  DealsDistinctCountWeOweFrontAsc = 'DEALS_DISTINCT_COUNT_WE_OWE_FRONT_ASC',
  DealsDistinctCountWeOweFrontDesc = 'DEALS_DISTINCT_COUNT_WE_OWE_FRONT_DESC',
  DealsDistinctCountTotalFrontFeesAftermarketProfitAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsDistinctCountTotalFrontFeesAftermarketProfitDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsDistinctCountTotalFrontCommissionAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_COMMISSION_ASC',
  DealsDistinctCountTotalFrontCommissionDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_COMMISSION_DESC',
  DealsDistinctCountTotalFrontSalesAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_SALES_ASC',
  DealsDistinctCountTotalFrontSalesDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_SALES_DESC',
  DealsDistinctCountTotalFrontCostAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_COST_ASC',
  DealsDistinctCountTotalFrontCostDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_COST_DESC',
  DealsDistinctCountFrontGrossAsc = 'DEALS_DISTINCT_COUNT_FRONT_GROSS_ASC',
  DealsDistinctCountFrontGrossDesc = 'DEALS_DISTINCT_COUNT_FRONT_GROSS_DESC',
  DealsDistinctCountFinanceProfitAsc = 'DEALS_DISTINCT_COUNT_FINANCE_PROFIT_ASC',
  DealsDistinctCountFinanceProfitDesc = 'DEALS_DISTINCT_COUNT_FINANCE_PROFIT_DESC',
  DealsDistinctCountTotalWarrantyProfitAsc = 'DEALS_DISTINCT_COUNT_TOTAL_WARRANTY_PROFIT_ASC',
  DealsDistinctCountTotalWarrantyProfitDesc = 'DEALS_DISTINCT_COUNT_TOTAL_WARRANTY_PROFIT_DESC',
  DealsDistinctCountWeOweBackAsc = 'DEALS_DISTINCT_COUNT_WE_OWE_BACK_ASC',
  DealsDistinctCountWeOweBackDesc = 'DEALS_DISTINCT_COUNT_WE_OWE_BACK_DESC',
  DealsDistinctCountInsuranceProfitAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_PROFIT_ASC',
  DealsDistinctCountInsuranceProfitDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_PROFIT_DESC',
  DealsDistinctCountTotalBackFeesAftermarketProfitAsc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsDistinctCountTotalBackFeesAftermarketProfitDesc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsDistinctCountFinanceReserveAsc = 'DEALS_DISTINCT_COUNT_FINANCE_RESERVE_ASC',
  DealsDistinctCountFinanceReserveDesc = 'DEALS_DISTINCT_COUNT_FINANCE_RESERVE_DESC',
  DealsDistinctCountTotalBackCommissionAsc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_COMMISSION_ASC',
  DealsDistinctCountTotalBackCommissionDesc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_COMMISSION_DESC',
  DealsDistinctCountTotalBackSalesAsc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_SALES_ASC',
  DealsDistinctCountTotalBackSalesDesc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_SALES_DESC',
  DealsDistinctCountTotalBackCostAsc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_COST_ASC',
  DealsDistinctCountTotalBackCostDesc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_COST_DESC',
  DealsDistinctCountBackGrossAsc = 'DEALS_DISTINCT_COUNT_BACK_GROSS_ASC',
  DealsDistinctCountBackGrossDesc = 'DEALS_DISTINCT_COUNT_BACK_GROSS_DESC',
  DealsDistinctCountTotalProfitAsc = 'DEALS_DISTINCT_COUNT_TOTAL_PROFIT_ASC',
  DealsDistinctCountTotalProfitDesc = 'DEALS_DISTINCT_COUNT_TOTAL_PROFIT_DESC',
  DealsDistinctCountGrossProfitAsc = 'DEALS_DISTINCT_COUNT_GROSS_PROFIT_ASC',
  DealsDistinctCountGrossProfitDesc = 'DEALS_DISTINCT_COUNT_GROSS_PROFIT_DESC',
  DealsDistinctCountGrossPayableAsc = 'DEALS_DISTINCT_COUNT_GROSS_PAYABLE_ASC',
  DealsDistinctCountGrossPayableDesc = 'DEALS_DISTINCT_COUNT_GROSS_PAYABLE_DESC',
  DealsDistinctCountDealStatusAsc = 'DEALS_DISTINCT_COUNT_DEAL_STATUS_ASC',
  DealsDistinctCountDealStatusDesc = 'DEALS_DISTINCT_COUNT_DEAL_STATUS_DESC',
  DealsDistinctCountEntryDateAsc = 'DEALS_DISTINCT_COUNT_ENTRY_DATE_ASC',
  DealsDistinctCountEntryDateDesc = 'DEALS_DISTINCT_COUNT_ENTRY_DATE_DESC',
  DealsDistinctCountBookedDateAsc = 'DEALS_DISTINCT_COUNT_BOOKED_DATE_ASC',
  DealsDistinctCountBookedDateDesc = 'DEALS_DISTINCT_COUNT_BOOKED_DATE_DESC',
  DealsDistinctCountFinalizedDateAsc = 'DEALS_DISTINCT_COUNT_FINALIZED_DATE_ASC',
  DealsDistinctCountFinalizedDateDesc = 'DEALS_DISTINCT_COUNT_FINALIZED_DATE_DESC',
  DealsDistinctCountContractDateAsc = 'DEALS_DISTINCT_COUNT_CONTRACT_DATE_ASC',
  DealsDistinctCountContractDateDesc = 'DEALS_DISTINCT_COUNT_CONTRACT_DATE_DESC',
  DealsDistinctCountAccountingDateAsc = 'DEALS_DISTINCT_COUNT_ACCOUNTING_DATE_ASC',
  DealsDistinctCountAccountingDateDesc = 'DEALS_DISTINCT_COUNT_ACCOUNTING_DATE_DESC',
  DealsDistinctCountStatusChangeDateAsc = 'DEALS_DISTINCT_COUNT_STATUS_CHANGE_DATE_ASC',
  DealsDistinctCountStatusChangeDateDesc = 'DEALS_DISTINCT_COUNT_STATUS_CHANGE_DATE_DESC',
  DealsDistinctCountFirstPayDateAsc = 'DEALS_DISTINCT_COUNT_FIRST_PAY_DATE_ASC',
  DealsDistinctCountFirstPayDateDesc = 'DEALS_DISTINCT_COUNT_FIRST_PAY_DATE_DESC',
  DealsDistinctCountDealTypeAsc = 'DEALS_DISTINCT_COUNT_DEAL_TYPE_ASC',
  DealsDistinctCountDealTypeDesc = 'DEALS_DISTINCT_COUNT_DEAL_TYPE_DESC',
  DealsDistinctCountSaleTypeAsc = 'DEALS_DISTINCT_COUNT_SALE_TYPE_ASC',
  DealsDistinctCountSaleTypeDesc = 'DEALS_DISTINCT_COUNT_SALE_TYPE_DESC',
  DealsDistinctCountBankIdAsc = 'DEALS_DISTINCT_COUNT_BANK_ID_ASC',
  DealsDistinctCountBankIdDesc = 'DEALS_DISTINCT_COUNT_BANK_ID_DESC',
  DealsDistinctCountBankNameAsc = 'DEALS_DISTINCT_COUNT_BANK_NAME_ASC',
  DealsDistinctCountBankNameDesc = 'DEALS_DISTINCT_COUNT_BANK_NAME_DESC',
  DealsDistinctCountBankAddressAsc = 'DEALS_DISTINCT_COUNT_BANK_ADDRESS_ASC',
  DealsDistinctCountBankAddressDesc = 'DEALS_DISTINCT_COUNT_BANK_ADDRESS_DESC',
  DealsDistinctCountTermAsc = 'DEALS_DISTINCT_COUNT_TERM_ASC',
  DealsDistinctCountTermDesc = 'DEALS_DISTINCT_COUNT_TERM_DESC',
  DealsDistinctCountAmountFinancedAsc = 'DEALS_DISTINCT_COUNT_AMOUNT_FINANCED_ASC',
  DealsDistinctCountAmountFinancedDesc = 'DEALS_DISTINCT_COUNT_AMOUNT_FINANCED_DESC',
  DealsDistinctCountAprAsc = 'DEALS_DISTINCT_COUNT_APR_ASC',
  DealsDistinctCountAprDesc = 'DEALS_DISTINCT_COUNT_APR_DESC',
  DealsDistinctCountMonthlyPaymentAsc = 'DEALS_DISTINCT_COUNT_MONTHLY_PAYMENT_ASC',
  DealsDistinctCountMonthlyPaymentDesc = 'DEALS_DISTINCT_COUNT_MONTHLY_PAYMENT_DESC',
  DealsDistinctCountPaymentTotalAsc = 'DEALS_DISTINCT_COUNT_PAYMENT_TOTAL_ASC',
  DealsDistinctCountPaymentTotalDesc = 'DEALS_DISTINCT_COUNT_PAYMENT_TOTAL_DESC',
  DealsDistinctCountRebatesAsc = 'DEALS_DISTINCT_COUNT_REBATES_ASC',
  DealsDistinctCountRebatesDesc = 'DEALS_DISTINCT_COUNT_REBATES_DESC',
  DealsDistinctCountDepositAsc = 'DEALS_DISTINCT_COUNT_DEPOSIT_ASC',
  DealsDistinctCountDepositDesc = 'DEALS_DISTINCT_COUNT_DEPOSIT_DESC',
  DealsDistinctCountDownPaymentAsc = 'DEALS_DISTINCT_COUNT_DOWN_PAYMENT_ASC',
  DealsDistinctCountDownPaymentDesc = 'DEALS_DISTINCT_COUNT_DOWN_PAYMENT_DESC',
  DealsDistinctCountTotalNetTradesAsc = 'DEALS_DISTINCT_COUNT_TOTAL_NET_TRADES_ASC',
  DealsDistinctCountTotalNetTradesDesc = 'DEALS_DISTINCT_COUNT_TOTAL_NET_TRADES_DESC',
  DealsDistinctCountTotalDownAsc = 'DEALS_DISTINCT_COUNT_TOTAL_DOWN_ASC',
  DealsDistinctCountTotalDownDesc = 'DEALS_DISTINCT_COUNT_TOTAL_DOWN_DESC',
  DealsDistinctCountBalloonAmountAsc = 'DEALS_DISTINCT_COUNT_BALLOON_AMOUNT_ASC',
  DealsDistinctCountBalloonAmountDesc = 'DEALS_DISTINCT_COUNT_BALLOON_AMOUNT_DESC',
  DealsDistinctCountAdjustedBalloonAmountAsc = 'DEALS_DISTINCT_COUNT_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsDistinctCountAdjustedBalloonAmountDesc = 'DEALS_DISTINCT_COUNT_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsDistinctCountHoldbackAmountAsc = 'DEALS_DISTINCT_COUNT_HOLDBACK_AMOUNT_ASC',
  DealsDistinctCountHoldbackAmountDesc = 'DEALS_DISTINCT_COUNT_HOLDBACK_AMOUNT_DESC',
  DealsDistinctCountTotalDriveOffAmountAsc = 'DEALS_DISTINCT_COUNT_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsDistinctCountTotalDriveOffAmountDesc = 'DEALS_DISTINCT_COUNT_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsDistinctCountLicenseFeeAsc = 'DEALS_DISTINCT_COUNT_LICENSE_FEE_ASC',
  DealsDistinctCountLicenseFeeDesc = 'DEALS_DISTINCT_COUNT_LICENSE_FEE_DESC',
  DealsDistinctCountRegistrationFeeAsc = 'DEALS_DISTINCT_COUNT_REGISTRATION_FEE_ASC',
  DealsDistinctCountRegistrationFeeDesc = 'DEALS_DISTINCT_COUNT_REGISTRATION_FEE_DESC',
  DealsDistinctCountDocumentationFeeAsc = 'DEALS_DISTINCT_COUNT_DOCUMENTATION_FEE_ASC',
  DealsDistinctCountDocumentationFeeDesc = 'DEALS_DISTINCT_COUNT_DOCUMENTATION_FEE_DESC',
  DealsDistinctCountFinanceChargeAsc = 'DEALS_DISTINCT_COUNT_FINANCE_CHARGE_ASC',
  DealsDistinctCountFinanceChargeDesc = 'DEALS_DISTINCT_COUNT_FINANCE_CHARGE_DESC',
  DealsDistinctCountTotalPickupPaymentsAsc = 'DEALS_DISTINCT_COUNT_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsDistinctCountTotalPickupPaymentsDesc = 'DEALS_DISTINCT_COUNT_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsDistinctCountSellRateAsc = 'DEALS_DISTINCT_COUNT_SELL_RATE_ASC',
  DealsDistinctCountSellRateDesc = 'DEALS_DISTINCT_COUNT_SELL_RATE_DESC',
  DealsDistinctCountBuyRateAsc = 'DEALS_DISTINCT_COUNT_BUY_RATE_ASC',
  DealsDistinctCountBuyRateDesc = 'DEALS_DISTINCT_COUNT_BUY_RATE_DESC',
  DealsDistinctCountResidualRateAsc = 'DEALS_DISTINCT_COUNT_RESIDUAL_RATE_ASC',
  DealsDistinctCountResidualRateDesc = 'DEALS_DISTINCT_COUNT_RESIDUAL_RATE_DESC',
  DealsDistinctCountResidualAmountAsc = 'DEALS_DISTINCT_COUNT_RESIDUAL_AMOUNT_ASC',
  DealsDistinctCountResidualAmountDesc = 'DEALS_DISTINCT_COUNT_RESIDUAL_AMOUNT_DESC',
  DealsDistinctCountAllowedMilesAsc = 'DEALS_DISTINCT_COUNT_ALLOWED_MILES_ASC',
  DealsDistinctCountAllowedMilesDesc = 'DEALS_DISTINCT_COUNT_ALLOWED_MILES_DESC',
  DealsDistinctCountEstimatedMilesAsc = 'DEALS_DISTINCT_COUNT_ESTIMATED_MILES_ASC',
  DealsDistinctCountEstimatedMilesDesc = 'DEALS_DISTINCT_COUNT_ESTIMATED_MILES_DESC',
  DealsDistinctCountMileageRateAsc = 'DEALS_DISTINCT_COUNT_MILEAGE_RATE_ASC',
  DealsDistinctCountMileageRateDesc = 'DEALS_DISTINCT_COUNT_MILEAGE_RATE_DESC',
  DealsDistinctCountAcquisitionFeeAsc = 'DEALS_DISTINCT_COUNT_ACQUISITION_FEE_ASC',
  DealsDistinctCountAcquisitionFeeDesc = 'DEALS_DISTINCT_COUNT_ACQUISITION_FEE_DESC',
  DealsDistinctCountBasePaymentAsc = 'DEALS_DISTINCT_COUNT_BASE_PAYMENT_ASC',
  DealsDistinctCountBasePaymentDesc = 'DEALS_DISTINCT_COUNT_BASE_PAYMENT_DESC',
  DealsDistinctCountSecurityDepositAsc = 'DEALS_DISTINCT_COUNT_SECURITY_DEPOSIT_ASC',
  DealsDistinctCountSecurityDepositDesc = 'DEALS_DISTINCT_COUNT_SECURITY_DEPOSIT_DESC',
  DealsDistinctCountTotalCapitalReductionAsc = 'DEALS_DISTINCT_COUNT_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsDistinctCountTotalCapitalReductionDesc = 'DEALS_DISTINCT_COUNT_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsDistinctCountNetCapitalCostAsc = 'DEALS_DISTINCT_COUNT_NET_CAPITAL_COST_ASC',
  DealsDistinctCountNetCapitalCostDesc = 'DEALS_DISTINCT_COUNT_NET_CAPITAL_COST_DESC',
  DealsDistinctCountLeaseDepreciationValueAsc = 'DEALS_DISTINCT_COUNT_LEASE_DEPRECIATION_VALUE_ASC',
  DealsDistinctCountLeaseDepreciationValueDesc = 'DEALS_DISTINCT_COUNT_LEASE_DEPRECIATION_VALUE_DESC',
  DealsDistinctCountDealerFeesAsc = 'DEALS_DISTINCT_COUNT_DEALER_FEES_ASC',
  DealsDistinctCountDealerFeesDesc = 'DEALS_DISTINCT_COUNT_DEALER_FEES_DESC',
  DealsDistinctCountGovernmentFeesAsc = 'DEALS_DISTINCT_COUNT_GOVERNMENT_FEES_ASC',
  DealsDistinctCountGovernmentFeesDesc = 'DEALS_DISTINCT_COUNT_GOVERNMENT_FEES_DESC',
  DealsDistinctCountTotalTaxAsc = 'DEALS_DISTINCT_COUNT_TOTAL_TAX_ASC',
  DealsDistinctCountTotalTaxDesc = 'DEALS_DISTINCT_COUNT_TOTAL_TAX_DESC',
  DealsDistinctCountRegistrationStateAsc = 'DEALS_DISTINCT_COUNT_REGISTRATION_STATE_ASC',
  DealsDistinctCountRegistrationStateDesc = 'DEALS_DISTINCT_COUNT_REGISTRATION_STATE_DESC',
  DealsDistinctCountReportOfSaleNumberAsc = 'DEALS_DISTINCT_COUNT_REPORT_OF_SALE_NUMBER_ASC',
  DealsDistinctCountReportOfSaleNumberDesc = 'DEALS_DISTINCT_COUNT_REPORT_OF_SALE_NUMBER_DESC',
  DealsDistinctCountSalesman_1TotalCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsDistinctCountSalesman_1TotalCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsDistinctCountSalesman_1FrontCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsDistinctCountSalesman_1FrontCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsDistinctCountSalesman_1BackCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsDistinctCountSalesman_1BackCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsDistinctCountSalesman_2TotalCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsDistinctCountSalesman_2TotalCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsDistinctCountSalesman_2FrontCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsDistinctCountSalesman_2FrontCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsDistinctCountSalesman_2BackCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsDistinctCountSalesman_2BackCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsDistinctCountSalesman_3TotalCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsDistinctCountSalesman_3TotalCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsDistinctCountSalesman_3FrontCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsDistinctCountSalesman_3FrontCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsDistinctCountSalesman_3BackCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsDistinctCountSalesman_3BackCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsDistinctCountWarranty_1NameAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_NAME_ASC',
  DealsDistinctCountWarranty_1NameDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_NAME_DESC',
  DealsDistinctCountWarranty_1SaleAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_SALE_ASC',
  DealsDistinctCountWarranty_1SaleDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_SALE_DESC',
  DealsDistinctCountWarranty_1CostAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_COST_ASC',
  DealsDistinctCountWarranty_1CostDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_COST_DESC',
  DealsDistinctCountWarranty_1MilesAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_MILES_ASC',
  DealsDistinctCountWarranty_1MilesDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_MILES_DESC',
  DealsDistinctCountWarranty_1TermAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_TERM_ASC',
  DealsDistinctCountWarranty_1TermDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_TERM_DESC',
  DealsDistinctCountWarranty_2NameAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_NAME_ASC',
  DealsDistinctCountWarranty_2NameDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_NAME_DESC',
  DealsDistinctCountWarranty_2SaleAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_SALE_ASC',
  DealsDistinctCountWarranty_2SaleDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_SALE_DESC',
  DealsDistinctCountWarranty_2CostAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_COST_ASC',
  DealsDistinctCountWarranty_2CostDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_COST_DESC',
  DealsDistinctCountWarranty_2MilesAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_MILES_ASC',
  DealsDistinctCountWarranty_2MilesDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_MILES_DESC',
  DealsDistinctCountWarranty_2TermAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_TERM_ASC',
  DealsDistinctCountWarranty_2TermDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_TERM_DESC',
  DealsDistinctCountWarranty_3NameAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_NAME_ASC',
  DealsDistinctCountWarranty_3NameDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_NAME_DESC',
  DealsDistinctCountWarranty_3SaleAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_SALE_ASC',
  DealsDistinctCountWarranty_3SaleDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_SALE_DESC',
  DealsDistinctCountWarranty_3CostAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_COST_ASC',
  DealsDistinctCountWarranty_3CostDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_COST_DESC',
  DealsDistinctCountWarranty_3MilesAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_MILES_ASC',
  DealsDistinctCountWarranty_3MilesDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_MILES_DESC',
  DealsDistinctCountWarranty_3TermAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_TERM_ASC',
  DealsDistinctCountWarranty_3TermDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_TERM_DESC',
  DealsDistinctCountWarranty_4NameAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_NAME_ASC',
  DealsDistinctCountWarranty_4NameDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_NAME_DESC',
  DealsDistinctCountWarranty_4SaleAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_SALE_ASC',
  DealsDistinctCountWarranty_4SaleDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_SALE_DESC',
  DealsDistinctCountWarranty_4CostAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_COST_ASC',
  DealsDistinctCountWarranty_4CostDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_COST_DESC',
  DealsDistinctCountWarranty_4MilesAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_MILES_ASC',
  DealsDistinctCountWarranty_4MilesDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_MILES_DESC',
  DealsDistinctCountWarranty_4TermAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_TERM_ASC',
  DealsDistinctCountWarranty_4TermDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_TERM_DESC',
  DealsDistinctCountWarranty_5NameAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_NAME_ASC',
  DealsDistinctCountWarranty_5NameDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_NAME_DESC',
  DealsDistinctCountWarranty_5SaleAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_SALE_ASC',
  DealsDistinctCountWarranty_5SaleDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_SALE_DESC',
  DealsDistinctCountWarranty_5CostAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_COST_ASC',
  DealsDistinctCountWarranty_5CostDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_COST_DESC',
  DealsDistinctCountWarranty_5MilesAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_MILES_ASC',
  DealsDistinctCountWarranty_5MilesDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_MILES_DESC',
  DealsDistinctCountWarranty_5TermAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_TERM_ASC',
  DealsDistinctCountWarranty_5TermDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_TERM_DESC',
  DealsDistinctCountTotalFeeAftermarketSaleAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsDistinctCountTotalFeeAftermarketSaleDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsDistinctCountTotalFeeAftermarketCostAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsDistinctCountTotalFeeAftermarketCostDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsDistinctCountFeeAftermarket_1NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_NAME_ASC',
  DealsDistinctCountFeeAftermarket_1NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_NAME_DESC',
  DealsDistinctCountFeeAftermarket_1SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_SALE_ASC',
  DealsDistinctCountFeeAftermarket_1SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_SALE_DESC',
  DealsDistinctCountFeeAftermarket_1CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_COST_ASC',
  DealsDistinctCountFeeAftermarket_1CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_COST_DESC',
  DealsDistinctCountFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_2NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_NAME_ASC',
  DealsDistinctCountFeeAftermarket_2NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_NAME_DESC',
  DealsDistinctCountFeeAftermarket_2SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_SALE_ASC',
  DealsDistinctCountFeeAftermarket_2SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_SALE_DESC',
  DealsDistinctCountFeeAftermarket_2CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_COST_ASC',
  DealsDistinctCountFeeAftermarket_2CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_COST_DESC',
  DealsDistinctCountFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_3NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_NAME_ASC',
  DealsDistinctCountFeeAftermarket_3NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_NAME_DESC',
  DealsDistinctCountFeeAftermarket_3SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_SALE_ASC',
  DealsDistinctCountFeeAftermarket_3SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_SALE_DESC',
  DealsDistinctCountFeeAftermarket_3CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_COST_ASC',
  DealsDistinctCountFeeAftermarket_3CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_COST_DESC',
  DealsDistinctCountFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_4NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_NAME_ASC',
  DealsDistinctCountFeeAftermarket_4NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_NAME_DESC',
  DealsDistinctCountFeeAftermarket_4SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_SALE_ASC',
  DealsDistinctCountFeeAftermarket_4SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_SALE_DESC',
  DealsDistinctCountFeeAftermarket_4CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_COST_ASC',
  DealsDistinctCountFeeAftermarket_4CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_COST_DESC',
  DealsDistinctCountFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_5NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_NAME_ASC',
  DealsDistinctCountFeeAftermarket_5NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_NAME_DESC',
  DealsDistinctCountFeeAftermarket_5SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_SALE_ASC',
  DealsDistinctCountFeeAftermarket_5SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_SALE_DESC',
  DealsDistinctCountFeeAftermarket_5CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_COST_ASC',
  DealsDistinctCountFeeAftermarket_5CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_COST_DESC',
  DealsDistinctCountFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_6NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_NAME_ASC',
  DealsDistinctCountFeeAftermarket_6NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_NAME_DESC',
  DealsDistinctCountFeeAftermarket_6SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_SALE_ASC',
  DealsDistinctCountFeeAftermarket_6SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_SALE_DESC',
  DealsDistinctCountFeeAftermarket_6CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_COST_ASC',
  DealsDistinctCountFeeAftermarket_6CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_COST_DESC',
  DealsDistinctCountFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_7NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_NAME_ASC',
  DealsDistinctCountFeeAftermarket_7NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_NAME_DESC',
  DealsDistinctCountFeeAftermarket_7SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_SALE_ASC',
  DealsDistinctCountFeeAftermarket_7SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_SALE_DESC',
  DealsDistinctCountFeeAftermarket_7CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_COST_ASC',
  DealsDistinctCountFeeAftermarket_7CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_COST_DESC',
  DealsDistinctCountFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_8NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_NAME_ASC',
  DealsDistinctCountFeeAftermarket_8NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_NAME_DESC',
  DealsDistinctCountFeeAftermarket_8SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_SALE_ASC',
  DealsDistinctCountFeeAftermarket_8SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_SALE_DESC',
  DealsDistinctCountFeeAftermarket_8CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_COST_ASC',
  DealsDistinctCountFeeAftermarket_8CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_COST_DESC',
  DealsDistinctCountFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_9NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_NAME_ASC',
  DealsDistinctCountFeeAftermarket_9NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_NAME_DESC',
  DealsDistinctCountFeeAftermarket_9SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_SALE_ASC',
  DealsDistinctCountFeeAftermarket_9SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_SALE_DESC',
  DealsDistinctCountFeeAftermarket_9CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_COST_ASC',
  DealsDistinctCountFeeAftermarket_9CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_COST_DESC',
  DealsDistinctCountFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_10NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_NAME_ASC',
  DealsDistinctCountFeeAftermarket_10NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_NAME_DESC',
  DealsDistinctCountFeeAftermarket_10SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_SALE_ASC',
  DealsDistinctCountFeeAftermarket_10SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_SALE_DESC',
  DealsDistinctCountFeeAftermarket_10CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_COST_ASC',
  DealsDistinctCountFeeAftermarket_10CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_COST_DESC',
  DealsDistinctCountFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_11NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_NAME_ASC',
  DealsDistinctCountFeeAftermarket_11NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_NAME_DESC',
  DealsDistinctCountFeeAftermarket_11SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_SALE_ASC',
  DealsDistinctCountFeeAftermarket_11SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_SALE_DESC',
  DealsDistinctCountFeeAftermarket_11CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_COST_ASC',
  DealsDistinctCountFeeAftermarket_11CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_COST_DESC',
  DealsDistinctCountFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_12NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_NAME_ASC',
  DealsDistinctCountFeeAftermarket_12NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_NAME_DESC',
  DealsDistinctCountFeeAftermarket_12SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_SALE_ASC',
  DealsDistinctCountFeeAftermarket_12SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_SALE_DESC',
  DealsDistinctCountFeeAftermarket_12CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_COST_ASC',
  DealsDistinctCountFeeAftermarket_12CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_COST_DESC',
  DealsDistinctCountFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_13NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_NAME_ASC',
  DealsDistinctCountFeeAftermarket_13NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_NAME_DESC',
  DealsDistinctCountFeeAftermarket_13SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_SALE_ASC',
  DealsDistinctCountFeeAftermarket_13SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_SALE_DESC',
  DealsDistinctCountFeeAftermarket_13CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_COST_ASC',
  DealsDistinctCountFeeAftermarket_13CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_COST_DESC',
  DealsDistinctCountFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_14NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_NAME_ASC',
  DealsDistinctCountFeeAftermarket_14NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_NAME_DESC',
  DealsDistinctCountFeeAftermarket_14SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_SALE_ASC',
  DealsDistinctCountFeeAftermarket_14SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_SALE_DESC',
  DealsDistinctCountFeeAftermarket_14CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_COST_ASC',
  DealsDistinctCountFeeAftermarket_14CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_COST_DESC',
  DealsDistinctCountFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_15NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_NAME_ASC',
  DealsDistinctCountFeeAftermarket_15NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_NAME_DESC',
  DealsDistinctCountFeeAftermarket_15SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_SALE_ASC',
  DealsDistinctCountFeeAftermarket_15SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_SALE_DESC',
  DealsDistinctCountFeeAftermarket_15CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_COST_ASC',
  DealsDistinctCountFeeAftermarket_15CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_COST_DESC',
  DealsDistinctCountFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_16NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_NAME_ASC',
  DealsDistinctCountFeeAftermarket_16NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_NAME_DESC',
  DealsDistinctCountFeeAftermarket_16SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_SALE_ASC',
  DealsDistinctCountFeeAftermarket_16SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_SALE_DESC',
  DealsDistinctCountFeeAftermarket_16CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_COST_ASC',
  DealsDistinctCountFeeAftermarket_16CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_COST_DESC',
  DealsDistinctCountFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_17NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_NAME_ASC',
  DealsDistinctCountFeeAftermarket_17NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_NAME_DESC',
  DealsDistinctCountFeeAftermarket_17SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_SALE_ASC',
  DealsDistinctCountFeeAftermarket_17SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_SALE_DESC',
  DealsDistinctCountFeeAftermarket_17CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_COST_ASC',
  DealsDistinctCountFeeAftermarket_17CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_COST_DESC',
  DealsDistinctCountFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_18NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_NAME_ASC',
  DealsDistinctCountFeeAftermarket_18NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_NAME_DESC',
  DealsDistinctCountFeeAftermarket_18SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_SALE_ASC',
  DealsDistinctCountFeeAftermarket_18SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_SALE_DESC',
  DealsDistinctCountFeeAftermarket_18CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_COST_ASC',
  DealsDistinctCountFeeAftermarket_18CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_COST_DESC',
  DealsDistinctCountFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_19NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_NAME_ASC',
  DealsDistinctCountFeeAftermarket_19NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_NAME_DESC',
  DealsDistinctCountFeeAftermarket_19SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_SALE_ASC',
  DealsDistinctCountFeeAftermarket_19SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_SALE_DESC',
  DealsDistinctCountFeeAftermarket_19CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_COST_ASC',
  DealsDistinctCountFeeAftermarket_19CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_COST_DESC',
  DealsDistinctCountFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_20NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_NAME_ASC',
  DealsDistinctCountFeeAftermarket_20NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_NAME_DESC',
  DealsDistinctCountFeeAftermarket_20SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_SALE_ASC',
  DealsDistinctCountFeeAftermarket_20SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_SALE_DESC',
  DealsDistinctCountFeeAftermarket_20CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_COST_ASC',
  DealsDistinctCountFeeAftermarket_20CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_COST_DESC',
  DealsDistinctCountFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsDistinctCountInsurance_1TypeAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_TYPE_ASC',
  DealsDistinctCountInsurance_1TypeDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_TYPE_DESC',
  DealsDistinctCountInsurance_1NameAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_NAME_ASC',
  DealsDistinctCountInsurance_1NameDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_NAME_DESC',
  DealsDistinctCountInsurance_1SaleAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_SALE_ASC',
  DealsDistinctCountInsurance_1SaleDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_SALE_DESC',
  DealsDistinctCountInsurance_1CostAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_COST_ASC',
  DealsDistinctCountInsurance_1CostDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_COST_DESC',
  DealsDistinctCountInsurance_1TermAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_TERM_ASC',
  DealsDistinctCountInsurance_1TermDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_TERM_DESC',
  DealsDistinctCountInsurance_2TypeAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_TYPE_ASC',
  DealsDistinctCountInsurance_2TypeDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_TYPE_DESC',
  DealsDistinctCountInsurance_2NameAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_NAME_ASC',
  DealsDistinctCountInsurance_2NameDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_NAME_DESC',
  DealsDistinctCountInsurance_2SaleAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_SALE_ASC',
  DealsDistinctCountInsurance_2SaleDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_SALE_DESC',
  DealsDistinctCountInsurance_2CostAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_COST_ASC',
  DealsDistinctCountInsurance_2CostDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_COST_DESC',
  DealsDistinctCountInsurance_2TermAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_TERM_ASC',
  DealsDistinctCountInsurance_2TermDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_TERM_DESC',
  DealsDistinctCountInsurance_3TypeAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_TYPE_ASC',
  DealsDistinctCountInsurance_3TypeDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_TYPE_DESC',
  DealsDistinctCountInsurance_3NameAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_NAME_ASC',
  DealsDistinctCountInsurance_3NameDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_NAME_DESC',
  DealsDistinctCountInsurance_3SaleAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_SALE_ASC',
  DealsDistinctCountInsurance_3SaleDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_SALE_DESC',
  DealsDistinctCountInsurance_3CostAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_COST_ASC',
  DealsDistinctCountInsurance_3CostDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_COST_DESC',
  DealsDistinctCountInsurance_3TermAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_TERM_ASC',
  DealsDistinctCountInsurance_3TermDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_TERM_DESC',
  DealsDistinctCountAccidentalHealthTypeAsc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsDistinctCountAccidentalHealthTypeDesc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsDistinctCountAccidentalHealthNameAsc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsDistinctCountAccidentalHealthNameDesc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsDistinctCountAccidentalHealthSaleAsc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsDistinctCountAccidentalHealthSaleDesc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsDistinctCountAccidentalHealthCostAsc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_COST_ASC',
  DealsDistinctCountAccidentalHealthCostDesc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_COST_DESC',
  DealsDistinctCountAccidentalHealthTermAsc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsDistinctCountAccidentalHealthTermDesc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsDistinctCountCreditLifeTypeAsc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_TYPE_ASC',
  DealsDistinctCountCreditLifeTypeDesc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_TYPE_DESC',
  DealsDistinctCountCreditLifeNameAsc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_NAME_ASC',
  DealsDistinctCountCreditLifeNameDesc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_NAME_DESC',
  DealsDistinctCountCreditLifeSaleAsc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_SALE_ASC',
  DealsDistinctCountCreditLifeSaleDesc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_SALE_DESC',
  DealsDistinctCountCreditLifeCostAsc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_COST_ASC',
  DealsDistinctCountCreditLifeCostDesc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_COST_DESC',
  DealsDistinctCountCreditLifeTermAsc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_TERM_ASC',
  DealsDistinctCountCreditLifeTermDesc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_TERM_DESC',
  DealsDistinctCountLevelizedLifeTypeAsc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_TYPE_ASC',
  DealsDistinctCountLevelizedLifeTypeDesc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_TYPE_DESC',
  DealsDistinctCountLevelizedLifeNameAsc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_NAME_ASC',
  DealsDistinctCountLevelizedLifeNameDesc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_NAME_DESC',
  DealsDistinctCountLevelizedLifeSaleAsc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_SALE_ASC',
  DealsDistinctCountLevelizedLifeSaleDesc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_SALE_DESC',
  DealsDistinctCountLevelizedLifeCostAsc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_COST_ASC',
  DealsDistinctCountLevelizedLifeCostDesc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_COST_DESC',
  DealsDistinctCountLevelizedLifeTermAsc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_TERM_ASC',
  DealsDistinctCountLevelizedLifeTermDesc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_TERM_DESC',
  DealsDistinctCountLossOfEmploymentTypeAsc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsDistinctCountLossOfEmploymentTypeDesc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsDistinctCountLossOfEmploymentNameAsc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsDistinctCountLossOfEmploymentNameDesc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsDistinctCountLossOfEmploymentSaleAsc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsDistinctCountLossOfEmploymentSaleDesc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsDistinctCountLossOfEmploymentCostAsc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsDistinctCountLossOfEmploymentCostDesc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsDistinctCountLossOfEmploymentTermAsc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsDistinctCountLossOfEmploymentTermDesc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsDistinctCountGuaranteedAutoProtectionTypeAsc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsDistinctCountGuaranteedAutoProtectionTypeDesc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsDistinctCountGuaranteedAutoProtectionNameAsc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsDistinctCountGuaranteedAutoProtectionNameDesc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsDistinctCountGuaranteedAutoProtectionSaleAsc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsDistinctCountGuaranteedAutoProtectionSaleDesc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsDistinctCountGuaranteedAutoProtectionCostAsc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsDistinctCountGuaranteedAutoProtectionCostDesc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsDistinctCountGuaranteedAutoProtectionTermAsc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsDistinctCountGuaranteedAutoProtectionTermDesc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsDistinctCountSaleCommentsAsc = 'DEALS_DISTINCT_COUNT_SALE_COMMENTS_ASC',
  DealsDistinctCountSaleCommentsDesc = 'DEALS_DISTINCT_COUNT_SALE_COMMENTS_DESC',
  DealsDistinctCountCassStdLine1Asc = 'DEALS_DISTINCT_COUNT_CASS_STD_LINE1_ASC',
  DealsDistinctCountCassStdLine1Desc = 'DEALS_DISTINCT_COUNT_CASS_STD_LINE1_DESC',
  DealsDistinctCountCassStdLine2Asc = 'DEALS_DISTINCT_COUNT_CASS_STD_LINE2_ASC',
  DealsDistinctCountCassStdLine2Desc = 'DEALS_DISTINCT_COUNT_CASS_STD_LINE2_DESC',
  DealsDistinctCountCassStdCityAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_CITY_ASC',
  DealsDistinctCountCassStdCityDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_CITY_DESC',
  DealsDistinctCountCassStdStateAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_STATE_ASC',
  DealsDistinctCountCassStdStateDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_STATE_DESC',
  DealsDistinctCountCassStdZipAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIP_ASC',
  DealsDistinctCountCassStdZipDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIP_DESC',
  DealsDistinctCountCassStdZip4Asc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIP4_ASC',
  DealsDistinctCountCassStdZip4Desc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIP4_DESC',
  DealsDistinctCountCassStdDpbcAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_DPBC_ASC',
  DealsDistinctCountCassStdDpbcDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_DPBC_DESC',
  DealsDistinctCountCassStdChkdgtAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_CHKDGT_ASC',
  DealsDistinctCountCassStdChkdgtDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_CHKDGT_DESC',
  DealsDistinctCountCassStdCartAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_CART_ASC',
  DealsDistinctCountCassStdCartDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_CART_DESC',
  DealsDistinctCountCassStdLotAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_LOT_ASC',
  DealsDistinctCountCassStdLotDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_LOT_DESC',
  DealsDistinctCountCassStdLotordAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_LOTORD_ASC',
  DealsDistinctCountCassStdLotordDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_LOTORD_DESC',
  DealsDistinctCountCassStdUrbAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_URB_ASC',
  DealsDistinctCountCassStdUrbDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_URB_DESC',
  DealsDistinctCountCassStdFipsAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_FIPS_ASC',
  DealsDistinctCountCassStdFipsDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_FIPS_DESC',
  DealsDistinctCountCassStdEwsAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_EWS_ASC',
  DealsDistinctCountCassStdEwsDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_EWS_DESC',
  DealsDistinctCountCassStdLacsAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_LACS_ASC',
  DealsDistinctCountCassStdLacsDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_LACS_DESC',
  DealsDistinctCountCassStdZipmovAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIPMOV_ASC',
  DealsDistinctCountCassStdZipmovDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIPMOV_DESC',
  DealsDistinctCountCassStdZ4LomAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_Z4LOM_ASC',
  DealsDistinctCountCassStdZ4LomDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_Z4LOM_DESC',
  DealsDistinctCountCassStdNdiaptAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_NDIAPT_ASC',
  DealsDistinctCountCassStdNdiaptDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_NDIAPT_DESC',
  DealsDistinctCountCassStdNdirrAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_NDIRR_ASC',
  DealsDistinctCountCassStdNdirrDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_NDIRR_DESC',
  DealsDistinctCountCassStdLacsrtAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_LACSRT_ASC',
  DealsDistinctCountCassStdLacsrtDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_LACSRT_DESC',
  DealsDistinctCountCassStdErrorCdAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_ERROR_CD_ASC',
  DealsDistinctCountCassStdErrorCdDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_ERROR_CD_DESC',
  DealsMinIdAsc = 'DEALS_MIN_ID_ASC',
  DealsMinIdDesc = 'DEALS_MIN_ID_DESC',
  DealsMinLeadIdAsc = 'DEALS_MIN_LEAD_ID_ASC',
  DealsMinLeadIdDesc = 'DEALS_MIN_LEAD_ID_DESC',
  DealsMinCompanyIntegrationIdAsc = 'DEALS_MIN_COMPANY_INTEGRATION_ID_ASC',
  DealsMinCompanyIntegrationIdDesc = 'DEALS_MIN_COMPANY_INTEGRATION_ID_DESC',
  DealsMinFileTypeAsc = 'DEALS_MIN_FILE_TYPE_ASC',
  DealsMinFileTypeDesc = 'DEALS_MIN_FILE_TYPE_DESC',
  DealsMinDvDealerIdAsc = 'DEALS_MIN_DV_DEALER_ID_ASC',
  DealsMinDvDealerIdDesc = 'DEALS_MIN_DV_DEALER_ID_DESC',
  DealsMinVendorDealerIdAsc = 'DEALS_MIN_VENDOR_DEALER_ID_ASC',
  DealsMinVendorDealerIdDesc = 'DEALS_MIN_VENDOR_DEALER_ID_DESC',
  DealsMinDmsTypeAsc = 'DEALS_MIN_DMS_TYPE_ASC',
  DealsMinDmsTypeDesc = 'DEALS_MIN_DMS_TYPE_DESC',
  DealsMinDealNumberAsc = 'DEALS_MIN_DEAL_NUMBER_ASC',
  DealsMinDealNumberDesc = 'DEALS_MIN_DEAL_NUMBER_DESC',
  DealsMinCustomerNumberAsc = 'DEALS_MIN_CUSTOMER_NUMBER_ASC',
  DealsMinCustomerNumberDesc = 'DEALS_MIN_CUSTOMER_NUMBER_DESC',
  DealsMinFullNameAsc = 'DEALS_MIN_FULL_NAME_ASC',
  DealsMinFullNameDesc = 'DEALS_MIN_FULL_NAME_DESC',
  DealsMinSalutationAsc = 'DEALS_MIN_SALUTATION_ASC',
  DealsMinSalutationDesc = 'DEALS_MIN_SALUTATION_DESC',
  DealsMinFirstNameAsc = 'DEALS_MIN_FIRST_NAME_ASC',
  DealsMinFirstNameDesc = 'DEALS_MIN_FIRST_NAME_DESC',
  DealsMinMiddleNameAsc = 'DEALS_MIN_MIDDLE_NAME_ASC',
  DealsMinMiddleNameDesc = 'DEALS_MIN_MIDDLE_NAME_DESC',
  DealsMinLastNameAsc = 'DEALS_MIN_LAST_NAME_ASC',
  DealsMinLastNameDesc = 'DEALS_MIN_LAST_NAME_DESC',
  DealsMinSuffixAsc = 'DEALS_MIN_SUFFIX_ASC',
  DealsMinSuffixDesc = 'DEALS_MIN_SUFFIX_DESC',
  DealsMinAddressLine_1Asc = 'DEALS_MIN_ADDRESS_LINE_1_ASC',
  DealsMinAddressLine_1Desc = 'DEALS_MIN_ADDRESS_LINE_1_DESC',
  DealsMinAddressLine_2Asc = 'DEALS_MIN_ADDRESS_LINE_2_ASC',
  DealsMinAddressLine_2Desc = 'DEALS_MIN_ADDRESS_LINE_2_DESC',
  DealsMinCityAsc = 'DEALS_MIN_CITY_ASC',
  DealsMinCityDesc = 'DEALS_MIN_CITY_DESC',
  DealsMinStateAsc = 'DEALS_MIN_STATE_ASC',
  DealsMinStateDesc = 'DEALS_MIN_STATE_DESC',
  DealsMinZipAsc = 'DEALS_MIN_ZIP_ASC',
  DealsMinZipDesc = 'DEALS_MIN_ZIP_DESC',
  DealsMinCountyAsc = 'DEALS_MIN_COUNTY_ASC',
  DealsMinCountyDesc = 'DEALS_MIN_COUNTY_DESC',
  DealsMinHomePhoneAsc = 'DEALS_MIN_HOME_PHONE_ASC',
  DealsMinHomePhoneDesc = 'DEALS_MIN_HOME_PHONE_DESC',
  DealsMinCellPhoneAsc = 'DEALS_MIN_CELL_PHONE_ASC',
  DealsMinCellPhoneDesc = 'DEALS_MIN_CELL_PHONE_DESC',
  DealsMinWorkPhoneAsc = 'DEALS_MIN_WORK_PHONE_ASC',
  DealsMinWorkPhoneDesc = 'DEALS_MIN_WORK_PHONE_DESC',
  DealsMinWorkExtensionAsc = 'DEALS_MIN_WORK_EXTENSION_ASC',
  DealsMinWorkExtensionDesc = 'DEALS_MIN_WORK_EXTENSION_DESC',
  DealsMinEmail_1Asc = 'DEALS_MIN_EMAIL_1_ASC',
  DealsMinEmail_1Desc = 'DEALS_MIN_EMAIL_1_DESC',
  DealsMinEmail_2Asc = 'DEALS_MIN_EMAIL_2_ASC',
  DealsMinEmail_2Desc = 'DEALS_MIN_EMAIL_2_DESC',
  DealsMinEmail_3Asc = 'DEALS_MIN_EMAIL_3_ASC',
  DealsMinEmail_3Desc = 'DEALS_MIN_EMAIL_3_DESC',
  DealsMinBirthDateAsc = 'DEALS_MIN_BIRTH_DATE_ASC',
  DealsMinBirthDateDesc = 'DEALS_MIN_BIRTH_DATE_DESC',
  DealsMinIndividualBusinessFlagAsc = 'DEALS_MIN_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsMinIndividualBusinessFlagDesc = 'DEALS_MIN_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsMinOptOutAsc = 'DEALS_MIN_OPT_OUT_ASC',
  DealsMinOptOutDesc = 'DEALS_MIN_OPT_OUT_DESC',
  DealsMinBlockEmailAsc = 'DEALS_MIN_BLOCK_EMAIL_ASC',
  DealsMinBlockEmailDesc = 'DEALS_MIN_BLOCK_EMAIL_DESC',
  DealsMinBlockPhoneAsc = 'DEALS_MIN_BLOCK_PHONE_ASC',
  DealsMinBlockPhoneDesc = 'DEALS_MIN_BLOCK_PHONE_DESC',
  DealsMinBlockMailAsc = 'DEALS_MIN_BLOCK_MAIL_ASC',
  DealsMinBlockMailDesc = 'DEALS_MIN_BLOCK_MAIL_DESC',
  DealsMinLanguageAsc = 'DEALS_MIN_LANGUAGE_ASC',
  DealsMinLanguageDesc = 'DEALS_MIN_LANGUAGE_DESC',
  DealsMinCustomerCreateDateAsc = 'DEALS_MIN_CUSTOMER_CREATE_DATE_ASC',
  DealsMinCustomerCreateDateDesc = 'DEALS_MIN_CUSTOMER_CREATE_DATE_DESC',
  DealsMinCustomerLastActivityDateAsc = 'DEALS_MIN_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsMinCustomerLastActivityDateDesc = 'DEALS_MIN_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsMinCoBuyerCustomerNumberAsc = 'DEALS_MIN_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsMinCoBuyerCustomerNumberDesc = 'DEALS_MIN_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsMinCoBuyerFullNameAsc = 'DEALS_MIN_CO_BUYER_FULL_NAME_ASC',
  DealsMinCoBuyerFullNameDesc = 'DEALS_MIN_CO_BUYER_FULL_NAME_DESC',
  DealsMinCoBuyerSalutationAsc = 'DEALS_MIN_CO_BUYER_SALUTATION_ASC',
  DealsMinCoBuyerSalutationDesc = 'DEALS_MIN_CO_BUYER_SALUTATION_DESC',
  DealsMinCoBuyerFirstNameAsc = 'DEALS_MIN_CO_BUYER_FIRST_NAME_ASC',
  DealsMinCoBuyerFirstNameDesc = 'DEALS_MIN_CO_BUYER_FIRST_NAME_DESC',
  DealsMinCoBuyerMiddleNameAsc = 'DEALS_MIN_CO_BUYER_MIDDLE_NAME_ASC',
  DealsMinCoBuyerMiddleNameDesc = 'DEALS_MIN_CO_BUYER_MIDDLE_NAME_DESC',
  DealsMinCoBuyerLastNameAsc = 'DEALS_MIN_CO_BUYER_LAST_NAME_ASC',
  DealsMinCoBuyerLastNameDesc = 'DEALS_MIN_CO_BUYER_LAST_NAME_DESC',
  DealsMinCoBuyerSuffixAsc = 'DEALS_MIN_CO_BUYER_SUFFIX_ASC',
  DealsMinCoBuyerSuffixDesc = 'DEALS_MIN_CO_BUYER_SUFFIX_DESC',
  DealsMinCoBuyerAddressLine_1Asc = 'DEALS_MIN_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsMinCoBuyerAddressLine_1Desc = 'DEALS_MIN_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsMinCoBuyerAddressLine_2Asc = 'DEALS_MIN_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsMinCoBuyerAddressLine_2Desc = 'DEALS_MIN_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsMinCoBuyerCityAsc = 'DEALS_MIN_CO_BUYER_CITY_ASC',
  DealsMinCoBuyerCityDesc = 'DEALS_MIN_CO_BUYER_CITY_DESC',
  DealsMinCoBuyerStateAsc = 'DEALS_MIN_CO_BUYER_STATE_ASC',
  DealsMinCoBuyerStateDesc = 'DEALS_MIN_CO_BUYER_STATE_DESC',
  DealsMinCoBuyerZipAsc = 'DEALS_MIN_CO_BUYER_ZIP_ASC',
  DealsMinCoBuyerZipDesc = 'DEALS_MIN_CO_BUYER_ZIP_DESC',
  DealsMinCoBuyerCountyAsc = 'DEALS_MIN_CO_BUYER_COUNTY_ASC',
  DealsMinCoBuyerCountyDesc = 'DEALS_MIN_CO_BUYER_COUNTY_DESC',
  DealsMinCoBuyerHomePhoneAsc = 'DEALS_MIN_CO_BUYER_HOME_PHONE_ASC',
  DealsMinCoBuyerHomePhoneDesc = 'DEALS_MIN_CO_BUYER_HOME_PHONE_DESC',
  DealsMinCoBuyerCellPhoneAsc = 'DEALS_MIN_CO_BUYER_CELL_PHONE_ASC',
  DealsMinCoBuyerCellPhoneDesc = 'DEALS_MIN_CO_BUYER_CELL_PHONE_DESC',
  DealsMinCoBuyerWorkPhoneAsc = 'DEALS_MIN_CO_BUYER_WORK_PHONE_ASC',
  DealsMinCoBuyerWorkPhoneDesc = 'DEALS_MIN_CO_BUYER_WORK_PHONE_DESC',
  DealsMinCoBuyerWorkExtensionAsc = 'DEALS_MIN_CO_BUYER_WORK_EXTENSION_ASC',
  DealsMinCoBuyerWorkExtensionDesc = 'DEALS_MIN_CO_BUYER_WORK_EXTENSION_DESC',
  DealsMinCoBuyerEmail_1Asc = 'DEALS_MIN_CO_BUYER_EMAIL_1_ASC',
  DealsMinCoBuyerEmail_1Desc = 'DEALS_MIN_CO_BUYER_EMAIL_1_DESC',
  DealsMinCoBuyerEmail_2Asc = 'DEALS_MIN_CO_BUYER_EMAIL_2_ASC',
  DealsMinCoBuyerEmail_2Desc = 'DEALS_MIN_CO_BUYER_EMAIL_2_DESC',
  DealsMinCoBuyerEmail_3Asc = 'DEALS_MIN_CO_BUYER_EMAIL_3_ASC',
  DealsMinCoBuyerEmail_3Desc = 'DEALS_MIN_CO_BUYER_EMAIL_3_DESC',
  DealsMinCoBuyerBirthDateAsc = 'DEALS_MIN_CO_BUYER_BIRTH_DATE_ASC',
  DealsMinCoBuyerBirthDateDesc = 'DEALS_MIN_CO_BUYER_BIRTH_DATE_DESC',
  DealsMinCoBuyerIndividualBusinessFlagAsc = 'DEALS_MIN_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsMinCoBuyerIndividualBusinessFlagDesc = 'DEALS_MIN_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsMinCoBuyerOptOutAsc = 'DEALS_MIN_CO_BUYER_OPT_OUT_ASC',
  DealsMinCoBuyerOptOutDesc = 'DEALS_MIN_CO_BUYER_OPT_OUT_DESC',
  DealsMinCoBuyerBlockEmailAsc = 'DEALS_MIN_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsMinCoBuyerBlockEmailDesc = 'DEALS_MIN_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsMinCoBuyerBlockPhoneAsc = 'DEALS_MIN_CO_BUYER_BLOCK_PHONE_ASC',
  DealsMinCoBuyerBlockPhoneDesc = 'DEALS_MIN_CO_BUYER_BLOCK_PHONE_DESC',
  DealsMinCoBuyerBlockMailAsc = 'DEALS_MIN_CO_BUYER_BLOCK_MAIL_ASC',
  DealsMinCoBuyerBlockMailDesc = 'DEALS_MIN_CO_BUYER_BLOCK_MAIL_DESC',
  DealsMinVinAsc = 'DEALS_MIN_VIN_ASC',
  DealsMinVinDesc = 'DEALS_MIN_VIN_DESC',
  DealsMinYearAsc = 'DEALS_MIN_YEAR_ASC',
  DealsMinYearDesc = 'DEALS_MIN_YEAR_DESC',
  DealsMinMakeAsc = 'DEALS_MIN_MAKE_ASC',
  DealsMinMakeDesc = 'DEALS_MIN_MAKE_DESC',
  DealsMinModelAsc = 'DEALS_MIN_MODEL_ASC',
  DealsMinModelDesc = 'DEALS_MIN_MODEL_DESC',
  DealsMinModelNumberAsc = 'DEALS_MIN_MODEL_NUMBER_ASC',
  DealsMinModelNumberDesc = 'DEALS_MIN_MODEL_NUMBER_DESC',
  DealsMinMileageAsc = 'DEALS_MIN_MILEAGE_ASC',
  DealsMinMileageDesc = 'DEALS_MIN_MILEAGE_DESC',
  DealsMinDescriptionAsc = 'DEALS_MIN_DESCRIPTION_ASC',
  DealsMinDescriptionDesc = 'DEALS_MIN_DESCRIPTION_DESC',
  DealsMinExteriorColorAsc = 'DEALS_MIN_EXTERIOR_COLOR_ASC',
  DealsMinExteriorColorDesc = 'DEALS_MIN_EXTERIOR_COLOR_DESC',
  DealsMinNewUsedAsc = 'DEALS_MIN_NEW_USED_ASC',
  DealsMinNewUsedDesc = 'DEALS_MIN_NEW_USED_DESC',
  DealsMinStockNumberAsc = 'DEALS_MIN_STOCK_NUMBER_ASC',
  DealsMinStockNumberDesc = 'DEALS_MIN_STOCK_NUMBER_DESC',
  DealsMinTransmissionAsc = 'DEALS_MIN_TRANSMISSION_ASC',
  DealsMinTransmissionDesc = 'DEALS_MIN_TRANSMISSION_DESC',
  DealsMinEngineConfigurationAsc = 'DEALS_MIN_ENGINE_CONFIGURATION_ASC',
  DealsMinEngineConfigurationDesc = 'DEALS_MIN_ENGINE_CONFIGURATION_DESC',
  DealsMinTrimAsc = 'DEALS_MIN_TRIM_ASC',
  DealsMinTrimDesc = 'DEALS_MIN_TRIM_DESC',
  DealsMinEngineNumberAsc = 'DEALS_MIN_ENGINE_NUMBER_ASC',
  DealsMinEngineNumberDesc = 'DEALS_MIN_ENGINE_NUMBER_DESC',
  DealsMinChassisNumberAsc = 'DEALS_MIN_CHASSIS_NUMBER_ASC',
  DealsMinChassisNumberDesc = 'DEALS_MIN_CHASSIS_NUMBER_DESC',
  DealsMinLicensePlateNumberAsc = 'DEALS_MIN_LICENSE_PLATE_NUMBER_ASC',
  DealsMinLicensePlateNumberDesc = 'DEALS_MIN_LICENSE_PLATE_NUMBER_DESC',
  DealsMinDeliveryDateAsc = 'DEALS_MIN_DELIVERY_DATE_ASC',
  DealsMinDeliveryDateDesc = 'DEALS_MIN_DELIVERY_DATE_DESC',
  DealsMinDeliveryMileageAsc = 'DEALS_MIN_DELIVERY_MILEAGE_ASC',
  DealsMinDeliveryMileageDesc = 'DEALS_MIN_DELIVERY_MILEAGE_DESC',
  DealsMinInventoryDateAsc = 'DEALS_MIN_INVENTORY_DATE_ASC',
  DealsMinInventoryDateDesc = 'DEALS_MIN_INVENTORY_DATE_DESC',
  DealsMinInServiceDateAsc = 'DEALS_MIN_IN_SERVICE_DATE_ASC',
  DealsMinInServiceDateDesc = 'DEALS_MIN_IN_SERVICE_DATE_DESC',
  DealsMinVinExplosionYearAsc = 'DEALS_MIN_VIN_EXPLOSION_YEAR_ASC',
  DealsMinVinExplosionYearDesc = 'DEALS_MIN_VIN_EXPLOSION_YEAR_DESC',
  DealsMinVinExplosionMakeAsc = 'DEALS_MIN_VIN_EXPLOSION_MAKE_ASC',
  DealsMinVinExplosionMakeDesc = 'DEALS_MIN_VIN_EXPLOSION_MAKE_DESC',
  DealsMinVinExplosionModelAsc = 'DEALS_MIN_VIN_EXPLOSION_MODEL_ASC',
  DealsMinVinExplosionModelDesc = 'DEALS_MIN_VIN_EXPLOSION_MODEL_DESC',
  DealsMinVinExplosionTrimAsc = 'DEALS_MIN_VIN_EXPLOSION_TRIM_ASC',
  DealsMinVinExplosionTrimDesc = 'DEALS_MIN_VIN_EXPLOSION_TRIM_DESC',
  DealsMinVinExplosionTransmissionTypeAsc = 'DEALS_MIN_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsMinVinExplosionTransmissionTypeDesc = 'DEALS_MIN_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsMinVinExplosionFuelTypeAsc = 'DEALS_MIN_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsMinVinExplosionFuelTypeDesc = 'DEALS_MIN_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsMinVinExplosionEngineSizeAsc = 'DEALS_MIN_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsMinVinExplosionEngineSizeDesc = 'DEALS_MIN_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsMinVinExplosionGvwRangeAsc = 'DEALS_MIN_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsMinVinExplosionGvwRangeDesc = 'DEALS_MIN_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsMinTrade_1VinAsc = 'DEALS_MIN_TRADE_1_VIN_ASC',
  DealsMinTrade_1VinDesc = 'DEALS_MIN_TRADE_1_VIN_DESC',
  DealsMinTrade_1YearAsc = 'DEALS_MIN_TRADE_1_YEAR_ASC',
  DealsMinTrade_1YearDesc = 'DEALS_MIN_TRADE_1_YEAR_DESC',
  DealsMinTrade_1MakeAsc = 'DEALS_MIN_TRADE_1_MAKE_ASC',
  DealsMinTrade_1MakeDesc = 'DEALS_MIN_TRADE_1_MAKE_DESC',
  DealsMinTrade_1ModelAsc = 'DEALS_MIN_TRADE_1_MODEL_ASC',
  DealsMinTrade_1ModelDesc = 'DEALS_MIN_TRADE_1_MODEL_DESC',
  DealsMinTrade_1OdometerAsc = 'DEALS_MIN_TRADE_1_ODOMETER_ASC',
  DealsMinTrade_1OdometerDesc = 'DEALS_MIN_TRADE_1_ODOMETER_DESC',
  DealsMinTrade_1ActualCashValueAsc = 'DEALS_MIN_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsMinTrade_1ActualCashValueDesc = 'DEALS_MIN_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsMinTrade_1GrossAsc = 'DEALS_MIN_TRADE_1_GROSS_ASC',
  DealsMinTrade_1GrossDesc = 'DEALS_MIN_TRADE_1_GROSS_DESC',
  DealsMinTrade_1PayoffAsc = 'DEALS_MIN_TRADE_1_PAYOFF_ASC',
  DealsMinTrade_1PayoffDesc = 'DEALS_MIN_TRADE_1_PAYOFF_DESC',
  DealsMinTrade_2VinAsc = 'DEALS_MIN_TRADE_2_VIN_ASC',
  DealsMinTrade_2VinDesc = 'DEALS_MIN_TRADE_2_VIN_DESC',
  DealsMinTrade_2YearAsc = 'DEALS_MIN_TRADE_2_YEAR_ASC',
  DealsMinTrade_2YearDesc = 'DEALS_MIN_TRADE_2_YEAR_DESC',
  DealsMinTrade_2MakeAsc = 'DEALS_MIN_TRADE_2_MAKE_ASC',
  DealsMinTrade_2MakeDesc = 'DEALS_MIN_TRADE_2_MAKE_DESC',
  DealsMinTrade_2ModelAsc = 'DEALS_MIN_TRADE_2_MODEL_ASC',
  DealsMinTrade_2ModelDesc = 'DEALS_MIN_TRADE_2_MODEL_DESC',
  DealsMinTrade_2OdometerAsc = 'DEALS_MIN_TRADE_2_ODOMETER_ASC',
  DealsMinTrade_2OdometerDesc = 'DEALS_MIN_TRADE_2_ODOMETER_DESC',
  DealsMinTrade_2ActualCashValueAsc = 'DEALS_MIN_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsMinTrade_2ActualCashValueDesc = 'DEALS_MIN_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsMinTrade_2GrossAsc = 'DEALS_MIN_TRADE_2_GROSS_ASC',
  DealsMinTrade_2GrossDesc = 'DEALS_MIN_TRADE_2_GROSS_DESC',
  DealsMinTrade_2PayoffAsc = 'DEALS_MIN_TRADE_2_PAYOFF_ASC',
  DealsMinTrade_2PayoffDesc = 'DEALS_MIN_TRADE_2_PAYOFF_DESC',
  DealsMinSalesman_1NumberAsc = 'DEALS_MIN_SALESMAN_1_NUMBER_ASC',
  DealsMinSalesman_1NumberDesc = 'DEALS_MIN_SALESMAN_1_NUMBER_DESC',
  DealsMinSalesman_1NameAsc = 'DEALS_MIN_SALESMAN_1_NAME_ASC',
  DealsMinSalesman_1NameDesc = 'DEALS_MIN_SALESMAN_1_NAME_DESC',
  DealsMinSalesman_2NumberAsc = 'DEALS_MIN_SALESMAN_2_NUMBER_ASC',
  DealsMinSalesman_2NumberDesc = 'DEALS_MIN_SALESMAN_2_NUMBER_DESC',
  DealsMinSalesman_2NameAsc = 'DEALS_MIN_SALESMAN_2_NAME_ASC',
  DealsMinSalesman_2NameDesc = 'DEALS_MIN_SALESMAN_2_NAME_DESC',
  DealsMinSalesman_3NumberAsc = 'DEALS_MIN_SALESMAN_3_NUMBER_ASC',
  DealsMinSalesman_3NumberDesc = 'DEALS_MIN_SALESMAN_3_NUMBER_DESC',
  DealsMinSalesman_3NameAsc = 'DEALS_MIN_SALESMAN_3_NAME_ASC',
  DealsMinSalesman_3NameDesc = 'DEALS_MIN_SALESMAN_3_NAME_DESC',
  DealsMinClosingManagerNumberAsc = 'DEALS_MIN_CLOSING_MANAGER_NUMBER_ASC',
  DealsMinClosingManagerNumberDesc = 'DEALS_MIN_CLOSING_MANAGER_NUMBER_DESC',
  DealsMinClosingManagerNameAsc = 'DEALS_MIN_CLOSING_MANAGER_NAME_ASC',
  DealsMinClosingManagerNameDesc = 'DEALS_MIN_CLOSING_MANAGER_NAME_DESC',
  DealsMinFinanceManagerNumberAsc = 'DEALS_MIN_FINANCE_MANAGER_NUMBER_ASC',
  DealsMinFinanceManagerNumberDesc = 'DEALS_MIN_FINANCE_MANAGER_NUMBER_DESC',
  DealsMinFinanceManagerNameAsc = 'DEALS_MIN_FINANCE_MANAGER_NAME_ASC',
  DealsMinFinanceManagerNameDesc = 'DEALS_MIN_FINANCE_MANAGER_NAME_DESC',
  DealsMinSalesmanManagerNumberAsc = 'DEALS_MIN_SALESMAN_MANAGER_NUMBER_ASC',
  DealsMinSalesmanManagerNumberDesc = 'DEALS_MIN_SALESMAN_MANAGER_NUMBER_DESC',
  DealsMinSalesmanManagerNameAsc = 'DEALS_MIN_SALESMAN_MANAGER_NAME_ASC',
  DealsMinSalesmanManagerNameDesc = 'DEALS_MIN_SALESMAN_MANAGER_NAME_DESC',
  DealsMinMsrpAsc = 'DEALS_MIN_MSRP_ASC',
  DealsMinMsrpDesc = 'DEALS_MIN_MSRP_DESC',
  DealsMinListPriceAsc = 'DEALS_MIN_LIST_PRICE_ASC',
  DealsMinListPriceDesc = 'DEALS_MIN_LIST_PRICE_DESC',
  DealsMinSalesPriceAsc = 'DEALS_MIN_SALES_PRICE_ASC',
  DealsMinSalesPriceDesc = 'DEALS_MIN_SALES_PRICE_DESC',
  DealsMinJournalPriceAsc = 'DEALS_MIN_JOURNAL_PRICE_ASC',
  DealsMinJournalPriceDesc = 'DEALS_MIN_JOURNAL_PRICE_DESC',
  DealsMinCostAsc = 'DEALS_MIN_COST_ASC',
  DealsMinCostDesc = 'DEALS_MIN_COST_DESC',
  DealsMinJournalCostAsc = 'DEALS_MIN_JOURNAL_COST_ASC',
  DealsMinJournalCostDesc = 'DEALS_MIN_JOURNAL_COST_DESC',
  DealsMinAdjustmentsAsc = 'DEALS_MIN_ADJUSTMENTS_ASC',
  DealsMinAdjustmentsDesc = 'DEALS_MIN_ADJUSTMENTS_DESC',
  DealsMinAdjustedCostAsc = 'DEALS_MIN_ADJUSTED_COST_ASC',
  DealsMinAdjustedCostDesc = 'DEALS_MIN_ADJUSTED_COST_DESC',
  DealsMinIncentivesAsc = 'DEALS_MIN_INCENTIVES_ASC',
  DealsMinIncentivesDesc = 'DEALS_MIN_INCENTIVES_DESC',
  DealsMinPackAmountAsc = 'DEALS_MIN_PACK_AMOUNT_ASC',
  DealsMinPackAmountDesc = 'DEALS_MIN_PACK_AMOUNT_DESC',
  DealsMinSaleNetAsc = 'DEALS_MIN_SALE_NET_ASC',
  DealsMinSaleNetDesc = 'DEALS_MIN_SALE_NET_DESC',
  DealsMinTotalTradeActualCashValueAsc = 'DEALS_MIN_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsMinTotalTradeActualCashValueDesc = 'DEALS_MIN_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsMinTotalTradeGrossAsc = 'DEALS_MIN_TOTAL_TRADE_GROSS_ASC',
  DealsMinTotalTradeGrossDesc = 'DEALS_MIN_TOTAL_TRADE_GROSS_DESC',
  DealsMinWeOweFrontAsc = 'DEALS_MIN_WE_OWE_FRONT_ASC',
  DealsMinWeOweFrontDesc = 'DEALS_MIN_WE_OWE_FRONT_DESC',
  DealsMinTotalFrontFeesAftermarketProfitAsc = 'DEALS_MIN_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsMinTotalFrontFeesAftermarketProfitDesc = 'DEALS_MIN_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsMinTotalFrontCommissionAsc = 'DEALS_MIN_TOTAL_FRONT_COMMISSION_ASC',
  DealsMinTotalFrontCommissionDesc = 'DEALS_MIN_TOTAL_FRONT_COMMISSION_DESC',
  DealsMinTotalFrontSalesAsc = 'DEALS_MIN_TOTAL_FRONT_SALES_ASC',
  DealsMinTotalFrontSalesDesc = 'DEALS_MIN_TOTAL_FRONT_SALES_DESC',
  DealsMinTotalFrontCostAsc = 'DEALS_MIN_TOTAL_FRONT_COST_ASC',
  DealsMinTotalFrontCostDesc = 'DEALS_MIN_TOTAL_FRONT_COST_DESC',
  DealsMinFrontGrossAsc = 'DEALS_MIN_FRONT_GROSS_ASC',
  DealsMinFrontGrossDesc = 'DEALS_MIN_FRONT_GROSS_DESC',
  DealsMinFinanceProfitAsc = 'DEALS_MIN_FINANCE_PROFIT_ASC',
  DealsMinFinanceProfitDesc = 'DEALS_MIN_FINANCE_PROFIT_DESC',
  DealsMinTotalWarrantyProfitAsc = 'DEALS_MIN_TOTAL_WARRANTY_PROFIT_ASC',
  DealsMinTotalWarrantyProfitDesc = 'DEALS_MIN_TOTAL_WARRANTY_PROFIT_DESC',
  DealsMinWeOweBackAsc = 'DEALS_MIN_WE_OWE_BACK_ASC',
  DealsMinWeOweBackDesc = 'DEALS_MIN_WE_OWE_BACK_DESC',
  DealsMinInsuranceProfitAsc = 'DEALS_MIN_INSURANCE_PROFIT_ASC',
  DealsMinInsuranceProfitDesc = 'DEALS_MIN_INSURANCE_PROFIT_DESC',
  DealsMinTotalBackFeesAftermarketProfitAsc = 'DEALS_MIN_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsMinTotalBackFeesAftermarketProfitDesc = 'DEALS_MIN_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsMinFinanceReserveAsc = 'DEALS_MIN_FINANCE_RESERVE_ASC',
  DealsMinFinanceReserveDesc = 'DEALS_MIN_FINANCE_RESERVE_DESC',
  DealsMinTotalBackCommissionAsc = 'DEALS_MIN_TOTAL_BACK_COMMISSION_ASC',
  DealsMinTotalBackCommissionDesc = 'DEALS_MIN_TOTAL_BACK_COMMISSION_DESC',
  DealsMinTotalBackSalesAsc = 'DEALS_MIN_TOTAL_BACK_SALES_ASC',
  DealsMinTotalBackSalesDesc = 'DEALS_MIN_TOTAL_BACK_SALES_DESC',
  DealsMinTotalBackCostAsc = 'DEALS_MIN_TOTAL_BACK_COST_ASC',
  DealsMinTotalBackCostDesc = 'DEALS_MIN_TOTAL_BACK_COST_DESC',
  DealsMinBackGrossAsc = 'DEALS_MIN_BACK_GROSS_ASC',
  DealsMinBackGrossDesc = 'DEALS_MIN_BACK_GROSS_DESC',
  DealsMinTotalProfitAsc = 'DEALS_MIN_TOTAL_PROFIT_ASC',
  DealsMinTotalProfitDesc = 'DEALS_MIN_TOTAL_PROFIT_DESC',
  DealsMinGrossProfitAsc = 'DEALS_MIN_GROSS_PROFIT_ASC',
  DealsMinGrossProfitDesc = 'DEALS_MIN_GROSS_PROFIT_DESC',
  DealsMinGrossPayableAsc = 'DEALS_MIN_GROSS_PAYABLE_ASC',
  DealsMinGrossPayableDesc = 'DEALS_MIN_GROSS_PAYABLE_DESC',
  DealsMinDealStatusAsc = 'DEALS_MIN_DEAL_STATUS_ASC',
  DealsMinDealStatusDesc = 'DEALS_MIN_DEAL_STATUS_DESC',
  DealsMinEntryDateAsc = 'DEALS_MIN_ENTRY_DATE_ASC',
  DealsMinEntryDateDesc = 'DEALS_MIN_ENTRY_DATE_DESC',
  DealsMinBookedDateAsc = 'DEALS_MIN_BOOKED_DATE_ASC',
  DealsMinBookedDateDesc = 'DEALS_MIN_BOOKED_DATE_DESC',
  DealsMinFinalizedDateAsc = 'DEALS_MIN_FINALIZED_DATE_ASC',
  DealsMinFinalizedDateDesc = 'DEALS_MIN_FINALIZED_DATE_DESC',
  DealsMinContractDateAsc = 'DEALS_MIN_CONTRACT_DATE_ASC',
  DealsMinContractDateDesc = 'DEALS_MIN_CONTRACT_DATE_DESC',
  DealsMinAccountingDateAsc = 'DEALS_MIN_ACCOUNTING_DATE_ASC',
  DealsMinAccountingDateDesc = 'DEALS_MIN_ACCOUNTING_DATE_DESC',
  DealsMinStatusChangeDateAsc = 'DEALS_MIN_STATUS_CHANGE_DATE_ASC',
  DealsMinStatusChangeDateDesc = 'DEALS_MIN_STATUS_CHANGE_DATE_DESC',
  DealsMinFirstPayDateAsc = 'DEALS_MIN_FIRST_PAY_DATE_ASC',
  DealsMinFirstPayDateDesc = 'DEALS_MIN_FIRST_PAY_DATE_DESC',
  DealsMinDealTypeAsc = 'DEALS_MIN_DEAL_TYPE_ASC',
  DealsMinDealTypeDesc = 'DEALS_MIN_DEAL_TYPE_DESC',
  DealsMinSaleTypeAsc = 'DEALS_MIN_SALE_TYPE_ASC',
  DealsMinSaleTypeDesc = 'DEALS_MIN_SALE_TYPE_DESC',
  DealsMinBankIdAsc = 'DEALS_MIN_BANK_ID_ASC',
  DealsMinBankIdDesc = 'DEALS_MIN_BANK_ID_DESC',
  DealsMinBankNameAsc = 'DEALS_MIN_BANK_NAME_ASC',
  DealsMinBankNameDesc = 'DEALS_MIN_BANK_NAME_DESC',
  DealsMinBankAddressAsc = 'DEALS_MIN_BANK_ADDRESS_ASC',
  DealsMinBankAddressDesc = 'DEALS_MIN_BANK_ADDRESS_DESC',
  DealsMinTermAsc = 'DEALS_MIN_TERM_ASC',
  DealsMinTermDesc = 'DEALS_MIN_TERM_DESC',
  DealsMinAmountFinancedAsc = 'DEALS_MIN_AMOUNT_FINANCED_ASC',
  DealsMinAmountFinancedDesc = 'DEALS_MIN_AMOUNT_FINANCED_DESC',
  DealsMinAprAsc = 'DEALS_MIN_APR_ASC',
  DealsMinAprDesc = 'DEALS_MIN_APR_DESC',
  DealsMinMonthlyPaymentAsc = 'DEALS_MIN_MONTHLY_PAYMENT_ASC',
  DealsMinMonthlyPaymentDesc = 'DEALS_MIN_MONTHLY_PAYMENT_DESC',
  DealsMinPaymentTotalAsc = 'DEALS_MIN_PAYMENT_TOTAL_ASC',
  DealsMinPaymentTotalDesc = 'DEALS_MIN_PAYMENT_TOTAL_DESC',
  DealsMinRebatesAsc = 'DEALS_MIN_REBATES_ASC',
  DealsMinRebatesDesc = 'DEALS_MIN_REBATES_DESC',
  DealsMinDepositAsc = 'DEALS_MIN_DEPOSIT_ASC',
  DealsMinDepositDesc = 'DEALS_MIN_DEPOSIT_DESC',
  DealsMinDownPaymentAsc = 'DEALS_MIN_DOWN_PAYMENT_ASC',
  DealsMinDownPaymentDesc = 'DEALS_MIN_DOWN_PAYMENT_DESC',
  DealsMinTotalNetTradesAsc = 'DEALS_MIN_TOTAL_NET_TRADES_ASC',
  DealsMinTotalNetTradesDesc = 'DEALS_MIN_TOTAL_NET_TRADES_DESC',
  DealsMinTotalDownAsc = 'DEALS_MIN_TOTAL_DOWN_ASC',
  DealsMinTotalDownDesc = 'DEALS_MIN_TOTAL_DOWN_DESC',
  DealsMinBalloonAmountAsc = 'DEALS_MIN_BALLOON_AMOUNT_ASC',
  DealsMinBalloonAmountDesc = 'DEALS_MIN_BALLOON_AMOUNT_DESC',
  DealsMinAdjustedBalloonAmountAsc = 'DEALS_MIN_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsMinAdjustedBalloonAmountDesc = 'DEALS_MIN_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsMinHoldbackAmountAsc = 'DEALS_MIN_HOLDBACK_AMOUNT_ASC',
  DealsMinHoldbackAmountDesc = 'DEALS_MIN_HOLDBACK_AMOUNT_DESC',
  DealsMinTotalDriveOffAmountAsc = 'DEALS_MIN_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsMinTotalDriveOffAmountDesc = 'DEALS_MIN_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsMinLicenseFeeAsc = 'DEALS_MIN_LICENSE_FEE_ASC',
  DealsMinLicenseFeeDesc = 'DEALS_MIN_LICENSE_FEE_DESC',
  DealsMinRegistrationFeeAsc = 'DEALS_MIN_REGISTRATION_FEE_ASC',
  DealsMinRegistrationFeeDesc = 'DEALS_MIN_REGISTRATION_FEE_DESC',
  DealsMinDocumentationFeeAsc = 'DEALS_MIN_DOCUMENTATION_FEE_ASC',
  DealsMinDocumentationFeeDesc = 'DEALS_MIN_DOCUMENTATION_FEE_DESC',
  DealsMinFinanceChargeAsc = 'DEALS_MIN_FINANCE_CHARGE_ASC',
  DealsMinFinanceChargeDesc = 'DEALS_MIN_FINANCE_CHARGE_DESC',
  DealsMinTotalPickupPaymentsAsc = 'DEALS_MIN_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsMinTotalPickupPaymentsDesc = 'DEALS_MIN_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsMinSellRateAsc = 'DEALS_MIN_SELL_RATE_ASC',
  DealsMinSellRateDesc = 'DEALS_MIN_SELL_RATE_DESC',
  DealsMinBuyRateAsc = 'DEALS_MIN_BUY_RATE_ASC',
  DealsMinBuyRateDesc = 'DEALS_MIN_BUY_RATE_DESC',
  DealsMinResidualRateAsc = 'DEALS_MIN_RESIDUAL_RATE_ASC',
  DealsMinResidualRateDesc = 'DEALS_MIN_RESIDUAL_RATE_DESC',
  DealsMinResidualAmountAsc = 'DEALS_MIN_RESIDUAL_AMOUNT_ASC',
  DealsMinResidualAmountDesc = 'DEALS_MIN_RESIDUAL_AMOUNT_DESC',
  DealsMinAllowedMilesAsc = 'DEALS_MIN_ALLOWED_MILES_ASC',
  DealsMinAllowedMilesDesc = 'DEALS_MIN_ALLOWED_MILES_DESC',
  DealsMinEstimatedMilesAsc = 'DEALS_MIN_ESTIMATED_MILES_ASC',
  DealsMinEstimatedMilesDesc = 'DEALS_MIN_ESTIMATED_MILES_DESC',
  DealsMinMileageRateAsc = 'DEALS_MIN_MILEAGE_RATE_ASC',
  DealsMinMileageRateDesc = 'DEALS_MIN_MILEAGE_RATE_DESC',
  DealsMinAcquisitionFeeAsc = 'DEALS_MIN_ACQUISITION_FEE_ASC',
  DealsMinAcquisitionFeeDesc = 'DEALS_MIN_ACQUISITION_FEE_DESC',
  DealsMinBasePaymentAsc = 'DEALS_MIN_BASE_PAYMENT_ASC',
  DealsMinBasePaymentDesc = 'DEALS_MIN_BASE_PAYMENT_DESC',
  DealsMinSecurityDepositAsc = 'DEALS_MIN_SECURITY_DEPOSIT_ASC',
  DealsMinSecurityDepositDesc = 'DEALS_MIN_SECURITY_DEPOSIT_DESC',
  DealsMinTotalCapitalReductionAsc = 'DEALS_MIN_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsMinTotalCapitalReductionDesc = 'DEALS_MIN_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsMinNetCapitalCostAsc = 'DEALS_MIN_NET_CAPITAL_COST_ASC',
  DealsMinNetCapitalCostDesc = 'DEALS_MIN_NET_CAPITAL_COST_DESC',
  DealsMinLeaseDepreciationValueAsc = 'DEALS_MIN_LEASE_DEPRECIATION_VALUE_ASC',
  DealsMinLeaseDepreciationValueDesc = 'DEALS_MIN_LEASE_DEPRECIATION_VALUE_DESC',
  DealsMinDealerFeesAsc = 'DEALS_MIN_DEALER_FEES_ASC',
  DealsMinDealerFeesDesc = 'DEALS_MIN_DEALER_FEES_DESC',
  DealsMinGovernmentFeesAsc = 'DEALS_MIN_GOVERNMENT_FEES_ASC',
  DealsMinGovernmentFeesDesc = 'DEALS_MIN_GOVERNMENT_FEES_DESC',
  DealsMinTotalTaxAsc = 'DEALS_MIN_TOTAL_TAX_ASC',
  DealsMinTotalTaxDesc = 'DEALS_MIN_TOTAL_TAX_DESC',
  DealsMinRegistrationStateAsc = 'DEALS_MIN_REGISTRATION_STATE_ASC',
  DealsMinRegistrationStateDesc = 'DEALS_MIN_REGISTRATION_STATE_DESC',
  DealsMinReportOfSaleNumberAsc = 'DEALS_MIN_REPORT_OF_SALE_NUMBER_ASC',
  DealsMinReportOfSaleNumberDesc = 'DEALS_MIN_REPORT_OF_SALE_NUMBER_DESC',
  DealsMinSalesman_1TotalCommissionAsc = 'DEALS_MIN_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsMinSalesman_1TotalCommissionDesc = 'DEALS_MIN_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsMinSalesman_1FrontCommissionAsc = 'DEALS_MIN_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsMinSalesman_1FrontCommissionDesc = 'DEALS_MIN_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsMinSalesman_1BackCommissionAsc = 'DEALS_MIN_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsMinSalesman_1BackCommissionDesc = 'DEALS_MIN_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsMinSalesman_2TotalCommissionAsc = 'DEALS_MIN_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsMinSalesman_2TotalCommissionDesc = 'DEALS_MIN_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsMinSalesman_2FrontCommissionAsc = 'DEALS_MIN_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsMinSalesman_2FrontCommissionDesc = 'DEALS_MIN_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsMinSalesman_2BackCommissionAsc = 'DEALS_MIN_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsMinSalesman_2BackCommissionDesc = 'DEALS_MIN_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsMinSalesman_3TotalCommissionAsc = 'DEALS_MIN_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsMinSalesman_3TotalCommissionDesc = 'DEALS_MIN_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsMinSalesman_3FrontCommissionAsc = 'DEALS_MIN_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsMinSalesman_3FrontCommissionDesc = 'DEALS_MIN_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsMinSalesman_3BackCommissionAsc = 'DEALS_MIN_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsMinSalesman_3BackCommissionDesc = 'DEALS_MIN_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsMinWarranty_1NameAsc = 'DEALS_MIN_WARRANTY_1_NAME_ASC',
  DealsMinWarranty_1NameDesc = 'DEALS_MIN_WARRANTY_1_NAME_DESC',
  DealsMinWarranty_1SaleAsc = 'DEALS_MIN_WARRANTY_1_SALE_ASC',
  DealsMinWarranty_1SaleDesc = 'DEALS_MIN_WARRANTY_1_SALE_DESC',
  DealsMinWarranty_1CostAsc = 'DEALS_MIN_WARRANTY_1_COST_ASC',
  DealsMinWarranty_1CostDesc = 'DEALS_MIN_WARRANTY_1_COST_DESC',
  DealsMinWarranty_1MilesAsc = 'DEALS_MIN_WARRANTY_1_MILES_ASC',
  DealsMinWarranty_1MilesDesc = 'DEALS_MIN_WARRANTY_1_MILES_DESC',
  DealsMinWarranty_1TermAsc = 'DEALS_MIN_WARRANTY_1_TERM_ASC',
  DealsMinWarranty_1TermDesc = 'DEALS_MIN_WARRANTY_1_TERM_DESC',
  DealsMinWarranty_2NameAsc = 'DEALS_MIN_WARRANTY_2_NAME_ASC',
  DealsMinWarranty_2NameDesc = 'DEALS_MIN_WARRANTY_2_NAME_DESC',
  DealsMinWarranty_2SaleAsc = 'DEALS_MIN_WARRANTY_2_SALE_ASC',
  DealsMinWarranty_2SaleDesc = 'DEALS_MIN_WARRANTY_2_SALE_DESC',
  DealsMinWarranty_2CostAsc = 'DEALS_MIN_WARRANTY_2_COST_ASC',
  DealsMinWarranty_2CostDesc = 'DEALS_MIN_WARRANTY_2_COST_DESC',
  DealsMinWarranty_2MilesAsc = 'DEALS_MIN_WARRANTY_2_MILES_ASC',
  DealsMinWarranty_2MilesDesc = 'DEALS_MIN_WARRANTY_2_MILES_DESC',
  DealsMinWarranty_2TermAsc = 'DEALS_MIN_WARRANTY_2_TERM_ASC',
  DealsMinWarranty_2TermDesc = 'DEALS_MIN_WARRANTY_2_TERM_DESC',
  DealsMinWarranty_3NameAsc = 'DEALS_MIN_WARRANTY_3_NAME_ASC',
  DealsMinWarranty_3NameDesc = 'DEALS_MIN_WARRANTY_3_NAME_DESC',
  DealsMinWarranty_3SaleAsc = 'DEALS_MIN_WARRANTY_3_SALE_ASC',
  DealsMinWarranty_3SaleDesc = 'DEALS_MIN_WARRANTY_3_SALE_DESC',
  DealsMinWarranty_3CostAsc = 'DEALS_MIN_WARRANTY_3_COST_ASC',
  DealsMinWarranty_3CostDesc = 'DEALS_MIN_WARRANTY_3_COST_DESC',
  DealsMinWarranty_3MilesAsc = 'DEALS_MIN_WARRANTY_3_MILES_ASC',
  DealsMinWarranty_3MilesDesc = 'DEALS_MIN_WARRANTY_3_MILES_DESC',
  DealsMinWarranty_3TermAsc = 'DEALS_MIN_WARRANTY_3_TERM_ASC',
  DealsMinWarranty_3TermDesc = 'DEALS_MIN_WARRANTY_3_TERM_DESC',
  DealsMinWarranty_4NameAsc = 'DEALS_MIN_WARRANTY_4_NAME_ASC',
  DealsMinWarranty_4NameDesc = 'DEALS_MIN_WARRANTY_4_NAME_DESC',
  DealsMinWarranty_4SaleAsc = 'DEALS_MIN_WARRANTY_4_SALE_ASC',
  DealsMinWarranty_4SaleDesc = 'DEALS_MIN_WARRANTY_4_SALE_DESC',
  DealsMinWarranty_4CostAsc = 'DEALS_MIN_WARRANTY_4_COST_ASC',
  DealsMinWarranty_4CostDesc = 'DEALS_MIN_WARRANTY_4_COST_DESC',
  DealsMinWarranty_4MilesAsc = 'DEALS_MIN_WARRANTY_4_MILES_ASC',
  DealsMinWarranty_4MilesDesc = 'DEALS_MIN_WARRANTY_4_MILES_DESC',
  DealsMinWarranty_4TermAsc = 'DEALS_MIN_WARRANTY_4_TERM_ASC',
  DealsMinWarranty_4TermDesc = 'DEALS_MIN_WARRANTY_4_TERM_DESC',
  DealsMinWarranty_5NameAsc = 'DEALS_MIN_WARRANTY_5_NAME_ASC',
  DealsMinWarranty_5NameDesc = 'DEALS_MIN_WARRANTY_5_NAME_DESC',
  DealsMinWarranty_5SaleAsc = 'DEALS_MIN_WARRANTY_5_SALE_ASC',
  DealsMinWarranty_5SaleDesc = 'DEALS_MIN_WARRANTY_5_SALE_DESC',
  DealsMinWarranty_5CostAsc = 'DEALS_MIN_WARRANTY_5_COST_ASC',
  DealsMinWarranty_5CostDesc = 'DEALS_MIN_WARRANTY_5_COST_DESC',
  DealsMinWarranty_5MilesAsc = 'DEALS_MIN_WARRANTY_5_MILES_ASC',
  DealsMinWarranty_5MilesDesc = 'DEALS_MIN_WARRANTY_5_MILES_DESC',
  DealsMinWarranty_5TermAsc = 'DEALS_MIN_WARRANTY_5_TERM_ASC',
  DealsMinWarranty_5TermDesc = 'DEALS_MIN_WARRANTY_5_TERM_DESC',
  DealsMinTotalFeeAftermarketSaleAsc = 'DEALS_MIN_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsMinTotalFeeAftermarketSaleDesc = 'DEALS_MIN_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsMinTotalFeeAftermarketCostAsc = 'DEALS_MIN_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsMinTotalFeeAftermarketCostDesc = 'DEALS_MIN_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsMinFeeAftermarket_1NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_1_NAME_ASC',
  DealsMinFeeAftermarket_1NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_1_NAME_DESC',
  DealsMinFeeAftermarket_1SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_1_SALE_ASC',
  DealsMinFeeAftermarket_1SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_1_SALE_DESC',
  DealsMinFeeAftermarket_1CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_1_COST_ASC',
  DealsMinFeeAftermarket_1CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_1_COST_DESC',
  DealsMinFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_2NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_2_NAME_ASC',
  DealsMinFeeAftermarket_2NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_2_NAME_DESC',
  DealsMinFeeAftermarket_2SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_2_SALE_ASC',
  DealsMinFeeAftermarket_2SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_2_SALE_DESC',
  DealsMinFeeAftermarket_2CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_2_COST_ASC',
  DealsMinFeeAftermarket_2CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_2_COST_DESC',
  DealsMinFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_3NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_3_NAME_ASC',
  DealsMinFeeAftermarket_3NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_3_NAME_DESC',
  DealsMinFeeAftermarket_3SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_3_SALE_ASC',
  DealsMinFeeAftermarket_3SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_3_SALE_DESC',
  DealsMinFeeAftermarket_3CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_3_COST_ASC',
  DealsMinFeeAftermarket_3CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_3_COST_DESC',
  DealsMinFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_4NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_4_NAME_ASC',
  DealsMinFeeAftermarket_4NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_4_NAME_DESC',
  DealsMinFeeAftermarket_4SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_4_SALE_ASC',
  DealsMinFeeAftermarket_4SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_4_SALE_DESC',
  DealsMinFeeAftermarket_4CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_4_COST_ASC',
  DealsMinFeeAftermarket_4CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_4_COST_DESC',
  DealsMinFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_5NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_5_NAME_ASC',
  DealsMinFeeAftermarket_5NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_5_NAME_DESC',
  DealsMinFeeAftermarket_5SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_5_SALE_ASC',
  DealsMinFeeAftermarket_5SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_5_SALE_DESC',
  DealsMinFeeAftermarket_5CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_5_COST_ASC',
  DealsMinFeeAftermarket_5CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_5_COST_DESC',
  DealsMinFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_6NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_6_NAME_ASC',
  DealsMinFeeAftermarket_6NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_6_NAME_DESC',
  DealsMinFeeAftermarket_6SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_6_SALE_ASC',
  DealsMinFeeAftermarket_6SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_6_SALE_DESC',
  DealsMinFeeAftermarket_6CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_6_COST_ASC',
  DealsMinFeeAftermarket_6CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_6_COST_DESC',
  DealsMinFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_7NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_7_NAME_ASC',
  DealsMinFeeAftermarket_7NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_7_NAME_DESC',
  DealsMinFeeAftermarket_7SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_7_SALE_ASC',
  DealsMinFeeAftermarket_7SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_7_SALE_DESC',
  DealsMinFeeAftermarket_7CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_7_COST_ASC',
  DealsMinFeeAftermarket_7CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_7_COST_DESC',
  DealsMinFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_8NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_8_NAME_ASC',
  DealsMinFeeAftermarket_8NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_8_NAME_DESC',
  DealsMinFeeAftermarket_8SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_8_SALE_ASC',
  DealsMinFeeAftermarket_8SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_8_SALE_DESC',
  DealsMinFeeAftermarket_8CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_8_COST_ASC',
  DealsMinFeeAftermarket_8CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_8_COST_DESC',
  DealsMinFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_9NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_9_NAME_ASC',
  DealsMinFeeAftermarket_9NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_9_NAME_DESC',
  DealsMinFeeAftermarket_9SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_9_SALE_ASC',
  DealsMinFeeAftermarket_9SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_9_SALE_DESC',
  DealsMinFeeAftermarket_9CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_9_COST_ASC',
  DealsMinFeeAftermarket_9CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_9_COST_DESC',
  DealsMinFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_10NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_10_NAME_ASC',
  DealsMinFeeAftermarket_10NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_10_NAME_DESC',
  DealsMinFeeAftermarket_10SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_10_SALE_ASC',
  DealsMinFeeAftermarket_10SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_10_SALE_DESC',
  DealsMinFeeAftermarket_10CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_10_COST_ASC',
  DealsMinFeeAftermarket_10CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_10_COST_DESC',
  DealsMinFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_11NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_11_NAME_ASC',
  DealsMinFeeAftermarket_11NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_11_NAME_DESC',
  DealsMinFeeAftermarket_11SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_11_SALE_ASC',
  DealsMinFeeAftermarket_11SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_11_SALE_DESC',
  DealsMinFeeAftermarket_11CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_11_COST_ASC',
  DealsMinFeeAftermarket_11CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_11_COST_DESC',
  DealsMinFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_12NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_12_NAME_ASC',
  DealsMinFeeAftermarket_12NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_12_NAME_DESC',
  DealsMinFeeAftermarket_12SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_12_SALE_ASC',
  DealsMinFeeAftermarket_12SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_12_SALE_DESC',
  DealsMinFeeAftermarket_12CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_12_COST_ASC',
  DealsMinFeeAftermarket_12CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_12_COST_DESC',
  DealsMinFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_13NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_13_NAME_ASC',
  DealsMinFeeAftermarket_13NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_13_NAME_DESC',
  DealsMinFeeAftermarket_13SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_13_SALE_ASC',
  DealsMinFeeAftermarket_13SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_13_SALE_DESC',
  DealsMinFeeAftermarket_13CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_13_COST_ASC',
  DealsMinFeeAftermarket_13CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_13_COST_DESC',
  DealsMinFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_14NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_14_NAME_ASC',
  DealsMinFeeAftermarket_14NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_14_NAME_DESC',
  DealsMinFeeAftermarket_14SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_14_SALE_ASC',
  DealsMinFeeAftermarket_14SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_14_SALE_DESC',
  DealsMinFeeAftermarket_14CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_14_COST_ASC',
  DealsMinFeeAftermarket_14CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_14_COST_DESC',
  DealsMinFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_15NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_15_NAME_ASC',
  DealsMinFeeAftermarket_15NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_15_NAME_DESC',
  DealsMinFeeAftermarket_15SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_15_SALE_ASC',
  DealsMinFeeAftermarket_15SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_15_SALE_DESC',
  DealsMinFeeAftermarket_15CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_15_COST_ASC',
  DealsMinFeeAftermarket_15CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_15_COST_DESC',
  DealsMinFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_16NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_16_NAME_ASC',
  DealsMinFeeAftermarket_16NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_16_NAME_DESC',
  DealsMinFeeAftermarket_16SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_16_SALE_ASC',
  DealsMinFeeAftermarket_16SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_16_SALE_DESC',
  DealsMinFeeAftermarket_16CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_16_COST_ASC',
  DealsMinFeeAftermarket_16CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_16_COST_DESC',
  DealsMinFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_17NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_17_NAME_ASC',
  DealsMinFeeAftermarket_17NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_17_NAME_DESC',
  DealsMinFeeAftermarket_17SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_17_SALE_ASC',
  DealsMinFeeAftermarket_17SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_17_SALE_DESC',
  DealsMinFeeAftermarket_17CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_17_COST_ASC',
  DealsMinFeeAftermarket_17CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_17_COST_DESC',
  DealsMinFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_18NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_18_NAME_ASC',
  DealsMinFeeAftermarket_18NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_18_NAME_DESC',
  DealsMinFeeAftermarket_18SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_18_SALE_ASC',
  DealsMinFeeAftermarket_18SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_18_SALE_DESC',
  DealsMinFeeAftermarket_18CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_18_COST_ASC',
  DealsMinFeeAftermarket_18CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_18_COST_DESC',
  DealsMinFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_19NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_19_NAME_ASC',
  DealsMinFeeAftermarket_19NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_19_NAME_DESC',
  DealsMinFeeAftermarket_19SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_19_SALE_ASC',
  DealsMinFeeAftermarket_19SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_19_SALE_DESC',
  DealsMinFeeAftermarket_19CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_19_COST_ASC',
  DealsMinFeeAftermarket_19CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_19_COST_DESC',
  DealsMinFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_20NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_20_NAME_ASC',
  DealsMinFeeAftermarket_20NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_20_NAME_DESC',
  DealsMinFeeAftermarket_20SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_20_SALE_ASC',
  DealsMinFeeAftermarket_20SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_20_SALE_DESC',
  DealsMinFeeAftermarket_20CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_20_COST_ASC',
  DealsMinFeeAftermarket_20CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_20_COST_DESC',
  DealsMinFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsMinInsurance_1TypeAsc = 'DEALS_MIN_INSURANCE_1_TYPE_ASC',
  DealsMinInsurance_1TypeDesc = 'DEALS_MIN_INSURANCE_1_TYPE_DESC',
  DealsMinInsurance_1NameAsc = 'DEALS_MIN_INSURANCE_1_NAME_ASC',
  DealsMinInsurance_1NameDesc = 'DEALS_MIN_INSURANCE_1_NAME_DESC',
  DealsMinInsurance_1SaleAsc = 'DEALS_MIN_INSURANCE_1_SALE_ASC',
  DealsMinInsurance_1SaleDesc = 'DEALS_MIN_INSURANCE_1_SALE_DESC',
  DealsMinInsurance_1CostAsc = 'DEALS_MIN_INSURANCE_1_COST_ASC',
  DealsMinInsurance_1CostDesc = 'DEALS_MIN_INSURANCE_1_COST_DESC',
  DealsMinInsurance_1TermAsc = 'DEALS_MIN_INSURANCE_1_TERM_ASC',
  DealsMinInsurance_1TermDesc = 'DEALS_MIN_INSURANCE_1_TERM_DESC',
  DealsMinInsurance_2TypeAsc = 'DEALS_MIN_INSURANCE_2_TYPE_ASC',
  DealsMinInsurance_2TypeDesc = 'DEALS_MIN_INSURANCE_2_TYPE_DESC',
  DealsMinInsurance_2NameAsc = 'DEALS_MIN_INSURANCE_2_NAME_ASC',
  DealsMinInsurance_2NameDesc = 'DEALS_MIN_INSURANCE_2_NAME_DESC',
  DealsMinInsurance_2SaleAsc = 'DEALS_MIN_INSURANCE_2_SALE_ASC',
  DealsMinInsurance_2SaleDesc = 'DEALS_MIN_INSURANCE_2_SALE_DESC',
  DealsMinInsurance_2CostAsc = 'DEALS_MIN_INSURANCE_2_COST_ASC',
  DealsMinInsurance_2CostDesc = 'DEALS_MIN_INSURANCE_2_COST_DESC',
  DealsMinInsurance_2TermAsc = 'DEALS_MIN_INSURANCE_2_TERM_ASC',
  DealsMinInsurance_2TermDesc = 'DEALS_MIN_INSURANCE_2_TERM_DESC',
  DealsMinInsurance_3TypeAsc = 'DEALS_MIN_INSURANCE_3_TYPE_ASC',
  DealsMinInsurance_3TypeDesc = 'DEALS_MIN_INSURANCE_3_TYPE_DESC',
  DealsMinInsurance_3NameAsc = 'DEALS_MIN_INSURANCE_3_NAME_ASC',
  DealsMinInsurance_3NameDesc = 'DEALS_MIN_INSURANCE_3_NAME_DESC',
  DealsMinInsurance_3SaleAsc = 'DEALS_MIN_INSURANCE_3_SALE_ASC',
  DealsMinInsurance_3SaleDesc = 'DEALS_MIN_INSURANCE_3_SALE_DESC',
  DealsMinInsurance_3CostAsc = 'DEALS_MIN_INSURANCE_3_COST_ASC',
  DealsMinInsurance_3CostDesc = 'DEALS_MIN_INSURANCE_3_COST_DESC',
  DealsMinInsurance_3TermAsc = 'DEALS_MIN_INSURANCE_3_TERM_ASC',
  DealsMinInsurance_3TermDesc = 'DEALS_MIN_INSURANCE_3_TERM_DESC',
  DealsMinAccidentalHealthTypeAsc = 'DEALS_MIN_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsMinAccidentalHealthTypeDesc = 'DEALS_MIN_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsMinAccidentalHealthNameAsc = 'DEALS_MIN_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsMinAccidentalHealthNameDesc = 'DEALS_MIN_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsMinAccidentalHealthSaleAsc = 'DEALS_MIN_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsMinAccidentalHealthSaleDesc = 'DEALS_MIN_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsMinAccidentalHealthCostAsc = 'DEALS_MIN_ACCIDENTAL_HEALTH_COST_ASC',
  DealsMinAccidentalHealthCostDesc = 'DEALS_MIN_ACCIDENTAL_HEALTH_COST_DESC',
  DealsMinAccidentalHealthTermAsc = 'DEALS_MIN_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsMinAccidentalHealthTermDesc = 'DEALS_MIN_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsMinCreditLifeTypeAsc = 'DEALS_MIN_CREDIT_LIFE_TYPE_ASC',
  DealsMinCreditLifeTypeDesc = 'DEALS_MIN_CREDIT_LIFE_TYPE_DESC',
  DealsMinCreditLifeNameAsc = 'DEALS_MIN_CREDIT_LIFE_NAME_ASC',
  DealsMinCreditLifeNameDesc = 'DEALS_MIN_CREDIT_LIFE_NAME_DESC',
  DealsMinCreditLifeSaleAsc = 'DEALS_MIN_CREDIT_LIFE_SALE_ASC',
  DealsMinCreditLifeSaleDesc = 'DEALS_MIN_CREDIT_LIFE_SALE_DESC',
  DealsMinCreditLifeCostAsc = 'DEALS_MIN_CREDIT_LIFE_COST_ASC',
  DealsMinCreditLifeCostDesc = 'DEALS_MIN_CREDIT_LIFE_COST_DESC',
  DealsMinCreditLifeTermAsc = 'DEALS_MIN_CREDIT_LIFE_TERM_ASC',
  DealsMinCreditLifeTermDesc = 'DEALS_MIN_CREDIT_LIFE_TERM_DESC',
  DealsMinLevelizedLifeTypeAsc = 'DEALS_MIN_LEVELIZED_LIFE_TYPE_ASC',
  DealsMinLevelizedLifeTypeDesc = 'DEALS_MIN_LEVELIZED_LIFE_TYPE_DESC',
  DealsMinLevelizedLifeNameAsc = 'DEALS_MIN_LEVELIZED_LIFE_NAME_ASC',
  DealsMinLevelizedLifeNameDesc = 'DEALS_MIN_LEVELIZED_LIFE_NAME_DESC',
  DealsMinLevelizedLifeSaleAsc = 'DEALS_MIN_LEVELIZED_LIFE_SALE_ASC',
  DealsMinLevelizedLifeSaleDesc = 'DEALS_MIN_LEVELIZED_LIFE_SALE_DESC',
  DealsMinLevelizedLifeCostAsc = 'DEALS_MIN_LEVELIZED_LIFE_COST_ASC',
  DealsMinLevelizedLifeCostDesc = 'DEALS_MIN_LEVELIZED_LIFE_COST_DESC',
  DealsMinLevelizedLifeTermAsc = 'DEALS_MIN_LEVELIZED_LIFE_TERM_ASC',
  DealsMinLevelizedLifeTermDesc = 'DEALS_MIN_LEVELIZED_LIFE_TERM_DESC',
  DealsMinLossOfEmploymentTypeAsc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsMinLossOfEmploymentTypeDesc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsMinLossOfEmploymentNameAsc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsMinLossOfEmploymentNameDesc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsMinLossOfEmploymentSaleAsc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsMinLossOfEmploymentSaleDesc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsMinLossOfEmploymentCostAsc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsMinLossOfEmploymentCostDesc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsMinLossOfEmploymentTermAsc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsMinLossOfEmploymentTermDesc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsMinGuaranteedAutoProtectionTypeAsc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsMinGuaranteedAutoProtectionTypeDesc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsMinGuaranteedAutoProtectionNameAsc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsMinGuaranteedAutoProtectionNameDesc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsMinGuaranteedAutoProtectionSaleAsc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsMinGuaranteedAutoProtectionSaleDesc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsMinGuaranteedAutoProtectionCostAsc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsMinGuaranteedAutoProtectionCostDesc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsMinGuaranteedAutoProtectionTermAsc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsMinGuaranteedAutoProtectionTermDesc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsMinSaleCommentsAsc = 'DEALS_MIN_SALE_COMMENTS_ASC',
  DealsMinSaleCommentsDesc = 'DEALS_MIN_SALE_COMMENTS_DESC',
  DealsMinCassStdLine1Asc = 'DEALS_MIN_CASS_STD_LINE1_ASC',
  DealsMinCassStdLine1Desc = 'DEALS_MIN_CASS_STD_LINE1_DESC',
  DealsMinCassStdLine2Asc = 'DEALS_MIN_CASS_STD_LINE2_ASC',
  DealsMinCassStdLine2Desc = 'DEALS_MIN_CASS_STD_LINE2_DESC',
  DealsMinCassStdCityAsc = 'DEALS_MIN_CASS_STD_CITY_ASC',
  DealsMinCassStdCityDesc = 'DEALS_MIN_CASS_STD_CITY_DESC',
  DealsMinCassStdStateAsc = 'DEALS_MIN_CASS_STD_STATE_ASC',
  DealsMinCassStdStateDesc = 'DEALS_MIN_CASS_STD_STATE_DESC',
  DealsMinCassStdZipAsc = 'DEALS_MIN_CASS_STD_ZIP_ASC',
  DealsMinCassStdZipDesc = 'DEALS_MIN_CASS_STD_ZIP_DESC',
  DealsMinCassStdZip4Asc = 'DEALS_MIN_CASS_STD_ZIP4_ASC',
  DealsMinCassStdZip4Desc = 'DEALS_MIN_CASS_STD_ZIP4_DESC',
  DealsMinCassStdDpbcAsc = 'DEALS_MIN_CASS_STD_DPBC_ASC',
  DealsMinCassStdDpbcDesc = 'DEALS_MIN_CASS_STD_DPBC_DESC',
  DealsMinCassStdChkdgtAsc = 'DEALS_MIN_CASS_STD_CHKDGT_ASC',
  DealsMinCassStdChkdgtDesc = 'DEALS_MIN_CASS_STD_CHKDGT_DESC',
  DealsMinCassStdCartAsc = 'DEALS_MIN_CASS_STD_CART_ASC',
  DealsMinCassStdCartDesc = 'DEALS_MIN_CASS_STD_CART_DESC',
  DealsMinCassStdLotAsc = 'DEALS_MIN_CASS_STD_LOT_ASC',
  DealsMinCassStdLotDesc = 'DEALS_MIN_CASS_STD_LOT_DESC',
  DealsMinCassStdLotordAsc = 'DEALS_MIN_CASS_STD_LOTORD_ASC',
  DealsMinCassStdLotordDesc = 'DEALS_MIN_CASS_STD_LOTORD_DESC',
  DealsMinCassStdUrbAsc = 'DEALS_MIN_CASS_STD_URB_ASC',
  DealsMinCassStdUrbDesc = 'DEALS_MIN_CASS_STD_URB_DESC',
  DealsMinCassStdFipsAsc = 'DEALS_MIN_CASS_STD_FIPS_ASC',
  DealsMinCassStdFipsDesc = 'DEALS_MIN_CASS_STD_FIPS_DESC',
  DealsMinCassStdEwsAsc = 'DEALS_MIN_CASS_STD_EWS_ASC',
  DealsMinCassStdEwsDesc = 'DEALS_MIN_CASS_STD_EWS_DESC',
  DealsMinCassStdLacsAsc = 'DEALS_MIN_CASS_STD_LACS_ASC',
  DealsMinCassStdLacsDesc = 'DEALS_MIN_CASS_STD_LACS_DESC',
  DealsMinCassStdZipmovAsc = 'DEALS_MIN_CASS_STD_ZIPMOV_ASC',
  DealsMinCassStdZipmovDesc = 'DEALS_MIN_CASS_STD_ZIPMOV_DESC',
  DealsMinCassStdZ4LomAsc = 'DEALS_MIN_CASS_STD_Z4LOM_ASC',
  DealsMinCassStdZ4LomDesc = 'DEALS_MIN_CASS_STD_Z4LOM_DESC',
  DealsMinCassStdNdiaptAsc = 'DEALS_MIN_CASS_STD_NDIAPT_ASC',
  DealsMinCassStdNdiaptDesc = 'DEALS_MIN_CASS_STD_NDIAPT_DESC',
  DealsMinCassStdNdirrAsc = 'DEALS_MIN_CASS_STD_NDIRR_ASC',
  DealsMinCassStdNdirrDesc = 'DEALS_MIN_CASS_STD_NDIRR_DESC',
  DealsMinCassStdLacsrtAsc = 'DEALS_MIN_CASS_STD_LACSRT_ASC',
  DealsMinCassStdLacsrtDesc = 'DEALS_MIN_CASS_STD_LACSRT_DESC',
  DealsMinCassStdErrorCdAsc = 'DEALS_MIN_CASS_STD_ERROR_CD_ASC',
  DealsMinCassStdErrorCdDesc = 'DEALS_MIN_CASS_STD_ERROR_CD_DESC',
  DealsMaxIdAsc = 'DEALS_MAX_ID_ASC',
  DealsMaxIdDesc = 'DEALS_MAX_ID_DESC',
  DealsMaxLeadIdAsc = 'DEALS_MAX_LEAD_ID_ASC',
  DealsMaxLeadIdDesc = 'DEALS_MAX_LEAD_ID_DESC',
  DealsMaxCompanyIntegrationIdAsc = 'DEALS_MAX_COMPANY_INTEGRATION_ID_ASC',
  DealsMaxCompanyIntegrationIdDesc = 'DEALS_MAX_COMPANY_INTEGRATION_ID_DESC',
  DealsMaxFileTypeAsc = 'DEALS_MAX_FILE_TYPE_ASC',
  DealsMaxFileTypeDesc = 'DEALS_MAX_FILE_TYPE_DESC',
  DealsMaxDvDealerIdAsc = 'DEALS_MAX_DV_DEALER_ID_ASC',
  DealsMaxDvDealerIdDesc = 'DEALS_MAX_DV_DEALER_ID_DESC',
  DealsMaxVendorDealerIdAsc = 'DEALS_MAX_VENDOR_DEALER_ID_ASC',
  DealsMaxVendorDealerIdDesc = 'DEALS_MAX_VENDOR_DEALER_ID_DESC',
  DealsMaxDmsTypeAsc = 'DEALS_MAX_DMS_TYPE_ASC',
  DealsMaxDmsTypeDesc = 'DEALS_MAX_DMS_TYPE_DESC',
  DealsMaxDealNumberAsc = 'DEALS_MAX_DEAL_NUMBER_ASC',
  DealsMaxDealNumberDesc = 'DEALS_MAX_DEAL_NUMBER_DESC',
  DealsMaxCustomerNumberAsc = 'DEALS_MAX_CUSTOMER_NUMBER_ASC',
  DealsMaxCustomerNumberDesc = 'DEALS_MAX_CUSTOMER_NUMBER_DESC',
  DealsMaxFullNameAsc = 'DEALS_MAX_FULL_NAME_ASC',
  DealsMaxFullNameDesc = 'DEALS_MAX_FULL_NAME_DESC',
  DealsMaxSalutationAsc = 'DEALS_MAX_SALUTATION_ASC',
  DealsMaxSalutationDesc = 'DEALS_MAX_SALUTATION_DESC',
  DealsMaxFirstNameAsc = 'DEALS_MAX_FIRST_NAME_ASC',
  DealsMaxFirstNameDesc = 'DEALS_MAX_FIRST_NAME_DESC',
  DealsMaxMiddleNameAsc = 'DEALS_MAX_MIDDLE_NAME_ASC',
  DealsMaxMiddleNameDesc = 'DEALS_MAX_MIDDLE_NAME_DESC',
  DealsMaxLastNameAsc = 'DEALS_MAX_LAST_NAME_ASC',
  DealsMaxLastNameDesc = 'DEALS_MAX_LAST_NAME_DESC',
  DealsMaxSuffixAsc = 'DEALS_MAX_SUFFIX_ASC',
  DealsMaxSuffixDesc = 'DEALS_MAX_SUFFIX_DESC',
  DealsMaxAddressLine_1Asc = 'DEALS_MAX_ADDRESS_LINE_1_ASC',
  DealsMaxAddressLine_1Desc = 'DEALS_MAX_ADDRESS_LINE_1_DESC',
  DealsMaxAddressLine_2Asc = 'DEALS_MAX_ADDRESS_LINE_2_ASC',
  DealsMaxAddressLine_2Desc = 'DEALS_MAX_ADDRESS_LINE_2_DESC',
  DealsMaxCityAsc = 'DEALS_MAX_CITY_ASC',
  DealsMaxCityDesc = 'DEALS_MAX_CITY_DESC',
  DealsMaxStateAsc = 'DEALS_MAX_STATE_ASC',
  DealsMaxStateDesc = 'DEALS_MAX_STATE_DESC',
  DealsMaxZipAsc = 'DEALS_MAX_ZIP_ASC',
  DealsMaxZipDesc = 'DEALS_MAX_ZIP_DESC',
  DealsMaxCountyAsc = 'DEALS_MAX_COUNTY_ASC',
  DealsMaxCountyDesc = 'DEALS_MAX_COUNTY_DESC',
  DealsMaxHomePhoneAsc = 'DEALS_MAX_HOME_PHONE_ASC',
  DealsMaxHomePhoneDesc = 'DEALS_MAX_HOME_PHONE_DESC',
  DealsMaxCellPhoneAsc = 'DEALS_MAX_CELL_PHONE_ASC',
  DealsMaxCellPhoneDesc = 'DEALS_MAX_CELL_PHONE_DESC',
  DealsMaxWorkPhoneAsc = 'DEALS_MAX_WORK_PHONE_ASC',
  DealsMaxWorkPhoneDesc = 'DEALS_MAX_WORK_PHONE_DESC',
  DealsMaxWorkExtensionAsc = 'DEALS_MAX_WORK_EXTENSION_ASC',
  DealsMaxWorkExtensionDesc = 'DEALS_MAX_WORK_EXTENSION_DESC',
  DealsMaxEmail_1Asc = 'DEALS_MAX_EMAIL_1_ASC',
  DealsMaxEmail_1Desc = 'DEALS_MAX_EMAIL_1_DESC',
  DealsMaxEmail_2Asc = 'DEALS_MAX_EMAIL_2_ASC',
  DealsMaxEmail_2Desc = 'DEALS_MAX_EMAIL_2_DESC',
  DealsMaxEmail_3Asc = 'DEALS_MAX_EMAIL_3_ASC',
  DealsMaxEmail_3Desc = 'DEALS_MAX_EMAIL_3_DESC',
  DealsMaxBirthDateAsc = 'DEALS_MAX_BIRTH_DATE_ASC',
  DealsMaxBirthDateDesc = 'DEALS_MAX_BIRTH_DATE_DESC',
  DealsMaxIndividualBusinessFlagAsc = 'DEALS_MAX_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsMaxIndividualBusinessFlagDesc = 'DEALS_MAX_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsMaxOptOutAsc = 'DEALS_MAX_OPT_OUT_ASC',
  DealsMaxOptOutDesc = 'DEALS_MAX_OPT_OUT_DESC',
  DealsMaxBlockEmailAsc = 'DEALS_MAX_BLOCK_EMAIL_ASC',
  DealsMaxBlockEmailDesc = 'DEALS_MAX_BLOCK_EMAIL_DESC',
  DealsMaxBlockPhoneAsc = 'DEALS_MAX_BLOCK_PHONE_ASC',
  DealsMaxBlockPhoneDesc = 'DEALS_MAX_BLOCK_PHONE_DESC',
  DealsMaxBlockMailAsc = 'DEALS_MAX_BLOCK_MAIL_ASC',
  DealsMaxBlockMailDesc = 'DEALS_MAX_BLOCK_MAIL_DESC',
  DealsMaxLanguageAsc = 'DEALS_MAX_LANGUAGE_ASC',
  DealsMaxLanguageDesc = 'DEALS_MAX_LANGUAGE_DESC',
  DealsMaxCustomerCreateDateAsc = 'DEALS_MAX_CUSTOMER_CREATE_DATE_ASC',
  DealsMaxCustomerCreateDateDesc = 'DEALS_MAX_CUSTOMER_CREATE_DATE_DESC',
  DealsMaxCustomerLastActivityDateAsc = 'DEALS_MAX_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsMaxCustomerLastActivityDateDesc = 'DEALS_MAX_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsMaxCoBuyerCustomerNumberAsc = 'DEALS_MAX_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsMaxCoBuyerCustomerNumberDesc = 'DEALS_MAX_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsMaxCoBuyerFullNameAsc = 'DEALS_MAX_CO_BUYER_FULL_NAME_ASC',
  DealsMaxCoBuyerFullNameDesc = 'DEALS_MAX_CO_BUYER_FULL_NAME_DESC',
  DealsMaxCoBuyerSalutationAsc = 'DEALS_MAX_CO_BUYER_SALUTATION_ASC',
  DealsMaxCoBuyerSalutationDesc = 'DEALS_MAX_CO_BUYER_SALUTATION_DESC',
  DealsMaxCoBuyerFirstNameAsc = 'DEALS_MAX_CO_BUYER_FIRST_NAME_ASC',
  DealsMaxCoBuyerFirstNameDesc = 'DEALS_MAX_CO_BUYER_FIRST_NAME_DESC',
  DealsMaxCoBuyerMiddleNameAsc = 'DEALS_MAX_CO_BUYER_MIDDLE_NAME_ASC',
  DealsMaxCoBuyerMiddleNameDesc = 'DEALS_MAX_CO_BUYER_MIDDLE_NAME_DESC',
  DealsMaxCoBuyerLastNameAsc = 'DEALS_MAX_CO_BUYER_LAST_NAME_ASC',
  DealsMaxCoBuyerLastNameDesc = 'DEALS_MAX_CO_BUYER_LAST_NAME_DESC',
  DealsMaxCoBuyerSuffixAsc = 'DEALS_MAX_CO_BUYER_SUFFIX_ASC',
  DealsMaxCoBuyerSuffixDesc = 'DEALS_MAX_CO_BUYER_SUFFIX_DESC',
  DealsMaxCoBuyerAddressLine_1Asc = 'DEALS_MAX_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsMaxCoBuyerAddressLine_1Desc = 'DEALS_MAX_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsMaxCoBuyerAddressLine_2Asc = 'DEALS_MAX_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsMaxCoBuyerAddressLine_2Desc = 'DEALS_MAX_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsMaxCoBuyerCityAsc = 'DEALS_MAX_CO_BUYER_CITY_ASC',
  DealsMaxCoBuyerCityDesc = 'DEALS_MAX_CO_BUYER_CITY_DESC',
  DealsMaxCoBuyerStateAsc = 'DEALS_MAX_CO_BUYER_STATE_ASC',
  DealsMaxCoBuyerStateDesc = 'DEALS_MAX_CO_BUYER_STATE_DESC',
  DealsMaxCoBuyerZipAsc = 'DEALS_MAX_CO_BUYER_ZIP_ASC',
  DealsMaxCoBuyerZipDesc = 'DEALS_MAX_CO_BUYER_ZIP_DESC',
  DealsMaxCoBuyerCountyAsc = 'DEALS_MAX_CO_BUYER_COUNTY_ASC',
  DealsMaxCoBuyerCountyDesc = 'DEALS_MAX_CO_BUYER_COUNTY_DESC',
  DealsMaxCoBuyerHomePhoneAsc = 'DEALS_MAX_CO_BUYER_HOME_PHONE_ASC',
  DealsMaxCoBuyerHomePhoneDesc = 'DEALS_MAX_CO_BUYER_HOME_PHONE_DESC',
  DealsMaxCoBuyerCellPhoneAsc = 'DEALS_MAX_CO_BUYER_CELL_PHONE_ASC',
  DealsMaxCoBuyerCellPhoneDesc = 'DEALS_MAX_CO_BUYER_CELL_PHONE_DESC',
  DealsMaxCoBuyerWorkPhoneAsc = 'DEALS_MAX_CO_BUYER_WORK_PHONE_ASC',
  DealsMaxCoBuyerWorkPhoneDesc = 'DEALS_MAX_CO_BUYER_WORK_PHONE_DESC',
  DealsMaxCoBuyerWorkExtensionAsc = 'DEALS_MAX_CO_BUYER_WORK_EXTENSION_ASC',
  DealsMaxCoBuyerWorkExtensionDesc = 'DEALS_MAX_CO_BUYER_WORK_EXTENSION_DESC',
  DealsMaxCoBuyerEmail_1Asc = 'DEALS_MAX_CO_BUYER_EMAIL_1_ASC',
  DealsMaxCoBuyerEmail_1Desc = 'DEALS_MAX_CO_BUYER_EMAIL_1_DESC',
  DealsMaxCoBuyerEmail_2Asc = 'DEALS_MAX_CO_BUYER_EMAIL_2_ASC',
  DealsMaxCoBuyerEmail_2Desc = 'DEALS_MAX_CO_BUYER_EMAIL_2_DESC',
  DealsMaxCoBuyerEmail_3Asc = 'DEALS_MAX_CO_BUYER_EMAIL_3_ASC',
  DealsMaxCoBuyerEmail_3Desc = 'DEALS_MAX_CO_BUYER_EMAIL_3_DESC',
  DealsMaxCoBuyerBirthDateAsc = 'DEALS_MAX_CO_BUYER_BIRTH_DATE_ASC',
  DealsMaxCoBuyerBirthDateDesc = 'DEALS_MAX_CO_BUYER_BIRTH_DATE_DESC',
  DealsMaxCoBuyerIndividualBusinessFlagAsc = 'DEALS_MAX_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsMaxCoBuyerIndividualBusinessFlagDesc = 'DEALS_MAX_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsMaxCoBuyerOptOutAsc = 'DEALS_MAX_CO_BUYER_OPT_OUT_ASC',
  DealsMaxCoBuyerOptOutDesc = 'DEALS_MAX_CO_BUYER_OPT_OUT_DESC',
  DealsMaxCoBuyerBlockEmailAsc = 'DEALS_MAX_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsMaxCoBuyerBlockEmailDesc = 'DEALS_MAX_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsMaxCoBuyerBlockPhoneAsc = 'DEALS_MAX_CO_BUYER_BLOCK_PHONE_ASC',
  DealsMaxCoBuyerBlockPhoneDesc = 'DEALS_MAX_CO_BUYER_BLOCK_PHONE_DESC',
  DealsMaxCoBuyerBlockMailAsc = 'DEALS_MAX_CO_BUYER_BLOCK_MAIL_ASC',
  DealsMaxCoBuyerBlockMailDesc = 'DEALS_MAX_CO_BUYER_BLOCK_MAIL_DESC',
  DealsMaxVinAsc = 'DEALS_MAX_VIN_ASC',
  DealsMaxVinDesc = 'DEALS_MAX_VIN_DESC',
  DealsMaxYearAsc = 'DEALS_MAX_YEAR_ASC',
  DealsMaxYearDesc = 'DEALS_MAX_YEAR_DESC',
  DealsMaxMakeAsc = 'DEALS_MAX_MAKE_ASC',
  DealsMaxMakeDesc = 'DEALS_MAX_MAKE_DESC',
  DealsMaxModelAsc = 'DEALS_MAX_MODEL_ASC',
  DealsMaxModelDesc = 'DEALS_MAX_MODEL_DESC',
  DealsMaxModelNumberAsc = 'DEALS_MAX_MODEL_NUMBER_ASC',
  DealsMaxModelNumberDesc = 'DEALS_MAX_MODEL_NUMBER_DESC',
  DealsMaxMileageAsc = 'DEALS_MAX_MILEAGE_ASC',
  DealsMaxMileageDesc = 'DEALS_MAX_MILEAGE_DESC',
  DealsMaxDescriptionAsc = 'DEALS_MAX_DESCRIPTION_ASC',
  DealsMaxDescriptionDesc = 'DEALS_MAX_DESCRIPTION_DESC',
  DealsMaxExteriorColorAsc = 'DEALS_MAX_EXTERIOR_COLOR_ASC',
  DealsMaxExteriorColorDesc = 'DEALS_MAX_EXTERIOR_COLOR_DESC',
  DealsMaxNewUsedAsc = 'DEALS_MAX_NEW_USED_ASC',
  DealsMaxNewUsedDesc = 'DEALS_MAX_NEW_USED_DESC',
  DealsMaxStockNumberAsc = 'DEALS_MAX_STOCK_NUMBER_ASC',
  DealsMaxStockNumberDesc = 'DEALS_MAX_STOCK_NUMBER_DESC',
  DealsMaxTransmissionAsc = 'DEALS_MAX_TRANSMISSION_ASC',
  DealsMaxTransmissionDesc = 'DEALS_MAX_TRANSMISSION_DESC',
  DealsMaxEngineConfigurationAsc = 'DEALS_MAX_ENGINE_CONFIGURATION_ASC',
  DealsMaxEngineConfigurationDesc = 'DEALS_MAX_ENGINE_CONFIGURATION_DESC',
  DealsMaxTrimAsc = 'DEALS_MAX_TRIM_ASC',
  DealsMaxTrimDesc = 'DEALS_MAX_TRIM_DESC',
  DealsMaxEngineNumberAsc = 'DEALS_MAX_ENGINE_NUMBER_ASC',
  DealsMaxEngineNumberDesc = 'DEALS_MAX_ENGINE_NUMBER_DESC',
  DealsMaxChassisNumberAsc = 'DEALS_MAX_CHASSIS_NUMBER_ASC',
  DealsMaxChassisNumberDesc = 'DEALS_MAX_CHASSIS_NUMBER_DESC',
  DealsMaxLicensePlateNumberAsc = 'DEALS_MAX_LICENSE_PLATE_NUMBER_ASC',
  DealsMaxLicensePlateNumberDesc = 'DEALS_MAX_LICENSE_PLATE_NUMBER_DESC',
  DealsMaxDeliveryDateAsc = 'DEALS_MAX_DELIVERY_DATE_ASC',
  DealsMaxDeliveryDateDesc = 'DEALS_MAX_DELIVERY_DATE_DESC',
  DealsMaxDeliveryMileageAsc = 'DEALS_MAX_DELIVERY_MILEAGE_ASC',
  DealsMaxDeliveryMileageDesc = 'DEALS_MAX_DELIVERY_MILEAGE_DESC',
  DealsMaxInventoryDateAsc = 'DEALS_MAX_INVENTORY_DATE_ASC',
  DealsMaxInventoryDateDesc = 'DEALS_MAX_INVENTORY_DATE_DESC',
  DealsMaxInServiceDateAsc = 'DEALS_MAX_IN_SERVICE_DATE_ASC',
  DealsMaxInServiceDateDesc = 'DEALS_MAX_IN_SERVICE_DATE_DESC',
  DealsMaxVinExplosionYearAsc = 'DEALS_MAX_VIN_EXPLOSION_YEAR_ASC',
  DealsMaxVinExplosionYearDesc = 'DEALS_MAX_VIN_EXPLOSION_YEAR_DESC',
  DealsMaxVinExplosionMakeAsc = 'DEALS_MAX_VIN_EXPLOSION_MAKE_ASC',
  DealsMaxVinExplosionMakeDesc = 'DEALS_MAX_VIN_EXPLOSION_MAKE_DESC',
  DealsMaxVinExplosionModelAsc = 'DEALS_MAX_VIN_EXPLOSION_MODEL_ASC',
  DealsMaxVinExplosionModelDesc = 'DEALS_MAX_VIN_EXPLOSION_MODEL_DESC',
  DealsMaxVinExplosionTrimAsc = 'DEALS_MAX_VIN_EXPLOSION_TRIM_ASC',
  DealsMaxVinExplosionTrimDesc = 'DEALS_MAX_VIN_EXPLOSION_TRIM_DESC',
  DealsMaxVinExplosionTransmissionTypeAsc = 'DEALS_MAX_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsMaxVinExplosionTransmissionTypeDesc = 'DEALS_MAX_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsMaxVinExplosionFuelTypeAsc = 'DEALS_MAX_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsMaxVinExplosionFuelTypeDesc = 'DEALS_MAX_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsMaxVinExplosionEngineSizeAsc = 'DEALS_MAX_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsMaxVinExplosionEngineSizeDesc = 'DEALS_MAX_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsMaxVinExplosionGvwRangeAsc = 'DEALS_MAX_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsMaxVinExplosionGvwRangeDesc = 'DEALS_MAX_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsMaxTrade_1VinAsc = 'DEALS_MAX_TRADE_1_VIN_ASC',
  DealsMaxTrade_1VinDesc = 'DEALS_MAX_TRADE_1_VIN_DESC',
  DealsMaxTrade_1YearAsc = 'DEALS_MAX_TRADE_1_YEAR_ASC',
  DealsMaxTrade_1YearDesc = 'DEALS_MAX_TRADE_1_YEAR_DESC',
  DealsMaxTrade_1MakeAsc = 'DEALS_MAX_TRADE_1_MAKE_ASC',
  DealsMaxTrade_1MakeDesc = 'DEALS_MAX_TRADE_1_MAKE_DESC',
  DealsMaxTrade_1ModelAsc = 'DEALS_MAX_TRADE_1_MODEL_ASC',
  DealsMaxTrade_1ModelDesc = 'DEALS_MAX_TRADE_1_MODEL_DESC',
  DealsMaxTrade_1OdometerAsc = 'DEALS_MAX_TRADE_1_ODOMETER_ASC',
  DealsMaxTrade_1OdometerDesc = 'DEALS_MAX_TRADE_1_ODOMETER_DESC',
  DealsMaxTrade_1ActualCashValueAsc = 'DEALS_MAX_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsMaxTrade_1ActualCashValueDesc = 'DEALS_MAX_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsMaxTrade_1GrossAsc = 'DEALS_MAX_TRADE_1_GROSS_ASC',
  DealsMaxTrade_1GrossDesc = 'DEALS_MAX_TRADE_1_GROSS_DESC',
  DealsMaxTrade_1PayoffAsc = 'DEALS_MAX_TRADE_1_PAYOFF_ASC',
  DealsMaxTrade_1PayoffDesc = 'DEALS_MAX_TRADE_1_PAYOFF_DESC',
  DealsMaxTrade_2VinAsc = 'DEALS_MAX_TRADE_2_VIN_ASC',
  DealsMaxTrade_2VinDesc = 'DEALS_MAX_TRADE_2_VIN_DESC',
  DealsMaxTrade_2YearAsc = 'DEALS_MAX_TRADE_2_YEAR_ASC',
  DealsMaxTrade_2YearDesc = 'DEALS_MAX_TRADE_2_YEAR_DESC',
  DealsMaxTrade_2MakeAsc = 'DEALS_MAX_TRADE_2_MAKE_ASC',
  DealsMaxTrade_2MakeDesc = 'DEALS_MAX_TRADE_2_MAKE_DESC',
  DealsMaxTrade_2ModelAsc = 'DEALS_MAX_TRADE_2_MODEL_ASC',
  DealsMaxTrade_2ModelDesc = 'DEALS_MAX_TRADE_2_MODEL_DESC',
  DealsMaxTrade_2OdometerAsc = 'DEALS_MAX_TRADE_2_ODOMETER_ASC',
  DealsMaxTrade_2OdometerDesc = 'DEALS_MAX_TRADE_2_ODOMETER_DESC',
  DealsMaxTrade_2ActualCashValueAsc = 'DEALS_MAX_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsMaxTrade_2ActualCashValueDesc = 'DEALS_MAX_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsMaxTrade_2GrossAsc = 'DEALS_MAX_TRADE_2_GROSS_ASC',
  DealsMaxTrade_2GrossDesc = 'DEALS_MAX_TRADE_2_GROSS_DESC',
  DealsMaxTrade_2PayoffAsc = 'DEALS_MAX_TRADE_2_PAYOFF_ASC',
  DealsMaxTrade_2PayoffDesc = 'DEALS_MAX_TRADE_2_PAYOFF_DESC',
  DealsMaxSalesman_1NumberAsc = 'DEALS_MAX_SALESMAN_1_NUMBER_ASC',
  DealsMaxSalesman_1NumberDesc = 'DEALS_MAX_SALESMAN_1_NUMBER_DESC',
  DealsMaxSalesman_1NameAsc = 'DEALS_MAX_SALESMAN_1_NAME_ASC',
  DealsMaxSalesman_1NameDesc = 'DEALS_MAX_SALESMAN_1_NAME_DESC',
  DealsMaxSalesman_2NumberAsc = 'DEALS_MAX_SALESMAN_2_NUMBER_ASC',
  DealsMaxSalesman_2NumberDesc = 'DEALS_MAX_SALESMAN_2_NUMBER_DESC',
  DealsMaxSalesman_2NameAsc = 'DEALS_MAX_SALESMAN_2_NAME_ASC',
  DealsMaxSalesman_2NameDesc = 'DEALS_MAX_SALESMAN_2_NAME_DESC',
  DealsMaxSalesman_3NumberAsc = 'DEALS_MAX_SALESMAN_3_NUMBER_ASC',
  DealsMaxSalesman_3NumberDesc = 'DEALS_MAX_SALESMAN_3_NUMBER_DESC',
  DealsMaxSalesman_3NameAsc = 'DEALS_MAX_SALESMAN_3_NAME_ASC',
  DealsMaxSalesman_3NameDesc = 'DEALS_MAX_SALESMAN_3_NAME_DESC',
  DealsMaxClosingManagerNumberAsc = 'DEALS_MAX_CLOSING_MANAGER_NUMBER_ASC',
  DealsMaxClosingManagerNumberDesc = 'DEALS_MAX_CLOSING_MANAGER_NUMBER_DESC',
  DealsMaxClosingManagerNameAsc = 'DEALS_MAX_CLOSING_MANAGER_NAME_ASC',
  DealsMaxClosingManagerNameDesc = 'DEALS_MAX_CLOSING_MANAGER_NAME_DESC',
  DealsMaxFinanceManagerNumberAsc = 'DEALS_MAX_FINANCE_MANAGER_NUMBER_ASC',
  DealsMaxFinanceManagerNumberDesc = 'DEALS_MAX_FINANCE_MANAGER_NUMBER_DESC',
  DealsMaxFinanceManagerNameAsc = 'DEALS_MAX_FINANCE_MANAGER_NAME_ASC',
  DealsMaxFinanceManagerNameDesc = 'DEALS_MAX_FINANCE_MANAGER_NAME_DESC',
  DealsMaxSalesmanManagerNumberAsc = 'DEALS_MAX_SALESMAN_MANAGER_NUMBER_ASC',
  DealsMaxSalesmanManagerNumberDesc = 'DEALS_MAX_SALESMAN_MANAGER_NUMBER_DESC',
  DealsMaxSalesmanManagerNameAsc = 'DEALS_MAX_SALESMAN_MANAGER_NAME_ASC',
  DealsMaxSalesmanManagerNameDesc = 'DEALS_MAX_SALESMAN_MANAGER_NAME_DESC',
  DealsMaxMsrpAsc = 'DEALS_MAX_MSRP_ASC',
  DealsMaxMsrpDesc = 'DEALS_MAX_MSRP_DESC',
  DealsMaxListPriceAsc = 'DEALS_MAX_LIST_PRICE_ASC',
  DealsMaxListPriceDesc = 'DEALS_MAX_LIST_PRICE_DESC',
  DealsMaxSalesPriceAsc = 'DEALS_MAX_SALES_PRICE_ASC',
  DealsMaxSalesPriceDesc = 'DEALS_MAX_SALES_PRICE_DESC',
  DealsMaxJournalPriceAsc = 'DEALS_MAX_JOURNAL_PRICE_ASC',
  DealsMaxJournalPriceDesc = 'DEALS_MAX_JOURNAL_PRICE_DESC',
  DealsMaxCostAsc = 'DEALS_MAX_COST_ASC',
  DealsMaxCostDesc = 'DEALS_MAX_COST_DESC',
  DealsMaxJournalCostAsc = 'DEALS_MAX_JOURNAL_COST_ASC',
  DealsMaxJournalCostDesc = 'DEALS_MAX_JOURNAL_COST_DESC',
  DealsMaxAdjustmentsAsc = 'DEALS_MAX_ADJUSTMENTS_ASC',
  DealsMaxAdjustmentsDesc = 'DEALS_MAX_ADJUSTMENTS_DESC',
  DealsMaxAdjustedCostAsc = 'DEALS_MAX_ADJUSTED_COST_ASC',
  DealsMaxAdjustedCostDesc = 'DEALS_MAX_ADJUSTED_COST_DESC',
  DealsMaxIncentivesAsc = 'DEALS_MAX_INCENTIVES_ASC',
  DealsMaxIncentivesDesc = 'DEALS_MAX_INCENTIVES_DESC',
  DealsMaxPackAmountAsc = 'DEALS_MAX_PACK_AMOUNT_ASC',
  DealsMaxPackAmountDesc = 'DEALS_MAX_PACK_AMOUNT_DESC',
  DealsMaxSaleNetAsc = 'DEALS_MAX_SALE_NET_ASC',
  DealsMaxSaleNetDesc = 'DEALS_MAX_SALE_NET_DESC',
  DealsMaxTotalTradeActualCashValueAsc = 'DEALS_MAX_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsMaxTotalTradeActualCashValueDesc = 'DEALS_MAX_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsMaxTotalTradeGrossAsc = 'DEALS_MAX_TOTAL_TRADE_GROSS_ASC',
  DealsMaxTotalTradeGrossDesc = 'DEALS_MAX_TOTAL_TRADE_GROSS_DESC',
  DealsMaxWeOweFrontAsc = 'DEALS_MAX_WE_OWE_FRONT_ASC',
  DealsMaxWeOweFrontDesc = 'DEALS_MAX_WE_OWE_FRONT_DESC',
  DealsMaxTotalFrontFeesAftermarketProfitAsc = 'DEALS_MAX_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsMaxTotalFrontFeesAftermarketProfitDesc = 'DEALS_MAX_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsMaxTotalFrontCommissionAsc = 'DEALS_MAX_TOTAL_FRONT_COMMISSION_ASC',
  DealsMaxTotalFrontCommissionDesc = 'DEALS_MAX_TOTAL_FRONT_COMMISSION_DESC',
  DealsMaxTotalFrontSalesAsc = 'DEALS_MAX_TOTAL_FRONT_SALES_ASC',
  DealsMaxTotalFrontSalesDesc = 'DEALS_MAX_TOTAL_FRONT_SALES_DESC',
  DealsMaxTotalFrontCostAsc = 'DEALS_MAX_TOTAL_FRONT_COST_ASC',
  DealsMaxTotalFrontCostDesc = 'DEALS_MAX_TOTAL_FRONT_COST_DESC',
  DealsMaxFrontGrossAsc = 'DEALS_MAX_FRONT_GROSS_ASC',
  DealsMaxFrontGrossDesc = 'DEALS_MAX_FRONT_GROSS_DESC',
  DealsMaxFinanceProfitAsc = 'DEALS_MAX_FINANCE_PROFIT_ASC',
  DealsMaxFinanceProfitDesc = 'DEALS_MAX_FINANCE_PROFIT_DESC',
  DealsMaxTotalWarrantyProfitAsc = 'DEALS_MAX_TOTAL_WARRANTY_PROFIT_ASC',
  DealsMaxTotalWarrantyProfitDesc = 'DEALS_MAX_TOTAL_WARRANTY_PROFIT_DESC',
  DealsMaxWeOweBackAsc = 'DEALS_MAX_WE_OWE_BACK_ASC',
  DealsMaxWeOweBackDesc = 'DEALS_MAX_WE_OWE_BACK_DESC',
  DealsMaxInsuranceProfitAsc = 'DEALS_MAX_INSURANCE_PROFIT_ASC',
  DealsMaxInsuranceProfitDesc = 'DEALS_MAX_INSURANCE_PROFIT_DESC',
  DealsMaxTotalBackFeesAftermarketProfitAsc = 'DEALS_MAX_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsMaxTotalBackFeesAftermarketProfitDesc = 'DEALS_MAX_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsMaxFinanceReserveAsc = 'DEALS_MAX_FINANCE_RESERVE_ASC',
  DealsMaxFinanceReserveDesc = 'DEALS_MAX_FINANCE_RESERVE_DESC',
  DealsMaxTotalBackCommissionAsc = 'DEALS_MAX_TOTAL_BACK_COMMISSION_ASC',
  DealsMaxTotalBackCommissionDesc = 'DEALS_MAX_TOTAL_BACK_COMMISSION_DESC',
  DealsMaxTotalBackSalesAsc = 'DEALS_MAX_TOTAL_BACK_SALES_ASC',
  DealsMaxTotalBackSalesDesc = 'DEALS_MAX_TOTAL_BACK_SALES_DESC',
  DealsMaxTotalBackCostAsc = 'DEALS_MAX_TOTAL_BACK_COST_ASC',
  DealsMaxTotalBackCostDesc = 'DEALS_MAX_TOTAL_BACK_COST_DESC',
  DealsMaxBackGrossAsc = 'DEALS_MAX_BACK_GROSS_ASC',
  DealsMaxBackGrossDesc = 'DEALS_MAX_BACK_GROSS_DESC',
  DealsMaxTotalProfitAsc = 'DEALS_MAX_TOTAL_PROFIT_ASC',
  DealsMaxTotalProfitDesc = 'DEALS_MAX_TOTAL_PROFIT_DESC',
  DealsMaxGrossProfitAsc = 'DEALS_MAX_GROSS_PROFIT_ASC',
  DealsMaxGrossProfitDesc = 'DEALS_MAX_GROSS_PROFIT_DESC',
  DealsMaxGrossPayableAsc = 'DEALS_MAX_GROSS_PAYABLE_ASC',
  DealsMaxGrossPayableDesc = 'DEALS_MAX_GROSS_PAYABLE_DESC',
  DealsMaxDealStatusAsc = 'DEALS_MAX_DEAL_STATUS_ASC',
  DealsMaxDealStatusDesc = 'DEALS_MAX_DEAL_STATUS_DESC',
  DealsMaxEntryDateAsc = 'DEALS_MAX_ENTRY_DATE_ASC',
  DealsMaxEntryDateDesc = 'DEALS_MAX_ENTRY_DATE_DESC',
  DealsMaxBookedDateAsc = 'DEALS_MAX_BOOKED_DATE_ASC',
  DealsMaxBookedDateDesc = 'DEALS_MAX_BOOKED_DATE_DESC',
  DealsMaxFinalizedDateAsc = 'DEALS_MAX_FINALIZED_DATE_ASC',
  DealsMaxFinalizedDateDesc = 'DEALS_MAX_FINALIZED_DATE_DESC',
  DealsMaxContractDateAsc = 'DEALS_MAX_CONTRACT_DATE_ASC',
  DealsMaxContractDateDesc = 'DEALS_MAX_CONTRACT_DATE_DESC',
  DealsMaxAccountingDateAsc = 'DEALS_MAX_ACCOUNTING_DATE_ASC',
  DealsMaxAccountingDateDesc = 'DEALS_MAX_ACCOUNTING_DATE_DESC',
  DealsMaxStatusChangeDateAsc = 'DEALS_MAX_STATUS_CHANGE_DATE_ASC',
  DealsMaxStatusChangeDateDesc = 'DEALS_MAX_STATUS_CHANGE_DATE_DESC',
  DealsMaxFirstPayDateAsc = 'DEALS_MAX_FIRST_PAY_DATE_ASC',
  DealsMaxFirstPayDateDesc = 'DEALS_MAX_FIRST_PAY_DATE_DESC',
  DealsMaxDealTypeAsc = 'DEALS_MAX_DEAL_TYPE_ASC',
  DealsMaxDealTypeDesc = 'DEALS_MAX_DEAL_TYPE_DESC',
  DealsMaxSaleTypeAsc = 'DEALS_MAX_SALE_TYPE_ASC',
  DealsMaxSaleTypeDesc = 'DEALS_MAX_SALE_TYPE_DESC',
  DealsMaxBankIdAsc = 'DEALS_MAX_BANK_ID_ASC',
  DealsMaxBankIdDesc = 'DEALS_MAX_BANK_ID_DESC',
  DealsMaxBankNameAsc = 'DEALS_MAX_BANK_NAME_ASC',
  DealsMaxBankNameDesc = 'DEALS_MAX_BANK_NAME_DESC',
  DealsMaxBankAddressAsc = 'DEALS_MAX_BANK_ADDRESS_ASC',
  DealsMaxBankAddressDesc = 'DEALS_MAX_BANK_ADDRESS_DESC',
  DealsMaxTermAsc = 'DEALS_MAX_TERM_ASC',
  DealsMaxTermDesc = 'DEALS_MAX_TERM_DESC',
  DealsMaxAmountFinancedAsc = 'DEALS_MAX_AMOUNT_FINANCED_ASC',
  DealsMaxAmountFinancedDesc = 'DEALS_MAX_AMOUNT_FINANCED_DESC',
  DealsMaxAprAsc = 'DEALS_MAX_APR_ASC',
  DealsMaxAprDesc = 'DEALS_MAX_APR_DESC',
  DealsMaxMonthlyPaymentAsc = 'DEALS_MAX_MONTHLY_PAYMENT_ASC',
  DealsMaxMonthlyPaymentDesc = 'DEALS_MAX_MONTHLY_PAYMENT_DESC',
  DealsMaxPaymentTotalAsc = 'DEALS_MAX_PAYMENT_TOTAL_ASC',
  DealsMaxPaymentTotalDesc = 'DEALS_MAX_PAYMENT_TOTAL_DESC',
  DealsMaxRebatesAsc = 'DEALS_MAX_REBATES_ASC',
  DealsMaxRebatesDesc = 'DEALS_MAX_REBATES_DESC',
  DealsMaxDepositAsc = 'DEALS_MAX_DEPOSIT_ASC',
  DealsMaxDepositDesc = 'DEALS_MAX_DEPOSIT_DESC',
  DealsMaxDownPaymentAsc = 'DEALS_MAX_DOWN_PAYMENT_ASC',
  DealsMaxDownPaymentDesc = 'DEALS_MAX_DOWN_PAYMENT_DESC',
  DealsMaxTotalNetTradesAsc = 'DEALS_MAX_TOTAL_NET_TRADES_ASC',
  DealsMaxTotalNetTradesDesc = 'DEALS_MAX_TOTAL_NET_TRADES_DESC',
  DealsMaxTotalDownAsc = 'DEALS_MAX_TOTAL_DOWN_ASC',
  DealsMaxTotalDownDesc = 'DEALS_MAX_TOTAL_DOWN_DESC',
  DealsMaxBalloonAmountAsc = 'DEALS_MAX_BALLOON_AMOUNT_ASC',
  DealsMaxBalloonAmountDesc = 'DEALS_MAX_BALLOON_AMOUNT_DESC',
  DealsMaxAdjustedBalloonAmountAsc = 'DEALS_MAX_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsMaxAdjustedBalloonAmountDesc = 'DEALS_MAX_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsMaxHoldbackAmountAsc = 'DEALS_MAX_HOLDBACK_AMOUNT_ASC',
  DealsMaxHoldbackAmountDesc = 'DEALS_MAX_HOLDBACK_AMOUNT_DESC',
  DealsMaxTotalDriveOffAmountAsc = 'DEALS_MAX_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsMaxTotalDriveOffAmountDesc = 'DEALS_MAX_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsMaxLicenseFeeAsc = 'DEALS_MAX_LICENSE_FEE_ASC',
  DealsMaxLicenseFeeDesc = 'DEALS_MAX_LICENSE_FEE_DESC',
  DealsMaxRegistrationFeeAsc = 'DEALS_MAX_REGISTRATION_FEE_ASC',
  DealsMaxRegistrationFeeDesc = 'DEALS_MAX_REGISTRATION_FEE_DESC',
  DealsMaxDocumentationFeeAsc = 'DEALS_MAX_DOCUMENTATION_FEE_ASC',
  DealsMaxDocumentationFeeDesc = 'DEALS_MAX_DOCUMENTATION_FEE_DESC',
  DealsMaxFinanceChargeAsc = 'DEALS_MAX_FINANCE_CHARGE_ASC',
  DealsMaxFinanceChargeDesc = 'DEALS_MAX_FINANCE_CHARGE_DESC',
  DealsMaxTotalPickupPaymentsAsc = 'DEALS_MAX_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsMaxTotalPickupPaymentsDesc = 'DEALS_MAX_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsMaxSellRateAsc = 'DEALS_MAX_SELL_RATE_ASC',
  DealsMaxSellRateDesc = 'DEALS_MAX_SELL_RATE_DESC',
  DealsMaxBuyRateAsc = 'DEALS_MAX_BUY_RATE_ASC',
  DealsMaxBuyRateDesc = 'DEALS_MAX_BUY_RATE_DESC',
  DealsMaxResidualRateAsc = 'DEALS_MAX_RESIDUAL_RATE_ASC',
  DealsMaxResidualRateDesc = 'DEALS_MAX_RESIDUAL_RATE_DESC',
  DealsMaxResidualAmountAsc = 'DEALS_MAX_RESIDUAL_AMOUNT_ASC',
  DealsMaxResidualAmountDesc = 'DEALS_MAX_RESIDUAL_AMOUNT_DESC',
  DealsMaxAllowedMilesAsc = 'DEALS_MAX_ALLOWED_MILES_ASC',
  DealsMaxAllowedMilesDesc = 'DEALS_MAX_ALLOWED_MILES_DESC',
  DealsMaxEstimatedMilesAsc = 'DEALS_MAX_ESTIMATED_MILES_ASC',
  DealsMaxEstimatedMilesDesc = 'DEALS_MAX_ESTIMATED_MILES_DESC',
  DealsMaxMileageRateAsc = 'DEALS_MAX_MILEAGE_RATE_ASC',
  DealsMaxMileageRateDesc = 'DEALS_MAX_MILEAGE_RATE_DESC',
  DealsMaxAcquisitionFeeAsc = 'DEALS_MAX_ACQUISITION_FEE_ASC',
  DealsMaxAcquisitionFeeDesc = 'DEALS_MAX_ACQUISITION_FEE_DESC',
  DealsMaxBasePaymentAsc = 'DEALS_MAX_BASE_PAYMENT_ASC',
  DealsMaxBasePaymentDesc = 'DEALS_MAX_BASE_PAYMENT_DESC',
  DealsMaxSecurityDepositAsc = 'DEALS_MAX_SECURITY_DEPOSIT_ASC',
  DealsMaxSecurityDepositDesc = 'DEALS_MAX_SECURITY_DEPOSIT_DESC',
  DealsMaxTotalCapitalReductionAsc = 'DEALS_MAX_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsMaxTotalCapitalReductionDesc = 'DEALS_MAX_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsMaxNetCapitalCostAsc = 'DEALS_MAX_NET_CAPITAL_COST_ASC',
  DealsMaxNetCapitalCostDesc = 'DEALS_MAX_NET_CAPITAL_COST_DESC',
  DealsMaxLeaseDepreciationValueAsc = 'DEALS_MAX_LEASE_DEPRECIATION_VALUE_ASC',
  DealsMaxLeaseDepreciationValueDesc = 'DEALS_MAX_LEASE_DEPRECIATION_VALUE_DESC',
  DealsMaxDealerFeesAsc = 'DEALS_MAX_DEALER_FEES_ASC',
  DealsMaxDealerFeesDesc = 'DEALS_MAX_DEALER_FEES_DESC',
  DealsMaxGovernmentFeesAsc = 'DEALS_MAX_GOVERNMENT_FEES_ASC',
  DealsMaxGovernmentFeesDesc = 'DEALS_MAX_GOVERNMENT_FEES_DESC',
  DealsMaxTotalTaxAsc = 'DEALS_MAX_TOTAL_TAX_ASC',
  DealsMaxTotalTaxDesc = 'DEALS_MAX_TOTAL_TAX_DESC',
  DealsMaxRegistrationStateAsc = 'DEALS_MAX_REGISTRATION_STATE_ASC',
  DealsMaxRegistrationStateDesc = 'DEALS_MAX_REGISTRATION_STATE_DESC',
  DealsMaxReportOfSaleNumberAsc = 'DEALS_MAX_REPORT_OF_SALE_NUMBER_ASC',
  DealsMaxReportOfSaleNumberDesc = 'DEALS_MAX_REPORT_OF_SALE_NUMBER_DESC',
  DealsMaxSalesman_1TotalCommissionAsc = 'DEALS_MAX_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsMaxSalesman_1TotalCommissionDesc = 'DEALS_MAX_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsMaxSalesman_1FrontCommissionAsc = 'DEALS_MAX_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsMaxSalesman_1FrontCommissionDesc = 'DEALS_MAX_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsMaxSalesman_1BackCommissionAsc = 'DEALS_MAX_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsMaxSalesman_1BackCommissionDesc = 'DEALS_MAX_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsMaxSalesman_2TotalCommissionAsc = 'DEALS_MAX_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsMaxSalesman_2TotalCommissionDesc = 'DEALS_MAX_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsMaxSalesman_2FrontCommissionAsc = 'DEALS_MAX_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsMaxSalesman_2FrontCommissionDesc = 'DEALS_MAX_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsMaxSalesman_2BackCommissionAsc = 'DEALS_MAX_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsMaxSalesman_2BackCommissionDesc = 'DEALS_MAX_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsMaxSalesman_3TotalCommissionAsc = 'DEALS_MAX_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsMaxSalesman_3TotalCommissionDesc = 'DEALS_MAX_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsMaxSalesman_3FrontCommissionAsc = 'DEALS_MAX_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsMaxSalesman_3FrontCommissionDesc = 'DEALS_MAX_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsMaxSalesman_3BackCommissionAsc = 'DEALS_MAX_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsMaxSalesman_3BackCommissionDesc = 'DEALS_MAX_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsMaxWarranty_1NameAsc = 'DEALS_MAX_WARRANTY_1_NAME_ASC',
  DealsMaxWarranty_1NameDesc = 'DEALS_MAX_WARRANTY_1_NAME_DESC',
  DealsMaxWarranty_1SaleAsc = 'DEALS_MAX_WARRANTY_1_SALE_ASC',
  DealsMaxWarranty_1SaleDesc = 'DEALS_MAX_WARRANTY_1_SALE_DESC',
  DealsMaxWarranty_1CostAsc = 'DEALS_MAX_WARRANTY_1_COST_ASC',
  DealsMaxWarranty_1CostDesc = 'DEALS_MAX_WARRANTY_1_COST_DESC',
  DealsMaxWarranty_1MilesAsc = 'DEALS_MAX_WARRANTY_1_MILES_ASC',
  DealsMaxWarranty_1MilesDesc = 'DEALS_MAX_WARRANTY_1_MILES_DESC',
  DealsMaxWarranty_1TermAsc = 'DEALS_MAX_WARRANTY_1_TERM_ASC',
  DealsMaxWarranty_1TermDesc = 'DEALS_MAX_WARRANTY_1_TERM_DESC',
  DealsMaxWarranty_2NameAsc = 'DEALS_MAX_WARRANTY_2_NAME_ASC',
  DealsMaxWarranty_2NameDesc = 'DEALS_MAX_WARRANTY_2_NAME_DESC',
  DealsMaxWarranty_2SaleAsc = 'DEALS_MAX_WARRANTY_2_SALE_ASC',
  DealsMaxWarranty_2SaleDesc = 'DEALS_MAX_WARRANTY_2_SALE_DESC',
  DealsMaxWarranty_2CostAsc = 'DEALS_MAX_WARRANTY_2_COST_ASC',
  DealsMaxWarranty_2CostDesc = 'DEALS_MAX_WARRANTY_2_COST_DESC',
  DealsMaxWarranty_2MilesAsc = 'DEALS_MAX_WARRANTY_2_MILES_ASC',
  DealsMaxWarranty_2MilesDesc = 'DEALS_MAX_WARRANTY_2_MILES_DESC',
  DealsMaxWarranty_2TermAsc = 'DEALS_MAX_WARRANTY_2_TERM_ASC',
  DealsMaxWarranty_2TermDesc = 'DEALS_MAX_WARRANTY_2_TERM_DESC',
  DealsMaxWarranty_3NameAsc = 'DEALS_MAX_WARRANTY_3_NAME_ASC',
  DealsMaxWarranty_3NameDesc = 'DEALS_MAX_WARRANTY_3_NAME_DESC',
  DealsMaxWarranty_3SaleAsc = 'DEALS_MAX_WARRANTY_3_SALE_ASC',
  DealsMaxWarranty_3SaleDesc = 'DEALS_MAX_WARRANTY_3_SALE_DESC',
  DealsMaxWarranty_3CostAsc = 'DEALS_MAX_WARRANTY_3_COST_ASC',
  DealsMaxWarranty_3CostDesc = 'DEALS_MAX_WARRANTY_3_COST_DESC',
  DealsMaxWarranty_3MilesAsc = 'DEALS_MAX_WARRANTY_3_MILES_ASC',
  DealsMaxWarranty_3MilesDesc = 'DEALS_MAX_WARRANTY_3_MILES_DESC',
  DealsMaxWarranty_3TermAsc = 'DEALS_MAX_WARRANTY_3_TERM_ASC',
  DealsMaxWarranty_3TermDesc = 'DEALS_MAX_WARRANTY_3_TERM_DESC',
  DealsMaxWarranty_4NameAsc = 'DEALS_MAX_WARRANTY_4_NAME_ASC',
  DealsMaxWarranty_4NameDesc = 'DEALS_MAX_WARRANTY_4_NAME_DESC',
  DealsMaxWarranty_4SaleAsc = 'DEALS_MAX_WARRANTY_4_SALE_ASC',
  DealsMaxWarranty_4SaleDesc = 'DEALS_MAX_WARRANTY_4_SALE_DESC',
  DealsMaxWarranty_4CostAsc = 'DEALS_MAX_WARRANTY_4_COST_ASC',
  DealsMaxWarranty_4CostDesc = 'DEALS_MAX_WARRANTY_4_COST_DESC',
  DealsMaxWarranty_4MilesAsc = 'DEALS_MAX_WARRANTY_4_MILES_ASC',
  DealsMaxWarranty_4MilesDesc = 'DEALS_MAX_WARRANTY_4_MILES_DESC',
  DealsMaxWarranty_4TermAsc = 'DEALS_MAX_WARRANTY_4_TERM_ASC',
  DealsMaxWarranty_4TermDesc = 'DEALS_MAX_WARRANTY_4_TERM_DESC',
  DealsMaxWarranty_5NameAsc = 'DEALS_MAX_WARRANTY_5_NAME_ASC',
  DealsMaxWarranty_5NameDesc = 'DEALS_MAX_WARRANTY_5_NAME_DESC',
  DealsMaxWarranty_5SaleAsc = 'DEALS_MAX_WARRANTY_5_SALE_ASC',
  DealsMaxWarranty_5SaleDesc = 'DEALS_MAX_WARRANTY_5_SALE_DESC',
  DealsMaxWarranty_5CostAsc = 'DEALS_MAX_WARRANTY_5_COST_ASC',
  DealsMaxWarranty_5CostDesc = 'DEALS_MAX_WARRANTY_5_COST_DESC',
  DealsMaxWarranty_5MilesAsc = 'DEALS_MAX_WARRANTY_5_MILES_ASC',
  DealsMaxWarranty_5MilesDesc = 'DEALS_MAX_WARRANTY_5_MILES_DESC',
  DealsMaxWarranty_5TermAsc = 'DEALS_MAX_WARRANTY_5_TERM_ASC',
  DealsMaxWarranty_5TermDesc = 'DEALS_MAX_WARRANTY_5_TERM_DESC',
  DealsMaxTotalFeeAftermarketSaleAsc = 'DEALS_MAX_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsMaxTotalFeeAftermarketSaleDesc = 'DEALS_MAX_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsMaxTotalFeeAftermarketCostAsc = 'DEALS_MAX_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsMaxTotalFeeAftermarketCostDesc = 'DEALS_MAX_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsMaxFeeAftermarket_1NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_1_NAME_ASC',
  DealsMaxFeeAftermarket_1NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_1_NAME_DESC',
  DealsMaxFeeAftermarket_1SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_1_SALE_ASC',
  DealsMaxFeeAftermarket_1SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_1_SALE_DESC',
  DealsMaxFeeAftermarket_1CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_1_COST_ASC',
  DealsMaxFeeAftermarket_1CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_1_COST_DESC',
  DealsMaxFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_2NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_2_NAME_ASC',
  DealsMaxFeeAftermarket_2NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_2_NAME_DESC',
  DealsMaxFeeAftermarket_2SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_2_SALE_ASC',
  DealsMaxFeeAftermarket_2SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_2_SALE_DESC',
  DealsMaxFeeAftermarket_2CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_2_COST_ASC',
  DealsMaxFeeAftermarket_2CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_2_COST_DESC',
  DealsMaxFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_3NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_3_NAME_ASC',
  DealsMaxFeeAftermarket_3NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_3_NAME_DESC',
  DealsMaxFeeAftermarket_3SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_3_SALE_ASC',
  DealsMaxFeeAftermarket_3SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_3_SALE_DESC',
  DealsMaxFeeAftermarket_3CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_3_COST_ASC',
  DealsMaxFeeAftermarket_3CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_3_COST_DESC',
  DealsMaxFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_4NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_4_NAME_ASC',
  DealsMaxFeeAftermarket_4NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_4_NAME_DESC',
  DealsMaxFeeAftermarket_4SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_4_SALE_ASC',
  DealsMaxFeeAftermarket_4SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_4_SALE_DESC',
  DealsMaxFeeAftermarket_4CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_4_COST_ASC',
  DealsMaxFeeAftermarket_4CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_4_COST_DESC',
  DealsMaxFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_5NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_5_NAME_ASC',
  DealsMaxFeeAftermarket_5NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_5_NAME_DESC',
  DealsMaxFeeAftermarket_5SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_5_SALE_ASC',
  DealsMaxFeeAftermarket_5SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_5_SALE_DESC',
  DealsMaxFeeAftermarket_5CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_5_COST_ASC',
  DealsMaxFeeAftermarket_5CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_5_COST_DESC',
  DealsMaxFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_6NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_6_NAME_ASC',
  DealsMaxFeeAftermarket_6NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_6_NAME_DESC',
  DealsMaxFeeAftermarket_6SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_6_SALE_ASC',
  DealsMaxFeeAftermarket_6SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_6_SALE_DESC',
  DealsMaxFeeAftermarket_6CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_6_COST_ASC',
  DealsMaxFeeAftermarket_6CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_6_COST_DESC',
  DealsMaxFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_7NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_7_NAME_ASC',
  DealsMaxFeeAftermarket_7NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_7_NAME_DESC',
  DealsMaxFeeAftermarket_7SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_7_SALE_ASC',
  DealsMaxFeeAftermarket_7SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_7_SALE_DESC',
  DealsMaxFeeAftermarket_7CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_7_COST_ASC',
  DealsMaxFeeAftermarket_7CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_7_COST_DESC',
  DealsMaxFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_8NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_8_NAME_ASC',
  DealsMaxFeeAftermarket_8NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_8_NAME_DESC',
  DealsMaxFeeAftermarket_8SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_8_SALE_ASC',
  DealsMaxFeeAftermarket_8SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_8_SALE_DESC',
  DealsMaxFeeAftermarket_8CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_8_COST_ASC',
  DealsMaxFeeAftermarket_8CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_8_COST_DESC',
  DealsMaxFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_9NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_9_NAME_ASC',
  DealsMaxFeeAftermarket_9NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_9_NAME_DESC',
  DealsMaxFeeAftermarket_9SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_9_SALE_ASC',
  DealsMaxFeeAftermarket_9SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_9_SALE_DESC',
  DealsMaxFeeAftermarket_9CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_9_COST_ASC',
  DealsMaxFeeAftermarket_9CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_9_COST_DESC',
  DealsMaxFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_10NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_10_NAME_ASC',
  DealsMaxFeeAftermarket_10NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_10_NAME_DESC',
  DealsMaxFeeAftermarket_10SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_10_SALE_ASC',
  DealsMaxFeeAftermarket_10SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_10_SALE_DESC',
  DealsMaxFeeAftermarket_10CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_10_COST_ASC',
  DealsMaxFeeAftermarket_10CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_10_COST_DESC',
  DealsMaxFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_11NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_11_NAME_ASC',
  DealsMaxFeeAftermarket_11NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_11_NAME_DESC',
  DealsMaxFeeAftermarket_11SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_11_SALE_ASC',
  DealsMaxFeeAftermarket_11SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_11_SALE_DESC',
  DealsMaxFeeAftermarket_11CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_11_COST_ASC',
  DealsMaxFeeAftermarket_11CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_11_COST_DESC',
  DealsMaxFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_12NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_12_NAME_ASC',
  DealsMaxFeeAftermarket_12NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_12_NAME_DESC',
  DealsMaxFeeAftermarket_12SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_12_SALE_ASC',
  DealsMaxFeeAftermarket_12SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_12_SALE_DESC',
  DealsMaxFeeAftermarket_12CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_12_COST_ASC',
  DealsMaxFeeAftermarket_12CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_12_COST_DESC',
  DealsMaxFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_13NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_13_NAME_ASC',
  DealsMaxFeeAftermarket_13NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_13_NAME_DESC',
  DealsMaxFeeAftermarket_13SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_13_SALE_ASC',
  DealsMaxFeeAftermarket_13SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_13_SALE_DESC',
  DealsMaxFeeAftermarket_13CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_13_COST_ASC',
  DealsMaxFeeAftermarket_13CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_13_COST_DESC',
  DealsMaxFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_14NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_14_NAME_ASC',
  DealsMaxFeeAftermarket_14NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_14_NAME_DESC',
  DealsMaxFeeAftermarket_14SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_14_SALE_ASC',
  DealsMaxFeeAftermarket_14SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_14_SALE_DESC',
  DealsMaxFeeAftermarket_14CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_14_COST_ASC',
  DealsMaxFeeAftermarket_14CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_14_COST_DESC',
  DealsMaxFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_15NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_15_NAME_ASC',
  DealsMaxFeeAftermarket_15NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_15_NAME_DESC',
  DealsMaxFeeAftermarket_15SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_15_SALE_ASC',
  DealsMaxFeeAftermarket_15SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_15_SALE_DESC',
  DealsMaxFeeAftermarket_15CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_15_COST_ASC',
  DealsMaxFeeAftermarket_15CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_15_COST_DESC',
  DealsMaxFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_16NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_16_NAME_ASC',
  DealsMaxFeeAftermarket_16NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_16_NAME_DESC',
  DealsMaxFeeAftermarket_16SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_16_SALE_ASC',
  DealsMaxFeeAftermarket_16SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_16_SALE_DESC',
  DealsMaxFeeAftermarket_16CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_16_COST_ASC',
  DealsMaxFeeAftermarket_16CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_16_COST_DESC',
  DealsMaxFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_17NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_17_NAME_ASC',
  DealsMaxFeeAftermarket_17NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_17_NAME_DESC',
  DealsMaxFeeAftermarket_17SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_17_SALE_ASC',
  DealsMaxFeeAftermarket_17SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_17_SALE_DESC',
  DealsMaxFeeAftermarket_17CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_17_COST_ASC',
  DealsMaxFeeAftermarket_17CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_17_COST_DESC',
  DealsMaxFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_18NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_18_NAME_ASC',
  DealsMaxFeeAftermarket_18NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_18_NAME_DESC',
  DealsMaxFeeAftermarket_18SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_18_SALE_ASC',
  DealsMaxFeeAftermarket_18SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_18_SALE_DESC',
  DealsMaxFeeAftermarket_18CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_18_COST_ASC',
  DealsMaxFeeAftermarket_18CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_18_COST_DESC',
  DealsMaxFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_19NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_19_NAME_ASC',
  DealsMaxFeeAftermarket_19NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_19_NAME_DESC',
  DealsMaxFeeAftermarket_19SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_19_SALE_ASC',
  DealsMaxFeeAftermarket_19SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_19_SALE_DESC',
  DealsMaxFeeAftermarket_19CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_19_COST_ASC',
  DealsMaxFeeAftermarket_19CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_19_COST_DESC',
  DealsMaxFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_20NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_20_NAME_ASC',
  DealsMaxFeeAftermarket_20NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_20_NAME_DESC',
  DealsMaxFeeAftermarket_20SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_20_SALE_ASC',
  DealsMaxFeeAftermarket_20SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_20_SALE_DESC',
  DealsMaxFeeAftermarket_20CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_20_COST_ASC',
  DealsMaxFeeAftermarket_20CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_20_COST_DESC',
  DealsMaxFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsMaxInsurance_1TypeAsc = 'DEALS_MAX_INSURANCE_1_TYPE_ASC',
  DealsMaxInsurance_1TypeDesc = 'DEALS_MAX_INSURANCE_1_TYPE_DESC',
  DealsMaxInsurance_1NameAsc = 'DEALS_MAX_INSURANCE_1_NAME_ASC',
  DealsMaxInsurance_1NameDesc = 'DEALS_MAX_INSURANCE_1_NAME_DESC',
  DealsMaxInsurance_1SaleAsc = 'DEALS_MAX_INSURANCE_1_SALE_ASC',
  DealsMaxInsurance_1SaleDesc = 'DEALS_MAX_INSURANCE_1_SALE_DESC',
  DealsMaxInsurance_1CostAsc = 'DEALS_MAX_INSURANCE_1_COST_ASC',
  DealsMaxInsurance_1CostDesc = 'DEALS_MAX_INSURANCE_1_COST_DESC',
  DealsMaxInsurance_1TermAsc = 'DEALS_MAX_INSURANCE_1_TERM_ASC',
  DealsMaxInsurance_1TermDesc = 'DEALS_MAX_INSURANCE_1_TERM_DESC',
  DealsMaxInsurance_2TypeAsc = 'DEALS_MAX_INSURANCE_2_TYPE_ASC',
  DealsMaxInsurance_2TypeDesc = 'DEALS_MAX_INSURANCE_2_TYPE_DESC',
  DealsMaxInsurance_2NameAsc = 'DEALS_MAX_INSURANCE_2_NAME_ASC',
  DealsMaxInsurance_2NameDesc = 'DEALS_MAX_INSURANCE_2_NAME_DESC',
  DealsMaxInsurance_2SaleAsc = 'DEALS_MAX_INSURANCE_2_SALE_ASC',
  DealsMaxInsurance_2SaleDesc = 'DEALS_MAX_INSURANCE_2_SALE_DESC',
  DealsMaxInsurance_2CostAsc = 'DEALS_MAX_INSURANCE_2_COST_ASC',
  DealsMaxInsurance_2CostDesc = 'DEALS_MAX_INSURANCE_2_COST_DESC',
  DealsMaxInsurance_2TermAsc = 'DEALS_MAX_INSURANCE_2_TERM_ASC',
  DealsMaxInsurance_2TermDesc = 'DEALS_MAX_INSURANCE_2_TERM_DESC',
  DealsMaxInsurance_3TypeAsc = 'DEALS_MAX_INSURANCE_3_TYPE_ASC',
  DealsMaxInsurance_3TypeDesc = 'DEALS_MAX_INSURANCE_3_TYPE_DESC',
  DealsMaxInsurance_3NameAsc = 'DEALS_MAX_INSURANCE_3_NAME_ASC',
  DealsMaxInsurance_3NameDesc = 'DEALS_MAX_INSURANCE_3_NAME_DESC',
  DealsMaxInsurance_3SaleAsc = 'DEALS_MAX_INSURANCE_3_SALE_ASC',
  DealsMaxInsurance_3SaleDesc = 'DEALS_MAX_INSURANCE_3_SALE_DESC',
  DealsMaxInsurance_3CostAsc = 'DEALS_MAX_INSURANCE_3_COST_ASC',
  DealsMaxInsurance_3CostDesc = 'DEALS_MAX_INSURANCE_3_COST_DESC',
  DealsMaxInsurance_3TermAsc = 'DEALS_MAX_INSURANCE_3_TERM_ASC',
  DealsMaxInsurance_3TermDesc = 'DEALS_MAX_INSURANCE_3_TERM_DESC',
  DealsMaxAccidentalHealthTypeAsc = 'DEALS_MAX_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsMaxAccidentalHealthTypeDesc = 'DEALS_MAX_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsMaxAccidentalHealthNameAsc = 'DEALS_MAX_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsMaxAccidentalHealthNameDesc = 'DEALS_MAX_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsMaxAccidentalHealthSaleAsc = 'DEALS_MAX_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsMaxAccidentalHealthSaleDesc = 'DEALS_MAX_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsMaxAccidentalHealthCostAsc = 'DEALS_MAX_ACCIDENTAL_HEALTH_COST_ASC',
  DealsMaxAccidentalHealthCostDesc = 'DEALS_MAX_ACCIDENTAL_HEALTH_COST_DESC',
  DealsMaxAccidentalHealthTermAsc = 'DEALS_MAX_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsMaxAccidentalHealthTermDesc = 'DEALS_MAX_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsMaxCreditLifeTypeAsc = 'DEALS_MAX_CREDIT_LIFE_TYPE_ASC',
  DealsMaxCreditLifeTypeDesc = 'DEALS_MAX_CREDIT_LIFE_TYPE_DESC',
  DealsMaxCreditLifeNameAsc = 'DEALS_MAX_CREDIT_LIFE_NAME_ASC',
  DealsMaxCreditLifeNameDesc = 'DEALS_MAX_CREDIT_LIFE_NAME_DESC',
  DealsMaxCreditLifeSaleAsc = 'DEALS_MAX_CREDIT_LIFE_SALE_ASC',
  DealsMaxCreditLifeSaleDesc = 'DEALS_MAX_CREDIT_LIFE_SALE_DESC',
  DealsMaxCreditLifeCostAsc = 'DEALS_MAX_CREDIT_LIFE_COST_ASC',
  DealsMaxCreditLifeCostDesc = 'DEALS_MAX_CREDIT_LIFE_COST_DESC',
  DealsMaxCreditLifeTermAsc = 'DEALS_MAX_CREDIT_LIFE_TERM_ASC',
  DealsMaxCreditLifeTermDesc = 'DEALS_MAX_CREDIT_LIFE_TERM_DESC',
  DealsMaxLevelizedLifeTypeAsc = 'DEALS_MAX_LEVELIZED_LIFE_TYPE_ASC',
  DealsMaxLevelizedLifeTypeDesc = 'DEALS_MAX_LEVELIZED_LIFE_TYPE_DESC',
  DealsMaxLevelizedLifeNameAsc = 'DEALS_MAX_LEVELIZED_LIFE_NAME_ASC',
  DealsMaxLevelizedLifeNameDesc = 'DEALS_MAX_LEVELIZED_LIFE_NAME_DESC',
  DealsMaxLevelizedLifeSaleAsc = 'DEALS_MAX_LEVELIZED_LIFE_SALE_ASC',
  DealsMaxLevelizedLifeSaleDesc = 'DEALS_MAX_LEVELIZED_LIFE_SALE_DESC',
  DealsMaxLevelizedLifeCostAsc = 'DEALS_MAX_LEVELIZED_LIFE_COST_ASC',
  DealsMaxLevelizedLifeCostDesc = 'DEALS_MAX_LEVELIZED_LIFE_COST_DESC',
  DealsMaxLevelizedLifeTermAsc = 'DEALS_MAX_LEVELIZED_LIFE_TERM_ASC',
  DealsMaxLevelizedLifeTermDesc = 'DEALS_MAX_LEVELIZED_LIFE_TERM_DESC',
  DealsMaxLossOfEmploymentTypeAsc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsMaxLossOfEmploymentTypeDesc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsMaxLossOfEmploymentNameAsc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsMaxLossOfEmploymentNameDesc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsMaxLossOfEmploymentSaleAsc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsMaxLossOfEmploymentSaleDesc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsMaxLossOfEmploymentCostAsc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsMaxLossOfEmploymentCostDesc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsMaxLossOfEmploymentTermAsc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsMaxLossOfEmploymentTermDesc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsMaxGuaranteedAutoProtectionTypeAsc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsMaxGuaranteedAutoProtectionTypeDesc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsMaxGuaranteedAutoProtectionNameAsc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsMaxGuaranteedAutoProtectionNameDesc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsMaxGuaranteedAutoProtectionSaleAsc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsMaxGuaranteedAutoProtectionSaleDesc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsMaxGuaranteedAutoProtectionCostAsc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsMaxGuaranteedAutoProtectionCostDesc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsMaxGuaranteedAutoProtectionTermAsc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsMaxGuaranteedAutoProtectionTermDesc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsMaxSaleCommentsAsc = 'DEALS_MAX_SALE_COMMENTS_ASC',
  DealsMaxSaleCommentsDesc = 'DEALS_MAX_SALE_COMMENTS_DESC',
  DealsMaxCassStdLine1Asc = 'DEALS_MAX_CASS_STD_LINE1_ASC',
  DealsMaxCassStdLine1Desc = 'DEALS_MAX_CASS_STD_LINE1_DESC',
  DealsMaxCassStdLine2Asc = 'DEALS_MAX_CASS_STD_LINE2_ASC',
  DealsMaxCassStdLine2Desc = 'DEALS_MAX_CASS_STD_LINE2_DESC',
  DealsMaxCassStdCityAsc = 'DEALS_MAX_CASS_STD_CITY_ASC',
  DealsMaxCassStdCityDesc = 'DEALS_MAX_CASS_STD_CITY_DESC',
  DealsMaxCassStdStateAsc = 'DEALS_MAX_CASS_STD_STATE_ASC',
  DealsMaxCassStdStateDesc = 'DEALS_MAX_CASS_STD_STATE_DESC',
  DealsMaxCassStdZipAsc = 'DEALS_MAX_CASS_STD_ZIP_ASC',
  DealsMaxCassStdZipDesc = 'DEALS_MAX_CASS_STD_ZIP_DESC',
  DealsMaxCassStdZip4Asc = 'DEALS_MAX_CASS_STD_ZIP4_ASC',
  DealsMaxCassStdZip4Desc = 'DEALS_MAX_CASS_STD_ZIP4_DESC',
  DealsMaxCassStdDpbcAsc = 'DEALS_MAX_CASS_STD_DPBC_ASC',
  DealsMaxCassStdDpbcDesc = 'DEALS_MAX_CASS_STD_DPBC_DESC',
  DealsMaxCassStdChkdgtAsc = 'DEALS_MAX_CASS_STD_CHKDGT_ASC',
  DealsMaxCassStdChkdgtDesc = 'DEALS_MAX_CASS_STD_CHKDGT_DESC',
  DealsMaxCassStdCartAsc = 'DEALS_MAX_CASS_STD_CART_ASC',
  DealsMaxCassStdCartDesc = 'DEALS_MAX_CASS_STD_CART_DESC',
  DealsMaxCassStdLotAsc = 'DEALS_MAX_CASS_STD_LOT_ASC',
  DealsMaxCassStdLotDesc = 'DEALS_MAX_CASS_STD_LOT_DESC',
  DealsMaxCassStdLotordAsc = 'DEALS_MAX_CASS_STD_LOTORD_ASC',
  DealsMaxCassStdLotordDesc = 'DEALS_MAX_CASS_STD_LOTORD_DESC',
  DealsMaxCassStdUrbAsc = 'DEALS_MAX_CASS_STD_URB_ASC',
  DealsMaxCassStdUrbDesc = 'DEALS_MAX_CASS_STD_URB_DESC',
  DealsMaxCassStdFipsAsc = 'DEALS_MAX_CASS_STD_FIPS_ASC',
  DealsMaxCassStdFipsDesc = 'DEALS_MAX_CASS_STD_FIPS_DESC',
  DealsMaxCassStdEwsAsc = 'DEALS_MAX_CASS_STD_EWS_ASC',
  DealsMaxCassStdEwsDesc = 'DEALS_MAX_CASS_STD_EWS_DESC',
  DealsMaxCassStdLacsAsc = 'DEALS_MAX_CASS_STD_LACS_ASC',
  DealsMaxCassStdLacsDesc = 'DEALS_MAX_CASS_STD_LACS_DESC',
  DealsMaxCassStdZipmovAsc = 'DEALS_MAX_CASS_STD_ZIPMOV_ASC',
  DealsMaxCassStdZipmovDesc = 'DEALS_MAX_CASS_STD_ZIPMOV_DESC',
  DealsMaxCassStdZ4LomAsc = 'DEALS_MAX_CASS_STD_Z4LOM_ASC',
  DealsMaxCassStdZ4LomDesc = 'DEALS_MAX_CASS_STD_Z4LOM_DESC',
  DealsMaxCassStdNdiaptAsc = 'DEALS_MAX_CASS_STD_NDIAPT_ASC',
  DealsMaxCassStdNdiaptDesc = 'DEALS_MAX_CASS_STD_NDIAPT_DESC',
  DealsMaxCassStdNdirrAsc = 'DEALS_MAX_CASS_STD_NDIRR_ASC',
  DealsMaxCassStdNdirrDesc = 'DEALS_MAX_CASS_STD_NDIRR_DESC',
  DealsMaxCassStdLacsrtAsc = 'DEALS_MAX_CASS_STD_LACSRT_ASC',
  DealsMaxCassStdLacsrtDesc = 'DEALS_MAX_CASS_STD_LACSRT_DESC',
  DealsMaxCassStdErrorCdAsc = 'DEALS_MAX_CASS_STD_ERROR_CD_ASC',
  DealsMaxCassStdErrorCdDesc = 'DEALS_MAX_CASS_STD_ERROR_CD_DESC',
  DealsAverageIdAsc = 'DEALS_AVERAGE_ID_ASC',
  DealsAverageIdDesc = 'DEALS_AVERAGE_ID_DESC',
  DealsAverageLeadIdAsc = 'DEALS_AVERAGE_LEAD_ID_ASC',
  DealsAverageLeadIdDesc = 'DEALS_AVERAGE_LEAD_ID_DESC',
  DealsAverageCompanyIntegrationIdAsc = 'DEALS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  DealsAverageCompanyIntegrationIdDesc = 'DEALS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  DealsAverageFileTypeAsc = 'DEALS_AVERAGE_FILE_TYPE_ASC',
  DealsAverageFileTypeDesc = 'DEALS_AVERAGE_FILE_TYPE_DESC',
  DealsAverageDvDealerIdAsc = 'DEALS_AVERAGE_DV_DEALER_ID_ASC',
  DealsAverageDvDealerIdDesc = 'DEALS_AVERAGE_DV_DEALER_ID_DESC',
  DealsAverageVendorDealerIdAsc = 'DEALS_AVERAGE_VENDOR_DEALER_ID_ASC',
  DealsAverageVendorDealerIdDesc = 'DEALS_AVERAGE_VENDOR_DEALER_ID_DESC',
  DealsAverageDmsTypeAsc = 'DEALS_AVERAGE_DMS_TYPE_ASC',
  DealsAverageDmsTypeDesc = 'DEALS_AVERAGE_DMS_TYPE_DESC',
  DealsAverageDealNumberAsc = 'DEALS_AVERAGE_DEAL_NUMBER_ASC',
  DealsAverageDealNumberDesc = 'DEALS_AVERAGE_DEAL_NUMBER_DESC',
  DealsAverageCustomerNumberAsc = 'DEALS_AVERAGE_CUSTOMER_NUMBER_ASC',
  DealsAverageCustomerNumberDesc = 'DEALS_AVERAGE_CUSTOMER_NUMBER_DESC',
  DealsAverageFullNameAsc = 'DEALS_AVERAGE_FULL_NAME_ASC',
  DealsAverageFullNameDesc = 'DEALS_AVERAGE_FULL_NAME_DESC',
  DealsAverageSalutationAsc = 'DEALS_AVERAGE_SALUTATION_ASC',
  DealsAverageSalutationDesc = 'DEALS_AVERAGE_SALUTATION_DESC',
  DealsAverageFirstNameAsc = 'DEALS_AVERAGE_FIRST_NAME_ASC',
  DealsAverageFirstNameDesc = 'DEALS_AVERAGE_FIRST_NAME_DESC',
  DealsAverageMiddleNameAsc = 'DEALS_AVERAGE_MIDDLE_NAME_ASC',
  DealsAverageMiddleNameDesc = 'DEALS_AVERAGE_MIDDLE_NAME_DESC',
  DealsAverageLastNameAsc = 'DEALS_AVERAGE_LAST_NAME_ASC',
  DealsAverageLastNameDesc = 'DEALS_AVERAGE_LAST_NAME_DESC',
  DealsAverageSuffixAsc = 'DEALS_AVERAGE_SUFFIX_ASC',
  DealsAverageSuffixDesc = 'DEALS_AVERAGE_SUFFIX_DESC',
  DealsAverageAddressLine_1Asc = 'DEALS_AVERAGE_ADDRESS_LINE_1_ASC',
  DealsAverageAddressLine_1Desc = 'DEALS_AVERAGE_ADDRESS_LINE_1_DESC',
  DealsAverageAddressLine_2Asc = 'DEALS_AVERAGE_ADDRESS_LINE_2_ASC',
  DealsAverageAddressLine_2Desc = 'DEALS_AVERAGE_ADDRESS_LINE_2_DESC',
  DealsAverageCityAsc = 'DEALS_AVERAGE_CITY_ASC',
  DealsAverageCityDesc = 'DEALS_AVERAGE_CITY_DESC',
  DealsAverageStateAsc = 'DEALS_AVERAGE_STATE_ASC',
  DealsAverageStateDesc = 'DEALS_AVERAGE_STATE_DESC',
  DealsAverageZipAsc = 'DEALS_AVERAGE_ZIP_ASC',
  DealsAverageZipDesc = 'DEALS_AVERAGE_ZIP_DESC',
  DealsAverageCountyAsc = 'DEALS_AVERAGE_COUNTY_ASC',
  DealsAverageCountyDesc = 'DEALS_AVERAGE_COUNTY_DESC',
  DealsAverageHomePhoneAsc = 'DEALS_AVERAGE_HOME_PHONE_ASC',
  DealsAverageHomePhoneDesc = 'DEALS_AVERAGE_HOME_PHONE_DESC',
  DealsAverageCellPhoneAsc = 'DEALS_AVERAGE_CELL_PHONE_ASC',
  DealsAverageCellPhoneDesc = 'DEALS_AVERAGE_CELL_PHONE_DESC',
  DealsAverageWorkPhoneAsc = 'DEALS_AVERAGE_WORK_PHONE_ASC',
  DealsAverageWorkPhoneDesc = 'DEALS_AVERAGE_WORK_PHONE_DESC',
  DealsAverageWorkExtensionAsc = 'DEALS_AVERAGE_WORK_EXTENSION_ASC',
  DealsAverageWorkExtensionDesc = 'DEALS_AVERAGE_WORK_EXTENSION_DESC',
  DealsAverageEmail_1Asc = 'DEALS_AVERAGE_EMAIL_1_ASC',
  DealsAverageEmail_1Desc = 'DEALS_AVERAGE_EMAIL_1_DESC',
  DealsAverageEmail_2Asc = 'DEALS_AVERAGE_EMAIL_2_ASC',
  DealsAverageEmail_2Desc = 'DEALS_AVERAGE_EMAIL_2_DESC',
  DealsAverageEmail_3Asc = 'DEALS_AVERAGE_EMAIL_3_ASC',
  DealsAverageEmail_3Desc = 'DEALS_AVERAGE_EMAIL_3_DESC',
  DealsAverageBirthDateAsc = 'DEALS_AVERAGE_BIRTH_DATE_ASC',
  DealsAverageBirthDateDesc = 'DEALS_AVERAGE_BIRTH_DATE_DESC',
  DealsAverageIndividualBusinessFlagAsc = 'DEALS_AVERAGE_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsAverageIndividualBusinessFlagDesc = 'DEALS_AVERAGE_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsAverageOptOutAsc = 'DEALS_AVERAGE_OPT_OUT_ASC',
  DealsAverageOptOutDesc = 'DEALS_AVERAGE_OPT_OUT_DESC',
  DealsAverageBlockEmailAsc = 'DEALS_AVERAGE_BLOCK_EMAIL_ASC',
  DealsAverageBlockEmailDesc = 'DEALS_AVERAGE_BLOCK_EMAIL_DESC',
  DealsAverageBlockPhoneAsc = 'DEALS_AVERAGE_BLOCK_PHONE_ASC',
  DealsAverageBlockPhoneDesc = 'DEALS_AVERAGE_BLOCK_PHONE_DESC',
  DealsAverageBlockMailAsc = 'DEALS_AVERAGE_BLOCK_MAIL_ASC',
  DealsAverageBlockMailDesc = 'DEALS_AVERAGE_BLOCK_MAIL_DESC',
  DealsAverageLanguageAsc = 'DEALS_AVERAGE_LANGUAGE_ASC',
  DealsAverageLanguageDesc = 'DEALS_AVERAGE_LANGUAGE_DESC',
  DealsAverageCustomerCreateDateAsc = 'DEALS_AVERAGE_CUSTOMER_CREATE_DATE_ASC',
  DealsAverageCustomerCreateDateDesc = 'DEALS_AVERAGE_CUSTOMER_CREATE_DATE_DESC',
  DealsAverageCustomerLastActivityDateAsc = 'DEALS_AVERAGE_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsAverageCustomerLastActivityDateDesc = 'DEALS_AVERAGE_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsAverageCoBuyerCustomerNumberAsc = 'DEALS_AVERAGE_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsAverageCoBuyerCustomerNumberDesc = 'DEALS_AVERAGE_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsAverageCoBuyerFullNameAsc = 'DEALS_AVERAGE_CO_BUYER_FULL_NAME_ASC',
  DealsAverageCoBuyerFullNameDesc = 'DEALS_AVERAGE_CO_BUYER_FULL_NAME_DESC',
  DealsAverageCoBuyerSalutationAsc = 'DEALS_AVERAGE_CO_BUYER_SALUTATION_ASC',
  DealsAverageCoBuyerSalutationDesc = 'DEALS_AVERAGE_CO_BUYER_SALUTATION_DESC',
  DealsAverageCoBuyerFirstNameAsc = 'DEALS_AVERAGE_CO_BUYER_FIRST_NAME_ASC',
  DealsAverageCoBuyerFirstNameDesc = 'DEALS_AVERAGE_CO_BUYER_FIRST_NAME_DESC',
  DealsAverageCoBuyerMiddleNameAsc = 'DEALS_AVERAGE_CO_BUYER_MIDDLE_NAME_ASC',
  DealsAverageCoBuyerMiddleNameDesc = 'DEALS_AVERAGE_CO_BUYER_MIDDLE_NAME_DESC',
  DealsAverageCoBuyerLastNameAsc = 'DEALS_AVERAGE_CO_BUYER_LAST_NAME_ASC',
  DealsAverageCoBuyerLastNameDesc = 'DEALS_AVERAGE_CO_BUYER_LAST_NAME_DESC',
  DealsAverageCoBuyerSuffixAsc = 'DEALS_AVERAGE_CO_BUYER_SUFFIX_ASC',
  DealsAverageCoBuyerSuffixDesc = 'DEALS_AVERAGE_CO_BUYER_SUFFIX_DESC',
  DealsAverageCoBuyerAddressLine_1Asc = 'DEALS_AVERAGE_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsAverageCoBuyerAddressLine_1Desc = 'DEALS_AVERAGE_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsAverageCoBuyerAddressLine_2Asc = 'DEALS_AVERAGE_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsAverageCoBuyerAddressLine_2Desc = 'DEALS_AVERAGE_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsAverageCoBuyerCityAsc = 'DEALS_AVERAGE_CO_BUYER_CITY_ASC',
  DealsAverageCoBuyerCityDesc = 'DEALS_AVERAGE_CO_BUYER_CITY_DESC',
  DealsAverageCoBuyerStateAsc = 'DEALS_AVERAGE_CO_BUYER_STATE_ASC',
  DealsAverageCoBuyerStateDesc = 'DEALS_AVERAGE_CO_BUYER_STATE_DESC',
  DealsAverageCoBuyerZipAsc = 'DEALS_AVERAGE_CO_BUYER_ZIP_ASC',
  DealsAverageCoBuyerZipDesc = 'DEALS_AVERAGE_CO_BUYER_ZIP_DESC',
  DealsAverageCoBuyerCountyAsc = 'DEALS_AVERAGE_CO_BUYER_COUNTY_ASC',
  DealsAverageCoBuyerCountyDesc = 'DEALS_AVERAGE_CO_BUYER_COUNTY_DESC',
  DealsAverageCoBuyerHomePhoneAsc = 'DEALS_AVERAGE_CO_BUYER_HOME_PHONE_ASC',
  DealsAverageCoBuyerHomePhoneDesc = 'DEALS_AVERAGE_CO_BUYER_HOME_PHONE_DESC',
  DealsAverageCoBuyerCellPhoneAsc = 'DEALS_AVERAGE_CO_BUYER_CELL_PHONE_ASC',
  DealsAverageCoBuyerCellPhoneDesc = 'DEALS_AVERAGE_CO_BUYER_CELL_PHONE_DESC',
  DealsAverageCoBuyerWorkPhoneAsc = 'DEALS_AVERAGE_CO_BUYER_WORK_PHONE_ASC',
  DealsAverageCoBuyerWorkPhoneDesc = 'DEALS_AVERAGE_CO_BUYER_WORK_PHONE_DESC',
  DealsAverageCoBuyerWorkExtensionAsc = 'DEALS_AVERAGE_CO_BUYER_WORK_EXTENSION_ASC',
  DealsAverageCoBuyerWorkExtensionDesc = 'DEALS_AVERAGE_CO_BUYER_WORK_EXTENSION_DESC',
  DealsAverageCoBuyerEmail_1Asc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_1_ASC',
  DealsAverageCoBuyerEmail_1Desc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_1_DESC',
  DealsAverageCoBuyerEmail_2Asc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_2_ASC',
  DealsAverageCoBuyerEmail_2Desc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_2_DESC',
  DealsAverageCoBuyerEmail_3Asc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_3_ASC',
  DealsAverageCoBuyerEmail_3Desc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_3_DESC',
  DealsAverageCoBuyerBirthDateAsc = 'DEALS_AVERAGE_CO_BUYER_BIRTH_DATE_ASC',
  DealsAverageCoBuyerBirthDateDesc = 'DEALS_AVERAGE_CO_BUYER_BIRTH_DATE_DESC',
  DealsAverageCoBuyerIndividualBusinessFlagAsc = 'DEALS_AVERAGE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsAverageCoBuyerIndividualBusinessFlagDesc = 'DEALS_AVERAGE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsAverageCoBuyerOptOutAsc = 'DEALS_AVERAGE_CO_BUYER_OPT_OUT_ASC',
  DealsAverageCoBuyerOptOutDesc = 'DEALS_AVERAGE_CO_BUYER_OPT_OUT_DESC',
  DealsAverageCoBuyerBlockEmailAsc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsAverageCoBuyerBlockEmailDesc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsAverageCoBuyerBlockPhoneAsc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_PHONE_ASC',
  DealsAverageCoBuyerBlockPhoneDesc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_PHONE_DESC',
  DealsAverageCoBuyerBlockMailAsc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_MAIL_ASC',
  DealsAverageCoBuyerBlockMailDesc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_MAIL_DESC',
  DealsAverageVinAsc = 'DEALS_AVERAGE_VIN_ASC',
  DealsAverageVinDesc = 'DEALS_AVERAGE_VIN_DESC',
  DealsAverageYearAsc = 'DEALS_AVERAGE_YEAR_ASC',
  DealsAverageYearDesc = 'DEALS_AVERAGE_YEAR_DESC',
  DealsAverageMakeAsc = 'DEALS_AVERAGE_MAKE_ASC',
  DealsAverageMakeDesc = 'DEALS_AVERAGE_MAKE_DESC',
  DealsAverageModelAsc = 'DEALS_AVERAGE_MODEL_ASC',
  DealsAverageModelDesc = 'DEALS_AVERAGE_MODEL_DESC',
  DealsAverageModelNumberAsc = 'DEALS_AVERAGE_MODEL_NUMBER_ASC',
  DealsAverageModelNumberDesc = 'DEALS_AVERAGE_MODEL_NUMBER_DESC',
  DealsAverageMileageAsc = 'DEALS_AVERAGE_MILEAGE_ASC',
  DealsAverageMileageDesc = 'DEALS_AVERAGE_MILEAGE_DESC',
  DealsAverageDescriptionAsc = 'DEALS_AVERAGE_DESCRIPTION_ASC',
  DealsAverageDescriptionDesc = 'DEALS_AVERAGE_DESCRIPTION_DESC',
  DealsAverageExteriorColorAsc = 'DEALS_AVERAGE_EXTERIOR_COLOR_ASC',
  DealsAverageExteriorColorDesc = 'DEALS_AVERAGE_EXTERIOR_COLOR_DESC',
  DealsAverageNewUsedAsc = 'DEALS_AVERAGE_NEW_USED_ASC',
  DealsAverageNewUsedDesc = 'DEALS_AVERAGE_NEW_USED_DESC',
  DealsAverageStockNumberAsc = 'DEALS_AVERAGE_STOCK_NUMBER_ASC',
  DealsAverageStockNumberDesc = 'DEALS_AVERAGE_STOCK_NUMBER_DESC',
  DealsAverageTransmissionAsc = 'DEALS_AVERAGE_TRANSMISSION_ASC',
  DealsAverageTransmissionDesc = 'DEALS_AVERAGE_TRANSMISSION_DESC',
  DealsAverageEngineConfigurationAsc = 'DEALS_AVERAGE_ENGINE_CONFIGURATION_ASC',
  DealsAverageEngineConfigurationDesc = 'DEALS_AVERAGE_ENGINE_CONFIGURATION_DESC',
  DealsAverageTrimAsc = 'DEALS_AVERAGE_TRIM_ASC',
  DealsAverageTrimDesc = 'DEALS_AVERAGE_TRIM_DESC',
  DealsAverageEngineNumberAsc = 'DEALS_AVERAGE_ENGINE_NUMBER_ASC',
  DealsAverageEngineNumberDesc = 'DEALS_AVERAGE_ENGINE_NUMBER_DESC',
  DealsAverageChassisNumberAsc = 'DEALS_AVERAGE_CHASSIS_NUMBER_ASC',
  DealsAverageChassisNumberDesc = 'DEALS_AVERAGE_CHASSIS_NUMBER_DESC',
  DealsAverageLicensePlateNumberAsc = 'DEALS_AVERAGE_LICENSE_PLATE_NUMBER_ASC',
  DealsAverageLicensePlateNumberDesc = 'DEALS_AVERAGE_LICENSE_PLATE_NUMBER_DESC',
  DealsAverageDeliveryDateAsc = 'DEALS_AVERAGE_DELIVERY_DATE_ASC',
  DealsAverageDeliveryDateDesc = 'DEALS_AVERAGE_DELIVERY_DATE_DESC',
  DealsAverageDeliveryMileageAsc = 'DEALS_AVERAGE_DELIVERY_MILEAGE_ASC',
  DealsAverageDeliveryMileageDesc = 'DEALS_AVERAGE_DELIVERY_MILEAGE_DESC',
  DealsAverageInventoryDateAsc = 'DEALS_AVERAGE_INVENTORY_DATE_ASC',
  DealsAverageInventoryDateDesc = 'DEALS_AVERAGE_INVENTORY_DATE_DESC',
  DealsAverageInServiceDateAsc = 'DEALS_AVERAGE_IN_SERVICE_DATE_ASC',
  DealsAverageInServiceDateDesc = 'DEALS_AVERAGE_IN_SERVICE_DATE_DESC',
  DealsAverageVinExplosionYearAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_YEAR_ASC',
  DealsAverageVinExplosionYearDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_YEAR_DESC',
  DealsAverageVinExplosionMakeAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_MAKE_ASC',
  DealsAverageVinExplosionMakeDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_MAKE_DESC',
  DealsAverageVinExplosionModelAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_MODEL_ASC',
  DealsAverageVinExplosionModelDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_MODEL_DESC',
  DealsAverageVinExplosionTrimAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_TRIM_ASC',
  DealsAverageVinExplosionTrimDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_TRIM_DESC',
  DealsAverageVinExplosionTransmissionTypeAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsAverageVinExplosionTransmissionTypeDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsAverageVinExplosionFuelTypeAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsAverageVinExplosionFuelTypeDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsAverageVinExplosionEngineSizeAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsAverageVinExplosionEngineSizeDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsAverageVinExplosionGvwRangeAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsAverageVinExplosionGvwRangeDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsAverageTrade_1VinAsc = 'DEALS_AVERAGE_TRADE_1_VIN_ASC',
  DealsAverageTrade_1VinDesc = 'DEALS_AVERAGE_TRADE_1_VIN_DESC',
  DealsAverageTrade_1YearAsc = 'DEALS_AVERAGE_TRADE_1_YEAR_ASC',
  DealsAverageTrade_1YearDesc = 'DEALS_AVERAGE_TRADE_1_YEAR_DESC',
  DealsAverageTrade_1MakeAsc = 'DEALS_AVERAGE_TRADE_1_MAKE_ASC',
  DealsAverageTrade_1MakeDesc = 'DEALS_AVERAGE_TRADE_1_MAKE_DESC',
  DealsAverageTrade_1ModelAsc = 'DEALS_AVERAGE_TRADE_1_MODEL_ASC',
  DealsAverageTrade_1ModelDesc = 'DEALS_AVERAGE_TRADE_1_MODEL_DESC',
  DealsAverageTrade_1OdometerAsc = 'DEALS_AVERAGE_TRADE_1_ODOMETER_ASC',
  DealsAverageTrade_1OdometerDesc = 'DEALS_AVERAGE_TRADE_1_ODOMETER_DESC',
  DealsAverageTrade_1ActualCashValueAsc = 'DEALS_AVERAGE_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsAverageTrade_1ActualCashValueDesc = 'DEALS_AVERAGE_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsAverageTrade_1GrossAsc = 'DEALS_AVERAGE_TRADE_1_GROSS_ASC',
  DealsAverageTrade_1GrossDesc = 'DEALS_AVERAGE_TRADE_1_GROSS_DESC',
  DealsAverageTrade_1PayoffAsc = 'DEALS_AVERAGE_TRADE_1_PAYOFF_ASC',
  DealsAverageTrade_1PayoffDesc = 'DEALS_AVERAGE_TRADE_1_PAYOFF_DESC',
  DealsAverageTrade_2VinAsc = 'DEALS_AVERAGE_TRADE_2_VIN_ASC',
  DealsAverageTrade_2VinDesc = 'DEALS_AVERAGE_TRADE_2_VIN_DESC',
  DealsAverageTrade_2YearAsc = 'DEALS_AVERAGE_TRADE_2_YEAR_ASC',
  DealsAverageTrade_2YearDesc = 'DEALS_AVERAGE_TRADE_2_YEAR_DESC',
  DealsAverageTrade_2MakeAsc = 'DEALS_AVERAGE_TRADE_2_MAKE_ASC',
  DealsAverageTrade_2MakeDesc = 'DEALS_AVERAGE_TRADE_2_MAKE_DESC',
  DealsAverageTrade_2ModelAsc = 'DEALS_AVERAGE_TRADE_2_MODEL_ASC',
  DealsAverageTrade_2ModelDesc = 'DEALS_AVERAGE_TRADE_2_MODEL_DESC',
  DealsAverageTrade_2OdometerAsc = 'DEALS_AVERAGE_TRADE_2_ODOMETER_ASC',
  DealsAverageTrade_2OdometerDesc = 'DEALS_AVERAGE_TRADE_2_ODOMETER_DESC',
  DealsAverageTrade_2ActualCashValueAsc = 'DEALS_AVERAGE_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsAverageTrade_2ActualCashValueDesc = 'DEALS_AVERAGE_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsAverageTrade_2GrossAsc = 'DEALS_AVERAGE_TRADE_2_GROSS_ASC',
  DealsAverageTrade_2GrossDesc = 'DEALS_AVERAGE_TRADE_2_GROSS_DESC',
  DealsAverageTrade_2PayoffAsc = 'DEALS_AVERAGE_TRADE_2_PAYOFF_ASC',
  DealsAverageTrade_2PayoffDesc = 'DEALS_AVERAGE_TRADE_2_PAYOFF_DESC',
  DealsAverageSalesman_1NumberAsc = 'DEALS_AVERAGE_SALESMAN_1_NUMBER_ASC',
  DealsAverageSalesman_1NumberDesc = 'DEALS_AVERAGE_SALESMAN_1_NUMBER_DESC',
  DealsAverageSalesman_1NameAsc = 'DEALS_AVERAGE_SALESMAN_1_NAME_ASC',
  DealsAverageSalesman_1NameDesc = 'DEALS_AVERAGE_SALESMAN_1_NAME_DESC',
  DealsAverageSalesman_2NumberAsc = 'DEALS_AVERAGE_SALESMAN_2_NUMBER_ASC',
  DealsAverageSalesman_2NumberDesc = 'DEALS_AVERAGE_SALESMAN_2_NUMBER_DESC',
  DealsAverageSalesman_2NameAsc = 'DEALS_AVERAGE_SALESMAN_2_NAME_ASC',
  DealsAverageSalesman_2NameDesc = 'DEALS_AVERAGE_SALESMAN_2_NAME_DESC',
  DealsAverageSalesman_3NumberAsc = 'DEALS_AVERAGE_SALESMAN_3_NUMBER_ASC',
  DealsAverageSalesman_3NumberDesc = 'DEALS_AVERAGE_SALESMAN_3_NUMBER_DESC',
  DealsAverageSalesman_3NameAsc = 'DEALS_AVERAGE_SALESMAN_3_NAME_ASC',
  DealsAverageSalesman_3NameDesc = 'DEALS_AVERAGE_SALESMAN_3_NAME_DESC',
  DealsAverageClosingManagerNumberAsc = 'DEALS_AVERAGE_CLOSING_MANAGER_NUMBER_ASC',
  DealsAverageClosingManagerNumberDesc = 'DEALS_AVERAGE_CLOSING_MANAGER_NUMBER_DESC',
  DealsAverageClosingManagerNameAsc = 'DEALS_AVERAGE_CLOSING_MANAGER_NAME_ASC',
  DealsAverageClosingManagerNameDesc = 'DEALS_AVERAGE_CLOSING_MANAGER_NAME_DESC',
  DealsAverageFinanceManagerNumberAsc = 'DEALS_AVERAGE_FINANCE_MANAGER_NUMBER_ASC',
  DealsAverageFinanceManagerNumberDesc = 'DEALS_AVERAGE_FINANCE_MANAGER_NUMBER_DESC',
  DealsAverageFinanceManagerNameAsc = 'DEALS_AVERAGE_FINANCE_MANAGER_NAME_ASC',
  DealsAverageFinanceManagerNameDesc = 'DEALS_AVERAGE_FINANCE_MANAGER_NAME_DESC',
  DealsAverageSalesmanManagerNumberAsc = 'DEALS_AVERAGE_SALESMAN_MANAGER_NUMBER_ASC',
  DealsAverageSalesmanManagerNumberDesc = 'DEALS_AVERAGE_SALESMAN_MANAGER_NUMBER_DESC',
  DealsAverageSalesmanManagerNameAsc = 'DEALS_AVERAGE_SALESMAN_MANAGER_NAME_ASC',
  DealsAverageSalesmanManagerNameDesc = 'DEALS_AVERAGE_SALESMAN_MANAGER_NAME_DESC',
  DealsAverageMsrpAsc = 'DEALS_AVERAGE_MSRP_ASC',
  DealsAverageMsrpDesc = 'DEALS_AVERAGE_MSRP_DESC',
  DealsAverageListPriceAsc = 'DEALS_AVERAGE_LIST_PRICE_ASC',
  DealsAverageListPriceDesc = 'DEALS_AVERAGE_LIST_PRICE_DESC',
  DealsAverageSalesPriceAsc = 'DEALS_AVERAGE_SALES_PRICE_ASC',
  DealsAverageSalesPriceDesc = 'DEALS_AVERAGE_SALES_PRICE_DESC',
  DealsAverageJournalPriceAsc = 'DEALS_AVERAGE_JOURNAL_PRICE_ASC',
  DealsAverageJournalPriceDesc = 'DEALS_AVERAGE_JOURNAL_PRICE_DESC',
  DealsAverageCostAsc = 'DEALS_AVERAGE_COST_ASC',
  DealsAverageCostDesc = 'DEALS_AVERAGE_COST_DESC',
  DealsAverageJournalCostAsc = 'DEALS_AVERAGE_JOURNAL_COST_ASC',
  DealsAverageJournalCostDesc = 'DEALS_AVERAGE_JOURNAL_COST_DESC',
  DealsAverageAdjustmentsAsc = 'DEALS_AVERAGE_ADJUSTMENTS_ASC',
  DealsAverageAdjustmentsDesc = 'DEALS_AVERAGE_ADJUSTMENTS_DESC',
  DealsAverageAdjustedCostAsc = 'DEALS_AVERAGE_ADJUSTED_COST_ASC',
  DealsAverageAdjustedCostDesc = 'DEALS_AVERAGE_ADJUSTED_COST_DESC',
  DealsAverageIncentivesAsc = 'DEALS_AVERAGE_INCENTIVES_ASC',
  DealsAverageIncentivesDesc = 'DEALS_AVERAGE_INCENTIVES_DESC',
  DealsAveragePackAmountAsc = 'DEALS_AVERAGE_PACK_AMOUNT_ASC',
  DealsAveragePackAmountDesc = 'DEALS_AVERAGE_PACK_AMOUNT_DESC',
  DealsAverageSaleNetAsc = 'DEALS_AVERAGE_SALE_NET_ASC',
  DealsAverageSaleNetDesc = 'DEALS_AVERAGE_SALE_NET_DESC',
  DealsAverageTotalTradeActualCashValueAsc = 'DEALS_AVERAGE_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsAverageTotalTradeActualCashValueDesc = 'DEALS_AVERAGE_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsAverageTotalTradeGrossAsc = 'DEALS_AVERAGE_TOTAL_TRADE_GROSS_ASC',
  DealsAverageTotalTradeGrossDesc = 'DEALS_AVERAGE_TOTAL_TRADE_GROSS_DESC',
  DealsAverageWeOweFrontAsc = 'DEALS_AVERAGE_WE_OWE_FRONT_ASC',
  DealsAverageWeOweFrontDesc = 'DEALS_AVERAGE_WE_OWE_FRONT_DESC',
  DealsAverageTotalFrontFeesAftermarketProfitAsc = 'DEALS_AVERAGE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsAverageTotalFrontFeesAftermarketProfitDesc = 'DEALS_AVERAGE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsAverageTotalFrontCommissionAsc = 'DEALS_AVERAGE_TOTAL_FRONT_COMMISSION_ASC',
  DealsAverageTotalFrontCommissionDesc = 'DEALS_AVERAGE_TOTAL_FRONT_COMMISSION_DESC',
  DealsAverageTotalFrontSalesAsc = 'DEALS_AVERAGE_TOTAL_FRONT_SALES_ASC',
  DealsAverageTotalFrontSalesDesc = 'DEALS_AVERAGE_TOTAL_FRONT_SALES_DESC',
  DealsAverageTotalFrontCostAsc = 'DEALS_AVERAGE_TOTAL_FRONT_COST_ASC',
  DealsAverageTotalFrontCostDesc = 'DEALS_AVERAGE_TOTAL_FRONT_COST_DESC',
  DealsAverageFrontGrossAsc = 'DEALS_AVERAGE_FRONT_GROSS_ASC',
  DealsAverageFrontGrossDesc = 'DEALS_AVERAGE_FRONT_GROSS_DESC',
  DealsAverageFinanceProfitAsc = 'DEALS_AVERAGE_FINANCE_PROFIT_ASC',
  DealsAverageFinanceProfitDesc = 'DEALS_AVERAGE_FINANCE_PROFIT_DESC',
  DealsAverageTotalWarrantyProfitAsc = 'DEALS_AVERAGE_TOTAL_WARRANTY_PROFIT_ASC',
  DealsAverageTotalWarrantyProfitDesc = 'DEALS_AVERAGE_TOTAL_WARRANTY_PROFIT_DESC',
  DealsAverageWeOweBackAsc = 'DEALS_AVERAGE_WE_OWE_BACK_ASC',
  DealsAverageWeOweBackDesc = 'DEALS_AVERAGE_WE_OWE_BACK_DESC',
  DealsAverageInsuranceProfitAsc = 'DEALS_AVERAGE_INSURANCE_PROFIT_ASC',
  DealsAverageInsuranceProfitDesc = 'DEALS_AVERAGE_INSURANCE_PROFIT_DESC',
  DealsAverageTotalBackFeesAftermarketProfitAsc = 'DEALS_AVERAGE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsAverageTotalBackFeesAftermarketProfitDesc = 'DEALS_AVERAGE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsAverageFinanceReserveAsc = 'DEALS_AVERAGE_FINANCE_RESERVE_ASC',
  DealsAverageFinanceReserveDesc = 'DEALS_AVERAGE_FINANCE_RESERVE_DESC',
  DealsAverageTotalBackCommissionAsc = 'DEALS_AVERAGE_TOTAL_BACK_COMMISSION_ASC',
  DealsAverageTotalBackCommissionDesc = 'DEALS_AVERAGE_TOTAL_BACK_COMMISSION_DESC',
  DealsAverageTotalBackSalesAsc = 'DEALS_AVERAGE_TOTAL_BACK_SALES_ASC',
  DealsAverageTotalBackSalesDesc = 'DEALS_AVERAGE_TOTAL_BACK_SALES_DESC',
  DealsAverageTotalBackCostAsc = 'DEALS_AVERAGE_TOTAL_BACK_COST_ASC',
  DealsAverageTotalBackCostDesc = 'DEALS_AVERAGE_TOTAL_BACK_COST_DESC',
  DealsAverageBackGrossAsc = 'DEALS_AVERAGE_BACK_GROSS_ASC',
  DealsAverageBackGrossDesc = 'DEALS_AVERAGE_BACK_GROSS_DESC',
  DealsAverageTotalProfitAsc = 'DEALS_AVERAGE_TOTAL_PROFIT_ASC',
  DealsAverageTotalProfitDesc = 'DEALS_AVERAGE_TOTAL_PROFIT_DESC',
  DealsAverageGrossProfitAsc = 'DEALS_AVERAGE_GROSS_PROFIT_ASC',
  DealsAverageGrossProfitDesc = 'DEALS_AVERAGE_GROSS_PROFIT_DESC',
  DealsAverageGrossPayableAsc = 'DEALS_AVERAGE_GROSS_PAYABLE_ASC',
  DealsAverageGrossPayableDesc = 'DEALS_AVERAGE_GROSS_PAYABLE_DESC',
  DealsAverageDealStatusAsc = 'DEALS_AVERAGE_DEAL_STATUS_ASC',
  DealsAverageDealStatusDesc = 'DEALS_AVERAGE_DEAL_STATUS_DESC',
  DealsAverageEntryDateAsc = 'DEALS_AVERAGE_ENTRY_DATE_ASC',
  DealsAverageEntryDateDesc = 'DEALS_AVERAGE_ENTRY_DATE_DESC',
  DealsAverageBookedDateAsc = 'DEALS_AVERAGE_BOOKED_DATE_ASC',
  DealsAverageBookedDateDesc = 'DEALS_AVERAGE_BOOKED_DATE_DESC',
  DealsAverageFinalizedDateAsc = 'DEALS_AVERAGE_FINALIZED_DATE_ASC',
  DealsAverageFinalizedDateDesc = 'DEALS_AVERAGE_FINALIZED_DATE_DESC',
  DealsAverageContractDateAsc = 'DEALS_AVERAGE_CONTRACT_DATE_ASC',
  DealsAverageContractDateDesc = 'DEALS_AVERAGE_CONTRACT_DATE_DESC',
  DealsAverageAccountingDateAsc = 'DEALS_AVERAGE_ACCOUNTING_DATE_ASC',
  DealsAverageAccountingDateDesc = 'DEALS_AVERAGE_ACCOUNTING_DATE_DESC',
  DealsAverageStatusChangeDateAsc = 'DEALS_AVERAGE_STATUS_CHANGE_DATE_ASC',
  DealsAverageStatusChangeDateDesc = 'DEALS_AVERAGE_STATUS_CHANGE_DATE_DESC',
  DealsAverageFirstPayDateAsc = 'DEALS_AVERAGE_FIRST_PAY_DATE_ASC',
  DealsAverageFirstPayDateDesc = 'DEALS_AVERAGE_FIRST_PAY_DATE_DESC',
  DealsAverageDealTypeAsc = 'DEALS_AVERAGE_DEAL_TYPE_ASC',
  DealsAverageDealTypeDesc = 'DEALS_AVERAGE_DEAL_TYPE_DESC',
  DealsAverageSaleTypeAsc = 'DEALS_AVERAGE_SALE_TYPE_ASC',
  DealsAverageSaleTypeDesc = 'DEALS_AVERAGE_SALE_TYPE_DESC',
  DealsAverageBankIdAsc = 'DEALS_AVERAGE_BANK_ID_ASC',
  DealsAverageBankIdDesc = 'DEALS_AVERAGE_BANK_ID_DESC',
  DealsAverageBankNameAsc = 'DEALS_AVERAGE_BANK_NAME_ASC',
  DealsAverageBankNameDesc = 'DEALS_AVERAGE_BANK_NAME_DESC',
  DealsAverageBankAddressAsc = 'DEALS_AVERAGE_BANK_ADDRESS_ASC',
  DealsAverageBankAddressDesc = 'DEALS_AVERAGE_BANK_ADDRESS_DESC',
  DealsAverageTermAsc = 'DEALS_AVERAGE_TERM_ASC',
  DealsAverageTermDesc = 'DEALS_AVERAGE_TERM_DESC',
  DealsAverageAmountFinancedAsc = 'DEALS_AVERAGE_AMOUNT_FINANCED_ASC',
  DealsAverageAmountFinancedDesc = 'DEALS_AVERAGE_AMOUNT_FINANCED_DESC',
  DealsAverageAprAsc = 'DEALS_AVERAGE_APR_ASC',
  DealsAverageAprDesc = 'DEALS_AVERAGE_APR_DESC',
  DealsAverageMonthlyPaymentAsc = 'DEALS_AVERAGE_MONTHLY_PAYMENT_ASC',
  DealsAverageMonthlyPaymentDesc = 'DEALS_AVERAGE_MONTHLY_PAYMENT_DESC',
  DealsAveragePaymentTotalAsc = 'DEALS_AVERAGE_PAYMENT_TOTAL_ASC',
  DealsAveragePaymentTotalDesc = 'DEALS_AVERAGE_PAYMENT_TOTAL_DESC',
  DealsAverageRebatesAsc = 'DEALS_AVERAGE_REBATES_ASC',
  DealsAverageRebatesDesc = 'DEALS_AVERAGE_REBATES_DESC',
  DealsAverageDepositAsc = 'DEALS_AVERAGE_DEPOSIT_ASC',
  DealsAverageDepositDesc = 'DEALS_AVERAGE_DEPOSIT_DESC',
  DealsAverageDownPaymentAsc = 'DEALS_AVERAGE_DOWN_PAYMENT_ASC',
  DealsAverageDownPaymentDesc = 'DEALS_AVERAGE_DOWN_PAYMENT_DESC',
  DealsAverageTotalNetTradesAsc = 'DEALS_AVERAGE_TOTAL_NET_TRADES_ASC',
  DealsAverageTotalNetTradesDesc = 'DEALS_AVERAGE_TOTAL_NET_TRADES_DESC',
  DealsAverageTotalDownAsc = 'DEALS_AVERAGE_TOTAL_DOWN_ASC',
  DealsAverageTotalDownDesc = 'DEALS_AVERAGE_TOTAL_DOWN_DESC',
  DealsAverageBalloonAmountAsc = 'DEALS_AVERAGE_BALLOON_AMOUNT_ASC',
  DealsAverageBalloonAmountDesc = 'DEALS_AVERAGE_BALLOON_AMOUNT_DESC',
  DealsAverageAdjustedBalloonAmountAsc = 'DEALS_AVERAGE_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsAverageAdjustedBalloonAmountDesc = 'DEALS_AVERAGE_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsAverageHoldbackAmountAsc = 'DEALS_AVERAGE_HOLDBACK_AMOUNT_ASC',
  DealsAverageHoldbackAmountDesc = 'DEALS_AVERAGE_HOLDBACK_AMOUNT_DESC',
  DealsAverageTotalDriveOffAmountAsc = 'DEALS_AVERAGE_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsAverageTotalDriveOffAmountDesc = 'DEALS_AVERAGE_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsAverageLicenseFeeAsc = 'DEALS_AVERAGE_LICENSE_FEE_ASC',
  DealsAverageLicenseFeeDesc = 'DEALS_AVERAGE_LICENSE_FEE_DESC',
  DealsAverageRegistrationFeeAsc = 'DEALS_AVERAGE_REGISTRATION_FEE_ASC',
  DealsAverageRegistrationFeeDesc = 'DEALS_AVERAGE_REGISTRATION_FEE_DESC',
  DealsAverageDocumentationFeeAsc = 'DEALS_AVERAGE_DOCUMENTATION_FEE_ASC',
  DealsAverageDocumentationFeeDesc = 'DEALS_AVERAGE_DOCUMENTATION_FEE_DESC',
  DealsAverageFinanceChargeAsc = 'DEALS_AVERAGE_FINANCE_CHARGE_ASC',
  DealsAverageFinanceChargeDesc = 'DEALS_AVERAGE_FINANCE_CHARGE_DESC',
  DealsAverageTotalPickupPaymentsAsc = 'DEALS_AVERAGE_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsAverageTotalPickupPaymentsDesc = 'DEALS_AVERAGE_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsAverageSellRateAsc = 'DEALS_AVERAGE_SELL_RATE_ASC',
  DealsAverageSellRateDesc = 'DEALS_AVERAGE_SELL_RATE_DESC',
  DealsAverageBuyRateAsc = 'DEALS_AVERAGE_BUY_RATE_ASC',
  DealsAverageBuyRateDesc = 'DEALS_AVERAGE_BUY_RATE_DESC',
  DealsAverageResidualRateAsc = 'DEALS_AVERAGE_RESIDUAL_RATE_ASC',
  DealsAverageResidualRateDesc = 'DEALS_AVERAGE_RESIDUAL_RATE_DESC',
  DealsAverageResidualAmountAsc = 'DEALS_AVERAGE_RESIDUAL_AMOUNT_ASC',
  DealsAverageResidualAmountDesc = 'DEALS_AVERAGE_RESIDUAL_AMOUNT_DESC',
  DealsAverageAllowedMilesAsc = 'DEALS_AVERAGE_ALLOWED_MILES_ASC',
  DealsAverageAllowedMilesDesc = 'DEALS_AVERAGE_ALLOWED_MILES_DESC',
  DealsAverageEstimatedMilesAsc = 'DEALS_AVERAGE_ESTIMATED_MILES_ASC',
  DealsAverageEstimatedMilesDesc = 'DEALS_AVERAGE_ESTIMATED_MILES_DESC',
  DealsAverageMileageRateAsc = 'DEALS_AVERAGE_MILEAGE_RATE_ASC',
  DealsAverageMileageRateDesc = 'DEALS_AVERAGE_MILEAGE_RATE_DESC',
  DealsAverageAcquisitionFeeAsc = 'DEALS_AVERAGE_ACQUISITION_FEE_ASC',
  DealsAverageAcquisitionFeeDesc = 'DEALS_AVERAGE_ACQUISITION_FEE_DESC',
  DealsAverageBasePaymentAsc = 'DEALS_AVERAGE_BASE_PAYMENT_ASC',
  DealsAverageBasePaymentDesc = 'DEALS_AVERAGE_BASE_PAYMENT_DESC',
  DealsAverageSecurityDepositAsc = 'DEALS_AVERAGE_SECURITY_DEPOSIT_ASC',
  DealsAverageSecurityDepositDesc = 'DEALS_AVERAGE_SECURITY_DEPOSIT_DESC',
  DealsAverageTotalCapitalReductionAsc = 'DEALS_AVERAGE_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsAverageTotalCapitalReductionDesc = 'DEALS_AVERAGE_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsAverageNetCapitalCostAsc = 'DEALS_AVERAGE_NET_CAPITAL_COST_ASC',
  DealsAverageNetCapitalCostDesc = 'DEALS_AVERAGE_NET_CAPITAL_COST_DESC',
  DealsAverageLeaseDepreciationValueAsc = 'DEALS_AVERAGE_LEASE_DEPRECIATION_VALUE_ASC',
  DealsAverageLeaseDepreciationValueDesc = 'DEALS_AVERAGE_LEASE_DEPRECIATION_VALUE_DESC',
  DealsAverageDealerFeesAsc = 'DEALS_AVERAGE_DEALER_FEES_ASC',
  DealsAverageDealerFeesDesc = 'DEALS_AVERAGE_DEALER_FEES_DESC',
  DealsAverageGovernmentFeesAsc = 'DEALS_AVERAGE_GOVERNMENT_FEES_ASC',
  DealsAverageGovernmentFeesDesc = 'DEALS_AVERAGE_GOVERNMENT_FEES_DESC',
  DealsAverageTotalTaxAsc = 'DEALS_AVERAGE_TOTAL_TAX_ASC',
  DealsAverageTotalTaxDesc = 'DEALS_AVERAGE_TOTAL_TAX_DESC',
  DealsAverageRegistrationStateAsc = 'DEALS_AVERAGE_REGISTRATION_STATE_ASC',
  DealsAverageRegistrationStateDesc = 'DEALS_AVERAGE_REGISTRATION_STATE_DESC',
  DealsAverageReportOfSaleNumberAsc = 'DEALS_AVERAGE_REPORT_OF_SALE_NUMBER_ASC',
  DealsAverageReportOfSaleNumberDesc = 'DEALS_AVERAGE_REPORT_OF_SALE_NUMBER_DESC',
  DealsAverageSalesman_1TotalCommissionAsc = 'DEALS_AVERAGE_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsAverageSalesman_1TotalCommissionDesc = 'DEALS_AVERAGE_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsAverageSalesman_1FrontCommissionAsc = 'DEALS_AVERAGE_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsAverageSalesman_1FrontCommissionDesc = 'DEALS_AVERAGE_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsAverageSalesman_1BackCommissionAsc = 'DEALS_AVERAGE_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsAverageSalesman_1BackCommissionDesc = 'DEALS_AVERAGE_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsAverageSalesman_2TotalCommissionAsc = 'DEALS_AVERAGE_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsAverageSalesman_2TotalCommissionDesc = 'DEALS_AVERAGE_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsAverageSalesman_2FrontCommissionAsc = 'DEALS_AVERAGE_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsAverageSalesman_2FrontCommissionDesc = 'DEALS_AVERAGE_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsAverageSalesman_2BackCommissionAsc = 'DEALS_AVERAGE_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsAverageSalesman_2BackCommissionDesc = 'DEALS_AVERAGE_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsAverageSalesman_3TotalCommissionAsc = 'DEALS_AVERAGE_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsAverageSalesman_3TotalCommissionDesc = 'DEALS_AVERAGE_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsAverageSalesman_3FrontCommissionAsc = 'DEALS_AVERAGE_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsAverageSalesman_3FrontCommissionDesc = 'DEALS_AVERAGE_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsAverageSalesman_3BackCommissionAsc = 'DEALS_AVERAGE_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsAverageSalesman_3BackCommissionDesc = 'DEALS_AVERAGE_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsAverageWarranty_1NameAsc = 'DEALS_AVERAGE_WARRANTY_1_NAME_ASC',
  DealsAverageWarranty_1NameDesc = 'DEALS_AVERAGE_WARRANTY_1_NAME_DESC',
  DealsAverageWarranty_1SaleAsc = 'DEALS_AVERAGE_WARRANTY_1_SALE_ASC',
  DealsAverageWarranty_1SaleDesc = 'DEALS_AVERAGE_WARRANTY_1_SALE_DESC',
  DealsAverageWarranty_1CostAsc = 'DEALS_AVERAGE_WARRANTY_1_COST_ASC',
  DealsAverageWarranty_1CostDesc = 'DEALS_AVERAGE_WARRANTY_1_COST_DESC',
  DealsAverageWarranty_1MilesAsc = 'DEALS_AVERAGE_WARRANTY_1_MILES_ASC',
  DealsAverageWarranty_1MilesDesc = 'DEALS_AVERAGE_WARRANTY_1_MILES_DESC',
  DealsAverageWarranty_1TermAsc = 'DEALS_AVERAGE_WARRANTY_1_TERM_ASC',
  DealsAverageWarranty_1TermDesc = 'DEALS_AVERAGE_WARRANTY_1_TERM_DESC',
  DealsAverageWarranty_2NameAsc = 'DEALS_AVERAGE_WARRANTY_2_NAME_ASC',
  DealsAverageWarranty_2NameDesc = 'DEALS_AVERAGE_WARRANTY_2_NAME_DESC',
  DealsAverageWarranty_2SaleAsc = 'DEALS_AVERAGE_WARRANTY_2_SALE_ASC',
  DealsAverageWarranty_2SaleDesc = 'DEALS_AVERAGE_WARRANTY_2_SALE_DESC',
  DealsAverageWarranty_2CostAsc = 'DEALS_AVERAGE_WARRANTY_2_COST_ASC',
  DealsAverageWarranty_2CostDesc = 'DEALS_AVERAGE_WARRANTY_2_COST_DESC',
  DealsAverageWarranty_2MilesAsc = 'DEALS_AVERAGE_WARRANTY_2_MILES_ASC',
  DealsAverageWarranty_2MilesDesc = 'DEALS_AVERAGE_WARRANTY_2_MILES_DESC',
  DealsAverageWarranty_2TermAsc = 'DEALS_AVERAGE_WARRANTY_2_TERM_ASC',
  DealsAverageWarranty_2TermDesc = 'DEALS_AVERAGE_WARRANTY_2_TERM_DESC',
  DealsAverageWarranty_3NameAsc = 'DEALS_AVERAGE_WARRANTY_3_NAME_ASC',
  DealsAverageWarranty_3NameDesc = 'DEALS_AVERAGE_WARRANTY_3_NAME_DESC',
  DealsAverageWarranty_3SaleAsc = 'DEALS_AVERAGE_WARRANTY_3_SALE_ASC',
  DealsAverageWarranty_3SaleDesc = 'DEALS_AVERAGE_WARRANTY_3_SALE_DESC',
  DealsAverageWarranty_3CostAsc = 'DEALS_AVERAGE_WARRANTY_3_COST_ASC',
  DealsAverageWarranty_3CostDesc = 'DEALS_AVERAGE_WARRANTY_3_COST_DESC',
  DealsAverageWarranty_3MilesAsc = 'DEALS_AVERAGE_WARRANTY_3_MILES_ASC',
  DealsAverageWarranty_3MilesDesc = 'DEALS_AVERAGE_WARRANTY_3_MILES_DESC',
  DealsAverageWarranty_3TermAsc = 'DEALS_AVERAGE_WARRANTY_3_TERM_ASC',
  DealsAverageWarranty_3TermDesc = 'DEALS_AVERAGE_WARRANTY_3_TERM_DESC',
  DealsAverageWarranty_4NameAsc = 'DEALS_AVERAGE_WARRANTY_4_NAME_ASC',
  DealsAverageWarranty_4NameDesc = 'DEALS_AVERAGE_WARRANTY_4_NAME_DESC',
  DealsAverageWarranty_4SaleAsc = 'DEALS_AVERAGE_WARRANTY_4_SALE_ASC',
  DealsAverageWarranty_4SaleDesc = 'DEALS_AVERAGE_WARRANTY_4_SALE_DESC',
  DealsAverageWarranty_4CostAsc = 'DEALS_AVERAGE_WARRANTY_4_COST_ASC',
  DealsAverageWarranty_4CostDesc = 'DEALS_AVERAGE_WARRANTY_4_COST_DESC',
  DealsAverageWarranty_4MilesAsc = 'DEALS_AVERAGE_WARRANTY_4_MILES_ASC',
  DealsAverageWarranty_4MilesDesc = 'DEALS_AVERAGE_WARRANTY_4_MILES_DESC',
  DealsAverageWarranty_4TermAsc = 'DEALS_AVERAGE_WARRANTY_4_TERM_ASC',
  DealsAverageWarranty_4TermDesc = 'DEALS_AVERAGE_WARRANTY_4_TERM_DESC',
  DealsAverageWarranty_5NameAsc = 'DEALS_AVERAGE_WARRANTY_5_NAME_ASC',
  DealsAverageWarranty_5NameDesc = 'DEALS_AVERAGE_WARRANTY_5_NAME_DESC',
  DealsAverageWarranty_5SaleAsc = 'DEALS_AVERAGE_WARRANTY_5_SALE_ASC',
  DealsAverageWarranty_5SaleDesc = 'DEALS_AVERAGE_WARRANTY_5_SALE_DESC',
  DealsAverageWarranty_5CostAsc = 'DEALS_AVERAGE_WARRANTY_5_COST_ASC',
  DealsAverageWarranty_5CostDesc = 'DEALS_AVERAGE_WARRANTY_5_COST_DESC',
  DealsAverageWarranty_5MilesAsc = 'DEALS_AVERAGE_WARRANTY_5_MILES_ASC',
  DealsAverageWarranty_5MilesDesc = 'DEALS_AVERAGE_WARRANTY_5_MILES_DESC',
  DealsAverageWarranty_5TermAsc = 'DEALS_AVERAGE_WARRANTY_5_TERM_ASC',
  DealsAverageWarranty_5TermDesc = 'DEALS_AVERAGE_WARRANTY_5_TERM_DESC',
  DealsAverageTotalFeeAftermarketSaleAsc = 'DEALS_AVERAGE_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsAverageTotalFeeAftermarketSaleDesc = 'DEALS_AVERAGE_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsAverageTotalFeeAftermarketCostAsc = 'DEALS_AVERAGE_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsAverageTotalFeeAftermarketCostDesc = 'DEALS_AVERAGE_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsAverageFeeAftermarket_1NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_NAME_ASC',
  DealsAverageFeeAftermarket_1NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_NAME_DESC',
  DealsAverageFeeAftermarket_1SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_SALE_ASC',
  DealsAverageFeeAftermarket_1SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_SALE_DESC',
  DealsAverageFeeAftermarket_1CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_COST_ASC',
  DealsAverageFeeAftermarket_1CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_COST_DESC',
  DealsAverageFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_2NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_NAME_ASC',
  DealsAverageFeeAftermarket_2NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_NAME_DESC',
  DealsAverageFeeAftermarket_2SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_SALE_ASC',
  DealsAverageFeeAftermarket_2SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_SALE_DESC',
  DealsAverageFeeAftermarket_2CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_COST_ASC',
  DealsAverageFeeAftermarket_2CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_COST_DESC',
  DealsAverageFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_3NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_NAME_ASC',
  DealsAverageFeeAftermarket_3NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_NAME_DESC',
  DealsAverageFeeAftermarket_3SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_SALE_ASC',
  DealsAverageFeeAftermarket_3SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_SALE_DESC',
  DealsAverageFeeAftermarket_3CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_COST_ASC',
  DealsAverageFeeAftermarket_3CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_COST_DESC',
  DealsAverageFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_4NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_NAME_ASC',
  DealsAverageFeeAftermarket_4NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_NAME_DESC',
  DealsAverageFeeAftermarket_4SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_SALE_ASC',
  DealsAverageFeeAftermarket_4SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_SALE_DESC',
  DealsAverageFeeAftermarket_4CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_COST_ASC',
  DealsAverageFeeAftermarket_4CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_COST_DESC',
  DealsAverageFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_5NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_NAME_ASC',
  DealsAverageFeeAftermarket_5NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_NAME_DESC',
  DealsAverageFeeAftermarket_5SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_SALE_ASC',
  DealsAverageFeeAftermarket_5SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_SALE_DESC',
  DealsAverageFeeAftermarket_5CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_COST_ASC',
  DealsAverageFeeAftermarket_5CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_COST_DESC',
  DealsAverageFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_6NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_NAME_ASC',
  DealsAverageFeeAftermarket_6NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_NAME_DESC',
  DealsAverageFeeAftermarket_6SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_SALE_ASC',
  DealsAverageFeeAftermarket_6SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_SALE_DESC',
  DealsAverageFeeAftermarket_6CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_COST_ASC',
  DealsAverageFeeAftermarket_6CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_COST_DESC',
  DealsAverageFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_7NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_NAME_ASC',
  DealsAverageFeeAftermarket_7NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_NAME_DESC',
  DealsAverageFeeAftermarket_7SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_SALE_ASC',
  DealsAverageFeeAftermarket_7SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_SALE_DESC',
  DealsAverageFeeAftermarket_7CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_COST_ASC',
  DealsAverageFeeAftermarket_7CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_COST_DESC',
  DealsAverageFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_8NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_NAME_ASC',
  DealsAverageFeeAftermarket_8NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_NAME_DESC',
  DealsAverageFeeAftermarket_8SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_SALE_ASC',
  DealsAverageFeeAftermarket_8SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_SALE_DESC',
  DealsAverageFeeAftermarket_8CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_COST_ASC',
  DealsAverageFeeAftermarket_8CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_COST_DESC',
  DealsAverageFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_9NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_NAME_ASC',
  DealsAverageFeeAftermarket_9NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_NAME_DESC',
  DealsAverageFeeAftermarket_9SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_SALE_ASC',
  DealsAverageFeeAftermarket_9SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_SALE_DESC',
  DealsAverageFeeAftermarket_9CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_COST_ASC',
  DealsAverageFeeAftermarket_9CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_COST_DESC',
  DealsAverageFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_10NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_NAME_ASC',
  DealsAverageFeeAftermarket_10NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_NAME_DESC',
  DealsAverageFeeAftermarket_10SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_SALE_ASC',
  DealsAverageFeeAftermarket_10SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_SALE_DESC',
  DealsAverageFeeAftermarket_10CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_COST_ASC',
  DealsAverageFeeAftermarket_10CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_COST_DESC',
  DealsAverageFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_11NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_NAME_ASC',
  DealsAverageFeeAftermarket_11NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_NAME_DESC',
  DealsAverageFeeAftermarket_11SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_SALE_ASC',
  DealsAverageFeeAftermarket_11SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_SALE_DESC',
  DealsAverageFeeAftermarket_11CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_COST_ASC',
  DealsAverageFeeAftermarket_11CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_COST_DESC',
  DealsAverageFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_12NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_NAME_ASC',
  DealsAverageFeeAftermarket_12NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_NAME_DESC',
  DealsAverageFeeAftermarket_12SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_SALE_ASC',
  DealsAverageFeeAftermarket_12SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_SALE_DESC',
  DealsAverageFeeAftermarket_12CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_COST_ASC',
  DealsAverageFeeAftermarket_12CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_COST_DESC',
  DealsAverageFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_13NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_NAME_ASC',
  DealsAverageFeeAftermarket_13NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_NAME_DESC',
  DealsAverageFeeAftermarket_13SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_SALE_ASC',
  DealsAverageFeeAftermarket_13SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_SALE_DESC',
  DealsAverageFeeAftermarket_13CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_COST_ASC',
  DealsAverageFeeAftermarket_13CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_COST_DESC',
  DealsAverageFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_14NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_NAME_ASC',
  DealsAverageFeeAftermarket_14NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_NAME_DESC',
  DealsAverageFeeAftermarket_14SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_SALE_ASC',
  DealsAverageFeeAftermarket_14SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_SALE_DESC',
  DealsAverageFeeAftermarket_14CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_COST_ASC',
  DealsAverageFeeAftermarket_14CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_COST_DESC',
  DealsAverageFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_15NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_NAME_ASC',
  DealsAverageFeeAftermarket_15NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_NAME_DESC',
  DealsAverageFeeAftermarket_15SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_SALE_ASC',
  DealsAverageFeeAftermarket_15SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_SALE_DESC',
  DealsAverageFeeAftermarket_15CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_COST_ASC',
  DealsAverageFeeAftermarket_15CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_COST_DESC',
  DealsAverageFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_16NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_NAME_ASC',
  DealsAverageFeeAftermarket_16NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_NAME_DESC',
  DealsAverageFeeAftermarket_16SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_SALE_ASC',
  DealsAverageFeeAftermarket_16SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_SALE_DESC',
  DealsAverageFeeAftermarket_16CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_COST_ASC',
  DealsAverageFeeAftermarket_16CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_COST_DESC',
  DealsAverageFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_17NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_NAME_ASC',
  DealsAverageFeeAftermarket_17NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_NAME_DESC',
  DealsAverageFeeAftermarket_17SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_SALE_ASC',
  DealsAverageFeeAftermarket_17SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_SALE_DESC',
  DealsAverageFeeAftermarket_17CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_COST_ASC',
  DealsAverageFeeAftermarket_17CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_COST_DESC',
  DealsAverageFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_18NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_NAME_ASC',
  DealsAverageFeeAftermarket_18NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_NAME_DESC',
  DealsAverageFeeAftermarket_18SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_SALE_ASC',
  DealsAverageFeeAftermarket_18SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_SALE_DESC',
  DealsAverageFeeAftermarket_18CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_COST_ASC',
  DealsAverageFeeAftermarket_18CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_COST_DESC',
  DealsAverageFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_19NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_NAME_ASC',
  DealsAverageFeeAftermarket_19NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_NAME_DESC',
  DealsAverageFeeAftermarket_19SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_SALE_ASC',
  DealsAverageFeeAftermarket_19SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_SALE_DESC',
  DealsAverageFeeAftermarket_19CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_COST_ASC',
  DealsAverageFeeAftermarket_19CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_COST_DESC',
  DealsAverageFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_20NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_NAME_ASC',
  DealsAverageFeeAftermarket_20NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_NAME_DESC',
  DealsAverageFeeAftermarket_20SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_SALE_ASC',
  DealsAverageFeeAftermarket_20SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_SALE_DESC',
  DealsAverageFeeAftermarket_20CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_COST_ASC',
  DealsAverageFeeAftermarket_20CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_COST_DESC',
  DealsAverageFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsAverageInsurance_1TypeAsc = 'DEALS_AVERAGE_INSURANCE_1_TYPE_ASC',
  DealsAverageInsurance_1TypeDesc = 'DEALS_AVERAGE_INSURANCE_1_TYPE_DESC',
  DealsAverageInsurance_1NameAsc = 'DEALS_AVERAGE_INSURANCE_1_NAME_ASC',
  DealsAverageInsurance_1NameDesc = 'DEALS_AVERAGE_INSURANCE_1_NAME_DESC',
  DealsAverageInsurance_1SaleAsc = 'DEALS_AVERAGE_INSURANCE_1_SALE_ASC',
  DealsAverageInsurance_1SaleDesc = 'DEALS_AVERAGE_INSURANCE_1_SALE_DESC',
  DealsAverageInsurance_1CostAsc = 'DEALS_AVERAGE_INSURANCE_1_COST_ASC',
  DealsAverageInsurance_1CostDesc = 'DEALS_AVERAGE_INSURANCE_1_COST_DESC',
  DealsAverageInsurance_1TermAsc = 'DEALS_AVERAGE_INSURANCE_1_TERM_ASC',
  DealsAverageInsurance_1TermDesc = 'DEALS_AVERAGE_INSURANCE_1_TERM_DESC',
  DealsAverageInsurance_2TypeAsc = 'DEALS_AVERAGE_INSURANCE_2_TYPE_ASC',
  DealsAverageInsurance_2TypeDesc = 'DEALS_AVERAGE_INSURANCE_2_TYPE_DESC',
  DealsAverageInsurance_2NameAsc = 'DEALS_AVERAGE_INSURANCE_2_NAME_ASC',
  DealsAverageInsurance_2NameDesc = 'DEALS_AVERAGE_INSURANCE_2_NAME_DESC',
  DealsAverageInsurance_2SaleAsc = 'DEALS_AVERAGE_INSURANCE_2_SALE_ASC',
  DealsAverageInsurance_2SaleDesc = 'DEALS_AVERAGE_INSURANCE_2_SALE_DESC',
  DealsAverageInsurance_2CostAsc = 'DEALS_AVERAGE_INSURANCE_2_COST_ASC',
  DealsAverageInsurance_2CostDesc = 'DEALS_AVERAGE_INSURANCE_2_COST_DESC',
  DealsAverageInsurance_2TermAsc = 'DEALS_AVERAGE_INSURANCE_2_TERM_ASC',
  DealsAverageInsurance_2TermDesc = 'DEALS_AVERAGE_INSURANCE_2_TERM_DESC',
  DealsAverageInsurance_3TypeAsc = 'DEALS_AVERAGE_INSURANCE_3_TYPE_ASC',
  DealsAverageInsurance_3TypeDesc = 'DEALS_AVERAGE_INSURANCE_3_TYPE_DESC',
  DealsAverageInsurance_3NameAsc = 'DEALS_AVERAGE_INSURANCE_3_NAME_ASC',
  DealsAverageInsurance_3NameDesc = 'DEALS_AVERAGE_INSURANCE_3_NAME_DESC',
  DealsAverageInsurance_3SaleAsc = 'DEALS_AVERAGE_INSURANCE_3_SALE_ASC',
  DealsAverageInsurance_3SaleDesc = 'DEALS_AVERAGE_INSURANCE_3_SALE_DESC',
  DealsAverageInsurance_3CostAsc = 'DEALS_AVERAGE_INSURANCE_3_COST_ASC',
  DealsAverageInsurance_3CostDesc = 'DEALS_AVERAGE_INSURANCE_3_COST_DESC',
  DealsAverageInsurance_3TermAsc = 'DEALS_AVERAGE_INSURANCE_3_TERM_ASC',
  DealsAverageInsurance_3TermDesc = 'DEALS_AVERAGE_INSURANCE_3_TERM_DESC',
  DealsAverageAccidentalHealthTypeAsc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsAverageAccidentalHealthTypeDesc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsAverageAccidentalHealthNameAsc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsAverageAccidentalHealthNameDesc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsAverageAccidentalHealthSaleAsc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsAverageAccidentalHealthSaleDesc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsAverageAccidentalHealthCostAsc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_COST_ASC',
  DealsAverageAccidentalHealthCostDesc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_COST_DESC',
  DealsAverageAccidentalHealthTermAsc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsAverageAccidentalHealthTermDesc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsAverageCreditLifeTypeAsc = 'DEALS_AVERAGE_CREDIT_LIFE_TYPE_ASC',
  DealsAverageCreditLifeTypeDesc = 'DEALS_AVERAGE_CREDIT_LIFE_TYPE_DESC',
  DealsAverageCreditLifeNameAsc = 'DEALS_AVERAGE_CREDIT_LIFE_NAME_ASC',
  DealsAverageCreditLifeNameDesc = 'DEALS_AVERAGE_CREDIT_LIFE_NAME_DESC',
  DealsAverageCreditLifeSaleAsc = 'DEALS_AVERAGE_CREDIT_LIFE_SALE_ASC',
  DealsAverageCreditLifeSaleDesc = 'DEALS_AVERAGE_CREDIT_LIFE_SALE_DESC',
  DealsAverageCreditLifeCostAsc = 'DEALS_AVERAGE_CREDIT_LIFE_COST_ASC',
  DealsAverageCreditLifeCostDesc = 'DEALS_AVERAGE_CREDIT_LIFE_COST_DESC',
  DealsAverageCreditLifeTermAsc = 'DEALS_AVERAGE_CREDIT_LIFE_TERM_ASC',
  DealsAverageCreditLifeTermDesc = 'DEALS_AVERAGE_CREDIT_LIFE_TERM_DESC',
  DealsAverageLevelizedLifeTypeAsc = 'DEALS_AVERAGE_LEVELIZED_LIFE_TYPE_ASC',
  DealsAverageLevelizedLifeTypeDesc = 'DEALS_AVERAGE_LEVELIZED_LIFE_TYPE_DESC',
  DealsAverageLevelizedLifeNameAsc = 'DEALS_AVERAGE_LEVELIZED_LIFE_NAME_ASC',
  DealsAverageLevelizedLifeNameDesc = 'DEALS_AVERAGE_LEVELIZED_LIFE_NAME_DESC',
  DealsAverageLevelizedLifeSaleAsc = 'DEALS_AVERAGE_LEVELIZED_LIFE_SALE_ASC',
  DealsAverageLevelizedLifeSaleDesc = 'DEALS_AVERAGE_LEVELIZED_LIFE_SALE_DESC',
  DealsAverageLevelizedLifeCostAsc = 'DEALS_AVERAGE_LEVELIZED_LIFE_COST_ASC',
  DealsAverageLevelizedLifeCostDesc = 'DEALS_AVERAGE_LEVELIZED_LIFE_COST_DESC',
  DealsAverageLevelizedLifeTermAsc = 'DEALS_AVERAGE_LEVELIZED_LIFE_TERM_ASC',
  DealsAverageLevelizedLifeTermDesc = 'DEALS_AVERAGE_LEVELIZED_LIFE_TERM_DESC',
  DealsAverageLossOfEmploymentTypeAsc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsAverageLossOfEmploymentTypeDesc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsAverageLossOfEmploymentNameAsc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsAverageLossOfEmploymentNameDesc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsAverageLossOfEmploymentSaleAsc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsAverageLossOfEmploymentSaleDesc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsAverageLossOfEmploymentCostAsc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsAverageLossOfEmploymentCostDesc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsAverageLossOfEmploymentTermAsc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsAverageLossOfEmploymentTermDesc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsAverageGuaranteedAutoProtectionTypeAsc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsAverageGuaranteedAutoProtectionTypeDesc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsAverageGuaranteedAutoProtectionNameAsc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsAverageGuaranteedAutoProtectionNameDesc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsAverageGuaranteedAutoProtectionSaleAsc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsAverageGuaranteedAutoProtectionSaleDesc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsAverageGuaranteedAutoProtectionCostAsc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsAverageGuaranteedAutoProtectionCostDesc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsAverageGuaranteedAutoProtectionTermAsc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsAverageGuaranteedAutoProtectionTermDesc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsAverageSaleCommentsAsc = 'DEALS_AVERAGE_SALE_COMMENTS_ASC',
  DealsAverageSaleCommentsDesc = 'DEALS_AVERAGE_SALE_COMMENTS_DESC',
  DealsAverageCassStdLine1Asc = 'DEALS_AVERAGE_CASS_STD_LINE1_ASC',
  DealsAverageCassStdLine1Desc = 'DEALS_AVERAGE_CASS_STD_LINE1_DESC',
  DealsAverageCassStdLine2Asc = 'DEALS_AVERAGE_CASS_STD_LINE2_ASC',
  DealsAverageCassStdLine2Desc = 'DEALS_AVERAGE_CASS_STD_LINE2_DESC',
  DealsAverageCassStdCityAsc = 'DEALS_AVERAGE_CASS_STD_CITY_ASC',
  DealsAverageCassStdCityDesc = 'DEALS_AVERAGE_CASS_STD_CITY_DESC',
  DealsAverageCassStdStateAsc = 'DEALS_AVERAGE_CASS_STD_STATE_ASC',
  DealsAverageCassStdStateDesc = 'DEALS_AVERAGE_CASS_STD_STATE_DESC',
  DealsAverageCassStdZipAsc = 'DEALS_AVERAGE_CASS_STD_ZIP_ASC',
  DealsAverageCassStdZipDesc = 'DEALS_AVERAGE_CASS_STD_ZIP_DESC',
  DealsAverageCassStdZip4Asc = 'DEALS_AVERAGE_CASS_STD_ZIP4_ASC',
  DealsAverageCassStdZip4Desc = 'DEALS_AVERAGE_CASS_STD_ZIP4_DESC',
  DealsAverageCassStdDpbcAsc = 'DEALS_AVERAGE_CASS_STD_DPBC_ASC',
  DealsAverageCassStdDpbcDesc = 'DEALS_AVERAGE_CASS_STD_DPBC_DESC',
  DealsAverageCassStdChkdgtAsc = 'DEALS_AVERAGE_CASS_STD_CHKDGT_ASC',
  DealsAverageCassStdChkdgtDesc = 'DEALS_AVERAGE_CASS_STD_CHKDGT_DESC',
  DealsAverageCassStdCartAsc = 'DEALS_AVERAGE_CASS_STD_CART_ASC',
  DealsAverageCassStdCartDesc = 'DEALS_AVERAGE_CASS_STD_CART_DESC',
  DealsAverageCassStdLotAsc = 'DEALS_AVERAGE_CASS_STD_LOT_ASC',
  DealsAverageCassStdLotDesc = 'DEALS_AVERAGE_CASS_STD_LOT_DESC',
  DealsAverageCassStdLotordAsc = 'DEALS_AVERAGE_CASS_STD_LOTORD_ASC',
  DealsAverageCassStdLotordDesc = 'DEALS_AVERAGE_CASS_STD_LOTORD_DESC',
  DealsAverageCassStdUrbAsc = 'DEALS_AVERAGE_CASS_STD_URB_ASC',
  DealsAverageCassStdUrbDesc = 'DEALS_AVERAGE_CASS_STD_URB_DESC',
  DealsAverageCassStdFipsAsc = 'DEALS_AVERAGE_CASS_STD_FIPS_ASC',
  DealsAverageCassStdFipsDesc = 'DEALS_AVERAGE_CASS_STD_FIPS_DESC',
  DealsAverageCassStdEwsAsc = 'DEALS_AVERAGE_CASS_STD_EWS_ASC',
  DealsAverageCassStdEwsDesc = 'DEALS_AVERAGE_CASS_STD_EWS_DESC',
  DealsAverageCassStdLacsAsc = 'DEALS_AVERAGE_CASS_STD_LACS_ASC',
  DealsAverageCassStdLacsDesc = 'DEALS_AVERAGE_CASS_STD_LACS_DESC',
  DealsAverageCassStdZipmovAsc = 'DEALS_AVERAGE_CASS_STD_ZIPMOV_ASC',
  DealsAverageCassStdZipmovDesc = 'DEALS_AVERAGE_CASS_STD_ZIPMOV_DESC',
  DealsAverageCassStdZ4LomAsc = 'DEALS_AVERAGE_CASS_STD_Z4LOM_ASC',
  DealsAverageCassStdZ4LomDesc = 'DEALS_AVERAGE_CASS_STD_Z4LOM_DESC',
  DealsAverageCassStdNdiaptAsc = 'DEALS_AVERAGE_CASS_STD_NDIAPT_ASC',
  DealsAverageCassStdNdiaptDesc = 'DEALS_AVERAGE_CASS_STD_NDIAPT_DESC',
  DealsAverageCassStdNdirrAsc = 'DEALS_AVERAGE_CASS_STD_NDIRR_ASC',
  DealsAverageCassStdNdirrDesc = 'DEALS_AVERAGE_CASS_STD_NDIRR_DESC',
  DealsAverageCassStdLacsrtAsc = 'DEALS_AVERAGE_CASS_STD_LACSRT_ASC',
  DealsAverageCassStdLacsrtDesc = 'DEALS_AVERAGE_CASS_STD_LACSRT_DESC',
  DealsAverageCassStdErrorCdAsc = 'DEALS_AVERAGE_CASS_STD_ERROR_CD_ASC',
  DealsAverageCassStdErrorCdDesc = 'DEALS_AVERAGE_CASS_STD_ERROR_CD_DESC',
  DealsStddevSampleIdAsc = 'DEALS_STDDEV_SAMPLE_ID_ASC',
  DealsStddevSampleIdDesc = 'DEALS_STDDEV_SAMPLE_ID_DESC',
  DealsStddevSampleLeadIdAsc = 'DEALS_STDDEV_SAMPLE_LEAD_ID_ASC',
  DealsStddevSampleLeadIdDesc = 'DEALS_STDDEV_SAMPLE_LEAD_ID_DESC',
  DealsStddevSampleCompanyIntegrationIdAsc = 'DEALS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  DealsStddevSampleCompanyIntegrationIdDesc = 'DEALS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  DealsStddevSampleFileTypeAsc = 'DEALS_STDDEV_SAMPLE_FILE_TYPE_ASC',
  DealsStddevSampleFileTypeDesc = 'DEALS_STDDEV_SAMPLE_FILE_TYPE_DESC',
  DealsStddevSampleDvDealerIdAsc = 'DEALS_STDDEV_SAMPLE_DV_DEALER_ID_ASC',
  DealsStddevSampleDvDealerIdDesc = 'DEALS_STDDEV_SAMPLE_DV_DEALER_ID_DESC',
  DealsStddevSampleVendorDealerIdAsc = 'DEALS_STDDEV_SAMPLE_VENDOR_DEALER_ID_ASC',
  DealsStddevSampleVendorDealerIdDesc = 'DEALS_STDDEV_SAMPLE_VENDOR_DEALER_ID_DESC',
  DealsStddevSampleDmsTypeAsc = 'DEALS_STDDEV_SAMPLE_DMS_TYPE_ASC',
  DealsStddevSampleDmsTypeDesc = 'DEALS_STDDEV_SAMPLE_DMS_TYPE_DESC',
  DealsStddevSampleDealNumberAsc = 'DEALS_STDDEV_SAMPLE_DEAL_NUMBER_ASC',
  DealsStddevSampleDealNumberDesc = 'DEALS_STDDEV_SAMPLE_DEAL_NUMBER_DESC',
  DealsStddevSampleCustomerNumberAsc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_NUMBER_ASC',
  DealsStddevSampleCustomerNumberDesc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_NUMBER_DESC',
  DealsStddevSampleFullNameAsc = 'DEALS_STDDEV_SAMPLE_FULL_NAME_ASC',
  DealsStddevSampleFullNameDesc = 'DEALS_STDDEV_SAMPLE_FULL_NAME_DESC',
  DealsStddevSampleSalutationAsc = 'DEALS_STDDEV_SAMPLE_SALUTATION_ASC',
  DealsStddevSampleSalutationDesc = 'DEALS_STDDEV_SAMPLE_SALUTATION_DESC',
  DealsStddevSampleFirstNameAsc = 'DEALS_STDDEV_SAMPLE_FIRST_NAME_ASC',
  DealsStddevSampleFirstNameDesc = 'DEALS_STDDEV_SAMPLE_FIRST_NAME_DESC',
  DealsStddevSampleMiddleNameAsc = 'DEALS_STDDEV_SAMPLE_MIDDLE_NAME_ASC',
  DealsStddevSampleMiddleNameDesc = 'DEALS_STDDEV_SAMPLE_MIDDLE_NAME_DESC',
  DealsStddevSampleLastNameAsc = 'DEALS_STDDEV_SAMPLE_LAST_NAME_ASC',
  DealsStddevSampleLastNameDesc = 'DEALS_STDDEV_SAMPLE_LAST_NAME_DESC',
  DealsStddevSampleSuffixAsc = 'DEALS_STDDEV_SAMPLE_SUFFIX_ASC',
  DealsStddevSampleSuffixDesc = 'DEALS_STDDEV_SAMPLE_SUFFIX_DESC',
  DealsStddevSampleAddressLine_1Asc = 'DEALS_STDDEV_SAMPLE_ADDRESS_LINE_1_ASC',
  DealsStddevSampleAddressLine_1Desc = 'DEALS_STDDEV_SAMPLE_ADDRESS_LINE_1_DESC',
  DealsStddevSampleAddressLine_2Asc = 'DEALS_STDDEV_SAMPLE_ADDRESS_LINE_2_ASC',
  DealsStddevSampleAddressLine_2Desc = 'DEALS_STDDEV_SAMPLE_ADDRESS_LINE_2_DESC',
  DealsStddevSampleCityAsc = 'DEALS_STDDEV_SAMPLE_CITY_ASC',
  DealsStddevSampleCityDesc = 'DEALS_STDDEV_SAMPLE_CITY_DESC',
  DealsStddevSampleStateAsc = 'DEALS_STDDEV_SAMPLE_STATE_ASC',
  DealsStddevSampleStateDesc = 'DEALS_STDDEV_SAMPLE_STATE_DESC',
  DealsStddevSampleZipAsc = 'DEALS_STDDEV_SAMPLE_ZIP_ASC',
  DealsStddevSampleZipDesc = 'DEALS_STDDEV_SAMPLE_ZIP_DESC',
  DealsStddevSampleCountyAsc = 'DEALS_STDDEV_SAMPLE_COUNTY_ASC',
  DealsStddevSampleCountyDesc = 'DEALS_STDDEV_SAMPLE_COUNTY_DESC',
  DealsStddevSampleHomePhoneAsc = 'DEALS_STDDEV_SAMPLE_HOME_PHONE_ASC',
  DealsStddevSampleHomePhoneDesc = 'DEALS_STDDEV_SAMPLE_HOME_PHONE_DESC',
  DealsStddevSampleCellPhoneAsc = 'DEALS_STDDEV_SAMPLE_CELL_PHONE_ASC',
  DealsStddevSampleCellPhoneDesc = 'DEALS_STDDEV_SAMPLE_CELL_PHONE_DESC',
  DealsStddevSampleWorkPhoneAsc = 'DEALS_STDDEV_SAMPLE_WORK_PHONE_ASC',
  DealsStddevSampleWorkPhoneDesc = 'DEALS_STDDEV_SAMPLE_WORK_PHONE_DESC',
  DealsStddevSampleWorkExtensionAsc = 'DEALS_STDDEV_SAMPLE_WORK_EXTENSION_ASC',
  DealsStddevSampleWorkExtensionDesc = 'DEALS_STDDEV_SAMPLE_WORK_EXTENSION_DESC',
  DealsStddevSampleEmail_1Asc = 'DEALS_STDDEV_SAMPLE_EMAIL_1_ASC',
  DealsStddevSampleEmail_1Desc = 'DEALS_STDDEV_SAMPLE_EMAIL_1_DESC',
  DealsStddevSampleEmail_2Asc = 'DEALS_STDDEV_SAMPLE_EMAIL_2_ASC',
  DealsStddevSampleEmail_2Desc = 'DEALS_STDDEV_SAMPLE_EMAIL_2_DESC',
  DealsStddevSampleEmail_3Asc = 'DEALS_STDDEV_SAMPLE_EMAIL_3_ASC',
  DealsStddevSampleEmail_3Desc = 'DEALS_STDDEV_SAMPLE_EMAIL_3_DESC',
  DealsStddevSampleBirthDateAsc = 'DEALS_STDDEV_SAMPLE_BIRTH_DATE_ASC',
  DealsStddevSampleBirthDateDesc = 'DEALS_STDDEV_SAMPLE_BIRTH_DATE_DESC',
  DealsStddevSampleIndividualBusinessFlagAsc = 'DEALS_STDDEV_SAMPLE_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsStddevSampleIndividualBusinessFlagDesc = 'DEALS_STDDEV_SAMPLE_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsStddevSampleOptOutAsc = 'DEALS_STDDEV_SAMPLE_OPT_OUT_ASC',
  DealsStddevSampleOptOutDesc = 'DEALS_STDDEV_SAMPLE_OPT_OUT_DESC',
  DealsStddevSampleBlockEmailAsc = 'DEALS_STDDEV_SAMPLE_BLOCK_EMAIL_ASC',
  DealsStddevSampleBlockEmailDesc = 'DEALS_STDDEV_SAMPLE_BLOCK_EMAIL_DESC',
  DealsStddevSampleBlockPhoneAsc = 'DEALS_STDDEV_SAMPLE_BLOCK_PHONE_ASC',
  DealsStddevSampleBlockPhoneDesc = 'DEALS_STDDEV_SAMPLE_BLOCK_PHONE_DESC',
  DealsStddevSampleBlockMailAsc = 'DEALS_STDDEV_SAMPLE_BLOCK_MAIL_ASC',
  DealsStddevSampleBlockMailDesc = 'DEALS_STDDEV_SAMPLE_BLOCK_MAIL_DESC',
  DealsStddevSampleLanguageAsc = 'DEALS_STDDEV_SAMPLE_LANGUAGE_ASC',
  DealsStddevSampleLanguageDesc = 'DEALS_STDDEV_SAMPLE_LANGUAGE_DESC',
  DealsStddevSampleCustomerCreateDateAsc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_CREATE_DATE_ASC',
  DealsStddevSampleCustomerCreateDateDesc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_CREATE_DATE_DESC',
  DealsStddevSampleCustomerLastActivityDateAsc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsStddevSampleCustomerLastActivityDateDesc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsStddevSampleCoBuyerCustomerNumberAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsStddevSampleCoBuyerCustomerNumberDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsStddevSampleCoBuyerFullNameAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_FULL_NAME_ASC',
  DealsStddevSampleCoBuyerFullNameDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_FULL_NAME_DESC',
  DealsStddevSampleCoBuyerSalutationAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_SALUTATION_ASC',
  DealsStddevSampleCoBuyerSalutationDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_SALUTATION_DESC',
  DealsStddevSampleCoBuyerFirstNameAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_FIRST_NAME_ASC',
  DealsStddevSampleCoBuyerFirstNameDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_FIRST_NAME_DESC',
  DealsStddevSampleCoBuyerMiddleNameAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_MIDDLE_NAME_ASC',
  DealsStddevSampleCoBuyerMiddleNameDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_MIDDLE_NAME_DESC',
  DealsStddevSampleCoBuyerLastNameAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_LAST_NAME_ASC',
  DealsStddevSampleCoBuyerLastNameDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_LAST_NAME_DESC',
  DealsStddevSampleCoBuyerSuffixAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_SUFFIX_ASC',
  DealsStddevSampleCoBuyerSuffixDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_SUFFIX_DESC',
  DealsStddevSampleCoBuyerAddressLine_1Asc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsStddevSampleCoBuyerAddressLine_1Desc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsStddevSampleCoBuyerAddressLine_2Asc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsStddevSampleCoBuyerAddressLine_2Desc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsStddevSampleCoBuyerCityAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CITY_ASC',
  DealsStddevSampleCoBuyerCityDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CITY_DESC',
  DealsStddevSampleCoBuyerStateAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_STATE_ASC',
  DealsStddevSampleCoBuyerStateDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_STATE_DESC',
  DealsStddevSampleCoBuyerZipAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ZIP_ASC',
  DealsStddevSampleCoBuyerZipDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ZIP_DESC',
  DealsStddevSampleCoBuyerCountyAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_COUNTY_ASC',
  DealsStddevSampleCoBuyerCountyDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_COUNTY_DESC',
  DealsStddevSampleCoBuyerHomePhoneAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_HOME_PHONE_ASC',
  DealsStddevSampleCoBuyerHomePhoneDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_HOME_PHONE_DESC',
  DealsStddevSampleCoBuyerCellPhoneAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CELL_PHONE_ASC',
  DealsStddevSampleCoBuyerCellPhoneDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CELL_PHONE_DESC',
  DealsStddevSampleCoBuyerWorkPhoneAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_WORK_PHONE_ASC',
  DealsStddevSampleCoBuyerWorkPhoneDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_WORK_PHONE_DESC',
  DealsStddevSampleCoBuyerWorkExtensionAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_WORK_EXTENSION_ASC',
  DealsStddevSampleCoBuyerWorkExtensionDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_WORK_EXTENSION_DESC',
  DealsStddevSampleCoBuyerEmail_1Asc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_1_ASC',
  DealsStddevSampleCoBuyerEmail_1Desc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_1_DESC',
  DealsStddevSampleCoBuyerEmail_2Asc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_2_ASC',
  DealsStddevSampleCoBuyerEmail_2Desc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_2_DESC',
  DealsStddevSampleCoBuyerEmail_3Asc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_3_ASC',
  DealsStddevSampleCoBuyerEmail_3Desc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_3_DESC',
  DealsStddevSampleCoBuyerBirthDateAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BIRTH_DATE_ASC',
  DealsStddevSampleCoBuyerBirthDateDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BIRTH_DATE_DESC',
  DealsStddevSampleCoBuyerIndividualBusinessFlagAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsStddevSampleCoBuyerIndividualBusinessFlagDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsStddevSampleCoBuyerOptOutAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_OPT_OUT_ASC',
  DealsStddevSampleCoBuyerOptOutDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_OPT_OUT_DESC',
  DealsStddevSampleCoBuyerBlockEmailAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsStddevSampleCoBuyerBlockEmailDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsStddevSampleCoBuyerBlockPhoneAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_PHONE_ASC',
  DealsStddevSampleCoBuyerBlockPhoneDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_PHONE_DESC',
  DealsStddevSampleCoBuyerBlockMailAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_MAIL_ASC',
  DealsStddevSampleCoBuyerBlockMailDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_MAIL_DESC',
  DealsStddevSampleVinAsc = 'DEALS_STDDEV_SAMPLE_VIN_ASC',
  DealsStddevSampleVinDesc = 'DEALS_STDDEV_SAMPLE_VIN_DESC',
  DealsStddevSampleYearAsc = 'DEALS_STDDEV_SAMPLE_YEAR_ASC',
  DealsStddevSampleYearDesc = 'DEALS_STDDEV_SAMPLE_YEAR_DESC',
  DealsStddevSampleMakeAsc = 'DEALS_STDDEV_SAMPLE_MAKE_ASC',
  DealsStddevSampleMakeDesc = 'DEALS_STDDEV_SAMPLE_MAKE_DESC',
  DealsStddevSampleModelAsc = 'DEALS_STDDEV_SAMPLE_MODEL_ASC',
  DealsStddevSampleModelDesc = 'DEALS_STDDEV_SAMPLE_MODEL_DESC',
  DealsStddevSampleModelNumberAsc = 'DEALS_STDDEV_SAMPLE_MODEL_NUMBER_ASC',
  DealsStddevSampleModelNumberDesc = 'DEALS_STDDEV_SAMPLE_MODEL_NUMBER_DESC',
  DealsStddevSampleMileageAsc = 'DEALS_STDDEV_SAMPLE_MILEAGE_ASC',
  DealsStddevSampleMileageDesc = 'DEALS_STDDEV_SAMPLE_MILEAGE_DESC',
  DealsStddevSampleDescriptionAsc = 'DEALS_STDDEV_SAMPLE_DESCRIPTION_ASC',
  DealsStddevSampleDescriptionDesc = 'DEALS_STDDEV_SAMPLE_DESCRIPTION_DESC',
  DealsStddevSampleExteriorColorAsc = 'DEALS_STDDEV_SAMPLE_EXTERIOR_COLOR_ASC',
  DealsStddevSampleExteriorColorDesc = 'DEALS_STDDEV_SAMPLE_EXTERIOR_COLOR_DESC',
  DealsStddevSampleNewUsedAsc = 'DEALS_STDDEV_SAMPLE_NEW_USED_ASC',
  DealsStddevSampleNewUsedDesc = 'DEALS_STDDEV_SAMPLE_NEW_USED_DESC',
  DealsStddevSampleStockNumberAsc = 'DEALS_STDDEV_SAMPLE_STOCK_NUMBER_ASC',
  DealsStddevSampleStockNumberDesc = 'DEALS_STDDEV_SAMPLE_STOCK_NUMBER_DESC',
  DealsStddevSampleTransmissionAsc = 'DEALS_STDDEV_SAMPLE_TRANSMISSION_ASC',
  DealsStddevSampleTransmissionDesc = 'DEALS_STDDEV_SAMPLE_TRANSMISSION_DESC',
  DealsStddevSampleEngineConfigurationAsc = 'DEALS_STDDEV_SAMPLE_ENGINE_CONFIGURATION_ASC',
  DealsStddevSampleEngineConfigurationDesc = 'DEALS_STDDEV_SAMPLE_ENGINE_CONFIGURATION_DESC',
  DealsStddevSampleTrimAsc = 'DEALS_STDDEV_SAMPLE_TRIM_ASC',
  DealsStddevSampleTrimDesc = 'DEALS_STDDEV_SAMPLE_TRIM_DESC',
  DealsStddevSampleEngineNumberAsc = 'DEALS_STDDEV_SAMPLE_ENGINE_NUMBER_ASC',
  DealsStddevSampleEngineNumberDesc = 'DEALS_STDDEV_SAMPLE_ENGINE_NUMBER_DESC',
  DealsStddevSampleChassisNumberAsc = 'DEALS_STDDEV_SAMPLE_CHASSIS_NUMBER_ASC',
  DealsStddevSampleChassisNumberDesc = 'DEALS_STDDEV_SAMPLE_CHASSIS_NUMBER_DESC',
  DealsStddevSampleLicensePlateNumberAsc = 'DEALS_STDDEV_SAMPLE_LICENSE_PLATE_NUMBER_ASC',
  DealsStddevSampleLicensePlateNumberDesc = 'DEALS_STDDEV_SAMPLE_LICENSE_PLATE_NUMBER_DESC',
  DealsStddevSampleDeliveryDateAsc = 'DEALS_STDDEV_SAMPLE_DELIVERY_DATE_ASC',
  DealsStddevSampleDeliveryDateDesc = 'DEALS_STDDEV_SAMPLE_DELIVERY_DATE_DESC',
  DealsStddevSampleDeliveryMileageAsc = 'DEALS_STDDEV_SAMPLE_DELIVERY_MILEAGE_ASC',
  DealsStddevSampleDeliveryMileageDesc = 'DEALS_STDDEV_SAMPLE_DELIVERY_MILEAGE_DESC',
  DealsStddevSampleInventoryDateAsc = 'DEALS_STDDEV_SAMPLE_INVENTORY_DATE_ASC',
  DealsStddevSampleInventoryDateDesc = 'DEALS_STDDEV_SAMPLE_INVENTORY_DATE_DESC',
  DealsStddevSampleInServiceDateAsc = 'DEALS_STDDEV_SAMPLE_IN_SERVICE_DATE_ASC',
  DealsStddevSampleInServiceDateDesc = 'DEALS_STDDEV_SAMPLE_IN_SERVICE_DATE_DESC',
  DealsStddevSampleVinExplosionYearAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_YEAR_ASC',
  DealsStddevSampleVinExplosionYearDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_YEAR_DESC',
  DealsStddevSampleVinExplosionMakeAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_MAKE_ASC',
  DealsStddevSampleVinExplosionMakeDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_MAKE_DESC',
  DealsStddevSampleVinExplosionModelAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_MODEL_ASC',
  DealsStddevSampleVinExplosionModelDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_MODEL_DESC',
  DealsStddevSampleVinExplosionTrimAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_TRIM_ASC',
  DealsStddevSampleVinExplosionTrimDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_TRIM_DESC',
  DealsStddevSampleVinExplosionTransmissionTypeAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsStddevSampleVinExplosionTransmissionTypeDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsStddevSampleVinExplosionFuelTypeAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsStddevSampleVinExplosionFuelTypeDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsStddevSampleVinExplosionEngineSizeAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsStddevSampleVinExplosionEngineSizeDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsStddevSampleVinExplosionGvwRangeAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsStddevSampleVinExplosionGvwRangeDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsStddevSampleTrade_1VinAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_VIN_ASC',
  DealsStddevSampleTrade_1VinDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_VIN_DESC',
  DealsStddevSampleTrade_1YearAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_YEAR_ASC',
  DealsStddevSampleTrade_1YearDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_YEAR_DESC',
  DealsStddevSampleTrade_1MakeAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_MAKE_ASC',
  DealsStddevSampleTrade_1MakeDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_MAKE_DESC',
  DealsStddevSampleTrade_1ModelAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_MODEL_ASC',
  DealsStddevSampleTrade_1ModelDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_MODEL_DESC',
  DealsStddevSampleTrade_1OdometerAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_ODOMETER_ASC',
  DealsStddevSampleTrade_1OdometerDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_ODOMETER_DESC',
  DealsStddevSampleTrade_1ActualCashValueAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsStddevSampleTrade_1ActualCashValueDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsStddevSampleTrade_1GrossAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_GROSS_ASC',
  DealsStddevSampleTrade_1GrossDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_GROSS_DESC',
  DealsStddevSampleTrade_1PayoffAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_PAYOFF_ASC',
  DealsStddevSampleTrade_1PayoffDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_PAYOFF_DESC',
  DealsStddevSampleTrade_2VinAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_VIN_ASC',
  DealsStddevSampleTrade_2VinDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_VIN_DESC',
  DealsStddevSampleTrade_2YearAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_YEAR_ASC',
  DealsStddevSampleTrade_2YearDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_YEAR_DESC',
  DealsStddevSampleTrade_2MakeAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_MAKE_ASC',
  DealsStddevSampleTrade_2MakeDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_MAKE_DESC',
  DealsStddevSampleTrade_2ModelAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_MODEL_ASC',
  DealsStddevSampleTrade_2ModelDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_MODEL_DESC',
  DealsStddevSampleTrade_2OdometerAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_ODOMETER_ASC',
  DealsStddevSampleTrade_2OdometerDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_ODOMETER_DESC',
  DealsStddevSampleTrade_2ActualCashValueAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsStddevSampleTrade_2ActualCashValueDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsStddevSampleTrade_2GrossAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_GROSS_ASC',
  DealsStddevSampleTrade_2GrossDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_GROSS_DESC',
  DealsStddevSampleTrade_2PayoffAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_PAYOFF_ASC',
  DealsStddevSampleTrade_2PayoffDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_PAYOFF_DESC',
  DealsStddevSampleSalesman_1NumberAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_NUMBER_ASC',
  DealsStddevSampleSalesman_1NumberDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_NUMBER_DESC',
  DealsStddevSampleSalesman_1NameAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_NAME_ASC',
  DealsStddevSampleSalesman_1NameDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_NAME_DESC',
  DealsStddevSampleSalesman_2NumberAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_NUMBER_ASC',
  DealsStddevSampleSalesman_2NumberDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_NUMBER_DESC',
  DealsStddevSampleSalesman_2NameAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_NAME_ASC',
  DealsStddevSampleSalesman_2NameDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_NAME_DESC',
  DealsStddevSampleSalesman_3NumberAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_NUMBER_ASC',
  DealsStddevSampleSalesman_3NumberDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_NUMBER_DESC',
  DealsStddevSampleSalesman_3NameAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_NAME_ASC',
  DealsStddevSampleSalesman_3NameDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_NAME_DESC',
  DealsStddevSampleClosingManagerNumberAsc = 'DEALS_STDDEV_SAMPLE_CLOSING_MANAGER_NUMBER_ASC',
  DealsStddevSampleClosingManagerNumberDesc = 'DEALS_STDDEV_SAMPLE_CLOSING_MANAGER_NUMBER_DESC',
  DealsStddevSampleClosingManagerNameAsc = 'DEALS_STDDEV_SAMPLE_CLOSING_MANAGER_NAME_ASC',
  DealsStddevSampleClosingManagerNameDesc = 'DEALS_STDDEV_SAMPLE_CLOSING_MANAGER_NAME_DESC',
  DealsStddevSampleFinanceManagerNumberAsc = 'DEALS_STDDEV_SAMPLE_FINANCE_MANAGER_NUMBER_ASC',
  DealsStddevSampleFinanceManagerNumberDesc = 'DEALS_STDDEV_SAMPLE_FINANCE_MANAGER_NUMBER_DESC',
  DealsStddevSampleFinanceManagerNameAsc = 'DEALS_STDDEV_SAMPLE_FINANCE_MANAGER_NAME_ASC',
  DealsStddevSampleFinanceManagerNameDesc = 'DEALS_STDDEV_SAMPLE_FINANCE_MANAGER_NAME_DESC',
  DealsStddevSampleSalesmanManagerNumberAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_MANAGER_NUMBER_ASC',
  DealsStddevSampleSalesmanManagerNumberDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_MANAGER_NUMBER_DESC',
  DealsStddevSampleSalesmanManagerNameAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_MANAGER_NAME_ASC',
  DealsStddevSampleSalesmanManagerNameDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_MANAGER_NAME_DESC',
  DealsStddevSampleMsrpAsc = 'DEALS_STDDEV_SAMPLE_MSRP_ASC',
  DealsStddevSampleMsrpDesc = 'DEALS_STDDEV_SAMPLE_MSRP_DESC',
  DealsStddevSampleListPriceAsc = 'DEALS_STDDEV_SAMPLE_LIST_PRICE_ASC',
  DealsStddevSampleListPriceDesc = 'DEALS_STDDEV_SAMPLE_LIST_PRICE_DESC',
  DealsStddevSampleSalesPriceAsc = 'DEALS_STDDEV_SAMPLE_SALES_PRICE_ASC',
  DealsStddevSampleSalesPriceDesc = 'DEALS_STDDEV_SAMPLE_SALES_PRICE_DESC',
  DealsStddevSampleJournalPriceAsc = 'DEALS_STDDEV_SAMPLE_JOURNAL_PRICE_ASC',
  DealsStddevSampleJournalPriceDesc = 'DEALS_STDDEV_SAMPLE_JOURNAL_PRICE_DESC',
  DealsStddevSampleCostAsc = 'DEALS_STDDEV_SAMPLE_COST_ASC',
  DealsStddevSampleCostDesc = 'DEALS_STDDEV_SAMPLE_COST_DESC',
  DealsStddevSampleJournalCostAsc = 'DEALS_STDDEV_SAMPLE_JOURNAL_COST_ASC',
  DealsStddevSampleJournalCostDesc = 'DEALS_STDDEV_SAMPLE_JOURNAL_COST_DESC',
  DealsStddevSampleAdjustmentsAsc = 'DEALS_STDDEV_SAMPLE_ADJUSTMENTS_ASC',
  DealsStddevSampleAdjustmentsDesc = 'DEALS_STDDEV_SAMPLE_ADJUSTMENTS_DESC',
  DealsStddevSampleAdjustedCostAsc = 'DEALS_STDDEV_SAMPLE_ADJUSTED_COST_ASC',
  DealsStddevSampleAdjustedCostDesc = 'DEALS_STDDEV_SAMPLE_ADJUSTED_COST_DESC',
  DealsStddevSampleIncentivesAsc = 'DEALS_STDDEV_SAMPLE_INCENTIVES_ASC',
  DealsStddevSampleIncentivesDesc = 'DEALS_STDDEV_SAMPLE_INCENTIVES_DESC',
  DealsStddevSamplePackAmountAsc = 'DEALS_STDDEV_SAMPLE_PACK_AMOUNT_ASC',
  DealsStddevSamplePackAmountDesc = 'DEALS_STDDEV_SAMPLE_PACK_AMOUNT_DESC',
  DealsStddevSampleSaleNetAsc = 'DEALS_STDDEV_SAMPLE_SALE_NET_ASC',
  DealsStddevSampleSaleNetDesc = 'DEALS_STDDEV_SAMPLE_SALE_NET_DESC',
  DealsStddevSampleTotalTradeActualCashValueAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsStddevSampleTotalTradeActualCashValueDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsStddevSampleTotalTradeGrossAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_TRADE_GROSS_ASC',
  DealsStddevSampleTotalTradeGrossDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_TRADE_GROSS_DESC',
  DealsStddevSampleWeOweFrontAsc = 'DEALS_STDDEV_SAMPLE_WE_OWE_FRONT_ASC',
  DealsStddevSampleWeOweFrontDesc = 'DEALS_STDDEV_SAMPLE_WE_OWE_FRONT_DESC',
  DealsStddevSampleTotalFrontFeesAftermarketProfitAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsStddevSampleTotalFrontFeesAftermarketProfitDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsStddevSampleTotalFrontCommissionAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_COMMISSION_ASC',
  DealsStddevSampleTotalFrontCommissionDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_COMMISSION_DESC',
  DealsStddevSampleTotalFrontSalesAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_SALES_ASC',
  DealsStddevSampleTotalFrontSalesDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_SALES_DESC',
  DealsStddevSampleTotalFrontCostAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_COST_ASC',
  DealsStddevSampleTotalFrontCostDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_COST_DESC',
  DealsStddevSampleFrontGrossAsc = 'DEALS_STDDEV_SAMPLE_FRONT_GROSS_ASC',
  DealsStddevSampleFrontGrossDesc = 'DEALS_STDDEV_SAMPLE_FRONT_GROSS_DESC',
  DealsStddevSampleFinanceProfitAsc = 'DEALS_STDDEV_SAMPLE_FINANCE_PROFIT_ASC',
  DealsStddevSampleFinanceProfitDesc = 'DEALS_STDDEV_SAMPLE_FINANCE_PROFIT_DESC',
  DealsStddevSampleTotalWarrantyProfitAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_WARRANTY_PROFIT_ASC',
  DealsStddevSampleTotalWarrantyProfitDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_WARRANTY_PROFIT_DESC',
  DealsStddevSampleWeOweBackAsc = 'DEALS_STDDEV_SAMPLE_WE_OWE_BACK_ASC',
  DealsStddevSampleWeOweBackDesc = 'DEALS_STDDEV_SAMPLE_WE_OWE_BACK_DESC',
  DealsStddevSampleInsuranceProfitAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_PROFIT_ASC',
  DealsStddevSampleInsuranceProfitDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_PROFIT_DESC',
  DealsStddevSampleTotalBackFeesAftermarketProfitAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsStddevSampleTotalBackFeesAftermarketProfitDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsStddevSampleFinanceReserveAsc = 'DEALS_STDDEV_SAMPLE_FINANCE_RESERVE_ASC',
  DealsStddevSampleFinanceReserveDesc = 'DEALS_STDDEV_SAMPLE_FINANCE_RESERVE_DESC',
  DealsStddevSampleTotalBackCommissionAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_COMMISSION_ASC',
  DealsStddevSampleTotalBackCommissionDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_COMMISSION_DESC',
  DealsStddevSampleTotalBackSalesAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_SALES_ASC',
  DealsStddevSampleTotalBackSalesDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_SALES_DESC',
  DealsStddevSampleTotalBackCostAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_COST_ASC',
  DealsStddevSampleTotalBackCostDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_COST_DESC',
  DealsStddevSampleBackGrossAsc = 'DEALS_STDDEV_SAMPLE_BACK_GROSS_ASC',
  DealsStddevSampleBackGrossDesc = 'DEALS_STDDEV_SAMPLE_BACK_GROSS_DESC',
  DealsStddevSampleTotalProfitAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_PROFIT_ASC',
  DealsStddevSampleTotalProfitDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_PROFIT_DESC',
  DealsStddevSampleGrossProfitAsc = 'DEALS_STDDEV_SAMPLE_GROSS_PROFIT_ASC',
  DealsStddevSampleGrossProfitDesc = 'DEALS_STDDEV_SAMPLE_GROSS_PROFIT_DESC',
  DealsStddevSampleGrossPayableAsc = 'DEALS_STDDEV_SAMPLE_GROSS_PAYABLE_ASC',
  DealsStddevSampleGrossPayableDesc = 'DEALS_STDDEV_SAMPLE_GROSS_PAYABLE_DESC',
  DealsStddevSampleDealStatusAsc = 'DEALS_STDDEV_SAMPLE_DEAL_STATUS_ASC',
  DealsStddevSampleDealStatusDesc = 'DEALS_STDDEV_SAMPLE_DEAL_STATUS_DESC',
  DealsStddevSampleEntryDateAsc = 'DEALS_STDDEV_SAMPLE_ENTRY_DATE_ASC',
  DealsStddevSampleEntryDateDesc = 'DEALS_STDDEV_SAMPLE_ENTRY_DATE_DESC',
  DealsStddevSampleBookedDateAsc = 'DEALS_STDDEV_SAMPLE_BOOKED_DATE_ASC',
  DealsStddevSampleBookedDateDesc = 'DEALS_STDDEV_SAMPLE_BOOKED_DATE_DESC',
  DealsStddevSampleFinalizedDateAsc = 'DEALS_STDDEV_SAMPLE_FINALIZED_DATE_ASC',
  DealsStddevSampleFinalizedDateDesc = 'DEALS_STDDEV_SAMPLE_FINALIZED_DATE_DESC',
  DealsStddevSampleContractDateAsc = 'DEALS_STDDEV_SAMPLE_CONTRACT_DATE_ASC',
  DealsStddevSampleContractDateDesc = 'DEALS_STDDEV_SAMPLE_CONTRACT_DATE_DESC',
  DealsStddevSampleAccountingDateAsc = 'DEALS_STDDEV_SAMPLE_ACCOUNTING_DATE_ASC',
  DealsStddevSampleAccountingDateDesc = 'DEALS_STDDEV_SAMPLE_ACCOUNTING_DATE_DESC',
  DealsStddevSampleStatusChangeDateAsc = 'DEALS_STDDEV_SAMPLE_STATUS_CHANGE_DATE_ASC',
  DealsStddevSampleStatusChangeDateDesc = 'DEALS_STDDEV_SAMPLE_STATUS_CHANGE_DATE_DESC',
  DealsStddevSampleFirstPayDateAsc = 'DEALS_STDDEV_SAMPLE_FIRST_PAY_DATE_ASC',
  DealsStddevSampleFirstPayDateDesc = 'DEALS_STDDEV_SAMPLE_FIRST_PAY_DATE_DESC',
  DealsStddevSampleDealTypeAsc = 'DEALS_STDDEV_SAMPLE_DEAL_TYPE_ASC',
  DealsStddevSampleDealTypeDesc = 'DEALS_STDDEV_SAMPLE_DEAL_TYPE_DESC',
  DealsStddevSampleSaleTypeAsc = 'DEALS_STDDEV_SAMPLE_SALE_TYPE_ASC',
  DealsStddevSampleSaleTypeDesc = 'DEALS_STDDEV_SAMPLE_SALE_TYPE_DESC',
  DealsStddevSampleBankIdAsc = 'DEALS_STDDEV_SAMPLE_BANK_ID_ASC',
  DealsStddevSampleBankIdDesc = 'DEALS_STDDEV_SAMPLE_BANK_ID_DESC',
  DealsStddevSampleBankNameAsc = 'DEALS_STDDEV_SAMPLE_BANK_NAME_ASC',
  DealsStddevSampleBankNameDesc = 'DEALS_STDDEV_SAMPLE_BANK_NAME_DESC',
  DealsStddevSampleBankAddressAsc = 'DEALS_STDDEV_SAMPLE_BANK_ADDRESS_ASC',
  DealsStddevSampleBankAddressDesc = 'DEALS_STDDEV_SAMPLE_BANK_ADDRESS_DESC',
  DealsStddevSampleTermAsc = 'DEALS_STDDEV_SAMPLE_TERM_ASC',
  DealsStddevSampleTermDesc = 'DEALS_STDDEV_SAMPLE_TERM_DESC',
  DealsStddevSampleAmountFinancedAsc = 'DEALS_STDDEV_SAMPLE_AMOUNT_FINANCED_ASC',
  DealsStddevSampleAmountFinancedDesc = 'DEALS_STDDEV_SAMPLE_AMOUNT_FINANCED_DESC',
  DealsStddevSampleAprAsc = 'DEALS_STDDEV_SAMPLE_APR_ASC',
  DealsStddevSampleAprDesc = 'DEALS_STDDEV_SAMPLE_APR_DESC',
  DealsStddevSampleMonthlyPaymentAsc = 'DEALS_STDDEV_SAMPLE_MONTHLY_PAYMENT_ASC',
  DealsStddevSampleMonthlyPaymentDesc = 'DEALS_STDDEV_SAMPLE_MONTHLY_PAYMENT_DESC',
  DealsStddevSamplePaymentTotalAsc = 'DEALS_STDDEV_SAMPLE_PAYMENT_TOTAL_ASC',
  DealsStddevSamplePaymentTotalDesc = 'DEALS_STDDEV_SAMPLE_PAYMENT_TOTAL_DESC',
  DealsStddevSampleRebatesAsc = 'DEALS_STDDEV_SAMPLE_REBATES_ASC',
  DealsStddevSampleRebatesDesc = 'DEALS_STDDEV_SAMPLE_REBATES_DESC',
  DealsStddevSampleDepositAsc = 'DEALS_STDDEV_SAMPLE_DEPOSIT_ASC',
  DealsStddevSampleDepositDesc = 'DEALS_STDDEV_SAMPLE_DEPOSIT_DESC',
  DealsStddevSampleDownPaymentAsc = 'DEALS_STDDEV_SAMPLE_DOWN_PAYMENT_ASC',
  DealsStddevSampleDownPaymentDesc = 'DEALS_STDDEV_SAMPLE_DOWN_PAYMENT_DESC',
  DealsStddevSampleTotalNetTradesAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_NET_TRADES_ASC',
  DealsStddevSampleTotalNetTradesDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_NET_TRADES_DESC',
  DealsStddevSampleTotalDownAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_DOWN_ASC',
  DealsStddevSampleTotalDownDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_DOWN_DESC',
  DealsStddevSampleBalloonAmountAsc = 'DEALS_STDDEV_SAMPLE_BALLOON_AMOUNT_ASC',
  DealsStddevSampleBalloonAmountDesc = 'DEALS_STDDEV_SAMPLE_BALLOON_AMOUNT_DESC',
  DealsStddevSampleAdjustedBalloonAmountAsc = 'DEALS_STDDEV_SAMPLE_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsStddevSampleAdjustedBalloonAmountDesc = 'DEALS_STDDEV_SAMPLE_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsStddevSampleHoldbackAmountAsc = 'DEALS_STDDEV_SAMPLE_HOLDBACK_AMOUNT_ASC',
  DealsStddevSampleHoldbackAmountDesc = 'DEALS_STDDEV_SAMPLE_HOLDBACK_AMOUNT_DESC',
  DealsStddevSampleTotalDriveOffAmountAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsStddevSampleTotalDriveOffAmountDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsStddevSampleLicenseFeeAsc = 'DEALS_STDDEV_SAMPLE_LICENSE_FEE_ASC',
  DealsStddevSampleLicenseFeeDesc = 'DEALS_STDDEV_SAMPLE_LICENSE_FEE_DESC',
  DealsStddevSampleRegistrationFeeAsc = 'DEALS_STDDEV_SAMPLE_REGISTRATION_FEE_ASC',
  DealsStddevSampleRegistrationFeeDesc = 'DEALS_STDDEV_SAMPLE_REGISTRATION_FEE_DESC',
  DealsStddevSampleDocumentationFeeAsc = 'DEALS_STDDEV_SAMPLE_DOCUMENTATION_FEE_ASC',
  DealsStddevSampleDocumentationFeeDesc = 'DEALS_STDDEV_SAMPLE_DOCUMENTATION_FEE_DESC',
  DealsStddevSampleFinanceChargeAsc = 'DEALS_STDDEV_SAMPLE_FINANCE_CHARGE_ASC',
  DealsStddevSampleFinanceChargeDesc = 'DEALS_STDDEV_SAMPLE_FINANCE_CHARGE_DESC',
  DealsStddevSampleTotalPickupPaymentsAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsStddevSampleTotalPickupPaymentsDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsStddevSampleSellRateAsc = 'DEALS_STDDEV_SAMPLE_SELL_RATE_ASC',
  DealsStddevSampleSellRateDesc = 'DEALS_STDDEV_SAMPLE_SELL_RATE_DESC',
  DealsStddevSampleBuyRateAsc = 'DEALS_STDDEV_SAMPLE_BUY_RATE_ASC',
  DealsStddevSampleBuyRateDesc = 'DEALS_STDDEV_SAMPLE_BUY_RATE_DESC',
  DealsStddevSampleResidualRateAsc = 'DEALS_STDDEV_SAMPLE_RESIDUAL_RATE_ASC',
  DealsStddevSampleResidualRateDesc = 'DEALS_STDDEV_SAMPLE_RESIDUAL_RATE_DESC',
  DealsStddevSampleResidualAmountAsc = 'DEALS_STDDEV_SAMPLE_RESIDUAL_AMOUNT_ASC',
  DealsStddevSampleResidualAmountDesc = 'DEALS_STDDEV_SAMPLE_RESIDUAL_AMOUNT_DESC',
  DealsStddevSampleAllowedMilesAsc = 'DEALS_STDDEV_SAMPLE_ALLOWED_MILES_ASC',
  DealsStddevSampleAllowedMilesDesc = 'DEALS_STDDEV_SAMPLE_ALLOWED_MILES_DESC',
  DealsStddevSampleEstimatedMilesAsc = 'DEALS_STDDEV_SAMPLE_ESTIMATED_MILES_ASC',
  DealsStddevSampleEstimatedMilesDesc = 'DEALS_STDDEV_SAMPLE_ESTIMATED_MILES_DESC',
  DealsStddevSampleMileageRateAsc = 'DEALS_STDDEV_SAMPLE_MILEAGE_RATE_ASC',
  DealsStddevSampleMileageRateDesc = 'DEALS_STDDEV_SAMPLE_MILEAGE_RATE_DESC',
  DealsStddevSampleAcquisitionFeeAsc = 'DEALS_STDDEV_SAMPLE_ACQUISITION_FEE_ASC',
  DealsStddevSampleAcquisitionFeeDesc = 'DEALS_STDDEV_SAMPLE_ACQUISITION_FEE_DESC',
  DealsStddevSampleBasePaymentAsc = 'DEALS_STDDEV_SAMPLE_BASE_PAYMENT_ASC',
  DealsStddevSampleBasePaymentDesc = 'DEALS_STDDEV_SAMPLE_BASE_PAYMENT_DESC',
  DealsStddevSampleSecurityDepositAsc = 'DEALS_STDDEV_SAMPLE_SECURITY_DEPOSIT_ASC',
  DealsStddevSampleSecurityDepositDesc = 'DEALS_STDDEV_SAMPLE_SECURITY_DEPOSIT_DESC',
  DealsStddevSampleTotalCapitalReductionAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsStddevSampleTotalCapitalReductionDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsStddevSampleNetCapitalCostAsc = 'DEALS_STDDEV_SAMPLE_NET_CAPITAL_COST_ASC',
  DealsStddevSampleNetCapitalCostDesc = 'DEALS_STDDEV_SAMPLE_NET_CAPITAL_COST_DESC',
  DealsStddevSampleLeaseDepreciationValueAsc = 'DEALS_STDDEV_SAMPLE_LEASE_DEPRECIATION_VALUE_ASC',
  DealsStddevSampleLeaseDepreciationValueDesc = 'DEALS_STDDEV_SAMPLE_LEASE_DEPRECIATION_VALUE_DESC',
  DealsStddevSampleDealerFeesAsc = 'DEALS_STDDEV_SAMPLE_DEALER_FEES_ASC',
  DealsStddevSampleDealerFeesDesc = 'DEALS_STDDEV_SAMPLE_DEALER_FEES_DESC',
  DealsStddevSampleGovernmentFeesAsc = 'DEALS_STDDEV_SAMPLE_GOVERNMENT_FEES_ASC',
  DealsStddevSampleGovernmentFeesDesc = 'DEALS_STDDEV_SAMPLE_GOVERNMENT_FEES_DESC',
  DealsStddevSampleTotalTaxAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_TAX_ASC',
  DealsStddevSampleTotalTaxDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_TAX_DESC',
  DealsStddevSampleRegistrationStateAsc = 'DEALS_STDDEV_SAMPLE_REGISTRATION_STATE_ASC',
  DealsStddevSampleRegistrationStateDesc = 'DEALS_STDDEV_SAMPLE_REGISTRATION_STATE_DESC',
  DealsStddevSampleReportOfSaleNumberAsc = 'DEALS_STDDEV_SAMPLE_REPORT_OF_SALE_NUMBER_ASC',
  DealsStddevSampleReportOfSaleNumberDesc = 'DEALS_STDDEV_SAMPLE_REPORT_OF_SALE_NUMBER_DESC',
  DealsStddevSampleSalesman_1TotalCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsStddevSampleSalesman_1TotalCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsStddevSampleSalesman_1FrontCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsStddevSampleSalesman_1FrontCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsStddevSampleSalesman_1BackCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsStddevSampleSalesman_1BackCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsStddevSampleSalesman_2TotalCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsStddevSampleSalesman_2TotalCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsStddevSampleSalesman_2FrontCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsStddevSampleSalesman_2FrontCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsStddevSampleSalesman_2BackCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsStddevSampleSalesman_2BackCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsStddevSampleSalesman_3TotalCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsStddevSampleSalesman_3TotalCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsStddevSampleSalesman_3FrontCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsStddevSampleSalesman_3FrontCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsStddevSampleSalesman_3BackCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsStddevSampleSalesman_3BackCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsStddevSampleWarranty_1NameAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_NAME_ASC',
  DealsStddevSampleWarranty_1NameDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_NAME_DESC',
  DealsStddevSampleWarranty_1SaleAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_SALE_ASC',
  DealsStddevSampleWarranty_1SaleDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_SALE_DESC',
  DealsStddevSampleWarranty_1CostAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_COST_ASC',
  DealsStddevSampleWarranty_1CostDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_COST_DESC',
  DealsStddevSampleWarranty_1MilesAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_MILES_ASC',
  DealsStddevSampleWarranty_1MilesDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_MILES_DESC',
  DealsStddevSampleWarranty_1TermAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_TERM_ASC',
  DealsStddevSampleWarranty_1TermDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_TERM_DESC',
  DealsStddevSampleWarranty_2NameAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_NAME_ASC',
  DealsStddevSampleWarranty_2NameDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_NAME_DESC',
  DealsStddevSampleWarranty_2SaleAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_SALE_ASC',
  DealsStddevSampleWarranty_2SaleDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_SALE_DESC',
  DealsStddevSampleWarranty_2CostAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_COST_ASC',
  DealsStddevSampleWarranty_2CostDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_COST_DESC',
  DealsStddevSampleWarranty_2MilesAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_MILES_ASC',
  DealsStddevSampleWarranty_2MilesDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_MILES_DESC',
  DealsStddevSampleWarranty_2TermAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_TERM_ASC',
  DealsStddevSampleWarranty_2TermDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_TERM_DESC',
  DealsStddevSampleWarranty_3NameAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_NAME_ASC',
  DealsStddevSampleWarranty_3NameDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_NAME_DESC',
  DealsStddevSampleWarranty_3SaleAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_SALE_ASC',
  DealsStddevSampleWarranty_3SaleDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_SALE_DESC',
  DealsStddevSampleWarranty_3CostAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_COST_ASC',
  DealsStddevSampleWarranty_3CostDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_COST_DESC',
  DealsStddevSampleWarranty_3MilesAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_MILES_ASC',
  DealsStddevSampleWarranty_3MilesDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_MILES_DESC',
  DealsStddevSampleWarranty_3TermAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_TERM_ASC',
  DealsStddevSampleWarranty_3TermDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_TERM_DESC',
  DealsStddevSampleWarranty_4NameAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_NAME_ASC',
  DealsStddevSampleWarranty_4NameDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_NAME_DESC',
  DealsStddevSampleWarranty_4SaleAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_SALE_ASC',
  DealsStddevSampleWarranty_4SaleDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_SALE_DESC',
  DealsStddevSampleWarranty_4CostAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_COST_ASC',
  DealsStddevSampleWarranty_4CostDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_COST_DESC',
  DealsStddevSampleWarranty_4MilesAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_MILES_ASC',
  DealsStddevSampleWarranty_4MilesDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_MILES_DESC',
  DealsStddevSampleWarranty_4TermAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_TERM_ASC',
  DealsStddevSampleWarranty_4TermDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_TERM_DESC',
  DealsStddevSampleWarranty_5NameAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_NAME_ASC',
  DealsStddevSampleWarranty_5NameDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_NAME_DESC',
  DealsStddevSampleWarranty_5SaleAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_SALE_ASC',
  DealsStddevSampleWarranty_5SaleDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_SALE_DESC',
  DealsStddevSampleWarranty_5CostAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_COST_ASC',
  DealsStddevSampleWarranty_5CostDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_COST_DESC',
  DealsStddevSampleWarranty_5MilesAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_MILES_ASC',
  DealsStddevSampleWarranty_5MilesDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_MILES_DESC',
  DealsStddevSampleWarranty_5TermAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_TERM_ASC',
  DealsStddevSampleWarranty_5TermDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_TERM_DESC',
  DealsStddevSampleTotalFeeAftermarketSaleAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsStddevSampleTotalFeeAftermarketSaleDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsStddevSampleTotalFeeAftermarketCostAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsStddevSampleTotalFeeAftermarketCostDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsStddevSampleFeeAftermarket_1NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_NAME_ASC',
  DealsStddevSampleFeeAftermarket_1NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_NAME_DESC',
  DealsStddevSampleFeeAftermarket_1SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_SALE_ASC',
  DealsStddevSampleFeeAftermarket_1SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_SALE_DESC',
  DealsStddevSampleFeeAftermarket_1CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_COST_ASC',
  DealsStddevSampleFeeAftermarket_1CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_COST_DESC',
  DealsStddevSampleFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_2NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_NAME_ASC',
  DealsStddevSampleFeeAftermarket_2NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_NAME_DESC',
  DealsStddevSampleFeeAftermarket_2SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_SALE_ASC',
  DealsStddevSampleFeeAftermarket_2SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_SALE_DESC',
  DealsStddevSampleFeeAftermarket_2CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_COST_ASC',
  DealsStddevSampleFeeAftermarket_2CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_COST_DESC',
  DealsStddevSampleFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_3NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_NAME_ASC',
  DealsStddevSampleFeeAftermarket_3NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_NAME_DESC',
  DealsStddevSampleFeeAftermarket_3SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_SALE_ASC',
  DealsStddevSampleFeeAftermarket_3SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_SALE_DESC',
  DealsStddevSampleFeeAftermarket_3CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_COST_ASC',
  DealsStddevSampleFeeAftermarket_3CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_COST_DESC',
  DealsStddevSampleFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_4NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_NAME_ASC',
  DealsStddevSampleFeeAftermarket_4NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_NAME_DESC',
  DealsStddevSampleFeeAftermarket_4SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_SALE_ASC',
  DealsStddevSampleFeeAftermarket_4SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_SALE_DESC',
  DealsStddevSampleFeeAftermarket_4CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_COST_ASC',
  DealsStddevSampleFeeAftermarket_4CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_COST_DESC',
  DealsStddevSampleFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_5NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_NAME_ASC',
  DealsStddevSampleFeeAftermarket_5NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_NAME_DESC',
  DealsStddevSampleFeeAftermarket_5SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_SALE_ASC',
  DealsStddevSampleFeeAftermarket_5SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_SALE_DESC',
  DealsStddevSampleFeeAftermarket_5CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_COST_ASC',
  DealsStddevSampleFeeAftermarket_5CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_COST_DESC',
  DealsStddevSampleFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_6NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_NAME_ASC',
  DealsStddevSampleFeeAftermarket_6NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_NAME_DESC',
  DealsStddevSampleFeeAftermarket_6SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_SALE_ASC',
  DealsStddevSampleFeeAftermarket_6SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_SALE_DESC',
  DealsStddevSampleFeeAftermarket_6CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_COST_ASC',
  DealsStddevSampleFeeAftermarket_6CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_COST_DESC',
  DealsStddevSampleFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_7NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_NAME_ASC',
  DealsStddevSampleFeeAftermarket_7NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_NAME_DESC',
  DealsStddevSampleFeeAftermarket_7SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_SALE_ASC',
  DealsStddevSampleFeeAftermarket_7SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_SALE_DESC',
  DealsStddevSampleFeeAftermarket_7CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_COST_ASC',
  DealsStddevSampleFeeAftermarket_7CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_COST_DESC',
  DealsStddevSampleFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_8NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_NAME_ASC',
  DealsStddevSampleFeeAftermarket_8NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_NAME_DESC',
  DealsStddevSampleFeeAftermarket_8SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_SALE_ASC',
  DealsStddevSampleFeeAftermarket_8SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_SALE_DESC',
  DealsStddevSampleFeeAftermarket_8CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_COST_ASC',
  DealsStddevSampleFeeAftermarket_8CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_COST_DESC',
  DealsStddevSampleFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_9NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_NAME_ASC',
  DealsStddevSampleFeeAftermarket_9NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_NAME_DESC',
  DealsStddevSampleFeeAftermarket_9SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_SALE_ASC',
  DealsStddevSampleFeeAftermarket_9SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_SALE_DESC',
  DealsStddevSampleFeeAftermarket_9CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_COST_ASC',
  DealsStddevSampleFeeAftermarket_9CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_COST_DESC',
  DealsStddevSampleFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_10NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_NAME_ASC',
  DealsStddevSampleFeeAftermarket_10NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_NAME_DESC',
  DealsStddevSampleFeeAftermarket_10SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_SALE_ASC',
  DealsStddevSampleFeeAftermarket_10SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_SALE_DESC',
  DealsStddevSampleFeeAftermarket_10CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_COST_ASC',
  DealsStddevSampleFeeAftermarket_10CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_COST_DESC',
  DealsStddevSampleFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_11NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_NAME_ASC',
  DealsStddevSampleFeeAftermarket_11NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_NAME_DESC',
  DealsStddevSampleFeeAftermarket_11SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_SALE_ASC',
  DealsStddevSampleFeeAftermarket_11SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_SALE_DESC',
  DealsStddevSampleFeeAftermarket_11CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_COST_ASC',
  DealsStddevSampleFeeAftermarket_11CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_COST_DESC',
  DealsStddevSampleFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_12NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_NAME_ASC',
  DealsStddevSampleFeeAftermarket_12NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_NAME_DESC',
  DealsStddevSampleFeeAftermarket_12SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_SALE_ASC',
  DealsStddevSampleFeeAftermarket_12SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_SALE_DESC',
  DealsStddevSampleFeeAftermarket_12CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_COST_ASC',
  DealsStddevSampleFeeAftermarket_12CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_COST_DESC',
  DealsStddevSampleFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_13NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_NAME_ASC',
  DealsStddevSampleFeeAftermarket_13NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_NAME_DESC',
  DealsStddevSampleFeeAftermarket_13SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_SALE_ASC',
  DealsStddevSampleFeeAftermarket_13SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_SALE_DESC',
  DealsStddevSampleFeeAftermarket_13CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_COST_ASC',
  DealsStddevSampleFeeAftermarket_13CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_COST_DESC',
  DealsStddevSampleFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_14NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_NAME_ASC',
  DealsStddevSampleFeeAftermarket_14NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_NAME_DESC',
  DealsStddevSampleFeeAftermarket_14SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_SALE_ASC',
  DealsStddevSampleFeeAftermarket_14SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_SALE_DESC',
  DealsStddevSampleFeeAftermarket_14CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_COST_ASC',
  DealsStddevSampleFeeAftermarket_14CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_COST_DESC',
  DealsStddevSampleFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_15NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_NAME_ASC',
  DealsStddevSampleFeeAftermarket_15NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_NAME_DESC',
  DealsStddevSampleFeeAftermarket_15SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_SALE_ASC',
  DealsStddevSampleFeeAftermarket_15SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_SALE_DESC',
  DealsStddevSampleFeeAftermarket_15CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_COST_ASC',
  DealsStddevSampleFeeAftermarket_15CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_COST_DESC',
  DealsStddevSampleFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_16NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_NAME_ASC',
  DealsStddevSampleFeeAftermarket_16NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_NAME_DESC',
  DealsStddevSampleFeeAftermarket_16SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_SALE_ASC',
  DealsStddevSampleFeeAftermarket_16SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_SALE_DESC',
  DealsStddevSampleFeeAftermarket_16CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_COST_ASC',
  DealsStddevSampleFeeAftermarket_16CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_COST_DESC',
  DealsStddevSampleFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_17NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_NAME_ASC',
  DealsStddevSampleFeeAftermarket_17NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_NAME_DESC',
  DealsStddevSampleFeeAftermarket_17SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_SALE_ASC',
  DealsStddevSampleFeeAftermarket_17SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_SALE_DESC',
  DealsStddevSampleFeeAftermarket_17CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_COST_ASC',
  DealsStddevSampleFeeAftermarket_17CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_COST_DESC',
  DealsStddevSampleFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_18NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_NAME_ASC',
  DealsStddevSampleFeeAftermarket_18NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_NAME_DESC',
  DealsStddevSampleFeeAftermarket_18SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_SALE_ASC',
  DealsStddevSampleFeeAftermarket_18SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_SALE_DESC',
  DealsStddevSampleFeeAftermarket_18CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_COST_ASC',
  DealsStddevSampleFeeAftermarket_18CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_COST_DESC',
  DealsStddevSampleFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_19NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_NAME_ASC',
  DealsStddevSampleFeeAftermarket_19NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_NAME_DESC',
  DealsStddevSampleFeeAftermarket_19SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_SALE_ASC',
  DealsStddevSampleFeeAftermarket_19SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_SALE_DESC',
  DealsStddevSampleFeeAftermarket_19CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_COST_ASC',
  DealsStddevSampleFeeAftermarket_19CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_COST_DESC',
  DealsStddevSampleFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_20NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_NAME_ASC',
  DealsStddevSampleFeeAftermarket_20NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_NAME_DESC',
  DealsStddevSampleFeeAftermarket_20SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_SALE_ASC',
  DealsStddevSampleFeeAftermarket_20SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_SALE_DESC',
  DealsStddevSampleFeeAftermarket_20CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_COST_ASC',
  DealsStddevSampleFeeAftermarket_20CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_COST_DESC',
  DealsStddevSampleFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsStddevSampleInsurance_1TypeAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_TYPE_ASC',
  DealsStddevSampleInsurance_1TypeDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_TYPE_DESC',
  DealsStddevSampleInsurance_1NameAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_NAME_ASC',
  DealsStddevSampleInsurance_1NameDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_NAME_DESC',
  DealsStddevSampleInsurance_1SaleAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_SALE_ASC',
  DealsStddevSampleInsurance_1SaleDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_SALE_DESC',
  DealsStddevSampleInsurance_1CostAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_COST_ASC',
  DealsStddevSampleInsurance_1CostDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_COST_DESC',
  DealsStddevSampleInsurance_1TermAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_TERM_ASC',
  DealsStddevSampleInsurance_1TermDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_TERM_DESC',
  DealsStddevSampleInsurance_2TypeAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_TYPE_ASC',
  DealsStddevSampleInsurance_2TypeDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_TYPE_DESC',
  DealsStddevSampleInsurance_2NameAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_NAME_ASC',
  DealsStddevSampleInsurance_2NameDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_NAME_DESC',
  DealsStddevSampleInsurance_2SaleAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_SALE_ASC',
  DealsStddevSampleInsurance_2SaleDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_SALE_DESC',
  DealsStddevSampleInsurance_2CostAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_COST_ASC',
  DealsStddevSampleInsurance_2CostDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_COST_DESC',
  DealsStddevSampleInsurance_2TermAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_TERM_ASC',
  DealsStddevSampleInsurance_2TermDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_TERM_DESC',
  DealsStddevSampleInsurance_3TypeAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_TYPE_ASC',
  DealsStddevSampleInsurance_3TypeDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_TYPE_DESC',
  DealsStddevSampleInsurance_3NameAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_NAME_ASC',
  DealsStddevSampleInsurance_3NameDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_NAME_DESC',
  DealsStddevSampleInsurance_3SaleAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_SALE_ASC',
  DealsStddevSampleInsurance_3SaleDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_SALE_DESC',
  DealsStddevSampleInsurance_3CostAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_COST_ASC',
  DealsStddevSampleInsurance_3CostDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_COST_DESC',
  DealsStddevSampleInsurance_3TermAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_TERM_ASC',
  DealsStddevSampleInsurance_3TermDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_TERM_DESC',
  DealsStddevSampleAccidentalHealthTypeAsc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsStddevSampleAccidentalHealthTypeDesc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsStddevSampleAccidentalHealthNameAsc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsStddevSampleAccidentalHealthNameDesc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsStddevSampleAccidentalHealthSaleAsc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsStddevSampleAccidentalHealthSaleDesc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsStddevSampleAccidentalHealthCostAsc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_COST_ASC',
  DealsStddevSampleAccidentalHealthCostDesc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_COST_DESC',
  DealsStddevSampleAccidentalHealthTermAsc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsStddevSampleAccidentalHealthTermDesc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsStddevSampleCreditLifeTypeAsc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_TYPE_ASC',
  DealsStddevSampleCreditLifeTypeDesc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_TYPE_DESC',
  DealsStddevSampleCreditLifeNameAsc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_NAME_ASC',
  DealsStddevSampleCreditLifeNameDesc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_NAME_DESC',
  DealsStddevSampleCreditLifeSaleAsc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_SALE_ASC',
  DealsStddevSampleCreditLifeSaleDesc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_SALE_DESC',
  DealsStddevSampleCreditLifeCostAsc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_COST_ASC',
  DealsStddevSampleCreditLifeCostDesc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_COST_DESC',
  DealsStddevSampleCreditLifeTermAsc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_TERM_ASC',
  DealsStddevSampleCreditLifeTermDesc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_TERM_DESC',
  DealsStddevSampleLevelizedLifeTypeAsc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_TYPE_ASC',
  DealsStddevSampleLevelizedLifeTypeDesc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_TYPE_DESC',
  DealsStddevSampleLevelizedLifeNameAsc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_NAME_ASC',
  DealsStddevSampleLevelizedLifeNameDesc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_NAME_DESC',
  DealsStddevSampleLevelizedLifeSaleAsc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_SALE_ASC',
  DealsStddevSampleLevelizedLifeSaleDesc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_SALE_DESC',
  DealsStddevSampleLevelizedLifeCostAsc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_COST_ASC',
  DealsStddevSampleLevelizedLifeCostDesc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_COST_DESC',
  DealsStddevSampleLevelizedLifeTermAsc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_TERM_ASC',
  DealsStddevSampleLevelizedLifeTermDesc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_TERM_DESC',
  DealsStddevSampleLossOfEmploymentTypeAsc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsStddevSampleLossOfEmploymentTypeDesc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsStddevSampleLossOfEmploymentNameAsc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsStddevSampleLossOfEmploymentNameDesc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsStddevSampleLossOfEmploymentSaleAsc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsStddevSampleLossOfEmploymentSaleDesc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsStddevSampleLossOfEmploymentCostAsc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsStddevSampleLossOfEmploymentCostDesc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsStddevSampleLossOfEmploymentTermAsc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsStddevSampleLossOfEmploymentTermDesc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsStddevSampleGuaranteedAutoProtectionTypeAsc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsStddevSampleGuaranteedAutoProtectionTypeDesc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsStddevSampleGuaranteedAutoProtectionNameAsc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsStddevSampleGuaranteedAutoProtectionNameDesc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsStddevSampleGuaranteedAutoProtectionSaleAsc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsStddevSampleGuaranteedAutoProtectionSaleDesc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsStddevSampleGuaranteedAutoProtectionCostAsc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsStddevSampleGuaranteedAutoProtectionCostDesc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsStddevSampleGuaranteedAutoProtectionTermAsc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsStddevSampleGuaranteedAutoProtectionTermDesc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsStddevSampleSaleCommentsAsc = 'DEALS_STDDEV_SAMPLE_SALE_COMMENTS_ASC',
  DealsStddevSampleSaleCommentsDesc = 'DEALS_STDDEV_SAMPLE_SALE_COMMENTS_DESC',
  DealsStddevSampleCassStdLine1Asc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LINE1_ASC',
  DealsStddevSampleCassStdLine1Desc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LINE1_DESC',
  DealsStddevSampleCassStdLine2Asc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LINE2_ASC',
  DealsStddevSampleCassStdLine2Desc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LINE2_DESC',
  DealsStddevSampleCassStdCityAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CITY_ASC',
  DealsStddevSampleCassStdCityDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CITY_DESC',
  DealsStddevSampleCassStdStateAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_STATE_ASC',
  DealsStddevSampleCassStdStateDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_STATE_DESC',
  DealsStddevSampleCassStdZipAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIP_ASC',
  DealsStddevSampleCassStdZipDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIP_DESC',
  DealsStddevSampleCassStdZip4Asc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIP4_ASC',
  DealsStddevSampleCassStdZip4Desc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIP4_DESC',
  DealsStddevSampleCassStdDpbcAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_DPBC_ASC',
  DealsStddevSampleCassStdDpbcDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_DPBC_DESC',
  DealsStddevSampleCassStdChkdgtAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CHKDGT_ASC',
  DealsStddevSampleCassStdChkdgtDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CHKDGT_DESC',
  DealsStddevSampleCassStdCartAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CART_ASC',
  DealsStddevSampleCassStdCartDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CART_DESC',
  DealsStddevSampleCassStdLotAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LOT_ASC',
  DealsStddevSampleCassStdLotDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LOT_DESC',
  DealsStddevSampleCassStdLotordAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LOTORD_ASC',
  DealsStddevSampleCassStdLotordDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LOTORD_DESC',
  DealsStddevSampleCassStdUrbAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_URB_ASC',
  DealsStddevSampleCassStdUrbDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_URB_DESC',
  DealsStddevSampleCassStdFipsAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_FIPS_ASC',
  DealsStddevSampleCassStdFipsDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_FIPS_DESC',
  DealsStddevSampleCassStdEwsAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_EWS_ASC',
  DealsStddevSampleCassStdEwsDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_EWS_DESC',
  DealsStddevSampleCassStdLacsAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LACS_ASC',
  DealsStddevSampleCassStdLacsDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LACS_DESC',
  DealsStddevSampleCassStdZipmovAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIPMOV_ASC',
  DealsStddevSampleCassStdZipmovDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIPMOV_DESC',
  DealsStddevSampleCassStdZ4LomAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_Z4LOM_ASC',
  DealsStddevSampleCassStdZ4LomDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_Z4LOM_DESC',
  DealsStddevSampleCassStdNdiaptAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_NDIAPT_ASC',
  DealsStddevSampleCassStdNdiaptDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_NDIAPT_DESC',
  DealsStddevSampleCassStdNdirrAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_NDIRR_ASC',
  DealsStddevSampleCassStdNdirrDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_NDIRR_DESC',
  DealsStddevSampleCassStdLacsrtAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LACSRT_ASC',
  DealsStddevSampleCassStdLacsrtDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LACSRT_DESC',
  DealsStddevSampleCassStdErrorCdAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ERROR_CD_ASC',
  DealsStddevSampleCassStdErrorCdDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ERROR_CD_DESC',
  DealsStddevPopulationIdAsc = 'DEALS_STDDEV_POPULATION_ID_ASC',
  DealsStddevPopulationIdDesc = 'DEALS_STDDEV_POPULATION_ID_DESC',
  DealsStddevPopulationLeadIdAsc = 'DEALS_STDDEV_POPULATION_LEAD_ID_ASC',
  DealsStddevPopulationLeadIdDesc = 'DEALS_STDDEV_POPULATION_LEAD_ID_DESC',
  DealsStddevPopulationCompanyIntegrationIdAsc = 'DEALS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  DealsStddevPopulationCompanyIntegrationIdDesc = 'DEALS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  DealsStddevPopulationFileTypeAsc = 'DEALS_STDDEV_POPULATION_FILE_TYPE_ASC',
  DealsStddevPopulationFileTypeDesc = 'DEALS_STDDEV_POPULATION_FILE_TYPE_DESC',
  DealsStddevPopulationDvDealerIdAsc = 'DEALS_STDDEV_POPULATION_DV_DEALER_ID_ASC',
  DealsStddevPopulationDvDealerIdDesc = 'DEALS_STDDEV_POPULATION_DV_DEALER_ID_DESC',
  DealsStddevPopulationVendorDealerIdAsc = 'DEALS_STDDEV_POPULATION_VENDOR_DEALER_ID_ASC',
  DealsStddevPopulationVendorDealerIdDesc = 'DEALS_STDDEV_POPULATION_VENDOR_DEALER_ID_DESC',
  DealsStddevPopulationDmsTypeAsc = 'DEALS_STDDEV_POPULATION_DMS_TYPE_ASC',
  DealsStddevPopulationDmsTypeDesc = 'DEALS_STDDEV_POPULATION_DMS_TYPE_DESC',
  DealsStddevPopulationDealNumberAsc = 'DEALS_STDDEV_POPULATION_DEAL_NUMBER_ASC',
  DealsStddevPopulationDealNumberDesc = 'DEALS_STDDEV_POPULATION_DEAL_NUMBER_DESC',
  DealsStddevPopulationCustomerNumberAsc = 'DEALS_STDDEV_POPULATION_CUSTOMER_NUMBER_ASC',
  DealsStddevPopulationCustomerNumberDesc = 'DEALS_STDDEV_POPULATION_CUSTOMER_NUMBER_DESC',
  DealsStddevPopulationFullNameAsc = 'DEALS_STDDEV_POPULATION_FULL_NAME_ASC',
  DealsStddevPopulationFullNameDesc = 'DEALS_STDDEV_POPULATION_FULL_NAME_DESC',
  DealsStddevPopulationSalutationAsc = 'DEALS_STDDEV_POPULATION_SALUTATION_ASC',
  DealsStddevPopulationSalutationDesc = 'DEALS_STDDEV_POPULATION_SALUTATION_DESC',
  DealsStddevPopulationFirstNameAsc = 'DEALS_STDDEV_POPULATION_FIRST_NAME_ASC',
  DealsStddevPopulationFirstNameDesc = 'DEALS_STDDEV_POPULATION_FIRST_NAME_DESC',
  DealsStddevPopulationMiddleNameAsc = 'DEALS_STDDEV_POPULATION_MIDDLE_NAME_ASC',
  DealsStddevPopulationMiddleNameDesc = 'DEALS_STDDEV_POPULATION_MIDDLE_NAME_DESC',
  DealsStddevPopulationLastNameAsc = 'DEALS_STDDEV_POPULATION_LAST_NAME_ASC',
  DealsStddevPopulationLastNameDesc = 'DEALS_STDDEV_POPULATION_LAST_NAME_DESC',
  DealsStddevPopulationSuffixAsc = 'DEALS_STDDEV_POPULATION_SUFFIX_ASC',
  DealsStddevPopulationSuffixDesc = 'DEALS_STDDEV_POPULATION_SUFFIX_DESC',
  DealsStddevPopulationAddressLine_1Asc = 'DEALS_STDDEV_POPULATION_ADDRESS_LINE_1_ASC',
  DealsStddevPopulationAddressLine_1Desc = 'DEALS_STDDEV_POPULATION_ADDRESS_LINE_1_DESC',
  DealsStddevPopulationAddressLine_2Asc = 'DEALS_STDDEV_POPULATION_ADDRESS_LINE_2_ASC',
  DealsStddevPopulationAddressLine_2Desc = 'DEALS_STDDEV_POPULATION_ADDRESS_LINE_2_DESC',
  DealsStddevPopulationCityAsc = 'DEALS_STDDEV_POPULATION_CITY_ASC',
  DealsStddevPopulationCityDesc = 'DEALS_STDDEV_POPULATION_CITY_DESC',
  DealsStddevPopulationStateAsc = 'DEALS_STDDEV_POPULATION_STATE_ASC',
  DealsStddevPopulationStateDesc = 'DEALS_STDDEV_POPULATION_STATE_DESC',
  DealsStddevPopulationZipAsc = 'DEALS_STDDEV_POPULATION_ZIP_ASC',
  DealsStddevPopulationZipDesc = 'DEALS_STDDEV_POPULATION_ZIP_DESC',
  DealsStddevPopulationCountyAsc = 'DEALS_STDDEV_POPULATION_COUNTY_ASC',
  DealsStddevPopulationCountyDesc = 'DEALS_STDDEV_POPULATION_COUNTY_DESC',
  DealsStddevPopulationHomePhoneAsc = 'DEALS_STDDEV_POPULATION_HOME_PHONE_ASC',
  DealsStddevPopulationHomePhoneDesc = 'DEALS_STDDEV_POPULATION_HOME_PHONE_DESC',
  DealsStddevPopulationCellPhoneAsc = 'DEALS_STDDEV_POPULATION_CELL_PHONE_ASC',
  DealsStddevPopulationCellPhoneDesc = 'DEALS_STDDEV_POPULATION_CELL_PHONE_DESC',
  DealsStddevPopulationWorkPhoneAsc = 'DEALS_STDDEV_POPULATION_WORK_PHONE_ASC',
  DealsStddevPopulationWorkPhoneDesc = 'DEALS_STDDEV_POPULATION_WORK_PHONE_DESC',
  DealsStddevPopulationWorkExtensionAsc = 'DEALS_STDDEV_POPULATION_WORK_EXTENSION_ASC',
  DealsStddevPopulationWorkExtensionDesc = 'DEALS_STDDEV_POPULATION_WORK_EXTENSION_DESC',
  DealsStddevPopulationEmail_1Asc = 'DEALS_STDDEV_POPULATION_EMAIL_1_ASC',
  DealsStddevPopulationEmail_1Desc = 'DEALS_STDDEV_POPULATION_EMAIL_1_DESC',
  DealsStddevPopulationEmail_2Asc = 'DEALS_STDDEV_POPULATION_EMAIL_2_ASC',
  DealsStddevPopulationEmail_2Desc = 'DEALS_STDDEV_POPULATION_EMAIL_2_DESC',
  DealsStddevPopulationEmail_3Asc = 'DEALS_STDDEV_POPULATION_EMAIL_3_ASC',
  DealsStddevPopulationEmail_3Desc = 'DEALS_STDDEV_POPULATION_EMAIL_3_DESC',
  DealsStddevPopulationBirthDateAsc = 'DEALS_STDDEV_POPULATION_BIRTH_DATE_ASC',
  DealsStddevPopulationBirthDateDesc = 'DEALS_STDDEV_POPULATION_BIRTH_DATE_DESC',
  DealsStddevPopulationIndividualBusinessFlagAsc = 'DEALS_STDDEV_POPULATION_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsStddevPopulationIndividualBusinessFlagDesc = 'DEALS_STDDEV_POPULATION_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsStddevPopulationOptOutAsc = 'DEALS_STDDEV_POPULATION_OPT_OUT_ASC',
  DealsStddevPopulationOptOutDesc = 'DEALS_STDDEV_POPULATION_OPT_OUT_DESC',
  DealsStddevPopulationBlockEmailAsc = 'DEALS_STDDEV_POPULATION_BLOCK_EMAIL_ASC',
  DealsStddevPopulationBlockEmailDesc = 'DEALS_STDDEV_POPULATION_BLOCK_EMAIL_DESC',
  DealsStddevPopulationBlockPhoneAsc = 'DEALS_STDDEV_POPULATION_BLOCK_PHONE_ASC',
  DealsStddevPopulationBlockPhoneDesc = 'DEALS_STDDEV_POPULATION_BLOCK_PHONE_DESC',
  DealsStddevPopulationBlockMailAsc = 'DEALS_STDDEV_POPULATION_BLOCK_MAIL_ASC',
  DealsStddevPopulationBlockMailDesc = 'DEALS_STDDEV_POPULATION_BLOCK_MAIL_DESC',
  DealsStddevPopulationLanguageAsc = 'DEALS_STDDEV_POPULATION_LANGUAGE_ASC',
  DealsStddevPopulationLanguageDesc = 'DEALS_STDDEV_POPULATION_LANGUAGE_DESC',
  DealsStddevPopulationCustomerCreateDateAsc = 'DEALS_STDDEV_POPULATION_CUSTOMER_CREATE_DATE_ASC',
  DealsStddevPopulationCustomerCreateDateDesc = 'DEALS_STDDEV_POPULATION_CUSTOMER_CREATE_DATE_DESC',
  DealsStddevPopulationCustomerLastActivityDateAsc = 'DEALS_STDDEV_POPULATION_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsStddevPopulationCustomerLastActivityDateDesc = 'DEALS_STDDEV_POPULATION_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsStddevPopulationCoBuyerCustomerNumberAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsStddevPopulationCoBuyerCustomerNumberDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsStddevPopulationCoBuyerFullNameAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_FULL_NAME_ASC',
  DealsStddevPopulationCoBuyerFullNameDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_FULL_NAME_DESC',
  DealsStddevPopulationCoBuyerSalutationAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_SALUTATION_ASC',
  DealsStddevPopulationCoBuyerSalutationDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_SALUTATION_DESC',
  DealsStddevPopulationCoBuyerFirstNameAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_FIRST_NAME_ASC',
  DealsStddevPopulationCoBuyerFirstNameDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_FIRST_NAME_DESC',
  DealsStddevPopulationCoBuyerMiddleNameAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_MIDDLE_NAME_ASC',
  DealsStddevPopulationCoBuyerMiddleNameDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_MIDDLE_NAME_DESC',
  DealsStddevPopulationCoBuyerLastNameAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_LAST_NAME_ASC',
  DealsStddevPopulationCoBuyerLastNameDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_LAST_NAME_DESC',
  DealsStddevPopulationCoBuyerSuffixAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_SUFFIX_ASC',
  DealsStddevPopulationCoBuyerSuffixDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_SUFFIX_DESC',
  DealsStddevPopulationCoBuyerAddressLine_1Asc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsStddevPopulationCoBuyerAddressLine_1Desc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsStddevPopulationCoBuyerAddressLine_2Asc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsStddevPopulationCoBuyerAddressLine_2Desc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsStddevPopulationCoBuyerCityAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CITY_ASC',
  DealsStddevPopulationCoBuyerCityDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CITY_DESC',
  DealsStddevPopulationCoBuyerStateAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_STATE_ASC',
  DealsStddevPopulationCoBuyerStateDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_STATE_DESC',
  DealsStddevPopulationCoBuyerZipAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ZIP_ASC',
  DealsStddevPopulationCoBuyerZipDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ZIP_DESC',
  DealsStddevPopulationCoBuyerCountyAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_COUNTY_ASC',
  DealsStddevPopulationCoBuyerCountyDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_COUNTY_DESC',
  DealsStddevPopulationCoBuyerHomePhoneAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_HOME_PHONE_ASC',
  DealsStddevPopulationCoBuyerHomePhoneDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_HOME_PHONE_DESC',
  DealsStddevPopulationCoBuyerCellPhoneAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CELL_PHONE_ASC',
  DealsStddevPopulationCoBuyerCellPhoneDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CELL_PHONE_DESC',
  DealsStddevPopulationCoBuyerWorkPhoneAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_WORK_PHONE_ASC',
  DealsStddevPopulationCoBuyerWorkPhoneDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_WORK_PHONE_DESC',
  DealsStddevPopulationCoBuyerWorkExtensionAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_WORK_EXTENSION_ASC',
  DealsStddevPopulationCoBuyerWorkExtensionDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_WORK_EXTENSION_DESC',
  DealsStddevPopulationCoBuyerEmail_1Asc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_1_ASC',
  DealsStddevPopulationCoBuyerEmail_1Desc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_1_DESC',
  DealsStddevPopulationCoBuyerEmail_2Asc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_2_ASC',
  DealsStddevPopulationCoBuyerEmail_2Desc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_2_DESC',
  DealsStddevPopulationCoBuyerEmail_3Asc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_3_ASC',
  DealsStddevPopulationCoBuyerEmail_3Desc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_3_DESC',
  DealsStddevPopulationCoBuyerBirthDateAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BIRTH_DATE_ASC',
  DealsStddevPopulationCoBuyerBirthDateDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BIRTH_DATE_DESC',
  DealsStddevPopulationCoBuyerIndividualBusinessFlagAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsStddevPopulationCoBuyerIndividualBusinessFlagDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsStddevPopulationCoBuyerOptOutAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_OPT_OUT_ASC',
  DealsStddevPopulationCoBuyerOptOutDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_OPT_OUT_DESC',
  DealsStddevPopulationCoBuyerBlockEmailAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsStddevPopulationCoBuyerBlockEmailDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsStddevPopulationCoBuyerBlockPhoneAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_PHONE_ASC',
  DealsStddevPopulationCoBuyerBlockPhoneDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_PHONE_DESC',
  DealsStddevPopulationCoBuyerBlockMailAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_MAIL_ASC',
  DealsStddevPopulationCoBuyerBlockMailDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_MAIL_DESC',
  DealsStddevPopulationVinAsc = 'DEALS_STDDEV_POPULATION_VIN_ASC',
  DealsStddevPopulationVinDesc = 'DEALS_STDDEV_POPULATION_VIN_DESC',
  DealsStddevPopulationYearAsc = 'DEALS_STDDEV_POPULATION_YEAR_ASC',
  DealsStddevPopulationYearDesc = 'DEALS_STDDEV_POPULATION_YEAR_DESC',
  DealsStddevPopulationMakeAsc = 'DEALS_STDDEV_POPULATION_MAKE_ASC',
  DealsStddevPopulationMakeDesc = 'DEALS_STDDEV_POPULATION_MAKE_DESC',
  DealsStddevPopulationModelAsc = 'DEALS_STDDEV_POPULATION_MODEL_ASC',
  DealsStddevPopulationModelDesc = 'DEALS_STDDEV_POPULATION_MODEL_DESC',
  DealsStddevPopulationModelNumberAsc = 'DEALS_STDDEV_POPULATION_MODEL_NUMBER_ASC',
  DealsStddevPopulationModelNumberDesc = 'DEALS_STDDEV_POPULATION_MODEL_NUMBER_DESC',
  DealsStddevPopulationMileageAsc = 'DEALS_STDDEV_POPULATION_MILEAGE_ASC',
  DealsStddevPopulationMileageDesc = 'DEALS_STDDEV_POPULATION_MILEAGE_DESC',
  DealsStddevPopulationDescriptionAsc = 'DEALS_STDDEV_POPULATION_DESCRIPTION_ASC',
  DealsStddevPopulationDescriptionDesc = 'DEALS_STDDEV_POPULATION_DESCRIPTION_DESC',
  DealsStddevPopulationExteriorColorAsc = 'DEALS_STDDEV_POPULATION_EXTERIOR_COLOR_ASC',
  DealsStddevPopulationExteriorColorDesc = 'DEALS_STDDEV_POPULATION_EXTERIOR_COLOR_DESC',
  DealsStddevPopulationNewUsedAsc = 'DEALS_STDDEV_POPULATION_NEW_USED_ASC',
  DealsStddevPopulationNewUsedDesc = 'DEALS_STDDEV_POPULATION_NEW_USED_DESC',
  DealsStddevPopulationStockNumberAsc = 'DEALS_STDDEV_POPULATION_STOCK_NUMBER_ASC',
  DealsStddevPopulationStockNumberDesc = 'DEALS_STDDEV_POPULATION_STOCK_NUMBER_DESC',
  DealsStddevPopulationTransmissionAsc = 'DEALS_STDDEV_POPULATION_TRANSMISSION_ASC',
  DealsStddevPopulationTransmissionDesc = 'DEALS_STDDEV_POPULATION_TRANSMISSION_DESC',
  DealsStddevPopulationEngineConfigurationAsc = 'DEALS_STDDEV_POPULATION_ENGINE_CONFIGURATION_ASC',
  DealsStddevPopulationEngineConfigurationDesc = 'DEALS_STDDEV_POPULATION_ENGINE_CONFIGURATION_DESC',
  DealsStddevPopulationTrimAsc = 'DEALS_STDDEV_POPULATION_TRIM_ASC',
  DealsStddevPopulationTrimDesc = 'DEALS_STDDEV_POPULATION_TRIM_DESC',
  DealsStddevPopulationEngineNumberAsc = 'DEALS_STDDEV_POPULATION_ENGINE_NUMBER_ASC',
  DealsStddevPopulationEngineNumberDesc = 'DEALS_STDDEV_POPULATION_ENGINE_NUMBER_DESC',
  DealsStddevPopulationChassisNumberAsc = 'DEALS_STDDEV_POPULATION_CHASSIS_NUMBER_ASC',
  DealsStddevPopulationChassisNumberDesc = 'DEALS_STDDEV_POPULATION_CHASSIS_NUMBER_DESC',
  DealsStddevPopulationLicensePlateNumberAsc = 'DEALS_STDDEV_POPULATION_LICENSE_PLATE_NUMBER_ASC',
  DealsStddevPopulationLicensePlateNumberDesc = 'DEALS_STDDEV_POPULATION_LICENSE_PLATE_NUMBER_DESC',
  DealsStddevPopulationDeliveryDateAsc = 'DEALS_STDDEV_POPULATION_DELIVERY_DATE_ASC',
  DealsStddevPopulationDeliveryDateDesc = 'DEALS_STDDEV_POPULATION_DELIVERY_DATE_DESC',
  DealsStddevPopulationDeliveryMileageAsc = 'DEALS_STDDEV_POPULATION_DELIVERY_MILEAGE_ASC',
  DealsStddevPopulationDeliveryMileageDesc = 'DEALS_STDDEV_POPULATION_DELIVERY_MILEAGE_DESC',
  DealsStddevPopulationInventoryDateAsc = 'DEALS_STDDEV_POPULATION_INVENTORY_DATE_ASC',
  DealsStddevPopulationInventoryDateDesc = 'DEALS_STDDEV_POPULATION_INVENTORY_DATE_DESC',
  DealsStddevPopulationInServiceDateAsc = 'DEALS_STDDEV_POPULATION_IN_SERVICE_DATE_ASC',
  DealsStddevPopulationInServiceDateDesc = 'DEALS_STDDEV_POPULATION_IN_SERVICE_DATE_DESC',
  DealsStddevPopulationVinExplosionYearAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_YEAR_ASC',
  DealsStddevPopulationVinExplosionYearDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_YEAR_DESC',
  DealsStddevPopulationVinExplosionMakeAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_MAKE_ASC',
  DealsStddevPopulationVinExplosionMakeDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_MAKE_DESC',
  DealsStddevPopulationVinExplosionModelAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_MODEL_ASC',
  DealsStddevPopulationVinExplosionModelDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_MODEL_DESC',
  DealsStddevPopulationVinExplosionTrimAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_TRIM_ASC',
  DealsStddevPopulationVinExplosionTrimDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_TRIM_DESC',
  DealsStddevPopulationVinExplosionTransmissionTypeAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsStddevPopulationVinExplosionTransmissionTypeDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsStddevPopulationVinExplosionFuelTypeAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsStddevPopulationVinExplosionFuelTypeDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsStddevPopulationVinExplosionEngineSizeAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsStddevPopulationVinExplosionEngineSizeDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsStddevPopulationVinExplosionGvwRangeAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsStddevPopulationVinExplosionGvwRangeDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsStddevPopulationTrade_1VinAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_VIN_ASC',
  DealsStddevPopulationTrade_1VinDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_VIN_DESC',
  DealsStddevPopulationTrade_1YearAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_YEAR_ASC',
  DealsStddevPopulationTrade_1YearDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_YEAR_DESC',
  DealsStddevPopulationTrade_1MakeAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_MAKE_ASC',
  DealsStddevPopulationTrade_1MakeDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_MAKE_DESC',
  DealsStddevPopulationTrade_1ModelAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_MODEL_ASC',
  DealsStddevPopulationTrade_1ModelDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_MODEL_DESC',
  DealsStddevPopulationTrade_1OdometerAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_ODOMETER_ASC',
  DealsStddevPopulationTrade_1OdometerDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_ODOMETER_DESC',
  DealsStddevPopulationTrade_1ActualCashValueAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsStddevPopulationTrade_1ActualCashValueDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsStddevPopulationTrade_1GrossAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_GROSS_ASC',
  DealsStddevPopulationTrade_1GrossDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_GROSS_DESC',
  DealsStddevPopulationTrade_1PayoffAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_PAYOFF_ASC',
  DealsStddevPopulationTrade_1PayoffDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_PAYOFF_DESC',
  DealsStddevPopulationTrade_2VinAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_VIN_ASC',
  DealsStddevPopulationTrade_2VinDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_VIN_DESC',
  DealsStddevPopulationTrade_2YearAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_YEAR_ASC',
  DealsStddevPopulationTrade_2YearDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_YEAR_DESC',
  DealsStddevPopulationTrade_2MakeAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_MAKE_ASC',
  DealsStddevPopulationTrade_2MakeDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_MAKE_DESC',
  DealsStddevPopulationTrade_2ModelAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_MODEL_ASC',
  DealsStddevPopulationTrade_2ModelDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_MODEL_DESC',
  DealsStddevPopulationTrade_2OdometerAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_ODOMETER_ASC',
  DealsStddevPopulationTrade_2OdometerDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_ODOMETER_DESC',
  DealsStddevPopulationTrade_2ActualCashValueAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsStddevPopulationTrade_2ActualCashValueDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsStddevPopulationTrade_2GrossAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_GROSS_ASC',
  DealsStddevPopulationTrade_2GrossDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_GROSS_DESC',
  DealsStddevPopulationTrade_2PayoffAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_PAYOFF_ASC',
  DealsStddevPopulationTrade_2PayoffDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_PAYOFF_DESC',
  DealsStddevPopulationSalesman_1NumberAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_NUMBER_ASC',
  DealsStddevPopulationSalesman_1NumberDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_NUMBER_DESC',
  DealsStddevPopulationSalesman_1NameAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_NAME_ASC',
  DealsStddevPopulationSalesman_1NameDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_NAME_DESC',
  DealsStddevPopulationSalesman_2NumberAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_NUMBER_ASC',
  DealsStddevPopulationSalesman_2NumberDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_NUMBER_DESC',
  DealsStddevPopulationSalesman_2NameAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_NAME_ASC',
  DealsStddevPopulationSalesman_2NameDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_NAME_DESC',
  DealsStddevPopulationSalesman_3NumberAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_NUMBER_ASC',
  DealsStddevPopulationSalesman_3NumberDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_NUMBER_DESC',
  DealsStddevPopulationSalesman_3NameAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_NAME_ASC',
  DealsStddevPopulationSalesman_3NameDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_NAME_DESC',
  DealsStddevPopulationClosingManagerNumberAsc = 'DEALS_STDDEV_POPULATION_CLOSING_MANAGER_NUMBER_ASC',
  DealsStddevPopulationClosingManagerNumberDesc = 'DEALS_STDDEV_POPULATION_CLOSING_MANAGER_NUMBER_DESC',
  DealsStddevPopulationClosingManagerNameAsc = 'DEALS_STDDEV_POPULATION_CLOSING_MANAGER_NAME_ASC',
  DealsStddevPopulationClosingManagerNameDesc = 'DEALS_STDDEV_POPULATION_CLOSING_MANAGER_NAME_DESC',
  DealsStddevPopulationFinanceManagerNumberAsc = 'DEALS_STDDEV_POPULATION_FINANCE_MANAGER_NUMBER_ASC',
  DealsStddevPopulationFinanceManagerNumberDesc = 'DEALS_STDDEV_POPULATION_FINANCE_MANAGER_NUMBER_DESC',
  DealsStddevPopulationFinanceManagerNameAsc = 'DEALS_STDDEV_POPULATION_FINANCE_MANAGER_NAME_ASC',
  DealsStddevPopulationFinanceManagerNameDesc = 'DEALS_STDDEV_POPULATION_FINANCE_MANAGER_NAME_DESC',
  DealsStddevPopulationSalesmanManagerNumberAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_MANAGER_NUMBER_ASC',
  DealsStddevPopulationSalesmanManagerNumberDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_MANAGER_NUMBER_DESC',
  DealsStddevPopulationSalesmanManagerNameAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_MANAGER_NAME_ASC',
  DealsStddevPopulationSalesmanManagerNameDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_MANAGER_NAME_DESC',
  DealsStddevPopulationMsrpAsc = 'DEALS_STDDEV_POPULATION_MSRP_ASC',
  DealsStddevPopulationMsrpDesc = 'DEALS_STDDEV_POPULATION_MSRP_DESC',
  DealsStddevPopulationListPriceAsc = 'DEALS_STDDEV_POPULATION_LIST_PRICE_ASC',
  DealsStddevPopulationListPriceDesc = 'DEALS_STDDEV_POPULATION_LIST_PRICE_DESC',
  DealsStddevPopulationSalesPriceAsc = 'DEALS_STDDEV_POPULATION_SALES_PRICE_ASC',
  DealsStddevPopulationSalesPriceDesc = 'DEALS_STDDEV_POPULATION_SALES_PRICE_DESC',
  DealsStddevPopulationJournalPriceAsc = 'DEALS_STDDEV_POPULATION_JOURNAL_PRICE_ASC',
  DealsStddevPopulationJournalPriceDesc = 'DEALS_STDDEV_POPULATION_JOURNAL_PRICE_DESC',
  DealsStddevPopulationCostAsc = 'DEALS_STDDEV_POPULATION_COST_ASC',
  DealsStddevPopulationCostDesc = 'DEALS_STDDEV_POPULATION_COST_DESC',
  DealsStddevPopulationJournalCostAsc = 'DEALS_STDDEV_POPULATION_JOURNAL_COST_ASC',
  DealsStddevPopulationJournalCostDesc = 'DEALS_STDDEV_POPULATION_JOURNAL_COST_DESC',
  DealsStddevPopulationAdjustmentsAsc = 'DEALS_STDDEV_POPULATION_ADJUSTMENTS_ASC',
  DealsStddevPopulationAdjustmentsDesc = 'DEALS_STDDEV_POPULATION_ADJUSTMENTS_DESC',
  DealsStddevPopulationAdjustedCostAsc = 'DEALS_STDDEV_POPULATION_ADJUSTED_COST_ASC',
  DealsStddevPopulationAdjustedCostDesc = 'DEALS_STDDEV_POPULATION_ADJUSTED_COST_DESC',
  DealsStddevPopulationIncentivesAsc = 'DEALS_STDDEV_POPULATION_INCENTIVES_ASC',
  DealsStddevPopulationIncentivesDesc = 'DEALS_STDDEV_POPULATION_INCENTIVES_DESC',
  DealsStddevPopulationPackAmountAsc = 'DEALS_STDDEV_POPULATION_PACK_AMOUNT_ASC',
  DealsStddevPopulationPackAmountDesc = 'DEALS_STDDEV_POPULATION_PACK_AMOUNT_DESC',
  DealsStddevPopulationSaleNetAsc = 'DEALS_STDDEV_POPULATION_SALE_NET_ASC',
  DealsStddevPopulationSaleNetDesc = 'DEALS_STDDEV_POPULATION_SALE_NET_DESC',
  DealsStddevPopulationTotalTradeActualCashValueAsc = 'DEALS_STDDEV_POPULATION_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsStddevPopulationTotalTradeActualCashValueDesc = 'DEALS_STDDEV_POPULATION_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsStddevPopulationTotalTradeGrossAsc = 'DEALS_STDDEV_POPULATION_TOTAL_TRADE_GROSS_ASC',
  DealsStddevPopulationTotalTradeGrossDesc = 'DEALS_STDDEV_POPULATION_TOTAL_TRADE_GROSS_DESC',
  DealsStddevPopulationWeOweFrontAsc = 'DEALS_STDDEV_POPULATION_WE_OWE_FRONT_ASC',
  DealsStddevPopulationWeOweFrontDesc = 'DEALS_STDDEV_POPULATION_WE_OWE_FRONT_DESC',
  DealsStddevPopulationTotalFrontFeesAftermarketProfitAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsStddevPopulationTotalFrontFeesAftermarketProfitDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsStddevPopulationTotalFrontCommissionAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_COMMISSION_ASC',
  DealsStddevPopulationTotalFrontCommissionDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_COMMISSION_DESC',
  DealsStddevPopulationTotalFrontSalesAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_SALES_ASC',
  DealsStddevPopulationTotalFrontSalesDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_SALES_DESC',
  DealsStddevPopulationTotalFrontCostAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_COST_ASC',
  DealsStddevPopulationTotalFrontCostDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_COST_DESC',
  DealsStddevPopulationFrontGrossAsc = 'DEALS_STDDEV_POPULATION_FRONT_GROSS_ASC',
  DealsStddevPopulationFrontGrossDesc = 'DEALS_STDDEV_POPULATION_FRONT_GROSS_DESC',
  DealsStddevPopulationFinanceProfitAsc = 'DEALS_STDDEV_POPULATION_FINANCE_PROFIT_ASC',
  DealsStddevPopulationFinanceProfitDesc = 'DEALS_STDDEV_POPULATION_FINANCE_PROFIT_DESC',
  DealsStddevPopulationTotalWarrantyProfitAsc = 'DEALS_STDDEV_POPULATION_TOTAL_WARRANTY_PROFIT_ASC',
  DealsStddevPopulationTotalWarrantyProfitDesc = 'DEALS_STDDEV_POPULATION_TOTAL_WARRANTY_PROFIT_DESC',
  DealsStddevPopulationWeOweBackAsc = 'DEALS_STDDEV_POPULATION_WE_OWE_BACK_ASC',
  DealsStddevPopulationWeOweBackDesc = 'DEALS_STDDEV_POPULATION_WE_OWE_BACK_DESC',
  DealsStddevPopulationInsuranceProfitAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_PROFIT_ASC',
  DealsStddevPopulationInsuranceProfitDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_PROFIT_DESC',
  DealsStddevPopulationTotalBackFeesAftermarketProfitAsc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsStddevPopulationTotalBackFeesAftermarketProfitDesc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsStddevPopulationFinanceReserveAsc = 'DEALS_STDDEV_POPULATION_FINANCE_RESERVE_ASC',
  DealsStddevPopulationFinanceReserveDesc = 'DEALS_STDDEV_POPULATION_FINANCE_RESERVE_DESC',
  DealsStddevPopulationTotalBackCommissionAsc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_COMMISSION_ASC',
  DealsStddevPopulationTotalBackCommissionDesc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_COMMISSION_DESC',
  DealsStddevPopulationTotalBackSalesAsc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_SALES_ASC',
  DealsStddevPopulationTotalBackSalesDesc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_SALES_DESC',
  DealsStddevPopulationTotalBackCostAsc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_COST_ASC',
  DealsStddevPopulationTotalBackCostDesc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_COST_DESC',
  DealsStddevPopulationBackGrossAsc = 'DEALS_STDDEV_POPULATION_BACK_GROSS_ASC',
  DealsStddevPopulationBackGrossDesc = 'DEALS_STDDEV_POPULATION_BACK_GROSS_DESC',
  DealsStddevPopulationTotalProfitAsc = 'DEALS_STDDEV_POPULATION_TOTAL_PROFIT_ASC',
  DealsStddevPopulationTotalProfitDesc = 'DEALS_STDDEV_POPULATION_TOTAL_PROFIT_DESC',
  DealsStddevPopulationGrossProfitAsc = 'DEALS_STDDEV_POPULATION_GROSS_PROFIT_ASC',
  DealsStddevPopulationGrossProfitDesc = 'DEALS_STDDEV_POPULATION_GROSS_PROFIT_DESC',
  DealsStddevPopulationGrossPayableAsc = 'DEALS_STDDEV_POPULATION_GROSS_PAYABLE_ASC',
  DealsStddevPopulationGrossPayableDesc = 'DEALS_STDDEV_POPULATION_GROSS_PAYABLE_DESC',
  DealsStddevPopulationDealStatusAsc = 'DEALS_STDDEV_POPULATION_DEAL_STATUS_ASC',
  DealsStddevPopulationDealStatusDesc = 'DEALS_STDDEV_POPULATION_DEAL_STATUS_DESC',
  DealsStddevPopulationEntryDateAsc = 'DEALS_STDDEV_POPULATION_ENTRY_DATE_ASC',
  DealsStddevPopulationEntryDateDesc = 'DEALS_STDDEV_POPULATION_ENTRY_DATE_DESC',
  DealsStddevPopulationBookedDateAsc = 'DEALS_STDDEV_POPULATION_BOOKED_DATE_ASC',
  DealsStddevPopulationBookedDateDesc = 'DEALS_STDDEV_POPULATION_BOOKED_DATE_DESC',
  DealsStddevPopulationFinalizedDateAsc = 'DEALS_STDDEV_POPULATION_FINALIZED_DATE_ASC',
  DealsStddevPopulationFinalizedDateDesc = 'DEALS_STDDEV_POPULATION_FINALIZED_DATE_DESC',
  DealsStddevPopulationContractDateAsc = 'DEALS_STDDEV_POPULATION_CONTRACT_DATE_ASC',
  DealsStddevPopulationContractDateDesc = 'DEALS_STDDEV_POPULATION_CONTRACT_DATE_DESC',
  DealsStddevPopulationAccountingDateAsc = 'DEALS_STDDEV_POPULATION_ACCOUNTING_DATE_ASC',
  DealsStddevPopulationAccountingDateDesc = 'DEALS_STDDEV_POPULATION_ACCOUNTING_DATE_DESC',
  DealsStddevPopulationStatusChangeDateAsc = 'DEALS_STDDEV_POPULATION_STATUS_CHANGE_DATE_ASC',
  DealsStddevPopulationStatusChangeDateDesc = 'DEALS_STDDEV_POPULATION_STATUS_CHANGE_DATE_DESC',
  DealsStddevPopulationFirstPayDateAsc = 'DEALS_STDDEV_POPULATION_FIRST_PAY_DATE_ASC',
  DealsStddevPopulationFirstPayDateDesc = 'DEALS_STDDEV_POPULATION_FIRST_PAY_DATE_DESC',
  DealsStddevPopulationDealTypeAsc = 'DEALS_STDDEV_POPULATION_DEAL_TYPE_ASC',
  DealsStddevPopulationDealTypeDesc = 'DEALS_STDDEV_POPULATION_DEAL_TYPE_DESC',
  DealsStddevPopulationSaleTypeAsc = 'DEALS_STDDEV_POPULATION_SALE_TYPE_ASC',
  DealsStddevPopulationSaleTypeDesc = 'DEALS_STDDEV_POPULATION_SALE_TYPE_DESC',
  DealsStddevPopulationBankIdAsc = 'DEALS_STDDEV_POPULATION_BANK_ID_ASC',
  DealsStddevPopulationBankIdDesc = 'DEALS_STDDEV_POPULATION_BANK_ID_DESC',
  DealsStddevPopulationBankNameAsc = 'DEALS_STDDEV_POPULATION_BANK_NAME_ASC',
  DealsStddevPopulationBankNameDesc = 'DEALS_STDDEV_POPULATION_BANK_NAME_DESC',
  DealsStddevPopulationBankAddressAsc = 'DEALS_STDDEV_POPULATION_BANK_ADDRESS_ASC',
  DealsStddevPopulationBankAddressDesc = 'DEALS_STDDEV_POPULATION_BANK_ADDRESS_DESC',
  DealsStddevPopulationTermAsc = 'DEALS_STDDEV_POPULATION_TERM_ASC',
  DealsStddevPopulationTermDesc = 'DEALS_STDDEV_POPULATION_TERM_DESC',
  DealsStddevPopulationAmountFinancedAsc = 'DEALS_STDDEV_POPULATION_AMOUNT_FINANCED_ASC',
  DealsStddevPopulationAmountFinancedDesc = 'DEALS_STDDEV_POPULATION_AMOUNT_FINANCED_DESC',
  DealsStddevPopulationAprAsc = 'DEALS_STDDEV_POPULATION_APR_ASC',
  DealsStddevPopulationAprDesc = 'DEALS_STDDEV_POPULATION_APR_DESC',
  DealsStddevPopulationMonthlyPaymentAsc = 'DEALS_STDDEV_POPULATION_MONTHLY_PAYMENT_ASC',
  DealsStddevPopulationMonthlyPaymentDesc = 'DEALS_STDDEV_POPULATION_MONTHLY_PAYMENT_DESC',
  DealsStddevPopulationPaymentTotalAsc = 'DEALS_STDDEV_POPULATION_PAYMENT_TOTAL_ASC',
  DealsStddevPopulationPaymentTotalDesc = 'DEALS_STDDEV_POPULATION_PAYMENT_TOTAL_DESC',
  DealsStddevPopulationRebatesAsc = 'DEALS_STDDEV_POPULATION_REBATES_ASC',
  DealsStddevPopulationRebatesDesc = 'DEALS_STDDEV_POPULATION_REBATES_DESC',
  DealsStddevPopulationDepositAsc = 'DEALS_STDDEV_POPULATION_DEPOSIT_ASC',
  DealsStddevPopulationDepositDesc = 'DEALS_STDDEV_POPULATION_DEPOSIT_DESC',
  DealsStddevPopulationDownPaymentAsc = 'DEALS_STDDEV_POPULATION_DOWN_PAYMENT_ASC',
  DealsStddevPopulationDownPaymentDesc = 'DEALS_STDDEV_POPULATION_DOWN_PAYMENT_DESC',
  DealsStddevPopulationTotalNetTradesAsc = 'DEALS_STDDEV_POPULATION_TOTAL_NET_TRADES_ASC',
  DealsStddevPopulationTotalNetTradesDesc = 'DEALS_STDDEV_POPULATION_TOTAL_NET_TRADES_DESC',
  DealsStddevPopulationTotalDownAsc = 'DEALS_STDDEV_POPULATION_TOTAL_DOWN_ASC',
  DealsStddevPopulationTotalDownDesc = 'DEALS_STDDEV_POPULATION_TOTAL_DOWN_DESC',
  DealsStddevPopulationBalloonAmountAsc = 'DEALS_STDDEV_POPULATION_BALLOON_AMOUNT_ASC',
  DealsStddevPopulationBalloonAmountDesc = 'DEALS_STDDEV_POPULATION_BALLOON_AMOUNT_DESC',
  DealsStddevPopulationAdjustedBalloonAmountAsc = 'DEALS_STDDEV_POPULATION_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsStddevPopulationAdjustedBalloonAmountDesc = 'DEALS_STDDEV_POPULATION_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsStddevPopulationHoldbackAmountAsc = 'DEALS_STDDEV_POPULATION_HOLDBACK_AMOUNT_ASC',
  DealsStddevPopulationHoldbackAmountDesc = 'DEALS_STDDEV_POPULATION_HOLDBACK_AMOUNT_DESC',
  DealsStddevPopulationTotalDriveOffAmountAsc = 'DEALS_STDDEV_POPULATION_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsStddevPopulationTotalDriveOffAmountDesc = 'DEALS_STDDEV_POPULATION_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsStddevPopulationLicenseFeeAsc = 'DEALS_STDDEV_POPULATION_LICENSE_FEE_ASC',
  DealsStddevPopulationLicenseFeeDesc = 'DEALS_STDDEV_POPULATION_LICENSE_FEE_DESC',
  DealsStddevPopulationRegistrationFeeAsc = 'DEALS_STDDEV_POPULATION_REGISTRATION_FEE_ASC',
  DealsStddevPopulationRegistrationFeeDesc = 'DEALS_STDDEV_POPULATION_REGISTRATION_FEE_DESC',
  DealsStddevPopulationDocumentationFeeAsc = 'DEALS_STDDEV_POPULATION_DOCUMENTATION_FEE_ASC',
  DealsStddevPopulationDocumentationFeeDesc = 'DEALS_STDDEV_POPULATION_DOCUMENTATION_FEE_DESC',
  DealsStddevPopulationFinanceChargeAsc = 'DEALS_STDDEV_POPULATION_FINANCE_CHARGE_ASC',
  DealsStddevPopulationFinanceChargeDesc = 'DEALS_STDDEV_POPULATION_FINANCE_CHARGE_DESC',
  DealsStddevPopulationTotalPickupPaymentsAsc = 'DEALS_STDDEV_POPULATION_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsStddevPopulationTotalPickupPaymentsDesc = 'DEALS_STDDEV_POPULATION_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsStddevPopulationSellRateAsc = 'DEALS_STDDEV_POPULATION_SELL_RATE_ASC',
  DealsStddevPopulationSellRateDesc = 'DEALS_STDDEV_POPULATION_SELL_RATE_DESC',
  DealsStddevPopulationBuyRateAsc = 'DEALS_STDDEV_POPULATION_BUY_RATE_ASC',
  DealsStddevPopulationBuyRateDesc = 'DEALS_STDDEV_POPULATION_BUY_RATE_DESC',
  DealsStddevPopulationResidualRateAsc = 'DEALS_STDDEV_POPULATION_RESIDUAL_RATE_ASC',
  DealsStddevPopulationResidualRateDesc = 'DEALS_STDDEV_POPULATION_RESIDUAL_RATE_DESC',
  DealsStddevPopulationResidualAmountAsc = 'DEALS_STDDEV_POPULATION_RESIDUAL_AMOUNT_ASC',
  DealsStddevPopulationResidualAmountDesc = 'DEALS_STDDEV_POPULATION_RESIDUAL_AMOUNT_DESC',
  DealsStddevPopulationAllowedMilesAsc = 'DEALS_STDDEV_POPULATION_ALLOWED_MILES_ASC',
  DealsStddevPopulationAllowedMilesDesc = 'DEALS_STDDEV_POPULATION_ALLOWED_MILES_DESC',
  DealsStddevPopulationEstimatedMilesAsc = 'DEALS_STDDEV_POPULATION_ESTIMATED_MILES_ASC',
  DealsStddevPopulationEstimatedMilesDesc = 'DEALS_STDDEV_POPULATION_ESTIMATED_MILES_DESC',
  DealsStddevPopulationMileageRateAsc = 'DEALS_STDDEV_POPULATION_MILEAGE_RATE_ASC',
  DealsStddevPopulationMileageRateDesc = 'DEALS_STDDEV_POPULATION_MILEAGE_RATE_DESC',
  DealsStddevPopulationAcquisitionFeeAsc = 'DEALS_STDDEV_POPULATION_ACQUISITION_FEE_ASC',
  DealsStddevPopulationAcquisitionFeeDesc = 'DEALS_STDDEV_POPULATION_ACQUISITION_FEE_DESC',
  DealsStddevPopulationBasePaymentAsc = 'DEALS_STDDEV_POPULATION_BASE_PAYMENT_ASC',
  DealsStddevPopulationBasePaymentDesc = 'DEALS_STDDEV_POPULATION_BASE_PAYMENT_DESC',
  DealsStddevPopulationSecurityDepositAsc = 'DEALS_STDDEV_POPULATION_SECURITY_DEPOSIT_ASC',
  DealsStddevPopulationSecurityDepositDesc = 'DEALS_STDDEV_POPULATION_SECURITY_DEPOSIT_DESC',
  DealsStddevPopulationTotalCapitalReductionAsc = 'DEALS_STDDEV_POPULATION_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsStddevPopulationTotalCapitalReductionDesc = 'DEALS_STDDEV_POPULATION_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsStddevPopulationNetCapitalCostAsc = 'DEALS_STDDEV_POPULATION_NET_CAPITAL_COST_ASC',
  DealsStddevPopulationNetCapitalCostDesc = 'DEALS_STDDEV_POPULATION_NET_CAPITAL_COST_DESC',
  DealsStddevPopulationLeaseDepreciationValueAsc = 'DEALS_STDDEV_POPULATION_LEASE_DEPRECIATION_VALUE_ASC',
  DealsStddevPopulationLeaseDepreciationValueDesc = 'DEALS_STDDEV_POPULATION_LEASE_DEPRECIATION_VALUE_DESC',
  DealsStddevPopulationDealerFeesAsc = 'DEALS_STDDEV_POPULATION_DEALER_FEES_ASC',
  DealsStddevPopulationDealerFeesDesc = 'DEALS_STDDEV_POPULATION_DEALER_FEES_DESC',
  DealsStddevPopulationGovernmentFeesAsc = 'DEALS_STDDEV_POPULATION_GOVERNMENT_FEES_ASC',
  DealsStddevPopulationGovernmentFeesDesc = 'DEALS_STDDEV_POPULATION_GOVERNMENT_FEES_DESC',
  DealsStddevPopulationTotalTaxAsc = 'DEALS_STDDEV_POPULATION_TOTAL_TAX_ASC',
  DealsStddevPopulationTotalTaxDesc = 'DEALS_STDDEV_POPULATION_TOTAL_TAX_DESC',
  DealsStddevPopulationRegistrationStateAsc = 'DEALS_STDDEV_POPULATION_REGISTRATION_STATE_ASC',
  DealsStddevPopulationRegistrationStateDesc = 'DEALS_STDDEV_POPULATION_REGISTRATION_STATE_DESC',
  DealsStddevPopulationReportOfSaleNumberAsc = 'DEALS_STDDEV_POPULATION_REPORT_OF_SALE_NUMBER_ASC',
  DealsStddevPopulationReportOfSaleNumberDesc = 'DEALS_STDDEV_POPULATION_REPORT_OF_SALE_NUMBER_DESC',
  DealsStddevPopulationSalesman_1TotalCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsStddevPopulationSalesman_1TotalCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsStddevPopulationSalesman_1FrontCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsStddevPopulationSalesman_1FrontCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsStddevPopulationSalesman_1BackCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsStddevPopulationSalesman_1BackCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsStddevPopulationSalesman_2TotalCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsStddevPopulationSalesman_2TotalCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsStddevPopulationSalesman_2FrontCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsStddevPopulationSalesman_2FrontCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsStddevPopulationSalesman_2BackCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsStddevPopulationSalesman_2BackCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsStddevPopulationSalesman_3TotalCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsStddevPopulationSalesman_3TotalCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsStddevPopulationSalesman_3FrontCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsStddevPopulationSalesman_3FrontCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsStddevPopulationSalesman_3BackCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsStddevPopulationSalesman_3BackCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsStddevPopulationWarranty_1NameAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_NAME_ASC',
  DealsStddevPopulationWarranty_1NameDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_NAME_DESC',
  DealsStddevPopulationWarranty_1SaleAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_SALE_ASC',
  DealsStddevPopulationWarranty_1SaleDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_SALE_DESC',
  DealsStddevPopulationWarranty_1CostAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_COST_ASC',
  DealsStddevPopulationWarranty_1CostDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_COST_DESC',
  DealsStddevPopulationWarranty_1MilesAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_MILES_ASC',
  DealsStddevPopulationWarranty_1MilesDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_MILES_DESC',
  DealsStddevPopulationWarranty_1TermAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_TERM_ASC',
  DealsStddevPopulationWarranty_1TermDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_TERM_DESC',
  DealsStddevPopulationWarranty_2NameAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_NAME_ASC',
  DealsStddevPopulationWarranty_2NameDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_NAME_DESC',
  DealsStddevPopulationWarranty_2SaleAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_SALE_ASC',
  DealsStddevPopulationWarranty_2SaleDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_SALE_DESC',
  DealsStddevPopulationWarranty_2CostAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_COST_ASC',
  DealsStddevPopulationWarranty_2CostDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_COST_DESC',
  DealsStddevPopulationWarranty_2MilesAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_MILES_ASC',
  DealsStddevPopulationWarranty_2MilesDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_MILES_DESC',
  DealsStddevPopulationWarranty_2TermAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_TERM_ASC',
  DealsStddevPopulationWarranty_2TermDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_TERM_DESC',
  DealsStddevPopulationWarranty_3NameAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_NAME_ASC',
  DealsStddevPopulationWarranty_3NameDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_NAME_DESC',
  DealsStddevPopulationWarranty_3SaleAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_SALE_ASC',
  DealsStddevPopulationWarranty_3SaleDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_SALE_DESC',
  DealsStddevPopulationWarranty_3CostAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_COST_ASC',
  DealsStddevPopulationWarranty_3CostDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_COST_DESC',
  DealsStddevPopulationWarranty_3MilesAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_MILES_ASC',
  DealsStddevPopulationWarranty_3MilesDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_MILES_DESC',
  DealsStddevPopulationWarranty_3TermAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_TERM_ASC',
  DealsStddevPopulationWarranty_3TermDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_TERM_DESC',
  DealsStddevPopulationWarranty_4NameAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_NAME_ASC',
  DealsStddevPopulationWarranty_4NameDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_NAME_DESC',
  DealsStddevPopulationWarranty_4SaleAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_SALE_ASC',
  DealsStddevPopulationWarranty_4SaleDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_SALE_DESC',
  DealsStddevPopulationWarranty_4CostAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_COST_ASC',
  DealsStddevPopulationWarranty_4CostDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_COST_DESC',
  DealsStddevPopulationWarranty_4MilesAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_MILES_ASC',
  DealsStddevPopulationWarranty_4MilesDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_MILES_DESC',
  DealsStddevPopulationWarranty_4TermAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_TERM_ASC',
  DealsStddevPopulationWarranty_4TermDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_TERM_DESC',
  DealsStddevPopulationWarranty_5NameAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_NAME_ASC',
  DealsStddevPopulationWarranty_5NameDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_NAME_DESC',
  DealsStddevPopulationWarranty_5SaleAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_SALE_ASC',
  DealsStddevPopulationWarranty_5SaleDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_SALE_DESC',
  DealsStddevPopulationWarranty_5CostAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_COST_ASC',
  DealsStddevPopulationWarranty_5CostDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_COST_DESC',
  DealsStddevPopulationWarranty_5MilesAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_MILES_ASC',
  DealsStddevPopulationWarranty_5MilesDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_MILES_DESC',
  DealsStddevPopulationWarranty_5TermAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_TERM_ASC',
  DealsStddevPopulationWarranty_5TermDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_TERM_DESC',
  DealsStddevPopulationTotalFeeAftermarketSaleAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsStddevPopulationTotalFeeAftermarketSaleDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsStddevPopulationTotalFeeAftermarketCostAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsStddevPopulationTotalFeeAftermarketCostDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsStddevPopulationFeeAftermarket_1NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_1NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_1SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_1SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_1CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_COST_ASC',
  DealsStddevPopulationFeeAftermarket_1CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_COST_DESC',
  DealsStddevPopulationFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_2NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_2NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_2SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_2SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_2CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_COST_ASC',
  DealsStddevPopulationFeeAftermarket_2CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_COST_DESC',
  DealsStddevPopulationFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_3NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_3NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_3SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_3SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_3CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_COST_ASC',
  DealsStddevPopulationFeeAftermarket_3CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_COST_DESC',
  DealsStddevPopulationFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_4NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_4NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_4SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_4SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_4CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_COST_ASC',
  DealsStddevPopulationFeeAftermarket_4CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_COST_DESC',
  DealsStddevPopulationFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_5NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_5NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_5SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_5SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_5CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_COST_ASC',
  DealsStddevPopulationFeeAftermarket_5CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_COST_DESC',
  DealsStddevPopulationFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_6NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_6NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_6SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_6SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_6CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_COST_ASC',
  DealsStddevPopulationFeeAftermarket_6CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_COST_DESC',
  DealsStddevPopulationFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_7NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_7NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_7SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_7SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_7CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_COST_ASC',
  DealsStddevPopulationFeeAftermarket_7CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_COST_DESC',
  DealsStddevPopulationFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_8NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_8NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_8SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_8SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_8CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_COST_ASC',
  DealsStddevPopulationFeeAftermarket_8CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_COST_DESC',
  DealsStddevPopulationFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_9NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_9NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_9SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_9SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_9CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_COST_ASC',
  DealsStddevPopulationFeeAftermarket_9CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_COST_DESC',
  DealsStddevPopulationFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_10NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_10NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_10SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_10SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_10CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_COST_ASC',
  DealsStddevPopulationFeeAftermarket_10CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_COST_DESC',
  DealsStddevPopulationFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_11NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_11NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_11SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_11SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_11CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_COST_ASC',
  DealsStddevPopulationFeeAftermarket_11CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_COST_DESC',
  DealsStddevPopulationFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_12NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_12NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_12SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_12SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_12CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_COST_ASC',
  DealsStddevPopulationFeeAftermarket_12CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_COST_DESC',
  DealsStddevPopulationFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_13NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_13NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_13SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_13SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_13CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_COST_ASC',
  DealsStddevPopulationFeeAftermarket_13CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_COST_DESC',
  DealsStddevPopulationFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_14NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_14NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_14SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_14SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_14CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_COST_ASC',
  DealsStddevPopulationFeeAftermarket_14CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_COST_DESC',
  DealsStddevPopulationFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_15NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_15NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_15SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_15SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_15CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_COST_ASC',
  DealsStddevPopulationFeeAftermarket_15CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_COST_DESC',
  DealsStddevPopulationFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_16NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_16NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_16SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_16SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_16CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_COST_ASC',
  DealsStddevPopulationFeeAftermarket_16CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_COST_DESC',
  DealsStddevPopulationFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_17NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_17NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_17SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_17SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_17CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_COST_ASC',
  DealsStddevPopulationFeeAftermarket_17CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_COST_DESC',
  DealsStddevPopulationFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_18NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_18NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_18SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_18SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_18CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_COST_ASC',
  DealsStddevPopulationFeeAftermarket_18CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_COST_DESC',
  DealsStddevPopulationFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_19NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_19NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_19SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_19SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_19CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_COST_ASC',
  DealsStddevPopulationFeeAftermarket_19CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_COST_DESC',
  DealsStddevPopulationFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_20NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_20NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_20SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_20SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_20CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_COST_ASC',
  DealsStddevPopulationFeeAftermarket_20CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_COST_DESC',
  DealsStddevPopulationFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationInsurance_1TypeAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_TYPE_ASC',
  DealsStddevPopulationInsurance_1TypeDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_TYPE_DESC',
  DealsStddevPopulationInsurance_1NameAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_NAME_ASC',
  DealsStddevPopulationInsurance_1NameDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_NAME_DESC',
  DealsStddevPopulationInsurance_1SaleAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_SALE_ASC',
  DealsStddevPopulationInsurance_1SaleDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_SALE_DESC',
  DealsStddevPopulationInsurance_1CostAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_COST_ASC',
  DealsStddevPopulationInsurance_1CostDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_COST_DESC',
  DealsStddevPopulationInsurance_1TermAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_TERM_ASC',
  DealsStddevPopulationInsurance_1TermDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_TERM_DESC',
  DealsStddevPopulationInsurance_2TypeAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_TYPE_ASC',
  DealsStddevPopulationInsurance_2TypeDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_TYPE_DESC',
  DealsStddevPopulationInsurance_2NameAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_NAME_ASC',
  DealsStddevPopulationInsurance_2NameDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_NAME_DESC',
  DealsStddevPopulationInsurance_2SaleAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_SALE_ASC',
  DealsStddevPopulationInsurance_2SaleDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_SALE_DESC',
  DealsStddevPopulationInsurance_2CostAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_COST_ASC',
  DealsStddevPopulationInsurance_2CostDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_COST_DESC',
  DealsStddevPopulationInsurance_2TermAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_TERM_ASC',
  DealsStddevPopulationInsurance_2TermDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_TERM_DESC',
  DealsStddevPopulationInsurance_3TypeAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_TYPE_ASC',
  DealsStddevPopulationInsurance_3TypeDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_TYPE_DESC',
  DealsStddevPopulationInsurance_3NameAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_NAME_ASC',
  DealsStddevPopulationInsurance_3NameDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_NAME_DESC',
  DealsStddevPopulationInsurance_3SaleAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_SALE_ASC',
  DealsStddevPopulationInsurance_3SaleDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_SALE_DESC',
  DealsStddevPopulationInsurance_3CostAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_COST_ASC',
  DealsStddevPopulationInsurance_3CostDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_COST_DESC',
  DealsStddevPopulationInsurance_3TermAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_TERM_ASC',
  DealsStddevPopulationInsurance_3TermDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_TERM_DESC',
  DealsStddevPopulationAccidentalHealthTypeAsc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsStddevPopulationAccidentalHealthTypeDesc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsStddevPopulationAccidentalHealthNameAsc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsStddevPopulationAccidentalHealthNameDesc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsStddevPopulationAccidentalHealthSaleAsc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsStddevPopulationAccidentalHealthSaleDesc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsStddevPopulationAccidentalHealthCostAsc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_COST_ASC',
  DealsStddevPopulationAccidentalHealthCostDesc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_COST_DESC',
  DealsStddevPopulationAccidentalHealthTermAsc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsStddevPopulationAccidentalHealthTermDesc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsStddevPopulationCreditLifeTypeAsc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_TYPE_ASC',
  DealsStddevPopulationCreditLifeTypeDesc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_TYPE_DESC',
  DealsStddevPopulationCreditLifeNameAsc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_NAME_ASC',
  DealsStddevPopulationCreditLifeNameDesc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_NAME_DESC',
  DealsStddevPopulationCreditLifeSaleAsc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_SALE_ASC',
  DealsStddevPopulationCreditLifeSaleDesc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_SALE_DESC',
  DealsStddevPopulationCreditLifeCostAsc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_COST_ASC',
  DealsStddevPopulationCreditLifeCostDesc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_COST_DESC',
  DealsStddevPopulationCreditLifeTermAsc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_TERM_ASC',
  DealsStddevPopulationCreditLifeTermDesc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_TERM_DESC',
  DealsStddevPopulationLevelizedLifeTypeAsc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_TYPE_ASC',
  DealsStddevPopulationLevelizedLifeTypeDesc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_TYPE_DESC',
  DealsStddevPopulationLevelizedLifeNameAsc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_NAME_ASC',
  DealsStddevPopulationLevelizedLifeNameDesc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_NAME_DESC',
  DealsStddevPopulationLevelizedLifeSaleAsc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_SALE_ASC',
  DealsStddevPopulationLevelizedLifeSaleDesc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_SALE_DESC',
  DealsStddevPopulationLevelizedLifeCostAsc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_COST_ASC',
  DealsStddevPopulationLevelizedLifeCostDesc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_COST_DESC',
  DealsStddevPopulationLevelizedLifeTermAsc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_TERM_ASC',
  DealsStddevPopulationLevelizedLifeTermDesc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_TERM_DESC',
  DealsStddevPopulationLossOfEmploymentTypeAsc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsStddevPopulationLossOfEmploymentTypeDesc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsStddevPopulationLossOfEmploymentNameAsc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsStddevPopulationLossOfEmploymentNameDesc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsStddevPopulationLossOfEmploymentSaleAsc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsStddevPopulationLossOfEmploymentSaleDesc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsStddevPopulationLossOfEmploymentCostAsc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsStddevPopulationLossOfEmploymentCostDesc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsStddevPopulationLossOfEmploymentTermAsc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsStddevPopulationLossOfEmploymentTermDesc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsStddevPopulationGuaranteedAutoProtectionTypeAsc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsStddevPopulationGuaranteedAutoProtectionTypeDesc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsStddevPopulationGuaranteedAutoProtectionNameAsc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsStddevPopulationGuaranteedAutoProtectionNameDesc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsStddevPopulationGuaranteedAutoProtectionSaleAsc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsStddevPopulationGuaranteedAutoProtectionSaleDesc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsStddevPopulationGuaranteedAutoProtectionCostAsc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsStddevPopulationGuaranteedAutoProtectionCostDesc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsStddevPopulationGuaranteedAutoProtectionTermAsc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsStddevPopulationGuaranteedAutoProtectionTermDesc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsStddevPopulationSaleCommentsAsc = 'DEALS_STDDEV_POPULATION_SALE_COMMENTS_ASC',
  DealsStddevPopulationSaleCommentsDesc = 'DEALS_STDDEV_POPULATION_SALE_COMMENTS_DESC',
  DealsStddevPopulationCassStdLine1Asc = 'DEALS_STDDEV_POPULATION_CASS_STD_LINE1_ASC',
  DealsStddevPopulationCassStdLine1Desc = 'DEALS_STDDEV_POPULATION_CASS_STD_LINE1_DESC',
  DealsStddevPopulationCassStdLine2Asc = 'DEALS_STDDEV_POPULATION_CASS_STD_LINE2_ASC',
  DealsStddevPopulationCassStdLine2Desc = 'DEALS_STDDEV_POPULATION_CASS_STD_LINE2_DESC',
  DealsStddevPopulationCassStdCityAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_CITY_ASC',
  DealsStddevPopulationCassStdCityDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_CITY_DESC',
  DealsStddevPopulationCassStdStateAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_STATE_ASC',
  DealsStddevPopulationCassStdStateDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_STATE_DESC',
  DealsStddevPopulationCassStdZipAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIP_ASC',
  DealsStddevPopulationCassStdZipDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIP_DESC',
  DealsStddevPopulationCassStdZip4Asc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIP4_ASC',
  DealsStddevPopulationCassStdZip4Desc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIP4_DESC',
  DealsStddevPopulationCassStdDpbcAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_DPBC_ASC',
  DealsStddevPopulationCassStdDpbcDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_DPBC_DESC',
  DealsStddevPopulationCassStdChkdgtAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_CHKDGT_ASC',
  DealsStddevPopulationCassStdChkdgtDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_CHKDGT_DESC',
  DealsStddevPopulationCassStdCartAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_CART_ASC',
  DealsStddevPopulationCassStdCartDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_CART_DESC',
  DealsStddevPopulationCassStdLotAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_LOT_ASC',
  DealsStddevPopulationCassStdLotDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_LOT_DESC',
  DealsStddevPopulationCassStdLotordAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_LOTORD_ASC',
  DealsStddevPopulationCassStdLotordDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_LOTORD_DESC',
  DealsStddevPopulationCassStdUrbAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_URB_ASC',
  DealsStddevPopulationCassStdUrbDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_URB_DESC',
  DealsStddevPopulationCassStdFipsAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_FIPS_ASC',
  DealsStddevPopulationCassStdFipsDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_FIPS_DESC',
  DealsStddevPopulationCassStdEwsAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_EWS_ASC',
  DealsStddevPopulationCassStdEwsDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_EWS_DESC',
  DealsStddevPopulationCassStdLacsAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_LACS_ASC',
  DealsStddevPopulationCassStdLacsDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_LACS_DESC',
  DealsStddevPopulationCassStdZipmovAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIPMOV_ASC',
  DealsStddevPopulationCassStdZipmovDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIPMOV_DESC',
  DealsStddevPopulationCassStdZ4LomAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_Z4LOM_ASC',
  DealsStddevPopulationCassStdZ4LomDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_Z4LOM_DESC',
  DealsStddevPopulationCassStdNdiaptAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_NDIAPT_ASC',
  DealsStddevPopulationCassStdNdiaptDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_NDIAPT_DESC',
  DealsStddevPopulationCassStdNdirrAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_NDIRR_ASC',
  DealsStddevPopulationCassStdNdirrDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_NDIRR_DESC',
  DealsStddevPopulationCassStdLacsrtAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_LACSRT_ASC',
  DealsStddevPopulationCassStdLacsrtDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_LACSRT_DESC',
  DealsStddevPopulationCassStdErrorCdAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_ERROR_CD_ASC',
  DealsStddevPopulationCassStdErrorCdDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_ERROR_CD_DESC',
  DealsVarianceSampleIdAsc = 'DEALS_VARIANCE_SAMPLE_ID_ASC',
  DealsVarianceSampleIdDesc = 'DEALS_VARIANCE_SAMPLE_ID_DESC',
  DealsVarianceSampleLeadIdAsc = 'DEALS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  DealsVarianceSampleLeadIdDesc = 'DEALS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  DealsVarianceSampleCompanyIntegrationIdAsc = 'DEALS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  DealsVarianceSampleCompanyIntegrationIdDesc = 'DEALS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  DealsVarianceSampleFileTypeAsc = 'DEALS_VARIANCE_SAMPLE_FILE_TYPE_ASC',
  DealsVarianceSampleFileTypeDesc = 'DEALS_VARIANCE_SAMPLE_FILE_TYPE_DESC',
  DealsVarianceSampleDvDealerIdAsc = 'DEALS_VARIANCE_SAMPLE_DV_DEALER_ID_ASC',
  DealsVarianceSampleDvDealerIdDesc = 'DEALS_VARIANCE_SAMPLE_DV_DEALER_ID_DESC',
  DealsVarianceSampleVendorDealerIdAsc = 'DEALS_VARIANCE_SAMPLE_VENDOR_DEALER_ID_ASC',
  DealsVarianceSampleVendorDealerIdDesc = 'DEALS_VARIANCE_SAMPLE_VENDOR_DEALER_ID_DESC',
  DealsVarianceSampleDmsTypeAsc = 'DEALS_VARIANCE_SAMPLE_DMS_TYPE_ASC',
  DealsVarianceSampleDmsTypeDesc = 'DEALS_VARIANCE_SAMPLE_DMS_TYPE_DESC',
  DealsVarianceSampleDealNumberAsc = 'DEALS_VARIANCE_SAMPLE_DEAL_NUMBER_ASC',
  DealsVarianceSampleDealNumberDesc = 'DEALS_VARIANCE_SAMPLE_DEAL_NUMBER_DESC',
  DealsVarianceSampleCustomerNumberAsc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_NUMBER_ASC',
  DealsVarianceSampleCustomerNumberDesc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_NUMBER_DESC',
  DealsVarianceSampleFullNameAsc = 'DEALS_VARIANCE_SAMPLE_FULL_NAME_ASC',
  DealsVarianceSampleFullNameDesc = 'DEALS_VARIANCE_SAMPLE_FULL_NAME_DESC',
  DealsVarianceSampleSalutationAsc = 'DEALS_VARIANCE_SAMPLE_SALUTATION_ASC',
  DealsVarianceSampleSalutationDesc = 'DEALS_VARIANCE_SAMPLE_SALUTATION_DESC',
  DealsVarianceSampleFirstNameAsc = 'DEALS_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  DealsVarianceSampleFirstNameDesc = 'DEALS_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  DealsVarianceSampleMiddleNameAsc = 'DEALS_VARIANCE_SAMPLE_MIDDLE_NAME_ASC',
  DealsVarianceSampleMiddleNameDesc = 'DEALS_VARIANCE_SAMPLE_MIDDLE_NAME_DESC',
  DealsVarianceSampleLastNameAsc = 'DEALS_VARIANCE_SAMPLE_LAST_NAME_ASC',
  DealsVarianceSampleLastNameDesc = 'DEALS_VARIANCE_SAMPLE_LAST_NAME_DESC',
  DealsVarianceSampleSuffixAsc = 'DEALS_VARIANCE_SAMPLE_SUFFIX_ASC',
  DealsVarianceSampleSuffixDesc = 'DEALS_VARIANCE_SAMPLE_SUFFIX_DESC',
  DealsVarianceSampleAddressLine_1Asc = 'DEALS_VARIANCE_SAMPLE_ADDRESS_LINE_1_ASC',
  DealsVarianceSampleAddressLine_1Desc = 'DEALS_VARIANCE_SAMPLE_ADDRESS_LINE_1_DESC',
  DealsVarianceSampleAddressLine_2Asc = 'DEALS_VARIANCE_SAMPLE_ADDRESS_LINE_2_ASC',
  DealsVarianceSampleAddressLine_2Desc = 'DEALS_VARIANCE_SAMPLE_ADDRESS_LINE_2_DESC',
  DealsVarianceSampleCityAsc = 'DEALS_VARIANCE_SAMPLE_CITY_ASC',
  DealsVarianceSampleCityDesc = 'DEALS_VARIANCE_SAMPLE_CITY_DESC',
  DealsVarianceSampleStateAsc = 'DEALS_VARIANCE_SAMPLE_STATE_ASC',
  DealsVarianceSampleStateDesc = 'DEALS_VARIANCE_SAMPLE_STATE_DESC',
  DealsVarianceSampleZipAsc = 'DEALS_VARIANCE_SAMPLE_ZIP_ASC',
  DealsVarianceSampleZipDesc = 'DEALS_VARIANCE_SAMPLE_ZIP_DESC',
  DealsVarianceSampleCountyAsc = 'DEALS_VARIANCE_SAMPLE_COUNTY_ASC',
  DealsVarianceSampleCountyDesc = 'DEALS_VARIANCE_SAMPLE_COUNTY_DESC',
  DealsVarianceSampleHomePhoneAsc = 'DEALS_VARIANCE_SAMPLE_HOME_PHONE_ASC',
  DealsVarianceSampleHomePhoneDesc = 'DEALS_VARIANCE_SAMPLE_HOME_PHONE_DESC',
  DealsVarianceSampleCellPhoneAsc = 'DEALS_VARIANCE_SAMPLE_CELL_PHONE_ASC',
  DealsVarianceSampleCellPhoneDesc = 'DEALS_VARIANCE_SAMPLE_CELL_PHONE_DESC',
  DealsVarianceSampleWorkPhoneAsc = 'DEALS_VARIANCE_SAMPLE_WORK_PHONE_ASC',
  DealsVarianceSampleWorkPhoneDesc = 'DEALS_VARIANCE_SAMPLE_WORK_PHONE_DESC',
  DealsVarianceSampleWorkExtensionAsc = 'DEALS_VARIANCE_SAMPLE_WORK_EXTENSION_ASC',
  DealsVarianceSampleWorkExtensionDesc = 'DEALS_VARIANCE_SAMPLE_WORK_EXTENSION_DESC',
  DealsVarianceSampleEmail_1Asc = 'DEALS_VARIANCE_SAMPLE_EMAIL_1_ASC',
  DealsVarianceSampleEmail_1Desc = 'DEALS_VARIANCE_SAMPLE_EMAIL_1_DESC',
  DealsVarianceSampleEmail_2Asc = 'DEALS_VARIANCE_SAMPLE_EMAIL_2_ASC',
  DealsVarianceSampleEmail_2Desc = 'DEALS_VARIANCE_SAMPLE_EMAIL_2_DESC',
  DealsVarianceSampleEmail_3Asc = 'DEALS_VARIANCE_SAMPLE_EMAIL_3_ASC',
  DealsVarianceSampleEmail_3Desc = 'DEALS_VARIANCE_SAMPLE_EMAIL_3_DESC',
  DealsVarianceSampleBirthDateAsc = 'DEALS_VARIANCE_SAMPLE_BIRTH_DATE_ASC',
  DealsVarianceSampleBirthDateDesc = 'DEALS_VARIANCE_SAMPLE_BIRTH_DATE_DESC',
  DealsVarianceSampleIndividualBusinessFlagAsc = 'DEALS_VARIANCE_SAMPLE_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsVarianceSampleIndividualBusinessFlagDesc = 'DEALS_VARIANCE_SAMPLE_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsVarianceSampleOptOutAsc = 'DEALS_VARIANCE_SAMPLE_OPT_OUT_ASC',
  DealsVarianceSampleOptOutDesc = 'DEALS_VARIANCE_SAMPLE_OPT_OUT_DESC',
  DealsVarianceSampleBlockEmailAsc = 'DEALS_VARIANCE_SAMPLE_BLOCK_EMAIL_ASC',
  DealsVarianceSampleBlockEmailDesc = 'DEALS_VARIANCE_SAMPLE_BLOCK_EMAIL_DESC',
  DealsVarianceSampleBlockPhoneAsc = 'DEALS_VARIANCE_SAMPLE_BLOCK_PHONE_ASC',
  DealsVarianceSampleBlockPhoneDesc = 'DEALS_VARIANCE_SAMPLE_BLOCK_PHONE_DESC',
  DealsVarianceSampleBlockMailAsc = 'DEALS_VARIANCE_SAMPLE_BLOCK_MAIL_ASC',
  DealsVarianceSampleBlockMailDesc = 'DEALS_VARIANCE_SAMPLE_BLOCK_MAIL_DESC',
  DealsVarianceSampleLanguageAsc = 'DEALS_VARIANCE_SAMPLE_LANGUAGE_ASC',
  DealsVarianceSampleLanguageDesc = 'DEALS_VARIANCE_SAMPLE_LANGUAGE_DESC',
  DealsVarianceSampleCustomerCreateDateAsc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_CREATE_DATE_ASC',
  DealsVarianceSampleCustomerCreateDateDesc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_CREATE_DATE_DESC',
  DealsVarianceSampleCustomerLastActivityDateAsc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsVarianceSampleCustomerLastActivityDateDesc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsVarianceSampleCoBuyerCustomerNumberAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsVarianceSampleCoBuyerCustomerNumberDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsVarianceSampleCoBuyerFullNameAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_FULL_NAME_ASC',
  DealsVarianceSampleCoBuyerFullNameDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_FULL_NAME_DESC',
  DealsVarianceSampleCoBuyerSalutationAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_SALUTATION_ASC',
  DealsVarianceSampleCoBuyerSalutationDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_SALUTATION_DESC',
  DealsVarianceSampleCoBuyerFirstNameAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_FIRST_NAME_ASC',
  DealsVarianceSampleCoBuyerFirstNameDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_FIRST_NAME_DESC',
  DealsVarianceSampleCoBuyerMiddleNameAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_MIDDLE_NAME_ASC',
  DealsVarianceSampleCoBuyerMiddleNameDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_MIDDLE_NAME_DESC',
  DealsVarianceSampleCoBuyerLastNameAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_LAST_NAME_ASC',
  DealsVarianceSampleCoBuyerLastNameDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_LAST_NAME_DESC',
  DealsVarianceSampleCoBuyerSuffixAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_SUFFIX_ASC',
  DealsVarianceSampleCoBuyerSuffixDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_SUFFIX_DESC',
  DealsVarianceSampleCoBuyerAddressLine_1Asc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsVarianceSampleCoBuyerAddressLine_1Desc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsVarianceSampleCoBuyerAddressLine_2Asc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsVarianceSampleCoBuyerAddressLine_2Desc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsVarianceSampleCoBuyerCityAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CITY_ASC',
  DealsVarianceSampleCoBuyerCityDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CITY_DESC',
  DealsVarianceSampleCoBuyerStateAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_STATE_ASC',
  DealsVarianceSampleCoBuyerStateDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_STATE_DESC',
  DealsVarianceSampleCoBuyerZipAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ZIP_ASC',
  DealsVarianceSampleCoBuyerZipDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ZIP_DESC',
  DealsVarianceSampleCoBuyerCountyAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_COUNTY_ASC',
  DealsVarianceSampleCoBuyerCountyDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_COUNTY_DESC',
  DealsVarianceSampleCoBuyerHomePhoneAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_HOME_PHONE_ASC',
  DealsVarianceSampleCoBuyerHomePhoneDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_HOME_PHONE_DESC',
  DealsVarianceSampleCoBuyerCellPhoneAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CELL_PHONE_ASC',
  DealsVarianceSampleCoBuyerCellPhoneDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CELL_PHONE_DESC',
  DealsVarianceSampleCoBuyerWorkPhoneAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_WORK_PHONE_ASC',
  DealsVarianceSampleCoBuyerWorkPhoneDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_WORK_PHONE_DESC',
  DealsVarianceSampleCoBuyerWorkExtensionAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_WORK_EXTENSION_ASC',
  DealsVarianceSampleCoBuyerWorkExtensionDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_WORK_EXTENSION_DESC',
  DealsVarianceSampleCoBuyerEmail_1Asc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_1_ASC',
  DealsVarianceSampleCoBuyerEmail_1Desc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_1_DESC',
  DealsVarianceSampleCoBuyerEmail_2Asc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_2_ASC',
  DealsVarianceSampleCoBuyerEmail_2Desc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_2_DESC',
  DealsVarianceSampleCoBuyerEmail_3Asc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_3_ASC',
  DealsVarianceSampleCoBuyerEmail_3Desc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_3_DESC',
  DealsVarianceSampleCoBuyerBirthDateAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BIRTH_DATE_ASC',
  DealsVarianceSampleCoBuyerBirthDateDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BIRTH_DATE_DESC',
  DealsVarianceSampleCoBuyerIndividualBusinessFlagAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsVarianceSampleCoBuyerIndividualBusinessFlagDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsVarianceSampleCoBuyerOptOutAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_OPT_OUT_ASC',
  DealsVarianceSampleCoBuyerOptOutDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_OPT_OUT_DESC',
  DealsVarianceSampleCoBuyerBlockEmailAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsVarianceSampleCoBuyerBlockEmailDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsVarianceSampleCoBuyerBlockPhoneAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_PHONE_ASC',
  DealsVarianceSampleCoBuyerBlockPhoneDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_PHONE_DESC',
  DealsVarianceSampleCoBuyerBlockMailAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_MAIL_ASC',
  DealsVarianceSampleCoBuyerBlockMailDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_MAIL_DESC',
  DealsVarianceSampleVinAsc = 'DEALS_VARIANCE_SAMPLE_VIN_ASC',
  DealsVarianceSampleVinDesc = 'DEALS_VARIANCE_SAMPLE_VIN_DESC',
  DealsVarianceSampleYearAsc = 'DEALS_VARIANCE_SAMPLE_YEAR_ASC',
  DealsVarianceSampleYearDesc = 'DEALS_VARIANCE_SAMPLE_YEAR_DESC',
  DealsVarianceSampleMakeAsc = 'DEALS_VARIANCE_SAMPLE_MAKE_ASC',
  DealsVarianceSampleMakeDesc = 'DEALS_VARIANCE_SAMPLE_MAKE_DESC',
  DealsVarianceSampleModelAsc = 'DEALS_VARIANCE_SAMPLE_MODEL_ASC',
  DealsVarianceSampleModelDesc = 'DEALS_VARIANCE_SAMPLE_MODEL_DESC',
  DealsVarianceSampleModelNumberAsc = 'DEALS_VARIANCE_SAMPLE_MODEL_NUMBER_ASC',
  DealsVarianceSampleModelNumberDesc = 'DEALS_VARIANCE_SAMPLE_MODEL_NUMBER_DESC',
  DealsVarianceSampleMileageAsc = 'DEALS_VARIANCE_SAMPLE_MILEAGE_ASC',
  DealsVarianceSampleMileageDesc = 'DEALS_VARIANCE_SAMPLE_MILEAGE_DESC',
  DealsVarianceSampleDescriptionAsc = 'DEALS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  DealsVarianceSampleDescriptionDesc = 'DEALS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  DealsVarianceSampleExteriorColorAsc = 'DEALS_VARIANCE_SAMPLE_EXTERIOR_COLOR_ASC',
  DealsVarianceSampleExteriorColorDesc = 'DEALS_VARIANCE_SAMPLE_EXTERIOR_COLOR_DESC',
  DealsVarianceSampleNewUsedAsc = 'DEALS_VARIANCE_SAMPLE_NEW_USED_ASC',
  DealsVarianceSampleNewUsedDesc = 'DEALS_VARIANCE_SAMPLE_NEW_USED_DESC',
  DealsVarianceSampleStockNumberAsc = 'DEALS_VARIANCE_SAMPLE_STOCK_NUMBER_ASC',
  DealsVarianceSampleStockNumberDesc = 'DEALS_VARIANCE_SAMPLE_STOCK_NUMBER_DESC',
  DealsVarianceSampleTransmissionAsc = 'DEALS_VARIANCE_SAMPLE_TRANSMISSION_ASC',
  DealsVarianceSampleTransmissionDesc = 'DEALS_VARIANCE_SAMPLE_TRANSMISSION_DESC',
  DealsVarianceSampleEngineConfigurationAsc = 'DEALS_VARIANCE_SAMPLE_ENGINE_CONFIGURATION_ASC',
  DealsVarianceSampleEngineConfigurationDesc = 'DEALS_VARIANCE_SAMPLE_ENGINE_CONFIGURATION_DESC',
  DealsVarianceSampleTrimAsc = 'DEALS_VARIANCE_SAMPLE_TRIM_ASC',
  DealsVarianceSampleTrimDesc = 'DEALS_VARIANCE_SAMPLE_TRIM_DESC',
  DealsVarianceSampleEngineNumberAsc = 'DEALS_VARIANCE_SAMPLE_ENGINE_NUMBER_ASC',
  DealsVarianceSampleEngineNumberDesc = 'DEALS_VARIANCE_SAMPLE_ENGINE_NUMBER_DESC',
  DealsVarianceSampleChassisNumberAsc = 'DEALS_VARIANCE_SAMPLE_CHASSIS_NUMBER_ASC',
  DealsVarianceSampleChassisNumberDesc = 'DEALS_VARIANCE_SAMPLE_CHASSIS_NUMBER_DESC',
  DealsVarianceSampleLicensePlateNumberAsc = 'DEALS_VARIANCE_SAMPLE_LICENSE_PLATE_NUMBER_ASC',
  DealsVarianceSampleLicensePlateNumberDesc = 'DEALS_VARIANCE_SAMPLE_LICENSE_PLATE_NUMBER_DESC',
  DealsVarianceSampleDeliveryDateAsc = 'DEALS_VARIANCE_SAMPLE_DELIVERY_DATE_ASC',
  DealsVarianceSampleDeliveryDateDesc = 'DEALS_VARIANCE_SAMPLE_DELIVERY_DATE_DESC',
  DealsVarianceSampleDeliveryMileageAsc = 'DEALS_VARIANCE_SAMPLE_DELIVERY_MILEAGE_ASC',
  DealsVarianceSampleDeliveryMileageDesc = 'DEALS_VARIANCE_SAMPLE_DELIVERY_MILEAGE_DESC',
  DealsVarianceSampleInventoryDateAsc = 'DEALS_VARIANCE_SAMPLE_INVENTORY_DATE_ASC',
  DealsVarianceSampleInventoryDateDesc = 'DEALS_VARIANCE_SAMPLE_INVENTORY_DATE_DESC',
  DealsVarianceSampleInServiceDateAsc = 'DEALS_VARIANCE_SAMPLE_IN_SERVICE_DATE_ASC',
  DealsVarianceSampleInServiceDateDesc = 'DEALS_VARIANCE_SAMPLE_IN_SERVICE_DATE_DESC',
  DealsVarianceSampleVinExplosionYearAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_YEAR_ASC',
  DealsVarianceSampleVinExplosionYearDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_YEAR_DESC',
  DealsVarianceSampleVinExplosionMakeAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_MAKE_ASC',
  DealsVarianceSampleVinExplosionMakeDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_MAKE_DESC',
  DealsVarianceSampleVinExplosionModelAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_MODEL_ASC',
  DealsVarianceSampleVinExplosionModelDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_MODEL_DESC',
  DealsVarianceSampleVinExplosionTrimAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_TRIM_ASC',
  DealsVarianceSampleVinExplosionTrimDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_TRIM_DESC',
  DealsVarianceSampleVinExplosionTransmissionTypeAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsVarianceSampleVinExplosionTransmissionTypeDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsVarianceSampleVinExplosionFuelTypeAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsVarianceSampleVinExplosionFuelTypeDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsVarianceSampleVinExplosionEngineSizeAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsVarianceSampleVinExplosionEngineSizeDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsVarianceSampleVinExplosionGvwRangeAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsVarianceSampleVinExplosionGvwRangeDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsVarianceSampleTrade_1VinAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_VIN_ASC',
  DealsVarianceSampleTrade_1VinDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_VIN_DESC',
  DealsVarianceSampleTrade_1YearAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_YEAR_ASC',
  DealsVarianceSampleTrade_1YearDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_YEAR_DESC',
  DealsVarianceSampleTrade_1MakeAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_MAKE_ASC',
  DealsVarianceSampleTrade_1MakeDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_MAKE_DESC',
  DealsVarianceSampleTrade_1ModelAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_MODEL_ASC',
  DealsVarianceSampleTrade_1ModelDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_MODEL_DESC',
  DealsVarianceSampleTrade_1OdometerAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_ODOMETER_ASC',
  DealsVarianceSampleTrade_1OdometerDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_ODOMETER_DESC',
  DealsVarianceSampleTrade_1ActualCashValueAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsVarianceSampleTrade_1ActualCashValueDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsVarianceSampleTrade_1GrossAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_GROSS_ASC',
  DealsVarianceSampleTrade_1GrossDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_GROSS_DESC',
  DealsVarianceSampleTrade_1PayoffAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_PAYOFF_ASC',
  DealsVarianceSampleTrade_1PayoffDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_PAYOFF_DESC',
  DealsVarianceSampleTrade_2VinAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_VIN_ASC',
  DealsVarianceSampleTrade_2VinDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_VIN_DESC',
  DealsVarianceSampleTrade_2YearAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_YEAR_ASC',
  DealsVarianceSampleTrade_2YearDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_YEAR_DESC',
  DealsVarianceSampleTrade_2MakeAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_MAKE_ASC',
  DealsVarianceSampleTrade_2MakeDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_MAKE_DESC',
  DealsVarianceSampleTrade_2ModelAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_MODEL_ASC',
  DealsVarianceSampleTrade_2ModelDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_MODEL_DESC',
  DealsVarianceSampleTrade_2OdometerAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_ODOMETER_ASC',
  DealsVarianceSampleTrade_2OdometerDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_ODOMETER_DESC',
  DealsVarianceSampleTrade_2ActualCashValueAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsVarianceSampleTrade_2ActualCashValueDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsVarianceSampleTrade_2GrossAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_GROSS_ASC',
  DealsVarianceSampleTrade_2GrossDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_GROSS_DESC',
  DealsVarianceSampleTrade_2PayoffAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_PAYOFF_ASC',
  DealsVarianceSampleTrade_2PayoffDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_PAYOFF_DESC',
  DealsVarianceSampleSalesman_1NumberAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_NUMBER_ASC',
  DealsVarianceSampleSalesman_1NumberDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_NUMBER_DESC',
  DealsVarianceSampleSalesman_1NameAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_NAME_ASC',
  DealsVarianceSampleSalesman_1NameDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_NAME_DESC',
  DealsVarianceSampleSalesman_2NumberAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_NUMBER_ASC',
  DealsVarianceSampleSalesman_2NumberDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_NUMBER_DESC',
  DealsVarianceSampleSalesman_2NameAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_NAME_ASC',
  DealsVarianceSampleSalesman_2NameDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_NAME_DESC',
  DealsVarianceSampleSalesman_3NumberAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_NUMBER_ASC',
  DealsVarianceSampleSalesman_3NumberDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_NUMBER_DESC',
  DealsVarianceSampleSalesman_3NameAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_NAME_ASC',
  DealsVarianceSampleSalesman_3NameDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_NAME_DESC',
  DealsVarianceSampleClosingManagerNumberAsc = 'DEALS_VARIANCE_SAMPLE_CLOSING_MANAGER_NUMBER_ASC',
  DealsVarianceSampleClosingManagerNumberDesc = 'DEALS_VARIANCE_SAMPLE_CLOSING_MANAGER_NUMBER_DESC',
  DealsVarianceSampleClosingManagerNameAsc = 'DEALS_VARIANCE_SAMPLE_CLOSING_MANAGER_NAME_ASC',
  DealsVarianceSampleClosingManagerNameDesc = 'DEALS_VARIANCE_SAMPLE_CLOSING_MANAGER_NAME_DESC',
  DealsVarianceSampleFinanceManagerNumberAsc = 'DEALS_VARIANCE_SAMPLE_FINANCE_MANAGER_NUMBER_ASC',
  DealsVarianceSampleFinanceManagerNumberDesc = 'DEALS_VARIANCE_SAMPLE_FINANCE_MANAGER_NUMBER_DESC',
  DealsVarianceSampleFinanceManagerNameAsc = 'DEALS_VARIANCE_SAMPLE_FINANCE_MANAGER_NAME_ASC',
  DealsVarianceSampleFinanceManagerNameDesc = 'DEALS_VARIANCE_SAMPLE_FINANCE_MANAGER_NAME_DESC',
  DealsVarianceSampleSalesmanManagerNumberAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_MANAGER_NUMBER_ASC',
  DealsVarianceSampleSalesmanManagerNumberDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_MANAGER_NUMBER_DESC',
  DealsVarianceSampleSalesmanManagerNameAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_MANAGER_NAME_ASC',
  DealsVarianceSampleSalesmanManagerNameDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_MANAGER_NAME_DESC',
  DealsVarianceSampleMsrpAsc = 'DEALS_VARIANCE_SAMPLE_MSRP_ASC',
  DealsVarianceSampleMsrpDesc = 'DEALS_VARIANCE_SAMPLE_MSRP_DESC',
  DealsVarianceSampleListPriceAsc = 'DEALS_VARIANCE_SAMPLE_LIST_PRICE_ASC',
  DealsVarianceSampleListPriceDesc = 'DEALS_VARIANCE_SAMPLE_LIST_PRICE_DESC',
  DealsVarianceSampleSalesPriceAsc = 'DEALS_VARIANCE_SAMPLE_SALES_PRICE_ASC',
  DealsVarianceSampleSalesPriceDesc = 'DEALS_VARIANCE_SAMPLE_SALES_PRICE_DESC',
  DealsVarianceSampleJournalPriceAsc = 'DEALS_VARIANCE_SAMPLE_JOURNAL_PRICE_ASC',
  DealsVarianceSampleJournalPriceDesc = 'DEALS_VARIANCE_SAMPLE_JOURNAL_PRICE_DESC',
  DealsVarianceSampleCostAsc = 'DEALS_VARIANCE_SAMPLE_COST_ASC',
  DealsVarianceSampleCostDesc = 'DEALS_VARIANCE_SAMPLE_COST_DESC',
  DealsVarianceSampleJournalCostAsc = 'DEALS_VARIANCE_SAMPLE_JOURNAL_COST_ASC',
  DealsVarianceSampleJournalCostDesc = 'DEALS_VARIANCE_SAMPLE_JOURNAL_COST_DESC',
  DealsVarianceSampleAdjustmentsAsc = 'DEALS_VARIANCE_SAMPLE_ADJUSTMENTS_ASC',
  DealsVarianceSampleAdjustmentsDesc = 'DEALS_VARIANCE_SAMPLE_ADJUSTMENTS_DESC',
  DealsVarianceSampleAdjustedCostAsc = 'DEALS_VARIANCE_SAMPLE_ADJUSTED_COST_ASC',
  DealsVarianceSampleAdjustedCostDesc = 'DEALS_VARIANCE_SAMPLE_ADJUSTED_COST_DESC',
  DealsVarianceSampleIncentivesAsc = 'DEALS_VARIANCE_SAMPLE_INCENTIVES_ASC',
  DealsVarianceSampleIncentivesDesc = 'DEALS_VARIANCE_SAMPLE_INCENTIVES_DESC',
  DealsVarianceSamplePackAmountAsc = 'DEALS_VARIANCE_SAMPLE_PACK_AMOUNT_ASC',
  DealsVarianceSamplePackAmountDesc = 'DEALS_VARIANCE_SAMPLE_PACK_AMOUNT_DESC',
  DealsVarianceSampleSaleNetAsc = 'DEALS_VARIANCE_SAMPLE_SALE_NET_ASC',
  DealsVarianceSampleSaleNetDesc = 'DEALS_VARIANCE_SAMPLE_SALE_NET_DESC',
  DealsVarianceSampleTotalTradeActualCashValueAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsVarianceSampleTotalTradeActualCashValueDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsVarianceSampleTotalTradeGrossAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TRADE_GROSS_ASC',
  DealsVarianceSampleTotalTradeGrossDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TRADE_GROSS_DESC',
  DealsVarianceSampleWeOweFrontAsc = 'DEALS_VARIANCE_SAMPLE_WE_OWE_FRONT_ASC',
  DealsVarianceSampleWeOweFrontDesc = 'DEALS_VARIANCE_SAMPLE_WE_OWE_FRONT_DESC',
  DealsVarianceSampleTotalFrontFeesAftermarketProfitAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsVarianceSampleTotalFrontFeesAftermarketProfitDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsVarianceSampleTotalFrontCommissionAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_COMMISSION_ASC',
  DealsVarianceSampleTotalFrontCommissionDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_COMMISSION_DESC',
  DealsVarianceSampleTotalFrontSalesAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_SALES_ASC',
  DealsVarianceSampleTotalFrontSalesDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_SALES_DESC',
  DealsVarianceSampleTotalFrontCostAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_COST_ASC',
  DealsVarianceSampleTotalFrontCostDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_COST_DESC',
  DealsVarianceSampleFrontGrossAsc = 'DEALS_VARIANCE_SAMPLE_FRONT_GROSS_ASC',
  DealsVarianceSampleFrontGrossDesc = 'DEALS_VARIANCE_SAMPLE_FRONT_GROSS_DESC',
  DealsVarianceSampleFinanceProfitAsc = 'DEALS_VARIANCE_SAMPLE_FINANCE_PROFIT_ASC',
  DealsVarianceSampleFinanceProfitDesc = 'DEALS_VARIANCE_SAMPLE_FINANCE_PROFIT_DESC',
  DealsVarianceSampleTotalWarrantyProfitAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_WARRANTY_PROFIT_ASC',
  DealsVarianceSampleTotalWarrantyProfitDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_WARRANTY_PROFIT_DESC',
  DealsVarianceSampleWeOweBackAsc = 'DEALS_VARIANCE_SAMPLE_WE_OWE_BACK_ASC',
  DealsVarianceSampleWeOweBackDesc = 'DEALS_VARIANCE_SAMPLE_WE_OWE_BACK_DESC',
  DealsVarianceSampleInsuranceProfitAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_PROFIT_ASC',
  DealsVarianceSampleInsuranceProfitDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_PROFIT_DESC',
  DealsVarianceSampleTotalBackFeesAftermarketProfitAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsVarianceSampleTotalBackFeesAftermarketProfitDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsVarianceSampleFinanceReserveAsc = 'DEALS_VARIANCE_SAMPLE_FINANCE_RESERVE_ASC',
  DealsVarianceSampleFinanceReserveDesc = 'DEALS_VARIANCE_SAMPLE_FINANCE_RESERVE_DESC',
  DealsVarianceSampleTotalBackCommissionAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_COMMISSION_ASC',
  DealsVarianceSampleTotalBackCommissionDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_COMMISSION_DESC',
  DealsVarianceSampleTotalBackSalesAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_SALES_ASC',
  DealsVarianceSampleTotalBackSalesDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_SALES_DESC',
  DealsVarianceSampleTotalBackCostAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_COST_ASC',
  DealsVarianceSampleTotalBackCostDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_COST_DESC',
  DealsVarianceSampleBackGrossAsc = 'DEALS_VARIANCE_SAMPLE_BACK_GROSS_ASC',
  DealsVarianceSampleBackGrossDesc = 'DEALS_VARIANCE_SAMPLE_BACK_GROSS_DESC',
  DealsVarianceSampleTotalProfitAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_PROFIT_ASC',
  DealsVarianceSampleTotalProfitDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_PROFIT_DESC',
  DealsVarianceSampleGrossProfitAsc = 'DEALS_VARIANCE_SAMPLE_GROSS_PROFIT_ASC',
  DealsVarianceSampleGrossProfitDesc = 'DEALS_VARIANCE_SAMPLE_GROSS_PROFIT_DESC',
  DealsVarianceSampleGrossPayableAsc = 'DEALS_VARIANCE_SAMPLE_GROSS_PAYABLE_ASC',
  DealsVarianceSampleGrossPayableDesc = 'DEALS_VARIANCE_SAMPLE_GROSS_PAYABLE_DESC',
  DealsVarianceSampleDealStatusAsc = 'DEALS_VARIANCE_SAMPLE_DEAL_STATUS_ASC',
  DealsVarianceSampleDealStatusDesc = 'DEALS_VARIANCE_SAMPLE_DEAL_STATUS_DESC',
  DealsVarianceSampleEntryDateAsc = 'DEALS_VARIANCE_SAMPLE_ENTRY_DATE_ASC',
  DealsVarianceSampleEntryDateDesc = 'DEALS_VARIANCE_SAMPLE_ENTRY_DATE_DESC',
  DealsVarianceSampleBookedDateAsc = 'DEALS_VARIANCE_SAMPLE_BOOKED_DATE_ASC',
  DealsVarianceSampleBookedDateDesc = 'DEALS_VARIANCE_SAMPLE_BOOKED_DATE_DESC',
  DealsVarianceSampleFinalizedDateAsc = 'DEALS_VARIANCE_SAMPLE_FINALIZED_DATE_ASC',
  DealsVarianceSampleFinalizedDateDesc = 'DEALS_VARIANCE_SAMPLE_FINALIZED_DATE_DESC',
  DealsVarianceSampleContractDateAsc = 'DEALS_VARIANCE_SAMPLE_CONTRACT_DATE_ASC',
  DealsVarianceSampleContractDateDesc = 'DEALS_VARIANCE_SAMPLE_CONTRACT_DATE_DESC',
  DealsVarianceSampleAccountingDateAsc = 'DEALS_VARIANCE_SAMPLE_ACCOUNTING_DATE_ASC',
  DealsVarianceSampleAccountingDateDesc = 'DEALS_VARIANCE_SAMPLE_ACCOUNTING_DATE_DESC',
  DealsVarianceSampleStatusChangeDateAsc = 'DEALS_VARIANCE_SAMPLE_STATUS_CHANGE_DATE_ASC',
  DealsVarianceSampleStatusChangeDateDesc = 'DEALS_VARIANCE_SAMPLE_STATUS_CHANGE_DATE_DESC',
  DealsVarianceSampleFirstPayDateAsc = 'DEALS_VARIANCE_SAMPLE_FIRST_PAY_DATE_ASC',
  DealsVarianceSampleFirstPayDateDesc = 'DEALS_VARIANCE_SAMPLE_FIRST_PAY_DATE_DESC',
  DealsVarianceSampleDealTypeAsc = 'DEALS_VARIANCE_SAMPLE_DEAL_TYPE_ASC',
  DealsVarianceSampleDealTypeDesc = 'DEALS_VARIANCE_SAMPLE_DEAL_TYPE_DESC',
  DealsVarianceSampleSaleTypeAsc = 'DEALS_VARIANCE_SAMPLE_SALE_TYPE_ASC',
  DealsVarianceSampleSaleTypeDesc = 'DEALS_VARIANCE_SAMPLE_SALE_TYPE_DESC',
  DealsVarianceSampleBankIdAsc = 'DEALS_VARIANCE_SAMPLE_BANK_ID_ASC',
  DealsVarianceSampleBankIdDesc = 'DEALS_VARIANCE_SAMPLE_BANK_ID_DESC',
  DealsVarianceSampleBankNameAsc = 'DEALS_VARIANCE_SAMPLE_BANK_NAME_ASC',
  DealsVarianceSampleBankNameDesc = 'DEALS_VARIANCE_SAMPLE_BANK_NAME_DESC',
  DealsVarianceSampleBankAddressAsc = 'DEALS_VARIANCE_SAMPLE_BANK_ADDRESS_ASC',
  DealsVarianceSampleBankAddressDesc = 'DEALS_VARIANCE_SAMPLE_BANK_ADDRESS_DESC',
  DealsVarianceSampleTermAsc = 'DEALS_VARIANCE_SAMPLE_TERM_ASC',
  DealsVarianceSampleTermDesc = 'DEALS_VARIANCE_SAMPLE_TERM_DESC',
  DealsVarianceSampleAmountFinancedAsc = 'DEALS_VARIANCE_SAMPLE_AMOUNT_FINANCED_ASC',
  DealsVarianceSampleAmountFinancedDesc = 'DEALS_VARIANCE_SAMPLE_AMOUNT_FINANCED_DESC',
  DealsVarianceSampleAprAsc = 'DEALS_VARIANCE_SAMPLE_APR_ASC',
  DealsVarianceSampleAprDesc = 'DEALS_VARIANCE_SAMPLE_APR_DESC',
  DealsVarianceSampleMonthlyPaymentAsc = 'DEALS_VARIANCE_SAMPLE_MONTHLY_PAYMENT_ASC',
  DealsVarianceSampleMonthlyPaymentDesc = 'DEALS_VARIANCE_SAMPLE_MONTHLY_PAYMENT_DESC',
  DealsVarianceSamplePaymentTotalAsc = 'DEALS_VARIANCE_SAMPLE_PAYMENT_TOTAL_ASC',
  DealsVarianceSamplePaymentTotalDesc = 'DEALS_VARIANCE_SAMPLE_PAYMENT_TOTAL_DESC',
  DealsVarianceSampleRebatesAsc = 'DEALS_VARIANCE_SAMPLE_REBATES_ASC',
  DealsVarianceSampleRebatesDesc = 'DEALS_VARIANCE_SAMPLE_REBATES_DESC',
  DealsVarianceSampleDepositAsc = 'DEALS_VARIANCE_SAMPLE_DEPOSIT_ASC',
  DealsVarianceSampleDepositDesc = 'DEALS_VARIANCE_SAMPLE_DEPOSIT_DESC',
  DealsVarianceSampleDownPaymentAsc = 'DEALS_VARIANCE_SAMPLE_DOWN_PAYMENT_ASC',
  DealsVarianceSampleDownPaymentDesc = 'DEALS_VARIANCE_SAMPLE_DOWN_PAYMENT_DESC',
  DealsVarianceSampleTotalNetTradesAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_NET_TRADES_ASC',
  DealsVarianceSampleTotalNetTradesDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_NET_TRADES_DESC',
  DealsVarianceSampleTotalDownAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_DOWN_ASC',
  DealsVarianceSampleTotalDownDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_DOWN_DESC',
  DealsVarianceSampleBalloonAmountAsc = 'DEALS_VARIANCE_SAMPLE_BALLOON_AMOUNT_ASC',
  DealsVarianceSampleBalloonAmountDesc = 'DEALS_VARIANCE_SAMPLE_BALLOON_AMOUNT_DESC',
  DealsVarianceSampleAdjustedBalloonAmountAsc = 'DEALS_VARIANCE_SAMPLE_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsVarianceSampleAdjustedBalloonAmountDesc = 'DEALS_VARIANCE_SAMPLE_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsVarianceSampleHoldbackAmountAsc = 'DEALS_VARIANCE_SAMPLE_HOLDBACK_AMOUNT_ASC',
  DealsVarianceSampleHoldbackAmountDesc = 'DEALS_VARIANCE_SAMPLE_HOLDBACK_AMOUNT_DESC',
  DealsVarianceSampleTotalDriveOffAmountAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsVarianceSampleTotalDriveOffAmountDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsVarianceSampleLicenseFeeAsc = 'DEALS_VARIANCE_SAMPLE_LICENSE_FEE_ASC',
  DealsVarianceSampleLicenseFeeDesc = 'DEALS_VARIANCE_SAMPLE_LICENSE_FEE_DESC',
  DealsVarianceSampleRegistrationFeeAsc = 'DEALS_VARIANCE_SAMPLE_REGISTRATION_FEE_ASC',
  DealsVarianceSampleRegistrationFeeDesc = 'DEALS_VARIANCE_SAMPLE_REGISTRATION_FEE_DESC',
  DealsVarianceSampleDocumentationFeeAsc = 'DEALS_VARIANCE_SAMPLE_DOCUMENTATION_FEE_ASC',
  DealsVarianceSampleDocumentationFeeDesc = 'DEALS_VARIANCE_SAMPLE_DOCUMENTATION_FEE_DESC',
  DealsVarianceSampleFinanceChargeAsc = 'DEALS_VARIANCE_SAMPLE_FINANCE_CHARGE_ASC',
  DealsVarianceSampleFinanceChargeDesc = 'DEALS_VARIANCE_SAMPLE_FINANCE_CHARGE_DESC',
  DealsVarianceSampleTotalPickupPaymentsAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsVarianceSampleTotalPickupPaymentsDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsVarianceSampleSellRateAsc = 'DEALS_VARIANCE_SAMPLE_SELL_RATE_ASC',
  DealsVarianceSampleSellRateDesc = 'DEALS_VARIANCE_SAMPLE_SELL_RATE_DESC',
  DealsVarianceSampleBuyRateAsc = 'DEALS_VARIANCE_SAMPLE_BUY_RATE_ASC',
  DealsVarianceSampleBuyRateDesc = 'DEALS_VARIANCE_SAMPLE_BUY_RATE_DESC',
  DealsVarianceSampleResidualRateAsc = 'DEALS_VARIANCE_SAMPLE_RESIDUAL_RATE_ASC',
  DealsVarianceSampleResidualRateDesc = 'DEALS_VARIANCE_SAMPLE_RESIDUAL_RATE_DESC',
  DealsVarianceSampleResidualAmountAsc = 'DEALS_VARIANCE_SAMPLE_RESIDUAL_AMOUNT_ASC',
  DealsVarianceSampleResidualAmountDesc = 'DEALS_VARIANCE_SAMPLE_RESIDUAL_AMOUNT_DESC',
  DealsVarianceSampleAllowedMilesAsc = 'DEALS_VARIANCE_SAMPLE_ALLOWED_MILES_ASC',
  DealsVarianceSampleAllowedMilesDesc = 'DEALS_VARIANCE_SAMPLE_ALLOWED_MILES_DESC',
  DealsVarianceSampleEstimatedMilesAsc = 'DEALS_VARIANCE_SAMPLE_ESTIMATED_MILES_ASC',
  DealsVarianceSampleEstimatedMilesDesc = 'DEALS_VARIANCE_SAMPLE_ESTIMATED_MILES_DESC',
  DealsVarianceSampleMileageRateAsc = 'DEALS_VARIANCE_SAMPLE_MILEAGE_RATE_ASC',
  DealsVarianceSampleMileageRateDesc = 'DEALS_VARIANCE_SAMPLE_MILEAGE_RATE_DESC',
  DealsVarianceSampleAcquisitionFeeAsc = 'DEALS_VARIANCE_SAMPLE_ACQUISITION_FEE_ASC',
  DealsVarianceSampleAcquisitionFeeDesc = 'DEALS_VARIANCE_SAMPLE_ACQUISITION_FEE_DESC',
  DealsVarianceSampleBasePaymentAsc = 'DEALS_VARIANCE_SAMPLE_BASE_PAYMENT_ASC',
  DealsVarianceSampleBasePaymentDesc = 'DEALS_VARIANCE_SAMPLE_BASE_PAYMENT_DESC',
  DealsVarianceSampleSecurityDepositAsc = 'DEALS_VARIANCE_SAMPLE_SECURITY_DEPOSIT_ASC',
  DealsVarianceSampleSecurityDepositDesc = 'DEALS_VARIANCE_SAMPLE_SECURITY_DEPOSIT_DESC',
  DealsVarianceSampleTotalCapitalReductionAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsVarianceSampleTotalCapitalReductionDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsVarianceSampleNetCapitalCostAsc = 'DEALS_VARIANCE_SAMPLE_NET_CAPITAL_COST_ASC',
  DealsVarianceSampleNetCapitalCostDesc = 'DEALS_VARIANCE_SAMPLE_NET_CAPITAL_COST_DESC',
  DealsVarianceSampleLeaseDepreciationValueAsc = 'DEALS_VARIANCE_SAMPLE_LEASE_DEPRECIATION_VALUE_ASC',
  DealsVarianceSampleLeaseDepreciationValueDesc = 'DEALS_VARIANCE_SAMPLE_LEASE_DEPRECIATION_VALUE_DESC',
  DealsVarianceSampleDealerFeesAsc = 'DEALS_VARIANCE_SAMPLE_DEALER_FEES_ASC',
  DealsVarianceSampleDealerFeesDesc = 'DEALS_VARIANCE_SAMPLE_DEALER_FEES_DESC',
  DealsVarianceSampleGovernmentFeesAsc = 'DEALS_VARIANCE_SAMPLE_GOVERNMENT_FEES_ASC',
  DealsVarianceSampleGovernmentFeesDesc = 'DEALS_VARIANCE_SAMPLE_GOVERNMENT_FEES_DESC',
  DealsVarianceSampleTotalTaxAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TAX_ASC',
  DealsVarianceSampleTotalTaxDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TAX_DESC',
  DealsVarianceSampleRegistrationStateAsc = 'DEALS_VARIANCE_SAMPLE_REGISTRATION_STATE_ASC',
  DealsVarianceSampleRegistrationStateDesc = 'DEALS_VARIANCE_SAMPLE_REGISTRATION_STATE_DESC',
  DealsVarianceSampleReportOfSaleNumberAsc = 'DEALS_VARIANCE_SAMPLE_REPORT_OF_SALE_NUMBER_ASC',
  DealsVarianceSampleReportOfSaleNumberDesc = 'DEALS_VARIANCE_SAMPLE_REPORT_OF_SALE_NUMBER_DESC',
  DealsVarianceSampleSalesman_1TotalCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsVarianceSampleSalesman_1TotalCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsVarianceSampleSalesman_1FrontCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsVarianceSampleSalesman_1FrontCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsVarianceSampleSalesman_1BackCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsVarianceSampleSalesman_1BackCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsVarianceSampleSalesman_2TotalCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsVarianceSampleSalesman_2TotalCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsVarianceSampleSalesman_2FrontCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsVarianceSampleSalesman_2FrontCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsVarianceSampleSalesman_2BackCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsVarianceSampleSalesman_2BackCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsVarianceSampleSalesman_3TotalCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsVarianceSampleSalesman_3TotalCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsVarianceSampleSalesman_3FrontCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsVarianceSampleSalesman_3FrontCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsVarianceSampleSalesman_3BackCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsVarianceSampleSalesman_3BackCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsVarianceSampleWarranty_1NameAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_NAME_ASC',
  DealsVarianceSampleWarranty_1NameDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_NAME_DESC',
  DealsVarianceSampleWarranty_1SaleAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_SALE_ASC',
  DealsVarianceSampleWarranty_1SaleDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_SALE_DESC',
  DealsVarianceSampleWarranty_1CostAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_COST_ASC',
  DealsVarianceSampleWarranty_1CostDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_COST_DESC',
  DealsVarianceSampleWarranty_1MilesAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_MILES_ASC',
  DealsVarianceSampleWarranty_1MilesDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_MILES_DESC',
  DealsVarianceSampleWarranty_1TermAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_TERM_ASC',
  DealsVarianceSampleWarranty_1TermDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_TERM_DESC',
  DealsVarianceSampleWarranty_2NameAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_NAME_ASC',
  DealsVarianceSampleWarranty_2NameDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_NAME_DESC',
  DealsVarianceSampleWarranty_2SaleAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_SALE_ASC',
  DealsVarianceSampleWarranty_2SaleDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_SALE_DESC',
  DealsVarianceSampleWarranty_2CostAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_COST_ASC',
  DealsVarianceSampleWarranty_2CostDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_COST_DESC',
  DealsVarianceSampleWarranty_2MilesAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_MILES_ASC',
  DealsVarianceSampleWarranty_2MilesDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_MILES_DESC',
  DealsVarianceSampleWarranty_2TermAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_TERM_ASC',
  DealsVarianceSampleWarranty_2TermDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_TERM_DESC',
  DealsVarianceSampleWarranty_3NameAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_NAME_ASC',
  DealsVarianceSampleWarranty_3NameDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_NAME_DESC',
  DealsVarianceSampleWarranty_3SaleAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_SALE_ASC',
  DealsVarianceSampleWarranty_3SaleDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_SALE_DESC',
  DealsVarianceSampleWarranty_3CostAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_COST_ASC',
  DealsVarianceSampleWarranty_3CostDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_COST_DESC',
  DealsVarianceSampleWarranty_3MilesAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_MILES_ASC',
  DealsVarianceSampleWarranty_3MilesDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_MILES_DESC',
  DealsVarianceSampleWarranty_3TermAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_TERM_ASC',
  DealsVarianceSampleWarranty_3TermDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_TERM_DESC',
  DealsVarianceSampleWarranty_4NameAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_NAME_ASC',
  DealsVarianceSampleWarranty_4NameDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_NAME_DESC',
  DealsVarianceSampleWarranty_4SaleAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_SALE_ASC',
  DealsVarianceSampleWarranty_4SaleDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_SALE_DESC',
  DealsVarianceSampleWarranty_4CostAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_COST_ASC',
  DealsVarianceSampleWarranty_4CostDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_COST_DESC',
  DealsVarianceSampleWarranty_4MilesAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_MILES_ASC',
  DealsVarianceSampleWarranty_4MilesDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_MILES_DESC',
  DealsVarianceSampleWarranty_4TermAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_TERM_ASC',
  DealsVarianceSampleWarranty_4TermDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_TERM_DESC',
  DealsVarianceSampleWarranty_5NameAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_NAME_ASC',
  DealsVarianceSampleWarranty_5NameDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_NAME_DESC',
  DealsVarianceSampleWarranty_5SaleAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_SALE_ASC',
  DealsVarianceSampleWarranty_5SaleDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_SALE_DESC',
  DealsVarianceSampleWarranty_5CostAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_COST_ASC',
  DealsVarianceSampleWarranty_5CostDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_COST_DESC',
  DealsVarianceSampleWarranty_5MilesAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_MILES_ASC',
  DealsVarianceSampleWarranty_5MilesDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_MILES_DESC',
  DealsVarianceSampleWarranty_5TermAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_TERM_ASC',
  DealsVarianceSampleWarranty_5TermDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_TERM_DESC',
  DealsVarianceSampleTotalFeeAftermarketSaleAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsVarianceSampleTotalFeeAftermarketSaleDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsVarianceSampleTotalFeeAftermarketCostAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsVarianceSampleTotalFeeAftermarketCostDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsVarianceSampleFeeAftermarket_1NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_1NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_1SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_1SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_1CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_COST_ASC',
  DealsVarianceSampleFeeAftermarket_1CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_COST_DESC',
  DealsVarianceSampleFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_2NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_2NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_2SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_2SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_2CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_COST_ASC',
  DealsVarianceSampleFeeAftermarket_2CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_COST_DESC',
  DealsVarianceSampleFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_3NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_3NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_3SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_3SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_3CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_COST_ASC',
  DealsVarianceSampleFeeAftermarket_3CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_COST_DESC',
  DealsVarianceSampleFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_4NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_4NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_4SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_4SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_4CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_COST_ASC',
  DealsVarianceSampleFeeAftermarket_4CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_COST_DESC',
  DealsVarianceSampleFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_5NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_5NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_5SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_5SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_5CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_COST_ASC',
  DealsVarianceSampleFeeAftermarket_5CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_COST_DESC',
  DealsVarianceSampleFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_6NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_6NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_6SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_6SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_6CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_COST_ASC',
  DealsVarianceSampleFeeAftermarket_6CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_COST_DESC',
  DealsVarianceSampleFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_7NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_7NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_7SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_7SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_7CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_COST_ASC',
  DealsVarianceSampleFeeAftermarket_7CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_COST_DESC',
  DealsVarianceSampleFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_8NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_8NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_8SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_8SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_8CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_COST_ASC',
  DealsVarianceSampleFeeAftermarket_8CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_COST_DESC',
  DealsVarianceSampleFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_9NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_9NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_9SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_9SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_9CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_COST_ASC',
  DealsVarianceSampleFeeAftermarket_9CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_COST_DESC',
  DealsVarianceSampleFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_10NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_10NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_10SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_10SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_10CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_COST_ASC',
  DealsVarianceSampleFeeAftermarket_10CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_COST_DESC',
  DealsVarianceSampleFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_11NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_11NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_11SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_11SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_11CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_COST_ASC',
  DealsVarianceSampleFeeAftermarket_11CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_COST_DESC',
  DealsVarianceSampleFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_12NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_12NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_12SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_12SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_12CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_COST_ASC',
  DealsVarianceSampleFeeAftermarket_12CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_COST_DESC',
  DealsVarianceSampleFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_13NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_13NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_13SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_13SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_13CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_COST_ASC',
  DealsVarianceSampleFeeAftermarket_13CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_COST_DESC',
  DealsVarianceSampleFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_14NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_14NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_14SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_14SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_14CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_COST_ASC',
  DealsVarianceSampleFeeAftermarket_14CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_COST_DESC',
  DealsVarianceSampleFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_15NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_15NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_15SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_15SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_15CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_COST_ASC',
  DealsVarianceSampleFeeAftermarket_15CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_COST_DESC',
  DealsVarianceSampleFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_16NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_16NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_16SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_16SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_16CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_COST_ASC',
  DealsVarianceSampleFeeAftermarket_16CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_COST_DESC',
  DealsVarianceSampleFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_17NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_17NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_17SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_17SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_17CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_COST_ASC',
  DealsVarianceSampleFeeAftermarket_17CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_COST_DESC',
  DealsVarianceSampleFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_18NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_18NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_18SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_18SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_18CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_COST_ASC',
  DealsVarianceSampleFeeAftermarket_18CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_COST_DESC',
  DealsVarianceSampleFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_19NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_19NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_19SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_19SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_19CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_COST_ASC',
  DealsVarianceSampleFeeAftermarket_19CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_COST_DESC',
  DealsVarianceSampleFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_20NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_20NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_20SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_20SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_20CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_COST_ASC',
  DealsVarianceSampleFeeAftermarket_20CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_COST_DESC',
  DealsVarianceSampleFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleInsurance_1TypeAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_TYPE_ASC',
  DealsVarianceSampleInsurance_1TypeDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_TYPE_DESC',
  DealsVarianceSampleInsurance_1NameAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_NAME_ASC',
  DealsVarianceSampleInsurance_1NameDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_NAME_DESC',
  DealsVarianceSampleInsurance_1SaleAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_SALE_ASC',
  DealsVarianceSampleInsurance_1SaleDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_SALE_DESC',
  DealsVarianceSampleInsurance_1CostAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_COST_ASC',
  DealsVarianceSampleInsurance_1CostDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_COST_DESC',
  DealsVarianceSampleInsurance_1TermAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_TERM_ASC',
  DealsVarianceSampleInsurance_1TermDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_TERM_DESC',
  DealsVarianceSampleInsurance_2TypeAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_TYPE_ASC',
  DealsVarianceSampleInsurance_2TypeDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_TYPE_DESC',
  DealsVarianceSampleInsurance_2NameAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_NAME_ASC',
  DealsVarianceSampleInsurance_2NameDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_NAME_DESC',
  DealsVarianceSampleInsurance_2SaleAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_SALE_ASC',
  DealsVarianceSampleInsurance_2SaleDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_SALE_DESC',
  DealsVarianceSampleInsurance_2CostAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_COST_ASC',
  DealsVarianceSampleInsurance_2CostDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_COST_DESC',
  DealsVarianceSampleInsurance_2TermAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_TERM_ASC',
  DealsVarianceSampleInsurance_2TermDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_TERM_DESC',
  DealsVarianceSampleInsurance_3TypeAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_TYPE_ASC',
  DealsVarianceSampleInsurance_3TypeDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_TYPE_DESC',
  DealsVarianceSampleInsurance_3NameAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_NAME_ASC',
  DealsVarianceSampleInsurance_3NameDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_NAME_DESC',
  DealsVarianceSampleInsurance_3SaleAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_SALE_ASC',
  DealsVarianceSampleInsurance_3SaleDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_SALE_DESC',
  DealsVarianceSampleInsurance_3CostAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_COST_ASC',
  DealsVarianceSampleInsurance_3CostDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_COST_DESC',
  DealsVarianceSampleInsurance_3TermAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_TERM_ASC',
  DealsVarianceSampleInsurance_3TermDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_TERM_DESC',
  DealsVarianceSampleAccidentalHealthTypeAsc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsVarianceSampleAccidentalHealthTypeDesc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsVarianceSampleAccidentalHealthNameAsc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsVarianceSampleAccidentalHealthNameDesc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsVarianceSampleAccidentalHealthSaleAsc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsVarianceSampleAccidentalHealthSaleDesc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsVarianceSampleAccidentalHealthCostAsc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_COST_ASC',
  DealsVarianceSampleAccidentalHealthCostDesc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_COST_DESC',
  DealsVarianceSampleAccidentalHealthTermAsc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsVarianceSampleAccidentalHealthTermDesc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsVarianceSampleCreditLifeTypeAsc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_TYPE_ASC',
  DealsVarianceSampleCreditLifeTypeDesc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_TYPE_DESC',
  DealsVarianceSampleCreditLifeNameAsc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_NAME_ASC',
  DealsVarianceSampleCreditLifeNameDesc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_NAME_DESC',
  DealsVarianceSampleCreditLifeSaleAsc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_SALE_ASC',
  DealsVarianceSampleCreditLifeSaleDesc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_SALE_DESC',
  DealsVarianceSampleCreditLifeCostAsc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_COST_ASC',
  DealsVarianceSampleCreditLifeCostDesc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_COST_DESC',
  DealsVarianceSampleCreditLifeTermAsc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_TERM_ASC',
  DealsVarianceSampleCreditLifeTermDesc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_TERM_DESC',
  DealsVarianceSampleLevelizedLifeTypeAsc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_TYPE_ASC',
  DealsVarianceSampleLevelizedLifeTypeDesc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_TYPE_DESC',
  DealsVarianceSampleLevelizedLifeNameAsc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_NAME_ASC',
  DealsVarianceSampleLevelizedLifeNameDesc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_NAME_DESC',
  DealsVarianceSampleLevelizedLifeSaleAsc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_SALE_ASC',
  DealsVarianceSampleLevelizedLifeSaleDesc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_SALE_DESC',
  DealsVarianceSampleLevelizedLifeCostAsc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_COST_ASC',
  DealsVarianceSampleLevelizedLifeCostDesc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_COST_DESC',
  DealsVarianceSampleLevelizedLifeTermAsc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_TERM_ASC',
  DealsVarianceSampleLevelizedLifeTermDesc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_TERM_DESC',
  DealsVarianceSampleLossOfEmploymentTypeAsc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsVarianceSampleLossOfEmploymentTypeDesc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsVarianceSampleLossOfEmploymentNameAsc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsVarianceSampleLossOfEmploymentNameDesc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsVarianceSampleLossOfEmploymentSaleAsc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsVarianceSampleLossOfEmploymentSaleDesc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsVarianceSampleLossOfEmploymentCostAsc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsVarianceSampleLossOfEmploymentCostDesc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsVarianceSampleLossOfEmploymentTermAsc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsVarianceSampleLossOfEmploymentTermDesc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsVarianceSampleGuaranteedAutoProtectionTypeAsc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsVarianceSampleGuaranteedAutoProtectionTypeDesc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsVarianceSampleGuaranteedAutoProtectionNameAsc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsVarianceSampleGuaranteedAutoProtectionNameDesc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsVarianceSampleGuaranteedAutoProtectionSaleAsc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsVarianceSampleGuaranteedAutoProtectionSaleDesc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsVarianceSampleGuaranteedAutoProtectionCostAsc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsVarianceSampleGuaranteedAutoProtectionCostDesc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsVarianceSampleGuaranteedAutoProtectionTermAsc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsVarianceSampleGuaranteedAutoProtectionTermDesc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsVarianceSampleSaleCommentsAsc = 'DEALS_VARIANCE_SAMPLE_SALE_COMMENTS_ASC',
  DealsVarianceSampleSaleCommentsDesc = 'DEALS_VARIANCE_SAMPLE_SALE_COMMENTS_DESC',
  DealsVarianceSampleCassStdLine1Asc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LINE1_ASC',
  DealsVarianceSampleCassStdLine1Desc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LINE1_DESC',
  DealsVarianceSampleCassStdLine2Asc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LINE2_ASC',
  DealsVarianceSampleCassStdLine2Desc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LINE2_DESC',
  DealsVarianceSampleCassStdCityAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CITY_ASC',
  DealsVarianceSampleCassStdCityDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CITY_DESC',
  DealsVarianceSampleCassStdStateAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_STATE_ASC',
  DealsVarianceSampleCassStdStateDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_STATE_DESC',
  DealsVarianceSampleCassStdZipAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIP_ASC',
  DealsVarianceSampleCassStdZipDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIP_DESC',
  DealsVarianceSampleCassStdZip4Asc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIP4_ASC',
  DealsVarianceSampleCassStdZip4Desc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIP4_DESC',
  DealsVarianceSampleCassStdDpbcAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_DPBC_ASC',
  DealsVarianceSampleCassStdDpbcDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_DPBC_DESC',
  DealsVarianceSampleCassStdChkdgtAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CHKDGT_ASC',
  DealsVarianceSampleCassStdChkdgtDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CHKDGT_DESC',
  DealsVarianceSampleCassStdCartAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CART_ASC',
  DealsVarianceSampleCassStdCartDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CART_DESC',
  DealsVarianceSampleCassStdLotAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LOT_ASC',
  DealsVarianceSampleCassStdLotDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LOT_DESC',
  DealsVarianceSampleCassStdLotordAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LOTORD_ASC',
  DealsVarianceSampleCassStdLotordDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LOTORD_DESC',
  DealsVarianceSampleCassStdUrbAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_URB_ASC',
  DealsVarianceSampleCassStdUrbDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_URB_DESC',
  DealsVarianceSampleCassStdFipsAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_FIPS_ASC',
  DealsVarianceSampleCassStdFipsDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_FIPS_DESC',
  DealsVarianceSampleCassStdEwsAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_EWS_ASC',
  DealsVarianceSampleCassStdEwsDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_EWS_DESC',
  DealsVarianceSampleCassStdLacsAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LACS_ASC',
  DealsVarianceSampleCassStdLacsDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LACS_DESC',
  DealsVarianceSampleCassStdZipmovAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIPMOV_ASC',
  DealsVarianceSampleCassStdZipmovDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIPMOV_DESC',
  DealsVarianceSampleCassStdZ4LomAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_Z4LOM_ASC',
  DealsVarianceSampleCassStdZ4LomDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_Z4LOM_DESC',
  DealsVarianceSampleCassStdNdiaptAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_NDIAPT_ASC',
  DealsVarianceSampleCassStdNdiaptDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_NDIAPT_DESC',
  DealsVarianceSampleCassStdNdirrAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_NDIRR_ASC',
  DealsVarianceSampleCassStdNdirrDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_NDIRR_DESC',
  DealsVarianceSampleCassStdLacsrtAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LACSRT_ASC',
  DealsVarianceSampleCassStdLacsrtDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LACSRT_DESC',
  DealsVarianceSampleCassStdErrorCdAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ERROR_CD_ASC',
  DealsVarianceSampleCassStdErrorCdDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ERROR_CD_DESC',
  DealsVariancePopulationIdAsc = 'DEALS_VARIANCE_POPULATION_ID_ASC',
  DealsVariancePopulationIdDesc = 'DEALS_VARIANCE_POPULATION_ID_DESC',
  DealsVariancePopulationLeadIdAsc = 'DEALS_VARIANCE_POPULATION_LEAD_ID_ASC',
  DealsVariancePopulationLeadIdDesc = 'DEALS_VARIANCE_POPULATION_LEAD_ID_DESC',
  DealsVariancePopulationCompanyIntegrationIdAsc = 'DEALS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  DealsVariancePopulationCompanyIntegrationIdDesc = 'DEALS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  DealsVariancePopulationFileTypeAsc = 'DEALS_VARIANCE_POPULATION_FILE_TYPE_ASC',
  DealsVariancePopulationFileTypeDesc = 'DEALS_VARIANCE_POPULATION_FILE_TYPE_DESC',
  DealsVariancePopulationDvDealerIdAsc = 'DEALS_VARIANCE_POPULATION_DV_DEALER_ID_ASC',
  DealsVariancePopulationDvDealerIdDesc = 'DEALS_VARIANCE_POPULATION_DV_DEALER_ID_DESC',
  DealsVariancePopulationVendorDealerIdAsc = 'DEALS_VARIANCE_POPULATION_VENDOR_DEALER_ID_ASC',
  DealsVariancePopulationVendorDealerIdDesc = 'DEALS_VARIANCE_POPULATION_VENDOR_DEALER_ID_DESC',
  DealsVariancePopulationDmsTypeAsc = 'DEALS_VARIANCE_POPULATION_DMS_TYPE_ASC',
  DealsVariancePopulationDmsTypeDesc = 'DEALS_VARIANCE_POPULATION_DMS_TYPE_DESC',
  DealsVariancePopulationDealNumberAsc = 'DEALS_VARIANCE_POPULATION_DEAL_NUMBER_ASC',
  DealsVariancePopulationDealNumberDesc = 'DEALS_VARIANCE_POPULATION_DEAL_NUMBER_DESC',
  DealsVariancePopulationCustomerNumberAsc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_NUMBER_ASC',
  DealsVariancePopulationCustomerNumberDesc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_NUMBER_DESC',
  DealsVariancePopulationFullNameAsc = 'DEALS_VARIANCE_POPULATION_FULL_NAME_ASC',
  DealsVariancePopulationFullNameDesc = 'DEALS_VARIANCE_POPULATION_FULL_NAME_DESC',
  DealsVariancePopulationSalutationAsc = 'DEALS_VARIANCE_POPULATION_SALUTATION_ASC',
  DealsVariancePopulationSalutationDesc = 'DEALS_VARIANCE_POPULATION_SALUTATION_DESC',
  DealsVariancePopulationFirstNameAsc = 'DEALS_VARIANCE_POPULATION_FIRST_NAME_ASC',
  DealsVariancePopulationFirstNameDesc = 'DEALS_VARIANCE_POPULATION_FIRST_NAME_DESC',
  DealsVariancePopulationMiddleNameAsc = 'DEALS_VARIANCE_POPULATION_MIDDLE_NAME_ASC',
  DealsVariancePopulationMiddleNameDesc = 'DEALS_VARIANCE_POPULATION_MIDDLE_NAME_DESC',
  DealsVariancePopulationLastNameAsc = 'DEALS_VARIANCE_POPULATION_LAST_NAME_ASC',
  DealsVariancePopulationLastNameDesc = 'DEALS_VARIANCE_POPULATION_LAST_NAME_DESC',
  DealsVariancePopulationSuffixAsc = 'DEALS_VARIANCE_POPULATION_SUFFIX_ASC',
  DealsVariancePopulationSuffixDesc = 'DEALS_VARIANCE_POPULATION_SUFFIX_DESC',
  DealsVariancePopulationAddressLine_1Asc = 'DEALS_VARIANCE_POPULATION_ADDRESS_LINE_1_ASC',
  DealsVariancePopulationAddressLine_1Desc = 'DEALS_VARIANCE_POPULATION_ADDRESS_LINE_1_DESC',
  DealsVariancePopulationAddressLine_2Asc = 'DEALS_VARIANCE_POPULATION_ADDRESS_LINE_2_ASC',
  DealsVariancePopulationAddressLine_2Desc = 'DEALS_VARIANCE_POPULATION_ADDRESS_LINE_2_DESC',
  DealsVariancePopulationCityAsc = 'DEALS_VARIANCE_POPULATION_CITY_ASC',
  DealsVariancePopulationCityDesc = 'DEALS_VARIANCE_POPULATION_CITY_DESC',
  DealsVariancePopulationStateAsc = 'DEALS_VARIANCE_POPULATION_STATE_ASC',
  DealsVariancePopulationStateDesc = 'DEALS_VARIANCE_POPULATION_STATE_DESC',
  DealsVariancePopulationZipAsc = 'DEALS_VARIANCE_POPULATION_ZIP_ASC',
  DealsVariancePopulationZipDesc = 'DEALS_VARIANCE_POPULATION_ZIP_DESC',
  DealsVariancePopulationCountyAsc = 'DEALS_VARIANCE_POPULATION_COUNTY_ASC',
  DealsVariancePopulationCountyDesc = 'DEALS_VARIANCE_POPULATION_COUNTY_DESC',
  DealsVariancePopulationHomePhoneAsc = 'DEALS_VARIANCE_POPULATION_HOME_PHONE_ASC',
  DealsVariancePopulationHomePhoneDesc = 'DEALS_VARIANCE_POPULATION_HOME_PHONE_DESC',
  DealsVariancePopulationCellPhoneAsc = 'DEALS_VARIANCE_POPULATION_CELL_PHONE_ASC',
  DealsVariancePopulationCellPhoneDesc = 'DEALS_VARIANCE_POPULATION_CELL_PHONE_DESC',
  DealsVariancePopulationWorkPhoneAsc = 'DEALS_VARIANCE_POPULATION_WORK_PHONE_ASC',
  DealsVariancePopulationWorkPhoneDesc = 'DEALS_VARIANCE_POPULATION_WORK_PHONE_DESC',
  DealsVariancePopulationWorkExtensionAsc = 'DEALS_VARIANCE_POPULATION_WORK_EXTENSION_ASC',
  DealsVariancePopulationWorkExtensionDesc = 'DEALS_VARIANCE_POPULATION_WORK_EXTENSION_DESC',
  DealsVariancePopulationEmail_1Asc = 'DEALS_VARIANCE_POPULATION_EMAIL_1_ASC',
  DealsVariancePopulationEmail_1Desc = 'DEALS_VARIANCE_POPULATION_EMAIL_1_DESC',
  DealsVariancePopulationEmail_2Asc = 'DEALS_VARIANCE_POPULATION_EMAIL_2_ASC',
  DealsVariancePopulationEmail_2Desc = 'DEALS_VARIANCE_POPULATION_EMAIL_2_DESC',
  DealsVariancePopulationEmail_3Asc = 'DEALS_VARIANCE_POPULATION_EMAIL_3_ASC',
  DealsVariancePopulationEmail_3Desc = 'DEALS_VARIANCE_POPULATION_EMAIL_3_DESC',
  DealsVariancePopulationBirthDateAsc = 'DEALS_VARIANCE_POPULATION_BIRTH_DATE_ASC',
  DealsVariancePopulationBirthDateDesc = 'DEALS_VARIANCE_POPULATION_BIRTH_DATE_DESC',
  DealsVariancePopulationIndividualBusinessFlagAsc = 'DEALS_VARIANCE_POPULATION_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsVariancePopulationIndividualBusinessFlagDesc = 'DEALS_VARIANCE_POPULATION_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsVariancePopulationOptOutAsc = 'DEALS_VARIANCE_POPULATION_OPT_OUT_ASC',
  DealsVariancePopulationOptOutDesc = 'DEALS_VARIANCE_POPULATION_OPT_OUT_DESC',
  DealsVariancePopulationBlockEmailAsc = 'DEALS_VARIANCE_POPULATION_BLOCK_EMAIL_ASC',
  DealsVariancePopulationBlockEmailDesc = 'DEALS_VARIANCE_POPULATION_BLOCK_EMAIL_DESC',
  DealsVariancePopulationBlockPhoneAsc = 'DEALS_VARIANCE_POPULATION_BLOCK_PHONE_ASC',
  DealsVariancePopulationBlockPhoneDesc = 'DEALS_VARIANCE_POPULATION_BLOCK_PHONE_DESC',
  DealsVariancePopulationBlockMailAsc = 'DEALS_VARIANCE_POPULATION_BLOCK_MAIL_ASC',
  DealsVariancePopulationBlockMailDesc = 'DEALS_VARIANCE_POPULATION_BLOCK_MAIL_DESC',
  DealsVariancePopulationLanguageAsc = 'DEALS_VARIANCE_POPULATION_LANGUAGE_ASC',
  DealsVariancePopulationLanguageDesc = 'DEALS_VARIANCE_POPULATION_LANGUAGE_DESC',
  DealsVariancePopulationCustomerCreateDateAsc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_CREATE_DATE_ASC',
  DealsVariancePopulationCustomerCreateDateDesc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_CREATE_DATE_DESC',
  DealsVariancePopulationCustomerLastActivityDateAsc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsVariancePopulationCustomerLastActivityDateDesc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsVariancePopulationCoBuyerCustomerNumberAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsVariancePopulationCoBuyerCustomerNumberDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsVariancePopulationCoBuyerFullNameAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_FULL_NAME_ASC',
  DealsVariancePopulationCoBuyerFullNameDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_FULL_NAME_DESC',
  DealsVariancePopulationCoBuyerSalutationAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_SALUTATION_ASC',
  DealsVariancePopulationCoBuyerSalutationDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_SALUTATION_DESC',
  DealsVariancePopulationCoBuyerFirstNameAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_FIRST_NAME_ASC',
  DealsVariancePopulationCoBuyerFirstNameDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_FIRST_NAME_DESC',
  DealsVariancePopulationCoBuyerMiddleNameAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_MIDDLE_NAME_ASC',
  DealsVariancePopulationCoBuyerMiddleNameDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_MIDDLE_NAME_DESC',
  DealsVariancePopulationCoBuyerLastNameAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_LAST_NAME_ASC',
  DealsVariancePopulationCoBuyerLastNameDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_LAST_NAME_DESC',
  DealsVariancePopulationCoBuyerSuffixAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_SUFFIX_ASC',
  DealsVariancePopulationCoBuyerSuffixDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_SUFFIX_DESC',
  DealsVariancePopulationCoBuyerAddressLine_1Asc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsVariancePopulationCoBuyerAddressLine_1Desc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsVariancePopulationCoBuyerAddressLine_2Asc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsVariancePopulationCoBuyerAddressLine_2Desc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsVariancePopulationCoBuyerCityAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CITY_ASC',
  DealsVariancePopulationCoBuyerCityDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CITY_DESC',
  DealsVariancePopulationCoBuyerStateAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_STATE_ASC',
  DealsVariancePopulationCoBuyerStateDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_STATE_DESC',
  DealsVariancePopulationCoBuyerZipAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ZIP_ASC',
  DealsVariancePopulationCoBuyerZipDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ZIP_DESC',
  DealsVariancePopulationCoBuyerCountyAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_COUNTY_ASC',
  DealsVariancePopulationCoBuyerCountyDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_COUNTY_DESC',
  DealsVariancePopulationCoBuyerHomePhoneAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_HOME_PHONE_ASC',
  DealsVariancePopulationCoBuyerHomePhoneDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_HOME_PHONE_DESC',
  DealsVariancePopulationCoBuyerCellPhoneAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CELL_PHONE_ASC',
  DealsVariancePopulationCoBuyerCellPhoneDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CELL_PHONE_DESC',
  DealsVariancePopulationCoBuyerWorkPhoneAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_WORK_PHONE_ASC',
  DealsVariancePopulationCoBuyerWorkPhoneDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_WORK_PHONE_DESC',
  DealsVariancePopulationCoBuyerWorkExtensionAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_WORK_EXTENSION_ASC',
  DealsVariancePopulationCoBuyerWorkExtensionDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_WORK_EXTENSION_DESC',
  DealsVariancePopulationCoBuyerEmail_1Asc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_1_ASC',
  DealsVariancePopulationCoBuyerEmail_1Desc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_1_DESC',
  DealsVariancePopulationCoBuyerEmail_2Asc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_2_ASC',
  DealsVariancePopulationCoBuyerEmail_2Desc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_2_DESC',
  DealsVariancePopulationCoBuyerEmail_3Asc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_3_ASC',
  DealsVariancePopulationCoBuyerEmail_3Desc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_3_DESC',
  DealsVariancePopulationCoBuyerBirthDateAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BIRTH_DATE_ASC',
  DealsVariancePopulationCoBuyerBirthDateDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BIRTH_DATE_DESC',
  DealsVariancePopulationCoBuyerIndividualBusinessFlagAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsVariancePopulationCoBuyerIndividualBusinessFlagDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsVariancePopulationCoBuyerOptOutAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_OPT_OUT_ASC',
  DealsVariancePopulationCoBuyerOptOutDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_OPT_OUT_DESC',
  DealsVariancePopulationCoBuyerBlockEmailAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsVariancePopulationCoBuyerBlockEmailDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsVariancePopulationCoBuyerBlockPhoneAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_PHONE_ASC',
  DealsVariancePopulationCoBuyerBlockPhoneDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_PHONE_DESC',
  DealsVariancePopulationCoBuyerBlockMailAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_MAIL_ASC',
  DealsVariancePopulationCoBuyerBlockMailDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_MAIL_DESC',
  DealsVariancePopulationVinAsc = 'DEALS_VARIANCE_POPULATION_VIN_ASC',
  DealsVariancePopulationVinDesc = 'DEALS_VARIANCE_POPULATION_VIN_DESC',
  DealsVariancePopulationYearAsc = 'DEALS_VARIANCE_POPULATION_YEAR_ASC',
  DealsVariancePopulationYearDesc = 'DEALS_VARIANCE_POPULATION_YEAR_DESC',
  DealsVariancePopulationMakeAsc = 'DEALS_VARIANCE_POPULATION_MAKE_ASC',
  DealsVariancePopulationMakeDesc = 'DEALS_VARIANCE_POPULATION_MAKE_DESC',
  DealsVariancePopulationModelAsc = 'DEALS_VARIANCE_POPULATION_MODEL_ASC',
  DealsVariancePopulationModelDesc = 'DEALS_VARIANCE_POPULATION_MODEL_DESC',
  DealsVariancePopulationModelNumberAsc = 'DEALS_VARIANCE_POPULATION_MODEL_NUMBER_ASC',
  DealsVariancePopulationModelNumberDesc = 'DEALS_VARIANCE_POPULATION_MODEL_NUMBER_DESC',
  DealsVariancePopulationMileageAsc = 'DEALS_VARIANCE_POPULATION_MILEAGE_ASC',
  DealsVariancePopulationMileageDesc = 'DEALS_VARIANCE_POPULATION_MILEAGE_DESC',
  DealsVariancePopulationDescriptionAsc = 'DEALS_VARIANCE_POPULATION_DESCRIPTION_ASC',
  DealsVariancePopulationDescriptionDesc = 'DEALS_VARIANCE_POPULATION_DESCRIPTION_DESC',
  DealsVariancePopulationExteriorColorAsc = 'DEALS_VARIANCE_POPULATION_EXTERIOR_COLOR_ASC',
  DealsVariancePopulationExteriorColorDesc = 'DEALS_VARIANCE_POPULATION_EXTERIOR_COLOR_DESC',
  DealsVariancePopulationNewUsedAsc = 'DEALS_VARIANCE_POPULATION_NEW_USED_ASC',
  DealsVariancePopulationNewUsedDesc = 'DEALS_VARIANCE_POPULATION_NEW_USED_DESC',
  DealsVariancePopulationStockNumberAsc = 'DEALS_VARIANCE_POPULATION_STOCK_NUMBER_ASC',
  DealsVariancePopulationStockNumberDesc = 'DEALS_VARIANCE_POPULATION_STOCK_NUMBER_DESC',
  DealsVariancePopulationTransmissionAsc = 'DEALS_VARIANCE_POPULATION_TRANSMISSION_ASC',
  DealsVariancePopulationTransmissionDesc = 'DEALS_VARIANCE_POPULATION_TRANSMISSION_DESC',
  DealsVariancePopulationEngineConfigurationAsc = 'DEALS_VARIANCE_POPULATION_ENGINE_CONFIGURATION_ASC',
  DealsVariancePopulationEngineConfigurationDesc = 'DEALS_VARIANCE_POPULATION_ENGINE_CONFIGURATION_DESC',
  DealsVariancePopulationTrimAsc = 'DEALS_VARIANCE_POPULATION_TRIM_ASC',
  DealsVariancePopulationTrimDesc = 'DEALS_VARIANCE_POPULATION_TRIM_DESC',
  DealsVariancePopulationEngineNumberAsc = 'DEALS_VARIANCE_POPULATION_ENGINE_NUMBER_ASC',
  DealsVariancePopulationEngineNumberDesc = 'DEALS_VARIANCE_POPULATION_ENGINE_NUMBER_DESC',
  DealsVariancePopulationChassisNumberAsc = 'DEALS_VARIANCE_POPULATION_CHASSIS_NUMBER_ASC',
  DealsVariancePopulationChassisNumberDesc = 'DEALS_VARIANCE_POPULATION_CHASSIS_NUMBER_DESC',
  DealsVariancePopulationLicensePlateNumberAsc = 'DEALS_VARIANCE_POPULATION_LICENSE_PLATE_NUMBER_ASC',
  DealsVariancePopulationLicensePlateNumberDesc = 'DEALS_VARIANCE_POPULATION_LICENSE_PLATE_NUMBER_DESC',
  DealsVariancePopulationDeliveryDateAsc = 'DEALS_VARIANCE_POPULATION_DELIVERY_DATE_ASC',
  DealsVariancePopulationDeliveryDateDesc = 'DEALS_VARIANCE_POPULATION_DELIVERY_DATE_DESC',
  DealsVariancePopulationDeliveryMileageAsc = 'DEALS_VARIANCE_POPULATION_DELIVERY_MILEAGE_ASC',
  DealsVariancePopulationDeliveryMileageDesc = 'DEALS_VARIANCE_POPULATION_DELIVERY_MILEAGE_DESC',
  DealsVariancePopulationInventoryDateAsc = 'DEALS_VARIANCE_POPULATION_INVENTORY_DATE_ASC',
  DealsVariancePopulationInventoryDateDesc = 'DEALS_VARIANCE_POPULATION_INVENTORY_DATE_DESC',
  DealsVariancePopulationInServiceDateAsc = 'DEALS_VARIANCE_POPULATION_IN_SERVICE_DATE_ASC',
  DealsVariancePopulationInServiceDateDesc = 'DEALS_VARIANCE_POPULATION_IN_SERVICE_DATE_DESC',
  DealsVariancePopulationVinExplosionYearAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_YEAR_ASC',
  DealsVariancePopulationVinExplosionYearDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_YEAR_DESC',
  DealsVariancePopulationVinExplosionMakeAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_MAKE_ASC',
  DealsVariancePopulationVinExplosionMakeDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_MAKE_DESC',
  DealsVariancePopulationVinExplosionModelAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_MODEL_ASC',
  DealsVariancePopulationVinExplosionModelDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_MODEL_DESC',
  DealsVariancePopulationVinExplosionTrimAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_TRIM_ASC',
  DealsVariancePopulationVinExplosionTrimDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_TRIM_DESC',
  DealsVariancePopulationVinExplosionTransmissionTypeAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsVariancePopulationVinExplosionTransmissionTypeDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsVariancePopulationVinExplosionFuelTypeAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsVariancePopulationVinExplosionFuelTypeDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsVariancePopulationVinExplosionEngineSizeAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsVariancePopulationVinExplosionEngineSizeDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsVariancePopulationVinExplosionGvwRangeAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsVariancePopulationVinExplosionGvwRangeDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsVariancePopulationTrade_1VinAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_VIN_ASC',
  DealsVariancePopulationTrade_1VinDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_VIN_DESC',
  DealsVariancePopulationTrade_1YearAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_YEAR_ASC',
  DealsVariancePopulationTrade_1YearDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_YEAR_DESC',
  DealsVariancePopulationTrade_1MakeAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_MAKE_ASC',
  DealsVariancePopulationTrade_1MakeDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_MAKE_DESC',
  DealsVariancePopulationTrade_1ModelAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_MODEL_ASC',
  DealsVariancePopulationTrade_1ModelDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_MODEL_DESC',
  DealsVariancePopulationTrade_1OdometerAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_ODOMETER_ASC',
  DealsVariancePopulationTrade_1OdometerDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_ODOMETER_DESC',
  DealsVariancePopulationTrade_1ActualCashValueAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsVariancePopulationTrade_1ActualCashValueDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsVariancePopulationTrade_1GrossAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_GROSS_ASC',
  DealsVariancePopulationTrade_1GrossDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_GROSS_DESC',
  DealsVariancePopulationTrade_1PayoffAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_PAYOFF_ASC',
  DealsVariancePopulationTrade_1PayoffDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_PAYOFF_DESC',
  DealsVariancePopulationTrade_2VinAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_VIN_ASC',
  DealsVariancePopulationTrade_2VinDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_VIN_DESC',
  DealsVariancePopulationTrade_2YearAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_YEAR_ASC',
  DealsVariancePopulationTrade_2YearDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_YEAR_DESC',
  DealsVariancePopulationTrade_2MakeAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_MAKE_ASC',
  DealsVariancePopulationTrade_2MakeDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_MAKE_DESC',
  DealsVariancePopulationTrade_2ModelAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_MODEL_ASC',
  DealsVariancePopulationTrade_2ModelDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_MODEL_DESC',
  DealsVariancePopulationTrade_2OdometerAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_ODOMETER_ASC',
  DealsVariancePopulationTrade_2OdometerDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_ODOMETER_DESC',
  DealsVariancePopulationTrade_2ActualCashValueAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsVariancePopulationTrade_2ActualCashValueDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsVariancePopulationTrade_2GrossAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_GROSS_ASC',
  DealsVariancePopulationTrade_2GrossDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_GROSS_DESC',
  DealsVariancePopulationTrade_2PayoffAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_PAYOFF_ASC',
  DealsVariancePopulationTrade_2PayoffDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_PAYOFF_DESC',
  DealsVariancePopulationSalesman_1NumberAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_NUMBER_ASC',
  DealsVariancePopulationSalesman_1NumberDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_NUMBER_DESC',
  DealsVariancePopulationSalesman_1NameAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_NAME_ASC',
  DealsVariancePopulationSalesman_1NameDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_NAME_DESC',
  DealsVariancePopulationSalesman_2NumberAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_NUMBER_ASC',
  DealsVariancePopulationSalesman_2NumberDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_NUMBER_DESC',
  DealsVariancePopulationSalesman_2NameAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_NAME_ASC',
  DealsVariancePopulationSalesman_2NameDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_NAME_DESC',
  DealsVariancePopulationSalesman_3NumberAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_NUMBER_ASC',
  DealsVariancePopulationSalesman_3NumberDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_NUMBER_DESC',
  DealsVariancePopulationSalesman_3NameAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_NAME_ASC',
  DealsVariancePopulationSalesman_3NameDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_NAME_DESC',
  DealsVariancePopulationClosingManagerNumberAsc = 'DEALS_VARIANCE_POPULATION_CLOSING_MANAGER_NUMBER_ASC',
  DealsVariancePopulationClosingManagerNumberDesc = 'DEALS_VARIANCE_POPULATION_CLOSING_MANAGER_NUMBER_DESC',
  DealsVariancePopulationClosingManagerNameAsc = 'DEALS_VARIANCE_POPULATION_CLOSING_MANAGER_NAME_ASC',
  DealsVariancePopulationClosingManagerNameDesc = 'DEALS_VARIANCE_POPULATION_CLOSING_MANAGER_NAME_DESC',
  DealsVariancePopulationFinanceManagerNumberAsc = 'DEALS_VARIANCE_POPULATION_FINANCE_MANAGER_NUMBER_ASC',
  DealsVariancePopulationFinanceManagerNumberDesc = 'DEALS_VARIANCE_POPULATION_FINANCE_MANAGER_NUMBER_DESC',
  DealsVariancePopulationFinanceManagerNameAsc = 'DEALS_VARIANCE_POPULATION_FINANCE_MANAGER_NAME_ASC',
  DealsVariancePopulationFinanceManagerNameDesc = 'DEALS_VARIANCE_POPULATION_FINANCE_MANAGER_NAME_DESC',
  DealsVariancePopulationSalesmanManagerNumberAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_MANAGER_NUMBER_ASC',
  DealsVariancePopulationSalesmanManagerNumberDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_MANAGER_NUMBER_DESC',
  DealsVariancePopulationSalesmanManagerNameAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_MANAGER_NAME_ASC',
  DealsVariancePopulationSalesmanManagerNameDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_MANAGER_NAME_DESC',
  DealsVariancePopulationMsrpAsc = 'DEALS_VARIANCE_POPULATION_MSRP_ASC',
  DealsVariancePopulationMsrpDesc = 'DEALS_VARIANCE_POPULATION_MSRP_DESC',
  DealsVariancePopulationListPriceAsc = 'DEALS_VARIANCE_POPULATION_LIST_PRICE_ASC',
  DealsVariancePopulationListPriceDesc = 'DEALS_VARIANCE_POPULATION_LIST_PRICE_DESC',
  DealsVariancePopulationSalesPriceAsc = 'DEALS_VARIANCE_POPULATION_SALES_PRICE_ASC',
  DealsVariancePopulationSalesPriceDesc = 'DEALS_VARIANCE_POPULATION_SALES_PRICE_DESC',
  DealsVariancePopulationJournalPriceAsc = 'DEALS_VARIANCE_POPULATION_JOURNAL_PRICE_ASC',
  DealsVariancePopulationJournalPriceDesc = 'DEALS_VARIANCE_POPULATION_JOURNAL_PRICE_DESC',
  DealsVariancePopulationCostAsc = 'DEALS_VARIANCE_POPULATION_COST_ASC',
  DealsVariancePopulationCostDesc = 'DEALS_VARIANCE_POPULATION_COST_DESC',
  DealsVariancePopulationJournalCostAsc = 'DEALS_VARIANCE_POPULATION_JOURNAL_COST_ASC',
  DealsVariancePopulationJournalCostDesc = 'DEALS_VARIANCE_POPULATION_JOURNAL_COST_DESC',
  DealsVariancePopulationAdjustmentsAsc = 'DEALS_VARIANCE_POPULATION_ADJUSTMENTS_ASC',
  DealsVariancePopulationAdjustmentsDesc = 'DEALS_VARIANCE_POPULATION_ADJUSTMENTS_DESC',
  DealsVariancePopulationAdjustedCostAsc = 'DEALS_VARIANCE_POPULATION_ADJUSTED_COST_ASC',
  DealsVariancePopulationAdjustedCostDesc = 'DEALS_VARIANCE_POPULATION_ADJUSTED_COST_DESC',
  DealsVariancePopulationIncentivesAsc = 'DEALS_VARIANCE_POPULATION_INCENTIVES_ASC',
  DealsVariancePopulationIncentivesDesc = 'DEALS_VARIANCE_POPULATION_INCENTIVES_DESC',
  DealsVariancePopulationPackAmountAsc = 'DEALS_VARIANCE_POPULATION_PACK_AMOUNT_ASC',
  DealsVariancePopulationPackAmountDesc = 'DEALS_VARIANCE_POPULATION_PACK_AMOUNT_DESC',
  DealsVariancePopulationSaleNetAsc = 'DEALS_VARIANCE_POPULATION_SALE_NET_ASC',
  DealsVariancePopulationSaleNetDesc = 'DEALS_VARIANCE_POPULATION_SALE_NET_DESC',
  DealsVariancePopulationTotalTradeActualCashValueAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsVariancePopulationTotalTradeActualCashValueDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsVariancePopulationTotalTradeGrossAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_TRADE_GROSS_ASC',
  DealsVariancePopulationTotalTradeGrossDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_TRADE_GROSS_DESC',
  DealsVariancePopulationWeOweFrontAsc = 'DEALS_VARIANCE_POPULATION_WE_OWE_FRONT_ASC',
  DealsVariancePopulationWeOweFrontDesc = 'DEALS_VARIANCE_POPULATION_WE_OWE_FRONT_DESC',
  DealsVariancePopulationTotalFrontFeesAftermarketProfitAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsVariancePopulationTotalFrontFeesAftermarketProfitDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsVariancePopulationTotalFrontCommissionAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_COMMISSION_ASC',
  DealsVariancePopulationTotalFrontCommissionDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_COMMISSION_DESC',
  DealsVariancePopulationTotalFrontSalesAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_SALES_ASC',
  DealsVariancePopulationTotalFrontSalesDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_SALES_DESC',
  DealsVariancePopulationTotalFrontCostAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_COST_ASC',
  DealsVariancePopulationTotalFrontCostDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_COST_DESC',
  DealsVariancePopulationFrontGrossAsc = 'DEALS_VARIANCE_POPULATION_FRONT_GROSS_ASC',
  DealsVariancePopulationFrontGrossDesc = 'DEALS_VARIANCE_POPULATION_FRONT_GROSS_DESC',
  DealsVariancePopulationFinanceProfitAsc = 'DEALS_VARIANCE_POPULATION_FINANCE_PROFIT_ASC',
  DealsVariancePopulationFinanceProfitDesc = 'DEALS_VARIANCE_POPULATION_FINANCE_PROFIT_DESC',
  DealsVariancePopulationTotalWarrantyProfitAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_WARRANTY_PROFIT_ASC',
  DealsVariancePopulationTotalWarrantyProfitDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_WARRANTY_PROFIT_DESC',
  DealsVariancePopulationWeOweBackAsc = 'DEALS_VARIANCE_POPULATION_WE_OWE_BACK_ASC',
  DealsVariancePopulationWeOweBackDesc = 'DEALS_VARIANCE_POPULATION_WE_OWE_BACK_DESC',
  DealsVariancePopulationInsuranceProfitAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_PROFIT_ASC',
  DealsVariancePopulationInsuranceProfitDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_PROFIT_DESC',
  DealsVariancePopulationTotalBackFeesAftermarketProfitAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsVariancePopulationTotalBackFeesAftermarketProfitDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsVariancePopulationFinanceReserveAsc = 'DEALS_VARIANCE_POPULATION_FINANCE_RESERVE_ASC',
  DealsVariancePopulationFinanceReserveDesc = 'DEALS_VARIANCE_POPULATION_FINANCE_RESERVE_DESC',
  DealsVariancePopulationTotalBackCommissionAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_COMMISSION_ASC',
  DealsVariancePopulationTotalBackCommissionDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_COMMISSION_DESC',
  DealsVariancePopulationTotalBackSalesAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_SALES_ASC',
  DealsVariancePopulationTotalBackSalesDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_SALES_DESC',
  DealsVariancePopulationTotalBackCostAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_COST_ASC',
  DealsVariancePopulationTotalBackCostDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_COST_DESC',
  DealsVariancePopulationBackGrossAsc = 'DEALS_VARIANCE_POPULATION_BACK_GROSS_ASC',
  DealsVariancePopulationBackGrossDesc = 'DEALS_VARIANCE_POPULATION_BACK_GROSS_DESC',
  DealsVariancePopulationTotalProfitAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_PROFIT_ASC',
  DealsVariancePopulationTotalProfitDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_PROFIT_DESC',
  DealsVariancePopulationGrossProfitAsc = 'DEALS_VARIANCE_POPULATION_GROSS_PROFIT_ASC',
  DealsVariancePopulationGrossProfitDesc = 'DEALS_VARIANCE_POPULATION_GROSS_PROFIT_DESC',
  DealsVariancePopulationGrossPayableAsc = 'DEALS_VARIANCE_POPULATION_GROSS_PAYABLE_ASC',
  DealsVariancePopulationGrossPayableDesc = 'DEALS_VARIANCE_POPULATION_GROSS_PAYABLE_DESC',
  DealsVariancePopulationDealStatusAsc = 'DEALS_VARIANCE_POPULATION_DEAL_STATUS_ASC',
  DealsVariancePopulationDealStatusDesc = 'DEALS_VARIANCE_POPULATION_DEAL_STATUS_DESC',
  DealsVariancePopulationEntryDateAsc = 'DEALS_VARIANCE_POPULATION_ENTRY_DATE_ASC',
  DealsVariancePopulationEntryDateDesc = 'DEALS_VARIANCE_POPULATION_ENTRY_DATE_DESC',
  DealsVariancePopulationBookedDateAsc = 'DEALS_VARIANCE_POPULATION_BOOKED_DATE_ASC',
  DealsVariancePopulationBookedDateDesc = 'DEALS_VARIANCE_POPULATION_BOOKED_DATE_DESC',
  DealsVariancePopulationFinalizedDateAsc = 'DEALS_VARIANCE_POPULATION_FINALIZED_DATE_ASC',
  DealsVariancePopulationFinalizedDateDesc = 'DEALS_VARIANCE_POPULATION_FINALIZED_DATE_DESC',
  DealsVariancePopulationContractDateAsc = 'DEALS_VARIANCE_POPULATION_CONTRACT_DATE_ASC',
  DealsVariancePopulationContractDateDesc = 'DEALS_VARIANCE_POPULATION_CONTRACT_DATE_DESC',
  DealsVariancePopulationAccountingDateAsc = 'DEALS_VARIANCE_POPULATION_ACCOUNTING_DATE_ASC',
  DealsVariancePopulationAccountingDateDesc = 'DEALS_VARIANCE_POPULATION_ACCOUNTING_DATE_DESC',
  DealsVariancePopulationStatusChangeDateAsc = 'DEALS_VARIANCE_POPULATION_STATUS_CHANGE_DATE_ASC',
  DealsVariancePopulationStatusChangeDateDesc = 'DEALS_VARIANCE_POPULATION_STATUS_CHANGE_DATE_DESC',
  DealsVariancePopulationFirstPayDateAsc = 'DEALS_VARIANCE_POPULATION_FIRST_PAY_DATE_ASC',
  DealsVariancePopulationFirstPayDateDesc = 'DEALS_VARIANCE_POPULATION_FIRST_PAY_DATE_DESC',
  DealsVariancePopulationDealTypeAsc = 'DEALS_VARIANCE_POPULATION_DEAL_TYPE_ASC',
  DealsVariancePopulationDealTypeDesc = 'DEALS_VARIANCE_POPULATION_DEAL_TYPE_DESC',
  DealsVariancePopulationSaleTypeAsc = 'DEALS_VARIANCE_POPULATION_SALE_TYPE_ASC',
  DealsVariancePopulationSaleTypeDesc = 'DEALS_VARIANCE_POPULATION_SALE_TYPE_DESC',
  DealsVariancePopulationBankIdAsc = 'DEALS_VARIANCE_POPULATION_BANK_ID_ASC',
  DealsVariancePopulationBankIdDesc = 'DEALS_VARIANCE_POPULATION_BANK_ID_DESC',
  DealsVariancePopulationBankNameAsc = 'DEALS_VARIANCE_POPULATION_BANK_NAME_ASC',
  DealsVariancePopulationBankNameDesc = 'DEALS_VARIANCE_POPULATION_BANK_NAME_DESC',
  DealsVariancePopulationBankAddressAsc = 'DEALS_VARIANCE_POPULATION_BANK_ADDRESS_ASC',
  DealsVariancePopulationBankAddressDesc = 'DEALS_VARIANCE_POPULATION_BANK_ADDRESS_DESC',
  DealsVariancePopulationTermAsc = 'DEALS_VARIANCE_POPULATION_TERM_ASC',
  DealsVariancePopulationTermDesc = 'DEALS_VARIANCE_POPULATION_TERM_DESC',
  DealsVariancePopulationAmountFinancedAsc = 'DEALS_VARIANCE_POPULATION_AMOUNT_FINANCED_ASC',
  DealsVariancePopulationAmountFinancedDesc = 'DEALS_VARIANCE_POPULATION_AMOUNT_FINANCED_DESC',
  DealsVariancePopulationAprAsc = 'DEALS_VARIANCE_POPULATION_APR_ASC',
  DealsVariancePopulationAprDesc = 'DEALS_VARIANCE_POPULATION_APR_DESC',
  DealsVariancePopulationMonthlyPaymentAsc = 'DEALS_VARIANCE_POPULATION_MONTHLY_PAYMENT_ASC',
  DealsVariancePopulationMonthlyPaymentDesc = 'DEALS_VARIANCE_POPULATION_MONTHLY_PAYMENT_DESC',
  DealsVariancePopulationPaymentTotalAsc = 'DEALS_VARIANCE_POPULATION_PAYMENT_TOTAL_ASC',
  DealsVariancePopulationPaymentTotalDesc = 'DEALS_VARIANCE_POPULATION_PAYMENT_TOTAL_DESC',
  DealsVariancePopulationRebatesAsc = 'DEALS_VARIANCE_POPULATION_REBATES_ASC',
  DealsVariancePopulationRebatesDesc = 'DEALS_VARIANCE_POPULATION_REBATES_DESC',
  DealsVariancePopulationDepositAsc = 'DEALS_VARIANCE_POPULATION_DEPOSIT_ASC',
  DealsVariancePopulationDepositDesc = 'DEALS_VARIANCE_POPULATION_DEPOSIT_DESC',
  DealsVariancePopulationDownPaymentAsc = 'DEALS_VARIANCE_POPULATION_DOWN_PAYMENT_ASC',
  DealsVariancePopulationDownPaymentDesc = 'DEALS_VARIANCE_POPULATION_DOWN_PAYMENT_DESC',
  DealsVariancePopulationTotalNetTradesAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_NET_TRADES_ASC',
  DealsVariancePopulationTotalNetTradesDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_NET_TRADES_DESC',
  DealsVariancePopulationTotalDownAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_DOWN_ASC',
  DealsVariancePopulationTotalDownDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_DOWN_DESC',
  DealsVariancePopulationBalloonAmountAsc = 'DEALS_VARIANCE_POPULATION_BALLOON_AMOUNT_ASC',
  DealsVariancePopulationBalloonAmountDesc = 'DEALS_VARIANCE_POPULATION_BALLOON_AMOUNT_DESC',
  DealsVariancePopulationAdjustedBalloonAmountAsc = 'DEALS_VARIANCE_POPULATION_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsVariancePopulationAdjustedBalloonAmountDesc = 'DEALS_VARIANCE_POPULATION_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsVariancePopulationHoldbackAmountAsc = 'DEALS_VARIANCE_POPULATION_HOLDBACK_AMOUNT_ASC',
  DealsVariancePopulationHoldbackAmountDesc = 'DEALS_VARIANCE_POPULATION_HOLDBACK_AMOUNT_DESC',
  DealsVariancePopulationTotalDriveOffAmountAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsVariancePopulationTotalDriveOffAmountDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsVariancePopulationLicenseFeeAsc = 'DEALS_VARIANCE_POPULATION_LICENSE_FEE_ASC',
  DealsVariancePopulationLicenseFeeDesc = 'DEALS_VARIANCE_POPULATION_LICENSE_FEE_DESC',
  DealsVariancePopulationRegistrationFeeAsc = 'DEALS_VARIANCE_POPULATION_REGISTRATION_FEE_ASC',
  DealsVariancePopulationRegistrationFeeDesc = 'DEALS_VARIANCE_POPULATION_REGISTRATION_FEE_DESC',
  DealsVariancePopulationDocumentationFeeAsc = 'DEALS_VARIANCE_POPULATION_DOCUMENTATION_FEE_ASC',
  DealsVariancePopulationDocumentationFeeDesc = 'DEALS_VARIANCE_POPULATION_DOCUMENTATION_FEE_DESC',
  DealsVariancePopulationFinanceChargeAsc = 'DEALS_VARIANCE_POPULATION_FINANCE_CHARGE_ASC',
  DealsVariancePopulationFinanceChargeDesc = 'DEALS_VARIANCE_POPULATION_FINANCE_CHARGE_DESC',
  DealsVariancePopulationTotalPickupPaymentsAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsVariancePopulationTotalPickupPaymentsDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsVariancePopulationSellRateAsc = 'DEALS_VARIANCE_POPULATION_SELL_RATE_ASC',
  DealsVariancePopulationSellRateDesc = 'DEALS_VARIANCE_POPULATION_SELL_RATE_DESC',
  DealsVariancePopulationBuyRateAsc = 'DEALS_VARIANCE_POPULATION_BUY_RATE_ASC',
  DealsVariancePopulationBuyRateDesc = 'DEALS_VARIANCE_POPULATION_BUY_RATE_DESC',
  DealsVariancePopulationResidualRateAsc = 'DEALS_VARIANCE_POPULATION_RESIDUAL_RATE_ASC',
  DealsVariancePopulationResidualRateDesc = 'DEALS_VARIANCE_POPULATION_RESIDUAL_RATE_DESC',
  DealsVariancePopulationResidualAmountAsc = 'DEALS_VARIANCE_POPULATION_RESIDUAL_AMOUNT_ASC',
  DealsVariancePopulationResidualAmountDesc = 'DEALS_VARIANCE_POPULATION_RESIDUAL_AMOUNT_DESC',
  DealsVariancePopulationAllowedMilesAsc = 'DEALS_VARIANCE_POPULATION_ALLOWED_MILES_ASC',
  DealsVariancePopulationAllowedMilesDesc = 'DEALS_VARIANCE_POPULATION_ALLOWED_MILES_DESC',
  DealsVariancePopulationEstimatedMilesAsc = 'DEALS_VARIANCE_POPULATION_ESTIMATED_MILES_ASC',
  DealsVariancePopulationEstimatedMilesDesc = 'DEALS_VARIANCE_POPULATION_ESTIMATED_MILES_DESC',
  DealsVariancePopulationMileageRateAsc = 'DEALS_VARIANCE_POPULATION_MILEAGE_RATE_ASC',
  DealsVariancePopulationMileageRateDesc = 'DEALS_VARIANCE_POPULATION_MILEAGE_RATE_DESC',
  DealsVariancePopulationAcquisitionFeeAsc = 'DEALS_VARIANCE_POPULATION_ACQUISITION_FEE_ASC',
  DealsVariancePopulationAcquisitionFeeDesc = 'DEALS_VARIANCE_POPULATION_ACQUISITION_FEE_DESC',
  DealsVariancePopulationBasePaymentAsc = 'DEALS_VARIANCE_POPULATION_BASE_PAYMENT_ASC',
  DealsVariancePopulationBasePaymentDesc = 'DEALS_VARIANCE_POPULATION_BASE_PAYMENT_DESC',
  DealsVariancePopulationSecurityDepositAsc = 'DEALS_VARIANCE_POPULATION_SECURITY_DEPOSIT_ASC',
  DealsVariancePopulationSecurityDepositDesc = 'DEALS_VARIANCE_POPULATION_SECURITY_DEPOSIT_DESC',
  DealsVariancePopulationTotalCapitalReductionAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsVariancePopulationTotalCapitalReductionDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsVariancePopulationNetCapitalCostAsc = 'DEALS_VARIANCE_POPULATION_NET_CAPITAL_COST_ASC',
  DealsVariancePopulationNetCapitalCostDesc = 'DEALS_VARIANCE_POPULATION_NET_CAPITAL_COST_DESC',
  DealsVariancePopulationLeaseDepreciationValueAsc = 'DEALS_VARIANCE_POPULATION_LEASE_DEPRECIATION_VALUE_ASC',
  DealsVariancePopulationLeaseDepreciationValueDesc = 'DEALS_VARIANCE_POPULATION_LEASE_DEPRECIATION_VALUE_DESC',
  DealsVariancePopulationDealerFeesAsc = 'DEALS_VARIANCE_POPULATION_DEALER_FEES_ASC',
  DealsVariancePopulationDealerFeesDesc = 'DEALS_VARIANCE_POPULATION_DEALER_FEES_DESC',
  DealsVariancePopulationGovernmentFeesAsc = 'DEALS_VARIANCE_POPULATION_GOVERNMENT_FEES_ASC',
  DealsVariancePopulationGovernmentFeesDesc = 'DEALS_VARIANCE_POPULATION_GOVERNMENT_FEES_DESC',
  DealsVariancePopulationTotalTaxAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_TAX_ASC',
  DealsVariancePopulationTotalTaxDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_TAX_DESC',
  DealsVariancePopulationRegistrationStateAsc = 'DEALS_VARIANCE_POPULATION_REGISTRATION_STATE_ASC',
  DealsVariancePopulationRegistrationStateDesc = 'DEALS_VARIANCE_POPULATION_REGISTRATION_STATE_DESC',
  DealsVariancePopulationReportOfSaleNumberAsc = 'DEALS_VARIANCE_POPULATION_REPORT_OF_SALE_NUMBER_ASC',
  DealsVariancePopulationReportOfSaleNumberDesc = 'DEALS_VARIANCE_POPULATION_REPORT_OF_SALE_NUMBER_DESC',
  DealsVariancePopulationSalesman_1TotalCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsVariancePopulationSalesman_1TotalCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsVariancePopulationSalesman_1FrontCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsVariancePopulationSalesman_1FrontCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsVariancePopulationSalesman_1BackCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsVariancePopulationSalesman_1BackCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsVariancePopulationSalesman_2TotalCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsVariancePopulationSalesman_2TotalCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsVariancePopulationSalesman_2FrontCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsVariancePopulationSalesman_2FrontCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsVariancePopulationSalesman_2BackCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsVariancePopulationSalesman_2BackCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsVariancePopulationSalesman_3TotalCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsVariancePopulationSalesman_3TotalCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsVariancePopulationSalesman_3FrontCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsVariancePopulationSalesman_3FrontCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsVariancePopulationSalesman_3BackCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsVariancePopulationSalesman_3BackCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsVariancePopulationWarranty_1NameAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_NAME_ASC',
  DealsVariancePopulationWarranty_1NameDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_NAME_DESC',
  DealsVariancePopulationWarranty_1SaleAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_SALE_ASC',
  DealsVariancePopulationWarranty_1SaleDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_SALE_DESC',
  DealsVariancePopulationWarranty_1CostAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_COST_ASC',
  DealsVariancePopulationWarranty_1CostDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_COST_DESC',
  DealsVariancePopulationWarranty_1MilesAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_MILES_ASC',
  DealsVariancePopulationWarranty_1MilesDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_MILES_DESC',
  DealsVariancePopulationWarranty_1TermAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_TERM_ASC',
  DealsVariancePopulationWarranty_1TermDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_TERM_DESC',
  DealsVariancePopulationWarranty_2NameAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_NAME_ASC',
  DealsVariancePopulationWarranty_2NameDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_NAME_DESC',
  DealsVariancePopulationWarranty_2SaleAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_SALE_ASC',
  DealsVariancePopulationWarranty_2SaleDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_SALE_DESC',
  DealsVariancePopulationWarranty_2CostAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_COST_ASC',
  DealsVariancePopulationWarranty_2CostDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_COST_DESC',
  DealsVariancePopulationWarranty_2MilesAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_MILES_ASC',
  DealsVariancePopulationWarranty_2MilesDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_MILES_DESC',
  DealsVariancePopulationWarranty_2TermAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_TERM_ASC',
  DealsVariancePopulationWarranty_2TermDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_TERM_DESC',
  DealsVariancePopulationWarranty_3NameAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_NAME_ASC',
  DealsVariancePopulationWarranty_3NameDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_NAME_DESC',
  DealsVariancePopulationWarranty_3SaleAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_SALE_ASC',
  DealsVariancePopulationWarranty_3SaleDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_SALE_DESC',
  DealsVariancePopulationWarranty_3CostAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_COST_ASC',
  DealsVariancePopulationWarranty_3CostDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_COST_DESC',
  DealsVariancePopulationWarranty_3MilesAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_MILES_ASC',
  DealsVariancePopulationWarranty_3MilesDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_MILES_DESC',
  DealsVariancePopulationWarranty_3TermAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_TERM_ASC',
  DealsVariancePopulationWarranty_3TermDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_TERM_DESC',
  DealsVariancePopulationWarranty_4NameAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_NAME_ASC',
  DealsVariancePopulationWarranty_4NameDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_NAME_DESC',
  DealsVariancePopulationWarranty_4SaleAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_SALE_ASC',
  DealsVariancePopulationWarranty_4SaleDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_SALE_DESC',
  DealsVariancePopulationWarranty_4CostAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_COST_ASC',
  DealsVariancePopulationWarranty_4CostDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_COST_DESC',
  DealsVariancePopulationWarranty_4MilesAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_MILES_ASC',
  DealsVariancePopulationWarranty_4MilesDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_MILES_DESC',
  DealsVariancePopulationWarranty_4TermAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_TERM_ASC',
  DealsVariancePopulationWarranty_4TermDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_TERM_DESC',
  DealsVariancePopulationWarranty_5NameAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_NAME_ASC',
  DealsVariancePopulationWarranty_5NameDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_NAME_DESC',
  DealsVariancePopulationWarranty_5SaleAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_SALE_ASC',
  DealsVariancePopulationWarranty_5SaleDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_SALE_DESC',
  DealsVariancePopulationWarranty_5CostAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_COST_ASC',
  DealsVariancePopulationWarranty_5CostDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_COST_DESC',
  DealsVariancePopulationWarranty_5MilesAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_MILES_ASC',
  DealsVariancePopulationWarranty_5MilesDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_MILES_DESC',
  DealsVariancePopulationWarranty_5TermAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_TERM_ASC',
  DealsVariancePopulationWarranty_5TermDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_TERM_DESC',
  DealsVariancePopulationTotalFeeAftermarketSaleAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsVariancePopulationTotalFeeAftermarketSaleDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsVariancePopulationTotalFeeAftermarketCostAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsVariancePopulationTotalFeeAftermarketCostDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsVariancePopulationFeeAftermarket_1NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_1NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_1SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_1SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_1CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_COST_ASC',
  DealsVariancePopulationFeeAftermarket_1CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_COST_DESC',
  DealsVariancePopulationFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_2NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_2NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_2SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_2SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_2CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_COST_ASC',
  DealsVariancePopulationFeeAftermarket_2CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_COST_DESC',
  DealsVariancePopulationFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_3NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_3NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_3SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_3SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_3CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_COST_ASC',
  DealsVariancePopulationFeeAftermarket_3CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_COST_DESC',
  DealsVariancePopulationFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_4NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_4NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_4SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_4SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_4CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_COST_ASC',
  DealsVariancePopulationFeeAftermarket_4CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_COST_DESC',
  DealsVariancePopulationFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_5NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_5NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_5SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_5SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_5CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_COST_ASC',
  DealsVariancePopulationFeeAftermarket_5CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_COST_DESC',
  DealsVariancePopulationFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_6NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_6NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_6SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_6SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_6CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_COST_ASC',
  DealsVariancePopulationFeeAftermarket_6CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_COST_DESC',
  DealsVariancePopulationFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_7NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_7NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_7SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_7SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_7CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_COST_ASC',
  DealsVariancePopulationFeeAftermarket_7CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_COST_DESC',
  DealsVariancePopulationFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_8NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_8NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_8SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_8SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_8CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_COST_ASC',
  DealsVariancePopulationFeeAftermarket_8CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_COST_DESC',
  DealsVariancePopulationFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_9NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_9NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_9SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_9SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_9CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_COST_ASC',
  DealsVariancePopulationFeeAftermarket_9CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_COST_DESC',
  DealsVariancePopulationFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_10NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_10NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_10SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_10SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_10CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_COST_ASC',
  DealsVariancePopulationFeeAftermarket_10CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_COST_DESC',
  DealsVariancePopulationFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_11NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_11NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_11SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_11SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_11CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_COST_ASC',
  DealsVariancePopulationFeeAftermarket_11CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_COST_DESC',
  DealsVariancePopulationFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_12NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_12NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_12SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_12SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_12CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_COST_ASC',
  DealsVariancePopulationFeeAftermarket_12CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_COST_DESC',
  DealsVariancePopulationFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_13NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_13NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_13SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_13SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_13CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_COST_ASC',
  DealsVariancePopulationFeeAftermarket_13CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_COST_DESC',
  DealsVariancePopulationFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_14NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_14NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_14SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_14SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_14CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_COST_ASC',
  DealsVariancePopulationFeeAftermarket_14CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_COST_DESC',
  DealsVariancePopulationFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_15NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_15NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_15SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_15SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_15CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_COST_ASC',
  DealsVariancePopulationFeeAftermarket_15CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_COST_DESC',
  DealsVariancePopulationFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_16NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_16NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_16SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_16SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_16CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_COST_ASC',
  DealsVariancePopulationFeeAftermarket_16CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_COST_DESC',
  DealsVariancePopulationFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_17NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_17NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_17SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_17SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_17CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_COST_ASC',
  DealsVariancePopulationFeeAftermarket_17CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_COST_DESC',
  DealsVariancePopulationFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_18NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_18NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_18SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_18SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_18CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_COST_ASC',
  DealsVariancePopulationFeeAftermarket_18CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_COST_DESC',
  DealsVariancePopulationFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_19NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_19NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_19SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_19SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_19CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_COST_ASC',
  DealsVariancePopulationFeeAftermarket_19CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_COST_DESC',
  DealsVariancePopulationFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_20NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_20NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_20SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_20SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_20CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_COST_ASC',
  DealsVariancePopulationFeeAftermarket_20CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_COST_DESC',
  DealsVariancePopulationFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationInsurance_1TypeAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_TYPE_ASC',
  DealsVariancePopulationInsurance_1TypeDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_TYPE_DESC',
  DealsVariancePopulationInsurance_1NameAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_NAME_ASC',
  DealsVariancePopulationInsurance_1NameDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_NAME_DESC',
  DealsVariancePopulationInsurance_1SaleAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_SALE_ASC',
  DealsVariancePopulationInsurance_1SaleDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_SALE_DESC',
  DealsVariancePopulationInsurance_1CostAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_COST_ASC',
  DealsVariancePopulationInsurance_1CostDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_COST_DESC',
  DealsVariancePopulationInsurance_1TermAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_TERM_ASC',
  DealsVariancePopulationInsurance_1TermDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_TERM_DESC',
  DealsVariancePopulationInsurance_2TypeAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_TYPE_ASC',
  DealsVariancePopulationInsurance_2TypeDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_TYPE_DESC',
  DealsVariancePopulationInsurance_2NameAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_NAME_ASC',
  DealsVariancePopulationInsurance_2NameDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_NAME_DESC',
  DealsVariancePopulationInsurance_2SaleAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_SALE_ASC',
  DealsVariancePopulationInsurance_2SaleDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_SALE_DESC',
  DealsVariancePopulationInsurance_2CostAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_COST_ASC',
  DealsVariancePopulationInsurance_2CostDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_COST_DESC',
  DealsVariancePopulationInsurance_2TermAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_TERM_ASC',
  DealsVariancePopulationInsurance_2TermDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_TERM_DESC',
  DealsVariancePopulationInsurance_3TypeAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_TYPE_ASC',
  DealsVariancePopulationInsurance_3TypeDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_TYPE_DESC',
  DealsVariancePopulationInsurance_3NameAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_NAME_ASC',
  DealsVariancePopulationInsurance_3NameDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_NAME_DESC',
  DealsVariancePopulationInsurance_3SaleAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_SALE_ASC',
  DealsVariancePopulationInsurance_3SaleDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_SALE_DESC',
  DealsVariancePopulationInsurance_3CostAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_COST_ASC',
  DealsVariancePopulationInsurance_3CostDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_COST_DESC',
  DealsVariancePopulationInsurance_3TermAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_TERM_ASC',
  DealsVariancePopulationInsurance_3TermDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_TERM_DESC',
  DealsVariancePopulationAccidentalHealthTypeAsc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsVariancePopulationAccidentalHealthTypeDesc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsVariancePopulationAccidentalHealthNameAsc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsVariancePopulationAccidentalHealthNameDesc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsVariancePopulationAccidentalHealthSaleAsc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsVariancePopulationAccidentalHealthSaleDesc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsVariancePopulationAccidentalHealthCostAsc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_COST_ASC',
  DealsVariancePopulationAccidentalHealthCostDesc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_COST_DESC',
  DealsVariancePopulationAccidentalHealthTermAsc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsVariancePopulationAccidentalHealthTermDesc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsVariancePopulationCreditLifeTypeAsc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_TYPE_ASC',
  DealsVariancePopulationCreditLifeTypeDesc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_TYPE_DESC',
  DealsVariancePopulationCreditLifeNameAsc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_NAME_ASC',
  DealsVariancePopulationCreditLifeNameDesc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_NAME_DESC',
  DealsVariancePopulationCreditLifeSaleAsc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_SALE_ASC',
  DealsVariancePopulationCreditLifeSaleDesc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_SALE_DESC',
  DealsVariancePopulationCreditLifeCostAsc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_COST_ASC',
  DealsVariancePopulationCreditLifeCostDesc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_COST_DESC',
  DealsVariancePopulationCreditLifeTermAsc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_TERM_ASC',
  DealsVariancePopulationCreditLifeTermDesc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_TERM_DESC',
  DealsVariancePopulationLevelizedLifeTypeAsc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_TYPE_ASC',
  DealsVariancePopulationLevelizedLifeTypeDesc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_TYPE_DESC',
  DealsVariancePopulationLevelizedLifeNameAsc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_NAME_ASC',
  DealsVariancePopulationLevelizedLifeNameDesc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_NAME_DESC',
  DealsVariancePopulationLevelizedLifeSaleAsc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_SALE_ASC',
  DealsVariancePopulationLevelizedLifeSaleDesc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_SALE_DESC',
  DealsVariancePopulationLevelizedLifeCostAsc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_COST_ASC',
  DealsVariancePopulationLevelizedLifeCostDesc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_COST_DESC',
  DealsVariancePopulationLevelizedLifeTermAsc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_TERM_ASC',
  DealsVariancePopulationLevelizedLifeTermDesc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_TERM_DESC',
  DealsVariancePopulationLossOfEmploymentTypeAsc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsVariancePopulationLossOfEmploymentTypeDesc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsVariancePopulationLossOfEmploymentNameAsc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsVariancePopulationLossOfEmploymentNameDesc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsVariancePopulationLossOfEmploymentSaleAsc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsVariancePopulationLossOfEmploymentSaleDesc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsVariancePopulationLossOfEmploymentCostAsc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsVariancePopulationLossOfEmploymentCostDesc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsVariancePopulationLossOfEmploymentTermAsc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsVariancePopulationLossOfEmploymentTermDesc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsVariancePopulationGuaranteedAutoProtectionTypeAsc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsVariancePopulationGuaranteedAutoProtectionTypeDesc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsVariancePopulationGuaranteedAutoProtectionNameAsc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsVariancePopulationGuaranteedAutoProtectionNameDesc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsVariancePopulationGuaranteedAutoProtectionSaleAsc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsVariancePopulationGuaranteedAutoProtectionSaleDesc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsVariancePopulationGuaranteedAutoProtectionCostAsc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsVariancePopulationGuaranteedAutoProtectionCostDesc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsVariancePopulationGuaranteedAutoProtectionTermAsc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsVariancePopulationGuaranteedAutoProtectionTermDesc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsVariancePopulationSaleCommentsAsc = 'DEALS_VARIANCE_POPULATION_SALE_COMMENTS_ASC',
  DealsVariancePopulationSaleCommentsDesc = 'DEALS_VARIANCE_POPULATION_SALE_COMMENTS_DESC',
  DealsVariancePopulationCassStdLine1Asc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LINE1_ASC',
  DealsVariancePopulationCassStdLine1Desc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LINE1_DESC',
  DealsVariancePopulationCassStdLine2Asc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LINE2_ASC',
  DealsVariancePopulationCassStdLine2Desc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LINE2_DESC',
  DealsVariancePopulationCassStdCityAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CITY_ASC',
  DealsVariancePopulationCassStdCityDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CITY_DESC',
  DealsVariancePopulationCassStdStateAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_STATE_ASC',
  DealsVariancePopulationCassStdStateDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_STATE_DESC',
  DealsVariancePopulationCassStdZipAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIP_ASC',
  DealsVariancePopulationCassStdZipDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIP_DESC',
  DealsVariancePopulationCassStdZip4Asc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIP4_ASC',
  DealsVariancePopulationCassStdZip4Desc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIP4_DESC',
  DealsVariancePopulationCassStdDpbcAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_DPBC_ASC',
  DealsVariancePopulationCassStdDpbcDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_DPBC_DESC',
  DealsVariancePopulationCassStdChkdgtAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CHKDGT_ASC',
  DealsVariancePopulationCassStdChkdgtDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CHKDGT_DESC',
  DealsVariancePopulationCassStdCartAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CART_ASC',
  DealsVariancePopulationCassStdCartDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CART_DESC',
  DealsVariancePopulationCassStdLotAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LOT_ASC',
  DealsVariancePopulationCassStdLotDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LOT_DESC',
  DealsVariancePopulationCassStdLotordAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LOTORD_ASC',
  DealsVariancePopulationCassStdLotordDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LOTORD_DESC',
  DealsVariancePopulationCassStdUrbAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_URB_ASC',
  DealsVariancePopulationCassStdUrbDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_URB_DESC',
  DealsVariancePopulationCassStdFipsAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_FIPS_ASC',
  DealsVariancePopulationCassStdFipsDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_FIPS_DESC',
  DealsVariancePopulationCassStdEwsAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_EWS_ASC',
  DealsVariancePopulationCassStdEwsDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_EWS_DESC',
  DealsVariancePopulationCassStdLacsAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LACS_ASC',
  DealsVariancePopulationCassStdLacsDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LACS_DESC',
  DealsVariancePopulationCassStdZipmovAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIPMOV_ASC',
  DealsVariancePopulationCassStdZipmovDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIPMOV_DESC',
  DealsVariancePopulationCassStdZ4LomAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_Z4LOM_ASC',
  DealsVariancePopulationCassStdZ4LomDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_Z4LOM_DESC',
  DealsVariancePopulationCassStdNdiaptAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_NDIAPT_ASC',
  DealsVariancePopulationCassStdNdiaptDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_NDIAPT_DESC',
  DealsVariancePopulationCassStdNdirrAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_NDIRR_ASC',
  DealsVariancePopulationCassStdNdirrDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_NDIRR_DESC',
  DealsVariancePopulationCassStdLacsrtAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LACSRT_ASC',
  DealsVariancePopulationCassStdLacsrtDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LACSRT_DESC',
  DealsVariancePopulationCassStdErrorCdAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ERROR_CD_ASC',
  DealsVariancePopulationCassStdErrorCdDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ERROR_CD_DESC'
}

export type CompanyInvoice = {
  provider: Scalars['String'];
  company_name: Scalars['String'];
  customer_name: Scalars['String'];
  status: Scalars['String'];
  invoice_id: Scalars['String'];
  invoice_number: Scalars['String'];
  date: Scalars['String'];
  due_date: Scalars['String'];
  total: Scalars['Float'];
  balance: Scalars['Float'];
  currency_code: Scalars['String'];
  currency_symbol: Scalars['String'];
  invoice_url?: Maybe<Scalars['String']>;
};

/** A connection to a list of `Lead` values, with data from `DistributedLead`. */
export type CompanyLeadsByDistributedLeadFromCompanyIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<CompanyLeadsByDistributedLeadFromCompanyIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `DistributedLead`. */
export type CompanyLeadsByDistributedLeadFromCompanyIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `DistributedLead`. */
export type CompanyLeadsByDistributedLeadFromCompanyIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeads: DistributedLeadsConnection;
};


/** A `Lead` edge in the connection, with data from `DistributedLead`. */
export type CompanyLeadsByDistributedLeadFromCompanyIdAndLeadIdManyToManyEdgeDistributedLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

/** A connection to a list of `Lead` values, with data from `DistributedLead`. */
export type CompanyLeadsByDistributedLeadToCompanyIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<CompanyLeadsByDistributedLeadToCompanyIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `DistributedLead`. */
export type CompanyLeadsByDistributedLeadToCompanyIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `DistributedLead`. */
export type CompanyLeadsByDistributedLeadToCompanyIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeads: DistributedLeadsConnection;
};


/** A `Lead` edge in the connection, with data from `DistributedLead`. */
export type CompanyLeadsByDistributedLeadToCompanyIdAndLeadIdManyToManyEdgeDistributedLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

export type CompanyLenderToolMeta = {
  creditApp?: Maybe<LenderProviderCreditAppConfig>;
  enabledProviders?: Maybe<Array<Scalars['String']>>;
};

export type CompanyLocation = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `CompanyLocation`. */
  company?: Maybe<Company>;
};

export type CompanyLocationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyLocationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyLocationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyLocationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyLocationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyLocationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyLocationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyLocationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyLocationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyLocationVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyLocation` object types. */
export type CompanyLocationAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyLocation` object to be included within the aggregate. */
  filter?: Maybe<CompanyLocationFilter>;
  /** Sum aggregate over matching `CompanyLocation` objects. */
  sum?: Maybe<CompanyLocationSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyLocation` objects. */
  distinctCount?: Maybe<CompanyLocationDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyLocation` objects. */
  min?: Maybe<CompanyLocationMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyLocation` objects. */
  max?: Maybe<CompanyLocationMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyLocation` objects. */
  average?: Maybe<CompanyLocationAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyLocation` objects. */
  stddevSample?: Maybe<CompanyLocationStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyLocation` objects. */
  stddevPopulation?: Maybe<CompanyLocationStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyLocation` objects. */
  varianceSample?: Maybe<CompanyLocationVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyLocation` objects. */
  variancePopulation?: Maybe<CompanyLocationVariancePopulationAggregateFilter>;
};

export type CompanyLocationAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Mean average of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

/** The `company` to be created by this mutation. */
export type CompanyLocationCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `companyLocation` to be created by this mutation. */
export type CompanyLocationCompanyIdFkeyCompanyLocationsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyLocationCompanyIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `CompanyLocationInput` mutation. */
export type CompanyLocationCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyLocationCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyLocation` in the `CompanyInput` mutation. */
export type CompanyLocationCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyLocation` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyLocation` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyLocationCompanyLocationsPkeyConnect>>;
  /** The primary key(s) for `companyLocation` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyLocationNodeIdConnect>>;
  /** The primary key(s) for `companyLocation` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyLocationCompanyLocationsPkeyDelete>>;
  /** The primary key(s) for `companyLocation` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyLocationNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyLocation` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyLocationsPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyLocation` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyLocationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyLocationCompanyIdFkeyCompanyLocationsCreateInput>>;
};

/** The fields on `companyLocation` to look up the row to connect. */
export type CompanyLocationCompanyLocationsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyLocation` to look up the row to delete. */
export type CompanyLocationCompanyLocationsPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyLocation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyLocationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `address1` field. */
  address1?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `address2` field. */
  address2?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `postalCode` field. */
  postalCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `province` field. */
  province?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lat` field. */
  lat?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `long` field. */
  long?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyLocationDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  address1?: Maybe<BigIntFilter>;
  address2?: Maybe<BigIntFilter>;
  city?: Maybe<BigIntFilter>;
  postalCode?: Maybe<BigIntFilter>;
  province?: Maybe<BigIntFilter>;
  lat?: Maybe<BigIntFilter>;
  long?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyLocationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of address1 across the matching connection */
  address1?: Maybe<Scalars['BigInt']>;
  /** Distinct count of address2 across the matching connection */
  address2?: Maybe<Scalars['BigInt']>;
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars['BigInt']>;
  /** Distinct count of postalCode across the matching connection */
  postalCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of province across the matching connection */
  province?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lat across the matching connection */
  lat?: Maybe<Scalars['BigInt']>;
  /** Distinct count of long across the matching connection */
  long?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyLocation` object types. All fields are combined with a logical ‘and.’ */
export type CompanyLocationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `address1` field. */
  address1?: Maybe<StringFilter>;
  /** Filter by the object’s `address2` field. */
  address2?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: Maybe<StringFilter>;
  /** Filter by the object’s `postalCode` field. */
  postalCode?: Maybe<StringFilter>;
  /** Filter by the object’s `province` field. */
  province?: Maybe<StringFilter>;
  /** Filter by the object’s `lat` field. */
  lat?: Maybe<FloatFilter>;
  /** Filter by the object’s `long` field. */
  long?: Maybe<FloatFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyLocationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyLocationFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyLocationFilter>;
};

/** An input for mutations affecting `CompanyLocation` */
export type CompanyLocationInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyLocationCompanyIdFkeyInput>;
};

export type CompanyLocationMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Maximum of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

export type CompanyLocationMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Minimum of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyLocationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyLocation` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyLocationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyLocation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyLocation` to look up the row to update. */
export type CompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyLocationsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyLocation` being updated. */
  patch: UpdateCompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyLocation`. Fields that are set will be updated. */
export type CompanyLocationPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyLocationCompanyIdFkeyInput>;
};

export type CompanyLocationStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Population standard deviation of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

export type CompanyLocationStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

export type CompanyLocationSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of lat across the matching connection */
  lat: Scalars['Float'];
  /** Sum of long across the matching connection */
  long: Scalars['Float'];
};

export type CompanyLocationVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Population variance of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

export type CompanyLocationVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Sample variance of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

/** A connection to a list of `CompanyLocation` values. */
export type CompanyLocationsConnection = {
  /** A list of `CompanyLocation` objects. */
  nodes: Array<CompanyLocation>;
  /** A list of edges which contains the `CompanyLocation` and cursor to aid in pagination. */
  edges: Array<CompanyLocationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyLocation` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyLocationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyLocationAggregates>>;
};


/** A connection to a list of `CompanyLocation` values. */
export type CompanyLocationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyLocationsGroupBy>;
  having?: Maybe<CompanyLocationsHavingInput>;
};

/** A `CompanyLocation` edge in the connection. */
export type CompanyLocationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyLocation` at the end of the edge. */
  node: CompanyLocation;
};

/** Grouping methods for `CompanyLocation` for usage during aggregation. */
export enum CompanyLocationsGroupBy {
  CompanyId = 'COMPANY_ID',
  Address1 = 'ADDRESS1',
  Address2 = 'ADDRESS2',
  City = 'CITY',
  PostalCode = 'POSTAL_CODE',
  Province = 'PROVINCE',
  Lat = 'LAT',
  Long = 'LONG',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyLocationsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyLocation` aggregates. */
export type CompanyLocationsHavingInput = {
  AND?: Maybe<Array<CompanyLocationsHavingInput>>;
  OR?: Maybe<Array<CompanyLocationsHavingInput>>;
  sum?: Maybe<CompanyLocationsHavingSumInput>;
  distinctCount?: Maybe<CompanyLocationsHavingDistinctCountInput>;
  min?: Maybe<CompanyLocationsHavingMinInput>;
  max?: Maybe<CompanyLocationsHavingMaxInput>;
  average?: Maybe<CompanyLocationsHavingAverageInput>;
  stddevSample?: Maybe<CompanyLocationsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyLocationsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyLocationsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyLocationsHavingVariancePopulationInput>;
};

export type CompanyLocationsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CompanyLocation`. */
export enum CompanyLocationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  Address1Asc = 'ADDRESS1_ASC',
  Address1Desc = 'ADDRESS1_DESC',
  Address2Asc = 'ADDRESS2_ASC',
  Address2Desc = 'ADDRESS2_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  PostalCodeAsc = 'POSTAL_CODE_ASC',
  PostalCodeDesc = 'POSTAL_CODE_DESC',
  ProvinceAsc = 'PROVINCE_ASC',
  ProvinceDesc = 'PROVINCE_DESC',
  LatAsc = 'LAT_ASC',
  LatDesc = 'LAT_DESC',
  LongAsc = 'LONG_ASC',
  LongDesc = 'LONG_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompanyMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  primaryGroup?: Maybe<IntFilter>;
  billableCompanyId?: Maybe<IntFilter>;
  accountManager?: Maybe<IntFilter>;
  supportAgent?: Maybe<IntFilter>;
};

export type CompanyMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['Int']>;
  /** Maximum of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['Int']>;
  /** Maximum of accountManager across the matching connection */
  accountManager?: Maybe<Scalars['Int']>;
  /** Maximum of supportAgent across the matching connection */
  supportAgent?: Maybe<Scalars['Int']>;
};

export type CompanyMeta = {
  id: Scalars['String'];
  template?: Maybe<CompanyTemplateMeta>;
  ctas?: Maybe<CompanyCtaMeta>;
  tradeTool?: Maybe<CompanyTradeToolMeta>;
  installation?: Maybe<CompanyInstallationMeta>;
  idTool?: Maybe<CompanyIdToolMeta>;
  lenderTool?: Maybe<CompanyLenderToolMeta>;
  securityCheck?: Maybe<SecurityCheck>;
  forceAssignedFilter?: Maybe<Scalars['Boolean']>;
};

export type CompanyMetaResponse = {
  companyId: Scalars['Int'];
  success?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type CompanyMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  primaryGroup?: Maybe<IntFilter>;
  billableCompanyId?: Maybe<IntFilter>;
  accountManager?: Maybe<IntFilter>;
  supportAgent?: Maybe<IntFilter>;
};

export type CompanyMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['Int']>;
  /** Minimum of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['Int']>;
  /** Minimum of accountManager across the matching connection */
  accountManager?: Maybe<Scalars['Int']>;
  /** Minimum of supportAgent across the matching connection */
  supportAgent?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `NeighborhoodView` values, with data from `Lead`. */
export type CompanyNeighborhoodViewsByLeadCompanyIdAndNeighborhoodViewIdManyToManyConnection = {
  /** A list of `NeighborhoodView` objects. */
  nodes: Array<NeighborhoodView>;
  /** A list of edges which contains the `NeighborhoodView`, info from the `Lead`, and the cursor to aid in pagination. */
  edges: Array<CompanyNeighborhoodViewsByLeadCompanyIdAndNeighborhoodViewIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NeighborhoodView` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NeighborhoodViewAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NeighborhoodViewAggregates>>;
};


/** A connection to a list of `NeighborhoodView` values, with data from `Lead`. */
export type CompanyNeighborhoodViewsByLeadCompanyIdAndNeighborhoodViewIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<NeighborhoodViewGroupBy>;
  having?: Maybe<NeighborhoodViewHavingInput>;
};

/** A `NeighborhoodView` edge in the connection, with data from `Lead`. */
export type CompanyNeighborhoodViewsByLeadCompanyIdAndNeighborhoodViewIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NeighborhoodView` at the end of the edge. */
  node: NeighborhoodView;
  /** Reads and enables pagination through a set of `Lead`. */
  leads: LeadsConnection;
};


/** A `NeighborhoodView` edge in the connection, with data from `Lead`. */
export type CompanyNeighborhoodViewsByLeadCompanyIdAndNeighborhoodViewIdManyToManyEdgeLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
};

export type CompanyNotificationInput = {
  companyId: Scalars['Int'];
  type: CompanyNotificationType;
};

export type CompanyNotificationResponse = {
  success: Scalars['Boolean'];
};

export enum CompanyNotificationType {
  OfferAccepted = 'OfferAccepted',
  NewVehicleAdded = 'NewVehicleAdded'
}

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnAnalyticForAnalyticsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `analytic` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `analytic` being updated. */
  patch: AnalyticPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnAnalyticForAnalyticsCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnAnalyticForAnalyticsCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnAnalyticForAnalyticsCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnAnalyticForAnalyticsCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnAnalyticForAnalyticsCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyAnalytic` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyAnalytic` being updated. */
  patch: CompanyAnalyticPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomain` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: CompanyDomainPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomainsApproved` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
  patch: CompanyDomainsApprovedPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomainsPath` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: CompanyDomainsPathPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyForCompanyAccountManagerFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyAccountManagerFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyAccountManagerFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyAccountManagerFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyAccountManagerFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyAccountManagerFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyAccountManagerFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyAccountManagerFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyAccountManagerFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyAccountManagerFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyAccountManagerFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyForCompanyPrimaryGroupFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyPrimaryGroupFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyPrimaryGroupFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyPrimaryGroupFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyPrimaryGroupFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyPrimaryGroupFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyForCompanySupportAgentFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanySupportAgentFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanySupportAgentFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanySupportAgentFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanySupportAgentFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanySupportAgentFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanySupportAgentFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanySupportAgentFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanySupportAgentFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanySupportAgentFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanySupportAgentFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyGroup` being updated. */
  patch: CompanyGroupPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyLocation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyLocation` being updated. */
  patch: CompanyLocationPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyProductForCompanyProductCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: CompanyProductPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyProductForCompanyProductCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyProductForCompanyProductCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyProductForCompanyProductCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyProductForCompanyProductCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyProductForCompanyProductCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyUserForCompanyUserCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyUser` being updated. */
  patch: CompanyUserPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyUserForCompanyUserCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyUserForCompanyUserCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyUserForCompanyUserCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyUserForCompanyUserCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyUserForCompanyUserCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `distributedLead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `distributedLead` being updated. */
  patch: DistributedLeadPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `distributedLead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `distributedLead` being updated. */
  patch: DistributedLeadPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `distributorRelationship` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `distributorRelationship` being updated. */
  patch: DistributorRelationshipPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `distributorRelationship` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `distributorRelationship` being updated. */
  patch: DistributorRelationshipPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnFieldForFieldCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: FieldPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnFieldForFieldCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnFieldForFieldCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnFieldForFieldCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnFieldForFieldCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnFieldForFieldCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnLeadForLeadCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnLeadForLeadCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnLeadForLeadCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnLeadForLeadCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnLeadForLeadCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnLeadForLeadCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnMediaForMediaCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `media` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `media` being updated. */
  patch: MediaPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnMediaForMediaCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnMediaForMediaCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnMediaForMediaCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnMediaForMediaCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnMediaForMediaCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnMediaForMediaCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnMediaForMediaCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnMediaForMediaCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnMediaForMediaCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnMediaForMediaCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnReviewForReviewsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `review` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `review` being updated. */
  patch: ReviewPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnReviewForReviewsCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnReviewForReviewsCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnReviewForReviewsCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnReviewForReviewsCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnReviewForReviewsCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnVehicleStockForVehicleStockCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `vehicleStock` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `vehicleStock` being updated. */
  patch: VehicleStockPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnVehicleStockForVehicleStockCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnVehicleStockForVehicleStockCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnVehicleStockForVehicleStockCompanyIdFkeyUsingCompanyLagoIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnVehicleStockForVehicleStockCompanyIdFkeyPatch;
  lagoId: Scalars['UUID'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnVehicleStockForVehicleStockCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnVehicleStockForVehicleStockCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnVehicleStockForVehicleStockCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnVehicleStockForVehicleStockCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnVehicleStockForVehicleStockCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnVehicleStockForVehicleStockCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** Represents an update to a `Company`. Fields that are set will be updated. */
export type CompanyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

export type CompanyPermissions = {
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<Permissions>;
};

/** The `company` to be created by this mutation. */
export type CompanyPrimaryGroupFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `group` to be created by this mutation. */
export type CompanyPrimaryGroupFkeyGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `CompanyInput` mutation. */
export type CompanyPrimaryGroupFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCompanyForCompanyPrimaryGroupFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnCompanyForCompanyPrimaryGroupFkeyUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyOnCompanyForCompanyPrimaryGroupFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyPrimaryGroupFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `company` in the `GroupInput` mutation. */
export type CompanyPrimaryGroupFkeyInverseInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<GroupOnCompanyForCompanyPrimaryGroupFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyPrimaryGroupFkeyCompanyCreateInput>>;
};

export type CompanyProduct = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  companyId: Scalars['Int'];
  productId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  id: Scalars['Int'];
  enabled: Scalars['Boolean'];
  /** Reads a single `Company` that is related to this `CompanyProduct`. */
  company?: Maybe<Company>;
  /** Reads a single `Product` that is related to this `CompanyProduct`. */
  product?: Maybe<Product>;
  /** Reads and enables pagination through a set of `CompanyProductIntegration`. */
  companyProductIntegrations: CompanyProductIntegrationsConnection;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationId: CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByFieldCompanyProductIdAndCompanyId: CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByFieldCompanyProductIdAndCompanyChannelId: CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationId: CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigsByFieldCompanyProductIdAndConfig: CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigManyToManyConnection;
};


export type CompanyProductCompanyProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
  condition?: Maybe<CompanyProductIntegrationCondition>;
  filter?: Maybe<CompanyProductIntegrationFilter>;
};


export type CompanyProductFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


export type CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

export type CompanyProductAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyProductSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyProductDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyProductMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyProductMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyProductAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyProductStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyProductStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyProductVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyProductVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyProduct` object types. */
export type CompanyProductAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyProduct` object to be included within the aggregate. */
  filter?: Maybe<CompanyProductFilter>;
  /** Sum aggregate over matching `CompanyProduct` objects. */
  sum?: Maybe<CompanyProductSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyProduct` objects. */
  distinctCount?: Maybe<CompanyProductDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyProduct` objects. */
  min?: Maybe<CompanyProductMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyProduct` objects. */
  max?: Maybe<CompanyProductMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyProduct` objects. */
  average?: Maybe<CompanyProductAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyProduct` objects. */
  stddevSample?: Maybe<CompanyProductStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyProduct` objects. */
  stddevPopulation?: Maybe<CompanyProductStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyProduct` objects. */
  varianceSample?: Maybe<CompanyProductVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyProduct` objects. */
  variancePopulation?: Maybe<CompanyProductVariancePopulationAggregateFilter>;
};

export type CompanyProductAverageAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type CompanyProductAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** The `company` to be created by this mutation. */
export type CompanyProductCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `companyProduct` to be created by this mutation. */
export type CompanyProductCompanyIdFkeyCompanyProductCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `CompanyProductInput` mutation. */
export type CompanyProductCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyProductCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyProduct` in the `CompanyInput` mutation. */
export type CompanyProductCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyProduct` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyProductCompanyProductPkeyConnect>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyProductNodeIdConnect>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyProductCompanyProductPkeyDelete>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyProductNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyProductPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyProductForCompanyProductCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyProductInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyProductCompanyIdFkeyCompanyProductCreateInput>>;
};

/** A connection to a list of `CompanyIntegration` values, with data from `CompanyProductIntegration`. */
export type CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `CompanyProductIntegration`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `CompanyProductIntegration`. */
export type CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `CompanyProductIntegration`. */
export type CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `CompanyProductIntegration`. */
  companyProductIntegrations: CompanyProductIntegrationsConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `CompanyProductIntegration`. */
export type CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyEdgeCompanyProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
  condition?: Maybe<CompanyProductIntegrationCondition>;
  filter?: Maybe<CompanyProductIntegrationFilter>;
};

/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** The fields on `companyProduct` to look up the row to connect. */
export type CompanyProductCompanyProductPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyProduct` to look up the row to delete. */
export type CompanyProductCompanyProductPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyProduct` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyProductCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
};

export type CompanyProductDistinctCountAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  id?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
};

export type CompanyProductDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of productId across the matching connection */
  productId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigManyToManyConnection = {
  /** A list of `FieldConfig` objects. */
  nodes: Array<FieldConfig>;
  /** A list of edges which contains the `FieldConfig`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldConfigAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldConfigAggregates>>;
};


/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldConfigGroupBy>;
  having?: Maybe<FieldConfigHavingInput>;
};

/** A `FieldConfig` edge in the connection, with data from `Field`. */
export type CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldConfig` at the end of the edge. */
  node: FieldConfig;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A filter to be used against `CompanyProduct` object types. All fields are combined with a logical ‘and.’ */
export type CompanyProductFilter = {
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `companyProductIntegrations` relation. */
  companyProductIntegrations?: Maybe<CompanyProductToManyCompanyProductIntegrationFilter>;
  /** Some related `companyProductIntegrations` exist. */
  companyProductIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fields` relation. */
  fields?: Maybe<CompanyProductToManyFieldFilter>;
  /** Some related `fields` exist. */
  fieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Filter by the object’s `product` relation. */
  product?: Maybe<ProductFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyProductFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyProductFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyProductFilter>;
};

/** Grouping methods for `CompanyProduct` for usage during aggregation. */
export enum CompanyProductGroupBy {
  CompanyId = 'COMPANY_ID',
  ProductId = 'PRODUCT_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Id = 'ID',
  Enabled = 'ENABLED'
}

export type CompanyProductHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

/** Conditions for `CompanyProduct` aggregates. */
export type CompanyProductHavingInput = {
  AND?: Maybe<Array<CompanyProductHavingInput>>;
  OR?: Maybe<Array<CompanyProductHavingInput>>;
  sum?: Maybe<CompanyProductHavingSumInput>;
  distinctCount?: Maybe<CompanyProductHavingDistinctCountInput>;
  min?: Maybe<CompanyProductHavingMinInput>;
  max?: Maybe<CompanyProductHavingMaxInput>;
  average?: Maybe<CompanyProductHavingAverageInput>;
  stddevSample?: Maybe<CompanyProductHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyProductHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyProductHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyProductHavingVariancePopulationInput>;
};

export type CompanyProductHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

/** An input for mutations affecting `CompanyProduct` */
export type CompanyProductInput = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

export type CompanyProductIntegration = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyIntegrationId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `CompanyIntegration` that is related to this `CompanyProductIntegration`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `CompanyProduct` that is related to this `CompanyProductIntegration`. */
  companyProduct?: Maybe<CompanyProduct>;
};

export type CompanyProductIntegrationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyProductIntegrationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyProductIntegrationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyProductIntegrationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyProductIntegrationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyProductIntegrationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyProductIntegrationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyProductIntegrationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyProductIntegrationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyProductIntegrationVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyProductIntegration` object types. */
export type CompanyProductIntegrationAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyProductIntegration` object to be included within the aggregate. */
  filter?: Maybe<CompanyProductIntegrationFilter>;
  /** Sum aggregate over matching `CompanyProductIntegration` objects. */
  sum?: Maybe<CompanyProductIntegrationSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyProductIntegration` objects. */
  distinctCount?: Maybe<CompanyProductIntegrationDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyProductIntegration` objects. */
  min?: Maybe<CompanyProductIntegrationMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyProductIntegration` objects. */
  max?: Maybe<CompanyProductIntegrationMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyProductIntegration` objects. */
  average?: Maybe<CompanyProductIntegrationAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyProductIntegration` objects. */
  stddevSample?: Maybe<CompanyProductIntegrationStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyProductIntegration` objects. */
  stddevPopulation?: Maybe<CompanyProductIntegrationStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyProductIntegration` objects. */
  varianceSample?: Maybe<CompanyProductIntegrationVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyProductIntegration` objects. */
  variancePopulation?: Maybe<CompanyProductIntegrationVariancePopulationAggregateFilter>;
};

export type CompanyProductIntegrationAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
};

export type CompanyProductIntegrationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
};

/** The `companyIntegration` to be created by this mutation. */
export type CompanyProductIntegrationCompanyIntegrationIdFkeyCompanyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** The `companyProductIntegration` to be created by this mutation. */
export type CompanyProductIntegrationCompanyIntegrationIdFkeyCompanyProductIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

/** Input for the nested mutation of `companyIntegration` in the `CompanyProductIntegrationInput` mutation. */
export type CompanyProductIntegrationCompanyIntegrationIdFkeyInput = {
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyIntegrationNodeIdConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyDelete>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyIntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateById?: Maybe<CompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyNodeIdUpdate>;
  /** A `CompanyIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyCompanyIntegrationCreateInput>;
};

/** Input for the nested mutation of `companyProductIntegration` in the `CompanyIntegrationInput` mutation. */
export type CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyProductIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyProductIntegrationCompanyProductIntegrationPkeyConnect>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyProductIntegrationNodeIdConnect>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyProductIntegrationCompanyProductIntegrationPkeyDelete>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyProductIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyProductIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyUsingCompanyProductIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyProductIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyNodeIdUpdate>>;
  /** A `CompanyProductIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyProductIntegrationCompanyIntegrationIdFkeyCompanyProductIntegrationCreateInput>>;
};

/** The `companyProduct` to be created by this mutation. */
export type CompanyProductIntegrationCompanyProductIdFkeyCompanyProductCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** The `companyProductIntegration` to be created by this mutation. */
export type CompanyProductIntegrationCompanyProductIdFkeyCompanyProductIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

/** Input for the nested mutation of `companyProduct` in the `CompanyProductIntegrationInput` mutation. */
export type CompanyProductIntegrationCompanyProductIdFkeyInput = {
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectById?: Maybe<CompanyProductCompanyProductPkeyConnect>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyProductNodeIdConnect>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteById?: Maybe<CompanyProductCompanyProductPkeyDelete>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyProductNodeIdDelete>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateById?: Maybe<CompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyUsingCompanyProductPkeyUpdate>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyNodeIdUpdate>;
  /** A `CompanyProductInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyCompanyProductCreateInput>;
};

/** Input for the nested mutation of `companyProductIntegration` in the `CompanyProductInput` mutation. */
export type CompanyProductIntegrationCompanyProductIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyProductIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyProductIntegrationCompanyProductIntegrationPkeyConnect>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyProductIntegrationNodeIdConnect>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyProductIntegrationCompanyProductIntegrationPkeyDelete>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyProductIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyProductIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyUsingCompanyProductIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyProductIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyNodeIdUpdate>>;
  /** A `CompanyProductIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyProductIntegrationCompanyProductIdFkeyCompanyProductIntegrationCreateInput>>;
};

/** The fields on `companyProductIntegration` to look up the row to connect. */
export type CompanyProductIntegrationCompanyProductIntegrationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyProductIntegration` to look up the row to delete. */
export type CompanyProductIntegrationCompanyProductIntegrationPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyProductIntegration` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type CompanyProductIntegrationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyProductId` field. */
  companyProductId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyProductIntegrationDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  companyProductId?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyProductIntegrationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type CompanyProductIntegrationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyProductId` field. */
  companyProductId?: Maybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `companyIntegration` relation. */
  companyIntegration?: Maybe<CompanyIntegrationFilter>;
  /** A related `companyIntegration` exists. */
  companyIntegrationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyProduct` relation. */
  companyProduct?: Maybe<CompanyProductFilter>;
  /** A related `companyProduct` exists. */
  companyProductExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyProductIntegrationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyProductIntegrationFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyProductIntegrationFilter>;
};

/** Grouping methods for `CompanyProductIntegration` for usage during aggregation. */
export enum CompanyProductIntegrationGroupBy {
  CompanyIntegrationId = 'COMPANY_INTEGRATION_ID',
  CompanyProductId = 'COMPANY_PRODUCT_ID',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyProductIntegrationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyProductIntegration` aggregates. */
export type CompanyProductIntegrationHavingInput = {
  AND?: Maybe<Array<CompanyProductIntegrationHavingInput>>;
  OR?: Maybe<Array<CompanyProductIntegrationHavingInput>>;
  sum?: Maybe<CompanyProductIntegrationHavingSumInput>;
  distinctCount?: Maybe<CompanyProductIntegrationHavingDistinctCountInput>;
  min?: Maybe<CompanyProductIntegrationHavingMinInput>;
  max?: Maybe<CompanyProductIntegrationHavingMaxInput>;
  average?: Maybe<CompanyProductIntegrationHavingAverageInput>;
  stddevSample?: Maybe<CompanyProductIntegrationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyProductIntegrationHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyProductIntegrationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyProductIntegrationHavingVariancePopulationInput>;
};

export type CompanyProductIntegrationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyProductIntegration` */
export type CompanyProductIntegrationInput = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

export type CompanyProductIntegrationMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  companyProductId?: Maybe<IntFilter>;
};

export type CompanyProductIntegrationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Maximum of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['Int']>;
};

export type CompanyProductIntegrationMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  companyProductId?: Maybe<IntFilter>;
};

export type CompanyProductIntegrationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Minimum of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyProductIntegrationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyProductIntegration` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyProductIntegrationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyProductIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** The fields on `companyProductIntegration` to look up the row to update. */
export type CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyUsingCompanyProductIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
  patch: UpdateCompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: CompanyProductPatch;
};

/** The fields on `companyProductIntegration` to look up the row to update. */
export type CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyUsingCompanyProductIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
  patch: UpdateCompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyProductIntegration`. Fields that are set will be updated. */
export type CompanyProductIntegrationPatch = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

export type CompanyProductIntegrationStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
};

export type CompanyProductIntegrationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductIntegrationStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
};

export type CompanyProductIntegrationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductIntegrationSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  companyProductId?: Maybe<BigIntFilter>;
};

export type CompanyProductIntegrationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyIntegrationId across the matching connection */
  companyIntegrationId: Scalars['BigInt'];
  /** Sum of companyProductId across the matching connection */
  companyProductId: Scalars['BigInt'];
};

export type CompanyProductIntegrationVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
};

export type CompanyProductIntegrationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductIntegrationVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
};

export type CompanyProductIntegrationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyProductIntegration` values. */
export type CompanyProductIntegrationsConnection = {
  /** A list of `CompanyProductIntegration` objects. */
  nodes: Array<CompanyProductIntegration>;
  /** A list of edges which contains the `CompanyProductIntegration` and cursor to aid in pagination. */
  edges: Array<CompanyProductIntegrationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProductIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductIntegrationAggregates>>;
};


/** A connection to a list of `CompanyProductIntegration` values. */
export type CompanyProductIntegrationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductIntegrationGroupBy>;
  having?: Maybe<CompanyProductIntegrationHavingInput>;
};

/** A `CompanyProductIntegration` edge in the connection. */
export type CompanyProductIntegrationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProductIntegration` at the end of the edge. */
  node: CompanyProductIntegration;
};

/** Methods to use when ordering `CompanyProductIntegration`. */
export enum CompanyProductIntegrationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIntegrationIdAsc = 'COMPANY_INTEGRATION_ID_ASC',
  CompanyIntegrationIdDesc = 'COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIdAsc = 'COMPANY_PRODUCT_ID_ASC',
  CompanyProductIdDesc = 'COMPANY_PRODUCT_ID_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompanyProductMaxAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  productId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
};

export type CompanyProductMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type CompanyProductMinAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  productId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
};

export type CompanyProductMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyProductNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyProductNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyProduct` to look up the row to update. */
export type CompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: UpdateCompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductOnCompanyProductForCompanyProductProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** The fields on `companyProduct` to look up the row to update. */
export type CompanyProductOnCompanyProductForCompanyProductProductIdFkeyUsingCompanyProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: UpdateCompanyProductOnCompanyProductForCompanyProductProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProductIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
  patch: CompanyProductIntegrationPatch;
};

/** The fields on `companyProduct` to look up the row to update. */
export type CompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyUsingCompanyProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: UpdateCompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductOnFieldForFieldCompanyProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: FieldPatch;
};

/** The fields on `companyProduct` to look up the row to update. */
export type CompanyProductOnFieldForFieldCompanyProductIdFkeyUsingCompanyProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: UpdateCompanyProductOnFieldForFieldCompanyProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyProduct`. Fields that are set will be updated. */
export type CompanyProductPatch = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** The `companyProduct` to be created by this mutation. */
export type CompanyProductProductIdFkeyCompanyProductCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** Input for the nested mutation of `product` in the `CompanyProductInput` mutation. */
export type CompanyProductProductIdFkeyInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectById?: Maybe<ProductProductPkeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByName?: Maybe<ProductProductNameKeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: Maybe<ProductNodeIdConnect>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateById?: Maybe<ProductOnCompanyProductForCompanyProductProductIdFkeyUsingProductPkeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByName?: Maybe<ProductOnCompanyProductForCompanyProductProductIdFkeyUsingProductNameKeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyProductOnCompanyProductForCompanyProductProductIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `companyProduct` in the `ProductInput` mutation. */
export type CompanyProductProductIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyProduct` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyProductCompanyProductPkeyConnect>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyProductNodeIdConnect>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyProductCompanyProductPkeyDelete>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyProductNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyProductOnCompanyProductForCompanyProductProductIdFkeyUsingCompanyProductPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<ProductOnCompanyProductForCompanyProductProductIdFkeyNodeIdUpdate>>;
  /** A `CompanyProductInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyProductProductIdFkeyCompanyProductCreateInput>>;
};

export type CompanyProductStddevPopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type CompanyProductStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductStddevSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type CompanyProductStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductSumAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
  id?: Maybe<BigIntFilter>;
};

export type CompanyProductSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of productId across the matching connection */
  productId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type CompanyProductToManyCompanyProductIntegrationFilter = {
  /** Every related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyProductIntegrationFilter>;
  /** Some related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyProductIntegrationFilter>;
  /** No related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyProductIntegrationFilter>;
  /** Aggregates across related `CompanyProductIntegration` match the filter criteria. */
  aggregates?: Maybe<CompanyProductIntegrationAggregatesFilter>;
};

/** A filter to be used against many `Field` object types. All fields are combined with a logical ‘and.’ */
export type CompanyProductToManyFieldFilter = {
  /** Every related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldFilter>;
  /** Some related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldFilter>;
  /** No related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldFilter>;
  /** Aggregates across related `Field` match the filter criteria. */
  aggregates?: Maybe<FieldAggregatesFilter>;
};

export type CompanyProductVariancePopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type CompanyProductVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductVarianceSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type CompanyProductVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Product` values, with data from `CompanyProduct`. */
export type CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyConnection = {
  /** A list of `Product` objects. */
  nodes: Array<Product>;
  /** A list of edges which contains the `Product`, info from the `CompanyProduct`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductAggregates>>;
};


/** A connection to a list of `Product` values, with data from `CompanyProduct`. */
export type CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductGroupBy>;
  having?: Maybe<ProductHavingInput>;
};

/** A `Product` edge in the connection, with data from `CompanyProduct`. */
export type CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node: Product;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProducts: CompanyProductsConnection;
};


/** A `Product` edge in the connection, with data from `CompanyProduct`. */
export type CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyEdgeCompanyProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};

/** A connection to a list of `CompanyProduct` values. */
export type CompanyProductsConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct` and cursor to aid in pagination. */
  edges: Array<CompanyProductsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values. */
export type CompanyProductsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection. */
export type CompanyProductsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
};

/** Methods to use when ordering `CompanyProduct`. */
export enum CompanyProductsOrderBy {
  Natural = 'NATURAL',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyProductIntegrationsCountAsc = 'COMPANY_PRODUCT_INTEGRATIONS_COUNT_ASC',
  CompanyProductIntegrationsCountDesc = 'COMPANY_PRODUCT_INTEGRATIONS_COUNT_DESC',
  CompanyProductIntegrationsSumIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ID_ASC',
  CompanyProductIntegrationsSumIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ID_DESC',
  CompanyProductIntegrationsSumCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsSumCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsSumCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsSumCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsSumEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ENABLED_ASC',
  CompanyProductIntegrationsSumEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ENABLED_DESC',
  CompanyProductIntegrationsSumCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_CREATED_AT_ASC',
  CompanyProductIntegrationsSumCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_CREATED_AT_DESC',
  CompanyProductIntegrationsSumUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  CompanyProductIntegrationsSumUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  CompanyProductIntegrationsDistinctCountIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  CompanyProductIntegrationsDistinctCountIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  CompanyProductIntegrationsDistinctCountCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsDistinctCountCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsDistinctCountCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsDistinctCountCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsDistinctCountEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyProductIntegrationsDistinctCountEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyProductIntegrationsDistinctCountCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyProductIntegrationsDistinctCountCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyProductIntegrationsDistinctCountUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyProductIntegrationsDistinctCountUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyProductIntegrationsMinIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ID_ASC',
  CompanyProductIntegrationsMinIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ID_DESC',
  CompanyProductIntegrationsMinCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsMinCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsMinCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsMinCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsMinEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ENABLED_ASC',
  CompanyProductIntegrationsMinEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ENABLED_DESC',
  CompanyProductIntegrationsMinCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_CREATED_AT_ASC',
  CompanyProductIntegrationsMinCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_CREATED_AT_DESC',
  CompanyProductIntegrationsMinUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  CompanyProductIntegrationsMinUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  CompanyProductIntegrationsMaxIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ID_ASC',
  CompanyProductIntegrationsMaxIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ID_DESC',
  CompanyProductIntegrationsMaxCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsMaxCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsMaxCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsMaxCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsMaxEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ENABLED_ASC',
  CompanyProductIntegrationsMaxEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ENABLED_DESC',
  CompanyProductIntegrationsMaxCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_CREATED_AT_ASC',
  CompanyProductIntegrationsMaxCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_CREATED_AT_DESC',
  CompanyProductIntegrationsMaxUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  CompanyProductIntegrationsMaxUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  CompanyProductIntegrationsAverageIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ID_ASC',
  CompanyProductIntegrationsAverageIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ID_DESC',
  CompanyProductIntegrationsAverageCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsAverageCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsAverageCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsAverageCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsAverageEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  CompanyProductIntegrationsAverageEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  CompanyProductIntegrationsAverageCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  CompanyProductIntegrationsAverageCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  CompanyProductIntegrationsAverageUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  CompanyProductIntegrationsAverageUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  CompanyProductIntegrationsStddevSampleIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  CompanyProductIntegrationsStddevSampleIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  CompanyProductIntegrationsStddevSampleCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsStddevSampleCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsStddevSampleCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsStddevSampleCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsStddevSampleEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyProductIntegrationsStddevSampleEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyProductIntegrationsStddevSampleCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyProductIntegrationsStddevSampleCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyProductIntegrationsStddevSampleUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyProductIntegrationsStddevSampleUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyProductIntegrationsStddevPopulationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  CompanyProductIntegrationsStddevPopulationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  CompanyProductIntegrationsStddevPopulationCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsStddevPopulationCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsStddevPopulationCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsStddevPopulationCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsStddevPopulationEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyProductIntegrationsStddevPopulationEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyProductIntegrationsStddevPopulationCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyProductIntegrationsStddevPopulationCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyProductIntegrationsStddevPopulationUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyProductIntegrationsStddevPopulationUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyProductIntegrationsVarianceSampleIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  CompanyProductIntegrationsVarianceSampleIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  CompanyProductIntegrationsVarianceSampleCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsVarianceSampleCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsVarianceSampleCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsVarianceSampleCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsVarianceSampleEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyProductIntegrationsVarianceSampleEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyProductIntegrationsVarianceSampleCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyProductIntegrationsVarianceSampleCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyProductIntegrationsVarianceSampleUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyProductIntegrationsVarianceSampleUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyProductIntegrationsVariancePopulationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  CompanyProductIntegrationsVariancePopulationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  CompanyProductIntegrationsVariancePopulationCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsVariancePopulationCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsVariancePopulationCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsVariancePopulationCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsVariancePopulationEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyProductIntegrationsVariancePopulationEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyProductIntegrationsVariancePopulationCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyProductIntegrationsVariancePopulationCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyProductIntegrationsVariancePopulationUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyProductIntegrationsVariancePopulationUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FieldsCountAsc = 'FIELDS_COUNT_ASC',
  FieldsCountDesc = 'FIELDS_COUNT_DESC',
  FieldsSumIdAsc = 'FIELDS_SUM_ID_ASC',
  FieldsSumIdDesc = 'FIELDS_SUM_ID_DESC',
  FieldsSumCompanyIdAsc = 'FIELDS_SUM_COMPANY_ID_ASC',
  FieldsSumCompanyIdDesc = 'FIELDS_SUM_COMPANY_ID_DESC',
  FieldsSumCompanyProductIdAsc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_ASC',
  FieldsSumCompanyProductIdDesc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_DESC',
  FieldsSumCompanyChannelIdAsc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_ASC',
  FieldsSumCompanyChannelIdDesc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_DESC',
  FieldsSumCompanyIntegrationIdAsc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_ASC',
  FieldsSumCompanyIntegrationIdDesc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_DESC',
  FieldsSumConfigAsc = 'FIELDS_SUM_CONFIG_ASC',
  FieldsSumConfigDesc = 'FIELDS_SUM_CONFIG_DESC',
  FieldsSumTopicAsc = 'FIELDS_SUM_TOPIC_ASC',
  FieldsSumTopicDesc = 'FIELDS_SUM_TOPIC_DESC',
  FieldsSumValueAsc = 'FIELDS_SUM_VALUE_ASC',
  FieldsSumValueDesc = 'FIELDS_SUM_VALUE_DESC',
  FieldsSumCreatedAtAsc = 'FIELDS_SUM_CREATED_AT_ASC',
  FieldsSumCreatedAtDesc = 'FIELDS_SUM_CREATED_AT_DESC',
  FieldsSumUpdatedAtAsc = 'FIELDS_SUM_UPDATED_AT_ASC',
  FieldsSumUpdatedAtDesc = 'FIELDS_SUM_UPDATED_AT_DESC',
  FieldsDistinctCountIdAsc = 'FIELDS_DISTINCT_COUNT_ID_ASC',
  FieldsDistinctCountIdDesc = 'FIELDS_DISTINCT_COUNT_ID_DESC',
  FieldsDistinctCountCompanyIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_ASC',
  FieldsDistinctCountCompanyIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_DESC',
  FieldsDistinctCountCompanyProductIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  FieldsDistinctCountCompanyProductIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  FieldsDistinctCountCompanyChannelIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  FieldsDistinctCountCompanyChannelIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  FieldsDistinctCountCompanyIntegrationIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  FieldsDistinctCountCompanyIntegrationIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  FieldsDistinctCountConfigAsc = 'FIELDS_DISTINCT_COUNT_CONFIG_ASC',
  FieldsDistinctCountConfigDesc = 'FIELDS_DISTINCT_COUNT_CONFIG_DESC',
  FieldsDistinctCountTopicAsc = 'FIELDS_DISTINCT_COUNT_TOPIC_ASC',
  FieldsDistinctCountTopicDesc = 'FIELDS_DISTINCT_COUNT_TOPIC_DESC',
  FieldsDistinctCountValueAsc = 'FIELDS_DISTINCT_COUNT_VALUE_ASC',
  FieldsDistinctCountValueDesc = 'FIELDS_DISTINCT_COUNT_VALUE_DESC',
  FieldsDistinctCountCreatedAtAsc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldsDistinctCountCreatedAtDesc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldsDistinctCountUpdatedAtAsc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldsDistinctCountUpdatedAtDesc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldsMinIdAsc = 'FIELDS_MIN_ID_ASC',
  FieldsMinIdDesc = 'FIELDS_MIN_ID_DESC',
  FieldsMinCompanyIdAsc = 'FIELDS_MIN_COMPANY_ID_ASC',
  FieldsMinCompanyIdDesc = 'FIELDS_MIN_COMPANY_ID_DESC',
  FieldsMinCompanyProductIdAsc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_ASC',
  FieldsMinCompanyProductIdDesc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_DESC',
  FieldsMinCompanyChannelIdAsc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_ASC',
  FieldsMinCompanyChannelIdDesc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_DESC',
  FieldsMinCompanyIntegrationIdAsc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_ASC',
  FieldsMinCompanyIntegrationIdDesc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_DESC',
  FieldsMinConfigAsc = 'FIELDS_MIN_CONFIG_ASC',
  FieldsMinConfigDesc = 'FIELDS_MIN_CONFIG_DESC',
  FieldsMinTopicAsc = 'FIELDS_MIN_TOPIC_ASC',
  FieldsMinTopicDesc = 'FIELDS_MIN_TOPIC_DESC',
  FieldsMinValueAsc = 'FIELDS_MIN_VALUE_ASC',
  FieldsMinValueDesc = 'FIELDS_MIN_VALUE_DESC',
  FieldsMinCreatedAtAsc = 'FIELDS_MIN_CREATED_AT_ASC',
  FieldsMinCreatedAtDesc = 'FIELDS_MIN_CREATED_AT_DESC',
  FieldsMinUpdatedAtAsc = 'FIELDS_MIN_UPDATED_AT_ASC',
  FieldsMinUpdatedAtDesc = 'FIELDS_MIN_UPDATED_AT_DESC',
  FieldsMaxIdAsc = 'FIELDS_MAX_ID_ASC',
  FieldsMaxIdDesc = 'FIELDS_MAX_ID_DESC',
  FieldsMaxCompanyIdAsc = 'FIELDS_MAX_COMPANY_ID_ASC',
  FieldsMaxCompanyIdDesc = 'FIELDS_MAX_COMPANY_ID_DESC',
  FieldsMaxCompanyProductIdAsc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_ASC',
  FieldsMaxCompanyProductIdDesc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_DESC',
  FieldsMaxCompanyChannelIdAsc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_ASC',
  FieldsMaxCompanyChannelIdDesc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_DESC',
  FieldsMaxCompanyIntegrationIdAsc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_ASC',
  FieldsMaxCompanyIntegrationIdDesc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_DESC',
  FieldsMaxConfigAsc = 'FIELDS_MAX_CONFIG_ASC',
  FieldsMaxConfigDesc = 'FIELDS_MAX_CONFIG_DESC',
  FieldsMaxTopicAsc = 'FIELDS_MAX_TOPIC_ASC',
  FieldsMaxTopicDesc = 'FIELDS_MAX_TOPIC_DESC',
  FieldsMaxValueAsc = 'FIELDS_MAX_VALUE_ASC',
  FieldsMaxValueDesc = 'FIELDS_MAX_VALUE_DESC',
  FieldsMaxCreatedAtAsc = 'FIELDS_MAX_CREATED_AT_ASC',
  FieldsMaxCreatedAtDesc = 'FIELDS_MAX_CREATED_AT_DESC',
  FieldsMaxUpdatedAtAsc = 'FIELDS_MAX_UPDATED_AT_ASC',
  FieldsMaxUpdatedAtDesc = 'FIELDS_MAX_UPDATED_AT_DESC',
  FieldsAverageIdAsc = 'FIELDS_AVERAGE_ID_ASC',
  FieldsAverageIdDesc = 'FIELDS_AVERAGE_ID_DESC',
  FieldsAverageCompanyIdAsc = 'FIELDS_AVERAGE_COMPANY_ID_ASC',
  FieldsAverageCompanyIdDesc = 'FIELDS_AVERAGE_COMPANY_ID_DESC',
  FieldsAverageCompanyProductIdAsc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  FieldsAverageCompanyProductIdDesc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  FieldsAverageCompanyChannelIdAsc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  FieldsAverageCompanyChannelIdDesc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  FieldsAverageCompanyIntegrationIdAsc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  FieldsAverageCompanyIntegrationIdDesc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  FieldsAverageConfigAsc = 'FIELDS_AVERAGE_CONFIG_ASC',
  FieldsAverageConfigDesc = 'FIELDS_AVERAGE_CONFIG_DESC',
  FieldsAverageTopicAsc = 'FIELDS_AVERAGE_TOPIC_ASC',
  FieldsAverageTopicDesc = 'FIELDS_AVERAGE_TOPIC_DESC',
  FieldsAverageValueAsc = 'FIELDS_AVERAGE_VALUE_ASC',
  FieldsAverageValueDesc = 'FIELDS_AVERAGE_VALUE_DESC',
  FieldsAverageCreatedAtAsc = 'FIELDS_AVERAGE_CREATED_AT_ASC',
  FieldsAverageCreatedAtDesc = 'FIELDS_AVERAGE_CREATED_AT_DESC',
  FieldsAverageUpdatedAtAsc = 'FIELDS_AVERAGE_UPDATED_AT_ASC',
  FieldsAverageUpdatedAtDesc = 'FIELDS_AVERAGE_UPDATED_AT_DESC',
  FieldsStddevSampleIdAsc = 'FIELDS_STDDEV_SAMPLE_ID_ASC',
  FieldsStddevSampleIdDesc = 'FIELDS_STDDEV_SAMPLE_ID_DESC',
  FieldsStddevSampleCompanyIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FieldsStddevSampleCompanyIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FieldsStddevSampleCompanyProductIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevSampleCompanyProductIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevSampleCompanyChannelIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevSampleCompanyChannelIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevSampleCompanyIntegrationIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevSampleCompanyIntegrationIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevSampleConfigAsc = 'FIELDS_STDDEV_SAMPLE_CONFIG_ASC',
  FieldsStddevSampleConfigDesc = 'FIELDS_STDDEV_SAMPLE_CONFIG_DESC',
  FieldsStddevSampleTopicAsc = 'FIELDS_STDDEV_SAMPLE_TOPIC_ASC',
  FieldsStddevSampleTopicDesc = 'FIELDS_STDDEV_SAMPLE_TOPIC_DESC',
  FieldsStddevSampleValueAsc = 'FIELDS_STDDEV_SAMPLE_VALUE_ASC',
  FieldsStddevSampleValueDesc = 'FIELDS_STDDEV_SAMPLE_VALUE_DESC',
  FieldsStddevSampleCreatedAtAsc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldsStddevSampleCreatedAtDesc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldsStddevSampleUpdatedAtAsc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldsStddevSampleUpdatedAtDesc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldsStddevPopulationIdAsc = 'FIELDS_STDDEV_POPULATION_ID_ASC',
  FieldsStddevPopulationIdDesc = 'FIELDS_STDDEV_POPULATION_ID_DESC',
  FieldsStddevPopulationCompanyIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_ASC',
  FieldsStddevPopulationCompanyIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_DESC',
  FieldsStddevPopulationCompanyProductIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevPopulationCompanyProductIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevPopulationCompanyChannelIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevPopulationCompanyChannelIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevPopulationCompanyIntegrationIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevPopulationCompanyIntegrationIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevPopulationConfigAsc = 'FIELDS_STDDEV_POPULATION_CONFIG_ASC',
  FieldsStddevPopulationConfigDesc = 'FIELDS_STDDEV_POPULATION_CONFIG_DESC',
  FieldsStddevPopulationTopicAsc = 'FIELDS_STDDEV_POPULATION_TOPIC_ASC',
  FieldsStddevPopulationTopicDesc = 'FIELDS_STDDEV_POPULATION_TOPIC_DESC',
  FieldsStddevPopulationValueAsc = 'FIELDS_STDDEV_POPULATION_VALUE_ASC',
  FieldsStddevPopulationValueDesc = 'FIELDS_STDDEV_POPULATION_VALUE_DESC',
  FieldsStddevPopulationCreatedAtAsc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldsStddevPopulationCreatedAtDesc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldsStddevPopulationUpdatedAtAsc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldsStddevPopulationUpdatedAtDesc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldsVarianceSampleIdAsc = 'FIELDS_VARIANCE_SAMPLE_ID_ASC',
  FieldsVarianceSampleIdDesc = 'FIELDS_VARIANCE_SAMPLE_ID_DESC',
  FieldsVarianceSampleCompanyIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FieldsVarianceSampleCompanyIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FieldsVarianceSampleCompanyProductIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsVarianceSampleCompanyProductIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsVarianceSampleCompanyChannelIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsVarianceSampleCompanyChannelIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsVarianceSampleCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsVarianceSampleCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsVarianceSampleConfigAsc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_ASC',
  FieldsVarianceSampleConfigDesc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_DESC',
  FieldsVarianceSampleTopicAsc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_ASC',
  FieldsVarianceSampleTopicDesc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_DESC',
  FieldsVarianceSampleValueAsc = 'FIELDS_VARIANCE_SAMPLE_VALUE_ASC',
  FieldsVarianceSampleValueDesc = 'FIELDS_VARIANCE_SAMPLE_VALUE_DESC',
  FieldsVarianceSampleCreatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldsVarianceSampleCreatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldsVarianceSampleUpdatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldsVarianceSampleUpdatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldsVariancePopulationIdAsc = 'FIELDS_VARIANCE_POPULATION_ID_ASC',
  FieldsVariancePopulationIdDesc = 'FIELDS_VARIANCE_POPULATION_ID_DESC',
  FieldsVariancePopulationCompanyIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FieldsVariancePopulationCompanyIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FieldsVariancePopulationCompanyProductIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsVariancePopulationCompanyProductIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsVariancePopulationCompanyChannelIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsVariancePopulationCompanyChannelIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsVariancePopulationCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsVariancePopulationCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsVariancePopulationConfigAsc = 'FIELDS_VARIANCE_POPULATION_CONFIG_ASC',
  FieldsVariancePopulationConfigDesc = 'FIELDS_VARIANCE_POPULATION_CONFIG_DESC',
  FieldsVariancePopulationTopicAsc = 'FIELDS_VARIANCE_POPULATION_TOPIC_ASC',
  FieldsVariancePopulationTopicDesc = 'FIELDS_VARIANCE_POPULATION_TOPIC_DESC',
  FieldsVariancePopulationValueAsc = 'FIELDS_VARIANCE_POPULATION_VALUE_ASC',
  FieldsVariancePopulationValueDesc = 'FIELDS_VARIANCE_POPULATION_VALUE_DESC',
  FieldsVariancePopulationCreatedAtAsc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldsVariancePopulationCreatedAtDesc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldsVariancePopulationUpdatedAtAsc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldsVariancePopulationUpdatedAtDesc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type CompanyRequestedAccess = {
  topic: Scalars['String'];
  timestamp: Scalars['Datetime'];
  userEmail: Scalars['String'];
};

export type CompanyReviewConfig = {
  googlePlacesId: Scalars['String'];
};

export type CompanySalesOrder = {
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<SalesOrderOwner>;
  tax?: Maybe<Scalars['String']>;
  totalDueOnSigning?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['String']>;
  grandTotal?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  paymentUrl?: Maybe<Scalars['String']>;
  collected?: Maybe<Scalars['String']>;
  accepted?: Maybe<Scalars['Boolean']>;
  portalAccess?: Maybe<Scalars['Boolean']>;
  billing?: Maybe<BillingAddress>;
  lineTax: Array<LineTax>;
  items: Array<LineItem>;
};

export type CompanyStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  primaryGroup?: Maybe<BigFloatFilter>;
  billableCompanyId?: Maybe<BigFloatFilter>;
  accountManager?: Maybe<BigFloatFilter>;
  supportAgent?: Maybe<BigFloatFilter>;
};

export type CompanyStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of accountManager across the matching connection */
  accountManager?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of supportAgent across the matching connection */
  supportAgent?: Maybe<Scalars['BigFloat']>;
};

export type CompanyStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  primaryGroup?: Maybe<BigFloatFilter>;
  billableCompanyId?: Maybe<BigFloatFilter>;
  accountManager?: Maybe<BigFloatFilter>;
  supportAgent?: Maybe<BigFloatFilter>;
};

export type CompanyStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of accountManager across the matching connection */
  accountManager?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of supportAgent across the matching connection */
  supportAgent?: Maybe<Scalars['BigFloat']>;
};

export type CompanySubscription = {
  id?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  providerCustomerId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nextPlanCode?: Maybe<Scalars['String']>;
  planCode?: Maybe<Scalars['String']>;
  previousPlanCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  billingTime?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  endingAt?: Maybe<Scalars['Datetime']>;
  startedAt?: Maybe<Scalars['Datetime']>;
  subscriptionAt?: Maybe<Scalars['Datetime']>;
  terminatedAt?: Maybe<Scalars['Datetime']>;
  usage?: Maybe<SubscriptionUsage>;
};

export type CompanySumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  primaryGroup?: Maybe<BigIntFilter>;
  billableCompanyId?: Maybe<BigIntFilter>;
  accountManager?: Maybe<BigIntFilter>;
  supportAgent?: Maybe<BigIntFilter>;
};

export type CompanySumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of primaryGroup across the matching connection */
  primaryGroup: Scalars['BigInt'];
  /** Sum of billableCompanyId across the matching connection */
  billableCompanyId: Scalars['BigInt'];
  /** Sum of accountManager across the matching connection */
  accountManager: Scalars['BigInt'];
  /** Sum of supportAgent across the matching connection */
  supportAgent: Scalars['BigInt'];
};

/** The `company` to be created by this mutation. */
export type CompanySupportAgentFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `user` in the `CompanyInput` mutation. */
export type CompanySupportAgentFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnCompanyForCompanySupportAgentFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyOnCompanyForCompanySupportAgentFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `company` in the `UserInput` mutation. */
export type CompanySupportAgentFkeyInverseInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyCompanyPkeyConnect>>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<Array<CompanyCompanyNameKeyConnect>>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<Array<CompanyCompanyExternalIdKeyConnect>>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<Array<CompanyCompanyPrimaryGroupKeyConnect>>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<Array<CompanyCompanyLagoIdKeyConnect>>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyNodeIdConnect>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyOnCompanyForCompanySupportAgentFkeyUsingCompanyPkeyUpdate>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<Array<CompanyOnCompanyForCompanySupportAgentFkeyUsingCompanyNameKeyUpdate>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<Array<CompanyOnCompanyForCompanySupportAgentFkeyUsingCompanyExternalIdKeyUpdate>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<Array<CompanyOnCompanyForCompanySupportAgentFkeyUsingCompanyPrimaryGroupKeyUpdate>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<Array<CompanyOnCompanyForCompanySupportAgentFkeyUsingCompanyLagoIdKeyUpdate>>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnCompanyForCompanySupportAgentFkeyNodeIdUpdate>>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanySupportAgentFkeyCompanyCreateInput>>;
};

export type CompanyTemplateMeta = {
  branchCode?: Maybe<Scalars['String']>;
};

/** A filter to be used against many `Analytic` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyAnalyticFilter = {
  /** Every related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AnalyticFilter>;
  /** Some related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AnalyticFilter>;
  /** No related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AnalyticFilter>;
  /** Aggregates across related `Analytic` match the filter criteria. */
  aggregates?: Maybe<AnalyticAggregatesFilter>;
};

/** A filter to be used against many `CompanyAnalytic` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyAnalyticFilter = {
  /** Every related `CompanyAnalytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyAnalyticFilter>;
  /** Some related `CompanyAnalytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyAnalyticFilter>;
  /** No related `CompanyAnalytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyAnalyticFilter>;
  /** Aggregates across related `CompanyAnalytic` match the filter criteria. */
  aggregates?: Maybe<CompanyAnalyticAggregatesFilter>;
};

/** A filter to be used against many `CompanyChannel` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyChannelFilter = {
  /** Every related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyChannelFilter>;
  /** Some related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyChannelFilter>;
  /** No related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyChannelFilter>;
  /** Aggregates across related `CompanyChannel` match the filter criteria. */
  aggregates?: Maybe<CompanyChannelAggregatesFilter>;
};

/** A filter to be used against many `CompanyDomain` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyDomainFilter = {
  /** Every related `CompanyDomain` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyDomainFilter>;
  /** Some related `CompanyDomain` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyDomainFilter>;
  /** No related `CompanyDomain` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyDomainFilter>;
  /** Aggregates across related `CompanyDomain` match the filter criteria. */
  aggregates?: Maybe<CompanyDomainAggregatesFilter>;
};

/** A filter to be used against many `CompanyDomainsApproved` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyDomainsApprovedFilter = {
  /** Every related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyDomainsApprovedFilter>;
  /** Some related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyDomainsApprovedFilter>;
  /** No related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyDomainsApprovedFilter>;
  /** Aggregates across related `CompanyDomainsApproved` match the filter criteria. */
  aggregates?: Maybe<CompanyDomainsApprovedAggregatesFilter>;
};

/** A filter to be used against many `CompanyDomainsPath` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyDomainsPathFilter = {
  /** Every related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyDomainsPathFilter>;
  /** Some related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyDomainsPathFilter>;
  /** No related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyDomainsPathFilter>;
  /** Aggregates across related `CompanyDomainsPath` match the filter criteria. */
  aggregates?: Maybe<CompanyDomainsPathAggregatesFilter>;
};

/** A filter to be used against many `CompanyGroup` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyGroupFilter = {
  /** Every related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyGroupFilter>;
  /** Some related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyGroupFilter>;
  /** No related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyGroupFilter>;
  /** Aggregates across related `CompanyGroup` match the filter criteria. */
  aggregates?: Maybe<CompanyGroupAggregatesFilter>;
};

/** A filter to be used against many `CompanyIntegration` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyIntegrationFilter = {
  /** Every related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyIntegrationFilter>;
  /** Some related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyIntegrationFilter>;
  /** No related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyIntegrationFilter>;
  /** Aggregates across related `CompanyIntegration` match the filter criteria. */
  aggregates?: Maybe<CompanyIntegrationAggregatesFilter>;
};

/** A filter to be used against many `CompanyLocation` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyLocationFilter = {
  /** Every related `CompanyLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyLocationFilter>;
  /** Some related `CompanyLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyLocationFilter>;
  /** No related `CompanyLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyLocationFilter>;
  /** Aggregates across related `CompanyLocation` match the filter criteria. */
  aggregates?: Maybe<CompanyLocationAggregatesFilter>;
};

/** A filter to be used against many `CompanyProduct` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyProductFilter = {
  /** Every related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyProductFilter>;
  /** Some related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyProductFilter>;
  /** No related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyProductFilter>;
  /** Aggregates across related `CompanyProduct` match the filter criteria. */
  aggregates?: Maybe<CompanyProductAggregatesFilter>;
};

/** A filter to be used against many `CompanyUser` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyUserFilter = {
  /** Every related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyUserFilter>;
  /** Some related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyUserFilter>;
  /** No related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyUserFilter>;
  /** Aggregates across related `CompanyUser` match the filter criteria. */
  aggregates?: Maybe<CompanyUserAggregatesFilter>;
};

/** A filter to be used against many `DeliveryMethodsInternal` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyDeliveryMethodsInternalFilter = {
  /** Every related `DeliveryMethodsInternal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<DeliveryMethodsInternalFilter>;
  /** Some related `DeliveryMethodsInternal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<DeliveryMethodsInternalFilter>;
  /** No related `DeliveryMethodsInternal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<DeliveryMethodsInternalFilter>;
  /** Aggregates across related `DeliveryMethodsInternal` match the filter criteria. */
  aggregates?: Maybe<DeliveryMethodsInternalAggregatesFilter>;
};

/** A filter to be used against many `DistributedLead` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyDistributedLeadFilter = {
  /** Every related `DistributedLead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<DistributedLeadFilter>;
  /** Some related `DistributedLead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<DistributedLeadFilter>;
  /** No related `DistributedLead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<DistributedLeadFilter>;
  /** Aggregates across related `DistributedLead` match the filter criteria. */
  aggregates?: Maybe<DistributedLeadAggregatesFilter>;
};

/** A filter to be used against many `DistributorRelationship` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyDistributorRelationshipFilter = {
  /** Every related `DistributorRelationship` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<DistributorRelationshipFilter>;
  /** Some related `DistributorRelationship` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<DistributorRelationshipFilter>;
  /** No related `DistributorRelationship` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<DistributorRelationshipFilter>;
  /** Aggregates across related `DistributorRelationship` match the filter criteria. */
  aggregates?: Maybe<DistributorRelationshipAggregatesFilter>;
};

/** A filter to be used against many `Field` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyFieldFilter = {
  /** Every related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldFilter>;
  /** Some related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldFilter>;
  /** No related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldFilter>;
  /** Aggregates across related `Field` match the filter criteria. */
  aggregates?: Maybe<FieldAggregatesFilter>;
};

/** A filter to be used against many `Lead` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyLeadFilter = {
  /** Every related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeadFilter>;
  /** Some related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeadFilter>;
  /** No related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeadFilter>;
  /** Aggregates across related `Lead` match the filter criteria. */
  aggregates?: Maybe<LeadAggregatesFilter>;
};

/** A filter to be used against many `Media` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyMediaFilter = {
  /** Every related `Media` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MediaFilter>;
  /** Some related `Media` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MediaFilter>;
  /** No related `Media` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MediaFilter>;
  /** Aggregates across related `Media` match the filter criteria. */
  aggregates?: Maybe<MediaAggregatesFilter>;
};

/** A filter to be used against many `Review` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyReviewFilter = {
  /** Every related `Review` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ReviewFilter>;
  /** Some related `Review` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ReviewFilter>;
  /** No related `Review` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ReviewFilter>;
  /** Aggregates across related `Review` match the filter criteria. */
  aggregates?: Maybe<ReviewAggregatesFilter>;
};

/** A filter to be used against many `VehicleStock` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyVehicleStockFilter = {
  /** Every related `VehicleStock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<VehicleStockFilter>;
  /** Some related `VehicleStock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<VehicleStockFilter>;
  /** No related `VehicleStock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<VehicleStockFilter>;
  /** Aggregates across related `VehicleStock` match the filter criteria. */
  aggregates?: Maybe<VehicleStockAggregatesFilter>;
};

export type CompanyTradeToolMeta = {
  disableTradeValue: Scalars['Boolean'];
  /**  Example values: +10, -10  */
  modifyByPercentage?: Maybe<Scalars['Int']>;
  provider?: Maybe<TradeProvider>;
};

export type CompanyUser = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  role: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `CompanyUser`. */
  company?: Maybe<Company>;
  /** Reads a single `Group` that is related to this `CompanyUser`. */
  group?: Maybe<Group>;
  /** Reads a single `User` that is related to this `CompanyUser`. */
  user?: Maybe<User>;
};

export type CompanyUserAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyUserSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyUserDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyUserMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyUserMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyUserAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyUserStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyUserStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyUserVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyUserVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyUser` object types. */
export type CompanyUserAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyUser` object to be included within the aggregate. */
  filter?: Maybe<CompanyUserFilter>;
  /** Sum aggregate over matching `CompanyUser` objects. */
  sum?: Maybe<CompanyUserSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyUser` objects. */
  distinctCount?: Maybe<CompanyUserDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyUser` objects. */
  min?: Maybe<CompanyUserMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyUser` objects. */
  max?: Maybe<CompanyUserMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyUser` objects. */
  average?: Maybe<CompanyUserAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyUser` objects. */
  stddevSample?: Maybe<CompanyUserStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyUser` objects. */
  stddevPopulation?: Maybe<CompanyUserStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyUser` objects. */
  varianceSample?: Maybe<CompanyUserVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyUser` objects. */
  variancePopulation?: Maybe<CompanyUserVariancePopulationAggregateFilter>;
};

export type CompanyUserAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type CompanyUserAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

/** The `company` to be created by this mutation. */
export type CompanyUserCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `companyUser` to be created by this mutation. */
export type CompanyUserCompanyIdFkeyCompanyUserCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyUserCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyUserGroupIdFkeyInput>;
  userToUserId?: Maybe<CompanyUserUserIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `CompanyUserInput` mutation. */
export type CompanyUserCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyUserOnCompanyUserForCompanyUserCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyUserCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyUser` in the `CompanyInput` mutation. */
export type CompanyUserCompanyIdFkeyInverseInput = {
  /** The primary key(s) for `companyUser` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyUserCompanyUserPkeyConnect>>;
  /** The primary key(s) for `companyUser` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyUserNodeIdConnect>>;
  /** The primary key(s) and patch data for `companyUser` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyUserOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyUser` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyUserForCompanyUserCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyUserInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyUserCompanyIdFkeyCompanyUserCreateInput>>;
};

/** The fields on `companyUser` to look up the row to connect. */
export type CompanyUserCompanyUserPkeyConnect = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CompanyUserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `role` field. */
  role?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyUserDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
  userId?: Maybe<BigIntFilter>;
  role?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyUserDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of role across the matching connection */
  role?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyUser` object types. All fields are combined with a logical ‘and.’ */
export type CompanyUserFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<IntFilter>;
  /** Filter by the object’s `role` field. */
  role?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<GroupFilter>;
  /** A related `group` exists. */
  groupExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `user` relation. */
  user?: Maybe<UserFilter>;
  /** A related `user` exists. */
  userExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyUserFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyUserFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyUserFilter>;
};

/** Grouping methods for `CompanyUser` for usage during aggregation. */
export enum CompanyUserGroupBy {
  CompanyId = 'COMPANY_ID',
  GroupId = 'GROUP_ID',
  UserId = 'USER_ID',
  Role = 'ROLE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

/** The `companyUser` to be created by this mutation. */
export type CompanyUserGroupIdFkeyCompanyUserCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyUserCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyUserGroupIdFkeyInput>;
  userToUserId?: Maybe<CompanyUserUserIdFkeyInput>;
};

/** The `group` to be created by this mutation. */
export type CompanyUserGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `CompanyUserInput` mutation. */
export type CompanyUserGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCompanyUserForCompanyUserGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnCompanyUserForCompanyUserGroupIdFkeyUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyUserOnCompanyUserForCompanyUserGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyUserGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `companyUser` in the `GroupInput` mutation. */
export type CompanyUserGroupIdFkeyInverseInput = {
  /** The primary key(s) for `companyUser` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyUserCompanyUserPkeyConnect>>;
  /** The primary key(s) for `companyUser` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyUserNodeIdConnect>>;
  /** The primary key(s) and patch data for `companyUser` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyUserOnCompanyUserForCompanyUserGroupIdFkeyUsingCompanyUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyUser` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnCompanyUserForCompanyUserGroupIdFkeyNodeIdUpdate>>;
  /** A `CompanyUserInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyUserGroupIdFkeyCompanyUserCreateInput>>;
};

export type CompanyUserHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyUserHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyUser` aggregates. */
export type CompanyUserHavingInput = {
  AND?: Maybe<Array<CompanyUserHavingInput>>;
  OR?: Maybe<Array<CompanyUserHavingInput>>;
  sum?: Maybe<CompanyUserHavingSumInput>;
  distinctCount?: Maybe<CompanyUserHavingDistinctCountInput>;
  min?: Maybe<CompanyUserHavingMinInput>;
  max?: Maybe<CompanyUserHavingMaxInput>;
  average?: Maybe<CompanyUserHavingAverageInput>;
  stddevSample?: Maybe<CompanyUserHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyUserHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyUserHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyUserHavingVariancePopulationInput>;
};

export type CompanyUserHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyUserHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyUserHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyUserHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyUserHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyUserHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyUserHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyUser` */
export type CompanyUserInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyUserCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyUserGroupIdFkeyInput>;
  userToUserId?: Maybe<CompanyUserUserIdFkeyInput>;
};

export type CompanyUserMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
  userId?: Maybe<IntFilter>;
};

export type CompanyUserMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
  /** Maximum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
};

export type CompanyUserMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
  userId?: Maybe<IntFilter>;
};

export type CompanyUserMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
  /** Minimum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyUserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyUser` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyUserOnCompanyUserForCompanyUserCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyUser` to look up the row to update. */
export type CompanyUserOnCompanyUserForCompanyUserCompanyIdFkeyUsingCompanyUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyUser` being updated. */
  patch: UpdateCompanyUserOnCompanyUserForCompanyUserCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyUserOnCompanyUserForCompanyUserGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `companyUser` to look up the row to update. */
export type CompanyUserOnCompanyUserForCompanyUserGroupIdFkeyUsingCompanyUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyUser` being updated. */
  patch: UpdateCompanyUserOnCompanyUserForCompanyUserGroupIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyUserOnCompanyUserForCompanyUserUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `companyUser` to look up the row to update. */
export type CompanyUserOnCompanyUserForCompanyUserUserIdFkeyUsingCompanyUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyUser` being updated. */
  patch: UpdateCompanyUserOnCompanyUserForCompanyUserUserIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyUser`. Fields that are set will be updated. */
export type CompanyUserPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyUserCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyUserGroupIdFkeyInput>;
  userToUserId?: Maybe<CompanyUserUserIdFkeyInput>;
};

export type CompanyUserStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type CompanyUserStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyUserStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type CompanyUserStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyUserSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
  userId?: Maybe<BigIntFilter>;
};

export type CompanyUserSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt'];
};

/** The `companyUser` to be created by this mutation. */
export type CompanyUserUserIdFkeyCompanyUserCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyUserCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyUserGroupIdFkeyInput>;
  userToUserId?: Maybe<CompanyUserUserIdFkeyInput>;
};

/** Input for the nested mutation of `user` in the `CompanyUserInput` mutation. */
export type CompanyUserUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnCompanyUserForCompanyUserUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyUserOnCompanyUserForCompanyUserUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `companyUser` in the `UserInput` mutation. */
export type CompanyUserUserIdFkeyInverseInput = {
  /** The primary key(s) for `companyUser` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyUserCompanyUserPkeyConnect>>;
  /** The primary key(s) for `companyUser` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyUserNodeIdConnect>>;
  /** The primary key(s) and patch data for `companyUser` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyUserOnCompanyUserForCompanyUserUserIdFkeyUsingCompanyUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyUser` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnCompanyUserForCompanyUserUserIdFkeyNodeIdUpdate>>;
  /** A `CompanyUserInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyUserUserIdFkeyCompanyUserCreateInput>>;
};

export type CompanyUserVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type CompanyUserVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyUserVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type CompanyUserVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `User` values, with data from `CompanyUser`. */
export type CompanyUsersByCompanyUserCompanyIdAndUserIdManyToManyConnection = {
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `CompanyUser`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByCompanyUserCompanyIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
};


/** A connection to a list of `User` values, with data from `CompanyUser`. */
export type CompanyUsersByCompanyUserCompanyIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having?: Maybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `CompanyUser`. */
export type CompanyUsersByCompanyUserCompanyIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsers: CompanyUsersConnection;
};


/** A `User` edge in the connection, with data from `CompanyUser`. */
export type CompanyUsersByCompanyUserCompanyIdAndUserIdManyToManyEdgeCompanyUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyUsersOrderBy>>;
  condition?: Maybe<CompanyUserCondition>;
  filter?: Maybe<CompanyUserFilter>;
};

/** A connection to a list of `User` values, with data from `Media`. */
export type CompanyUsersByMediaCompanyIdAndUploaderManyToManyConnection = {
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Media`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByMediaCompanyIdAndUploaderManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
};


/** A connection to a list of `User` values, with data from `Media`. */
export type CompanyUsersByMediaCompanyIdAndUploaderManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having?: Maybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Media`. */
export type CompanyUsersByMediaCompanyIdAndUploaderManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Media`. */
  mediaByUploader: MediaConnection;
};


/** A `User` edge in the connection, with data from `Media`. */
export type CompanyUsersByMediaCompanyIdAndUploaderManyToManyEdgeMediaByUploaderArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaOrderBy>>;
  condition?: Maybe<MediaCondition>;
  filter?: Maybe<MediaFilter>;
};

/** A connection to a list of `CompanyUser` values. */
export type CompanyUsersConnection = {
  /** A list of `CompanyUser` objects. */
  nodes: Array<CompanyUser>;
  /** A list of edges which contains the `CompanyUser` and cursor to aid in pagination. */
  edges: Array<CompanyUsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyUser` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyUserAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyUserAggregates>>;
};


/** A connection to a list of `CompanyUser` values. */
export type CompanyUsersConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyUserGroupBy>;
  having?: Maybe<CompanyUserHavingInput>;
};

/** A `CompanyUser` edge in the connection. */
export type CompanyUsersEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyUser` at the end of the edge. */
  node: CompanyUser;
};

/** Methods to use when ordering `CompanyUser`. */
export enum CompanyUsersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  RoleAsc = 'ROLE_ASC',
  RoleDesc = 'ROLE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompanyVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  primaryGroup?: Maybe<BigFloatFilter>;
  billableCompanyId?: Maybe<BigFloatFilter>;
  accountManager?: Maybe<BigFloatFilter>;
  supportAgent?: Maybe<BigFloatFilter>;
};

export type CompanyVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigFloat']>;
  /** Population variance of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of accountManager across the matching connection */
  accountManager?: Maybe<Scalars['BigFloat']>;
  /** Population variance of supportAgent across the matching connection */
  supportAgent?: Maybe<Scalars['BigFloat']>;
};

export type CompanyVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  primaryGroup?: Maybe<BigFloatFilter>;
  billableCompanyId?: Maybe<BigFloatFilter>;
  accountManager?: Maybe<BigFloatFilter>;
  supportAgent?: Maybe<BigFloatFilter>;
};

export type CompanyVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of accountManager across the matching connection */
  accountManager?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of supportAgent across the matching connection */
  supportAgent?: Maybe<Scalars['BigFloat']>;
};

export type CompanyWidget = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
  specOverride?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Group` that is related to this `CompanyWidget`. */
  group?: Maybe<Group>;
  /** Reads a single `Widget` that is related to this `CompanyWidget`. */
  widget?: Maybe<Widget>;
};

export type CompanyWidgetAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyWidgetSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyWidgetDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyWidgetMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyWidgetMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyWidgetAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyWidgetStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyWidgetStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyWidgetVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyWidgetVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyWidget` object types. */
export type CompanyWidgetAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyWidget` object to be included within the aggregate. */
  filter?: Maybe<CompanyWidgetFilter>;
  /** Sum aggregate over matching `CompanyWidget` objects. */
  sum?: Maybe<CompanyWidgetSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyWidget` objects. */
  distinctCount?: Maybe<CompanyWidgetDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyWidget` objects. */
  min?: Maybe<CompanyWidgetMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyWidget` objects. */
  max?: Maybe<CompanyWidgetMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyWidget` objects. */
  average?: Maybe<CompanyWidgetAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyWidget` objects. */
  stddevSample?: Maybe<CompanyWidgetStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyWidget` objects. */
  stddevPopulation?: Maybe<CompanyWidgetStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyWidget` objects. */
  varianceSample?: Maybe<CompanyWidgetVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyWidget` objects. */
  variancePopulation?: Maybe<CompanyWidgetVariancePopulationAggregateFilter>;
};

export type CompanyWidgetAverageAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyWidgetAverageAggregates = {
  /** Mean average of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `companyWidget` to look up the row to connect. */
export type CompanyWidgetCompanyWidgetsPkeyConnect = {
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/** The fields on `companyWidget` to look up the row to delete. */
export type CompanyWidgetCompanyWidgetsPkeyDelete = {
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/**
 * A condition to be used against `CompanyWidget` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyWidgetCondition = {
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `widgetId` field. */
  widgetId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `specOverride` field. */
  specOverride?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyWidgetDistinctCountAggregateFilter = {
  groupId?: Maybe<BigIntFilter>;
  widgetId?: Maybe<BigIntFilter>;
  specOverride?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyWidgetDistinctCountAggregates = {
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetId across the matching connection */
  widgetId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of specOverride across the matching connection */
  specOverride?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyWidget` object types. All fields are combined with a logical ‘and.’ */
export type CompanyWidgetFilter = {
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<IntFilter>;
  /** Filter by the object’s `widgetId` field. */
  widgetId?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<GroupFilter>;
  /** Filter by the object’s `widget` relation. */
  widget?: Maybe<WidgetFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyWidgetFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyWidgetFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyWidgetFilter>;
};

/** An input for mutations affecting `CompanyWidget` */
export type CompanyWidgetInput = {
  groupId?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  specOverride?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

export type CompanyWidgetMaxAggregateFilter = {
  groupId?: Maybe<IntFilter>;
};

export type CompanyWidgetMaxAggregates = {
  /** Maximum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

export type CompanyWidgetMinAggregateFilter = {
  groupId?: Maybe<IntFilter>;
};

export type CompanyWidgetMinAggregates = {
  /** Minimum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyWidgetNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyWidget` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyWidgetNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyWidget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `companyWidget` to look up the row to update. */
export type CompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingCompanyWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyWidget` being updated. */
  patch: UpdateCompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkPatch;
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `widget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: WidgetPatch;
};

/** The fields on `companyWidget` to look up the row to update. */
export type CompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingCompanyWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyWidget` being updated. */
  patch: UpdateCompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkPatch;
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/** Represents an update to a `CompanyWidget`. Fields that are set will be updated. */
export type CompanyWidgetPatch = {
  groupId?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  specOverride?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

export type CompanyWidgetStddevPopulationAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyWidgetStddevPopulationAggregates = {
  /** Population standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyWidgetStddevSampleAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyWidgetStddevSampleAggregates = {
  /** Sample standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyWidgetSumAggregateFilter = {
  groupId?: Maybe<BigIntFilter>;
};

export type CompanyWidgetSumAggregates = {
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt'];
};

export type CompanyWidgetVariancePopulationAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyWidgetVariancePopulationAggregates = {
  /** Population variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyWidgetVarianceSampleAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyWidgetVarianceSampleAggregates = {
  /** Sample variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyWidget` values. */
export type CompanyWidgetsConnection = {
  /** A list of `CompanyWidget` objects. */
  nodes: Array<CompanyWidget>;
  /** A list of edges which contains the `CompanyWidget` and cursor to aid in pagination. */
  edges: Array<CompanyWidgetsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyWidget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyWidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyWidgetAggregates>>;
};


/** A connection to a list of `CompanyWidget` values. */
export type CompanyWidgetsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyWidgetsGroupBy>;
  having?: Maybe<CompanyWidgetsHavingInput>;
};

/** A `CompanyWidget` edge in the connection. */
export type CompanyWidgetsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyWidget` at the end of the edge. */
  node: CompanyWidget;
};

/** Grouping methods for `CompanyWidget` for usage during aggregation. */
export enum CompanyWidgetsGroupBy {
  GroupId = 'GROUP_ID',
  WidgetId = 'WIDGET_ID',
  SpecOverride = 'SPEC_OVERRIDE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

/** The `companyWidget` to be created by this mutation. */
export type CompanyWidgetsGroupIdFkCompanyWidgetsCreateInput = {
  groupId?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  specOverride?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

/** The `group` to be created by this mutation. */
export type CompanyWidgetsGroupIdFkGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `CompanyWidgetInput` mutation. */
export type CompanyWidgetsGroupIdFkInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyWidgetsGroupIdFkGroupCreateInput>;
};

/** Input for the nested mutation of `companyWidget` in the `GroupInput` mutation. */
export type CompanyWidgetsGroupIdFkInverseInput = {
  /** Flag indicating whether all other `companyWidget` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  connectByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetCompanyWidgetsPkeyConnect>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyWidgetNodeIdConnect>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  deleteByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetCompanyWidgetsPkeyDelete>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyWidgetNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyWidget` for the far side of the relationship. */
  updateByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingCompanyWidgetsPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyWidget` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkNodeIdUpdate>>;
  /** A `CompanyWidgetInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyWidgetsGroupIdFkCompanyWidgetsCreateInput>>;
};

export type CompanyWidgetsHavingAverageInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyWidgetsHavingDistinctCountInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyWidget` aggregates. */
export type CompanyWidgetsHavingInput = {
  AND?: Maybe<Array<CompanyWidgetsHavingInput>>;
  OR?: Maybe<Array<CompanyWidgetsHavingInput>>;
  sum?: Maybe<CompanyWidgetsHavingSumInput>;
  distinctCount?: Maybe<CompanyWidgetsHavingDistinctCountInput>;
  min?: Maybe<CompanyWidgetsHavingMinInput>;
  max?: Maybe<CompanyWidgetsHavingMaxInput>;
  average?: Maybe<CompanyWidgetsHavingAverageInput>;
  stddevSample?: Maybe<CompanyWidgetsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyWidgetsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyWidgetsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyWidgetsHavingVariancePopulationInput>;
};

export type CompanyWidgetsHavingMaxInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyWidgetsHavingMinInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyWidgetsHavingStddevPopulationInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyWidgetsHavingStddevSampleInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyWidgetsHavingSumInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyWidgetsHavingVariancePopulationInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyWidgetsHavingVarianceSampleInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CompanyWidget`. */
export enum CompanyWidgetsOrderBy {
  Natural = 'NATURAL',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  WidgetIdAsc = 'WIDGET_ID_ASC',
  WidgetIdDesc = 'WIDGET_ID_DESC',
  SpecOverrideAsc = 'SPEC_OVERRIDE_ASC',
  SpecOverrideDesc = 'SPEC_OVERRIDE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `companyWidget` to be created by this mutation. */
export type CompanyWidgetsWidgetIdFkCompanyWidgetsCreateInput = {
  groupId?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  specOverride?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

/** Input for the nested mutation of `widget` in the `CompanyWidgetInput` mutation. */
export type CompanyWidgetsWidgetIdFkInput = {
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectById?: Maybe<WidgetWidgetsPkeyConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByVariant?: Maybe<WidgetWidgetsUqVariantConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByNodeId?: Maybe<WidgetNodeIdConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteById?: Maybe<WidgetWidgetsPkeyDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByVariant?: Maybe<WidgetWidgetsUqVariantDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<WidgetNodeIdDelete>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateById?: Maybe<WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingWidgetsPkeyUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByVariant?: Maybe<WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingWidgetsUqVariantUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkNodeIdUpdate>;
  /** A `WidgetInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyWidgetsWidgetIdFkWidgetsCreateInput>;
};

/** Input for the nested mutation of `companyWidget` in the `WidgetInput` mutation. */
export type CompanyWidgetsWidgetIdFkInverseInput = {
  /** Flag indicating whether all other `companyWidget` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  connectByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetCompanyWidgetsPkeyConnect>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyWidgetNodeIdConnect>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  deleteByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetCompanyWidgetsPkeyDelete>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyWidgetNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyWidget` for the far side of the relationship. */
  updateByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingCompanyWidgetsPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyWidget` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkNodeIdUpdate>>;
  /** A `CompanyWidgetInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyWidgetsWidgetIdFkCompanyWidgetsCreateInput>>;
};

/** The `widget` to be created by this mutation. */
export type CompanyWidgetsWidgetIdFkWidgetsCreateInput = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

export type ConfirmUserSignUpInput = {
  username: Scalars['String'];
  confirmationCode: Scalars['String'];
};

export type ConfirmUserSignUpResponse = {
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type CopyPermissionsInput = {
  fromUsername: Scalars['String'];
  toUsername: Scalars['String'];
};

export type CopyPermissionsResponse = {
  success?: Maybe<Scalars['Boolean']>;
  removedFromGroups?: Maybe<Array<Scalars['String']>>;
  addedToGroups?: Maybe<Array<Scalars['String']>>;
};

/** All input for the create `Analytic` mutation. */
export type CreateAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Analytic` to be created by this mutation. */
  analytic: AnalyticInput;
};

/** The output of our create `Analytic` mutation. */
export type CreateAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Analytic` that was created by this mutation. */
  analytic?: Maybe<Analytic>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Analytic`. */
  company?: Maybe<Company>;
  /** An edge for our `Analytic`. May be used by Relay 1. */
  analyticEdge?: Maybe<AnalyticsEdge>;
};


/** The output of our create `Analytic` mutation. */
export type CreateAnalyticPayloadAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsOrderBy>>;
};

/** All input for the create `AnalyticsCta` mutation. */
export type CreateAnalyticsCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AnalyticsCta` to be created by this mutation. */
  analyticsCta: AnalyticsCtaInput;
};

/** The output of our create `AnalyticsCta` mutation. */
export type CreateAnalyticsCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AnalyticsCta` that was created by this mutation. */
  analyticsCta?: Maybe<AnalyticsCta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AnalyticsCta`. May be used by Relay 1. */
  analyticsCtaEdge?: Maybe<AnalyticsCtasEdge>;
};


/** The output of our create `AnalyticsCta` mutation. */
export type CreateAnalyticsCtaPayloadAnalyticsCtaEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsCtasOrderBy>>;
};

/** All input for the create `Appointment` mutation. */
export type CreateAppointmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Appointment` to be created by this mutation. */
  appointment: AppointmentInput;
};

/** The output of our create `Appointment` mutation. */
export type CreateAppointmentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Appointment` that was created by this mutation. */
  appointment?: Maybe<Appointment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `Appointment`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `Appointment`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `Appointment`. May be used by Relay 1. */
  appointmentEdge?: Maybe<AppointmentsEdge>;
};


/** The output of our create `Appointment` mutation. */
export type CreateAppointmentPayloadAppointmentEdgeArgs = {
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
};

/** All input for the create `AvaApp` mutation. */
export type CreateAvaAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AvaApp` to be created by this mutation. */
  avaApp: AvaAppInput;
};

/** The output of our create `AvaApp` mutation. */
export type CreateAvaAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AvaApp` that was created by this mutation. */
  avaApp?: Maybe<AvaApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `AvaApp`. */
  lead?: Maybe<Lead>;
  /** An edge for our `AvaApp`. May be used by Relay 1. */
  avaAppEdge?: Maybe<AvaAppsEdge>;
};


/** The output of our create `AvaApp` mutation. */
export type CreateAvaAppPayloadAvaAppEdgeArgs = {
  orderBy?: Maybe<Array<AvaAppsOrderBy>>;
};

/** All input for the create `BillableMetricLog` mutation. */
export type CreateBillableMetricLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BillableMetricLog` to be created by this mutation. */
  billableMetricLog: BillableMetricLogInput;
};

/** The output of our create `BillableMetricLog` mutation. */
export type CreateBillableMetricLogPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BillableMetricLog` that was created by this mutation. */
  billableMetricLog?: Maybe<BillableMetricLog>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `BillableMetricLog`. May be used by Relay 1. */
  billableMetricLogEdge?: Maybe<BillableMetricLogsEdge>;
};


/** The output of our create `BillableMetricLog` mutation. */
export type CreateBillableMetricLogPayloadBillableMetricLogEdgeArgs = {
  orderBy?: Maybe<Array<BillableMetricLogsOrderBy>>;
};

/** All input for the create `Channel` mutation. */
export type CreateChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Channel` to be created by this mutation. */
  channel: ChannelInput;
};

/** The output of our create `Channel` mutation. */
export type CreateChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Channel` that was created by this mutation. */
  channel?: Maybe<Channel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge?: Maybe<ChannelsEdge>;
};


/** The output of our create `Channel` mutation. */
export type CreateChannelPayloadChannelEdgeArgs = {
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
};

/** All input for the create `CompanyAnalytic` mutation. */
export type CreateCompanyAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyAnalytic` to be created by this mutation. */
  companyAnalytic: CompanyAnalyticInput;
};

/** The output of our create `CompanyAnalytic` mutation. */
export type CreateCompanyAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyAnalytic` that was created by this mutation. */
  companyAnalytic?: Maybe<CompanyAnalytic>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyAnalytic`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyAnalytic`. May be used by Relay 1. */
  companyAnalyticEdge?: Maybe<CompanyAnalyticsEdge>;
};


/** The output of our create `CompanyAnalytic` mutation. */
export type CreateCompanyAnalyticPayloadCompanyAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<CompanyAnalyticsOrderBy>>;
};

export type CreateCompanyByUserInput = {
  company: CompanyInput;
};

/** All input for the create `CompanyChannel` mutation. */
export type CreateCompanyChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyChannel` to be created by this mutation. */
  companyChannel: CompanyChannelInput;
};

/** All input for the create `CompanyChannelMedia` mutation. */
export type CreateCompanyChannelMediaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyChannelMedia` to be created by this mutation. */
  companyChannelMedia: CompanyChannelMediaInput;
};

/** The output of our create `CompanyChannelMedia` mutation. */
export type CreateCompanyChannelMediaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyChannelMedia` that was created by this mutation. */
  companyChannelMedia?: Maybe<CompanyChannelMedia>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CompanyChannel` that is related to this `CompanyChannelMedia`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `Media` that is related to this `CompanyChannelMedia`. */
  media?: Maybe<Media>;
  /** Reads a single `User` that is related to this `CompanyChannelMedia`. */
  assignee?: Maybe<User>;
  /** An edge for our `CompanyChannelMedia`. May be used by Relay 1. */
  companyChannelMediaEdge?: Maybe<CompanyChannelMediaEdge>;
};


/** The output of our create `CompanyChannelMedia` mutation. */
export type CreateCompanyChannelMediaPayloadCompanyChannelMediaEdgeArgs = {
  orderBy?: Maybe<Array<CompanyChannelMediaOrderBy>>;
};

/** The output of our create `CompanyChannel` mutation. */
export type CreateCompanyChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyChannel` that was created by this mutation. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Channel` that is related to this `CompanyChannel`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Company` that is related to this `CompanyChannel`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyChannel`. May be used by Relay 1. */
  companyChannelEdge?: Maybe<CompanyChannelsEdge>;
};


/** The output of our create `CompanyChannel` mutation. */
export type CreateCompanyChannelPayloadCompanyChannelEdgeArgs = {
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
};

/** All input for the create `CompanyCta` mutation. */
export type CreateCompanyCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyCta` to be created by this mutation. */
  companyCta: CompanyCtaInput;
};

/** The output of our create `CompanyCta` mutation. */
export type CreateCompanyCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyCta` that was created by this mutation. */
  companyCta?: Maybe<CompanyCta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CompanyCta`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Group` that is related to this `CompanyCta`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyCta`. May be used by Relay 1. */
  companyCtaEdge?: Maybe<CompanyCtasEdge>;
};


/** The output of our create `CompanyCta` mutation. */
export type CreateCompanyCtaPayloadCompanyCtaEdgeArgs = {
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
};

/** All input for the create `CompanyDomain` mutation. */
export type CreateCompanyDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomain` to be created by this mutation. */
  companyDomain: CompanyDomainInput;
};

/** The output of our create `CompanyDomain` mutation. */
export type CreateCompanyDomainPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomain` that was created by this mutation. */
  companyDomain?: Maybe<CompanyDomain>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomain`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyDomain`. May be used by Relay 1. */
  companyDomainEdge?: Maybe<CompanyDomainsEdge>;
};


/** The output of our create `CompanyDomain` mutation. */
export type CreateCompanyDomainPayloadCompanyDomainEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
};

/** All input for the create `CompanyDomainsApproved` mutation. */
export type CreateCompanyDomainsApprovedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsApproved` to be created by this mutation. */
  companyDomainsApproved: CompanyDomainsApprovedInput;
};

/** The output of our create `CompanyDomainsApproved` mutation. */
export type CreateCompanyDomainsApprovedPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsApproved` that was created by this mutation. */
  companyDomainsApproved?: Maybe<CompanyDomainsApproved>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsApproved`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsApproved`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsApproved`. May be used by Relay 1. */
  companyDomainsApprovedEdge?: Maybe<CompanyDomainsApprovedsEdge>;
};


/** The output of our create `CompanyDomainsApproved` mutation. */
export type CreateCompanyDomainsApprovedPayloadCompanyDomainsApprovedEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
};

/** All input for the create `CompanyDomainsPath` mutation. */
export type CreateCompanyDomainsPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsPath` to be created by this mutation. */
  companyDomainsPath: CompanyDomainsPathInput;
};

/** The output of our create `CompanyDomainsPath` mutation. */
export type CreateCompanyDomainsPathPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsPath` that was created by this mutation. */
  companyDomainsPath?: Maybe<CompanyDomainsPath>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsPath`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsPath`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsPath`. May be used by Relay 1. */
  companyDomainsPathEdge?: Maybe<CompanyDomainsPathsEdge>;
};


/** The output of our create `CompanyDomainsPath` mutation. */
export type CreateCompanyDomainsPathPayloadCompanyDomainsPathEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
};

/** All input for the create `CompanyGroup` mutation. */
export type CreateCompanyGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyGroup` to be created by this mutation. */
  companyGroup: CompanyGroupInput;
};

/** The output of our create `CompanyGroup` mutation. */
export type CreateCompanyGroupPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyGroup` that was created by this mutation. */
  companyGroup?: Maybe<CompanyGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyGroup`. */
  company?: Maybe<Company>;
  /** Reads a single `Group` that is related to this `CompanyGroup`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyGroup`. May be used by Relay 1. */
  companyGroupEdge?: Maybe<CompanyGroupsEdge>;
};


/** The output of our create `CompanyGroup` mutation. */
export type CreateCompanyGroupPayloadCompanyGroupEdgeArgs = {
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
};

/** All input for the create `Company` mutation. */
export type CreateCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Company` to be created by this mutation. */
  company: CompanyInput;
};

/** All input for the create `CompanyIntegration` mutation. */
export type CreateCompanyIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyIntegration` to be created by this mutation. */
  companyIntegration: CompanyIntegrationInput;
};

/** The output of our create `CompanyIntegration` mutation. */
export type CreateCompanyIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyIntegration` that was created by this mutation. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `CompanyIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Company` that is related to this `CompanyIntegration`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyIntegration`. May be used by Relay 1. */
  companyIntegrationEdge?: Maybe<CompanyIntegrationsEdge>;
};


/** The output of our create `CompanyIntegration` mutation. */
export type CreateCompanyIntegrationPayloadCompanyIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
};

/** All input for the create `CompanyLocation` mutation. */
export type CreateCompanyLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyLocation` to be created by this mutation. */
  companyLocation: CompanyLocationInput;
};

/** The output of our create `CompanyLocation` mutation. */
export type CreateCompanyLocationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyLocation` that was created by this mutation. */
  companyLocation?: Maybe<CompanyLocation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyLocation`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyLocation`. May be used by Relay 1. */
  companyLocationEdge?: Maybe<CompanyLocationsEdge>;
};


/** The output of our create `CompanyLocation` mutation. */
export type CreateCompanyLocationPayloadCompanyLocationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyLocationsOrderBy>>;
};

/** The output of our create `Company` mutation. */
export type CreateCompanyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Company` that was created by this mutation. */
  company?: Maybe<Company>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Company`. */
  groupByPrimaryGroup?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Company`. */
  userByAccountManager?: Maybe<User>;
  /** Reads a single `User` that is related to this `Company`. */
  userBySupportAgent?: Maybe<User>;
  /** An edge for our `Company`. May be used by Relay 1. */
  companyEdge?: Maybe<CompaniesEdge>;
};


/** The output of our create `Company` mutation. */
export type CreateCompanyPayloadCompanyEdgeArgs = {
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
};

/** All input for the create `CompanyProduct` mutation. */
export type CreateCompanyProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProduct` to be created by this mutation. */
  companyProduct: CompanyProductInput;
};

/** All input for the create `CompanyProductIntegration` mutation. */
export type CreateCompanyProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProductIntegration` to be created by this mutation. */
  companyProductIntegration: CompanyProductIntegrationInput;
};

/** The output of our create `CompanyProductIntegration` mutation. */
export type CreateCompanyProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProductIntegration` that was created by this mutation. */
  companyProductIntegration?: Maybe<CompanyProductIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CompanyIntegration` that is related to this `CompanyProductIntegration`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `CompanyProduct` that is related to this `CompanyProductIntegration`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** An edge for our `CompanyProductIntegration`. May be used by Relay 1. */
  companyProductIntegrationEdge?: Maybe<CompanyProductIntegrationsEdge>;
};


/** The output of our create `CompanyProductIntegration` mutation. */
export type CreateCompanyProductIntegrationPayloadCompanyProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
};

/** The output of our create `CompanyProduct` mutation. */
export type CreateCompanyProductPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProduct` that was created by this mutation. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyProduct`. */
  company?: Maybe<Company>;
  /** Reads a single `Product` that is related to this `CompanyProduct`. */
  product?: Maybe<Product>;
  /** An edge for our `CompanyProduct`. May be used by Relay 1. */
  companyProductEdge?: Maybe<CompanyProductsEdge>;
};


/** The output of our create `CompanyProduct` mutation. */
export type CreateCompanyProductPayloadCompanyProductEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
};

/** All input for the create `CompanyUser` mutation. */
export type CreateCompanyUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyUser` to be created by this mutation. */
  companyUser: CompanyUserInput;
};

/** The output of our create `CompanyUser` mutation. */
export type CreateCompanyUserPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyUser` that was created by this mutation. */
  companyUser?: Maybe<CompanyUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyUser`. */
  company?: Maybe<Company>;
  /** Reads a single `Group` that is related to this `CompanyUser`. */
  group?: Maybe<Group>;
  /** Reads a single `User` that is related to this `CompanyUser`. */
  user?: Maybe<User>;
  /** An edge for our `CompanyUser`. May be used by Relay 1. */
  companyUserEdge?: Maybe<CompanyUsersEdge>;
};


/** The output of our create `CompanyUser` mutation. */
export type CreateCompanyUserPayloadCompanyUserEdgeArgs = {
  orderBy?: Maybe<Array<CompanyUsersOrderBy>>;
};

/** All input for the create `CompanyWidget` mutation. */
export type CreateCompanyWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyWidget` to be created by this mutation. */
  companyWidget: CompanyWidgetInput;
};

/** The output of our create `CompanyWidget` mutation. */
export type CreateCompanyWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyWidget` that was created by this mutation. */
  companyWidget?: Maybe<CompanyWidget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `CompanyWidget`. */
  group?: Maybe<Group>;
  /** Reads a single `Widget` that is related to this `CompanyWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CompanyWidget`. May be used by Relay 1. */
  companyWidgetEdge?: Maybe<CompanyWidgetsEdge>;
};


/** The output of our create `CompanyWidget` mutation. */
export type CreateCompanyWidgetPayloadCompanyWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
};

export type CreateCompanyWithUserInput = {
  cloneFromCompanyId?: Maybe<Scalars['Int']>;
  company: CompanyInput;
  user?: Maybe<CreateUserInput>;
  groupDetails?: Maybe<GroupDetailsInput>;
  products?: Maybe<Array<ProductType>>;
  deliveryMethods?: Maybe<Array<DeliveryMethodInput>>;
};

export type CreateCompanyWithUserResponse = {
  company: BasicCompanyDetails;
  user: CreateUserResponse;
  companyProducts?: Maybe<Array<BasicProductDetails>>;
  deliveryMethods: Array<BasicDeliveryMethodDetails>;
};

export type CreateCreditAppApplicationResponse = {
  applicationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

/** All input for the create `CreditBureau` mutation. */
export type CreateCreditBureauInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CreditBureau` to be created by this mutation. */
  creditBureau: CreditBureauInput;
};

/** The output of our create `CreditBureau` mutation. */
export type CreateCreditBureauPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CreditBureau` that was created by this mutation. */
  creditBureau?: Maybe<CreditBureau>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AvaApp` that is related to this `CreditBureau`. */
  avaApp?: Maybe<AvaApp>;
  /** An edge for our `CreditBureau`. May be used by Relay 1. */
  creditBureauEdge?: Maybe<CreditBureausEdge>;
};


/** The output of our create `CreditBureau` mutation. */
export type CreateCreditBureauPayloadCreditBureauEdgeArgs = {
  orderBy?: Maybe<Array<CreditBureausOrderBy>>;
};

/** All input for the create `Cta` mutation. */
export type CreateCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Cta` to be created by this mutation. */
  cta: CtaInput;
};

/** The output of our create `Cta` mutation. */
export type CreateCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Cta` that was created by this mutation. */
  cta?: Maybe<Cta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Cta`. May be used by Relay 1. */
  ctaEdge?: Maybe<CtasEdge>;
};


/** The output of our create `Cta` mutation. */
export type CreateCtaPayloadCtaEdgeArgs = {
  orderBy?: Maybe<Array<CtasOrderBy>>;
};

export type CreateCtaSpecsInput = {
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  /**
   * Use this field to generate ctaStyles, ctaStylesStatic, and ctaTree from
   * a raw html string
   */
  html?: Maybe<Scalars['String']>;
  ctaStyles?: Maybe<Scalars['String']>;
  ctaStylesStatic?: Maybe<Scalars['String']>;
  ctaTree?: Maybe<Scalars['JSON']>;
  /** Should be the themeSpec.spec value */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Should be the templateSpec.spec value */
  templateSpec?: Maybe<Scalars['JSON']>;
  /** Should be the theme definitions for themeDef.defs.default */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Should be the template definitions for templateDef.defs.default */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Should be the plugin value definitions, as needed. */
  pluginDef?: Maybe<Scalars['JSON']>;
};

/** All input for the create `CtaWidget` mutation. */
export type CreateCtaWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CtaWidget` to be created by this mutation. */
  ctaWidget: CtaWidgetInput;
};

/** The output of our create `CtaWidget` mutation. */
export type CreateCtaWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CtaWidget` that was created by this mutation. */
  ctaWidget?: Maybe<CtaWidget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CtaWidget`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Widget` that is related to this `CtaWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CtaWidget`. May be used by Relay 1. */
  ctaWidgetEdge?: Maybe<CtaWidgetsEdge>;
};


/** The output of our create `CtaWidget` mutation. */
export type CreateCtaWidgetPayloadCtaWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
};

/** All input for the create `Deal` mutation. */
export type CreateDealInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Deal` to be created by this mutation. */
  deal: DealInput;
};

/** The output of our create `Deal` mutation. */
export type CreateDealPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Deal` that was created by this mutation. */
  deal?: Maybe<Deal>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `Deal`. */
  lead?: Maybe<Lead>;
  /** Reads a single `CompanyIntegration` that is related to this `Deal`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** An edge for our `Deal`. May be used by Relay 1. */
  dealEdge?: Maybe<DealsEdge>;
};


/** The output of our create `Deal` mutation. */
export type CreateDealPayloadDealEdgeArgs = {
  orderBy?: Maybe<Array<DealsOrderBy>>;
};

/** All input for the create `DeliveryMethod` mutation. */
export type CreateDeliveryMethodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DeliveryMethod` to be created by this mutation. */
  deliveryMethod: DeliveryMethodInput;
};

/** The output of our create `DeliveryMethod` mutation. */
export type CreateDeliveryMethodPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DeliveryMethod` that was created by this mutation. */
  deliveryMethod?: Maybe<DeliveryMethod>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `DeliveryMethod`. */
  group?: Maybe<Group>;
  /** An edge for our `DeliveryMethod`. May be used by Relay 1. */
  deliveryMethodEdge?: Maybe<DeliveryMethodsEdge>;
};


/** The output of our create `DeliveryMethod` mutation. */
export type CreateDeliveryMethodPayloadDeliveryMethodEdgeArgs = {
  orderBy?: Maybe<Array<DeliveryMethodsOrderBy>>;
};

/** All input for the create `DeliveryMethodsInternal` mutation. */
export type CreateDeliveryMethodsInternalInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DeliveryMethodsInternal` to be created by this mutation. */
  deliveryMethodsInternal: DeliveryMethodsInternalInput;
};

/** The output of our create `DeliveryMethodsInternal` mutation. */
export type CreateDeliveryMethodsInternalPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DeliveryMethodsInternal` that was created by this mutation. */
  deliveryMethodsInternal?: Maybe<DeliveryMethodsInternal>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `DeliveryMethodsInternal`. */
  company?: Maybe<Company>;
  /** An edge for our `DeliveryMethodsInternal`. May be used by Relay 1. */
  deliveryMethodsInternalEdge?: Maybe<DeliveryMethodsInternalsEdge>;
};


/** The output of our create `DeliveryMethodsInternal` mutation. */
export type CreateDeliveryMethodsInternalPayloadDeliveryMethodsInternalEdgeArgs = {
  orderBy?: Maybe<Array<DeliveryMethodsInternalsOrderBy>>;
};

/** All input for the create `DistributorRelationship` mutation. */
export type CreateDistributorRelationshipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DistributorRelationship` to be created by this mutation. */
  distributorRelationship: DistributorRelationshipInput;
};

/** The output of our create `DistributorRelationship` mutation. */
export type CreateDistributorRelationshipPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DistributorRelationship` that was created by this mutation. */
  distributorRelationship?: Maybe<DistributorRelationship>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `DistributorRelationship`. */
  distributorCompany?: Maybe<Company>;
  /** Reads a single `Company` that is related to this `DistributorRelationship`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyChannel` that is related to this `DistributorRelationship`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** An edge for our `DistributorRelationship`. May be used by Relay 1. */
  distributorRelationshipEdge?: Maybe<DistributorRelationshipsEdge>;
};


/** The output of our create `DistributorRelationship` mutation. */
export type CreateDistributorRelationshipPayloadDistributorRelationshipEdgeArgs = {
  orderBy?: Maybe<Array<DistributorRelationshipsOrderBy>>;
};

/** All input for the create `EmailTemplate` mutation. */
export type CreateEmailTemplateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmailTemplate` to be created by this mutation. */
  emailTemplate: EmailTemplateInput;
};

/** The output of our create `EmailTemplate` mutation. */
export type CreateEmailTemplatePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmailTemplate` that was created by this mutation. */
  emailTemplate?: Maybe<EmailTemplate>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `EmailTemplate`. May be used by Relay 1. */
  emailTemplateEdge?: Maybe<EmailTemplatesEdge>;
};


/** The output of our create `EmailTemplate` mutation. */
export type CreateEmailTemplatePayloadEmailTemplateEdgeArgs = {
  orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
};

/** All input for the create `EquifaxLender` mutation. */
export type CreateEquifaxLenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquifaxLender` to be created by this mutation. */
  equifaxLender: EquifaxLenderInput;
};

/** The output of our create `EquifaxLender` mutation. */
export type CreateEquifaxLenderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquifaxLender` that was created by this mutation. */
  equifaxLender?: Maybe<EquifaxLender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lender` that is related to this `EquifaxLender`. */
  lender?: Maybe<Lender>;
  /** An edge for our `EquifaxLender`. May be used by Relay 1. */
  equifaxLenderEdge?: Maybe<EquifaxLendersEdge>;
};


/** The output of our create `EquifaxLender` mutation. */
export type CreateEquifaxLenderPayloadEquifaxLenderEdgeArgs = {
  orderBy?: Maybe<Array<EquifaxLendersOrderBy>>;
};

/** All input for the create `FieldConfig` mutation. */
export type CreateFieldConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldConfig` to be created by this mutation. */
  fieldConfig: FieldConfigInput;
};

/** The output of our create `FieldConfig` mutation. */
export type CreateFieldConfigPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldConfig` that was created by this mutation. */
  fieldConfig?: Maybe<FieldConfig>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `FieldConfig`. May be used by Relay 1. */
  fieldConfigEdge?: Maybe<FieldConfigsEdge>;
};


/** The output of our create `FieldConfig` mutation. */
export type CreateFieldConfigPayloadFieldConfigEdgeArgs = {
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
};

/** All input for the create `Field` mutation. */
export type CreateFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Field` to be created by this mutation. */
  field: FieldInput;
};

/** All input for the create `FieldKey` mutation. */
export type CreateFieldKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldKey` to be created by this mutation. */
  fieldKey: FieldKeyInput;
};

/** The output of our create `FieldKey` mutation. */
export type CreateFieldKeyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldKey` that was created by this mutation. */
  fieldKey?: Maybe<FieldKey>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `FieldKey`. May be used by Relay 1. */
  fieldKeyEdge?: Maybe<FieldKeysEdge>;
};


/** The output of our create `FieldKey` mutation. */
export type CreateFieldKeyPayloadFieldKeyEdgeArgs = {
  orderBy?: Maybe<Array<FieldKeysOrderBy>>;
};

/** The output of our create `Field` mutation. */
export type CreateFieldPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Field` that was created by this mutation. */
  field?: Maybe<Field>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Field`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyProduct` that is related to this `Field`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Reads a single `CompanyChannel` that is related to this `Field`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `CompanyIntegration` that is related to this `Field`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `FieldConfig` that is related to this `Field`. */
  fieldConfigByConfig?: Maybe<FieldConfig>;
  /** An edge for our `Field`. May be used by Relay 1. */
  fieldEdge?: Maybe<FieldsEdge>;
};


/** The output of our create `Field` mutation. */
export type CreateFieldPayloadFieldEdgeArgs = {
  orderBy?: Maybe<Array<FieldsOrderBy>>;
};

/** All input for the create `File` mutation. */
export type CreateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `File` to be created by this mutation. */
  file: FileInput;
};

/** The output of our create `File` mutation. */
export type CreateFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `File` that was created by this mutation. */
  file?: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderProgram` that is related to this `File`. */
  lenderProgram?: Maybe<LenderProgram>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
};


/** The output of our create `File` mutation. */
export type CreateFilePayloadFileEdgeArgs = {
  orderBy?: Maybe<Array<FilesOrderBy>>;
};

/** All input for the create `Group` mutation. */
export type CreateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` to be created by this mutation. */
  group: GroupInput;
};

/** The output of our create `Group` mutation. */
export type CreateGroupPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` that was created by this mutation. */
  group?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
};


/** The output of our create `Group` mutation. */
export type CreateGroupPayloadGroupEdgeArgs = {
  orderBy?: Maybe<Array<GroupsOrderBy>>;
};

/** All input for the create `IdInquiry` mutation. */
export type CreateIdInquiryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiry` to be created by this mutation. */
  idInquiry: IdInquiryInput;
};

/** The output of our create `IdInquiry` mutation. */
export type CreateIdInquiryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiry` that was created by this mutation. */
  idInquiry?: Maybe<IdInquiry>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IdInquiry`. */
  lead?: Maybe<Lead>;
  /** Reads a single `Integration` that is related to this `IdInquiry`. */
  integration?: Maybe<Integration>;
  /** An edge for our `IdInquiry`. May be used by Relay 1. */
  idInquiryEdge?: Maybe<IdInquiriesEdge>;
};


/** The output of our create `IdInquiry` mutation. */
export type CreateIdInquiryPayloadIdInquiryEdgeArgs = {
  orderBy?: Maybe<Array<IdInquiriesOrderBy>>;
};

/** All input for the create `IdInquiryVerificationCheck` mutation. */
export type CreateIdInquiryVerificationCheckInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiryVerificationCheck` to be created by this mutation. */
  idInquiryVerificationCheck: IdInquiryVerificationCheckInput;
};

/** The output of our create `IdInquiryVerificationCheck` mutation. */
export type CreateIdInquiryVerificationCheckPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiryVerificationCheck` that was created by this mutation. */
  idInquiryVerificationCheck?: Maybe<IdInquiryVerificationCheck>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiryVerification` that is related to this `IdInquiryVerificationCheck`. */
  verification?: Maybe<IdInquiryVerification>;
  /** Reads a single `VerificationCheck` that is related to this `IdInquiryVerificationCheck`. */
  verificationCheck?: Maybe<VerificationCheck>;
  /** An edge for our `IdInquiryVerificationCheck`. May be used by Relay 1. */
  idInquiryVerificationCheckEdge?: Maybe<IdInquiryVerificationChecksEdge>;
};


/** The output of our create `IdInquiryVerificationCheck` mutation. */
export type CreateIdInquiryVerificationCheckPayloadIdInquiryVerificationCheckEdgeArgs = {
  orderBy?: Maybe<Array<IdInquiryVerificationChecksOrderBy>>;
};

/** All input for the create `IdInquiryVerification` mutation. */
export type CreateIdInquiryVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiryVerification` to be created by this mutation. */
  idInquiryVerification: IdInquiryVerificationInput;
};

/** The output of our create `IdInquiryVerification` mutation. */
export type CreateIdInquiryVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiryVerification` that was created by this mutation. */
  idInquiryVerification?: Maybe<IdInquiryVerification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiry` that is related to this `IdInquiryVerification`. */
  inquiry?: Maybe<IdInquiry>;
  /** An edge for our `IdInquiryVerification`. May be used by Relay 1. */
  idInquiryVerificationEdge?: Maybe<IdInquiryVerificationsEdge>;
};


/** The output of our create `IdInquiryVerification` mutation. */
export type CreateIdInquiryVerificationPayloadIdInquiryVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IdInquiryVerificationsOrderBy>>;
};

/** All input for the create `IdMedia` mutation. */
export type CreateIdMediaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdMedia` to be created by this mutation. */
  idMedia: IdMediaInput;
};

/** The output of our create `IdMedia` mutation. */
export type CreateIdMediaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdMedia` that was created by this mutation. */
  idMedia?: Maybe<IdMedia>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiryVerification` that is related to this `IdMedia`. */
  verification?: Maybe<IdInquiryVerification>;
  /** Reads a single `Media` that is related to this `IdMedia`. */
  media?: Maybe<Media>;
  /** An edge for our `IdMedia`. May be used by Relay 1. */
  idMediaEdge?: Maybe<IdMediaEdge>;
};


/** The output of our create `IdMedia` mutation. */
export type CreateIdMediaPayloadIdMediaEdgeArgs = {
  orderBy?: Maybe<Array<IdMediaOrderBy>>;
};

/** All input for the create `IdSession` mutation. */
export type CreateIdSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdSession` to be created by this mutation. */
  idSession: IdSessionInput;
};

/** The output of our create `IdSession` mutation. */
export type CreateIdSessionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdSession` that was created by this mutation. */
  idSession?: Maybe<IdSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiry` that is related to this `IdSession`. */
  inquiry?: Maybe<IdInquiry>;
  /** An edge for our `IdSession`. May be used by Relay 1. */
  idSessionEdge?: Maybe<IdSessionsEdge>;
};


/** The output of our create `IdSession` mutation. */
export type CreateIdSessionPayloadIdSessionEdgeArgs = {
  orderBy?: Maybe<Array<IdSessionsOrderBy>>;
};

/** All input for the create `IdVerification` mutation. */
export type CreateIdVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdVerification` to be created by this mutation. */
  idVerification: IdVerificationInput;
};

/** The output of our create `IdVerification` mutation. */
export type CreateIdVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdVerification` that was created by this mutation. */
  idVerification?: Maybe<IdVerification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IdVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IdVerification`. May be used by Relay 1. */
  idVerificationEdge?: Maybe<IdVerificationsEdge>;
};


/** The output of our create `IdVerification` mutation. */
export type CreateIdVerificationPayloadIdVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IdVerificationsOrderBy>>;
};

/** All input for the create `IncomeVerification` mutation. */
export type CreateIncomeVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IncomeVerification` to be created by this mutation. */
  incomeVerification: IncomeVerificationInput;
};

/** The output of our create `IncomeVerification` mutation. */
export type CreateIncomeVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IncomeVerification` that was created by this mutation. */
  incomeVerification?: Maybe<IncomeVerification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IncomeVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IncomeVerification`. May be used by Relay 1. */
  incomeVerificationEdge?: Maybe<IncomeVerificationsEdge>;
};


/** The output of our create `IncomeVerification` mutation. */
export type CreateIncomeVerificationPayloadIncomeVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IncomeVerificationsOrderBy>>;
};

/** All input for the create `Integration` mutation. */
export type CreateIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Integration` to be created by this mutation. */
  integration: IntegrationInput;
};

/** The output of our create `Integration` mutation. */
export type CreateIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Integration` that was created by this mutation. */
  integration?: Maybe<Integration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Integration`. May be used by Relay 1. */
  integrationEdge?: Maybe<IntegrationsEdge>;
};


/** The output of our create `Integration` mutation. */
export type CreateIntegrationPayloadIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
};

/** All input for the create `LeadCampaign` mutation. */
export type CreateLeadCampaignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadCampaign` to be created by this mutation. */
  leadCampaign: LeadCampaignInput;
};

/** The output of our create `LeadCampaign` mutation. */
export type CreateLeadCampaignPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadCampaign` that was created by this mutation. */
  leadCampaign?: Maybe<LeadCampaign>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LeadCampaign`. */
  lead?: Maybe<Lead>;
  /** An edge for our `LeadCampaign`. May be used by Relay 1. */
  leadCampaignEdge?: Maybe<LeadCampaignsEdge>;
};


/** The output of our create `LeadCampaign` mutation. */
export type CreateLeadCampaignPayloadLeadCampaignEdgeArgs = {
  orderBy?: Maybe<Array<LeadCampaignsOrderBy>>;
};

/** All input for the create `Lead` mutation. */
export type CreateLeadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` to be created by this mutation. */
  lead: LeadInput;
};

/** All input for the create `LeadJourney` mutation. */
export type CreateLeadJourneyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadJourney` to be created by this mutation. */
  leadJourney: LeadJourneyInput;
};

/** The output of our create `LeadJourney` mutation. */
export type CreateLeadJourneyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadJourney` that was created by this mutation. */
  leadJourney?: Maybe<LeadJourney>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LeadJourney`. */
  lead?: Maybe<Lead>;
  /** Reads a single `Product` that is related to this `LeadJourney`. */
  product?: Maybe<Product>;
  /** An edge for our `LeadJourney`. May be used by Relay 1. */
  leadJourneyEdge?: Maybe<LeadJourneysEdge>;
};


/** The output of our create `LeadJourney` mutation. */
export type CreateLeadJourneyPayloadLeadJourneyEdgeArgs = {
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
};

/** The output of our create `Lead` mutation. */
export type CreateLeadPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` that was created by this mutation. */
  lead?: Maybe<Lead>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Lead`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyChannel` that is related to this `Lead`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `NeighborhoodView` that is related to this `Lead`. */
  neighborhoodView?: Maybe<NeighborhoodView>;
  /** An edge for our `Lead`. May be used by Relay 1. */
  leadEdge?: Maybe<LeadsEdge>;
};


/** The output of our create `Lead` mutation. */
export type CreateLeadPayloadLeadEdgeArgs = {
  orderBy?: Maybe<Array<LeadsOrderBy>>;
};

/** All input for the create `LeadRole` mutation. */
export type CreateLeadRoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadRole` to be created by this mutation. */
  leadRole: LeadRoleInput;
};

/** The output of our create `LeadRole` mutation. */
export type CreateLeadRolePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadRole` that was created by this mutation. */
  leadRole?: Maybe<LeadRole>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LeadRole`. */
  lead?: Maybe<Lead>;
  /** Reads a single `User` that is related to this `LeadRole`. */
  user?: Maybe<User>;
  /** An edge for our `LeadRole`. May be used by Relay 1. */
  leadRoleEdge?: Maybe<LeadRolesEdge>;
};


/** The output of our create `LeadRole` mutation. */
export type CreateLeadRolePayloadLeadRoleEdgeArgs = {
  orderBy?: Maybe<Array<LeadRolesOrderBy>>;
};

/** All input for the create `LenderApp` mutation. */
export type CreateLenderAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderApp` to be created by this mutation. */
  lenderApp: LenderAppInput;
};

/** The output of our create `LenderApp` mutation. */
export type CreateLenderAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderApp` that was created by this mutation. */
  lenderApp?: Maybe<LenderApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderApp`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `LenderApp`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `LenderApp`. May be used by Relay 1. */
  lenderAppEdge?: Maybe<LenderAppsEdge>;
};


/** The output of our create `LenderApp` mutation. */
export type CreateLenderAppPayloadLenderAppEdgeArgs = {
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
};

/** All input for the create `LenderCreditKey` mutation. */
export type CreateLenderCreditKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderCreditKey` to be created by this mutation. */
  lenderCreditKey: LenderCreditKeyInput;
};

/** The output of our create `LenderCreditKey` mutation. */
export type CreateLenderCreditKeyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderCreditKey` that was created by this mutation. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderProgram` that is related to this `LenderCreditKey`. */
  lenderProgram?: Maybe<LenderProgram>;
  /** An edge for our `LenderCreditKey`. May be used by Relay 1. */
  lenderCreditKeyEdge?: Maybe<LenderCreditKeysEdge>;
};


/** The output of our create `LenderCreditKey` mutation. */
export type CreateLenderCreditKeyPayloadLenderCreditKeyEdgeArgs = {
  orderBy?: Maybe<Array<LenderCreditKeysOrderBy>>;
};

/** All input for the create `Lender` mutation. */
export type CreateLenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lender` to be created by this mutation. */
  lender: LenderInput;
};

/** The output of our create `Lender` mutation. */
export type CreateLenderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lender` that was created by this mutation. */
  lender?: Maybe<Lender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Lender`. May be used by Relay 1. */
  lenderEdge?: Maybe<LendersEdge>;
};


/** The output of our create `Lender` mutation. */
export type CreateLenderPayloadLenderEdgeArgs = {
  orderBy?: Maybe<Array<LendersOrderBy>>;
};

/** All input for the create `LenderProgramBonus` mutation. */
export type CreateLenderProgramBonusInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderProgramBonus` to be created by this mutation. */
  lenderProgramBonus: LenderProgramBonusInput;
};

/** The output of our create `LenderProgramBonus` mutation. */
export type CreateLenderProgramBonusPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderProgramBonus` that was created by this mutation. */
  lenderProgramBonus?: Maybe<LenderProgramBonus>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderCreditKey` that is related to this `LenderProgramBonus`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** An edge for our `LenderProgramBonus`. May be used by Relay 1. */
  lenderProgramBonusEdge?: Maybe<LenderProgramBonusesEdge>;
};


/** The output of our create `LenderProgramBonus` mutation. */
export type CreateLenderProgramBonusPayloadLenderProgramBonusEdgeArgs = {
  orderBy?: Maybe<Array<LenderProgramBonusesOrderBy>>;
};

/** All input for the create `LenderProgram` mutation. */
export type CreateLenderProgramInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderProgram` to be created by this mutation. */
  lenderProgram: LenderProgramInput;
};

/** The output of our create `LenderProgram` mutation. */
export type CreateLenderProgramPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderProgram` that was created by this mutation. */
  lenderProgram?: Maybe<LenderProgram>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lender` that is related to this `LenderProgram`. */
  lender?: Maybe<Lender>;
  /** An edge for our `LenderProgram`. May be used by Relay 1. */
  lenderProgramEdge?: Maybe<LenderProgramsEdge>;
};


/** The output of our create `LenderProgram` mutation. */
export type CreateLenderProgramPayloadLenderProgramEdgeArgs = {
  orderBy?: Maybe<Array<LenderProgramsOrderBy>>;
};

/** All input for the create `LenderQuote` mutation. */
export type CreateLenderQuoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderQuote` to be created by this mutation. */
  lenderQuote: LenderQuoteInput;
};

/** The output of our create `LenderQuote` mutation. */
export type CreateLenderQuotePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderQuote` that was created by this mutation. */
  lenderQuote?: Maybe<LenderQuote>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderQuote`. */
  lead?: Maybe<Lead>;
  /** Reads a single `LenderApp` that is related to this `LenderQuote`. */
  lenderApp?: Maybe<LenderApp>;
  /** Reads a single `Lender` that is related to this `LenderQuote`. */
  lender?: Maybe<Lender>;
  /** Reads a single `CompanyIntegration` that is related to this `LenderQuote`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** An edge for our `LenderQuote`. May be used by Relay 1. */
  lenderQuoteEdge?: Maybe<LenderQuotesEdge>;
};


/** The output of our create `LenderQuote` mutation. */
export type CreateLenderQuotePayloadLenderQuoteEdgeArgs = {
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
};

/** All input for the create `MaxAmortization` mutation. */
export type CreateMaxAmortizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MaxAmortization` to be created by this mutation. */
  maxAmortization: MaxAmortizationInput;
};

/** The output of our create `MaxAmortization` mutation. */
export type CreateMaxAmortizationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MaxAmortization` that was created by this mutation. */
  maxAmortization?: Maybe<MaxAmortization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderCreditKey` that is related to this `MaxAmortization`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** An edge for our `MaxAmortization`. May be used by Relay 1. */
  maxAmortizationEdge?: Maybe<MaxAmortizationsEdge>;
};


/** The output of our create `MaxAmortization` mutation. */
export type CreateMaxAmortizationPayloadMaxAmortizationEdgeArgs = {
  orderBy?: Maybe<Array<MaxAmortizationsOrderBy>>;
};

/** All input for the create `Option` mutation. */
export type CreateOptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Option` to be created by this mutation. */
  option: OptionInput;
};

/** The output of our create `Option` mutation. */
export type CreateOptionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Option` that was created by this mutation. */
  option?: Maybe<Option>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `FieldConfig` that is related to this `Option`. */
  fieldConfig?: Maybe<FieldConfig>;
  /** An edge for our `Option`. May be used by Relay 1. */
  optionEdge?: Maybe<OptionsEdge>;
};


/** The output of our create `Option` mutation. */
export type CreateOptionPayloadOptionEdgeArgs = {
  orderBy?: Maybe<Array<OptionsOrderBy>>;
};

/** All input for the create `ProductIntegration` mutation. */
export type CreateProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProductIntegration` to be created by this mutation. */
  productIntegration: ProductIntegrationInput;
};

/** The output of our create `ProductIntegration` mutation. */
export type CreateProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProductIntegration` that was created by this mutation. */
  productIntegration?: Maybe<ProductIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `ProductIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Product` that is related to this `ProductIntegration`. */
  product?: Maybe<Product>;
  /** An edge for our `ProductIntegration`. May be used by Relay 1. */
  productIntegrationEdge?: Maybe<ProductIntegrationsEdge>;
};


/** The output of our create `ProductIntegration` mutation. */
export type CreateProductIntegrationPayloadProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
};

/** All input for the create `Rate` mutation. */
export type CreateRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Rate` to be created by this mutation. */
  rate: RateInput;
};

/** The output of our create `Rate` mutation. */
export type CreateRatePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Rate` that was created by this mutation. */
  rate?: Maybe<Rate>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderCreditKey` that is related to this `Rate`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** An edge for our `Rate`. May be used by Relay 1. */
  rateEdge?: Maybe<RatesEdge>;
};


/** The output of our create `Rate` mutation. */
export type CreateRatePayloadRateEdgeArgs = {
  orderBy?: Maybe<Array<RatesOrderBy>>;
};

/** All input for the create `Reserve` mutation. */
export type CreateReserveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Reserve` to be created by this mutation. */
  reserve: ReserveInput;
};

/** The output of our create `Reserve` mutation. */
export type CreateReservePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Reserve` that was created by this mutation. */
  reserve?: Maybe<Reserve>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Rate` that is related to this `Reserve`. */
  rate?: Maybe<Rate>;
  /** An edge for our `Reserve`. May be used by Relay 1. */
  reserveEdge?: Maybe<ReservesEdge>;
};


/** The output of our create `Reserve` mutation. */
export type CreateReservePayloadReserveEdgeArgs = {
  orderBy?: Maybe<Array<ReservesOrderBy>>;
};

/** All input for the create `Review` mutation. */
export type CreateReviewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Review` to be created by this mutation. */
  review: ReviewInput;
};

/** The output of our create `Review` mutation. */
export type CreateReviewPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Review` that was created by this mutation. */
  review?: Maybe<Review>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Review`. */
  company?: Maybe<Company>;
  /** An edge for our `Review`. May be used by Relay 1. */
  reviewEdge?: Maybe<ReviewsEdge>;
};


/** The output of our create `Review` mutation. */
export type CreateReviewPayloadReviewEdgeArgs = {
  orderBy?: Maybe<Array<ReviewsOrderBy>>;
};

/** All input for the create `TradeApp` mutation. */
export type CreateTradeAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TradeApp` to be created by this mutation. */
  tradeApp: TradeAppInput;
};

/** The output of our create `TradeApp` mutation. */
export type CreateTradeAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TradeApp` that was created by this mutation. */
  tradeApp?: Maybe<TradeApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `TradeApp`. */
  lead?: Maybe<Lead>;
  /** An edge for our `TradeApp`. May be used by Relay 1. */
  tradeAppEdge?: Maybe<TradeAppsEdge>;
};


/** The output of our create `TradeApp` mutation. */
export type CreateTradeAppPayloadTradeAppEdgeArgs = {
  orderBy?: Maybe<Array<TradeAppsOrderBy>>;
};

/** All input for the create `TradeLine` mutation. */
export type CreateTradeLineInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TradeLine` to be created by this mutation. */
  tradeLine: TradeLineInput;
};

/** The output of our create `TradeLine` mutation. */
export type CreateTradeLinePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TradeLine` that was created by this mutation. */
  tradeLine?: Maybe<TradeLine>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CreditBureau` that is related to this `TradeLine`. */
  bureau?: Maybe<CreditBureau>;
  /** Reads a single `EquifaxLender` that is related to this `TradeLine`. */
  equifaxLender?: Maybe<EquifaxLender>;
  /** An edge for our `TradeLine`. May be used by Relay 1. */
  tradeLineEdge?: Maybe<TradeLinesEdge>;
};


/** The output of our create `TradeLine` mutation. */
export type CreateTradeLinePayloadTradeLineEdgeArgs = {
  orderBy?: Maybe<Array<TradeLinesOrderBy>>;
};

/** All input for the create `UserActivity` mutation. */
export type CreateUserActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserActivity` to be created by this mutation. */
  userActivity: UserActivityInput;
};

/** The output of our create `UserActivity` mutation. */
export type CreateUserActivityPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserActivity` that was created by this mutation. */
  userActivity?: Maybe<UserActivity>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserActivity`. */
  user?: Maybe<User>;
  /** An edge for our `UserActivity`. May be used by Relay 1. */
  userActivityEdge?: Maybe<UserActivitiesEdge>;
};


/** The output of our create `UserActivity` mutation. */
export type CreateUserActivityPayloadUserActivityEdgeArgs = {
  orderBy?: Maybe<Array<UserActivitiesOrderBy>>;
};

export type CreateUserInput = {
  username: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  makeGroupAdmin?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
};

export type CreateUserResponse = {
  success?: Maybe<Scalars['Boolean']>;
  temporaryPassword?: Maybe<Scalars['String']>;
  user?: Maybe<UserDetails>;
};

/** All input for the create `VehicleBooking` mutation. */
export type CreateVehicleBookingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleBooking` to be created by this mutation. */
  vehicleBooking: VehicleBookingInput;
};

/** The output of our create `VehicleBooking` mutation. */
export type CreateVehicleBookingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleBooking` that was created by this mutation. */
  vehicleBooking?: Maybe<VehicleBooking>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderProgram` that is related to this `VehicleBooking`. */
  lenderProgram?: Maybe<LenderProgram>;
  /** An edge for our `VehicleBooking`. May be used by Relay 1. */
  vehicleBookingEdge?: Maybe<VehicleBookingsEdge>;
};


/** The output of our create `VehicleBooking` mutation. */
export type CreateVehicleBookingPayloadVehicleBookingEdgeArgs = {
  orderBy?: Maybe<Array<VehicleBookingsOrderBy>>;
};

/** All input for the create `VehicleListing` mutation. */
export type CreateVehicleListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleListing` to be created by this mutation. */
  vehicleListing: VehicleListingInput;
};

/** The output of our create `VehicleListing` mutation. */
export type CreateVehicleListingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleListing` that was created by this mutation. */
  vehicleListing?: Maybe<VehicleListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `VehicleListing`. May be used by Relay 1. */
  vehicleListingEdge?: Maybe<VehicleListingsEdge>;
};


/** The output of our create `VehicleListing` mutation. */
export type CreateVehicleListingPayloadVehicleListingEdgeArgs = {
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
};

/** All input for the create `VehicleStock` mutation. */
export type CreateVehicleStockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleStock` to be created by this mutation. */
  vehicleStock: VehicleStockInput;
};

/** The output of our create `VehicleStock` mutation. */
export type CreateVehicleStockPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleStock` that was created by this mutation. */
  vehicleStock?: Maybe<VehicleStock>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `VehicleStock`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyIntegration` that is related to this `VehicleStock`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** An edge for our `VehicleStock`. May be used by Relay 1. */
  vehicleStockEdge?: Maybe<VehicleStocksEdge>;
};


/** The output of our create `VehicleStock` mutation. */
export type CreateVehicleStockPayloadVehicleStockEdgeArgs = {
  orderBy?: Maybe<Array<VehicleStocksOrderBy>>;
};

/** All input for the create `VerificationCheck` mutation. */
export type CreateVerificationCheckInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VerificationCheck` to be created by this mutation. */
  verificationCheck: VerificationCheckInput;
};

/** The output of our create `VerificationCheck` mutation. */
export type CreateVerificationCheckPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VerificationCheck` that was created by this mutation. */
  verificationCheck?: Maybe<VerificationCheck>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `VerificationCheck`. May be used by Relay 1. */
  verificationCheckEdge?: Maybe<VerificationChecksEdge>;
};


/** The output of our create `VerificationCheck` mutation. */
export type CreateVerificationCheckPayloadVerificationCheckEdgeArgs = {
  orderBy?: Maybe<Array<VerificationChecksOrderBy>>;
};

/** All input for the create `Widget` mutation. */
export type CreateWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Widget` to be created by this mutation. */
  widget: WidgetInput;
};

/** The output of our create `Widget` mutation. */
export type CreateWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Widget` that was created by this mutation. */
  widget?: Maybe<Widget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Widget`. May be used by Relay 1. */
  widgetEdge?: Maybe<WidgetsEdge>;
};


/** The output of our create `Widget` mutation. */
export type CreateWidgetPayloadWidgetEdgeArgs = {
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
};

/** All input for the create `WidgetSetting` mutation. */
export type CreateWidgetSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WidgetSetting` to be created by this mutation. */
  widgetSetting: WidgetSettingInput;
};

/** The output of our create `WidgetSetting` mutation. */
export type CreateWidgetSettingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WidgetSetting` that was created by this mutation. */
  widgetSetting?: Maybe<WidgetSetting>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Widget` that is related to this `WidgetSetting`. */
  widget?: Maybe<Widget>;
  /** Reads a single `FieldKey` that is related to this `WidgetSetting`. */
  fieldKeyByFieldKey?: Maybe<FieldKey>;
  /** An edge for our `WidgetSetting`. May be used by Relay 1. */
  widgetSettingEdge?: Maybe<WidgetSettingsEdge>;
};


/** The output of our create `WidgetSetting` mutation. */
export type CreateWidgetSettingPayloadWidgetSettingEdgeArgs = {
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
};

export type CreditAnswer = {
  answerId: Scalars['String'];
  value: Scalars['String'];
};

export type CreditAnswerInput = {
  questionId: Scalars['String'];
  answerId: Scalars['String'];
};

export type CreditAppApplicant = {
  applicantId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  sin?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  addressHistory?: Maybe<Scalars['JSON']>;
};

export type CreditAppApplication = {
  applicationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  hasCoApplicant?: Maybe<Scalars['Boolean']>;
  applicationType?: Maybe<Scalars['String']>;
  primaryApplicant?: Maybe<CreditAppApplicant>;
  coApplicant?: Maybe<CreditAppApplicant>;
};

export type CreditAuthInput = {
  id?: Maybe<Scalars['UUID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  dateOfBirth: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  streetNumber: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  smsConsent?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent: Scalars['Boolean'];
  sourceUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<LeadCampaignInputType>;
  meta?: Maybe<Scalars['JSON']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  lenderApp?: Maybe<LenderAppInput>;
};

export type CreditBureau = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  avaAppId: Scalars['Int'];
  /** company | lead */
  type: Scalars['String'];
  createdAt: Scalars['Datetime'];
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['Datetime'];
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  uploaded: Scalars['Boolean'];
  /** Reads a single `AvaApp` that is related to this `CreditBureau`. */
  avaApp?: Maybe<AvaApp>;
  /** Reads and enables pagination through a set of `TradeLine`. */
  tradeLinesByBureauId: TradeLinesConnection;
  /** Reads and enables pagination through a set of `EquifaxLender`. */
  equifaxLendersByTradeLineBureauIdAndEquifaxLenderId: CreditBureauEquifaxLendersByTradeLineBureauIdAndEquifaxLenderIdManyToManyConnection;
  bureau?: Maybe<CreditReport>;
  expired: Scalars['Boolean'];
};


export type CreditBureauTradeLinesByBureauIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TradeLinesOrderBy>>;
  condition?: Maybe<TradeLineCondition>;
  filter?: Maybe<TradeLineFilter>;
};


export type CreditBureauEquifaxLendersByTradeLineBureauIdAndEquifaxLenderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EquifaxLendersOrderBy>>;
  condition?: Maybe<EquifaxLenderCondition>;
  filter?: Maybe<EquifaxLenderFilter>;
};

export type CreditBureauAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CreditBureauSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CreditBureauDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CreditBureauMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CreditBureauMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CreditBureauAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CreditBureauStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CreditBureauStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CreditBureauVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CreditBureauVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CreditBureau` object types. */
export type CreditBureauAggregatesFilter = {
  /** A filter that must pass for the relevant `CreditBureau` object to be included within the aggregate. */
  filter?: Maybe<CreditBureauFilter>;
  /** Sum aggregate over matching `CreditBureau` objects. */
  sum?: Maybe<CreditBureauSumAggregateFilter>;
  /** Distinct count aggregate over matching `CreditBureau` objects. */
  distinctCount?: Maybe<CreditBureauDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CreditBureau` objects. */
  min?: Maybe<CreditBureauMinAggregateFilter>;
  /** Maximum aggregate over matching `CreditBureau` objects. */
  max?: Maybe<CreditBureauMaxAggregateFilter>;
  /** Mean average aggregate over matching `CreditBureau` objects. */
  average?: Maybe<CreditBureauAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CreditBureau` objects. */
  stddevSample?: Maybe<CreditBureauStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CreditBureau` objects. */
  stddevPopulation?: Maybe<CreditBureauStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CreditBureau` objects. */
  varianceSample?: Maybe<CreditBureauVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CreditBureau` objects. */
  variancePopulation?: Maybe<CreditBureauVariancePopulationAggregateFilter>;
};

/** The `avaApp` to be created by this mutation. */
export type CreditBureauAvaAppIdFkeyAvaAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

/** The `creditBureau` to be created by this mutation. */
export type CreditBureauAvaAppIdFkeyCreditBureauCreateInput = {
  id?: Maybe<Scalars['Int']>;
  avaAppId?: Maybe<Scalars['Int']>;
  /** company | lead */
  type: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  avaAppToAvaAppId?: Maybe<CreditBureauAvaAppIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineBureauIdFkeyInverseInput>;
};

/** Input for the nested mutation of `avaApp` in the `CreditBureauInput` mutation. */
export type CreditBureauAvaAppIdFkeyInput = {
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectById?: Maybe<AvaAppAvaAppPkeyConnect>;
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectByLeadId?: Maybe<AvaAppAvaAppLeadIdKeyConnect>;
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<AvaAppNodeIdConnect>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateById?: Maybe<AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyUsingAvaAppPkeyUpdate>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateByLeadId?: Maybe<AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyUsingAvaAppLeadIdKeyUpdate>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<CreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyNodeIdUpdate>;
  /** A `AvaAppInput` object that will be created and connected to this object. */
  create?: Maybe<CreditBureauAvaAppIdFkeyAvaAppCreateInput>;
};

/** Input for the nested mutation of `creditBureau` in the `AvaAppInput` mutation. */
export type CreditBureauAvaAppIdFkeyInverseInput = {
  /** The primary key(s) for `creditBureau` for the far side of the relationship. */
  connectById?: Maybe<Array<CreditBureauCreditBureauPkeyConnect>>;
  /** The primary key(s) for `creditBureau` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CreditBureauNodeIdConnect>>;
  /** The primary key(s) and patch data for `creditBureau` for the far side of the relationship. */
  updateById?: Maybe<Array<CreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyUsingCreditBureauPkeyUpdate>>;
  /** The primary key(s) and patch data for `creditBureau` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyNodeIdUpdate>>;
  /** A `CreditBureauInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CreditBureauAvaAppIdFkeyCreditBureauCreateInput>>;
};

export type CreditBureauAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  avaAppId?: Maybe<BigFloatFilter>;
  creditScore?: Maybe<BigFloatFilter>;
};

export type CreditBureauAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CreditBureau` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CreditBureauCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `avaAppId` field. */
  avaAppId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `creditScore` field. */
  creditScore?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rawBureau` field. */
  rawBureau?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `rawExpired` field. */
  rawExpired?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `xml` field. */
  xml?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `uploaded` field. */
  uploaded?: Maybe<Scalars['Boolean']>;
};

/** The fields on `creditBureau` to look up the row to connect. */
export type CreditBureauCreditBureauPkeyConnect = {
  id: Scalars['Int'];
};

export type CreditBureauDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  avaAppId?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  creditScore?: Maybe<BigIntFilter>;
  rawBureau?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  rawExpired?: Maybe<BigIntFilter>;
  xml?: Maybe<BigIntFilter>;
  uploaded?: Maybe<BigIntFilter>;
};

export type CreditBureauDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawBureau across the matching connection */
  rawBureau?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawExpired across the matching connection */
  rawExpired?: Maybe<Scalars['BigInt']>;
  /** Distinct count of xml across the matching connection */
  xml?: Maybe<Scalars['BigInt']>;
  /** Distinct count of uploaded across the matching connection */
  uploaded?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `EquifaxLender` values, with data from `TradeLine`. */
export type CreditBureauEquifaxLendersByTradeLineBureauIdAndEquifaxLenderIdManyToManyConnection = {
  /** A list of `EquifaxLender` objects. */
  nodes: Array<EquifaxLender>;
  /** A list of edges which contains the `EquifaxLender`, info from the `TradeLine`, and the cursor to aid in pagination. */
  edges: Array<CreditBureauEquifaxLendersByTradeLineBureauIdAndEquifaxLenderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EquifaxLender` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EquifaxLenderAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EquifaxLenderAggregates>>;
};


/** A connection to a list of `EquifaxLender` values, with data from `TradeLine`. */
export type CreditBureauEquifaxLendersByTradeLineBureauIdAndEquifaxLenderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<EquifaxLendersGroupBy>;
  having?: Maybe<EquifaxLendersHavingInput>;
};

/** A `EquifaxLender` edge in the connection, with data from `TradeLine`. */
export type CreditBureauEquifaxLendersByTradeLineBureauIdAndEquifaxLenderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EquifaxLender` at the end of the edge. */
  node: EquifaxLender;
  /** Reads and enables pagination through a set of `TradeLine`. */
  tradeLines: TradeLinesConnection;
};


/** A `EquifaxLender` edge in the connection, with data from `TradeLine`. */
export type CreditBureauEquifaxLendersByTradeLineBureauIdAndEquifaxLenderIdManyToManyEdgeTradeLinesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TradeLinesOrderBy>>;
  condition?: Maybe<TradeLineCondition>;
  filter?: Maybe<TradeLineFilter>;
};

/** A filter to be used against `CreditBureau` object types. All fields are combined with a logical ‘and.’ */
export type CreditBureauFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `avaAppId` field. */
  avaAppId?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `creditScore` field. */
  creditScore?: Maybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `rawExpired` field. */
  rawExpired?: Maybe<BooleanFilter>;
  /** Filter by the object’s `xml` field. */
  xml?: Maybe<StringFilter>;
  /** Filter by the object’s `uploaded` field. */
  uploaded?: Maybe<BooleanFilter>;
  /** Filter by the object’s `tradeLinesByBureauId` relation. */
  tradeLinesByBureauId?: Maybe<CreditBureauToManyTradeLineFilter>;
  /** Some related `tradeLinesByBureauId` exist. */
  tradeLinesByBureauIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `avaApp` relation. */
  avaApp?: Maybe<AvaAppFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CreditBureauFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CreditBureauFilter>>;
  /** Negates the expression. */
  not?: Maybe<CreditBureauFilter>;
};

/** Grouping methods for `CreditBureau` for usage during aggregation. */
export enum CreditBureauGroupBy {
  AvaAppId = 'AVA_APP_ID',
  Type = 'TYPE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreditScore = 'CREDIT_SCORE',
  RawBureau = 'RAW_BUREAU',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  RawExpired = 'RAW_EXPIRED',
  Xml = 'XML',
  Uploaded = 'UPLOADED'
}

export type CreditBureauHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CreditBureau` aggregates. */
export type CreditBureauHavingInput = {
  AND?: Maybe<Array<CreditBureauHavingInput>>;
  OR?: Maybe<Array<CreditBureauHavingInput>>;
  sum?: Maybe<CreditBureauHavingSumInput>;
  distinctCount?: Maybe<CreditBureauHavingDistinctCountInput>;
  min?: Maybe<CreditBureauHavingMinInput>;
  max?: Maybe<CreditBureauHavingMaxInput>;
  average?: Maybe<CreditBureauHavingAverageInput>;
  stddevSample?: Maybe<CreditBureauHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CreditBureauHavingStddevPopulationInput>;
  varianceSample?: Maybe<CreditBureauHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CreditBureauHavingVariancePopulationInput>;
};

export type CreditBureauHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CreditBureau` */
export type CreditBureauInput = {
  id?: Maybe<Scalars['Int']>;
  avaAppId?: Maybe<Scalars['Int']>;
  /** company | lead */
  type: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  avaAppToAvaAppId?: Maybe<CreditBureauAvaAppIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineBureauIdFkeyInverseInput>;
};

export type CreditBureauMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  avaAppId?: Maybe<IntFilter>;
  creditScore?: Maybe<IntFilter>;
};

export type CreditBureauMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['Int']>;
  /** Maximum of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['Int']>;
};

export type CreditBureauMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  avaAppId?: Maybe<IntFilter>;
  creditScore?: Maybe<IntFilter>;
};

export type CreditBureauMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['Int']>;
  /** Minimum of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CreditBureauNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `creditBureau` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `avaApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: AvaAppPatch;
};

/** The fields on `creditBureau` to look up the row to update. */
export type CreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyUsingCreditBureauPkeyUpdate = {
  /** An object where the defined keys will be set on the `creditBureau` being updated. */
  patch: UpdateCreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CreditBureauOnTradeLineForTradeLineBureauIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `tradeLine` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `tradeLine` being updated. */
  patch: TradeLinePatch;
};

/** The fields on `creditBureau` to look up the row to update. */
export type CreditBureauOnTradeLineForTradeLineBureauIdFkeyUsingCreditBureauPkeyUpdate = {
  /** An object where the defined keys will be set on the `creditBureau` being updated. */
  patch: UpdateCreditBureauOnTradeLineForTradeLineBureauIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CreditBureau`. Fields that are set will be updated. */
export type CreditBureauPatch = {
  id?: Maybe<Scalars['Int']>;
  avaAppId?: Maybe<Scalars['Int']>;
  /** company | lead */
  type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  avaAppToAvaAppId?: Maybe<CreditBureauAvaAppIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineBureauIdFkeyInverseInput>;
};

export type CreditBureauStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  avaAppId?: Maybe<BigFloatFilter>;
  creditScore?: Maybe<BigFloatFilter>;
};

export type CreditBureauStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type CreditBureauStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  avaAppId?: Maybe<BigFloatFilter>;
  creditScore?: Maybe<BigFloatFilter>;
};

export type CreditBureauStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type CreditBureauSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  avaAppId?: Maybe<BigIntFilter>;
  creditScore?: Maybe<BigIntFilter>;
};

export type CreditBureauSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of avaAppId across the matching connection */
  avaAppId: Scalars['BigInt'];
  /** Sum of creditScore across the matching connection */
  creditScore: Scalars['BigInt'];
};

/** A filter to be used against many `TradeLine` object types. All fields are combined with a logical ‘and.’ */
export type CreditBureauToManyTradeLineFilter = {
  /** Every related `TradeLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TradeLineFilter>;
  /** Some related `TradeLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TradeLineFilter>;
  /** No related `TradeLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TradeLineFilter>;
  /** Aggregates across related `TradeLine` match the filter criteria. */
  aggregates?: Maybe<TradeLineAggregatesFilter>;
};

export type CreditBureauVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  avaAppId?: Maybe<BigFloatFilter>;
  creditScore?: Maybe<BigFloatFilter>;
};

export type CreditBureauVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type CreditBureauVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  avaAppId?: Maybe<BigFloatFilter>;
  creditScore?: Maybe<BigFloatFilter>;
};

export type CreditBureauVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CreditBureau` values. */
export type CreditBureausConnection = {
  /** A list of `CreditBureau` objects. */
  nodes: Array<CreditBureau>;
  /** A list of edges which contains the `CreditBureau` and cursor to aid in pagination. */
  edges: Array<CreditBureausEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CreditBureau` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CreditBureauAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CreditBureauAggregates>>;
};


/** A connection to a list of `CreditBureau` values. */
export type CreditBureausConnectionGroupedAggregatesArgs = {
  groupBy: Array<CreditBureauGroupBy>;
  having?: Maybe<CreditBureauHavingInput>;
};

/** A `CreditBureau` edge in the connection. */
export type CreditBureausEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CreditBureau` at the end of the edge. */
  node: CreditBureau;
};

/** Methods to use when ordering `CreditBureau`. */
export enum CreditBureausOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  AvaAppIdAsc = 'AVA_APP_ID_ASC',
  AvaAppIdDesc = 'AVA_APP_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreditScoreAsc = 'CREDIT_SCORE_ASC',
  CreditScoreDesc = 'CREDIT_SCORE_DESC',
  RawBureauAsc = 'RAW_BUREAU_ASC',
  RawBureauDesc = 'RAW_BUREAU_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  RawExpiredAsc = 'RAW_EXPIRED_ASC',
  RawExpiredDesc = 'RAW_EXPIRED_DESC',
  XmlAsc = 'XML_ASC',
  XmlDesc = 'XML_DESC',
  UploadedAsc = 'UPLOADED_ASC',
  UploadedDesc = 'UPLOADED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TradeLinesByBureauIdCountAsc = 'TRADE_LINES_BY_BUREAU_ID_COUNT_ASC',
  TradeLinesByBureauIdCountDesc = 'TRADE_LINES_BY_BUREAU_ID_COUNT_DESC',
  TradeLinesByBureauIdSumIdAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_ID_ASC',
  TradeLinesByBureauIdSumIdDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_ID_DESC',
  TradeLinesByBureauIdSumBureauIdAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_BUREAU_ID_ASC',
  TradeLinesByBureauIdSumBureauIdDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_BUREAU_ID_DESC',
  TradeLinesByBureauIdSumEquifaxLenderIdAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_EQUIFAX_LENDER_ID_ASC',
  TradeLinesByBureauIdSumEquifaxLenderIdDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_EQUIFAX_LENDER_ID_DESC',
  TradeLinesByBureauIdSumTradeDateAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_TRADE_DATE_ASC',
  TradeLinesByBureauIdSumTradeDateDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_TRADE_DATE_DESC',
  TradeLinesByBureauIdSumCreditorIdAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_CREDITOR_ID_ASC',
  TradeLinesByBureauIdSumCreditorIdDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_CREDITOR_ID_DESC',
  TradeLinesByBureauIdSumCreditorNameAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_CREDITOR_NAME_ASC',
  TradeLinesByBureauIdSumCreditorNameDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_CREDITOR_NAME_DESC',
  TradeLinesByBureauIdSumCreditorPhoneAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_CREDITOR_PHONE_ASC',
  TradeLinesByBureauIdSumCreditorPhoneDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_CREDITOR_PHONE_DESC',
  TradeLinesByBureauIdSumAccountNumberAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_ACCOUNT_NUMBER_ASC',
  TradeLinesByBureauIdSumAccountNumberDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_ACCOUNT_NUMBER_DESC',
  TradeLinesByBureauIdSumAssociationAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_ASSOCIATION_ASC',
  TradeLinesByBureauIdSumAssociationDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_ASSOCIATION_DESC',
  TradeLinesByBureauIdSumUpdateSourceAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_UPDATE_SOURCE_ASC',
  TradeLinesByBureauIdSumUpdateSourceDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_UPDATE_SOURCE_DESC',
  TradeLinesByBureauIdSumHighCreditAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesByBureauIdSumHighCreditAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesByBureauIdSumBalanceAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_BALANCE_AMOUNT_ASC',
  TradeLinesByBureauIdSumBalanceAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_BALANCE_AMOUNT_DESC',
  TradeLinesByBureauIdSumPaymentTermAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAYMENT_TERM_ASC',
  TradeLinesByBureauIdSumPaymentTermDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAYMENT_TERM_DESC',
  TradeLinesByBureauIdSumPaymentTermAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesByBureauIdSumPaymentTermAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesByBureauIdSumPastDueAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAST_DUE_AMOUNT_ASC',
  TradeLinesByBureauIdSumPastDueAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAST_DUE_AMOUNT_DESC',
  TradeLinesByBureauIdSumMonthsReviewedAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_MONTHS_REVIEWED_ASC',
  TradeLinesByBureauIdSumMonthsReviewedDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_MONTHS_REVIEWED_DESC',
  TradeLinesByBureauIdSumDateOpenedAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_DATE_OPENED_ASC',
  TradeLinesByBureauIdSumDateOpenedDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_DATE_OPENED_DESC',
  TradeLinesByBureauIdSumDateLastActivityOrPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesByBureauIdSumDateLastActivityOrPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesByBureauIdSumPortfolioTypeDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesByBureauIdSumPortfolioTypeDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesByBureauIdSumPortfolioTypeCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesByBureauIdSumPortfolioTypeCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesByBureauIdSumPaymentRateDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesByBureauIdSumPaymentRateDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesByBureauIdSumPaymentRateCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAYMENT_RATE_CODE_ASC',
  TradeLinesByBureauIdSumPaymentRateCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAYMENT_RATE_CODE_DESC',
  TradeLinesByBureauIdSumPast_30Asc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAST_30_ASC',
  TradeLinesByBureauIdSumPast_30Desc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAST_30_DESC',
  TradeLinesByBureauIdSumPast_60Asc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAST_60_ASC',
  TradeLinesByBureauIdSumPast_60Desc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAST_60_DESC',
  TradeLinesByBureauIdSumPast_90Asc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAST_90_ASC',
  TradeLinesByBureauIdSumPast_90Desc = 'TRADE_LINES_BY_BUREAU_ID_SUM_PAST_90_DESC',
  TradeLinesByBureauIdSumLastSlowPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_LAST_SLOW_PAYMENT_ASC',
  TradeLinesByBureauIdSumLastSlowPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_LAST_SLOW_PAYMENT_DESC',
  TradeLinesByBureauIdSumNarrativesAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_NARRATIVES_ASC',
  TradeLinesByBureauIdSumNarrativesDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_NARRATIVES_DESC',
  TradeLinesByBureauIdSumCreatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_CREATED_AT_ASC',
  TradeLinesByBureauIdSumCreatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_CREATED_AT_DESC',
  TradeLinesByBureauIdSumUpdatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_SUM_UPDATED_AT_ASC',
  TradeLinesByBureauIdSumUpdatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_SUM_UPDATED_AT_DESC',
  TradeLinesByBureauIdDistinctCountIdAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_ID_ASC',
  TradeLinesByBureauIdDistinctCountIdDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_ID_DESC',
  TradeLinesByBureauIdDistinctCountBureauIdAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_BUREAU_ID_ASC',
  TradeLinesByBureauIdDistinctCountBureauIdDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_BUREAU_ID_DESC',
  TradeLinesByBureauIdDistinctCountEquifaxLenderIdAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_EQUIFAX_LENDER_ID_ASC',
  TradeLinesByBureauIdDistinctCountEquifaxLenderIdDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_EQUIFAX_LENDER_ID_DESC',
  TradeLinesByBureauIdDistinctCountTradeDateAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_TRADE_DATE_ASC',
  TradeLinesByBureauIdDistinctCountTradeDateDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_TRADE_DATE_DESC',
  TradeLinesByBureauIdDistinctCountCreditorIdAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_CREDITOR_ID_ASC',
  TradeLinesByBureauIdDistinctCountCreditorIdDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_CREDITOR_ID_DESC',
  TradeLinesByBureauIdDistinctCountCreditorNameAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_CREDITOR_NAME_ASC',
  TradeLinesByBureauIdDistinctCountCreditorNameDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_CREDITOR_NAME_DESC',
  TradeLinesByBureauIdDistinctCountCreditorPhoneAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_CREDITOR_PHONE_ASC',
  TradeLinesByBureauIdDistinctCountCreditorPhoneDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_CREDITOR_PHONE_DESC',
  TradeLinesByBureauIdDistinctCountAccountNumberAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_ACCOUNT_NUMBER_ASC',
  TradeLinesByBureauIdDistinctCountAccountNumberDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_ACCOUNT_NUMBER_DESC',
  TradeLinesByBureauIdDistinctCountAssociationAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_ASSOCIATION_ASC',
  TradeLinesByBureauIdDistinctCountAssociationDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_ASSOCIATION_DESC',
  TradeLinesByBureauIdDistinctCountUpdateSourceAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_UPDATE_SOURCE_ASC',
  TradeLinesByBureauIdDistinctCountUpdateSourceDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_UPDATE_SOURCE_DESC',
  TradeLinesByBureauIdDistinctCountHighCreditAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesByBureauIdDistinctCountHighCreditAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesByBureauIdDistinctCountBalanceAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_BALANCE_AMOUNT_ASC',
  TradeLinesByBureauIdDistinctCountBalanceAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_BALANCE_AMOUNT_DESC',
  TradeLinesByBureauIdDistinctCountPaymentTermAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAYMENT_TERM_ASC',
  TradeLinesByBureauIdDistinctCountPaymentTermDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAYMENT_TERM_DESC',
  TradeLinesByBureauIdDistinctCountPaymentTermAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesByBureauIdDistinctCountPaymentTermAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesByBureauIdDistinctCountPastDueAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAST_DUE_AMOUNT_ASC',
  TradeLinesByBureauIdDistinctCountPastDueAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAST_DUE_AMOUNT_DESC',
  TradeLinesByBureauIdDistinctCountMonthsReviewedAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_MONTHS_REVIEWED_ASC',
  TradeLinesByBureauIdDistinctCountMonthsReviewedDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_MONTHS_REVIEWED_DESC',
  TradeLinesByBureauIdDistinctCountDateOpenedAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_DATE_OPENED_ASC',
  TradeLinesByBureauIdDistinctCountDateOpenedDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_DATE_OPENED_DESC',
  TradeLinesByBureauIdDistinctCountDateLastActivityOrPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesByBureauIdDistinctCountDateLastActivityOrPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesByBureauIdDistinctCountPortfolioTypeDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesByBureauIdDistinctCountPortfolioTypeDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesByBureauIdDistinctCountPortfolioTypeCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesByBureauIdDistinctCountPortfolioTypeCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesByBureauIdDistinctCountPaymentRateDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesByBureauIdDistinctCountPaymentRateDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesByBureauIdDistinctCountPaymentRateCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAYMENT_RATE_CODE_ASC',
  TradeLinesByBureauIdDistinctCountPaymentRateCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAYMENT_RATE_CODE_DESC',
  TradeLinesByBureauIdDistinctCountPast_30Asc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAST_30_ASC',
  TradeLinesByBureauIdDistinctCountPast_30Desc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAST_30_DESC',
  TradeLinesByBureauIdDistinctCountPast_60Asc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAST_60_ASC',
  TradeLinesByBureauIdDistinctCountPast_60Desc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAST_60_DESC',
  TradeLinesByBureauIdDistinctCountPast_90Asc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAST_90_ASC',
  TradeLinesByBureauIdDistinctCountPast_90Desc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_PAST_90_DESC',
  TradeLinesByBureauIdDistinctCountLastSlowPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_LAST_SLOW_PAYMENT_ASC',
  TradeLinesByBureauIdDistinctCountLastSlowPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_LAST_SLOW_PAYMENT_DESC',
  TradeLinesByBureauIdDistinctCountNarrativesAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_NARRATIVES_ASC',
  TradeLinesByBureauIdDistinctCountNarrativesDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_NARRATIVES_DESC',
  TradeLinesByBureauIdDistinctCountCreatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  TradeLinesByBureauIdDistinctCountCreatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  TradeLinesByBureauIdDistinctCountUpdatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  TradeLinesByBureauIdDistinctCountUpdatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  TradeLinesByBureauIdMinIdAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_ID_ASC',
  TradeLinesByBureauIdMinIdDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_ID_DESC',
  TradeLinesByBureauIdMinBureauIdAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_BUREAU_ID_ASC',
  TradeLinesByBureauIdMinBureauIdDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_BUREAU_ID_DESC',
  TradeLinesByBureauIdMinEquifaxLenderIdAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_EQUIFAX_LENDER_ID_ASC',
  TradeLinesByBureauIdMinEquifaxLenderIdDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_EQUIFAX_LENDER_ID_DESC',
  TradeLinesByBureauIdMinTradeDateAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_TRADE_DATE_ASC',
  TradeLinesByBureauIdMinTradeDateDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_TRADE_DATE_DESC',
  TradeLinesByBureauIdMinCreditorIdAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_CREDITOR_ID_ASC',
  TradeLinesByBureauIdMinCreditorIdDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_CREDITOR_ID_DESC',
  TradeLinesByBureauIdMinCreditorNameAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_CREDITOR_NAME_ASC',
  TradeLinesByBureauIdMinCreditorNameDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_CREDITOR_NAME_DESC',
  TradeLinesByBureauIdMinCreditorPhoneAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_CREDITOR_PHONE_ASC',
  TradeLinesByBureauIdMinCreditorPhoneDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_CREDITOR_PHONE_DESC',
  TradeLinesByBureauIdMinAccountNumberAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_ACCOUNT_NUMBER_ASC',
  TradeLinesByBureauIdMinAccountNumberDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_ACCOUNT_NUMBER_DESC',
  TradeLinesByBureauIdMinAssociationAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_ASSOCIATION_ASC',
  TradeLinesByBureauIdMinAssociationDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_ASSOCIATION_DESC',
  TradeLinesByBureauIdMinUpdateSourceAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_UPDATE_SOURCE_ASC',
  TradeLinesByBureauIdMinUpdateSourceDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_UPDATE_SOURCE_DESC',
  TradeLinesByBureauIdMinHighCreditAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesByBureauIdMinHighCreditAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesByBureauIdMinBalanceAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_BALANCE_AMOUNT_ASC',
  TradeLinesByBureauIdMinBalanceAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_BALANCE_AMOUNT_DESC',
  TradeLinesByBureauIdMinPaymentTermAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAYMENT_TERM_ASC',
  TradeLinesByBureauIdMinPaymentTermDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAYMENT_TERM_DESC',
  TradeLinesByBureauIdMinPaymentTermAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesByBureauIdMinPaymentTermAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesByBureauIdMinPastDueAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAST_DUE_AMOUNT_ASC',
  TradeLinesByBureauIdMinPastDueAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAST_DUE_AMOUNT_DESC',
  TradeLinesByBureauIdMinMonthsReviewedAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_MONTHS_REVIEWED_ASC',
  TradeLinesByBureauIdMinMonthsReviewedDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_MONTHS_REVIEWED_DESC',
  TradeLinesByBureauIdMinDateOpenedAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_DATE_OPENED_ASC',
  TradeLinesByBureauIdMinDateOpenedDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_DATE_OPENED_DESC',
  TradeLinesByBureauIdMinDateLastActivityOrPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesByBureauIdMinDateLastActivityOrPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesByBureauIdMinPortfolioTypeDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesByBureauIdMinPortfolioTypeDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesByBureauIdMinPortfolioTypeCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesByBureauIdMinPortfolioTypeCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesByBureauIdMinPaymentRateDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesByBureauIdMinPaymentRateDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesByBureauIdMinPaymentRateCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAYMENT_RATE_CODE_ASC',
  TradeLinesByBureauIdMinPaymentRateCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAYMENT_RATE_CODE_DESC',
  TradeLinesByBureauIdMinPast_30Asc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAST_30_ASC',
  TradeLinesByBureauIdMinPast_30Desc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAST_30_DESC',
  TradeLinesByBureauIdMinPast_60Asc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAST_60_ASC',
  TradeLinesByBureauIdMinPast_60Desc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAST_60_DESC',
  TradeLinesByBureauIdMinPast_90Asc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAST_90_ASC',
  TradeLinesByBureauIdMinPast_90Desc = 'TRADE_LINES_BY_BUREAU_ID_MIN_PAST_90_DESC',
  TradeLinesByBureauIdMinLastSlowPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_LAST_SLOW_PAYMENT_ASC',
  TradeLinesByBureauIdMinLastSlowPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_LAST_SLOW_PAYMENT_DESC',
  TradeLinesByBureauIdMinNarrativesAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_NARRATIVES_ASC',
  TradeLinesByBureauIdMinNarrativesDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_NARRATIVES_DESC',
  TradeLinesByBureauIdMinCreatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_CREATED_AT_ASC',
  TradeLinesByBureauIdMinCreatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_CREATED_AT_DESC',
  TradeLinesByBureauIdMinUpdatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_MIN_UPDATED_AT_ASC',
  TradeLinesByBureauIdMinUpdatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_MIN_UPDATED_AT_DESC',
  TradeLinesByBureauIdMaxIdAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_ID_ASC',
  TradeLinesByBureauIdMaxIdDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_ID_DESC',
  TradeLinesByBureauIdMaxBureauIdAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_BUREAU_ID_ASC',
  TradeLinesByBureauIdMaxBureauIdDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_BUREAU_ID_DESC',
  TradeLinesByBureauIdMaxEquifaxLenderIdAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_EQUIFAX_LENDER_ID_ASC',
  TradeLinesByBureauIdMaxEquifaxLenderIdDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_EQUIFAX_LENDER_ID_DESC',
  TradeLinesByBureauIdMaxTradeDateAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_TRADE_DATE_ASC',
  TradeLinesByBureauIdMaxTradeDateDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_TRADE_DATE_DESC',
  TradeLinesByBureauIdMaxCreditorIdAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_CREDITOR_ID_ASC',
  TradeLinesByBureauIdMaxCreditorIdDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_CREDITOR_ID_DESC',
  TradeLinesByBureauIdMaxCreditorNameAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_CREDITOR_NAME_ASC',
  TradeLinesByBureauIdMaxCreditorNameDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_CREDITOR_NAME_DESC',
  TradeLinesByBureauIdMaxCreditorPhoneAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_CREDITOR_PHONE_ASC',
  TradeLinesByBureauIdMaxCreditorPhoneDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_CREDITOR_PHONE_DESC',
  TradeLinesByBureauIdMaxAccountNumberAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_ACCOUNT_NUMBER_ASC',
  TradeLinesByBureauIdMaxAccountNumberDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_ACCOUNT_NUMBER_DESC',
  TradeLinesByBureauIdMaxAssociationAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_ASSOCIATION_ASC',
  TradeLinesByBureauIdMaxAssociationDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_ASSOCIATION_DESC',
  TradeLinesByBureauIdMaxUpdateSourceAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_UPDATE_SOURCE_ASC',
  TradeLinesByBureauIdMaxUpdateSourceDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_UPDATE_SOURCE_DESC',
  TradeLinesByBureauIdMaxHighCreditAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesByBureauIdMaxHighCreditAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesByBureauIdMaxBalanceAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_BALANCE_AMOUNT_ASC',
  TradeLinesByBureauIdMaxBalanceAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_BALANCE_AMOUNT_DESC',
  TradeLinesByBureauIdMaxPaymentTermAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAYMENT_TERM_ASC',
  TradeLinesByBureauIdMaxPaymentTermDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAYMENT_TERM_DESC',
  TradeLinesByBureauIdMaxPaymentTermAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesByBureauIdMaxPaymentTermAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesByBureauIdMaxPastDueAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAST_DUE_AMOUNT_ASC',
  TradeLinesByBureauIdMaxPastDueAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAST_DUE_AMOUNT_DESC',
  TradeLinesByBureauIdMaxMonthsReviewedAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_MONTHS_REVIEWED_ASC',
  TradeLinesByBureauIdMaxMonthsReviewedDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_MONTHS_REVIEWED_DESC',
  TradeLinesByBureauIdMaxDateOpenedAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_DATE_OPENED_ASC',
  TradeLinesByBureauIdMaxDateOpenedDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_DATE_OPENED_DESC',
  TradeLinesByBureauIdMaxDateLastActivityOrPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesByBureauIdMaxDateLastActivityOrPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesByBureauIdMaxPortfolioTypeDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesByBureauIdMaxPortfolioTypeDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesByBureauIdMaxPortfolioTypeCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesByBureauIdMaxPortfolioTypeCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesByBureauIdMaxPaymentRateDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesByBureauIdMaxPaymentRateDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesByBureauIdMaxPaymentRateCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAYMENT_RATE_CODE_ASC',
  TradeLinesByBureauIdMaxPaymentRateCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAYMENT_RATE_CODE_DESC',
  TradeLinesByBureauIdMaxPast_30Asc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAST_30_ASC',
  TradeLinesByBureauIdMaxPast_30Desc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAST_30_DESC',
  TradeLinesByBureauIdMaxPast_60Asc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAST_60_ASC',
  TradeLinesByBureauIdMaxPast_60Desc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAST_60_DESC',
  TradeLinesByBureauIdMaxPast_90Asc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAST_90_ASC',
  TradeLinesByBureauIdMaxPast_90Desc = 'TRADE_LINES_BY_BUREAU_ID_MAX_PAST_90_DESC',
  TradeLinesByBureauIdMaxLastSlowPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_LAST_SLOW_PAYMENT_ASC',
  TradeLinesByBureauIdMaxLastSlowPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_LAST_SLOW_PAYMENT_DESC',
  TradeLinesByBureauIdMaxNarrativesAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_NARRATIVES_ASC',
  TradeLinesByBureauIdMaxNarrativesDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_NARRATIVES_DESC',
  TradeLinesByBureauIdMaxCreatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_CREATED_AT_ASC',
  TradeLinesByBureauIdMaxCreatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_CREATED_AT_DESC',
  TradeLinesByBureauIdMaxUpdatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_MAX_UPDATED_AT_ASC',
  TradeLinesByBureauIdMaxUpdatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_MAX_UPDATED_AT_DESC',
  TradeLinesByBureauIdAverageIdAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_ID_ASC',
  TradeLinesByBureauIdAverageIdDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_ID_DESC',
  TradeLinesByBureauIdAverageBureauIdAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_BUREAU_ID_ASC',
  TradeLinesByBureauIdAverageBureauIdDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_BUREAU_ID_DESC',
  TradeLinesByBureauIdAverageEquifaxLenderIdAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_EQUIFAX_LENDER_ID_ASC',
  TradeLinesByBureauIdAverageEquifaxLenderIdDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_EQUIFAX_LENDER_ID_DESC',
  TradeLinesByBureauIdAverageTradeDateAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_TRADE_DATE_ASC',
  TradeLinesByBureauIdAverageTradeDateDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_TRADE_DATE_DESC',
  TradeLinesByBureauIdAverageCreditorIdAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_CREDITOR_ID_ASC',
  TradeLinesByBureauIdAverageCreditorIdDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_CREDITOR_ID_DESC',
  TradeLinesByBureauIdAverageCreditorNameAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_CREDITOR_NAME_ASC',
  TradeLinesByBureauIdAverageCreditorNameDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_CREDITOR_NAME_DESC',
  TradeLinesByBureauIdAverageCreditorPhoneAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_CREDITOR_PHONE_ASC',
  TradeLinesByBureauIdAverageCreditorPhoneDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_CREDITOR_PHONE_DESC',
  TradeLinesByBureauIdAverageAccountNumberAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_ACCOUNT_NUMBER_ASC',
  TradeLinesByBureauIdAverageAccountNumberDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_ACCOUNT_NUMBER_DESC',
  TradeLinesByBureauIdAverageAssociationAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_ASSOCIATION_ASC',
  TradeLinesByBureauIdAverageAssociationDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_ASSOCIATION_DESC',
  TradeLinesByBureauIdAverageUpdateSourceAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_UPDATE_SOURCE_ASC',
  TradeLinesByBureauIdAverageUpdateSourceDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_UPDATE_SOURCE_DESC',
  TradeLinesByBureauIdAverageHighCreditAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesByBureauIdAverageHighCreditAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesByBureauIdAverageBalanceAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_BALANCE_AMOUNT_ASC',
  TradeLinesByBureauIdAverageBalanceAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_BALANCE_AMOUNT_DESC',
  TradeLinesByBureauIdAveragePaymentTermAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAYMENT_TERM_ASC',
  TradeLinesByBureauIdAveragePaymentTermDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAYMENT_TERM_DESC',
  TradeLinesByBureauIdAveragePaymentTermAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesByBureauIdAveragePaymentTermAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesByBureauIdAveragePastDueAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAST_DUE_AMOUNT_ASC',
  TradeLinesByBureauIdAveragePastDueAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAST_DUE_AMOUNT_DESC',
  TradeLinesByBureauIdAverageMonthsReviewedAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_MONTHS_REVIEWED_ASC',
  TradeLinesByBureauIdAverageMonthsReviewedDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_MONTHS_REVIEWED_DESC',
  TradeLinesByBureauIdAverageDateOpenedAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_DATE_OPENED_ASC',
  TradeLinesByBureauIdAverageDateOpenedDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_DATE_OPENED_DESC',
  TradeLinesByBureauIdAverageDateLastActivityOrPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesByBureauIdAverageDateLastActivityOrPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesByBureauIdAveragePortfolioTypeDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesByBureauIdAveragePortfolioTypeDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesByBureauIdAveragePortfolioTypeCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesByBureauIdAveragePortfolioTypeCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesByBureauIdAveragePaymentRateDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesByBureauIdAveragePaymentRateDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesByBureauIdAveragePaymentRateCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAYMENT_RATE_CODE_ASC',
  TradeLinesByBureauIdAveragePaymentRateCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAYMENT_RATE_CODE_DESC',
  TradeLinesByBureauIdAveragePast_30Asc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAST_30_ASC',
  TradeLinesByBureauIdAveragePast_30Desc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAST_30_DESC',
  TradeLinesByBureauIdAveragePast_60Asc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAST_60_ASC',
  TradeLinesByBureauIdAveragePast_60Desc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAST_60_DESC',
  TradeLinesByBureauIdAveragePast_90Asc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAST_90_ASC',
  TradeLinesByBureauIdAveragePast_90Desc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_PAST_90_DESC',
  TradeLinesByBureauIdAverageLastSlowPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_LAST_SLOW_PAYMENT_ASC',
  TradeLinesByBureauIdAverageLastSlowPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_LAST_SLOW_PAYMENT_DESC',
  TradeLinesByBureauIdAverageNarrativesAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_NARRATIVES_ASC',
  TradeLinesByBureauIdAverageNarrativesDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_NARRATIVES_DESC',
  TradeLinesByBureauIdAverageCreatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_CREATED_AT_ASC',
  TradeLinesByBureauIdAverageCreatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_CREATED_AT_DESC',
  TradeLinesByBureauIdAverageUpdatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_UPDATED_AT_ASC',
  TradeLinesByBureauIdAverageUpdatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_AVERAGE_UPDATED_AT_DESC',
  TradeLinesByBureauIdStddevSampleIdAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_ID_ASC',
  TradeLinesByBureauIdStddevSampleIdDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_ID_DESC',
  TradeLinesByBureauIdStddevSampleBureauIdAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_BUREAU_ID_ASC',
  TradeLinesByBureauIdStddevSampleBureauIdDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_BUREAU_ID_DESC',
  TradeLinesByBureauIdStddevSampleEquifaxLenderIdAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_EQUIFAX_LENDER_ID_ASC',
  TradeLinesByBureauIdStddevSampleEquifaxLenderIdDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_EQUIFAX_LENDER_ID_DESC',
  TradeLinesByBureauIdStddevSampleTradeDateAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_TRADE_DATE_ASC',
  TradeLinesByBureauIdStddevSampleTradeDateDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_TRADE_DATE_DESC',
  TradeLinesByBureauIdStddevSampleCreditorIdAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_CREDITOR_ID_ASC',
  TradeLinesByBureauIdStddevSampleCreditorIdDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_CREDITOR_ID_DESC',
  TradeLinesByBureauIdStddevSampleCreditorNameAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_CREDITOR_NAME_ASC',
  TradeLinesByBureauIdStddevSampleCreditorNameDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_CREDITOR_NAME_DESC',
  TradeLinesByBureauIdStddevSampleCreditorPhoneAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_CREDITOR_PHONE_ASC',
  TradeLinesByBureauIdStddevSampleCreditorPhoneDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_CREDITOR_PHONE_DESC',
  TradeLinesByBureauIdStddevSampleAccountNumberAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_ACCOUNT_NUMBER_ASC',
  TradeLinesByBureauIdStddevSampleAccountNumberDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_ACCOUNT_NUMBER_DESC',
  TradeLinesByBureauIdStddevSampleAssociationAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_ASSOCIATION_ASC',
  TradeLinesByBureauIdStddevSampleAssociationDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_ASSOCIATION_DESC',
  TradeLinesByBureauIdStddevSampleUpdateSourceAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_UPDATE_SOURCE_ASC',
  TradeLinesByBureauIdStddevSampleUpdateSourceDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_UPDATE_SOURCE_DESC',
  TradeLinesByBureauIdStddevSampleHighCreditAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesByBureauIdStddevSampleHighCreditAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesByBureauIdStddevSampleBalanceAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_BALANCE_AMOUNT_ASC',
  TradeLinesByBureauIdStddevSampleBalanceAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_BALANCE_AMOUNT_DESC',
  TradeLinesByBureauIdStddevSamplePaymentTermAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAYMENT_TERM_ASC',
  TradeLinesByBureauIdStddevSamplePaymentTermDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAYMENT_TERM_DESC',
  TradeLinesByBureauIdStddevSamplePaymentTermAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesByBureauIdStddevSamplePaymentTermAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesByBureauIdStddevSamplePastDueAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAST_DUE_AMOUNT_ASC',
  TradeLinesByBureauIdStddevSamplePastDueAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAST_DUE_AMOUNT_DESC',
  TradeLinesByBureauIdStddevSampleMonthsReviewedAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_MONTHS_REVIEWED_ASC',
  TradeLinesByBureauIdStddevSampleMonthsReviewedDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_MONTHS_REVIEWED_DESC',
  TradeLinesByBureauIdStddevSampleDateOpenedAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_DATE_OPENED_ASC',
  TradeLinesByBureauIdStddevSampleDateOpenedDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_DATE_OPENED_DESC',
  TradeLinesByBureauIdStddevSampleDateLastActivityOrPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesByBureauIdStddevSampleDateLastActivityOrPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesByBureauIdStddevSamplePortfolioTypeDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesByBureauIdStddevSamplePortfolioTypeDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesByBureauIdStddevSamplePortfolioTypeCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesByBureauIdStddevSamplePortfolioTypeCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesByBureauIdStddevSamplePaymentRateDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesByBureauIdStddevSamplePaymentRateDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesByBureauIdStddevSamplePaymentRateCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAYMENT_RATE_CODE_ASC',
  TradeLinesByBureauIdStddevSamplePaymentRateCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAYMENT_RATE_CODE_DESC',
  TradeLinesByBureauIdStddevSamplePast_30Asc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAST_30_ASC',
  TradeLinesByBureauIdStddevSamplePast_30Desc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAST_30_DESC',
  TradeLinesByBureauIdStddevSamplePast_60Asc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAST_60_ASC',
  TradeLinesByBureauIdStddevSamplePast_60Desc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAST_60_DESC',
  TradeLinesByBureauIdStddevSamplePast_90Asc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAST_90_ASC',
  TradeLinesByBureauIdStddevSamplePast_90Desc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_PAST_90_DESC',
  TradeLinesByBureauIdStddevSampleLastSlowPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_LAST_SLOW_PAYMENT_ASC',
  TradeLinesByBureauIdStddevSampleLastSlowPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_LAST_SLOW_PAYMENT_DESC',
  TradeLinesByBureauIdStddevSampleNarrativesAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_NARRATIVES_ASC',
  TradeLinesByBureauIdStddevSampleNarrativesDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_NARRATIVES_DESC',
  TradeLinesByBureauIdStddevSampleCreatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  TradeLinesByBureauIdStddevSampleCreatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  TradeLinesByBureauIdStddevSampleUpdatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TradeLinesByBureauIdStddevSampleUpdatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TradeLinesByBureauIdStddevPopulationIdAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_ID_ASC',
  TradeLinesByBureauIdStddevPopulationIdDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_ID_DESC',
  TradeLinesByBureauIdStddevPopulationBureauIdAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_BUREAU_ID_ASC',
  TradeLinesByBureauIdStddevPopulationBureauIdDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_BUREAU_ID_DESC',
  TradeLinesByBureauIdStddevPopulationEquifaxLenderIdAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_EQUIFAX_LENDER_ID_ASC',
  TradeLinesByBureauIdStddevPopulationEquifaxLenderIdDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_EQUIFAX_LENDER_ID_DESC',
  TradeLinesByBureauIdStddevPopulationTradeDateAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_TRADE_DATE_ASC',
  TradeLinesByBureauIdStddevPopulationTradeDateDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_TRADE_DATE_DESC',
  TradeLinesByBureauIdStddevPopulationCreditorIdAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_CREDITOR_ID_ASC',
  TradeLinesByBureauIdStddevPopulationCreditorIdDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_CREDITOR_ID_DESC',
  TradeLinesByBureauIdStddevPopulationCreditorNameAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_CREDITOR_NAME_ASC',
  TradeLinesByBureauIdStddevPopulationCreditorNameDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_CREDITOR_NAME_DESC',
  TradeLinesByBureauIdStddevPopulationCreditorPhoneAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_CREDITOR_PHONE_ASC',
  TradeLinesByBureauIdStddevPopulationCreditorPhoneDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_CREDITOR_PHONE_DESC',
  TradeLinesByBureauIdStddevPopulationAccountNumberAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_ACCOUNT_NUMBER_ASC',
  TradeLinesByBureauIdStddevPopulationAccountNumberDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_ACCOUNT_NUMBER_DESC',
  TradeLinesByBureauIdStddevPopulationAssociationAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_ASSOCIATION_ASC',
  TradeLinesByBureauIdStddevPopulationAssociationDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_ASSOCIATION_DESC',
  TradeLinesByBureauIdStddevPopulationUpdateSourceAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_UPDATE_SOURCE_ASC',
  TradeLinesByBureauIdStddevPopulationUpdateSourceDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_UPDATE_SOURCE_DESC',
  TradeLinesByBureauIdStddevPopulationHighCreditAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesByBureauIdStddevPopulationHighCreditAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesByBureauIdStddevPopulationBalanceAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_BALANCE_AMOUNT_ASC',
  TradeLinesByBureauIdStddevPopulationBalanceAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_BALANCE_AMOUNT_DESC',
  TradeLinesByBureauIdStddevPopulationPaymentTermAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAYMENT_TERM_ASC',
  TradeLinesByBureauIdStddevPopulationPaymentTermDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAYMENT_TERM_DESC',
  TradeLinesByBureauIdStddevPopulationPaymentTermAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesByBureauIdStddevPopulationPaymentTermAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesByBureauIdStddevPopulationPastDueAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAST_DUE_AMOUNT_ASC',
  TradeLinesByBureauIdStddevPopulationPastDueAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAST_DUE_AMOUNT_DESC',
  TradeLinesByBureauIdStddevPopulationMonthsReviewedAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_MONTHS_REVIEWED_ASC',
  TradeLinesByBureauIdStddevPopulationMonthsReviewedDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_MONTHS_REVIEWED_DESC',
  TradeLinesByBureauIdStddevPopulationDateOpenedAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_DATE_OPENED_ASC',
  TradeLinesByBureauIdStddevPopulationDateOpenedDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_DATE_OPENED_DESC',
  TradeLinesByBureauIdStddevPopulationDateLastActivityOrPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesByBureauIdStddevPopulationDateLastActivityOrPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesByBureauIdStddevPopulationPortfolioTypeDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesByBureauIdStddevPopulationPortfolioTypeDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesByBureauIdStddevPopulationPortfolioTypeCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesByBureauIdStddevPopulationPortfolioTypeCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesByBureauIdStddevPopulationPaymentRateDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesByBureauIdStddevPopulationPaymentRateDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesByBureauIdStddevPopulationPaymentRateCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAYMENT_RATE_CODE_ASC',
  TradeLinesByBureauIdStddevPopulationPaymentRateCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAYMENT_RATE_CODE_DESC',
  TradeLinesByBureauIdStddevPopulationPast_30Asc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAST_30_ASC',
  TradeLinesByBureauIdStddevPopulationPast_30Desc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAST_30_DESC',
  TradeLinesByBureauIdStddevPopulationPast_60Asc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAST_60_ASC',
  TradeLinesByBureauIdStddevPopulationPast_60Desc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAST_60_DESC',
  TradeLinesByBureauIdStddevPopulationPast_90Asc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAST_90_ASC',
  TradeLinesByBureauIdStddevPopulationPast_90Desc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_PAST_90_DESC',
  TradeLinesByBureauIdStddevPopulationLastSlowPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_LAST_SLOW_PAYMENT_ASC',
  TradeLinesByBureauIdStddevPopulationLastSlowPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_LAST_SLOW_PAYMENT_DESC',
  TradeLinesByBureauIdStddevPopulationNarrativesAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_NARRATIVES_ASC',
  TradeLinesByBureauIdStddevPopulationNarrativesDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_NARRATIVES_DESC',
  TradeLinesByBureauIdStddevPopulationCreatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  TradeLinesByBureauIdStddevPopulationCreatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  TradeLinesByBureauIdStddevPopulationUpdatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  TradeLinesByBureauIdStddevPopulationUpdatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  TradeLinesByBureauIdVarianceSampleIdAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_ID_ASC',
  TradeLinesByBureauIdVarianceSampleIdDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_ID_DESC',
  TradeLinesByBureauIdVarianceSampleBureauIdAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_BUREAU_ID_ASC',
  TradeLinesByBureauIdVarianceSampleBureauIdDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_BUREAU_ID_DESC',
  TradeLinesByBureauIdVarianceSampleEquifaxLenderIdAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_EQUIFAX_LENDER_ID_ASC',
  TradeLinesByBureauIdVarianceSampleEquifaxLenderIdDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_EQUIFAX_LENDER_ID_DESC',
  TradeLinesByBureauIdVarianceSampleTradeDateAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_TRADE_DATE_ASC',
  TradeLinesByBureauIdVarianceSampleTradeDateDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_TRADE_DATE_DESC',
  TradeLinesByBureauIdVarianceSampleCreditorIdAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_CREDITOR_ID_ASC',
  TradeLinesByBureauIdVarianceSampleCreditorIdDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_CREDITOR_ID_DESC',
  TradeLinesByBureauIdVarianceSampleCreditorNameAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_CREDITOR_NAME_ASC',
  TradeLinesByBureauIdVarianceSampleCreditorNameDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_CREDITOR_NAME_DESC',
  TradeLinesByBureauIdVarianceSampleCreditorPhoneAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_CREDITOR_PHONE_ASC',
  TradeLinesByBureauIdVarianceSampleCreditorPhoneDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_CREDITOR_PHONE_DESC',
  TradeLinesByBureauIdVarianceSampleAccountNumberAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_ACCOUNT_NUMBER_ASC',
  TradeLinesByBureauIdVarianceSampleAccountNumberDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_ACCOUNT_NUMBER_DESC',
  TradeLinesByBureauIdVarianceSampleAssociationAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_ASSOCIATION_ASC',
  TradeLinesByBureauIdVarianceSampleAssociationDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_ASSOCIATION_DESC',
  TradeLinesByBureauIdVarianceSampleUpdateSourceAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_UPDATE_SOURCE_ASC',
  TradeLinesByBureauIdVarianceSampleUpdateSourceDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_UPDATE_SOURCE_DESC',
  TradeLinesByBureauIdVarianceSampleHighCreditAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesByBureauIdVarianceSampleHighCreditAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesByBureauIdVarianceSampleBalanceAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_BALANCE_AMOUNT_ASC',
  TradeLinesByBureauIdVarianceSampleBalanceAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_BALANCE_AMOUNT_DESC',
  TradeLinesByBureauIdVarianceSamplePaymentTermAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAYMENT_TERM_ASC',
  TradeLinesByBureauIdVarianceSamplePaymentTermDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAYMENT_TERM_DESC',
  TradeLinesByBureauIdVarianceSamplePaymentTermAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesByBureauIdVarianceSamplePaymentTermAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesByBureauIdVarianceSamplePastDueAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAST_DUE_AMOUNT_ASC',
  TradeLinesByBureauIdVarianceSamplePastDueAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAST_DUE_AMOUNT_DESC',
  TradeLinesByBureauIdVarianceSampleMonthsReviewedAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_MONTHS_REVIEWED_ASC',
  TradeLinesByBureauIdVarianceSampleMonthsReviewedDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_MONTHS_REVIEWED_DESC',
  TradeLinesByBureauIdVarianceSampleDateOpenedAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_DATE_OPENED_ASC',
  TradeLinesByBureauIdVarianceSampleDateOpenedDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_DATE_OPENED_DESC',
  TradeLinesByBureauIdVarianceSampleDateLastActivityOrPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesByBureauIdVarianceSampleDateLastActivityOrPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesByBureauIdVarianceSamplePortfolioTypeDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesByBureauIdVarianceSamplePortfolioTypeDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesByBureauIdVarianceSamplePortfolioTypeCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesByBureauIdVarianceSamplePortfolioTypeCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesByBureauIdVarianceSamplePaymentRateDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesByBureauIdVarianceSamplePaymentRateDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesByBureauIdVarianceSamplePaymentRateCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAYMENT_RATE_CODE_ASC',
  TradeLinesByBureauIdVarianceSamplePaymentRateCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAYMENT_RATE_CODE_DESC',
  TradeLinesByBureauIdVarianceSamplePast_30Asc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAST_30_ASC',
  TradeLinesByBureauIdVarianceSamplePast_30Desc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAST_30_DESC',
  TradeLinesByBureauIdVarianceSamplePast_60Asc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAST_60_ASC',
  TradeLinesByBureauIdVarianceSamplePast_60Desc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAST_60_DESC',
  TradeLinesByBureauIdVarianceSamplePast_90Asc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAST_90_ASC',
  TradeLinesByBureauIdVarianceSamplePast_90Desc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_PAST_90_DESC',
  TradeLinesByBureauIdVarianceSampleLastSlowPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_LAST_SLOW_PAYMENT_ASC',
  TradeLinesByBureauIdVarianceSampleLastSlowPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_LAST_SLOW_PAYMENT_DESC',
  TradeLinesByBureauIdVarianceSampleNarrativesAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_NARRATIVES_ASC',
  TradeLinesByBureauIdVarianceSampleNarrativesDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_NARRATIVES_DESC',
  TradeLinesByBureauIdVarianceSampleCreatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TradeLinesByBureauIdVarianceSampleCreatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TradeLinesByBureauIdVarianceSampleUpdatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TradeLinesByBureauIdVarianceSampleUpdatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TradeLinesByBureauIdVariancePopulationIdAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_ID_ASC',
  TradeLinesByBureauIdVariancePopulationIdDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_ID_DESC',
  TradeLinesByBureauIdVariancePopulationBureauIdAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_BUREAU_ID_ASC',
  TradeLinesByBureauIdVariancePopulationBureauIdDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_BUREAU_ID_DESC',
  TradeLinesByBureauIdVariancePopulationEquifaxLenderIdAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_EQUIFAX_LENDER_ID_ASC',
  TradeLinesByBureauIdVariancePopulationEquifaxLenderIdDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_EQUIFAX_LENDER_ID_DESC',
  TradeLinesByBureauIdVariancePopulationTradeDateAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_TRADE_DATE_ASC',
  TradeLinesByBureauIdVariancePopulationTradeDateDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_TRADE_DATE_DESC',
  TradeLinesByBureauIdVariancePopulationCreditorIdAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_CREDITOR_ID_ASC',
  TradeLinesByBureauIdVariancePopulationCreditorIdDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_CREDITOR_ID_DESC',
  TradeLinesByBureauIdVariancePopulationCreditorNameAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_CREDITOR_NAME_ASC',
  TradeLinesByBureauIdVariancePopulationCreditorNameDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_CREDITOR_NAME_DESC',
  TradeLinesByBureauIdVariancePopulationCreditorPhoneAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_CREDITOR_PHONE_ASC',
  TradeLinesByBureauIdVariancePopulationCreditorPhoneDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_CREDITOR_PHONE_DESC',
  TradeLinesByBureauIdVariancePopulationAccountNumberAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_ACCOUNT_NUMBER_ASC',
  TradeLinesByBureauIdVariancePopulationAccountNumberDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_ACCOUNT_NUMBER_DESC',
  TradeLinesByBureauIdVariancePopulationAssociationAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_ASSOCIATION_ASC',
  TradeLinesByBureauIdVariancePopulationAssociationDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_ASSOCIATION_DESC',
  TradeLinesByBureauIdVariancePopulationUpdateSourceAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_UPDATE_SOURCE_ASC',
  TradeLinesByBureauIdVariancePopulationUpdateSourceDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_UPDATE_SOURCE_DESC',
  TradeLinesByBureauIdVariancePopulationHighCreditAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesByBureauIdVariancePopulationHighCreditAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesByBureauIdVariancePopulationBalanceAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_BALANCE_AMOUNT_ASC',
  TradeLinesByBureauIdVariancePopulationBalanceAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_BALANCE_AMOUNT_DESC',
  TradeLinesByBureauIdVariancePopulationPaymentTermAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAYMENT_TERM_ASC',
  TradeLinesByBureauIdVariancePopulationPaymentTermDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAYMENT_TERM_DESC',
  TradeLinesByBureauIdVariancePopulationPaymentTermAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesByBureauIdVariancePopulationPaymentTermAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesByBureauIdVariancePopulationPastDueAmountAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAST_DUE_AMOUNT_ASC',
  TradeLinesByBureauIdVariancePopulationPastDueAmountDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAST_DUE_AMOUNT_DESC',
  TradeLinesByBureauIdVariancePopulationMonthsReviewedAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_MONTHS_REVIEWED_ASC',
  TradeLinesByBureauIdVariancePopulationMonthsReviewedDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_MONTHS_REVIEWED_DESC',
  TradeLinesByBureauIdVariancePopulationDateOpenedAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_DATE_OPENED_ASC',
  TradeLinesByBureauIdVariancePopulationDateOpenedDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_DATE_OPENED_DESC',
  TradeLinesByBureauIdVariancePopulationDateLastActivityOrPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesByBureauIdVariancePopulationDateLastActivityOrPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesByBureauIdVariancePopulationPortfolioTypeDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesByBureauIdVariancePopulationPortfolioTypeDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesByBureauIdVariancePopulationPortfolioTypeCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesByBureauIdVariancePopulationPortfolioTypeCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesByBureauIdVariancePopulationPaymentRateDescriptionAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesByBureauIdVariancePopulationPaymentRateDescriptionDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesByBureauIdVariancePopulationPaymentRateCodeAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAYMENT_RATE_CODE_ASC',
  TradeLinesByBureauIdVariancePopulationPaymentRateCodeDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAYMENT_RATE_CODE_DESC',
  TradeLinesByBureauIdVariancePopulationPast_30Asc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAST_30_ASC',
  TradeLinesByBureauIdVariancePopulationPast_30Desc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAST_30_DESC',
  TradeLinesByBureauIdVariancePopulationPast_60Asc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAST_60_ASC',
  TradeLinesByBureauIdVariancePopulationPast_60Desc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAST_60_DESC',
  TradeLinesByBureauIdVariancePopulationPast_90Asc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAST_90_ASC',
  TradeLinesByBureauIdVariancePopulationPast_90Desc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_PAST_90_DESC',
  TradeLinesByBureauIdVariancePopulationLastSlowPaymentAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_LAST_SLOW_PAYMENT_ASC',
  TradeLinesByBureauIdVariancePopulationLastSlowPaymentDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_LAST_SLOW_PAYMENT_DESC',
  TradeLinesByBureauIdVariancePopulationNarrativesAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_NARRATIVES_ASC',
  TradeLinesByBureauIdVariancePopulationNarrativesDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_NARRATIVES_DESC',
  TradeLinesByBureauIdVariancePopulationCreatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  TradeLinesByBureauIdVariancePopulationCreatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  TradeLinesByBureauIdVariancePopulationUpdatedAtAsc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TradeLinesByBureauIdVariancePopulationUpdatedAtDesc = 'TRADE_LINES_BY_BUREAU_ID_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type CreditConsentStatus = {
  leadId: Scalars['UUID'];
  consentAlreadyGiven: Scalars['Boolean'];
  companyDetails: BasicCompanyDetails;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreditQuery = {
  transactionKey?: Maybe<Scalars['String']>;
  interactiveQuery?: Maybe<InteractiveQuery>;
  assessmentComplete?: Maybe<AssessmentComplete>;
  fieldErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
  equifaxServerError?: Maybe<Scalars['Boolean']>;
  equifaxErrorMessages?: Maybe<Array<Maybe<Scalars['String']>>>;
  fraudCheckFailed?: Maybe<Scalars['Boolean']>;
  consumer: Scalars['ID'];
  report?: Maybe<CreditReport>;
  lenderOffers?: Maybe<LenderOffers>;
  lenderApp?: Maybe<LenderAppResult>;
};

export type CreditQueryInput = {
  transactionKey?: Maybe<Scalars['String']>;
  queryId?: Maybe<Scalars['String']>;
  answers: Array<CreditAnswerInput>;
  consumer: Scalars['ID'];
};

export type CreditQuestion = {
  questionId: Scalars['String'];
  questionText: Scalars['String'];
  answers?: Maybe<Array<CreditAnswer>>;
};

export type CreditReport = {
  dateOpened?: Maybe<Scalars['String']>;
  dateLastActivity?: Maybe<Scalars['String']>;
  dateRequested?: Maybe<Scalars['String']>;
  score?: Maybe<Score>;
  trades: Array<Trade>;
  tradeLines: TradeLines;
  collections?: Maybe<Array<Collection>>;
  bankruptcies?: Maybe<Array<Bankruptcy>>;
  securedLoans?: Maybe<Array<SecuredLoan>>;
  inquiries: Array<Inquiry>;
  expired?: Maybe<Scalars['Boolean']>;
};

export type Cta = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['String'];
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree: Scalars['JSON'];
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec: Scalars['JSON'];
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef: Scalars['JSON'];
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec: Scalars['JSON'];
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef: Scalars['JSON'];
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyCtas: CompanyCtasConnection;
  /** Reads and enables pagination through a set of `CtaWidget`. */
  ctaWidgets: CtaWidgetsConnection;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyOverride: CompanyCtasConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCompanyCtaCtaIdAndGroupId: CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Widget`. */
  widgetsByCtaWidgetCtaIdAndWidgetId: CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyConnection;
};


export type CtaCompanyCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
  condition?: Maybe<CompanyCtaCondition>;
  filter?: Maybe<CompanyCtaFilter>;
};


export type CtaCtaWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
  condition?: Maybe<CtaWidgetCondition>;
  filter?: Maybe<CtaWidgetFilter>;
};


export type CtaCompanyOverrideArgs = {
  inputGroup?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CompanyCtaFilter>;
};


export type CtaGroupsByCompanyCtaCtaIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


export type CtaWidgetsByCtaWidgetCtaIdAndWidgetIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
  condition?: Maybe<WidgetCondition>;
  filter?: Maybe<WidgetFilter>;
};

export type CtaAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CtaSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CtaDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CtaMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CtaMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CtaAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CtaStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CtaStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CtaVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CtaVariancePopulationAggregates>;
};

export type CtaAnalyticsFilter = {
  hour?: Maybe<DatetimeFilter>;
};

export type CtaAnalyticsResponse = {
  nodes: Array<CtaAnalyticsSummary>;
};

export type CtaAnalyticsSummary = {
  id?: Maybe<Scalars['String']>;
  ctaId?: Maybe<Scalars['String']>;
  ctaVersion?: Maybe<Scalars['String']>;
  ctaOverride?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['Int']>;
  companyGroup?: Maybe<Scalars['Int']>;
  companyName?: Maybe<Scalars['String']>;
  companyProducts: Array<ProductType>;
  product?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  ctaImpression?: Maybe<Scalars['Int']>;
  ctaLoaded?: Maybe<Scalars['Int']>;
  widgetOpened?: Maybe<Scalars['Int']>;
  widgetClosed?: Maybe<Scalars['Int']>;
  userEngagement?: Maybe<Scalars['Int']>;
  partialLead?: Maybe<Scalars['Int']>;
  creditLead?: Maybe<Scalars['Int']>;
  creditVerifiedLead?: Maybe<Scalars['Int']>;
  tradeLead?: Maybe<Scalars['Int']>;
};

export type CtaAsset = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  bucket: Scalars['String'];
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Group` that is related to this `CtaAsset`. */
  group?: Maybe<Group>;
  href: Scalars['String'];
};

export type CtaAssetAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CtaAssetSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CtaAssetDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CtaAssetMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CtaAssetMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CtaAssetAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CtaAssetStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CtaAssetStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CtaAssetVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CtaAssetVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CtaAsset` object types. */
export type CtaAssetAggregatesFilter = {
  /** A filter that must pass for the relevant `CtaAsset` object to be included within the aggregate. */
  filter?: Maybe<CtaAssetFilter>;
  /** Sum aggregate over matching `CtaAsset` objects. */
  sum?: Maybe<CtaAssetSumAggregateFilter>;
  /** Distinct count aggregate over matching `CtaAsset` objects. */
  distinctCount?: Maybe<CtaAssetDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CtaAsset` objects. */
  min?: Maybe<CtaAssetMinAggregateFilter>;
  /** Maximum aggregate over matching `CtaAsset` objects. */
  max?: Maybe<CtaAssetMaxAggregateFilter>;
  /** Mean average aggregate over matching `CtaAsset` objects. */
  average?: Maybe<CtaAssetAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CtaAsset` objects. */
  stddevSample?: Maybe<CtaAssetStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CtaAsset` objects. */
  stddevPopulation?: Maybe<CtaAssetStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CtaAsset` objects. */
  varianceSample?: Maybe<CtaAssetVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CtaAsset` objects. */
  variancePopulation?: Maybe<CtaAssetVariancePopulationAggregateFilter>;
};

export type CtaAssetAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CtaAssetAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CtaAsset` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CtaAssetCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bucket` field. */
  bucket?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `key` field. */
  key?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email` field. */
  email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ipAddress` field. */
  ipAddress?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** The fields on `ctaAsset` to look up the row to connect. */
export type CtaAssetCtaAssetsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `ctaAsset` to look up the row to connect. */
export type CtaAssetCtaAssetsUqGroupIdNameConnect = {
  groupId: Scalars['Int'];
  name: Scalars['String'];
};

export type CtaAssetDetails = {
  id: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  bucket: Scalars['String'];
  key: Scalars['String'];
  href: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type CtaAssetDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  bucket?: Maybe<BigIntFilter>;
  key?: Maybe<BigIntFilter>;
  description?: Maybe<BigIntFilter>;
  email?: Maybe<BigIntFilter>;
  ipAddress?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CtaAssetDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of bucket across the matching connection */
  bucket?: Maybe<Scalars['BigInt']>;
  /** Distinct count of key across the matching connection */
  key?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ipAddress across the matching connection */
  ipAddress?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CtaAsset` object types. All fields are combined with a logical ‘and.’ */
export type CtaAssetFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `bucket` field. */
  bucket?: Maybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: Maybe<StringFilter>;
  /** Filter by the object’s `ipAddress` field. */
  ipAddress?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<GroupFilter>;
  /** A related `group` exists. */
  groupExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CtaAssetFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CtaAssetFilter>>;
  /** Negates the expression. */
  not?: Maybe<CtaAssetFilter>;
};

export type CtaAssetMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
};

export type CtaAssetMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

export type CtaAssetMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
};

export type CtaAssetMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CtaAssetNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `ctaAsset` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `ctaAsset` to look up the row to update. */
export type CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyUsingCtaAssetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `ctaAsset` being updated. */
  patch: UpdateCtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `ctaAsset` to look up the row to update. */
export type CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyUsingCtaAssetsUqGroupIdNameUpdate = {
  /** An object where the defined keys will be set on the `ctaAsset` being updated. */
  patch: UpdateCtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyPatch;
  groupId: Scalars['Int'];
  name: Scalars['String'];
};

/** Represents an update to a `CtaAsset`. Fields that are set will be updated. */
export type CtaAssetPatch = {
  id?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<CtaAssetsGroupIdFkeyInput>;
};

export type CtaAssetStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CtaAssetStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CtaAssetStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CtaAssetStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CtaAssetSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
};

export type CtaAssetSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt'];
};

export type CtaAssetVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CtaAssetVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CtaAssetVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CtaAssetVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CtaAsset` values. */
export type CtaAssetsConnection = {
  /** A list of `CtaAsset` objects. */
  nodes: Array<CtaAsset>;
  /** A list of edges which contains the `CtaAsset` and cursor to aid in pagination. */
  edges: Array<CtaAssetsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CtaAsset` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CtaAssetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CtaAssetAggregates>>;
};


/** A connection to a list of `CtaAsset` values. */
export type CtaAssetsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CtaAssetsGroupBy>;
  having?: Maybe<CtaAssetsHavingInput>;
};

/** A `CtaAsset` edge in the connection. */
export type CtaAssetsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CtaAsset` at the end of the edge. */
  node: CtaAsset;
};

/** Grouping methods for `CtaAsset` for usage during aggregation. */
export enum CtaAssetsGroupBy {
  GroupId = 'GROUP_ID',
  Name = 'NAME',
  Bucket = 'BUCKET',
  Key = 'KEY',
  Description = 'DESCRIPTION',
  Email = 'EMAIL',
  IpAddress = 'IP_ADDRESS',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

/** The `group` to be created by this mutation. */
export type CtaAssetsGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `CtaAssetInput` mutation. */
export type CtaAssetsGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCtaAssetForCtaAssetsGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnCtaAssetForCtaAssetsGroupIdFkeyUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CtaAssetsGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `ctaAsset` in the `GroupInput` mutation. */
export type CtaAssetsGroupIdFkeyInverseInput = {
  /** The primary key(s) for `ctaAsset` for the far side of the relationship. */
  connectById?: Maybe<Array<CtaAssetCtaAssetsPkeyConnect>>;
  /** The primary key(s) for `ctaAsset` for the far side of the relationship. */
  connectByGroupIdAndName?: Maybe<Array<CtaAssetCtaAssetsUqGroupIdNameConnect>>;
  /** The primary key(s) for `ctaAsset` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CtaAssetNodeIdConnect>>;
  /** The primary key(s) and patch data for `ctaAsset` for the far side of the relationship. */
  updateById?: Maybe<Array<CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyUsingCtaAssetsPkeyUpdate>>;
  /** The primary key(s) and patch data for `ctaAsset` for the far side of the relationship. */
  updateByGroupIdAndName?: Maybe<Array<CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyUsingCtaAssetsUqGroupIdNameUpdate>>;
  /** The primary key(s) and patch data for `ctaAsset` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnCtaAssetForCtaAssetsGroupIdFkeyNodeIdUpdate>>;
};

export type CtaAssetsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CtaAsset` aggregates. */
export type CtaAssetsHavingInput = {
  AND?: Maybe<Array<CtaAssetsHavingInput>>;
  OR?: Maybe<Array<CtaAssetsHavingInput>>;
  sum?: Maybe<CtaAssetsHavingSumInput>;
  distinctCount?: Maybe<CtaAssetsHavingDistinctCountInput>;
  min?: Maybe<CtaAssetsHavingMinInput>;
  max?: Maybe<CtaAssetsHavingMaxInput>;
  average?: Maybe<CtaAssetsHavingAverageInput>;
  stddevSample?: Maybe<CtaAssetsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CtaAssetsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CtaAssetsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CtaAssetsHavingVariancePopulationInput>;
};

export type CtaAssetsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CtaAsset`. */
export enum CtaAssetsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  BucketAsc = 'BUCKET_ASC',
  BucketDesc = 'BUCKET_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  IpAddressAsc = 'IP_ADDRESS_ASC',
  IpAddressDesc = 'IP_ADDRESS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CtaAverageAggregates = {
  /** Mean average of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CtaCatalogNode = {
  overrides: Array<CtaCatalogOverride>;
  themes: Array<Scalars['String']>;
  id: Scalars['String'];
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

export type CtaCatalogOverride = {
  id: Scalars['Int'];
  ctaId: Scalars['String'];
  groupId: Scalars['Int'];
  /** User friendly name of this override. */
  name: Scalars['String'];
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
};

export type CtaCatalogResults = {
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
  catalog: Array<CtaCatalogNode>;
};

/** A condition to be used against `Cta` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CtaCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `product` field. */
  product?: Maybe<ProductType>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<CtaType>;
  /** Checks for equality with the object’s `version` field. */
  version?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `published` field. */
  published?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `ctaStyles` field. */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ctaStylesStatic` field. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ctaTree` field. */
  ctaTree?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `templateSpec` field. */
  templateSpec?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `templateDef` field. */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `themeSpec` field. */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `themeDef` field. */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `pluginDef` field. */
  pluginDef?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `compatibleMajor` field. */
  compatibleMajor?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invalidate` field. */
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `themes` field. */
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** The fields on `cta` to look up the row to connect. */
export type CtaCtasPkeyConnect = {
  id: Scalars['String'];
};

/** The fields on `cta` to look up the row to delete. */
export type CtaCtasPkeyDelete = {
  id: Scalars['String'];
};

/** The fields on `cta` to look up the row to connect. */
export type CtaCtasProductTypeVersionKeyConnect = {
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

/** The fields on `cta` to look up the row to delete. */
export type CtaCtasProductTypeVersionKeyDelete = {
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

export type CtaDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of product across the matching connection */
  product?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of version across the matching connection */
  version?: Maybe<Scalars['BigInt']>;
  /** Distinct count of published across the matching connection */
  published?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaStyles across the matching connection */
  ctaStyles?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaStylesStatic across the matching connection */
  ctaStylesStatic?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaTree across the matching connection */
  ctaTree?: Maybe<Scalars['BigInt']>;
  /** Distinct count of templateSpec across the matching connection */
  templateSpec?: Maybe<Scalars['BigInt']>;
  /** Distinct count of templateDef across the matching connection */
  templateDef?: Maybe<Scalars['BigInt']>;
  /** Distinct count of themeSpec across the matching connection */
  themeSpec?: Maybe<Scalars['BigInt']>;
  /** Distinct count of themeDef across the matching connection */
  themeDef?: Maybe<Scalars['BigInt']>;
  /** Distinct count of pluginDef across the matching connection */
  pluginDef?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of invalidate across the matching connection */
  invalidate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of themes across the matching connection */
  themes?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Cta` object types. All fields are combined with a logical ‘and.’ */
export type CtaFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<StringFilter>;
  /** Filter by the object’s `product` field. */
  product?: Maybe<ProductTypeFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<CtaTypeFilter>;
  /** Filter by the object’s `version` field. */
  version?: Maybe<StringFilter>;
  /** Filter by the object’s `published` field. */
  published?: Maybe<BooleanFilter>;
  /** Filter by the object’s `ctaStyles` field. */
  ctaStyles?: Maybe<StringFilter>;
  /** Filter by the object’s `ctaStylesStatic` field. */
  ctaStylesStatic?: Maybe<StringFilter>;
  /** Filter by the object’s `templateSpec` field. */
  templateSpec?: Maybe<JsonFilter>;
  /** Filter by the object’s `templateDef` field. */
  templateDef?: Maybe<JsonFilter>;
  /** Filter by the object’s `themeSpec` field. */
  themeSpec?: Maybe<JsonFilter>;
  /** Filter by the object’s `themeDef` field. */
  themeDef?: Maybe<JsonFilter>;
  /** Filter by the object’s `pluginDef` field. */
  pluginDef?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `compatibleMajor` field. */
  compatibleMajor?: Maybe<IntFilter>;
  /** Filter by the object’s `invalidate` field. */
  invalidate?: Maybe<StringListFilter>;
  /** Filter by the object’s `themes` field. */
  themes?: Maybe<StringListFilter>;
  /** Filter by the object’s `companyCtas` relation. */
  companyCtas?: Maybe<CtaToManyCompanyCtaFilter>;
  /** Some related `companyCtas` exist. */
  companyCtasExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `ctaWidgets` relation. */
  ctaWidgets?: Maybe<CtaToManyCtaWidgetFilter>;
  /** Some related `ctaWidgets` exist. */
  ctaWidgetsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CtaFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CtaFilter>>;
  /** Negates the expression. */
  not?: Maybe<CtaFilter>;
};

/** A connection to a list of `Group` values, with data from `CompanyCta`. */
export type CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyConnection = {
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `CompanyCta`, and the cursor to aid in pagination. */
  edges: Array<CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GroupAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GroupAggregates>>;
};


/** A connection to a list of `Group` values, with data from `CompanyCta`. */
export type CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<GroupGroupBy>;
  having?: Maybe<GroupHavingInput>;
};

/** A `Group` edge in the connection, with data from `CompanyCta`. */
export type CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyCtas: CompanyCtasConnection;
};


/** A `Group` edge in the connection, with data from `CompanyCta`. */
export type CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyEdgeCompanyCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
  condition?: Maybe<CompanyCtaCondition>;
  filter?: Maybe<CompanyCtaFilter>;
};

/** An input for mutations affecting `Cta` */
export type CtaInput = {
  id?: Maybe<Scalars['String']>;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree: Scalars['JSON'];
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec: Scalars['JSON'];
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef: Scalars['JSON'];
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec: Scalars['JSON'];
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef: Scalars['JSON'];
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

export type CtaMaxAggregates = {
  /** Maximum of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['Int']>;
};

export type CtaMinAggregates = {
  /** Minimum of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CtaNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `cta` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CtaNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `cta` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CtaOnCompanyCtaForCompanyCtasCtaIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyCta` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyCta` being updated. */
  patch: CompanyCtaPatch;
};

/** The fields on `cta` to look up the row to update. */
export type CtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCtasPkeyUpdate = {
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: UpdateCtaOnCompanyCtaForCompanyCtasCtaIdFkeyPatch;
  id: Scalars['String'];
};

/** The fields on `cta` to look up the row to update. */
export type CtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCtasProductTypeVersionKeyUpdate = {
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: UpdateCtaOnCompanyCtaForCompanyCtasCtaIdFkeyPatch;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type CtaOnCtaWidgetForCtaWidgetsCtaIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `ctaWidget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ctaWidget` being updated. */
  patch: CtaWidgetPatch;
};

/** The fields on `cta` to look up the row to update. */
export type CtaOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtasPkeyUpdate = {
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: UpdateCtaOnCtaWidgetForCtaWidgetsCtaIdFkPatch;
  id: Scalars['String'];
};

/** The fields on `cta` to look up the row to update. */
export type CtaOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtasProductTypeVersionKeyUpdate = {
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: UpdateCtaOnCtaWidgetForCtaWidgetsCtaIdFkPatch;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

/** Represents an update to a `Cta`. Fields that are set will be updated. */
export type CtaPatch = {
  id?: Maybe<Scalars['String']>;
  product?: Maybe<ProductType>;
  type?: Maybe<CtaType>;
  version?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

export type CtaSpecs = {
  id: Scalars['String'];
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  ctaStyles?: Maybe<Scalars['String']>;
  ctaStylesStatic?: Maybe<Scalars['String']>;
  ctaTree: Scalars['JSON'];
  templateSpec: Scalars['JSON'];
  templateDef: Scalars['JSON'];
  themeSpec: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  pluginDef: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export enum CtaSpecsErrors {
  ProductNotEnabled = 'ProductNotEnabled'
}

export type CtaSpecsInput = {
  widgetId: Scalars['String'];
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  theme?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['Boolean']>;
};

export type CtaSpecsResponse = {
  cta?: Maybe<CtaSpecs>;
  overrides?: Maybe<CompanyCtaOverrides>;
  ctaAssets?: Maybe<Array<CtaAssetDetails>>;
  error?: Maybe<CtaSpecsResponseError>;
};

export type CtaSpecsResponseError = {
  message: Scalars['String'];
  code?: Maybe<Scalars['Int']>;
  type: CtaSpecsErrors;
};

export type CtaStddevPopulationAggregates = {
  /** Population standard deviation of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CtaStddevSampleAggregates = {
  /** Sample standard deviation of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CtaSumAggregates = {
  /** Sum of compatibleMajor across the matching connection */
  compatibleMajor: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyCta` object types. All fields are combined with a logical ‘and.’ */
export type CtaToManyCompanyCtaFilter = {
  /** Every related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyCtaFilter>;
  /** Some related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyCtaFilter>;
  /** No related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyCtaFilter>;
  /** Aggregates across related `CompanyCta` match the filter criteria. */
  aggregates?: Maybe<CompanyCtaAggregatesFilter>;
};

/** A filter to be used against many `CtaWidget` object types. All fields are combined with a logical ‘and.’ */
export type CtaToManyCtaWidgetFilter = {
  /** Every related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CtaWidgetFilter>;
  /** Some related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CtaWidgetFilter>;
  /** No related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CtaWidgetFilter>;
  /** Aggregates across related `CtaWidget` match the filter criteria. */
  aggregates?: Maybe<CtaWidgetAggregatesFilter>;
};

export enum CtaType {
  Floating = 'FLOATING',
  Banner = 'BANNER',
  Inline = 'INLINE',
  Button = 'BUTTON',
  Hero = 'HERO',
  BannerHero = 'BANNER_HERO',
  OemBanner = 'OEM_BANNER',
  OemInline = 'OEM_INLINE',
  InlineHero = 'INLINE_HERO'
}

/** A filter to be used against CtaType fields. All fields are combined with a logical ‘and.’ */
export type CtaTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<CtaType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<CtaType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<CtaType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<CtaType>;
  /** Included in the specified list. */
  in?: Maybe<Array<CtaType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<CtaType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<CtaType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<CtaType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<CtaType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<CtaType>;
};

export type CtaVariancePopulationAggregates = {
  /** Population variance of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CtaVarianceSampleAggregates = {
  /** Sample variance of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CtaWidget = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Cta` that is related to this `CtaWidget`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Widget` that is related to this `CtaWidget`. */
  widget?: Maybe<Widget>;
};

export type CtaWidgetAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CtaWidgetSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CtaWidgetDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CtaWidgetMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CtaWidgetMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CtaWidgetAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CtaWidgetStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CtaWidgetStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CtaWidgetVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CtaWidgetVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CtaWidget` object types. */
export type CtaWidgetAggregatesFilter = {
  /** A filter that must pass for the relevant `CtaWidget` object to be included within the aggregate. */
  filter?: Maybe<CtaWidgetFilter>;
  /** Sum aggregate over matching `CtaWidget` objects. */
  sum?: Maybe<CtaWidgetSumAggregateFilter>;
  /** Distinct count aggregate over matching `CtaWidget` objects. */
  distinctCount?: Maybe<CtaWidgetDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CtaWidget` objects. */
  min?: Maybe<CtaWidgetMinAggregateFilter>;
  /** Maximum aggregate over matching `CtaWidget` objects. */
  max?: Maybe<CtaWidgetMaxAggregateFilter>;
  /** Mean average aggregate over matching `CtaWidget` objects. */
  average?: Maybe<CtaWidgetAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CtaWidget` objects. */
  stddevSample?: Maybe<CtaWidgetStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CtaWidget` objects. */
  stddevPopulation?: Maybe<CtaWidgetStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CtaWidget` objects. */
  varianceSample?: Maybe<CtaWidgetVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CtaWidget` objects. */
  variancePopulation?: Maybe<CtaWidgetVariancePopulationAggregateFilter>;
};

export type CtaWidgetAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type CtaWidgetAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CtaWidget` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CtaWidgetCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `ctaId` field. */
  ctaId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `staticId` field. */
  staticId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `widgetId` field. */
  widgetId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** The fields on `ctaWidget` to look up the row to connect. */
export type CtaWidgetCtaWidgetsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `ctaWidget` to look up the row to delete. */
export type CtaWidgetCtaWidgetsPkeyDelete = {
  id: Scalars['Int'];
};

export type CtaWidgetDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  ctaId?: Maybe<BigIntFilter>;
  staticId?: Maybe<BigIntFilter>;
  widgetId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CtaWidgetDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaId across the matching connection */
  ctaId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of staticId across the matching connection */
  staticId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetId across the matching connection */
  widgetId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CtaWidget` object types. All fields are combined with a logical ‘and.’ */
export type CtaWidgetFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `ctaId` field. */
  ctaId?: Maybe<StringFilter>;
  /** Filter by the object’s `staticId` field. */
  staticId?: Maybe<StringFilter>;
  /** Filter by the object’s `widgetId` field. */
  widgetId?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `cta` relation. */
  cta?: Maybe<CtaFilter>;
  /** A related `cta` exists. */
  ctaExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `widget` relation. */
  widget?: Maybe<WidgetFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CtaWidgetFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CtaWidgetFilter>>;
  /** Negates the expression. */
  not?: Maybe<CtaWidgetFilter>;
};

/** An input for mutations affecting `CtaWidget` */
export type CtaWidgetInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

export type CtaWidgetMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
};

export type CtaWidgetMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type CtaWidgetMinAggregateFilter = {
  id?: Maybe<IntFilter>;
};

export type CtaWidgetMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CtaWidgetNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `ctaWidget` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CtaWidgetNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `ctaWidget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `cta` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: CtaPatch;
};

/** The fields on `ctaWidget` to look up the row to update. */
export type CtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtaWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `ctaWidget` being updated. */
  patch: UpdateCtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `widget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: WidgetPatch;
};

/** The fields on `ctaWidget` to look up the row to update. */
export type CtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingCtaWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `ctaWidget` being updated. */
  patch: UpdateCtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CtaWidget`. Fields that are set will be updated. */
export type CtaWidgetPatch = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

export type CtaWidgetStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type CtaWidgetStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CtaWidgetStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type CtaWidgetStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CtaWidgetSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
};

export type CtaWidgetSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type CtaWidgetVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type CtaWidgetVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CtaWidgetVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type CtaWidgetVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Widget` values, with data from `CtaWidget`. */
export type CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyConnection = {
  /** A list of `Widget` objects. */
  nodes: Array<Widget>;
  /** A list of edges which contains the `Widget`, info from the `CtaWidget`, and the cursor to aid in pagination. */
  edges: Array<CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Widget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WidgetAggregates>>;
};


/** A connection to a list of `Widget` values, with data from `CtaWidget`. */
export type CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<WidgetsGroupBy>;
  having?: Maybe<WidgetsHavingInput>;
};

/** A `Widget` edge in the connection, with data from `CtaWidget`. */
export type CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Widget` at the end of the edge. */
  node: Widget;
  /** Reads and enables pagination through a set of `CtaWidget`. */
  ctaWidgets: CtaWidgetsConnection;
};


/** A `Widget` edge in the connection, with data from `CtaWidget`. */
export type CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyEdgeCtaWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
  condition?: Maybe<CtaWidgetCondition>;
  filter?: Maybe<CtaWidgetFilter>;
};

/** A connection to a list of `CtaWidget` values. */
export type CtaWidgetsConnection = {
  /** A list of `CtaWidget` objects. */
  nodes: Array<CtaWidget>;
  /** A list of edges which contains the `CtaWidget` and cursor to aid in pagination. */
  edges: Array<CtaWidgetsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CtaWidget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CtaWidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CtaWidgetAggregates>>;
};


/** A connection to a list of `CtaWidget` values. */
export type CtaWidgetsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CtaWidgetsGroupBy>;
  having?: Maybe<CtaWidgetsHavingInput>;
};

/** The `ctaWidget` to be created by this mutation. */
export type CtaWidgetsCtaIdFkCtaWidgetsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

/** The `cta` to be created by this mutation. */
export type CtaWidgetsCtaIdFkCtasCreateInput = {
  id?: Maybe<Scalars['String']>;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree: Scalars['JSON'];
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec: Scalars['JSON'];
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef: Scalars['JSON'];
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec: Scalars['JSON'];
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef: Scalars['JSON'];
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

/** Input for the nested mutation of `cta` in the `CtaWidgetInput` mutation. */
export type CtaWidgetsCtaIdFkInput = {
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectById?: Maybe<CtaCtasPkeyConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectByProductAndTypeAndVersion?: Maybe<CtaCtasProductTypeVersionKeyConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectByNodeId?: Maybe<CtaNodeIdConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteById?: Maybe<CtaCtasPkeyDelete>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteByProductAndTypeAndVersion?: Maybe<CtaCtasProductTypeVersionKeyDelete>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CtaNodeIdDelete>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateById?: Maybe<CtaOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtasPkeyUpdate>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateByProductAndTypeAndVersion?: Maybe<CtaOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtasProductTypeVersionKeyUpdate>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateByNodeId?: Maybe<CtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkNodeIdUpdate>;
  /** A `CtaInput` object that will be created and connected to this object. */
  create?: Maybe<CtaWidgetsCtaIdFkCtasCreateInput>;
};

/** Input for the nested mutation of `ctaWidget` in the `CtaInput` mutation. */
export type CtaWidgetsCtaIdFkInverseInput = {
  /** Flag indicating whether all other `ctaWidget` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  connectById?: Maybe<Array<CtaWidgetCtaWidgetsPkeyConnect>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CtaWidgetNodeIdConnect>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  deleteById?: Maybe<Array<CtaWidgetCtaWidgetsPkeyDelete>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CtaWidgetNodeIdDelete>>;
  /** The primary key(s) and patch data for `ctaWidget` for the far side of the relationship. */
  updateById?: Maybe<Array<CtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtaWidgetsPkeyUpdate>>;
  /** The primary key(s) and patch data for `ctaWidget` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CtaOnCtaWidgetForCtaWidgetsCtaIdFkNodeIdUpdate>>;
  /** A `CtaWidgetInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CtaWidgetsCtaIdFkCtaWidgetsCreateInput>>;
};

/** A `CtaWidget` edge in the connection. */
export type CtaWidgetsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CtaWidget` at the end of the edge. */
  node: CtaWidget;
};

/** Grouping methods for `CtaWidget` for usage during aggregation. */
export enum CtaWidgetsGroupBy {
  CtaId = 'CTA_ID',
  StaticId = 'STATIC_ID',
  WidgetId = 'WIDGET_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CtaWidgetsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CtaWidget` aggregates. */
export type CtaWidgetsHavingInput = {
  AND?: Maybe<Array<CtaWidgetsHavingInput>>;
  OR?: Maybe<Array<CtaWidgetsHavingInput>>;
  sum?: Maybe<CtaWidgetsHavingSumInput>;
  distinctCount?: Maybe<CtaWidgetsHavingDistinctCountInput>;
  min?: Maybe<CtaWidgetsHavingMinInput>;
  max?: Maybe<CtaWidgetsHavingMaxInput>;
  average?: Maybe<CtaWidgetsHavingAverageInput>;
  stddevSample?: Maybe<CtaWidgetsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CtaWidgetsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CtaWidgetsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CtaWidgetsHavingVariancePopulationInput>;
};

export type CtaWidgetsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CtaWidget`. */
export enum CtaWidgetsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CtaIdAsc = 'CTA_ID_ASC',
  CtaIdDesc = 'CTA_ID_DESC',
  StaticIdAsc = 'STATIC_ID_ASC',
  StaticIdDesc = 'STATIC_ID_DESC',
  WidgetIdAsc = 'WIDGET_ID_ASC',
  WidgetIdDesc = 'WIDGET_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `ctaWidget` to be created by this mutation. */
export type CtaWidgetsWidgetIdFkCtaWidgetsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

/** Input for the nested mutation of `widget` in the `CtaWidgetInput` mutation. */
export type CtaWidgetsWidgetIdFkInput = {
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectById?: Maybe<WidgetWidgetsPkeyConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByVariant?: Maybe<WidgetWidgetsUqVariantConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByNodeId?: Maybe<WidgetNodeIdConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteById?: Maybe<WidgetWidgetsPkeyDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByVariant?: Maybe<WidgetWidgetsUqVariantDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<WidgetNodeIdDelete>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateById?: Maybe<WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingWidgetsPkeyUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByVariant?: Maybe<WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingWidgetsUqVariantUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByNodeId?: Maybe<CtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkNodeIdUpdate>;
  /** A `WidgetInput` object that will be created and connected to this object. */
  create?: Maybe<CtaWidgetsWidgetIdFkWidgetsCreateInput>;
};

/** Input for the nested mutation of `ctaWidget` in the `WidgetInput` mutation. */
export type CtaWidgetsWidgetIdFkInverseInput = {
  /** Flag indicating whether all other `ctaWidget` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  connectById?: Maybe<Array<CtaWidgetCtaWidgetsPkeyConnect>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CtaWidgetNodeIdConnect>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  deleteById?: Maybe<Array<CtaWidgetCtaWidgetsPkeyDelete>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CtaWidgetNodeIdDelete>>;
  /** The primary key(s) and patch data for `ctaWidget` for the far side of the relationship. */
  updateById?: Maybe<Array<CtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingCtaWidgetsPkeyUpdate>>;
  /** The primary key(s) and patch data for `ctaWidget` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkNodeIdUpdate>>;
  /** A `CtaWidgetInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CtaWidgetsWidgetIdFkCtaWidgetsCreateInput>>;
};

/** The `widget` to be created by this mutation. */
export type CtaWidgetsWidgetIdFkWidgetsCreateInput = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

/** A connection to a list of `Cta` values. */
export type CtasConnection = {
  /** A list of `Cta` objects. */
  nodes: Array<Cta>;
  /** A list of edges which contains the `Cta` and cursor to aid in pagination. */
  edges: Array<CtasEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Cta` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CtaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CtaAggregates>>;
};


/** A connection to a list of `Cta` values. */
export type CtasConnectionGroupedAggregatesArgs = {
  groupBy: Array<CtasGroupBy>;
  having?: Maybe<CtasHavingInput>;
};

/** A `Cta` edge in the connection. */
export type CtasEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Cta` at the end of the edge. */
  node: Cta;
};

/** Grouping methods for `Cta` for usage during aggregation. */
export enum CtasGroupBy {
  Product = 'PRODUCT',
  Type = 'TYPE',
  Version = 'VERSION',
  Published = 'PUBLISHED',
  CtaStyles = 'CTA_STYLES',
  CtaStylesStatic = 'CTA_STYLES_STATIC',
  CtaTree = 'CTA_TREE',
  TemplateSpec = 'TEMPLATE_SPEC',
  TemplateDef = 'TEMPLATE_DEF',
  ThemeSpec = 'THEME_SPEC',
  ThemeDef = 'THEME_DEF',
  PluginDef = 'PLUGIN_DEF',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  CompatibleMajor = 'COMPATIBLE_MAJOR',
  Invalidate = 'INVALIDATE',
  Themes = 'THEMES'
}

export type CtasHavingAverageInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingDistinctCountInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

/** Conditions for `Cta` aggregates. */
export type CtasHavingInput = {
  AND?: Maybe<Array<CtasHavingInput>>;
  OR?: Maybe<Array<CtasHavingInput>>;
  sum?: Maybe<CtasHavingSumInput>;
  distinctCount?: Maybe<CtasHavingDistinctCountInput>;
  min?: Maybe<CtasHavingMinInput>;
  max?: Maybe<CtasHavingMaxInput>;
  average?: Maybe<CtasHavingAverageInput>;
  stddevSample?: Maybe<CtasHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CtasHavingStddevPopulationInput>;
  varianceSample?: Maybe<CtasHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CtasHavingVariancePopulationInput>;
};

export type CtasHavingMaxInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingMinInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingStddevPopulationInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingStddevSampleInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingSumInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingVariancePopulationInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingVarianceSampleInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

/** Methods to use when ordering `Cta`. */
export enum CtasOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProductAsc = 'PRODUCT_ASC',
  ProductDesc = 'PRODUCT_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC',
  PublishedAsc = 'PUBLISHED_ASC',
  PublishedDesc = 'PUBLISHED_DESC',
  CtaStylesAsc = 'CTA_STYLES_ASC',
  CtaStylesDesc = 'CTA_STYLES_DESC',
  CtaStylesStaticAsc = 'CTA_STYLES_STATIC_ASC',
  CtaStylesStaticDesc = 'CTA_STYLES_STATIC_DESC',
  CtaTreeAsc = 'CTA_TREE_ASC',
  CtaTreeDesc = 'CTA_TREE_DESC',
  TemplateSpecAsc = 'TEMPLATE_SPEC_ASC',
  TemplateSpecDesc = 'TEMPLATE_SPEC_DESC',
  TemplateDefAsc = 'TEMPLATE_DEF_ASC',
  TemplateDefDesc = 'TEMPLATE_DEF_DESC',
  ThemeSpecAsc = 'THEME_SPEC_ASC',
  ThemeSpecDesc = 'THEME_SPEC_DESC',
  ThemeDefAsc = 'THEME_DEF_ASC',
  ThemeDefDesc = 'THEME_DEF_DESC',
  PluginDefAsc = 'PLUGIN_DEF_ASC',
  PluginDefDesc = 'PLUGIN_DEF_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  CompatibleMajorAsc = 'COMPATIBLE_MAJOR_ASC',
  CompatibleMajorDesc = 'COMPATIBLE_MAJOR_DESC',
  InvalidateAsc = 'INVALIDATE_ASC',
  InvalidateDesc = 'INVALIDATE_DESC',
  ThemesAsc = 'THEMES_ASC',
  ThemesDesc = 'THEMES_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyCtasCountAsc = 'COMPANY_CTAS_COUNT_ASC',
  CompanyCtasCountDesc = 'COMPANY_CTAS_COUNT_DESC',
  CompanyCtasSumIdAsc = 'COMPANY_CTAS_SUM_ID_ASC',
  CompanyCtasSumIdDesc = 'COMPANY_CTAS_SUM_ID_DESC',
  CompanyCtasSumCtaIdAsc = 'COMPANY_CTAS_SUM_CTA_ID_ASC',
  CompanyCtasSumCtaIdDesc = 'COMPANY_CTAS_SUM_CTA_ID_DESC',
  CompanyCtasSumGroupIdAsc = 'COMPANY_CTAS_SUM_GROUP_ID_ASC',
  CompanyCtasSumGroupIdDesc = 'COMPANY_CTAS_SUM_GROUP_ID_DESC',
  CompanyCtasSumThemeAsc = 'COMPANY_CTAS_SUM_THEME_ASC',
  CompanyCtasSumThemeDesc = 'COMPANY_CTAS_SUM_THEME_DESC',
  CompanyCtasSumNameAsc = 'COMPANY_CTAS_SUM_NAME_ASC',
  CompanyCtasSumNameDesc = 'COMPANY_CTAS_SUM_NAME_DESC',
  CompanyCtasSumLockedAsc = 'COMPANY_CTAS_SUM_LOCKED_ASC',
  CompanyCtasSumLockedDesc = 'COMPANY_CTAS_SUM_LOCKED_DESC',
  CompanyCtasSumDefaultAsc = 'COMPANY_CTAS_SUM_DEFAULT_ASC',
  CompanyCtasSumDefaultDesc = 'COMPANY_CTAS_SUM_DEFAULT_DESC',
  CompanyCtasSumTemplateDefAsc = 'COMPANY_CTAS_SUM_TEMPLATE_DEF_ASC',
  CompanyCtasSumTemplateDefDesc = 'COMPANY_CTAS_SUM_TEMPLATE_DEF_DESC',
  CompanyCtasSumThemeDefAsc = 'COMPANY_CTAS_SUM_THEME_DEF_ASC',
  CompanyCtasSumThemeDefDesc = 'COMPANY_CTAS_SUM_THEME_DEF_DESC',
  CompanyCtasSumCreatedAtAsc = 'COMPANY_CTAS_SUM_CREATED_AT_ASC',
  CompanyCtasSumCreatedAtDesc = 'COMPANY_CTAS_SUM_CREATED_AT_DESC',
  CompanyCtasSumUpdatedAtAsc = 'COMPANY_CTAS_SUM_UPDATED_AT_ASC',
  CompanyCtasSumUpdatedAtDesc = 'COMPANY_CTAS_SUM_UPDATED_AT_DESC',
  CompanyCtasSumCompatibleMajorAsc = 'COMPANY_CTAS_SUM_COMPATIBLE_MAJOR_ASC',
  CompanyCtasSumCompatibleMajorDesc = 'COMPANY_CTAS_SUM_COMPATIBLE_MAJOR_DESC',
  CompanyCtasSumCtaDetailsAsc = 'COMPANY_CTAS_SUM_CTA_DETAILS_ASC',
  CompanyCtasSumCtaDetailsDesc = 'COMPANY_CTAS_SUM_CTA_DETAILS_DESC',
  CompanyCtasDistinctCountIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_ID_ASC',
  CompanyCtasDistinctCountIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_ID_DESC',
  CompanyCtasDistinctCountCtaIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_ID_ASC',
  CompanyCtasDistinctCountCtaIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_ID_DESC',
  CompanyCtasDistinctCountGroupIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyCtasDistinctCountGroupIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyCtasDistinctCountThemeAsc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_ASC',
  CompanyCtasDistinctCountThemeDesc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DESC',
  CompanyCtasDistinctCountNameAsc = 'COMPANY_CTAS_DISTINCT_COUNT_NAME_ASC',
  CompanyCtasDistinctCountNameDesc = 'COMPANY_CTAS_DISTINCT_COUNT_NAME_DESC',
  CompanyCtasDistinctCountLockedAsc = 'COMPANY_CTAS_DISTINCT_COUNT_LOCKED_ASC',
  CompanyCtasDistinctCountLockedDesc = 'COMPANY_CTAS_DISTINCT_COUNT_LOCKED_DESC',
  CompanyCtasDistinctCountDefaultAsc = 'COMPANY_CTAS_DISTINCT_COUNT_DEFAULT_ASC',
  CompanyCtasDistinctCountDefaultDesc = 'COMPANY_CTAS_DISTINCT_COUNT_DEFAULT_DESC',
  CompanyCtasDistinctCountTemplateDefAsc = 'COMPANY_CTAS_DISTINCT_COUNT_TEMPLATE_DEF_ASC',
  CompanyCtasDistinctCountTemplateDefDesc = 'COMPANY_CTAS_DISTINCT_COUNT_TEMPLATE_DEF_DESC',
  CompanyCtasDistinctCountThemeDefAsc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DEF_ASC',
  CompanyCtasDistinctCountThemeDefDesc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DEF_DESC',
  CompanyCtasDistinctCountCreatedAtAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyCtasDistinctCountCreatedAtDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyCtasDistinctCountUpdatedAtAsc = 'COMPANY_CTAS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyCtasDistinctCountUpdatedAtDesc = 'COMPANY_CTAS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyCtasDistinctCountCompatibleMajorAsc = 'COMPANY_CTAS_DISTINCT_COUNT_COMPATIBLE_MAJOR_ASC',
  CompanyCtasDistinctCountCompatibleMajorDesc = 'COMPANY_CTAS_DISTINCT_COUNT_COMPATIBLE_MAJOR_DESC',
  CompanyCtasDistinctCountCtaDetailsAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_DETAILS_ASC',
  CompanyCtasDistinctCountCtaDetailsDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_DETAILS_DESC',
  CompanyCtasMinIdAsc = 'COMPANY_CTAS_MIN_ID_ASC',
  CompanyCtasMinIdDesc = 'COMPANY_CTAS_MIN_ID_DESC',
  CompanyCtasMinCtaIdAsc = 'COMPANY_CTAS_MIN_CTA_ID_ASC',
  CompanyCtasMinCtaIdDesc = 'COMPANY_CTAS_MIN_CTA_ID_DESC',
  CompanyCtasMinGroupIdAsc = 'COMPANY_CTAS_MIN_GROUP_ID_ASC',
  CompanyCtasMinGroupIdDesc = 'COMPANY_CTAS_MIN_GROUP_ID_DESC',
  CompanyCtasMinThemeAsc = 'COMPANY_CTAS_MIN_THEME_ASC',
  CompanyCtasMinThemeDesc = 'COMPANY_CTAS_MIN_THEME_DESC',
  CompanyCtasMinNameAsc = 'COMPANY_CTAS_MIN_NAME_ASC',
  CompanyCtasMinNameDesc = 'COMPANY_CTAS_MIN_NAME_DESC',
  CompanyCtasMinLockedAsc = 'COMPANY_CTAS_MIN_LOCKED_ASC',
  CompanyCtasMinLockedDesc = 'COMPANY_CTAS_MIN_LOCKED_DESC',
  CompanyCtasMinDefaultAsc = 'COMPANY_CTAS_MIN_DEFAULT_ASC',
  CompanyCtasMinDefaultDesc = 'COMPANY_CTAS_MIN_DEFAULT_DESC',
  CompanyCtasMinTemplateDefAsc = 'COMPANY_CTAS_MIN_TEMPLATE_DEF_ASC',
  CompanyCtasMinTemplateDefDesc = 'COMPANY_CTAS_MIN_TEMPLATE_DEF_DESC',
  CompanyCtasMinThemeDefAsc = 'COMPANY_CTAS_MIN_THEME_DEF_ASC',
  CompanyCtasMinThemeDefDesc = 'COMPANY_CTAS_MIN_THEME_DEF_DESC',
  CompanyCtasMinCreatedAtAsc = 'COMPANY_CTAS_MIN_CREATED_AT_ASC',
  CompanyCtasMinCreatedAtDesc = 'COMPANY_CTAS_MIN_CREATED_AT_DESC',
  CompanyCtasMinUpdatedAtAsc = 'COMPANY_CTAS_MIN_UPDATED_AT_ASC',
  CompanyCtasMinUpdatedAtDesc = 'COMPANY_CTAS_MIN_UPDATED_AT_DESC',
  CompanyCtasMinCompatibleMajorAsc = 'COMPANY_CTAS_MIN_COMPATIBLE_MAJOR_ASC',
  CompanyCtasMinCompatibleMajorDesc = 'COMPANY_CTAS_MIN_COMPATIBLE_MAJOR_DESC',
  CompanyCtasMinCtaDetailsAsc = 'COMPANY_CTAS_MIN_CTA_DETAILS_ASC',
  CompanyCtasMinCtaDetailsDesc = 'COMPANY_CTAS_MIN_CTA_DETAILS_DESC',
  CompanyCtasMaxIdAsc = 'COMPANY_CTAS_MAX_ID_ASC',
  CompanyCtasMaxIdDesc = 'COMPANY_CTAS_MAX_ID_DESC',
  CompanyCtasMaxCtaIdAsc = 'COMPANY_CTAS_MAX_CTA_ID_ASC',
  CompanyCtasMaxCtaIdDesc = 'COMPANY_CTAS_MAX_CTA_ID_DESC',
  CompanyCtasMaxGroupIdAsc = 'COMPANY_CTAS_MAX_GROUP_ID_ASC',
  CompanyCtasMaxGroupIdDesc = 'COMPANY_CTAS_MAX_GROUP_ID_DESC',
  CompanyCtasMaxThemeAsc = 'COMPANY_CTAS_MAX_THEME_ASC',
  CompanyCtasMaxThemeDesc = 'COMPANY_CTAS_MAX_THEME_DESC',
  CompanyCtasMaxNameAsc = 'COMPANY_CTAS_MAX_NAME_ASC',
  CompanyCtasMaxNameDesc = 'COMPANY_CTAS_MAX_NAME_DESC',
  CompanyCtasMaxLockedAsc = 'COMPANY_CTAS_MAX_LOCKED_ASC',
  CompanyCtasMaxLockedDesc = 'COMPANY_CTAS_MAX_LOCKED_DESC',
  CompanyCtasMaxDefaultAsc = 'COMPANY_CTAS_MAX_DEFAULT_ASC',
  CompanyCtasMaxDefaultDesc = 'COMPANY_CTAS_MAX_DEFAULT_DESC',
  CompanyCtasMaxTemplateDefAsc = 'COMPANY_CTAS_MAX_TEMPLATE_DEF_ASC',
  CompanyCtasMaxTemplateDefDesc = 'COMPANY_CTAS_MAX_TEMPLATE_DEF_DESC',
  CompanyCtasMaxThemeDefAsc = 'COMPANY_CTAS_MAX_THEME_DEF_ASC',
  CompanyCtasMaxThemeDefDesc = 'COMPANY_CTAS_MAX_THEME_DEF_DESC',
  CompanyCtasMaxCreatedAtAsc = 'COMPANY_CTAS_MAX_CREATED_AT_ASC',
  CompanyCtasMaxCreatedAtDesc = 'COMPANY_CTAS_MAX_CREATED_AT_DESC',
  CompanyCtasMaxUpdatedAtAsc = 'COMPANY_CTAS_MAX_UPDATED_AT_ASC',
  CompanyCtasMaxUpdatedAtDesc = 'COMPANY_CTAS_MAX_UPDATED_AT_DESC',
  CompanyCtasMaxCompatibleMajorAsc = 'COMPANY_CTAS_MAX_COMPATIBLE_MAJOR_ASC',
  CompanyCtasMaxCompatibleMajorDesc = 'COMPANY_CTAS_MAX_COMPATIBLE_MAJOR_DESC',
  CompanyCtasMaxCtaDetailsAsc = 'COMPANY_CTAS_MAX_CTA_DETAILS_ASC',
  CompanyCtasMaxCtaDetailsDesc = 'COMPANY_CTAS_MAX_CTA_DETAILS_DESC',
  CompanyCtasAverageIdAsc = 'COMPANY_CTAS_AVERAGE_ID_ASC',
  CompanyCtasAverageIdDesc = 'COMPANY_CTAS_AVERAGE_ID_DESC',
  CompanyCtasAverageCtaIdAsc = 'COMPANY_CTAS_AVERAGE_CTA_ID_ASC',
  CompanyCtasAverageCtaIdDesc = 'COMPANY_CTAS_AVERAGE_CTA_ID_DESC',
  CompanyCtasAverageGroupIdAsc = 'COMPANY_CTAS_AVERAGE_GROUP_ID_ASC',
  CompanyCtasAverageGroupIdDesc = 'COMPANY_CTAS_AVERAGE_GROUP_ID_DESC',
  CompanyCtasAverageThemeAsc = 'COMPANY_CTAS_AVERAGE_THEME_ASC',
  CompanyCtasAverageThemeDesc = 'COMPANY_CTAS_AVERAGE_THEME_DESC',
  CompanyCtasAverageNameAsc = 'COMPANY_CTAS_AVERAGE_NAME_ASC',
  CompanyCtasAverageNameDesc = 'COMPANY_CTAS_AVERAGE_NAME_DESC',
  CompanyCtasAverageLockedAsc = 'COMPANY_CTAS_AVERAGE_LOCKED_ASC',
  CompanyCtasAverageLockedDesc = 'COMPANY_CTAS_AVERAGE_LOCKED_DESC',
  CompanyCtasAverageDefaultAsc = 'COMPANY_CTAS_AVERAGE_DEFAULT_ASC',
  CompanyCtasAverageDefaultDesc = 'COMPANY_CTAS_AVERAGE_DEFAULT_DESC',
  CompanyCtasAverageTemplateDefAsc = 'COMPANY_CTAS_AVERAGE_TEMPLATE_DEF_ASC',
  CompanyCtasAverageTemplateDefDesc = 'COMPANY_CTAS_AVERAGE_TEMPLATE_DEF_DESC',
  CompanyCtasAverageThemeDefAsc = 'COMPANY_CTAS_AVERAGE_THEME_DEF_ASC',
  CompanyCtasAverageThemeDefDesc = 'COMPANY_CTAS_AVERAGE_THEME_DEF_DESC',
  CompanyCtasAverageCreatedAtAsc = 'COMPANY_CTAS_AVERAGE_CREATED_AT_ASC',
  CompanyCtasAverageCreatedAtDesc = 'COMPANY_CTAS_AVERAGE_CREATED_AT_DESC',
  CompanyCtasAverageUpdatedAtAsc = 'COMPANY_CTAS_AVERAGE_UPDATED_AT_ASC',
  CompanyCtasAverageUpdatedAtDesc = 'COMPANY_CTAS_AVERAGE_UPDATED_AT_DESC',
  CompanyCtasAverageCompatibleMajorAsc = 'COMPANY_CTAS_AVERAGE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasAverageCompatibleMajorDesc = 'COMPANY_CTAS_AVERAGE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasAverageCtaDetailsAsc = 'COMPANY_CTAS_AVERAGE_CTA_DETAILS_ASC',
  CompanyCtasAverageCtaDetailsDesc = 'COMPANY_CTAS_AVERAGE_CTA_DETAILS_DESC',
  CompanyCtasStddevSampleIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_ID_ASC',
  CompanyCtasStddevSampleIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_ID_DESC',
  CompanyCtasStddevSampleCtaIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_ID_ASC',
  CompanyCtasStddevSampleCtaIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_ID_DESC',
  CompanyCtasStddevSampleGroupIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyCtasStddevSampleGroupIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyCtasStddevSampleThemeAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_ASC',
  CompanyCtasStddevSampleThemeDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DESC',
  CompanyCtasStddevSampleNameAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_NAME_ASC',
  CompanyCtasStddevSampleNameDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_NAME_DESC',
  CompanyCtasStddevSampleLockedAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_LOCKED_ASC',
  CompanyCtasStddevSampleLockedDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_LOCKED_DESC',
  CompanyCtasStddevSampleDefaultAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_DEFAULT_ASC',
  CompanyCtasStddevSampleDefaultDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_DEFAULT_DESC',
  CompanyCtasStddevSampleTemplateDefAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_TEMPLATE_DEF_ASC',
  CompanyCtasStddevSampleTemplateDefDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_TEMPLATE_DEF_DESC',
  CompanyCtasStddevSampleThemeDefAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DEF_ASC',
  CompanyCtasStddevSampleThemeDefDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DEF_DESC',
  CompanyCtasStddevSampleCreatedAtAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyCtasStddevSampleCreatedAtDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyCtasStddevSampleUpdatedAtAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyCtasStddevSampleUpdatedAtDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyCtasStddevSampleCompatibleMajorAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasStddevSampleCompatibleMajorDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasStddevSampleCtaDetailsAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_DETAILS_ASC',
  CompanyCtasStddevSampleCtaDetailsDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_DETAILS_DESC',
  CompanyCtasStddevPopulationIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_ID_ASC',
  CompanyCtasStddevPopulationIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_ID_DESC',
  CompanyCtasStddevPopulationCtaIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_ID_ASC',
  CompanyCtasStddevPopulationCtaIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_ID_DESC',
  CompanyCtasStddevPopulationGroupIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyCtasStddevPopulationGroupIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyCtasStddevPopulationThemeAsc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_ASC',
  CompanyCtasStddevPopulationThemeDesc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DESC',
  CompanyCtasStddevPopulationNameAsc = 'COMPANY_CTAS_STDDEV_POPULATION_NAME_ASC',
  CompanyCtasStddevPopulationNameDesc = 'COMPANY_CTAS_STDDEV_POPULATION_NAME_DESC',
  CompanyCtasStddevPopulationLockedAsc = 'COMPANY_CTAS_STDDEV_POPULATION_LOCKED_ASC',
  CompanyCtasStddevPopulationLockedDesc = 'COMPANY_CTAS_STDDEV_POPULATION_LOCKED_DESC',
  CompanyCtasStddevPopulationDefaultAsc = 'COMPANY_CTAS_STDDEV_POPULATION_DEFAULT_ASC',
  CompanyCtasStddevPopulationDefaultDesc = 'COMPANY_CTAS_STDDEV_POPULATION_DEFAULT_DESC',
  CompanyCtasStddevPopulationTemplateDefAsc = 'COMPANY_CTAS_STDDEV_POPULATION_TEMPLATE_DEF_ASC',
  CompanyCtasStddevPopulationTemplateDefDesc = 'COMPANY_CTAS_STDDEV_POPULATION_TEMPLATE_DEF_DESC',
  CompanyCtasStddevPopulationThemeDefAsc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DEF_ASC',
  CompanyCtasStddevPopulationThemeDefDesc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DEF_DESC',
  CompanyCtasStddevPopulationCreatedAtAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyCtasStddevPopulationCreatedAtDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyCtasStddevPopulationUpdatedAtAsc = 'COMPANY_CTAS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyCtasStddevPopulationUpdatedAtDesc = 'COMPANY_CTAS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyCtasStddevPopulationCompatibleMajorAsc = 'COMPANY_CTAS_STDDEV_POPULATION_COMPATIBLE_MAJOR_ASC',
  CompanyCtasStddevPopulationCompatibleMajorDesc = 'COMPANY_CTAS_STDDEV_POPULATION_COMPATIBLE_MAJOR_DESC',
  CompanyCtasStddevPopulationCtaDetailsAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_DETAILS_ASC',
  CompanyCtasStddevPopulationCtaDetailsDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_DETAILS_DESC',
  CompanyCtasVarianceSampleIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_ID_ASC',
  CompanyCtasVarianceSampleIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_ID_DESC',
  CompanyCtasVarianceSampleCtaIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_ID_ASC',
  CompanyCtasVarianceSampleCtaIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_ID_DESC',
  CompanyCtasVarianceSampleGroupIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyCtasVarianceSampleGroupIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyCtasVarianceSampleThemeAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_ASC',
  CompanyCtasVarianceSampleThemeDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DESC',
  CompanyCtasVarianceSampleNameAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_NAME_ASC',
  CompanyCtasVarianceSampleNameDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_NAME_DESC',
  CompanyCtasVarianceSampleLockedAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_LOCKED_ASC',
  CompanyCtasVarianceSampleLockedDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_LOCKED_DESC',
  CompanyCtasVarianceSampleDefaultAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_DEFAULT_ASC',
  CompanyCtasVarianceSampleDefaultDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_DEFAULT_DESC',
  CompanyCtasVarianceSampleTemplateDefAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_TEMPLATE_DEF_ASC',
  CompanyCtasVarianceSampleTemplateDefDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_TEMPLATE_DEF_DESC',
  CompanyCtasVarianceSampleThemeDefAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DEF_ASC',
  CompanyCtasVarianceSampleThemeDefDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DEF_DESC',
  CompanyCtasVarianceSampleCreatedAtAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyCtasVarianceSampleCreatedAtDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyCtasVarianceSampleUpdatedAtAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyCtasVarianceSampleUpdatedAtDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyCtasVarianceSampleCompatibleMajorAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasVarianceSampleCompatibleMajorDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasVarianceSampleCtaDetailsAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_DETAILS_ASC',
  CompanyCtasVarianceSampleCtaDetailsDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_DETAILS_DESC',
  CompanyCtasVariancePopulationIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_ID_ASC',
  CompanyCtasVariancePopulationIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_ID_DESC',
  CompanyCtasVariancePopulationCtaIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_ID_ASC',
  CompanyCtasVariancePopulationCtaIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_ID_DESC',
  CompanyCtasVariancePopulationGroupIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyCtasVariancePopulationGroupIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyCtasVariancePopulationThemeAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_ASC',
  CompanyCtasVariancePopulationThemeDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DESC',
  CompanyCtasVariancePopulationNameAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_NAME_ASC',
  CompanyCtasVariancePopulationNameDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_NAME_DESC',
  CompanyCtasVariancePopulationLockedAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_LOCKED_ASC',
  CompanyCtasVariancePopulationLockedDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_LOCKED_DESC',
  CompanyCtasVariancePopulationDefaultAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_DEFAULT_ASC',
  CompanyCtasVariancePopulationDefaultDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_DEFAULT_DESC',
  CompanyCtasVariancePopulationTemplateDefAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_TEMPLATE_DEF_ASC',
  CompanyCtasVariancePopulationTemplateDefDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_TEMPLATE_DEF_DESC',
  CompanyCtasVariancePopulationThemeDefAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DEF_ASC',
  CompanyCtasVariancePopulationThemeDefDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DEF_DESC',
  CompanyCtasVariancePopulationCreatedAtAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyCtasVariancePopulationCreatedAtDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyCtasVariancePopulationUpdatedAtAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyCtasVariancePopulationUpdatedAtDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyCtasVariancePopulationCompatibleMajorAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_COMPATIBLE_MAJOR_ASC',
  CompanyCtasVariancePopulationCompatibleMajorDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_COMPATIBLE_MAJOR_DESC',
  CompanyCtasVariancePopulationCtaDetailsAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_DETAILS_ASC',
  CompanyCtasVariancePopulationCtaDetailsDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_DETAILS_DESC',
  CtaWidgetsCountAsc = 'CTA_WIDGETS_COUNT_ASC',
  CtaWidgetsCountDesc = 'CTA_WIDGETS_COUNT_DESC',
  CtaWidgetsSumIdAsc = 'CTA_WIDGETS_SUM_ID_ASC',
  CtaWidgetsSumIdDesc = 'CTA_WIDGETS_SUM_ID_DESC',
  CtaWidgetsSumCtaIdAsc = 'CTA_WIDGETS_SUM_CTA_ID_ASC',
  CtaWidgetsSumCtaIdDesc = 'CTA_WIDGETS_SUM_CTA_ID_DESC',
  CtaWidgetsSumStaticIdAsc = 'CTA_WIDGETS_SUM_STATIC_ID_ASC',
  CtaWidgetsSumStaticIdDesc = 'CTA_WIDGETS_SUM_STATIC_ID_DESC',
  CtaWidgetsSumWidgetIdAsc = 'CTA_WIDGETS_SUM_WIDGET_ID_ASC',
  CtaWidgetsSumWidgetIdDesc = 'CTA_WIDGETS_SUM_WIDGET_ID_DESC',
  CtaWidgetsSumCreatedAtAsc = 'CTA_WIDGETS_SUM_CREATED_AT_ASC',
  CtaWidgetsSumCreatedAtDesc = 'CTA_WIDGETS_SUM_CREATED_AT_DESC',
  CtaWidgetsSumUpdatedAtAsc = 'CTA_WIDGETS_SUM_UPDATED_AT_ASC',
  CtaWidgetsSumUpdatedAtDesc = 'CTA_WIDGETS_SUM_UPDATED_AT_DESC',
  CtaWidgetsDistinctCountIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_ID_ASC',
  CtaWidgetsDistinctCountIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_ID_DESC',
  CtaWidgetsDistinctCountCtaIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_CTA_ID_ASC',
  CtaWidgetsDistinctCountCtaIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_CTA_ID_DESC',
  CtaWidgetsDistinctCountStaticIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_STATIC_ID_ASC',
  CtaWidgetsDistinctCountStaticIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_STATIC_ID_DESC',
  CtaWidgetsDistinctCountWidgetIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CtaWidgetsDistinctCountWidgetIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CtaWidgetsDistinctCountCreatedAtAsc = 'CTA_WIDGETS_DISTINCT_COUNT_CREATED_AT_ASC',
  CtaWidgetsDistinctCountCreatedAtDesc = 'CTA_WIDGETS_DISTINCT_COUNT_CREATED_AT_DESC',
  CtaWidgetsDistinctCountUpdatedAtAsc = 'CTA_WIDGETS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CtaWidgetsDistinctCountUpdatedAtDesc = 'CTA_WIDGETS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CtaWidgetsMinIdAsc = 'CTA_WIDGETS_MIN_ID_ASC',
  CtaWidgetsMinIdDesc = 'CTA_WIDGETS_MIN_ID_DESC',
  CtaWidgetsMinCtaIdAsc = 'CTA_WIDGETS_MIN_CTA_ID_ASC',
  CtaWidgetsMinCtaIdDesc = 'CTA_WIDGETS_MIN_CTA_ID_DESC',
  CtaWidgetsMinStaticIdAsc = 'CTA_WIDGETS_MIN_STATIC_ID_ASC',
  CtaWidgetsMinStaticIdDesc = 'CTA_WIDGETS_MIN_STATIC_ID_DESC',
  CtaWidgetsMinWidgetIdAsc = 'CTA_WIDGETS_MIN_WIDGET_ID_ASC',
  CtaWidgetsMinWidgetIdDesc = 'CTA_WIDGETS_MIN_WIDGET_ID_DESC',
  CtaWidgetsMinCreatedAtAsc = 'CTA_WIDGETS_MIN_CREATED_AT_ASC',
  CtaWidgetsMinCreatedAtDesc = 'CTA_WIDGETS_MIN_CREATED_AT_DESC',
  CtaWidgetsMinUpdatedAtAsc = 'CTA_WIDGETS_MIN_UPDATED_AT_ASC',
  CtaWidgetsMinUpdatedAtDesc = 'CTA_WIDGETS_MIN_UPDATED_AT_DESC',
  CtaWidgetsMaxIdAsc = 'CTA_WIDGETS_MAX_ID_ASC',
  CtaWidgetsMaxIdDesc = 'CTA_WIDGETS_MAX_ID_DESC',
  CtaWidgetsMaxCtaIdAsc = 'CTA_WIDGETS_MAX_CTA_ID_ASC',
  CtaWidgetsMaxCtaIdDesc = 'CTA_WIDGETS_MAX_CTA_ID_DESC',
  CtaWidgetsMaxStaticIdAsc = 'CTA_WIDGETS_MAX_STATIC_ID_ASC',
  CtaWidgetsMaxStaticIdDesc = 'CTA_WIDGETS_MAX_STATIC_ID_DESC',
  CtaWidgetsMaxWidgetIdAsc = 'CTA_WIDGETS_MAX_WIDGET_ID_ASC',
  CtaWidgetsMaxWidgetIdDesc = 'CTA_WIDGETS_MAX_WIDGET_ID_DESC',
  CtaWidgetsMaxCreatedAtAsc = 'CTA_WIDGETS_MAX_CREATED_AT_ASC',
  CtaWidgetsMaxCreatedAtDesc = 'CTA_WIDGETS_MAX_CREATED_AT_DESC',
  CtaWidgetsMaxUpdatedAtAsc = 'CTA_WIDGETS_MAX_UPDATED_AT_ASC',
  CtaWidgetsMaxUpdatedAtDesc = 'CTA_WIDGETS_MAX_UPDATED_AT_DESC',
  CtaWidgetsAverageIdAsc = 'CTA_WIDGETS_AVERAGE_ID_ASC',
  CtaWidgetsAverageIdDesc = 'CTA_WIDGETS_AVERAGE_ID_DESC',
  CtaWidgetsAverageCtaIdAsc = 'CTA_WIDGETS_AVERAGE_CTA_ID_ASC',
  CtaWidgetsAverageCtaIdDesc = 'CTA_WIDGETS_AVERAGE_CTA_ID_DESC',
  CtaWidgetsAverageStaticIdAsc = 'CTA_WIDGETS_AVERAGE_STATIC_ID_ASC',
  CtaWidgetsAverageStaticIdDesc = 'CTA_WIDGETS_AVERAGE_STATIC_ID_DESC',
  CtaWidgetsAverageWidgetIdAsc = 'CTA_WIDGETS_AVERAGE_WIDGET_ID_ASC',
  CtaWidgetsAverageWidgetIdDesc = 'CTA_WIDGETS_AVERAGE_WIDGET_ID_DESC',
  CtaWidgetsAverageCreatedAtAsc = 'CTA_WIDGETS_AVERAGE_CREATED_AT_ASC',
  CtaWidgetsAverageCreatedAtDesc = 'CTA_WIDGETS_AVERAGE_CREATED_AT_DESC',
  CtaWidgetsAverageUpdatedAtAsc = 'CTA_WIDGETS_AVERAGE_UPDATED_AT_ASC',
  CtaWidgetsAverageUpdatedAtDesc = 'CTA_WIDGETS_AVERAGE_UPDATED_AT_DESC',
  CtaWidgetsStddevSampleIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_ID_ASC',
  CtaWidgetsStddevSampleIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_ID_DESC',
  CtaWidgetsStddevSampleCtaIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_CTA_ID_ASC',
  CtaWidgetsStddevSampleCtaIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_CTA_ID_DESC',
  CtaWidgetsStddevSampleStaticIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_STATIC_ID_ASC',
  CtaWidgetsStddevSampleStaticIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_STATIC_ID_DESC',
  CtaWidgetsStddevSampleWidgetIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CtaWidgetsStddevSampleWidgetIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CtaWidgetsStddevSampleCreatedAtAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CtaWidgetsStddevSampleCreatedAtDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CtaWidgetsStddevSampleUpdatedAtAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CtaWidgetsStddevSampleUpdatedAtDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CtaWidgetsStddevPopulationIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_ID_ASC',
  CtaWidgetsStddevPopulationIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_ID_DESC',
  CtaWidgetsStddevPopulationCtaIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_CTA_ID_ASC',
  CtaWidgetsStddevPopulationCtaIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_CTA_ID_DESC',
  CtaWidgetsStddevPopulationStaticIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_STATIC_ID_ASC',
  CtaWidgetsStddevPopulationStaticIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_STATIC_ID_DESC',
  CtaWidgetsStddevPopulationWidgetIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CtaWidgetsStddevPopulationWidgetIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CtaWidgetsStddevPopulationCreatedAtAsc = 'CTA_WIDGETS_STDDEV_POPULATION_CREATED_AT_ASC',
  CtaWidgetsStddevPopulationCreatedAtDesc = 'CTA_WIDGETS_STDDEV_POPULATION_CREATED_AT_DESC',
  CtaWidgetsStddevPopulationUpdatedAtAsc = 'CTA_WIDGETS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CtaWidgetsStddevPopulationUpdatedAtDesc = 'CTA_WIDGETS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CtaWidgetsVarianceSampleIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_ID_ASC',
  CtaWidgetsVarianceSampleIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_ID_DESC',
  CtaWidgetsVarianceSampleCtaIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CTA_ID_ASC',
  CtaWidgetsVarianceSampleCtaIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CTA_ID_DESC',
  CtaWidgetsVarianceSampleStaticIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_STATIC_ID_ASC',
  CtaWidgetsVarianceSampleStaticIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_STATIC_ID_DESC',
  CtaWidgetsVarianceSampleWidgetIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CtaWidgetsVarianceSampleWidgetIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CtaWidgetsVarianceSampleCreatedAtAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CtaWidgetsVarianceSampleCreatedAtDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CtaWidgetsVarianceSampleUpdatedAtAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CtaWidgetsVarianceSampleUpdatedAtDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CtaWidgetsVariancePopulationIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_ID_ASC',
  CtaWidgetsVariancePopulationIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_ID_DESC',
  CtaWidgetsVariancePopulationCtaIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_CTA_ID_ASC',
  CtaWidgetsVariancePopulationCtaIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_CTA_ID_DESC',
  CtaWidgetsVariancePopulationStaticIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_STATIC_ID_ASC',
  CtaWidgetsVariancePopulationStaticIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_STATIC_ID_DESC',
  CtaWidgetsVariancePopulationWidgetIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CtaWidgetsVariancePopulationWidgetIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CtaWidgetsVariancePopulationCreatedAtAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CtaWidgetsVariancePopulationCreatedAtDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CtaWidgetsVariancePopulationUpdatedAtAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CtaWidgetsVariancePopulationUpdatedAtDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}



/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Date']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Date']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Date']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Date']>;
};


/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Datetime']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Datetime']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

export type Deal = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId?: Maybe<Scalars['UUID']>;
  companyIntegrationId: Scalars['Int'];
  fileType?: Maybe<Scalars['String']>;
  dvDealerId?: Maybe<Scalars['String']>;
  vendorDealerId?: Maybe<Scalars['String']>;
  dmsType?: Maybe<Scalars['String']>;
  dealNumber?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  workExtension?: Maybe<Scalars['String']>;
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  email3?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  individualBusinessFlag?: Maybe<Scalars['String']>;
  optOut?: Maybe<Scalars['Boolean']>;
  blockEmail?: Maybe<Scalars['Boolean']>;
  blockPhone?: Maybe<Scalars['Boolean']>;
  blockMail?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  customerCreateDate?: Maybe<Scalars['Date']>;
  customerLastActivityDate?: Maybe<Scalars['Date']>;
  coBuyerCustomerNumber?: Maybe<Scalars['String']>;
  coBuyerFullName?: Maybe<Scalars['String']>;
  coBuyerSalutation?: Maybe<Scalars['String']>;
  coBuyerFirstName?: Maybe<Scalars['String']>;
  coBuyerMiddleName?: Maybe<Scalars['String']>;
  coBuyerLastName?: Maybe<Scalars['String']>;
  coBuyerSuffix?: Maybe<Scalars['String']>;
  coBuyerAddressLine1?: Maybe<Scalars['String']>;
  coBuyerAddressLine2?: Maybe<Scalars['String']>;
  coBuyerCity?: Maybe<Scalars['String']>;
  coBuyerState?: Maybe<Scalars['String']>;
  coBuyerZip?: Maybe<Scalars['String']>;
  coBuyerCounty?: Maybe<Scalars['String']>;
  coBuyerHomePhone?: Maybe<Scalars['String']>;
  coBuyerCellPhone?: Maybe<Scalars['String']>;
  coBuyerWorkPhone?: Maybe<Scalars['String']>;
  coBuyerWorkExtension?: Maybe<Scalars['String']>;
  coBuyerEmail1?: Maybe<Scalars['String']>;
  coBuyerEmail2?: Maybe<Scalars['String']>;
  coBuyerEmail3?: Maybe<Scalars['String']>;
  coBuyerBirthDate?: Maybe<Scalars['Date']>;
  coBuyerIndividualBusinessFlag?: Maybe<Scalars['String']>;
  coBuyerOptOut?: Maybe<Scalars['Boolean']>;
  coBuyerBlockEmail?: Maybe<Scalars['Boolean']>;
  coBuyerBlockPhone?: Maybe<Scalars['Boolean']>;
  coBuyerBlockMail?: Maybe<Scalars['Boolean']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  newUsed?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  engineConfiguration?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  engineNumber?: Maybe<Scalars['String']>;
  chassisNumber?: Maybe<Scalars['String']>;
  licensePlateNumber?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  deliveryMileage?: Maybe<Scalars['Int']>;
  inventoryDate?: Maybe<Scalars['Date']>;
  inServiceDate?: Maybe<Scalars['Date']>;
  vinExplosionYear?: Maybe<Scalars['Int']>;
  vinExplosionMake?: Maybe<Scalars['String']>;
  vinExplosionModel?: Maybe<Scalars['String']>;
  vinExplosionTrim?: Maybe<Scalars['String']>;
  vinExplosionTransmissionType?: Maybe<Scalars['String']>;
  vinExplosionFuelType?: Maybe<Scalars['String']>;
  vinExplosionEngineSize?: Maybe<Scalars['String']>;
  vinExplosionGvwRange?: Maybe<Scalars['String']>;
  trade1Vin?: Maybe<Scalars['String']>;
  trade1Year?: Maybe<Scalars['Int']>;
  trade1Make?: Maybe<Scalars['String']>;
  trade1Model?: Maybe<Scalars['String']>;
  trade1Odometer?: Maybe<Scalars['Int']>;
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  trade2Vin?: Maybe<Scalars['String']>;
  trade2Year?: Maybe<Scalars['Int']>;
  trade2Make?: Maybe<Scalars['String']>;
  trade2Model?: Maybe<Scalars['String']>;
  trade2Odometer?: Maybe<Scalars['Int']>;
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  salesman1Number?: Maybe<Scalars['String']>;
  salesman1Name?: Maybe<Scalars['String']>;
  salesman2Number?: Maybe<Scalars['String']>;
  salesman2Name?: Maybe<Scalars['String']>;
  salesman3Number?: Maybe<Scalars['String']>;
  salesman3Name?: Maybe<Scalars['String']>;
  closingManagerNumber?: Maybe<Scalars['String']>;
  closingManagerName?: Maybe<Scalars['String']>;
  financeManagerNumber?: Maybe<Scalars['String']>;
  financeManagerName?: Maybe<Scalars['String']>;
  salesmanManagerNumber?: Maybe<Scalars['String']>;
  salesmanManagerName?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['BigFloat']>;
  listPrice?: Maybe<Scalars['BigFloat']>;
  salesPrice?: Maybe<Scalars['BigFloat']>;
  journalPrice?: Maybe<Scalars['BigFloat']>;
  cost?: Maybe<Scalars['BigFloat']>;
  journalCost?: Maybe<Scalars['BigFloat']>;
  adjustments?: Maybe<Scalars['BigFloat']>;
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  incentives?: Maybe<Scalars['BigFloat']>;
  packAmount?: Maybe<Scalars['BigFloat']>;
  saleNet?: Maybe<Scalars['BigFloat']>;
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  weOweFront?: Maybe<Scalars['BigFloat']>;
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  frontGross?: Maybe<Scalars['BigFloat']>;
  financeProfit?: Maybe<Scalars['BigFloat']>;
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  weOweBack?: Maybe<Scalars['BigFloat']>;
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  financeReserve?: Maybe<Scalars['BigFloat']>;
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  backGross?: Maybe<Scalars['BigFloat']>;
  totalProfit?: Maybe<Scalars['BigFloat']>;
  grossProfit?: Maybe<Scalars['BigFloat']>;
  grossPayable?: Maybe<Scalars['BigFloat']>;
  dealStatus?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  bookedDate?: Maybe<Scalars['Date']>;
  finalizedDate?: Maybe<Scalars['Date']>;
  contractDate?: Maybe<Scalars['Date']>;
  accountingDate?: Maybe<Scalars['Date']>;
  statusChangeDate?: Maybe<Scalars['Date']>;
  firstPayDate?: Maybe<Scalars['Date']>;
  dealType?: Maybe<Scalars['String']>;
  saleType?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  apr?: Maybe<Scalars['BigFloat']>;
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  rebates?: Maybe<Scalars['BigFloat']>;
  deposit?: Maybe<Scalars['BigFloat']>;
  downPayment?: Maybe<Scalars['BigFloat']>;
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  totalDown?: Maybe<Scalars['BigFloat']>;
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  licenseFee?: Maybe<Scalars['BigFloat']>;
  registrationFee?: Maybe<Scalars['BigFloat']>;
  documentationFee?: Maybe<Scalars['BigFloat']>;
  financeCharge?: Maybe<Scalars['BigFloat']>;
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  sellRate?: Maybe<Scalars['BigFloat']>;
  buyRate?: Maybe<Scalars['BigFloat']>;
  residualRate?: Maybe<Scalars['BigFloat']>;
  residualAmount?: Maybe<Scalars['BigFloat']>;
  allowedMiles?: Maybe<Scalars['Int']>;
  estimatedMiles?: Maybe<Scalars['Int']>;
  mileageRate?: Maybe<Scalars['BigFloat']>;
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  basePayment?: Maybe<Scalars['BigFloat']>;
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  dealerFees?: Maybe<Scalars['BigFloat']>;
  governmentFees?: Maybe<Scalars['BigFloat']>;
  totalTax?: Maybe<Scalars['BigFloat']>;
  registrationState?: Maybe<Scalars['String']>;
  reportOfSaleNumber?: Maybe<Scalars['String']>;
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  warranty1Name?: Maybe<Scalars['String']>;
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  warranty1Miles?: Maybe<Scalars['Int']>;
  warranty1Term?: Maybe<Scalars['Int']>;
  warranty2Name?: Maybe<Scalars['String']>;
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  warranty2Miles?: Maybe<Scalars['Int']>;
  warranty2Term?: Maybe<Scalars['Int']>;
  warranty3Name?: Maybe<Scalars['String']>;
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  warranty3Miles?: Maybe<Scalars['Int']>;
  warranty3Term?: Maybe<Scalars['Int']>;
  warranty4Name?: Maybe<Scalars['String']>;
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  warranty4Miles?: Maybe<Scalars['Int']>;
  warranty4Term?: Maybe<Scalars['Int']>;
  warranty5Name?: Maybe<Scalars['String']>;
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  warranty5Miles?: Maybe<Scalars['Int']>;
  warranty5Term?: Maybe<Scalars['Int']>;
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Name?: Maybe<Scalars['String']>;
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket2Name?: Maybe<Scalars['String']>;
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket3Name?: Maybe<Scalars['String']>;
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket4Name?: Maybe<Scalars['String']>;
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket5Name?: Maybe<Scalars['String']>;
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket6Name?: Maybe<Scalars['String']>;
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket7Name?: Maybe<Scalars['String']>;
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket8Name?: Maybe<Scalars['String']>;
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket9Name?: Maybe<Scalars['String']>;
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket10Name?: Maybe<Scalars['String']>;
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket11Name?: Maybe<Scalars['String']>;
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket12Name?: Maybe<Scalars['String']>;
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket13Name?: Maybe<Scalars['String']>;
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket14Name?: Maybe<Scalars['String']>;
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket15Name?: Maybe<Scalars['String']>;
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket16Name?: Maybe<Scalars['String']>;
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket17Name?: Maybe<Scalars['String']>;
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket18Name?: Maybe<Scalars['String']>;
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket19Name?: Maybe<Scalars['String']>;
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket20Name?: Maybe<Scalars['String']>;
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20ProfitIndicator?: Maybe<Scalars['Boolean']>;
  insurance1Type?: Maybe<Scalars['String']>;
  insurance1Name?: Maybe<Scalars['String']>;
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  insurance1Term?: Maybe<Scalars['Int']>;
  insurance2Type?: Maybe<Scalars['String']>;
  insurance2Name?: Maybe<Scalars['String']>;
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  insurance2Term?: Maybe<Scalars['Int']>;
  insurance3Type?: Maybe<Scalars['String']>;
  insurance3Name?: Maybe<Scalars['String']>;
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  insurance3Term?: Maybe<Scalars['Int']>;
  accidentalHealthType?: Maybe<Scalars['String']>;
  accidentalHealthName?: Maybe<Scalars['String']>;
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  accidentalHealthTerm?: Maybe<Scalars['Int']>;
  creditLifeType?: Maybe<Scalars['String']>;
  creditLifeName?: Maybe<Scalars['String']>;
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  creditLifeTerm?: Maybe<Scalars['Int']>;
  levelizedLifeType?: Maybe<Scalars['String']>;
  levelizedLifeName?: Maybe<Scalars['String']>;
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  levelizedLifeTerm?: Maybe<Scalars['Int']>;
  lossOfEmploymentType?: Maybe<Scalars['String']>;
  lossOfEmploymentName?: Maybe<Scalars['String']>;
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentTerm?: Maybe<Scalars['Int']>;
  guaranteedAutoProtectionType?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionName?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionTerm?: Maybe<Scalars['Int']>;
  saleComments?: Maybe<Scalars['String']>;
  cassStdLine1?: Maybe<Scalars['String']>;
  cassStdLine2?: Maybe<Scalars['String']>;
  cassStdCity?: Maybe<Scalars['String']>;
  cassStdState?: Maybe<Scalars['String']>;
  cassStdZip?: Maybe<Scalars['String']>;
  cassStdZip4?: Maybe<Scalars['String']>;
  cassStdDpbc?: Maybe<Scalars['String']>;
  cassStdChkdgt?: Maybe<Scalars['String']>;
  cassStdCart?: Maybe<Scalars['String']>;
  cassStdLot?: Maybe<Scalars['String']>;
  cassStdLotord?: Maybe<Scalars['String']>;
  cassStdUrb?: Maybe<Scalars['String']>;
  cassStdFips?: Maybe<Scalars['String']>;
  cassStdEws?: Maybe<Scalars['String']>;
  cassStdLacs?: Maybe<Scalars['String']>;
  cassStdZipmov?: Maybe<Scalars['String']>;
  cassStdZ4Lom?: Maybe<Scalars['String']>;
  cassStdNdiapt?: Maybe<Scalars['String']>;
  cassStdNdirr?: Maybe<Scalars['String']>;
  cassStdLacsrt?: Maybe<Scalars['String']>;
  cassStdErrorCd?: Maybe<Scalars['String']>;
  /** Reads a single `Lead` that is related to this `Deal`. */
  lead?: Maybe<Lead>;
  /** Reads a single `CompanyIntegration` that is related to this `Deal`. */
  companyIntegration?: Maybe<CompanyIntegration>;
};

export type DealAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DealSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DealDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DealMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DealMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DealAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DealStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DealStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DealVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DealVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Deal` object types. */
export type DealAggregatesFilter = {
  /** A filter that must pass for the relevant `Deal` object to be included within the aggregate. */
  filter?: Maybe<DealFilter>;
  /** Sum aggregate over matching `Deal` objects. */
  sum?: Maybe<DealSumAggregateFilter>;
  /** Distinct count aggregate over matching `Deal` objects. */
  distinctCount?: Maybe<DealDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Deal` objects. */
  min?: Maybe<DealMinAggregateFilter>;
  /** Maximum aggregate over matching `Deal` objects. */
  max?: Maybe<DealMaxAggregateFilter>;
  /** Mean average aggregate over matching `Deal` objects. */
  average?: Maybe<DealAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Deal` objects. */
  stddevSample?: Maybe<DealStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Deal` objects. */
  stddevPopulation?: Maybe<DealStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Deal` objects. */
  varianceSample?: Maybe<DealVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Deal` objects. */
  variancePopulation?: Maybe<DealVariancePopulationAggregateFilter>;
};

export type DealAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  deliveryMileage?: Maybe<BigFloatFilter>;
  vinExplosionYear?: Maybe<BigFloatFilter>;
  trade1Year?: Maybe<BigFloatFilter>;
  trade1Odometer?: Maybe<BigFloatFilter>;
  trade1ActualCashValue?: Maybe<BigFloatFilter>;
  trade1Gross?: Maybe<BigFloatFilter>;
  trade1Payoff?: Maybe<BigFloatFilter>;
  trade2Year?: Maybe<BigFloatFilter>;
  trade2Odometer?: Maybe<BigFloatFilter>;
  trade2ActualCashValue?: Maybe<BigFloatFilter>;
  trade2Gross?: Maybe<BigFloatFilter>;
  trade2Payoff?: Maybe<BigFloatFilter>;
  msrp?: Maybe<BigFloatFilter>;
  listPrice?: Maybe<BigFloatFilter>;
  salesPrice?: Maybe<BigFloatFilter>;
  journalPrice?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
  journalCost?: Maybe<BigFloatFilter>;
  adjustments?: Maybe<BigFloatFilter>;
  adjustedCost?: Maybe<BigFloatFilter>;
  incentives?: Maybe<BigFloatFilter>;
  packAmount?: Maybe<BigFloatFilter>;
  saleNet?: Maybe<BigFloatFilter>;
  totalTradeActualCashValue?: Maybe<BigFloatFilter>;
  totalTradeGross?: Maybe<BigFloatFilter>;
  weOweFront?: Maybe<BigFloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  totalFrontCommission?: Maybe<BigFloatFilter>;
  totalFrontSales?: Maybe<BigFloatFilter>;
  totalFrontCost?: Maybe<BigFloatFilter>;
  frontGross?: Maybe<BigFloatFilter>;
  financeProfit?: Maybe<BigFloatFilter>;
  totalWarrantyProfit?: Maybe<BigFloatFilter>;
  weOweBack?: Maybe<BigFloatFilter>;
  insuranceProfit?: Maybe<BigFloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  financeReserve?: Maybe<BigFloatFilter>;
  totalBackCommission?: Maybe<BigFloatFilter>;
  totalBackSales?: Maybe<BigFloatFilter>;
  totalBackCost?: Maybe<BigFloatFilter>;
  backGross?: Maybe<BigFloatFilter>;
  totalProfit?: Maybe<BigFloatFilter>;
  grossProfit?: Maybe<BigFloatFilter>;
  grossPayable?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
  amountFinanced?: Maybe<BigFloatFilter>;
  apr?: Maybe<BigFloatFilter>;
  monthlyPayment?: Maybe<BigFloatFilter>;
  paymentTotal?: Maybe<BigFloatFilter>;
  rebates?: Maybe<BigFloatFilter>;
  deposit?: Maybe<BigFloatFilter>;
  downPayment?: Maybe<BigFloatFilter>;
  totalNetTrades?: Maybe<BigFloatFilter>;
  totalDown?: Maybe<BigFloatFilter>;
  balloonAmount?: Maybe<BigFloatFilter>;
  adjustedBalloonAmount?: Maybe<BigFloatFilter>;
  holdbackAmount?: Maybe<BigFloatFilter>;
  totalDriveOffAmount?: Maybe<BigFloatFilter>;
  licenseFee?: Maybe<BigFloatFilter>;
  registrationFee?: Maybe<BigFloatFilter>;
  documentationFee?: Maybe<BigFloatFilter>;
  financeCharge?: Maybe<BigFloatFilter>;
  totalPickupPayments?: Maybe<BigFloatFilter>;
  sellRate?: Maybe<BigFloatFilter>;
  buyRate?: Maybe<BigFloatFilter>;
  residualRate?: Maybe<BigFloatFilter>;
  residualAmount?: Maybe<BigFloatFilter>;
  allowedMiles?: Maybe<BigFloatFilter>;
  estimatedMiles?: Maybe<BigFloatFilter>;
  mileageRate?: Maybe<BigFloatFilter>;
  acquisitionFee?: Maybe<BigFloatFilter>;
  basePayment?: Maybe<BigFloatFilter>;
  securityDeposit?: Maybe<BigFloatFilter>;
  totalCapitalReduction?: Maybe<BigFloatFilter>;
  netCapitalCost?: Maybe<BigFloatFilter>;
  leaseDepreciationValue?: Maybe<BigFloatFilter>;
  dealerFees?: Maybe<BigFloatFilter>;
  governmentFees?: Maybe<BigFloatFilter>;
  totalTax?: Maybe<BigFloatFilter>;
  salesman1TotalCommission?: Maybe<BigFloatFilter>;
  salesman1FrontCommission?: Maybe<BigFloatFilter>;
  salesman1BackCommission?: Maybe<BigFloatFilter>;
  salesman2TotalCommission?: Maybe<BigFloatFilter>;
  salesman2FrontCommission?: Maybe<BigFloatFilter>;
  salesman2BackCommission?: Maybe<BigFloatFilter>;
  salesman3TotalCommission?: Maybe<BigFloatFilter>;
  salesman3FrontCommission?: Maybe<BigFloatFilter>;
  salesman3BackCommission?: Maybe<BigFloatFilter>;
  warranty1Sale?: Maybe<BigFloatFilter>;
  warranty1Cost?: Maybe<BigFloatFilter>;
  warranty1Miles?: Maybe<BigFloatFilter>;
  warranty1Term?: Maybe<BigFloatFilter>;
  warranty2Sale?: Maybe<BigFloatFilter>;
  warranty2Cost?: Maybe<BigFloatFilter>;
  warranty2Miles?: Maybe<BigFloatFilter>;
  warranty2Term?: Maybe<BigFloatFilter>;
  warranty3Sale?: Maybe<BigFloatFilter>;
  warranty3Cost?: Maybe<BigFloatFilter>;
  warranty3Miles?: Maybe<BigFloatFilter>;
  warranty3Term?: Maybe<BigFloatFilter>;
  warranty4Sale?: Maybe<BigFloatFilter>;
  warranty4Cost?: Maybe<BigFloatFilter>;
  warranty4Miles?: Maybe<BigFloatFilter>;
  warranty4Term?: Maybe<BigFloatFilter>;
  warranty5Sale?: Maybe<BigFloatFilter>;
  warranty5Cost?: Maybe<BigFloatFilter>;
  warranty5Miles?: Maybe<BigFloatFilter>;
  warranty5Term?: Maybe<BigFloatFilter>;
  totalFeeAftermarketSale?: Maybe<BigFloatFilter>;
  totalFeeAftermarketCost?: Maybe<BigFloatFilter>;
  feeAftermarket1Sale?: Maybe<BigFloatFilter>;
  feeAftermarket1Cost?: Maybe<BigFloatFilter>;
  feeAftermarket2Sale?: Maybe<BigFloatFilter>;
  feeAftermarket2Cost?: Maybe<BigFloatFilter>;
  feeAftermarket3Sale?: Maybe<BigFloatFilter>;
  feeAftermarket3Cost?: Maybe<BigFloatFilter>;
  feeAftermarket4Sale?: Maybe<BigFloatFilter>;
  feeAftermarket4Cost?: Maybe<BigFloatFilter>;
  feeAftermarket5Sale?: Maybe<BigFloatFilter>;
  feeAftermarket5Cost?: Maybe<BigFloatFilter>;
  feeAftermarket6Sale?: Maybe<BigFloatFilter>;
  feeAftermarket6Cost?: Maybe<BigFloatFilter>;
  feeAftermarket7Sale?: Maybe<BigFloatFilter>;
  feeAftermarket7Cost?: Maybe<BigFloatFilter>;
  feeAftermarket8Sale?: Maybe<BigFloatFilter>;
  feeAftermarket8Cost?: Maybe<BigFloatFilter>;
  feeAftermarket9Sale?: Maybe<BigFloatFilter>;
  feeAftermarket9Cost?: Maybe<BigFloatFilter>;
  feeAftermarket10Sale?: Maybe<BigFloatFilter>;
  feeAftermarket10Cost?: Maybe<BigFloatFilter>;
  feeAftermarket11Sale?: Maybe<BigFloatFilter>;
  feeAftermarket11Cost?: Maybe<BigFloatFilter>;
  feeAftermarket12Sale?: Maybe<BigFloatFilter>;
  feeAftermarket12Cost?: Maybe<BigFloatFilter>;
  feeAftermarket13Sale?: Maybe<BigFloatFilter>;
  feeAftermarket13Cost?: Maybe<BigFloatFilter>;
  feeAftermarket14Sale?: Maybe<BigFloatFilter>;
  feeAftermarket14Cost?: Maybe<BigFloatFilter>;
  feeAftermarket15Sale?: Maybe<BigFloatFilter>;
  feeAftermarket15Cost?: Maybe<BigFloatFilter>;
  feeAftermarket16Sale?: Maybe<BigFloatFilter>;
  feeAftermarket16Cost?: Maybe<BigFloatFilter>;
  feeAftermarket17Sale?: Maybe<BigFloatFilter>;
  feeAftermarket17Cost?: Maybe<BigFloatFilter>;
  feeAftermarket18Sale?: Maybe<BigFloatFilter>;
  feeAftermarket18Cost?: Maybe<BigFloatFilter>;
  feeAftermarket19Sale?: Maybe<BigFloatFilter>;
  feeAftermarket19Cost?: Maybe<BigFloatFilter>;
  feeAftermarket20Sale?: Maybe<BigFloatFilter>;
  feeAftermarket20Cost?: Maybe<BigFloatFilter>;
  insurance1Sale?: Maybe<BigFloatFilter>;
  insurance1Cost?: Maybe<BigFloatFilter>;
  insurance1Term?: Maybe<BigFloatFilter>;
  insurance2Sale?: Maybe<BigFloatFilter>;
  insurance2Cost?: Maybe<BigFloatFilter>;
  insurance2Term?: Maybe<BigFloatFilter>;
  insurance3Sale?: Maybe<BigFloatFilter>;
  insurance3Cost?: Maybe<BigFloatFilter>;
  insurance3Term?: Maybe<BigFloatFilter>;
  accidentalHealthSale?: Maybe<BigFloatFilter>;
  accidentalHealthCost?: Maybe<BigFloatFilter>;
  accidentalHealthTerm?: Maybe<BigFloatFilter>;
  creditLifeSale?: Maybe<BigFloatFilter>;
  creditLifeCost?: Maybe<BigFloatFilter>;
  creditLifeTerm?: Maybe<BigFloatFilter>;
  levelizedLifeSale?: Maybe<BigFloatFilter>;
  levelizedLifeCost?: Maybe<BigFloatFilter>;
  levelizedLifeTerm?: Maybe<BigFloatFilter>;
  lossOfEmploymentSale?: Maybe<BigFloatFilter>;
  lossOfEmploymentCost?: Maybe<BigFloatFilter>;
  lossOfEmploymentTerm?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionSale?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<BigFloatFilter>;
};

export type DealAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Mean average of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Mean average of deliveryMileage across the matching connection */
  deliveryMileage?: Maybe<Scalars['BigFloat']>;
  /** Mean average of vinExplosionYear across the matching connection */
  vinExplosionYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of trade1Year across the matching connection */
  trade1Year?: Maybe<Scalars['BigFloat']>;
  /** Mean average of trade1Odometer across the matching connection */
  trade1Odometer?: Maybe<Scalars['BigFloat']>;
  /** Mean average of trade1ActualCashValue across the matching connection */
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Mean average of trade1Gross across the matching connection */
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  /** Mean average of trade1Payoff across the matching connection */
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  /** Mean average of trade2Year across the matching connection */
  trade2Year?: Maybe<Scalars['BigFloat']>;
  /** Mean average of trade2Odometer across the matching connection */
  trade2Odometer?: Maybe<Scalars['BigFloat']>;
  /** Mean average of trade2ActualCashValue across the matching connection */
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Mean average of trade2Gross across the matching connection */
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  /** Mean average of trade2Payoff across the matching connection */
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  /** Mean average of msrp across the matching connection */
  msrp?: Maybe<Scalars['BigFloat']>;
  /** Mean average of listPrice across the matching connection */
  listPrice?: Maybe<Scalars['BigFloat']>;
  /** Mean average of salesPrice across the matching connection */
  salesPrice?: Maybe<Scalars['BigFloat']>;
  /** Mean average of journalPrice across the matching connection */
  journalPrice?: Maybe<Scalars['BigFloat']>;
  /** Mean average of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of journalCost across the matching connection */
  journalCost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of adjustments across the matching connection */
  adjustments?: Maybe<Scalars['BigFloat']>;
  /** Mean average of adjustedCost across the matching connection */
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of incentives across the matching connection */
  incentives?: Maybe<Scalars['BigFloat']>;
  /** Mean average of packAmount across the matching connection */
  packAmount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of saleNet across the matching connection */
  saleNet?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalTradeActualCashValue across the matching connection */
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalTradeGross across the matching connection */
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  /** Mean average of weOweFront across the matching connection */
  weOweFront?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalFrontFeesAftermarketProfit across the matching connection */
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalFrontCommission across the matching connection */
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalFrontSales across the matching connection */
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalFrontCost across the matching connection */
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of frontGross across the matching connection */
  frontGross?: Maybe<Scalars['BigFloat']>;
  /** Mean average of financeProfit across the matching connection */
  financeProfit?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalWarrantyProfit across the matching connection */
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  /** Mean average of weOweBack across the matching connection */
  weOweBack?: Maybe<Scalars['BigFloat']>;
  /** Mean average of insuranceProfit across the matching connection */
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalBackFeesAftermarketProfit across the matching connection */
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Mean average of financeReserve across the matching connection */
  financeReserve?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalBackCommission across the matching connection */
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalBackSales across the matching connection */
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalBackCost across the matching connection */
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of backGross across the matching connection */
  backGross?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalProfit across the matching connection */
  totalProfit?: Maybe<Scalars['BigFloat']>;
  /** Mean average of grossProfit across the matching connection */
  grossProfit?: Maybe<Scalars['BigFloat']>;
  /** Mean average of grossPayable across the matching connection */
  grossPayable?: Maybe<Scalars['BigFloat']>;
  /** Mean average of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
  /** Mean average of amountFinanced across the matching connection */
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  /** Mean average of apr across the matching connection */
  apr?: Maybe<Scalars['BigFloat']>;
  /** Mean average of monthlyPayment across the matching connection */
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  /** Mean average of paymentTotal across the matching connection */
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  /** Mean average of rebates across the matching connection */
  rebates?: Maybe<Scalars['BigFloat']>;
  /** Mean average of deposit across the matching connection */
  deposit?: Maybe<Scalars['BigFloat']>;
  /** Mean average of downPayment across the matching connection */
  downPayment?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalNetTrades across the matching connection */
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalDown across the matching connection */
  totalDown?: Maybe<Scalars['BigFloat']>;
  /** Mean average of balloonAmount across the matching connection */
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of adjustedBalloonAmount across the matching connection */
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of holdbackAmount across the matching connection */
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalDriveOffAmount across the matching connection */
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of licenseFee across the matching connection */
  licenseFee?: Maybe<Scalars['BigFloat']>;
  /** Mean average of registrationFee across the matching connection */
  registrationFee?: Maybe<Scalars['BigFloat']>;
  /** Mean average of documentationFee across the matching connection */
  documentationFee?: Maybe<Scalars['BigFloat']>;
  /** Mean average of financeCharge across the matching connection */
  financeCharge?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalPickupPayments across the matching connection */
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  /** Mean average of sellRate across the matching connection */
  sellRate?: Maybe<Scalars['BigFloat']>;
  /** Mean average of buyRate across the matching connection */
  buyRate?: Maybe<Scalars['BigFloat']>;
  /** Mean average of residualRate across the matching connection */
  residualRate?: Maybe<Scalars['BigFloat']>;
  /** Mean average of residualAmount across the matching connection */
  residualAmount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of allowedMiles across the matching connection */
  allowedMiles?: Maybe<Scalars['BigFloat']>;
  /** Mean average of estimatedMiles across the matching connection */
  estimatedMiles?: Maybe<Scalars['BigFloat']>;
  /** Mean average of mileageRate across the matching connection */
  mileageRate?: Maybe<Scalars['BigFloat']>;
  /** Mean average of acquisitionFee across the matching connection */
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  /** Mean average of basePayment across the matching connection */
  basePayment?: Maybe<Scalars['BigFloat']>;
  /** Mean average of securityDeposit across the matching connection */
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalCapitalReduction across the matching connection */
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  /** Mean average of netCapitalCost across the matching connection */
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of leaseDepreciationValue across the matching connection */
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  /** Mean average of dealerFees across the matching connection */
  dealerFees?: Maybe<Scalars['BigFloat']>;
  /** Mean average of governmentFees across the matching connection */
  governmentFees?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalTax across the matching connection */
  totalTax?: Maybe<Scalars['BigFloat']>;
  /** Mean average of salesman1TotalCommission across the matching connection */
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Mean average of salesman1FrontCommission across the matching connection */
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Mean average of salesman1BackCommission across the matching connection */
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Mean average of salesman2TotalCommission across the matching connection */
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Mean average of salesman2FrontCommission across the matching connection */
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Mean average of salesman2BackCommission across the matching connection */
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Mean average of salesman3TotalCommission across the matching connection */
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Mean average of salesman3FrontCommission across the matching connection */
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Mean average of salesman3BackCommission across the matching connection */
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty1Sale across the matching connection */
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty1Cost across the matching connection */
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty1Miles across the matching connection */
  warranty1Miles?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty1Term across the matching connection */
  warranty1Term?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty2Sale across the matching connection */
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty2Cost across the matching connection */
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty2Miles across the matching connection */
  warranty2Miles?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty2Term across the matching connection */
  warranty2Term?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty3Sale across the matching connection */
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty3Cost across the matching connection */
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty3Miles across the matching connection */
  warranty3Miles?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty3Term across the matching connection */
  warranty3Term?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty4Sale across the matching connection */
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty4Cost across the matching connection */
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty4Miles across the matching connection */
  warranty4Miles?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty4Term across the matching connection */
  warranty4Term?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty5Sale across the matching connection */
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty5Cost across the matching connection */
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty5Miles across the matching connection */
  warranty5Miles?: Maybe<Scalars['BigFloat']>;
  /** Mean average of warranty5Term across the matching connection */
  warranty5Term?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalFeeAftermarketSale across the matching connection */
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of totalFeeAftermarketCost across the matching connection */
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket1Sale across the matching connection */
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket1Cost across the matching connection */
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket2Sale across the matching connection */
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket2Cost across the matching connection */
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket3Sale across the matching connection */
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket3Cost across the matching connection */
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket4Sale across the matching connection */
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket4Cost across the matching connection */
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket5Sale across the matching connection */
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket5Cost across the matching connection */
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket6Sale across the matching connection */
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket6Cost across the matching connection */
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket7Sale across the matching connection */
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket7Cost across the matching connection */
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket8Sale across the matching connection */
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket8Cost across the matching connection */
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket9Sale across the matching connection */
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket9Cost across the matching connection */
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket10Sale across the matching connection */
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket10Cost across the matching connection */
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket11Sale across the matching connection */
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket11Cost across the matching connection */
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket12Sale across the matching connection */
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket12Cost across the matching connection */
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket13Sale across the matching connection */
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket13Cost across the matching connection */
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket14Sale across the matching connection */
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket14Cost across the matching connection */
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket15Sale across the matching connection */
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket15Cost across the matching connection */
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket16Sale across the matching connection */
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket16Cost across the matching connection */
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket17Sale across the matching connection */
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket17Cost across the matching connection */
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket18Sale across the matching connection */
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket18Cost across the matching connection */
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket19Sale across the matching connection */
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket19Cost across the matching connection */
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket20Sale across the matching connection */
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of feeAftermarket20Cost across the matching connection */
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of insurance1Sale across the matching connection */
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of insurance1Cost across the matching connection */
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of insurance1Term across the matching connection */
  insurance1Term?: Maybe<Scalars['BigFloat']>;
  /** Mean average of insurance2Sale across the matching connection */
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of insurance2Cost across the matching connection */
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of insurance2Term across the matching connection */
  insurance2Term?: Maybe<Scalars['BigFloat']>;
  /** Mean average of insurance3Sale across the matching connection */
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of insurance3Cost across the matching connection */
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of insurance3Term across the matching connection */
  insurance3Term?: Maybe<Scalars['BigFloat']>;
  /** Mean average of accidentalHealthSale across the matching connection */
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of accidentalHealthCost across the matching connection */
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of accidentalHealthTerm across the matching connection */
  accidentalHealthTerm?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditLifeSale across the matching connection */
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditLifeCost across the matching connection */
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditLifeTerm across the matching connection */
  creditLifeTerm?: Maybe<Scalars['BigFloat']>;
  /** Mean average of levelizedLifeSale across the matching connection */
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of levelizedLifeCost across the matching connection */
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of levelizedLifeTerm across the matching connection */
  levelizedLifeTerm?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lossOfEmploymentSale across the matching connection */
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lossOfEmploymentCost across the matching connection */
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lossOfEmploymentTerm across the matching connection */
  lossOfEmploymentTerm?: Maybe<Scalars['BigFloat']>;
  /** Mean average of guaranteedAutoProtectionSale across the matching connection */
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  /** Mean average of guaranteedAutoProtectionCost across the matching connection */
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  /** Mean average of guaranteedAutoProtectionTerm across the matching connection */
  guaranteedAutoProtectionTerm?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Deal` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DealCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `fileType` field. */
  fileType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dvDealerId` field. */
  dvDealerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vendorDealerId` field. */
  vendorDealerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dmsType` field. */
  dmsType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dealNumber` field. */
  dealNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerNumber` field. */
  customerNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salutation` field. */
  salutation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `middleName` field. */
  middleName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `suffix` field. */
  suffix?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `addressLine1` field. */
  addressLine1?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `addressLine2` field. */
  addressLine2?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `zip` field. */
  zip?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `county` field. */
  county?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `homePhone` field. */
  homePhone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cellPhone` field. */
  cellPhone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `workPhone` field. */
  workPhone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `workExtension` field. */
  workExtension?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email1` field. */
  email1?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email2` field. */
  email2?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email3` field. */
  email3?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `birthDate` field. */
  birthDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `individualBusinessFlag` field. */
  individualBusinessFlag?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `optOut` field. */
  optOut?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `blockEmail` field. */
  blockEmail?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `blockPhone` field. */
  blockPhone?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `blockMail` field. */
  blockMail?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `language` field. */
  language?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerCreateDate` field. */
  customerCreateDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `customerLastActivityDate` field. */
  customerLastActivityDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `coBuyerCustomerNumber` field. */
  coBuyerCustomerNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerFullName` field. */
  coBuyerFullName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerSalutation` field. */
  coBuyerSalutation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerFirstName` field. */
  coBuyerFirstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerMiddleName` field. */
  coBuyerMiddleName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerLastName` field. */
  coBuyerLastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerSuffix` field. */
  coBuyerSuffix?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerAddressLine1` field. */
  coBuyerAddressLine1?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerAddressLine2` field. */
  coBuyerAddressLine2?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerCity` field. */
  coBuyerCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerState` field. */
  coBuyerState?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerZip` field. */
  coBuyerZip?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerCounty` field. */
  coBuyerCounty?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerHomePhone` field. */
  coBuyerHomePhone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerCellPhone` field. */
  coBuyerCellPhone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerWorkPhone` field. */
  coBuyerWorkPhone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerWorkExtension` field. */
  coBuyerWorkExtension?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerEmail1` field. */
  coBuyerEmail1?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerEmail2` field. */
  coBuyerEmail2?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerEmail3` field. */
  coBuyerEmail3?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerBirthDate` field. */
  coBuyerBirthDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `coBuyerIndividualBusinessFlag` field. */
  coBuyerIndividualBusinessFlag?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coBuyerOptOut` field. */
  coBuyerOptOut?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `coBuyerBlockEmail` field. */
  coBuyerBlockEmail?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `coBuyerBlockPhone` field. */
  coBuyerBlockPhone?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `coBuyerBlockMail` field. */
  coBuyerBlockMail?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `vin` field. */
  vin?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `make` field. */
  make?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `model` field. */
  model?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `modelNumber` field. */
  modelNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `mileage` field. */
  mileage?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `exteriorColor` field. */
  exteriorColor?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `newUsed` field. */
  newUsed?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `stockNumber` field. */
  stockNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `transmission` field. */
  transmission?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `engineConfiguration` field. */
  engineConfiguration?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `trim` field. */
  trim?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `engineNumber` field. */
  engineNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `chassisNumber` field. */
  chassisNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `licensePlateNumber` field. */
  licensePlateNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deliveryDate` field. */
  deliveryDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `deliveryMileage` field. */
  deliveryMileage?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `inventoryDate` field. */
  inventoryDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `inServiceDate` field. */
  inServiceDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `vinExplosionYear` field. */
  vinExplosionYear?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `vinExplosionMake` field. */
  vinExplosionMake?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vinExplosionModel` field. */
  vinExplosionModel?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vinExplosionTrim` field. */
  vinExplosionTrim?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vinExplosionTransmissionType` field. */
  vinExplosionTransmissionType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vinExplosionFuelType` field. */
  vinExplosionFuelType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vinExplosionEngineSize` field. */
  vinExplosionEngineSize?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vinExplosionGvwRange` field. */
  vinExplosionGvwRange?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `trade1Vin` field. */
  trade1Vin?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `trade1Year` field. */
  trade1Year?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `trade1Make` field. */
  trade1Make?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `trade1Model` field. */
  trade1Model?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `trade1Odometer` field. */
  trade1Odometer?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `trade1ActualCashValue` field. */
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `trade1Gross` field. */
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `trade1Payoff` field. */
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `trade2Vin` field. */
  trade2Vin?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `trade2Year` field. */
  trade2Year?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `trade2Make` field. */
  trade2Make?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `trade2Model` field. */
  trade2Model?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `trade2Odometer` field. */
  trade2Odometer?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `trade2ActualCashValue` field. */
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `trade2Gross` field. */
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `trade2Payoff` field. */
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `salesman1Number` field. */
  salesman1Number?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salesman1Name` field. */
  salesman1Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salesman2Number` field. */
  salesman2Number?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salesman2Name` field. */
  salesman2Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salesman3Number` field. */
  salesman3Number?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salesman3Name` field. */
  salesman3Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `closingManagerNumber` field. */
  closingManagerNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `closingManagerName` field. */
  closingManagerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `financeManagerNumber` field. */
  financeManagerNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `financeManagerName` field. */
  financeManagerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salesmanManagerNumber` field. */
  salesmanManagerNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salesmanManagerName` field. */
  salesmanManagerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `msrp` field. */
  msrp?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `listPrice` field. */
  listPrice?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `salesPrice` field. */
  salesPrice?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `journalPrice` field. */
  journalPrice?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `cost` field. */
  cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `journalCost` field. */
  journalCost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `adjustments` field. */
  adjustments?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `adjustedCost` field. */
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `incentives` field. */
  incentives?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `packAmount` field. */
  packAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `saleNet` field. */
  saleNet?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalTradeActualCashValue` field. */
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalTradeGross` field. */
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `weOweFront` field. */
  weOweFront?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalFrontFeesAftermarketProfit` field. */
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalFrontCommission` field. */
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalFrontSales` field. */
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalFrontCost` field. */
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `frontGross` field. */
  frontGross?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `financeProfit` field. */
  financeProfit?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalWarrantyProfit` field. */
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `weOweBack` field. */
  weOweBack?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `insuranceProfit` field. */
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalBackFeesAftermarketProfit` field. */
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `financeReserve` field. */
  financeReserve?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalBackCommission` field. */
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalBackSales` field. */
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalBackCost` field. */
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `backGross` field. */
  backGross?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalProfit` field. */
  totalProfit?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `grossProfit` field. */
  grossProfit?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `grossPayable` field. */
  grossPayable?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `dealStatus` field. */
  dealStatus?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `entryDate` field. */
  entryDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `bookedDate` field. */
  bookedDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `finalizedDate` field. */
  finalizedDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `contractDate` field. */
  contractDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `accountingDate` field. */
  accountingDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `statusChangeDate` field. */
  statusChangeDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `firstPayDate` field. */
  firstPayDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `dealType` field. */
  dealType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `saleType` field. */
  saleType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bankId` field. */
  bankId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bankName` field. */
  bankName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bankAddress` field. */
  bankAddress?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `term` field. */
  term?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `amountFinanced` field. */
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `apr` field. */
  apr?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `monthlyPayment` field. */
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `paymentTotal` field. */
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `rebates` field. */
  rebates?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `deposit` field. */
  deposit?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `downPayment` field. */
  downPayment?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalNetTrades` field. */
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalDown` field. */
  totalDown?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `balloonAmount` field. */
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `adjustedBalloonAmount` field. */
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `holdbackAmount` field. */
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalDriveOffAmount` field. */
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `licenseFee` field. */
  licenseFee?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `registrationFee` field. */
  registrationFee?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `documentationFee` field. */
  documentationFee?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `financeCharge` field. */
  financeCharge?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalPickupPayments` field. */
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `sellRate` field. */
  sellRate?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `buyRate` field. */
  buyRate?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `residualRate` field. */
  residualRate?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `residualAmount` field. */
  residualAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `allowedMiles` field. */
  allowedMiles?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `estimatedMiles` field. */
  estimatedMiles?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `mileageRate` field. */
  mileageRate?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `acquisitionFee` field. */
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `basePayment` field. */
  basePayment?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `securityDeposit` field. */
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalCapitalReduction` field. */
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `netCapitalCost` field. */
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `leaseDepreciationValue` field. */
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `dealerFees` field. */
  dealerFees?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `governmentFees` field. */
  governmentFees?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalTax` field. */
  totalTax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `registrationState` field. */
  registrationState?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `reportOfSaleNumber` field. */
  reportOfSaleNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salesman1TotalCommission` field. */
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `salesman1FrontCommission` field. */
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `salesman1BackCommission` field. */
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `salesman2TotalCommission` field. */
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `salesman2FrontCommission` field. */
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `salesman2BackCommission` field. */
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `salesman3TotalCommission` field. */
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `salesman3FrontCommission` field. */
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `salesman3BackCommission` field. */
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `warranty1Name` field. */
  warranty1Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `warranty1Sale` field. */
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `warranty1Cost` field. */
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `warranty1Miles` field. */
  warranty1Miles?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `warranty1Term` field. */
  warranty1Term?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `warranty2Name` field. */
  warranty2Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `warranty2Sale` field. */
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `warranty2Cost` field. */
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `warranty2Miles` field. */
  warranty2Miles?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `warranty2Term` field. */
  warranty2Term?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `warranty3Name` field. */
  warranty3Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `warranty3Sale` field. */
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `warranty3Cost` field. */
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `warranty3Miles` field. */
  warranty3Miles?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `warranty3Term` field. */
  warranty3Term?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `warranty4Name` field. */
  warranty4Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `warranty4Sale` field. */
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `warranty4Cost` field. */
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `warranty4Miles` field. */
  warranty4Miles?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `warranty4Term` field. */
  warranty4Term?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `warranty5Name` field. */
  warranty5Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `warranty5Sale` field. */
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `warranty5Cost` field. */
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `warranty5Miles` field. */
  warranty5Miles?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `warranty5Term` field. */
  warranty5Term?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `totalFeeAftermarketSale` field. */
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalFeeAftermarketCost` field. */
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket1Name` field. */
  feeAftermarket1Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket1Sale` field. */
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket1Cost` field. */
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket1ProfitIndicator` field. */
  feeAftermarket1ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket2Name` field. */
  feeAftermarket2Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket2Sale` field. */
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket2Cost` field. */
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket2ProfitIndicator` field. */
  feeAftermarket2ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket3Name` field. */
  feeAftermarket3Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket3Sale` field. */
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket3Cost` field. */
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket3ProfitIndicator` field. */
  feeAftermarket3ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket4Name` field. */
  feeAftermarket4Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket4Sale` field. */
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket4Cost` field. */
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket4ProfitIndicator` field. */
  feeAftermarket4ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket5Name` field. */
  feeAftermarket5Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket5Sale` field. */
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket5Cost` field. */
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket5ProfitIndicator` field. */
  feeAftermarket5ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket6Name` field. */
  feeAftermarket6Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket6Sale` field. */
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket6Cost` field. */
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket6ProfitIndicator` field. */
  feeAftermarket6ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket7Name` field. */
  feeAftermarket7Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket7Sale` field. */
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket7Cost` field. */
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket7ProfitIndicator` field. */
  feeAftermarket7ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket8Name` field. */
  feeAftermarket8Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket8Sale` field. */
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket8Cost` field. */
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket8ProfitIndicator` field. */
  feeAftermarket8ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket9Name` field. */
  feeAftermarket9Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket9Sale` field. */
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket9Cost` field. */
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket9ProfitIndicator` field. */
  feeAftermarket9ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket10Name` field. */
  feeAftermarket10Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket10Sale` field. */
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket10Cost` field. */
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket10ProfitIndicator` field. */
  feeAftermarket10ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket11Name` field. */
  feeAftermarket11Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket11Sale` field. */
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket11Cost` field. */
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket11ProfitIndicator` field. */
  feeAftermarket11ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket12Name` field. */
  feeAftermarket12Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket12Sale` field. */
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket12Cost` field. */
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket12ProfitIndicator` field. */
  feeAftermarket12ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket13Name` field. */
  feeAftermarket13Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket13Sale` field. */
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket13Cost` field. */
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket13ProfitIndicator` field. */
  feeAftermarket13ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket14Name` field. */
  feeAftermarket14Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket14Sale` field. */
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket14Cost` field. */
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket14ProfitIndicator` field. */
  feeAftermarket14ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket15Name` field. */
  feeAftermarket15Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket15Sale` field. */
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket15Cost` field. */
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket15ProfitIndicator` field. */
  feeAftermarket15ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket16Name` field. */
  feeAftermarket16Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket16Sale` field. */
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket16Cost` field. */
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket16ProfitIndicator` field. */
  feeAftermarket16ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket17Name` field. */
  feeAftermarket17Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket17Sale` field. */
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket17Cost` field. */
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket17ProfitIndicator` field. */
  feeAftermarket17ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket18Name` field. */
  feeAftermarket18Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket18Sale` field. */
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket18Cost` field. */
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket18ProfitIndicator` field. */
  feeAftermarket18ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket19Name` field. */
  feeAftermarket19Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket19Sale` field. */
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket19Cost` field. */
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket19ProfitIndicator` field. */
  feeAftermarket19ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `feeAftermarket20Name` field. */
  feeAftermarket20Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeAftermarket20Sale` field. */
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket20Cost` field. */
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeAftermarket20ProfitIndicator` field. */
  feeAftermarket20ProfitIndicator?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `insurance1Type` field. */
  insurance1Type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `insurance1Name` field. */
  insurance1Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `insurance1Sale` field. */
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `insurance1Cost` field. */
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `insurance1Term` field. */
  insurance1Term?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `insurance2Type` field. */
  insurance2Type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `insurance2Name` field. */
  insurance2Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `insurance2Sale` field. */
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `insurance2Cost` field. */
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `insurance2Term` field. */
  insurance2Term?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `insurance3Type` field. */
  insurance3Type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `insurance3Name` field. */
  insurance3Name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `insurance3Sale` field. */
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `insurance3Cost` field. */
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `insurance3Term` field. */
  insurance3Term?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `accidentalHealthType` field. */
  accidentalHealthType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `accidentalHealthName` field. */
  accidentalHealthName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `accidentalHealthSale` field. */
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `accidentalHealthCost` field. */
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `accidentalHealthTerm` field. */
  accidentalHealthTerm?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `creditLifeType` field. */
  creditLifeType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `creditLifeName` field. */
  creditLifeName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `creditLifeSale` field. */
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `creditLifeCost` field. */
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `creditLifeTerm` field. */
  creditLifeTerm?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `levelizedLifeType` field. */
  levelizedLifeType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `levelizedLifeName` field. */
  levelizedLifeName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `levelizedLifeSale` field. */
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `levelizedLifeCost` field. */
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `levelizedLifeTerm` field. */
  levelizedLifeTerm?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lossOfEmploymentType` field. */
  lossOfEmploymentType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lossOfEmploymentName` field. */
  lossOfEmploymentName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lossOfEmploymentSale` field. */
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `lossOfEmploymentCost` field. */
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `lossOfEmploymentTerm` field. */
  lossOfEmploymentTerm?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `guaranteedAutoProtectionType` field. */
  guaranteedAutoProtectionType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `guaranteedAutoProtectionName` field. */
  guaranteedAutoProtectionName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `guaranteedAutoProtectionSale` field. */
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `guaranteedAutoProtectionCost` field. */
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `guaranteedAutoProtectionTerm` field. */
  guaranteedAutoProtectionTerm?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `saleComments` field. */
  saleComments?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdLine1` field. */
  cassStdLine1?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdLine2` field. */
  cassStdLine2?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdCity` field. */
  cassStdCity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdState` field. */
  cassStdState?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdZip` field. */
  cassStdZip?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdZip4` field. */
  cassStdZip4?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdDpbc` field. */
  cassStdDpbc?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdChkdgt` field. */
  cassStdChkdgt?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdCart` field. */
  cassStdCart?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdLot` field. */
  cassStdLot?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdLotord` field. */
  cassStdLotord?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdUrb` field. */
  cassStdUrb?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdFips` field. */
  cassStdFips?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdEws` field. */
  cassStdEws?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdLacs` field. */
  cassStdLacs?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdZipmov` field. */
  cassStdZipmov?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdZ4Lom` field. */
  cassStdZ4Lom?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdNdiapt` field. */
  cassStdNdiapt?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdNdirr` field. */
  cassStdNdirr?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdLacsrt` field. */
  cassStdLacsrt?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cassStdErrorCd` field. */
  cassStdErrorCd?: Maybe<Scalars['String']>;
};

/** The fields on `deal` to look up the row to connect. */
export type DealDealsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `deal` to look up the row to delete. */
export type DealDealsPkeyDelete = {
  id: Scalars['Int'];
};

export type DealDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  fileType?: Maybe<BigIntFilter>;
  dvDealerId?: Maybe<BigIntFilter>;
  vendorDealerId?: Maybe<BigIntFilter>;
  dmsType?: Maybe<BigIntFilter>;
  dealNumber?: Maybe<BigIntFilter>;
  customerNumber?: Maybe<BigIntFilter>;
  fullName?: Maybe<BigIntFilter>;
  salutation?: Maybe<BigIntFilter>;
  firstName?: Maybe<BigIntFilter>;
  middleName?: Maybe<BigIntFilter>;
  lastName?: Maybe<BigIntFilter>;
  suffix?: Maybe<BigIntFilter>;
  addressLine1?: Maybe<BigIntFilter>;
  addressLine2?: Maybe<BigIntFilter>;
  city?: Maybe<BigIntFilter>;
  state?: Maybe<BigIntFilter>;
  zip?: Maybe<BigIntFilter>;
  county?: Maybe<BigIntFilter>;
  homePhone?: Maybe<BigIntFilter>;
  cellPhone?: Maybe<BigIntFilter>;
  workPhone?: Maybe<BigIntFilter>;
  workExtension?: Maybe<BigIntFilter>;
  email1?: Maybe<BigIntFilter>;
  email2?: Maybe<BigIntFilter>;
  email3?: Maybe<BigIntFilter>;
  birthDate?: Maybe<BigIntFilter>;
  individualBusinessFlag?: Maybe<BigIntFilter>;
  optOut?: Maybe<BigIntFilter>;
  blockEmail?: Maybe<BigIntFilter>;
  blockPhone?: Maybe<BigIntFilter>;
  blockMail?: Maybe<BigIntFilter>;
  language?: Maybe<BigIntFilter>;
  customerCreateDate?: Maybe<BigIntFilter>;
  customerLastActivityDate?: Maybe<BigIntFilter>;
  coBuyerCustomerNumber?: Maybe<BigIntFilter>;
  coBuyerFullName?: Maybe<BigIntFilter>;
  coBuyerSalutation?: Maybe<BigIntFilter>;
  coBuyerFirstName?: Maybe<BigIntFilter>;
  coBuyerMiddleName?: Maybe<BigIntFilter>;
  coBuyerLastName?: Maybe<BigIntFilter>;
  coBuyerSuffix?: Maybe<BigIntFilter>;
  coBuyerAddressLine1?: Maybe<BigIntFilter>;
  coBuyerAddressLine2?: Maybe<BigIntFilter>;
  coBuyerCity?: Maybe<BigIntFilter>;
  coBuyerState?: Maybe<BigIntFilter>;
  coBuyerZip?: Maybe<BigIntFilter>;
  coBuyerCounty?: Maybe<BigIntFilter>;
  coBuyerHomePhone?: Maybe<BigIntFilter>;
  coBuyerCellPhone?: Maybe<BigIntFilter>;
  coBuyerWorkPhone?: Maybe<BigIntFilter>;
  coBuyerWorkExtension?: Maybe<BigIntFilter>;
  coBuyerEmail1?: Maybe<BigIntFilter>;
  coBuyerEmail2?: Maybe<BigIntFilter>;
  coBuyerEmail3?: Maybe<BigIntFilter>;
  coBuyerBirthDate?: Maybe<BigIntFilter>;
  coBuyerIndividualBusinessFlag?: Maybe<BigIntFilter>;
  coBuyerOptOut?: Maybe<BigIntFilter>;
  coBuyerBlockEmail?: Maybe<BigIntFilter>;
  coBuyerBlockPhone?: Maybe<BigIntFilter>;
  coBuyerBlockMail?: Maybe<BigIntFilter>;
  vin?: Maybe<BigIntFilter>;
  year?: Maybe<BigIntFilter>;
  make?: Maybe<BigIntFilter>;
  model?: Maybe<BigIntFilter>;
  modelNumber?: Maybe<BigIntFilter>;
  mileage?: Maybe<BigIntFilter>;
  description?: Maybe<BigIntFilter>;
  exteriorColor?: Maybe<BigIntFilter>;
  newUsed?: Maybe<BigIntFilter>;
  stockNumber?: Maybe<BigIntFilter>;
  transmission?: Maybe<BigIntFilter>;
  engineConfiguration?: Maybe<BigIntFilter>;
  trim?: Maybe<BigIntFilter>;
  engineNumber?: Maybe<BigIntFilter>;
  chassisNumber?: Maybe<BigIntFilter>;
  licensePlateNumber?: Maybe<BigIntFilter>;
  deliveryDate?: Maybe<BigIntFilter>;
  deliveryMileage?: Maybe<BigIntFilter>;
  inventoryDate?: Maybe<BigIntFilter>;
  inServiceDate?: Maybe<BigIntFilter>;
  vinExplosionYear?: Maybe<BigIntFilter>;
  vinExplosionMake?: Maybe<BigIntFilter>;
  vinExplosionModel?: Maybe<BigIntFilter>;
  vinExplosionTrim?: Maybe<BigIntFilter>;
  vinExplosionTransmissionType?: Maybe<BigIntFilter>;
  vinExplosionFuelType?: Maybe<BigIntFilter>;
  vinExplosionEngineSize?: Maybe<BigIntFilter>;
  vinExplosionGvwRange?: Maybe<BigIntFilter>;
  trade1Vin?: Maybe<BigIntFilter>;
  trade1Year?: Maybe<BigIntFilter>;
  trade1Make?: Maybe<BigIntFilter>;
  trade1Model?: Maybe<BigIntFilter>;
  trade1Odometer?: Maybe<BigIntFilter>;
  trade1ActualCashValue?: Maybe<BigIntFilter>;
  trade1Gross?: Maybe<BigIntFilter>;
  trade1Payoff?: Maybe<BigIntFilter>;
  trade2Vin?: Maybe<BigIntFilter>;
  trade2Year?: Maybe<BigIntFilter>;
  trade2Make?: Maybe<BigIntFilter>;
  trade2Model?: Maybe<BigIntFilter>;
  trade2Odometer?: Maybe<BigIntFilter>;
  trade2ActualCashValue?: Maybe<BigIntFilter>;
  trade2Gross?: Maybe<BigIntFilter>;
  trade2Payoff?: Maybe<BigIntFilter>;
  salesman1Number?: Maybe<BigIntFilter>;
  salesman1Name?: Maybe<BigIntFilter>;
  salesman2Number?: Maybe<BigIntFilter>;
  salesman2Name?: Maybe<BigIntFilter>;
  salesman3Number?: Maybe<BigIntFilter>;
  salesman3Name?: Maybe<BigIntFilter>;
  closingManagerNumber?: Maybe<BigIntFilter>;
  closingManagerName?: Maybe<BigIntFilter>;
  financeManagerNumber?: Maybe<BigIntFilter>;
  financeManagerName?: Maybe<BigIntFilter>;
  salesmanManagerNumber?: Maybe<BigIntFilter>;
  salesmanManagerName?: Maybe<BigIntFilter>;
  msrp?: Maybe<BigIntFilter>;
  listPrice?: Maybe<BigIntFilter>;
  salesPrice?: Maybe<BigIntFilter>;
  journalPrice?: Maybe<BigIntFilter>;
  cost?: Maybe<BigIntFilter>;
  journalCost?: Maybe<BigIntFilter>;
  adjustments?: Maybe<BigIntFilter>;
  adjustedCost?: Maybe<BigIntFilter>;
  incentives?: Maybe<BigIntFilter>;
  packAmount?: Maybe<BigIntFilter>;
  saleNet?: Maybe<BigIntFilter>;
  totalTradeActualCashValue?: Maybe<BigIntFilter>;
  totalTradeGross?: Maybe<BigIntFilter>;
  weOweFront?: Maybe<BigIntFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<BigIntFilter>;
  totalFrontCommission?: Maybe<BigIntFilter>;
  totalFrontSales?: Maybe<BigIntFilter>;
  totalFrontCost?: Maybe<BigIntFilter>;
  frontGross?: Maybe<BigIntFilter>;
  financeProfit?: Maybe<BigIntFilter>;
  totalWarrantyProfit?: Maybe<BigIntFilter>;
  weOweBack?: Maybe<BigIntFilter>;
  insuranceProfit?: Maybe<BigIntFilter>;
  totalBackFeesAftermarketProfit?: Maybe<BigIntFilter>;
  financeReserve?: Maybe<BigIntFilter>;
  totalBackCommission?: Maybe<BigIntFilter>;
  totalBackSales?: Maybe<BigIntFilter>;
  totalBackCost?: Maybe<BigIntFilter>;
  backGross?: Maybe<BigIntFilter>;
  totalProfit?: Maybe<BigIntFilter>;
  grossProfit?: Maybe<BigIntFilter>;
  grossPayable?: Maybe<BigIntFilter>;
  dealStatus?: Maybe<BigIntFilter>;
  entryDate?: Maybe<BigIntFilter>;
  bookedDate?: Maybe<BigIntFilter>;
  finalizedDate?: Maybe<BigIntFilter>;
  contractDate?: Maybe<BigIntFilter>;
  accountingDate?: Maybe<BigIntFilter>;
  statusChangeDate?: Maybe<BigIntFilter>;
  firstPayDate?: Maybe<BigIntFilter>;
  dealType?: Maybe<BigIntFilter>;
  saleType?: Maybe<BigIntFilter>;
  bankId?: Maybe<BigIntFilter>;
  bankName?: Maybe<BigIntFilter>;
  bankAddress?: Maybe<BigIntFilter>;
  term?: Maybe<BigIntFilter>;
  amountFinanced?: Maybe<BigIntFilter>;
  apr?: Maybe<BigIntFilter>;
  monthlyPayment?: Maybe<BigIntFilter>;
  paymentTotal?: Maybe<BigIntFilter>;
  rebates?: Maybe<BigIntFilter>;
  deposit?: Maybe<BigIntFilter>;
  downPayment?: Maybe<BigIntFilter>;
  totalNetTrades?: Maybe<BigIntFilter>;
  totalDown?: Maybe<BigIntFilter>;
  balloonAmount?: Maybe<BigIntFilter>;
  adjustedBalloonAmount?: Maybe<BigIntFilter>;
  holdbackAmount?: Maybe<BigIntFilter>;
  totalDriveOffAmount?: Maybe<BigIntFilter>;
  licenseFee?: Maybe<BigIntFilter>;
  registrationFee?: Maybe<BigIntFilter>;
  documentationFee?: Maybe<BigIntFilter>;
  financeCharge?: Maybe<BigIntFilter>;
  totalPickupPayments?: Maybe<BigIntFilter>;
  sellRate?: Maybe<BigIntFilter>;
  buyRate?: Maybe<BigIntFilter>;
  residualRate?: Maybe<BigIntFilter>;
  residualAmount?: Maybe<BigIntFilter>;
  allowedMiles?: Maybe<BigIntFilter>;
  estimatedMiles?: Maybe<BigIntFilter>;
  mileageRate?: Maybe<BigIntFilter>;
  acquisitionFee?: Maybe<BigIntFilter>;
  basePayment?: Maybe<BigIntFilter>;
  securityDeposit?: Maybe<BigIntFilter>;
  totalCapitalReduction?: Maybe<BigIntFilter>;
  netCapitalCost?: Maybe<BigIntFilter>;
  leaseDepreciationValue?: Maybe<BigIntFilter>;
  dealerFees?: Maybe<BigIntFilter>;
  governmentFees?: Maybe<BigIntFilter>;
  totalTax?: Maybe<BigIntFilter>;
  registrationState?: Maybe<BigIntFilter>;
  reportOfSaleNumber?: Maybe<BigIntFilter>;
  salesman1TotalCommission?: Maybe<BigIntFilter>;
  salesman1FrontCommission?: Maybe<BigIntFilter>;
  salesman1BackCommission?: Maybe<BigIntFilter>;
  salesman2TotalCommission?: Maybe<BigIntFilter>;
  salesman2FrontCommission?: Maybe<BigIntFilter>;
  salesman2BackCommission?: Maybe<BigIntFilter>;
  salesman3TotalCommission?: Maybe<BigIntFilter>;
  salesman3FrontCommission?: Maybe<BigIntFilter>;
  salesman3BackCommission?: Maybe<BigIntFilter>;
  warranty1Name?: Maybe<BigIntFilter>;
  warranty1Sale?: Maybe<BigIntFilter>;
  warranty1Cost?: Maybe<BigIntFilter>;
  warranty1Miles?: Maybe<BigIntFilter>;
  warranty1Term?: Maybe<BigIntFilter>;
  warranty2Name?: Maybe<BigIntFilter>;
  warranty2Sale?: Maybe<BigIntFilter>;
  warranty2Cost?: Maybe<BigIntFilter>;
  warranty2Miles?: Maybe<BigIntFilter>;
  warranty2Term?: Maybe<BigIntFilter>;
  warranty3Name?: Maybe<BigIntFilter>;
  warranty3Sale?: Maybe<BigIntFilter>;
  warranty3Cost?: Maybe<BigIntFilter>;
  warranty3Miles?: Maybe<BigIntFilter>;
  warranty3Term?: Maybe<BigIntFilter>;
  warranty4Name?: Maybe<BigIntFilter>;
  warranty4Sale?: Maybe<BigIntFilter>;
  warranty4Cost?: Maybe<BigIntFilter>;
  warranty4Miles?: Maybe<BigIntFilter>;
  warranty4Term?: Maybe<BigIntFilter>;
  warranty5Name?: Maybe<BigIntFilter>;
  warranty5Sale?: Maybe<BigIntFilter>;
  warranty5Cost?: Maybe<BigIntFilter>;
  warranty5Miles?: Maybe<BigIntFilter>;
  warranty5Term?: Maybe<BigIntFilter>;
  totalFeeAftermarketSale?: Maybe<BigIntFilter>;
  totalFeeAftermarketCost?: Maybe<BigIntFilter>;
  feeAftermarket1Name?: Maybe<BigIntFilter>;
  feeAftermarket1Sale?: Maybe<BigIntFilter>;
  feeAftermarket1Cost?: Maybe<BigIntFilter>;
  feeAftermarket1ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket2Name?: Maybe<BigIntFilter>;
  feeAftermarket2Sale?: Maybe<BigIntFilter>;
  feeAftermarket2Cost?: Maybe<BigIntFilter>;
  feeAftermarket2ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket3Name?: Maybe<BigIntFilter>;
  feeAftermarket3Sale?: Maybe<BigIntFilter>;
  feeAftermarket3Cost?: Maybe<BigIntFilter>;
  feeAftermarket3ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket4Name?: Maybe<BigIntFilter>;
  feeAftermarket4Sale?: Maybe<BigIntFilter>;
  feeAftermarket4Cost?: Maybe<BigIntFilter>;
  feeAftermarket4ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket5Name?: Maybe<BigIntFilter>;
  feeAftermarket5Sale?: Maybe<BigIntFilter>;
  feeAftermarket5Cost?: Maybe<BigIntFilter>;
  feeAftermarket5ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket6Name?: Maybe<BigIntFilter>;
  feeAftermarket6Sale?: Maybe<BigIntFilter>;
  feeAftermarket6Cost?: Maybe<BigIntFilter>;
  feeAftermarket6ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket7Name?: Maybe<BigIntFilter>;
  feeAftermarket7Sale?: Maybe<BigIntFilter>;
  feeAftermarket7Cost?: Maybe<BigIntFilter>;
  feeAftermarket7ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket8Name?: Maybe<BigIntFilter>;
  feeAftermarket8Sale?: Maybe<BigIntFilter>;
  feeAftermarket8Cost?: Maybe<BigIntFilter>;
  feeAftermarket8ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket9Name?: Maybe<BigIntFilter>;
  feeAftermarket9Sale?: Maybe<BigIntFilter>;
  feeAftermarket9Cost?: Maybe<BigIntFilter>;
  feeAftermarket9ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket10Name?: Maybe<BigIntFilter>;
  feeAftermarket10Sale?: Maybe<BigIntFilter>;
  feeAftermarket10Cost?: Maybe<BigIntFilter>;
  feeAftermarket10ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket11Name?: Maybe<BigIntFilter>;
  feeAftermarket11Sale?: Maybe<BigIntFilter>;
  feeAftermarket11Cost?: Maybe<BigIntFilter>;
  feeAftermarket11ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket12Name?: Maybe<BigIntFilter>;
  feeAftermarket12Sale?: Maybe<BigIntFilter>;
  feeAftermarket12Cost?: Maybe<BigIntFilter>;
  feeAftermarket12ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket13Name?: Maybe<BigIntFilter>;
  feeAftermarket13Sale?: Maybe<BigIntFilter>;
  feeAftermarket13Cost?: Maybe<BigIntFilter>;
  feeAftermarket13ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket14Name?: Maybe<BigIntFilter>;
  feeAftermarket14Sale?: Maybe<BigIntFilter>;
  feeAftermarket14Cost?: Maybe<BigIntFilter>;
  feeAftermarket14ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket15Name?: Maybe<BigIntFilter>;
  feeAftermarket15Sale?: Maybe<BigIntFilter>;
  feeAftermarket15Cost?: Maybe<BigIntFilter>;
  feeAftermarket15ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket16Name?: Maybe<BigIntFilter>;
  feeAftermarket16Sale?: Maybe<BigIntFilter>;
  feeAftermarket16Cost?: Maybe<BigIntFilter>;
  feeAftermarket16ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket17Name?: Maybe<BigIntFilter>;
  feeAftermarket17Sale?: Maybe<BigIntFilter>;
  feeAftermarket17Cost?: Maybe<BigIntFilter>;
  feeAftermarket17ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket18Name?: Maybe<BigIntFilter>;
  feeAftermarket18Sale?: Maybe<BigIntFilter>;
  feeAftermarket18Cost?: Maybe<BigIntFilter>;
  feeAftermarket18ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket19Name?: Maybe<BigIntFilter>;
  feeAftermarket19Sale?: Maybe<BigIntFilter>;
  feeAftermarket19Cost?: Maybe<BigIntFilter>;
  feeAftermarket19ProfitIndicator?: Maybe<BigIntFilter>;
  feeAftermarket20Name?: Maybe<BigIntFilter>;
  feeAftermarket20Sale?: Maybe<BigIntFilter>;
  feeAftermarket20Cost?: Maybe<BigIntFilter>;
  feeAftermarket20ProfitIndicator?: Maybe<BigIntFilter>;
  insurance1Type?: Maybe<BigIntFilter>;
  insurance1Name?: Maybe<BigIntFilter>;
  insurance1Sale?: Maybe<BigIntFilter>;
  insurance1Cost?: Maybe<BigIntFilter>;
  insurance1Term?: Maybe<BigIntFilter>;
  insurance2Type?: Maybe<BigIntFilter>;
  insurance2Name?: Maybe<BigIntFilter>;
  insurance2Sale?: Maybe<BigIntFilter>;
  insurance2Cost?: Maybe<BigIntFilter>;
  insurance2Term?: Maybe<BigIntFilter>;
  insurance3Type?: Maybe<BigIntFilter>;
  insurance3Name?: Maybe<BigIntFilter>;
  insurance3Sale?: Maybe<BigIntFilter>;
  insurance3Cost?: Maybe<BigIntFilter>;
  insurance3Term?: Maybe<BigIntFilter>;
  accidentalHealthType?: Maybe<BigIntFilter>;
  accidentalHealthName?: Maybe<BigIntFilter>;
  accidentalHealthSale?: Maybe<BigIntFilter>;
  accidentalHealthCost?: Maybe<BigIntFilter>;
  accidentalHealthTerm?: Maybe<BigIntFilter>;
  creditLifeType?: Maybe<BigIntFilter>;
  creditLifeName?: Maybe<BigIntFilter>;
  creditLifeSale?: Maybe<BigIntFilter>;
  creditLifeCost?: Maybe<BigIntFilter>;
  creditLifeTerm?: Maybe<BigIntFilter>;
  levelizedLifeType?: Maybe<BigIntFilter>;
  levelizedLifeName?: Maybe<BigIntFilter>;
  levelizedLifeSale?: Maybe<BigIntFilter>;
  levelizedLifeCost?: Maybe<BigIntFilter>;
  levelizedLifeTerm?: Maybe<BigIntFilter>;
  lossOfEmploymentType?: Maybe<BigIntFilter>;
  lossOfEmploymentName?: Maybe<BigIntFilter>;
  lossOfEmploymentSale?: Maybe<BigIntFilter>;
  lossOfEmploymentCost?: Maybe<BigIntFilter>;
  lossOfEmploymentTerm?: Maybe<BigIntFilter>;
  guaranteedAutoProtectionType?: Maybe<BigIntFilter>;
  guaranteedAutoProtectionName?: Maybe<BigIntFilter>;
  guaranteedAutoProtectionSale?: Maybe<BigIntFilter>;
  guaranteedAutoProtectionCost?: Maybe<BigIntFilter>;
  guaranteedAutoProtectionTerm?: Maybe<BigIntFilter>;
  saleComments?: Maybe<BigIntFilter>;
  cassStdLine1?: Maybe<BigIntFilter>;
  cassStdLine2?: Maybe<BigIntFilter>;
  cassStdCity?: Maybe<BigIntFilter>;
  cassStdState?: Maybe<BigIntFilter>;
  cassStdZip?: Maybe<BigIntFilter>;
  cassStdZip4?: Maybe<BigIntFilter>;
  cassStdDpbc?: Maybe<BigIntFilter>;
  cassStdChkdgt?: Maybe<BigIntFilter>;
  cassStdCart?: Maybe<BigIntFilter>;
  cassStdLot?: Maybe<BigIntFilter>;
  cassStdLotord?: Maybe<BigIntFilter>;
  cassStdUrb?: Maybe<BigIntFilter>;
  cassStdFips?: Maybe<BigIntFilter>;
  cassStdEws?: Maybe<BigIntFilter>;
  cassStdLacs?: Maybe<BigIntFilter>;
  cassStdZipmov?: Maybe<BigIntFilter>;
  cassStdZ4Lom?: Maybe<BigIntFilter>;
  cassStdNdiapt?: Maybe<BigIntFilter>;
  cassStdNdirr?: Maybe<BigIntFilter>;
  cassStdLacsrt?: Maybe<BigIntFilter>;
  cassStdErrorCd?: Maybe<BigIntFilter>;
};

export type DealDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fileType across the matching connection */
  fileType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dvDealerId across the matching connection */
  dvDealerId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vendorDealerId across the matching connection */
  vendorDealerId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dmsType across the matching connection */
  dmsType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dealNumber across the matching connection */
  dealNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of customerNumber across the matching connection */
  customerNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fullName across the matching connection */
  fullName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salutation across the matching connection */
  salutation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of middleName across the matching connection */
  middleName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of suffix across the matching connection */
  suffix?: Maybe<Scalars['BigInt']>;
  /** Distinct count of addressLine1 across the matching connection */
  addressLine1?: Maybe<Scalars['BigInt']>;
  /** Distinct count of addressLine2 across the matching connection */
  addressLine2?: Maybe<Scalars['BigInt']>;
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars['BigInt']>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars['BigInt']>;
  /** Distinct count of zip across the matching connection */
  zip?: Maybe<Scalars['BigInt']>;
  /** Distinct count of county across the matching connection */
  county?: Maybe<Scalars['BigInt']>;
  /** Distinct count of homePhone across the matching connection */
  homePhone?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cellPhone across the matching connection */
  cellPhone?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workPhone across the matching connection */
  workPhone?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workExtension across the matching connection */
  workExtension?: Maybe<Scalars['BigInt']>;
  /** Distinct count of email1 across the matching connection */
  email1?: Maybe<Scalars['BigInt']>;
  /** Distinct count of email2 across the matching connection */
  email2?: Maybe<Scalars['BigInt']>;
  /** Distinct count of email3 across the matching connection */
  email3?: Maybe<Scalars['BigInt']>;
  /** Distinct count of birthDate across the matching connection */
  birthDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of individualBusinessFlag across the matching connection */
  individualBusinessFlag?: Maybe<Scalars['BigInt']>;
  /** Distinct count of optOut across the matching connection */
  optOut?: Maybe<Scalars['BigInt']>;
  /** Distinct count of blockEmail across the matching connection */
  blockEmail?: Maybe<Scalars['BigInt']>;
  /** Distinct count of blockPhone across the matching connection */
  blockPhone?: Maybe<Scalars['BigInt']>;
  /** Distinct count of blockMail across the matching connection */
  blockMail?: Maybe<Scalars['BigInt']>;
  /** Distinct count of language across the matching connection */
  language?: Maybe<Scalars['BigInt']>;
  /** Distinct count of customerCreateDate across the matching connection */
  customerCreateDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of customerLastActivityDate across the matching connection */
  customerLastActivityDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerCustomerNumber across the matching connection */
  coBuyerCustomerNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerFullName across the matching connection */
  coBuyerFullName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerSalutation across the matching connection */
  coBuyerSalutation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerFirstName across the matching connection */
  coBuyerFirstName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerMiddleName across the matching connection */
  coBuyerMiddleName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerLastName across the matching connection */
  coBuyerLastName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerSuffix across the matching connection */
  coBuyerSuffix?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerAddressLine1 across the matching connection */
  coBuyerAddressLine1?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerAddressLine2 across the matching connection */
  coBuyerAddressLine2?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerCity across the matching connection */
  coBuyerCity?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerState across the matching connection */
  coBuyerState?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerZip across the matching connection */
  coBuyerZip?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerCounty across the matching connection */
  coBuyerCounty?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerHomePhone across the matching connection */
  coBuyerHomePhone?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerCellPhone across the matching connection */
  coBuyerCellPhone?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerWorkPhone across the matching connection */
  coBuyerWorkPhone?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerWorkExtension across the matching connection */
  coBuyerWorkExtension?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerEmail1 across the matching connection */
  coBuyerEmail1?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerEmail2 across the matching connection */
  coBuyerEmail2?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerEmail3 across the matching connection */
  coBuyerEmail3?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerBirthDate across the matching connection */
  coBuyerBirthDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerIndividualBusinessFlag across the matching connection */
  coBuyerIndividualBusinessFlag?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerOptOut across the matching connection */
  coBuyerOptOut?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerBlockEmail across the matching connection */
  coBuyerBlockEmail?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerBlockPhone across the matching connection */
  coBuyerBlockPhone?: Maybe<Scalars['BigInt']>;
  /** Distinct count of coBuyerBlockMail across the matching connection */
  coBuyerBlockMail?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vin across the matching connection */
  vin?: Maybe<Scalars['BigInt']>;
  /** Distinct count of year across the matching connection */
  year?: Maybe<Scalars['BigInt']>;
  /** Distinct count of make across the matching connection */
  make?: Maybe<Scalars['BigInt']>;
  /** Distinct count of model across the matching connection */
  model?: Maybe<Scalars['BigInt']>;
  /** Distinct count of modelNumber across the matching connection */
  modelNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of exteriorColor across the matching connection */
  exteriorColor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of newUsed across the matching connection */
  newUsed?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stockNumber across the matching connection */
  stockNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of transmission across the matching connection */
  transmission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of engineConfiguration across the matching connection */
  engineConfiguration?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trim across the matching connection */
  trim?: Maybe<Scalars['BigInt']>;
  /** Distinct count of engineNumber across the matching connection */
  engineNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of chassisNumber across the matching connection */
  chassisNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of licensePlateNumber across the matching connection */
  licensePlateNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deliveryDate across the matching connection */
  deliveryDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deliveryMileage across the matching connection */
  deliveryMileage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of inventoryDate across the matching connection */
  inventoryDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of inServiceDate across the matching connection */
  inServiceDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vinExplosionYear across the matching connection */
  vinExplosionYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vinExplosionMake across the matching connection */
  vinExplosionMake?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vinExplosionModel across the matching connection */
  vinExplosionModel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vinExplosionTrim across the matching connection */
  vinExplosionTrim?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vinExplosionTransmissionType across the matching connection */
  vinExplosionTransmissionType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vinExplosionFuelType across the matching connection */
  vinExplosionFuelType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vinExplosionEngineSize across the matching connection */
  vinExplosionEngineSize?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vinExplosionGvwRange across the matching connection */
  vinExplosionGvwRange?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade1Vin across the matching connection */
  trade1Vin?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade1Year across the matching connection */
  trade1Year?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade1Make across the matching connection */
  trade1Make?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade1Model across the matching connection */
  trade1Model?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade1Odometer across the matching connection */
  trade1Odometer?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade1ActualCashValue across the matching connection */
  trade1ActualCashValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade1Gross across the matching connection */
  trade1Gross?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade1Payoff across the matching connection */
  trade1Payoff?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade2Vin across the matching connection */
  trade2Vin?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade2Year across the matching connection */
  trade2Year?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade2Make across the matching connection */
  trade2Make?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade2Model across the matching connection */
  trade2Model?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade2Odometer across the matching connection */
  trade2Odometer?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade2ActualCashValue across the matching connection */
  trade2ActualCashValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade2Gross across the matching connection */
  trade2Gross?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trade2Payoff across the matching connection */
  trade2Payoff?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman1Number across the matching connection */
  salesman1Number?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman1Name across the matching connection */
  salesman1Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman2Number across the matching connection */
  salesman2Number?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman2Name across the matching connection */
  salesman2Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman3Number across the matching connection */
  salesman3Number?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman3Name across the matching connection */
  salesman3Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of closingManagerNumber across the matching connection */
  closingManagerNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of closingManagerName across the matching connection */
  closingManagerName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of financeManagerNumber across the matching connection */
  financeManagerNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of financeManagerName across the matching connection */
  financeManagerName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesmanManagerNumber across the matching connection */
  salesmanManagerNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesmanManagerName across the matching connection */
  salesmanManagerName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of msrp across the matching connection */
  msrp?: Maybe<Scalars['BigInt']>;
  /** Distinct count of listPrice across the matching connection */
  listPrice?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesPrice across the matching connection */
  salesPrice?: Maybe<Scalars['BigInt']>;
  /** Distinct count of journalPrice across the matching connection */
  journalPrice?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cost across the matching connection */
  cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of journalCost across the matching connection */
  journalCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of adjustments across the matching connection */
  adjustments?: Maybe<Scalars['BigInt']>;
  /** Distinct count of adjustedCost across the matching connection */
  adjustedCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of incentives across the matching connection */
  incentives?: Maybe<Scalars['BigInt']>;
  /** Distinct count of packAmount across the matching connection */
  packAmount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of saleNet across the matching connection */
  saleNet?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalTradeActualCashValue across the matching connection */
  totalTradeActualCashValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalTradeGross across the matching connection */
  totalTradeGross?: Maybe<Scalars['BigInt']>;
  /** Distinct count of weOweFront across the matching connection */
  weOweFront?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalFrontFeesAftermarketProfit across the matching connection */
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalFrontCommission across the matching connection */
  totalFrontCommission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalFrontSales across the matching connection */
  totalFrontSales?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalFrontCost across the matching connection */
  totalFrontCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of frontGross across the matching connection */
  frontGross?: Maybe<Scalars['BigInt']>;
  /** Distinct count of financeProfit across the matching connection */
  financeProfit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalWarrantyProfit across the matching connection */
  totalWarrantyProfit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of weOweBack across the matching connection */
  weOweBack?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insuranceProfit across the matching connection */
  insuranceProfit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalBackFeesAftermarketProfit across the matching connection */
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of financeReserve across the matching connection */
  financeReserve?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalBackCommission across the matching connection */
  totalBackCommission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalBackSales across the matching connection */
  totalBackSales?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalBackCost across the matching connection */
  totalBackCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of backGross across the matching connection */
  backGross?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalProfit across the matching connection */
  totalProfit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of grossProfit across the matching connection */
  grossProfit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of grossPayable across the matching connection */
  grossPayable?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dealStatus across the matching connection */
  dealStatus?: Maybe<Scalars['BigInt']>;
  /** Distinct count of entryDate across the matching connection */
  entryDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of bookedDate across the matching connection */
  bookedDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of finalizedDate across the matching connection */
  finalizedDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of contractDate across the matching connection */
  contractDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accountingDate across the matching connection */
  accountingDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statusChangeDate across the matching connection */
  statusChangeDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of firstPayDate across the matching connection */
  firstPayDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dealType across the matching connection */
  dealType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of saleType across the matching connection */
  saleType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of bankId across the matching connection */
  bankId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of bankName across the matching connection */
  bankName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of bankAddress across the matching connection */
  bankAddress?: Maybe<Scalars['BigInt']>;
  /** Distinct count of term across the matching connection */
  term?: Maybe<Scalars['BigInt']>;
  /** Distinct count of amountFinanced across the matching connection */
  amountFinanced?: Maybe<Scalars['BigInt']>;
  /** Distinct count of apr across the matching connection */
  apr?: Maybe<Scalars['BigInt']>;
  /** Distinct count of monthlyPayment across the matching connection */
  monthlyPayment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of paymentTotal across the matching connection */
  paymentTotal?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rebates across the matching connection */
  rebates?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deposit across the matching connection */
  deposit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of downPayment across the matching connection */
  downPayment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalNetTrades across the matching connection */
  totalNetTrades?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalDown across the matching connection */
  totalDown?: Maybe<Scalars['BigInt']>;
  /** Distinct count of balloonAmount across the matching connection */
  balloonAmount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of adjustedBalloonAmount across the matching connection */
  adjustedBalloonAmount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of holdbackAmount across the matching connection */
  holdbackAmount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalDriveOffAmount across the matching connection */
  totalDriveOffAmount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of licenseFee across the matching connection */
  licenseFee?: Maybe<Scalars['BigInt']>;
  /** Distinct count of registrationFee across the matching connection */
  registrationFee?: Maybe<Scalars['BigInt']>;
  /** Distinct count of documentationFee across the matching connection */
  documentationFee?: Maybe<Scalars['BigInt']>;
  /** Distinct count of financeCharge across the matching connection */
  financeCharge?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalPickupPayments across the matching connection */
  totalPickupPayments?: Maybe<Scalars['BigInt']>;
  /** Distinct count of sellRate across the matching connection */
  sellRate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of buyRate across the matching connection */
  buyRate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of residualRate across the matching connection */
  residualRate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of residualAmount across the matching connection */
  residualAmount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of allowedMiles across the matching connection */
  allowedMiles?: Maybe<Scalars['BigInt']>;
  /** Distinct count of estimatedMiles across the matching connection */
  estimatedMiles?: Maybe<Scalars['BigInt']>;
  /** Distinct count of mileageRate across the matching connection */
  mileageRate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of acquisitionFee across the matching connection */
  acquisitionFee?: Maybe<Scalars['BigInt']>;
  /** Distinct count of basePayment across the matching connection */
  basePayment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of securityDeposit across the matching connection */
  securityDeposit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalCapitalReduction across the matching connection */
  totalCapitalReduction?: Maybe<Scalars['BigInt']>;
  /** Distinct count of netCapitalCost across the matching connection */
  netCapitalCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leaseDepreciationValue across the matching connection */
  leaseDepreciationValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dealerFees across the matching connection */
  dealerFees?: Maybe<Scalars['BigInt']>;
  /** Distinct count of governmentFees across the matching connection */
  governmentFees?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalTax across the matching connection */
  totalTax?: Maybe<Scalars['BigInt']>;
  /** Distinct count of registrationState across the matching connection */
  registrationState?: Maybe<Scalars['BigInt']>;
  /** Distinct count of reportOfSaleNumber across the matching connection */
  reportOfSaleNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman1TotalCommission across the matching connection */
  salesman1TotalCommission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman1FrontCommission across the matching connection */
  salesman1FrontCommission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman1BackCommission across the matching connection */
  salesman1BackCommission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman2TotalCommission across the matching connection */
  salesman2TotalCommission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman2FrontCommission across the matching connection */
  salesman2FrontCommission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman2BackCommission across the matching connection */
  salesman2BackCommission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman3TotalCommission across the matching connection */
  salesman3TotalCommission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman3FrontCommission across the matching connection */
  salesman3FrontCommission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salesman3BackCommission across the matching connection */
  salesman3BackCommission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty1Name across the matching connection */
  warranty1Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty1Sale across the matching connection */
  warranty1Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty1Cost across the matching connection */
  warranty1Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty1Miles across the matching connection */
  warranty1Miles?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty1Term across the matching connection */
  warranty1Term?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty2Name across the matching connection */
  warranty2Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty2Sale across the matching connection */
  warranty2Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty2Cost across the matching connection */
  warranty2Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty2Miles across the matching connection */
  warranty2Miles?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty2Term across the matching connection */
  warranty2Term?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty3Name across the matching connection */
  warranty3Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty3Sale across the matching connection */
  warranty3Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty3Cost across the matching connection */
  warranty3Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty3Miles across the matching connection */
  warranty3Miles?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty3Term across the matching connection */
  warranty3Term?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty4Name across the matching connection */
  warranty4Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty4Sale across the matching connection */
  warranty4Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty4Cost across the matching connection */
  warranty4Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty4Miles across the matching connection */
  warranty4Miles?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty4Term across the matching connection */
  warranty4Term?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty5Name across the matching connection */
  warranty5Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty5Sale across the matching connection */
  warranty5Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty5Cost across the matching connection */
  warranty5Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty5Miles across the matching connection */
  warranty5Miles?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warranty5Term across the matching connection */
  warranty5Term?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalFeeAftermarketSale across the matching connection */
  totalFeeAftermarketSale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of totalFeeAftermarketCost across the matching connection */
  totalFeeAftermarketCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket1Name across the matching connection */
  feeAftermarket1Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket1Sale across the matching connection */
  feeAftermarket1Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket1Cost across the matching connection */
  feeAftermarket1Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket1ProfitIndicator across the matching connection */
  feeAftermarket1ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket2Name across the matching connection */
  feeAftermarket2Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket2Sale across the matching connection */
  feeAftermarket2Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket2Cost across the matching connection */
  feeAftermarket2Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket2ProfitIndicator across the matching connection */
  feeAftermarket2ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket3Name across the matching connection */
  feeAftermarket3Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket3Sale across the matching connection */
  feeAftermarket3Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket3Cost across the matching connection */
  feeAftermarket3Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket3ProfitIndicator across the matching connection */
  feeAftermarket3ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket4Name across the matching connection */
  feeAftermarket4Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket4Sale across the matching connection */
  feeAftermarket4Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket4Cost across the matching connection */
  feeAftermarket4Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket4ProfitIndicator across the matching connection */
  feeAftermarket4ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket5Name across the matching connection */
  feeAftermarket5Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket5Sale across the matching connection */
  feeAftermarket5Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket5Cost across the matching connection */
  feeAftermarket5Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket5ProfitIndicator across the matching connection */
  feeAftermarket5ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket6Name across the matching connection */
  feeAftermarket6Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket6Sale across the matching connection */
  feeAftermarket6Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket6Cost across the matching connection */
  feeAftermarket6Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket6ProfitIndicator across the matching connection */
  feeAftermarket6ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket7Name across the matching connection */
  feeAftermarket7Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket7Sale across the matching connection */
  feeAftermarket7Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket7Cost across the matching connection */
  feeAftermarket7Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket7ProfitIndicator across the matching connection */
  feeAftermarket7ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket8Name across the matching connection */
  feeAftermarket8Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket8Sale across the matching connection */
  feeAftermarket8Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket8Cost across the matching connection */
  feeAftermarket8Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket8ProfitIndicator across the matching connection */
  feeAftermarket8ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket9Name across the matching connection */
  feeAftermarket9Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket9Sale across the matching connection */
  feeAftermarket9Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket9Cost across the matching connection */
  feeAftermarket9Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket9ProfitIndicator across the matching connection */
  feeAftermarket9ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket10Name across the matching connection */
  feeAftermarket10Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket10Sale across the matching connection */
  feeAftermarket10Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket10Cost across the matching connection */
  feeAftermarket10Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket10ProfitIndicator across the matching connection */
  feeAftermarket10ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket11Name across the matching connection */
  feeAftermarket11Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket11Sale across the matching connection */
  feeAftermarket11Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket11Cost across the matching connection */
  feeAftermarket11Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket11ProfitIndicator across the matching connection */
  feeAftermarket11ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket12Name across the matching connection */
  feeAftermarket12Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket12Sale across the matching connection */
  feeAftermarket12Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket12Cost across the matching connection */
  feeAftermarket12Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket12ProfitIndicator across the matching connection */
  feeAftermarket12ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket13Name across the matching connection */
  feeAftermarket13Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket13Sale across the matching connection */
  feeAftermarket13Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket13Cost across the matching connection */
  feeAftermarket13Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket13ProfitIndicator across the matching connection */
  feeAftermarket13ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket14Name across the matching connection */
  feeAftermarket14Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket14Sale across the matching connection */
  feeAftermarket14Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket14Cost across the matching connection */
  feeAftermarket14Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket14ProfitIndicator across the matching connection */
  feeAftermarket14ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket15Name across the matching connection */
  feeAftermarket15Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket15Sale across the matching connection */
  feeAftermarket15Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket15Cost across the matching connection */
  feeAftermarket15Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket15ProfitIndicator across the matching connection */
  feeAftermarket15ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket16Name across the matching connection */
  feeAftermarket16Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket16Sale across the matching connection */
  feeAftermarket16Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket16Cost across the matching connection */
  feeAftermarket16Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket16ProfitIndicator across the matching connection */
  feeAftermarket16ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket17Name across the matching connection */
  feeAftermarket17Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket17Sale across the matching connection */
  feeAftermarket17Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket17Cost across the matching connection */
  feeAftermarket17Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket17ProfitIndicator across the matching connection */
  feeAftermarket17ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket18Name across the matching connection */
  feeAftermarket18Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket18Sale across the matching connection */
  feeAftermarket18Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket18Cost across the matching connection */
  feeAftermarket18Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket18ProfitIndicator across the matching connection */
  feeAftermarket18ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket19Name across the matching connection */
  feeAftermarket19Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket19Sale across the matching connection */
  feeAftermarket19Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket19Cost across the matching connection */
  feeAftermarket19Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket19ProfitIndicator across the matching connection */
  feeAftermarket19ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket20Name across the matching connection */
  feeAftermarket20Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket20Sale across the matching connection */
  feeAftermarket20Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket20Cost across the matching connection */
  feeAftermarket20Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of feeAftermarket20ProfitIndicator across the matching connection */
  feeAftermarket20ProfitIndicator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance1Type across the matching connection */
  insurance1Type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance1Name across the matching connection */
  insurance1Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance1Sale across the matching connection */
  insurance1Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance1Cost across the matching connection */
  insurance1Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance1Term across the matching connection */
  insurance1Term?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance2Type across the matching connection */
  insurance2Type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance2Name across the matching connection */
  insurance2Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance2Sale across the matching connection */
  insurance2Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance2Cost across the matching connection */
  insurance2Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance2Term across the matching connection */
  insurance2Term?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance3Type across the matching connection */
  insurance3Type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance3Name across the matching connection */
  insurance3Name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance3Sale across the matching connection */
  insurance3Sale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance3Cost across the matching connection */
  insurance3Cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of insurance3Term across the matching connection */
  insurance3Term?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accidentalHealthType across the matching connection */
  accidentalHealthType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accidentalHealthName across the matching connection */
  accidentalHealthName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accidentalHealthSale across the matching connection */
  accidentalHealthSale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accidentalHealthCost across the matching connection */
  accidentalHealthCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accidentalHealthTerm across the matching connection */
  accidentalHealthTerm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditLifeType across the matching connection */
  creditLifeType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditLifeName across the matching connection */
  creditLifeName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditLifeSale across the matching connection */
  creditLifeSale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditLifeCost across the matching connection */
  creditLifeCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditLifeTerm across the matching connection */
  creditLifeTerm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of levelizedLifeType across the matching connection */
  levelizedLifeType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of levelizedLifeName across the matching connection */
  levelizedLifeName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of levelizedLifeSale across the matching connection */
  levelizedLifeSale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of levelizedLifeCost across the matching connection */
  levelizedLifeCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of levelizedLifeTerm across the matching connection */
  levelizedLifeTerm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lossOfEmploymentType across the matching connection */
  lossOfEmploymentType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lossOfEmploymentName across the matching connection */
  lossOfEmploymentName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lossOfEmploymentSale across the matching connection */
  lossOfEmploymentSale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lossOfEmploymentCost across the matching connection */
  lossOfEmploymentCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lossOfEmploymentTerm across the matching connection */
  lossOfEmploymentTerm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of guaranteedAutoProtectionType across the matching connection */
  guaranteedAutoProtectionType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of guaranteedAutoProtectionName across the matching connection */
  guaranteedAutoProtectionName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of guaranteedAutoProtectionSale across the matching connection */
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigInt']>;
  /** Distinct count of guaranteedAutoProtectionCost across the matching connection */
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of guaranteedAutoProtectionTerm across the matching connection */
  guaranteedAutoProtectionTerm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of saleComments across the matching connection */
  saleComments?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdLine1 across the matching connection */
  cassStdLine1?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdLine2 across the matching connection */
  cassStdLine2?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdCity across the matching connection */
  cassStdCity?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdState across the matching connection */
  cassStdState?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdZip across the matching connection */
  cassStdZip?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdZip4 across the matching connection */
  cassStdZip4?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdDpbc across the matching connection */
  cassStdDpbc?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdChkdgt across the matching connection */
  cassStdChkdgt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdCart across the matching connection */
  cassStdCart?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdLot across the matching connection */
  cassStdLot?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdLotord across the matching connection */
  cassStdLotord?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdUrb across the matching connection */
  cassStdUrb?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdFips across the matching connection */
  cassStdFips?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdEws across the matching connection */
  cassStdEws?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdLacs across the matching connection */
  cassStdLacs?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdZipmov across the matching connection */
  cassStdZipmov?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdZ4Lom across the matching connection */
  cassStdZ4Lom?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdNdiapt across the matching connection */
  cassStdNdiapt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdNdirr across the matching connection */
  cassStdNdirr?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdLacsrt across the matching connection */
  cassStdLacsrt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cassStdErrorCd across the matching connection */
  cassStdErrorCd?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Deal` object types. All fields are combined with a logical ‘and.’ */
export type DealFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `fileType` field. */
  fileType?: Maybe<StringFilter>;
  /** Filter by the object’s `dvDealerId` field. */
  dvDealerId?: Maybe<StringFilter>;
  /** Filter by the object’s `vendorDealerId` field. */
  vendorDealerId?: Maybe<StringFilter>;
  /** Filter by the object’s `dmsType` field. */
  dmsType?: Maybe<StringFilter>;
  /** Filter by the object’s `dealNumber` field. */
  dealNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `customerNumber` field. */
  customerNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `fullName` field. */
  fullName?: Maybe<StringFilter>;
  /** Filter by the object’s `salutation` field. */
  salutation?: Maybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `middleName` field. */
  middleName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `suffix` field. */
  suffix?: Maybe<StringFilter>;
  /** Filter by the object’s `addressLine1` field. */
  addressLine1?: Maybe<StringFilter>;
  /** Filter by the object’s `addressLine2` field. */
  addressLine2?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: Maybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: Maybe<StringFilter>;
  /** Filter by the object’s `zip` field. */
  zip?: Maybe<StringFilter>;
  /** Filter by the object’s `county` field. */
  county?: Maybe<StringFilter>;
  /** Filter by the object’s `homePhone` field. */
  homePhone?: Maybe<StringFilter>;
  /** Filter by the object’s `cellPhone` field. */
  cellPhone?: Maybe<StringFilter>;
  /** Filter by the object’s `workPhone` field. */
  workPhone?: Maybe<StringFilter>;
  /** Filter by the object’s `workExtension` field. */
  workExtension?: Maybe<StringFilter>;
  /** Filter by the object’s `email1` field. */
  email1?: Maybe<StringFilter>;
  /** Filter by the object’s `email2` field. */
  email2?: Maybe<StringFilter>;
  /** Filter by the object’s `email3` field. */
  email3?: Maybe<StringFilter>;
  /** Filter by the object’s `birthDate` field. */
  birthDate?: Maybe<DateFilter>;
  /** Filter by the object’s `individualBusinessFlag` field. */
  individualBusinessFlag?: Maybe<StringFilter>;
  /** Filter by the object’s `optOut` field. */
  optOut?: Maybe<BooleanFilter>;
  /** Filter by the object’s `blockEmail` field. */
  blockEmail?: Maybe<BooleanFilter>;
  /** Filter by the object’s `blockPhone` field. */
  blockPhone?: Maybe<BooleanFilter>;
  /** Filter by the object’s `blockMail` field. */
  blockMail?: Maybe<BooleanFilter>;
  /** Filter by the object’s `language` field. */
  language?: Maybe<StringFilter>;
  /** Filter by the object’s `customerCreateDate` field. */
  customerCreateDate?: Maybe<DateFilter>;
  /** Filter by the object’s `customerLastActivityDate` field. */
  customerLastActivityDate?: Maybe<DateFilter>;
  /** Filter by the object’s `coBuyerCustomerNumber` field. */
  coBuyerCustomerNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerFullName` field. */
  coBuyerFullName?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerSalutation` field. */
  coBuyerSalutation?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerFirstName` field. */
  coBuyerFirstName?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerMiddleName` field. */
  coBuyerMiddleName?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerLastName` field. */
  coBuyerLastName?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerSuffix` field. */
  coBuyerSuffix?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerAddressLine1` field. */
  coBuyerAddressLine1?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerAddressLine2` field. */
  coBuyerAddressLine2?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerCity` field. */
  coBuyerCity?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerState` field. */
  coBuyerState?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerZip` field. */
  coBuyerZip?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerCounty` field. */
  coBuyerCounty?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerHomePhone` field. */
  coBuyerHomePhone?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerCellPhone` field. */
  coBuyerCellPhone?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerWorkPhone` field. */
  coBuyerWorkPhone?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerWorkExtension` field. */
  coBuyerWorkExtension?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerEmail1` field. */
  coBuyerEmail1?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerEmail2` field. */
  coBuyerEmail2?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerEmail3` field. */
  coBuyerEmail3?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerBirthDate` field. */
  coBuyerBirthDate?: Maybe<DateFilter>;
  /** Filter by the object’s `coBuyerIndividualBusinessFlag` field. */
  coBuyerIndividualBusinessFlag?: Maybe<StringFilter>;
  /** Filter by the object’s `coBuyerOptOut` field. */
  coBuyerOptOut?: Maybe<BooleanFilter>;
  /** Filter by the object’s `coBuyerBlockEmail` field. */
  coBuyerBlockEmail?: Maybe<BooleanFilter>;
  /** Filter by the object’s `coBuyerBlockPhone` field. */
  coBuyerBlockPhone?: Maybe<BooleanFilter>;
  /** Filter by the object’s `coBuyerBlockMail` field. */
  coBuyerBlockMail?: Maybe<BooleanFilter>;
  /** Filter by the object’s `vin` field. */
  vin?: Maybe<StringFilter>;
  /** Filter by the object’s `year` field. */
  year?: Maybe<IntFilter>;
  /** Filter by the object’s `make` field. */
  make?: Maybe<StringFilter>;
  /** Filter by the object’s `model` field. */
  model?: Maybe<StringFilter>;
  /** Filter by the object’s `modelNumber` field. */
  modelNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `mileage` field. */
  mileage?: Maybe<IntFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `exteriorColor` field. */
  exteriorColor?: Maybe<StringFilter>;
  /** Filter by the object’s `newUsed` field. */
  newUsed?: Maybe<StringFilter>;
  /** Filter by the object’s `stockNumber` field. */
  stockNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `transmission` field. */
  transmission?: Maybe<StringFilter>;
  /** Filter by the object’s `engineConfiguration` field. */
  engineConfiguration?: Maybe<StringFilter>;
  /** Filter by the object’s `trim` field. */
  trim?: Maybe<StringFilter>;
  /** Filter by the object’s `engineNumber` field. */
  engineNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `chassisNumber` field. */
  chassisNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `licensePlateNumber` field. */
  licensePlateNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `deliveryDate` field. */
  deliveryDate?: Maybe<DateFilter>;
  /** Filter by the object’s `deliveryMileage` field. */
  deliveryMileage?: Maybe<IntFilter>;
  /** Filter by the object’s `inventoryDate` field. */
  inventoryDate?: Maybe<DateFilter>;
  /** Filter by the object’s `inServiceDate` field. */
  inServiceDate?: Maybe<DateFilter>;
  /** Filter by the object’s `vinExplosionYear` field. */
  vinExplosionYear?: Maybe<IntFilter>;
  /** Filter by the object’s `vinExplosionMake` field. */
  vinExplosionMake?: Maybe<StringFilter>;
  /** Filter by the object’s `vinExplosionModel` field. */
  vinExplosionModel?: Maybe<StringFilter>;
  /** Filter by the object’s `vinExplosionTrim` field. */
  vinExplosionTrim?: Maybe<StringFilter>;
  /** Filter by the object’s `vinExplosionTransmissionType` field. */
  vinExplosionTransmissionType?: Maybe<StringFilter>;
  /** Filter by the object’s `vinExplosionFuelType` field. */
  vinExplosionFuelType?: Maybe<StringFilter>;
  /** Filter by the object’s `vinExplosionEngineSize` field. */
  vinExplosionEngineSize?: Maybe<StringFilter>;
  /** Filter by the object’s `vinExplosionGvwRange` field. */
  vinExplosionGvwRange?: Maybe<StringFilter>;
  /** Filter by the object’s `trade1Vin` field. */
  trade1Vin?: Maybe<StringFilter>;
  /** Filter by the object’s `trade1Year` field. */
  trade1Year?: Maybe<IntFilter>;
  /** Filter by the object’s `trade1Make` field. */
  trade1Make?: Maybe<StringFilter>;
  /** Filter by the object’s `trade1Model` field. */
  trade1Model?: Maybe<StringFilter>;
  /** Filter by the object’s `trade1Odometer` field. */
  trade1Odometer?: Maybe<IntFilter>;
  /** Filter by the object’s `trade1ActualCashValue` field. */
  trade1ActualCashValue?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `trade1Gross` field. */
  trade1Gross?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `trade1Payoff` field. */
  trade1Payoff?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `trade2Vin` field. */
  trade2Vin?: Maybe<StringFilter>;
  /** Filter by the object’s `trade2Year` field. */
  trade2Year?: Maybe<IntFilter>;
  /** Filter by the object’s `trade2Make` field. */
  trade2Make?: Maybe<StringFilter>;
  /** Filter by the object’s `trade2Model` field. */
  trade2Model?: Maybe<StringFilter>;
  /** Filter by the object’s `trade2Odometer` field. */
  trade2Odometer?: Maybe<IntFilter>;
  /** Filter by the object’s `trade2ActualCashValue` field. */
  trade2ActualCashValue?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `trade2Gross` field. */
  trade2Gross?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `trade2Payoff` field. */
  trade2Payoff?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `salesman1Number` field. */
  salesman1Number?: Maybe<StringFilter>;
  /** Filter by the object’s `salesman1Name` field. */
  salesman1Name?: Maybe<StringFilter>;
  /** Filter by the object’s `salesman2Number` field. */
  salesman2Number?: Maybe<StringFilter>;
  /** Filter by the object’s `salesman2Name` field. */
  salesman2Name?: Maybe<StringFilter>;
  /** Filter by the object’s `salesman3Number` field. */
  salesman3Number?: Maybe<StringFilter>;
  /** Filter by the object’s `salesman3Name` field. */
  salesman3Name?: Maybe<StringFilter>;
  /** Filter by the object’s `closingManagerNumber` field. */
  closingManagerNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `closingManagerName` field. */
  closingManagerName?: Maybe<StringFilter>;
  /** Filter by the object’s `financeManagerNumber` field. */
  financeManagerNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `financeManagerName` field. */
  financeManagerName?: Maybe<StringFilter>;
  /** Filter by the object’s `salesmanManagerNumber` field. */
  salesmanManagerNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `salesmanManagerName` field. */
  salesmanManagerName?: Maybe<StringFilter>;
  /** Filter by the object’s `msrp` field. */
  msrp?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `listPrice` field. */
  listPrice?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `salesPrice` field. */
  salesPrice?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `journalPrice` field. */
  journalPrice?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `cost` field. */
  cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `journalCost` field. */
  journalCost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `adjustments` field. */
  adjustments?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `adjustedCost` field. */
  adjustedCost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `incentives` field. */
  incentives?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `packAmount` field. */
  packAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `saleNet` field. */
  saleNet?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalTradeActualCashValue` field. */
  totalTradeActualCashValue?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalTradeGross` field. */
  totalTradeGross?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `weOweFront` field. */
  weOweFront?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalFrontFeesAftermarketProfit` field. */
  totalFrontFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalFrontCommission` field. */
  totalFrontCommission?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalFrontSales` field. */
  totalFrontSales?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalFrontCost` field. */
  totalFrontCost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `frontGross` field. */
  frontGross?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `financeProfit` field. */
  financeProfit?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalWarrantyProfit` field. */
  totalWarrantyProfit?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `weOweBack` field. */
  weOweBack?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `insuranceProfit` field. */
  insuranceProfit?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalBackFeesAftermarketProfit` field. */
  totalBackFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `financeReserve` field. */
  financeReserve?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalBackCommission` field. */
  totalBackCommission?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalBackSales` field. */
  totalBackSales?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalBackCost` field. */
  totalBackCost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `backGross` field. */
  backGross?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalProfit` field. */
  totalProfit?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `grossProfit` field. */
  grossProfit?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `grossPayable` field. */
  grossPayable?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `dealStatus` field. */
  dealStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `entryDate` field. */
  entryDate?: Maybe<DateFilter>;
  /** Filter by the object’s `bookedDate` field. */
  bookedDate?: Maybe<DateFilter>;
  /** Filter by the object’s `finalizedDate` field. */
  finalizedDate?: Maybe<DateFilter>;
  /** Filter by the object’s `contractDate` field. */
  contractDate?: Maybe<DateFilter>;
  /** Filter by the object’s `accountingDate` field. */
  accountingDate?: Maybe<DateFilter>;
  /** Filter by the object’s `statusChangeDate` field. */
  statusChangeDate?: Maybe<DateFilter>;
  /** Filter by the object’s `firstPayDate` field. */
  firstPayDate?: Maybe<DateFilter>;
  /** Filter by the object’s `dealType` field. */
  dealType?: Maybe<StringFilter>;
  /** Filter by the object’s `saleType` field. */
  saleType?: Maybe<StringFilter>;
  /** Filter by the object’s `bankId` field. */
  bankId?: Maybe<StringFilter>;
  /** Filter by the object’s `bankName` field. */
  bankName?: Maybe<StringFilter>;
  /** Filter by the object’s `bankAddress` field. */
  bankAddress?: Maybe<StringFilter>;
  /** Filter by the object’s `term` field. */
  term?: Maybe<IntFilter>;
  /** Filter by the object’s `amountFinanced` field. */
  amountFinanced?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `apr` field. */
  apr?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `monthlyPayment` field. */
  monthlyPayment?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `paymentTotal` field. */
  paymentTotal?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `rebates` field. */
  rebates?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `deposit` field. */
  deposit?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `downPayment` field. */
  downPayment?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalNetTrades` field. */
  totalNetTrades?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalDown` field. */
  totalDown?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `balloonAmount` field. */
  balloonAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `adjustedBalloonAmount` field. */
  adjustedBalloonAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `holdbackAmount` field. */
  holdbackAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalDriveOffAmount` field. */
  totalDriveOffAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `licenseFee` field. */
  licenseFee?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `registrationFee` field. */
  registrationFee?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `documentationFee` field. */
  documentationFee?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `financeCharge` field. */
  financeCharge?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalPickupPayments` field. */
  totalPickupPayments?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `sellRate` field. */
  sellRate?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `buyRate` field. */
  buyRate?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `residualRate` field. */
  residualRate?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `residualAmount` field. */
  residualAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `allowedMiles` field. */
  allowedMiles?: Maybe<IntFilter>;
  /** Filter by the object’s `estimatedMiles` field. */
  estimatedMiles?: Maybe<IntFilter>;
  /** Filter by the object’s `mileageRate` field. */
  mileageRate?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `acquisitionFee` field. */
  acquisitionFee?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `basePayment` field. */
  basePayment?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `securityDeposit` field. */
  securityDeposit?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalCapitalReduction` field. */
  totalCapitalReduction?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `netCapitalCost` field. */
  netCapitalCost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `leaseDepreciationValue` field. */
  leaseDepreciationValue?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `dealerFees` field. */
  dealerFees?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `governmentFees` field. */
  governmentFees?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalTax` field. */
  totalTax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `registrationState` field. */
  registrationState?: Maybe<StringFilter>;
  /** Filter by the object’s `reportOfSaleNumber` field. */
  reportOfSaleNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `salesman1TotalCommission` field. */
  salesman1TotalCommission?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `salesman1FrontCommission` field. */
  salesman1FrontCommission?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `salesman1BackCommission` field. */
  salesman1BackCommission?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `salesman2TotalCommission` field. */
  salesman2TotalCommission?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `salesman2FrontCommission` field. */
  salesman2FrontCommission?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `salesman2BackCommission` field. */
  salesman2BackCommission?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `salesman3TotalCommission` field. */
  salesman3TotalCommission?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `salesman3FrontCommission` field. */
  salesman3FrontCommission?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `salesman3BackCommission` field. */
  salesman3BackCommission?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `warranty1Name` field. */
  warranty1Name?: Maybe<StringFilter>;
  /** Filter by the object’s `warranty1Sale` field. */
  warranty1Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `warranty1Cost` field. */
  warranty1Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `warranty1Miles` field. */
  warranty1Miles?: Maybe<IntFilter>;
  /** Filter by the object’s `warranty1Term` field. */
  warranty1Term?: Maybe<IntFilter>;
  /** Filter by the object’s `warranty2Name` field. */
  warranty2Name?: Maybe<StringFilter>;
  /** Filter by the object’s `warranty2Sale` field. */
  warranty2Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `warranty2Cost` field. */
  warranty2Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `warranty2Miles` field. */
  warranty2Miles?: Maybe<IntFilter>;
  /** Filter by the object’s `warranty2Term` field. */
  warranty2Term?: Maybe<IntFilter>;
  /** Filter by the object’s `warranty3Name` field. */
  warranty3Name?: Maybe<StringFilter>;
  /** Filter by the object’s `warranty3Sale` field. */
  warranty3Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `warranty3Cost` field. */
  warranty3Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `warranty3Miles` field. */
  warranty3Miles?: Maybe<IntFilter>;
  /** Filter by the object’s `warranty3Term` field. */
  warranty3Term?: Maybe<IntFilter>;
  /** Filter by the object’s `warranty4Name` field. */
  warranty4Name?: Maybe<StringFilter>;
  /** Filter by the object’s `warranty4Sale` field. */
  warranty4Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `warranty4Cost` field. */
  warranty4Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `warranty4Miles` field. */
  warranty4Miles?: Maybe<IntFilter>;
  /** Filter by the object’s `warranty4Term` field. */
  warranty4Term?: Maybe<IntFilter>;
  /** Filter by the object’s `warranty5Name` field. */
  warranty5Name?: Maybe<StringFilter>;
  /** Filter by the object’s `warranty5Sale` field. */
  warranty5Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `warranty5Cost` field. */
  warranty5Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `warranty5Miles` field. */
  warranty5Miles?: Maybe<IntFilter>;
  /** Filter by the object’s `warranty5Term` field. */
  warranty5Term?: Maybe<IntFilter>;
  /** Filter by the object’s `totalFeeAftermarketSale` field. */
  totalFeeAftermarketSale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalFeeAftermarketCost` field. */
  totalFeeAftermarketCost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket1Name` field. */
  feeAftermarket1Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket1Sale` field. */
  feeAftermarket1Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket1Cost` field. */
  feeAftermarket1Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket1ProfitIndicator` field. */
  feeAftermarket1ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket2Name` field. */
  feeAftermarket2Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket2Sale` field. */
  feeAftermarket2Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket2Cost` field. */
  feeAftermarket2Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket2ProfitIndicator` field. */
  feeAftermarket2ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket3Name` field. */
  feeAftermarket3Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket3Sale` field. */
  feeAftermarket3Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket3Cost` field. */
  feeAftermarket3Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket3ProfitIndicator` field. */
  feeAftermarket3ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket4Name` field. */
  feeAftermarket4Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket4Sale` field. */
  feeAftermarket4Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket4Cost` field. */
  feeAftermarket4Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket4ProfitIndicator` field. */
  feeAftermarket4ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket5Name` field. */
  feeAftermarket5Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket5Sale` field. */
  feeAftermarket5Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket5Cost` field. */
  feeAftermarket5Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket5ProfitIndicator` field. */
  feeAftermarket5ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket6Name` field. */
  feeAftermarket6Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket6Sale` field. */
  feeAftermarket6Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket6Cost` field. */
  feeAftermarket6Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket6ProfitIndicator` field. */
  feeAftermarket6ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket7Name` field. */
  feeAftermarket7Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket7Sale` field. */
  feeAftermarket7Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket7Cost` field. */
  feeAftermarket7Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket7ProfitIndicator` field. */
  feeAftermarket7ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket8Name` field. */
  feeAftermarket8Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket8Sale` field. */
  feeAftermarket8Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket8Cost` field. */
  feeAftermarket8Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket8ProfitIndicator` field. */
  feeAftermarket8ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket9Name` field. */
  feeAftermarket9Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket9Sale` field. */
  feeAftermarket9Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket9Cost` field. */
  feeAftermarket9Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket9ProfitIndicator` field. */
  feeAftermarket9ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket10Name` field. */
  feeAftermarket10Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket10Sale` field. */
  feeAftermarket10Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket10Cost` field. */
  feeAftermarket10Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket10ProfitIndicator` field. */
  feeAftermarket10ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket11Name` field. */
  feeAftermarket11Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket11Sale` field. */
  feeAftermarket11Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket11Cost` field. */
  feeAftermarket11Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket11ProfitIndicator` field. */
  feeAftermarket11ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket12Name` field. */
  feeAftermarket12Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket12Sale` field. */
  feeAftermarket12Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket12Cost` field. */
  feeAftermarket12Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket12ProfitIndicator` field. */
  feeAftermarket12ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket13Name` field. */
  feeAftermarket13Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket13Sale` field. */
  feeAftermarket13Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket13Cost` field. */
  feeAftermarket13Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket13ProfitIndicator` field. */
  feeAftermarket13ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket14Name` field. */
  feeAftermarket14Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket14Sale` field. */
  feeAftermarket14Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket14Cost` field. */
  feeAftermarket14Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket14ProfitIndicator` field. */
  feeAftermarket14ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket15Name` field. */
  feeAftermarket15Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket15Sale` field. */
  feeAftermarket15Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket15Cost` field. */
  feeAftermarket15Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket15ProfitIndicator` field. */
  feeAftermarket15ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket16Name` field. */
  feeAftermarket16Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket16Sale` field. */
  feeAftermarket16Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket16Cost` field. */
  feeAftermarket16Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket16ProfitIndicator` field. */
  feeAftermarket16ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket17Name` field. */
  feeAftermarket17Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket17Sale` field. */
  feeAftermarket17Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket17Cost` field. */
  feeAftermarket17Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket17ProfitIndicator` field. */
  feeAftermarket17ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket18Name` field. */
  feeAftermarket18Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket18Sale` field. */
  feeAftermarket18Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket18Cost` field. */
  feeAftermarket18Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket18ProfitIndicator` field. */
  feeAftermarket18ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket19Name` field. */
  feeAftermarket19Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket19Sale` field. */
  feeAftermarket19Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket19Cost` field. */
  feeAftermarket19Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket19ProfitIndicator` field. */
  feeAftermarket19ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `feeAftermarket20Name` field. */
  feeAftermarket20Name?: Maybe<StringFilter>;
  /** Filter by the object’s `feeAftermarket20Sale` field. */
  feeAftermarket20Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket20Cost` field. */
  feeAftermarket20Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `feeAftermarket20ProfitIndicator` field. */
  feeAftermarket20ProfitIndicator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `insurance1Type` field. */
  insurance1Type?: Maybe<StringFilter>;
  /** Filter by the object’s `insurance1Name` field. */
  insurance1Name?: Maybe<StringFilter>;
  /** Filter by the object’s `insurance1Sale` field. */
  insurance1Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `insurance1Cost` field. */
  insurance1Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `insurance1Term` field. */
  insurance1Term?: Maybe<IntFilter>;
  /** Filter by the object’s `insurance2Type` field. */
  insurance2Type?: Maybe<StringFilter>;
  /** Filter by the object’s `insurance2Name` field. */
  insurance2Name?: Maybe<StringFilter>;
  /** Filter by the object’s `insurance2Sale` field. */
  insurance2Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `insurance2Cost` field. */
  insurance2Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `insurance2Term` field. */
  insurance2Term?: Maybe<IntFilter>;
  /** Filter by the object’s `insurance3Type` field. */
  insurance3Type?: Maybe<StringFilter>;
  /** Filter by the object’s `insurance3Name` field. */
  insurance3Name?: Maybe<StringFilter>;
  /** Filter by the object’s `insurance3Sale` field. */
  insurance3Sale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `insurance3Cost` field. */
  insurance3Cost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `insurance3Term` field. */
  insurance3Term?: Maybe<IntFilter>;
  /** Filter by the object’s `accidentalHealthType` field. */
  accidentalHealthType?: Maybe<StringFilter>;
  /** Filter by the object’s `accidentalHealthName` field. */
  accidentalHealthName?: Maybe<StringFilter>;
  /** Filter by the object’s `accidentalHealthSale` field. */
  accidentalHealthSale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `accidentalHealthCost` field. */
  accidentalHealthCost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `accidentalHealthTerm` field. */
  accidentalHealthTerm?: Maybe<IntFilter>;
  /** Filter by the object’s `creditLifeType` field. */
  creditLifeType?: Maybe<StringFilter>;
  /** Filter by the object’s `creditLifeName` field. */
  creditLifeName?: Maybe<StringFilter>;
  /** Filter by the object’s `creditLifeSale` field. */
  creditLifeSale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `creditLifeCost` field. */
  creditLifeCost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `creditLifeTerm` field. */
  creditLifeTerm?: Maybe<IntFilter>;
  /** Filter by the object’s `levelizedLifeType` field. */
  levelizedLifeType?: Maybe<StringFilter>;
  /** Filter by the object’s `levelizedLifeName` field. */
  levelizedLifeName?: Maybe<StringFilter>;
  /** Filter by the object’s `levelizedLifeSale` field. */
  levelizedLifeSale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `levelizedLifeCost` field. */
  levelizedLifeCost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `levelizedLifeTerm` field. */
  levelizedLifeTerm?: Maybe<IntFilter>;
  /** Filter by the object’s `lossOfEmploymentType` field. */
  lossOfEmploymentType?: Maybe<StringFilter>;
  /** Filter by the object’s `lossOfEmploymentName` field. */
  lossOfEmploymentName?: Maybe<StringFilter>;
  /** Filter by the object’s `lossOfEmploymentSale` field. */
  lossOfEmploymentSale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `lossOfEmploymentCost` field. */
  lossOfEmploymentCost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `lossOfEmploymentTerm` field. */
  lossOfEmploymentTerm?: Maybe<IntFilter>;
  /** Filter by the object’s `guaranteedAutoProtectionType` field. */
  guaranteedAutoProtectionType?: Maybe<StringFilter>;
  /** Filter by the object’s `guaranteedAutoProtectionName` field. */
  guaranteedAutoProtectionName?: Maybe<StringFilter>;
  /** Filter by the object’s `guaranteedAutoProtectionSale` field. */
  guaranteedAutoProtectionSale?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `guaranteedAutoProtectionCost` field. */
  guaranteedAutoProtectionCost?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `guaranteedAutoProtectionTerm` field. */
  guaranteedAutoProtectionTerm?: Maybe<IntFilter>;
  /** Filter by the object’s `saleComments` field. */
  saleComments?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdLine1` field. */
  cassStdLine1?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdLine2` field. */
  cassStdLine2?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdCity` field. */
  cassStdCity?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdState` field. */
  cassStdState?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdZip` field. */
  cassStdZip?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdZip4` field. */
  cassStdZip4?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdDpbc` field. */
  cassStdDpbc?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdChkdgt` field. */
  cassStdChkdgt?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdCart` field. */
  cassStdCart?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdLot` field. */
  cassStdLot?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdLotord` field. */
  cassStdLotord?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdUrb` field. */
  cassStdUrb?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdFips` field. */
  cassStdFips?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdEws` field. */
  cassStdEws?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdLacs` field. */
  cassStdLacs?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdZipmov` field. */
  cassStdZipmov?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdZ4Lom` field. */
  cassStdZ4Lom?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdNdiapt` field. */
  cassStdNdiapt?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdNdirr` field. */
  cassStdNdirr?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdLacsrt` field. */
  cassStdLacsrt?: Maybe<StringFilter>;
  /** Filter by the object’s `cassStdErrorCd` field. */
  cassStdErrorCd?: Maybe<StringFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** A related `lead` exists. */
  leadExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyIntegration` relation. */
  companyIntegration?: Maybe<CompanyIntegrationFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<DealFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<DealFilter>>;
  /** Negates the expression. */
  not?: Maybe<DealFilter>;
};

/** An input for mutations affecting `Deal` */
export type DealInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  fileType?: Maybe<Scalars['String']>;
  dvDealerId?: Maybe<Scalars['String']>;
  vendorDealerId?: Maybe<Scalars['String']>;
  dmsType?: Maybe<Scalars['String']>;
  dealNumber?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  workExtension?: Maybe<Scalars['String']>;
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  email3?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  individualBusinessFlag?: Maybe<Scalars['String']>;
  optOut?: Maybe<Scalars['Boolean']>;
  blockEmail?: Maybe<Scalars['Boolean']>;
  blockPhone?: Maybe<Scalars['Boolean']>;
  blockMail?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  customerCreateDate?: Maybe<Scalars['Date']>;
  customerLastActivityDate?: Maybe<Scalars['Date']>;
  coBuyerCustomerNumber?: Maybe<Scalars['String']>;
  coBuyerFullName?: Maybe<Scalars['String']>;
  coBuyerSalutation?: Maybe<Scalars['String']>;
  coBuyerFirstName?: Maybe<Scalars['String']>;
  coBuyerMiddleName?: Maybe<Scalars['String']>;
  coBuyerLastName?: Maybe<Scalars['String']>;
  coBuyerSuffix?: Maybe<Scalars['String']>;
  coBuyerAddressLine1?: Maybe<Scalars['String']>;
  coBuyerAddressLine2?: Maybe<Scalars['String']>;
  coBuyerCity?: Maybe<Scalars['String']>;
  coBuyerState?: Maybe<Scalars['String']>;
  coBuyerZip?: Maybe<Scalars['String']>;
  coBuyerCounty?: Maybe<Scalars['String']>;
  coBuyerHomePhone?: Maybe<Scalars['String']>;
  coBuyerCellPhone?: Maybe<Scalars['String']>;
  coBuyerWorkPhone?: Maybe<Scalars['String']>;
  coBuyerWorkExtension?: Maybe<Scalars['String']>;
  coBuyerEmail1?: Maybe<Scalars['String']>;
  coBuyerEmail2?: Maybe<Scalars['String']>;
  coBuyerEmail3?: Maybe<Scalars['String']>;
  coBuyerBirthDate?: Maybe<Scalars['Date']>;
  coBuyerIndividualBusinessFlag?: Maybe<Scalars['String']>;
  coBuyerOptOut?: Maybe<Scalars['Boolean']>;
  coBuyerBlockEmail?: Maybe<Scalars['Boolean']>;
  coBuyerBlockPhone?: Maybe<Scalars['Boolean']>;
  coBuyerBlockMail?: Maybe<Scalars['Boolean']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  newUsed?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  engineConfiguration?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  engineNumber?: Maybe<Scalars['String']>;
  chassisNumber?: Maybe<Scalars['String']>;
  licensePlateNumber?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  deliveryMileage?: Maybe<Scalars['Int']>;
  inventoryDate?: Maybe<Scalars['Date']>;
  inServiceDate?: Maybe<Scalars['Date']>;
  vinExplosionYear?: Maybe<Scalars['Int']>;
  vinExplosionMake?: Maybe<Scalars['String']>;
  vinExplosionModel?: Maybe<Scalars['String']>;
  vinExplosionTrim?: Maybe<Scalars['String']>;
  vinExplosionTransmissionType?: Maybe<Scalars['String']>;
  vinExplosionFuelType?: Maybe<Scalars['String']>;
  vinExplosionEngineSize?: Maybe<Scalars['String']>;
  vinExplosionGvwRange?: Maybe<Scalars['String']>;
  trade1Vin?: Maybe<Scalars['String']>;
  trade1Year?: Maybe<Scalars['Int']>;
  trade1Make?: Maybe<Scalars['String']>;
  trade1Model?: Maybe<Scalars['String']>;
  trade1Odometer?: Maybe<Scalars['Int']>;
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  trade2Vin?: Maybe<Scalars['String']>;
  trade2Year?: Maybe<Scalars['Int']>;
  trade2Make?: Maybe<Scalars['String']>;
  trade2Model?: Maybe<Scalars['String']>;
  trade2Odometer?: Maybe<Scalars['Int']>;
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  salesman1Number?: Maybe<Scalars['String']>;
  salesman1Name?: Maybe<Scalars['String']>;
  salesman2Number?: Maybe<Scalars['String']>;
  salesman2Name?: Maybe<Scalars['String']>;
  salesman3Number?: Maybe<Scalars['String']>;
  salesman3Name?: Maybe<Scalars['String']>;
  closingManagerNumber?: Maybe<Scalars['String']>;
  closingManagerName?: Maybe<Scalars['String']>;
  financeManagerNumber?: Maybe<Scalars['String']>;
  financeManagerName?: Maybe<Scalars['String']>;
  salesmanManagerNumber?: Maybe<Scalars['String']>;
  salesmanManagerName?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['BigFloat']>;
  listPrice?: Maybe<Scalars['BigFloat']>;
  salesPrice?: Maybe<Scalars['BigFloat']>;
  journalPrice?: Maybe<Scalars['BigFloat']>;
  cost?: Maybe<Scalars['BigFloat']>;
  journalCost?: Maybe<Scalars['BigFloat']>;
  adjustments?: Maybe<Scalars['BigFloat']>;
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  incentives?: Maybe<Scalars['BigFloat']>;
  packAmount?: Maybe<Scalars['BigFloat']>;
  saleNet?: Maybe<Scalars['BigFloat']>;
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  weOweFront?: Maybe<Scalars['BigFloat']>;
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  frontGross?: Maybe<Scalars['BigFloat']>;
  financeProfit?: Maybe<Scalars['BigFloat']>;
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  weOweBack?: Maybe<Scalars['BigFloat']>;
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  financeReserve?: Maybe<Scalars['BigFloat']>;
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  backGross?: Maybe<Scalars['BigFloat']>;
  totalProfit?: Maybe<Scalars['BigFloat']>;
  grossProfit?: Maybe<Scalars['BigFloat']>;
  grossPayable?: Maybe<Scalars['BigFloat']>;
  dealStatus?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  bookedDate?: Maybe<Scalars['Date']>;
  finalizedDate?: Maybe<Scalars['Date']>;
  contractDate?: Maybe<Scalars['Date']>;
  accountingDate?: Maybe<Scalars['Date']>;
  statusChangeDate?: Maybe<Scalars['Date']>;
  firstPayDate?: Maybe<Scalars['Date']>;
  dealType?: Maybe<Scalars['String']>;
  saleType?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  apr?: Maybe<Scalars['BigFloat']>;
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  rebates?: Maybe<Scalars['BigFloat']>;
  deposit?: Maybe<Scalars['BigFloat']>;
  downPayment?: Maybe<Scalars['BigFloat']>;
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  totalDown?: Maybe<Scalars['BigFloat']>;
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  licenseFee?: Maybe<Scalars['BigFloat']>;
  registrationFee?: Maybe<Scalars['BigFloat']>;
  documentationFee?: Maybe<Scalars['BigFloat']>;
  financeCharge?: Maybe<Scalars['BigFloat']>;
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  sellRate?: Maybe<Scalars['BigFloat']>;
  buyRate?: Maybe<Scalars['BigFloat']>;
  residualRate?: Maybe<Scalars['BigFloat']>;
  residualAmount?: Maybe<Scalars['BigFloat']>;
  allowedMiles?: Maybe<Scalars['Int']>;
  estimatedMiles?: Maybe<Scalars['Int']>;
  mileageRate?: Maybe<Scalars['BigFloat']>;
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  basePayment?: Maybe<Scalars['BigFloat']>;
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  dealerFees?: Maybe<Scalars['BigFloat']>;
  governmentFees?: Maybe<Scalars['BigFloat']>;
  totalTax?: Maybe<Scalars['BigFloat']>;
  registrationState?: Maybe<Scalars['String']>;
  reportOfSaleNumber?: Maybe<Scalars['String']>;
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  warranty1Name?: Maybe<Scalars['String']>;
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  warranty1Miles?: Maybe<Scalars['Int']>;
  warranty1Term?: Maybe<Scalars['Int']>;
  warranty2Name?: Maybe<Scalars['String']>;
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  warranty2Miles?: Maybe<Scalars['Int']>;
  warranty2Term?: Maybe<Scalars['Int']>;
  warranty3Name?: Maybe<Scalars['String']>;
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  warranty3Miles?: Maybe<Scalars['Int']>;
  warranty3Term?: Maybe<Scalars['Int']>;
  warranty4Name?: Maybe<Scalars['String']>;
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  warranty4Miles?: Maybe<Scalars['Int']>;
  warranty4Term?: Maybe<Scalars['Int']>;
  warranty5Name?: Maybe<Scalars['String']>;
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  warranty5Miles?: Maybe<Scalars['Int']>;
  warranty5Term?: Maybe<Scalars['Int']>;
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Name?: Maybe<Scalars['String']>;
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket2Name?: Maybe<Scalars['String']>;
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket3Name?: Maybe<Scalars['String']>;
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket4Name?: Maybe<Scalars['String']>;
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket5Name?: Maybe<Scalars['String']>;
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket6Name?: Maybe<Scalars['String']>;
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket7Name?: Maybe<Scalars['String']>;
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket8Name?: Maybe<Scalars['String']>;
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket9Name?: Maybe<Scalars['String']>;
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket10Name?: Maybe<Scalars['String']>;
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket11Name?: Maybe<Scalars['String']>;
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket12Name?: Maybe<Scalars['String']>;
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket13Name?: Maybe<Scalars['String']>;
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket14Name?: Maybe<Scalars['String']>;
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket15Name?: Maybe<Scalars['String']>;
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket16Name?: Maybe<Scalars['String']>;
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket17Name?: Maybe<Scalars['String']>;
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket18Name?: Maybe<Scalars['String']>;
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket19Name?: Maybe<Scalars['String']>;
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket20Name?: Maybe<Scalars['String']>;
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20ProfitIndicator?: Maybe<Scalars['Boolean']>;
  insurance1Type?: Maybe<Scalars['String']>;
  insurance1Name?: Maybe<Scalars['String']>;
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  insurance1Term?: Maybe<Scalars['Int']>;
  insurance2Type?: Maybe<Scalars['String']>;
  insurance2Name?: Maybe<Scalars['String']>;
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  insurance2Term?: Maybe<Scalars['Int']>;
  insurance3Type?: Maybe<Scalars['String']>;
  insurance3Name?: Maybe<Scalars['String']>;
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  insurance3Term?: Maybe<Scalars['Int']>;
  accidentalHealthType?: Maybe<Scalars['String']>;
  accidentalHealthName?: Maybe<Scalars['String']>;
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  accidentalHealthTerm?: Maybe<Scalars['Int']>;
  creditLifeType?: Maybe<Scalars['String']>;
  creditLifeName?: Maybe<Scalars['String']>;
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  creditLifeTerm?: Maybe<Scalars['Int']>;
  levelizedLifeType?: Maybe<Scalars['String']>;
  levelizedLifeName?: Maybe<Scalars['String']>;
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  levelizedLifeTerm?: Maybe<Scalars['Int']>;
  lossOfEmploymentType?: Maybe<Scalars['String']>;
  lossOfEmploymentName?: Maybe<Scalars['String']>;
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentTerm?: Maybe<Scalars['Int']>;
  guaranteedAutoProtectionType?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionName?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionTerm?: Maybe<Scalars['Int']>;
  saleComments?: Maybe<Scalars['String']>;
  cassStdLine1?: Maybe<Scalars['String']>;
  cassStdLine2?: Maybe<Scalars['String']>;
  cassStdCity?: Maybe<Scalars['String']>;
  cassStdState?: Maybe<Scalars['String']>;
  cassStdZip?: Maybe<Scalars['String']>;
  cassStdZip4?: Maybe<Scalars['String']>;
  cassStdDpbc?: Maybe<Scalars['String']>;
  cassStdChkdgt?: Maybe<Scalars['String']>;
  cassStdCart?: Maybe<Scalars['String']>;
  cassStdLot?: Maybe<Scalars['String']>;
  cassStdLotord?: Maybe<Scalars['String']>;
  cassStdUrb?: Maybe<Scalars['String']>;
  cassStdFips?: Maybe<Scalars['String']>;
  cassStdEws?: Maybe<Scalars['String']>;
  cassStdLacs?: Maybe<Scalars['String']>;
  cassStdZipmov?: Maybe<Scalars['String']>;
  cassStdZ4Lom?: Maybe<Scalars['String']>;
  cassStdNdiapt?: Maybe<Scalars['String']>;
  cassStdNdirr?: Maybe<Scalars['String']>;
  cassStdLacsrt?: Maybe<Scalars['String']>;
  cassStdErrorCd?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<DealsLeadIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<DealsCompanyIntegrationIdFkeyInput>;
};

export type DealMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  year?: Maybe<IntFilter>;
  mileage?: Maybe<IntFilter>;
  deliveryMileage?: Maybe<IntFilter>;
  vinExplosionYear?: Maybe<IntFilter>;
  trade1Year?: Maybe<IntFilter>;
  trade1Odometer?: Maybe<IntFilter>;
  trade1ActualCashValue?: Maybe<BigFloatFilter>;
  trade1Gross?: Maybe<BigFloatFilter>;
  trade1Payoff?: Maybe<BigFloatFilter>;
  trade2Year?: Maybe<IntFilter>;
  trade2Odometer?: Maybe<IntFilter>;
  trade2ActualCashValue?: Maybe<BigFloatFilter>;
  trade2Gross?: Maybe<BigFloatFilter>;
  trade2Payoff?: Maybe<BigFloatFilter>;
  msrp?: Maybe<BigFloatFilter>;
  listPrice?: Maybe<BigFloatFilter>;
  salesPrice?: Maybe<BigFloatFilter>;
  journalPrice?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
  journalCost?: Maybe<BigFloatFilter>;
  adjustments?: Maybe<BigFloatFilter>;
  adjustedCost?: Maybe<BigFloatFilter>;
  incentives?: Maybe<BigFloatFilter>;
  packAmount?: Maybe<BigFloatFilter>;
  saleNet?: Maybe<BigFloatFilter>;
  totalTradeActualCashValue?: Maybe<BigFloatFilter>;
  totalTradeGross?: Maybe<BigFloatFilter>;
  weOweFront?: Maybe<BigFloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  totalFrontCommission?: Maybe<BigFloatFilter>;
  totalFrontSales?: Maybe<BigFloatFilter>;
  totalFrontCost?: Maybe<BigFloatFilter>;
  frontGross?: Maybe<BigFloatFilter>;
  financeProfit?: Maybe<BigFloatFilter>;
  totalWarrantyProfit?: Maybe<BigFloatFilter>;
  weOweBack?: Maybe<BigFloatFilter>;
  insuranceProfit?: Maybe<BigFloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  financeReserve?: Maybe<BigFloatFilter>;
  totalBackCommission?: Maybe<BigFloatFilter>;
  totalBackSales?: Maybe<BigFloatFilter>;
  totalBackCost?: Maybe<BigFloatFilter>;
  backGross?: Maybe<BigFloatFilter>;
  totalProfit?: Maybe<BigFloatFilter>;
  grossProfit?: Maybe<BigFloatFilter>;
  grossPayable?: Maybe<BigFloatFilter>;
  term?: Maybe<IntFilter>;
  amountFinanced?: Maybe<BigFloatFilter>;
  apr?: Maybe<BigFloatFilter>;
  monthlyPayment?: Maybe<BigFloatFilter>;
  paymentTotal?: Maybe<BigFloatFilter>;
  rebates?: Maybe<BigFloatFilter>;
  deposit?: Maybe<BigFloatFilter>;
  downPayment?: Maybe<BigFloatFilter>;
  totalNetTrades?: Maybe<BigFloatFilter>;
  totalDown?: Maybe<BigFloatFilter>;
  balloonAmount?: Maybe<BigFloatFilter>;
  adjustedBalloonAmount?: Maybe<BigFloatFilter>;
  holdbackAmount?: Maybe<BigFloatFilter>;
  totalDriveOffAmount?: Maybe<BigFloatFilter>;
  licenseFee?: Maybe<BigFloatFilter>;
  registrationFee?: Maybe<BigFloatFilter>;
  documentationFee?: Maybe<BigFloatFilter>;
  financeCharge?: Maybe<BigFloatFilter>;
  totalPickupPayments?: Maybe<BigFloatFilter>;
  sellRate?: Maybe<BigFloatFilter>;
  buyRate?: Maybe<BigFloatFilter>;
  residualRate?: Maybe<BigFloatFilter>;
  residualAmount?: Maybe<BigFloatFilter>;
  allowedMiles?: Maybe<IntFilter>;
  estimatedMiles?: Maybe<IntFilter>;
  mileageRate?: Maybe<BigFloatFilter>;
  acquisitionFee?: Maybe<BigFloatFilter>;
  basePayment?: Maybe<BigFloatFilter>;
  securityDeposit?: Maybe<BigFloatFilter>;
  totalCapitalReduction?: Maybe<BigFloatFilter>;
  netCapitalCost?: Maybe<BigFloatFilter>;
  leaseDepreciationValue?: Maybe<BigFloatFilter>;
  dealerFees?: Maybe<BigFloatFilter>;
  governmentFees?: Maybe<BigFloatFilter>;
  totalTax?: Maybe<BigFloatFilter>;
  salesman1TotalCommission?: Maybe<BigFloatFilter>;
  salesman1FrontCommission?: Maybe<BigFloatFilter>;
  salesman1BackCommission?: Maybe<BigFloatFilter>;
  salesman2TotalCommission?: Maybe<BigFloatFilter>;
  salesman2FrontCommission?: Maybe<BigFloatFilter>;
  salesman2BackCommission?: Maybe<BigFloatFilter>;
  salesman3TotalCommission?: Maybe<BigFloatFilter>;
  salesman3FrontCommission?: Maybe<BigFloatFilter>;
  salesman3BackCommission?: Maybe<BigFloatFilter>;
  warranty1Sale?: Maybe<BigFloatFilter>;
  warranty1Cost?: Maybe<BigFloatFilter>;
  warranty1Miles?: Maybe<IntFilter>;
  warranty1Term?: Maybe<IntFilter>;
  warranty2Sale?: Maybe<BigFloatFilter>;
  warranty2Cost?: Maybe<BigFloatFilter>;
  warranty2Miles?: Maybe<IntFilter>;
  warranty2Term?: Maybe<IntFilter>;
  warranty3Sale?: Maybe<BigFloatFilter>;
  warranty3Cost?: Maybe<BigFloatFilter>;
  warranty3Miles?: Maybe<IntFilter>;
  warranty3Term?: Maybe<IntFilter>;
  warranty4Sale?: Maybe<BigFloatFilter>;
  warranty4Cost?: Maybe<BigFloatFilter>;
  warranty4Miles?: Maybe<IntFilter>;
  warranty4Term?: Maybe<IntFilter>;
  warranty5Sale?: Maybe<BigFloatFilter>;
  warranty5Cost?: Maybe<BigFloatFilter>;
  warranty5Miles?: Maybe<IntFilter>;
  warranty5Term?: Maybe<IntFilter>;
  totalFeeAftermarketSale?: Maybe<BigFloatFilter>;
  totalFeeAftermarketCost?: Maybe<BigFloatFilter>;
  feeAftermarket1Sale?: Maybe<BigFloatFilter>;
  feeAftermarket1Cost?: Maybe<BigFloatFilter>;
  feeAftermarket2Sale?: Maybe<BigFloatFilter>;
  feeAftermarket2Cost?: Maybe<BigFloatFilter>;
  feeAftermarket3Sale?: Maybe<BigFloatFilter>;
  feeAftermarket3Cost?: Maybe<BigFloatFilter>;
  feeAftermarket4Sale?: Maybe<BigFloatFilter>;
  feeAftermarket4Cost?: Maybe<BigFloatFilter>;
  feeAftermarket5Sale?: Maybe<BigFloatFilter>;
  feeAftermarket5Cost?: Maybe<BigFloatFilter>;
  feeAftermarket6Sale?: Maybe<BigFloatFilter>;
  feeAftermarket6Cost?: Maybe<BigFloatFilter>;
  feeAftermarket7Sale?: Maybe<BigFloatFilter>;
  feeAftermarket7Cost?: Maybe<BigFloatFilter>;
  feeAftermarket8Sale?: Maybe<BigFloatFilter>;
  feeAftermarket8Cost?: Maybe<BigFloatFilter>;
  feeAftermarket9Sale?: Maybe<BigFloatFilter>;
  feeAftermarket9Cost?: Maybe<BigFloatFilter>;
  feeAftermarket10Sale?: Maybe<BigFloatFilter>;
  feeAftermarket10Cost?: Maybe<BigFloatFilter>;
  feeAftermarket11Sale?: Maybe<BigFloatFilter>;
  feeAftermarket11Cost?: Maybe<BigFloatFilter>;
  feeAftermarket12Sale?: Maybe<BigFloatFilter>;
  feeAftermarket12Cost?: Maybe<BigFloatFilter>;
  feeAftermarket13Sale?: Maybe<BigFloatFilter>;
  feeAftermarket13Cost?: Maybe<BigFloatFilter>;
  feeAftermarket14Sale?: Maybe<BigFloatFilter>;
  feeAftermarket14Cost?: Maybe<BigFloatFilter>;
  feeAftermarket15Sale?: Maybe<BigFloatFilter>;
  feeAftermarket15Cost?: Maybe<BigFloatFilter>;
  feeAftermarket16Sale?: Maybe<BigFloatFilter>;
  feeAftermarket16Cost?: Maybe<BigFloatFilter>;
  feeAftermarket17Sale?: Maybe<BigFloatFilter>;
  feeAftermarket17Cost?: Maybe<BigFloatFilter>;
  feeAftermarket18Sale?: Maybe<BigFloatFilter>;
  feeAftermarket18Cost?: Maybe<BigFloatFilter>;
  feeAftermarket19Sale?: Maybe<BigFloatFilter>;
  feeAftermarket19Cost?: Maybe<BigFloatFilter>;
  feeAftermarket20Sale?: Maybe<BigFloatFilter>;
  feeAftermarket20Cost?: Maybe<BigFloatFilter>;
  insurance1Sale?: Maybe<BigFloatFilter>;
  insurance1Cost?: Maybe<BigFloatFilter>;
  insurance1Term?: Maybe<IntFilter>;
  insurance2Sale?: Maybe<BigFloatFilter>;
  insurance2Cost?: Maybe<BigFloatFilter>;
  insurance2Term?: Maybe<IntFilter>;
  insurance3Sale?: Maybe<BigFloatFilter>;
  insurance3Cost?: Maybe<BigFloatFilter>;
  insurance3Term?: Maybe<IntFilter>;
  accidentalHealthSale?: Maybe<BigFloatFilter>;
  accidentalHealthCost?: Maybe<BigFloatFilter>;
  accidentalHealthTerm?: Maybe<IntFilter>;
  creditLifeSale?: Maybe<BigFloatFilter>;
  creditLifeCost?: Maybe<BigFloatFilter>;
  creditLifeTerm?: Maybe<IntFilter>;
  levelizedLifeSale?: Maybe<BigFloatFilter>;
  levelizedLifeCost?: Maybe<BigFloatFilter>;
  levelizedLifeTerm?: Maybe<IntFilter>;
  lossOfEmploymentSale?: Maybe<BigFloatFilter>;
  lossOfEmploymentCost?: Maybe<BigFloatFilter>;
  lossOfEmploymentTerm?: Maybe<IntFilter>;
  guaranteedAutoProtectionSale?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<IntFilter>;
};

export type DealMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Maximum of year across the matching connection */
  year?: Maybe<Scalars['Int']>;
  /** Maximum of mileage across the matching connection */
  mileage?: Maybe<Scalars['Int']>;
  /** Maximum of deliveryMileage across the matching connection */
  deliveryMileage?: Maybe<Scalars['Int']>;
  /** Maximum of vinExplosionYear across the matching connection */
  vinExplosionYear?: Maybe<Scalars['Int']>;
  /** Maximum of trade1Year across the matching connection */
  trade1Year?: Maybe<Scalars['Int']>;
  /** Maximum of trade1Odometer across the matching connection */
  trade1Odometer?: Maybe<Scalars['Int']>;
  /** Maximum of trade1ActualCashValue across the matching connection */
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Maximum of trade1Gross across the matching connection */
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  /** Maximum of trade1Payoff across the matching connection */
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  /** Maximum of trade2Year across the matching connection */
  trade2Year?: Maybe<Scalars['Int']>;
  /** Maximum of trade2Odometer across the matching connection */
  trade2Odometer?: Maybe<Scalars['Int']>;
  /** Maximum of trade2ActualCashValue across the matching connection */
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Maximum of trade2Gross across the matching connection */
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  /** Maximum of trade2Payoff across the matching connection */
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  /** Maximum of msrp across the matching connection */
  msrp?: Maybe<Scalars['BigFloat']>;
  /** Maximum of listPrice across the matching connection */
  listPrice?: Maybe<Scalars['BigFloat']>;
  /** Maximum of salesPrice across the matching connection */
  salesPrice?: Maybe<Scalars['BigFloat']>;
  /** Maximum of journalPrice across the matching connection */
  journalPrice?: Maybe<Scalars['BigFloat']>;
  /** Maximum of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of journalCost across the matching connection */
  journalCost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of adjustments across the matching connection */
  adjustments?: Maybe<Scalars['BigFloat']>;
  /** Maximum of adjustedCost across the matching connection */
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of incentives across the matching connection */
  incentives?: Maybe<Scalars['BigFloat']>;
  /** Maximum of packAmount across the matching connection */
  packAmount?: Maybe<Scalars['BigFloat']>;
  /** Maximum of saleNet across the matching connection */
  saleNet?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalTradeActualCashValue across the matching connection */
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalTradeGross across the matching connection */
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  /** Maximum of weOweFront across the matching connection */
  weOweFront?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalFrontFeesAftermarketProfit across the matching connection */
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalFrontCommission across the matching connection */
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalFrontSales across the matching connection */
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalFrontCost across the matching connection */
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of frontGross across the matching connection */
  frontGross?: Maybe<Scalars['BigFloat']>;
  /** Maximum of financeProfit across the matching connection */
  financeProfit?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalWarrantyProfit across the matching connection */
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  /** Maximum of weOweBack across the matching connection */
  weOweBack?: Maybe<Scalars['BigFloat']>;
  /** Maximum of insuranceProfit across the matching connection */
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalBackFeesAftermarketProfit across the matching connection */
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Maximum of financeReserve across the matching connection */
  financeReserve?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalBackCommission across the matching connection */
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalBackSales across the matching connection */
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalBackCost across the matching connection */
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of backGross across the matching connection */
  backGross?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalProfit across the matching connection */
  totalProfit?: Maybe<Scalars['BigFloat']>;
  /** Maximum of grossProfit across the matching connection */
  grossProfit?: Maybe<Scalars['BigFloat']>;
  /** Maximum of grossPayable across the matching connection */
  grossPayable?: Maybe<Scalars['BigFloat']>;
  /** Maximum of term across the matching connection */
  term?: Maybe<Scalars['Int']>;
  /** Maximum of amountFinanced across the matching connection */
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  /** Maximum of apr across the matching connection */
  apr?: Maybe<Scalars['BigFloat']>;
  /** Maximum of monthlyPayment across the matching connection */
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  /** Maximum of paymentTotal across the matching connection */
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  /** Maximum of rebates across the matching connection */
  rebates?: Maybe<Scalars['BigFloat']>;
  /** Maximum of deposit across the matching connection */
  deposit?: Maybe<Scalars['BigFloat']>;
  /** Maximum of downPayment across the matching connection */
  downPayment?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalNetTrades across the matching connection */
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalDown across the matching connection */
  totalDown?: Maybe<Scalars['BigFloat']>;
  /** Maximum of balloonAmount across the matching connection */
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Maximum of adjustedBalloonAmount across the matching connection */
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Maximum of holdbackAmount across the matching connection */
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalDriveOffAmount across the matching connection */
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  /** Maximum of licenseFee across the matching connection */
  licenseFee?: Maybe<Scalars['BigFloat']>;
  /** Maximum of registrationFee across the matching connection */
  registrationFee?: Maybe<Scalars['BigFloat']>;
  /** Maximum of documentationFee across the matching connection */
  documentationFee?: Maybe<Scalars['BigFloat']>;
  /** Maximum of financeCharge across the matching connection */
  financeCharge?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalPickupPayments across the matching connection */
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  /** Maximum of sellRate across the matching connection */
  sellRate?: Maybe<Scalars['BigFloat']>;
  /** Maximum of buyRate across the matching connection */
  buyRate?: Maybe<Scalars['BigFloat']>;
  /** Maximum of residualRate across the matching connection */
  residualRate?: Maybe<Scalars['BigFloat']>;
  /** Maximum of residualAmount across the matching connection */
  residualAmount?: Maybe<Scalars['BigFloat']>;
  /** Maximum of allowedMiles across the matching connection */
  allowedMiles?: Maybe<Scalars['Int']>;
  /** Maximum of estimatedMiles across the matching connection */
  estimatedMiles?: Maybe<Scalars['Int']>;
  /** Maximum of mileageRate across the matching connection */
  mileageRate?: Maybe<Scalars['BigFloat']>;
  /** Maximum of acquisitionFee across the matching connection */
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  /** Maximum of basePayment across the matching connection */
  basePayment?: Maybe<Scalars['BigFloat']>;
  /** Maximum of securityDeposit across the matching connection */
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalCapitalReduction across the matching connection */
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  /** Maximum of netCapitalCost across the matching connection */
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of leaseDepreciationValue across the matching connection */
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  /** Maximum of dealerFees across the matching connection */
  dealerFees?: Maybe<Scalars['BigFloat']>;
  /** Maximum of governmentFees across the matching connection */
  governmentFees?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalTax across the matching connection */
  totalTax?: Maybe<Scalars['BigFloat']>;
  /** Maximum of salesman1TotalCommission across the matching connection */
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Maximum of salesman1FrontCommission across the matching connection */
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Maximum of salesman1BackCommission across the matching connection */
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Maximum of salesman2TotalCommission across the matching connection */
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Maximum of salesman2FrontCommission across the matching connection */
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Maximum of salesman2BackCommission across the matching connection */
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Maximum of salesman3TotalCommission across the matching connection */
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Maximum of salesman3FrontCommission across the matching connection */
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Maximum of salesman3BackCommission across the matching connection */
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Maximum of warranty1Sale across the matching connection */
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of warranty1Cost across the matching connection */
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of warranty1Miles across the matching connection */
  warranty1Miles?: Maybe<Scalars['Int']>;
  /** Maximum of warranty1Term across the matching connection */
  warranty1Term?: Maybe<Scalars['Int']>;
  /** Maximum of warranty2Sale across the matching connection */
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of warranty2Cost across the matching connection */
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of warranty2Miles across the matching connection */
  warranty2Miles?: Maybe<Scalars['Int']>;
  /** Maximum of warranty2Term across the matching connection */
  warranty2Term?: Maybe<Scalars['Int']>;
  /** Maximum of warranty3Sale across the matching connection */
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of warranty3Cost across the matching connection */
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of warranty3Miles across the matching connection */
  warranty3Miles?: Maybe<Scalars['Int']>;
  /** Maximum of warranty3Term across the matching connection */
  warranty3Term?: Maybe<Scalars['Int']>;
  /** Maximum of warranty4Sale across the matching connection */
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of warranty4Cost across the matching connection */
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of warranty4Miles across the matching connection */
  warranty4Miles?: Maybe<Scalars['Int']>;
  /** Maximum of warranty4Term across the matching connection */
  warranty4Term?: Maybe<Scalars['Int']>;
  /** Maximum of warranty5Sale across the matching connection */
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of warranty5Cost across the matching connection */
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of warranty5Miles across the matching connection */
  warranty5Miles?: Maybe<Scalars['Int']>;
  /** Maximum of warranty5Term across the matching connection */
  warranty5Term?: Maybe<Scalars['Int']>;
  /** Maximum of totalFeeAftermarketSale across the matching connection */
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of totalFeeAftermarketCost across the matching connection */
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket1Sale across the matching connection */
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket1Cost across the matching connection */
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket2Sale across the matching connection */
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket2Cost across the matching connection */
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket3Sale across the matching connection */
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket3Cost across the matching connection */
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket4Sale across the matching connection */
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket4Cost across the matching connection */
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket5Sale across the matching connection */
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket5Cost across the matching connection */
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket6Sale across the matching connection */
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket6Cost across the matching connection */
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket7Sale across the matching connection */
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket7Cost across the matching connection */
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket8Sale across the matching connection */
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket8Cost across the matching connection */
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket9Sale across the matching connection */
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket9Cost across the matching connection */
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket10Sale across the matching connection */
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket10Cost across the matching connection */
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket11Sale across the matching connection */
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket11Cost across the matching connection */
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket12Sale across the matching connection */
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket12Cost across the matching connection */
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket13Sale across the matching connection */
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket13Cost across the matching connection */
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket14Sale across the matching connection */
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket14Cost across the matching connection */
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket15Sale across the matching connection */
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket15Cost across the matching connection */
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket16Sale across the matching connection */
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket16Cost across the matching connection */
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket17Sale across the matching connection */
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket17Cost across the matching connection */
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket18Sale across the matching connection */
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket18Cost across the matching connection */
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket19Sale across the matching connection */
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket19Cost across the matching connection */
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket20Sale across the matching connection */
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of feeAftermarket20Cost across the matching connection */
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of insurance1Sale across the matching connection */
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of insurance1Cost across the matching connection */
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of insurance1Term across the matching connection */
  insurance1Term?: Maybe<Scalars['Int']>;
  /** Maximum of insurance2Sale across the matching connection */
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of insurance2Cost across the matching connection */
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of insurance2Term across the matching connection */
  insurance2Term?: Maybe<Scalars['Int']>;
  /** Maximum of insurance3Sale across the matching connection */
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of insurance3Cost across the matching connection */
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of insurance3Term across the matching connection */
  insurance3Term?: Maybe<Scalars['Int']>;
  /** Maximum of accidentalHealthSale across the matching connection */
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of accidentalHealthCost across the matching connection */
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of accidentalHealthTerm across the matching connection */
  accidentalHealthTerm?: Maybe<Scalars['Int']>;
  /** Maximum of creditLifeSale across the matching connection */
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of creditLifeCost across the matching connection */
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of creditLifeTerm across the matching connection */
  creditLifeTerm?: Maybe<Scalars['Int']>;
  /** Maximum of levelizedLifeSale across the matching connection */
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of levelizedLifeCost across the matching connection */
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of levelizedLifeTerm across the matching connection */
  levelizedLifeTerm?: Maybe<Scalars['Int']>;
  /** Maximum of lossOfEmploymentSale across the matching connection */
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of lossOfEmploymentCost across the matching connection */
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of lossOfEmploymentTerm across the matching connection */
  lossOfEmploymentTerm?: Maybe<Scalars['Int']>;
  /** Maximum of guaranteedAutoProtectionSale across the matching connection */
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  /** Maximum of guaranteedAutoProtectionCost across the matching connection */
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  /** Maximum of guaranteedAutoProtectionTerm across the matching connection */
  guaranteedAutoProtectionTerm?: Maybe<Scalars['Int']>;
};

export type DealMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  year?: Maybe<IntFilter>;
  mileage?: Maybe<IntFilter>;
  deliveryMileage?: Maybe<IntFilter>;
  vinExplosionYear?: Maybe<IntFilter>;
  trade1Year?: Maybe<IntFilter>;
  trade1Odometer?: Maybe<IntFilter>;
  trade1ActualCashValue?: Maybe<BigFloatFilter>;
  trade1Gross?: Maybe<BigFloatFilter>;
  trade1Payoff?: Maybe<BigFloatFilter>;
  trade2Year?: Maybe<IntFilter>;
  trade2Odometer?: Maybe<IntFilter>;
  trade2ActualCashValue?: Maybe<BigFloatFilter>;
  trade2Gross?: Maybe<BigFloatFilter>;
  trade2Payoff?: Maybe<BigFloatFilter>;
  msrp?: Maybe<BigFloatFilter>;
  listPrice?: Maybe<BigFloatFilter>;
  salesPrice?: Maybe<BigFloatFilter>;
  journalPrice?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
  journalCost?: Maybe<BigFloatFilter>;
  adjustments?: Maybe<BigFloatFilter>;
  adjustedCost?: Maybe<BigFloatFilter>;
  incentives?: Maybe<BigFloatFilter>;
  packAmount?: Maybe<BigFloatFilter>;
  saleNet?: Maybe<BigFloatFilter>;
  totalTradeActualCashValue?: Maybe<BigFloatFilter>;
  totalTradeGross?: Maybe<BigFloatFilter>;
  weOweFront?: Maybe<BigFloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  totalFrontCommission?: Maybe<BigFloatFilter>;
  totalFrontSales?: Maybe<BigFloatFilter>;
  totalFrontCost?: Maybe<BigFloatFilter>;
  frontGross?: Maybe<BigFloatFilter>;
  financeProfit?: Maybe<BigFloatFilter>;
  totalWarrantyProfit?: Maybe<BigFloatFilter>;
  weOweBack?: Maybe<BigFloatFilter>;
  insuranceProfit?: Maybe<BigFloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  financeReserve?: Maybe<BigFloatFilter>;
  totalBackCommission?: Maybe<BigFloatFilter>;
  totalBackSales?: Maybe<BigFloatFilter>;
  totalBackCost?: Maybe<BigFloatFilter>;
  backGross?: Maybe<BigFloatFilter>;
  totalProfit?: Maybe<BigFloatFilter>;
  grossProfit?: Maybe<BigFloatFilter>;
  grossPayable?: Maybe<BigFloatFilter>;
  term?: Maybe<IntFilter>;
  amountFinanced?: Maybe<BigFloatFilter>;
  apr?: Maybe<BigFloatFilter>;
  monthlyPayment?: Maybe<BigFloatFilter>;
  paymentTotal?: Maybe<BigFloatFilter>;
  rebates?: Maybe<BigFloatFilter>;
  deposit?: Maybe<BigFloatFilter>;
  downPayment?: Maybe<BigFloatFilter>;
  totalNetTrades?: Maybe<BigFloatFilter>;
  totalDown?: Maybe<BigFloatFilter>;
  balloonAmount?: Maybe<BigFloatFilter>;
  adjustedBalloonAmount?: Maybe<BigFloatFilter>;
  holdbackAmount?: Maybe<BigFloatFilter>;
  totalDriveOffAmount?: Maybe<BigFloatFilter>;
  licenseFee?: Maybe<BigFloatFilter>;
  registrationFee?: Maybe<BigFloatFilter>;
  documentationFee?: Maybe<BigFloatFilter>;
  financeCharge?: Maybe<BigFloatFilter>;
  totalPickupPayments?: Maybe<BigFloatFilter>;
  sellRate?: Maybe<BigFloatFilter>;
  buyRate?: Maybe<BigFloatFilter>;
  residualRate?: Maybe<BigFloatFilter>;
  residualAmount?: Maybe<BigFloatFilter>;
  allowedMiles?: Maybe<IntFilter>;
  estimatedMiles?: Maybe<IntFilter>;
  mileageRate?: Maybe<BigFloatFilter>;
  acquisitionFee?: Maybe<BigFloatFilter>;
  basePayment?: Maybe<BigFloatFilter>;
  securityDeposit?: Maybe<BigFloatFilter>;
  totalCapitalReduction?: Maybe<BigFloatFilter>;
  netCapitalCost?: Maybe<BigFloatFilter>;
  leaseDepreciationValue?: Maybe<BigFloatFilter>;
  dealerFees?: Maybe<BigFloatFilter>;
  governmentFees?: Maybe<BigFloatFilter>;
  totalTax?: Maybe<BigFloatFilter>;
  salesman1TotalCommission?: Maybe<BigFloatFilter>;
  salesman1FrontCommission?: Maybe<BigFloatFilter>;
  salesman1BackCommission?: Maybe<BigFloatFilter>;
  salesman2TotalCommission?: Maybe<BigFloatFilter>;
  salesman2FrontCommission?: Maybe<BigFloatFilter>;
  salesman2BackCommission?: Maybe<BigFloatFilter>;
  salesman3TotalCommission?: Maybe<BigFloatFilter>;
  salesman3FrontCommission?: Maybe<BigFloatFilter>;
  salesman3BackCommission?: Maybe<BigFloatFilter>;
  warranty1Sale?: Maybe<BigFloatFilter>;
  warranty1Cost?: Maybe<BigFloatFilter>;
  warranty1Miles?: Maybe<IntFilter>;
  warranty1Term?: Maybe<IntFilter>;
  warranty2Sale?: Maybe<BigFloatFilter>;
  warranty2Cost?: Maybe<BigFloatFilter>;
  warranty2Miles?: Maybe<IntFilter>;
  warranty2Term?: Maybe<IntFilter>;
  warranty3Sale?: Maybe<BigFloatFilter>;
  warranty3Cost?: Maybe<BigFloatFilter>;
  warranty3Miles?: Maybe<IntFilter>;
  warranty3Term?: Maybe<IntFilter>;
  warranty4Sale?: Maybe<BigFloatFilter>;
  warranty4Cost?: Maybe<BigFloatFilter>;
  warranty4Miles?: Maybe<IntFilter>;
  warranty4Term?: Maybe<IntFilter>;
  warranty5Sale?: Maybe<BigFloatFilter>;
  warranty5Cost?: Maybe<BigFloatFilter>;
  warranty5Miles?: Maybe<IntFilter>;
  warranty5Term?: Maybe<IntFilter>;
  totalFeeAftermarketSale?: Maybe<BigFloatFilter>;
  totalFeeAftermarketCost?: Maybe<BigFloatFilter>;
  feeAftermarket1Sale?: Maybe<BigFloatFilter>;
  feeAftermarket1Cost?: Maybe<BigFloatFilter>;
  feeAftermarket2Sale?: Maybe<BigFloatFilter>;
  feeAftermarket2Cost?: Maybe<BigFloatFilter>;
  feeAftermarket3Sale?: Maybe<BigFloatFilter>;
  feeAftermarket3Cost?: Maybe<BigFloatFilter>;
  feeAftermarket4Sale?: Maybe<BigFloatFilter>;
  feeAftermarket4Cost?: Maybe<BigFloatFilter>;
  feeAftermarket5Sale?: Maybe<BigFloatFilter>;
  feeAftermarket5Cost?: Maybe<BigFloatFilter>;
  feeAftermarket6Sale?: Maybe<BigFloatFilter>;
  feeAftermarket6Cost?: Maybe<BigFloatFilter>;
  feeAftermarket7Sale?: Maybe<BigFloatFilter>;
  feeAftermarket7Cost?: Maybe<BigFloatFilter>;
  feeAftermarket8Sale?: Maybe<BigFloatFilter>;
  feeAftermarket8Cost?: Maybe<BigFloatFilter>;
  feeAftermarket9Sale?: Maybe<BigFloatFilter>;
  feeAftermarket9Cost?: Maybe<BigFloatFilter>;
  feeAftermarket10Sale?: Maybe<BigFloatFilter>;
  feeAftermarket10Cost?: Maybe<BigFloatFilter>;
  feeAftermarket11Sale?: Maybe<BigFloatFilter>;
  feeAftermarket11Cost?: Maybe<BigFloatFilter>;
  feeAftermarket12Sale?: Maybe<BigFloatFilter>;
  feeAftermarket12Cost?: Maybe<BigFloatFilter>;
  feeAftermarket13Sale?: Maybe<BigFloatFilter>;
  feeAftermarket13Cost?: Maybe<BigFloatFilter>;
  feeAftermarket14Sale?: Maybe<BigFloatFilter>;
  feeAftermarket14Cost?: Maybe<BigFloatFilter>;
  feeAftermarket15Sale?: Maybe<BigFloatFilter>;
  feeAftermarket15Cost?: Maybe<BigFloatFilter>;
  feeAftermarket16Sale?: Maybe<BigFloatFilter>;
  feeAftermarket16Cost?: Maybe<BigFloatFilter>;
  feeAftermarket17Sale?: Maybe<BigFloatFilter>;
  feeAftermarket17Cost?: Maybe<BigFloatFilter>;
  feeAftermarket18Sale?: Maybe<BigFloatFilter>;
  feeAftermarket18Cost?: Maybe<BigFloatFilter>;
  feeAftermarket19Sale?: Maybe<BigFloatFilter>;
  feeAftermarket19Cost?: Maybe<BigFloatFilter>;
  feeAftermarket20Sale?: Maybe<BigFloatFilter>;
  feeAftermarket20Cost?: Maybe<BigFloatFilter>;
  insurance1Sale?: Maybe<BigFloatFilter>;
  insurance1Cost?: Maybe<BigFloatFilter>;
  insurance1Term?: Maybe<IntFilter>;
  insurance2Sale?: Maybe<BigFloatFilter>;
  insurance2Cost?: Maybe<BigFloatFilter>;
  insurance2Term?: Maybe<IntFilter>;
  insurance3Sale?: Maybe<BigFloatFilter>;
  insurance3Cost?: Maybe<BigFloatFilter>;
  insurance3Term?: Maybe<IntFilter>;
  accidentalHealthSale?: Maybe<BigFloatFilter>;
  accidentalHealthCost?: Maybe<BigFloatFilter>;
  accidentalHealthTerm?: Maybe<IntFilter>;
  creditLifeSale?: Maybe<BigFloatFilter>;
  creditLifeCost?: Maybe<BigFloatFilter>;
  creditLifeTerm?: Maybe<IntFilter>;
  levelizedLifeSale?: Maybe<BigFloatFilter>;
  levelizedLifeCost?: Maybe<BigFloatFilter>;
  levelizedLifeTerm?: Maybe<IntFilter>;
  lossOfEmploymentSale?: Maybe<BigFloatFilter>;
  lossOfEmploymentCost?: Maybe<BigFloatFilter>;
  lossOfEmploymentTerm?: Maybe<IntFilter>;
  guaranteedAutoProtectionSale?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<IntFilter>;
};

export type DealMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Minimum of year across the matching connection */
  year?: Maybe<Scalars['Int']>;
  /** Minimum of mileage across the matching connection */
  mileage?: Maybe<Scalars['Int']>;
  /** Minimum of deliveryMileage across the matching connection */
  deliveryMileage?: Maybe<Scalars['Int']>;
  /** Minimum of vinExplosionYear across the matching connection */
  vinExplosionYear?: Maybe<Scalars['Int']>;
  /** Minimum of trade1Year across the matching connection */
  trade1Year?: Maybe<Scalars['Int']>;
  /** Minimum of trade1Odometer across the matching connection */
  trade1Odometer?: Maybe<Scalars['Int']>;
  /** Minimum of trade1ActualCashValue across the matching connection */
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Minimum of trade1Gross across the matching connection */
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  /** Minimum of trade1Payoff across the matching connection */
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  /** Minimum of trade2Year across the matching connection */
  trade2Year?: Maybe<Scalars['Int']>;
  /** Minimum of trade2Odometer across the matching connection */
  trade2Odometer?: Maybe<Scalars['Int']>;
  /** Minimum of trade2ActualCashValue across the matching connection */
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Minimum of trade2Gross across the matching connection */
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  /** Minimum of trade2Payoff across the matching connection */
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  /** Minimum of msrp across the matching connection */
  msrp?: Maybe<Scalars['BigFloat']>;
  /** Minimum of listPrice across the matching connection */
  listPrice?: Maybe<Scalars['BigFloat']>;
  /** Minimum of salesPrice across the matching connection */
  salesPrice?: Maybe<Scalars['BigFloat']>;
  /** Minimum of journalPrice across the matching connection */
  journalPrice?: Maybe<Scalars['BigFloat']>;
  /** Minimum of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of journalCost across the matching connection */
  journalCost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of adjustments across the matching connection */
  adjustments?: Maybe<Scalars['BigFloat']>;
  /** Minimum of adjustedCost across the matching connection */
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of incentives across the matching connection */
  incentives?: Maybe<Scalars['BigFloat']>;
  /** Minimum of packAmount across the matching connection */
  packAmount?: Maybe<Scalars['BigFloat']>;
  /** Minimum of saleNet across the matching connection */
  saleNet?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalTradeActualCashValue across the matching connection */
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalTradeGross across the matching connection */
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  /** Minimum of weOweFront across the matching connection */
  weOweFront?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalFrontFeesAftermarketProfit across the matching connection */
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalFrontCommission across the matching connection */
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalFrontSales across the matching connection */
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalFrontCost across the matching connection */
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of frontGross across the matching connection */
  frontGross?: Maybe<Scalars['BigFloat']>;
  /** Minimum of financeProfit across the matching connection */
  financeProfit?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalWarrantyProfit across the matching connection */
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  /** Minimum of weOweBack across the matching connection */
  weOweBack?: Maybe<Scalars['BigFloat']>;
  /** Minimum of insuranceProfit across the matching connection */
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalBackFeesAftermarketProfit across the matching connection */
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Minimum of financeReserve across the matching connection */
  financeReserve?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalBackCommission across the matching connection */
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalBackSales across the matching connection */
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalBackCost across the matching connection */
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of backGross across the matching connection */
  backGross?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalProfit across the matching connection */
  totalProfit?: Maybe<Scalars['BigFloat']>;
  /** Minimum of grossProfit across the matching connection */
  grossProfit?: Maybe<Scalars['BigFloat']>;
  /** Minimum of grossPayable across the matching connection */
  grossPayable?: Maybe<Scalars['BigFloat']>;
  /** Minimum of term across the matching connection */
  term?: Maybe<Scalars['Int']>;
  /** Minimum of amountFinanced across the matching connection */
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  /** Minimum of apr across the matching connection */
  apr?: Maybe<Scalars['BigFloat']>;
  /** Minimum of monthlyPayment across the matching connection */
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  /** Minimum of paymentTotal across the matching connection */
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  /** Minimum of rebates across the matching connection */
  rebates?: Maybe<Scalars['BigFloat']>;
  /** Minimum of deposit across the matching connection */
  deposit?: Maybe<Scalars['BigFloat']>;
  /** Minimum of downPayment across the matching connection */
  downPayment?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalNetTrades across the matching connection */
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalDown across the matching connection */
  totalDown?: Maybe<Scalars['BigFloat']>;
  /** Minimum of balloonAmount across the matching connection */
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Minimum of adjustedBalloonAmount across the matching connection */
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Minimum of holdbackAmount across the matching connection */
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalDriveOffAmount across the matching connection */
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  /** Minimum of licenseFee across the matching connection */
  licenseFee?: Maybe<Scalars['BigFloat']>;
  /** Minimum of registrationFee across the matching connection */
  registrationFee?: Maybe<Scalars['BigFloat']>;
  /** Minimum of documentationFee across the matching connection */
  documentationFee?: Maybe<Scalars['BigFloat']>;
  /** Minimum of financeCharge across the matching connection */
  financeCharge?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalPickupPayments across the matching connection */
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  /** Minimum of sellRate across the matching connection */
  sellRate?: Maybe<Scalars['BigFloat']>;
  /** Minimum of buyRate across the matching connection */
  buyRate?: Maybe<Scalars['BigFloat']>;
  /** Minimum of residualRate across the matching connection */
  residualRate?: Maybe<Scalars['BigFloat']>;
  /** Minimum of residualAmount across the matching connection */
  residualAmount?: Maybe<Scalars['BigFloat']>;
  /** Minimum of allowedMiles across the matching connection */
  allowedMiles?: Maybe<Scalars['Int']>;
  /** Minimum of estimatedMiles across the matching connection */
  estimatedMiles?: Maybe<Scalars['Int']>;
  /** Minimum of mileageRate across the matching connection */
  mileageRate?: Maybe<Scalars['BigFloat']>;
  /** Minimum of acquisitionFee across the matching connection */
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  /** Minimum of basePayment across the matching connection */
  basePayment?: Maybe<Scalars['BigFloat']>;
  /** Minimum of securityDeposit across the matching connection */
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalCapitalReduction across the matching connection */
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  /** Minimum of netCapitalCost across the matching connection */
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of leaseDepreciationValue across the matching connection */
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  /** Minimum of dealerFees across the matching connection */
  dealerFees?: Maybe<Scalars['BigFloat']>;
  /** Minimum of governmentFees across the matching connection */
  governmentFees?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalTax across the matching connection */
  totalTax?: Maybe<Scalars['BigFloat']>;
  /** Minimum of salesman1TotalCommission across the matching connection */
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Minimum of salesman1FrontCommission across the matching connection */
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Minimum of salesman1BackCommission across the matching connection */
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Minimum of salesman2TotalCommission across the matching connection */
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Minimum of salesman2FrontCommission across the matching connection */
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Minimum of salesman2BackCommission across the matching connection */
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Minimum of salesman3TotalCommission across the matching connection */
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Minimum of salesman3FrontCommission across the matching connection */
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Minimum of salesman3BackCommission across the matching connection */
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Minimum of warranty1Sale across the matching connection */
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of warranty1Cost across the matching connection */
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of warranty1Miles across the matching connection */
  warranty1Miles?: Maybe<Scalars['Int']>;
  /** Minimum of warranty1Term across the matching connection */
  warranty1Term?: Maybe<Scalars['Int']>;
  /** Minimum of warranty2Sale across the matching connection */
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of warranty2Cost across the matching connection */
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of warranty2Miles across the matching connection */
  warranty2Miles?: Maybe<Scalars['Int']>;
  /** Minimum of warranty2Term across the matching connection */
  warranty2Term?: Maybe<Scalars['Int']>;
  /** Minimum of warranty3Sale across the matching connection */
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of warranty3Cost across the matching connection */
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of warranty3Miles across the matching connection */
  warranty3Miles?: Maybe<Scalars['Int']>;
  /** Minimum of warranty3Term across the matching connection */
  warranty3Term?: Maybe<Scalars['Int']>;
  /** Minimum of warranty4Sale across the matching connection */
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of warranty4Cost across the matching connection */
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of warranty4Miles across the matching connection */
  warranty4Miles?: Maybe<Scalars['Int']>;
  /** Minimum of warranty4Term across the matching connection */
  warranty4Term?: Maybe<Scalars['Int']>;
  /** Minimum of warranty5Sale across the matching connection */
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of warranty5Cost across the matching connection */
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of warranty5Miles across the matching connection */
  warranty5Miles?: Maybe<Scalars['Int']>;
  /** Minimum of warranty5Term across the matching connection */
  warranty5Term?: Maybe<Scalars['Int']>;
  /** Minimum of totalFeeAftermarketSale across the matching connection */
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of totalFeeAftermarketCost across the matching connection */
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket1Sale across the matching connection */
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket1Cost across the matching connection */
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket2Sale across the matching connection */
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket2Cost across the matching connection */
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket3Sale across the matching connection */
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket3Cost across the matching connection */
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket4Sale across the matching connection */
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket4Cost across the matching connection */
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket5Sale across the matching connection */
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket5Cost across the matching connection */
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket6Sale across the matching connection */
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket6Cost across the matching connection */
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket7Sale across the matching connection */
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket7Cost across the matching connection */
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket8Sale across the matching connection */
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket8Cost across the matching connection */
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket9Sale across the matching connection */
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket9Cost across the matching connection */
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket10Sale across the matching connection */
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket10Cost across the matching connection */
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket11Sale across the matching connection */
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket11Cost across the matching connection */
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket12Sale across the matching connection */
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket12Cost across the matching connection */
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket13Sale across the matching connection */
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket13Cost across the matching connection */
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket14Sale across the matching connection */
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket14Cost across the matching connection */
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket15Sale across the matching connection */
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket15Cost across the matching connection */
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket16Sale across the matching connection */
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket16Cost across the matching connection */
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket17Sale across the matching connection */
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket17Cost across the matching connection */
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket18Sale across the matching connection */
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket18Cost across the matching connection */
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket19Sale across the matching connection */
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket19Cost across the matching connection */
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket20Sale across the matching connection */
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of feeAftermarket20Cost across the matching connection */
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of insurance1Sale across the matching connection */
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of insurance1Cost across the matching connection */
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of insurance1Term across the matching connection */
  insurance1Term?: Maybe<Scalars['Int']>;
  /** Minimum of insurance2Sale across the matching connection */
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of insurance2Cost across the matching connection */
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of insurance2Term across the matching connection */
  insurance2Term?: Maybe<Scalars['Int']>;
  /** Minimum of insurance3Sale across the matching connection */
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of insurance3Cost across the matching connection */
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of insurance3Term across the matching connection */
  insurance3Term?: Maybe<Scalars['Int']>;
  /** Minimum of accidentalHealthSale across the matching connection */
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of accidentalHealthCost across the matching connection */
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of accidentalHealthTerm across the matching connection */
  accidentalHealthTerm?: Maybe<Scalars['Int']>;
  /** Minimum of creditLifeSale across the matching connection */
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of creditLifeCost across the matching connection */
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of creditLifeTerm across the matching connection */
  creditLifeTerm?: Maybe<Scalars['Int']>;
  /** Minimum of levelizedLifeSale across the matching connection */
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of levelizedLifeCost across the matching connection */
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of levelizedLifeTerm across the matching connection */
  levelizedLifeTerm?: Maybe<Scalars['Int']>;
  /** Minimum of lossOfEmploymentSale across the matching connection */
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of lossOfEmploymentCost across the matching connection */
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of lossOfEmploymentTerm across the matching connection */
  lossOfEmploymentTerm?: Maybe<Scalars['Int']>;
  /** Minimum of guaranteedAutoProtectionSale across the matching connection */
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  /** Minimum of guaranteedAutoProtectionCost across the matching connection */
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  /** Minimum of guaranteedAutoProtectionTerm across the matching connection */
  guaranteedAutoProtectionTerm?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DealNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `deal` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type DealNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `deal` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type DealOnDealForDealsCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** The fields on `deal` to look up the row to update. */
export type DealOnDealForDealsCompanyIntegrationIdFkeyUsingDealsPkeyUpdate = {
  /** An object where the defined keys will be set on the `deal` being updated. */
  patch: UpdateDealOnDealForDealsCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type DealOnDealForDealsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `deal` to look up the row to update. */
export type DealOnDealForDealsLeadIdFkeyUsingDealsPkeyUpdate = {
  /** An object where the defined keys will be set on the `deal` being updated. */
  patch: UpdateDealOnDealForDealsLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Deal`. Fields that are set will be updated. */
export type DealPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  fileType?: Maybe<Scalars['String']>;
  dvDealerId?: Maybe<Scalars['String']>;
  vendorDealerId?: Maybe<Scalars['String']>;
  dmsType?: Maybe<Scalars['String']>;
  dealNumber?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  workExtension?: Maybe<Scalars['String']>;
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  email3?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  individualBusinessFlag?: Maybe<Scalars['String']>;
  optOut?: Maybe<Scalars['Boolean']>;
  blockEmail?: Maybe<Scalars['Boolean']>;
  blockPhone?: Maybe<Scalars['Boolean']>;
  blockMail?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  customerCreateDate?: Maybe<Scalars['Date']>;
  customerLastActivityDate?: Maybe<Scalars['Date']>;
  coBuyerCustomerNumber?: Maybe<Scalars['String']>;
  coBuyerFullName?: Maybe<Scalars['String']>;
  coBuyerSalutation?: Maybe<Scalars['String']>;
  coBuyerFirstName?: Maybe<Scalars['String']>;
  coBuyerMiddleName?: Maybe<Scalars['String']>;
  coBuyerLastName?: Maybe<Scalars['String']>;
  coBuyerSuffix?: Maybe<Scalars['String']>;
  coBuyerAddressLine1?: Maybe<Scalars['String']>;
  coBuyerAddressLine2?: Maybe<Scalars['String']>;
  coBuyerCity?: Maybe<Scalars['String']>;
  coBuyerState?: Maybe<Scalars['String']>;
  coBuyerZip?: Maybe<Scalars['String']>;
  coBuyerCounty?: Maybe<Scalars['String']>;
  coBuyerHomePhone?: Maybe<Scalars['String']>;
  coBuyerCellPhone?: Maybe<Scalars['String']>;
  coBuyerWorkPhone?: Maybe<Scalars['String']>;
  coBuyerWorkExtension?: Maybe<Scalars['String']>;
  coBuyerEmail1?: Maybe<Scalars['String']>;
  coBuyerEmail2?: Maybe<Scalars['String']>;
  coBuyerEmail3?: Maybe<Scalars['String']>;
  coBuyerBirthDate?: Maybe<Scalars['Date']>;
  coBuyerIndividualBusinessFlag?: Maybe<Scalars['String']>;
  coBuyerOptOut?: Maybe<Scalars['Boolean']>;
  coBuyerBlockEmail?: Maybe<Scalars['Boolean']>;
  coBuyerBlockPhone?: Maybe<Scalars['Boolean']>;
  coBuyerBlockMail?: Maybe<Scalars['Boolean']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  newUsed?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  engineConfiguration?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  engineNumber?: Maybe<Scalars['String']>;
  chassisNumber?: Maybe<Scalars['String']>;
  licensePlateNumber?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  deliveryMileage?: Maybe<Scalars['Int']>;
  inventoryDate?: Maybe<Scalars['Date']>;
  inServiceDate?: Maybe<Scalars['Date']>;
  vinExplosionYear?: Maybe<Scalars['Int']>;
  vinExplosionMake?: Maybe<Scalars['String']>;
  vinExplosionModel?: Maybe<Scalars['String']>;
  vinExplosionTrim?: Maybe<Scalars['String']>;
  vinExplosionTransmissionType?: Maybe<Scalars['String']>;
  vinExplosionFuelType?: Maybe<Scalars['String']>;
  vinExplosionEngineSize?: Maybe<Scalars['String']>;
  vinExplosionGvwRange?: Maybe<Scalars['String']>;
  trade1Vin?: Maybe<Scalars['String']>;
  trade1Year?: Maybe<Scalars['Int']>;
  trade1Make?: Maybe<Scalars['String']>;
  trade1Model?: Maybe<Scalars['String']>;
  trade1Odometer?: Maybe<Scalars['Int']>;
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  trade2Vin?: Maybe<Scalars['String']>;
  trade2Year?: Maybe<Scalars['Int']>;
  trade2Make?: Maybe<Scalars['String']>;
  trade2Model?: Maybe<Scalars['String']>;
  trade2Odometer?: Maybe<Scalars['Int']>;
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  salesman1Number?: Maybe<Scalars['String']>;
  salesman1Name?: Maybe<Scalars['String']>;
  salesman2Number?: Maybe<Scalars['String']>;
  salesman2Name?: Maybe<Scalars['String']>;
  salesman3Number?: Maybe<Scalars['String']>;
  salesman3Name?: Maybe<Scalars['String']>;
  closingManagerNumber?: Maybe<Scalars['String']>;
  closingManagerName?: Maybe<Scalars['String']>;
  financeManagerNumber?: Maybe<Scalars['String']>;
  financeManagerName?: Maybe<Scalars['String']>;
  salesmanManagerNumber?: Maybe<Scalars['String']>;
  salesmanManagerName?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['BigFloat']>;
  listPrice?: Maybe<Scalars['BigFloat']>;
  salesPrice?: Maybe<Scalars['BigFloat']>;
  journalPrice?: Maybe<Scalars['BigFloat']>;
  cost?: Maybe<Scalars['BigFloat']>;
  journalCost?: Maybe<Scalars['BigFloat']>;
  adjustments?: Maybe<Scalars['BigFloat']>;
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  incentives?: Maybe<Scalars['BigFloat']>;
  packAmount?: Maybe<Scalars['BigFloat']>;
  saleNet?: Maybe<Scalars['BigFloat']>;
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  weOweFront?: Maybe<Scalars['BigFloat']>;
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  frontGross?: Maybe<Scalars['BigFloat']>;
  financeProfit?: Maybe<Scalars['BigFloat']>;
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  weOweBack?: Maybe<Scalars['BigFloat']>;
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  financeReserve?: Maybe<Scalars['BigFloat']>;
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  backGross?: Maybe<Scalars['BigFloat']>;
  totalProfit?: Maybe<Scalars['BigFloat']>;
  grossProfit?: Maybe<Scalars['BigFloat']>;
  grossPayable?: Maybe<Scalars['BigFloat']>;
  dealStatus?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  bookedDate?: Maybe<Scalars['Date']>;
  finalizedDate?: Maybe<Scalars['Date']>;
  contractDate?: Maybe<Scalars['Date']>;
  accountingDate?: Maybe<Scalars['Date']>;
  statusChangeDate?: Maybe<Scalars['Date']>;
  firstPayDate?: Maybe<Scalars['Date']>;
  dealType?: Maybe<Scalars['String']>;
  saleType?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  apr?: Maybe<Scalars['BigFloat']>;
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  rebates?: Maybe<Scalars['BigFloat']>;
  deposit?: Maybe<Scalars['BigFloat']>;
  downPayment?: Maybe<Scalars['BigFloat']>;
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  totalDown?: Maybe<Scalars['BigFloat']>;
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  licenseFee?: Maybe<Scalars['BigFloat']>;
  registrationFee?: Maybe<Scalars['BigFloat']>;
  documentationFee?: Maybe<Scalars['BigFloat']>;
  financeCharge?: Maybe<Scalars['BigFloat']>;
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  sellRate?: Maybe<Scalars['BigFloat']>;
  buyRate?: Maybe<Scalars['BigFloat']>;
  residualRate?: Maybe<Scalars['BigFloat']>;
  residualAmount?: Maybe<Scalars['BigFloat']>;
  allowedMiles?: Maybe<Scalars['Int']>;
  estimatedMiles?: Maybe<Scalars['Int']>;
  mileageRate?: Maybe<Scalars['BigFloat']>;
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  basePayment?: Maybe<Scalars['BigFloat']>;
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  dealerFees?: Maybe<Scalars['BigFloat']>;
  governmentFees?: Maybe<Scalars['BigFloat']>;
  totalTax?: Maybe<Scalars['BigFloat']>;
  registrationState?: Maybe<Scalars['String']>;
  reportOfSaleNumber?: Maybe<Scalars['String']>;
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  warranty1Name?: Maybe<Scalars['String']>;
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  warranty1Miles?: Maybe<Scalars['Int']>;
  warranty1Term?: Maybe<Scalars['Int']>;
  warranty2Name?: Maybe<Scalars['String']>;
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  warranty2Miles?: Maybe<Scalars['Int']>;
  warranty2Term?: Maybe<Scalars['Int']>;
  warranty3Name?: Maybe<Scalars['String']>;
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  warranty3Miles?: Maybe<Scalars['Int']>;
  warranty3Term?: Maybe<Scalars['Int']>;
  warranty4Name?: Maybe<Scalars['String']>;
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  warranty4Miles?: Maybe<Scalars['Int']>;
  warranty4Term?: Maybe<Scalars['Int']>;
  warranty5Name?: Maybe<Scalars['String']>;
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  warranty5Miles?: Maybe<Scalars['Int']>;
  warranty5Term?: Maybe<Scalars['Int']>;
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Name?: Maybe<Scalars['String']>;
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket2Name?: Maybe<Scalars['String']>;
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket3Name?: Maybe<Scalars['String']>;
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket4Name?: Maybe<Scalars['String']>;
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket5Name?: Maybe<Scalars['String']>;
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket6Name?: Maybe<Scalars['String']>;
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket7Name?: Maybe<Scalars['String']>;
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket8Name?: Maybe<Scalars['String']>;
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket9Name?: Maybe<Scalars['String']>;
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket10Name?: Maybe<Scalars['String']>;
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket11Name?: Maybe<Scalars['String']>;
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket12Name?: Maybe<Scalars['String']>;
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket13Name?: Maybe<Scalars['String']>;
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket14Name?: Maybe<Scalars['String']>;
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket15Name?: Maybe<Scalars['String']>;
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket16Name?: Maybe<Scalars['String']>;
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket17Name?: Maybe<Scalars['String']>;
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket18Name?: Maybe<Scalars['String']>;
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket19Name?: Maybe<Scalars['String']>;
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket20Name?: Maybe<Scalars['String']>;
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20ProfitIndicator?: Maybe<Scalars['Boolean']>;
  insurance1Type?: Maybe<Scalars['String']>;
  insurance1Name?: Maybe<Scalars['String']>;
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  insurance1Term?: Maybe<Scalars['Int']>;
  insurance2Type?: Maybe<Scalars['String']>;
  insurance2Name?: Maybe<Scalars['String']>;
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  insurance2Term?: Maybe<Scalars['Int']>;
  insurance3Type?: Maybe<Scalars['String']>;
  insurance3Name?: Maybe<Scalars['String']>;
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  insurance3Term?: Maybe<Scalars['Int']>;
  accidentalHealthType?: Maybe<Scalars['String']>;
  accidentalHealthName?: Maybe<Scalars['String']>;
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  accidentalHealthTerm?: Maybe<Scalars['Int']>;
  creditLifeType?: Maybe<Scalars['String']>;
  creditLifeName?: Maybe<Scalars['String']>;
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  creditLifeTerm?: Maybe<Scalars['Int']>;
  levelizedLifeType?: Maybe<Scalars['String']>;
  levelizedLifeName?: Maybe<Scalars['String']>;
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  levelizedLifeTerm?: Maybe<Scalars['Int']>;
  lossOfEmploymentType?: Maybe<Scalars['String']>;
  lossOfEmploymentName?: Maybe<Scalars['String']>;
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentTerm?: Maybe<Scalars['Int']>;
  guaranteedAutoProtectionType?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionName?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionTerm?: Maybe<Scalars['Int']>;
  saleComments?: Maybe<Scalars['String']>;
  cassStdLine1?: Maybe<Scalars['String']>;
  cassStdLine2?: Maybe<Scalars['String']>;
  cassStdCity?: Maybe<Scalars['String']>;
  cassStdState?: Maybe<Scalars['String']>;
  cassStdZip?: Maybe<Scalars['String']>;
  cassStdZip4?: Maybe<Scalars['String']>;
  cassStdDpbc?: Maybe<Scalars['String']>;
  cassStdChkdgt?: Maybe<Scalars['String']>;
  cassStdCart?: Maybe<Scalars['String']>;
  cassStdLot?: Maybe<Scalars['String']>;
  cassStdLotord?: Maybe<Scalars['String']>;
  cassStdUrb?: Maybe<Scalars['String']>;
  cassStdFips?: Maybe<Scalars['String']>;
  cassStdEws?: Maybe<Scalars['String']>;
  cassStdLacs?: Maybe<Scalars['String']>;
  cassStdZipmov?: Maybe<Scalars['String']>;
  cassStdZ4Lom?: Maybe<Scalars['String']>;
  cassStdNdiapt?: Maybe<Scalars['String']>;
  cassStdNdirr?: Maybe<Scalars['String']>;
  cassStdLacsrt?: Maybe<Scalars['String']>;
  cassStdErrorCd?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<DealsLeadIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<DealsCompanyIntegrationIdFkeyInput>;
};

export type DealStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  deliveryMileage?: Maybe<BigFloatFilter>;
  vinExplosionYear?: Maybe<BigFloatFilter>;
  trade1Year?: Maybe<BigFloatFilter>;
  trade1Odometer?: Maybe<BigFloatFilter>;
  trade1ActualCashValue?: Maybe<BigFloatFilter>;
  trade1Gross?: Maybe<BigFloatFilter>;
  trade1Payoff?: Maybe<BigFloatFilter>;
  trade2Year?: Maybe<BigFloatFilter>;
  trade2Odometer?: Maybe<BigFloatFilter>;
  trade2ActualCashValue?: Maybe<BigFloatFilter>;
  trade2Gross?: Maybe<BigFloatFilter>;
  trade2Payoff?: Maybe<BigFloatFilter>;
  msrp?: Maybe<BigFloatFilter>;
  listPrice?: Maybe<BigFloatFilter>;
  salesPrice?: Maybe<BigFloatFilter>;
  journalPrice?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
  journalCost?: Maybe<BigFloatFilter>;
  adjustments?: Maybe<BigFloatFilter>;
  adjustedCost?: Maybe<BigFloatFilter>;
  incentives?: Maybe<BigFloatFilter>;
  packAmount?: Maybe<BigFloatFilter>;
  saleNet?: Maybe<BigFloatFilter>;
  totalTradeActualCashValue?: Maybe<BigFloatFilter>;
  totalTradeGross?: Maybe<BigFloatFilter>;
  weOweFront?: Maybe<BigFloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  totalFrontCommission?: Maybe<BigFloatFilter>;
  totalFrontSales?: Maybe<BigFloatFilter>;
  totalFrontCost?: Maybe<BigFloatFilter>;
  frontGross?: Maybe<BigFloatFilter>;
  financeProfit?: Maybe<BigFloatFilter>;
  totalWarrantyProfit?: Maybe<BigFloatFilter>;
  weOweBack?: Maybe<BigFloatFilter>;
  insuranceProfit?: Maybe<BigFloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  financeReserve?: Maybe<BigFloatFilter>;
  totalBackCommission?: Maybe<BigFloatFilter>;
  totalBackSales?: Maybe<BigFloatFilter>;
  totalBackCost?: Maybe<BigFloatFilter>;
  backGross?: Maybe<BigFloatFilter>;
  totalProfit?: Maybe<BigFloatFilter>;
  grossProfit?: Maybe<BigFloatFilter>;
  grossPayable?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
  amountFinanced?: Maybe<BigFloatFilter>;
  apr?: Maybe<BigFloatFilter>;
  monthlyPayment?: Maybe<BigFloatFilter>;
  paymentTotal?: Maybe<BigFloatFilter>;
  rebates?: Maybe<BigFloatFilter>;
  deposit?: Maybe<BigFloatFilter>;
  downPayment?: Maybe<BigFloatFilter>;
  totalNetTrades?: Maybe<BigFloatFilter>;
  totalDown?: Maybe<BigFloatFilter>;
  balloonAmount?: Maybe<BigFloatFilter>;
  adjustedBalloonAmount?: Maybe<BigFloatFilter>;
  holdbackAmount?: Maybe<BigFloatFilter>;
  totalDriveOffAmount?: Maybe<BigFloatFilter>;
  licenseFee?: Maybe<BigFloatFilter>;
  registrationFee?: Maybe<BigFloatFilter>;
  documentationFee?: Maybe<BigFloatFilter>;
  financeCharge?: Maybe<BigFloatFilter>;
  totalPickupPayments?: Maybe<BigFloatFilter>;
  sellRate?: Maybe<BigFloatFilter>;
  buyRate?: Maybe<BigFloatFilter>;
  residualRate?: Maybe<BigFloatFilter>;
  residualAmount?: Maybe<BigFloatFilter>;
  allowedMiles?: Maybe<BigFloatFilter>;
  estimatedMiles?: Maybe<BigFloatFilter>;
  mileageRate?: Maybe<BigFloatFilter>;
  acquisitionFee?: Maybe<BigFloatFilter>;
  basePayment?: Maybe<BigFloatFilter>;
  securityDeposit?: Maybe<BigFloatFilter>;
  totalCapitalReduction?: Maybe<BigFloatFilter>;
  netCapitalCost?: Maybe<BigFloatFilter>;
  leaseDepreciationValue?: Maybe<BigFloatFilter>;
  dealerFees?: Maybe<BigFloatFilter>;
  governmentFees?: Maybe<BigFloatFilter>;
  totalTax?: Maybe<BigFloatFilter>;
  salesman1TotalCommission?: Maybe<BigFloatFilter>;
  salesman1FrontCommission?: Maybe<BigFloatFilter>;
  salesman1BackCommission?: Maybe<BigFloatFilter>;
  salesman2TotalCommission?: Maybe<BigFloatFilter>;
  salesman2FrontCommission?: Maybe<BigFloatFilter>;
  salesman2BackCommission?: Maybe<BigFloatFilter>;
  salesman3TotalCommission?: Maybe<BigFloatFilter>;
  salesman3FrontCommission?: Maybe<BigFloatFilter>;
  salesman3BackCommission?: Maybe<BigFloatFilter>;
  warranty1Sale?: Maybe<BigFloatFilter>;
  warranty1Cost?: Maybe<BigFloatFilter>;
  warranty1Miles?: Maybe<BigFloatFilter>;
  warranty1Term?: Maybe<BigFloatFilter>;
  warranty2Sale?: Maybe<BigFloatFilter>;
  warranty2Cost?: Maybe<BigFloatFilter>;
  warranty2Miles?: Maybe<BigFloatFilter>;
  warranty2Term?: Maybe<BigFloatFilter>;
  warranty3Sale?: Maybe<BigFloatFilter>;
  warranty3Cost?: Maybe<BigFloatFilter>;
  warranty3Miles?: Maybe<BigFloatFilter>;
  warranty3Term?: Maybe<BigFloatFilter>;
  warranty4Sale?: Maybe<BigFloatFilter>;
  warranty4Cost?: Maybe<BigFloatFilter>;
  warranty4Miles?: Maybe<BigFloatFilter>;
  warranty4Term?: Maybe<BigFloatFilter>;
  warranty5Sale?: Maybe<BigFloatFilter>;
  warranty5Cost?: Maybe<BigFloatFilter>;
  warranty5Miles?: Maybe<BigFloatFilter>;
  warranty5Term?: Maybe<BigFloatFilter>;
  totalFeeAftermarketSale?: Maybe<BigFloatFilter>;
  totalFeeAftermarketCost?: Maybe<BigFloatFilter>;
  feeAftermarket1Sale?: Maybe<BigFloatFilter>;
  feeAftermarket1Cost?: Maybe<BigFloatFilter>;
  feeAftermarket2Sale?: Maybe<BigFloatFilter>;
  feeAftermarket2Cost?: Maybe<BigFloatFilter>;
  feeAftermarket3Sale?: Maybe<BigFloatFilter>;
  feeAftermarket3Cost?: Maybe<BigFloatFilter>;
  feeAftermarket4Sale?: Maybe<BigFloatFilter>;
  feeAftermarket4Cost?: Maybe<BigFloatFilter>;
  feeAftermarket5Sale?: Maybe<BigFloatFilter>;
  feeAftermarket5Cost?: Maybe<BigFloatFilter>;
  feeAftermarket6Sale?: Maybe<BigFloatFilter>;
  feeAftermarket6Cost?: Maybe<BigFloatFilter>;
  feeAftermarket7Sale?: Maybe<BigFloatFilter>;
  feeAftermarket7Cost?: Maybe<BigFloatFilter>;
  feeAftermarket8Sale?: Maybe<BigFloatFilter>;
  feeAftermarket8Cost?: Maybe<BigFloatFilter>;
  feeAftermarket9Sale?: Maybe<BigFloatFilter>;
  feeAftermarket9Cost?: Maybe<BigFloatFilter>;
  feeAftermarket10Sale?: Maybe<BigFloatFilter>;
  feeAftermarket10Cost?: Maybe<BigFloatFilter>;
  feeAftermarket11Sale?: Maybe<BigFloatFilter>;
  feeAftermarket11Cost?: Maybe<BigFloatFilter>;
  feeAftermarket12Sale?: Maybe<BigFloatFilter>;
  feeAftermarket12Cost?: Maybe<BigFloatFilter>;
  feeAftermarket13Sale?: Maybe<BigFloatFilter>;
  feeAftermarket13Cost?: Maybe<BigFloatFilter>;
  feeAftermarket14Sale?: Maybe<BigFloatFilter>;
  feeAftermarket14Cost?: Maybe<BigFloatFilter>;
  feeAftermarket15Sale?: Maybe<BigFloatFilter>;
  feeAftermarket15Cost?: Maybe<BigFloatFilter>;
  feeAftermarket16Sale?: Maybe<BigFloatFilter>;
  feeAftermarket16Cost?: Maybe<BigFloatFilter>;
  feeAftermarket17Sale?: Maybe<BigFloatFilter>;
  feeAftermarket17Cost?: Maybe<BigFloatFilter>;
  feeAftermarket18Sale?: Maybe<BigFloatFilter>;
  feeAftermarket18Cost?: Maybe<BigFloatFilter>;
  feeAftermarket19Sale?: Maybe<BigFloatFilter>;
  feeAftermarket19Cost?: Maybe<BigFloatFilter>;
  feeAftermarket20Sale?: Maybe<BigFloatFilter>;
  feeAftermarket20Cost?: Maybe<BigFloatFilter>;
  insurance1Sale?: Maybe<BigFloatFilter>;
  insurance1Cost?: Maybe<BigFloatFilter>;
  insurance1Term?: Maybe<BigFloatFilter>;
  insurance2Sale?: Maybe<BigFloatFilter>;
  insurance2Cost?: Maybe<BigFloatFilter>;
  insurance2Term?: Maybe<BigFloatFilter>;
  insurance3Sale?: Maybe<BigFloatFilter>;
  insurance3Cost?: Maybe<BigFloatFilter>;
  insurance3Term?: Maybe<BigFloatFilter>;
  accidentalHealthSale?: Maybe<BigFloatFilter>;
  accidentalHealthCost?: Maybe<BigFloatFilter>;
  accidentalHealthTerm?: Maybe<BigFloatFilter>;
  creditLifeSale?: Maybe<BigFloatFilter>;
  creditLifeCost?: Maybe<BigFloatFilter>;
  creditLifeTerm?: Maybe<BigFloatFilter>;
  levelizedLifeSale?: Maybe<BigFloatFilter>;
  levelizedLifeCost?: Maybe<BigFloatFilter>;
  levelizedLifeTerm?: Maybe<BigFloatFilter>;
  lossOfEmploymentSale?: Maybe<BigFloatFilter>;
  lossOfEmploymentCost?: Maybe<BigFloatFilter>;
  lossOfEmploymentTerm?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionSale?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<BigFloatFilter>;
};

export type DealStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of deliveryMileage across the matching connection */
  deliveryMileage?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of vinExplosionYear across the matching connection */
  vinExplosionYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of trade1Year across the matching connection */
  trade1Year?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of trade1Odometer across the matching connection */
  trade1Odometer?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of trade1ActualCashValue across the matching connection */
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of trade1Gross across the matching connection */
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of trade1Payoff across the matching connection */
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of trade2Year across the matching connection */
  trade2Year?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of trade2Odometer across the matching connection */
  trade2Odometer?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of trade2ActualCashValue across the matching connection */
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of trade2Gross across the matching connection */
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of trade2Payoff across the matching connection */
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of msrp across the matching connection */
  msrp?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of listPrice across the matching connection */
  listPrice?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of salesPrice across the matching connection */
  salesPrice?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of journalPrice across the matching connection */
  journalPrice?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of journalCost across the matching connection */
  journalCost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of adjustments across the matching connection */
  adjustments?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of adjustedCost across the matching connection */
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of incentives across the matching connection */
  incentives?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of packAmount across the matching connection */
  packAmount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of saleNet across the matching connection */
  saleNet?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalTradeActualCashValue across the matching connection */
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalTradeGross across the matching connection */
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of weOweFront across the matching connection */
  weOweFront?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalFrontFeesAftermarketProfit across the matching connection */
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalFrontCommission across the matching connection */
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalFrontSales across the matching connection */
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalFrontCost across the matching connection */
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of frontGross across the matching connection */
  frontGross?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of financeProfit across the matching connection */
  financeProfit?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalWarrantyProfit across the matching connection */
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of weOweBack across the matching connection */
  weOweBack?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of insuranceProfit across the matching connection */
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalBackFeesAftermarketProfit across the matching connection */
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of financeReserve across the matching connection */
  financeReserve?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalBackCommission across the matching connection */
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalBackSales across the matching connection */
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalBackCost across the matching connection */
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of backGross across the matching connection */
  backGross?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalProfit across the matching connection */
  totalProfit?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of grossProfit across the matching connection */
  grossProfit?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of grossPayable across the matching connection */
  grossPayable?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of amountFinanced across the matching connection */
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of apr across the matching connection */
  apr?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of monthlyPayment across the matching connection */
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of paymentTotal across the matching connection */
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of rebates across the matching connection */
  rebates?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of deposit across the matching connection */
  deposit?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of downPayment across the matching connection */
  downPayment?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalNetTrades across the matching connection */
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalDown across the matching connection */
  totalDown?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of balloonAmount across the matching connection */
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of adjustedBalloonAmount across the matching connection */
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of holdbackAmount across the matching connection */
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalDriveOffAmount across the matching connection */
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of licenseFee across the matching connection */
  licenseFee?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of registrationFee across the matching connection */
  registrationFee?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of documentationFee across the matching connection */
  documentationFee?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of financeCharge across the matching connection */
  financeCharge?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalPickupPayments across the matching connection */
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of sellRate across the matching connection */
  sellRate?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of buyRate across the matching connection */
  buyRate?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of residualRate across the matching connection */
  residualRate?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of residualAmount across the matching connection */
  residualAmount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of allowedMiles across the matching connection */
  allowedMiles?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of estimatedMiles across the matching connection */
  estimatedMiles?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of mileageRate across the matching connection */
  mileageRate?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of acquisitionFee across the matching connection */
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of basePayment across the matching connection */
  basePayment?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of securityDeposit across the matching connection */
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalCapitalReduction across the matching connection */
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of netCapitalCost across the matching connection */
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of leaseDepreciationValue across the matching connection */
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of dealerFees across the matching connection */
  dealerFees?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of governmentFees across the matching connection */
  governmentFees?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalTax across the matching connection */
  totalTax?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of salesman1TotalCommission across the matching connection */
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of salesman1FrontCommission across the matching connection */
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of salesman1BackCommission across the matching connection */
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of salesman2TotalCommission across the matching connection */
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of salesman2FrontCommission across the matching connection */
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of salesman2BackCommission across the matching connection */
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of salesman3TotalCommission across the matching connection */
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of salesman3FrontCommission across the matching connection */
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of salesman3BackCommission across the matching connection */
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty1Sale across the matching connection */
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty1Cost across the matching connection */
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty1Miles across the matching connection */
  warranty1Miles?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty1Term across the matching connection */
  warranty1Term?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty2Sale across the matching connection */
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty2Cost across the matching connection */
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty2Miles across the matching connection */
  warranty2Miles?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty2Term across the matching connection */
  warranty2Term?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty3Sale across the matching connection */
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty3Cost across the matching connection */
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty3Miles across the matching connection */
  warranty3Miles?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty3Term across the matching connection */
  warranty3Term?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty4Sale across the matching connection */
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty4Cost across the matching connection */
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty4Miles across the matching connection */
  warranty4Miles?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty4Term across the matching connection */
  warranty4Term?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty5Sale across the matching connection */
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty5Cost across the matching connection */
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty5Miles across the matching connection */
  warranty5Miles?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of warranty5Term across the matching connection */
  warranty5Term?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalFeeAftermarketSale across the matching connection */
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of totalFeeAftermarketCost across the matching connection */
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket1Sale across the matching connection */
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket1Cost across the matching connection */
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket2Sale across the matching connection */
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket2Cost across the matching connection */
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket3Sale across the matching connection */
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket3Cost across the matching connection */
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket4Sale across the matching connection */
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket4Cost across the matching connection */
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket5Sale across the matching connection */
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket5Cost across the matching connection */
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket6Sale across the matching connection */
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket6Cost across the matching connection */
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket7Sale across the matching connection */
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket7Cost across the matching connection */
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket8Sale across the matching connection */
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket8Cost across the matching connection */
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket9Sale across the matching connection */
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket9Cost across the matching connection */
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket10Sale across the matching connection */
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket10Cost across the matching connection */
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket11Sale across the matching connection */
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket11Cost across the matching connection */
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket12Sale across the matching connection */
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket12Cost across the matching connection */
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket13Sale across the matching connection */
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket13Cost across the matching connection */
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket14Sale across the matching connection */
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket14Cost across the matching connection */
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket15Sale across the matching connection */
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket15Cost across the matching connection */
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket16Sale across the matching connection */
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket16Cost across the matching connection */
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket17Sale across the matching connection */
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket17Cost across the matching connection */
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket18Sale across the matching connection */
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket18Cost across the matching connection */
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket19Sale across the matching connection */
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket19Cost across the matching connection */
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket20Sale across the matching connection */
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of feeAftermarket20Cost across the matching connection */
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of insurance1Sale across the matching connection */
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of insurance1Cost across the matching connection */
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of insurance1Term across the matching connection */
  insurance1Term?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of insurance2Sale across the matching connection */
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of insurance2Cost across the matching connection */
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of insurance2Term across the matching connection */
  insurance2Term?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of insurance3Sale across the matching connection */
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of insurance3Cost across the matching connection */
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of insurance3Term across the matching connection */
  insurance3Term?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of accidentalHealthSale across the matching connection */
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of accidentalHealthCost across the matching connection */
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of accidentalHealthTerm across the matching connection */
  accidentalHealthTerm?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditLifeSale across the matching connection */
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditLifeCost across the matching connection */
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditLifeTerm across the matching connection */
  creditLifeTerm?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of levelizedLifeSale across the matching connection */
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of levelizedLifeCost across the matching connection */
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of levelizedLifeTerm across the matching connection */
  levelizedLifeTerm?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lossOfEmploymentSale across the matching connection */
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lossOfEmploymentCost across the matching connection */
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lossOfEmploymentTerm across the matching connection */
  lossOfEmploymentTerm?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of guaranteedAutoProtectionSale across the matching connection */
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of guaranteedAutoProtectionCost across the matching connection */
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of guaranteedAutoProtectionTerm across the matching connection */
  guaranteedAutoProtectionTerm?: Maybe<Scalars['BigFloat']>;
};

export type DealStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  deliveryMileage?: Maybe<BigFloatFilter>;
  vinExplosionYear?: Maybe<BigFloatFilter>;
  trade1Year?: Maybe<BigFloatFilter>;
  trade1Odometer?: Maybe<BigFloatFilter>;
  trade1ActualCashValue?: Maybe<BigFloatFilter>;
  trade1Gross?: Maybe<BigFloatFilter>;
  trade1Payoff?: Maybe<BigFloatFilter>;
  trade2Year?: Maybe<BigFloatFilter>;
  trade2Odometer?: Maybe<BigFloatFilter>;
  trade2ActualCashValue?: Maybe<BigFloatFilter>;
  trade2Gross?: Maybe<BigFloatFilter>;
  trade2Payoff?: Maybe<BigFloatFilter>;
  msrp?: Maybe<BigFloatFilter>;
  listPrice?: Maybe<BigFloatFilter>;
  salesPrice?: Maybe<BigFloatFilter>;
  journalPrice?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
  journalCost?: Maybe<BigFloatFilter>;
  adjustments?: Maybe<BigFloatFilter>;
  adjustedCost?: Maybe<BigFloatFilter>;
  incentives?: Maybe<BigFloatFilter>;
  packAmount?: Maybe<BigFloatFilter>;
  saleNet?: Maybe<BigFloatFilter>;
  totalTradeActualCashValue?: Maybe<BigFloatFilter>;
  totalTradeGross?: Maybe<BigFloatFilter>;
  weOweFront?: Maybe<BigFloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  totalFrontCommission?: Maybe<BigFloatFilter>;
  totalFrontSales?: Maybe<BigFloatFilter>;
  totalFrontCost?: Maybe<BigFloatFilter>;
  frontGross?: Maybe<BigFloatFilter>;
  financeProfit?: Maybe<BigFloatFilter>;
  totalWarrantyProfit?: Maybe<BigFloatFilter>;
  weOweBack?: Maybe<BigFloatFilter>;
  insuranceProfit?: Maybe<BigFloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  financeReserve?: Maybe<BigFloatFilter>;
  totalBackCommission?: Maybe<BigFloatFilter>;
  totalBackSales?: Maybe<BigFloatFilter>;
  totalBackCost?: Maybe<BigFloatFilter>;
  backGross?: Maybe<BigFloatFilter>;
  totalProfit?: Maybe<BigFloatFilter>;
  grossProfit?: Maybe<BigFloatFilter>;
  grossPayable?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
  amountFinanced?: Maybe<BigFloatFilter>;
  apr?: Maybe<BigFloatFilter>;
  monthlyPayment?: Maybe<BigFloatFilter>;
  paymentTotal?: Maybe<BigFloatFilter>;
  rebates?: Maybe<BigFloatFilter>;
  deposit?: Maybe<BigFloatFilter>;
  downPayment?: Maybe<BigFloatFilter>;
  totalNetTrades?: Maybe<BigFloatFilter>;
  totalDown?: Maybe<BigFloatFilter>;
  balloonAmount?: Maybe<BigFloatFilter>;
  adjustedBalloonAmount?: Maybe<BigFloatFilter>;
  holdbackAmount?: Maybe<BigFloatFilter>;
  totalDriveOffAmount?: Maybe<BigFloatFilter>;
  licenseFee?: Maybe<BigFloatFilter>;
  registrationFee?: Maybe<BigFloatFilter>;
  documentationFee?: Maybe<BigFloatFilter>;
  financeCharge?: Maybe<BigFloatFilter>;
  totalPickupPayments?: Maybe<BigFloatFilter>;
  sellRate?: Maybe<BigFloatFilter>;
  buyRate?: Maybe<BigFloatFilter>;
  residualRate?: Maybe<BigFloatFilter>;
  residualAmount?: Maybe<BigFloatFilter>;
  allowedMiles?: Maybe<BigFloatFilter>;
  estimatedMiles?: Maybe<BigFloatFilter>;
  mileageRate?: Maybe<BigFloatFilter>;
  acquisitionFee?: Maybe<BigFloatFilter>;
  basePayment?: Maybe<BigFloatFilter>;
  securityDeposit?: Maybe<BigFloatFilter>;
  totalCapitalReduction?: Maybe<BigFloatFilter>;
  netCapitalCost?: Maybe<BigFloatFilter>;
  leaseDepreciationValue?: Maybe<BigFloatFilter>;
  dealerFees?: Maybe<BigFloatFilter>;
  governmentFees?: Maybe<BigFloatFilter>;
  totalTax?: Maybe<BigFloatFilter>;
  salesman1TotalCommission?: Maybe<BigFloatFilter>;
  salesman1FrontCommission?: Maybe<BigFloatFilter>;
  salesman1BackCommission?: Maybe<BigFloatFilter>;
  salesman2TotalCommission?: Maybe<BigFloatFilter>;
  salesman2FrontCommission?: Maybe<BigFloatFilter>;
  salesman2BackCommission?: Maybe<BigFloatFilter>;
  salesman3TotalCommission?: Maybe<BigFloatFilter>;
  salesman3FrontCommission?: Maybe<BigFloatFilter>;
  salesman3BackCommission?: Maybe<BigFloatFilter>;
  warranty1Sale?: Maybe<BigFloatFilter>;
  warranty1Cost?: Maybe<BigFloatFilter>;
  warranty1Miles?: Maybe<BigFloatFilter>;
  warranty1Term?: Maybe<BigFloatFilter>;
  warranty2Sale?: Maybe<BigFloatFilter>;
  warranty2Cost?: Maybe<BigFloatFilter>;
  warranty2Miles?: Maybe<BigFloatFilter>;
  warranty2Term?: Maybe<BigFloatFilter>;
  warranty3Sale?: Maybe<BigFloatFilter>;
  warranty3Cost?: Maybe<BigFloatFilter>;
  warranty3Miles?: Maybe<BigFloatFilter>;
  warranty3Term?: Maybe<BigFloatFilter>;
  warranty4Sale?: Maybe<BigFloatFilter>;
  warranty4Cost?: Maybe<BigFloatFilter>;
  warranty4Miles?: Maybe<BigFloatFilter>;
  warranty4Term?: Maybe<BigFloatFilter>;
  warranty5Sale?: Maybe<BigFloatFilter>;
  warranty5Cost?: Maybe<BigFloatFilter>;
  warranty5Miles?: Maybe<BigFloatFilter>;
  warranty5Term?: Maybe<BigFloatFilter>;
  totalFeeAftermarketSale?: Maybe<BigFloatFilter>;
  totalFeeAftermarketCost?: Maybe<BigFloatFilter>;
  feeAftermarket1Sale?: Maybe<BigFloatFilter>;
  feeAftermarket1Cost?: Maybe<BigFloatFilter>;
  feeAftermarket2Sale?: Maybe<BigFloatFilter>;
  feeAftermarket2Cost?: Maybe<BigFloatFilter>;
  feeAftermarket3Sale?: Maybe<BigFloatFilter>;
  feeAftermarket3Cost?: Maybe<BigFloatFilter>;
  feeAftermarket4Sale?: Maybe<BigFloatFilter>;
  feeAftermarket4Cost?: Maybe<BigFloatFilter>;
  feeAftermarket5Sale?: Maybe<BigFloatFilter>;
  feeAftermarket5Cost?: Maybe<BigFloatFilter>;
  feeAftermarket6Sale?: Maybe<BigFloatFilter>;
  feeAftermarket6Cost?: Maybe<BigFloatFilter>;
  feeAftermarket7Sale?: Maybe<BigFloatFilter>;
  feeAftermarket7Cost?: Maybe<BigFloatFilter>;
  feeAftermarket8Sale?: Maybe<BigFloatFilter>;
  feeAftermarket8Cost?: Maybe<BigFloatFilter>;
  feeAftermarket9Sale?: Maybe<BigFloatFilter>;
  feeAftermarket9Cost?: Maybe<BigFloatFilter>;
  feeAftermarket10Sale?: Maybe<BigFloatFilter>;
  feeAftermarket10Cost?: Maybe<BigFloatFilter>;
  feeAftermarket11Sale?: Maybe<BigFloatFilter>;
  feeAftermarket11Cost?: Maybe<BigFloatFilter>;
  feeAftermarket12Sale?: Maybe<BigFloatFilter>;
  feeAftermarket12Cost?: Maybe<BigFloatFilter>;
  feeAftermarket13Sale?: Maybe<BigFloatFilter>;
  feeAftermarket13Cost?: Maybe<BigFloatFilter>;
  feeAftermarket14Sale?: Maybe<BigFloatFilter>;
  feeAftermarket14Cost?: Maybe<BigFloatFilter>;
  feeAftermarket15Sale?: Maybe<BigFloatFilter>;
  feeAftermarket15Cost?: Maybe<BigFloatFilter>;
  feeAftermarket16Sale?: Maybe<BigFloatFilter>;
  feeAftermarket16Cost?: Maybe<BigFloatFilter>;
  feeAftermarket17Sale?: Maybe<BigFloatFilter>;
  feeAftermarket17Cost?: Maybe<BigFloatFilter>;
  feeAftermarket18Sale?: Maybe<BigFloatFilter>;
  feeAftermarket18Cost?: Maybe<BigFloatFilter>;
  feeAftermarket19Sale?: Maybe<BigFloatFilter>;
  feeAftermarket19Cost?: Maybe<BigFloatFilter>;
  feeAftermarket20Sale?: Maybe<BigFloatFilter>;
  feeAftermarket20Cost?: Maybe<BigFloatFilter>;
  insurance1Sale?: Maybe<BigFloatFilter>;
  insurance1Cost?: Maybe<BigFloatFilter>;
  insurance1Term?: Maybe<BigFloatFilter>;
  insurance2Sale?: Maybe<BigFloatFilter>;
  insurance2Cost?: Maybe<BigFloatFilter>;
  insurance2Term?: Maybe<BigFloatFilter>;
  insurance3Sale?: Maybe<BigFloatFilter>;
  insurance3Cost?: Maybe<BigFloatFilter>;
  insurance3Term?: Maybe<BigFloatFilter>;
  accidentalHealthSale?: Maybe<BigFloatFilter>;
  accidentalHealthCost?: Maybe<BigFloatFilter>;
  accidentalHealthTerm?: Maybe<BigFloatFilter>;
  creditLifeSale?: Maybe<BigFloatFilter>;
  creditLifeCost?: Maybe<BigFloatFilter>;
  creditLifeTerm?: Maybe<BigFloatFilter>;
  levelizedLifeSale?: Maybe<BigFloatFilter>;
  levelizedLifeCost?: Maybe<BigFloatFilter>;
  levelizedLifeTerm?: Maybe<BigFloatFilter>;
  lossOfEmploymentSale?: Maybe<BigFloatFilter>;
  lossOfEmploymentCost?: Maybe<BigFloatFilter>;
  lossOfEmploymentTerm?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionSale?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<BigFloatFilter>;
};

export type DealStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of deliveryMileage across the matching connection */
  deliveryMileage?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of vinExplosionYear across the matching connection */
  vinExplosionYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of trade1Year across the matching connection */
  trade1Year?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of trade1Odometer across the matching connection */
  trade1Odometer?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of trade1ActualCashValue across the matching connection */
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of trade1Gross across the matching connection */
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of trade1Payoff across the matching connection */
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of trade2Year across the matching connection */
  trade2Year?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of trade2Odometer across the matching connection */
  trade2Odometer?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of trade2ActualCashValue across the matching connection */
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of trade2Gross across the matching connection */
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of trade2Payoff across the matching connection */
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of msrp across the matching connection */
  msrp?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of listPrice across the matching connection */
  listPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of salesPrice across the matching connection */
  salesPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of journalPrice across the matching connection */
  journalPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of journalCost across the matching connection */
  journalCost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of adjustments across the matching connection */
  adjustments?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of adjustedCost across the matching connection */
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of incentives across the matching connection */
  incentives?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of packAmount across the matching connection */
  packAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of saleNet across the matching connection */
  saleNet?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalTradeActualCashValue across the matching connection */
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalTradeGross across the matching connection */
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of weOweFront across the matching connection */
  weOweFront?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalFrontFeesAftermarketProfit across the matching connection */
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalFrontCommission across the matching connection */
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalFrontSales across the matching connection */
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalFrontCost across the matching connection */
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of frontGross across the matching connection */
  frontGross?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of financeProfit across the matching connection */
  financeProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalWarrantyProfit across the matching connection */
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of weOweBack across the matching connection */
  weOweBack?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of insuranceProfit across the matching connection */
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalBackFeesAftermarketProfit across the matching connection */
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of financeReserve across the matching connection */
  financeReserve?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalBackCommission across the matching connection */
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalBackSales across the matching connection */
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalBackCost across the matching connection */
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of backGross across the matching connection */
  backGross?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalProfit across the matching connection */
  totalProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of grossProfit across the matching connection */
  grossProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of grossPayable across the matching connection */
  grossPayable?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of amountFinanced across the matching connection */
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of apr across the matching connection */
  apr?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of monthlyPayment across the matching connection */
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of paymentTotal across the matching connection */
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of rebates across the matching connection */
  rebates?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of deposit across the matching connection */
  deposit?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of downPayment across the matching connection */
  downPayment?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalNetTrades across the matching connection */
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalDown across the matching connection */
  totalDown?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of balloonAmount across the matching connection */
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of adjustedBalloonAmount across the matching connection */
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of holdbackAmount across the matching connection */
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalDriveOffAmount across the matching connection */
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of licenseFee across the matching connection */
  licenseFee?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of registrationFee across the matching connection */
  registrationFee?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of documentationFee across the matching connection */
  documentationFee?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of financeCharge across the matching connection */
  financeCharge?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalPickupPayments across the matching connection */
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of sellRate across the matching connection */
  sellRate?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of buyRate across the matching connection */
  buyRate?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of residualRate across the matching connection */
  residualRate?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of residualAmount across the matching connection */
  residualAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of allowedMiles across the matching connection */
  allowedMiles?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of estimatedMiles across the matching connection */
  estimatedMiles?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of mileageRate across the matching connection */
  mileageRate?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of acquisitionFee across the matching connection */
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of basePayment across the matching connection */
  basePayment?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of securityDeposit across the matching connection */
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalCapitalReduction across the matching connection */
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of netCapitalCost across the matching connection */
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of leaseDepreciationValue across the matching connection */
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of dealerFees across the matching connection */
  dealerFees?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of governmentFees across the matching connection */
  governmentFees?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalTax across the matching connection */
  totalTax?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of salesman1TotalCommission across the matching connection */
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of salesman1FrontCommission across the matching connection */
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of salesman1BackCommission across the matching connection */
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of salesman2TotalCommission across the matching connection */
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of salesman2FrontCommission across the matching connection */
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of salesman2BackCommission across the matching connection */
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of salesman3TotalCommission across the matching connection */
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of salesman3FrontCommission across the matching connection */
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of salesman3BackCommission across the matching connection */
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty1Sale across the matching connection */
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty1Cost across the matching connection */
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty1Miles across the matching connection */
  warranty1Miles?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty1Term across the matching connection */
  warranty1Term?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty2Sale across the matching connection */
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty2Cost across the matching connection */
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty2Miles across the matching connection */
  warranty2Miles?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty2Term across the matching connection */
  warranty2Term?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty3Sale across the matching connection */
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty3Cost across the matching connection */
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty3Miles across the matching connection */
  warranty3Miles?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty3Term across the matching connection */
  warranty3Term?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty4Sale across the matching connection */
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty4Cost across the matching connection */
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty4Miles across the matching connection */
  warranty4Miles?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty4Term across the matching connection */
  warranty4Term?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty5Sale across the matching connection */
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty5Cost across the matching connection */
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty5Miles across the matching connection */
  warranty5Miles?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of warranty5Term across the matching connection */
  warranty5Term?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalFeeAftermarketSale across the matching connection */
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of totalFeeAftermarketCost across the matching connection */
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket1Sale across the matching connection */
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket1Cost across the matching connection */
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket2Sale across the matching connection */
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket2Cost across the matching connection */
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket3Sale across the matching connection */
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket3Cost across the matching connection */
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket4Sale across the matching connection */
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket4Cost across the matching connection */
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket5Sale across the matching connection */
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket5Cost across the matching connection */
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket6Sale across the matching connection */
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket6Cost across the matching connection */
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket7Sale across the matching connection */
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket7Cost across the matching connection */
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket8Sale across the matching connection */
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket8Cost across the matching connection */
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket9Sale across the matching connection */
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket9Cost across the matching connection */
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket10Sale across the matching connection */
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket10Cost across the matching connection */
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket11Sale across the matching connection */
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket11Cost across the matching connection */
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket12Sale across the matching connection */
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket12Cost across the matching connection */
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket13Sale across the matching connection */
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket13Cost across the matching connection */
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket14Sale across the matching connection */
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket14Cost across the matching connection */
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket15Sale across the matching connection */
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket15Cost across the matching connection */
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket16Sale across the matching connection */
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket16Cost across the matching connection */
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket17Sale across the matching connection */
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket17Cost across the matching connection */
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket18Sale across the matching connection */
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket18Cost across the matching connection */
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket19Sale across the matching connection */
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket19Cost across the matching connection */
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket20Sale across the matching connection */
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of feeAftermarket20Cost across the matching connection */
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of insurance1Sale across the matching connection */
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of insurance1Cost across the matching connection */
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of insurance1Term across the matching connection */
  insurance1Term?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of insurance2Sale across the matching connection */
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of insurance2Cost across the matching connection */
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of insurance2Term across the matching connection */
  insurance2Term?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of insurance3Sale across the matching connection */
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of insurance3Cost across the matching connection */
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of insurance3Term across the matching connection */
  insurance3Term?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of accidentalHealthSale across the matching connection */
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of accidentalHealthCost across the matching connection */
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of accidentalHealthTerm across the matching connection */
  accidentalHealthTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditLifeSale across the matching connection */
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditLifeCost across the matching connection */
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditLifeTerm across the matching connection */
  creditLifeTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of levelizedLifeSale across the matching connection */
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of levelizedLifeCost across the matching connection */
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of levelizedLifeTerm across the matching connection */
  levelizedLifeTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lossOfEmploymentSale across the matching connection */
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lossOfEmploymentCost across the matching connection */
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lossOfEmploymentTerm across the matching connection */
  lossOfEmploymentTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of guaranteedAutoProtectionSale across the matching connection */
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of guaranteedAutoProtectionCost across the matching connection */
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of guaranteedAutoProtectionTerm across the matching connection */
  guaranteedAutoProtectionTerm?: Maybe<Scalars['BigFloat']>;
};

export type DealSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  year?: Maybe<BigIntFilter>;
  mileage?: Maybe<BigIntFilter>;
  deliveryMileage?: Maybe<BigIntFilter>;
  vinExplosionYear?: Maybe<BigIntFilter>;
  trade1Year?: Maybe<BigIntFilter>;
  trade1Odometer?: Maybe<BigIntFilter>;
  trade1ActualCashValue?: Maybe<BigFloatFilter>;
  trade1Gross?: Maybe<BigFloatFilter>;
  trade1Payoff?: Maybe<BigFloatFilter>;
  trade2Year?: Maybe<BigIntFilter>;
  trade2Odometer?: Maybe<BigIntFilter>;
  trade2ActualCashValue?: Maybe<BigFloatFilter>;
  trade2Gross?: Maybe<BigFloatFilter>;
  trade2Payoff?: Maybe<BigFloatFilter>;
  msrp?: Maybe<BigFloatFilter>;
  listPrice?: Maybe<BigFloatFilter>;
  salesPrice?: Maybe<BigFloatFilter>;
  journalPrice?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
  journalCost?: Maybe<BigFloatFilter>;
  adjustments?: Maybe<BigFloatFilter>;
  adjustedCost?: Maybe<BigFloatFilter>;
  incentives?: Maybe<BigFloatFilter>;
  packAmount?: Maybe<BigFloatFilter>;
  saleNet?: Maybe<BigFloatFilter>;
  totalTradeActualCashValue?: Maybe<BigFloatFilter>;
  totalTradeGross?: Maybe<BigFloatFilter>;
  weOweFront?: Maybe<BigFloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  totalFrontCommission?: Maybe<BigFloatFilter>;
  totalFrontSales?: Maybe<BigFloatFilter>;
  totalFrontCost?: Maybe<BigFloatFilter>;
  frontGross?: Maybe<BigFloatFilter>;
  financeProfit?: Maybe<BigFloatFilter>;
  totalWarrantyProfit?: Maybe<BigFloatFilter>;
  weOweBack?: Maybe<BigFloatFilter>;
  insuranceProfit?: Maybe<BigFloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  financeReserve?: Maybe<BigFloatFilter>;
  totalBackCommission?: Maybe<BigFloatFilter>;
  totalBackSales?: Maybe<BigFloatFilter>;
  totalBackCost?: Maybe<BigFloatFilter>;
  backGross?: Maybe<BigFloatFilter>;
  totalProfit?: Maybe<BigFloatFilter>;
  grossProfit?: Maybe<BigFloatFilter>;
  grossPayable?: Maybe<BigFloatFilter>;
  term?: Maybe<BigIntFilter>;
  amountFinanced?: Maybe<BigFloatFilter>;
  apr?: Maybe<BigFloatFilter>;
  monthlyPayment?: Maybe<BigFloatFilter>;
  paymentTotal?: Maybe<BigFloatFilter>;
  rebates?: Maybe<BigFloatFilter>;
  deposit?: Maybe<BigFloatFilter>;
  downPayment?: Maybe<BigFloatFilter>;
  totalNetTrades?: Maybe<BigFloatFilter>;
  totalDown?: Maybe<BigFloatFilter>;
  balloonAmount?: Maybe<BigFloatFilter>;
  adjustedBalloonAmount?: Maybe<BigFloatFilter>;
  holdbackAmount?: Maybe<BigFloatFilter>;
  totalDriveOffAmount?: Maybe<BigFloatFilter>;
  licenseFee?: Maybe<BigFloatFilter>;
  registrationFee?: Maybe<BigFloatFilter>;
  documentationFee?: Maybe<BigFloatFilter>;
  financeCharge?: Maybe<BigFloatFilter>;
  totalPickupPayments?: Maybe<BigFloatFilter>;
  sellRate?: Maybe<BigFloatFilter>;
  buyRate?: Maybe<BigFloatFilter>;
  residualRate?: Maybe<BigFloatFilter>;
  residualAmount?: Maybe<BigFloatFilter>;
  allowedMiles?: Maybe<BigIntFilter>;
  estimatedMiles?: Maybe<BigIntFilter>;
  mileageRate?: Maybe<BigFloatFilter>;
  acquisitionFee?: Maybe<BigFloatFilter>;
  basePayment?: Maybe<BigFloatFilter>;
  securityDeposit?: Maybe<BigFloatFilter>;
  totalCapitalReduction?: Maybe<BigFloatFilter>;
  netCapitalCost?: Maybe<BigFloatFilter>;
  leaseDepreciationValue?: Maybe<BigFloatFilter>;
  dealerFees?: Maybe<BigFloatFilter>;
  governmentFees?: Maybe<BigFloatFilter>;
  totalTax?: Maybe<BigFloatFilter>;
  salesman1TotalCommission?: Maybe<BigFloatFilter>;
  salesman1FrontCommission?: Maybe<BigFloatFilter>;
  salesman1BackCommission?: Maybe<BigFloatFilter>;
  salesman2TotalCommission?: Maybe<BigFloatFilter>;
  salesman2FrontCommission?: Maybe<BigFloatFilter>;
  salesman2BackCommission?: Maybe<BigFloatFilter>;
  salesman3TotalCommission?: Maybe<BigFloatFilter>;
  salesman3FrontCommission?: Maybe<BigFloatFilter>;
  salesman3BackCommission?: Maybe<BigFloatFilter>;
  warranty1Sale?: Maybe<BigFloatFilter>;
  warranty1Cost?: Maybe<BigFloatFilter>;
  warranty1Miles?: Maybe<BigIntFilter>;
  warranty1Term?: Maybe<BigIntFilter>;
  warranty2Sale?: Maybe<BigFloatFilter>;
  warranty2Cost?: Maybe<BigFloatFilter>;
  warranty2Miles?: Maybe<BigIntFilter>;
  warranty2Term?: Maybe<BigIntFilter>;
  warranty3Sale?: Maybe<BigFloatFilter>;
  warranty3Cost?: Maybe<BigFloatFilter>;
  warranty3Miles?: Maybe<BigIntFilter>;
  warranty3Term?: Maybe<BigIntFilter>;
  warranty4Sale?: Maybe<BigFloatFilter>;
  warranty4Cost?: Maybe<BigFloatFilter>;
  warranty4Miles?: Maybe<BigIntFilter>;
  warranty4Term?: Maybe<BigIntFilter>;
  warranty5Sale?: Maybe<BigFloatFilter>;
  warranty5Cost?: Maybe<BigFloatFilter>;
  warranty5Miles?: Maybe<BigIntFilter>;
  warranty5Term?: Maybe<BigIntFilter>;
  totalFeeAftermarketSale?: Maybe<BigFloatFilter>;
  totalFeeAftermarketCost?: Maybe<BigFloatFilter>;
  feeAftermarket1Sale?: Maybe<BigFloatFilter>;
  feeAftermarket1Cost?: Maybe<BigFloatFilter>;
  feeAftermarket2Sale?: Maybe<BigFloatFilter>;
  feeAftermarket2Cost?: Maybe<BigFloatFilter>;
  feeAftermarket3Sale?: Maybe<BigFloatFilter>;
  feeAftermarket3Cost?: Maybe<BigFloatFilter>;
  feeAftermarket4Sale?: Maybe<BigFloatFilter>;
  feeAftermarket4Cost?: Maybe<BigFloatFilter>;
  feeAftermarket5Sale?: Maybe<BigFloatFilter>;
  feeAftermarket5Cost?: Maybe<BigFloatFilter>;
  feeAftermarket6Sale?: Maybe<BigFloatFilter>;
  feeAftermarket6Cost?: Maybe<BigFloatFilter>;
  feeAftermarket7Sale?: Maybe<BigFloatFilter>;
  feeAftermarket7Cost?: Maybe<BigFloatFilter>;
  feeAftermarket8Sale?: Maybe<BigFloatFilter>;
  feeAftermarket8Cost?: Maybe<BigFloatFilter>;
  feeAftermarket9Sale?: Maybe<BigFloatFilter>;
  feeAftermarket9Cost?: Maybe<BigFloatFilter>;
  feeAftermarket10Sale?: Maybe<BigFloatFilter>;
  feeAftermarket10Cost?: Maybe<BigFloatFilter>;
  feeAftermarket11Sale?: Maybe<BigFloatFilter>;
  feeAftermarket11Cost?: Maybe<BigFloatFilter>;
  feeAftermarket12Sale?: Maybe<BigFloatFilter>;
  feeAftermarket12Cost?: Maybe<BigFloatFilter>;
  feeAftermarket13Sale?: Maybe<BigFloatFilter>;
  feeAftermarket13Cost?: Maybe<BigFloatFilter>;
  feeAftermarket14Sale?: Maybe<BigFloatFilter>;
  feeAftermarket14Cost?: Maybe<BigFloatFilter>;
  feeAftermarket15Sale?: Maybe<BigFloatFilter>;
  feeAftermarket15Cost?: Maybe<BigFloatFilter>;
  feeAftermarket16Sale?: Maybe<BigFloatFilter>;
  feeAftermarket16Cost?: Maybe<BigFloatFilter>;
  feeAftermarket17Sale?: Maybe<BigFloatFilter>;
  feeAftermarket17Cost?: Maybe<BigFloatFilter>;
  feeAftermarket18Sale?: Maybe<BigFloatFilter>;
  feeAftermarket18Cost?: Maybe<BigFloatFilter>;
  feeAftermarket19Sale?: Maybe<BigFloatFilter>;
  feeAftermarket19Cost?: Maybe<BigFloatFilter>;
  feeAftermarket20Sale?: Maybe<BigFloatFilter>;
  feeAftermarket20Cost?: Maybe<BigFloatFilter>;
  insurance1Sale?: Maybe<BigFloatFilter>;
  insurance1Cost?: Maybe<BigFloatFilter>;
  insurance1Term?: Maybe<BigIntFilter>;
  insurance2Sale?: Maybe<BigFloatFilter>;
  insurance2Cost?: Maybe<BigFloatFilter>;
  insurance2Term?: Maybe<BigIntFilter>;
  insurance3Sale?: Maybe<BigFloatFilter>;
  insurance3Cost?: Maybe<BigFloatFilter>;
  insurance3Term?: Maybe<BigIntFilter>;
  accidentalHealthSale?: Maybe<BigFloatFilter>;
  accidentalHealthCost?: Maybe<BigFloatFilter>;
  accidentalHealthTerm?: Maybe<BigIntFilter>;
  creditLifeSale?: Maybe<BigFloatFilter>;
  creditLifeCost?: Maybe<BigFloatFilter>;
  creditLifeTerm?: Maybe<BigIntFilter>;
  levelizedLifeSale?: Maybe<BigFloatFilter>;
  levelizedLifeCost?: Maybe<BigFloatFilter>;
  levelizedLifeTerm?: Maybe<BigIntFilter>;
  lossOfEmploymentSale?: Maybe<BigFloatFilter>;
  lossOfEmploymentCost?: Maybe<BigFloatFilter>;
  lossOfEmploymentTerm?: Maybe<BigIntFilter>;
  guaranteedAutoProtectionSale?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<BigIntFilter>;
};

export type DealSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyIntegrationId across the matching connection */
  companyIntegrationId: Scalars['BigInt'];
  /** Sum of year across the matching connection */
  year: Scalars['BigInt'];
  /** Sum of mileage across the matching connection */
  mileage: Scalars['BigInt'];
  /** Sum of deliveryMileage across the matching connection */
  deliveryMileage: Scalars['BigInt'];
  /** Sum of vinExplosionYear across the matching connection */
  vinExplosionYear: Scalars['BigInt'];
  /** Sum of trade1Year across the matching connection */
  trade1Year: Scalars['BigInt'];
  /** Sum of trade1Odometer across the matching connection */
  trade1Odometer: Scalars['BigInt'];
  /** Sum of trade1ActualCashValue across the matching connection */
  trade1ActualCashValue: Scalars['BigFloat'];
  /** Sum of trade1Gross across the matching connection */
  trade1Gross: Scalars['BigFloat'];
  /** Sum of trade1Payoff across the matching connection */
  trade1Payoff: Scalars['BigFloat'];
  /** Sum of trade2Year across the matching connection */
  trade2Year: Scalars['BigInt'];
  /** Sum of trade2Odometer across the matching connection */
  trade2Odometer: Scalars['BigInt'];
  /** Sum of trade2ActualCashValue across the matching connection */
  trade2ActualCashValue: Scalars['BigFloat'];
  /** Sum of trade2Gross across the matching connection */
  trade2Gross: Scalars['BigFloat'];
  /** Sum of trade2Payoff across the matching connection */
  trade2Payoff: Scalars['BigFloat'];
  /** Sum of msrp across the matching connection */
  msrp: Scalars['BigFloat'];
  /** Sum of listPrice across the matching connection */
  listPrice: Scalars['BigFloat'];
  /** Sum of salesPrice across the matching connection */
  salesPrice: Scalars['BigFloat'];
  /** Sum of journalPrice across the matching connection */
  journalPrice: Scalars['BigFloat'];
  /** Sum of cost across the matching connection */
  cost: Scalars['BigFloat'];
  /** Sum of journalCost across the matching connection */
  journalCost: Scalars['BigFloat'];
  /** Sum of adjustments across the matching connection */
  adjustments: Scalars['BigFloat'];
  /** Sum of adjustedCost across the matching connection */
  adjustedCost: Scalars['BigFloat'];
  /** Sum of incentives across the matching connection */
  incentives: Scalars['BigFloat'];
  /** Sum of packAmount across the matching connection */
  packAmount: Scalars['BigFloat'];
  /** Sum of saleNet across the matching connection */
  saleNet: Scalars['BigFloat'];
  /** Sum of totalTradeActualCashValue across the matching connection */
  totalTradeActualCashValue: Scalars['BigFloat'];
  /** Sum of totalTradeGross across the matching connection */
  totalTradeGross: Scalars['BigFloat'];
  /** Sum of weOweFront across the matching connection */
  weOweFront: Scalars['BigFloat'];
  /** Sum of totalFrontFeesAftermarketProfit across the matching connection */
  totalFrontFeesAftermarketProfit: Scalars['BigFloat'];
  /** Sum of totalFrontCommission across the matching connection */
  totalFrontCommission: Scalars['BigFloat'];
  /** Sum of totalFrontSales across the matching connection */
  totalFrontSales: Scalars['BigFloat'];
  /** Sum of totalFrontCost across the matching connection */
  totalFrontCost: Scalars['BigFloat'];
  /** Sum of frontGross across the matching connection */
  frontGross: Scalars['BigFloat'];
  /** Sum of financeProfit across the matching connection */
  financeProfit: Scalars['BigFloat'];
  /** Sum of totalWarrantyProfit across the matching connection */
  totalWarrantyProfit: Scalars['BigFloat'];
  /** Sum of weOweBack across the matching connection */
  weOweBack: Scalars['BigFloat'];
  /** Sum of insuranceProfit across the matching connection */
  insuranceProfit: Scalars['BigFloat'];
  /** Sum of totalBackFeesAftermarketProfit across the matching connection */
  totalBackFeesAftermarketProfit: Scalars['BigFloat'];
  /** Sum of financeReserve across the matching connection */
  financeReserve: Scalars['BigFloat'];
  /** Sum of totalBackCommission across the matching connection */
  totalBackCommission: Scalars['BigFloat'];
  /** Sum of totalBackSales across the matching connection */
  totalBackSales: Scalars['BigFloat'];
  /** Sum of totalBackCost across the matching connection */
  totalBackCost: Scalars['BigFloat'];
  /** Sum of backGross across the matching connection */
  backGross: Scalars['BigFloat'];
  /** Sum of totalProfit across the matching connection */
  totalProfit: Scalars['BigFloat'];
  /** Sum of grossProfit across the matching connection */
  grossProfit: Scalars['BigFloat'];
  /** Sum of grossPayable across the matching connection */
  grossPayable: Scalars['BigFloat'];
  /** Sum of term across the matching connection */
  term: Scalars['BigInt'];
  /** Sum of amountFinanced across the matching connection */
  amountFinanced: Scalars['BigFloat'];
  /** Sum of apr across the matching connection */
  apr: Scalars['BigFloat'];
  /** Sum of monthlyPayment across the matching connection */
  monthlyPayment: Scalars['BigFloat'];
  /** Sum of paymentTotal across the matching connection */
  paymentTotal: Scalars['BigFloat'];
  /** Sum of rebates across the matching connection */
  rebates: Scalars['BigFloat'];
  /** Sum of deposit across the matching connection */
  deposit: Scalars['BigFloat'];
  /** Sum of downPayment across the matching connection */
  downPayment: Scalars['BigFloat'];
  /** Sum of totalNetTrades across the matching connection */
  totalNetTrades: Scalars['BigFloat'];
  /** Sum of totalDown across the matching connection */
  totalDown: Scalars['BigFloat'];
  /** Sum of balloonAmount across the matching connection */
  balloonAmount: Scalars['BigFloat'];
  /** Sum of adjustedBalloonAmount across the matching connection */
  adjustedBalloonAmount: Scalars['BigFloat'];
  /** Sum of holdbackAmount across the matching connection */
  holdbackAmount: Scalars['BigFloat'];
  /** Sum of totalDriveOffAmount across the matching connection */
  totalDriveOffAmount: Scalars['BigFloat'];
  /** Sum of licenseFee across the matching connection */
  licenseFee: Scalars['BigFloat'];
  /** Sum of registrationFee across the matching connection */
  registrationFee: Scalars['BigFloat'];
  /** Sum of documentationFee across the matching connection */
  documentationFee: Scalars['BigFloat'];
  /** Sum of financeCharge across the matching connection */
  financeCharge: Scalars['BigFloat'];
  /** Sum of totalPickupPayments across the matching connection */
  totalPickupPayments: Scalars['BigFloat'];
  /** Sum of sellRate across the matching connection */
  sellRate: Scalars['BigFloat'];
  /** Sum of buyRate across the matching connection */
  buyRate: Scalars['BigFloat'];
  /** Sum of residualRate across the matching connection */
  residualRate: Scalars['BigFloat'];
  /** Sum of residualAmount across the matching connection */
  residualAmount: Scalars['BigFloat'];
  /** Sum of allowedMiles across the matching connection */
  allowedMiles: Scalars['BigInt'];
  /** Sum of estimatedMiles across the matching connection */
  estimatedMiles: Scalars['BigInt'];
  /** Sum of mileageRate across the matching connection */
  mileageRate: Scalars['BigFloat'];
  /** Sum of acquisitionFee across the matching connection */
  acquisitionFee: Scalars['BigFloat'];
  /** Sum of basePayment across the matching connection */
  basePayment: Scalars['BigFloat'];
  /** Sum of securityDeposit across the matching connection */
  securityDeposit: Scalars['BigFloat'];
  /** Sum of totalCapitalReduction across the matching connection */
  totalCapitalReduction: Scalars['BigFloat'];
  /** Sum of netCapitalCost across the matching connection */
  netCapitalCost: Scalars['BigFloat'];
  /** Sum of leaseDepreciationValue across the matching connection */
  leaseDepreciationValue: Scalars['BigFloat'];
  /** Sum of dealerFees across the matching connection */
  dealerFees: Scalars['BigFloat'];
  /** Sum of governmentFees across the matching connection */
  governmentFees: Scalars['BigFloat'];
  /** Sum of totalTax across the matching connection */
  totalTax: Scalars['BigFloat'];
  /** Sum of salesman1TotalCommission across the matching connection */
  salesman1TotalCommission: Scalars['BigFloat'];
  /** Sum of salesman1FrontCommission across the matching connection */
  salesman1FrontCommission: Scalars['BigFloat'];
  /** Sum of salesman1BackCommission across the matching connection */
  salesman1BackCommission: Scalars['BigFloat'];
  /** Sum of salesman2TotalCommission across the matching connection */
  salesman2TotalCommission: Scalars['BigFloat'];
  /** Sum of salesman2FrontCommission across the matching connection */
  salesman2FrontCommission: Scalars['BigFloat'];
  /** Sum of salesman2BackCommission across the matching connection */
  salesman2BackCommission: Scalars['BigFloat'];
  /** Sum of salesman3TotalCommission across the matching connection */
  salesman3TotalCommission: Scalars['BigFloat'];
  /** Sum of salesman3FrontCommission across the matching connection */
  salesman3FrontCommission: Scalars['BigFloat'];
  /** Sum of salesman3BackCommission across the matching connection */
  salesman3BackCommission: Scalars['BigFloat'];
  /** Sum of warranty1Sale across the matching connection */
  warranty1Sale: Scalars['BigFloat'];
  /** Sum of warranty1Cost across the matching connection */
  warranty1Cost: Scalars['BigFloat'];
  /** Sum of warranty1Miles across the matching connection */
  warranty1Miles: Scalars['BigInt'];
  /** Sum of warranty1Term across the matching connection */
  warranty1Term: Scalars['BigInt'];
  /** Sum of warranty2Sale across the matching connection */
  warranty2Sale: Scalars['BigFloat'];
  /** Sum of warranty2Cost across the matching connection */
  warranty2Cost: Scalars['BigFloat'];
  /** Sum of warranty2Miles across the matching connection */
  warranty2Miles: Scalars['BigInt'];
  /** Sum of warranty2Term across the matching connection */
  warranty2Term: Scalars['BigInt'];
  /** Sum of warranty3Sale across the matching connection */
  warranty3Sale: Scalars['BigFloat'];
  /** Sum of warranty3Cost across the matching connection */
  warranty3Cost: Scalars['BigFloat'];
  /** Sum of warranty3Miles across the matching connection */
  warranty3Miles: Scalars['BigInt'];
  /** Sum of warranty3Term across the matching connection */
  warranty3Term: Scalars['BigInt'];
  /** Sum of warranty4Sale across the matching connection */
  warranty4Sale: Scalars['BigFloat'];
  /** Sum of warranty4Cost across the matching connection */
  warranty4Cost: Scalars['BigFloat'];
  /** Sum of warranty4Miles across the matching connection */
  warranty4Miles: Scalars['BigInt'];
  /** Sum of warranty4Term across the matching connection */
  warranty4Term: Scalars['BigInt'];
  /** Sum of warranty5Sale across the matching connection */
  warranty5Sale: Scalars['BigFloat'];
  /** Sum of warranty5Cost across the matching connection */
  warranty5Cost: Scalars['BigFloat'];
  /** Sum of warranty5Miles across the matching connection */
  warranty5Miles: Scalars['BigInt'];
  /** Sum of warranty5Term across the matching connection */
  warranty5Term: Scalars['BigInt'];
  /** Sum of totalFeeAftermarketSale across the matching connection */
  totalFeeAftermarketSale: Scalars['BigFloat'];
  /** Sum of totalFeeAftermarketCost across the matching connection */
  totalFeeAftermarketCost: Scalars['BigFloat'];
  /** Sum of feeAftermarket1Sale across the matching connection */
  feeAftermarket1Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket1Cost across the matching connection */
  feeAftermarket1Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket2Sale across the matching connection */
  feeAftermarket2Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket2Cost across the matching connection */
  feeAftermarket2Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket3Sale across the matching connection */
  feeAftermarket3Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket3Cost across the matching connection */
  feeAftermarket3Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket4Sale across the matching connection */
  feeAftermarket4Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket4Cost across the matching connection */
  feeAftermarket4Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket5Sale across the matching connection */
  feeAftermarket5Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket5Cost across the matching connection */
  feeAftermarket5Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket6Sale across the matching connection */
  feeAftermarket6Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket6Cost across the matching connection */
  feeAftermarket6Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket7Sale across the matching connection */
  feeAftermarket7Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket7Cost across the matching connection */
  feeAftermarket7Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket8Sale across the matching connection */
  feeAftermarket8Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket8Cost across the matching connection */
  feeAftermarket8Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket9Sale across the matching connection */
  feeAftermarket9Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket9Cost across the matching connection */
  feeAftermarket9Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket10Sale across the matching connection */
  feeAftermarket10Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket10Cost across the matching connection */
  feeAftermarket10Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket11Sale across the matching connection */
  feeAftermarket11Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket11Cost across the matching connection */
  feeAftermarket11Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket12Sale across the matching connection */
  feeAftermarket12Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket12Cost across the matching connection */
  feeAftermarket12Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket13Sale across the matching connection */
  feeAftermarket13Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket13Cost across the matching connection */
  feeAftermarket13Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket14Sale across the matching connection */
  feeAftermarket14Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket14Cost across the matching connection */
  feeAftermarket14Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket15Sale across the matching connection */
  feeAftermarket15Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket15Cost across the matching connection */
  feeAftermarket15Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket16Sale across the matching connection */
  feeAftermarket16Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket16Cost across the matching connection */
  feeAftermarket16Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket17Sale across the matching connection */
  feeAftermarket17Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket17Cost across the matching connection */
  feeAftermarket17Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket18Sale across the matching connection */
  feeAftermarket18Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket18Cost across the matching connection */
  feeAftermarket18Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket19Sale across the matching connection */
  feeAftermarket19Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket19Cost across the matching connection */
  feeAftermarket19Cost: Scalars['BigFloat'];
  /** Sum of feeAftermarket20Sale across the matching connection */
  feeAftermarket20Sale: Scalars['BigFloat'];
  /** Sum of feeAftermarket20Cost across the matching connection */
  feeAftermarket20Cost: Scalars['BigFloat'];
  /** Sum of insurance1Sale across the matching connection */
  insurance1Sale: Scalars['BigFloat'];
  /** Sum of insurance1Cost across the matching connection */
  insurance1Cost: Scalars['BigFloat'];
  /** Sum of insurance1Term across the matching connection */
  insurance1Term: Scalars['BigInt'];
  /** Sum of insurance2Sale across the matching connection */
  insurance2Sale: Scalars['BigFloat'];
  /** Sum of insurance2Cost across the matching connection */
  insurance2Cost: Scalars['BigFloat'];
  /** Sum of insurance2Term across the matching connection */
  insurance2Term: Scalars['BigInt'];
  /** Sum of insurance3Sale across the matching connection */
  insurance3Sale: Scalars['BigFloat'];
  /** Sum of insurance3Cost across the matching connection */
  insurance3Cost: Scalars['BigFloat'];
  /** Sum of insurance3Term across the matching connection */
  insurance3Term: Scalars['BigInt'];
  /** Sum of accidentalHealthSale across the matching connection */
  accidentalHealthSale: Scalars['BigFloat'];
  /** Sum of accidentalHealthCost across the matching connection */
  accidentalHealthCost: Scalars['BigFloat'];
  /** Sum of accidentalHealthTerm across the matching connection */
  accidentalHealthTerm: Scalars['BigInt'];
  /** Sum of creditLifeSale across the matching connection */
  creditLifeSale: Scalars['BigFloat'];
  /** Sum of creditLifeCost across the matching connection */
  creditLifeCost: Scalars['BigFloat'];
  /** Sum of creditLifeTerm across the matching connection */
  creditLifeTerm: Scalars['BigInt'];
  /** Sum of levelizedLifeSale across the matching connection */
  levelizedLifeSale: Scalars['BigFloat'];
  /** Sum of levelizedLifeCost across the matching connection */
  levelizedLifeCost: Scalars['BigFloat'];
  /** Sum of levelizedLifeTerm across the matching connection */
  levelizedLifeTerm: Scalars['BigInt'];
  /** Sum of lossOfEmploymentSale across the matching connection */
  lossOfEmploymentSale: Scalars['BigFloat'];
  /** Sum of lossOfEmploymentCost across the matching connection */
  lossOfEmploymentCost: Scalars['BigFloat'];
  /** Sum of lossOfEmploymentTerm across the matching connection */
  lossOfEmploymentTerm: Scalars['BigInt'];
  /** Sum of guaranteedAutoProtectionSale across the matching connection */
  guaranteedAutoProtectionSale: Scalars['BigFloat'];
  /** Sum of guaranteedAutoProtectionCost across the matching connection */
  guaranteedAutoProtectionCost: Scalars['BigFloat'];
  /** Sum of guaranteedAutoProtectionTerm across the matching connection */
  guaranteedAutoProtectionTerm: Scalars['BigInt'];
};

export type DealVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  deliveryMileage?: Maybe<BigFloatFilter>;
  vinExplosionYear?: Maybe<BigFloatFilter>;
  trade1Year?: Maybe<BigFloatFilter>;
  trade1Odometer?: Maybe<BigFloatFilter>;
  trade1ActualCashValue?: Maybe<BigFloatFilter>;
  trade1Gross?: Maybe<BigFloatFilter>;
  trade1Payoff?: Maybe<BigFloatFilter>;
  trade2Year?: Maybe<BigFloatFilter>;
  trade2Odometer?: Maybe<BigFloatFilter>;
  trade2ActualCashValue?: Maybe<BigFloatFilter>;
  trade2Gross?: Maybe<BigFloatFilter>;
  trade2Payoff?: Maybe<BigFloatFilter>;
  msrp?: Maybe<BigFloatFilter>;
  listPrice?: Maybe<BigFloatFilter>;
  salesPrice?: Maybe<BigFloatFilter>;
  journalPrice?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
  journalCost?: Maybe<BigFloatFilter>;
  adjustments?: Maybe<BigFloatFilter>;
  adjustedCost?: Maybe<BigFloatFilter>;
  incentives?: Maybe<BigFloatFilter>;
  packAmount?: Maybe<BigFloatFilter>;
  saleNet?: Maybe<BigFloatFilter>;
  totalTradeActualCashValue?: Maybe<BigFloatFilter>;
  totalTradeGross?: Maybe<BigFloatFilter>;
  weOweFront?: Maybe<BigFloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  totalFrontCommission?: Maybe<BigFloatFilter>;
  totalFrontSales?: Maybe<BigFloatFilter>;
  totalFrontCost?: Maybe<BigFloatFilter>;
  frontGross?: Maybe<BigFloatFilter>;
  financeProfit?: Maybe<BigFloatFilter>;
  totalWarrantyProfit?: Maybe<BigFloatFilter>;
  weOweBack?: Maybe<BigFloatFilter>;
  insuranceProfit?: Maybe<BigFloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  financeReserve?: Maybe<BigFloatFilter>;
  totalBackCommission?: Maybe<BigFloatFilter>;
  totalBackSales?: Maybe<BigFloatFilter>;
  totalBackCost?: Maybe<BigFloatFilter>;
  backGross?: Maybe<BigFloatFilter>;
  totalProfit?: Maybe<BigFloatFilter>;
  grossProfit?: Maybe<BigFloatFilter>;
  grossPayable?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
  amountFinanced?: Maybe<BigFloatFilter>;
  apr?: Maybe<BigFloatFilter>;
  monthlyPayment?: Maybe<BigFloatFilter>;
  paymentTotal?: Maybe<BigFloatFilter>;
  rebates?: Maybe<BigFloatFilter>;
  deposit?: Maybe<BigFloatFilter>;
  downPayment?: Maybe<BigFloatFilter>;
  totalNetTrades?: Maybe<BigFloatFilter>;
  totalDown?: Maybe<BigFloatFilter>;
  balloonAmount?: Maybe<BigFloatFilter>;
  adjustedBalloonAmount?: Maybe<BigFloatFilter>;
  holdbackAmount?: Maybe<BigFloatFilter>;
  totalDriveOffAmount?: Maybe<BigFloatFilter>;
  licenseFee?: Maybe<BigFloatFilter>;
  registrationFee?: Maybe<BigFloatFilter>;
  documentationFee?: Maybe<BigFloatFilter>;
  financeCharge?: Maybe<BigFloatFilter>;
  totalPickupPayments?: Maybe<BigFloatFilter>;
  sellRate?: Maybe<BigFloatFilter>;
  buyRate?: Maybe<BigFloatFilter>;
  residualRate?: Maybe<BigFloatFilter>;
  residualAmount?: Maybe<BigFloatFilter>;
  allowedMiles?: Maybe<BigFloatFilter>;
  estimatedMiles?: Maybe<BigFloatFilter>;
  mileageRate?: Maybe<BigFloatFilter>;
  acquisitionFee?: Maybe<BigFloatFilter>;
  basePayment?: Maybe<BigFloatFilter>;
  securityDeposit?: Maybe<BigFloatFilter>;
  totalCapitalReduction?: Maybe<BigFloatFilter>;
  netCapitalCost?: Maybe<BigFloatFilter>;
  leaseDepreciationValue?: Maybe<BigFloatFilter>;
  dealerFees?: Maybe<BigFloatFilter>;
  governmentFees?: Maybe<BigFloatFilter>;
  totalTax?: Maybe<BigFloatFilter>;
  salesman1TotalCommission?: Maybe<BigFloatFilter>;
  salesman1FrontCommission?: Maybe<BigFloatFilter>;
  salesman1BackCommission?: Maybe<BigFloatFilter>;
  salesman2TotalCommission?: Maybe<BigFloatFilter>;
  salesman2FrontCommission?: Maybe<BigFloatFilter>;
  salesman2BackCommission?: Maybe<BigFloatFilter>;
  salesman3TotalCommission?: Maybe<BigFloatFilter>;
  salesman3FrontCommission?: Maybe<BigFloatFilter>;
  salesman3BackCommission?: Maybe<BigFloatFilter>;
  warranty1Sale?: Maybe<BigFloatFilter>;
  warranty1Cost?: Maybe<BigFloatFilter>;
  warranty1Miles?: Maybe<BigFloatFilter>;
  warranty1Term?: Maybe<BigFloatFilter>;
  warranty2Sale?: Maybe<BigFloatFilter>;
  warranty2Cost?: Maybe<BigFloatFilter>;
  warranty2Miles?: Maybe<BigFloatFilter>;
  warranty2Term?: Maybe<BigFloatFilter>;
  warranty3Sale?: Maybe<BigFloatFilter>;
  warranty3Cost?: Maybe<BigFloatFilter>;
  warranty3Miles?: Maybe<BigFloatFilter>;
  warranty3Term?: Maybe<BigFloatFilter>;
  warranty4Sale?: Maybe<BigFloatFilter>;
  warranty4Cost?: Maybe<BigFloatFilter>;
  warranty4Miles?: Maybe<BigFloatFilter>;
  warranty4Term?: Maybe<BigFloatFilter>;
  warranty5Sale?: Maybe<BigFloatFilter>;
  warranty5Cost?: Maybe<BigFloatFilter>;
  warranty5Miles?: Maybe<BigFloatFilter>;
  warranty5Term?: Maybe<BigFloatFilter>;
  totalFeeAftermarketSale?: Maybe<BigFloatFilter>;
  totalFeeAftermarketCost?: Maybe<BigFloatFilter>;
  feeAftermarket1Sale?: Maybe<BigFloatFilter>;
  feeAftermarket1Cost?: Maybe<BigFloatFilter>;
  feeAftermarket2Sale?: Maybe<BigFloatFilter>;
  feeAftermarket2Cost?: Maybe<BigFloatFilter>;
  feeAftermarket3Sale?: Maybe<BigFloatFilter>;
  feeAftermarket3Cost?: Maybe<BigFloatFilter>;
  feeAftermarket4Sale?: Maybe<BigFloatFilter>;
  feeAftermarket4Cost?: Maybe<BigFloatFilter>;
  feeAftermarket5Sale?: Maybe<BigFloatFilter>;
  feeAftermarket5Cost?: Maybe<BigFloatFilter>;
  feeAftermarket6Sale?: Maybe<BigFloatFilter>;
  feeAftermarket6Cost?: Maybe<BigFloatFilter>;
  feeAftermarket7Sale?: Maybe<BigFloatFilter>;
  feeAftermarket7Cost?: Maybe<BigFloatFilter>;
  feeAftermarket8Sale?: Maybe<BigFloatFilter>;
  feeAftermarket8Cost?: Maybe<BigFloatFilter>;
  feeAftermarket9Sale?: Maybe<BigFloatFilter>;
  feeAftermarket9Cost?: Maybe<BigFloatFilter>;
  feeAftermarket10Sale?: Maybe<BigFloatFilter>;
  feeAftermarket10Cost?: Maybe<BigFloatFilter>;
  feeAftermarket11Sale?: Maybe<BigFloatFilter>;
  feeAftermarket11Cost?: Maybe<BigFloatFilter>;
  feeAftermarket12Sale?: Maybe<BigFloatFilter>;
  feeAftermarket12Cost?: Maybe<BigFloatFilter>;
  feeAftermarket13Sale?: Maybe<BigFloatFilter>;
  feeAftermarket13Cost?: Maybe<BigFloatFilter>;
  feeAftermarket14Sale?: Maybe<BigFloatFilter>;
  feeAftermarket14Cost?: Maybe<BigFloatFilter>;
  feeAftermarket15Sale?: Maybe<BigFloatFilter>;
  feeAftermarket15Cost?: Maybe<BigFloatFilter>;
  feeAftermarket16Sale?: Maybe<BigFloatFilter>;
  feeAftermarket16Cost?: Maybe<BigFloatFilter>;
  feeAftermarket17Sale?: Maybe<BigFloatFilter>;
  feeAftermarket17Cost?: Maybe<BigFloatFilter>;
  feeAftermarket18Sale?: Maybe<BigFloatFilter>;
  feeAftermarket18Cost?: Maybe<BigFloatFilter>;
  feeAftermarket19Sale?: Maybe<BigFloatFilter>;
  feeAftermarket19Cost?: Maybe<BigFloatFilter>;
  feeAftermarket20Sale?: Maybe<BigFloatFilter>;
  feeAftermarket20Cost?: Maybe<BigFloatFilter>;
  insurance1Sale?: Maybe<BigFloatFilter>;
  insurance1Cost?: Maybe<BigFloatFilter>;
  insurance1Term?: Maybe<BigFloatFilter>;
  insurance2Sale?: Maybe<BigFloatFilter>;
  insurance2Cost?: Maybe<BigFloatFilter>;
  insurance2Term?: Maybe<BigFloatFilter>;
  insurance3Sale?: Maybe<BigFloatFilter>;
  insurance3Cost?: Maybe<BigFloatFilter>;
  insurance3Term?: Maybe<BigFloatFilter>;
  accidentalHealthSale?: Maybe<BigFloatFilter>;
  accidentalHealthCost?: Maybe<BigFloatFilter>;
  accidentalHealthTerm?: Maybe<BigFloatFilter>;
  creditLifeSale?: Maybe<BigFloatFilter>;
  creditLifeCost?: Maybe<BigFloatFilter>;
  creditLifeTerm?: Maybe<BigFloatFilter>;
  levelizedLifeSale?: Maybe<BigFloatFilter>;
  levelizedLifeCost?: Maybe<BigFloatFilter>;
  levelizedLifeTerm?: Maybe<BigFloatFilter>;
  lossOfEmploymentSale?: Maybe<BigFloatFilter>;
  lossOfEmploymentCost?: Maybe<BigFloatFilter>;
  lossOfEmploymentTerm?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionSale?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<BigFloatFilter>;
};

export type DealVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Population variance of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Population variance of deliveryMileage across the matching connection */
  deliveryMileage?: Maybe<Scalars['BigFloat']>;
  /** Population variance of vinExplosionYear across the matching connection */
  vinExplosionYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of trade1Year across the matching connection */
  trade1Year?: Maybe<Scalars['BigFloat']>;
  /** Population variance of trade1Odometer across the matching connection */
  trade1Odometer?: Maybe<Scalars['BigFloat']>;
  /** Population variance of trade1ActualCashValue across the matching connection */
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Population variance of trade1Gross across the matching connection */
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  /** Population variance of trade1Payoff across the matching connection */
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  /** Population variance of trade2Year across the matching connection */
  trade2Year?: Maybe<Scalars['BigFloat']>;
  /** Population variance of trade2Odometer across the matching connection */
  trade2Odometer?: Maybe<Scalars['BigFloat']>;
  /** Population variance of trade2ActualCashValue across the matching connection */
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Population variance of trade2Gross across the matching connection */
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  /** Population variance of trade2Payoff across the matching connection */
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  /** Population variance of msrp across the matching connection */
  msrp?: Maybe<Scalars['BigFloat']>;
  /** Population variance of listPrice across the matching connection */
  listPrice?: Maybe<Scalars['BigFloat']>;
  /** Population variance of salesPrice across the matching connection */
  salesPrice?: Maybe<Scalars['BigFloat']>;
  /** Population variance of journalPrice across the matching connection */
  journalPrice?: Maybe<Scalars['BigFloat']>;
  /** Population variance of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of journalCost across the matching connection */
  journalCost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of adjustments across the matching connection */
  adjustments?: Maybe<Scalars['BigFloat']>;
  /** Population variance of adjustedCost across the matching connection */
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of incentives across the matching connection */
  incentives?: Maybe<Scalars['BigFloat']>;
  /** Population variance of packAmount across the matching connection */
  packAmount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of saleNet across the matching connection */
  saleNet?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalTradeActualCashValue across the matching connection */
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalTradeGross across the matching connection */
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  /** Population variance of weOweFront across the matching connection */
  weOweFront?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalFrontFeesAftermarketProfit across the matching connection */
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalFrontCommission across the matching connection */
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalFrontSales across the matching connection */
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalFrontCost across the matching connection */
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of frontGross across the matching connection */
  frontGross?: Maybe<Scalars['BigFloat']>;
  /** Population variance of financeProfit across the matching connection */
  financeProfit?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalWarrantyProfit across the matching connection */
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  /** Population variance of weOweBack across the matching connection */
  weOweBack?: Maybe<Scalars['BigFloat']>;
  /** Population variance of insuranceProfit across the matching connection */
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalBackFeesAftermarketProfit across the matching connection */
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Population variance of financeReserve across the matching connection */
  financeReserve?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalBackCommission across the matching connection */
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalBackSales across the matching connection */
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalBackCost across the matching connection */
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of backGross across the matching connection */
  backGross?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalProfit across the matching connection */
  totalProfit?: Maybe<Scalars['BigFloat']>;
  /** Population variance of grossProfit across the matching connection */
  grossProfit?: Maybe<Scalars['BigFloat']>;
  /** Population variance of grossPayable across the matching connection */
  grossPayable?: Maybe<Scalars['BigFloat']>;
  /** Population variance of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
  /** Population variance of amountFinanced across the matching connection */
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  /** Population variance of apr across the matching connection */
  apr?: Maybe<Scalars['BigFloat']>;
  /** Population variance of monthlyPayment across the matching connection */
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  /** Population variance of paymentTotal across the matching connection */
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  /** Population variance of rebates across the matching connection */
  rebates?: Maybe<Scalars['BigFloat']>;
  /** Population variance of deposit across the matching connection */
  deposit?: Maybe<Scalars['BigFloat']>;
  /** Population variance of downPayment across the matching connection */
  downPayment?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalNetTrades across the matching connection */
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalDown across the matching connection */
  totalDown?: Maybe<Scalars['BigFloat']>;
  /** Population variance of balloonAmount across the matching connection */
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of adjustedBalloonAmount across the matching connection */
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of holdbackAmount across the matching connection */
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalDriveOffAmount across the matching connection */
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of licenseFee across the matching connection */
  licenseFee?: Maybe<Scalars['BigFloat']>;
  /** Population variance of registrationFee across the matching connection */
  registrationFee?: Maybe<Scalars['BigFloat']>;
  /** Population variance of documentationFee across the matching connection */
  documentationFee?: Maybe<Scalars['BigFloat']>;
  /** Population variance of financeCharge across the matching connection */
  financeCharge?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalPickupPayments across the matching connection */
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  /** Population variance of sellRate across the matching connection */
  sellRate?: Maybe<Scalars['BigFloat']>;
  /** Population variance of buyRate across the matching connection */
  buyRate?: Maybe<Scalars['BigFloat']>;
  /** Population variance of residualRate across the matching connection */
  residualRate?: Maybe<Scalars['BigFloat']>;
  /** Population variance of residualAmount across the matching connection */
  residualAmount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of allowedMiles across the matching connection */
  allowedMiles?: Maybe<Scalars['BigFloat']>;
  /** Population variance of estimatedMiles across the matching connection */
  estimatedMiles?: Maybe<Scalars['BigFloat']>;
  /** Population variance of mileageRate across the matching connection */
  mileageRate?: Maybe<Scalars['BigFloat']>;
  /** Population variance of acquisitionFee across the matching connection */
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  /** Population variance of basePayment across the matching connection */
  basePayment?: Maybe<Scalars['BigFloat']>;
  /** Population variance of securityDeposit across the matching connection */
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalCapitalReduction across the matching connection */
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  /** Population variance of netCapitalCost across the matching connection */
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of leaseDepreciationValue across the matching connection */
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  /** Population variance of dealerFees across the matching connection */
  dealerFees?: Maybe<Scalars['BigFloat']>;
  /** Population variance of governmentFees across the matching connection */
  governmentFees?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalTax across the matching connection */
  totalTax?: Maybe<Scalars['BigFloat']>;
  /** Population variance of salesman1TotalCommission across the matching connection */
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Population variance of salesman1FrontCommission across the matching connection */
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Population variance of salesman1BackCommission across the matching connection */
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Population variance of salesman2TotalCommission across the matching connection */
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Population variance of salesman2FrontCommission across the matching connection */
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Population variance of salesman2BackCommission across the matching connection */
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Population variance of salesman3TotalCommission across the matching connection */
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Population variance of salesman3FrontCommission across the matching connection */
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Population variance of salesman3BackCommission across the matching connection */
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty1Sale across the matching connection */
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty1Cost across the matching connection */
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty1Miles across the matching connection */
  warranty1Miles?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty1Term across the matching connection */
  warranty1Term?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty2Sale across the matching connection */
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty2Cost across the matching connection */
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty2Miles across the matching connection */
  warranty2Miles?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty2Term across the matching connection */
  warranty2Term?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty3Sale across the matching connection */
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty3Cost across the matching connection */
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty3Miles across the matching connection */
  warranty3Miles?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty3Term across the matching connection */
  warranty3Term?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty4Sale across the matching connection */
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty4Cost across the matching connection */
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty4Miles across the matching connection */
  warranty4Miles?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty4Term across the matching connection */
  warranty4Term?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty5Sale across the matching connection */
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty5Cost across the matching connection */
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty5Miles across the matching connection */
  warranty5Miles?: Maybe<Scalars['BigFloat']>;
  /** Population variance of warranty5Term across the matching connection */
  warranty5Term?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalFeeAftermarketSale across the matching connection */
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of totalFeeAftermarketCost across the matching connection */
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket1Sale across the matching connection */
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket1Cost across the matching connection */
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket2Sale across the matching connection */
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket2Cost across the matching connection */
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket3Sale across the matching connection */
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket3Cost across the matching connection */
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket4Sale across the matching connection */
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket4Cost across the matching connection */
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket5Sale across the matching connection */
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket5Cost across the matching connection */
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket6Sale across the matching connection */
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket6Cost across the matching connection */
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket7Sale across the matching connection */
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket7Cost across the matching connection */
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket8Sale across the matching connection */
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket8Cost across the matching connection */
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket9Sale across the matching connection */
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket9Cost across the matching connection */
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket10Sale across the matching connection */
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket10Cost across the matching connection */
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket11Sale across the matching connection */
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket11Cost across the matching connection */
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket12Sale across the matching connection */
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket12Cost across the matching connection */
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket13Sale across the matching connection */
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket13Cost across the matching connection */
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket14Sale across the matching connection */
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket14Cost across the matching connection */
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket15Sale across the matching connection */
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket15Cost across the matching connection */
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket16Sale across the matching connection */
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket16Cost across the matching connection */
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket17Sale across the matching connection */
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket17Cost across the matching connection */
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket18Sale across the matching connection */
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket18Cost across the matching connection */
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket19Sale across the matching connection */
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket19Cost across the matching connection */
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket20Sale across the matching connection */
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of feeAftermarket20Cost across the matching connection */
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of insurance1Sale across the matching connection */
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of insurance1Cost across the matching connection */
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of insurance1Term across the matching connection */
  insurance1Term?: Maybe<Scalars['BigFloat']>;
  /** Population variance of insurance2Sale across the matching connection */
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of insurance2Cost across the matching connection */
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of insurance2Term across the matching connection */
  insurance2Term?: Maybe<Scalars['BigFloat']>;
  /** Population variance of insurance3Sale across the matching connection */
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of insurance3Cost across the matching connection */
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of insurance3Term across the matching connection */
  insurance3Term?: Maybe<Scalars['BigFloat']>;
  /** Population variance of accidentalHealthSale across the matching connection */
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of accidentalHealthCost across the matching connection */
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of accidentalHealthTerm across the matching connection */
  accidentalHealthTerm?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditLifeSale across the matching connection */
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditLifeCost across the matching connection */
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditLifeTerm across the matching connection */
  creditLifeTerm?: Maybe<Scalars['BigFloat']>;
  /** Population variance of levelizedLifeSale across the matching connection */
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of levelizedLifeCost across the matching connection */
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of levelizedLifeTerm across the matching connection */
  levelizedLifeTerm?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lossOfEmploymentSale across the matching connection */
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lossOfEmploymentCost across the matching connection */
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lossOfEmploymentTerm across the matching connection */
  lossOfEmploymentTerm?: Maybe<Scalars['BigFloat']>;
  /** Population variance of guaranteedAutoProtectionSale across the matching connection */
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  /** Population variance of guaranteedAutoProtectionCost across the matching connection */
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  /** Population variance of guaranteedAutoProtectionTerm across the matching connection */
  guaranteedAutoProtectionTerm?: Maybe<Scalars['BigFloat']>;
};

export type DealVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  deliveryMileage?: Maybe<BigFloatFilter>;
  vinExplosionYear?: Maybe<BigFloatFilter>;
  trade1Year?: Maybe<BigFloatFilter>;
  trade1Odometer?: Maybe<BigFloatFilter>;
  trade1ActualCashValue?: Maybe<BigFloatFilter>;
  trade1Gross?: Maybe<BigFloatFilter>;
  trade1Payoff?: Maybe<BigFloatFilter>;
  trade2Year?: Maybe<BigFloatFilter>;
  trade2Odometer?: Maybe<BigFloatFilter>;
  trade2ActualCashValue?: Maybe<BigFloatFilter>;
  trade2Gross?: Maybe<BigFloatFilter>;
  trade2Payoff?: Maybe<BigFloatFilter>;
  msrp?: Maybe<BigFloatFilter>;
  listPrice?: Maybe<BigFloatFilter>;
  salesPrice?: Maybe<BigFloatFilter>;
  journalPrice?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
  journalCost?: Maybe<BigFloatFilter>;
  adjustments?: Maybe<BigFloatFilter>;
  adjustedCost?: Maybe<BigFloatFilter>;
  incentives?: Maybe<BigFloatFilter>;
  packAmount?: Maybe<BigFloatFilter>;
  saleNet?: Maybe<BigFloatFilter>;
  totalTradeActualCashValue?: Maybe<BigFloatFilter>;
  totalTradeGross?: Maybe<BigFloatFilter>;
  weOweFront?: Maybe<BigFloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  totalFrontCommission?: Maybe<BigFloatFilter>;
  totalFrontSales?: Maybe<BigFloatFilter>;
  totalFrontCost?: Maybe<BigFloatFilter>;
  frontGross?: Maybe<BigFloatFilter>;
  financeProfit?: Maybe<BigFloatFilter>;
  totalWarrantyProfit?: Maybe<BigFloatFilter>;
  weOweBack?: Maybe<BigFloatFilter>;
  insuranceProfit?: Maybe<BigFloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<BigFloatFilter>;
  financeReserve?: Maybe<BigFloatFilter>;
  totalBackCommission?: Maybe<BigFloatFilter>;
  totalBackSales?: Maybe<BigFloatFilter>;
  totalBackCost?: Maybe<BigFloatFilter>;
  backGross?: Maybe<BigFloatFilter>;
  totalProfit?: Maybe<BigFloatFilter>;
  grossProfit?: Maybe<BigFloatFilter>;
  grossPayable?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
  amountFinanced?: Maybe<BigFloatFilter>;
  apr?: Maybe<BigFloatFilter>;
  monthlyPayment?: Maybe<BigFloatFilter>;
  paymentTotal?: Maybe<BigFloatFilter>;
  rebates?: Maybe<BigFloatFilter>;
  deposit?: Maybe<BigFloatFilter>;
  downPayment?: Maybe<BigFloatFilter>;
  totalNetTrades?: Maybe<BigFloatFilter>;
  totalDown?: Maybe<BigFloatFilter>;
  balloonAmount?: Maybe<BigFloatFilter>;
  adjustedBalloonAmount?: Maybe<BigFloatFilter>;
  holdbackAmount?: Maybe<BigFloatFilter>;
  totalDriveOffAmount?: Maybe<BigFloatFilter>;
  licenseFee?: Maybe<BigFloatFilter>;
  registrationFee?: Maybe<BigFloatFilter>;
  documentationFee?: Maybe<BigFloatFilter>;
  financeCharge?: Maybe<BigFloatFilter>;
  totalPickupPayments?: Maybe<BigFloatFilter>;
  sellRate?: Maybe<BigFloatFilter>;
  buyRate?: Maybe<BigFloatFilter>;
  residualRate?: Maybe<BigFloatFilter>;
  residualAmount?: Maybe<BigFloatFilter>;
  allowedMiles?: Maybe<BigFloatFilter>;
  estimatedMiles?: Maybe<BigFloatFilter>;
  mileageRate?: Maybe<BigFloatFilter>;
  acquisitionFee?: Maybe<BigFloatFilter>;
  basePayment?: Maybe<BigFloatFilter>;
  securityDeposit?: Maybe<BigFloatFilter>;
  totalCapitalReduction?: Maybe<BigFloatFilter>;
  netCapitalCost?: Maybe<BigFloatFilter>;
  leaseDepreciationValue?: Maybe<BigFloatFilter>;
  dealerFees?: Maybe<BigFloatFilter>;
  governmentFees?: Maybe<BigFloatFilter>;
  totalTax?: Maybe<BigFloatFilter>;
  salesman1TotalCommission?: Maybe<BigFloatFilter>;
  salesman1FrontCommission?: Maybe<BigFloatFilter>;
  salesman1BackCommission?: Maybe<BigFloatFilter>;
  salesman2TotalCommission?: Maybe<BigFloatFilter>;
  salesman2FrontCommission?: Maybe<BigFloatFilter>;
  salesman2BackCommission?: Maybe<BigFloatFilter>;
  salesman3TotalCommission?: Maybe<BigFloatFilter>;
  salesman3FrontCommission?: Maybe<BigFloatFilter>;
  salesman3BackCommission?: Maybe<BigFloatFilter>;
  warranty1Sale?: Maybe<BigFloatFilter>;
  warranty1Cost?: Maybe<BigFloatFilter>;
  warranty1Miles?: Maybe<BigFloatFilter>;
  warranty1Term?: Maybe<BigFloatFilter>;
  warranty2Sale?: Maybe<BigFloatFilter>;
  warranty2Cost?: Maybe<BigFloatFilter>;
  warranty2Miles?: Maybe<BigFloatFilter>;
  warranty2Term?: Maybe<BigFloatFilter>;
  warranty3Sale?: Maybe<BigFloatFilter>;
  warranty3Cost?: Maybe<BigFloatFilter>;
  warranty3Miles?: Maybe<BigFloatFilter>;
  warranty3Term?: Maybe<BigFloatFilter>;
  warranty4Sale?: Maybe<BigFloatFilter>;
  warranty4Cost?: Maybe<BigFloatFilter>;
  warranty4Miles?: Maybe<BigFloatFilter>;
  warranty4Term?: Maybe<BigFloatFilter>;
  warranty5Sale?: Maybe<BigFloatFilter>;
  warranty5Cost?: Maybe<BigFloatFilter>;
  warranty5Miles?: Maybe<BigFloatFilter>;
  warranty5Term?: Maybe<BigFloatFilter>;
  totalFeeAftermarketSale?: Maybe<BigFloatFilter>;
  totalFeeAftermarketCost?: Maybe<BigFloatFilter>;
  feeAftermarket1Sale?: Maybe<BigFloatFilter>;
  feeAftermarket1Cost?: Maybe<BigFloatFilter>;
  feeAftermarket2Sale?: Maybe<BigFloatFilter>;
  feeAftermarket2Cost?: Maybe<BigFloatFilter>;
  feeAftermarket3Sale?: Maybe<BigFloatFilter>;
  feeAftermarket3Cost?: Maybe<BigFloatFilter>;
  feeAftermarket4Sale?: Maybe<BigFloatFilter>;
  feeAftermarket4Cost?: Maybe<BigFloatFilter>;
  feeAftermarket5Sale?: Maybe<BigFloatFilter>;
  feeAftermarket5Cost?: Maybe<BigFloatFilter>;
  feeAftermarket6Sale?: Maybe<BigFloatFilter>;
  feeAftermarket6Cost?: Maybe<BigFloatFilter>;
  feeAftermarket7Sale?: Maybe<BigFloatFilter>;
  feeAftermarket7Cost?: Maybe<BigFloatFilter>;
  feeAftermarket8Sale?: Maybe<BigFloatFilter>;
  feeAftermarket8Cost?: Maybe<BigFloatFilter>;
  feeAftermarket9Sale?: Maybe<BigFloatFilter>;
  feeAftermarket9Cost?: Maybe<BigFloatFilter>;
  feeAftermarket10Sale?: Maybe<BigFloatFilter>;
  feeAftermarket10Cost?: Maybe<BigFloatFilter>;
  feeAftermarket11Sale?: Maybe<BigFloatFilter>;
  feeAftermarket11Cost?: Maybe<BigFloatFilter>;
  feeAftermarket12Sale?: Maybe<BigFloatFilter>;
  feeAftermarket12Cost?: Maybe<BigFloatFilter>;
  feeAftermarket13Sale?: Maybe<BigFloatFilter>;
  feeAftermarket13Cost?: Maybe<BigFloatFilter>;
  feeAftermarket14Sale?: Maybe<BigFloatFilter>;
  feeAftermarket14Cost?: Maybe<BigFloatFilter>;
  feeAftermarket15Sale?: Maybe<BigFloatFilter>;
  feeAftermarket15Cost?: Maybe<BigFloatFilter>;
  feeAftermarket16Sale?: Maybe<BigFloatFilter>;
  feeAftermarket16Cost?: Maybe<BigFloatFilter>;
  feeAftermarket17Sale?: Maybe<BigFloatFilter>;
  feeAftermarket17Cost?: Maybe<BigFloatFilter>;
  feeAftermarket18Sale?: Maybe<BigFloatFilter>;
  feeAftermarket18Cost?: Maybe<BigFloatFilter>;
  feeAftermarket19Sale?: Maybe<BigFloatFilter>;
  feeAftermarket19Cost?: Maybe<BigFloatFilter>;
  feeAftermarket20Sale?: Maybe<BigFloatFilter>;
  feeAftermarket20Cost?: Maybe<BigFloatFilter>;
  insurance1Sale?: Maybe<BigFloatFilter>;
  insurance1Cost?: Maybe<BigFloatFilter>;
  insurance1Term?: Maybe<BigFloatFilter>;
  insurance2Sale?: Maybe<BigFloatFilter>;
  insurance2Cost?: Maybe<BigFloatFilter>;
  insurance2Term?: Maybe<BigFloatFilter>;
  insurance3Sale?: Maybe<BigFloatFilter>;
  insurance3Cost?: Maybe<BigFloatFilter>;
  insurance3Term?: Maybe<BigFloatFilter>;
  accidentalHealthSale?: Maybe<BigFloatFilter>;
  accidentalHealthCost?: Maybe<BigFloatFilter>;
  accidentalHealthTerm?: Maybe<BigFloatFilter>;
  creditLifeSale?: Maybe<BigFloatFilter>;
  creditLifeCost?: Maybe<BigFloatFilter>;
  creditLifeTerm?: Maybe<BigFloatFilter>;
  levelizedLifeSale?: Maybe<BigFloatFilter>;
  levelizedLifeCost?: Maybe<BigFloatFilter>;
  levelizedLifeTerm?: Maybe<BigFloatFilter>;
  lossOfEmploymentSale?: Maybe<BigFloatFilter>;
  lossOfEmploymentCost?: Maybe<BigFloatFilter>;
  lossOfEmploymentTerm?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionSale?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<BigFloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<BigFloatFilter>;
};

export type DealVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of deliveryMileage across the matching connection */
  deliveryMileage?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of vinExplosionYear across the matching connection */
  vinExplosionYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of trade1Year across the matching connection */
  trade1Year?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of trade1Odometer across the matching connection */
  trade1Odometer?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of trade1ActualCashValue across the matching connection */
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of trade1Gross across the matching connection */
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of trade1Payoff across the matching connection */
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of trade2Year across the matching connection */
  trade2Year?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of trade2Odometer across the matching connection */
  trade2Odometer?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of trade2ActualCashValue across the matching connection */
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of trade2Gross across the matching connection */
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of trade2Payoff across the matching connection */
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of msrp across the matching connection */
  msrp?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of listPrice across the matching connection */
  listPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of salesPrice across the matching connection */
  salesPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of journalPrice across the matching connection */
  journalPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of journalCost across the matching connection */
  journalCost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of adjustments across the matching connection */
  adjustments?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of adjustedCost across the matching connection */
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of incentives across the matching connection */
  incentives?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of packAmount across the matching connection */
  packAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of saleNet across the matching connection */
  saleNet?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalTradeActualCashValue across the matching connection */
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalTradeGross across the matching connection */
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of weOweFront across the matching connection */
  weOweFront?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalFrontFeesAftermarketProfit across the matching connection */
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalFrontCommission across the matching connection */
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalFrontSales across the matching connection */
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalFrontCost across the matching connection */
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of frontGross across the matching connection */
  frontGross?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of financeProfit across the matching connection */
  financeProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalWarrantyProfit across the matching connection */
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of weOweBack across the matching connection */
  weOweBack?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of insuranceProfit across the matching connection */
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalBackFeesAftermarketProfit across the matching connection */
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of financeReserve across the matching connection */
  financeReserve?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalBackCommission across the matching connection */
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalBackSales across the matching connection */
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalBackCost across the matching connection */
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of backGross across the matching connection */
  backGross?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalProfit across the matching connection */
  totalProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of grossProfit across the matching connection */
  grossProfit?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of grossPayable across the matching connection */
  grossPayable?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of amountFinanced across the matching connection */
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of apr across the matching connection */
  apr?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of monthlyPayment across the matching connection */
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of paymentTotal across the matching connection */
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of rebates across the matching connection */
  rebates?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of deposit across the matching connection */
  deposit?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of downPayment across the matching connection */
  downPayment?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalNetTrades across the matching connection */
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalDown across the matching connection */
  totalDown?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of balloonAmount across the matching connection */
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of adjustedBalloonAmount across the matching connection */
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of holdbackAmount across the matching connection */
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalDriveOffAmount across the matching connection */
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of licenseFee across the matching connection */
  licenseFee?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of registrationFee across the matching connection */
  registrationFee?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of documentationFee across the matching connection */
  documentationFee?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of financeCharge across the matching connection */
  financeCharge?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalPickupPayments across the matching connection */
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of sellRate across the matching connection */
  sellRate?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of buyRate across the matching connection */
  buyRate?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of residualRate across the matching connection */
  residualRate?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of residualAmount across the matching connection */
  residualAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of allowedMiles across the matching connection */
  allowedMiles?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of estimatedMiles across the matching connection */
  estimatedMiles?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of mileageRate across the matching connection */
  mileageRate?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of acquisitionFee across the matching connection */
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of basePayment across the matching connection */
  basePayment?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of securityDeposit across the matching connection */
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalCapitalReduction across the matching connection */
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of netCapitalCost across the matching connection */
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of leaseDepreciationValue across the matching connection */
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of dealerFees across the matching connection */
  dealerFees?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of governmentFees across the matching connection */
  governmentFees?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalTax across the matching connection */
  totalTax?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of salesman1TotalCommission across the matching connection */
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of salesman1FrontCommission across the matching connection */
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of salesman1BackCommission across the matching connection */
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of salesman2TotalCommission across the matching connection */
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of salesman2FrontCommission across the matching connection */
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of salesman2BackCommission across the matching connection */
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of salesman3TotalCommission across the matching connection */
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of salesman3FrontCommission across the matching connection */
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of salesman3BackCommission across the matching connection */
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty1Sale across the matching connection */
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty1Cost across the matching connection */
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty1Miles across the matching connection */
  warranty1Miles?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty1Term across the matching connection */
  warranty1Term?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty2Sale across the matching connection */
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty2Cost across the matching connection */
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty2Miles across the matching connection */
  warranty2Miles?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty2Term across the matching connection */
  warranty2Term?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty3Sale across the matching connection */
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty3Cost across the matching connection */
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty3Miles across the matching connection */
  warranty3Miles?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty3Term across the matching connection */
  warranty3Term?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty4Sale across the matching connection */
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty4Cost across the matching connection */
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty4Miles across the matching connection */
  warranty4Miles?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty4Term across the matching connection */
  warranty4Term?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty5Sale across the matching connection */
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty5Cost across the matching connection */
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty5Miles across the matching connection */
  warranty5Miles?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of warranty5Term across the matching connection */
  warranty5Term?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalFeeAftermarketSale across the matching connection */
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of totalFeeAftermarketCost across the matching connection */
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket1Sale across the matching connection */
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket1Cost across the matching connection */
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket2Sale across the matching connection */
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket2Cost across the matching connection */
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket3Sale across the matching connection */
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket3Cost across the matching connection */
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket4Sale across the matching connection */
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket4Cost across the matching connection */
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket5Sale across the matching connection */
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket5Cost across the matching connection */
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket6Sale across the matching connection */
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket6Cost across the matching connection */
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket7Sale across the matching connection */
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket7Cost across the matching connection */
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket8Sale across the matching connection */
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket8Cost across the matching connection */
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket9Sale across the matching connection */
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket9Cost across the matching connection */
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket10Sale across the matching connection */
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket10Cost across the matching connection */
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket11Sale across the matching connection */
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket11Cost across the matching connection */
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket12Sale across the matching connection */
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket12Cost across the matching connection */
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket13Sale across the matching connection */
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket13Cost across the matching connection */
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket14Sale across the matching connection */
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket14Cost across the matching connection */
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket15Sale across the matching connection */
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket15Cost across the matching connection */
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket16Sale across the matching connection */
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket16Cost across the matching connection */
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket17Sale across the matching connection */
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket17Cost across the matching connection */
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket18Sale across the matching connection */
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket18Cost across the matching connection */
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket19Sale across the matching connection */
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket19Cost across the matching connection */
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket20Sale across the matching connection */
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of feeAftermarket20Cost across the matching connection */
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of insurance1Sale across the matching connection */
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of insurance1Cost across the matching connection */
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of insurance1Term across the matching connection */
  insurance1Term?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of insurance2Sale across the matching connection */
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of insurance2Cost across the matching connection */
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of insurance2Term across the matching connection */
  insurance2Term?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of insurance3Sale across the matching connection */
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of insurance3Cost across the matching connection */
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of insurance3Term across the matching connection */
  insurance3Term?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of accidentalHealthSale across the matching connection */
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of accidentalHealthCost across the matching connection */
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of accidentalHealthTerm across the matching connection */
  accidentalHealthTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditLifeSale across the matching connection */
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditLifeCost across the matching connection */
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditLifeTerm across the matching connection */
  creditLifeTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of levelizedLifeSale across the matching connection */
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of levelizedLifeCost across the matching connection */
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of levelizedLifeTerm across the matching connection */
  levelizedLifeTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lossOfEmploymentSale across the matching connection */
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lossOfEmploymentCost across the matching connection */
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lossOfEmploymentTerm across the matching connection */
  lossOfEmploymentTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of guaranteedAutoProtectionSale across the matching connection */
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of guaranteedAutoProtectionCost across the matching connection */
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of guaranteedAutoProtectionTerm across the matching connection */
  guaranteedAutoProtectionTerm?: Maybe<Scalars['BigFloat']>;
};

/** The `companyIntegration` to be created by this mutation. */
export type DealsCompanyIntegrationIdFkeyCompanyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** The `deal` to be created by this mutation. */
export type DealsCompanyIntegrationIdFkeyDealsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  fileType?: Maybe<Scalars['String']>;
  dvDealerId?: Maybe<Scalars['String']>;
  vendorDealerId?: Maybe<Scalars['String']>;
  dmsType?: Maybe<Scalars['String']>;
  dealNumber?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  workExtension?: Maybe<Scalars['String']>;
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  email3?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  individualBusinessFlag?: Maybe<Scalars['String']>;
  optOut?: Maybe<Scalars['Boolean']>;
  blockEmail?: Maybe<Scalars['Boolean']>;
  blockPhone?: Maybe<Scalars['Boolean']>;
  blockMail?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  customerCreateDate?: Maybe<Scalars['Date']>;
  customerLastActivityDate?: Maybe<Scalars['Date']>;
  coBuyerCustomerNumber?: Maybe<Scalars['String']>;
  coBuyerFullName?: Maybe<Scalars['String']>;
  coBuyerSalutation?: Maybe<Scalars['String']>;
  coBuyerFirstName?: Maybe<Scalars['String']>;
  coBuyerMiddleName?: Maybe<Scalars['String']>;
  coBuyerLastName?: Maybe<Scalars['String']>;
  coBuyerSuffix?: Maybe<Scalars['String']>;
  coBuyerAddressLine1?: Maybe<Scalars['String']>;
  coBuyerAddressLine2?: Maybe<Scalars['String']>;
  coBuyerCity?: Maybe<Scalars['String']>;
  coBuyerState?: Maybe<Scalars['String']>;
  coBuyerZip?: Maybe<Scalars['String']>;
  coBuyerCounty?: Maybe<Scalars['String']>;
  coBuyerHomePhone?: Maybe<Scalars['String']>;
  coBuyerCellPhone?: Maybe<Scalars['String']>;
  coBuyerWorkPhone?: Maybe<Scalars['String']>;
  coBuyerWorkExtension?: Maybe<Scalars['String']>;
  coBuyerEmail1?: Maybe<Scalars['String']>;
  coBuyerEmail2?: Maybe<Scalars['String']>;
  coBuyerEmail3?: Maybe<Scalars['String']>;
  coBuyerBirthDate?: Maybe<Scalars['Date']>;
  coBuyerIndividualBusinessFlag?: Maybe<Scalars['String']>;
  coBuyerOptOut?: Maybe<Scalars['Boolean']>;
  coBuyerBlockEmail?: Maybe<Scalars['Boolean']>;
  coBuyerBlockPhone?: Maybe<Scalars['Boolean']>;
  coBuyerBlockMail?: Maybe<Scalars['Boolean']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  newUsed?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  engineConfiguration?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  engineNumber?: Maybe<Scalars['String']>;
  chassisNumber?: Maybe<Scalars['String']>;
  licensePlateNumber?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  deliveryMileage?: Maybe<Scalars['Int']>;
  inventoryDate?: Maybe<Scalars['Date']>;
  inServiceDate?: Maybe<Scalars['Date']>;
  vinExplosionYear?: Maybe<Scalars['Int']>;
  vinExplosionMake?: Maybe<Scalars['String']>;
  vinExplosionModel?: Maybe<Scalars['String']>;
  vinExplosionTrim?: Maybe<Scalars['String']>;
  vinExplosionTransmissionType?: Maybe<Scalars['String']>;
  vinExplosionFuelType?: Maybe<Scalars['String']>;
  vinExplosionEngineSize?: Maybe<Scalars['String']>;
  vinExplosionGvwRange?: Maybe<Scalars['String']>;
  trade1Vin?: Maybe<Scalars['String']>;
  trade1Year?: Maybe<Scalars['Int']>;
  trade1Make?: Maybe<Scalars['String']>;
  trade1Model?: Maybe<Scalars['String']>;
  trade1Odometer?: Maybe<Scalars['Int']>;
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  trade2Vin?: Maybe<Scalars['String']>;
  trade2Year?: Maybe<Scalars['Int']>;
  trade2Make?: Maybe<Scalars['String']>;
  trade2Model?: Maybe<Scalars['String']>;
  trade2Odometer?: Maybe<Scalars['Int']>;
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  salesman1Number?: Maybe<Scalars['String']>;
  salesman1Name?: Maybe<Scalars['String']>;
  salesman2Number?: Maybe<Scalars['String']>;
  salesman2Name?: Maybe<Scalars['String']>;
  salesman3Number?: Maybe<Scalars['String']>;
  salesman3Name?: Maybe<Scalars['String']>;
  closingManagerNumber?: Maybe<Scalars['String']>;
  closingManagerName?: Maybe<Scalars['String']>;
  financeManagerNumber?: Maybe<Scalars['String']>;
  financeManagerName?: Maybe<Scalars['String']>;
  salesmanManagerNumber?: Maybe<Scalars['String']>;
  salesmanManagerName?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['BigFloat']>;
  listPrice?: Maybe<Scalars['BigFloat']>;
  salesPrice?: Maybe<Scalars['BigFloat']>;
  journalPrice?: Maybe<Scalars['BigFloat']>;
  cost?: Maybe<Scalars['BigFloat']>;
  journalCost?: Maybe<Scalars['BigFloat']>;
  adjustments?: Maybe<Scalars['BigFloat']>;
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  incentives?: Maybe<Scalars['BigFloat']>;
  packAmount?: Maybe<Scalars['BigFloat']>;
  saleNet?: Maybe<Scalars['BigFloat']>;
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  weOweFront?: Maybe<Scalars['BigFloat']>;
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  frontGross?: Maybe<Scalars['BigFloat']>;
  financeProfit?: Maybe<Scalars['BigFloat']>;
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  weOweBack?: Maybe<Scalars['BigFloat']>;
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  financeReserve?: Maybe<Scalars['BigFloat']>;
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  backGross?: Maybe<Scalars['BigFloat']>;
  totalProfit?: Maybe<Scalars['BigFloat']>;
  grossProfit?: Maybe<Scalars['BigFloat']>;
  grossPayable?: Maybe<Scalars['BigFloat']>;
  dealStatus?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  bookedDate?: Maybe<Scalars['Date']>;
  finalizedDate?: Maybe<Scalars['Date']>;
  contractDate?: Maybe<Scalars['Date']>;
  accountingDate?: Maybe<Scalars['Date']>;
  statusChangeDate?: Maybe<Scalars['Date']>;
  firstPayDate?: Maybe<Scalars['Date']>;
  dealType?: Maybe<Scalars['String']>;
  saleType?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  apr?: Maybe<Scalars['BigFloat']>;
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  rebates?: Maybe<Scalars['BigFloat']>;
  deposit?: Maybe<Scalars['BigFloat']>;
  downPayment?: Maybe<Scalars['BigFloat']>;
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  totalDown?: Maybe<Scalars['BigFloat']>;
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  licenseFee?: Maybe<Scalars['BigFloat']>;
  registrationFee?: Maybe<Scalars['BigFloat']>;
  documentationFee?: Maybe<Scalars['BigFloat']>;
  financeCharge?: Maybe<Scalars['BigFloat']>;
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  sellRate?: Maybe<Scalars['BigFloat']>;
  buyRate?: Maybe<Scalars['BigFloat']>;
  residualRate?: Maybe<Scalars['BigFloat']>;
  residualAmount?: Maybe<Scalars['BigFloat']>;
  allowedMiles?: Maybe<Scalars['Int']>;
  estimatedMiles?: Maybe<Scalars['Int']>;
  mileageRate?: Maybe<Scalars['BigFloat']>;
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  basePayment?: Maybe<Scalars['BigFloat']>;
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  dealerFees?: Maybe<Scalars['BigFloat']>;
  governmentFees?: Maybe<Scalars['BigFloat']>;
  totalTax?: Maybe<Scalars['BigFloat']>;
  registrationState?: Maybe<Scalars['String']>;
  reportOfSaleNumber?: Maybe<Scalars['String']>;
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  warranty1Name?: Maybe<Scalars['String']>;
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  warranty1Miles?: Maybe<Scalars['Int']>;
  warranty1Term?: Maybe<Scalars['Int']>;
  warranty2Name?: Maybe<Scalars['String']>;
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  warranty2Miles?: Maybe<Scalars['Int']>;
  warranty2Term?: Maybe<Scalars['Int']>;
  warranty3Name?: Maybe<Scalars['String']>;
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  warranty3Miles?: Maybe<Scalars['Int']>;
  warranty3Term?: Maybe<Scalars['Int']>;
  warranty4Name?: Maybe<Scalars['String']>;
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  warranty4Miles?: Maybe<Scalars['Int']>;
  warranty4Term?: Maybe<Scalars['Int']>;
  warranty5Name?: Maybe<Scalars['String']>;
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  warranty5Miles?: Maybe<Scalars['Int']>;
  warranty5Term?: Maybe<Scalars['Int']>;
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Name?: Maybe<Scalars['String']>;
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket2Name?: Maybe<Scalars['String']>;
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket3Name?: Maybe<Scalars['String']>;
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket4Name?: Maybe<Scalars['String']>;
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket5Name?: Maybe<Scalars['String']>;
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket6Name?: Maybe<Scalars['String']>;
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket7Name?: Maybe<Scalars['String']>;
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket8Name?: Maybe<Scalars['String']>;
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket9Name?: Maybe<Scalars['String']>;
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket10Name?: Maybe<Scalars['String']>;
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket11Name?: Maybe<Scalars['String']>;
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket12Name?: Maybe<Scalars['String']>;
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket13Name?: Maybe<Scalars['String']>;
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket14Name?: Maybe<Scalars['String']>;
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket15Name?: Maybe<Scalars['String']>;
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket16Name?: Maybe<Scalars['String']>;
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket17Name?: Maybe<Scalars['String']>;
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket18Name?: Maybe<Scalars['String']>;
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket19Name?: Maybe<Scalars['String']>;
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket20Name?: Maybe<Scalars['String']>;
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20ProfitIndicator?: Maybe<Scalars['Boolean']>;
  insurance1Type?: Maybe<Scalars['String']>;
  insurance1Name?: Maybe<Scalars['String']>;
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  insurance1Term?: Maybe<Scalars['Int']>;
  insurance2Type?: Maybe<Scalars['String']>;
  insurance2Name?: Maybe<Scalars['String']>;
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  insurance2Term?: Maybe<Scalars['Int']>;
  insurance3Type?: Maybe<Scalars['String']>;
  insurance3Name?: Maybe<Scalars['String']>;
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  insurance3Term?: Maybe<Scalars['Int']>;
  accidentalHealthType?: Maybe<Scalars['String']>;
  accidentalHealthName?: Maybe<Scalars['String']>;
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  accidentalHealthTerm?: Maybe<Scalars['Int']>;
  creditLifeType?: Maybe<Scalars['String']>;
  creditLifeName?: Maybe<Scalars['String']>;
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  creditLifeTerm?: Maybe<Scalars['Int']>;
  levelizedLifeType?: Maybe<Scalars['String']>;
  levelizedLifeName?: Maybe<Scalars['String']>;
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  levelizedLifeTerm?: Maybe<Scalars['Int']>;
  lossOfEmploymentType?: Maybe<Scalars['String']>;
  lossOfEmploymentName?: Maybe<Scalars['String']>;
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentTerm?: Maybe<Scalars['Int']>;
  guaranteedAutoProtectionType?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionName?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionTerm?: Maybe<Scalars['Int']>;
  saleComments?: Maybe<Scalars['String']>;
  cassStdLine1?: Maybe<Scalars['String']>;
  cassStdLine2?: Maybe<Scalars['String']>;
  cassStdCity?: Maybe<Scalars['String']>;
  cassStdState?: Maybe<Scalars['String']>;
  cassStdZip?: Maybe<Scalars['String']>;
  cassStdZip4?: Maybe<Scalars['String']>;
  cassStdDpbc?: Maybe<Scalars['String']>;
  cassStdChkdgt?: Maybe<Scalars['String']>;
  cassStdCart?: Maybe<Scalars['String']>;
  cassStdLot?: Maybe<Scalars['String']>;
  cassStdLotord?: Maybe<Scalars['String']>;
  cassStdUrb?: Maybe<Scalars['String']>;
  cassStdFips?: Maybe<Scalars['String']>;
  cassStdEws?: Maybe<Scalars['String']>;
  cassStdLacs?: Maybe<Scalars['String']>;
  cassStdZipmov?: Maybe<Scalars['String']>;
  cassStdZ4Lom?: Maybe<Scalars['String']>;
  cassStdNdiapt?: Maybe<Scalars['String']>;
  cassStdNdirr?: Maybe<Scalars['String']>;
  cassStdLacsrt?: Maybe<Scalars['String']>;
  cassStdErrorCd?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<DealsLeadIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<DealsCompanyIntegrationIdFkeyInput>;
};

/** Input for the nested mutation of `companyIntegration` in the `DealInput` mutation. */
export type DealsCompanyIntegrationIdFkeyInput = {
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyIntegrationNodeIdConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyDelete>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyIntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateById?: Maybe<CompanyIntegrationOnDealForDealsCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<DealOnDealForDealsCompanyIntegrationIdFkeyNodeIdUpdate>;
  /** A `CompanyIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<DealsCompanyIntegrationIdFkeyCompanyIntegrationCreateInput>;
};

/** Input for the nested mutation of `deal` in the `CompanyIntegrationInput` mutation. */
export type DealsCompanyIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `deal` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `deal` for the far side of the relationship. */
  connectById?: Maybe<Array<DealDealsPkeyConnect>>;
  /** The primary key(s) for `deal` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DealNodeIdConnect>>;
  /** The primary key(s) for `deal` for the far side of the relationship. */
  deleteById?: Maybe<Array<DealDealsPkeyDelete>>;
  /** The primary key(s) for `deal` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<DealNodeIdDelete>>;
  /** The primary key(s) and patch data for `deal` for the far side of the relationship. */
  updateById?: Maybe<Array<DealOnDealForDealsCompanyIntegrationIdFkeyUsingDealsPkeyUpdate>>;
  /** The primary key(s) and patch data for `deal` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyIntegrationOnDealForDealsCompanyIntegrationIdFkeyNodeIdUpdate>>;
  /** A `DealInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DealsCompanyIntegrationIdFkeyDealsCreateInput>>;
};

/** A connection to a list of `Deal` values. */
export type DealsConnection = {
  /** A list of `Deal` objects. */
  nodes: Array<Deal>;
  /** A list of edges which contains the `Deal` and cursor to aid in pagination. */
  edges: Array<DealsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Deal` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealAggregates>>;
};


/** A connection to a list of `Deal` values. */
export type DealsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DealsGroupBy>;
  having?: Maybe<DealsHavingInput>;
};

/** A `Deal` edge in the connection. */
export type DealsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Deal` at the end of the edge. */
  node: Deal;
};

/** Grouping methods for `Deal` for usage during aggregation. */
export enum DealsGroupBy {
  LeadId = 'LEAD_ID',
  CompanyIntegrationId = 'COMPANY_INTEGRATION_ID',
  FileType = 'FILE_TYPE',
  DvDealerId = 'DV_DEALER_ID',
  VendorDealerId = 'VENDOR_DEALER_ID',
  DmsType = 'DMS_TYPE',
  DealNumber = 'DEAL_NUMBER',
  CustomerNumber = 'CUSTOMER_NUMBER',
  FullName = 'FULL_NAME',
  Salutation = 'SALUTATION',
  FirstName = 'FIRST_NAME',
  MiddleName = 'MIDDLE_NAME',
  LastName = 'LAST_NAME',
  Suffix = 'SUFFIX',
  AddressLine_1 = 'ADDRESS_LINE_1',
  AddressLine_2 = 'ADDRESS_LINE_2',
  City = 'CITY',
  State = 'STATE',
  Zip = 'ZIP',
  County = 'COUNTY',
  HomePhone = 'HOME_PHONE',
  CellPhone = 'CELL_PHONE',
  WorkPhone = 'WORK_PHONE',
  WorkExtension = 'WORK_EXTENSION',
  Email_1 = 'EMAIL_1',
  Email_2 = 'EMAIL_2',
  Email_3 = 'EMAIL_3',
  BirthDate = 'BIRTH_DATE',
  IndividualBusinessFlag = 'INDIVIDUAL_BUSINESS_FLAG',
  OptOut = 'OPT_OUT',
  BlockEmail = 'BLOCK_EMAIL',
  BlockPhone = 'BLOCK_PHONE',
  BlockMail = 'BLOCK_MAIL',
  Language = 'LANGUAGE',
  CustomerCreateDate = 'CUSTOMER_CREATE_DATE',
  CustomerLastActivityDate = 'CUSTOMER_LAST_ACTIVITY_DATE',
  CoBuyerCustomerNumber = 'CO_BUYER_CUSTOMER_NUMBER',
  CoBuyerFullName = 'CO_BUYER_FULL_NAME',
  CoBuyerSalutation = 'CO_BUYER_SALUTATION',
  CoBuyerFirstName = 'CO_BUYER_FIRST_NAME',
  CoBuyerMiddleName = 'CO_BUYER_MIDDLE_NAME',
  CoBuyerLastName = 'CO_BUYER_LAST_NAME',
  CoBuyerSuffix = 'CO_BUYER_SUFFIX',
  CoBuyerAddressLine_1 = 'CO_BUYER_ADDRESS_LINE_1',
  CoBuyerAddressLine_2 = 'CO_BUYER_ADDRESS_LINE_2',
  CoBuyerCity = 'CO_BUYER_CITY',
  CoBuyerState = 'CO_BUYER_STATE',
  CoBuyerZip = 'CO_BUYER_ZIP',
  CoBuyerCounty = 'CO_BUYER_COUNTY',
  CoBuyerHomePhone = 'CO_BUYER_HOME_PHONE',
  CoBuyerCellPhone = 'CO_BUYER_CELL_PHONE',
  CoBuyerWorkPhone = 'CO_BUYER_WORK_PHONE',
  CoBuyerWorkExtension = 'CO_BUYER_WORK_EXTENSION',
  CoBuyerEmail_1 = 'CO_BUYER_EMAIL_1',
  CoBuyerEmail_2 = 'CO_BUYER_EMAIL_2',
  CoBuyerEmail_3 = 'CO_BUYER_EMAIL_3',
  CoBuyerBirthDate = 'CO_BUYER_BIRTH_DATE',
  CoBuyerIndividualBusinessFlag = 'CO_BUYER_INDIVIDUAL_BUSINESS_FLAG',
  CoBuyerOptOut = 'CO_BUYER_OPT_OUT',
  CoBuyerBlockEmail = 'CO_BUYER_BLOCK_EMAIL',
  CoBuyerBlockPhone = 'CO_BUYER_BLOCK_PHONE',
  CoBuyerBlockMail = 'CO_BUYER_BLOCK_MAIL',
  Vin = 'VIN',
  Year = 'YEAR',
  Make = 'MAKE',
  Model = 'MODEL',
  ModelNumber = 'MODEL_NUMBER',
  Mileage = 'MILEAGE',
  Description = 'DESCRIPTION',
  ExteriorColor = 'EXTERIOR_COLOR',
  NewUsed = 'NEW_USED',
  StockNumber = 'STOCK_NUMBER',
  Transmission = 'TRANSMISSION',
  EngineConfiguration = 'ENGINE_CONFIGURATION',
  Trim = 'TRIM',
  EngineNumber = 'ENGINE_NUMBER',
  ChassisNumber = 'CHASSIS_NUMBER',
  LicensePlateNumber = 'LICENSE_PLATE_NUMBER',
  DeliveryDate = 'DELIVERY_DATE',
  DeliveryMileage = 'DELIVERY_MILEAGE',
  InventoryDate = 'INVENTORY_DATE',
  InServiceDate = 'IN_SERVICE_DATE',
  VinExplosionYear = 'VIN_EXPLOSION_YEAR',
  VinExplosionMake = 'VIN_EXPLOSION_MAKE',
  VinExplosionModel = 'VIN_EXPLOSION_MODEL',
  VinExplosionTrim = 'VIN_EXPLOSION_TRIM',
  VinExplosionTransmissionType = 'VIN_EXPLOSION_TRANSMISSION_TYPE',
  VinExplosionFuelType = 'VIN_EXPLOSION_FUEL_TYPE',
  VinExplosionEngineSize = 'VIN_EXPLOSION_ENGINE_SIZE',
  VinExplosionGvwRange = 'VIN_EXPLOSION_GVW_RANGE',
  Trade_1Vin = 'TRADE_1_VIN',
  Trade_1Year = 'TRADE_1_YEAR',
  Trade_1Make = 'TRADE_1_MAKE',
  Trade_1Model = 'TRADE_1_MODEL',
  Trade_1Odometer = 'TRADE_1_ODOMETER',
  Trade_1ActualCashValue = 'TRADE_1_ACTUAL_CASH_VALUE',
  Trade_1Gross = 'TRADE_1_GROSS',
  Trade_1Payoff = 'TRADE_1_PAYOFF',
  Trade_2Vin = 'TRADE_2_VIN',
  Trade_2Year = 'TRADE_2_YEAR',
  Trade_2Make = 'TRADE_2_MAKE',
  Trade_2Model = 'TRADE_2_MODEL',
  Trade_2Odometer = 'TRADE_2_ODOMETER',
  Trade_2ActualCashValue = 'TRADE_2_ACTUAL_CASH_VALUE',
  Trade_2Gross = 'TRADE_2_GROSS',
  Trade_2Payoff = 'TRADE_2_PAYOFF',
  Salesman_1Number = 'SALESMAN_1_NUMBER',
  Salesman_1Name = 'SALESMAN_1_NAME',
  Salesman_2Number = 'SALESMAN_2_NUMBER',
  Salesman_2Name = 'SALESMAN_2_NAME',
  Salesman_3Number = 'SALESMAN_3_NUMBER',
  Salesman_3Name = 'SALESMAN_3_NAME',
  ClosingManagerNumber = 'CLOSING_MANAGER_NUMBER',
  ClosingManagerName = 'CLOSING_MANAGER_NAME',
  FinanceManagerNumber = 'FINANCE_MANAGER_NUMBER',
  FinanceManagerName = 'FINANCE_MANAGER_NAME',
  SalesmanManagerNumber = 'SALESMAN_MANAGER_NUMBER',
  SalesmanManagerName = 'SALESMAN_MANAGER_NAME',
  Msrp = 'MSRP',
  ListPrice = 'LIST_PRICE',
  SalesPrice = 'SALES_PRICE',
  JournalPrice = 'JOURNAL_PRICE',
  Cost = 'COST',
  JournalCost = 'JOURNAL_COST',
  Adjustments = 'ADJUSTMENTS',
  AdjustedCost = 'ADJUSTED_COST',
  Incentives = 'INCENTIVES',
  PackAmount = 'PACK_AMOUNT',
  SaleNet = 'SALE_NET',
  TotalTradeActualCashValue = 'TOTAL_TRADE_ACTUAL_CASH_VALUE',
  TotalTradeGross = 'TOTAL_TRADE_GROSS',
  WeOweFront = 'WE_OWE_FRONT',
  TotalFrontFeesAftermarketProfit = 'TOTAL_FRONT_FEES_AFTERMARKET_PROFIT',
  TotalFrontCommission = 'TOTAL_FRONT_COMMISSION',
  TotalFrontSales = 'TOTAL_FRONT_SALES',
  TotalFrontCost = 'TOTAL_FRONT_COST',
  FrontGross = 'FRONT_GROSS',
  FinanceProfit = 'FINANCE_PROFIT',
  TotalWarrantyProfit = 'TOTAL_WARRANTY_PROFIT',
  WeOweBack = 'WE_OWE_BACK',
  InsuranceProfit = 'INSURANCE_PROFIT',
  TotalBackFeesAftermarketProfit = 'TOTAL_BACK_FEES_AFTERMARKET_PROFIT',
  FinanceReserve = 'FINANCE_RESERVE',
  TotalBackCommission = 'TOTAL_BACK_COMMISSION',
  TotalBackSales = 'TOTAL_BACK_SALES',
  TotalBackCost = 'TOTAL_BACK_COST',
  BackGross = 'BACK_GROSS',
  TotalProfit = 'TOTAL_PROFIT',
  GrossProfit = 'GROSS_PROFIT',
  GrossPayable = 'GROSS_PAYABLE',
  DealStatus = 'DEAL_STATUS',
  EntryDate = 'ENTRY_DATE',
  BookedDate = 'BOOKED_DATE',
  FinalizedDate = 'FINALIZED_DATE',
  ContractDate = 'CONTRACT_DATE',
  AccountingDate = 'ACCOUNTING_DATE',
  StatusChangeDate = 'STATUS_CHANGE_DATE',
  FirstPayDate = 'FIRST_PAY_DATE',
  DealType = 'DEAL_TYPE',
  SaleType = 'SALE_TYPE',
  BankId = 'BANK_ID',
  BankName = 'BANK_NAME',
  BankAddress = 'BANK_ADDRESS',
  Term = 'TERM',
  AmountFinanced = 'AMOUNT_FINANCED',
  Apr = 'APR',
  MonthlyPayment = 'MONTHLY_PAYMENT',
  PaymentTotal = 'PAYMENT_TOTAL',
  Rebates = 'REBATES',
  Deposit = 'DEPOSIT',
  DownPayment = 'DOWN_PAYMENT',
  TotalNetTrades = 'TOTAL_NET_TRADES',
  TotalDown = 'TOTAL_DOWN',
  BalloonAmount = 'BALLOON_AMOUNT',
  AdjustedBalloonAmount = 'ADJUSTED_BALLOON_AMOUNT',
  HoldbackAmount = 'HOLDBACK_AMOUNT',
  TotalDriveOffAmount = 'TOTAL_DRIVE_OFF_AMOUNT',
  LicenseFee = 'LICENSE_FEE',
  RegistrationFee = 'REGISTRATION_FEE',
  DocumentationFee = 'DOCUMENTATION_FEE',
  FinanceCharge = 'FINANCE_CHARGE',
  TotalPickupPayments = 'TOTAL_PICKUP_PAYMENTS',
  SellRate = 'SELL_RATE',
  BuyRate = 'BUY_RATE',
  ResidualRate = 'RESIDUAL_RATE',
  ResidualAmount = 'RESIDUAL_AMOUNT',
  AllowedMiles = 'ALLOWED_MILES',
  EstimatedMiles = 'ESTIMATED_MILES',
  MileageRate = 'MILEAGE_RATE',
  AcquisitionFee = 'ACQUISITION_FEE',
  BasePayment = 'BASE_PAYMENT',
  SecurityDeposit = 'SECURITY_DEPOSIT',
  TotalCapitalReduction = 'TOTAL_CAPITAL_REDUCTION',
  NetCapitalCost = 'NET_CAPITAL_COST',
  LeaseDepreciationValue = 'LEASE_DEPRECIATION_VALUE',
  DealerFees = 'DEALER_FEES',
  GovernmentFees = 'GOVERNMENT_FEES',
  TotalTax = 'TOTAL_TAX',
  RegistrationState = 'REGISTRATION_STATE',
  ReportOfSaleNumber = 'REPORT_OF_SALE_NUMBER',
  Salesman_1TotalCommission = 'SALESMAN_1_TOTAL_COMMISSION',
  Salesman_1FrontCommission = 'SALESMAN_1_FRONT_COMMISSION',
  Salesman_1BackCommission = 'SALESMAN_1_BACK_COMMISSION',
  Salesman_2TotalCommission = 'SALESMAN_2_TOTAL_COMMISSION',
  Salesman_2FrontCommission = 'SALESMAN_2_FRONT_COMMISSION',
  Salesman_2BackCommission = 'SALESMAN_2_BACK_COMMISSION',
  Salesman_3TotalCommission = 'SALESMAN_3_TOTAL_COMMISSION',
  Salesman_3FrontCommission = 'SALESMAN_3_FRONT_COMMISSION',
  Salesman_3BackCommission = 'SALESMAN_3_BACK_COMMISSION',
  Warranty_1Name = 'WARRANTY_1_NAME',
  Warranty_1Sale = 'WARRANTY_1_SALE',
  Warranty_1Cost = 'WARRANTY_1_COST',
  Warranty_1Miles = 'WARRANTY_1_MILES',
  Warranty_1Term = 'WARRANTY_1_TERM',
  Warranty_2Name = 'WARRANTY_2_NAME',
  Warranty_2Sale = 'WARRANTY_2_SALE',
  Warranty_2Cost = 'WARRANTY_2_COST',
  Warranty_2Miles = 'WARRANTY_2_MILES',
  Warranty_2Term = 'WARRANTY_2_TERM',
  Warranty_3Name = 'WARRANTY_3_NAME',
  Warranty_3Sale = 'WARRANTY_3_SALE',
  Warranty_3Cost = 'WARRANTY_3_COST',
  Warranty_3Miles = 'WARRANTY_3_MILES',
  Warranty_3Term = 'WARRANTY_3_TERM',
  Warranty_4Name = 'WARRANTY_4_NAME',
  Warranty_4Sale = 'WARRANTY_4_SALE',
  Warranty_4Cost = 'WARRANTY_4_COST',
  Warranty_4Miles = 'WARRANTY_4_MILES',
  Warranty_4Term = 'WARRANTY_4_TERM',
  Warranty_5Name = 'WARRANTY_5_NAME',
  Warranty_5Sale = 'WARRANTY_5_SALE',
  Warranty_5Cost = 'WARRANTY_5_COST',
  Warranty_5Miles = 'WARRANTY_5_MILES',
  Warranty_5Term = 'WARRANTY_5_TERM',
  TotalFeeAftermarketSale = 'TOTAL_FEE_AFTERMARKET_SALE',
  TotalFeeAftermarketCost = 'TOTAL_FEE_AFTERMARKET_COST',
  FeeAftermarket_1Name = 'FEE_AFTERMARKET_1_NAME',
  FeeAftermarket_1Sale = 'FEE_AFTERMARKET_1_SALE',
  FeeAftermarket_1Cost = 'FEE_AFTERMARKET_1_COST',
  FeeAftermarket_1ProfitIndicator = 'FEE_AFTERMARKET_1_PROFIT_INDICATOR',
  FeeAftermarket_2Name = 'FEE_AFTERMARKET_2_NAME',
  FeeAftermarket_2Sale = 'FEE_AFTERMARKET_2_SALE',
  FeeAftermarket_2Cost = 'FEE_AFTERMARKET_2_COST',
  FeeAftermarket_2ProfitIndicator = 'FEE_AFTERMARKET_2_PROFIT_INDICATOR',
  FeeAftermarket_3Name = 'FEE_AFTERMARKET_3_NAME',
  FeeAftermarket_3Sale = 'FEE_AFTERMARKET_3_SALE',
  FeeAftermarket_3Cost = 'FEE_AFTERMARKET_3_COST',
  FeeAftermarket_3ProfitIndicator = 'FEE_AFTERMARKET_3_PROFIT_INDICATOR',
  FeeAftermarket_4Name = 'FEE_AFTERMARKET_4_NAME',
  FeeAftermarket_4Sale = 'FEE_AFTERMARKET_4_SALE',
  FeeAftermarket_4Cost = 'FEE_AFTERMARKET_4_COST',
  FeeAftermarket_4ProfitIndicator = 'FEE_AFTERMARKET_4_PROFIT_INDICATOR',
  FeeAftermarket_5Name = 'FEE_AFTERMARKET_5_NAME',
  FeeAftermarket_5Sale = 'FEE_AFTERMARKET_5_SALE',
  FeeAftermarket_5Cost = 'FEE_AFTERMARKET_5_COST',
  FeeAftermarket_5ProfitIndicator = 'FEE_AFTERMARKET_5_PROFIT_INDICATOR',
  FeeAftermarket_6Name = 'FEE_AFTERMARKET_6_NAME',
  FeeAftermarket_6Sale = 'FEE_AFTERMARKET_6_SALE',
  FeeAftermarket_6Cost = 'FEE_AFTERMARKET_6_COST',
  FeeAftermarket_6ProfitIndicator = 'FEE_AFTERMARKET_6_PROFIT_INDICATOR',
  FeeAftermarket_7Name = 'FEE_AFTERMARKET_7_NAME',
  FeeAftermarket_7Sale = 'FEE_AFTERMARKET_7_SALE',
  FeeAftermarket_7Cost = 'FEE_AFTERMARKET_7_COST',
  FeeAftermarket_7ProfitIndicator = 'FEE_AFTERMARKET_7_PROFIT_INDICATOR',
  FeeAftermarket_8Name = 'FEE_AFTERMARKET_8_NAME',
  FeeAftermarket_8Sale = 'FEE_AFTERMARKET_8_SALE',
  FeeAftermarket_8Cost = 'FEE_AFTERMARKET_8_COST',
  FeeAftermarket_8ProfitIndicator = 'FEE_AFTERMARKET_8_PROFIT_INDICATOR',
  FeeAftermarket_9Name = 'FEE_AFTERMARKET_9_NAME',
  FeeAftermarket_9Sale = 'FEE_AFTERMARKET_9_SALE',
  FeeAftermarket_9Cost = 'FEE_AFTERMARKET_9_COST',
  FeeAftermarket_9ProfitIndicator = 'FEE_AFTERMARKET_9_PROFIT_INDICATOR',
  FeeAftermarket_10Name = 'FEE_AFTERMARKET_10_NAME',
  FeeAftermarket_10Sale = 'FEE_AFTERMARKET_10_SALE',
  FeeAftermarket_10Cost = 'FEE_AFTERMARKET_10_COST',
  FeeAftermarket_10ProfitIndicator = 'FEE_AFTERMARKET_10_PROFIT_INDICATOR',
  FeeAftermarket_11Name = 'FEE_AFTERMARKET_11_NAME',
  FeeAftermarket_11Sale = 'FEE_AFTERMARKET_11_SALE',
  FeeAftermarket_11Cost = 'FEE_AFTERMARKET_11_COST',
  FeeAftermarket_11ProfitIndicator = 'FEE_AFTERMARKET_11_PROFIT_INDICATOR',
  FeeAftermarket_12Name = 'FEE_AFTERMARKET_12_NAME',
  FeeAftermarket_12Sale = 'FEE_AFTERMARKET_12_SALE',
  FeeAftermarket_12Cost = 'FEE_AFTERMARKET_12_COST',
  FeeAftermarket_12ProfitIndicator = 'FEE_AFTERMARKET_12_PROFIT_INDICATOR',
  FeeAftermarket_13Name = 'FEE_AFTERMARKET_13_NAME',
  FeeAftermarket_13Sale = 'FEE_AFTERMARKET_13_SALE',
  FeeAftermarket_13Cost = 'FEE_AFTERMARKET_13_COST',
  FeeAftermarket_13ProfitIndicator = 'FEE_AFTERMARKET_13_PROFIT_INDICATOR',
  FeeAftermarket_14Name = 'FEE_AFTERMARKET_14_NAME',
  FeeAftermarket_14Sale = 'FEE_AFTERMARKET_14_SALE',
  FeeAftermarket_14Cost = 'FEE_AFTERMARKET_14_COST',
  FeeAftermarket_14ProfitIndicator = 'FEE_AFTERMARKET_14_PROFIT_INDICATOR',
  FeeAftermarket_15Name = 'FEE_AFTERMARKET_15_NAME',
  FeeAftermarket_15Sale = 'FEE_AFTERMARKET_15_SALE',
  FeeAftermarket_15Cost = 'FEE_AFTERMARKET_15_COST',
  FeeAftermarket_15ProfitIndicator = 'FEE_AFTERMARKET_15_PROFIT_INDICATOR',
  FeeAftermarket_16Name = 'FEE_AFTERMARKET_16_NAME',
  FeeAftermarket_16Sale = 'FEE_AFTERMARKET_16_SALE',
  FeeAftermarket_16Cost = 'FEE_AFTERMARKET_16_COST',
  FeeAftermarket_16ProfitIndicator = 'FEE_AFTERMARKET_16_PROFIT_INDICATOR',
  FeeAftermarket_17Name = 'FEE_AFTERMARKET_17_NAME',
  FeeAftermarket_17Sale = 'FEE_AFTERMARKET_17_SALE',
  FeeAftermarket_17Cost = 'FEE_AFTERMARKET_17_COST',
  FeeAftermarket_17ProfitIndicator = 'FEE_AFTERMARKET_17_PROFIT_INDICATOR',
  FeeAftermarket_18Name = 'FEE_AFTERMARKET_18_NAME',
  FeeAftermarket_18Sale = 'FEE_AFTERMARKET_18_SALE',
  FeeAftermarket_18Cost = 'FEE_AFTERMARKET_18_COST',
  FeeAftermarket_18ProfitIndicator = 'FEE_AFTERMARKET_18_PROFIT_INDICATOR',
  FeeAftermarket_19Name = 'FEE_AFTERMARKET_19_NAME',
  FeeAftermarket_19Sale = 'FEE_AFTERMARKET_19_SALE',
  FeeAftermarket_19Cost = 'FEE_AFTERMARKET_19_COST',
  FeeAftermarket_19ProfitIndicator = 'FEE_AFTERMARKET_19_PROFIT_INDICATOR',
  FeeAftermarket_20Name = 'FEE_AFTERMARKET_20_NAME',
  FeeAftermarket_20Sale = 'FEE_AFTERMARKET_20_SALE',
  FeeAftermarket_20Cost = 'FEE_AFTERMARKET_20_COST',
  FeeAftermarket_20ProfitIndicator = 'FEE_AFTERMARKET_20_PROFIT_INDICATOR',
  Insurance_1Type = 'INSURANCE_1_TYPE',
  Insurance_1Name = 'INSURANCE_1_NAME',
  Insurance_1Sale = 'INSURANCE_1_SALE',
  Insurance_1Cost = 'INSURANCE_1_COST',
  Insurance_1Term = 'INSURANCE_1_TERM',
  Insurance_2Type = 'INSURANCE_2_TYPE',
  Insurance_2Name = 'INSURANCE_2_NAME',
  Insurance_2Sale = 'INSURANCE_2_SALE',
  Insurance_2Cost = 'INSURANCE_2_COST',
  Insurance_2Term = 'INSURANCE_2_TERM',
  Insurance_3Type = 'INSURANCE_3_TYPE',
  Insurance_3Name = 'INSURANCE_3_NAME',
  Insurance_3Sale = 'INSURANCE_3_SALE',
  Insurance_3Cost = 'INSURANCE_3_COST',
  Insurance_3Term = 'INSURANCE_3_TERM',
  AccidentalHealthType = 'ACCIDENTAL_HEALTH_TYPE',
  AccidentalHealthName = 'ACCIDENTAL_HEALTH_NAME',
  AccidentalHealthSale = 'ACCIDENTAL_HEALTH_SALE',
  AccidentalHealthCost = 'ACCIDENTAL_HEALTH_COST',
  AccidentalHealthTerm = 'ACCIDENTAL_HEALTH_TERM',
  CreditLifeType = 'CREDIT_LIFE_TYPE',
  CreditLifeName = 'CREDIT_LIFE_NAME',
  CreditLifeSale = 'CREDIT_LIFE_SALE',
  CreditLifeCost = 'CREDIT_LIFE_COST',
  CreditLifeTerm = 'CREDIT_LIFE_TERM',
  LevelizedLifeType = 'LEVELIZED_LIFE_TYPE',
  LevelizedLifeName = 'LEVELIZED_LIFE_NAME',
  LevelizedLifeSale = 'LEVELIZED_LIFE_SALE',
  LevelizedLifeCost = 'LEVELIZED_LIFE_COST',
  LevelizedLifeTerm = 'LEVELIZED_LIFE_TERM',
  LossOfEmploymentType = 'LOSS_OF_EMPLOYMENT_TYPE',
  LossOfEmploymentName = 'LOSS_OF_EMPLOYMENT_NAME',
  LossOfEmploymentSale = 'LOSS_OF_EMPLOYMENT_SALE',
  LossOfEmploymentCost = 'LOSS_OF_EMPLOYMENT_COST',
  LossOfEmploymentTerm = 'LOSS_OF_EMPLOYMENT_TERM',
  GuaranteedAutoProtectionType = 'GUARANTEED_AUTO_PROTECTION_TYPE',
  GuaranteedAutoProtectionName = 'GUARANTEED_AUTO_PROTECTION_NAME',
  GuaranteedAutoProtectionSale = 'GUARANTEED_AUTO_PROTECTION_SALE',
  GuaranteedAutoProtectionCost = 'GUARANTEED_AUTO_PROTECTION_COST',
  GuaranteedAutoProtectionTerm = 'GUARANTEED_AUTO_PROTECTION_TERM',
  SaleComments = 'SALE_COMMENTS',
  CassStdLine1 = 'CASS_STD_LINE1',
  CassStdLine2 = 'CASS_STD_LINE2',
  CassStdCity = 'CASS_STD_CITY',
  CassStdState = 'CASS_STD_STATE',
  CassStdZip = 'CASS_STD_ZIP',
  CassStdZip4 = 'CASS_STD_ZIP4',
  CassStdDpbc = 'CASS_STD_DPBC',
  CassStdChkdgt = 'CASS_STD_CHKDGT',
  CassStdCart = 'CASS_STD_CART',
  CassStdLot = 'CASS_STD_LOT',
  CassStdLotord = 'CASS_STD_LOTORD',
  CassStdUrb = 'CASS_STD_URB',
  CassStdFips = 'CASS_STD_FIPS',
  CassStdEws = 'CASS_STD_EWS',
  CassStdLacs = 'CASS_STD_LACS',
  CassStdZipmov = 'CASS_STD_ZIPMOV',
  CassStdZ4Lom = 'CASS_STD_Z4LOM',
  CassStdNdiapt = 'CASS_STD_NDIAPT',
  CassStdNdirr = 'CASS_STD_NDIRR',
  CassStdLacsrt = 'CASS_STD_LACSRT',
  CassStdErrorCd = 'CASS_STD_ERROR_CD'
}

export type DealsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  birthDate?: Maybe<HavingDatetimeFilter>;
  customerCreateDate?: Maybe<HavingDatetimeFilter>;
  customerLastActivityDate?: Maybe<HavingDatetimeFilter>;
  coBuyerBirthDate?: Maybe<HavingDatetimeFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  deliveryDate?: Maybe<HavingDatetimeFilter>;
  deliveryMileage?: Maybe<HavingIntFilter>;
  inventoryDate?: Maybe<HavingDatetimeFilter>;
  inServiceDate?: Maybe<HavingDatetimeFilter>;
  vinExplosionYear?: Maybe<HavingIntFilter>;
  trade1Year?: Maybe<HavingIntFilter>;
  trade1Odometer?: Maybe<HavingIntFilter>;
  trade1ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade1Gross?: Maybe<HavingBigfloatFilter>;
  trade1Payoff?: Maybe<HavingBigfloatFilter>;
  trade2Year?: Maybe<HavingIntFilter>;
  trade2Odometer?: Maybe<HavingIntFilter>;
  trade2ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade2Gross?: Maybe<HavingBigfloatFilter>;
  trade2Payoff?: Maybe<HavingBigfloatFilter>;
  msrp?: Maybe<HavingBigfloatFilter>;
  listPrice?: Maybe<HavingBigfloatFilter>;
  salesPrice?: Maybe<HavingBigfloatFilter>;
  journalPrice?: Maybe<HavingBigfloatFilter>;
  cost?: Maybe<HavingBigfloatFilter>;
  journalCost?: Maybe<HavingBigfloatFilter>;
  adjustments?: Maybe<HavingBigfloatFilter>;
  adjustedCost?: Maybe<HavingBigfloatFilter>;
  incentives?: Maybe<HavingBigfloatFilter>;
  packAmount?: Maybe<HavingBigfloatFilter>;
  saleNet?: Maybe<HavingBigfloatFilter>;
  totalTradeActualCashValue?: Maybe<HavingBigfloatFilter>;
  totalTradeGross?: Maybe<HavingBigfloatFilter>;
  weOweFront?: Maybe<HavingBigfloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  totalFrontCommission?: Maybe<HavingBigfloatFilter>;
  totalFrontSales?: Maybe<HavingBigfloatFilter>;
  totalFrontCost?: Maybe<HavingBigfloatFilter>;
  frontGross?: Maybe<HavingBigfloatFilter>;
  financeProfit?: Maybe<HavingBigfloatFilter>;
  totalWarrantyProfit?: Maybe<HavingBigfloatFilter>;
  weOweBack?: Maybe<HavingBigfloatFilter>;
  insuranceProfit?: Maybe<HavingBigfloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  financeReserve?: Maybe<HavingBigfloatFilter>;
  totalBackCommission?: Maybe<HavingBigfloatFilter>;
  totalBackSales?: Maybe<HavingBigfloatFilter>;
  totalBackCost?: Maybe<HavingBigfloatFilter>;
  backGross?: Maybe<HavingBigfloatFilter>;
  totalProfit?: Maybe<HavingBigfloatFilter>;
  grossProfit?: Maybe<HavingBigfloatFilter>;
  grossPayable?: Maybe<HavingBigfloatFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  bookedDate?: Maybe<HavingDatetimeFilter>;
  finalizedDate?: Maybe<HavingDatetimeFilter>;
  contractDate?: Maybe<HavingDatetimeFilter>;
  accountingDate?: Maybe<HavingDatetimeFilter>;
  statusChangeDate?: Maybe<HavingDatetimeFilter>;
  firstPayDate?: Maybe<HavingDatetimeFilter>;
  term?: Maybe<HavingIntFilter>;
  amountFinanced?: Maybe<HavingBigfloatFilter>;
  apr?: Maybe<HavingBigfloatFilter>;
  monthlyPayment?: Maybe<HavingBigfloatFilter>;
  paymentTotal?: Maybe<HavingBigfloatFilter>;
  rebates?: Maybe<HavingBigfloatFilter>;
  deposit?: Maybe<HavingBigfloatFilter>;
  downPayment?: Maybe<HavingBigfloatFilter>;
  totalNetTrades?: Maybe<HavingBigfloatFilter>;
  totalDown?: Maybe<HavingBigfloatFilter>;
  balloonAmount?: Maybe<HavingBigfloatFilter>;
  adjustedBalloonAmount?: Maybe<HavingBigfloatFilter>;
  holdbackAmount?: Maybe<HavingBigfloatFilter>;
  totalDriveOffAmount?: Maybe<HavingBigfloatFilter>;
  licenseFee?: Maybe<HavingBigfloatFilter>;
  registrationFee?: Maybe<HavingBigfloatFilter>;
  documentationFee?: Maybe<HavingBigfloatFilter>;
  financeCharge?: Maybe<HavingBigfloatFilter>;
  totalPickupPayments?: Maybe<HavingBigfloatFilter>;
  sellRate?: Maybe<HavingBigfloatFilter>;
  buyRate?: Maybe<HavingBigfloatFilter>;
  residualRate?: Maybe<HavingBigfloatFilter>;
  residualAmount?: Maybe<HavingBigfloatFilter>;
  allowedMiles?: Maybe<HavingIntFilter>;
  estimatedMiles?: Maybe<HavingIntFilter>;
  mileageRate?: Maybe<HavingBigfloatFilter>;
  acquisitionFee?: Maybe<HavingBigfloatFilter>;
  basePayment?: Maybe<HavingBigfloatFilter>;
  securityDeposit?: Maybe<HavingBigfloatFilter>;
  totalCapitalReduction?: Maybe<HavingBigfloatFilter>;
  netCapitalCost?: Maybe<HavingBigfloatFilter>;
  leaseDepreciationValue?: Maybe<HavingBigfloatFilter>;
  dealerFees?: Maybe<HavingBigfloatFilter>;
  governmentFees?: Maybe<HavingBigfloatFilter>;
  totalTax?: Maybe<HavingBigfloatFilter>;
  salesman1TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman1FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman1BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman2TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman2FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman2BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman3TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman3FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman3BackCommission?: Maybe<HavingBigfloatFilter>;
  warranty1Sale?: Maybe<HavingBigfloatFilter>;
  warranty1Cost?: Maybe<HavingBigfloatFilter>;
  warranty1Miles?: Maybe<HavingIntFilter>;
  warranty1Term?: Maybe<HavingIntFilter>;
  warranty2Sale?: Maybe<HavingBigfloatFilter>;
  warranty2Cost?: Maybe<HavingBigfloatFilter>;
  warranty2Miles?: Maybe<HavingIntFilter>;
  warranty2Term?: Maybe<HavingIntFilter>;
  warranty3Sale?: Maybe<HavingBigfloatFilter>;
  warranty3Cost?: Maybe<HavingBigfloatFilter>;
  warranty3Miles?: Maybe<HavingIntFilter>;
  warranty3Term?: Maybe<HavingIntFilter>;
  warranty4Sale?: Maybe<HavingBigfloatFilter>;
  warranty4Cost?: Maybe<HavingBigfloatFilter>;
  warranty4Miles?: Maybe<HavingIntFilter>;
  warranty4Term?: Maybe<HavingIntFilter>;
  warranty5Sale?: Maybe<HavingBigfloatFilter>;
  warranty5Cost?: Maybe<HavingBigfloatFilter>;
  warranty5Miles?: Maybe<HavingIntFilter>;
  warranty5Term?: Maybe<HavingIntFilter>;
  totalFeeAftermarketSale?: Maybe<HavingBigfloatFilter>;
  totalFeeAftermarketCost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Sale?: Maybe<HavingBigfloatFilter>;
  insurance1Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Term?: Maybe<HavingIntFilter>;
  insurance2Sale?: Maybe<HavingBigfloatFilter>;
  insurance2Cost?: Maybe<HavingBigfloatFilter>;
  insurance2Term?: Maybe<HavingIntFilter>;
  insurance3Sale?: Maybe<HavingBigfloatFilter>;
  insurance3Cost?: Maybe<HavingBigfloatFilter>;
  insurance3Term?: Maybe<HavingIntFilter>;
  accidentalHealthSale?: Maybe<HavingBigfloatFilter>;
  accidentalHealthCost?: Maybe<HavingBigfloatFilter>;
  accidentalHealthTerm?: Maybe<HavingIntFilter>;
  creditLifeSale?: Maybe<HavingBigfloatFilter>;
  creditLifeCost?: Maybe<HavingBigfloatFilter>;
  creditLifeTerm?: Maybe<HavingIntFilter>;
  levelizedLifeSale?: Maybe<HavingBigfloatFilter>;
  levelizedLifeCost?: Maybe<HavingBigfloatFilter>;
  levelizedLifeTerm?: Maybe<HavingIntFilter>;
  lossOfEmploymentSale?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentCost?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentTerm?: Maybe<HavingIntFilter>;
  guaranteedAutoProtectionSale?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<HavingIntFilter>;
};

export type DealsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  birthDate?: Maybe<HavingDatetimeFilter>;
  customerCreateDate?: Maybe<HavingDatetimeFilter>;
  customerLastActivityDate?: Maybe<HavingDatetimeFilter>;
  coBuyerBirthDate?: Maybe<HavingDatetimeFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  deliveryDate?: Maybe<HavingDatetimeFilter>;
  deliveryMileage?: Maybe<HavingIntFilter>;
  inventoryDate?: Maybe<HavingDatetimeFilter>;
  inServiceDate?: Maybe<HavingDatetimeFilter>;
  vinExplosionYear?: Maybe<HavingIntFilter>;
  trade1Year?: Maybe<HavingIntFilter>;
  trade1Odometer?: Maybe<HavingIntFilter>;
  trade1ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade1Gross?: Maybe<HavingBigfloatFilter>;
  trade1Payoff?: Maybe<HavingBigfloatFilter>;
  trade2Year?: Maybe<HavingIntFilter>;
  trade2Odometer?: Maybe<HavingIntFilter>;
  trade2ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade2Gross?: Maybe<HavingBigfloatFilter>;
  trade2Payoff?: Maybe<HavingBigfloatFilter>;
  msrp?: Maybe<HavingBigfloatFilter>;
  listPrice?: Maybe<HavingBigfloatFilter>;
  salesPrice?: Maybe<HavingBigfloatFilter>;
  journalPrice?: Maybe<HavingBigfloatFilter>;
  cost?: Maybe<HavingBigfloatFilter>;
  journalCost?: Maybe<HavingBigfloatFilter>;
  adjustments?: Maybe<HavingBigfloatFilter>;
  adjustedCost?: Maybe<HavingBigfloatFilter>;
  incentives?: Maybe<HavingBigfloatFilter>;
  packAmount?: Maybe<HavingBigfloatFilter>;
  saleNet?: Maybe<HavingBigfloatFilter>;
  totalTradeActualCashValue?: Maybe<HavingBigfloatFilter>;
  totalTradeGross?: Maybe<HavingBigfloatFilter>;
  weOweFront?: Maybe<HavingBigfloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  totalFrontCommission?: Maybe<HavingBigfloatFilter>;
  totalFrontSales?: Maybe<HavingBigfloatFilter>;
  totalFrontCost?: Maybe<HavingBigfloatFilter>;
  frontGross?: Maybe<HavingBigfloatFilter>;
  financeProfit?: Maybe<HavingBigfloatFilter>;
  totalWarrantyProfit?: Maybe<HavingBigfloatFilter>;
  weOweBack?: Maybe<HavingBigfloatFilter>;
  insuranceProfit?: Maybe<HavingBigfloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  financeReserve?: Maybe<HavingBigfloatFilter>;
  totalBackCommission?: Maybe<HavingBigfloatFilter>;
  totalBackSales?: Maybe<HavingBigfloatFilter>;
  totalBackCost?: Maybe<HavingBigfloatFilter>;
  backGross?: Maybe<HavingBigfloatFilter>;
  totalProfit?: Maybe<HavingBigfloatFilter>;
  grossProfit?: Maybe<HavingBigfloatFilter>;
  grossPayable?: Maybe<HavingBigfloatFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  bookedDate?: Maybe<HavingDatetimeFilter>;
  finalizedDate?: Maybe<HavingDatetimeFilter>;
  contractDate?: Maybe<HavingDatetimeFilter>;
  accountingDate?: Maybe<HavingDatetimeFilter>;
  statusChangeDate?: Maybe<HavingDatetimeFilter>;
  firstPayDate?: Maybe<HavingDatetimeFilter>;
  term?: Maybe<HavingIntFilter>;
  amountFinanced?: Maybe<HavingBigfloatFilter>;
  apr?: Maybe<HavingBigfloatFilter>;
  monthlyPayment?: Maybe<HavingBigfloatFilter>;
  paymentTotal?: Maybe<HavingBigfloatFilter>;
  rebates?: Maybe<HavingBigfloatFilter>;
  deposit?: Maybe<HavingBigfloatFilter>;
  downPayment?: Maybe<HavingBigfloatFilter>;
  totalNetTrades?: Maybe<HavingBigfloatFilter>;
  totalDown?: Maybe<HavingBigfloatFilter>;
  balloonAmount?: Maybe<HavingBigfloatFilter>;
  adjustedBalloonAmount?: Maybe<HavingBigfloatFilter>;
  holdbackAmount?: Maybe<HavingBigfloatFilter>;
  totalDriveOffAmount?: Maybe<HavingBigfloatFilter>;
  licenseFee?: Maybe<HavingBigfloatFilter>;
  registrationFee?: Maybe<HavingBigfloatFilter>;
  documentationFee?: Maybe<HavingBigfloatFilter>;
  financeCharge?: Maybe<HavingBigfloatFilter>;
  totalPickupPayments?: Maybe<HavingBigfloatFilter>;
  sellRate?: Maybe<HavingBigfloatFilter>;
  buyRate?: Maybe<HavingBigfloatFilter>;
  residualRate?: Maybe<HavingBigfloatFilter>;
  residualAmount?: Maybe<HavingBigfloatFilter>;
  allowedMiles?: Maybe<HavingIntFilter>;
  estimatedMiles?: Maybe<HavingIntFilter>;
  mileageRate?: Maybe<HavingBigfloatFilter>;
  acquisitionFee?: Maybe<HavingBigfloatFilter>;
  basePayment?: Maybe<HavingBigfloatFilter>;
  securityDeposit?: Maybe<HavingBigfloatFilter>;
  totalCapitalReduction?: Maybe<HavingBigfloatFilter>;
  netCapitalCost?: Maybe<HavingBigfloatFilter>;
  leaseDepreciationValue?: Maybe<HavingBigfloatFilter>;
  dealerFees?: Maybe<HavingBigfloatFilter>;
  governmentFees?: Maybe<HavingBigfloatFilter>;
  totalTax?: Maybe<HavingBigfloatFilter>;
  salesman1TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman1FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman1BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman2TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman2FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman2BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman3TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman3FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman3BackCommission?: Maybe<HavingBigfloatFilter>;
  warranty1Sale?: Maybe<HavingBigfloatFilter>;
  warranty1Cost?: Maybe<HavingBigfloatFilter>;
  warranty1Miles?: Maybe<HavingIntFilter>;
  warranty1Term?: Maybe<HavingIntFilter>;
  warranty2Sale?: Maybe<HavingBigfloatFilter>;
  warranty2Cost?: Maybe<HavingBigfloatFilter>;
  warranty2Miles?: Maybe<HavingIntFilter>;
  warranty2Term?: Maybe<HavingIntFilter>;
  warranty3Sale?: Maybe<HavingBigfloatFilter>;
  warranty3Cost?: Maybe<HavingBigfloatFilter>;
  warranty3Miles?: Maybe<HavingIntFilter>;
  warranty3Term?: Maybe<HavingIntFilter>;
  warranty4Sale?: Maybe<HavingBigfloatFilter>;
  warranty4Cost?: Maybe<HavingBigfloatFilter>;
  warranty4Miles?: Maybe<HavingIntFilter>;
  warranty4Term?: Maybe<HavingIntFilter>;
  warranty5Sale?: Maybe<HavingBigfloatFilter>;
  warranty5Cost?: Maybe<HavingBigfloatFilter>;
  warranty5Miles?: Maybe<HavingIntFilter>;
  warranty5Term?: Maybe<HavingIntFilter>;
  totalFeeAftermarketSale?: Maybe<HavingBigfloatFilter>;
  totalFeeAftermarketCost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Sale?: Maybe<HavingBigfloatFilter>;
  insurance1Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Term?: Maybe<HavingIntFilter>;
  insurance2Sale?: Maybe<HavingBigfloatFilter>;
  insurance2Cost?: Maybe<HavingBigfloatFilter>;
  insurance2Term?: Maybe<HavingIntFilter>;
  insurance3Sale?: Maybe<HavingBigfloatFilter>;
  insurance3Cost?: Maybe<HavingBigfloatFilter>;
  insurance3Term?: Maybe<HavingIntFilter>;
  accidentalHealthSale?: Maybe<HavingBigfloatFilter>;
  accidentalHealthCost?: Maybe<HavingBigfloatFilter>;
  accidentalHealthTerm?: Maybe<HavingIntFilter>;
  creditLifeSale?: Maybe<HavingBigfloatFilter>;
  creditLifeCost?: Maybe<HavingBigfloatFilter>;
  creditLifeTerm?: Maybe<HavingIntFilter>;
  levelizedLifeSale?: Maybe<HavingBigfloatFilter>;
  levelizedLifeCost?: Maybe<HavingBigfloatFilter>;
  levelizedLifeTerm?: Maybe<HavingIntFilter>;
  lossOfEmploymentSale?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentCost?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentTerm?: Maybe<HavingIntFilter>;
  guaranteedAutoProtectionSale?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<HavingIntFilter>;
};

/** Conditions for `Deal` aggregates. */
export type DealsHavingInput = {
  AND?: Maybe<Array<DealsHavingInput>>;
  OR?: Maybe<Array<DealsHavingInput>>;
  sum?: Maybe<DealsHavingSumInput>;
  distinctCount?: Maybe<DealsHavingDistinctCountInput>;
  min?: Maybe<DealsHavingMinInput>;
  max?: Maybe<DealsHavingMaxInput>;
  average?: Maybe<DealsHavingAverageInput>;
  stddevSample?: Maybe<DealsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<DealsHavingStddevPopulationInput>;
  varianceSample?: Maybe<DealsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<DealsHavingVariancePopulationInput>;
};

export type DealsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  birthDate?: Maybe<HavingDatetimeFilter>;
  customerCreateDate?: Maybe<HavingDatetimeFilter>;
  customerLastActivityDate?: Maybe<HavingDatetimeFilter>;
  coBuyerBirthDate?: Maybe<HavingDatetimeFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  deliveryDate?: Maybe<HavingDatetimeFilter>;
  deliveryMileage?: Maybe<HavingIntFilter>;
  inventoryDate?: Maybe<HavingDatetimeFilter>;
  inServiceDate?: Maybe<HavingDatetimeFilter>;
  vinExplosionYear?: Maybe<HavingIntFilter>;
  trade1Year?: Maybe<HavingIntFilter>;
  trade1Odometer?: Maybe<HavingIntFilter>;
  trade1ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade1Gross?: Maybe<HavingBigfloatFilter>;
  trade1Payoff?: Maybe<HavingBigfloatFilter>;
  trade2Year?: Maybe<HavingIntFilter>;
  trade2Odometer?: Maybe<HavingIntFilter>;
  trade2ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade2Gross?: Maybe<HavingBigfloatFilter>;
  trade2Payoff?: Maybe<HavingBigfloatFilter>;
  msrp?: Maybe<HavingBigfloatFilter>;
  listPrice?: Maybe<HavingBigfloatFilter>;
  salesPrice?: Maybe<HavingBigfloatFilter>;
  journalPrice?: Maybe<HavingBigfloatFilter>;
  cost?: Maybe<HavingBigfloatFilter>;
  journalCost?: Maybe<HavingBigfloatFilter>;
  adjustments?: Maybe<HavingBigfloatFilter>;
  adjustedCost?: Maybe<HavingBigfloatFilter>;
  incentives?: Maybe<HavingBigfloatFilter>;
  packAmount?: Maybe<HavingBigfloatFilter>;
  saleNet?: Maybe<HavingBigfloatFilter>;
  totalTradeActualCashValue?: Maybe<HavingBigfloatFilter>;
  totalTradeGross?: Maybe<HavingBigfloatFilter>;
  weOweFront?: Maybe<HavingBigfloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  totalFrontCommission?: Maybe<HavingBigfloatFilter>;
  totalFrontSales?: Maybe<HavingBigfloatFilter>;
  totalFrontCost?: Maybe<HavingBigfloatFilter>;
  frontGross?: Maybe<HavingBigfloatFilter>;
  financeProfit?: Maybe<HavingBigfloatFilter>;
  totalWarrantyProfit?: Maybe<HavingBigfloatFilter>;
  weOweBack?: Maybe<HavingBigfloatFilter>;
  insuranceProfit?: Maybe<HavingBigfloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  financeReserve?: Maybe<HavingBigfloatFilter>;
  totalBackCommission?: Maybe<HavingBigfloatFilter>;
  totalBackSales?: Maybe<HavingBigfloatFilter>;
  totalBackCost?: Maybe<HavingBigfloatFilter>;
  backGross?: Maybe<HavingBigfloatFilter>;
  totalProfit?: Maybe<HavingBigfloatFilter>;
  grossProfit?: Maybe<HavingBigfloatFilter>;
  grossPayable?: Maybe<HavingBigfloatFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  bookedDate?: Maybe<HavingDatetimeFilter>;
  finalizedDate?: Maybe<HavingDatetimeFilter>;
  contractDate?: Maybe<HavingDatetimeFilter>;
  accountingDate?: Maybe<HavingDatetimeFilter>;
  statusChangeDate?: Maybe<HavingDatetimeFilter>;
  firstPayDate?: Maybe<HavingDatetimeFilter>;
  term?: Maybe<HavingIntFilter>;
  amountFinanced?: Maybe<HavingBigfloatFilter>;
  apr?: Maybe<HavingBigfloatFilter>;
  monthlyPayment?: Maybe<HavingBigfloatFilter>;
  paymentTotal?: Maybe<HavingBigfloatFilter>;
  rebates?: Maybe<HavingBigfloatFilter>;
  deposit?: Maybe<HavingBigfloatFilter>;
  downPayment?: Maybe<HavingBigfloatFilter>;
  totalNetTrades?: Maybe<HavingBigfloatFilter>;
  totalDown?: Maybe<HavingBigfloatFilter>;
  balloonAmount?: Maybe<HavingBigfloatFilter>;
  adjustedBalloonAmount?: Maybe<HavingBigfloatFilter>;
  holdbackAmount?: Maybe<HavingBigfloatFilter>;
  totalDriveOffAmount?: Maybe<HavingBigfloatFilter>;
  licenseFee?: Maybe<HavingBigfloatFilter>;
  registrationFee?: Maybe<HavingBigfloatFilter>;
  documentationFee?: Maybe<HavingBigfloatFilter>;
  financeCharge?: Maybe<HavingBigfloatFilter>;
  totalPickupPayments?: Maybe<HavingBigfloatFilter>;
  sellRate?: Maybe<HavingBigfloatFilter>;
  buyRate?: Maybe<HavingBigfloatFilter>;
  residualRate?: Maybe<HavingBigfloatFilter>;
  residualAmount?: Maybe<HavingBigfloatFilter>;
  allowedMiles?: Maybe<HavingIntFilter>;
  estimatedMiles?: Maybe<HavingIntFilter>;
  mileageRate?: Maybe<HavingBigfloatFilter>;
  acquisitionFee?: Maybe<HavingBigfloatFilter>;
  basePayment?: Maybe<HavingBigfloatFilter>;
  securityDeposit?: Maybe<HavingBigfloatFilter>;
  totalCapitalReduction?: Maybe<HavingBigfloatFilter>;
  netCapitalCost?: Maybe<HavingBigfloatFilter>;
  leaseDepreciationValue?: Maybe<HavingBigfloatFilter>;
  dealerFees?: Maybe<HavingBigfloatFilter>;
  governmentFees?: Maybe<HavingBigfloatFilter>;
  totalTax?: Maybe<HavingBigfloatFilter>;
  salesman1TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman1FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman1BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman2TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman2FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman2BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman3TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman3FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman3BackCommission?: Maybe<HavingBigfloatFilter>;
  warranty1Sale?: Maybe<HavingBigfloatFilter>;
  warranty1Cost?: Maybe<HavingBigfloatFilter>;
  warranty1Miles?: Maybe<HavingIntFilter>;
  warranty1Term?: Maybe<HavingIntFilter>;
  warranty2Sale?: Maybe<HavingBigfloatFilter>;
  warranty2Cost?: Maybe<HavingBigfloatFilter>;
  warranty2Miles?: Maybe<HavingIntFilter>;
  warranty2Term?: Maybe<HavingIntFilter>;
  warranty3Sale?: Maybe<HavingBigfloatFilter>;
  warranty3Cost?: Maybe<HavingBigfloatFilter>;
  warranty3Miles?: Maybe<HavingIntFilter>;
  warranty3Term?: Maybe<HavingIntFilter>;
  warranty4Sale?: Maybe<HavingBigfloatFilter>;
  warranty4Cost?: Maybe<HavingBigfloatFilter>;
  warranty4Miles?: Maybe<HavingIntFilter>;
  warranty4Term?: Maybe<HavingIntFilter>;
  warranty5Sale?: Maybe<HavingBigfloatFilter>;
  warranty5Cost?: Maybe<HavingBigfloatFilter>;
  warranty5Miles?: Maybe<HavingIntFilter>;
  warranty5Term?: Maybe<HavingIntFilter>;
  totalFeeAftermarketSale?: Maybe<HavingBigfloatFilter>;
  totalFeeAftermarketCost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Sale?: Maybe<HavingBigfloatFilter>;
  insurance1Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Term?: Maybe<HavingIntFilter>;
  insurance2Sale?: Maybe<HavingBigfloatFilter>;
  insurance2Cost?: Maybe<HavingBigfloatFilter>;
  insurance2Term?: Maybe<HavingIntFilter>;
  insurance3Sale?: Maybe<HavingBigfloatFilter>;
  insurance3Cost?: Maybe<HavingBigfloatFilter>;
  insurance3Term?: Maybe<HavingIntFilter>;
  accidentalHealthSale?: Maybe<HavingBigfloatFilter>;
  accidentalHealthCost?: Maybe<HavingBigfloatFilter>;
  accidentalHealthTerm?: Maybe<HavingIntFilter>;
  creditLifeSale?: Maybe<HavingBigfloatFilter>;
  creditLifeCost?: Maybe<HavingBigfloatFilter>;
  creditLifeTerm?: Maybe<HavingIntFilter>;
  levelizedLifeSale?: Maybe<HavingBigfloatFilter>;
  levelizedLifeCost?: Maybe<HavingBigfloatFilter>;
  levelizedLifeTerm?: Maybe<HavingIntFilter>;
  lossOfEmploymentSale?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentCost?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentTerm?: Maybe<HavingIntFilter>;
  guaranteedAutoProtectionSale?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<HavingIntFilter>;
};

export type DealsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  birthDate?: Maybe<HavingDatetimeFilter>;
  customerCreateDate?: Maybe<HavingDatetimeFilter>;
  customerLastActivityDate?: Maybe<HavingDatetimeFilter>;
  coBuyerBirthDate?: Maybe<HavingDatetimeFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  deliveryDate?: Maybe<HavingDatetimeFilter>;
  deliveryMileage?: Maybe<HavingIntFilter>;
  inventoryDate?: Maybe<HavingDatetimeFilter>;
  inServiceDate?: Maybe<HavingDatetimeFilter>;
  vinExplosionYear?: Maybe<HavingIntFilter>;
  trade1Year?: Maybe<HavingIntFilter>;
  trade1Odometer?: Maybe<HavingIntFilter>;
  trade1ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade1Gross?: Maybe<HavingBigfloatFilter>;
  trade1Payoff?: Maybe<HavingBigfloatFilter>;
  trade2Year?: Maybe<HavingIntFilter>;
  trade2Odometer?: Maybe<HavingIntFilter>;
  trade2ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade2Gross?: Maybe<HavingBigfloatFilter>;
  trade2Payoff?: Maybe<HavingBigfloatFilter>;
  msrp?: Maybe<HavingBigfloatFilter>;
  listPrice?: Maybe<HavingBigfloatFilter>;
  salesPrice?: Maybe<HavingBigfloatFilter>;
  journalPrice?: Maybe<HavingBigfloatFilter>;
  cost?: Maybe<HavingBigfloatFilter>;
  journalCost?: Maybe<HavingBigfloatFilter>;
  adjustments?: Maybe<HavingBigfloatFilter>;
  adjustedCost?: Maybe<HavingBigfloatFilter>;
  incentives?: Maybe<HavingBigfloatFilter>;
  packAmount?: Maybe<HavingBigfloatFilter>;
  saleNet?: Maybe<HavingBigfloatFilter>;
  totalTradeActualCashValue?: Maybe<HavingBigfloatFilter>;
  totalTradeGross?: Maybe<HavingBigfloatFilter>;
  weOweFront?: Maybe<HavingBigfloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  totalFrontCommission?: Maybe<HavingBigfloatFilter>;
  totalFrontSales?: Maybe<HavingBigfloatFilter>;
  totalFrontCost?: Maybe<HavingBigfloatFilter>;
  frontGross?: Maybe<HavingBigfloatFilter>;
  financeProfit?: Maybe<HavingBigfloatFilter>;
  totalWarrantyProfit?: Maybe<HavingBigfloatFilter>;
  weOweBack?: Maybe<HavingBigfloatFilter>;
  insuranceProfit?: Maybe<HavingBigfloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  financeReserve?: Maybe<HavingBigfloatFilter>;
  totalBackCommission?: Maybe<HavingBigfloatFilter>;
  totalBackSales?: Maybe<HavingBigfloatFilter>;
  totalBackCost?: Maybe<HavingBigfloatFilter>;
  backGross?: Maybe<HavingBigfloatFilter>;
  totalProfit?: Maybe<HavingBigfloatFilter>;
  grossProfit?: Maybe<HavingBigfloatFilter>;
  grossPayable?: Maybe<HavingBigfloatFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  bookedDate?: Maybe<HavingDatetimeFilter>;
  finalizedDate?: Maybe<HavingDatetimeFilter>;
  contractDate?: Maybe<HavingDatetimeFilter>;
  accountingDate?: Maybe<HavingDatetimeFilter>;
  statusChangeDate?: Maybe<HavingDatetimeFilter>;
  firstPayDate?: Maybe<HavingDatetimeFilter>;
  term?: Maybe<HavingIntFilter>;
  amountFinanced?: Maybe<HavingBigfloatFilter>;
  apr?: Maybe<HavingBigfloatFilter>;
  monthlyPayment?: Maybe<HavingBigfloatFilter>;
  paymentTotal?: Maybe<HavingBigfloatFilter>;
  rebates?: Maybe<HavingBigfloatFilter>;
  deposit?: Maybe<HavingBigfloatFilter>;
  downPayment?: Maybe<HavingBigfloatFilter>;
  totalNetTrades?: Maybe<HavingBigfloatFilter>;
  totalDown?: Maybe<HavingBigfloatFilter>;
  balloonAmount?: Maybe<HavingBigfloatFilter>;
  adjustedBalloonAmount?: Maybe<HavingBigfloatFilter>;
  holdbackAmount?: Maybe<HavingBigfloatFilter>;
  totalDriveOffAmount?: Maybe<HavingBigfloatFilter>;
  licenseFee?: Maybe<HavingBigfloatFilter>;
  registrationFee?: Maybe<HavingBigfloatFilter>;
  documentationFee?: Maybe<HavingBigfloatFilter>;
  financeCharge?: Maybe<HavingBigfloatFilter>;
  totalPickupPayments?: Maybe<HavingBigfloatFilter>;
  sellRate?: Maybe<HavingBigfloatFilter>;
  buyRate?: Maybe<HavingBigfloatFilter>;
  residualRate?: Maybe<HavingBigfloatFilter>;
  residualAmount?: Maybe<HavingBigfloatFilter>;
  allowedMiles?: Maybe<HavingIntFilter>;
  estimatedMiles?: Maybe<HavingIntFilter>;
  mileageRate?: Maybe<HavingBigfloatFilter>;
  acquisitionFee?: Maybe<HavingBigfloatFilter>;
  basePayment?: Maybe<HavingBigfloatFilter>;
  securityDeposit?: Maybe<HavingBigfloatFilter>;
  totalCapitalReduction?: Maybe<HavingBigfloatFilter>;
  netCapitalCost?: Maybe<HavingBigfloatFilter>;
  leaseDepreciationValue?: Maybe<HavingBigfloatFilter>;
  dealerFees?: Maybe<HavingBigfloatFilter>;
  governmentFees?: Maybe<HavingBigfloatFilter>;
  totalTax?: Maybe<HavingBigfloatFilter>;
  salesman1TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman1FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman1BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman2TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman2FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman2BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman3TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman3FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman3BackCommission?: Maybe<HavingBigfloatFilter>;
  warranty1Sale?: Maybe<HavingBigfloatFilter>;
  warranty1Cost?: Maybe<HavingBigfloatFilter>;
  warranty1Miles?: Maybe<HavingIntFilter>;
  warranty1Term?: Maybe<HavingIntFilter>;
  warranty2Sale?: Maybe<HavingBigfloatFilter>;
  warranty2Cost?: Maybe<HavingBigfloatFilter>;
  warranty2Miles?: Maybe<HavingIntFilter>;
  warranty2Term?: Maybe<HavingIntFilter>;
  warranty3Sale?: Maybe<HavingBigfloatFilter>;
  warranty3Cost?: Maybe<HavingBigfloatFilter>;
  warranty3Miles?: Maybe<HavingIntFilter>;
  warranty3Term?: Maybe<HavingIntFilter>;
  warranty4Sale?: Maybe<HavingBigfloatFilter>;
  warranty4Cost?: Maybe<HavingBigfloatFilter>;
  warranty4Miles?: Maybe<HavingIntFilter>;
  warranty4Term?: Maybe<HavingIntFilter>;
  warranty5Sale?: Maybe<HavingBigfloatFilter>;
  warranty5Cost?: Maybe<HavingBigfloatFilter>;
  warranty5Miles?: Maybe<HavingIntFilter>;
  warranty5Term?: Maybe<HavingIntFilter>;
  totalFeeAftermarketSale?: Maybe<HavingBigfloatFilter>;
  totalFeeAftermarketCost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Sale?: Maybe<HavingBigfloatFilter>;
  insurance1Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Term?: Maybe<HavingIntFilter>;
  insurance2Sale?: Maybe<HavingBigfloatFilter>;
  insurance2Cost?: Maybe<HavingBigfloatFilter>;
  insurance2Term?: Maybe<HavingIntFilter>;
  insurance3Sale?: Maybe<HavingBigfloatFilter>;
  insurance3Cost?: Maybe<HavingBigfloatFilter>;
  insurance3Term?: Maybe<HavingIntFilter>;
  accidentalHealthSale?: Maybe<HavingBigfloatFilter>;
  accidentalHealthCost?: Maybe<HavingBigfloatFilter>;
  accidentalHealthTerm?: Maybe<HavingIntFilter>;
  creditLifeSale?: Maybe<HavingBigfloatFilter>;
  creditLifeCost?: Maybe<HavingBigfloatFilter>;
  creditLifeTerm?: Maybe<HavingIntFilter>;
  levelizedLifeSale?: Maybe<HavingBigfloatFilter>;
  levelizedLifeCost?: Maybe<HavingBigfloatFilter>;
  levelizedLifeTerm?: Maybe<HavingIntFilter>;
  lossOfEmploymentSale?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentCost?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentTerm?: Maybe<HavingIntFilter>;
  guaranteedAutoProtectionSale?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<HavingIntFilter>;
};

export type DealsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  birthDate?: Maybe<HavingDatetimeFilter>;
  customerCreateDate?: Maybe<HavingDatetimeFilter>;
  customerLastActivityDate?: Maybe<HavingDatetimeFilter>;
  coBuyerBirthDate?: Maybe<HavingDatetimeFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  deliveryDate?: Maybe<HavingDatetimeFilter>;
  deliveryMileage?: Maybe<HavingIntFilter>;
  inventoryDate?: Maybe<HavingDatetimeFilter>;
  inServiceDate?: Maybe<HavingDatetimeFilter>;
  vinExplosionYear?: Maybe<HavingIntFilter>;
  trade1Year?: Maybe<HavingIntFilter>;
  trade1Odometer?: Maybe<HavingIntFilter>;
  trade1ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade1Gross?: Maybe<HavingBigfloatFilter>;
  trade1Payoff?: Maybe<HavingBigfloatFilter>;
  trade2Year?: Maybe<HavingIntFilter>;
  trade2Odometer?: Maybe<HavingIntFilter>;
  trade2ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade2Gross?: Maybe<HavingBigfloatFilter>;
  trade2Payoff?: Maybe<HavingBigfloatFilter>;
  msrp?: Maybe<HavingBigfloatFilter>;
  listPrice?: Maybe<HavingBigfloatFilter>;
  salesPrice?: Maybe<HavingBigfloatFilter>;
  journalPrice?: Maybe<HavingBigfloatFilter>;
  cost?: Maybe<HavingBigfloatFilter>;
  journalCost?: Maybe<HavingBigfloatFilter>;
  adjustments?: Maybe<HavingBigfloatFilter>;
  adjustedCost?: Maybe<HavingBigfloatFilter>;
  incentives?: Maybe<HavingBigfloatFilter>;
  packAmount?: Maybe<HavingBigfloatFilter>;
  saleNet?: Maybe<HavingBigfloatFilter>;
  totalTradeActualCashValue?: Maybe<HavingBigfloatFilter>;
  totalTradeGross?: Maybe<HavingBigfloatFilter>;
  weOweFront?: Maybe<HavingBigfloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  totalFrontCommission?: Maybe<HavingBigfloatFilter>;
  totalFrontSales?: Maybe<HavingBigfloatFilter>;
  totalFrontCost?: Maybe<HavingBigfloatFilter>;
  frontGross?: Maybe<HavingBigfloatFilter>;
  financeProfit?: Maybe<HavingBigfloatFilter>;
  totalWarrantyProfit?: Maybe<HavingBigfloatFilter>;
  weOweBack?: Maybe<HavingBigfloatFilter>;
  insuranceProfit?: Maybe<HavingBigfloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  financeReserve?: Maybe<HavingBigfloatFilter>;
  totalBackCommission?: Maybe<HavingBigfloatFilter>;
  totalBackSales?: Maybe<HavingBigfloatFilter>;
  totalBackCost?: Maybe<HavingBigfloatFilter>;
  backGross?: Maybe<HavingBigfloatFilter>;
  totalProfit?: Maybe<HavingBigfloatFilter>;
  grossProfit?: Maybe<HavingBigfloatFilter>;
  grossPayable?: Maybe<HavingBigfloatFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  bookedDate?: Maybe<HavingDatetimeFilter>;
  finalizedDate?: Maybe<HavingDatetimeFilter>;
  contractDate?: Maybe<HavingDatetimeFilter>;
  accountingDate?: Maybe<HavingDatetimeFilter>;
  statusChangeDate?: Maybe<HavingDatetimeFilter>;
  firstPayDate?: Maybe<HavingDatetimeFilter>;
  term?: Maybe<HavingIntFilter>;
  amountFinanced?: Maybe<HavingBigfloatFilter>;
  apr?: Maybe<HavingBigfloatFilter>;
  monthlyPayment?: Maybe<HavingBigfloatFilter>;
  paymentTotal?: Maybe<HavingBigfloatFilter>;
  rebates?: Maybe<HavingBigfloatFilter>;
  deposit?: Maybe<HavingBigfloatFilter>;
  downPayment?: Maybe<HavingBigfloatFilter>;
  totalNetTrades?: Maybe<HavingBigfloatFilter>;
  totalDown?: Maybe<HavingBigfloatFilter>;
  balloonAmount?: Maybe<HavingBigfloatFilter>;
  adjustedBalloonAmount?: Maybe<HavingBigfloatFilter>;
  holdbackAmount?: Maybe<HavingBigfloatFilter>;
  totalDriveOffAmount?: Maybe<HavingBigfloatFilter>;
  licenseFee?: Maybe<HavingBigfloatFilter>;
  registrationFee?: Maybe<HavingBigfloatFilter>;
  documentationFee?: Maybe<HavingBigfloatFilter>;
  financeCharge?: Maybe<HavingBigfloatFilter>;
  totalPickupPayments?: Maybe<HavingBigfloatFilter>;
  sellRate?: Maybe<HavingBigfloatFilter>;
  buyRate?: Maybe<HavingBigfloatFilter>;
  residualRate?: Maybe<HavingBigfloatFilter>;
  residualAmount?: Maybe<HavingBigfloatFilter>;
  allowedMiles?: Maybe<HavingIntFilter>;
  estimatedMiles?: Maybe<HavingIntFilter>;
  mileageRate?: Maybe<HavingBigfloatFilter>;
  acquisitionFee?: Maybe<HavingBigfloatFilter>;
  basePayment?: Maybe<HavingBigfloatFilter>;
  securityDeposit?: Maybe<HavingBigfloatFilter>;
  totalCapitalReduction?: Maybe<HavingBigfloatFilter>;
  netCapitalCost?: Maybe<HavingBigfloatFilter>;
  leaseDepreciationValue?: Maybe<HavingBigfloatFilter>;
  dealerFees?: Maybe<HavingBigfloatFilter>;
  governmentFees?: Maybe<HavingBigfloatFilter>;
  totalTax?: Maybe<HavingBigfloatFilter>;
  salesman1TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman1FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman1BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman2TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman2FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman2BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman3TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman3FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman3BackCommission?: Maybe<HavingBigfloatFilter>;
  warranty1Sale?: Maybe<HavingBigfloatFilter>;
  warranty1Cost?: Maybe<HavingBigfloatFilter>;
  warranty1Miles?: Maybe<HavingIntFilter>;
  warranty1Term?: Maybe<HavingIntFilter>;
  warranty2Sale?: Maybe<HavingBigfloatFilter>;
  warranty2Cost?: Maybe<HavingBigfloatFilter>;
  warranty2Miles?: Maybe<HavingIntFilter>;
  warranty2Term?: Maybe<HavingIntFilter>;
  warranty3Sale?: Maybe<HavingBigfloatFilter>;
  warranty3Cost?: Maybe<HavingBigfloatFilter>;
  warranty3Miles?: Maybe<HavingIntFilter>;
  warranty3Term?: Maybe<HavingIntFilter>;
  warranty4Sale?: Maybe<HavingBigfloatFilter>;
  warranty4Cost?: Maybe<HavingBigfloatFilter>;
  warranty4Miles?: Maybe<HavingIntFilter>;
  warranty4Term?: Maybe<HavingIntFilter>;
  warranty5Sale?: Maybe<HavingBigfloatFilter>;
  warranty5Cost?: Maybe<HavingBigfloatFilter>;
  warranty5Miles?: Maybe<HavingIntFilter>;
  warranty5Term?: Maybe<HavingIntFilter>;
  totalFeeAftermarketSale?: Maybe<HavingBigfloatFilter>;
  totalFeeAftermarketCost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Sale?: Maybe<HavingBigfloatFilter>;
  insurance1Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Term?: Maybe<HavingIntFilter>;
  insurance2Sale?: Maybe<HavingBigfloatFilter>;
  insurance2Cost?: Maybe<HavingBigfloatFilter>;
  insurance2Term?: Maybe<HavingIntFilter>;
  insurance3Sale?: Maybe<HavingBigfloatFilter>;
  insurance3Cost?: Maybe<HavingBigfloatFilter>;
  insurance3Term?: Maybe<HavingIntFilter>;
  accidentalHealthSale?: Maybe<HavingBigfloatFilter>;
  accidentalHealthCost?: Maybe<HavingBigfloatFilter>;
  accidentalHealthTerm?: Maybe<HavingIntFilter>;
  creditLifeSale?: Maybe<HavingBigfloatFilter>;
  creditLifeCost?: Maybe<HavingBigfloatFilter>;
  creditLifeTerm?: Maybe<HavingIntFilter>;
  levelizedLifeSale?: Maybe<HavingBigfloatFilter>;
  levelizedLifeCost?: Maybe<HavingBigfloatFilter>;
  levelizedLifeTerm?: Maybe<HavingIntFilter>;
  lossOfEmploymentSale?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentCost?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentTerm?: Maybe<HavingIntFilter>;
  guaranteedAutoProtectionSale?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<HavingIntFilter>;
};

export type DealsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  birthDate?: Maybe<HavingDatetimeFilter>;
  customerCreateDate?: Maybe<HavingDatetimeFilter>;
  customerLastActivityDate?: Maybe<HavingDatetimeFilter>;
  coBuyerBirthDate?: Maybe<HavingDatetimeFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  deliveryDate?: Maybe<HavingDatetimeFilter>;
  deliveryMileage?: Maybe<HavingIntFilter>;
  inventoryDate?: Maybe<HavingDatetimeFilter>;
  inServiceDate?: Maybe<HavingDatetimeFilter>;
  vinExplosionYear?: Maybe<HavingIntFilter>;
  trade1Year?: Maybe<HavingIntFilter>;
  trade1Odometer?: Maybe<HavingIntFilter>;
  trade1ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade1Gross?: Maybe<HavingBigfloatFilter>;
  trade1Payoff?: Maybe<HavingBigfloatFilter>;
  trade2Year?: Maybe<HavingIntFilter>;
  trade2Odometer?: Maybe<HavingIntFilter>;
  trade2ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade2Gross?: Maybe<HavingBigfloatFilter>;
  trade2Payoff?: Maybe<HavingBigfloatFilter>;
  msrp?: Maybe<HavingBigfloatFilter>;
  listPrice?: Maybe<HavingBigfloatFilter>;
  salesPrice?: Maybe<HavingBigfloatFilter>;
  journalPrice?: Maybe<HavingBigfloatFilter>;
  cost?: Maybe<HavingBigfloatFilter>;
  journalCost?: Maybe<HavingBigfloatFilter>;
  adjustments?: Maybe<HavingBigfloatFilter>;
  adjustedCost?: Maybe<HavingBigfloatFilter>;
  incentives?: Maybe<HavingBigfloatFilter>;
  packAmount?: Maybe<HavingBigfloatFilter>;
  saleNet?: Maybe<HavingBigfloatFilter>;
  totalTradeActualCashValue?: Maybe<HavingBigfloatFilter>;
  totalTradeGross?: Maybe<HavingBigfloatFilter>;
  weOweFront?: Maybe<HavingBigfloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  totalFrontCommission?: Maybe<HavingBigfloatFilter>;
  totalFrontSales?: Maybe<HavingBigfloatFilter>;
  totalFrontCost?: Maybe<HavingBigfloatFilter>;
  frontGross?: Maybe<HavingBigfloatFilter>;
  financeProfit?: Maybe<HavingBigfloatFilter>;
  totalWarrantyProfit?: Maybe<HavingBigfloatFilter>;
  weOweBack?: Maybe<HavingBigfloatFilter>;
  insuranceProfit?: Maybe<HavingBigfloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  financeReserve?: Maybe<HavingBigfloatFilter>;
  totalBackCommission?: Maybe<HavingBigfloatFilter>;
  totalBackSales?: Maybe<HavingBigfloatFilter>;
  totalBackCost?: Maybe<HavingBigfloatFilter>;
  backGross?: Maybe<HavingBigfloatFilter>;
  totalProfit?: Maybe<HavingBigfloatFilter>;
  grossProfit?: Maybe<HavingBigfloatFilter>;
  grossPayable?: Maybe<HavingBigfloatFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  bookedDate?: Maybe<HavingDatetimeFilter>;
  finalizedDate?: Maybe<HavingDatetimeFilter>;
  contractDate?: Maybe<HavingDatetimeFilter>;
  accountingDate?: Maybe<HavingDatetimeFilter>;
  statusChangeDate?: Maybe<HavingDatetimeFilter>;
  firstPayDate?: Maybe<HavingDatetimeFilter>;
  term?: Maybe<HavingIntFilter>;
  amountFinanced?: Maybe<HavingBigfloatFilter>;
  apr?: Maybe<HavingBigfloatFilter>;
  monthlyPayment?: Maybe<HavingBigfloatFilter>;
  paymentTotal?: Maybe<HavingBigfloatFilter>;
  rebates?: Maybe<HavingBigfloatFilter>;
  deposit?: Maybe<HavingBigfloatFilter>;
  downPayment?: Maybe<HavingBigfloatFilter>;
  totalNetTrades?: Maybe<HavingBigfloatFilter>;
  totalDown?: Maybe<HavingBigfloatFilter>;
  balloonAmount?: Maybe<HavingBigfloatFilter>;
  adjustedBalloonAmount?: Maybe<HavingBigfloatFilter>;
  holdbackAmount?: Maybe<HavingBigfloatFilter>;
  totalDriveOffAmount?: Maybe<HavingBigfloatFilter>;
  licenseFee?: Maybe<HavingBigfloatFilter>;
  registrationFee?: Maybe<HavingBigfloatFilter>;
  documentationFee?: Maybe<HavingBigfloatFilter>;
  financeCharge?: Maybe<HavingBigfloatFilter>;
  totalPickupPayments?: Maybe<HavingBigfloatFilter>;
  sellRate?: Maybe<HavingBigfloatFilter>;
  buyRate?: Maybe<HavingBigfloatFilter>;
  residualRate?: Maybe<HavingBigfloatFilter>;
  residualAmount?: Maybe<HavingBigfloatFilter>;
  allowedMiles?: Maybe<HavingIntFilter>;
  estimatedMiles?: Maybe<HavingIntFilter>;
  mileageRate?: Maybe<HavingBigfloatFilter>;
  acquisitionFee?: Maybe<HavingBigfloatFilter>;
  basePayment?: Maybe<HavingBigfloatFilter>;
  securityDeposit?: Maybe<HavingBigfloatFilter>;
  totalCapitalReduction?: Maybe<HavingBigfloatFilter>;
  netCapitalCost?: Maybe<HavingBigfloatFilter>;
  leaseDepreciationValue?: Maybe<HavingBigfloatFilter>;
  dealerFees?: Maybe<HavingBigfloatFilter>;
  governmentFees?: Maybe<HavingBigfloatFilter>;
  totalTax?: Maybe<HavingBigfloatFilter>;
  salesman1TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman1FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman1BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman2TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman2FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman2BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman3TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman3FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman3BackCommission?: Maybe<HavingBigfloatFilter>;
  warranty1Sale?: Maybe<HavingBigfloatFilter>;
  warranty1Cost?: Maybe<HavingBigfloatFilter>;
  warranty1Miles?: Maybe<HavingIntFilter>;
  warranty1Term?: Maybe<HavingIntFilter>;
  warranty2Sale?: Maybe<HavingBigfloatFilter>;
  warranty2Cost?: Maybe<HavingBigfloatFilter>;
  warranty2Miles?: Maybe<HavingIntFilter>;
  warranty2Term?: Maybe<HavingIntFilter>;
  warranty3Sale?: Maybe<HavingBigfloatFilter>;
  warranty3Cost?: Maybe<HavingBigfloatFilter>;
  warranty3Miles?: Maybe<HavingIntFilter>;
  warranty3Term?: Maybe<HavingIntFilter>;
  warranty4Sale?: Maybe<HavingBigfloatFilter>;
  warranty4Cost?: Maybe<HavingBigfloatFilter>;
  warranty4Miles?: Maybe<HavingIntFilter>;
  warranty4Term?: Maybe<HavingIntFilter>;
  warranty5Sale?: Maybe<HavingBigfloatFilter>;
  warranty5Cost?: Maybe<HavingBigfloatFilter>;
  warranty5Miles?: Maybe<HavingIntFilter>;
  warranty5Term?: Maybe<HavingIntFilter>;
  totalFeeAftermarketSale?: Maybe<HavingBigfloatFilter>;
  totalFeeAftermarketCost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Sale?: Maybe<HavingBigfloatFilter>;
  insurance1Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Term?: Maybe<HavingIntFilter>;
  insurance2Sale?: Maybe<HavingBigfloatFilter>;
  insurance2Cost?: Maybe<HavingBigfloatFilter>;
  insurance2Term?: Maybe<HavingIntFilter>;
  insurance3Sale?: Maybe<HavingBigfloatFilter>;
  insurance3Cost?: Maybe<HavingBigfloatFilter>;
  insurance3Term?: Maybe<HavingIntFilter>;
  accidentalHealthSale?: Maybe<HavingBigfloatFilter>;
  accidentalHealthCost?: Maybe<HavingBigfloatFilter>;
  accidentalHealthTerm?: Maybe<HavingIntFilter>;
  creditLifeSale?: Maybe<HavingBigfloatFilter>;
  creditLifeCost?: Maybe<HavingBigfloatFilter>;
  creditLifeTerm?: Maybe<HavingIntFilter>;
  levelizedLifeSale?: Maybe<HavingBigfloatFilter>;
  levelizedLifeCost?: Maybe<HavingBigfloatFilter>;
  levelizedLifeTerm?: Maybe<HavingIntFilter>;
  lossOfEmploymentSale?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentCost?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentTerm?: Maybe<HavingIntFilter>;
  guaranteedAutoProtectionSale?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<HavingIntFilter>;
};

export type DealsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  birthDate?: Maybe<HavingDatetimeFilter>;
  customerCreateDate?: Maybe<HavingDatetimeFilter>;
  customerLastActivityDate?: Maybe<HavingDatetimeFilter>;
  coBuyerBirthDate?: Maybe<HavingDatetimeFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  deliveryDate?: Maybe<HavingDatetimeFilter>;
  deliveryMileage?: Maybe<HavingIntFilter>;
  inventoryDate?: Maybe<HavingDatetimeFilter>;
  inServiceDate?: Maybe<HavingDatetimeFilter>;
  vinExplosionYear?: Maybe<HavingIntFilter>;
  trade1Year?: Maybe<HavingIntFilter>;
  trade1Odometer?: Maybe<HavingIntFilter>;
  trade1ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade1Gross?: Maybe<HavingBigfloatFilter>;
  trade1Payoff?: Maybe<HavingBigfloatFilter>;
  trade2Year?: Maybe<HavingIntFilter>;
  trade2Odometer?: Maybe<HavingIntFilter>;
  trade2ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade2Gross?: Maybe<HavingBigfloatFilter>;
  trade2Payoff?: Maybe<HavingBigfloatFilter>;
  msrp?: Maybe<HavingBigfloatFilter>;
  listPrice?: Maybe<HavingBigfloatFilter>;
  salesPrice?: Maybe<HavingBigfloatFilter>;
  journalPrice?: Maybe<HavingBigfloatFilter>;
  cost?: Maybe<HavingBigfloatFilter>;
  journalCost?: Maybe<HavingBigfloatFilter>;
  adjustments?: Maybe<HavingBigfloatFilter>;
  adjustedCost?: Maybe<HavingBigfloatFilter>;
  incentives?: Maybe<HavingBigfloatFilter>;
  packAmount?: Maybe<HavingBigfloatFilter>;
  saleNet?: Maybe<HavingBigfloatFilter>;
  totalTradeActualCashValue?: Maybe<HavingBigfloatFilter>;
  totalTradeGross?: Maybe<HavingBigfloatFilter>;
  weOweFront?: Maybe<HavingBigfloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  totalFrontCommission?: Maybe<HavingBigfloatFilter>;
  totalFrontSales?: Maybe<HavingBigfloatFilter>;
  totalFrontCost?: Maybe<HavingBigfloatFilter>;
  frontGross?: Maybe<HavingBigfloatFilter>;
  financeProfit?: Maybe<HavingBigfloatFilter>;
  totalWarrantyProfit?: Maybe<HavingBigfloatFilter>;
  weOweBack?: Maybe<HavingBigfloatFilter>;
  insuranceProfit?: Maybe<HavingBigfloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  financeReserve?: Maybe<HavingBigfloatFilter>;
  totalBackCommission?: Maybe<HavingBigfloatFilter>;
  totalBackSales?: Maybe<HavingBigfloatFilter>;
  totalBackCost?: Maybe<HavingBigfloatFilter>;
  backGross?: Maybe<HavingBigfloatFilter>;
  totalProfit?: Maybe<HavingBigfloatFilter>;
  grossProfit?: Maybe<HavingBigfloatFilter>;
  grossPayable?: Maybe<HavingBigfloatFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  bookedDate?: Maybe<HavingDatetimeFilter>;
  finalizedDate?: Maybe<HavingDatetimeFilter>;
  contractDate?: Maybe<HavingDatetimeFilter>;
  accountingDate?: Maybe<HavingDatetimeFilter>;
  statusChangeDate?: Maybe<HavingDatetimeFilter>;
  firstPayDate?: Maybe<HavingDatetimeFilter>;
  term?: Maybe<HavingIntFilter>;
  amountFinanced?: Maybe<HavingBigfloatFilter>;
  apr?: Maybe<HavingBigfloatFilter>;
  monthlyPayment?: Maybe<HavingBigfloatFilter>;
  paymentTotal?: Maybe<HavingBigfloatFilter>;
  rebates?: Maybe<HavingBigfloatFilter>;
  deposit?: Maybe<HavingBigfloatFilter>;
  downPayment?: Maybe<HavingBigfloatFilter>;
  totalNetTrades?: Maybe<HavingBigfloatFilter>;
  totalDown?: Maybe<HavingBigfloatFilter>;
  balloonAmount?: Maybe<HavingBigfloatFilter>;
  adjustedBalloonAmount?: Maybe<HavingBigfloatFilter>;
  holdbackAmount?: Maybe<HavingBigfloatFilter>;
  totalDriveOffAmount?: Maybe<HavingBigfloatFilter>;
  licenseFee?: Maybe<HavingBigfloatFilter>;
  registrationFee?: Maybe<HavingBigfloatFilter>;
  documentationFee?: Maybe<HavingBigfloatFilter>;
  financeCharge?: Maybe<HavingBigfloatFilter>;
  totalPickupPayments?: Maybe<HavingBigfloatFilter>;
  sellRate?: Maybe<HavingBigfloatFilter>;
  buyRate?: Maybe<HavingBigfloatFilter>;
  residualRate?: Maybe<HavingBigfloatFilter>;
  residualAmount?: Maybe<HavingBigfloatFilter>;
  allowedMiles?: Maybe<HavingIntFilter>;
  estimatedMiles?: Maybe<HavingIntFilter>;
  mileageRate?: Maybe<HavingBigfloatFilter>;
  acquisitionFee?: Maybe<HavingBigfloatFilter>;
  basePayment?: Maybe<HavingBigfloatFilter>;
  securityDeposit?: Maybe<HavingBigfloatFilter>;
  totalCapitalReduction?: Maybe<HavingBigfloatFilter>;
  netCapitalCost?: Maybe<HavingBigfloatFilter>;
  leaseDepreciationValue?: Maybe<HavingBigfloatFilter>;
  dealerFees?: Maybe<HavingBigfloatFilter>;
  governmentFees?: Maybe<HavingBigfloatFilter>;
  totalTax?: Maybe<HavingBigfloatFilter>;
  salesman1TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman1FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman1BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman2TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman2FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman2BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman3TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman3FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman3BackCommission?: Maybe<HavingBigfloatFilter>;
  warranty1Sale?: Maybe<HavingBigfloatFilter>;
  warranty1Cost?: Maybe<HavingBigfloatFilter>;
  warranty1Miles?: Maybe<HavingIntFilter>;
  warranty1Term?: Maybe<HavingIntFilter>;
  warranty2Sale?: Maybe<HavingBigfloatFilter>;
  warranty2Cost?: Maybe<HavingBigfloatFilter>;
  warranty2Miles?: Maybe<HavingIntFilter>;
  warranty2Term?: Maybe<HavingIntFilter>;
  warranty3Sale?: Maybe<HavingBigfloatFilter>;
  warranty3Cost?: Maybe<HavingBigfloatFilter>;
  warranty3Miles?: Maybe<HavingIntFilter>;
  warranty3Term?: Maybe<HavingIntFilter>;
  warranty4Sale?: Maybe<HavingBigfloatFilter>;
  warranty4Cost?: Maybe<HavingBigfloatFilter>;
  warranty4Miles?: Maybe<HavingIntFilter>;
  warranty4Term?: Maybe<HavingIntFilter>;
  warranty5Sale?: Maybe<HavingBigfloatFilter>;
  warranty5Cost?: Maybe<HavingBigfloatFilter>;
  warranty5Miles?: Maybe<HavingIntFilter>;
  warranty5Term?: Maybe<HavingIntFilter>;
  totalFeeAftermarketSale?: Maybe<HavingBigfloatFilter>;
  totalFeeAftermarketCost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Sale?: Maybe<HavingBigfloatFilter>;
  insurance1Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Term?: Maybe<HavingIntFilter>;
  insurance2Sale?: Maybe<HavingBigfloatFilter>;
  insurance2Cost?: Maybe<HavingBigfloatFilter>;
  insurance2Term?: Maybe<HavingIntFilter>;
  insurance3Sale?: Maybe<HavingBigfloatFilter>;
  insurance3Cost?: Maybe<HavingBigfloatFilter>;
  insurance3Term?: Maybe<HavingIntFilter>;
  accidentalHealthSale?: Maybe<HavingBigfloatFilter>;
  accidentalHealthCost?: Maybe<HavingBigfloatFilter>;
  accidentalHealthTerm?: Maybe<HavingIntFilter>;
  creditLifeSale?: Maybe<HavingBigfloatFilter>;
  creditLifeCost?: Maybe<HavingBigfloatFilter>;
  creditLifeTerm?: Maybe<HavingIntFilter>;
  levelizedLifeSale?: Maybe<HavingBigfloatFilter>;
  levelizedLifeCost?: Maybe<HavingBigfloatFilter>;
  levelizedLifeTerm?: Maybe<HavingIntFilter>;
  lossOfEmploymentSale?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentCost?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentTerm?: Maybe<HavingIntFilter>;
  guaranteedAutoProtectionSale?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<HavingIntFilter>;
};

export type DealsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  birthDate?: Maybe<HavingDatetimeFilter>;
  customerCreateDate?: Maybe<HavingDatetimeFilter>;
  customerLastActivityDate?: Maybe<HavingDatetimeFilter>;
  coBuyerBirthDate?: Maybe<HavingDatetimeFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  deliveryDate?: Maybe<HavingDatetimeFilter>;
  deliveryMileage?: Maybe<HavingIntFilter>;
  inventoryDate?: Maybe<HavingDatetimeFilter>;
  inServiceDate?: Maybe<HavingDatetimeFilter>;
  vinExplosionYear?: Maybe<HavingIntFilter>;
  trade1Year?: Maybe<HavingIntFilter>;
  trade1Odometer?: Maybe<HavingIntFilter>;
  trade1ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade1Gross?: Maybe<HavingBigfloatFilter>;
  trade1Payoff?: Maybe<HavingBigfloatFilter>;
  trade2Year?: Maybe<HavingIntFilter>;
  trade2Odometer?: Maybe<HavingIntFilter>;
  trade2ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade2Gross?: Maybe<HavingBigfloatFilter>;
  trade2Payoff?: Maybe<HavingBigfloatFilter>;
  msrp?: Maybe<HavingBigfloatFilter>;
  listPrice?: Maybe<HavingBigfloatFilter>;
  salesPrice?: Maybe<HavingBigfloatFilter>;
  journalPrice?: Maybe<HavingBigfloatFilter>;
  cost?: Maybe<HavingBigfloatFilter>;
  journalCost?: Maybe<HavingBigfloatFilter>;
  adjustments?: Maybe<HavingBigfloatFilter>;
  adjustedCost?: Maybe<HavingBigfloatFilter>;
  incentives?: Maybe<HavingBigfloatFilter>;
  packAmount?: Maybe<HavingBigfloatFilter>;
  saleNet?: Maybe<HavingBigfloatFilter>;
  totalTradeActualCashValue?: Maybe<HavingBigfloatFilter>;
  totalTradeGross?: Maybe<HavingBigfloatFilter>;
  weOweFront?: Maybe<HavingBigfloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  totalFrontCommission?: Maybe<HavingBigfloatFilter>;
  totalFrontSales?: Maybe<HavingBigfloatFilter>;
  totalFrontCost?: Maybe<HavingBigfloatFilter>;
  frontGross?: Maybe<HavingBigfloatFilter>;
  financeProfit?: Maybe<HavingBigfloatFilter>;
  totalWarrantyProfit?: Maybe<HavingBigfloatFilter>;
  weOweBack?: Maybe<HavingBigfloatFilter>;
  insuranceProfit?: Maybe<HavingBigfloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  financeReserve?: Maybe<HavingBigfloatFilter>;
  totalBackCommission?: Maybe<HavingBigfloatFilter>;
  totalBackSales?: Maybe<HavingBigfloatFilter>;
  totalBackCost?: Maybe<HavingBigfloatFilter>;
  backGross?: Maybe<HavingBigfloatFilter>;
  totalProfit?: Maybe<HavingBigfloatFilter>;
  grossProfit?: Maybe<HavingBigfloatFilter>;
  grossPayable?: Maybe<HavingBigfloatFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  bookedDate?: Maybe<HavingDatetimeFilter>;
  finalizedDate?: Maybe<HavingDatetimeFilter>;
  contractDate?: Maybe<HavingDatetimeFilter>;
  accountingDate?: Maybe<HavingDatetimeFilter>;
  statusChangeDate?: Maybe<HavingDatetimeFilter>;
  firstPayDate?: Maybe<HavingDatetimeFilter>;
  term?: Maybe<HavingIntFilter>;
  amountFinanced?: Maybe<HavingBigfloatFilter>;
  apr?: Maybe<HavingBigfloatFilter>;
  monthlyPayment?: Maybe<HavingBigfloatFilter>;
  paymentTotal?: Maybe<HavingBigfloatFilter>;
  rebates?: Maybe<HavingBigfloatFilter>;
  deposit?: Maybe<HavingBigfloatFilter>;
  downPayment?: Maybe<HavingBigfloatFilter>;
  totalNetTrades?: Maybe<HavingBigfloatFilter>;
  totalDown?: Maybe<HavingBigfloatFilter>;
  balloonAmount?: Maybe<HavingBigfloatFilter>;
  adjustedBalloonAmount?: Maybe<HavingBigfloatFilter>;
  holdbackAmount?: Maybe<HavingBigfloatFilter>;
  totalDriveOffAmount?: Maybe<HavingBigfloatFilter>;
  licenseFee?: Maybe<HavingBigfloatFilter>;
  registrationFee?: Maybe<HavingBigfloatFilter>;
  documentationFee?: Maybe<HavingBigfloatFilter>;
  financeCharge?: Maybe<HavingBigfloatFilter>;
  totalPickupPayments?: Maybe<HavingBigfloatFilter>;
  sellRate?: Maybe<HavingBigfloatFilter>;
  buyRate?: Maybe<HavingBigfloatFilter>;
  residualRate?: Maybe<HavingBigfloatFilter>;
  residualAmount?: Maybe<HavingBigfloatFilter>;
  allowedMiles?: Maybe<HavingIntFilter>;
  estimatedMiles?: Maybe<HavingIntFilter>;
  mileageRate?: Maybe<HavingBigfloatFilter>;
  acquisitionFee?: Maybe<HavingBigfloatFilter>;
  basePayment?: Maybe<HavingBigfloatFilter>;
  securityDeposit?: Maybe<HavingBigfloatFilter>;
  totalCapitalReduction?: Maybe<HavingBigfloatFilter>;
  netCapitalCost?: Maybe<HavingBigfloatFilter>;
  leaseDepreciationValue?: Maybe<HavingBigfloatFilter>;
  dealerFees?: Maybe<HavingBigfloatFilter>;
  governmentFees?: Maybe<HavingBigfloatFilter>;
  totalTax?: Maybe<HavingBigfloatFilter>;
  salesman1TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman1FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman1BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman2TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman2FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman2BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman3TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman3FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman3BackCommission?: Maybe<HavingBigfloatFilter>;
  warranty1Sale?: Maybe<HavingBigfloatFilter>;
  warranty1Cost?: Maybe<HavingBigfloatFilter>;
  warranty1Miles?: Maybe<HavingIntFilter>;
  warranty1Term?: Maybe<HavingIntFilter>;
  warranty2Sale?: Maybe<HavingBigfloatFilter>;
  warranty2Cost?: Maybe<HavingBigfloatFilter>;
  warranty2Miles?: Maybe<HavingIntFilter>;
  warranty2Term?: Maybe<HavingIntFilter>;
  warranty3Sale?: Maybe<HavingBigfloatFilter>;
  warranty3Cost?: Maybe<HavingBigfloatFilter>;
  warranty3Miles?: Maybe<HavingIntFilter>;
  warranty3Term?: Maybe<HavingIntFilter>;
  warranty4Sale?: Maybe<HavingBigfloatFilter>;
  warranty4Cost?: Maybe<HavingBigfloatFilter>;
  warranty4Miles?: Maybe<HavingIntFilter>;
  warranty4Term?: Maybe<HavingIntFilter>;
  warranty5Sale?: Maybe<HavingBigfloatFilter>;
  warranty5Cost?: Maybe<HavingBigfloatFilter>;
  warranty5Miles?: Maybe<HavingIntFilter>;
  warranty5Term?: Maybe<HavingIntFilter>;
  totalFeeAftermarketSale?: Maybe<HavingBigfloatFilter>;
  totalFeeAftermarketCost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Sale?: Maybe<HavingBigfloatFilter>;
  insurance1Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Term?: Maybe<HavingIntFilter>;
  insurance2Sale?: Maybe<HavingBigfloatFilter>;
  insurance2Cost?: Maybe<HavingBigfloatFilter>;
  insurance2Term?: Maybe<HavingIntFilter>;
  insurance3Sale?: Maybe<HavingBigfloatFilter>;
  insurance3Cost?: Maybe<HavingBigfloatFilter>;
  insurance3Term?: Maybe<HavingIntFilter>;
  accidentalHealthSale?: Maybe<HavingBigfloatFilter>;
  accidentalHealthCost?: Maybe<HavingBigfloatFilter>;
  accidentalHealthTerm?: Maybe<HavingIntFilter>;
  creditLifeSale?: Maybe<HavingBigfloatFilter>;
  creditLifeCost?: Maybe<HavingBigfloatFilter>;
  creditLifeTerm?: Maybe<HavingIntFilter>;
  levelizedLifeSale?: Maybe<HavingBigfloatFilter>;
  levelizedLifeCost?: Maybe<HavingBigfloatFilter>;
  levelizedLifeTerm?: Maybe<HavingIntFilter>;
  lossOfEmploymentSale?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentCost?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentTerm?: Maybe<HavingIntFilter>;
  guaranteedAutoProtectionSale?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<HavingIntFilter>;
};

export type DealsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  birthDate?: Maybe<HavingDatetimeFilter>;
  customerCreateDate?: Maybe<HavingDatetimeFilter>;
  customerLastActivityDate?: Maybe<HavingDatetimeFilter>;
  coBuyerBirthDate?: Maybe<HavingDatetimeFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  deliveryDate?: Maybe<HavingDatetimeFilter>;
  deliveryMileage?: Maybe<HavingIntFilter>;
  inventoryDate?: Maybe<HavingDatetimeFilter>;
  inServiceDate?: Maybe<HavingDatetimeFilter>;
  vinExplosionYear?: Maybe<HavingIntFilter>;
  trade1Year?: Maybe<HavingIntFilter>;
  trade1Odometer?: Maybe<HavingIntFilter>;
  trade1ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade1Gross?: Maybe<HavingBigfloatFilter>;
  trade1Payoff?: Maybe<HavingBigfloatFilter>;
  trade2Year?: Maybe<HavingIntFilter>;
  trade2Odometer?: Maybe<HavingIntFilter>;
  trade2ActualCashValue?: Maybe<HavingBigfloatFilter>;
  trade2Gross?: Maybe<HavingBigfloatFilter>;
  trade2Payoff?: Maybe<HavingBigfloatFilter>;
  msrp?: Maybe<HavingBigfloatFilter>;
  listPrice?: Maybe<HavingBigfloatFilter>;
  salesPrice?: Maybe<HavingBigfloatFilter>;
  journalPrice?: Maybe<HavingBigfloatFilter>;
  cost?: Maybe<HavingBigfloatFilter>;
  journalCost?: Maybe<HavingBigfloatFilter>;
  adjustments?: Maybe<HavingBigfloatFilter>;
  adjustedCost?: Maybe<HavingBigfloatFilter>;
  incentives?: Maybe<HavingBigfloatFilter>;
  packAmount?: Maybe<HavingBigfloatFilter>;
  saleNet?: Maybe<HavingBigfloatFilter>;
  totalTradeActualCashValue?: Maybe<HavingBigfloatFilter>;
  totalTradeGross?: Maybe<HavingBigfloatFilter>;
  weOweFront?: Maybe<HavingBigfloatFilter>;
  totalFrontFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  totalFrontCommission?: Maybe<HavingBigfloatFilter>;
  totalFrontSales?: Maybe<HavingBigfloatFilter>;
  totalFrontCost?: Maybe<HavingBigfloatFilter>;
  frontGross?: Maybe<HavingBigfloatFilter>;
  financeProfit?: Maybe<HavingBigfloatFilter>;
  totalWarrantyProfit?: Maybe<HavingBigfloatFilter>;
  weOweBack?: Maybe<HavingBigfloatFilter>;
  insuranceProfit?: Maybe<HavingBigfloatFilter>;
  totalBackFeesAftermarketProfit?: Maybe<HavingBigfloatFilter>;
  financeReserve?: Maybe<HavingBigfloatFilter>;
  totalBackCommission?: Maybe<HavingBigfloatFilter>;
  totalBackSales?: Maybe<HavingBigfloatFilter>;
  totalBackCost?: Maybe<HavingBigfloatFilter>;
  backGross?: Maybe<HavingBigfloatFilter>;
  totalProfit?: Maybe<HavingBigfloatFilter>;
  grossProfit?: Maybe<HavingBigfloatFilter>;
  grossPayable?: Maybe<HavingBigfloatFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  bookedDate?: Maybe<HavingDatetimeFilter>;
  finalizedDate?: Maybe<HavingDatetimeFilter>;
  contractDate?: Maybe<HavingDatetimeFilter>;
  accountingDate?: Maybe<HavingDatetimeFilter>;
  statusChangeDate?: Maybe<HavingDatetimeFilter>;
  firstPayDate?: Maybe<HavingDatetimeFilter>;
  term?: Maybe<HavingIntFilter>;
  amountFinanced?: Maybe<HavingBigfloatFilter>;
  apr?: Maybe<HavingBigfloatFilter>;
  monthlyPayment?: Maybe<HavingBigfloatFilter>;
  paymentTotal?: Maybe<HavingBigfloatFilter>;
  rebates?: Maybe<HavingBigfloatFilter>;
  deposit?: Maybe<HavingBigfloatFilter>;
  downPayment?: Maybe<HavingBigfloatFilter>;
  totalNetTrades?: Maybe<HavingBigfloatFilter>;
  totalDown?: Maybe<HavingBigfloatFilter>;
  balloonAmount?: Maybe<HavingBigfloatFilter>;
  adjustedBalloonAmount?: Maybe<HavingBigfloatFilter>;
  holdbackAmount?: Maybe<HavingBigfloatFilter>;
  totalDriveOffAmount?: Maybe<HavingBigfloatFilter>;
  licenseFee?: Maybe<HavingBigfloatFilter>;
  registrationFee?: Maybe<HavingBigfloatFilter>;
  documentationFee?: Maybe<HavingBigfloatFilter>;
  financeCharge?: Maybe<HavingBigfloatFilter>;
  totalPickupPayments?: Maybe<HavingBigfloatFilter>;
  sellRate?: Maybe<HavingBigfloatFilter>;
  buyRate?: Maybe<HavingBigfloatFilter>;
  residualRate?: Maybe<HavingBigfloatFilter>;
  residualAmount?: Maybe<HavingBigfloatFilter>;
  allowedMiles?: Maybe<HavingIntFilter>;
  estimatedMiles?: Maybe<HavingIntFilter>;
  mileageRate?: Maybe<HavingBigfloatFilter>;
  acquisitionFee?: Maybe<HavingBigfloatFilter>;
  basePayment?: Maybe<HavingBigfloatFilter>;
  securityDeposit?: Maybe<HavingBigfloatFilter>;
  totalCapitalReduction?: Maybe<HavingBigfloatFilter>;
  netCapitalCost?: Maybe<HavingBigfloatFilter>;
  leaseDepreciationValue?: Maybe<HavingBigfloatFilter>;
  dealerFees?: Maybe<HavingBigfloatFilter>;
  governmentFees?: Maybe<HavingBigfloatFilter>;
  totalTax?: Maybe<HavingBigfloatFilter>;
  salesman1TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman1FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman1BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman2TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman2FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman2BackCommission?: Maybe<HavingBigfloatFilter>;
  salesman3TotalCommission?: Maybe<HavingBigfloatFilter>;
  salesman3FrontCommission?: Maybe<HavingBigfloatFilter>;
  salesman3BackCommission?: Maybe<HavingBigfloatFilter>;
  warranty1Sale?: Maybe<HavingBigfloatFilter>;
  warranty1Cost?: Maybe<HavingBigfloatFilter>;
  warranty1Miles?: Maybe<HavingIntFilter>;
  warranty1Term?: Maybe<HavingIntFilter>;
  warranty2Sale?: Maybe<HavingBigfloatFilter>;
  warranty2Cost?: Maybe<HavingBigfloatFilter>;
  warranty2Miles?: Maybe<HavingIntFilter>;
  warranty2Term?: Maybe<HavingIntFilter>;
  warranty3Sale?: Maybe<HavingBigfloatFilter>;
  warranty3Cost?: Maybe<HavingBigfloatFilter>;
  warranty3Miles?: Maybe<HavingIntFilter>;
  warranty3Term?: Maybe<HavingIntFilter>;
  warranty4Sale?: Maybe<HavingBigfloatFilter>;
  warranty4Cost?: Maybe<HavingBigfloatFilter>;
  warranty4Miles?: Maybe<HavingIntFilter>;
  warranty4Term?: Maybe<HavingIntFilter>;
  warranty5Sale?: Maybe<HavingBigfloatFilter>;
  warranty5Cost?: Maybe<HavingBigfloatFilter>;
  warranty5Miles?: Maybe<HavingIntFilter>;
  warranty5Term?: Maybe<HavingIntFilter>;
  totalFeeAftermarketSale?: Maybe<HavingBigfloatFilter>;
  totalFeeAftermarketCost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket1Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket2Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket3Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket4Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket5Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket6Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket7Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket8Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket9Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket10Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket11Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket12Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket13Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket14Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket15Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket16Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket17Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket18Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket19Cost?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Sale?: Maybe<HavingBigfloatFilter>;
  feeAftermarket20Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Sale?: Maybe<HavingBigfloatFilter>;
  insurance1Cost?: Maybe<HavingBigfloatFilter>;
  insurance1Term?: Maybe<HavingIntFilter>;
  insurance2Sale?: Maybe<HavingBigfloatFilter>;
  insurance2Cost?: Maybe<HavingBigfloatFilter>;
  insurance2Term?: Maybe<HavingIntFilter>;
  insurance3Sale?: Maybe<HavingBigfloatFilter>;
  insurance3Cost?: Maybe<HavingBigfloatFilter>;
  insurance3Term?: Maybe<HavingIntFilter>;
  accidentalHealthSale?: Maybe<HavingBigfloatFilter>;
  accidentalHealthCost?: Maybe<HavingBigfloatFilter>;
  accidentalHealthTerm?: Maybe<HavingIntFilter>;
  creditLifeSale?: Maybe<HavingBigfloatFilter>;
  creditLifeCost?: Maybe<HavingBigfloatFilter>;
  creditLifeTerm?: Maybe<HavingIntFilter>;
  levelizedLifeSale?: Maybe<HavingBigfloatFilter>;
  levelizedLifeCost?: Maybe<HavingBigfloatFilter>;
  levelizedLifeTerm?: Maybe<HavingIntFilter>;
  lossOfEmploymentSale?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentCost?: Maybe<HavingBigfloatFilter>;
  lossOfEmploymentTerm?: Maybe<HavingIntFilter>;
  guaranteedAutoProtectionSale?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionCost?: Maybe<HavingBigfloatFilter>;
  guaranteedAutoProtectionTerm?: Maybe<HavingIntFilter>;
};

/** The `deal` to be created by this mutation. */
export type DealsLeadIdFkeyDealsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  fileType?: Maybe<Scalars['String']>;
  dvDealerId?: Maybe<Scalars['String']>;
  vendorDealerId?: Maybe<Scalars['String']>;
  dmsType?: Maybe<Scalars['String']>;
  dealNumber?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  workExtension?: Maybe<Scalars['String']>;
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  email3?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  individualBusinessFlag?: Maybe<Scalars['String']>;
  optOut?: Maybe<Scalars['Boolean']>;
  blockEmail?: Maybe<Scalars['Boolean']>;
  blockPhone?: Maybe<Scalars['Boolean']>;
  blockMail?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  customerCreateDate?: Maybe<Scalars['Date']>;
  customerLastActivityDate?: Maybe<Scalars['Date']>;
  coBuyerCustomerNumber?: Maybe<Scalars['String']>;
  coBuyerFullName?: Maybe<Scalars['String']>;
  coBuyerSalutation?: Maybe<Scalars['String']>;
  coBuyerFirstName?: Maybe<Scalars['String']>;
  coBuyerMiddleName?: Maybe<Scalars['String']>;
  coBuyerLastName?: Maybe<Scalars['String']>;
  coBuyerSuffix?: Maybe<Scalars['String']>;
  coBuyerAddressLine1?: Maybe<Scalars['String']>;
  coBuyerAddressLine2?: Maybe<Scalars['String']>;
  coBuyerCity?: Maybe<Scalars['String']>;
  coBuyerState?: Maybe<Scalars['String']>;
  coBuyerZip?: Maybe<Scalars['String']>;
  coBuyerCounty?: Maybe<Scalars['String']>;
  coBuyerHomePhone?: Maybe<Scalars['String']>;
  coBuyerCellPhone?: Maybe<Scalars['String']>;
  coBuyerWorkPhone?: Maybe<Scalars['String']>;
  coBuyerWorkExtension?: Maybe<Scalars['String']>;
  coBuyerEmail1?: Maybe<Scalars['String']>;
  coBuyerEmail2?: Maybe<Scalars['String']>;
  coBuyerEmail3?: Maybe<Scalars['String']>;
  coBuyerBirthDate?: Maybe<Scalars['Date']>;
  coBuyerIndividualBusinessFlag?: Maybe<Scalars['String']>;
  coBuyerOptOut?: Maybe<Scalars['Boolean']>;
  coBuyerBlockEmail?: Maybe<Scalars['Boolean']>;
  coBuyerBlockPhone?: Maybe<Scalars['Boolean']>;
  coBuyerBlockMail?: Maybe<Scalars['Boolean']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  newUsed?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  engineConfiguration?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  engineNumber?: Maybe<Scalars['String']>;
  chassisNumber?: Maybe<Scalars['String']>;
  licensePlateNumber?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  deliveryMileage?: Maybe<Scalars['Int']>;
  inventoryDate?: Maybe<Scalars['Date']>;
  inServiceDate?: Maybe<Scalars['Date']>;
  vinExplosionYear?: Maybe<Scalars['Int']>;
  vinExplosionMake?: Maybe<Scalars['String']>;
  vinExplosionModel?: Maybe<Scalars['String']>;
  vinExplosionTrim?: Maybe<Scalars['String']>;
  vinExplosionTransmissionType?: Maybe<Scalars['String']>;
  vinExplosionFuelType?: Maybe<Scalars['String']>;
  vinExplosionEngineSize?: Maybe<Scalars['String']>;
  vinExplosionGvwRange?: Maybe<Scalars['String']>;
  trade1Vin?: Maybe<Scalars['String']>;
  trade1Year?: Maybe<Scalars['Int']>;
  trade1Make?: Maybe<Scalars['String']>;
  trade1Model?: Maybe<Scalars['String']>;
  trade1Odometer?: Maybe<Scalars['Int']>;
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  trade2Vin?: Maybe<Scalars['String']>;
  trade2Year?: Maybe<Scalars['Int']>;
  trade2Make?: Maybe<Scalars['String']>;
  trade2Model?: Maybe<Scalars['String']>;
  trade2Odometer?: Maybe<Scalars['Int']>;
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  salesman1Number?: Maybe<Scalars['String']>;
  salesman1Name?: Maybe<Scalars['String']>;
  salesman2Number?: Maybe<Scalars['String']>;
  salesman2Name?: Maybe<Scalars['String']>;
  salesman3Number?: Maybe<Scalars['String']>;
  salesman3Name?: Maybe<Scalars['String']>;
  closingManagerNumber?: Maybe<Scalars['String']>;
  closingManagerName?: Maybe<Scalars['String']>;
  financeManagerNumber?: Maybe<Scalars['String']>;
  financeManagerName?: Maybe<Scalars['String']>;
  salesmanManagerNumber?: Maybe<Scalars['String']>;
  salesmanManagerName?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['BigFloat']>;
  listPrice?: Maybe<Scalars['BigFloat']>;
  salesPrice?: Maybe<Scalars['BigFloat']>;
  journalPrice?: Maybe<Scalars['BigFloat']>;
  cost?: Maybe<Scalars['BigFloat']>;
  journalCost?: Maybe<Scalars['BigFloat']>;
  adjustments?: Maybe<Scalars['BigFloat']>;
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  incentives?: Maybe<Scalars['BigFloat']>;
  packAmount?: Maybe<Scalars['BigFloat']>;
  saleNet?: Maybe<Scalars['BigFloat']>;
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  weOweFront?: Maybe<Scalars['BigFloat']>;
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  frontGross?: Maybe<Scalars['BigFloat']>;
  financeProfit?: Maybe<Scalars['BigFloat']>;
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  weOweBack?: Maybe<Scalars['BigFloat']>;
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  financeReserve?: Maybe<Scalars['BigFloat']>;
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  backGross?: Maybe<Scalars['BigFloat']>;
  totalProfit?: Maybe<Scalars['BigFloat']>;
  grossProfit?: Maybe<Scalars['BigFloat']>;
  grossPayable?: Maybe<Scalars['BigFloat']>;
  dealStatus?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  bookedDate?: Maybe<Scalars['Date']>;
  finalizedDate?: Maybe<Scalars['Date']>;
  contractDate?: Maybe<Scalars['Date']>;
  accountingDate?: Maybe<Scalars['Date']>;
  statusChangeDate?: Maybe<Scalars['Date']>;
  firstPayDate?: Maybe<Scalars['Date']>;
  dealType?: Maybe<Scalars['String']>;
  saleType?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  apr?: Maybe<Scalars['BigFloat']>;
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  rebates?: Maybe<Scalars['BigFloat']>;
  deposit?: Maybe<Scalars['BigFloat']>;
  downPayment?: Maybe<Scalars['BigFloat']>;
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  totalDown?: Maybe<Scalars['BigFloat']>;
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  licenseFee?: Maybe<Scalars['BigFloat']>;
  registrationFee?: Maybe<Scalars['BigFloat']>;
  documentationFee?: Maybe<Scalars['BigFloat']>;
  financeCharge?: Maybe<Scalars['BigFloat']>;
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  sellRate?: Maybe<Scalars['BigFloat']>;
  buyRate?: Maybe<Scalars['BigFloat']>;
  residualRate?: Maybe<Scalars['BigFloat']>;
  residualAmount?: Maybe<Scalars['BigFloat']>;
  allowedMiles?: Maybe<Scalars['Int']>;
  estimatedMiles?: Maybe<Scalars['Int']>;
  mileageRate?: Maybe<Scalars['BigFloat']>;
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  basePayment?: Maybe<Scalars['BigFloat']>;
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  dealerFees?: Maybe<Scalars['BigFloat']>;
  governmentFees?: Maybe<Scalars['BigFloat']>;
  totalTax?: Maybe<Scalars['BigFloat']>;
  registrationState?: Maybe<Scalars['String']>;
  reportOfSaleNumber?: Maybe<Scalars['String']>;
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  warranty1Name?: Maybe<Scalars['String']>;
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  warranty1Miles?: Maybe<Scalars['Int']>;
  warranty1Term?: Maybe<Scalars['Int']>;
  warranty2Name?: Maybe<Scalars['String']>;
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  warranty2Miles?: Maybe<Scalars['Int']>;
  warranty2Term?: Maybe<Scalars['Int']>;
  warranty3Name?: Maybe<Scalars['String']>;
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  warranty3Miles?: Maybe<Scalars['Int']>;
  warranty3Term?: Maybe<Scalars['Int']>;
  warranty4Name?: Maybe<Scalars['String']>;
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  warranty4Miles?: Maybe<Scalars['Int']>;
  warranty4Term?: Maybe<Scalars['Int']>;
  warranty5Name?: Maybe<Scalars['String']>;
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  warranty5Miles?: Maybe<Scalars['Int']>;
  warranty5Term?: Maybe<Scalars['Int']>;
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Name?: Maybe<Scalars['String']>;
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket2Name?: Maybe<Scalars['String']>;
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket3Name?: Maybe<Scalars['String']>;
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket4Name?: Maybe<Scalars['String']>;
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket5Name?: Maybe<Scalars['String']>;
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket6Name?: Maybe<Scalars['String']>;
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket7Name?: Maybe<Scalars['String']>;
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket8Name?: Maybe<Scalars['String']>;
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket9Name?: Maybe<Scalars['String']>;
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket10Name?: Maybe<Scalars['String']>;
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket11Name?: Maybe<Scalars['String']>;
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket12Name?: Maybe<Scalars['String']>;
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket13Name?: Maybe<Scalars['String']>;
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket14Name?: Maybe<Scalars['String']>;
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket15Name?: Maybe<Scalars['String']>;
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket16Name?: Maybe<Scalars['String']>;
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket17Name?: Maybe<Scalars['String']>;
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket18Name?: Maybe<Scalars['String']>;
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket19Name?: Maybe<Scalars['String']>;
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket20Name?: Maybe<Scalars['String']>;
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20ProfitIndicator?: Maybe<Scalars['Boolean']>;
  insurance1Type?: Maybe<Scalars['String']>;
  insurance1Name?: Maybe<Scalars['String']>;
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  insurance1Term?: Maybe<Scalars['Int']>;
  insurance2Type?: Maybe<Scalars['String']>;
  insurance2Name?: Maybe<Scalars['String']>;
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  insurance2Term?: Maybe<Scalars['Int']>;
  insurance3Type?: Maybe<Scalars['String']>;
  insurance3Name?: Maybe<Scalars['String']>;
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  insurance3Term?: Maybe<Scalars['Int']>;
  accidentalHealthType?: Maybe<Scalars['String']>;
  accidentalHealthName?: Maybe<Scalars['String']>;
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  accidentalHealthTerm?: Maybe<Scalars['Int']>;
  creditLifeType?: Maybe<Scalars['String']>;
  creditLifeName?: Maybe<Scalars['String']>;
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  creditLifeTerm?: Maybe<Scalars['Int']>;
  levelizedLifeType?: Maybe<Scalars['String']>;
  levelizedLifeName?: Maybe<Scalars['String']>;
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  levelizedLifeTerm?: Maybe<Scalars['Int']>;
  lossOfEmploymentType?: Maybe<Scalars['String']>;
  lossOfEmploymentName?: Maybe<Scalars['String']>;
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentTerm?: Maybe<Scalars['Int']>;
  guaranteedAutoProtectionType?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionName?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionTerm?: Maybe<Scalars['Int']>;
  saleComments?: Maybe<Scalars['String']>;
  cassStdLine1?: Maybe<Scalars['String']>;
  cassStdLine2?: Maybe<Scalars['String']>;
  cassStdCity?: Maybe<Scalars['String']>;
  cassStdState?: Maybe<Scalars['String']>;
  cassStdZip?: Maybe<Scalars['String']>;
  cassStdZip4?: Maybe<Scalars['String']>;
  cassStdDpbc?: Maybe<Scalars['String']>;
  cassStdChkdgt?: Maybe<Scalars['String']>;
  cassStdCart?: Maybe<Scalars['String']>;
  cassStdLot?: Maybe<Scalars['String']>;
  cassStdLotord?: Maybe<Scalars['String']>;
  cassStdUrb?: Maybe<Scalars['String']>;
  cassStdFips?: Maybe<Scalars['String']>;
  cassStdEws?: Maybe<Scalars['String']>;
  cassStdLacs?: Maybe<Scalars['String']>;
  cassStdZipmov?: Maybe<Scalars['String']>;
  cassStdZ4Lom?: Maybe<Scalars['String']>;
  cassStdNdiapt?: Maybe<Scalars['String']>;
  cassStdNdirr?: Maybe<Scalars['String']>;
  cassStdLacsrt?: Maybe<Scalars['String']>;
  cassStdErrorCd?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<DealsLeadIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<DealsCompanyIntegrationIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `DealInput` mutation. */
export type DealsLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnDealForDealsLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<DealOnDealForDealsLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<DealsLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `deal` in the `LeadInput` mutation. */
export type DealsLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `deal` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `deal` for the far side of the relationship. */
  connectById?: Maybe<Array<DealDealsPkeyConnect>>;
  /** The primary key(s) for `deal` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DealNodeIdConnect>>;
  /** The primary key(s) for `deal` for the far side of the relationship. */
  deleteById?: Maybe<Array<DealDealsPkeyDelete>>;
  /** The primary key(s) for `deal` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<DealNodeIdDelete>>;
  /** The primary key(s) and patch data for `deal` for the far side of the relationship. */
  updateById?: Maybe<Array<DealOnDealForDealsLeadIdFkeyUsingDealsPkeyUpdate>>;
  /** The primary key(s) and patch data for `deal` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnDealForDealsLeadIdFkeyNodeIdUpdate>>;
  /** A `DealInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DealsLeadIdFkeyDealsCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type DealsLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** Methods to use when ordering `Deal`. */
export enum DealsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  CompanyIntegrationIdAsc = 'COMPANY_INTEGRATION_ID_ASC',
  CompanyIntegrationIdDesc = 'COMPANY_INTEGRATION_ID_DESC',
  FileTypeAsc = 'FILE_TYPE_ASC',
  FileTypeDesc = 'FILE_TYPE_DESC',
  DvDealerIdAsc = 'DV_DEALER_ID_ASC',
  DvDealerIdDesc = 'DV_DEALER_ID_DESC',
  VendorDealerIdAsc = 'VENDOR_DEALER_ID_ASC',
  VendorDealerIdDesc = 'VENDOR_DEALER_ID_DESC',
  DmsTypeAsc = 'DMS_TYPE_ASC',
  DmsTypeDesc = 'DMS_TYPE_DESC',
  DealNumberAsc = 'DEAL_NUMBER_ASC',
  DealNumberDesc = 'DEAL_NUMBER_DESC',
  CustomerNumberAsc = 'CUSTOMER_NUMBER_ASC',
  CustomerNumberDesc = 'CUSTOMER_NUMBER_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  SalutationAsc = 'SALUTATION_ASC',
  SalutationDesc = 'SALUTATION_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  MiddleNameAsc = 'MIDDLE_NAME_ASC',
  MiddleNameDesc = 'MIDDLE_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  SuffixAsc = 'SUFFIX_ASC',
  SuffixDesc = 'SUFFIX_DESC',
  AddressLine_1Asc = 'ADDRESS_LINE_1_ASC',
  AddressLine_1Desc = 'ADDRESS_LINE_1_DESC',
  AddressLine_2Asc = 'ADDRESS_LINE_2_ASC',
  AddressLine_2Desc = 'ADDRESS_LINE_2_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  ZipAsc = 'ZIP_ASC',
  ZipDesc = 'ZIP_DESC',
  CountyAsc = 'COUNTY_ASC',
  CountyDesc = 'COUNTY_DESC',
  HomePhoneAsc = 'HOME_PHONE_ASC',
  HomePhoneDesc = 'HOME_PHONE_DESC',
  CellPhoneAsc = 'CELL_PHONE_ASC',
  CellPhoneDesc = 'CELL_PHONE_DESC',
  WorkPhoneAsc = 'WORK_PHONE_ASC',
  WorkPhoneDesc = 'WORK_PHONE_DESC',
  WorkExtensionAsc = 'WORK_EXTENSION_ASC',
  WorkExtensionDesc = 'WORK_EXTENSION_DESC',
  Email_1Asc = 'EMAIL_1_ASC',
  Email_1Desc = 'EMAIL_1_DESC',
  Email_2Asc = 'EMAIL_2_ASC',
  Email_2Desc = 'EMAIL_2_DESC',
  Email_3Asc = 'EMAIL_3_ASC',
  Email_3Desc = 'EMAIL_3_DESC',
  BirthDateAsc = 'BIRTH_DATE_ASC',
  BirthDateDesc = 'BIRTH_DATE_DESC',
  IndividualBusinessFlagAsc = 'INDIVIDUAL_BUSINESS_FLAG_ASC',
  IndividualBusinessFlagDesc = 'INDIVIDUAL_BUSINESS_FLAG_DESC',
  OptOutAsc = 'OPT_OUT_ASC',
  OptOutDesc = 'OPT_OUT_DESC',
  BlockEmailAsc = 'BLOCK_EMAIL_ASC',
  BlockEmailDesc = 'BLOCK_EMAIL_DESC',
  BlockPhoneAsc = 'BLOCK_PHONE_ASC',
  BlockPhoneDesc = 'BLOCK_PHONE_DESC',
  BlockMailAsc = 'BLOCK_MAIL_ASC',
  BlockMailDesc = 'BLOCK_MAIL_DESC',
  LanguageAsc = 'LANGUAGE_ASC',
  LanguageDesc = 'LANGUAGE_DESC',
  CustomerCreateDateAsc = 'CUSTOMER_CREATE_DATE_ASC',
  CustomerCreateDateDesc = 'CUSTOMER_CREATE_DATE_DESC',
  CustomerLastActivityDateAsc = 'CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  CustomerLastActivityDateDesc = 'CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  CoBuyerCustomerNumberAsc = 'CO_BUYER_CUSTOMER_NUMBER_ASC',
  CoBuyerCustomerNumberDesc = 'CO_BUYER_CUSTOMER_NUMBER_DESC',
  CoBuyerFullNameAsc = 'CO_BUYER_FULL_NAME_ASC',
  CoBuyerFullNameDesc = 'CO_BUYER_FULL_NAME_DESC',
  CoBuyerSalutationAsc = 'CO_BUYER_SALUTATION_ASC',
  CoBuyerSalutationDesc = 'CO_BUYER_SALUTATION_DESC',
  CoBuyerFirstNameAsc = 'CO_BUYER_FIRST_NAME_ASC',
  CoBuyerFirstNameDesc = 'CO_BUYER_FIRST_NAME_DESC',
  CoBuyerMiddleNameAsc = 'CO_BUYER_MIDDLE_NAME_ASC',
  CoBuyerMiddleNameDesc = 'CO_BUYER_MIDDLE_NAME_DESC',
  CoBuyerLastNameAsc = 'CO_BUYER_LAST_NAME_ASC',
  CoBuyerLastNameDesc = 'CO_BUYER_LAST_NAME_DESC',
  CoBuyerSuffixAsc = 'CO_BUYER_SUFFIX_ASC',
  CoBuyerSuffixDesc = 'CO_BUYER_SUFFIX_DESC',
  CoBuyerAddressLine_1Asc = 'CO_BUYER_ADDRESS_LINE_1_ASC',
  CoBuyerAddressLine_1Desc = 'CO_BUYER_ADDRESS_LINE_1_DESC',
  CoBuyerAddressLine_2Asc = 'CO_BUYER_ADDRESS_LINE_2_ASC',
  CoBuyerAddressLine_2Desc = 'CO_BUYER_ADDRESS_LINE_2_DESC',
  CoBuyerCityAsc = 'CO_BUYER_CITY_ASC',
  CoBuyerCityDesc = 'CO_BUYER_CITY_DESC',
  CoBuyerStateAsc = 'CO_BUYER_STATE_ASC',
  CoBuyerStateDesc = 'CO_BUYER_STATE_DESC',
  CoBuyerZipAsc = 'CO_BUYER_ZIP_ASC',
  CoBuyerZipDesc = 'CO_BUYER_ZIP_DESC',
  CoBuyerCountyAsc = 'CO_BUYER_COUNTY_ASC',
  CoBuyerCountyDesc = 'CO_BUYER_COUNTY_DESC',
  CoBuyerHomePhoneAsc = 'CO_BUYER_HOME_PHONE_ASC',
  CoBuyerHomePhoneDesc = 'CO_BUYER_HOME_PHONE_DESC',
  CoBuyerCellPhoneAsc = 'CO_BUYER_CELL_PHONE_ASC',
  CoBuyerCellPhoneDesc = 'CO_BUYER_CELL_PHONE_DESC',
  CoBuyerWorkPhoneAsc = 'CO_BUYER_WORK_PHONE_ASC',
  CoBuyerWorkPhoneDesc = 'CO_BUYER_WORK_PHONE_DESC',
  CoBuyerWorkExtensionAsc = 'CO_BUYER_WORK_EXTENSION_ASC',
  CoBuyerWorkExtensionDesc = 'CO_BUYER_WORK_EXTENSION_DESC',
  CoBuyerEmail_1Asc = 'CO_BUYER_EMAIL_1_ASC',
  CoBuyerEmail_1Desc = 'CO_BUYER_EMAIL_1_DESC',
  CoBuyerEmail_2Asc = 'CO_BUYER_EMAIL_2_ASC',
  CoBuyerEmail_2Desc = 'CO_BUYER_EMAIL_2_DESC',
  CoBuyerEmail_3Asc = 'CO_BUYER_EMAIL_3_ASC',
  CoBuyerEmail_3Desc = 'CO_BUYER_EMAIL_3_DESC',
  CoBuyerBirthDateAsc = 'CO_BUYER_BIRTH_DATE_ASC',
  CoBuyerBirthDateDesc = 'CO_BUYER_BIRTH_DATE_DESC',
  CoBuyerIndividualBusinessFlagAsc = 'CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  CoBuyerIndividualBusinessFlagDesc = 'CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  CoBuyerOptOutAsc = 'CO_BUYER_OPT_OUT_ASC',
  CoBuyerOptOutDesc = 'CO_BUYER_OPT_OUT_DESC',
  CoBuyerBlockEmailAsc = 'CO_BUYER_BLOCK_EMAIL_ASC',
  CoBuyerBlockEmailDesc = 'CO_BUYER_BLOCK_EMAIL_DESC',
  CoBuyerBlockPhoneAsc = 'CO_BUYER_BLOCK_PHONE_ASC',
  CoBuyerBlockPhoneDesc = 'CO_BUYER_BLOCK_PHONE_DESC',
  CoBuyerBlockMailAsc = 'CO_BUYER_BLOCK_MAIL_ASC',
  CoBuyerBlockMailDesc = 'CO_BUYER_BLOCK_MAIL_DESC',
  VinAsc = 'VIN_ASC',
  VinDesc = 'VIN_DESC',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MakeAsc = 'MAKE_ASC',
  MakeDesc = 'MAKE_DESC',
  ModelAsc = 'MODEL_ASC',
  ModelDesc = 'MODEL_DESC',
  ModelNumberAsc = 'MODEL_NUMBER_ASC',
  ModelNumberDesc = 'MODEL_NUMBER_DESC',
  MileageAsc = 'MILEAGE_ASC',
  MileageDesc = 'MILEAGE_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  ExteriorColorAsc = 'EXTERIOR_COLOR_ASC',
  ExteriorColorDesc = 'EXTERIOR_COLOR_DESC',
  NewUsedAsc = 'NEW_USED_ASC',
  NewUsedDesc = 'NEW_USED_DESC',
  StockNumberAsc = 'STOCK_NUMBER_ASC',
  StockNumberDesc = 'STOCK_NUMBER_DESC',
  TransmissionAsc = 'TRANSMISSION_ASC',
  TransmissionDesc = 'TRANSMISSION_DESC',
  EngineConfigurationAsc = 'ENGINE_CONFIGURATION_ASC',
  EngineConfigurationDesc = 'ENGINE_CONFIGURATION_DESC',
  TrimAsc = 'TRIM_ASC',
  TrimDesc = 'TRIM_DESC',
  EngineNumberAsc = 'ENGINE_NUMBER_ASC',
  EngineNumberDesc = 'ENGINE_NUMBER_DESC',
  ChassisNumberAsc = 'CHASSIS_NUMBER_ASC',
  ChassisNumberDesc = 'CHASSIS_NUMBER_DESC',
  LicensePlateNumberAsc = 'LICENSE_PLATE_NUMBER_ASC',
  LicensePlateNumberDesc = 'LICENSE_PLATE_NUMBER_DESC',
  DeliveryDateAsc = 'DELIVERY_DATE_ASC',
  DeliveryDateDesc = 'DELIVERY_DATE_DESC',
  DeliveryMileageAsc = 'DELIVERY_MILEAGE_ASC',
  DeliveryMileageDesc = 'DELIVERY_MILEAGE_DESC',
  InventoryDateAsc = 'INVENTORY_DATE_ASC',
  InventoryDateDesc = 'INVENTORY_DATE_DESC',
  InServiceDateAsc = 'IN_SERVICE_DATE_ASC',
  InServiceDateDesc = 'IN_SERVICE_DATE_DESC',
  VinExplosionYearAsc = 'VIN_EXPLOSION_YEAR_ASC',
  VinExplosionYearDesc = 'VIN_EXPLOSION_YEAR_DESC',
  VinExplosionMakeAsc = 'VIN_EXPLOSION_MAKE_ASC',
  VinExplosionMakeDesc = 'VIN_EXPLOSION_MAKE_DESC',
  VinExplosionModelAsc = 'VIN_EXPLOSION_MODEL_ASC',
  VinExplosionModelDesc = 'VIN_EXPLOSION_MODEL_DESC',
  VinExplosionTrimAsc = 'VIN_EXPLOSION_TRIM_ASC',
  VinExplosionTrimDesc = 'VIN_EXPLOSION_TRIM_DESC',
  VinExplosionTransmissionTypeAsc = 'VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  VinExplosionTransmissionTypeDesc = 'VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  VinExplosionFuelTypeAsc = 'VIN_EXPLOSION_FUEL_TYPE_ASC',
  VinExplosionFuelTypeDesc = 'VIN_EXPLOSION_FUEL_TYPE_DESC',
  VinExplosionEngineSizeAsc = 'VIN_EXPLOSION_ENGINE_SIZE_ASC',
  VinExplosionEngineSizeDesc = 'VIN_EXPLOSION_ENGINE_SIZE_DESC',
  VinExplosionGvwRangeAsc = 'VIN_EXPLOSION_GVW_RANGE_ASC',
  VinExplosionGvwRangeDesc = 'VIN_EXPLOSION_GVW_RANGE_DESC',
  Trade_1VinAsc = 'TRADE_1_VIN_ASC',
  Trade_1VinDesc = 'TRADE_1_VIN_DESC',
  Trade_1YearAsc = 'TRADE_1_YEAR_ASC',
  Trade_1YearDesc = 'TRADE_1_YEAR_DESC',
  Trade_1MakeAsc = 'TRADE_1_MAKE_ASC',
  Trade_1MakeDesc = 'TRADE_1_MAKE_DESC',
  Trade_1ModelAsc = 'TRADE_1_MODEL_ASC',
  Trade_1ModelDesc = 'TRADE_1_MODEL_DESC',
  Trade_1OdometerAsc = 'TRADE_1_ODOMETER_ASC',
  Trade_1OdometerDesc = 'TRADE_1_ODOMETER_DESC',
  Trade_1ActualCashValueAsc = 'TRADE_1_ACTUAL_CASH_VALUE_ASC',
  Trade_1ActualCashValueDesc = 'TRADE_1_ACTUAL_CASH_VALUE_DESC',
  Trade_1GrossAsc = 'TRADE_1_GROSS_ASC',
  Trade_1GrossDesc = 'TRADE_1_GROSS_DESC',
  Trade_1PayoffAsc = 'TRADE_1_PAYOFF_ASC',
  Trade_1PayoffDesc = 'TRADE_1_PAYOFF_DESC',
  Trade_2VinAsc = 'TRADE_2_VIN_ASC',
  Trade_2VinDesc = 'TRADE_2_VIN_DESC',
  Trade_2YearAsc = 'TRADE_2_YEAR_ASC',
  Trade_2YearDesc = 'TRADE_2_YEAR_DESC',
  Trade_2MakeAsc = 'TRADE_2_MAKE_ASC',
  Trade_2MakeDesc = 'TRADE_2_MAKE_DESC',
  Trade_2ModelAsc = 'TRADE_2_MODEL_ASC',
  Trade_2ModelDesc = 'TRADE_2_MODEL_DESC',
  Trade_2OdometerAsc = 'TRADE_2_ODOMETER_ASC',
  Trade_2OdometerDesc = 'TRADE_2_ODOMETER_DESC',
  Trade_2ActualCashValueAsc = 'TRADE_2_ACTUAL_CASH_VALUE_ASC',
  Trade_2ActualCashValueDesc = 'TRADE_2_ACTUAL_CASH_VALUE_DESC',
  Trade_2GrossAsc = 'TRADE_2_GROSS_ASC',
  Trade_2GrossDesc = 'TRADE_2_GROSS_DESC',
  Trade_2PayoffAsc = 'TRADE_2_PAYOFF_ASC',
  Trade_2PayoffDesc = 'TRADE_2_PAYOFF_DESC',
  Salesman_1NumberAsc = 'SALESMAN_1_NUMBER_ASC',
  Salesman_1NumberDesc = 'SALESMAN_1_NUMBER_DESC',
  Salesman_1NameAsc = 'SALESMAN_1_NAME_ASC',
  Salesman_1NameDesc = 'SALESMAN_1_NAME_DESC',
  Salesman_2NumberAsc = 'SALESMAN_2_NUMBER_ASC',
  Salesman_2NumberDesc = 'SALESMAN_2_NUMBER_DESC',
  Salesman_2NameAsc = 'SALESMAN_2_NAME_ASC',
  Salesman_2NameDesc = 'SALESMAN_2_NAME_DESC',
  Salesman_3NumberAsc = 'SALESMAN_3_NUMBER_ASC',
  Salesman_3NumberDesc = 'SALESMAN_3_NUMBER_DESC',
  Salesman_3NameAsc = 'SALESMAN_3_NAME_ASC',
  Salesman_3NameDesc = 'SALESMAN_3_NAME_DESC',
  ClosingManagerNumberAsc = 'CLOSING_MANAGER_NUMBER_ASC',
  ClosingManagerNumberDesc = 'CLOSING_MANAGER_NUMBER_DESC',
  ClosingManagerNameAsc = 'CLOSING_MANAGER_NAME_ASC',
  ClosingManagerNameDesc = 'CLOSING_MANAGER_NAME_DESC',
  FinanceManagerNumberAsc = 'FINANCE_MANAGER_NUMBER_ASC',
  FinanceManagerNumberDesc = 'FINANCE_MANAGER_NUMBER_DESC',
  FinanceManagerNameAsc = 'FINANCE_MANAGER_NAME_ASC',
  FinanceManagerNameDesc = 'FINANCE_MANAGER_NAME_DESC',
  SalesmanManagerNumberAsc = 'SALESMAN_MANAGER_NUMBER_ASC',
  SalesmanManagerNumberDesc = 'SALESMAN_MANAGER_NUMBER_DESC',
  SalesmanManagerNameAsc = 'SALESMAN_MANAGER_NAME_ASC',
  SalesmanManagerNameDesc = 'SALESMAN_MANAGER_NAME_DESC',
  MsrpAsc = 'MSRP_ASC',
  MsrpDesc = 'MSRP_DESC',
  ListPriceAsc = 'LIST_PRICE_ASC',
  ListPriceDesc = 'LIST_PRICE_DESC',
  SalesPriceAsc = 'SALES_PRICE_ASC',
  SalesPriceDesc = 'SALES_PRICE_DESC',
  JournalPriceAsc = 'JOURNAL_PRICE_ASC',
  JournalPriceDesc = 'JOURNAL_PRICE_DESC',
  CostAsc = 'COST_ASC',
  CostDesc = 'COST_DESC',
  JournalCostAsc = 'JOURNAL_COST_ASC',
  JournalCostDesc = 'JOURNAL_COST_DESC',
  AdjustmentsAsc = 'ADJUSTMENTS_ASC',
  AdjustmentsDesc = 'ADJUSTMENTS_DESC',
  AdjustedCostAsc = 'ADJUSTED_COST_ASC',
  AdjustedCostDesc = 'ADJUSTED_COST_DESC',
  IncentivesAsc = 'INCENTIVES_ASC',
  IncentivesDesc = 'INCENTIVES_DESC',
  PackAmountAsc = 'PACK_AMOUNT_ASC',
  PackAmountDesc = 'PACK_AMOUNT_DESC',
  SaleNetAsc = 'SALE_NET_ASC',
  SaleNetDesc = 'SALE_NET_DESC',
  TotalTradeActualCashValueAsc = 'TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  TotalTradeActualCashValueDesc = 'TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  TotalTradeGrossAsc = 'TOTAL_TRADE_GROSS_ASC',
  TotalTradeGrossDesc = 'TOTAL_TRADE_GROSS_DESC',
  WeOweFrontAsc = 'WE_OWE_FRONT_ASC',
  WeOweFrontDesc = 'WE_OWE_FRONT_DESC',
  TotalFrontFeesAftermarketProfitAsc = 'TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  TotalFrontFeesAftermarketProfitDesc = 'TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  TotalFrontCommissionAsc = 'TOTAL_FRONT_COMMISSION_ASC',
  TotalFrontCommissionDesc = 'TOTAL_FRONT_COMMISSION_DESC',
  TotalFrontSalesAsc = 'TOTAL_FRONT_SALES_ASC',
  TotalFrontSalesDesc = 'TOTAL_FRONT_SALES_DESC',
  TotalFrontCostAsc = 'TOTAL_FRONT_COST_ASC',
  TotalFrontCostDesc = 'TOTAL_FRONT_COST_DESC',
  FrontGrossAsc = 'FRONT_GROSS_ASC',
  FrontGrossDesc = 'FRONT_GROSS_DESC',
  FinanceProfitAsc = 'FINANCE_PROFIT_ASC',
  FinanceProfitDesc = 'FINANCE_PROFIT_DESC',
  TotalWarrantyProfitAsc = 'TOTAL_WARRANTY_PROFIT_ASC',
  TotalWarrantyProfitDesc = 'TOTAL_WARRANTY_PROFIT_DESC',
  WeOweBackAsc = 'WE_OWE_BACK_ASC',
  WeOweBackDesc = 'WE_OWE_BACK_DESC',
  InsuranceProfitAsc = 'INSURANCE_PROFIT_ASC',
  InsuranceProfitDesc = 'INSURANCE_PROFIT_DESC',
  TotalBackFeesAftermarketProfitAsc = 'TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  TotalBackFeesAftermarketProfitDesc = 'TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  FinanceReserveAsc = 'FINANCE_RESERVE_ASC',
  FinanceReserveDesc = 'FINANCE_RESERVE_DESC',
  TotalBackCommissionAsc = 'TOTAL_BACK_COMMISSION_ASC',
  TotalBackCommissionDesc = 'TOTAL_BACK_COMMISSION_DESC',
  TotalBackSalesAsc = 'TOTAL_BACK_SALES_ASC',
  TotalBackSalesDesc = 'TOTAL_BACK_SALES_DESC',
  TotalBackCostAsc = 'TOTAL_BACK_COST_ASC',
  TotalBackCostDesc = 'TOTAL_BACK_COST_DESC',
  BackGrossAsc = 'BACK_GROSS_ASC',
  BackGrossDesc = 'BACK_GROSS_DESC',
  TotalProfitAsc = 'TOTAL_PROFIT_ASC',
  TotalProfitDesc = 'TOTAL_PROFIT_DESC',
  GrossProfitAsc = 'GROSS_PROFIT_ASC',
  GrossProfitDesc = 'GROSS_PROFIT_DESC',
  GrossPayableAsc = 'GROSS_PAYABLE_ASC',
  GrossPayableDesc = 'GROSS_PAYABLE_DESC',
  DealStatusAsc = 'DEAL_STATUS_ASC',
  DealStatusDesc = 'DEAL_STATUS_DESC',
  EntryDateAsc = 'ENTRY_DATE_ASC',
  EntryDateDesc = 'ENTRY_DATE_DESC',
  BookedDateAsc = 'BOOKED_DATE_ASC',
  BookedDateDesc = 'BOOKED_DATE_DESC',
  FinalizedDateAsc = 'FINALIZED_DATE_ASC',
  FinalizedDateDesc = 'FINALIZED_DATE_DESC',
  ContractDateAsc = 'CONTRACT_DATE_ASC',
  ContractDateDesc = 'CONTRACT_DATE_DESC',
  AccountingDateAsc = 'ACCOUNTING_DATE_ASC',
  AccountingDateDesc = 'ACCOUNTING_DATE_DESC',
  StatusChangeDateAsc = 'STATUS_CHANGE_DATE_ASC',
  StatusChangeDateDesc = 'STATUS_CHANGE_DATE_DESC',
  FirstPayDateAsc = 'FIRST_PAY_DATE_ASC',
  FirstPayDateDesc = 'FIRST_PAY_DATE_DESC',
  DealTypeAsc = 'DEAL_TYPE_ASC',
  DealTypeDesc = 'DEAL_TYPE_DESC',
  SaleTypeAsc = 'SALE_TYPE_ASC',
  SaleTypeDesc = 'SALE_TYPE_DESC',
  BankIdAsc = 'BANK_ID_ASC',
  BankIdDesc = 'BANK_ID_DESC',
  BankNameAsc = 'BANK_NAME_ASC',
  BankNameDesc = 'BANK_NAME_DESC',
  BankAddressAsc = 'BANK_ADDRESS_ASC',
  BankAddressDesc = 'BANK_ADDRESS_DESC',
  TermAsc = 'TERM_ASC',
  TermDesc = 'TERM_DESC',
  AmountFinancedAsc = 'AMOUNT_FINANCED_ASC',
  AmountFinancedDesc = 'AMOUNT_FINANCED_DESC',
  AprAsc = 'APR_ASC',
  AprDesc = 'APR_DESC',
  MonthlyPaymentAsc = 'MONTHLY_PAYMENT_ASC',
  MonthlyPaymentDesc = 'MONTHLY_PAYMENT_DESC',
  PaymentTotalAsc = 'PAYMENT_TOTAL_ASC',
  PaymentTotalDesc = 'PAYMENT_TOTAL_DESC',
  RebatesAsc = 'REBATES_ASC',
  RebatesDesc = 'REBATES_DESC',
  DepositAsc = 'DEPOSIT_ASC',
  DepositDesc = 'DEPOSIT_DESC',
  DownPaymentAsc = 'DOWN_PAYMENT_ASC',
  DownPaymentDesc = 'DOWN_PAYMENT_DESC',
  TotalNetTradesAsc = 'TOTAL_NET_TRADES_ASC',
  TotalNetTradesDesc = 'TOTAL_NET_TRADES_DESC',
  TotalDownAsc = 'TOTAL_DOWN_ASC',
  TotalDownDesc = 'TOTAL_DOWN_DESC',
  BalloonAmountAsc = 'BALLOON_AMOUNT_ASC',
  BalloonAmountDesc = 'BALLOON_AMOUNT_DESC',
  AdjustedBalloonAmountAsc = 'ADJUSTED_BALLOON_AMOUNT_ASC',
  AdjustedBalloonAmountDesc = 'ADJUSTED_BALLOON_AMOUNT_DESC',
  HoldbackAmountAsc = 'HOLDBACK_AMOUNT_ASC',
  HoldbackAmountDesc = 'HOLDBACK_AMOUNT_DESC',
  TotalDriveOffAmountAsc = 'TOTAL_DRIVE_OFF_AMOUNT_ASC',
  TotalDriveOffAmountDesc = 'TOTAL_DRIVE_OFF_AMOUNT_DESC',
  LicenseFeeAsc = 'LICENSE_FEE_ASC',
  LicenseFeeDesc = 'LICENSE_FEE_DESC',
  RegistrationFeeAsc = 'REGISTRATION_FEE_ASC',
  RegistrationFeeDesc = 'REGISTRATION_FEE_DESC',
  DocumentationFeeAsc = 'DOCUMENTATION_FEE_ASC',
  DocumentationFeeDesc = 'DOCUMENTATION_FEE_DESC',
  FinanceChargeAsc = 'FINANCE_CHARGE_ASC',
  FinanceChargeDesc = 'FINANCE_CHARGE_DESC',
  TotalPickupPaymentsAsc = 'TOTAL_PICKUP_PAYMENTS_ASC',
  TotalPickupPaymentsDesc = 'TOTAL_PICKUP_PAYMENTS_DESC',
  SellRateAsc = 'SELL_RATE_ASC',
  SellRateDesc = 'SELL_RATE_DESC',
  BuyRateAsc = 'BUY_RATE_ASC',
  BuyRateDesc = 'BUY_RATE_DESC',
  ResidualRateAsc = 'RESIDUAL_RATE_ASC',
  ResidualRateDesc = 'RESIDUAL_RATE_DESC',
  ResidualAmountAsc = 'RESIDUAL_AMOUNT_ASC',
  ResidualAmountDesc = 'RESIDUAL_AMOUNT_DESC',
  AllowedMilesAsc = 'ALLOWED_MILES_ASC',
  AllowedMilesDesc = 'ALLOWED_MILES_DESC',
  EstimatedMilesAsc = 'ESTIMATED_MILES_ASC',
  EstimatedMilesDesc = 'ESTIMATED_MILES_DESC',
  MileageRateAsc = 'MILEAGE_RATE_ASC',
  MileageRateDesc = 'MILEAGE_RATE_DESC',
  AcquisitionFeeAsc = 'ACQUISITION_FEE_ASC',
  AcquisitionFeeDesc = 'ACQUISITION_FEE_DESC',
  BasePaymentAsc = 'BASE_PAYMENT_ASC',
  BasePaymentDesc = 'BASE_PAYMENT_DESC',
  SecurityDepositAsc = 'SECURITY_DEPOSIT_ASC',
  SecurityDepositDesc = 'SECURITY_DEPOSIT_DESC',
  TotalCapitalReductionAsc = 'TOTAL_CAPITAL_REDUCTION_ASC',
  TotalCapitalReductionDesc = 'TOTAL_CAPITAL_REDUCTION_DESC',
  NetCapitalCostAsc = 'NET_CAPITAL_COST_ASC',
  NetCapitalCostDesc = 'NET_CAPITAL_COST_DESC',
  LeaseDepreciationValueAsc = 'LEASE_DEPRECIATION_VALUE_ASC',
  LeaseDepreciationValueDesc = 'LEASE_DEPRECIATION_VALUE_DESC',
  DealerFeesAsc = 'DEALER_FEES_ASC',
  DealerFeesDesc = 'DEALER_FEES_DESC',
  GovernmentFeesAsc = 'GOVERNMENT_FEES_ASC',
  GovernmentFeesDesc = 'GOVERNMENT_FEES_DESC',
  TotalTaxAsc = 'TOTAL_TAX_ASC',
  TotalTaxDesc = 'TOTAL_TAX_DESC',
  RegistrationStateAsc = 'REGISTRATION_STATE_ASC',
  RegistrationStateDesc = 'REGISTRATION_STATE_DESC',
  ReportOfSaleNumberAsc = 'REPORT_OF_SALE_NUMBER_ASC',
  ReportOfSaleNumberDesc = 'REPORT_OF_SALE_NUMBER_DESC',
  Salesman_1TotalCommissionAsc = 'SALESMAN_1_TOTAL_COMMISSION_ASC',
  Salesman_1TotalCommissionDesc = 'SALESMAN_1_TOTAL_COMMISSION_DESC',
  Salesman_1FrontCommissionAsc = 'SALESMAN_1_FRONT_COMMISSION_ASC',
  Salesman_1FrontCommissionDesc = 'SALESMAN_1_FRONT_COMMISSION_DESC',
  Salesman_1BackCommissionAsc = 'SALESMAN_1_BACK_COMMISSION_ASC',
  Salesman_1BackCommissionDesc = 'SALESMAN_1_BACK_COMMISSION_DESC',
  Salesman_2TotalCommissionAsc = 'SALESMAN_2_TOTAL_COMMISSION_ASC',
  Salesman_2TotalCommissionDesc = 'SALESMAN_2_TOTAL_COMMISSION_DESC',
  Salesman_2FrontCommissionAsc = 'SALESMAN_2_FRONT_COMMISSION_ASC',
  Salesman_2FrontCommissionDesc = 'SALESMAN_2_FRONT_COMMISSION_DESC',
  Salesman_2BackCommissionAsc = 'SALESMAN_2_BACK_COMMISSION_ASC',
  Salesman_2BackCommissionDesc = 'SALESMAN_2_BACK_COMMISSION_DESC',
  Salesman_3TotalCommissionAsc = 'SALESMAN_3_TOTAL_COMMISSION_ASC',
  Salesman_3TotalCommissionDesc = 'SALESMAN_3_TOTAL_COMMISSION_DESC',
  Salesman_3FrontCommissionAsc = 'SALESMAN_3_FRONT_COMMISSION_ASC',
  Salesman_3FrontCommissionDesc = 'SALESMAN_3_FRONT_COMMISSION_DESC',
  Salesman_3BackCommissionAsc = 'SALESMAN_3_BACK_COMMISSION_ASC',
  Salesman_3BackCommissionDesc = 'SALESMAN_3_BACK_COMMISSION_DESC',
  Warranty_1NameAsc = 'WARRANTY_1_NAME_ASC',
  Warranty_1NameDesc = 'WARRANTY_1_NAME_DESC',
  Warranty_1SaleAsc = 'WARRANTY_1_SALE_ASC',
  Warranty_1SaleDesc = 'WARRANTY_1_SALE_DESC',
  Warranty_1CostAsc = 'WARRANTY_1_COST_ASC',
  Warranty_1CostDesc = 'WARRANTY_1_COST_DESC',
  Warranty_1MilesAsc = 'WARRANTY_1_MILES_ASC',
  Warranty_1MilesDesc = 'WARRANTY_1_MILES_DESC',
  Warranty_1TermAsc = 'WARRANTY_1_TERM_ASC',
  Warranty_1TermDesc = 'WARRANTY_1_TERM_DESC',
  Warranty_2NameAsc = 'WARRANTY_2_NAME_ASC',
  Warranty_2NameDesc = 'WARRANTY_2_NAME_DESC',
  Warranty_2SaleAsc = 'WARRANTY_2_SALE_ASC',
  Warranty_2SaleDesc = 'WARRANTY_2_SALE_DESC',
  Warranty_2CostAsc = 'WARRANTY_2_COST_ASC',
  Warranty_2CostDesc = 'WARRANTY_2_COST_DESC',
  Warranty_2MilesAsc = 'WARRANTY_2_MILES_ASC',
  Warranty_2MilesDesc = 'WARRANTY_2_MILES_DESC',
  Warranty_2TermAsc = 'WARRANTY_2_TERM_ASC',
  Warranty_2TermDesc = 'WARRANTY_2_TERM_DESC',
  Warranty_3NameAsc = 'WARRANTY_3_NAME_ASC',
  Warranty_3NameDesc = 'WARRANTY_3_NAME_DESC',
  Warranty_3SaleAsc = 'WARRANTY_3_SALE_ASC',
  Warranty_3SaleDesc = 'WARRANTY_3_SALE_DESC',
  Warranty_3CostAsc = 'WARRANTY_3_COST_ASC',
  Warranty_3CostDesc = 'WARRANTY_3_COST_DESC',
  Warranty_3MilesAsc = 'WARRANTY_3_MILES_ASC',
  Warranty_3MilesDesc = 'WARRANTY_3_MILES_DESC',
  Warranty_3TermAsc = 'WARRANTY_3_TERM_ASC',
  Warranty_3TermDesc = 'WARRANTY_3_TERM_DESC',
  Warranty_4NameAsc = 'WARRANTY_4_NAME_ASC',
  Warranty_4NameDesc = 'WARRANTY_4_NAME_DESC',
  Warranty_4SaleAsc = 'WARRANTY_4_SALE_ASC',
  Warranty_4SaleDesc = 'WARRANTY_4_SALE_DESC',
  Warranty_4CostAsc = 'WARRANTY_4_COST_ASC',
  Warranty_4CostDesc = 'WARRANTY_4_COST_DESC',
  Warranty_4MilesAsc = 'WARRANTY_4_MILES_ASC',
  Warranty_4MilesDesc = 'WARRANTY_4_MILES_DESC',
  Warranty_4TermAsc = 'WARRANTY_4_TERM_ASC',
  Warranty_4TermDesc = 'WARRANTY_4_TERM_DESC',
  Warranty_5NameAsc = 'WARRANTY_5_NAME_ASC',
  Warranty_5NameDesc = 'WARRANTY_5_NAME_DESC',
  Warranty_5SaleAsc = 'WARRANTY_5_SALE_ASC',
  Warranty_5SaleDesc = 'WARRANTY_5_SALE_DESC',
  Warranty_5CostAsc = 'WARRANTY_5_COST_ASC',
  Warranty_5CostDesc = 'WARRANTY_5_COST_DESC',
  Warranty_5MilesAsc = 'WARRANTY_5_MILES_ASC',
  Warranty_5MilesDesc = 'WARRANTY_5_MILES_DESC',
  Warranty_5TermAsc = 'WARRANTY_5_TERM_ASC',
  Warranty_5TermDesc = 'WARRANTY_5_TERM_DESC',
  TotalFeeAftermarketSaleAsc = 'TOTAL_FEE_AFTERMARKET_SALE_ASC',
  TotalFeeAftermarketSaleDesc = 'TOTAL_FEE_AFTERMARKET_SALE_DESC',
  TotalFeeAftermarketCostAsc = 'TOTAL_FEE_AFTERMARKET_COST_ASC',
  TotalFeeAftermarketCostDesc = 'TOTAL_FEE_AFTERMARKET_COST_DESC',
  FeeAftermarket_1NameAsc = 'FEE_AFTERMARKET_1_NAME_ASC',
  FeeAftermarket_1NameDesc = 'FEE_AFTERMARKET_1_NAME_DESC',
  FeeAftermarket_1SaleAsc = 'FEE_AFTERMARKET_1_SALE_ASC',
  FeeAftermarket_1SaleDesc = 'FEE_AFTERMARKET_1_SALE_DESC',
  FeeAftermarket_1CostAsc = 'FEE_AFTERMARKET_1_COST_ASC',
  FeeAftermarket_1CostDesc = 'FEE_AFTERMARKET_1_COST_DESC',
  FeeAftermarket_1ProfitIndicatorAsc = 'FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  FeeAftermarket_1ProfitIndicatorDesc = 'FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  FeeAftermarket_2NameAsc = 'FEE_AFTERMARKET_2_NAME_ASC',
  FeeAftermarket_2NameDesc = 'FEE_AFTERMARKET_2_NAME_DESC',
  FeeAftermarket_2SaleAsc = 'FEE_AFTERMARKET_2_SALE_ASC',
  FeeAftermarket_2SaleDesc = 'FEE_AFTERMARKET_2_SALE_DESC',
  FeeAftermarket_2CostAsc = 'FEE_AFTERMARKET_2_COST_ASC',
  FeeAftermarket_2CostDesc = 'FEE_AFTERMARKET_2_COST_DESC',
  FeeAftermarket_2ProfitIndicatorAsc = 'FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  FeeAftermarket_2ProfitIndicatorDesc = 'FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  FeeAftermarket_3NameAsc = 'FEE_AFTERMARKET_3_NAME_ASC',
  FeeAftermarket_3NameDesc = 'FEE_AFTERMARKET_3_NAME_DESC',
  FeeAftermarket_3SaleAsc = 'FEE_AFTERMARKET_3_SALE_ASC',
  FeeAftermarket_3SaleDesc = 'FEE_AFTERMARKET_3_SALE_DESC',
  FeeAftermarket_3CostAsc = 'FEE_AFTERMARKET_3_COST_ASC',
  FeeAftermarket_3CostDesc = 'FEE_AFTERMARKET_3_COST_DESC',
  FeeAftermarket_3ProfitIndicatorAsc = 'FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  FeeAftermarket_3ProfitIndicatorDesc = 'FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  FeeAftermarket_4NameAsc = 'FEE_AFTERMARKET_4_NAME_ASC',
  FeeAftermarket_4NameDesc = 'FEE_AFTERMARKET_4_NAME_DESC',
  FeeAftermarket_4SaleAsc = 'FEE_AFTERMARKET_4_SALE_ASC',
  FeeAftermarket_4SaleDesc = 'FEE_AFTERMARKET_4_SALE_DESC',
  FeeAftermarket_4CostAsc = 'FEE_AFTERMARKET_4_COST_ASC',
  FeeAftermarket_4CostDesc = 'FEE_AFTERMARKET_4_COST_DESC',
  FeeAftermarket_4ProfitIndicatorAsc = 'FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  FeeAftermarket_4ProfitIndicatorDesc = 'FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  FeeAftermarket_5NameAsc = 'FEE_AFTERMARKET_5_NAME_ASC',
  FeeAftermarket_5NameDesc = 'FEE_AFTERMARKET_5_NAME_DESC',
  FeeAftermarket_5SaleAsc = 'FEE_AFTERMARKET_5_SALE_ASC',
  FeeAftermarket_5SaleDesc = 'FEE_AFTERMARKET_5_SALE_DESC',
  FeeAftermarket_5CostAsc = 'FEE_AFTERMARKET_5_COST_ASC',
  FeeAftermarket_5CostDesc = 'FEE_AFTERMARKET_5_COST_DESC',
  FeeAftermarket_5ProfitIndicatorAsc = 'FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  FeeAftermarket_5ProfitIndicatorDesc = 'FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  FeeAftermarket_6NameAsc = 'FEE_AFTERMARKET_6_NAME_ASC',
  FeeAftermarket_6NameDesc = 'FEE_AFTERMARKET_6_NAME_DESC',
  FeeAftermarket_6SaleAsc = 'FEE_AFTERMARKET_6_SALE_ASC',
  FeeAftermarket_6SaleDesc = 'FEE_AFTERMARKET_6_SALE_DESC',
  FeeAftermarket_6CostAsc = 'FEE_AFTERMARKET_6_COST_ASC',
  FeeAftermarket_6CostDesc = 'FEE_AFTERMARKET_6_COST_DESC',
  FeeAftermarket_6ProfitIndicatorAsc = 'FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  FeeAftermarket_6ProfitIndicatorDesc = 'FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  FeeAftermarket_7NameAsc = 'FEE_AFTERMARKET_7_NAME_ASC',
  FeeAftermarket_7NameDesc = 'FEE_AFTERMARKET_7_NAME_DESC',
  FeeAftermarket_7SaleAsc = 'FEE_AFTERMARKET_7_SALE_ASC',
  FeeAftermarket_7SaleDesc = 'FEE_AFTERMARKET_7_SALE_DESC',
  FeeAftermarket_7CostAsc = 'FEE_AFTERMARKET_7_COST_ASC',
  FeeAftermarket_7CostDesc = 'FEE_AFTERMARKET_7_COST_DESC',
  FeeAftermarket_7ProfitIndicatorAsc = 'FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  FeeAftermarket_7ProfitIndicatorDesc = 'FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  FeeAftermarket_8NameAsc = 'FEE_AFTERMARKET_8_NAME_ASC',
  FeeAftermarket_8NameDesc = 'FEE_AFTERMARKET_8_NAME_DESC',
  FeeAftermarket_8SaleAsc = 'FEE_AFTERMARKET_8_SALE_ASC',
  FeeAftermarket_8SaleDesc = 'FEE_AFTERMARKET_8_SALE_DESC',
  FeeAftermarket_8CostAsc = 'FEE_AFTERMARKET_8_COST_ASC',
  FeeAftermarket_8CostDesc = 'FEE_AFTERMARKET_8_COST_DESC',
  FeeAftermarket_8ProfitIndicatorAsc = 'FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  FeeAftermarket_8ProfitIndicatorDesc = 'FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  FeeAftermarket_9NameAsc = 'FEE_AFTERMARKET_9_NAME_ASC',
  FeeAftermarket_9NameDesc = 'FEE_AFTERMARKET_9_NAME_DESC',
  FeeAftermarket_9SaleAsc = 'FEE_AFTERMARKET_9_SALE_ASC',
  FeeAftermarket_9SaleDesc = 'FEE_AFTERMARKET_9_SALE_DESC',
  FeeAftermarket_9CostAsc = 'FEE_AFTERMARKET_9_COST_ASC',
  FeeAftermarket_9CostDesc = 'FEE_AFTERMARKET_9_COST_DESC',
  FeeAftermarket_9ProfitIndicatorAsc = 'FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  FeeAftermarket_9ProfitIndicatorDesc = 'FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  FeeAftermarket_10NameAsc = 'FEE_AFTERMARKET_10_NAME_ASC',
  FeeAftermarket_10NameDesc = 'FEE_AFTERMARKET_10_NAME_DESC',
  FeeAftermarket_10SaleAsc = 'FEE_AFTERMARKET_10_SALE_ASC',
  FeeAftermarket_10SaleDesc = 'FEE_AFTERMARKET_10_SALE_DESC',
  FeeAftermarket_10CostAsc = 'FEE_AFTERMARKET_10_COST_ASC',
  FeeAftermarket_10CostDesc = 'FEE_AFTERMARKET_10_COST_DESC',
  FeeAftermarket_10ProfitIndicatorAsc = 'FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  FeeAftermarket_10ProfitIndicatorDesc = 'FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  FeeAftermarket_11NameAsc = 'FEE_AFTERMARKET_11_NAME_ASC',
  FeeAftermarket_11NameDesc = 'FEE_AFTERMARKET_11_NAME_DESC',
  FeeAftermarket_11SaleAsc = 'FEE_AFTERMARKET_11_SALE_ASC',
  FeeAftermarket_11SaleDesc = 'FEE_AFTERMARKET_11_SALE_DESC',
  FeeAftermarket_11CostAsc = 'FEE_AFTERMARKET_11_COST_ASC',
  FeeAftermarket_11CostDesc = 'FEE_AFTERMARKET_11_COST_DESC',
  FeeAftermarket_11ProfitIndicatorAsc = 'FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  FeeAftermarket_11ProfitIndicatorDesc = 'FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  FeeAftermarket_12NameAsc = 'FEE_AFTERMARKET_12_NAME_ASC',
  FeeAftermarket_12NameDesc = 'FEE_AFTERMARKET_12_NAME_DESC',
  FeeAftermarket_12SaleAsc = 'FEE_AFTERMARKET_12_SALE_ASC',
  FeeAftermarket_12SaleDesc = 'FEE_AFTERMARKET_12_SALE_DESC',
  FeeAftermarket_12CostAsc = 'FEE_AFTERMARKET_12_COST_ASC',
  FeeAftermarket_12CostDesc = 'FEE_AFTERMARKET_12_COST_DESC',
  FeeAftermarket_12ProfitIndicatorAsc = 'FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  FeeAftermarket_12ProfitIndicatorDesc = 'FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  FeeAftermarket_13NameAsc = 'FEE_AFTERMARKET_13_NAME_ASC',
  FeeAftermarket_13NameDesc = 'FEE_AFTERMARKET_13_NAME_DESC',
  FeeAftermarket_13SaleAsc = 'FEE_AFTERMARKET_13_SALE_ASC',
  FeeAftermarket_13SaleDesc = 'FEE_AFTERMARKET_13_SALE_DESC',
  FeeAftermarket_13CostAsc = 'FEE_AFTERMARKET_13_COST_ASC',
  FeeAftermarket_13CostDesc = 'FEE_AFTERMARKET_13_COST_DESC',
  FeeAftermarket_13ProfitIndicatorAsc = 'FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  FeeAftermarket_13ProfitIndicatorDesc = 'FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  FeeAftermarket_14NameAsc = 'FEE_AFTERMARKET_14_NAME_ASC',
  FeeAftermarket_14NameDesc = 'FEE_AFTERMARKET_14_NAME_DESC',
  FeeAftermarket_14SaleAsc = 'FEE_AFTERMARKET_14_SALE_ASC',
  FeeAftermarket_14SaleDesc = 'FEE_AFTERMARKET_14_SALE_DESC',
  FeeAftermarket_14CostAsc = 'FEE_AFTERMARKET_14_COST_ASC',
  FeeAftermarket_14CostDesc = 'FEE_AFTERMARKET_14_COST_DESC',
  FeeAftermarket_14ProfitIndicatorAsc = 'FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  FeeAftermarket_14ProfitIndicatorDesc = 'FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  FeeAftermarket_15NameAsc = 'FEE_AFTERMARKET_15_NAME_ASC',
  FeeAftermarket_15NameDesc = 'FEE_AFTERMARKET_15_NAME_DESC',
  FeeAftermarket_15SaleAsc = 'FEE_AFTERMARKET_15_SALE_ASC',
  FeeAftermarket_15SaleDesc = 'FEE_AFTERMARKET_15_SALE_DESC',
  FeeAftermarket_15CostAsc = 'FEE_AFTERMARKET_15_COST_ASC',
  FeeAftermarket_15CostDesc = 'FEE_AFTERMARKET_15_COST_DESC',
  FeeAftermarket_15ProfitIndicatorAsc = 'FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  FeeAftermarket_15ProfitIndicatorDesc = 'FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  FeeAftermarket_16NameAsc = 'FEE_AFTERMARKET_16_NAME_ASC',
  FeeAftermarket_16NameDesc = 'FEE_AFTERMARKET_16_NAME_DESC',
  FeeAftermarket_16SaleAsc = 'FEE_AFTERMARKET_16_SALE_ASC',
  FeeAftermarket_16SaleDesc = 'FEE_AFTERMARKET_16_SALE_DESC',
  FeeAftermarket_16CostAsc = 'FEE_AFTERMARKET_16_COST_ASC',
  FeeAftermarket_16CostDesc = 'FEE_AFTERMARKET_16_COST_DESC',
  FeeAftermarket_16ProfitIndicatorAsc = 'FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  FeeAftermarket_16ProfitIndicatorDesc = 'FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  FeeAftermarket_17NameAsc = 'FEE_AFTERMARKET_17_NAME_ASC',
  FeeAftermarket_17NameDesc = 'FEE_AFTERMARKET_17_NAME_DESC',
  FeeAftermarket_17SaleAsc = 'FEE_AFTERMARKET_17_SALE_ASC',
  FeeAftermarket_17SaleDesc = 'FEE_AFTERMARKET_17_SALE_DESC',
  FeeAftermarket_17CostAsc = 'FEE_AFTERMARKET_17_COST_ASC',
  FeeAftermarket_17CostDesc = 'FEE_AFTERMARKET_17_COST_DESC',
  FeeAftermarket_17ProfitIndicatorAsc = 'FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  FeeAftermarket_17ProfitIndicatorDesc = 'FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  FeeAftermarket_18NameAsc = 'FEE_AFTERMARKET_18_NAME_ASC',
  FeeAftermarket_18NameDesc = 'FEE_AFTERMARKET_18_NAME_DESC',
  FeeAftermarket_18SaleAsc = 'FEE_AFTERMARKET_18_SALE_ASC',
  FeeAftermarket_18SaleDesc = 'FEE_AFTERMARKET_18_SALE_DESC',
  FeeAftermarket_18CostAsc = 'FEE_AFTERMARKET_18_COST_ASC',
  FeeAftermarket_18CostDesc = 'FEE_AFTERMARKET_18_COST_DESC',
  FeeAftermarket_18ProfitIndicatorAsc = 'FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  FeeAftermarket_18ProfitIndicatorDesc = 'FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  FeeAftermarket_19NameAsc = 'FEE_AFTERMARKET_19_NAME_ASC',
  FeeAftermarket_19NameDesc = 'FEE_AFTERMARKET_19_NAME_DESC',
  FeeAftermarket_19SaleAsc = 'FEE_AFTERMARKET_19_SALE_ASC',
  FeeAftermarket_19SaleDesc = 'FEE_AFTERMARKET_19_SALE_DESC',
  FeeAftermarket_19CostAsc = 'FEE_AFTERMARKET_19_COST_ASC',
  FeeAftermarket_19CostDesc = 'FEE_AFTERMARKET_19_COST_DESC',
  FeeAftermarket_19ProfitIndicatorAsc = 'FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  FeeAftermarket_19ProfitIndicatorDesc = 'FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  FeeAftermarket_20NameAsc = 'FEE_AFTERMARKET_20_NAME_ASC',
  FeeAftermarket_20NameDesc = 'FEE_AFTERMARKET_20_NAME_DESC',
  FeeAftermarket_20SaleAsc = 'FEE_AFTERMARKET_20_SALE_ASC',
  FeeAftermarket_20SaleDesc = 'FEE_AFTERMARKET_20_SALE_DESC',
  FeeAftermarket_20CostAsc = 'FEE_AFTERMARKET_20_COST_ASC',
  FeeAftermarket_20CostDesc = 'FEE_AFTERMARKET_20_COST_DESC',
  FeeAftermarket_20ProfitIndicatorAsc = 'FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  FeeAftermarket_20ProfitIndicatorDesc = 'FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  Insurance_1TypeAsc = 'INSURANCE_1_TYPE_ASC',
  Insurance_1TypeDesc = 'INSURANCE_1_TYPE_DESC',
  Insurance_1NameAsc = 'INSURANCE_1_NAME_ASC',
  Insurance_1NameDesc = 'INSURANCE_1_NAME_DESC',
  Insurance_1SaleAsc = 'INSURANCE_1_SALE_ASC',
  Insurance_1SaleDesc = 'INSURANCE_1_SALE_DESC',
  Insurance_1CostAsc = 'INSURANCE_1_COST_ASC',
  Insurance_1CostDesc = 'INSURANCE_1_COST_DESC',
  Insurance_1TermAsc = 'INSURANCE_1_TERM_ASC',
  Insurance_1TermDesc = 'INSURANCE_1_TERM_DESC',
  Insurance_2TypeAsc = 'INSURANCE_2_TYPE_ASC',
  Insurance_2TypeDesc = 'INSURANCE_2_TYPE_DESC',
  Insurance_2NameAsc = 'INSURANCE_2_NAME_ASC',
  Insurance_2NameDesc = 'INSURANCE_2_NAME_DESC',
  Insurance_2SaleAsc = 'INSURANCE_2_SALE_ASC',
  Insurance_2SaleDesc = 'INSURANCE_2_SALE_DESC',
  Insurance_2CostAsc = 'INSURANCE_2_COST_ASC',
  Insurance_2CostDesc = 'INSURANCE_2_COST_DESC',
  Insurance_2TermAsc = 'INSURANCE_2_TERM_ASC',
  Insurance_2TermDesc = 'INSURANCE_2_TERM_DESC',
  Insurance_3TypeAsc = 'INSURANCE_3_TYPE_ASC',
  Insurance_3TypeDesc = 'INSURANCE_3_TYPE_DESC',
  Insurance_3NameAsc = 'INSURANCE_3_NAME_ASC',
  Insurance_3NameDesc = 'INSURANCE_3_NAME_DESC',
  Insurance_3SaleAsc = 'INSURANCE_3_SALE_ASC',
  Insurance_3SaleDesc = 'INSURANCE_3_SALE_DESC',
  Insurance_3CostAsc = 'INSURANCE_3_COST_ASC',
  Insurance_3CostDesc = 'INSURANCE_3_COST_DESC',
  Insurance_3TermAsc = 'INSURANCE_3_TERM_ASC',
  Insurance_3TermDesc = 'INSURANCE_3_TERM_DESC',
  AccidentalHealthTypeAsc = 'ACCIDENTAL_HEALTH_TYPE_ASC',
  AccidentalHealthTypeDesc = 'ACCIDENTAL_HEALTH_TYPE_DESC',
  AccidentalHealthNameAsc = 'ACCIDENTAL_HEALTH_NAME_ASC',
  AccidentalHealthNameDesc = 'ACCIDENTAL_HEALTH_NAME_DESC',
  AccidentalHealthSaleAsc = 'ACCIDENTAL_HEALTH_SALE_ASC',
  AccidentalHealthSaleDesc = 'ACCIDENTAL_HEALTH_SALE_DESC',
  AccidentalHealthCostAsc = 'ACCIDENTAL_HEALTH_COST_ASC',
  AccidentalHealthCostDesc = 'ACCIDENTAL_HEALTH_COST_DESC',
  AccidentalHealthTermAsc = 'ACCIDENTAL_HEALTH_TERM_ASC',
  AccidentalHealthTermDesc = 'ACCIDENTAL_HEALTH_TERM_DESC',
  CreditLifeTypeAsc = 'CREDIT_LIFE_TYPE_ASC',
  CreditLifeTypeDesc = 'CREDIT_LIFE_TYPE_DESC',
  CreditLifeNameAsc = 'CREDIT_LIFE_NAME_ASC',
  CreditLifeNameDesc = 'CREDIT_LIFE_NAME_DESC',
  CreditLifeSaleAsc = 'CREDIT_LIFE_SALE_ASC',
  CreditLifeSaleDesc = 'CREDIT_LIFE_SALE_DESC',
  CreditLifeCostAsc = 'CREDIT_LIFE_COST_ASC',
  CreditLifeCostDesc = 'CREDIT_LIFE_COST_DESC',
  CreditLifeTermAsc = 'CREDIT_LIFE_TERM_ASC',
  CreditLifeTermDesc = 'CREDIT_LIFE_TERM_DESC',
  LevelizedLifeTypeAsc = 'LEVELIZED_LIFE_TYPE_ASC',
  LevelizedLifeTypeDesc = 'LEVELIZED_LIFE_TYPE_DESC',
  LevelizedLifeNameAsc = 'LEVELIZED_LIFE_NAME_ASC',
  LevelizedLifeNameDesc = 'LEVELIZED_LIFE_NAME_DESC',
  LevelizedLifeSaleAsc = 'LEVELIZED_LIFE_SALE_ASC',
  LevelizedLifeSaleDesc = 'LEVELIZED_LIFE_SALE_DESC',
  LevelizedLifeCostAsc = 'LEVELIZED_LIFE_COST_ASC',
  LevelizedLifeCostDesc = 'LEVELIZED_LIFE_COST_DESC',
  LevelizedLifeTermAsc = 'LEVELIZED_LIFE_TERM_ASC',
  LevelizedLifeTermDesc = 'LEVELIZED_LIFE_TERM_DESC',
  LossOfEmploymentTypeAsc = 'LOSS_OF_EMPLOYMENT_TYPE_ASC',
  LossOfEmploymentTypeDesc = 'LOSS_OF_EMPLOYMENT_TYPE_DESC',
  LossOfEmploymentNameAsc = 'LOSS_OF_EMPLOYMENT_NAME_ASC',
  LossOfEmploymentNameDesc = 'LOSS_OF_EMPLOYMENT_NAME_DESC',
  LossOfEmploymentSaleAsc = 'LOSS_OF_EMPLOYMENT_SALE_ASC',
  LossOfEmploymentSaleDesc = 'LOSS_OF_EMPLOYMENT_SALE_DESC',
  LossOfEmploymentCostAsc = 'LOSS_OF_EMPLOYMENT_COST_ASC',
  LossOfEmploymentCostDesc = 'LOSS_OF_EMPLOYMENT_COST_DESC',
  LossOfEmploymentTermAsc = 'LOSS_OF_EMPLOYMENT_TERM_ASC',
  LossOfEmploymentTermDesc = 'LOSS_OF_EMPLOYMENT_TERM_DESC',
  GuaranteedAutoProtectionTypeAsc = 'GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  GuaranteedAutoProtectionTypeDesc = 'GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  GuaranteedAutoProtectionNameAsc = 'GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  GuaranteedAutoProtectionNameDesc = 'GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  GuaranteedAutoProtectionSaleAsc = 'GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  GuaranteedAutoProtectionSaleDesc = 'GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  GuaranteedAutoProtectionCostAsc = 'GUARANTEED_AUTO_PROTECTION_COST_ASC',
  GuaranteedAutoProtectionCostDesc = 'GUARANTEED_AUTO_PROTECTION_COST_DESC',
  GuaranteedAutoProtectionTermAsc = 'GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  GuaranteedAutoProtectionTermDesc = 'GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  SaleCommentsAsc = 'SALE_COMMENTS_ASC',
  SaleCommentsDesc = 'SALE_COMMENTS_DESC',
  CassStdLine1Asc = 'CASS_STD_LINE1_ASC',
  CassStdLine1Desc = 'CASS_STD_LINE1_DESC',
  CassStdLine2Asc = 'CASS_STD_LINE2_ASC',
  CassStdLine2Desc = 'CASS_STD_LINE2_DESC',
  CassStdCityAsc = 'CASS_STD_CITY_ASC',
  CassStdCityDesc = 'CASS_STD_CITY_DESC',
  CassStdStateAsc = 'CASS_STD_STATE_ASC',
  CassStdStateDesc = 'CASS_STD_STATE_DESC',
  CassStdZipAsc = 'CASS_STD_ZIP_ASC',
  CassStdZipDesc = 'CASS_STD_ZIP_DESC',
  CassStdZip4Asc = 'CASS_STD_ZIP4_ASC',
  CassStdZip4Desc = 'CASS_STD_ZIP4_DESC',
  CassStdDpbcAsc = 'CASS_STD_DPBC_ASC',
  CassStdDpbcDesc = 'CASS_STD_DPBC_DESC',
  CassStdChkdgtAsc = 'CASS_STD_CHKDGT_ASC',
  CassStdChkdgtDesc = 'CASS_STD_CHKDGT_DESC',
  CassStdCartAsc = 'CASS_STD_CART_ASC',
  CassStdCartDesc = 'CASS_STD_CART_DESC',
  CassStdLotAsc = 'CASS_STD_LOT_ASC',
  CassStdLotDesc = 'CASS_STD_LOT_DESC',
  CassStdLotordAsc = 'CASS_STD_LOTORD_ASC',
  CassStdLotordDesc = 'CASS_STD_LOTORD_DESC',
  CassStdUrbAsc = 'CASS_STD_URB_ASC',
  CassStdUrbDesc = 'CASS_STD_URB_DESC',
  CassStdFipsAsc = 'CASS_STD_FIPS_ASC',
  CassStdFipsDesc = 'CASS_STD_FIPS_DESC',
  CassStdEwsAsc = 'CASS_STD_EWS_ASC',
  CassStdEwsDesc = 'CASS_STD_EWS_DESC',
  CassStdLacsAsc = 'CASS_STD_LACS_ASC',
  CassStdLacsDesc = 'CASS_STD_LACS_DESC',
  CassStdZipmovAsc = 'CASS_STD_ZIPMOV_ASC',
  CassStdZipmovDesc = 'CASS_STD_ZIPMOV_DESC',
  CassStdZ4LomAsc = 'CASS_STD_Z4LOM_ASC',
  CassStdZ4LomDesc = 'CASS_STD_Z4LOM_DESC',
  CassStdNdiaptAsc = 'CASS_STD_NDIAPT_ASC',
  CassStdNdiaptDesc = 'CASS_STD_NDIAPT_DESC',
  CassStdNdirrAsc = 'CASS_STD_NDIRR_ASC',
  CassStdNdirrDesc = 'CASS_STD_NDIRR_DESC',
  CassStdLacsrtAsc = 'CASS_STD_LACSRT_ASC',
  CassStdLacsrtDesc = 'CASS_STD_LACSRT_DESC',
  CassStdErrorCdAsc = 'CASS_STD_ERROR_CD_ASC',
  CassStdErrorCdDesc = 'CASS_STD_ERROR_CD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `deleteAnalyticByNodeId` mutation. */
export type DeleteAnalyticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Analytic` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAnalytic` mutation. */
export type DeleteAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Analytic` mutation. */
export type DeleteAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Analytic` that was deleted by this mutation. */
  analytic?: Maybe<Analytic>;
  deletedAnalyticNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Analytic`. */
  company?: Maybe<Company>;
  /** An edge for our `Analytic`. May be used by Relay 1. */
  analyticEdge?: Maybe<AnalyticsEdge>;
};


/** The output of our delete `Analytic` mutation. */
export type DeleteAnalyticPayloadAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsOrderBy>>;
};

/** All input for the `deleteAnalyticsCtaByNodeId` mutation. */
export type DeleteAnalyticsCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AnalyticsCta` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAnalyticsCta` mutation. */
export type DeleteAnalyticsCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  eventId: Scalars['Int'];
};

/** The output of our delete `AnalyticsCta` mutation. */
export type DeleteAnalyticsCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AnalyticsCta` that was deleted by this mutation. */
  analyticsCta?: Maybe<AnalyticsCta>;
  deletedAnalyticsCtaNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AnalyticsCta`. May be used by Relay 1. */
  analyticsCtaEdge?: Maybe<AnalyticsCtasEdge>;
};


/** The output of our delete `AnalyticsCta` mutation. */
export type DeleteAnalyticsCtaPayloadAnalyticsCtaEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsCtasOrderBy>>;
};

/** All input for the `deleteAppointmentByNodeId` mutation. */
export type DeleteAppointmentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Appointment` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAppointment` mutation. */
export type DeleteAppointmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Appointment` mutation. */
export type DeleteAppointmentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Appointment` that was deleted by this mutation. */
  appointment?: Maybe<Appointment>;
  deletedAppointmentNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `Appointment`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `Appointment`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `Appointment`. May be used by Relay 1. */
  appointmentEdge?: Maybe<AppointmentsEdge>;
};


/** The output of our delete `Appointment` mutation. */
export type DeleteAppointmentPayloadAppointmentEdgeArgs = {
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
};

/** All input for the `deleteBillableMetricLogByNodeId` mutation. */
export type DeleteBillableMetricLogByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BillableMetricLog` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBillableMetricLog` mutation. */
export type DeleteBillableMetricLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `BillableMetricLog` mutation. */
export type DeleteBillableMetricLogPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BillableMetricLog` that was deleted by this mutation. */
  billableMetricLog?: Maybe<BillableMetricLog>;
  deletedBillableMetricLogNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `BillableMetricLog`. May be used by Relay 1. */
  billableMetricLogEdge?: Maybe<BillableMetricLogsEdge>;
};


/** The output of our delete `BillableMetricLog` mutation. */
export type DeleteBillableMetricLogPayloadBillableMetricLogEdgeArgs = {
  orderBy?: Maybe<Array<BillableMetricLogsOrderBy>>;
};

/** All input for the `deleteChannelByName` mutation. */
export type DeleteChannelByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** All input for the `deleteChannelByNodeId` mutation. */
export type DeleteChannelByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Channel` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteChannel` mutation. */
export type DeleteChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Channel` mutation. */
export type DeleteChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Channel` that was deleted by this mutation. */
  channel?: Maybe<Channel>;
  deletedChannelNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge?: Maybe<ChannelsEdge>;
};


/** The output of our delete `Channel` mutation. */
export type DeleteChannelPayloadChannelEdgeArgs = {
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
};

/** All input for the `deleteCompanyAnalyticByNodeId` mutation. */
export type DeleteCompanyAnalyticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyAnalytic` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyAnalytic` mutation. */
export type DeleteCompanyAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyAnalytic` mutation. */
export type DeleteCompanyAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyAnalytic` that was deleted by this mutation. */
  companyAnalytic?: Maybe<CompanyAnalytic>;
  deletedCompanyAnalyticNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyAnalytic`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyAnalytic`. May be used by Relay 1. */
  companyAnalyticEdge?: Maybe<CompanyAnalyticsEdge>;
};


/** The output of our delete `CompanyAnalytic` mutation. */
export type DeleteCompanyAnalyticPayloadCompanyAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<CompanyAnalyticsOrderBy>>;
};

/** All input for the `deleteCompanyChannel` mutation. */
export type DeleteCompanyChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** All input for the `deleteCompanyChannelMediaByCompanyChannelIdAndMediaId` mutation. */
export type DeleteCompanyChannelMediaByCompanyChannelIdAndMediaIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyChannelId: Scalars['Int'];
  mediaId: Scalars['Int'];
};

/** All input for the `deleteCompanyChannelMediaByNodeId` mutation. */
export type DeleteCompanyChannelMediaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyChannelMedia` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyChannelMedia` mutation. */
export type DeleteCompanyChannelMediaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyChannelMedia` mutation. */
export type DeleteCompanyChannelMediaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyChannelMedia` that was deleted by this mutation. */
  companyChannelMedia?: Maybe<CompanyChannelMedia>;
  deletedCompanyChannelMediaNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CompanyChannel` that is related to this `CompanyChannelMedia`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `Media` that is related to this `CompanyChannelMedia`. */
  media?: Maybe<Media>;
  /** Reads a single `User` that is related to this `CompanyChannelMedia`. */
  assignee?: Maybe<User>;
  /** An edge for our `CompanyChannelMedia`. May be used by Relay 1. */
  companyChannelMediaEdge?: Maybe<CompanyChannelMediaEdge>;
};


/** The output of our delete `CompanyChannelMedia` mutation. */
export type DeleteCompanyChannelMediaPayloadCompanyChannelMediaEdgeArgs = {
  orderBy?: Maybe<Array<CompanyChannelMediaOrderBy>>;
};

/** The output of our `deleteCompanyChannel` mutation. */
export type DeleteCompanyChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyChannel?: Maybe<CompanyChannel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Channel` that is related to this `CompanyChannel`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Company` that is related to this `CompanyChannel`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyChannel`. May be used by Relay 1. */
  companyChannelEdge?: Maybe<CompanyChannelsEdge>;
};


/** The output of our `deleteCompanyChannel` mutation. */
export type DeleteCompanyChannelPayloadCompanyChannelEdgeArgs = {
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
};

/** All input for the `deleteCompanyCtaByNodeId` mutation. */
export type DeleteCompanyCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyCta` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyCta` mutation. */
export type DeleteCompanyCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyCta` mutation. */
export type DeleteCompanyCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyCta` that was deleted by this mutation. */
  companyCta?: Maybe<CompanyCta>;
  deletedCompanyCtaNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CompanyCta`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Group` that is related to this `CompanyCta`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyCta`. May be used by Relay 1. */
  companyCtaEdge?: Maybe<CompanyCtasEdge>;
};


/** The output of our delete `CompanyCta` mutation. */
export type DeleteCompanyCtaPayloadCompanyCtaEdgeArgs = {
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
};

/** All input for the `deleteCompanyDomainByDomain` mutation. */
export type DeleteCompanyDomainByDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  domain: Scalars['String'];
};

/** All input for the `deleteCompanyDomainByNodeId` mutation. */
export type DeleteCompanyDomainByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomain` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyDomain` mutation. */
export type DeleteCompanyDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyDomain` mutation. */
export type DeleteCompanyDomainPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomain` that was deleted by this mutation. */
  companyDomain?: Maybe<CompanyDomain>;
  deletedCompanyDomainNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomain`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyDomain`. May be used by Relay 1. */
  companyDomainEdge?: Maybe<CompanyDomainsEdge>;
};


/** The output of our delete `CompanyDomain` mutation. */
export type DeleteCompanyDomainPayloadCompanyDomainEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
};

/** All input for the `deleteCompanyDomainsApprovedByNodeId` mutation. */
export type DeleteCompanyDomainsApprovedByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomainsApproved` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyDomainsApproved` mutation. */
export type DeleteCompanyDomainsApprovedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** The output of our delete `CompanyDomainsApproved` mutation. */
export type DeleteCompanyDomainsApprovedPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsApproved` that was deleted by this mutation. */
  companyDomainsApproved?: Maybe<CompanyDomainsApproved>;
  deletedCompanyDomainsApprovedNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsApproved`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsApproved`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsApproved`. May be used by Relay 1. */
  companyDomainsApprovedEdge?: Maybe<CompanyDomainsApprovedsEdge>;
};


/** The output of our delete `CompanyDomainsApproved` mutation. */
export type DeleteCompanyDomainsApprovedPayloadCompanyDomainsApprovedEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
};

/** All input for the `deleteCompanyDomainsPathByDomainIdAndPath` mutation. */
export type DeleteCompanyDomainsPathByDomainIdAndPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** All input for the `deleteCompanyDomainsPathByNodeId` mutation. */
export type DeleteCompanyDomainsPathByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomainsPath` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyDomainsPath` mutation. */
export type DeleteCompanyDomainsPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyDomainsPath` mutation. */
export type DeleteCompanyDomainsPathPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsPath` that was deleted by this mutation. */
  companyDomainsPath?: Maybe<CompanyDomainsPath>;
  deletedCompanyDomainsPathNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsPath`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsPath`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsPath`. May be used by Relay 1. */
  companyDomainsPathEdge?: Maybe<CompanyDomainsPathsEdge>;
};


/** The output of our delete `CompanyDomainsPath` mutation. */
export type DeleteCompanyDomainsPathPayloadCompanyDomainsPathEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
};

/** All input for the `deleteCompanyGroupByNodeId` mutation. */
export type DeleteCompanyGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyGroup` mutation. */
export type DeleteCompanyGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** The output of our delete `CompanyGroup` mutation. */
export type DeleteCompanyGroupPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyGroup` that was deleted by this mutation. */
  companyGroup?: Maybe<CompanyGroup>;
  deletedCompanyGroupNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyGroup`. */
  company?: Maybe<Company>;
  /** Reads a single `Group` that is related to this `CompanyGroup`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyGroup`. May be used by Relay 1. */
  companyGroupEdge?: Maybe<CompanyGroupsEdge>;
};


/** The output of our delete `CompanyGroup` mutation. */
export type DeleteCompanyGroupPayloadCompanyGroupEdgeArgs = {
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
};

/** All input for the `deleteCompanyIntegrationByNodeId` mutation. */
export type DeleteCompanyIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyIntegration` mutation. */
export type DeleteCompanyIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyIntegration` mutation. */
export type DeleteCompanyIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyIntegration` that was deleted by this mutation. */
  companyIntegration?: Maybe<CompanyIntegration>;
  deletedCompanyIntegrationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `CompanyIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Company` that is related to this `CompanyIntegration`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyIntegration`. May be used by Relay 1. */
  companyIntegrationEdge?: Maybe<CompanyIntegrationsEdge>;
};


/** The output of our delete `CompanyIntegration` mutation. */
export type DeleteCompanyIntegrationPayloadCompanyIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
};

/** All input for the `deleteCompanyLocationByNodeId` mutation. */
export type DeleteCompanyLocationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyLocation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyLocation` mutation. */
export type DeleteCompanyLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyLocation` mutation. */
export type DeleteCompanyLocationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyLocation` that was deleted by this mutation. */
  companyLocation?: Maybe<CompanyLocation>;
  deletedCompanyLocationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyLocation`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyLocation`. May be used by Relay 1. */
  companyLocationEdge?: Maybe<CompanyLocationsEdge>;
};


/** The output of our delete `CompanyLocation` mutation. */
export type DeleteCompanyLocationPayloadCompanyLocationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyLocationsOrderBy>>;
};

/** All input for the `deleteCompanyProductByNodeId` mutation. */
export type DeleteCompanyProductByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyProduct` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyProduct` mutation. */
export type DeleteCompanyProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** All input for the `deleteCompanyProductIntegrationByNodeId` mutation. */
export type DeleteCompanyProductIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyProductIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyProductIntegration` mutation. */
export type DeleteCompanyProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyProductIntegration` mutation. */
export type DeleteCompanyProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProductIntegration` that was deleted by this mutation. */
  companyProductIntegration?: Maybe<CompanyProductIntegration>;
  deletedCompanyProductIntegrationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CompanyIntegration` that is related to this `CompanyProductIntegration`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `CompanyProduct` that is related to this `CompanyProductIntegration`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** An edge for our `CompanyProductIntegration`. May be used by Relay 1. */
  companyProductIntegrationEdge?: Maybe<CompanyProductIntegrationsEdge>;
};


/** The output of our delete `CompanyProductIntegration` mutation. */
export type DeleteCompanyProductIntegrationPayloadCompanyProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
};

/** The output of our delete `CompanyProduct` mutation. */
export type DeleteCompanyProductPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProduct` that was deleted by this mutation. */
  companyProduct?: Maybe<CompanyProduct>;
  deletedCompanyProductNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyProduct`. */
  company?: Maybe<Company>;
  /** Reads a single `Product` that is related to this `CompanyProduct`. */
  product?: Maybe<Product>;
  /** An edge for our `CompanyProduct`. May be used by Relay 1. */
  companyProductEdge?: Maybe<CompanyProductsEdge>;
};


/** The output of our delete `CompanyProduct` mutation. */
export type DeleteCompanyProductPayloadCompanyProductEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
};

/** All input for the `deleteCompanyWidgetByNodeId` mutation. */
export type DeleteCompanyWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyWidget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyWidget` mutation. */
export type DeleteCompanyWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/** The output of our delete `CompanyWidget` mutation. */
export type DeleteCompanyWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyWidget` that was deleted by this mutation. */
  companyWidget?: Maybe<CompanyWidget>;
  deletedCompanyWidgetNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `CompanyWidget`. */
  group?: Maybe<Group>;
  /** Reads a single `Widget` that is related to this `CompanyWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CompanyWidget`. May be used by Relay 1. */
  companyWidgetEdge?: Maybe<CompanyWidgetsEdge>;
};


/** The output of our delete `CompanyWidget` mutation. */
export type DeleteCompanyWidgetPayloadCompanyWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
};

/** All input for the `deleteCtaByNodeId` mutation. */
export type DeleteCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Cta` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCtaByProductAndTypeAndVersion` mutation. */
export type DeleteCtaByProductAndTypeAndVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

/** All input for the `deleteCta` mutation. */
export type DeleteCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** The output of our delete `Cta` mutation. */
export type DeleteCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Cta` that was deleted by this mutation. */
  cta?: Maybe<Cta>;
  deletedCtaNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Cta`. May be used by Relay 1. */
  ctaEdge?: Maybe<CtasEdge>;
};


/** The output of our delete `Cta` mutation. */
export type DeleteCtaPayloadCtaEdgeArgs = {
  orderBy?: Maybe<Array<CtasOrderBy>>;
};

export type DeleteCtaSpecInput = {
  id: Scalars['String'];
};

/** All input for the `deleteCtaWidgetByNodeId` mutation. */
export type DeleteCtaWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CtaWidget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCtaWidget` mutation. */
export type DeleteCtaWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CtaWidget` mutation. */
export type DeleteCtaWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CtaWidget` that was deleted by this mutation. */
  ctaWidget?: Maybe<CtaWidget>;
  deletedCtaWidgetNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CtaWidget`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Widget` that is related to this `CtaWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CtaWidget`. May be used by Relay 1. */
  ctaWidgetEdge?: Maybe<CtaWidgetsEdge>;
};


/** The output of our delete `CtaWidget` mutation. */
export type DeleteCtaWidgetPayloadCtaWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
};

/** All input for the `deleteDealByNodeId` mutation. */
export type DeleteDealByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Deal` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteDeal` mutation. */
export type DeleteDealInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Deal` mutation. */
export type DeleteDealPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Deal` that was deleted by this mutation. */
  deal?: Maybe<Deal>;
  deletedDealNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `Deal`. */
  lead?: Maybe<Lead>;
  /** Reads a single `CompanyIntegration` that is related to this `Deal`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** An edge for our `Deal`. May be used by Relay 1. */
  dealEdge?: Maybe<DealsEdge>;
};


/** The output of our delete `Deal` mutation. */
export type DeleteDealPayloadDealEdgeArgs = {
  orderBy?: Maybe<Array<DealsOrderBy>>;
};

/** All input for the `deleteDeliveryMethodByNodeId` mutation. */
export type DeleteDeliveryMethodByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DeliveryMethod` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteDeliveryMethod` mutation. */
export type DeleteDeliveryMethodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `DeliveryMethod` mutation. */
export type DeleteDeliveryMethodPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DeliveryMethod` that was deleted by this mutation. */
  deliveryMethod?: Maybe<DeliveryMethod>;
  deletedDeliveryMethodNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `DeliveryMethod`. */
  group?: Maybe<Group>;
  /** An edge for our `DeliveryMethod`. May be used by Relay 1. */
  deliveryMethodEdge?: Maybe<DeliveryMethodsEdge>;
};


/** The output of our delete `DeliveryMethod` mutation. */
export type DeleteDeliveryMethodPayloadDeliveryMethodEdgeArgs = {
  orderBy?: Maybe<Array<DeliveryMethodsOrderBy>>;
};

/** All input for the `deleteDistributorRelationshipByDistributorCompanyIdAndCompanyId` mutation. */
export type DeleteDistributorRelationshipByDistributorCompanyIdAndCompanyIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  distributorCompanyId: Scalars['Int'];
  companyId: Scalars['Int'];
};

/** All input for the `deleteDistributorRelationshipByNodeId` mutation. */
export type DeleteDistributorRelationshipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DistributorRelationship` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteDistributorRelationship` mutation. */
export type DeleteDistributorRelationshipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `DistributorRelationship` mutation. */
export type DeleteDistributorRelationshipPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DistributorRelationship` that was deleted by this mutation. */
  distributorRelationship?: Maybe<DistributorRelationship>;
  deletedDistributorRelationshipNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `DistributorRelationship`. */
  distributorCompany?: Maybe<Company>;
  /** Reads a single `Company` that is related to this `DistributorRelationship`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyChannel` that is related to this `DistributorRelationship`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** An edge for our `DistributorRelationship`. May be used by Relay 1. */
  distributorRelationshipEdge?: Maybe<DistributorRelationshipsEdge>;
};


/** The output of our delete `DistributorRelationship` mutation. */
export type DeleteDistributorRelationshipPayloadDistributorRelationshipEdgeArgs = {
  orderBy?: Maybe<Array<DistributorRelationshipsOrderBy>>;
};

/** All input for the `deleteEmailTemplateByNodeId` mutation. */
export type DeleteEmailTemplateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EmailTemplate` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEmailTemplate` mutation. */
export type DeleteEmailTemplateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** The output of our delete `EmailTemplate` mutation. */
export type DeleteEmailTemplatePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmailTemplate` that was deleted by this mutation. */
  emailTemplate?: Maybe<EmailTemplate>;
  deletedEmailTemplateNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `EmailTemplate`. May be used by Relay 1. */
  emailTemplateEdge?: Maybe<EmailTemplatesEdge>;
};


/** The output of our delete `EmailTemplate` mutation. */
export type DeleteEmailTemplatePayloadEmailTemplateEdgeArgs = {
  orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
};

/** All input for the `deleteEquifaxLenderByEquifaxIdAndName` mutation. */
export type DeleteEquifaxLenderByEquifaxIdAndNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  equifaxId: Scalars['String'];
  name: Scalars['String'];
};

/** All input for the `deleteEquifaxLenderByNodeId` mutation. */
export type DeleteEquifaxLenderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EquifaxLender` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEquifaxLender` mutation. */
export type DeleteEquifaxLenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `EquifaxLender` mutation. */
export type DeleteEquifaxLenderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquifaxLender` that was deleted by this mutation. */
  equifaxLender?: Maybe<EquifaxLender>;
  deletedEquifaxLenderNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lender` that is related to this `EquifaxLender`. */
  lender?: Maybe<Lender>;
  /** An edge for our `EquifaxLender`. May be used by Relay 1. */
  equifaxLenderEdge?: Maybe<EquifaxLendersEdge>;
};


/** The output of our delete `EquifaxLender` mutation. */
export type DeleteEquifaxLenderPayloadEquifaxLenderEdgeArgs = {
  orderBy?: Maybe<Array<EquifaxLendersOrderBy>>;
};

/** All input for the `deleteEventByNodeId` mutation. */
export type DeleteEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Event` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEvent` mutation. */
export type DeleteEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Event` mutation. */
export type DeleteEventPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` that was deleted by this mutation. */
  event?: Maybe<Event>;
  deletedEventNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `Event`. */
  lead?: Maybe<Lead>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
};


/** The output of our delete `Event` mutation. */
export type DeleteEventPayloadEventEdgeArgs = {
  orderBy?: Maybe<Array<EventsOrderBy>>;
};

/** All input for the `deleteFieldByCompanyChannelIdAndConfig` mutation. */
export type DeleteFieldByCompanyChannelIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `deleteFieldByCompanyIntegrationIdAndConfig` mutation. */
export type DeleteFieldByCompanyIntegrationIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `deleteFieldByCompanyProductIdAndConfig` mutation. */
export type DeleteFieldByCompanyProductIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `deleteFieldByNodeId` mutation. */
export type DeleteFieldByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Field` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFieldConfigByNodeId` mutation. */
export type DeleteFieldConfigByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FieldConfig` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFieldConfigByParentIdAndParentTypeAndKey` mutation. */
export type DeleteFieldConfigByParentIdAndParentTypeAndKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  parentId: Scalars['Int'];
  parentType: Scalars['String'];
  key: Scalars['String'];
};

/** All input for the `deleteFieldConfig` mutation. */
export type DeleteFieldConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `FieldConfig` mutation. */
export type DeleteFieldConfigPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldConfig` that was deleted by this mutation. */
  fieldConfig?: Maybe<FieldConfig>;
  deletedFieldConfigNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `FieldConfig`. May be used by Relay 1. */
  fieldConfigEdge?: Maybe<FieldConfigsEdge>;
};


/** The output of our delete `FieldConfig` mutation. */
export type DeleteFieldConfigPayloadFieldConfigEdgeArgs = {
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
};

/** All input for the `deleteField` mutation. */
export type DeleteFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** All input for the `deleteFieldKeyByKey` mutation. */
export type DeleteFieldKeyByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  key: Scalars['String'];
};

/** All input for the `deleteFieldKeyByNodeId` mutation. */
export type DeleteFieldKeyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FieldKey` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFieldKey` mutation. */
export type DeleteFieldKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `FieldKey` mutation. */
export type DeleteFieldKeyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldKey` that was deleted by this mutation. */
  fieldKey?: Maybe<FieldKey>;
  deletedFieldKeyNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `FieldKey`. May be used by Relay 1. */
  fieldKeyEdge?: Maybe<FieldKeysEdge>;
};


/** The output of our delete `FieldKey` mutation. */
export type DeleteFieldKeyPayloadFieldKeyEdgeArgs = {
  orderBy?: Maybe<Array<FieldKeysOrderBy>>;
};

/** The output of our delete `Field` mutation. */
export type DeleteFieldPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Field` that was deleted by this mutation. */
  field?: Maybe<Field>;
  deletedFieldNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Field`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyProduct` that is related to this `Field`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Reads a single `CompanyChannel` that is related to this `Field`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `CompanyIntegration` that is related to this `Field`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `FieldConfig` that is related to this `Field`. */
  fieldConfigByConfig?: Maybe<FieldConfig>;
  /** An edge for our `Field`. May be used by Relay 1. */
  fieldEdge?: Maybe<FieldsEdge>;
};


/** The output of our delete `Field` mutation. */
export type DeleteFieldPayloadFieldEdgeArgs = {
  orderBy?: Maybe<Array<FieldsOrderBy>>;
};

/** All input for the `deleteFileByNodeId` mutation. */
export type DeleteFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `File` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFile` mutation. */
export type DeleteFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `File` mutation. */
export type DeleteFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `File` that was deleted by this mutation. */
  file?: Maybe<File>;
  deletedFileNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderProgram` that is related to this `File`. */
  lenderProgram?: Maybe<LenderProgram>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
};


/** The output of our delete `File` mutation. */
export type DeleteFilePayloadFileEdgeArgs = {
  orderBy?: Maybe<Array<FilesOrderBy>>;
};

/** All input for the `deleteIdInquiryByNodeId` mutation. */
export type DeleteIdInquiryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdInquiry` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIdInquiry` mutation. */
export type DeleteIdInquiryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `IdInquiry` mutation. */
export type DeleteIdInquiryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiry` that was deleted by this mutation. */
  idInquiry?: Maybe<IdInquiry>;
  deletedIdInquiryNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IdInquiry`. */
  lead?: Maybe<Lead>;
  /** Reads a single `Integration` that is related to this `IdInquiry`. */
  integration?: Maybe<Integration>;
  /** An edge for our `IdInquiry`. May be used by Relay 1. */
  idInquiryEdge?: Maybe<IdInquiriesEdge>;
};


/** The output of our delete `IdInquiry` mutation. */
export type DeleteIdInquiryPayloadIdInquiryEdgeArgs = {
  orderBy?: Maybe<Array<IdInquiriesOrderBy>>;
};

/** All input for the `deleteIdInquiryVerificationByNodeId` mutation. */
export type DeleteIdInquiryVerificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdInquiryVerification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIdInquiryVerificationCheckByNodeId` mutation. */
export type DeleteIdInquiryVerificationCheckByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdInquiryVerificationCheck` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIdInquiryVerificationCheck` mutation. */
export type DeleteIdInquiryVerificationCheckInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `IdInquiryVerificationCheck` mutation. */
export type DeleteIdInquiryVerificationCheckPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiryVerificationCheck` that was deleted by this mutation. */
  idInquiryVerificationCheck?: Maybe<IdInquiryVerificationCheck>;
  deletedIdInquiryVerificationCheckNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiryVerification` that is related to this `IdInquiryVerificationCheck`. */
  verification?: Maybe<IdInquiryVerification>;
  /** Reads a single `VerificationCheck` that is related to this `IdInquiryVerificationCheck`. */
  verificationCheck?: Maybe<VerificationCheck>;
  /** An edge for our `IdInquiryVerificationCheck`. May be used by Relay 1. */
  idInquiryVerificationCheckEdge?: Maybe<IdInquiryVerificationChecksEdge>;
};


/** The output of our delete `IdInquiryVerificationCheck` mutation. */
export type DeleteIdInquiryVerificationCheckPayloadIdInquiryVerificationCheckEdgeArgs = {
  orderBy?: Maybe<Array<IdInquiryVerificationChecksOrderBy>>;
};

/** All input for the `deleteIdInquiryVerification` mutation. */
export type DeleteIdInquiryVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `IdInquiryVerification` mutation. */
export type DeleteIdInquiryVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiryVerification` that was deleted by this mutation. */
  idInquiryVerification?: Maybe<IdInquiryVerification>;
  deletedIdInquiryVerificationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiry` that is related to this `IdInquiryVerification`. */
  inquiry?: Maybe<IdInquiry>;
  /** An edge for our `IdInquiryVerification`. May be used by Relay 1. */
  idInquiryVerificationEdge?: Maybe<IdInquiryVerificationsEdge>;
};


/** The output of our delete `IdInquiryVerification` mutation. */
export type DeleteIdInquiryVerificationPayloadIdInquiryVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IdInquiryVerificationsOrderBy>>;
};

/** All input for the `deleteIdMediaByNodeId` mutation. */
export type DeleteIdMediaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdMedia` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIdMedia` mutation. */
export type DeleteIdMediaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `IdMedia` mutation. */
export type DeleteIdMediaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdMedia` that was deleted by this mutation. */
  idMedia?: Maybe<IdMedia>;
  deletedIdMediaNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiryVerification` that is related to this `IdMedia`. */
  verification?: Maybe<IdInquiryVerification>;
  /** Reads a single `Media` that is related to this `IdMedia`. */
  media?: Maybe<Media>;
  /** An edge for our `IdMedia`. May be used by Relay 1. */
  idMediaEdge?: Maybe<IdMediaEdge>;
};


/** The output of our delete `IdMedia` mutation. */
export type DeleteIdMediaPayloadIdMediaEdgeArgs = {
  orderBy?: Maybe<Array<IdMediaOrderBy>>;
};

/** All input for the `deleteIdSessionByNodeId` mutation. */
export type DeleteIdSessionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdSession` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIdSession` mutation. */
export type DeleteIdSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `IdSession` mutation. */
export type DeleteIdSessionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdSession` that was deleted by this mutation. */
  idSession?: Maybe<IdSession>;
  deletedIdSessionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiry` that is related to this `IdSession`. */
  inquiry?: Maybe<IdInquiry>;
  /** An edge for our `IdSession`. May be used by Relay 1. */
  idSessionEdge?: Maybe<IdSessionsEdge>;
};


/** The output of our delete `IdSession` mutation. */
export type DeleteIdSessionPayloadIdSessionEdgeArgs = {
  orderBy?: Maybe<Array<IdSessionsOrderBy>>;
};

/** All input for the `deleteIdVerificationByLeadId` mutation. */
export type DeleteIdVerificationByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  leadId: Scalars['UUID'];
};

/** All input for the `deleteIdVerificationByNodeId` mutation. */
export type DeleteIdVerificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdVerification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIdVerification` mutation. */
export type DeleteIdVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `IdVerification` mutation. */
export type DeleteIdVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdVerification` that was deleted by this mutation. */
  idVerification?: Maybe<IdVerification>;
  deletedIdVerificationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IdVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IdVerification`. May be used by Relay 1. */
  idVerificationEdge?: Maybe<IdVerificationsEdge>;
};


/** The output of our delete `IdVerification` mutation. */
export type DeleteIdVerificationPayloadIdVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IdVerificationsOrderBy>>;
};

/** All input for the `deleteIncomeVerificationByLeadId` mutation. */
export type DeleteIncomeVerificationByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  leadId: Scalars['UUID'];
};

/** All input for the `deleteIncomeVerificationByNodeId` mutation. */
export type DeleteIncomeVerificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IncomeVerification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIncomeVerification` mutation. */
export type DeleteIncomeVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `IncomeVerification` mutation. */
export type DeleteIncomeVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IncomeVerification` that was deleted by this mutation. */
  incomeVerification?: Maybe<IncomeVerification>;
  deletedIncomeVerificationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IncomeVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IncomeVerification`. May be used by Relay 1. */
  incomeVerificationEdge?: Maybe<IncomeVerificationsEdge>;
};


/** The output of our delete `IncomeVerification` mutation. */
export type DeleteIncomeVerificationPayloadIncomeVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IncomeVerificationsOrderBy>>;
};

/** All input for the `deleteIntegrationByName` mutation. */
export type DeleteIntegrationByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** All input for the `deleteIntegrationByNodeId` mutation. */
export type DeleteIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Integration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIntegration` mutation. */
export type DeleteIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Integration` mutation. */
export type DeleteIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Integration` that was deleted by this mutation. */
  integration?: Maybe<Integration>;
  deletedIntegrationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Integration`. May be used by Relay 1. */
  integrationEdge?: Maybe<IntegrationsEdge>;
};


/** The output of our delete `Integration` mutation. */
export type DeleteIntegrationPayloadIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
};

/** All input for the `deleteLeadByNodeId` mutation. */
export type DeleteLeadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lead` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLeadCampaignByLeadId` mutation. */
export type DeleteLeadCampaignByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  leadId: Scalars['UUID'];
};

/** All input for the `deleteLeadCampaignByNodeId` mutation. */
export type DeleteLeadCampaignByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeadCampaign` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLeadCampaign` mutation. */
export type DeleteLeadCampaignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `LeadCampaign` mutation. */
export type DeleteLeadCampaignPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadCampaign` that was deleted by this mutation. */
  leadCampaign?: Maybe<LeadCampaign>;
  deletedLeadCampaignNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LeadCampaign`. */
  lead?: Maybe<Lead>;
  /** An edge for our `LeadCampaign`. May be used by Relay 1. */
  leadCampaignEdge?: Maybe<LeadCampaignsEdge>;
};


/** The output of our delete `LeadCampaign` mutation. */
export type DeleteLeadCampaignPayloadLeadCampaignEdgeArgs = {
  orderBy?: Maybe<Array<LeadCampaignsOrderBy>>;
};

/** All input for the `deleteLead` mutation. */
export type DeleteLeadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Lead` mutation. */
export type DeleteLeadPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` that was deleted by this mutation. */
  lead?: Maybe<Lead>;
  deletedLeadNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Lead`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyChannel` that is related to this `Lead`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `NeighborhoodView` that is related to this `Lead`. */
  neighborhoodView?: Maybe<NeighborhoodView>;
  /** An edge for our `Lead`. May be used by Relay 1. */
  leadEdge?: Maybe<LeadsEdge>;
};


/** The output of our delete `Lead` mutation. */
export type DeleteLeadPayloadLeadEdgeArgs = {
  orderBy?: Maybe<Array<LeadsOrderBy>>;
};

export type DeleteLeadResponse = {
  success: Scalars['Boolean'];
  leadId: Scalars['ID'];
};

/** All input for the `deleteLeadRoleByNodeId` mutation. */
export type DeleteLeadRoleByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeadRole` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLeadRole` mutation. */
export type DeleteLeadRoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `LeadRole` mutation. */
export type DeleteLeadRolePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadRole` that was deleted by this mutation. */
  leadRole?: Maybe<LeadRole>;
  deletedLeadRoleNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LeadRole`. */
  lead?: Maybe<Lead>;
  /** Reads a single `User` that is related to this `LeadRole`. */
  user?: Maybe<User>;
  /** An edge for our `LeadRole`. May be used by Relay 1. */
  leadRoleEdge?: Maybe<LeadRolesEdge>;
};


/** The output of our delete `LeadRole` mutation. */
export type DeleteLeadRolePayloadLeadRoleEdgeArgs = {
  orderBy?: Maybe<Array<LeadRolesOrderBy>>;
};

/** All input for the `deleteLenderAppByNodeId` mutation. */
export type DeleteLenderAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderApp` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLenderApp` mutation. */
export type DeleteLenderAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `LenderApp` mutation. */
export type DeleteLenderAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderApp` that was deleted by this mutation. */
  lenderApp?: Maybe<LenderApp>;
  deletedLenderAppNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderApp`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `LenderApp`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `LenderApp`. May be used by Relay 1. */
  lenderAppEdge?: Maybe<LenderAppsEdge>;
};


/** The output of our delete `LenderApp` mutation. */
export type DeleteLenderAppPayloadLenderAppEdgeArgs = {
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
};

/** All input for the `deleteLenderByName` mutation. */
export type DeleteLenderByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** All input for the `deleteLenderByNodeId` mutation. */
export type DeleteLenderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lender` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLenderCreditKeyByNodeId` mutation. */
export type DeleteLenderCreditKeyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderCreditKey` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLenderCreditKey` mutation. */
export type DeleteLenderCreditKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `LenderCreditKey` mutation. */
export type DeleteLenderCreditKeyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderCreditKey` that was deleted by this mutation. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  deletedLenderCreditKeyNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderProgram` that is related to this `LenderCreditKey`. */
  lenderProgram?: Maybe<LenderProgram>;
  /** An edge for our `LenderCreditKey`. May be used by Relay 1. */
  lenderCreditKeyEdge?: Maybe<LenderCreditKeysEdge>;
};


/** The output of our delete `LenderCreditKey` mutation. */
export type DeleteLenderCreditKeyPayloadLenderCreditKeyEdgeArgs = {
  orderBy?: Maybe<Array<LenderCreditKeysOrderBy>>;
};

/** All input for the `deleteLender` mutation. */
export type DeleteLenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Lender` mutation. */
export type DeleteLenderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lender` that was deleted by this mutation. */
  lender?: Maybe<Lender>;
  deletedLenderNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Lender`. May be used by Relay 1. */
  lenderEdge?: Maybe<LendersEdge>;
};


/** The output of our delete `Lender` mutation. */
export type DeleteLenderPayloadLenderEdgeArgs = {
  orderBy?: Maybe<Array<LendersOrderBy>>;
};

/** All input for the `deleteLenderProgramBonusByNodeId` mutation. */
export type DeleteLenderProgramBonusByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderProgramBonus` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLenderProgramBonus` mutation. */
export type DeleteLenderProgramBonusInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `LenderProgramBonus` mutation. */
export type DeleteLenderProgramBonusPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderProgramBonus` that was deleted by this mutation. */
  lenderProgramBonus?: Maybe<LenderProgramBonus>;
  deletedLenderProgramBonusNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderCreditKey` that is related to this `LenderProgramBonus`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** An edge for our `LenderProgramBonus`. May be used by Relay 1. */
  lenderProgramBonusEdge?: Maybe<LenderProgramBonusesEdge>;
};


/** The output of our delete `LenderProgramBonus` mutation. */
export type DeleteLenderProgramBonusPayloadLenderProgramBonusEdgeArgs = {
  orderBy?: Maybe<Array<LenderProgramBonusesOrderBy>>;
};

/** All input for the `deleteLenderProgramByNodeId` mutation. */
export type DeleteLenderProgramByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderProgram` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLenderProgram` mutation. */
export type DeleteLenderProgramInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `LenderProgram` mutation. */
export type DeleteLenderProgramPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderProgram` that was deleted by this mutation. */
  lenderProgram?: Maybe<LenderProgram>;
  deletedLenderProgramNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lender` that is related to this `LenderProgram`. */
  lender?: Maybe<Lender>;
  /** An edge for our `LenderProgram`. May be used by Relay 1. */
  lenderProgramEdge?: Maybe<LenderProgramsEdge>;
};


/** The output of our delete `LenderProgram` mutation. */
export type DeleteLenderProgramPayloadLenderProgramEdgeArgs = {
  orderBy?: Maybe<Array<LenderProgramsOrderBy>>;
};

/** All input for the `deleteLenderQuoteByNodeId` mutation. */
export type DeleteLenderQuoteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderQuote` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLenderQuote` mutation. */
export type DeleteLenderQuoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `LenderQuote` mutation. */
export type DeleteLenderQuotePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderQuote` that was deleted by this mutation. */
  lenderQuote?: Maybe<LenderQuote>;
  deletedLenderQuoteNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderQuote`. */
  lead?: Maybe<Lead>;
  /** Reads a single `LenderApp` that is related to this `LenderQuote`. */
  lenderApp?: Maybe<LenderApp>;
  /** Reads a single `Lender` that is related to this `LenderQuote`. */
  lender?: Maybe<Lender>;
  /** Reads a single `CompanyIntegration` that is related to this `LenderQuote`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** An edge for our `LenderQuote`. May be used by Relay 1. */
  lenderQuoteEdge?: Maybe<LenderQuotesEdge>;
};


/** The output of our delete `LenderQuote` mutation. */
export type DeleteLenderQuotePayloadLenderQuoteEdgeArgs = {
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
};

/** All input for the `deleteMaxAmortizationByNodeId` mutation. */
export type DeleteMaxAmortizationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MaxAmortization` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMaxAmortization` mutation. */
export type DeleteMaxAmortizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `MaxAmortization` mutation. */
export type DeleteMaxAmortizationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MaxAmortization` that was deleted by this mutation. */
  maxAmortization?: Maybe<MaxAmortization>;
  deletedMaxAmortizationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderCreditKey` that is related to this `MaxAmortization`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** An edge for our `MaxAmortization`. May be used by Relay 1. */
  maxAmortizationEdge?: Maybe<MaxAmortizationsEdge>;
};


/** The output of our delete `MaxAmortization` mutation. */
export type DeleteMaxAmortizationPayloadMaxAmortizationEdgeArgs = {
  orderBy?: Maybe<Array<MaxAmortizationsOrderBy>>;
};

/** All input for the `deleteOptionByNodeId` mutation. */
export type DeleteOptionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Option` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOption` mutation. */
export type DeleteOptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Option` mutation. */
export type DeleteOptionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Option` that was deleted by this mutation. */
  option?: Maybe<Option>;
  deletedOptionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `FieldConfig` that is related to this `Option`. */
  fieldConfig?: Maybe<FieldConfig>;
  /** An edge for our `Option`. May be used by Relay 1. */
  optionEdge?: Maybe<OptionsEdge>;
};


/** The output of our delete `Option` mutation. */
export type DeleteOptionPayloadOptionEdgeArgs = {
  orderBy?: Maybe<Array<OptionsOrderBy>>;
};

/** All input for the `deleteProductIntegrationByNodeId` mutation. */
export type DeleteProductIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProductIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProductIntegration` mutation. */
export type DeleteProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ProductIntegration` mutation. */
export type DeleteProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProductIntegration` that was deleted by this mutation. */
  productIntegration?: Maybe<ProductIntegration>;
  deletedProductIntegrationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `ProductIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Product` that is related to this `ProductIntegration`. */
  product?: Maybe<Product>;
  /** An edge for our `ProductIntegration`. May be used by Relay 1. */
  productIntegrationEdge?: Maybe<ProductIntegrationsEdge>;
};


/** The output of our delete `ProductIntegration` mutation. */
export type DeleteProductIntegrationPayloadProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
};

/** All input for the `deleteRateByNodeId` mutation. */
export type DeleteRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Rate` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRate` mutation. */
export type DeleteRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Rate` mutation. */
export type DeleteRatePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Rate` that was deleted by this mutation. */
  rate?: Maybe<Rate>;
  deletedRateNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderCreditKey` that is related to this `Rate`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** An edge for our `Rate`. May be used by Relay 1. */
  rateEdge?: Maybe<RatesEdge>;
};


/** The output of our delete `Rate` mutation. */
export type DeleteRatePayloadRateEdgeArgs = {
  orderBy?: Maybe<Array<RatesOrderBy>>;
};

/** All input for the `deleteReserveByNodeId` mutation. */
export type DeleteReserveByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Reserve` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteReserve` mutation. */
export type DeleteReserveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Reserve` mutation. */
export type DeleteReservePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Reserve` that was deleted by this mutation. */
  reserve?: Maybe<Reserve>;
  deletedReserveNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Rate` that is related to this `Reserve`. */
  rate?: Maybe<Rate>;
  /** An edge for our `Reserve`. May be used by Relay 1. */
  reserveEdge?: Maybe<ReservesEdge>;
};


/** The output of our delete `Reserve` mutation. */
export type DeleteReservePayloadReserveEdgeArgs = {
  orderBy?: Maybe<Array<ReservesOrderBy>>;
};

/** All input for the `deleteReviewByNodeId` mutation. */
export type DeleteReviewByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Review` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteReview` mutation. */
export type DeleteReviewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};

/** The output of our delete `Review` mutation. */
export type DeleteReviewPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Review` that was deleted by this mutation. */
  review?: Maybe<Review>;
  deletedReviewNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Review`. */
  company?: Maybe<Company>;
  /** An edge for our `Review`. May be used by Relay 1. */
  reviewEdge?: Maybe<ReviewsEdge>;
};


/** The output of our delete `Review` mutation. */
export type DeleteReviewPayloadReviewEdgeArgs = {
  orderBy?: Maybe<Array<ReviewsOrderBy>>;
};

/** All input for the `deleteTradeLineByNodeId` mutation. */
export type DeleteTradeLineByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TradeLine` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTradeLine` mutation. */
export type DeleteTradeLineInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `TradeLine` mutation. */
export type DeleteTradeLinePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TradeLine` that was deleted by this mutation. */
  tradeLine?: Maybe<TradeLine>;
  deletedTradeLineNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CreditBureau` that is related to this `TradeLine`. */
  bureau?: Maybe<CreditBureau>;
  /** Reads a single `EquifaxLender` that is related to this `TradeLine`. */
  equifaxLender?: Maybe<EquifaxLender>;
  /** An edge for our `TradeLine`. May be used by Relay 1. */
  tradeLineEdge?: Maybe<TradeLinesEdge>;
};


/** The output of our delete `TradeLine` mutation. */
export type DeleteTradeLinePayloadTradeLineEdgeArgs = {
  orderBy?: Maybe<Array<TradeLinesOrderBy>>;
};

/** All input for the `deleteUserActivityByNodeId` mutation. */
export type DeleteUserActivityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserActivity` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserActivity` mutation. */
export type DeleteUserActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `UserActivity` mutation. */
export type DeleteUserActivityPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserActivity` that was deleted by this mutation. */
  userActivity?: Maybe<UserActivity>;
  deletedUserActivityNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserActivity`. */
  user?: Maybe<User>;
  /** An edge for our `UserActivity`. May be used by Relay 1. */
  userActivityEdge?: Maybe<UserActivitiesEdge>;
};


/** The output of our delete `UserActivity` mutation. */
export type DeleteUserActivityPayloadUserActivityEdgeArgs = {
  orderBy?: Maybe<Array<UserActivitiesOrderBy>>;
};

/** All input for the `deleteVehicleBookingByNodeId` mutation. */
export type DeleteVehicleBookingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleBooking` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVehicleBooking` mutation. */
export type DeleteVehicleBookingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `VehicleBooking` mutation. */
export type DeleteVehicleBookingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleBooking` that was deleted by this mutation. */
  vehicleBooking?: Maybe<VehicleBooking>;
  deletedVehicleBookingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderProgram` that is related to this `VehicleBooking`. */
  lenderProgram?: Maybe<LenderProgram>;
  /** An edge for our `VehicleBooking`. May be used by Relay 1. */
  vehicleBookingEdge?: Maybe<VehicleBookingsEdge>;
};


/** The output of our delete `VehicleBooking` mutation. */
export type DeleteVehicleBookingPayloadVehicleBookingEdgeArgs = {
  orderBy?: Maybe<Array<VehicleBookingsOrderBy>>;
};

/** All input for the `deleteVehicleListingByNodeId` mutation. */
export type DeleteVehicleListingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleListing` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVehicleListing` mutation. */
export type DeleteVehicleListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `VehicleListing` mutation. */
export type DeleteVehicleListingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleListing` that was deleted by this mutation. */
  vehicleListing?: Maybe<VehicleListing>;
  deletedVehicleListingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `VehicleListing`. May be used by Relay 1. */
  vehicleListingEdge?: Maybe<VehicleListingsEdge>;
};


/** The output of our delete `VehicleListing` mutation. */
export type DeleteVehicleListingPayloadVehicleListingEdgeArgs = {
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
};

/** All input for the `deleteVehicleStockByNodeId` mutation. */
export type DeleteVehicleStockByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleStock` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVehicleStockByProviderId` mutation. */
export type DeleteVehicleStockByProviderIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  providerId: Scalars['String'];
};

/** All input for the `deleteVehicleStockByStockNumber` mutation. */
export type DeleteVehicleStockByStockNumberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  stockNumber: Scalars['String'];
};

/** All input for the `deleteVehicleStockByVin` mutation. */
export type DeleteVehicleStockByVinInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
};

/** All input for the `deleteVehicleStock` mutation. */
export type DeleteVehicleStockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `VehicleStock` mutation. */
export type DeleteVehicleStockPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleStock` that was deleted by this mutation. */
  vehicleStock?: Maybe<VehicleStock>;
  deletedVehicleStockNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `VehicleStock`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyIntegration` that is related to this `VehicleStock`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** An edge for our `VehicleStock`. May be used by Relay 1. */
  vehicleStockEdge?: Maybe<VehicleStocksEdge>;
};


/** The output of our delete `VehicleStock` mutation. */
export type DeleteVehicleStockPayloadVehicleStockEdgeArgs = {
  orderBy?: Maybe<Array<VehicleStocksOrderBy>>;
};

/** All input for the `deleteVerificationCheckByName` mutation. */
export type DeleteVerificationCheckByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** All input for the `deleteVerificationCheckByNodeId` mutation. */
export type DeleteVerificationCheckByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VerificationCheck` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVerificationCheck` mutation. */
export type DeleteVerificationCheckInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `VerificationCheck` mutation. */
export type DeleteVerificationCheckPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VerificationCheck` that was deleted by this mutation. */
  verificationCheck?: Maybe<VerificationCheck>;
  deletedVerificationCheckNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `VerificationCheck`. May be used by Relay 1. */
  verificationCheckEdge?: Maybe<VerificationChecksEdge>;
};


/** The output of our delete `VerificationCheck` mutation. */
export type DeleteVerificationCheckPayloadVerificationCheckEdgeArgs = {
  orderBy?: Maybe<Array<VerificationChecksOrderBy>>;
};

/** All input for the `deleteWidgetByNodeId` mutation. */
export type DeleteWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Widget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteWidgetByVariant` mutation. */
export type DeleteWidgetByVariantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  variant: Scalars['String'];
};

/** All input for the `deleteWidget` mutation. */
export type DeleteWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** The output of our delete `Widget` mutation. */
export type DeleteWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Widget` that was deleted by this mutation. */
  widget?: Maybe<Widget>;
  deletedWidgetNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Widget`. May be used by Relay 1. */
  widgetEdge?: Maybe<WidgetsEdge>;
};


/** The output of our delete `Widget` mutation. */
export type DeleteWidgetPayloadWidgetEdgeArgs = {
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
};

/** All input for the `deleteWidgetSettingByNodeId` mutation. */
export type DeleteWidgetSettingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `WidgetSetting` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteWidgetSetting` mutation. */
export type DeleteWidgetSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `WidgetSetting` mutation. */
export type DeleteWidgetSettingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WidgetSetting` that was deleted by this mutation. */
  widgetSetting?: Maybe<WidgetSetting>;
  deletedWidgetSettingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Widget` that is related to this `WidgetSetting`. */
  widget?: Maybe<Widget>;
  /** Reads a single `FieldKey` that is related to this `WidgetSetting`. */
  fieldKeyByFieldKey?: Maybe<FieldKey>;
  /** An edge for our `WidgetSetting`. May be used by Relay 1. */
  widgetSettingEdge?: Maybe<WidgetSettingsEdge>;
};


/** The output of our delete `WidgetSetting` mutation. */
export type DeleteWidgetSettingPayloadWidgetSettingEdgeArgs = {
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
};

export type DeliveryMethod = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  groupId?: Maybe<Scalars['Int']>;
  type?: Maybe<DeliveryMethodType>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  rule?: Maybe<Scalars['JSON']>;
  /** Reads a single `Group` that is related to this `DeliveryMethod`. */
  group?: Maybe<Group>;
  values: Array<Scalars['String']>;
};

export type DeliveryMethodAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DeliveryMethodSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DeliveryMethodDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DeliveryMethodMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DeliveryMethodMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DeliveryMethodAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DeliveryMethodStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DeliveryMethodStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DeliveryMethodVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DeliveryMethodVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `DeliveryMethod` object types. */
export type DeliveryMethodAggregatesFilter = {
  /** A filter that must pass for the relevant `DeliveryMethod` object to be included within the aggregate. */
  filter?: Maybe<DeliveryMethodFilter>;
  /** Sum aggregate over matching `DeliveryMethod` objects. */
  sum?: Maybe<DeliveryMethodSumAggregateFilter>;
  /** Distinct count aggregate over matching `DeliveryMethod` objects. */
  distinctCount?: Maybe<DeliveryMethodDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `DeliveryMethod` objects. */
  min?: Maybe<DeliveryMethodMinAggregateFilter>;
  /** Maximum aggregate over matching `DeliveryMethod` objects. */
  max?: Maybe<DeliveryMethodMaxAggregateFilter>;
  /** Mean average aggregate over matching `DeliveryMethod` objects. */
  average?: Maybe<DeliveryMethodAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DeliveryMethod` objects. */
  stddevSample?: Maybe<DeliveryMethodStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `DeliveryMethod` objects. */
  stddevPopulation?: Maybe<DeliveryMethodStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DeliveryMethod` objects. */
  varianceSample?: Maybe<DeliveryMethodVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `DeliveryMethod` objects. */
  variancePopulation?: Maybe<DeliveryMethodVariancePopulationAggregateFilter>;
};

export type DeliveryMethodAverageAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodAverageAggregates = {
  /** Mean average of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `DeliveryMethod` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DeliveryMethodCondition = {
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<DeliveryMethodType>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `config` field. */
  config?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rule` field. */
  rule?: Maybe<Scalars['JSON']>;
};

/** The fields on `deliveryMethod` to look up the row to connect. */
export type DeliveryMethodDeliveryMethodsPkey1Connect = {
  id: Scalars['Int'];
};

/** The fields on `deliveryMethod` to look up the row to delete. */
export type DeliveryMethodDeliveryMethodsPkey1Delete = {
  id: Scalars['Int'];
};

export type DeliveryMethodDistinctCountAggregateFilter = {
  groupId?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  config?: Maybe<BigIntFilter>;
  value?: Maybe<BigIntFilter>;
  id?: Maybe<BigIntFilter>;
  rule?: Maybe<BigIntFilter>;
};

export type DeliveryMethodDistinctCountAggregates = {
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rule across the matching connection */
  rule?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `DeliveryMethod` object types. All fields are combined with a logical ‘and.’ */
export type DeliveryMethodFilter = {
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<DeliveryMethodTypeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `config` field. */
  config?: Maybe<JsonFilter>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `rule` field. */
  rule?: Maybe<JsonFilter>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<GroupFilter>;
  /** A related `group` exists. */
  groupExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<DeliveryMethodFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<DeliveryMethodFilter>>;
  /** Negates the expression. */
  not?: Maybe<DeliveryMethodFilter>;
};

/** The `deliveryMethod` to be created by this mutation. */
export type DeliveryMethodGroupIdFkDeliveryMethodsCreateInput = {
  groupId?: Maybe<Scalars['Int']>;
  type?: Maybe<DeliveryMethodType>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  rule?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<DeliveryMethodGroupIdFkInput>;
};

/** The `group` to be created by this mutation. */
export type DeliveryMethodGroupIdFkGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `DeliveryMethodInput` mutation. */
export type DeliveryMethodGroupIdFkInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnDeliveryMethodForDeliveryMethodGroupIdFkUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnDeliveryMethodForDeliveryMethodGroupIdFkUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<DeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<DeliveryMethodGroupIdFkGroupCreateInput>;
};

/** Input for the nested mutation of `deliveryMethod` in the `GroupInput` mutation. */
export type DeliveryMethodGroupIdFkInverseInput = {
  /** Flag indicating whether all other `deliveryMethod` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `deliveryMethod` for the far side of the relationship. */
  connectById?: Maybe<Array<DeliveryMethodDeliveryMethodsPkey1Connect>>;
  /** The primary key(s) for `deliveryMethod` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DeliveryMethodNodeIdConnect>>;
  /** The primary key(s) for `deliveryMethod` for the far side of the relationship. */
  deleteById?: Maybe<Array<DeliveryMethodDeliveryMethodsPkey1Delete>>;
  /** The primary key(s) for `deliveryMethod` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<DeliveryMethodNodeIdDelete>>;
  /** The primary key(s) and patch data for `deliveryMethod` for the far side of the relationship. */
  updateById?: Maybe<Array<DeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkUsingDeliveryMethodsPkey1Update>>;
  /** The primary key(s) and patch data for `deliveryMethod` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnDeliveryMethodForDeliveryMethodGroupIdFkNodeIdUpdate>>;
  /** A `DeliveryMethodInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DeliveryMethodGroupIdFkDeliveryMethodsCreateInput>>;
};

/** An input for mutations affecting `DeliveryMethod` */
export type DeliveryMethodInput = {
  groupId?: Maybe<Scalars['Int']>;
  type?: Maybe<DeliveryMethodType>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  rule?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<DeliveryMethodGroupIdFkInput>;
};

export enum DeliveryMethodInternalType {
  CompanyChannels = 'COMPANY_CHANNELS'
}

/** A filter to be used against DeliveryMethodInternalType fields. All fields are combined with a logical ‘and.’ */
export type DeliveryMethodInternalTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<DeliveryMethodInternalType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<DeliveryMethodInternalType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<DeliveryMethodInternalType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<DeliveryMethodInternalType>;
  /** Included in the specified list. */
  in?: Maybe<Array<DeliveryMethodInternalType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<DeliveryMethodInternalType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<DeliveryMethodInternalType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<DeliveryMethodInternalType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<DeliveryMethodInternalType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<DeliveryMethodInternalType>;
};

export type DeliveryMethodMaxAggregateFilter = {
  groupId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
};

export type DeliveryMethodMaxAggregates = {
  /** Maximum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type DeliveryMethodMinAggregateFilter = {
  groupId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
};

export type DeliveryMethodMinAggregates = {
  /** Minimum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DeliveryMethodNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `deliveryMethod` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type DeliveryMethodNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `deliveryMethod` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type DeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `deliveryMethod` to look up the row to update. */
export type DeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkUsingDeliveryMethodsPkey1Update = {
  /** An object where the defined keys will be set on the `deliveryMethod` being updated. */
  patch: UpdateDeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `DeliveryMethod`. Fields that are set will be updated. */
export type DeliveryMethodPatch = {
  groupId?: Maybe<Scalars['Int']>;
  type?: Maybe<DeliveryMethodType>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  rule?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<DeliveryMethodGroupIdFkInput>;
};

export type DeliveryMethodResponse = {
  success: Scalars['Boolean'];
  deliveryMethodId: Scalars['Int'];
  preferredSender: Scalars['String'];
};

export type DeliveryMethodStddevPopulationAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodStddevPopulationAggregates = {
  /** Population standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DeliveryMethodStddevSampleAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodStddevSampleAggregates = {
  /** Sample standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DeliveryMethodSumAggregateFilter = {
  groupId?: Maybe<BigIntFilter>;
  id?: Maybe<BigIntFilter>;
};

export type DeliveryMethodSumAggregates = {
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export enum DeliveryMethodType {
  Email = 'EMAIL',
  Adf = 'ADF',
  Webhook = 'WEBHOOK',
  Leadspedia = 'LEADSPEDIA'
}

/** A filter to be used against DeliveryMethodType fields. All fields are combined with a logical ‘and.’ */
export type DeliveryMethodTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<DeliveryMethodType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<DeliveryMethodType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<DeliveryMethodType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<DeliveryMethodType>;
  /** Included in the specified list. */
  in?: Maybe<Array<DeliveryMethodType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<DeliveryMethodType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<DeliveryMethodType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<DeliveryMethodType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<DeliveryMethodType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<DeliveryMethodType>;
};

export type DeliveryMethodVariancePopulationAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodVariancePopulationAggregates = {
  /** Population variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DeliveryMethodVarianceSampleAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodVarianceSampleAggregates = {
  /** Sample variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DeliveryMethod` values. */
export type DeliveryMethodsConnection = {
  /** A list of `DeliveryMethod` objects. */
  nodes: Array<DeliveryMethod>;
  /** A list of edges which contains the `DeliveryMethod` and cursor to aid in pagination. */
  edges: Array<DeliveryMethodsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DeliveryMethod` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DeliveryMethodAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DeliveryMethodAggregates>>;
};


/** A connection to a list of `DeliveryMethod` values. */
export type DeliveryMethodsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DeliveryMethodsGroupBy>;
  having?: Maybe<DeliveryMethodsHavingInput>;
};

/** A `DeliveryMethod` edge in the connection. */
export type DeliveryMethodsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DeliveryMethod` at the end of the edge. */
  node: DeliveryMethod;
};

/** Grouping methods for `DeliveryMethod` for usage during aggregation. */
export enum DeliveryMethodsGroupBy {
  GroupId = 'GROUP_ID',
  Type = 'TYPE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Config = 'CONFIG',
  Value = 'VALUE',
  Rule = 'RULE'
}

export type DeliveryMethodsHavingAverageInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingDistinctCountInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

/** Conditions for `DeliveryMethod` aggregates. */
export type DeliveryMethodsHavingInput = {
  AND?: Maybe<Array<DeliveryMethodsHavingInput>>;
  OR?: Maybe<Array<DeliveryMethodsHavingInput>>;
  sum?: Maybe<DeliveryMethodsHavingSumInput>;
  distinctCount?: Maybe<DeliveryMethodsHavingDistinctCountInput>;
  min?: Maybe<DeliveryMethodsHavingMinInput>;
  max?: Maybe<DeliveryMethodsHavingMaxInput>;
  average?: Maybe<DeliveryMethodsHavingAverageInput>;
  stddevSample?: Maybe<DeliveryMethodsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<DeliveryMethodsHavingStddevPopulationInput>;
  varianceSample?: Maybe<DeliveryMethodsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<DeliveryMethodsHavingVariancePopulationInput>;
};

export type DeliveryMethodsHavingMaxInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingMinInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingStddevPopulationInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingStddevSampleInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingSumInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingVariancePopulationInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingVarianceSampleInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsInternal = {
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  name: Scalars['String'];
  type: DeliveryMethodInternalType;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  values?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rule?: Maybe<Scalars['JSON']>;
  /** Reads a single `Company` that is related to this `DeliveryMethodsInternal`. */
  company?: Maybe<Company>;
};

export type DeliveryMethodsInternalAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DeliveryMethodsInternalSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DeliveryMethodsInternalDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DeliveryMethodsInternalMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DeliveryMethodsInternalMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DeliveryMethodsInternalAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DeliveryMethodsInternalStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DeliveryMethodsInternalStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DeliveryMethodsInternalVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DeliveryMethodsInternalVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `DeliveryMethodsInternal` object types. */
export type DeliveryMethodsInternalAggregatesFilter = {
  /** A filter that must pass for the relevant `DeliveryMethodsInternal` object to be included within the aggregate. */
  filter?: Maybe<DeliveryMethodsInternalFilter>;
  /** Sum aggregate over matching `DeliveryMethodsInternal` objects. */
  sum?: Maybe<DeliveryMethodsInternalSumAggregateFilter>;
  /** Distinct count aggregate over matching `DeliveryMethodsInternal` objects. */
  distinctCount?: Maybe<DeliveryMethodsInternalDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `DeliveryMethodsInternal` objects. */
  min?: Maybe<DeliveryMethodsInternalMinAggregateFilter>;
  /** Maximum aggregate over matching `DeliveryMethodsInternal` objects. */
  max?: Maybe<DeliveryMethodsInternalMaxAggregateFilter>;
  /** Mean average aggregate over matching `DeliveryMethodsInternal` objects. */
  average?: Maybe<DeliveryMethodsInternalAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DeliveryMethodsInternal` objects. */
  stddevSample?: Maybe<DeliveryMethodsInternalStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `DeliveryMethodsInternal` objects. */
  stddevPopulation?: Maybe<DeliveryMethodsInternalStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DeliveryMethodsInternal` objects. */
  varianceSample?: Maybe<DeliveryMethodsInternalVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `DeliveryMethodsInternal` objects. */
  variancePopulation?: Maybe<DeliveryMethodsInternalVariancePopulationAggregateFilter>;
};

export type DeliveryMethodsInternalAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodsInternalAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** The `company` to be created by this mutation. */
export type DeliveryMethodsInternalCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `deliveryMethodsInternal` to be created by this mutation. */
export type DeliveryMethodsInternalCompanyIdFkeyDeliveryMethodsInternalCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  type: DeliveryMethodInternalType;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  values?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rule?: Maybe<Scalars['JSON']>;
  companyToCompanyId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `DeliveryMethodsInternalInput` mutation. */
export type DeliveryMethodsInternalCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<DeliveryMethodsInternalOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<DeliveryMethodsInternalCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `deliveryMethodsInternal` in the `CompanyInput` mutation. */
export type DeliveryMethodsInternalCompanyIdFkeyInverseInput = {
  /** A `DeliveryMethodsInternalInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DeliveryMethodsInternalCompanyIdFkeyDeliveryMethodsInternalCreateInput>>;
};

/**
 * A condition to be used against `DeliveryMethodsInternal` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DeliveryMethodsInternalCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<DeliveryMethodInternalType>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `config` field. */
  config?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `values` field. */
  values?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Checks for equality with the object’s `rule` field. */
  rule?: Maybe<Scalars['JSON']>;
};

export type DeliveryMethodsInternalDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  config?: Maybe<BigIntFilter>;
  values?: Maybe<BigIntFilter>;
  rule?: Maybe<BigIntFilter>;
};

export type DeliveryMethodsInternalDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars['BigInt']>;
  /** Distinct count of values across the matching connection */
  values?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rule across the matching connection */
  rule?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `DeliveryMethodsInternal` object types. All fields are combined with a logical ‘and.’ */
export type DeliveryMethodsInternalFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<DeliveryMethodInternalTypeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `config` field. */
  config?: Maybe<JsonFilter>;
  /** Filter by the object’s `values` field. */
  values?: Maybe<IntListFilter>;
  /** Filter by the object’s `rule` field. */
  rule?: Maybe<JsonFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<DeliveryMethodsInternalFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<DeliveryMethodsInternalFilter>>;
  /** Negates the expression. */
  not?: Maybe<DeliveryMethodsInternalFilter>;
};

/** Grouping methods for `DeliveryMethodsInternal` for usage during aggregation. */
export enum DeliveryMethodsInternalGroupBy {
  Id = 'ID',
  CompanyId = 'COMPANY_ID',
  Name = 'NAME',
  Type = 'TYPE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Config = 'CONFIG',
  Values = 'VALUES',
  Rule = 'RULE'
}

export type DeliveryMethodsInternalHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DeliveryMethodsInternalHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `DeliveryMethodsInternal` aggregates. */
export type DeliveryMethodsInternalHavingInput = {
  AND?: Maybe<Array<DeliveryMethodsInternalHavingInput>>;
  OR?: Maybe<Array<DeliveryMethodsInternalHavingInput>>;
  sum?: Maybe<DeliveryMethodsInternalHavingSumInput>;
  distinctCount?: Maybe<DeliveryMethodsInternalHavingDistinctCountInput>;
  min?: Maybe<DeliveryMethodsInternalHavingMinInput>;
  max?: Maybe<DeliveryMethodsInternalHavingMaxInput>;
  average?: Maybe<DeliveryMethodsInternalHavingAverageInput>;
  stddevSample?: Maybe<DeliveryMethodsInternalHavingStddevSampleInput>;
  stddevPopulation?: Maybe<DeliveryMethodsInternalHavingStddevPopulationInput>;
  varianceSample?: Maybe<DeliveryMethodsInternalHavingVarianceSampleInput>;
  variancePopulation?: Maybe<DeliveryMethodsInternalHavingVariancePopulationInput>;
};

export type DeliveryMethodsInternalHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DeliveryMethodsInternalHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DeliveryMethodsInternalHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DeliveryMethodsInternalHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DeliveryMethodsInternalHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DeliveryMethodsInternalHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DeliveryMethodsInternalHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `DeliveryMethodsInternal` */
export type DeliveryMethodsInternalInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  type: DeliveryMethodInternalType;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  values?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rule?: Maybe<Scalars['JSON']>;
  companyToCompanyId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInput>;
};

export type DeliveryMethodsInternalMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type DeliveryMethodsInternalMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type DeliveryMethodsInternalMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type DeliveryMethodsInternalMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to update. */
export type DeliveryMethodsInternalOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

export type DeliveryMethodsInternalStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodsInternalStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type DeliveryMethodsInternalStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodsInternalStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type DeliveryMethodsInternalSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
};

export type DeliveryMethodsInternalSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

export type DeliveryMethodsInternalVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodsInternalVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type DeliveryMethodsInternalVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodsInternalVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DeliveryMethodsInternal` values. */
export type DeliveryMethodsInternalsConnection = {
  /** A list of `DeliveryMethodsInternal` objects. */
  nodes: Array<DeliveryMethodsInternal>;
  /** A list of edges which contains the `DeliveryMethodsInternal` and cursor to aid in pagination. */
  edges: Array<DeliveryMethodsInternalsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DeliveryMethodsInternal` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DeliveryMethodsInternalAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DeliveryMethodsInternalAggregates>>;
};


/** A connection to a list of `DeliveryMethodsInternal` values. */
export type DeliveryMethodsInternalsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DeliveryMethodsInternalGroupBy>;
  having?: Maybe<DeliveryMethodsInternalHavingInput>;
};

/** A `DeliveryMethodsInternal` edge in the connection. */
export type DeliveryMethodsInternalsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DeliveryMethodsInternal` at the end of the edge. */
  node: DeliveryMethodsInternal;
};

/** Methods to use when ordering `DeliveryMethodsInternal`. */
export enum DeliveryMethodsInternalsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ConfigAsc = 'CONFIG_ASC',
  ConfigDesc = 'CONFIG_DESC',
  ValuesAsc = 'VALUES_ASC',
  ValuesDesc = 'VALUES_DESC',
  RuleAsc = 'RULE_ASC',
  RuleDesc = 'RULE_DESC'
}

/** Methods to use when ordering `DeliveryMethod`. */
export enum DeliveryMethodsOrderBy {
  Natural = 'NATURAL',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ConfigAsc = 'CONFIG_ASC',
  ConfigDesc = 'CONFIG_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  RuleAsc = 'RULE_ASC',
  RuleDesc = 'RULE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type DerogatoryCounters = {
  past30: Scalars['Int'];
  past60: Scalars['Int'];
  past90: Scalars['Int'];
};

export type DistributeLeadInput = {
  leadId: Scalars['UUID'];
  companyId: Scalars['Int'];
};

export type DistributeLeadResponse = {
  success: Scalars['Boolean'];
  leadId: Scalars['UUID'];
  companyId: Scalars['Int'];
};

export type DistributedLead = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  fromCompanyId: Scalars['Int'];
  toCompanyId: Scalars['Int'];
  originalCompanyChannelId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `DistributedLead`. */
  lead?: Maybe<Lead>;
  /** Reads a single `Company` that is related to this `DistributedLead`. */
  fromCompany?: Maybe<Company>;
  /** Reads a single `Company` that is related to this `DistributedLead`. */
  toCompany?: Maybe<Company>;
  /** Reads a single `CompanyChannel` that is related to this `DistributedLead`. */
  originalCompanyChannel?: Maybe<CompanyChannel>;
};

export type DistributedLeadAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DistributedLeadSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DistributedLeadDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DistributedLeadMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DistributedLeadMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DistributedLeadAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DistributedLeadStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DistributedLeadStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DistributedLeadVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DistributedLeadVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `DistributedLead` object types. */
export type DistributedLeadAggregatesFilter = {
  /** A filter that must pass for the relevant `DistributedLead` object to be included within the aggregate. */
  filter?: Maybe<DistributedLeadFilter>;
  /** Sum aggregate over matching `DistributedLead` objects. */
  sum?: Maybe<DistributedLeadSumAggregateFilter>;
  /** Distinct count aggregate over matching `DistributedLead` objects. */
  distinctCount?: Maybe<DistributedLeadDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `DistributedLead` objects. */
  min?: Maybe<DistributedLeadMinAggregateFilter>;
  /** Maximum aggregate over matching `DistributedLead` objects. */
  max?: Maybe<DistributedLeadMaxAggregateFilter>;
  /** Mean average aggregate over matching `DistributedLead` objects. */
  average?: Maybe<DistributedLeadAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DistributedLead` objects. */
  stddevSample?: Maybe<DistributedLeadStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `DistributedLead` objects. */
  stddevPopulation?: Maybe<DistributedLeadStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DistributedLead` objects. */
  varianceSample?: Maybe<DistributedLeadVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `DistributedLead` objects. */
  variancePopulation?: Maybe<DistributedLeadVariancePopulationAggregateFilter>;
};

export type DistributedLeadAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fromCompanyId?: Maybe<BigFloatFilter>;
  toCompanyId?: Maybe<BigFloatFilter>;
  originalCompanyChannelId?: Maybe<BigFloatFilter>;
};

export type DistributedLeadAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of fromCompanyId across the matching connection */
  fromCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of toCompanyId across the matching connection */
  toCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of originalCompanyChannelId across the matching connection */
  originalCompanyChannelId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `DistributedLead` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DistributedLeadCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `fromCompanyId` field. */
  fromCompanyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `toCompanyId` field. */
  toCompanyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `originalCompanyChannelId` field. */
  originalCompanyChannelId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type DistributedLeadDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  fromCompanyId?: Maybe<BigIntFilter>;
  toCompanyId?: Maybe<BigIntFilter>;
  originalCompanyChannelId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type DistributedLeadDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fromCompanyId across the matching connection */
  fromCompanyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of toCompanyId across the matching connection */
  toCompanyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of originalCompanyChannelId across the matching connection */
  originalCompanyChannelId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The fields on `distributedLead` to look up the row to connect. */
export type DistributedLeadDistributedLeadsPkeyConnect = {
  id: Scalars['Int'];
};

/** A filter to be used against `DistributedLead` object types. All fields are combined with a logical ‘and.’ */
export type DistributedLeadFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `fromCompanyId` field. */
  fromCompanyId?: Maybe<IntFilter>;
  /** Filter by the object’s `toCompanyId` field. */
  toCompanyId?: Maybe<IntFilter>;
  /** Filter by the object’s `originalCompanyChannelId` field. */
  originalCompanyChannelId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Filter by the object’s `fromCompany` relation. */
  fromCompany?: Maybe<CompanyFilter>;
  /** Filter by the object’s `toCompany` relation. */
  toCompany?: Maybe<CompanyFilter>;
  /** Filter by the object’s `originalCompanyChannel` relation. */
  originalCompanyChannel?: Maybe<CompanyChannelFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<DistributedLeadFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<DistributedLeadFilter>>;
  /** Negates the expression. */
  not?: Maybe<DistributedLeadFilter>;
};

export type DistributedLeadMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  fromCompanyId?: Maybe<IntFilter>;
  toCompanyId?: Maybe<IntFilter>;
  originalCompanyChannelId?: Maybe<IntFilter>;
};

export type DistributedLeadMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of fromCompanyId across the matching connection */
  fromCompanyId?: Maybe<Scalars['Int']>;
  /** Maximum of toCompanyId across the matching connection */
  toCompanyId?: Maybe<Scalars['Int']>;
  /** Maximum of originalCompanyChannelId across the matching connection */
  originalCompanyChannelId?: Maybe<Scalars['Int']>;
};

export type DistributedLeadMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  fromCompanyId?: Maybe<IntFilter>;
  toCompanyId?: Maybe<IntFilter>;
  originalCompanyChannelId?: Maybe<IntFilter>;
};

export type DistributedLeadMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of fromCompanyId across the matching connection */
  fromCompanyId?: Maybe<Scalars['Int']>;
  /** Minimum of toCompanyId across the matching connection */
  toCompanyId?: Maybe<Scalars['Int']>;
  /** Minimum of originalCompanyChannelId across the matching connection */
  originalCompanyChannelId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DistributedLeadNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `distributedLead` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type DistributedLeadOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `distributedLead` to look up the row to update. */
export type DistributedLeadOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingDistributedLeadsPkeyUpdate = {
  /** An object where the defined keys will be set on the `distributedLead` being updated. */
  patch: UpdateDistributedLeadOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type DistributedLeadOnDistributedLeadForDistributedLeadsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `distributedLead` to look up the row to update. */
export type DistributedLeadOnDistributedLeadForDistributedLeadsLeadIdFkeyUsingDistributedLeadsPkeyUpdate = {
  /** An object where the defined keys will be set on the `distributedLead` being updated. */
  patch: UpdateDistributedLeadOnDistributedLeadForDistributedLeadsLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type DistributedLeadOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** The fields on `distributedLead` to look up the row to update. */
export type DistributedLeadOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyUsingDistributedLeadsPkeyUpdate = {
  /** An object where the defined keys will be set on the `distributedLead` being updated. */
  patch: UpdateDistributedLeadOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type DistributedLeadOnDistributedLeadForDistributedLeadsToCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `distributedLead` to look up the row to update. */
export type DistributedLeadOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingDistributedLeadsPkeyUpdate = {
  /** An object where the defined keys will be set on the `distributedLead` being updated. */
  patch: UpdateDistributedLeadOnDistributedLeadForDistributedLeadsToCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `DistributedLead`. Fields that are set will be updated. */
export type DistributedLeadPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  fromCompanyId?: Maybe<Scalars['Int']>;
  toCompanyId?: Maybe<Scalars['Int']>;
  originalCompanyChannelId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<DistributedLeadsLeadIdFkeyInput>;
  companyToFromCompanyId?: Maybe<DistributedLeadsFromCompanyIdFkeyInput>;
  companyToToCompanyId?: Maybe<DistributedLeadsToCompanyIdFkeyInput>;
  companyChannelToOriginalCompanyChannelId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInput>;
};

export type DistributedLeadStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fromCompanyId?: Maybe<BigFloatFilter>;
  toCompanyId?: Maybe<BigFloatFilter>;
  originalCompanyChannelId?: Maybe<BigFloatFilter>;
};

export type DistributedLeadStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of fromCompanyId across the matching connection */
  fromCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of toCompanyId across the matching connection */
  toCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of originalCompanyChannelId across the matching connection */
  originalCompanyChannelId?: Maybe<Scalars['BigFloat']>;
};

export type DistributedLeadStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fromCompanyId?: Maybe<BigFloatFilter>;
  toCompanyId?: Maybe<BigFloatFilter>;
  originalCompanyChannelId?: Maybe<BigFloatFilter>;
};

export type DistributedLeadStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of fromCompanyId across the matching connection */
  fromCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of toCompanyId across the matching connection */
  toCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of originalCompanyChannelId across the matching connection */
  originalCompanyChannelId?: Maybe<Scalars['BigFloat']>;
};

export type DistributedLeadSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  fromCompanyId?: Maybe<BigIntFilter>;
  toCompanyId?: Maybe<BigIntFilter>;
  originalCompanyChannelId?: Maybe<BigIntFilter>;
};

export type DistributedLeadSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of fromCompanyId across the matching connection */
  fromCompanyId: Scalars['BigInt'];
  /** Sum of toCompanyId across the matching connection */
  toCompanyId: Scalars['BigInt'];
  /** Sum of originalCompanyChannelId across the matching connection */
  originalCompanyChannelId: Scalars['BigInt'];
};

export type DistributedLeadVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fromCompanyId?: Maybe<BigFloatFilter>;
  toCompanyId?: Maybe<BigFloatFilter>;
  originalCompanyChannelId?: Maybe<BigFloatFilter>;
};

export type DistributedLeadVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of fromCompanyId across the matching connection */
  fromCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of toCompanyId across the matching connection */
  toCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of originalCompanyChannelId across the matching connection */
  originalCompanyChannelId?: Maybe<Scalars['BigFloat']>;
};

export type DistributedLeadVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fromCompanyId?: Maybe<BigFloatFilter>;
  toCompanyId?: Maybe<BigFloatFilter>;
  originalCompanyChannelId?: Maybe<BigFloatFilter>;
};

export type DistributedLeadVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of fromCompanyId across the matching connection */
  fromCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of toCompanyId across the matching connection */
  toCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of originalCompanyChannelId across the matching connection */
  originalCompanyChannelId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DistributedLead` values. */
export type DistributedLeadsConnection = {
  /** A list of `DistributedLead` objects. */
  nodes: Array<DistributedLead>;
  /** A list of edges which contains the `DistributedLead` and cursor to aid in pagination. */
  edges: Array<DistributedLeadsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DistributedLead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DistributedLeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DistributedLeadAggregates>>;
};


/** A connection to a list of `DistributedLead` values. */
export type DistributedLeadsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DistributedLeadsGroupBy>;
  having?: Maybe<DistributedLeadsHavingInput>;
};

/** A `DistributedLead` edge in the connection. */
export type DistributedLeadsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DistributedLead` at the end of the edge. */
  node: DistributedLead;
};

/** The `company` to be created by this mutation. */
export type DistributedLeadsFromCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `DistributedLeadInput` mutation. */
export type DistributedLeadsFromCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<DistributedLeadOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<DistributedLeadsFromCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `distributedLead` in the `CompanyInput` mutation. */
export type DistributedLeadsFromCompanyIdFkeyInverseInput = {
  /** The primary key(s) for `distributedLead` for the far side of the relationship. */
  connectById?: Maybe<Array<DistributedLeadDistributedLeadsPkeyConnect>>;
  /** The primary key(s) for `distributedLead` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DistributedLeadNodeIdConnect>>;
  /** The primary key(s) and patch data for `distributedLead` for the far side of the relationship. */
  updateById?: Maybe<Array<DistributedLeadOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyUsingDistributedLeadsPkeyUpdate>>;
  /** The primary key(s) and patch data for `distributedLead` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyNodeIdUpdate>>;
};

/** Grouping methods for `DistributedLead` for usage during aggregation. */
export enum DistributedLeadsGroupBy {
  LeadId = 'LEAD_ID',
  FromCompanyId = 'FROM_COMPANY_ID',
  ToCompanyId = 'TO_COMPANY_ID',
  OriginalCompanyChannelId = 'ORIGINAL_COMPANY_CHANNEL_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type DistributedLeadsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  fromCompanyId?: Maybe<HavingIntFilter>;
  toCompanyId?: Maybe<HavingIntFilter>;
  originalCompanyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DistributedLeadsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  fromCompanyId?: Maybe<HavingIntFilter>;
  toCompanyId?: Maybe<HavingIntFilter>;
  originalCompanyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `DistributedLead` aggregates. */
export type DistributedLeadsHavingInput = {
  AND?: Maybe<Array<DistributedLeadsHavingInput>>;
  OR?: Maybe<Array<DistributedLeadsHavingInput>>;
  sum?: Maybe<DistributedLeadsHavingSumInput>;
  distinctCount?: Maybe<DistributedLeadsHavingDistinctCountInput>;
  min?: Maybe<DistributedLeadsHavingMinInput>;
  max?: Maybe<DistributedLeadsHavingMaxInput>;
  average?: Maybe<DistributedLeadsHavingAverageInput>;
  stddevSample?: Maybe<DistributedLeadsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<DistributedLeadsHavingStddevPopulationInput>;
  varianceSample?: Maybe<DistributedLeadsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<DistributedLeadsHavingVariancePopulationInput>;
};

export type DistributedLeadsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  fromCompanyId?: Maybe<HavingIntFilter>;
  toCompanyId?: Maybe<HavingIntFilter>;
  originalCompanyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DistributedLeadsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  fromCompanyId?: Maybe<HavingIntFilter>;
  toCompanyId?: Maybe<HavingIntFilter>;
  originalCompanyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DistributedLeadsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  fromCompanyId?: Maybe<HavingIntFilter>;
  toCompanyId?: Maybe<HavingIntFilter>;
  originalCompanyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DistributedLeadsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  fromCompanyId?: Maybe<HavingIntFilter>;
  toCompanyId?: Maybe<HavingIntFilter>;
  originalCompanyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DistributedLeadsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  fromCompanyId?: Maybe<HavingIntFilter>;
  toCompanyId?: Maybe<HavingIntFilter>;
  originalCompanyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DistributedLeadsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  fromCompanyId?: Maybe<HavingIntFilter>;
  toCompanyId?: Maybe<HavingIntFilter>;
  originalCompanyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type DistributedLeadsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  fromCompanyId?: Maybe<HavingIntFilter>;
  toCompanyId?: Maybe<HavingIntFilter>;
  originalCompanyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Input for the nested mutation of `lead` in the `DistributedLeadInput` mutation. */
export type DistributedLeadsLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnDistributedLeadForDistributedLeadsLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<DistributedLeadOnDistributedLeadForDistributedLeadsLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<DistributedLeadsLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `distributedLead` in the `LeadInput` mutation. */
export type DistributedLeadsLeadIdFkeyInverseInput = {
  /** The primary key(s) for `distributedLead` for the far side of the relationship. */
  connectById?: Maybe<Array<DistributedLeadDistributedLeadsPkeyConnect>>;
  /** The primary key(s) for `distributedLead` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DistributedLeadNodeIdConnect>>;
  /** The primary key(s) and patch data for `distributedLead` for the far side of the relationship. */
  updateById?: Maybe<Array<DistributedLeadOnDistributedLeadForDistributedLeadsLeadIdFkeyUsingDistributedLeadsPkeyUpdate>>;
  /** The primary key(s) and patch data for `distributedLead` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnDistributedLeadForDistributedLeadsLeadIdFkeyNodeIdUpdate>>;
};

/** The `lead` to be created by this mutation. */
export type DistributedLeadsLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** Methods to use when ordering `DistributedLead`. */
export enum DistributedLeadsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  FromCompanyIdAsc = 'FROM_COMPANY_ID_ASC',
  FromCompanyIdDesc = 'FROM_COMPANY_ID_DESC',
  ToCompanyIdAsc = 'TO_COMPANY_ID_ASC',
  ToCompanyIdDesc = 'TO_COMPANY_ID_DESC',
  OriginalCompanyChannelIdAsc = 'ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  OriginalCompanyChannelIdDesc = 'ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `companyChannel` to be created by this mutation. */
export type DistributedLeadsOriginalCompanyChannelIdFkeyCompanyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** Input for the nested mutation of `companyChannel` in the `DistributedLeadInput` mutation. */
export type DistributedLeadsOriginalCompanyChannelIdFkeyInput = {
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectById?: Maybe<CompanyChannelCompanyChannelPkeyConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByCompanyIdAndNameAndChannelId?: Maybe<CompanyChannelUnqCompanyChannelConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyChannelNodeIdConnect>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateById?: Maybe<CompanyChannelOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByCompanyIdAndNameAndChannelId?: Maybe<CompanyChannelOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyUsingUnqCompanyChannelUpdate>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByNodeId?: Maybe<DistributedLeadOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyNodeIdUpdate>;
  /** A `CompanyChannelInput` object that will be created and connected to this object. */
  create?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyCompanyChannelCreateInput>;
};

/** Input for the nested mutation of `distributedLead` in the `CompanyChannelInput` mutation. */
export type DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput = {
  /** The primary key(s) for `distributedLead` for the far side of the relationship. */
  connectById?: Maybe<Array<DistributedLeadDistributedLeadsPkeyConnect>>;
  /** The primary key(s) for `distributedLead` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DistributedLeadNodeIdConnect>>;
  /** The primary key(s) and patch data for `distributedLead` for the far side of the relationship. */
  updateById?: Maybe<Array<DistributedLeadOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyUsingDistributedLeadsPkeyUpdate>>;
  /** The primary key(s) and patch data for `distributedLead` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyChannelOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyNodeIdUpdate>>;
};

/** The `company` to be created by this mutation. */
export type DistributedLeadsToCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `DistributedLeadInput` mutation. */
export type DistributedLeadsToCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<DistributedLeadOnDistributedLeadForDistributedLeadsToCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<DistributedLeadsToCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `distributedLead` in the `CompanyInput` mutation. */
export type DistributedLeadsToCompanyIdFkeyInverseInput = {
  /** The primary key(s) for `distributedLead` for the far side of the relationship. */
  connectById?: Maybe<Array<DistributedLeadDistributedLeadsPkeyConnect>>;
  /** The primary key(s) for `distributedLead` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DistributedLeadNodeIdConnect>>;
  /** The primary key(s) and patch data for `distributedLead` for the far side of the relationship. */
  updateById?: Maybe<Array<DistributedLeadOnDistributedLeadForDistributedLeadsToCompanyIdFkeyUsingDistributedLeadsPkeyUpdate>>;
  /** The primary key(s) and patch data for `distributedLead` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyNodeIdUpdate>>;
};

export enum DistributionStrategy {
  Manual = 'MANUAL',
  CompanyLocation = 'COMPANY_LOCATION'
}

/** A filter to be used against DistributionStrategy fields. All fields are combined with a logical ‘and.’ */
export type DistributionStrategyFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<DistributionStrategy>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<DistributionStrategy>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<DistributionStrategy>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<DistributionStrategy>;
  /** Included in the specified list. */
  in?: Maybe<Array<DistributionStrategy>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<DistributionStrategy>>;
  /** Less than the specified value. */
  lessThan?: Maybe<DistributionStrategy>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<DistributionStrategy>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<DistributionStrategy>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<DistributionStrategy>;
};

export type DistributorRelationship = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  distributorCompanyId: Scalars['Int'];
  companyId: Scalars['Int'];
  companyChannelId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  strategy: DistributionStrategy;
  maxDistanceKm: Scalars['Int'];
  /** Reads a single `Company` that is related to this `DistributorRelationship`. */
  distributorCompany?: Maybe<Company>;
  /** Reads a single `Company` that is related to this `DistributorRelationship`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyChannel` that is related to this `DistributorRelationship`. */
  companyChannel?: Maybe<CompanyChannel>;
};

export type DistributorRelationshipAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DistributorRelationshipSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DistributorRelationshipDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DistributorRelationshipMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DistributorRelationshipMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DistributorRelationshipAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DistributorRelationshipStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DistributorRelationshipStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DistributorRelationshipVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DistributorRelationshipVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `DistributorRelationship` object types. */
export type DistributorRelationshipAggregatesFilter = {
  /** A filter that must pass for the relevant `DistributorRelationship` object to be included within the aggregate. */
  filter?: Maybe<DistributorRelationshipFilter>;
  /** Sum aggregate over matching `DistributorRelationship` objects. */
  sum?: Maybe<DistributorRelationshipSumAggregateFilter>;
  /** Distinct count aggregate over matching `DistributorRelationship` objects. */
  distinctCount?: Maybe<DistributorRelationshipDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `DistributorRelationship` objects. */
  min?: Maybe<DistributorRelationshipMinAggregateFilter>;
  /** Maximum aggregate over matching `DistributorRelationship` objects. */
  max?: Maybe<DistributorRelationshipMaxAggregateFilter>;
  /** Mean average aggregate over matching `DistributorRelationship` objects. */
  average?: Maybe<DistributorRelationshipAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DistributorRelationship` objects. */
  stddevSample?: Maybe<DistributorRelationshipStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `DistributorRelationship` objects. */
  stddevPopulation?: Maybe<DistributorRelationshipStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DistributorRelationship` objects. */
  varianceSample?: Maybe<DistributorRelationshipVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `DistributorRelationship` objects. */
  variancePopulation?: Maybe<DistributorRelationshipVariancePopulationAggregateFilter>;
};

export type DistributorRelationshipAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  distributorCompanyId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  maxDistanceKm?: Maybe<BigFloatFilter>;
};

export type DistributorRelationshipAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of distributorCompanyId across the matching connection */
  distributorCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of maxDistanceKm across the matching connection */
  maxDistanceKm?: Maybe<Scalars['BigFloat']>;
};

/** The `companyChannel` to be created by this mutation. */
export type DistributorRelationshipCompanyChannelIdFkeyCompanyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** The `distributorRelationship` to be created by this mutation. */
export type DistributorRelationshipCompanyChannelIdFkeyDistributorRelationshipCreateInput = {
  id?: Maybe<Scalars['Int']>;
  distributorCompanyId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  strategy?: Maybe<DistributionStrategy>;
  maxDistanceKm?: Maybe<Scalars['Int']>;
  companyToDistributorCompanyId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInput>;
  companyToCompanyId?: Maybe<DistributorRelationshipCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInput>;
};

/** Input for the nested mutation of `companyChannel` in the `DistributorRelationshipInput` mutation. */
export type DistributorRelationshipCompanyChannelIdFkeyInput = {
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectById?: Maybe<CompanyChannelCompanyChannelPkeyConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByCompanyIdAndNameAndChannelId?: Maybe<CompanyChannelUnqCompanyChannelConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyChannelNodeIdConnect>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateById?: Maybe<CompanyChannelOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByCompanyIdAndNameAndChannelId?: Maybe<CompanyChannelOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyUsingUnqCompanyChannelUpdate>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByNodeId?: Maybe<DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyNodeIdUpdate>;
  /** A `CompanyChannelInput` object that will be created and connected to this object. */
  create?: Maybe<DistributorRelationshipCompanyChannelIdFkeyCompanyChannelCreateInput>;
};

/** Input for the nested mutation of `distributorRelationship` in the `CompanyChannelInput` mutation. */
export type DistributorRelationshipCompanyChannelIdFkeyInverseInput = {
  /** Flag indicating whether all other `distributorRelationship` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  connectById?: Maybe<Array<DistributorRelationshipDistributorRelationshipPkeyConnect>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  connectByDistributorCompanyIdAndCompanyId?: Maybe<Array<DistributorRelationshipDistributorRelationshipUniqueConnect>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DistributorRelationshipNodeIdConnect>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  deleteById?: Maybe<Array<DistributorRelationshipDistributorRelationshipPkeyDelete>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  deleteByDistributorCompanyIdAndCompanyId?: Maybe<Array<DistributorRelationshipDistributorRelationshipUniqueDelete>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<DistributorRelationshipNodeIdDelete>>;
  /** The primary key(s) and patch data for `distributorRelationship` for the far side of the relationship. */
  updateById?: Maybe<Array<DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyUsingDistributorRelationshipPkeyUpdate>>;
  /** The primary key(s) and patch data for `distributorRelationship` for the far side of the relationship. */
  updateByDistributorCompanyIdAndCompanyId?: Maybe<Array<DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyUsingDistributorRelationshipUniqueUpdate>>;
  /** The primary key(s) and patch data for `distributorRelationship` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyChannelOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyNodeIdUpdate>>;
  /** A `DistributorRelationshipInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DistributorRelationshipCompanyChannelIdFkeyDistributorRelationshipCreateInput>>;
};

/** The `company` to be created by this mutation. */
export type DistributorRelationshipCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `distributorRelationship` to be created by this mutation. */
export type DistributorRelationshipCompanyIdFkeyDistributorRelationshipCreateInput = {
  id?: Maybe<Scalars['Int']>;
  distributorCompanyId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  strategy?: Maybe<DistributionStrategy>;
  maxDistanceKm?: Maybe<Scalars['Int']>;
  companyToDistributorCompanyId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInput>;
  companyToCompanyId?: Maybe<DistributorRelationshipCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `DistributorRelationshipInput` mutation. */
export type DistributorRelationshipCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<DistributorRelationshipCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `distributorRelationship` in the `CompanyInput` mutation. */
export type DistributorRelationshipCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `distributorRelationship` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  connectById?: Maybe<Array<DistributorRelationshipDistributorRelationshipPkeyConnect>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  connectByDistributorCompanyIdAndCompanyId?: Maybe<Array<DistributorRelationshipDistributorRelationshipUniqueConnect>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DistributorRelationshipNodeIdConnect>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  deleteById?: Maybe<Array<DistributorRelationshipDistributorRelationshipPkeyDelete>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  deleteByDistributorCompanyIdAndCompanyId?: Maybe<Array<DistributorRelationshipDistributorRelationshipUniqueDelete>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<DistributorRelationshipNodeIdDelete>>;
  /** The primary key(s) and patch data for `distributorRelationship` for the far side of the relationship. */
  updateById?: Maybe<Array<DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingDistributorRelationshipPkeyUpdate>>;
  /** The primary key(s) and patch data for `distributorRelationship` for the far side of the relationship. */
  updateByDistributorCompanyIdAndCompanyId?: Maybe<Array<DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingDistributorRelationshipUniqueUpdate>>;
  /** The primary key(s) and patch data for `distributorRelationship` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyNodeIdUpdate>>;
  /** A `DistributorRelationshipInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DistributorRelationshipCompanyIdFkeyDistributorRelationshipCreateInput>>;
};

/**
 * A condition to be used against `DistributorRelationship` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DistributorRelationshipCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `distributorCompanyId` field. */
  distributorCompanyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyChannelId` field. */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `strategy` field. */
  strategy?: Maybe<DistributionStrategy>;
  /** Checks for equality with the object’s `maxDistanceKm` field. */
  maxDistanceKm?: Maybe<Scalars['Int']>;
};

export type DistributorRelationshipDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  distributorCompanyId?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  companyChannelId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  strategy?: Maybe<BigIntFilter>;
  maxDistanceKm?: Maybe<BigIntFilter>;
};

export type DistributorRelationshipDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of distributorCompanyId across the matching connection */
  distributorCompanyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of strategy across the matching connection */
  strategy?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxDistanceKm across the matching connection */
  maxDistanceKm?: Maybe<Scalars['BigInt']>;
};

/** The `company` to be created by this mutation. */
export type DistributorRelationshipDistributorCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `distributorRelationship` to be created by this mutation. */
export type DistributorRelationshipDistributorCompanyIdFkeyDistributorRelationshipCreateInput = {
  id?: Maybe<Scalars['Int']>;
  distributorCompanyId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  strategy?: Maybe<DistributionStrategy>;
  maxDistanceKm?: Maybe<Scalars['Int']>;
  companyToDistributorCompanyId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInput>;
  companyToCompanyId?: Maybe<DistributorRelationshipCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `DistributorRelationshipInput` mutation. */
export type DistributorRelationshipDistributorCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `distributorRelationship` in the `CompanyInput` mutation. */
export type DistributorRelationshipDistributorCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `distributorRelationship` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  connectById?: Maybe<Array<DistributorRelationshipDistributorRelationshipPkeyConnect>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  connectByDistributorCompanyIdAndCompanyId?: Maybe<Array<DistributorRelationshipDistributorRelationshipUniqueConnect>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DistributorRelationshipNodeIdConnect>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  deleteById?: Maybe<Array<DistributorRelationshipDistributorRelationshipPkeyDelete>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  deleteByDistributorCompanyIdAndCompanyId?: Maybe<Array<DistributorRelationshipDistributorRelationshipUniqueDelete>>;
  /** The primary key(s) for `distributorRelationship` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<DistributorRelationshipNodeIdDelete>>;
  /** The primary key(s) and patch data for `distributorRelationship` for the far side of the relationship. */
  updateById?: Maybe<Array<DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingDistributorRelationshipPkeyUpdate>>;
  /** The primary key(s) and patch data for `distributorRelationship` for the far side of the relationship. */
  updateByDistributorCompanyIdAndCompanyId?: Maybe<Array<DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingDistributorRelationshipUniqueUpdate>>;
  /** The primary key(s) and patch data for `distributorRelationship` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyNodeIdUpdate>>;
  /** A `DistributorRelationshipInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DistributorRelationshipDistributorCompanyIdFkeyDistributorRelationshipCreateInput>>;
};

/** The fields on `distributorRelationship` to look up the row to connect. */
export type DistributorRelationshipDistributorRelationshipPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `distributorRelationship` to look up the row to delete. */
export type DistributorRelationshipDistributorRelationshipPkeyDelete = {
  id: Scalars['Int'];
};

/** The fields on `distributorRelationship` to look up the row to connect. */
export type DistributorRelationshipDistributorRelationshipUniqueConnect = {
  distributorCompanyId: Scalars['Int'];
  companyId: Scalars['Int'];
};

/** The fields on `distributorRelationship` to look up the row to delete. */
export type DistributorRelationshipDistributorRelationshipUniqueDelete = {
  distributorCompanyId: Scalars['Int'];
  companyId: Scalars['Int'];
};

/** A filter to be used against `DistributorRelationship` object types. All fields are combined with a logical ‘and.’ */
export type DistributorRelationshipFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `distributorCompanyId` field. */
  distributorCompanyId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyChannelId` field. */
  companyChannelId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `strategy` field. */
  strategy?: Maybe<DistributionStrategyFilter>;
  /** Filter by the object’s `maxDistanceKm` field. */
  maxDistanceKm?: Maybe<IntFilter>;
  /** Filter by the object’s `distributorCompany` relation. */
  distributorCompany?: Maybe<CompanyFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Filter by the object’s `companyChannel` relation. */
  companyChannel?: Maybe<CompanyChannelFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<DistributorRelationshipFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<DistributorRelationshipFilter>>;
  /** Negates the expression. */
  not?: Maybe<DistributorRelationshipFilter>;
};

/** Grouping methods for `DistributorRelationship` for usage during aggregation. */
export enum DistributorRelationshipGroupBy {
  DistributorCompanyId = 'DISTRIBUTOR_COMPANY_ID',
  CompanyId = 'COMPANY_ID',
  CompanyChannelId = 'COMPANY_CHANNEL_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Strategy = 'STRATEGY',
  MaxDistanceKm = 'MAX_DISTANCE_KM'
}

export type DistributorRelationshipHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  distributorCompanyId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  maxDistanceKm?: Maybe<HavingIntFilter>;
};

export type DistributorRelationshipHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  distributorCompanyId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  maxDistanceKm?: Maybe<HavingIntFilter>;
};

/** Conditions for `DistributorRelationship` aggregates. */
export type DistributorRelationshipHavingInput = {
  AND?: Maybe<Array<DistributorRelationshipHavingInput>>;
  OR?: Maybe<Array<DistributorRelationshipHavingInput>>;
  sum?: Maybe<DistributorRelationshipHavingSumInput>;
  distinctCount?: Maybe<DistributorRelationshipHavingDistinctCountInput>;
  min?: Maybe<DistributorRelationshipHavingMinInput>;
  max?: Maybe<DistributorRelationshipHavingMaxInput>;
  average?: Maybe<DistributorRelationshipHavingAverageInput>;
  stddevSample?: Maybe<DistributorRelationshipHavingStddevSampleInput>;
  stddevPopulation?: Maybe<DistributorRelationshipHavingStddevPopulationInput>;
  varianceSample?: Maybe<DistributorRelationshipHavingVarianceSampleInput>;
  variancePopulation?: Maybe<DistributorRelationshipHavingVariancePopulationInput>;
};

export type DistributorRelationshipHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  distributorCompanyId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  maxDistanceKm?: Maybe<HavingIntFilter>;
};

export type DistributorRelationshipHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  distributorCompanyId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  maxDistanceKm?: Maybe<HavingIntFilter>;
};

export type DistributorRelationshipHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  distributorCompanyId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  maxDistanceKm?: Maybe<HavingIntFilter>;
};

export type DistributorRelationshipHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  distributorCompanyId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  maxDistanceKm?: Maybe<HavingIntFilter>;
};

export type DistributorRelationshipHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  distributorCompanyId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  maxDistanceKm?: Maybe<HavingIntFilter>;
};

export type DistributorRelationshipHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  distributorCompanyId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  maxDistanceKm?: Maybe<HavingIntFilter>;
};

export type DistributorRelationshipHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  distributorCompanyId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  maxDistanceKm?: Maybe<HavingIntFilter>;
};

/** An input for mutations affecting `DistributorRelationship` */
export type DistributorRelationshipInput = {
  id?: Maybe<Scalars['Int']>;
  distributorCompanyId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  strategy?: Maybe<DistributionStrategy>;
  maxDistanceKm?: Maybe<Scalars['Int']>;
  companyToDistributorCompanyId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInput>;
  companyToCompanyId?: Maybe<DistributorRelationshipCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInput>;
};

export type DistributorRelationshipMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  distributorCompanyId?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  companyChannelId?: Maybe<IntFilter>;
  maxDistanceKm?: Maybe<IntFilter>;
};

export type DistributorRelationshipMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of distributorCompanyId across the matching connection */
  distributorCompanyId?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Maximum of maxDistanceKm across the matching connection */
  maxDistanceKm?: Maybe<Scalars['Int']>;
};

export type DistributorRelationshipMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  distributorCompanyId?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  companyChannelId?: Maybe<IntFilter>;
  maxDistanceKm?: Maybe<IntFilter>;
};

export type DistributorRelationshipMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of distributorCompanyId across the matching connection */
  distributorCompanyId?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Minimum of maxDistanceKm across the matching connection */
  maxDistanceKm?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DistributorRelationshipNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `distributorRelationship` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type DistributorRelationshipNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `distributorRelationship` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** The fields on `distributorRelationship` to look up the row to update. */
export type DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyUsingDistributorRelationshipPkeyUpdate = {
  /** An object where the defined keys will be set on the `distributorRelationship` being updated. */
  patch: UpdateDistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `distributorRelationship` to look up the row to update. */
export type DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyUsingDistributorRelationshipUniqueUpdate = {
  /** An object where the defined keys will be set on the `distributorRelationship` being updated. */
  patch: UpdateDistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyPatch;
  distributorCompanyId: Scalars['Int'];
  companyId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `distributorRelationship` to look up the row to update. */
export type DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingDistributorRelationshipPkeyUpdate = {
  /** An object where the defined keys will be set on the `distributorRelationship` being updated. */
  patch: UpdateDistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `distributorRelationship` to look up the row to update. */
export type DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyUsingDistributorRelationshipUniqueUpdate = {
  /** An object where the defined keys will be set on the `distributorRelationship` being updated. */
  patch: UpdateDistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyPatch;
  distributorCompanyId: Scalars['Int'];
  companyId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `distributorRelationship` to look up the row to update. */
export type DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingDistributorRelationshipPkeyUpdate = {
  /** An object where the defined keys will be set on the `distributorRelationship` being updated. */
  patch: UpdateDistributorRelationshipOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `distributorRelationship` to look up the row to update. */
export type DistributorRelationshipOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyUsingDistributorRelationshipUniqueUpdate = {
  /** An object where the defined keys will be set on the `distributorRelationship` being updated. */
  patch: UpdateDistributorRelationshipOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyPatch;
  distributorCompanyId: Scalars['Int'];
  companyId: Scalars['Int'];
};

/** Represents an update to a `DistributorRelationship`. Fields that are set will be updated. */
export type DistributorRelationshipPatch = {
  id?: Maybe<Scalars['Int']>;
  distributorCompanyId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  strategy?: Maybe<DistributionStrategy>;
  maxDistanceKm?: Maybe<Scalars['Int']>;
  companyToDistributorCompanyId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInput>;
  companyToCompanyId?: Maybe<DistributorRelationshipCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInput>;
};

export type DistributorRelationshipStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  distributorCompanyId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  maxDistanceKm?: Maybe<BigFloatFilter>;
};

export type DistributorRelationshipStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of distributorCompanyId across the matching connection */
  distributorCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of maxDistanceKm across the matching connection */
  maxDistanceKm?: Maybe<Scalars['BigFloat']>;
};

export type DistributorRelationshipStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  distributorCompanyId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  maxDistanceKm?: Maybe<BigFloatFilter>;
};

export type DistributorRelationshipStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of distributorCompanyId across the matching connection */
  distributorCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of maxDistanceKm across the matching connection */
  maxDistanceKm?: Maybe<Scalars['BigFloat']>;
};

export type DistributorRelationshipSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  distributorCompanyId?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  companyChannelId?: Maybe<BigIntFilter>;
  maxDistanceKm?: Maybe<BigIntFilter>;
};

export type DistributorRelationshipSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of distributorCompanyId across the matching connection */
  distributorCompanyId: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of companyChannelId across the matching connection */
  companyChannelId: Scalars['BigInt'];
  /** Sum of maxDistanceKm across the matching connection */
  maxDistanceKm: Scalars['BigInt'];
};

export type DistributorRelationshipVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  distributorCompanyId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  maxDistanceKm?: Maybe<BigFloatFilter>;
};

export type DistributorRelationshipVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of distributorCompanyId across the matching connection */
  distributorCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of maxDistanceKm across the matching connection */
  maxDistanceKm?: Maybe<Scalars['BigFloat']>;
};

export type DistributorRelationshipVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  distributorCompanyId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  maxDistanceKm?: Maybe<BigFloatFilter>;
};

export type DistributorRelationshipVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of distributorCompanyId across the matching connection */
  distributorCompanyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of maxDistanceKm across the matching connection */
  maxDistanceKm?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DistributorRelationship` values. */
export type DistributorRelationshipsConnection = {
  /** A list of `DistributorRelationship` objects. */
  nodes: Array<DistributorRelationship>;
  /** A list of edges which contains the `DistributorRelationship` and cursor to aid in pagination. */
  edges: Array<DistributorRelationshipsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DistributorRelationship` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DistributorRelationshipAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DistributorRelationshipAggregates>>;
};


/** A connection to a list of `DistributorRelationship` values. */
export type DistributorRelationshipsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DistributorRelationshipGroupBy>;
  having?: Maybe<DistributorRelationshipHavingInput>;
};

/** A `DistributorRelationship` edge in the connection. */
export type DistributorRelationshipsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DistributorRelationship` at the end of the edge. */
  node: DistributorRelationship;
};

/** Methods to use when ordering `DistributorRelationship`. */
export enum DistributorRelationshipsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  DistributorCompanyIdAsc = 'DISTRIBUTOR_COMPANY_ID_ASC',
  DistributorCompanyIdDesc = 'DISTRIBUTOR_COMPANY_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompanyChannelIdAsc = 'COMPANY_CHANNEL_ID_ASC',
  CompanyChannelIdDesc = 'COMPANY_CHANNEL_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  StrategyAsc = 'STRATEGY_ASC',
  StrategyDesc = 'STRATEGY_DESC',
  MaxDistanceKmAsc = 'MAX_DISTANCE_KM_ASC',
  MaxDistanceKmDesc = 'MAX_DISTANCE_KM_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type EmailTemplate = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  name: Scalars['String'];
  parents?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type EmailTemplateAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EmailTemplateDistinctCountAggregates>;
};

/**
 * A condition to be used against `EmailTemplate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EmailTemplateCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `parents` field. */
  parents?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `subject` field. */
  subject?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `body` field. */
  body?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EmailTemplateDistinctCountAggregates = {
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parents across the matching connection */
  parents?: Maybe<Scalars['BigInt']>;
  /** Distinct count of subject across the matching connection */
  subject?: Maybe<Scalars['BigInt']>;
  /** Distinct count of body across the matching connection */
  body?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `EmailTemplate` object types. All fields are combined with a logical ‘and.’ */
export type EmailTemplateFilter = {
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `parents` field. */
  parents?: Maybe<StringListFilter>;
  /** Filter by the object’s `subject` field. */
  subject?: Maybe<StringFilter>;
  /** Filter by the object’s `body` field. */
  body?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EmailTemplateFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EmailTemplateFilter>>;
  /** Negates the expression. */
  not?: Maybe<EmailTemplateFilter>;
};

/** An input for mutations affecting `EmailTemplate` */
export type EmailTemplateInput = {
  name: Scalars['String'];
  parents?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `EmailTemplate`. Fields that are set will be updated. */
export type EmailTemplatePatch = {
  name?: Maybe<Scalars['String']>;
  parents?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `EmailTemplate` values. */
export type EmailTemplatesConnection = {
  /** A list of `EmailTemplate` objects. */
  nodes: Array<EmailTemplate>;
  /** A list of edges which contains the `EmailTemplate` and cursor to aid in pagination. */
  edges: Array<EmailTemplatesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EmailTemplate` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EmailTemplateAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EmailTemplateAggregates>>;
};


/** A connection to a list of `EmailTemplate` values. */
export type EmailTemplatesConnectionGroupedAggregatesArgs = {
  groupBy: Array<EmailTemplatesGroupBy>;
  having?: Maybe<EmailTemplatesHavingInput>;
};

/** A `EmailTemplate` edge in the connection. */
export type EmailTemplatesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EmailTemplate` at the end of the edge. */
  node: EmailTemplate;
};

/** Grouping methods for `EmailTemplate` for usage during aggregation. */
export enum EmailTemplatesGroupBy {
  Parents = 'PARENTS',
  Subject = 'SUBJECT',
  Body = 'BODY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type EmailTemplatesHavingAverageInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingDistinctCountInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `EmailTemplate` aggregates. */
export type EmailTemplatesHavingInput = {
  AND?: Maybe<Array<EmailTemplatesHavingInput>>;
  OR?: Maybe<Array<EmailTemplatesHavingInput>>;
  sum?: Maybe<EmailTemplatesHavingSumInput>;
  distinctCount?: Maybe<EmailTemplatesHavingDistinctCountInput>;
  min?: Maybe<EmailTemplatesHavingMinInput>;
  max?: Maybe<EmailTemplatesHavingMaxInput>;
  average?: Maybe<EmailTemplatesHavingAverageInput>;
  stddevSample?: Maybe<EmailTemplatesHavingStddevSampleInput>;
  stddevPopulation?: Maybe<EmailTemplatesHavingStddevPopulationInput>;
  varianceSample?: Maybe<EmailTemplatesHavingVarianceSampleInput>;
  variancePopulation?: Maybe<EmailTemplatesHavingVariancePopulationInput>;
};

export type EmailTemplatesHavingMaxInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingMinInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingStddevPopulationInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingStddevSampleInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingSumInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingVariancePopulationInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingVarianceSampleInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `EmailTemplate`. */
export enum EmailTemplatesOrderBy {
  Natural = 'NATURAL',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ParentsAsc = 'PARENTS_ASC',
  ParentsDesc = 'PARENTS_DESC',
  SubjectAsc = 'SUBJECT_ASC',
  SubjectDesc = 'SUBJECT_DESC',
  BodyAsc = 'BODY_ASC',
  BodyDesc = 'BODY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type EnableProductsForCompanyInput = {
  companyId: Scalars['Int'];
  products: Array<ProductType>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  equifaxSecurityCode?: Maybe<Scalars['String']>;
};

export type EnableProductsForCompanyResponse = {
  companyId: Scalars['Int'];
  companyProducts?: Maybe<Array<BasicProductDetails>>;
  success: Scalars['Boolean'];
};

export type EquifaxEntity = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type EquifaxLender = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  equifaxId: Scalars['String'];
  name: Scalars['String'];
  display: Scalars['Boolean'];
  reviewedAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  lenderId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  /** Reads a single `Lender` that is related to this `EquifaxLender`. */
  lender?: Maybe<Lender>;
  /** Reads and enables pagination through a set of `TradeLine`. */
  tradeLines: TradeLinesConnection;
  /** Reads and enables pagination through a set of `CreditBureau`. */
  creditBureausByTradeLineEquifaxLenderIdAndBureauId: EquifaxLenderCreditBureausByTradeLineEquifaxLenderIdAndBureauIdManyToManyConnection;
};


export type EquifaxLenderTradeLinesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TradeLinesOrderBy>>;
  condition?: Maybe<TradeLineCondition>;
  filter?: Maybe<TradeLineFilter>;
};


export type EquifaxLenderCreditBureausByTradeLineEquifaxLenderIdAndBureauIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CreditBureausOrderBy>>;
  condition?: Maybe<CreditBureauCondition>;
  filter?: Maybe<CreditBureauFilter>;
};

export type EquifaxLenderAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<EquifaxLenderSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EquifaxLenderDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<EquifaxLenderMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<EquifaxLenderMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<EquifaxLenderAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<EquifaxLenderStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<EquifaxLenderStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<EquifaxLenderVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<EquifaxLenderVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `EquifaxLender` object types. */
export type EquifaxLenderAggregatesFilter = {
  /** A filter that must pass for the relevant `EquifaxLender` object to be included within the aggregate. */
  filter?: Maybe<EquifaxLenderFilter>;
  /** Sum aggregate over matching `EquifaxLender` objects. */
  sum?: Maybe<EquifaxLenderSumAggregateFilter>;
  /** Distinct count aggregate over matching `EquifaxLender` objects. */
  distinctCount?: Maybe<EquifaxLenderDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `EquifaxLender` objects. */
  min?: Maybe<EquifaxLenderMinAggregateFilter>;
  /** Maximum aggregate over matching `EquifaxLender` objects. */
  max?: Maybe<EquifaxLenderMaxAggregateFilter>;
  /** Mean average aggregate over matching `EquifaxLender` objects. */
  average?: Maybe<EquifaxLenderAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `EquifaxLender` objects. */
  stddevSample?: Maybe<EquifaxLenderStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `EquifaxLender` objects. */
  stddevPopulation?: Maybe<EquifaxLenderStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `EquifaxLender` objects. */
  varianceSample?: Maybe<EquifaxLenderVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `EquifaxLender` objects. */
  variancePopulation?: Maybe<EquifaxLenderVariancePopulationAggregateFilter>;
};

export type EquifaxLenderAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
};

export type EquifaxLenderAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `EquifaxLender` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EquifaxLenderCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `equifaxId` field. */
  equifaxId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `display` field. */
  display?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `reviewedAt` field. */
  reviewedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `lenderId` field. */
  lenderId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `phone` field. */
  phone?: Maybe<Scalars['String']>;
};

/** A connection to a list of `CreditBureau` values, with data from `TradeLine`. */
export type EquifaxLenderCreditBureausByTradeLineEquifaxLenderIdAndBureauIdManyToManyConnection = {
  /** A list of `CreditBureau` objects. */
  nodes: Array<CreditBureau>;
  /** A list of edges which contains the `CreditBureau`, info from the `TradeLine`, and the cursor to aid in pagination. */
  edges: Array<EquifaxLenderCreditBureausByTradeLineEquifaxLenderIdAndBureauIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CreditBureau` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CreditBureauAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CreditBureauAggregates>>;
};


/** A connection to a list of `CreditBureau` values, with data from `TradeLine`. */
export type EquifaxLenderCreditBureausByTradeLineEquifaxLenderIdAndBureauIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CreditBureauGroupBy>;
  having?: Maybe<CreditBureauHavingInput>;
};

/** A `CreditBureau` edge in the connection, with data from `TradeLine`. */
export type EquifaxLenderCreditBureausByTradeLineEquifaxLenderIdAndBureauIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CreditBureau` at the end of the edge. */
  node: CreditBureau;
  /** Reads and enables pagination through a set of `TradeLine`. */
  tradeLinesByBureauId: TradeLinesConnection;
};


/** A `CreditBureau` edge in the connection, with data from `TradeLine`. */
export type EquifaxLenderCreditBureausByTradeLineEquifaxLenderIdAndBureauIdManyToManyEdgeTradeLinesByBureauIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TradeLinesOrderBy>>;
  condition?: Maybe<TradeLineCondition>;
  filter?: Maybe<TradeLineFilter>;
};

export type EquifaxLenderDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  equifaxId?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  display?: Maybe<BigIntFilter>;
  reviewedAt?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  lenderId?: Maybe<BigIntFilter>;
  phone?: Maybe<BigIntFilter>;
};

export type EquifaxLenderDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of equifaxId across the matching connection */
  equifaxId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of display across the matching connection */
  display?: Maybe<Scalars['BigInt']>;
  /** Distinct count of reviewedAt across the matching connection */
  reviewedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of phone across the matching connection */
  phone?: Maybe<Scalars['BigInt']>;
};

/** The fields on `equifaxLender` to look up the row to connect. */
export type EquifaxLenderEquifaxLendersEquifaxIdNameKeyConnect = {
  equifaxId: Scalars['String'];
  name: Scalars['String'];
};

/** The fields on `equifaxLender` to look up the row to delete. */
export type EquifaxLenderEquifaxLendersEquifaxIdNameKeyDelete = {
  equifaxId: Scalars['String'];
  name: Scalars['String'];
};

/** The fields on `equifaxLender` to look up the row to connect. */
export type EquifaxLenderEquifaxLendersPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `equifaxLender` to look up the row to delete. */
export type EquifaxLenderEquifaxLendersPkeyDelete = {
  id: Scalars['Int'];
};

/** A filter to be used against `EquifaxLender` object types. All fields are combined with a logical ‘and.’ */
export type EquifaxLenderFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `equifaxId` field. */
  equifaxId?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `display` field. */
  display?: Maybe<BooleanFilter>;
  /** Filter by the object’s `reviewedAt` field. */
  reviewedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lenderId` field. */
  lenderId?: Maybe<IntFilter>;
  /** Filter by the object’s `phone` field. */
  phone?: Maybe<StringFilter>;
  /** Filter by the object’s `tradeLines` relation. */
  tradeLines?: Maybe<EquifaxLenderToManyTradeLineFilter>;
  /** Some related `tradeLines` exist. */
  tradeLinesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lender` relation. */
  lender?: Maybe<LenderFilter>;
  /** A related `lender` exists. */
  lenderExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EquifaxLenderFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EquifaxLenderFilter>>;
  /** Negates the expression. */
  not?: Maybe<EquifaxLenderFilter>;
};

/** An input for mutations affecting `EquifaxLender` */
export type EquifaxLenderInput = {
  id?: Maybe<Scalars['Int']>;
  equifaxId: Scalars['String'];
  name: Scalars['String'];
  display?: Maybe<Scalars['Boolean']>;
  reviewedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  lenderToLenderId?: Maybe<EquifaxLendersLenderIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineEquifaxLenderIdFkeyInverseInput>;
};

export type EquifaxLenderMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderId?: Maybe<IntFilter>;
};

export type EquifaxLenderMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['Int']>;
};

export type EquifaxLenderMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderId?: Maybe<IntFilter>;
};

export type EquifaxLenderMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type EquifaxLenderNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `equifaxLender` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type EquifaxLenderNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `equifaxLender` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type EquifaxLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lender` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lender` being updated. */
  patch: LenderPatch;
};

/** The fields on `equifaxLender` to look up the row to update. */
export type EquifaxLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyUsingEquifaxLendersEquifaxIdNameKeyUpdate = {
  /** An object where the defined keys will be set on the `equifaxLender` being updated. */
  patch: UpdateEquifaxLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyPatch;
  equifaxId: Scalars['String'];
  name: Scalars['String'];
};

/** The fields on `equifaxLender` to look up the row to update. */
export type EquifaxLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyUsingEquifaxLendersPkeyUpdate = {
  /** An object where the defined keys will be set on the `equifaxLender` being updated. */
  patch: UpdateEquifaxLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type EquifaxLenderOnTradeLineForTradeLineEquifaxLenderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `tradeLine` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `tradeLine` being updated. */
  patch: TradeLinePatch;
};

/** The fields on `equifaxLender` to look up the row to update. */
export type EquifaxLenderOnTradeLineForTradeLineEquifaxLenderIdFkeyUsingEquifaxLendersEquifaxIdNameKeyUpdate = {
  /** An object where the defined keys will be set on the `equifaxLender` being updated. */
  patch: UpdateEquifaxLenderOnTradeLineForTradeLineEquifaxLenderIdFkeyPatch;
  equifaxId: Scalars['String'];
  name: Scalars['String'];
};

/** The fields on `equifaxLender` to look up the row to update. */
export type EquifaxLenderOnTradeLineForTradeLineEquifaxLenderIdFkeyUsingEquifaxLendersPkeyUpdate = {
  /** An object where the defined keys will be set on the `equifaxLender` being updated. */
  patch: UpdateEquifaxLenderOnTradeLineForTradeLineEquifaxLenderIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `EquifaxLender`. Fields that are set will be updated. */
export type EquifaxLenderPatch = {
  id?: Maybe<Scalars['Int']>;
  equifaxId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['Boolean']>;
  reviewedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  lenderToLenderId?: Maybe<EquifaxLendersLenderIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineEquifaxLenderIdFkeyInverseInput>;
};

export type EquifaxLenderStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
};

export type EquifaxLenderStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
};

export type EquifaxLenderStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
};

export type EquifaxLenderStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
};

export type EquifaxLenderSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderId?: Maybe<BigIntFilter>;
};

export type EquifaxLenderSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lenderId across the matching connection */
  lenderId: Scalars['BigInt'];
};

/** A filter to be used against many `TradeLine` object types. All fields are combined with a logical ‘and.’ */
export type EquifaxLenderToManyTradeLineFilter = {
  /** Every related `TradeLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TradeLineFilter>;
  /** Some related `TradeLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TradeLineFilter>;
  /** No related `TradeLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TradeLineFilter>;
  /** Aggregates across related `TradeLine` match the filter criteria. */
  aggregates?: Maybe<TradeLineAggregatesFilter>;
};

export type EquifaxLenderVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
};

export type EquifaxLenderVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
};

export type EquifaxLenderVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
};

export type EquifaxLenderVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `EquifaxLender` values. */
export type EquifaxLendersConnection = {
  /** A list of `EquifaxLender` objects. */
  nodes: Array<EquifaxLender>;
  /** A list of edges which contains the `EquifaxLender` and cursor to aid in pagination. */
  edges: Array<EquifaxLendersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EquifaxLender` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EquifaxLenderAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EquifaxLenderAggregates>>;
};


/** A connection to a list of `EquifaxLender` values. */
export type EquifaxLendersConnectionGroupedAggregatesArgs = {
  groupBy: Array<EquifaxLendersGroupBy>;
  having?: Maybe<EquifaxLendersHavingInput>;
};

/** A `EquifaxLender` edge in the connection. */
export type EquifaxLendersEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EquifaxLender` at the end of the edge. */
  node: EquifaxLender;
};

/** Grouping methods for `EquifaxLender` for usage during aggregation. */
export enum EquifaxLendersGroupBy {
  EquifaxId = 'EQUIFAX_ID',
  Name = 'NAME',
  Display = 'DISPLAY',
  ReviewedAt = 'REVIEWED_AT',
  ReviewedAtTruncatedToHour = 'REVIEWED_AT_TRUNCATED_TO_HOUR',
  ReviewedAtTruncatedToDay = 'REVIEWED_AT_TRUNCATED_TO_DAY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  LenderId = 'LENDER_ID',
  Phone = 'PHONE'
}

export type EquifaxLendersHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
};

export type EquifaxLendersHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
};

/** Conditions for `EquifaxLender` aggregates. */
export type EquifaxLendersHavingInput = {
  AND?: Maybe<Array<EquifaxLendersHavingInput>>;
  OR?: Maybe<Array<EquifaxLendersHavingInput>>;
  sum?: Maybe<EquifaxLendersHavingSumInput>;
  distinctCount?: Maybe<EquifaxLendersHavingDistinctCountInput>;
  min?: Maybe<EquifaxLendersHavingMinInput>;
  max?: Maybe<EquifaxLendersHavingMaxInput>;
  average?: Maybe<EquifaxLendersHavingAverageInput>;
  stddevSample?: Maybe<EquifaxLendersHavingStddevSampleInput>;
  stddevPopulation?: Maybe<EquifaxLendersHavingStddevPopulationInput>;
  varianceSample?: Maybe<EquifaxLendersHavingVarianceSampleInput>;
  variancePopulation?: Maybe<EquifaxLendersHavingVariancePopulationInput>;
};

export type EquifaxLendersHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
};

export type EquifaxLendersHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
};

export type EquifaxLendersHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
};

export type EquifaxLendersHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
};

export type EquifaxLendersHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
};

export type EquifaxLendersHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
};

export type EquifaxLendersHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
};

/** The `equifaxLender` to be created by this mutation. */
export type EquifaxLendersLenderIdFkeyEquifaxLendersCreateInput = {
  id?: Maybe<Scalars['Int']>;
  equifaxId: Scalars['String'];
  name: Scalars['String'];
  display?: Maybe<Scalars['Boolean']>;
  reviewedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  lenderToLenderId?: Maybe<EquifaxLendersLenderIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineEquifaxLenderIdFkeyInverseInput>;
};

/** Input for the nested mutation of `lender` in the `EquifaxLenderInput` mutation. */
export type EquifaxLendersLenderIdFkeyInput = {
  /** The primary key(s) for `lender` for the far side of the relationship. */
  connectById?: Maybe<LenderLenderPkeyConnect>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  connectByName?: Maybe<LenderLenderNameKeyConnect>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  connectByNodeId?: Maybe<LenderNodeIdConnect>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  deleteById?: Maybe<LenderLenderPkeyDelete>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  deleteByName?: Maybe<LenderLenderNameKeyDelete>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LenderNodeIdDelete>;
  /** The primary key(s) and patch data for `lender` for the far side of the relationship. */
  updateById?: Maybe<LenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyUsingLenderPkeyUpdate>;
  /** The primary key(s) and patch data for `lender` for the far side of the relationship. */
  updateByName?: Maybe<LenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyUsingLenderNameKeyUpdate>;
  /** The primary key(s) and patch data for `lender` for the far side of the relationship. */
  updateByNodeId?: Maybe<EquifaxLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyNodeIdUpdate>;
  /** A `LenderInput` object that will be created and connected to this object. */
  create?: Maybe<EquifaxLendersLenderIdFkeyLenderCreateInput>;
};

/** Input for the nested mutation of `equifaxLender` in the `LenderInput` mutation. */
export type EquifaxLendersLenderIdFkeyInverseInput = {
  /** Flag indicating whether all other `equifaxLender` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  connectById?: Maybe<Array<EquifaxLenderEquifaxLendersPkeyConnect>>;
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  connectByEquifaxIdAndName?: Maybe<Array<EquifaxLenderEquifaxLendersEquifaxIdNameKeyConnect>>;
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<EquifaxLenderNodeIdConnect>>;
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  deleteById?: Maybe<Array<EquifaxLenderEquifaxLendersPkeyDelete>>;
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  deleteByEquifaxIdAndName?: Maybe<Array<EquifaxLenderEquifaxLendersEquifaxIdNameKeyDelete>>;
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<EquifaxLenderNodeIdDelete>>;
  /** The primary key(s) and patch data for `equifaxLender` for the far side of the relationship. */
  updateById?: Maybe<Array<EquifaxLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyUsingEquifaxLendersPkeyUpdate>>;
  /** The primary key(s) and patch data for `equifaxLender` for the far side of the relationship. */
  updateByEquifaxIdAndName?: Maybe<Array<EquifaxLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyUsingEquifaxLendersEquifaxIdNameKeyUpdate>>;
  /** The primary key(s) and patch data for `equifaxLender` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyNodeIdUpdate>>;
  /** A `EquifaxLenderInput` object that will be created and connected to this object. */
  create?: Maybe<Array<EquifaxLendersLenderIdFkeyEquifaxLendersCreateInput>>;
};

/** The `lender` to be created by this mutation. */
export type EquifaxLendersLenderIdFkeyLenderCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  bio?: Maybe<Scalars['String']>;
  equifaxLendersUsingId?: Maybe<EquifaxLendersLenderIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderIdFkeyInverseInput>;
  lenderProgramsUsingId?: Maybe<LenderProgramLenderIdFkeyInverseInput>;
};

/** Methods to use when ordering `EquifaxLender`. */
export enum EquifaxLendersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  EquifaxIdAsc = 'EQUIFAX_ID_ASC',
  EquifaxIdDesc = 'EQUIFAX_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayAsc = 'DISPLAY_ASC',
  DisplayDesc = 'DISPLAY_DESC',
  ReviewedAtAsc = 'REVIEWED_AT_ASC',
  ReviewedAtDesc = 'REVIEWED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  LenderIdAsc = 'LENDER_ID_ASC',
  LenderIdDesc = 'LENDER_ID_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TradeLinesCountAsc = 'TRADE_LINES_COUNT_ASC',
  TradeLinesCountDesc = 'TRADE_LINES_COUNT_DESC',
  TradeLinesSumIdAsc = 'TRADE_LINES_SUM_ID_ASC',
  TradeLinesSumIdDesc = 'TRADE_LINES_SUM_ID_DESC',
  TradeLinesSumBureauIdAsc = 'TRADE_LINES_SUM_BUREAU_ID_ASC',
  TradeLinesSumBureauIdDesc = 'TRADE_LINES_SUM_BUREAU_ID_DESC',
  TradeLinesSumEquifaxLenderIdAsc = 'TRADE_LINES_SUM_EQUIFAX_LENDER_ID_ASC',
  TradeLinesSumEquifaxLenderIdDesc = 'TRADE_LINES_SUM_EQUIFAX_LENDER_ID_DESC',
  TradeLinesSumTradeDateAsc = 'TRADE_LINES_SUM_TRADE_DATE_ASC',
  TradeLinesSumTradeDateDesc = 'TRADE_LINES_SUM_TRADE_DATE_DESC',
  TradeLinesSumCreditorIdAsc = 'TRADE_LINES_SUM_CREDITOR_ID_ASC',
  TradeLinesSumCreditorIdDesc = 'TRADE_LINES_SUM_CREDITOR_ID_DESC',
  TradeLinesSumCreditorNameAsc = 'TRADE_LINES_SUM_CREDITOR_NAME_ASC',
  TradeLinesSumCreditorNameDesc = 'TRADE_LINES_SUM_CREDITOR_NAME_DESC',
  TradeLinesSumCreditorPhoneAsc = 'TRADE_LINES_SUM_CREDITOR_PHONE_ASC',
  TradeLinesSumCreditorPhoneDesc = 'TRADE_LINES_SUM_CREDITOR_PHONE_DESC',
  TradeLinesSumAccountNumberAsc = 'TRADE_LINES_SUM_ACCOUNT_NUMBER_ASC',
  TradeLinesSumAccountNumberDesc = 'TRADE_LINES_SUM_ACCOUNT_NUMBER_DESC',
  TradeLinesSumAssociationAsc = 'TRADE_LINES_SUM_ASSOCIATION_ASC',
  TradeLinesSumAssociationDesc = 'TRADE_LINES_SUM_ASSOCIATION_DESC',
  TradeLinesSumUpdateSourceAsc = 'TRADE_LINES_SUM_UPDATE_SOURCE_ASC',
  TradeLinesSumUpdateSourceDesc = 'TRADE_LINES_SUM_UPDATE_SOURCE_DESC',
  TradeLinesSumHighCreditAmountAsc = 'TRADE_LINES_SUM_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesSumHighCreditAmountDesc = 'TRADE_LINES_SUM_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesSumBalanceAmountAsc = 'TRADE_LINES_SUM_BALANCE_AMOUNT_ASC',
  TradeLinesSumBalanceAmountDesc = 'TRADE_LINES_SUM_BALANCE_AMOUNT_DESC',
  TradeLinesSumPaymentTermAsc = 'TRADE_LINES_SUM_PAYMENT_TERM_ASC',
  TradeLinesSumPaymentTermDesc = 'TRADE_LINES_SUM_PAYMENT_TERM_DESC',
  TradeLinesSumPaymentTermAmountAsc = 'TRADE_LINES_SUM_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesSumPaymentTermAmountDesc = 'TRADE_LINES_SUM_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesSumPastDueAmountAsc = 'TRADE_LINES_SUM_PAST_DUE_AMOUNT_ASC',
  TradeLinesSumPastDueAmountDesc = 'TRADE_LINES_SUM_PAST_DUE_AMOUNT_DESC',
  TradeLinesSumMonthsReviewedAsc = 'TRADE_LINES_SUM_MONTHS_REVIEWED_ASC',
  TradeLinesSumMonthsReviewedDesc = 'TRADE_LINES_SUM_MONTHS_REVIEWED_DESC',
  TradeLinesSumDateOpenedAsc = 'TRADE_LINES_SUM_DATE_OPENED_ASC',
  TradeLinesSumDateOpenedDesc = 'TRADE_LINES_SUM_DATE_OPENED_DESC',
  TradeLinesSumDateLastActivityOrPaymentAsc = 'TRADE_LINES_SUM_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesSumDateLastActivityOrPaymentDesc = 'TRADE_LINES_SUM_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesSumPortfolioTypeDescriptionAsc = 'TRADE_LINES_SUM_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesSumPortfolioTypeDescriptionDesc = 'TRADE_LINES_SUM_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesSumPortfolioTypeCodeAsc = 'TRADE_LINES_SUM_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesSumPortfolioTypeCodeDesc = 'TRADE_LINES_SUM_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesSumPaymentRateDescriptionAsc = 'TRADE_LINES_SUM_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesSumPaymentRateDescriptionDesc = 'TRADE_LINES_SUM_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesSumPaymentRateCodeAsc = 'TRADE_LINES_SUM_PAYMENT_RATE_CODE_ASC',
  TradeLinesSumPaymentRateCodeDesc = 'TRADE_LINES_SUM_PAYMENT_RATE_CODE_DESC',
  TradeLinesSumPast_30Asc = 'TRADE_LINES_SUM_PAST_30_ASC',
  TradeLinesSumPast_30Desc = 'TRADE_LINES_SUM_PAST_30_DESC',
  TradeLinesSumPast_60Asc = 'TRADE_LINES_SUM_PAST_60_ASC',
  TradeLinesSumPast_60Desc = 'TRADE_LINES_SUM_PAST_60_DESC',
  TradeLinesSumPast_90Asc = 'TRADE_LINES_SUM_PAST_90_ASC',
  TradeLinesSumPast_90Desc = 'TRADE_LINES_SUM_PAST_90_DESC',
  TradeLinesSumLastSlowPaymentAsc = 'TRADE_LINES_SUM_LAST_SLOW_PAYMENT_ASC',
  TradeLinesSumLastSlowPaymentDesc = 'TRADE_LINES_SUM_LAST_SLOW_PAYMENT_DESC',
  TradeLinesSumNarrativesAsc = 'TRADE_LINES_SUM_NARRATIVES_ASC',
  TradeLinesSumNarrativesDesc = 'TRADE_LINES_SUM_NARRATIVES_DESC',
  TradeLinesSumCreatedAtAsc = 'TRADE_LINES_SUM_CREATED_AT_ASC',
  TradeLinesSumCreatedAtDesc = 'TRADE_LINES_SUM_CREATED_AT_DESC',
  TradeLinesSumUpdatedAtAsc = 'TRADE_LINES_SUM_UPDATED_AT_ASC',
  TradeLinesSumUpdatedAtDesc = 'TRADE_LINES_SUM_UPDATED_AT_DESC',
  TradeLinesDistinctCountIdAsc = 'TRADE_LINES_DISTINCT_COUNT_ID_ASC',
  TradeLinesDistinctCountIdDesc = 'TRADE_LINES_DISTINCT_COUNT_ID_DESC',
  TradeLinesDistinctCountBureauIdAsc = 'TRADE_LINES_DISTINCT_COUNT_BUREAU_ID_ASC',
  TradeLinesDistinctCountBureauIdDesc = 'TRADE_LINES_DISTINCT_COUNT_BUREAU_ID_DESC',
  TradeLinesDistinctCountEquifaxLenderIdAsc = 'TRADE_LINES_DISTINCT_COUNT_EQUIFAX_LENDER_ID_ASC',
  TradeLinesDistinctCountEquifaxLenderIdDesc = 'TRADE_LINES_DISTINCT_COUNT_EQUIFAX_LENDER_ID_DESC',
  TradeLinesDistinctCountTradeDateAsc = 'TRADE_LINES_DISTINCT_COUNT_TRADE_DATE_ASC',
  TradeLinesDistinctCountTradeDateDesc = 'TRADE_LINES_DISTINCT_COUNT_TRADE_DATE_DESC',
  TradeLinesDistinctCountCreditorIdAsc = 'TRADE_LINES_DISTINCT_COUNT_CREDITOR_ID_ASC',
  TradeLinesDistinctCountCreditorIdDesc = 'TRADE_LINES_DISTINCT_COUNT_CREDITOR_ID_DESC',
  TradeLinesDistinctCountCreditorNameAsc = 'TRADE_LINES_DISTINCT_COUNT_CREDITOR_NAME_ASC',
  TradeLinesDistinctCountCreditorNameDesc = 'TRADE_LINES_DISTINCT_COUNT_CREDITOR_NAME_DESC',
  TradeLinesDistinctCountCreditorPhoneAsc = 'TRADE_LINES_DISTINCT_COUNT_CREDITOR_PHONE_ASC',
  TradeLinesDistinctCountCreditorPhoneDesc = 'TRADE_LINES_DISTINCT_COUNT_CREDITOR_PHONE_DESC',
  TradeLinesDistinctCountAccountNumberAsc = 'TRADE_LINES_DISTINCT_COUNT_ACCOUNT_NUMBER_ASC',
  TradeLinesDistinctCountAccountNumberDesc = 'TRADE_LINES_DISTINCT_COUNT_ACCOUNT_NUMBER_DESC',
  TradeLinesDistinctCountAssociationAsc = 'TRADE_LINES_DISTINCT_COUNT_ASSOCIATION_ASC',
  TradeLinesDistinctCountAssociationDesc = 'TRADE_LINES_DISTINCT_COUNT_ASSOCIATION_DESC',
  TradeLinesDistinctCountUpdateSourceAsc = 'TRADE_LINES_DISTINCT_COUNT_UPDATE_SOURCE_ASC',
  TradeLinesDistinctCountUpdateSourceDesc = 'TRADE_LINES_DISTINCT_COUNT_UPDATE_SOURCE_DESC',
  TradeLinesDistinctCountHighCreditAmountAsc = 'TRADE_LINES_DISTINCT_COUNT_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesDistinctCountHighCreditAmountDesc = 'TRADE_LINES_DISTINCT_COUNT_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesDistinctCountBalanceAmountAsc = 'TRADE_LINES_DISTINCT_COUNT_BALANCE_AMOUNT_ASC',
  TradeLinesDistinctCountBalanceAmountDesc = 'TRADE_LINES_DISTINCT_COUNT_BALANCE_AMOUNT_DESC',
  TradeLinesDistinctCountPaymentTermAsc = 'TRADE_LINES_DISTINCT_COUNT_PAYMENT_TERM_ASC',
  TradeLinesDistinctCountPaymentTermDesc = 'TRADE_LINES_DISTINCT_COUNT_PAYMENT_TERM_DESC',
  TradeLinesDistinctCountPaymentTermAmountAsc = 'TRADE_LINES_DISTINCT_COUNT_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesDistinctCountPaymentTermAmountDesc = 'TRADE_LINES_DISTINCT_COUNT_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesDistinctCountPastDueAmountAsc = 'TRADE_LINES_DISTINCT_COUNT_PAST_DUE_AMOUNT_ASC',
  TradeLinesDistinctCountPastDueAmountDesc = 'TRADE_LINES_DISTINCT_COUNT_PAST_DUE_AMOUNT_DESC',
  TradeLinesDistinctCountMonthsReviewedAsc = 'TRADE_LINES_DISTINCT_COUNT_MONTHS_REVIEWED_ASC',
  TradeLinesDistinctCountMonthsReviewedDesc = 'TRADE_LINES_DISTINCT_COUNT_MONTHS_REVIEWED_DESC',
  TradeLinesDistinctCountDateOpenedAsc = 'TRADE_LINES_DISTINCT_COUNT_DATE_OPENED_ASC',
  TradeLinesDistinctCountDateOpenedDesc = 'TRADE_LINES_DISTINCT_COUNT_DATE_OPENED_DESC',
  TradeLinesDistinctCountDateLastActivityOrPaymentAsc = 'TRADE_LINES_DISTINCT_COUNT_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesDistinctCountDateLastActivityOrPaymentDesc = 'TRADE_LINES_DISTINCT_COUNT_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesDistinctCountPortfolioTypeDescriptionAsc = 'TRADE_LINES_DISTINCT_COUNT_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesDistinctCountPortfolioTypeDescriptionDesc = 'TRADE_LINES_DISTINCT_COUNT_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesDistinctCountPortfolioTypeCodeAsc = 'TRADE_LINES_DISTINCT_COUNT_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesDistinctCountPortfolioTypeCodeDesc = 'TRADE_LINES_DISTINCT_COUNT_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesDistinctCountPaymentRateDescriptionAsc = 'TRADE_LINES_DISTINCT_COUNT_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesDistinctCountPaymentRateDescriptionDesc = 'TRADE_LINES_DISTINCT_COUNT_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesDistinctCountPaymentRateCodeAsc = 'TRADE_LINES_DISTINCT_COUNT_PAYMENT_RATE_CODE_ASC',
  TradeLinesDistinctCountPaymentRateCodeDesc = 'TRADE_LINES_DISTINCT_COUNT_PAYMENT_RATE_CODE_DESC',
  TradeLinesDistinctCountPast_30Asc = 'TRADE_LINES_DISTINCT_COUNT_PAST_30_ASC',
  TradeLinesDistinctCountPast_30Desc = 'TRADE_LINES_DISTINCT_COUNT_PAST_30_DESC',
  TradeLinesDistinctCountPast_60Asc = 'TRADE_LINES_DISTINCT_COUNT_PAST_60_ASC',
  TradeLinesDistinctCountPast_60Desc = 'TRADE_LINES_DISTINCT_COUNT_PAST_60_DESC',
  TradeLinesDistinctCountPast_90Asc = 'TRADE_LINES_DISTINCT_COUNT_PAST_90_ASC',
  TradeLinesDistinctCountPast_90Desc = 'TRADE_LINES_DISTINCT_COUNT_PAST_90_DESC',
  TradeLinesDistinctCountLastSlowPaymentAsc = 'TRADE_LINES_DISTINCT_COUNT_LAST_SLOW_PAYMENT_ASC',
  TradeLinesDistinctCountLastSlowPaymentDesc = 'TRADE_LINES_DISTINCT_COUNT_LAST_SLOW_PAYMENT_DESC',
  TradeLinesDistinctCountNarrativesAsc = 'TRADE_LINES_DISTINCT_COUNT_NARRATIVES_ASC',
  TradeLinesDistinctCountNarrativesDesc = 'TRADE_LINES_DISTINCT_COUNT_NARRATIVES_DESC',
  TradeLinesDistinctCountCreatedAtAsc = 'TRADE_LINES_DISTINCT_COUNT_CREATED_AT_ASC',
  TradeLinesDistinctCountCreatedAtDesc = 'TRADE_LINES_DISTINCT_COUNT_CREATED_AT_DESC',
  TradeLinesDistinctCountUpdatedAtAsc = 'TRADE_LINES_DISTINCT_COUNT_UPDATED_AT_ASC',
  TradeLinesDistinctCountUpdatedAtDesc = 'TRADE_LINES_DISTINCT_COUNT_UPDATED_AT_DESC',
  TradeLinesMinIdAsc = 'TRADE_LINES_MIN_ID_ASC',
  TradeLinesMinIdDesc = 'TRADE_LINES_MIN_ID_DESC',
  TradeLinesMinBureauIdAsc = 'TRADE_LINES_MIN_BUREAU_ID_ASC',
  TradeLinesMinBureauIdDesc = 'TRADE_LINES_MIN_BUREAU_ID_DESC',
  TradeLinesMinEquifaxLenderIdAsc = 'TRADE_LINES_MIN_EQUIFAX_LENDER_ID_ASC',
  TradeLinesMinEquifaxLenderIdDesc = 'TRADE_LINES_MIN_EQUIFAX_LENDER_ID_DESC',
  TradeLinesMinTradeDateAsc = 'TRADE_LINES_MIN_TRADE_DATE_ASC',
  TradeLinesMinTradeDateDesc = 'TRADE_LINES_MIN_TRADE_DATE_DESC',
  TradeLinesMinCreditorIdAsc = 'TRADE_LINES_MIN_CREDITOR_ID_ASC',
  TradeLinesMinCreditorIdDesc = 'TRADE_LINES_MIN_CREDITOR_ID_DESC',
  TradeLinesMinCreditorNameAsc = 'TRADE_LINES_MIN_CREDITOR_NAME_ASC',
  TradeLinesMinCreditorNameDesc = 'TRADE_LINES_MIN_CREDITOR_NAME_DESC',
  TradeLinesMinCreditorPhoneAsc = 'TRADE_LINES_MIN_CREDITOR_PHONE_ASC',
  TradeLinesMinCreditorPhoneDesc = 'TRADE_LINES_MIN_CREDITOR_PHONE_DESC',
  TradeLinesMinAccountNumberAsc = 'TRADE_LINES_MIN_ACCOUNT_NUMBER_ASC',
  TradeLinesMinAccountNumberDesc = 'TRADE_LINES_MIN_ACCOUNT_NUMBER_DESC',
  TradeLinesMinAssociationAsc = 'TRADE_LINES_MIN_ASSOCIATION_ASC',
  TradeLinesMinAssociationDesc = 'TRADE_LINES_MIN_ASSOCIATION_DESC',
  TradeLinesMinUpdateSourceAsc = 'TRADE_LINES_MIN_UPDATE_SOURCE_ASC',
  TradeLinesMinUpdateSourceDesc = 'TRADE_LINES_MIN_UPDATE_SOURCE_DESC',
  TradeLinesMinHighCreditAmountAsc = 'TRADE_LINES_MIN_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesMinHighCreditAmountDesc = 'TRADE_LINES_MIN_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesMinBalanceAmountAsc = 'TRADE_LINES_MIN_BALANCE_AMOUNT_ASC',
  TradeLinesMinBalanceAmountDesc = 'TRADE_LINES_MIN_BALANCE_AMOUNT_DESC',
  TradeLinesMinPaymentTermAsc = 'TRADE_LINES_MIN_PAYMENT_TERM_ASC',
  TradeLinesMinPaymentTermDesc = 'TRADE_LINES_MIN_PAYMENT_TERM_DESC',
  TradeLinesMinPaymentTermAmountAsc = 'TRADE_LINES_MIN_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesMinPaymentTermAmountDesc = 'TRADE_LINES_MIN_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesMinPastDueAmountAsc = 'TRADE_LINES_MIN_PAST_DUE_AMOUNT_ASC',
  TradeLinesMinPastDueAmountDesc = 'TRADE_LINES_MIN_PAST_DUE_AMOUNT_DESC',
  TradeLinesMinMonthsReviewedAsc = 'TRADE_LINES_MIN_MONTHS_REVIEWED_ASC',
  TradeLinesMinMonthsReviewedDesc = 'TRADE_LINES_MIN_MONTHS_REVIEWED_DESC',
  TradeLinesMinDateOpenedAsc = 'TRADE_LINES_MIN_DATE_OPENED_ASC',
  TradeLinesMinDateOpenedDesc = 'TRADE_LINES_MIN_DATE_OPENED_DESC',
  TradeLinesMinDateLastActivityOrPaymentAsc = 'TRADE_LINES_MIN_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesMinDateLastActivityOrPaymentDesc = 'TRADE_LINES_MIN_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesMinPortfolioTypeDescriptionAsc = 'TRADE_LINES_MIN_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesMinPortfolioTypeDescriptionDesc = 'TRADE_LINES_MIN_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesMinPortfolioTypeCodeAsc = 'TRADE_LINES_MIN_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesMinPortfolioTypeCodeDesc = 'TRADE_LINES_MIN_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesMinPaymentRateDescriptionAsc = 'TRADE_LINES_MIN_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesMinPaymentRateDescriptionDesc = 'TRADE_LINES_MIN_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesMinPaymentRateCodeAsc = 'TRADE_LINES_MIN_PAYMENT_RATE_CODE_ASC',
  TradeLinesMinPaymentRateCodeDesc = 'TRADE_LINES_MIN_PAYMENT_RATE_CODE_DESC',
  TradeLinesMinPast_30Asc = 'TRADE_LINES_MIN_PAST_30_ASC',
  TradeLinesMinPast_30Desc = 'TRADE_LINES_MIN_PAST_30_DESC',
  TradeLinesMinPast_60Asc = 'TRADE_LINES_MIN_PAST_60_ASC',
  TradeLinesMinPast_60Desc = 'TRADE_LINES_MIN_PAST_60_DESC',
  TradeLinesMinPast_90Asc = 'TRADE_LINES_MIN_PAST_90_ASC',
  TradeLinesMinPast_90Desc = 'TRADE_LINES_MIN_PAST_90_DESC',
  TradeLinesMinLastSlowPaymentAsc = 'TRADE_LINES_MIN_LAST_SLOW_PAYMENT_ASC',
  TradeLinesMinLastSlowPaymentDesc = 'TRADE_LINES_MIN_LAST_SLOW_PAYMENT_DESC',
  TradeLinesMinNarrativesAsc = 'TRADE_LINES_MIN_NARRATIVES_ASC',
  TradeLinesMinNarrativesDesc = 'TRADE_LINES_MIN_NARRATIVES_DESC',
  TradeLinesMinCreatedAtAsc = 'TRADE_LINES_MIN_CREATED_AT_ASC',
  TradeLinesMinCreatedAtDesc = 'TRADE_LINES_MIN_CREATED_AT_DESC',
  TradeLinesMinUpdatedAtAsc = 'TRADE_LINES_MIN_UPDATED_AT_ASC',
  TradeLinesMinUpdatedAtDesc = 'TRADE_LINES_MIN_UPDATED_AT_DESC',
  TradeLinesMaxIdAsc = 'TRADE_LINES_MAX_ID_ASC',
  TradeLinesMaxIdDesc = 'TRADE_LINES_MAX_ID_DESC',
  TradeLinesMaxBureauIdAsc = 'TRADE_LINES_MAX_BUREAU_ID_ASC',
  TradeLinesMaxBureauIdDesc = 'TRADE_LINES_MAX_BUREAU_ID_DESC',
  TradeLinesMaxEquifaxLenderIdAsc = 'TRADE_LINES_MAX_EQUIFAX_LENDER_ID_ASC',
  TradeLinesMaxEquifaxLenderIdDesc = 'TRADE_LINES_MAX_EQUIFAX_LENDER_ID_DESC',
  TradeLinesMaxTradeDateAsc = 'TRADE_LINES_MAX_TRADE_DATE_ASC',
  TradeLinesMaxTradeDateDesc = 'TRADE_LINES_MAX_TRADE_DATE_DESC',
  TradeLinesMaxCreditorIdAsc = 'TRADE_LINES_MAX_CREDITOR_ID_ASC',
  TradeLinesMaxCreditorIdDesc = 'TRADE_LINES_MAX_CREDITOR_ID_DESC',
  TradeLinesMaxCreditorNameAsc = 'TRADE_LINES_MAX_CREDITOR_NAME_ASC',
  TradeLinesMaxCreditorNameDesc = 'TRADE_LINES_MAX_CREDITOR_NAME_DESC',
  TradeLinesMaxCreditorPhoneAsc = 'TRADE_LINES_MAX_CREDITOR_PHONE_ASC',
  TradeLinesMaxCreditorPhoneDesc = 'TRADE_LINES_MAX_CREDITOR_PHONE_DESC',
  TradeLinesMaxAccountNumberAsc = 'TRADE_LINES_MAX_ACCOUNT_NUMBER_ASC',
  TradeLinesMaxAccountNumberDesc = 'TRADE_LINES_MAX_ACCOUNT_NUMBER_DESC',
  TradeLinesMaxAssociationAsc = 'TRADE_LINES_MAX_ASSOCIATION_ASC',
  TradeLinesMaxAssociationDesc = 'TRADE_LINES_MAX_ASSOCIATION_DESC',
  TradeLinesMaxUpdateSourceAsc = 'TRADE_LINES_MAX_UPDATE_SOURCE_ASC',
  TradeLinesMaxUpdateSourceDesc = 'TRADE_LINES_MAX_UPDATE_SOURCE_DESC',
  TradeLinesMaxHighCreditAmountAsc = 'TRADE_LINES_MAX_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesMaxHighCreditAmountDesc = 'TRADE_LINES_MAX_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesMaxBalanceAmountAsc = 'TRADE_LINES_MAX_BALANCE_AMOUNT_ASC',
  TradeLinesMaxBalanceAmountDesc = 'TRADE_LINES_MAX_BALANCE_AMOUNT_DESC',
  TradeLinesMaxPaymentTermAsc = 'TRADE_LINES_MAX_PAYMENT_TERM_ASC',
  TradeLinesMaxPaymentTermDesc = 'TRADE_LINES_MAX_PAYMENT_TERM_DESC',
  TradeLinesMaxPaymentTermAmountAsc = 'TRADE_LINES_MAX_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesMaxPaymentTermAmountDesc = 'TRADE_LINES_MAX_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesMaxPastDueAmountAsc = 'TRADE_LINES_MAX_PAST_DUE_AMOUNT_ASC',
  TradeLinesMaxPastDueAmountDesc = 'TRADE_LINES_MAX_PAST_DUE_AMOUNT_DESC',
  TradeLinesMaxMonthsReviewedAsc = 'TRADE_LINES_MAX_MONTHS_REVIEWED_ASC',
  TradeLinesMaxMonthsReviewedDesc = 'TRADE_LINES_MAX_MONTHS_REVIEWED_DESC',
  TradeLinesMaxDateOpenedAsc = 'TRADE_LINES_MAX_DATE_OPENED_ASC',
  TradeLinesMaxDateOpenedDesc = 'TRADE_LINES_MAX_DATE_OPENED_DESC',
  TradeLinesMaxDateLastActivityOrPaymentAsc = 'TRADE_LINES_MAX_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesMaxDateLastActivityOrPaymentDesc = 'TRADE_LINES_MAX_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesMaxPortfolioTypeDescriptionAsc = 'TRADE_LINES_MAX_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesMaxPortfolioTypeDescriptionDesc = 'TRADE_LINES_MAX_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesMaxPortfolioTypeCodeAsc = 'TRADE_LINES_MAX_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesMaxPortfolioTypeCodeDesc = 'TRADE_LINES_MAX_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesMaxPaymentRateDescriptionAsc = 'TRADE_LINES_MAX_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesMaxPaymentRateDescriptionDesc = 'TRADE_LINES_MAX_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesMaxPaymentRateCodeAsc = 'TRADE_LINES_MAX_PAYMENT_RATE_CODE_ASC',
  TradeLinesMaxPaymentRateCodeDesc = 'TRADE_LINES_MAX_PAYMENT_RATE_CODE_DESC',
  TradeLinesMaxPast_30Asc = 'TRADE_LINES_MAX_PAST_30_ASC',
  TradeLinesMaxPast_30Desc = 'TRADE_LINES_MAX_PAST_30_DESC',
  TradeLinesMaxPast_60Asc = 'TRADE_LINES_MAX_PAST_60_ASC',
  TradeLinesMaxPast_60Desc = 'TRADE_LINES_MAX_PAST_60_DESC',
  TradeLinesMaxPast_90Asc = 'TRADE_LINES_MAX_PAST_90_ASC',
  TradeLinesMaxPast_90Desc = 'TRADE_LINES_MAX_PAST_90_DESC',
  TradeLinesMaxLastSlowPaymentAsc = 'TRADE_LINES_MAX_LAST_SLOW_PAYMENT_ASC',
  TradeLinesMaxLastSlowPaymentDesc = 'TRADE_LINES_MAX_LAST_SLOW_PAYMENT_DESC',
  TradeLinesMaxNarrativesAsc = 'TRADE_LINES_MAX_NARRATIVES_ASC',
  TradeLinesMaxNarrativesDesc = 'TRADE_LINES_MAX_NARRATIVES_DESC',
  TradeLinesMaxCreatedAtAsc = 'TRADE_LINES_MAX_CREATED_AT_ASC',
  TradeLinesMaxCreatedAtDesc = 'TRADE_LINES_MAX_CREATED_AT_DESC',
  TradeLinesMaxUpdatedAtAsc = 'TRADE_LINES_MAX_UPDATED_AT_ASC',
  TradeLinesMaxUpdatedAtDesc = 'TRADE_LINES_MAX_UPDATED_AT_DESC',
  TradeLinesAverageIdAsc = 'TRADE_LINES_AVERAGE_ID_ASC',
  TradeLinesAverageIdDesc = 'TRADE_LINES_AVERAGE_ID_DESC',
  TradeLinesAverageBureauIdAsc = 'TRADE_LINES_AVERAGE_BUREAU_ID_ASC',
  TradeLinesAverageBureauIdDesc = 'TRADE_LINES_AVERAGE_BUREAU_ID_DESC',
  TradeLinesAverageEquifaxLenderIdAsc = 'TRADE_LINES_AVERAGE_EQUIFAX_LENDER_ID_ASC',
  TradeLinesAverageEquifaxLenderIdDesc = 'TRADE_LINES_AVERAGE_EQUIFAX_LENDER_ID_DESC',
  TradeLinesAverageTradeDateAsc = 'TRADE_LINES_AVERAGE_TRADE_DATE_ASC',
  TradeLinesAverageTradeDateDesc = 'TRADE_LINES_AVERAGE_TRADE_DATE_DESC',
  TradeLinesAverageCreditorIdAsc = 'TRADE_LINES_AVERAGE_CREDITOR_ID_ASC',
  TradeLinesAverageCreditorIdDesc = 'TRADE_LINES_AVERAGE_CREDITOR_ID_DESC',
  TradeLinesAverageCreditorNameAsc = 'TRADE_LINES_AVERAGE_CREDITOR_NAME_ASC',
  TradeLinesAverageCreditorNameDesc = 'TRADE_LINES_AVERAGE_CREDITOR_NAME_DESC',
  TradeLinesAverageCreditorPhoneAsc = 'TRADE_LINES_AVERAGE_CREDITOR_PHONE_ASC',
  TradeLinesAverageCreditorPhoneDesc = 'TRADE_LINES_AVERAGE_CREDITOR_PHONE_DESC',
  TradeLinesAverageAccountNumberAsc = 'TRADE_LINES_AVERAGE_ACCOUNT_NUMBER_ASC',
  TradeLinesAverageAccountNumberDesc = 'TRADE_LINES_AVERAGE_ACCOUNT_NUMBER_DESC',
  TradeLinesAverageAssociationAsc = 'TRADE_LINES_AVERAGE_ASSOCIATION_ASC',
  TradeLinesAverageAssociationDesc = 'TRADE_LINES_AVERAGE_ASSOCIATION_DESC',
  TradeLinesAverageUpdateSourceAsc = 'TRADE_LINES_AVERAGE_UPDATE_SOURCE_ASC',
  TradeLinesAverageUpdateSourceDesc = 'TRADE_LINES_AVERAGE_UPDATE_SOURCE_DESC',
  TradeLinesAverageHighCreditAmountAsc = 'TRADE_LINES_AVERAGE_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesAverageHighCreditAmountDesc = 'TRADE_LINES_AVERAGE_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesAverageBalanceAmountAsc = 'TRADE_LINES_AVERAGE_BALANCE_AMOUNT_ASC',
  TradeLinesAverageBalanceAmountDesc = 'TRADE_LINES_AVERAGE_BALANCE_AMOUNT_DESC',
  TradeLinesAveragePaymentTermAsc = 'TRADE_LINES_AVERAGE_PAYMENT_TERM_ASC',
  TradeLinesAveragePaymentTermDesc = 'TRADE_LINES_AVERAGE_PAYMENT_TERM_DESC',
  TradeLinesAveragePaymentTermAmountAsc = 'TRADE_LINES_AVERAGE_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesAveragePaymentTermAmountDesc = 'TRADE_LINES_AVERAGE_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesAveragePastDueAmountAsc = 'TRADE_LINES_AVERAGE_PAST_DUE_AMOUNT_ASC',
  TradeLinesAveragePastDueAmountDesc = 'TRADE_LINES_AVERAGE_PAST_DUE_AMOUNT_DESC',
  TradeLinesAverageMonthsReviewedAsc = 'TRADE_LINES_AVERAGE_MONTHS_REVIEWED_ASC',
  TradeLinesAverageMonthsReviewedDesc = 'TRADE_LINES_AVERAGE_MONTHS_REVIEWED_DESC',
  TradeLinesAverageDateOpenedAsc = 'TRADE_LINES_AVERAGE_DATE_OPENED_ASC',
  TradeLinesAverageDateOpenedDesc = 'TRADE_LINES_AVERAGE_DATE_OPENED_DESC',
  TradeLinesAverageDateLastActivityOrPaymentAsc = 'TRADE_LINES_AVERAGE_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesAverageDateLastActivityOrPaymentDesc = 'TRADE_LINES_AVERAGE_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesAveragePortfolioTypeDescriptionAsc = 'TRADE_LINES_AVERAGE_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesAveragePortfolioTypeDescriptionDesc = 'TRADE_LINES_AVERAGE_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesAveragePortfolioTypeCodeAsc = 'TRADE_LINES_AVERAGE_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesAveragePortfolioTypeCodeDesc = 'TRADE_LINES_AVERAGE_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesAveragePaymentRateDescriptionAsc = 'TRADE_LINES_AVERAGE_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesAveragePaymentRateDescriptionDesc = 'TRADE_LINES_AVERAGE_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesAveragePaymentRateCodeAsc = 'TRADE_LINES_AVERAGE_PAYMENT_RATE_CODE_ASC',
  TradeLinesAveragePaymentRateCodeDesc = 'TRADE_LINES_AVERAGE_PAYMENT_RATE_CODE_DESC',
  TradeLinesAveragePast_30Asc = 'TRADE_LINES_AVERAGE_PAST_30_ASC',
  TradeLinesAveragePast_30Desc = 'TRADE_LINES_AVERAGE_PAST_30_DESC',
  TradeLinesAveragePast_60Asc = 'TRADE_LINES_AVERAGE_PAST_60_ASC',
  TradeLinesAveragePast_60Desc = 'TRADE_LINES_AVERAGE_PAST_60_DESC',
  TradeLinesAveragePast_90Asc = 'TRADE_LINES_AVERAGE_PAST_90_ASC',
  TradeLinesAveragePast_90Desc = 'TRADE_LINES_AVERAGE_PAST_90_DESC',
  TradeLinesAverageLastSlowPaymentAsc = 'TRADE_LINES_AVERAGE_LAST_SLOW_PAYMENT_ASC',
  TradeLinesAverageLastSlowPaymentDesc = 'TRADE_LINES_AVERAGE_LAST_SLOW_PAYMENT_DESC',
  TradeLinesAverageNarrativesAsc = 'TRADE_LINES_AVERAGE_NARRATIVES_ASC',
  TradeLinesAverageNarrativesDesc = 'TRADE_LINES_AVERAGE_NARRATIVES_DESC',
  TradeLinesAverageCreatedAtAsc = 'TRADE_LINES_AVERAGE_CREATED_AT_ASC',
  TradeLinesAverageCreatedAtDesc = 'TRADE_LINES_AVERAGE_CREATED_AT_DESC',
  TradeLinesAverageUpdatedAtAsc = 'TRADE_LINES_AVERAGE_UPDATED_AT_ASC',
  TradeLinesAverageUpdatedAtDesc = 'TRADE_LINES_AVERAGE_UPDATED_AT_DESC',
  TradeLinesStddevSampleIdAsc = 'TRADE_LINES_STDDEV_SAMPLE_ID_ASC',
  TradeLinesStddevSampleIdDesc = 'TRADE_LINES_STDDEV_SAMPLE_ID_DESC',
  TradeLinesStddevSampleBureauIdAsc = 'TRADE_LINES_STDDEV_SAMPLE_BUREAU_ID_ASC',
  TradeLinesStddevSampleBureauIdDesc = 'TRADE_LINES_STDDEV_SAMPLE_BUREAU_ID_DESC',
  TradeLinesStddevSampleEquifaxLenderIdAsc = 'TRADE_LINES_STDDEV_SAMPLE_EQUIFAX_LENDER_ID_ASC',
  TradeLinesStddevSampleEquifaxLenderIdDesc = 'TRADE_LINES_STDDEV_SAMPLE_EQUIFAX_LENDER_ID_DESC',
  TradeLinesStddevSampleTradeDateAsc = 'TRADE_LINES_STDDEV_SAMPLE_TRADE_DATE_ASC',
  TradeLinesStddevSampleTradeDateDesc = 'TRADE_LINES_STDDEV_SAMPLE_TRADE_DATE_DESC',
  TradeLinesStddevSampleCreditorIdAsc = 'TRADE_LINES_STDDEV_SAMPLE_CREDITOR_ID_ASC',
  TradeLinesStddevSampleCreditorIdDesc = 'TRADE_LINES_STDDEV_SAMPLE_CREDITOR_ID_DESC',
  TradeLinesStddevSampleCreditorNameAsc = 'TRADE_LINES_STDDEV_SAMPLE_CREDITOR_NAME_ASC',
  TradeLinesStddevSampleCreditorNameDesc = 'TRADE_LINES_STDDEV_SAMPLE_CREDITOR_NAME_DESC',
  TradeLinesStddevSampleCreditorPhoneAsc = 'TRADE_LINES_STDDEV_SAMPLE_CREDITOR_PHONE_ASC',
  TradeLinesStddevSampleCreditorPhoneDesc = 'TRADE_LINES_STDDEV_SAMPLE_CREDITOR_PHONE_DESC',
  TradeLinesStddevSampleAccountNumberAsc = 'TRADE_LINES_STDDEV_SAMPLE_ACCOUNT_NUMBER_ASC',
  TradeLinesStddevSampleAccountNumberDesc = 'TRADE_LINES_STDDEV_SAMPLE_ACCOUNT_NUMBER_DESC',
  TradeLinesStddevSampleAssociationAsc = 'TRADE_LINES_STDDEV_SAMPLE_ASSOCIATION_ASC',
  TradeLinesStddevSampleAssociationDesc = 'TRADE_LINES_STDDEV_SAMPLE_ASSOCIATION_DESC',
  TradeLinesStddevSampleUpdateSourceAsc = 'TRADE_LINES_STDDEV_SAMPLE_UPDATE_SOURCE_ASC',
  TradeLinesStddevSampleUpdateSourceDesc = 'TRADE_LINES_STDDEV_SAMPLE_UPDATE_SOURCE_DESC',
  TradeLinesStddevSampleHighCreditAmountAsc = 'TRADE_LINES_STDDEV_SAMPLE_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesStddevSampleHighCreditAmountDesc = 'TRADE_LINES_STDDEV_SAMPLE_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesStddevSampleBalanceAmountAsc = 'TRADE_LINES_STDDEV_SAMPLE_BALANCE_AMOUNT_ASC',
  TradeLinesStddevSampleBalanceAmountDesc = 'TRADE_LINES_STDDEV_SAMPLE_BALANCE_AMOUNT_DESC',
  TradeLinesStddevSamplePaymentTermAsc = 'TRADE_LINES_STDDEV_SAMPLE_PAYMENT_TERM_ASC',
  TradeLinesStddevSamplePaymentTermDesc = 'TRADE_LINES_STDDEV_SAMPLE_PAYMENT_TERM_DESC',
  TradeLinesStddevSamplePaymentTermAmountAsc = 'TRADE_LINES_STDDEV_SAMPLE_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesStddevSamplePaymentTermAmountDesc = 'TRADE_LINES_STDDEV_SAMPLE_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesStddevSamplePastDueAmountAsc = 'TRADE_LINES_STDDEV_SAMPLE_PAST_DUE_AMOUNT_ASC',
  TradeLinesStddevSamplePastDueAmountDesc = 'TRADE_LINES_STDDEV_SAMPLE_PAST_DUE_AMOUNT_DESC',
  TradeLinesStddevSampleMonthsReviewedAsc = 'TRADE_LINES_STDDEV_SAMPLE_MONTHS_REVIEWED_ASC',
  TradeLinesStddevSampleMonthsReviewedDesc = 'TRADE_LINES_STDDEV_SAMPLE_MONTHS_REVIEWED_DESC',
  TradeLinesStddevSampleDateOpenedAsc = 'TRADE_LINES_STDDEV_SAMPLE_DATE_OPENED_ASC',
  TradeLinesStddevSampleDateOpenedDesc = 'TRADE_LINES_STDDEV_SAMPLE_DATE_OPENED_DESC',
  TradeLinesStddevSampleDateLastActivityOrPaymentAsc = 'TRADE_LINES_STDDEV_SAMPLE_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesStddevSampleDateLastActivityOrPaymentDesc = 'TRADE_LINES_STDDEV_SAMPLE_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesStddevSamplePortfolioTypeDescriptionAsc = 'TRADE_LINES_STDDEV_SAMPLE_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesStddevSamplePortfolioTypeDescriptionDesc = 'TRADE_LINES_STDDEV_SAMPLE_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesStddevSamplePortfolioTypeCodeAsc = 'TRADE_LINES_STDDEV_SAMPLE_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesStddevSamplePortfolioTypeCodeDesc = 'TRADE_LINES_STDDEV_SAMPLE_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesStddevSamplePaymentRateDescriptionAsc = 'TRADE_LINES_STDDEV_SAMPLE_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesStddevSamplePaymentRateDescriptionDesc = 'TRADE_LINES_STDDEV_SAMPLE_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesStddevSamplePaymentRateCodeAsc = 'TRADE_LINES_STDDEV_SAMPLE_PAYMENT_RATE_CODE_ASC',
  TradeLinesStddevSamplePaymentRateCodeDesc = 'TRADE_LINES_STDDEV_SAMPLE_PAYMENT_RATE_CODE_DESC',
  TradeLinesStddevSamplePast_30Asc = 'TRADE_LINES_STDDEV_SAMPLE_PAST_30_ASC',
  TradeLinesStddevSamplePast_30Desc = 'TRADE_LINES_STDDEV_SAMPLE_PAST_30_DESC',
  TradeLinesStddevSamplePast_60Asc = 'TRADE_LINES_STDDEV_SAMPLE_PAST_60_ASC',
  TradeLinesStddevSamplePast_60Desc = 'TRADE_LINES_STDDEV_SAMPLE_PAST_60_DESC',
  TradeLinesStddevSamplePast_90Asc = 'TRADE_LINES_STDDEV_SAMPLE_PAST_90_ASC',
  TradeLinesStddevSamplePast_90Desc = 'TRADE_LINES_STDDEV_SAMPLE_PAST_90_DESC',
  TradeLinesStddevSampleLastSlowPaymentAsc = 'TRADE_LINES_STDDEV_SAMPLE_LAST_SLOW_PAYMENT_ASC',
  TradeLinesStddevSampleLastSlowPaymentDesc = 'TRADE_LINES_STDDEV_SAMPLE_LAST_SLOW_PAYMENT_DESC',
  TradeLinesStddevSampleNarrativesAsc = 'TRADE_LINES_STDDEV_SAMPLE_NARRATIVES_ASC',
  TradeLinesStddevSampleNarrativesDesc = 'TRADE_LINES_STDDEV_SAMPLE_NARRATIVES_DESC',
  TradeLinesStddevSampleCreatedAtAsc = 'TRADE_LINES_STDDEV_SAMPLE_CREATED_AT_ASC',
  TradeLinesStddevSampleCreatedAtDesc = 'TRADE_LINES_STDDEV_SAMPLE_CREATED_AT_DESC',
  TradeLinesStddevSampleUpdatedAtAsc = 'TRADE_LINES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TradeLinesStddevSampleUpdatedAtDesc = 'TRADE_LINES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TradeLinesStddevPopulationIdAsc = 'TRADE_LINES_STDDEV_POPULATION_ID_ASC',
  TradeLinesStddevPopulationIdDesc = 'TRADE_LINES_STDDEV_POPULATION_ID_DESC',
  TradeLinesStddevPopulationBureauIdAsc = 'TRADE_LINES_STDDEV_POPULATION_BUREAU_ID_ASC',
  TradeLinesStddevPopulationBureauIdDesc = 'TRADE_LINES_STDDEV_POPULATION_BUREAU_ID_DESC',
  TradeLinesStddevPopulationEquifaxLenderIdAsc = 'TRADE_LINES_STDDEV_POPULATION_EQUIFAX_LENDER_ID_ASC',
  TradeLinesStddevPopulationEquifaxLenderIdDesc = 'TRADE_LINES_STDDEV_POPULATION_EQUIFAX_LENDER_ID_DESC',
  TradeLinesStddevPopulationTradeDateAsc = 'TRADE_LINES_STDDEV_POPULATION_TRADE_DATE_ASC',
  TradeLinesStddevPopulationTradeDateDesc = 'TRADE_LINES_STDDEV_POPULATION_TRADE_DATE_DESC',
  TradeLinesStddevPopulationCreditorIdAsc = 'TRADE_LINES_STDDEV_POPULATION_CREDITOR_ID_ASC',
  TradeLinesStddevPopulationCreditorIdDesc = 'TRADE_LINES_STDDEV_POPULATION_CREDITOR_ID_DESC',
  TradeLinesStddevPopulationCreditorNameAsc = 'TRADE_LINES_STDDEV_POPULATION_CREDITOR_NAME_ASC',
  TradeLinesStddevPopulationCreditorNameDesc = 'TRADE_LINES_STDDEV_POPULATION_CREDITOR_NAME_DESC',
  TradeLinesStddevPopulationCreditorPhoneAsc = 'TRADE_LINES_STDDEV_POPULATION_CREDITOR_PHONE_ASC',
  TradeLinesStddevPopulationCreditorPhoneDesc = 'TRADE_LINES_STDDEV_POPULATION_CREDITOR_PHONE_DESC',
  TradeLinesStddevPopulationAccountNumberAsc = 'TRADE_LINES_STDDEV_POPULATION_ACCOUNT_NUMBER_ASC',
  TradeLinesStddevPopulationAccountNumberDesc = 'TRADE_LINES_STDDEV_POPULATION_ACCOUNT_NUMBER_DESC',
  TradeLinesStddevPopulationAssociationAsc = 'TRADE_LINES_STDDEV_POPULATION_ASSOCIATION_ASC',
  TradeLinesStddevPopulationAssociationDesc = 'TRADE_LINES_STDDEV_POPULATION_ASSOCIATION_DESC',
  TradeLinesStddevPopulationUpdateSourceAsc = 'TRADE_LINES_STDDEV_POPULATION_UPDATE_SOURCE_ASC',
  TradeLinesStddevPopulationUpdateSourceDesc = 'TRADE_LINES_STDDEV_POPULATION_UPDATE_SOURCE_DESC',
  TradeLinesStddevPopulationHighCreditAmountAsc = 'TRADE_LINES_STDDEV_POPULATION_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesStddevPopulationHighCreditAmountDesc = 'TRADE_LINES_STDDEV_POPULATION_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesStddevPopulationBalanceAmountAsc = 'TRADE_LINES_STDDEV_POPULATION_BALANCE_AMOUNT_ASC',
  TradeLinesStddevPopulationBalanceAmountDesc = 'TRADE_LINES_STDDEV_POPULATION_BALANCE_AMOUNT_DESC',
  TradeLinesStddevPopulationPaymentTermAsc = 'TRADE_LINES_STDDEV_POPULATION_PAYMENT_TERM_ASC',
  TradeLinesStddevPopulationPaymentTermDesc = 'TRADE_LINES_STDDEV_POPULATION_PAYMENT_TERM_DESC',
  TradeLinesStddevPopulationPaymentTermAmountAsc = 'TRADE_LINES_STDDEV_POPULATION_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesStddevPopulationPaymentTermAmountDesc = 'TRADE_LINES_STDDEV_POPULATION_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesStddevPopulationPastDueAmountAsc = 'TRADE_LINES_STDDEV_POPULATION_PAST_DUE_AMOUNT_ASC',
  TradeLinesStddevPopulationPastDueAmountDesc = 'TRADE_LINES_STDDEV_POPULATION_PAST_DUE_AMOUNT_DESC',
  TradeLinesStddevPopulationMonthsReviewedAsc = 'TRADE_LINES_STDDEV_POPULATION_MONTHS_REVIEWED_ASC',
  TradeLinesStddevPopulationMonthsReviewedDesc = 'TRADE_LINES_STDDEV_POPULATION_MONTHS_REVIEWED_DESC',
  TradeLinesStddevPopulationDateOpenedAsc = 'TRADE_LINES_STDDEV_POPULATION_DATE_OPENED_ASC',
  TradeLinesStddevPopulationDateOpenedDesc = 'TRADE_LINES_STDDEV_POPULATION_DATE_OPENED_DESC',
  TradeLinesStddevPopulationDateLastActivityOrPaymentAsc = 'TRADE_LINES_STDDEV_POPULATION_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesStddevPopulationDateLastActivityOrPaymentDesc = 'TRADE_LINES_STDDEV_POPULATION_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesStddevPopulationPortfolioTypeDescriptionAsc = 'TRADE_LINES_STDDEV_POPULATION_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesStddevPopulationPortfolioTypeDescriptionDesc = 'TRADE_LINES_STDDEV_POPULATION_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesStddevPopulationPortfolioTypeCodeAsc = 'TRADE_LINES_STDDEV_POPULATION_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesStddevPopulationPortfolioTypeCodeDesc = 'TRADE_LINES_STDDEV_POPULATION_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesStddevPopulationPaymentRateDescriptionAsc = 'TRADE_LINES_STDDEV_POPULATION_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesStddevPopulationPaymentRateDescriptionDesc = 'TRADE_LINES_STDDEV_POPULATION_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesStddevPopulationPaymentRateCodeAsc = 'TRADE_LINES_STDDEV_POPULATION_PAYMENT_RATE_CODE_ASC',
  TradeLinesStddevPopulationPaymentRateCodeDesc = 'TRADE_LINES_STDDEV_POPULATION_PAYMENT_RATE_CODE_DESC',
  TradeLinesStddevPopulationPast_30Asc = 'TRADE_LINES_STDDEV_POPULATION_PAST_30_ASC',
  TradeLinesStddevPopulationPast_30Desc = 'TRADE_LINES_STDDEV_POPULATION_PAST_30_DESC',
  TradeLinesStddevPopulationPast_60Asc = 'TRADE_LINES_STDDEV_POPULATION_PAST_60_ASC',
  TradeLinesStddevPopulationPast_60Desc = 'TRADE_LINES_STDDEV_POPULATION_PAST_60_DESC',
  TradeLinesStddevPopulationPast_90Asc = 'TRADE_LINES_STDDEV_POPULATION_PAST_90_ASC',
  TradeLinesStddevPopulationPast_90Desc = 'TRADE_LINES_STDDEV_POPULATION_PAST_90_DESC',
  TradeLinesStddevPopulationLastSlowPaymentAsc = 'TRADE_LINES_STDDEV_POPULATION_LAST_SLOW_PAYMENT_ASC',
  TradeLinesStddevPopulationLastSlowPaymentDesc = 'TRADE_LINES_STDDEV_POPULATION_LAST_SLOW_PAYMENT_DESC',
  TradeLinesStddevPopulationNarrativesAsc = 'TRADE_LINES_STDDEV_POPULATION_NARRATIVES_ASC',
  TradeLinesStddevPopulationNarrativesDesc = 'TRADE_LINES_STDDEV_POPULATION_NARRATIVES_DESC',
  TradeLinesStddevPopulationCreatedAtAsc = 'TRADE_LINES_STDDEV_POPULATION_CREATED_AT_ASC',
  TradeLinesStddevPopulationCreatedAtDesc = 'TRADE_LINES_STDDEV_POPULATION_CREATED_AT_DESC',
  TradeLinesStddevPopulationUpdatedAtAsc = 'TRADE_LINES_STDDEV_POPULATION_UPDATED_AT_ASC',
  TradeLinesStddevPopulationUpdatedAtDesc = 'TRADE_LINES_STDDEV_POPULATION_UPDATED_AT_DESC',
  TradeLinesVarianceSampleIdAsc = 'TRADE_LINES_VARIANCE_SAMPLE_ID_ASC',
  TradeLinesVarianceSampleIdDesc = 'TRADE_LINES_VARIANCE_SAMPLE_ID_DESC',
  TradeLinesVarianceSampleBureauIdAsc = 'TRADE_LINES_VARIANCE_SAMPLE_BUREAU_ID_ASC',
  TradeLinesVarianceSampleBureauIdDesc = 'TRADE_LINES_VARIANCE_SAMPLE_BUREAU_ID_DESC',
  TradeLinesVarianceSampleEquifaxLenderIdAsc = 'TRADE_LINES_VARIANCE_SAMPLE_EQUIFAX_LENDER_ID_ASC',
  TradeLinesVarianceSampleEquifaxLenderIdDesc = 'TRADE_LINES_VARIANCE_SAMPLE_EQUIFAX_LENDER_ID_DESC',
  TradeLinesVarianceSampleTradeDateAsc = 'TRADE_LINES_VARIANCE_SAMPLE_TRADE_DATE_ASC',
  TradeLinesVarianceSampleTradeDateDesc = 'TRADE_LINES_VARIANCE_SAMPLE_TRADE_DATE_DESC',
  TradeLinesVarianceSampleCreditorIdAsc = 'TRADE_LINES_VARIANCE_SAMPLE_CREDITOR_ID_ASC',
  TradeLinesVarianceSampleCreditorIdDesc = 'TRADE_LINES_VARIANCE_SAMPLE_CREDITOR_ID_DESC',
  TradeLinesVarianceSampleCreditorNameAsc = 'TRADE_LINES_VARIANCE_SAMPLE_CREDITOR_NAME_ASC',
  TradeLinesVarianceSampleCreditorNameDesc = 'TRADE_LINES_VARIANCE_SAMPLE_CREDITOR_NAME_DESC',
  TradeLinesVarianceSampleCreditorPhoneAsc = 'TRADE_LINES_VARIANCE_SAMPLE_CREDITOR_PHONE_ASC',
  TradeLinesVarianceSampleCreditorPhoneDesc = 'TRADE_LINES_VARIANCE_SAMPLE_CREDITOR_PHONE_DESC',
  TradeLinesVarianceSampleAccountNumberAsc = 'TRADE_LINES_VARIANCE_SAMPLE_ACCOUNT_NUMBER_ASC',
  TradeLinesVarianceSampleAccountNumberDesc = 'TRADE_LINES_VARIANCE_SAMPLE_ACCOUNT_NUMBER_DESC',
  TradeLinesVarianceSampleAssociationAsc = 'TRADE_LINES_VARIANCE_SAMPLE_ASSOCIATION_ASC',
  TradeLinesVarianceSampleAssociationDesc = 'TRADE_LINES_VARIANCE_SAMPLE_ASSOCIATION_DESC',
  TradeLinesVarianceSampleUpdateSourceAsc = 'TRADE_LINES_VARIANCE_SAMPLE_UPDATE_SOURCE_ASC',
  TradeLinesVarianceSampleUpdateSourceDesc = 'TRADE_LINES_VARIANCE_SAMPLE_UPDATE_SOURCE_DESC',
  TradeLinesVarianceSampleHighCreditAmountAsc = 'TRADE_LINES_VARIANCE_SAMPLE_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesVarianceSampleHighCreditAmountDesc = 'TRADE_LINES_VARIANCE_SAMPLE_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesVarianceSampleBalanceAmountAsc = 'TRADE_LINES_VARIANCE_SAMPLE_BALANCE_AMOUNT_ASC',
  TradeLinesVarianceSampleBalanceAmountDesc = 'TRADE_LINES_VARIANCE_SAMPLE_BALANCE_AMOUNT_DESC',
  TradeLinesVarianceSamplePaymentTermAsc = 'TRADE_LINES_VARIANCE_SAMPLE_PAYMENT_TERM_ASC',
  TradeLinesVarianceSamplePaymentTermDesc = 'TRADE_LINES_VARIANCE_SAMPLE_PAYMENT_TERM_DESC',
  TradeLinesVarianceSamplePaymentTermAmountAsc = 'TRADE_LINES_VARIANCE_SAMPLE_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesVarianceSamplePaymentTermAmountDesc = 'TRADE_LINES_VARIANCE_SAMPLE_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesVarianceSamplePastDueAmountAsc = 'TRADE_LINES_VARIANCE_SAMPLE_PAST_DUE_AMOUNT_ASC',
  TradeLinesVarianceSamplePastDueAmountDesc = 'TRADE_LINES_VARIANCE_SAMPLE_PAST_DUE_AMOUNT_DESC',
  TradeLinesVarianceSampleMonthsReviewedAsc = 'TRADE_LINES_VARIANCE_SAMPLE_MONTHS_REVIEWED_ASC',
  TradeLinesVarianceSampleMonthsReviewedDesc = 'TRADE_LINES_VARIANCE_SAMPLE_MONTHS_REVIEWED_DESC',
  TradeLinesVarianceSampleDateOpenedAsc = 'TRADE_LINES_VARIANCE_SAMPLE_DATE_OPENED_ASC',
  TradeLinesVarianceSampleDateOpenedDesc = 'TRADE_LINES_VARIANCE_SAMPLE_DATE_OPENED_DESC',
  TradeLinesVarianceSampleDateLastActivityOrPaymentAsc = 'TRADE_LINES_VARIANCE_SAMPLE_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesVarianceSampleDateLastActivityOrPaymentDesc = 'TRADE_LINES_VARIANCE_SAMPLE_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesVarianceSamplePortfolioTypeDescriptionAsc = 'TRADE_LINES_VARIANCE_SAMPLE_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesVarianceSamplePortfolioTypeDescriptionDesc = 'TRADE_LINES_VARIANCE_SAMPLE_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesVarianceSamplePortfolioTypeCodeAsc = 'TRADE_LINES_VARIANCE_SAMPLE_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesVarianceSamplePortfolioTypeCodeDesc = 'TRADE_LINES_VARIANCE_SAMPLE_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesVarianceSamplePaymentRateDescriptionAsc = 'TRADE_LINES_VARIANCE_SAMPLE_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesVarianceSamplePaymentRateDescriptionDesc = 'TRADE_LINES_VARIANCE_SAMPLE_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesVarianceSamplePaymentRateCodeAsc = 'TRADE_LINES_VARIANCE_SAMPLE_PAYMENT_RATE_CODE_ASC',
  TradeLinesVarianceSamplePaymentRateCodeDesc = 'TRADE_LINES_VARIANCE_SAMPLE_PAYMENT_RATE_CODE_DESC',
  TradeLinesVarianceSamplePast_30Asc = 'TRADE_LINES_VARIANCE_SAMPLE_PAST_30_ASC',
  TradeLinesVarianceSamplePast_30Desc = 'TRADE_LINES_VARIANCE_SAMPLE_PAST_30_DESC',
  TradeLinesVarianceSamplePast_60Asc = 'TRADE_LINES_VARIANCE_SAMPLE_PAST_60_ASC',
  TradeLinesVarianceSamplePast_60Desc = 'TRADE_LINES_VARIANCE_SAMPLE_PAST_60_DESC',
  TradeLinesVarianceSamplePast_90Asc = 'TRADE_LINES_VARIANCE_SAMPLE_PAST_90_ASC',
  TradeLinesVarianceSamplePast_90Desc = 'TRADE_LINES_VARIANCE_SAMPLE_PAST_90_DESC',
  TradeLinesVarianceSampleLastSlowPaymentAsc = 'TRADE_LINES_VARIANCE_SAMPLE_LAST_SLOW_PAYMENT_ASC',
  TradeLinesVarianceSampleLastSlowPaymentDesc = 'TRADE_LINES_VARIANCE_SAMPLE_LAST_SLOW_PAYMENT_DESC',
  TradeLinesVarianceSampleNarrativesAsc = 'TRADE_LINES_VARIANCE_SAMPLE_NARRATIVES_ASC',
  TradeLinesVarianceSampleNarrativesDesc = 'TRADE_LINES_VARIANCE_SAMPLE_NARRATIVES_DESC',
  TradeLinesVarianceSampleCreatedAtAsc = 'TRADE_LINES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TradeLinesVarianceSampleCreatedAtDesc = 'TRADE_LINES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TradeLinesVarianceSampleUpdatedAtAsc = 'TRADE_LINES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TradeLinesVarianceSampleUpdatedAtDesc = 'TRADE_LINES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TradeLinesVariancePopulationIdAsc = 'TRADE_LINES_VARIANCE_POPULATION_ID_ASC',
  TradeLinesVariancePopulationIdDesc = 'TRADE_LINES_VARIANCE_POPULATION_ID_DESC',
  TradeLinesVariancePopulationBureauIdAsc = 'TRADE_LINES_VARIANCE_POPULATION_BUREAU_ID_ASC',
  TradeLinesVariancePopulationBureauIdDesc = 'TRADE_LINES_VARIANCE_POPULATION_BUREAU_ID_DESC',
  TradeLinesVariancePopulationEquifaxLenderIdAsc = 'TRADE_LINES_VARIANCE_POPULATION_EQUIFAX_LENDER_ID_ASC',
  TradeLinesVariancePopulationEquifaxLenderIdDesc = 'TRADE_LINES_VARIANCE_POPULATION_EQUIFAX_LENDER_ID_DESC',
  TradeLinesVariancePopulationTradeDateAsc = 'TRADE_LINES_VARIANCE_POPULATION_TRADE_DATE_ASC',
  TradeLinesVariancePopulationTradeDateDesc = 'TRADE_LINES_VARIANCE_POPULATION_TRADE_DATE_DESC',
  TradeLinesVariancePopulationCreditorIdAsc = 'TRADE_LINES_VARIANCE_POPULATION_CREDITOR_ID_ASC',
  TradeLinesVariancePopulationCreditorIdDesc = 'TRADE_LINES_VARIANCE_POPULATION_CREDITOR_ID_DESC',
  TradeLinesVariancePopulationCreditorNameAsc = 'TRADE_LINES_VARIANCE_POPULATION_CREDITOR_NAME_ASC',
  TradeLinesVariancePopulationCreditorNameDesc = 'TRADE_LINES_VARIANCE_POPULATION_CREDITOR_NAME_DESC',
  TradeLinesVariancePopulationCreditorPhoneAsc = 'TRADE_LINES_VARIANCE_POPULATION_CREDITOR_PHONE_ASC',
  TradeLinesVariancePopulationCreditorPhoneDesc = 'TRADE_LINES_VARIANCE_POPULATION_CREDITOR_PHONE_DESC',
  TradeLinesVariancePopulationAccountNumberAsc = 'TRADE_LINES_VARIANCE_POPULATION_ACCOUNT_NUMBER_ASC',
  TradeLinesVariancePopulationAccountNumberDesc = 'TRADE_LINES_VARIANCE_POPULATION_ACCOUNT_NUMBER_DESC',
  TradeLinesVariancePopulationAssociationAsc = 'TRADE_LINES_VARIANCE_POPULATION_ASSOCIATION_ASC',
  TradeLinesVariancePopulationAssociationDesc = 'TRADE_LINES_VARIANCE_POPULATION_ASSOCIATION_DESC',
  TradeLinesVariancePopulationUpdateSourceAsc = 'TRADE_LINES_VARIANCE_POPULATION_UPDATE_SOURCE_ASC',
  TradeLinesVariancePopulationUpdateSourceDesc = 'TRADE_LINES_VARIANCE_POPULATION_UPDATE_SOURCE_DESC',
  TradeLinesVariancePopulationHighCreditAmountAsc = 'TRADE_LINES_VARIANCE_POPULATION_HIGH_CREDIT_AMOUNT_ASC',
  TradeLinesVariancePopulationHighCreditAmountDesc = 'TRADE_LINES_VARIANCE_POPULATION_HIGH_CREDIT_AMOUNT_DESC',
  TradeLinesVariancePopulationBalanceAmountAsc = 'TRADE_LINES_VARIANCE_POPULATION_BALANCE_AMOUNT_ASC',
  TradeLinesVariancePopulationBalanceAmountDesc = 'TRADE_LINES_VARIANCE_POPULATION_BALANCE_AMOUNT_DESC',
  TradeLinesVariancePopulationPaymentTermAsc = 'TRADE_LINES_VARIANCE_POPULATION_PAYMENT_TERM_ASC',
  TradeLinesVariancePopulationPaymentTermDesc = 'TRADE_LINES_VARIANCE_POPULATION_PAYMENT_TERM_DESC',
  TradeLinesVariancePopulationPaymentTermAmountAsc = 'TRADE_LINES_VARIANCE_POPULATION_PAYMENT_TERM_AMOUNT_ASC',
  TradeLinesVariancePopulationPaymentTermAmountDesc = 'TRADE_LINES_VARIANCE_POPULATION_PAYMENT_TERM_AMOUNT_DESC',
  TradeLinesVariancePopulationPastDueAmountAsc = 'TRADE_LINES_VARIANCE_POPULATION_PAST_DUE_AMOUNT_ASC',
  TradeLinesVariancePopulationPastDueAmountDesc = 'TRADE_LINES_VARIANCE_POPULATION_PAST_DUE_AMOUNT_DESC',
  TradeLinesVariancePopulationMonthsReviewedAsc = 'TRADE_LINES_VARIANCE_POPULATION_MONTHS_REVIEWED_ASC',
  TradeLinesVariancePopulationMonthsReviewedDesc = 'TRADE_LINES_VARIANCE_POPULATION_MONTHS_REVIEWED_DESC',
  TradeLinesVariancePopulationDateOpenedAsc = 'TRADE_LINES_VARIANCE_POPULATION_DATE_OPENED_ASC',
  TradeLinesVariancePopulationDateOpenedDesc = 'TRADE_LINES_VARIANCE_POPULATION_DATE_OPENED_DESC',
  TradeLinesVariancePopulationDateLastActivityOrPaymentAsc = 'TRADE_LINES_VARIANCE_POPULATION_DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  TradeLinesVariancePopulationDateLastActivityOrPaymentDesc = 'TRADE_LINES_VARIANCE_POPULATION_DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  TradeLinesVariancePopulationPortfolioTypeDescriptionAsc = 'TRADE_LINES_VARIANCE_POPULATION_PORTFOLIO_TYPE_DESCRIPTION_ASC',
  TradeLinesVariancePopulationPortfolioTypeDescriptionDesc = 'TRADE_LINES_VARIANCE_POPULATION_PORTFOLIO_TYPE_DESCRIPTION_DESC',
  TradeLinesVariancePopulationPortfolioTypeCodeAsc = 'TRADE_LINES_VARIANCE_POPULATION_PORTFOLIO_TYPE_CODE_ASC',
  TradeLinesVariancePopulationPortfolioTypeCodeDesc = 'TRADE_LINES_VARIANCE_POPULATION_PORTFOLIO_TYPE_CODE_DESC',
  TradeLinesVariancePopulationPaymentRateDescriptionAsc = 'TRADE_LINES_VARIANCE_POPULATION_PAYMENT_RATE_DESCRIPTION_ASC',
  TradeLinesVariancePopulationPaymentRateDescriptionDesc = 'TRADE_LINES_VARIANCE_POPULATION_PAYMENT_RATE_DESCRIPTION_DESC',
  TradeLinesVariancePopulationPaymentRateCodeAsc = 'TRADE_LINES_VARIANCE_POPULATION_PAYMENT_RATE_CODE_ASC',
  TradeLinesVariancePopulationPaymentRateCodeDesc = 'TRADE_LINES_VARIANCE_POPULATION_PAYMENT_RATE_CODE_DESC',
  TradeLinesVariancePopulationPast_30Asc = 'TRADE_LINES_VARIANCE_POPULATION_PAST_30_ASC',
  TradeLinesVariancePopulationPast_30Desc = 'TRADE_LINES_VARIANCE_POPULATION_PAST_30_DESC',
  TradeLinesVariancePopulationPast_60Asc = 'TRADE_LINES_VARIANCE_POPULATION_PAST_60_ASC',
  TradeLinesVariancePopulationPast_60Desc = 'TRADE_LINES_VARIANCE_POPULATION_PAST_60_DESC',
  TradeLinesVariancePopulationPast_90Asc = 'TRADE_LINES_VARIANCE_POPULATION_PAST_90_ASC',
  TradeLinesVariancePopulationPast_90Desc = 'TRADE_LINES_VARIANCE_POPULATION_PAST_90_DESC',
  TradeLinesVariancePopulationLastSlowPaymentAsc = 'TRADE_LINES_VARIANCE_POPULATION_LAST_SLOW_PAYMENT_ASC',
  TradeLinesVariancePopulationLastSlowPaymentDesc = 'TRADE_LINES_VARIANCE_POPULATION_LAST_SLOW_PAYMENT_DESC',
  TradeLinesVariancePopulationNarrativesAsc = 'TRADE_LINES_VARIANCE_POPULATION_NARRATIVES_ASC',
  TradeLinesVariancePopulationNarrativesDesc = 'TRADE_LINES_VARIANCE_POPULATION_NARRATIVES_DESC',
  TradeLinesVariancePopulationCreatedAtAsc = 'TRADE_LINES_VARIANCE_POPULATION_CREATED_AT_ASC',
  TradeLinesVariancePopulationCreatedAtDesc = 'TRADE_LINES_VARIANCE_POPULATION_CREATED_AT_DESC',
  TradeLinesVariancePopulationUpdatedAtAsc = 'TRADE_LINES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TradeLinesVariancePopulationUpdatedAtDesc = 'TRADE_LINES_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type Event = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId?: Maybe<Scalars['UUID']>;
  requestId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
  error?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `Event`. */
  lead?: Maybe<Lead>;
};

export type EventAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<EventSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EventDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<EventMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<EventMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<EventAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<EventStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<EventStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<EventVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<EventVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Event` object types. */
export type EventAggregatesFilter = {
  /** A filter that must pass for the relevant `Event` object to be included within the aggregate. */
  filter?: Maybe<EventFilter>;
  /** Sum aggregate over matching `Event` objects. */
  sum?: Maybe<EventSumAggregateFilter>;
  /** Distinct count aggregate over matching `Event` objects. */
  distinctCount?: Maybe<EventDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Event` objects. */
  min?: Maybe<EventMinAggregateFilter>;
  /** Maximum aggregate over matching `Event` objects. */
  max?: Maybe<EventMaxAggregateFilter>;
  /** Mean average aggregate over matching `Event` objects. */
  average?: Maybe<EventAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Event` objects. */
  stddevSample?: Maybe<EventStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Event` objects. */
  stddevPopulation?: Maybe<EventStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Event` objects. */
  varianceSample?: Maybe<EventVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Event` objects. */
  variancePopulation?: Maybe<EventVariancePopulationAggregateFilter>;
};

export type EventAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type EventAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Event` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type EventCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `requestId` field. */
  requestId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `error` field. */
  error?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EventDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  requestId?: Maybe<BigIntFilter>;
  meta?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  error?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type EventDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of requestId across the matching connection */
  requestId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meta across the matching connection */
  meta?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of error across the matching connection */
  error?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The fields on `event` to look up the row to connect. */
export type EventEventsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `event` to look up the row to delete. */
export type EventEventsPkeyDelete = {
  id: Scalars['Int'];
};

/** A filter to be used against `Event` object types. All fields are combined with a logical ‘and.’ */
export type EventFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `requestId` field. */
  requestId?: Maybe<StringFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: Maybe<JsonFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `error` field. */
  error?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** A related `lead` exists. */
  leadExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EventFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EventFilter>>;
  /** Negates the expression. */
  not?: Maybe<EventFilter>;
};

export type EventMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
};

export type EventMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type EventMinAggregateFilter = {
  id?: Maybe<IntFilter>;
};

export type EventMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type EventNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `event` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type EventNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `event` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type EventOnEventForFakePublicEventsForeignKey0NodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForFakePublicEventsForeignKey0UsingEventsPkeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForFakePublicEventsForeignKey0Patch;
  id: Scalars['Int'];
};

/** Represents an update to a `Event`. Fields that are set will be updated. */
export type EventPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  requestId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<FakePublicEventsForeignKey0Input>;
};

export type EventStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type EventStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EventStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type EventStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EventSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
};

export type EventSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type EventVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type EventVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EventVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type EventVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Event` values. */
export type EventsConnection = {
  /** A list of `Event` objects. */
  nodes: Array<Event>;
  /** A list of edges which contains the `Event` and cursor to aid in pagination. */
  edges: Array<EventsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Event` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EventAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EventAggregates>>;
};


/** A connection to a list of `Event` values. */
export type EventsConnectionGroupedAggregatesArgs = {
  groupBy: Array<EventsGroupBy>;
  having?: Maybe<EventsHavingInput>;
};

/** A `Event` edge in the connection. */
export type EventsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Event` at the end of the edge. */
  node: Event;
};

/** Grouping methods for `Event` for usage during aggregation. */
export enum EventsGroupBy {
  LeadId = 'LEAD_ID',
  RequestId = 'REQUEST_ID',
  Meta = 'META',
  Type = 'TYPE',
  Error = 'ERROR',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type EventsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Event` aggregates. */
export type EventsHavingInput = {
  AND?: Maybe<Array<EventsHavingInput>>;
  OR?: Maybe<Array<EventsHavingInput>>;
  sum?: Maybe<EventsHavingSumInput>;
  distinctCount?: Maybe<EventsHavingDistinctCountInput>;
  min?: Maybe<EventsHavingMinInput>;
  max?: Maybe<EventsHavingMaxInput>;
  average?: Maybe<EventsHavingAverageInput>;
  stddevSample?: Maybe<EventsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<EventsHavingStddevPopulationInput>;
  varianceSample?: Maybe<EventsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<EventsHavingVariancePopulationInput>;
};

export type EventsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Event`. */
export enum EventsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  RequestIdAsc = 'REQUEST_ID_ASC',
  RequestIdDesc = 'REQUEST_ID_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  ErrorAsc = 'ERROR_ASC',
  ErrorDesc = 'ERROR_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Input for the nested mutation of `lead` in the `EventInput` mutation. */
export type FakePublicEventsForeignKey0Input = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnEventForFakePublicEventsForeignKey0UsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<EventOnEventForFakePublicEventsForeignKey0NodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<FakePublicEventsForeignKey0LeadCreateInput>;
};

/** Input for the nested mutation of `event` in the `LeadInput` mutation. */
export type FakePublicEventsForeignKey0InverseInput = {
  /** Flag indicating whether all other `event` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectById?: Maybe<Array<EventEventsPkeyConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<EventNodeIdConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteById?: Maybe<Array<EventEventsPkeyDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<EventNodeIdDelete>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateById?: Maybe<Array<EventOnEventForFakePublicEventsForeignKey0UsingEventsPkeyUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnEventForFakePublicEventsForeignKey0NodeIdUpdate>>;
};

/** The `lead` to be created by this mutation. */
export type FakePublicEventsForeignKey0LeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export enum FeedbackType {
  Feedback = 'Feedback',
  Complaint = 'Complaint'
}

export type FetchLenderQuotesInput = {
  leadId: Scalars['UUID'];
  updatedAsset?: Maybe<FixAssetInput>;
};

export type Field = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `Field`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyProduct` that is related to this `Field`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Reads a single `CompanyChannel` that is related to this `Field`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `CompanyIntegration` that is related to this `Field`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `FieldConfig` that is related to this `Field`. */
  fieldConfigByConfig?: Maybe<FieldConfig>;
};

export type FieldAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FieldSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FieldDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FieldMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FieldMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FieldAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FieldStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FieldStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FieldVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FieldVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Field` object types. */
export type FieldAggregatesFilter = {
  /** A filter that must pass for the relevant `Field` object to be included within the aggregate. */
  filter?: Maybe<FieldFilter>;
  /** Sum aggregate over matching `Field` objects. */
  sum?: Maybe<FieldSumAggregateFilter>;
  /** Distinct count aggregate over matching `Field` objects. */
  distinctCount?: Maybe<FieldDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Field` objects. */
  min?: Maybe<FieldMinAggregateFilter>;
  /** Maximum aggregate over matching `Field` objects. */
  max?: Maybe<FieldMaxAggregateFilter>;
  /** Mean average aggregate over matching `Field` objects. */
  average?: Maybe<FieldAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Field` objects. */
  stddevSample?: Maybe<FieldStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Field` objects. */
  stddevPopulation?: Maybe<FieldStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Field` objects. */
  varianceSample?: Maybe<FieldVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Field` objects. */
  variancePopulation?: Maybe<FieldVariancePopulationAggregateFilter>;
};

export type FieldAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  config?: Maybe<BigFloatFilter>;
};

export type FieldAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of config across the matching connection */
  config?: Maybe<Scalars['BigFloat']>;
};

/** The `companyChannel` to be created by this mutation. */
export type FieldCompanyChannelIdFkeyCompanyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** The `field` to be created by this mutation. */
export type FieldCompanyChannelIdFkeyFieldCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** Input for the nested mutation of `companyChannel` in the `FieldInput` mutation. */
export type FieldCompanyChannelIdFkeyInput = {
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectById?: Maybe<CompanyChannelCompanyChannelPkeyConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByCompanyIdAndNameAndChannelId?: Maybe<CompanyChannelUnqCompanyChannelConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyChannelNodeIdConnect>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateById?: Maybe<CompanyChannelOnFieldForFieldCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByCompanyIdAndNameAndChannelId?: Maybe<CompanyChannelOnFieldForFieldCompanyChannelIdFkeyUsingUnqCompanyChannelUpdate>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldOnFieldForFieldCompanyChannelIdFkeyNodeIdUpdate>;
  /** A `CompanyChannelInput` object that will be created and connected to this object. */
  create?: Maybe<FieldCompanyChannelIdFkeyCompanyChannelCreateInput>;
};

/** Input for the nested mutation of `field` in the `CompanyChannelInput` mutation. */
export type FieldCompanyChannelIdFkeyInverseInput = {
  /** Flag indicating whether all other `field` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldFieldPkeyConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldNodeIdConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldFieldPkeyDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldNodeIdDelete>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldPkeyUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyProductIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueProductUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyChannelIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueChannelUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueIntegrationUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyChannelOnFieldForFieldCompanyChannelIdFkeyNodeIdUpdate>>;
  /** A `FieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldCompanyChannelIdFkeyFieldCreateInput>>;
};

/** The `company` to be created by this mutation. */
export type FieldCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** The `field` to be created by this mutation. */
export type FieldCompanyIdFkeyFieldCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** Input for the nested mutation of `company` in the `FieldInput` mutation. */
export type FieldCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldOnFieldForFieldCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<FieldCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `field` in the `CompanyInput` mutation. */
export type FieldCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `field` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldFieldPkeyConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldNodeIdConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldFieldPkeyDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldNodeIdDelete>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldOnFieldForFieldCompanyIdFkeyUsingFieldPkeyUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyProductIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueProductUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyChannelIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueChannelUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueIntegrationUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnFieldForFieldCompanyIdFkeyNodeIdUpdate>>;
  /** A `FieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldCompanyIdFkeyFieldCreateInput>>;
};

/** The `companyIntegration` to be created by this mutation. */
export type FieldCompanyIntegrationIdFkeyCompanyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** The `field` to be created by this mutation. */
export type FieldCompanyIntegrationIdFkeyFieldCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** Input for the nested mutation of `companyIntegration` in the `FieldInput` mutation. */
export type FieldCompanyIntegrationIdFkeyInput = {
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyIntegrationNodeIdConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyDelete>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyIntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateById?: Maybe<CompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldOnFieldForFieldCompanyIntegrationIdFkeyNodeIdUpdate>;
  /** A `CompanyIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<FieldCompanyIntegrationIdFkeyCompanyIntegrationCreateInput>;
};

/** Input for the nested mutation of `field` in the `CompanyIntegrationInput` mutation. */
export type FieldCompanyIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `field` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldFieldPkeyConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldNodeIdConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldFieldPkeyDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldNodeIdDelete>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldPkeyUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyProductIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueProductUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyChannelIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueChannelUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueIntegrationUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyNodeIdUpdate>>;
  /** A `FieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldCompanyIntegrationIdFkeyFieldCreateInput>>;
};

/** The `companyProduct` to be created by this mutation. */
export type FieldCompanyProductIdFkeyCompanyProductCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** The `field` to be created by this mutation. */
export type FieldCompanyProductIdFkeyFieldCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** Input for the nested mutation of `companyProduct` in the `FieldInput` mutation. */
export type FieldCompanyProductIdFkeyInput = {
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectById?: Maybe<CompanyProductCompanyProductPkeyConnect>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyProductNodeIdConnect>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteById?: Maybe<CompanyProductCompanyProductPkeyDelete>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyProductNodeIdDelete>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateById?: Maybe<CompanyProductOnFieldForFieldCompanyProductIdFkeyUsingCompanyProductPkeyUpdate>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldOnFieldForFieldCompanyProductIdFkeyNodeIdUpdate>;
  /** A `CompanyProductInput` object that will be created and connected to this object. */
  create?: Maybe<FieldCompanyProductIdFkeyCompanyProductCreateInput>;
};

/** Input for the nested mutation of `field` in the `CompanyProductInput` mutation. */
export type FieldCompanyProductIdFkeyInverseInput = {
  /** Flag indicating whether all other `field` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldFieldPkeyConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldNodeIdConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldFieldPkeyDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldNodeIdDelete>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldPkeyUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyProductIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueProductUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyChannelIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueChannelUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueIntegrationUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyProductOnFieldForFieldCompanyProductIdFkeyNodeIdUpdate>>;
  /** A `FieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldCompanyProductIdFkeyFieldCreateInput>>;
};

/** A condition to be used against `Field` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FieldCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyProductId` field. */
  companyProductId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyChannelId` field. */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `config` field. */
  config?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `topic` field. */
  topic?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type FieldConfig = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  type: FieldType;
  label: Scalars['String'];
  helper: Scalars['String'];
  key: Scalars['String'];
  public: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  userEditable?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Option`. */
  options: OptionsConnection;
  /** Reads and enables pagination through a set of `Field`. */
  fieldsByConfig: FieldsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByFieldConfigAndCompanyId: FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProductsByFieldConfigAndCompanyProductId: FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByFieldConfigAndCompanyChannelId: FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByFieldConfigAndCompanyIntegrationId: FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdManyToManyConnection;
  channel?: Maybe<ChannelsConnection>;
  integration?: Maybe<IntegrationsConnection>;
  product?: Maybe<ProductsConnection>;
};


export type FieldConfigOptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<OptionsOrderBy>>;
  condition?: Maybe<OptionCondition>;
  filter?: Maybe<OptionFilter>;
};


export type FieldConfigFieldsByConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


export type FieldConfigCompaniesByFieldConfigAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type FieldConfigChannelArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
  condition?: Maybe<ChannelCondition>;
  filter?: Maybe<ChannelFilter>;
};


export type FieldConfigIntegrationArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
  condition?: Maybe<IntegrationCondition>;
  filter?: Maybe<IntegrationFilter>;
};


export type FieldConfigProductArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};

export type FieldConfigAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FieldConfigSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FieldConfigDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FieldConfigMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FieldConfigMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FieldConfigAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FieldConfigStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FieldConfigStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FieldConfigVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FieldConfigVariancePopulationAggregates>;
};

export type FieldConfigAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Company` values, with data from `Field`. */
export type FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `Field`. */
export type FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `Field`. */
export type FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `Company` edge in the connection, with data from `Field`. */
export type FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdManyToManyConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `FieldConfig` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FieldConfigCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<FieldType>;
  /** Checks for equality with the object’s `label` field. */
  label?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `helper` field. */
  helper?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `key` field. */
  key?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `public` field. */
  public?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `parentType` field. */
  parentType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userEditable` field. */
  userEditable?: Maybe<Scalars['Boolean']>;
};

export type FieldConfigDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of label across the matching connection */
  label?: Maybe<Scalars['BigInt']>;
  /** Distinct count of helper across the matching connection */
  helper?: Maybe<Scalars['BigInt']>;
  /** Distinct count of key across the matching connection */
  key?: Maybe<Scalars['BigInt']>;
  /** Distinct count of public across the matching connection */
  public?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parentType across the matching connection */
  parentType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userEditable across the matching connection */
  userEditable?: Maybe<Scalars['BigInt']>;
};

/** The fields on `fieldConfig` to look up the row to connect. */
export type FieldConfigFieldConfigPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `fieldConfig` to look up the row to delete. */
export type FieldConfigFieldConfigPkeyDelete = {
  id: Scalars['Int'];
};

/** A filter to be used against `FieldConfig` object types. All fields are combined with a logical ‘and.’ */
export type FieldConfigFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<FieldTypeFilter>;
  /** Filter by the object’s `label` field. */
  label?: Maybe<StringFilter>;
  /** Filter by the object’s `helper` field. */
  helper?: Maybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: Maybe<StringFilter>;
  /** Filter by the object’s `public` field. */
  public?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: Maybe<IntFilter>;
  /** Filter by the object’s `parentType` field. */
  parentType?: Maybe<StringFilter>;
  /** Filter by the object’s `userEditable` field. */
  userEditable?: Maybe<BooleanFilter>;
  /** Filter by the object’s `options` relation. */
  options?: Maybe<FieldConfigToManyOptionFilter>;
  /** Some related `options` exist. */
  optionsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fieldsByConfig` relation. */
  fieldsByConfig?: Maybe<FieldConfigToManyFieldFilter>;
  /** Some related `fieldsByConfig` exist. */
  fieldsByConfigExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<FieldConfigFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<FieldConfigFilter>>;
  /** Negates the expression. */
  not?: Maybe<FieldConfigFilter>;
};

/** The `fieldConfig` to be created by this mutation. */
export type FieldConfigFkeyFieldConfigCreateInput = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  userEditable?: Maybe<Scalars['Boolean']>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** The `field` to be created by this mutation. */
export type FieldConfigFkeyFieldCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** Input for the nested mutation of `fieldConfig` in the `FieldInput` mutation. */
export type FieldConfigFkeyInput = {
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectById?: Maybe<FieldConfigFieldConfigPkeyConnect>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectByParentIdAndParentTypeAndKey?: Maybe<FieldConfigUniqueParentIdParentTypeKeyConnect>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectByNodeId?: Maybe<FieldConfigNodeIdConnect>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteById?: Maybe<FieldConfigFieldConfigPkeyDelete>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteByParentIdAndParentTypeAndKey?: Maybe<FieldConfigUniqueParentIdParentTypeKeyDelete>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FieldConfigNodeIdDelete>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateById?: Maybe<FieldConfigOnFieldForFieldConfigFkeyUsingFieldConfigPkeyUpdate>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateByParentIdAndParentTypeAndKey?: Maybe<FieldConfigOnFieldForFieldConfigFkeyUsingUniqueParentIdParentTypeKeyUpdate>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldOnFieldForFieldConfigFkeyNodeIdUpdate>;
  /** A `FieldConfigInput` object that will be created and connected to this object. */
  create?: Maybe<FieldConfigFkeyFieldConfigCreateInput>;
};

/** Input for the nested mutation of `field` in the `FieldConfigInput` mutation. */
export type FieldConfigFkeyInverseInput = {
  /** Flag indicating whether all other `field` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldFieldPkeyConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldNodeIdConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldFieldPkeyDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldNodeIdDelete>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldOnFieldForFieldConfigFkeyUsingFieldPkeyUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyProductIdAndConfig?: Maybe<Array<FieldOnFieldForFieldConfigFkeyUsingFieldUniqueProductUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyChannelIdAndConfig?: Maybe<Array<FieldOnFieldForFieldConfigFkeyUsingFieldUniqueChannelUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldOnFieldForFieldConfigFkeyUsingFieldUniqueIntegrationUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FieldConfigOnFieldForFieldConfigFkeyNodeIdUpdate>>;
  /** A `FieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldConfigFkeyFieldCreateInput>>;
};

/** Grouping methods for `FieldConfig` for usage during aggregation. */
export enum FieldConfigGroupBy {
  Type = 'TYPE',
  Label = 'LABEL',
  Helper = 'HELPER',
  Key = 'KEY',
  Public = 'PUBLIC',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  ParentId = 'PARENT_ID',
  ParentType = 'PARENT_TYPE',
  UserEditable = 'USER_EDITABLE'
}

export type FieldConfigHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  parentId?: Maybe<HavingIntFilter>;
};

export type FieldConfigHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  parentId?: Maybe<HavingIntFilter>;
};

/** Conditions for `FieldConfig` aggregates. */
export type FieldConfigHavingInput = {
  AND?: Maybe<Array<FieldConfigHavingInput>>;
  OR?: Maybe<Array<FieldConfigHavingInput>>;
  sum?: Maybe<FieldConfigHavingSumInput>;
  distinctCount?: Maybe<FieldConfigHavingDistinctCountInput>;
  min?: Maybe<FieldConfigHavingMinInput>;
  max?: Maybe<FieldConfigHavingMaxInput>;
  average?: Maybe<FieldConfigHavingAverageInput>;
  stddevSample?: Maybe<FieldConfigHavingStddevSampleInput>;
  stddevPopulation?: Maybe<FieldConfigHavingStddevPopulationInput>;
  varianceSample?: Maybe<FieldConfigHavingVarianceSampleInput>;
  variancePopulation?: Maybe<FieldConfigHavingVariancePopulationInput>;
};

export type FieldConfigHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  parentId?: Maybe<HavingIntFilter>;
};

export type FieldConfigHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  parentId?: Maybe<HavingIntFilter>;
};

export type FieldConfigHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  parentId?: Maybe<HavingIntFilter>;
};

export type FieldConfigHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  parentId?: Maybe<HavingIntFilter>;
};

export type FieldConfigHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  parentId?: Maybe<HavingIntFilter>;
};

export type FieldConfigHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  parentId?: Maybe<HavingIntFilter>;
};

export type FieldConfigHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  parentId?: Maybe<HavingIntFilter>;
};

/** An input for mutations affecting `FieldConfig` */
export type FieldConfigInput = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  userEditable?: Maybe<Scalars['Boolean']>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

export type FieldConfigMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of parentId across the matching connection */
  parentId?: Maybe<Scalars['Int']>;
};

export type FieldConfigMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of parentId across the matching connection */
  parentId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type FieldConfigNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `fieldConfig` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type FieldConfigNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `fieldConfig` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldConfigOnFieldForFieldConfigFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: FieldPatch;
};

/** The fields on `fieldConfig` to look up the row to update. */
export type FieldConfigOnFieldForFieldConfigFkeyUsingFieldConfigPkeyUpdate = {
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: UpdateFieldConfigOnFieldForFieldConfigFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `fieldConfig` to look up the row to update. */
export type FieldConfigOnFieldForFieldConfigFkeyUsingUniqueParentIdParentTypeKeyUpdate = {
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: UpdateFieldConfigOnFieldForFieldConfigFkeyPatch;
  parentId: Scalars['Int'];
  parentType: Scalars['String'];
  key: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldConfigOnOptionForOptionFieldConfigIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `option` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `option` being updated. */
  patch: OptionPatch;
};

/** The fields on `fieldConfig` to look up the row to update. */
export type FieldConfigOnOptionForOptionFieldConfigIdFkeyUsingFieldConfigPkeyUpdate = {
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: UpdateFieldConfigOnOptionForOptionFieldConfigIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `fieldConfig` to look up the row to update. */
export type FieldConfigOnOptionForOptionFieldConfigIdFkeyUsingUniqueParentIdParentTypeKeyUpdate = {
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: UpdateFieldConfigOnOptionForOptionFieldConfigIdFkeyPatch;
  parentId: Scalars['Int'];
  parentType: Scalars['String'];
  key: Scalars['String'];
};

/** Represents an update to a `FieldConfig`. Fields that are set will be updated. */
export type FieldConfigPatch = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  userEditable?: Maybe<Scalars['Boolean']>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

export type FieldConfigStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type FieldConfigStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type FieldConfigSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of parentId across the matching connection */
  parentId: Scalars['BigInt'];
};

/** A filter to be used against many `Field` object types. All fields are combined with a logical ‘and.’ */
export type FieldConfigToManyFieldFilter = {
  /** Every related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldFilter>;
  /** Some related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldFilter>;
  /** No related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldFilter>;
  /** Aggregates across related `Field` match the filter criteria. */
  aggregates?: Maybe<FieldAggregatesFilter>;
};

/** A filter to be used against many `Option` object types. All fields are combined with a logical ‘and.’ */
export type FieldConfigToManyOptionFilter = {
  /** Every related `Option` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<OptionFilter>;
  /** Some related `Option` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<OptionFilter>;
  /** No related `Option` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<OptionFilter>;
  /** Aggregates across related `Option` match the filter criteria. */
  aggregates?: Maybe<OptionAggregatesFilter>;
};

/** The fields on `fieldConfig` to look up the row to connect. */
export type FieldConfigUniqueParentIdParentTypeKeyConnect = {
  parentId: Scalars['Int'];
  parentType: Scalars['String'];
  key: Scalars['String'];
};

/** The fields on `fieldConfig` to look up the row to delete. */
export type FieldConfigUniqueParentIdParentTypeKeyDelete = {
  parentId: Scalars['Int'];
  parentType: Scalars['String'];
  key: Scalars['String'];
};

export type FieldConfigVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type FieldConfigVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `FieldConfig` values. */
export type FieldConfigsConnection = {
  /** A list of `FieldConfig` objects. */
  nodes: Array<FieldConfig>;
  /** A list of edges which contains the `FieldConfig` and cursor to aid in pagination. */
  edges: Array<FieldConfigsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldConfigAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldConfigAggregates>>;
};


/** A connection to a list of `FieldConfig` values. */
export type FieldConfigsConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldConfigGroupBy>;
  having?: Maybe<FieldConfigHavingInput>;
};

/** A `FieldConfig` edge in the connection. */
export type FieldConfigsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldConfig` at the end of the edge. */
  node: FieldConfig;
};

/** Methods to use when ordering `FieldConfig`. */
export enum FieldConfigsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  HelperAsc = 'HELPER_ASC',
  HelperDesc = 'HELPER_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  PublicAsc = 'PUBLIC_ASC',
  PublicDesc = 'PUBLIC_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  ParentTypeAsc = 'PARENT_TYPE_ASC',
  ParentTypeDesc = 'PARENT_TYPE_DESC',
  UserEditableAsc = 'USER_EDITABLE_ASC',
  UserEditableDesc = 'USER_EDITABLE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  OptionsCountAsc = 'OPTIONS_COUNT_ASC',
  OptionsCountDesc = 'OPTIONS_COUNT_DESC',
  OptionsSumIdAsc = 'OPTIONS_SUM_ID_ASC',
  OptionsSumIdDesc = 'OPTIONS_SUM_ID_DESC',
  OptionsSumFieldConfigIdAsc = 'OPTIONS_SUM_FIELD_CONFIG_ID_ASC',
  OptionsSumFieldConfigIdDesc = 'OPTIONS_SUM_FIELD_CONFIG_ID_DESC',
  OptionsSumDisplayAsc = 'OPTIONS_SUM_DISPLAY_ASC',
  OptionsSumDisplayDesc = 'OPTIONS_SUM_DISPLAY_DESC',
  OptionsSumValueAsc = 'OPTIONS_SUM_VALUE_ASC',
  OptionsSumValueDesc = 'OPTIONS_SUM_VALUE_DESC',
  OptionsSumEnabledAsc = 'OPTIONS_SUM_ENABLED_ASC',
  OptionsSumEnabledDesc = 'OPTIONS_SUM_ENABLED_DESC',
  OptionsSumCreatedAtAsc = 'OPTIONS_SUM_CREATED_AT_ASC',
  OptionsSumCreatedAtDesc = 'OPTIONS_SUM_CREATED_AT_DESC',
  OptionsSumUpdatedAtAsc = 'OPTIONS_SUM_UPDATED_AT_ASC',
  OptionsSumUpdatedAtDesc = 'OPTIONS_SUM_UPDATED_AT_DESC',
  OptionsDistinctCountIdAsc = 'OPTIONS_DISTINCT_COUNT_ID_ASC',
  OptionsDistinctCountIdDesc = 'OPTIONS_DISTINCT_COUNT_ID_DESC',
  OptionsDistinctCountFieldConfigIdAsc = 'OPTIONS_DISTINCT_COUNT_FIELD_CONFIG_ID_ASC',
  OptionsDistinctCountFieldConfigIdDesc = 'OPTIONS_DISTINCT_COUNT_FIELD_CONFIG_ID_DESC',
  OptionsDistinctCountDisplayAsc = 'OPTIONS_DISTINCT_COUNT_DISPLAY_ASC',
  OptionsDistinctCountDisplayDesc = 'OPTIONS_DISTINCT_COUNT_DISPLAY_DESC',
  OptionsDistinctCountValueAsc = 'OPTIONS_DISTINCT_COUNT_VALUE_ASC',
  OptionsDistinctCountValueDesc = 'OPTIONS_DISTINCT_COUNT_VALUE_DESC',
  OptionsDistinctCountEnabledAsc = 'OPTIONS_DISTINCT_COUNT_ENABLED_ASC',
  OptionsDistinctCountEnabledDesc = 'OPTIONS_DISTINCT_COUNT_ENABLED_DESC',
  OptionsDistinctCountCreatedAtAsc = 'OPTIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  OptionsDistinctCountCreatedAtDesc = 'OPTIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  OptionsDistinctCountUpdatedAtAsc = 'OPTIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  OptionsDistinctCountUpdatedAtDesc = 'OPTIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  OptionsMinIdAsc = 'OPTIONS_MIN_ID_ASC',
  OptionsMinIdDesc = 'OPTIONS_MIN_ID_DESC',
  OptionsMinFieldConfigIdAsc = 'OPTIONS_MIN_FIELD_CONFIG_ID_ASC',
  OptionsMinFieldConfigIdDesc = 'OPTIONS_MIN_FIELD_CONFIG_ID_DESC',
  OptionsMinDisplayAsc = 'OPTIONS_MIN_DISPLAY_ASC',
  OptionsMinDisplayDesc = 'OPTIONS_MIN_DISPLAY_DESC',
  OptionsMinValueAsc = 'OPTIONS_MIN_VALUE_ASC',
  OptionsMinValueDesc = 'OPTIONS_MIN_VALUE_DESC',
  OptionsMinEnabledAsc = 'OPTIONS_MIN_ENABLED_ASC',
  OptionsMinEnabledDesc = 'OPTIONS_MIN_ENABLED_DESC',
  OptionsMinCreatedAtAsc = 'OPTIONS_MIN_CREATED_AT_ASC',
  OptionsMinCreatedAtDesc = 'OPTIONS_MIN_CREATED_AT_DESC',
  OptionsMinUpdatedAtAsc = 'OPTIONS_MIN_UPDATED_AT_ASC',
  OptionsMinUpdatedAtDesc = 'OPTIONS_MIN_UPDATED_AT_DESC',
  OptionsMaxIdAsc = 'OPTIONS_MAX_ID_ASC',
  OptionsMaxIdDesc = 'OPTIONS_MAX_ID_DESC',
  OptionsMaxFieldConfigIdAsc = 'OPTIONS_MAX_FIELD_CONFIG_ID_ASC',
  OptionsMaxFieldConfigIdDesc = 'OPTIONS_MAX_FIELD_CONFIG_ID_DESC',
  OptionsMaxDisplayAsc = 'OPTIONS_MAX_DISPLAY_ASC',
  OptionsMaxDisplayDesc = 'OPTIONS_MAX_DISPLAY_DESC',
  OptionsMaxValueAsc = 'OPTIONS_MAX_VALUE_ASC',
  OptionsMaxValueDesc = 'OPTIONS_MAX_VALUE_DESC',
  OptionsMaxEnabledAsc = 'OPTIONS_MAX_ENABLED_ASC',
  OptionsMaxEnabledDesc = 'OPTIONS_MAX_ENABLED_DESC',
  OptionsMaxCreatedAtAsc = 'OPTIONS_MAX_CREATED_AT_ASC',
  OptionsMaxCreatedAtDesc = 'OPTIONS_MAX_CREATED_AT_DESC',
  OptionsMaxUpdatedAtAsc = 'OPTIONS_MAX_UPDATED_AT_ASC',
  OptionsMaxUpdatedAtDesc = 'OPTIONS_MAX_UPDATED_AT_DESC',
  OptionsAverageIdAsc = 'OPTIONS_AVERAGE_ID_ASC',
  OptionsAverageIdDesc = 'OPTIONS_AVERAGE_ID_DESC',
  OptionsAverageFieldConfigIdAsc = 'OPTIONS_AVERAGE_FIELD_CONFIG_ID_ASC',
  OptionsAverageFieldConfigIdDesc = 'OPTIONS_AVERAGE_FIELD_CONFIG_ID_DESC',
  OptionsAverageDisplayAsc = 'OPTIONS_AVERAGE_DISPLAY_ASC',
  OptionsAverageDisplayDesc = 'OPTIONS_AVERAGE_DISPLAY_DESC',
  OptionsAverageValueAsc = 'OPTIONS_AVERAGE_VALUE_ASC',
  OptionsAverageValueDesc = 'OPTIONS_AVERAGE_VALUE_DESC',
  OptionsAverageEnabledAsc = 'OPTIONS_AVERAGE_ENABLED_ASC',
  OptionsAverageEnabledDesc = 'OPTIONS_AVERAGE_ENABLED_DESC',
  OptionsAverageCreatedAtAsc = 'OPTIONS_AVERAGE_CREATED_AT_ASC',
  OptionsAverageCreatedAtDesc = 'OPTIONS_AVERAGE_CREATED_AT_DESC',
  OptionsAverageUpdatedAtAsc = 'OPTIONS_AVERAGE_UPDATED_AT_ASC',
  OptionsAverageUpdatedAtDesc = 'OPTIONS_AVERAGE_UPDATED_AT_DESC',
  OptionsStddevSampleIdAsc = 'OPTIONS_STDDEV_SAMPLE_ID_ASC',
  OptionsStddevSampleIdDesc = 'OPTIONS_STDDEV_SAMPLE_ID_DESC',
  OptionsStddevSampleFieldConfigIdAsc = 'OPTIONS_STDDEV_SAMPLE_FIELD_CONFIG_ID_ASC',
  OptionsStddevSampleFieldConfigIdDesc = 'OPTIONS_STDDEV_SAMPLE_FIELD_CONFIG_ID_DESC',
  OptionsStddevSampleDisplayAsc = 'OPTIONS_STDDEV_SAMPLE_DISPLAY_ASC',
  OptionsStddevSampleDisplayDesc = 'OPTIONS_STDDEV_SAMPLE_DISPLAY_DESC',
  OptionsStddevSampleValueAsc = 'OPTIONS_STDDEV_SAMPLE_VALUE_ASC',
  OptionsStddevSampleValueDesc = 'OPTIONS_STDDEV_SAMPLE_VALUE_DESC',
  OptionsStddevSampleEnabledAsc = 'OPTIONS_STDDEV_SAMPLE_ENABLED_ASC',
  OptionsStddevSampleEnabledDesc = 'OPTIONS_STDDEV_SAMPLE_ENABLED_DESC',
  OptionsStddevSampleCreatedAtAsc = 'OPTIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  OptionsStddevSampleCreatedAtDesc = 'OPTIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  OptionsStddevSampleUpdatedAtAsc = 'OPTIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  OptionsStddevSampleUpdatedAtDesc = 'OPTIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  OptionsStddevPopulationIdAsc = 'OPTIONS_STDDEV_POPULATION_ID_ASC',
  OptionsStddevPopulationIdDesc = 'OPTIONS_STDDEV_POPULATION_ID_DESC',
  OptionsStddevPopulationFieldConfigIdAsc = 'OPTIONS_STDDEV_POPULATION_FIELD_CONFIG_ID_ASC',
  OptionsStddevPopulationFieldConfigIdDesc = 'OPTIONS_STDDEV_POPULATION_FIELD_CONFIG_ID_DESC',
  OptionsStddevPopulationDisplayAsc = 'OPTIONS_STDDEV_POPULATION_DISPLAY_ASC',
  OptionsStddevPopulationDisplayDesc = 'OPTIONS_STDDEV_POPULATION_DISPLAY_DESC',
  OptionsStddevPopulationValueAsc = 'OPTIONS_STDDEV_POPULATION_VALUE_ASC',
  OptionsStddevPopulationValueDesc = 'OPTIONS_STDDEV_POPULATION_VALUE_DESC',
  OptionsStddevPopulationEnabledAsc = 'OPTIONS_STDDEV_POPULATION_ENABLED_ASC',
  OptionsStddevPopulationEnabledDesc = 'OPTIONS_STDDEV_POPULATION_ENABLED_DESC',
  OptionsStddevPopulationCreatedAtAsc = 'OPTIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  OptionsStddevPopulationCreatedAtDesc = 'OPTIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  OptionsStddevPopulationUpdatedAtAsc = 'OPTIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  OptionsStddevPopulationUpdatedAtDesc = 'OPTIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  OptionsVarianceSampleIdAsc = 'OPTIONS_VARIANCE_SAMPLE_ID_ASC',
  OptionsVarianceSampleIdDesc = 'OPTIONS_VARIANCE_SAMPLE_ID_DESC',
  OptionsVarianceSampleFieldConfigIdAsc = 'OPTIONS_VARIANCE_SAMPLE_FIELD_CONFIG_ID_ASC',
  OptionsVarianceSampleFieldConfigIdDesc = 'OPTIONS_VARIANCE_SAMPLE_FIELD_CONFIG_ID_DESC',
  OptionsVarianceSampleDisplayAsc = 'OPTIONS_VARIANCE_SAMPLE_DISPLAY_ASC',
  OptionsVarianceSampleDisplayDesc = 'OPTIONS_VARIANCE_SAMPLE_DISPLAY_DESC',
  OptionsVarianceSampleValueAsc = 'OPTIONS_VARIANCE_SAMPLE_VALUE_ASC',
  OptionsVarianceSampleValueDesc = 'OPTIONS_VARIANCE_SAMPLE_VALUE_DESC',
  OptionsVarianceSampleEnabledAsc = 'OPTIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  OptionsVarianceSampleEnabledDesc = 'OPTIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  OptionsVarianceSampleCreatedAtAsc = 'OPTIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  OptionsVarianceSampleCreatedAtDesc = 'OPTIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  OptionsVarianceSampleUpdatedAtAsc = 'OPTIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  OptionsVarianceSampleUpdatedAtDesc = 'OPTIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  OptionsVariancePopulationIdAsc = 'OPTIONS_VARIANCE_POPULATION_ID_ASC',
  OptionsVariancePopulationIdDesc = 'OPTIONS_VARIANCE_POPULATION_ID_DESC',
  OptionsVariancePopulationFieldConfigIdAsc = 'OPTIONS_VARIANCE_POPULATION_FIELD_CONFIG_ID_ASC',
  OptionsVariancePopulationFieldConfigIdDesc = 'OPTIONS_VARIANCE_POPULATION_FIELD_CONFIG_ID_DESC',
  OptionsVariancePopulationDisplayAsc = 'OPTIONS_VARIANCE_POPULATION_DISPLAY_ASC',
  OptionsVariancePopulationDisplayDesc = 'OPTIONS_VARIANCE_POPULATION_DISPLAY_DESC',
  OptionsVariancePopulationValueAsc = 'OPTIONS_VARIANCE_POPULATION_VALUE_ASC',
  OptionsVariancePopulationValueDesc = 'OPTIONS_VARIANCE_POPULATION_VALUE_DESC',
  OptionsVariancePopulationEnabledAsc = 'OPTIONS_VARIANCE_POPULATION_ENABLED_ASC',
  OptionsVariancePopulationEnabledDesc = 'OPTIONS_VARIANCE_POPULATION_ENABLED_DESC',
  OptionsVariancePopulationCreatedAtAsc = 'OPTIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  OptionsVariancePopulationCreatedAtDesc = 'OPTIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  OptionsVariancePopulationUpdatedAtAsc = 'OPTIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  OptionsVariancePopulationUpdatedAtDesc = 'OPTIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FieldsByConfigCountAsc = 'FIELDS_BY_CONFIG_COUNT_ASC',
  FieldsByConfigCountDesc = 'FIELDS_BY_CONFIG_COUNT_DESC',
  FieldsByConfigSumIdAsc = 'FIELDS_BY_CONFIG_SUM_ID_ASC',
  FieldsByConfigSumIdDesc = 'FIELDS_BY_CONFIG_SUM_ID_DESC',
  FieldsByConfigSumCompanyIdAsc = 'FIELDS_BY_CONFIG_SUM_COMPANY_ID_ASC',
  FieldsByConfigSumCompanyIdDesc = 'FIELDS_BY_CONFIG_SUM_COMPANY_ID_DESC',
  FieldsByConfigSumCompanyProductIdAsc = 'FIELDS_BY_CONFIG_SUM_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigSumCompanyProductIdDesc = 'FIELDS_BY_CONFIG_SUM_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigSumCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_SUM_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigSumCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_SUM_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigSumCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_SUM_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigSumCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_SUM_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigSumConfigAsc = 'FIELDS_BY_CONFIG_SUM_CONFIG_ASC',
  FieldsByConfigSumConfigDesc = 'FIELDS_BY_CONFIG_SUM_CONFIG_DESC',
  FieldsByConfigSumTopicAsc = 'FIELDS_BY_CONFIG_SUM_TOPIC_ASC',
  FieldsByConfigSumTopicDesc = 'FIELDS_BY_CONFIG_SUM_TOPIC_DESC',
  FieldsByConfigSumValueAsc = 'FIELDS_BY_CONFIG_SUM_VALUE_ASC',
  FieldsByConfigSumValueDesc = 'FIELDS_BY_CONFIG_SUM_VALUE_DESC',
  FieldsByConfigSumCreatedAtAsc = 'FIELDS_BY_CONFIG_SUM_CREATED_AT_ASC',
  FieldsByConfigSumCreatedAtDesc = 'FIELDS_BY_CONFIG_SUM_CREATED_AT_DESC',
  FieldsByConfigSumUpdatedAtAsc = 'FIELDS_BY_CONFIG_SUM_UPDATED_AT_ASC',
  FieldsByConfigSumUpdatedAtDesc = 'FIELDS_BY_CONFIG_SUM_UPDATED_AT_DESC',
  FieldsByConfigDistinctCountIdAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_ID_ASC',
  FieldsByConfigDistinctCountIdDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_ID_DESC',
  FieldsByConfigDistinctCountCompanyIdAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_ID_ASC',
  FieldsByConfigDistinctCountCompanyIdDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_ID_DESC',
  FieldsByConfigDistinctCountCompanyProductIdAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigDistinctCountCompanyProductIdDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigDistinctCountCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigDistinctCountCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigDistinctCountCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigDistinctCountCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigDistinctCountConfigAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_CONFIG_ASC',
  FieldsByConfigDistinctCountConfigDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_CONFIG_DESC',
  FieldsByConfigDistinctCountTopicAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_TOPIC_ASC',
  FieldsByConfigDistinctCountTopicDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_TOPIC_DESC',
  FieldsByConfigDistinctCountValueAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_VALUE_ASC',
  FieldsByConfigDistinctCountValueDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_VALUE_DESC',
  FieldsByConfigDistinctCountCreatedAtAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldsByConfigDistinctCountCreatedAtDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldsByConfigDistinctCountUpdatedAtAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldsByConfigDistinctCountUpdatedAtDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldsByConfigMinIdAsc = 'FIELDS_BY_CONFIG_MIN_ID_ASC',
  FieldsByConfigMinIdDesc = 'FIELDS_BY_CONFIG_MIN_ID_DESC',
  FieldsByConfigMinCompanyIdAsc = 'FIELDS_BY_CONFIG_MIN_COMPANY_ID_ASC',
  FieldsByConfigMinCompanyIdDesc = 'FIELDS_BY_CONFIG_MIN_COMPANY_ID_DESC',
  FieldsByConfigMinCompanyProductIdAsc = 'FIELDS_BY_CONFIG_MIN_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigMinCompanyProductIdDesc = 'FIELDS_BY_CONFIG_MIN_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigMinCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_MIN_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigMinCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_MIN_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigMinCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_MIN_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigMinCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_MIN_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigMinConfigAsc = 'FIELDS_BY_CONFIG_MIN_CONFIG_ASC',
  FieldsByConfigMinConfigDesc = 'FIELDS_BY_CONFIG_MIN_CONFIG_DESC',
  FieldsByConfigMinTopicAsc = 'FIELDS_BY_CONFIG_MIN_TOPIC_ASC',
  FieldsByConfigMinTopicDesc = 'FIELDS_BY_CONFIG_MIN_TOPIC_DESC',
  FieldsByConfigMinValueAsc = 'FIELDS_BY_CONFIG_MIN_VALUE_ASC',
  FieldsByConfigMinValueDesc = 'FIELDS_BY_CONFIG_MIN_VALUE_DESC',
  FieldsByConfigMinCreatedAtAsc = 'FIELDS_BY_CONFIG_MIN_CREATED_AT_ASC',
  FieldsByConfigMinCreatedAtDesc = 'FIELDS_BY_CONFIG_MIN_CREATED_AT_DESC',
  FieldsByConfigMinUpdatedAtAsc = 'FIELDS_BY_CONFIG_MIN_UPDATED_AT_ASC',
  FieldsByConfigMinUpdatedAtDesc = 'FIELDS_BY_CONFIG_MIN_UPDATED_AT_DESC',
  FieldsByConfigMaxIdAsc = 'FIELDS_BY_CONFIG_MAX_ID_ASC',
  FieldsByConfigMaxIdDesc = 'FIELDS_BY_CONFIG_MAX_ID_DESC',
  FieldsByConfigMaxCompanyIdAsc = 'FIELDS_BY_CONFIG_MAX_COMPANY_ID_ASC',
  FieldsByConfigMaxCompanyIdDesc = 'FIELDS_BY_CONFIG_MAX_COMPANY_ID_DESC',
  FieldsByConfigMaxCompanyProductIdAsc = 'FIELDS_BY_CONFIG_MAX_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigMaxCompanyProductIdDesc = 'FIELDS_BY_CONFIG_MAX_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigMaxCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_MAX_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigMaxCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_MAX_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigMaxCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_MAX_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigMaxCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_MAX_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigMaxConfigAsc = 'FIELDS_BY_CONFIG_MAX_CONFIG_ASC',
  FieldsByConfigMaxConfigDesc = 'FIELDS_BY_CONFIG_MAX_CONFIG_DESC',
  FieldsByConfigMaxTopicAsc = 'FIELDS_BY_CONFIG_MAX_TOPIC_ASC',
  FieldsByConfigMaxTopicDesc = 'FIELDS_BY_CONFIG_MAX_TOPIC_DESC',
  FieldsByConfigMaxValueAsc = 'FIELDS_BY_CONFIG_MAX_VALUE_ASC',
  FieldsByConfigMaxValueDesc = 'FIELDS_BY_CONFIG_MAX_VALUE_DESC',
  FieldsByConfigMaxCreatedAtAsc = 'FIELDS_BY_CONFIG_MAX_CREATED_AT_ASC',
  FieldsByConfigMaxCreatedAtDesc = 'FIELDS_BY_CONFIG_MAX_CREATED_AT_DESC',
  FieldsByConfigMaxUpdatedAtAsc = 'FIELDS_BY_CONFIG_MAX_UPDATED_AT_ASC',
  FieldsByConfigMaxUpdatedAtDesc = 'FIELDS_BY_CONFIG_MAX_UPDATED_AT_DESC',
  FieldsByConfigAverageIdAsc = 'FIELDS_BY_CONFIG_AVERAGE_ID_ASC',
  FieldsByConfigAverageIdDesc = 'FIELDS_BY_CONFIG_AVERAGE_ID_DESC',
  FieldsByConfigAverageCompanyIdAsc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_ID_ASC',
  FieldsByConfigAverageCompanyIdDesc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_ID_DESC',
  FieldsByConfigAverageCompanyProductIdAsc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigAverageCompanyProductIdDesc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigAverageCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigAverageCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigAverageCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigAverageCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigAverageConfigAsc = 'FIELDS_BY_CONFIG_AVERAGE_CONFIG_ASC',
  FieldsByConfigAverageConfigDesc = 'FIELDS_BY_CONFIG_AVERAGE_CONFIG_DESC',
  FieldsByConfigAverageTopicAsc = 'FIELDS_BY_CONFIG_AVERAGE_TOPIC_ASC',
  FieldsByConfigAverageTopicDesc = 'FIELDS_BY_CONFIG_AVERAGE_TOPIC_DESC',
  FieldsByConfigAverageValueAsc = 'FIELDS_BY_CONFIG_AVERAGE_VALUE_ASC',
  FieldsByConfigAverageValueDesc = 'FIELDS_BY_CONFIG_AVERAGE_VALUE_DESC',
  FieldsByConfigAverageCreatedAtAsc = 'FIELDS_BY_CONFIG_AVERAGE_CREATED_AT_ASC',
  FieldsByConfigAverageCreatedAtDesc = 'FIELDS_BY_CONFIG_AVERAGE_CREATED_AT_DESC',
  FieldsByConfigAverageUpdatedAtAsc = 'FIELDS_BY_CONFIG_AVERAGE_UPDATED_AT_ASC',
  FieldsByConfigAverageUpdatedAtDesc = 'FIELDS_BY_CONFIG_AVERAGE_UPDATED_AT_DESC',
  FieldsByConfigStddevSampleIdAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_ID_ASC',
  FieldsByConfigStddevSampleIdDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_ID_DESC',
  FieldsByConfigStddevSampleCompanyIdAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FieldsByConfigStddevSampleCompanyIdDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FieldsByConfigStddevSampleCompanyProductIdAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigStddevSampleCompanyProductIdDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigStddevSampleCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigStddevSampleCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigStddevSampleCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigStddevSampleCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigStddevSampleConfigAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_CONFIG_ASC',
  FieldsByConfigStddevSampleConfigDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_CONFIG_DESC',
  FieldsByConfigStddevSampleTopicAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_TOPIC_ASC',
  FieldsByConfigStddevSampleTopicDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_TOPIC_DESC',
  FieldsByConfigStddevSampleValueAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_VALUE_ASC',
  FieldsByConfigStddevSampleValueDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_VALUE_DESC',
  FieldsByConfigStddevSampleCreatedAtAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldsByConfigStddevSampleCreatedAtDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldsByConfigStddevSampleUpdatedAtAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldsByConfigStddevSampleUpdatedAtDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldsByConfigStddevPopulationIdAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_ID_ASC',
  FieldsByConfigStddevPopulationIdDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_ID_DESC',
  FieldsByConfigStddevPopulationCompanyIdAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_ID_ASC',
  FieldsByConfigStddevPopulationCompanyIdDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_ID_DESC',
  FieldsByConfigStddevPopulationCompanyProductIdAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigStddevPopulationCompanyProductIdDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigStddevPopulationCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigStddevPopulationCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigStddevPopulationCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigStddevPopulationCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigStddevPopulationConfigAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_CONFIG_ASC',
  FieldsByConfigStddevPopulationConfigDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_CONFIG_DESC',
  FieldsByConfigStddevPopulationTopicAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_TOPIC_ASC',
  FieldsByConfigStddevPopulationTopicDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_TOPIC_DESC',
  FieldsByConfigStddevPopulationValueAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_VALUE_ASC',
  FieldsByConfigStddevPopulationValueDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_VALUE_DESC',
  FieldsByConfigStddevPopulationCreatedAtAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldsByConfigStddevPopulationCreatedAtDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldsByConfigStddevPopulationUpdatedAtAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldsByConfigStddevPopulationUpdatedAtDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldsByConfigVarianceSampleIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_ID_ASC',
  FieldsByConfigVarianceSampleIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_ID_DESC',
  FieldsByConfigVarianceSampleCompanyIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FieldsByConfigVarianceSampleCompanyIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FieldsByConfigVarianceSampleCompanyProductIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigVarianceSampleCompanyProductIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigVarianceSampleCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigVarianceSampleCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigVarianceSampleCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigVarianceSampleCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigVarianceSampleConfigAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_CONFIG_ASC',
  FieldsByConfigVarianceSampleConfigDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_CONFIG_DESC',
  FieldsByConfigVarianceSampleTopicAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_TOPIC_ASC',
  FieldsByConfigVarianceSampleTopicDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_TOPIC_DESC',
  FieldsByConfigVarianceSampleValueAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_VALUE_ASC',
  FieldsByConfigVarianceSampleValueDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_VALUE_DESC',
  FieldsByConfigVarianceSampleCreatedAtAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldsByConfigVarianceSampleCreatedAtDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldsByConfigVarianceSampleUpdatedAtAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldsByConfigVarianceSampleUpdatedAtDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldsByConfigVariancePopulationIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_ID_ASC',
  FieldsByConfigVariancePopulationIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_ID_DESC',
  FieldsByConfigVariancePopulationCompanyIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FieldsByConfigVariancePopulationCompanyIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FieldsByConfigVariancePopulationCompanyProductIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigVariancePopulationCompanyProductIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigVariancePopulationCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigVariancePopulationCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigVariancePopulationCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigVariancePopulationCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigVariancePopulationConfigAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_CONFIG_ASC',
  FieldsByConfigVariancePopulationConfigDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_CONFIG_DESC',
  FieldsByConfigVariancePopulationTopicAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_TOPIC_ASC',
  FieldsByConfigVariancePopulationTopicDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_TOPIC_DESC',
  FieldsByConfigVariancePopulationValueAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_VALUE_ASC',
  FieldsByConfigVariancePopulationValueDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_VALUE_DESC',
  FieldsByConfigVariancePopulationCreatedAtAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldsByConfigVariancePopulationCreatedAtDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldsByConfigVariancePopulationUpdatedAtAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldsByConfigVariancePopulationUpdatedAtDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type FieldDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  companyProductId?: Maybe<BigIntFilter>;
  companyChannelId?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  config?: Maybe<BigIntFilter>;
  topic?: Maybe<BigIntFilter>;
  value?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type FieldDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars['BigInt']>;
  /** Distinct count of topic across the matching connection */
  topic?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The fields on `field` to look up the row to connect. */
export type FieldFieldPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to delete. */
export type FieldFieldPkeyDelete = {
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to connect. */
export type FieldFieldUniqueChannelConnect = {
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to delete. */
export type FieldFieldUniqueChannelDelete = {
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to connect. */
export type FieldFieldUniqueIntegrationConnect = {
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to delete. */
export type FieldFieldUniqueIntegrationDelete = {
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to connect. */
export type FieldFieldUniqueProductConnect = {
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to delete. */
export type FieldFieldUniqueProductDelete = {
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** A filter to be used against `Field` object types. All fields are combined with a logical ‘and.’ */
export type FieldFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyProductId` field. */
  companyProductId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyChannelId` field. */
  companyChannelId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `config` field. */
  config?: Maybe<IntFilter>;
  /** Filter by the object’s `topic` field. */
  topic?: Maybe<StringFilter>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyProduct` relation. */
  companyProduct?: Maybe<CompanyProductFilter>;
  /** A related `companyProduct` exists. */
  companyProductExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyChannel` relation. */
  companyChannel?: Maybe<CompanyChannelFilter>;
  /** A related `companyChannel` exists. */
  companyChannelExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyIntegration` relation. */
  companyIntegration?: Maybe<CompanyIntegrationFilter>;
  /** A related `companyIntegration` exists. */
  companyIntegrationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fieldConfigByConfig` relation. */
  fieldConfigByConfig?: Maybe<FieldConfigFilter>;
  /** A related `fieldConfigByConfig` exists. */
  fieldConfigByConfigExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<FieldFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<FieldFilter>>;
  /** Negates the expression. */
  not?: Maybe<FieldFilter>;
};

/** Grouping methods for `Field` for usage during aggregation. */
export enum FieldGroupBy {
  CompanyId = 'COMPANY_ID',
  CompanyProductId = 'COMPANY_PRODUCT_ID',
  CompanyChannelId = 'COMPANY_CHANNEL_ID',
  CompanyIntegrationId = 'COMPANY_INTEGRATION_ID',
  Config = 'CONFIG',
  Topic = 'TOPIC',
  Value = 'VALUE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type FieldHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Field` aggregates. */
export type FieldHavingInput = {
  AND?: Maybe<Array<FieldHavingInput>>;
  OR?: Maybe<Array<FieldHavingInput>>;
  sum?: Maybe<FieldHavingSumInput>;
  distinctCount?: Maybe<FieldHavingDistinctCountInput>;
  min?: Maybe<FieldHavingMinInput>;
  max?: Maybe<FieldHavingMaxInput>;
  average?: Maybe<FieldHavingAverageInput>;
  stddevSample?: Maybe<FieldHavingStddevSampleInput>;
  stddevPopulation?: Maybe<FieldHavingStddevPopulationInput>;
  varianceSample?: Maybe<FieldHavingVarianceSampleInput>;
  variancePopulation?: Maybe<FieldHavingVariancePopulationInput>;
};

export type FieldHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Field` */
export type FieldInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

export type FieldKey = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  public: Scalars['Boolean'];
  type: FieldType;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  key: Scalars['String'];
  /** Reads and enables pagination through a set of `WidgetSetting`. */
  widgetSettingsByFieldKey: WidgetSettingsConnection;
  /** Reads and enables pagination through a set of `Widget`. */
  widgetsByWidgetSettingFieldKeyAndWidgetId: FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyConnection;
};


export type FieldKeyWidgetSettingsByFieldKeyArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
  condition?: Maybe<WidgetSettingCondition>;
  filter?: Maybe<WidgetSettingFilter>;
};


export type FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
  condition?: Maybe<WidgetCondition>;
  filter?: Maybe<WidgetFilter>;
};

export type FieldKeyAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FieldKeySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FieldKeyDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FieldKeyMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FieldKeyMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FieldKeyAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FieldKeyStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FieldKeyStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FieldKeyVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FieldKeyVariancePopulationAggregates>;
};

export type FieldKeyAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `FieldKey` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FieldKeyCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `label` field. */
  label?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `public` field. */
  public?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<FieldType>;
  /** Checks for equality with the object’s `options` field. */
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `key` field. */
  key?: Maybe<Scalars['String']>;
};

export type FieldKeyDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of label across the matching connection */
  label?: Maybe<Scalars['BigInt']>;
  /** Distinct count of public across the matching connection */
  public?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of options across the matching connection */
  options?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of key across the matching connection */
  key?: Maybe<Scalars['BigInt']>;
};

/** The fields on `fieldKey` to look up the row to connect. */
export type FieldKeyFieldKeyKeyKeyConnect = {
  key: Scalars['String'];
};

/** The fields on `fieldKey` to look up the row to delete. */
export type FieldKeyFieldKeyKeyKeyDelete = {
  key: Scalars['String'];
};

/** The fields on `fieldKey` to look up the row to connect. */
export type FieldKeyFieldKeyPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `fieldKey` to look up the row to delete. */
export type FieldKeyFieldKeyPkeyDelete = {
  id: Scalars['Int'];
};

/** A filter to be used against `FieldKey` object types. All fields are combined with a logical ‘and.’ */
export type FieldKeyFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `label` field. */
  label?: Maybe<StringFilter>;
  /** Filter by the object’s `public` field. */
  public?: Maybe<BooleanFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<FieldTypeFilter>;
  /** Filter by the object’s `options` field. */
  options?: Maybe<StringListFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `key` field. */
  key?: Maybe<StringFilter>;
  /** Filter by the object’s `widgetSettingsByFieldKey` relation. */
  widgetSettingsByFieldKey?: Maybe<FieldKeyToManyWidgetSettingFilter>;
  /** Some related `widgetSettingsByFieldKey` exist. */
  widgetSettingsByFieldKeyExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<FieldKeyFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<FieldKeyFilter>>;
  /** Negates the expression. */
  not?: Maybe<FieldKeyFilter>;
};

/** Grouping methods for `FieldKey` for usage during aggregation. */
export enum FieldKeyGroupBy {
  Label = 'LABEL',
  Public = 'PUBLIC',
  Type = 'TYPE',
  Options = 'OPTIONS',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type FieldKeyHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `FieldKey` aggregates. */
export type FieldKeyHavingInput = {
  AND?: Maybe<Array<FieldKeyHavingInput>>;
  OR?: Maybe<Array<FieldKeyHavingInput>>;
  sum?: Maybe<FieldKeyHavingSumInput>;
  distinctCount?: Maybe<FieldKeyHavingDistinctCountInput>;
  min?: Maybe<FieldKeyHavingMinInput>;
  max?: Maybe<FieldKeyHavingMaxInput>;
  average?: Maybe<FieldKeyHavingAverageInput>;
  stddevSample?: Maybe<FieldKeyHavingStddevSampleInput>;
  stddevPopulation?: Maybe<FieldKeyHavingStddevPopulationInput>;
  varianceSample?: Maybe<FieldKeyHavingVarianceSampleInput>;
  variancePopulation?: Maybe<FieldKeyHavingVariancePopulationInput>;
};

export type FieldKeyHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `FieldKey` */
export type FieldKeyInput = {
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  type: FieldType;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  key: Scalars['String'];
  widgetSettingsUsingId?: Maybe<WidgetSettingsFieldKeyFkeyInverseInput>;
};

export type FieldKeyMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type FieldKeyMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type FieldKeyNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `fieldKey` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type FieldKeyNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `fieldKey` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `widgetSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `widgetSetting` being updated. */
  patch: WidgetSettingPatch;
};

/** The fields on `fieldKey` to look up the row to update. */
export type FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingFieldKeyKeyKeyUpdate = {
  /** An object where the defined keys will be set on the `fieldKey` being updated. */
  patch: UpdateFieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyPatch;
  key: Scalars['String'];
};

/** The fields on `fieldKey` to look up the row to update. */
export type FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingFieldKeyPkeyUpdate = {
  /** An object where the defined keys will be set on the `fieldKey` being updated. */
  patch: UpdateFieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `FieldKey`. Fields that are set will be updated. */
export type FieldKeyPatch = {
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  type?: Maybe<FieldType>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsFieldKeyFkeyInverseInput>;
};

export type FieldKeyStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FieldKeyStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FieldKeySumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `WidgetSetting` object types. All fields are combined with a logical ‘and.’ */
export type FieldKeyToManyWidgetSettingFilter = {
  /** Every related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<WidgetSettingFilter>;
  /** Some related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<WidgetSettingFilter>;
  /** No related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<WidgetSettingFilter>;
  /** Aggregates across related `WidgetSetting` match the filter criteria. */
  aggregates?: Maybe<WidgetSettingAggregatesFilter>;
};

export type FieldKeyType = {
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  public: Scalars['Boolean'];
  type: FieldType;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type FieldKeyVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FieldKeyVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Widget` values, with data from `WidgetSetting`. */
export type FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyConnection = {
  /** A list of `Widget` objects. */
  nodes: Array<Widget>;
  /** A list of edges which contains the `Widget`, info from the `WidgetSetting`, and the cursor to aid in pagination. */
  edges: Array<FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Widget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WidgetAggregates>>;
};


/** A connection to a list of `Widget` values, with data from `WidgetSetting`. */
export type FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<WidgetsGroupBy>;
  having?: Maybe<WidgetsHavingInput>;
};

/** A `Widget` edge in the connection, with data from `WidgetSetting`. */
export type FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Widget` at the end of the edge. */
  node: Widget;
  /** Reads and enables pagination through a set of `WidgetSetting`. */
  widgetSettings: WidgetSettingsConnection;
};


/** A `Widget` edge in the connection, with data from `WidgetSetting`. */
export type FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyEdgeWidgetSettingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
  condition?: Maybe<WidgetSettingCondition>;
  filter?: Maybe<WidgetSettingFilter>;
};

/** A connection to a list of `FieldKey` values. */
export type FieldKeysConnection = {
  /** A list of `FieldKey` objects. */
  nodes: Array<FieldKey>;
  /** A list of edges which contains the `FieldKey` and cursor to aid in pagination. */
  edges: Array<FieldKeysEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldKey` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldKeyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldKeyAggregates>>;
};


/** A connection to a list of `FieldKey` values. */
export type FieldKeysConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldKeyGroupBy>;
  having?: Maybe<FieldKeyHavingInput>;
};

/** A `FieldKey` edge in the connection. */
export type FieldKeysEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldKey` at the end of the edge. */
  node: FieldKey;
};

/** Methods to use when ordering `FieldKey`. */
export enum FieldKeysOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  PublicAsc = 'PUBLIC_ASC',
  PublicDesc = 'PUBLIC_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  WidgetSettingsByFieldKeyCountAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_COUNT_ASC',
  WidgetSettingsByFieldKeyCountDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_COUNT_DESC',
  WidgetSettingsByFieldKeySumIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_ID_ASC',
  WidgetSettingsByFieldKeySumIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_ID_DESC',
  WidgetSettingsByFieldKeySumWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeySumWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeySumTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_TOPIC_ASC',
  WidgetSettingsByFieldKeySumTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_TOPIC_DESC',
  WidgetSettingsByFieldKeySumFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeySumFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeySumValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_VALUE_ASC',
  WidgetSettingsByFieldKeySumValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_VALUE_DESC',
  WidgetSettingsByFieldKeySumCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_CREATED_AT_ASC',
  WidgetSettingsByFieldKeySumCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_CREATED_AT_DESC',
  WidgetSettingsByFieldKeySumUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeySumUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyDistinctCountIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_ID_ASC',
  WidgetSettingsByFieldKeyDistinctCountIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_ID_DESC',
  WidgetSettingsByFieldKeyDistinctCountWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyDistinctCountWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyDistinctCountTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_TOPIC_ASC',
  WidgetSettingsByFieldKeyDistinctCountTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_TOPIC_DESC',
  WidgetSettingsByFieldKeyDistinctCountFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyDistinctCountFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyDistinctCountValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_VALUE_ASC',
  WidgetSettingsByFieldKeyDistinctCountValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_VALUE_DESC',
  WidgetSettingsByFieldKeyDistinctCountCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyDistinctCountCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyDistinctCountUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyDistinctCountUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyMinIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_ID_ASC',
  WidgetSettingsByFieldKeyMinIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_ID_DESC',
  WidgetSettingsByFieldKeyMinWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyMinWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyMinTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_TOPIC_ASC',
  WidgetSettingsByFieldKeyMinTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_TOPIC_DESC',
  WidgetSettingsByFieldKeyMinFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyMinFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyMinValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_VALUE_ASC',
  WidgetSettingsByFieldKeyMinValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_VALUE_DESC',
  WidgetSettingsByFieldKeyMinCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyMinCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyMinUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyMinUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyMaxIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_ID_ASC',
  WidgetSettingsByFieldKeyMaxIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_ID_DESC',
  WidgetSettingsByFieldKeyMaxWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyMaxWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyMaxTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_TOPIC_ASC',
  WidgetSettingsByFieldKeyMaxTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_TOPIC_DESC',
  WidgetSettingsByFieldKeyMaxFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyMaxFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyMaxValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_VALUE_ASC',
  WidgetSettingsByFieldKeyMaxValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_VALUE_DESC',
  WidgetSettingsByFieldKeyMaxCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyMaxCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyMaxUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyMaxUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyAverageIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_ID_ASC',
  WidgetSettingsByFieldKeyAverageIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_ID_DESC',
  WidgetSettingsByFieldKeyAverageWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyAverageWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyAverageTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_TOPIC_ASC',
  WidgetSettingsByFieldKeyAverageTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_TOPIC_DESC',
  WidgetSettingsByFieldKeyAverageFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyAverageFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyAverageValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_VALUE_ASC',
  WidgetSettingsByFieldKeyAverageValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_VALUE_DESC',
  WidgetSettingsByFieldKeyAverageCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyAverageCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyAverageUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyAverageUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyStddevSampleIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_ID_ASC',
  WidgetSettingsByFieldKeyStddevSampleIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_ID_DESC',
  WidgetSettingsByFieldKeyStddevSampleWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyStddevSampleWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyStddevSampleTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_TOPIC_ASC',
  WidgetSettingsByFieldKeyStddevSampleTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_TOPIC_DESC',
  WidgetSettingsByFieldKeyStddevSampleFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyStddevSampleFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyStddevSampleValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_VALUE_ASC',
  WidgetSettingsByFieldKeyStddevSampleValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_VALUE_DESC',
  WidgetSettingsByFieldKeyStddevSampleCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyStddevSampleCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyStddevSampleUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyStddevSampleUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyStddevPopulationIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_ID_ASC',
  WidgetSettingsByFieldKeyStddevPopulationIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_ID_DESC',
  WidgetSettingsByFieldKeyStddevPopulationWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyStddevPopulationWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyStddevPopulationTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_TOPIC_ASC',
  WidgetSettingsByFieldKeyStddevPopulationTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_TOPIC_DESC',
  WidgetSettingsByFieldKeyStddevPopulationFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyStddevPopulationFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyStddevPopulationValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_VALUE_ASC',
  WidgetSettingsByFieldKeyStddevPopulationValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_VALUE_DESC',
  WidgetSettingsByFieldKeyStddevPopulationCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyStddevPopulationCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyStddevPopulationUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyStddevPopulationUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyVarianceSampleIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_ID_ASC',
  WidgetSettingsByFieldKeyVarianceSampleIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_ID_DESC',
  WidgetSettingsByFieldKeyVarianceSampleWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyVarianceSampleWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyVarianceSampleTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_TOPIC_ASC',
  WidgetSettingsByFieldKeyVarianceSampleTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_TOPIC_DESC',
  WidgetSettingsByFieldKeyVarianceSampleFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyVarianceSampleFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyVarianceSampleValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_VALUE_ASC',
  WidgetSettingsByFieldKeyVarianceSampleValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_VALUE_DESC',
  WidgetSettingsByFieldKeyVarianceSampleCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyVarianceSampleCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyVarianceSampleUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyVarianceSampleUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyVariancePopulationIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_ID_ASC',
  WidgetSettingsByFieldKeyVariancePopulationIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_ID_DESC',
  WidgetSettingsByFieldKeyVariancePopulationWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyVariancePopulationWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyVariancePopulationTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_TOPIC_ASC',
  WidgetSettingsByFieldKeyVariancePopulationTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_TOPIC_DESC',
  WidgetSettingsByFieldKeyVariancePopulationFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyVariancePopulationFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyVariancePopulationValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_VALUE_ASC',
  WidgetSettingsByFieldKeyVariancePopulationValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_VALUE_DESC',
  WidgetSettingsByFieldKeyVariancePopulationCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyVariancePopulationCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyVariancePopulationUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyVariancePopulationUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type FieldMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  companyProductId?: Maybe<IntFilter>;
  companyChannelId?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  config?: Maybe<IntFilter>;
};

export type FieldMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['Int']>;
  /** Maximum of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Maximum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Maximum of config across the matching connection */
  config?: Maybe<Scalars['Int']>;
};

export type FieldMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  companyProductId?: Maybe<IntFilter>;
  companyChannelId?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  config?: Maybe<IntFilter>;
};

export type FieldMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['Int']>;
  /** Minimum of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Minimum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Minimum of config across the matching connection */
  config?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type FieldNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type FieldNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `field` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldOnFieldForFieldCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueChannelUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyChannelIdFkeyPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueIntegrationUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyChannelIdFkeyPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueProductUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyChannelIdFkeyPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldOnFieldForFieldCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIdFkeyUsingFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueChannelUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIdFkeyPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueIntegrationUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIdFkeyPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueProductUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIdFkeyPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldOnFieldForFieldCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueChannelUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIntegrationIdFkeyPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueIntegrationUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIntegrationIdFkeyPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueProductUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIntegrationIdFkeyPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldOnFieldForFieldCompanyProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: CompanyProductPatch;
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueChannelUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyProductIdFkeyPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueIntegrationUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyProductIdFkeyPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueProductUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyProductIdFkeyPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldOnFieldForFieldConfigFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `fieldConfig` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: FieldConfigPatch;
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldConfigFkeyUsingFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldConfigFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldConfigFkeyUsingFieldUniqueChannelUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldConfigFkeyPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldConfigFkeyUsingFieldUniqueIntegrationUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldConfigFkeyPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldConfigFkeyUsingFieldUniqueProductUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldConfigFkeyPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** Represents an update to a `Field`. Fields that are set will be updated. */
export type FieldPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

export type FieldStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  config?: Maybe<BigFloatFilter>;
};

export type FieldStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of config across the matching connection */
  config?: Maybe<Scalars['BigFloat']>;
};

export type FieldStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  config?: Maybe<BigFloatFilter>;
};

export type FieldStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of config across the matching connection */
  config?: Maybe<Scalars['BigFloat']>;
};

export type FieldSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  companyProductId?: Maybe<BigIntFilter>;
  companyChannelId?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  config?: Maybe<BigIntFilter>;
};

export type FieldSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of companyProductId across the matching connection */
  companyProductId: Scalars['BigInt'];
  /** Sum of companyChannelId across the matching connection */
  companyChannelId: Scalars['BigInt'];
  /** Sum of companyIntegrationId across the matching connection */
  companyIntegrationId: Scalars['BigInt'];
  /** Sum of config across the matching connection */
  config: Scalars['BigInt'];
};

export enum FieldType {
  Text = 'TEXT',
  Option = 'OPTION'
}

/** A filter to be used against FieldType fields. All fields are combined with a logical ‘and.’ */
export type FieldTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<FieldType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<FieldType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<FieldType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<FieldType>;
  /** Included in the specified list. */
  in?: Maybe<Array<FieldType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<FieldType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<FieldType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<FieldType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<FieldType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<FieldType>;
};

export type FieldVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  config?: Maybe<BigFloatFilter>;
};

export type FieldVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of config across the matching connection */
  config?: Maybe<Scalars['BigFloat']>;
};

export type FieldVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  config?: Maybe<BigFloatFilter>;
};

export type FieldVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of config across the matching connection */
  config?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Field` values. */
export type FieldsConnection = {
  /** A list of `Field` objects. */
  nodes: Array<Field>;
  /** A list of edges which contains the `Field` and cursor to aid in pagination. */
  edges: Array<FieldsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Field` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldAggregates>>;
};


/** A connection to a list of `Field` values. */
export type FieldsConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldGroupBy>;
  having?: Maybe<FieldHavingInput>;
};

/** A `Field` edge in the connection. */
export type FieldsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Field` at the end of the edge. */
  node: Field;
};

/** Methods to use when ordering `Field`. */
export enum FieldsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompanyProductIdAsc = 'COMPANY_PRODUCT_ID_ASC',
  CompanyProductIdDesc = 'COMPANY_PRODUCT_ID_DESC',
  CompanyChannelIdAsc = 'COMPANY_CHANNEL_ID_ASC',
  CompanyChannelIdDesc = 'COMPANY_CHANNEL_ID_DESC',
  CompanyIntegrationIdAsc = 'COMPANY_INTEGRATION_ID_ASC',
  CompanyIntegrationIdDesc = 'COMPANY_INTEGRATION_ID_DESC',
  ConfigAsc = 'CONFIG_ASC',
  ConfigDesc = 'CONFIG_DESC',
  TopicAsc = 'TOPIC_ASC',
  TopicDesc = 'TOPIC_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type File = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  lenderProgramId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  url: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `LenderProgram` that is related to this `File`. */
  lenderProgram?: Maybe<LenderProgram>;
};

export type FileAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FileSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FileDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FileMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FileMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FileAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FileStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FileStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FileVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FileVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `File` object types. */
export type FileAggregatesFilter = {
  /** A filter that must pass for the relevant `File` object to be included within the aggregate. */
  filter?: Maybe<FileFilter>;
  /** Sum aggregate over matching `File` objects. */
  sum?: Maybe<FileSumAggregateFilter>;
  /** Distinct count aggregate over matching `File` objects. */
  distinctCount?: Maybe<FileDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `File` objects. */
  min?: Maybe<FileMinAggregateFilter>;
  /** Maximum aggregate over matching `File` objects. */
  max?: Maybe<FileMaxAggregateFilter>;
  /** Mean average aggregate over matching `File` objects. */
  average?: Maybe<FileAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `File` objects. */
  stddevSample?: Maybe<FileStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `File` objects. */
  stddevPopulation?: Maybe<FileStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `File` objects. */
  varianceSample?: Maybe<FileVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `File` objects. */
  variancePopulation?: Maybe<FileVariancePopulationAggregateFilter>;
};

export type FileAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
};

export type FileAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `File` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FileCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lenderProgramId` field. */
  lenderProgramId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `url` field. */
  url?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type FileDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderProgramId?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  displayName?: Maybe<BigIntFilter>;
  url?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type FileDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of url across the matching connection */
  url?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The fields on `file` to look up the row to connect. */
export type FileFilePkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `file` to look up the row to delete. */
export type FileFilePkeyDelete = {
  id: Scalars['Int'];
};

/** A filter to be used against `File` object types. All fields are combined with a logical ‘and.’ */
export type FileFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `lenderProgramId` field. */
  lenderProgramId?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  url?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lenderProgram` relation. */
  lenderProgram?: Maybe<LenderProgramFilter>;
  /** A related `lenderProgram` exists. */
  lenderProgramExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<FileFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<FileFilter>>;
  /** Negates the expression. */
  not?: Maybe<FileFilter>;
};

/** Grouping methods for `File` for usage during aggregation. */
export enum FileGroupBy {
  LenderProgramId = 'LENDER_PROGRAM_ID',
  Name = 'NAME',
  DisplayName = 'DISPLAY_NAME',
  Url = 'URL',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type FileHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FileHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `File` aggregates. */
export type FileHavingInput = {
  AND?: Maybe<Array<FileHavingInput>>;
  OR?: Maybe<Array<FileHavingInput>>;
  sum?: Maybe<FileHavingSumInput>;
  distinctCount?: Maybe<FileHavingDistinctCountInput>;
  min?: Maybe<FileHavingMinInput>;
  max?: Maybe<FileHavingMaxInput>;
  average?: Maybe<FileHavingAverageInput>;
  stddevSample?: Maybe<FileHavingStddevSampleInput>;
  stddevPopulation?: Maybe<FileHavingStddevPopulationInput>;
  varianceSample?: Maybe<FileHavingVarianceSampleInput>;
  variancePopulation?: Maybe<FileHavingVariancePopulationInput>;
};

export type FileHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FileHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FileHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FileHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FileHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FileHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FileHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `File` */
export type FileInput = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  url: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<FileLenderProgramIdFkeyInput>;
};

/** The `file` to be created by this mutation. */
export type FileLenderProgramIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  url: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<FileLenderProgramIdFkeyInput>;
};

/** Input for the nested mutation of `lenderProgram` in the `FileInput` mutation. */
export type FileLenderProgramIdFkeyInput = {
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  connectById?: Maybe<LenderProgramLenderProgramPkeyConnect>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  connectByNodeId?: Maybe<LenderProgramNodeIdConnect>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  deleteById?: Maybe<LenderProgramLenderProgramPkeyDelete>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LenderProgramNodeIdDelete>;
  /** The primary key(s) and patch data for `lenderProgram` for the far side of the relationship. */
  updateById?: Maybe<LenderProgramOnFileForFileLenderProgramIdFkeyUsingLenderProgramPkeyUpdate>;
  /** The primary key(s) and patch data for `lenderProgram` for the far side of the relationship. */
  updateByNodeId?: Maybe<FileOnFileForFileLenderProgramIdFkeyNodeIdUpdate>;
  /** A `LenderProgramInput` object that will be created and connected to this object. */
  create?: Maybe<FileLenderProgramIdFkeyLenderProgramCreateInput>;
};

/** Input for the nested mutation of `file` in the `LenderProgramInput` mutation. */
export type FileLenderProgramIdFkeyInverseInput = {
  /** Flag indicating whether all other `file` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<Array<FileFilePkeyConnect>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FileNodeIdConnect>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<Array<FileFilePkeyDelete>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FileNodeIdDelete>>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<Array<FileOnFileForFileLenderProgramIdFkeyUsingFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LenderProgramOnFileForFileLenderProgramIdFkeyNodeIdUpdate>>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FileLenderProgramIdFkeyFileCreateInput>>;
};

/** The `lenderProgram` to be created by this mutation. */
export type FileLenderProgramIdFkeyLenderProgramCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  applicationRequirements: Array<Maybe<Scalars['String']>>;
  vehicleRequirements: Array<Maybe<Scalars['String']>>;
  minDate?: Maybe<Scalars['Datetime']>;
  maxDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderToLenderId?: Maybe<LenderProgramLenderIdFkeyInput>;
  lenderCreditKeysUsingId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInverseInput>;
  vehicleBookingsUsingId?: Maybe<VehicleBookingLenderProgramIdFkeyInverseInput>;
  filesUsingId?: Maybe<FileLenderProgramIdFkeyInverseInput>;
};

export type FileMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderProgramId?: Maybe<IntFilter>;
};

export type FileMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['Int']>;
};

export type FileMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderProgramId?: Maybe<IntFilter>;
};

export type FileMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type FileNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type FileNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `file` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnFileForFileLenderProgramIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderProgram` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderProgram` being updated. */
  patch: LenderProgramPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnFileForFileLenderProgramIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnFileForFileLenderProgramIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `File`. Fields that are set will be updated. */
export type FilePatch = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<FileLenderProgramIdFkeyInput>;
};

export type FileStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
};

export type FileStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
};

export type FileStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
};

export type FileStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
};

export type FileSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderProgramId?: Maybe<BigIntFilter>;
};

export type FileSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lenderProgramId across the matching connection */
  lenderProgramId: Scalars['BigInt'];
};

export type FileVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
};

export type FileVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
};

export type FileVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
};

export type FileVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `File` values. */
export type FilesConnection = {
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File` and cursor to aid in pagination. */
  edges: Array<FilesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FileAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FileAggregates>>;
};


/** A connection to a list of `File` values. */
export type FilesConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having?: Maybe<FileHavingInput>;
};

/** A `File` edge in the connection. */
export type FilesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
};

/** Methods to use when ordering `File`. */
export enum FilesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LenderProgramIdAsc = 'LENDER_PROGRAM_ID_ASC',
  LenderProgramIdDesc = 'LENDER_PROGRAM_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type FixAssetInput = {
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
};

/** The `companyAnalytic` to be created by this mutation. */
export type FkCompanyAnalyticsCompanyIdCompanyAnalyticsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  type: AnalyticsType;
  analyticsId: Scalars['String'];
  analyticsSecret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FkCompanyAnalyticsCompanyIdInput>;
};

/** The `company` to be created by this mutation. */
export type FkCompanyAnalyticsCompanyIdCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `CompanyAnalyticInput` mutation. */
export type FkCompanyAnalyticsCompanyIdInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<FkCompanyAnalyticsCompanyIdCompanyCreateInput>;
};

/** Input for the nested mutation of `companyAnalytic` in the `CompanyInput` mutation. */
export type FkCompanyAnalyticsCompanyIdInverseInput = {
  /** Flag indicating whether all other `companyAnalytic` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyAnalytic` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyAnalyticCompanyAnalyticsPkeyConnect>>;
  /** The primary key(s) for `companyAnalytic` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyAnalyticNodeIdConnect>>;
  /** The primary key(s) for `companyAnalytic` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyAnalyticCompanyAnalyticsPkeyDelete>>;
  /** The primary key(s) for `companyAnalytic` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyAnalyticNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyAnalytic` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyAnalyticsPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyAnalytic` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdNodeIdUpdate>>;
  /** A `CompanyAnalyticInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkCompanyAnalyticsCompanyIdCompanyAnalyticsCreateInput>>;
};

/** Input for the nested mutation of `user` in the `UserActivityInput` mutation. */
export type FkUserActivityUserIdInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnUserActivityForFkUserActivityUserIdUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<UserActivityOnUserActivityForFkUserActivityUserIdNodeIdUpdate>;
};

/** Input for the nested mutation of `userActivity` in the `UserInput` mutation. */
export type FkUserActivityUserIdInverseInput = {
  /** Flag indicating whether all other `userActivity` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `userActivity` for the far side of the relationship. */
  connectById?: Maybe<Array<UserActivityUserActivityPkeyConnect>>;
  /** The primary key(s) for `userActivity` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<UserActivityNodeIdConnect>>;
  /** The primary key(s) for `userActivity` for the far side of the relationship. */
  deleteById?: Maybe<Array<UserActivityUserActivityPkeyDelete>>;
  /** The primary key(s) for `userActivity` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<UserActivityNodeIdDelete>>;
  /** The primary key(s) and patch data for `userActivity` for the far side of the relationship. */
  updateById?: Maybe<Array<UserActivityOnUserActivityForFkUserActivityUserIdUsingUserActivityPkeyUpdate>>;
  /** The primary key(s) and patch data for `userActivity` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnUserActivityForFkUserActivityUserIdNodeIdUpdate>>;
  /** A `UserActivityInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkUserActivityUserIdUserActivityCreateInput>>;
};

/** The `userActivity` to be created by this mutation. */
export type FkUserActivityUserIdUserActivityCreateInput = {
  id?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
  claims: Array<Maybe<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  userId?: Maybe<Scalars['Int']>;
  userToUserId?: Maybe<FkUserActivityUserIdInput>;
};

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Float']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Float']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Float']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Float']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Float']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Float']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Float']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Float']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Float']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Float']>;
};


/** A filter to be used against FullText fields. All fields are combined with a logical ‘and.’ */
export type FullTextFilter = {
  /** Performs a full text search on the field. */
  matches?: Maybe<Scalars['String']>;
};

/** The return type of our `getCompanyIdsByCognitoGroups` query. */
export type GetCompanyIdsByCognitoGroupsRecord = {
  administeredCompanyIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  normalCompanyIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** The return type of our `getCompanyIdsForUser` query. */
export type GetCompanyIdsForUserRecord = {
  administeredCompanyIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  normalCompanyIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GetCreditAppCreditorsResponse = {
  creditorId?: Maybe<Scalars['String']>;
  creditorName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type GetMediaUploadUrlInput = {
  companyId: Scalars['Int'];
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  mediaType: MediaType;
  mediaParentType: MediaParentType;
  mediaParentId: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  meta?: Maybe<MediaMetaInput>;
};

export type GetMediaUploadUrlResponse = {
  mediaId: Scalars['Int'];
  signedUploadUrl: Scalars['String'];
};

export type GetQrCodeImageInput = {
  widgetId: Scalars['String'];
  product: ProductType;
  logo: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  qr?: Maybe<Scalars['String']>;
};

export type GetQrCodeImageResponse = {
  image?: Maybe<Scalars['String']>;
};

export type GiveUserAccessInput = {
  username: Scalars['String'];
  groupId: Scalars['Int'];
  makeGroupAdmin?: Maybe<Scalars['Boolean']>;
};

export type Group = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type: GroupType;
  distributionType: GroupDistType;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `Group`. */
  companyByPrimaryGroup?: Maybe<Company>;
  /** Reads and enables pagination through a set of `CompanyWidget`. */
  companyWidgets: CompanyWidgetsConnection;
  /** Reads and enables pagination through a set of `CompanyGroup`. */
  companyGroups: CompanyGroupsConnection;
  /** Reads and enables pagination through a set of `CtaAsset`. */
  ctaAssets: CtaAssetsConnection;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyCtas: CompanyCtasConnection;
  /** Reads and enables pagination through a set of `DeliveryMethod`. */
  deliveryMethods: DeliveryMethodsConnection;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsers: CompanyUsersConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companies: CompaniesConnection;
  /** Reads and enables pagination through a set of `Widget`. */
  widgets: WidgetsConnection;
  /** Reads and enables pagination through a set of `Widget`. */
  widgetsByCompanyWidgetGroupIdAndWidgetId: GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Cta`. */
  ctasByCompanyCtaGroupIdAndCtaId: GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyUserGroupIdAndCompanyId: GroupCompaniesByCompanyUserGroupIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByCompanyUserGroupIdAndUserId: GroupUsersByCompanyUserGroupIdAndUserIdManyToManyConnection;
};


export type GroupCompanyWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
  condition?: Maybe<CompanyWidgetCondition>;
  filter?: Maybe<CompanyWidgetFilter>;
};


export type GroupCompanyGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
  condition?: Maybe<CompanyGroupCondition>;
  filter?: Maybe<CompanyGroupFilter>;
};


export type GroupCtaAssetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaAssetsOrderBy>>;
  condition?: Maybe<CtaAssetCondition>;
  filter?: Maybe<CtaAssetFilter>;
};


export type GroupCompanyCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
  condition?: Maybe<CompanyCtaCondition>;
  filter?: Maybe<CompanyCtaFilter>;
};


export type GroupDeliveryMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DeliveryMethodsOrderBy>>;
  condition?: Maybe<DeliveryMethodCondition>;
  filter?: Maybe<DeliveryMethodFilter>;
};


export type GroupCompanyUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyUsersOrderBy>>;
  condition?: Maybe<CompanyUserCondition>;
  filter?: Maybe<CompanyUserFilter>;
};


export type GroupCompaniesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CompanyFilter>;
};


export type GroupWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<WidgetFilter>;
};


export type GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
  condition?: Maybe<WidgetCondition>;
  filter?: Maybe<WidgetFilter>;
};


export type GroupCtasByCompanyCtaGroupIdAndCtaIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtasOrderBy>>;
  condition?: Maybe<CtaCondition>;
  filter?: Maybe<CtaFilter>;
};


export type GroupCompaniesByCompanyUserGroupIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type GroupUsersByCompanyUserGroupIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};

export type GroupAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<GroupSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<GroupDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<GroupMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<GroupMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<GroupAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<GroupStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<GroupStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<GroupVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<GroupVariancePopulationAggregates>;
};

export type GroupAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Company` values, with data from `CompanyUser`. */
export type GroupCompaniesByCompanyUserGroupIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyUser`, and the cursor to aid in pagination. */
  edges: Array<GroupCompaniesByCompanyUserGroupIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyUser`. */
export type GroupCompaniesByCompanyUserGroupIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyUser`. */
export type GroupCompaniesByCompanyUserGroupIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsers: CompanyUsersConnection;
};


/** A `Company` edge in the connection, with data from `CompanyUser`. */
export type GroupCompaniesByCompanyUserGroupIdAndCompanyIdManyToManyEdgeCompanyUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyUsersOrderBy>>;
  condition?: Maybe<CompanyUserCondition>;
  filter?: Maybe<CompanyUserFilter>;
};

/** A condition to be used against `Group` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cognitoName` field. */
  cognitoName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<GroupType>;
  /** Checks for equality with the object’s `distributionType` field. */
  distributionType?: Maybe<GroupDistType>;
  /** Checks for equality with the object’s `legalDesc` field. */
  legalDesc?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cognitoGroup` field. */
  cognitoGroup?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cognitoAdminGroup` field. */
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Cta` values, with data from `CompanyCta`. */
export type GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyConnection = {
  /** A list of `Cta` objects. */
  nodes: Array<Cta>;
  /** A list of edges which contains the `Cta`, info from the `CompanyCta`, and the cursor to aid in pagination. */
  edges: Array<GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Cta` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CtaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CtaAggregates>>;
};


/** A connection to a list of `Cta` values, with data from `CompanyCta`. */
export type GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CtasGroupBy>;
  having?: Maybe<CtasHavingInput>;
};

/** A `Cta` edge in the connection, with data from `CompanyCta`. */
export type GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Cta` at the end of the edge. */
  node: Cta;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyCtas: CompanyCtasConnection;
};


/** A `Cta` edge in the connection, with data from `CompanyCta`. */
export type GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyEdgeCompanyCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
  condition?: Maybe<CompanyCtaCondition>;
  filter?: Maybe<CompanyCtaFilter>;
};

export type GroupDetailsInput = {
  distributionType: GroupDistType;
  groupType: GroupType;
};

export enum GroupDistType {
  Direct = 'DIRECT',
  Geo = 'GEO',
  User = 'USER'
}

/** A filter to be used against GroupDistType fields. All fields are combined with a logical ‘and.’ */
export type GroupDistTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<GroupDistType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<GroupDistType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<GroupDistType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<GroupDistType>;
  /** Included in the specified list. */
  in?: Maybe<Array<GroupDistType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<GroupDistType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<GroupDistType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<GroupDistType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<GroupDistType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<GroupDistType>;
};

export type GroupDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cognitoName across the matching connection */
  cognitoName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of distributionType across the matching connection */
  distributionType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of legalDesc across the matching connection */
  legalDesc?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cognitoGroup across the matching connection */
  cognitoGroup?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cognitoAdminGroup across the matching connection */
  cognitoAdminGroup?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Group` object types. All fields are combined with a logical ‘and.’ */
export type GroupFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `cognitoName` field. */
  cognitoName?: Maybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<GroupTypeFilter>;
  /** Filter by the object’s `distributionType` field. */
  distributionType?: Maybe<GroupDistTypeFilter>;
  /** Filter by the object’s `legalDesc` field. */
  legalDesc?: Maybe<StringFilter>;
  /** Filter by the object’s `cognitoGroup` field. */
  cognitoGroup?: Maybe<StringFilter>;
  /** Filter by the object’s `cognitoAdminGroup` field. */
  cognitoAdminGroup?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `companyByPrimaryGroup` relation. */
  companyByPrimaryGroup?: Maybe<CompanyFilter>;
  /** A related `companyByPrimaryGroup` exists. */
  companyByPrimaryGroupExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyWidgets` relation. */
  companyWidgets?: Maybe<GroupToManyCompanyWidgetFilter>;
  /** Some related `companyWidgets` exist. */
  companyWidgetsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyGroups` relation. */
  companyGroups?: Maybe<GroupToManyCompanyGroupFilter>;
  /** Some related `companyGroups` exist. */
  companyGroupsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `ctaAssets` relation. */
  ctaAssets?: Maybe<GroupToManyCtaAssetFilter>;
  /** Some related `ctaAssets` exist. */
  ctaAssetsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyCtas` relation. */
  companyCtas?: Maybe<GroupToManyCompanyCtaFilter>;
  /** Some related `companyCtas` exist. */
  companyCtasExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `deliveryMethods` relation. */
  deliveryMethods?: Maybe<GroupToManyDeliveryMethodFilter>;
  /** Some related `deliveryMethods` exist. */
  deliveryMethodsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyUsers` relation. */
  companyUsers?: Maybe<GroupToManyCompanyUserFilter>;
  /** Some related `companyUsers` exist. */
  companyUsersExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GroupFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GroupFilter>>;
  /** Negates the expression. */
  not?: Maybe<GroupFilter>;
};

/** Grouping methods for `Group` for usage during aggregation. */
export enum GroupGroupBy {
  Name = 'NAME',
  Type = 'TYPE',
  DistributionType = 'DISTRIBUTION_TYPE',
  LegalDesc = 'LEGAL_DESC',
  CognitoGroup = 'COGNITO_GROUP',
  CognitoAdminGroup = 'COGNITO_ADMIN_GROUP',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

/** The fields on `group` to look up the row to connect. */
export type GroupGroupCognitoNameKeyConnect = {
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to connect. */
export type GroupGroupPkeyConnect = {
  id: Scalars['Int'];
};

export type GroupHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Group` aggregates. */
export type GroupHavingInput = {
  AND?: Maybe<Array<GroupHavingInput>>;
  OR?: Maybe<Array<GroupHavingInput>>;
  sum?: Maybe<GroupHavingSumInput>;
  distinctCount?: Maybe<GroupHavingDistinctCountInput>;
  min?: Maybe<GroupHavingMinInput>;
  max?: Maybe<GroupHavingMaxInput>;
  average?: Maybe<GroupHavingAverageInput>;
  stddevSample?: Maybe<GroupHavingStddevSampleInput>;
  stddevPopulation?: Maybe<GroupHavingStddevPopulationInput>;
  varianceSample?: Maybe<GroupHavingVarianceSampleInput>;
  variancePopulation?: Maybe<GroupHavingVariancePopulationInput>;
};

export type GroupHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Group` */
export type GroupInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

export type GroupMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type GroupMembership = {
  id: Scalars['Int'];
  name: Scalars['String'];
  type: Scalars['String'];
  users: Array<UserDetails>;
};

export type GroupMemberships = {
  groups: Array<GroupMembership>;
};

export type GroupMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GroupNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCompanyCtaForCompanyCtasGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyCta` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyCta` being updated. */
  patch: CompanyCtaPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyCtaForCompanyCtasGroupIdFkeyUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyCtaForCompanyCtasGroupIdFkeyPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyCtaForCompanyCtasGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyCtaForCompanyCtasGroupIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCompanyForCompanyPrimaryGroupFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyForCompanyPrimaryGroupFkeyUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyForCompanyPrimaryGroupFkeyPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyForCompanyPrimaryGroupFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyForCompanyPrimaryGroupFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCompanyGroupForCompanyGroupGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyGroup` being updated. */
  patch: CompanyGroupPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyGroupForCompanyGroupGroupIdFkeyPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyGroupForCompanyGroupGroupIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCompanyUserForCompanyUserGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyUser` being updated. */
  patch: CompanyUserPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyUserForCompanyUserGroupIdFkeyUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyUserForCompanyUserGroupIdFkeyPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyUserForCompanyUserGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyUserForCompanyUserGroupIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyWidget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyWidget` being updated. */
  patch: CompanyWidgetPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyWidgetForCompanyWidgetsGroupIdFkPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyWidgetForCompanyWidgetsGroupIdFkPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCtaAssetForCtaAssetsGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `ctaAsset` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ctaAsset` being updated. */
  patch: CtaAssetPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCtaAssetForCtaAssetsGroupIdFkeyUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCtaAssetForCtaAssetsGroupIdFkeyPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCtaAssetForCtaAssetsGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCtaAssetForCtaAssetsGroupIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnDeliveryMethodForDeliveryMethodGroupIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `deliveryMethod` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `deliveryMethod` being updated. */
  patch: DeliveryMethodPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnDeliveryMethodForDeliveryMethodGroupIdFkUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnDeliveryMethodForDeliveryMethodGroupIdFkPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnDeliveryMethodForDeliveryMethodGroupIdFkUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnDeliveryMethodForDeliveryMethodGroupIdFkPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Group`. Fields that are set will be updated. */
export type GroupPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

export type GroupStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type GroupStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type GroupSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyCta` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyCompanyCtaFilter = {
  /** Every related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyCtaFilter>;
  /** Some related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyCtaFilter>;
  /** No related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyCtaFilter>;
  /** Aggregates across related `CompanyCta` match the filter criteria. */
  aggregates?: Maybe<CompanyCtaAggregatesFilter>;
};

/** A filter to be used against many `CompanyGroup` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyCompanyGroupFilter = {
  /** Every related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyGroupFilter>;
  /** Some related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyGroupFilter>;
  /** No related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyGroupFilter>;
  /** Aggregates across related `CompanyGroup` match the filter criteria. */
  aggregates?: Maybe<CompanyGroupAggregatesFilter>;
};

/** A filter to be used against many `CompanyUser` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyCompanyUserFilter = {
  /** Every related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyUserFilter>;
  /** Some related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyUserFilter>;
  /** No related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyUserFilter>;
  /** Aggregates across related `CompanyUser` match the filter criteria. */
  aggregates?: Maybe<CompanyUserAggregatesFilter>;
};

/** A filter to be used against many `CompanyWidget` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyCompanyWidgetFilter = {
  /** Every related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyWidgetFilter>;
  /** Some related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyWidgetFilter>;
  /** No related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyWidgetFilter>;
  /** Aggregates across related `CompanyWidget` match the filter criteria. */
  aggregates?: Maybe<CompanyWidgetAggregatesFilter>;
};

/** A filter to be used against many `CtaAsset` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyCtaAssetFilter = {
  /** Every related `CtaAsset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CtaAssetFilter>;
  /** Some related `CtaAsset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CtaAssetFilter>;
  /** No related `CtaAsset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CtaAssetFilter>;
  /** Aggregates across related `CtaAsset` match the filter criteria. */
  aggregates?: Maybe<CtaAssetAggregatesFilter>;
};

/** A filter to be used against many `DeliveryMethod` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyDeliveryMethodFilter = {
  /** Every related `DeliveryMethod` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<DeliveryMethodFilter>;
  /** Some related `DeliveryMethod` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<DeliveryMethodFilter>;
  /** No related `DeliveryMethod` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<DeliveryMethodFilter>;
  /** Aggregates across related `DeliveryMethod` match the filter criteria. */
  aggregates?: Maybe<DeliveryMethodAggregatesFilter>;
};

export enum GroupType {
  Dealer = 'DEALER',
  Super = 'SUPER'
}

/** A filter to be used against GroupType fields. All fields are combined with a logical ‘and.’ */
export type GroupTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<GroupType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<GroupType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<GroupType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<GroupType>;
  /** Included in the specified list. */
  in?: Maybe<Array<GroupType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<GroupType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<GroupType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<GroupType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<GroupType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<GroupType>;
};

/** A connection to a list of `User` values, with data from `CompanyUser`. */
export type GroupUsersByCompanyUserGroupIdAndUserIdManyToManyConnection = {
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `CompanyUser`, and the cursor to aid in pagination. */
  edges: Array<GroupUsersByCompanyUserGroupIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
};


/** A connection to a list of `User` values, with data from `CompanyUser`. */
export type GroupUsersByCompanyUserGroupIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having?: Maybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `CompanyUser`. */
export type GroupUsersByCompanyUserGroupIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsers: CompanyUsersConnection;
};


/** A `User` edge in the connection, with data from `CompanyUser`. */
export type GroupUsersByCompanyUserGroupIdAndUserIdManyToManyEdgeCompanyUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyUsersOrderBy>>;
  condition?: Maybe<CompanyUserCondition>;
  filter?: Maybe<CompanyUserFilter>;
};

export type GroupVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type GroupVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Widget` values, with data from `CompanyWidget`. */
export type GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdManyToManyConnection = {
  /** A list of `Widget` objects. */
  nodes: Array<Widget>;
  /** A list of edges which contains the `Widget`, info from the `CompanyWidget`, and the cursor to aid in pagination. */
  edges: Array<GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Widget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WidgetAggregates>>;
};


/** A connection to a list of `Widget` values, with data from `CompanyWidget`. */
export type GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<WidgetsGroupBy>;
  having?: Maybe<WidgetsHavingInput>;
};

/** A `Widget` edge in the connection, with data from `CompanyWidget`. */
export type GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Widget` at the end of the edge. */
  node: Widget;
  specOverride?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `Group` values. */
export type GroupsConnection = {
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group` and cursor to aid in pagination. */
  edges: Array<GroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GroupAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GroupAggregates>>;
};


/** A connection to a list of `Group` values. */
export type GroupsConnectionGroupedAggregatesArgs = {
  groupBy: Array<GroupGroupBy>;
  having?: Maybe<GroupHavingInput>;
};

/** A `Group` edge in the connection. */
export type GroupsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
};

/** Methods to use when ordering `Group`. */
export enum GroupsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CognitoNameAsc = 'COGNITO_NAME_ASC',
  CognitoNameDesc = 'COGNITO_NAME_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  DistributionTypeAsc = 'DISTRIBUTION_TYPE_ASC',
  DistributionTypeDesc = 'DISTRIBUTION_TYPE_DESC',
  LegalDescAsc = 'LEGAL_DESC_ASC',
  LegalDescDesc = 'LEGAL_DESC_DESC',
  CognitoGroupAsc = 'COGNITO_GROUP_ASC',
  CognitoGroupDesc = 'COGNITO_GROUP_DESC',
  CognitoAdminGroupAsc = 'COGNITO_ADMIN_GROUP_ASC',
  CognitoAdminGroupDesc = 'COGNITO_ADMIN_GROUP_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyWidgetsCountAsc = 'COMPANY_WIDGETS_COUNT_ASC',
  CompanyWidgetsCountDesc = 'COMPANY_WIDGETS_COUNT_DESC',
  CompanyWidgetsSumGroupIdAsc = 'COMPANY_WIDGETS_SUM_GROUP_ID_ASC',
  CompanyWidgetsSumGroupIdDesc = 'COMPANY_WIDGETS_SUM_GROUP_ID_DESC',
  CompanyWidgetsSumWidgetIdAsc = 'COMPANY_WIDGETS_SUM_WIDGET_ID_ASC',
  CompanyWidgetsSumWidgetIdDesc = 'COMPANY_WIDGETS_SUM_WIDGET_ID_DESC',
  CompanyWidgetsSumSpecOverrideAsc = 'COMPANY_WIDGETS_SUM_SPEC_OVERRIDE_ASC',
  CompanyWidgetsSumSpecOverrideDesc = 'COMPANY_WIDGETS_SUM_SPEC_OVERRIDE_DESC',
  CompanyWidgetsSumCreatedAtAsc = 'COMPANY_WIDGETS_SUM_CREATED_AT_ASC',
  CompanyWidgetsSumCreatedAtDesc = 'COMPANY_WIDGETS_SUM_CREATED_AT_DESC',
  CompanyWidgetsSumUpdatedAtAsc = 'COMPANY_WIDGETS_SUM_UPDATED_AT_ASC',
  CompanyWidgetsSumUpdatedAtDesc = 'COMPANY_WIDGETS_SUM_UPDATED_AT_DESC',
  CompanyWidgetsDistinctCountGroupIdAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyWidgetsDistinctCountGroupIdDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyWidgetsDistinctCountWidgetIdAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CompanyWidgetsDistinctCountWidgetIdDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CompanyWidgetsDistinctCountSpecOverrideAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_SPEC_OVERRIDE_ASC',
  CompanyWidgetsDistinctCountSpecOverrideDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_SPEC_OVERRIDE_DESC',
  CompanyWidgetsDistinctCountCreatedAtAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyWidgetsDistinctCountCreatedAtDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyWidgetsDistinctCountUpdatedAtAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyWidgetsDistinctCountUpdatedAtDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyWidgetsMinGroupIdAsc = 'COMPANY_WIDGETS_MIN_GROUP_ID_ASC',
  CompanyWidgetsMinGroupIdDesc = 'COMPANY_WIDGETS_MIN_GROUP_ID_DESC',
  CompanyWidgetsMinWidgetIdAsc = 'COMPANY_WIDGETS_MIN_WIDGET_ID_ASC',
  CompanyWidgetsMinWidgetIdDesc = 'COMPANY_WIDGETS_MIN_WIDGET_ID_DESC',
  CompanyWidgetsMinSpecOverrideAsc = 'COMPANY_WIDGETS_MIN_SPEC_OVERRIDE_ASC',
  CompanyWidgetsMinSpecOverrideDesc = 'COMPANY_WIDGETS_MIN_SPEC_OVERRIDE_DESC',
  CompanyWidgetsMinCreatedAtAsc = 'COMPANY_WIDGETS_MIN_CREATED_AT_ASC',
  CompanyWidgetsMinCreatedAtDesc = 'COMPANY_WIDGETS_MIN_CREATED_AT_DESC',
  CompanyWidgetsMinUpdatedAtAsc = 'COMPANY_WIDGETS_MIN_UPDATED_AT_ASC',
  CompanyWidgetsMinUpdatedAtDesc = 'COMPANY_WIDGETS_MIN_UPDATED_AT_DESC',
  CompanyWidgetsMaxGroupIdAsc = 'COMPANY_WIDGETS_MAX_GROUP_ID_ASC',
  CompanyWidgetsMaxGroupIdDesc = 'COMPANY_WIDGETS_MAX_GROUP_ID_DESC',
  CompanyWidgetsMaxWidgetIdAsc = 'COMPANY_WIDGETS_MAX_WIDGET_ID_ASC',
  CompanyWidgetsMaxWidgetIdDesc = 'COMPANY_WIDGETS_MAX_WIDGET_ID_DESC',
  CompanyWidgetsMaxSpecOverrideAsc = 'COMPANY_WIDGETS_MAX_SPEC_OVERRIDE_ASC',
  CompanyWidgetsMaxSpecOverrideDesc = 'COMPANY_WIDGETS_MAX_SPEC_OVERRIDE_DESC',
  CompanyWidgetsMaxCreatedAtAsc = 'COMPANY_WIDGETS_MAX_CREATED_AT_ASC',
  CompanyWidgetsMaxCreatedAtDesc = 'COMPANY_WIDGETS_MAX_CREATED_AT_DESC',
  CompanyWidgetsMaxUpdatedAtAsc = 'COMPANY_WIDGETS_MAX_UPDATED_AT_ASC',
  CompanyWidgetsMaxUpdatedAtDesc = 'COMPANY_WIDGETS_MAX_UPDATED_AT_DESC',
  CompanyWidgetsAverageGroupIdAsc = 'COMPANY_WIDGETS_AVERAGE_GROUP_ID_ASC',
  CompanyWidgetsAverageGroupIdDesc = 'COMPANY_WIDGETS_AVERAGE_GROUP_ID_DESC',
  CompanyWidgetsAverageWidgetIdAsc = 'COMPANY_WIDGETS_AVERAGE_WIDGET_ID_ASC',
  CompanyWidgetsAverageWidgetIdDesc = 'COMPANY_WIDGETS_AVERAGE_WIDGET_ID_DESC',
  CompanyWidgetsAverageSpecOverrideAsc = 'COMPANY_WIDGETS_AVERAGE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsAverageSpecOverrideDesc = 'COMPANY_WIDGETS_AVERAGE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsAverageCreatedAtAsc = 'COMPANY_WIDGETS_AVERAGE_CREATED_AT_ASC',
  CompanyWidgetsAverageCreatedAtDesc = 'COMPANY_WIDGETS_AVERAGE_CREATED_AT_DESC',
  CompanyWidgetsAverageUpdatedAtAsc = 'COMPANY_WIDGETS_AVERAGE_UPDATED_AT_ASC',
  CompanyWidgetsAverageUpdatedAtDesc = 'COMPANY_WIDGETS_AVERAGE_UPDATED_AT_DESC',
  CompanyWidgetsStddevSampleGroupIdAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyWidgetsStddevSampleGroupIdDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyWidgetsStddevSampleWidgetIdAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CompanyWidgetsStddevSampleWidgetIdDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CompanyWidgetsStddevSampleSpecOverrideAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsStddevSampleSpecOverrideDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsStddevSampleCreatedAtAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyWidgetsStddevSampleCreatedAtDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyWidgetsStddevSampleUpdatedAtAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyWidgetsStddevSampleUpdatedAtDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyWidgetsStddevPopulationGroupIdAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyWidgetsStddevPopulationGroupIdDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyWidgetsStddevPopulationWidgetIdAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CompanyWidgetsStddevPopulationWidgetIdDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CompanyWidgetsStddevPopulationSpecOverrideAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_SPEC_OVERRIDE_ASC',
  CompanyWidgetsStddevPopulationSpecOverrideDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_SPEC_OVERRIDE_DESC',
  CompanyWidgetsStddevPopulationCreatedAtAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyWidgetsStddevPopulationCreatedAtDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyWidgetsStddevPopulationUpdatedAtAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyWidgetsStddevPopulationUpdatedAtDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyWidgetsVarianceSampleGroupIdAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyWidgetsVarianceSampleGroupIdDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyWidgetsVarianceSampleWidgetIdAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CompanyWidgetsVarianceSampleWidgetIdDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CompanyWidgetsVarianceSampleSpecOverrideAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsVarianceSampleSpecOverrideDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsVarianceSampleCreatedAtAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyWidgetsVarianceSampleCreatedAtDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyWidgetsVarianceSampleUpdatedAtAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyWidgetsVarianceSampleUpdatedAtDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyWidgetsVariancePopulationGroupIdAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyWidgetsVariancePopulationGroupIdDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyWidgetsVariancePopulationWidgetIdAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CompanyWidgetsVariancePopulationWidgetIdDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CompanyWidgetsVariancePopulationSpecOverrideAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_SPEC_OVERRIDE_ASC',
  CompanyWidgetsVariancePopulationSpecOverrideDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_SPEC_OVERRIDE_DESC',
  CompanyWidgetsVariancePopulationCreatedAtAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyWidgetsVariancePopulationCreatedAtDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyWidgetsVariancePopulationUpdatedAtAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyWidgetsVariancePopulationUpdatedAtDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyGroupsCountAsc = 'COMPANY_GROUPS_COUNT_ASC',
  CompanyGroupsCountDesc = 'COMPANY_GROUPS_COUNT_DESC',
  CompanyGroupsSumCompanyIdAsc = 'COMPANY_GROUPS_SUM_COMPANY_ID_ASC',
  CompanyGroupsSumCompanyIdDesc = 'COMPANY_GROUPS_SUM_COMPANY_ID_DESC',
  CompanyGroupsSumGroupIdAsc = 'COMPANY_GROUPS_SUM_GROUP_ID_ASC',
  CompanyGroupsSumGroupIdDesc = 'COMPANY_GROUPS_SUM_GROUP_ID_DESC',
  CompanyGroupsSumCreatedAtAsc = 'COMPANY_GROUPS_SUM_CREATED_AT_ASC',
  CompanyGroupsSumCreatedAtDesc = 'COMPANY_GROUPS_SUM_CREATED_AT_DESC',
  CompanyGroupsSumUpdatedAtAsc = 'COMPANY_GROUPS_SUM_UPDATED_AT_ASC',
  CompanyGroupsSumUpdatedAtDesc = 'COMPANY_GROUPS_SUM_UPDATED_AT_DESC',
  CompanyGroupsDistinctCountCompanyIdAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyGroupsDistinctCountCompanyIdDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyGroupsDistinctCountGroupIdAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyGroupsDistinctCountGroupIdDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyGroupsDistinctCountCreatedAtAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyGroupsDistinctCountCreatedAtDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyGroupsDistinctCountUpdatedAtAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyGroupsDistinctCountUpdatedAtDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyGroupsMinCompanyIdAsc = 'COMPANY_GROUPS_MIN_COMPANY_ID_ASC',
  CompanyGroupsMinCompanyIdDesc = 'COMPANY_GROUPS_MIN_COMPANY_ID_DESC',
  CompanyGroupsMinGroupIdAsc = 'COMPANY_GROUPS_MIN_GROUP_ID_ASC',
  CompanyGroupsMinGroupIdDesc = 'COMPANY_GROUPS_MIN_GROUP_ID_DESC',
  CompanyGroupsMinCreatedAtAsc = 'COMPANY_GROUPS_MIN_CREATED_AT_ASC',
  CompanyGroupsMinCreatedAtDesc = 'COMPANY_GROUPS_MIN_CREATED_AT_DESC',
  CompanyGroupsMinUpdatedAtAsc = 'COMPANY_GROUPS_MIN_UPDATED_AT_ASC',
  CompanyGroupsMinUpdatedAtDesc = 'COMPANY_GROUPS_MIN_UPDATED_AT_DESC',
  CompanyGroupsMaxCompanyIdAsc = 'COMPANY_GROUPS_MAX_COMPANY_ID_ASC',
  CompanyGroupsMaxCompanyIdDesc = 'COMPANY_GROUPS_MAX_COMPANY_ID_DESC',
  CompanyGroupsMaxGroupIdAsc = 'COMPANY_GROUPS_MAX_GROUP_ID_ASC',
  CompanyGroupsMaxGroupIdDesc = 'COMPANY_GROUPS_MAX_GROUP_ID_DESC',
  CompanyGroupsMaxCreatedAtAsc = 'COMPANY_GROUPS_MAX_CREATED_AT_ASC',
  CompanyGroupsMaxCreatedAtDesc = 'COMPANY_GROUPS_MAX_CREATED_AT_DESC',
  CompanyGroupsMaxUpdatedAtAsc = 'COMPANY_GROUPS_MAX_UPDATED_AT_ASC',
  CompanyGroupsMaxUpdatedAtDesc = 'COMPANY_GROUPS_MAX_UPDATED_AT_DESC',
  CompanyGroupsAverageCompanyIdAsc = 'COMPANY_GROUPS_AVERAGE_COMPANY_ID_ASC',
  CompanyGroupsAverageCompanyIdDesc = 'COMPANY_GROUPS_AVERAGE_COMPANY_ID_DESC',
  CompanyGroupsAverageGroupIdAsc = 'COMPANY_GROUPS_AVERAGE_GROUP_ID_ASC',
  CompanyGroupsAverageGroupIdDesc = 'COMPANY_GROUPS_AVERAGE_GROUP_ID_DESC',
  CompanyGroupsAverageCreatedAtAsc = 'COMPANY_GROUPS_AVERAGE_CREATED_AT_ASC',
  CompanyGroupsAverageCreatedAtDesc = 'COMPANY_GROUPS_AVERAGE_CREATED_AT_DESC',
  CompanyGroupsAverageUpdatedAtAsc = 'COMPANY_GROUPS_AVERAGE_UPDATED_AT_ASC',
  CompanyGroupsAverageUpdatedAtDesc = 'COMPANY_GROUPS_AVERAGE_UPDATED_AT_DESC',
  CompanyGroupsStddevSampleCompanyIdAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyGroupsStddevSampleCompanyIdDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyGroupsStddevSampleGroupIdAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyGroupsStddevSampleGroupIdDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyGroupsStddevSampleCreatedAtAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyGroupsStddevSampleCreatedAtDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyGroupsStddevSampleUpdatedAtAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyGroupsStddevSampleUpdatedAtDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyGroupsStddevPopulationCompanyIdAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyGroupsStddevPopulationCompanyIdDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyGroupsStddevPopulationGroupIdAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyGroupsStddevPopulationGroupIdDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyGroupsStddevPopulationCreatedAtAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyGroupsStddevPopulationCreatedAtDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyGroupsStddevPopulationUpdatedAtAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyGroupsStddevPopulationUpdatedAtDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyGroupsVarianceSampleCompanyIdAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyGroupsVarianceSampleCompanyIdDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyGroupsVarianceSampleGroupIdAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyGroupsVarianceSampleGroupIdDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyGroupsVarianceSampleCreatedAtAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyGroupsVarianceSampleCreatedAtDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyGroupsVarianceSampleUpdatedAtAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyGroupsVarianceSampleUpdatedAtDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyGroupsVariancePopulationCompanyIdAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyGroupsVariancePopulationCompanyIdDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyGroupsVariancePopulationGroupIdAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyGroupsVariancePopulationGroupIdDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyGroupsVariancePopulationCreatedAtAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyGroupsVariancePopulationCreatedAtDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyGroupsVariancePopulationUpdatedAtAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyGroupsVariancePopulationUpdatedAtDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CtaAssetsCountAsc = 'CTA_ASSETS_COUNT_ASC',
  CtaAssetsCountDesc = 'CTA_ASSETS_COUNT_DESC',
  CtaAssetsSumIdAsc = 'CTA_ASSETS_SUM_ID_ASC',
  CtaAssetsSumIdDesc = 'CTA_ASSETS_SUM_ID_DESC',
  CtaAssetsSumGroupIdAsc = 'CTA_ASSETS_SUM_GROUP_ID_ASC',
  CtaAssetsSumGroupIdDesc = 'CTA_ASSETS_SUM_GROUP_ID_DESC',
  CtaAssetsSumNameAsc = 'CTA_ASSETS_SUM_NAME_ASC',
  CtaAssetsSumNameDesc = 'CTA_ASSETS_SUM_NAME_DESC',
  CtaAssetsSumBucketAsc = 'CTA_ASSETS_SUM_BUCKET_ASC',
  CtaAssetsSumBucketDesc = 'CTA_ASSETS_SUM_BUCKET_DESC',
  CtaAssetsSumKeyAsc = 'CTA_ASSETS_SUM_KEY_ASC',
  CtaAssetsSumKeyDesc = 'CTA_ASSETS_SUM_KEY_DESC',
  CtaAssetsSumDescriptionAsc = 'CTA_ASSETS_SUM_DESCRIPTION_ASC',
  CtaAssetsSumDescriptionDesc = 'CTA_ASSETS_SUM_DESCRIPTION_DESC',
  CtaAssetsSumEmailAsc = 'CTA_ASSETS_SUM_EMAIL_ASC',
  CtaAssetsSumEmailDesc = 'CTA_ASSETS_SUM_EMAIL_DESC',
  CtaAssetsSumIpAddressAsc = 'CTA_ASSETS_SUM_IP_ADDRESS_ASC',
  CtaAssetsSumIpAddressDesc = 'CTA_ASSETS_SUM_IP_ADDRESS_DESC',
  CtaAssetsSumCreatedAtAsc = 'CTA_ASSETS_SUM_CREATED_AT_ASC',
  CtaAssetsSumCreatedAtDesc = 'CTA_ASSETS_SUM_CREATED_AT_DESC',
  CtaAssetsSumUpdatedAtAsc = 'CTA_ASSETS_SUM_UPDATED_AT_ASC',
  CtaAssetsSumUpdatedAtDesc = 'CTA_ASSETS_SUM_UPDATED_AT_DESC',
  CtaAssetsDistinctCountIdAsc = 'CTA_ASSETS_DISTINCT_COUNT_ID_ASC',
  CtaAssetsDistinctCountIdDesc = 'CTA_ASSETS_DISTINCT_COUNT_ID_DESC',
  CtaAssetsDistinctCountGroupIdAsc = 'CTA_ASSETS_DISTINCT_COUNT_GROUP_ID_ASC',
  CtaAssetsDistinctCountGroupIdDesc = 'CTA_ASSETS_DISTINCT_COUNT_GROUP_ID_DESC',
  CtaAssetsDistinctCountNameAsc = 'CTA_ASSETS_DISTINCT_COUNT_NAME_ASC',
  CtaAssetsDistinctCountNameDesc = 'CTA_ASSETS_DISTINCT_COUNT_NAME_DESC',
  CtaAssetsDistinctCountBucketAsc = 'CTA_ASSETS_DISTINCT_COUNT_BUCKET_ASC',
  CtaAssetsDistinctCountBucketDesc = 'CTA_ASSETS_DISTINCT_COUNT_BUCKET_DESC',
  CtaAssetsDistinctCountKeyAsc = 'CTA_ASSETS_DISTINCT_COUNT_KEY_ASC',
  CtaAssetsDistinctCountKeyDesc = 'CTA_ASSETS_DISTINCT_COUNT_KEY_DESC',
  CtaAssetsDistinctCountDescriptionAsc = 'CTA_ASSETS_DISTINCT_COUNT_DESCRIPTION_ASC',
  CtaAssetsDistinctCountDescriptionDesc = 'CTA_ASSETS_DISTINCT_COUNT_DESCRIPTION_DESC',
  CtaAssetsDistinctCountEmailAsc = 'CTA_ASSETS_DISTINCT_COUNT_EMAIL_ASC',
  CtaAssetsDistinctCountEmailDesc = 'CTA_ASSETS_DISTINCT_COUNT_EMAIL_DESC',
  CtaAssetsDistinctCountIpAddressAsc = 'CTA_ASSETS_DISTINCT_COUNT_IP_ADDRESS_ASC',
  CtaAssetsDistinctCountIpAddressDesc = 'CTA_ASSETS_DISTINCT_COUNT_IP_ADDRESS_DESC',
  CtaAssetsDistinctCountCreatedAtAsc = 'CTA_ASSETS_DISTINCT_COUNT_CREATED_AT_ASC',
  CtaAssetsDistinctCountCreatedAtDesc = 'CTA_ASSETS_DISTINCT_COUNT_CREATED_AT_DESC',
  CtaAssetsDistinctCountUpdatedAtAsc = 'CTA_ASSETS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CtaAssetsDistinctCountUpdatedAtDesc = 'CTA_ASSETS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CtaAssetsMinIdAsc = 'CTA_ASSETS_MIN_ID_ASC',
  CtaAssetsMinIdDesc = 'CTA_ASSETS_MIN_ID_DESC',
  CtaAssetsMinGroupIdAsc = 'CTA_ASSETS_MIN_GROUP_ID_ASC',
  CtaAssetsMinGroupIdDesc = 'CTA_ASSETS_MIN_GROUP_ID_DESC',
  CtaAssetsMinNameAsc = 'CTA_ASSETS_MIN_NAME_ASC',
  CtaAssetsMinNameDesc = 'CTA_ASSETS_MIN_NAME_DESC',
  CtaAssetsMinBucketAsc = 'CTA_ASSETS_MIN_BUCKET_ASC',
  CtaAssetsMinBucketDesc = 'CTA_ASSETS_MIN_BUCKET_DESC',
  CtaAssetsMinKeyAsc = 'CTA_ASSETS_MIN_KEY_ASC',
  CtaAssetsMinKeyDesc = 'CTA_ASSETS_MIN_KEY_DESC',
  CtaAssetsMinDescriptionAsc = 'CTA_ASSETS_MIN_DESCRIPTION_ASC',
  CtaAssetsMinDescriptionDesc = 'CTA_ASSETS_MIN_DESCRIPTION_DESC',
  CtaAssetsMinEmailAsc = 'CTA_ASSETS_MIN_EMAIL_ASC',
  CtaAssetsMinEmailDesc = 'CTA_ASSETS_MIN_EMAIL_DESC',
  CtaAssetsMinIpAddressAsc = 'CTA_ASSETS_MIN_IP_ADDRESS_ASC',
  CtaAssetsMinIpAddressDesc = 'CTA_ASSETS_MIN_IP_ADDRESS_DESC',
  CtaAssetsMinCreatedAtAsc = 'CTA_ASSETS_MIN_CREATED_AT_ASC',
  CtaAssetsMinCreatedAtDesc = 'CTA_ASSETS_MIN_CREATED_AT_DESC',
  CtaAssetsMinUpdatedAtAsc = 'CTA_ASSETS_MIN_UPDATED_AT_ASC',
  CtaAssetsMinUpdatedAtDesc = 'CTA_ASSETS_MIN_UPDATED_AT_DESC',
  CtaAssetsMaxIdAsc = 'CTA_ASSETS_MAX_ID_ASC',
  CtaAssetsMaxIdDesc = 'CTA_ASSETS_MAX_ID_DESC',
  CtaAssetsMaxGroupIdAsc = 'CTA_ASSETS_MAX_GROUP_ID_ASC',
  CtaAssetsMaxGroupIdDesc = 'CTA_ASSETS_MAX_GROUP_ID_DESC',
  CtaAssetsMaxNameAsc = 'CTA_ASSETS_MAX_NAME_ASC',
  CtaAssetsMaxNameDesc = 'CTA_ASSETS_MAX_NAME_DESC',
  CtaAssetsMaxBucketAsc = 'CTA_ASSETS_MAX_BUCKET_ASC',
  CtaAssetsMaxBucketDesc = 'CTA_ASSETS_MAX_BUCKET_DESC',
  CtaAssetsMaxKeyAsc = 'CTA_ASSETS_MAX_KEY_ASC',
  CtaAssetsMaxKeyDesc = 'CTA_ASSETS_MAX_KEY_DESC',
  CtaAssetsMaxDescriptionAsc = 'CTA_ASSETS_MAX_DESCRIPTION_ASC',
  CtaAssetsMaxDescriptionDesc = 'CTA_ASSETS_MAX_DESCRIPTION_DESC',
  CtaAssetsMaxEmailAsc = 'CTA_ASSETS_MAX_EMAIL_ASC',
  CtaAssetsMaxEmailDesc = 'CTA_ASSETS_MAX_EMAIL_DESC',
  CtaAssetsMaxIpAddressAsc = 'CTA_ASSETS_MAX_IP_ADDRESS_ASC',
  CtaAssetsMaxIpAddressDesc = 'CTA_ASSETS_MAX_IP_ADDRESS_DESC',
  CtaAssetsMaxCreatedAtAsc = 'CTA_ASSETS_MAX_CREATED_AT_ASC',
  CtaAssetsMaxCreatedAtDesc = 'CTA_ASSETS_MAX_CREATED_AT_DESC',
  CtaAssetsMaxUpdatedAtAsc = 'CTA_ASSETS_MAX_UPDATED_AT_ASC',
  CtaAssetsMaxUpdatedAtDesc = 'CTA_ASSETS_MAX_UPDATED_AT_DESC',
  CtaAssetsAverageIdAsc = 'CTA_ASSETS_AVERAGE_ID_ASC',
  CtaAssetsAverageIdDesc = 'CTA_ASSETS_AVERAGE_ID_DESC',
  CtaAssetsAverageGroupIdAsc = 'CTA_ASSETS_AVERAGE_GROUP_ID_ASC',
  CtaAssetsAverageGroupIdDesc = 'CTA_ASSETS_AVERAGE_GROUP_ID_DESC',
  CtaAssetsAverageNameAsc = 'CTA_ASSETS_AVERAGE_NAME_ASC',
  CtaAssetsAverageNameDesc = 'CTA_ASSETS_AVERAGE_NAME_DESC',
  CtaAssetsAverageBucketAsc = 'CTA_ASSETS_AVERAGE_BUCKET_ASC',
  CtaAssetsAverageBucketDesc = 'CTA_ASSETS_AVERAGE_BUCKET_DESC',
  CtaAssetsAverageKeyAsc = 'CTA_ASSETS_AVERAGE_KEY_ASC',
  CtaAssetsAverageKeyDesc = 'CTA_ASSETS_AVERAGE_KEY_DESC',
  CtaAssetsAverageDescriptionAsc = 'CTA_ASSETS_AVERAGE_DESCRIPTION_ASC',
  CtaAssetsAverageDescriptionDesc = 'CTA_ASSETS_AVERAGE_DESCRIPTION_DESC',
  CtaAssetsAverageEmailAsc = 'CTA_ASSETS_AVERAGE_EMAIL_ASC',
  CtaAssetsAverageEmailDesc = 'CTA_ASSETS_AVERAGE_EMAIL_DESC',
  CtaAssetsAverageIpAddressAsc = 'CTA_ASSETS_AVERAGE_IP_ADDRESS_ASC',
  CtaAssetsAverageIpAddressDesc = 'CTA_ASSETS_AVERAGE_IP_ADDRESS_DESC',
  CtaAssetsAverageCreatedAtAsc = 'CTA_ASSETS_AVERAGE_CREATED_AT_ASC',
  CtaAssetsAverageCreatedAtDesc = 'CTA_ASSETS_AVERAGE_CREATED_AT_DESC',
  CtaAssetsAverageUpdatedAtAsc = 'CTA_ASSETS_AVERAGE_UPDATED_AT_ASC',
  CtaAssetsAverageUpdatedAtDesc = 'CTA_ASSETS_AVERAGE_UPDATED_AT_DESC',
  CtaAssetsStddevSampleIdAsc = 'CTA_ASSETS_STDDEV_SAMPLE_ID_ASC',
  CtaAssetsStddevSampleIdDesc = 'CTA_ASSETS_STDDEV_SAMPLE_ID_DESC',
  CtaAssetsStddevSampleGroupIdAsc = 'CTA_ASSETS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CtaAssetsStddevSampleGroupIdDesc = 'CTA_ASSETS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CtaAssetsStddevSampleNameAsc = 'CTA_ASSETS_STDDEV_SAMPLE_NAME_ASC',
  CtaAssetsStddevSampleNameDesc = 'CTA_ASSETS_STDDEV_SAMPLE_NAME_DESC',
  CtaAssetsStddevSampleBucketAsc = 'CTA_ASSETS_STDDEV_SAMPLE_BUCKET_ASC',
  CtaAssetsStddevSampleBucketDesc = 'CTA_ASSETS_STDDEV_SAMPLE_BUCKET_DESC',
  CtaAssetsStddevSampleKeyAsc = 'CTA_ASSETS_STDDEV_SAMPLE_KEY_ASC',
  CtaAssetsStddevSampleKeyDesc = 'CTA_ASSETS_STDDEV_SAMPLE_KEY_DESC',
  CtaAssetsStddevSampleDescriptionAsc = 'CTA_ASSETS_STDDEV_SAMPLE_DESCRIPTION_ASC',
  CtaAssetsStddevSampleDescriptionDesc = 'CTA_ASSETS_STDDEV_SAMPLE_DESCRIPTION_DESC',
  CtaAssetsStddevSampleEmailAsc = 'CTA_ASSETS_STDDEV_SAMPLE_EMAIL_ASC',
  CtaAssetsStddevSampleEmailDesc = 'CTA_ASSETS_STDDEV_SAMPLE_EMAIL_DESC',
  CtaAssetsStddevSampleIpAddressAsc = 'CTA_ASSETS_STDDEV_SAMPLE_IP_ADDRESS_ASC',
  CtaAssetsStddevSampleIpAddressDesc = 'CTA_ASSETS_STDDEV_SAMPLE_IP_ADDRESS_DESC',
  CtaAssetsStddevSampleCreatedAtAsc = 'CTA_ASSETS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CtaAssetsStddevSampleCreatedAtDesc = 'CTA_ASSETS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CtaAssetsStddevSampleUpdatedAtAsc = 'CTA_ASSETS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CtaAssetsStddevSampleUpdatedAtDesc = 'CTA_ASSETS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CtaAssetsStddevPopulationIdAsc = 'CTA_ASSETS_STDDEV_POPULATION_ID_ASC',
  CtaAssetsStddevPopulationIdDesc = 'CTA_ASSETS_STDDEV_POPULATION_ID_DESC',
  CtaAssetsStddevPopulationGroupIdAsc = 'CTA_ASSETS_STDDEV_POPULATION_GROUP_ID_ASC',
  CtaAssetsStddevPopulationGroupIdDesc = 'CTA_ASSETS_STDDEV_POPULATION_GROUP_ID_DESC',
  CtaAssetsStddevPopulationNameAsc = 'CTA_ASSETS_STDDEV_POPULATION_NAME_ASC',
  CtaAssetsStddevPopulationNameDesc = 'CTA_ASSETS_STDDEV_POPULATION_NAME_DESC',
  CtaAssetsStddevPopulationBucketAsc = 'CTA_ASSETS_STDDEV_POPULATION_BUCKET_ASC',
  CtaAssetsStddevPopulationBucketDesc = 'CTA_ASSETS_STDDEV_POPULATION_BUCKET_DESC',
  CtaAssetsStddevPopulationKeyAsc = 'CTA_ASSETS_STDDEV_POPULATION_KEY_ASC',
  CtaAssetsStddevPopulationKeyDesc = 'CTA_ASSETS_STDDEV_POPULATION_KEY_DESC',
  CtaAssetsStddevPopulationDescriptionAsc = 'CTA_ASSETS_STDDEV_POPULATION_DESCRIPTION_ASC',
  CtaAssetsStddevPopulationDescriptionDesc = 'CTA_ASSETS_STDDEV_POPULATION_DESCRIPTION_DESC',
  CtaAssetsStddevPopulationEmailAsc = 'CTA_ASSETS_STDDEV_POPULATION_EMAIL_ASC',
  CtaAssetsStddevPopulationEmailDesc = 'CTA_ASSETS_STDDEV_POPULATION_EMAIL_DESC',
  CtaAssetsStddevPopulationIpAddressAsc = 'CTA_ASSETS_STDDEV_POPULATION_IP_ADDRESS_ASC',
  CtaAssetsStddevPopulationIpAddressDesc = 'CTA_ASSETS_STDDEV_POPULATION_IP_ADDRESS_DESC',
  CtaAssetsStddevPopulationCreatedAtAsc = 'CTA_ASSETS_STDDEV_POPULATION_CREATED_AT_ASC',
  CtaAssetsStddevPopulationCreatedAtDesc = 'CTA_ASSETS_STDDEV_POPULATION_CREATED_AT_DESC',
  CtaAssetsStddevPopulationUpdatedAtAsc = 'CTA_ASSETS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CtaAssetsStddevPopulationUpdatedAtDesc = 'CTA_ASSETS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CtaAssetsVarianceSampleIdAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_ID_ASC',
  CtaAssetsVarianceSampleIdDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_ID_DESC',
  CtaAssetsVarianceSampleGroupIdAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CtaAssetsVarianceSampleGroupIdDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CtaAssetsVarianceSampleNameAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_NAME_ASC',
  CtaAssetsVarianceSampleNameDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_NAME_DESC',
  CtaAssetsVarianceSampleBucketAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_BUCKET_ASC',
  CtaAssetsVarianceSampleBucketDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_BUCKET_DESC',
  CtaAssetsVarianceSampleKeyAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_KEY_ASC',
  CtaAssetsVarianceSampleKeyDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_KEY_DESC',
  CtaAssetsVarianceSampleDescriptionAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  CtaAssetsVarianceSampleDescriptionDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CtaAssetsVarianceSampleEmailAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_EMAIL_ASC',
  CtaAssetsVarianceSampleEmailDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_EMAIL_DESC',
  CtaAssetsVarianceSampleIpAddressAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_IP_ADDRESS_ASC',
  CtaAssetsVarianceSampleIpAddressDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_IP_ADDRESS_DESC',
  CtaAssetsVarianceSampleCreatedAtAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CtaAssetsVarianceSampleCreatedAtDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CtaAssetsVarianceSampleUpdatedAtAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CtaAssetsVarianceSampleUpdatedAtDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CtaAssetsVariancePopulationIdAsc = 'CTA_ASSETS_VARIANCE_POPULATION_ID_ASC',
  CtaAssetsVariancePopulationIdDesc = 'CTA_ASSETS_VARIANCE_POPULATION_ID_DESC',
  CtaAssetsVariancePopulationGroupIdAsc = 'CTA_ASSETS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CtaAssetsVariancePopulationGroupIdDesc = 'CTA_ASSETS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CtaAssetsVariancePopulationNameAsc = 'CTA_ASSETS_VARIANCE_POPULATION_NAME_ASC',
  CtaAssetsVariancePopulationNameDesc = 'CTA_ASSETS_VARIANCE_POPULATION_NAME_DESC',
  CtaAssetsVariancePopulationBucketAsc = 'CTA_ASSETS_VARIANCE_POPULATION_BUCKET_ASC',
  CtaAssetsVariancePopulationBucketDesc = 'CTA_ASSETS_VARIANCE_POPULATION_BUCKET_DESC',
  CtaAssetsVariancePopulationKeyAsc = 'CTA_ASSETS_VARIANCE_POPULATION_KEY_ASC',
  CtaAssetsVariancePopulationKeyDesc = 'CTA_ASSETS_VARIANCE_POPULATION_KEY_DESC',
  CtaAssetsVariancePopulationDescriptionAsc = 'CTA_ASSETS_VARIANCE_POPULATION_DESCRIPTION_ASC',
  CtaAssetsVariancePopulationDescriptionDesc = 'CTA_ASSETS_VARIANCE_POPULATION_DESCRIPTION_DESC',
  CtaAssetsVariancePopulationEmailAsc = 'CTA_ASSETS_VARIANCE_POPULATION_EMAIL_ASC',
  CtaAssetsVariancePopulationEmailDesc = 'CTA_ASSETS_VARIANCE_POPULATION_EMAIL_DESC',
  CtaAssetsVariancePopulationIpAddressAsc = 'CTA_ASSETS_VARIANCE_POPULATION_IP_ADDRESS_ASC',
  CtaAssetsVariancePopulationIpAddressDesc = 'CTA_ASSETS_VARIANCE_POPULATION_IP_ADDRESS_DESC',
  CtaAssetsVariancePopulationCreatedAtAsc = 'CTA_ASSETS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CtaAssetsVariancePopulationCreatedAtDesc = 'CTA_ASSETS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CtaAssetsVariancePopulationUpdatedAtAsc = 'CTA_ASSETS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CtaAssetsVariancePopulationUpdatedAtDesc = 'CTA_ASSETS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyCtasCountAsc = 'COMPANY_CTAS_COUNT_ASC',
  CompanyCtasCountDesc = 'COMPANY_CTAS_COUNT_DESC',
  CompanyCtasSumIdAsc = 'COMPANY_CTAS_SUM_ID_ASC',
  CompanyCtasSumIdDesc = 'COMPANY_CTAS_SUM_ID_DESC',
  CompanyCtasSumCtaIdAsc = 'COMPANY_CTAS_SUM_CTA_ID_ASC',
  CompanyCtasSumCtaIdDesc = 'COMPANY_CTAS_SUM_CTA_ID_DESC',
  CompanyCtasSumGroupIdAsc = 'COMPANY_CTAS_SUM_GROUP_ID_ASC',
  CompanyCtasSumGroupIdDesc = 'COMPANY_CTAS_SUM_GROUP_ID_DESC',
  CompanyCtasSumThemeAsc = 'COMPANY_CTAS_SUM_THEME_ASC',
  CompanyCtasSumThemeDesc = 'COMPANY_CTAS_SUM_THEME_DESC',
  CompanyCtasSumNameAsc = 'COMPANY_CTAS_SUM_NAME_ASC',
  CompanyCtasSumNameDesc = 'COMPANY_CTAS_SUM_NAME_DESC',
  CompanyCtasSumLockedAsc = 'COMPANY_CTAS_SUM_LOCKED_ASC',
  CompanyCtasSumLockedDesc = 'COMPANY_CTAS_SUM_LOCKED_DESC',
  CompanyCtasSumDefaultAsc = 'COMPANY_CTAS_SUM_DEFAULT_ASC',
  CompanyCtasSumDefaultDesc = 'COMPANY_CTAS_SUM_DEFAULT_DESC',
  CompanyCtasSumTemplateDefAsc = 'COMPANY_CTAS_SUM_TEMPLATE_DEF_ASC',
  CompanyCtasSumTemplateDefDesc = 'COMPANY_CTAS_SUM_TEMPLATE_DEF_DESC',
  CompanyCtasSumThemeDefAsc = 'COMPANY_CTAS_SUM_THEME_DEF_ASC',
  CompanyCtasSumThemeDefDesc = 'COMPANY_CTAS_SUM_THEME_DEF_DESC',
  CompanyCtasSumCreatedAtAsc = 'COMPANY_CTAS_SUM_CREATED_AT_ASC',
  CompanyCtasSumCreatedAtDesc = 'COMPANY_CTAS_SUM_CREATED_AT_DESC',
  CompanyCtasSumUpdatedAtAsc = 'COMPANY_CTAS_SUM_UPDATED_AT_ASC',
  CompanyCtasSumUpdatedAtDesc = 'COMPANY_CTAS_SUM_UPDATED_AT_DESC',
  CompanyCtasSumCompatibleMajorAsc = 'COMPANY_CTAS_SUM_COMPATIBLE_MAJOR_ASC',
  CompanyCtasSumCompatibleMajorDesc = 'COMPANY_CTAS_SUM_COMPATIBLE_MAJOR_DESC',
  CompanyCtasSumCtaDetailsAsc = 'COMPANY_CTAS_SUM_CTA_DETAILS_ASC',
  CompanyCtasSumCtaDetailsDesc = 'COMPANY_CTAS_SUM_CTA_DETAILS_DESC',
  CompanyCtasDistinctCountIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_ID_ASC',
  CompanyCtasDistinctCountIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_ID_DESC',
  CompanyCtasDistinctCountCtaIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_ID_ASC',
  CompanyCtasDistinctCountCtaIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_ID_DESC',
  CompanyCtasDistinctCountGroupIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyCtasDistinctCountGroupIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyCtasDistinctCountThemeAsc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_ASC',
  CompanyCtasDistinctCountThemeDesc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DESC',
  CompanyCtasDistinctCountNameAsc = 'COMPANY_CTAS_DISTINCT_COUNT_NAME_ASC',
  CompanyCtasDistinctCountNameDesc = 'COMPANY_CTAS_DISTINCT_COUNT_NAME_DESC',
  CompanyCtasDistinctCountLockedAsc = 'COMPANY_CTAS_DISTINCT_COUNT_LOCKED_ASC',
  CompanyCtasDistinctCountLockedDesc = 'COMPANY_CTAS_DISTINCT_COUNT_LOCKED_DESC',
  CompanyCtasDistinctCountDefaultAsc = 'COMPANY_CTAS_DISTINCT_COUNT_DEFAULT_ASC',
  CompanyCtasDistinctCountDefaultDesc = 'COMPANY_CTAS_DISTINCT_COUNT_DEFAULT_DESC',
  CompanyCtasDistinctCountTemplateDefAsc = 'COMPANY_CTAS_DISTINCT_COUNT_TEMPLATE_DEF_ASC',
  CompanyCtasDistinctCountTemplateDefDesc = 'COMPANY_CTAS_DISTINCT_COUNT_TEMPLATE_DEF_DESC',
  CompanyCtasDistinctCountThemeDefAsc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DEF_ASC',
  CompanyCtasDistinctCountThemeDefDesc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DEF_DESC',
  CompanyCtasDistinctCountCreatedAtAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyCtasDistinctCountCreatedAtDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyCtasDistinctCountUpdatedAtAsc = 'COMPANY_CTAS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyCtasDistinctCountUpdatedAtDesc = 'COMPANY_CTAS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyCtasDistinctCountCompatibleMajorAsc = 'COMPANY_CTAS_DISTINCT_COUNT_COMPATIBLE_MAJOR_ASC',
  CompanyCtasDistinctCountCompatibleMajorDesc = 'COMPANY_CTAS_DISTINCT_COUNT_COMPATIBLE_MAJOR_DESC',
  CompanyCtasDistinctCountCtaDetailsAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_DETAILS_ASC',
  CompanyCtasDistinctCountCtaDetailsDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_DETAILS_DESC',
  CompanyCtasMinIdAsc = 'COMPANY_CTAS_MIN_ID_ASC',
  CompanyCtasMinIdDesc = 'COMPANY_CTAS_MIN_ID_DESC',
  CompanyCtasMinCtaIdAsc = 'COMPANY_CTAS_MIN_CTA_ID_ASC',
  CompanyCtasMinCtaIdDesc = 'COMPANY_CTAS_MIN_CTA_ID_DESC',
  CompanyCtasMinGroupIdAsc = 'COMPANY_CTAS_MIN_GROUP_ID_ASC',
  CompanyCtasMinGroupIdDesc = 'COMPANY_CTAS_MIN_GROUP_ID_DESC',
  CompanyCtasMinThemeAsc = 'COMPANY_CTAS_MIN_THEME_ASC',
  CompanyCtasMinThemeDesc = 'COMPANY_CTAS_MIN_THEME_DESC',
  CompanyCtasMinNameAsc = 'COMPANY_CTAS_MIN_NAME_ASC',
  CompanyCtasMinNameDesc = 'COMPANY_CTAS_MIN_NAME_DESC',
  CompanyCtasMinLockedAsc = 'COMPANY_CTAS_MIN_LOCKED_ASC',
  CompanyCtasMinLockedDesc = 'COMPANY_CTAS_MIN_LOCKED_DESC',
  CompanyCtasMinDefaultAsc = 'COMPANY_CTAS_MIN_DEFAULT_ASC',
  CompanyCtasMinDefaultDesc = 'COMPANY_CTAS_MIN_DEFAULT_DESC',
  CompanyCtasMinTemplateDefAsc = 'COMPANY_CTAS_MIN_TEMPLATE_DEF_ASC',
  CompanyCtasMinTemplateDefDesc = 'COMPANY_CTAS_MIN_TEMPLATE_DEF_DESC',
  CompanyCtasMinThemeDefAsc = 'COMPANY_CTAS_MIN_THEME_DEF_ASC',
  CompanyCtasMinThemeDefDesc = 'COMPANY_CTAS_MIN_THEME_DEF_DESC',
  CompanyCtasMinCreatedAtAsc = 'COMPANY_CTAS_MIN_CREATED_AT_ASC',
  CompanyCtasMinCreatedAtDesc = 'COMPANY_CTAS_MIN_CREATED_AT_DESC',
  CompanyCtasMinUpdatedAtAsc = 'COMPANY_CTAS_MIN_UPDATED_AT_ASC',
  CompanyCtasMinUpdatedAtDesc = 'COMPANY_CTAS_MIN_UPDATED_AT_DESC',
  CompanyCtasMinCompatibleMajorAsc = 'COMPANY_CTAS_MIN_COMPATIBLE_MAJOR_ASC',
  CompanyCtasMinCompatibleMajorDesc = 'COMPANY_CTAS_MIN_COMPATIBLE_MAJOR_DESC',
  CompanyCtasMinCtaDetailsAsc = 'COMPANY_CTAS_MIN_CTA_DETAILS_ASC',
  CompanyCtasMinCtaDetailsDesc = 'COMPANY_CTAS_MIN_CTA_DETAILS_DESC',
  CompanyCtasMaxIdAsc = 'COMPANY_CTAS_MAX_ID_ASC',
  CompanyCtasMaxIdDesc = 'COMPANY_CTAS_MAX_ID_DESC',
  CompanyCtasMaxCtaIdAsc = 'COMPANY_CTAS_MAX_CTA_ID_ASC',
  CompanyCtasMaxCtaIdDesc = 'COMPANY_CTAS_MAX_CTA_ID_DESC',
  CompanyCtasMaxGroupIdAsc = 'COMPANY_CTAS_MAX_GROUP_ID_ASC',
  CompanyCtasMaxGroupIdDesc = 'COMPANY_CTAS_MAX_GROUP_ID_DESC',
  CompanyCtasMaxThemeAsc = 'COMPANY_CTAS_MAX_THEME_ASC',
  CompanyCtasMaxThemeDesc = 'COMPANY_CTAS_MAX_THEME_DESC',
  CompanyCtasMaxNameAsc = 'COMPANY_CTAS_MAX_NAME_ASC',
  CompanyCtasMaxNameDesc = 'COMPANY_CTAS_MAX_NAME_DESC',
  CompanyCtasMaxLockedAsc = 'COMPANY_CTAS_MAX_LOCKED_ASC',
  CompanyCtasMaxLockedDesc = 'COMPANY_CTAS_MAX_LOCKED_DESC',
  CompanyCtasMaxDefaultAsc = 'COMPANY_CTAS_MAX_DEFAULT_ASC',
  CompanyCtasMaxDefaultDesc = 'COMPANY_CTAS_MAX_DEFAULT_DESC',
  CompanyCtasMaxTemplateDefAsc = 'COMPANY_CTAS_MAX_TEMPLATE_DEF_ASC',
  CompanyCtasMaxTemplateDefDesc = 'COMPANY_CTAS_MAX_TEMPLATE_DEF_DESC',
  CompanyCtasMaxThemeDefAsc = 'COMPANY_CTAS_MAX_THEME_DEF_ASC',
  CompanyCtasMaxThemeDefDesc = 'COMPANY_CTAS_MAX_THEME_DEF_DESC',
  CompanyCtasMaxCreatedAtAsc = 'COMPANY_CTAS_MAX_CREATED_AT_ASC',
  CompanyCtasMaxCreatedAtDesc = 'COMPANY_CTAS_MAX_CREATED_AT_DESC',
  CompanyCtasMaxUpdatedAtAsc = 'COMPANY_CTAS_MAX_UPDATED_AT_ASC',
  CompanyCtasMaxUpdatedAtDesc = 'COMPANY_CTAS_MAX_UPDATED_AT_DESC',
  CompanyCtasMaxCompatibleMajorAsc = 'COMPANY_CTAS_MAX_COMPATIBLE_MAJOR_ASC',
  CompanyCtasMaxCompatibleMajorDesc = 'COMPANY_CTAS_MAX_COMPATIBLE_MAJOR_DESC',
  CompanyCtasMaxCtaDetailsAsc = 'COMPANY_CTAS_MAX_CTA_DETAILS_ASC',
  CompanyCtasMaxCtaDetailsDesc = 'COMPANY_CTAS_MAX_CTA_DETAILS_DESC',
  CompanyCtasAverageIdAsc = 'COMPANY_CTAS_AVERAGE_ID_ASC',
  CompanyCtasAverageIdDesc = 'COMPANY_CTAS_AVERAGE_ID_DESC',
  CompanyCtasAverageCtaIdAsc = 'COMPANY_CTAS_AVERAGE_CTA_ID_ASC',
  CompanyCtasAverageCtaIdDesc = 'COMPANY_CTAS_AVERAGE_CTA_ID_DESC',
  CompanyCtasAverageGroupIdAsc = 'COMPANY_CTAS_AVERAGE_GROUP_ID_ASC',
  CompanyCtasAverageGroupIdDesc = 'COMPANY_CTAS_AVERAGE_GROUP_ID_DESC',
  CompanyCtasAverageThemeAsc = 'COMPANY_CTAS_AVERAGE_THEME_ASC',
  CompanyCtasAverageThemeDesc = 'COMPANY_CTAS_AVERAGE_THEME_DESC',
  CompanyCtasAverageNameAsc = 'COMPANY_CTAS_AVERAGE_NAME_ASC',
  CompanyCtasAverageNameDesc = 'COMPANY_CTAS_AVERAGE_NAME_DESC',
  CompanyCtasAverageLockedAsc = 'COMPANY_CTAS_AVERAGE_LOCKED_ASC',
  CompanyCtasAverageLockedDesc = 'COMPANY_CTAS_AVERAGE_LOCKED_DESC',
  CompanyCtasAverageDefaultAsc = 'COMPANY_CTAS_AVERAGE_DEFAULT_ASC',
  CompanyCtasAverageDefaultDesc = 'COMPANY_CTAS_AVERAGE_DEFAULT_DESC',
  CompanyCtasAverageTemplateDefAsc = 'COMPANY_CTAS_AVERAGE_TEMPLATE_DEF_ASC',
  CompanyCtasAverageTemplateDefDesc = 'COMPANY_CTAS_AVERAGE_TEMPLATE_DEF_DESC',
  CompanyCtasAverageThemeDefAsc = 'COMPANY_CTAS_AVERAGE_THEME_DEF_ASC',
  CompanyCtasAverageThemeDefDesc = 'COMPANY_CTAS_AVERAGE_THEME_DEF_DESC',
  CompanyCtasAverageCreatedAtAsc = 'COMPANY_CTAS_AVERAGE_CREATED_AT_ASC',
  CompanyCtasAverageCreatedAtDesc = 'COMPANY_CTAS_AVERAGE_CREATED_AT_DESC',
  CompanyCtasAverageUpdatedAtAsc = 'COMPANY_CTAS_AVERAGE_UPDATED_AT_ASC',
  CompanyCtasAverageUpdatedAtDesc = 'COMPANY_CTAS_AVERAGE_UPDATED_AT_DESC',
  CompanyCtasAverageCompatibleMajorAsc = 'COMPANY_CTAS_AVERAGE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasAverageCompatibleMajorDesc = 'COMPANY_CTAS_AVERAGE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasAverageCtaDetailsAsc = 'COMPANY_CTAS_AVERAGE_CTA_DETAILS_ASC',
  CompanyCtasAverageCtaDetailsDesc = 'COMPANY_CTAS_AVERAGE_CTA_DETAILS_DESC',
  CompanyCtasStddevSampleIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_ID_ASC',
  CompanyCtasStddevSampleIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_ID_DESC',
  CompanyCtasStddevSampleCtaIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_ID_ASC',
  CompanyCtasStddevSampleCtaIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_ID_DESC',
  CompanyCtasStddevSampleGroupIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyCtasStddevSampleGroupIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyCtasStddevSampleThemeAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_ASC',
  CompanyCtasStddevSampleThemeDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DESC',
  CompanyCtasStddevSampleNameAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_NAME_ASC',
  CompanyCtasStddevSampleNameDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_NAME_DESC',
  CompanyCtasStddevSampleLockedAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_LOCKED_ASC',
  CompanyCtasStddevSampleLockedDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_LOCKED_DESC',
  CompanyCtasStddevSampleDefaultAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_DEFAULT_ASC',
  CompanyCtasStddevSampleDefaultDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_DEFAULT_DESC',
  CompanyCtasStddevSampleTemplateDefAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_TEMPLATE_DEF_ASC',
  CompanyCtasStddevSampleTemplateDefDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_TEMPLATE_DEF_DESC',
  CompanyCtasStddevSampleThemeDefAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DEF_ASC',
  CompanyCtasStddevSampleThemeDefDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DEF_DESC',
  CompanyCtasStddevSampleCreatedAtAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyCtasStddevSampleCreatedAtDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyCtasStddevSampleUpdatedAtAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyCtasStddevSampleUpdatedAtDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyCtasStddevSampleCompatibleMajorAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasStddevSampleCompatibleMajorDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasStddevSampleCtaDetailsAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_DETAILS_ASC',
  CompanyCtasStddevSampleCtaDetailsDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_DETAILS_DESC',
  CompanyCtasStddevPopulationIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_ID_ASC',
  CompanyCtasStddevPopulationIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_ID_DESC',
  CompanyCtasStddevPopulationCtaIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_ID_ASC',
  CompanyCtasStddevPopulationCtaIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_ID_DESC',
  CompanyCtasStddevPopulationGroupIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyCtasStddevPopulationGroupIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyCtasStddevPopulationThemeAsc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_ASC',
  CompanyCtasStddevPopulationThemeDesc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DESC',
  CompanyCtasStddevPopulationNameAsc = 'COMPANY_CTAS_STDDEV_POPULATION_NAME_ASC',
  CompanyCtasStddevPopulationNameDesc = 'COMPANY_CTAS_STDDEV_POPULATION_NAME_DESC',
  CompanyCtasStddevPopulationLockedAsc = 'COMPANY_CTAS_STDDEV_POPULATION_LOCKED_ASC',
  CompanyCtasStddevPopulationLockedDesc = 'COMPANY_CTAS_STDDEV_POPULATION_LOCKED_DESC',
  CompanyCtasStddevPopulationDefaultAsc = 'COMPANY_CTAS_STDDEV_POPULATION_DEFAULT_ASC',
  CompanyCtasStddevPopulationDefaultDesc = 'COMPANY_CTAS_STDDEV_POPULATION_DEFAULT_DESC',
  CompanyCtasStddevPopulationTemplateDefAsc = 'COMPANY_CTAS_STDDEV_POPULATION_TEMPLATE_DEF_ASC',
  CompanyCtasStddevPopulationTemplateDefDesc = 'COMPANY_CTAS_STDDEV_POPULATION_TEMPLATE_DEF_DESC',
  CompanyCtasStddevPopulationThemeDefAsc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DEF_ASC',
  CompanyCtasStddevPopulationThemeDefDesc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DEF_DESC',
  CompanyCtasStddevPopulationCreatedAtAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyCtasStddevPopulationCreatedAtDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyCtasStddevPopulationUpdatedAtAsc = 'COMPANY_CTAS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyCtasStddevPopulationUpdatedAtDesc = 'COMPANY_CTAS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyCtasStddevPopulationCompatibleMajorAsc = 'COMPANY_CTAS_STDDEV_POPULATION_COMPATIBLE_MAJOR_ASC',
  CompanyCtasStddevPopulationCompatibleMajorDesc = 'COMPANY_CTAS_STDDEV_POPULATION_COMPATIBLE_MAJOR_DESC',
  CompanyCtasStddevPopulationCtaDetailsAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_DETAILS_ASC',
  CompanyCtasStddevPopulationCtaDetailsDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_DETAILS_DESC',
  CompanyCtasVarianceSampleIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_ID_ASC',
  CompanyCtasVarianceSampleIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_ID_DESC',
  CompanyCtasVarianceSampleCtaIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_ID_ASC',
  CompanyCtasVarianceSampleCtaIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_ID_DESC',
  CompanyCtasVarianceSampleGroupIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyCtasVarianceSampleGroupIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyCtasVarianceSampleThemeAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_ASC',
  CompanyCtasVarianceSampleThemeDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DESC',
  CompanyCtasVarianceSampleNameAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_NAME_ASC',
  CompanyCtasVarianceSampleNameDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_NAME_DESC',
  CompanyCtasVarianceSampleLockedAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_LOCKED_ASC',
  CompanyCtasVarianceSampleLockedDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_LOCKED_DESC',
  CompanyCtasVarianceSampleDefaultAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_DEFAULT_ASC',
  CompanyCtasVarianceSampleDefaultDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_DEFAULT_DESC',
  CompanyCtasVarianceSampleTemplateDefAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_TEMPLATE_DEF_ASC',
  CompanyCtasVarianceSampleTemplateDefDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_TEMPLATE_DEF_DESC',
  CompanyCtasVarianceSampleThemeDefAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DEF_ASC',
  CompanyCtasVarianceSampleThemeDefDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DEF_DESC',
  CompanyCtasVarianceSampleCreatedAtAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyCtasVarianceSampleCreatedAtDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyCtasVarianceSampleUpdatedAtAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyCtasVarianceSampleUpdatedAtDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyCtasVarianceSampleCompatibleMajorAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasVarianceSampleCompatibleMajorDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasVarianceSampleCtaDetailsAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_DETAILS_ASC',
  CompanyCtasVarianceSampleCtaDetailsDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_DETAILS_DESC',
  CompanyCtasVariancePopulationIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_ID_ASC',
  CompanyCtasVariancePopulationIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_ID_DESC',
  CompanyCtasVariancePopulationCtaIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_ID_ASC',
  CompanyCtasVariancePopulationCtaIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_ID_DESC',
  CompanyCtasVariancePopulationGroupIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyCtasVariancePopulationGroupIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyCtasVariancePopulationThemeAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_ASC',
  CompanyCtasVariancePopulationThemeDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DESC',
  CompanyCtasVariancePopulationNameAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_NAME_ASC',
  CompanyCtasVariancePopulationNameDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_NAME_DESC',
  CompanyCtasVariancePopulationLockedAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_LOCKED_ASC',
  CompanyCtasVariancePopulationLockedDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_LOCKED_DESC',
  CompanyCtasVariancePopulationDefaultAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_DEFAULT_ASC',
  CompanyCtasVariancePopulationDefaultDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_DEFAULT_DESC',
  CompanyCtasVariancePopulationTemplateDefAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_TEMPLATE_DEF_ASC',
  CompanyCtasVariancePopulationTemplateDefDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_TEMPLATE_DEF_DESC',
  CompanyCtasVariancePopulationThemeDefAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DEF_ASC',
  CompanyCtasVariancePopulationThemeDefDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DEF_DESC',
  CompanyCtasVariancePopulationCreatedAtAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyCtasVariancePopulationCreatedAtDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyCtasVariancePopulationUpdatedAtAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyCtasVariancePopulationUpdatedAtDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyCtasVariancePopulationCompatibleMajorAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_COMPATIBLE_MAJOR_ASC',
  CompanyCtasVariancePopulationCompatibleMajorDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_COMPATIBLE_MAJOR_DESC',
  CompanyCtasVariancePopulationCtaDetailsAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_DETAILS_ASC',
  CompanyCtasVariancePopulationCtaDetailsDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_DETAILS_DESC',
  DeliveryMethodsCountAsc = 'DELIVERY_METHODS_COUNT_ASC',
  DeliveryMethodsCountDesc = 'DELIVERY_METHODS_COUNT_DESC',
  DeliveryMethodsSumGroupIdAsc = 'DELIVERY_METHODS_SUM_GROUP_ID_ASC',
  DeliveryMethodsSumGroupIdDesc = 'DELIVERY_METHODS_SUM_GROUP_ID_DESC',
  DeliveryMethodsSumTypeAsc = 'DELIVERY_METHODS_SUM_TYPE_ASC',
  DeliveryMethodsSumTypeDesc = 'DELIVERY_METHODS_SUM_TYPE_DESC',
  DeliveryMethodsSumCreatedAtAsc = 'DELIVERY_METHODS_SUM_CREATED_AT_ASC',
  DeliveryMethodsSumCreatedAtDesc = 'DELIVERY_METHODS_SUM_CREATED_AT_DESC',
  DeliveryMethodsSumUpdatedAtAsc = 'DELIVERY_METHODS_SUM_UPDATED_AT_ASC',
  DeliveryMethodsSumUpdatedAtDesc = 'DELIVERY_METHODS_SUM_UPDATED_AT_DESC',
  DeliveryMethodsSumConfigAsc = 'DELIVERY_METHODS_SUM_CONFIG_ASC',
  DeliveryMethodsSumConfigDesc = 'DELIVERY_METHODS_SUM_CONFIG_DESC',
  DeliveryMethodsSumValueAsc = 'DELIVERY_METHODS_SUM_VALUE_ASC',
  DeliveryMethodsSumValueDesc = 'DELIVERY_METHODS_SUM_VALUE_DESC',
  DeliveryMethodsSumIdAsc = 'DELIVERY_METHODS_SUM_ID_ASC',
  DeliveryMethodsSumIdDesc = 'DELIVERY_METHODS_SUM_ID_DESC',
  DeliveryMethodsSumRuleAsc = 'DELIVERY_METHODS_SUM_RULE_ASC',
  DeliveryMethodsSumRuleDesc = 'DELIVERY_METHODS_SUM_RULE_DESC',
  DeliveryMethodsDistinctCountGroupIdAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_GROUP_ID_ASC',
  DeliveryMethodsDistinctCountGroupIdDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_GROUP_ID_DESC',
  DeliveryMethodsDistinctCountTypeAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_TYPE_ASC',
  DeliveryMethodsDistinctCountTypeDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_TYPE_DESC',
  DeliveryMethodsDistinctCountCreatedAtAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_CREATED_AT_ASC',
  DeliveryMethodsDistinctCountCreatedAtDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_CREATED_AT_DESC',
  DeliveryMethodsDistinctCountUpdatedAtAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_UPDATED_AT_ASC',
  DeliveryMethodsDistinctCountUpdatedAtDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_UPDATED_AT_DESC',
  DeliveryMethodsDistinctCountConfigAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_CONFIG_ASC',
  DeliveryMethodsDistinctCountConfigDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_CONFIG_DESC',
  DeliveryMethodsDistinctCountValueAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_VALUE_ASC',
  DeliveryMethodsDistinctCountValueDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_VALUE_DESC',
  DeliveryMethodsDistinctCountIdAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_ID_ASC',
  DeliveryMethodsDistinctCountIdDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_ID_DESC',
  DeliveryMethodsDistinctCountRuleAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_RULE_ASC',
  DeliveryMethodsDistinctCountRuleDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_RULE_DESC',
  DeliveryMethodsMinGroupIdAsc = 'DELIVERY_METHODS_MIN_GROUP_ID_ASC',
  DeliveryMethodsMinGroupIdDesc = 'DELIVERY_METHODS_MIN_GROUP_ID_DESC',
  DeliveryMethodsMinTypeAsc = 'DELIVERY_METHODS_MIN_TYPE_ASC',
  DeliveryMethodsMinTypeDesc = 'DELIVERY_METHODS_MIN_TYPE_DESC',
  DeliveryMethodsMinCreatedAtAsc = 'DELIVERY_METHODS_MIN_CREATED_AT_ASC',
  DeliveryMethodsMinCreatedAtDesc = 'DELIVERY_METHODS_MIN_CREATED_AT_DESC',
  DeliveryMethodsMinUpdatedAtAsc = 'DELIVERY_METHODS_MIN_UPDATED_AT_ASC',
  DeliveryMethodsMinUpdatedAtDesc = 'DELIVERY_METHODS_MIN_UPDATED_AT_DESC',
  DeliveryMethodsMinConfigAsc = 'DELIVERY_METHODS_MIN_CONFIG_ASC',
  DeliveryMethodsMinConfigDesc = 'DELIVERY_METHODS_MIN_CONFIG_DESC',
  DeliveryMethodsMinValueAsc = 'DELIVERY_METHODS_MIN_VALUE_ASC',
  DeliveryMethodsMinValueDesc = 'DELIVERY_METHODS_MIN_VALUE_DESC',
  DeliveryMethodsMinIdAsc = 'DELIVERY_METHODS_MIN_ID_ASC',
  DeliveryMethodsMinIdDesc = 'DELIVERY_METHODS_MIN_ID_DESC',
  DeliveryMethodsMinRuleAsc = 'DELIVERY_METHODS_MIN_RULE_ASC',
  DeliveryMethodsMinRuleDesc = 'DELIVERY_METHODS_MIN_RULE_DESC',
  DeliveryMethodsMaxGroupIdAsc = 'DELIVERY_METHODS_MAX_GROUP_ID_ASC',
  DeliveryMethodsMaxGroupIdDesc = 'DELIVERY_METHODS_MAX_GROUP_ID_DESC',
  DeliveryMethodsMaxTypeAsc = 'DELIVERY_METHODS_MAX_TYPE_ASC',
  DeliveryMethodsMaxTypeDesc = 'DELIVERY_METHODS_MAX_TYPE_DESC',
  DeliveryMethodsMaxCreatedAtAsc = 'DELIVERY_METHODS_MAX_CREATED_AT_ASC',
  DeliveryMethodsMaxCreatedAtDesc = 'DELIVERY_METHODS_MAX_CREATED_AT_DESC',
  DeliveryMethodsMaxUpdatedAtAsc = 'DELIVERY_METHODS_MAX_UPDATED_AT_ASC',
  DeliveryMethodsMaxUpdatedAtDesc = 'DELIVERY_METHODS_MAX_UPDATED_AT_DESC',
  DeliveryMethodsMaxConfigAsc = 'DELIVERY_METHODS_MAX_CONFIG_ASC',
  DeliveryMethodsMaxConfigDesc = 'DELIVERY_METHODS_MAX_CONFIG_DESC',
  DeliveryMethodsMaxValueAsc = 'DELIVERY_METHODS_MAX_VALUE_ASC',
  DeliveryMethodsMaxValueDesc = 'DELIVERY_METHODS_MAX_VALUE_DESC',
  DeliveryMethodsMaxIdAsc = 'DELIVERY_METHODS_MAX_ID_ASC',
  DeliveryMethodsMaxIdDesc = 'DELIVERY_METHODS_MAX_ID_DESC',
  DeliveryMethodsMaxRuleAsc = 'DELIVERY_METHODS_MAX_RULE_ASC',
  DeliveryMethodsMaxRuleDesc = 'DELIVERY_METHODS_MAX_RULE_DESC',
  DeliveryMethodsAverageGroupIdAsc = 'DELIVERY_METHODS_AVERAGE_GROUP_ID_ASC',
  DeliveryMethodsAverageGroupIdDesc = 'DELIVERY_METHODS_AVERAGE_GROUP_ID_DESC',
  DeliveryMethodsAverageTypeAsc = 'DELIVERY_METHODS_AVERAGE_TYPE_ASC',
  DeliveryMethodsAverageTypeDesc = 'DELIVERY_METHODS_AVERAGE_TYPE_DESC',
  DeliveryMethodsAverageCreatedAtAsc = 'DELIVERY_METHODS_AVERAGE_CREATED_AT_ASC',
  DeliveryMethodsAverageCreatedAtDesc = 'DELIVERY_METHODS_AVERAGE_CREATED_AT_DESC',
  DeliveryMethodsAverageUpdatedAtAsc = 'DELIVERY_METHODS_AVERAGE_UPDATED_AT_ASC',
  DeliveryMethodsAverageUpdatedAtDesc = 'DELIVERY_METHODS_AVERAGE_UPDATED_AT_DESC',
  DeliveryMethodsAverageConfigAsc = 'DELIVERY_METHODS_AVERAGE_CONFIG_ASC',
  DeliveryMethodsAverageConfigDesc = 'DELIVERY_METHODS_AVERAGE_CONFIG_DESC',
  DeliveryMethodsAverageValueAsc = 'DELIVERY_METHODS_AVERAGE_VALUE_ASC',
  DeliveryMethodsAverageValueDesc = 'DELIVERY_METHODS_AVERAGE_VALUE_DESC',
  DeliveryMethodsAverageIdAsc = 'DELIVERY_METHODS_AVERAGE_ID_ASC',
  DeliveryMethodsAverageIdDesc = 'DELIVERY_METHODS_AVERAGE_ID_DESC',
  DeliveryMethodsAverageRuleAsc = 'DELIVERY_METHODS_AVERAGE_RULE_ASC',
  DeliveryMethodsAverageRuleDesc = 'DELIVERY_METHODS_AVERAGE_RULE_DESC',
  DeliveryMethodsStddevSampleGroupIdAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_GROUP_ID_ASC',
  DeliveryMethodsStddevSampleGroupIdDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_GROUP_ID_DESC',
  DeliveryMethodsStddevSampleTypeAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_TYPE_ASC',
  DeliveryMethodsStddevSampleTypeDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_TYPE_DESC',
  DeliveryMethodsStddevSampleCreatedAtAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_CREATED_AT_ASC',
  DeliveryMethodsStddevSampleCreatedAtDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_CREATED_AT_DESC',
  DeliveryMethodsStddevSampleUpdatedAtAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DeliveryMethodsStddevSampleUpdatedAtDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DeliveryMethodsStddevSampleConfigAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_CONFIG_ASC',
  DeliveryMethodsStddevSampleConfigDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_CONFIG_DESC',
  DeliveryMethodsStddevSampleValueAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_VALUE_ASC',
  DeliveryMethodsStddevSampleValueDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_VALUE_DESC',
  DeliveryMethodsStddevSampleIdAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_ID_ASC',
  DeliveryMethodsStddevSampleIdDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_ID_DESC',
  DeliveryMethodsStddevSampleRuleAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_RULE_ASC',
  DeliveryMethodsStddevSampleRuleDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_RULE_DESC',
  DeliveryMethodsStddevPopulationGroupIdAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_GROUP_ID_ASC',
  DeliveryMethodsStddevPopulationGroupIdDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_GROUP_ID_DESC',
  DeliveryMethodsStddevPopulationTypeAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_TYPE_ASC',
  DeliveryMethodsStddevPopulationTypeDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_TYPE_DESC',
  DeliveryMethodsStddevPopulationCreatedAtAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_CREATED_AT_ASC',
  DeliveryMethodsStddevPopulationCreatedAtDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_CREATED_AT_DESC',
  DeliveryMethodsStddevPopulationUpdatedAtAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_UPDATED_AT_ASC',
  DeliveryMethodsStddevPopulationUpdatedAtDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_UPDATED_AT_DESC',
  DeliveryMethodsStddevPopulationConfigAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_CONFIG_ASC',
  DeliveryMethodsStddevPopulationConfigDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_CONFIG_DESC',
  DeliveryMethodsStddevPopulationValueAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_VALUE_ASC',
  DeliveryMethodsStddevPopulationValueDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_VALUE_DESC',
  DeliveryMethodsStddevPopulationIdAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_ID_ASC',
  DeliveryMethodsStddevPopulationIdDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_ID_DESC',
  DeliveryMethodsStddevPopulationRuleAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_RULE_ASC',
  DeliveryMethodsStddevPopulationRuleDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_RULE_DESC',
  DeliveryMethodsVarianceSampleGroupIdAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  DeliveryMethodsVarianceSampleGroupIdDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  DeliveryMethodsVarianceSampleTypeAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_TYPE_ASC',
  DeliveryMethodsVarianceSampleTypeDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_TYPE_DESC',
  DeliveryMethodsVarianceSampleCreatedAtAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DeliveryMethodsVarianceSampleCreatedAtDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DeliveryMethodsVarianceSampleUpdatedAtAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DeliveryMethodsVarianceSampleUpdatedAtDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DeliveryMethodsVarianceSampleConfigAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_CONFIG_ASC',
  DeliveryMethodsVarianceSampleConfigDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_CONFIG_DESC',
  DeliveryMethodsVarianceSampleValueAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_VALUE_ASC',
  DeliveryMethodsVarianceSampleValueDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_VALUE_DESC',
  DeliveryMethodsVarianceSampleIdAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_ID_ASC',
  DeliveryMethodsVarianceSampleIdDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_ID_DESC',
  DeliveryMethodsVarianceSampleRuleAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_RULE_ASC',
  DeliveryMethodsVarianceSampleRuleDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_RULE_DESC',
  DeliveryMethodsVariancePopulationGroupIdAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_GROUP_ID_ASC',
  DeliveryMethodsVariancePopulationGroupIdDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_GROUP_ID_DESC',
  DeliveryMethodsVariancePopulationTypeAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_TYPE_ASC',
  DeliveryMethodsVariancePopulationTypeDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_TYPE_DESC',
  DeliveryMethodsVariancePopulationCreatedAtAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_CREATED_AT_ASC',
  DeliveryMethodsVariancePopulationCreatedAtDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_CREATED_AT_DESC',
  DeliveryMethodsVariancePopulationUpdatedAtAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DeliveryMethodsVariancePopulationUpdatedAtDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DeliveryMethodsVariancePopulationConfigAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_CONFIG_ASC',
  DeliveryMethodsVariancePopulationConfigDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_CONFIG_DESC',
  DeliveryMethodsVariancePopulationValueAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_VALUE_ASC',
  DeliveryMethodsVariancePopulationValueDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_VALUE_DESC',
  DeliveryMethodsVariancePopulationIdAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_ID_ASC',
  DeliveryMethodsVariancePopulationIdDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_ID_DESC',
  DeliveryMethodsVariancePopulationRuleAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_RULE_ASC',
  DeliveryMethodsVariancePopulationRuleDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_RULE_DESC',
  CompanyUsersCountAsc = 'COMPANY_USERS_COUNT_ASC',
  CompanyUsersCountDesc = 'COMPANY_USERS_COUNT_DESC',
  CompanyUsersSumIdAsc = 'COMPANY_USERS_SUM_ID_ASC',
  CompanyUsersSumIdDesc = 'COMPANY_USERS_SUM_ID_DESC',
  CompanyUsersSumCompanyIdAsc = 'COMPANY_USERS_SUM_COMPANY_ID_ASC',
  CompanyUsersSumCompanyIdDesc = 'COMPANY_USERS_SUM_COMPANY_ID_DESC',
  CompanyUsersSumGroupIdAsc = 'COMPANY_USERS_SUM_GROUP_ID_ASC',
  CompanyUsersSumGroupIdDesc = 'COMPANY_USERS_SUM_GROUP_ID_DESC',
  CompanyUsersSumUserIdAsc = 'COMPANY_USERS_SUM_USER_ID_ASC',
  CompanyUsersSumUserIdDesc = 'COMPANY_USERS_SUM_USER_ID_DESC',
  CompanyUsersSumRoleAsc = 'COMPANY_USERS_SUM_ROLE_ASC',
  CompanyUsersSumRoleDesc = 'COMPANY_USERS_SUM_ROLE_DESC',
  CompanyUsersSumCreatedAtAsc = 'COMPANY_USERS_SUM_CREATED_AT_ASC',
  CompanyUsersSumCreatedAtDesc = 'COMPANY_USERS_SUM_CREATED_AT_DESC',
  CompanyUsersSumUpdatedAtAsc = 'COMPANY_USERS_SUM_UPDATED_AT_ASC',
  CompanyUsersSumUpdatedAtDesc = 'COMPANY_USERS_SUM_UPDATED_AT_DESC',
  CompanyUsersDistinctCountIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_ID_ASC',
  CompanyUsersDistinctCountIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_ID_DESC',
  CompanyUsersDistinctCountCompanyIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyUsersDistinctCountCompanyIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyUsersDistinctCountGroupIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyUsersDistinctCountGroupIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyUsersDistinctCountUserIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_USER_ID_ASC',
  CompanyUsersDistinctCountUserIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_USER_ID_DESC',
  CompanyUsersDistinctCountRoleAsc = 'COMPANY_USERS_DISTINCT_COUNT_ROLE_ASC',
  CompanyUsersDistinctCountRoleDesc = 'COMPANY_USERS_DISTINCT_COUNT_ROLE_DESC',
  CompanyUsersDistinctCountCreatedAtAsc = 'COMPANY_USERS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyUsersDistinctCountCreatedAtDesc = 'COMPANY_USERS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyUsersDistinctCountUpdatedAtAsc = 'COMPANY_USERS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyUsersDistinctCountUpdatedAtDesc = 'COMPANY_USERS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyUsersMinIdAsc = 'COMPANY_USERS_MIN_ID_ASC',
  CompanyUsersMinIdDesc = 'COMPANY_USERS_MIN_ID_DESC',
  CompanyUsersMinCompanyIdAsc = 'COMPANY_USERS_MIN_COMPANY_ID_ASC',
  CompanyUsersMinCompanyIdDesc = 'COMPANY_USERS_MIN_COMPANY_ID_DESC',
  CompanyUsersMinGroupIdAsc = 'COMPANY_USERS_MIN_GROUP_ID_ASC',
  CompanyUsersMinGroupIdDesc = 'COMPANY_USERS_MIN_GROUP_ID_DESC',
  CompanyUsersMinUserIdAsc = 'COMPANY_USERS_MIN_USER_ID_ASC',
  CompanyUsersMinUserIdDesc = 'COMPANY_USERS_MIN_USER_ID_DESC',
  CompanyUsersMinRoleAsc = 'COMPANY_USERS_MIN_ROLE_ASC',
  CompanyUsersMinRoleDesc = 'COMPANY_USERS_MIN_ROLE_DESC',
  CompanyUsersMinCreatedAtAsc = 'COMPANY_USERS_MIN_CREATED_AT_ASC',
  CompanyUsersMinCreatedAtDesc = 'COMPANY_USERS_MIN_CREATED_AT_DESC',
  CompanyUsersMinUpdatedAtAsc = 'COMPANY_USERS_MIN_UPDATED_AT_ASC',
  CompanyUsersMinUpdatedAtDesc = 'COMPANY_USERS_MIN_UPDATED_AT_DESC',
  CompanyUsersMaxIdAsc = 'COMPANY_USERS_MAX_ID_ASC',
  CompanyUsersMaxIdDesc = 'COMPANY_USERS_MAX_ID_DESC',
  CompanyUsersMaxCompanyIdAsc = 'COMPANY_USERS_MAX_COMPANY_ID_ASC',
  CompanyUsersMaxCompanyIdDesc = 'COMPANY_USERS_MAX_COMPANY_ID_DESC',
  CompanyUsersMaxGroupIdAsc = 'COMPANY_USERS_MAX_GROUP_ID_ASC',
  CompanyUsersMaxGroupIdDesc = 'COMPANY_USERS_MAX_GROUP_ID_DESC',
  CompanyUsersMaxUserIdAsc = 'COMPANY_USERS_MAX_USER_ID_ASC',
  CompanyUsersMaxUserIdDesc = 'COMPANY_USERS_MAX_USER_ID_DESC',
  CompanyUsersMaxRoleAsc = 'COMPANY_USERS_MAX_ROLE_ASC',
  CompanyUsersMaxRoleDesc = 'COMPANY_USERS_MAX_ROLE_DESC',
  CompanyUsersMaxCreatedAtAsc = 'COMPANY_USERS_MAX_CREATED_AT_ASC',
  CompanyUsersMaxCreatedAtDesc = 'COMPANY_USERS_MAX_CREATED_AT_DESC',
  CompanyUsersMaxUpdatedAtAsc = 'COMPANY_USERS_MAX_UPDATED_AT_ASC',
  CompanyUsersMaxUpdatedAtDesc = 'COMPANY_USERS_MAX_UPDATED_AT_DESC',
  CompanyUsersAverageIdAsc = 'COMPANY_USERS_AVERAGE_ID_ASC',
  CompanyUsersAverageIdDesc = 'COMPANY_USERS_AVERAGE_ID_DESC',
  CompanyUsersAverageCompanyIdAsc = 'COMPANY_USERS_AVERAGE_COMPANY_ID_ASC',
  CompanyUsersAverageCompanyIdDesc = 'COMPANY_USERS_AVERAGE_COMPANY_ID_DESC',
  CompanyUsersAverageGroupIdAsc = 'COMPANY_USERS_AVERAGE_GROUP_ID_ASC',
  CompanyUsersAverageGroupIdDesc = 'COMPANY_USERS_AVERAGE_GROUP_ID_DESC',
  CompanyUsersAverageUserIdAsc = 'COMPANY_USERS_AVERAGE_USER_ID_ASC',
  CompanyUsersAverageUserIdDesc = 'COMPANY_USERS_AVERAGE_USER_ID_DESC',
  CompanyUsersAverageRoleAsc = 'COMPANY_USERS_AVERAGE_ROLE_ASC',
  CompanyUsersAverageRoleDesc = 'COMPANY_USERS_AVERAGE_ROLE_DESC',
  CompanyUsersAverageCreatedAtAsc = 'COMPANY_USERS_AVERAGE_CREATED_AT_ASC',
  CompanyUsersAverageCreatedAtDesc = 'COMPANY_USERS_AVERAGE_CREATED_AT_DESC',
  CompanyUsersAverageUpdatedAtAsc = 'COMPANY_USERS_AVERAGE_UPDATED_AT_ASC',
  CompanyUsersAverageUpdatedAtDesc = 'COMPANY_USERS_AVERAGE_UPDATED_AT_DESC',
  CompanyUsersStddevSampleIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_ID_ASC',
  CompanyUsersStddevSampleIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_ID_DESC',
  CompanyUsersStddevSampleCompanyIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyUsersStddevSampleCompanyIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyUsersStddevSampleGroupIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyUsersStddevSampleGroupIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyUsersStddevSampleUserIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_USER_ID_ASC',
  CompanyUsersStddevSampleUserIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_USER_ID_DESC',
  CompanyUsersStddevSampleRoleAsc = 'COMPANY_USERS_STDDEV_SAMPLE_ROLE_ASC',
  CompanyUsersStddevSampleRoleDesc = 'COMPANY_USERS_STDDEV_SAMPLE_ROLE_DESC',
  CompanyUsersStddevSampleCreatedAtAsc = 'COMPANY_USERS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyUsersStddevSampleCreatedAtDesc = 'COMPANY_USERS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyUsersStddevSampleUpdatedAtAsc = 'COMPANY_USERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyUsersStddevSampleUpdatedAtDesc = 'COMPANY_USERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyUsersStddevPopulationIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_ID_ASC',
  CompanyUsersStddevPopulationIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_ID_DESC',
  CompanyUsersStddevPopulationCompanyIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyUsersStddevPopulationCompanyIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyUsersStddevPopulationGroupIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyUsersStddevPopulationGroupIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyUsersStddevPopulationUserIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_USER_ID_ASC',
  CompanyUsersStddevPopulationUserIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_USER_ID_DESC',
  CompanyUsersStddevPopulationRoleAsc = 'COMPANY_USERS_STDDEV_POPULATION_ROLE_ASC',
  CompanyUsersStddevPopulationRoleDesc = 'COMPANY_USERS_STDDEV_POPULATION_ROLE_DESC',
  CompanyUsersStddevPopulationCreatedAtAsc = 'COMPANY_USERS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyUsersStddevPopulationCreatedAtDesc = 'COMPANY_USERS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyUsersStddevPopulationUpdatedAtAsc = 'COMPANY_USERS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyUsersStddevPopulationUpdatedAtDesc = 'COMPANY_USERS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyUsersVarianceSampleIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_ID_ASC',
  CompanyUsersVarianceSampleIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_ID_DESC',
  CompanyUsersVarianceSampleCompanyIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyUsersVarianceSampleCompanyIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyUsersVarianceSampleGroupIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyUsersVarianceSampleGroupIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyUsersVarianceSampleUserIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_USER_ID_ASC',
  CompanyUsersVarianceSampleUserIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_USER_ID_DESC',
  CompanyUsersVarianceSampleRoleAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_ROLE_ASC',
  CompanyUsersVarianceSampleRoleDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_ROLE_DESC',
  CompanyUsersVarianceSampleCreatedAtAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyUsersVarianceSampleCreatedAtDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyUsersVarianceSampleUpdatedAtAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyUsersVarianceSampleUpdatedAtDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyUsersVariancePopulationIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_ID_ASC',
  CompanyUsersVariancePopulationIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_ID_DESC',
  CompanyUsersVariancePopulationCompanyIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyUsersVariancePopulationCompanyIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyUsersVariancePopulationGroupIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyUsersVariancePopulationGroupIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyUsersVariancePopulationUserIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_USER_ID_ASC',
  CompanyUsersVariancePopulationUserIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_USER_ID_DESC',
  CompanyUsersVariancePopulationRoleAsc = 'COMPANY_USERS_VARIANCE_POPULATION_ROLE_ASC',
  CompanyUsersVariancePopulationRoleDesc = 'COMPANY_USERS_VARIANCE_POPULATION_ROLE_DESC',
  CompanyUsersVariancePopulationCreatedAtAsc = 'COMPANY_USERS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyUsersVariancePopulationCreatedAtDesc = 'COMPANY_USERS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyUsersVariancePopulationUpdatedAtAsc = 'COMPANY_USERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyUsersVariancePopulationUpdatedAtDesc = 'COMPANY_USERS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type HavingBigfloatFilter = {
  equalTo?: Maybe<Scalars['BigFloat']>;
  notEqualTo?: Maybe<Scalars['BigFloat']>;
  greaterThan?: Maybe<Scalars['BigFloat']>;
  greaterThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
  lessThan?: Maybe<Scalars['BigFloat']>;
  lessThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
};

export type HavingBigintFilter = {
  equalTo?: Maybe<Scalars['BigInt']>;
  notEqualTo?: Maybe<Scalars['BigInt']>;
  greaterThan?: Maybe<Scalars['BigInt']>;
  greaterThanOrEqualTo?: Maybe<Scalars['BigInt']>;
  lessThan?: Maybe<Scalars['BigInt']>;
  lessThanOrEqualTo?: Maybe<Scalars['BigInt']>;
};

export type HavingDatetimeFilter = {
  equalTo?: Maybe<Scalars['Datetime']>;
  notEqualTo?: Maybe<Scalars['Datetime']>;
  greaterThan?: Maybe<Scalars['Datetime']>;
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  lessThan?: Maybe<Scalars['Datetime']>;
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

export type HavingFloatFilter = {
  equalTo?: Maybe<Scalars['Float']>;
  notEqualTo?: Maybe<Scalars['Float']>;
  greaterThan?: Maybe<Scalars['Float']>;
  greaterThanOrEqualTo?: Maybe<Scalars['Float']>;
  lessThan?: Maybe<Scalars['Float']>;
  lessThanOrEqualTo?: Maybe<Scalars['Float']>;
};

export type HavingIntFilter = {
  equalTo?: Maybe<Scalars['Int']>;
  notEqualTo?: Maybe<Scalars['Int']>;
  greaterThan?: Maybe<Scalars['Int']>;
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
  lessThan?: Maybe<Scalars['Int']>;
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
};

export type IdDuplicate = {
  customerUid?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['Float']>;
};

export type IdFieldSource = {
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['String']>;
};

export type IdFieldValues = {
  value?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['String']>;
  sources?: Maybe<Array<Maybe<IdFieldSource>>>;
};

export type IdFields = {
  givenName?: Maybe<IdFieldValues>;
  familyName?: Maybe<IdFieldValues>;
  middleName?: Maybe<IdFieldValues>;
  dateOfBirth?: Maybe<IdFieldValues>;
  idType?: Maybe<IdFieldValues>;
  idIssuer?: Maybe<IdFieldValues>;
  idExpiryDate?: Maybe<IdFieldValues>;
  addressCity?: Maybe<IdFieldValues>;
  addressCountry?: Maybe<IdFieldValues>;
  addressPostalCode?: Maybe<IdFieldValues>;
  addressStreet?: Maybe<IdFieldValues>;
  addressSubdivision?: Maybe<IdFieldValues>;
};

export type IdImageDetails = {
  fullImage?: Maybe<Scalars['String']>;
  croppedImage?: Maybe<Scalars['String']>;
  faceImage?: Maybe<Scalars['String']>;
};

export enum IdImageFormat {
  Png = 'png',
  Jpeg = 'jpeg'
}

export type IdImageInput = {
  fullImage: Scalars['String'];
  imageFormat?: Maybe<IdImageFormat>;
};

export enum IdImageType {
  IdFront = 'idFront',
  IdBack = 'idBack',
  Selfie = 'selfie'
}

/** A connection to a list of `IdInquiry` values. */
export type IdInquiriesConnection = {
  /** A list of `IdInquiry` objects. */
  nodes: Array<IdInquiry>;
  /** A list of edges which contains the `IdInquiry` and cursor to aid in pagination. */
  edges: Array<IdInquiriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdInquiry` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IdInquiryAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IdInquiryAggregates>>;
};


/** A connection to a list of `IdInquiry` values. */
export type IdInquiriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<IdInquiryGroupBy>;
  having?: Maybe<IdInquiryHavingInput>;
};

/** A `IdInquiry` edge in the connection. */
export type IdInquiriesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdInquiry` at the end of the edge. */
  node: IdInquiry;
};

/** Methods to use when ordering `IdInquiry`. */
export enum IdInquiriesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  ProvinceAsc = 'PROVINCE_ASC',
  ProvinceDesc = 'PROVINCE_DESC',
  PostalCodeAsc = 'POSTAL_CODE_ASC',
  PostalCodeDesc = 'POSTAL_CODE_DESC',
  BirthDateAsc = 'BIRTH_DATE_ASC',
  BirthDateDesc = 'BIRTH_DATE_DESC',
  IdNumberAsc = 'ID_NUMBER_ASC',
  IdNumberDesc = 'ID_NUMBER_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  RawInquiryAsc = 'RAW_INQUIRY_ASC',
  RawInquiryDesc = 'RAW_INQUIRY_DESC',
  InquiryIdAsc = 'INQUIRY_ID_ASC',
  InquiryIdDesc = 'INQUIRY_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  IdInquiryVerificationsByInquiryIdCountAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_COUNT_ASC',
  IdInquiryVerificationsByInquiryIdCountDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_COUNT_DESC',
  IdInquiryVerificationsByInquiryIdSumIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_ID_ASC',
  IdInquiryVerificationsByInquiryIdSumIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_ID_DESC',
  IdInquiryVerificationsByInquiryIdSumInquiryIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_INQUIRY_ID_ASC',
  IdInquiryVerificationsByInquiryIdSumInquiryIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_INQUIRY_ID_DESC',
  IdInquiryVerificationsByInquiryIdSumTypeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_TYPE_ASC',
  IdInquiryVerificationsByInquiryIdSumTypeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_TYPE_DESC',
  IdInquiryVerificationsByInquiryIdSumStatusAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_STATUS_ASC',
  IdInquiryVerificationsByInquiryIdSumStatusDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_STATUS_DESC',
  IdInquiryVerificationsByInquiryIdSumReasonsAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_REASONS_ASC',
  IdInquiryVerificationsByInquiryIdSumReasonsDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_REASONS_DESC',
  IdInquiryVerificationsByInquiryIdSumFirstNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_FIRST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdSumFirstNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_FIRST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdSumLastNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_LAST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdSumLastNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_LAST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdSumGenderAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_GENDER_ASC',
  IdInquiryVerificationsByInquiryIdSumGenderDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_GENDER_DESC',
  IdInquiryVerificationsByInquiryIdSumAddressAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_ADDRESS_ASC',
  IdInquiryVerificationsByInquiryIdSumAddressDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_ADDRESS_DESC',
  IdInquiryVerificationsByInquiryIdSumCityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_CITY_ASC',
  IdInquiryVerificationsByInquiryIdSumCityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_CITY_DESC',
  IdInquiryVerificationsByInquiryIdSumProvinceAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_PROVINCE_ASC',
  IdInquiryVerificationsByInquiryIdSumProvinceDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_PROVINCE_DESC',
  IdInquiryVerificationsByInquiryIdSumPostalCodeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_POSTAL_CODE_ASC',
  IdInquiryVerificationsByInquiryIdSumPostalCodeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_POSTAL_CODE_DESC',
  IdInquiryVerificationsByInquiryIdSumBirthDateAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_BIRTH_DATE_ASC',
  IdInquiryVerificationsByInquiryIdSumBirthDateDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_BIRTH_DATE_DESC',
  IdInquiryVerificationsByInquiryIdSumIdNumberAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_ID_NUMBER_ASC',
  IdInquiryVerificationsByInquiryIdSumIdNumberDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_ID_NUMBER_DESC',
  IdInquiryVerificationsByInquiryIdSumIssueAuthorityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_ISSUE_AUTHORITY_ASC',
  IdInquiryVerificationsByInquiryIdSumIssueAuthorityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_ISSUE_AUTHORITY_DESC',
  IdInquiryVerificationsByInquiryIdSumCreatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_CREATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdSumCreatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_CREATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdSumUpdatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_UPDATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdSumUpdatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_UPDATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdSumDeletedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_DELETED_AT_ASC',
  IdInquiryVerificationsByInquiryIdSumDeletedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_DELETED_AT_DESC',
  IdInquiryVerificationsByInquiryIdSumIdInquiryVerificationIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_ID_INQUIRY_VERIFICATION_ID_ASC',
  IdInquiryVerificationsByInquiryIdSumIdInquiryVerificationIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_SUM_ID_INQUIRY_VERIFICATION_ID_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ID_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ID_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountInquiryIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_INQUIRY_ID_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountInquiryIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_INQUIRY_ID_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountTypeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_TYPE_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountTypeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_TYPE_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountStatusAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_STATUS_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountStatusDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_STATUS_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountReasonsAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_REASONS_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountReasonsDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_REASONS_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountFirstNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_FIRST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountFirstNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_FIRST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountLastNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_LAST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountLastNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_LAST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountGenderAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_GENDER_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountGenderDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_GENDER_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountAddressAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ADDRESS_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountAddressDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ADDRESS_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountCityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_CITY_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountCityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_CITY_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountProvinceAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_PROVINCE_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountProvinceDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_PROVINCE_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountPostalCodeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_POSTAL_CODE_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountPostalCodeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_POSTAL_CODE_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountBirthDateAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_BIRTH_DATE_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountBirthDateDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_BIRTH_DATE_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountIdNumberAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ID_NUMBER_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountIdNumberDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ID_NUMBER_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountIssueAuthorityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ISSUE_AUTHORITY_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountIssueAuthorityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ISSUE_AUTHORITY_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountCreatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountCreatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountUpdatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountUpdatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountDeletedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_DELETED_AT_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountDeletedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_DELETED_AT_DESC',
  IdInquiryVerificationsByInquiryIdDistinctCountIdInquiryVerificationIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ID_INQUIRY_VERIFICATION_ID_ASC',
  IdInquiryVerificationsByInquiryIdDistinctCountIdInquiryVerificationIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ID_INQUIRY_VERIFICATION_ID_DESC',
  IdInquiryVerificationsByInquiryIdMinIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_ID_ASC',
  IdInquiryVerificationsByInquiryIdMinIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_ID_DESC',
  IdInquiryVerificationsByInquiryIdMinInquiryIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_INQUIRY_ID_ASC',
  IdInquiryVerificationsByInquiryIdMinInquiryIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_INQUIRY_ID_DESC',
  IdInquiryVerificationsByInquiryIdMinTypeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_TYPE_ASC',
  IdInquiryVerificationsByInquiryIdMinTypeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_TYPE_DESC',
  IdInquiryVerificationsByInquiryIdMinStatusAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_STATUS_ASC',
  IdInquiryVerificationsByInquiryIdMinStatusDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_STATUS_DESC',
  IdInquiryVerificationsByInquiryIdMinReasonsAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_REASONS_ASC',
  IdInquiryVerificationsByInquiryIdMinReasonsDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_REASONS_DESC',
  IdInquiryVerificationsByInquiryIdMinFirstNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_FIRST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdMinFirstNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_FIRST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdMinLastNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_LAST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdMinLastNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_LAST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdMinGenderAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_GENDER_ASC',
  IdInquiryVerificationsByInquiryIdMinGenderDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_GENDER_DESC',
  IdInquiryVerificationsByInquiryIdMinAddressAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_ADDRESS_ASC',
  IdInquiryVerificationsByInquiryIdMinAddressDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_ADDRESS_DESC',
  IdInquiryVerificationsByInquiryIdMinCityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_CITY_ASC',
  IdInquiryVerificationsByInquiryIdMinCityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_CITY_DESC',
  IdInquiryVerificationsByInquiryIdMinProvinceAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_PROVINCE_ASC',
  IdInquiryVerificationsByInquiryIdMinProvinceDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_PROVINCE_DESC',
  IdInquiryVerificationsByInquiryIdMinPostalCodeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_POSTAL_CODE_ASC',
  IdInquiryVerificationsByInquiryIdMinPostalCodeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_POSTAL_CODE_DESC',
  IdInquiryVerificationsByInquiryIdMinBirthDateAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_BIRTH_DATE_ASC',
  IdInquiryVerificationsByInquiryIdMinBirthDateDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_BIRTH_DATE_DESC',
  IdInquiryVerificationsByInquiryIdMinIdNumberAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_ID_NUMBER_ASC',
  IdInquiryVerificationsByInquiryIdMinIdNumberDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_ID_NUMBER_DESC',
  IdInquiryVerificationsByInquiryIdMinIssueAuthorityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_ISSUE_AUTHORITY_ASC',
  IdInquiryVerificationsByInquiryIdMinIssueAuthorityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_ISSUE_AUTHORITY_DESC',
  IdInquiryVerificationsByInquiryIdMinCreatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_CREATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdMinCreatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_CREATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdMinUpdatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_UPDATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdMinUpdatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_UPDATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdMinDeletedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_DELETED_AT_ASC',
  IdInquiryVerificationsByInquiryIdMinDeletedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_DELETED_AT_DESC',
  IdInquiryVerificationsByInquiryIdMinIdInquiryVerificationIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_ID_INQUIRY_VERIFICATION_ID_ASC',
  IdInquiryVerificationsByInquiryIdMinIdInquiryVerificationIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MIN_ID_INQUIRY_VERIFICATION_ID_DESC',
  IdInquiryVerificationsByInquiryIdMaxIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_ID_ASC',
  IdInquiryVerificationsByInquiryIdMaxIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_ID_DESC',
  IdInquiryVerificationsByInquiryIdMaxInquiryIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_INQUIRY_ID_ASC',
  IdInquiryVerificationsByInquiryIdMaxInquiryIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_INQUIRY_ID_DESC',
  IdInquiryVerificationsByInquiryIdMaxTypeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_TYPE_ASC',
  IdInquiryVerificationsByInquiryIdMaxTypeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_TYPE_DESC',
  IdInquiryVerificationsByInquiryIdMaxStatusAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_STATUS_ASC',
  IdInquiryVerificationsByInquiryIdMaxStatusDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_STATUS_DESC',
  IdInquiryVerificationsByInquiryIdMaxReasonsAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_REASONS_ASC',
  IdInquiryVerificationsByInquiryIdMaxReasonsDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_REASONS_DESC',
  IdInquiryVerificationsByInquiryIdMaxFirstNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_FIRST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdMaxFirstNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_FIRST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdMaxLastNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_LAST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdMaxLastNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_LAST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdMaxGenderAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_GENDER_ASC',
  IdInquiryVerificationsByInquiryIdMaxGenderDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_GENDER_DESC',
  IdInquiryVerificationsByInquiryIdMaxAddressAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_ADDRESS_ASC',
  IdInquiryVerificationsByInquiryIdMaxAddressDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_ADDRESS_DESC',
  IdInquiryVerificationsByInquiryIdMaxCityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_CITY_ASC',
  IdInquiryVerificationsByInquiryIdMaxCityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_CITY_DESC',
  IdInquiryVerificationsByInquiryIdMaxProvinceAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_PROVINCE_ASC',
  IdInquiryVerificationsByInquiryIdMaxProvinceDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_PROVINCE_DESC',
  IdInquiryVerificationsByInquiryIdMaxPostalCodeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_POSTAL_CODE_ASC',
  IdInquiryVerificationsByInquiryIdMaxPostalCodeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_POSTAL_CODE_DESC',
  IdInquiryVerificationsByInquiryIdMaxBirthDateAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_BIRTH_DATE_ASC',
  IdInquiryVerificationsByInquiryIdMaxBirthDateDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_BIRTH_DATE_DESC',
  IdInquiryVerificationsByInquiryIdMaxIdNumberAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_ID_NUMBER_ASC',
  IdInquiryVerificationsByInquiryIdMaxIdNumberDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_ID_NUMBER_DESC',
  IdInquiryVerificationsByInquiryIdMaxIssueAuthorityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_ISSUE_AUTHORITY_ASC',
  IdInquiryVerificationsByInquiryIdMaxIssueAuthorityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_ISSUE_AUTHORITY_DESC',
  IdInquiryVerificationsByInquiryIdMaxCreatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_CREATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdMaxCreatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_CREATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdMaxUpdatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_UPDATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdMaxUpdatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_UPDATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdMaxDeletedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_DELETED_AT_ASC',
  IdInquiryVerificationsByInquiryIdMaxDeletedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_DELETED_AT_DESC',
  IdInquiryVerificationsByInquiryIdMaxIdInquiryVerificationIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_ID_INQUIRY_VERIFICATION_ID_ASC',
  IdInquiryVerificationsByInquiryIdMaxIdInquiryVerificationIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_MAX_ID_INQUIRY_VERIFICATION_ID_DESC',
  IdInquiryVerificationsByInquiryIdAverageIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_ID_ASC',
  IdInquiryVerificationsByInquiryIdAverageIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_ID_DESC',
  IdInquiryVerificationsByInquiryIdAverageInquiryIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_INQUIRY_ID_ASC',
  IdInquiryVerificationsByInquiryIdAverageInquiryIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_INQUIRY_ID_DESC',
  IdInquiryVerificationsByInquiryIdAverageTypeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_TYPE_ASC',
  IdInquiryVerificationsByInquiryIdAverageTypeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_TYPE_DESC',
  IdInquiryVerificationsByInquiryIdAverageStatusAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_STATUS_ASC',
  IdInquiryVerificationsByInquiryIdAverageStatusDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_STATUS_DESC',
  IdInquiryVerificationsByInquiryIdAverageReasonsAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_REASONS_ASC',
  IdInquiryVerificationsByInquiryIdAverageReasonsDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_REASONS_DESC',
  IdInquiryVerificationsByInquiryIdAverageFirstNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_FIRST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdAverageFirstNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_FIRST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdAverageLastNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_LAST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdAverageLastNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_LAST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdAverageGenderAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_GENDER_ASC',
  IdInquiryVerificationsByInquiryIdAverageGenderDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_GENDER_DESC',
  IdInquiryVerificationsByInquiryIdAverageAddressAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_ADDRESS_ASC',
  IdInquiryVerificationsByInquiryIdAverageAddressDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_ADDRESS_DESC',
  IdInquiryVerificationsByInquiryIdAverageCityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_CITY_ASC',
  IdInquiryVerificationsByInquiryIdAverageCityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_CITY_DESC',
  IdInquiryVerificationsByInquiryIdAverageProvinceAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_PROVINCE_ASC',
  IdInquiryVerificationsByInquiryIdAverageProvinceDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_PROVINCE_DESC',
  IdInquiryVerificationsByInquiryIdAveragePostalCodeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_POSTAL_CODE_ASC',
  IdInquiryVerificationsByInquiryIdAveragePostalCodeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_POSTAL_CODE_DESC',
  IdInquiryVerificationsByInquiryIdAverageBirthDateAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_BIRTH_DATE_ASC',
  IdInquiryVerificationsByInquiryIdAverageBirthDateDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_BIRTH_DATE_DESC',
  IdInquiryVerificationsByInquiryIdAverageIdNumberAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_ID_NUMBER_ASC',
  IdInquiryVerificationsByInquiryIdAverageIdNumberDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_ID_NUMBER_DESC',
  IdInquiryVerificationsByInquiryIdAverageIssueAuthorityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_ISSUE_AUTHORITY_ASC',
  IdInquiryVerificationsByInquiryIdAverageIssueAuthorityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_ISSUE_AUTHORITY_DESC',
  IdInquiryVerificationsByInquiryIdAverageCreatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_CREATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdAverageCreatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_CREATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdAverageUpdatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_UPDATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdAverageUpdatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_UPDATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdAverageDeletedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_DELETED_AT_ASC',
  IdInquiryVerificationsByInquiryIdAverageDeletedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_DELETED_AT_DESC',
  IdInquiryVerificationsByInquiryIdAverageIdInquiryVerificationIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_ID_INQUIRY_VERIFICATION_ID_ASC',
  IdInquiryVerificationsByInquiryIdAverageIdInquiryVerificationIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_AVERAGE_ID_INQUIRY_VERIFICATION_ID_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ID_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ID_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleInquiryIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_INQUIRY_ID_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleInquiryIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_INQUIRY_ID_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleTypeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_TYPE_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleTypeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_TYPE_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleStatusAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_STATUS_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleStatusDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_STATUS_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleReasonsAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_REASONS_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleReasonsDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_REASONS_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleFirstNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_FIRST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleFirstNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_FIRST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleLastNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_LAST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleLastNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_LAST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleGenderAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_GENDER_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleGenderDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_GENDER_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleAddressAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ADDRESS_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleAddressDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ADDRESS_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleCityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_CITY_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleCityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_CITY_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleProvinceAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_PROVINCE_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleProvinceDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_PROVINCE_DESC',
  IdInquiryVerificationsByInquiryIdStddevSamplePostalCodeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_POSTAL_CODE_ASC',
  IdInquiryVerificationsByInquiryIdStddevSamplePostalCodeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_POSTAL_CODE_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleBirthDateAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_BIRTH_DATE_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleBirthDateDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_BIRTH_DATE_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleIdNumberAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ID_NUMBER_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleIdNumberDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ID_NUMBER_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleIssueAuthorityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ISSUE_AUTHORITY_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleIssueAuthorityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ISSUE_AUTHORITY_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleCreatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleCreatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleUpdatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleUpdatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleDeletedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_DELETED_AT_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleDeletedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_DELETED_AT_DESC',
  IdInquiryVerificationsByInquiryIdStddevSampleIdInquiryVerificationIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ID_INQUIRY_VERIFICATION_ID_ASC',
  IdInquiryVerificationsByInquiryIdStddevSampleIdInquiryVerificationIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ID_INQUIRY_VERIFICATION_ID_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ID_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ID_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationInquiryIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_INQUIRY_ID_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationInquiryIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_INQUIRY_ID_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationTypeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_TYPE_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationTypeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_TYPE_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationStatusAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_STATUS_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationStatusDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_STATUS_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationReasonsAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_REASONS_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationReasonsDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_REASONS_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationFirstNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_FIRST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationFirstNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_FIRST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationLastNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_LAST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationLastNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_LAST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationGenderAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_GENDER_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationGenderDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_GENDER_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationAddressAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ADDRESS_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationAddressDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ADDRESS_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationCityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_CITY_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationCityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_CITY_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationProvinceAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_PROVINCE_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationProvinceDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_PROVINCE_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationPostalCodeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_POSTAL_CODE_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationPostalCodeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_POSTAL_CODE_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationBirthDateAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_BIRTH_DATE_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationBirthDateDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_BIRTH_DATE_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationIdNumberAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ID_NUMBER_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationIdNumberDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ID_NUMBER_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationIssueAuthorityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ISSUE_AUTHORITY_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationIssueAuthorityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ISSUE_AUTHORITY_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationCreatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationCreatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationUpdatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationUpdatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationDeletedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_DELETED_AT_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationDeletedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_DELETED_AT_DESC',
  IdInquiryVerificationsByInquiryIdStddevPopulationIdInquiryVerificationIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ID_INQUIRY_VERIFICATION_ID_ASC',
  IdInquiryVerificationsByInquiryIdStddevPopulationIdInquiryVerificationIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ID_INQUIRY_VERIFICATION_ID_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ID_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ID_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleInquiryIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_INQUIRY_ID_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleInquiryIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_INQUIRY_ID_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleTypeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_TYPE_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleTypeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_TYPE_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleStatusAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_STATUS_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleStatusDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_STATUS_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleReasonsAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_REASONS_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleReasonsDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_REASONS_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleFirstNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleFirstNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleLastNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_LAST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleLastNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_LAST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleGenderAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_GENDER_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleGenderDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_GENDER_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleAddressAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ADDRESS_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleAddressDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ADDRESS_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleCityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_CITY_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleCityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_CITY_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleProvinceAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_PROVINCE_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleProvinceDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_PROVINCE_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSamplePostalCodeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_POSTAL_CODE_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSamplePostalCodeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_POSTAL_CODE_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleBirthDateAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_BIRTH_DATE_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleBirthDateDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_BIRTH_DATE_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleIdNumberAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ID_NUMBER_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleIdNumberDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ID_NUMBER_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleIssueAuthorityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ISSUE_AUTHORITY_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleIssueAuthorityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ISSUE_AUTHORITY_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleCreatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleCreatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleUpdatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleUpdatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleDeletedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_DELETED_AT_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleDeletedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_DELETED_AT_DESC',
  IdInquiryVerificationsByInquiryIdVarianceSampleIdInquiryVerificationIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ID_INQUIRY_VERIFICATION_ID_ASC',
  IdInquiryVerificationsByInquiryIdVarianceSampleIdInquiryVerificationIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ID_INQUIRY_VERIFICATION_ID_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ID_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ID_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationInquiryIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_INQUIRY_ID_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationInquiryIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_INQUIRY_ID_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationTypeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_TYPE_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationTypeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_TYPE_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationStatusAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_STATUS_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationStatusDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_STATUS_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationReasonsAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_REASONS_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationReasonsDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_REASONS_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationFirstNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_FIRST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationFirstNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_FIRST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationLastNameAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_LAST_NAME_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationLastNameDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_LAST_NAME_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationGenderAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_GENDER_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationGenderDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_GENDER_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationAddressAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ADDRESS_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationAddressDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ADDRESS_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationCityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_CITY_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationCityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_CITY_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationProvinceAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_PROVINCE_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationProvinceDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_PROVINCE_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationPostalCodeAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_POSTAL_CODE_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationPostalCodeDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_POSTAL_CODE_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationBirthDateAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_BIRTH_DATE_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationBirthDateDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_BIRTH_DATE_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationIdNumberAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ID_NUMBER_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationIdNumberDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ID_NUMBER_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationIssueAuthorityAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ISSUE_AUTHORITY_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationIssueAuthorityDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ISSUE_AUTHORITY_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationCreatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationCreatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationUpdatedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationUpdatedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationDeletedAtAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_DELETED_AT_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationDeletedAtDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_DELETED_AT_DESC',
  IdInquiryVerificationsByInquiryIdVariancePopulationIdInquiryVerificationIdAsc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ID_INQUIRY_VERIFICATION_ID_ASC',
  IdInquiryVerificationsByInquiryIdVariancePopulationIdInquiryVerificationIdDesc = 'ID_INQUIRY_VERIFICATIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ID_INQUIRY_VERIFICATION_ID_DESC',
  IdSessionsByInquiryIdCountAsc = 'ID_SESSIONS_BY_INQUIRY_ID_COUNT_ASC',
  IdSessionsByInquiryIdCountDesc = 'ID_SESSIONS_BY_INQUIRY_ID_COUNT_DESC',
  IdSessionsByInquiryIdSumIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_ID_ASC',
  IdSessionsByInquiryIdSumIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_ID_DESC',
  IdSessionsByInquiryIdSumInquiryIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_INQUIRY_ID_ASC',
  IdSessionsByInquiryIdSumInquiryIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_INQUIRY_ID_DESC',
  IdSessionsByInquiryIdSumThreatAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_THREAT_ASC',
  IdSessionsByInquiryIdSumThreatDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_THREAT_DESC',
  IdSessionsByInquiryIdSumLocationAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_LOCATION_ASC',
  IdSessionsByInquiryIdSumLocationDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_LOCATION_DESC',
  IdSessionsByInquiryIdSumMapsAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_MAPS_ASC',
  IdSessionsByInquiryIdSumMapsDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_MAPS_DESC',
  IdSessionsByInquiryIdSumDeviceAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_DEVICE_ASC',
  IdSessionsByInquiryIdSumDeviceDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_DEVICE_DESC',
  IdSessionsByInquiryIdSumNotesAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_NOTES_ASC',
  IdSessionsByInquiryIdSumNotesDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_NOTES_DESC',
  IdSessionsByInquiryIdSumStartedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_STARTED_AT_ASC',
  IdSessionsByInquiryIdSumStartedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_STARTED_AT_DESC',
  IdSessionsByInquiryIdSumCreatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_CREATED_AT_ASC',
  IdSessionsByInquiryIdSumCreatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_CREATED_AT_DESC',
  IdSessionsByInquiryIdSumUpdatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_UPDATED_AT_ASC',
  IdSessionsByInquiryIdSumUpdatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_UPDATED_AT_DESC',
  IdSessionsByInquiryIdSumDeletedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_DELETED_AT_ASC',
  IdSessionsByInquiryIdSumDeletedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_DELETED_AT_DESC',
  IdSessionsByInquiryIdSumIdSessionIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_ID_SESSION_ID_ASC',
  IdSessionsByInquiryIdSumIdSessionIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_SUM_ID_SESSION_ID_DESC',
  IdSessionsByInquiryIdDistinctCountIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ID_ASC',
  IdSessionsByInquiryIdDistinctCountIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ID_DESC',
  IdSessionsByInquiryIdDistinctCountInquiryIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_INQUIRY_ID_ASC',
  IdSessionsByInquiryIdDistinctCountInquiryIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_INQUIRY_ID_DESC',
  IdSessionsByInquiryIdDistinctCountThreatAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_THREAT_ASC',
  IdSessionsByInquiryIdDistinctCountThreatDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_THREAT_DESC',
  IdSessionsByInquiryIdDistinctCountLocationAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_LOCATION_ASC',
  IdSessionsByInquiryIdDistinctCountLocationDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_LOCATION_DESC',
  IdSessionsByInquiryIdDistinctCountMapsAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_MAPS_ASC',
  IdSessionsByInquiryIdDistinctCountMapsDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_MAPS_DESC',
  IdSessionsByInquiryIdDistinctCountDeviceAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_DEVICE_ASC',
  IdSessionsByInquiryIdDistinctCountDeviceDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_DEVICE_DESC',
  IdSessionsByInquiryIdDistinctCountNotesAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_NOTES_ASC',
  IdSessionsByInquiryIdDistinctCountNotesDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_NOTES_DESC',
  IdSessionsByInquiryIdDistinctCountStartedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_STARTED_AT_ASC',
  IdSessionsByInquiryIdDistinctCountStartedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_STARTED_AT_DESC',
  IdSessionsByInquiryIdDistinctCountCreatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  IdSessionsByInquiryIdDistinctCountCreatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  IdSessionsByInquiryIdDistinctCountUpdatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  IdSessionsByInquiryIdDistinctCountUpdatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  IdSessionsByInquiryIdDistinctCountDeletedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_DELETED_AT_ASC',
  IdSessionsByInquiryIdDistinctCountDeletedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_DELETED_AT_DESC',
  IdSessionsByInquiryIdDistinctCountIdSessionIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ID_SESSION_ID_ASC',
  IdSessionsByInquiryIdDistinctCountIdSessionIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_DISTINCT_COUNT_ID_SESSION_ID_DESC',
  IdSessionsByInquiryIdMinIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_ID_ASC',
  IdSessionsByInquiryIdMinIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_ID_DESC',
  IdSessionsByInquiryIdMinInquiryIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_INQUIRY_ID_ASC',
  IdSessionsByInquiryIdMinInquiryIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_INQUIRY_ID_DESC',
  IdSessionsByInquiryIdMinThreatAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_THREAT_ASC',
  IdSessionsByInquiryIdMinThreatDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_THREAT_DESC',
  IdSessionsByInquiryIdMinLocationAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_LOCATION_ASC',
  IdSessionsByInquiryIdMinLocationDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_LOCATION_DESC',
  IdSessionsByInquiryIdMinMapsAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_MAPS_ASC',
  IdSessionsByInquiryIdMinMapsDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_MAPS_DESC',
  IdSessionsByInquiryIdMinDeviceAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_DEVICE_ASC',
  IdSessionsByInquiryIdMinDeviceDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_DEVICE_DESC',
  IdSessionsByInquiryIdMinNotesAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_NOTES_ASC',
  IdSessionsByInquiryIdMinNotesDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_NOTES_DESC',
  IdSessionsByInquiryIdMinStartedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_STARTED_AT_ASC',
  IdSessionsByInquiryIdMinStartedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_STARTED_AT_DESC',
  IdSessionsByInquiryIdMinCreatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_CREATED_AT_ASC',
  IdSessionsByInquiryIdMinCreatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_CREATED_AT_DESC',
  IdSessionsByInquiryIdMinUpdatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_UPDATED_AT_ASC',
  IdSessionsByInquiryIdMinUpdatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_UPDATED_AT_DESC',
  IdSessionsByInquiryIdMinDeletedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_DELETED_AT_ASC',
  IdSessionsByInquiryIdMinDeletedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_DELETED_AT_DESC',
  IdSessionsByInquiryIdMinIdSessionIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_ID_SESSION_ID_ASC',
  IdSessionsByInquiryIdMinIdSessionIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MIN_ID_SESSION_ID_DESC',
  IdSessionsByInquiryIdMaxIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_ID_ASC',
  IdSessionsByInquiryIdMaxIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_ID_DESC',
  IdSessionsByInquiryIdMaxInquiryIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_INQUIRY_ID_ASC',
  IdSessionsByInquiryIdMaxInquiryIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_INQUIRY_ID_DESC',
  IdSessionsByInquiryIdMaxThreatAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_THREAT_ASC',
  IdSessionsByInquiryIdMaxThreatDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_THREAT_DESC',
  IdSessionsByInquiryIdMaxLocationAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_LOCATION_ASC',
  IdSessionsByInquiryIdMaxLocationDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_LOCATION_DESC',
  IdSessionsByInquiryIdMaxMapsAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_MAPS_ASC',
  IdSessionsByInquiryIdMaxMapsDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_MAPS_DESC',
  IdSessionsByInquiryIdMaxDeviceAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_DEVICE_ASC',
  IdSessionsByInquiryIdMaxDeviceDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_DEVICE_DESC',
  IdSessionsByInquiryIdMaxNotesAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_NOTES_ASC',
  IdSessionsByInquiryIdMaxNotesDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_NOTES_DESC',
  IdSessionsByInquiryIdMaxStartedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_STARTED_AT_ASC',
  IdSessionsByInquiryIdMaxStartedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_STARTED_AT_DESC',
  IdSessionsByInquiryIdMaxCreatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_CREATED_AT_ASC',
  IdSessionsByInquiryIdMaxCreatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_CREATED_AT_DESC',
  IdSessionsByInquiryIdMaxUpdatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_UPDATED_AT_ASC',
  IdSessionsByInquiryIdMaxUpdatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_UPDATED_AT_DESC',
  IdSessionsByInquiryIdMaxDeletedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_DELETED_AT_ASC',
  IdSessionsByInquiryIdMaxDeletedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_DELETED_AT_DESC',
  IdSessionsByInquiryIdMaxIdSessionIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_ID_SESSION_ID_ASC',
  IdSessionsByInquiryIdMaxIdSessionIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_MAX_ID_SESSION_ID_DESC',
  IdSessionsByInquiryIdAverageIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_ID_ASC',
  IdSessionsByInquiryIdAverageIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_ID_DESC',
  IdSessionsByInquiryIdAverageInquiryIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_INQUIRY_ID_ASC',
  IdSessionsByInquiryIdAverageInquiryIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_INQUIRY_ID_DESC',
  IdSessionsByInquiryIdAverageThreatAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_THREAT_ASC',
  IdSessionsByInquiryIdAverageThreatDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_THREAT_DESC',
  IdSessionsByInquiryIdAverageLocationAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_LOCATION_ASC',
  IdSessionsByInquiryIdAverageLocationDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_LOCATION_DESC',
  IdSessionsByInquiryIdAverageMapsAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_MAPS_ASC',
  IdSessionsByInquiryIdAverageMapsDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_MAPS_DESC',
  IdSessionsByInquiryIdAverageDeviceAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_DEVICE_ASC',
  IdSessionsByInquiryIdAverageDeviceDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_DEVICE_DESC',
  IdSessionsByInquiryIdAverageNotesAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_NOTES_ASC',
  IdSessionsByInquiryIdAverageNotesDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_NOTES_DESC',
  IdSessionsByInquiryIdAverageStartedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_STARTED_AT_ASC',
  IdSessionsByInquiryIdAverageStartedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_STARTED_AT_DESC',
  IdSessionsByInquiryIdAverageCreatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_CREATED_AT_ASC',
  IdSessionsByInquiryIdAverageCreatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_CREATED_AT_DESC',
  IdSessionsByInquiryIdAverageUpdatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_UPDATED_AT_ASC',
  IdSessionsByInquiryIdAverageUpdatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_UPDATED_AT_DESC',
  IdSessionsByInquiryIdAverageDeletedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_DELETED_AT_ASC',
  IdSessionsByInquiryIdAverageDeletedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_DELETED_AT_DESC',
  IdSessionsByInquiryIdAverageIdSessionIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_ID_SESSION_ID_ASC',
  IdSessionsByInquiryIdAverageIdSessionIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_AVERAGE_ID_SESSION_ID_DESC',
  IdSessionsByInquiryIdStddevSampleIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ID_ASC',
  IdSessionsByInquiryIdStddevSampleIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ID_DESC',
  IdSessionsByInquiryIdStddevSampleInquiryIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_INQUIRY_ID_ASC',
  IdSessionsByInquiryIdStddevSampleInquiryIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_INQUIRY_ID_DESC',
  IdSessionsByInquiryIdStddevSampleThreatAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_THREAT_ASC',
  IdSessionsByInquiryIdStddevSampleThreatDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_THREAT_DESC',
  IdSessionsByInquiryIdStddevSampleLocationAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_LOCATION_ASC',
  IdSessionsByInquiryIdStddevSampleLocationDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_LOCATION_DESC',
  IdSessionsByInquiryIdStddevSampleMapsAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_MAPS_ASC',
  IdSessionsByInquiryIdStddevSampleMapsDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_MAPS_DESC',
  IdSessionsByInquiryIdStddevSampleDeviceAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_DEVICE_ASC',
  IdSessionsByInquiryIdStddevSampleDeviceDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_DEVICE_DESC',
  IdSessionsByInquiryIdStddevSampleNotesAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_NOTES_ASC',
  IdSessionsByInquiryIdStddevSampleNotesDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_NOTES_DESC',
  IdSessionsByInquiryIdStddevSampleStartedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_STARTED_AT_ASC',
  IdSessionsByInquiryIdStddevSampleStartedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_STARTED_AT_DESC',
  IdSessionsByInquiryIdStddevSampleCreatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  IdSessionsByInquiryIdStddevSampleCreatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  IdSessionsByInquiryIdStddevSampleUpdatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  IdSessionsByInquiryIdStddevSampleUpdatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  IdSessionsByInquiryIdStddevSampleDeletedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_DELETED_AT_ASC',
  IdSessionsByInquiryIdStddevSampleDeletedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_DELETED_AT_DESC',
  IdSessionsByInquiryIdStddevSampleIdSessionIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ID_SESSION_ID_ASC',
  IdSessionsByInquiryIdStddevSampleIdSessionIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_SAMPLE_ID_SESSION_ID_DESC',
  IdSessionsByInquiryIdStddevPopulationIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ID_ASC',
  IdSessionsByInquiryIdStddevPopulationIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ID_DESC',
  IdSessionsByInquiryIdStddevPopulationInquiryIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_INQUIRY_ID_ASC',
  IdSessionsByInquiryIdStddevPopulationInquiryIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_INQUIRY_ID_DESC',
  IdSessionsByInquiryIdStddevPopulationThreatAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_THREAT_ASC',
  IdSessionsByInquiryIdStddevPopulationThreatDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_THREAT_DESC',
  IdSessionsByInquiryIdStddevPopulationLocationAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_LOCATION_ASC',
  IdSessionsByInquiryIdStddevPopulationLocationDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_LOCATION_DESC',
  IdSessionsByInquiryIdStddevPopulationMapsAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_MAPS_ASC',
  IdSessionsByInquiryIdStddevPopulationMapsDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_MAPS_DESC',
  IdSessionsByInquiryIdStddevPopulationDeviceAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_DEVICE_ASC',
  IdSessionsByInquiryIdStddevPopulationDeviceDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_DEVICE_DESC',
  IdSessionsByInquiryIdStddevPopulationNotesAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_NOTES_ASC',
  IdSessionsByInquiryIdStddevPopulationNotesDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_NOTES_DESC',
  IdSessionsByInquiryIdStddevPopulationStartedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_STARTED_AT_ASC',
  IdSessionsByInquiryIdStddevPopulationStartedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_STARTED_AT_DESC',
  IdSessionsByInquiryIdStddevPopulationCreatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  IdSessionsByInquiryIdStddevPopulationCreatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  IdSessionsByInquiryIdStddevPopulationUpdatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  IdSessionsByInquiryIdStddevPopulationUpdatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  IdSessionsByInquiryIdStddevPopulationDeletedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_DELETED_AT_ASC',
  IdSessionsByInquiryIdStddevPopulationDeletedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_DELETED_AT_DESC',
  IdSessionsByInquiryIdStddevPopulationIdSessionIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ID_SESSION_ID_ASC',
  IdSessionsByInquiryIdStddevPopulationIdSessionIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_STDDEV_POPULATION_ID_SESSION_ID_DESC',
  IdSessionsByInquiryIdVarianceSampleIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ID_ASC',
  IdSessionsByInquiryIdVarianceSampleIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ID_DESC',
  IdSessionsByInquiryIdVarianceSampleInquiryIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_INQUIRY_ID_ASC',
  IdSessionsByInquiryIdVarianceSampleInquiryIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_INQUIRY_ID_DESC',
  IdSessionsByInquiryIdVarianceSampleThreatAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_THREAT_ASC',
  IdSessionsByInquiryIdVarianceSampleThreatDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_THREAT_DESC',
  IdSessionsByInquiryIdVarianceSampleLocationAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_LOCATION_ASC',
  IdSessionsByInquiryIdVarianceSampleLocationDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_LOCATION_DESC',
  IdSessionsByInquiryIdVarianceSampleMapsAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_MAPS_ASC',
  IdSessionsByInquiryIdVarianceSampleMapsDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_MAPS_DESC',
  IdSessionsByInquiryIdVarianceSampleDeviceAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_DEVICE_ASC',
  IdSessionsByInquiryIdVarianceSampleDeviceDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_DEVICE_DESC',
  IdSessionsByInquiryIdVarianceSampleNotesAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_NOTES_ASC',
  IdSessionsByInquiryIdVarianceSampleNotesDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_NOTES_DESC',
  IdSessionsByInquiryIdVarianceSampleStartedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_STARTED_AT_ASC',
  IdSessionsByInquiryIdVarianceSampleStartedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_STARTED_AT_DESC',
  IdSessionsByInquiryIdVarianceSampleCreatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  IdSessionsByInquiryIdVarianceSampleCreatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  IdSessionsByInquiryIdVarianceSampleUpdatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  IdSessionsByInquiryIdVarianceSampleUpdatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdSessionsByInquiryIdVarianceSampleDeletedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_DELETED_AT_ASC',
  IdSessionsByInquiryIdVarianceSampleDeletedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_DELETED_AT_DESC',
  IdSessionsByInquiryIdVarianceSampleIdSessionIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ID_SESSION_ID_ASC',
  IdSessionsByInquiryIdVarianceSampleIdSessionIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_SAMPLE_ID_SESSION_ID_DESC',
  IdSessionsByInquiryIdVariancePopulationIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ID_ASC',
  IdSessionsByInquiryIdVariancePopulationIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ID_DESC',
  IdSessionsByInquiryIdVariancePopulationInquiryIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_INQUIRY_ID_ASC',
  IdSessionsByInquiryIdVariancePopulationInquiryIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_INQUIRY_ID_DESC',
  IdSessionsByInquiryIdVariancePopulationThreatAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_THREAT_ASC',
  IdSessionsByInquiryIdVariancePopulationThreatDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_THREAT_DESC',
  IdSessionsByInquiryIdVariancePopulationLocationAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_LOCATION_ASC',
  IdSessionsByInquiryIdVariancePopulationLocationDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_LOCATION_DESC',
  IdSessionsByInquiryIdVariancePopulationMapsAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_MAPS_ASC',
  IdSessionsByInquiryIdVariancePopulationMapsDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_MAPS_DESC',
  IdSessionsByInquiryIdVariancePopulationDeviceAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_DEVICE_ASC',
  IdSessionsByInquiryIdVariancePopulationDeviceDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_DEVICE_DESC',
  IdSessionsByInquiryIdVariancePopulationNotesAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_NOTES_ASC',
  IdSessionsByInquiryIdVariancePopulationNotesDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_NOTES_DESC',
  IdSessionsByInquiryIdVariancePopulationStartedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_STARTED_AT_ASC',
  IdSessionsByInquiryIdVariancePopulationStartedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_STARTED_AT_DESC',
  IdSessionsByInquiryIdVariancePopulationCreatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  IdSessionsByInquiryIdVariancePopulationCreatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  IdSessionsByInquiryIdVariancePopulationUpdatedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  IdSessionsByInquiryIdVariancePopulationUpdatedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  IdSessionsByInquiryIdVariancePopulationDeletedAtAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_DELETED_AT_ASC',
  IdSessionsByInquiryIdVariancePopulationDeletedAtDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_DELETED_AT_DESC',
  IdSessionsByInquiryIdVariancePopulationIdSessionIdAsc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ID_SESSION_ID_ASC',
  IdSessionsByInquiryIdVariancePopulationIdSessionIdDesc = 'ID_SESSIONS_BY_INQUIRY_ID_VARIANCE_POPULATION_ID_SESSION_ID_DESC'
}

export type IdInquiry = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  integrationId: Scalars['Int'];
  status: IdStatus;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  rawInquiry?: Maybe<Scalars['JSON']>;
  inquiryId: Scalars['String'];
  /** Reads a single `Lead` that is related to this `IdInquiry`. */
  lead?: Maybe<Lead>;
  /** Reads a single `Integration` that is related to this `IdInquiry`. */
  integration?: Maybe<Integration>;
  /** Reads and enables pagination through a set of `IdInquiryVerification`. */
  idInquiryVerificationsByInquiryId: IdInquiryVerificationsConnection;
  /** Reads and enables pagination through a set of `IdSession`. */
  idSessionsByInquiryId: IdSessionsConnection;
};


export type IdInquiryIdInquiryVerificationsByInquiryIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiryVerificationsOrderBy>>;
  condition?: Maybe<IdInquiryVerificationCondition>;
  filter?: Maybe<IdInquiryVerificationFilter>;
};


export type IdInquiryIdSessionsByInquiryIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdSessionsOrderBy>>;
  condition?: Maybe<IdSessionCondition>;
  filter?: Maybe<IdSessionFilter>;
};

export type IdInquiryAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IdInquirySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IdInquiryDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IdInquiryMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IdInquiryMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IdInquiryAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IdInquiryStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IdInquiryStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IdInquiryVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IdInquiryVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `IdInquiry` object types. */
export type IdInquiryAggregatesFilter = {
  /** A filter that must pass for the relevant `IdInquiry` object to be included within the aggregate. */
  filter?: Maybe<IdInquiryFilter>;
  /** Sum aggregate over matching `IdInquiry` objects. */
  sum?: Maybe<IdInquirySumAggregateFilter>;
  /** Distinct count aggregate over matching `IdInquiry` objects. */
  distinctCount?: Maybe<IdInquiryDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `IdInquiry` objects. */
  min?: Maybe<IdInquiryMinAggregateFilter>;
  /** Maximum aggregate over matching `IdInquiry` objects. */
  max?: Maybe<IdInquiryMaxAggregateFilter>;
  /** Mean average aggregate over matching `IdInquiry` objects. */
  average?: Maybe<IdInquiryAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `IdInquiry` objects. */
  stddevSample?: Maybe<IdInquiryStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `IdInquiry` objects. */
  stddevPopulation?: Maybe<IdInquiryStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `IdInquiry` objects. */
  varianceSample?: Maybe<IdInquiryVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `IdInquiry` objects. */
  variancePopulation?: Maybe<IdInquiryVariancePopulationAggregateFilter>;
};

export type IdInquiryAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
};

export type IdInquiryAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `IdInquiry` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type IdInquiryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<IdStatus>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `address` field. */
  address?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `province` field. */
  province?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `postalCode` field. */
  postalCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `birthDate` field. */
  birthDate?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `idNumber` field. */
  idNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `rawInquiry` field. */
  rawInquiry?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `inquiryId` field. */
  inquiryId?: Maybe<Scalars['String']>;
};

export type IdInquiryDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
  status?: Maybe<BigIntFilter>;
  firstName?: Maybe<BigIntFilter>;
  lastName?: Maybe<BigIntFilter>;
  address?: Maybe<BigIntFilter>;
  city?: Maybe<BigIntFilter>;
  province?: Maybe<BigIntFilter>;
  postalCode?: Maybe<BigIntFilter>;
  birthDate?: Maybe<BigIntFilter>;
  idNumber?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  rawInquiry?: Maybe<BigIntFilter>;
  inquiryId?: Maybe<BigIntFilter>;
};

export type IdInquiryDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of address across the matching connection */
  address?: Maybe<Scalars['BigInt']>;
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars['BigInt']>;
  /** Distinct count of province across the matching connection */
  province?: Maybe<Scalars['BigInt']>;
  /** Distinct count of postalCode across the matching connection */
  postalCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of birthDate across the matching connection */
  birthDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of idNumber across the matching connection */
  idNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawInquiry across the matching connection */
  rawInquiry?: Maybe<Scalars['BigInt']>;
  /** Distinct count of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `IdInquiry` object types. All fields are combined with a logical ‘and.’ */
export type IdInquiryFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `integrationId` field. */
  integrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<IdStatusFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `address` field. */
  address?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: Maybe<StringFilter>;
  /** Filter by the object’s `province` field. */
  province?: Maybe<StringFilter>;
  /** Filter by the object’s `postalCode` field. */
  postalCode?: Maybe<StringFilter>;
  /** Filter by the object’s `birthDate` field. */
  birthDate?: Maybe<StringFilter>;
  /** Filter by the object’s `idNumber` field. */
  idNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `inquiryId` field. */
  inquiryId?: Maybe<StringFilter>;
  /** Filter by the object’s `idInquiryVerificationsByInquiryId` relation. */
  idInquiryVerificationsByInquiryId?: Maybe<IdInquiryToManyIdInquiryVerificationFilter>;
  /** Some related `idInquiryVerificationsByInquiryId` exist. */
  idInquiryVerificationsByInquiryIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `idSessionsByInquiryId` relation. */
  idSessionsByInquiryId?: Maybe<IdInquiryToManyIdSessionFilter>;
  /** Some related `idSessionsByInquiryId` exist. */
  idSessionsByInquiryIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Filter by the object’s `integration` relation. */
  integration?: Maybe<IntegrationFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IdInquiryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IdInquiryFilter>>;
  /** Negates the expression. */
  not?: Maybe<IdInquiryFilter>;
};

/** Grouping methods for `IdInquiry` for usage during aggregation. */
export enum IdInquiryGroupBy {
  LeadId = 'LEAD_ID',
  IntegrationId = 'INTEGRATION_ID',
  Status = 'STATUS',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Address = 'ADDRESS',
  City = 'CITY',
  Province = 'PROVINCE',
  PostalCode = 'POSTAL_CODE',
  BirthDate = 'BIRTH_DATE',
  IdNumber = 'ID_NUMBER',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  RawInquiry = 'RAW_INQUIRY',
  InquiryId = 'INQUIRY_ID'
}

export type IdInquiryHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `IdInquiry` aggregates. */
export type IdInquiryHavingInput = {
  AND?: Maybe<Array<IdInquiryHavingInput>>;
  OR?: Maybe<Array<IdInquiryHavingInput>>;
  sum?: Maybe<IdInquiryHavingSumInput>;
  distinctCount?: Maybe<IdInquiryHavingDistinctCountInput>;
  min?: Maybe<IdInquiryHavingMinInput>;
  max?: Maybe<IdInquiryHavingMaxInput>;
  average?: Maybe<IdInquiryHavingAverageInput>;
  stddevSample?: Maybe<IdInquiryHavingStddevSampleInput>;
  stddevPopulation?: Maybe<IdInquiryHavingStddevPopulationInput>;
  varianceSample?: Maybe<IdInquiryHavingVarianceSampleInput>;
  variancePopulation?: Maybe<IdInquiryHavingVariancePopulationInput>;
};

export type IdInquiryHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** The fields on `idInquiry` to look up the row to connect. */
export type IdInquiryIdInquiryPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `idInquiry` to look up the row to delete. */
export type IdInquiryIdInquiryPkeyDelete = {
  id: Scalars['Int'];
};

/** An input for mutations affecting `IdInquiry` */
export type IdInquiryInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  integrationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawInquiry?: Maybe<Scalars['JSON']>;
  inquiryId: Scalars['String'];
  leadToLeadId?: Maybe<IdInquiryLeadIdFkeyInput>;
  integrationToIntegrationId?: Maybe<IdInquiryIntegrationIdFkeyInput>;
  idInquiryVerificationsUsingId?: Maybe<IdInquiryVerificationInquiryIdFkeyInverseInput>;
  idSessionsUsingId?: Maybe<IdSessionInquiryIdFkeyInverseInput>;
};

/** The `idInquiry` to be created by this mutation. */
export type IdInquiryIntegrationIdFkeyIdInquiryCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  integrationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawInquiry?: Maybe<Scalars['JSON']>;
  inquiryId: Scalars['String'];
  leadToLeadId?: Maybe<IdInquiryLeadIdFkeyInput>;
  integrationToIntegrationId?: Maybe<IdInquiryIntegrationIdFkeyInput>;
  idInquiryVerificationsUsingId?: Maybe<IdInquiryVerificationInquiryIdFkeyInverseInput>;
  idSessionsUsingId?: Maybe<IdSessionInquiryIdFkeyInverseInput>;
};

/** Input for the nested mutation of `integration` in the `IdInquiryInput` mutation. */
export type IdInquiryIntegrationIdFkeyInput = {
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectById?: Maybe<IntegrationIntegrationPkeyConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectByName?: Maybe<IntegrationIntegrationNameUniqueConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectByNodeId?: Maybe<IntegrationNodeIdConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteById?: Maybe<IntegrationIntegrationPkeyDelete>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteByName?: Maybe<IntegrationIntegrationNameUniqueDelete>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateById?: Maybe<IntegrationOnIdInquiryForIdInquiryIntegrationIdFkeyUsingIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateByName?: Maybe<IntegrationOnIdInquiryForIdInquiryIntegrationIdFkeyUsingIntegrationNameUniqueUpdate>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateByNodeId?: Maybe<IdInquiryOnIdInquiryForIdInquiryIntegrationIdFkeyNodeIdUpdate>;
  /** A `IntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<IdInquiryIntegrationIdFkeyIntegrationCreateInput>;
};

/** The `integration` to be created by this mutation. */
export type IdInquiryIntegrationIdFkeyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `idInquiry` in the `IntegrationInput` mutation. */
export type IdInquiryIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `idInquiry` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  connectById?: Maybe<Array<IdInquiryIdInquiryPkeyConnect>>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IdInquiryNodeIdConnect>>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  deleteById?: Maybe<Array<IdInquiryIdInquiryPkeyDelete>>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IdInquiryNodeIdDelete>>;
  /** The primary key(s) and patch data for `idInquiry` for the far side of the relationship. */
  updateById?: Maybe<Array<IdInquiryOnIdInquiryForIdInquiryIntegrationIdFkeyUsingIdInquiryPkeyUpdate>>;
  /** The primary key(s) and patch data for `idInquiry` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<IntegrationOnIdInquiryForIdInquiryIntegrationIdFkeyNodeIdUpdate>>;
  /** A `IdInquiryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IdInquiryIntegrationIdFkeyIdInquiryCreateInput>>;
};

/** The `idInquiry` to be created by this mutation. */
export type IdInquiryLeadIdFkeyIdInquiryCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  integrationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawInquiry?: Maybe<Scalars['JSON']>;
  inquiryId: Scalars['String'];
  leadToLeadId?: Maybe<IdInquiryLeadIdFkeyInput>;
  integrationToIntegrationId?: Maybe<IdInquiryIntegrationIdFkeyInput>;
  idInquiryVerificationsUsingId?: Maybe<IdInquiryVerificationInquiryIdFkeyInverseInput>;
  idSessionsUsingId?: Maybe<IdSessionInquiryIdFkeyInverseInput>;
};

/** Input for the nested mutation of `lead` in the `IdInquiryInput` mutation. */
export type IdInquiryLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnIdInquiryForIdInquiryLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<IdInquiryOnIdInquiryForIdInquiryLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<IdInquiryLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `idInquiry` in the `LeadInput` mutation. */
export type IdInquiryLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `idInquiry` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  connectById?: Maybe<Array<IdInquiryIdInquiryPkeyConnect>>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IdInquiryNodeIdConnect>>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  deleteById?: Maybe<Array<IdInquiryIdInquiryPkeyDelete>>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IdInquiryNodeIdDelete>>;
  /** The primary key(s) and patch data for `idInquiry` for the far side of the relationship. */
  updateById?: Maybe<Array<IdInquiryOnIdInquiryForIdInquiryLeadIdFkeyUsingIdInquiryPkeyUpdate>>;
  /** The primary key(s) and patch data for `idInquiry` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnIdInquiryForIdInquiryLeadIdFkeyNodeIdUpdate>>;
  /** A `IdInquiryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IdInquiryLeadIdFkeyIdInquiryCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type IdInquiryLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export type IdInquiryMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
};

export type IdInquiryMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
};

export type IdInquiryMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
};

export type IdInquiryMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type IdInquiryNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `idInquiry` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IdInquiryNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `idInquiry` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdInquiryOnIdInquiryForIdInquiryIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `integration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: IntegrationPatch;
};

/** The fields on `idInquiry` to look up the row to update. */
export type IdInquiryOnIdInquiryForIdInquiryIntegrationIdFkeyUsingIdInquiryPkeyUpdate = {
  /** An object where the defined keys will be set on the `idInquiry` being updated. */
  patch: UpdateIdInquiryOnIdInquiryForIdInquiryIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdInquiryOnIdInquiryForIdInquiryLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `idInquiry` to look up the row to update. */
export type IdInquiryOnIdInquiryForIdInquiryLeadIdFkeyUsingIdInquiryPkeyUpdate = {
  /** An object where the defined keys will be set on the `idInquiry` being updated. */
  patch: UpdateIdInquiryOnIdInquiryForIdInquiryLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdInquiryOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idInquiryVerification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idInquiryVerification` being updated. */
  patch: IdInquiryVerificationPatch;
};

/** The fields on `idInquiry` to look up the row to update. */
export type IdInquiryOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyUsingIdInquiryPkeyUpdate = {
  /** An object where the defined keys will be set on the `idInquiry` being updated. */
  patch: UpdateIdInquiryOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdInquiryOnIdSessionForIdSessionInquiryIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idSession` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idSession` being updated. */
  patch: IdSessionPatch;
};

/** The fields on `idInquiry` to look up the row to update. */
export type IdInquiryOnIdSessionForIdSessionInquiryIdFkeyUsingIdInquiryPkeyUpdate = {
  /** An object where the defined keys will be set on the `idInquiry` being updated. */
  patch: UpdateIdInquiryOnIdSessionForIdSessionInquiryIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `IdInquiry`. Fields that are set will be updated. */
export type IdInquiryPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  integrationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawInquiry?: Maybe<Scalars['JSON']>;
  inquiryId?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IdInquiryLeadIdFkeyInput>;
  integrationToIntegrationId?: Maybe<IdInquiryIntegrationIdFkeyInput>;
  idInquiryVerificationsUsingId?: Maybe<IdInquiryVerificationInquiryIdFkeyInverseInput>;
  idSessionsUsingId?: Maybe<IdSessionInquiryIdFkeyInverseInput>;
};

export type IdInquiryStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
};

export type IdInquiryStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type IdInquiryStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
};

export type IdInquiryStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type IdInquirySumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
};

export type IdInquirySumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of integrationId across the matching connection */
  integrationId: Scalars['BigInt'];
};

/** A filter to be used against many `IdInquiryVerification` object types. All fields are combined with a logical ‘and.’ */
export type IdInquiryToManyIdInquiryVerificationFilter = {
  /** Every related `IdInquiryVerification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<IdInquiryVerificationFilter>;
  /** Some related `IdInquiryVerification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<IdInquiryVerificationFilter>;
  /** No related `IdInquiryVerification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<IdInquiryVerificationFilter>;
  /** Aggregates across related `IdInquiryVerification` match the filter criteria. */
  aggregates?: Maybe<IdInquiryVerificationAggregatesFilter>;
};

/** A filter to be used against many `IdSession` object types. All fields are combined with a logical ‘and.’ */
export type IdInquiryToManyIdSessionFilter = {
  /** Every related `IdSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<IdSessionFilter>;
  /** Some related `IdSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<IdSessionFilter>;
  /** No related `IdSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<IdSessionFilter>;
  /** Aggregates across related `IdSession` match the filter criteria. */
  aggregates?: Maybe<IdSessionAggregatesFilter>;
};

export type IdInquiryVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type IdInquiryVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type IdInquiryVerification = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  inquiryId?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  status: IdStatus;
  reasons?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  issueAuthority?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationId?: Maybe<Scalars['String']>;
  /** Reads a single `IdInquiry` that is related to this `IdInquiryVerification`. */
  inquiry?: Maybe<IdInquiry>;
  /** Reads and enables pagination through a set of `IdMedia`. */
  idMediasByVerificationId: IdMediaConnection;
  /** Reads and enables pagination through a set of `IdInquiryVerificationCheck`. */
  idInquiryVerificationChecksByVerificationId: IdInquiryVerificationChecksConnection;
  /** Reads and enables pagination through a set of `Media`. */
  mediaByIdMediaVerificationIdAndMediaId: IdInquiryVerificationMediaByIdMediaVerificationIdAndMediaIdManyToManyConnection;
  /** Reads and enables pagination through a set of `VerificationCheck`. */
  verificationChecksByIdInquiryVerificationCheckVerificationIdAndVerificationCheckId: IdInquiryVerificationVerificationChecksByIdInquiryVerificationCheckVerificationIdAndVerificationCheckIdManyToManyConnection;
};


export type IdInquiryVerificationIdMediasByVerificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdMediaOrderBy>>;
  condition?: Maybe<IdMediaCondition>;
  filter?: Maybe<IdMediaFilter>;
};


export type IdInquiryVerificationIdInquiryVerificationChecksByVerificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiryVerificationChecksOrderBy>>;
  condition?: Maybe<IdInquiryVerificationCheckCondition>;
  filter?: Maybe<IdInquiryVerificationCheckFilter>;
};


export type IdInquiryVerificationMediaByIdMediaVerificationIdAndMediaIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaOrderBy>>;
  condition?: Maybe<MediaCondition>;
  filter?: Maybe<MediaFilter>;
};


export type IdInquiryVerificationVerificationChecksByIdInquiryVerificationCheckVerificationIdAndVerificationCheckIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VerificationChecksOrderBy>>;
  condition?: Maybe<VerificationCheckCondition>;
  filter?: Maybe<VerificationCheckFilter>;
};

export type IdInquiryVerificationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IdInquiryVerificationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IdInquiryVerificationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IdInquiryVerificationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IdInquiryVerificationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IdInquiryVerificationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IdInquiryVerificationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IdInquiryVerificationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IdInquiryVerificationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IdInquiryVerificationVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `IdInquiryVerification` object types. */
export type IdInquiryVerificationAggregatesFilter = {
  /** A filter that must pass for the relevant `IdInquiryVerification` object to be included within the aggregate. */
  filter?: Maybe<IdInquiryVerificationFilter>;
  /** Sum aggregate over matching `IdInquiryVerification` objects. */
  sum?: Maybe<IdInquiryVerificationSumAggregateFilter>;
  /** Distinct count aggregate over matching `IdInquiryVerification` objects. */
  distinctCount?: Maybe<IdInquiryVerificationDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `IdInquiryVerification` objects. */
  min?: Maybe<IdInquiryVerificationMinAggregateFilter>;
  /** Maximum aggregate over matching `IdInquiryVerification` objects. */
  max?: Maybe<IdInquiryVerificationMaxAggregateFilter>;
  /** Mean average aggregate over matching `IdInquiryVerification` objects. */
  average?: Maybe<IdInquiryVerificationAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `IdInquiryVerification` objects. */
  stddevSample?: Maybe<IdInquiryVerificationStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `IdInquiryVerification` objects. */
  stddevPopulation?: Maybe<IdInquiryVerificationStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `IdInquiryVerification` objects. */
  varianceSample?: Maybe<IdInquiryVerificationVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `IdInquiryVerification` objects. */
  variancePopulation?: Maybe<IdInquiryVerificationVariancePopulationAggregateFilter>;
};

export type IdInquiryVerificationAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inquiryId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVerificationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigFloat']>;
};

export type IdInquiryVerificationCheck = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  verificationId?: Maybe<Scalars['Int']>;
  status: IdStatus;
  reasons: Array<Maybe<Scalars['String']>>;
  required: Scalars['Boolean'];
  metadata?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  verificationCheckId?: Maybe<Scalars['Int']>;
  /** Reads a single `IdInquiryVerification` that is related to this `IdInquiryVerificationCheck`. */
  verification?: Maybe<IdInquiryVerification>;
  /** Reads a single `VerificationCheck` that is related to this `IdInquiryVerificationCheck`. */
  verificationCheck?: Maybe<VerificationCheck>;
};

export type IdInquiryVerificationCheckAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IdInquiryVerificationCheckSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IdInquiryVerificationCheckDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IdInquiryVerificationCheckMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IdInquiryVerificationCheckMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IdInquiryVerificationCheckAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IdInquiryVerificationCheckStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IdInquiryVerificationCheckStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IdInquiryVerificationCheckVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IdInquiryVerificationCheckVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `IdInquiryVerificationCheck` object types. */
export type IdInquiryVerificationCheckAggregatesFilter = {
  /** A filter that must pass for the relevant `IdInquiryVerificationCheck` object to be included within the aggregate. */
  filter?: Maybe<IdInquiryVerificationCheckFilter>;
  /** Sum aggregate over matching `IdInquiryVerificationCheck` objects. */
  sum?: Maybe<IdInquiryVerificationCheckSumAggregateFilter>;
  /** Distinct count aggregate over matching `IdInquiryVerificationCheck` objects. */
  distinctCount?: Maybe<IdInquiryVerificationCheckDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `IdInquiryVerificationCheck` objects. */
  min?: Maybe<IdInquiryVerificationCheckMinAggregateFilter>;
  /** Maximum aggregate over matching `IdInquiryVerificationCheck` objects. */
  max?: Maybe<IdInquiryVerificationCheckMaxAggregateFilter>;
  /** Mean average aggregate over matching `IdInquiryVerificationCheck` objects. */
  average?: Maybe<IdInquiryVerificationCheckAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `IdInquiryVerificationCheck` objects. */
  stddevSample?: Maybe<IdInquiryVerificationCheckStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `IdInquiryVerificationCheck` objects. */
  stddevPopulation?: Maybe<IdInquiryVerificationCheckStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `IdInquiryVerificationCheck` objects. */
  varianceSample?: Maybe<IdInquiryVerificationCheckVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `IdInquiryVerificationCheck` objects. */
  variancePopulation?: Maybe<IdInquiryVerificationCheckVariancePopulationAggregateFilter>;
};

export type IdInquiryVerificationCheckAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  verificationId?: Maybe<BigFloatFilter>;
  verificationCheckId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVerificationCheckAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of verificationCheckId across the matching connection */
  verificationCheckId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `IdInquiryVerificationCheck` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type IdInquiryVerificationCheckCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `verificationId` field. */
  verificationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<IdStatus>;
  /** Checks for equality with the object’s `reasons` field. */
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `required` field. */
  required?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `verificationCheckId` field. */
  verificationCheckId?: Maybe<Scalars['Int']>;
};

export type IdInquiryVerificationCheckDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  verificationId?: Maybe<BigIntFilter>;
  status?: Maybe<BigIntFilter>;
  reasons?: Maybe<BigIntFilter>;
  required?: Maybe<BigIntFilter>;
  metadata?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  deletedAt?: Maybe<BigIntFilter>;
  verificationCheckId?: Maybe<BigIntFilter>;
};

export type IdInquiryVerificationCheckDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']>;
  /** Distinct count of reasons across the matching connection */
  reasons?: Maybe<Scalars['BigInt']>;
  /** Distinct count of required across the matching connection */
  required?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verificationCheckId across the matching connection */
  verificationCheckId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `IdInquiryVerificationCheck` object types. All fields are combined with a logical ‘and.’ */
export type IdInquiryVerificationCheckFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `verificationId` field. */
  verificationId?: Maybe<IntFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<IdStatusFilter>;
  /** Filter by the object’s `reasons` field. */
  reasons?: Maybe<StringListFilter>;
  /** Filter by the object’s `required` field. */
  required?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `verificationCheckId` field. */
  verificationCheckId?: Maybe<IntFilter>;
  /** Filter by the object’s `verification` relation. */
  verification?: Maybe<IdInquiryVerificationFilter>;
  /** A related `verification` exists. */
  verificationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `verificationCheck` relation. */
  verificationCheck?: Maybe<VerificationCheckFilter>;
  /** A related `verificationCheck` exists. */
  verificationCheckExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IdInquiryVerificationCheckFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IdInquiryVerificationCheckFilter>>;
  /** Negates the expression. */
  not?: Maybe<IdInquiryVerificationCheckFilter>;
};

/** Grouping methods for `IdInquiryVerificationCheck` for usage during aggregation. */
export enum IdInquiryVerificationCheckGroupBy {
  VerificationId = 'VERIFICATION_ID',
  Status = 'STATUS',
  Reasons = 'REASONS',
  Required = 'REQUIRED',
  Metadata = 'METADATA',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  DeletedAt = 'DELETED_AT',
  DeletedAtTruncatedToHour = 'DELETED_AT_TRUNCATED_TO_HOUR',
  DeletedAtTruncatedToDay = 'DELETED_AT_TRUNCATED_TO_DAY',
  VerificationCheckId = 'VERIFICATION_CHECK_ID'
}

export type IdInquiryVerificationCheckHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
  verificationCheckId?: Maybe<HavingIntFilter>;
};

export type IdInquiryVerificationCheckHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
  verificationCheckId?: Maybe<HavingIntFilter>;
};

/** Conditions for `IdInquiryVerificationCheck` aggregates. */
export type IdInquiryVerificationCheckHavingInput = {
  AND?: Maybe<Array<IdInquiryVerificationCheckHavingInput>>;
  OR?: Maybe<Array<IdInquiryVerificationCheckHavingInput>>;
  sum?: Maybe<IdInquiryVerificationCheckHavingSumInput>;
  distinctCount?: Maybe<IdInquiryVerificationCheckHavingDistinctCountInput>;
  min?: Maybe<IdInquiryVerificationCheckHavingMinInput>;
  max?: Maybe<IdInquiryVerificationCheckHavingMaxInput>;
  average?: Maybe<IdInquiryVerificationCheckHavingAverageInput>;
  stddevSample?: Maybe<IdInquiryVerificationCheckHavingStddevSampleInput>;
  stddevPopulation?: Maybe<IdInquiryVerificationCheckHavingStddevPopulationInput>;
  varianceSample?: Maybe<IdInquiryVerificationCheckHavingVarianceSampleInput>;
  variancePopulation?: Maybe<IdInquiryVerificationCheckHavingVariancePopulationInput>;
};

export type IdInquiryVerificationCheckHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
  verificationCheckId?: Maybe<HavingIntFilter>;
};

export type IdInquiryVerificationCheckHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
  verificationCheckId?: Maybe<HavingIntFilter>;
};

export type IdInquiryVerificationCheckHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
  verificationCheckId?: Maybe<HavingIntFilter>;
};

export type IdInquiryVerificationCheckHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
  verificationCheckId?: Maybe<HavingIntFilter>;
};

export type IdInquiryVerificationCheckHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
  verificationCheckId?: Maybe<HavingIntFilter>;
};

export type IdInquiryVerificationCheckHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
  verificationCheckId?: Maybe<HavingIntFilter>;
};

export type IdInquiryVerificationCheckHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
  verificationCheckId?: Maybe<HavingIntFilter>;
};

/** The fields on `idInquiryVerificationCheck` to look up the row to connect. */
export type IdInquiryVerificationCheckIdInquiryVerificationCheckPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `idInquiryVerificationCheck` to look up the row to delete. */
export type IdInquiryVerificationCheckIdInquiryVerificationCheckPkeyDelete = {
  id: Scalars['Int'];
};

/** An input for mutations affecting `IdInquiryVerificationCheck` */
export type IdInquiryVerificationCheckInput = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  required?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  verificationCheckId?: Maybe<Scalars['Int']>;
  idInquiryVerificationToVerificationId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInput>;
  verificationCheckToVerificationCheckId?: Maybe<IdInquiryVerificationCheckVerificationCheckIdFkeyInput>;
};

export type IdInquiryVerificationCheckMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  verificationId?: Maybe<IntFilter>;
  verificationCheckId?: Maybe<IntFilter>;
};

export type IdInquiryVerificationCheckMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['Int']>;
  /** Maximum of verificationCheckId across the matching connection */
  verificationCheckId?: Maybe<Scalars['Int']>;
};

export type IdInquiryVerificationCheckMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  verificationId?: Maybe<IntFilter>;
  verificationCheckId?: Maybe<IntFilter>;
};

export type IdInquiryVerificationCheckMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['Int']>;
  /** Minimum of verificationCheckId across the matching connection */
  verificationCheckId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type IdInquiryVerificationCheckNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `idInquiryVerificationCheck` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IdInquiryVerificationCheckNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `idInquiryVerificationCheck` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `verificationCheck` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `verificationCheck` being updated. */
  patch: VerificationCheckPatch;
};

/** The fields on `idInquiryVerificationCheck` to look up the row to update. */
export type IdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyUsingIdInquiryVerificationCheckPkeyUpdate = {
  /** An object where the defined keys will be set on the `idInquiryVerificationCheck` being updated. */
  patch: UpdateIdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idInquiryVerification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idInquiryVerification` being updated. */
  patch: IdInquiryVerificationPatch;
};

/** The fields on `idInquiryVerificationCheck` to look up the row to update. */
export type IdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyUsingIdInquiryVerificationCheckPkeyUpdate = {
  /** An object where the defined keys will be set on the `idInquiryVerificationCheck` being updated. */
  patch: UpdateIdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `IdInquiryVerificationCheck`. Fields that are set will be updated. */
export type IdInquiryVerificationCheckPatch = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  required?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  verificationCheckId?: Maybe<Scalars['Int']>;
  idInquiryVerificationToVerificationId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInput>;
  verificationCheckToVerificationCheckId?: Maybe<IdInquiryVerificationCheckVerificationCheckIdFkeyInput>;
};

export type IdInquiryVerificationCheckStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  verificationId?: Maybe<BigFloatFilter>;
  verificationCheckId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVerificationCheckStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of verificationCheckId across the matching connection */
  verificationCheckId?: Maybe<Scalars['BigFloat']>;
};

export type IdInquiryVerificationCheckStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  verificationId?: Maybe<BigFloatFilter>;
  verificationCheckId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVerificationCheckStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of verificationCheckId across the matching connection */
  verificationCheckId?: Maybe<Scalars['BigFloat']>;
};

export type IdInquiryVerificationCheckSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  verificationId?: Maybe<BigIntFilter>;
  verificationCheckId?: Maybe<BigIntFilter>;
};

export type IdInquiryVerificationCheckSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of verificationId across the matching connection */
  verificationId: Scalars['BigInt'];
  /** Sum of verificationCheckId across the matching connection */
  verificationCheckId: Scalars['BigInt'];
};

export type IdInquiryVerificationCheckVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  verificationId?: Maybe<BigFloatFilter>;
  verificationCheckId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVerificationCheckVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of verificationCheckId across the matching connection */
  verificationCheckId?: Maybe<Scalars['BigFloat']>;
};

export type IdInquiryVerificationCheckVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  verificationId?: Maybe<BigFloatFilter>;
  verificationCheckId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVerificationCheckVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of verificationCheckId across the matching connection */
  verificationCheckId?: Maybe<Scalars['BigFloat']>;
};

/** The `idInquiryVerificationCheck` to be created by this mutation. */
export type IdInquiryVerificationCheckVerificationCheckIdFkeyIdInquiryVerificationCheckCreateInput = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  required?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  verificationCheckId?: Maybe<Scalars['Int']>;
  idInquiryVerificationToVerificationId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInput>;
  verificationCheckToVerificationCheckId?: Maybe<IdInquiryVerificationCheckVerificationCheckIdFkeyInput>;
};

/** Input for the nested mutation of `verificationCheck` in the `IdInquiryVerificationCheckInput` mutation. */
export type IdInquiryVerificationCheckVerificationCheckIdFkeyInput = {
  /** The primary key(s) for `verificationCheck` for the far side of the relationship. */
  connectById?: Maybe<VerificationCheckVerificationCheckPkeyConnect>;
  /** The primary key(s) for `verificationCheck` for the far side of the relationship. */
  connectByName?: Maybe<VerificationCheckVerificationCheckUniqueNameConnect>;
  /** The primary key(s) for `verificationCheck` for the far side of the relationship. */
  connectByNodeId?: Maybe<VerificationCheckNodeIdConnect>;
  /** The primary key(s) for `verificationCheck` for the far side of the relationship. */
  deleteById?: Maybe<VerificationCheckVerificationCheckPkeyDelete>;
  /** The primary key(s) for `verificationCheck` for the far side of the relationship. */
  deleteByName?: Maybe<VerificationCheckVerificationCheckUniqueNameDelete>;
  /** The primary key(s) for `verificationCheck` for the far side of the relationship. */
  deleteByNodeId?: Maybe<VerificationCheckNodeIdDelete>;
  /** The primary key(s) and patch data for `verificationCheck` for the far side of the relationship. */
  updateById?: Maybe<VerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyUsingVerificationCheckPkeyUpdate>;
  /** The primary key(s) and patch data for `verificationCheck` for the far side of the relationship. */
  updateByName?: Maybe<VerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyUsingVerificationCheckUniqueNameUpdate>;
  /** The primary key(s) and patch data for `verificationCheck` for the far side of the relationship. */
  updateByNodeId?: Maybe<IdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyNodeIdUpdate>;
  /** A `VerificationCheckInput` object that will be created and connected to this object. */
  create?: Maybe<IdInquiryVerificationCheckVerificationCheckIdFkeyVerificationCheckCreateInput>;
};

/** Input for the nested mutation of `idInquiryVerificationCheck` in the `VerificationCheckInput` mutation. */
export type IdInquiryVerificationCheckVerificationCheckIdFkeyInverseInput = {
  /** Flag indicating whether all other `idInquiryVerificationCheck` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `idInquiryVerificationCheck` for the far side of the relationship. */
  connectById?: Maybe<Array<IdInquiryVerificationCheckIdInquiryVerificationCheckPkeyConnect>>;
  /** The primary key(s) for `idInquiryVerificationCheck` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IdInquiryVerificationCheckNodeIdConnect>>;
  /** The primary key(s) for `idInquiryVerificationCheck` for the far side of the relationship. */
  deleteById?: Maybe<Array<IdInquiryVerificationCheckIdInquiryVerificationCheckPkeyDelete>>;
  /** The primary key(s) for `idInquiryVerificationCheck` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IdInquiryVerificationCheckNodeIdDelete>>;
  /** The primary key(s) and patch data for `idInquiryVerificationCheck` for the far side of the relationship. */
  updateById?: Maybe<Array<IdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyUsingIdInquiryVerificationCheckPkeyUpdate>>;
  /** The primary key(s) and patch data for `idInquiryVerificationCheck` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<VerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyNodeIdUpdate>>;
  /** A `IdInquiryVerificationCheckInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IdInquiryVerificationCheckVerificationCheckIdFkeyIdInquiryVerificationCheckCreateInput>>;
};

/** The `verificationCheck` to be created by this mutation. */
export type IdInquiryVerificationCheckVerificationCheckIdFkeyVerificationCheckCreateInput = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationCheckIdFkeyInverseInput>;
};

/** The `idInquiryVerificationCheck` to be created by this mutation. */
export type IdInquiryVerificationCheckVerificationIdFkeyIdInquiryVerificationCheckCreateInput = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  required?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  verificationCheckId?: Maybe<Scalars['Int']>;
  idInquiryVerificationToVerificationId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInput>;
  verificationCheckToVerificationCheckId?: Maybe<IdInquiryVerificationCheckVerificationCheckIdFkeyInput>;
};

/** The `idInquiryVerification` to be created by this mutation. */
export type IdInquiryVerificationCheckVerificationIdFkeyIdInquiryVerificationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  inquiryId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  issueAuthority?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdInquiryVerificationInquiryIdFkeyInput>;
  idMediaUsingId?: Maybe<IdMediaVerificationIdFkeyInverseInput>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `idInquiryVerification` in the `IdInquiryVerificationCheckInput` mutation. */
export type IdInquiryVerificationCheckVerificationIdFkeyInput = {
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  connectById?: Maybe<IdInquiryVerificationIdInquiryVerificationPkeyConnect>;
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  connectByNodeId?: Maybe<IdInquiryVerificationNodeIdConnect>;
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  deleteById?: Maybe<IdInquiryVerificationIdInquiryVerificationPkeyDelete>;
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IdInquiryVerificationNodeIdDelete>;
  /** The primary key(s) and patch data for `idInquiryVerification` for the far side of the relationship. */
  updateById?: Maybe<IdInquiryVerificationOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyUsingIdInquiryVerificationPkeyUpdate>;
  /** The primary key(s) and patch data for `idInquiryVerification` for the far side of the relationship. */
  updateByNodeId?: Maybe<IdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyNodeIdUpdate>;
  /** A `IdInquiryVerificationInput` object that will be created and connected to this object. */
  create?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyIdInquiryVerificationCreateInput>;
};

/** Input for the nested mutation of `idInquiryVerificationCheck` in the `IdInquiryVerificationInput` mutation. */
export type IdInquiryVerificationCheckVerificationIdFkeyInverseInput = {
  /** Flag indicating whether all other `idInquiryVerificationCheck` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `idInquiryVerificationCheck` for the far side of the relationship. */
  connectById?: Maybe<Array<IdInquiryVerificationCheckIdInquiryVerificationCheckPkeyConnect>>;
  /** The primary key(s) for `idInquiryVerificationCheck` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IdInquiryVerificationCheckNodeIdConnect>>;
  /** The primary key(s) for `idInquiryVerificationCheck` for the far side of the relationship. */
  deleteById?: Maybe<Array<IdInquiryVerificationCheckIdInquiryVerificationCheckPkeyDelete>>;
  /** The primary key(s) for `idInquiryVerificationCheck` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IdInquiryVerificationCheckNodeIdDelete>>;
  /** The primary key(s) and patch data for `idInquiryVerificationCheck` for the far side of the relationship. */
  updateById?: Maybe<Array<IdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyUsingIdInquiryVerificationCheckPkeyUpdate>>;
  /** The primary key(s) and patch data for `idInquiryVerificationCheck` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<IdInquiryVerificationOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyNodeIdUpdate>>;
  /** A `IdInquiryVerificationCheckInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IdInquiryVerificationCheckVerificationIdFkeyIdInquiryVerificationCheckCreateInput>>;
};

/** A connection to a list of `IdInquiryVerificationCheck` values. */
export type IdInquiryVerificationChecksConnection = {
  /** A list of `IdInquiryVerificationCheck` objects. */
  nodes: Array<IdInquiryVerificationCheck>;
  /** A list of edges which contains the `IdInquiryVerificationCheck` and cursor to aid in pagination. */
  edges: Array<IdInquiryVerificationChecksEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdInquiryVerificationCheck` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IdInquiryVerificationCheckAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IdInquiryVerificationCheckAggregates>>;
};


/** A connection to a list of `IdInquiryVerificationCheck` values. */
export type IdInquiryVerificationChecksConnectionGroupedAggregatesArgs = {
  groupBy: Array<IdInquiryVerificationCheckGroupBy>;
  having?: Maybe<IdInquiryVerificationCheckHavingInput>;
};

/** A `IdInquiryVerificationCheck` edge in the connection. */
export type IdInquiryVerificationChecksEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdInquiryVerificationCheck` at the end of the edge. */
  node: IdInquiryVerificationCheck;
};

/** Methods to use when ordering `IdInquiryVerificationCheck`. */
export enum IdInquiryVerificationChecksOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  VerificationIdAsc = 'VERIFICATION_ID_ASC',
  VerificationIdDesc = 'VERIFICATION_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  ReasonsAsc = 'REASONS_ASC',
  ReasonsDesc = 'REASONS_DESC',
  RequiredAsc = 'REQUIRED_ASC',
  RequiredDesc = 'REQUIRED_DESC',
  MetadataAsc = 'METADATA_ASC',
  MetadataDesc = 'METADATA_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  VerificationCheckIdAsc = 'VERIFICATION_CHECK_ID_ASC',
  VerificationCheckIdDesc = 'VERIFICATION_CHECK_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `IdInquiryVerification` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type IdInquiryVerificationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `inquiryId` field. */
  inquiryId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<IdStatus>;
  /** Checks for equality with the object’s `reasons` field. */
  reasons?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `gender` field. */
  gender?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `address` field. */
  address?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `province` field. */
  province?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `postalCode` field. */
  postalCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `birthDate` field. */
  birthDate?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `idNumber` field. */
  idNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `issueAuthority` field. */
  issueAuthority?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `idInquiryVerificationId` field. */
  idInquiryVerificationId?: Maybe<Scalars['String']>;
};

export type IdInquiryVerificationDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  inquiryId?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  status?: Maybe<BigIntFilter>;
  reasons?: Maybe<BigIntFilter>;
  firstName?: Maybe<BigIntFilter>;
  lastName?: Maybe<BigIntFilter>;
  gender?: Maybe<BigIntFilter>;
  address?: Maybe<BigIntFilter>;
  city?: Maybe<BigIntFilter>;
  province?: Maybe<BigIntFilter>;
  postalCode?: Maybe<BigIntFilter>;
  birthDate?: Maybe<BigIntFilter>;
  idNumber?: Maybe<BigIntFilter>;
  issueAuthority?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  deletedAt?: Maybe<BigIntFilter>;
  idInquiryVerificationId?: Maybe<BigIntFilter>;
};

export type IdInquiryVerificationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']>;
  /** Distinct count of reasons across the matching connection */
  reasons?: Maybe<Scalars['BigInt']>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of gender across the matching connection */
  gender?: Maybe<Scalars['BigInt']>;
  /** Distinct count of address across the matching connection */
  address?: Maybe<Scalars['BigInt']>;
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars['BigInt']>;
  /** Distinct count of province across the matching connection */
  province?: Maybe<Scalars['BigInt']>;
  /** Distinct count of postalCode across the matching connection */
  postalCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of birthDate across the matching connection */
  birthDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of idNumber across the matching connection */
  idNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of issueAuthority across the matching connection */
  issueAuthority?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of idInquiryVerificationId across the matching connection */
  idInquiryVerificationId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `IdInquiryVerification` object types. All fields are combined with a logical ‘and.’ */
export type IdInquiryVerificationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `inquiryId` field. */
  inquiryId?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<IdStatusFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `gender` field. */
  gender?: Maybe<StringFilter>;
  /** Filter by the object’s `address` field. */
  address?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: Maybe<StringFilter>;
  /** Filter by the object’s `province` field. */
  province?: Maybe<StringFilter>;
  /** Filter by the object’s `postalCode` field. */
  postalCode?: Maybe<StringFilter>;
  /** Filter by the object’s `birthDate` field. */
  birthDate?: Maybe<StringFilter>;
  /** Filter by the object’s `idNumber` field. */
  idNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `issueAuthority` field. */
  issueAuthority?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `idInquiryVerificationId` field. */
  idInquiryVerificationId?: Maybe<StringFilter>;
  /** Filter by the object’s `idMediasByVerificationId` relation. */
  idMediasByVerificationId?: Maybe<IdInquiryVerificationToManyIdMediaFilter>;
  /** Some related `idMediasByVerificationId` exist. */
  idMediasByVerificationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `idInquiryVerificationChecksByVerificationId` relation. */
  idInquiryVerificationChecksByVerificationId?: Maybe<IdInquiryVerificationToManyIdInquiryVerificationCheckFilter>;
  /** Some related `idInquiryVerificationChecksByVerificationId` exist. */
  idInquiryVerificationChecksByVerificationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `inquiry` relation. */
  inquiry?: Maybe<IdInquiryFilter>;
  /** A related `inquiry` exists. */
  inquiryExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IdInquiryVerificationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IdInquiryVerificationFilter>>;
  /** Negates the expression. */
  not?: Maybe<IdInquiryVerificationFilter>;
};

/** Grouping methods for `IdInquiryVerification` for usage during aggregation. */
export enum IdInquiryVerificationGroupBy {
  InquiryId = 'INQUIRY_ID',
  Type = 'TYPE',
  Status = 'STATUS',
  Reasons = 'REASONS',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Gender = 'GENDER',
  Address = 'ADDRESS',
  City = 'CITY',
  Province = 'PROVINCE',
  PostalCode = 'POSTAL_CODE',
  BirthDate = 'BIRTH_DATE',
  IdNumber = 'ID_NUMBER',
  IssueAuthority = 'ISSUE_AUTHORITY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  DeletedAt = 'DELETED_AT',
  DeletedAtTruncatedToHour = 'DELETED_AT_TRUNCATED_TO_HOUR',
  DeletedAtTruncatedToDay = 'DELETED_AT_TRUNCATED_TO_DAY',
  IdInquiryVerificationId = 'ID_INQUIRY_VERIFICATION_ID'
}

export type IdInquiryVerificationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryVerificationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `IdInquiryVerification` aggregates. */
export type IdInquiryVerificationHavingInput = {
  AND?: Maybe<Array<IdInquiryVerificationHavingInput>>;
  OR?: Maybe<Array<IdInquiryVerificationHavingInput>>;
  sum?: Maybe<IdInquiryVerificationHavingSumInput>;
  distinctCount?: Maybe<IdInquiryVerificationHavingDistinctCountInput>;
  min?: Maybe<IdInquiryVerificationHavingMinInput>;
  max?: Maybe<IdInquiryVerificationHavingMaxInput>;
  average?: Maybe<IdInquiryVerificationHavingAverageInput>;
  stddevSample?: Maybe<IdInquiryVerificationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<IdInquiryVerificationHavingStddevPopulationInput>;
  varianceSample?: Maybe<IdInquiryVerificationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<IdInquiryVerificationHavingVariancePopulationInput>;
};

export type IdInquiryVerificationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryVerificationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryVerificationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryVerificationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryVerificationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryVerificationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdInquiryVerificationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** The fields on `idInquiryVerification` to look up the row to connect. */
export type IdInquiryVerificationIdInquiryVerificationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `idInquiryVerification` to look up the row to delete. */
export type IdInquiryVerificationIdInquiryVerificationPkeyDelete = {
  id: Scalars['Int'];
};

/** An input for mutations affecting `IdInquiryVerification` */
export type IdInquiryVerificationInput = {
  id?: Maybe<Scalars['Int']>;
  inquiryId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  issueAuthority?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdInquiryVerificationInquiryIdFkeyInput>;
  idMediaUsingId?: Maybe<IdMediaVerificationIdFkeyInverseInput>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInverseInput>;
};

/** The `idInquiry` to be created by this mutation. */
export type IdInquiryVerificationInquiryIdFkeyIdInquiryCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  integrationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawInquiry?: Maybe<Scalars['JSON']>;
  inquiryId: Scalars['String'];
  leadToLeadId?: Maybe<IdInquiryLeadIdFkeyInput>;
  integrationToIntegrationId?: Maybe<IdInquiryIntegrationIdFkeyInput>;
  idInquiryVerificationsUsingId?: Maybe<IdInquiryVerificationInquiryIdFkeyInverseInput>;
  idSessionsUsingId?: Maybe<IdSessionInquiryIdFkeyInverseInput>;
};

/** The `idInquiryVerification` to be created by this mutation. */
export type IdInquiryVerificationInquiryIdFkeyIdInquiryVerificationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  inquiryId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  issueAuthority?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdInquiryVerificationInquiryIdFkeyInput>;
  idMediaUsingId?: Maybe<IdMediaVerificationIdFkeyInverseInput>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `idInquiry` in the `IdInquiryVerificationInput` mutation. */
export type IdInquiryVerificationInquiryIdFkeyInput = {
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  connectById?: Maybe<IdInquiryIdInquiryPkeyConnect>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  connectByNodeId?: Maybe<IdInquiryNodeIdConnect>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  deleteById?: Maybe<IdInquiryIdInquiryPkeyDelete>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IdInquiryNodeIdDelete>;
  /** The primary key(s) and patch data for `idInquiry` for the far side of the relationship. */
  updateById?: Maybe<IdInquiryOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyUsingIdInquiryPkeyUpdate>;
  /** The primary key(s) and patch data for `idInquiry` for the far side of the relationship. */
  updateByNodeId?: Maybe<IdInquiryVerificationOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyNodeIdUpdate>;
  /** A `IdInquiryInput` object that will be created and connected to this object. */
  create?: Maybe<IdInquiryVerificationInquiryIdFkeyIdInquiryCreateInput>;
};

/** Input for the nested mutation of `idInquiryVerification` in the `IdInquiryInput` mutation. */
export type IdInquiryVerificationInquiryIdFkeyInverseInput = {
  /** Flag indicating whether all other `idInquiryVerification` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  connectById?: Maybe<Array<IdInquiryVerificationIdInquiryVerificationPkeyConnect>>;
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IdInquiryVerificationNodeIdConnect>>;
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  deleteById?: Maybe<Array<IdInquiryVerificationIdInquiryVerificationPkeyDelete>>;
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IdInquiryVerificationNodeIdDelete>>;
  /** The primary key(s) and patch data for `idInquiryVerification` for the far side of the relationship. */
  updateById?: Maybe<Array<IdInquiryVerificationOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyUsingIdInquiryVerificationPkeyUpdate>>;
  /** The primary key(s) and patch data for `idInquiryVerification` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<IdInquiryOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyNodeIdUpdate>>;
  /** A `IdInquiryVerificationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IdInquiryVerificationInquiryIdFkeyIdInquiryVerificationCreateInput>>;
};

export type IdInquiryVerificationMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  inquiryId?: Maybe<IntFilter>;
};

export type IdInquiryVerificationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `Media` values, with data from `IdMedia`. */
export type IdInquiryVerificationMediaByIdMediaVerificationIdAndMediaIdManyToManyConnection = {
  /** A list of `Media` objects. */
  nodes: Array<Media>;
  /** A list of edges which contains the `Media`, info from the `IdMedia`, and the cursor to aid in pagination. */
  edges: Array<IdInquiryVerificationMediaByIdMediaVerificationIdAndMediaIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Media` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MediaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MediaAggregates>>;
};


/** A connection to a list of `Media` values, with data from `IdMedia`. */
export type IdInquiryVerificationMediaByIdMediaVerificationIdAndMediaIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<MediaGroupBy>;
  having?: Maybe<MediaHavingInput>;
};

/** A `Media` edge in the connection, with data from `IdMedia`. */
export type IdInquiryVerificationMediaByIdMediaVerificationIdAndMediaIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Media` at the end of the edge. */
  node: Media;
  /** Reads and enables pagination through a set of `IdMedia`. */
  idMedias: IdMediaConnection;
};


/** A `Media` edge in the connection, with data from `IdMedia`. */
export type IdInquiryVerificationMediaByIdMediaVerificationIdAndMediaIdManyToManyEdgeIdMediasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdMediaOrderBy>>;
  condition?: Maybe<IdMediaCondition>;
  filter?: Maybe<IdMediaFilter>;
};

export type IdInquiryVerificationMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  inquiryId?: Maybe<IntFilter>;
};

export type IdInquiryVerificationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type IdInquiryVerificationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `idInquiryVerification` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IdInquiryVerificationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `idInquiryVerification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdInquiryVerificationOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idInquiryVerificationCheck` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idInquiryVerificationCheck` being updated. */
  patch: IdInquiryVerificationCheckPatch;
};

/** The fields on `idInquiryVerification` to look up the row to update. */
export type IdInquiryVerificationOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyUsingIdInquiryVerificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `idInquiryVerification` being updated. */
  patch: UpdateIdInquiryVerificationOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdInquiryVerificationOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idInquiry` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idInquiry` being updated. */
  patch: IdInquiryPatch;
};

/** The fields on `idInquiryVerification` to look up the row to update. */
export type IdInquiryVerificationOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyUsingIdInquiryVerificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `idInquiryVerification` being updated. */
  patch: UpdateIdInquiryVerificationOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdInquiryVerificationOnIdMediaForIdMediaVerificationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idMedia` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idMedia` being updated. */
  patch: IdMediaPatch;
};

/** The fields on `idInquiryVerification` to look up the row to update. */
export type IdInquiryVerificationOnIdMediaForIdMediaVerificationIdFkeyUsingIdInquiryVerificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `idInquiryVerification` being updated. */
  patch: UpdateIdInquiryVerificationOnIdMediaForIdMediaVerificationIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `IdInquiryVerification`. Fields that are set will be updated. */
export type IdInquiryVerificationPatch = {
  id?: Maybe<Scalars['Int']>;
  inquiryId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  issueAuthority?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdInquiryVerificationInquiryIdFkeyInput>;
  idMediaUsingId?: Maybe<IdMediaVerificationIdFkeyInverseInput>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInverseInput>;
};

export type IdInquiryVerificationStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inquiryId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVerificationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigFloat']>;
};

export type IdInquiryVerificationStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inquiryId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVerificationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigFloat']>;
};

export type IdInquiryVerificationSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  inquiryId?: Maybe<BigIntFilter>;
};

export type IdInquiryVerificationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of inquiryId across the matching connection */
  inquiryId: Scalars['BigInt'];
};

/** A filter to be used against many `IdInquiryVerificationCheck` object types. All fields are combined with a logical ‘and.’ */
export type IdInquiryVerificationToManyIdInquiryVerificationCheckFilter = {
  /** Every related `IdInquiryVerificationCheck` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<IdInquiryVerificationCheckFilter>;
  /** Some related `IdInquiryVerificationCheck` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<IdInquiryVerificationCheckFilter>;
  /** No related `IdInquiryVerificationCheck` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<IdInquiryVerificationCheckFilter>;
  /** Aggregates across related `IdInquiryVerificationCheck` match the filter criteria. */
  aggregates?: Maybe<IdInquiryVerificationCheckAggregatesFilter>;
};

/** A filter to be used against many `IdMedia` object types. All fields are combined with a logical ‘and.’ */
export type IdInquiryVerificationToManyIdMediaFilter = {
  /** Every related `IdMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<IdMediaFilter>;
  /** Some related `IdMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<IdMediaFilter>;
  /** No related `IdMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<IdMediaFilter>;
  /** Aggregates across related `IdMedia` match the filter criteria. */
  aggregates?: Maybe<IdMediaAggregatesFilter>;
};

export type IdInquiryVerificationVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inquiryId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVerificationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigFloat']>;
};

export type IdInquiryVerificationVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inquiryId?: Maybe<BigFloatFilter>;
};

export type IdInquiryVerificationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `VerificationCheck` values, with data from `IdInquiryVerificationCheck`. */
export type IdInquiryVerificationVerificationChecksByIdInquiryVerificationCheckVerificationIdAndVerificationCheckIdManyToManyConnection = {
  /** A list of `VerificationCheck` objects. */
  nodes: Array<VerificationCheck>;
  /** A list of edges which contains the `VerificationCheck`, info from the `IdInquiryVerificationCheck`, and the cursor to aid in pagination. */
  edges: Array<IdInquiryVerificationVerificationChecksByIdInquiryVerificationCheckVerificationIdAndVerificationCheckIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VerificationCheck` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<VerificationCheckAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<VerificationCheckAggregates>>;
};


/** A connection to a list of `VerificationCheck` values, with data from `IdInquiryVerificationCheck`. */
export type IdInquiryVerificationVerificationChecksByIdInquiryVerificationCheckVerificationIdAndVerificationCheckIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<VerificationCheckGroupBy>;
  having?: Maybe<VerificationCheckHavingInput>;
};

/** A `VerificationCheck` edge in the connection, with data from `IdInquiryVerificationCheck`. */
export type IdInquiryVerificationVerificationChecksByIdInquiryVerificationCheckVerificationIdAndVerificationCheckIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `VerificationCheck` at the end of the edge. */
  node: VerificationCheck;
  /** Reads and enables pagination through a set of `IdInquiryVerificationCheck`. */
  idInquiryVerificationChecks: IdInquiryVerificationChecksConnection;
};


/** A `VerificationCheck` edge in the connection, with data from `IdInquiryVerificationCheck`. */
export type IdInquiryVerificationVerificationChecksByIdInquiryVerificationCheckVerificationIdAndVerificationCheckIdManyToManyEdgeIdInquiryVerificationChecksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiryVerificationChecksOrderBy>>;
  condition?: Maybe<IdInquiryVerificationCheckCondition>;
  filter?: Maybe<IdInquiryVerificationCheckFilter>;
};

/** A connection to a list of `IdInquiryVerification` values. */
export type IdInquiryVerificationsConnection = {
  /** A list of `IdInquiryVerification` objects. */
  nodes: Array<IdInquiryVerification>;
  /** A list of edges which contains the `IdInquiryVerification` and cursor to aid in pagination. */
  edges: Array<IdInquiryVerificationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdInquiryVerification` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IdInquiryVerificationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IdInquiryVerificationAggregates>>;
};


/** A connection to a list of `IdInquiryVerification` values. */
export type IdInquiryVerificationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IdInquiryVerificationGroupBy>;
  having?: Maybe<IdInquiryVerificationHavingInput>;
};

/** A `IdInquiryVerification` edge in the connection. */
export type IdInquiryVerificationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdInquiryVerification` at the end of the edge. */
  node: IdInquiryVerification;
};

/** Methods to use when ordering `IdInquiryVerification`. */
export enum IdInquiryVerificationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InquiryIdAsc = 'INQUIRY_ID_ASC',
  InquiryIdDesc = 'INQUIRY_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  ReasonsAsc = 'REASONS_ASC',
  ReasonsDesc = 'REASONS_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  GenderAsc = 'GENDER_ASC',
  GenderDesc = 'GENDER_DESC',
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  ProvinceAsc = 'PROVINCE_ASC',
  ProvinceDesc = 'PROVINCE_DESC',
  PostalCodeAsc = 'POSTAL_CODE_ASC',
  PostalCodeDesc = 'POSTAL_CODE_DESC',
  BirthDateAsc = 'BIRTH_DATE_ASC',
  BirthDateDesc = 'BIRTH_DATE_DESC',
  IdNumberAsc = 'ID_NUMBER_ASC',
  IdNumberDesc = 'ID_NUMBER_DESC',
  IssueAuthorityAsc = 'ISSUE_AUTHORITY_ASC',
  IssueAuthorityDesc = 'ISSUE_AUTHORITY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdInquiryVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_ID_ASC',
  IdInquiryVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  IdMediasByVerificationIdCountAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_COUNT_ASC',
  IdMediasByVerificationIdCountDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_COUNT_DESC',
  IdMediasByVerificationIdSumIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_ID_ASC',
  IdMediasByVerificationIdSumIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_ID_DESC',
  IdMediasByVerificationIdSumVerificationIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_VERIFICATION_ID_ASC',
  IdMediasByVerificationIdSumVerificationIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_VERIFICATION_ID_DESC',
  IdMediasByVerificationIdSumMediaIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_MEDIA_ID_ASC',
  IdMediasByVerificationIdSumMediaIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_MEDIA_ID_DESC',
  IdMediasByVerificationIdSumNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_NAME_ASC',
  IdMediasByVerificationIdSumNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_NAME_DESC',
  IdMediasByVerificationIdSumDisplayNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_DISPLAY_NAME_ASC',
  IdMediasByVerificationIdSumDisplayNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_DISPLAY_NAME_DESC',
  IdMediasByVerificationIdSumCreatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_CREATED_AT_ASC',
  IdMediasByVerificationIdSumCreatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_CREATED_AT_DESC',
  IdMediasByVerificationIdSumUpdatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_UPDATED_AT_ASC',
  IdMediasByVerificationIdSumUpdatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_UPDATED_AT_DESC',
  IdMediasByVerificationIdSumDeletedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_DELETED_AT_ASC',
  IdMediasByVerificationIdSumDeletedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_SUM_DELETED_AT_DESC',
  IdMediasByVerificationIdDistinctCountIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_ID_ASC',
  IdMediasByVerificationIdDistinctCountIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_ID_DESC',
  IdMediasByVerificationIdDistinctCountVerificationIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_VERIFICATION_ID_ASC',
  IdMediasByVerificationIdDistinctCountVerificationIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_VERIFICATION_ID_DESC',
  IdMediasByVerificationIdDistinctCountMediaIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_MEDIA_ID_ASC',
  IdMediasByVerificationIdDistinctCountMediaIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_MEDIA_ID_DESC',
  IdMediasByVerificationIdDistinctCountNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_NAME_ASC',
  IdMediasByVerificationIdDistinctCountNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_NAME_DESC',
  IdMediasByVerificationIdDistinctCountDisplayNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_DISPLAY_NAME_ASC',
  IdMediasByVerificationIdDistinctCountDisplayNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_DISPLAY_NAME_DESC',
  IdMediasByVerificationIdDistinctCountCreatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  IdMediasByVerificationIdDistinctCountCreatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  IdMediasByVerificationIdDistinctCountUpdatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  IdMediasByVerificationIdDistinctCountUpdatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  IdMediasByVerificationIdDistinctCountDeletedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_DELETED_AT_ASC',
  IdMediasByVerificationIdDistinctCountDeletedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_DISTINCT_COUNT_DELETED_AT_DESC',
  IdMediasByVerificationIdMinIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_ID_ASC',
  IdMediasByVerificationIdMinIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_ID_DESC',
  IdMediasByVerificationIdMinVerificationIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_VERIFICATION_ID_ASC',
  IdMediasByVerificationIdMinVerificationIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_VERIFICATION_ID_DESC',
  IdMediasByVerificationIdMinMediaIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_MEDIA_ID_ASC',
  IdMediasByVerificationIdMinMediaIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_MEDIA_ID_DESC',
  IdMediasByVerificationIdMinNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_NAME_ASC',
  IdMediasByVerificationIdMinNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_NAME_DESC',
  IdMediasByVerificationIdMinDisplayNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_DISPLAY_NAME_ASC',
  IdMediasByVerificationIdMinDisplayNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_DISPLAY_NAME_DESC',
  IdMediasByVerificationIdMinCreatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_CREATED_AT_ASC',
  IdMediasByVerificationIdMinCreatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_CREATED_AT_DESC',
  IdMediasByVerificationIdMinUpdatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_UPDATED_AT_ASC',
  IdMediasByVerificationIdMinUpdatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_UPDATED_AT_DESC',
  IdMediasByVerificationIdMinDeletedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_DELETED_AT_ASC',
  IdMediasByVerificationIdMinDeletedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MIN_DELETED_AT_DESC',
  IdMediasByVerificationIdMaxIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_ID_ASC',
  IdMediasByVerificationIdMaxIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_ID_DESC',
  IdMediasByVerificationIdMaxVerificationIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_VERIFICATION_ID_ASC',
  IdMediasByVerificationIdMaxVerificationIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_VERIFICATION_ID_DESC',
  IdMediasByVerificationIdMaxMediaIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_MEDIA_ID_ASC',
  IdMediasByVerificationIdMaxMediaIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_MEDIA_ID_DESC',
  IdMediasByVerificationIdMaxNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_NAME_ASC',
  IdMediasByVerificationIdMaxNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_NAME_DESC',
  IdMediasByVerificationIdMaxDisplayNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_DISPLAY_NAME_ASC',
  IdMediasByVerificationIdMaxDisplayNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_DISPLAY_NAME_DESC',
  IdMediasByVerificationIdMaxCreatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_CREATED_AT_ASC',
  IdMediasByVerificationIdMaxCreatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_CREATED_AT_DESC',
  IdMediasByVerificationIdMaxUpdatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_UPDATED_AT_ASC',
  IdMediasByVerificationIdMaxUpdatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_UPDATED_AT_DESC',
  IdMediasByVerificationIdMaxDeletedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_DELETED_AT_ASC',
  IdMediasByVerificationIdMaxDeletedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_MAX_DELETED_AT_DESC',
  IdMediasByVerificationIdAverageIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_ID_ASC',
  IdMediasByVerificationIdAverageIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_ID_DESC',
  IdMediasByVerificationIdAverageVerificationIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_VERIFICATION_ID_ASC',
  IdMediasByVerificationIdAverageVerificationIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_VERIFICATION_ID_DESC',
  IdMediasByVerificationIdAverageMediaIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_MEDIA_ID_ASC',
  IdMediasByVerificationIdAverageMediaIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_MEDIA_ID_DESC',
  IdMediasByVerificationIdAverageNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_NAME_ASC',
  IdMediasByVerificationIdAverageNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_NAME_DESC',
  IdMediasByVerificationIdAverageDisplayNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_DISPLAY_NAME_ASC',
  IdMediasByVerificationIdAverageDisplayNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_DISPLAY_NAME_DESC',
  IdMediasByVerificationIdAverageCreatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_CREATED_AT_ASC',
  IdMediasByVerificationIdAverageCreatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_CREATED_AT_DESC',
  IdMediasByVerificationIdAverageUpdatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_UPDATED_AT_ASC',
  IdMediasByVerificationIdAverageUpdatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_UPDATED_AT_DESC',
  IdMediasByVerificationIdAverageDeletedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_DELETED_AT_ASC',
  IdMediasByVerificationIdAverageDeletedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_AVERAGE_DELETED_AT_DESC',
  IdMediasByVerificationIdStddevSampleIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_ID_ASC',
  IdMediasByVerificationIdStddevSampleIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_ID_DESC',
  IdMediasByVerificationIdStddevSampleVerificationIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_VERIFICATION_ID_ASC',
  IdMediasByVerificationIdStddevSampleVerificationIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_VERIFICATION_ID_DESC',
  IdMediasByVerificationIdStddevSampleMediaIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_MEDIA_ID_ASC',
  IdMediasByVerificationIdStddevSampleMediaIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_MEDIA_ID_DESC',
  IdMediasByVerificationIdStddevSampleNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_NAME_ASC',
  IdMediasByVerificationIdStddevSampleNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_NAME_DESC',
  IdMediasByVerificationIdStddevSampleDisplayNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_DISPLAY_NAME_ASC',
  IdMediasByVerificationIdStddevSampleDisplayNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_DISPLAY_NAME_DESC',
  IdMediasByVerificationIdStddevSampleCreatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  IdMediasByVerificationIdStddevSampleCreatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  IdMediasByVerificationIdStddevSampleUpdatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  IdMediasByVerificationIdStddevSampleUpdatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  IdMediasByVerificationIdStddevSampleDeletedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_DELETED_AT_ASC',
  IdMediasByVerificationIdStddevSampleDeletedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_SAMPLE_DELETED_AT_DESC',
  IdMediasByVerificationIdStddevPopulationIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_ID_ASC',
  IdMediasByVerificationIdStddevPopulationIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_ID_DESC',
  IdMediasByVerificationIdStddevPopulationVerificationIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_VERIFICATION_ID_ASC',
  IdMediasByVerificationIdStddevPopulationVerificationIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_VERIFICATION_ID_DESC',
  IdMediasByVerificationIdStddevPopulationMediaIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_MEDIA_ID_ASC',
  IdMediasByVerificationIdStddevPopulationMediaIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_MEDIA_ID_DESC',
  IdMediasByVerificationIdStddevPopulationNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_NAME_ASC',
  IdMediasByVerificationIdStddevPopulationNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_NAME_DESC',
  IdMediasByVerificationIdStddevPopulationDisplayNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_DISPLAY_NAME_ASC',
  IdMediasByVerificationIdStddevPopulationDisplayNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_DISPLAY_NAME_DESC',
  IdMediasByVerificationIdStddevPopulationCreatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  IdMediasByVerificationIdStddevPopulationCreatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  IdMediasByVerificationIdStddevPopulationUpdatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  IdMediasByVerificationIdStddevPopulationUpdatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  IdMediasByVerificationIdStddevPopulationDeletedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_DELETED_AT_ASC',
  IdMediasByVerificationIdStddevPopulationDeletedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_STDDEV_POPULATION_DELETED_AT_DESC',
  IdMediasByVerificationIdVarianceSampleIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_ID_ASC',
  IdMediasByVerificationIdVarianceSampleIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_ID_DESC',
  IdMediasByVerificationIdVarianceSampleVerificationIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_VERIFICATION_ID_ASC',
  IdMediasByVerificationIdVarianceSampleVerificationIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_VERIFICATION_ID_DESC',
  IdMediasByVerificationIdVarianceSampleMediaIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_MEDIA_ID_ASC',
  IdMediasByVerificationIdVarianceSampleMediaIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_MEDIA_ID_DESC',
  IdMediasByVerificationIdVarianceSampleNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_NAME_ASC',
  IdMediasByVerificationIdVarianceSampleNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_NAME_DESC',
  IdMediasByVerificationIdVarianceSampleDisplayNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_DISPLAY_NAME_ASC',
  IdMediasByVerificationIdVarianceSampleDisplayNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_DISPLAY_NAME_DESC',
  IdMediasByVerificationIdVarianceSampleCreatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  IdMediasByVerificationIdVarianceSampleCreatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  IdMediasByVerificationIdVarianceSampleUpdatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  IdMediasByVerificationIdVarianceSampleUpdatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdMediasByVerificationIdVarianceSampleDeletedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_DELETED_AT_ASC',
  IdMediasByVerificationIdVarianceSampleDeletedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_DELETED_AT_DESC',
  IdMediasByVerificationIdVariancePopulationIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_ID_ASC',
  IdMediasByVerificationIdVariancePopulationIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_ID_DESC',
  IdMediasByVerificationIdVariancePopulationVerificationIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_VERIFICATION_ID_ASC',
  IdMediasByVerificationIdVariancePopulationVerificationIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_VERIFICATION_ID_DESC',
  IdMediasByVerificationIdVariancePopulationMediaIdAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_MEDIA_ID_ASC',
  IdMediasByVerificationIdVariancePopulationMediaIdDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_MEDIA_ID_DESC',
  IdMediasByVerificationIdVariancePopulationNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_NAME_ASC',
  IdMediasByVerificationIdVariancePopulationNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_NAME_DESC',
  IdMediasByVerificationIdVariancePopulationDisplayNameAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_DISPLAY_NAME_ASC',
  IdMediasByVerificationIdVariancePopulationDisplayNameDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_DISPLAY_NAME_DESC',
  IdMediasByVerificationIdVariancePopulationCreatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  IdMediasByVerificationIdVariancePopulationCreatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  IdMediasByVerificationIdVariancePopulationUpdatedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  IdMediasByVerificationIdVariancePopulationUpdatedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  IdMediasByVerificationIdVariancePopulationDeletedAtAsc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_DELETED_AT_ASC',
  IdMediasByVerificationIdVariancePopulationDeletedAtDesc = 'ID_MEDIAS_BY_VERIFICATION_ID_VARIANCE_POPULATION_DELETED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdCountAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_COUNT_ASC',
  IdInquiryVerificationChecksByVerificationIdCountDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_COUNT_DESC',
  IdInquiryVerificationChecksByVerificationIdSumIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdSumIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdSumVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdSumVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdSumStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_STATUS_ASC',
  IdInquiryVerificationChecksByVerificationIdSumStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_STATUS_DESC',
  IdInquiryVerificationChecksByVerificationIdSumReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_REASONS_ASC',
  IdInquiryVerificationChecksByVerificationIdSumReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_REASONS_DESC',
  IdInquiryVerificationChecksByVerificationIdSumRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_REQUIRED_ASC',
  IdInquiryVerificationChecksByVerificationIdSumRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_REQUIRED_DESC',
  IdInquiryVerificationChecksByVerificationIdSumMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_METADATA_ASC',
  IdInquiryVerificationChecksByVerificationIdSumMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_METADATA_DESC',
  IdInquiryVerificationChecksByVerificationIdSumCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_CREATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdSumCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_CREATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdSumUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_UPDATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdSumUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_UPDATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdSumDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_DELETED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdSumDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_DELETED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdSumVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdSumVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_SUM_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_STATUS_ASC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_STATUS_DESC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_REASONS_ASC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_REASONS_DESC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_REQUIRED_ASC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_REQUIRED_DESC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_METADATA_ASC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_METADATA_DESC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_DELETED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_DELETED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdDistinctCountVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_DISTINCT_COUNT_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdMinIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdMinIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdMinVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdMinVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdMinStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_STATUS_ASC',
  IdInquiryVerificationChecksByVerificationIdMinStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_STATUS_DESC',
  IdInquiryVerificationChecksByVerificationIdMinReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_REASONS_ASC',
  IdInquiryVerificationChecksByVerificationIdMinReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_REASONS_DESC',
  IdInquiryVerificationChecksByVerificationIdMinRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_REQUIRED_ASC',
  IdInquiryVerificationChecksByVerificationIdMinRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_REQUIRED_DESC',
  IdInquiryVerificationChecksByVerificationIdMinMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_METADATA_ASC',
  IdInquiryVerificationChecksByVerificationIdMinMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_METADATA_DESC',
  IdInquiryVerificationChecksByVerificationIdMinCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_CREATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdMinCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_CREATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdMinUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_UPDATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdMinUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_UPDATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdMinDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_DELETED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdMinDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_DELETED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdMinVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdMinVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MIN_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdMaxIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdMaxIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdMaxVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdMaxVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdMaxStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_STATUS_ASC',
  IdInquiryVerificationChecksByVerificationIdMaxStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_STATUS_DESC',
  IdInquiryVerificationChecksByVerificationIdMaxReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_REASONS_ASC',
  IdInquiryVerificationChecksByVerificationIdMaxReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_REASONS_DESC',
  IdInquiryVerificationChecksByVerificationIdMaxRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_REQUIRED_ASC',
  IdInquiryVerificationChecksByVerificationIdMaxRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_REQUIRED_DESC',
  IdInquiryVerificationChecksByVerificationIdMaxMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_METADATA_ASC',
  IdInquiryVerificationChecksByVerificationIdMaxMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_METADATA_DESC',
  IdInquiryVerificationChecksByVerificationIdMaxCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_CREATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdMaxCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_CREATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdMaxUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_UPDATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdMaxUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_UPDATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdMaxDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_DELETED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdMaxDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_DELETED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdMaxVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdMaxVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_MAX_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdAverageIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdAverageIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdAverageVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdAverageVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdAverageStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_STATUS_ASC',
  IdInquiryVerificationChecksByVerificationIdAverageStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_STATUS_DESC',
  IdInquiryVerificationChecksByVerificationIdAverageReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_REASONS_ASC',
  IdInquiryVerificationChecksByVerificationIdAverageReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_REASONS_DESC',
  IdInquiryVerificationChecksByVerificationIdAverageRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_REQUIRED_ASC',
  IdInquiryVerificationChecksByVerificationIdAverageRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_REQUIRED_DESC',
  IdInquiryVerificationChecksByVerificationIdAverageMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_METADATA_ASC',
  IdInquiryVerificationChecksByVerificationIdAverageMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_METADATA_DESC',
  IdInquiryVerificationChecksByVerificationIdAverageCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_CREATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdAverageCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_CREATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdAverageUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_UPDATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdAverageUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_UPDATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdAverageDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_DELETED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdAverageDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_DELETED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdAverageVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdAverageVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_AVERAGE_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_STATUS_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_STATUS_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_REASONS_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_REASONS_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_REQUIRED_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_REQUIRED_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_METADATA_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_METADATA_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_DELETED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_DELETED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevSampleVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_SAMPLE_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_STATUS_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_STATUS_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_REASONS_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_REASONS_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_REQUIRED_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_REQUIRED_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_METADATA_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_METADATA_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_DELETED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_DELETED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdStddevPopulationVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_STDDEV_POPULATION_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_STATUS_ASC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_STATUS_DESC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_REASONS_ASC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_REASONS_DESC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_REQUIRED_ASC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_REQUIRED_DESC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_METADATA_ASC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_METADATA_DESC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_DELETED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_DELETED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdVarianceSampleVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_SAMPLE_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_STATUS_ASC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_STATUS_DESC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_REASONS_ASC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_REASONS_DESC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_REQUIRED_ASC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_REQUIRED_DESC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_METADATA_ASC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_METADATA_DESC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_DELETED_AT_ASC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_DELETED_AT_DESC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksByVerificationIdVariancePopulationVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_BY_VERIFICATION_ID_VARIANCE_POPULATION_VERIFICATION_CHECK_ID_DESC'
}

export type IdMedia = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  verificationId?: Maybe<Scalars['Int']>;
  mediaId: Scalars['Int'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `IdInquiryVerification` that is related to this `IdMedia`. */
  verification?: Maybe<IdInquiryVerification>;
  /** Reads a single `Media` that is related to this `IdMedia`. */
  media?: Maybe<Media>;
};

export type IdMediaAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IdMediaSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IdMediaDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IdMediaMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IdMediaMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IdMediaAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IdMediaStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IdMediaStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IdMediaVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IdMediaVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `IdMedia` object types. */
export type IdMediaAggregatesFilter = {
  /** A filter that must pass for the relevant `IdMedia` object to be included within the aggregate. */
  filter?: Maybe<IdMediaFilter>;
  /** Sum aggregate over matching `IdMedia` objects. */
  sum?: Maybe<IdMediaSumAggregateFilter>;
  /** Distinct count aggregate over matching `IdMedia` objects. */
  distinctCount?: Maybe<IdMediaDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `IdMedia` objects. */
  min?: Maybe<IdMediaMinAggregateFilter>;
  /** Maximum aggregate over matching `IdMedia` objects. */
  max?: Maybe<IdMediaMaxAggregateFilter>;
  /** Mean average aggregate over matching `IdMedia` objects. */
  average?: Maybe<IdMediaAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `IdMedia` objects. */
  stddevSample?: Maybe<IdMediaStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `IdMedia` objects. */
  stddevPopulation?: Maybe<IdMediaStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `IdMedia` objects. */
  varianceSample?: Maybe<IdMediaVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `IdMedia` objects. */
  variancePopulation?: Maybe<IdMediaVariancePopulationAggregateFilter>;
};

export type IdMediaAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  verificationId?: Maybe<BigFloatFilter>;
  mediaId?: Maybe<BigFloatFilter>;
};

export type IdMediaAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `IdMedia` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type IdMediaCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `verificationId` field. */
  verificationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `mediaId` field. */
  mediaId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `IdMedia` values. */
export type IdMediaConnection = {
  /** A list of `IdMedia` objects. */
  nodes: Array<IdMedia>;
  /** A list of edges which contains the `IdMedia` and cursor to aid in pagination. */
  edges: Array<IdMediaEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdMedia` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IdMediaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IdMediaAggregates>>;
};


/** A connection to a list of `IdMedia` values. */
export type IdMediaConnectionGroupedAggregatesArgs = {
  groupBy: Array<IdMediaGroupBy>;
  having?: Maybe<IdMediaHavingInput>;
};

export type IdMediaDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  verificationId?: Maybe<BigIntFilter>;
  mediaId?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  displayName?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  deletedAt?: Maybe<BigIntFilter>;
};

export type IdMediaDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
};

/** A `IdMedia` edge in the connection. */
export type IdMediaEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdMedia` at the end of the edge. */
  node: IdMedia;
};

/** A filter to be used against `IdMedia` object types. All fields are combined with a logical ‘and.’ */
export type IdMediaFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `verificationId` field. */
  verificationId?: Maybe<IntFilter>;
  /** Filter by the object’s `mediaId` field. */
  mediaId?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `verification` relation. */
  verification?: Maybe<IdInquiryVerificationFilter>;
  /** A related `verification` exists. */
  verificationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `media` relation. */
  media?: Maybe<MediaFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IdMediaFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IdMediaFilter>>;
  /** Negates the expression. */
  not?: Maybe<IdMediaFilter>;
};

/** Grouping methods for `IdMedia` for usage during aggregation. */
export enum IdMediaGroupBy {
  VerificationId = 'VERIFICATION_ID',
  MediaId = 'MEDIA_ID',
  Name = 'NAME',
  DisplayName = 'DISPLAY_NAME',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  DeletedAt = 'DELETED_AT',
  DeletedAtTruncatedToHour = 'DELETED_AT_TRUNCATED_TO_HOUR',
  DeletedAtTruncatedToDay = 'DELETED_AT_TRUNCATED_TO_DAY'
}

export type IdMediaHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdMediaHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `IdMedia` aggregates. */
export type IdMediaHavingInput = {
  AND?: Maybe<Array<IdMediaHavingInput>>;
  OR?: Maybe<Array<IdMediaHavingInput>>;
  sum?: Maybe<IdMediaHavingSumInput>;
  distinctCount?: Maybe<IdMediaHavingDistinctCountInput>;
  min?: Maybe<IdMediaHavingMinInput>;
  max?: Maybe<IdMediaHavingMaxInput>;
  average?: Maybe<IdMediaHavingAverageInput>;
  stddevSample?: Maybe<IdMediaHavingStddevSampleInput>;
  stddevPopulation?: Maybe<IdMediaHavingStddevPopulationInput>;
  varianceSample?: Maybe<IdMediaHavingVarianceSampleInput>;
  variancePopulation?: Maybe<IdMediaHavingVariancePopulationInput>;
};

export type IdMediaHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdMediaHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdMediaHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdMediaHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdMediaHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdMediaHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdMediaHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  verificationId?: Maybe<HavingIntFilter>;
  mediaId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** The fields on `idMedia` to look up the row to connect. */
export type IdMediaIdMediaPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `idMedia` to look up the row to delete. */
export type IdMediaIdMediaPkeyDelete = {
  id: Scalars['Int'];
};

/** An input for mutations affecting `IdMedia` */
export type IdMediaInput = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationToVerificationId?: Maybe<IdMediaVerificationIdFkeyInput>;
  mediaToMediaId?: Maybe<IdMediaMediaIdFkeyInput>;
};

export type IdMediaMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  verificationId?: Maybe<IntFilter>;
  mediaId?: Maybe<IntFilter>;
};

export type IdMediaMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['Int']>;
  /** Maximum of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['Int']>;
};

/** The `idMedia` to be created by this mutation. */
export type IdMediaMediaIdFkeyIdMediaCreateInput = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationToVerificationId?: Maybe<IdMediaVerificationIdFkeyInput>;
  mediaToMediaId?: Maybe<IdMediaMediaIdFkeyInput>;
};

/** Input for the nested mutation of `media` in the `IdMediaInput` mutation. */
export type IdMediaMediaIdFkeyInput = {
  /** The primary key(s) for `media` for the far side of the relationship. */
  connectById?: Maybe<MediaMediaPkeyConnect>;
  /** The primary key(s) for `media` for the far side of the relationship. */
  connectByNodeId?: Maybe<MediaNodeIdConnect>;
  /** The primary key(s) and patch data for `media` for the far side of the relationship. */
  updateById?: Maybe<MediaOnIdMediaForIdMediaMediaIdFkeyUsingMediaPkeyUpdate>;
  /** The primary key(s) and patch data for `media` for the far side of the relationship. */
  updateByNodeId?: Maybe<IdMediaOnIdMediaForIdMediaMediaIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `idMedia` in the `MediaInput` mutation. */
export type IdMediaMediaIdFkeyInverseInput = {
  /** Flag indicating whether all other `idMedia` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `idMedia` for the far side of the relationship. */
  connectById?: Maybe<Array<IdMediaIdMediaPkeyConnect>>;
  /** The primary key(s) for `idMedia` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IdMediaNodeIdConnect>>;
  /** The primary key(s) for `idMedia` for the far side of the relationship. */
  deleteById?: Maybe<Array<IdMediaIdMediaPkeyDelete>>;
  /** The primary key(s) for `idMedia` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IdMediaNodeIdDelete>>;
  /** The primary key(s) and patch data for `idMedia` for the far side of the relationship. */
  updateById?: Maybe<Array<IdMediaOnIdMediaForIdMediaMediaIdFkeyUsingIdMediaPkeyUpdate>>;
  /** The primary key(s) and patch data for `idMedia` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<MediaOnIdMediaForIdMediaMediaIdFkeyNodeIdUpdate>>;
  /** A `IdMediaInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IdMediaMediaIdFkeyIdMediaCreateInput>>;
};

export type IdMediaMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  verificationId?: Maybe<IntFilter>;
  mediaId?: Maybe<IntFilter>;
};

export type IdMediaMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['Int']>;
  /** Minimum of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type IdMediaNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `idMedia` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IdMediaNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `idMedia` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdMediaOnIdMediaForIdMediaMediaIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `media` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `media` being updated. */
  patch: MediaPatch;
};

/** The fields on `idMedia` to look up the row to update. */
export type IdMediaOnIdMediaForIdMediaMediaIdFkeyUsingIdMediaPkeyUpdate = {
  /** An object where the defined keys will be set on the `idMedia` being updated. */
  patch: UpdateIdMediaOnIdMediaForIdMediaMediaIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdMediaOnIdMediaForIdMediaVerificationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idInquiryVerification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idInquiryVerification` being updated. */
  patch: IdInquiryVerificationPatch;
};

/** The fields on `idMedia` to look up the row to update. */
export type IdMediaOnIdMediaForIdMediaVerificationIdFkeyUsingIdMediaPkeyUpdate = {
  /** An object where the defined keys will be set on the `idMedia` being updated. */
  patch: UpdateIdMediaOnIdMediaForIdMediaVerificationIdFkeyPatch;
  id: Scalars['Int'];
};

/** Methods to use when ordering `IdMedia`. */
export enum IdMediaOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  VerificationIdAsc = 'VERIFICATION_ID_ASC',
  VerificationIdDesc = 'VERIFICATION_ID_DESC',
  MediaIdAsc = 'MEDIA_ID_ASC',
  MediaIdDesc = 'MEDIA_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Represents an update to a `IdMedia`. Fields that are set will be updated. */
export type IdMediaPatch = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationToVerificationId?: Maybe<IdMediaVerificationIdFkeyInput>;
  mediaToMediaId?: Maybe<IdMediaMediaIdFkeyInput>;
};

export type IdMediaStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  verificationId?: Maybe<BigFloatFilter>;
  mediaId?: Maybe<BigFloatFilter>;
};

export type IdMediaStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigFloat']>;
};

export type IdMediaStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  verificationId?: Maybe<BigFloatFilter>;
  mediaId?: Maybe<BigFloatFilter>;
};

export type IdMediaStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigFloat']>;
};

export type IdMediaSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  verificationId?: Maybe<BigIntFilter>;
  mediaId?: Maybe<BigIntFilter>;
};

export type IdMediaSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of verificationId across the matching connection */
  verificationId: Scalars['BigInt'];
  /** Sum of mediaId across the matching connection */
  mediaId: Scalars['BigInt'];
};

export type IdMediaVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  verificationId?: Maybe<BigFloatFilter>;
  mediaId?: Maybe<BigFloatFilter>;
};

export type IdMediaVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigFloat']>;
};

export type IdMediaVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  verificationId?: Maybe<BigFloatFilter>;
  mediaId?: Maybe<BigFloatFilter>;
};

export type IdMediaVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of mediaId across the matching connection */
  mediaId?: Maybe<Scalars['BigFloat']>;
};

/** The `idInquiryVerification` to be created by this mutation. */
export type IdMediaVerificationIdFkeyIdInquiryVerificationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  inquiryId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  issueAuthority?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdInquiryVerificationInquiryIdFkeyInput>;
  idMediaUsingId?: Maybe<IdMediaVerificationIdFkeyInverseInput>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInverseInput>;
};

/** The `idMedia` to be created by this mutation. */
export type IdMediaVerificationIdFkeyIdMediaCreateInput = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationToVerificationId?: Maybe<IdMediaVerificationIdFkeyInput>;
  mediaToMediaId?: Maybe<IdMediaMediaIdFkeyInput>;
};

/** Input for the nested mutation of `idInquiryVerification` in the `IdMediaInput` mutation. */
export type IdMediaVerificationIdFkeyInput = {
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  connectById?: Maybe<IdInquiryVerificationIdInquiryVerificationPkeyConnect>;
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  connectByNodeId?: Maybe<IdInquiryVerificationNodeIdConnect>;
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  deleteById?: Maybe<IdInquiryVerificationIdInquiryVerificationPkeyDelete>;
  /** The primary key(s) for `idInquiryVerification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IdInquiryVerificationNodeIdDelete>;
  /** The primary key(s) and patch data for `idInquiryVerification` for the far side of the relationship. */
  updateById?: Maybe<IdInquiryVerificationOnIdMediaForIdMediaVerificationIdFkeyUsingIdInquiryVerificationPkeyUpdate>;
  /** The primary key(s) and patch data for `idInquiryVerification` for the far side of the relationship. */
  updateByNodeId?: Maybe<IdMediaOnIdMediaForIdMediaVerificationIdFkeyNodeIdUpdate>;
  /** A `IdInquiryVerificationInput` object that will be created and connected to this object. */
  create?: Maybe<IdMediaVerificationIdFkeyIdInquiryVerificationCreateInput>;
};

/** Input for the nested mutation of `idMedia` in the `IdInquiryVerificationInput` mutation. */
export type IdMediaVerificationIdFkeyInverseInput = {
  /** Flag indicating whether all other `idMedia` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `idMedia` for the far side of the relationship. */
  connectById?: Maybe<Array<IdMediaIdMediaPkeyConnect>>;
  /** The primary key(s) for `idMedia` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IdMediaNodeIdConnect>>;
  /** The primary key(s) for `idMedia` for the far side of the relationship. */
  deleteById?: Maybe<Array<IdMediaIdMediaPkeyDelete>>;
  /** The primary key(s) for `idMedia` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IdMediaNodeIdDelete>>;
  /** The primary key(s) and patch data for `idMedia` for the far side of the relationship. */
  updateById?: Maybe<Array<IdMediaOnIdMediaForIdMediaVerificationIdFkeyUsingIdMediaPkeyUpdate>>;
  /** The primary key(s) and patch data for `idMedia` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<IdInquiryVerificationOnIdMediaForIdMediaVerificationIdFkeyNodeIdUpdate>>;
  /** A `IdMediaInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IdMediaVerificationIdFkeyIdMediaCreateInput>>;
};

export type IdName = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum IdProvider {
  Berbix = 'berbix',
  Stripe = 'stripe',
  Autocorp = 'autocorp',
  Persona = 'persona'
}

export enum IdRequestDeliveryType {
  Sms = 'sms',
  Email = 'email'
}

export type IdSession = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  inquiryId?: Maybe<Scalars['Int']>;
  threat: Scalars['String'];
  location: Scalars['String'];
  maps: Scalars['String'];
  device: Scalars['String'];
  notes: Array<Maybe<Scalars['String']>>;
  startedAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  idSessionId?: Maybe<Scalars['String']>;
  /** Reads a single `IdInquiry` that is related to this `IdSession`. */
  inquiry?: Maybe<IdInquiry>;
};

export type IdSessionAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IdSessionSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IdSessionDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IdSessionMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IdSessionMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IdSessionAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IdSessionStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IdSessionStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IdSessionVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IdSessionVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `IdSession` object types. */
export type IdSessionAggregatesFilter = {
  /** A filter that must pass for the relevant `IdSession` object to be included within the aggregate. */
  filter?: Maybe<IdSessionFilter>;
  /** Sum aggregate over matching `IdSession` objects. */
  sum?: Maybe<IdSessionSumAggregateFilter>;
  /** Distinct count aggregate over matching `IdSession` objects. */
  distinctCount?: Maybe<IdSessionDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `IdSession` objects. */
  min?: Maybe<IdSessionMinAggregateFilter>;
  /** Maximum aggregate over matching `IdSession` objects. */
  max?: Maybe<IdSessionMaxAggregateFilter>;
  /** Mean average aggregate over matching `IdSession` objects. */
  average?: Maybe<IdSessionAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `IdSession` objects. */
  stddevSample?: Maybe<IdSessionStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `IdSession` objects. */
  stddevPopulation?: Maybe<IdSessionStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `IdSession` objects. */
  varianceSample?: Maybe<IdSessionVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `IdSession` objects. */
  variancePopulation?: Maybe<IdSessionVariancePopulationAggregateFilter>;
};

export type IdSessionAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inquiryId?: Maybe<BigFloatFilter>;
};

export type IdSessionAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `IdSession` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type IdSessionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `inquiryId` field. */
  inquiryId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `threat` field. */
  threat?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `location` field. */
  location?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `maps` field. */
  maps?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `device` field. */
  device?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `idSessionId` field. */
  idSessionId?: Maybe<Scalars['String']>;
};

export type IdSessionDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  inquiryId?: Maybe<BigIntFilter>;
  threat?: Maybe<BigIntFilter>;
  location?: Maybe<BigIntFilter>;
  maps?: Maybe<BigIntFilter>;
  device?: Maybe<BigIntFilter>;
  notes?: Maybe<BigIntFilter>;
  startedAt?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  deletedAt?: Maybe<BigIntFilter>;
  idSessionId?: Maybe<BigIntFilter>;
};

export type IdSessionDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of threat across the matching connection */
  threat?: Maybe<Scalars['BigInt']>;
  /** Distinct count of location across the matching connection */
  location?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maps across the matching connection */
  maps?: Maybe<Scalars['BigInt']>;
  /** Distinct count of device across the matching connection */
  device?: Maybe<Scalars['BigInt']>;
  /** Distinct count of notes across the matching connection */
  notes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startedAt across the matching connection */
  startedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of idSessionId across the matching connection */
  idSessionId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `IdSession` object types. All fields are combined with a logical ‘and.’ */
export type IdSessionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `inquiryId` field. */
  inquiryId?: Maybe<IntFilter>;
  /** Filter by the object’s `threat` field. */
  threat?: Maybe<StringFilter>;
  /** Filter by the object’s `location` field. */
  location?: Maybe<StringFilter>;
  /** Filter by the object’s `maps` field. */
  maps?: Maybe<StringFilter>;
  /** Filter by the object’s `device` field. */
  device?: Maybe<StringFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: Maybe<StringListFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `idSessionId` field. */
  idSessionId?: Maybe<StringFilter>;
  /** Filter by the object’s `inquiry` relation. */
  inquiry?: Maybe<IdInquiryFilter>;
  /** A related `inquiry` exists. */
  inquiryExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IdSessionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IdSessionFilter>>;
  /** Negates the expression. */
  not?: Maybe<IdSessionFilter>;
};

/** Grouping methods for `IdSession` for usage during aggregation. */
export enum IdSessionGroupBy {
  InquiryId = 'INQUIRY_ID',
  Threat = 'THREAT',
  Location = 'LOCATION',
  Maps = 'MAPS',
  Device = 'DEVICE',
  Notes = 'NOTES',
  StartedAt = 'STARTED_AT',
  StartedAtTruncatedToHour = 'STARTED_AT_TRUNCATED_TO_HOUR',
  StartedAtTruncatedToDay = 'STARTED_AT_TRUNCATED_TO_DAY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  DeletedAt = 'DELETED_AT',
  DeletedAtTruncatedToHour = 'DELETED_AT_TRUNCATED_TO_HOUR',
  DeletedAtTruncatedToDay = 'DELETED_AT_TRUNCATED_TO_DAY',
  IdSessionId = 'ID_SESSION_ID'
}

export type IdSessionHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  startedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdSessionHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  startedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `IdSession` aggregates. */
export type IdSessionHavingInput = {
  AND?: Maybe<Array<IdSessionHavingInput>>;
  OR?: Maybe<Array<IdSessionHavingInput>>;
  sum?: Maybe<IdSessionHavingSumInput>;
  distinctCount?: Maybe<IdSessionHavingDistinctCountInput>;
  min?: Maybe<IdSessionHavingMinInput>;
  max?: Maybe<IdSessionHavingMaxInput>;
  average?: Maybe<IdSessionHavingAverageInput>;
  stddevSample?: Maybe<IdSessionHavingStddevSampleInput>;
  stddevPopulation?: Maybe<IdSessionHavingStddevPopulationInput>;
  varianceSample?: Maybe<IdSessionHavingVarianceSampleInput>;
  variancePopulation?: Maybe<IdSessionHavingVariancePopulationInput>;
};

export type IdSessionHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  startedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdSessionHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  startedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdSessionHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  startedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdSessionHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  startedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdSessionHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  startedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdSessionHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  startedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdSessionHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  inquiryId?: Maybe<HavingIntFilter>;
  startedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** The fields on `idSession` to look up the row to connect. */
export type IdSessionIdSessionPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `idSession` to look up the row to delete. */
export type IdSessionIdSessionPkeyDelete = {
  id: Scalars['Int'];
};

/** An input for mutations affecting `IdSession` */
export type IdSessionInput = {
  id?: Maybe<Scalars['Int']>;
  inquiryId?: Maybe<Scalars['Int']>;
  threat?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  maps?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Scalars['String']>>>;
  startedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idSessionId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdSessionInquiryIdFkeyInput>;
};

/** The `idInquiry` to be created by this mutation. */
export type IdSessionInquiryIdFkeyIdInquiryCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  integrationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawInquiry?: Maybe<Scalars['JSON']>;
  inquiryId: Scalars['String'];
  leadToLeadId?: Maybe<IdInquiryLeadIdFkeyInput>;
  integrationToIntegrationId?: Maybe<IdInquiryIntegrationIdFkeyInput>;
  idInquiryVerificationsUsingId?: Maybe<IdInquiryVerificationInquiryIdFkeyInverseInput>;
  idSessionsUsingId?: Maybe<IdSessionInquiryIdFkeyInverseInput>;
};

/** The `idSession` to be created by this mutation. */
export type IdSessionInquiryIdFkeyIdSessionCreateInput = {
  id?: Maybe<Scalars['Int']>;
  inquiryId?: Maybe<Scalars['Int']>;
  threat?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  maps?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Scalars['String']>>>;
  startedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idSessionId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdSessionInquiryIdFkeyInput>;
};

/** Input for the nested mutation of `idInquiry` in the `IdSessionInput` mutation. */
export type IdSessionInquiryIdFkeyInput = {
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  connectById?: Maybe<IdInquiryIdInquiryPkeyConnect>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  connectByNodeId?: Maybe<IdInquiryNodeIdConnect>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  deleteById?: Maybe<IdInquiryIdInquiryPkeyDelete>;
  /** The primary key(s) for `idInquiry` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IdInquiryNodeIdDelete>;
  /** The primary key(s) and patch data for `idInquiry` for the far side of the relationship. */
  updateById?: Maybe<IdInquiryOnIdSessionForIdSessionInquiryIdFkeyUsingIdInquiryPkeyUpdate>;
  /** The primary key(s) and patch data for `idInquiry` for the far side of the relationship. */
  updateByNodeId?: Maybe<IdSessionOnIdSessionForIdSessionInquiryIdFkeyNodeIdUpdate>;
  /** A `IdInquiryInput` object that will be created and connected to this object. */
  create?: Maybe<IdSessionInquiryIdFkeyIdInquiryCreateInput>;
};

/** Input for the nested mutation of `idSession` in the `IdInquiryInput` mutation. */
export type IdSessionInquiryIdFkeyInverseInput = {
  /** Flag indicating whether all other `idSession` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `idSession` for the far side of the relationship. */
  connectById?: Maybe<Array<IdSessionIdSessionPkeyConnect>>;
  /** The primary key(s) for `idSession` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IdSessionNodeIdConnect>>;
  /** The primary key(s) for `idSession` for the far side of the relationship. */
  deleteById?: Maybe<Array<IdSessionIdSessionPkeyDelete>>;
  /** The primary key(s) for `idSession` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IdSessionNodeIdDelete>>;
  /** The primary key(s) and patch data for `idSession` for the far side of the relationship. */
  updateById?: Maybe<Array<IdSessionOnIdSessionForIdSessionInquiryIdFkeyUsingIdSessionPkeyUpdate>>;
  /** The primary key(s) and patch data for `idSession` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<IdInquiryOnIdSessionForIdSessionInquiryIdFkeyNodeIdUpdate>>;
  /** A `IdSessionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IdSessionInquiryIdFkeyIdSessionCreateInput>>;
};

export type IdSessionMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  inquiryId?: Maybe<IntFilter>;
};

export type IdSessionMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['Int']>;
};

export type IdSessionMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  inquiryId?: Maybe<IntFilter>;
};

export type IdSessionMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type IdSessionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `idSession` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IdSessionNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `idSession` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdSessionOnIdSessionForIdSessionInquiryIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idInquiry` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idInquiry` being updated. */
  patch: IdInquiryPatch;
};

/** The fields on `idSession` to look up the row to update. */
export type IdSessionOnIdSessionForIdSessionInquiryIdFkeyUsingIdSessionPkeyUpdate = {
  /** An object where the defined keys will be set on the `idSession` being updated. */
  patch: UpdateIdSessionOnIdSessionForIdSessionInquiryIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `IdSession`. Fields that are set will be updated. */
export type IdSessionPatch = {
  id?: Maybe<Scalars['Int']>;
  inquiryId?: Maybe<Scalars['Int']>;
  threat?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  maps?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Scalars['String']>>>;
  startedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idSessionId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdSessionInquiryIdFkeyInput>;
};

export type IdSessionStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inquiryId?: Maybe<BigFloatFilter>;
};

export type IdSessionStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigFloat']>;
};

export type IdSessionStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inquiryId?: Maybe<BigFloatFilter>;
};

export type IdSessionStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigFloat']>;
};

export type IdSessionSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  inquiryId?: Maybe<BigIntFilter>;
};

export type IdSessionSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of inquiryId across the matching connection */
  inquiryId: Scalars['BigInt'];
};

export type IdSessionVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inquiryId?: Maybe<BigFloatFilter>;
};

export type IdSessionVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigFloat']>;
};

export type IdSessionVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inquiryId?: Maybe<BigFloatFilter>;
};

export type IdSessionVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of inquiryId across the matching connection */
  inquiryId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `IdSession` values. */
export type IdSessionsConnection = {
  /** A list of `IdSession` objects. */
  nodes: Array<IdSession>;
  /** A list of edges which contains the `IdSession` and cursor to aid in pagination. */
  edges: Array<IdSessionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdSession` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IdSessionAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IdSessionAggregates>>;
};


/** A connection to a list of `IdSession` values. */
export type IdSessionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IdSessionGroupBy>;
  having?: Maybe<IdSessionHavingInput>;
};

/** A `IdSession` edge in the connection. */
export type IdSessionsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdSession` at the end of the edge. */
  node: IdSession;
};

/** Methods to use when ordering `IdSession`. */
export enum IdSessionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InquiryIdAsc = 'INQUIRY_ID_ASC',
  InquiryIdDesc = 'INQUIRY_ID_DESC',
  ThreatAsc = 'THREAT_ASC',
  ThreatDesc = 'THREAT_DESC',
  LocationAsc = 'LOCATION_ASC',
  LocationDesc = 'LOCATION_DESC',
  MapsAsc = 'MAPS_ASC',
  MapsDesc = 'MAPS_DESC',
  DeviceAsc = 'DEVICE_ASC',
  DeviceDesc = 'DEVICE_DESC',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  StartedAtAsc = 'STARTED_AT_ASC',
  StartedAtDesc = 'STARTED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdSessionIdAsc = 'ID_SESSION_ID_ASC',
  IdSessionIdDesc = 'ID_SESSION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum IdStatus {
  NotRun = 'NOT_RUN',
  Passed = 'PASSED',
  Failed = 'FAILED',
  NeedsReview = 'NEEDS_REVIEW',
  NotApplicable = 'NOT_APPLICABLE'
}

/** A filter to be used against IdStatus fields. All fields are combined with a logical ‘and.’ */
export type IdStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<IdStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<IdStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<IdStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<IdStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<IdStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<IdStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<IdStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<IdStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<IdStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<IdStatus>;
};

export enum IdSupportedCountries {
  Us = 'US',
  Ca = 'CA'
}

export enum IdToolTemplateType {
  FrontBackSelfieLiveness = 'FrontBackSelfieLiveness',
  FrontSelfieLiveness = 'FrontSelfieLiveness',
  FrontBackSelfie = 'FrontBackSelfie',
  FrontSelfie = 'FrontSelfie',
  FrontBack = 'FrontBack',
  Front = 'Front'
}

export type IdVerification = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  verificationId?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
  verificationUserId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  firstStep?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['JSON']>;
  nextStep?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  /** Reads a single `Lead` that is related to this `IdVerification`. */
  lead?: Maybe<Lead>;
};

export type IdVerificationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IdVerificationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IdVerificationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IdVerificationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IdVerificationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IdVerificationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IdVerificationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IdVerificationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IdVerificationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IdVerificationVariancePopulationAggregates>;
};

export type IdVerificationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `IdVerification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type IdVerificationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `verificationId` field. */
  verificationId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `verificationToken` field. */
  verificationToken?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `verificationUserId` field. */
  verificationUserId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `firstStep` field. */
  firstStep?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `transaction` field. */
  transaction?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `nextStep` field. */
  nextStep?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `templateType` field. */
  templateType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `verificationUrl` field. */
  verificationUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: Maybe<Scalars['String']>;
};

export type IdVerificationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verificationToken across the matching connection */
  verificationToken?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verificationUserId across the matching connection */
  verificationUserId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of firstStep across the matching connection */
  firstStep?: Maybe<Scalars['BigInt']>;
  /** Distinct count of transaction across the matching connection */
  transaction?: Maybe<Scalars['BigInt']>;
  /** Distinct count of nextStep across the matching connection */
  nextStep?: Maybe<Scalars['BigInt']>;
  /** Distinct count of templateType across the matching connection */
  templateType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verificationUrl across the matching connection */
  verificationUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `IdVerification` object types. All fields are combined with a logical ‘and.’ */
export type IdVerificationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `verificationId` field. */
  verificationId?: Maybe<StringFilter>;
  /** Filter by the object’s `verificationToken` field. */
  verificationToken?: Maybe<StringFilter>;
  /** Filter by the object’s `verificationUserId` field. */
  verificationUserId?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `firstStep` field. */
  firstStep?: Maybe<StringFilter>;
  /** Filter by the object’s `transaction` field. */
  transaction?: Maybe<JsonFilter>;
  /** Filter by the object’s `nextStep` field. */
  nextStep?: Maybe<StringFilter>;
  /** Filter by the object’s `templateType` field. */
  templateType?: Maybe<StringFilter>;
  /** Filter by the object’s `verificationUrl` field. */
  verificationUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: Maybe<StringFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IdVerificationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IdVerificationFilter>>;
  /** Negates the expression. */
  not?: Maybe<IdVerificationFilter>;
};

/** Grouping methods for `IdVerification` for usage during aggregation. */
export enum IdVerificationGroupBy {
  VerificationId = 'VERIFICATION_ID',
  VerificationToken = 'VERIFICATION_TOKEN',
  VerificationUserId = 'VERIFICATION_USER_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  FirstStep = 'FIRST_STEP',
  Transaction = 'TRANSACTION',
  NextStep = 'NEXT_STEP',
  TemplateType = 'TEMPLATE_TYPE',
  VerificationUrl = 'VERIFICATION_URL',
  Provider = 'PROVIDER'
}

export type IdVerificationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `IdVerification` aggregates. */
export type IdVerificationHavingInput = {
  AND?: Maybe<Array<IdVerificationHavingInput>>;
  OR?: Maybe<Array<IdVerificationHavingInput>>;
  sum?: Maybe<IdVerificationHavingSumInput>;
  distinctCount?: Maybe<IdVerificationHavingDistinctCountInput>;
  min?: Maybe<IdVerificationHavingMinInput>;
  max?: Maybe<IdVerificationHavingMaxInput>;
  average?: Maybe<IdVerificationHavingAverageInput>;
  stddevSample?: Maybe<IdVerificationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<IdVerificationHavingStddevPopulationInput>;
  varianceSample?: Maybe<IdVerificationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<IdVerificationHavingVariancePopulationInput>;
};

export type IdVerificationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** The fields on `idVerification` to look up the row to connect. */
export type IdVerificationIdVerificationLeadIdKeyConnect = {
  leadId: Scalars['UUID'];
};

/** The fields on `idVerification` to look up the row to delete. */
export type IdVerificationIdVerificationLeadIdKeyDelete = {
  leadId: Scalars['UUID'];
};

/** The fields on `idVerification` to look up the row to connect. */
export type IdVerificationIdVerificationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `idVerification` to look up the row to delete. */
export type IdVerificationIdVerificationPkeyDelete = {
  id: Scalars['Int'];
};

/** An input for mutations affecting `IdVerification` */
export type IdVerificationInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  verificationId?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
  verificationUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  firstStep?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['JSON']>;
  nextStep?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IdVerificationLeadIdFkeyInput>;
};

/** The `idVerification` to be created by this mutation. */
export type IdVerificationLeadIdFkeyIdVerificationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  verificationId?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
  verificationUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  firstStep?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['JSON']>;
  nextStep?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IdVerificationLeadIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `IdVerificationInput` mutation. */
export type IdVerificationLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnIdVerificationForIdVerificationLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<IdVerificationLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `idVerification` in the `LeadInput` mutation. */
export type IdVerificationLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `idVerification` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  connectById?: Maybe<IdVerificationIdVerificationPkeyConnect>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  connectByLeadId?: Maybe<IdVerificationIdVerificationLeadIdKeyConnect>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  connectByNodeId?: Maybe<IdVerificationNodeIdConnect>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  deleteById?: Maybe<IdVerificationIdVerificationPkeyDelete>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  deleteByLeadId?: Maybe<IdVerificationIdVerificationLeadIdKeyDelete>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IdVerificationNodeIdDelete>;
  /** The primary key(s) and patch data for `idVerification` for the far side of the relationship. */
  updateById?: Maybe<IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyUsingIdVerificationPkeyUpdate>;
  /** The primary key(s) and patch data for `idVerification` for the far side of the relationship. */
  updateByLeadId?: Maybe<IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyUsingIdVerificationLeadIdKeyUpdate>;
  /** The primary key(s) and patch data for `idVerification` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnIdVerificationForIdVerificationLeadIdFkeyNodeIdUpdate>;
  /** A `IdVerificationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IdVerificationLeadIdFkeyIdVerificationCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type IdVerificationLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export type IdVerificationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type IdVerificationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type IdVerificationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `idVerification` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IdVerificationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `idVerification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `idVerification` to look up the row to update. */
export type IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyUsingIdVerificationLeadIdKeyUpdate = {
  /** An object where the defined keys will be set on the `idVerification` being updated. */
  patch: UpdateIdVerificationOnIdVerificationForIdVerificationLeadIdFkeyPatch;
  leadId: Scalars['UUID'];
};

/** The fields on `idVerification` to look up the row to update. */
export type IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyUsingIdVerificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `idVerification` being updated. */
  patch: UpdateIdVerificationOnIdVerificationForIdVerificationLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `IdVerification`. Fields that are set will be updated. */
export type IdVerificationPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  verificationId?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
  verificationUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  firstStep?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['JSON']>;
  nextStep?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IdVerificationLeadIdFkeyInput>;
};

export type IdVerificationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IdVerificationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IdVerificationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type IdVerificationTemplateResponse = {
  success?: Maybe<Scalars['Boolean']>;
  templateType?: Maybe<IdToolTemplateType>;
  alreadyVerified?: Maybe<Scalars['Boolean']>;
  isIdEnabled: Scalars['Boolean'];
  nextStep?: Maybe<Scalars['String']>;
  provider?: Maybe<IdProvider>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type IdVerificationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IdVerificationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `IdVerification` values. */
export type IdVerificationsConnection = {
  /** A list of `IdVerification` objects. */
  nodes: Array<IdVerification>;
  /** A list of edges which contains the `IdVerification` and cursor to aid in pagination. */
  edges: Array<IdVerificationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdVerification` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IdVerificationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IdVerificationAggregates>>;
};


/** A connection to a list of `IdVerification` values. */
export type IdVerificationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IdVerificationGroupBy>;
  having?: Maybe<IdVerificationHavingInput>;
};

/** A `IdVerification` edge in the connection. */
export type IdVerificationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdVerification` at the end of the edge. */
  node: IdVerification;
};

/** Methods to use when ordering `IdVerification`. */
export enum IdVerificationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  VerificationIdAsc = 'VERIFICATION_ID_ASC',
  VerificationIdDesc = 'VERIFICATION_ID_DESC',
  VerificationTokenAsc = 'VERIFICATION_TOKEN_ASC',
  VerificationTokenDesc = 'VERIFICATION_TOKEN_DESC',
  VerificationUserIdAsc = 'VERIFICATION_USER_ID_ASC',
  VerificationUserIdDesc = 'VERIFICATION_USER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  FirstStepAsc = 'FIRST_STEP_ASC',
  FirstStepDesc = 'FIRST_STEP_DESC',
  TransactionAsc = 'TRANSACTION_ASC',
  TransactionDesc = 'TRANSACTION_DESC',
  NextStepAsc = 'NEXT_STEP_ASC',
  NextStepDesc = 'NEXT_STEP_DESC',
  TemplateTypeAsc = 'TEMPLATE_TYPE_ASC',
  TemplateTypeDesc = 'TEMPLATE_TYPE_DESC',
  VerificationUrlAsc = 'VERIFICATION_URL_ASC',
  VerificationUrlDesc = 'VERIFICATION_URL_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderDesc = 'PROVIDER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Images = {
  front?: Maybe<IdImageDetails>;
  selfie?: Maybe<IdImageDetails>;
  back?: Maybe<IdImageDetails>;
  liveness?: Maybe<Scalars['JSON']>;
};

export type IncomeAccount = {
  id?: Maybe<Scalars['String']>;
  transitNumber?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  institutionNumber?: Maybe<Scalars['String']>;
  overdraftLimit?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  balance?: Maybe<IncomeAccountBalance>;
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  holder?: Maybe<IncomeAccountHolder>;
  transactions?: Maybe<Array<IncomeAccountTransaction>>;
};

export type IncomeAccountBalance = {
  available?: Maybe<Scalars['Float']>;
  current?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};

export type IncomeAccountHolder = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<IncomeAccountHolderAddress>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type IncomeAccountHolderAddress = {
  civicAddress?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type IncomeAccountTransaction = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  debit?: Maybe<Scalars['Float']>;
  credit?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
};

export type IncomeAttributes = {
  estimatedAnnualIncome?: Maybe<Scalars['Float']>;
  verifiedIncome?: Maybe<Scalars['Float']>;
  employerIncomeFrequency?: Maybe<Scalars['String']>;
  employerName?: Maybe<Scalars['String']>;
  loanElements?: Maybe<LoanElements>;
  riskElements?: Maybe<RiskElements>;
};

export type IncomeVerification = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  loginToken: Scalars['String'];
  directDepositAccountId?: Maybe<Scalars['String']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  rawAccounts?: Maybe<Scalars['JSON']>;
  report?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  verifiedAt?: Maybe<Scalars['Datetime']>;
  provider?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  /** Reads a single `Lead` that is related to this `IncomeVerification`. */
  lead?: Maybe<Lead>;
  accounts?: Maybe<Array<IncomeAccount>>;
  attributes?: Maybe<IncomeAttributes>;
};

export type IncomeVerificationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IncomeVerificationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IncomeVerificationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IncomeVerificationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IncomeVerificationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IncomeVerificationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IncomeVerificationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IncomeVerificationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IncomeVerificationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IncomeVerificationVariancePopulationAggregates>;
};

export type IncomeVerificationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `IncomeVerification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type IncomeVerificationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `loginToken` field. */
  loginToken?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `directDepositAccountId` field. */
  directDepositAccountId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rawAttributes` field. */
  rawAttributes?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rawAccounts` field. */
  rawAccounts?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `report` field. */
  report?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `verifiedAt` field. */
  verifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `accessToken` field. */
  accessToken?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['String']>;
};

export type IncomeVerificationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of loginToken across the matching connection */
  loginToken?: Maybe<Scalars['BigInt']>;
  /** Distinct count of directDepositAccountId across the matching connection */
  directDepositAccountId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawAttributes across the matching connection */
  rawAttributes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawAccounts across the matching connection */
  rawAccounts?: Maybe<Scalars['BigInt']>;
  /** Distinct count of report across the matching connection */
  report?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verifiedAt across the matching connection */
  verifiedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accessToken across the matching connection */
  accessToken?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `IncomeVerification` object types. All fields are combined with a logical ‘and.’ */
export type IncomeVerificationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `loginToken` field. */
  loginToken?: Maybe<StringFilter>;
  /** Filter by the object’s `directDepositAccountId` field. */
  directDepositAccountId?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `verifiedAt` field. */
  verifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: Maybe<StringFilter>;
  /** Filter by the object’s `accessToken` field. */
  accessToken?: Maybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<StringFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IncomeVerificationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IncomeVerificationFilter>>;
  /** Negates the expression. */
  not?: Maybe<IncomeVerificationFilter>;
};

/** Grouping methods for `IncomeVerification` for usage during aggregation. */
export enum IncomeVerificationGroupBy {
  LoginToken = 'LOGIN_TOKEN',
  DirectDepositAccountId = 'DIRECT_DEPOSIT_ACCOUNT_ID',
  RawAttributes = 'RAW_ATTRIBUTES',
  RawAccounts = 'RAW_ACCOUNTS',
  Report = 'REPORT',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  VerifiedAt = 'VERIFIED_AT',
  VerifiedAtTruncatedToHour = 'VERIFIED_AT_TRUNCATED_TO_HOUR',
  VerifiedAtTruncatedToDay = 'VERIFIED_AT_TRUNCATED_TO_DAY',
  Provider = 'PROVIDER',
  AccessToken = 'ACCESS_TOKEN',
  UserId = 'USER_ID'
}

export type IncomeVerificationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `IncomeVerification` aggregates. */
export type IncomeVerificationHavingInput = {
  AND?: Maybe<Array<IncomeVerificationHavingInput>>;
  OR?: Maybe<Array<IncomeVerificationHavingInput>>;
  sum?: Maybe<IncomeVerificationHavingSumInput>;
  distinctCount?: Maybe<IncomeVerificationHavingDistinctCountInput>;
  min?: Maybe<IncomeVerificationHavingMinInput>;
  max?: Maybe<IncomeVerificationHavingMaxInput>;
  average?: Maybe<IncomeVerificationHavingAverageInput>;
  stddevSample?: Maybe<IncomeVerificationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<IncomeVerificationHavingStddevPopulationInput>;
  varianceSample?: Maybe<IncomeVerificationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<IncomeVerificationHavingVariancePopulationInput>;
};

export type IncomeVerificationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

/** The fields on `incomeVerification` to look up the row to connect. */
export type IncomeVerificationIncomeVerificationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `incomeVerification` to look up the row to delete. */
export type IncomeVerificationIncomeVerificationPkeyDelete = {
  id: Scalars['Int'];
};

/** The fields on `incomeVerification` to look up the row to connect. */
export type IncomeVerificationIncomeVerificationUniqueToLeadKeyConnect = {
  leadId: Scalars['UUID'];
};

/** The fields on `incomeVerification` to look up the row to delete. */
export type IncomeVerificationIncomeVerificationUniqueToLeadKeyDelete = {
  leadId: Scalars['UUID'];
};

/** An input for mutations affecting `IncomeVerification` */
export type IncomeVerificationInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  loginToken: Scalars['String'];
  directDepositAccountId?: Maybe<Scalars['String']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  rawAccounts?: Maybe<Scalars['JSON']>;
  report?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  verifiedAt?: Maybe<Scalars['Datetime']>;
  provider?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IncomeVerificationLeadIdFkeyInput>;
};

/** The `incomeVerification` to be created by this mutation. */
export type IncomeVerificationLeadIdFkeyIncomeVerificationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  loginToken: Scalars['String'];
  directDepositAccountId?: Maybe<Scalars['String']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  rawAccounts?: Maybe<Scalars['JSON']>;
  report?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  verifiedAt?: Maybe<Scalars['Datetime']>;
  provider?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IncomeVerificationLeadIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `IncomeVerificationInput` mutation. */
export type IncomeVerificationLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<IncomeVerificationLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `incomeVerification` in the `LeadInput` mutation. */
export type IncomeVerificationLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `incomeVerification` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  connectById?: Maybe<IncomeVerificationIncomeVerificationPkeyConnect>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  connectByLeadId?: Maybe<IncomeVerificationIncomeVerificationUniqueToLeadKeyConnect>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  connectByNodeId?: Maybe<IncomeVerificationNodeIdConnect>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  deleteById?: Maybe<IncomeVerificationIncomeVerificationPkeyDelete>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  deleteByLeadId?: Maybe<IncomeVerificationIncomeVerificationUniqueToLeadKeyDelete>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IncomeVerificationNodeIdDelete>;
  /** The primary key(s) and patch data for `incomeVerification` for the far side of the relationship. */
  updateById?: Maybe<IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingIncomeVerificationPkeyUpdate>;
  /** The primary key(s) and patch data for `incomeVerification` for the far side of the relationship. */
  updateByLeadId?: Maybe<IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingIncomeVerificationUniqueToLeadKeyUpdate>;
  /** The primary key(s) and patch data for `incomeVerification` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyNodeIdUpdate>;
  /** A `IncomeVerificationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IncomeVerificationLeadIdFkeyIncomeVerificationCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type IncomeVerificationLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export type IncomeVerificationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type IncomeVerificationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type IncomeVerificationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `incomeVerification` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IncomeVerificationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `incomeVerification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `incomeVerification` to look up the row to update. */
export type IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingIncomeVerificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `incomeVerification` being updated. */
  patch: UpdateIncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `incomeVerification` to look up the row to update. */
export type IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingIncomeVerificationUniqueToLeadKeyUpdate = {
  /** An object where the defined keys will be set on the `incomeVerification` being updated. */
  patch: UpdateIncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyPatch;
  leadId: Scalars['UUID'];
};

/** Represents an update to a `IncomeVerification`. Fields that are set will be updated. */
export type IncomeVerificationPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  loginToken?: Maybe<Scalars['String']>;
  directDepositAccountId?: Maybe<Scalars['String']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  rawAccounts?: Maybe<Scalars['JSON']>;
  report?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  verifiedAt?: Maybe<Scalars['Datetime']>;
  provider?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IncomeVerificationLeadIdFkeyInput>;
};

export type IncomeVerificationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IncomeVerificationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IncomeVerificationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type IncomeVerificationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IncomeVerificationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `IncomeVerification` values. */
export type IncomeVerificationsConnection = {
  /** A list of `IncomeVerification` objects. */
  nodes: Array<IncomeVerification>;
  /** A list of edges which contains the `IncomeVerification` and cursor to aid in pagination. */
  edges: Array<IncomeVerificationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IncomeVerification` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IncomeVerificationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IncomeVerificationAggregates>>;
};


/** A connection to a list of `IncomeVerification` values. */
export type IncomeVerificationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IncomeVerificationGroupBy>;
  having?: Maybe<IncomeVerificationHavingInput>;
};

/** A `IncomeVerification` edge in the connection. */
export type IncomeVerificationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IncomeVerification` at the end of the edge. */
  node: IncomeVerification;
};

/** Methods to use when ordering `IncomeVerification`. */
export enum IncomeVerificationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  LoginTokenAsc = 'LOGIN_TOKEN_ASC',
  LoginTokenDesc = 'LOGIN_TOKEN_DESC',
  DirectDepositAccountIdAsc = 'DIRECT_DEPOSIT_ACCOUNT_ID_ASC',
  DirectDepositAccountIdDesc = 'DIRECT_DEPOSIT_ACCOUNT_ID_DESC',
  RawAttributesAsc = 'RAW_ATTRIBUTES_ASC',
  RawAttributesDesc = 'RAW_ATTRIBUTES_DESC',
  RawAccountsAsc = 'RAW_ACCOUNTS_ASC',
  RawAccountsDesc = 'RAW_ACCOUNTS_DESC',
  ReportAsc = 'REPORT_ASC',
  ReportDesc = 'REPORT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VerifiedAtAsc = 'VERIFIED_AT_ASC',
  VerifiedAtDesc = 'VERIFIED_AT_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderDesc = 'PROVIDER_DESC',
  AccessTokenAsc = 'ACCESS_TOKEN_ASC',
  AccessTokenDesc = 'ACCESS_TOKEN_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Inquiry = {
  date?: Maybe<Scalars['String']>;
  inquirer?: Maybe<EquifaxEntity>;
  fields: Array<Scalars['String']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Int']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Int']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};

/** A filter to be used against Int List fields. All fields are combined with a logical ‘and.’ */
export type IntListFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Contains the specified list of values. */
  contains?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Contained by the specified list of values. */
  containedBy?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Any array item is equal to the specified value. */
  anyEqualTo?: Maybe<Scalars['Int']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: Maybe<Scalars['Int']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: Maybe<Scalars['Int']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: Maybe<Scalars['Int']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};

export type Integration = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  displayName: Scalars['String'];
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrations: CompanyIntegrationsConnection;
  /** Reads and enables pagination through a set of `ProductIntegration`. */
  productIntegrations: ProductIntegrationsConnection;
  /** Reads and enables pagination through a set of `IdInquiry`. */
  idInquiries: IdInquiriesConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyIntegrationIntegrationIdAndCompanyId: IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Product`. */
  productsByProductIntegrationIntegrationIdAndProductId: IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByIdInquiryIntegrationIdAndLeadId: IntegrationLeadsByIdInquiryIntegrationIdAndLeadIdManyToManyConnection;
  fieldConfigs?: Maybe<FieldConfigsConnection>;
};


export type IntegrationCompanyIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type IntegrationProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
  condition?: Maybe<ProductIntegrationCondition>;
  filter?: Maybe<ProductIntegrationFilter>;
};


export type IntegrationIdInquiriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiriesOrderBy>>;
  condition?: Maybe<IdInquiryCondition>;
  filter?: Maybe<IdInquiryFilter>;
};


export type IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type IntegrationProductsByProductIntegrationIntegrationIdAndProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


export type IntegrationLeadsByIdInquiryIntegrationIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type IntegrationFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

export type IntegrationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IntegrationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IntegrationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IntegrationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IntegrationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IntegrationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IntegrationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IntegrationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IntegrationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IntegrationVariancePopulationAggregates>;
};

export type IntegrationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Company` values, with data from `CompanyIntegration`. */
export type IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyIntegration`, and the cursor to aid in pagination. */
  edges: Array<IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyIntegration`. */
export type IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyIntegration`. */
export type IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrations: CompanyIntegrationsConnection;
};


/** A `Company` edge in the connection, with data from `CompanyIntegration`. */
export type IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyEdgeCompanyIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};

/**
 * A condition to be used against `Integration` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type IntegrationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
};

export type IntegrationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Integration` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `companyIntegrations` relation. */
  companyIntegrations?: Maybe<IntegrationToManyCompanyIntegrationFilter>;
  /** Some related `companyIntegrations` exist. */
  companyIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `productIntegrations` relation. */
  productIntegrations?: Maybe<IntegrationToManyProductIntegrationFilter>;
  /** Some related `productIntegrations` exist. */
  productIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `idInquiries` relation. */
  idInquiries?: Maybe<IntegrationToManyIdInquiryFilter>;
  /** Some related `idInquiries` exist. */
  idInquiriesExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IntegrationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IntegrationFilter>>;
  /** Negates the expression. */
  not?: Maybe<IntegrationFilter>;
};

/** Grouping methods for `Integration` for usage during aggregation. */
export enum IntegrationGroupBy {
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  DisplayName = 'DISPLAY_NAME'
}

export type IntegrationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Integration` aggregates. */
export type IntegrationHavingInput = {
  AND?: Maybe<Array<IntegrationHavingInput>>;
  OR?: Maybe<Array<IntegrationHavingInput>>;
  sum?: Maybe<IntegrationHavingSumInput>;
  distinctCount?: Maybe<IntegrationHavingDistinctCountInput>;
  min?: Maybe<IntegrationHavingMinInput>;
  max?: Maybe<IntegrationHavingMaxInput>;
  average?: Maybe<IntegrationHavingAverageInput>;
  stddevSample?: Maybe<IntegrationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<IntegrationHavingStddevPopulationInput>;
  varianceSample?: Maybe<IntegrationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<IntegrationHavingVariancePopulationInput>;
};

export type IntegrationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Integration` */
export type IntegrationInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryIntegrationIdFkeyInverseInput>;
};

/** The fields on `integration` to look up the row to connect. */
export type IntegrationIntegrationNameUniqueConnect = {
  name: Scalars['String'];
};

/** The fields on `integration` to look up the row to delete. */
export type IntegrationIntegrationNameUniqueDelete = {
  name: Scalars['String'];
};

/** The fields on `integration` to look up the row to connect. */
export type IntegrationIntegrationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `integration` to look up the row to delete. */
export type IntegrationIntegrationPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `Lead` values, with data from `IdInquiry`. */
export type IntegrationLeadsByIdInquiryIntegrationIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `IdInquiry`, and the cursor to aid in pagination. */
  edges: Array<IntegrationLeadsByIdInquiryIntegrationIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `IdInquiry`. */
export type IntegrationLeadsByIdInquiryIntegrationIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `IdInquiry`. */
export type IntegrationLeadsByIdInquiryIntegrationIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `IdInquiry`. */
  idInquiries: IdInquiriesConnection;
};


/** A `Lead` edge in the connection, with data from `IdInquiry`. */
export type IntegrationLeadsByIdInquiryIntegrationIdAndLeadIdManyToManyEdgeIdInquiriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiriesOrderBy>>;
  condition?: Maybe<IdInquiryCondition>;
  filter?: Maybe<IdInquiryFilter>;
};

export type IntegrationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type IntegrationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type IntegrationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `integration` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IntegrationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `integration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** The fields on `integration` to look up the row to update. */
export type IntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyUsingIntegrationNameUniqueUpdate = {
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: UpdateIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `integration` to look up the row to update. */
export type IntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyUsingIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: UpdateIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type IntegrationOnIdInquiryForIdInquiryIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idInquiry` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idInquiry` being updated. */
  patch: IdInquiryPatch;
};

/** The fields on `integration` to look up the row to update. */
export type IntegrationOnIdInquiryForIdInquiryIntegrationIdFkeyUsingIntegrationNameUniqueUpdate = {
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: UpdateIntegrationOnIdInquiryForIdInquiryIntegrationIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `integration` to look up the row to update. */
export type IntegrationOnIdInquiryForIdInquiryIntegrationIdFkeyUsingIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: UpdateIntegrationOnIdInquiryForIdInquiryIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type IntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `productIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `productIntegration` being updated. */
  patch: ProductIntegrationPatch;
};

/** The fields on `integration` to look up the row to update. */
export type IntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyUsingIntegrationNameUniqueUpdate = {
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: UpdateIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `integration` to look up the row to update. */
export type IntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyUsingIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: UpdateIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Integration`. Fields that are set will be updated. */
export type IntegrationPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryIntegrationIdFkeyInverseInput>;
};

/** A connection to a list of `Product` values, with data from `ProductIntegration`. */
export type IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyConnection = {
  /** A list of `Product` objects. */
  nodes: Array<Product>;
  /** A list of edges which contains the `Product`, info from the `ProductIntegration`, and the cursor to aid in pagination. */
  edges: Array<IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductAggregates>>;
};


/** A connection to a list of `Product` values, with data from `ProductIntegration`. */
export type IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductGroupBy>;
  having?: Maybe<ProductHavingInput>;
};

/** A `Product` edge in the connection, with data from `ProductIntegration`. */
export type IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node: Product;
  /** Reads and enables pagination through a set of `ProductIntegration`. */
  productIntegrations: ProductIntegrationsConnection;
};


/** A `Product` edge in the connection, with data from `ProductIntegration`. */
export type IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyEdgeProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
  condition?: Maybe<ProductIntegrationCondition>;
  filter?: Maybe<ProductIntegrationFilter>;
};

export type IntegrationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyIntegration` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationToManyCompanyIntegrationFilter = {
  /** Every related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyIntegrationFilter>;
  /** Some related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyIntegrationFilter>;
  /** No related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyIntegrationFilter>;
  /** Aggregates across related `CompanyIntegration` match the filter criteria. */
  aggregates?: Maybe<CompanyIntegrationAggregatesFilter>;
};

/** A filter to be used against many `IdInquiry` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationToManyIdInquiryFilter = {
  /** Every related `IdInquiry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<IdInquiryFilter>;
  /** Some related `IdInquiry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<IdInquiryFilter>;
  /** No related `IdInquiry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<IdInquiryFilter>;
  /** Aggregates across related `IdInquiry` match the filter criteria. */
  aggregates?: Maybe<IdInquiryAggregatesFilter>;
};

/** A filter to be used against many `ProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationToManyProductIntegrationFilter = {
  /** Every related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ProductIntegrationFilter>;
  /** Some related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ProductIntegrationFilter>;
  /** No related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ProductIntegrationFilter>;
  /** Aggregates across related `ProductIntegration` match the filter criteria. */
  aggregates?: Maybe<ProductIntegrationAggregatesFilter>;
};

export type IntegrationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Integration` values. */
export type IntegrationsConnection = {
  /** A list of `Integration` objects. */
  nodes: Array<Integration>;
  /** A list of edges which contains the `Integration` and cursor to aid in pagination. */
  edges: Array<IntegrationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Integration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationAggregates>>;
};


/** A connection to a list of `Integration` values. */
export type IntegrationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationGroupBy>;
  having?: Maybe<IntegrationHavingInput>;
};

/** A `Integration` edge in the connection. */
export type IntegrationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Integration` at the end of the edge. */
  node: Integration;
};

/** Methods to use when ordering `Integration`. */
export enum IntegrationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyIntegrationsCountAsc = 'COMPANY_INTEGRATIONS_COUNT_ASC',
  CompanyIntegrationsCountDesc = 'COMPANY_INTEGRATIONS_COUNT_DESC',
  CompanyIntegrationsSumIdAsc = 'COMPANY_INTEGRATIONS_SUM_ID_ASC',
  CompanyIntegrationsSumIdDesc = 'COMPANY_INTEGRATIONS_SUM_ID_DESC',
  CompanyIntegrationsSumIntegrationIdAsc = 'COMPANY_INTEGRATIONS_SUM_INTEGRATION_ID_ASC',
  CompanyIntegrationsSumIntegrationIdDesc = 'COMPANY_INTEGRATIONS_SUM_INTEGRATION_ID_DESC',
  CompanyIntegrationsSumCompanyIdAsc = 'COMPANY_INTEGRATIONS_SUM_COMPANY_ID_ASC',
  CompanyIntegrationsSumCompanyIdDesc = 'COMPANY_INTEGRATIONS_SUM_COMPANY_ID_DESC',
  CompanyIntegrationsSumEnabledAsc = 'COMPANY_INTEGRATIONS_SUM_ENABLED_ASC',
  CompanyIntegrationsSumEnabledDesc = 'COMPANY_INTEGRATIONS_SUM_ENABLED_DESC',
  CompanyIntegrationsSumCreatedAtAsc = 'COMPANY_INTEGRATIONS_SUM_CREATED_AT_ASC',
  CompanyIntegrationsSumCreatedAtDesc = 'COMPANY_INTEGRATIONS_SUM_CREATED_AT_DESC',
  CompanyIntegrationsSumUpdatedAtAsc = 'COMPANY_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  CompanyIntegrationsSumUpdatedAtDesc = 'COMPANY_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  CompanyIntegrationsSumNameAsc = 'COMPANY_INTEGRATIONS_SUM_NAME_ASC',
  CompanyIntegrationsSumNameDesc = 'COMPANY_INTEGRATIONS_SUM_NAME_DESC',
  CompanyIntegrationsSumDisplayNameAsc = 'COMPANY_INTEGRATIONS_SUM_DISPLAY_NAME_ASC',
  CompanyIntegrationsSumDisplayNameDesc = 'COMPANY_INTEGRATIONS_SUM_DISPLAY_NAME_DESC',
  CompanyIntegrationsDistinctCountIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  CompanyIntegrationsDistinctCountIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  CompanyIntegrationsDistinctCountIntegrationIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  CompanyIntegrationsDistinctCountIntegrationIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  CompanyIntegrationsDistinctCountCompanyIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyIntegrationsDistinctCountCompanyIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyIntegrationsDistinctCountEnabledAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyIntegrationsDistinctCountEnabledDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyIntegrationsDistinctCountCreatedAtAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyIntegrationsDistinctCountCreatedAtDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyIntegrationsDistinctCountUpdatedAtAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyIntegrationsDistinctCountUpdatedAtDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyIntegrationsDistinctCountNameAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_NAME_ASC',
  CompanyIntegrationsDistinctCountNameDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_NAME_DESC',
  CompanyIntegrationsDistinctCountDisplayNameAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_DISPLAY_NAME_ASC',
  CompanyIntegrationsDistinctCountDisplayNameDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_DISPLAY_NAME_DESC',
  CompanyIntegrationsMinIdAsc = 'COMPANY_INTEGRATIONS_MIN_ID_ASC',
  CompanyIntegrationsMinIdDesc = 'COMPANY_INTEGRATIONS_MIN_ID_DESC',
  CompanyIntegrationsMinIntegrationIdAsc = 'COMPANY_INTEGRATIONS_MIN_INTEGRATION_ID_ASC',
  CompanyIntegrationsMinIntegrationIdDesc = 'COMPANY_INTEGRATIONS_MIN_INTEGRATION_ID_DESC',
  CompanyIntegrationsMinCompanyIdAsc = 'COMPANY_INTEGRATIONS_MIN_COMPANY_ID_ASC',
  CompanyIntegrationsMinCompanyIdDesc = 'COMPANY_INTEGRATIONS_MIN_COMPANY_ID_DESC',
  CompanyIntegrationsMinEnabledAsc = 'COMPANY_INTEGRATIONS_MIN_ENABLED_ASC',
  CompanyIntegrationsMinEnabledDesc = 'COMPANY_INTEGRATIONS_MIN_ENABLED_DESC',
  CompanyIntegrationsMinCreatedAtAsc = 'COMPANY_INTEGRATIONS_MIN_CREATED_AT_ASC',
  CompanyIntegrationsMinCreatedAtDesc = 'COMPANY_INTEGRATIONS_MIN_CREATED_AT_DESC',
  CompanyIntegrationsMinUpdatedAtAsc = 'COMPANY_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  CompanyIntegrationsMinUpdatedAtDesc = 'COMPANY_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  CompanyIntegrationsMinNameAsc = 'COMPANY_INTEGRATIONS_MIN_NAME_ASC',
  CompanyIntegrationsMinNameDesc = 'COMPANY_INTEGRATIONS_MIN_NAME_DESC',
  CompanyIntegrationsMinDisplayNameAsc = 'COMPANY_INTEGRATIONS_MIN_DISPLAY_NAME_ASC',
  CompanyIntegrationsMinDisplayNameDesc = 'COMPANY_INTEGRATIONS_MIN_DISPLAY_NAME_DESC',
  CompanyIntegrationsMaxIdAsc = 'COMPANY_INTEGRATIONS_MAX_ID_ASC',
  CompanyIntegrationsMaxIdDesc = 'COMPANY_INTEGRATIONS_MAX_ID_DESC',
  CompanyIntegrationsMaxIntegrationIdAsc = 'COMPANY_INTEGRATIONS_MAX_INTEGRATION_ID_ASC',
  CompanyIntegrationsMaxIntegrationIdDesc = 'COMPANY_INTEGRATIONS_MAX_INTEGRATION_ID_DESC',
  CompanyIntegrationsMaxCompanyIdAsc = 'COMPANY_INTEGRATIONS_MAX_COMPANY_ID_ASC',
  CompanyIntegrationsMaxCompanyIdDesc = 'COMPANY_INTEGRATIONS_MAX_COMPANY_ID_DESC',
  CompanyIntegrationsMaxEnabledAsc = 'COMPANY_INTEGRATIONS_MAX_ENABLED_ASC',
  CompanyIntegrationsMaxEnabledDesc = 'COMPANY_INTEGRATIONS_MAX_ENABLED_DESC',
  CompanyIntegrationsMaxCreatedAtAsc = 'COMPANY_INTEGRATIONS_MAX_CREATED_AT_ASC',
  CompanyIntegrationsMaxCreatedAtDesc = 'COMPANY_INTEGRATIONS_MAX_CREATED_AT_DESC',
  CompanyIntegrationsMaxUpdatedAtAsc = 'COMPANY_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  CompanyIntegrationsMaxUpdatedAtDesc = 'COMPANY_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  CompanyIntegrationsMaxNameAsc = 'COMPANY_INTEGRATIONS_MAX_NAME_ASC',
  CompanyIntegrationsMaxNameDesc = 'COMPANY_INTEGRATIONS_MAX_NAME_DESC',
  CompanyIntegrationsMaxDisplayNameAsc = 'COMPANY_INTEGRATIONS_MAX_DISPLAY_NAME_ASC',
  CompanyIntegrationsMaxDisplayNameDesc = 'COMPANY_INTEGRATIONS_MAX_DISPLAY_NAME_DESC',
  CompanyIntegrationsAverageIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_ID_ASC',
  CompanyIntegrationsAverageIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_ID_DESC',
  CompanyIntegrationsAverageIntegrationIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_INTEGRATION_ID_ASC',
  CompanyIntegrationsAverageIntegrationIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_INTEGRATION_ID_DESC',
  CompanyIntegrationsAverageCompanyIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_COMPANY_ID_ASC',
  CompanyIntegrationsAverageCompanyIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_COMPANY_ID_DESC',
  CompanyIntegrationsAverageEnabledAsc = 'COMPANY_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  CompanyIntegrationsAverageEnabledDesc = 'COMPANY_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  CompanyIntegrationsAverageCreatedAtAsc = 'COMPANY_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  CompanyIntegrationsAverageCreatedAtDesc = 'COMPANY_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  CompanyIntegrationsAverageUpdatedAtAsc = 'COMPANY_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  CompanyIntegrationsAverageUpdatedAtDesc = 'COMPANY_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  CompanyIntegrationsAverageNameAsc = 'COMPANY_INTEGRATIONS_AVERAGE_NAME_ASC',
  CompanyIntegrationsAverageNameDesc = 'COMPANY_INTEGRATIONS_AVERAGE_NAME_DESC',
  CompanyIntegrationsAverageDisplayNameAsc = 'COMPANY_INTEGRATIONS_AVERAGE_DISPLAY_NAME_ASC',
  CompanyIntegrationsAverageDisplayNameDesc = 'COMPANY_INTEGRATIONS_AVERAGE_DISPLAY_NAME_DESC',
  CompanyIntegrationsStddevSampleIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  CompanyIntegrationsStddevSampleIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  CompanyIntegrationsStddevSampleIntegrationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  CompanyIntegrationsStddevSampleIntegrationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  CompanyIntegrationsStddevSampleCompanyIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyIntegrationsStddevSampleCompanyIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyIntegrationsStddevSampleEnabledAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyIntegrationsStddevSampleEnabledDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyIntegrationsStddevSampleCreatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyIntegrationsStddevSampleCreatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyIntegrationsStddevSampleUpdatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyIntegrationsStddevSampleUpdatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyIntegrationsStddevSampleNameAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_NAME_ASC',
  CompanyIntegrationsStddevSampleNameDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_NAME_DESC',
  CompanyIntegrationsStddevSampleDisplayNameAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_DISPLAY_NAME_ASC',
  CompanyIntegrationsStddevSampleDisplayNameDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_DISPLAY_NAME_DESC',
  CompanyIntegrationsStddevPopulationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  CompanyIntegrationsStddevPopulationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  CompanyIntegrationsStddevPopulationIntegrationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  CompanyIntegrationsStddevPopulationIntegrationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  CompanyIntegrationsStddevPopulationCompanyIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyIntegrationsStddevPopulationCompanyIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyIntegrationsStddevPopulationEnabledAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyIntegrationsStddevPopulationEnabledDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyIntegrationsStddevPopulationCreatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyIntegrationsStddevPopulationCreatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyIntegrationsStddevPopulationUpdatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyIntegrationsStddevPopulationUpdatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyIntegrationsStddevPopulationNameAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_NAME_ASC',
  CompanyIntegrationsStddevPopulationNameDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_NAME_DESC',
  CompanyIntegrationsStddevPopulationDisplayNameAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_DISPLAY_NAME_ASC',
  CompanyIntegrationsStddevPopulationDisplayNameDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_DISPLAY_NAME_DESC',
  CompanyIntegrationsVarianceSampleIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  CompanyIntegrationsVarianceSampleIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  CompanyIntegrationsVarianceSampleIntegrationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  CompanyIntegrationsVarianceSampleIntegrationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  CompanyIntegrationsVarianceSampleCompanyIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyIntegrationsVarianceSampleCompanyIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyIntegrationsVarianceSampleEnabledAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyIntegrationsVarianceSampleEnabledDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyIntegrationsVarianceSampleCreatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyIntegrationsVarianceSampleCreatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyIntegrationsVarianceSampleUpdatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyIntegrationsVarianceSampleUpdatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyIntegrationsVarianceSampleNameAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_NAME_ASC',
  CompanyIntegrationsVarianceSampleNameDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_NAME_DESC',
  CompanyIntegrationsVarianceSampleDisplayNameAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_DISPLAY_NAME_ASC',
  CompanyIntegrationsVarianceSampleDisplayNameDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_DISPLAY_NAME_DESC',
  CompanyIntegrationsVariancePopulationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  CompanyIntegrationsVariancePopulationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  CompanyIntegrationsVariancePopulationIntegrationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  CompanyIntegrationsVariancePopulationIntegrationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  CompanyIntegrationsVariancePopulationCompanyIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyIntegrationsVariancePopulationCompanyIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyIntegrationsVariancePopulationEnabledAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyIntegrationsVariancePopulationEnabledDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyIntegrationsVariancePopulationCreatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyIntegrationsVariancePopulationCreatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyIntegrationsVariancePopulationUpdatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyIntegrationsVariancePopulationUpdatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyIntegrationsVariancePopulationNameAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_NAME_ASC',
  CompanyIntegrationsVariancePopulationNameDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_NAME_DESC',
  CompanyIntegrationsVariancePopulationDisplayNameAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_DISPLAY_NAME_ASC',
  CompanyIntegrationsVariancePopulationDisplayNameDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_DISPLAY_NAME_DESC',
  ProductIntegrationsCountAsc = 'PRODUCT_INTEGRATIONS_COUNT_ASC',
  ProductIntegrationsCountDesc = 'PRODUCT_INTEGRATIONS_COUNT_DESC',
  ProductIntegrationsSumIdAsc = 'PRODUCT_INTEGRATIONS_SUM_ID_ASC',
  ProductIntegrationsSumIdDesc = 'PRODUCT_INTEGRATIONS_SUM_ID_DESC',
  ProductIntegrationsSumIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_SUM_INTEGRATION_ID_ASC',
  ProductIntegrationsSumIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_SUM_INTEGRATION_ID_DESC',
  ProductIntegrationsSumProductIdAsc = 'PRODUCT_INTEGRATIONS_SUM_PRODUCT_ID_ASC',
  ProductIntegrationsSumProductIdDesc = 'PRODUCT_INTEGRATIONS_SUM_PRODUCT_ID_DESC',
  ProductIntegrationsSumEnabledAsc = 'PRODUCT_INTEGRATIONS_SUM_ENABLED_ASC',
  ProductIntegrationsSumEnabledDesc = 'PRODUCT_INTEGRATIONS_SUM_ENABLED_DESC',
  ProductIntegrationsSumCreatedAtAsc = 'PRODUCT_INTEGRATIONS_SUM_CREATED_AT_ASC',
  ProductIntegrationsSumCreatedAtDesc = 'PRODUCT_INTEGRATIONS_SUM_CREATED_AT_DESC',
  ProductIntegrationsSumUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  ProductIntegrationsSumUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  ProductIntegrationsDistinctCountIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  ProductIntegrationsDistinctCountIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  ProductIntegrationsDistinctCountIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  ProductIntegrationsDistinctCountIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  ProductIntegrationsDistinctCountProductIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  ProductIntegrationsDistinctCountProductIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  ProductIntegrationsDistinctCountEnabledAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  ProductIntegrationsDistinctCountEnabledDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  ProductIntegrationsDistinctCountCreatedAtAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  ProductIntegrationsDistinctCountCreatedAtDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  ProductIntegrationsDistinctCountUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProductIntegrationsDistinctCountUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProductIntegrationsMinIdAsc = 'PRODUCT_INTEGRATIONS_MIN_ID_ASC',
  ProductIntegrationsMinIdDesc = 'PRODUCT_INTEGRATIONS_MIN_ID_DESC',
  ProductIntegrationsMinIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_MIN_INTEGRATION_ID_ASC',
  ProductIntegrationsMinIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_MIN_INTEGRATION_ID_DESC',
  ProductIntegrationsMinProductIdAsc = 'PRODUCT_INTEGRATIONS_MIN_PRODUCT_ID_ASC',
  ProductIntegrationsMinProductIdDesc = 'PRODUCT_INTEGRATIONS_MIN_PRODUCT_ID_DESC',
  ProductIntegrationsMinEnabledAsc = 'PRODUCT_INTEGRATIONS_MIN_ENABLED_ASC',
  ProductIntegrationsMinEnabledDesc = 'PRODUCT_INTEGRATIONS_MIN_ENABLED_DESC',
  ProductIntegrationsMinCreatedAtAsc = 'PRODUCT_INTEGRATIONS_MIN_CREATED_AT_ASC',
  ProductIntegrationsMinCreatedAtDesc = 'PRODUCT_INTEGRATIONS_MIN_CREATED_AT_DESC',
  ProductIntegrationsMinUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  ProductIntegrationsMinUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  ProductIntegrationsMaxIdAsc = 'PRODUCT_INTEGRATIONS_MAX_ID_ASC',
  ProductIntegrationsMaxIdDesc = 'PRODUCT_INTEGRATIONS_MAX_ID_DESC',
  ProductIntegrationsMaxIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_MAX_INTEGRATION_ID_ASC',
  ProductIntegrationsMaxIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_MAX_INTEGRATION_ID_DESC',
  ProductIntegrationsMaxProductIdAsc = 'PRODUCT_INTEGRATIONS_MAX_PRODUCT_ID_ASC',
  ProductIntegrationsMaxProductIdDesc = 'PRODUCT_INTEGRATIONS_MAX_PRODUCT_ID_DESC',
  ProductIntegrationsMaxEnabledAsc = 'PRODUCT_INTEGRATIONS_MAX_ENABLED_ASC',
  ProductIntegrationsMaxEnabledDesc = 'PRODUCT_INTEGRATIONS_MAX_ENABLED_DESC',
  ProductIntegrationsMaxCreatedAtAsc = 'PRODUCT_INTEGRATIONS_MAX_CREATED_AT_ASC',
  ProductIntegrationsMaxCreatedAtDesc = 'PRODUCT_INTEGRATIONS_MAX_CREATED_AT_DESC',
  ProductIntegrationsMaxUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  ProductIntegrationsMaxUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  ProductIntegrationsAverageIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_ID_ASC',
  ProductIntegrationsAverageIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_ID_DESC',
  ProductIntegrationsAverageIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_INTEGRATION_ID_ASC',
  ProductIntegrationsAverageIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_INTEGRATION_ID_DESC',
  ProductIntegrationsAverageProductIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_PRODUCT_ID_ASC',
  ProductIntegrationsAverageProductIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_PRODUCT_ID_DESC',
  ProductIntegrationsAverageEnabledAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  ProductIntegrationsAverageEnabledDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  ProductIntegrationsAverageCreatedAtAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  ProductIntegrationsAverageCreatedAtDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  ProductIntegrationsAverageUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  ProductIntegrationsAverageUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  ProductIntegrationsStddevSampleIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  ProductIntegrationsStddevSampleIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  ProductIntegrationsStddevSampleIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  ProductIntegrationsStddevSampleIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  ProductIntegrationsStddevSampleProductIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  ProductIntegrationsStddevSampleProductIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  ProductIntegrationsStddevSampleEnabledAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  ProductIntegrationsStddevSampleEnabledDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  ProductIntegrationsStddevSampleCreatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProductIntegrationsStddevSampleCreatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProductIntegrationsStddevSampleUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProductIntegrationsStddevSampleUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProductIntegrationsStddevPopulationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  ProductIntegrationsStddevPopulationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  ProductIntegrationsStddevPopulationIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  ProductIntegrationsStddevPopulationIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  ProductIntegrationsStddevPopulationProductIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  ProductIntegrationsStddevPopulationProductIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  ProductIntegrationsStddevPopulationEnabledAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  ProductIntegrationsStddevPopulationEnabledDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  ProductIntegrationsStddevPopulationCreatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  ProductIntegrationsStddevPopulationCreatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  ProductIntegrationsStddevPopulationUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProductIntegrationsStddevPopulationUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProductIntegrationsVarianceSampleIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  ProductIntegrationsVarianceSampleIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  ProductIntegrationsVarianceSampleIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  ProductIntegrationsVarianceSampleIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  ProductIntegrationsVarianceSampleProductIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  ProductIntegrationsVarianceSampleProductIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  ProductIntegrationsVarianceSampleEnabledAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  ProductIntegrationsVarianceSampleEnabledDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  ProductIntegrationsVarianceSampleCreatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProductIntegrationsVarianceSampleCreatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProductIntegrationsVarianceSampleUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProductIntegrationsVarianceSampleUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProductIntegrationsVariancePopulationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  ProductIntegrationsVariancePopulationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  ProductIntegrationsVariancePopulationIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  ProductIntegrationsVariancePopulationIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  ProductIntegrationsVariancePopulationProductIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  ProductIntegrationsVariancePopulationProductIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  ProductIntegrationsVariancePopulationEnabledAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  ProductIntegrationsVariancePopulationEnabledDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  ProductIntegrationsVariancePopulationCreatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProductIntegrationsVariancePopulationCreatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProductIntegrationsVariancePopulationUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProductIntegrationsVariancePopulationUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  IdInquiriesCountAsc = 'ID_INQUIRIES_COUNT_ASC',
  IdInquiriesCountDesc = 'ID_INQUIRIES_COUNT_DESC',
  IdInquiriesSumIdAsc = 'ID_INQUIRIES_SUM_ID_ASC',
  IdInquiriesSumIdDesc = 'ID_INQUIRIES_SUM_ID_DESC',
  IdInquiriesSumLeadIdAsc = 'ID_INQUIRIES_SUM_LEAD_ID_ASC',
  IdInquiriesSumLeadIdDesc = 'ID_INQUIRIES_SUM_LEAD_ID_DESC',
  IdInquiriesSumIntegrationIdAsc = 'ID_INQUIRIES_SUM_INTEGRATION_ID_ASC',
  IdInquiriesSumIntegrationIdDesc = 'ID_INQUIRIES_SUM_INTEGRATION_ID_DESC',
  IdInquiriesSumStatusAsc = 'ID_INQUIRIES_SUM_STATUS_ASC',
  IdInquiriesSumStatusDesc = 'ID_INQUIRIES_SUM_STATUS_DESC',
  IdInquiriesSumFirstNameAsc = 'ID_INQUIRIES_SUM_FIRST_NAME_ASC',
  IdInquiriesSumFirstNameDesc = 'ID_INQUIRIES_SUM_FIRST_NAME_DESC',
  IdInquiriesSumLastNameAsc = 'ID_INQUIRIES_SUM_LAST_NAME_ASC',
  IdInquiriesSumLastNameDesc = 'ID_INQUIRIES_SUM_LAST_NAME_DESC',
  IdInquiriesSumAddressAsc = 'ID_INQUIRIES_SUM_ADDRESS_ASC',
  IdInquiriesSumAddressDesc = 'ID_INQUIRIES_SUM_ADDRESS_DESC',
  IdInquiriesSumCityAsc = 'ID_INQUIRIES_SUM_CITY_ASC',
  IdInquiriesSumCityDesc = 'ID_INQUIRIES_SUM_CITY_DESC',
  IdInquiriesSumProvinceAsc = 'ID_INQUIRIES_SUM_PROVINCE_ASC',
  IdInquiriesSumProvinceDesc = 'ID_INQUIRIES_SUM_PROVINCE_DESC',
  IdInquiriesSumPostalCodeAsc = 'ID_INQUIRIES_SUM_POSTAL_CODE_ASC',
  IdInquiriesSumPostalCodeDesc = 'ID_INQUIRIES_SUM_POSTAL_CODE_DESC',
  IdInquiriesSumBirthDateAsc = 'ID_INQUIRIES_SUM_BIRTH_DATE_ASC',
  IdInquiriesSumBirthDateDesc = 'ID_INQUIRIES_SUM_BIRTH_DATE_DESC',
  IdInquiriesSumIdNumberAsc = 'ID_INQUIRIES_SUM_ID_NUMBER_ASC',
  IdInquiriesSumIdNumberDesc = 'ID_INQUIRIES_SUM_ID_NUMBER_DESC',
  IdInquiriesSumCreatedAtAsc = 'ID_INQUIRIES_SUM_CREATED_AT_ASC',
  IdInquiriesSumCreatedAtDesc = 'ID_INQUIRIES_SUM_CREATED_AT_DESC',
  IdInquiriesSumUpdatedAtAsc = 'ID_INQUIRIES_SUM_UPDATED_AT_ASC',
  IdInquiriesSumUpdatedAtDesc = 'ID_INQUIRIES_SUM_UPDATED_AT_DESC',
  IdInquiriesSumRawInquiryAsc = 'ID_INQUIRIES_SUM_RAW_INQUIRY_ASC',
  IdInquiriesSumRawInquiryDesc = 'ID_INQUIRIES_SUM_RAW_INQUIRY_DESC',
  IdInquiriesSumInquiryIdAsc = 'ID_INQUIRIES_SUM_INQUIRY_ID_ASC',
  IdInquiriesSumInquiryIdDesc = 'ID_INQUIRIES_SUM_INQUIRY_ID_DESC',
  IdInquiriesDistinctCountIdAsc = 'ID_INQUIRIES_DISTINCT_COUNT_ID_ASC',
  IdInquiriesDistinctCountIdDesc = 'ID_INQUIRIES_DISTINCT_COUNT_ID_DESC',
  IdInquiriesDistinctCountLeadIdAsc = 'ID_INQUIRIES_DISTINCT_COUNT_LEAD_ID_ASC',
  IdInquiriesDistinctCountLeadIdDesc = 'ID_INQUIRIES_DISTINCT_COUNT_LEAD_ID_DESC',
  IdInquiriesDistinctCountIntegrationIdAsc = 'ID_INQUIRIES_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  IdInquiriesDistinctCountIntegrationIdDesc = 'ID_INQUIRIES_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  IdInquiriesDistinctCountStatusAsc = 'ID_INQUIRIES_DISTINCT_COUNT_STATUS_ASC',
  IdInquiriesDistinctCountStatusDesc = 'ID_INQUIRIES_DISTINCT_COUNT_STATUS_DESC',
  IdInquiriesDistinctCountFirstNameAsc = 'ID_INQUIRIES_DISTINCT_COUNT_FIRST_NAME_ASC',
  IdInquiriesDistinctCountFirstNameDesc = 'ID_INQUIRIES_DISTINCT_COUNT_FIRST_NAME_DESC',
  IdInquiriesDistinctCountLastNameAsc = 'ID_INQUIRIES_DISTINCT_COUNT_LAST_NAME_ASC',
  IdInquiriesDistinctCountLastNameDesc = 'ID_INQUIRIES_DISTINCT_COUNT_LAST_NAME_DESC',
  IdInquiriesDistinctCountAddressAsc = 'ID_INQUIRIES_DISTINCT_COUNT_ADDRESS_ASC',
  IdInquiriesDistinctCountAddressDesc = 'ID_INQUIRIES_DISTINCT_COUNT_ADDRESS_DESC',
  IdInquiriesDistinctCountCityAsc = 'ID_INQUIRIES_DISTINCT_COUNT_CITY_ASC',
  IdInquiriesDistinctCountCityDesc = 'ID_INQUIRIES_DISTINCT_COUNT_CITY_DESC',
  IdInquiriesDistinctCountProvinceAsc = 'ID_INQUIRIES_DISTINCT_COUNT_PROVINCE_ASC',
  IdInquiriesDistinctCountProvinceDesc = 'ID_INQUIRIES_DISTINCT_COUNT_PROVINCE_DESC',
  IdInquiriesDistinctCountPostalCodeAsc = 'ID_INQUIRIES_DISTINCT_COUNT_POSTAL_CODE_ASC',
  IdInquiriesDistinctCountPostalCodeDesc = 'ID_INQUIRIES_DISTINCT_COUNT_POSTAL_CODE_DESC',
  IdInquiriesDistinctCountBirthDateAsc = 'ID_INQUIRIES_DISTINCT_COUNT_BIRTH_DATE_ASC',
  IdInquiriesDistinctCountBirthDateDesc = 'ID_INQUIRIES_DISTINCT_COUNT_BIRTH_DATE_DESC',
  IdInquiriesDistinctCountIdNumberAsc = 'ID_INQUIRIES_DISTINCT_COUNT_ID_NUMBER_ASC',
  IdInquiriesDistinctCountIdNumberDesc = 'ID_INQUIRIES_DISTINCT_COUNT_ID_NUMBER_DESC',
  IdInquiriesDistinctCountCreatedAtAsc = 'ID_INQUIRIES_DISTINCT_COUNT_CREATED_AT_ASC',
  IdInquiriesDistinctCountCreatedAtDesc = 'ID_INQUIRIES_DISTINCT_COUNT_CREATED_AT_DESC',
  IdInquiriesDistinctCountUpdatedAtAsc = 'ID_INQUIRIES_DISTINCT_COUNT_UPDATED_AT_ASC',
  IdInquiriesDistinctCountUpdatedAtDesc = 'ID_INQUIRIES_DISTINCT_COUNT_UPDATED_AT_DESC',
  IdInquiriesDistinctCountRawInquiryAsc = 'ID_INQUIRIES_DISTINCT_COUNT_RAW_INQUIRY_ASC',
  IdInquiriesDistinctCountRawInquiryDesc = 'ID_INQUIRIES_DISTINCT_COUNT_RAW_INQUIRY_DESC',
  IdInquiriesDistinctCountInquiryIdAsc = 'ID_INQUIRIES_DISTINCT_COUNT_INQUIRY_ID_ASC',
  IdInquiriesDistinctCountInquiryIdDesc = 'ID_INQUIRIES_DISTINCT_COUNT_INQUIRY_ID_DESC',
  IdInquiriesMinIdAsc = 'ID_INQUIRIES_MIN_ID_ASC',
  IdInquiriesMinIdDesc = 'ID_INQUIRIES_MIN_ID_DESC',
  IdInquiriesMinLeadIdAsc = 'ID_INQUIRIES_MIN_LEAD_ID_ASC',
  IdInquiriesMinLeadIdDesc = 'ID_INQUIRIES_MIN_LEAD_ID_DESC',
  IdInquiriesMinIntegrationIdAsc = 'ID_INQUIRIES_MIN_INTEGRATION_ID_ASC',
  IdInquiriesMinIntegrationIdDesc = 'ID_INQUIRIES_MIN_INTEGRATION_ID_DESC',
  IdInquiriesMinStatusAsc = 'ID_INQUIRIES_MIN_STATUS_ASC',
  IdInquiriesMinStatusDesc = 'ID_INQUIRIES_MIN_STATUS_DESC',
  IdInquiriesMinFirstNameAsc = 'ID_INQUIRIES_MIN_FIRST_NAME_ASC',
  IdInquiriesMinFirstNameDesc = 'ID_INQUIRIES_MIN_FIRST_NAME_DESC',
  IdInquiriesMinLastNameAsc = 'ID_INQUIRIES_MIN_LAST_NAME_ASC',
  IdInquiriesMinLastNameDesc = 'ID_INQUIRIES_MIN_LAST_NAME_DESC',
  IdInquiriesMinAddressAsc = 'ID_INQUIRIES_MIN_ADDRESS_ASC',
  IdInquiriesMinAddressDesc = 'ID_INQUIRIES_MIN_ADDRESS_DESC',
  IdInquiriesMinCityAsc = 'ID_INQUIRIES_MIN_CITY_ASC',
  IdInquiriesMinCityDesc = 'ID_INQUIRIES_MIN_CITY_DESC',
  IdInquiriesMinProvinceAsc = 'ID_INQUIRIES_MIN_PROVINCE_ASC',
  IdInquiriesMinProvinceDesc = 'ID_INQUIRIES_MIN_PROVINCE_DESC',
  IdInquiriesMinPostalCodeAsc = 'ID_INQUIRIES_MIN_POSTAL_CODE_ASC',
  IdInquiriesMinPostalCodeDesc = 'ID_INQUIRIES_MIN_POSTAL_CODE_DESC',
  IdInquiriesMinBirthDateAsc = 'ID_INQUIRIES_MIN_BIRTH_DATE_ASC',
  IdInquiriesMinBirthDateDesc = 'ID_INQUIRIES_MIN_BIRTH_DATE_DESC',
  IdInquiriesMinIdNumberAsc = 'ID_INQUIRIES_MIN_ID_NUMBER_ASC',
  IdInquiriesMinIdNumberDesc = 'ID_INQUIRIES_MIN_ID_NUMBER_DESC',
  IdInquiriesMinCreatedAtAsc = 'ID_INQUIRIES_MIN_CREATED_AT_ASC',
  IdInquiriesMinCreatedAtDesc = 'ID_INQUIRIES_MIN_CREATED_AT_DESC',
  IdInquiriesMinUpdatedAtAsc = 'ID_INQUIRIES_MIN_UPDATED_AT_ASC',
  IdInquiriesMinUpdatedAtDesc = 'ID_INQUIRIES_MIN_UPDATED_AT_DESC',
  IdInquiriesMinRawInquiryAsc = 'ID_INQUIRIES_MIN_RAW_INQUIRY_ASC',
  IdInquiriesMinRawInquiryDesc = 'ID_INQUIRIES_MIN_RAW_INQUIRY_DESC',
  IdInquiriesMinInquiryIdAsc = 'ID_INQUIRIES_MIN_INQUIRY_ID_ASC',
  IdInquiriesMinInquiryIdDesc = 'ID_INQUIRIES_MIN_INQUIRY_ID_DESC',
  IdInquiriesMaxIdAsc = 'ID_INQUIRIES_MAX_ID_ASC',
  IdInquiriesMaxIdDesc = 'ID_INQUIRIES_MAX_ID_DESC',
  IdInquiriesMaxLeadIdAsc = 'ID_INQUIRIES_MAX_LEAD_ID_ASC',
  IdInquiriesMaxLeadIdDesc = 'ID_INQUIRIES_MAX_LEAD_ID_DESC',
  IdInquiriesMaxIntegrationIdAsc = 'ID_INQUIRIES_MAX_INTEGRATION_ID_ASC',
  IdInquiriesMaxIntegrationIdDesc = 'ID_INQUIRIES_MAX_INTEGRATION_ID_DESC',
  IdInquiriesMaxStatusAsc = 'ID_INQUIRIES_MAX_STATUS_ASC',
  IdInquiriesMaxStatusDesc = 'ID_INQUIRIES_MAX_STATUS_DESC',
  IdInquiriesMaxFirstNameAsc = 'ID_INQUIRIES_MAX_FIRST_NAME_ASC',
  IdInquiriesMaxFirstNameDesc = 'ID_INQUIRIES_MAX_FIRST_NAME_DESC',
  IdInquiriesMaxLastNameAsc = 'ID_INQUIRIES_MAX_LAST_NAME_ASC',
  IdInquiriesMaxLastNameDesc = 'ID_INQUIRIES_MAX_LAST_NAME_DESC',
  IdInquiriesMaxAddressAsc = 'ID_INQUIRIES_MAX_ADDRESS_ASC',
  IdInquiriesMaxAddressDesc = 'ID_INQUIRIES_MAX_ADDRESS_DESC',
  IdInquiriesMaxCityAsc = 'ID_INQUIRIES_MAX_CITY_ASC',
  IdInquiriesMaxCityDesc = 'ID_INQUIRIES_MAX_CITY_DESC',
  IdInquiriesMaxProvinceAsc = 'ID_INQUIRIES_MAX_PROVINCE_ASC',
  IdInquiriesMaxProvinceDesc = 'ID_INQUIRIES_MAX_PROVINCE_DESC',
  IdInquiriesMaxPostalCodeAsc = 'ID_INQUIRIES_MAX_POSTAL_CODE_ASC',
  IdInquiriesMaxPostalCodeDesc = 'ID_INQUIRIES_MAX_POSTAL_CODE_DESC',
  IdInquiriesMaxBirthDateAsc = 'ID_INQUIRIES_MAX_BIRTH_DATE_ASC',
  IdInquiriesMaxBirthDateDesc = 'ID_INQUIRIES_MAX_BIRTH_DATE_DESC',
  IdInquiriesMaxIdNumberAsc = 'ID_INQUIRIES_MAX_ID_NUMBER_ASC',
  IdInquiriesMaxIdNumberDesc = 'ID_INQUIRIES_MAX_ID_NUMBER_DESC',
  IdInquiriesMaxCreatedAtAsc = 'ID_INQUIRIES_MAX_CREATED_AT_ASC',
  IdInquiriesMaxCreatedAtDesc = 'ID_INQUIRIES_MAX_CREATED_AT_DESC',
  IdInquiriesMaxUpdatedAtAsc = 'ID_INQUIRIES_MAX_UPDATED_AT_ASC',
  IdInquiriesMaxUpdatedAtDesc = 'ID_INQUIRIES_MAX_UPDATED_AT_DESC',
  IdInquiriesMaxRawInquiryAsc = 'ID_INQUIRIES_MAX_RAW_INQUIRY_ASC',
  IdInquiriesMaxRawInquiryDesc = 'ID_INQUIRIES_MAX_RAW_INQUIRY_DESC',
  IdInquiriesMaxInquiryIdAsc = 'ID_INQUIRIES_MAX_INQUIRY_ID_ASC',
  IdInquiriesMaxInquiryIdDesc = 'ID_INQUIRIES_MAX_INQUIRY_ID_DESC',
  IdInquiriesAverageIdAsc = 'ID_INQUIRIES_AVERAGE_ID_ASC',
  IdInquiriesAverageIdDesc = 'ID_INQUIRIES_AVERAGE_ID_DESC',
  IdInquiriesAverageLeadIdAsc = 'ID_INQUIRIES_AVERAGE_LEAD_ID_ASC',
  IdInquiriesAverageLeadIdDesc = 'ID_INQUIRIES_AVERAGE_LEAD_ID_DESC',
  IdInquiriesAverageIntegrationIdAsc = 'ID_INQUIRIES_AVERAGE_INTEGRATION_ID_ASC',
  IdInquiriesAverageIntegrationIdDesc = 'ID_INQUIRIES_AVERAGE_INTEGRATION_ID_DESC',
  IdInquiriesAverageStatusAsc = 'ID_INQUIRIES_AVERAGE_STATUS_ASC',
  IdInquiriesAverageStatusDesc = 'ID_INQUIRIES_AVERAGE_STATUS_DESC',
  IdInquiriesAverageFirstNameAsc = 'ID_INQUIRIES_AVERAGE_FIRST_NAME_ASC',
  IdInquiriesAverageFirstNameDesc = 'ID_INQUIRIES_AVERAGE_FIRST_NAME_DESC',
  IdInquiriesAverageLastNameAsc = 'ID_INQUIRIES_AVERAGE_LAST_NAME_ASC',
  IdInquiriesAverageLastNameDesc = 'ID_INQUIRIES_AVERAGE_LAST_NAME_DESC',
  IdInquiriesAverageAddressAsc = 'ID_INQUIRIES_AVERAGE_ADDRESS_ASC',
  IdInquiriesAverageAddressDesc = 'ID_INQUIRIES_AVERAGE_ADDRESS_DESC',
  IdInquiriesAverageCityAsc = 'ID_INQUIRIES_AVERAGE_CITY_ASC',
  IdInquiriesAverageCityDesc = 'ID_INQUIRIES_AVERAGE_CITY_DESC',
  IdInquiriesAverageProvinceAsc = 'ID_INQUIRIES_AVERAGE_PROVINCE_ASC',
  IdInquiriesAverageProvinceDesc = 'ID_INQUIRIES_AVERAGE_PROVINCE_DESC',
  IdInquiriesAveragePostalCodeAsc = 'ID_INQUIRIES_AVERAGE_POSTAL_CODE_ASC',
  IdInquiriesAveragePostalCodeDesc = 'ID_INQUIRIES_AVERAGE_POSTAL_CODE_DESC',
  IdInquiriesAverageBirthDateAsc = 'ID_INQUIRIES_AVERAGE_BIRTH_DATE_ASC',
  IdInquiriesAverageBirthDateDesc = 'ID_INQUIRIES_AVERAGE_BIRTH_DATE_DESC',
  IdInquiriesAverageIdNumberAsc = 'ID_INQUIRIES_AVERAGE_ID_NUMBER_ASC',
  IdInquiriesAverageIdNumberDesc = 'ID_INQUIRIES_AVERAGE_ID_NUMBER_DESC',
  IdInquiriesAverageCreatedAtAsc = 'ID_INQUIRIES_AVERAGE_CREATED_AT_ASC',
  IdInquiriesAverageCreatedAtDesc = 'ID_INQUIRIES_AVERAGE_CREATED_AT_DESC',
  IdInquiriesAverageUpdatedAtAsc = 'ID_INQUIRIES_AVERAGE_UPDATED_AT_ASC',
  IdInquiriesAverageUpdatedAtDesc = 'ID_INQUIRIES_AVERAGE_UPDATED_AT_DESC',
  IdInquiriesAverageRawInquiryAsc = 'ID_INQUIRIES_AVERAGE_RAW_INQUIRY_ASC',
  IdInquiriesAverageRawInquiryDesc = 'ID_INQUIRIES_AVERAGE_RAW_INQUIRY_DESC',
  IdInquiriesAverageInquiryIdAsc = 'ID_INQUIRIES_AVERAGE_INQUIRY_ID_ASC',
  IdInquiriesAverageInquiryIdDesc = 'ID_INQUIRIES_AVERAGE_INQUIRY_ID_DESC',
  IdInquiriesStddevSampleIdAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_ID_ASC',
  IdInquiriesStddevSampleIdDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_ID_DESC',
  IdInquiriesStddevSampleLeadIdAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_LEAD_ID_ASC',
  IdInquiriesStddevSampleLeadIdDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_LEAD_ID_DESC',
  IdInquiriesStddevSampleIntegrationIdAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  IdInquiriesStddevSampleIntegrationIdDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  IdInquiriesStddevSampleStatusAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_STATUS_ASC',
  IdInquiriesStddevSampleStatusDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_STATUS_DESC',
  IdInquiriesStddevSampleFirstNameAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_FIRST_NAME_ASC',
  IdInquiriesStddevSampleFirstNameDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_FIRST_NAME_DESC',
  IdInquiriesStddevSampleLastNameAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_LAST_NAME_ASC',
  IdInquiriesStddevSampleLastNameDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_LAST_NAME_DESC',
  IdInquiriesStddevSampleAddressAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_ADDRESS_ASC',
  IdInquiriesStddevSampleAddressDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_ADDRESS_DESC',
  IdInquiriesStddevSampleCityAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_CITY_ASC',
  IdInquiriesStddevSampleCityDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_CITY_DESC',
  IdInquiriesStddevSampleProvinceAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_PROVINCE_ASC',
  IdInquiriesStddevSampleProvinceDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_PROVINCE_DESC',
  IdInquiriesStddevSamplePostalCodeAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_POSTAL_CODE_ASC',
  IdInquiriesStddevSamplePostalCodeDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_POSTAL_CODE_DESC',
  IdInquiriesStddevSampleBirthDateAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_BIRTH_DATE_ASC',
  IdInquiriesStddevSampleBirthDateDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_BIRTH_DATE_DESC',
  IdInquiriesStddevSampleIdNumberAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_ID_NUMBER_ASC',
  IdInquiriesStddevSampleIdNumberDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_ID_NUMBER_DESC',
  IdInquiriesStddevSampleCreatedAtAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_CREATED_AT_ASC',
  IdInquiriesStddevSampleCreatedAtDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_CREATED_AT_DESC',
  IdInquiriesStddevSampleUpdatedAtAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  IdInquiriesStddevSampleUpdatedAtDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  IdInquiriesStddevSampleRawInquiryAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_RAW_INQUIRY_ASC',
  IdInquiriesStddevSampleRawInquiryDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_RAW_INQUIRY_DESC',
  IdInquiriesStddevSampleInquiryIdAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_INQUIRY_ID_ASC',
  IdInquiriesStddevSampleInquiryIdDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_INQUIRY_ID_DESC',
  IdInquiriesStddevPopulationIdAsc = 'ID_INQUIRIES_STDDEV_POPULATION_ID_ASC',
  IdInquiriesStddevPopulationIdDesc = 'ID_INQUIRIES_STDDEV_POPULATION_ID_DESC',
  IdInquiriesStddevPopulationLeadIdAsc = 'ID_INQUIRIES_STDDEV_POPULATION_LEAD_ID_ASC',
  IdInquiriesStddevPopulationLeadIdDesc = 'ID_INQUIRIES_STDDEV_POPULATION_LEAD_ID_DESC',
  IdInquiriesStddevPopulationIntegrationIdAsc = 'ID_INQUIRIES_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  IdInquiriesStddevPopulationIntegrationIdDesc = 'ID_INQUIRIES_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  IdInquiriesStddevPopulationStatusAsc = 'ID_INQUIRIES_STDDEV_POPULATION_STATUS_ASC',
  IdInquiriesStddevPopulationStatusDesc = 'ID_INQUIRIES_STDDEV_POPULATION_STATUS_DESC',
  IdInquiriesStddevPopulationFirstNameAsc = 'ID_INQUIRIES_STDDEV_POPULATION_FIRST_NAME_ASC',
  IdInquiriesStddevPopulationFirstNameDesc = 'ID_INQUIRIES_STDDEV_POPULATION_FIRST_NAME_DESC',
  IdInquiriesStddevPopulationLastNameAsc = 'ID_INQUIRIES_STDDEV_POPULATION_LAST_NAME_ASC',
  IdInquiriesStddevPopulationLastNameDesc = 'ID_INQUIRIES_STDDEV_POPULATION_LAST_NAME_DESC',
  IdInquiriesStddevPopulationAddressAsc = 'ID_INQUIRIES_STDDEV_POPULATION_ADDRESS_ASC',
  IdInquiriesStddevPopulationAddressDesc = 'ID_INQUIRIES_STDDEV_POPULATION_ADDRESS_DESC',
  IdInquiriesStddevPopulationCityAsc = 'ID_INQUIRIES_STDDEV_POPULATION_CITY_ASC',
  IdInquiriesStddevPopulationCityDesc = 'ID_INQUIRIES_STDDEV_POPULATION_CITY_DESC',
  IdInquiriesStddevPopulationProvinceAsc = 'ID_INQUIRIES_STDDEV_POPULATION_PROVINCE_ASC',
  IdInquiriesStddevPopulationProvinceDesc = 'ID_INQUIRIES_STDDEV_POPULATION_PROVINCE_DESC',
  IdInquiriesStddevPopulationPostalCodeAsc = 'ID_INQUIRIES_STDDEV_POPULATION_POSTAL_CODE_ASC',
  IdInquiriesStddevPopulationPostalCodeDesc = 'ID_INQUIRIES_STDDEV_POPULATION_POSTAL_CODE_DESC',
  IdInquiriesStddevPopulationBirthDateAsc = 'ID_INQUIRIES_STDDEV_POPULATION_BIRTH_DATE_ASC',
  IdInquiriesStddevPopulationBirthDateDesc = 'ID_INQUIRIES_STDDEV_POPULATION_BIRTH_DATE_DESC',
  IdInquiriesStddevPopulationIdNumberAsc = 'ID_INQUIRIES_STDDEV_POPULATION_ID_NUMBER_ASC',
  IdInquiriesStddevPopulationIdNumberDesc = 'ID_INQUIRIES_STDDEV_POPULATION_ID_NUMBER_DESC',
  IdInquiriesStddevPopulationCreatedAtAsc = 'ID_INQUIRIES_STDDEV_POPULATION_CREATED_AT_ASC',
  IdInquiriesStddevPopulationCreatedAtDesc = 'ID_INQUIRIES_STDDEV_POPULATION_CREATED_AT_DESC',
  IdInquiriesStddevPopulationUpdatedAtAsc = 'ID_INQUIRIES_STDDEV_POPULATION_UPDATED_AT_ASC',
  IdInquiriesStddevPopulationUpdatedAtDesc = 'ID_INQUIRIES_STDDEV_POPULATION_UPDATED_AT_DESC',
  IdInquiriesStddevPopulationRawInquiryAsc = 'ID_INQUIRIES_STDDEV_POPULATION_RAW_INQUIRY_ASC',
  IdInquiriesStddevPopulationRawInquiryDesc = 'ID_INQUIRIES_STDDEV_POPULATION_RAW_INQUIRY_DESC',
  IdInquiriesStddevPopulationInquiryIdAsc = 'ID_INQUIRIES_STDDEV_POPULATION_INQUIRY_ID_ASC',
  IdInquiriesStddevPopulationInquiryIdDesc = 'ID_INQUIRIES_STDDEV_POPULATION_INQUIRY_ID_DESC',
  IdInquiriesVarianceSampleIdAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ID_ASC',
  IdInquiriesVarianceSampleIdDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ID_DESC',
  IdInquiriesVarianceSampleLeadIdAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_LEAD_ID_ASC',
  IdInquiriesVarianceSampleLeadIdDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_LEAD_ID_DESC',
  IdInquiriesVarianceSampleIntegrationIdAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  IdInquiriesVarianceSampleIntegrationIdDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  IdInquiriesVarianceSampleStatusAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_STATUS_ASC',
  IdInquiriesVarianceSampleStatusDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_STATUS_DESC',
  IdInquiriesVarianceSampleFirstNameAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  IdInquiriesVarianceSampleFirstNameDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  IdInquiriesVarianceSampleLastNameAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_LAST_NAME_ASC',
  IdInquiriesVarianceSampleLastNameDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_LAST_NAME_DESC',
  IdInquiriesVarianceSampleAddressAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ADDRESS_ASC',
  IdInquiriesVarianceSampleAddressDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ADDRESS_DESC',
  IdInquiriesVarianceSampleCityAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_CITY_ASC',
  IdInquiriesVarianceSampleCityDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_CITY_DESC',
  IdInquiriesVarianceSampleProvinceAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_PROVINCE_ASC',
  IdInquiriesVarianceSampleProvinceDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_PROVINCE_DESC',
  IdInquiriesVarianceSamplePostalCodeAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_POSTAL_CODE_ASC',
  IdInquiriesVarianceSamplePostalCodeDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_POSTAL_CODE_DESC',
  IdInquiriesVarianceSampleBirthDateAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_BIRTH_DATE_ASC',
  IdInquiriesVarianceSampleBirthDateDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_BIRTH_DATE_DESC',
  IdInquiriesVarianceSampleIdNumberAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ID_NUMBER_ASC',
  IdInquiriesVarianceSampleIdNumberDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ID_NUMBER_DESC',
  IdInquiriesVarianceSampleCreatedAtAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  IdInquiriesVarianceSampleCreatedAtDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  IdInquiriesVarianceSampleUpdatedAtAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  IdInquiriesVarianceSampleUpdatedAtDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdInquiriesVarianceSampleRawInquiryAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_RAW_INQUIRY_ASC',
  IdInquiriesVarianceSampleRawInquiryDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_RAW_INQUIRY_DESC',
  IdInquiriesVarianceSampleInquiryIdAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_INQUIRY_ID_ASC',
  IdInquiriesVarianceSampleInquiryIdDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_INQUIRY_ID_DESC',
  IdInquiriesVariancePopulationIdAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_ID_ASC',
  IdInquiriesVariancePopulationIdDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_ID_DESC',
  IdInquiriesVariancePopulationLeadIdAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_LEAD_ID_ASC',
  IdInquiriesVariancePopulationLeadIdDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_LEAD_ID_DESC',
  IdInquiriesVariancePopulationIntegrationIdAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  IdInquiriesVariancePopulationIntegrationIdDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  IdInquiriesVariancePopulationStatusAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_STATUS_ASC',
  IdInquiriesVariancePopulationStatusDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_STATUS_DESC',
  IdInquiriesVariancePopulationFirstNameAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_FIRST_NAME_ASC',
  IdInquiriesVariancePopulationFirstNameDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_FIRST_NAME_DESC',
  IdInquiriesVariancePopulationLastNameAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_LAST_NAME_ASC',
  IdInquiriesVariancePopulationLastNameDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_LAST_NAME_DESC',
  IdInquiriesVariancePopulationAddressAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_ADDRESS_ASC',
  IdInquiriesVariancePopulationAddressDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_ADDRESS_DESC',
  IdInquiriesVariancePopulationCityAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_CITY_ASC',
  IdInquiriesVariancePopulationCityDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_CITY_DESC',
  IdInquiriesVariancePopulationProvinceAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_PROVINCE_ASC',
  IdInquiriesVariancePopulationProvinceDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_PROVINCE_DESC',
  IdInquiriesVariancePopulationPostalCodeAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_POSTAL_CODE_ASC',
  IdInquiriesVariancePopulationPostalCodeDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_POSTAL_CODE_DESC',
  IdInquiriesVariancePopulationBirthDateAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_BIRTH_DATE_ASC',
  IdInquiriesVariancePopulationBirthDateDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_BIRTH_DATE_DESC',
  IdInquiriesVariancePopulationIdNumberAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_ID_NUMBER_ASC',
  IdInquiriesVariancePopulationIdNumberDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_ID_NUMBER_DESC',
  IdInquiriesVariancePopulationCreatedAtAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_CREATED_AT_ASC',
  IdInquiriesVariancePopulationCreatedAtDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_CREATED_AT_DESC',
  IdInquiriesVariancePopulationUpdatedAtAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  IdInquiriesVariancePopulationUpdatedAtDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  IdInquiriesVariancePopulationRawInquiryAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_RAW_INQUIRY_ASC',
  IdInquiriesVariancePopulationRawInquiryDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_RAW_INQUIRY_DESC',
  IdInquiriesVariancePopulationInquiryIdAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_INQUIRY_ID_ASC',
  IdInquiriesVariancePopulationInquiryIdDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_INQUIRY_ID_DESC'
}

export type InteractiveQuery = {
  queryId?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<CreditQuestion>>;
};


/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['JSON']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['JSON']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: Maybe<Scalars['JSON']>;
  /** Contains the specified key. */
  containsKey?: Maybe<Scalars['String']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: Maybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: Maybe<Array<Scalars['String']>>;
  /** Contained by the specified JSON. */
  containedBy?: Maybe<Scalars['JSON']>;
};

export enum JourneyStage {
  Partial = 'PARTIAL',
  Credit = 'CREDIT',
  CreditScore = 'CREDIT_SCORE',
  CreditFull = 'CREDIT_FULL',
  Trade = 'TRADE',
  LenderApp = 'LENDER_APP',
  BuyPartial = 'BUY_PARTIAL',
  IdPartial = 'ID_PARTIAL',
  IdComplete = 'ID_COMPLETE',
  IdPartialAdmin = 'ID_PARTIAL_ADMIN',
  ReviewPartial = 'REVIEW_PARTIAL',
  ReviewComplete = 'REVIEW_COMPLETE',
  DrivePartial = 'DRIVE_PARTIAL',
  DriveComplete = 'DRIVE_COMPLETE',
  IncomeSmsSent = 'INCOME_SMS_SENT',
  IncomeLinkOpened = 'INCOME_LINK_OPENED',
  IncomeVoidCheckSelected = 'INCOME_VOID_CHECK_SELECTED',
  IncomeComplete = 'INCOME_COMPLETE',
  IdSmsSent = 'ID_SMS_SENT',
  IdLinkOpened = 'ID_LINK_OPENED',
  IdInitiated = 'ID_INITIATED',
  CreditConsentRequested = 'CREDIT_CONSENT_REQUESTED',
  CreditConsentSmsSent = 'CREDIT_CONSENT_SMS_SENT',
  CreditConsentLinkOpened = 'CREDIT_CONSENT_LINK_OPENED',
  CreditConsentAccepted = 'CREDIT_CONSENT_ACCEPTED',
  CreditConsentDeclined = 'CREDIT_CONSENT_DECLINED',
  NeighborhoodScoreSuccess = 'NEIGHBORHOOD_SCORE_SUCCESS',
  NeighborhoodScoreFailure = 'NEIGHBORHOOD_SCORE_FAILURE',
  InboundAdf = 'INBOUND_ADF'
}

/** A filter to be used against JourneyStage fields. All fields are combined with a logical ‘and.’ */
export type JourneyStageFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<JourneyStage>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<JourneyStage>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<JourneyStage>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<JourneyStage>;
  /** Included in the specified list. */
  in?: Maybe<Array<JourneyStage>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<JourneyStage>>;
  /** Less than the specified value. */
  lessThan?: Maybe<JourneyStage>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<JourneyStage>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<JourneyStage>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<JourneyStage>;
};

export type Lead = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  companyId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId: Scalars['Int'];
  companyChannelId: Scalars['Int'];
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus: Scalars['String'];
  desiredVehicle: Scalars['String'];
  jobTitle: Scalars['String'];
  incomePeriod: Scalars['String'];
  salaryHourly: Scalars['String'];
  incomeSource: Scalars['String'];
  incomeTime: Scalars['String'];
  preferredContactMethod: Scalars['String'];
  companyName: Scalars['String'];
  creditConcern: Scalars['String'];
  residenceTime: Scalars['String'];
  rentOrOwn: Scalars['String'];
  employmentStatus: Scalars['String'];
  incomeAmount: Scalars['BigFloat'];
  monthlyHomePayment: Scalars['BigFloat'];
  smsConsent: Scalars['Boolean'];
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  /** Reads a single `Company` that is related to this `Lead`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyChannel` that is related to this `Lead`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `NeighborhoodView` that is related to this `Lead`. */
  neighborhoodView?: Maybe<NeighborhoodView>;
  /** Reads a single `AvaApp` that is related to this `Lead`. */
  avaApp?: Maybe<AvaApp>;
  /** Reads a single `LeadCampaign` that is related to this `Lead`. */
  leadCampaign?: Maybe<LeadCampaign>;
  /** Reads and enables pagination through a set of `TradeApp`. */
  tradeApps: TradeAppsConnection;
  /** Reads and enables pagination through a set of `LeadJourney`. */
  leadJourneys: LeadJourneysConnection;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderApps: LenderAppsConnection;
  /** Reads a single `IdVerification` that is related to this `Lead`. */
  idVerification?: Maybe<IdVerification>;
  /** Reads and enables pagination through a set of `Appointment`. */
  appointments: AppointmentsConnection;
  /** Reads a single `IncomeVerification` that is related to this `Lead`. */
  incomeVerification?: Maybe<IncomeVerification>;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
  /** Reads and enables pagination through a set of `LeadRole`. */
  leadRoles: LeadRolesConnection;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeads: DistributedLeadsConnection;
  /** Reads and enables pagination through a set of `AuditLog`. */
  auditLogs: AuditLogsConnection;
  /** Reads and enables pagination through a set of `IdInquiry`. */
  idInquiries: IdInquiriesConnection;
  /** Reads and enables pagination through a set of `Deal`. */
  deals: DealsConnection;
  /** Reads and enables pagination through a set of `Event`. */
  events: EventsConnection;
  journeyStages?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Product`. */
  productsByLeadJourneyLeadIdAndProductId: LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `VehicleListing`. */
  vehicleListingsByLenderAppLeadIdAndVehicleListingId: LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyConnection;
  /** Reads and enables pagination through a set of `VehicleListing`. */
  vehicleListingsByAppointmentLeadIdAndVehicleListingId: LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderAppsByLenderQuoteLeadIdAndLenderAppId: LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lender`. */
  lendersByLenderQuoteLeadIdAndLenderId: LeadLendersByLenderQuoteLeadIdAndLenderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByLenderQuoteLeadIdAndCompanyIntegrationId: LeadCompanyIntegrationsByLenderQuoteLeadIdAndCompanyIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLeadRoleLeadIdAndUserId: LeadUsersByLeadRoleLeadIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDistributedLeadLeadIdAndFromCompanyId: LeadCompaniesByDistributedLeadLeadIdAndFromCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDistributedLeadLeadIdAndToCompanyId: LeadCompaniesByDistributedLeadLeadIdAndToCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByDistributedLeadLeadIdAndOriginalCompanyChannelId: LeadCompanyChannelsByDistributedLeadLeadIdAndOriginalCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByAuditLogLeadIdAndUserId: LeadUsersByAuditLogLeadIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Integration`. */
  integrationsByIdInquiryLeadIdAndIntegrationId: LeadIntegrationsByIdInquiryLeadIdAndIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByDealLeadIdAndCompanyIntegrationId: LeadCompanyIntegrationsByDealLeadIdAndCompanyIntegrationIdManyToManyConnection;
  /** Full-text search ranking when filtered by `searchText`. */
  searchTextRank?: Maybe<Scalars['Float']>;
  retrieveIdVerification?: Maybe<TransactionResponse>;
  idVerificationTemplateType?: Maybe<IdToolTemplateType>;
};


export type LeadTradeAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TradeAppsOrderBy>>;
  condition?: Maybe<TradeAppCondition>;
  filter?: Maybe<TradeAppFilter>;
};


export type LeadLeadJourneysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
  condition?: Maybe<LeadJourneyCondition>;
  filter?: Maybe<LeadJourneyFilter>;
};


export type LeadLenderAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};


export type LeadAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
  condition?: Maybe<AppointmentCondition>;
  filter?: Maybe<AppointmentFilter>;
};


export type LeadLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};


export type LeadLeadRolesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadRolesOrderBy>>;
  condition?: Maybe<LeadRoleCondition>;
  filter?: Maybe<LeadRoleFilter>;
};


export type LeadDistributedLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};


export type LeadAuditLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuditLogsOrderBy>>;
  condition?: Maybe<AuditLogCondition>;
  filter?: Maybe<AuditLogFilter>;
};


export type LeadIdInquiriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiriesOrderBy>>;
  condition?: Maybe<IdInquiryCondition>;
  filter?: Maybe<IdInquiryFilter>;
};


export type LeadDealsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DealsOrderBy>>;
  condition?: Maybe<DealCondition>;
  filter?: Maybe<DealFilter>;
};


export type LeadEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


export type LeadProductsByLeadJourneyLeadIdAndProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


export type LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
  condition?: Maybe<VehicleListingCondition>;
  filter?: Maybe<VehicleListingFilter>;
};


export type LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
  condition?: Maybe<VehicleListingCondition>;
  filter?: Maybe<VehicleListingFilter>;
};


export type LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};


export type LeadLendersByLenderQuoteLeadIdAndLenderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LendersOrderBy>>;
  condition?: Maybe<LenderCondition>;
  filter?: Maybe<LenderFilter>;
};


export type LeadCompanyIntegrationsByLenderQuoteLeadIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type LeadUsersByLeadRoleLeadIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LeadCompaniesByDistributedLeadLeadIdAndFromCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type LeadCompaniesByDistributedLeadLeadIdAndToCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type LeadCompanyChannelsByDistributedLeadLeadIdAndOriginalCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type LeadUsersByAuditLogLeadIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LeadIntegrationsByIdInquiryLeadIdAndIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
  condition?: Maybe<IntegrationCondition>;
  filter?: Maybe<IntegrationFilter>;
};


export type LeadCompanyIntegrationsByDealLeadIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};

export type LeadAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LeadSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LeadDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LeadMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LeadMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LeadAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LeadStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LeadStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LeadVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LeadVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Lead` object types. */
export type LeadAggregatesFilter = {
  /** A filter that must pass for the relevant `Lead` object to be included within the aggregate. */
  filter?: Maybe<LeadFilter>;
  /** Sum aggregate over matching `Lead` objects. */
  sum?: Maybe<LeadSumAggregateFilter>;
  /** Distinct count aggregate over matching `Lead` objects. */
  distinctCount?: Maybe<LeadDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Lead` objects. */
  min?: Maybe<LeadMinAggregateFilter>;
  /** Maximum aggregate over matching `Lead` objects. */
  max?: Maybe<LeadMaxAggregateFilter>;
  /** Mean average aggregate over matching `Lead` objects. */
  average?: Maybe<LeadAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Lead` objects. */
  stddevSample?: Maybe<LeadStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Lead` objects. */
  stddevPopulation?: Maybe<LeadStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Lead` objects. */
  varianceSample?: Maybe<LeadVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Lead` objects. */
  variancePopulation?: Maybe<LeadVariancePopulationAggregateFilter>;
};

export type LeadAverageAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  shortId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  neighborhoodViewId?: Maybe<BigFloatFilter>;
  incomeAmount?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  incomeTimeYears?: Maybe<BigFloatFilter>;
  incomeTimeMonths?: Maybe<BigFloatFilter>;
  residenceTimeYears?: Maybe<BigFloatFilter>;
  residenceTimeMonths?: Maybe<BigFloatFilter>;
};

export type LeadAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of neighborhoodViewId across the matching connection */
  neighborhoodViewId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of incomeAmount across the matching connection */
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Mean average of incomeTimeYears across the matching connection */
  incomeTimeYears?: Maybe<Scalars['BigFloat']>;
  /** Mean average of incomeTimeMonths across the matching connection */
  incomeTimeMonths?: Maybe<Scalars['BigFloat']>;
  /** Mean average of residenceTimeYears across the matching connection */
  residenceTimeYears?: Maybe<Scalars['BigFloat']>;
  /** Mean average of residenceTimeMonths across the matching connection */
  residenceTimeMonths?: Maybe<Scalars['BigFloat']>;
};

export type LeadCampaign = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId?: Maybe<Scalars['UUID']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `LeadCampaign`. */
  lead?: Maybe<Lead>;
};

export type LeadCampaignAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LeadCampaignSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LeadCampaignDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LeadCampaignMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LeadCampaignMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LeadCampaignAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LeadCampaignStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LeadCampaignStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LeadCampaignVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LeadCampaignVariancePopulationAggregates>;
};

export type LeadCampaignAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LeadCampaign` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LeadCampaignCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `utmCampaign` field. */
  utmCampaign?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `utmContent` field. */
  utmContent?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `utmMedium` field. */
  utmMedium?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `utmSource` field. */
  utmSource?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `s1` field. */
  s1?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `s2` field. */
  s2?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `s3` field. */
  s3?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `s4` field. */
  s4?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `s5` field. */
  s5?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type LeadCampaignDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utmCampaign across the matching connection */
  utmCampaign?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utmContent across the matching connection */
  utmContent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utmMedium across the matching connection */
  utmMedium?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utmSource across the matching connection */
  utmSource?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s1 across the matching connection */
  s1?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s2 across the matching connection */
  s2?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s3 across the matching connection */
  s3?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s4 across the matching connection */
  s4?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s5 across the matching connection */
  s5?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LeadCampaign` object types. All fields are combined with a logical ‘and.’ */
export type LeadCampaignFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `utmCampaign` field. */
  utmCampaign?: Maybe<StringFilter>;
  /** Filter by the object’s `utmContent` field. */
  utmContent?: Maybe<StringFilter>;
  /** Filter by the object’s `utmMedium` field. */
  utmMedium?: Maybe<StringFilter>;
  /** Filter by the object’s `utmSource` field. */
  utmSource?: Maybe<StringFilter>;
  /** Filter by the object’s `s1` field. */
  s1?: Maybe<StringFilter>;
  /** Filter by the object’s `s2` field. */
  s2?: Maybe<StringFilter>;
  /** Filter by the object’s `s3` field. */
  s3?: Maybe<StringFilter>;
  /** Filter by the object’s `s4` field. */
  s4?: Maybe<StringFilter>;
  /** Filter by the object’s `s5` field. */
  s5?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** A related `lead` exists. */
  leadExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeadCampaignFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeadCampaignFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeadCampaignFilter>;
};

/** An input for mutations affecting `LeadCampaign` */
export type LeadCampaignInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadCampaignsLeadIdFkeyInput>;
};

export type LeadCampaignInputType = {
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
};

/** The fields on `leadCampaign` to look up the row to connect. */
export type LeadCampaignLeadCampaignsLeadIdKeyConnect = {
  leadId: Scalars['UUID'];
};

/** The fields on `leadCampaign` to look up the row to delete. */
export type LeadCampaignLeadCampaignsLeadIdKeyDelete = {
  leadId: Scalars['UUID'];
};

/** The fields on `leadCampaign` to look up the row to connect. */
export type LeadCampaignLeadCampaignsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `leadCampaign` to look up the row to delete. */
export type LeadCampaignLeadCampaignsPkeyDelete = {
  id: Scalars['Int'];
};

export type LeadCampaignMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type LeadCampaignMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LeadCampaignNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `leadCampaign` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LeadCampaignNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `leadCampaign` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `leadCampaign` to look up the row to update. */
export type LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadCampaignsLeadIdKeyUpdate = {
  /** An object where the defined keys will be set on the `leadCampaign` being updated. */
  patch: UpdateLeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyPatch;
  leadId: Scalars['UUID'];
};

/** The fields on `leadCampaign` to look up the row to update. */
export type LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadCampaignsPkeyUpdate = {
  /** An object where the defined keys will be set on the `leadCampaign` being updated. */
  patch: UpdateLeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `LeadCampaign`. Fields that are set will be updated. */
export type LeadCampaignPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadCampaignsLeadIdFkeyInput>;
};

export type LeadCampaignStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type LeadCampaignStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type LeadCampaignSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type LeadCampaignVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type LeadCampaignVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LeadCampaign` values. */
export type LeadCampaignsConnection = {
  /** A list of `LeadCampaign` objects. */
  nodes: Array<LeadCampaign>;
  /** A list of edges which contains the `LeadCampaign` and cursor to aid in pagination. */
  edges: Array<LeadCampaignsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LeadCampaign` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadCampaignAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadCampaignAggregates>>;
};


/** A connection to a list of `LeadCampaign` values. */
export type LeadCampaignsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadCampaignsGroupBy>;
  having?: Maybe<LeadCampaignsHavingInput>;
};

/** A `LeadCampaign` edge in the connection. */
export type LeadCampaignsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LeadCampaign` at the end of the edge. */
  node: LeadCampaign;
};

/** Grouping methods for `LeadCampaign` for usage during aggregation. */
export enum LeadCampaignsGroupBy {
  UtmCampaign = 'UTM_CAMPAIGN',
  UtmContent = 'UTM_CONTENT',
  UtmMedium = 'UTM_MEDIUM',
  UtmSource = 'UTM_SOURCE',
  S1 = 'S1',
  S2 = 'S2',
  S3 = 'S3',
  S4 = 'S4',
  S5 = 'S5',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type LeadCampaignsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `LeadCampaign` aggregates. */
export type LeadCampaignsHavingInput = {
  AND?: Maybe<Array<LeadCampaignsHavingInput>>;
  OR?: Maybe<Array<LeadCampaignsHavingInput>>;
  sum?: Maybe<LeadCampaignsHavingSumInput>;
  distinctCount?: Maybe<LeadCampaignsHavingDistinctCountInput>;
  min?: Maybe<LeadCampaignsHavingMinInput>;
  max?: Maybe<LeadCampaignsHavingMaxInput>;
  average?: Maybe<LeadCampaignsHavingAverageInput>;
  stddevSample?: Maybe<LeadCampaignsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LeadCampaignsHavingStddevPopulationInput>;
  varianceSample?: Maybe<LeadCampaignsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LeadCampaignsHavingVariancePopulationInput>;
};

export type LeadCampaignsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Input for the nested mutation of `lead` in the `LeadCampaignInput` mutation. */
export type LeadCampaignsLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<LeadCampaignsLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `leadCampaign` in the `LeadInput` mutation. */
export type LeadCampaignsLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `leadCampaign` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  connectById?: Maybe<LeadCampaignLeadCampaignsPkeyConnect>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  connectByLeadId?: Maybe<LeadCampaignLeadCampaignsLeadIdKeyConnect>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadCampaignNodeIdConnect>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  deleteById?: Maybe<LeadCampaignLeadCampaignsPkeyDelete>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  deleteByLeadId?: Maybe<LeadCampaignLeadCampaignsLeadIdKeyDelete>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadCampaignNodeIdDelete>;
  /** The primary key(s) and patch data for `leadCampaign` for the far side of the relationship. */
  updateById?: Maybe<LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadCampaignsPkeyUpdate>;
  /** The primary key(s) and patch data for `leadCampaign` for the far side of the relationship. */
  updateByLeadId?: Maybe<LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadCampaignsLeadIdKeyUpdate>;
  /** The primary key(s) and patch data for `leadCampaign` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnLeadCampaignForLeadCampaignsLeadIdFkeyNodeIdUpdate>;
  /** A `LeadCampaignInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadCampaignsLeadIdFkeyLeadCampaignsCreateInput>>;
};

/** The `leadCampaign` to be created by this mutation. */
export type LeadCampaignsLeadIdFkeyLeadCampaignsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadCampaignsLeadIdFkeyInput>;
};

/** The `lead` to be created by this mutation. */
export type LeadCampaignsLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** Methods to use when ordering `LeadCampaign`. */
export enum LeadCampaignsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  UtmCampaignAsc = 'UTM_CAMPAIGN_ASC',
  UtmCampaignDesc = 'UTM_CAMPAIGN_DESC',
  UtmContentAsc = 'UTM_CONTENT_ASC',
  UtmContentDesc = 'UTM_CONTENT_DESC',
  UtmMediumAsc = 'UTM_MEDIUM_ASC',
  UtmMediumDesc = 'UTM_MEDIUM_DESC',
  UtmSourceAsc = 'UTM_SOURCE_ASC',
  UtmSourceDesc = 'UTM_SOURCE_DESC',
  S1Asc = 'S1_ASC',
  S1Desc = 'S1_DESC',
  S2Asc = 'S2_ASC',
  S2Desc = 'S2_DESC',
  S3Asc = 'S3_ASC',
  S3Desc = 'S3_DESC',
  S4Asc = 'S4_ASC',
  S4Desc = 'S4_DESC',
  S5Asc = 'S5_ASC',
  S5Desc = 'S5_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type LeadCompaniesByDistributedLeadLeadIdAndFromCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<LeadCompaniesByDistributedLeadLeadIdAndFromCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type LeadCompaniesByDistributedLeadLeadIdAndFromCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type LeadCompaniesByDistributedLeadLeadIdAndFromCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByFromCompanyId: DistributedLeadsConnection;
};


/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type LeadCompaniesByDistributedLeadLeadIdAndFromCompanyIdManyToManyEdgeDistributedLeadsByFromCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type LeadCompaniesByDistributedLeadLeadIdAndToCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<LeadCompaniesByDistributedLeadLeadIdAndToCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `DistributedLead`. */
export type LeadCompaniesByDistributedLeadLeadIdAndToCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type LeadCompaniesByDistributedLeadLeadIdAndToCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByToCompanyId: DistributedLeadsConnection;
};


/** A `Company` edge in the connection, with data from `DistributedLead`. */
export type LeadCompaniesByDistributedLeadLeadIdAndToCompanyIdManyToManyEdgeDistributedLeadsByToCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

/** The `companyChannel` to be created by this mutation. */
export type LeadCompanyChannelIdFkeyCompanyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** Input for the nested mutation of `companyChannel` in the `LeadInput` mutation. */
export type LeadCompanyChannelIdFkeyInput = {
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectById?: Maybe<CompanyChannelCompanyChannelPkeyConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByCompanyIdAndNameAndChannelId?: Maybe<CompanyChannelUnqCompanyChannelConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyChannelNodeIdConnect>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateById?: Maybe<CompanyChannelOnLeadForLeadCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByCompanyIdAndNameAndChannelId?: Maybe<CompanyChannelOnLeadForLeadCompanyChannelIdFkeyUsingUnqCompanyChannelUpdate>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnLeadForLeadCompanyChannelIdFkeyNodeIdUpdate>;
  /** A `CompanyChannelInput` object that will be created and connected to this object. */
  create?: Maybe<LeadCompanyChannelIdFkeyCompanyChannelCreateInput>;
};

/** Input for the nested mutation of `lead` in the `CompanyChannelInput` mutation. */
export type LeadCompanyChannelIdFkeyInverseInput = {
  /** Flag indicating whether all other `lead` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<Array<LeadLeadPkeyConnect>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LeadNodeIdConnect>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<Array<LeadLeadPkeyDelete>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LeadNodeIdDelete>>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<Array<LeadOnLeadForLeadCompanyChannelIdFkeyUsingLeadPkeyUpdate>>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyChannelOnLeadForLeadCompanyChannelIdFkeyNodeIdUpdate>>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadCompanyChannelIdFkeyLeadCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type LeadCompanyChannelIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** A connection to a list of `CompanyChannel` values, with data from `DistributedLead`. */
export type LeadCompanyChannelsByDistributedLeadLeadIdAndOriginalCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `DistributedLead`, and the cursor to aid in pagination. */
  edges: Array<LeadCompanyChannelsByDistributedLeadLeadIdAndOriginalCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `DistributedLead`. */
export type LeadCompanyChannelsByDistributedLeadLeadIdAndOriginalCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `DistributedLead`. */
export type LeadCompanyChannelsByDistributedLeadLeadIdAndOriginalCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeadsByOriginalCompanyChannelId: DistributedLeadsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `DistributedLead`. */
export type LeadCompanyChannelsByDistributedLeadLeadIdAndOriginalCompanyChannelIdManyToManyEdgeDistributedLeadsByOriginalCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};

/** The `company` to be created by this mutation. */
export type LeadCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `LeadInput` mutation. */
export type LeadCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnLeadForLeadCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<LeadCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `lead` in the `CompanyInput` mutation. */
export type LeadCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `lead` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<Array<LeadLeadPkeyConnect>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LeadNodeIdConnect>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<Array<LeadLeadPkeyDelete>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LeadNodeIdDelete>>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<Array<LeadOnLeadForLeadCompanyIdFkeyUsingLeadPkeyUpdate>>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnLeadForLeadCompanyIdFkeyNodeIdUpdate>>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadCompanyIdFkeyLeadCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type LeadCompanyIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** A connection to a list of `CompanyIntegration` values, with data from `Deal`. */
export type LeadCompanyIntegrationsByDealLeadIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `Deal`, and the cursor to aid in pagination. */
  edges: Array<LeadCompanyIntegrationsByDealLeadIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `Deal`. */
export type LeadCompanyIntegrationsByDealLeadIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `Deal`. */
export type LeadCompanyIntegrationsByDealLeadIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `Deal`. */
  deals: DealsConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `Deal`. */
export type LeadCompanyIntegrationsByDealLeadIdAndCompanyIntegrationIdManyToManyEdgeDealsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DealsOrderBy>>;
  condition?: Maybe<DealCondition>;
  filter?: Maybe<DealFilter>;
};

/** A connection to a list of `CompanyIntegration` values, with data from `LenderQuote`. */
export type LeadCompanyIntegrationsByLenderQuoteLeadIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<LeadCompanyIntegrationsByLenderQuoteLeadIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `LenderQuote`. */
export type LeadCompanyIntegrationsByLenderQuoteLeadIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `LenderQuote`. */
export type LeadCompanyIntegrationsByLenderQuoteLeadIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `LenderQuote`. */
export type LeadCompanyIntegrationsByLenderQuoteLeadIdAndCompanyIntegrationIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

/** A condition to be used against `Lead` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LeadCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dateOfBirth` field. */
  dateOfBirth?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `street` field. */
  street?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `streetNumber` field. */
  streetNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `unit` field. */
  unit?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `country` field. */
  country?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `postalCode` field. */
  postalCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `provinceCode` field. */
  provinceCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `emailAddress` field. */
  emailAddress?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sourceUrl` field. */
  sourceUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionKey` field. */
  transactionKey?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `leadspediaId` field. */
  leadspediaId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `searchText` field. */
  searchText?: Maybe<Scalars['FullText']>;
  /** Checks for equality with the object’s `comment` field. */
  comment?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: Maybe<LeadState>;
  /** Checks for equality with the object’s `read` field. */
  read?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `externalIds` field. */
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `additionalInformation` field. */
  additionalInformation?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `phoneVerifiedAt` field. */
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `ipAddress` field. */
  ipAddress?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `clientId` field. */
  clientId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: Maybe<LeadPriority>;
  /** Checks for equality with the object’s `assignee` field. */
  assignee?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `shortId` field. */
  shortId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyChannelId` field. */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `neighborhoodViewId` field. */
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maritalStatus` field. */
  maritalStatus?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `desiredVehicle` field. */
  desiredVehicle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `jobTitle` field. */
  jobTitle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `incomePeriod` field. */
  incomePeriod?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salaryHourly` field. */
  salaryHourly?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `incomeSource` field. */
  incomeSource?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `incomeTime` field. */
  incomeTime?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `preferredContactMethod` field. */
  preferredContactMethod?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `creditConcern` field. */
  creditConcern?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `residenceTime` field. */
  residenceTime?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rentOrOwn` field. */
  rentOrOwn?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `employmentStatus` field. */
  employmentStatus?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `incomeAmount` field. */
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `monthlyHomePayment` field. */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `smsConsent` field. */
  smsConsent?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `incomeTimeYears` field. */
  incomeTimeYears?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `incomeTimeMonths` field. */
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `residenceTimeYears` field. */
  residenceTimeYears?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `residenceTimeMonths` field. */
  residenceTimeMonths?: Maybe<Scalars['Int']>;
};

export type LeadDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  firstName?: Maybe<BigIntFilter>;
  lastName?: Maybe<BigIntFilter>;
  dateOfBirth?: Maybe<BigIntFilter>;
  street?: Maybe<BigIntFilter>;
  streetNumber?: Maybe<BigIntFilter>;
  unit?: Maybe<BigIntFilter>;
  city?: Maybe<BigIntFilter>;
  country?: Maybe<BigIntFilter>;
  postalCode?: Maybe<BigIntFilter>;
  provinceCode?: Maybe<BigIntFilter>;
  emailAddress?: Maybe<BigIntFilter>;
  phoneNumber?: Maybe<BigIntFilter>;
  sourceUrl?: Maybe<BigIntFilter>;
  transactionKey?: Maybe<BigIntFilter>;
  leadspediaId?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  searchText?: Maybe<BigIntFilter>;
  comment?: Maybe<BigIntFilter>;
  state?: Maybe<BigIntFilter>;
  read?: Maybe<BigIntFilter>;
  externalIds?: Maybe<BigIntFilter>;
  meta?: Maybe<BigIntFilter>;
  additionalInformation?: Maybe<BigIntFilter>;
  phoneVerifiedAt?: Maybe<BigIntFilter>;
  ipAddress?: Maybe<BigIntFilter>;
  clientId?: Maybe<BigIntFilter>;
  deleted?: Maybe<BigIntFilter>;
  priority?: Maybe<BigIntFilter>;
  assignee?: Maybe<BigIntFilter>;
  shortId?: Maybe<BigIntFilter>;
  companyChannelId?: Maybe<BigIntFilter>;
  neighborhoodViewId?: Maybe<BigIntFilter>;
  maritalStatus?: Maybe<BigIntFilter>;
  desiredVehicle?: Maybe<BigIntFilter>;
  jobTitle?: Maybe<BigIntFilter>;
  incomePeriod?: Maybe<BigIntFilter>;
  salaryHourly?: Maybe<BigIntFilter>;
  incomeSource?: Maybe<BigIntFilter>;
  incomeTime?: Maybe<BigIntFilter>;
  preferredContactMethod?: Maybe<BigIntFilter>;
  companyName?: Maybe<BigIntFilter>;
  creditConcern?: Maybe<BigIntFilter>;
  residenceTime?: Maybe<BigIntFilter>;
  rentOrOwn?: Maybe<BigIntFilter>;
  employmentStatus?: Maybe<BigIntFilter>;
  incomeAmount?: Maybe<BigIntFilter>;
  monthlyHomePayment?: Maybe<BigIntFilter>;
  smsConsent?: Maybe<BigIntFilter>;
  incomeTimeYears?: Maybe<BigIntFilter>;
  incomeTimeMonths?: Maybe<BigIntFilter>;
  residenceTimeYears?: Maybe<BigIntFilter>;
  residenceTimeMonths?: Maybe<BigIntFilter>;
  journeyStages?: Maybe<StringFilter>;
};

export type LeadDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dateOfBirth across the matching connection */
  dateOfBirth?: Maybe<Scalars['BigInt']>;
  /** Distinct count of street across the matching connection */
  street?: Maybe<Scalars['BigInt']>;
  /** Distinct count of streetNumber across the matching connection */
  streetNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of unit across the matching connection */
  unit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars['BigInt']>;
  /** Distinct count of country across the matching connection */
  country?: Maybe<Scalars['BigInt']>;
  /** Distinct count of postalCode across the matching connection */
  postalCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provinceCode across the matching connection */
  provinceCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of emailAddress across the matching connection */
  emailAddress?: Maybe<Scalars['BigInt']>;
  /** Distinct count of phoneNumber across the matching connection */
  phoneNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of sourceUrl across the matching connection */
  sourceUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of transactionKey across the matching connection */
  transactionKey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadspediaId across the matching connection */
  leadspediaId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of searchText across the matching connection */
  searchText?: Maybe<Scalars['BigInt']>;
  /** Distinct count of comment across the matching connection */
  comment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars['BigInt']>;
  /** Distinct count of read across the matching connection */
  read?: Maybe<Scalars['BigInt']>;
  /** Distinct count of externalIds across the matching connection */
  externalIds?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meta across the matching connection */
  meta?: Maybe<Scalars['BigInt']>;
  /** Distinct count of additionalInformation across the matching connection */
  additionalInformation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of phoneVerifiedAt across the matching connection */
  phoneVerifiedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ipAddress across the matching connection */
  ipAddress?: Maybe<Scalars['BigInt']>;
  /** Distinct count of clientId across the matching connection */
  clientId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars['BigInt']>;
  /** Distinct count of priority across the matching connection */
  priority?: Maybe<Scalars['BigInt']>;
  /** Distinct count of assignee across the matching connection */
  assignee?: Maybe<Scalars['BigInt']>;
  /** Distinct count of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of neighborhoodViewId across the matching connection */
  neighborhoodViewId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maritalStatus across the matching connection */
  maritalStatus?: Maybe<Scalars['BigInt']>;
  /** Distinct count of desiredVehicle across the matching connection */
  desiredVehicle?: Maybe<Scalars['BigInt']>;
  /** Distinct count of jobTitle across the matching connection */
  jobTitle?: Maybe<Scalars['BigInt']>;
  /** Distinct count of incomePeriod across the matching connection */
  incomePeriod?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salaryHourly across the matching connection */
  salaryHourly?: Maybe<Scalars['BigInt']>;
  /** Distinct count of incomeSource across the matching connection */
  incomeSource?: Maybe<Scalars['BigInt']>;
  /** Distinct count of incomeTime across the matching connection */
  incomeTime?: Maybe<Scalars['BigInt']>;
  /** Distinct count of preferredContactMethod across the matching connection */
  preferredContactMethod?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyName across the matching connection */
  companyName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditConcern across the matching connection */
  creditConcern?: Maybe<Scalars['BigInt']>;
  /** Distinct count of residenceTime across the matching connection */
  residenceTime?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rentOrOwn across the matching connection */
  rentOrOwn?: Maybe<Scalars['BigInt']>;
  /** Distinct count of employmentStatus across the matching connection */
  employmentStatus?: Maybe<Scalars['BigInt']>;
  /** Distinct count of incomeAmount across the matching connection */
  incomeAmount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of smsConsent across the matching connection */
  smsConsent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of incomeTimeYears across the matching connection */
  incomeTimeYears?: Maybe<Scalars['BigInt']>;
  /** Distinct count of incomeTimeMonths across the matching connection */
  incomeTimeMonths?: Maybe<Scalars['BigInt']>;
  /** Distinct count of residenceTimeYears across the matching connection */
  residenceTimeYears?: Maybe<Scalars['BigInt']>;
  /** Distinct count of residenceTimeMonths across the matching connection */
  residenceTimeMonths?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  journeyStages?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Lead` object types. All fields are combined with a logical ‘and.’ */
export type LeadFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `dateOfBirth` field. */
  dateOfBirth?: Maybe<DateFilter>;
  /** Filter by the object’s `street` field. */
  street?: Maybe<StringFilter>;
  /** Filter by the object’s `streetNumber` field. */
  streetNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `unit` field. */
  unit?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: Maybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  country?: Maybe<StringFilter>;
  /** Filter by the object’s `postalCode` field. */
  postalCode?: Maybe<StringFilter>;
  /** Filter by the object’s `provinceCode` field. */
  provinceCode?: Maybe<StringFilter>;
  /** Filter by the object’s `emailAddress` field. */
  emailAddress?: Maybe<StringFilter>;
  /** Filter by the object’s `phoneNumber` field. */
  phoneNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `sourceUrl` field. */
  sourceUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `transactionKey` field. */
  transactionKey?: Maybe<StringFilter>;
  /** Filter by the object’s `leadspediaId` field. */
  leadspediaId?: Maybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `searchText` field. */
  searchText?: Maybe<FullTextFilter>;
  /** Filter by the object’s `comment` field. */
  comment?: Maybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: Maybe<LeadStateFilter>;
  /** Filter by the object’s `read` field. */
  read?: Maybe<BooleanFilter>;
  /** Filter by the object’s `externalIds` field. */
  externalIds?: Maybe<StringListFilter>;
  /** Filter by the object’s `phoneVerifiedAt` field. */
  phoneVerifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `ipAddress` field. */
  ipAddress?: Maybe<StringFilter>;
  /** Filter by the object’s `clientId` field. */
  clientId?: Maybe<StringFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `priority` field. */
  priority?: Maybe<LeadPriorityFilter>;
  /** Filter by the object’s `assignee` field. */
  assignee?: Maybe<StringFilter>;
  /** Filter by the object’s `shortId` field. */
  shortId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyChannelId` field. */
  companyChannelId?: Maybe<IntFilter>;
  /** Filter by the object’s `neighborhoodViewId` field. */
  neighborhoodViewId?: Maybe<IntFilter>;
  /** Filter by the object’s `maritalStatus` field. */
  maritalStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `desiredVehicle` field. */
  desiredVehicle?: Maybe<StringFilter>;
  /** Filter by the object’s `jobTitle` field. */
  jobTitle?: Maybe<StringFilter>;
  /** Filter by the object’s `incomePeriod` field. */
  incomePeriod?: Maybe<StringFilter>;
  /** Filter by the object’s `salaryHourly` field. */
  salaryHourly?: Maybe<StringFilter>;
  /** Filter by the object’s `incomeSource` field. */
  incomeSource?: Maybe<StringFilter>;
  /** Filter by the object’s `incomeTime` field. */
  incomeTime?: Maybe<StringFilter>;
  /** Filter by the object’s `preferredContactMethod` field. */
  preferredContactMethod?: Maybe<StringFilter>;
  /** Filter by the object’s `companyName` field. */
  companyName?: Maybe<StringFilter>;
  /** Filter by the object’s `creditConcern` field. */
  creditConcern?: Maybe<StringFilter>;
  /** Filter by the object’s `residenceTime` field. */
  residenceTime?: Maybe<StringFilter>;
  /** Filter by the object’s `rentOrOwn` field. */
  rentOrOwn?: Maybe<StringFilter>;
  /** Filter by the object’s `employmentStatus` field. */
  employmentStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `incomeAmount` field. */
  incomeAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `monthlyHomePayment` field. */
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `smsConsent` field. */
  smsConsent?: Maybe<BooleanFilter>;
  /** Filter by the object’s `incomeTimeYears` field. */
  incomeTimeYears?: Maybe<IntFilter>;
  /** Filter by the object’s `incomeTimeMonths` field. */
  incomeTimeMonths?: Maybe<IntFilter>;
  /** Filter by the object’s `residenceTimeYears` field. */
  residenceTimeYears?: Maybe<IntFilter>;
  /** Filter by the object’s `residenceTimeMonths` field. */
  residenceTimeMonths?: Maybe<IntFilter>;
  /** Filter by the object’s `journeyStages` field. */
  journeyStages?: Maybe<StringFilter>;
  /** Filter by the object’s `avaApp` relation. */
  avaApp?: Maybe<AvaAppFilter>;
  /** A related `avaApp` exists. */
  avaAppExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `leadCampaign` relation. */
  leadCampaign?: Maybe<LeadCampaignFilter>;
  /** A related `leadCampaign` exists. */
  leadCampaignExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `tradeApps` relation. */
  tradeApps?: Maybe<LeadToManyTradeAppFilter>;
  /** Some related `tradeApps` exist. */
  tradeAppsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `leadJourneys` relation. */
  leadJourneys?: Maybe<LeadToManyLeadJourneyFilter>;
  /** Some related `leadJourneys` exist. */
  leadJourneysExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lenderApps` relation. */
  lenderApps?: Maybe<LeadToManyLenderAppFilter>;
  /** Some related `lenderApps` exist. */
  lenderAppsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `idVerification` relation. */
  idVerification?: Maybe<IdVerificationFilter>;
  /** A related `idVerification` exists. */
  idVerificationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `appointments` relation. */
  appointments?: Maybe<LeadToManyAppointmentFilter>;
  /** Some related `appointments` exist. */
  appointmentsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `incomeVerification` relation. */
  incomeVerification?: Maybe<IncomeVerificationFilter>;
  /** A related `incomeVerification` exists. */
  incomeVerificationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lenderQuotes` relation. */
  lenderQuotes?: Maybe<LeadToManyLenderQuoteFilter>;
  /** Some related `lenderQuotes` exist. */
  lenderQuotesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `leadRoles` relation. */
  leadRoles?: Maybe<LeadToManyLeadRoleFilter>;
  /** Some related `leadRoles` exist. */
  leadRolesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `distributedLeads` relation. */
  distributedLeads?: Maybe<LeadToManyDistributedLeadFilter>;
  /** Some related `distributedLeads` exist. */
  distributedLeadsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `auditLogs` relation. */
  auditLogs?: Maybe<LeadToManyAuditLogFilter>;
  /** Some related `auditLogs` exist. */
  auditLogsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `idInquiries` relation. */
  idInquiries?: Maybe<LeadToManyIdInquiryFilter>;
  /** Some related `idInquiries` exist. */
  idInquiriesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `deals` relation. */
  deals?: Maybe<LeadToManyDealFilter>;
  /** Some related `deals` exist. */
  dealsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `events` relation. */
  events?: Maybe<LeadToManyEventFilter>;
  /** Some related `events` exist. */
  eventsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyChannel` relation. */
  companyChannel?: Maybe<CompanyChannelFilter>;
  /** Filter by the object’s `neighborhoodView` relation. */
  neighborhoodView?: Maybe<NeighborhoodViewFilter>;
  /** A related `neighborhoodView` exists. */
  neighborhoodViewExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeadFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeadFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeadFilter>;
};

/** Grouping methods for `Lead` for usage during aggregation. */
export enum LeadGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  DateOfBirth = 'DATE_OF_BIRTH',
  Street = 'STREET',
  StreetNumber = 'STREET_NUMBER',
  Unit = 'UNIT',
  City = 'CITY',
  Country = 'COUNTRY',
  PostalCode = 'POSTAL_CODE',
  ProvinceCode = 'PROVINCE_CODE',
  EmailAddress = 'EMAIL_ADDRESS',
  PhoneNumber = 'PHONE_NUMBER',
  SourceUrl = 'SOURCE_URL',
  TransactionKey = 'TRANSACTION_KEY',
  LeadspediaId = 'LEADSPEDIA_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  SearchText = 'SEARCH_TEXT',
  Comment = 'COMMENT',
  State = 'STATE',
  Read = 'READ',
  ExternalIds = 'EXTERNAL_IDS',
  Meta = 'META',
  AdditionalInformation = 'ADDITIONAL_INFORMATION',
  PhoneVerifiedAt = 'PHONE_VERIFIED_AT',
  PhoneVerifiedAtTruncatedToHour = 'PHONE_VERIFIED_AT_TRUNCATED_TO_HOUR',
  PhoneVerifiedAtTruncatedToDay = 'PHONE_VERIFIED_AT_TRUNCATED_TO_DAY',
  IpAddress = 'IP_ADDRESS',
  ClientId = 'CLIENT_ID',
  Deleted = 'DELETED',
  Priority = 'PRIORITY',
  Assignee = 'ASSIGNEE',
  ShortId = 'SHORT_ID',
  CompanyChannelId = 'COMPANY_CHANNEL_ID',
  NeighborhoodViewId = 'NEIGHBORHOOD_VIEW_ID',
  MaritalStatus = 'MARITAL_STATUS',
  DesiredVehicle = 'DESIRED_VEHICLE',
  JobTitle = 'JOB_TITLE',
  IncomePeriod = 'INCOME_PERIOD',
  SalaryHourly = 'SALARY_HOURLY',
  IncomeSource = 'INCOME_SOURCE',
  IncomeTime = 'INCOME_TIME',
  PreferredContactMethod = 'PREFERRED_CONTACT_METHOD',
  CompanyName = 'COMPANY_NAME',
  CreditConcern = 'CREDIT_CONCERN',
  ResidenceTime = 'RESIDENCE_TIME',
  RentOrOwn = 'RENT_OR_OWN',
  EmploymentStatus = 'EMPLOYMENT_STATUS',
  IncomeAmount = 'INCOME_AMOUNT',
  MonthlyHomePayment = 'MONTHLY_HOME_PAYMENT',
  SmsConsent = 'SMS_CONSENT',
  IncomeTimeYears = 'INCOME_TIME_YEARS',
  IncomeTimeMonths = 'INCOME_TIME_MONTHS',
  ResidenceTimeYears = 'RESIDENCE_TIME_YEARS',
  ResidenceTimeMonths = 'RESIDENCE_TIME_MONTHS'
}

export type LeadHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  neighborhoodViewId?: Maybe<HavingIntFilter>;
  incomeAmount?: Maybe<HavingBigfloatFilter>;
  monthlyHomePayment?: Maybe<HavingBigfloatFilter>;
  incomeTimeYears?: Maybe<HavingIntFilter>;
  incomeTimeMonths?: Maybe<HavingIntFilter>;
  residenceTimeYears?: Maybe<HavingIntFilter>;
  residenceTimeMonths?: Maybe<HavingIntFilter>;
};

export type LeadHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  neighborhoodViewId?: Maybe<HavingIntFilter>;
  incomeAmount?: Maybe<HavingBigfloatFilter>;
  monthlyHomePayment?: Maybe<HavingBigfloatFilter>;
  incomeTimeYears?: Maybe<HavingIntFilter>;
  incomeTimeMonths?: Maybe<HavingIntFilter>;
  residenceTimeYears?: Maybe<HavingIntFilter>;
  residenceTimeMonths?: Maybe<HavingIntFilter>;
};

/** Conditions for `Lead` aggregates. */
export type LeadHavingInput = {
  AND?: Maybe<Array<LeadHavingInput>>;
  OR?: Maybe<Array<LeadHavingInput>>;
  sum?: Maybe<LeadHavingSumInput>;
  distinctCount?: Maybe<LeadHavingDistinctCountInput>;
  min?: Maybe<LeadHavingMinInput>;
  max?: Maybe<LeadHavingMaxInput>;
  average?: Maybe<LeadHavingAverageInput>;
  stddevSample?: Maybe<LeadHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LeadHavingStddevPopulationInput>;
  varianceSample?: Maybe<LeadHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LeadHavingVariancePopulationInput>;
};

export type LeadHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  neighborhoodViewId?: Maybe<HavingIntFilter>;
  incomeAmount?: Maybe<HavingBigfloatFilter>;
  monthlyHomePayment?: Maybe<HavingBigfloatFilter>;
  incomeTimeYears?: Maybe<HavingIntFilter>;
  incomeTimeMonths?: Maybe<HavingIntFilter>;
  residenceTimeYears?: Maybe<HavingIntFilter>;
  residenceTimeMonths?: Maybe<HavingIntFilter>;
};

export type LeadHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  neighborhoodViewId?: Maybe<HavingIntFilter>;
  incomeAmount?: Maybe<HavingBigfloatFilter>;
  monthlyHomePayment?: Maybe<HavingBigfloatFilter>;
  incomeTimeYears?: Maybe<HavingIntFilter>;
  incomeTimeMonths?: Maybe<HavingIntFilter>;
  residenceTimeYears?: Maybe<HavingIntFilter>;
  residenceTimeMonths?: Maybe<HavingIntFilter>;
};

export type LeadHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  neighborhoodViewId?: Maybe<HavingIntFilter>;
  incomeAmount?: Maybe<HavingBigfloatFilter>;
  monthlyHomePayment?: Maybe<HavingBigfloatFilter>;
  incomeTimeYears?: Maybe<HavingIntFilter>;
  incomeTimeMonths?: Maybe<HavingIntFilter>;
  residenceTimeYears?: Maybe<HavingIntFilter>;
  residenceTimeMonths?: Maybe<HavingIntFilter>;
};

export type LeadHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  neighborhoodViewId?: Maybe<HavingIntFilter>;
  incomeAmount?: Maybe<HavingBigfloatFilter>;
  monthlyHomePayment?: Maybe<HavingBigfloatFilter>;
  incomeTimeYears?: Maybe<HavingIntFilter>;
  incomeTimeMonths?: Maybe<HavingIntFilter>;
  residenceTimeYears?: Maybe<HavingIntFilter>;
  residenceTimeMonths?: Maybe<HavingIntFilter>;
};

export type LeadHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  neighborhoodViewId?: Maybe<HavingIntFilter>;
  incomeAmount?: Maybe<HavingBigfloatFilter>;
  monthlyHomePayment?: Maybe<HavingBigfloatFilter>;
  incomeTimeYears?: Maybe<HavingIntFilter>;
  incomeTimeMonths?: Maybe<HavingIntFilter>;
  residenceTimeYears?: Maybe<HavingIntFilter>;
  residenceTimeMonths?: Maybe<HavingIntFilter>;
};

export type LeadHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  neighborhoodViewId?: Maybe<HavingIntFilter>;
  incomeAmount?: Maybe<HavingBigfloatFilter>;
  monthlyHomePayment?: Maybe<HavingBigfloatFilter>;
  incomeTimeYears?: Maybe<HavingIntFilter>;
  incomeTimeMonths?: Maybe<HavingIntFilter>;
  residenceTimeYears?: Maybe<HavingIntFilter>;
  residenceTimeMonths?: Maybe<HavingIntFilter>;
};

export type LeadHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  neighborhoodViewId?: Maybe<HavingIntFilter>;
  incomeAmount?: Maybe<HavingBigfloatFilter>;
  monthlyHomePayment?: Maybe<HavingBigfloatFilter>;
  incomeTimeYears?: Maybe<HavingIntFilter>;
  incomeTimeMonths?: Maybe<HavingIntFilter>;
  residenceTimeYears?: Maybe<HavingIntFilter>;
  residenceTimeMonths?: Maybe<HavingIntFilter>;
};

export type LeadInfoCompanyType = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type LeadInfoResponse = {
  id: Scalars['UUID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  company?: Maybe<LeadInfoCompanyType>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['Float']>;
  incomePeriod?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  incomeTimeYears?: Maybe<Scalars['String']>;
  incomeTimeMonths?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  residenceTimeYears?: Maybe<Scalars['String']>;
  residenceTimeMonths?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Float']>;
  employmentStatus?: Maybe<Scalars['String']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
};

/** An input for mutations affecting `Lead` */
export type LeadInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** A connection to a list of `Integration` values, with data from `IdInquiry`. */
export type LeadIntegrationsByIdInquiryLeadIdAndIntegrationIdManyToManyConnection = {
  /** A list of `Integration` objects. */
  nodes: Array<Integration>;
  /** A list of edges which contains the `Integration`, info from the `IdInquiry`, and the cursor to aid in pagination. */
  edges: Array<LeadIntegrationsByIdInquiryLeadIdAndIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Integration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationAggregates>>;
};


/** A connection to a list of `Integration` values, with data from `IdInquiry`. */
export type LeadIntegrationsByIdInquiryLeadIdAndIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationGroupBy>;
  having?: Maybe<IntegrationHavingInput>;
};

/** A `Integration` edge in the connection, with data from `IdInquiry`. */
export type LeadIntegrationsByIdInquiryLeadIdAndIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Integration` at the end of the edge. */
  node: Integration;
  /** Reads and enables pagination through a set of `IdInquiry`. */
  idInquiries: IdInquiriesConnection;
};


/** A `Integration` edge in the connection, with data from `IdInquiry`. */
export type LeadIntegrationsByIdInquiryLeadIdAndIntegrationIdManyToManyEdgeIdInquiriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiriesOrderBy>>;
  condition?: Maybe<IdInquiryCondition>;
  filter?: Maybe<IdInquiryFilter>;
};

export type LeadJourney = {
  leadId: Scalars['UUID'];
  stage: JourneyStage;
  productId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `LeadJourney`. */
  lead?: Maybe<Lead>;
  /** Reads a single `Product` that is related to this `LeadJourney`. */
  product?: Maybe<Product>;
};

export type LeadJourneyAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LeadJourneySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LeadJourneyDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LeadJourneyMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LeadJourneyMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LeadJourneyAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LeadJourneyStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LeadJourneyStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LeadJourneyVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LeadJourneyVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `LeadJourney` object types. */
export type LeadJourneyAggregatesFilter = {
  /** A filter that must pass for the relevant `LeadJourney` object to be included within the aggregate. */
  filter?: Maybe<LeadJourneyFilter>;
  /** Sum aggregate over matching `LeadJourney` objects. */
  sum?: Maybe<LeadJourneySumAggregateFilter>;
  /** Distinct count aggregate over matching `LeadJourney` objects. */
  distinctCount?: Maybe<LeadJourneyDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `LeadJourney` objects. */
  min?: Maybe<LeadJourneyMinAggregateFilter>;
  /** Maximum aggregate over matching `LeadJourney` objects. */
  max?: Maybe<LeadJourneyMaxAggregateFilter>;
  /** Mean average aggregate over matching `LeadJourney` objects. */
  average?: Maybe<LeadJourneyAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LeadJourney` objects. */
  stddevSample?: Maybe<LeadJourneyStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `LeadJourney` objects. */
  stddevPopulation?: Maybe<LeadJourneyStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LeadJourney` objects. */
  varianceSample?: Maybe<LeadJourneyVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `LeadJourney` objects. */
  variancePopulation?: Maybe<LeadJourneyVariancePopulationAggregateFilter>;
};

export type LeadJourneyAverageAggregateFilter = {
  productId?: Maybe<BigFloatFilter>;
};

export type LeadJourneyAverageAggregates = {
  /** Mean average of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LeadJourney` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LeadJourneyCondition = {
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `stage` field. */
  stage?: Maybe<JourneyStage>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type LeadJourneyDistinctCountAggregateFilter = {
  leadId?: Maybe<BigIntFilter>;
  stage?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type LeadJourneyDistinctCountAggregates = {
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stage across the matching connection */
  stage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of productId across the matching connection */
  productId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LeadJourney` object types. All fields are combined with a logical ‘and.’ */
export type LeadJourneyFilter = {
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `stage` field. */
  stage?: Maybe<JourneyStageFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Filter by the object’s `product` relation. */
  product?: Maybe<ProductFilter>;
  /** A related `product` exists. */
  productExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeadJourneyFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeadJourneyFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeadJourneyFilter>;
};

/** Grouping methods for `LeadJourney` for usage during aggregation. */
export enum LeadJourneyGroupBy {
  LeadId = 'LEAD_ID',
  Stage = 'STAGE',
  ProductId = 'PRODUCT_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type LeadJourneyHavingAverageInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingDistinctCountInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `LeadJourney` aggregates. */
export type LeadJourneyHavingInput = {
  AND?: Maybe<Array<LeadJourneyHavingInput>>;
  OR?: Maybe<Array<LeadJourneyHavingInput>>;
  sum?: Maybe<LeadJourneyHavingSumInput>;
  distinctCount?: Maybe<LeadJourneyHavingDistinctCountInput>;
  min?: Maybe<LeadJourneyHavingMinInput>;
  max?: Maybe<LeadJourneyHavingMaxInput>;
  average?: Maybe<LeadJourneyHavingAverageInput>;
  stddevSample?: Maybe<LeadJourneyHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LeadJourneyHavingStddevPopulationInput>;
  varianceSample?: Maybe<LeadJourneyHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LeadJourneyHavingVariancePopulationInput>;
};

export type LeadJourneyHavingMaxInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingMinInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingStddevPopulationInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingStddevSampleInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingSumInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingVariancePopulationInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingVarianceSampleInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `LeadJourney` */
export type LeadJourneyInput = {
  leadId?: Maybe<Scalars['UUID']>;
  stage: JourneyStage;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadJourneyLeadIdFkeyInput>;
  productToProductId?: Maybe<LeadJourneyProductIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `LeadJourneyInput` mutation. */
export type LeadJourneyLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnLeadJourneyForLeadJourneyLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadJourneyOnLeadJourneyForLeadJourneyLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<LeadJourneyLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `leadJourney` in the `LeadInput` mutation. */
export type LeadJourneyLeadIdFkeyInverseInput = {
  /** A `LeadJourneyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadJourneyLeadIdFkeyLeadJourneyCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type LeadJourneyLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** The `leadJourney` to be created by this mutation. */
export type LeadJourneyLeadIdFkeyLeadJourneyCreateInput = {
  leadId?: Maybe<Scalars['UUID']>;
  stage: JourneyStage;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadJourneyLeadIdFkeyInput>;
  productToProductId?: Maybe<LeadJourneyProductIdFkeyInput>;
};

export type LeadJourneyMaxAggregateFilter = {
  productId?: Maybe<IntFilter>;
};

export type LeadJourneyMaxAggregates = {
  /** Maximum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
};

export type LeadJourneyMinAggregateFilter = {
  productId?: Maybe<IntFilter>;
};

export type LeadJourneyMinAggregates = {
  /** Minimum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to update. */
export type LeadJourneyOnLeadJourneyForLeadJourneyLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type LeadJourneyOnLeadJourneyForLeadJourneyProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** Input for the nested mutation of `product` in the `LeadJourneyInput` mutation. */
export type LeadJourneyProductIdFkeyInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectById?: Maybe<ProductProductPkeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByName?: Maybe<ProductProductNameKeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: Maybe<ProductNodeIdConnect>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateById?: Maybe<ProductOnLeadJourneyForLeadJourneyProductIdFkeyUsingProductPkeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByName?: Maybe<ProductOnLeadJourneyForLeadJourneyProductIdFkeyUsingProductNameKeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadJourneyOnLeadJourneyForLeadJourneyProductIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `leadJourney` in the `ProductInput` mutation. */
export type LeadJourneyProductIdFkeyInverseInput = {
  /** A `LeadJourneyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadJourneyProductIdFkeyLeadJourneyCreateInput>>;
};

/** The `leadJourney` to be created by this mutation. */
export type LeadJourneyProductIdFkeyLeadJourneyCreateInput = {
  leadId?: Maybe<Scalars['UUID']>;
  stage: JourneyStage;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadJourneyLeadIdFkeyInput>;
  productToProductId?: Maybe<LeadJourneyProductIdFkeyInput>;
};

export type LeadJourneyStddevPopulationAggregateFilter = {
  productId?: Maybe<BigFloatFilter>;
};

export type LeadJourneyStddevPopulationAggregates = {
  /** Population standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type LeadJourneyStddevSampleAggregateFilter = {
  productId?: Maybe<BigFloatFilter>;
};

export type LeadJourneyStddevSampleAggregates = {
  /** Sample standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type LeadJourneySumAggregateFilter = {
  productId?: Maybe<BigIntFilter>;
};

export type LeadJourneySumAggregates = {
  /** Sum of productId across the matching connection */
  productId: Scalars['BigInt'];
};

export type LeadJourneyVariancePopulationAggregateFilter = {
  productId?: Maybe<BigFloatFilter>;
};

export type LeadJourneyVariancePopulationAggregates = {
  /** Population variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type LeadJourneyVarianceSampleAggregateFilter = {
  productId?: Maybe<BigFloatFilter>;
};

export type LeadJourneyVarianceSampleAggregates = {
  /** Sample variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LeadJourney` values. */
export type LeadJourneysConnection = {
  /** A list of `LeadJourney` objects. */
  nodes: Array<LeadJourney>;
  /** A list of edges which contains the `LeadJourney` and cursor to aid in pagination. */
  edges: Array<LeadJourneysEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LeadJourney` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadJourneyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadJourneyAggregates>>;
};


/** A connection to a list of `LeadJourney` values. */
export type LeadJourneysConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadJourneyGroupBy>;
  having?: Maybe<LeadJourneyHavingInput>;
};

/** A `LeadJourney` edge in the connection. */
export type LeadJourneysEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LeadJourney` at the end of the edge. */
  node: LeadJourney;
};

/** Methods to use when ordering `LeadJourney`. */
export enum LeadJourneysOrderBy {
  Natural = 'NATURAL',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  StageAsc = 'STAGE_ASC',
  StageDesc = 'STAGE_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** The fields on `lead` to look up the row to connect. */
export type LeadLeadPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `lead` to look up the row to delete. */
export type LeadLeadPkeyDelete = {
  id: Scalars['UUID'];
};

/** A connection to a list of `LenderApp` values, with data from `LenderQuote`. */
export type LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyConnection = {
  /** A list of `LenderApp` objects. */
  nodes: Array<LenderApp>;
  /** A list of edges which contains the `LenderApp`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LenderApp` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderAppAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderAppAggregates>>;
};


/** A connection to a list of `LenderApp` values, with data from `LenderQuote`. */
export type LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderAppGroupBy>;
  having?: Maybe<LenderAppHavingInput>;
};

/** A `LenderApp` edge in the connection, with data from `LenderQuote`. */
export type LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LenderApp` at the end of the edge. */
  node: LenderApp;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `LenderApp` edge in the connection, with data from `LenderQuote`. */
export type LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

/** A connection to a list of `Lender` values, with data from `LenderQuote`. */
export type LeadLendersByLenderQuoteLeadIdAndLenderIdManyToManyConnection = {
  /** A list of `Lender` objects. */
  nodes: Array<Lender>;
  /** A list of edges which contains the `Lender`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<LeadLendersByLenderQuoteLeadIdAndLenderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lender` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderAggregates>>;
};


/** A connection to a list of `Lender` values, with data from `LenderQuote`. */
export type LeadLendersByLenderQuoteLeadIdAndLenderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderGroupBy>;
  having?: Maybe<LenderHavingInput>;
};

/** A `Lender` edge in the connection, with data from `LenderQuote`. */
export type LeadLendersByLenderQuoteLeadIdAndLenderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lender` at the end of the edge. */
  node: Lender;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `Lender` edge in the connection, with data from `LenderQuote`. */
export type LeadLendersByLenderQuoteLeadIdAndLenderIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

export type LeadMaxAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  shortId?: Maybe<IntFilter>;
  companyChannelId?: Maybe<IntFilter>;
  neighborhoodViewId?: Maybe<IntFilter>;
  incomeAmount?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  incomeTimeYears?: Maybe<IntFilter>;
  incomeTimeMonths?: Maybe<IntFilter>;
  residenceTimeYears?: Maybe<IntFilter>;
  residenceTimeMonths?: Maybe<IntFilter>;
};

export type LeadMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of shortId across the matching connection */
  shortId?: Maybe<Scalars['Int']>;
  /** Maximum of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Maximum of neighborhoodViewId across the matching connection */
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  /** Maximum of incomeAmount across the matching connection */
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  /** Maximum of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Maximum of incomeTimeYears across the matching connection */
  incomeTimeYears?: Maybe<Scalars['Int']>;
  /** Maximum of incomeTimeMonths across the matching connection */
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  /** Maximum of residenceTimeYears across the matching connection */
  residenceTimeYears?: Maybe<Scalars['Int']>;
  /** Maximum of residenceTimeMonths across the matching connection */
  residenceTimeMonths?: Maybe<Scalars['Int']>;
};

export type LeadMetaResponse = {
  leadId: Scalars['UUID'];
  success?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type LeadMinAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  shortId?: Maybe<IntFilter>;
  companyChannelId?: Maybe<IntFilter>;
  neighborhoodViewId?: Maybe<IntFilter>;
  incomeAmount?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  incomeTimeYears?: Maybe<IntFilter>;
  incomeTimeMonths?: Maybe<IntFilter>;
  residenceTimeYears?: Maybe<IntFilter>;
  residenceTimeMonths?: Maybe<IntFilter>;
};

export type LeadMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of shortId across the matching connection */
  shortId?: Maybe<Scalars['Int']>;
  /** Minimum of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Minimum of neighborhoodViewId across the matching connection */
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  /** Minimum of incomeAmount across the matching connection */
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  /** Minimum of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Minimum of incomeTimeYears across the matching connection */
  incomeTimeYears?: Maybe<Scalars['Int']>;
  /** Minimum of incomeTimeMonths across the matching connection */
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  /** Minimum of residenceTimeYears across the matching connection */
  residenceTimeYears?: Maybe<Scalars['Int']>;
  /** Minimum of residenceTimeMonths across the matching connection */
  residenceTimeMonths?: Maybe<Scalars['Int']>;
};

/** Input for the nested mutation of `neighborhoodView` in the `LeadInput` mutation. */
export type LeadNeighborhoodViewIdFkeyInput = {
  /** The primary key(s) for `neighborhoodView` for the far side of the relationship. */
  connectById?: Maybe<NeighborhoodViewNeighborhoodViewPkeyConnect>;
  /** The primary key(s) for `neighborhoodView` for the far side of the relationship. */
  connectByNodeId?: Maybe<NeighborhoodViewNodeIdConnect>;
  /** The primary key(s) and patch data for `neighborhoodView` for the far side of the relationship. */
  updateById?: Maybe<NeighborhoodViewOnLeadForLeadNeighborhoodViewIdFkeyUsingNeighborhoodViewPkeyUpdate>;
  /** The primary key(s) and patch data for `neighborhoodView` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnLeadForLeadNeighborhoodViewIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `lead` in the `NeighborhoodViewInput` mutation. */
export type LeadNeighborhoodViewIdFkeyInverseInput = {
  /** Flag indicating whether all other `lead` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<Array<LeadLeadPkeyConnect>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LeadNodeIdConnect>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<Array<LeadLeadPkeyDelete>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LeadNodeIdDelete>>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<Array<LeadOnLeadForLeadNeighborhoodViewIdFkeyUsingLeadPkeyUpdate>>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<NeighborhoodViewOnLeadForLeadNeighborhoodViewIdFkeyNodeIdUpdate>>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadNeighborhoodViewIdFkeyLeadCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type LeadNeighborhoodViewIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LeadNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LeadNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lead` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnAppointmentForAppointmentsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appointment` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appointment` being updated. */
  patch: AppointmentPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnAppointmentForAppointmentsLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnAppointmentForAppointmentsLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnAuditLogForAuditLogLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `auditLog` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `auditLog` being updated. */
  patch: AuditLogPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnAuditLogForAuditLogLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnAuditLogForAuditLogLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnAvaAppForAvaAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `avaApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: AvaAppPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnAvaAppForAvaAppLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnAvaAppForAvaAppLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnDealForDealsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `deal` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `deal` being updated. */
  patch: DealPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnDealForDealsLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnDealForDealsLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnDistributedLeadForDistributedLeadsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `distributedLead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `distributedLead` being updated. */
  patch: DistributedLeadPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnDistributedLeadForDistributedLeadsLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnDistributedLeadForDistributedLeadsLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnEventForFakePublicEventsForeignKey0NodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `event` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: EventPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnEventForFakePublicEventsForeignKey0UsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnEventForFakePublicEventsForeignKey0Patch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnIdInquiryForIdInquiryLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idInquiry` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idInquiry` being updated. */
  patch: IdInquiryPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnIdInquiryForIdInquiryLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnIdInquiryForIdInquiryLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnIdVerificationForIdVerificationLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idVerification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idVerification` being updated. */
  patch: IdVerificationPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnIdVerificationForIdVerificationLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnIdVerificationForIdVerificationLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `incomeVerification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `incomeVerification` being updated. */
  patch: IncomeVerificationPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnLeadCampaignForLeadCampaignsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `leadCampaign` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `leadCampaign` being updated. */
  patch: LeadCampaignPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLeadCampaignForLeadCampaignsLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnLeadForLeadCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLeadForLeadCompanyChannelIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLeadForLeadCompanyChannelIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnLeadForLeadCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLeadForLeadCompanyIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLeadForLeadCompanyIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnLeadForLeadNeighborhoodViewIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `neighborhoodView` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `neighborhoodView` being updated. */
  patch: NeighborhoodViewPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLeadForLeadNeighborhoodViewIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLeadForLeadNeighborhoodViewIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLeadJourneyForLeadJourneyLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLeadJourneyForLeadJourneyLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnLeadRoleForLeadRoleLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `leadRole` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `leadRole` being updated. */
  patch: LeadRolePatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLeadRoleForLeadRoleLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLeadRoleForLeadRoleLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnLenderAppForLenderAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: LenderAppPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLenderAppForLenderAppLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLenderAppForLenderAppLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnLenderQuoteForLenderQuotesLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderQuote` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: LenderQuotePatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLenderQuoteForLenderQuotesLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLenderQuoteForLenderQuotesLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnTradeAppForTradeAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `tradeApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `tradeApp` being updated. */
  patch: TradeAppPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnTradeAppForTradeAppLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnTradeAppForTradeAppLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `Lead`. Fields that are set will be updated. */
export type LeadPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export enum LeadPriority {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW'
}

/** A filter to be used against LeadPriority fields. All fields are combined with a logical ‘and.’ */
export type LeadPriorityFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<LeadPriority>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<LeadPriority>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<LeadPriority>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<LeadPriority>;
  /** Included in the specified list. */
  in?: Maybe<Array<LeadPriority>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<LeadPriority>>;
  /** Less than the specified value. */
  lessThan?: Maybe<LeadPriority>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<LeadPriority>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<LeadPriority>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<LeadPriority>;
};

/** A connection to a list of `Product` values, with data from `LeadJourney`. */
export type LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyConnection = {
  /** A list of `Product` objects. */
  nodes: Array<Product>;
  /** A list of edges which contains the `Product`, info from the `LeadJourney`, and the cursor to aid in pagination. */
  edges: Array<LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductAggregates>>;
};


/** A connection to a list of `Product` values, with data from `LeadJourney`. */
export type LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductGroupBy>;
  having?: Maybe<ProductHavingInput>;
};

/** A `Product` edge in the connection, with data from `LeadJourney`. */
export type LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node: Product;
  /** Reads and enables pagination through a set of `LeadJourney`. */
  leadJourneys: LeadJourneysConnection;
};


/** A `Product` edge in the connection, with data from `LeadJourney`. */
export type LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyEdgeLeadJourneysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
  condition?: Maybe<LeadJourneyCondition>;
  filter?: Maybe<LeadJourneyFilter>;
};

export type LeadRole = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['Int']>;
  role: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `LeadRole`. */
  lead?: Maybe<Lead>;
  /** Reads a single `User` that is related to this `LeadRole`. */
  user?: Maybe<User>;
};

export type LeadRoleAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LeadRoleSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LeadRoleDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LeadRoleMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LeadRoleMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LeadRoleAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LeadRoleStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LeadRoleStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LeadRoleVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LeadRoleVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `LeadRole` object types. */
export type LeadRoleAggregatesFilter = {
  /** A filter that must pass for the relevant `LeadRole` object to be included within the aggregate. */
  filter?: Maybe<LeadRoleFilter>;
  /** Sum aggregate over matching `LeadRole` objects. */
  sum?: Maybe<LeadRoleSumAggregateFilter>;
  /** Distinct count aggregate over matching `LeadRole` objects. */
  distinctCount?: Maybe<LeadRoleDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `LeadRole` objects. */
  min?: Maybe<LeadRoleMinAggregateFilter>;
  /** Maximum aggregate over matching `LeadRole` objects. */
  max?: Maybe<LeadRoleMaxAggregateFilter>;
  /** Mean average aggregate over matching `LeadRole` objects. */
  average?: Maybe<LeadRoleAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LeadRole` objects. */
  stddevSample?: Maybe<LeadRoleStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `LeadRole` objects. */
  stddevPopulation?: Maybe<LeadRoleStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LeadRole` objects. */
  varianceSample?: Maybe<LeadRoleVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `LeadRole` objects. */
  variancePopulation?: Maybe<LeadRoleVariancePopulationAggregateFilter>;
};

export type LeadRoleAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type LeadRoleAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LeadRole` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LeadRoleCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `role` field. */
  role?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type LeadRoleDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  userId?: Maybe<BigIntFilter>;
  role?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type LeadRoleDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of role across the matching connection */
  role?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LeadRole` object types. All fields are combined with a logical ‘and.’ */
export type LeadRoleFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<IntFilter>;
  /** Filter by the object’s `role` field. */
  role?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** A related `lead` exists. */
  leadExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `user` relation. */
  user?: Maybe<UserFilter>;
  /** A related `user` exists. */
  userExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeadRoleFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeadRoleFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeadRoleFilter>;
};

/** Grouping methods for `LeadRole` for usage during aggregation. */
export enum LeadRoleGroupBy {
  LeadId = 'LEAD_ID',
  UserId = 'USER_ID',
  Role = 'ROLE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type LeadRoleHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadRoleHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `LeadRole` aggregates. */
export type LeadRoleHavingInput = {
  AND?: Maybe<Array<LeadRoleHavingInput>>;
  OR?: Maybe<Array<LeadRoleHavingInput>>;
  sum?: Maybe<LeadRoleHavingSumInput>;
  distinctCount?: Maybe<LeadRoleHavingDistinctCountInput>;
  min?: Maybe<LeadRoleHavingMinInput>;
  max?: Maybe<LeadRoleHavingMaxInput>;
  average?: Maybe<LeadRoleHavingAverageInput>;
  stddevSample?: Maybe<LeadRoleHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LeadRoleHavingStddevPopulationInput>;
  varianceSample?: Maybe<LeadRoleHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LeadRoleHavingVariancePopulationInput>;
};

export type LeadRoleHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadRoleHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadRoleHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadRoleHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadRoleHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadRoleHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadRoleHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  userId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `LeadRole` */
export type LeadRoleInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['Int']>;
  role: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadRoleLeadIdFkeyInput>;
  userToUserId?: Maybe<LeadRoleUserIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `LeadRoleInput` mutation. */
export type LeadRoleLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnLeadRoleForLeadRoleLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadRoleOnLeadRoleForLeadRoleLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<LeadRoleLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `leadRole` in the `LeadInput` mutation. */
export type LeadRoleLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `leadRole` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `leadRole` for the far side of the relationship. */
  connectById?: Maybe<Array<LeadRoleLeadRolePkeyConnect>>;
  /** The primary key(s) for `leadRole` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LeadRoleNodeIdConnect>>;
  /** The primary key(s) for `leadRole` for the far side of the relationship. */
  deleteById?: Maybe<Array<LeadRoleLeadRolePkeyDelete>>;
  /** The primary key(s) for `leadRole` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LeadRoleNodeIdDelete>>;
  /** The primary key(s) and patch data for `leadRole` for the far side of the relationship. */
  updateById?: Maybe<Array<LeadRoleOnLeadRoleForLeadRoleLeadIdFkeyUsingLeadRolePkeyUpdate>>;
  /** The primary key(s) and patch data for `leadRole` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnLeadRoleForLeadRoleLeadIdFkeyNodeIdUpdate>>;
  /** A `LeadRoleInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadRoleLeadIdFkeyLeadRoleCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type LeadRoleLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** The `leadRole` to be created by this mutation. */
export type LeadRoleLeadIdFkeyLeadRoleCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['Int']>;
  role: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadRoleLeadIdFkeyInput>;
  userToUserId?: Maybe<LeadRoleUserIdFkeyInput>;
};

/** The fields on `leadRole` to look up the row to connect. */
export type LeadRoleLeadRolePkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `leadRole` to look up the row to delete. */
export type LeadRoleLeadRolePkeyDelete = {
  id: Scalars['Int'];
};

export type LeadRoleMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  userId?: Maybe<IntFilter>;
};

export type LeadRoleMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
};

export type LeadRoleMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  userId?: Maybe<IntFilter>;
};

export type LeadRoleMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LeadRoleNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `leadRole` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LeadRoleNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `leadRole` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadRoleOnLeadRoleForLeadRoleLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `leadRole` to look up the row to update. */
export type LeadRoleOnLeadRoleForLeadRoleLeadIdFkeyUsingLeadRolePkeyUpdate = {
  /** An object where the defined keys will be set on the `leadRole` being updated. */
  patch: UpdateLeadRoleOnLeadRoleForLeadRoleLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadRoleOnLeadRoleForLeadRoleUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `leadRole` to look up the row to update. */
export type LeadRoleOnLeadRoleForLeadRoleUserIdFkeyUsingLeadRolePkeyUpdate = {
  /** An object where the defined keys will be set on the `leadRole` being updated. */
  patch: UpdateLeadRoleOnLeadRoleForLeadRoleUserIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `LeadRole`. Fields that are set will be updated. */
export type LeadRolePatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadRoleLeadIdFkeyInput>;
  userToUserId?: Maybe<LeadRoleUserIdFkeyInput>;
};

export type LeadRoleStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type LeadRoleStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type LeadRoleStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type LeadRoleStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type LeadRoleSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  userId?: Maybe<BigIntFilter>;
};

export type LeadRoleSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt'];
};

/** Input for the nested mutation of `user` in the `LeadRoleInput` mutation. */
export type LeadRoleUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnLeadRoleForLeadRoleUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadRoleOnLeadRoleForLeadRoleUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `leadRole` in the `UserInput` mutation. */
export type LeadRoleUserIdFkeyInverseInput = {
  /** Flag indicating whether all other `leadRole` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `leadRole` for the far side of the relationship. */
  connectById?: Maybe<Array<LeadRoleLeadRolePkeyConnect>>;
  /** The primary key(s) for `leadRole` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LeadRoleNodeIdConnect>>;
  /** The primary key(s) for `leadRole` for the far side of the relationship. */
  deleteById?: Maybe<Array<LeadRoleLeadRolePkeyDelete>>;
  /** The primary key(s) for `leadRole` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LeadRoleNodeIdDelete>>;
  /** The primary key(s) and patch data for `leadRole` for the far side of the relationship. */
  updateById?: Maybe<Array<LeadRoleOnLeadRoleForLeadRoleUserIdFkeyUsingLeadRolePkeyUpdate>>;
  /** The primary key(s) and patch data for `leadRole` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnLeadRoleForLeadRoleUserIdFkeyNodeIdUpdate>>;
  /** A `LeadRoleInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadRoleUserIdFkeyLeadRoleCreateInput>>;
};

/** The `leadRole` to be created by this mutation. */
export type LeadRoleUserIdFkeyLeadRoleCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['Int']>;
  role: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadRoleLeadIdFkeyInput>;
  userToUserId?: Maybe<LeadRoleUserIdFkeyInput>;
};

export type LeadRoleVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type LeadRoleVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type LeadRoleVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type LeadRoleVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LeadRole` values. */
export type LeadRolesConnection = {
  /** A list of `LeadRole` objects. */
  nodes: Array<LeadRole>;
  /** A list of edges which contains the `LeadRole` and cursor to aid in pagination. */
  edges: Array<LeadRolesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LeadRole` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadRoleAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadRoleAggregates>>;
};


/** A connection to a list of `LeadRole` values. */
export type LeadRolesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadRoleGroupBy>;
  having?: Maybe<LeadRoleHavingInput>;
};

/** A `LeadRole` edge in the connection. */
export type LeadRolesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LeadRole` at the end of the edge. */
  node: LeadRole;
};

/** Methods to use when ordering `LeadRole`. */
export enum LeadRolesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  RoleAsc = 'ROLE_ASC',
  RoleDesc = 'ROLE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum LeadState {
  New = 'NEW',
  Working = 'WORKING',
  Sold = 'SOLD',
  Dead = 'DEAD',
  Contacted = 'CONTACTED',
  AppointmentBooked = 'APPOINTMENT_BOOKED'
}

/** A filter to be used against LeadState fields. All fields are combined with a logical ‘and.’ */
export type LeadStateFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<LeadState>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<LeadState>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<LeadState>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<LeadState>;
  /** Included in the specified list. */
  in?: Maybe<Array<LeadState>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<LeadState>>;
  /** Less than the specified value. */
  lessThan?: Maybe<LeadState>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<LeadState>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<LeadState>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<LeadState>;
};

export type LeadStddevPopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  shortId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  neighborhoodViewId?: Maybe<BigFloatFilter>;
  incomeAmount?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  incomeTimeYears?: Maybe<BigFloatFilter>;
  incomeTimeMonths?: Maybe<BigFloatFilter>;
  residenceTimeYears?: Maybe<BigFloatFilter>;
  residenceTimeMonths?: Maybe<BigFloatFilter>;
};

export type LeadStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of neighborhoodViewId across the matching connection */
  neighborhoodViewId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of incomeAmount across the matching connection */
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of incomeTimeYears across the matching connection */
  incomeTimeYears?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of incomeTimeMonths across the matching connection */
  incomeTimeMonths?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of residenceTimeYears across the matching connection */
  residenceTimeYears?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of residenceTimeMonths across the matching connection */
  residenceTimeMonths?: Maybe<Scalars['BigFloat']>;
};

export type LeadStddevSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  shortId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  neighborhoodViewId?: Maybe<BigFloatFilter>;
  incomeAmount?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  incomeTimeYears?: Maybe<BigFloatFilter>;
  incomeTimeMonths?: Maybe<BigFloatFilter>;
  residenceTimeYears?: Maybe<BigFloatFilter>;
  residenceTimeMonths?: Maybe<BigFloatFilter>;
};

export type LeadStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of neighborhoodViewId across the matching connection */
  neighborhoodViewId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of incomeAmount across the matching connection */
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of incomeTimeYears across the matching connection */
  incomeTimeYears?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of incomeTimeMonths across the matching connection */
  incomeTimeMonths?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of residenceTimeYears across the matching connection */
  residenceTimeYears?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of residenceTimeMonths across the matching connection */
  residenceTimeMonths?: Maybe<Scalars['BigFloat']>;
};

export type LeadSumAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  shortId?: Maybe<BigIntFilter>;
  companyChannelId?: Maybe<BigIntFilter>;
  neighborhoodViewId?: Maybe<BigIntFilter>;
  incomeAmount?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  incomeTimeYears?: Maybe<BigIntFilter>;
  incomeTimeMonths?: Maybe<BigIntFilter>;
  residenceTimeYears?: Maybe<BigIntFilter>;
  residenceTimeMonths?: Maybe<BigIntFilter>;
};

export type LeadSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of shortId across the matching connection */
  shortId: Scalars['BigInt'];
  /** Sum of companyChannelId across the matching connection */
  companyChannelId: Scalars['BigInt'];
  /** Sum of neighborhoodViewId across the matching connection */
  neighborhoodViewId: Scalars['BigInt'];
  /** Sum of incomeAmount across the matching connection */
  incomeAmount: Scalars['BigFloat'];
  /** Sum of monthlyHomePayment across the matching connection */
  monthlyHomePayment: Scalars['BigFloat'];
  /** Sum of incomeTimeYears across the matching connection */
  incomeTimeYears: Scalars['BigInt'];
  /** Sum of incomeTimeMonths across the matching connection */
  incomeTimeMonths: Scalars['BigInt'];
  /** Sum of residenceTimeYears across the matching connection */
  residenceTimeYears: Scalars['BigInt'];
  /** Sum of residenceTimeMonths across the matching connection */
  residenceTimeMonths: Scalars['BigInt'];
};

/** A filter to be used against many `Appointment` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyAppointmentFilter = {
  /** Every related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AppointmentFilter>;
  /** Some related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AppointmentFilter>;
  /** No related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AppointmentFilter>;
  /** Aggregates across related `Appointment` match the filter criteria. */
  aggregates?: Maybe<AppointmentAggregatesFilter>;
};

/** A filter to be used against many `AuditLog` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyAuditLogFilter = {
  /** Every related `AuditLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AuditLogFilter>;
  /** Some related `AuditLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AuditLogFilter>;
  /** No related `AuditLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AuditLogFilter>;
  /** Aggregates across related `AuditLog` match the filter criteria. */
  aggregates?: Maybe<AuditLogAggregatesFilter>;
};

/** A filter to be used against many `Deal` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyDealFilter = {
  /** Every related `Deal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<DealFilter>;
  /** Some related `Deal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<DealFilter>;
  /** No related `Deal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<DealFilter>;
  /** Aggregates across related `Deal` match the filter criteria. */
  aggregates?: Maybe<DealAggregatesFilter>;
};

/** A filter to be used against many `DistributedLead` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyDistributedLeadFilter = {
  /** Every related `DistributedLead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<DistributedLeadFilter>;
  /** Some related `DistributedLead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<DistributedLeadFilter>;
  /** No related `DistributedLead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<DistributedLeadFilter>;
  /** Aggregates across related `DistributedLead` match the filter criteria. */
  aggregates?: Maybe<DistributedLeadAggregatesFilter>;
};

/** A filter to be used against many `Event` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyEventFilter = {
  /** Every related `Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EventFilter>;
  /** Some related `Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EventFilter>;
  /** No related `Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EventFilter>;
  /** Aggregates across related `Event` match the filter criteria. */
  aggregates?: Maybe<EventAggregatesFilter>;
};

/** A filter to be used against many `IdInquiry` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyIdInquiryFilter = {
  /** Every related `IdInquiry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<IdInquiryFilter>;
  /** Some related `IdInquiry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<IdInquiryFilter>;
  /** No related `IdInquiry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<IdInquiryFilter>;
  /** Aggregates across related `IdInquiry` match the filter criteria. */
  aggregates?: Maybe<IdInquiryAggregatesFilter>;
};

/** A filter to be used against many `LeadJourney` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyLeadJourneyFilter = {
  /** Every related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeadJourneyFilter>;
  /** Some related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeadJourneyFilter>;
  /** No related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeadJourneyFilter>;
  /** Aggregates across related `LeadJourney` match the filter criteria. */
  aggregates?: Maybe<LeadJourneyAggregatesFilter>;
};

/** A filter to be used against many `LeadRole` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyLeadRoleFilter = {
  /** Every related `LeadRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeadRoleFilter>;
  /** Some related `LeadRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeadRoleFilter>;
  /** No related `LeadRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeadRoleFilter>;
  /** Aggregates across related `LeadRole` match the filter criteria. */
  aggregates?: Maybe<LeadRoleAggregatesFilter>;
};

/** A filter to be used against many `LenderApp` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyLenderAppFilter = {
  /** Every related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderAppFilter>;
  /** Some related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderAppFilter>;
  /** No related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderAppFilter>;
  /** Aggregates across related `LenderApp` match the filter criteria. */
  aggregates?: Maybe<LenderAppAggregatesFilter>;
};

/** A filter to be used against many `LenderQuote` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyLenderQuoteFilter = {
  /** Every related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderQuoteFilter>;
  /** Some related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderQuoteFilter>;
  /** No related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderQuoteFilter>;
  /** Aggregates across related `LenderQuote` match the filter criteria. */
  aggregates?: Maybe<LenderQuoteAggregatesFilter>;
};

/** A filter to be used against many `TradeApp` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyTradeAppFilter = {
  /** Every related `TradeApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TradeAppFilter>;
  /** Some related `TradeApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TradeAppFilter>;
  /** No related `TradeApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TradeAppFilter>;
  /** Aggregates across related `TradeApp` match the filter criteria. */
  aggregates?: Maybe<TradeAppAggregatesFilter>;
};

/** A connection to a list of `User` values, with data from `AuditLog`. */
export type LeadUsersByAuditLogLeadIdAndUserIdManyToManyConnection = {
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `AuditLog`, and the cursor to aid in pagination. */
  edges: Array<LeadUsersByAuditLogLeadIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
};


/** A connection to a list of `User` values, with data from `AuditLog`. */
export type LeadUsersByAuditLogLeadIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having?: Maybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `AuditLog`. */
export type LeadUsersByAuditLogLeadIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `AuditLog`. */
  auditLogs: AuditLogsConnection;
};


/** A `User` edge in the connection, with data from `AuditLog`. */
export type LeadUsersByAuditLogLeadIdAndUserIdManyToManyEdgeAuditLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuditLogsOrderBy>>;
  condition?: Maybe<AuditLogCondition>;
  filter?: Maybe<AuditLogFilter>;
};

/** A connection to a list of `User` values, with data from `LeadRole`. */
export type LeadUsersByLeadRoleLeadIdAndUserIdManyToManyConnection = {
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `LeadRole`, and the cursor to aid in pagination. */
  edges: Array<LeadUsersByLeadRoleLeadIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
};


/** A connection to a list of `User` values, with data from `LeadRole`. */
export type LeadUsersByLeadRoleLeadIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having?: Maybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `LeadRole`. */
export type LeadUsersByLeadRoleLeadIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `LeadRole`. */
  leadRoles: LeadRolesConnection;
};


/** A `User` edge in the connection, with data from `LeadRole`. */
export type LeadUsersByLeadRoleLeadIdAndUserIdManyToManyEdgeLeadRolesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadRolesOrderBy>>;
  condition?: Maybe<LeadRoleCondition>;
  filter?: Maybe<LeadRoleFilter>;
};

export type LeadVariancePopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  shortId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  neighborhoodViewId?: Maybe<BigFloatFilter>;
  incomeAmount?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  incomeTimeYears?: Maybe<BigFloatFilter>;
  incomeTimeMonths?: Maybe<BigFloatFilter>;
  residenceTimeYears?: Maybe<BigFloatFilter>;
  residenceTimeMonths?: Maybe<BigFloatFilter>;
};

export type LeadVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of neighborhoodViewId across the matching connection */
  neighborhoodViewId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of incomeAmount across the matching connection */
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Population variance of incomeTimeYears across the matching connection */
  incomeTimeYears?: Maybe<Scalars['BigFloat']>;
  /** Population variance of incomeTimeMonths across the matching connection */
  incomeTimeMonths?: Maybe<Scalars['BigFloat']>;
  /** Population variance of residenceTimeYears across the matching connection */
  residenceTimeYears?: Maybe<Scalars['BigFloat']>;
  /** Population variance of residenceTimeMonths across the matching connection */
  residenceTimeMonths?: Maybe<Scalars['BigFloat']>;
};

export type LeadVarianceSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  shortId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  neighborhoodViewId?: Maybe<BigFloatFilter>;
  incomeAmount?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  incomeTimeYears?: Maybe<BigFloatFilter>;
  incomeTimeMonths?: Maybe<BigFloatFilter>;
  residenceTimeYears?: Maybe<BigFloatFilter>;
  residenceTimeMonths?: Maybe<BigFloatFilter>;
};

export type LeadVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of neighborhoodViewId across the matching connection */
  neighborhoodViewId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of incomeAmount across the matching connection */
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of incomeTimeYears across the matching connection */
  incomeTimeYears?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of incomeTimeMonths across the matching connection */
  incomeTimeMonths?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of residenceTimeYears across the matching connection */
  residenceTimeYears?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of residenceTimeMonths across the matching connection */
  residenceTimeMonths?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `VehicleListing` values, with data from `Appointment`. */
export type LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyConnection = {
  /** A list of `VehicleListing` objects. */
  nodes: Array<VehicleListing>;
  /** A list of edges which contains the `VehicleListing`, info from the `Appointment`, and the cursor to aid in pagination. */
  edges: Array<LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VehicleListing` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<VehicleListingAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<VehicleListingAggregates>>;
};


/** A connection to a list of `VehicleListing` values, with data from `Appointment`. */
export type LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<VehicleListingGroupBy>;
  having?: Maybe<VehicleListingHavingInput>;
};

/** A `VehicleListing` edge in the connection, with data from `Appointment`. */
export type LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `VehicleListing` at the end of the edge. */
  node: VehicleListing;
  /** Reads and enables pagination through a set of `Appointment`. */
  appointments: AppointmentsConnection;
};


/** A `VehicleListing` edge in the connection, with data from `Appointment`. */
export type LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyEdgeAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
  condition?: Maybe<AppointmentCondition>;
  filter?: Maybe<AppointmentFilter>;
};

/** A connection to a list of `VehicleListing` values, with data from `LenderApp`. */
export type LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyConnection = {
  /** A list of `VehicleListing` objects. */
  nodes: Array<VehicleListing>;
  /** A list of edges which contains the `VehicleListing`, info from the `LenderApp`, and the cursor to aid in pagination. */
  edges: Array<LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VehicleListing` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<VehicleListingAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<VehicleListingAggregates>>;
};


/** A connection to a list of `VehicleListing` values, with data from `LenderApp`. */
export type LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<VehicleListingGroupBy>;
  having?: Maybe<VehicleListingHavingInput>;
};

/** A `VehicleListing` edge in the connection, with data from `LenderApp`. */
export type LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `VehicleListing` at the end of the edge. */
  node: VehicleListing;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderApps: LenderAppsConnection;
};


/** A `VehicleListing` edge in the connection, with data from `LenderApp`. */
export type LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyEdgeLenderAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};

/** A connection to a list of `Lead` values. */
export type LeadsConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead` and cursor to aid in pagination. */
  edges: Array<LeadsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values. */
export type LeadsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection. */
export type LeadsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
};

/** Methods to use when ordering `Lead`. */
export enum LeadsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  DateOfBirthAsc = 'DATE_OF_BIRTH_ASC',
  DateOfBirthDesc = 'DATE_OF_BIRTH_DESC',
  StreetAsc = 'STREET_ASC',
  StreetDesc = 'STREET_DESC',
  StreetNumberAsc = 'STREET_NUMBER_ASC',
  StreetNumberDesc = 'STREET_NUMBER_DESC',
  UnitAsc = 'UNIT_ASC',
  UnitDesc = 'UNIT_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  PostalCodeAsc = 'POSTAL_CODE_ASC',
  PostalCodeDesc = 'POSTAL_CODE_DESC',
  ProvinceCodeAsc = 'PROVINCE_CODE_ASC',
  ProvinceCodeDesc = 'PROVINCE_CODE_DESC',
  EmailAddressAsc = 'EMAIL_ADDRESS_ASC',
  EmailAddressDesc = 'EMAIL_ADDRESS_DESC',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  SourceUrlAsc = 'SOURCE_URL_ASC',
  SourceUrlDesc = 'SOURCE_URL_DESC',
  TransactionKeyAsc = 'TRANSACTION_KEY_ASC',
  TransactionKeyDesc = 'TRANSACTION_KEY_DESC',
  LeadspediaIdAsc = 'LEADSPEDIA_ID_ASC',
  LeadspediaIdDesc = 'LEADSPEDIA_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  SearchTextAsc = 'SEARCH_TEXT_ASC',
  SearchTextDesc = 'SEARCH_TEXT_DESC',
  CommentAsc = 'COMMENT_ASC',
  CommentDesc = 'COMMENT_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  ReadAsc = 'READ_ASC',
  ReadDesc = 'READ_DESC',
  ExternalIdsAsc = 'EXTERNAL_IDS_ASC',
  ExternalIdsDesc = 'EXTERNAL_IDS_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  AdditionalInformationAsc = 'ADDITIONAL_INFORMATION_ASC',
  AdditionalInformationDesc = 'ADDITIONAL_INFORMATION_DESC',
  PhoneVerifiedAtAsc = 'PHONE_VERIFIED_AT_ASC',
  PhoneVerifiedAtDesc = 'PHONE_VERIFIED_AT_DESC',
  IpAddressAsc = 'IP_ADDRESS_ASC',
  IpAddressDesc = 'IP_ADDRESS_DESC',
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  AssigneeAsc = 'ASSIGNEE_ASC',
  AssigneeDesc = 'ASSIGNEE_DESC',
  ShortIdAsc = 'SHORT_ID_ASC',
  ShortIdDesc = 'SHORT_ID_DESC',
  CompanyChannelIdAsc = 'COMPANY_CHANNEL_ID_ASC',
  CompanyChannelIdDesc = 'COMPANY_CHANNEL_ID_DESC',
  NeighborhoodViewIdAsc = 'NEIGHBORHOOD_VIEW_ID_ASC',
  NeighborhoodViewIdDesc = 'NEIGHBORHOOD_VIEW_ID_DESC',
  MaritalStatusAsc = 'MARITAL_STATUS_ASC',
  MaritalStatusDesc = 'MARITAL_STATUS_DESC',
  DesiredVehicleAsc = 'DESIRED_VEHICLE_ASC',
  DesiredVehicleDesc = 'DESIRED_VEHICLE_DESC',
  JobTitleAsc = 'JOB_TITLE_ASC',
  JobTitleDesc = 'JOB_TITLE_DESC',
  IncomePeriodAsc = 'INCOME_PERIOD_ASC',
  IncomePeriodDesc = 'INCOME_PERIOD_DESC',
  SalaryHourlyAsc = 'SALARY_HOURLY_ASC',
  SalaryHourlyDesc = 'SALARY_HOURLY_DESC',
  IncomeSourceAsc = 'INCOME_SOURCE_ASC',
  IncomeSourceDesc = 'INCOME_SOURCE_DESC',
  IncomeTimeAsc = 'INCOME_TIME_ASC',
  IncomeTimeDesc = 'INCOME_TIME_DESC',
  PreferredContactMethodAsc = 'PREFERRED_CONTACT_METHOD_ASC',
  PreferredContactMethodDesc = 'PREFERRED_CONTACT_METHOD_DESC',
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  CreditConcernAsc = 'CREDIT_CONCERN_ASC',
  CreditConcernDesc = 'CREDIT_CONCERN_DESC',
  ResidenceTimeAsc = 'RESIDENCE_TIME_ASC',
  ResidenceTimeDesc = 'RESIDENCE_TIME_DESC',
  RentOrOwnAsc = 'RENT_OR_OWN_ASC',
  RentOrOwnDesc = 'RENT_OR_OWN_DESC',
  EmploymentStatusAsc = 'EMPLOYMENT_STATUS_ASC',
  EmploymentStatusDesc = 'EMPLOYMENT_STATUS_DESC',
  IncomeAmountAsc = 'INCOME_AMOUNT_ASC',
  IncomeAmountDesc = 'INCOME_AMOUNT_DESC',
  MonthlyHomePaymentAsc = 'MONTHLY_HOME_PAYMENT_ASC',
  MonthlyHomePaymentDesc = 'MONTHLY_HOME_PAYMENT_DESC',
  SmsConsentAsc = 'SMS_CONSENT_ASC',
  SmsConsentDesc = 'SMS_CONSENT_DESC',
  IncomeTimeYearsAsc = 'INCOME_TIME_YEARS_ASC',
  IncomeTimeYearsDesc = 'INCOME_TIME_YEARS_DESC',
  IncomeTimeMonthsAsc = 'INCOME_TIME_MONTHS_ASC',
  IncomeTimeMonthsDesc = 'INCOME_TIME_MONTHS_DESC',
  ResidenceTimeYearsAsc = 'RESIDENCE_TIME_YEARS_ASC',
  ResidenceTimeYearsDesc = 'RESIDENCE_TIME_YEARS_DESC',
  ResidenceTimeMonthsAsc = 'RESIDENCE_TIME_MONTHS_ASC',
  ResidenceTimeMonthsDesc = 'RESIDENCE_TIME_MONTHS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SearchTextRankAsc = 'SEARCH_TEXT_RANK_ASC',
  SearchTextRankDesc = 'SEARCH_TEXT_RANK_DESC',
  TradeAppsCountAsc = 'TRADE_APPS_COUNT_ASC',
  TradeAppsCountDesc = 'TRADE_APPS_COUNT_DESC',
  TradeAppsSumIdAsc = 'TRADE_APPS_SUM_ID_ASC',
  TradeAppsSumIdDesc = 'TRADE_APPS_SUM_ID_DESC',
  TradeAppsSumLeadIdAsc = 'TRADE_APPS_SUM_LEAD_ID_ASC',
  TradeAppsSumLeadIdDesc = 'TRADE_APPS_SUM_LEAD_ID_DESC',
  TradeAppsSumYearAsc = 'TRADE_APPS_SUM_YEAR_ASC',
  TradeAppsSumYearDesc = 'TRADE_APPS_SUM_YEAR_DESC',
  TradeAppsSumMakeAsc = 'TRADE_APPS_SUM_MAKE_ASC',
  TradeAppsSumMakeDesc = 'TRADE_APPS_SUM_MAKE_DESC',
  TradeAppsSumModelAsc = 'TRADE_APPS_SUM_MODEL_ASC',
  TradeAppsSumModelDesc = 'TRADE_APPS_SUM_MODEL_DESC',
  TradeAppsSumTrimAsc = 'TRADE_APPS_SUM_TRIM_ASC',
  TradeAppsSumTrimDesc = 'TRADE_APPS_SUM_TRIM_DESC',
  TradeAppsSumMileageAsc = 'TRADE_APPS_SUM_MILEAGE_ASC',
  TradeAppsSumMileageDesc = 'TRADE_APPS_SUM_MILEAGE_DESC',
  TradeAppsSumConditionAsc = 'TRADE_APPS_SUM_CONDITION_ASC',
  TradeAppsSumConditionDesc = 'TRADE_APPS_SUM_CONDITION_DESC',
  TradeAppsSumTradeInValueAsc = 'TRADE_APPS_SUM_TRADE_IN_VALUE_ASC',
  TradeAppsSumTradeInValueDesc = 'TRADE_APPS_SUM_TRADE_IN_VALUE_DESC',
  TradeAppsSumMaxTradeInValueAsc = 'TRADE_APPS_SUM_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsSumMaxTradeInValueDesc = 'TRADE_APPS_SUM_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsSumMinTradeInValueAsc = 'TRADE_APPS_SUM_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsSumMinTradeInValueDesc = 'TRADE_APPS_SUM_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsSumCreatedAtAsc = 'TRADE_APPS_SUM_CREATED_AT_ASC',
  TradeAppsSumCreatedAtDesc = 'TRADE_APPS_SUM_CREATED_AT_DESC',
  TradeAppsSumUpdatedAtAsc = 'TRADE_APPS_SUM_UPDATED_AT_ASC',
  TradeAppsSumUpdatedAtDesc = 'TRADE_APPS_SUM_UPDATED_AT_DESC',
  TradeAppsDistinctCountIdAsc = 'TRADE_APPS_DISTINCT_COUNT_ID_ASC',
  TradeAppsDistinctCountIdDesc = 'TRADE_APPS_DISTINCT_COUNT_ID_DESC',
  TradeAppsDistinctCountLeadIdAsc = 'TRADE_APPS_DISTINCT_COUNT_LEAD_ID_ASC',
  TradeAppsDistinctCountLeadIdDesc = 'TRADE_APPS_DISTINCT_COUNT_LEAD_ID_DESC',
  TradeAppsDistinctCountYearAsc = 'TRADE_APPS_DISTINCT_COUNT_YEAR_ASC',
  TradeAppsDistinctCountYearDesc = 'TRADE_APPS_DISTINCT_COUNT_YEAR_DESC',
  TradeAppsDistinctCountMakeAsc = 'TRADE_APPS_DISTINCT_COUNT_MAKE_ASC',
  TradeAppsDistinctCountMakeDesc = 'TRADE_APPS_DISTINCT_COUNT_MAKE_DESC',
  TradeAppsDistinctCountModelAsc = 'TRADE_APPS_DISTINCT_COUNT_MODEL_ASC',
  TradeAppsDistinctCountModelDesc = 'TRADE_APPS_DISTINCT_COUNT_MODEL_DESC',
  TradeAppsDistinctCountTrimAsc = 'TRADE_APPS_DISTINCT_COUNT_TRIM_ASC',
  TradeAppsDistinctCountTrimDesc = 'TRADE_APPS_DISTINCT_COUNT_TRIM_DESC',
  TradeAppsDistinctCountMileageAsc = 'TRADE_APPS_DISTINCT_COUNT_MILEAGE_ASC',
  TradeAppsDistinctCountMileageDesc = 'TRADE_APPS_DISTINCT_COUNT_MILEAGE_DESC',
  TradeAppsDistinctCountConditionAsc = 'TRADE_APPS_DISTINCT_COUNT_CONDITION_ASC',
  TradeAppsDistinctCountConditionDesc = 'TRADE_APPS_DISTINCT_COUNT_CONDITION_DESC',
  TradeAppsDistinctCountTradeInValueAsc = 'TRADE_APPS_DISTINCT_COUNT_TRADE_IN_VALUE_ASC',
  TradeAppsDistinctCountTradeInValueDesc = 'TRADE_APPS_DISTINCT_COUNT_TRADE_IN_VALUE_DESC',
  TradeAppsDistinctCountMaxTradeInValueAsc = 'TRADE_APPS_DISTINCT_COUNT_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsDistinctCountMaxTradeInValueDesc = 'TRADE_APPS_DISTINCT_COUNT_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsDistinctCountMinTradeInValueAsc = 'TRADE_APPS_DISTINCT_COUNT_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsDistinctCountMinTradeInValueDesc = 'TRADE_APPS_DISTINCT_COUNT_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsDistinctCountCreatedAtAsc = 'TRADE_APPS_DISTINCT_COUNT_CREATED_AT_ASC',
  TradeAppsDistinctCountCreatedAtDesc = 'TRADE_APPS_DISTINCT_COUNT_CREATED_AT_DESC',
  TradeAppsDistinctCountUpdatedAtAsc = 'TRADE_APPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  TradeAppsDistinctCountUpdatedAtDesc = 'TRADE_APPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  TradeAppsMinIdAsc = 'TRADE_APPS_MIN_ID_ASC',
  TradeAppsMinIdDesc = 'TRADE_APPS_MIN_ID_DESC',
  TradeAppsMinLeadIdAsc = 'TRADE_APPS_MIN_LEAD_ID_ASC',
  TradeAppsMinLeadIdDesc = 'TRADE_APPS_MIN_LEAD_ID_DESC',
  TradeAppsMinYearAsc = 'TRADE_APPS_MIN_YEAR_ASC',
  TradeAppsMinYearDesc = 'TRADE_APPS_MIN_YEAR_DESC',
  TradeAppsMinMakeAsc = 'TRADE_APPS_MIN_MAKE_ASC',
  TradeAppsMinMakeDesc = 'TRADE_APPS_MIN_MAKE_DESC',
  TradeAppsMinModelAsc = 'TRADE_APPS_MIN_MODEL_ASC',
  TradeAppsMinModelDesc = 'TRADE_APPS_MIN_MODEL_DESC',
  TradeAppsMinTrimAsc = 'TRADE_APPS_MIN_TRIM_ASC',
  TradeAppsMinTrimDesc = 'TRADE_APPS_MIN_TRIM_DESC',
  TradeAppsMinMileageAsc = 'TRADE_APPS_MIN_MILEAGE_ASC',
  TradeAppsMinMileageDesc = 'TRADE_APPS_MIN_MILEAGE_DESC',
  TradeAppsMinConditionAsc = 'TRADE_APPS_MIN_CONDITION_ASC',
  TradeAppsMinConditionDesc = 'TRADE_APPS_MIN_CONDITION_DESC',
  TradeAppsMinTradeInValueAsc = 'TRADE_APPS_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsMinTradeInValueDesc = 'TRADE_APPS_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsMinMaxTradeInValueAsc = 'TRADE_APPS_MIN_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsMinMaxTradeInValueDesc = 'TRADE_APPS_MIN_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsMinMinTradeInValueAsc = 'TRADE_APPS_MIN_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsMinMinTradeInValueDesc = 'TRADE_APPS_MIN_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsMinCreatedAtAsc = 'TRADE_APPS_MIN_CREATED_AT_ASC',
  TradeAppsMinCreatedAtDesc = 'TRADE_APPS_MIN_CREATED_AT_DESC',
  TradeAppsMinUpdatedAtAsc = 'TRADE_APPS_MIN_UPDATED_AT_ASC',
  TradeAppsMinUpdatedAtDesc = 'TRADE_APPS_MIN_UPDATED_AT_DESC',
  TradeAppsMaxIdAsc = 'TRADE_APPS_MAX_ID_ASC',
  TradeAppsMaxIdDesc = 'TRADE_APPS_MAX_ID_DESC',
  TradeAppsMaxLeadIdAsc = 'TRADE_APPS_MAX_LEAD_ID_ASC',
  TradeAppsMaxLeadIdDesc = 'TRADE_APPS_MAX_LEAD_ID_DESC',
  TradeAppsMaxYearAsc = 'TRADE_APPS_MAX_YEAR_ASC',
  TradeAppsMaxYearDesc = 'TRADE_APPS_MAX_YEAR_DESC',
  TradeAppsMaxMakeAsc = 'TRADE_APPS_MAX_MAKE_ASC',
  TradeAppsMaxMakeDesc = 'TRADE_APPS_MAX_MAKE_DESC',
  TradeAppsMaxModelAsc = 'TRADE_APPS_MAX_MODEL_ASC',
  TradeAppsMaxModelDesc = 'TRADE_APPS_MAX_MODEL_DESC',
  TradeAppsMaxTrimAsc = 'TRADE_APPS_MAX_TRIM_ASC',
  TradeAppsMaxTrimDesc = 'TRADE_APPS_MAX_TRIM_DESC',
  TradeAppsMaxMileageAsc = 'TRADE_APPS_MAX_MILEAGE_ASC',
  TradeAppsMaxMileageDesc = 'TRADE_APPS_MAX_MILEAGE_DESC',
  TradeAppsMaxConditionAsc = 'TRADE_APPS_MAX_CONDITION_ASC',
  TradeAppsMaxConditionDesc = 'TRADE_APPS_MAX_CONDITION_DESC',
  TradeAppsMaxTradeInValueAsc = 'TRADE_APPS_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsMaxTradeInValueDesc = 'TRADE_APPS_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsMaxMaxTradeInValueAsc = 'TRADE_APPS_MAX_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsMaxMaxTradeInValueDesc = 'TRADE_APPS_MAX_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsMaxMinTradeInValueAsc = 'TRADE_APPS_MAX_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsMaxMinTradeInValueDesc = 'TRADE_APPS_MAX_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsMaxCreatedAtAsc = 'TRADE_APPS_MAX_CREATED_AT_ASC',
  TradeAppsMaxCreatedAtDesc = 'TRADE_APPS_MAX_CREATED_AT_DESC',
  TradeAppsMaxUpdatedAtAsc = 'TRADE_APPS_MAX_UPDATED_AT_ASC',
  TradeAppsMaxUpdatedAtDesc = 'TRADE_APPS_MAX_UPDATED_AT_DESC',
  TradeAppsAverageIdAsc = 'TRADE_APPS_AVERAGE_ID_ASC',
  TradeAppsAverageIdDesc = 'TRADE_APPS_AVERAGE_ID_DESC',
  TradeAppsAverageLeadIdAsc = 'TRADE_APPS_AVERAGE_LEAD_ID_ASC',
  TradeAppsAverageLeadIdDesc = 'TRADE_APPS_AVERAGE_LEAD_ID_DESC',
  TradeAppsAverageYearAsc = 'TRADE_APPS_AVERAGE_YEAR_ASC',
  TradeAppsAverageYearDesc = 'TRADE_APPS_AVERAGE_YEAR_DESC',
  TradeAppsAverageMakeAsc = 'TRADE_APPS_AVERAGE_MAKE_ASC',
  TradeAppsAverageMakeDesc = 'TRADE_APPS_AVERAGE_MAKE_DESC',
  TradeAppsAverageModelAsc = 'TRADE_APPS_AVERAGE_MODEL_ASC',
  TradeAppsAverageModelDesc = 'TRADE_APPS_AVERAGE_MODEL_DESC',
  TradeAppsAverageTrimAsc = 'TRADE_APPS_AVERAGE_TRIM_ASC',
  TradeAppsAverageTrimDesc = 'TRADE_APPS_AVERAGE_TRIM_DESC',
  TradeAppsAverageMileageAsc = 'TRADE_APPS_AVERAGE_MILEAGE_ASC',
  TradeAppsAverageMileageDesc = 'TRADE_APPS_AVERAGE_MILEAGE_DESC',
  TradeAppsAverageConditionAsc = 'TRADE_APPS_AVERAGE_CONDITION_ASC',
  TradeAppsAverageConditionDesc = 'TRADE_APPS_AVERAGE_CONDITION_DESC',
  TradeAppsAverageTradeInValueAsc = 'TRADE_APPS_AVERAGE_TRADE_IN_VALUE_ASC',
  TradeAppsAverageTradeInValueDesc = 'TRADE_APPS_AVERAGE_TRADE_IN_VALUE_DESC',
  TradeAppsAverageMaxTradeInValueAsc = 'TRADE_APPS_AVERAGE_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsAverageMaxTradeInValueDesc = 'TRADE_APPS_AVERAGE_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsAverageMinTradeInValueAsc = 'TRADE_APPS_AVERAGE_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsAverageMinTradeInValueDesc = 'TRADE_APPS_AVERAGE_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsAverageCreatedAtAsc = 'TRADE_APPS_AVERAGE_CREATED_AT_ASC',
  TradeAppsAverageCreatedAtDesc = 'TRADE_APPS_AVERAGE_CREATED_AT_DESC',
  TradeAppsAverageUpdatedAtAsc = 'TRADE_APPS_AVERAGE_UPDATED_AT_ASC',
  TradeAppsAverageUpdatedAtDesc = 'TRADE_APPS_AVERAGE_UPDATED_AT_DESC',
  TradeAppsStddevSampleIdAsc = 'TRADE_APPS_STDDEV_SAMPLE_ID_ASC',
  TradeAppsStddevSampleIdDesc = 'TRADE_APPS_STDDEV_SAMPLE_ID_DESC',
  TradeAppsStddevSampleLeadIdAsc = 'TRADE_APPS_STDDEV_SAMPLE_LEAD_ID_ASC',
  TradeAppsStddevSampleLeadIdDesc = 'TRADE_APPS_STDDEV_SAMPLE_LEAD_ID_DESC',
  TradeAppsStddevSampleYearAsc = 'TRADE_APPS_STDDEV_SAMPLE_YEAR_ASC',
  TradeAppsStddevSampleYearDesc = 'TRADE_APPS_STDDEV_SAMPLE_YEAR_DESC',
  TradeAppsStddevSampleMakeAsc = 'TRADE_APPS_STDDEV_SAMPLE_MAKE_ASC',
  TradeAppsStddevSampleMakeDesc = 'TRADE_APPS_STDDEV_SAMPLE_MAKE_DESC',
  TradeAppsStddevSampleModelAsc = 'TRADE_APPS_STDDEV_SAMPLE_MODEL_ASC',
  TradeAppsStddevSampleModelDesc = 'TRADE_APPS_STDDEV_SAMPLE_MODEL_DESC',
  TradeAppsStddevSampleTrimAsc = 'TRADE_APPS_STDDEV_SAMPLE_TRIM_ASC',
  TradeAppsStddevSampleTrimDesc = 'TRADE_APPS_STDDEV_SAMPLE_TRIM_DESC',
  TradeAppsStddevSampleMileageAsc = 'TRADE_APPS_STDDEV_SAMPLE_MILEAGE_ASC',
  TradeAppsStddevSampleMileageDesc = 'TRADE_APPS_STDDEV_SAMPLE_MILEAGE_DESC',
  TradeAppsStddevSampleConditionAsc = 'TRADE_APPS_STDDEV_SAMPLE_CONDITION_ASC',
  TradeAppsStddevSampleConditionDesc = 'TRADE_APPS_STDDEV_SAMPLE_CONDITION_DESC',
  TradeAppsStddevSampleTradeInValueAsc = 'TRADE_APPS_STDDEV_SAMPLE_TRADE_IN_VALUE_ASC',
  TradeAppsStddevSampleTradeInValueDesc = 'TRADE_APPS_STDDEV_SAMPLE_TRADE_IN_VALUE_DESC',
  TradeAppsStddevSampleMaxTradeInValueAsc = 'TRADE_APPS_STDDEV_SAMPLE_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsStddevSampleMaxTradeInValueDesc = 'TRADE_APPS_STDDEV_SAMPLE_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsStddevSampleMinTradeInValueAsc = 'TRADE_APPS_STDDEV_SAMPLE_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsStddevSampleMinTradeInValueDesc = 'TRADE_APPS_STDDEV_SAMPLE_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsStddevSampleCreatedAtAsc = 'TRADE_APPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  TradeAppsStddevSampleCreatedAtDesc = 'TRADE_APPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  TradeAppsStddevSampleUpdatedAtAsc = 'TRADE_APPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TradeAppsStddevSampleUpdatedAtDesc = 'TRADE_APPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TradeAppsStddevPopulationIdAsc = 'TRADE_APPS_STDDEV_POPULATION_ID_ASC',
  TradeAppsStddevPopulationIdDesc = 'TRADE_APPS_STDDEV_POPULATION_ID_DESC',
  TradeAppsStddevPopulationLeadIdAsc = 'TRADE_APPS_STDDEV_POPULATION_LEAD_ID_ASC',
  TradeAppsStddevPopulationLeadIdDesc = 'TRADE_APPS_STDDEV_POPULATION_LEAD_ID_DESC',
  TradeAppsStddevPopulationYearAsc = 'TRADE_APPS_STDDEV_POPULATION_YEAR_ASC',
  TradeAppsStddevPopulationYearDesc = 'TRADE_APPS_STDDEV_POPULATION_YEAR_DESC',
  TradeAppsStddevPopulationMakeAsc = 'TRADE_APPS_STDDEV_POPULATION_MAKE_ASC',
  TradeAppsStddevPopulationMakeDesc = 'TRADE_APPS_STDDEV_POPULATION_MAKE_DESC',
  TradeAppsStddevPopulationModelAsc = 'TRADE_APPS_STDDEV_POPULATION_MODEL_ASC',
  TradeAppsStddevPopulationModelDesc = 'TRADE_APPS_STDDEV_POPULATION_MODEL_DESC',
  TradeAppsStddevPopulationTrimAsc = 'TRADE_APPS_STDDEV_POPULATION_TRIM_ASC',
  TradeAppsStddevPopulationTrimDesc = 'TRADE_APPS_STDDEV_POPULATION_TRIM_DESC',
  TradeAppsStddevPopulationMileageAsc = 'TRADE_APPS_STDDEV_POPULATION_MILEAGE_ASC',
  TradeAppsStddevPopulationMileageDesc = 'TRADE_APPS_STDDEV_POPULATION_MILEAGE_DESC',
  TradeAppsStddevPopulationConditionAsc = 'TRADE_APPS_STDDEV_POPULATION_CONDITION_ASC',
  TradeAppsStddevPopulationConditionDesc = 'TRADE_APPS_STDDEV_POPULATION_CONDITION_DESC',
  TradeAppsStddevPopulationTradeInValueAsc = 'TRADE_APPS_STDDEV_POPULATION_TRADE_IN_VALUE_ASC',
  TradeAppsStddevPopulationTradeInValueDesc = 'TRADE_APPS_STDDEV_POPULATION_TRADE_IN_VALUE_DESC',
  TradeAppsStddevPopulationMaxTradeInValueAsc = 'TRADE_APPS_STDDEV_POPULATION_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsStddevPopulationMaxTradeInValueDesc = 'TRADE_APPS_STDDEV_POPULATION_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsStddevPopulationMinTradeInValueAsc = 'TRADE_APPS_STDDEV_POPULATION_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsStddevPopulationMinTradeInValueDesc = 'TRADE_APPS_STDDEV_POPULATION_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsStddevPopulationCreatedAtAsc = 'TRADE_APPS_STDDEV_POPULATION_CREATED_AT_ASC',
  TradeAppsStddevPopulationCreatedAtDesc = 'TRADE_APPS_STDDEV_POPULATION_CREATED_AT_DESC',
  TradeAppsStddevPopulationUpdatedAtAsc = 'TRADE_APPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  TradeAppsStddevPopulationUpdatedAtDesc = 'TRADE_APPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  TradeAppsVarianceSampleIdAsc = 'TRADE_APPS_VARIANCE_SAMPLE_ID_ASC',
  TradeAppsVarianceSampleIdDesc = 'TRADE_APPS_VARIANCE_SAMPLE_ID_DESC',
  TradeAppsVarianceSampleLeadIdAsc = 'TRADE_APPS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  TradeAppsVarianceSampleLeadIdDesc = 'TRADE_APPS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  TradeAppsVarianceSampleYearAsc = 'TRADE_APPS_VARIANCE_SAMPLE_YEAR_ASC',
  TradeAppsVarianceSampleYearDesc = 'TRADE_APPS_VARIANCE_SAMPLE_YEAR_DESC',
  TradeAppsVarianceSampleMakeAsc = 'TRADE_APPS_VARIANCE_SAMPLE_MAKE_ASC',
  TradeAppsVarianceSampleMakeDesc = 'TRADE_APPS_VARIANCE_SAMPLE_MAKE_DESC',
  TradeAppsVarianceSampleModelAsc = 'TRADE_APPS_VARIANCE_SAMPLE_MODEL_ASC',
  TradeAppsVarianceSampleModelDesc = 'TRADE_APPS_VARIANCE_SAMPLE_MODEL_DESC',
  TradeAppsVarianceSampleTrimAsc = 'TRADE_APPS_VARIANCE_SAMPLE_TRIM_ASC',
  TradeAppsVarianceSampleTrimDesc = 'TRADE_APPS_VARIANCE_SAMPLE_TRIM_DESC',
  TradeAppsVarianceSampleMileageAsc = 'TRADE_APPS_VARIANCE_SAMPLE_MILEAGE_ASC',
  TradeAppsVarianceSampleMileageDesc = 'TRADE_APPS_VARIANCE_SAMPLE_MILEAGE_DESC',
  TradeAppsVarianceSampleConditionAsc = 'TRADE_APPS_VARIANCE_SAMPLE_CONDITION_ASC',
  TradeAppsVarianceSampleConditionDesc = 'TRADE_APPS_VARIANCE_SAMPLE_CONDITION_DESC',
  TradeAppsVarianceSampleTradeInValueAsc = 'TRADE_APPS_VARIANCE_SAMPLE_TRADE_IN_VALUE_ASC',
  TradeAppsVarianceSampleTradeInValueDesc = 'TRADE_APPS_VARIANCE_SAMPLE_TRADE_IN_VALUE_DESC',
  TradeAppsVarianceSampleMaxTradeInValueAsc = 'TRADE_APPS_VARIANCE_SAMPLE_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsVarianceSampleMaxTradeInValueDesc = 'TRADE_APPS_VARIANCE_SAMPLE_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsVarianceSampleMinTradeInValueAsc = 'TRADE_APPS_VARIANCE_SAMPLE_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsVarianceSampleMinTradeInValueDesc = 'TRADE_APPS_VARIANCE_SAMPLE_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsVarianceSampleCreatedAtAsc = 'TRADE_APPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TradeAppsVarianceSampleCreatedAtDesc = 'TRADE_APPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TradeAppsVarianceSampleUpdatedAtAsc = 'TRADE_APPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TradeAppsVarianceSampleUpdatedAtDesc = 'TRADE_APPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TradeAppsVariancePopulationIdAsc = 'TRADE_APPS_VARIANCE_POPULATION_ID_ASC',
  TradeAppsVariancePopulationIdDesc = 'TRADE_APPS_VARIANCE_POPULATION_ID_DESC',
  TradeAppsVariancePopulationLeadIdAsc = 'TRADE_APPS_VARIANCE_POPULATION_LEAD_ID_ASC',
  TradeAppsVariancePopulationLeadIdDesc = 'TRADE_APPS_VARIANCE_POPULATION_LEAD_ID_DESC',
  TradeAppsVariancePopulationYearAsc = 'TRADE_APPS_VARIANCE_POPULATION_YEAR_ASC',
  TradeAppsVariancePopulationYearDesc = 'TRADE_APPS_VARIANCE_POPULATION_YEAR_DESC',
  TradeAppsVariancePopulationMakeAsc = 'TRADE_APPS_VARIANCE_POPULATION_MAKE_ASC',
  TradeAppsVariancePopulationMakeDesc = 'TRADE_APPS_VARIANCE_POPULATION_MAKE_DESC',
  TradeAppsVariancePopulationModelAsc = 'TRADE_APPS_VARIANCE_POPULATION_MODEL_ASC',
  TradeAppsVariancePopulationModelDesc = 'TRADE_APPS_VARIANCE_POPULATION_MODEL_DESC',
  TradeAppsVariancePopulationTrimAsc = 'TRADE_APPS_VARIANCE_POPULATION_TRIM_ASC',
  TradeAppsVariancePopulationTrimDesc = 'TRADE_APPS_VARIANCE_POPULATION_TRIM_DESC',
  TradeAppsVariancePopulationMileageAsc = 'TRADE_APPS_VARIANCE_POPULATION_MILEAGE_ASC',
  TradeAppsVariancePopulationMileageDesc = 'TRADE_APPS_VARIANCE_POPULATION_MILEAGE_DESC',
  TradeAppsVariancePopulationConditionAsc = 'TRADE_APPS_VARIANCE_POPULATION_CONDITION_ASC',
  TradeAppsVariancePopulationConditionDesc = 'TRADE_APPS_VARIANCE_POPULATION_CONDITION_DESC',
  TradeAppsVariancePopulationTradeInValueAsc = 'TRADE_APPS_VARIANCE_POPULATION_TRADE_IN_VALUE_ASC',
  TradeAppsVariancePopulationTradeInValueDesc = 'TRADE_APPS_VARIANCE_POPULATION_TRADE_IN_VALUE_DESC',
  TradeAppsVariancePopulationMaxTradeInValueAsc = 'TRADE_APPS_VARIANCE_POPULATION_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsVariancePopulationMaxTradeInValueDesc = 'TRADE_APPS_VARIANCE_POPULATION_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsVariancePopulationMinTradeInValueAsc = 'TRADE_APPS_VARIANCE_POPULATION_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsVariancePopulationMinTradeInValueDesc = 'TRADE_APPS_VARIANCE_POPULATION_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsVariancePopulationCreatedAtAsc = 'TRADE_APPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  TradeAppsVariancePopulationCreatedAtDesc = 'TRADE_APPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  TradeAppsVariancePopulationUpdatedAtAsc = 'TRADE_APPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TradeAppsVariancePopulationUpdatedAtDesc = 'TRADE_APPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LeadJourneysCountAsc = 'LEAD_JOURNEYS_COUNT_ASC',
  LeadJourneysCountDesc = 'LEAD_JOURNEYS_COUNT_DESC',
  LeadJourneysSumLeadIdAsc = 'LEAD_JOURNEYS_SUM_LEAD_ID_ASC',
  LeadJourneysSumLeadIdDesc = 'LEAD_JOURNEYS_SUM_LEAD_ID_DESC',
  LeadJourneysSumStageAsc = 'LEAD_JOURNEYS_SUM_STAGE_ASC',
  LeadJourneysSumStageDesc = 'LEAD_JOURNEYS_SUM_STAGE_DESC',
  LeadJourneysSumProductIdAsc = 'LEAD_JOURNEYS_SUM_PRODUCT_ID_ASC',
  LeadJourneysSumProductIdDesc = 'LEAD_JOURNEYS_SUM_PRODUCT_ID_DESC',
  LeadJourneysSumCreatedAtAsc = 'LEAD_JOURNEYS_SUM_CREATED_AT_ASC',
  LeadJourneysSumCreatedAtDesc = 'LEAD_JOURNEYS_SUM_CREATED_AT_DESC',
  LeadJourneysSumUpdatedAtAsc = 'LEAD_JOURNEYS_SUM_UPDATED_AT_ASC',
  LeadJourneysSumUpdatedAtDesc = 'LEAD_JOURNEYS_SUM_UPDATED_AT_DESC',
  LeadJourneysDistinctCountLeadIdAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_LEAD_ID_ASC',
  LeadJourneysDistinctCountLeadIdDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_LEAD_ID_DESC',
  LeadJourneysDistinctCountStageAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_STAGE_ASC',
  LeadJourneysDistinctCountStageDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_STAGE_DESC',
  LeadJourneysDistinctCountProductIdAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  LeadJourneysDistinctCountProductIdDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  LeadJourneysDistinctCountCreatedAtAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_CREATED_AT_ASC',
  LeadJourneysDistinctCountCreatedAtDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_CREATED_AT_DESC',
  LeadJourneysDistinctCountUpdatedAtAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LeadJourneysDistinctCountUpdatedAtDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LeadJourneysMinLeadIdAsc = 'LEAD_JOURNEYS_MIN_LEAD_ID_ASC',
  LeadJourneysMinLeadIdDesc = 'LEAD_JOURNEYS_MIN_LEAD_ID_DESC',
  LeadJourneysMinStageAsc = 'LEAD_JOURNEYS_MIN_STAGE_ASC',
  LeadJourneysMinStageDesc = 'LEAD_JOURNEYS_MIN_STAGE_DESC',
  LeadJourneysMinProductIdAsc = 'LEAD_JOURNEYS_MIN_PRODUCT_ID_ASC',
  LeadJourneysMinProductIdDesc = 'LEAD_JOURNEYS_MIN_PRODUCT_ID_DESC',
  LeadJourneysMinCreatedAtAsc = 'LEAD_JOURNEYS_MIN_CREATED_AT_ASC',
  LeadJourneysMinCreatedAtDesc = 'LEAD_JOURNEYS_MIN_CREATED_AT_DESC',
  LeadJourneysMinUpdatedAtAsc = 'LEAD_JOURNEYS_MIN_UPDATED_AT_ASC',
  LeadJourneysMinUpdatedAtDesc = 'LEAD_JOURNEYS_MIN_UPDATED_AT_DESC',
  LeadJourneysMaxLeadIdAsc = 'LEAD_JOURNEYS_MAX_LEAD_ID_ASC',
  LeadJourneysMaxLeadIdDesc = 'LEAD_JOURNEYS_MAX_LEAD_ID_DESC',
  LeadJourneysMaxStageAsc = 'LEAD_JOURNEYS_MAX_STAGE_ASC',
  LeadJourneysMaxStageDesc = 'LEAD_JOURNEYS_MAX_STAGE_DESC',
  LeadJourneysMaxProductIdAsc = 'LEAD_JOURNEYS_MAX_PRODUCT_ID_ASC',
  LeadJourneysMaxProductIdDesc = 'LEAD_JOURNEYS_MAX_PRODUCT_ID_DESC',
  LeadJourneysMaxCreatedAtAsc = 'LEAD_JOURNEYS_MAX_CREATED_AT_ASC',
  LeadJourneysMaxCreatedAtDesc = 'LEAD_JOURNEYS_MAX_CREATED_AT_DESC',
  LeadJourneysMaxUpdatedAtAsc = 'LEAD_JOURNEYS_MAX_UPDATED_AT_ASC',
  LeadJourneysMaxUpdatedAtDesc = 'LEAD_JOURNEYS_MAX_UPDATED_AT_DESC',
  LeadJourneysAverageLeadIdAsc = 'LEAD_JOURNEYS_AVERAGE_LEAD_ID_ASC',
  LeadJourneysAverageLeadIdDesc = 'LEAD_JOURNEYS_AVERAGE_LEAD_ID_DESC',
  LeadJourneysAverageStageAsc = 'LEAD_JOURNEYS_AVERAGE_STAGE_ASC',
  LeadJourneysAverageStageDesc = 'LEAD_JOURNEYS_AVERAGE_STAGE_DESC',
  LeadJourneysAverageProductIdAsc = 'LEAD_JOURNEYS_AVERAGE_PRODUCT_ID_ASC',
  LeadJourneysAverageProductIdDesc = 'LEAD_JOURNEYS_AVERAGE_PRODUCT_ID_DESC',
  LeadJourneysAverageCreatedAtAsc = 'LEAD_JOURNEYS_AVERAGE_CREATED_AT_ASC',
  LeadJourneysAverageCreatedAtDesc = 'LEAD_JOURNEYS_AVERAGE_CREATED_AT_DESC',
  LeadJourneysAverageUpdatedAtAsc = 'LEAD_JOURNEYS_AVERAGE_UPDATED_AT_ASC',
  LeadJourneysAverageUpdatedAtDesc = 'LEAD_JOURNEYS_AVERAGE_UPDATED_AT_DESC',
  LeadJourneysStddevSampleLeadIdAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_LEAD_ID_ASC',
  LeadJourneysStddevSampleLeadIdDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_LEAD_ID_DESC',
  LeadJourneysStddevSampleStageAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_STAGE_ASC',
  LeadJourneysStddevSampleStageDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_STAGE_DESC',
  LeadJourneysStddevSampleProductIdAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  LeadJourneysStddevSampleProductIdDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  LeadJourneysStddevSampleCreatedAtAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LeadJourneysStddevSampleCreatedAtDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LeadJourneysStddevSampleUpdatedAtAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LeadJourneysStddevSampleUpdatedAtDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LeadJourneysStddevPopulationLeadIdAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_LEAD_ID_ASC',
  LeadJourneysStddevPopulationLeadIdDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_LEAD_ID_DESC',
  LeadJourneysStddevPopulationStageAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_STAGE_ASC',
  LeadJourneysStddevPopulationStageDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_STAGE_DESC',
  LeadJourneysStddevPopulationProductIdAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  LeadJourneysStddevPopulationProductIdDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  LeadJourneysStddevPopulationCreatedAtAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_CREATED_AT_ASC',
  LeadJourneysStddevPopulationCreatedAtDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_CREATED_AT_DESC',
  LeadJourneysStddevPopulationUpdatedAtAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LeadJourneysStddevPopulationUpdatedAtDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LeadJourneysVarianceSampleLeadIdAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LeadJourneysVarianceSampleLeadIdDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LeadJourneysVarianceSampleStageAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_STAGE_ASC',
  LeadJourneysVarianceSampleStageDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_STAGE_DESC',
  LeadJourneysVarianceSampleProductIdAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  LeadJourneysVarianceSampleProductIdDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  LeadJourneysVarianceSampleCreatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LeadJourneysVarianceSampleCreatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LeadJourneysVarianceSampleUpdatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LeadJourneysVarianceSampleUpdatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LeadJourneysVariancePopulationLeadIdAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_LEAD_ID_ASC',
  LeadJourneysVariancePopulationLeadIdDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_LEAD_ID_DESC',
  LeadJourneysVariancePopulationStageAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_STAGE_ASC',
  LeadJourneysVariancePopulationStageDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_STAGE_DESC',
  LeadJourneysVariancePopulationProductIdAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  LeadJourneysVariancePopulationProductIdDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  LeadJourneysVariancePopulationCreatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LeadJourneysVariancePopulationCreatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LeadJourneysVariancePopulationUpdatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LeadJourneysVariancePopulationUpdatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderAppsCountAsc = 'LENDER_APPS_COUNT_ASC',
  LenderAppsCountDesc = 'LENDER_APPS_COUNT_DESC',
  LenderAppsSumIdAsc = 'LENDER_APPS_SUM_ID_ASC',
  LenderAppsSumIdDesc = 'LENDER_APPS_SUM_ID_DESC',
  LenderAppsSumLeadIdAsc = 'LENDER_APPS_SUM_LEAD_ID_ASC',
  LenderAppsSumLeadIdDesc = 'LENDER_APPS_SUM_LEAD_ID_DESC',
  LenderAppsSumInovatecApplicationNumberAsc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsSumInovatecApplicationNumberDesc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsSumInovatecApplicationIdAsc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsSumInovatecApplicationIdDesc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsSumYearlyIncomeAsc = 'LENDER_APPS_SUM_YEARLY_INCOME_ASC',
  LenderAppsSumYearlyIncomeDesc = 'LENDER_APPS_SUM_YEARLY_INCOME_DESC',
  LenderAppsSumEmploymentStatusAsc = 'LENDER_APPS_SUM_EMPLOYMENT_STATUS_ASC',
  LenderAppsSumEmploymentStatusDesc = 'LENDER_APPS_SUM_EMPLOYMENT_STATUS_DESC',
  LenderAppsSumMonthlyHomePaymentAsc = 'LENDER_APPS_SUM_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsSumMonthlyHomePaymentDesc = 'LENDER_APPS_SUM_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsSumPreferredOfferIdAsc = 'LENDER_APPS_SUM_PREFERRED_OFFER_ID_ASC',
  LenderAppsSumPreferredOfferIdDesc = 'LENDER_APPS_SUM_PREFERRED_OFFER_ID_DESC',
  LenderAppsSumOffersAsc = 'LENDER_APPS_SUM_OFFERS_ASC',
  LenderAppsSumOffersDesc = 'LENDER_APPS_SUM_OFFERS_DESC',
  LenderAppsSumCreatedAtAsc = 'LENDER_APPS_SUM_CREATED_AT_ASC',
  LenderAppsSumCreatedAtDesc = 'LENDER_APPS_SUM_CREATED_AT_DESC',
  LenderAppsSumUpdatedAtAsc = 'LENDER_APPS_SUM_UPDATED_AT_ASC',
  LenderAppsSumUpdatedAtDesc = 'LENDER_APPS_SUM_UPDATED_AT_DESC',
  LenderAppsSumVehicleListingIdAsc = 'LENDER_APPS_SUM_VEHICLE_LISTING_ID_ASC',
  LenderAppsSumVehicleListingIdDesc = 'LENDER_APPS_SUM_VEHICLE_LISTING_ID_DESC',
  LenderAppsSumMaritalStatusAsc = 'LENDER_APPS_SUM_MARITAL_STATUS_ASC',
  LenderAppsSumMaritalStatusDesc = 'LENDER_APPS_SUM_MARITAL_STATUS_DESC',
  LenderAppsDistinctCountIdAsc = 'LENDER_APPS_DISTINCT_COUNT_ID_ASC',
  LenderAppsDistinctCountIdDesc = 'LENDER_APPS_DISTINCT_COUNT_ID_DESC',
  LenderAppsDistinctCountLeadIdAsc = 'LENDER_APPS_DISTINCT_COUNT_LEAD_ID_ASC',
  LenderAppsDistinctCountLeadIdDesc = 'LENDER_APPS_DISTINCT_COUNT_LEAD_ID_DESC',
  LenderAppsDistinctCountInovatecApplicationNumberAsc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsDistinctCountInovatecApplicationNumberDesc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsDistinctCountInovatecApplicationIdAsc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsDistinctCountInovatecApplicationIdDesc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsDistinctCountYearlyIncomeAsc = 'LENDER_APPS_DISTINCT_COUNT_YEARLY_INCOME_ASC',
  LenderAppsDistinctCountYearlyIncomeDesc = 'LENDER_APPS_DISTINCT_COUNT_YEARLY_INCOME_DESC',
  LenderAppsDistinctCountEmploymentStatusAsc = 'LENDER_APPS_DISTINCT_COUNT_EMPLOYMENT_STATUS_ASC',
  LenderAppsDistinctCountEmploymentStatusDesc = 'LENDER_APPS_DISTINCT_COUNT_EMPLOYMENT_STATUS_DESC',
  LenderAppsDistinctCountMonthlyHomePaymentAsc = 'LENDER_APPS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsDistinctCountMonthlyHomePaymentDesc = 'LENDER_APPS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsDistinctCountPreferredOfferIdAsc = 'LENDER_APPS_DISTINCT_COUNT_PREFERRED_OFFER_ID_ASC',
  LenderAppsDistinctCountPreferredOfferIdDesc = 'LENDER_APPS_DISTINCT_COUNT_PREFERRED_OFFER_ID_DESC',
  LenderAppsDistinctCountOffersAsc = 'LENDER_APPS_DISTINCT_COUNT_OFFERS_ASC',
  LenderAppsDistinctCountOffersDesc = 'LENDER_APPS_DISTINCT_COUNT_OFFERS_DESC',
  LenderAppsDistinctCountCreatedAtAsc = 'LENDER_APPS_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderAppsDistinctCountCreatedAtDesc = 'LENDER_APPS_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderAppsDistinctCountUpdatedAtAsc = 'LENDER_APPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderAppsDistinctCountUpdatedAtDesc = 'LENDER_APPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderAppsDistinctCountVehicleListingIdAsc = 'LENDER_APPS_DISTINCT_COUNT_VEHICLE_LISTING_ID_ASC',
  LenderAppsDistinctCountVehicleListingIdDesc = 'LENDER_APPS_DISTINCT_COUNT_VEHICLE_LISTING_ID_DESC',
  LenderAppsDistinctCountMaritalStatusAsc = 'LENDER_APPS_DISTINCT_COUNT_MARITAL_STATUS_ASC',
  LenderAppsDistinctCountMaritalStatusDesc = 'LENDER_APPS_DISTINCT_COUNT_MARITAL_STATUS_DESC',
  LenderAppsMinIdAsc = 'LENDER_APPS_MIN_ID_ASC',
  LenderAppsMinIdDesc = 'LENDER_APPS_MIN_ID_DESC',
  LenderAppsMinLeadIdAsc = 'LENDER_APPS_MIN_LEAD_ID_ASC',
  LenderAppsMinLeadIdDesc = 'LENDER_APPS_MIN_LEAD_ID_DESC',
  LenderAppsMinInovatecApplicationNumberAsc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsMinInovatecApplicationNumberDesc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsMinInovatecApplicationIdAsc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsMinInovatecApplicationIdDesc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsMinYearlyIncomeAsc = 'LENDER_APPS_MIN_YEARLY_INCOME_ASC',
  LenderAppsMinYearlyIncomeDesc = 'LENDER_APPS_MIN_YEARLY_INCOME_DESC',
  LenderAppsMinEmploymentStatusAsc = 'LENDER_APPS_MIN_EMPLOYMENT_STATUS_ASC',
  LenderAppsMinEmploymentStatusDesc = 'LENDER_APPS_MIN_EMPLOYMENT_STATUS_DESC',
  LenderAppsMinMonthlyHomePaymentAsc = 'LENDER_APPS_MIN_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsMinMonthlyHomePaymentDesc = 'LENDER_APPS_MIN_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsMinPreferredOfferIdAsc = 'LENDER_APPS_MIN_PREFERRED_OFFER_ID_ASC',
  LenderAppsMinPreferredOfferIdDesc = 'LENDER_APPS_MIN_PREFERRED_OFFER_ID_DESC',
  LenderAppsMinOffersAsc = 'LENDER_APPS_MIN_OFFERS_ASC',
  LenderAppsMinOffersDesc = 'LENDER_APPS_MIN_OFFERS_DESC',
  LenderAppsMinCreatedAtAsc = 'LENDER_APPS_MIN_CREATED_AT_ASC',
  LenderAppsMinCreatedAtDesc = 'LENDER_APPS_MIN_CREATED_AT_DESC',
  LenderAppsMinUpdatedAtAsc = 'LENDER_APPS_MIN_UPDATED_AT_ASC',
  LenderAppsMinUpdatedAtDesc = 'LENDER_APPS_MIN_UPDATED_AT_DESC',
  LenderAppsMinVehicleListingIdAsc = 'LENDER_APPS_MIN_VEHICLE_LISTING_ID_ASC',
  LenderAppsMinVehicleListingIdDesc = 'LENDER_APPS_MIN_VEHICLE_LISTING_ID_DESC',
  LenderAppsMinMaritalStatusAsc = 'LENDER_APPS_MIN_MARITAL_STATUS_ASC',
  LenderAppsMinMaritalStatusDesc = 'LENDER_APPS_MIN_MARITAL_STATUS_DESC',
  LenderAppsMaxIdAsc = 'LENDER_APPS_MAX_ID_ASC',
  LenderAppsMaxIdDesc = 'LENDER_APPS_MAX_ID_DESC',
  LenderAppsMaxLeadIdAsc = 'LENDER_APPS_MAX_LEAD_ID_ASC',
  LenderAppsMaxLeadIdDesc = 'LENDER_APPS_MAX_LEAD_ID_DESC',
  LenderAppsMaxInovatecApplicationNumberAsc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsMaxInovatecApplicationNumberDesc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsMaxInovatecApplicationIdAsc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsMaxInovatecApplicationIdDesc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsMaxYearlyIncomeAsc = 'LENDER_APPS_MAX_YEARLY_INCOME_ASC',
  LenderAppsMaxYearlyIncomeDesc = 'LENDER_APPS_MAX_YEARLY_INCOME_DESC',
  LenderAppsMaxEmploymentStatusAsc = 'LENDER_APPS_MAX_EMPLOYMENT_STATUS_ASC',
  LenderAppsMaxEmploymentStatusDesc = 'LENDER_APPS_MAX_EMPLOYMENT_STATUS_DESC',
  LenderAppsMaxMonthlyHomePaymentAsc = 'LENDER_APPS_MAX_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsMaxMonthlyHomePaymentDesc = 'LENDER_APPS_MAX_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsMaxPreferredOfferIdAsc = 'LENDER_APPS_MAX_PREFERRED_OFFER_ID_ASC',
  LenderAppsMaxPreferredOfferIdDesc = 'LENDER_APPS_MAX_PREFERRED_OFFER_ID_DESC',
  LenderAppsMaxOffersAsc = 'LENDER_APPS_MAX_OFFERS_ASC',
  LenderAppsMaxOffersDesc = 'LENDER_APPS_MAX_OFFERS_DESC',
  LenderAppsMaxCreatedAtAsc = 'LENDER_APPS_MAX_CREATED_AT_ASC',
  LenderAppsMaxCreatedAtDesc = 'LENDER_APPS_MAX_CREATED_AT_DESC',
  LenderAppsMaxUpdatedAtAsc = 'LENDER_APPS_MAX_UPDATED_AT_ASC',
  LenderAppsMaxUpdatedAtDesc = 'LENDER_APPS_MAX_UPDATED_AT_DESC',
  LenderAppsMaxVehicleListingIdAsc = 'LENDER_APPS_MAX_VEHICLE_LISTING_ID_ASC',
  LenderAppsMaxVehicleListingIdDesc = 'LENDER_APPS_MAX_VEHICLE_LISTING_ID_DESC',
  LenderAppsMaxMaritalStatusAsc = 'LENDER_APPS_MAX_MARITAL_STATUS_ASC',
  LenderAppsMaxMaritalStatusDesc = 'LENDER_APPS_MAX_MARITAL_STATUS_DESC',
  LenderAppsAverageIdAsc = 'LENDER_APPS_AVERAGE_ID_ASC',
  LenderAppsAverageIdDesc = 'LENDER_APPS_AVERAGE_ID_DESC',
  LenderAppsAverageLeadIdAsc = 'LENDER_APPS_AVERAGE_LEAD_ID_ASC',
  LenderAppsAverageLeadIdDesc = 'LENDER_APPS_AVERAGE_LEAD_ID_DESC',
  LenderAppsAverageInovatecApplicationNumberAsc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsAverageInovatecApplicationNumberDesc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsAverageInovatecApplicationIdAsc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsAverageInovatecApplicationIdDesc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsAverageYearlyIncomeAsc = 'LENDER_APPS_AVERAGE_YEARLY_INCOME_ASC',
  LenderAppsAverageYearlyIncomeDesc = 'LENDER_APPS_AVERAGE_YEARLY_INCOME_DESC',
  LenderAppsAverageEmploymentStatusAsc = 'LENDER_APPS_AVERAGE_EMPLOYMENT_STATUS_ASC',
  LenderAppsAverageEmploymentStatusDesc = 'LENDER_APPS_AVERAGE_EMPLOYMENT_STATUS_DESC',
  LenderAppsAverageMonthlyHomePaymentAsc = 'LENDER_APPS_AVERAGE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsAverageMonthlyHomePaymentDesc = 'LENDER_APPS_AVERAGE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsAveragePreferredOfferIdAsc = 'LENDER_APPS_AVERAGE_PREFERRED_OFFER_ID_ASC',
  LenderAppsAveragePreferredOfferIdDesc = 'LENDER_APPS_AVERAGE_PREFERRED_OFFER_ID_DESC',
  LenderAppsAverageOffersAsc = 'LENDER_APPS_AVERAGE_OFFERS_ASC',
  LenderAppsAverageOffersDesc = 'LENDER_APPS_AVERAGE_OFFERS_DESC',
  LenderAppsAverageCreatedAtAsc = 'LENDER_APPS_AVERAGE_CREATED_AT_ASC',
  LenderAppsAverageCreatedAtDesc = 'LENDER_APPS_AVERAGE_CREATED_AT_DESC',
  LenderAppsAverageUpdatedAtAsc = 'LENDER_APPS_AVERAGE_UPDATED_AT_ASC',
  LenderAppsAverageUpdatedAtDesc = 'LENDER_APPS_AVERAGE_UPDATED_AT_DESC',
  LenderAppsAverageVehicleListingIdAsc = 'LENDER_APPS_AVERAGE_VEHICLE_LISTING_ID_ASC',
  LenderAppsAverageVehicleListingIdDesc = 'LENDER_APPS_AVERAGE_VEHICLE_LISTING_ID_DESC',
  LenderAppsAverageMaritalStatusAsc = 'LENDER_APPS_AVERAGE_MARITAL_STATUS_ASC',
  LenderAppsAverageMaritalStatusDesc = 'LENDER_APPS_AVERAGE_MARITAL_STATUS_DESC',
  LenderAppsStddevSampleIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_ID_ASC',
  LenderAppsStddevSampleIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_ID_DESC',
  LenderAppsStddevSampleLeadIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_LEAD_ID_ASC',
  LenderAppsStddevSampleLeadIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_LEAD_ID_DESC',
  LenderAppsStddevSampleInovatecApplicationNumberAsc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsStddevSampleInovatecApplicationNumberDesc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsStddevSampleInovatecApplicationIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsStddevSampleInovatecApplicationIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsStddevSampleYearlyIncomeAsc = 'LENDER_APPS_STDDEV_SAMPLE_YEARLY_INCOME_ASC',
  LenderAppsStddevSampleYearlyIncomeDesc = 'LENDER_APPS_STDDEV_SAMPLE_YEARLY_INCOME_DESC',
  LenderAppsStddevSampleEmploymentStatusAsc = 'LENDER_APPS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LenderAppsStddevSampleEmploymentStatusDesc = 'LENDER_APPS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LenderAppsStddevSampleMonthlyHomePaymentAsc = 'LENDER_APPS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsStddevSampleMonthlyHomePaymentDesc = 'LENDER_APPS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsStddevSamplePreferredOfferIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_PREFERRED_OFFER_ID_ASC',
  LenderAppsStddevSamplePreferredOfferIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_PREFERRED_OFFER_ID_DESC',
  LenderAppsStddevSampleOffersAsc = 'LENDER_APPS_STDDEV_SAMPLE_OFFERS_ASC',
  LenderAppsStddevSampleOffersDesc = 'LENDER_APPS_STDDEV_SAMPLE_OFFERS_DESC',
  LenderAppsStddevSampleCreatedAtAsc = 'LENDER_APPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderAppsStddevSampleCreatedAtDesc = 'LENDER_APPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderAppsStddevSampleUpdatedAtAsc = 'LENDER_APPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderAppsStddevSampleUpdatedAtDesc = 'LENDER_APPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderAppsStddevSampleVehicleListingIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_ASC',
  LenderAppsStddevSampleVehicleListingIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_DESC',
  LenderAppsStddevSampleMaritalStatusAsc = 'LENDER_APPS_STDDEV_SAMPLE_MARITAL_STATUS_ASC',
  LenderAppsStddevSampleMaritalStatusDesc = 'LENDER_APPS_STDDEV_SAMPLE_MARITAL_STATUS_DESC',
  LenderAppsStddevPopulationIdAsc = 'LENDER_APPS_STDDEV_POPULATION_ID_ASC',
  LenderAppsStddevPopulationIdDesc = 'LENDER_APPS_STDDEV_POPULATION_ID_DESC',
  LenderAppsStddevPopulationLeadIdAsc = 'LENDER_APPS_STDDEV_POPULATION_LEAD_ID_ASC',
  LenderAppsStddevPopulationLeadIdDesc = 'LENDER_APPS_STDDEV_POPULATION_LEAD_ID_DESC',
  LenderAppsStddevPopulationInovatecApplicationNumberAsc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsStddevPopulationInovatecApplicationNumberDesc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsStddevPopulationInovatecApplicationIdAsc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsStddevPopulationInovatecApplicationIdDesc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsStddevPopulationYearlyIncomeAsc = 'LENDER_APPS_STDDEV_POPULATION_YEARLY_INCOME_ASC',
  LenderAppsStddevPopulationYearlyIncomeDesc = 'LENDER_APPS_STDDEV_POPULATION_YEARLY_INCOME_DESC',
  LenderAppsStddevPopulationEmploymentStatusAsc = 'LENDER_APPS_STDDEV_POPULATION_EMPLOYMENT_STATUS_ASC',
  LenderAppsStddevPopulationEmploymentStatusDesc = 'LENDER_APPS_STDDEV_POPULATION_EMPLOYMENT_STATUS_DESC',
  LenderAppsStddevPopulationMonthlyHomePaymentAsc = 'LENDER_APPS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsStddevPopulationMonthlyHomePaymentDesc = 'LENDER_APPS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsStddevPopulationPreferredOfferIdAsc = 'LENDER_APPS_STDDEV_POPULATION_PREFERRED_OFFER_ID_ASC',
  LenderAppsStddevPopulationPreferredOfferIdDesc = 'LENDER_APPS_STDDEV_POPULATION_PREFERRED_OFFER_ID_DESC',
  LenderAppsStddevPopulationOffersAsc = 'LENDER_APPS_STDDEV_POPULATION_OFFERS_ASC',
  LenderAppsStddevPopulationOffersDesc = 'LENDER_APPS_STDDEV_POPULATION_OFFERS_DESC',
  LenderAppsStddevPopulationCreatedAtAsc = 'LENDER_APPS_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderAppsStddevPopulationCreatedAtDesc = 'LENDER_APPS_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderAppsStddevPopulationUpdatedAtAsc = 'LENDER_APPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderAppsStddevPopulationUpdatedAtDesc = 'LENDER_APPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderAppsStddevPopulationVehicleListingIdAsc = 'LENDER_APPS_STDDEV_POPULATION_VEHICLE_LISTING_ID_ASC',
  LenderAppsStddevPopulationVehicleListingIdDesc = 'LENDER_APPS_STDDEV_POPULATION_VEHICLE_LISTING_ID_DESC',
  LenderAppsStddevPopulationMaritalStatusAsc = 'LENDER_APPS_STDDEV_POPULATION_MARITAL_STATUS_ASC',
  LenderAppsStddevPopulationMaritalStatusDesc = 'LENDER_APPS_STDDEV_POPULATION_MARITAL_STATUS_DESC',
  LenderAppsVarianceSampleIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_ID_ASC',
  LenderAppsVarianceSampleIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_ID_DESC',
  LenderAppsVarianceSampleLeadIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LenderAppsVarianceSampleLeadIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LenderAppsVarianceSampleInovatecApplicationNumberAsc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsVarianceSampleInovatecApplicationNumberDesc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsVarianceSampleInovatecApplicationIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsVarianceSampleInovatecApplicationIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsVarianceSampleYearlyIncomeAsc = 'LENDER_APPS_VARIANCE_SAMPLE_YEARLY_INCOME_ASC',
  LenderAppsVarianceSampleYearlyIncomeDesc = 'LENDER_APPS_VARIANCE_SAMPLE_YEARLY_INCOME_DESC',
  LenderAppsVarianceSampleEmploymentStatusAsc = 'LENDER_APPS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LenderAppsVarianceSampleEmploymentStatusDesc = 'LENDER_APPS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LenderAppsVarianceSampleMonthlyHomePaymentAsc = 'LENDER_APPS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsVarianceSampleMonthlyHomePaymentDesc = 'LENDER_APPS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsVarianceSamplePreferredOfferIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_PREFERRED_OFFER_ID_ASC',
  LenderAppsVarianceSamplePreferredOfferIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_PREFERRED_OFFER_ID_DESC',
  LenderAppsVarianceSampleOffersAsc = 'LENDER_APPS_VARIANCE_SAMPLE_OFFERS_ASC',
  LenderAppsVarianceSampleOffersDesc = 'LENDER_APPS_VARIANCE_SAMPLE_OFFERS_DESC',
  LenderAppsVarianceSampleCreatedAtAsc = 'LENDER_APPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderAppsVarianceSampleCreatedAtDesc = 'LENDER_APPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderAppsVarianceSampleUpdatedAtAsc = 'LENDER_APPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderAppsVarianceSampleUpdatedAtDesc = 'LENDER_APPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderAppsVarianceSampleVehicleListingIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_ASC',
  LenderAppsVarianceSampleVehicleListingIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_DESC',
  LenderAppsVarianceSampleMaritalStatusAsc = 'LENDER_APPS_VARIANCE_SAMPLE_MARITAL_STATUS_ASC',
  LenderAppsVarianceSampleMaritalStatusDesc = 'LENDER_APPS_VARIANCE_SAMPLE_MARITAL_STATUS_DESC',
  LenderAppsVariancePopulationIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_ID_ASC',
  LenderAppsVariancePopulationIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_ID_DESC',
  LenderAppsVariancePopulationLeadIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_LEAD_ID_ASC',
  LenderAppsVariancePopulationLeadIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_LEAD_ID_DESC',
  LenderAppsVariancePopulationInovatecApplicationNumberAsc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsVariancePopulationInovatecApplicationNumberDesc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsVariancePopulationInovatecApplicationIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsVariancePopulationInovatecApplicationIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsVariancePopulationYearlyIncomeAsc = 'LENDER_APPS_VARIANCE_POPULATION_YEARLY_INCOME_ASC',
  LenderAppsVariancePopulationYearlyIncomeDesc = 'LENDER_APPS_VARIANCE_POPULATION_YEARLY_INCOME_DESC',
  LenderAppsVariancePopulationEmploymentStatusAsc = 'LENDER_APPS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_ASC',
  LenderAppsVariancePopulationEmploymentStatusDesc = 'LENDER_APPS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_DESC',
  LenderAppsVariancePopulationMonthlyHomePaymentAsc = 'LENDER_APPS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsVariancePopulationMonthlyHomePaymentDesc = 'LENDER_APPS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsVariancePopulationPreferredOfferIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_PREFERRED_OFFER_ID_ASC',
  LenderAppsVariancePopulationPreferredOfferIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_PREFERRED_OFFER_ID_DESC',
  LenderAppsVariancePopulationOffersAsc = 'LENDER_APPS_VARIANCE_POPULATION_OFFERS_ASC',
  LenderAppsVariancePopulationOffersDesc = 'LENDER_APPS_VARIANCE_POPULATION_OFFERS_DESC',
  LenderAppsVariancePopulationCreatedAtAsc = 'LENDER_APPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderAppsVariancePopulationCreatedAtDesc = 'LENDER_APPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderAppsVariancePopulationUpdatedAtAsc = 'LENDER_APPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderAppsVariancePopulationUpdatedAtDesc = 'LENDER_APPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderAppsVariancePopulationVehicleListingIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_ASC',
  LenderAppsVariancePopulationVehicleListingIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_DESC',
  LenderAppsVariancePopulationMaritalStatusAsc = 'LENDER_APPS_VARIANCE_POPULATION_MARITAL_STATUS_ASC',
  LenderAppsVariancePopulationMaritalStatusDesc = 'LENDER_APPS_VARIANCE_POPULATION_MARITAL_STATUS_DESC',
  AppointmentsCountAsc = 'APPOINTMENTS_COUNT_ASC',
  AppointmentsCountDesc = 'APPOINTMENTS_COUNT_DESC',
  AppointmentsSumIdAsc = 'APPOINTMENTS_SUM_ID_ASC',
  AppointmentsSumIdDesc = 'APPOINTMENTS_SUM_ID_DESC',
  AppointmentsSumLeadIdAsc = 'APPOINTMENTS_SUM_LEAD_ID_ASC',
  AppointmentsSumLeadIdDesc = 'APPOINTMENTS_SUM_LEAD_ID_DESC',
  AppointmentsSumVehicleListingIdAsc = 'APPOINTMENTS_SUM_VEHICLE_LISTING_ID_ASC',
  AppointmentsSumVehicleListingIdDesc = 'APPOINTMENTS_SUM_VEHICLE_LISTING_ID_DESC',
  AppointmentsSumAdditionalInformationAsc = 'APPOINTMENTS_SUM_ADDITIONAL_INFORMATION_ASC',
  AppointmentsSumAdditionalInformationDesc = 'APPOINTMENTS_SUM_ADDITIONAL_INFORMATION_DESC',
  AppointmentsSumAppointmentAtAsc = 'APPOINTMENTS_SUM_APPOINTMENT_AT_ASC',
  AppointmentsSumAppointmentAtDesc = 'APPOINTMENTS_SUM_APPOINTMENT_AT_DESC',
  AppointmentsSumConfirmedAtAsc = 'APPOINTMENTS_SUM_CONFIRMED_AT_ASC',
  AppointmentsSumConfirmedAtDesc = 'APPOINTMENTS_SUM_CONFIRMED_AT_DESC',
  AppointmentsSumCreatedAtAsc = 'APPOINTMENTS_SUM_CREATED_AT_ASC',
  AppointmentsSumCreatedAtDesc = 'APPOINTMENTS_SUM_CREATED_AT_DESC',
  AppointmentsSumUpdatedAtAsc = 'APPOINTMENTS_SUM_UPDATED_AT_ASC',
  AppointmentsSumUpdatedAtDesc = 'APPOINTMENTS_SUM_UPDATED_AT_DESC',
  AppointmentsDistinctCountIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_ID_ASC',
  AppointmentsDistinctCountIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_ID_DESC',
  AppointmentsDistinctCountLeadIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_LEAD_ID_ASC',
  AppointmentsDistinctCountLeadIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_LEAD_ID_DESC',
  AppointmentsDistinctCountVehicleListingIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_VEHICLE_LISTING_ID_ASC',
  AppointmentsDistinctCountVehicleListingIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_VEHICLE_LISTING_ID_DESC',
  AppointmentsDistinctCountAdditionalInformationAsc = 'APPOINTMENTS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_ASC',
  AppointmentsDistinctCountAdditionalInformationDesc = 'APPOINTMENTS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_DESC',
  AppointmentsDistinctCountAppointmentAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_APPOINTMENT_AT_ASC',
  AppointmentsDistinctCountAppointmentAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_APPOINTMENT_AT_DESC',
  AppointmentsDistinctCountConfirmedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_CONFIRMED_AT_ASC',
  AppointmentsDistinctCountConfirmedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_CONFIRMED_AT_DESC',
  AppointmentsDistinctCountCreatedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  AppointmentsDistinctCountCreatedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  AppointmentsDistinctCountUpdatedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  AppointmentsDistinctCountUpdatedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  AppointmentsMinIdAsc = 'APPOINTMENTS_MIN_ID_ASC',
  AppointmentsMinIdDesc = 'APPOINTMENTS_MIN_ID_DESC',
  AppointmentsMinLeadIdAsc = 'APPOINTMENTS_MIN_LEAD_ID_ASC',
  AppointmentsMinLeadIdDesc = 'APPOINTMENTS_MIN_LEAD_ID_DESC',
  AppointmentsMinVehicleListingIdAsc = 'APPOINTMENTS_MIN_VEHICLE_LISTING_ID_ASC',
  AppointmentsMinVehicleListingIdDesc = 'APPOINTMENTS_MIN_VEHICLE_LISTING_ID_DESC',
  AppointmentsMinAdditionalInformationAsc = 'APPOINTMENTS_MIN_ADDITIONAL_INFORMATION_ASC',
  AppointmentsMinAdditionalInformationDesc = 'APPOINTMENTS_MIN_ADDITIONAL_INFORMATION_DESC',
  AppointmentsMinAppointmentAtAsc = 'APPOINTMENTS_MIN_APPOINTMENT_AT_ASC',
  AppointmentsMinAppointmentAtDesc = 'APPOINTMENTS_MIN_APPOINTMENT_AT_DESC',
  AppointmentsMinConfirmedAtAsc = 'APPOINTMENTS_MIN_CONFIRMED_AT_ASC',
  AppointmentsMinConfirmedAtDesc = 'APPOINTMENTS_MIN_CONFIRMED_AT_DESC',
  AppointmentsMinCreatedAtAsc = 'APPOINTMENTS_MIN_CREATED_AT_ASC',
  AppointmentsMinCreatedAtDesc = 'APPOINTMENTS_MIN_CREATED_AT_DESC',
  AppointmentsMinUpdatedAtAsc = 'APPOINTMENTS_MIN_UPDATED_AT_ASC',
  AppointmentsMinUpdatedAtDesc = 'APPOINTMENTS_MIN_UPDATED_AT_DESC',
  AppointmentsMaxIdAsc = 'APPOINTMENTS_MAX_ID_ASC',
  AppointmentsMaxIdDesc = 'APPOINTMENTS_MAX_ID_DESC',
  AppointmentsMaxLeadIdAsc = 'APPOINTMENTS_MAX_LEAD_ID_ASC',
  AppointmentsMaxLeadIdDesc = 'APPOINTMENTS_MAX_LEAD_ID_DESC',
  AppointmentsMaxVehicleListingIdAsc = 'APPOINTMENTS_MAX_VEHICLE_LISTING_ID_ASC',
  AppointmentsMaxVehicleListingIdDesc = 'APPOINTMENTS_MAX_VEHICLE_LISTING_ID_DESC',
  AppointmentsMaxAdditionalInformationAsc = 'APPOINTMENTS_MAX_ADDITIONAL_INFORMATION_ASC',
  AppointmentsMaxAdditionalInformationDesc = 'APPOINTMENTS_MAX_ADDITIONAL_INFORMATION_DESC',
  AppointmentsMaxAppointmentAtAsc = 'APPOINTMENTS_MAX_APPOINTMENT_AT_ASC',
  AppointmentsMaxAppointmentAtDesc = 'APPOINTMENTS_MAX_APPOINTMENT_AT_DESC',
  AppointmentsMaxConfirmedAtAsc = 'APPOINTMENTS_MAX_CONFIRMED_AT_ASC',
  AppointmentsMaxConfirmedAtDesc = 'APPOINTMENTS_MAX_CONFIRMED_AT_DESC',
  AppointmentsMaxCreatedAtAsc = 'APPOINTMENTS_MAX_CREATED_AT_ASC',
  AppointmentsMaxCreatedAtDesc = 'APPOINTMENTS_MAX_CREATED_AT_DESC',
  AppointmentsMaxUpdatedAtAsc = 'APPOINTMENTS_MAX_UPDATED_AT_ASC',
  AppointmentsMaxUpdatedAtDesc = 'APPOINTMENTS_MAX_UPDATED_AT_DESC',
  AppointmentsAverageIdAsc = 'APPOINTMENTS_AVERAGE_ID_ASC',
  AppointmentsAverageIdDesc = 'APPOINTMENTS_AVERAGE_ID_DESC',
  AppointmentsAverageLeadIdAsc = 'APPOINTMENTS_AVERAGE_LEAD_ID_ASC',
  AppointmentsAverageLeadIdDesc = 'APPOINTMENTS_AVERAGE_LEAD_ID_DESC',
  AppointmentsAverageVehicleListingIdAsc = 'APPOINTMENTS_AVERAGE_VEHICLE_LISTING_ID_ASC',
  AppointmentsAverageVehicleListingIdDesc = 'APPOINTMENTS_AVERAGE_VEHICLE_LISTING_ID_DESC',
  AppointmentsAverageAdditionalInformationAsc = 'APPOINTMENTS_AVERAGE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsAverageAdditionalInformationDesc = 'APPOINTMENTS_AVERAGE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsAverageAppointmentAtAsc = 'APPOINTMENTS_AVERAGE_APPOINTMENT_AT_ASC',
  AppointmentsAverageAppointmentAtDesc = 'APPOINTMENTS_AVERAGE_APPOINTMENT_AT_DESC',
  AppointmentsAverageConfirmedAtAsc = 'APPOINTMENTS_AVERAGE_CONFIRMED_AT_ASC',
  AppointmentsAverageConfirmedAtDesc = 'APPOINTMENTS_AVERAGE_CONFIRMED_AT_DESC',
  AppointmentsAverageCreatedAtAsc = 'APPOINTMENTS_AVERAGE_CREATED_AT_ASC',
  AppointmentsAverageCreatedAtDesc = 'APPOINTMENTS_AVERAGE_CREATED_AT_DESC',
  AppointmentsAverageUpdatedAtAsc = 'APPOINTMENTS_AVERAGE_UPDATED_AT_ASC',
  AppointmentsAverageUpdatedAtDesc = 'APPOINTMENTS_AVERAGE_UPDATED_AT_DESC',
  AppointmentsStddevSampleIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_ID_ASC',
  AppointmentsStddevSampleIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_ID_DESC',
  AppointmentsStddevSampleLeadIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_LEAD_ID_ASC',
  AppointmentsStddevSampleLeadIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_LEAD_ID_DESC',
  AppointmentsStddevSampleVehicleListingIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_ASC',
  AppointmentsStddevSampleVehicleListingIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_DESC',
  AppointmentsStddevSampleAdditionalInformationAsc = 'APPOINTMENTS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsStddevSampleAdditionalInformationDesc = 'APPOINTMENTS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsStddevSampleAppointmentAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_APPOINTMENT_AT_ASC',
  AppointmentsStddevSampleAppointmentAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_APPOINTMENT_AT_DESC',
  AppointmentsStddevSampleConfirmedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_CONFIRMED_AT_ASC',
  AppointmentsStddevSampleConfirmedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_CONFIRMED_AT_DESC',
  AppointmentsStddevSampleCreatedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  AppointmentsStddevSampleCreatedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  AppointmentsStddevSampleUpdatedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  AppointmentsStddevSampleUpdatedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  AppointmentsStddevPopulationIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_ID_ASC',
  AppointmentsStddevPopulationIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_ID_DESC',
  AppointmentsStddevPopulationLeadIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_LEAD_ID_ASC',
  AppointmentsStddevPopulationLeadIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_LEAD_ID_DESC',
  AppointmentsStddevPopulationVehicleListingIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_VEHICLE_LISTING_ID_ASC',
  AppointmentsStddevPopulationVehicleListingIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_VEHICLE_LISTING_ID_DESC',
  AppointmentsStddevPopulationAdditionalInformationAsc = 'APPOINTMENTS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_ASC',
  AppointmentsStddevPopulationAdditionalInformationDesc = 'APPOINTMENTS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_DESC',
  AppointmentsStddevPopulationAppointmentAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_APPOINTMENT_AT_ASC',
  AppointmentsStddevPopulationAppointmentAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_APPOINTMENT_AT_DESC',
  AppointmentsStddevPopulationConfirmedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_CONFIRMED_AT_ASC',
  AppointmentsStddevPopulationConfirmedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_CONFIRMED_AT_DESC',
  AppointmentsStddevPopulationCreatedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  AppointmentsStddevPopulationCreatedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  AppointmentsStddevPopulationUpdatedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  AppointmentsStddevPopulationUpdatedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  AppointmentsVarianceSampleIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_ID_ASC',
  AppointmentsVarianceSampleIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_ID_DESC',
  AppointmentsVarianceSampleLeadIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  AppointmentsVarianceSampleLeadIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  AppointmentsVarianceSampleVehicleListingIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_ASC',
  AppointmentsVarianceSampleVehicleListingIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_DESC',
  AppointmentsVarianceSampleAdditionalInformationAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsVarianceSampleAdditionalInformationDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsVarianceSampleAppointmentAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_APPOINTMENT_AT_ASC',
  AppointmentsVarianceSampleAppointmentAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_APPOINTMENT_AT_DESC',
  AppointmentsVarianceSampleConfirmedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_CONFIRMED_AT_ASC',
  AppointmentsVarianceSampleConfirmedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_CONFIRMED_AT_DESC',
  AppointmentsVarianceSampleCreatedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  AppointmentsVarianceSampleCreatedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  AppointmentsVarianceSampleUpdatedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  AppointmentsVarianceSampleUpdatedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  AppointmentsVariancePopulationIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_ID_ASC',
  AppointmentsVariancePopulationIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_ID_DESC',
  AppointmentsVariancePopulationLeadIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_LEAD_ID_ASC',
  AppointmentsVariancePopulationLeadIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_LEAD_ID_DESC',
  AppointmentsVariancePopulationVehicleListingIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_ASC',
  AppointmentsVariancePopulationVehicleListingIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_DESC',
  AppointmentsVariancePopulationAdditionalInformationAsc = 'APPOINTMENTS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_ASC',
  AppointmentsVariancePopulationAdditionalInformationDesc = 'APPOINTMENTS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_DESC',
  AppointmentsVariancePopulationAppointmentAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_APPOINTMENT_AT_ASC',
  AppointmentsVariancePopulationAppointmentAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_APPOINTMENT_AT_DESC',
  AppointmentsVariancePopulationConfirmedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_CONFIRMED_AT_ASC',
  AppointmentsVariancePopulationConfirmedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_CONFIRMED_AT_DESC',
  AppointmentsVariancePopulationCreatedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  AppointmentsVariancePopulationCreatedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  AppointmentsVariancePopulationUpdatedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  AppointmentsVariancePopulationUpdatedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderQuotesCountAsc = 'LENDER_QUOTES_COUNT_ASC',
  LenderQuotesCountDesc = 'LENDER_QUOTES_COUNT_DESC',
  LenderQuotesSumIdAsc = 'LENDER_QUOTES_SUM_ID_ASC',
  LenderQuotesSumIdDesc = 'LENDER_QUOTES_SUM_ID_DESC',
  LenderQuotesSumLeadIdAsc = 'LENDER_QUOTES_SUM_LEAD_ID_ASC',
  LenderQuotesSumLeadIdDesc = 'LENDER_QUOTES_SUM_LEAD_ID_DESC',
  LenderQuotesSumLenderAppIdAsc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_ASC',
  LenderQuotesSumLenderAppIdDesc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_DESC',
  LenderQuotesSumProviderAsc = 'LENDER_QUOTES_SUM_PROVIDER_ASC',
  LenderQuotesSumProviderDesc = 'LENDER_QUOTES_SUM_PROVIDER_DESC',
  LenderQuotesSumQuoteJsonAsc = 'LENDER_QUOTES_SUM_QUOTE_JSON_ASC',
  LenderQuotesSumQuoteJsonDesc = 'LENDER_QUOTES_SUM_QUOTE_JSON_DESC',
  LenderQuotesSumCreatedAtAsc = 'LENDER_QUOTES_SUM_CREATED_AT_ASC',
  LenderQuotesSumCreatedAtDesc = 'LENDER_QUOTES_SUM_CREATED_AT_DESC',
  LenderQuotesSumUpdatedAtAsc = 'LENDER_QUOTES_SUM_UPDATED_AT_ASC',
  LenderQuotesSumUpdatedAtDesc = 'LENDER_QUOTES_SUM_UPDATED_AT_DESC',
  LenderQuotesSumTypeAsc = 'LENDER_QUOTES_SUM_TYPE_ASC',
  LenderQuotesSumTypeDesc = 'LENDER_QUOTES_SUM_TYPE_DESC',
  LenderQuotesSumExpiredAsc = 'LENDER_QUOTES_SUM_EXPIRED_ASC',
  LenderQuotesSumExpiredDesc = 'LENDER_QUOTES_SUM_EXPIRED_DESC',
  LenderQuotesSumLenderIdAsc = 'LENDER_QUOTES_SUM_LENDER_ID_ASC',
  LenderQuotesSumLenderIdDesc = 'LENDER_QUOTES_SUM_LENDER_ID_DESC',
  LenderQuotesSumCompanyIntegrationIdAsc = 'LENDER_QUOTES_SUM_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesSumCompanyIntegrationIdDesc = 'LENDER_QUOTES_SUM_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesSumStipsAsc = 'LENDER_QUOTES_SUM_STIPS_ASC',
  LenderQuotesSumStipsDesc = 'LENDER_QUOTES_SUM_STIPS_DESC',
  LenderQuotesSumApplicationLinkAsc = 'LENDER_QUOTES_SUM_APPLICATION_LINK_ASC',
  LenderQuotesSumApplicationLinkDesc = 'LENDER_QUOTES_SUM_APPLICATION_LINK_DESC',
  LenderQuotesDistinctCountIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_ASC',
  LenderQuotesDistinctCountIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_DESC',
  LenderQuotesDistinctCountLeadIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_ASC',
  LenderQuotesDistinctCountLeadIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_DESC',
  LenderQuotesDistinctCountLenderAppIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_ASC',
  LenderQuotesDistinctCountLenderAppIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_DESC',
  LenderQuotesDistinctCountProviderAsc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_ASC',
  LenderQuotesDistinctCountProviderDesc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_DESC',
  LenderQuotesDistinctCountQuoteJsonAsc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_ASC',
  LenderQuotesDistinctCountQuoteJsonDesc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_DESC',
  LenderQuotesDistinctCountCreatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderQuotesDistinctCountCreatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderQuotesDistinctCountUpdatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderQuotesDistinctCountUpdatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderQuotesDistinctCountTypeAsc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_ASC',
  LenderQuotesDistinctCountTypeDesc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_DESC',
  LenderQuotesDistinctCountExpiredAsc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_ASC',
  LenderQuotesDistinctCountExpiredDesc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_DESC',
  LenderQuotesDistinctCountLenderIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_ID_ASC',
  LenderQuotesDistinctCountLenderIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_ID_DESC',
  LenderQuotesDistinctCountCompanyIntegrationIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesDistinctCountCompanyIntegrationIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesDistinctCountStipsAsc = 'LENDER_QUOTES_DISTINCT_COUNT_STIPS_ASC',
  LenderQuotesDistinctCountStipsDesc = 'LENDER_QUOTES_DISTINCT_COUNT_STIPS_DESC',
  LenderQuotesDistinctCountApplicationLinkAsc = 'LENDER_QUOTES_DISTINCT_COUNT_APPLICATION_LINK_ASC',
  LenderQuotesDistinctCountApplicationLinkDesc = 'LENDER_QUOTES_DISTINCT_COUNT_APPLICATION_LINK_DESC',
  LenderQuotesMinIdAsc = 'LENDER_QUOTES_MIN_ID_ASC',
  LenderQuotesMinIdDesc = 'LENDER_QUOTES_MIN_ID_DESC',
  LenderQuotesMinLeadIdAsc = 'LENDER_QUOTES_MIN_LEAD_ID_ASC',
  LenderQuotesMinLeadIdDesc = 'LENDER_QUOTES_MIN_LEAD_ID_DESC',
  LenderQuotesMinLenderAppIdAsc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_ASC',
  LenderQuotesMinLenderAppIdDesc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_DESC',
  LenderQuotesMinProviderAsc = 'LENDER_QUOTES_MIN_PROVIDER_ASC',
  LenderQuotesMinProviderDesc = 'LENDER_QUOTES_MIN_PROVIDER_DESC',
  LenderQuotesMinQuoteJsonAsc = 'LENDER_QUOTES_MIN_QUOTE_JSON_ASC',
  LenderQuotesMinQuoteJsonDesc = 'LENDER_QUOTES_MIN_QUOTE_JSON_DESC',
  LenderQuotesMinCreatedAtAsc = 'LENDER_QUOTES_MIN_CREATED_AT_ASC',
  LenderQuotesMinCreatedAtDesc = 'LENDER_QUOTES_MIN_CREATED_AT_DESC',
  LenderQuotesMinUpdatedAtAsc = 'LENDER_QUOTES_MIN_UPDATED_AT_ASC',
  LenderQuotesMinUpdatedAtDesc = 'LENDER_QUOTES_MIN_UPDATED_AT_DESC',
  LenderQuotesMinTypeAsc = 'LENDER_QUOTES_MIN_TYPE_ASC',
  LenderQuotesMinTypeDesc = 'LENDER_QUOTES_MIN_TYPE_DESC',
  LenderQuotesMinExpiredAsc = 'LENDER_QUOTES_MIN_EXPIRED_ASC',
  LenderQuotesMinExpiredDesc = 'LENDER_QUOTES_MIN_EXPIRED_DESC',
  LenderQuotesMinLenderIdAsc = 'LENDER_QUOTES_MIN_LENDER_ID_ASC',
  LenderQuotesMinLenderIdDesc = 'LENDER_QUOTES_MIN_LENDER_ID_DESC',
  LenderQuotesMinCompanyIntegrationIdAsc = 'LENDER_QUOTES_MIN_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesMinCompanyIntegrationIdDesc = 'LENDER_QUOTES_MIN_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesMinStipsAsc = 'LENDER_QUOTES_MIN_STIPS_ASC',
  LenderQuotesMinStipsDesc = 'LENDER_QUOTES_MIN_STIPS_DESC',
  LenderQuotesMinApplicationLinkAsc = 'LENDER_QUOTES_MIN_APPLICATION_LINK_ASC',
  LenderQuotesMinApplicationLinkDesc = 'LENDER_QUOTES_MIN_APPLICATION_LINK_DESC',
  LenderQuotesMaxIdAsc = 'LENDER_QUOTES_MAX_ID_ASC',
  LenderQuotesMaxIdDesc = 'LENDER_QUOTES_MAX_ID_DESC',
  LenderQuotesMaxLeadIdAsc = 'LENDER_QUOTES_MAX_LEAD_ID_ASC',
  LenderQuotesMaxLeadIdDesc = 'LENDER_QUOTES_MAX_LEAD_ID_DESC',
  LenderQuotesMaxLenderAppIdAsc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_ASC',
  LenderQuotesMaxLenderAppIdDesc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_DESC',
  LenderQuotesMaxProviderAsc = 'LENDER_QUOTES_MAX_PROVIDER_ASC',
  LenderQuotesMaxProviderDesc = 'LENDER_QUOTES_MAX_PROVIDER_DESC',
  LenderQuotesMaxQuoteJsonAsc = 'LENDER_QUOTES_MAX_QUOTE_JSON_ASC',
  LenderQuotesMaxQuoteJsonDesc = 'LENDER_QUOTES_MAX_QUOTE_JSON_DESC',
  LenderQuotesMaxCreatedAtAsc = 'LENDER_QUOTES_MAX_CREATED_AT_ASC',
  LenderQuotesMaxCreatedAtDesc = 'LENDER_QUOTES_MAX_CREATED_AT_DESC',
  LenderQuotesMaxUpdatedAtAsc = 'LENDER_QUOTES_MAX_UPDATED_AT_ASC',
  LenderQuotesMaxUpdatedAtDesc = 'LENDER_QUOTES_MAX_UPDATED_AT_DESC',
  LenderQuotesMaxTypeAsc = 'LENDER_QUOTES_MAX_TYPE_ASC',
  LenderQuotesMaxTypeDesc = 'LENDER_QUOTES_MAX_TYPE_DESC',
  LenderQuotesMaxExpiredAsc = 'LENDER_QUOTES_MAX_EXPIRED_ASC',
  LenderQuotesMaxExpiredDesc = 'LENDER_QUOTES_MAX_EXPIRED_DESC',
  LenderQuotesMaxLenderIdAsc = 'LENDER_QUOTES_MAX_LENDER_ID_ASC',
  LenderQuotesMaxLenderIdDesc = 'LENDER_QUOTES_MAX_LENDER_ID_DESC',
  LenderQuotesMaxCompanyIntegrationIdAsc = 'LENDER_QUOTES_MAX_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesMaxCompanyIntegrationIdDesc = 'LENDER_QUOTES_MAX_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesMaxStipsAsc = 'LENDER_QUOTES_MAX_STIPS_ASC',
  LenderQuotesMaxStipsDesc = 'LENDER_QUOTES_MAX_STIPS_DESC',
  LenderQuotesMaxApplicationLinkAsc = 'LENDER_QUOTES_MAX_APPLICATION_LINK_ASC',
  LenderQuotesMaxApplicationLinkDesc = 'LENDER_QUOTES_MAX_APPLICATION_LINK_DESC',
  LenderQuotesAverageIdAsc = 'LENDER_QUOTES_AVERAGE_ID_ASC',
  LenderQuotesAverageIdDesc = 'LENDER_QUOTES_AVERAGE_ID_DESC',
  LenderQuotesAverageLeadIdAsc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_ASC',
  LenderQuotesAverageLeadIdDesc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_DESC',
  LenderQuotesAverageLenderAppIdAsc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_ASC',
  LenderQuotesAverageLenderAppIdDesc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_DESC',
  LenderQuotesAverageProviderAsc = 'LENDER_QUOTES_AVERAGE_PROVIDER_ASC',
  LenderQuotesAverageProviderDesc = 'LENDER_QUOTES_AVERAGE_PROVIDER_DESC',
  LenderQuotesAverageQuoteJsonAsc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_ASC',
  LenderQuotesAverageQuoteJsonDesc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_DESC',
  LenderQuotesAverageCreatedAtAsc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_ASC',
  LenderQuotesAverageCreatedAtDesc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_DESC',
  LenderQuotesAverageUpdatedAtAsc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_ASC',
  LenderQuotesAverageUpdatedAtDesc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_DESC',
  LenderQuotesAverageTypeAsc = 'LENDER_QUOTES_AVERAGE_TYPE_ASC',
  LenderQuotesAverageTypeDesc = 'LENDER_QUOTES_AVERAGE_TYPE_DESC',
  LenderQuotesAverageExpiredAsc = 'LENDER_QUOTES_AVERAGE_EXPIRED_ASC',
  LenderQuotesAverageExpiredDesc = 'LENDER_QUOTES_AVERAGE_EXPIRED_DESC',
  LenderQuotesAverageLenderIdAsc = 'LENDER_QUOTES_AVERAGE_LENDER_ID_ASC',
  LenderQuotesAverageLenderIdDesc = 'LENDER_QUOTES_AVERAGE_LENDER_ID_DESC',
  LenderQuotesAverageCompanyIntegrationIdAsc = 'LENDER_QUOTES_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesAverageCompanyIntegrationIdDesc = 'LENDER_QUOTES_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesAverageStipsAsc = 'LENDER_QUOTES_AVERAGE_STIPS_ASC',
  LenderQuotesAverageStipsDesc = 'LENDER_QUOTES_AVERAGE_STIPS_DESC',
  LenderQuotesAverageApplicationLinkAsc = 'LENDER_QUOTES_AVERAGE_APPLICATION_LINK_ASC',
  LenderQuotesAverageApplicationLinkDesc = 'LENDER_QUOTES_AVERAGE_APPLICATION_LINK_DESC',
  LenderQuotesStddevSampleIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_ASC',
  LenderQuotesStddevSampleIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_DESC',
  LenderQuotesStddevSampleLeadIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_ASC',
  LenderQuotesStddevSampleLeadIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_DESC',
  LenderQuotesStddevSampleLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesStddevSampleLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesStddevSampleProviderAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_ASC',
  LenderQuotesStddevSampleProviderDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_DESC',
  LenderQuotesStddevSampleQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesStddevSampleQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesStddevSampleCreatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderQuotesStddevSampleCreatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderQuotesStddevSampleUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesStddevSampleUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesStddevSampleTypeAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_ASC',
  LenderQuotesStddevSampleTypeDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_DESC',
  LenderQuotesStddevSampleExpiredAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_ASC',
  LenderQuotesStddevSampleExpiredDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_DESC',
  LenderQuotesStddevSampleLenderIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_ID_ASC',
  LenderQuotesStddevSampleLenderIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_ID_DESC',
  LenderQuotesStddevSampleCompanyIntegrationIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesStddevSampleCompanyIntegrationIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesStddevSampleStipsAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_STIPS_ASC',
  LenderQuotesStddevSampleStipsDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_STIPS_DESC',
  LenderQuotesStddevSampleApplicationLinkAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_APPLICATION_LINK_ASC',
  LenderQuotesStddevSampleApplicationLinkDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_APPLICATION_LINK_DESC',
  LenderQuotesStddevPopulationIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_ASC',
  LenderQuotesStddevPopulationIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_DESC',
  LenderQuotesStddevPopulationLeadIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_ASC',
  LenderQuotesStddevPopulationLeadIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_DESC',
  LenderQuotesStddevPopulationLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesStddevPopulationLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesStddevPopulationProviderAsc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_ASC',
  LenderQuotesStddevPopulationProviderDesc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_DESC',
  LenderQuotesStddevPopulationQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesStddevPopulationQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesStddevPopulationCreatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderQuotesStddevPopulationCreatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderQuotesStddevPopulationUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderQuotesStddevPopulationUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderQuotesStddevPopulationTypeAsc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_ASC',
  LenderQuotesStddevPopulationTypeDesc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_DESC',
  LenderQuotesStddevPopulationExpiredAsc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_ASC',
  LenderQuotesStddevPopulationExpiredDesc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_DESC',
  LenderQuotesStddevPopulationLenderIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_ID_ASC',
  LenderQuotesStddevPopulationLenderIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_ID_DESC',
  LenderQuotesStddevPopulationCompanyIntegrationIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesStddevPopulationCompanyIntegrationIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesStddevPopulationStipsAsc = 'LENDER_QUOTES_STDDEV_POPULATION_STIPS_ASC',
  LenderQuotesStddevPopulationStipsDesc = 'LENDER_QUOTES_STDDEV_POPULATION_STIPS_DESC',
  LenderQuotesStddevPopulationApplicationLinkAsc = 'LENDER_QUOTES_STDDEV_POPULATION_APPLICATION_LINK_ASC',
  LenderQuotesStddevPopulationApplicationLinkDesc = 'LENDER_QUOTES_STDDEV_POPULATION_APPLICATION_LINK_DESC',
  LenderQuotesVarianceSampleIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_ASC',
  LenderQuotesVarianceSampleIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_DESC',
  LenderQuotesVarianceSampleLeadIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LenderQuotesVarianceSampleLeadIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LenderQuotesVarianceSampleLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesVarianceSampleLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesVarianceSampleProviderAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_ASC',
  LenderQuotesVarianceSampleProviderDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_DESC',
  LenderQuotesVarianceSampleQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesVarianceSampleQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesVarianceSampleCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderQuotesVarianceSampleCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderQuotesVarianceSampleUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesVarianceSampleUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesVarianceSampleTypeAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_ASC',
  LenderQuotesVarianceSampleTypeDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_DESC',
  LenderQuotesVarianceSampleExpiredAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_ASC',
  LenderQuotesVarianceSampleExpiredDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_DESC',
  LenderQuotesVarianceSampleLenderIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_ID_ASC',
  LenderQuotesVarianceSampleLenderIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_ID_DESC',
  LenderQuotesVarianceSampleCompanyIntegrationIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesVarianceSampleCompanyIntegrationIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesVarianceSampleStipsAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_STIPS_ASC',
  LenderQuotesVarianceSampleStipsDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_STIPS_DESC',
  LenderQuotesVarianceSampleApplicationLinkAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_APPLICATION_LINK_ASC',
  LenderQuotesVarianceSampleApplicationLinkDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_APPLICATION_LINK_DESC',
  LenderQuotesVariancePopulationIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_ASC',
  LenderQuotesVariancePopulationIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_DESC',
  LenderQuotesVariancePopulationLeadIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_ASC',
  LenderQuotesVariancePopulationLeadIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_DESC',
  LenderQuotesVariancePopulationLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesVariancePopulationLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesVariancePopulationProviderAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_ASC',
  LenderQuotesVariancePopulationProviderDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_DESC',
  LenderQuotesVariancePopulationQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesVariancePopulationQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesVariancePopulationCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderQuotesVariancePopulationCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderQuotesVariancePopulationUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderQuotesVariancePopulationUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderQuotesVariancePopulationTypeAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_ASC',
  LenderQuotesVariancePopulationTypeDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_DESC',
  LenderQuotesVariancePopulationExpiredAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_ASC',
  LenderQuotesVariancePopulationExpiredDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_DESC',
  LenderQuotesVariancePopulationLenderIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_ID_ASC',
  LenderQuotesVariancePopulationLenderIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_ID_DESC',
  LenderQuotesVariancePopulationCompanyIntegrationIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesVariancePopulationCompanyIntegrationIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesVariancePopulationStipsAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_STIPS_ASC',
  LenderQuotesVariancePopulationStipsDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_STIPS_DESC',
  LenderQuotesVariancePopulationApplicationLinkAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_APPLICATION_LINK_ASC',
  LenderQuotesVariancePopulationApplicationLinkDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_APPLICATION_LINK_DESC',
  LeadRolesCountAsc = 'LEAD_ROLES_COUNT_ASC',
  LeadRolesCountDesc = 'LEAD_ROLES_COUNT_DESC',
  LeadRolesSumIdAsc = 'LEAD_ROLES_SUM_ID_ASC',
  LeadRolesSumIdDesc = 'LEAD_ROLES_SUM_ID_DESC',
  LeadRolesSumLeadIdAsc = 'LEAD_ROLES_SUM_LEAD_ID_ASC',
  LeadRolesSumLeadIdDesc = 'LEAD_ROLES_SUM_LEAD_ID_DESC',
  LeadRolesSumUserIdAsc = 'LEAD_ROLES_SUM_USER_ID_ASC',
  LeadRolesSumUserIdDesc = 'LEAD_ROLES_SUM_USER_ID_DESC',
  LeadRolesSumRoleAsc = 'LEAD_ROLES_SUM_ROLE_ASC',
  LeadRolesSumRoleDesc = 'LEAD_ROLES_SUM_ROLE_DESC',
  LeadRolesSumCreatedAtAsc = 'LEAD_ROLES_SUM_CREATED_AT_ASC',
  LeadRolesSumCreatedAtDesc = 'LEAD_ROLES_SUM_CREATED_AT_DESC',
  LeadRolesSumUpdatedAtAsc = 'LEAD_ROLES_SUM_UPDATED_AT_ASC',
  LeadRolesSumUpdatedAtDesc = 'LEAD_ROLES_SUM_UPDATED_AT_DESC',
  LeadRolesDistinctCountIdAsc = 'LEAD_ROLES_DISTINCT_COUNT_ID_ASC',
  LeadRolesDistinctCountIdDesc = 'LEAD_ROLES_DISTINCT_COUNT_ID_DESC',
  LeadRolesDistinctCountLeadIdAsc = 'LEAD_ROLES_DISTINCT_COUNT_LEAD_ID_ASC',
  LeadRolesDistinctCountLeadIdDesc = 'LEAD_ROLES_DISTINCT_COUNT_LEAD_ID_DESC',
  LeadRolesDistinctCountUserIdAsc = 'LEAD_ROLES_DISTINCT_COUNT_USER_ID_ASC',
  LeadRolesDistinctCountUserIdDesc = 'LEAD_ROLES_DISTINCT_COUNT_USER_ID_DESC',
  LeadRolesDistinctCountRoleAsc = 'LEAD_ROLES_DISTINCT_COUNT_ROLE_ASC',
  LeadRolesDistinctCountRoleDesc = 'LEAD_ROLES_DISTINCT_COUNT_ROLE_DESC',
  LeadRolesDistinctCountCreatedAtAsc = 'LEAD_ROLES_DISTINCT_COUNT_CREATED_AT_ASC',
  LeadRolesDistinctCountCreatedAtDesc = 'LEAD_ROLES_DISTINCT_COUNT_CREATED_AT_DESC',
  LeadRolesDistinctCountUpdatedAtAsc = 'LEAD_ROLES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LeadRolesDistinctCountUpdatedAtDesc = 'LEAD_ROLES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LeadRolesMinIdAsc = 'LEAD_ROLES_MIN_ID_ASC',
  LeadRolesMinIdDesc = 'LEAD_ROLES_MIN_ID_DESC',
  LeadRolesMinLeadIdAsc = 'LEAD_ROLES_MIN_LEAD_ID_ASC',
  LeadRolesMinLeadIdDesc = 'LEAD_ROLES_MIN_LEAD_ID_DESC',
  LeadRolesMinUserIdAsc = 'LEAD_ROLES_MIN_USER_ID_ASC',
  LeadRolesMinUserIdDesc = 'LEAD_ROLES_MIN_USER_ID_DESC',
  LeadRolesMinRoleAsc = 'LEAD_ROLES_MIN_ROLE_ASC',
  LeadRolesMinRoleDesc = 'LEAD_ROLES_MIN_ROLE_DESC',
  LeadRolesMinCreatedAtAsc = 'LEAD_ROLES_MIN_CREATED_AT_ASC',
  LeadRolesMinCreatedAtDesc = 'LEAD_ROLES_MIN_CREATED_AT_DESC',
  LeadRolesMinUpdatedAtAsc = 'LEAD_ROLES_MIN_UPDATED_AT_ASC',
  LeadRolesMinUpdatedAtDesc = 'LEAD_ROLES_MIN_UPDATED_AT_DESC',
  LeadRolesMaxIdAsc = 'LEAD_ROLES_MAX_ID_ASC',
  LeadRolesMaxIdDesc = 'LEAD_ROLES_MAX_ID_DESC',
  LeadRolesMaxLeadIdAsc = 'LEAD_ROLES_MAX_LEAD_ID_ASC',
  LeadRolesMaxLeadIdDesc = 'LEAD_ROLES_MAX_LEAD_ID_DESC',
  LeadRolesMaxUserIdAsc = 'LEAD_ROLES_MAX_USER_ID_ASC',
  LeadRolesMaxUserIdDesc = 'LEAD_ROLES_MAX_USER_ID_DESC',
  LeadRolesMaxRoleAsc = 'LEAD_ROLES_MAX_ROLE_ASC',
  LeadRolesMaxRoleDesc = 'LEAD_ROLES_MAX_ROLE_DESC',
  LeadRolesMaxCreatedAtAsc = 'LEAD_ROLES_MAX_CREATED_AT_ASC',
  LeadRolesMaxCreatedAtDesc = 'LEAD_ROLES_MAX_CREATED_AT_DESC',
  LeadRolesMaxUpdatedAtAsc = 'LEAD_ROLES_MAX_UPDATED_AT_ASC',
  LeadRolesMaxUpdatedAtDesc = 'LEAD_ROLES_MAX_UPDATED_AT_DESC',
  LeadRolesAverageIdAsc = 'LEAD_ROLES_AVERAGE_ID_ASC',
  LeadRolesAverageIdDesc = 'LEAD_ROLES_AVERAGE_ID_DESC',
  LeadRolesAverageLeadIdAsc = 'LEAD_ROLES_AVERAGE_LEAD_ID_ASC',
  LeadRolesAverageLeadIdDesc = 'LEAD_ROLES_AVERAGE_LEAD_ID_DESC',
  LeadRolesAverageUserIdAsc = 'LEAD_ROLES_AVERAGE_USER_ID_ASC',
  LeadRolesAverageUserIdDesc = 'LEAD_ROLES_AVERAGE_USER_ID_DESC',
  LeadRolesAverageRoleAsc = 'LEAD_ROLES_AVERAGE_ROLE_ASC',
  LeadRolesAverageRoleDesc = 'LEAD_ROLES_AVERAGE_ROLE_DESC',
  LeadRolesAverageCreatedAtAsc = 'LEAD_ROLES_AVERAGE_CREATED_AT_ASC',
  LeadRolesAverageCreatedAtDesc = 'LEAD_ROLES_AVERAGE_CREATED_AT_DESC',
  LeadRolesAverageUpdatedAtAsc = 'LEAD_ROLES_AVERAGE_UPDATED_AT_ASC',
  LeadRolesAverageUpdatedAtDesc = 'LEAD_ROLES_AVERAGE_UPDATED_AT_DESC',
  LeadRolesStddevSampleIdAsc = 'LEAD_ROLES_STDDEV_SAMPLE_ID_ASC',
  LeadRolesStddevSampleIdDesc = 'LEAD_ROLES_STDDEV_SAMPLE_ID_DESC',
  LeadRolesStddevSampleLeadIdAsc = 'LEAD_ROLES_STDDEV_SAMPLE_LEAD_ID_ASC',
  LeadRolesStddevSampleLeadIdDesc = 'LEAD_ROLES_STDDEV_SAMPLE_LEAD_ID_DESC',
  LeadRolesStddevSampleUserIdAsc = 'LEAD_ROLES_STDDEV_SAMPLE_USER_ID_ASC',
  LeadRolesStddevSampleUserIdDesc = 'LEAD_ROLES_STDDEV_SAMPLE_USER_ID_DESC',
  LeadRolesStddevSampleRoleAsc = 'LEAD_ROLES_STDDEV_SAMPLE_ROLE_ASC',
  LeadRolesStddevSampleRoleDesc = 'LEAD_ROLES_STDDEV_SAMPLE_ROLE_DESC',
  LeadRolesStddevSampleCreatedAtAsc = 'LEAD_ROLES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LeadRolesStddevSampleCreatedAtDesc = 'LEAD_ROLES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LeadRolesStddevSampleUpdatedAtAsc = 'LEAD_ROLES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LeadRolesStddevSampleUpdatedAtDesc = 'LEAD_ROLES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LeadRolesStddevPopulationIdAsc = 'LEAD_ROLES_STDDEV_POPULATION_ID_ASC',
  LeadRolesStddevPopulationIdDesc = 'LEAD_ROLES_STDDEV_POPULATION_ID_DESC',
  LeadRolesStddevPopulationLeadIdAsc = 'LEAD_ROLES_STDDEV_POPULATION_LEAD_ID_ASC',
  LeadRolesStddevPopulationLeadIdDesc = 'LEAD_ROLES_STDDEV_POPULATION_LEAD_ID_DESC',
  LeadRolesStddevPopulationUserIdAsc = 'LEAD_ROLES_STDDEV_POPULATION_USER_ID_ASC',
  LeadRolesStddevPopulationUserIdDesc = 'LEAD_ROLES_STDDEV_POPULATION_USER_ID_DESC',
  LeadRolesStddevPopulationRoleAsc = 'LEAD_ROLES_STDDEV_POPULATION_ROLE_ASC',
  LeadRolesStddevPopulationRoleDesc = 'LEAD_ROLES_STDDEV_POPULATION_ROLE_DESC',
  LeadRolesStddevPopulationCreatedAtAsc = 'LEAD_ROLES_STDDEV_POPULATION_CREATED_AT_ASC',
  LeadRolesStddevPopulationCreatedAtDesc = 'LEAD_ROLES_STDDEV_POPULATION_CREATED_AT_DESC',
  LeadRolesStddevPopulationUpdatedAtAsc = 'LEAD_ROLES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LeadRolesStddevPopulationUpdatedAtDesc = 'LEAD_ROLES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LeadRolesVarianceSampleIdAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_ID_ASC',
  LeadRolesVarianceSampleIdDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_ID_DESC',
  LeadRolesVarianceSampleLeadIdAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LeadRolesVarianceSampleLeadIdDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LeadRolesVarianceSampleUserIdAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_USER_ID_ASC',
  LeadRolesVarianceSampleUserIdDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_USER_ID_DESC',
  LeadRolesVarianceSampleRoleAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_ROLE_ASC',
  LeadRolesVarianceSampleRoleDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_ROLE_DESC',
  LeadRolesVarianceSampleCreatedAtAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LeadRolesVarianceSampleCreatedAtDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LeadRolesVarianceSampleUpdatedAtAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LeadRolesVarianceSampleUpdatedAtDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LeadRolesVariancePopulationIdAsc = 'LEAD_ROLES_VARIANCE_POPULATION_ID_ASC',
  LeadRolesVariancePopulationIdDesc = 'LEAD_ROLES_VARIANCE_POPULATION_ID_DESC',
  LeadRolesVariancePopulationLeadIdAsc = 'LEAD_ROLES_VARIANCE_POPULATION_LEAD_ID_ASC',
  LeadRolesVariancePopulationLeadIdDesc = 'LEAD_ROLES_VARIANCE_POPULATION_LEAD_ID_DESC',
  LeadRolesVariancePopulationUserIdAsc = 'LEAD_ROLES_VARIANCE_POPULATION_USER_ID_ASC',
  LeadRolesVariancePopulationUserIdDesc = 'LEAD_ROLES_VARIANCE_POPULATION_USER_ID_DESC',
  LeadRolesVariancePopulationRoleAsc = 'LEAD_ROLES_VARIANCE_POPULATION_ROLE_ASC',
  LeadRolesVariancePopulationRoleDesc = 'LEAD_ROLES_VARIANCE_POPULATION_ROLE_DESC',
  LeadRolesVariancePopulationCreatedAtAsc = 'LEAD_ROLES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LeadRolesVariancePopulationCreatedAtDesc = 'LEAD_ROLES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LeadRolesVariancePopulationUpdatedAtAsc = 'LEAD_ROLES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LeadRolesVariancePopulationUpdatedAtDesc = 'LEAD_ROLES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DistributedLeadsCountAsc = 'DISTRIBUTED_LEADS_COUNT_ASC',
  DistributedLeadsCountDesc = 'DISTRIBUTED_LEADS_COUNT_DESC',
  DistributedLeadsSumIdAsc = 'DISTRIBUTED_LEADS_SUM_ID_ASC',
  DistributedLeadsSumIdDesc = 'DISTRIBUTED_LEADS_SUM_ID_DESC',
  DistributedLeadsSumLeadIdAsc = 'DISTRIBUTED_LEADS_SUM_LEAD_ID_ASC',
  DistributedLeadsSumLeadIdDesc = 'DISTRIBUTED_LEADS_SUM_LEAD_ID_DESC',
  DistributedLeadsSumFromCompanyIdAsc = 'DISTRIBUTED_LEADS_SUM_FROM_COMPANY_ID_ASC',
  DistributedLeadsSumFromCompanyIdDesc = 'DISTRIBUTED_LEADS_SUM_FROM_COMPANY_ID_DESC',
  DistributedLeadsSumToCompanyIdAsc = 'DISTRIBUTED_LEADS_SUM_TO_COMPANY_ID_ASC',
  DistributedLeadsSumToCompanyIdDesc = 'DISTRIBUTED_LEADS_SUM_TO_COMPANY_ID_DESC',
  DistributedLeadsSumOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_SUM_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsSumOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_SUM_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsSumCreatedAtAsc = 'DISTRIBUTED_LEADS_SUM_CREATED_AT_ASC',
  DistributedLeadsSumCreatedAtDesc = 'DISTRIBUTED_LEADS_SUM_CREATED_AT_DESC',
  DistributedLeadsSumUpdatedAtAsc = 'DISTRIBUTED_LEADS_SUM_UPDATED_AT_ASC',
  DistributedLeadsSumUpdatedAtDesc = 'DISTRIBUTED_LEADS_SUM_UPDATED_AT_DESC',
  DistributedLeadsDistinctCountIdAsc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_ID_ASC',
  DistributedLeadsDistinctCountIdDesc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_ID_DESC',
  DistributedLeadsDistinctCountLeadIdAsc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_LEAD_ID_ASC',
  DistributedLeadsDistinctCountLeadIdDesc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_LEAD_ID_DESC',
  DistributedLeadsDistinctCountFromCompanyIdAsc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_FROM_COMPANY_ID_ASC',
  DistributedLeadsDistinctCountFromCompanyIdDesc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_FROM_COMPANY_ID_DESC',
  DistributedLeadsDistinctCountToCompanyIdAsc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_TO_COMPANY_ID_ASC',
  DistributedLeadsDistinctCountToCompanyIdDesc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_TO_COMPANY_ID_DESC',
  DistributedLeadsDistinctCountOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsDistinctCountOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsDistinctCountCreatedAtAsc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_CREATED_AT_ASC',
  DistributedLeadsDistinctCountCreatedAtDesc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_CREATED_AT_DESC',
  DistributedLeadsDistinctCountUpdatedAtAsc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_UPDATED_AT_ASC',
  DistributedLeadsDistinctCountUpdatedAtDesc = 'DISTRIBUTED_LEADS_DISTINCT_COUNT_UPDATED_AT_DESC',
  DistributedLeadsMinIdAsc = 'DISTRIBUTED_LEADS_MIN_ID_ASC',
  DistributedLeadsMinIdDesc = 'DISTRIBUTED_LEADS_MIN_ID_DESC',
  DistributedLeadsMinLeadIdAsc = 'DISTRIBUTED_LEADS_MIN_LEAD_ID_ASC',
  DistributedLeadsMinLeadIdDesc = 'DISTRIBUTED_LEADS_MIN_LEAD_ID_DESC',
  DistributedLeadsMinFromCompanyIdAsc = 'DISTRIBUTED_LEADS_MIN_FROM_COMPANY_ID_ASC',
  DistributedLeadsMinFromCompanyIdDesc = 'DISTRIBUTED_LEADS_MIN_FROM_COMPANY_ID_DESC',
  DistributedLeadsMinToCompanyIdAsc = 'DISTRIBUTED_LEADS_MIN_TO_COMPANY_ID_ASC',
  DistributedLeadsMinToCompanyIdDesc = 'DISTRIBUTED_LEADS_MIN_TO_COMPANY_ID_DESC',
  DistributedLeadsMinOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_MIN_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsMinOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_MIN_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsMinCreatedAtAsc = 'DISTRIBUTED_LEADS_MIN_CREATED_AT_ASC',
  DistributedLeadsMinCreatedAtDesc = 'DISTRIBUTED_LEADS_MIN_CREATED_AT_DESC',
  DistributedLeadsMinUpdatedAtAsc = 'DISTRIBUTED_LEADS_MIN_UPDATED_AT_ASC',
  DistributedLeadsMinUpdatedAtDesc = 'DISTRIBUTED_LEADS_MIN_UPDATED_AT_DESC',
  DistributedLeadsMaxIdAsc = 'DISTRIBUTED_LEADS_MAX_ID_ASC',
  DistributedLeadsMaxIdDesc = 'DISTRIBUTED_LEADS_MAX_ID_DESC',
  DistributedLeadsMaxLeadIdAsc = 'DISTRIBUTED_LEADS_MAX_LEAD_ID_ASC',
  DistributedLeadsMaxLeadIdDesc = 'DISTRIBUTED_LEADS_MAX_LEAD_ID_DESC',
  DistributedLeadsMaxFromCompanyIdAsc = 'DISTRIBUTED_LEADS_MAX_FROM_COMPANY_ID_ASC',
  DistributedLeadsMaxFromCompanyIdDesc = 'DISTRIBUTED_LEADS_MAX_FROM_COMPANY_ID_DESC',
  DistributedLeadsMaxToCompanyIdAsc = 'DISTRIBUTED_LEADS_MAX_TO_COMPANY_ID_ASC',
  DistributedLeadsMaxToCompanyIdDesc = 'DISTRIBUTED_LEADS_MAX_TO_COMPANY_ID_DESC',
  DistributedLeadsMaxOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_MAX_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsMaxOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_MAX_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsMaxCreatedAtAsc = 'DISTRIBUTED_LEADS_MAX_CREATED_AT_ASC',
  DistributedLeadsMaxCreatedAtDesc = 'DISTRIBUTED_LEADS_MAX_CREATED_AT_DESC',
  DistributedLeadsMaxUpdatedAtAsc = 'DISTRIBUTED_LEADS_MAX_UPDATED_AT_ASC',
  DistributedLeadsMaxUpdatedAtDesc = 'DISTRIBUTED_LEADS_MAX_UPDATED_AT_DESC',
  DistributedLeadsAverageIdAsc = 'DISTRIBUTED_LEADS_AVERAGE_ID_ASC',
  DistributedLeadsAverageIdDesc = 'DISTRIBUTED_LEADS_AVERAGE_ID_DESC',
  DistributedLeadsAverageLeadIdAsc = 'DISTRIBUTED_LEADS_AVERAGE_LEAD_ID_ASC',
  DistributedLeadsAverageLeadIdDesc = 'DISTRIBUTED_LEADS_AVERAGE_LEAD_ID_DESC',
  DistributedLeadsAverageFromCompanyIdAsc = 'DISTRIBUTED_LEADS_AVERAGE_FROM_COMPANY_ID_ASC',
  DistributedLeadsAverageFromCompanyIdDesc = 'DISTRIBUTED_LEADS_AVERAGE_FROM_COMPANY_ID_DESC',
  DistributedLeadsAverageToCompanyIdAsc = 'DISTRIBUTED_LEADS_AVERAGE_TO_COMPANY_ID_ASC',
  DistributedLeadsAverageToCompanyIdDesc = 'DISTRIBUTED_LEADS_AVERAGE_TO_COMPANY_ID_DESC',
  DistributedLeadsAverageOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_AVERAGE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsAverageOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_AVERAGE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsAverageCreatedAtAsc = 'DISTRIBUTED_LEADS_AVERAGE_CREATED_AT_ASC',
  DistributedLeadsAverageCreatedAtDesc = 'DISTRIBUTED_LEADS_AVERAGE_CREATED_AT_DESC',
  DistributedLeadsAverageUpdatedAtAsc = 'DISTRIBUTED_LEADS_AVERAGE_UPDATED_AT_ASC',
  DistributedLeadsAverageUpdatedAtDesc = 'DISTRIBUTED_LEADS_AVERAGE_UPDATED_AT_DESC',
  DistributedLeadsStddevSampleIdAsc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_ID_ASC',
  DistributedLeadsStddevSampleIdDesc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_ID_DESC',
  DistributedLeadsStddevSampleLeadIdAsc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_LEAD_ID_ASC',
  DistributedLeadsStddevSampleLeadIdDesc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_LEAD_ID_DESC',
  DistributedLeadsStddevSampleFromCompanyIdAsc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_FROM_COMPANY_ID_ASC',
  DistributedLeadsStddevSampleFromCompanyIdDesc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_FROM_COMPANY_ID_DESC',
  DistributedLeadsStddevSampleToCompanyIdAsc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_TO_COMPANY_ID_ASC',
  DistributedLeadsStddevSampleToCompanyIdDesc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_TO_COMPANY_ID_DESC',
  DistributedLeadsStddevSampleOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsStddevSampleOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsStddevSampleCreatedAtAsc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_CREATED_AT_ASC',
  DistributedLeadsStddevSampleCreatedAtDesc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_CREATED_AT_DESC',
  DistributedLeadsStddevSampleUpdatedAtAsc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DistributedLeadsStddevSampleUpdatedAtDesc = 'DISTRIBUTED_LEADS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DistributedLeadsStddevPopulationIdAsc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_ID_ASC',
  DistributedLeadsStddevPopulationIdDesc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_ID_DESC',
  DistributedLeadsStddevPopulationLeadIdAsc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_LEAD_ID_ASC',
  DistributedLeadsStddevPopulationLeadIdDesc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_LEAD_ID_DESC',
  DistributedLeadsStddevPopulationFromCompanyIdAsc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_FROM_COMPANY_ID_ASC',
  DistributedLeadsStddevPopulationFromCompanyIdDesc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_FROM_COMPANY_ID_DESC',
  DistributedLeadsStddevPopulationToCompanyIdAsc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_TO_COMPANY_ID_ASC',
  DistributedLeadsStddevPopulationToCompanyIdDesc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_TO_COMPANY_ID_DESC',
  DistributedLeadsStddevPopulationOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsStddevPopulationOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsStddevPopulationCreatedAtAsc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_CREATED_AT_ASC',
  DistributedLeadsStddevPopulationCreatedAtDesc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_CREATED_AT_DESC',
  DistributedLeadsStddevPopulationUpdatedAtAsc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_UPDATED_AT_ASC',
  DistributedLeadsStddevPopulationUpdatedAtDesc = 'DISTRIBUTED_LEADS_STDDEV_POPULATION_UPDATED_AT_DESC',
  DistributedLeadsVarianceSampleIdAsc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_ID_ASC',
  DistributedLeadsVarianceSampleIdDesc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_ID_DESC',
  DistributedLeadsVarianceSampleLeadIdAsc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  DistributedLeadsVarianceSampleLeadIdDesc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  DistributedLeadsVarianceSampleFromCompanyIdAsc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_FROM_COMPANY_ID_ASC',
  DistributedLeadsVarianceSampleFromCompanyIdDesc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_FROM_COMPANY_ID_DESC',
  DistributedLeadsVarianceSampleToCompanyIdAsc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_TO_COMPANY_ID_ASC',
  DistributedLeadsVarianceSampleToCompanyIdDesc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_TO_COMPANY_ID_DESC',
  DistributedLeadsVarianceSampleOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsVarianceSampleOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsVarianceSampleCreatedAtAsc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DistributedLeadsVarianceSampleCreatedAtDesc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DistributedLeadsVarianceSampleUpdatedAtAsc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DistributedLeadsVarianceSampleUpdatedAtDesc = 'DISTRIBUTED_LEADS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DistributedLeadsVariancePopulationIdAsc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_ID_ASC',
  DistributedLeadsVariancePopulationIdDesc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_ID_DESC',
  DistributedLeadsVariancePopulationLeadIdAsc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_LEAD_ID_ASC',
  DistributedLeadsVariancePopulationLeadIdDesc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_LEAD_ID_DESC',
  DistributedLeadsVariancePopulationFromCompanyIdAsc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_FROM_COMPANY_ID_ASC',
  DistributedLeadsVariancePopulationFromCompanyIdDesc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_FROM_COMPANY_ID_DESC',
  DistributedLeadsVariancePopulationToCompanyIdAsc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_TO_COMPANY_ID_ASC',
  DistributedLeadsVariancePopulationToCompanyIdDesc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_TO_COMPANY_ID_DESC',
  DistributedLeadsVariancePopulationOriginalCompanyChannelIdAsc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_ASC',
  DistributedLeadsVariancePopulationOriginalCompanyChannelIdDesc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_ORIGINAL_COMPANY_CHANNEL_ID_DESC',
  DistributedLeadsVariancePopulationCreatedAtAsc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_CREATED_AT_ASC',
  DistributedLeadsVariancePopulationCreatedAtDesc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_CREATED_AT_DESC',
  DistributedLeadsVariancePopulationUpdatedAtAsc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DistributedLeadsVariancePopulationUpdatedAtDesc = 'DISTRIBUTED_LEADS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  AuditLogsCountAsc = 'AUDIT_LOGS_COUNT_ASC',
  AuditLogsCountDesc = 'AUDIT_LOGS_COUNT_DESC',
  AuditLogsSumEventIdAsc = 'AUDIT_LOGS_SUM_EVENT_ID_ASC',
  AuditLogsSumEventIdDesc = 'AUDIT_LOGS_SUM_EVENT_ID_DESC',
  AuditLogsSumParentTypeAsc = 'AUDIT_LOGS_SUM_PARENT_TYPE_ASC',
  AuditLogsSumParentTypeDesc = 'AUDIT_LOGS_SUM_PARENT_TYPE_DESC',
  AuditLogsSumParentIdAsc = 'AUDIT_LOGS_SUM_PARENT_ID_ASC',
  AuditLogsSumParentIdDesc = 'AUDIT_LOGS_SUM_PARENT_ID_DESC',
  AuditLogsSumEventTypeAsc = 'AUDIT_LOGS_SUM_EVENT_TYPE_ASC',
  AuditLogsSumEventTypeDesc = 'AUDIT_LOGS_SUM_EVENT_TYPE_DESC',
  AuditLogsSumDbRoleAsc = 'AUDIT_LOGS_SUM_DB_ROLE_ASC',
  AuditLogsSumDbRoleDesc = 'AUDIT_LOGS_SUM_DB_ROLE_DESC',
  AuditLogsSumUserIdAsc = 'AUDIT_LOGS_SUM_USER_ID_ASC',
  AuditLogsSumUserIdDesc = 'AUDIT_LOGS_SUM_USER_ID_DESC',
  AuditLogsSumEmailAsc = 'AUDIT_LOGS_SUM_EMAIL_ASC',
  AuditLogsSumEmailDesc = 'AUDIT_LOGS_SUM_EMAIL_DESC',
  AuditLogsSumOldValueAsc = 'AUDIT_LOGS_SUM_OLD_VALUE_ASC',
  AuditLogsSumOldValueDesc = 'AUDIT_LOGS_SUM_OLD_VALUE_DESC',
  AuditLogsSumNewValueAsc = 'AUDIT_LOGS_SUM_NEW_VALUE_ASC',
  AuditLogsSumNewValueDesc = 'AUDIT_LOGS_SUM_NEW_VALUE_DESC',
  AuditLogsSumEventTimestampAsc = 'AUDIT_LOGS_SUM_EVENT_TIMESTAMP_ASC',
  AuditLogsSumEventTimestampDesc = 'AUDIT_LOGS_SUM_EVENT_TIMESTAMP_DESC',
  AuditLogsSumLeadIdAsc = 'AUDIT_LOGS_SUM_LEAD_ID_ASC',
  AuditLogsSumLeadIdDesc = 'AUDIT_LOGS_SUM_LEAD_ID_DESC',
  AuditLogsDistinctCountEventIdAsc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_ID_ASC',
  AuditLogsDistinctCountEventIdDesc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_ID_DESC',
  AuditLogsDistinctCountParentTypeAsc = 'AUDIT_LOGS_DISTINCT_COUNT_PARENT_TYPE_ASC',
  AuditLogsDistinctCountParentTypeDesc = 'AUDIT_LOGS_DISTINCT_COUNT_PARENT_TYPE_DESC',
  AuditLogsDistinctCountParentIdAsc = 'AUDIT_LOGS_DISTINCT_COUNT_PARENT_ID_ASC',
  AuditLogsDistinctCountParentIdDesc = 'AUDIT_LOGS_DISTINCT_COUNT_PARENT_ID_DESC',
  AuditLogsDistinctCountEventTypeAsc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_TYPE_ASC',
  AuditLogsDistinctCountEventTypeDesc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_TYPE_DESC',
  AuditLogsDistinctCountDbRoleAsc = 'AUDIT_LOGS_DISTINCT_COUNT_DB_ROLE_ASC',
  AuditLogsDistinctCountDbRoleDesc = 'AUDIT_LOGS_DISTINCT_COUNT_DB_ROLE_DESC',
  AuditLogsDistinctCountUserIdAsc = 'AUDIT_LOGS_DISTINCT_COUNT_USER_ID_ASC',
  AuditLogsDistinctCountUserIdDesc = 'AUDIT_LOGS_DISTINCT_COUNT_USER_ID_DESC',
  AuditLogsDistinctCountEmailAsc = 'AUDIT_LOGS_DISTINCT_COUNT_EMAIL_ASC',
  AuditLogsDistinctCountEmailDesc = 'AUDIT_LOGS_DISTINCT_COUNT_EMAIL_DESC',
  AuditLogsDistinctCountOldValueAsc = 'AUDIT_LOGS_DISTINCT_COUNT_OLD_VALUE_ASC',
  AuditLogsDistinctCountOldValueDesc = 'AUDIT_LOGS_DISTINCT_COUNT_OLD_VALUE_DESC',
  AuditLogsDistinctCountNewValueAsc = 'AUDIT_LOGS_DISTINCT_COUNT_NEW_VALUE_ASC',
  AuditLogsDistinctCountNewValueDesc = 'AUDIT_LOGS_DISTINCT_COUNT_NEW_VALUE_DESC',
  AuditLogsDistinctCountEventTimestampAsc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_TIMESTAMP_ASC',
  AuditLogsDistinctCountEventTimestampDesc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_TIMESTAMP_DESC',
  AuditLogsDistinctCountLeadIdAsc = 'AUDIT_LOGS_DISTINCT_COUNT_LEAD_ID_ASC',
  AuditLogsDistinctCountLeadIdDesc = 'AUDIT_LOGS_DISTINCT_COUNT_LEAD_ID_DESC',
  AuditLogsMinEventIdAsc = 'AUDIT_LOGS_MIN_EVENT_ID_ASC',
  AuditLogsMinEventIdDesc = 'AUDIT_LOGS_MIN_EVENT_ID_DESC',
  AuditLogsMinParentTypeAsc = 'AUDIT_LOGS_MIN_PARENT_TYPE_ASC',
  AuditLogsMinParentTypeDesc = 'AUDIT_LOGS_MIN_PARENT_TYPE_DESC',
  AuditLogsMinParentIdAsc = 'AUDIT_LOGS_MIN_PARENT_ID_ASC',
  AuditLogsMinParentIdDesc = 'AUDIT_LOGS_MIN_PARENT_ID_DESC',
  AuditLogsMinEventTypeAsc = 'AUDIT_LOGS_MIN_EVENT_TYPE_ASC',
  AuditLogsMinEventTypeDesc = 'AUDIT_LOGS_MIN_EVENT_TYPE_DESC',
  AuditLogsMinDbRoleAsc = 'AUDIT_LOGS_MIN_DB_ROLE_ASC',
  AuditLogsMinDbRoleDesc = 'AUDIT_LOGS_MIN_DB_ROLE_DESC',
  AuditLogsMinUserIdAsc = 'AUDIT_LOGS_MIN_USER_ID_ASC',
  AuditLogsMinUserIdDesc = 'AUDIT_LOGS_MIN_USER_ID_DESC',
  AuditLogsMinEmailAsc = 'AUDIT_LOGS_MIN_EMAIL_ASC',
  AuditLogsMinEmailDesc = 'AUDIT_LOGS_MIN_EMAIL_DESC',
  AuditLogsMinOldValueAsc = 'AUDIT_LOGS_MIN_OLD_VALUE_ASC',
  AuditLogsMinOldValueDesc = 'AUDIT_LOGS_MIN_OLD_VALUE_DESC',
  AuditLogsMinNewValueAsc = 'AUDIT_LOGS_MIN_NEW_VALUE_ASC',
  AuditLogsMinNewValueDesc = 'AUDIT_LOGS_MIN_NEW_VALUE_DESC',
  AuditLogsMinEventTimestampAsc = 'AUDIT_LOGS_MIN_EVENT_TIMESTAMP_ASC',
  AuditLogsMinEventTimestampDesc = 'AUDIT_LOGS_MIN_EVENT_TIMESTAMP_DESC',
  AuditLogsMinLeadIdAsc = 'AUDIT_LOGS_MIN_LEAD_ID_ASC',
  AuditLogsMinLeadIdDesc = 'AUDIT_LOGS_MIN_LEAD_ID_DESC',
  AuditLogsMaxEventIdAsc = 'AUDIT_LOGS_MAX_EVENT_ID_ASC',
  AuditLogsMaxEventIdDesc = 'AUDIT_LOGS_MAX_EVENT_ID_DESC',
  AuditLogsMaxParentTypeAsc = 'AUDIT_LOGS_MAX_PARENT_TYPE_ASC',
  AuditLogsMaxParentTypeDesc = 'AUDIT_LOGS_MAX_PARENT_TYPE_DESC',
  AuditLogsMaxParentIdAsc = 'AUDIT_LOGS_MAX_PARENT_ID_ASC',
  AuditLogsMaxParentIdDesc = 'AUDIT_LOGS_MAX_PARENT_ID_DESC',
  AuditLogsMaxEventTypeAsc = 'AUDIT_LOGS_MAX_EVENT_TYPE_ASC',
  AuditLogsMaxEventTypeDesc = 'AUDIT_LOGS_MAX_EVENT_TYPE_DESC',
  AuditLogsMaxDbRoleAsc = 'AUDIT_LOGS_MAX_DB_ROLE_ASC',
  AuditLogsMaxDbRoleDesc = 'AUDIT_LOGS_MAX_DB_ROLE_DESC',
  AuditLogsMaxUserIdAsc = 'AUDIT_LOGS_MAX_USER_ID_ASC',
  AuditLogsMaxUserIdDesc = 'AUDIT_LOGS_MAX_USER_ID_DESC',
  AuditLogsMaxEmailAsc = 'AUDIT_LOGS_MAX_EMAIL_ASC',
  AuditLogsMaxEmailDesc = 'AUDIT_LOGS_MAX_EMAIL_DESC',
  AuditLogsMaxOldValueAsc = 'AUDIT_LOGS_MAX_OLD_VALUE_ASC',
  AuditLogsMaxOldValueDesc = 'AUDIT_LOGS_MAX_OLD_VALUE_DESC',
  AuditLogsMaxNewValueAsc = 'AUDIT_LOGS_MAX_NEW_VALUE_ASC',
  AuditLogsMaxNewValueDesc = 'AUDIT_LOGS_MAX_NEW_VALUE_DESC',
  AuditLogsMaxEventTimestampAsc = 'AUDIT_LOGS_MAX_EVENT_TIMESTAMP_ASC',
  AuditLogsMaxEventTimestampDesc = 'AUDIT_LOGS_MAX_EVENT_TIMESTAMP_DESC',
  AuditLogsMaxLeadIdAsc = 'AUDIT_LOGS_MAX_LEAD_ID_ASC',
  AuditLogsMaxLeadIdDesc = 'AUDIT_LOGS_MAX_LEAD_ID_DESC',
  AuditLogsAverageEventIdAsc = 'AUDIT_LOGS_AVERAGE_EVENT_ID_ASC',
  AuditLogsAverageEventIdDesc = 'AUDIT_LOGS_AVERAGE_EVENT_ID_DESC',
  AuditLogsAverageParentTypeAsc = 'AUDIT_LOGS_AVERAGE_PARENT_TYPE_ASC',
  AuditLogsAverageParentTypeDesc = 'AUDIT_LOGS_AVERAGE_PARENT_TYPE_DESC',
  AuditLogsAverageParentIdAsc = 'AUDIT_LOGS_AVERAGE_PARENT_ID_ASC',
  AuditLogsAverageParentIdDesc = 'AUDIT_LOGS_AVERAGE_PARENT_ID_DESC',
  AuditLogsAverageEventTypeAsc = 'AUDIT_LOGS_AVERAGE_EVENT_TYPE_ASC',
  AuditLogsAverageEventTypeDesc = 'AUDIT_LOGS_AVERAGE_EVENT_TYPE_DESC',
  AuditLogsAverageDbRoleAsc = 'AUDIT_LOGS_AVERAGE_DB_ROLE_ASC',
  AuditLogsAverageDbRoleDesc = 'AUDIT_LOGS_AVERAGE_DB_ROLE_DESC',
  AuditLogsAverageUserIdAsc = 'AUDIT_LOGS_AVERAGE_USER_ID_ASC',
  AuditLogsAverageUserIdDesc = 'AUDIT_LOGS_AVERAGE_USER_ID_DESC',
  AuditLogsAverageEmailAsc = 'AUDIT_LOGS_AVERAGE_EMAIL_ASC',
  AuditLogsAverageEmailDesc = 'AUDIT_LOGS_AVERAGE_EMAIL_DESC',
  AuditLogsAverageOldValueAsc = 'AUDIT_LOGS_AVERAGE_OLD_VALUE_ASC',
  AuditLogsAverageOldValueDesc = 'AUDIT_LOGS_AVERAGE_OLD_VALUE_DESC',
  AuditLogsAverageNewValueAsc = 'AUDIT_LOGS_AVERAGE_NEW_VALUE_ASC',
  AuditLogsAverageNewValueDesc = 'AUDIT_LOGS_AVERAGE_NEW_VALUE_DESC',
  AuditLogsAverageEventTimestampAsc = 'AUDIT_LOGS_AVERAGE_EVENT_TIMESTAMP_ASC',
  AuditLogsAverageEventTimestampDesc = 'AUDIT_LOGS_AVERAGE_EVENT_TIMESTAMP_DESC',
  AuditLogsAverageLeadIdAsc = 'AUDIT_LOGS_AVERAGE_LEAD_ID_ASC',
  AuditLogsAverageLeadIdDesc = 'AUDIT_LOGS_AVERAGE_LEAD_ID_DESC',
  AuditLogsStddevSampleEventIdAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_ID_ASC',
  AuditLogsStddevSampleEventIdDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_ID_DESC',
  AuditLogsStddevSampleParentTypeAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_PARENT_TYPE_ASC',
  AuditLogsStddevSampleParentTypeDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_PARENT_TYPE_DESC',
  AuditLogsStddevSampleParentIdAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_PARENT_ID_ASC',
  AuditLogsStddevSampleParentIdDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_PARENT_ID_DESC',
  AuditLogsStddevSampleEventTypeAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  AuditLogsStddevSampleEventTypeDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  AuditLogsStddevSampleDbRoleAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_DB_ROLE_ASC',
  AuditLogsStddevSampleDbRoleDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_DB_ROLE_DESC',
  AuditLogsStddevSampleUserIdAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_USER_ID_ASC',
  AuditLogsStddevSampleUserIdDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_USER_ID_DESC',
  AuditLogsStddevSampleEmailAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_EMAIL_ASC',
  AuditLogsStddevSampleEmailDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_EMAIL_DESC',
  AuditLogsStddevSampleOldValueAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_OLD_VALUE_ASC',
  AuditLogsStddevSampleOldValueDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_OLD_VALUE_DESC',
  AuditLogsStddevSampleNewValueAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_NEW_VALUE_ASC',
  AuditLogsStddevSampleNewValueDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_NEW_VALUE_DESC',
  AuditLogsStddevSampleEventTimestampAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_TIMESTAMP_ASC',
  AuditLogsStddevSampleEventTimestampDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_TIMESTAMP_DESC',
  AuditLogsStddevSampleLeadIdAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_LEAD_ID_ASC',
  AuditLogsStddevSampleLeadIdDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_LEAD_ID_DESC',
  AuditLogsStddevPopulationEventIdAsc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_ID_ASC',
  AuditLogsStddevPopulationEventIdDesc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_ID_DESC',
  AuditLogsStddevPopulationParentTypeAsc = 'AUDIT_LOGS_STDDEV_POPULATION_PARENT_TYPE_ASC',
  AuditLogsStddevPopulationParentTypeDesc = 'AUDIT_LOGS_STDDEV_POPULATION_PARENT_TYPE_DESC',
  AuditLogsStddevPopulationParentIdAsc = 'AUDIT_LOGS_STDDEV_POPULATION_PARENT_ID_ASC',
  AuditLogsStddevPopulationParentIdDesc = 'AUDIT_LOGS_STDDEV_POPULATION_PARENT_ID_DESC',
  AuditLogsStddevPopulationEventTypeAsc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_TYPE_ASC',
  AuditLogsStddevPopulationEventTypeDesc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_TYPE_DESC',
  AuditLogsStddevPopulationDbRoleAsc = 'AUDIT_LOGS_STDDEV_POPULATION_DB_ROLE_ASC',
  AuditLogsStddevPopulationDbRoleDesc = 'AUDIT_LOGS_STDDEV_POPULATION_DB_ROLE_DESC',
  AuditLogsStddevPopulationUserIdAsc = 'AUDIT_LOGS_STDDEV_POPULATION_USER_ID_ASC',
  AuditLogsStddevPopulationUserIdDesc = 'AUDIT_LOGS_STDDEV_POPULATION_USER_ID_DESC',
  AuditLogsStddevPopulationEmailAsc = 'AUDIT_LOGS_STDDEV_POPULATION_EMAIL_ASC',
  AuditLogsStddevPopulationEmailDesc = 'AUDIT_LOGS_STDDEV_POPULATION_EMAIL_DESC',
  AuditLogsStddevPopulationOldValueAsc = 'AUDIT_LOGS_STDDEV_POPULATION_OLD_VALUE_ASC',
  AuditLogsStddevPopulationOldValueDesc = 'AUDIT_LOGS_STDDEV_POPULATION_OLD_VALUE_DESC',
  AuditLogsStddevPopulationNewValueAsc = 'AUDIT_LOGS_STDDEV_POPULATION_NEW_VALUE_ASC',
  AuditLogsStddevPopulationNewValueDesc = 'AUDIT_LOGS_STDDEV_POPULATION_NEW_VALUE_DESC',
  AuditLogsStddevPopulationEventTimestampAsc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_TIMESTAMP_ASC',
  AuditLogsStddevPopulationEventTimestampDesc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_TIMESTAMP_DESC',
  AuditLogsStddevPopulationLeadIdAsc = 'AUDIT_LOGS_STDDEV_POPULATION_LEAD_ID_ASC',
  AuditLogsStddevPopulationLeadIdDesc = 'AUDIT_LOGS_STDDEV_POPULATION_LEAD_ID_DESC',
  AuditLogsVarianceSampleEventIdAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_ID_ASC',
  AuditLogsVarianceSampleEventIdDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_ID_DESC',
  AuditLogsVarianceSampleParentTypeAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_PARENT_TYPE_ASC',
  AuditLogsVarianceSampleParentTypeDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_PARENT_TYPE_DESC',
  AuditLogsVarianceSampleParentIdAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_PARENT_ID_ASC',
  AuditLogsVarianceSampleParentIdDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_PARENT_ID_DESC',
  AuditLogsVarianceSampleEventTypeAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  AuditLogsVarianceSampleEventTypeDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  AuditLogsVarianceSampleDbRoleAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_DB_ROLE_ASC',
  AuditLogsVarianceSampleDbRoleDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_DB_ROLE_DESC',
  AuditLogsVarianceSampleUserIdAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_USER_ID_ASC',
  AuditLogsVarianceSampleUserIdDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_USER_ID_DESC',
  AuditLogsVarianceSampleEmailAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EMAIL_ASC',
  AuditLogsVarianceSampleEmailDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EMAIL_DESC',
  AuditLogsVarianceSampleOldValueAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_OLD_VALUE_ASC',
  AuditLogsVarianceSampleOldValueDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_OLD_VALUE_DESC',
  AuditLogsVarianceSampleNewValueAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_NEW_VALUE_ASC',
  AuditLogsVarianceSampleNewValueDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_NEW_VALUE_DESC',
  AuditLogsVarianceSampleEventTimestampAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_TIMESTAMP_ASC',
  AuditLogsVarianceSampleEventTimestampDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_TIMESTAMP_DESC',
  AuditLogsVarianceSampleLeadIdAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  AuditLogsVarianceSampleLeadIdDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  AuditLogsVariancePopulationEventIdAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_ID_ASC',
  AuditLogsVariancePopulationEventIdDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_ID_DESC',
  AuditLogsVariancePopulationParentTypeAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_PARENT_TYPE_ASC',
  AuditLogsVariancePopulationParentTypeDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_PARENT_TYPE_DESC',
  AuditLogsVariancePopulationParentIdAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_PARENT_ID_ASC',
  AuditLogsVariancePopulationParentIdDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_PARENT_ID_DESC',
  AuditLogsVariancePopulationEventTypeAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  AuditLogsVariancePopulationEventTypeDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  AuditLogsVariancePopulationDbRoleAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_DB_ROLE_ASC',
  AuditLogsVariancePopulationDbRoleDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_DB_ROLE_DESC',
  AuditLogsVariancePopulationUserIdAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_USER_ID_ASC',
  AuditLogsVariancePopulationUserIdDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_USER_ID_DESC',
  AuditLogsVariancePopulationEmailAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_EMAIL_ASC',
  AuditLogsVariancePopulationEmailDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_EMAIL_DESC',
  AuditLogsVariancePopulationOldValueAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_OLD_VALUE_ASC',
  AuditLogsVariancePopulationOldValueDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_OLD_VALUE_DESC',
  AuditLogsVariancePopulationNewValueAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_NEW_VALUE_ASC',
  AuditLogsVariancePopulationNewValueDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_NEW_VALUE_DESC',
  AuditLogsVariancePopulationEventTimestampAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_TIMESTAMP_ASC',
  AuditLogsVariancePopulationEventTimestampDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_TIMESTAMP_DESC',
  AuditLogsVariancePopulationLeadIdAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_LEAD_ID_ASC',
  AuditLogsVariancePopulationLeadIdDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_LEAD_ID_DESC',
  IdInquiriesCountAsc = 'ID_INQUIRIES_COUNT_ASC',
  IdInquiriesCountDesc = 'ID_INQUIRIES_COUNT_DESC',
  IdInquiriesSumIdAsc = 'ID_INQUIRIES_SUM_ID_ASC',
  IdInquiriesSumIdDesc = 'ID_INQUIRIES_SUM_ID_DESC',
  IdInquiriesSumLeadIdAsc = 'ID_INQUIRIES_SUM_LEAD_ID_ASC',
  IdInquiriesSumLeadIdDesc = 'ID_INQUIRIES_SUM_LEAD_ID_DESC',
  IdInquiriesSumIntegrationIdAsc = 'ID_INQUIRIES_SUM_INTEGRATION_ID_ASC',
  IdInquiriesSumIntegrationIdDesc = 'ID_INQUIRIES_SUM_INTEGRATION_ID_DESC',
  IdInquiriesSumStatusAsc = 'ID_INQUIRIES_SUM_STATUS_ASC',
  IdInquiriesSumStatusDesc = 'ID_INQUIRIES_SUM_STATUS_DESC',
  IdInquiriesSumFirstNameAsc = 'ID_INQUIRIES_SUM_FIRST_NAME_ASC',
  IdInquiriesSumFirstNameDesc = 'ID_INQUIRIES_SUM_FIRST_NAME_DESC',
  IdInquiriesSumLastNameAsc = 'ID_INQUIRIES_SUM_LAST_NAME_ASC',
  IdInquiriesSumLastNameDesc = 'ID_INQUIRIES_SUM_LAST_NAME_DESC',
  IdInquiriesSumAddressAsc = 'ID_INQUIRIES_SUM_ADDRESS_ASC',
  IdInquiriesSumAddressDesc = 'ID_INQUIRIES_SUM_ADDRESS_DESC',
  IdInquiriesSumCityAsc = 'ID_INQUIRIES_SUM_CITY_ASC',
  IdInquiriesSumCityDesc = 'ID_INQUIRIES_SUM_CITY_DESC',
  IdInquiriesSumProvinceAsc = 'ID_INQUIRIES_SUM_PROVINCE_ASC',
  IdInquiriesSumProvinceDesc = 'ID_INQUIRIES_SUM_PROVINCE_DESC',
  IdInquiriesSumPostalCodeAsc = 'ID_INQUIRIES_SUM_POSTAL_CODE_ASC',
  IdInquiriesSumPostalCodeDesc = 'ID_INQUIRIES_SUM_POSTAL_CODE_DESC',
  IdInquiriesSumBirthDateAsc = 'ID_INQUIRIES_SUM_BIRTH_DATE_ASC',
  IdInquiriesSumBirthDateDesc = 'ID_INQUIRIES_SUM_BIRTH_DATE_DESC',
  IdInquiriesSumIdNumberAsc = 'ID_INQUIRIES_SUM_ID_NUMBER_ASC',
  IdInquiriesSumIdNumberDesc = 'ID_INQUIRIES_SUM_ID_NUMBER_DESC',
  IdInquiriesSumCreatedAtAsc = 'ID_INQUIRIES_SUM_CREATED_AT_ASC',
  IdInquiriesSumCreatedAtDesc = 'ID_INQUIRIES_SUM_CREATED_AT_DESC',
  IdInquiriesSumUpdatedAtAsc = 'ID_INQUIRIES_SUM_UPDATED_AT_ASC',
  IdInquiriesSumUpdatedAtDesc = 'ID_INQUIRIES_SUM_UPDATED_AT_DESC',
  IdInquiriesSumRawInquiryAsc = 'ID_INQUIRIES_SUM_RAW_INQUIRY_ASC',
  IdInquiriesSumRawInquiryDesc = 'ID_INQUIRIES_SUM_RAW_INQUIRY_DESC',
  IdInquiriesSumInquiryIdAsc = 'ID_INQUIRIES_SUM_INQUIRY_ID_ASC',
  IdInquiriesSumInquiryIdDesc = 'ID_INQUIRIES_SUM_INQUIRY_ID_DESC',
  IdInquiriesDistinctCountIdAsc = 'ID_INQUIRIES_DISTINCT_COUNT_ID_ASC',
  IdInquiriesDistinctCountIdDesc = 'ID_INQUIRIES_DISTINCT_COUNT_ID_DESC',
  IdInquiriesDistinctCountLeadIdAsc = 'ID_INQUIRIES_DISTINCT_COUNT_LEAD_ID_ASC',
  IdInquiriesDistinctCountLeadIdDesc = 'ID_INQUIRIES_DISTINCT_COUNT_LEAD_ID_DESC',
  IdInquiriesDistinctCountIntegrationIdAsc = 'ID_INQUIRIES_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  IdInquiriesDistinctCountIntegrationIdDesc = 'ID_INQUIRIES_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  IdInquiriesDistinctCountStatusAsc = 'ID_INQUIRIES_DISTINCT_COUNT_STATUS_ASC',
  IdInquiriesDistinctCountStatusDesc = 'ID_INQUIRIES_DISTINCT_COUNT_STATUS_DESC',
  IdInquiriesDistinctCountFirstNameAsc = 'ID_INQUIRIES_DISTINCT_COUNT_FIRST_NAME_ASC',
  IdInquiriesDistinctCountFirstNameDesc = 'ID_INQUIRIES_DISTINCT_COUNT_FIRST_NAME_DESC',
  IdInquiriesDistinctCountLastNameAsc = 'ID_INQUIRIES_DISTINCT_COUNT_LAST_NAME_ASC',
  IdInquiriesDistinctCountLastNameDesc = 'ID_INQUIRIES_DISTINCT_COUNT_LAST_NAME_DESC',
  IdInquiriesDistinctCountAddressAsc = 'ID_INQUIRIES_DISTINCT_COUNT_ADDRESS_ASC',
  IdInquiriesDistinctCountAddressDesc = 'ID_INQUIRIES_DISTINCT_COUNT_ADDRESS_DESC',
  IdInquiriesDistinctCountCityAsc = 'ID_INQUIRIES_DISTINCT_COUNT_CITY_ASC',
  IdInquiriesDistinctCountCityDesc = 'ID_INQUIRIES_DISTINCT_COUNT_CITY_DESC',
  IdInquiriesDistinctCountProvinceAsc = 'ID_INQUIRIES_DISTINCT_COUNT_PROVINCE_ASC',
  IdInquiriesDistinctCountProvinceDesc = 'ID_INQUIRIES_DISTINCT_COUNT_PROVINCE_DESC',
  IdInquiriesDistinctCountPostalCodeAsc = 'ID_INQUIRIES_DISTINCT_COUNT_POSTAL_CODE_ASC',
  IdInquiriesDistinctCountPostalCodeDesc = 'ID_INQUIRIES_DISTINCT_COUNT_POSTAL_CODE_DESC',
  IdInquiriesDistinctCountBirthDateAsc = 'ID_INQUIRIES_DISTINCT_COUNT_BIRTH_DATE_ASC',
  IdInquiriesDistinctCountBirthDateDesc = 'ID_INQUIRIES_DISTINCT_COUNT_BIRTH_DATE_DESC',
  IdInquiriesDistinctCountIdNumberAsc = 'ID_INQUIRIES_DISTINCT_COUNT_ID_NUMBER_ASC',
  IdInquiriesDistinctCountIdNumberDesc = 'ID_INQUIRIES_DISTINCT_COUNT_ID_NUMBER_DESC',
  IdInquiriesDistinctCountCreatedAtAsc = 'ID_INQUIRIES_DISTINCT_COUNT_CREATED_AT_ASC',
  IdInquiriesDistinctCountCreatedAtDesc = 'ID_INQUIRIES_DISTINCT_COUNT_CREATED_AT_DESC',
  IdInquiriesDistinctCountUpdatedAtAsc = 'ID_INQUIRIES_DISTINCT_COUNT_UPDATED_AT_ASC',
  IdInquiriesDistinctCountUpdatedAtDesc = 'ID_INQUIRIES_DISTINCT_COUNT_UPDATED_AT_DESC',
  IdInquiriesDistinctCountRawInquiryAsc = 'ID_INQUIRIES_DISTINCT_COUNT_RAW_INQUIRY_ASC',
  IdInquiriesDistinctCountRawInquiryDesc = 'ID_INQUIRIES_DISTINCT_COUNT_RAW_INQUIRY_DESC',
  IdInquiriesDistinctCountInquiryIdAsc = 'ID_INQUIRIES_DISTINCT_COUNT_INQUIRY_ID_ASC',
  IdInquiriesDistinctCountInquiryIdDesc = 'ID_INQUIRIES_DISTINCT_COUNT_INQUIRY_ID_DESC',
  IdInquiriesMinIdAsc = 'ID_INQUIRIES_MIN_ID_ASC',
  IdInquiriesMinIdDesc = 'ID_INQUIRIES_MIN_ID_DESC',
  IdInquiriesMinLeadIdAsc = 'ID_INQUIRIES_MIN_LEAD_ID_ASC',
  IdInquiriesMinLeadIdDesc = 'ID_INQUIRIES_MIN_LEAD_ID_DESC',
  IdInquiriesMinIntegrationIdAsc = 'ID_INQUIRIES_MIN_INTEGRATION_ID_ASC',
  IdInquiriesMinIntegrationIdDesc = 'ID_INQUIRIES_MIN_INTEGRATION_ID_DESC',
  IdInquiriesMinStatusAsc = 'ID_INQUIRIES_MIN_STATUS_ASC',
  IdInquiriesMinStatusDesc = 'ID_INQUIRIES_MIN_STATUS_DESC',
  IdInquiriesMinFirstNameAsc = 'ID_INQUIRIES_MIN_FIRST_NAME_ASC',
  IdInquiriesMinFirstNameDesc = 'ID_INQUIRIES_MIN_FIRST_NAME_DESC',
  IdInquiriesMinLastNameAsc = 'ID_INQUIRIES_MIN_LAST_NAME_ASC',
  IdInquiriesMinLastNameDesc = 'ID_INQUIRIES_MIN_LAST_NAME_DESC',
  IdInquiriesMinAddressAsc = 'ID_INQUIRIES_MIN_ADDRESS_ASC',
  IdInquiriesMinAddressDesc = 'ID_INQUIRIES_MIN_ADDRESS_DESC',
  IdInquiriesMinCityAsc = 'ID_INQUIRIES_MIN_CITY_ASC',
  IdInquiriesMinCityDesc = 'ID_INQUIRIES_MIN_CITY_DESC',
  IdInquiriesMinProvinceAsc = 'ID_INQUIRIES_MIN_PROVINCE_ASC',
  IdInquiriesMinProvinceDesc = 'ID_INQUIRIES_MIN_PROVINCE_DESC',
  IdInquiriesMinPostalCodeAsc = 'ID_INQUIRIES_MIN_POSTAL_CODE_ASC',
  IdInquiriesMinPostalCodeDesc = 'ID_INQUIRIES_MIN_POSTAL_CODE_DESC',
  IdInquiriesMinBirthDateAsc = 'ID_INQUIRIES_MIN_BIRTH_DATE_ASC',
  IdInquiriesMinBirthDateDesc = 'ID_INQUIRIES_MIN_BIRTH_DATE_DESC',
  IdInquiriesMinIdNumberAsc = 'ID_INQUIRIES_MIN_ID_NUMBER_ASC',
  IdInquiriesMinIdNumberDesc = 'ID_INQUIRIES_MIN_ID_NUMBER_DESC',
  IdInquiriesMinCreatedAtAsc = 'ID_INQUIRIES_MIN_CREATED_AT_ASC',
  IdInquiriesMinCreatedAtDesc = 'ID_INQUIRIES_MIN_CREATED_AT_DESC',
  IdInquiriesMinUpdatedAtAsc = 'ID_INQUIRIES_MIN_UPDATED_AT_ASC',
  IdInquiriesMinUpdatedAtDesc = 'ID_INQUIRIES_MIN_UPDATED_AT_DESC',
  IdInquiriesMinRawInquiryAsc = 'ID_INQUIRIES_MIN_RAW_INQUIRY_ASC',
  IdInquiriesMinRawInquiryDesc = 'ID_INQUIRIES_MIN_RAW_INQUIRY_DESC',
  IdInquiriesMinInquiryIdAsc = 'ID_INQUIRIES_MIN_INQUIRY_ID_ASC',
  IdInquiriesMinInquiryIdDesc = 'ID_INQUIRIES_MIN_INQUIRY_ID_DESC',
  IdInquiriesMaxIdAsc = 'ID_INQUIRIES_MAX_ID_ASC',
  IdInquiriesMaxIdDesc = 'ID_INQUIRIES_MAX_ID_DESC',
  IdInquiriesMaxLeadIdAsc = 'ID_INQUIRIES_MAX_LEAD_ID_ASC',
  IdInquiriesMaxLeadIdDesc = 'ID_INQUIRIES_MAX_LEAD_ID_DESC',
  IdInquiriesMaxIntegrationIdAsc = 'ID_INQUIRIES_MAX_INTEGRATION_ID_ASC',
  IdInquiriesMaxIntegrationIdDesc = 'ID_INQUIRIES_MAX_INTEGRATION_ID_DESC',
  IdInquiriesMaxStatusAsc = 'ID_INQUIRIES_MAX_STATUS_ASC',
  IdInquiriesMaxStatusDesc = 'ID_INQUIRIES_MAX_STATUS_DESC',
  IdInquiriesMaxFirstNameAsc = 'ID_INQUIRIES_MAX_FIRST_NAME_ASC',
  IdInquiriesMaxFirstNameDesc = 'ID_INQUIRIES_MAX_FIRST_NAME_DESC',
  IdInquiriesMaxLastNameAsc = 'ID_INQUIRIES_MAX_LAST_NAME_ASC',
  IdInquiriesMaxLastNameDesc = 'ID_INQUIRIES_MAX_LAST_NAME_DESC',
  IdInquiriesMaxAddressAsc = 'ID_INQUIRIES_MAX_ADDRESS_ASC',
  IdInquiriesMaxAddressDesc = 'ID_INQUIRIES_MAX_ADDRESS_DESC',
  IdInquiriesMaxCityAsc = 'ID_INQUIRIES_MAX_CITY_ASC',
  IdInquiriesMaxCityDesc = 'ID_INQUIRIES_MAX_CITY_DESC',
  IdInquiriesMaxProvinceAsc = 'ID_INQUIRIES_MAX_PROVINCE_ASC',
  IdInquiriesMaxProvinceDesc = 'ID_INQUIRIES_MAX_PROVINCE_DESC',
  IdInquiriesMaxPostalCodeAsc = 'ID_INQUIRIES_MAX_POSTAL_CODE_ASC',
  IdInquiriesMaxPostalCodeDesc = 'ID_INQUIRIES_MAX_POSTAL_CODE_DESC',
  IdInquiriesMaxBirthDateAsc = 'ID_INQUIRIES_MAX_BIRTH_DATE_ASC',
  IdInquiriesMaxBirthDateDesc = 'ID_INQUIRIES_MAX_BIRTH_DATE_DESC',
  IdInquiriesMaxIdNumberAsc = 'ID_INQUIRIES_MAX_ID_NUMBER_ASC',
  IdInquiriesMaxIdNumberDesc = 'ID_INQUIRIES_MAX_ID_NUMBER_DESC',
  IdInquiriesMaxCreatedAtAsc = 'ID_INQUIRIES_MAX_CREATED_AT_ASC',
  IdInquiriesMaxCreatedAtDesc = 'ID_INQUIRIES_MAX_CREATED_AT_DESC',
  IdInquiriesMaxUpdatedAtAsc = 'ID_INQUIRIES_MAX_UPDATED_AT_ASC',
  IdInquiriesMaxUpdatedAtDesc = 'ID_INQUIRIES_MAX_UPDATED_AT_DESC',
  IdInquiriesMaxRawInquiryAsc = 'ID_INQUIRIES_MAX_RAW_INQUIRY_ASC',
  IdInquiriesMaxRawInquiryDesc = 'ID_INQUIRIES_MAX_RAW_INQUIRY_DESC',
  IdInquiriesMaxInquiryIdAsc = 'ID_INQUIRIES_MAX_INQUIRY_ID_ASC',
  IdInquiriesMaxInquiryIdDesc = 'ID_INQUIRIES_MAX_INQUIRY_ID_DESC',
  IdInquiriesAverageIdAsc = 'ID_INQUIRIES_AVERAGE_ID_ASC',
  IdInquiriesAverageIdDesc = 'ID_INQUIRIES_AVERAGE_ID_DESC',
  IdInquiriesAverageLeadIdAsc = 'ID_INQUIRIES_AVERAGE_LEAD_ID_ASC',
  IdInquiriesAverageLeadIdDesc = 'ID_INQUIRIES_AVERAGE_LEAD_ID_DESC',
  IdInquiriesAverageIntegrationIdAsc = 'ID_INQUIRIES_AVERAGE_INTEGRATION_ID_ASC',
  IdInquiriesAverageIntegrationIdDesc = 'ID_INQUIRIES_AVERAGE_INTEGRATION_ID_DESC',
  IdInquiriesAverageStatusAsc = 'ID_INQUIRIES_AVERAGE_STATUS_ASC',
  IdInquiriesAverageStatusDesc = 'ID_INQUIRIES_AVERAGE_STATUS_DESC',
  IdInquiriesAverageFirstNameAsc = 'ID_INQUIRIES_AVERAGE_FIRST_NAME_ASC',
  IdInquiriesAverageFirstNameDesc = 'ID_INQUIRIES_AVERAGE_FIRST_NAME_DESC',
  IdInquiriesAverageLastNameAsc = 'ID_INQUIRIES_AVERAGE_LAST_NAME_ASC',
  IdInquiriesAverageLastNameDesc = 'ID_INQUIRIES_AVERAGE_LAST_NAME_DESC',
  IdInquiriesAverageAddressAsc = 'ID_INQUIRIES_AVERAGE_ADDRESS_ASC',
  IdInquiriesAverageAddressDesc = 'ID_INQUIRIES_AVERAGE_ADDRESS_DESC',
  IdInquiriesAverageCityAsc = 'ID_INQUIRIES_AVERAGE_CITY_ASC',
  IdInquiriesAverageCityDesc = 'ID_INQUIRIES_AVERAGE_CITY_DESC',
  IdInquiriesAverageProvinceAsc = 'ID_INQUIRIES_AVERAGE_PROVINCE_ASC',
  IdInquiriesAverageProvinceDesc = 'ID_INQUIRIES_AVERAGE_PROVINCE_DESC',
  IdInquiriesAveragePostalCodeAsc = 'ID_INQUIRIES_AVERAGE_POSTAL_CODE_ASC',
  IdInquiriesAveragePostalCodeDesc = 'ID_INQUIRIES_AVERAGE_POSTAL_CODE_DESC',
  IdInquiriesAverageBirthDateAsc = 'ID_INQUIRIES_AVERAGE_BIRTH_DATE_ASC',
  IdInquiriesAverageBirthDateDesc = 'ID_INQUIRIES_AVERAGE_BIRTH_DATE_DESC',
  IdInquiriesAverageIdNumberAsc = 'ID_INQUIRIES_AVERAGE_ID_NUMBER_ASC',
  IdInquiriesAverageIdNumberDesc = 'ID_INQUIRIES_AVERAGE_ID_NUMBER_DESC',
  IdInquiriesAverageCreatedAtAsc = 'ID_INQUIRIES_AVERAGE_CREATED_AT_ASC',
  IdInquiriesAverageCreatedAtDesc = 'ID_INQUIRIES_AVERAGE_CREATED_AT_DESC',
  IdInquiriesAverageUpdatedAtAsc = 'ID_INQUIRIES_AVERAGE_UPDATED_AT_ASC',
  IdInquiriesAverageUpdatedAtDesc = 'ID_INQUIRIES_AVERAGE_UPDATED_AT_DESC',
  IdInquiriesAverageRawInquiryAsc = 'ID_INQUIRIES_AVERAGE_RAW_INQUIRY_ASC',
  IdInquiriesAverageRawInquiryDesc = 'ID_INQUIRIES_AVERAGE_RAW_INQUIRY_DESC',
  IdInquiriesAverageInquiryIdAsc = 'ID_INQUIRIES_AVERAGE_INQUIRY_ID_ASC',
  IdInquiriesAverageInquiryIdDesc = 'ID_INQUIRIES_AVERAGE_INQUIRY_ID_DESC',
  IdInquiriesStddevSampleIdAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_ID_ASC',
  IdInquiriesStddevSampleIdDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_ID_DESC',
  IdInquiriesStddevSampleLeadIdAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_LEAD_ID_ASC',
  IdInquiriesStddevSampleLeadIdDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_LEAD_ID_DESC',
  IdInquiriesStddevSampleIntegrationIdAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  IdInquiriesStddevSampleIntegrationIdDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  IdInquiriesStddevSampleStatusAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_STATUS_ASC',
  IdInquiriesStddevSampleStatusDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_STATUS_DESC',
  IdInquiriesStddevSampleFirstNameAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_FIRST_NAME_ASC',
  IdInquiriesStddevSampleFirstNameDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_FIRST_NAME_DESC',
  IdInquiriesStddevSampleLastNameAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_LAST_NAME_ASC',
  IdInquiriesStddevSampleLastNameDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_LAST_NAME_DESC',
  IdInquiriesStddevSampleAddressAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_ADDRESS_ASC',
  IdInquiriesStddevSampleAddressDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_ADDRESS_DESC',
  IdInquiriesStddevSampleCityAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_CITY_ASC',
  IdInquiriesStddevSampleCityDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_CITY_DESC',
  IdInquiriesStddevSampleProvinceAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_PROVINCE_ASC',
  IdInquiriesStddevSampleProvinceDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_PROVINCE_DESC',
  IdInquiriesStddevSamplePostalCodeAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_POSTAL_CODE_ASC',
  IdInquiriesStddevSamplePostalCodeDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_POSTAL_CODE_DESC',
  IdInquiriesStddevSampleBirthDateAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_BIRTH_DATE_ASC',
  IdInquiriesStddevSampleBirthDateDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_BIRTH_DATE_DESC',
  IdInquiriesStddevSampleIdNumberAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_ID_NUMBER_ASC',
  IdInquiriesStddevSampleIdNumberDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_ID_NUMBER_DESC',
  IdInquiriesStddevSampleCreatedAtAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_CREATED_AT_ASC',
  IdInquiriesStddevSampleCreatedAtDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_CREATED_AT_DESC',
  IdInquiriesStddevSampleUpdatedAtAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  IdInquiriesStddevSampleUpdatedAtDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  IdInquiriesStddevSampleRawInquiryAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_RAW_INQUIRY_ASC',
  IdInquiriesStddevSampleRawInquiryDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_RAW_INQUIRY_DESC',
  IdInquiriesStddevSampleInquiryIdAsc = 'ID_INQUIRIES_STDDEV_SAMPLE_INQUIRY_ID_ASC',
  IdInquiriesStddevSampleInquiryIdDesc = 'ID_INQUIRIES_STDDEV_SAMPLE_INQUIRY_ID_DESC',
  IdInquiriesStddevPopulationIdAsc = 'ID_INQUIRIES_STDDEV_POPULATION_ID_ASC',
  IdInquiriesStddevPopulationIdDesc = 'ID_INQUIRIES_STDDEV_POPULATION_ID_DESC',
  IdInquiriesStddevPopulationLeadIdAsc = 'ID_INQUIRIES_STDDEV_POPULATION_LEAD_ID_ASC',
  IdInquiriesStddevPopulationLeadIdDesc = 'ID_INQUIRIES_STDDEV_POPULATION_LEAD_ID_DESC',
  IdInquiriesStddevPopulationIntegrationIdAsc = 'ID_INQUIRIES_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  IdInquiriesStddevPopulationIntegrationIdDesc = 'ID_INQUIRIES_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  IdInquiriesStddevPopulationStatusAsc = 'ID_INQUIRIES_STDDEV_POPULATION_STATUS_ASC',
  IdInquiriesStddevPopulationStatusDesc = 'ID_INQUIRIES_STDDEV_POPULATION_STATUS_DESC',
  IdInquiriesStddevPopulationFirstNameAsc = 'ID_INQUIRIES_STDDEV_POPULATION_FIRST_NAME_ASC',
  IdInquiriesStddevPopulationFirstNameDesc = 'ID_INQUIRIES_STDDEV_POPULATION_FIRST_NAME_DESC',
  IdInquiriesStddevPopulationLastNameAsc = 'ID_INQUIRIES_STDDEV_POPULATION_LAST_NAME_ASC',
  IdInquiriesStddevPopulationLastNameDesc = 'ID_INQUIRIES_STDDEV_POPULATION_LAST_NAME_DESC',
  IdInquiriesStddevPopulationAddressAsc = 'ID_INQUIRIES_STDDEV_POPULATION_ADDRESS_ASC',
  IdInquiriesStddevPopulationAddressDesc = 'ID_INQUIRIES_STDDEV_POPULATION_ADDRESS_DESC',
  IdInquiriesStddevPopulationCityAsc = 'ID_INQUIRIES_STDDEV_POPULATION_CITY_ASC',
  IdInquiriesStddevPopulationCityDesc = 'ID_INQUIRIES_STDDEV_POPULATION_CITY_DESC',
  IdInquiriesStddevPopulationProvinceAsc = 'ID_INQUIRIES_STDDEV_POPULATION_PROVINCE_ASC',
  IdInquiriesStddevPopulationProvinceDesc = 'ID_INQUIRIES_STDDEV_POPULATION_PROVINCE_DESC',
  IdInquiriesStddevPopulationPostalCodeAsc = 'ID_INQUIRIES_STDDEV_POPULATION_POSTAL_CODE_ASC',
  IdInquiriesStddevPopulationPostalCodeDesc = 'ID_INQUIRIES_STDDEV_POPULATION_POSTAL_CODE_DESC',
  IdInquiriesStddevPopulationBirthDateAsc = 'ID_INQUIRIES_STDDEV_POPULATION_BIRTH_DATE_ASC',
  IdInquiriesStddevPopulationBirthDateDesc = 'ID_INQUIRIES_STDDEV_POPULATION_BIRTH_DATE_DESC',
  IdInquiriesStddevPopulationIdNumberAsc = 'ID_INQUIRIES_STDDEV_POPULATION_ID_NUMBER_ASC',
  IdInquiriesStddevPopulationIdNumberDesc = 'ID_INQUIRIES_STDDEV_POPULATION_ID_NUMBER_DESC',
  IdInquiriesStddevPopulationCreatedAtAsc = 'ID_INQUIRIES_STDDEV_POPULATION_CREATED_AT_ASC',
  IdInquiriesStddevPopulationCreatedAtDesc = 'ID_INQUIRIES_STDDEV_POPULATION_CREATED_AT_DESC',
  IdInquiriesStddevPopulationUpdatedAtAsc = 'ID_INQUIRIES_STDDEV_POPULATION_UPDATED_AT_ASC',
  IdInquiriesStddevPopulationUpdatedAtDesc = 'ID_INQUIRIES_STDDEV_POPULATION_UPDATED_AT_DESC',
  IdInquiriesStddevPopulationRawInquiryAsc = 'ID_INQUIRIES_STDDEV_POPULATION_RAW_INQUIRY_ASC',
  IdInquiriesStddevPopulationRawInquiryDesc = 'ID_INQUIRIES_STDDEV_POPULATION_RAW_INQUIRY_DESC',
  IdInquiriesStddevPopulationInquiryIdAsc = 'ID_INQUIRIES_STDDEV_POPULATION_INQUIRY_ID_ASC',
  IdInquiriesStddevPopulationInquiryIdDesc = 'ID_INQUIRIES_STDDEV_POPULATION_INQUIRY_ID_DESC',
  IdInquiriesVarianceSampleIdAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ID_ASC',
  IdInquiriesVarianceSampleIdDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ID_DESC',
  IdInquiriesVarianceSampleLeadIdAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_LEAD_ID_ASC',
  IdInquiriesVarianceSampleLeadIdDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_LEAD_ID_DESC',
  IdInquiriesVarianceSampleIntegrationIdAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  IdInquiriesVarianceSampleIntegrationIdDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  IdInquiriesVarianceSampleStatusAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_STATUS_ASC',
  IdInquiriesVarianceSampleStatusDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_STATUS_DESC',
  IdInquiriesVarianceSampleFirstNameAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  IdInquiriesVarianceSampleFirstNameDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  IdInquiriesVarianceSampleLastNameAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_LAST_NAME_ASC',
  IdInquiriesVarianceSampleLastNameDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_LAST_NAME_DESC',
  IdInquiriesVarianceSampleAddressAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ADDRESS_ASC',
  IdInquiriesVarianceSampleAddressDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ADDRESS_DESC',
  IdInquiriesVarianceSampleCityAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_CITY_ASC',
  IdInquiriesVarianceSampleCityDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_CITY_DESC',
  IdInquiriesVarianceSampleProvinceAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_PROVINCE_ASC',
  IdInquiriesVarianceSampleProvinceDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_PROVINCE_DESC',
  IdInquiriesVarianceSamplePostalCodeAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_POSTAL_CODE_ASC',
  IdInquiriesVarianceSamplePostalCodeDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_POSTAL_CODE_DESC',
  IdInquiriesVarianceSampleBirthDateAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_BIRTH_DATE_ASC',
  IdInquiriesVarianceSampleBirthDateDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_BIRTH_DATE_DESC',
  IdInquiriesVarianceSampleIdNumberAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ID_NUMBER_ASC',
  IdInquiriesVarianceSampleIdNumberDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_ID_NUMBER_DESC',
  IdInquiriesVarianceSampleCreatedAtAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  IdInquiriesVarianceSampleCreatedAtDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  IdInquiriesVarianceSampleUpdatedAtAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  IdInquiriesVarianceSampleUpdatedAtDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdInquiriesVarianceSampleRawInquiryAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_RAW_INQUIRY_ASC',
  IdInquiriesVarianceSampleRawInquiryDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_RAW_INQUIRY_DESC',
  IdInquiriesVarianceSampleInquiryIdAsc = 'ID_INQUIRIES_VARIANCE_SAMPLE_INQUIRY_ID_ASC',
  IdInquiriesVarianceSampleInquiryIdDesc = 'ID_INQUIRIES_VARIANCE_SAMPLE_INQUIRY_ID_DESC',
  IdInquiriesVariancePopulationIdAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_ID_ASC',
  IdInquiriesVariancePopulationIdDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_ID_DESC',
  IdInquiriesVariancePopulationLeadIdAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_LEAD_ID_ASC',
  IdInquiriesVariancePopulationLeadIdDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_LEAD_ID_DESC',
  IdInquiriesVariancePopulationIntegrationIdAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  IdInquiriesVariancePopulationIntegrationIdDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  IdInquiriesVariancePopulationStatusAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_STATUS_ASC',
  IdInquiriesVariancePopulationStatusDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_STATUS_DESC',
  IdInquiriesVariancePopulationFirstNameAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_FIRST_NAME_ASC',
  IdInquiriesVariancePopulationFirstNameDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_FIRST_NAME_DESC',
  IdInquiriesVariancePopulationLastNameAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_LAST_NAME_ASC',
  IdInquiriesVariancePopulationLastNameDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_LAST_NAME_DESC',
  IdInquiriesVariancePopulationAddressAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_ADDRESS_ASC',
  IdInquiriesVariancePopulationAddressDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_ADDRESS_DESC',
  IdInquiriesVariancePopulationCityAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_CITY_ASC',
  IdInquiriesVariancePopulationCityDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_CITY_DESC',
  IdInquiriesVariancePopulationProvinceAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_PROVINCE_ASC',
  IdInquiriesVariancePopulationProvinceDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_PROVINCE_DESC',
  IdInquiriesVariancePopulationPostalCodeAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_POSTAL_CODE_ASC',
  IdInquiriesVariancePopulationPostalCodeDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_POSTAL_CODE_DESC',
  IdInquiriesVariancePopulationBirthDateAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_BIRTH_DATE_ASC',
  IdInquiriesVariancePopulationBirthDateDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_BIRTH_DATE_DESC',
  IdInquiriesVariancePopulationIdNumberAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_ID_NUMBER_ASC',
  IdInquiriesVariancePopulationIdNumberDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_ID_NUMBER_DESC',
  IdInquiriesVariancePopulationCreatedAtAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_CREATED_AT_ASC',
  IdInquiriesVariancePopulationCreatedAtDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_CREATED_AT_DESC',
  IdInquiriesVariancePopulationUpdatedAtAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  IdInquiriesVariancePopulationUpdatedAtDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  IdInquiriesVariancePopulationRawInquiryAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_RAW_INQUIRY_ASC',
  IdInquiriesVariancePopulationRawInquiryDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_RAW_INQUIRY_DESC',
  IdInquiriesVariancePopulationInquiryIdAsc = 'ID_INQUIRIES_VARIANCE_POPULATION_INQUIRY_ID_ASC',
  IdInquiriesVariancePopulationInquiryIdDesc = 'ID_INQUIRIES_VARIANCE_POPULATION_INQUIRY_ID_DESC',
  DealsCountAsc = 'DEALS_COUNT_ASC',
  DealsCountDesc = 'DEALS_COUNT_DESC',
  DealsSumIdAsc = 'DEALS_SUM_ID_ASC',
  DealsSumIdDesc = 'DEALS_SUM_ID_DESC',
  DealsSumLeadIdAsc = 'DEALS_SUM_LEAD_ID_ASC',
  DealsSumLeadIdDesc = 'DEALS_SUM_LEAD_ID_DESC',
  DealsSumCompanyIntegrationIdAsc = 'DEALS_SUM_COMPANY_INTEGRATION_ID_ASC',
  DealsSumCompanyIntegrationIdDesc = 'DEALS_SUM_COMPANY_INTEGRATION_ID_DESC',
  DealsSumFileTypeAsc = 'DEALS_SUM_FILE_TYPE_ASC',
  DealsSumFileTypeDesc = 'DEALS_SUM_FILE_TYPE_DESC',
  DealsSumDvDealerIdAsc = 'DEALS_SUM_DV_DEALER_ID_ASC',
  DealsSumDvDealerIdDesc = 'DEALS_SUM_DV_DEALER_ID_DESC',
  DealsSumVendorDealerIdAsc = 'DEALS_SUM_VENDOR_DEALER_ID_ASC',
  DealsSumVendorDealerIdDesc = 'DEALS_SUM_VENDOR_DEALER_ID_DESC',
  DealsSumDmsTypeAsc = 'DEALS_SUM_DMS_TYPE_ASC',
  DealsSumDmsTypeDesc = 'DEALS_SUM_DMS_TYPE_DESC',
  DealsSumDealNumberAsc = 'DEALS_SUM_DEAL_NUMBER_ASC',
  DealsSumDealNumberDesc = 'DEALS_SUM_DEAL_NUMBER_DESC',
  DealsSumCustomerNumberAsc = 'DEALS_SUM_CUSTOMER_NUMBER_ASC',
  DealsSumCustomerNumberDesc = 'DEALS_SUM_CUSTOMER_NUMBER_DESC',
  DealsSumFullNameAsc = 'DEALS_SUM_FULL_NAME_ASC',
  DealsSumFullNameDesc = 'DEALS_SUM_FULL_NAME_DESC',
  DealsSumSalutationAsc = 'DEALS_SUM_SALUTATION_ASC',
  DealsSumSalutationDesc = 'DEALS_SUM_SALUTATION_DESC',
  DealsSumFirstNameAsc = 'DEALS_SUM_FIRST_NAME_ASC',
  DealsSumFirstNameDesc = 'DEALS_SUM_FIRST_NAME_DESC',
  DealsSumMiddleNameAsc = 'DEALS_SUM_MIDDLE_NAME_ASC',
  DealsSumMiddleNameDesc = 'DEALS_SUM_MIDDLE_NAME_DESC',
  DealsSumLastNameAsc = 'DEALS_SUM_LAST_NAME_ASC',
  DealsSumLastNameDesc = 'DEALS_SUM_LAST_NAME_DESC',
  DealsSumSuffixAsc = 'DEALS_SUM_SUFFIX_ASC',
  DealsSumSuffixDesc = 'DEALS_SUM_SUFFIX_DESC',
  DealsSumAddressLine_1Asc = 'DEALS_SUM_ADDRESS_LINE_1_ASC',
  DealsSumAddressLine_1Desc = 'DEALS_SUM_ADDRESS_LINE_1_DESC',
  DealsSumAddressLine_2Asc = 'DEALS_SUM_ADDRESS_LINE_2_ASC',
  DealsSumAddressLine_2Desc = 'DEALS_SUM_ADDRESS_LINE_2_DESC',
  DealsSumCityAsc = 'DEALS_SUM_CITY_ASC',
  DealsSumCityDesc = 'DEALS_SUM_CITY_DESC',
  DealsSumStateAsc = 'DEALS_SUM_STATE_ASC',
  DealsSumStateDesc = 'DEALS_SUM_STATE_DESC',
  DealsSumZipAsc = 'DEALS_SUM_ZIP_ASC',
  DealsSumZipDesc = 'DEALS_SUM_ZIP_DESC',
  DealsSumCountyAsc = 'DEALS_SUM_COUNTY_ASC',
  DealsSumCountyDesc = 'DEALS_SUM_COUNTY_DESC',
  DealsSumHomePhoneAsc = 'DEALS_SUM_HOME_PHONE_ASC',
  DealsSumHomePhoneDesc = 'DEALS_SUM_HOME_PHONE_DESC',
  DealsSumCellPhoneAsc = 'DEALS_SUM_CELL_PHONE_ASC',
  DealsSumCellPhoneDesc = 'DEALS_SUM_CELL_PHONE_DESC',
  DealsSumWorkPhoneAsc = 'DEALS_SUM_WORK_PHONE_ASC',
  DealsSumWorkPhoneDesc = 'DEALS_SUM_WORK_PHONE_DESC',
  DealsSumWorkExtensionAsc = 'DEALS_SUM_WORK_EXTENSION_ASC',
  DealsSumWorkExtensionDesc = 'DEALS_SUM_WORK_EXTENSION_DESC',
  DealsSumEmail_1Asc = 'DEALS_SUM_EMAIL_1_ASC',
  DealsSumEmail_1Desc = 'DEALS_SUM_EMAIL_1_DESC',
  DealsSumEmail_2Asc = 'DEALS_SUM_EMAIL_2_ASC',
  DealsSumEmail_2Desc = 'DEALS_SUM_EMAIL_2_DESC',
  DealsSumEmail_3Asc = 'DEALS_SUM_EMAIL_3_ASC',
  DealsSumEmail_3Desc = 'DEALS_SUM_EMAIL_3_DESC',
  DealsSumBirthDateAsc = 'DEALS_SUM_BIRTH_DATE_ASC',
  DealsSumBirthDateDesc = 'DEALS_SUM_BIRTH_DATE_DESC',
  DealsSumIndividualBusinessFlagAsc = 'DEALS_SUM_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsSumIndividualBusinessFlagDesc = 'DEALS_SUM_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsSumOptOutAsc = 'DEALS_SUM_OPT_OUT_ASC',
  DealsSumOptOutDesc = 'DEALS_SUM_OPT_OUT_DESC',
  DealsSumBlockEmailAsc = 'DEALS_SUM_BLOCK_EMAIL_ASC',
  DealsSumBlockEmailDesc = 'DEALS_SUM_BLOCK_EMAIL_DESC',
  DealsSumBlockPhoneAsc = 'DEALS_SUM_BLOCK_PHONE_ASC',
  DealsSumBlockPhoneDesc = 'DEALS_SUM_BLOCK_PHONE_DESC',
  DealsSumBlockMailAsc = 'DEALS_SUM_BLOCK_MAIL_ASC',
  DealsSumBlockMailDesc = 'DEALS_SUM_BLOCK_MAIL_DESC',
  DealsSumLanguageAsc = 'DEALS_SUM_LANGUAGE_ASC',
  DealsSumLanguageDesc = 'DEALS_SUM_LANGUAGE_DESC',
  DealsSumCustomerCreateDateAsc = 'DEALS_SUM_CUSTOMER_CREATE_DATE_ASC',
  DealsSumCustomerCreateDateDesc = 'DEALS_SUM_CUSTOMER_CREATE_DATE_DESC',
  DealsSumCustomerLastActivityDateAsc = 'DEALS_SUM_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsSumCustomerLastActivityDateDesc = 'DEALS_SUM_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsSumCoBuyerCustomerNumberAsc = 'DEALS_SUM_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsSumCoBuyerCustomerNumberDesc = 'DEALS_SUM_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsSumCoBuyerFullNameAsc = 'DEALS_SUM_CO_BUYER_FULL_NAME_ASC',
  DealsSumCoBuyerFullNameDesc = 'DEALS_SUM_CO_BUYER_FULL_NAME_DESC',
  DealsSumCoBuyerSalutationAsc = 'DEALS_SUM_CO_BUYER_SALUTATION_ASC',
  DealsSumCoBuyerSalutationDesc = 'DEALS_SUM_CO_BUYER_SALUTATION_DESC',
  DealsSumCoBuyerFirstNameAsc = 'DEALS_SUM_CO_BUYER_FIRST_NAME_ASC',
  DealsSumCoBuyerFirstNameDesc = 'DEALS_SUM_CO_BUYER_FIRST_NAME_DESC',
  DealsSumCoBuyerMiddleNameAsc = 'DEALS_SUM_CO_BUYER_MIDDLE_NAME_ASC',
  DealsSumCoBuyerMiddleNameDesc = 'DEALS_SUM_CO_BUYER_MIDDLE_NAME_DESC',
  DealsSumCoBuyerLastNameAsc = 'DEALS_SUM_CO_BUYER_LAST_NAME_ASC',
  DealsSumCoBuyerLastNameDesc = 'DEALS_SUM_CO_BUYER_LAST_NAME_DESC',
  DealsSumCoBuyerSuffixAsc = 'DEALS_SUM_CO_BUYER_SUFFIX_ASC',
  DealsSumCoBuyerSuffixDesc = 'DEALS_SUM_CO_BUYER_SUFFIX_DESC',
  DealsSumCoBuyerAddressLine_1Asc = 'DEALS_SUM_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsSumCoBuyerAddressLine_1Desc = 'DEALS_SUM_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsSumCoBuyerAddressLine_2Asc = 'DEALS_SUM_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsSumCoBuyerAddressLine_2Desc = 'DEALS_SUM_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsSumCoBuyerCityAsc = 'DEALS_SUM_CO_BUYER_CITY_ASC',
  DealsSumCoBuyerCityDesc = 'DEALS_SUM_CO_BUYER_CITY_DESC',
  DealsSumCoBuyerStateAsc = 'DEALS_SUM_CO_BUYER_STATE_ASC',
  DealsSumCoBuyerStateDesc = 'DEALS_SUM_CO_BUYER_STATE_DESC',
  DealsSumCoBuyerZipAsc = 'DEALS_SUM_CO_BUYER_ZIP_ASC',
  DealsSumCoBuyerZipDesc = 'DEALS_SUM_CO_BUYER_ZIP_DESC',
  DealsSumCoBuyerCountyAsc = 'DEALS_SUM_CO_BUYER_COUNTY_ASC',
  DealsSumCoBuyerCountyDesc = 'DEALS_SUM_CO_BUYER_COUNTY_DESC',
  DealsSumCoBuyerHomePhoneAsc = 'DEALS_SUM_CO_BUYER_HOME_PHONE_ASC',
  DealsSumCoBuyerHomePhoneDesc = 'DEALS_SUM_CO_BUYER_HOME_PHONE_DESC',
  DealsSumCoBuyerCellPhoneAsc = 'DEALS_SUM_CO_BUYER_CELL_PHONE_ASC',
  DealsSumCoBuyerCellPhoneDesc = 'DEALS_SUM_CO_BUYER_CELL_PHONE_DESC',
  DealsSumCoBuyerWorkPhoneAsc = 'DEALS_SUM_CO_BUYER_WORK_PHONE_ASC',
  DealsSumCoBuyerWorkPhoneDesc = 'DEALS_SUM_CO_BUYER_WORK_PHONE_DESC',
  DealsSumCoBuyerWorkExtensionAsc = 'DEALS_SUM_CO_BUYER_WORK_EXTENSION_ASC',
  DealsSumCoBuyerWorkExtensionDesc = 'DEALS_SUM_CO_BUYER_WORK_EXTENSION_DESC',
  DealsSumCoBuyerEmail_1Asc = 'DEALS_SUM_CO_BUYER_EMAIL_1_ASC',
  DealsSumCoBuyerEmail_1Desc = 'DEALS_SUM_CO_BUYER_EMAIL_1_DESC',
  DealsSumCoBuyerEmail_2Asc = 'DEALS_SUM_CO_BUYER_EMAIL_2_ASC',
  DealsSumCoBuyerEmail_2Desc = 'DEALS_SUM_CO_BUYER_EMAIL_2_DESC',
  DealsSumCoBuyerEmail_3Asc = 'DEALS_SUM_CO_BUYER_EMAIL_3_ASC',
  DealsSumCoBuyerEmail_3Desc = 'DEALS_SUM_CO_BUYER_EMAIL_3_DESC',
  DealsSumCoBuyerBirthDateAsc = 'DEALS_SUM_CO_BUYER_BIRTH_DATE_ASC',
  DealsSumCoBuyerBirthDateDesc = 'DEALS_SUM_CO_BUYER_BIRTH_DATE_DESC',
  DealsSumCoBuyerIndividualBusinessFlagAsc = 'DEALS_SUM_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsSumCoBuyerIndividualBusinessFlagDesc = 'DEALS_SUM_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsSumCoBuyerOptOutAsc = 'DEALS_SUM_CO_BUYER_OPT_OUT_ASC',
  DealsSumCoBuyerOptOutDesc = 'DEALS_SUM_CO_BUYER_OPT_OUT_DESC',
  DealsSumCoBuyerBlockEmailAsc = 'DEALS_SUM_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsSumCoBuyerBlockEmailDesc = 'DEALS_SUM_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsSumCoBuyerBlockPhoneAsc = 'DEALS_SUM_CO_BUYER_BLOCK_PHONE_ASC',
  DealsSumCoBuyerBlockPhoneDesc = 'DEALS_SUM_CO_BUYER_BLOCK_PHONE_DESC',
  DealsSumCoBuyerBlockMailAsc = 'DEALS_SUM_CO_BUYER_BLOCK_MAIL_ASC',
  DealsSumCoBuyerBlockMailDesc = 'DEALS_SUM_CO_BUYER_BLOCK_MAIL_DESC',
  DealsSumVinAsc = 'DEALS_SUM_VIN_ASC',
  DealsSumVinDesc = 'DEALS_SUM_VIN_DESC',
  DealsSumYearAsc = 'DEALS_SUM_YEAR_ASC',
  DealsSumYearDesc = 'DEALS_SUM_YEAR_DESC',
  DealsSumMakeAsc = 'DEALS_SUM_MAKE_ASC',
  DealsSumMakeDesc = 'DEALS_SUM_MAKE_DESC',
  DealsSumModelAsc = 'DEALS_SUM_MODEL_ASC',
  DealsSumModelDesc = 'DEALS_SUM_MODEL_DESC',
  DealsSumModelNumberAsc = 'DEALS_SUM_MODEL_NUMBER_ASC',
  DealsSumModelNumberDesc = 'DEALS_SUM_MODEL_NUMBER_DESC',
  DealsSumMileageAsc = 'DEALS_SUM_MILEAGE_ASC',
  DealsSumMileageDesc = 'DEALS_SUM_MILEAGE_DESC',
  DealsSumDescriptionAsc = 'DEALS_SUM_DESCRIPTION_ASC',
  DealsSumDescriptionDesc = 'DEALS_SUM_DESCRIPTION_DESC',
  DealsSumExteriorColorAsc = 'DEALS_SUM_EXTERIOR_COLOR_ASC',
  DealsSumExteriorColorDesc = 'DEALS_SUM_EXTERIOR_COLOR_DESC',
  DealsSumNewUsedAsc = 'DEALS_SUM_NEW_USED_ASC',
  DealsSumNewUsedDesc = 'DEALS_SUM_NEW_USED_DESC',
  DealsSumStockNumberAsc = 'DEALS_SUM_STOCK_NUMBER_ASC',
  DealsSumStockNumberDesc = 'DEALS_SUM_STOCK_NUMBER_DESC',
  DealsSumTransmissionAsc = 'DEALS_SUM_TRANSMISSION_ASC',
  DealsSumTransmissionDesc = 'DEALS_SUM_TRANSMISSION_DESC',
  DealsSumEngineConfigurationAsc = 'DEALS_SUM_ENGINE_CONFIGURATION_ASC',
  DealsSumEngineConfigurationDesc = 'DEALS_SUM_ENGINE_CONFIGURATION_DESC',
  DealsSumTrimAsc = 'DEALS_SUM_TRIM_ASC',
  DealsSumTrimDesc = 'DEALS_SUM_TRIM_DESC',
  DealsSumEngineNumberAsc = 'DEALS_SUM_ENGINE_NUMBER_ASC',
  DealsSumEngineNumberDesc = 'DEALS_SUM_ENGINE_NUMBER_DESC',
  DealsSumChassisNumberAsc = 'DEALS_SUM_CHASSIS_NUMBER_ASC',
  DealsSumChassisNumberDesc = 'DEALS_SUM_CHASSIS_NUMBER_DESC',
  DealsSumLicensePlateNumberAsc = 'DEALS_SUM_LICENSE_PLATE_NUMBER_ASC',
  DealsSumLicensePlateNumberDesc = 'DEALS_SUM_LICENSE_PLATE_NUMBER_DESC',
  DealsSumDeliveryDateAsc = 'DEALS_SUM_DELIVERY_DATE_ASC',
  DealsSumDeliveryDateDesc = 'DEALS_SUM_DELIVERY_DATE_DESC',
  DealsSumDeliveryMileageAsc = 'DEALS_SUM_DELIVERY_MILEAGE_ASC',
  DealsSumDeliveryMileageDesc = 'DEALS_SUM_DELIVERY_MILEAGE_DESC',
  DealsSumInventoryDateAsc = 'DEALS_SUM_INVENTORY_DATE_ASC',
  DealsSumInventoryDateDesc = 'DEALS_SUM_INVENTORY_DATE_DESC',
  DealsSumInServiceDateAsc = 'DEALS_SUM_IN_SERVICE_DATE_ASC',
  DealsSumInServiceDateDesc = 'DEALS_SUM_IN_SERVICE_DATE_DESC',
  DealsSumVinExplosionYearAsc = 'DEALS_SUM_VIN_EXPLOSION_YEAR_ASC',
  DealsSumVinExplosionYearDesc = 'DEALS_SUM_VIN_EXPLOSION_YEAR_DESC',
  DealsSumVinExplosionMakeAsc = 'DEALS_SUM_VIN_EXPLOSION_MAKE_ASC',
  DealsSumVinExplosionMakeDesc = 'DEALS_SUM_VIN_EXPLOSION_MAKE_DESC',
  DealsSumVinExplosionModelAsc = 'DEALS_SUM_VIN_EXPLOSION_MODEL_ASC',
  DealsSumVinExplosionModelDesc = 'DEALS_SUM_VIN_EXPLOSION_MODEL_DESC',
  DealsSumVinExplosionTrimAsc = 'DEALS_SUM_VIN_EXPLOSION_TRIM_ASC',
  DealsSumVinExplosionTrimDesc = 'DEALS_SUM_VIN_EXPLOSION_TRIM_DESC',
  DealsSumVinExplosionTransmissionTypeAsc = 'DEALS_SUM_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsSumVinExplosionTransmissionTypeDesc = 'DEALS_SUM_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsSumVinExplosionFuelTypeAsc = 'DEALS_SUM_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsSumVinExplosionFuelTypeDesc = 'DEALS_SUM_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsSumVinExplosionEngineSizeAsc = 'DEALS_SUM_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsSumVinExplosionEngineSizeDesc = 'DEALS_SUM_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsSumVinExplosionGvwRangeAsc = 'DEALS_SUM_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsSumVinExplosionGvwRangeDesc = 'DEALS_SUM_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsSumTrade_1VinAsc = 'DEALS_SUM_TRADE_1_VIN_ASC',
  DealsSumTrade_1VinDesc = 'DEALS_SUM_TRADE_1_VIN_DESC',
  DealsSumTrade_1YearAsc = 'DEALS_SUM_TRADE_1_YEAR_ASC',
  DealsSumTrade_1YearDesc = 'DEALS_SUM_TRADE_1_YEAR_DESC',
  DealsSumTrade_1MakeAsc = 'DEALS_SUM_TRADE_1_MAKE_ASC',
  DealsSumTrade_1MakeDesc = 'DEALS_SUM_TRADE_1_MAKE_DESC',
  DealsSumTrade_1ModelAsc = 'DEALS_SUM_TRADE_1_MODEL_ASC',
  DealsSumTrade_1ModelDesc = 'DEALS_SUM_TRADE_1_MODEL_DESC',
  DealsSumTrade_1OdometerAsc = 'DEALS_SUM_TRADE_1_ODOMETER_ASC',
  DealsSumTrade_1OdometerDesc = 'DEALS_SUM_TRADE_1_ODOMETER_DESC',
  DealsSumTrade_1ActualCashValueAsc = 'DEALS_SUM_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsSumTrade_1ActualCashValueDesc = 'DEALS_SUM_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsSumTrade_1GrossAsc = 'DEALS_SUM_TRADE_1_GROSS_ASC',
  DealsSumTrade_1GrossDesc = 'DEALS_SUM_TRADE_1_GROSS_DESC',
  DealsSumTrade_1PayoffAsc = 'DEALS_SUM_TRADE_1_PAYOFF_ASC',
  DealsSumTrade_1PayoffDesc = 'DEALS_SUM_TRADE_1_PAYOFF_DESC',
  DealsSumTrade_2VinAsc = 'DEALS_SUM_TRADE_2_VIN_ASC',
  DealsSumTrade_2VinDesc = 'DEALS_SUM_TRADE_2_VIN_DESC',
  DealsSumTrade_2YearAsc = 'DEALS_SUM_TRADE_2_YEAR_ASC',
  DealsSumTrade_2YearDesc = 'DEALS_SUM_TRADE_2_YEAR_DESC',
  DealsSumTrade_2MakeAsc = 'DEALS_SUM_TRADE_2_MAKE_ASC',
  DealsSumTrade_2MakeDesc = 'DEALS_SUM_TRADE_2_MAKE_DESC',
  DealsSumTrade_2ModelAsc = 'DEALS_SUM_TRADE_2_MODEL_ASC',
  DealsSumTrade_2ModelDesc = 'DEALS_SUM_TRADE_2_MODEL_DESC',
  DealsSumTrade_2OdometerAsc = 'DEALS_SUM_TRADE_2_ODOMETER_ASC',
  DealsSumTrade_2OdometerDesc = 'DEALS_SUM_TRADE_2_ODOMETER_DESC',
  DealsSumTrade_2ActualCashValueAsc = 'DEALS_SUM_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsSumTrade_2ActualCashValueDesc = 'DEALS_SUM_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsSumTrade_2GrossAsc = 'DEALS_SUM_TRADE_2_GROSS_ASC',
  DealsSumTrade_2GrossDesc = 'DEALS_SUM_TRADE_2_GROSS_DESC',
  DealsSumTrade_2PayoffAsc = 'DEALS_SUM_TRADE_2_PAYOFF_ASC',
  DealsSumTrade_2PayoffDesc = 'DEALS_SUM_TRADE_2_PAYOFF_DESC',
  DealsSumSalesman_1NumberAsc = 'DEALS_SUM_SALESMAN_1_NUMBER_ASC',
  DealsSumSalesman_1NumberDesc = 'DEALS_SUM_SALESMAN_1_NUMBER_DESC',
  DealsSumSalesman_1NameAsc = 'DEALS_SUM_SALESMAN_1_NAME_ASC',
  DealsSumSalesman_1NameDesc = 'DEALS_SUM_SALESMAN_1_NAME_DESC',
  DealsSumSalesman_2NumberAsc = 'DEALS_SUM_SALESMAN_2_NUMBER_ASC',
  DealsSumSalesman_2NumberDesc = 'DEALS_SUM_SALESMAN_2_NUMBER_DESC',
  DealsSumSalesman_2NameAsc = 'DEALS_SUM_SALESMAN_2_NAME_ASC',
  DealsSumSalesman_2NameDesc = 'DEALS_SUM_SALESMAN_2_NAME_DESC',
  DealsSumSalesman_3NumberAsc = 'DEALS_SUM_SALESMAN_3_NUMBER_ASC',
  DealsSumSalesman_3NumberDesc = 'DEALS_SUM_SALESMAN_3_NUMBER_DESC',
  DealsSumSalesman_3NameAsc = 'DEALS_SUM_SALESMAN_3_NAME_ASC',
  DealsSumSalesman_3NameDesc = 'DEALS_SUM_SALESMAN_3_NAME_DESC',
  DealsSumClosingManagerNumberAsc = 'DEALS_SUM_CLOSING_MANAGER_NUMBER_ASC',
  DealsSumClosingManagerNumberDesc = 'DEALS_SUM_CLOSING_MANAGER_NUMBER_DESC',
  DealsSumClosingManagerNameAsc = 'DEALS_SUM_CLOSING_MANAGER_NAME_ASC',
  DealsSumClosingManagerNameDesc = 'DEALS_SUM_CLOSING_MANAGER_NAME_DESC',
  DealsSumFinanceManagerNumberAsc = 'DEALS_SUM_FINANCE_MANAGER_NUMBER_ASC',
  DealsSumFinanceManagerNumberDesc = 'DEALS_SUM_FINANCE_MANAGER_NUMBER_DESC',
  DealsSumFinanceManagerNameAsc = 'DEALS_SUM_FINANCE_MANAGER_NAME_ASC',
  DealsSumFinanceManagerNameDesc = 'DEALS_SUM_FINANCE_MANAGER_NAME_DESC',
  DealsSumSalesmanManagerNumberAsc = 'DEALS_SUM_SALESMAN_MANAGER_NUMBER_ASC',
  DealsSumSalesmanManagerNumberDesc = 'DEALS_SUM_SALESMAN_MANAGER_NUMBER_DESC',
  DealsSumSalesmanManagerNameAsc = 'DEALS_SUM_SALESMAN_MANAGER_NAME_ASC',
  DealsSumSalesmanManagerNameDesc = 'DEALS_SUM_SALESMAN_MANAGER_NAME_DESC',
  DealsSumMsrpAsc = 'DEALS_SUM_MSRP_ASC',
  DealsSumMsrpDesc = 'DEALS_SUM_MSRP_DESC',
  DealsSumListPriceAsc = 'DEALS_SUM_LIST_PRICE_ASC',
  DealsSumListPriceDesc = 'DEALS_SUM_LIST_PRICE_DESC',
  DealsSumSalesPriceAsc = 'DEALS_SUM_SALES_PRICE_ASC',
  DealsSumSalesPriceDesc = 'DEALS_SUM_SALES_PRICE_DESC',
  DealsSumJournalPriceAsc = 'DEALS_SUM_JOURNAL_PRICE_ASC',
  DealsSumJournalPriceDesc = 'DEALS_SUM_JOURNAL_PRICE_DESC',
  DealsSumCostAsc = 'DEALS_SUM_COST_ASC',
  DealsSumCostDesc = 'DEALS_SUM_COST_DESC',
  DealsSumJournalCostAsc = 'DEALS_SUM_JOURNAL_COST_ASC',
  DealsSumJournalCostDesc = 'DEALS_SUM_JOURNAL_COST_DESC',
  DealsSumAdjustmentsAsc = 'DEALS_SUM_ADJUSTMENTS_ASC',
  DealsSumAdjustmentsDesc = 'DEALS_SUM_ADJUSTMENTS_DESC',
  DealsSumAdjustedCostAsc = 'DEALS_SUM_ADJUSTED_COST_ASC',
  DealsSumAdjustedCostDesc = 'DEALS_SUM_ADJUSTED_COST_DESC',
  DealsSumIncentivesAsc = 'DEALS_SUM_INCENTIVES_ASC',
  DealsSumIncentivesDesc = 'DEALS_SUM_INCENTIVES_DESC',
  DealsSumPackAmountAsc = 'DEALS_SUM_PACK_AMOUNT_ASC',
  DealsSumPackAmountDesc = 'DEALS_SUM_PACK_AMOUNT_DESC',
  DealsSumSaleNetAsc = 'DEALS_SUM_SALE_NET_ASC',
  DealsSumSaleNetDesc = 'DEALS_SUM_SALE_NET_DESC',
  DealsSumTotalTradeActualCashValueAsc = 'DEALS_SUM_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsSumTotalTradeActualCashValueDesc = 'DEALS_SUM_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsSumTotalTradeGrossAsc = 'DEALS_SUM_TOTAL_TRADE_GROSS_ASC',
  DealsSumTotalTradeGrossDesc = 'DEALS_SUM_TOTAL_TRADE_GROSS_DESC',
  DealsSumWeOweFrontAsc = 'DEALS_SUM_WE_OWE_FRONT_ASC',
  DealsSumWeOweFrontDesc = 'DEALS_SUM_WE_OWE_FRONT_DESC',
  DealsSumTotalFrontFeesAftermarketProfitAsc = 'DEALS_SUM_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsSumTotalFrontFeesAftermarketProfitDesc = 'DEALS_SUM_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsSumTotalFrontCommissionAsc = 'DEALS_SUM_TOTAL_FRONT_COMMISSION_ASC',
  DealsSumTotalFrontCommissionDesc = 'DEALS_SUM_TOTAL_FRONT_COMMISSION_DESC',
  DealsSumTotalFrontSalesAsc = 'DEALS_SUM_TOTAL_FRONT_SALES_ASC',
  DealsSumTotalFrontSalesDesc = 'DEALS_SUM_TOTAL_FRONT_SALES_DESC',
  DealsSumTotalFrontCostAsc = 'DEALS_SUM_TOTAL_FRONT_COST_ASC',
  DealsSumTotalFrontCostDesc = 'DEALS_SUM_TOTAL_FRONT_COST_DESC',
  DealsSumFrontGrossAsc = 'DEALS_SUM_FRONT_GROSS_ASC',
  DealsSumFrontGrossDesc = 'DEALS_SUM_FRONT_GROSS_DESC',
  DealsSumFinanceProfitAsc = 'DEALS_SUM_FINANCE_PROFIT_ASC',
  DealsSumFinanceProfitDesc = 'DEALS_SUM_FINANCE_PROFIT_DESC',
  DealsSumTotalWarrantyProfitAsc = 'DEALS_SUM_TOTAL_WARRANTY_PROFIT_ASC',
  DealsSumTotalWarrantyProfitDesc = 'DEALS_SUM_TOTAL_WARRANTY_PROFIT_DESC',
  DealsSumWeOweBackAsc = 'DEALS_SUM_WE_OWE_BACK_ASC',
  DealsSumWeOweBackDesc = 'DEALS_SUM_WE_OWE_BACK_DESC',
  DealsSumInsuranceProfitAsc = 'DEALS_SUM_INSURANCE_PROFIT_ASC',
  DealsSumInsuranceProfitDesc = 'DEALS_SUM_INSURANCE_PROFIT_DESC',
  DealsSumTotalBackFeesAftermarketProfitAsc = 'DEALS_SUM_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsSumTotalBackFeesAftermarketProfitDesc = 'DEALS_SUM_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsSumFinanceReserveAsc = 'DEALS_SUM_FINANCE_RESERVE_ASC',
  DealsSumFinanceReserveDesc = 'DEALS_SUM_FINANCE_RESERVE_DESC',
  DealsSumTotalBackCommissionAsc = 'DEALS_SUM_TOTAL_BACK_COMMISSION_ASC',
  DealsSumTotalBackCommissionDesc = 'DEALS_SUM_TOTAL_BACK_COMMISSION_DESC',
  DealsSumTotalBackSalesAsc = 'DEALS_SUM_TOTAL_BACK_SALES_ASC',
  DealsSumTotalBackSalesDesc = 'DEALS_SUM_TOTAL_BACK_SALES_DESC',
  DealsSumTotalBackCostAsc = 'DEALS_SUM_TOTAL_BACK_COST_ASC',
  DealsSumTotalBackCostDesc = 'DEALS_SUM_TOTAL_BACK_COST_DESC',
  DealsSumBackGrossAsc = 'DEALS_SUM_BACK_GROSS_ASC',
  DealsSumBackGrossDesc = 'DEALS_SUM_BACK_GROSS_DESC',
  DealsSumTotalProfitAsc = 'DEALS_SUM_TOTAL_PROFIT_ASC',
  DealsSumTotalProfitDesc = 'DEALS_SUM_TOTAL_PROFIT_DESC',
  DealsSumGrossProfitAsc = 'DEALS_SUM_GROSS_PROFIT_ASC',
  DealsSumGrossProfitDesc = 'DEALS_SUM_GROSS_PROFIT_DESC',
  DealsSumGrossPayableAsc = 'DEALS_SUM_GROSS_PAYABLE_ASC',
  DealsSumGrossPayableDesc = 'DEALS_SUM_GROSS_PAYABLE_DESC',
  DealsSumDealStatusAsc = 'DEALS_SUM_DEAL_STATUS_ASC',
  DealsSumDealStatusDesc = 'DEALS_SUM_DEAL_STATUS_DESC',
  DealsSumEntryDateAsc = 'DEALS_SUM_ENTRY_DATE_ASC',
  DealsSumEntryDateDesc = 'DEALS_SUM_ENTRY_DATE_DESC',
  DealsSumBookedDateAsc = 'DEALS_SUM_BOOKED_DATE_ASC',
  DealsSumBookedDateDesc = 'DEALS_SUM_BOOKED_DATE_DESC',
  DealsSumFinalizedDateAsc = 'DEALS_SUM_FINALIZED_DATE_ASC',
  DealsSumFinalizedDateDesc = 'DEALS_SUM_FINALIZED_DATE_DESC',
  DealsSumContractDateAsc = 'DEALS_SUM_CONTRACT_DATE_ASC',
  DealsSumContractDateDesc = 'DEALS_SUM_CONTRACT_DATE_DESC',
  DealsSumAccountingDateAsc = 'DEALS_SUM_ACCOUNTING_DATE_ASC',
  DealsSumAccountingDateDesc = 'DEALS_SUM_ACCOUNTING_DATE_DESC',
  DealsSumStatusChangeDateAsc = 'DEALS_SUM_STATUS_CHANGE_DATE_ASC',
  DealsSumStatusChangeDateDesc = 'DEALS_SUM_STATUS_CHANGE_DATE_DESC',
  DealsSumFirstPayDateAsc = 'DEALS_SUM_FIRST_PAY_DATE_ASC',
  DealsSumFirstPayDateDesc = 'DEALS_SUM_FIRST_PAY_DATE_DESC',
  DealsSumDealTypeAsc = 'DEALS_SUM_DEAL_TYPE_ASC',
  DealsSumDealTypeDesc = 'DEALS_SUM_DEAL_TYPE_DESC',
  DealsSumSaleTypeAsc = 'DEALS_SUM_SALE_TYPE_ASC',
  DealsSumSaleTypeDesc = 'DEALS_SUM_SALE_TYPE_DESC',
  DealsSumBankIdAsc = 'DEALS_SUM_BANK_ID_ASC',
  DealsSumBankIdDesc = 'DEALS_SUM_BANK_ID_DESC',
  DealsSumBankNameAsc = 'DEALS_SUM_BANK_NAME_ASC',
  DealsSumBankNameDesc = 'DEALS_SUM_BANK_NAME_DESC',
  DealsSumBankAddressAsc = 'DEALS_SUM_BANK_ADDRESS_ASC',
  DealsSumBankAddressDesc = 'DEALS_SUM_BANK_ADDRESS_DESC',
  DealsSumTermAsc = 'DEALS_SUM_TERM_ASC',
  DealsSumTermDesc = 'DEALS_SUM_TERM_DESC',
  DealsSumAmountFinancedAsc = 'DEALS_SUM_AMOUNT_FINANCED_ASC',
  DealsSumAmountFinancedDesc = 'DEALS_SUM_AMOUNT_FINANCED_DESC',
  DealsSumAprAsc = 'DEALS_SUM_APR_ASC',
  DealsSumAprDesc = 'DEALS_SUM_APR_DESC',
  DealsSumMonthlyPaymentAsc = 'DEALS_SUM_MONTHLY_PAYMENT_ASC',
  DealsSumMonthlyPaymentDesc = 'DEALS_SUM_MONTHLY_PAYMENT_DESC',
  DealsSumPaymentTotalAsc = 'DEALS_SUM_PAYMENT_TOTAL_ASC',
  DealsSumPaymentTotalDesc = 'DEALS_SUM_PAYMENT_TOTAL_DESC',
  DealsSumRebatesAsc = 'DEALS_SUM_REBATES_ASC',
  DealsSumRebatesDesc = 'DEALS_SUM_REBATES_DESC',
  DealsSumDepositAsc = 'DEALS_SUM_DEPOSIT_ASC',
  DealsSumDepositDesc = 'DEALS_SUM_DEPOSIT_DESC',
  DealsSumDownPaymentAsc = 'DEALS_SUM_DOWN_PAYMENT_ASC',
  DealsSumDownPaymentDesc = 'DEALS_SUM_DOWN_PAYMENT_DESC',
  DealsSumTotalNetTradesAsc = 'DEALS_SUM_TOTAL_NET_TRADES_ASC',
  DealsSumTotalNetTradesDesc = 'DEALS_SUM_TOTAL_NET_TRADES_DESC',
  DealsSumTotalDownAsc = 'DEALS_SUM_TOTAL_DOWN_ASC',
  DealsSumTotalDownDesc = 'DEALS_SUM_TOTAL_DOWN_DESC',
  DealsSumBalloonAmountAsc = 'DEALS_SUM_BALLOON_AMOUNT_ASC',
  DealsSumBalloonAmountDesc = 'DEALS_SUM_BALLOON_AMOUNT_DESC',
  DealsSumAdjustedBalloonAmountAsc = 'DEALS_SUM_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsSumAdjustedBalloonAmountDesc = 'DEALS_SUM_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsSumHoldbackAmountAsc = 'DEALS_SUM_HOLDBACK_AMOUNT_ASC',
  DealsSumHoldbackAmountDesc = 'DEALS_SUM_HOLDBACK_AMOUNT_DESC',
  DealsSumTotalDriveOffAmountAsc = 'DEALS_SUM_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsSumTotalDriveOffAmountDesc = 'DEALS_SUM_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsSumLicenseFeeAsc = 'DEALS_SUM_LICENSE_FEE_ASC',
  DealsSumLicenseFeeDesc = 'DEALS_SUM_LICENSE_FEE_DESC',
  DealsSumRegistrationFeeAsc = 'DEALS_SUM_REGISTRATION_FEE_ASC',
  DealsSumRegistrationFeeDesc = 'DEALS_SUM_REGISTRATION_FEE_DESC',
  DealsSumDocumentationFeeAsc = 'DEALS_SUM_DOCUMENTATION_FEE_ASC',
  DealsSumDocumentationFeeDesc = 'DEALS_SUM_DOCUMENTATION_FEE_DESC',
  DealsSumFinanceChargeAsc = 'DEALS_SUM_FINANCE_CHARGE_ASC',
  DealsSumFinanceChargeDesc = 'DEALS_SUM_FINANCE_CHARGE_DESC',
  DealsSumTotalPickupPaymentsAsc = 'DEALS_SUM_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsSumTotalPickupPaymentsDesc = 'DEALS_SUM_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsSumSellRateAsc = 'DEALS_SUM_SELL_RATE_ASC',
  DealsSumSellRateDesc = 'DEALS_SUM_SELL_RATE_DESC',
  DealsSumBuyRateAsc = 'DEALS_SUM_BUY_RATE_ASC',
  DealsSumBuyRateDesc = 'DEALS_SUM_BUY_RATE_DESC',
  DealsSumResidualRateAsc = 'DEALS_SUM_RESIDUAL_RATE_ASC',
  DealsSumResidualRateDesc = 'DEALS_SUM_RESIDUAL_RATE_DESC',
  DealsSumResidualAmountAsc = 'DEALS_SUM_RESIDUAL_AMOUNT_ASC',
  DealsSumResidualAmountDesc = 'DEALS_SUM_RESIDUAL_AMOUNT_DESC',
  DealsSumAllowedMilesAsc = 'DEALS_SUM_ALLOWED_MILES_ASC',
  DealsSumAllowedMilesDesc = 'DEALS_SUM_ALLOWED_MILES_DESC',
  DealsSumEstimatedMilesAsc = 'DEALS_SUM_ESTIMATED_MILES_ASC',
  DealsSumEstimatedMilesDesc = 'DEALS_SUM_ESTIMATED_MILES_DESC',
  DealsSumMileageRateAsc = 'DEALS_SUM_MILEAGE_RATE_ASC',
  DealsSumMileageRateDesc = 'DEALS_SUM_MILEAGE_RATE_DESC',
  DealsSumAcquisitionFeeAsc = 'DEALS_SUM_ACQUISITION_FEE_ASC',
  DealsSumAcquisitionFeeDesc = 'DEALS_SUM_ACQUISITION_FEE_DESC',
  DealsSumBasePaymentAsc = 'DEALS_SUM_BASE_PAYMENT_ASC',
  DealsSumBasePaymentDesc = 'DEALS_SUM_BASE_PAYMENT_DESC',
  DealsSumSecurityDepositAsc = 'DEALS_SUM_SECURITY_DEPOSIT_ASC',
  DealsSumSecurityDepositDesc = 'DEALS_SUM_SECURITY_DEPOSIT_DESC',
  DealsSumTotalCapitalReductionAsc = 'DEALS_SUM_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsSumTotalCapitalReductionDesc = 'DEALS_SUM_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsSumNetCapitalCostAsc = 'DEALS_SUM_NET_CAPITAL_COST_ASC',
  DealsSumNetCapitalCostDesc = 'DEALS_SUM_NET_CAPITAL_COST_DESC',
  DealsSumLeaseDepreciationValueAsc = 'DEALS_SUM_LEASE_DEPRECIATION_VALUE_ASC',
  DealsSumLeaseDepreciationValueDesc = 'DEALS_SUM_LEASE_DEPRECIATION_VALUE_DESC',
  DealsSumDealerFeesAsc = 'DEALS_SUM_DEALER_FEES_ASC',
  DealsSumDealerFeesDesc = 'DEALS_SUM_DEALER_FEES_DESC',
  DealsSumGovernmentFeesAsc = 'DEALS_SUM_GOVERNMENT_FEES_ASC',
  DealsSumGovernmentFeesDesc = 'DEALS_SUM_GOVERNMENT_FEES_DESC',
  DealsSumTotalTaxAsc = 'DEALS_SUM_TOTAL_TAX_ASC',
  DealsSumTotalTaxDesc = 'DEALS_SUM_TOTAL_TAX_DESC',
  DealsSumRegistrationStateAsc = 'DEALS_SUM_REGISTRATION_STATE_ASC',
  DealsSumRegistrationStateDesc = 'DEALS_SUM_REGISTRATION_STATE_DESC',
  DealsSumReportOfSaleNumberAsc = 'DEALS_SUM_REPORT_OF_SALE_NUMBER_ASC',
  DealsSumReportOfSaleNumberDesc = 'DEALS_SUM_REPORT_OF_SALE_NUMBER_DESC',
  DealsSumSalesman_1TotalCommissionAsc = 'DEALS_SUM_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsSumSalesman_1TotalCommissionDesc = 'DEALS_SUM_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsSumSalesman_1FrontCommissionAsc = 'DEALS_SUM_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsSumSalesman_1FrontCommissionDesc = 'DEALS_SUM_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsSumSalesman_1BackCommissionAsc = 'DEALS_SUM_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsSumSalesman_1BackCommissionDesc = 'DEALS_SUM_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsSumSalesman_2TotalCommissionAsc = 'DEALS_SUM_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsSumSalesman_2TotalCommissionDesc = 'DEALS_SUM_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsSumSalesman_2FrontCommissionAsc = 'DEALS_SUM_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsSumSalesman_2FrontCommissionDesc = 'DEALS_SUM_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsSumSalesman_2BackCommissionAsc = 'DEALS_SUM_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsSumSalesman_2BackCommissionDesc = 'DEALS_SUM_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsSumSalesman_3TotalCommissionAsc = 'DEALS_SUM_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsSumSalesman_3TotalCommissionDesc = 'DEALS_SUM_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsSumSalesman_3FrontCommissionAsc = 'DEALS_SUM_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsSumSalesman_3FrontCommissionDesc = 'DEALS_SUM_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsSumSalesman_3BackCommissionAsc = 'DEALS_SUM_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsSumSalesman_3BackCommissionDesc = 'DEALS_SUM_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsSumWarranty_1NameAsc = 'DEALS_SUM_WARRANTY_1_NAME_ASC',
  DealsSumWarranty_1NameDesc = 'DEALS_SUM_WARRANTY_1_NAME_DESC',
  DealsSumWarranty_1SaleAsc = 'DEALS_SUM_WARRANTY_1_SALE_ASC',
  DealsSumWarranty_1SaleDesc = 'DEALS_SUM_WARRANTY_1_SALE_DESC',
  DealsSumWarranty_1CostAsc = 'DEALS_SUM_WARRANTY_1_COST_ASC',
  DealsSumWarranty_1CostDesc = 'DEALS_SUM_WARRANTY_1_COST_DESC',
  DealsSumWarranty_1MilesAsc = 'DEALS_SUM_WARRANTY_1_MILES_ASC',
  DealsSumWarranty_1MilesDesc = 'DEALS_SUM_WARRANTY_1_MILES_DESC',
  DealsSumWarranty_1TermAsc = 'DEALS_SUM_WARRANTY_1_TERM_ASC',
  DealsSumWarranty_1TermDesc = 'DEALS_SUM_WARRANTY_1_TERM_DESC',
  DealsSumWarranty_2NameAsc = 'DEALS_SUM_WARRANTY_2_NAME_ASC',
  DealsSumWarranty_2NameDesc = 'DEALS_SUM_WARRANTY_2_NAME_DESC',
  DealsSumWarranty_2SaleAsc = 'DEALS_SUM_WARRANTY_2_SALE_ASC',
  DealsSumWarranty_2SaleDesc = 'DEALS_SUM_WARRANTY_2_SALE_DESC',
  DealsSumWarranty_2CostAsc = 'DEALS_SUM_WARRANTY_2_COST_ASC',
  DealsSumWarranty_2CostDesc = 'DEALS_SUM_WARRANTY_2_COST_DESC',
  DealsSumWarranty_2MilesAsc = 'DEALS_SUM_WARRANTY_2_MILES_ASC',
  DealsSumWarranty_2MilesDesc = 'DEALS_SUM_WARRANTY_2_MILES_DESC',
  DealsSumWarranty_2TermAsc = 'DEALS_SUM_WARRANTY_2_TERM_ASC',
  DealsSumWarranty_2TermDesc = 'DEALS_SUM_WARRANTY_2_TERM_DESC',
  DealsSumWarranty_3NameAsc = 'DEALS_SUM_WARRANTY_3_NAME_ASC',
  DealsSumWarranty_3NameDesc = 'DEALS_SUM_WARRANTY_3_NAME_DESC',
  DealsSumWarranty_3SaleAsc = 'DEALS_SUM_WARRANTY_3_SALE_ASC',
  DealsSumWarranty_3SaleDesc = 'DEALS_SUM_WARRANTY_3_SALE_DESC',
  DealsSumWarranty_3CostAsc = 'DEALS_SUM_WARRANTY_3_COST_ASC',
  DealsSumWarranty_3CostDesc = 'DEALS_SUM_WARRANTY_3_COST_DESC',
  DealsSumWarranty_3MilesAsc = 'DEALS_SUM_WARRANTY_3_MILES_ASC',
  DealsSumWarranty_3MilesDesc = 'DEALS_SUM_WARRANTY_3_MILES_DESC',
  DealsSumWarranty_3TermAsc = 'DEALS_SUM_WARRANTY_3_TERM_ASC',
  DealsSumWarranty_3TermDesc = 'DEALS_SUM_WARRANTY_3_TERM_DESC',
  DealsSumWarranty_4NameAsc = 'DEALS_SUM_WARRANTY_4_NAME_ASC',
  DealsSumWarranty_4NameDesc = 'DEALS_SUM_WARRANTY_4_NAME_DESC',
  DealsSumWarranty_4SaleAsc = 'DEALS_SUM_WARRANTY_4_SALE_ASC',
  DealsSumWarranty_4SaleDesc = 'DEALS_SUM_WARRANTY_4_SALE_DESC',
  DealsSumWarranty_4CostAsc = 'DEALS_SUM_WARRANTY_4_COST_ASC',
  DealsSumWarranty_4CostDesc = 'DEALS_SUM_WARRANTY_4_COST_DESC',
  DealsSumWarranty_4MilesAsc = 'DEALS_SUM_WARRANTY_4_MILES_ASC',
  DealsSumWarranty_4MilesDesc = 'DEALS_SUM_WARRANTY_4_MILES_DESC',
  DealsSumWarranty_4TermAsc = 'DEALS_SUM_WARRANTY_4_TERM_ASC',
  DealsSumWarranty_4TermDesc = 'DEALS_SUM_WARRANTY_4_TERM_DESC',
  DealsSumWarranty_5NameAsc = 'DEALS_SUM_WARRANTY_5_NAME_ASC',
  DealsSumWarranty_5NameDesc = 'DEALS_SUM_WARRANTY_5_NAME_DESC',
  DealsSumWarranty_5SaleAsc = 'DEALS_SUM_WARRANTY_5_SALE_ASC',
  DealsSumWarranty_5SaleDesc = 'DEALS_SUM_WARRANTY_5_SALE_DESC',
  DealsSumWarranty_5CostAsc = 'DEALS_SUM_WARRANTY_5_COST_ASC',
  DealsSumWarranty_5CostDesc = 'DEALS_SUM_WARRANTY_5_COST_DESC',
  DealsSumWarranty_5MilesAsc = 'DEALS_SUM_WARRANTY_5_MILES_ASC',
  DealsSumWarranty_5MilesDesc = 'DEALS_SUM_WARRANTY_5_MILES_DESC',
  DealsSumWarranty_5TermAsc = 'DEALS_SUM_WARRANTY_5_TERM_ASC',
  DealsSumWarranty_5TermDesc = 'DEALS_SUM_WARRANTY_5_TERM_DESC',
  DealsSumTotalFeeAftermarketSaleAsc = 'DEALS_SUM_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsSumTotalFeeAftermarketSaleDesc = 'DEALS_SUM_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsSumTotalFeeAftermarketCostAsc = 'DEALS_SUM_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsSumTotalFeeAftermarketCostDesc = 'DEALS_SUM_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsSumFeeAftermarket_1NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_1_NAME_ASC',
  DealsSumFeeAftermarket_1NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_1_NAME_DESC',
  DealsSumFeeAftermarket_1SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_1_SALE_ASC',
  DealsSumFeeAftermarket_1SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_1_SALE_DESC',
  DealsSumFeeAftermarket_1CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_1_COST_ASC',
  DealsSumFeeAftermarket_1CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_1_COST_DESC',
  DealsSumFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_2NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_2_NAME_ASC',
  DealsSumFeeAftermarket_2NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_2_NAME_DESC',
  DealsSumFeeAftermarket_2SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_2_SALE_ASC',
  DealsSumFeeAftermarket_2SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_2_SALE_DESC',
  DealsSumFeeAftermarket_2CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_2_COST_ASC',
  DealsSumFeeAftermarket_2CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_2_COST_DESC',
  DealsSumFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_3NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_3_NAME_ASC',
  DealsSumFeeAftermarket_3NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_3_NAME_DESC',
  DealsSumFeeAftermarket_3SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_3_SALE_ASC',
  DealsSumFeeAftermarket_3SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_3_SALE_DESC',
  DealsSumFeeAftermarket_3CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_3_COST_ASC',
  DealsSumFeeAftermarket_3CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_3_COST_DESC',
  DealsSumFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_4NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_4_NAME_ASC',
  DealsSumFeeAftermarket_4NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_4_NAME_DESC',
  DealsSumFeeAftermarket_4SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_4_SALE_ASC',
  DealsSumFeeAftermarket_4SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_4_SALE_DESC',
  DealsSumFeeAftermarket_4CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_4_COST_ASC',
  DealsSumFeeAftermarket_4CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_4_COST_DESC',
  DealsSumFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_5NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_5_NAME_ASC',
  DealsSumFeeAftermarket_5NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_5_NAME_DESC',
  DealsSumFeeAftermarket_5SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_5_SALE_ASC',
  DealsSumFeeAftermarket_5SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_5_SALE_DESC',
  DealsSumFeeAftermarket_5CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_5_COST_ASC',
  DealsSumFeeAftermarket_5CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_5_COST_DESC',
  DealsSumFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_6NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_6_NAME_ASC',
  DealsSumFeeAftermarket_6NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_6_NAME_DESC',
  DealsSumFeeAftermarket_6SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_6_SALE_ASC',
  DealsSumFeeAftermarket_6SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_6_SALE_DESC',
  DealsSumFeeAftermarket_6CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_6_COST_ASC',
  DealsSumFeeAftermarket_6CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_6_COST_DESC',
  DealsSumFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_7NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_7_NAME_ASC',
  DealsSumFeeAftermarket_7NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_7_NAME_DESC',
  DealsSumFeeAftermarket_7SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_7_SALE_ASC',
  DealsSumFeeAftermarket_7SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_7_SALE_DESC',
  DealsSumFeeAftermarket_7CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_7_COST_ASC',
  DealsSumFeeAftermarket_7CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_7_COST_DESC',
  DealsSumFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_8NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_8_NAME_ASC',
  DealsSumFeeAftermarket_8NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_8_NAME_DESC',
  DealsSumFeeAftermarket_8SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_8_SALE_ASC',
  DealsSumFeeAftermarket_8SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_8_SALE_DESC',
  DealsSumFeeAftermarket_8CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_8_COST_ASC',
  DealsSumFeeAftermarket_8CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_8_COST_DESC',
  DealsSumFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_9NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_9_NAME_ASC',
  DealsSumFeeAftermarket_9NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_9_NAME_DESC',
  DealsSumFeeAftermarket_9SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_9_SALE_ASC',
  DealsSumFeeAftermarket_9SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_9_SALE_DESC',
  DealsSumFeeAftermarket_9CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_9_COST_ASC',
  DealsSumFeeAftermarket_9CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_9_COST_DESC',
  DealsSumFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_10NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_10_NAME_ASC',
  DealsSumFeeAftermarket_10NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_10_NAME_DESC',
  DealsSumFeeAftermarket_10SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_10_SALE_ASC',
  DealsSumFeeAftermarket_10SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_10_SALE_DESC',
  DealsSumFeeAftermarket_10CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_10_COST_ASC',
  DealsSumFeeAftermarket_10CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_10_COST_DESC',
  DealsSumFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_11NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_11_NAME_ASC',
  DealsSumFeeAftermarket_11NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_11_NAME_DESC',
  DealsSumFeeAftermarket_11SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_11_SALE_ASC',
  DealsSumFeeAftermarket_11SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_11_SALE_DESC',
  DealsSumFeeAftermarket_11CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_11_COST_ASC',
  DealsSumFeeAftermarket_11CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_11_COST_DESC',
  DealsSumFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_12NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_12_NAME_ASC',
  DealsSumFeeAftermarket_12NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_12_NAME_DESC',
  DealsSumFeeAftermarket_12SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_12_SALE_ASC',
  DealsSumFeeAftermarket_12SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_12_SALE_DESC',
  DealsSumFeeAftermarket_12CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_12_COST_ASC',
  DealsSumFeeAftermarket_12CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_12_COST_DESC',
  DealsSumFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_13NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_13_NAME_ASC',
  DealsSumFeeAftermarket_13NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_13_NAME_DESC',
  DealsSumFeeAftermarket_13SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_13_SALE_ASC',
  DealsSumFeeAftermarket_13SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_13_SALE_DESC',
  DealsSumFeeAftermarket_13CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_13_COST_ASC',
  DealsSumFeeAftermarket_13CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_13_COST_DESC',
  DealsSumFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_14NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_14_NAME_ASC',
  DealsSumFeeAftermarket_14NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_14_NAME_DESC',
  DealsSumFeeAftermarket_14SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_14_SALE_ASC',
  DealsSumFeeAftermarket_14SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_14_SALE_DESC',
  DealsSumFeeAftermarket_14CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_14_COST_ASC',
  DealsSumFeeAftermarket_14CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_14_COST_DESC',
  DealsSumFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_15NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_15_NAME_ASC',
  DealsSumFeeAftermarket_15NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_15_NAME_DESC',
  DealsSumFeeAftermarket_15SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_15_SALE_ASC',
  DealsSumFeeAftermarket_15SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_15_SALE_DESC',
  DealsSumFeeAftermarket_15CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_15_COST_ASC',
  DealsSumFeeAftermarket_15CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_15_COST_DESC',
  DealsSumFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_16NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_16_NAME_ASC',
  DealsSumFeeAftermarket_16NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_16_NAME_DESC',
  DealsSumFeeAftermarket_16SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_16_SALE_ASC',
  DealsSumFeeAftermarket_16SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_16_SALE_DESC',
  DealsSumFeeAftermarket_16CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_16_COST_ASC',
  DealsSumFeeAftermarket_16CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_16_COST_DESC',
  DealsSumFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_17NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_17_NAME_ASC',
  DealsSumFeeAftermarket_17NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_17_NAME_DESC',
  DealsSumFeeAftermarket_17SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_17_SALE_ASC',
  DealsSumFeeAftermarket_17SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_17_SALE_DESC',
  DealsSumFeeAftermarket_17CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_17_COST_ASC',
  DealsSumFeeAftermarket_17CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_17_COST_DESC',
  DealsSumFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_18NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_18_NAME_ASC',
  DealsSumFeeAftermarket_18NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_18_NAME_DESC',
  DealsSumFeeAftermarket_18SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_18_SALE_ASC',
  DealsSumFeeAftermarket_18SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_18_SALE_DESC',
  DealsSumFeeAftermarket_18CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_18_COST_ASC',
  DealsSumFeeAftermarket_18CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_18_COST_DESC',
  DealsSumFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_19NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_19_NAME_ASC',
  DealsSumFeeAftermarket_19NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_19_NAME_DESC',
  DealsSumFeeAftermarket_19SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_19_SALE_ASC',
  DealsSumFeeAftermarket_19SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_19_SALE_DESC',
  DealsSumFeeAftermarket_19CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_19_COST_ASC',
  DealsSumFeeAftermarket_19CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_19_COST_DESC',
  DealsSumFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsSumFeeAftermarket_20NameAsc = 'DEALS_SUM_FEE_AFTERMARKET_20_NAME_ASC',
  DealsSumFeeAftermarket_20NameDesc = 'DEALS_SUM_FEE_AFTERMARKET_20_NAME_DESC',
  DealsSumFeeAftermarket_20SaleAsc = 'DEALS_SUM_FEE_AFTERMARKET_20_SALE_ASC',
  DealsSumFeeAftermarket_20SaleDesc = 'DEALS_SUM_FEE_AFTERMARKET_20_SALE_DESC',
  DealsSumFeeAftermarket_20CostAsc = 'DEALS_SUM_FEE_AFTERMARKET_20_COST_ASC',
  DealsSumFeeAftermarket_20CostDesc = 'DEALS_SUM_FEE_AFTERMARKET_20_COST_DESC',
  DealsSumFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_SUM_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsSumFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_SUM_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsSumInsurance_1TypeAsc = 'DEALS_SUM_INSURANCE_1_TYPE_ASC',
  DealsSumInsurance_1TypeDesc = 'DEALS_SUM_INSURANCE_1_TYPE_DESC',
  DealsSumInsurance_1NameAsc = 'DEALS_SUM_INSURANCE_1_NAME_ASC',
  DealsSumInsurance_1NameDesc = 'DEALS_SUM_INSURANCE_1_NAME_DESC',
  DealsSumInsurance_1SaleAsc = 'DEALS_SUM_INSURANCE_1_SALE_ASC',
  DealsSumInsurance_1SaleDesc = 'DEALS_SUM_INSURANCE_1_SALE_DESC',
  DealsSumInsurance_1CostAsc = 'DEALS_SUM_INSURANCE_1_COST_ASC',
  DealsSumInsurance_1CostDesc = 'DEALS_SUM_INSURANCE_1_COST_DESC',
  DealsSumInsurance_1TermAsc = 'DEALS_SUM_INSURANCE_1_TERM_ASC',
  DealsSumInsurance_1TermDesc = 'DEALS_SUM_INSURANCE_1_TERM_DESC',
  DealsSumInsurance_2TypeAsc = 'DEALS_SUM_INSURANCE_2_TYPE_ASC',
  DealsSumInsurance_2TypeDesc = 'DEALS_SUM_INSURANCE_2_TYPE_DESC',
  DealsSumInsurance_2NameAsc = 'DEALS_SUM_INSURANCE_2_NAME_ASC',
  DealsSumInsurance_2NameDesc = 'DEALS_SUM_INSURANCE_2_NAME_DESC',
  DealsSumInsurance_2SaleAsc = 'DEALS_SUM_INSURANCE_2_SALE_ASC',
  DealsSumInsurance_2SaleDesc = 'DEALS_SUM_INSURANCE_2_SALE_DESC',
  DealsSumInsurance_2CostAsc = 'DEALS_SUM_INSURANCE_2_COST_ASC',
  DealsSumInsurance_2CostDesc = 'DEALS_SUM_INSURANCE_2_COST_DESC',
  DealsSumInsurance_2TermAsc = 'DEALS_SUM_INSURANCE_2_TERM_ASC',
  DealsSumInsurance_2TermDesc = 'DEALS_SUM_INSURANCE_2_TERM_DESC',
  DealsSumInsurance_3TypeAsc = 'DEALS_SUM_INSURANCE_3_TYPE_ASC',
  DealsSumInsurance_3TypeDesc = 'DEALS_SUM_INSURANCE_3_TYPE_DESC',
  DealsSumInsurance_3NameAsc = 'DEALS_SUM_INSURANCE_3_NAME_ASC',
  DealsSumInsurance_3NameDesc = 'DEALS_SUM_INSURANCE_3_NAME_DESC',
  DealsSumInsurance_3SaleAsc = 'DEALS_SUM_INSURANCE_3_SALE_ASC',
  DealsSumInsurance_3SaleDesc = 'DEALS_SUM_INSURANCE_3_SALE_DESC',
  DealsSumInsurance_3CostAsc = 'DEALS_SUM_INSURANCE_3_COST_ASC',
  DealsSumInsurance_3CostDesc = 'DEALS_SUM_INSURANCE_3_COST_DESC',
  DealsSumInsurance_3TermAsc = 'DEALS_SUM_INSURANCE_3_TERM_ASC',
  DealsSumInsurance_3TermDesc = 'DEALS_SUM_INSURANCE_3_TERM_DESC',
  DealsSumAccidentalHealthTypeAsc = 'DEALS_SUM_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsSumAccidentalHealthTypeDesc = 'DEALS_SUM_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsSumAccidentalHealthNameAsc = 'DEALS_SUM_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsSumAccidentalHealthNameDesc = 'DEALS_SUM_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsSumAccidentalHealthSaleAsc = 'DEALS_SUM_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsSumAccidentalHealthSaleDesc = 'DEALS_SUM_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsSumAccidentalHealthCostAsc = 'DEALS_SUM_ACCIDENTAL_HEALTH_COST_ASC',
  DealsSumAccidentalHealthCostDesc = 'DEALS_SUM_ACCIDENTAL_HEALTH_COST_DESC',
  DealsSumAccidentalHealthTermAsc = 'DEALS_SUM_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsSumAccidentalHealthTermDesc = 'DEALS_SUM_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsSumCreditLifeTypeAsc = 'DEALS_SUM_CREDIT_LIFE_TYPE_ASC',
  DealsSumCreditLifeTypeDesc = 'DEALS_SUM_CREDIT_LIFE_TYPE_DESC',
  DealsSumCreditLifeNameAsc = 'DEALS_SUM_CREDIT_LIFE_NAME_ASC',
  DealsSumCreditLifeNameDesc = 'DEALS_SUM_CREDIT_LIFE_NAME_DESC',
  DealsSumCreditLifeSaleAsc = 'DEALS_SUM_CREDIT_LIFE_SALE_ASC',
  DealsSumCreditLifeSaleDesc = 'DEALS_SUM_CREDIT_LIFE_SALE_DESC',
  DealsSumCreditLifeCostAsc = 'DEALS_SUM_CREDIT_LIFE_COST_ASC',
  DealsSumCreditLifeCostDesc = 'DEALS_SUM_CREDIT_LIFE_COST_DESC',
  DealsSumCreditLifeTermAsc = 'DEALS_SUM_CREDIT_LIFE_TERM_ASC',
  DealsSumCreditLifeTermDesc = 'DEALS_SUM_CREDIT_LIFE_TERM_DESC',
  DealsSumLevelizedLifeTypeAsc = 'DEALS_SUM_LEVELIZED_LIFE_TYPE_ASC',
  DealsSumLevelizedLifeTypeDesc = 'DEALS_SUM_LEVELIZED_LIFE_TYPE_DESC',
  DealsSumLevelizedLifeNameAsc = 'DEALS_SUM_LEVELIZED_LIFE_NAME_ASC',
  DealsSumLevelizedLifeNameDesc = 'DEALS_SUM_LEVELIZED_LIFE_NAME_DESC',
  DealsSumLevelizedLifeSaleAsc = 'DEALS_SUM_LEVELIZED_LIFE_SALE_ASC',
  DealsSumLevelizedLifeSaleDesc = 'DEALS_SUM_LEVELIZED_LIFE_SALE_DESC',
  DealsSumLevelizedLifeCostAsc = 'DEALS_SUM_LEVELIZED_LIFE_COST_ASC',
  DealsSumLevelizedLifeCostDesc = 'DEALS_SUM_LEVELIZED_LIFE_COST_DESC',
  DealsSumLevelizedLifeTermAsc = 'DEALS_SUM_LEVELIZED_LIFE_TERM_ASC',
  DealsSumLevelizedLifeTermDesc = 'DEALS_SUM_LEVELIZED_LIFE_TERM_DESC',
  DealsSumLossOfEmploymentTypeAsc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsSumLossOfEmploymentTypeDesc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsSumLossOfEmploymentNameAsc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsSumLossOfEmploymentNameDesc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsSumLossOfEmploymentSaleAsc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsSumLossOfEmploymentSaleDesc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsSumLossOfEmploymentCostAsc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsSumLossOfEmploymentCostDesc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsSumLossOfEmploymentTermAsc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsSumLossOfEmploymentTermDesc = 'DEALS_SUM_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsSumGuaranteedAutoProtectionTypeAsc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsSumGuaranteedAutoProtectionTypeDesc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsSumGuaranteedAutoProtectionNameAsc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsSumGuaranteedAutoProtectionNameDesc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsSumGuaranteedAutoProtectionSaleAsc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsSumGuaranteedAutoProtectionSaleDesc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsSumGuaranteedAutoProtectionCostAsc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsSumGuaranteedAutoProtectionCostDesc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsSumGuaranteedAutoProtectionTermAsc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsSumGuaranteedAutoProtectionTermDesc = 'DEALS_SUM_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsSumSaleCommentsAsc = 'DEALS_SUM_SALE_COMMENTS_ASC',
  DealsSumSaleCommentsDesc = 'DEALS_SUM_SALE_COMMENTS_DESC',
  DealsSumCassStdLine1Asc = 'DEALS_SUM_CASS_STD_LINE1_ASC',
  DealsSumCassStdLine1Desc = 'DEALS_SUM_CASS_STD_LINE1_DESC',
  DealsSumCassStdLine2Asc = 'DEALS_SUM_CASS_STD_LINE2_ASC',
  DealsSumCassStdLine2Desc = 'DEALS_SUM_CASS_STD_LINE2_DESC',
  DealsSumCassStdCityAsc = 'DEALS_SUM_CASS_STD_CITY_ASC',
  DealsSumCassStdCityDesc = 'DEALS_SUM_CASS_STD_CITY_DESC',
  DealsSumCassStdStateAsc = 'DEALS_SUM_CASS_STD_STATE_ASC',
  DealsSumCassStdStateDesc = 'DEALS_SUM_CASS_STD_STATE_DESC',
  DealsSumCassStdZipAsc = 'DEALS_SUM_CASS_STD_ZIP_ASC',
  DealsSumCassStdZipDesc = 'DEALS_SUM_CASS_STD_ZIP_DESC',
  DealsSumCassStdZip4Asc = 'DEALS_SUM_CASS_STD_ZIP4_ASC',
  DealsSumCassStdZip4Desc = 'DEALS_SUM_CASS_STD_ZIP4_DESC',
  DealsSumCassStdDpbcAsc = 'DEALS_SUM_CASS_STD_DPBC_ASC',
  DealsSumCassStdDpbcDesc = 'DEALS_SUM_CASS_STD_DPBC_DESC',
  DealsSumCassStdChkdgtAsc = 'DEALS_SUM_CASS_STD_CHKDGT_ASC',
  DealsSumCassStdChkdgtDesc = 'DEALS_SUM_CASS_STD_CHKDGT_DESC',
  DealsSumCassStdCartAsc = 'DEALS_SUM_CASS_STD_CART_ASC',
  DealsSumCassStdCartDesc = 'DEALS_SUM_CASS_STD_CART_DESC',
  DealsSumCassStdLotAsc = 'DEALS_SUM_CASS_STD_LOT_ASC',
  DealsSumCassStdLotDesc = 'DEALS_SUM_CASS_STD_LOT_DESC',
  DealsSumCassStdLotordAsc = 'DEALS_SUM_CASS_STD_LOTORD_ASC',
  DealsSumCassStdLotordDesc = 'DEALS_SUM_CASS_STD_LOTORD_DESC',
  DealsSumCassStdUrbAsc = 'DEALS_SUM_CASS_STD_URB_ASC',
  DealsSumCassStdUrbDesc = 'DEALS_SUM_CASS_STD_URB_DESC',
  DealsSumCassStdFipsAsc = 'DEALS_SUM_CASS_STD_FIPS_ASC',
  DealsSumCassStdFipsDesc = 'DEALS_SUM_CASS_STD_FIPS_DESC',
  DealsSumCassStdEwsAsc = 'DEALS_SUM_CASS_STD_EWS_ASC',
  DealsSumCassStdEwsDesc = 'DEALS_SUM_CASS_STD_EWS_DESC',
  DealsSumCassStdLacsAsc = 'DEALS_SUM_CASS_STD_LACS_ASC',
  DealsSumCassStdLacsDesc = 'DEALS_SUM_CASS_STD_LACS_DESC',
  DealsSumCassStdZipmovAsc = 'DEALS_SUM_CASS_STD_ZIPMOV_ASC',
  DealsSumCassStdZipmovDesc = 'DEALS_SUM_CASS_STD_ZIPMOV_DESC',
  DealsSumCassStdZ4LomAsc = 'DEALS_SUM_CASS_STD_Z4LOM_ASC',
  DealsSumCassStdZ4LomDesc = 'DEALS_SUM_CASS_STD_Z4LOM_DESC',
  DealsSumCassStdNdiaptAsc = 'DEALS_SUM_CASS_STD_NDIAPT_ASC',
  DealsSumCassStdNdiaptDesc = 'DEALS_SUM_CASS_STD_NDIAPT_DESC',
  DealsSumCassStdNdirrAsc = 'DEALS_SUM_CASS_STD_NDIRR_ASC',
  DealsSumCassStdNdirrDesc = 'DEALS_SUM_CASS_STD_NDIRR_DESC',
  DealsSumCassStdLacsrtAsc = 'DEALS_SUM_CASS_STD_LACSRT_ASC',
  DealsSumCassStdLacsrtDesc = 'DEALS_SUM_CASS_STD_LACSRT_DESC',
  DealsSumCassStdErrorCdAsc = 'DEALS_SUM_CASS_STD_ERROR_CD_ASC',
  DealsSumCassStdErrorCdDesc = 'DEALS_SUM_CASS_STD_ERROR_CD_DESC',
  DealsDistinctCountIdAsc = 'DEALS_DISTINCT_COUNT_ID_ASC',
  DealsDistinctCountIdDesc = 'DEALS_DISTINCT_COUNT_ID_DESC',
  DealsDistinctCountLeadIdAsc = 'DEALS_DISTINCT_COUNT_LEAD_ID_ASC',
  DealsDistinctCountLeadIdDesc = 'DEALS_DISTINCT_COUNT_LEAD_ID_DESC',
  DealsDistinctCountCompanyIntegrationIdAsc = 'DEALS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  DealsDistinctCountCompanyIntegrationIdDesc = 'DEALS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  DealsDistinctCountFileTypeAsc = 'DEALS_DISTINCT_COUNT_FILE_TYPE_ASC',
  DealsDistinctCountFileTypeDesc = 'DEALS_DISTINCT_COUNT_FILE_TYPE_DESC',
  DealsDistinctCountDvDealerIdAsc = 'DEALS_DISTINCT_COUNT_DV_DEALER_ID_ASC',
  DealsDistinctCountDvDealerIdDesc = 'DEALS_DISTINCT_COUNT_DV_DEALER_ID_DESC',
  DealsDistinctCountVendorDealerIdAsc = 'DEALS_DISTINCT_COUNT_VENDOR_DEALER_ID_ASC',
  DealsDistinctCountVendorDealerIdDesc = 'DEALS_DISTINCT_COUNT_VENDOR_DEALER_ID_DESC',
  DealsDistinctCountDmsTypeAsc = 'DEALS_DISTINCT_COUNT_DMS_TYPE_ASC',
  DealsDistinctCountDmsTypeDesc = 'DEALS_DISTINCT_COUNT_DMS_TYPE_DESC',
  DealsDistinctCountDealNumberAsc = 'DEALS_DISTINCT_COUNT_DEAL_NUMBER_ASC',
  DealsDistinctCountDealNumberDesc = 'DEALS_DISTINCT_COUNT_DEAL_NUMBER_DESC',
  DealsDistinctCountCustomerNumberAsc = 'DEALS_DISTINCT_COUNT_CUSTOMER_NUMBER_ASC',
  DealsDistinctCountCustomerNumberDesc = 'DEALS_DISTINCT_COUNT_CUSTOMER_NUMBER_DESC',
  DealsDistinctCountFullNameAsc = 'DEALS_DISTINCT_COUNT_FULL_NAME_ASC',
  DealsDistinctCountFullNameDesc = 'DEALS_DISTINCT_COUNT_FULL_NAME_DESC',
  DealsDistinctCountSalutationAsc = 'DEALS_DISTINCT_COUNT_SALUTATION_ASC',
  DealsDistinctCountSalutationDesc = 'DEALS_DISTINCT_COUNT_SALUTATION_DESC',
  DealsDistinctCountFirstNameAsc = 'DEALS_DISTINCT_COUNT_FIRST_NAME_ASC',
  DealsDistinctCountFirstNameDesc = 'DEALS_DISTINCT_COUNT_FIRST_NAME_DESC',
  DealsDistinctCountMiddleNameAsc = 'DEALS_DISTINCT_COUNT_MIDDLE_NAME_ASC',
  DealsDistinctCountMiddleNameDesc = 'DEALS_DISTINCT_COUNT_MIDDLE_NAME_DESC',
  DealsDistinctCountLastNameAsc = 'DEALS_DISTINCT_COUNT_LAST_NAME_ASC',
  DealsDistinctCountLastNameDesc = 'DEALS_DISTINCT_COUNT_LAST_NAME_DESC',
  DealsDistinctCountSuffixAsc = 'DEALS_DISTINCT_COUNT_SUFFIX_ASC',
  DealsDistinctCountSuffixDesc = 'DEALS_DISTINCT_COUNT_SUFFIX_DESC',
  DealsDistinctCountAddressLine_1Asc = 'DEALS_DISTINCT_COUNT_ADDRESS_LINE_1_ASC',
  DealsDistinctCountAddressLine_1Desc = 'DEALS_DISTINCT_COUNT_ADDRESS_LINE_1_DESC',
  DealsDistinctCountAddressLine_2Asc = 'DEALS_DISTINCT_COUNT_ADDRESS_LINE_2_ASC',
  DealsDistinctCountAddressLine_2Desc = 'DEALS_DISTINCT_COUNT_ADDRESS_LINE_2_DESC',
  DealsDistinctCountCityAsc = 'DEALS_DISTINCT_COUNT_CITY_ASC',
  DealsDistinctCountCityDesc = 'DEALS_DISTINCT_COUNT_CITY_DESC',
  DealsDistinctCountStateAsc = 'DEALS_DISTINCT_COUNT_STATE_ASC',
  DealsDistinctCountStateDesc = 'DEALS_DISTINCT_COUNT_STATE_DESC',
  DealsDistinctCountZipAsc = 'DEALS_DISTINCT_COUNT_ZIP_ASC',
  DealsDistinctCountZipDesc = 'DEALS_DISTINCT_COUNT_ZIP_DESC',
  DealsDistinctCountCountyAsc = 'DEALS_DISTINCT_COUNT_COUNTY_ASC',
  DealsDistinctCountCountyDesc = 'DEALS_DISTINCT_COUNT_COUNTY_DESC',
  DealsDistinctCountHomePhoneAsc = 'DEALS_DISTINCT_COUNT_HOME_PHONE_ASC',
  DealsDistinctCountHomePhoneDesc = 'DEALS_DISTINCT_COUNT_HOME_PHONE_DESC',
  DealsDistinctCountCellPhoneAsc = 'DEALS_DISTINCT_COUNT_CELL_PHONE_ASC',
  DealsDistinctCountCellPhoneDesc = 'DEALS_DISTINCT_COUNT_CELL_PHONE_DESC',
  DealsDistinctCountWorkPhoneAsc = 'DEALS_DISTINCT_COUNT_WORK_PHONE_ASC',
  DealsDistinctCountWorkPhoneDesc = 'DEALS_DISTINCT_COUNT_WORK_PHONE_DESC',
  DealsDistinctCountWorkExtensionAsc = 'DEALS_DISTINCT_COUNT_WORK_EXTENSION_ASC',
  DealsDistinctCountWorkExtensionDesc = 'DEALS_DISTINCT_COUNT_WORK_EXTENSION_DESC',
  DealsDistinctCountEmail_1Asc = 'DEALS_DISTINCT_COUNT_EMAIL_1_ASC',
  DealsDistinctCountEmail_1Desc = 'DEALS_DISTINCT_COUNT_EMAIL_1_DESC',
  DealsDistinctCountEmail_2Asc = 'DEALS_DISTINCT_COUNT_EMAIL_2_ASC',
  DealsDistinctCountEmail_2Desc = 'DEALS_DISTINCT_COUNT_EMAIL_2_DESC',
  DealsDistinctCountEmail_3Asc = 'DEALS_DISTINCT_COUNT_EMAIL_3_ASC',
  DealsDistinctCountEmail_3Desc = 'DEALS_DISTINCT_COUNT_EMAIL_3_DESC',
  DealsDistinctCountBirthDateAsc = 'DEALS_DISTINCT_COUNT_BIRTH_DATE_ASC',
  DealsDistinctCountBirthDateDesc = 'DEALS_DISTINCT_COUNT_BIRTH_DATE_DESC',
  DealsDistinctCountIndividualBusinessFlagAsc = 'DEALS_DISTINCT_COUNT_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsDistinctCountIndividualBusinessFlagDesc = 'DEALS_DISTINCT_COUNT_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsDistinctCountOptOutAsc = 'DEALS_DISTINCT_COUNT_OPT_OUT_ASC',
  DealsDistinctCountOptOutDesc = 'DEALS_DISTINCT_COUNT_OPT_OUT_DESC',
  DealsDistinctCountBlockEmailAsc = 'DEALS_DISTINCT_COUNT_BLOCK_EMAIL_ASC',
  DealsDistinctCountBlockEmailDesc = 'DEALS_DISTINCT_COUNT_BLOCK_EMAIL_DESC',
  DealsDistinctCountBlockPhoneAsc = 'DEALS_DISTINCT_COUNT_BLOCK_PHONE_ASC',
  DealsDistinctCountBlockPhoneDesc = 'DEALS_DISTINCT_COUNT_BLOCK_PHONE_DESC',
  DealsDistinctCountBlockMailAsc = 'DEALS_DISTINCT_COUNT_BLOCK_MAIL_ASC',
  DealsDistinctCountBlockMailDesc = 'DEALS_DISTINCT_COUNT_BLOCK_MAIL_DESC',
  DealsDistinctCountLanguageAsc = 'DEALS_DISTINCT_COUNT_LANGUAGE_ASC',
  DealsDistinctCountLanguageDesc = 'DEALS_DISTINCT_COUNT_LANGUAGE_DESC',
  DealsDistinctCountCustomerCreateDateAsc = 'DEALS_DISTINCT_COUNT_CUSTOMER_CREATE_DATE_ASC',
  DealsDistinctCountCustomerCreateDateDesc = 'DEALS_DISTINCT_COUNT_CUSTOMER_CREATE_DATE_DESC',
  DealsDistinctCountCustomerLastActivityDateAsc = 'DEALS_DISTINCT_COUNT_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsDistinctCountCustomerLastActivityDateDesc = 'DEALS_DISTINCT_COUNT_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsDistinctCountCoBuyerCustomerNumberAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsDistinctCountCoBuyerCustomerNumberDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsDistinctCountCoBuyerFullNameAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_FULL_NAME_ASC',
  DealsDistinctCountCoBuyerFullNameDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_FULL_NAME_DESC',
  DealsDistinctCountCoBuyerSalutationAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_SALUTATION_ASC',
  DealsDistinctCountCoBuyerSalutationDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_SALUTATION_DESC',
  DealsDistinctCountCoBuyerFirstNameAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_FIRST_NAME_ASC',
  DealsDistinctCountCoBuyerFirstNameDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_FIRST_NAME_DESC',
  DealsDistinctCountCoBuyerMiddleNameAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_MIDDLE_NAME_ASC',
  DealsDistinctCountCoBuyerMiddleNameDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_MIDDLE_NAME_DESC',
  DealsDistinctCountCoBuyerLastNameAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_LAST_NAME_ASC',
  DealsDistinctCountCoBuyerLastNameDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_LAST_NAME_DESC',
  DealsDistinctCountCoBuyerSuffixAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_SUFFIX_ASC',
  DealsDistinctCountCoBuyerSuffixDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_SUFFIX_DESC',
  DealsDistinctCountCoBuyerAddressLine_1Asc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsDistinctCountCoBuyerAddressLine_1Desc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsDistinctCountCoBuyerAddressLine_2Asc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsDistinctCountCoBuyerAddressLine_2Desc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsDistinctCountCoBuyerCityAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CITY_ASC',
  DealsDistinctCountCoBuyerCityDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CITY_DESC',
  DealsDistinctCountCoBuyerStateAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_STATE_ASC',
  DealsDistinctCountCoBuyerStateDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_STATE_DESC',
  DealsDistinctCountCoBuyerZipAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ZIP_ASC',
  DealsDistinctCountCoBuyerZipDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_ZIP_DESC',
  DealsDistinctCountCoBuyerCountyAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_COUNTY_ASC',
  DealsDistinctCountCoBuyerCountyDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_COUNTY_DESC',
  DealsDistinctCountCoBuyerHomePhoneAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_HOME_PHONE_ASC',
  DealsDistinctCountCoBuyerHomePhoneDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_HOME_PHONE_DESC',
  DealsDistinctCountCoBuyerCellPhoneAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CELL_PHONE_ASC',
  DealsDistinctCountCoBuyerCellPhoneDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_CELL_PHONE_DESC',
  DealsDistinctCountCoBuyerWorkPhoneAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_WORK_PHONE_ASC',
  DealsDistinctCountCoBuyerWorkPhoneDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_WORK_PHONE_DESC',
  DealsDistinctCountCoBuyerWorkExtensionAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_WORK_EXTENSION_ASC',
  DealsDistinctCountCoBuyerWorkExtensionDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_WORK_EXTENSION_DESC',
  DealsDistinctCountCoBuyerEmail_1Asc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_1_ASC',
  DealsDistinctCountCoBuyerEmail_1Desc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_1_DESC',
  DealsDistinctCountCoBuyerEmail_2Asc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_2_ASC',
  DealsDistinctCountCoBuyerEmail_2Desc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_2_DESC',
  DealsDistinctCountCoBuyerEmail_3Asc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_3_ASC',
  DealsDistinctCountCoBuyerEmail_3Desc = 'DEALS_DISTINCT_COUNT_CO_BUYER_EMAIL_3_DESC',
  DealsDistinctCountCoBuyerBirthDateAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BIRTH_DATE_ASC',
  DealsDistinctCountCoBuyerBirthDateDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BIRTH_DATE_DESC',
  DealsDistinctCountCoBuyerIndividualBusinessFlagAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsDistinctCountCoBuyerIndividualBusinessFlagDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsDistinctCountCoBuyerOptOutAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_OPT_OUT_ASC',
  DealsDistinctCountCoBuyerOptOutDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_OPT_OUT_DESC',
  DealsDistinctCountCoBuyerBlockEmailAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsDistinctCountCoBuyerBlockEmailDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsDistinctCountCoBuyerBlockPhoneAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_PHONE_ASC',
  DealsDistinctCountCoBuyerBlockPhoneDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_PHONE_DESC',
  DealsDistinctCountCoBuyerBlockMailAsc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_MAIL_ASC',
  DealsDistinctCountCoBuyerBlockMailDesc = 'DEALS_DISTINCT_COUNT_CO_BUYER_BLOCK_MAIL_DESC',
  DealsDistinctCountVinAsc = 'DEALS_DISTINCT_COUNT_VIN_ASC',
  DealsDistinctCountVinDesc = 'DEALS_DISTINCT_COUNT_VIN_DESC',
  DealsDistinctCountYearAsc = 'DEALS_DISTINCT_COUNT_YEAR_ASC',
  DealsDistinctCountYearDesc = 'DEALS_DISTINCT_COUNT_YEAR_DESC',
  DealsDistinctCountMakeAsc = 'DEALS_DISTINCT_COUNT_MAKE_ASC',
  DealsDistinctCountMakeDesc = 'DEALS_DISTINCT_COUNT_MAKE_DESC',
  DealsDistinctCountModelAsc = 'DEALS_DISTINCT_COUNT_MODEL_ASC',
  DealsDistinctCountModelDesc = 'DEALS_DISTINCT_COUNT_MODEL_DESC',
  DealsDistinctCountModelNumberAsc = 'DEALS_DISTINCT_COUNT_MODEL_NUMBER_ASC',
  DealsDistinctCountModelNumberDesc = 'DEALS_DISTINCT_COUNT_MODEL_NUMBER_DESC',
  DealsDistinctCountMileageAsc = 'DEALS_DISTINCT_COUNT_MILEAGE_ASC',
  DealsDistinctCountMileageDesc = 'DEALS_DISTINCT_COUNT_MILEAGE_DESC',
  DealsDistinctCountDescriptionAsc = 'DEALS_DISTINCT_COUNT_DESCRIPTION_ASC',
  DealsDistinctCountDescriptionDesc = 'DEALS_DISTINCT_COUNT_DESCRIPTION_DESC',
  DealsDistinctCountExteriorColorAsc = 'DEALS_DISTINCT_COUNT_EXTERIOR_COLOR_ASC',
  DealsDistinctCountExteriorColorDesc = 'DEALS_DISTINCT_COUNT_EXTERIOR_COLOR_DESC',
  DealsDistinctCountNewUsedAsc = 'DEALS_DISTINCT_COUNT_NEW_USED_ASC',
  DealsDistinctCountNewUsedDesc = 'DEALS_DISTINCT_COUNT_NEW_USED_DESC',
  DealsDistinctCountStockNumberAsc = 'DEALS_DISTINCT_COUNT_STOCK_NUMBER_ASC',
  DealsDistinctCountStockNumberDesc = 'DEALS_DISTINCT_COUNT_STOCK_NUMBER_DESC',
  DealsDistinctCountTransmissionAsc = 'DEALS_DISTINCT_COUNT_TRANSMISSION_ASC',
  DealsDistinctCountTransmissionDesc = 'DEALS_DISTINCT_COUNT_TRANSMISSION_DESC',
  DealsDistinctCountEngineConfigurationAsc = 'DEALS_DISTINCT_COUNT_ENGINE_CONFIGURATION_ASC',
  DealsDistinctCountEngineConfigurationDesc = 'DEALS_DISTINCT_COUNT_ENGINE_CONFIGURATION_DESC',
  DealsDistinctCountTrimAsc = 'DEALS_DISTINCT_COUNT_TRIM_ASC',
  DealsDistinctCountTrimDesc = 'DEALS_DISTINCT_COUNT_TRIM_DESC',
  DealsDistinctCountEngineNumberAsc = 'DEALS_DISTINCT_COUNT_ENGINE_NUMBER_ASC',
  DealsDistinctCountEngineNumberDesc = 'DEALS_DISTINCT_COUNT_ENGINE_NUMBER_DESC',
  DealsDistinctCountChassisNumberAsc = 'DEALS_DISTINCT_COUNT_CHASSIS_NUMBER_ASC',
  DealsDistinctCountChassisNumberDesc = 'DEALS_DISTINCT_COUNT_CHASSIS_NUMBER_DESC',
  DealsDistinctCountLicensePlateNumberAsc = 'DEALS_DISTINCT_COUNT_LICENSE_PLATE_NUMBER_ASC',
  DealsDistinctCountLicensePlateNumberDesc = 'DEALS_DISTINCT_COUNT_LICENSE_PLATE_NUMBER_DESC',
  DealsDistinctCountDeliveryDateAsc = 'DEALS_DISTINCT_COUNT_DELIVERY_DATE_ASC',
  DealsDistinctCountDeliveryDateDesc = 'DEALS_DISTINCT_COUNT_DELIVERY_DATE_DESC',
  DealsDistinctCountDeliveryMileageAsc = 'DEALS_DISTINCT_COUNT_DELIVERY_MILEAGE_ASC',
  DealsDistinctCountDeliveryMileageDesc = 'DEALS_DISTINCT_COUNT_DELIVERY_MILEAGE_DESC',
  DealsDistinctCountInventoryDateAsc = 'DEALS_DISTINCT_COUNT_INVENTORY_DATE_ASC',
  DealsDistinctCountInventoryDateDesc = 'DEALS_DISTINCT_COUNT_INVENTORY_DATE_DESC',
  DealsDistinctCountInServiceDateAsc = 'DEALS_DISTINCT_COUNT_IN_SERVICE_DATE_ASC',
  DealsDistinctCountInServiceDateDesc = 'DEALS_DISTINCT_COUNT_IN_SERVICE_DATE_DESC',
  DealsDistinctCountVinExplosionYearAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_YEAR_ASC',
  DealsDistinctCountVinExplosionYearDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_YEAR_DESC',
  DealsDistinctCountVinExplosionMakeAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_MAKE_ASC',
  DealsDistinctCountVinExplosionMakeDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_MAKE_DESC',
  DealsDistinctCountVinExplosionModelAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_MODEL_ASC',
  DealsDistinctCountVinExplosionModelDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_MODEL_DESC',
  DealsDistinctCountVinExplosionTrimAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_TRIM_ASC',
  DealsDistinctCountVinExplosionTrimDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_TRIM_DESC',
  DealsDistinctCountVinExplosionTransmissionTypeAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsDistinctCountVinExplosionTransmissionTypeDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsDistinctCountVinExplosionFuelTypeAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsDistinctCountVinExplosionFuelTypeDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsDistinctCountVinExplosionEngineSizeAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsDistinctCountVinExplosionEngineSizeDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsDistinctCountVinExplosionGvwRangeAsc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsDistinctCountVinExplosionGvwRangeDesc = 'DEALS_DISTINCT_COUNT_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsDistinctCountTrade_1VinAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_VIN_ASC',
  DealsDistinctCountTrade_1VinDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_VIN_DESC',
  DealsDistinctCountTrade_1YearAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_YEAR_ASC',
  DealsDistinctCountTrade_1YearDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_YEAR_DESC',
  DealsDistinctCountTrade_1MakeAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_MAKE_ASC',
  DealsDistinctCountTrade_1MakeDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_MAKE_DESC',
  DealsDistinctCountTrade_1ModelAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_MODEL_ASC',
  DealsDistinctCountTrade_1ModelDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_MODEL_DESC',
  DealsDistinctCountTrade_1OdometerAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_ODOMETER_ASC',
  DealsDistinctCountTrade_1OdometerDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_ODOMETER_DESC',
  DealsDistinctCountTrade_1ActualCashValueAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsDistinctCountTrade_1ActualCashValueDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsDistinctCountTrade_1GrossAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_GROSS_ASC',
  DealsDistinctCountTrade_1GrossDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_GROSS_DESC',
  DealsDistinctCountTrade_1PayoffAsc = 'DEALS_DISTINCT_COUNT_TRADE_1_PAYOFF_ASC',
  DealsDistinctCountTrade_1PayoffDesc = 'DEALS_DISTINCT_COUNT_TRADE_1_PAYOFF_DESC',
  DealsDistinctCountTrade_2VinAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_VIN_ASC',
  DealsDistinctCountTrade_2VinDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_VIN_DESC',
  DealsDistinctCountTrade_2YearAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_YEAR_ASC',
  DealsDistinctCountTrade_2YearDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_YEAR_DESC',
  DealsDistinctCountTrade_2MakeAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_MAKE_ASC',
  DealsDistinctCountTrade_2MakeDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_MAKE_DESC',
  DealsDistinctCountTrade_2ModelAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_MODEL_ASC',
  DealsDistinctCountTrade_2ModelDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_MODEL_DESC',
  DealsDistinctCountTrade_2OdometerAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_ODOMETER_ASC',
  DealsDistinctCountTrade_2OdometerDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_ODOMETER_DESC',
  DealsDistinctCountTrade_2ActualCashValueAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsDistinctCountTrade_2ActualCashValueDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsDistinctCountTrade_2GrossAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_GROSS_ASC',
  DealsDistinctCountTrade_2GrossDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_GROSS_DESC',
  DealsDistinctCountTrade_2PayoffAsc = 'DEALS_DISTINCT_COUNT_TRADE_2_PAYOFF_ASC',
  DealsDistinctCountTrade_2PayoffDesc = 'DEALS_DISTINCT_COUNT_TRADE_2_PAYOFF_DESC',
  DealsDistinctCountSalesman_1NumberAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_NUMBER_ASC',
  DealsDistinctCountSalesman_1NumberDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_NUMBER_DESC',
  DealsDistinctCountSalesman_1NameAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_NAME_ASC',
  DealsDistinctCountSalesman_1NameDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_NAME_DESC',
  DealsDistinctCountSalesman_2NumberAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_NUMBER_ASC',
  DealsDistinctCountSalesman_2NumberDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_NUMBER_DESC',
  DealsDistinctCountSalesman_2NameAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_NAME_ASC',
  DealsDistinctCountSalesman_2NameDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_NAME_DESC',
  DealsDistinctCountSalesman_3NumberAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_NUMBER_ASC',
  DealsDistinctCountSalesman_3NumberDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_NUMBER_DESC',
  DealsDistinctCountSalesman_3NameAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_NAME_ASC',
  DealsDistinctCountSalesman_3NameDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_NAME_DESC',
  DealsDistinctCountClosingManagerNumberAsc = 'DEALS_DISTINCT_COUNT_CLOSING_MANAGER_NUMBER_ASC',
  DealsDistinctCountClosingManagerNumberDesc = 'DEALS_DISTINCT_COUNT_CLOSING_MANAGER_NUMBER_DESC',
  DealsDistinctCountClosingManagerNameAsc = 'DEALS_DISTINCT_COUNT_CLOSING_MANAGER_NAME_ASC',
  DealsDistinctCountClosingManagerNameDesc = 'DEALS_DISTINCT_COUNT_CLOSING_MANAGER_NAME_DESC',
  DealsDistinctCountFinanceManagerNumberAsc = 'DEALS_DISTINCT_COUNT_FINANCE_MANAGER_NUMBER_ASC',
  DealsDistinctCountFinanceManagerNumberDesc = 'DEALS_DISTINCT_COUNT_FINANCE_MANAGER_NUMBER_DESC',
  DealsDistinctCountFinanceManagerNameAsc = 'DEALS_DISTINCT_COUNT_FINANCE_MANAGER_NAME_ASC',
  DealsDistinctCountFinanceManagerNameDesc = 'DEALS_DISTINCT_COUNT_FINANCE_MANAGER_NAME_DESC',
  DealsDistinctCountSalesmanManagerNumberAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_MANAGER_NUMBER_ASC',
  DealsDistinctCountSalesmanManagerNumberDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_MANAGER_NUMBER_DESC',
  DealsDistinctCountSalesmanManagerNameAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_MANAGER_NAME_ASC',
  DealsDistinctCountSalesmanManagerNameDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_MANAGER_NAME_DESC',
  DealsDistinctCountMsrpAsc = 'DEALS_DISTINCT_COUNT_MSRP_ASC',
  DealsDistinctCountMsrpDesc = 'DEALS_DISTINCT_COUNT_MSRP_DESC',
  DealsDistinctCountListPriceAsc = 'DEALS_DISTINCT_COUNT_LIST_PRICE_ASC',
  DealsDistinctCountListPriceDesc = 'DEALS_DISTINCT_COUNT_LIST_PRICE_DESC',
  DealsDistinctCountSalesPriceAsc = 'DEALS_DISTINCT_COUNT_SALES_PRICE_ASC',
  DealsDistinctCountSalesPriceDesc = 'DEALS_DISTINCT_COUNT_SALES_PRICE_DESC',
  DealsDistinctCountJournalPriceAsc = 'DEALS_DISTINCT_COUNT_JOURNAL_PRICE_ASC',
  DealsDistinctCountJournalPriceDesc = 'DEALS_DISTINCT_COUNT_JOURNAL_PRICE_DESC',
  DealsDistinctCountCostAsc = 'DEALS_DISTINCT_COUNT_COST_ASC',
  DealsDistinctCountCostDesc = 'DEALS_DISTINCT_COUNT_COST_DESC',
  DealsDistinctCountJournalCostAsc = 'DEALS_DISTINCT_COUNT_JOURNAL_COST_ASC',
  DealsDistinctCountJournalCostDesc = 'DEALS_DISTINCT_COUNT_JOURNAL_COST_DESC',
  DealsDistinctCountAdjustmentsAsc = 'DEALS_DISTINCT_COUNT_ADJUSTMENTS_ASC',
  DealsDistinctCountAdjustmentsDesc = 'DEALS_DISTINCT_COUNT_ADJUSTMENTS_DESC',
  DealsDistinctCountAdjustedCostAsc = 'DEALS_DISTINCT_COUNT_ADJUSTED_COST_ASC',
  DealsDistinctCountAdjustedCostDesc = 'DEALS_DISTINCT_COUNT_ADJUSTED_COST_DESC',
  DealsDistinctCountIncentivesAsc = 'DEALS_DISTINCT_COUNT_INCENTIVES_ASC',
  DealsDistinctCountIncentivesDesc = 'DEALS_DISTINCT_COUNT_INCENTIVES_DESC',
  DealsDistinctCountPackAmountAsc = 'DEALS_DISTINCT_COUNT_PACK_AMOUNT_ASC',
  DealsDistinctCountPackAmountDesc = 'DEALS_DISTINCT_COUNT_PACK_AMOUNT_DESC',
  DealsDistinctCountSaleNetAsc = 'DEALS_DISTINCT_COUNT_SALE_NET_ASC',
  DealsDistinctCountSaleNetDesc = 'DEALS_DISTINCT_COUNT_SALE_NET_DESC',
  DealsDistinctCountTotalTradeActualCashValueAsc = 'DEALS_DISTINCT_COUNT_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsDistinctCountTotalTradeActualCashValueDesc = 'DEALS_DISTINCT_COUNT_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsDistinctCountTotalTradeGrossAsc = 'DEALS_DISTINCT_COUNT_TOTAL_TRADE_GROSS_ASC',
  DealsDistinctCountTotalTradeGrossDesc = 'DEALS_DISTINCT_COUNT_TOTAL_TRADE_GROSS_DESC',
  DealsDistinctCountWeOweFrontAsc = 'DEALS_DISTINCT_COUNT_WE_OWE_FRONT_ASC',
  DealsDistinctCountWeOweFrontDesc = 'DEALS_DISTINCT_COUNT_WE_OWE_FRONT_DESC',
  DealsDistinctCountTotalFrontFeesAftermarketProfitAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsDistinctCountTotalFrontFeesAftermarketProfitDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsDistinctCountTotalFrontCommissionAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_COMMISSION_ASC',
  DealsDistinctCountTotalFrontCommissionDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_COMMISSION_DESC',
  DealsDistinctCountTotalFrontSalesAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_SALES_ASC',
  DealsDistinctCountTotalFrontSalesDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_SALES_DESC',
  DealsDistinctCountTotalFrontCostAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_COST_ASC',
  DealsDistinctCountTotalFrontCostDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FRONT_COST_DESC',
  DealsDistinctCountFrontGrossAsc = 'DEALS_DISTINCT_COUNT_FRONT_GROSS_ASC',
  DealsDistinctCountFrontGrossDesc = 'DEALS_DISTINCT_COUNT_FRONT_GROSS_DESC',
  DealsDistinctCountFinanceProfitAsc = 'DEALS_DISTINCT_COUNT_FINANCE_PROFIT_ASC',
  DealsDistinctCountFinanceProfitDesc = 'DEALS_DISTINCT_COUNT_FINANCE_PROFIT_DESC',
  DealsDistinctCountTotalWarrantyProfitAsc = 'DEALS_DISTINCT_COUNT_TOTAL_WARRANTY_PROFIT_ASC',
  DealsDistinctCountTotalWarrantyProfitDesc = 'DEALS_DISTINCT_COUNT_TOTAL_WARRANTY_PROFIT_DESC',
  DealsDistinctCountWeOweBackAsc = 'DEALS_DISTINCT_COUNT_WE_OWE_BACK_ASC',
  DealsDistinctCountWeOweBackDesc = 'DEALS_DISTINCT_COUNT_WE_OWE_BACK_DESC',
  DealsDistinctCountInsuranceProfitAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_PROFIT_ASC',
  DealsDistinctCountInsuranceProfitDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_PROFIT_DESC',
  DealsDistinctCountTotalBackFeesAftermarketProfitAsc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsDistinctCountTotalBackFeesAftermarketProfitDesc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsDistinctCountFinanceReserveAsc = 'DEALS_DISTINCT_COUNT_FINANCE_RESERVE_ASC',
  DealsDistinctCountFinanceReserveDesc = 'DEALS_DISTINCT_COUNT_FINANCE_RESERVE_DESC',
  DealsDistinctCountTotalBackCommissionAsc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_COMMISSION_ASC',
  DealsDistinctCountTotalBackCommissionDesc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_COMMISSION_DESC',
  DealsDistinctCountTotalBackSalesAsc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_SALES_ASC',
  DealsDistinctCountTotalBackSalesDesc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_SALES_DESC',
  DealsDistinctCountTotalBackCostAsc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_COST_ASC',
  DealsDistinctCountTotalBackCostDesc = 'DEALS_DISTINCT_COUNT_TOTAL_BACK_COST_DESC',
  DealsDistinctCountBackGrossAsc = 'DEALS_DISTINCT_COUNT_BACK_GROSS_ASC',
  DealsDistinctCountBackGrossDesc = 'DEALS_DISTINCT_COUNT_BACK_GROSS_DESC',
  DealsDistinctCountTotalProfitAsc = 'DEALS_DISTINCT_COUNT_TOTAL_PROFIT_ASC',
  DealsDistinctCountTotalProfitDesc = 'DEALS_DISTINCT_COUNT_TOTAL_PROFIT_DESC',
  DealsDistinctCountGrossProfitAsc = 'DEALS_DISTINCT_COUNT_GROSS_PROFIT_ASC',
  DealsDistinctCountGrossProfitDesc = 'DEALS_DISTINCT_COUNT_GROSS_PROFIT_DESC',
  DealsDistinctCountGrossPayableAsc = 'DEALS_DISTINCT_COUNT_GROSS_PAYABLE_ASC',
  DealsDistinctCountGrossPayableDesc = 'DEALS_DISTINCT_COUNT_GROSS_PAYABLE_DESC',
  DealsDistinctCountDealStatusAsc = 'DEALS_DISTINCT_COUNT_DEAL_STATUS_ASC',
  DealsDistinctCountDealStatusDesc = 'DEALS_DISTINCT_COUNT_DEAL_STATUS_DESC',
  DealsDistinctCountEntryDateAsc = 'DEALS_DISTINCT_COUNT_ENTRY_DATE_ASC',
  DealsDistinctCountEntryDateDesc = 'DEALS_DISTINCT_COUNT_ENTRY_DATE_DESC',
  DealsDistinctCountBookedDateAsc = 'DEALS_DISTINCT_COUNT_BOOKED_DATE_ASC',
  DealsDistinctCountBookedDateDesc = 'DEALS_DISTINCT_COUNT_BOOKED_DATE_DESC',
  DealsDistinctCountFinalizedDateAsc = 'DEALS_DISTINCT_COUNT_FINALIZED_DATE_ASC',
  DealsDistinctCountFinalizedDateDesc = 'DEALS_DISTINCT_COUNT_FINALIZED_DATE_DESC',
  DealsDistinctCountContractDateAsc = 'DEALS_DISTINCT_COUNT_CONTRACT_DATE_ASC',
  DealsDistinctCountContractDateDesc = 'DEALS_DISTINCT_COUNT_CONTRACT_DATE_DESC',
  DealsDistinctCountAccountingDateAsc = 'DEALS_DISTINCT_COUNT_ACCOUNTING_DATE_ASC',
  DealsDistinctCountAccountingDateDesc = 'DEALS_DISTINCT_COUNT_ACCOUNTING_DATE_DESC',
  DealsDistinctCountStatusChangeDateAsc = 'DEALS_DISTINCT_COUNT_STATUS_CHANGE_DATE_ASC',
  DealsDistinctCountStatusChangeDateDesc = 'DEALS_DISTINCT_COUNT_STATUS_CHANGE_DATE_DESC',
  DealsDistinctCountFirstPayDateAsc = 'DEALS_DISTINCT_COUNT_FIRST_PAY_DATE_ASC',
  DealsDistinctCountFirstPayDateDesc = 'DEALS_DISTINCT_COUNT_FIRST_PAY_DATE_DESC',
  DealsDistinctCountDealTypeAsc = 'DEALS_DISTINCT_COUNT_DEAL_TYPE_ASC',
  DealsDistinctCountDealTypeDesc = 'DEALS_DISTINCT_COUNT_DEAL_TYPE_DESC',
  DealsDistinctCountSaleTypeAsc = 'DEALS_DISTINCT_COUNT_SALE_TYPE_ASC',
  DealsDistinctCountSaleTypeDesc = 'DEALS_DISTINCT_COUNT_SALE_TYPE_DESC',
  DealsDistinctCountBankIdAsc = 'DEALS_DISTINCT_COUNT_BANK_ID_ASC',
  DealsDistinctCountBankIdDesc = 'DEALS_DISTINCT_COUNT_BANK_ID_DESC',
  DealsDistinctCountBankNameAsc = 'DEALS_DISTINCT_COUNT_BANK_NAME_ASC',
  DealsDistinctCountBankNameDesc = 'DEALS_DISTINCT_COUNT_BANK_NAME_DESC',
  DealsDistinctCountBankAddressAsc = 'DEALS_DISTINCT_COUNT_BANK_ADDRESS_ASC',
  DealsDistinctCountBankAddressDesc = 'DEALS_DISTINCT_COUNT_BANK_ADDRESS_DESC',
  DealsDistinctCountTermAsc = 'DEALS_DISTINCT_COUNT_TERM_ASC',
  DealsDistinctCountTermDesc = 'DEALS_DISTINCT_COUNT_TERM_DESC',
  DealsDistinctCountAmountFinancedAsc = 'DEALS_DISTINCT_COUNT_AMOUNT_FINANCED_ASC',
  DealsDistinctCountAmountFinancedDesc = 'DEALS_DISTINCT_COUNT_AMOUNT_FINANCED_DESC',
  DealsDistinctCountAprAsc = 'DEALS_DISTINCT_COUNT_APR_ASC',
  DealsDistinctCountAprDesc = 'DEALS_DISTINCT_COUNT_APR_DESC',
  DealsDistinctCountMonthlyPaymentAsc = 'DEALS_DISTINCT_COUNT_MONTHLY_PAYMENT_ASC',
  DealsDistinctCountMonthlyPaymentDesc = 'DEALS_DISTINCT_COUNT_MONTHLY_PAYMENT_DESC',
  DealsDistinctCountPaymentTotalAsc = 'DEALS_DISTINCT_COUNT_PAYMENT_TOTAL_ASC',
  DealsDistinctCountPaymentTotalDesc = 'DEALS_DISTINCT_COUNT_PAYMENT_TOTAL_DESC',
  DealsDistinctCountRebatesAsc = 'DEALS_DISTINCT_COUNT_REBATES_ASC',
  DealsDistinctCountRebatesDesc = 'DEALS_DISTINCT_COUNT_REBATES_DESC',
  DealsDistinctCountDepositAsc = 'DEALS_DISTINCT_COUNT_DEPOSIT_ASC',
  DealsDistinctCountDepositDesc = 'DEALS_DISTINCT_COUNT_DEPOSIT_DESC',
  DealsDistinctCountDownPaymentAsc = 'DEALS_DISTINCT_COUNT_DOWN_PAYMENT_ASC',
  DealsDistinctCountDownPaymentDesc = 'DEALS_DISTINCT_COUNT_DOWN_PAYMENT_DESC',
  DealsDistinctCountTotalNetTradesAsc = 'DEALS_DISTINCT_COUNT_TOTAL_NET_TRADES_ASC',
  DealsDistinctCountTotalNetTradesDesc = 'DEALS_DISTINCT_COUNT_TOTAL_NET_TRADES_DESC',
  DealsDistinctCountTotalDownAsc = 'DEALS_DISTINCT_COUNT_TOTAL_DOWN_ASC',
  DealsDistinctCountTotalDownDesc = 'DEALS_DISTINCT_COUNT_TOTAL_DOWN_DESC',
  DealsDistinctCountBalloonAmountAsc = 'DEALS_DISTINCT_COUNT_BALLOON_AMOUNT_ASC',
  DealsDistinctCountBalloonAmountDesc = 'DEALS_DISTINCT_COUNT_BALLOON_AMOUNT_DESC',
  DealsDistinctCountAdjustedBalloonAmountAsc = 'DEALS_DISTINCT_COUNT_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsDistinctCountAdjustedBalloonAmountDesc = 'DEALS_DISTINCT_COUNT_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsDistinctCountHoldbackAmountAsc = 'DEALS_DISTINCT_COUNT_HOLDBACK_AMOUNT_ASC',
  DealsDistinctCountHoldbackAmountDesc = 'DEALS_DISTINCT_COUNT_HOLDBACK_AMOUNT_DESC',
  DealsDistinctCountTotalDriveOffAmountAsc = 'DEALS_DISTINCT_COUNT_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsDistinctCountTotalDriveOffAmountDesc = 'DEALS_DISTINCT_COUNT_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsDistinctCountLicenseFeeAsc = 'DEALS_DISTINCT_COUNT_LICENSE_FEE_ASC',
  DealsDistinctCountLicenseFeeDesc = 'DEALS_DISTINCT_COUNT_LICENSE_FEE_DESC',
  DealsDistinctCountRegistrationFeeAsc = 'DEALS_DISTINCT_COUNT_REGISTRATION_FEE_ASC',
  DealsDistinctCountRegistrationFeeDesc = 'DEALS_DISTINCT_COUNT_REGISTRATION_FEE_DESC',
  DealsDistinctCountDocumentationFeeAsc = 'DEALS_DISTINCT_COUNT_DOCUMENTATION_FEE_ASC',
  DealsDistinctCountDocumentationFeeDesc = 'DEALS_DISTINCT_COUNT_DOCUMENTATION_FEE_DESC',
  DealsDistinctCountFinanceChargeAsc = 'DEALS_DISTINCT_COUNT_FINANCE_CHARGE_ASC',
  DealsDistinctCountFinanceChargeDesc = 'DEALS_DISTINCT_COUNT_FINANCE_CHARGE_DESC',
  DealsDistinctCountTotalPickupPaymentsAsc = 'DEALS_DISTINCT_COUNT_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsDistinctCountTotalPickupPaymentsDesc = 'DEALS_DISTINCT_COUNT_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsDistinctCountSellRateAsc = 'DEALS_DISTINCT_COUNT_SELL_RATE_ASC',
  DealsDistinctCountSellRateDesc = 'DEALS_DISTINCT_COUNT_SELL_RATE_DESC',
  DealsDistinctCountBuyRateAsc = 'DEALS_DISTINCT_COUNT_BUY_RATE_ASC',
  DealsDistinctCountBuyRateDesc = 'DEALS_DISTINCT_COUNT_BUY_RATE_DESC',
  DealsDistinctCountResidualRateAsc = 'DEALS_DISTINCT_COUNT_RESIDUAL_RATE_ASC',
  DealsDistinctCountResidualRateDesc = 'DEALS_DISTINCT_COUNT_RESIDUAL_RATE_DESC',
  DealsDistinctCountResidualAmountAsc = 'DEALS_DISTINCT_COUNT_RESIDUAL_AMOUNT_ASC',
  DealsDistinctCountResidualAmountDesc = 'DEALS_DISTINCT_COUNT_RESIDUAL_AMOUNT_DESC',
  DealsDistinctCountAllowedMilesAsc = 'DEALS_DISTINCT_COUNT_ALLOWED_MILES_ASC',
  DealsDistinctCountAllowedMilesDesc = 'DEALS_DISTINCT_COUNT_ALLOWED_MILES_DESC',
  DealsDistinctCountEstimatedMilesAsc = 'DEALS_DISTINCT_COUNT_ESTIMATED_MILES_ASC',
  DealsDistinctCountEstimatedMilesDesc = 'DEALS_DISTINCT_COUNT_ESTIMATED_MILES_DESC',
  DealsDistinctCountMileageRateAsc = 'DEALS_DISTINCT_COUNT_MILEAGE_RATE_ASC',
  DealsDistinctCountMileageRateDesc = 'DEALS_DISTINCT_COUNT_MILEAGE_RATE_DESC',
  DealsDistinctCountAcquisitionFeeAsc = 'DEALS_DISTINCT_COUNT_ACQUISITION_FEE_ASC',
  DealsDistinctCountAcquisitionFeeDesc = 'DEALS_DISTINCT_COUNT_ACQUISITION_FEE_DESC',
  DealsDistinctCountBasePaymentAsc = 'DEALS_DISTINCT_COUNT_BASE_PAYMENT_ASC',
  DealsDistinctCountBasePaymentDesc = 'DEALS_DISTINCT_COUNT_BASE_PAYMENT_DESC',
  DealsDistinctCountSecurityDepositAsc = 'DEALS_DISTINCT_COUNT_SECURITY_DEPOSIT_ASC',
  DealsDistinctCountSecurityDepositDesc = 'DEALS_DISTINCT_COUNT_SECURITY_DEPOSIT_DESC',
  DealsDistinctCountTotalCapitalReductionAsc = 'DEALS_DISTINCT_COUNT_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsDistinctCountTotalCapitalReductionDesc = 'DEALS_DISTINCT_COUNT_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsDistinctCountNetCapitalCostAsc = 'DEALS_DISTINCT_COUNT_NET_CAPITAL_COST_ASC',
  DealsDistinctCountNetCapitalCostDesc = 'DEALS_DISTINCT_COUNT_NET_CAPITAL_COST_DESC',
  DealsDistinctCountLeaseDepreciationValueAsc = 'DEALS_DISTINCT_COUNT_LEASE_DEPRECIATION_VALUE_ASC',
  DealsDistinctCountLeaseDepreciationValueDesc = 'DEALS_DISTINCT_COUNT_LEASE_DEPRECIATION_VALUE_DESC',
  DealsDistinctCountDealerFeesAsc = 'DEALS_DISTINCT_COUNT_DEALER_FEES_ASC',
  DealsDistinctCountDealerFeesDesc = 'DEALS_DISTINCT_COUNT_DEALER_FEES_DESC',
  DealsDistinctCountGovernmentFeesAsc = 'DEALS_DISTINCT_COUNT_GOVERNMENT_FEES_ASC',
  DealsDistinctCountGovernmentFeesDesc = 'DEALS_DISTINCT_COUNT_GOVERNMENT_FEES_DESC',
  DealsDistinctCountTotalTaxAsc = 'DEALS_DISTINCT_COUNT_TOTAL_TAX_ASC',
  DealsDistinctCountTotalTaxDesc = 'DEALS_DISTINCT_COUNT_TOTAL_TAX_DESC',
  DealsDistinctCountRegistrationStateAsc = 'DEALS_DISTINCT_COUNT_REGISTRATION_STATE_ASC',
  DealsDistinctCountRegistrationStateDesc = 'DEALS_DISTINCT_COUNT_REGISTRATION_STATE_DESC',
  DealsDistinctCountReportOfSaleNumberAsc = 'DEALS_DISTINCT_COUNT_REPORT_OF_SALE_NUMBER_ASC',
  DealsDistinctCountReportOfSaleNumberDesc = 'DEALS_DISTINCT_COUNT_REPORT_OF_SALE_NUMBER_DESC',
  DealsDistinctCountSalesman_1TotalCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsDistinctCountSalesman_1TotalCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsDistinctCountSalesman_1FrontCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsDistinctCountSalesman_1FrontCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsDistinctCountSalesman_1BackCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsDistinctCountSalesman_1BackCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsDistinctCountSalesman_2TotalCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsDistinctCountSalesman_2TotalCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsDistinctCountSalesman_2FrontCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsDistinctCountSalesman_2FrontCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsDistinctCountSalesman_2BackCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsDistinctCountSalesman_2BackCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsDistinctCountSalesman_3TotalCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsDistinctCountSalesman_3TotalCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsDistinctCountSalesman_3FrontCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsDistinctCountSalesman_3FrontCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsDistinctCountSalesman_3BackCommissionAsc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsDistinctCountSalesman_3BackCommissionDesc = 'DEALS_DISTINCT_COUNT_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsDistinctCountWarranty_1NameAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_NAME_ASC',
  DealsDistinctCountWarranty_1NameDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_NAME_DESC',
  DealsDistinctCountWarranty_1SaleAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_SALE_ASC',
  DealsDistinctCountWarranty_1SaleDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_SALE_DESC',
  DealsDistinctCountWarranty_1CostAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_COST_ASC',
  DealsDistinctCountWarranty_1CostDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_COST_DESC',
  DealsDistinctCountWarranty_1MilesAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_MILES_ASC',
  DealsDistinctCountWarranty_1MilesDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_MILES_DESC',
  DealsDistinctCountWarranty_1TermAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_TERM_ASC',
  DealsDistinctCountWarranty_1TermDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_1_TERM_DESC',
  DealsDistinctCountWarranty_2NameAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_NAME_ASC',
  DealsDistinctCountWarranty_2NameDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_NAME_DESC',
  DealsDistinctCountWarranty_2SaleAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_SALE_ASC',
  DealsDistinctCountWarranty_2SaleDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_SALE_DESC',
  DealsDistinctCountWarranty_2CostAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_COST_ASC',
  DealsDistinctCountWarranty_2CostDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_COST_DESC',
  DealsDistinctCountWarranty_2MilesAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_MILES_ASC',
  DealsDistinctCountWarranty_2MilesDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_MILES_DESC',
  DealsDistinctCountWarranty_2TermAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_TERM_ASC',
  DealsDistinctCountWarranty_2TermDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_2_TERM_DESC',
  DealsDistinctCountWarranty_3NameAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_NAME_ASC',
  DealsDistinctCountWarranty_3NameDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_NAME_DESC',
  DealsDistinctCountWarranty_3SaleAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_SALE_ASC',
  DealsDistinctCountWarranty_3SaleDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_SALE_DESC',
  DealsDistinctCountWarranty_3CostAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_COST_ASC',
  DealsDistinctCountWarranty_3CostDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_COST_DESC',
  DealsDistinctCountWarranty_3MilesAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_MILES_ASC',
  DealsDistinctCountWarranty_3MilesDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_MILES_DESC',
  DealsDistinctCountWarranty_3TermAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_TERM_ASC',
  DealsDistinctCountWarranty_3TermDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_3_TERM_DESC',
  DealsDistinctCountWarranty_4NameAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_NAME_ASC',
  DealsDistinctCountWarranty_4NameDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_NAME_DESC',
  DealsDistinctCountWarranty_4SaleAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_SALE_ASC',
  DealsDistinctCountWarranty_4SaleDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_SALE_DESC',
  DealsDistinctCountWarranty_4CostAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_COST_ASC',
  DealsDistinctCountWarranty_4CostDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_COST_DESC',
  DealsDistinctCountWarranty_4MilesAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_MILES_ASC',
  DealsDistinctCountWarranty_4MilesDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_MILES_DESC',
  DealsDistinctCountWarranty_4TermAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_TERM_ASC',
  DealsDistinctCountWarranty_4TermDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_4_TERM_DESC',
  DealsDistinctCountWarranty_5NameAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_NAME_ASC',
  DealsDistinctCountWarranty_5NameDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_NAME_DESC',
  DealsDistinctCountWarranty_5SaleAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_SALE_ASC',
  DealsDistinctCountWarranty_5SaleDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_SALE_DESC',
  DealsDistinctCountWarranty_5CostAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_COST_ASC',
  DealsDistinctCountWarranty_5CostDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_COST_DESC',
  DealsDistinctCountWarranty_5MilesAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_MILES_ASC',
  DealsDistinctCountWarranty_5MilesDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_MILES_DESC',
  DealsDistinctCountWarranty_5TermAsc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_TERM_ASC',
  DealsDistinctCountWarranty_5TermDesc = 'DEALS_DISTINCT_COUNT_WARRANTY_5_TERM_DESC',
  DealsDistinctCountTotalFeeAftermarketSaleAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsDistinctCountTotalFeeAftermarketSaleDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsDistinctCountTotalFeeAftermarketCostAsc = 'DEALS_DISTINCT_COUNT_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsDistinctCountTotalFeeAftermarketCostDesc = 'DEALS_DISTINCT_COUNT_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsDistinctCountFeeAftermarket_1NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_NAME_ASC',
  DealsDistinctCountFeeAftermarket_1NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_NAME_DESC',
  DealsDistinctCountFeeAftermarket_1SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_SALE_ASC',
  DealsDistinctCountFeeAftermarket_1SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_SALE_DESC',
  DealsDistinctCountFeeAftermarket_1CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_COST_ASC',
  DealsDistinctCountFeeAftermarket_1CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_COST_DESC',
  DealsDistinctCountFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_2NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_NAME_ASC',
  DealsDistinctCountFeeAftermarket_2NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_NAME_DESC',
  DealsDistinctCountFeeAftermarket_2SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_SALE_ASC',
  DealsDistinctCountFeeAftermarket_2SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_SALE_DESC',
  DealsDistinctCountFeeAftermarket_2CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_COST_ASC',
  DealsDistinctCountFeeAftermarket_2CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_COST_DESC',
  DealsDistinctCountFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_3NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_NAME_ASC',
  DealsDistinctCountFeeAftermarket_3NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_NAME_DESC',
  DealsDistinctCountFeeAftermarket_3SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_SALE_ASC',
  DealsDistinctCountFeeAftermarket_3SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_SALE_DESC',
  DealsDistinctCountFeeAftermarket_3CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_COST_ASC',
  DealsDistinctCountFeeAftermarket_3CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_COST_DESC',
  DealsDistinctCountFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_4NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_NAME_ASC',
  DealsDistinctCountFeeAftermarket_4NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_NAME_DESC',
  DealsDistinctCountFeeAftermarket_4SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_SALE_ASC',
  DealsDistinctCountFeeAftermarket_4SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_SALE_DESC',
  DealsDistinctCountFeeAftermarket_4CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_COST_ASC',
  DealsDistinctCountFeeAftermarket_4CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_COST_DESC',
  DealsDistinctCountFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_5NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_NAME_ASC',
  DealsDistinctCountFeeAftermarket_5NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_NAME_DESC',
  DealsDistinctCountFeeAftermarket_5SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_SALE_ASC',
  DealsDistinctCountFeeAftermarket_5SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_SALE_DESC',
  DealsDistinctCountFeeAftermarket_5CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_COST_ASC',
  DealsDistinctCountFeeAftermarket_5CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_COST_DESC',
  DealsDistinctCountFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_6NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_NAME_ASC',
  DealsDistinctCountFeeAftermarket_6NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_NAME_DESC',
  DealsDistinctCountFeeAftermarket_6SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_SALE_ASC',
  DealsDistinctCountFeeAftermarket_6SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_SALE_DESC',
  DealsDistinctCountFeeAftermarket_6CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_COST_ASC',
  DealsDistinctCountFeeAftermarket_6CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_COST_DESC',
  DealsDistinctCountFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_7NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_NAME_ASC',
  DealsDistinctCountFeeAftermarket_7NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_NAME_DESC',
  DealsDistinctCountFeeAftermarket_7SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_SALE_ASC',
  DealsDistinctCountFeeAftermarket_7SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_SALE_DESC',
  DealsDistinctCountFeeAftermarket_7CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_COST_ASC',
  DealsDistinctCountFeeAftermarket_7CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_COST_DESC',
  DealsDistinctCountFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_8NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_NAME_ASC',
  DealsDistinctCountFeeAftermarket_8NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_NAME_DESC',
  DealsDistinctCountFeeAftermarket_8SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_SALE_ASC',
  DealsDistinctCountFeeAftermarket_8SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_SALE_DESC',
  DealsDistinctCountFeeAftermarket_8CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_COST_ASC',
  DealsDistinctCountFeeAftermarket_8CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_COST_DESC',
  DealsDistinctCountFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_9NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_NAME_ASC',
  DealsDistinctCountFeeAftermarket_9NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_NAME_DESC',
  DealsDistinctCountFeeAftermarket_9SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_SALE_ASC',
  DealsDistinctCountFeeAftermarket_9SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_SALE_DESC',
  DealsDistinctCountFeeAftermarket_9CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_COST_ASC',
  DealsDistinctCountFeeAftermarket_9CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_COST_DESC',
  DealsDistinctCountFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_10NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_NAME_ASC',
  DealsDistinctCountFeeAftermarket_10NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_NAME_DESC',
  DealsDistinctCountFeeAftermarket_10SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_SALE_ASC',
  DealsDistinctCountFeeAftermarket_10SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_SALE_DESC',
  DealsDistinctCountFeeAftermarket_10CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_COST_ASC',
  DealsDistinctCountFeeAftermarket_10CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_COST_DESC',
  DealsDistinctCountFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_11NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_NAME_ASC',
  DealsDistinctCountFeeAftermarket_11NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_NAME_DESC',
  DealsDistinctCountFeeAftermarket_11SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_SALE_ASC',
  DealsDistinctCountFeeAftermarket_11SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_SALE_DESC',
  DealsDistinctCountFeeAftermarket_11CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_COST_ASC',
  DealsDistinctCountFeeAftermarket_11CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_COST_DESC',
  DealsDistinctCountFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_12NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_NAME_ASC',
  DealsDistinctCountFeeAftermarket_12NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_NAME_DESC',
  DealsDistinctCountFeeAftermarket_12SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_SALE_ASC',
  DealsDistinctCountFeeAftermarket_12SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_SALE_DESC',
  DealsDistinctCountFeeAftermarket_12CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_COST_ASC',
  DealsDistinctCountFeeAftermarket_12CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_COST_DESC',
  DealsDistinctCountFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_13NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_NAME_ASC',
  DealsDistinctCountFeeAftermarket_13NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_NAME_DESC',
  DealsDistinctCountFeeAftermarket_13SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_SALE_ASC',
  DealsDistinctCountFeeAftermarket_13SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_SALE_DESC',
  DealsDistinctCountFeeAftermarket_13CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_COST_ASC',
  DealsDistinctCountFeeAftermarket_13CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_COST_DESC',
  DealsDistinctCountFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_14NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_NAME_ASC',
  DealsDistinctCountFeeAftermarket_14NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_NAME_DESC',
  DealsDistinctCountFeeAftermarket_14SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_SALE_ASC',
  DealsDistinctCountFeeAftermarket_14SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_SALE_DESC',
  DealsDistinctCountFeeAftermarket_14CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_COST_ASC',
  DealsDistinctCountFeeAftermarket_14CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_COST_DESC',
  DealsDistinctCountFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_15NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_NAME_ASC',
  DealsDistinctCountFeeAftermarket_15NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_NAME_DESC',
  DealsDistinctCountFeeAftermarket_15SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_SALE_ASC',
  DealsDistinctCountFeeAftermarket_15SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_SALE_DESC',
  DealsDistinctCountFeeAftermarket_15CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_COST_ASC',
  DealsDistinctCountFeeAftermarket_15CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_COST_DESC',
  DealsDistinctCountFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_16NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_NAME_ASC',
  DealsDistinctCountFeeAftermarket_16NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_NAME_DESC',
  DealsDistinctCountFeeAftermarket_16SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_SALE_ASC',
  DealsDistinctCountFeeAftermarket_16SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_SALE_DESC',
  DealsDistinctCountFeeAftermarket_16CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_COST_ASC',
  DealsDistinctCountFeeAftermarket_16CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_COST_DESC',
  DealsDistinctCountFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_17NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_NAME_ASC',
  DealsDistinctCountFeeAftermarket_17NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_NAME_DESC',
  DealsDistinctCountFeeAftermarket_17SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_SALE_ASC',
  DealsDistinctCountFeeAftermarket_17SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_SALE_DESC',
  DealsDistinctCountFeeAftermarket_17CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_COST_ASC',
  DealsDistinctCountFeeAftermarket_17CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_COST_DESC',
  DealsDistinctCountFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_18NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_NAME_ASC',
  DealsDistinctCountFeeAftermarket_18NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_NAME_DESC',
  DealsDistinctCountFeeAftermarket_18SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_SALE_ASC',
  DealsDistinctCountFeeAftermarket_18SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_SALE_DESC',
  DealsDistinctCountFeeAftermarket_18CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_COST_ASC',
  DealsDistinctCountFeeAftermarket_18CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_COST_DESC',
  DealsDistinctCountFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_19NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_NAME_ASC',
  DealsDistinctCountFeeAftermarket_19NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_NAME_DESC',
  DealsDistinctCountFeeAftermarket_19SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_SALE_ASC',
  DealsDistinctCountFeeAftermarket_19SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_SALE_DESC',
  DealsDistinctCountFeeAftermarket_19CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_COST_ASC',
  DealsDistinctCountFeeAftermarket_19CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_COST_DESC',
  DealsDistinctCountFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsDistinctCountFeeAftermarket_20NameAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_NAME_ASC',
  DealsDistinctCountFeeAftermarket_20NameDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_NAME_DESC',
  DealsDistinctCountFeeAftermarket_20SaleAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_SALE_ASC',
  DealsDistinctCountFeeAftermarket_20SaleDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_SALE_DESC',
  DealsDistinctCountFeeAftermarket_20CostAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_COST_ASC',
  DealsDistinctCountFeeAftermarket_20CostDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_COST_DESC',
  DealsDistinctCountFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsDistinctCountFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_DISTINCT_COUNT_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsDistinctCountInsurance_1TypeAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_TYPE_ASC',
  DealsDistinctCountInsurance_1TypeDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_TYPE_DESC',
  DealsDistinctCountInsurance_1NameAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_NAME_ASC',
  DealsDistinctCountInsurance_1NameDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_NAME_DESC',
  DealsDistinctCountInsurance_1SaleAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_SALE_ASC',
  DealsDistinctCountInsurance_1SaleDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_SALE_DESC',
  DealsDistinctCountInsurance_1CostAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_COST_ASC',
  DealsDistinctCountInsurance_1CostDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_COST_DESC',
  DealsDistinctCountInsurance_1TermAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_TERM_ASC',
  DealsDistinctCountInsurance_1TermDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_1_TERM_DESC',
  DealsDistinctCountInsurance_2TypeAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_TYPE_ASC',
  DealsDistinctCountInsurance_2TypeDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_TYPE_DESC',
  DealsDistinctCountInsurance_2NameAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_NAME_ASC',
  DealsDistinctCountInsurance_2NameDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_NAME_DESC',
  DealsDistinctCountInsurance_2SaleAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_SALE_ASC',
  DealsDistinctCountInsurance_2SaleDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_SALE_DESC',
  DealsDistinctCountInsurance_2CostAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_COST_ASC',
  DealsDistinctCountInsurance_2CostDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_COST_DESC',
  DealsDistinctCountInsurance_2TermAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_TERM_ASC',
  DealsDistinctCountInsurance_2TermDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_2_TERM_DESC',
  DealsDistinctCountInsurance_3TypeAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_TYPE_ASC',
  DealsDistinctCountInsurance_3TypeDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_TYPE_DESC',
  DealsDistinctCountInsurance_3NameAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_NAME_ASC',
  DealsDistinctCountInsurance_3NameDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_NAME_DESC',
  DealsDistinctCountInsurance_3SaleAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_SALE_ASC',
  DealsDistinctCountInsurance_3SaleDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_SALE_DESC',
  DealsDistinctCountInsurance_3CostAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_COST_ASC',
  DealsDistinctCountInsurance_3CostDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_COST_DESC',
  DealsDistinctCountInsurance_3TermAsc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_TERM_ASC',
  DealsDistinctCountInsurance_3TermDesc = 'DEALS_DISTINCT_COUNT_INSURANCE_3_TERM_DESC',
  DealsDistinctCountAccidentalHealthTypeAsc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsDistinctCountAccidentalHealthTypeDesc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsDistinctCountAccidentalHealthNameAsc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsDistinctCountAccidentalHealthNameDesc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsDistinctCountAccidentalHealthSaleAsc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsDistinctCountAccidentalHealthSaleDesc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsDistinctCountAccidentalHealthCostAsc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_COST_ASC',
  DealsDistinctCountAccidentalHealthCostDesc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_COST_DESC',
  DealsDistinctCountAccidentalHealthTermAsc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsDistinctCountAccidentalHealthTermDesc = 'DEALS_DISTINCT_COUNT_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsDistinctCountCreditLifeTypeAsc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_TYPE_ASC',
  DealsDistinctCountCreditLifeTypeDesc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_TYPE_DESC',
  DealsDistinctCountCreditLifeNameAsc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_NAME_ASC',
  DealsDistinctCountCreditLifeNameDesc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_NAME_DESC',
  DealsDistinctCountCreditLifeSaleAsc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_SALE_ASC',
  DealsDistinctCountCreditLifeSaleDesc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_SALE_DESC',
  DealsDistinctCountCreditLifeCostAsc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_COST_ASC',
  DealsDistinctCountCreditLifeCostDesc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_COST_DESC',
  DealsDistinctCountCreditLifeTermAsc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_TERM_ASC',
  DealsDistinctCountCreditLifeTermDesc = 'DEALS_DISTINCT_COUNT_CREDIT_LIFE_TERM_DESC',
  DealsDistinctCountLevelizedLifeTypeAsc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_TYPE_ASC',
  DealsDistinctCountLevelizedLifeTypeDesc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_TYPE_DESC',
  DealsDistinctCountLevelizedLifeNameAsc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_NAME_ASC',
  DealsDistinctCountLevelizedLifeNameDesc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_NAME_DESC',
  DealsDistinctCountLevelizedLifeSaleAsc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_SALE_ASC',
  DealsDistinctCountLevelizedLifeSaleDesc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_SALE_DESC',
  DealsDistinctCountLevelizedLifeCostAsc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_COST_ASC',
  DealsDistinctCountLevelizedLifeCostDesc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_COST_DESC',
  DealsDistinctCountLevelizedLifeTermAsc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_TERM_ASC',
  DealsDistinctCountLevelizedLifeTermDesc = 'DEALS_DISTINCT_COUNT_LEVELIZED_LIFE_TERM_DESC',
  DealsDistinctCountLossOfEmploymentTypeAsc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsDistinctCountLossOfEmploymentTypeDesc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsDistinctCountLossOfEmploymentNameAsc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsDistinctCountLossOfEmploymentNameDesc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsDistinctCountLossOfEmploymentSaleAsc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsDistinctCountLossOfEmploymentSaleDesc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsDistinctCountLossOfEmploymentCostAsc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsDistinctCountLossOfEmploymentCostDesc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsDistinctCountLossOfEmploymentTermAsc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsDistinctCountLossOfEmploymentTermDesc = 'DEALS_DISTINCT_COUNT_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsDistinctCountGuaranteedAutoProtectionTypeAsc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsDistinctCountGuaranteedAutoProtectionTypeDesc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsDistinctCountGuaranteedAutoProtectionNameAsc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsDistinctCountGuaranteedAutoProtectionNameDesc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsDistinctCountGuaranteedAutoProtectionSaleAsc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsDistinctCountGuaranteedAutoProtectionSaleDesc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsDistinctCountGuaranteedAutoProtectionCostAsc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsDistinctCountGuaranteedAutoProtectionCostDesc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsDistinctCountGuaranteedAutoProtectionTermAsc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsDistinctCountGuaranteedAutoProtectionTermDesc = 'DEALS_DISTINCT_COUNT_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsDistinctCountSaleCommentsAsc = 'DEALS_DISTINCT_COUNT_SALE_COMMENTS_ASC',
  DealsDistinctCountSaleCommentsDesc = 'DEALS_DISTINCT_COUNT_SALE_COMMENTS_DESC',
  DealsDistinctCountCassStdLine1Asc = 'DEALS_DISTINCT_COUNT_CASS_STD_LINE1_ASC',
  DealsDistinctCountCassStdLine1Desc = 'DEALS_DISTINCT_COUNT_CASS_STD_LINE1_DESC',
  DealsDistinctCountCassStdLine2Asc = 'DEALS_DISTINCT_COUNT_CASS_STD_LINE2_ASC',
  DealsDistinctCountCassStdLine2Desc = 'DEALS_DISTINCT_COUNT_CASS_STD_LINE2_DESC',
  DealsDistinctCountCassStdCityAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_CITY_ASC',
  DealsDistinctCountCassStdCityDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_CITY_DESC',
  DealsDistinctCountCassStdStateAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_STATE_ASC',
  DealsDistinctCountCassStdStateDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_STATE_DESC',
  DealsDistinctCountCassStdZipAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIP_ASC',
  DealsDistinctCountCassStdZipDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIP_DESC',
  DealsDistinctCountCassStdZip4Asc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIP4_ASC',
  DealsDistinctCountCassStdZip4Desc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIP4_DESC',
  DealsDistinctCountCassStdDpbcAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_DPBC_ASC',
  DealsDistinctCountCassStdDpbcDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_DPBC_DESC',
  DealsDistinctCountCassStdChkdgtAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_CHKDGT_ASC',
  DealsDistinctCountCassStdChkdgtDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_CHKDGT_DESC',
  DealsDistinctCountCassStdCartAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_CART_ASC',
  DealsDistinctCountCassStdCartDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_CART_DESC',
  DealsDistinctCountCassStdLotAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_LOT_ASC',
  DealsDistinctCountCassStdLotDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_LOT_DESC',
  DealsDistinctCountCassStdLotordAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_LOTORD_ASC',
  DealsDistinctCountCassStdLotordDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_LOTORD_DESC',
  DealsDistinctCountCassStdUrbAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_URB_ASC',
  DealsDistinctCountCassStdUrbDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_URB_DESC',
  DealsDistinctCountCassStdFipsAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_FIPS_ASC',
  DealsDistinctCountCassStdFipsDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_FIPS_DESC',
  DealsDistinctCountCassStdEwsAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_EWS_ASC',
  DealsDistinctCountCassStdEwsDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_EWS_DESC',
  DealsDistinctCountCassStdLacsAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_LACS_ASC',
  DealsDistinctCountCassStdLacsDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_LACS_DESC',
  DealsDistinctCountCassStdZipmovAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIPMOV_ASC',
  DealsDistinctCountCassStdZipmovDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_ZIPMOV_DESC',
  DealsDistinctCountCassStdZ4LomAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_Z4LOM_ASC',
  DealsDistinctCountCassStdZ4LomDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_Z4LOM_DESC',
  DealsDistinctCountCassStdNdiaptAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_NDIAPT_ASC',
  DealsDistinctCountCassStdNdiaptDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_NDIAPT_DESC',
  DealsDistinctCountCassStdNdirrAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_NDIRR_ASC',
  DealsDistinctCountCassStdNdirrDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_NDIRR_DESC',
  DealsDistinctCountCassStdLacsrtAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_LACSRT_ASC',
  DealsDistinctCountCassStdLacsrtDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_LACSRT_DESC',
  DealsDistinctCountCassStdErrorCdAsc = 'DEALS_DISTINCT_COUNT_CASS_STD_ERROR_CD_ASC',
  DealsDistinctCountCassStdErrorCdDesc = 'DEALS_DISTINCT_COUNT_CASS_STD_ERROR_CD_DESC',
  DealsMinIdAsc = 'DEALS_MIN_ID_ASC',
  DealsMinIdDesc = 'DEALS_MIN_ID_DESC',
  DealsMinLeadIdAsc = 'DEALS_MIN_LEAD_ID_ASC',
  DealsMinLeadIdDesc = 'DEALS_MIN_LEAD_ID_DESC',
  DealsMinCompanyIntegrationIdAsc = 'DEALS_MIN_COMPANY_INTEGRATION_ID_ASC',
  DealsMinCompanyIntegrationIdDesc = 'DEALS_MIN_COMPANY_INTEGRATION_ID_DESC',
  DealsMinFileTypeAsc = 'DEALS_MIN_FILE_TYPE_ASC',
  DealsMinFileTypeDesc = 'DEALS_MIN_FILE_TYPE_DESC',
  DealsMinDvDealerIdAsc = 'DEALS_MIN_DV_DEALER_ID_ASC',
  DealsMinDvDealerIdDesc = 'DEALS_MIN_DV_DEALER_ID_DESC',
  DealsMinVendorDealerIdAsc = 'DEALS_MIN_VENDOR_DEALER_ID_ASC',
  DealsMinVendorDealerIdDesc = 'DEALS_MIN_VENDOR_DEALER_ID_DESC',
  DealsMinDmsTypeAsc = 'DEALS_MIN_DMS_TYPE_ASC',
  DealsMinDmsTypeDesc = 'DEALS_MIN_DMS_TYPE_DESC',
  DealsMinDealNumberAsc = 'DEALS_MIN_DEAL_NUMBER_ASC',
  DealsMinDealNumberDesc = 'DEALS_MIN_DEAL_NUMBER_DESC',
  DealsMinCustomerNumberAsc = 'DEALS_MIN_CUSTOMER_NUMBER_ASC',
  DealsMinCustomerNumberDesc = 'DEALS_MIN_CUSTOMER_NUMBER_DESC',
  DealsMinFullNameAsc = 'DEALS_MIN_FULL_NAME_ASC',
  DealsMinFullNameDesc = 'DEALS_MIN_FULL_NAME_DESC',
  DealsMinSalutationAsc = 'DEALS_MIN_SALUTATION_ASC',
  DealsMinSalutationDesc = 'DEALS_MIN_SALUTATION_DESC',
  DealsMinFirstNameAsc = 'DEALS_MIN_FIRST_NAME_ASC',
  DealsMinFirstNameDesc = 'DEALS_MIN_FIRST_NAME_DESC',
  DealsMinMiddleNameAsc = 'DEALS_MIN_MIDDLE_NAME_ASC',
  DealsMinMiddleNameDesc = 'DEALS_MIN_MIDDLE_NAME_DESC',
  DealsMinLastNameAsc = 'DEALS_MIN_LAST_NAME_ASC',
  DealsMinLastNameDesc = 'DEALS_MIN_LAST_NAME_DESC',
  DealsMinSuffixAsc = 'DEALS_MIN_SUFFIX_ASC',
  DealsMinSuffixDesc = 'DEALS_MIN_SUFFIX_DESC',
  DealsMinAddressLine_1Asc = 'DEALS_MIN_ADDRESS_LINE_1_ASC',
  DealsMinAddressLine_1Desc = 'DEALS_MIN_ADDRESS_LINE_1_DESC',
  DealsMinAddressLine_2Asc = 'DEALS_MIN_ADDRESS_LINE_2_ASC',
  DealsMinAddressLine_2Desc = 'DEALS_MIN_ADDRESS_LINE_2_DESC',
  DealsMinCityAsc = 'DEALS_MIN_CITY_ASC',
  DealsMinCityDesc = 'DEALS_MIN_CITY_DESC',
  DealsMinStateAsc = 'DEALS_MIN_STATE_ASC',
  DealsMinStateDesc = 'DEALS_MIN_STATE_DESC',
  DealsMinZipAsc = 'DEALS_MIN_ZIP_ASC',
  DealsMinZipDesc = 'DEALS_MIN_ZIP_DESC',
  DealsMinCountyAsc = 'DEALS_MIN_COUNTY_ASC',
  DealsMinCountyDesc = 'DEALS_MIN_COUNTY_DESC',
  DealsMinHomePhoneAsc = 'DEALS_MIN_HOME_PHONE_ASC',
  DealsMinHomePhoneDesc = 'DEALS_MIN_HOME_PHONE_DESC',
  DealsMinCellPhoneAsc = 'DEALS_MIN_CELL_PHONE_ASC',
  DealsMinCellPhoneDesc = 'DEALS_MIN_CELL_PHONE_DESC',
  DealsMinWorkPhoneAsc = 'DEALS_MIN_WORK_PHONE_ASC',
  DealsMinWorkPhoneDesc = 'DEALS_MIN_WORK_PHONE_DESC',
  DealsMinWorkExtensionAsc = 'DEALS_MIN_WORK_EXTENSION_ASC',
  DealsMinWorkExtensionDesc = 'DEALS_MIN_WORK_EXTENSION_DESC',
  DealsMinEmail_1Asc = 'DEALS_MIN_EMAIL_1_ASC',
  DealsMinEmail_1Desc = 'DEALS_MIN_EMAIL_1_DESC',
  DealsMinEmail_2Asc = 'DEALS_MIN_EMAIL_2_ASC',
  DealsMinEmail_2Desc = 'DEALS_MIN_EMAIL_2_DESC',
  DealsMinEmail_3Asc = 'DEALS_MIN_EMAIL_3_ASC',
  DealsMinEmail_3Desc = 'DEALS_MIN_EMAIL_3_DESC',
  DealsMinBirthDateAsc = 'DEALS_MIN_BIRTH_DATE_ASC',
  DealsMinBirthDateDesc = 'DEALS_MIN_BIRTH_DATE_DESC',
  DealsMinIndividualBusinessFlagAsc = 'DEALS_MIN_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsMinIndividualBusinessFlagDesc = 'DEALS_MIN_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsMinOptOutAsc = 'DEALS_MIN_OPT_OUT_ASC',
  DealsMinOptOutDesc = 'DEALS_MIN_OPT_OUT_DESC',
  DealsMinBlockEmailAsc = 'DEALS_MIN_BLOCK_EMAIL_ASC',
  DealsMinBlockEmailDesc = 'DEALS_MIN_BLOCK_EMAIL_DESC',
  DealsMinBlockPhoneAsc = 'DEALS_MIN_BLOCK_PHONE_ASC',
  DealsMinBlockPhoneDesc = 'DEALS_MIN_BLOCK_PHONE_DESC',
  DealsMinBlockMailAsc = 'DEALS_MIN_BLOCK_MAIL_ASC',
  DealsMinBlockMailDesc = 'DEALS_MIN_BLOCK_MAIL_DESC',
  DealsMinLanguageAsc = 'DEALS_MIN_LANGUAGE_ASC',
  DealsMinLanguageDesc = 'DEALS_MIN_LANGUAGE_DESC',
  DealsMinCustomerCreateDateAsc = 'DEALS_MIN_CUSTOMER_CREATE_DATE_ASC',
  DealsMinCustomerCreateDateDesc = 'DEALS_MIN_CUSTOMER_CREATE_DATE_DESC',
  DealsMinCustomerLastActivityDateAsc = 'DEALS_MIN_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsMinCustomerLastActivityDateDesc = 'DEALS_MIN_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsMinCoBuyerCustomerNumberAsc = 'DEALS_MIN_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsMinCoBuyerCustomerNumberDesc = 'DEALS_MIN_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsMinCoBuyerFullNameAsc = 'DEALS_MIN_CO_BUYER_FULL_NAME_ASC',
  DealsMinCoBuyerFullNameDesc = 'DEALS_MIN_CO_BUYER_FULL_NAME_DESC',
  DealsMinCoBuyerSalutationAsc = 'DEALS_MIN_CO_BUYER_SALUTATION_ASC',
  DealsMinCoBuyerSalutationDesc = 'DEALS_MIN_CO_BUYER_SALUTATION_DESC',
  DealsMinCoBuyerFirstNameAsc = 'DEALS_MIN_CO_BUYER_FIRST_NAME_ASC',
  DealsMinCoBuyerFirstNameDesc = 'DEALS_MIN_CO_BUYER_FIRST_NAME_DESC',
  DealsMinCoBuyerMiddleNameAsc = 'DEALS_MIN_CO_BUYER_MIDDLE_NAME_ASC',
  DealsMinCoBuyerMiddleNameDesc = 'DEALS_MIN_CO_BUYER_MIDDLE_NAME_DESC',
  DealsMinCoBuyerLastNameAsc = 'DEALS_MIN_CO_BUYER_LAST_NAME_ASC',
  DealsMinCoBuyerLastNameDesc = 'DEALS_MIN_CO_BUYER_LAST_NAME_DESC',
  DealsMinCoBuyerSuffixAsc = 'DEALS_MIN_CO_BUYER_SUFFIX_ASC',
  DealsMinCoBuyerSuffixDesc = 'DEALS_MIN_CO_BUYER_SUFFIX_DESC',
  DealsMinCoBuyerAddressLine_1Asc = 'DEALS_MIN_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsMinCoBuyerAddressLine_1Desc = 'DEALS_MIN_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsMinCoBuyerAddressLine_2Asc = 'DEALS_MIN_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsMinCoBuyerAddressLine_2Desc = 'DEALS_MIN_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsMinCoBuyerCityAsc = 'DEALS_MIN_CO_BUYER_CITY_ASC',
  DealsMinCoBuyerCityDesc = 'DEALS_MIN_CO_BUYER_CITY_DESC',
  DealsMinCoBuyerStateAsc = 'DEALS_MIN_CO_BUYER_STATE_ASC',
  DealsMinCoBuyerStateDesc = 'DEALS_MIN_CO_BUYER_STATE_DESC',
  DealsMinCoBuyerZipAsc = 'DEALS_MIN_CO_BUYER_ZIP_ASC',
  DealsMinCoBuyerZipDesc = 'DEALS_MIN_CO_BUYER_ZIP_DESC',
  DealsMinCoBuyerCountyAsc = 'DEALS_MIN_CO_BUYER_COUNTY_ASC',
  DealsMinCoBuyerCountyDesc = 'DEALS_MIN_CO_BUYER_COUNTY_DESC',
  DealsMinCoBuyerHomePhoneAsc = 'DEALS_MIN_CO_BUYER_HOME_PHONE_ASC',
  DealsMinCoBuyerHomePhoneDesc = 'DEALS_MIN_CO_BUYER_HOME_PHONE_DESC',
  DealsMinCoBuyerCellPhoneAsc = 'DEALS_MIN_CO_BUYER_CELL_PHONE_ASC',
  DealsMinCoBuyerCellPhoneDesc = 'DEALS_MIN_CO_BUYER_CELL_PHONE_DESC',
  DealsMinCoBuyerWorkPhoneAsc = 'DEALS_MIN_CO_BUYER_WORK_PHONE_ASC',
  DealsMinCoBuyerWorkPhoneDesc = 'DEALS_MIN_CO_BUYER_WORK_PHONE_DESC',
  DealsMinCoBuyerWorkExtensionAsc = 'DEALS_MIN_CO_BUYER_WORK_EXTENSION_ASC',
  DealsMinCoBuyerWorkExtensionDesc = 'DEALS_MIN_CO_BUYER_WORK_EXTENSION_DESC',
  DealsMinCoBuyerEmail_1Asc = 'DEALS_MIN_CO_BUYER_EMAIL_1_ASC',
  DealsMinCoBuyerEmail_1Desc = 'DEALS_MIN_CO_BUYER_EMAIL_1_DESC',
  DealsMinCoBuyerEmail_2Asc = 'DEALS_MIN_CO_BUYER_EMAIL_2_ASC',
  DealsMinCoBuyerEmail_2Desc = 'DEALS_MIN_CO_BUYER_EMAIL_2_DESC',
  DealsMinCoBuyerEmail_3Asc = 'DEALS_MIN_CO_BUYER_EMAIL_3_ASC',
  DealsMinCoBuyerEmail_3Desc = 'DEALS_MIN_CO_BUYER_EMAIL_3_DESC',
  DealsMinCoBuyerBirthDateAsc = 'DEALS_MIN_CO_BUYER_BIRTH_DATE_ASC',
  DealsMinCoBuyerBirthDateDesc = 'DEALS_MIN_CO_BUYER_BIRTH_DATE_DESC',
  DealsMinCoBuyerIndividualBusinessFlagAsc = 'DEALS_MIN_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsMinCoBuyerIndividualBusinessFlagDesc = 'DEALS_MIN_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsMinCoBuyerOptOutAsc = 'DEALS_MIN_CO_BUYER_OPT_OUT_ASC',
  DealsMinCoBuyerOptOutDesc = 'DEALS_MIN_CO_BUYER_OPT_OUT_DESC',
  DealsMinCoBuyerBlockEmailAsc = 'DEALS_MIN_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsMinCoBuyerBlockEmailDesc = 'DEALS_MIN_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsMinCoBuyerBlockPhoneAsc = 'DEALS_MIN_CO_BUYER_BLOCK_PHONE_ASC',
  DealsMinCoBuyerBlockPhoneDesc = 'DEALS_MIN_CO_BUYER_BLOCK_PHONE_DESC',
  DealsMinCoBuyerBlockMailAsc = 'DEALS_MIN_CO_BUYER_BLOCK_MAIL_ASC',
  DealsMinCoBuyerBlockMailDesc = 'DEALS_MIN_CO_BUYER_BLOCK_MAIL_DESC',
  DealsMinVinAsc = 'DEALS_MIN_VIN_ASC',
  DealsMinVinDesc = 'DEALS_MIN_VIN_DESC',
  DealsMinYearAsc = 'DEALS_MIN_YEAR_ASC',
  DealsMinYearDesc = 'DEALS_MIN_YEAR_DESC',
  DealsMinMakeAsc = 'DEALS_MIN_MAKE_ASC',
  DealsMinMakeDesc = 'DEALS_MIN_MAKE_DESC',
  DealsMinModelAsc = 'DEALS_MIN_MODEL_ASC',
  DealsMinModelDesc = 'DEALS_MIN_MODEL_DESC',
  DealsMinModelNumberAsc = 'DEALS_MIN_MODEL_NUMBER_ASC',
  DealsMinModelNumberDesc = 'DEALS_MIN_MODEL_NUMBER_DESC',
  DealsMinMileageAsc = 'DEALS_MIN_MILEAGE_ASC',
  DealsMinMileageDesc = 'DEALS_MIN_MILEAGE_DESC',
  DealsMinDescriptionAsc = 'DEALS_MIN_DESCRIPTION_ASC',
  DealsMinDescriptionDesc = 'DEALS_MIN_DESCRIPTION_DESC',
  DealsMinExteriorColorAsc = 'DEALS_MIN_EXTERIOR_COLOR_ASC',
  DealsMinExteriorColorDesc = 'DEALS_MIN_EXTERIOR_COLOR_DESC',
  DealsMinNewUsedAsc = 'DEALS_MIN_NEW_USED_ASC',
  DealsMinNewUsedDesc = 'DEALS_MIN_NEW_USED_DESC',
  DealsMinStockNumberAsc = 'DEALS_MIN_STOCK_NUMBER_ASC',
  DealsMinStockNumberDesc = 'DEALS_MIN_STOCK_NUMBER_DESC',
  DealsMinTransmissionAsc = 'DEALS_MIN_TRANSMISSION_ASC',
  DealsMinTransmissionDesc = 'DEALS_MIN_TRANSMISSION_DESC',
  DealsMinEngineConfigurationAsc = 'DEALS_MIN_ENGINE_CONFIGURATION_ASC',
  DealsMinEngineConfigurationDesc = 'DEALS_MIN_ENGINE_CONFIGURATION_DESC',
  DealsMinTrimAsc = 'DEALS_MIN_TRIM_ASC',
  DealsMinTrimDesc = 'DEALS_MIN_TRIM_DESC',
  DealsMinEngineNumberAsc = 'DEALS_MIN_ENGINE_NUMBER_ASC',
  DealsMinEngineNumberDesc = 'DEALS_MIN_ENGINE_NUMBER_DESC',
  DealsMinChassisNumberAsc = 'DEALS_MIN_CHASSIS_NUMBER_ASC',
  DealsMinChassisNumberDesc = 'DEALS_MIN_CHASSIS_NUMBER_DESC',
  DealsMinLicensePlateNumberAsc = 'DEALS_MIN_LICENSE_PLATE_NUMBER_ASC',
  DealsMinLicensePlateNumberDesc = 'DEALS_MIN_LICENSE_PLATE_NUMBER_DESC',
  DealsMinDeliveryDateAsc = 'DEALS_MIN_DELIVERY_DATE_ASC',
  DealsMinDeliveryDateDesc = 'DEALS_MIN_DELIVERY_DATE_DESC',
  DealsMinDeliveryMileageAsc = 'DEALS_MIN_DELIVERY_MILEAGE_ASC',
  DealsMinDeliveryMileageDesc = 'DEALS_MIN_DELIVERY_MILEAGE_DESC',
  DealsMinInventoryDateAsc = 'DEALS_MIN_INVENTORY_DATE_ASC',
  DealsMinInventoryDateDesc = 'DEALS_MIN_INVENTORY_DATE_DESC',
  DealsMinInServiceDateAsc = 'DEALS_MIN_IN_SERVICE_DATE_ASC',
  DealsMinInServiceDateDesc = 'DEALS_MIN_IN_SERVICE_DATE_DESC',
  DealsMinVinExplosionYearAsc = 'DEALS_MIN_VIN_EXPLOSION_YEAR_ASC',
  DealsMinVinExplosionYearDesc = 'DEALS_MIN_VIN_EXPLOSION_YEAR_DESC',
  DealsMinVinExplosionMakeAsc = 'DEALS_MIN_VIN_EXPLOSION_MAKE_ASC',
  DealsMinVinExplosionMakeDesc = 'DEALS_MIN_VIN_EXPLOSION_MAKE_DESC',
  DealsMinVinExplosionModelAsc = 'DEALS_MIN_VIN_EXPLOSION_MODEL_ASC',
  DealsMinVinExplosionModelDesc = 'DEALS_MIN_VIN_EXPLOSION_MODEL_DESC',
  DealsMinVinExplosionTrimAsc = 'DEALS_MIN_VIN_EXPLOSION_TRIM_ASC',
  DealsMinVinExplosionTrimDesc = 'DEALS_MIN_VIN_EXPLOSION_TRIM_DESC',
  DealsMinVinExplosionTransmissionTypeAsc = 'DEALS_MIN_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsMinVinExplosionTransmissionTypeDesc = 'DEALS_MIN_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsMinVinExplosionFuelTypeAsc = 'DEALS_MIN_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsMinVinExplosionFuelTypeDesc = 'DEALS_MIN_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsMinVinExplosionEngineSizeAsc = 'DEALS_MIN_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsMinVinExplosionEngineSizeDesc = 'DEALS_MIN_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsMinVinExplosionGvwRangeAsc = 'DEALS_MIN_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsMinVinExplosionGvwRangeDesc = 'DEALS_MIN_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsMinTrade_1VinAsc = 'DEALS_MIN_TRADE_1_VIN_ASC',
  DealsMinTrade_1VinDesc = 'DEALS_MIN_TRADE_1_VIN_DESC',
  DealsMinTrade_1YearAsc = 'DEALS_MIN_TRADE_1_YEAR_ASC',
  DealsMinTrade_1YearDesc = 'DEALS_MIN_TRADE_1_YEAR_DESC',
  DealsMinTrade_1MakeAsc = 'DEALS_MIN_TRADE_1_MAKE_ASC',
  DealsMinTrade_1MakeDesc = 'DEALS_MIN_TRADE_1_MAKE_DESC',
  DealsMinTrade_1ModelAsc = 'DEALS_MIN_TRADE_1_MODEL_ASC',
  DealsMinTrade_1ModelDesc = 'DEALS_MIN_TRADE_1_MODEL_DESC',
  DealsMinTrade_1OdometerAsc = 'DEALS_MIN_TRADE_1_ODOMETER_ASC',
  DealsMinTrade_1OdometerDesc = 'DEALS_MIN_TRADE_1_ODOMETER_DESC',
  DealsMinTrade_1ActualCashValueAsc = 'DEALS_MIN_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsMinTrade_1ActualCashValueDesc = 'DEALS_MIN_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsMinTrade_1GrossAsc = 'DEALS_MIN_TRADE_1_GROSS_ASC',
  DealsMinTrade_1GrossDesc = 'DEALS_MIN_TRADE_1_GROSS_DESC',
  DealsMinTrade_1PayoffAsc = 'DEALS_MIN_TRADE_1_PAYOFF_ASC',
  DealsMinTrade_1PayoffDesc = 'DEALS_MIN_TRADE_1_PAYOFF_DESC',
  DealsMinTrade_2VinAsc = 'DEALS_MIN_TRADE_2_VIN_ASC',
  DealsMinTrade_2VinDesc = 'DEALS_MIN_TRADE_2_VIN_DESC',
  DealsMinTrade_2YearAsc = 'DEALS_MIN_TRADE_2_YEAR_ASC',
  DealsMinTrade_2YearDesc = 'DEALS_MIN_TRADE_2_YEAR_DESC',
  DealsMinTrade_2MakeAsc = 'DEALS_MIN_TRADE_2_MAKE_ASC',
  DealsMinTrade_2MakeDesc = 'DEALS_MIN_TRADE_2_MAKE_DESC',
  DealsMinTrade_2ModelAsc = 'DEALS_MIN_TRADE_2_MODEL_ASC',
  DealsMinTrade_2ModelDesc = 'DEALS_MIN_TRADE_2_MODEL_DESC',
  DealsMinTrade_2OdometerAsc = 'DEALS_MIN_TRADE_2_ODOMETER_ASC',
  DealsMinTrade_2OdometerDesc = 'DEALS_MIN_TRADE_2_ODOMETER_DESC',
  DealsMinTrade_2ActualCashValueAsc = 'DEALS_MIN_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsMinTrade_2ActualCashValueDesc = 'DEALS_MIN_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsMinTrade_2GrossAsc = 'DEALS_MIN_TRADE_2_GROSS_ASC',
  DealsMinTrade_2GrossDesc = 'DEALS_MIN_TRADE_2_GROSS_DESC',
  DealsMinTrade_2PayoffAsc = 'DEALS_MIN_TRADE_2_PAYOFF_ASC',
  DealsMinTrade_2PayoffDesc = 'DEALS_MIN_TRADE_2_PAYOFF_DESC',
  DealsMinSalesman_1NumberAsc = 'DEALS_MIN_SALESMAN_1_NUMBER_ASC',
  DealsMinSalesman_1NumberDesc = 'DEALS_MIN_SALESMAN_1_NUMBER_DESC',
  DealsMinSalesman_1NameAsc = 'DEALS_MIN_SALESMAN_1_NAME_ASC',
  DealsMinSalesman_1NameDesc = 'DEALS_MIN_SALESMAN_1_NAME_DESC',
  DealsMinSalesman_2NumberAsc = 'DEALS_MIN_SALESMAN_2_NUMBER_ASC',
  DealsMinSalesman_2NumberDesc = 'DEALS_MIN_SALESMAN_2_NUMBER_DESC',
  DealsMinSalesman_2NameAsc = 'DEALS_MIN_SALESMAN_2_NAME_ASC',
  DealsMinSalesman_2NameDesc = 'DEALS_MIN_SALESMAN_2_NAME_DESC',
  DealsMinSalesman_3NumberAsc = 'DEALS_MIN_SALESMAN_3_NUMBER_ASC',
  DealsMinSalesman_3NumberDesc = 'DEALS_MIN_SALESMAN_3_NUMBER_DESC',
  DealsMinSalesman_3NameAsc = 'DEALS_MIN_SALESMAN_3_NAME_ASC',
  DealsMinSalesman_3NameDesc = 'DEALS_MIN_SALESMAN_3_NAME_DESC',
  DealsMinClosingManagerNumberAsc = 'DEALS_MIN_CLOSING_MANAGER_NUMBER_ASC',
  DealsMinClosingManagerNumberDesc = 'DEALS_MIN_CLOSING_MANAGER_NUMBER_DESC',
  DealsMinClosingManagerNameAsc = 'DEALS_MIN_CLOSING_MANAGER_NAME_ASC',
  DealsMinClosingManagerNameDesc = 'DEALS_MIN_CLOSING_MANAGER_NAME_DESC',
  DealsMinFinanceManagerNumberAsc = 'DEALS_MIN_FINANCE_MANAGER_NUMBER_ASC',
  DealsMinFinanceManagerNumberDesc = 'DEALS_MIN_FINANCE_MANAGER_NUMBER_DESC',
  DealsMinFinanceManagerNameAsc = 'DEALS_MIN_FINANCE_MANAGER_NAME_ASC',
  DealsMinFinanceManagerNameDesc = 'DEALS_MIN_FINANCE_MANAGER_NAME_DESC',
  DealsMinSalesmanManagerNumberAsc = 'DEALS_MIN_SALESMAN_MANAGER_NUMBER_ASC',
  DealsMinSalesmanManagerNumberDesc = 'DEALS_MIN_SALESMAN_MANAGER_NUMBER_DESC',
  DealsMinSalesmanManagerNameAsc = 'DEALS_MIN_SALESMAN_MANAGER_NAME_ASC',
  DealsMinSalesmanManagerNameDesc = 'DEALS_MIN_SALESMAN_MANAGER_NAME_DESC',
  DealsMinMsrpAsc = 'DEALS_MIN_MSRP_ASC',
  DealsMinMsrpDesc = 'DEALS_MIN_MSRP_DESC',
  DealsMinListPriceAsc = 'DEALS_MIN_LIST_PRICE_ASC',
  DealsMinListPriceDesc = 'DEALS_MIN_LIST_PRICE_DESC',
  DealsMinSalesPriceAsc = 'DEALS_MIN_SALES_PRICE_ASC',
  DealsMinSalesPriceDesc = 'DEALS_MIN_SALES_PRICE_DESC',
  DealsMinJournalPriceAsc = 'DEALS_MIN_JOURNAL_PRICE_ASC',
  DealsMinJournalPriceDesc = 'DEALS_MIN_JOURNAL_PRICE_DESC',
  DealsMinCostAsc = 'DEALS_MIN_COST_ASC',
  DealsMinCostDesc = 'DEALS_MIN_COST_DESC',
  DealsMinJournalCostAsc = 'DEALS_MIN_JOURNAL_COST_ASC',
  DealsMinJournalCostDesc = 'DEALS_MIN_JOURNAL_COST_DESC',
  DealsMinAdjustmentsAsc = 'DEALS_MIN_ADJUSTMENTS_ASC',
  DealsMinAdjustmentsDesc = 'DEALS_MIN_ADJUSTMENTS_DESC',
  DealsMinAdjustedCostAsc = 'DEALS_MIN_ADJUSTED_COST_ASC',
  DealsMinAdjustedCostDesc = 'DEALS_MIN_ADJUSTED_COST_DESC',
  DealsMinIncentivesAsc = 'DEALS_MIN_INCENTIVES_ASC',
  DealsMinIncentivesDesc = 'DEALS_MIN_INCENTIVES_DESC',
  DealsMinPackAmountAsc = 'DEALS_MIN_PACK_AMOUNT_ASC',
  DealsMinPackAmountDesc = 'DEALS_MIN_PACK_AMOUNT_DESC',
  DealsMinSaleNetAsc = 'DEALS_MIN_SALE_NET_ASC',
  DealsMinSaleNetDesc = 'DEALS_MIN_SALE_NET_DESC',
  DealsMinTotalTradeActualCashValueAsc = 'DEALS_MIN_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsMinTotalTradeActualCashValueDesc = 'DEALS_MIN_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsMinTotalTradeGrossAsc = 'DEALS_MIN_TOTAL_TRADE_GROSS_ASC',
  DealsMinTotalTradeGrossDesc = 'DEALS_MIN_TOTAL_TRADE_GROSS_DESC',
  DealsMinWeOweFrontAsc = 'DEALS_MIN_WE_OWE_FRONT_ASC',
  DealsMinWeOweFrontDesc = 'DEALS_MIN_WE_OWE_FRONT_DESC',
  DealsMinTotalFrontFeesAftermarketProfitAsc = 'DEALS_MIN_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsMinTotalFrontFeesAftermarketProfitDesc = 'DEALS_MIN_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsMinTotalFrontCommissionAsc = 'DEALS_MIN_TOTAL_FRONT_COMMISSION_ASC',
  DealsMinTotalFrontCommissionDesc = 'DEALS_MIN_TOTAL_FRONT_COMMISSION_DESC',
  DealsMinTotalFrontSalesAsc = 'DEALS_MIN_TOTAL_FRONT_SALES_ASC',
  DealsMinTotalFrontSalesDesc = 'DEALS_MIN_TOTAL_FRONT_SALES_DESC',
  DealsMinTotalFrontCostAsc = 'DEALS_MIN_TOTAL_FRONT_COST_ASC',
  DealsMinTotalFrontCostDesc = 'DEALS_MIN_TOTAL_FRONT_COST_DESC',
  DealsMinFrontGrossAsc = 'DEALS_MIN_FRONT_GROSS_ASC',
  DealsMinFrontGrossDesc = 'DEALS_MIN_FRONT_GROSS_DESC',
  DealsMinFinanceProfitAsc = 'DEALS_MIN_FINANCE_PROFIT_ASC',
  DealsMinFinanceProfitDesc = 'DEALS_MIN_FINANCE_PROFIT_DESC',
  DealsMinTotalWarrantyProfitAsc = 'DEALS_MIN_TOTAL_WARRANTY_PROFIT_ASC',
  DealsMinTotalWarrantyProfitDesc = 'DEALS_MIN_TOTAL_WARRANTY_PROFIT_DESC',
  DealsMinWeOweBackAsc = 'DEALS_MIN_WE_OWE_BACK_ASC',
  DealsMinWeOweBackDesc = 'DEALS_MIN_WE_OWE_BACK_DESC',
  DealsMinInsuranceProfitAsc = 'DEALS_MIN_INSURANCE_PROFIT_ASC',
  DealsMinInsuranceProfitDesc = 'DEALS_MIN_INSURANCE_PROFIT_DESC',
  DealsMinTotalBackFeesAftermarketProfitAsc = 'DEALS_MIN_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsMinTotalBackFeesAftermarketProfitDesc = 'DEALS_MIN_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsMinFinanceReserveAsc = 'DEALS_MIN_FINANCE_RESERVE_ASC',
  DealsMinFinanceReserveDesc = 'DEALS_MIN_FINANCE_RESERVE_DESC',
  DealsMinTotalBackCommissionAsc = 'DEALS_MIN_TOTAL_BACK_COMMISSION_ASC',
  DealsMinTotalBackCommissionDesc = 'DEALS_MIN_TOTAL_BACK_COMMISSION_DESC',
  DealsMinTotalBackSalesAsc = 'DEALS_MIN_TOTAL_BACK_SALES_ASC',
  DealsMinTotalBackSalesDesc = 'DEALS_MIN_TOTAL_BACK_SALES_DESC',
  DealsMinTotalBackCostAsc = 'DEALS_MIN_TOTAL_BACK_COST_ASC',
  DealsMinTotalBackCostDesc = 'DEALS_MIN_TOTAL_BACK_COST_DESC',
  DealsMinBackGrossAsc = 'DEALS_MIN_BACK_GROSS_ASC',
  DealsMinBackGrossDesc = 'DEALS_MIN_BACK_GROSS_DESC',
  DealsMinTotalProfitAsc = 'DEALS_MIN_TOTAL_PROFIT_ASC',
  DealsMinTotalProfitDesc = 'DEALS_MIN_TOTAL_PROFIT_DESC',
  DealsMinGrossProfitAsc = 'DEALS_MIN_GROSS_PROFIT_ASC',
  DealsMinGrossProfitDesc = 'DEALS_MIN_GROSS_PROFIT_DESC',
  DealsMinGrossPayableAsc = 'DEALS_MIN_GROSS_PAYABLE_ASC',
  DealsMinGrossPayableDesc = 'DEALS_MIN_GROSS_PAYABLE_DESC',
  DealsMinDealStatusAsc = 'DEALS_MIN_DEAL_STATUS_ASC',
  DealsMinDealStatusDesc = 'DEALS_MIN_DEAL_STATUS_DESC',
  DealsMinEntryDateAsc = 'DEALS_MIN_ENTRY_DATE_ASC',
  DealsMinEntryDateDesc = 'DEALS_MIN_ENTRY_DATE_DESC',
  DealsMinBookedDateAsc = 'DEALS_MIN_BOOKED_DATE_ASC',
  DealsMinBookedDateDesc = 'DEALS_MIN_BOOKED_DATE_DESC',
  DealsMinFinalizedDateAsc = 'DEALS_MIN_FINALIZED_DATE_ASC',
  DealsMinFinalizedDateDesc = 'DEALS_MIN_FINALIZED_DATE_DESC',
  DealsMinContractDateAsc = 'DEALS_MIN_CONTRACT_DATE_ASC',
  DealsMinContractDateDesc = 'DEALS_MIN_CONTRACT_DATE_DESC',
  DealsMinAccountingDateAsc = 'DEALS_MIN_ACCOUNTING_DATE_ASC',
  DealsMinAccountingDateDesc = 'DEALS_MIN_ACCOUNTING_DATE_DESC',
  DealsMinStatusChangeDateAsc = 'DEALS_MIN_STATUS_CHANGE_DATE_ASC',
  DealsMinStatusChangeDateDesc = 'DEALS_MIN_STATUS_CHANGE_DATE_DESC',
  DealsMinFirstPayDateAsc = 'DEALS_MIN_FIRST_PAY_DATE_ASC',
  DealsMinFirstPayDateDesc = 'DEALS_MIN_FIRST_PAY_DATE_DESC',
  DealsMinDealTypeAsc = 'DEALS_MIN_DEAL_TYPE_ASC',
  DealsMinDealTypeDesc = 'DEALS_MIN_DEAL_TYPE_DESC',
  DealsMinSaleTypeAsc = 'DEALS_MIN_SALE_TYPE_ASC',
  DealsMinSaleTypeDesc = 'DEALS_MIN_SALE_TYPE_DESC',
  DealsMinBankIdAsc = 'DEALS_MIN_BANK_ID_ASC',
  DealsMinBankIdDesc = 'DEALS_MIN_BANK_ID_DESC',
  DealsMinBankNameAsc = 'DEALS_MIN_BANK_NAME_ASC',
  DealsMinBankNameDesc = 'DEALS_MIN_BANK_NAME_DESC',
  DealsMinBankAddressAsc = 'DEALS_MIN_BANK_ADDRESS_ASC',
  DealsMinBankAddressDesc = 'DEALS_MIN_BANK_ADDRESS_DESC',
  DealsMinTermAsc = 'DEALS_MIN_TERM_ASC',
  DealsMinTermDesc = 'DEALS_MIN_TERM_DESC',
  DealsMinAmountFinancedAsc = 'DEALS_MIN_AMOUNT_FINANCED_ASC',
  DealsMinAmountFinancedDesc = 'DEALS_MIN_AMOUNT_FINANCED_DESC',
  DealsMinAprAsc = 'DEALS_MIN_APR_ASC',
  DealsMinAprDesc = 'DEALS_MIN_APR_DESC',
  DealsMinMonthlyPaymentAsc = 'DEALS_MIN_MONTHLY_PAYMENT_ASC',
  DealsMinMonthlyPaymentDesc = 'DEALS_MIN_MONTHLY_PAYMENT_DESC',
  DealsMinPaymentTotalAsc = 'DEALS_MIN_PAYMENT_TOTAL_ASC',
  DealsMinPaymentTotalDesc = 'DEALS_MIN_PAYMENT_TOTAL_DESC',
  DealsMinRebatesAsc = 'DEALS_MIN_REBATES_ASC',
  DealsMinRebatesDesc = 'DEALS_MIN_REBATES_DESC',
  DealsMinDepositAsc = 'DEALS_MIN_DEPOSIT_ASC',
  DealsMinDepositDesc = 'DEALS_MIN_DEPOSIT_DESC',
  DealsMinDownPaymentAsc = 'DEALS_MIN_DOWN_PAYMENT_ASC',
  DealsMinDownPaymentDesc = 'DEALS_MIN_DOWN_PAYMENT_DESC',
  DealsMinTotalNetTradesAsc = 'DEALS_MIN_TOTAL_NET_TRADES_ASC',
  DealsMinTotalNetTradesDesc = 'DEALS_MIN_TOTAL_NET_TRADES_DESC',
  DealsMinTotalDownAsc = 'DEALS_MIN_TOTAL_DOWN_ASC',
  DealsMinTotalDownDesc = 'DEALS_MIN_TOTAL_DOWN_DESC',
  DealsMinBalloonAmountAsc = 'DEALS_MIN_BALLOON_AMOUNT_ASC',
  DealsMinBalloonAmountDesc = 'DEALS_MIN_BALLOON_AMOUNT_DESC',
  DealsMinAdjustedBalloonAmountAsc = 'DEALS_MIN_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsMinAdjustedBalloonAmountDesc = 'DEALS_MIN_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsMinHoldbackAmountAsc = 'DEALS_MIN_HOLDBACK_AMOUNT_ASC',
  DealsMinHoldbackAmountDesc = 'DEALS_MIN_HOLDBACK_AMOUNT_DESC',
  DealsMinTotalDriveOffAmountAsc = 'DEALS_MIN_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsMinTotalDriveOffAmountDesc = 'DEALS_MIN_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsMinLicenseFeeAsc = 'DEALS_MIN_LICENSE_FEE_ASC',
  DealsMinLicenseFeeDesc = 'DEALS_MIN_LICENSE_FEE_DESC',
  DealsMinRegistrationFeeAsc = 'DEALS_MIN_REGISTRATION_FEE_ASC',
  DealsMinRegistrationFeeDesc = 'DEALS_MIN_REGISTRATION_FEE_DESC',
  DealsMinDocumentationFeeAsc = 'DEALS_MIN_DOCUMENTATION_FEE_ASC',
  DealsMinDocumentationFeeDesc = 'DEALS_MIN_DOCUMENTATION_FEE_DESC',
  DealsMinFinanceChargeAsc = 'DEALS_MIN_FINANCE_CHARGE_ASC',
  DealsMinFinanceChargeDesc = 'DEALS_MIN_FINANCE_CHARGE_DESC',
  DealsMinTotalPickupPaymentsAsc = 'DEALS_MIN_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsMinTotalPickupPaymentsDesc = 'DEALS_MIN_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsMinSellRateAsc = 'DEALS_MIN_SELL_RATE_ASC',
  DealsMinSellRateDesc = 'DEALS_MIN_SELL_RATE_DESC',
  DealsMinBuyRateAsc = 'DEALS_MIN_BUY_RATE_ASC',
  DealsMinBuyRateDesc = 'DEALS_MIN_BUY_RATE_DESC',
  DealsMinResidualRateAsc = 'DEALS_MIN_RESIDUAL_RATE_ASC',
  DealsMinResidualRateDesc = 'DEALS_MIN_RESIDUAL_RATE_DESC',
  DealsMinResidualAmountAsc = 'DEALS_MIN_RESIDUAL_AMOUNT_ASC',
  DealsMinResidualAmountDesc = 'DEALS_MIN_RESIDUAL_AMOUNT_DESC',
  DealsMinAllowedMilesAsc = 'DEALS_MIN_ALLOWED_MILES_ASC',
  DealsMinAllowedMilesDesc = 'DEALS_MIN_ALLOWED_MILES_DESC',
  DealsMinEstimatedMilesAsc = 'DEALS_MIN_ESTIMATED_MILES_ASC',
  DealsMinEstimatedMilesDesc = 'DEALS_MIN_ESTIMATED_MILES_DESC',
  DealsMinMileageRateAsc = 'DEALS_MIN_MILEAGE_RATE_ASC',
  DealsMinMileageRateDesc = 'DEALS_MIN_MILEAGE_RATE_DESC',
  DealsMinAcquisitionFeeAsc = 'DEALS_MIN_ACQUISITION_FEE_ASC',
  DealsMinAcquisitionFeeDesc = 'DEALS_MIN_ACQUISITION_FEE_DESC',
  DealsMinBasePaymentAsc = 'DEALS_MIN_BASE_PAYMENT_ASC',
  DealsMinBasePaymentDesc = 'DEALS_MIN_BASE_PAYMENT_DESC',
  DealsMinSecurityDepositAsc = 'DEALS_MIN_SECURITY_DEPOSIT_ASC',
  DealsMinSecurityDepositDesc = 'DEALS_MIN_SECURITY_DEPOSIT_DESC',
  DealsMinTotalCapitalReductionAsc = 'DEALS_MIN_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsMinTotalCapitalReductionDesc = 'DEALS_MIN_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsMinNetCapitalCostAsc = 'DEALS_MIN_NET_CAPITAL_COST_ASC',
  DealsMinNetCapitalCostDesc = 'DEALS_MIN_NET_CAPITAL_COST_DESC',
  DealsMinLeaseDepreciationValueAsc = 'DEALS_MIN_LEASE_DEPRECIATION_VALUE_ASC',
  DealsMinLeaseDepreciationValueDesc = 'DEALS_MIN_LEASE_DEPRECIATION_VALUE_DESC',
  DealsMinDealerFeesAsc = 'DEALS_MIN_DEALER_FEES_ASC',
  DealsMinDealerFeesDesc = 'DEALS_MIN_DEALER_FEES_DESC',
  DealsMinGovernmentFeesAsc = 'DEALS_MIN_GOVERNMENT_FEES_ASC',
  DealsMinGovernmentFeesDesc = 'DEALS_MIN_GOVERNMENT_FEES_DESC',
  DealsMinTotalTaxAsc = 'DEALS_MIN_TOTAL_TAX_ASC',
  DealsMinTotalTaxDesc = 'DEALS_MIN_TOTAL_TAX_DESC',
  DealsMinRegistrationStateAsc = 'DEALS_MIN_REGISTRATION_STATE_ASC',
  DealsMinRegistrationStateDesc = 'DEALS_MIN_REGISTRATION_STATE_DESC',
  DealsMinReportOfSaleNumberAsc = 'DEALS_MIN_REPORT_OF_SALE_NUMBER_ASC',
  DealsMinReportOfSaleNumberDesc = 'DEALS_MIN_REPORT_OF_SALE_NUMBER_DESC',
  DealsMinSalesman_1TotalCommissionAsc = 'DEALS_MIN_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsMinSalesman_1TotalCommissionDesc = 'DEALS_MIN_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsMinSalesman_1FrontCommissionAsc = 'DEALS_MIN_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsMinSalesman_1FrontCommissionDesc = 'DEALS_MIN_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsMinSalesman_1BackCommissionAsc = 'DEALS_MIN_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsMinSalesman_1BackCommissionDesc = 'DEALS_MIN_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsMinSalesman_2TotalCommissionAsc = 'DEALS_MIN_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsMinSalesman_2TotalCommissionDesc = 'DEALS_MIN_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsMinSalesman_2FrontCommissionAsc = 'DEALS_MIN_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsMinSalesman_2FrontCommissionDesc = 'DEALS_MIN_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsMinSalesman_2BackCommissionAsc = 'DEALS_MIN_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsMinSalesman_2BackCommissionDesc = 'DEALS_MIN_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsMinSalesman_3TotalCommissionAsc = 'DEALS_MIN_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsMinSalesman_3TotalCommissionDesc = 'DEALS_MIN_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsMinSalesman_3FrontCommissionAsc = 'DEALS_MIN_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsMinSalesman_3FrontCommissionDesc = 'DEALS_MIN_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsMinSalesman_3BackCommissionAsc = 'DEALS_MIN_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsMinSalesman_3BackCommissionDesc = 'DEALS_MIN_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsMinWarranty_1NameAsc = 'DEALS_MIN_WARRANTY_1_NAME_ASC',
  DealsMinWarranty_1NameDesc = 'DEALS_MIN_WARRANTY_1_NAME_DESC',
  DealsMinWarranty_1SaleAsc = 'DEALS_MIN_WARRANTY_1_SALE_ASC',
  DealsMinWarranty_1SaleDesc = 'DEALS_MIN_WARRANTY_1_SALE_DESC',
  DealsMinWarranty_1CostAsc = 'DEALS_MIN_WARRANTY_1_COST_ASC',
  DealsMinWarranty_1CostDesc = 'DEALS_MIN_WARRANTY_1_COST_DESC',
  DealsMinWarranty_1MilesAsc = 'DEALS_MIN_WARRANTY_1_MILES_ASC',
  DealsMinWarranty_1MilesDesc = 'DEALS_MIN_WARRANTY_1_MILES_DESC',
  DealsMinWarranty_1TermAsc = 'DEALS_MIN_WARRANTY_1_TERM_ASC',
  DealsMinWarranty_1TermDesc = 'DEALS_MIN_WARRANTY_1_TERM_DESC',
  DealsMinWarranty_2NameAsc = 'DEALS_MIN_WARRANTY_2_NAME_ASC',
  DealsMinWarranty_2NameDesc = 'DEALS_MIN_WARRANTY_2_NAME_DESC',
  DealsMinWarranty_2SaleAsc = 'DEALS_MIN_WARRANTY_2_SALE_ASC',
  DealsMinWarranty_2SaleDesc = 'DEALS_MIN_WARRANTY_2_SALE_DESC',
  DealsMinWarranty_2CostAsc = 'DEALS_MIN_WARRANTY_2_COST_ASC',
  DealsMinWarranty_2CostDesc = 'DEALS_MIN_WARRANTY_2_COST_DESC',
  DealsMinWarranty_2MilesAsc = 'DEALS_MIN_WARRANTY_2_MILES_ASC',
  DealsMinWarranty_2MilesDesc = 'DEALS_MIN_WARRANTY_2_MILES_DESC',
  DealsMinWarranty_2TermAsc = 'DEALS_MIN_WARRANTY_2_TERM_ASC',
  DealsMinWarranty_2TermDesc = 'DEALS_MIN_WARRANTY_2_TERM_DESC',
  DealsMinWarranty_3NameAsc = 'DEALS_MIN_WARRANTY_3_NAME_ASC',
  DealsMinWarranty_3NameDesc = 'DEALS_MIN_WARRANTY_3_NAME_DESC',
  DealsMinWarranty_3SaleAsc = 'DEALS_MIN_WARRANTY_3_SALE_ASC',
  DealsMinWarranty_3SaleDesc = 'DEALS_MIN_WARRANTY_3_SALE_DESC',
  DealsMinWarranty_3CostAsc = 'DEALS_MIN_WARRANTY_3_COST_ASC',
  DealsMinWarranty_3CostDesc = 'DEALS_MIN_WARRANTY_3_COST_DESC',
  DealsMinWarranty_3MilesAsc = 'DEALS_MIN_WARRANTY_3_MILES_ASC',
  DealsMinWarranty_3MilesDesc = 'DEALS_MIN_WARRANTY_3_MILES_DESC',
  DealsMinWarranty_3TermAsc = 'DEALS_MIN_WARRANTY_3_TERM_ASC',
  DealsMinWarranty_3TermDesc = 'DEALS_MIN_WARRANTY_3_TERM_DESC',
  DealsMinWarranty_4NameAsc = 'DEALS_MIN_WARRANTY_4_NAME_ASC',
  DealsMinWarranty_4NameDesc = 'DEALS_MIN_WARRANTY_4_NAME_DESC',
  DealsMinWarranty_4SaleAsc = 'DEALS_MIN_WARRANTY_4_SALE_ASC',
  DealsMinWarranty_4SaleDesc = 'DEALS_MIN_WARRANTY_4_SALE_DESC',
  DealsMinWarranty_4CostAsc = 'DEALS_MIN_WARRANTY_4_COST_ASC',
  DealsMinWarranty_4CostDesc = 'DEALS_MIN_WARRANTY_4_COST_DESC',
  DealsMinWarranty_4MilesAsc = 'DEALS_MIN_WARRANTY_4_MILES_ASC',
  DealsMinWarranty_4MilesDesc = 'DEALS_MIN_WARRANTY_4_MILES_DESC',
  DealsMinWarranty_4TermAsc = 'DEALS_MIN_WARRANTY_4_TERM_ASC',
  DealsMinWarranty_4TermDesc = 'DEALS_MIN_WARRANTY_4_TERM_DESC',
  DealsMinWarranty_5NameAsc = 'DEALS_MIN_WARRANTY_5_NAME_ASC',
  DealsMinWarranty_5NameDesc = 'DEALS_MIN_WARRANTY_5_NAME_DESC',
  DealsMinWarranty_5SaleAsc = 'DEALS_MIN_WARRANTY_5_SALE_ASC',
  DealsMinWarranty_5SaleDesc = 'DEALS_MIN_WARRANTY_5_SALE_DESC',
  DealsMinWarranty_5CostAsc = 'DEALS_MIN_WARRANTY_5_COST_ASC',
  DealsMinWarranty_5CostDesc = 'DEALS_MIN_WARRANTY_5_COST_DESC',
  DealsMinWarranty_5MilesAsc = 'DEALS_MIN_WARRANTY_5_MILES_ASC',
  DealsMinWarranty_5MilesDesc = 'DEALS_MIN_WARRANTY_5_MILES_DESC',
  DealsMinWarranty_5TermAsc = 'DEALS_MIN_WARRANTY_5_TERM_ASC',
  DealsMinWarranty_5TermDesc = 'DEALS_MIN_WARRANTY_5_TERM_DESC',
  DealsMinTotalFeeAftermarketSaleAsc = 'DEALS_MIN_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsMinTotalFeeAftermarketSaleDesc = 'DEALS_MIN_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsMinTotalFeeAftermarketCostAsc = 'DEALS_MIN_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsMinTotalFeeAftermarketCostDesc = 'DEALS_MIN_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsMinFeeAftermarket_1NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_1_NAME_ASC',
  DealsMinFeeAftermarket_1NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_1_NAME_DESC',
  DealsMinFeeAftermarket_1SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_1_SALE_ASC',
  DealsMinFeeAftermarket_1SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_1_SALE_DESC',
  DealsMinFeeAftermarket_1CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_1_COST_ASC',
  DealsMinFeeAftermarket_1CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_1_COST_DESC',
  DealsMinFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_2NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_2_NAME_ASC',
  DealsMinFeeAftermarket_2NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_2_NAME_DESC',
  DealsMinFeeAftermarket_2SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_2_SALE_ASC',
  DealsMinFeeAftermarket_2SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_2_SALE_DESC',
  DealsMinFeeAftermarket_2CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_2_COST_ASC',
  DealsMinFeeAftermarket_2CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_2_COST_DESC',
  DealsMinFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_3NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_3_NAME_ASC',
  DealsMinFeeAftermarket_3NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_3_NAME_DESC',
  DealsMinFeeAftermarket_3SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_3_SALE_ASC',
  DealsMinFeeAftermarket_3SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_3_SALE_DESC',
  DealsMinFeeAftermarket_3CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_3_COST_ASC',
  DealsMinFeeAftermarket_3CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_3_COST_DESC',
  DealsMinFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_4NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_4_NAME_ASC',
  DealsMinFeeAftermarket_4NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_4_NAME_DESC',
  DealsMinFeeAftermarket_4SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_4_SALE_ASC',
  DealsMinFeeAftermarket_4SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_4_SALE_DESC',
  DealsMinFeeAftermarket_4CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_4_COST_ASC',
  DealsMinFeeAftermarket_4CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_4_COST_DESC',
  DealsMinFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_5NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_5_NAME_ASC',
  DealsMinFeeAftermarket_5NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_5_NAME_DESC',
  DealsMinFeeAftermarket_5SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_5_SALE_ASC',
  DealsMinFeeAftermarket_5SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_5_SALE_DESC',
  DealsMinFeeAftermarket_5CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_5_COST_ASC',
  DealsMinFeeAftermarket_5CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_5_COST_DESC',
  DealsMinFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_6NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_6_NAME_ASC',
  DealsMinFeeAftermarket_6NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_6_NAME_DESC',
  DealsMinFeeAftermarket_6SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_6_SALE_ASC',
  DealsMinFeeAftermarket_6SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_6_SALE_DESC',
  DealsMinFeeAftermarket_6CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_6_COST_ASC',
  DealsMinFeeAftermarket_6CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_6_COST_DESC',
  DealsMinFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_7NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_7_NAME_ASC',
  DealsMinFeeAftermarket_7NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_7_NAME_DESC',
  DealsMinFeeAftermarket_7SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_7_SALE_ASC',
  DealsMinFeeAftermarket_7SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_7_SALE_DESC',
  DealsMinFeeAftermarket_7CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_7_COST_ASC',
  DealsMinFeeAftermarket_7CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_7_COST_DESC',
  DealsMinFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_8NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_8_NAME_ASC',
  DealsMinFeeAftermarket_8NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_8_NAME_DESC',
  DealsMinFeeAftermarket_8SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_8_SALE_ASC',
  DealsMinFeeAftermarket_8SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_8_SALE_DESC',
  DealsMinFeeAftermarket_8CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_8_COST_ASC',
  DealsMinFeeAftermarket_8CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_8_COST_DESC',
  DealsMinFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_9NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_9_NAME_ASC',
  DealsMinFeeAftermarket_9NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_9_NAME_DESC',
  DealsMinFeeAftermarket_9SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_9_SALE_ASC',
  DealsMinFeeAftermarket_9SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_9_SALE_DESC',
  DealsMinFeeAftermarket_9CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_9_COST_ASC',
  DealsMinFeeAftermarket_9CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_9_COST_DESC',
  DealsMinFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_10NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_10_NAME_ASC',
  DealsMinFeeAftermarket_10NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_10_NAME_DESC',
  DealsMinFeeAftermarket_10SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_10_SALE_ASC',
  DealsMinFeeAftermarket_10SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_10_SALE_DESC',
  DealsMinFeeAftermarket_10CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_10_COST_ASC',
  DealsMinFeeAftermarket_10CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_10_COST_DESC',
  DealsMinFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_11NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_11_NAME_ASC',
  DealsMinFeeAftermarket_11NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_11_NAME_DESC',
  DealsMinFeeAftermarket_11SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_11_SALE_ASC',
  DealsMinFeeAftermarket_11SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_11_SALE_DESC',
  DealsMinFeeAftermarket_11CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_11_COST_ASC',
  DealsMinFeeAftermarket_11CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_11_COST_DESC',
  DealsMinFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_12NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_12_NAME_ASC',
  DealsMinFeeAftermarket_12NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_12_NAME_DESC',
  DealsMinFeeAftermarket_12SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_12_SALE_ASC',
  DealsMinFeeAftermarket_12SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_12_SALE_DESC',
  DealsMinFeeAftermarket_12CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_12_COST_ASC',
  DealsMinFeeAftermarket_12CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_12_COST_DESC',
  DealsMinFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_13NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_13_NAME_ASC',
  DealsMinFeeAftermarket_13NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_13_NAME_DESC',
  DealsMinFeeAftermarket_13SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_13_SALE_ASC',
  DealsMinFeeAftermarket_13SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_13_SALE_DESC',
  DealsMinFeeAftermarket_13CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_13_COST_ASC',
  DealsMinFeeAftermarket_13CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_13_COST_DESC',
  DealsMinFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_14NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_14_NAME_ASC',
  DealsMinFeeAftermarket_14NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_14_NAME_DESC',
  DealsMinFeeAftermarket_14SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_14_SALE_ASC',
  DealsMinFeeAftermarket_14SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_14_SALE_DESC',
  DealsMinFeeAftermarket_14CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_14_COST_ASC',
  DealsMinFeeAftermarket_14CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_14_COST_DESC',
  DealsMinFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_15NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_15_NAME_ASC',
  DealsMinFeeAftermarket_15NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_15_NAME_DESC',
  DealsMinFeeAftermarket_15SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_15_SALE_ASC',
  DealsMinFeeAftermarket_15SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_15_SALE_DESC',
  DealsMinFeeAftermarket_15CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_15_COST_ASC',
  DealsMinFeeAftermarket_15CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_15_COST_DESC',
  DealsMinFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_16NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_16_NAME_ASC',
  DealsMinFeeAftermarket_16NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_16_NAME_DESC',
  DealsMinFeeAftermarket_16SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_16_SALE_ASC',
  DealsMinFeeAftermarket_16SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_16_SALE_DESC',
  DealsMinFeeAftermarket_16CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_16_COST_ASC',
  DealsMinFeeAftermarket_16CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_16_COST_DESC',
  DealsMinFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_17NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_17_NAME_ASC',
  DealsMinFeeAftermarket_17NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_17_NAME_DESC',
  DealsMinFeeAftermarket_17SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_17_SALE_ASC',
  DealsMinFeeAftermarket_17SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_17_SALE_DESC',
  DealsMinFeeAftermarket_17CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_17_COST_ASC',
  DealsMinFeeAftermarket_17CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_17_COST_DESC',
  DealsMinFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_18NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_18_NAME_ASC',
  DealsMinFeeAftermarket_18NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_18_NAME_DESC',
  DealsMinFeeAftermarket_18SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_18_SALE_ASC',
  DealsMinFeeAftermarket_18SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_18_SALE_DESC',
  DealsMinFeeAftermarket_18CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_18_COST_ASC',
  DealsMinFeeAftermarket_18CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_18_COST_DESC',
  DealsMinFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_19NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_19_NAME_ASC',
  DealsMinFeeAftermarket_19NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_19_NAME_DESC',
  DealsMinFeeAftermarket_19SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_19_SALE_ASC',
  DealsMinFeeAftermarket_19SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_19_SALE_DESC',
  DealsMinFeeAftermarket_19CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_19_COST_ASC',
  DealsMinFeeAftermarket_19CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_19_COST_DESC',
  DealsMinFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsMinFeeAftermarket_20NameAsc = 'DEALS_MIN_FEE_AFTERMARKET_20_NAME_ASC',
  DealsMinFeeAftermarket_20NameDesc = 'DEALS_MIN_FEE_AFTERMARKET_20_NAME_DESC',
  DealsMinFeeAftermarket_20SaleAsc = 'DEALS_MIN_FEE_AFTERMARKET_20_SALE_ASC',
  DealsMinFeeAftermarket_20SaleDesc = 'DEALS_MIN_FEE_AFTERMARKET_20_SALE_DESC',
  DealsMinFeeAftermarket_20CostAsc = 'DEALS_MIN_FEE_AFTERMARKET_20_COST_ASC',
  DealsMinFeeAftermarket_20CostDesc = 'DEALS_MIN_FEE_AFTERMARKET_20_COST_DESC',
  DealsMinFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_MIN_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsMinFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_MIN_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsMinInsurance_1TypeAsc = 'DEALS_MIN_INSURANCE_1_TYPE_ASC',
  DealsMinInsurance_1TypeDesc = 'DEALS_MIN_INSURANCE_1_TYPE_DESC',
  DealsMinInsurance_1NameAsc = 'DEALS_MIN_INSURANCE_1_NAME_ASC',
  DealsMinInsurance_1NameDesc = 'DEALS_MIN_INSURANCE_1_NAME_DESC',
  DealsMinInsurance_1SaleAsc = 'DEALS_MIN_INSURANCE_1_SALE_ASC',
  DealsMinInsurance_1SaleDesc = 'DEALS_MIN_INSURANCE_1_SALE_DESC',
  DealsMinInsurance_1CostAsc = 'DEALS_MIN_INSURANCE_1_COST_ASC',
  DealsMinInsurance_1CostDesc = 'DEALS_MIN_INSURANCE_1_COST_DESC',
  DealsMinInsurance_1TermAsc = 'DEALS_MIN_INSURANCE_1_TERM_ASC',
  DealsMinInsurance_1TermDesc = 'DEALS_MIN_INSURANCE_1_TERM_DESC',
  DealsMinInsurance_2TypeAsc = 'DEALS_MIN_INSURANCE_2_TYPE_ASC',
  DealsMinInsurance_2TypeDesc = 'DEALS_MIN_INSURANCE_2_TYPE_DESC',
  DealsMinInsurance_2NameAsc = 'DEALS_MIN_INSURANCE_2_NAME_ASC',
  DealsMinInsurance_2NameDesc = 'DEALS_MIN_INSURANCE_2_NAME_DESC',
  DealsMinInsurance_2SaleAsc = 'DEALS_MIN_INSURANCE_2_SALE_ASC',
  DealsMinInsurance_2SaleDesc = 'DEALS_MIN_INSURANCE_2_SALE_DESC',
  DealsMinInsurance_2CostAsc = 'DEALS_MIN_INSURANCE_2_COST_ASC',
  DealsMinInsurance_2CostDesc = 'DEALS_MIN_INSURANCE_2_COST_DESC',
  DealsMinInsurance_2TermAsc = 'DEALS_MIN_INSURANCE_2_TERM_ASC',
  DealsMinInsurance_2TermDesc = 'DEALS_MIN_INSURANCE_2_TERM_DESC',
  DealsMinInsurance_3TypeAsc = 'DEALS_MIN_INSURANCE_3_TYPE_ASC',
  DealsMinInsurance_3TypeDesc = 'DEALS_MIN_INSURANCE_3_TYPE_DESC',
  DealsMinInsurance_3NameAsc = 'DEALS_MIN_INSURANCE_3_NAME_ASC',
  DealsMinInsurance_3NameDesc = 'DEALS_MIN_INSURANCE_3_NAME_DESC',
  DealsMinInsurance_3SaleAsc = 'DEALS_MIN_INSURANCE_3_SALE_ASC',
  DealsMinInsurance_3SaleDesc = 'DEALS_MIN_INSURANCE_3_SALE_DESC',
  DealsMinInsurance_3CostAsc = 'DEALS_MIN_INSURANCE_3_COST_ASC',
  DealsMinInsurance_3CostDesc = 'DEALS_MIN_INSURANCE_3_COST_DESC',
  DealsMinInsurance_3TermAsc = 'DEALS_MIN_INSURANCE_3_TERM_ASC',
  DealsMinInsurance_3TermDesc = 'DEALS_MIN_INSURANCE_3_TERM_DESC',
  DealsMinAccidentalHealthTypeAsc = 'DEALS_MIN_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsMinAccidentalHealthTypeDesc = 'DEALS_MIN_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsMinAccidentalHealthNameAsc = 'DEALS_MIN_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsMinAccidentalHealthNameDesc = 'DEALS_MIN_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsMinAccidentalHealthSaleAsc = 'DEALS_MIN_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsMinAccidentalHealthSaleDesc = 'DEALS_MIN_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsMinAccidentalHealthCostAsc = 'DEALS_MIN_ACCIDENTAL_HEALTH_COST_ASC',
  DealsMinAccidentalHealthCostDesc = 'DEALS_MIN_ACCIDENTAL_HEALTH_COST_DESC',
  DealsMinAccidentalHealthTermAsc = 'DEALS_MIN_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsMinAccidentalHealthTermDesc = 'DEALS_MIN_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsMinCreditLifeTypeAsc = 'DEALS_MIN_CREDIT_LIFE_TYPE_ASC',
  DealsMinCreditLifeTypeDesc = 'DEALS_MIN_CREDIT_LIFE_TYPE_DESC',
  DealsMinCreditLifeNameAsc = 'DEALS_MIN_CREDIT_LIFE_NAME_ASC',
  DealsMinCreditLifeNameDesc = 'DEALS_MIN_CREDIT_LIFE_NAME_DESC',
  DealsMinCreditLifeSaleAsc = 'DEALS_MIN_CREDIT_LIFE_SALE_ASC',
  DealsMinCreditLifeSaleDesc = 'DEALS_MIN_CREDIT_LIFE_SALE_DESC',
  DealsMinCreditLifeCostAsc = 'DEALS_MIN_CREDIT_LIFE_COST_ASC',
  DealsMinCreditLifeCostDesc = 'DEALS_MIN_CREDIT_LIFE_COST_DESC',
  DealsMinCreditLifeTermAsc = 'DEALS_MIN_CREDIT_LIFE_TERM_ASC',
  DealsMinCreditLifeTermDesc = 'DEALS_MIN_CREDIT_LIFE_TERM_DESC',
  DealsMinLevelizedLifeTypeAsc = 'DEALS_MIN_LEVELIZED_LIFE_TYPE_ASC',
  DealsMinLevelizedLifeTypeDesc = 'DEALS_MIN_LEVELIZED_LIFE_TYPE_DESC',
  DealsMinLevelizedLifeNameAsc = 'DEALS_MIN_LEVELIZED_LIFE_NAME_ASC',
  DealsMinLevelizedLifeNameDesc = 'DEALS_MIN_LEVELIZED_LIFE_NAME_DESC',
  DealsMinLevelizedLifeSaleAsc = 'DEALS_MIN_LEVELIZED_LIFE_SALE_ASC',
  DealsMinLevelizedLifeSaleDesc = 'DEALS_MIN_LEVELIZED_LIFE_SALE_DESC',
  DealsMinLevelizedLifeCostAsc = 'DEALS_MIN_LEVELIZED_LIFE_COST_ASC',
  DealsMinLevelizedLifeCostDesc = 'DEALS_MIN_LEVELIZED_LIFE_COST_DESC',
  DealsMinLevelizedLifeTermAsc = 'DEALS_MIN_LEVELIZED_LIFE_TERM_ASC',
  DealsMinLevelizedLifeTermDesc = 'DEALS_MIN_LEVELIZED_LIFE_TERM_DESC',
  DealsMinLossOfEmploymentTypeAsc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsMinLossOfEmploymentTypeDesc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsMinLossOfEmploymentNameAsc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsMinLossOfEmploymentNameDesc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsMinLossOfEmploymentSaleAsc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsMinLossOfEmploymentSaleDesc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsMinLossOfEmploymentCostAsc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsMinLossOfEmploymentCostDesc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsMinLossOfEmploymentTermAsc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsMinLossOfEmploymentTermDesc = 'DEALS_MIN_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsMinGuaranteedAutoProtectionTypeAsc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsMinGuaranteedAutoProtectionTypeDesc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsMinGuaranteedAutoProtectionNameAsc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsMinGuaranteedAutoProtectionNameDesc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsMinGuaranteedAutoProtectionSaleAsc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsMinGuaranteedAutoProtectionSaleDesc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsMinGuaranteedAutoProtectionCostAsc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsMinGuaranteedAutoProtectionCostDesc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsMinGuaranteedAutoProtectionTermAsc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsMinGuaranteedAutoProtectionTermDesc = 'DEALS_MIN_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsMinSaleCommentsAsc = 'DEALS_MIN_SALE_COMMENTS_ASC',
  DealsMinSaleCommentsDesc = 'DEALS_MIN_SALE_COMMENTS_DESC',
  DealsMinCassStdLine1Asc = 'DEALS_MIN_CASS_STD_LINE1_ASC',
  DealsMinCassStdLine1Desc = 'DEALS_MIN_CASS_STD_LINE1_DESC',
  DealsMinCassStdLine2Asc = 'DEALS_MIN_CASS_STD_LINE2_ASC',
  DealsMinCassStdLine2Desc = 'DEALS_MIN_CASS_STD_LINE2_DESC',
  DealsMinCassStdCityAsc = 'DEALS_MIN_CASS_STD_CITY_ASC',
  DealsMinCassStdCityDesc = 'DEALS_MIN_CASS_STD_CITY_DESC',
  DealsMinCassStdStateAsc = 'DEALS_MIN_CASS_STD_STATE_ASC',
  DealsMinCassStdStateDesc = 'DEALS_MIN_CASS_STD_STATE_DESC',
  DealsMinCassStdZipAsc = 'DEALS_MIN_CASS_STD_ZIP_ASC',
  DealsMinCassStdZipDesc = 'DEALS_MIN_CASS_STD_ZIP_DESC',
  DealsMinCassStdZip4Asc = 'DEALS_MIN_CASS_STD_ZIP4_ASC',
  DealsMinCassStdZip4Desc = 'DEALS_MIN_CASS_STD_ZIP4_DESC',
  DealsMinCassStdDpbcAsc = 'DEALS_MIN_CASS_STD_DPBC_ASC',
  DealsMinCassStdDpbcDesc = 'DEALS_MIN_CASS_STD_DPBC_DESC',
  DealsMinCassStdChkdgtAsc = 'DEALS_MIN_CASS_STD_CHKDGT_ASC',
  DealsMinCassStdChkdgtDesc = 'DEALS_MIN_CASS_STD_CHKDGT_DESC',
  DealsMinCassStdCartAsc = 'DEALS_MIN_CASS_STD_CART_ASC',
  DealsMinCassStdCartDesc = 'DEALS_MIN_CASS_STD_CART_DESC',
  DealsMinCassStdLotAsc = 'DEALS_MIN_CASS_STD_LOT_ASC',
  DealsMinCassStdLotDesc = 'DEALS_MIN_CASS_STD_LOT_DESC',
  DealsMinCassStdLotordAsc = 'DEALS_MIN_CASS_STD_LOTORD_ASC',
  DealsMinCassStdLotordDesc = 'DEALS_MIN_CASS_STD_LOTORD_DESC',
  DealsMinCassStdUrbAsc = 'DEALS_MIN_CASS_STD_URB_ASC',
  DealsMinCassStdUrbDesc = 'DEALS_MIN_CASS_STD_URB_DESC',
  DealsMinCassStdFipsAsc = 'DEALS_MIN_CASS_STD_FIPS_ASC',
  DealsMinCassStdFipsDesc = 'DEALS_MIN_CASS_STD_FIPS_DESC',
  DealsMinCassStdEwsAsc = 'DEALS_MIN_CASS_STD_EWS_ASC',
  DealsMinCassStdEwsDesc = 'DEALS_MIN_CASS_STD_EWS_DESC',
  DealsMinCassStdLacsAsc = 'DEALS_MIN_CASS_STD_LACS_ASC',
  DealsMinCassStdLacsDesc = 'DEALS_MIN_CASS_STD_LACS_DESC',
  DealsMinCassStdZipmovAsc = 'DEALS_MIN_CASS_STD_ZIPMOV_ASC',
  DealsMinCassStdZipmovDesc = 'DEALS_MIN_CASS_STD_ZIPMOV_DESC',
  DealsMinCassStdZ4LomAsc = 'DEALS_MIN_CASS_STD_Z4LOM_ASC',
  DealsMinCassStdZ4LomDesc = 'DEALS_MIN_CASS_STD_Z4LOM_DESC',
  DealsMinCassStdNdiaptAsc = 'DEALS_MIN_CASS_STD_NDIAPT_ASC',
  DealsMinCassStdNdiaptDesc = 'DEALS_MIN_CASS_STD_NDIAPT_DESC',
  DealsMinCassStdNdirrAsc = 'DEALS_MIN_CASS_STD_NDIRR_ASC',
  DealsMinCassStdNdirrDesc = 'DEALS_MIN_CASS_STD_NDIRR_DESC',
  DealsMinCassStdLacsrtAsc = 'DEALS_MIN_CASS_STD_LACSRT_ASC',
  DealsMinCassStdLacsrtDesc = 'DEALS_MIN_CASS_STD_LACSRT_DESC',
  DealsMinCassStdErrorCdAsc = 'DEALS_MIN_CASS_STD_ERROR_CD_ASC',
  DealsMinCassStdErrorCdDesc = 'DEALS_MIN_CASS_STD_ERROR_CD_DESC',
  DealsMaxIdAsc = 'DEALS_MAX_ID_ASC',
  DealsMaxIdDesc = 'DEALS_MAX_ID_DESC',
  DealsMaxLeadIdAsc = 'DEALS_MAX_LEAD_ID_ASC',
  DealsMaxLeadIdDesc = 'DEALS_MAX_LEAD_ID_DESC',
  DealsMaxCompanyIntegrationIdAsc = 'DEALS_MAX_COMPANY_INTEGRATION_ID_ASC',
  DealsMaxCompanyIntegrationIdDesc = 'DEALS_MAX_COMPANY_INTEGRATION_ID_DESC',
  DealsMaxFileTypeAsc = 'DEALS_MAX_FILE_TYPE_ASC',
  DealsMaxFileTypeDesc = 'DEALS_MAX_FILE_TYPE_DESC',
  DealsMaxDvDealerIdAsc = 'DEALS_MAX_DV_DEALER_ID_ASC',
  DealsMaxDvDealerIdDesc = 'DEALS_MAX_DV_DEALER_ID_DESC',
  DealsMaxVendorDealerIdAsc = 'DEALS_MAX_VENDOR_DEALER_ID_ASC',
  DealsMaxVendorDealerIdDesc = 'DEALS_MAX_VENDOR_DEALER_ID_DESC',
  DealsMaxDmsTypeAsc = 'DEALS_MAX_DMS_TYPE_ASC',
  DealsMaxDmsTypeDesc = 'DEALS_MAX_DMS_TYPE_DESC',
  DealsMaxDealNumberAsc = 'DEALS_MAX_DEAL_NUMBER_ASC',
  DealsMaxDealNumberDesc = 'DEALS_MAX_DEAL_NUMBER_DESC',
  DealsMaxCustomerNumberAsc = 'DEALS_MAX_CUSTOMER_NUMBER_ASC',
  DealsMaxCustomerNumberDesc = 'DEALS_MAX_CUSTOMER_NUMBER_DESC',
  DealsMaxFullNameAsc = 'DEALS_MAX_FULL_NAME_ASC',
  DealsMaxFullNameDesc = 'DEALS_MAX_FULL_NAME_DESC',
  DealsMaxSalutationAsc = 'DEALS_MAX_SALUTATION_ASC',
  DealsMaxSalutationDesc = 'DEALS_MAX_SALUTATION_DESC',
  DealsMaxFirstNameAsc = 'DEALS_MAX_FIRST_NAME_ASC',
  DealsMaxFirstNameDesc = 'DEALS_MAX_FIRST_NAME_DESC',
  DealsMaxMiddleNameAsc = 'DEALS_MAX_MIDDLE_NAME_ASC',
  DealsMaxMiddleNameDesc = 'DEALS_MAX_MIDDLE_NAME_DESC',
  DealsMaxLastNameAsc = 'DEALS_MAX_LAST_NAME_ASC',
  DealsMaxLastNameDesc = 'DEALS_MAX_LAST_NAME_DESC',
  DealsMaxSuffixAsc = 'DEALS_MAX_SUFFIX_ASC',
  DealsMaxSuffixDesc = 'DEALS_MAX_SUFFIX_DESC',
  DealsMaxAddressLine_1Asc = 'DEALS_MAX_ADDRESS_LINE_1_ASC',
  DealsMaxAddressLine_1Desc = 'DEALS_MAX_ADDRESS_LINE_1_DESC',
  DealsMaxAddressLine_2Asc = 'DEALS_MAX_ADDRESS_LINE_2_ASC',
  DealsMaxAddressLine_2Desc = 'DEALS_MAX_ADDRESS_LINE_2_DESC',
  DealsMaxCityAsc = 'DEALS_MAX_CITY_ASC',
  DealsMaxCityDesc = 'DEALS_MAX_CITY_DESC',
  DealsMaxStateAsc = 'DEALS_MAX_STATE_ASC',
  DealsMaxStateDesc = 'DEALS_MAX_STATE_DESC',
  DealsMaxZipAsc = 'DEALS_MAX_ZIP_ASC',
  DealsMaxZipDesc = 'DEALS_MAX_ZIP_DESC',
  DealsMaxCountyAsc = 'DEALS_MAX_COUNTY_ASC',
  DealsMaxCountyDesc = 'DEALS_MAX_COUNTY_DESC',
  DealsMaxHomePhoneAsc = 'DEALS_MAX_HOME_PHONE_ASC',
  DealsMaxHomePhoneDesc = 'DEALS_MAX_HOME_PHONE_DESC',
  DealsMaxCellPhoneAsc = 'DEALS_MAX_CELL_PHONE_ASC',
  DealsMaxCellPhoneDesc = 'DEALS_MAX_CELL_PHONE_DESC',
  DealsMaxWorkPhoneAsc = 'DEALS_MAX_WORK_PHONE_ASC',
  DealsMaxWorkPhoneDesc = 'DEALS_MAX_WORK_PHONE_DESC',
  DealsMaxWorkExtensionAsc = 'DEALS_MAX_WORK_EXTENSION_ASC',
  DealsMaxWorkExtensionDesc = 'DEALS_MAX_WORK_EXTENSION_DESC',
  DealsMaxEmail_1Asc = 'DEALS_MAX_EMAIL_1_ASC',
  DealsMaxEmail_1Desc = 'DEALS_MAX_EMAIL_1_DESC',
  DealsMaxEmail_2Asc = 'DEALS_MAX_EMAIL_2_ASC',
  DealsMaxEmail_2Desc = 'DEALS_MAX_EMAIL_2_DESC',
  DealsMaxEmail_3Asc = 'DEALS_MAX_EMAIL_3_ASC',
  DealsMaxEmail_3Desc = 'DEALS_MAX_EMAIL_3_DESC',
  DealsMaxBirthDateAsc = 'DEALS_MAX_BIRTH_DATE_ASC',
  DealsMaxBirthDateDesc = 'DEALS_MAX_BIRTH_DATE_DESC',
  DealsMaxIndividualBusinessFlagAsc = 'DEALS_MAX_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsMaxIndividualBusinessFlagDesc = 'DEALS_MAX_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsMaxOptOutAsc = 'DEALS_MAX_OPT_OUT_ASC',
  DealsMaxOptOutDesc = 'DEALS_MAX_OPT_OUT_DESC',
  DealsMaxBlockEmailAsc = 'DEALS_MAX_BLOCK_EMAIL_ASC',
  DealsMaxBlockEmailDesc = 'DEALS_MAX_BLOCK_EMAIL_DESC',
  DealsMaxBlockPhoneAsc = 'DEALS_MAX_BLOCK_PHONE_ASC',
  DealsMaxBlockPhoneDesc = 'DEALS_MAX_BLOCK_PHONE_DESC',
  DealsMaxBlockMailAsc = 'DEALS_MAX_BLOCK_MAIL_ASC',
  DealsMaxBlockMailDesc = 'DEALS_MAX_BLOCK_MAIL_DESC',
  DealsMaxLanguageAsc = 'DEALS_MAX_LANGUAGE_ASC',
  DealsMaxLanguageDesc = 'DEALS_MAX_LANGUAGE_DESC',
  DealsMaxCustomerCreateDateAsc = 'DEALS_MAX_CUSTOMER_CREATE_DATE_ASC',
  DealsMaxCustomerCreateDateDesc = 'DEALS_MAX_CUSTOMER_CREATE_DATE_DESC',
  DealsMaxCustomerLastActivityDateAsc = 'DEALS_MAX_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsMaxCustomerLastActivityDateDesc = 'DEALS_MAX_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsMaxCoBuyerCustomerNumberAsc = 'DEALS_MAX_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsMaxCoBuyerCustomerNumberDesc = 'DEALS_MAX_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsMaxCoBuyerFullNameAsc = 'DEALS_MAX_CO_BUYER_FULL_NAME_ASC',
  DealsMaxCoBuyerFullNameDesc = 'DEALS_MAX_CO_BUYER_FULL_NAME_DESC',
  DealsMaxCoBuyerSalutationAsc = 'DEALS_MAX_CO_BUYER_SALUTATION_ASC',
  DealsMaxCoBuyerSalutationDesc = 'DEALS_MAX_CO_BUYER_SALUTATION_DESC',
  DealsMaxCoBuyerFirstNameAsc = 'DEALS_MAX_CO_BUYER_FIRST_NAME_ASC',
  DealsMaxCoBuyerFirstNameDesc = 'DEALS_MAX_CO_BUYER_FIRST_NAME_DESC',
  DealsMaxCoBuyerMiddleNameAsc = 'DEALS_MAX_CO_BUYER_MIDDLE_NAME_ASC',
  DealsMaxCoBuyerMiddleNameDesc = 'DEALS_MAX_CO_BUYER_MIDDLE_NAME_DESC',
  DealsMaxCoBuyerLastNameAsc = 'DEALS_MAX_CO_BUYER_LAST_NAME_ASC',
  DealsMaxCoBuyerLastNameDesc = 'DEALS_MAX_CO_BUYER_LAST_NAME_DESC',
  DealsMaxCoBuyerSuffixAsc = 'DEALS_MAX_CO_BUYER_SUFFIX_ASC',
  DealsMaxCoBuyerSuffixDesc = 'DEALS_MAX_CO_BUYER_SUFFIX_DESC',
  DealsMaxCoBuyerAddressLine_1Asc = 'DEALS_MAX_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsMaxCoBuyerAddressLine_1Desc = 'DEALS_MAX_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsMaxCoBuyerAddressLine_2Asc = 'DEALS_MAX_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsMaxCoBuyerAddressLine_2Desc = 'DEALS_MAX_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsMaxCoBuyerCityAsc = 'DEALS_MAX_CO_BUYER_CITY_ASC',
  DealsMaxCoBuyerCityDesc = 'DEALS_MAX_CO_BUYER_CITY_DESC',
  DealsMaxCoBuyerStateAsc = 'DEALS_MAX_CO_BUYER_STATE_ASC',
  DealsMaxCoBuyerStateDesc = 'DEALS_MAX_CO_BUYER_STATE_DESC',
  DealsMaxCoBuyerZipAsc = 'DEALS_MAX_CO_BUYER_ZIP_ASC',
  DealsMaxCoBuyerZipDesc = 'DEALS_MAX_CO_BUYER_ZIP_DESC',
  DealsMaxCoBuyerCountyAsc = 'DEALS_MAX_CO_BUYER_COUNTY_ASC',
  DealsMaxCoBuyerCountyDesc = 'DEALS_MAX_CO_BUYER_COUNTY_DESC',
  DealsMaxCoBuyerHomePhoneAsc = 'DEALS_MAX_CO_BUYER_HOME_PHONE_ASC',
  DealsMaxCoBuyerHomePhoneDesc = 'DEALS_MAX_CO_BUYER_HOME_PHONE_DESC',
  DealsMaxCoBuyerCellPhoneAsc = 'DEALS_MAX_CO_BUYER_CELL_PHONE_ASC',
  DealsMaxCoBuyerCellPhoneDesc = 'DEALS_MAX_CO_BUYER_CELL_PHONE_DESC',
  DealsMaxCoBuyerWorkPhoneAsc = 'DEALS_MAX_CO_BUYER_WORK_PHONE_ASC',
  DealsMaxCoBuyerWorkPhoneDesc = 'DEALS_MAX_CO_BUYER_WORK_PHONE_DESC',
  DealsMaxCoBuyerWorkExtensionAsc = 'DEALS_MAX_CO_BUYER_WORK_EXTENSION_ASC',
  DealsMaxCoBuyerWorkExtensionDesc = 'DEALS_MAX_CO_BUYER_WORK_EXTENSION_DESC',
  DealsMaxCoBuyerEmail_1Asc = 'DEALS_MAX_CO_BUYER_EMAIL_1_ASC',
  DealsMaxCoBuyerEmail_1Desc = 'DEALS_MAX_CO_BUYER_EMAIL_1_DESC',
  DealsMaxCoBuyerEmail_2Asc = 'DEALS_MAX_CO_BUYER_EMAIL_2_ASC',
  DealsMaxCoBuyerEmail_2Desc = 'DEALS_MAX_CO_BUYER_EMAIL_2_DESC',
  DealsMaxCoBuyerEmail_3Asc = 'DEALS_MAX_CO_BUYER_EMAIL_3_ASC',
  DealsMaxCoBuyerEmail_3Desc = 'DEALS_MAX_CO_BUYER_EMAIL_3_DESC',
  DealsMaxCoBuyerBirthDateAsc = 'DEALS_MAX_CO_BUYER_BIRTH_DATE_ASC',
  DealsMaxCoBuyerBirthDateDesc = 'DEALS_MAX_CO_BUYER_BIRTH_DATE_DESC',
  DealsMaxCoBuyerIndividualBusinessFlagAsc = 'DEALS_MAX_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsMaxCoBuyerIndividualBusinessFlagDesc = 'DEALS_MAX_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsMaxCoBuyerOptOutAsc = 'DEALS_MAX_CO_BUYER_OPT_OUT_ASC',
  DealsMaxCoBuyerOptOutDesc = 'DEALS_MAX_CO_BUYER_OPT_OUT_DESC',
  DealsMaxCoBuyerBlockEmailAsc = 'DEALS_MAX_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsMaxCoBuyerBlockEmailDesc = 'DEALS_MAX_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsMaxCoBuyerBlockPhoneAsc = 'DEALS_MAX_CO_BUYER_BLOCK_PHONE_ASC',
  DealsMaxCoBuyerBlockPhoneDesc = 'DEALS_MAX_CO_BUYER_BLOCK_PHONE_DESC',
  DealsMaxCoBuyerBlockMailAsc = 'DEALS_MAX_CO_BUYER_BLOCK_MAIL_ASC',
  DealsMaxCoBuyerBlockMailDesc = 'DEALS_MAX_CO_BUYER_BLOCK_MAIL_DESC',
  DealsMaxVinAsc = 'DEALS_MAX_VIN_ASC',
  DealsMaxVinDesc = 'DEALS_MAX_VIN_DESC',
  DealsMaxYearAsc = 'DEALS_MAX_YEAR_ASC',
  DealsMaxYearDesc = 'DEALS_MAX_YEAR_DESC',
  DealsMaxMakeAsc = 'DEALS_MAX_MAKE_ASC',
  DealsMaxMakeDesc = 'DEALS_MAX_MAKE_DESC',
  DealsMaxModelAsc = 'DEALS_MAX_MODEL_ASC',
  DealsMaxModelDesc = 'DEALS_MAX_MODEL_DESC',
  DealsMaxModelNumberAsc = 'DEALS_MAX_MODEL_NUMBER_ASC',
  DealsMaxModelNumberDesc = 'DEALS_MAX_MODEL_NUMBER_DESC',
  DealsMaxMileageAsc = 'DEALS_MAX_MILEAGE_ASC',
  DealsMaxMileageDesc = 'DEALS_MAX_MILEAGE_DESC',
  DealsMaxDescriptionAsc = 'DEALS_MAX_DESCRIPTION_ASC',
  DealsMaxDescriptionDesc = 'DEALS_MAX_DESCRIPTION_DESC',
  DealsMaxExteriorColorAsc = 'DEALS_MAX_EXTERIOR_COLOR_ASC',
  DealsMaxExteriorColorDesc = 'DEALS_MAX_EXTERIOR_COLOR_DESC',
  DealsMaxNewUsedAsc = 'DEALS_MAX_NEW_USED_ASC',
  DealsMaxNewUsedDesc = 'DEALS_MAX_NEW_USED_DESC',
  DealsMaxStockNumberAsc = 'DEALS_MAX_STOCK_NUMBER_ASC',
  DealsMaxStockNumberDesc = 'DEALS_MAX_STOCK_NUMBER_DESC',
  DealsMaxTransmissionAsc = 'DEALS_MAX_TRANSMISSION_ASC',
  DealsMaxTransmissionDesc = 'DEALS_MAX_TRANSMISSION_DESC',
  DealsMaxEngineConfigurationAsc = 'DEALS_MAX_ENGINE_CONFIGURATION_ASC',
  DealsMaxEngineConfigurationDesc = 'DEALS_MAX_ENGINE_CONFIGURATION_DESC',
  DealsMaxTrimAsc = 'DEALS_MAX_TRIM_ASC',
  DealsMaxTrimDesc = 'DEALS_MAX_TRIM_DESC',
  DealsMaxEngineNumberAsc = 'DEALS_MAX_ENGINE_NUMBER_ASC',
  DealsMaxEngineNumberDesc = 'DEALS_MAX_ENGINE_NUMBER_DESC',
  DealsMaxChassisNumberAsc = 'DEALS_MAX_CHASSIS_NUMBER_ASC',
  DealsMaxChassisNumberDesc = 'DEALS_MAX_CHASSIS_NUMBER_DESC',
  DealsMaxLicensePlateNumberAsc = 'DEALS_MAX_LICENSE_PLATE_NUMBER_ASC',
  DealsMaxLicensePlateNumberDesc = 'DEALS_MAX_LICENSE_PLATE_NUMBER_DESC',
  DealsMaxDeliveryDateAsc = 'DEALS_MAX_DELIVERY_DATE_ASC',
  DealsMaxDeliveryDateDesc = 'DEALS_MAX_DELIVERY_DATE_DESC',
  DealsMaxDeliveryMileageAsc = 'DEALS_MAX_DELIVERY_MILEAGE_ASC',
  DealsMaxDeliveryMileageDesc = 'DEALS_MAX_DELIVERY_MILEAGE_DESC',
  DealsMaxInventoryDateAsc = 'DEALS_MAX_INVENTORY_DATE_ASC',
  DealsMaxInventoryDateDesc = 'DEALS_MAX_INVENTORY_DATE_DESC',
  DealsMaxInServiceDateAsc = 'DEALS_MAX_IN_SERVICE_DATE_ASC',
  DealsMaxInServiceDateDesc = 'DEALS_MAX_IN_SERVICE_DATE_DESC',
  DealsMaxVinExplosionYearAsc = 'DEALS_MAX_VIN_EXPLOSION_YEAR_ASC',
  DealsMaxVinExplosionYearDesc = 'DEALS_MAX_VIN_EXPLOSION_YEAR_DESC',
  DealsMaxVinExplosionMakeAsc = 'DEALS_MAX_VIN_EXPLOSION_MAKE_ASC',
  DealsMaxVinExplosionMakeDesc = 'DEALS_MAX_VIN_EXPLOSION_MAKE_DESC',
  DealsMaxVinExplosionModelAsc = 'DEALS_MAX_VIN_EXPLOSION_MODEL_ASC',
  DealsMaxVinExplosionModelDesc = 'DEALS_MAX_VIN_EXPLOSION_MODEL_DESC',
  DealsMaxVinExplosionTrimAsc = 'DEALS_MAX_VIN_EXPLOSION_TRIM_ASC',
  DealsMaxVinExplosionTrimDesc = 'DEALS_MAX_VIN_EXPLOSION_TRIM_DESC',
  DealsMaxVinExplosionTransmissionTypeAsc = 'DEALS_MAX_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsMaxVinExplosionTransmissionTypeDesc = 'DEALS_MAX_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsMaxVinExplosionFuelTypeAsc = 'DEALS_MAX_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsMaxVinExplosionFuelTypeDesc = 'DEALS_MAX_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsMaxVinExplosionEngineSizeAsc = 'DEALS_MAX_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsMaxVinExplosionEngineSizeDesc = 'DEALS_MAX_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsMaxVinExplosionGvwRangeAsc = 'DEALS_MAX_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsMaxVinExplosionGvwRangeDesc = 'DEALS_MAX_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsMaxTrade_1VinAsc = 'DEALS_MAX_TRADE_1_VIN_ASC',
  DealsMaxTrade_1VinDesc = 'DEALS_MAX_TRADE_1_VIN_DESC',
  DealsMaxTrade_1YearAsc = 'DEALS_MAX_TRADE_1_YEAR_ASC',
  DealsMaxTrade_1YearDesc = 'DEALS_MAX_TRADE_1_YEAR_DESC',
  DealsMaxTrade_1MakeAsc = 'DEALS_MAX_TRADE_1_MAKE_ASC',
  DealsMaxTrade_1MakeDesc = 'DEALS_MAX_TRADE_1_MAKE_DESC',
  DealsMaxTrade_1ModelAsc = 'DEALS_MAX_TRADE_1_MODEL_ASC',
  DealsMaxTrade_1ModelDesc = 'DEALS_MAX_TRADE_1_MODEL_DESC',
  DealsMaxTrade_1OdometerAsc = 'DEALS_MAX_TRADE_1_ODOMETER_ASC',
  DealsMaxTrade_1OdometerDesc = 'DEALS_MAX_TRADE_1_ODOMETER_DESC',
  DealsMaxTrade_1ActualCashValueAsc = 'DEALS_MAX_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsMaxTrade_1ActualCashValueDesc = 'DEALS_MAX_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsMaxTrade_1GrossAsc = 'DEALS_MAX_TRADE_1_GROSS_ASC',
  DealsMaxTrade_1GrossDesc = 'DEALS_MAX_TRADE_1_GROSS_DESC',
  DealsMaxTrade_1PayoffAsc = 'DEALS_MAX_TRADE_1_PAYOFF_ASC',
  DealsMaxTrade_1PayoffDesc = 'DEALS_MAX_TRADE_1_PAYOFF_DESC',
  DealsMaxTrade_2VinAsc = 'DEALS_MAX_TRADE_2_VIN_ASC',
  DealsMaxTrade_2VinDesc = 'DEALS_MAX_TRADE_2_VIN_DESC',
  DealsMaxTrade_2YearAsc = 'DEALS_MAX_TRADE_2_YEAR_ASC',
  DealsMaxTrade_2YearDesc = 'DEALS_MAX_TRADE_2_YEAR_DESC',
  DealsMaxTrade_2MakeAsc = 'DEALS_MAX_TRADE_2_MAKE_ASC',
  DealsMaxTrade_2MakeDesc = 'DEALS_MAX_TRADE_2_MAKE_DESC',
  DealsMaxTrade_2ModelAsc = 'DEALS_MAX_TRADE_2_MODEL_ASC',
  DealsMaxTrade_2ModelDesc = 'DEALS_MAX_TRADE_2_MODEL_DESC',
  DealsMaxTrade_2OdometerAsc = 'DEALS_MAX_TRADE_2_ODOMETER_ASC',
  DealsMaxTrade_2OdometerDesc = 'DEALS_MAX_TRADE_2_ODOMETER_DESC',
  DealsMaxTrade_2ActualCashValueAsc = 'DEALS_MAX_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsMaxTrade_2ActualCashValueDesc = 'DEALS_MAX_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsMaxTrade_2GrossAsc = 'DEALS_MAX_TRADE_2_GROSS_ASC',
  DealsMaxTrade_2GrossDesc = 'DEALS_MAX_TRADE_2_GROSS_DESC',
  DealsMaxTrade_2PayoffAsc = 'DEALS_MAX_TRADE_2_PAYOFF_ASC',
  DealsMaxTrade_2PayoffDesc = 'DEALS_MAX_TRADE_2_PAYOFF_DESC',
  DealsMaxSalesman_1NumberAsc = 'DEALS_MAX_SALESMAN_1_NUMBER_ASC',
  DealsMaxSalesman_1NumberDesc = 'DEALS_MAX_SALESMAN_1_NUMBER_DESC',
  DealsMaxSalesman_1NameAsc = 'DEALS_MAX_SALESMAN_1_NAME_ASC',
  DealsMaxSalesman_1NameDesc = 'DEALS_MAX_SALESMAN_1_NAME_DESC',
  DealsMaxSalesman_2NumberAsc = 'DEALS_MAX_SALESMAN_2_NUMBER_ASC',
  DealsMaxSalesman_2NumberDesc = 'DEALS_MAX_SALESMAN_2_NUMBER_DESC',
  DealsMaxSalesman_2NameAsc = 'DEALS_MAX_SALESMAN_2_NAME_ASC',
  DealsMaxSalesman_2NameDesc = 'DEALS_MAX_SALESMAN_2_NAME_DESC',
  DealsMaxSalesman_3NumberAsc = 'DEALS_MAX_SALESMAN_3_NUMBER_ASC',
  DealsMaxSalesman_3NumberDesc = 'DEALS_MAX_SALESMAN_3_NUMBER_DESC',
  DealsMaxSalesman_3NameAsc = 'DEALS_MAX_SALESMAN_3_NAME_ASC',
  DealsMaxSalesman_3NameDesc = 'DEALS_MAX_SALESMAN_3_NAME_DESC',
  DealsMaxClosingManagerNumberAsc = 'DEALS_MAX_CLOSING_MANAGER_NUMBER_ASC',
  DealsMaxClosingManagerNumberDesc = 'DEALS_MAX_CLOSING_MANAGER_NUMBER_DESC',
  DealsMaxClosingManagerNameAsc = 'DEALS_MAX_CLOSING_MANAGER_NAME_ASC',
  DealsMaxClosingManagerNameDesc = 'DEALS_MAX_CLOSING_MANAGER_NAME_DESC',
  DealsMaxFinanceManagerNumberAsc = 'DEALS_MAX_FINANCE_MANAGER_NUMBER_ASC',
  DealsMaxFinanceManagerNumberDesc = 'DEALS_MAX_FINANCE_MANAGER_NUMBER_DESC',
  DealsMaxFinanceManagerNameAsc = 'DEALS_MAX_FINANCE_MANAGER_NAME_ASC',
  DealsMaxFinanceManagerNameDesc = 'DEALS_MAX_FINANCE_MANAGER_NAME_DESC',
  DealsMaxSalesmanManagerNumberAsc = 'DEALS_MAX_SALESMAN_MANAGER_NUMBER_ASC',
  DealsMaxSalesmanManagerNumberDesc = 'DEALS_MAX_SALESMAN_MANAGER_NUMBER_DESC',
  DealsMaxSalesmanManagerNameAsc = 'DEALS_MAX_SALESMAN_MANAGER_NAME_ASC',
  DealsMaxSalesmanManagerNameDesc = 'DEALS_MAX_SALESMAN_MANAGER_NAME_DESC',
  DealsMaxMsrpAsc = 'DEALS_MAX_MSRP_ASC',
  DealsMaxMsrpDesc = 'DEALS_MAX_MSRP_DESC',
  DealsMaxListPriceAsc = 'DEALS_MAX_LIST_PRICE_ASC',
  DealsMaxListPriceDesc = 'DEALS_MAX_LIST_PRICE_DESC',
  DealsMaxSalesPriceAsc = 'DEALS_MAX_SALES_PRICE_ASC',
  DealsMaxSalesPriceDesc = 'DEALS_MAX_SALES_PRICE_DESC',
  DealsMaxJournalPriceAsc = 'DEALS_MAX_JOURNAL_PRICE_ASC',
  DealsMaxJournalPriceDesc = 'DEALS_MAX_JOURNAL_PRICE_DESC',
  DealsMaxCostAsc = 'DEALS_MAX_COST_ASC',
  DealsMaxCostDesc = 'DEALS_MAX_COST_DESC',
  DealsMaxJournalCostAsc = 'DEALS_MAX_JOURNAL_COST_ASC',
  DealsMaxJournalCostDesc = 'DEALS_MAX_JOURNAL_COST_DESC',
  DealsMaxAdjustmentsAsc = 'DEALS_MAX_ADJUSTMENTS_ASC',
  DealsMaxAdjustmentsDesc = 'DEALS_MAX_ADJUSTMENTS_DESC',
  DealsMaxAdjustedCostAsc = 'DEALS_MAX_ADJUSTED_COST_ASC',
  DealsMaxAdjustedCostDesc = 'DEALS_MAX_ADJUSTED_COST_DESC',
  DealsMaxIncentivesAsc = 'DEALS_MAX_INCENTIVES_ASC',
  DealsMaxIncentivesDesc = 'DEALS_MAX_INCENTIVES_DESC',
  DealsMaxPackAmountAsc = 'DEALS_MAX_PACK_AMOUNT_ASC',
  DealsMaxPackAmountDesc = 'DEALS_MAX_PACK_AMOUNT_DESC',
  DealsMaxSaleNetAsc = 'DEALS_MAX_SALE_NET_ASC',
  DealsMaxSaleNetDesc = 'DEALS_MAX_SALE_NET_DESC',
  DealsMaxTotalTradeActualCashValueAsc = 'DEALS_MAX_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsMaxTotalTradeActualCashValueDesc = 'DEALS_MAX_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsMaxTotalTradeGrossAsc = 'DEALS_MAX_TOTAL_TRADE_GROSS_ASC',
  DealsMaxTotalTradeGrossDesc = 'DEALS_MAX_TOTAL_TRADE_GROSS_DESC',
  DealsMaxWeOweFrontAsc = 'DEALS_MAX_WE_OWE_FRONT_ASC',
  DealsMaxWeOweFrontDesc = 'DEALS_MAX_WE_OWE_FRONT_DESC',
  DealsMaxTotalFrontFeesAftermarketProfitAsc = 'DEALS_MAX_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsMaxTotalFrontFeesAftermarketProfitDesc = 'DEALS_MAX_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsMaxTotalFrontCommissionAsc = 'DEALS_MAX_TOTAL_FRONT_COMMISSION_ASC',
  DealsMaxTotalFrontCommissionDesc = 'DEALS_MAX_TOTAL_FRONT_COMMISSION_DESC',
  DealsMaxTotalFrontSalesAsc = 'DEALS_MAX_TOTAL_FRONT_SALES_ASC',
  DealsMaxTotalFrontSalesDesc = 'DEALS_MAX_TOTAL_FRONT_SALES_DESC',
  DealsMaxTotalFrontCostAsc = 'DEALS_MAX_TOTAL_FRONT_COST_ASC',
  DealsMaxTotalFrontCostDesc = 'DEALS_MAX_TOTAL_FRONT_COST_DESC',
  DealsMaxFrontGrossAsc = 'DEALS_MAX_FRONT_GROSS_ASC',
  DealsMaxFrontGrossDesc = 'DEALS_MAX_FRONT_GROSS_DESC',
  DealsMaxFinanceProfitAsc = 'DEALS_MAX_FINANCE_PROFIT_ASC',
  DealsMaxFinanceProfitDesc = 'DEALS_MAX_FINANCE_PROFIT_DESC',
  DealsMaxTotalWarrantyProfitAsc = 'DEALS_MAX_TOTAL_WARRANTY_PROFIT_ASC',
  DealsMaxTotalWarrantyProfitDesc = 'DEALS_MAX_TOTAL_WARRANTY_PROFIT_DESC',
  DealsMaxWeOweBackAsc = 'DEALS_MAX_WE_OWE_BACK_ASC',
  DealsMaxWeOweBackDesc = 'DEALS_MAX_WE_OWE_BACK_DESC',
  DealsMaxInsuranceProfitAsc = 'DEALS_MAX_INSURANCE_PROFIT_ASC',
  DealsMaxInsuranceProfitDesc = 'DEALS_MAX_INSURANCE_PROFIT_DESC',
  DealsMaxTotalBackFeesAftermarketProfitAsc = 'DEALS_MAX_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsMaxTotalBackFeesAftermarketProfitDesc = 'DEALS_MAX_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsMaxFinanceReserveAsc = 'DEALS_MAX_FINANCE_RESERVE_ASC',
  DealsMaxFinanceReserveDesc = 'DEALS_MAX_FINANCE_RESERVE_DESC',
  DealsMaxTotalBackCommissionAsc = 'DEALS_MAX_TOTAL_BACK_COMMISSION_ASC',
  DealsMaxTotalBackCommissionDesc = 'DEALS_MAX_TOTAL_BACK_COMMISSION_DESC',
  DealsMaxTotalBackSalesAsc = 'DEALS_MAX_TOTAL_BACK_SALES_ASC',
  DealsMaxTotalBackSalesDesc = 'DEALS_MAX_TOTAL_BACK_SALES_DESC',
  DealsMaxTotalBackCostAsc = 'DEALS_MAX_TOTAL_BACK_COST_ASC',
  DealsMaxTotalBackCostDesc = 'DEALS_MAX_TOTAL_BACK_COST_DESC',
  DealsMaxBackGrossAsc = 'DEALS_MAX_BACK_GROSS_ASC',
  DealsMaxBackGrossDesc = 'DEALS_MAX_BACK_GROSS_DESC',
  DealsMaxTotalProfitAsc = 'DEALS_MAX_TOTAL_PROFIT_ASC',
  DealsMaxTotalProfitDesc = 'DEALS_MAX_TOTAL_PROFIT_DESC',
  DealsMaxGrossProfitAsc = 'DEALS_MAX_GROSS_PROFIT_ASC',
  DealsMaxGrossProfitDesc = 'DEALS_MAX_GROSS_PROFIT_DESC',
  DealsMaxGrossPayableAsc = 'DEALS_MAX_GROSS_PAYABLE_ASC',
  DealsMaxGrossPayableDesc = 'DEALS_MAX_GROSS_PAYABLE_DESC',
  DealsMaxDealStatusAsc = 'DEALS_MAX_DEAL_STATUS_ASC',
  DealsMaxDealStatusDesc = 'DEALS_MAX_DEAL_STATUS_DESC',
  DealsMaxEntryDateAsc = 'DEALS_MAX_ENTRY_DATE_ASC',
  DealsMaxEntryDateDesc = 'DEALS_MAX_ENTRY_DATE_DESC',
  DealsMaxBookedDateAsc = 'DEALS_MAX_BOOKED_DATE_ASC',
  DealsMaxBookedDateDesc = 'DEALS_MAX_BOOKED_DATE_DESC',
  DealsMaxFinalizedDateAsc = 'DEALS_MAX_FINALIZED_DATE_ASC',
  DealsMaxFinalizedDateDesc = 'DEALS_MAX_FINALIZED_DATE_DESC',
  DealsMaxContractDateAsc = 'DEALS_MAX_CONTRACT_DATE_ASC',
  DealsMaxContractDateDesc = 'DEALS_MAX_CONTRACT_DATE_DESC',
  DealsMaxAccountingDateAsc = 'DEALS_MAX_ACCOUNTING_DATE_ASC',
  DealsMaxAccountingDateDesc = 'DEALS_MAX_ACCOUNTING_DATE_DESC',
  DealsMaxStatusChangeDateAsc = 'DEALS_MAX_STATUS_CHANGE_DATE_ASC',
  DealsMaxStatusChangeDateDesc = 'DEALS_MAX_STATUS_CHANGE_DATE_DESC',
  DealsMaxFirstPayDateAsc = 'DEALS_MAX_FIRST_PAY_DATE_ASC',
  DealsMaxFirstPayDateDesc = 'DEALS_MAX_FIRST_PAY_DATE_DESC',
  DealsMaxDealTypeAsc = 'DEALS_MAX_DEAL_TYPE_ASC',
  DealsMaxDealTypeDesc = 'DEALS_MAX_DEAL_TYPE_DESC',
  DealsMaxSaleTypeAsc = 'DEALS_MAX_SALE_TYPE_ASC',
  DealsMaxSaleTypeDesc = 'DEALS_MAX_SALE_TYPE_DESC',
  DealsMaxBankIdAsc = 'DEALS_MAX_BANK_ID_ASC',
  DealsMaxBankIdDesc = 'DEALS_MAX_BANK_ID_DESC',
  DealsMaxBankNameAsc = 'DEALS_MAX_BANK_NAME_ASC',
  DealsMaxBankNameDesc = 'DEALS_MAX_BANK_NAME_DESC',
  DealsMaxBankAddressAsc = 'DEALS_MAX_BANK_ADDRESS_ASC',
  DealsMaxBankAddressDesc = 'DEALS_MAX_BANK_ADDRESS_DESC',
  DealsMaxTermAsc = 'DEALS_MAX_TERM_ASC',
  DealsMaxTermDesc = 'DEALS_MAX_TERM_DESC',
  DealsMaxAmountFinancedAsc = 'DEALS_MAX_AMOUNT_FINANCED_ASC',
  DealsMaxAmountFinancedDesc = 'DEALS_MAX_AMOUNT_FINANCED_DESC',
  DealsMaxAprAsc = 'DEALS_MAX_APR_ASC',
  DealsMaxAprDesc = 'DEALS_MAX_APR_DESC',
  DealsMaxMonthlyPaymentAsc = 'DEALS_MAX_MONTHLY_PAYMENT_ASC',
  DealsMaxMonthlyPaymentDesc = 'DEALS_MAX_MONTHLY_PAYMENT_DESC',
  DealsMaxPaymentTotalAsc = 'DEALS_MAX_PAYMENT_TOTAL_ASC',
  DealsMaxPaymentTotalDesc = 'DEALS_MAX_PAYMENT_TOTAL_DESC',
  DealsMaxRebatesAsc = 'DEALS_MAX_REBATES_ASC',
  DealsMaxRebatesDesc = 'DEALS_MAX_REBATES_DESC',
  DealsMaxDepositAsc = 'DEALS_MAX_DEPOSIT_ASC',
  DealsMaxDepositDesc = 'DEALS_MAX_DEPOSIT_DESC',
  DealsMaxDownPaymentAsc = 'DEALS_MAX_DOWN_PAYMENT_ASC',
  DealsMaxDownPaymentDesc = 'DEALS_MAX_DOWN_PAYMENT_DESC',
  DealsMaxTotalNetTradesAsc = 'DEALS_MAX_TOTAL_NET_TRADES_ASC',
  DealsMaxTotalNetTradesDesc = 'DEALS_MAX_TOTAL_NET_TRADES_DESC',
  DealsMaxTotalDownAsc = 'DEALS_MAX_TOTAL_DOWN_ASC',
  DealsMaxTotalDownDesc = 'DEALS_MAX_TOTAL_DOWN_DESC',
  DealsMaxBalloonAmountAsc = 'DEALS_MAX_BALLOON_AMOUNT_ASC',
  DealsMaxBalloonAmountDesc = 'DEALS_MAX_BALLOON_AMOUNT_DESC',
  DealsMaxAdjustedBalloonAmountAsc = 'DEALS_MAX_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsMaxAdjustedBalloonAmountDesc = 'DEALS_MAX_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsMaxHoldbackAmountAsc = 'DEALS_MAX_HOLDBACK_AMOUNT_ASC',
  DealsMaxHoldbackAmountDesc = 'DEALS_MAX_HOLDBACK_AMOUNT_DESC',
  DealsMaxTotalDriveOffAmountAsc = 'DEALS_MAX_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsMaxTotalDriveOffAmountDesc = 'DEALS_MAX_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsMaxLicenseFeeAsc = 'DEALS_MAX_LICENSE_FEE_ASC',
  DealsMaxLicenseFeeDesc = 'DEALS_MAX_LICENSE_FEE_DESC',
  DealsMaxRegistrationFeeAsc = 'DEALS_MAX_REGISTRATION_FEE_ASC',
  DealsMaxRegistrationFeeDesc = 'DEALS_MAX_REGISTRATION_FEE_DESC',
  DealsMaxDocumentationFeeAsc = 'DEALS_MAX_DOCUMENTATION_FEE_ASC',
  DealsMaxDocumentationFeeDesc = 'DEALS_MAX_DOCUMENTATION_FEE_DESC',
  DealsMaxFinanceChargeAsc = 'DEALS_MAX_FINANCE_CHARGE_ASC',
  DealsMaxFinanceChargeDesc = 'DEALS_MAX_FINANCE_CHARGE_DESC',
  DealsMaxTotalPickupPaymentsAsc = 'DEALS_MAX_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsMaxTotalPickupPaymentsDesc = 'DEALS_MAX_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsMaxSellRateAsc = 'DEALS_MAX_SELL_RATE_ASC',
  DealsMaxSellRateDesc = 'DEALS_MAX_SELL_RATE_DESC',
  DealsMaxBuyRateAsc = 'DEALS_MAX_BUY_RATE_ASC',
  DealsMaxBuyRateDesc = 'DEALS_MAX_BUY_RATE_DESC',
  DealsMaxResidualRateAsc = 'DEALS_MAX_RESIDUAL_RATE_ASC',
  DealsMaxResidualRateDesc = 'DEALS_MAX_RESIDUAL_RATE_DESC',
  DealsMaxResidualAmountAsc = 'DEALS_MAX_RESIDUAL_AMOUNT_ASC',
  DealsMaxResidualAmountDesc = 'DEALS_MAX_RESIDUAL_AMOUNT_DESC',
  DealsMaxAllowedMilesAsc = 'DEALS_MAX_ALLOWED_MILES_ASC',
  DealsMaxAllowedMilesDesc = 'DEALS_MAX_ALLOWED_MILES_DESC',
  DealsMaxEstimatedMilesAsc = 'DEALS_MAX_ESTIMATED_MILES_ASC',
  DealsMaxEstimatedMilesDesc = 'DEALS_MAX_ESTIMATED_MILES_DESC',
  DealsMaxMileageRateAsc = 'DEALS_MAX_MILEAGE_RATE_ASC',
  DealsMaxMileageRateDesc = 'DEALS_MAX_MILEAGE_RATE_DESC',
  DealsMaxAcquisitionFeeAsc = 'DEALS_MAX_ACQUISITION_FEE_ASC',
  DealsMaxAcquisitionFeeDesc = 'DEALS_MAX_ACQUISITION_FEE_DESC',
  DealsMaxBasePaymentAsc = 'DEALS_MAX_BASE_PAYMENT_ASC',
  DealsMaxBasePaymentDesc = 'DEALS_MAX_BASE_PAYMENT_DESC',
  DealsMaxSecurityDepositAsc = 'DEALS_MAX_SECURITY_DEPOSIT_ASC',
  DealsMaxSecurityDepositDesc = 'DEALS_MAX_SECURITY_DEPOSIT_DESC',
  DealsMaxTotalCapitalReductionAsc = 'DEALS_MAX_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsMaxTotalCapitalReductionDesc = 'DEALS_MAX_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsMaxNetCapitalCostAsc = 'DEALS_MAX_NET_CAPITAL_COST_ASC',
  DealsMaxNetCapitalCostDesc = 'DEALS_MAX_NET_CAPITAL_COST_DESC',
  DealsMaxLeaseDepreciationValueAsc = 'DEALS_MAX_LEASE_DEPRECIATION_VALUE_ASC',
  DealsMaxLeaseDepreciationValueDesc = 'DEALS_MAX_LEASE_DEPRECIATION_VALUE_DESC',
  DealsMaxDealerFeesAsc = 'DEALS_MAX_DEALER_FEES_ASC',
  DealsMaxDealerFeesDesc = 'DEALS_MAX_DEALER_FEES_DESC',
  DealsMaxGovernmentFeesAsc = 'DEALS_MAX_GOVERNMENT_FEES_ASC',
  DealsMaxGovernmentFeesDesc = 'DEALS_MAX_GOVERNMENT_FEES_DESC',
  DealsMaxTotalTaxAsc = 'DEALS_MAX_TOTAL_TAX_ASC',
  DealsMaxTotalTaxDesc = 'DEALS_MAX_TOTAL_TAX_DESC',
  DealsMaxRegistrationStateAsc = 'DEALS_MAX_REGISTRATION_STATE_ASC',
  DealsMaxRegistrationStateDesc = 'DEALS_MAX_REGISTRATION_STATE_DESC',
  DealsMaxReportOfSaleNumberAsc = 'DEALS_MAX_REPORT_OF_SALE_NUMBER_ASC',
  DealsMaxReportOfSaleNumberDesc = 'DEALS_MAX_REPORT_OF_SALE_NUMBER_DESC',
  DealsMaxSalesman_1TotalCommissionAsc = 'DEALS_MAX_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsMaxSalesman_1TotalCommissionDesc = 'DEALS_MAX_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsMaxSalesman_1FrontCommissionAsc = 'DEALS_MAX_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsMaxSalesman_1FrontCommissionDesc = 'DEALS_MAX_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsMaxSalesman_1BackCommissionAsc = 'DEALS_MAX_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsMaxSalesman_1BackCommissionDesc = 'DEALS_MAX_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsMaxSalesman_2TotalCommissionAsc = 'DEALS_MAX_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsMaxSalesman_2TotalCommissionDesc = 'DEALS_MAX_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsMaxSalesman_2FrontCommissionAsc = 'DEALS_MAX_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsMaxSalesman_2FrontCommissionDesc = 'DEALS_MAX_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsMaxSalesman_2BackCommissionAsc = 'DEALS_MAX_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsMaxSalesman_2BackCommissionDesc = 'DEALS_MAX_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsMaxSalesman_3TotalCommissionAsc = 'DEALS_MAX_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsMaxSalesman_3TotalCommissionDesc = 'DEALS_MAX_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsMaxSalesman_3FrontCommissionAsc = 'DEALS_MAX_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsMaxSalesman_3FrontCommissionDesc = 'DEALS_MAX_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsMaxSalesman_3BackCommissionAsc = 'DEALS_MAX_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsMaxSalesman_3BackCommissionDesc = 'DEALS_MAX_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsMaxWarranty_1NameAsc = 'DEALS_MAX_WARRANTY_1_NAME_ASC',
  DealsMaxWarranty_1NameDesc = 'DEALS_MAX_WARRANTY_1_NAME_DESC',
  DealsMaxWarranty_1SaleAsc = 'DEALS_MAX_WARRANTY_1_SALE_ASC',
  DealsMaxWarranty_1SaleDesc = 'DEALS_MAX_WARRANTY_1_SALE_DESC',
  DealsMaxWarranty_1CostAsc = 'DEALS_MAX_WARRANTY_1_COST_ASC',
  DealsMaxWarranty_1CostDesc = 'DEALS_MAX_WARRANTY_1_COST_DESC',
  DealsMaxWarranty_1MilesAsc = 'DEALS_MAX_WARRANTY_1_MILES_ASC',
  DealsMaxWarranty_1MilesDesc = 'DEALS_MAX_WARRANTY_1_MILES_DESC',
  DealsMaxWarranty_1TermAsc = 'DEALS_MAX_WARRANTY_1_TERM_ASC',
  DealsMaxWarranty_1TermDesc = 'DEALS_MAX_WARRANTY_1_TERM_DESC',
  DealsMaxWarranty_2NameAsc = 'DEALS_MAX_WARRANTY_2_NAME_ASC',
  DealsMaxWarranty_2NameDesc = 'DEALS_MAX_WARRANTY_2_NAME_DESC',
  DealsMaxWarranty_2SaleAsc = 'DEALS_MAX_WARRANTY_2_SALE_ASC',
  DealsMaxWarranty_2SaleDesc = 'DEALS_MAX_WARRANTY_2_SALE_DESC',
  DealsMaxWarranty_2CostAsc = 'DEALS_MAX_WARRANTY_2_COST_ASC',
  DealsMaxWarranty_2CostDesc = 'DEALS_MAX_WARRANTY_2_COST_DESC',
  DealsMaxWarranty_2MilesAsc = 'DEALS_MAX_WARRANTY_2_MILES_ASC',
  DealsMaxWarranty_2MilesDesc = 'DEALS_MAX_WARRANTY_2_MILES_DESC',
  DealsMaxWarranty_2TermAsc = 'DEALS_MAX_WARRANTY_2_TERM_ASC',
  DealsMaxWarranty_2TermDesc = 'DEALS_MAX_WARRANTY_2_TERM_DESC',
  DealsMaxWarranty_3NameAsc = 'DEALS_MAX_WARRANTY_3_NAME_ASC',
  DealsMaxWarranty_3NameDesc = 'DEALS_MAX_WARRANTY_3_NAME_DESC',
  DealsMaxWarranty_3SaleAsc = 'DEALS_MAX_WARRANTY_3_SALE_ASC',
  DealsMaxWarranty_3SaleDesc = 'DEALS_MAX_WARRANTY_3_SALE_DESC',
  DealsMaxWarranty_3CostAsc = 'DEALS_MAX_WARRANTY_3_COST_ASC',
  DealsMaxWarranty_3CostDesc = 'DEALS_MAX_WARRANTY_3_COST_DESC',
  DealsMaxWarranty_3MilesAsc = 'DEALS_MAX_WARRANTY_3_MILES_ASC',
  DealsMaxWarranty_3MilesDesc = 'DEALS_MAX_WARRANTY_3_MILES_DESC',
  DealsMaxWarranty_3TermAsc = 'DEALS_MAX_WARRANTY_3_TERM_ASC',
  DealsMaxWarranty_3TermDesc = 'DEALS_MAX_WARRANTY_3_TERM_DESC',
  DealsMaxWarranty_4NameAsc = 'DEALS_MAX_WARRANTY_4_NAME_ASC',
  DealsMaxWarranty_4NameDesc = 'DEALS_MAX_WARRANTY_4_NAME_DESC',
  DealsMaxWarranty_4SaleAsc = 'DEALS_MAX_WARRANTY_4_SALE_ASC',
  DealsMaxWarranty_4SaleDesc = 'DEALS_MAX_WARRANTY_4_SALE_DESC',
  DealsMaxWarranty_4CostAsc = 'DEALS_MAX_WARRANTY_4_COST_ASC',
  DealsMaxWarranty_4CostDesc = 'DEALS_MAX_WARRANTY_4_COST_DESC',
  DealsMaxWarranty_4MilesAsc = 'DEALS_MAX_WARRANTY_4_MILES_ASC',
  DealsMaxWarranty_4MilesDesc = 'DEALS_MAX_WARRANTY_4_MILES_DESC',
  DealsMaxWarranty_4TermAsc = 'DEALS_MAX_WARRANTY_4_TERM_ASC',
  DealsMaxWarranty_4TermDesc = 'DEALS_MAX_WARRANTY_4_TERM_DESC',
  DealsMaxWarranty_5NameAsc = 'DEALS_MAX_WARRANTY_5_NAME_ASC',
  DealsMaxWarranty_5NameDesc = 'DEALS_MAX_WARRANTY_5_NAME_DESC',
  DealsMaxWarranty_5SaleAsc = 'DEALS_MAX_WARRANTY_5_SALE_ASC',
  DealsMaxWarranty_5SaleDesc = 'DEALS_MAX_WARRANTY_5_SALE_DESC',
  DealsMaxWarranty_5CostAsc = 'DEALS_MAX_WARRANTY_5_COST_ASC',
  DealsMaxWarranty_5CostDesc = 'DEALS_MAX_WARRANTY_5_COST_DESC',
  DealsMaxWarranty_5MilesAsc = 'DEALS_MAX_WARRANTY_5_MILES_ASC',
  DealsMaxWarranty_5MilesDesc = 'DEALS_MAX_WARRANTY_5_MILES_DESC',
  DealsMaxWarranty_5TermAsc = 'DEALS_MAX_WARRANTY_5_TERM_ASC',
  DealsMaxWarranty_5TermDesc = 'DEALS_MAX_WARRANTY_5_TERM_DESC',
  DealsMaxTotalFeeAftermarketSaleAsc = 'DEALS_MAX_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsMaxTotalFeeAftermarketSaleDesc = 'DEALS_MAX_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsMaxTotalFeeAftermarketCostAsc = 'DEALS_MAX_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsMaxTotalFeeAftermarketCostDesc = 'DEALS_MAX_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsMaxFeeAftermarket_1NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_1_NAME_ASC',
  DealsMaxFeeAftermarket_1NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_1_NAME_DESC',
  DealsMaxFeeAftermarket_1SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_1_SALE_ASC',
  DealsMaxFeeAftermarket_1SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_1_SALE_DESC',
  DealsMaxFeeAftermarket_1CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_1_COST_ASC',
  DealsMaxFeeAftermarket_1CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_1_COST_DESC',
  DealsMaxFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_2NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_2_NAME_ASC',
  DealsMaxFeeAftermarket_2NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_2_NAME_DESC',
  DealsMaxFeeAftermarket_2SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_2_SALE_ASC',
  DealsMaxFeeAftermarket_2SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_2_SALE_DESC',
  DealsMaxFeeAftermarket_2CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_2_COST_ASC',
  DealsMaxFeeAftermarket_2CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_2_COST_DESC',
  DealsMaxFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_3NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_3_NAME_ASC',
  DealsMaxFeeAftermarket_3NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_3_NAME_DESC',
  DealsMaxFeeAftermarket_3SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_3_SALE_ASC',
  DealsMaxFeeAftermarket_3SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_3_SALE_DESC',
  DealsMaxFeeAftermarket_3CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_3_COST_ASC',
  DealsMaxFeeAftermarket_3CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_3_COST_DESC',
  DealsMaxFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_4NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_4_NAME_ASC',
  DealsMaxFeeAftermarket_4NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_4_NAME_DESC',
  DealsMaxFeeAftermarket_4SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_4_SALE_ASC',
  DealsMaxFeeAftermarket_4SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_4_SALE_DESC',
  DealsMaxFeeAftermarket_4CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_4_COST_ASC',
  DealsMaxFeeAftermarket_4CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_4_COST_DESC',
  DealsMaxFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_5NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_5_NAME_ASC',
  DealsMaxFeeAftermarket_5NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_5_NAME_DESC',
  DealsMaxFeeAftermarket_5SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_5_SALE_ASC',
  DealsMaxFeeAftermarket_5SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_5_SALE_DESC',
  DealsMaxFeeAftermarket_5CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_5_COST_ASC',
  DealsMaxFeeAftermarket_5CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_5_COST_DESC',
  DealsMaxFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_6NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_6_NAME_ASC',
  DealsMaxFeeAftermarket_6NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_6_NAME_DESC',
  DealsMaxFeeAftermarket_6SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_6_SALE_ASC',
  DealsMaxFeeAftermarket_6SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_6_SALE_DESC',
  DealsMaxFeeAftermarket_6CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_6_COST_ASC',
  DealsMaxFeeAftermarket_6CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_6_COST_DESC',
  DealsMaxFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_7NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_7_NAME_ASC',
  DealsMaxFeeAftermarket_7NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_7_NAME_DESC',
  DealsMaxFeeAftermarket_7SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_7_SALE_ASC',
  DealsMaxFeeAftermarket_7SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_7_SALE_DESC',
  DealsMaxFeeAftermarket_7CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_7_COST_ASC',
  DealsMaxFeeAftermarket_7CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_7_COST_DESC',
  DealsMaxFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_8NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_8_NAME_ASC',
  DealsMaxFeeAftermarket_8NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_8_NAME_DESC',
  DealsMaxFeeAftermarket_8SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_8_SALE_ASC',
  DealsMaxFeeAftermarket_8SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_8_SALE_DESC',
  DealsMaxFeeAftermarket_8CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_8_COST_ASC',
  DealsMaxFeeAftermarket_8CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_8_COST_DESC',
  DealsMaxFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_9NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_9_NAME_ASC',
  DealsMaxFeeAftermarket_9NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_9_NAME_DESC',
  DealsMaxFeeAftermarket_9SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_9_SALE_ASC',
  DealsMaxFeeAftermarket_9SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_9_SALE_DESC',
  DealsMaxFeeAftermarket_9CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_9_COST_ASC',
  DealsMaxFeeAftermarket_9CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_9_COST_DESC',
  DealsMaxFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_10NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_10_NAME_ASC',
  DealsMaxFeeAftermarket_10NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_10_NAME_DESC',
  DealsMaxFeeAftermarket_10SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_10_SALE_ASC',
  DealsMaxFeeAftermarket_10SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_10_SALE_DESC',
  DealsMaxFeeAftermarket_10CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_10_COST_ASC',
  DealsMaxFeeAftermarket_10CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_10_COST_DESC',
  DealsMaxFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_11NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_11_NAME_ASC',
  DealsMaxFeeAftermarket_11NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_11_NAME_DESC',
  DealsMaxFeeAftermarket_11SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_11_SALE_ASC',
  DealsMaxFeeAftermarket_11SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_11_SALE_DESC',
  DealsMaxFeeAftermarket_11CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_11_COST_ASC',
  DealsMaxFeeAftermarket_11CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_11_COST_DESC',
  DealsMaxFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_12NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_12_NAME_ASC',
  DealsMaxFeeAftermarket_12NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_12_NAME_DESC',
  DealsMaxFeeAftermarket_12SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_12_SALE_ASC',
  DealsMaxFeeAftermarket_12SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_12_SALE_DESC',
  DealsMaxFeeAftermarket_12CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_12_COST_ASC',
  DealsMaxFeeAftermarket_12CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_12_COST_DESC',
  DealsMaxFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_13NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_13_NAME_ASC',
  DealsMaxFeeAftermarket_13NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_13_NAME_DESC',
  DealsMaxFeeAftermarket_13SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_13_SALE_ASC',
  DealsMaxFeeAftermarket_13SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_13_SALE_DESC',
  DealsMaxFeeAftermarket_13CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_13_COST_ASC',
  DealsMaxFeeAftermarket_13CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_13_COST_DESC',
  DealsMaxFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_14NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_14_NAME_ASC',
  DealsMaxFeeAftermarket_14NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_14_NAME_DESC',
  DealsMaxFeeAftermarket_14SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_14_SALE_ASC',
  DealsMaxFeeAftermarket_14SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_14_SALE_DESC',
  DealsMaxFeeAftermarket_14CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_14_COST_ASC',
  DealsMaxFeeAftermarket_14CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_14_COST_DESC',
  DealsMaxFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_15NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_15_NAME_ASC',
  DealsMaxFeeAftermarket_15NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_15_NAME_DESC',
  DealsMaxFeeAftermarket_15SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_15_SALE_ASC',
  DealsMaxFeeAftermarket_15SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_15_SALE_DESC',
  DealsMaxFeeAftermarket_15CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_15_COST_ASC',
  DealsMaxFeeAftermarket_15CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_15_COST_DESC',
  DealsMaxFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_16NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_16_NAME_ASC',
  DealsMaxFeeAftermarket_16NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_16_NAME_DESC',
  DealsMaxFeeAftermarket_16SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_16_SALE_ASC',
  DealsMaxFeeAftermarket_16SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_16_SALE_DESC',
  DealsMaxFeeAftermarket_16CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_16_COST_ASC',
  DealsMaxFeeAftermarket_16CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_16_COST_DESC',
  DealsMaxFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_17NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_17_NAME_ASC',
  DealsMaxFeeAftermarket_17NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_17_NAME_DESC',
  DealsMaxFeeAftermarket_17SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_17_SALE_ASC',
  DealsMaxFeeAftermarket_17SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_17_SALE_DESC',
  DealsMaxFeeAftermarket_17CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_17_COST_ASC',
  DealsMaxFeeAftermarket_17CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_17_COST_DESC',
  DealsMaxFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_18NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_18_NAME_ASC',
  DealsMaxFeeAftermarket_18NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_18_NAME_DESC',
  DealsMaxFeeAftermarket_18SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_18_SALE_ASC',
  DealsMaxFeeAftermarket_18SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_18_SALE_DESC',
  DealsMaxFeeAftermarket_18CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_18_COST_ASC',
  DealsMaxFeeAftermarket_18CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_18_COST_DESC',
  DealsMaxFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_19NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_19_NAME_ASC',
  DealsMaxFeeAftermarket_19NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_19_NAME_DESC',
  DealsMaxFeeAftermarket_19SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_19_SALE_ASC',
  DealsMaxFeeAftermarket_19SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_19_SALE_DESC',
  DealsMaxFeeAftermarket_19CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_19_COST_ASC',
  DealsMaxFeeAftermarket_19CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_19_COST_DESC',
  DealsMaxFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsMaxFeeAftermarket_20NameAsc = 'DEALS_MAX_FEE_AFTERMARKET_20_NAME_ASC',
  DealsMaxFeeAftermarket_20NameDesc = 'DEALS_MAX_FEE_AFTERMARKET_20_NAME_DESC',
  DealsMaxFeeAftermarket_20SaleAsc = 'DEALS_MAX_FEE_AFTERMARKET_20_SALE_ASC',
  DealsMaxFeeAftermarket_20SaleDesc = 'DEALS_MAX_FEE_AFTERMARKET_20_SALE_DESC',
  DealsMaxFeeAftermarket_20CostAsc = 'DEALS_MAX_FEE_AFTERMARKET_20_COST_ASC',
  DealsMaxFeeAftermarket_20CostDesc = 'DEALS_MAX_FEE_AFTERMARKET_20_COST_DESC',
  DealsMaxFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_MAX_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsMaxFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_MAX_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsMaxInsurance_1TypeAsc = 'DEALS_MAX_INSURANCE_1_TYPE_ASC',
  DealsMaxInsurance_1TypeDesc = 'DEALS_MAX_INSURANCE_1_TYPE_DESC',
  DealsMaxInsurance_1NameAsc = 'DEALS_MAX_INSURANCE_1_NAME_ASC',
  DealsMaxInsurance_1NameDesc = 'DEALS_MAX_INSURANCE_1_NAME_DESC',
  DealsMaxInsurance_1SaleAsc = 'DEALS_MAX_INSURANCE_1_SALE_ASC',
  DealsMaxInsurance_1SaleDesc = 'DEALS_MAX_INSURANCE_1_SALE_DESC',
  DealsMaxInsurance_1CostAsc = 'DEALS_MAX_INSURANCE_1_COST_ASC',
  DealsMaxInsurance_1CostDesc = 'DEALS_MAX_INSURANCE_1_COST_DESC',
  DealsMaxInsurance_1TermAsc = 'DEALS_MAX_INSURANCE_1_TERM_ASC',
  DealsMaxInsurance_1TermDesc = 'DEALS_MAX_INSURANCE_1_TERM_DESC',
  DealsMaxInsurance_2TypeAsc = 'DEALS_MAX_INSURANCE_2_TYPE_ASC',
  DealsMaxInsurance_2TypeDesc = 'DEALS_MAX_INSURANCE_2_TYPE_DESC',
  DealsMaxInsurance_2NameAsc = 'DEALS_MAX_INSURANCE_2_NAME_ASC',
  DealsMaxInsurance_2NameDesc = 'DEALS_MAX_INSURANCE_2_NAME_DESC',
  DealsMaxInsurance_2SaleAsc = 'DEALS_MAX_INSURANCE_2_SALE_ASC',
  DealsMaxInsurance_2SaleDesc = 'DEALS_MAX_INSURANCE_2_SALE_DESC',
  DealsMaxInsurance_2CostAsc = 'DEALS_MAX_INSURANCE_2_COST_ASC',
  DealsMaxInsurance_2CostDesc = 'DEALS_MAX_INSURANCE_2_COST_DESC',
  DealsMaxInsurance_2TermAsc = 'DEALS_MAX_INSURANCE_2_TERM_ASC',
  DealsMaxInsurance_2TermDesc = 'DEALS_MAX_INSURANCE_2_TERM_DESC',
  DealsMaxInsurance_3TypeAsc = 'DEALS_MAX_INSURANCE_3_TYPE_ASC',
  DealsMaxInsurance_3TypeDesc = 'DEALS_MAX_INSURANCE_3_TYPE_DESC',
  DealsMaxInsurance_3NameAsc = 'DEALS_MAX_INSURANCE_3_NAME_ASC',
  DealsMaxInsurance_3NameDesc = 'DEALS_MAX_INSURANCE_3_NAME_DESC',
  DealsMaxInsurance_3SaleAsc = 'DEALS_MAX_INSURANCE_3_SALE_ASC',
  DealsMaxInsurance_3SaleDesc = 'DEALS_MAX_INSURANCE_3_SALE_DESC',
  DealsMaxInsurance_3CostAsc = 'DEALS_MAX_INSURANCE_3_COST_ASC',
  DealsMaxInsurance_3CostDesc = 'DEALS_MAX_INSURANCE_3_COST_DESC',
  DealsMaxInsurance_3TermAsc = 'DEALS_MAX_INSURANCE_3_TERM_ASC',
  DealsMaxInsurance_3TermDesc = 'DEALS_MAX_INSURANCE_3_TERM_DESC',
  DealsMaxAccidentalHealthTypeAsc = 'DEALS_MAX_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsMaxAccidentalHealthTypeDesc = 'DEALS_MAX_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsMaxAccidentalHealthNameAsc = 'DEALS_MAX_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsMaxAccidentalHealthNameDesc = 'DEALS_MAX_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsMaxAccidentalHealthSaleAsc = 'DEALS_MAX_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsMaxAccidentalHealthSaleDesc = 'DEALS_MAX_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsMaxAccidentalHealthCostAsc = 'DEALS_MAX_ACCIDENTAL_HEALTH_COST_ASC',
  DealsMaxAccidentalHealthCostDesc = 'DEALS_MAX_ACCIDENTAL_HEALTH_COST_DESC',
  DealsMaxAccidentalHealthTermAsc = 'DEALS_MAX_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsMaxAccidentalHealthTermDesc = 'DEALS_MAX_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsMaxCreditLifeTypeAsc = 'DEALS_MAX_CREDIT_LIFE_TYPE_ASC',
  DealsMaxCreditLifeTypeDesc = 'DEALS_MAX_CREDIT_LIFE_TYPE_DESC',
  DealsMaxCreditLifeNameAsc = 'DEALS_MAX_CREDIT_LIFE_NAME_ASC',
  DealsMaxCreditLifeNameDesc = 'DEALS_MAX_CREDIT_LIFE_NAME_DESC',
  DealsMaxCreditLifeSaleAsc = 'DEALS_MAX_CREDIT_LIFE_SALE_ASC',
  DealsMaxCreditLifeSaleDesc = 'DEALS_MAX_CREDIT_LIFE_SALE_DESC',
  DealsMaxCreditLifeCostAsc = 'DEALS_MAX_CREDIT_LIFE_COST_ASC',
  DealsMaxCreditLifeCostDesc = 'DEALS_MAX_CREDIT_LIFE_COST_DESC',
  DealsMaxCreditLifeTermAsc = 'DEALS_MAX_CREDIT_LIFE_TERM_ASC',
  DealsMaxCreditLifeTermDesc = 'DEALS_MAX_CREDIT_LIFE_TERM_DESC',
  DealsMaxLevelizedLifeTypeAsc = 'DEALS_MAX_LEVELIZED_LIFE_TYPE_ASC',
  DealsMaxLevelizedLifeTypeDesc = 'DEALS_MAX_LEVELIZED_LIFE_TYPE_DESC',
  DealsMaxLevelizedLifeNameAsc = 'DEALS_MAX_LEVELIZED_LIFE_NAME_ASC',
  DealsMaxLevelizedLifeNameDesc = 'DEALS_MAX_LEVELIZED_LIFE_NAME_DESC',
  DealsMaxLevelizedLifeSaleAsc = 'DEALS_MAX_LEVELIZED_LIFE_SALE_ASC',
  DealsMaxLevelizedLifeSaleDesc = 'DEALS_MAX_LEVELIZED_LIFE_SALE_DESC',
  DealsMaxLevelizedLifeCostAsc = 'DEALS_MAX_LEVELIZED_LIFE_COST_ASC',
  DealsMaxLevelizedLifeCostDesc = 'DEALS_MAX_LEVELIZED_LIFE_COST_DESC',
  DealsMaxLevelizedLifeTermAsc = 'DEALS_MAX_LEVELIZED_LIFE_TERM_ASC',
  DealsMaxLevelizedLifeTermDesc = 'DEALS_MAX_LEVELIZED_LIFE_TERM_DESC',
  DealsMaxLossOfEmploymentTypeAsc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsMaxLossOfEmploymentTypeDesc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsMaxLossOfEmploymentNameAsc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsMaxLossOfEmploymentNameDesc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsMaxLossOfEmploymentSaleAsc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsMaxLossOfEmploymentSaleDesc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsMaxLossOfEmploymentCostAsc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsMaxLossOfEmploymentCostDesc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsMaxLossOfEmploymentTermAsc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsMaxLossOfEmploymentTermDesc = 'DEALS_MAX_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsMaxGuaranteedAutoProtectionTypeAsc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsMaxGuaranteedAutoProtectionTypeDesc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsMaxGuaranteedAutoProtectionNameAsc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsMaxGuaranteedAutoProtectionNameDesc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsMaxGuaranteedAutoProtectionSaleAsc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsMaxGuaranteedAutoProtectionSaleDesc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsMaxGuaranteedAutoProtectionCostAsc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsMaxGuaranteedAutoProtectionCostDesc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsMaxGuaranteedAutoProtectionTermAsc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsMaxGuaranteedAutoProtectionTermDesc = 'DEALS_MAX_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsMaxSaleCommentsAsc = 'DEALS_MAX_SALE_COMMENTS_ASC',
  DealsMaxSaleCommentsDesc = 'DEALS_MAX_SALE_COMMENTS_DESC',
  DealsMaxCassStdLine1Asc = 'DEALS_MAX_CASS_STD_LINE1_ASC',
  DealsMaxCassStdLine1Desc = 'DEALS_MAX_CASS_STD_LINE1_DESC',
  DealsMaxCassStdLine2Asc = 'DEALS_MAX_CASS_STD_LINE2_ASC',
  DealsMaxCassStdLine2Desc = 'DEALS_MAX_CASS_STD_LINE2_DESC',
  DealsMaxCassStdCityAsc = 'DEALS_MAX_CASS_STD_CITY_ASC',
  DealsMaxCassStdCityDesc = 'DEALS_MAX_CASS_STD_CITY_DESC',
  DealsMaxCassStdStateAsc = 'DEALS_MAX_CASS_STD_STATE_ASC',
  DealsMaxCassStdStateDesc = 'DEALS_MAX_CASS_STD_STATE_DESC',
  DealsMaxCassStdZipAsc = 'DEALS_MAX_CASS_STD_ZIP_ASC',
  DealsMaxCassStdZipDesc = 'DEALS_MAX_CASS_STD_ZIP_DESC',
  DealsMaxCassStdZip4Asc = 'DEALS_MAX_CASS_STD_ZIP4_ASC',
  DealsMaxCassStdZip4Desc = 'DEALS_MAX_CASS_STD_ZIP4_DESC',
  DealsMaxCassStdDpbcAsc = 'DEALS_MAX_CASS_STD_DPBC_ASC',
  DealsMaxCassStdDpbcDesc = 'DEALS_MAX_CASS_STD_DPBC_DESC',
  DealsMaxCassStdChkdgtAsc = 'DEALS_MAX_CASS_STD_CHKDGT_ASC',
  DealsMaxCassStdChkdgtDesc = 'DEALS_MAX_CASS_STD_CHKDGT_DESC',
  DealsMaxCassStdCartAsc = 'DEALS_MAX_CASS_STD_CART_ASC',
  DealsMaxCassStdCartDesc = 'DEALS_MAX_CASS_STD_CART_DESC',
  DealsMaxCassStdLotAsc = 'DEALS_MAX_CASS_STD_LOT_ASC',
  DealsMaxCassStdLotDesc = 'DEALS_MAX_CASS_STD_LOT_DESC',
  DealsMaxCassStdLotordAsc = 'DEALS_MAX_CASS_STD_LOTORD_ASC',
  DealsMaxCassStdLotordDesc = 'DEALS_MAX_CASS_STD_LOTORD_DESC',
  DealsMaxCassStdUrbAsc = 'DEALS_MAX_CASS_STD_URB_ASC',
  DealsMaxCassStdUrbDesc = 'DEALS_MAX_CASS_STD_URB_DESC',
  DealsMaxCassStdFipsAsc = 'DEALS_MAX_CASS_STD_FIPS_ASC',
  DealsMaxCassStdFipsDesc = 'DEALS_MAX_CASS_STD_FIPS_DESC',
  DealsMaxCassStdEwsAsc = 'DEALS_MAX_CASS_STD_EWS_ASC',
  DealsMaxCassStdEwsDesc = 'DEALS_MAX_CASS_STD_EWS_DESC',
  DealsMaxCassStdLacsAsc = 'DEALS_MAX_CASS_STD_LACS_ASC',
  DealsMaxCassStdLacsDesc = 'DEALS_MAX_CASS_STD_LACS_DESC',
  DealsMaxCassStdZipmovAsc = 'DEALS_MAX_CASS_STD_ZIPMOV_ASC',
  DealsMaxCassStdZipmovDesc = 'DEALS_MAX_CASS_STD_ZIPMOV_DESC',
  DealsMaxCassStdZ4LomAsc = 'DEALS_MAX_CASS_STD_Z4LOM_ASC',
  DealsMaxCassStdZ4LomDesc = 'DEALS_MAX_CASS_STD_Z4LOM_DESC',
  DealsMaxCassStdNdiaptAsc = 'DEALS_MAX_CASS_STD_NDIAPT_ASC',
  DealsMaxCassStdNdiaptDesc = 'DEALS_MAX_CASS_STD_NDIAPT_DESC',
  DealsMaxCassStdNdirrAsc = 'DEALS_MAX_CASS_STD_NDIRR_ASC',
  DealsMaxCassStdNdirrDesc = 'DEALS_MAX_CASS_STD_NDIRR_DESC',
  DealsMaxCassStdLacsrtAsc = 'DEALS_MAX_CASS_STD_LACSRT_ASC',
  DealsMaxCassStdLacsrtDesc = 'DEALS_MAX_CASS_STD_LACSRT_DESC',
  DealsMaxCassStdErrorCdAsc = 'DEALS_MAX_CASS_STD_ERROR_CD_ASC',
  DealsMaxCassStdErrorCdDesc = 'DEALS_MAX_CASS_STD_ERROR_CD_DESC',
  DealsAverageIdAsc = 'DEALS_AVERAGE_ID_ASC',
  DealsAverageIdDesc = 'DEALS_AVERAGE_ID_DESC',
  DealsAverageLeadIdAsc = 'DEALS_AVERAGE_LEAD_ID_ASC',
  DealsAverageLeadIdDesc = 'DEALS_AVERAGE_LEAD_ID_DESC',
  DealsAverageCompanyIntegrationIdAsc = 'DEALS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  DealsAverageCompanyIntegrationIdDesc = 'DEALS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  DealsAverageFileTypeAsc = 'DEALS_AVERAGE_FILE_TYPE_ASC',
  DealsAverageFileTypeDesc = 'DEALS_AVERAGE_FILE_TYPE_DESC',
  DealsAverageDvDealerIdAsc = 'DEALS_AVERAGE_DV_DEALER_ID_ASC',
  DealsAverageDvDealerIdDesc = 'DEALS_AVERAGE_DV_DEALER_ID_DESC',
  DealsAverageVendorDealerIdAsc = 'DEALS_AVERAGE_VENDOR_DEALER_ID_ASC',
  DealsAverageVendorDealerIdDesc = 'DEALS_AVERAGE_VENDOR_DEALER_ID_DESC',
  DealsAverageDmsTypeAsc = 'DEALS_AVERAGE_DMS_TYPE_ASC',
  DealsAverageDmsTypeDesc = 'DEALS_AVERAGE_DMS_TYPE_DESC',
  DealsAverageDealNumberAsc = 'DEALS_AVERAGE_DEAL_NUMBER_ASC',
  DealsAverageDealNumberDesc = 'DEALS_AVERAGE_DEAL_NUMBER_DESC',
  DealsAverageCustomerNumberAsc = 'DEALS_AVERAGE_CUSTOMER_NUMBER_ASC',
  DealsAverageCustomerNumberDesc = 'DEALS_AVERAGE_CUSTOMER_NUMBER_DESC',
  DealsAverageFullNameAsc = 'DEALS_AVERAGE_FULL_NAME_ASC',
  DealsAverageFullNameDesc = 'DEALS_AVERAGE_FULL_NAME_DESC',
  DealsAverageSalutationAsc = 'DEALS_AVERAGE_SALUTATION_ASC',
  DealsAverageSalutationDesc = 'DEALS_AVERAGE_SALUTATION_DESC',
  DealsAverageFirstNameAsc = 'DEALS_AVERAGE_FIRST_NAME_ASC',
  DealsAverageFirstNameDesc = 'DEALS_AVERAGE_FIRST_NAME_DESC',
  DealsAverageMiddleNameAsc = 'DEALS_AVERAGE_MIDDLE_NAME_ASC',
  DealsAverageMiddleNameDesc = 'DEALS_AVERAGE_MIDDLE_NAME_DESC',
  DealsAverageLastNameAsc = 'DEALS_AVERAGE_LAST_NAME_ASC',
  DealsAverageLastNameDesc = 'DEALS_AVERAGE_LAST_NAME_DESC',
  DealsAverageSuffixAsc = 'DEALS_AVERAGE_SUFFIX_ASC',
  DealsAverageSuffixDesc = 'DEALS_AVERAGE_SUFFIX_DESC',
  DealsAverageAddressLine_1Asc = 'DEALS_AVERAGE_ADDRESS_LINE_1_ASC',
  DealsAverageAddressLine_1Desc = 'DEALS_AVERAGE_ADDRESS_LINE_1_DESC',
  DealsAverageAddressLine_2Asc = 'DEALS_AVERAGE_ADDRESS_LINE_2_ASC',
  DealsAverageAddressLine_2Desc = 'DEALS_AVERAGE_ADDRESS_LINE_2_DESC',
  DealsAverageCityAsc = 'DEALS_AVERAGE_CITY_ASC',
  DealsAverageCityDesc = 'DEALS_AVERAGE_CITY_DESC',
  DealsAverageStateAsc = 'DEALS_AVERAGE_STATE_ASC',
  DealsAverageStateDesc = 'DEALS_AVERAGE_STATE_DESC',
  DealsAverageZipAsc = 'DEALS_AVERAGE_ZIP_ASC',
  DealsAverageZipDesc = 'DEALS_AVERAGE_ZIP_DESC',
  DealsAverageCountyAsc = 'DEALS_AVERAGE_COUNTY_ASC',
  DealsAverageCountyDesc = 'DEALS_AVERAGE_COUNTY_DESC',
  DealsAverageHomePhoneAsc = 'DEALS_AVERAGE_HOME_PHONE_ASC',
  DealsAverageHomePhoneDesc = 'DEALS_AVERAGE_HOME_PHONE_DESC',
  DealsAverageCellPhoneAsc = 'DEALS_AVERAGE_CELL_PHONE_ASC',
  DealsAverageCellPhoneDesc = 'DEALS_AVERAGE_CELL_PHONE_DESC',
  DealsAverageWorkPhoneAsc = 'DEALS_AVERAGE_WORK_PHONE_ASC',
  DealsAverageWorkPhoneDesc = 'DEALS_AVERAGE_WORK_PHONE_DESC',
  DealsAverageWorkExtensionAsc = 'DEALS_AVERAGE_WORK_EXTENSION_ASC',
  DealsAverageWorkExtensionDesc = 'DEALS_AVERAGE_WORK_EXTENSION_DESC',
  DealsAverageEmail_1Asc = 'DEALS_AVERAGE_EMAIL_1_ASC',
  DealsAverageEmail_1Desc = 'DEALS_AVERAGE_EMAIL_1_DESC',
  DealsAverageEmail_2Asc = 'DEALS_AVERAGE_EMAIL_2_ASC',
  DealsAverageEmail_2Desc = 'DEALS_AVERAGE_EMAIL_2_DESC',
  DealsAverageEmail_3Asc = 'DEALS_AVERAGE_EMAIL_3_ASC',
  DealsAverageEmail_3Desc = 'DEALS_AVERAGE_EMAIL_3_DESC',
  DealsAverageBirthDateAsc = 'DEALS_AVERAGE_BIRTH_DATE_ASC',
  DealsAverageBirthDateDesc = 'DEALS_AVERAGE_BIRTH_DATE_DESC',
  DealsAverageIndividualBusinessFlagAsc = 'DEALS_AVERAGE_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsAverageIndividualBusinessFlagDesc = 'DEALS_AVERAGE_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsAverageOptOutAsc = 'DEALS_AVERAGE_OPT_OUT_ASC',
  DealsAverageOptOutDesc = 'DEALS_AVERAGE_OPT_OUT_DESC',
  DealsAverageBlockEmailAsc = 'DEALS_AVERAGE_BLOCK_EMAIL_ASC',
  DealsAverageBlockEmailDesc = 'DEALS_AVERAGE_BLOCK_EMAIL_DESC',
  DealsAverageBlockPhoneAsc = 'DEALS_AVERAGE_BLOCK_PHONE_ASC',
  DealsAverageBlockPhoneDesc = 'DEALS_AVERAGE_BLOCK_PHONE_DESC',
  DealsAverageBlockMailAsc = 'DEALS_AVERAGE_BLOCK_MAIL_ASC',
  DealsAverageBlockMailDesc = 'DEALS_AVERAGE_BLOCK_MAIL_DESC',
  DealsAverageLanguageAsc = 'DEALS_AVERAGE_LANGUAGE_ASC',
  DealsAverageLanguageDesc = 'DEALS_AVERAGE_LANGUAGE_DESC',
  DealsAverageCustomerCreateDateAsc = 'DEALS_AVERAGE_CUSTOMER_CREATE_DATE_ASC',
  DealsAverageCustomerCreateDateDesc = 'DEALS_AVERAGE_CUSTOMER_CREATE_DATE_DESC',
  DealsAverageCustomerLastActivityDateAsc = 'DEALS_AVERAGE_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsAverageCustomerLastActivityDateDesc = 'DEALS_AVERAGE_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsAverageCoBuyerCustomerNumberAsc = 'DEALS_AVERAGE_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsAverageCoBuyerCustomerNumberDesc = 'DEALS_AVERAGE_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsAverageCoBuyerFullNameAsc = 'DEALS_AVERAGE_CO_BUYER_FULL_NAME_ASC',
  DealsAverageCoBuyerFullNameDesc = 'DEALS_AVERAGE_CO_BUYER_FULL_NAME_DESC',
  DealsAverageCoBuyerSalutationAsc = 'DEALS_AVERAGE_CO_BUYER_SALUTATION_ASC',
  DealsAverageCoBuyerSalutationDesc = 'DEALS_AVERAGE_CO_BUYER_SALUTATION_DESC',
  DealsAverageCoBuyerFirstNameAsc = 'DEALS_AVERAGE_CO_BUYER_FIRST_NAME_ASC',
  DealsAverageCoBuyerFirstNameDesc = 'DEALS_AVERAGE_CO_BUYER_FIRST_NAME_DESC',
  DealsAverageCoBuyerMiddleNameAsc = 'DEALS_AVERAGE_CO_BUYER_MIDDLE_NAME_ASC',
  DealsAverageCoBuyerMiddleNameDesc = 'DEALS_AVERAGE_CO_BUYER_MIDDLE_NAME_DESC',
  DealsAverageCoBuyerLastNameAsc = 'DEALS_AVERAGE_CO_BUYER_LAST_NAME_ASC',
  DealsAverageCoBuyerLastNameDesc = 'DEALS_AVERAGE_CO_BUYER_LAST_NAME_DESC',
  DealsAverageCoBuyerSuffixAsc = 'DEALS_AVERAGE_CO_BUYER_SUFFIX_ASC',
  DealsAverageCoBuyerSuffixDesc = 'DEALS_AVERAGE_CO_BUYER_SUFFIX_DESC',
  DealsAverageCoBuyerAddressLine_1Asc = 'DEALS_AVERAGE_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsAverageCoBuyerAddressLine_1Desc = 'DEALS_AVERAGE_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsAverageCoBuyerAddressLine_2Asc = 'DEALS_AVERAGE_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsAverageCoBuyerAddressLine_2Desc = 'DEALS_AVERAGE_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsAverageCoBuyerCityAsc = 'DEALS_AVERAGE_CO_BUYER_CITY_ASC',
  DealsAverageCoBuyerCityDesc = 'DEALS_AVERAGE_CO_BUYER_CITY_DESC',
  DealsAverageCoBuyerStateAsc = 'DEALS_AVERAGE_CO_BUYER_STATE_ASC',
  DealsAverageCoBuyerStateDesc = 'DEALS_AVERAGE_CO_BUYER_STATE_DESC',
  DealsAverageCoBuyerZipAsc = 'DEALS_AVERAGE_CO_BUYER_ZIP_ASC',
  DealsAverageCoBuyerZipDesc = 'DEALS_AVERAGE_CO_BUYER_ZIP_DESC',
  DealsAverageCoBuyerCountyAsc = 'DEALS_AVERAGE_CO_BUYER_COUNTY_ASC',
  DealsAverageCoBuyerCountyDesc = 'DEALS_AVERAGE_CO_BUYER_COUNTY_DESC',
  DealsAverageCoBuyerHomePhoneAsc = 'DEALS_AVERAGE_CO_BUYER_HOME_PHONE_ASC',
  DealsAverageCoBuyerHomePhoneDesc = 'DEALS_AVERAGE_CO_BUYER_HOME_PHONE_DESC',
  DealsAverageCoBuyerCellPhoneAsc = 'DEALS_AVERAGE_CO_BUYER_CELL_PHONE_ASC',
  DealsAverageCoBuyerCellPhoneDesc = 'DEALS_AVERAGE_CO_BUYER_CELL_PHONE_DESC',
  DealsAverageCoBuyerWorkPhoneAsc = 'DEALS_AVERAGE_CO_BUYER_WORK_PHONE_ASC',
  DealsAverageCoBuyerWorkPhoneDesc = 'DEALS_AVERAGE_CO_BUYER_WORK_PHONE_DESC',
  DealsAverageCoBuyerWorkExtensionAsc = 'DEALS_AVERAGE_CO_BUYER_WORK_EXTENSION_ASC',
  DealsAverageCoBuyerWorkExtensionDesc = 'DEALS_AVERAGE_CO_BUYER_WORK_EXTENSION_DESC',
  DealsAverageCoBuyerEmail_1Asc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_1_ASC',
  DealsAverageCoBuyerEmail_1Desc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_1_DESC',
  DealsAverageCoBuyerEmail_2Asc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_2_ASC',
  DealsAverageCoBuyerEmail_2Desc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_2_DESC',
  DealsAverageCoBuyerEmail_3Asc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_3_ASC',
  DealsAverageCoBuyerEmail_3Desc = 'DEALS_AVERAGE_CO_BUYER_EMAIL_3_DESC',
  DealsAverageCoBuyerBirthDateAsc = 'DEALS_AVERAGE_CO_BUYER_BIRTH_DATE_ASC',
  DealsAverageCoBuyerBirthDateDesc = 'DEALS_AVERAGE_CO_BUYER_BIRTH_DATE_DESC',
  DealsAverageCoBuyerIndividualBusinessFlagAsc = 'DEALS_AVERAGE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsAverageCoBuyerIndividualBusinessFlagDesc = 'DEALS_AVERAGE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsAverageCoBuyerOptOutAsc = 'DEALS_AVERAGE_CO_BUYER_OPT_OUT_ASC',
  DealsAverageCoBuyerOptOutDesc = 'DEALS_AVERAGE_CO_BUYER_OPT_OUT_DESC',
  DealsAverageCoBuyerBlockEmailAsc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsAverageCoBuyerBlockEmailDesc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsAverageCoBuyerBlockPhoneAsc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_PHONE_ASC',
  DealsAverageCoBuyerBlockPhoneDesc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_PHONE_DESC',
  DealsAverageCoBuyerBlockMailAsc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_MAIL_ASC',
  DealsAverageCoBuyerBlockMailDesc = 'DEALS_AVERAGE_CO_BUYER_BLOCK_MAIL_DESC',
  DealsAverageVinAsc = 'DEALS_AVERAGE_VIN_ASC',
  DealsAverageVinDesc = 'DEALS_AVERAGE_VIN_DESC',
  DealsAverageYearAsc = 'DEALS_AVERAGE_YEAR_ASC',
  DealsAverageYearDesc = 'DEALS_AVERAGE_YEAR_DESC',
  DealsAverageMakeAsc = 'DEALS_AVERAGE_MAKE_ASC',
  DealsAverageMakeDesc = 'DEALS_AVERAGE_MAKE_DESC',
  DealsAverageModelAsc = 'DEALS_AVERAGE_MODEL_ASC',
  DealsAverageModelDesc = 'DEALS_AVERAGE_MODEL_DESC',
  DealsAverageModelNumberAsc = 'DEALS_AVERAGE_MODEL_NUMBER_ASC',
  DealsAverageModelNumberDesc = 'DEALS_AVERAGE_MODEL_NUMBER_DESC',
  DealsAverageMileageAsc = 'DEALS_AVERAGE_MILEAGE_ASC',
  DealsAverageMileageDesc = 'DEALS_AVERAGE_MILEAGE_DESC',
  DealsAverageDescriptionAsc = 'DEALS_AVERAGE_DESCRIPTION_ASC',
  DealsAverageDescriptionDesc = 'DEALS_AVERAGE_DESCRIPTION_DESC',
  DealsAverageExteriorColorAsc = 'DEALS_AVERAGE_EXTERIOR_COLOR_ASC',
  DealsAverageExteriorColorDesc = 'DEALS_AVERAGE_EXTERIOR_COLOR_DESC',
  DealsAverageNewUsedAsc = 'DEALS_AVERAGE_NEW_USED_ASC',
  DealsAverageNewUsedDesc = 'DEALS_AVERAGE_NEW_USED_DESC',
  DealsAverageStockNumberAsc = 'DEALS_AVERAGE_STOCK_NUMBER_ASC',
  DealsAverageStockNumberDesc = 'DEALS_AVERAGE_STOCK_NUMBER_DESC',
  DealsAverageTransmissionAsc = 'DEALS_AVERAGE_TRANSMISSION_ASC',
  DealsAverageTransmissionDesc = 'DEALS_AVERAGE_TRANSMISSION_DESC',
  DealsAverageEngineConfigurationAsc = 'DEALS_AVERAGE_ENGINE_CONFIGURATION_ASC',
  DealsAverageEngineConfigurationDesc = 'DEALS_AVERAGE_ENGINE_CONFIGURATION_DESC',
  DealsAverageTrimAsc = 'DEALS_AVERAGE_TRIM_ASC',
  DealsAverageTrimDesc = 'DEALS_AVERAGE_TRIM_DESC',
  DealsAverageEngineNumberAsc = 'DEALS_AVERAGE_ENGINE_NUMBER_ASC',
  DealsAverageEngineNumberDesc = 'DEALS_AVERAGE_ENGINE_NUMBER_DESC',
  DealsAverageChassisNumberAsc = 'DEALS_AVERAGE_CHASSIS_NUMBER_ASC',
  DealsAverageChassisNumberDesc = 'DEALS_AVERAGE_CHASSIS_NUMBER_DESC',
  DealsAverageLicensePlateNumberAsc = 'DEALS_AVERAGE_LICENSE_PLATE_NUMBER_ASC',
  DealsAverageLicensePlateNumberDesc = 'DEALS_AVERAGE_LICENSE_PLATE_NUMBER_DESC',
  DealsAverageDeliveryDateAsc = 'DEALS_AVERAGE_DELIVERY_DATE_ASC',
  DealsAverageDeliveryDateDesc = 'DEALS_AVERAGE_DELIVERY_DATE_DESC',
  DealsAverageDeliveryMileageAsc = 'DEALS_AVERAGE_DELIVERY_MILEAGE_ASC',
  DealsAverageDeliveryMileageDesc = 'DEALS_AVERAGE_DELIVERY_MILEAGE_DESC',
  DealsAverageInventoryDateAsc = 'DEALS_AVERAGE_INVENTORY_DATE_ASC',
  DealsAverageInventoryDateDesc = 'DEALS_AVERAGE_INVENTORY_DATE_DESC',
  DealsAverageInServiceDateAsc = 'DEALS_AVERAGE_IN_SERVICE_DATE_ASC',
  DealsAverageInServiceDateDesc = 'DEALS_AVERAGE_IN_SERVICE_DATE_DESC',
  DealsAverageVinExplosionYearAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_YEAR_ASC',
  DealsAverageVinExplosionYearDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_YEAR_DESC',
  DealsAverageVinExplosionMakeAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_MAKE_ASC',
  DealsAverageVinExplosionMakeDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_MAKE_DESC',
  DealsAverageVinExplosionModelAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_MODEL_ASC',
  DealsAverageVinExplosionModelDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_MODEL_DESC',
  DealsAverageVinExplosionTrimAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_TRIM_ASC',
  DealsAverageVinExplosionTrimDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_TRIM_DESC',
  DealsAverageVinExplosionTransmissionTypeAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsAverageVinExplosionTransmissionTypeDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsAverageVinExplosionFuelTypeAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsAverageVinExplosionFuelTypeDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsAverageVinExplosionEngineSizeAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsAverageVinExplosionEngineSizeDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsAverageVinExplosionGvwRangeAsc = 'DEALS_AVERAGE_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsAverageVinExplosionGvwRangeDesc = 'DEALS_AVERAGE_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsAverageTrade_1VinAsc = 'DEALS_AVERAGE_TRADE_1_VIN_ASC',
  DealsAverageTrade_1VinDesc = 'DEALS_AVERAGE_TRADE_1_VIN_DESC',
  DealsAverageTrade_1YearAsc = 'DEALS_AVERAGE_TRADE_1_YEAR_ASC',
  DealsAverageTrade_1YearDesc = 'DEALS_AVERAGE_TRADE_1_YEAR_DESC',
  DealsAverageTrade_1MakeAsc = 'DEALS_AVERAGE_TRADE_1_MAKE_ASC',
  DealsAverageTrade_1MakeDesc = 'DEALS_AVERAGE_TRADE_1_MAKE_DESC',
  DealsAverageTrade_1ModelAsc = 'DEALS_AVERAGE_TRADE_1_MODEL_ASC',
  DealsAverageTrade_1ModelDesc = 'DEALS_AVERAGE_TRADE_1_MODEL_DESC',
  DealsAverageTrade_1OdometerAsc = 'DEALS_AVERAGE_TRADE_1_ODOMETER_ASC',
  DealsAverageTrade_1OdometerDesc = 'DEALS_AVERAGE_TRADE_1_ODOMETER_DESC',
  DealsAverageTrade_1ActualCashValueAsc = 'DEALS_AVERAGE_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsAverageTrade_1ActualCashValueDesc = 'DEALS_AVERAGE_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsAverageTrade_1GrossAsc = 'DEALS_AVERAGE_TRADE_1_GROSS_ASC',
  DealsAverageTrade_1GrossDesc = 'DEALS_AVERAGE_TRADE_1_GROSS_DESC',
  DealsAverageTrade_1PayoffAsc = 'DEALS_AVERAGE_TRADE_1_PAYOFF_ASC',
  DealsAverageTrade_1PayoffDesc = 'DEALS_AVERAGE_TRADE_1_PAYOFF_DESC',
  DealsAverageTrade_2VinAsc = 'DEALS_AVERAGE_TRADE_2_VIN_ASC',
  DealsAverageTrade_2VinDesc = 'DEALS_AVERAGE_TRADE_2_VIN_DESC',
  DealsAverageTrade_2YearAsc = 'DEALS_AVERAGE_TRADE_2_YEAR_ASC',
  DealsAverageTrade_2YearDesc = 'DEALS_AVERAGE_TRADE_2_YEAR_DESC',
  DealsAverageTrade_2MakeAsc = 'DEALS_AVERAGE_TRADE_2_MAKE_ASC',
  DealsAverageTrade_2MakeDesc = 'DEALS_AVERAGE_TRADE_2_MAKE_DESC',
  DealsAverageTrade_2ModelAsc = 'DEALS_AVERAGE_TRADE_2_MODEL_ASC',
  DealsAverageTrade_2ModelDesc = 'DEALS_AVERAGE_TRADE_2_MODEL_DESC',
  DealsAverageTrade_2OdometerAsc = 'DEALS_AVERAGE_TRADE_2_ODOMETER_ASC',
  DealsAverageTrade_2OdometerDesc = 'DEALS_AVERAGE_TRADE_2_ODOMETER_DESC',
  DealsAverageTrade_2ActualCashValueAsc = 'DEALS_AVERAGE_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsAverageTrade_2ActualCashValueDesc = 'DEALS_AVERAGE_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsAverageTrade_2GrossAsc = 'DEALS_AVERAGE_TRADE_2_GROSS_ASC',
  DealsAverageTrade_2GrossDesc = 'DEALS_AVERAGE_TRADE_2_GROSS_DESC',
  DealsAverageTrade_2PayoffAsc = 'DEALS_AVERAGE_TRADE_2_PAYOFF_ASC',
  DealsAverageTrade_2PayoffDesc = 'DEALS_AVERAGE_TRADE_2_PAYOFF_DESC',
  DealsAverageSalesman_1NumberAsc = 'DEALS_AVERAGE_SALESMAN_1_NUMBER_ASC',
  DealsAverageSalesman_1NumberDesc = 'DEALS_AVERAGE_SALESMAN_1_NUMBER_DESC',
  DealsAverageSalesman_1NameAsc = 'DEALS_AVERAGE_SALESMAN_1_NAME_ASC',
  DealsAverageSalesman_1NameDesc = 'DEALS_AVERAGE_SALESMAN_1_NAME_DESC',
  DealsAverageSalesman_2NumberAsc = 'DEALS_AVERAGE_SALESMAN_2_NUMBER_ASC',
  DealsAverageSalesman_2NumberDesc = 'DEALS_AVERAGE_SALESMAN_2_NUMBER_DESC',
  DealsAverageSalesman_2NameAsc = 'DEALS_AVERAGE_SALESMAN_2_NAME_ASC',
  DealsAverageSalesman_2NameDesc = 'DEALS_AVERAGE_SALESMAN_2_NAME_DESC',
  DealsAverageSalesman_3NumberAsc = 'DEALS_AVERAGE_SALESMAN_3_NUMBER_ASC',
  DealsAverageSalesman_3NumberDesc = 'DEALS_AVERAGE_SALESMAN_3_NUMBER_DESC',
  DealsAverageSalesman_3NameAsc = 'DEALS_AVERAGE_SALESMAN_3_NAME_ASC',
  DealsAverageSalesman_3NameDesc = 'DEALS_AVERAGE_SALESMAN_3_NAME_DESC',
  DealsAverageClosingManagerNumberAsc = 'DEALS_AVERAGE_CLOSING_MANAGER_NUMBER_ASC',
  DealsAverageClosingManagerNumberDesc = 'DEALS_AVERAGE_CLOSING_MANAGER_NUMBER_DESC',
  DealsAverageClosingManagerNameAsc = 'DEALS_AVERAGE_CLOSING_MANAGER_NAME_ASC',
  DealsAverageClosingManagerNameDesc = 'DEALS_AVERAGE_CLOSING_MANAGER_NAME_DESC',
  DealsAverageFinanceManagerNumberAsc = 'DEALS_AVERAGE_FINANCE_MANAGER_NUMBER_ASC',
  DealsAverageFinanceManagerNumberDesc = 'DEALS_AVERAGE_FINANCE_MANAGER_NUMBER_DESC',
  DealsAverageFinanceManagerNameAsc = 'DEALS_AVERAGE_FINANCE_MANAGER_NAME_ASC',
  DealsAverageFinanceManagerNameDesc = 'DEALS_AVERAGE_FINANCE_MANAGER_NAME_DESC',
  DealsAverageSalesmanManagerNumberAsc = 'DEALS_AVERAGE_SALESMAN_MANAGER_NUMBER_ASC',
  DealsAverageSalesmanManagerNumberDesc = 'DEALS_AVERAGE_SALESMAN_MANAGER_NUMBER_DESC',
  DealsAverageSalesmanManagerNameAsc = 'DEALS_AVERAGE_SALESMAN_MANAGER_NAME_ASC',
  DealsAverageSalesmanManagerNameDesc = 'DEALS_AVERAGE_SALESMAN_MANAGER_NAME_DESC',
  DealsAverageMsrpAsc = 'DEALS_AVERAGE_MSRP_ASC',
  DealsAverageMsrpDesc = 'DEALS_AVERAGE_MSRP_DESC',
  DealsAverageListPriceAsc = 'DEALS_AVERAGE_LIST_PRICE_ASC',
  DealsAverageListPriceDesc = 'DEALS_AVERAGE_LIST_PRICE_DESC',
  DealsAverageSalesPriceAsc = 'DEALS_AVERAGE_SALES_PRICE_ASC',
  DealsAverageSalesPriceDesc = 'DEALS_AVERAGE_SALES_PRICE_DESC',
  DealsAverageJournalPriceAsc = 'DEALS_AVERAGE_JOURNAL_PRICE_ASC',
  DealsAverageJournalPriceDesc = 'DEALS_AVERAGE_JOURNAL_PRICE_DESC',
  DealsAverageCostAsc = 'DEALS_AVERAGE_COST_ASC',
  DealsAverageCostDesc = 'DEALS_AVERAGE_COST_DESC',
  DealsAverageJournalCostAsc = 'DEALS_AVERAGE_JOURNAL_COST_ASC',
  DealsAverageJournalCostDesc = 'DEALS_AVERAGE_JOURNAL_COST_DESC',
  DealsAverageAdjustmentsAsc = 'DEALS_AVERAGE_ADJUSTMENTS_ASC',
  DealsAverageAdjustmentsDesc = 'DEALS_AVERAGE_ADJUSTMENTS_DESC',
  DealsAverageAdjustedCostAsc = 'DEALS_AVERAGE_ADJUSTED_COST_ASC',
  DealsAverageAdjustedCostDesc = 'DEALS_AVERAGE_ADJUSTED_COST_DESC',
  DealsAverageIncentivesAsc = 'DEALS_AVERAGE_INCENTIVES_ASC',
  DealsAverageIncentivesDesc = 'DEALS_AVERAGE_INCENTIVES_DESC',
  DealsAveragePackAmountAsc = 'DEALS_AVERAGE_PACK_AMOUNT_ASC',
  DealsAveragePackAmountDesc = 'DEALS_AVERAGE_PACK_AMOUNT_DESC',
  DealsAverageSaleNetAsc = 'DEALS_AVERAGE_SALE_NET_ASC',
  DealsAverageSaleNetDesc = 'DEALS_AVERAGE_SALE_NET_DESC',
  DealsAverageTotalTradeActualCashValueAsc = 'DEALS_AVERAGE_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsAverageTotalTradeActualCashValueDesc = 'DEALS_AVERAGE_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsAverageTotalTradeGrossAsc = 'DEALS_AVERAGE_TOTAL_TRADE_GROSS_ASC',
  DealsAverageTotalTradeGrossDesc = 'DEALS_AVERAGE_TOTAL_TRADE_GROSS_DESC',
  DealsAverageWeOweFrontAsc = 'DEALS_AVERAGE_WE_OWE_FRONT_ASC',
  DealsAverageWeOweFrontDesc = 'DEALS_AVERAGE_WE_OWE_FRONT_DESC',
  DealsAverageTotalFrontFeesAftermarketProfitAsc = 'DEALS_AVERAGE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsAverageTotalFrontFeesAftermarketProfitDesc = 'DEALS_AVERAGE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsAverageTotalFrontCommissionAsc = 'DEALS_AVERAGE_TOTAL_FRONT_COMMISSION_ASC',
  DealsAverageTotalFrontCommissionDesc = 'DEALS_AVERAGE_TOTAL_FRONT_COMMISSION_DESC',
  DealsAverageTotalFrontSalesAsc = 'DEALS_AVERAGE_TOTAL_FRONT_SALES_ASC',
  DealsAverageTotalFrontSalesDesc = 'DEALS_AVERAGE_TOTAL_FRONT_SALES_DESC',
  DealsAverageTotalFrontCostAsc = 'DEALS_AVERAGE_TOTAL_FRONT_COST_ASC',
  DealsAverageTotalFrontCostDesc = 'DEALS_AVERAGE_TOTAL_FRONT_COST_DESC',
  DealsAverageFrontGrossAsc = 'DEALS_AVERAGE_FRONT_GROSS_ASC',
  DealsAverageFrontGrossDesc = 'DEALS_AVERAGE_FRONT_GROSS_DESC',
  DealsAverageFinanceProfitAsc = 'DEALS_AVERAGE_FINANCE_PROFIT_ASC',
  DealsAverageFinanceProfitDesc = 'DEALS_AVERAGE_FINANCE_PROFIT_DESC',
  DealsAverageTotalWarrantyProfitAsc = 'DEALS_AVERAGE_TOTAL_WARRANTY_PROFIT_ASC',
  DealsAverageTotalWarrantyProfitDesc = 'DEALS_AVERAGE_TOTAL_WARRANTY_PROFIT_DESC',
  DealsAverageWeOweBackAsc = 'DEALS_AVERAGE_WE_OWE_BACK_ASC',
  DealsAverageWeOweBackDesc = 'DEALS_AVERAGE_WE_OWE_BACK_DESC',
  DealsAverageInsuranceProfitAsc = 'DEALS_AVERAGE_INSURANCE_PROFIT_ASC',
  DealsAverageInsuranceProfitDesc = 'DEALS_AVERAGE_INSURANCE_PROFIT_DESC',
  DealsAverageTotalBackFeesAftermarketProfitAsc = 'DEALS_AVERAGE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsAverageTotalBackFeesAftermarketProfitDesc = 'DEALS_AVERAGE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsAverageFinanceReserveAsc = 'DEALS_AVERAGE_FINANCE_RESERVE_ASC',
  DealsAverageFinanceReserveDesc = 'DEALS_AVERAGE_FINANCE_RESERVE_DESC',
  DealsAverageTotalBackCommissionAsc = 'DEALS_AVERAGE_TOTAL_BACK_COMMISSION_ASC',
  DealsAverageTotalBackCommissionDesc = 'DEALS_AVERAGE_TOTAL_BACK_COMMISSION_DESC',
  DealsAverageTotalBackSalesAsc = 'DEALS_AVERAGE_TOTAL_BACK_SALES_ASC',
  DealsAverageTotalBackSalesDesc = 'DEALS_AVERAGE_TOTAL_BACK_SALES_DESC',
  DealsAverageTotalBackCostAsc = 'DEALS_AVERAGE_TOTAL_BACK_COST_ASC',
  DealsAverageTotalBackCostDesc = 'DEALS_AVERAGE_TOTAL_BACK_COST_DESC',
  DealsAverageBackGrossAsc = 'DEALS_AVERAGE_BACK_GROSS_ASC',
  DealsAverageBackGrossDesc = 'DEALS_AVERAGE_BACK_GROSS_DESC',
  DealsAverageTotalProfitAsc = 'DEALS_AVERAGE_TOTAL_PROFIT_ASC',
  DealsAverageTotalProfitDesc = 'DEALS_AVERAGE_TOTAL_PROFIT_DESC',
  DealsAverageGrossProfitAsc = 'DEALS_AVERAGE_GROSS_PROFIT_ASC',
  DealsAverageGrossProfitDesc = 'DEALS_AVERAGE_GROSS_PROFIT_DESC',
  DealsAverageGrossPayableAsc = 'DEALS_AVERAGE_GROSS_PAYABLE_ASC',
  DealsAverageGrossPayableDesc = 'DEALS_AVERAGE_GROSS_PAYABLE_DESC',
  DealsAverageDealStatusAsc = 'DEALS_AVERAGE_DEAL_STATUS_ASC',
  DealsAverageDealStatusDesc = 'DEALS_AVERAGE_DEAL_STATUS_DESC',
  DealsAverageEntryDateAsc = 'DEALS_AVERAGE_ENTRY_DATE_ASC',
  DealsAverageEntryDateDesc = 'DEALS_AVERAGE_ENTRY_DATE_DESC',
  DealsAverageBookedDateAsc = 'DEALS_AVERAGE_BOOKED_DATE_ASC',
  DealsAverageBookedDateDesc = 'DEALS_AVERAGE_BOOKED_DATE_DESC',
  DealsAverageFinalizedDateAsc = 'DEALS_AVERAGE_FINALIZED_DATE_ASC',
  DealsAverageFinalizedDateDesc = 'DEALS_AVERAGE_FINALIZED_DATE_DESC',
  DealsAverageContractDateAsc = 'DEALS_AVERAGE_CONTRACT_DATE_ASC',
  DealsAverageContractDateDesc = 'DEALS_AVERAGE_CONTRACT_DATE_DESC',
  DealsAverageAccountingDateAsc = 'DEALS_AVERAGE_ACCOUNTING_DATE_ASC',
  DealsAverageAccountingDateDesc = 'DEALS_AVERAGE_ACCOUNTING_DATE_DESC',
  DealsAverageStatusChangeDateAsc = 'DEALS_AVERAGE_STATUS_CHANGE_DATE_ASC',
  DealsAverageStatusChangeDateDesc = 'DEALS_AVERAGE_STATUS_CHANGE_DATE_DESC',
  DealsAverageFirstPayDateAsc = 'DEALS_AVERAGE_FIRST_PAY_DATE_ASC',
  DealsAverageFirstPayDateDesc = 'DEALS_AVERAGE_FIRST_PAY_DATE_DESC',
  DealsAverageDealTypeAsc = 'DEALS_AVERAGE_DEAL_TYPE_ASC',
  DealsAverageDealTypeDesc = 'DEALS_AVERAGE_DEAL_TYPE_DESC',
  DealsAverageSaleTypeAsc = 'DEALS_AVERAGE_SALE_TYPE_ASC',
  DealsAverageSaleTypeDesc = 'DEALS_AVERAGE_SALE_TYPE_DESC',
  DealsAverageBankIdAsc = 'DEALS_AVERAGE_BANK_ID_ASC',
  DealsAverageBankIdDesc = 'DEALS_AVERAGE_BANK_ID_DESC',
  DealsAverageBankNameAsc = 'DEALS_AVERAGE_BANK_NAME_ASC',
  DealsAverageBankNameDesc = 'DEALS_AVERAGE_BANK_NAME_DESC',
  DealsAverageBankAddressAsc = 'DEALS_AVERAGE_BANK_ADDRESS_ASC',
  DealsAverageBankAddressDesc = 'DEALS_AVERAGE_BANK_ADDRESS_DESC',
  DealsAverageTermAsc = 'DEALS_AVERAGE_TERM_ASC',
  DealsAverageTermDesc = 'DEALS_AVERAGE_TERM_DESC',
  DealsAverageAmountFinancedAsc = 'DEALS_AVERAGE_AMOUNT_FINANCED_ASC',
  DealsAverageAmountFinancedDesc = 'DEALS_AVERAGE_AMOUNT_FINANCED_DESC',
  DealsAverageAprAsc = 'DEALS_AVERAGE_APR_ASC',
  DealsAverageAprDesc = 'DEALS_AVERAGE_APR_DESC',
  DealsAverageMonthlyPaymentAsc = 'DEALS_AVERAGE_MONTHLY_PAYMENT_ASC',
  DealsAverageMonthlyPaymentDesc = 'DEALS_AVERAGE_MONTHLY_PAYMENT_DESC',
  DealsAveragePaymentTotalAsc = 'DEALS_AVERAGE_PAYMENT_TOTAL_ASC',
  DealsAveragePaymentTotalDesc = 'DEALS_AVERAGE_PAYMENT_TOTAL_DESC',
  DealsAverageRebatesAsc = 'DEALS_AVERAGE_REBATES_ASC',
  DealsAverageRebatesDesc = 'DEALS_AVERAGE_REBATES_DESC',
  DealsAverageDepositAsc = 'DEALS_AVERAGE_DEPOSIT_ASC',
  DealsAverageDepositDesc = 'DEALS_AVERAGE_DEPOSIT_DESC',
  DealsAverageDownPaymentAsc = 'DEALS_AVERAGE_DOWN_PAYMENT_ASC',
  DealsAverageDownPaymentDesc = 'DEALS_AVERAGE_DOWN_PAYMENT_DESC',
  DealsAverageTotalNetTradesAsc = 'DEALS_AVERAGE_TOTAL_NET_TRADES_ASC',
  DealsAverageTotalNetTradesDesc = 'DEALS_AVERAGE_TOTAL_NET_TRADES_DESC',
  DealsAverageTotalDownAsc = 'DEALS_AVERAGE_TOTAL_DOWN_ASC',
  DealsAverageTotalDownDesc = 'DEALS_AVERAGE_TOTAL_DOWN_DESC',
  DealsAverageBalloonAmountAsc = 'DEALS_AVERAGE_BALLOON_AMOUNT_ASC',
  DealsAverageBalloonAmountDesc = 'DEALS_AVERAGE_BALLOON_AMOUNT_DESC',
  DealsAverageAdjustedBalloonAmountAsc = 'DEALS_AVERAGE_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsAverageAdjustedBalloonAmountDesc = 'DEALS_AVERAGE_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsAverageHoldbackAmountAsc = 'DEALS_AVERAGE_HOLDBACK_AMOUNT_ASC',
  DealsAverageHoldbackAmountDesc = 'DEALS_AVERAGE_HOLDBACK_AMOUNT_DESC',
  DealsAverageTotalDriveOffAmountAsc = 'DEALS_AVERAGE_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsAverageTotalDriveOffAmountDesc = 'DEALS_AVERAGE_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsAverageLicenseFeeAsc = 'DEALS_AVERAGE_LICENSE_FEE_ASC',
  DealsAverageLicenseFeeDesc = 'DEALS_AVERAGE_LICENSE_FEE_DESC',
  DealsAverageRegistrationFeeAsc = 'DEALS_AVERAGE_REGISTRATION_FEE_ASC',
  DealsAverageRegistrationFeeDesc = 'DEALS_AVERAGE_REGISTRATION_FEE_DESC',
  DealsAverageDocumentationFeeAsc = 'DEALS_AVERAGE_DOCUMENTATION_FEE_ASC',
  DealsAverageDocumentationFeeDesc = 'DEALS_AVERAGE_DOCUMENTATION_FEE_DESC',
  DealsAverageFinanceChargeAsc = 'DEALS_AVERAGE_FINANCE_CHARGE_ASC',
  DealsAverageFinanceChargeDesc = 'DEALS_AVERAGE_FINANCE_CHARGE_DESC',
  DealsAverageTotalPickupPaymentsAsc = 'DEALS_AVERAGE_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsAverageTotalPickupPaymentsDesc = 'DEALS_AVERAGE_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsAverageSellRateAsc = 'DEALS_AVERAGE_SELL_RATE_ASC',
  DealsAverageSellRateDesc = 'DEALS_AVERAGE_SELL_RATE_DESC',
  DealsAverageBuyRateAsc = 'DEALS_AVERAGE_BUY_RATE_ASC',
  DealsAverageBuyRateDesc = 'DEALS_AVERAGE_BUY_RATE_DESC',
  DealsAverageResidualRateAsc = 'DEALS_AVERAGE_RESIDUAL_RATE_ASC',
  DealsAverageResidualRateDesc = 'DEALS_AVERAGE_RESIDUAL_RATE_DESC',
  DealsAverageResidualAmountAsc = 'DEALS_AVERAGE_RESIDUAL_AMOUNT_ASC',
  DealsAverageResidualAmountDesc = 'DEALS_AVERAGE_RESIDUAL_AMOUNT_DESC',
  DealsAverageAllowedMilesAsc = 'DEALS_AVERAGE_ALLOWED_MILES_ASC',
  DealsAverageAllowedMilesDesc = 'DEALS_AVERAGE_ALLOWED_MILES_DESC',
  DealsAverageEstimatedMilesAsc = 'DEALS_AVERAGE_ESTIMATED_MILES_ASC',
  DealsAverageEstimatedMilesDesc = 'DEALS_AVERAGE_ESTIMATED_MILES_DESC',
  DealsAverageMileageRateAsc = 'DEALS_AVERAGE_MILEAGE_RATE_ASC',
  DealsAverageMileageRateDesc = 'DEALS_AVERAGE_MILEAGE_RATE_DESC',
  DealsAverageAcquisitionFeeAsc = 'DEALS_AVERAGE_ACQUISITION_FEE_ASC',
  DealsAverageAcquisitionFeeDesc = 'DEALS_AVERAGE_ACQUISITION_FEE_DESC',
  DealsAverageBasePaymentAsc = 'DEALS_AVERAGE_BASE_PAYMENT_ASC',
  DealsAverageBasePaymentDesc = 'DEALS_AVERAGE_BASE_PAYMENT_DESC',
  DealsAverageSecurityDepositAsc = 'DEALS_AVERAGE_SECURITY_DEPOSIT_ASC',
  DealsAverageSecurityDepositDesc = 'DEALS_AVERAGE_SECURITY_DEPOSIT_DESC',
  DealsAverageTotalCapitalReductionAsc = 'DEALS_AVERAGE_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsAverageTotalCapitalReductionDesc = 'DEALS_AVERAGE_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsAverageNetCapitalCostAsc = 'DEALS_AVERAGE_NET_CAPITAL_COST_ASC',
  DealsAverageNetCapitalCostDesc = 'DEALS_AVERAGE_NET_CAPITAL_COST_DESC',
  DealsAverageLeaseDepreciationValueAsc = 'DEALS_AVERAGE_LEASE_DEPRECIATION_VALUE_ASC',
  DealsAverageLeaseDepreciationValueDesc = 'DEALS_AVERAGE_LEASE_DEPRECIATION_VALUE_DESC',
  DealsAverageDealerFeesAsc = 'DEALS_AVERAGE_DEALER_FEES_ASC',
  DealsAverageDealerFeesDesc = 'DEALS_AVERAGE_DEALER_FEES_DESC',
  DealsAverageGovernmentFeesAsc = 'DEALS_AVERAGE_GOVERNMENT_FEES_ASC',
  DealsAverageGovernmentFeesDesc = 'DEALS_AVERAGE_GOVERNMENT_FEES_DESC',
  DealsAverageTotalTaxAsc = 'DEALS_AVERAGE_TOTAL_TAX_ASC',
  DealsAverageTotalTaxDesc = 'DEALS_AVERAGE_TOTAL_TAX_DESC',
  DealsAverageRegistrationStateAsc = 'DEALS_AVERAGE_REGISTRATION_STATE_ASC',
  DealsAverageRegistrationStateDesc = 'DEALS_AVERAGE_REGISTRATION_STATE_DESC',
  DealsAverageReportOfSaleNumberAsc = 'DEALS_AVERAGE_REPORT_OF_SALE_NUMBER_ASC',
  DealsAverageReportOfSaleNumberDesc = 'DEALS_AVERAGE_REPORT_OF_SALE_NUMBER_DESC',
  DealsAverageSalesman_1TotalCommissionAsc = 'DEALS_AVERAGE_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsAverageSalesman_1TotalCommissionDesc = 'DEALS_AVERAGE_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsAverageSalesman_1FrontCommissionAsc = 'DEALS_AVERAGE_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsAverageSalesman_1FrontCommissionDesc = 'DEALS_AVERAGE_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsAverageSalesman_1BackCommissionAsc = 'DEALS_AVERAGE_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsAverageSalesman_1BackCommissionDesc = 'DEALS_AVERAGE_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsAverageSalesman_2TotalCommissionAsc = 'DEALS_AVERAGE_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsAverageSalesman_2TotalCommissionDesc = 'DEALS_AVERAGE_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsAverageSalesman_2FrontCommissionAsc = 'DEALS_AVERAGE_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsAverageSalesman_2FrontCommissionDesc = 'DEALS_AVERAGE_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsAverageSalesman_2BackCommissionAsc = 'DEALS_AVERAGE_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsAverageSalesman_2BackCommissionDesc = 'DEALS_AVERAGE_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsAverageSalesman_3TotalCommissionAsc = 'DEALS_AVERAGE_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsAverageSalesman_3TotalCommissionDesc = 'DEALS_AVERAGE_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsAverageSalesman_3FrontCommissionAsc = 'DEALS_AVERAGE_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsAverageSalesman_3FrontCommissionDesc = 'DEALS_AVERAGE_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsAverageSalesman_3BackCommissionAsc = 'DEALS_AVERAGE_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsAverageSalesman_3BackCommissionDesc = 'DEALS_AVERAGE_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsAverageWarranty_1NameAsc = 'DEALS_AVERAGE_WARRANTY_1_NAME_ASC',
  DealsAverageWarranty_1NameDesc = 'DEALS_AVERAGE_WARRANTY_1_NAME_DESC',
  DealsAverageWarranty_1SaleAsc = 'DEALS_AVERAGE_WARRANTY_1_SALE_ASC',
  DealsAverageWarranty_1SaleDesc = 'DEALS_AVERAGE_WARRANTY_1_SALE_DESC',
  DealsAverageWarranty_1CostAsc = 'DEALS_AVERAGE_WARRANTY_1_COST_ASC',
  DealsAverageWarranty_1CostDesc = 'DEALS_AVERAGE_WARRANTY_1_COST_DESC',
  DealsAverageWarranty_1MilesAsc = 'DEALS_AVERAGE_WARRANTY_1_MILES_ASC',
  DealsAverageWarranty_1MilesDesc = 'DEALS_AVERAGE_WARRANTY_1_MILES_DESC',
  DealsAverageWarranty_1TermAsc = 'DEALS_AVERAGE_WARRANTY_1_TERM_ASC',
  DealsAverageWarranty_1TermDesc = 'DEALS_AVERAGE_WARRANTY_1_TERM_DESC',
  DealsAverageWarranty_2NameAsc = 'DEALS_AVERAGE_WARRANTY_2_NAME_ASC',
  DealsAverageWarranty_2NameDesc = 'DEALS_AVERAGE_WARRANTY_2_NAME_DESC',
  DealsAverageWarranty_2SaleAsc = 'DEALS_AVERAGE_WARRANTY_2_SALE_ASC',
  DealsAverageWarranty_2SaleDesc = 'DEALS_AVERAGE_WARRANTY_2_SALE_DESC',
  DealsAverageWarranty_2CostAsc = 'DEALS_AVERAGE_WARRANTY_2_COST_ASC',
  DealsAverageWarranty_2CostDesc = 'DEALS_AVERAGE_WARRANTY_2_COST_DESC',
  DealsAverageWarranty_2MilesAsc = 'DEALS_AVERAGE_WARRANTY_2_MILES_ASC',
  DealsAverageWarranty_2MilesDesc = 'DEALS_AVERAGE_WARRANTY_2_MILES_DESC',
  DealsAverageWarranty_2TermAsc = 'DEALS_AVERAGE_WARRANTY_2_TERM_ASC',
  DealsAverageWarranty_2TermDesc = 'DEALS_AVERAGE_WARRANTY_2_TERM_DESC',
  DealsAverageWarranty_3NameAsc = 'DEALS_AVERAGE_WARRANTY_3_NAME_ASC',
  DealsAverageWarranty_3NameDesc = 'DEALS_AVERAGE_WARRANTY_3_NAME_DESC',
  DealsAverageWarranty_3SaleAsc = 'DEALS_AVERAGE_WARRANTY_3_SALE_ASC',
  DealsAverageWarranty_3SaleDesc = 'DEALS_AVERAGE_WARRANTY_3_SALE_DESC',
  DealsAverageWarranty_3CostAsc = 'DEALS_AVERAGE_WARRANTY_3_COST_ASC',
  DealsAverageWarranty_3CostDesc = 'DEALS_AVERAGE_WARRANTY_3_COST_DESC',
  DealsAverageWarranty_3MilesAsc = 'DEALS_AVERAGE_WARRANTY_3_MILES_ASC',
  DealsAverageWarranty_3MilesDesc = 'DEALS_AVERAGE_WARRANTY_3_MILES_DESC',
  DealsAverageWarranty_3TermAsc = 'DEALS_AVERAGE_WARRANTY_3_TERM_ASC',
  DealsAverageWarranty_3TermDesc = 'DEALS_AVERAGE_WARRANTY_3_TERM_DESC',
  DealsAverageWarranty_4NameAsc = 'DEALS_AVERAGE_WARRANTY_4_NAME_ASC',
  DealsAverageWarranty_4NameDesc = 'DEALS_AVERAGE_WARRANTY_4_NAME_DESC',
  DealsAverageWarranty_4SaleAsc = 'DEALS_AVERAGE_WARRANTY_4_SALE_ASC',
  DealsAverageWarranty_4SaleDesc = 'DEALS_AVERAGE_WARRANTY_4_SALE_DESC',
  DealsAverageWarranty_4CostAsc = 'DEALS_AVERAGE_WARRANTY_4_COST_ASC',
  DealsAverageWarranty_4CostDesc = 'DEALS_AVERAGE_WARRANTY_4_COST_DESC',
  DealsAverageWarranty_4MilesAsc = 'DEALS_AVERAGE_WARRANTY_4_MILES_ASC',
  DealsAverageWarranty_4MilesDesc = 'DEALS_AVERAGE_WARRANTY_4_MILES_DESC',
  DealsAverageWarranty_4TermAsc = 'DEALS_AVERAGE_WARRANTY_4_TERM_ASC',
  DealsAverageWarranty_4TermDesc = 'DEALS_AVERAGE_WARRANTY_4_TERM_DESC',
  DealsAverageWarranty_5NameAsc = 'DEALS_AVERAGE_WARRANTY_5_NAME_ASC',
  DealsAverageWarranty_5NameDesc = 'DEALS_AVERAGE_WARRANTY_5_NAME_DESC',
  DealsAverageWarranty_5SaleAsc = 'DEALS_AVERAGE_WARRANTY_5_SALE_ASC',
  DealsAverageWarranty_5SaleDesc = 'DEALS_AVERAGE_WARRANTY_5_SALE_DESC',
  DealsAverageWarranty_5CostAsc = 'DEALS_AVERAGE_WARRANTY_5_COST_ASC',
  DealsAverageWarranty_5CostDesc = 'DEALS_AVERAGE_WARRANTY_5_COST_DESC',
  DealsAverageWarranty_5MilesAsc = 'DEALS_AVERAGE_WARRANTY_5_MILES_ASC',
  DealsAverageWarranty_5MilesDesc = 'DEALS_AVERAGE_WARRANTY_5_MILES_DESC',
  DealsAverageWarranty_5TermAsc = 'DEALS_AVERAGE_WARRANTY_5_TERM_ASC',
  DealsAverageWarranty_5TermDesc = 'DEALS_AVERAGE_WARRANTY_5_TERM_DESC',
  DealsAverageTotalFeeAftermarketSaleAsc = 'DEALS_AVERAGE_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsAverageTotalFeeAftermarketSaleDesc = 'DEALS_AVERAGE_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsAverageTotalFeeAftermarketCostAsc = 'DEALS_AVERAGE_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsAverageTotalFeeAftermarketCostDesc = 'DEALS_AVERAGE_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsAverageFeeAftermarket_1NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_NAME_ASC',
  DealsAverageFeeAftermarket_1NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_NAME_DESC',
  DealsAverageFeeAftermarket_1SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_SALE_ASC',
  DealsAverageFeeAftermarket_1SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_SALE_DESC',
  DealsAverageFeeAftermarket_1CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_COST_ASC',
  DealsAverageFeeAftermarket_1CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_COST_DESC',
  DealsAverageFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_2NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_NAME_ASC',
  DealsAverageFeeAftermarket_2NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_NAME_DESC',
  DealsAverageFeeAftermarket_2SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_SALE_ASC',
  DealsAverageFeeAftermarket_2SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_SALE_DESC',
  DealsAverageFeeAftermarket_2CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_COST_ASC',
  DealsAverageFeeAftermarket_2CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_COST_DESC',
  DealsAverageFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_3NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_NAME_ASC',
  DealsAverageFeeAftermarket_3NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_NAME_DESC',
  DealsAverageFeeAftermarket_3SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_SALE_ASC',
  DealsAverageFeeAftermarket_3SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_SALE_DESC',
  DealsAverageFeeAftermarket_3CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_COST_ASC',
  DealsAverageFeeAftermarket_3CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_COST_DESC',
  DealsAverageFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_4NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_NAME_ASC',
  DealsAverageFeeAftermarket_4NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_NAME_DESC',
  DealsAverageFeeAftermarket_4SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_SALE_ASC',
  DealsAverageFeeAftermarket_4SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_SALE_DESC',
  DealsAverageFeeAftermarket_4CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_COST_ASC',
  DealsAverageFeeAftermarket_4CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_COST_DESC',
  DealsAverageFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_5NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_NAME_ASC',
  DealsAverageFeeAftermarket_5NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_NAME_DESC',
  DealsAverageFeeAftermarket_5SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_SALE_ASC',
  DealsAverageFeeAftermarket_5SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_SALE_DESC',
  DealsAverageFeeAftermarket_5CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_COST_ASC',
  DealsAverageFeeAftermarket_5CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_COST_DESC',
  DealsAverageFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_6NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_NAME_ASC',
  DealsAverageFeeAftermarket_6NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_NAME_DESC',
  DealsAverageFeeAftermarket_6SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_SALE_ASC',
  DealsAverageFeeAftermarket_6SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_SALE_DESC',
  DealsAverageFeeAftermarket_6CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_COST_ASC',
  DealsAverageFeeAftermarket_6CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_COST_DESC',
  DealsAverageFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_7NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_NAME_ASC',
  DealsAverageFeeAftermarket_7NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_NAME_DESC',
  DealsAverageFeeAftermarket_7SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_SALE_ASC',
  DealsAverageFeeAftermarket_7SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_SALE_DESC',
  DealsAverageFeeAftermarket_7CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_COST_ASC',
  DealsAverageFeeAftermarket_7CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_COST_DESC',
  DealsAverageFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_8NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_NAME_ASC',
  DealsAverageFeeAftermarket_8NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_NAME_DESC',
  DealsAverageFeeAftermarket_8SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_SALE_ASC',
  DealsAverageFeeAftermarket_8SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_SALE_DESC',
  DealsAverageFeeAftermarket_8CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_COST_ASC',
  DealsAverageFeeAftermarket_8CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_COST_DESC',
  DealsAverageFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_9NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_NAME_ASC',
  DealsAverageFeeAftermarket_9NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_NAME_DESC',
  DealsAverageFeeAftermarket_9SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_SALE_ASC',
  DealsAverageFeeAftermarket_9SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_SALE_DESC',
  DealsAverageFeeAftermarket_9CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_COST_ASC',
  DealsAverageFeeAftermarket_9CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_COST_DESC',
  DealsAverageFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_10NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_NAME_ASC',
  DealsAverageFeeAftermarket_10NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_NAME_DESC',
  DealsAverageFeeAftermarket_10SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_SALE_ASC',
  DealsAverageFeeAftermarket_10SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_SALE_DESC',
  DealsAverageFeeAftermarket_10CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_COST_ASC',
  DealsAverageFeeAftermarket_10CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_COST_DESC',
  DealsAverageFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_11NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_NAME_ASC',
  DealsAverageFeeAftermarket_11NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_NAME_DESC',
  DealsAverageFeeAftermarket_11SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_SALE_ASC',
  DealsAverageFeeAftermarket_11SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_SALE_DESC',
  DealsAverageFeeAftermarket_11CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_COST_ASC',
  DealsAverageFeeAftermarket_11CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_COST_DESC',
  DealsAverageFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_12NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_NAME_ASC',
  DealsAverageFeeAftermarket_12NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_NAME_DESC',
  DealsAverageFeeAftermarket_12SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_SALE_ASC',
  DealsAverageFeeAftermarket_12SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_SALE_DESC',
  DealsAverageFeeAftermarket_12CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_COST_ASC',
  DealsAverageFeeAftermarket_12CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_COST_DESC',
  DealsAverageFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_13NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_NAME_ASC',
  DealsAverageFeeAftermarket_13NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_NAME_DESC',
  DealsAverageFeeAftermarket_13SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_SALE_ASC',
  DealsAverageFeeAftermarket_13SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_SALE_DESC',
  DealsAverageFeeAftermarket_13CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_COST_ASC',
  DealsAverageFeeAftermarket_13CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_COST_DESC',
  DealsAverageFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_14NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_NAME_ASC',
  DealsAverageFeeAftermarket_14NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_NAME_DESC',
  DealsAverageFeeAftermarket_14SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_SALE_ASC',
  DealsAverageFeeAftermarket_14SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_SALE_DESC',
  DealsAverageFeeAftermarket_14CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_COST_ASC',
  DealsAverageFeeAftermarket_14CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_COST_DESC',
  DealsAverageFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_15NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_NAME_ASC',
  DealsAverageFeeAftermarket_15NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_NAME_DESC',
  DealsAverageFeeAftermarket_15SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_SALE_ASC',
  DealsAverageFeeAftermarket_15SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_SALE_DESC',
  DealsAverageFeeAftermarket_15CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_COST_ASC',
  DealsAverageFeeAftermarket_15CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_COST_DESC',
  DealsAverageFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_16NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_NAME_ASC',
  DealsAverageFeeAftermarket_16NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_NAME_DESC',
  DealsAverageFeeAftermarket_16SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_SALE_ASC',
  DealsAverageFeeAftermarket_16SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_SALE_DESC',
  DealsAverageFeeAftermarket_16CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_COST_ASC',
  DealsAverageFeeAftermarket_16CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_COST_DESC',
  DealsAverageFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_17NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_NAME_ASC',
  DealsAverageFeeAftermarket_17NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_NAME_DESC',
  DealsAverageFeeAftermarket_17SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_SALE_ASC',
  DealsAverageFeeAftermarket_17SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_SALE_DESC',
  DealsAverageFeeAftermarket_17CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_COST_ASC',
  DealsAverageFeeAftermarket_17CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_COST_DESC',
  DealsAverageFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_18NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_NAME_ASC',
  DealsAverageFeeAftermarket_18NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_NAME_DESC',
  DealsAverageFeeAftermarket_18SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_SALE_ASC',
  DealsAverageFeeAftermarket_18SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_SALE_DESC',
  DealsAverageFeeAftermarket_18CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_COST_ASC',
  DealsAverageFeeAftermarket_18CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_COST_DESC',
  DealsAverageFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_19NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_NAME_ASC',
  DealsAverageFeeAftermarket_19NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_NAME_DESC',
  DealsAverageFeeAftermarket_19SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_SALE_ASC',
  DealsAverageFeeAftermarket_19SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_SALE_DESC',
  DealsAverageFeeAftermarket_19CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_COST_ASC',
  DealsAverageFeeAftermarket_19CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_COST_DESC',
  DealsAverageFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsAverageFeeAftermarket_20NameAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_NAME_ASC',
  DealsAverageFeeAftermarket_20NameDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_NAME_DESC',
  DealsAverageFeeAftermarket_20SaleAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_SALE_ASC',
  DealsAverageFeeAftermarket_20SaleDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_SALE_DESC',
  DealsAverageFeeAftermarket_20CostAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_COST_ASC',
  DealsAverageFeeAftermarket_20CostDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_COST_DESC',
  DealsAverageFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsAverageFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_AVERAGE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsAverageInsurance_1TypeAsc = 'DEALS_AVERAGE_INSURANCE_1_TYPE_ASC',
  DealsAverageInsurance_1TypeDesc = 'DEALS_AVERAGE_INSURANCE_1_TYPE_DESC',
  DealsAverageInsurance_1NameAsc = 'DEALS_AVERAGE_INSURANCE_1_NAME_ASC',
  DealsAverageInsurance_1NameDesc = 'DEALS_AVERAGE_INSURANCE_1_NAME_DESC',
  DealsAverageInsurance_1SaleAsc = 'DEALS_AVERAGE_INSURANCE_1_SALE_ASC',
  DealsAverageInsurance_1SaleDesc = 'DEALS_AVERAGE_INSURANCE_1_SALE_DESC',
  DealsAverageInsurance_1CostAsc = 'DEALS_AVERAGE_INSURANCE_1_COST_ASC',
  DealsAverageInsurance_1CostDesc = 'DEALS_AVERAGE_INSURANCE_1_COST_DESC',
  DealsAverageInsurance_1TermAsc = 'DEALS_AVERAGE_INSURANCE_1_TERM_ASC',
  DealsAverageInsurance_1TermDesc = 'DEALS_AVERAGE_INSURANCE_1_TERM_DESC',
  DealsAverageInsurance_2TypeAsc = 'DEALS_AVERAGE_INSURANCE_2_TYPE_ASC',
  DealsAverageInsurance_2TypeDesc = 'DEALS_AVERAGE_INSURANCE_2_TYPE_DESC',
  DealsAverageInsurance_2NameAsc = 'DEALS_AVERAGE_INSURANCE_2_NAME_ASC',
  DealsAverageInsurance_2NameDesc = 'DEALS_AVERAGE_INSURANCE_2_NAME_DESC',
  DealsAverageInsurance_2SaleAsc = 'DEALS_AVERAGE_INSURANCE_2_SALE_ASC',
  DealsAverageInsurance_2SaleDesc = 'DEALS_AVERAGE_INSURANCE_2_SALE_DESC',
  DealsAverageInsurance_2CostAsc = 'DEALS_AVERAGE_INSURANCE_2_COST_ASC',
  DealsAverageInsurance_2CostDesc = 'DEALS_AVERAGE_INSURANCE_2_COST_DESC',
  DealsAverageInsurance_2TermAsc = 'DEALS_AVERAGE_INSURANCE_2_TERM_ASC',
  DealsAverageInsurance_2TermDesc = 'DEALS_AVERAGE_INSURANCE_2_TERM_DESC',
  DealsAverageInsurance_3TypeAsc = 'DEALS_AVERAGE_INSURANCE_3_TYPE_ASC',
  DealsAverageInsurance_3TypeDesc = 'DEALS_AVERAGE_INSURANCE_3_TYPE_DESC',
  DealsAverageInsurance_3NameAsc = 'DEALS_AVERAGE_INSURANCE_3_NAME_ASC',
  DealsAverageInsurance_3NameDesc = 'DEALS_AVERAGE_INSURANCE_3_NAME_DESC',
  DealsAverageInsurance_3SaleAsc = 'DEALS_AVERAGE_INSURANCE_3_SALE_ASC',
  DealsAverageInsurance_3SaleDesc = 'DEALS_AVERAGE_INSURANCE_3_SALE_DESC',
  DealsAverageInsurance_3CostAsc = 'DEALS_AVERAGE_INSURANCE_3_COST_ASC',
  DealsAverageInsurance_3CostDesc = 'DEALS_AVERAGE_INSURANCE_3_COST_DESC',
  DealsAverageInsurance_3TermAsc = 'DEALS_AVERAGE_INSURANCE_3_TERM_ASC',
  DealsAverageInsurance_3TermDesc = 'DEALS_AVERAGE_INSURANCE_3_TERM_DESC',
  DealsAverageAccidentalHealthTypeAsc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsAverageAccidentalHealthTypeDesc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsAverageAccidentalHealthNameAsc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsAverageAccidentalHealthNameDesc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsAverageAccidentalHealthSaleAsc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsAverageAccidentalHealthSaleDesc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsAverageAccidentalHealthCostAsc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_COST_ASC',
  DealsAverageAccidentalHealthCostDesc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_COST_DESC',
  DealsAverageAccidentalHealthTermAsc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsAverageAccidentalHealthTermDesc = 'DEALS_AVERAGE_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsAverageCreditLifeTypeAsc = 'DEALS_AVERAGE_CREDIT_LIFE_TYPE_ASC',
  DealsAverageCreditLifeTypeDesc = 'DEALS_AVERAGE_CREDIT_LIFE_TYPE_DESC',
  DealsAverageCreditLifeNameAsc = 'DEALS_AVERAGE_CREDIT_LIFE_NAME_ASC',
  DealsAverageCreditLifeNameDesc = 'DEALS_AVERAGE_CREDIT_LIFE_NAME_DESC',
  DealsAverageCreditLifeSaleAsc = 'DEALS_AVERAGE_CREDIT_LIFE_SALE_ASC',
  DealsAverageCreditLifeSaleDesc = 'DEALS_AVERAGE_CREDIT_LIFE_SALE_DESC',
  DealsAverageCreditLifeCostAsc = 'DEALS_AVERAGE_CREDIT_LIFE_COST_ASC',
  DealsAverageCreditLifeCostDesc = 'DEALS_AVERAGE_CREDIT_LIFE_COST_DESC',
  DealsAverageCreditLifeTermAsc = 'DEALS_AVERAGE_CREDIT_LIFE_TERM_ASC',
  DealsAverageCreditLifeTermDesc = 'DEALS_AVERAGE_CREDIT_LIFE_TERM_DESC',
  DealsAverageLevelizedLifeTypeAsc = 'DEALS_AVERAGE_LEVELIZED_LIFE_TYPE_ASC',
  DealsAverageLevelizedLifeTypeDesc = 'DEALS_AVERAGE_LEVELIZED_LIFE_TYPE_DESC',
  DealsAverageLevelizedLifeNameAsc = 'DEALS_AVERAGE_LEVELIZED_LIFE_NAME_ASC',
  DealsAverageLevelizedLifeNameDesc = 'DEALS_AVERAGE_LEVELIZED_LIFE_NAME_DESC',
  DealsAverageLevelizedLifeSaleAsc = 'DEALS_AVERAGE_LEVELIZED_LIFE_SALE_ASC',
  DealsAverageLevelizedLifeSaleDesc = 'DEALS_AVERAGE_LEVELIZED_LIFE_SALE_DESC',
  DealsAverageLevelizedLifeCostAsc = 'DEALS_AVERAGE_LEVELIZED_LIFE_COST_ASC',
  DealsAverageLevelizedLifeCostDesc = 'DEALS_AVERAGE_LEVELIZED_LIFE_COST_DESC',
  DealsAverageLevelizedLifeTermAsc = 'DEALS_AVERAGE_LEVELIZED_LIFE_TERM_ASC',
  DealsAverageLevelizedLifeTermDesc = 'DEALS_AVERAGE_LEVELIZED_LIFE_TERM_DESC',
  DealsAverageLossOfEmploymentTypeAsc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsAverageLossOfEmploymentTypeDesc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsAverageLossOfEmploymentNameAsc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsAverageLossOfEmploymentNameDesc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsAverageLossOfEmploymentSaleAsc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsAverageLossOfEmploymentSaleDesc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsAverageLossOfEmploymentCostAsc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsAverageLossOfEmploymentCostDesc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsAverageLossOfEmploymentTermAsc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsAverageLossOfEmploymentTermDesc = 'DEALS_AVERAGE_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsAverageGuaranteedAutoProtectionTypeAsc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsAverageGuaranteedAutoProtectionTypeDesc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsAverageGuaranteedAutoProtectionNameAsc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsAverageGuaranteedAutoProtectionNameDesc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsAverageGuaranteedAutoProtectionSaleAsc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsAverageGuaranteedAutoProtectionSaleDesc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsAverageGuaranteedAutoProtectionCostAsc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsAverageGuaranteedAutoProtectionCostDesc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsAverageGuaranteedAutoProtectionTermAsc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsAverageGuaranteedAutoProtectionTermDesc = 'DEALS_AVERAGE_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsAverageSaleCommentsAsc = 'DEALS_AVERAGE_SALE_COMMENTS_ASC',
  DealsAverageSaleCommentsDesc = 'DEALS_AVERAGE_SALE_COMMENTS_DESC',
  DealsAverageCassStdLine1Asc = 'DEALS_AVERAGE_CASS_STD_LINE1_ASC',
  DealsAverageCassStdLine1Desc = 'DEALS_AVERAGE_CASS_STD_LINE1_DESC',
  DealsAverageCassStdLine2Asc = 'DEALS_AVERAGE_CASS_STD_LINE2_ASC',
  DealsAverageCassStdLine2Desc = 'DEALS_AVERAGE_CASS_STD_LINE2_DESC',
  DealsAverageCassStdCityAsc = 'DEALS_AVERAGE_CASS_STD_CITY_ASC',
  DealsAverageCassStdCityDesc = 'DEALS_AVERAGE_CASS_STD_CITY_DESC',
  DealsAverageCassStdStateAsc = 'DEALS_AVERAGE_CASS_STD_STATE_ASC',
  DealsAverageCassStdStateDesc = 'DEALS_AVERAGE_CASS_STD_STATE_DESC',
  DealsAverageCassStdZipAsc = 'DEALS_AVERAGE_CASS_STD_ZIP_ASC',
  DealsAverageCassStdZipDesc = 'DEALS_AVERAGE_CASS_STD_ZIP_DESC',
  DealsAverageCassStdZip4Asc = 'DEALS_AVERAGE_CASS_STD_ZIP4_ASC',
  DealsAverageCassStdZip4Desc = 'DEALS_AVERAGE_CASS_STD_ZIP4_DESC',
  DealsAverageCassStdDpbcAsc = 'DEALS_AVERAGE_CASS_STD_DPBC_ASC',
  DealsAverageCassStdDpbcDesc = 'DEALS_AVERAGE_CASS_STD_DPBC_DESC',
  DealsAverageCassStdChkdgtAsc = 'DEALS_AVERAGE_CASS_STD_CHKDGT_ASC',
  DealsAverageCassStdChkdgtDesc = 'DEALS_AVERAGE_CASS_STD_CHKDGT_DESC',
  DealsAverageCassStdCartAsc = 'DEALS_AVERAGE_CASS_STD_CART_ASC',
  DealsAverageCassStdCartDesc = 'DEALS_AVERAGE_CASS_STD_CART_DESC',
  DealsAverageCassStdLotAsc = 'DEALS_AVERAGE_CASS_STD_LOT_ASC',
  DealsAverageCassStdLotDesc = 'DEALS_AVERAGE_CASS_STD_LOT_DESC',
  DealsAverageCassStdLotordAsc = 'DEALS_AVERAGE_CASS_STD_LOTORD_ASC',
  DealsAverageCassStdLotordDesc = 'DEALS_AVERAGE_CASS_STD_LOTORD_DESC',
  DealsAverageCassStdUrbAsc = 'DEALS_AVERAGE_CASS_STD_URB_ASC',
  DealsAverageCassStdUrbDesc = 'DEALS_AVERAGE_CASS_STD_URB_DESC',
  DealsAverageCassStdFipsAsc = 'DEALS_AVERAGE_CASS_STD_FIPS_ASC',
  DealsAverageCassStdFipsDesc = 'DEALS_AVERAGE_CASS_STD_FIPS_DESC',
  DealsAverageCassStdEwsAsc = 'DEALS_AVERAGE_CASS_STD_EWS_ASC',
  DealsAverageCassStdEwsDesc = 'DEALS_AVERAGE_CASS_STD_EWS_DESC',
  DealsAverageCassStdLacsAsc = 'DEALS_AVERAGE_CASS_STD_LACS_ASC',
  DealsAverageCassStdLacsDesc = 'DEALS_AVERAGE_CASS_STD_LACS_DESC',
  DealsAverageCassStdZipmovAsc = 'DEALS_AVERAGE_CASS_STD_ZIPMOV_ASC',
  DealsAverageCassStdZipmovDesc = 'DEALS_AVERAGE_CASS_STD_ZIPMOV_DESC',
  DealsAverageCassStdZ4LomAsc = 'DEALS_AVERAGE_CASS_STD_Z4LOM_ASC',
  DealsAverageCassStdZ4LomDesc = 'DEALS_AVERAGE_CASS_STD_Z4LOM_DESC',
  DealsAverageCassStdNdiaptAsc = 'DEALS_AVERAGE_CASS_STD_NDIAPT_ASC',
  DealsAverageCassStdNdiaptDesc = 'DEALS_AVERAGE_CASS_STD_NDIAPT_DESC',
  DealsAverageCassStdNdirrAsc = 'DEALS_AVERAGE_CASS_STD_NDIRR_ASC',
  DealsAverageCassStdNdirrDesc = 'DEALS_AVERAGE_CASS_STD_NDIRR_DESC',
  DealsAverageCassStdLacsrtAsc = 'DEALS_AVERAGE_CASS_STD_LACSRT_ASC',
  DealsAverageCassStdLacsrtDesc = 'DEALS_AVERAGE_CASS_STD_LACSRT_DESC',
  DealsAverageCassStdErrorCdAsc = 'DEALS_AVERAGE_CASS_STD_ERROR_CD_ASC',
  DealsAverageCassStdErrorCdDesc = 'DEALS_AVERAGE_CASS_STD_ERROR_CD_DESC',
  DealsStddevSampleIdAsc = 'DEALS_STDDEV_SAMPLE_ID_ASC',
  DealsStddevSampleIdDesc = 'DEALS_STDDEV_SAMPLE_ID_DESC',
  DealsStddevSampleLeadIdAsc = 'DEALS_STDDEV_SAMPLE_LEAD_ID_ASC',
  DealsStddevSampleLeadIdDesc = 'DEALS_STDDEV_SAMPLE_LEAD_ID_DESC',
  DealsStddevSampleCompanyIntegrationIdAsc = 'DEALS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  DealsStddevSampleCompanyIntegrationIdDesc = 'DEALS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  DealsStddevSampleFileTypeAsc = 'DEALS_STDDEV_SAMPLE_FILE_TYPE_ASC',
  DealsStddevSampleFileTypeDesc = 'DEALS_STDDEV_SAMPLE_FILE_TYPE_DESC',
  DealsStddevSampleDvDealerIdAsc = 'DEALS_STDDEV_SAMPLE_DV_DEALER_ID_ASC',
  DealsStddevSampleDvDealerIdDesc = 'DEALS_STDDEV_SAMPLE_DV_DEALER_ID_DESC',
  DealsStddevSampleVendorDealerIdAsc = 'DEALS_STDDEV_SAMPLE_VENDOR_DEALER_ID_ASC',
  DealsStddevSampleVendorDealerIdDesc = 'DEALS_STDDEV_SAMPLE_VENDOR_DEALER_ID_DESC',
  DealsStddevSampleDmsTypeAsc = 'DEALS_STDDEV_SAMPLE_DMS_TYPE_ASC',
  DealsStddevSampleDmsTypeDesc = 'DEALS_STDDEV_SAMPLE_DMS_TYPE_DESC',
  DealsStddevSampleDealNumberAsc = 'DEALS_STDDEV_SAMPLE_DEAL_NUMBER_ASC',
  DealsStddevSampleDealNumberDesc = 'DEALS_STDDEV_SAMPLE_DEAL_NUMBER_DESC',
  DealsStddevSampleCustomerNumberAsc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_NUMBER_ASC',
  DealsStddevSampleCustomerNumberDesc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_NUMBER_DESC',
  DealsStddevSampleFullNameAsc = 'DEALS_STDDEV_SAMPLE_FULL_NAME_ASC',
  DealsStddevSampleFullNameDesc = 'DEALS_STDDEV_SAMPLE_FULL_NAME_DESC',
  DealsStddevSampleSalutationAsc = 'DEALS_STDDEV_SAMPLE_SALUTATION_ASC',
  DealsStddevSampleSalutationDesc = 'DEALS_STDDEV_SAMPLE_SALUTATION_DESC',
  DealsStddevSampleFirstNameAsc = 'DEALS_STDDEV_SAMPLE_FIRST_NAME_ASC',
  DealsStddevSampleFirstNameDesc = 'DEALS_STDDEV_SAMPLE_FIRST_NAME_DESC',
  DealsStddevSampleMiddleNameAsc = 'DEALS_STDDEV_SAMPLE_MIDDLE_NAME_ASC',
  DealsStddevSampleMiddleNameDesc = 'DEALS_STDDEV_SAMPLE_MIDDLE_NAME_DESC',
  DealsStddevSampleLastNameAsc = 'DEALS_STDDEV_SAMPLE_LAST_NAME_ASC',
  DealsStddevSampleLastNameDesc = 'DEALS_STDDEV_SAMPLE_LAST_NAME_DESC',
  DealsStddevSampleSuffixAsc = 'DEALS_STDDEV_SAMPLE_SUFFIX_ASC',
  DealsStddevSampleSuffixDesc = 'DEALS_STDDEV_SAMPLE_SUFFIX_DESC',
  DealsStddevSampleAddressLine_1Asc = 'DEALS_STDDEV_SAMPLE_ADDRESS_LINE_1_ASC',
  DealsStddevSampleAddressLine_1Desc = 'DEALS_STDDEV_SAMPLE_ADDRESS_LINE_1_DESC',
  DealsStddevSampleAddressLine_2Asc = 'DEALS_STDDEV_SAMPLE_ADDRESS_LINE_2_ASC',
  DealsStddevSampleAddressLine_2Desc = 'DEALS_STDDEV_SAMPLE_ADDRESS_LINE_2_DESC',
  DealsStddevSampleCityAsc = 'DEALS_STDDEV_SAMPLE_CITY_ASC',
  DealsStddevSampleCityDesc = 'DEALS_STDDEV_SAMPLE_CITY_DESC',
  DealsStddevSampleStateAsc = 'DEALS_STDDEV_SAMPLE_STATE_ASC',
  DealsStddevSampleStateDesc = 'DEALS_STDDEV_SAMPLE_STATE_DESC',
  DealsStddevSampleZipAsc = 'DEALS_STDDEV_SAMPLE_ZIP_ASC',
  DealsStddevSampleZipDesc = 'DEALS_STDDEV_SAMPLE_ZIP_DESC',
  DealsStddevSampleCountyAsc = 'DEALS_STDDEV_SAMPLE_COUNTY_ASC',
  DealsStddevSampleCountyDesc = 'DEALS_STDDEV_SAMPLE_COUNTY_DESC',
  DealsStddevSampleHomePhoneAsc = 'DEALS_STDDEV_SAMPLE_HOME_PHONE_ASC',
  DealsStddevSampleHomePhoneDesc = 'DEALS_STDDEV_SAMPLE_HOME_PHONE_DESC',
  DealsStddevSampleCellPhoneAsc = 'DEALS_STDDEV_SAMPLE_CELL_PHONE_ASC',
  DealsStddevSampleCellPhoneDesc = 'DEALS_STDDEV_SAMPLE_CELL_PHONE_DESC',
  DealsStddevSampleWorkPhoneAsc = 'DEALS_STDDEV_SAMPLE_WORK_PHONE_ASC',
  DealsStddevSampleWorkPhoneDesc = 'DEALS_STDDEV_SAMPLE_WORK_PHONE_DESC',
  DealsStddevSampleWorkExtensionAsc = 'DEALS_STDDEV_SAMPLE_WORK_EXTENSION_ASC',
  DealsStddevSampleWorkExtensionDesc = 'DEALS_STDDEV_SAMPLE_WORK_EXTENSION_DESC',
  DealsStddevSampleEmail_1Asc = 'DEALS_STDDEV_SAMPLE_EMAIL_1_ASC',
  DealsStddevSampleEmail_1Desc = 'DEALS_STDDEV_SAMPLE_EMAIL_1_DESC',
  DealsStddevSampleEmail_2Asc = 'DEALS_STDDEV_SAMPLE_EMAIL_2_ASC',
  DealsStddevSampleEmail_2Desc = 'DEALS_STDDEV_SAMPLE_EMAIL_2_DESC',
  DealsStddevSampleEmail_3Asc = 'DEALS_STDDEV_SAMPLE_EMAIL_3_ASC',
  DealsStddevSampleEmail_3Desc = 'DEALS_STDDEV_SAMPLE_EMAIL_3_DESC',
  DealsStddevSampleBirthDateAsc = 'DEALS_STDDEV_SAMPLE_BIRTH_DATE_ASC',
  DealsStddevSampleBirthDateDesc = 'DEALS_STDDEV_SAMPLE_BIRTH_DATE_DESC',
  DealsStddevSampleIndividualBusinessFlagAsc = 'DEALS_STDDEV_SAMPLE_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsStddevSampleIndividualBusinessFlagDesc = 'DEALS_STDDEV_SAMPLE_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsStddevSampleOptOutAsc = 'DEALS_STDDEV_SAMPLE_OPT_OUT_ASC',
  DealsStddevSampleOptOutDesc = 'DEALS_STDDEV_SAMPLE_OPT_OUT_DESC',
  DealsStddevSampleBlockEmailAsc = 'DEALS_STDDEV_SAMPLE_BLOCK_EMAIL_ASC',
  DealsStddevSampleBlockEmailDesc = 'DEALS_STDDEV_SAMPLE_BLOCK_EMAIL_DESC',
  DealsStddevSampleBlockPhoneAsc = 'DEALS_STDDEV_SAMPLE_BLOCK_PHONE_ASC',
  DealsStddevSampleBlockPhoneDesc = 'DEALS_STDDEV_SAMPLE_BLOCK_PHONE_DESC',
  DealsStddevSampleBlockMailAsc = 'DEALS_STDDEV_SAMPLE_BLOCK_MAIL_ASC',
  DealsStddevSampleBlockMailDesc = 'DEALS_STDDEV_SAMPLE_BLOCK_MAIL_DESC',
  DealsStddevSampleLanguageAsc = 'DEALS_STDDEV_SAMPLE_LANGUAGE_ASC',
  DealsStddevSampleLanguageDesc = 'DEALS_STDDEV_SAMPLE_LANGUAGE_DESC',
  DealsStddevSampleCustomerCreateDateAsc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_CREATE_DATE_ASC',
  DealsStddevSampleCustomerCreateDateDesc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_CREATE_DATE_DESC',
  DealsStddevSampleCustomerLastActivityDateAsc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsStddevSampleCustomerLastActivityDateDesc = 'DEALS_STDDEV_SAMPLE_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsStddevSampleCoBuyerCustomerNumberAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsStddevSampleCoBuyerCustomerNumberDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsStddevSampleCoBuyerFullNameAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_FULL_NAME_ASC',
  DealsStddevSampleCoBuyerFullNameDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_FULL_NAME_DESC',
  DealsStddevSampleCoBuyerSalutationAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_SALUTATION_ASC',
  DealsStddevSampleCoBuyerSalutationDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_SALUTATION_DESC',
  DealsStddevSampleCoBuyerFirstNameAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_FIRST_NAME_ASC',
  DealsStddevSampleCoBuyerFirstNameDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_FIRST_NAME_DESC',
  DealsStddevSampleCoBuyerMiddleNameAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_MIDDLE_NAME_ASC',
  DealsStddevSampleCoBuyerMiddleNameDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_MIDDLE_NAME_DESC',
  DealsStddevSampleCoBuyerLastNameAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_LAST_NAME_ASC',
  DealsStddevSampleCoBuyerLastNameDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_LAST_NAME_DESC',
  DealsStddevSampleCoBuyerSuffixAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_SUFFIX_ASC',
  DealsStddevSampleCoBuyerSuffixDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_SUFFIX_DESC',
  DealsStddevSampleCoBuyerAddressLine_1Asc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsStddevSampleCoBuyerAddressLine_1Desc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsStddevSampleCoBuyerAddressLine_2Asc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsStddevSampleCoBuyerAddressLine_2Desc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsStddevSampleCoBuyerCityAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CITY_ASC',
  DealsStddevSampleCoBuyerCityDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CITY_DESC',
  DealsStddevSampleCoBuyerStateAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_STATE_ASC',
  DealsStddevSampleCoBuyerStateDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_STATE_DESC',
  DealsStddevSampleCoBuyerZipAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ZIP_ASC',
  DealsStddevSampleCoBuyerZipDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_ZIP_DESC',
  DealsStddevSampleCoBuyerCountyAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_COUNTY_ASC',
  DealsStddevSampleCoBuyerCountyDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_COUNTY_DESC',
  DealsStddevSampleCoBuyerHomePhoneAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_HOME_PHONE_ASC',
  DealsStddevSampleCoBuyerHomePhoneDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_HOME_PHONE_DESC',
  DealsStddevSampleCoBuyerCellPhoneAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CELL_PHONE_ASC',
  DealsStddevSampleCoBuyerCellPhoneDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_CELL_PHONE_DESC',
  DealsStddevSampleCoBuyerWorkPhoneAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_WORK_PHONE_ASC',
  DealsStddevSampleCoBuyerWorkPhoneDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_WORK_PHONE_DESC',
  DealsStddevSampleCoBuyerWorkExtensionAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_WORK_EXTENSION_ASC',
  DealsStddevSampleCoBuyerWorkExtensionDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_WORK_EXTENSION_DESC',
  DealsStddevSampleCoBuyerEmail_1Asc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_1_ASC',
  DealsStddevSampleCoBuyerEmail_1Desc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_1_DESC',
  DealsStddevSampleCoBuyerEmail_2Asc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_2_ASC',
  DealsStddevSampleCoBuyerEmail_2Desc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_2_DESC',
  DealsStddevSampleCoBuyerEmail_3Asc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_3_ASC',
  DealsStddevSampleCoBuyerEmail_3Desc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_EMAIL_3_DESC',
  DealsStddevSampleCoBuyerBirthDateAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BIRTH_DATE_ASC',
  DealsStddevSampleCoBuyerBirthDateDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BIRTH_DATE_DESC',
  DealsStddevSampleCoBuyerIndividualBusinessFlagAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsStddevSampleCoBuyerIndividualBusinessFlagDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsStddevSampleCoBuyerOptOutAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_OPT_OUT_ASC',
  DealsStddevSampleCoBuyerOptOutDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_OPT_OUT_DESC',
  DealsStddevSampleCoBuyerBlockEmailAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsStddevSampleCoBuyerBlockEmailDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsStddevSampleCoBuyerBlockPhoneAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_PHONE_ASC',
  DealsStddevSampleCoBuyerBlockPhoneDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_PHONE_DESC',
  DealsStddevSampleCoBuyerBlockMailAsc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_MAIL_ASC',
  DealsStddevSampleCoBuyerBlockMailDesc = 'DEALS_STDDEV_SAMPLE_CO_BUYER_BLOCK_MAIL_DESC',
  DealsStddevSampleVinAsc = 'DEALS_STDDEV_SAMPLE_VIN_ASC',
  DealsStddevSampleVinDesc = 'DEALS_STDDEV_SAMPLE_VIN_DESC',
  DealsStddevSampleYearAsc = 'DEALS_STDDEV_SAMPLE_YEAR_ASC',
  DealsStddevSampleYearDesc = 'DEALS_STDDEV_SAMPLE_YEAR_DESC',
  DealsStddevSampleMakeAsc = 'DEALS_STDDEV_SAMPLE_MAKE_ASC',
  DealsStddevSampleMakeDesc = 'DEALS_STDDEV_SAMPLE_MAKE_DESC',
  DealsStddevSampleModelAsc = 'DEALS_STDDEV_SAMPLE_MODEL_ASC',
  DealsStddevSampleModelDesc = 'DEALS_STDDEV_SAMPLE_MODEL_DESC',
  DealsStddevSampleModelNumberAsc = 'DEALS_STDDEV_SAMPLE_MODEL_NUMBER_ASC',
  DealsStddevSampleModelNumberDesc = 'DEALS_STDDEV_SAMPLE_MODEL_NUMBER_DESC',
  DealsStddevSampleMileageAsc = 'DEALS_STDDEV_SAMPLE_MILEAGE_ASC',
  DealsStddevSampleMileageDesc = 'DEALS_STDDEV_SAMPLE_MILEAGE_DESC',
  DealsStddevSampleDescriptionAsc = 'DEALS_STDDEV_SAMPLE_DESCRIPTION_ASC',
  DealsStddevSampleDescriptionDesc = 'DEALS_STDDEV_SAMPLE_DESCRIPTION_DESC',
  DealsStddevSampleExteriorColorAsc = 'DEALS_STDDEV_SAMPLE_EXTERIOR_COLOR_ASC',
  DealsStddevSampleExteriorColorDesc = 'DEALS_STDDEV_SAMPLE_EXTERIOR_COLOR_DESC',
  DealsStddevSampleNewUsedAsc = 'DEALS_STDDEV_SAMPLE_NEW_USED_ASC',
  DealsStddevSampleNewUsedDesc = 'DEALS_STDDEV_SAMPLE_NEW_USED_DESC',
  DealsStddevSampleStockNumberAsc = 'DEALS_STDDEV_SAMPLE_STOCK_NUMBER_ASC',
  DealsStddevSampleStockNumberDesc = 'DEALS_STDDEV_SAMPLE_STOCK_NUMBER_DESC',
  DealsStddevSampleTransmissionAsc = 'DEALS_STDDEV_SAMPLE_TRANSMISSION_ASC',
  DealsStddevSampleTransmissionDesc = 'DEALS_STDDEV_SAMPLE_TRANSMISSION_DESC',
  DealsStddevSampleEngineConfigurationAsc = 'DEALS_STDDEV_SAMPLE_ENGINE_CONFIGURATION_ASC',
  DealsStddevSampleEngineConfigurationDesc = 'DEALS_STDDEV_SAMPLE_ENGINE_CONFIGURATION_DESC',
  DealsStddevSampleTrimAsc = 'DEALS_STDDEV_SAMPLE_TRIM_ASC',
  DealsStddevSampleTrimDesc = 'DEALS_STDDEV_SAMPLE_TRIM_DESC',
  DealsStddevSampleEngineNumberAsc = 'DEALS_STDDEV_SAMPLE_ENGINE_NUMBER_ASC',
  DealsStddevSampleEngineNumberDesc = 'DEALS_STDDEV_SAMPLE_ENGINE_NUMBER_DESC',
  DealsStddevSampleChassisNumberAsc = 'DEALS_STDDEV_SAMPLE_CHASSIS_NUMBER_ASC',
  DealsStddevSampleChassisNumberDesc = 'DEALS_STDDEV_SAMPLE_CHASSIS_NUMBER_DESC',
  DealsStddevSampleLicensePlateNumberAsc = 'DEALS_STDDEV_SAMPLE_LICENSE_PLATE_NUMBER_ASC',
  DealsStddevSampleLicensePlateNumberDesc = 'DEALS_STDDEV_SAMPLE_LICENSE_PLATE_NUMBER_DESC',
  DealsStddevSampleDeliveryDateAsc = 'DEALS_STDDEV_SAMPLE_DELIVERY_DATE_ASC',
  DealsStddevSampleDeliveryDateDesc = 'DEALS_STDDEV_SAMPLE_DELIVERY_DATE_DESC',
  DealsStddevSampleDeliveryMileageAsc = 'DEALS_STDDEV_SAMPLE_DELIVERY_MILEAGE_ASC',
  DealsStddevSampleDeliveryMileageDesc = 'DEALS_STDDEV_SAMPLE_DELIVERY_MILEAGE_DESC',
  DealsStddevSampleInventoryDateAsc = 'DEALS_STDDEV_SAMPLE_INVENTORY_DATE_ASC',
  DealsStddevSampleInventoryDateDesc = 'DEALS_STDDEV_SAMPLE_INVENTORY_DATE_DESC',
  DealsStddevSampleInServiceDateAsc = 'DEALS_STDDEV_SAMPLE_IN_SERVICE_DATE_ASC',
  DealsStddevSampleInServiceDateDesc = 'DEALS_STDDEV_SAMPLE_IN_SERVICE_DATE_DESC',
  DealsStddevSampleVinExplosionYearAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_YEAR_ASC',
  DealsStddevSampleVinExplosionYearDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_YEAR_DESC',
  DealsStddevSampleVinExplosionMakeAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_MAKE_ASC',
  DealsStddevSampleVinExplosionMakeDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_MAKE_DESC',
  DealsStddevSampleVinExplosionModelAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_MODEL_ASC',
  DealsStddevSampleVinExplosionModelDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_MODEL_DESC',
  DealsStddevSampleVinExplosionTrimAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_TRIM_ASC',
  DealsStddevSampleVinExplosionTrimDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_TRIM_DESC',
  DealsStddevSampleVinExplosionTransmissionTypeAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsStddevSampleVinExplosionTransmissionTypeDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsStddevSampleVinExplosionFuelTypeAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsStddevSampleVinExplosionFuelTypeDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsStddevSampleVinExplosionEngineSizeAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsStddevSampleVinExplosionEngineSizeDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsStddevSampleVinExplosionGvwRangeAsc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsStddevSampleVinExplosionGvwRangeDesc = 'DEALS_STDDEV_SAMPLE_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsStddevSampleTrade_1VinAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_VIN_ASC',
  DealsStddevSampleTrade_1VinDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_VIN_DESC',
  DealsStddevSampleTrade_1YearAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_YEAR_ASC',
  DealsStddevSampleTrade_1YearDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_YEAR_DESC',
  DealsStddevSampleTrade_1MakeAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_MAKE_ASC',
  DealsStddevSampleTrade_1MakeDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_MAKE_DESC',
  DealsStddevSampleTrade_1ModelAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_MODEL_ASC',
  DealsStddevSampleTrade_1ModelDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_MODEL_DESC',
  DealsStddevSampleTrade_1OdometerAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_ODOMETER_ASC',
  DealsStddevSampleTrade_1OdometerDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_ODOMETER_DESC',
  DealsStddevSampleTrade_1ActualCashValueAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsStddevSampleTrade_1ActualCashValueDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsStddevSampleTrade_1GrossAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_GROSS_ASC',
  DealsStddevSampleTrade_1GrossDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_GROSS_DESC',
  DealsStddevSampleTrade_1PayoffAsc = 'DEALS_STDDEV_SAMPLE_TRADE_1_PAYOFF_ASC',
  DealsStddevSampleTrade_1PayoffDesc = 'DEALS_STDDEV_SAMPLE_TRADE_1_PAYOFF_DESC',
  DealsStddevSampleTrade_2VinAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_VIN_ASC',
  DealsStddevSampleTrade_2VinDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_VIN_DESC',
  DealsStddevSampleTrade_2YearAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_YEAR_ASC',
  DealsStddevSampleTrade_2YearDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_YEAR_DESC',
  DealsStddevSampleTrade_2MakeAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_MAKE_ASC',
  DealsStddevSampleTrade_2MakeDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_MAKE_DESC',
  DealsStddevSampleTrade_2ModelAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_MODEL_ASC',
  DealsStddevSampleTrade_2ModelDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_MODEL_DESC',
  DealsStddevSampleTrade_2OdometerAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_ODOMETER_ASC',
  DealsStddevSampleTrade_2OdometerDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_ODOMETER_DESC',
  DealsStddevSampleTrade_2ActualCashValueAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsStddevSampleTrade_2ActualCashValueDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsStddevSampleTrade_2GrossAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_GROSS_ASC',
  DealsStddevSampleTrade_2GrossDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_GROSS_DESC',
  DealsStddevSampleTrade_2PayoffAsc = 'DEALS_STDDEV_SAMPLE_TRADE_2_PAYOFF_ASC',
  DealsStddevSampleTrade_2PayoffDesc = 'DEALS_STDDEV_SAMPLE_TRADE_2_PAYOFF_DESC',
  DealsStddevSampleSalesman_1NumberAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_NUMBER_ASC',
  DealsStddevSampleSalesman_1NumberDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_NUMBER_DESC',
  DealsStddevSampleSalesman_1NameAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_NAME_ASC',
  DealsStddevSampleSalesman_1NameDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_NAME_DESC',
  DealsStddevSampleSalesman_2NumberAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_NUMBER_ASC',
  DealsStddevSampleSalesman_2NumberDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_NUMBER_DESC',
  DealsStddevSampleSalesman_2NameAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_NAME_ASC',
  DealsStddevSampleSalesman_2NameDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_NAME_DESC',
  DealsStddevSampleSalesman_3NumberAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_NUMBER_ASC',
  DealsStddevSampleSalesman_3NumberDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_NUMBER_DESC',
  DealsStddevSampleSalesman_3NameAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_NAME_ASC',
  DealsStddevSampleSalesman_3NameDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_NAME_DESC',
  DealsStddevSampleClosingManagerNumberAsc = 'DEALS_STDDEV_SAMPLE_CLOSING_MANAGER_NUMBER_ASC',
  DealsStddevSampleClosingManagerNumberDesc = 'DEALS_STDDEV_SAMPLE_CLOSING_MANAGER_NUMBER_DESC',
  DealsStddevSampleClosingManagerNameAsc = 'DEALS_STDDEV_SAMPLE_CLOSING_MANAGER_NAME_ASC',
  DealsStddevSampleClosingManagerNameDesc = 'DEALS_STDDEV_SAMPLE_CLOSING_MANAGER_NAME_DESC',
  DealsStddevSampleFinanceManagerNumberAsc = 'DEALS_STDDEV_SAMPLE_FINANCE_MANAGER_NUMBER_ASC',
  DealsStddevSampleFinanceManagerNumberDesc = 'DEALS_STDDEV_SAMPLE_FINANCE_MANAGER_NUMBER_DESC',
  DealsStddevSampleFinanceManagerNameAsc = 'DEALS_STDDEV_SAMPLE_FINANCE_MANAGER_NAME_ASC',
  DealsStddevSampleFinanceManagerNameDesc = 'DEALS_STDDEV_SAMPLE_FINANCE_MANAGER_NAME_DESC',
  DealsStddevSampleSalesmanManagerNumberAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_MANAGER_NUMBER_ASC',
  DealsStddevSampleSalesmanManagerNumberDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_MANAGER_NUMBER_DESC',
  DealsStddevSampleSalesmanManagerNameAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_MANAGER_NAME_ASC',
  DealsStddevSampleSalesmanManagerNameDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_MANAGER_NAME_DESC',
  DealsStddevSampleMsrpAsc = 'DEALS_STDDEV_SAMPLE_MSRP_ASC',
  DealsStddevSampleMsrpDesc = 'DEALS_STDDEV_SAMPLE_MSRP_DESC',
  DealsStddevSampleListPriceAsc = 'DEALS_STDDEV_SAMPLE_LIST_PRICE_ASC',
  DealsStddevSampleListPriceDesc = 'DEALS_STDDEV_SAMPLE_LIST_PRICE_DESC',
  DealsStddevSampleSalesPriceAsc = 'DEALS_STDDEV_SAMPLE_SALES_PRICE_ASC',
  DealsStddevSampleSalesPriceDesc = 'DEALS_STDDEV_SAMPLE_SALES_PRICE_DESC',
  DealsStddevSampleJournalPriceAsc = 'DEALS_STDDEV_SAMPLE_JOURNAL_PRICE_ASC',
  DealsStddevSampleJournalPriceDesc = 'DEALS_STDDEV_SAMPLE_JOURNAL_PRICE_DESC',
  DealsStddevSampleCostAsc = 'DEALS_STDDEV_SAMPLE_COST_ASC',
  DealsStddevSampleCostDesc = 'DEALS_STDDEV_SAMPLE_COST_DESC',
  DealsStddevSampleJournalCostAsc = 'DEALS_STDDEV_SAMPLE_JOURNAL_COST_ASC',
  DealsStddevSampleJournalCostDesc = 'DEALS_STDDEV_SAMPLE_JOURNAL_COST_DESC',
  DealsStddevSampleAdjustmentsAsc = 'DEALS_STDDEV_SAMPLE_ADJUSTMENTS_ASC',
  DealsStddevSampleAdjustmentsDesc = 'DEALS_STDDEV_SAMPLE_ADJUSTMENTS_DESC',
  DealsStddevSampleAdjustedCostAsc = 'DEALS_STDDEV_SAMPLE_ADJUSTED_COST_ASC',
  DealsStddevSampleAdjustedCostDesc = 'DEALS_STDDEV_SAMPLE_ADJUSTED_COST_DESC',
  DealsStddevSampleIncentivesAsc = 'DEALS_STDDEV_SAMPLE_INCENTIVES_ASC',
  DealsStddevSampleIncentivesDesc = 'DEALS_STDDEV_SAMPLE_INCENTIVES_DESC',
  DealsStddevSamplePackAmountAsc = 'DEALS_STDDEV_SAMPLE_PACK_AMOUNT_ASC',
  DealsStddevSamplePackAmountDesc = 'DEALS_STDDEV_SAMPLE_PACK_AMOUNT_DESC',
  DealsStddevSampleSaleNetAsc = 'DEALS_STDDEV_SAMPLE_SALE_NET_ASC',
  DealsStddevSampleSaleNetDesc = 'DEALS_STDDEV_SAMPLE_SALE_NET_DESC',
  DealsStddevSampleTotalTradeActualCashValueAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsStddevSampleTotalTradeActualCashValueDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsStddevSampleTotalTradeGrossAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_TRADE_GROSS_ASC',
  DealsStddevSampleTotalTradeGrossDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_TRADE_GROSS_DESC',
  DealsStddevSampleWeOweFrontAsc = 'DEALS_STDDEV_SAMPLE_WE_OWE_FRONT_ASC',
  DealsStddevSampleWeOweFrontDesc = 'DEALS_STDDEV_SAMPLE_WE_OWE_FRONT_DESC',
  DealsStddevSampleTotalFrontFeesAftermarketProfitAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsStddevSampleTotalFrontFeesAftermarketProfitDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsStddevSampleTotalFrontCommissionAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_COMMISSION_ASC',
  DealsStddevSampleTotalFrontCommissionDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_COMMISSION_DESC',
  DealsStddevSampleTotalFrontSalesAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_SALES_ASC',
  DealsStddevSampleTotalFrontSalesDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_SALES_DESC',
  DealsStddevSampleTotalFrontCostAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_COST_ASC',
  DealsStddevSampleTotalFrontCostDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FRONT_COST_DESC',
  DealsStddevSampleFrontGrossAsc = 'DEALS_STDDEV_SAMPLE_FRONT_GROSS_ASC',
  DealsStddevSampleFrontGrossDesc = 'DEALS_STDDEV_SAMPLE_FRONT_GROSS_DESC',
  DealsStddevSampleFinanceProfitAsc = 'DEALS_STDDEV_SAMPLE_FINANCE_PROFIT_ASC',
  DealsStddevSampleFinanceProfitDesc = 'DEALS_STDDEV_SAMPLE_FINANCE_PROFIT_DESC',
  DealsStddevSampleTotalWarrantyProfitAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_WARRANTY_PROFIT_ASC',
  DealsStddevSampleTotalWarrantyProfitDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_WARRANTY_PROFIT_DESC',
  DealsStddevSampleWeOweBackAsc = 'DEALS_STDDEV_SAMPLE_WE_OWE_BACK_ASC',
  DealsStddevSampleWeOweBackDesc = 'DEALS_STDDEV_SAMPLE_WE_OWE_BACK_DESC',
  DealsStddevSampleInsuranceProfitAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_PROFIT_ASC',
  DealsStddevSampleInsuranceProfitDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_PROFIT_DESC',
  DealsStddevSampleTotalBackFeesAftermarketProfitAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsStddevSampleTotalBackFeesAftermarketProfitDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsStddevSampleFinanceReserveAsc = 'DEALS_STDDEV_SAMPLE_FINANCE_RESERVE_ASC',
  DealsStddevSampleFinanceReserveDesc = 'DEALS_STDDEV_SAMPLE_FINANCE_RESERVE_DESC',
  DealsStddevSampleTotalBackCommissionAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_COMMISSION_ASC',
  DealsStddevSampleTotalBackCommissionDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_COMMISSION_DESC',
  DealsStddevSampleTotalBackSalesAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_SALES_ASC',
  DealsStddevSampleTotalBackSalesDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_SALES_DESC',
  DealsStddevSampleTotalBackCostAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_COST_ASC',
  DealsStddevSampleTotalBackCostDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_BACK_COST_DESC',
  DealsStddevSampleBackGrossAsc = 'DEALS_STDDEV_SAMPLE_BACK_GROSS_ASC',
  DealsStddevSampleBackGrossDesc = 'DEALS_STDDEV_SAMPLE_BACK_GROSS_DESC',
  DealsStddevSampleTotalProfitAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_PROFIT_ASC',
  DealsStddevSampleTotalProfitDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_PROFIT_DESC',
  DealsStddevSampleGrossProfitAsc = 'DEALS_STDDEV_SAMPLE_GROSS_PROFIT_ASC',
  DealsStddevSampleGrossProfitDesc = 'DEALS_STDDEV_SAMPLE_GROSS_PROFIT_DESC',
  DealsStddevSampleGrossPayableAsc = 'DEALS_STDDEV_SAMPLE_GROSS_PAYABLE_ASC',
  DealsStddevSampleGrossPayableDesc = 'DEALS_STDDEV_SAMPLE_GROSS_PAYABLE_DESC',
  DealsStddevSampleDealStatusAsc = 'DEALS_STDDEV_SAMPLE_DEAL_STATUS_ASC',
  DealsStddevSampleDealStatusDesc = 'DEALS_STDDEV_SAMPLE_DEAL_STATUS_DESC',
  DealsStddevSampleEntryDateAsc = 'DEALS_STDDEV_SAMPLE_ENTRY_DATE_ASC',
  DealsStddevSampleEntryDateDesc = 'DEALS_STDDEV_SAMPLE_ENTRY_DATE_DESC',
  DealsStddevSampleBookedDateAsc = 'DEALS_STDDEV_SAMPLE_BOOKED_DATE_ASC',
  DealsStddevSampleBookedDateDesc = 'DEALS_STDDEV_SAMPLE_BOOKED_DATE_DESC',
  DealsStddevSampleFinalizedDateAsc = 'DEALS_STDDEV_SAMPLE_FINALIZED_DATE_ASC',
  DealsStddevSampleFinalizedDateDesc = 'DEALS_STDDEV_SAMPLE_FINALIZED_DATE_DESC',
  DealsStddevSampleContractDateAsc = 'DEALS_STDDEV_SAMPLE_CONTRACT_DATE_ASC',
  DealsStddevSampleContractDateDesc = 'DEALS_STDDEV_SAMPLE_CONTRACT_DATE_DESC',
  DealsStddevSampleAccountingDateAsc = 'DEALS_STDDEV_SAMPLE_ACCOUNTING_DATE_ASC',
  DealsStddevSampleAccountingDateDesc = 'DEALS_STDDEV_SAMPLE_ACCOUNTING_DATE_DESC',
  DealsStddevSampleStatusChangeDateAsc = 'DEALS_STDDEV_SAMPLE_STATUS_CHANGE_DATE_ASC',
  DealsStddevSampleStatusChangeDateDesc = 'DEALS_STDDEV_SAMPLE_STATUS_CHANGE_DATE_DESC',
  DealsStddevSampleFirstPayDateAsc = 'DEALS_STDDEV_SAMPLE_FIRST_PAY_DATE_ASC',
  DealsStddevSampleFirstPayDateDesc = 'DEALS_STDDEV_SAMPLE_FIRST_PAY_DATE_DESC',
  DealsStddevSampleDealTypeAsc = 'DEALS_STDDEV_SAMPLE_DEAL_TYPE_ASC',
  DealsStddevSampleDealTypeDesc = 'DEALS_STDDEV_SAMPLE_DEAL_TYPE_DESC',
  DealsStddevSampleSaleTypeAsc = 'DEALS_STDDEV_SAMPLE_SALE_TYPE_ASC',
  DealsStddevSampleSaleTypeDesc = 'DEALS_STDDEV_SAMPLE_SALE_TYPE_DESC',
  DealsStddevSampleBankIdAsc = 'DEALS_STDDEV_SAMPLE_BANK_ID_ASC',
  DealsStddevSampleBankIdDesc = 'DEALS_STDDEV_SAMPLE_BANK_ID_DESC',
  DealsStddevSampleBankNameAsc = 'DEALS_STDDEV_SAMPLE_BANK_NAME_ASC',
  DealsStddevSampleBankNameDesc = 'DEALS_STDDEV_SAMPLE_BANK_NAME_DESC',
  DealsStddevSampleBankAddressAsc = 'DEALS_STDDEV_SAMPLE_BANK_ADDRESS_ASC',
  DealsStddevSampleBankAddressDesc = 'DEALS_STDDEV_SAMPLE_BANK_ADDRESS_DESC',
  DealsStddevSampleTermAsc = 'DEALS_STDDEV_SAMPLE_TERM_ASC',
  DealsStddevSampleTermDesc = 'DEALS_STDDEV_SAMPLE_TERM_DESC',
  DealsStddevSampleAmountFinancedAsc = 'DEALS_STDDEV_SAMPLE_AMOUNT_FINANCED_ASC',
  DealsStddevSampleAmountFinancedDesc = 'DEALS_STDDEV_SAMPLE_AMOUNT_FINANCED_DESC',
  DealsStddevSampleAprAsc = 'DEALS_STDDEV_SAMPLE_APR_ASC',
  DealsStddevSampleAprDesc = 'DEALS_STDDEV_SAMPLE_APR_DESC',
  DealsStddevSampleMonthlyPaymentAsc = 'DEALS_STDDEV_SAMPLE_MONTHLY_PAYMENT_ASC',
  DealsStddevSampleMonthlyPaymentDesc = 'DEALS_STDDEV_SAMPLE_MONTHLY_PAYMENT_DESC',
  DealsStddevSamplePaymentTotalAsc = 'DEALS_STDDEV_SAMPLE_PAYMENT_TOTAL_ASC',
  DealsStddevSamplePaymentTotalDesc = 'DEALS_STDDEV_SAMPLE_PAYMENT_TOTAL_DESC',
  DealsStddevSampleRebatesAsc = 'DEALS_STDDEV_SAMPLE_REBATES_ASC',
  DealsStddevSampleRebatesDesc = 'DEALS_STDDEV_SAMPLE_REBATES_DESC',
  DealsStddevSampleDepositAsc = 'DEALS_STDDEV_SAMPLE_DEPOSIT_ASC',
  DealsStddevSampleDepositDesc = 'DEALS_STDDEV_SAMPLE_DEPOSIT_DESC',
  DealsStddevSampleDownPaymentAsc = 'DEALS_STDDEV_SAMPLE_DOWN_PAYMENT_ASC',
  DealsStddevSampleDownPaymentDesc = 'DEALS_STDDEV_SAMPLE_DOWN_PAYMENT_DESC',
  DealsStddevSampleTotalNetTradesAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_NET_TRADES_ASC',
  DealsStddevSampleTotalNetTradesDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_NET_TRADES_DESC',
  DealsStddevSampleTotalDownAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_DOWN_ASC',
  DealsStddevSampleTotalDownDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_DOWN_DESC',
  DealsStddevSampleBalloonAmountAsc = 'DEALS_STDDEV_SAMPLE_BALLOON_AMOUNT_ASC',
  DealsStddevSampleBalloonAmountDesc = 'DEALS_STDDEV_SAMPLE_BALLOON_AMOUNT_DESC',
  DealsStddevSampleAdjustedBalloonAmountAsc = 'DEALS_STDDEV_SAMPLE_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsStddevSampleAdjustedBalloonAmountDesc = 'DEALS_STDDEV_SAMPLE_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsStddevSampleHoldbackAmountAsc = 'DEALS_STDDEV_SAMPLE_HOLDBACK_AMOUNT_ASC',
  DealsStddevSampleHoldbackAmountDesc = 'DEALS_STDDEV_SAMPLE_HOLDBACK_AMOUNT_DESC',
  DealsStddevSampleTotalDriveOffAmountAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsStddevSampleTotalDriveOffAmountDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsStddevSampleLicenseFeeAsc = 'DEALS_STDDEV_SAMPLE_LICENSE_FEE_ASC',
  DealsStddevSampleLicenseFeeDesc = 'DEALS_STDDEV_SAMPLE_LICENSE_FEE_DESC',
  DealsStddevSampleRegistrationFeeAsc = 'DEALS_STDDEV_SAMPLE_REGISTRATION_FEE_ASC',
  DealsStddevSampleRegistrationFeeDesc = 'DEALS_STDDEV_SAMPLE_REGISTRATION_FEE_DESC',
  DealsStddevSampleDocumentationFeeAsc = 'DEALS_STDDEV_SAMPLE_DOCUMENTATION_FEE_ASC',
  DealsStddevSampleDocumentationFeeDesc = 'DEALS_STDDEV_SAMPLE_DOCUMENTATION_FEE_DESC',
  DealsStddevSampleFinanceChargeAsc = 'DEALS_STDDEV_SAMPLE_FINANCE_CHARGE_ASC',
  DealsStddevSampleFinanceChargeDesc = 'DEALS_STDDEV_SAMPLE_FINANCE_CHARGE_DESC',
  DealsStddevSampleTotalPickupPaymentsAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsStddevSampleTotalPickupPaymentsDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsStddevSampleSellRateAsc = 'DEALS_STDDEV_SAMPLE_SELL_RATE_ASC',
  DealsStddevSampleSellRateDesc = 'DEALS_STDDEV_SAMPLE_SELL_RATE_DESC',
  DealsStddevSampleBuyRateAsc = 'DEALS_STDDEV_SAMPLE_BUY_RATE_ASC',
  DealsStddevSampleBuyRateDesc = 'DEALS_STDDEV_SAMPLE_BUY_RATE_DESC',
  DealsStddevSampleResidualRateAsc = 'DEALS_STDDEV_SAMPLE_RESIDUAL_RATE_ASC',
  DealsStddevSampleResidualRateDesc = 'DEALS_STDDEV_SAMPLE_RESIDUAL_RATE_DESC',
  DealsStddevSampleResidualAmountAsc = 'DEALS_STDDEV_SAMPLE_RESIDUAL_AMOUNT_ASC',
  DealsStddevSampleResidualAmountDesc = 'DEALS_STDDEV_SAMPLE_RESIDUAL_AMOUNT_DESC',
  DealsStddevSampleAllowedMilesAsc = 'DEALS_STDDEV_SAMPLE_ALLOWED_MILES_ASC',
  DealsStddevSampleAllowedMilesDesc = 'DEALS_STDDEV_SAMPLE_ALLOWED_MILES_DESC',
  DealsStddevSampleEstimatedMilesAsc = 'DEALS_STDDEV_SAMPLE_ESTIMATED_MILES_ASC',
  DealsStddevSampleEstimatedMilesDesc = 'DEALS_STDDEV_SAMPLE_ESTIMATED_MILES_DESC',
  DealsStddevSampleMileageRateAsc = 'DEALS_STDDEV_SAMPLE_MILEAGE_RATE_ASC',
  DealsStddevSampleMileageRateDesc = 'DEALS_STDDEV_SAMPLE_MILEAGE_RATE_DESC',
  DealsStddevSampleAcquisitionFeeAsc = 'DEALS_STDDEV_SAMPLE_ACQUISITION_FEE_ASC',
  DealsStddevSampleAcquisitionFeeDesc = 'DEALS_STDDEV_SAMPLE_ACQUISITION_FEE_DESC',
  DealsStddevSampleBasePaymentAsc = 'DEALS_STDDEV_SAMPLE_BASE_PAYMENT_ASC',
  DealsStddevSampleBasePaymentDesc = 'DEALS_STDDEV_SAMPLE_BASE_PAYMENT_DESC',
  DealsStddevSampleSecurityDepositAsc = 'DEALS_STDDEV_SAMPLE_SECURITY_DEPOSIT_ASC',
  DealsStddevSampleSecurityDepositDesc = 'DEALS_STDDEV_SAMPLE_SECURITY_DEPOSIT_DESC',
  DealsStddevSampleTotalCapitalReductionAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsStddevSampleTotalCapitalReductionDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsStddevSampleNetCapitalCostAsc = 'DEALS_STDDEV_SAMPLE_NET_CAPITAL_COST_ASC',
  DealsStddevSampleNetCapitalCostDesc = 'DEALS_STDDEV_SAMPLE_NET_CAPITAL_COST_DESC',
  DealsStddevSampleLeaseDepreciationValueAsc = 'DEALS_STDDEV_SAMPLE_LEASE_DEPRECIATION_VALUE_ASC',
  DealsStddevSampleLeaseDepreciationValueDesc = 'DEALS_STDDEV_SAMPLE_LEASE_DEPRECIATION_VALUE_DESC',
  DealsStddevSampleDealerFeesAsc = 'DEALS_STDDEV_SAMPLE_DEALER_FEES_ASC',
  DealsStddevSampleDealerFeesDesc = 'DEALS_STDDEV_SAMPLE_DEALER_FEES_DESC',
  DealsStddevSampleGovernmentFeesAsc = 'DEALS_STDDEV_SAMPLE_GOVERNMENT_FEES_ASC',
  DealsStddevSampleGovernmentFeesDesc = 'DEALS_STDDEV_SAMPLE_GOVERNMENT_FEES_DESC',
  DealsStddevSampleTotalTaxAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_TAX_ASC',
  DealsStddevSampleTotalTaxDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_TAX_DESC',
  DealsStddevSampleRegistrationStateAsc = 'DEALS_STDDEV_SAMPLE_REGISTRATION_STATE_ASC',
  DealsStddevSampleRegistrationStateDesc = 'DEALS_STDDEV_SAMPLE_REGISTRATION_STATE_DESC',
  DealsStddevSampleReportOfSaleNumberAsc = 'DEALS_STDDEV_SAMPLE_REPORT_OF_SALE_NUMBER_ASC',
  DealsStddevSampleReportOfSaleNumberDesc = 'DEALS_STDDEV_SAMPLE_REPORT_OF_SALE_NUMBER_DESC',
  DealsStddevSampleSalesman_1TotalCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsStddevSampleSalesman_1TotalCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsStddevSampleSalesman_1FrontCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsStddevSampleSalesman_1FrontCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsStddevSampleSalesman_1BackCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsStddevSampleSalesman_1BackCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsStddevSampleSalesman_2TotalCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsStddevSampleSalesman_2TotalCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsStddevSampleSalesman_2FrontCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsStddevSampleSalesman_2FrontCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsStddevSampleSalesman_2BackCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsStddevSampleSalesman_2BackCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsStddevSampleSalesman_3TotalCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsStddevSampleSalesman_3TotalCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsStddevSampleSalesman_3FrontCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsStddevSampleSalesman_3FrontCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsStddevSampleSalesman_3BackCommissionAsc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsStddevSampleSalesman_3BackCommissionDesc = 'DEALS_STDDEV_SAMPLE_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsStddevSampleWarranty_1NameAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_NAME_ASC',
  DealsStddevSampleWarranty_1NameDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_NAME_DESC',
  DealsStddevSampleWarranty_1SaleAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_SALE_ASC',
  DealsStddevSampleWarranty_1SaleDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_SALE_DESC',
  DealsStddevSampleWarranty_1CostAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_COST_ASC',
  DealsStddevSampleWarranty_1CostDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_COST_DESC',
  DealsStddevSampleWarranty_1MilesAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_MILES_ASC',
  DealsStddevSampleWarranty_1MilesDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_MILES_DESC',
  DealsStddevSampleWarranty_1TermAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_TERM_ASC',
  DealsStddevSampleWarranty_1TermDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_1_TERM_DESC',
  DealsStddevSampleWarranty_2NameAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_NAME_ASC',
  DealsStddevSampleWarranty_2NameDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_NAME_DESC',
  DealsStddevSampleWarranty_2SaleAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_SALE_ASC',
  DealsStddevSampleWarranty_2SaleDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_SALE_DESC',
  DealsStddevSampleWarranty_2CostAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_COST_ASC',
  DealsStddevSampleWarranty_2CostDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_COST_DESC',
  DealsStddevSampleWarranty_2MilesAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_MILES_ASC',
  DealsStddevSampleWarranty_2MilesDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_MILES_DESC',
  DealsStddevSampleWarranty_2TermAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_TERM_ASC',
  DealsStddevSampleWarranty_2TermDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_2_TERM_DESC',
  DealsStddevSampleWarranty_3NameAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_NAME_ASC',
  DealsStddevSampleWarranty_3NameDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_NAME_DESC',
  DealsStddevSampleWarranty_3SaleAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_SALE_ASC',
  DealsStddevSampleWarranty_3SaleDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_SALE_DESC',
  DealsStddevSampleWarranty_3CostAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_COST_ASC',
  DealsStddevSampleWarranty_3CostDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_COST_DESC',
  DealsStddevSampleWarranty_3MilesAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_MILES_ASC',
  DealsStddevSampleWarranty_3MilesDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_MILES_DESC',
  DealsStddevSampleWarranty_3TermAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_TERM_ASC',
  DealsStddevSampleWarranty_3TermDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_3_TERM_DESC',
  DealsStddevSampleWarranty_4NameAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_NAME_ASC',
  DealsStddevSampleWarranty_4NameDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_NAME_DESC',
  DealsStddevSampleWarranty_4SaleAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_SALE_ASC',
  DealsStddevSampleWarranty_4SaleDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_SALE_DESC',
  DealsStddevSampleWarranty_4CostAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_COST_ASC',
  DealsStddevSampleWarranty_4CostDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_COST_DESC',
  DealsStddevSampleWarranty_4MilesAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_MILES_ASC',
  DealsStddevSampleWarranty_4MilesDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_MILES_DESC',
  DealsStddevSampleWarranty_4TermAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_TERM_ASC',
  DealsStddevSampleWarranty_4TermDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_4_TERM_DESC',
  DealsStddevSampleWarranty_5NameAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_NAME_ASC',
  DealsStddevSampleWarranty_5NameDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_NAME_DESC',
  DealsStddevSampleWarranty_5SaleAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_SALE_ASC',
  DealsStddevSampleWarranty_5SaleDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_SALE_DESC',
  DealsStddevSampleWarranty_5CostAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_COST_ASC',
  DealsStddevSampleWarranty_5CostDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_COST_DESC',
  DealsStddevSampleWarranty_5MilesAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_MILES_ASC',
  DealsStddevSampleWarranty_5MilesDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_MILES_DESC',
  DealsStddevSampleWarranty_5TermAsc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_TERM_ASC',
  DealsStddevSampleWarranty_5TermDesc = 'DEALS_STDDEV_SAMPLE_WARRANTY_5_TERM_DESC',
  DealsStddevSampleTotalFeeAftermarketSaleAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsStddevSampleTotalFeeAftermarketSaleDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsStddevSampleTotalFeeAftermarketCostAsc = 'DEALS_STDDEV_SAMPLE_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsStddevSampleTotalFeeAftermarketCostDesc = 'DEALS_STDDEV_SAMPLE_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsStddevSampleFeeAftermarket_1NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_NAME_ASC',
  DealsStddevSampleFeeAftermarket_1NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_NAME_DESC',
  DealsStddevSampleFeeAftermarket_1SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_SALE_ASC',
  DealsStddevSampleFeeAftermarket_1SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_SALE_DESC',
  DealsStddevSampleFeeAftermarket_1CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_COST_ASC',
  DealsStddevSampleFeeAftermarket_1CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_COST_DESC',
  DealsStddevSampleFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_2NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_NAME_ASC',
  DealsStddevSampleFeeAftermarket_2NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_NAME_DESC',
  DealsStddevSampleFeeAftermarket_2SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_SALE_ASC',
  DealsStddevSampleFeeAftermarket_2SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_SALE_DESC',
  DealsStddevSampleFeeAftermarket_2CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_COST_ASC',
  DealsStddevSampleFeeAftermarket_2CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_COST_DESC',
  DealsStddevSampleFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_3NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_NAME_ASC',
  DealsStddevSampleFeeAftermarket_3NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_NAME_DESC',
  DealsStddevSampleFeeAftermarket_3SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_SALE_ASC',
  DealsStddevSampleFeeAftermarket_3SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_SALE_DESC',
  DealsStddevSampleFeeAftermarket_3CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_COST_ASC',
  DealsStddevSampleFeeAftermarket_3CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_COST_DESC',
  DealsStddevSampleFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_4NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_NAME_ASC',
  DealsStddevSampleFeeAftermarket_4NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_NAME_DESC',
  DealsStddevSampleFeeAftermarket_4SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_SALE_ASC',
  DealsStddevSampleFeeAftermarket_4SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_SALE_DESC',
  DealsStddevSampleFeeAftermarket_4CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_COST_ASC',
  DealsStddevSampleFeeAftermarket_4CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_COST_DESC',
  DealsStddevSampleFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_5NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_NAME_ASC',
  DealsStddevSampleFeeAftermarket_5NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_NAME_DESC',
  DealsStddevSampleFeeAftermarket_5SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_SALE_ASC',
  DealsStddevSampleFeeAftermarket_5SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_SALE_DESC',
  DealsStddevSampleFeeAftermarket_5CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_COST_ASC',
  DealsStddevSampleFeeAftermarket_5CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_COST_DESC',
  DealsStddevSampleFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_6NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_NAME_ASC',
  DealsStddevSampleFeeAftermarket_6NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_NAME_DESC',
  DealsStddevSampleFeeAftermarket_6SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_SALE_ASC',
  DealsStddevSampleFeeAftermarket_6SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_SALE_DESC',
  DealsStddevSampleFeeAftermarket_6CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_COST_ASC',
  DealsStddevSampleFeeAftermarket_6CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_COST_DESC',
  DealsStddevSampleFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_7NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_NAME_ASC',
  DealsStddevSampleFeeAftermarket_7NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_NAME_DESC',
  DealsStddevSampleFeeAftermarket_7SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_SALE_ASC',
  DealsStddevSampleFeeAftermarket_7SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_SALE_DESC',
  DealsStddevSampleFeeAftermarket_7CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_COST_ASC',
  DealsStddevSampleFeeAftermarket_7CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_COST_DESC',
  DealsStddevSampleFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_8NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_NAME_ASC',
  DealsStddevSampleFeeAftermarket_8NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_NAME_DESC',
  DealsStddevSampleFeeAftermarket_8SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_SALE_ASC',
  DealsStddevSampleFeeAftermarket_8SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_SALE_DESC',
  DealsStddevSampleFeeAftermarket_8CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_COST_ASC',
  DealsStddevSampleFeeAftermarket_8CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_COST_DESC',
  DealsStddevSampleFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_9NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_NAME_ASC',
  DealsStddevSampleFeeAftermarket_9NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_NAME_DESC',
  DealsStddevSampleFeeAftermarket_9SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_SALE_ASC',
  DealsStddevSampleFeeAftermarket_9SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_SALE_DESC',
  DealsStddevSampleFeeAftermarket_9CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_COST_ASC',
  DealsStddevSampleFeeAftermarket_9CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_COST_DESC',
  DealsStddevSampleFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_10NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_NAME_ASC',
  DealsStddevSampleFeeAftermarket_10NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_NAME_DESC',
  DealsStddevSampleFeeAftermarket_10SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_SALE_ASC',
  DealsStddevSampleFeeAftermarket_10SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_SALE_DESC',
  DealsStddevSampleFeeAftermarket_10CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_COST_ASC',
  DealsStddevSampleFeeAftermarket_10CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_COST_DESC',
  DealsStddevSampleFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_11NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_NAME_ASC',
  DealsStddevSampleFeeAftermarket_11NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_NAME_DESC',
  DealsStddevSampleFeeAftermarket_11SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_SALE_ASC',
  DealsStddevSampleFeeAftermarket_11SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_SALE_DESC',
  DealsStddevSampleFeeAftermarket_11CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_COST_ASC',
  DealsStddevSampleFeeAftermarket_11CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_COST_DESC',
  DealsStddevSampleFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_12NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_NAME_ASC',
  DealsStddevSampleFeeAftermarket_12NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_NAME_DESC',
  DealsStddevSampleFeeAftermarket_12SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_SALE_ASC',
  DealsStddevSampleFeeAftermarket_12SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_SALE_DESC',
  DealsStddevSampleFeeAftermarket_12CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_COST_ASC',
  DealsStddevSampleFeeAftermarket_12CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_COST_DESC',
  DealsStddevSampleFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_13NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_NAME_ASC',
  DealsStddevSampleFeeAftermarket_13NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_NAME_DESC',
  DealsStddevSampleFeeAftermarket_13SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_SALE_ASC',
  DealsStddevSampleFeeAftermarket_13SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_SALE_DESC',
  DealsStddevSampleFeeAftermarket_13CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_COST_ASC',
  DealsStddevSampleFeeAftermarket_13CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_COST_DESC',
  DealsStddevSampleFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_14NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_NAME_ASC',
  DealsStddevSampleFeeAftermarket_14NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_NAME_DESC',
  DealsStddevSampleFeeAftermarket_14SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_SALE_ASC',
  DealsStddevSampleFeeAftermarket_14SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_SALE_DESC',
  DealsStddevSampleFeeAftermarket_14CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_COST_ASC',
  DealsStddevSampleFeeAftermarket_14CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_COST_DESC',
  DealsStddevSampleFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_15NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_NAME_ASC',
  DealsStddevSampleFeeAftermarket_15NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_NAME_DESC',
  DealsStddevSampleFeeAftermarket_15SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_SALE_ASC',
  DealsStddevSampleFeeAftermarket_15SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_SALE_DESC',
  DealsStddevSampleFeeAftermarket_15CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_COST_ASC',
  DealsStddevSampleFeeAftermarket_15CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_COST_DESC',
  DealsStddevSampleFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_16NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_NAME_ASC',
  DealsStddevSampleFeeAftermarket_16NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_NAME_DESC',
  DealsStddevSampleFeeAftermarket_16SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_SALE_ASC',
  DealsStddevSampleFeeAftermarket_16SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_SALE_DESC',
  DealsStddevSampleFeeAftermarket_16CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_COST_ASC',
  DealsStddevSampleFeeAftermarket_16CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_COST_DESC',
  DealsStddevSampleFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_17NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_NAME_ASC',
  DealsStddevSampleFeeAftermarket_17NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_NAME_DESC',
  DealsStddevSampleFeeAftermarket_17SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_SALE_ASC',
  DealsStddevSampleFeeAftermarket_17SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_SALE_DESC',
  DealsStddevSampleFeeAftermarket_17CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_COST_ASC',
  DealsStddevSampleFeeAftermarket_17CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_COST_DESC',
  DealsStddevSampleFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_18NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_NAME_ASC',
  DealsStddevSampleFeeAftermarket_18NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_NAME_DESC',
  DealsStddevSampleFeeAftermarket_18SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_SALE_ASC',
  DealsStddevSampleFeeAftermarket_18SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_SALE_DESC',
  DealsStddevSampleFeeAftermarket_18CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_COST_ASC',
  DealsStddevSampleFeeAftermarket_18CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_COST_DESC',
  DealsStddevSampleFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_19NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_NAME_ASC',
  DealsStddevSampleFeeAftermarket_19NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_NAME_DESC',
  DealsStddevSampleFeeAftermarket_19SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_SALE_ASC',
  DealsStddevSampleFeeAftermarket_19SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_SALE_DESC',
  DealsStddevSampleFeeAftermarket_19CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_COST_ASC',
  DealsStddevSampleFeeAftermarket_19CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_COST_DESC',
  DealsStddevSampleFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsStddevSampleFeeAftermarket_20NameAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_NAME_ASC',
  DealsStddevSampleFeeAftermarket_20NameDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_NAME_DESC',
  DealsStddevSampleFeeAftermarket_20SaleAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_SALE_ASC',
  DealsStddevSampleFeeAftermarket_20SaleDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_SALE_DESC',
  DealsStddevSampleFeeAftermarket_20CostAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_COST_ASC',
  DealsStddevSampleFeeAftermarket_20CostDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_COST_DESC',
  DealsStddevSampleFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsStddevSampleFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_STDDEV_SAMPLE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsStddevSampleInsurance_1TypeAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_TYPE_ASC',
  DealsStddevSampleInsurance_1TypeDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_TYPE_DESC',
  DealsStddevSampleInsurance_1NameAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_NAME_ASC',
  DealsStddevSampleInsurance_1NameDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_NAME_DESC',
  DealsStddevSampleInsurance_1SaleAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_SALE_ASC',
  DealsStddevSampleInsurance_1SaleDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_SALE_DESC',
  DealsStddevSampleInsurance_1CostAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_COST_ASC',
  DealsStddevSampleInsurance_1CostDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_COST_DESC',
  DealsStddevSampleInsurance_1TermAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_TERM_ASC',
  DealsStddevSampleInsurance_1TermDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_1_TERM_DESC',
  DealsStddevSampleInsurance_2TypeAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_TYPE_ASC',
  DealsStddevSampleInsurance_2TypeDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_TYPE_DESC',
  DealsStddevSampleInsurance_2NameAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_NAME_ASC',
  DealsStddevSampleInsurance_2NameDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_NAME_DESC',
  DealsStddevSampleInsurance_2SaleAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_SALE_ASC',
  DealsStddevSampleInsurance_2SaleDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_SALE_DESC',
  DealsStddevSampleInsurance_2CostAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_COST_ASC',
  DealsStddevSampleInsurance_2CostDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_COST_DESC',
  DealsStddevSampleInsurance_2TermAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_TERM_ASC',
  DealsStddevSampleInsurance_2TermDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_2_TERM_DESC',
  DealsStddevSampleInsurance_3TypeAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_TYPE_ASC',
  DealsStddevSampleInsurance_3TypeDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_TYPE_DESC',
  DealsStddevSampleInsurance_3NameAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_NAME_ASC',
  DealsStddevSampleInsurance_3NameDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_NAME_DESC',
  DealsStddevSampleInsurance_3SaleAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_SALE_ASC',
  DealsStddevSampleInsurance_3SaleDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_SALE_DESC',
  DealsStddevSampleInsurance_3CostAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_COST_ASC',
  DealsStddevSampleInsurance_3CostDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_COST_DESC',
  DealsStddevSampleInsurance_3TermAsc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_TERM_ASC',
  DealsStddevSampleInsurance_3TermDesc = 'DEALS_STDDEV_SAMPLE_INSURANCE_3_TERM_DESC',
  DealsStddevSampleAccidentalHealthTypeAsc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsStddevSampleAccidentalHealthTypeDesc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsStddevSampleAccidentalHealthNameAsc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsStddevSampleAccidentalHealthNameDesc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsStddevSampleAccidentalHealthSaleAsc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsStddevSampleAccidentalHealthSaleDesc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsStddevSampleAccidentalHealthCostAsc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_COST_ASC',
  DealsStddevSampleAccidentalHealthCostDesc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_COST_DESC',
  DealsStddevSampleAccidentalHealthTermAsc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsStddevSampleAccidentalHealthTermDesc = 'DEALS_STDDEV_SAMPLE_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsStddevSampleCreditLifeTypeAsc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_TYPE_ASC',
  DealsStddevSampleCreditLifeTypeDesc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_TYPE_DESC',
  DealsStddevSampleCreditLifeNameAsc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_NAME_ASC',
  DealsStddevSampleCreditLifeNameDesc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_NAME_DESC',
  DealsStddevSampleCreditLifeSaleAsc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_SALE_ASC',
  DealsStddevSampleCreditLifeSaleDesc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_SALE_DESC',
  DealsStddevSampleCreditLifeCostAsc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_COST_ASC',
  DealsStddevSampleCreditLifeCostDesc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_COST_DESC',
  DealsStddevSampleCreditLifeTermAsc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_TERM_ASC',
  DealsStddevSampleCreditLifeTermDesc = 'DEALS_STDDEV_SAMPLE_CREDIT_LIFE_TERM_DESC',
  DealsStddevSampleLevelizedLifeTypeAsc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_TYPE_ASC',
  DealsStddevSampleLevelizedLifeTypeDesc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_TYPE_DESC',
  DealsStddevSampleLevelizedLifeNameAsc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_NAME_ASC',
  DealsStddevSampleLevelizedLifeNameDesc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_NAME_DESC',
  DealsStddevSampleLevelizedLifeSaleAsc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_SALE_ASC',
  DealsStddevSampleLevelizedLifeSaleDesc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_SALE_DESC',
  DealsStddevSampleLevelizedLifeCostAsc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_COST_ASC',
  DealsStddevSampleLevelizedLifeCostDesc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_COST_DESC',
  DealsStddevSampleLevelizedLifeTermAsc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_TERM_ASC',
  DealsStddevSampleLevelizedLifeTermDesc = 'DEALS_STDDEV_SAMPLE_LEVELIZED_LIFE_TERM_DESC',
  DealsStddevSampleLossOfEmploymentTypeAsc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsStddevSampleLossOfEmploymentTypeDesc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsStddevSampleLossOfEmploymentNameAsc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsStddevSampleLossOfEmploymentNameDesc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsStddevSampleLossOfEmploymentSaleAsc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsStddevSampleLossOfEmploymentSaleDesc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsStddevSampleLossOfEmploymentCostAsc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsStddevSampleLossOfEmploymentCostDesc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsStddevSampleLossOfEmploymentTermAsc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsStddevSampleLossOfEmploymentTermDesc = 'DEALS_STDDEV_SAMPLE_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsStddevSampleGuaranteedAutoProtectionTypeAsc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsStddevSampleGuaranteedAutoProtectionTypeDesc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsStddevSampleGuaranteedAutoProtectionNameAsc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsStddevSampleGuaranteedAutoProtectionNameDesc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsStddevSampleGuaranteedAutoProtectionSaleAsc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsStddevSampleGuaranteedAutoProtectionSaleDesc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsStddevSampleGuaranteedAutoProtectionCostAsc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsStddevSampleGuaranteedAutoProtectionCostDesc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsStddevSampleGuaranteedAutoProtectionTermAsc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsStddevSampleGuaranteedAutoProtectionTermDesc = 'DEALS_STDDEV_SAMPLE_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsStddevSampleSaleCommentsAsc = 'DEALS_STDDEV_SAMPLE_SALE_COMMENTS_ASC',
  DealsStddevSampleSaleCommentsDesc = 'DEALS_STDDEV_SAMPLE_SALE_COMMENTS_DESC',
  DealsStddevSampleCassStdLine1Asc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LINE1_ASC',
  DealsStddevSampleCassStdLine1Desc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LINE1_DESC',
  DealsStddevSampleCassStdLine2Asc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LINE2_ASC',
  DealsStddevSampleCassStdLine2Desc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LINE2_DESC',
  DealsStddevSampleCassStdCityAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CITY_ASC',
  DealsStddevSampleCassStdCityDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CITY_DESC',
  DealsStddevSampleCassStdStateAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_STATE_ASC',
  DealsStddevSampleCassStdStateDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_STATE_DESC',
  DealsStddevSampleCassStdZipAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIP_ASC',
  DealsStddevSampleCassStdZipDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIP_DESC',
  DealsStddevSampleCassStdZip4Asc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIP4_ASC',
  DealsStddevSampleCassStdZip4Desc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIP4_DESC',
  DealsStddevSampleCassStdDpbcAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_DPBC_ASC',
  DealsStddevSampleCassStdDpbcDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_DPBC_DESC',
  DealsStddevSampleCassStdChkdgtAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CHKDGT_ASC',
  DealsStddevSampleCassStdChkdgtDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CHKDGT_DESC',
  DealsStddevSampleCassStdCartAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CART_ASC',
  DealsStddevSampleCassStdCartDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_CART_DESC',
  DealsStddevSampleCassStdLotAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LOT_ASC',
  DealsStddevSampleCassStdLotDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LOT_DESC',
  DealsStddevSampleCassStdLotordAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LOTORD_ASC',
  DealsStddevSampleCassStdLotordDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LOTORD_DESC',
  DealsStddevSampleCassStdUrbAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_URB_ASC',
  DealsStddevSampleCassStdUrbDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_URB_DESC',
  DealsStddevSampleCassStdFipsAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_FIPS_ASC',
  DealsStddevSampleCassStdFipsDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_FIPS_DESC',
  DealsStddevSampleCassStdEwsAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_EWS_ASC',
  DealsStddevSampleCassStdEwsDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_EWS_DESC',
  DealsStddevSampleCassStdLacsAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LACS_ASC',
  DealsStddevSampleCassStdLacsDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LACS_DESC',
  DealsStddevSampleCassStdZipmovAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIPMOV_ASC',
  DealsStddevSampleCassStdZipmovDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ZIPMOV_DESC',
  DealsStddevSampleCassStdZ4LomAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_Z4LOM_ASC',
  DealsStddevSampleCassStdZ4LomDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_Z4LOM_DESC',
  DealsStddevSampleCassStdNdiaptAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_NDIAPT_ASC',
  DealsStddevSampleCassStdNdiaptDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_NDIAPT_DESC',
  DealsStddevSampleCassStdNdirrAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_NDIRR_ASC',
  DealsStddevSampleCassStdNdirrDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_NDIRR_DESC',
  DealsStddevSampleCassStdLacsrtAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LACSRT_ASC',
  DealsStddevSampleCassStdLacsrtDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_LACSRT_DESC',
  DealsStddevSampleCassStdErrorCdAsc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ERROR_CD_ASC',
  DealsStddevSampleCassStdErrorCdDesc = 'DEALS_STDDEV_SAMPLE_CASS_STD_ERROR_CD_DESC',
  DealsStddevPopulationIdAsc = 'DEALS_STDDEV_POPULATION_ID_ASC',
  DealsStddevPopulationIdDesc = 'DEALS_STDDEV_POPULATION_ID_DESC',
  DealsStddevPopulationLeadIdAsc = 'DEALS_STDDEV_POPULATION_LEAD_ID_ASC',
  DealsStddevPopulationLeadIdDesc = 'DEALS_STDDEV_POPULATION_LEAD_ID_DESC',
  DealsStddevPopulationCompanyIntegrationIdAsc = 'DEALS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  DealsStddevPopulationCompanyIntegrationIdDesc = 'DEALS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  DealsStddevPopulationFileTypeAsc = 'DEALS_STDDEV_POPULATION_FILE_TYPE_ASC',
  DealsStddevPopulationFileTypeDesc = 'DEALS_STDDEV_POPULATION_FILE_TYPE_DESC',
  DealsStddevPopulationDvDealerIdAsc = 'DEALS_STDDEV_POPULATION_DV_DEALER_ID_ASC',
  DealsStddevPopulationDvDealerIdDesc = 'DEALS_STDDEV_POPULATION_DV_DEALER_ID_DESC',
  DealsStddevPopulationVendorDealerIdAsc = 'DEALS_STDDEV_POPULATION_VENDOR_DEALER_ID_ASC',
  DealsStddevPopulationVendorDealerIdDesc = 'DEALS_STDDEV_POPULATION_VENDOR_DEALER_ID_DESC',
  DealsStddevPopulationDmsTypeAsc = 'DEALS_STDDEV_POPULATION_DMS_TYPE_ASC',
  DealsStddevPopulationDmsTypeDesc = 'DEALS_STDDEV_POPULATION_DMS_TYPE_DESC',
  DealsStddevPopulationDealNumberAsc = 'DEALS_STDDEV_POPULATION_DEAL_NUMBER_ASC',
  DealsStddevPopulationDealNumberDesc = 'DEALS_STDDEV_POPULATION_DEAL_NUMBER_DESC',
  DealsStddevPopulationCustomerNumberAsc = 'DEALS_STDDEV_POPULATION_CUSTOMER_NUMBER_ASC',
  DealsStddevPopulationCustomerNumberDesc = 'DEALS_STDDEV_POPULATION_CUSTOMER_NUMBER_DESC',
  DealsStddevPopulationFullNameAsc = 'DEALS_STDDEV_POPULATION_FULL_NAME_ASC',
  DealsStddevPopulationFullNameDesc = 'DEALS_STDDEV_POPULATION_FULL_NAME_DESC',
  DealsStddevPopulationSalutationAsc = 'DEALS_STDDEV_POPULATION_SALUTATION_ASC',
  DealsStddevPopulationSalutationDesc = 'DEALS_STDDEV_POPULATION_SALUTATION_DESC',
  DealsStddevPopulationFirstNameAsc = 'DEALS_STDDEV_POPULATION_FIRST_NAME_ASC',
  DealsStddevPopulationFirstNameDesc = 'DEALS_STDDEV_POPULATION_FIRST_NAME_DESC',
  DealsStddevPopulationMiddleNameAsc = 'DEALS_STDDEV_POPULATION_MIDDLE_NAME_ASC',
  DealsStddevPopulationMiddleNameDesc = 'DEALS_STDDEV_POPULATION_MIDDLE_NAME_DESC',
  DealsStddevPopulationLastNameAsc = 'DEALS_STDDEV_POPULATION_LAST_NAME_ASC',
  DealsStddevPopulationLastNameDesc = 'DEALS_STDDEV_POPULATION_LAST_NAME_DESC',
  DealsStddevPopulationSuffixAsc = 'DEALS_STDDEV_POPULATION_SUFFIX_ASC',
  DealsStddevPopulationSuffixDesc = 'DEALS_STDDEV_POPULATION_SUFFIX_DESC',
  DealsStddevPopulationAddressLine_1Asc = 'DEALS_STDDEV_POPULATION_ADDRESS_LINE_1_ASC',
  DealsStddevPopulationAddressLine_1Desc = 'DEALS_STDDEV_POPULATION_ADDRESS_LINE_1_DESC',
  DealsStddevPopulationAddressLine_2Asc = 'DEALS_STDDEV_POPULATION_ADDRESS_LINE_2_ASC',
  DealsStddevPopulationAddressLine_2Desc = 'DEALS_STDDEV_POPULATION_ADDRESS_LINE_2_DESC',
  DealsStddevPopulationCityAsc = 'DEALS_STDDEV_POPULATION_CITY_ASC',
  DealsStddevPopulationCityDesc = 'DEALS_STDDEV_POPULATION_CITY_DESC',
  DealsStddevPopulationStateAsc = 'DEALS_STDDEV_POPULATION_STATE_ASC',
  DealsStddevPopulationStateDesc = 'DEALS_STDDEV_POPULATION_STATE_DESC',
  DealsStddevPopulationZipAsc = 'DEALS_STDDEV_POPULATION_ZIP_ASC',
  DealsStddevPopulationZipDesc = 'DEALS_STDDEV_POPULATION_ZIP_DESC',
  DealsStddevPopulationCountyAsc = 'DEALS_STDDEV_POPULATION_COUNTY_ASC',
  DealsStddevPopulationCountyDesc = 'DEALS_STDDEV_POPULATION_COUNTY_DESC',
  DealsStddevPopulationHomePhoneAsc = 'DEALS_STDDEV_POPULATION_HOME_PHONE_ASC',
  DealsStddevPopulationHomePhoneDesc = 'DEALS_STDDEV_POPULATION_HOME_PHONE_DESC',
  DealsStddevPopulationCellPhoneAsc = 'DEALS_STDDEV_POPULATION_CELL_PHONE_ASC',
  DealsStddevPopulationCellPhoneDesc = 'DEALS_STDDEV_POPULATION_CELL_PHONE_DESC',
  DealsStddevPopulationWorkPhoneAsc = 'DEALS_STDDEV_POPULATION_WORK_PHONE_ASC',
  DealsStddevPopulationWorkPhoneDesc = 'DEALS_STDDEV_POPULATION_WORK_PHONE_DESC',
  DealsStddevPopulationWorkExtensionAsc = 'DEALS_STDDEV_POPULATION_WORK_EXTENSION_ASC',
  DealsStddevPopulationWorkExtensionDesc = 'DEALS_STDDEV_POPULATION_WORK_EXTENSION_DESC',
  DealsStddevPopulationEmail_1Asc = 'DEALS_STDDEV_POPULATION_EMAIL_1_ASC',
  DealsStddevPopulationEmail_1Desc = 'DEALS_STDDEV_POPULATION_EMAIL_1_DESC',
  DealsStddevPopulationEmail_2Asc = 'DEALS_STDDEV_POPULATION_EMAIL_2_ASC',
  DealsStddevPopulationEmail_2Desc = 'DEALS_STDDEV_POPULATION_EMAIL_2_DESC',
  DealsStddevPopulationEmail_3Asc = 'DEALS_STDDEV_POPULATION_EMAIL_3_ASC',
  DealsStddevPopulationEmail_3Desc = 'DEALS_STDDEV_POPULATION_EMAIL_3_DESC',
  DealsStddevPopulationBirthDateAsc = 'DEALS_STDDEV_POPULATION_BIRTH_DATE_ASC',
  DealsStddevPopulationBirthDateDesc = 'DEALS_STDDEV_POPULATION_BIRTH_DATE_DESC',
  DealsStddevPopulationIndividualBusinessFlagAsc = 'DEALS_STDDEV_POPULATION_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsStddevPopulationIndividualBusinessFlagDesc = 'DEALS_STDDEV_POPULATION_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsStddevPopulationOptOutAsc = 'DEALS_STDDEV_POPULATION_OPT_OUT_ASC',
  DealsStddevPopulationOptOutDesc = 'DEALS_STDDEV_POPULATION_OPT_OUT_DESC',
  DealsStddevPopulationBlockEmailAsc = 'DEALS_STDDEV_POPULATION_BLOCK_EMAIL_ASC',
  DealsStddevPopulationBlockEmailDesc = 'DEALS_STDDEV_POPULATION_BLOCK_EMAIL_DESC',
  DealsStddevPopulationBlockPhoneAsc = 'DEALS_STDDEV_POPULATION_BLOCK_PHONE_ASC',
  DealsStddevPopulationBlockPhoneDesc = 'DEALS_STDDEV_POPULATION_BLOCK_PHONE_DESC',
  DealsStddevPopulationBlockMailAsc = 'DEALS_STDDEV_POPULATION_BLOCK_MAIL_ASC',
  DealsStddevPopulationBlockMailDesc = 'DEALS_STDDEV_POPULATION_BLOCK_MAIL_DESC',
  DealsStddevPopulationLanguageAsc = 'DEALS_STDDEV_POPULATION_LANGUAGE_ASC',
  DealsStddevPopulationLanguageDesc = 'DEALS_STDDEV_POPULATION_LANGUAGE_DESC',
  DealsStddevPopulationCustomerCreateDateAsc = 'DEALS_STDDEV_POPULATION_CUSTOMER_CREATE_DATE_ASC',
  DealsStddevPopulationCustomerCreateDateDesc = 'DEALS_STDDEV_POPULATION_CUSTOMER_CREATE_DATE_DESC',
  DealsStddevPopulationCustomerLastActivityDateAsc = 'DEALS_STDDEV_POPULATION_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsStddevPopulationCustomerLastActivityDateDesc = 'DEALS_STDDEV_POPULATION_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsStddevPopulationCoBuyerCustomerNumberAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsStddevPopulationCoBuyerCustomerNumberDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsStddevPopulationCoBuyerFullNameAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_FULL_NAME_ASC',
  DealsStddevPopulationCoBuyerFullNameDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_FULL_NAME_DESC',
  DealsStddevPopulationCoBuyerSalutationAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_SALUTATION_ASC',
  DealsStddevPopulationCoBuyerSalutationDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_SALUTATION_DESC',
  DealsStddevPopulationCoBuyerFirstNameAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_FIRST_NAME_ASC',
  DealsStddevPopulationCoBuyerFirstNameDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_FIRST_NAME_DESC',
  DealsStddevPopulationCoBuyerMiddleNameAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_MIDDLE_NAME_ASC',
  DealsStddevPopulationCoBuyerMiddleNameDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_MIDDLE_NAME_DESC',
  DealsStddevPopulationCoBuyerLastNameAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_LAST_NAME_ASC',
  DealsStddevPopulationCoBuyerLastNameDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_LAST_NAME_DESC',
  DealsStddevPopulationCoBuyerSuffixAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_SUFFIX_ASC',
  DealsStddevPopulationCoBuyerSuffixDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_SUFFIX_DESC',
  DealsStddevPopulationCoBuyerAddressLine_1Asc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsStddevPopulationCoBuyerAddressLine_1Desc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsStddevPopulationCoBuyerAddressLine_2Asc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsStddevPopulationCoBuyerAddressLine_2Desc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsStddevPopulationCoBuyerCityAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CITY_ASC',
  DealsStddevPopulationCoBuyerCityDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CITY_DESC',
  DealsStddevPopulationCoBuyerStateAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_STATE_ASC',
  DealsStddevPopulationCoBuyerStateDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_STATE_DESC',
  DealsStddevPopulationCoBuyerZipAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ZIP_ASC',
  DealsStddevPopulationCoBuyerZipDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_ZIP_DESC',
  DealsStddevPopulationCoBuyerCountyAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_COUNTY_ASC',
  DealsStddevPopulationCoBuyerCountyDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_COUNTY_DESC',
  DealsStddevPopulationCoBuyerHomePhoneAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_HOME_PHONE_ASC',
  DealsStddevPopulationCoBuyerHomePhoneDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_HOME_PHONE_DESC',
  DealsStddevPopulationCoBuyerCellPhoneAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CELL_PHONE_ASC',
  DealsStddevPopulationCoBuyerCellPhoneDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_CELL_PHONE_DESC',
  DealsStddevPopulationCoBuyerWorkPhoneAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_WORK_PHONE_ASC',
  DealsStddevPopulationCoBuyerWorkPhoneDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_WORK_PHONE_DESC',
  DealsStddevPopulationCoBuyerWorkExtensionAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_WORK_EXTENSION_ASC',
  DealsStddevPopulationCoBuyerWorkExtensionDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_WORK_EXTENSION_DESC',
  DealsStddevPopulationCoBuyerEmail_1Asc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_1_ASC',
  DealsStddevPopulationCoBuyerEmail_1Desc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_1_DESC',
  DealsStddevPopulationCoBuyerEmail_2Asc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_2_ASC',
  DealsStddevPopulationCoBuyerEmail_2Desc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_2_DESC',
  DealsStddevPopulationCoBuyerEmail_3Asc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_3_ASC',
  DealsStddevPopulationCoBuyerEmail_3Desc = 'DEALS_STDDEV_POPULATION_CO_BUYER_EMAIL_3_DESC',
  DealsStddevPopulationCoBuyerBirthDateAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BIRTH_DATE_ASC',
  DealsStddevPopulationCoBuyerBirthDateDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BIRTH_DATE_DESC',
  DealsStddevPopulationCoBuyerIndividualBusinessFlagAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsStddevPopulationCoBuyerIndividualBusinessFlagDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsStddevPopulationCoBuyerOptOutAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_OPT_OUT_ASC',
  DealsStddevPopulationCoBuyerOptOutDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_OPT_OUT_DESC',
  DealsStddevPopulationCoBuyerBlockEmailAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsStddevPopulationCoBuyerBlockEmailDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsStddevPopulationCoBuyerBlockPhoneAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_PHONE_ASC',
  DealsStddevPopulationCoBuyerBlockPhoneDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_PHONE_DESC',
  DealsStddevPopulationCoBuyerBlockMailAsc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_MAIL_ASC',
  DealsStddevPopulationCoBuyerBlockMailDesc = 'DEALS_STDDEV_POPULATION_CO_BUYER_BLOCK_MAIL_DESC',
  DealsStddevPopulationVinAsc = 'DEALS_STDDEV_POPULATION_VIN_ASC',
  DealsStddevPopulationVinDesc = 'DEALS_STDDEV_POPULATION_VIN_DESC',
  DealsStddevPopulationYearAsc = 'DEALS_STDDEV_POPULATION_YEAR_ASC',
  DealsStddevPopulationYearDesc = 'DEALS_STDDEV_POPULATION_YEAR_DESC',
  DealsStddevPopulationMakeAsc = 'DEALS_STDDEV_POPULATION_MAKE_ASC',
  DealsStddevPopulationMakeDesc = 'DEALS_STDDEV_POPULATION_MAKE_DESC',
  DealsStddevPopulationModelAsc = 'DEALS_STDDEV_POPULATION_MODEL_ASC',
  DealsStddevPopulationModelDesc = 'DEALS_STDDEV_POPULATION_MODEL_DESC',
  DealsStddevPopulationModelNumberAsc = 'DEALS_STDDEV_POPULATION_MODEL_NUMBER_ASC',
  DealsStddevPopulationModelNumberDesc = 'DEALS_STDDEV_POPULATION_MODEL_NUMBER_DESC',
  DealsStddevPopulationMileageAsc = 'DEALS_STDDEV_POPULATION_MILEAGE_ASC',
  DealsStddevPopulationMileageDesc = 'DEALS_STDDEV_POPULATION_MILEAGE_DESC',
  DealsStddevPopulationDescriptionAsc = 'DEALS_STDDEV_POPULATION_DESCRIPTION_ASC',
  DealsStddevPopulationDescriptionDesc = 'DEALS_STDDEV_POPULATION_DESCRIPTION_DESC',
  DealsStddevPopulationExteriorColorAsc = 'DEALS_STDDEV_POPULATION_EXTERIOR_COLOR_ASC',
  DealsStddevPopulationExteriorColorDesc = 'DEALS_STDDEV_POPULATION_EXTERIOR_COLOR_DESC',
  DealsStddevPopulationNewUsedAsc = 'DEALS_STDDEV_POPULATION_NEW_USED_ASC',
  DealsStddevPopulationNewUsedDesc = 'DEALS_STDDEV_POPULATION_NEW_USED_DESC',
  DealsStddevPopulationStockNumberAsc = 'DEALS_STDDEV_POPULATION_STOCK_NUMBER_ASC',
  DealsStddevPopulationStockNumberDesc = 'DEALS_STDDEV_POPULATION_STOCK_NUMBER_DESC',
  DealsStddevPopulationTransmissionAsc = 'DEALS_STDDEV_POPULATION_TRANSMISSION_ASC',
  DealsStddevPopulationTransmissionDesc = 'DEALS_STDDEV_POPULATION_TRANSMISSION_DESC',
  DealsStddevPopulationEngineConfigurationAsc = 'DEALS_STDDEV_POPULATION_ENGINE_CONFIGURATION_ASC',
  DealsStddevPopulationEngineConfigurationDesc = 'DEALS_STDDEV_POPULATION_ENGINE_CONFIGURATION_DESC',
  DealsStddevPopulationTrimAsc = 'DEALS_STDDEV_POPULATION_TRIM_ASC',
  DealsStddevPopulationTrimDesc = 'DEALS_STDDEV_POPULATION_TRIM_DESC',
  DealsStddevPopulationEngineNumberAsc = 'DEALS_STDDEV_POPULATION_ENGINE_NUMBER_ASC',
  DealsStddevPopulationEngineNumberDesc = 'DEALS_STDDEV_POPULATION_ENGINE_NUMBER_DESC',
  DealsStddevPopulationChassisNumberAsc = 'DEALS_STDDEV_POPULATION_CHASSIS_NUMBER_ASC',
  DealsStddevPopulationChassisNumberDesc = 'DEALS_STDDEV_POPULATION_CHASSIS_NUMBER_DESC',
  DealsStddevPopulationLicensePlateNumberAsc = 'DEALS_STDDEV_POPULATION_LICENSE_PLATE_NUMBER_ASC',
  DealsStddevPopulationLicensePlateNumberDesc = 'DEALS_STDDEV_POPULATION_LICENSE_PLATE_NUMBER_DESC',
  DealsStddevPopulationDeliveryDateAsc = 'DEALS_STDDEV_POPULATION_DELIVERY_DATE_ASC',
  DealsStddevPopulationDeliveryDateDesc = 'DEALS_STDDEV_POPULATION_DELIVERY_DATE_DESC',
  DealsStddevPopulationDeliveryMileageAsc = 'DEALS_STDDEV_POPULATION_DELIVERY_MILEAGE_ASC',
  DealsStddevPopulationDeliveryMileageDesc = 'DEALS_STDDEV_POPULATION_DELIVERY_MILEAGE_DESC',
  DealsStddevPopulationInventoryDateAsc = 'DEALS_STDDEV_POPULATION_INVENTORY_DATE_ASC',
  DealsStddevPopulationInventoryDateDesc = 'DEALS_STDDEV_POPULATION_INVENTORY_DATE_DESC',
  DealsStddevPopulationInServiceDateAsc = 'DEALS_STDDEV_POPULATION_IN_SERVICE_DATE_ASC',
  DealsStddevPopulationInServiceDateDesc = 'DEALS_STDDEV_POPULATION_IN_SERVICE_DATE_DESC',
  DealsStddevPopulationVinExplosionYearAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_YEAR_ASC',
  DealsStddevPopulationVinExplosionYearDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_YEAR_DESC',
  DealsStddevPopulationVinExplosionMakeAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_MAKE_ASC',
  DealsStddevPopulationVinExplosionMakeDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_MAKE_DESC',
  DealsStddevPopulationVinExplosionModelAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_MODEL_ASC',
  DealsStddevPopulationVinExplosionModelDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_MODEL_DESC',
  DealsStddevPopulationVinExplosionTrimAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_TRIM_ASC',
  DealsStddevPopulationVinExplosionTrimDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_TRIM_DESC',
  DealsStddevPopulationVinExplosionTransmissionTypeAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsStddevPopulationVinExplosionTransmissionTypeDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsStddevPopulationVinExplosionFuelTypeAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsStddevPopulationVinExplosionFuelTypeDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsStddevPopulationVinExplosionEngineSizeAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsStddevPopulationVinExplosionEngineSizeDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsStddevPopulationVinExplosionGvwRangeAsc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsStddevPopulationVinExplosionGvwRangeDesc = 'DEALS_STDDEV_POPULATION_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsStddevPopulationTrade_1VinAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_VIN_ASC',
  DealsStddevPopulationTrade_1VinDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_VIN_DESC',
  DealsStddevPopulationTrade_1YearAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_YEAR_ASC',
  DealsStddevPopulationTrade_1YearDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_YEAR_DESC',
  DealsStddevPopulationTrade_1MakeAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_MAKE_ASC',
  DealsStddevPopulationTrade_1MakeDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_MAKE_DESC',
  DealsStddevPopulationTrade_1ModelAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_MODEL_ASC',
  DealsStddevPopulationTrade_1ModelDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_MODEL_DESC',
  DealsStddevPopulationTrade_1OdometerAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_ODOMETER_ASC',
  DealsStddevPopulationTrade_1OdometerDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_ODOMETER_DESC',
  DealsStddevPopulationTrade_1ActualCashValueAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsStddevPopulationTrade_1ActualCashValueDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsStddevPopulationTrade_1GrossAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_GROSS_ASC',
  DealsStddevPopulationTrade_1GrossDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_GROSS_DESC',
  DealsStddevPopulationTrade_1PayoffAsc = 'DEALS_STDDEV_POPULATION_TRADE_1_PAYOFF_ASC',
  DealsStddevPopulationTrade_1PayoffDesc = 'DEALS_STDDEV_POPULATION_TRADE_1_PAYOFF_DESC',
  DealsStddevPopulationTrade_2VinAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_VIN_ASC',
  DealsStddevPopulationTrade_2VinDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_VIN_DESC',
  DealsStddevPopulationTrade_2YearAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_YEAR_ASC',
  DealsStddevPopulationTrade_2YearDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_YEAR_DESC',
  DealsStddevPopulationTrade_2MakeAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_MAKE_ASC',
  DealsStddevPopulationTrade_2MakeDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_MAKE_DESC',
  DealsStddevPopulationTrade_2ModelAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_MODEL_ASC',
  DealsStddevPopulationTrade_2ModelDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_MODEL_DESC',
  DealsStddevPopulationTrade_2OdometerAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_ODOMETER_ASC',
  DealsStddevPopulationTrade_2OdometerDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_ODOMETER_DESC',
  DealsStddevPopulationTrade_2ActualCashValueAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsStddevPopulationTrade_2ActualCashValueDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsStddevPopulationTrade_2GrossAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_GROSS_ASC',
  DealsStddevPopulationTrade_2GrossDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_GROSS_DESC',
  DealsStddevPopulationTrade_2PayoffAsc = 'DEALS_STDDEV_POPULATION_TRADE_2_PAYOFF_ASC',
  DealsStddevPopulationTrade_2PayoffDesc = 'DEALS_STDDEV_POPULATION_TRADE_2_PAYOFF_DESC',
  DealsStddevPopulationSalesman_1NumberAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_NUMBER_ASC',
  DealsStddevPopulationSalesman_1NumberDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_NUMBER_DESC',
  DealsStddevPopulationSalesman_1NameAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_NAME_ASC',
  DealsStddevPopulationSalesman_1NameDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_NAME_DESC',
  DealsStddevPopulationSalesman_2NumberAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_NUMBER_ASC',
  DealsStddevPopulationSalesman_2NumberDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_NUMBER_DESC',
  DealsStddevPopulationSalesman_2NameAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_NAME_ASC',
  DealsStddevPopulationSalesman_2NameDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_NAME_DESC',
  DealsStddevPopulationSalesman_3NumberAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_NUMBER_ASC',
  DealsStddevPopulationSalesman_3NumberDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_NUMBER_DESC',
  DealsStddevPopulationSalesman_3NameAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_NAME_ASC',
  DealsStddevPopulationSalesman_3NameDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_NAME_DESC',
  DealsStddevPopulationClosingManagerNumberAsc = 'DEALS_STDDEV_POPULATION_CLOSING_MANAGER_NUMBER_ASC',
  DealsStddevPopulationClosingManagerNumberDesc = 'DEALS_STDDEV_POPULATION_CLOSING_MANAGER_NUMBER_DESC',
  DealsStddevPopulationClosingManagerNameAsc = 'DEALS_STDDEV_POPULATION_CLOSING_MANAGER_NAME_ASC',
  DealsStddevPopulationClosingManagerNameDesc = 'DEALS_STDDEV_POPULATION_CLOSING_MANAGER_NAME_DESC',
  DealsStddevPopulationFinanceManagerNumberAsc = 'DEALS_STDDEV_POPULATION_FINANCE_MANAGER_NUMBER_ASC',
  DealsStddevPopulationFinanceManagerNumberDesc = 'DEALS_STDDEV_POPULATION_FINANCE_MANAGER_NUMBER_DESC',
  DealsStddevPopulationFinanceManagerNameAsc = 'DEALS_STDDEV_POPULATION_FINANCE_MANAGER_NAME_ASC',
  DealsStddevPopulationFinanceManagerNameDesc = 'DEALS_STDDEV_POPULATION_FINANCE_MANAGER_NAME_DESC',
  DealsStddevPopulationSalesmanManagerNumberAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_MANAGER_NUMBER_ASC',
  DealsStddevPopulationSalesmanManagerNumberDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_MANAGER_NUMBER_DESC',
  DealsStddevPopulationSalesmanManagerNameAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_MANAGER_NAME_ASC',
  DealsStddevPopulationSalesmanManagerNameDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_MANAGER_NAME_DESC',
  DealsStddevPopulationMsrpAsc = 'DEALS_STDDEV_POPULATION_MSRP_ASC',
  DealsStddevPopulationMsrpDesc = 'DEALS_STDDEV_POPULATION_MSRP_DESC',
  DealsStddevPopulationListPriceAsc = 'DEALS_STDDEV_POPULATION_LIST_PRICE_ASC',
  DealsStddevPopulationListPriceDesc = 'DEALS_STDDEV_POPULATION_LIST_PRICE_DESC',
  DealsStddevPopulationSalesPriceAsc = 'DEALS_STDDEV_POPULATION_SALES_PRICE_ASC',
  DealsStddevPopulationSalesPriceDesc = 'DEALS_STDDEV_POPULATION_SALES_PRICE_DESC',
  DealsStddevPopulationJournalPriceAsc = 'DEALS_STDDEV_POPULATION_JOURNAL_PRICE_ASC',
  DealsStddevPopulationJournalPriceDesc = 'DEALS_STDDEV_POPULATION_JOURNAL_PRICE_DESC',
  DealsStddevPopulationCostAsc = 'DEALS_STDDEV_POPULATION_COST_ASC',
  DealsStddevPopulationCostDesc = 'DEALS_STDDEV_POPULATION_COST_DESC',
  DealsStddevPopulationJournalCostAsc = 'DEALS_STDDEV_POPULATION_JOURNAL_COST_ASC',
  DealsStddevPopulationJournalCostDesc = 'DEALS_STDDEV_POPULATION_JOURNAL_COST_DESC',
  DealsStddevPopulationAdjustmentsAsc = 'DEALS_STDDEV_POPULATION_ADJUSTMENTS_ASC',
  DealsStddevPopulationAdjustmentsDesc = 'DEALS_STDDEV_POPULATION_ADJUSTMENTS_DESC',
  DealsStddevPopulationAdjustedCostAsc = 'DEALS_STDDEV_POPULATION_ADJUSTED_COST_ASC',
  DealsStddevPopulationAdjustedCostDesc = 'DEALS_STDDEV_POPULATION_ADJUSTED_COST_DESC',
  DealsStddevPopulationIncentivesAsc = 'DEALS_STDDEV_POPULATION_INCENTIVES_ASC',
  DealsStddevPopulationIncentivesDesc = 'DEALS_STDDEV_POPULATION_INCENTIVES_DESC',
  DealsStddevPopulationPackAmountAsc = 'DEALS_STDDEV_POPULATION_PACK_AMOUNT_ASC',
  DealsStddevPopulationPackAmountDesc = 'DEALS_STDDEV_POPULATION_PACK_AMOUNT_DESC',
  DealsStddevPopulationSaleNetAsc = 'DEALS_STDDEV_POPULATION_SALE_NET_ASC',
  DealsStddevPopulationSaleNetDesc = 'DEALS_STDDEV_POPULATION_SALE_NET_DESC',
  DealsStddevPopulationTotalTradeActualCashValueAsc = 'DEALS_STDDEV_POPULATION_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsStddevPopulationTotalTradeActualCashValueDesc = 'DEALS_STDDEV_POPULATION_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsStddevPopulationTotalTradeGrossAsc = 'DEALS_STDDEV_POPULATION_TOTAL_TRADE_GROSS_ASC',
  DealsStddevPopulationTotalTradeGrossDesc = 'DEALS_STDDEV_POPULATION_TOTAL_TRADE_GROSS_DESC',
  DealsStddevPopulationWeOweFrontAsc = 'DEALS_STDDEV_POPULATION_WE_OWE_FRONT_ASC',
  DealsStddevPopulationWeOweFrontDesc = 'DEALS_STDDEV_POPULATION_WE_OWE_FRONT_DESC',
  DealsStddevPopulationTotalFrontFeesAftermarketProfitAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsStddevPopulationTotalFrontFeesAftermarketProfitDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsStddevPopulationTotalFrontCommissionAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_COMMISSION_ASC',
  DealsStddevPopulationTotalFrontCommissionDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_COMMISSION_DESC',
  DealsStddevPopulationTotalFrontSalesAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_SALES_ASC',
  DealsStddevPopulationTotalFrontSalesDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_SALES_DESC',
  DealsStddevPopulationTotalFrontCostAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_COST_ASC',
  DealsStddevPopulationTotalFrontCostDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FRONT_COST_DESC',
  DealsStddevPopulationFrontGrossAsc = 'DEALS_STDDEV_POPULATION_FRONT_GROSS_ASC',
  DealsStddevPopulationFrontGrossDesc = 'DEALS_STDDEV_POPULATION_FRONT_GROSS_DESC',
  DealsStddevPopulationFinanceProfitAsc = 'DEALS_STDDEV_POPULATION_FINANCE_PROFIT_ASC',
  DealsStddevPopulationFinanceProfitDesc = 'DEALS_STDDEV_POPULATION_FINANCE_PROFIT_DESC',
  DealsStddevPopulationTotalWarrantyProfitAsc = 'DEALS_STDDEV_POPULATION_TOTAL_WARRANTY_PROFIT_ASC',
  DealsStddevPopulationTotalWarrantyProfitDesc = 'DEALS_STDDEV_POPULATION_TOTAL_WARRANTY_PROFIT_DESC',
  DealsStddevPopulationWeOweBackAsc = 'DEALS_STDDEV_POPULATION_WE_OWE_BACK_ASC',
  DealsStddevPopulationWeOweBackDesc = 'DEALS_STDDEV_POPULATION_WE_OWE_BACK_DESC',
  DealsStddevPopulationInsuranceProfitAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_PROFIT_ASC',
  DealsStddevPopulationInsuranceProfitDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_PROFIT_DESC',
  DealsStddevPopulationTotalBackFeesAftermarketProfitAsc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsStddevPopulationTotalBackFeesAftermarketProfitDesc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsStddevPopulationFinanceReserveAsc = 'DEALS_STDDEV_POPULATION_FINANCE_RESERVE_ASC',
  DealsStddevPopulationFinanceReserveDesc = 'DEALS_STDDEV_POPULATION_FINANCE_RESERVE_DESC',
  DealsStddevPopulationTotalBackCommissionAsc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_COMMISSION_ASC',
  DealsStddevPopulationTotalBackCommissionDesc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_COMMISSION_DESC',
  DealsStddevPopulationTotalBackSalesAsc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_SALES_ASC',
  DealsStddevPopulationTotalBackSalesDesc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_SALES_DESC',
  DealsStddevPopulationTotalBackCostAsc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_COST_ASC',
  DealsStddevPopulationTotalBackCostDesc = 'DEALS_STDDEV_POPULATION_TOTAL_BACK_COST_DESC',
  DealsStddevPopulationBackGrossAsc = 'DEALS_STDDEV_POPULATION_BACK_GROSS_ASC',
  DealsStddevPopulationBackGrossDesc = 'DEALS_STDDEV_POPULATION_BACK_GROSS_DESC',
  DealsStddevPopulationTotalProfitAsc = 'DEALS_STDDEV_POPULATION_TOTAL_PROFIT_ASC',
  DealsStddevPopulationTotalProfitDesc = 'DEALS_STDDEV_POPULATION_TOTAL_PROFIT_DESC',
  DealsStddevPopulationGrossProfitAsc = 'DEALS_STDDEV_POPULATION_GROSS_PROFIT_ASC',
  DealsStddevPopulationGrossProfitDesc = 'DEALS_STDDEV_POPULATION_GROSS_PROFIT_DESC',
  DealsStddevPopulationGrossPayableAsc = 'DEALS_STDDEV_POPULATION_GROSS_PAYABLE_ASC',
  DealsStddevPopulationGrossPayableDesc = 'DEALS_STDDEV_POPULATION_GROSS_PAYABLE_DESC',
  DealsStddevPopulationDealStatusAsc = 'DEALS_STDDEV_POPULATION_DEAL_STATUS_ASC',
  DealsStddevPopulationDealStatusDesc = 'DEALS_STDDEV_POPULATION_DEAL_STATUS_DESC',
  DealsStddevPopulationEntryDateAsc = 'DEALS_STDDEV_POPULATION_ENTRY_DATE_ASC',
  DealsStddevPopulationEntryDateDesc = 'DEALS_STDDEV_POPULATION_ENTRY_DATE_DESC',
  DealsStddevPopulationBookedDateAsc = 'DEALS_STDDEV_POPULATION_BOOKED_DATE_ASC',
  DealsStddevPopulationBookedDateDesc = 'DEALS_STDDEV_POPULATION_BOOKED_DATE_DESC',
  DealsStddevPopulationFinalizedDateAsc = 'DEALS_STDDEV_POPULATION_FINALIZED_DATE_ASC',
  DealsStddevPopulationFinalizedDateDesc = 'DEALS_STDDEV_POPULATION_FINALIZED_DATE_DESC',
  DealsStddevPopulationContractDateAsc = 'DEALS_STDDEV_POPULATION_CONTRACT_DATE_ASC',
  DealsStddevPopulationContractDateDesc = 'DEALS_STDDEV_POPULATION_CONTRACT_DATE_DESC',
  DealsStddevPopulationAccountingDateAsc = 'DEALS_STDDEV_POPULATION_ACCOUNTING_DATE_ASC',
  DealsStddevPopulationAccountingDateDesc = 'DEALS_STDDEV_POPULATION_ACCOUNTING_DATE_DESC',
  DealsStddevPopulationStatusChangeDateAsc = 'DEALS_STDDEV_POPULATION_STATUS_CHANGE_DATE_ASC',
  DealsStddevPopulationStatusChangeDateDesc = 'DEALS_STDDEV_POPULATION_STATUS_CHANGE_DATE_DESC',
  DealsStddevPopulationFirstPayDateAsc = 'DEALS_STDDEV_POPULATION_FIRST_PAY_DATE_ASC',
  DealsStddevPopulationFirstPayDateDesc = 'DEALS_STDDEV_POPULATION_FIRST_PAY_DATE_DESC',
  DealsStddevPopulationDealTypeAsc = 'DEALS_STDDEV_POPULATION_DEAL_TYPE_ASC',
  DealsStddevPopulationDealTypeDesc = 'DEALS_STDDEV_POPULATION_DEAL_TYPE_DESC',
  DealsStddevPopulationSaleTypeAsc = 'DEALS_STDDEV_POPULATION_SALE_TYPE_ASC',
  DealsStddevPopulationSaleTypeDesc = 'DEALS_STDDEV_POPULATION_SALE_TYPE_DESC',
  DealsStddevPopulationBankIdAsc = 'DEALS_STDDEV_POPULATION_BANK_ID_ASC',
  DealsStddevPopulationBankIdDesc = 'DEALS_STDDEV_POPULATION_BANK_ID_DESC',
  DealsStddevPopulationBankNameAsc = 'DEALS_STDDEV_POPULATION_BANK_NAME_ASC',
  DealsStddevPopulationBankNameDesc = 'DEALS_STDDEV_POPULATION_BANK_NAME_DESC',
  DealsStddevPopulationBankAddressAsc = 'DEALS_STDDEV_POPULATION_BANK_ADDRESS_ASC',
  DealsStddevPopulationBankAddressDesc = 'DEALS_STDDEV_POPULATION_BANK_ADDRESS_DESC',
  DealsStddevPopulationTermAsc = 'DEALS_STDDEV_POPULATION_TERM_ASC',
  DealsStddevPopulationTermDesc = 'DEALS_STDDEV_POPULATION_TERM_DESC',
  DealsStddevPopulationAmountFinancedAsc = 'DEALS_STDDEV_POPULATION_AMOUNT_FINANCED_ASC',
  DealsStddevPopulationAmountFinancedDesc = 'DEALS_STDDEV_POPULATION_AMOUNT_FINANCED_DESC',
  DealsStddevPopulationAprAsc = 'DEALS_STDDEV_POPULATION_APR_ASC',
  DealsStddevPopulationAprDesc = 'DEALS_STDDEV_POPULATION_APR_DESC',
  DealsStddevPopulationMonthlyPaymentAsc = 'DEALS_STDDEV_POPULATION_MONTHLY_PAYMENT_ASC',
  DealsStddevPopulationMonthlyPaymentDesc = 'DEALS_STDDEV_POPULATION_MONTHLY_PAYMENT_DESC',
  DealsStddevPopulationPaymentTotalAsc = 'DEALS_STDDEV_POPULATION_PAYMENT_TOTAL_ASC',
  DealsStddevPopulationPaymentTotalDesc = 'DEALS_STDDEV_POPULATION_PAYMENT_TOTAL_DESC',
  DealsStddevPopulationRebatesAsc = 'DEALS_STDDEV_POPULATION_REBATES_ASC',
  DealsStddevPopulationRebatesDesc = 'DEALS_STDDEV_POPULATION_REBATES_DESC',
  DealsStddevPopulationDepositAsc = 'DEALS_STDDEV_POPULATION_DEPOSIT_ASC',
  DealsStddevPopulationDepositDesc = 'DEALS_STDDEV_POPULATION_DEPOSIT_DESC',
  DealsStddevPopulationDownPaymentAsc = 'DEALS_STDDEV_POPULATION_DOWN_PAYMENT_ASC',
  DealsStddevPopulationDownPaymentDesc = 'DEALS_STDDEV_POPULATION_DOWN_PAYMENT_DESC',
  DealsStddevPopulationTotalNetTradesAsc = 'DEALS_STDDEV_POPULATION_TOTAL_NET_TRADES_ASC',
  DealsStddevPopulationTotalNetTradesDesc = 'DEALS_STDDEV_POPULATION_TOTAL_NET_TRADES_DESC',
  DealsStddevPopulationTotalDownAsc = 'DEALS_STDDEV_POPULATION_TOTAL_DOWN_ASC',
  DealsStddevPopulationTotalDownDesc = 'DEALS_STDDEV_POPULATION_TOTAL_DOWN_DESC',
  DealsStddevPopulationBalloonAmountAsc = 'DEALS_STDDEV_POPULATION_BALLOON_AMOUNT_ASC',
  DealsStddevPopulationBalloonAmountDesc = 'DEALS_STDDEV_POPULATION_BALLOON_AMOUNT_DESC',
  DealsStddevPopulationAdjustedBalloonAmountAsc = 'DEALS_STDDEV_POPULATION_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsStddevPopulationAdjustedBalloonAmountDesc = 'DEALS_STDDEV_POPULATION_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsStddevPopulationHoldbackAmountAsc = 'DEALS_STDDEV_POPULATION_HOLDBACK_AMOUNT_ASC',
  DealsStddevPopulationHoldbackAmountDesc = 'DEALS_STDDEV_POPULATION_HOLDBACK_AMOUNT_DESC',
  DealsStddevPopulationTotalDriveOffAmountAsc = 'DEALS_STDDEV_POPULATION_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsStddevPopulationTotalDriveOffAmountDesc = 'DEALS_STDDEV_POPULATION_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsStddevPopulationLicenseFeeAsc = 'DEALS_STDDEV_POPULATION_LICENSE_FEE_ASC',
  DealsStddevPopulationLicenseFeeDesc = 'DEALS_STDDEV_POPULATION_LICENSE_FEE_DESC',
  DealsStddevPopulationRegistrationFeeAsc = 'DEALS_STDDEV_POPULATION_REGISTRATION_FEE_ASC',
  DealsStddevPopulationRegistrationFeeDesc = 'DEALS_STDDEV_POPULATION_REGISTRATION_FEE_DESC',
  DealsStddevPopulationDocumentationFeeAsc = 'DEALS_STDDEV_POPULATION_DOCUMENTATION_FEE_ASC',
  DealsStddevPopulationDocumentationFeeDesc = 'DEALS_STDDEV_POPULATION_DOCUMENTATION_FEE_DESC',
  DealsStddevPopulationFinanceChargeAsc = 'DEALS_STDDEV_POPULATION_FINANCE_CHARGE_ASC',
  DealsStddevPopulationFinanceChargeDesc = 'DEALS_STDDEV_POPULATION_FINANCE_CHARGE_DESC',
  DealsStddevPopulationTotalPickupPaymentsAsc = 'DEALS_STDDEV_POPULATION_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsStddevPopulationTotalPickupPaymentsDesc = 'DEALS_STDDEV_POPULATION_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsStddevPopulationSellRateAsc = 'DEALS_STDDEV_POPULATION_SELL_RATE_ASC',
  DealsStddevPopulationSellRateDesc = 'DEALS_STDDEV_POPULATION_SELL_RATE_DESC',
  DealsStddevPopulationBuyRateAsc = 'DEALS_STDDEV_POPULATION_BUY_RATE_ASC',
  DealsStddevPopulationBuyRateDesc = 'DEALS_STDDEV_POPULATION_BUY_RATE_DESC',
  DealsStddevPopulationResidualRateAsc = 'DEALS_STDDEV_POPULATION_RESIDUAL_RATE_ASC',
  DealsStddevPopulationResidualRateDesc = 'DEALS_STDDEV_POPULATION_RESIDUAL_RATE_DESC',
  DealsStddevPopulationResidualAmountAsc = 'DEALS_STDDEV_POPULATION_RESIDUAL_AMOUNT_ASC',
  DealsStddevPopulationResidualAmountDesc = 'DEALS_STDDEV_POPULATION_RESIDUAL_AMOUNT_DESC',
  DealsStddevPopulationAllowedMilesAsc = 'DEALS_STDDEV_POPULATION_ALLOWED_MILES_ASC',
  DealsStddevPopulationAllowedMilesDesc = 'DEALS_STDDEV_POPULATION_ALLOWED_MILES_DESC',
  DealsStddevPopulationEstimatedMilesAsc = 'DEALS_STDDEV_POPULATION_ESTIMATED_MILES_ASC',
  DealsStddevPopulationEstimatedMilesDesc = 'DEALS_STDDEV_POPULATION_ESTIMATED_MILES_DESC',
  DealsStddevPopulationMileageRateAsc = 'DEALS_STDDEV_POPULATION_MILEAGE_RATE_ASC',
  DealsStddevPopulationMileageRateDesc = 'DEALS_STDDEV_POPULATION_MILEAGE_RATE_DESC',
  DealsStddevPopulationAcquisitionFeeAsc = 'DEALS_STDDEV_POPULATION_ACQUISITION_FEE_ASC',
  DealsStddevPopulationAcquisitionFeeDesc = 'DEALS_STDDEV_POPULATION_ACQUISITION_FEE_DESC',
  DealsStddevPopulationBasePaymentAsc = 'DEALS_STDDEV_POPULATION_BASE_PAYMENT_ASC',
  DealsStddevPopulationBasePaymentDesc = 'DEALS_STDDEV_POPULATION_BASE_PAYMENT_DESC',
  DealsStddevPopulationSecurityDepositAsc = 'DEALS_STDDEV_POPULATION_SECURITY_DEPOSIT_ASC',
  DealsStddevPopulationSecurityDepositDesc = 'DEALS_STDDEV_POPULATION_SECURITY_DEPOSIT_DESC',
  DealsStddevPopulationTotalCapitalReductionAsc = 'DEALS_STDDEV_POPULATION_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsStddevPopulationTotalCapitalReductionDesc = 'DEALS_STDDEV_POPULATION_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsStddevPopulationNetCapitalCostAsc = 'DEALS_STDDEV_POPULATION_NET_CAPITAL_COST_ASC',
  DealsStddevPopulationNetCapitalCostDesc = 'DEALS_STDDEV_POPULATION_NET_CAPITAL_COST_DESC',
  DealsStddevPopulationLeaseDepreciationValueAsc = 'DEALS_STDDEV_POPULATION_LEASE_DEPRECIATION_VALUE_ASC',
  DealsStddevPopulationLeaseDepreciationValueDesc = 'DEALS_STDDEV_POPULATION_LEASE_DEPRECIATION_VALUE_DESC',
  DealsStddevPopulationDealerFeesAsc = 'DEALS_STDDEV_POPULATION_DEALER_FEES_ASC',
  DealsStddevPopulationDealerFeesDesc = 'DEALS_STDDEV_POPULATION_DEALER_FEES_DESC',
  DealsStddevPopulationGovernmentFeesAsc = 'DEALS_STDDEV_POPULATION_GOVERNMENT_FEES_ASC',
  DealsStddevPopulationGovernmentFeesDesc = 'DEALS_STDDEV_POPULATION_GOVERNMENT_FEES_DESC',
  DealsStddevPopulationTotalTaxAsc = 'DEALS_STDDEV_POPULATION_TOTAL_TAX_ASC',
  DealsStddevPopulationTotalTaxDesc = 'DEALS_STDDEV_POPULATION_TOTAL_TAX_DESC',
  DealsStddevPopulationRegistrationStateAsc = 'DEALS_STDDEV_POPULATION_REGISTRATION_STATE_ASC',
  DealsStddevPopulationRegistrationStateDesc = 'DEALS_STDDEV_POPULATION_REGISTRATION_STATE_DESC',
  DealsStddevPopulationReportOfSaleNumberAsc = 'DEALS_STDDEV_POPULATION_REPORT_OF_SALE_NUMBER_ASC',
  DealsStddevPopulationReportOfSaleNumberDesc = 'DEALS_STDDEV_POPULATION_REPORT_OF_SALE_NUMBER_DESC',
  DealsStddevPopulationSalesman_1TotalCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsStddevPopulationSalesman_1TotalCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsStddevPopulationSalesman_1FrontCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsStddevPopulationSalesman_1FrontCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsStddevPopulationSalesman_1BackCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsStddevPopulationSalesman_1BackCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsStddevPopulationSalesman_2TotalCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsStddevPopulationSalesman_2TotalCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsStddevPopulationSalesman_2FrontCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsStddevPopulationSalesman_2FrontCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsStddevPopulationSalesman_2BackCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsStddevPopulationSalesman_2BackCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsStddevPopulationSalesman_3TotalCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsStddevPopulationSalesman_3TotalCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsStddevPopulationSalesman_3FrontCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsStddevPopulationSalesman_3FrontCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsStddevPopulationSalesman_3BackCommissionAsc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsStddevPopulationSalesman_3BackCommissionDesc = 'DEALS_STDDEV_POPULATION_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsStddevPopulationWarranty_1NameAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_NAME_ASC',
  DealsStddevPopulationWarranty_1NameDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_NAME_DESC',
  DealsStddevPopulationWarranty_1SaleAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_SALE_ASC',
  DealsStddevPopulationWarranty_1SaleDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_SALE_DESC',
  DealsStddevPopulationWarranty_1CostAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_COST_ASC',
  DealsStddevPopulationWarranty_1CostDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_COST_DESC',
  DealsStddevPopulationWarranty_1MilesAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_MILES_ASC',
  DealsStddevPopulationWarranty_1MilesDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_MILES_DESC',
  DealsStddevPopulationWarranty_1TermAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_TERM_ASC',
  DealsStddevPopulationWarranty_1TermDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_1_TERM_DESC',
  DealsStddevPopulationWarranty_2NameAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_NAME_ASC',
  DealsStddevPopulationWarranty_2NameDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_NAME_DESC',
  DealsStddevPopulationWarranty_2SaleAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_SALE_ASC',
  DealsStddevPopulationWarranty_2SaleDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_SALE_DESC',
  DealsStddevPopulationWarranty_2CostAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_COST_ASC',
  DealsStddevPopulationWarranty_2CostDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_COST_DESC',
  DealsStddevPopulationWarranty_2MilesAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_MILES_ASC',
  DealsStddevPopulationWarranty_2MilesDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_MILES_DESC',
  DealsStddevPopulationWarranty_2TermAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_TERM_ASC',
  DealsStddevPopulationWarranty_2TermDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_2_TERM_DESC',
  DealsStddevPopulationWarranty_3NameAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_NAME_ASC',
  DealsStddevPopulationWarranty_3NameDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_NAME_DESC',
  DealsStddevPopulationWarranty_3SaleAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_SALE_ASC',
  DealsStddevPopulationWarranty_3SaleDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_SALE_DESC',
  DealsStddevPopulationWarranty_3CostAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_COST_ASC',
  DealsStddevPopulationWarranty_3CostDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_COST_DESC',
  DealsStddevPopulationWarranty_3MilesAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_MILES_ASC',
  DealsStddevPopulationWarranty_3MilesDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_MILES_DESC',
  DealsStddevPopulationWarranty_3TermAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_TERM_ASC',
  DealsStddevPopulationWarranty_3TermDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_3_TERM_DESC',
  DealsStddevPopulationWarranty_4NameAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_NAME_ASC',
  DealsStddevPopulationWarranty_4NameDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_NAME_DESC',
  DealsStddevPopulationWarranty_4SaleAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_SALE_ASC',
  DealsStddevPopulationWarranty_4SaleDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_SALE_DESC',
  DealsStddevPopulationWarranty_4CostAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_COST_ASC',
  DealsStddevPopulationWarranty_4CostDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_COST_DESC',
  DealsStddevPopulationWarranty_4MilesAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_MILES_ASC',
  DealsStddevPopulationWarranty_4MilesDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_MILES_DESC',
  DealsStddevPopulationWarranty_4TermAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_TERM_ASC',
  DealsStddevPopulationWarranty_4TermDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_4_TERM_DESC',
  DealsStddevPopulationWarranty_5NameAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_NAME_ASC',
  DealsStddevPopulationWarranty_5NameDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_NAME_DESC',
  DealsStddevPopulationWarranty_5SaleAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_SALE_ASC',
  DealsStddevPopulationWarranty_5SaleDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_SALE_DESC',
  DealsStddevPopulationWarranty_5CostAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_COST_ASC',
  DealsStddevPopulationWarranty_5CostDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_COST_DESC',
  DealsStddevPopulationWarranty_5MilesAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_MILES_ASC',
  DealsStddevPopulationWarranty_5MilesDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_MILES_DESC',
  DealsStddevPopulationWarranty_5TermAsc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_TERM_ASC',
  DealsStddevPopulationWarranty_5TermDesc = 'DEALS_STDDEV_POPULATION_WARRANTY_5_TERM_DESC',
  DealsStddevPopulationTotalFeeAftermarketSaleAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsStddevPopulationTotalFeeAftermarketSaleDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsStddevPopulationTotalFeeAftermarketCostAsc = 'DEALS_STDDEV_POPULATION_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsStddevPopulationTotalFeeAftermarketCostDesc = 'DEALS_STDDEV_POPULATION_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsStddevPopulationFeeAftermarket_1NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_1NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_1SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_1SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_1CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_COST_ASC',
  DealsStddevPopulationFeeAftermarket_1CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_COST_DESC',
  DealsStddevPopulationFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_2NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_2NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_2SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_2SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_2CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_COST_ASC',
  DealsStddevPopulationFeeAftermarket_2CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_COST_DESC',
  DealsStddevPopulationFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_3NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_3NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_3SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_3SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_3CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_COST_ASC',
  DealsStddevPopulationFeeAftermarket_3CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_COST_DESC',
  DealsStddevPopulationFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_4NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_4NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_4SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_4SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_4CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_COST_ASC',
  DealsStddevPopulationFeeAftermarket_4CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_COST_DESC',
  DealsStddevPopulationFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_5NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_5NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_5SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_5SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_5CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_COST_ASC',
  DealsStddevPopulationFeeAftermarket_5CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_COST_DESC',
  DealsStddevPopulationFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_6NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_6NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_6SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_6SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_6CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_COST_ASC',
  DealsStddevPopulationFeeAftermarket_6CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_COST_DESC',
  DealsStddevPopulationFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_7NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_7NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_7SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_7SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_7CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_COST_ASC',
  DealsStddevPopulationFeeAftermarket_7CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_COST_DESC',
  DealsStddevPopulationFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_8NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_8NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_8SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_8SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_8CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_COST_ASC',
  DealsStddevPopulationFeeAftermarket_8CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_COST_DESC',
  DealsStddevPopulationFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_9NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_9NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_9SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_9SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_9CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_COST_ASC',
  DealsStddevPopulationFeeAftermarket_9CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_COST_DESC',
  DealsStddevPopulationFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_10NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_10NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_10SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_10SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_10CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_COST_ASC',
  DealsStddevPopulationFeeAftermarket_10CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_COST_DESC',
  DealsStddevPopulationFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_11NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_11NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_11SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_11SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_11CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_COST_ASC',
  DealsStddevPopulationFeeAftermarket_11CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_COST_DESC',
  DealsStddevPopulationFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_12NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_12NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_12SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_12SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_12CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_COST_ASC',
  DealsStddevPopulationFeeAftermarket_12CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_COST_DESC',
  DealsStddevPopulationFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_13NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_13NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_13SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_13SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_13CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_COST_ASC',
  DealsStddevPopulationFeeAftermarket_13CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_COST_DESC',
  DealsStddevPopulationFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_14NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_14NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_14SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_14SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_14CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_COST_ASC',
  DealsStddevPopulationFeeAftermarket_14CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_COST_DESC',
  DealsStddevPopulationFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_15NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_15NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_15SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_15SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_15CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_COST_ASC',
  DealsStddevPopulationFeeAftermarket_15CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_COST_DESC',
  DealsStddevPopulationFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_16NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_16NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_16SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_16SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_16CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_COST_ASC',
  DealsStddevPopulationFeeAftermarket_16CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_COST_DESC',
  DealsStddevPopulationFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_17NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_17NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_17SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_17SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_17CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_COST_ASC',
  DealsStddevPopulationFeeAftermarket_17CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_COST_DESC',
  DealsStddevPopulationFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_18NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_18NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_18SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_18SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_18CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_COST_ASC',
  DealsStddevPopulationFeeAftermarket_18CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_COST_DESC',
  DealsStddevPopulationFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_19NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_19NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_19SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_19SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_19CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_COST_ASC',
  DealsStddevPopulationFeeAftermarket_19CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_COST_DESC',
  DealsStddevPopulationFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationFeeAftermarket_20NameAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_NAME_ASC',
  DealsStddevPopulationFeeAftermarket_20NameDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_NAME_DESC',
  DealsStddevPopulationFeeAftermarket_20SaleAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_SALE_ASC',
  DealsStddevPopulationFeeAftermarket_20SaleDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_SALE_DESC',
  DealsStddevPopulationFeeAftermarket_20CostAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_COST_ASC',
  DealsStddevPopulationFeeAftermarket_20CostDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_COST_DESC',
  DealsStddevPopulationFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsStddevPopulationFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_STDDEV_POPULATION_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsStddevPopulationInsurance_1TypeAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_TYPE_ASC',
  DealsStddevPopulationInsurance_1TypeDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_TYPE_DESC',
  DealsStddevPopulationInsurance_1NameAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_NAME_ASC',
  DealsStddevPopulationInsurance_1NameDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_NAME_DESC',
  DealsStddevPopulationInsurance_1SaleAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_SALE_ASC',
  DealsStddevPopulationInsurance_1SaleDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_SALE_DESC',
  DealsStddevPopulationInsurance_1CostAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_COST_ASC',
  DealsStddevPopulationInsurance_1CostDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_COST_DESC',
  DealsStddevPopulationInsurance_1TermAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_TERM_ASC',
  DealsStddevPopulationInsurance_1TermDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_1_TERM_DESC',
  DealsStddevPopulationInsurance_2TypeAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_TYPE_ASC',
  DealsStddevPopulationInsurance_2TypeDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_TYPE_DESC',
  DealsStddevPopulationInsurance_2NameAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_NAME_ASC',
  DealsStddevPopulationInsurance_2NameDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_NAME_DESC',
  DealsStddevPopulationInsurance_2SaleAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_SALE_ASC',
  DealsStddevPopulationInsurance_2SaleDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_SALE_DESC',
  DealsStddevPopulationInsurance_2CostAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_COST_ASC',
  DealsStddevPopulationInsurance_2CostDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_COST_DESC',
  DealsStddevPopulationInsurance_2TermAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_TERM_ASC',
  DealsStddevPopulationInsurance_2TermDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_2_TERM_DESC',
  DealsStddevPopulationInsurance_3TypeAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_TYPE_ASC',
  DealsStddevPopulationInsurance_3TypeDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_TYPE_DESC',
  DealsStddevPopulationInsurance_3NameAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_NAME_ASC',
  DealsStddevPopulationInsurance_3NameDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_NAME_DESC',
  DealsStddevPopulationInsurance_3SaleAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_SALE_ASC',
  DealsStddevPopulationInsurance_3SaleDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_SALE_DESC',
  DealsStddevPopulationInsurance_3CostAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_COST_ASC',
  DealsStddevPopulationInsurance_3CostDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_COST_DESC',
  DealsStddevPopulationInsurance_3TermAsc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_TERM_ASC',
  DealsStddevPopulationInsurance_3TermDesc = 'DEALS_STDDEV_POPULATION_INSURANCE_3_TERM_DESC',
  DealsStddevPopulationAccidentalHealthTypeAsc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsStddevPopulationAccidentalHealthTypeDesc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsStddevPopulationAccidentalHealthNameAsc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsStddevPopulationAccidentalHealthNameDesc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsStddevPopulationAccidentalHealthSaleAsc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsStddevPopulationAccidentalHealthSaleDesc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsStddevPopulationAccidentalHealthCostAsc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_COST_ASC',
  DealsStddevPopulationAccidentalHealthCostDesc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_COST_DESC',
  DealsStddevPopulationAccidentalHealthTermAsc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsStddevPopulationAccidentalHealthTermDesc = 'DEALS_STDDEV_POPULATION_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsStddevPopulationCreditLifeTypeAsc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_TYPE_ASC',
  DealsStddevPopulationCreditLifeTypeDesc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_TYPE_DESC',
  DealsStddevPopulationCreditLifeNameAsc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_NAME_ASC',
  DealsStddevPopulationCreditLifeNameDesc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_NAME_DESC',
  DealsStddevPopulationCreditLifeSaleAsc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_SALE_ASC',
  DealsStddevPopulationCreditLifeSaleDesc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_SALE_DESC',
  DealsStddevPopulationCreditLifeCostAsc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_COST_ASC',
  DealsStddevPopulationCreditLifeCostDesc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_COST_DESC',
  DealsStddevPopulationCreditLifeTermAsc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_TERM_ASC',
  DealsStddevPopulationCreditLifeTermDesc = 'DEALS_STDDEV_POPULATION_CREDIT_LIFE_TERM_DESC',
  DealsStddevPopulationLevelizedLifeTypeAsc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_TYPE_ASC',
  DealsStddevPopulationLevelizedLifeTypeDesc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_TYPE_DESC',
  DealsStddevPopulationLevelizedLifeNameAsc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_NAME_ASC',
  DealsStddevPopulationLevelizedLifeNameDesc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_NAME_DESC',
  DealsStddevPopulationLevelizedLifeSaleAsc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_SALE_ASC',
  DealsStddevPopulationLevelizedLifeSaleDesc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_SALE_DESC',
  DealsStddevPopulationLevelizedLifeCostAsc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_COST_ASC',
  DealsStddevPopulationLevelizedLifeCostDesc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_COST_DESC',
  DealsStddevPopulationLevelizedLifeTermAsc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_TERM_ASC',
  DealsStddevPopulationLevelizedLifeTermDesc = 'DEALS_STDDEV_POPULATION_LEVELIZED_LIFE_TERM_DESC',
  DealsStddevPopulationLossOfEmploymentTypeAsc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsStddevPopulationLossOfEmploymentTypeDesc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsStddevPopulationLossOfEmploymentNameAsc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsStddevPopulationLossOfEmploymentNameDesc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsStddevPopulationLossOfEmploymentSaleAsc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsStddevPopulationLossOfEmploymentSaleDesc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsStddevPopulationLossOfEmploymentCostAsc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsStddevPopulationLossOfEmploymentCostDesc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsStddevPopulationLossOfEmploymentTermAsc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsStddevPopulationLossOfEmploymentTermDesc = 'DEALS_STDDEV_POPULATION_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsStddevPopulationGuaranteedAutoProtectionTypeAsc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsStddevPopulationGuaranteedAutoProtectionTypeDesc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsStddevPopulationGuaranteedAutoProtectionNameAsc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsStddevPopulationGuaranteedAutoProtectionNameDesc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsStddevPopulationGuaranteedAutoProtectionSaleAsc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsStddevPopulationGuaranteedAutoProtectionSaleDesc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsStddevPopulationGuaranteedAutoProtectionCostAsc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsStddevPopulationGuaranteedAutoProtectionCostDesc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsStddevPopulationGuaranteedAutoProtectionTermAsc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsStddevPopulationGuaranteedAutoProtectionTermDesc = 'DEALS_STDDEV_POPULATION_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsStddevPopulationSaleCommentsAsc = 'DEALS_STDDEV_POPULATION_SALE_COMMENTS_ASC',
  DealsStddevPopulationSaleCommentsDesc = 'DEALS_STDDEV_POPULATION_SALE_COMMENTS_DESC',
  DealsStddevPopulationCassStdLine1Asc = 'DEALS_STDDEV_POPULATION_CASS_STD_LINE1_ASC',
  DealsStddevPopulationCassStdLine1Desc = 'DEALS_STDDEV_POPULATION_CASS_STD_LINE1_DESC',
  DealsStddevPopulationCassStdLine2Asc = 'DEALS_STDDEV_POPULATION_CASS_STD_LINE2_ASC',
  DealsStddevPopulationCassStdLine2Desc = 'DEALS_STDDEV_POPULATION_CASS_STD_LINE2_DESC',
  DealsStddevPopulationCassStdCityAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_CITY_ASC',
  DealsStddevPopulationCassStdCityDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_CITY_DESC',
  DealsStddevPopulationCassStdStateAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_STATE_ASC',
  DealsStddevPopulationCassStdStateDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_STATE_DESC',
  DealsStddevPopulationCassStdZipAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIP_ASC',
  DealsStddevPopulationCassStdZipDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIP_DESC',
  DealsStddevPopulationCassStdZip4Asc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIP4_ASC',
  DealsStddevPopulationCassStdZip4Desc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIP4_DESC',
  DealsStddevPopulationCassStdDpbcAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_DPBC_ASC',
  DealsStddevPopulationCassStdDpbcDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_DPBC_DESC',
  DealsStddevPopulationCassStdChkdgtAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_CHKDGT_ASC',
  DealsStddevPopulationCassStdChkdgtDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_CHKDGT_DESC',
  DealsStddevPopulationCassStdCartAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_CART_ASC',
  DealsStddevPopulationCassStdCartDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_CART_DESC',
  DealsStddevPopulationCassStdLotAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_LOT_ASC',
  DealsStddevPopulationCassStdLotDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_LOT_DESC',
  DealsStddevPopulationCassStdLotordAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_LOTORD_ASC',
  DealsStddevPopulationCassStdLotordDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_LOTORD_DESC',
  DealsStddevPopulationCassStdUrbAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_URB_ASC',
  DealsStddevPopulationCassStdUrbDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_URB_DESC',
  DealsStddevPopulationCassStdFipsAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_FIPS_ASC',
  DealsStddevPopulationCassStdFipsDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_FIPS_DESC',
  DealsStddevPopulationCassStdEwsAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_EWS_ASC',
  DealsStddevPopulationCassStdEwsDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_EWS_DESC',
  DealsStddevPopulationCassStdLacsAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_LACS_ASC',
  DealsStddevPopulationCassStdLacsDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_LACS_DESC',
  DealsStddevPopulationCassStdZipmovAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIPMOV_ASC',
  DealsStddevPopulationCassStdZipmovDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_ZIPMOV_DESC',
  DealsStddevPopulationCassStdZ4LomAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_Z4LOM_ASC',
  DealsStddevPopulationCassStdZ4LomDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_Z4LOM_DESC',
  DealsStddevPopulationCassStdNdiaptAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_NDIAPT_ASC',
  DealsStddevPopulationCassStdNdiaptDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_NDIAPT_DESC',
  DealsStddevPopulationCassStdNdirrAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_NDIRR_ASC',
  DealsStddevPopulationCassStdNdirrDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_NDIRR_DESC',
  DealsStddevPopulationCassStdLacsrtAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_LACSRT_ASC',
  DealsStddevPopulationCassStdLacsrtDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_LACSRT_DESC',
  DealsStddevPopulationCassStdErrorCdAsc = 'DEALS_STDDEV_POPULATION_CASS_STD_ERROR_CD_ASC',
  DealsStddevPopulationCassStdErrorCdDesc = 'DEALS_STDDEV_POPULATION_CASS_STD_ERROR_CD_DESC',
  DealsVarianceSampleIdAsc = 'DEALS_VARIANCE_SAMPLE_ID_ASC',
  DealsVarianceSampleIdDesc = 'DEALS_VARIANCE_SAMPLE_ID_DESC',
  DealsVarianceSampleLeadIdAsc = 'DEALS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  DealsVarianceSampleLeadIdDesc = 'DEALS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  DealsVarianceSampleCompanyIntegrationIdAsc = 'DEALS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  DealsVarianceSampleCompanyIntegrationIdDesc = 'DEALS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  DealsVarianceSampleFileTypeAsc = 'DEALS_VARIANCE_SAMPLE_FILE_TYPE_ASC',
  DealsVarianceSampleFileTypeDesc = 'DEALS_VARIANCE_SAMPLE_FILE_TYPE_DESC',
  DealsVarianceSampleDvDealerIdAsc = 'DEALS_VARIANCE_SAMPLE_DV_DEALER_ID_ASC',
  DealsVarianceSampleDvDealerIdDesc = 'DEALS_VARIANCE_SAMPLE_DV_DEALER_ID_DESC',
  DealsVarianceSampleVendorDealerIdAsc = 'DEALS_VARIANCE_SAMPLE_VENDOR_DEALER_ID_ASC',
  DealsVarianceSampleVendorDealerIdDesc = 'DEALS_VARIANCE_SAMPLE_VENDOR_DEALER_ID_DESC',
  DealsVarianceSampleDmsTypeAsc = 'DEALS_VARIANCE_SAMPLE_DMS_TYPE_ASC',
  DealsVarianceSampleDmsTypeDesc = 'DEALS_VARIANCE_SAMPLE_DMS_TYPE_DESC',
  DealsVarianceSampleDealNumberAsc = 'DEALS_VARIANCE_SAMPLE_DEAL_NUMBER_ASC',
  DealsVarianceSampleDealNumberDesc = 'DEALS_VARIANCE_SAMPLE_DEAL_NUMBER_DESC',
  DealsVarianceSampleCustomerNumberAsc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_NUMBER_ASC',
  DealsVarianceSampleCustomerNumberDesc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_NUMBER_DESC',
  DealsVarianceSampleFullNameAsc = 'DEALS_VARIANCE_SAMPLE_FULL_NAME_ASC',
  DealsVarianceSampleFullNameDesc = 'DEALS_VARIANCE_SAMPLE_FULL_NAME_DESC',
  DealsVarianceSampleSalutationAsc = 'DEALS_VARIANCE_SAMPLE_SALUTATION_ASC',
  DealsVarianceSampleSalutationDesc = 'DEALS_VARIANCE_SAMPLE_SALUTATION_DESC',
  DealsVarianceSampleFirstNameAsc = 'DEALS_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  DealsVarianceSampleFirstNameDesc = 'DEALS_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  DealsVarianceSampleMiddleNameAsc = 'DEALS_VARIANCE_SAMPLE_MIDDLE_NAME_ASC',
  DealsVarianceSampleMiddleNameDesc = 'DEALS_VARIANCE_SAMPLE_MIDDLE_NAME_DESC',
  DealsVarianceSampleLastNameAsc = 'DEALS_VARIANCE_SAMPLE_LAST_NAME_ASC',
  DealsVarianceSampleLastNameDesc = 'DEALS_VARIANCE_SAMPLE_LAST_NAME_DESC',
  DealsVarianceSampleSuffixAsc = 'DEALS_VARIANCE_SAMPLE_SUFFIX_ASC',
  DealsVarianceSampleSuffixDesc = 'DEALS_VARIANCE_SAMPLE_SUFFIX_DESC',
  DealsVarianceSampleAddressLine_1Asc = 'DEALS_VARIANCE_SAMPLE_ADDRESS_LINE_1_ASC',
  DealsVarianceSampleAddressLine_1Desc = 'DEALS_VARIANCE_SAMPLE_ADDRESS_LINE_1_DESC',
  DealsVarianceSampleAddressLine_2Asc = 'DEALS_VARIANCE_SAMPLE_ADDRESS_LINE_2_ASC',
  DealsVarianceSampleAddressLine_2Desc = 'DEALS_VARIANCE_SAMPLE_ADDRESS_LINE_2_DESC',
  DealsVarianceSampleCityAsc = 'DEALS_VARIANCE_SAMPLE_CITY_ASC',
  DealsVarianceSampleCityDesc = 'DEALS_VARIANCE_SAMPLE_CITY_DESC',
  DealsVarianceSampleStateAsc = 'DEALS_VARIANCE_SAMPLE_STATE_ASC',
  DealsVarianceSampleStateDesc = 'DEALS_VARIANCE_SAMPLE_STATE_DESC',
  DealsVarianceSampleZipAsc = 'DEALS_VARIANCE_SAMPLE_ZIP_ASC',
  DealsVarianceSampleZipDesc = 'DEALS_VARIANCE_SAMPLE_ZIP_DESC',
  DealsVarianceSampleCountyAsc = 'DEALS_VARIANCE_SAMPLE_COUNTY_ASC',
  DealsVarianceSampleCountyDesc = 'DEALS_VARIANCE_SAMPLE_COUNTY_DESC',
  DealsVarianceSampleHomePhoneAsc = 'DEALS_VARIANCE_SAMPLE_HOME_PHONE_ASC',
  DealsVarianceSampleHomePhoneDesc = 'DEALS_VARIANCE_SAMPLE_HOME_PHONE_DESC',
  DealsVarianceSampleCellPhoneAsc = 'DEALS_VARIANCE_SAMPLE_CELL_PHONE_ASC',
  DealsVarianceSampleCellPhoneDesc = 'DEALS_VARIANCE_SAMPLE_CELL_PHONE_DESC',
  DealsVarianceSampleWorkPhoneAsc = 'DEALS_VARIANCE_SAMPLE_WORK_PHONE_ASC',
  DealsVarianceSampleWorkPhoneDesc = 'DEALS_VARIANCE_SAMPLE_WORK_PHONE_DESC',
  DealsVarianceSampleWorkExtensionAsc = 'DEALS_VARIANCE_SAMPLE_WORK_EXTENSION_ASC',
  DealsVarianceSampleWorkExtensionDesc = 'DEALS_VARIANCE_SAMPLE_WORK_EXTENSION_DESC',
  DealsVarianceSampleEmail_1Asc = 'DEALS_VARIANCE_SAMPLE_EMAIL_1_ASC',
  DealsVarianceSampleEmail_1Desc = 'DEALS_VARIANCE_SAMPLE_EMAIL_1_DESC',
  DealsVarianceSampleEmail_2Asc = 'DEALS_VARIANCE_SAMPLE_EMAIL_2_ASC',
  DealsVarianceSampleEmail_2Desc = 'DEALS_VARIANCE_SAMPLE_EMAIL_2_DESC',
  DealsVarianceSampleEmail_3Asc = 'DEALS_VARIANCE_SAMPLE_EMAIL_3_ASC',
  DealsVarianceSampleEmail_3Desc = 'DEALS_VARIANCE_SAMPLE_EMAIL_3_DESC',
  DealsVarianceSampleBirthDateAsc = 'DEALS_VARIANCE_SAMPLE_BIRTH_DATE_ASC',
  DealsVarianceSampleBirthDateDesc = 'DEALS_VARIANCE_SAMPLE_BIRTH_DATE_DESC',
  DealsVarianceSampleIndividualBusinessFlagAsc = 'DEALS_VARIANCE_SAMPLE_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsVarianceSampleIndividualBusinessFlagDesc = 'DEALS_VARIANCE_SAMPLE_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsVarianceSampleOptOutAsc = 'DEALS_VARIANCE_SAMPLE_OPT_OUT_ASC',
  DealsVarianceSampleOptOutDesc = 'DEALS_VARIANCE_SAMPLE_OPT_OUT_DESC',
  DealsVarianceSampleBlockEmailAsc = 'DEALS_VARIANCE_SAMPLE_BLOCK_EMAIL_ASC',
  DealsVarianceSampleBlockEmailDesc = 'DEALS_VARIANCE_SAMPLE_BLOCK_EMAIL_DESC',
  DealsVarianceSampleBlockPhoneAsc = 'DEALS_VARIANCE_SAMPLE_BLOCK_PHONE_ASC',
  DealsVarianceSampleBlockPhoneDesc = 'DEALS_VARIANCE_SAMPLE_BLOCK_PHONE_DESC',
  DealsVarianceSampleBlockMailAsc = 'DEALS_VARIANCE_SAMPLE_BLOCK_MAIL_ASC',
  DealsVarianceSampleBlockMailDesc = 'DEALS_VARIANCE_SAMPLE_BLOCK_MAIL_DESC',
  DealsVarianceSampleLanguageAsc = 'DEALS_VARIANCE_SAMPLE_LANGUAGE_ASC',
  DealsVarianceSampleLanguageDesc = 'DEALS_VARIANCE_SAMPLE_LANGUAGE_DESC',
  DealsVarianceSampleCustomerCreateDateAsc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_CREATE_DATE_ASC',
  DealsVarianceSampleCustomerCreateDateDesc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_CREATE_DATE_DESC',
  DealsVarianceSampleCustomerLastActivityDateAsc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsVarianceSampleCustomerLastActivityDateDesc = 'DEALS_VARIANCE_SAMPLE_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsVarianceSampleCoBuyerCustomerNumberAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsVarianceSampleCoBuyerCustomerNumberDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsVarianceSampleCoBuyerFullNameAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_FULL_NAME_ASC',
  DealsVarianceSampleCoBuyerFullNameDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_FULL_NAME_DESC',
  DealsVarianceSampleCoBuyerSalutationAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_SALUTATION_ASC',
  DealsVarianceSampleCoBuyerSalutationDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_SALUTATION_DESC',
  DealsVarianceSampleCoBuyerFirstNameAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_FIRST_NAME_ASC',
  DealsVarianceSampleCoBuyerFirstNameDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_FIRST_NAME_DESC',
  DealsVarianceSampleCoBuyerMiddleNameAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_MIDDLE_NAME_ASC',
  DealsVarianceSampleCoBuyerMiddleNameDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_MIDDLE_NAME_DESC',
  DealsVarianceSampleCoBuyerLastNameAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_LAST_NAME_ASC',
  DealsVarianceSampleCoBuyerLastNameDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_LAST_NAME_DESC',
  DealsVarianceSampleCoBuyerSuffixAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_SUFFIX_ASC',
  DealsVarianceSampleCoBuyerSuffixDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_SUFFIX_DESC',
  DealsVarianceSampleCoBuyerAddressLine_1Asc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsVarianceSampleCoBuyerAddressLine_1Desc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsVarianceSampleCoBuyerAddressLine_2Asc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsVarianceSampleCoBuyerAddressLine_2Desc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsVarianceSampleCoBuyerCityAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CITY_ASC',
  DealsVarianceSampleCoBuyerCityDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CITY_DESC',
  DealsVarianceSampleCoBuyerStateAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_STATE_ASC',
  DealsVarianceSampleCoBuyerStateDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_STATE_DESC',
  DealsVarianceSampleCoBuyerZipAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ZIP_ASC',
  DealsVarianceSampleCoBuyerZipDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_ZIP_DESC',
  DealsVarianceSampleCoBuyerCountyAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_COUNTY_ASC',
  DealsVarianceSampleCoBuyerCountyDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_COUNTY_DESC',
  DealsVarianceSampleCoBuyerHomePhoneAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_HOME_PHONE_ASC',
  DealsVarianceSampleCoBuyerHomePhoneDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_HOME_PHONE_DESC',
  DealsVarianceSampleCoBuyerCellPhoneAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CELL_PHONE_ASC',
  DealsVarianceSampleCoBuyerCellPhoneDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_CELL_PHONE_DESC',
  DealsVarianceSampleCoBuyerWorkPhoneAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_WORK_PHONE_ASC',
  DealsVarianceSampleCoBuyerWorkPhoneDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_WORK_PHONE_DESC',
  DealsVarianceSampleCoBuyerWorkExtensionAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_WORK_EXTENSION_ASC',
  DealsVarianceSampleCoBuyerWorkExtensionDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_WORK_EXTENSION_DESC',
  DealsVarianceSampleCoBuyerEmail_1Asc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_1_ASC',
  DealsVarianceSampleCoBuyerEmail_1Desc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_1_DESC',
  DealsVarianceSampleCoBuyerEmail_2Asc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_2_ASC',
  DealsVarianceSampleCoBuyerEmail_2Desc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_2_DESC',
  DealsVarianceSampleCoBuyerEmail_3Asc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_3_ASC',
  DealsVarianceSampleCoBuyerEmail_3Desc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_EMAIL_3_DESC',
  DealsVarianceSampleCoBuyerBirthDateAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BIRTH_DATE_ASC',
  DealsVarianceSampleCoBuyerBirthDateDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BIRTH_DATE_DESC',
  DealsVarianceSampleCoBuyerIndividualBusinessFlagAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsVarianceSampleCoBuyerIndividualBusinessFlagDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsVarianceSampleCoBuyerOptOutAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_OPT_OUT_ASC',
  DealsVarianceSampleCoBuyerOptOutDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_OPT_OUT_DESC',
  DealsVarianceSampleCoBuyerBlockEmailAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsVarianceSampleCoBuyerBlockEmailDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsVarianceSampleCoBuyerBlockPhoneAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_PHONE_ASC',
  DealsVarianceSampleCoBuyerBlockPhoneDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_PHONE_DESC',
  DealsVarianceSampleCoBuyerBlockMailAsc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_MAIL_ASC',
  DealsVarianceSampleCoBuyerBlockMailDesc = 'DEALS_VARIANCE_SAMPLE_CO_BUYER_BLOCK_MAIL_DESC',
  DealsVarianceSampleVinAsc = 'DEALS_VARIANCE_SAMPLE_VIN_ASC',
  DealsVarianceSampleVinDesc = 'DEALS_VARIANCE_SAMPLE_VIN_DESC',
  DealsVarianceSampleYearAsc = 'DEALS_VARIANCE_SAMPLE_YEAR_ASC',
  DealsVarianceSampleYearDesc = 'DEALS_VARIANCE_SAMPLE_YEAR_DESC',
  DealsVarianceSampleMakeAsc = 'DEALS_VARIANCE_SAMPLE_MAKE_ASC',
  DealsVarianceSampleMakeDesc = 'DEALS_VARIANCE_SAMPLE_MAKE_DESC',
  DealsVarianceSampleModelAsc = 'DEALS_VARIANCE_SAMPLE_MODEL_ASC',
  DealsVarianceSampleModelDesc = 'DEALS_VARIANCE_SAMPLE_MODEL_DESC',
  DealsVarianceSampleModelNumberAsc = 'DEALS_VARIANCE_SAMPLE_MODEL_NUMBER_ASC',
  DealsVarianceSampleModelNumberDesc = 'DEALS_VARIANCE_SAMPLE_MODEL_NUMBER_DESC',
  DealsVarianceSampleMileageAsc = 'DEALS_VARIANCE_SAMPLE_MILEAGE_ASC',
  DealsVarianceSampleMileageDesc = 'DEALS_VARIANCE_SAMPLE_MILEAGE_DESC',
  DealsVarianceSampleDescriptionAsc = 'DEALS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  DealsVarianceSampleDescriptionDesc = 'DEALS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  DealsVarianceSampleExteriorColorAsc = 'DEALS_VARIANCE_SAMPLE_EXTERIOR_COLOR_ASC',
  DealsVarianceSampleExteriorColorDesc = 'DEALS_VARIANCE_SAMPLE_EXTERIOR_COLOR_DESC',
  DealsVarianceSampleNewUsedAsc = 'DEALS_VARIANCE_SAMPLE_NEW_USED_ASC',
  DealsVarianceSampleNewUsedDesc = 'DEALS_VARIANCE_SAMPLE_NEW_USED_DESC',
  DealsVarianceSampleStockNumberAsc = 'DEALS_VARIANCE_SAMPLE_STOCK_NUMBER_ASC',
  DealsVarianceSampleStockNumberDesc = 'DEALS_VARIANCE_SAMPLE_STOCK_NUMBER_DESC',
  DealsVarianceSampleTransmissionAsc = 'DEALS_VARIANCE_SAMPLE_TRANSMISSION_ASC',
  DealsVarianceSampleTransmissionDesc = 'DEALS_VARIANCE_SAMPLE_TRANSMISSION_DESC',
  DealsVarianceSampleEngineConfigurationAsc = 'DEALS_VARIANCE_SAMPLE_ENGINE_CONFIGURATION_ASC',
  DealsVarianceSampleEngineConfigurationDesc = 'DEALS_VARIANCE_SAMPLE_ENGINE_CONFIGURATION_DESC',
  DealsVarianceSampleTrimAsc = 'DEALS_VARIANCE_SAMPLE_TRIM_ASC',
  DealsVarianceSampleTrimDesc = 'DEALS_VARIANCE_SAMPLE_TRIM_DESC',
  DealsVarianceSampleEngineNumberAsc = 'DEALS_VARIANCE_SAMPLE_ENGINE_NUMBER_ASC',
  DealsVarianceSampleEngineNumberDesc = 'DEALS_VARIANCE_SAMPLE_ENGINE_NUMBER_DESC',
  DealsVarianceSampleChassisNumberAsc = 'DEALS_VARIANCE_SAMPLE_CHASSIS_NUMBER_ASC',
  DealsVarianceSampleChassisNumberDesc = 'DEALS_VARIANCE_SAMPLE_CHASSIS_NUMBER_DESC',
  DealsVarianceSampleLicensePlateNumberAsc = 'DEALS_VARIANCE_SAMPLE_LICENSE_PLATE_NUMBER_ASC',
  DealsVarianceSampleLicensePlateNumberDesc = 'DEALS_VARIANCE_SAMPLE_LICENSE_PLATE_NUMBER_DESC',
  DealsVarianceSampleDeliveryDateAsc = 'DEALS_VARIANCE_SAMPLE_DELIVERY_DATE_ASC',
  DealsVarianceSampleDeliveryDateDesc = 'DEALS_VARIANCE_SAMPLE_DELIVERY_DATE_DESC',
  DealsVarianceSampleDeliveryMileageAsc = 'DEALS_VARIANCE_SAMPLE_DELIVERY_MILEAGE_ASC',
  DealsVarianceSampleDeliveryMileageDesc = 'DEALS_VARIANCE_SAMPLE_DELIVERY_MILEAGE_DESC',
  DealsVarianceSampleInventoryDateAsc = 'DEALS_VARIANCE_SAMPLE_INVENTORY_DATE_ASC',
  DealsVarianceSampleInventoryDateDesc = 'DEALS_VARIANCE_SAMPLE_INVENTORY_DATE_DESC',
  DealsVarianceSampleInServiceDateAsc = 'DEALS_VARIANCE_SAMPLE_IN_SERVICE_DATE_ASC',
  DealsVarianceSampleInServiceDateDesc = 'DEALS_VARIANCE_SAMPLE_IN_SERVICE_DATE_DESC',
  DealsVarianceSampleVinExplosionYearAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_YEAR_ASC',
  DealsVarianceSampleVinExplosionYearDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_YEAR_DESC',
  DealsVarianceSampleVinExplosionMakeAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_MAKE_ASC',
  DealsVarianceSampleVinExplosionMakeDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_MAKE_DESC',
  DealsVarianceSampleVinExplosionModelAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_MODEL_ASC',
  DealsVarianceSampleVinExplosionModelDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_MODEL_DESC',
  DealsVarianceSampleVinExplosionTrimAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_TRIM_ASC',
  DealsVarianceSampleVinExplosionTrimDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_TRIM_DESC',
  DealsVarianceSampleVinExplosionTransmissionTypeAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsVarianceSampleVinExplosionTransmissionTypeDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsVarianceSampleVinExplosionFuelTypeAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsVarianceSampleVinExplosionFuelTypeDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsVarianceSampleVinExplosionEngineSizeAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsVarianceSampleVinExplosionEngineSizeDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsVarianceSampleVinExplosionGvwRangeAsc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsVarianceSampleVinExplosionGvwRangeDesc = 'DEALS_VARIANCE_SAMPLE_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsVarianceSampleTrade_1VinAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_VIN_ASC',
  DealsVarianceSampleTrade_1VinDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_VIN_DESC',
  DealsVarianceSampleTrade_1YearAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_YEAR_ASC',
  DealsVarianceSampleTrade_1YearDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_YEAR_DESC',
  DealsVarianceSampleTrade_1MakeAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_MAKE_ASC',
  DealsVarianceSampleTrade_1MakeDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_MAKE_DESC',
  DealsVarianceSampleTrade_1ModelAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_MODEL_ASC',
  DealsVarianceSampleTrade_1ModelDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_MODEL_DESC',
  DealsVarianceSampleTrade_1OdometerAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_ODOMETER_ASC',
  DealsVarianceSampleTrade_1OdometerDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_ODOMETER_DESC',
  DealsVarianceSampleTrade_1ActualCashValueAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsVarianceSampleTrade_1ActualCashValueDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsVarianceSampleTrade_1GrossAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_GROSS_ASC',
  DealsVarianceSampleTrade_1GrossDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_GROSS_DESC',
  DealsVarianceSampleTrade_1PayoffAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_PAYOFF_ASC',
  DealsVarianceSampleTrade_1PayoffDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_1_PAYOFF_DESC',
  DealsVarianceSampleTrade_2VinAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_VIN_ASC',
  DealsVarianceSampleTrade_2VinDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_VIN_DESC',
  DealsVarianceSampleTrade_2YearAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_YEAR_ASC',
  DealsVarianceSampleTrade_2YearDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_YEAR_DESC',
  DealsVarianceSampleTrade_2MakeAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_MAKE_ASC',
  DealsVarianceSampleTrade_2MakeDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_MAKE_DESC',
  DealsVarianceSampleTrade_2ModelAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_MODEL_ASC',
  DealsVarianceSampleTrade_2ModelDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_MODEL_DESC',
  DealsVarianceSampleTrade_2OdometerAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_ODOMETER_ASC',
  DealsVarianceSampleTrade_2OdometerDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_ODOMETER_DESC',
  DealsVarianceSampleTrade_2ActualCashValueAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsVarianceSampleTrade_2ActualCashValueDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsVarianceSampleTrade_2GrossAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_GROSS_ASC',
  DealsVarianceSampleTrade_2GrossDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_GROSS_DESC',
  DealsVarianceSampleTrade_2PayoffAsc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_PAYOFF_ASC',
  DealsVarianceSampleTrade_2PayoffDesc = 'DEALS_VARIANCE_SAMPLE_TRADE_2_PAYOFF_DESC',
  DealsVarianceSampleSalesman_1NumberAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_NUMBER_ASC',
  DealsVarianceSampleSalesman_1NumberDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_NUMBER_DESC',
  DealsVarianceSampleSalesman_1NameAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_NAME_ASC',
  DealsVarianceSampleSalesman_1NameDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_NAME_DESC',
  DealsVarianceSampleSalesman_2NumberAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_NUMBER_ASC',
  DealsVarianceSampleSalesman_2NumberDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_NUMBER_DESC',
  DealsVarianceSampleSalesman_2NameAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_NAME_ASC',
  DealsVarianceSampleSalesman_2NameDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_NAME_DESC',
  DealsVarianceSampleSalesman_3NumberAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_NUMBER_ASC',
  DealsVarianceSampleSalesman_3NumberDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_NUMBER_DESC',
  DealsVarianceSampleSalesman_3NameAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_NAME_ASC',
  DealsVarianceSampleSalesman_3NameDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_NAME_DESC',
  DealsVarianceSampleClosingManagerNumberAsc = 'DEALS_VARIANCE_SAMPLE_CLOSING_MANAGER_NUMBER_ASC',
  DealsVarianceSampleClosingManagerNumberDesc = 'DEALS_VARIANCE_SAMPLE_CLOSING_MANAGER_NUMBER_DESC',
  DealsVarianceSampleClosingManagerNameAsc = 'DEALS_VARIANCE_SAMPLE_CLOSING_MANAGER_NAME_ASC',
  DealsVarianceSampleClosingManagerNameDesc = 'DEALS_VARIANCE_SAMPLE_CLOSING_MANAGER_NAME_DESC',
  DealsVarianceSampleFinanceManagerNumberAsc = 'DEALS_VARIANCE_SAMPLE_FINANCE_MANAGER_NUMBER_ASC',
  DealsVarianceSampleFinanceManagerNumberDesc = 'DEALS_VARIANCE_SAMPLE_FINANCE_MANAGER_NUMBER_DESC',
  DealsVarianceSampleFinanceManagerNameAsc = 'DEALS_VARIANCE_SAMPLE_FINANCE_MANAGER_NAME_ASC',
  DealsVarianceSampleFinanceManagerNameDesc = 'DEALS_VARIANCE_SAMPLE_FINANCE_MANAGER_NAME_DESC',
  DealsVarianceSampleSalesmanManagerNumberAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_MANAGER_NUMBER_ASC',
  DealsVarianceSampleSalesmanManagerNumberDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_MANAGER_NUMBER_DESC',
  DealsVarianceSampleSalesmanManagerNameAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_MANAGER_NAME_ASC',
  DealsVarianceSampleSalesmanManagerNameDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_MANAGER_NAME_DESC',
  DealsVarianceSampleMsrpAsc = 'DEALS_VARIANCE_SAMPLE_MSRP_ASC',
  DealsVarianceSampleMsrpDesc = 'DEALS_VARIANCE_SAMPLE_MSRP_DESC',
  DealsVarianceSampleListPriceAsc = 'DEALS_VARIANCE_SAMPLE_LIST_PRICE_ASC',
  DealsVarianceSampleListPriceDesc = 'DEALS_VARIANCE_SAMPLE_LIST_PRICE_DESC',
  DealsVarianceSampleSalesPriceAsc = 'DEALS_VARIANCE_SAMPLE_SALES_PRICE_ASC',
  DealsVarianceSampleSalesPriceDesc = 'DEALS_VARIANCE_SAMPLE_SALES_PRICE_DESC',
  DealsVarianceSampleJournalPriceAsc = 'DEALS_VARIANCE_SAMPLE_JOURNAL_PRICE_ASC',
  DealsVarianceSampleJournalPriceDesc = 'DEALS_VARIANCE_SAMPLE_JOURNAL_PRICE_DESC',
  DealsVarianceSampleCostAsc = 'DEALS_VARIANCE_SAMPLE_COST_ASC',
  DealsVarianceSampleCostDesc = 'DEALS_VARIANCE_SAMPLE_COST_DESC',
  DealsVarianceSampleJournalCostAsc = 'DEALS_VARIANCE_SAMPLE_JOURNAL_COST_ASC',
  DealsVarianceSampleJournalCostDesc = 'DEALS_VARIANCE_SAMPLE_JOURNAL_COST_DESC',
  DealsVarianceSampleAdjustmentsAsc = 'DEALS_VARIANCE_SAMPLE_ADJUSTMENTS_ASC',
  DealsVarianceSampleAdjustmentsDesc = 'DEALS_VARIANCE_SAMPLE_ADJUSTMENTS_DESC',
  DealsVarianceSampleAdjustedCostAsc = 'DEALS_VARIANCE_SAMPLE_ADJUSTED_COST_ASC',
  DealsVarianceSampleAdjustedCostDesc = 'DEALS_VARIANCE_SAMPLE_ADJUSTED_COST_DESC',
  DealsVarianceSampleIncentivesAsc = 'DEALS_VARIANCE_SAMPLE_INCENTIVES_ASC',
  DealsVarianceSampleIncentivesDesc = 'DEALS_VARIANCE_SAMPLE_INCENTIVES_DESC',
  DealsVarianceSamplePackAmountAsc = 'DEALS_VARIANCE_SAMPLE_PACK_AMOUNT_ASC',
  DealsVarianceSamplePackAmountDesc = 'DEALS_VARIANCE_SAMPLE_PACK_AMOUNT_DESC',
  DealsVarianceSampleSaleNetAsc = 'DEALS_VARIANCE_SAMPLE_SALE_NET_ASC',
  DealsVarianceSampleSaleNetDesc = 'DEALS_VARIANCE_SAMPLE_SALE_NET_DESC',
  DealsVarianceSampleTotalTradeActualCashValueAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsVarianceSampleTotalTradeActualCashValueDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsVarianceSampleTotalTradeGrossAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TRADE_GROSS_ASC',
  DealsVarianceSampleTotalTradeGrossDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TRADE_GROSS_DESC',
  DealsVarianceSampleWeOweFrontAsc = 'DEALS_VARIANCE_SAMPLE_WE_OWE_FRONT_ASC',
  DealsVarianceSampleWeOweFrontDesc = 'DEALS_VARIANCE_SAMPLE_WE_OWE_FRONT_DESC',
  DealsVarianceSampleTotalFrontFeesAftermarketProfitAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsVarianceSampleTotalFrontFeesAftermarketProfitDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsVarianceSampleTotalFrontCommissionAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_COMMISSION_ASC',
  DealsVarianceSampleTotalFrontCommissionDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_COMMISSION_DESC',
  DealsVarianceSampleTotalFrontSalesAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_SALES_ASC',
  DealsVarianceSampleTotalFrontSalesDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_SALES_DESC',
  DealsVarianceSampleTotalFrontCostAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_COST_ASC',
  DealsVarianceSampleTotalFrontCostDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FRONT_COST_DESC',
  DealsVarianceSampleFrontGrossAsc = 'DEALS_VARIANCE_SAMPLE_FRONT_GROSS_ASC',
  DealsVarianceSampleFrontGrossDesc = 'DEALS_VARIANCE_SAMPLE_FRONT_GROSS_DESC',
  DealsVarianceSampleFinanceProfitAsc = 'DEALS_VARIANCE_SAMPLE_FINANCE_PROFIT_ASC',
  DealsVarianceSampleFinanceProfitDesc = 'DEALS_VARIANCE_SAMPLE_FINANCE_PROFIT_DESC',
  DealsVarianceSampleTotalWarrantyProfitAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_WARRANTY_PROFIT_ASC',
  DealsVarianceSampleTotalWarrantyProfitDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_WARRANTY_PROFIT_DESC',
  DealsVarianceSampleWeOweBackAsc = 'DEALS_VARIANCE_SAMPLE_WE_OWE_BACK_ASC',
  DealsVarianceSampleWeOweBackDesc = 'DEALS_VARIANCE_SAMPLE_WE_OWE_BACK_DESC',
  DealsVarianceSampleInsuranceProfitAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_PROFIT_ASC',
  DealsVarianceSampleInsuranceProfitDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_PROFIT_DESC',
  DealsVarianceSampleTotalBackFeesAftermarketProfitAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsVarianceSampleTotalBackFeesAftermarketProfitDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsVarianceSampleFinanceReserveAsc = 'DEALS_VARIANCE_SAMPLE_FINANCE_RESERVE_ASC',
  DealsVarianceSampleFinanceReserveDesc = 'DEALS_VARIANCE_SAMPLE_FINANCE_RESERVE_DESC',
  DealsVarianceSampleTotalBackCommissionAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_COMMISSION_ASC',
  DealsVarianceSampleTotalBackCommissionDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_COMMISSION_DESC',
  DealsVarianceSampleTotalBackSalesAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_SALES_ASC',
  DealsVarianceSampleTotalBackSalesDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_SALES_DESC',
  DealsVarianceSampleTotalBackCostAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_COST_ASC',
  DealsVarianceSampleTotalBackCostDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_BACK_COST_DESC',
  DealsVarianceSampleBackGrossAsc = 'DEALS_VARIANCE_SAMPLE_BACK_GROSS_ASC',
  DealsVarianceSampleBackGrossDesc = 'DEALS_VARIANCE_SAMPLE_BACK_GROSS_DESC',
  DealsVarianceSampleTotalProfitAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_PROFIT_ASC',
  DealsVarianceSampleTotalProfitDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_PROFIT_DESC',
  DealsVarianceSampleGrossProfitAsc = 'DEALS_VARIANCE_SAMPLE_GROSS_PROFIT_ASC',
  DealsVarianceSampleGrossProfitDesc = 'DEALS_VARIANCE_SAMPLE_GROSS_PROFIT_DESC',
  DealsVarianceSampleGrossPayableAsc = 'DEALS_VARIANCE_SAMPLE_GROSS_PAYABLE_ASC',
  DealsVarianceSampleGrossPayableDesc = 'DEALS_VARIANCE_SAMPLE_GROSS_PAYABLE_DESC',
  DealsVarianceSampleDealStatusAsc = 'DEALS_VARIANCE_SAMPLE_DEAL_STATUS_ASC',
  DealsVarianceSampleDealStatusDesc = 'DEALS_VARIANCE_SAMPLE_DEAL_STATUS_DESC',
  DealsVarianceSampleEntryDateAsc = 'DEALS_VARIANCE_SAMPLE_ENTRY_DATE_ASC',
  DealsVarianceSampleEntryDateDesc = 'DEALS_VARIANCE_SAMPLE_ENTRY_DATE_DESC',
  DealsVarianceSampleBookedDateAsc = 'DEALS_VARIANCE_SAMPLE_BOOKED_DATE_ASC',
  DealsVarianceSampleBookedDateDesc = 'DEALS_VARIANCE_SAMPLE_BOOKED_DATE_DESC',
  DealsVarianceSampleFinalizedDateAsc = 'DEALS_VARIANCE_SAMPLE_FINALIZED_DATE_ASC',
  DealsVarianceSampleFinalizedDateDesc = 'DEALS_VARIANCE_SAMPLE_FINALIZED_DATE_DESC',
  DealsVarianceSampleContractDateAsc = 'DEALS_VARIANCE_SAMPLE_CONTRACT_DATE_ASC',
  DealsVarianceSampleContractDateDesc = 'DEALS_VARIANCE_SAMPLE_CONTRACT_DATE_DESC',
  DealsVarianceSampleAccountingDateAsc = 'DEALS_VARIANCE_SAMPLE_ACCOUNTING_DATE_ASC',
  DealsVarianceSampleAccountingDateDesc = 'DEALS_VARIANCE_SAMPLE_ACCOUNTING_DATE_DESC',
  DealsVarianceSampleStatusChangeDateAsc = 'DEALS_VARIANCE_SAMPLE_STATUS_CHANGE_DATE_ASC',
  DealsVarianceSampleStatusChangeDateDesc = 'DEALS_VARIANCE_SAMPLE_STATUS_CHANGE_DATE_DESC',
  DealsVarianceSampleFirstPayDateAsc = 'DEALS_VARIANCE_SAMPLE_FIRST_PAY_DATE_ASC',
  DealsVarianceSampleFirstPayDateDesc = 'DEALS_VARIANCE_SAMPLE_FIRST_PAY_DATE_DESC',
  DealsVarianceSampleDealTypeAsc = 'DEALS_VARIANCE_SAMPLE_DEAL_TYPE_ASC',
  DealsVarianceSampleDealTypeDesc = 'DEALS_VARIANCE_SAMPLE_DEAL_TYPE_DESC',
  DealsVarianceSampleSaleTypeAsc = 'DEALS_VARIANCE_SAMPLE_SALE_TYPE_ASC',
  DealsVarianceSampleSaleTypeDesc = 'DEALS_VARIANCE_SAMPLE_SALE_TYPE_DESC',
  DealsVarianceSampleBankIdAsc = 'DEALS_VARIANCE_SAMPLE_BANK_ID_ASC',
  DealsVarianceSampleBankIdDesc = 'DEALS_VARIANCE_SAMPLE_BANK_ID_DESC',
  DealsVarianceSampleBankNameAsc = 'DEALS_VARIANCE_SAMPLE_BANK_NAME_ASC',
  DealsVarianceSampleBankNameDesc = 'DEALS_VARIANCE_SAMPLE_BANK_NAME_DESC',
  DealsVarianceSampleBankAddressAsc = 'DEALS_VARIANCE_SAMPLE_BANK_ADDRESS_ASC',
  DealsVarianceSampleBankAddressDesc = 'DEALS_VARIANCE_SAMPLE_BANK_ADDRESS_DESC',
  DealsVarianceSampleTermAsc = 'DEALS_VARIANCE_SAMPLE_TERM_ASC',
  DealsVarianceSampleTermDesc = 'DEALS_VARIANCE_SAMPLE_TERM_DESC',
  DealsVarianceSampleAmountFinancedAsc = 'DEALS_VARIANCE_SAMPLE_AMOUNT_FINANCED_ASC',
  DealsVarianceSampleAmountFinancedDesc = 'DEALS_VARIANCE_SAMPLE_AMOUNT_FINANCED_DESC',
  DealsVarianceSampleAprAsc = 'DEALS_VARIANCE_SAMPLE_APR_ASC',
  DealsVarianceSampleAprDesc = 'DEALS_VARIANCE_SAMPLE_APR_DESC',
  DealsVarianceSampleMonthlyPaymentAsc = 'DEALS_VARIANCE_SAMPLE_MONTHLY_PAYMENT_ASC',
  DealsVarianceSampleMonthlyPaymentDesc = 'DEALS_VARIANCE_SAMPLE_MONTHLY_PAYMENT_DESC',
  DealsVarianceSamplePaymentTotalAsc = 'DEALS_VARIANCE_SAMPLE_PAYMENT_TOTAL_ASC',
  DealsVarianceSamplePaymentTotalDesc = 'DEALS_VARIANCE_SAMPLE_PAYMENT_TOTAL_DESC',
  DealsVarianceSampleRebatesAsc = 'DEALS_VARIANCE_SAMPLE_REBATES_ASC',
  DealsVarianceSampleRebatesDesc = 'DEALS_VARIANCE_SAMPLE_REBATES_DESC',
  DealsVarianceSampleDepositAsc = 'DEALS_VARIANCE_SAMPLE_DEPOSIT_ASC',
  DealsVarianceSampleDepositDesc = 'DEALS_VARIANCE_SAMPLE_DEPOSIT_DESC',
  DealsVarianceSampleDownPaymentAsc = 'DEALS_VARIANCE_SAMPLE_DOWN_PAYMENT_ASC',
  DealsVarianceSampleDownPaymentDesc = 'DEALS_VARIANCE_SAMPLE_DOWN_PAYMENT_DESC',
  DealsVarianceSampleTotalNetTradesAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_NET_TRADES_ASC',
  DealsVarianceSampleTotalNetTradesDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_NET_TRADES_DESC',
  DealsVarianceSampleTotalDownAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_DOWN_ASC',
  DealsVarianceSampleTotalDownDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_DOWN_DESC',
  DealsVarianceSampleBalloonAmountAsc = 'DEALS_VARIANCE_SAMPLE_BALLOON_AMOUNT_ASC',
  DealsVarianceSampleBalloonAmountDesc = 'DEALS_VARIANCE_SAMPLE_BALLOON_AMOUNT_DESC',
  DealsVarianceSampleAdjustedBalloonAmountAsc = 'DEALS_VARIANCE_SAMPLE_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsVarianceSampleAdjustedBalloonAmountDesc = 'DEALS_VARIANCE_SAMPLE_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsVarianceSampleHoldbackAmountAsc = 'DEALS_VARIANCE_SAMPLE_HOLDBACK_AMOUNT_ASC',
  DealsVarianceSampleHoldbackAmountDesc = 'DEALS_VARIANCE_SAMPLE_HOLDBACK_AMOUNT_DESC',
  DealsVarianceSampleTotalDriveOffAmountAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsVarianceSampleTotalDriveOffAmountDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsVarianceSampleLicenseFeeAsc = 'DEALS_VARIANCE_SAMPLE_LICENSE_FEE_ASC',
  DealsVarianceSampleLicenseFeeDesc = 'DEALS_VARIANCE_SAMPLE_LICENSE_FEE_DESC',
  DealsVarianceSampleRegistrationFeeAsc = 'DEALS_VARIANCE_SAMPLE_REGISTRATION_FEE_ASC',
  DealsVarianceSampleRegistrationFeeDesc = 'DEALS_VARIANCE_SAMPLE_REGISTRATION_FEE_DESC',
  DealsVarianceSampleDocumentationFeeAsc = 'DEALS_VARIANCE_SAMPLE_DOCUMENTATION_FEE_ASC',
  DealsVarianceSampleDocumentationFeeDesc = 'DEALS_VARIANCE_SAMPLE_DOCUMENTATION_FEE_DESC',
  DealsVarianceSampleFinanceChargeAsc = 'DEALS_VARIANCE_SAMPLE_FINANCE_CHARGE_ASC',
  DealsVarianceSampleFinanceChargeDesc = 'DEALS_VARIANCE_SAMPLE_FINANCE_CHARGE_DESC',
  DealsVarianceSampleTotalPickupPaymentsAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsVarianceSampleTotalPickupPaymentsDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsVarianceSampleSellRateAsc = 'DEALS_VARIANCE_SAMPLE_SELL_RATE_ASC',
  DealsVarianceSampleSellRateDesc = 'DEALS_VARIANCE_SAMPLE_SELL_RATE_DESC',
  DealsVarianceSampleBuyRateAsc = 'DEALS_VARIANCE_SAMPLE_BUY_RATE_ASC',
  DealsVarianceSampleBuyRateDesc = 'DEALS_VARIANCE_SAMPLE_BUY_RATE_DESC',
  DealsVarianceSampleResidualRateAsc = 'DEALS_VARIANCE_SAMPLE_RESIDUAL_RATE_ASC',
  DealsVarianceSampleResidualRateDesc = 'DEALS_VARIANCE_SAMPLE_RESIDUAL_RATE_DESC',
  DealsVarianceSampleResidualAmountAsc = 'DEALS_VARIANCE_SAMPLE_RESIDUAL_AMOUNT_ASC',
  DealsVarianceSampleResidualAmountDesc = 'DEALS_VARIANCE_SAMPLE_RESIDUAL_AMOUNT_DESC',
  DealsVarianceSampleAllowedMilesAsc = 'DEALS_VARIANCE_SAMPLE_ALLOWED_MILES_ASC',
  DealsVarianceSampleAllowedMilesDesc = 'DEALS_VARIANCE_SAMPLE_ALLOWED_MILES_DESC',
  DealsVarianceSampleEstimatedMilesAsc = 'DEALS_VARIANCE_SAMPLE_ESTIMATED_MILES_ASC',
  DealsVarianceSampleEstimatedMilesDesc = 'DEALS_VARIANCE_SAMPLE_ESTIMATED_MILES_DESC',
  DealsVarianceSampleMileageRateAsc = 'DEALS_VARIANCE_SAMPLE_MILEAGE_RATE_ASC',
  DealsVarianceSampleMileageRateDesc = 'DEALS_VARIANCE_SAMPLE_MILEAGE_RATE_DESC',
  DealsVarianceSampleAcquisitionFeeAsc = 'DEALS_VARIANCE_SAMPLE_ACQUISITION_FEE_ASC',
  DealsVarianceSampleAcquisitionFeeDesc = 'DEALS_VARIANCE_SAMPLE_ACQUISITION_FEE_DESC',
  DealsVarianceSampleBasePaymentAsc = 'DEALS_VARIANCE_SAMPLE_BASE_PAYMENT_ASC',
  DealsVarianceSampleBasePaymentDesc = 'DEALS_VARIANCE_SAMPLE_BASE_PAYMENT_DESC',
  DealsVarianceSampleSecurityDepositAsc = 'DEALS_VARIANCE_SAMPLE_SECURITY_DEPOSIT_ASC',
  DealsVarianceSampleSecurityDepositDesc = 'DEALS_VARIANCE_SAMPLE_SECURITY_DEPOSIT_DESC',
  DealsVarianceSampleTotalCapitalReductionAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsVarianceSampleTotalCapitalReductionDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsVarianceSampleNetCapitalCostAsc = 'DEALS_VARIANCE_SAMPLE_NET_CAPITAL_COST_ASC',
  DealsVarianceSampleNetCapitalCostDesc = 'DEALS_VARIANCE_SAMPLE_NET_CAPITAL_COST_DESC',
  DealsVarianceSampleLeaseDepreciationValueAsc = 'DEALS_VARIANCE_SAMPLE_LEASE_DEPRECIATION_VALUE_ASC',
  DealsVarianceSampleLeaseDepreciationValueDesc = 'DEALS_VARIANCE_SAMPLE_LEASE_DEPRECIATION_VALUE_DESC',
  DealsVarianceSampleDealerFeesAsc = 'DEALS_VARIANCE_SAMPLE_DEALER_FEES_ASC',
  DealsVarianceSampleDealerFeesDesc = 'DEALS_VARIANCE_SAMPLE_DEALER_FEES_DESC',
  DealsVarianceSampleGovernmentFeesAsc = 'DEALS_VARIANCE_SAMPLE_GOVERNMENT_FEES_ASC',
  DealsVarianceSampleGovernmentFeesDesc = 'DEALS_VARIANCE_SAMPLE_GOVERNMENT_FEES_DESC',
  DealsVarianceSampleTotalTaxAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TAX_ASC',
  DealsVarianceSampleTotalTaxDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_TAX_DESC',
  DealsVarianceSampleRegistrationStateAsc = 'DEALS_VARIANCE_SAMPLE_REGISTRATION_STATE_ASC',
  DealsVarianceSampleRegistrationStateDesc = 'DEALS_VARIANCE_SAMPLE_REGISTRATION_STATE_DESC',
  DealsVarianceSampleReportOfSaleNumberAsc = 'DEALS_VARIANCE_SAMPLE_REPORT_OF_SALE_NUMBER_ASC',
  DealsVarianceSampleReportOfSaleNumberDesc = 'DEALS_VARIANCE_SAMPLE_REPORT_OF_SALE_NUMBER_DESC',
  DealsVarianceSampleSalesman_1TotalCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsVarianceSampleSalesman_1TotalCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsVarianceSampleSalesman_1FrontCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsVarianceSampleSalesman_1FrontCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsVarianceSampleSalesman_1BackCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsVarianceSampleSalesman_1BackCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsVarianceSampleSalesman_2TotalCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsVarianceSampleSalesman_2TotalCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsVarianceSampleSalesman_2FrontCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsVarianceSampleSalesman_2FrontCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsVarianceSampleSalesman_2BackCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsVarianceSampleSalesman_2BackCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsVarianceSampleSalesman_3TotalCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsVarianceSampleSalesman_3TotalCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsVarianceSampleSalesman_3FrontCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsVarianceSampleSalesman_3FrontCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsVarianceSampleSalesman_3BackCommissionAsc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsVarianceSampleSalesman_3BackCommissionDesc = 'DEALS_VARIANCE_SAMPLE_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsVarianceSampleWarranty_1NameAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_NAME_ASC',
  DealsVarianceSampleWarranty_1NameDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_NAME_DESC',
  DealsVarianceSampleWarranty_1SaleAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_SALE_ASC',
  DealsVarianceSampleWarranty_1SaleDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_SALE_DESC',
  DealsVarianceSampleWarranty_1CostAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_COST_ASC',
  DealsVarianceSampleWarranty_1CostDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_COST_DESC',
  DealsVarianceSampleWarranty_1MilesAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_MILES_ASC',
  DealsVarianceSampleWarranty_1MilesDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_MILES_DESC',
  DealsVarianceSampleWarranty_1TermAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_TERM_ASC',
  DealsVarianceSampleWarranty_1TermDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_1_TERM_DESC',
  DealsVarianceSampleWarranty_2NameAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_NAME_ASC',
  DealsVarianceSampleWarranty_2NameDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_NAME_DESC',
  DealsVarianceSampleWarranty_2SaleAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_SALE_ASC',
  DealsVarianceSampleWarranty_2SaleDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_SALE_DESC',
  DealsVarianceSampleWarranty_2CostAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_COST_ASC',
  DealsVarianceSampleWarranty_2CostDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_COST_DESC',
  DealsVarianceSampleWarranty_2MilesAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_MILES_ASC',
  DealsVarianceSampleWarranty_2MilesDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_MILES_DESC',
  DealsVarianceSampleWarranty_2TermAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_TERM_ASC',
  DealsVarianceSampleWarranty_2TermDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_2_TERM_DESC',
  DealsVarianceSampleWarranty_3NameAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_NAME_ASC',
  DealsVarianceSampleWarranty_3NameDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_NAME_DESC',
  DealsVarianceSampleWarranty_3SaleAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_SALE_ASC',
  DealsVarianceSampleWarranty_3SaleDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_SALE_DESC',
  DealsVarianceSampleWarranty_3CostAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_COST_ASC',
  DealsVarianceSampleWarranty_3CostDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_COST_DESC',
  DealsVarianceSampleWarranty_3MilesAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_MILES_ASC',
  DealsVarianceSampleWarranty_3MilesDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_MILES_DESC',
  DealsVarianceSampleWarranty_3TermAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_TERM_ASC',
  DealsVarianceSampleWarranty_3TermDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_3_TERM_DESC',
  DealsVarianceSampleWarranty_4NameAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_NAME_ASC',
  DealsVarianceSampleWarranty_4NameDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_NAME_DESC',
  DealsVarianceSampleWarranty_4SaleAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_SALE_ASC',
  DealsVarianceSampleWarranty_4SaleDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_SALE_DESC',
  DealsVarianceSampleWarranty_4CostAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_COST_ASC',
  DealsVarianceSampleWarranty_4CostDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_COST_DESC',
  DealsVarianceSampleWarranty_4MilesAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_MILES_ASC',
  DealsVarianceSampleWarranty_4MilesDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_MILES_DESC',
  DealsVarianceSampleWarranty_4TermAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_TERM_ASC',
  DealsVarianceSampleWarranty_4TermDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_4_TERM_DESC',
  DealsVarianceSampleWarranty_5NameAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_NAME_ASC',
  DealsVarianceSampleWarranty_5NameDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_NAME_DESC',
  DealsVarianceSampleWarranty_5SaleAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_SALE_ASC',
  DealsVarianceSampleWarranty_5SaleDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_SALE_DESC',
  DealsVarianceSampleWarranty_5CostAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_COST_ASC',
  DealsVarianceSampleWarranty_5CostDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_COST_DESC',
  DealsVarianceSampleWarranty_5MilesAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_MILES_ASC',
  DealsVarianceSampleWarranty_5MilesDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_MILES_DESC',
  DealsVarianceSampleWarranty_5TermAsc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_TERM_ASC',
  DealsVarianceSampleWarranty_5TermDesc = 'DEALS_VARIANCE_SAMPLE_WARRANTY_5_TERM_DESC',
  DealsVarianceSampleTotalFeeAftermarketSaleAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsVarianceSampleTotalFeeAftermarketSaleDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsVarianceSampleTotalFeeAftermarketCostAsc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsVarianceSampleTotalFeeAftermarketCostDesc = 'DEALS_VARIANCE_SAMPLE_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsVarianceSampleFeeAftermarket_1NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_1NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_1SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_1SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_1CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_COST_ASC',
  DealsVarianceSampleFeeAftermarket_1CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_COST_DESC',
  DealsVarianceSampleFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_2NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_2NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_2SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_2SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_2CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_COST_ASC',
  DealsVarianceSampleFeeAftermarket_2CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_COST_DESC',
  DealsVarianceSampleFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_3NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_3NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_3SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_3SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_3CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_COST_ASC',
  DealsVarianceSampleFeeAftermarket_3CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_COST_DESC',
  DealsVarianceSampleFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_4NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_4NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_4SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_4SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_4CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_COST_ASC',
  DealsVarianceSampleFeeAftermarket_4CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_COST_DESC',
  DealsVarianceSampleFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_5NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_5NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_5SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_5SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_5CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_COST_ASC',
  DealsVarianceSampleFeeAftermarket_5CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_COST_DESC',
  DealsVarianceSampleFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_6NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_6NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_6SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_6SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_6CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_COST_ASC',
  DealsVarianceSampleFeeAftermarket_6CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_COST_DESC',
  DealsVarianceSampleFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_7NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_7NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_7SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_7SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_7CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_COST_ASC',
  DealsVarianceSampleFeeAftermarket_7CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_COST_DESC',
  DealsVarianceSampleFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_8NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_8NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_8SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_8SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_8CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_COST_ASC',
  DealsVarianceSampleFeeAftermarket_8CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_COST_DESC',
  DealsVarianceSampleFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_9NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_9NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_9SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_9SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_9CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_COST_ASC',
  DealsVarianceSampleFeeAftermarket_9CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_COST_DESC',
  DealsVarianceSampleFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_10NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_10NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_10SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_10SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_10CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_COST_ASC',
  DealsVarianceSampleFeeAftermarket_10CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_COST_DESC',
  DealsVarianceSampleFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_11NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_11NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_11SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_11SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_11CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_COST_ASC',
  DealsVarianceSampleFeeAftermarket_11CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_COST_DESC',
  DealsVarianceSampleFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_12NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_12NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_12SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_12SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_12CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_COST_ASC',
  DealsVarianceSampleFeeAftermarket_12CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_COST_DESC',
  DealsVarianceSampleFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_13NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_13NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_13SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_13SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_13CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_COST_ASC',
  DealsVarianceSampleFeeAftermarket_13CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_COST_DESC',
  DealsVarianceSampleFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_14NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_14NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_14SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_14SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_14CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_COST_ASC',
  DealsVarianceSampleFeeAftermarket_14CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_COST_DESC',
  DealsVarianceSampleFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_15NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_15NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_15SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_15SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_15CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_COST_ASC',
  DealsVarianceSampleFeeAftermarket_15CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_COST_DESC',
  DealsVarianceSampleFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_16NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_16NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_16SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_16SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_16CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_COST_ASC',
  DealsVarianceSampleFeeAftermarket_16CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_COST_DESC',
  DealsVarianceSampleFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_17NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_17NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_17SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_17SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_17CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_COST_ASC',
  DealsVarianceSampleFeeAftermarket_17CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_COST_DESC',
  DealsVarianceSampleFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_18NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_18NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_18SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_18SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_18CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_COST_ASC',
  DealsVarianceSampleFeeAftermarket_18CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_COST_DESC',
  DealsVarianceSampleFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_19NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_19NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_19SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_19SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_19CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_COST_ASC',
  DealsVarianceSampleFeeAftermarket_19CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_COST_DESC',
  DealsVarianceSampleFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleFeeAftermarket_20NameAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_NAME_ASC',
  DealsVarianceSampleFeeAftermarket_20NameDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_NAME_DESC',
  DealsVarianceSampleFeeAftermarket_20SaleAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_SALE_ASC',
  DealsVarianceSampleFeeAftermarket_20SaleDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_SALE_DESC',
  DealsVarianceSampleFeeAftermarket_20CostAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_COST_ASC',
  DealsVarianceSampleFeeAftermarket_20CostDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_COST_DESC',
  DealsVarianceSampleFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsVarianceSampleFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_VARIANCE_SAMPLE_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsVarianceSampleInsurance_1TypeAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_TYPE_ASC',
  DealsVarianceSampleInsurance_1TypeDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_TYPE_DESC',
  DealsVarianceSampleInsurance_1NameAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_NAME_ASC',
  DealsVarianceSampleInsurance_1NameDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_NAME_DESC',
  DealsVarianceSampleInsurance_1SaleAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_SALE_ASC',
  DealsVarianceSampleInsurance_1SaleDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_SALE_DESC',
  DealsVarianceSampleInsurance_1CostAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_COST_ASC',
  DealsVarianceSampleInsurance_1CostDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_COST_DESC',
  DealsVarianceSampleInsurance_1TermAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_TERM_ASC',
  DealsVarianceSampleInsurance_1TermDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_1_TERM_DESC',
  DealsVarianceSampleInsurance_2TypeAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_TYPE_ASC',
  DealsVarianceSampleInsurance_2TypeDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_TYPE_DESC',
  DealsVarianceSampleInsurance_2NameAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_NAME_ASC',
  DealsVarianceSampleInsurance_2NameDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_NAME_DESC',
  DealsVarianceSampleInsurance_2SaleAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_SALE_ASC',
  DealsVarianceSampleInsurance_2SaleDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_SALE_DESC',
  DealsVarianceSampleInsurance_2CostAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_COST_ASC',
  DealsVarianceSampleInsurance_2CostDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_COST_DESC',
  DealsVarianceSampleInsurance_2TermAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_TERM_ASC',
  DealsVarianceSampleInsurance_2TermDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_2_TERM_DESC',
  DealsVarianceSampleInsurance_3TypeAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_TYPE_ASC',
  DealsVarianceSampleInsurance_3TypeDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_TYPE_DESC',
  DealsVarianceSampleInsurance_3NameAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_NAME_ASC',
  DealsVarianceSampleInsurance_3NameDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_NAME_DESC',
  DealsVarianceSampleInsurance_3SaleAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_SALE_ASC',
  DealsVarianceSampleInsurance_3SaleDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_SALE_DESC',
  DealsVarianceSampleInsurance_3CostAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_COST_ASC',
  DealsVarianceSampleInsurance_3CostDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_COST_DESC',
  DealsVarianceSampleInsurance_3TermAsc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_TERM_ASC',
  DealsVarianceSampleInsurance_3TermDesc = 'DEALS_VARIANCE_SAMPLE_INSURANCE_3_TERM_DESC',
  DealsVarianceSampleAccidentalHealthTypeAsc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsVarianceSampleAccidentalHealthTypeDesc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsVarianceSampleAccidentalHealthNameAsc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsVarianceSampleAccidentalHealthNameDesc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsVarianceSampleAccidentalHealthSaleAsc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsVarianceSampleAccidentalHealthSaleDesc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsVarianceSampleAccidentalHealthCostAsc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_COST_ASC',
  DealsVarianceSampleAccidentalHealthCostDesc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_COST_DESC',
  DealsVarianceSampleAccidentalHealthTermAsc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsVarianceSampleAccidentalHealthTermDesc = 'DEALS_VARIANCE_SAMPLE_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsVarianceSampleCreditLifeTypeAsc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_TYPE_ASC',
  DealsVarianceSampleCreditLifeTypeDesc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_TYPE_DESC',
  DealsVarianceSampleCreditLifeNameAsc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_NAME_ASC',
  DealsVarianceSampleCreditLifeNameDesc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_NAME_DESC',
  DealsVarianceSampleCreditLifeSaleAsc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_SALE_ASC',
  DealsVarianceSampleCreditLifeSaleDesc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_SALE_DESC',
  DealsVarianceSampleCreditLifeCostAsc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_COST_ASC',
  DealsVarianceSampleCreditLifeCostDesc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_COST_DESC',
  DealsVarianceSampleCreditLifeTermAsc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_TERM_ASC',
  DealsVarianceSampleCreditLifeTermDesc = 'DEALS_VARIANCE_SAMPLE_CREDIT_LIFE_TERM_DESC',
  DealsVarianceSampleLevelizedLifeTypeAsc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_TYPE_ASC',
  DealsVarianceSampleLevelizedLifeTypeDesc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_TYPE_DESC',
  DealsVarianceSampleLevelizedLifeNameAsc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_NAME_ASC',
  DealsVarianceSampleLevelizedLifeNameDesc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_NAME_DESC',
  DealsVarianceSampleLevelizedLifeSaleAsc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_SALE_ASC',
  DealsVarianceSampleLevelizedLifeSaleDesc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_SALE_DESC',
  DealsVarianceSampleLevelizedLifeCostAsc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_COST_ASC',
  DealsVarianceSampleLevelizedLifeCostDesc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_COST_DESC',
  DealsVarianceSampleLevelizedLifeTermAsc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_TERM_ASC',
  DealsVarianceSampleLevelizedLifeTermDesc = 'DEALS_VARIANCE_SAMPLE_LEVELIZED_LIFE_TERM_DESC',
  DealsVarianceSampleLossOfEmploymentTypeAsc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsVarianceSampleLossOfEmploymentTypeDesc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsVarianceSampleLossOfEmploymentNameAsc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsVarianceSampleLossOfEmploymentNameDesc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsVarianceSampleLossOfEmploymentSaleAsc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsVarianceSampleLossOfEmploymentSaleDesc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsVarianceSampleLossOfEmploymentCostAsc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsVarianceSampleLossOfEmploymentCostDesc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsVarianceSampleLossOfEmploymentTermAsc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsVarianceSampleLossOfEmploymentTermDesc = 'DEALS_VARIANCE_SAMPLE_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsVarianceSampleGuaranteedAutoProtectionTypeAsc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsVarianceSampleGuaranteedAutoProtectionTypeDesc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsVarianceSampleGuaranteedAutoProtectionNameAsc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsVarianceSampleGuaranteedAutoProtectionNameDesc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsVarianceSampleGuaranteedAutoProtectionSaleAsc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsVarianceSampleGuaranteedAutoProtectionSaleDesc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsVarianceSampleGuaranteedAutoProtectionCostAsc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsVarianceSampleGuaranteedAutoProtectionCostDesc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsVarianceSampleGuaranteedAutoProtectionTermAsc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsVarianceSampleGuaranteedAutoProtectionTermDesc = 'DEALS_VARIANCE_SAMPLE_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsVarianceSampleSaleCommentsAsc = 'DEALS_VARIANCE_SAMPLE_SALE_COMMENTS_ASC',
  DealsVarianceSampleSaleCommentsDesc = 'DEALS_VARIANCE_SAMPLE_SALE_COMMENTS_DESC',
  DealsVarianceSampleCassStdLine1Asc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LINE1_ASC',
  DealsVarianceSampleCassStdLine1Desc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LINE1_DESC',
  DealsVarianceSampleCassStdLine2Asc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LINE2_ASC',
  DealsVarianceSampleCassStdLine2Desc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LINE2_DESC',
  DealsVarianceSampleCassStdCityAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CITY_ASC',
  DealsVarianceSampleCassStdCityDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CITY_DESC',
  DealsVarianceSampleCassStdStateAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_STATE_ASC',
  DealsVarianceSampleCassStdStateDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_STATE_DESC',
  DealsVarianceSampleCassStdZipAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIP_ASC',
  DealsVarianceSampleCassStdZipDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIP_DESC',
  DealsVarianceSampleCassStdZip4Asc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIP4_ASC',
  DealsVarianceSampleCassStdZip4Desc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIP4_DESC',
  DealsVarianceSampleCassStdDpbcAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_DPBC_ASC',
  DealsVarianceSampleCassStdDpbcDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_DPBC_DESC',
  DealsVarianceSampleCassStdChkdgtAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CHKDGT_ASC',
  DealsVarianceSampleCassStdChkdgtDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CHKDGT_DESC',
  DealsVarianceSampleCassStdCartAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CART_ASC',
  DealsVarianceSampleCassStdCartDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_CART_DESC',
  DealsVarianceSampleCassStdLotAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LOT_ASC',
  DealsVarianceSampleCassStdLotDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LOT_DESC',
  DealsVarianceSampleCassStdLotordAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LOTORD_ASC',
  DealsVarianceSampleCassStdLotordDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LOTORD_DESC',
  DealsVarianceSampleCassStdUrbAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_URB_ASC',
  DealsVarianceSampleCassStdUrbDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_URB_DESC',
  DealsVarianceSampleCassStdFipsAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_FIPS_ASC',
  DealsVarianceSampleCassStdFipsDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_FIPS_DESC',
  DealsVarianceSampleCassStdEwsAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_EWS_ASC',
  DealsVarianceSampleCassStdEwsDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_EWS_DESC',
  DealsVarianceSampleCassStdLacsAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LACS_ASC',
  DealsVarianceSampleCassStdLacsDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LACS_DESC',
  DealsVarianceSampleCassStdZipmovAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIPMOV_ASC',
  DealsVarianceSampleCassStdZipmovDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ZIPMOV_DESC',
  DealsVarianceSampleCassStdZ4LomAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_Z4LOM_ASC',
  DealsVarianceSampleCassStdZ4LomDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_Z4LOM_DESC',
  DealsVarianceSampleCassStdNdiaptAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_NDIAPT_ASC',
  DealsVarianceSampleCassStdNdiaptDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_NDIAPT_DESC',
  DealsVarianceSampleCassStdNdirrAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_NDIRR_ASC',
  DealsVarianceSampleCassStdNdirrDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_NDIRR_DESC',
  DealsVarianceSampleCassStdLacsrtAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LACSRT_ASC',
  DealsVarianceSampleCassStdLacsrtDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_LACSRT_DESC',
  DealsVarianceSampleCassStdErrorCdAsc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ERROR_CD_ASC',
  DealsVarianceSampleCassStdErrorCdDesc = 'DEALS_VARIANCE_SAMPLE_CASS_STD_ERROR_CD_DESC',
  DealsVariancePopulationIdAsc = 'DEALS_VARIANCE_POPULATION_ID_ASC',
  DealsVariancePopulationIdDesc = 'DEALS_VARIANCE_POPULATION_ID_DESC',
  DealsVariancePopulationLeadIdAsc = 'DEALS_VARIANCE_POPULATION_LEAD_ID_ASC',
  DealsVariancePopulationLeadIdDesc = 'DEALS_VARIANCE_POPULATION_LEAD_ID_DESC',
  DealsVariancePopulationCompanyIntegrationIdAsc = 'DEALS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  DealsVariancePopulationCompanyIntegrationIdDesc = 'DEALS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  DealsVariancePopulationFileTypeAsc = 'DEALS_VARIANCE_POPULATION_FILE_TYPE_ASC',
  DealsVariancePopulationFileTypeDesc = 'DEALS_VARIANCE_POPULATION_FILE_TYPE_DESC',
  DealsVariancePopulationDvDealerIdAsc = 'DEALS_VARIANCE_POPULATION_DV_DEALER_ID_ASC',
  DealsVariancePopulationDvDealerIdDesc = 'DEALS_VARIANCE_POPULATION_DV_DEALER_ID_DESC',
  DealsVariancePopulationVendorDealerIdAsc = 'DEALS_VARIANCE_POPULATION_VENDOR_DEALER_ID_ASC',
  DealsVariancePopulationVendorDealerIdDesc = 'DEALS_VARIANCE_POPULATION_VENDOR_DEALER_ID_DESC',
  DealsVariancePopulationDmsTypeAsc = 'DEALS_VARIANCE_POPULATION_DMS_TYPE_ASC',
  DealsVariancePopulationDmsTypeDesc = 'DEALS_VARIANCE_POPULATION_DMS_TYPE_DESC',
  DealsVariancePopulationDealNumberAsc = 'DEALS_VARIANCE_POPULATION_DEAL_NUMBER_ASC',
  DealsVariancePopulationDealNumberDesc = 'DEALS_VARIANCE_POPULATION_DEAL_NUMBER_DESC',
  DealsVariancePopulationCustomerNumberAsc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_NUMBER_ASC',
  DealsVariancePopulationCustomerNumberDesc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_NUMBER_DESC',
  DealsVariancePopulationFullNameAsc = 'DEALS_VARIANCE_POPULATION_FULL_NAME_ASC',
  DealsVariancePopulationFullNameDesc = 'DEALS_VARIANCE_POPULATION_FULL_NAME_DESC',
  DealsVariancePopulationSalutationAsc = 'DEALS_VARIANCE_POPULATION_SALUTATION_ASC',
  DealsVariancePopulationSalutationDesc = 'DEALS_VARIANCE_POPULATION_SALUTATION_DESC',
  DealsVariancePopulationFirstNameAsc = 'DEALS_VARIANCE_POPULATION_FIRST_NAME_ASC',
  DealsVariancePopulationFirstNameDesc = 'DEALS_VARIANCE_POPULATION_FIRST_NAME_DESC',
  DealsVariancePopulationMiddleNameAsc = 'DEALS_VARIANCE_POPULATION_MIDDLE_NAME_ASC',
  DealsVariancePopulationMiddleNameDesc = 'DEALS_VARIANCE_POPULATION_MIDDLE_NAME_DESC',
  DealsVariancePopulationLastNameAsc = 'DEALS_VARIANCE_POPULATION_LAST_NAME_ASC',
  DealsVariancePopulationLastNameDesc = 'DEALS_VARIANCE_POPULATION_LAST_NAME_DESC',
  DealsVariancePopulationSuffixAsc = 'DEALS_VARIANCE_POPULATION_SUFFIX_ASC',
  DealsVariancePopulationSuffixDesc = 'DEALS_VARIANCE_POPULATION_SUFFIX_DESC',
  DealsVariancePopulationAddressLine_1Asc = 'DEALS_VARIANCE_POPULATION_ADDRESS_LINE_1_ASC',
  DealsVariancePopulationAddressLine_1Desc = 'DEALS_VARIANCE_POPULATION_ADDRESS_LINE_1_DESC',
  DealsVariancePopulationAddressLine_2Asc = 'DEALS_VARIANCE_POPULATION_ADDRESS_LINE_2_ASC',
  DealsVariancePopulationAddressLine_2Desc = 'DEALS_VARIANCE_POPULATION_ADDRESS_LINE_2_DESC',
  DealsVariancePopulationCityAsc = 'DEALS_VARIANCE_POPULATION_CITY_ASC',
  DealsVariancePopulationCityDesc = 'DEALS_VARIANCE_POPULATION_CITY_DESC',
  DealsVariancePopulationStateAsc = 'DEALS_VARIANCE_POPULATION_STATE_ASC',
  DealsVariancePopulationStateDesc = 'DEALS_VARIANCE_POPULATION_STATE_DESC',
  DealsVariancePopulationZipAsc = 'DEALS_VARIANCE_POPULATION_ZIP_ASC',
  DealsVariancePopulationZipDesc = 'DEALS_VARIANCE_POPULATION_ZIP_DESC',
  DealsVariancePopulationCountyAsc = 'DEALS_VARIANCE_POPULATION_COUNTY_ASC',
  DealsVariancePopulationCountyDesc = 'DEALS_VARIANCE_POPULATION_COUNTY_DESC',
  DealsVariancePopulationHomePhoneAsc = 'DEALS_VARIANCE_POPULATION_HOME_PHONE_ASC',
  DealsVariancePopulationHomePhoneDesc = 'DEALS_VARIANCE_POPULATION_HOME_PHONE_DESC',
  DealsVariancePopulationCellPhoneAsc = 'DEALS_VARIANCE_POPULATION_CELL_PHONE_ASC',
  DealsVariancePopulationCellPhoneDesc = 'DEALS_VARIANCE_POPULATION_CELL_PHONE_DESC',
  DealsVariancePopulationWorkPhoneAsc = 'DEALS_VARIANCE_POPULATION_WORK_PHONE_ASC',
  DealsVariancePopulationWorkPhoneDesc = 'DEALS_VARIANCE_POPULATION_WORK_PHONE_DESC',
  DealsVariancePopulationWorkExtensionAsc = 'DEALS_VARIANCE_POPULATION_WORK_EXTENSION_ASC',
  DealsVariancePopulationWorkExtensionDesc = 'DEALS_VARIANCE_POPULATION_WORK_EXTENSION_DESC',
  DealsVariancePopulationEmail_1Asc = 'DEALS_VARIANCE_POPULATION_EMAIL_1_ASC',
  DealsVariancePopulationEmail_1Desc = 'DEALS_VARIANCE_POPULATION_EMAIL_1_DESC',
  DealsVariancePopulationEmail_2Asc = 'DEALS_VARIANCE_POPULATION_EMAIL_2_ASC',
  DealsVariancePopulationEmail_2Desc = 'DEALS_VARIANCE_POPULATION_EMAIL_2_DESC',
  DealsVariancePopulationEmail_3Asc = 'DEALS_VARIANCE_POPULATION_EMAIL_3_ASC',
  DealsVariancePopulationEmail_3Desc = 'DEALS_VARIANCE_POPULATION_EMAIL_3_DESC',
  DealsVariancePopulationBirthDateAsc = 'DEALS_VARIANCE_POPULATION_BIRTH_DATE_ASC',
  DealsVariancePopulationBirthDateDesc = 'DEALS_VARIANCE_POPULATION_BIRTH_DATE_DESC',
  DealsVariancePopulationIndividualBusinessFlagAsc = 'DEALS_VARIANCE_POPULATION_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsVariancePopulationIndividualBusinessFlagDesc = 'DEALS_VARIANCE_POPULATION_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsVariancePopulationOptOutAsc = 'DEALS_VARIANCE_POPULATION_OPT_OUT_ASC',
  DealsVariancePopulationOptOutDesc = 'DEALS_VARIANCE_POPULATION_OPT_OUT_DESC',
  DealsVariancePopulationBlockEmailAsc = 'DEALS_VARIANCE_POPULATION_BLOCK_EMAIL_ASC',
  DealsVariancePopulationBlockEmailDesc = 'DEALS_VARIANCE_POPULATION_BLOCK_EMAIL_DESC',
  DealsVariancePopulationBlockPhoneAsc = 'DEALS_VARIANCE_POPULATION_BLOCK_PHONE_ASC',
  DealsVariancePopulationBlockPhoneDesc = 'DEALS_VARIANCE_POPULATION_BLOCK_PHONE_DESC',
  DealsVariancePopulationBlockMailAsc = 'DEALS_VARIANCE_POPULATION_BLOCK_MAIL_ASC',
  DealsVariancePopulationBlockMailDesc = 'DEALS_VARIANCE_POPULATION_BLOCK_MAIL_DESC',
  DealsVariancePopulationLanguageAsc = 'DEALS_VARIANCE_POPULATION_LANGUAGE_ASC',
  DealsVariancePopulationLanguageDesc = 'DEALS_VARIANCE_POPULATION_LANGUAGE_DESC',
  DealsVariancePopulationCustomerCreateDateAsc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_CREATE_DATE_ASC',
  DealsVariancePopulationCustomerCreateDateDesc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_CREATE_DATE_DESC',
  DealsVariancePopulationCustomerLastActivityDateAsc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_LAST_ACTIVITY_DATE_ASC',
  DealsVariancePopulationCustomerLastActivityDateDesc = 'DEALS_VARIANCE_POPULATION_CUSTOMER_LAST_ACTIVITY_DATE_DESC',
  DealsVariancePopulationCoBuyerCustomerNumberAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CUSTOMER_NUMBER_ASC',
  DealsVariancePopulationCoBuyerCustomerNumberDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CUSTOMER_NUMBER_DESC',
  DealsVariancePopulationCoBuyerFullNameAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_FULL_NAME_ASC',
  DealsVariancePopulationCoBuyerFullNameDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_FULL_NAME_DESC',
  DealsVariancePopulationCoBuyerSalutationAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_SALUTATION_ASC',
  DealsVariancePopulationCoBuyerSalutationDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_SALUTATION_DESC',
  DealsVariancePopulationCoBuyerFirstNameAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_FIRST_NAME_ASC',
  DealsVariancePopulationCoBuyerFirstNameDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_FIRST_NAME_DESC',
  DealsVariancePopulationCoBuyerMiddleNameAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_MIDDLE_NAME_ASC',
  DealsVariancePopulationCoBuyerMiddleNameDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_MIDDLE_NAME_DESC',
  DealsVariancePopulationCoBuyerLastNameAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_LAST_NAME_ASC',
  DealsVariancePopulationCoBuyerLastNameDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_LAST_NAME_DESC',
  DealsVariancePopulationCoBuyerSuffixAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_SUFFIX_ASC',
  DealsVariancePopulationCoBuyerSuffixDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_SUFFIX_DESC',
  DealsVariancePopulationCoBuyerAddressLine_1Asc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ADDRESS_LINE_1_ASC',
  DealsVariancePopulationCoBuyerAddressLine_1Desc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ADDRESS_LINE_1_DESC',
  DealsVariancePopulationCoBuyerAddressLine_2Asc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ADDRESS_LINE_2_ASC',
  DealsVariancePopulationCoBuyerAddressLine_2Desc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ADDRESS_LINE_2_DESC',
  DealsVariancePopulationCoBuyerCityAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CITY_ASC',
  DealsVariancePopulationCoBuyerCityDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CITY_DESC',
  DealsVariancePopulationCoBuyerStateAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_STATE_ASC',
  DealsVariancePopulationCoBuyerStateDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_STATE_DESC',
  DealsVariancePopulationCoBuyerZipAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ZIP_ASC',
  DealsVariancePopulationCoBuyerZipDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_ZIP_DESC',
  DealsVariancePopulationCoBuyerCountyAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_COUNTY_ASC',
  DealsVariancePopulationCoBuyerCountyDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_COUNTY_DESC',
  DealsVariancePopulationCoBuyerHomePhoneAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_HOME_PHONE_ASC',
  DealsVariancePopulationCoBuyerHomePhoneDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_HOME_PHONE_DESC',
  DealsVariancePopulationCoBuyerCellPhoneAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CELL_PHONE_ASC',
  DealsVariancePopulationCoBuyerCellPhoneDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_CELL_PHONE_DESC',
  DealsVariancePopulationCoBuyerWorkPhoneAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_WORK_PHONE_ASC',
  DealsVariancePopulationCoBuyerWorkPhoneDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_WORK_PHONE_DESC',
  DealsVariancePopulationCoBuyerWorkExtensionAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_WORK_EXTENSION_ASC',
  DealsVariancePopulationCoBuyerWorkExtensionDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_WORK_EXTENSION_DESC',
  DealsVariancePopulationCoBuyerEmail_1Asc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_1_ASC',
  DealsVariancePopulationCoBuyerEmail_1Desc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_1_DESC',
  DealsVariancePopulationCoBuyerEmail_2Asc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_2_ASC',
  DealsVariancePopulationCoBuyerEmail_2Desc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_2_DESC',
  DealsVariancePopulationCoBuyerEmail_3Asc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_3_ASC',
  DealsVariancePopulationCoBuyerEmail_3Desc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_EMAIL_3_DESC',
  DealsVariancePopulationCoBuyerBirthDateAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BIRTH_DATE_ASC',
  DealsVariancePopulationCoBuyerBirthDateDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BIRTH_DATE_DESC',
  DealsVariancePopulationCoBuyerIndividualBusinessFlagAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_ASC',
  DealsVariancePopulationCoBuyerIndividualBusinessFlagDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_INDIVIDUAL_BUSINESS_FLAG_DESC',
  DealsVariancePopulationCoBuyerOptOutAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_OPT_OUT_ASC',
  DealsVariancePopulationCoBuyerOptOutDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_OPT_OUT_DESC',
  DealsVariancePopulationCoBuyerBlockEmailAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_EMAIL_ASC',
  DealsVariancePopulationCoBuyerBlockEmailDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_EMAIL_DESC',
  DealsVariancePopulationCoBuyerBlockPhoneAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_PHONE_ASC',
  DealsVariancePopulationCoBuyerBlockPhoneDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_PHONE_DESC',
  DealsVariancePopulationCoBuyerBlockMailAsc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_MAIL_ASC',
  DealsVariancePopulationCoBuyerBlockMailDesc = 'DEALS_VARIANCE_POPULATION_CO_BUYER_BLOCK_MAIL_DESC',
  DealsVariancePopulationVinAsc = 'DEALS_VARIANCE_POPULATION_VIN_ASC',
  DealsVariancePopulationVinDesc = 'DEALS_VARIANCE_POPULATION_VIN_DESC',
  DealsVariancePopulationYearAsc = 'DEALS_VARIANCE_POPULATION_YEAR_ASC',
  DealsVariancePopulationYearDesc = 'DEALS_VARIANCE_POPULATION_YEAR_DESC',
  DealsVariancePopulationMakeAsc = 'DEALS_VARIANCE_POPULATION_MAKE_ASC',
  DealsVariancePopulationMakeDesc = 'DEALS_VARIANCE_POPULATION_MAKE_DESC',
  DealsVariancePopulationModelAsc = 'DEALS_VARIANCE_POPULATION_MODEL_ASC',
  DealsVariancePopulationModelDesc = 'DEALS_VARIANCE_POPULATION_MODEL_DESC',
  DealsVariancePopulationModelNumberAsc = 'DEALS_VARIANCE_POPULATION_MODEL_NUMBER_ASC',
  DealsVariancePopulationModelNumberDesc = 'DEALS_VARIANCE_POPULATION_MODEL_NUMBER_DESC',
  DealsVariancePopulationMileageAsc = 'DEALS_VARIANCE_POPULATION_MILEAGE_ASC',
  DealsVariancePopulationMileageDesc = 'DEALS_VARIANCE_POPULATION_MILEAGE_DESC',
  DealsVariancePopulationDescriptionAsc = 'DEALS_VARIANCE_POPULATION_DESCRIPTION_ASC',
  DealsVariancePopulationDescriptionDesc = 'DEALS_VARIANCE_POPULATION_DESCRIPTION_DESC',
  DealsVariancePopulationExteriorColorAsc = 'DEALS_VARIANCE_POPULATION_EXTERIOR_COLOR_ASC',
  DealsVariancePopulationExteriorColorDesc = 'DEALS_VARIANCE_POPULATION_EXTERIOR_COLOR_DESC',
  DealsVariancePopulationNewUsedAsc = 'DEALS_VARIANCE_POPULATION_NEW_USED_ASC',
  DealsVariancePopulationNewUsedDesc = 'DEALS_VARIANCE_POPULATION_NEW_USED_DESC',
  DealsVariancePopulationStockNumberAsc = 'DEALS_VARIANCE_POPULATION_STOCK_NUMBER_ASC',
  DealsVariancePopulationStockNumberDesc = 'DEALS_VARIANCE_POPULATION_STOCK_NUMBER_DESC',
  DealsVariancePopulationTransmissionAsc = 'DEALS_VARIANCE_POPULATION_TRANSMISSION_ASC',
  DealsVariancePopulationTransmissionDesc = 'DEALS_VARIANCE_POPULATION_TRANSMISSION_DESC',
  DealsVariancePopulationEngineConfigurationAsc = 'DEALS_VARIANCE_POPULATION_ENGINE_CONFIGURATION_ASC',
  DealsVariancePopulationEngineConfigurationDesc = 'DEALS_VARIANCE_POPULATION_ENGINE_CONFIGURATION_DESC',
  DealsVariancePopulationTrimAsc = 'DEALS_VARIANCE_POPULATION_TRIM_ASC',
  DealsVariancePopulationTrimDesc = 'DEALS_VARIANCE_POPULATION_TRIM_DESC',
  DealsVariancePopulationEngineNumberAsc = 'DEALS_VARIANCE_POPULATION_ENGINE_NUMBER_ASC',
  DealsVariancePopulationEngineNumberDesc = 'DEALS_VARIANCE_POPULATION_ENGINE_NUMBER_DESC',
  DealsVariancePopulationChassisNumberAsc = 'DEALS_VARIANCE_POPULATION_CHASSIS_NUMBER_ASC',
  DealsVariancePopulationChassisNumberDesc = 'DEALS_VARIANCE_POPULATION_CHASSIS_NUMBER_DESC',
  DealsVariancePopulationLicensePlateNumberAsc = 'DEALS_VARIANCE_POPULATION_LICENSE_PLATE_NUMBER_ASC',
  DealsVariancePopulationLicensePlateNumberDesc = 'DEALS_VARIANCE_POPULATION_LICENSE_PLATE_NUMBER_DESC',
  DealsVariancePopulationDeliveryDateAsc = 'DEALS_VARIANCE_POPULATION_DELIVERY_DATE_ASC',
  DealsVariancePopulationDeliveryDateDesc = 'DEALS_VARIANCE_POPULATION_DELIVERY_DATE_DESC',
  DealsVariancePopulationDeliveryMileageAsc = 'DEALS_VARIANCE_POPULATION_DELIVERY_MILEAGE_ASC',
  DealsVariancePopulationDeliveryMileageDesc = 'DEALS_VARIANCE_POPULATION_DELIVERY_MILEAGE_DESC',
  DealsVariancePopulationInventoryDateAsc = 'DEALS_VARIANCE_POPULATION_INVENTORY_DATE_ASC',
  DealsVariancePopulationInventoryDateDesc = 'DEALS_VARIANCE_POPULATION_INVENTORY_DATE_DESC',
  DealsVariancePopulationInServiceDateAsc = 'DEALS_VARIANCE_POPULATION_IN_SERVICE_DATE_ASC',
  DealsVariancePopulationInServiceDateDesc = 'DEALS_VARIANCE_POPULATION_IN_SERVICE_DATE_DESC',
  DealsVariancePopulationVinExplosionYearAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_YEAR_ASC',
  DealsVariancePopulationVinExplosionYearDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_YEAR_DESC',
  DealsVariancePopulationVinExplosionMakeAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_MAKE_ASC',
  DealsVariancePopulationVinExplosionMakeDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_MAKE_DESC',
  DealsVariancePopulationVinExplosionModelAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_MODEL_ASC',
  DealsVariancePopulationVinExplosionModelDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_MODEL_DESC',
  DealsVariancePopulationVinExplosionTrimAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_TRIM_ASC',
  DealsVariancePopulationVinExplosionTrimDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_TRIM_DESC',
  DealsVariancePopulationVinExplosionTransmissionTypeAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_TRANSMISSION_TYPE_ASC',
  DealsVariancePopulationVinExplosionTransmissionTypeDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_TRANSMISSION_TYPE_DESC',
  DealsVariancePopulationVinExplosionFuelTypeAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_FUEL_TYPE_ASC',
  DealsVariancePopulationVinExplosionFuelTypeDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_FUEL_TYPE_DESC',
  DealsVariancePopulationVinExplosionEngineSizeAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_ENGINE_SIZE_ASC',
  DealsVariancePopulationVinExplosionEngineSizeDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_ENGINE_SIZE_DESC',
  DealsVariancePopulationVinExplosionGvwRangeAsc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_GVW_RANGE_ASC',
  DealsVariancePopulationVinExplosionGvwRangeDesc = 'DEALS_VARIANCE_POPULATION_VIN_EXPLOSION_GVW_RANGE_DESC',
  DealsVariancePopulationTrade_1VinAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_VIN_ASC',
  DealsVariancePopulationTrade_1VinDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_VIN_DESC',
  DealsVariancePopulationTrade_1YearAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_YEAR_ASC',
  DealsVariancePopulationTrade_1YearDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_YEAR_DESC',
  DealsVariancePopulationTrade_1MakeAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_MAKE_ASC',
  DealsVariancePopulationTrade_1MakeDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_MAKE_DESC',
  DealsVariancePopulationTrade_1ModelAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_MODEL_ASC',
  DealsVariancePopulationTrade_1ModelDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_MODEL_DESC',
  DealsVariancePopulationTrade_1OdometerAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_ODOMETER_ASC',
  DealsVariancePopulationTrade_1OdometerDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_ODOMETER_DESC',
  DealsVariancePopulationTrade_1ActualCashValueAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_ACTUAL_CASH_VALUE_ASC',
  DealsVariancePopulationTrade_1ActualCashValueDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_ACTUAL_CASH_VALUE_DESC',
  DealsVariancePopulationTrade_1GrossAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_GROSS_ASC',
  DealsVariancePopulationTrade_1GrossDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_GROSS_DESC',
  DealsVariancePopulationTrade_1PayoffAsc = 'DEALS_VARIANCE_POPULATION_TRADE_1_PAYOFF_ASC',
  DealsVariancePopulationTrade_1PayoffDesc = 'DEALS_VARIANCE_POPULATION_TRADE_1_PAYOFF_DESC',
  DealsVariancePopulationTrade_2VinAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_VIN_ASC',
  DealsVariancePopulationTrade_2VinDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_VIN_DESC',
  DealsVariancePopulationTrade_2YearAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_YEAR_ASC',
  DealsVariancePopulationTrade_2YearDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_YEAR_DESC',
  DealsVariancePopulationTrade_2MakeAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_MAKE_ASC',
  DealsVariancePopulationTrade_2MakeDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_MAKE_DESC',
  DealsVariancePopulationTrade_2ModelAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_MODEL_ASC',
  DealsVariancePopulationTrade_2ModelDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_MODEL_DESC',
  DealsVariancePopulationTrade_2OdometerAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_ODOMETER_ASC',
  DealsVariancePopulationTrade_2OdometerDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_ODOMETER_DESC',
  DealsVariancePopulationTrade_2ActualCashValueAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_ACTUAL_CASH_VALUE_ASC',
  DealsVariancePopulationTrade_2ActualCashValueDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_ACTUAL_CASH_VALUE_DESC',
  DealsVariancePopulationTrade_2GrossAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_GROSS_ASC',
  DealsVariancePopulationTrade_2GrossDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_GROSS_DESC',
  DealsVariancePopulationTrade_2PayoffAsc = 'DEALS_VARIANCE_POPULATION_TRADE_2_PAYOFF_ASC',
  DealsVariancePopulationTrade_2PayoffDesc = 'DEALS_VARIANCE_POPULATION_TRADE_2_PAYOFF_DESC',
  DealsVariancePopulationSalesman_1NumberAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_NUMBER_ASC',
  DealsVariancePopulationSalesman_1NumberDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_NUMBER_DESC',
  DealsVariancePopulationSalesman_1NameAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_NAME_ASC',
  DealsVariancePopulationSalesman_1NameDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_NAME_DESC',
  DealsVariancePopulationSalesman_2NumberAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_NUMBER_ASC',
  DealsVariancePopulationSalesman_2NumberDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_NUMBER_DESC',
  DealsVariancePopulationSalesman_2NameAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_NAME_ASC',
  DealsVariancePopulationSalesman_2NameDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_NAME_DESC',
  DealsVariancePopulationSalesman_3NumberAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_NUMBER_ASC',
  DealsVariancePopulationSalesman_3NumberDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_NUMBER_DESC',
  DealsVariancePopulationSalesman_3NameAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_NAME_ASC',
  DealsVariancePopulationSalesman_3NameDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_NAME_DESC',
  DealsVariancePopulationClosingManagerNumberAsc = 'DEALS_VARIANCE_POPULATION_CLOSING_MANAGER_NUMBER_ASC',
  DealsVariancePopulationClosingManagerNumberDesc = 'DEALS_VARIANCE_POPULATION_CLOSING_MANAGER_NUMBER_DESC',
  DealsVariancePopulationClosingManagerNameAsc = 'DEALS_VARIANCE_POPULATION_CLOSING_MANAGER_NAME_ASC',
  DealsVariancePopulationClosingManagerNameDesc = 'DEALS_VARIANCE_POPULATION_CLOSING_MANAGER_NAME_DESC',
  DealsVariancePopulationFinanceManagerNumberAsc = 'DEALS_VARIANCE_POPULATION_FINANCE_MANAGER_NUMBER_ASC',
  DealsVariancePopulationFinanceManagerNumberDesc = 'DEALS_VARIANCE_POPULATION_FINANCE_MANAGER_NUMBER_DESC',
  DealsVariancePopulationFinanceManagerNameAsc = 'DEALS_VARIANCE_POPULATION_FINANCE_MANAGER_NAME_ASC',
  DealsVariancePopulationFinanceManagerNameDesc = 'DEALS_VARIANCE_POPULATION_FINANCE_MANAGER_NAME_DESC',
  DealsVariancePopulationSalesmanManagerNumberAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_MANAGER_NUMBER_ASC',
  DealsVariancePopulationSalesmanManagerNumberDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_MANAGER_NUMBER_DESC',
  DealsVariancePopulationSalesmanManagerNameAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_MANAGER_NAME_ASC',
  DealsVariancePopulationSalesmanManagerNameDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_MANAGER_NAME_DESC',
  DealsVariancePopulationMsrpAsc = 'DEALS_VARIANCE_POPULATION_MSRP_ASC',
  DealsVariancePopulationMsrpDesc = 'DEALS_VARIANCE_POPULATION_MSRP_DESC',
  DealsVariancePopulationListPriceAsc = 'DEALS_VARIANCE_POPULATION_LIST_PRICE_ASC',
  DealsVariancePopulationListPriceDesc = 'DEALS_VARIANCE_POPULATION_LIST_PRICE_DESC',
  DealsVariancePopulationSalesPriceAsc = 'DEALS_VARIANCE_POPULATION_SALES_PRICE_ASC',
  DealsVariancePopulationSalesPriceDesc = 'DEALS_VARIANCE_POPULATION_SALES_PRICE_DESC',
  DealsVariancePopulationJournalPriceAsc = 'DEALS_VARIANCE_POPULATION_JOURNAL_PRICE_ASC',
  DealsVariancePopulationJournalPriceDesc = 'DEALS_VARIANCE_POPULATION_JOURNAL_PRICE_DESC',
  DealsVariancePopulationCostAsc = 'DEALS_VARIANCE_POPULATION_COST_ASC',
  DealsVariancePopulationCostDesc = 'DEALS_VARIANCE_POPULATION_COST_DESC',
  DealsVariancePopulationJournalCostAsc = 'DEALS_VARIANCE_POPULATION_JOURNAL_COST_ASC',
  DealsVariancePopulationJournalCostDesc = 'DEALS_VARIANCE_POPULATION_JOURNAL_COST_DESC',
  DealsVariancePopulationAdjustmentsAsc = 'DEALS_VARIANCE_POPULATION_ADJUSTMENTS_ASC',
  DealsVariancePopulationAdjustmentsDesc = 'DEALS_VARIANCE_POPULATION_ADJUSTMENTS_DESC',
  DealsVariancePopulationAdjustedCostAsc = 'DEALS_VARIANCE_POPULATION_ADJUSTED_COST_ASC',
  DealsVariancePopulationAdjustedCostDesc = 'DEALS_VARIANCE_POPULATION_ADJUSTED_COST_DESC',
  DealsVariancePopulationIncentivesAsc = 'DEALS_VARIANCE_POPULATION_INCENTIVES_ASC',
  DealsVariancePopulationIncentivesDesc = 'DEALS_VARIANCE_POPULATION_INCENTIVES_DESC',
  DealsVariancePopulationPackAmountAsc = 'DEALS_VARIANCE_POPULATION_PACK_AMOUNT_ASC',
  DealsVariancePopulationPackAmountDesc = 'DEALS_VARIANCE_POPULATION_PACK_AMOUNT_DESC',
  DealsVariancePopulationSaleNetAsc = 'DEALS_VARIANCE_POPULATION_SALE_NET_ASC',
  DealsVariancePopulationSaleNetDesc = 'DEALS_VARIANCE_POPULATION_SALE_NET_DESC',
  DealsVariancePopulationTotalTradeActualCashValueAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_TRADE_ACTUAL_CASH_VALUE_ASC',
  DealsVariancePopulationTotalTradeActualCashValueDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_TRADE_ACTUAL_CASH_VALUE_DESC',
  DealsVariancePopulationTotalTradeGrossAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_TRADE_GROSS_ASC',
  DealsVariancePopulationTotalTradeGrossDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_TRADE_GROSS_DESC',
  DealsVariancePopulationWeOweFrontAsc = 'DEALS_VARIANCE_POPULATION_WE_OWE_FRONT_ASC',
  DealsVariancePopulationWeOweFrontDesc = 'DEALS_VARIANCE_POPULATION_WE_OWE_FRONT_DESC',
  DealsVariancePopulationTotalFrontFeesAftermarketProfitAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_ASC',
  DealsVariancePopulationTotalFrontFeesAftermarketProfitDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_FEES_AFTERMARKET_PROFIT_DESC',
  DealsVariancePopulationTotalFrontCommissionAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_COMMISSION_ASC',
  DealsVariancePopulationTotalFrontCommissionDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_COMMISSION_DESC',
  DealsVariancePopulationTotalFrontSalesAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_SALES_ASC',
  DealsVariancePopulationTotalFrontSalesDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_SALES_DESC',
  DealsVariancePopulationTotalFrontCostAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_COST_ASC',
  DealsVariancePopulationTotalFrontCostDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FRONT_COST_DESC',
  DealsVariancePopulationFrontGrossAsc = 'DEALS_VARIANCE_POPULATION_FRONT_GROSS_ASC',
  DealsVariancePopulationFrontGrossDesc = 'DEALS_VARIANCE_POPULATION_FRONT_GROSS_DESC',
  DealsVariancePopulationFinanceProfitAsc = 'DEALS_VARIANCE_POPULATION_FINANCE_PROFIT_ASC',
  DealsVariancePopulationFinanceProfitDesc = 'DEALS_VARIANCE_POPULATION_FINANCE_PROFIT_DESC',
  DealsVariancePopulationTotalWarrantyProfitAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_WARRANTY_PROFIT_ASC',
  DealsVariancePopulationTotalWarrantyProfitDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_WARRANTY_PROFIT_DESC',
  DealsVariancePopulationWeOweBackAsc = 'DEALS_VARIANCE_POPULATION_WE_OWE_BACK_ASC',
  DealsVariancePopulationWeOweBackDesc = 'DEALS_VARIANCE_POPULATION_WE_OWE_BACK_DESC',
  DealsVariancePopulationInsuranceProfitAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_PROFIT_ASC',
  DealsVariancePopulationInsuranceProfitDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_PROFIT_DESC',
  DealsVariancePopulationTotalBackFeesAftermarketProfitAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_ASC',
  DealsVariancePopulationTotalBackFeesAftermarketProfitDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_FEES_AFTERMARKET_PROFIT_DESC',
  DealsVariancePopulationFinanceReserveAsc = 'DEALS_VARIANCE_POPULATION_FINANCE_RESERVE_ASC',
  DealsVariancePopulationFinanceReserveDesc = 'DEALS_VARIANCE_POPULATION_FINANCE_RESERVE_DESC',
  DealsVariancePopulationTotalBackCommissionAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_COMMISSION_ASC',
  DealsVariancePopulationTotalBackCommissionDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_COMMISSION_DESC',
  DealsVariancePopulationTotalBackSalesAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_SALES_ASC',
  DealsVariancePopulationTotalBackSalesDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_SALES_DESC',
  DealsVariancePopulationTotalBackCostAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_COST_ASC',
  DealsVariancePopulationTotalBackCostDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_BACK_COST_DESC',
  DealsVariancePopulationBackGrossAsc = 'DEALS_VARIANCE_POPULATION_BACK_GROSS_ASC',
  DealsVariancePopulationBackGrossDesc = 'DEALS_VARIANCE_POPULATION_BACK_GROSS_DESC',
  DealsVariancePopulationTotalProfitAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_PROFIT_ASC',
  DealsVariancePopulationTotalProfitDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_PROFIT_DESC',
  DealsVariancePopulationGrossProfitAsc = 'DEALS_VARIANCE_POPULATION_GROSS_PROFIT_ASC',
  DealsVariancePopulationGrossProfitDesc = 'DEALS_VARIANCE_POPULATION_GROSS_PROFIT_DESC',
  DealsVariancePopulationGrossPayableAsc = 'DEALS_VARIANCE_POPULATION_GROSS_PAYABLE_ASC',
  DealsVariancePopulationGrossPayableDesc = 'DEALS_VARIANCE_POPULATION_GROSS_PAYABLE_DESC',
  DealsVariancePopulationDealStatusAsc = 'DEALS_VARIANCE_POPULATION_DEAL_STATUS_ASC',
  DealsVariancePopulationDealStatusDesc = 'DEALS_VARIANCE_POPULATION_DEAL_STATUS_DESC',
  DealsVariancePopulationEntryDateAsc = 'DEALS_VARIANCE_POPULATION_ENTRY_DATE_ASC',
  DealsVariancePopulationEntryDateDesc = 'DEALS_VARIANCE_POPULATION_ENTRY_DATE_DESC',
  DealsVariancePopulationBookedDateAsc = 'DEALS_VARIANCE_POPULATION_BOOKED_DATE_ASC',
  DealsVariancePopulationBookedDateDesc = 'DEALS_VARIANCE_POPULATION_BOOKED_DATE_DESC',
  DealsVariancePopulationFinalizedDateAsc = 'DEALS_VARIANCE_POPULATION_FINALIZED_DATE_ASC',
  DealsVariancePopulationFinalizedDateDesc = 'DEALS_VARIANCE_POPULATION_FINALIZED_DATE_DESC',
  DealsVariancePopulationContractDateAsc = 'DEALS_VARIANCE_POPULATION_CONTRACT_DATE_ASC',
  DealsVariancePopulationContractDateDesc = 'DEALS_VARIANCE_POPULATION_CONTRACT_DATE_DESC',
  DealsVariancePopulationAccountingDateAsc = 'DEALS_VARIANCE_POPULATION_ACCOUNTING_DATE_ASC',
  DealsVariancePopulationAccountingDateDesc = 'DEALS_VARIANCE_POPULATION_ACCOUNTING_DATE_DESC',
  DealsVariancePopulationStatusChangeDateAsc = 'DEALS_VARIANCE_POPULATION_STATUS_CHANGE_DATE_ASC',
  DealsVariancePopulationStatusChangeDateDesc = 'DEALS_VARIANCE_POPULATION_STATUS_CHANGE_DATE_DESC',
  DealsVariancePopulationFirstPayDateAsc = 'DEALS_VARIANCE_POPULATION_FIRST_PAY_DATE_ASC',
  DealsVariancePopulationFirstPayDateDesc = 'DEALS_VARIANCE_POPULATION_FIRST_PAY_DATE_DESC',
  DealsVariancePopulationDealTypeAsc = 'DEALS_VARIANCE_POPULATION_DEAL_TYPE_ASC',
  DealsVariancePopulationDealTypeDesc = 'DEALS_VARIANCE_POPULATION_DEAL_TYPE_DESC',
  DealsVariancePopulationSaleTypeAsc = 'DEALS_VARIANCE_POPULATION_SALE_TYPE_ASC',
  DealsVariancePopulationSaleTypeDesc = 'DEALS_VARIANCE_POPULATION_SALE_TYPE_DESC',
  DealsVariancePopulationBankIdAsc = 'DEALS_VARIANCE_POPULATION_BANK_ID_ASC',
  DealsVariancePopulationBankIdDesc = 'DEALS_VARIANCE_POPULATION_BANK_ID_DESC',
  DealsVariancePopulationBankNameAsc = 'DEALS_VARIANCE_POPULATION_BANK_NAME_ASC',
  DealsVariancePopulationBankNameDesc = 'DEALS_VARIANCE_POPULATION_BANK_NAME_DESC',
  DealsVariancePopulationBankAddressAsc = 'DEALS_VARIANCE_POPULATION_BANK_ADDRESS_ASC',
  DealsVariancePopulationBankAddressDesc = 'DEALS_VARIANCE_POPULATION_BANK_ADDRESS_DESC',
  DealsVariancePopulationTermAsc = 'DEALS_VARIANCE_POPULATION_TERM_ASC',
  DealsVariancePopulationTermDesc = 'DEALS_VARIANCE_POPULATION_TERM_DESC',
  DealsVariancePopulationAmountFinancedAsc = 'DEALS_VARIANCE_POPULATION_AMOUNT_FINANCED_ASC',
  DealsVariancePopulationAmountFinancedDesc = 'DEALS_VARIANCE_POPULATION_AMOUNT_FINANCED_DESC',
  DealsVariancePopulationAprAsc = 'DEALS_VARIANCE_POPULATION_APR_ASC',
  DealsVariancePopulationAprDesc = 'DEALS_VARIANCE_POPULATION_APR_DESC',
  DealsVariancePopulationMonthlyPaymentAsc = 'DEALS_VARIANCE_POPULATION_MONTHLY_PAYMENT_ASC',
  DealsVariancePopulationMonthlyPaymentDesc = 'DEALS_VARIANCE_POPULATION_MONTHLY_PAYMENT_DESC',
  DealsVariancePopulationPaymentTotalAsc = 'DEALS_VARIANCE_POPULATION_PAYMENT_TOTAL_ASC',
  DealsVariancePopulationPaymentTotalDesc = 'DEALS_VARIANCE_POPULATION_PAYMENT_TOTAL_DESC',
  DealsVariancePopulationRebatesAsc = 'DEALS_VARIANCE_POPULATION_REBATES_ASC',
  DealsVariancePopulationRebatesDesc = 'DEALS_VARIANCE_POPULATION_REBATES_DESC',
  DealsVariancePopulationDepositAsc = 'DEALS_VARIANCE_POPULATION_DEPOSIT_ASC',
  DealsVariancePopulationDepositDesc = 'DEALS_VARIANCE_POPULATION_DEPOSIT_DESC',
  DealsVariancePopulationDownPaymentAsc = 'DEALS_VARIANCE_POPULATION_DOWN_PAYMENT_ASC',
  DealsVariancePopulationDownPaymentDesc = 'DEALS_VARIANCE_POPULATION_DOWN_PAYMENT_DESC',
  DealsVariancePopulationTotalNetTradesAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_NET_TRADES_ASC',
  DealsVariancePopulationTotalNetTradesDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_NET_TRADES_DESC',
  DealsVariancePopulationTotalDownAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_DOWN_ASC',
  DealsVariancePopulationTotalDownDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_DOWN_DESC',
  DealsVariancePopulationBalloonAmountAsc = 'DEALS_VARIANCE_POPULATION_BALLOON_AMOUNT_ASC',
  DealsVariancePopulationBalloonAmountDesc = 'DEALS_VARIANCE_POPULATION_BALLOON_AMOUNT_DESC',
  DealsVariancePopulationAdjustedBalloonAmountAsc = 'DEALS_VARIANCE_POPULATION_ADJUSTED_BALLOON_AMOUNT_ASC',
  DealsVariancePopulationAdjustedBalloonAmountDesc = 'DEALS_VARIANCE_POPULATION_ADJUSTED_BALLOON_AMOUNT_DESC',
  DealsVariancePopulationHoldbackAmountAsc = 'DEALS_VARIANCE_POPULATION_HOLDBACK_AMOUNT_ASC',
  DealsVariancePopulationHoldbackAmountDesc = 'DEALS_VARIANCE_POPULATION_HOLDBACK_AMOUNT_DESC',
  DealsVariancePopulationTotalDriveOffAmountAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_DRIVE_OFF_AMOUNT_ASC',
  DealsVariancePopulationTotalDriveOffAmountDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_DRIVE_OFF_AMOUNT_DESC',
  DealsVariancePopulationLicenseFeeAsc = 'DEALS_VARIANCE_POPULATION_LICENSE_FEE_ASC',
  DealsVariancePopulationLicenseFeeDesc = 'DEALS_VARIANCE_POPULATION_LICENSE_FEE_DESC',
  DealsVariancePopulationRegistrationFeeAsc = 'DEALS_VARIANCE_POPULATION_REGISTRATION_FEE_ASC',
  DealsVariancePopulationRegistrationFeeDesc = 'DEALS_VARIANCE_POPULATION_REGISTRATION_FEE_DESC',
  DealsVariancePopulationDocumentationFeeAsc = 'DEALS_VARIANCE_POPULATION_DOCUMENTATION_FEE_ASC',
  DealsVariancePopulationDocumentationFeeDesc = 'DEALS_VARIANCE_POPULATION_DOCUMENTATION_FEE_DESC',
  DealsVariancePopulationFinanceChargeAsc = 'DEALS_VARIANCE_POPULATION_FINANCE_CHARGE_ASC',
  DealsVariancePopulationFinanceChargeDesc = 'DEALS_VARIANCE_POPULATION_FINANCE_CHARGE_DESC',
  DealsVariancePopulationTotalPickupPaymentsAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_PICKUP_PAYMENTS_ASC',
  DealsVariancePopulationTotalPickupPaymentsDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_PICKUP_PAYMENTS_DESC',
  DealsVariancePopulationSellRateAsc = 'DEALS_VARIANCE_POPULATION_SELL_RATE_ASC',
  DealsVariancePopulationSellRateDesc = 'DEALS_VARIANCE_POPULATION_SELL_RATE_DESC',
  DealsVariancePopulationBuyRateAsc = 'DEALS_VARIANCE_POPULATION_BUY_RATE_ASC',
  DealsVariancePopulationBuyRateDesc = 'DEALS_VARIANCE_POPULATION_BUY_RATE_DESC',
  DealsVariancePopulationResidualRateAsc = 'DEALS_VARIANCE_POPULATION_RESIDUAL_RATE_ASC',
  DealsVariancePopulationResidualRateDesc = 'DEALS_VARIANCE_POPULATION_RESIDUAL_RATE_DESC',
  DealsVariancePopulationResidualAmountAsc = 'DEALS_VARIANCE_POPULATION_RESIDUAL_AMOUNT_ASC',
  DealsVariancePopulationResidualAmountDesc = 'DEALS_VARIANCE_POPULATION_RESIDUAL_AMOUNT_DESC',
  DealsVariancePopulationAllowedMilesAsc = 'DEALS_VARIANCE_POPULATION_ALLOWED_MILES_ASC',
  DealsVariancePopulationAllowedMilesDesc = 'DEALS_VARIANCE_POPULATION_ALLOWED_MILES_DESC',
  DealsVariancePopulationEstimatedMilesAsc = 'DEALS_VARIANCE_POPULATION_ESTIMATED_MILES_ASC',
  DealsVariancePopulationEstimatedMilesDesc = 'DEALS_VARIANCE_POPULATION_ESTIMATED_MILES_DESC',
  DealsVariancePopulationMileageRateAsc = 'DEALS_VARIANCE_POPULATION_MILEAGE_RATE_ASC',
  DealsVariancePopulationMileageRateDesc = 'DEALS_VARIANCE_POPULATION_MILEAGE_RATE_DESC',
  DealsVariancePopulationAcquisitionFeeAsc = 'DEALS_VARIANCE_POPULATION_ACQUISITION_FEE_ASC',
  DealsVariancePopulationAcquisitionFeeDesc = 'DEALS_VARIANCE_POPULATION_ACQUISITION_FEE_DESC',
  DealsVariancePopulationBasePaymentAsc = 'DEALS_VARIANCE_POPULATION_BASE_PAYMENT_ASC',
  DealsVariancePopulationBasePaymentDesc = 'DEALS_VARIANCE_POPULATION_BASE_PAYMENT_DESC',
  DealsVariancePopulationSecurityDepositAsc = 'DEALS_VARIANCE_POPULATION_SECURITY_DEPOSIT_ASC',
  DealsVariancePopulationSecurityDepositDesc = 'DEALS_VARIANCE_POPULATION_SECURITY_DEPOSIT_DESC',
  DealsVariancePopulationTotalCapitalReductionAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_CAPITAL_REDUCTION_ASC',
  DealsVariancePopulationTotalCapitalReductionDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_CAPITAL_REDUCTION_DESC',
  DealsVariancePopulationNetCapitalCostAsc = 'DEALS_VARIANCE_POPULATION_NET_CAPITAL_COST_ASC',
  DealsVariancePopulationNetCapitalCostDesc = 'DEALS_VARIANCE_POPULATION_NET_CAPITAL_COST_DESC',
  DealsVariancePopulationLeaseDepreciationValueAsc = 'DEALS_VARIANCE_POPULATION_LEASE_DEPRECIATION_VALUE_ASC',
  DealsVariancePopulationLeaseDepreciationValueDesc = 'DEALS_VARIANCE_POPULATION_LEASE_DEPRECIATION_VALUE_DESC',
  DealsVariancePopulationDealerFeesAsc = 'DEALS_VARIANCE_POPULATION_DEALER_FEES_ASC',
  DealsVariancePopulationDealerFeesDesc = 'DEALS_VARIANCE_POPULATION_DEALER_FEES_DESC',
  DealsVariancePopulationGovernmentFeesAsc = 'DEALS_VARIANCE_POPULATION_GOVERNMENT_FEES_ASC',
  DealsVariancePopulationGovernmentFeesDesc = 'DEALS_VARIANCE_POPULATION_GOVERNMENT_FEES_DESC',
  DealsVariancePopulationTotalTaxAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_TAX_ASC',
  DealsVariancePopulationTotalTaxDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_TAX_DESC',
  DealsVariancePopulationRegistrationStateAsc = 'DEALS_VARIANCE_POPULATION_REGISTRATION_STATE_ASC',
  DealsVariancePopulationRegistrationStateDesc = 'DEALS_VARIANCE_POPULATION_REGISTRATION_STATE_DESC',
  DealsVariancePopulationReportOfSaleNumberAsc = 'DEALS_VARIANCE_POPULATION_REPORT_OF_SALE_NUMBER_ASC',
  DealsVariancePopulationReportOfSaleNumberDesc = 'DEALS_VARIANCE_POPULATION_REPORT_OF_SALE_NUMBER_DESC',
  DealsVariancePopulationSalesman_1TotalCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_TOTAL_COMMISSION_ASC',
  DealsVariancePopulationSalesman_1TotalCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_TOTAL_COMMISSION_DESC',
  DealsVariancePopulationSalesman_1FrontCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_FRONT_COMMISSION_ASC',
  DealsVariancePopulationSalesman_1FrontCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_FRONT_COMMISSION_DESC',
  DealsVariancePopulationSalesman_1BackCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_BACK_COMMISSION_ASC',
  DealsVariancePopulationSalesman_1BackCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_1_BACK_COMMISSION_DESC',
  DealsVariancePopulationSalesman_2TotalCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_TOTAL_COMMISSION_ASC',
  DealsVariancePopulationSalesman_2TotalCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_TOTAL_COMMISSION_DESC',
  DealsVariancePopulationSalesman_2FrontCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_FRONT_COMMISSION_ASC',
  DealsVariancePopulationSalesman_2FrontCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_FRONT_COMMISSION_DESC',
  DealsVariancePopulationSalesman_2BackCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_BACK_COMMISSION_ASC',
  DealsVariancePopulationSalesman_2BackCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_2_BACK_COMMISSION_DESC',
  DealsVariancePopulationSalesman_3TotalCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_TOTAL_COMMISSION_ASC',
  DealsVariancePopulationSalesman_3TotalCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_TOTAL_COMMISSION_DESC',
  DealsVariancePopulationSalesman_3FrontCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_FRONT_COMMISSION_ASC',
  DealsVariancePopulationSalesman_3FrontCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_FRONT_COMMISSION_DESC',
  DealsVariancePopulationSalesman_3BackCommissionAsc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_BACK_COMMISSION_ASC',
  DealsVariancePopulationSalesman_3BackCommissionDesc = 'DEALS_VARIANCE_POPULATION_SALESMAN_3_BACK_COMMISSION_DESC',
  DealsVariancePopulationWarranty_1NameAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_NAME_ASC',
  DealsVariancePopulationWarranty_1NameDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_NAME_DESC',
  DealsVariancePopulationWarranty_1SaleAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_SALE_ASC',
  DealsVariancePopulationWarranty_1SaleDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_SALE_DESC',
  DealsVariancePopulationWarranty_1CostAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_COST_ASC',
  DealsVariancePopulationWarranty_1CostDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_COST_DESC',
  DealsVariancePopulationWarranty_1MilesAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_MILES_ASC',
  DealsVariancePopulationWarranty_1MilesDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_MILES_DESC',
  DealsVariancePopulationWarranty_1TermAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_TERM_ASC',
  DealsVariancePopulationWarranty_1TermDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_1_TERM_DESC',
  DealsVariancePopulationWarranty_2NameAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_NAME_ASC',
  DealsVariancePopulationWarranty_2NameDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_NAME_DESC',
  DealsVariancePopulationWarranty_2SaleAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_SALE_ASC',
  DealsVariancePopulationWarranty_2SaleDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_SALE_DESC',
  DealsVariancePopulationWarranty_2CostAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_COST_ASC',
  DealsVariancePopulationWarranty_2CostDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_COST_DESC',
  DealsVariancePopulationWarranty_2MilesAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_MILES_ASC',
  DealsVariancePopulationWarranty_2MilesDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_MILES_DESC',
  DealsVariancePopulationWarranty_2TermAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_TERM_ASC',
  DealsVariancePopulationWarranty_2TermDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_2_TERM_DESC',
  DealsVariancePopulationWarranty_3NameAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_NAME_ASC',
  DealsVariancePopulationWarranty_3NameDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_NAME_DESC',
  DealsVariancePopulationWarranty_3SaleAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_SALE_ASC',
  DealsVariancePopulationWarranty_3SaleDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_SALE_DESC',
  DealsVariancePopulationWarranty_3CostAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_COST_ASC',
  DealsVariancePopulationWarranty_3CostDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_COST_DESC',
  DealsVariancePopulationWarranty_3MilesAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_MILES_ASC',
  DealsVariancePopulationWarranty_3MilesDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_MILES_DESC',
  DealsVariancePopulationWarranty_3TermAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_TERM_ASC',
  DealsVariancePopulationWarranty_3TermDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_3_TERM_DESC',
  DealsVariancePopulationWarranty_4NameAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_NAME_ASC',
  DealsVariancePopulationWarranty_4NameDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_NAME_DESC',
  DealsVariancePopulationWarranty_4SaleAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_SALE_ASC',
  DealsVariancePopulationWarranty_4SaleDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_SALE_DESC',
  DealsVariancePopulationWarranty_4CostAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_COST_ASC',
  DealsVariancePopulationWarranty_4CostDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_COST_DESC',
  DealsVariancePopulationWarranty_4MilesAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_MILES_ASC',
  DealsVariancePopulationWarranty_4MilesDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_MILES_DESC',
  DealsVariancePopulationWarranty_4TermAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_TERM_ASC',
  DealsVariancePopulationWarranty_4TermDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_4_TERM_DESC',
  DealsVariancePopulationWarranty_5NameAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_NAME_ASC',
  DealsVariancePopulationWarranty_5NameDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_NAME_DESC',
  DealsVariancePopulationWarranty_5SaleAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_SALE_ASC',
  DealsVariancePopulationWarranty_5SaleDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_SALE_DESC',
  DealsVariancePopulationWarranty_5CostAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_COST_ASC',
  DealsVariancePopulationWarranty_5CostDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_COST_DESC',
  DealsVariancePopulationWarranty_5MilesAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_MILES_ASC',
  DealsVariancePopulationWarranty_5MilesDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_MILES_DESC',
  DealsVariancePopulationWarranty_5TermAsc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_TERM_ASC',
  DealsVariancePopulationWarranty_5TermDesc = 'DEALS_VARIANCE_POPULATION_WARRANTY_5_TERM_DESC',
  DealsVariancePopulationTotalFeeAftermarketSaleAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FEE_AFTERMARKET_SALE_ASC',
  DealsVariancePopulationTotalFeeAftermarketSaleDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FEE_AFTERMARKET_SALE_DESC',
  DealsVariancePopulationTotalFeeAftermarketCostAsc = 'DEALS_VARIANCE_POPULATION_TOTAL_FEE_AFTERMARKET_COST_ASC',
  DealsVariancePopulationTotalFeeAftermarketCostDesc = 'DEALS_VARIANCE_POPULATION_TOTAL_FEE_AFTERMARKET_COST_DESC',
  DealsVariancePopulationFeeAftermarket_1NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_1NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_1SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_1SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_1CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_COST_ASC',
  DealsVariancePopulationFeeAftermarket_1CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_COST_DESC',
  DealsVariancePopulationFeeAftermarket_1ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_1ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_1_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_2NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_2NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_2SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_2SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_2CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_COST_ASC',
  DealsVariancePopulationFeeAftermarket_2CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_COST_DESC',
  DealsVariancePopulationFeeAftermarket_2ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_2ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_2_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_3NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_3NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_3SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_3SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_3CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_COST_ASC',
  DealsVariancePopulationFeeAftermarket_3CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_COST_DESC',
  DealsVariancePopulationFeeAftermarket_3ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_3ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_3_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_4NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_4NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_4SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_4SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_4CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_COST_ASC',
  DealsVariancePopulationFeeAftermarket_4CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_COST_DESC',
  DealsVariancePopulationFeeAftermarket_4ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_4ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_4_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_5NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_5NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_5SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_5SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_5CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_COST_ASC',
  DealsVariancePopulationFeeAftermarket_5CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_COST_DESC',
  DealsVariancePopulationFeeAftermarket_5ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_5ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_5_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_6NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_6NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_6SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_6SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_6CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_COST_ASC',
  DealsVariancePopulationFeeAftermarket_6CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_COST_DESC',
  DealsVariancePopulationFeeAftermarket_6ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_6ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_6_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_7NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_7NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_7SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_7SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_7CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_COST_ASC',
  DealsVariancePopulationFeeAftermarket_7CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_COST_DESC',
  DealsVariancePopulationFeeAftermarket_7ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_7ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_7_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_8NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_8NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_8SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_8SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_8CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_COST_ASC',
  DealsVariancePopulationFeeAftermarket_8CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_COST_DESC',
  DealsVariancePopulationFeeAftermarket_8ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_8ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_8_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_9NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_9NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_9SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_9SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_9CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_COST_ASC',
  DealsVariancePopulationFeeAftermarket_9CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_COST_DESC',
  DealsVariancePopulationFeeAftermarket_9ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_9ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_9_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_10NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_10NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_10SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_10SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_10CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_COST_ASC',
  DealsVariancePopulationFeeAftermarket_10CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_COST_DESC',
  DealsVariancePopulationFeeAftermarket_10ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_10ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_10_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_11NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_11NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_11SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_11SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_11CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_COST_ASC',
  DealsVariancePopulationFeeAftermarket_11CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_COST_DESC',
  DealsVariancePopulationFeeAftermarket_11ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_11ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_11_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_12NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_12NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_12SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_12SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_12CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_COST_ASC',
  DealsVariancePopulationFeeAftermarket_12CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_COST_DESC',
  DealsVariancePopulationFeeAftermarket_12ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_12ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_12_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_13NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_13NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_13SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_13SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_13CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_COST_ASC',
  DealsVariancePopulationFeeAftermarket_13CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_COST_DESC',
  DealsVariancePopulationFeeAftermarket_13ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_13ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_13_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_14NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_14NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_14SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_14SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_14CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_COST_ASC',
  DealsVariancePopulationFeeAftermarket_14CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_COST_DESC',
  DealsVariancePopulationFeeAftermarket_14ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_14ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_14_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_15NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_15NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_15SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_15SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_15CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_COST_ASC',
  DealsVariancePopulationFeeAftermarket_15CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_COST_DESC',
  DealsVariancePopulationFeeAftermarket_15ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_15ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_15_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_16NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_16NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_16SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_16SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_16CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_COST_ASC',
  DealsVariancePopulationFeeAftermarket_16CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_COST_DESC',
  DealsVariancePopulationFeeAftermarket_16ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_16ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_16_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_17NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_17NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_17SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_17SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_17CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_COST_ASC',
  DealsVariancePopulationFeeAftermarket_17CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_COST_DESC',
  DealsVariancePopulationFeeAftermarket_17ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_17ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_17_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_18NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_18NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_18SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_18SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_18CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_COST_ASC',
  DealsVariancePopulationFeeAftermarket_18CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_COST_DESC',
  DealsVariancePopulationFeeAftermarket_18ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_18ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_18_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_19NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_19NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_19SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_19SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_19CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_COST_ASC',
  DealsVariancePopulationFeeAftermarket_19CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_COST_DESC',
  DealsVariancePopulationFeeAftermarket_19ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_19ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_19_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationFeeAftermarket_20NameAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_NAME_ASC',
  DealsVariancePopulationFeeAftermarket_20NameDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_NAME_DESC',
  DealsVariancePopulationFeeAftermarket_20SaleAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_SALE_ASC',
  DealsVariancePopulationFeeAftermarket_20SaleDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_SALE_DESC',
  DealsVariancePopulationFeeAftermarket_20CostAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_COST_ASC',
  DealsVariancePopulationFeeAftermarket_20CostDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_COST_DESC',
  DealsVariancePopulationFeeAftermarket_20ProfitIndicatorAsc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_PROFIT_INDICATOR_ASC',
  DealsVariancePopulationFeeAftermarket_20ProfitIndicatorDesc = 'DEALS_VARIANCE_POPULATION_FEE_AFTERMARKET_20_PROFIT_INDICATOR_DESC',
  DealsVariancePopulationInsurance_1TypeAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_TYPE_ASC',
  DealsVariancePopulationInsurance_1TypeDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_TYPE_DESC',
  DealsVariancePopulationInsurance_1NameAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_NAME_ASC',
  DealsVariancePopulationInsurance_1NameDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_NAME_DESC',
  DealsVariancePopulationInsurance_1SaleAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_SALE_ASC',
  DealsVariancePopulationInsurance_1SaleDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_SALE_DESC',
  DealsVariancePopulationInsurance_1CostAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_COST_ASC',
  DealsVariancePopulationInsurance_1CostDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_COST_DESC',
  DealsVariancePopulationInsurance_1TermAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_TERM_ASC',
  DealsVariancePopulationInsurance_1TermDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_1_TERM_DESC',
  DealsVariancePopulationInsurance_2TypeAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_TYPE_ASC',
  DealsVariancePopulationInsurance_2TypeDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_TYPE_DESC',
  DealsVariancePopulationInsurance_2NameAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_NAME_ASC',
  DealsVariancePopulationInsurance_2NameDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_NAME_DESC',
  DealsVariancePopulationInsurance_2SaleAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_SALE_ASC',
  DealsVariancePopulationInsurance_2SaleDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_SALE_DESC',
  DealsVariancePopulationInsurance_2CostAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_COST_ASC',
  DealsVariancePopulationInsurance_2CostDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_COST_DESC',
  DealsVariancePopulationInsurance_2TermAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_TERM_ASC',
  DealsVariancePopulationInsurance_2TermDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_2_TERM_DESC',
  DealsVariancePopulationInsurance_3TypeAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_TYPE_ASC',
  DealsVariancePopulationInsurance_3TypeDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_TYPE_DESC',
  DealsVariancePopulationInsurance_3NameAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_NAME_ASC',
  DealsVariancePopulationInsurance_3NameDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_NAME_DESC',
  DealsVariancePopulationInsurance_3SaleAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_SALE_ASC',
  DealsVariancePopulationInsurance_3SaleDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_SALE_DESC',
  DealsVariancePopulationInsurance_3CostAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_COST_ASC',
  DealsVariancePopulationInsurance_3CostDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_COST_DESC',
  DealsVariancePopulationInsurance_3TermAsc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_TERM_ASC',
  DealsVariancePopulationInsurance_3TermDesc = 'DEALS_VARIANCE_POPULATION_INSURANCE_3_TERM_DESC',
  DealsVariancePopulationAccidentalHealthTypeAsc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_TYPE_ASC',
  DealsVariancePopulationAccidentalHealthTypeDesc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_TYPE_DESC',
  DealsVariancePopulationAccidentalHealthNameAsc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_NAME_ASC',
  DealsVariancePopulationAccidentalHealthNameDesc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_NAME_DESC',
  DealsVariancePopulationAccidentalHealthSaleAsc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_SALE_ASC',
  DealsVariancePopulationAccidentalHealthSaleDesc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_SALE_DESC',
  DealsVariancePopulationAccidentalHealthCostAsc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_COST_ASC',
  DealsVariancePopulationAccidentalHealthCostDesc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_COST_DESC',
  DealsVariancePopulationAccidentalHealthTermAsc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_TERM_ASC',
  DealsVariancePopulationAccidentalHealthTermDesc = 'DEALS_VARIANCE_POPULATION_ACCIDENTAL_HEALTH_TERM_DESC',
  DealsVariancePopulationCreditLifeTypeAsc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_TYPE_ASC',
  DealsVariancePopulationCreditLifeTypeDesc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_TYPE_DESC',
  DealsVariancePopulationCreditLifeNameAsc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_NAME_ASC',
  DealsVariancePopulationCreditLifeNameDesc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_NAME_DESC',
  DealsVariancePopulationCreditLifeSaleAsc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_SALE_ASC',
  DealsVariancePopulationCreditLifeSaleDesc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_SALE_DESC',
  DealsVariancePopulationCreditLifeCostAsc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_COST_ASC',
  DealsVariancePopulationCreditLifeCostDesc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_COST_DESC',
  DealsVariancePopulationCreditLifeTermAsc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_TERM_ASC',
  DealsVariancePopulationCreditLifeTermDesc = 'DEALS_VARIANCE_POPULATION_CREDIT_LIFE_TERM_DESC',
  DealsVariancePopulationLevelizedLifeTypeAsc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_TYPE_ASC',
  DealsVariancePopulationLevelizedLifeTypeDesc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_TYPE_DESC',
  DealsVariancePopulationLevelizedLifeNameAsc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_NAME_ASC',
  DealsVariancePopulationLevelizedLifeNameDesc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_NAME_DESC',
  DealsVariancePopulationLevelizedLifeSaleAsc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_SALE_ASC',
  DealsVariancePopulationLevelizedLifeSaleDesc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_SALE_DESC',
  DealsVariancePopulationLevelizedLifeCostAsc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_COST_ASC',
  DealsVariancePopulationLevelizedLifeCostDesc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_COST_DESC',
  DealsVariancePopulationLevelizedLifeTermAsc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_TERM_ASC',
  DealsVariancePopulationLevelizedLifeTermDesc = 'DEALS_VARIANCE_POPULATION_LEVELIZED_LIFE_TERM_DESC',
  DealsVariancePopulationLossOfEmploymentTypeAsc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_TYPE_ASC',
  DealsVariancePopulationLossOfEmploymentTypeDesc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_TYPE_DESC',
  DealsVariancePopulationLossOfEmploymentNameAsc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_NAME_ASC',
  DealsVariancePopulationLossOfEmploymentNameDesc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_NAME_DESC',
  DealsVariancePopulationLossOfEmploymentSaleAsc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_SALE_ASC',
  DealsVariancePopulationLossOfEmploymentSaleDesc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_SALE_DESC',
  DealsVariancePopulationLossOfEmploymentCostAsc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_COST_ASC',
  DealsVariancePopulationLossOfEmploymentCostDesc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_COST_DESC',
  DealsVariancePopulationLossOfEmploymentTermAsc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_TERM_ASC',
  DealsVariancePopulationLossOfEmploymentTermDesc = 'DEALS_VARIANCE_POPULATION_LOSS_OF_EMPLOYMENT_TERM_DESC',
  DealsVariancePopulationGuaranteedAutoProtectionTypeAsc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_TYPE_ASC',
  DealsVariancePopulationGuaranteedAutoProtectionTypeDesc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_TYPE_DESC',
  DealsVariancePopulationGuaranteedAutoProtectionNameAsc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_NAME_ASC',
  DealsVariancePopulationGuaranteedAutoProtectionNameDesc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_NAME_DESC',
  DealsVariancePopulationGuaranteedAutoProtectionSaleAsc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_SALE_ASC',
  DealsVariancePopulationGuaranteedAutoProtectionSaleDesc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_SALE_DESC',
  DealsVariancePopulationGuaranteedAutoProtectionCostAsc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_COST_ASC',
  DealsVariancePopulationGuaranteedAutoProtectionCostDesc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_COST_DESC',
  DealsVariancePopulationGuaranteedAutoProtectionTermAsc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_TERM_ASC',
  DealsVariancePopulationGuaranteedAutoProtectionTermDesc = 'DEALS_VARIANCE_POPULATION_GUARANTEED_AUTO_PROTECTION_TERM_DESC',
  DealsVariancePopulationSaleCommentsAsc = 'DEALS_VARIANCE_POPULATION_SALE_COMMENTS_ASC',
  DealsVariancePopulationSaleCommentsDesc = 'DEALS_VARIANCE_POPULATION_SALE_COMMENTS_DESC',
  DealsVariancePopulationCassStdLine1Asc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LINE1_ASC',
  DealsVariancePopulationCassStdLine1Desc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LINE1_DESC',
  DealsVariancePopulationCassStdLine2Asc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LINE2_ASC',
  DealsVariancePopulationCassStdLine2Desc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LINE2_DESC',
  DealsVariancePopulationCassStdCityAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CITY_ASC',
  DealsVariancePopulationCassStdCityDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CITY_DESC',
  DealsVariancePopulationCassStdStateAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_STATE_ASC',
  DealsVariancePopulationCassStdStateDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_STATE_DESC',
  DealsVariancePopulationCassStdZipAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIP_ASC',
  DealsVariancePopulationCassStdZipDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIP_DESC',
  DealsVariancePopulationCassStdZip4Asc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIP4_ASC',
  DealsVariancePopulationCassStdZip4Desc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIP4_DESC',
  DealsVariancePopulationCassStdDpbcAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_DPBC_ASC',
  DealsVariancePopulationCassStdDpbcDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_DPBC_DESC',
  DealsVariancePopulationCassStdChkdgtAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CHKDGT_ASC',
  DealsVariancePopulationCassStdChkdgtDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CHKDGT_DESC',
  DealsVariancePopulationCassStdCartAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CART_ASC',
  DealsVariancePopulationCassStdCartDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_CART_DESC',
  DealsVariancePopulationCassStdLotAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LOT_ASC',
  DealsVariancePopulationCassStdLotDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LOT_DESC',
  DealsVariancePopulationCassStdLotordAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LOTORD_ASC',
  DealsVariancePopulationCassStdLotordDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LOTORD_DESC',
  DealsVariancePopulationCassStdUrbAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_URB_ASC',
  DealsVariancePopulationCassStdUrbDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_URB_DESC',
  DealsVariancePopulationCassStdFipsAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_FIPS_ASC',
  DealsVariancePopulationCassStdFipsDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_FIPS_DESC',
  DealsVariancePopulationCassStdEwsAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_EWS_ASC',
  DealsVariancePopulationCassStdEwsDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_EWS_DESC',
  DealsVariancePopulationCassStdLacsAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LACS_ASC',
  DealsVariancePopulationCassStdLacsDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LACS_DESC',
  DealsVariancePopulationCassStdZipmovAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIPMOV_ASC',
  DealsVariancePopulationCassStdZipmovDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ZIPMOV_DESC',
  DealsVariancePopulationCassStdZ4LomAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_Z4LOM_ASC',
  DealsVariancePopulationCassStdZ4LomDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_Z4LOM_DESC',
  DealsVariancePopulationCassStdNdiaptAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_NDIAPT_ASC',
  DealsVariancePopulationCassStdNdiaptDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_NDIAPT_DESC',
  DealsVariancePopulationCassStdNdirrAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_NDIRR_ASC',
  DealsVariancePopulationCassStdNdirrDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_NDIRR_DESC',
  DealsVariancePopulationCassStdLacsrtAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LACSRT_ASC',
  DealsVariancePopulationCassStdLacsrtDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_LACSRT_DESC',
  DealsVariancePopulationCassStdErrorCdAsc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ERROR_CD_ASC',
  DealsVariancePopulationCassStdErrorCdDesc = 'DEALS_VARIANCE_POPULATION_CASS_STD_ERROR_CD_DESC',
  EventsCountAsc = 'EVENTS_COUNT_ASC',
  EventsCountDesc = 'EVENTS_COUNT_DESC',
  EventsSumIdAsc = 'EVENTS_SUM_ID_ASC',
  EventsSumIdDesc = 'EVENTS_SUM_ID_DESC',
  EventsSumLeadIdAsc = 'EVENTS_SUM_LEAD_ID_ASC',
  EventsSumLeadIdDesc = 'EVENTS_SUM_LEAD_ID_DESC',
  EventsSumRequestIdAsc = 'EVENTS_SUM_REQUEST_ID_ASC',
  EventsSumRequestIdDesc = 'EVENTS_SUM_REQUEST_ID_DESC',
  EventsSumMetaAsc = 'EVENTS_SUM_META_ASC',
  EventsSumMetaDesc = 'EVENTS_SUM_META_DESC',
  EventsSumTypeAsc = 'EVENTS_SUM_TYPE_ASC',
  EventsSumTypeDesc = 'EVENTS_SUM_TYPE_DESC',
  EventsSumErrorAsc = 'EVENTS_SUM_ERROR_ASC',
  EventsSumErrorDesc = 'EVENTS_SUM_ERROR_DESC',
  EventsSumCreatedAtAsc = 'EVENTS_SUM_CREATED_AT_ASC',
  EventsSumCreatedAtDesc = 'EVENTS_SUM_CREATED_AT_DESC',
  EventsSumUpdatedAtAsc = 'EVENTS_SUM_UPDATED_AT_ASC',
  EventsSumUpdatedAtDesc = 'EVENTS_SUM_UPDATED_AT_DESC',
  EventsDistinctCountIdAsc = 'EVENTS_DISTINCT_COUNT_ID_ASC',
  EventsDistinctCountIdDesc = 'EVENTS_DISTINCT_COUNT_ID_DESC',
  EventsDistinctCountLeadIdAsc = 'EVENTS_DISTINCT_COUNT_LEAD_ID_ASC',
  EventsDistinctCountLeadIdDesc = 'EVENTS_DISTINCT_COUNT_LEAD_ID_DESC',
  EventsDistinctCountRequestIdAsc = 'EVENTS_DISTINCT_COUNT_REQUEST_ID_ASC',
  EventsDistinctCountRequestIdDesc = 'EVENTS_DISTINCT_COUNT_REQUEST_ID_DESC',
  EventsDistinctCountMetaAsc = 'EVENTS_DISTINCT_COUNT_META_ASC',
  EventsDistinctCountMetaDesc = 'EVENTS_DISTINCT_COUNT_META_DESC',
  EventsDistinctCountTypeAsc = 'EVENTS_DISTINCT_COUNT_TYPE_ASC',
  EventsDistinctCountTypeDesc = 'EVENTS_DISTINCT_COUNT_TYPE_DESC',
  EventsDistinctCountErrorAsc = 'EVENTS_DISTINCT_COUNT_ERROR_ASC',
  EventsDistinctCountErrorDesc = 'EVENTS_DISTINCT_COUNT_ERROR_DESC',
  EventsDistinctCountCreatedAtAsc = 'EVENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  EventsDistinctCountCreatedAtDesc = 'EVENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  EventsDistinctCountUpdatedAtAsc = 'EVENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  EventsDistinctCountUpdatedAtDesc = 'EVENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  EventsMinIdAsc = 'EVENTS_MIN_ID_ASC',
  EventsMinIdDesc = 'EVENTS_MIN_ID_DESC',
  EventsMinLeadIdAsc = 'EVENTS_MIN_LEAD_ID_ASC',
  EventsMinLeadIdDesc = 'EVENTS_MIN_LEAD_ID_DESC',
  EventsMinRequestIdAsc = 'EVENTS_MIN_REQUEST_ID_ASC',
  EventsMinRequestIdDesc = 'EVENTS_MIN_REQUEST_ID_DESC',
  EventsMinMetaAsc = 'EVENTS_MIN_META_ASC',
  EventsMinMetaDesc = 'EVENTS_MIN_META_DESC',
  EventsMinTypeAsc = 'EVENTS_MIN_TYPE_ASC',
  EventsMinTypeDesc = 'EVENTS_MIN_TYPE_DESC',
  EventsMinErrorAsc = 'EVENTS_MIN_ERROR_ASC',
  EventsMinErrorDesc = 'EVENTS_MIN_ERROR_DESC',
  EventsMinCreatedAtAsc = 'EVENTS_MIN_CREATED_AT_ASC',
  EventsMinCreatedAtDesc = 'EVENTS_MIN_CREATED_AT_DESC',
  EventsMinUpdatedAtAsc = 'EVENTS_MIN_UPDATED_AT_ASC',
  EventsMinUpdatedAtDesc = 'EVENTS_MIN_UPDATED_AT_DESC',
  EventsMaxIdAsc = 'EVENTS_MAX_ID_ASC',
  EventsMaxIdDesc = 'EVENTS_MAX_ID_DESC',
  EventsMaxLeadIdAsc = 'EVENTS_MAX_LEAD_ID_ASC',
  EventsMaxLeadIdDesc = 'EVENTS_MAX_LEAD_ID_DESC',
  EventsMaxRequestIdAsc = 'EVENTS_MAX_REQUEST_ID_ASC',
  EventsMaxRequestIdDesc = 'EVENTS_MAX_REQUEST_ID_DESC',
  EventsMaxMetaAsc = 'EVENTS_MAX_META_ASC',
  EventsMaxMetaDesc = 'EVENTS_MAX_META_DESC',
  EventsMaxTypeAsc = 'EVENTS_MAX_TYPE_ASC',
  EventsMaxTypeDesc = 'EVENTS_MAX_TYPE_DESC',
  EventsMaxErrorAsc = 'EVENTS_MAX_ERROR_ASC',
  EventsMaxErrorDesc = 'EVENTS_MAX_ERROR_DESC',
  EventsMaxCreatedAtAsc = 'EVENTS_MAX_CREATED_AT_ASC',
  EventsMaxCreatedAtDesc = 'EVENTS_MAX_CREATED_AT_DESC',
  EventsMaxUpdatedAtAsc = 'EVENTS_MAX_UPDATED_AT_ASC',
  EventsMaxUpdatedAtDesc = 'EVENTS_MAX_UPDATED_AT_DESC',
  EventsAverageIdAsc = 'EVENTS_AVERAGE_ID_ASC',
  EventsAverageIdDesc = 'EVENTS_AVERAGE_ID_DESC',
  EventsAverageLeadIdAsc = 'EVENTS_AVERAGE_LEAD_ID_ASC',
  EventsAverageLeadIdDesc = 'EVENTS_AVERAGE_LEAD_ID_DESC',
  EventsAverageRequestIdAsc = 'EVENTS_AVERAGE_REQUEST_ID_ASC',
  EventsAverageRequestIdDesc = 'EVENTS_AVERAGE_REQUEST_ID_DESC',
  EventsAverageMetaAsc = 'EVENTS_AVERAGE_META_ASC',
  EventsAverageMetaDesc = 'EVENTS_AVERAGE_META_DESC',
  EventsAverageTypeAsc = 'EVENTS_AVERAGE_TYPE_ASC',
  EventsAverageTypeDesc = 'EVENTS_AVERAGE_TYPE_DESC',
  EventsAverageErrorAsc = 'EVENTS_AVERAGE_ERROR_ASC',
  EventsAverageErrorDesc = 'EVENTS_AVERAGE_ERROR_DESC',
  EventsAverageCreatedAtAsc = 'EVENTS_AVERAGE_CREATED_AT_ASC',
  EventsAverageCreatedAtDesc = 'EVENTS_AVERAGE_CREATED_AT_DESC',
  EventsAverageUpdatedAtAsc = 'EVENTS_AVERAGE_UPDATED_AT_ASC',
  EventsAverageUpdatedAtDesc = 'EVENTS_AVERAGE_UPDATED_AT_DESC',
  EventsStddevSampleIdAsc = 'EVENTS_STDDEV_SAMPLE_ID_ASC',
  EventsStddevSampleIdDesc = 'EVENTS_STDDEV_SAMPLE_ID_DESC',
  EventsStddevSampleLeadIdAsc = 'EVENTS_STDDEV_SAMPLE_LEAD_ID_ASC',
  EventsStddevSampleLeadIdDesc = 'EVENTS_STDDEV_SAMPLE_LEAD_ID_DESC',
  EventsStddevSampleRequestIdAsc = 'EVENTS_STDDEV_SAMPLE_REQUEST_ID_ASC',
  EventsStddevSampleRequestIdDesc = 'EVENTS_STDDEV_SAMPLE_REQUEST_ID_DESC',
  EventsStddevSampleMetaAsc = 'EVENTS_STDDEV_SAMPLE_META_ASC',
  EventsStddevSampleMetaDesc = 'EVENTS_STDDEV_SAMPLE_META_DESC',
  EventsStddevSampleTypeAsc = 'EVENTS_STDDEV_SAMPLE_TYPE_ASC',
  EventsStddevSampleTypeDesc = 'EVENTS_STDDEV_SAMPLE_TYPE_DESC',
  EventsStddevSampleErrorAsc = 'EVENTS_STDDEV_SAMPLE_ERROR_ASC',
  EventsStddevSampleErrorDesc = 'EVENTS_STDDEV_SAMPLE_ERROR_DESC',
  EventsStddevSampleCreatedAtAsc = 'EVENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  EventsStddevSampleCreatedAtDesc = 'EVENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  EventsStddevSampleUpdatedAtAsc = 'EVENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  EventsStddevSampleUpdatedAtDesc = 'EVENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  EventsStddevPopulationIdAsc = 'EVENTS_STDDEV_POPULATION_ID_ASC',
  EventsStddevPopulationIdDesc = 'EVENTS_STDDEV_POPULATION_ID_DESC',
  EventsStddevPopulationLeadIdAsc = 'EVENTS_STDDEV_POPULATION_LEAD_ID_ASC',
  EventsStddevPopulationLeadIdDesc = 'EVENTS_STDDEV_POPULATION_LEAD_ID_DESC',
  EventsStddevPopulationRequestIdAsc = 'EVENTS_STDDEV_POPULATION_REQUEST_ID_ASC',
  EventsStddevPopulationRequestIdDesc = 'EVENTS_STDDEV_POPULATION_REQUEST_ID_DESC',
  EventsStddevPopulationMetaAsc = 'EVENTS_STDDEV_POPULATION_META_ASC',
  EventsStddevPopulationMetaDesc = 'EVENTS_STDDEV_POPULATION_META_DESC',
  EventsStddevPopulationTypeAsc = 'EVENTS_STDDEV_POPULATION_TYPE_ASC',
  EventsStddevPopulationTypeDesc = 'EVENTS_STDDEV_POPULATION_TYPE_DESC',
  EventsStddevPopulationErrorAsc = 'EVENTS_STDDEV_POPULATION_ERROR_ASC',
  EventsStddevPopulationErrorDesc = 'EVENTS_STDDEV_POPULATION_ERROR_DESC',
  EventsStddevPopulationCreatedAtAsc = 'EVENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  EventsStddevPopulationCreatedAtDesc = 'EVENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  EventsStddevPopulationUpdatedAtAsc = 'EVENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  EventsStddevPopulationUpdatedAtDesc = 'EVENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  EventsVarianceSampleIdAsc = 'EVENTS_VARIANCE_SAMPLE_ID_ASC',
  EventsVarianceSampleIdDesc = 'EVENTS_VARIANCE_SAMPLE_ID_DESC',
  EventsVarianceSampleLeadIdAsc = 'EVENTS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  EventsVarianceSampleLeadIdDesc = 'EVENTS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  EventsVarianceSampleRequestIdAsc = 'EVENTS_VARIANCE_SAMPLE_REQUEST_ID_ASC',
  EventsVarianceSampleRequestIdDesc = 'EVENTS_VARIANCE_SAMPLE_REQUEST_ID_DESC',
  EventsVarianceSampleMetaAsc = 'EVENTS_VARIANCE_SAMPLE_META_ASC',
  EventsVarianceSampleMetaDesc = 'EVENTS_VARIANCE_SAMPLE_META_DESC',
  EventsVarianceSampleTypeAsc = 'EVENTS_VARIANCE_SAMPLE_TYPE_ASC',
  EventsVarianceSampleTypeDesc = 'EVENTS_VARIANCE_SAMPLE_TYPE_DESC',
  EventsVarianceSampleErrorAsc = 'EVENTS_VARIANCE_SAMPLE_ERROR_ASC',
  EventsVarianceSampleErrorDesc = 'EVENTS_VARIANCE_SAMPLE_ERROR_DESC',
  EventsVarianceSampleCreatedAtAsc = 'EVENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EventsVarianceSampleCreatedAtDesc = 'EVENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EventsVarianceSampleUpdatedAtAsc = 'EVENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  EventsVarianceSampleUpdatedAtDesc = 'EVENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EventsVariancePopulationIdAsc = 'EVENTS_VARIANCE_POPULATION_ID_ASC',
  EventsVariancePopulationIdDesc = 'EVENTS_VARIANCE_POPULATION_ID_DESC',
  EventsVariancePopulationLeadIdAsc = 'EVENTS_VARIANCE_POPULATION_LEAD_ID_ASC',
  EventsVariancePopulationLeadIdDesc = 'EVENTS_VARIANCE_POPULATION_LEAD_ID_DESC',
  EventsVariancePopulationRequestIdAsc = 'EVENTS_VARIANCE_POPULATION_REQUEST_ID_ASC',
  EventsVariancePopulationRequestIdDesc = 'EVENTS_VARIANCE_POPULATION_REQUEST_ID_DESC',
  EventsVariancePopulationMetaAsc = 'EVENTS_VARIANCE_POPULATION_META_ASC',
  EventsVariancePopulationMetaDesc = 'EVENTS_VARIANCE_POPULATION_META_DESC',
  EventsVariancePopulationTypeAsc = 'EVENTS_VARIANCE_POPULATION_TYPE_ASC',
  EventsVariancePopulationTypeDesc = 'EVENTS_VARIANCE_POPULATION_TYPE_DESC',
  EventsVariancePopulationErrorAsc = 'EVENTS_VARIANCE_POPULATION_ERROR_ASC',
  EventsVariancePopulationErrorDesc = 'EVENTS_VARIANCE_POPULATION_ERROR_DESC',
  EventsVariancePopulationCreatedAtAsc = 'EVENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  EventsVariancePopulationCreatedAtDesc = 'EVENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  EventsVariancePopulationUpdatedAtAsc = 'EVENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  EventsVariancePopulationUpdatedAtDesc = 'EVENTS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type Lender = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  bio?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `EquifaxLender`. */
  equifaxLenders: EquifaxLendersConnection;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
  /** Reads and enables pagination through a set of `LenderProgram`. */
  lenderPrograms: LenderProgramsConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByLenderQuoteLenderIdAndLeadId: LenderLeadsByLenderQuoteLenderIdAndLeadIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderAppsByLenderQuoteLenderIdAndLenderAppId: LenderLenderAppsByLenderQuoteLenderIdAndLenderAppIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByLenderQuoteLenderIdAndCompanyIntegrationId: LenderCompanyIntegrationsByLenderQuoteLenderIdAndCompanyIntegrationIdManyToManyConnection;
};


export type LenderEquifaxLendersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EquifaxLendersOrderBy>>;
  condition?: Maybe<EquifaxLenderCondition>;
  filter?: Maybe<EquifaxLenderFilter>;
};


export type LenderLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};


export type LenderLenderProgramsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderProgramsOrderBy>>;
  condition?: Maybe<LenderProgramCondition>;
  filter?: Maybe<LenderProgramFilter>;
};


export type LenderLeadsByLenderQuoteLenderIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type LenderLenderAppsByLenderQuoteLenderIdAndLenderAppIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};


export type LenderCompanyIntegrationsByLenderQuoteLenderIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};

export type LenderAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LenderSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LenderDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LenderMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LenderMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LenderAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LenderStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LenderStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LenderVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LenderVariancePopulationAggregates>;
};

export type LenderApp = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome: Scalars['Int'];
  employmentStatus: Scalars['String'];
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  /** Reads a single `Lead` that is related to this `LenderApp`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `LenderApp`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByLenderQuoteLenderAppIdAndLeadId: LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lender`. */
  lendersByLenderQuoteLenderAppIdAndLenderId: LenderAppLendersByLenderQuoteLenderAppIdAndLenderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByLenderQuoteLenderAppIdAndCompanyIntegrationId: LenderAppCompanyIntegrationsByLenderQuoteLenderAppIdAndCompanyIntegrationIdManyToManyConnection;
};


export type LenderAppLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};


export type LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type LenderAppLendersByLenderQuoteLenderAppIdAndLenderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LendersOrderBy>>;
  condition?: Maybe<LenderCondition>;
  filter?: Maybe<LenderFilter>;
};


export type LenderAppCompanyIntegrationsByLenderQuoteLenderAppIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};

export type LenderAppAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LenderAppSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LenderAppDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LenderAppMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LenderAppMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LenderAppAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LenderAppStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LenderAppStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LenderAppVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LenderAppVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `LenderApp` object types. */
export type LenderAppAggregatesFilter = {
  /** A filter that must pass for the relevant `LenderApp` object to be included within the aggregate. */
  filter?: Maybe<LenderAppFilter>;
  /** Sum aggregate over matching `LenderApp` objects. */
  sum?: Maybe<LenderAppSumAggregateFilter>;
  /** Distinct count aggregate over matching `LenderApp` objects. */
  distinctCount?: Maybe<LenderAppDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `LenderApp` objects. */
  min?: Maybe<LenderAppMinAggregateFilter>;
  /** Maximum aggregate over matching `LenderApp` objects. */
  max?: Maybe<LenderAppMaxAggregateFilter>;
  /** Mean average aggregate over matching `LenderApp` objects. */
  average?: Maybe<LenderAppAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LenderApp` objects. */
  stddevSample?: Maybe<LenderAppStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `LenderApp` objects. */
  stddevPopulation?: Maybe<LenderAppStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LenderApp` objects. */
  varianceSample?: Maybe<LenderAppVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `LenderApp` objects. */
  variancePopulation?: Maybe<LenderAppVariancePopulationAggregateFilter>;
};

export type LenderAppAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inovatecApplicationId?: Maybe<BigFloatFilter>;
  yearlyIncome?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  preferredOfferId?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type LenderAppAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigFloat']>;
  /** Mean average of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Mean average of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyIntegration` values, with data from `LenderQuote`. */
export type LenderAppCompanyIntegrationsByLenderQuoteLenderAppIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<LenderAppCompanyIntegrationsByLenderQuoteLenderAppIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `LenderQuote`. */
export type LenderAppCompanyIntegrationsByLenderQuoteLenderAppIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `LenderQuote`. */
export type LenderAppCompanyIntegrationsByLenderQuoteLenderAppIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `LenderQuote`. */
export type LenderAppCompanyIntegrationsByLenderQuoteLenderAppIdAndCompanyIntegrationIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

/**
 * A condition to be used against `LenderApp` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LenderAppCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `inovatecApplicationNumber` field. */
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `inovatecApplicationId` field. */
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `yearlyIncome` field. */
  yearlyIncome?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `employmentStatus` field. */
  employmentStatus?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `monthlyHomePayment` field. */
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `preferredOfferId` field. */
  preferredOfferId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `offers` field. */
  offers?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `vehicleListingId` field. */
  vehicleListingId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maritalStatus` field. */
  maritalStatus?: Maybe<Scalars['String']>;
};

export type LenderAppDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  inovatecApplicationNumber?: Maybe<BigIntFilter>;
  inovatecApplicationId?: Maybe<BigIntFilter>;
  yearlyIncome?: Maybe<BigIntFilter>;
  employmentStatus?: Maybe<BigIntFilter>;
  monthlyHomePayment?: Maybe<BigIntFilter>;
  preferredOfferId?: Maybe<BigIntFilter>;
  offers?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  vehicleListingId?: Maybe<BigIntFilter>;
  maritalStatus?: Maybe<BigIntFilter>;
};

export type LenderAppDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of inovatecApplicationNumber across the matching connection */
  inovatecApplicationNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigInt']>;
  /** Distinct count of employmentStatus across the matching connection */
  employmentStatus?: Maybe<Scalars['BigInt']>;
  /** Distinct count of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of offers across the matching connection */
  offers?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maritalStatus across the matching connection */
  maritalStatus?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LenderApp` object types. All fields are combined with a logical ‘and.’ */
export type LenderAppFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `inovatecApplicationNumber` field. */
  inovatecApplicationNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `inovatecApplicationId` field. */
  inovatecApplicationId?: Maybe<IntFilter>;
  /** Filter by the object’s `yearlyIncome` field. */
  yearlyIncome?: Maybe<IntFilter>;
  /** Filter by the object’s `employmentStatus` field. */
  employmentStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `monthlyHomePayment` field. */
  monthlyHomePayment?: Maybe<IntFilter>;
  /** Filter by the object’s `preferredOfferId` field. */
  preferredOfferId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `vehicleListingId` field. */
  vehicleListingId?: Maybe<IntFilter>;
  /** Filter by the object’s `maritalStatus` field. */
  maritalStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `lenderQuotes` relation. */
  lenderQuotes?: Maybe<LenderAppToManyLenderQuoteFilter>;
  /** Some related `lenderQuotes` exist. */
  lenderQuotesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Filter by the object’s `vehicleListing` relation. */
  vehicleListing?: Maybe<VehicleListingFilter>;
  /** A related `vehicleListing` exists. */
  vehicleListingExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LenderAppFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LenderAppFilter>>;
  /** Negates the expression. */
  not?: Maybe<LenderAppFilter>;
};

/** Grouping methods for `LenderApp` for usage during aggregation. */
export enum LenderAppGroupBy {
  LeadId = 'LEAD_ID',
  InovatecApplicationNumber = 'INOVATEC_APPLICATION_NUMBER',
  InovatecApplicationId = 'INOVATEC_APPLICATION_ID',
  YearlyIncome = 'YEARLY_INCOME',
  EmploymentStatus = 'EMPLOYMENT_STATUS',
  MonthlyHomePayment = 'MONTHLY_HOME_PAYMENT',
  PreferredOfferId = 'PREFERRED_OFFER_ID',
  Offers = 'OFFERS',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  VehicleListingId = 'VEHICLE_LISTING_ID',
  MaritalStatus = 'MARITAL_STATUS'
}

export type LenderAppHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

/** Conditions for `LenderApp` aggregates. */
export type LenderAppHavingInput = {
  AND?: Maybe<Array<LenderAppHavingInput>>;
  OR?: Maybe<Array<LenderAppHavingInput>>;
  sum?: Maybe<LenderAppHavingSumInput>;
  distinctCount?: Maybe<LenderAppHavingDistinctCountInput>;
  min?: Maybe<LenderAppHavingMinInput>;
  max?: Maybe<LenderAppHavingMaxInput>;
  average?: Maybe<LenderAppHavingAverageInput>;
  stddevSample?: Maybe<LenderAppHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LenderAppHavingStddevPopulationInput>;
  varianceSample?: Maybe<LenderAppHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LenderAppHavingVariancePopulationInput>;
};

export type LenderAppHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

/** An input for mutations affecting `LenderApp` */
export type LenderAppInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome: Scalars['Int'];
  employmentStatus: Scalars['String'];
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `lead` in the `LenderAppInput` mutation. */
export type LenderAppLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnLenderAppForLenderAppLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderAppOnLenderAppForLenderAppLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<LenderAppLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `lenderApp` in the `LeadInput` mutation. */
export type LenderAppLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderApp` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderAppLenderAppPkeyConnect>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderAppNodeIdConnect>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderAppLenderAppPkeyDelete>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderAppNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderAppOnLenderAppForLenderAppLeadIdFkeyUsingLenderAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnLenderAppForLenderAppLeadIdFkeyNodeIdUpdate>>;
  /** A `LenderAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderAppLeadIdFkeyLenderAppCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type LenderAppLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** The `lenderApp` to be created by this mutation. */
export type LenderAppLeadIdFkeyLenderAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome: Scalars['Int'];
  employmentStatus: Scalars['String'];
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** A connection to a list of `Lead` values, with data from `LenderQuote`. */
export type LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `LenderQuote`. */
export type LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `LenderQuote`. */
export type LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `Lead` edge in the connection, with data from `LenderQuote`. */
export type LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

/** The fields on `lenderApp` to look up the row to connect. */
export type LenderAppLenderAppPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `lenderApp` to look up the row to delete. */
export type LenderAppLenderAppPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `Lender` values, with data from `LenderQuote`. */
export type LenderAppLendersByLenderQuoteLenderAppIdAndLenderIdManyToManyConnection = {
  /** A list of `Lender` objects. */
  nodes: Array<Lender>;
  /** A list of edges which contains the `Lender`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<LenderAppLendersByLenderQuoteLenderAppIdAndLenderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lender` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderAggregates>>;
};


/** A connection to a list of `Lender` values, with data from `LenderQuote`. */
export type LenderAppLendersByLenderQuoteLenderAppIdAndLenderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderGroupBy>;
  having?: Maybe<LenderHavingInput>;
};

/** A `Lender` edge in the connection, with data from `LenderQuote`. */
export type LenderAppLendersByLenderQuoteLenderAppIdAndLenderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lender` at the end of the edge. */
  node: Lender;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `Lender` edge in the connection, with data from `LenderQuote`. */
export type LenderAppLendersByLenderQuoteLenderAppIdAndLenderIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

export type LenderAppMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  inovatecApplicationId?: Maybe<IntFilter>;
  yearlyIncome?: Maybe<IntFilter>;
  monthlyHomePayment?: Maybe<IntFilter>;
  preferredOfferId?: Maybe<IntFilter>;
  vehicleListingId?: Maybe<IntFilter>;
};

export type LenderAppMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  /** Maximum of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['Int']>;
  /** Maximum of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  /** Maximum of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['Int']>;
  /** Maximum of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['Int']>;
};

export type LenderAppMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  inovatecApplicationId?: Maybe<IntFilter>;
  yearlyIncome?: Maybe<IntFilter>;
  monthlyHomePayment?: Maybe<IntFilter>;
  preferredOfferId?: Maybe<IntFilter>;
  vehicleListingId?: Maybe<IntFilter>;
};

export type LenderAppMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  /** Minimum of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['Int']>;
  /** Minimum of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  /** Minimum of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['Int']>;
  /** Minimum of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LenderAppNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lenderApp` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LenderAppNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lenderApp` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderAppOnLenderAppForLenderAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `lenderApp` to look up the row to update. */
export type LenderAppOnLenderAppForLenderAppLeadIdFkeyUsingLenderAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: UpdateLenderAppOnLenderAppForLenderAppLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderAppOnLenderAppForLenderAppVehicleListingIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `vehicleListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `vehicleListing` being updated. */
  patch: VehicleListingPatch;
};

/** The fields on `lenderApp` to look up the row to update. */
export type LenderAppOnLenderAppForLenderAppVehicleListingIdFkeyUsingLenderAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: UpdateLenderAppOnLenderAppForLenderAppVehicleListingIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderQuote` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: LenderQuotePatch;
};

/** The fields on `lenderApp` to look up the row to update. */
export type LenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyUsingLenderAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: UpdateLenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `LenderApp`. Fields that are set will be updated. */
export type LenderAppPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome?: Maybe<Scalars['Int']>;
  employmentStatus?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

export type LenderAppResult = {
  lenderAppId: Scalars['Int'];
  preferredOfferId?: Maybe<Scalars['Int']>;
  vehicleListing?: Maybe<VehicleListing>;
  offers?: Maybe<Array<Maybe<LenderOffer>>>;
};

export type LenderAppStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inovatecApplicationId?: Maybe<BigFloatFilter>;
  yearlyIncome?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  preferredOfferId?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type LenderAppStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type LenderAppStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inovatecApplicationId?: Maybe<BigFloatFilter>;
  yearlyIncome?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  preferredOfferId?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type LenderAppStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type LenderAppSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  inovatecApplicationId?: Maybe<BigIntFilter>;
  yearlyIncome?: Maybe<BigIntFilter>;
  monthlyHomePayment?: Maybe<BigIntFilter>;
  preferredOfferId?: Maybe<BigIntFilter>;
  vehicleListingId?: Maybe<BigIntFilter>;
};

export type LenderAppSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of inovatecApplicationId across the matching connection */
  inovatecApplicationId: Scalars['BigInt'];
  /** Sum of yearlyIncome across the matching connection */
  yearlyIncome: Scalars['BigInt'];
  /** Sum of monthlyHomePayment across the matching connection */
  monthlyHomePayment: Scalars['BigInt'];
  /** Sum of preferredOfferId across the matching connection */
  preferredOfferId: Scalars['BigInt'];
  /** Sum of vehicleListingId across the matching connection */
  vehicleListingId: Scalars['BigInt'];
};

/** A filter to be used against many `LenderQuote` object types. All fields are combined with a logical ‘and.’ */
export type LenderAppToManyLenderQuoteFilter = {
  /** Every related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderQuoteFilter>;
  /** Some related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderQuoteFilter>;
  /** No related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderQuoteFilter>;
  /** Aggregates across related `LenderQuote` match the filter criteria. */
  aggregates?: Maybe<LenderQuoteAggregatesFilter>;
};

export type LenderAppVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inovatecApplicationId?: Maybe<BigFloatFilter>;
  yearlyIncome?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  preferredOfferId?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type LenderAppVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigFloat']>;
  /** Population variance of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Population variance of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type LenderAppVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inovatecApplicationId?: Maybe<BigFloatFilter>;
  yearlyIncome?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  preferredOfferId?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type LenderAppVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

/** Input for the nested mutation of `vehicleListing` in the `LenderAppInput` mutation. */
export type LenderAppVehicleListingIdFkeyInput = {
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  connectById?: Maybe<VehicleListingVehicleListingsPkeyConnect>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<VehicleListingNodeIdConnect>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  deleteById?: Maybe<VehicleListingVehicleListingsPkeyDelete>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<VehicleListingNodeIdDelete>;
  /** The primary key(s) and patch data for `vehicleListing` for the far side of the relationship. */
  updateById?: Maybe<VehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyUsingVehicleListingsPkeyUpdate>;
  /** The primary key(s) and patch data for `vehicleListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderAppOnLenderAppForLenderAppVehicleListingIdFkeyNodeIdUpdate>;
  /** A `VehicleListingInput` object that will be created and connected to this object. */
  create?: Maybe<LenderAppVehicleListingIdFkeyVehicleListingCreateInput>;
};

/** Input for the nested mutation of `lenderApp` in the `VehicleListingInput` mutation. */
export type LenderAppVehicleListingIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderApp` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderAppLenderAppPkeyConnect>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderAppNodeIdConnect>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderAppLenderAppPkeyDelete>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderAppNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderAppOnLenderAppForLenderAppVehicleListingIdFkeyUsingLenderAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<VehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyNodeIdUpdate>>;
  /** A `LenderAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderAppVehicleListingIdFkeyLenderAppCreateInput>>;
};

/** The `lenderApp` to be created by this mutation. */
export type LenderAppVehicleListingIdFkeyLenderAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome: Scalars['Int'];
  employmentStatus: Scalars['String'];
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** The `vehicleListing` to be created by this mutation. */
export type LenderAppVehicleListingIdFkeyVehicleListingCreateInput = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

/** A connection to a list of `LenderApp` values. */
export type LenderAppsConnection = {
  /** A list of `LenderApp` objects. */
  nodes: Array<LenderApp>;
  /** A list of edges which contains the `LenderApp` and cursor to aid in pagination. */
  edges: Array<LenderAppsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LenderApp` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderAppAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderAppAggregates>>;
};


/** A connection to a list of `LenderApp` values. */
export type LenderAppsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderAppGroupBy>;
  having?: Maybe<LenderAppHavingInput>;
};

/** A `LenderApp` edge in the connection. */
export type LenderAppsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LenderApp` at the end of the edge. */
  node: LenderApp;
};

/** Methods to use when ordering `LenderApp`. */
export enum LenderAppsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  InovatecApplicationNumberAsc = 'INOVATEC_APPLICATION_NUMBER_ASC',
  InovatecApplicationNumberDesc = 'INOVATEC_APPLICATION_NUMBER_DESC',
  InovatecApplicationIdAsc = 'INOVATEC_APPLICATION_ID_ASC',
  InovatecApplicationIdDesc = 'INOVATEC_APPLICATION_ID_DESC',
  YearlyIncomeAsc = 'YEARLY_INCOME_ASC',
  YearlyIncomeDesc = 'YEARLY_INCOME_DESC',
  EmploymentStatusAsc = 'EMPLOYMENT_STATUS_ASC',
  EmploymentStatusDesc = 'EMPLOYMENT_STATUS_DESC',
  MonthlyHomePaymentAsc = 'MONTHLY_HOME_PAYMENT_ASC',
  MonthlyHomePaymentDesc = 'MONTHLY_HOME_PAYMENT_DESC',
  PreferredOfferIdAsc = 'PREFERRED_OFFER_ID_ASC',
  PreferredOfferIdDesc = 'PREFERRED_OFFER_ID_DESC',
  OffersAsc = 'OFFERS_ASC',
  OffersDesc = 'OFFERS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VehicleListingIdAsc = 'VEHICLE_LISTING_ID_ASC',
  VehicleListingIdDesc = 'VEHICLE_LISTING_ID_DESC',
  MaritalStatusAsc = 'MARITAL_STATUS_ASC',
  MaritalStatusDesc = 'MARITAL_STATUS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  LenderQuotesCountAsc = 'LENDER_QUOTES_COUNT_ASC',
  LenderQuotesCountDesc = 'LENDER_QUOTES_COUNT_DESC',
  LenderQuotesSumIdAsc = 'LENDER_QUOTES_SUM_ID_ASC',
  LenderQuotesSumIdDesc = 'LENDER_QUOTES_SUM_ID_DESC',
  LenderQuotesSumLeadIdAsc = 'LENDER_QUOTES_SUM_LEAD_ID_ASC',
  LenderQuotesSumLeadIdDesc = 'LENDER_QUOTES_SUM_LEAD_ID_DESC',
  LenderQuotesSumLenderAppIdAsc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_ASC',
  LenderQuotesSumLenderAppIdDesc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_DESC',
  LenderQuotesSumProviderAsc = 'LENDER_QUOTES_SUM_PROVIDER_ASC',
  LenderQuotesSumProviderDesc = 'LENDER_QUOTES_SUM_PROVIDER_DESC',
  LenderQuotesSumQuoteJsonAsc = 'LENDER_QUOTES_SUM_QUOTE_JSON_ASC',
  LenderQuotesSumQuoteJsonDesc = 'LENDER_QUOTES_SUM_QUOTE_JSON_DESC',
  LenderQuotesSumCreatedAtAsc = 'LENDER_QUOTES_SUM_CREATED_AT_ASC',
  LenderQuotesSumCreatedAtDesc = 'LENDER_QUOTES_SUM_CREATED_AT_DESC',
  LenderQuotesSumUpdatedAtAsc = 'LENDER_QUOTES_SUM_UPDATED_AT_ASC',
  LenderQuotesSumUpdatedAtDesc = 'LENDER_QUOTES_SUM_UPDATED_AT_DESC',
  LenderQuotesSumTypeAsc = 'LENDER_QUOTES_SUM_TYPE_ASC',
  LenderQuotesSumTypeDesc = 'LENDER_QUOTES_SUM_TYPE_DESC',
  LenderQuotesSumExpiredAsc = 'LENDER_QUOTES_SUM_EXPIRED_ASC',
  LenderQuotesSumExpiredDesc = 'LENDER_QUOTES_SUM_EXPIRED_DESC',
  LenderQuotesSumLenderIdAsc = 'LENDER_QUOTES_SUM_LENDER_ID_ASC',
  LenderQuotesSumLenderIdDesc = 'LENDER_QUOTES_SUM_LENDER_ID_DESC',
  LenderQuotesSumCompanyIntegrationIdAsc = 'LENDER_QUOTES_SUM_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesSumCompanyIntegrationIdDesc = 'LENDER_QUOTES_SUM_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesSumStipsAsc = 'LENDER_QUOTES_SUM_STIPS_ASC',
  LenderQuotesSumStipsDesc = 'LENDER_QUOTES_SUM_STIPS_DESC',
  LenderQuotesSumApplicationLinkAsc = 'LENDER_QUOTES_SUM_APPLICATION_LINK_ASC',
  LenderQuotesSumApplicationLinkDesc = 'LENDER_QUOTES_SUM_APPLICATION_LINK_DESC',
  LenderQuotesDistinctCountIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_ASC',
  LenderQuotesDistinctCountIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_DESC',
  LenderQuotesDistinctCountLeadIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_ASC',
  LenderQuotesDistinctCountLeadIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_DESC',
  LenderQuotesDistinctCountLenderAppIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_ASC',
  LenderQuotesDistinctCountLenderAppIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_DESC',
  LenderQuotesDistinctCountProviderAsc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_ASC',
  LenderQuotesDistinctCountProviderDesc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_DESC',
  LenderQuotesDistinctCountQuoteJsonAsc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_ASC',
  LenderQuotesDistinctCountQuoteJsonDesc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_DESC',
  LenderQuotesDistinctCountCreatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderQuotesDistinctCountCreatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderQuotesDistinctCountUpdatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderQuotesDistinctCountUpdatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderQuotesDistinctCountTypeAsc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_ASC',
  LenderQuotesDistinctCountTypeDesc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_DESC',
  LenderQuotesDistinctCountExpiredAsc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_ASC',
  LenderQuotesDistinctCountExpiredDesc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_DESC',
  LenderQuotesDistinctCountLenderIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_ID_ASC',
  LenderQuotesDistinctCountLenderIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_ID_DESC',
  LenderQuotesDistinctCountCompanyIntegrationIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesDistinctCountCompanyIntegrationIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesDistinctCountStipsAsc = 'LENDER_QUOTES_DISTINCT_COUNT_STIPS_ASC',
  LenderQuotesDistinctCountStipsDesc = 'LENDER_QUOTES_DISTINCT_COUNT_STIPS_DESC',
  LenderQuotesDistinctCountApplicationLinkAsc = 'LENDER_QUOTES_DISTINCT_COUNT_APPLICATION_LINK_ASC',
  LenderQuotesDistinctCountApplicationLinkDesc = 'LENDER_QUOTES_DISTINCT_COUNT_APPLICATION_LINK_DESC',
  LenderQuotesMinIdAsc = 'LENDER_QUOTES_MIN_ID_ASC',
  LenderQuotesMinIdDesc = 'LENDER_QUOTES_MIN_ID_DESC',
  LenderQuotesMinLeadIdAsc = 'LENDER_QUOTES_MIN_LEAD_ID_ASC',
  LenderQuotesMinLeadIdDesc = 'LENDER_QUOTES_MIN_LEAD_ID_DESC',
  LenderQuotesMinLenderAppIdAsc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_ASC',
  LenderQuotesMinLenderAppIdDesc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_DESC',
  LenderQuotesMinProviderAsc = 'LENDER_QUOTES_MIN_PROVIDER_ASC',
  LenderQuotesMinProviderDesc = 'LENDER_QUOTES_MIN_PROVIDER_DESC',
  LenderQuotesMinQuoteJsonAsc = 'LENDER_QUOTES_MIN_QUOTE_JSON_ASC',
  LenderQuotesMinQuoteJsonDesc = 'LENDER_QUOTES_MIN_QUOTE_JSON_DESC',
  LenderQuotesMinCreatedAtAsc = 'LENDER_QUOTES_MIN_CREATED_AT_ASC',
  LenderQuotesMinCreatedAtDesc = 'LENDER_QUOTES_MIN_CREATED_AT_DESC',
  LenderQuotesMinUpdatedAtAsc = 'LENDER_QUOTES_MIN_UPDATED_AT_ASC',
  LenderQuotesMinUpdatedAtDesc = 'LENDER_QUOTES_MIN_UPDATED_AT_DESC',
  LenderQuotesMinTypeAsc = 'LENDER_QUOTES_MIN_TYPE_ASC',
  LenderQuotesMinTypeDesc = 'LENDER_QUOTES_MIN_TYPE_DESC',
  LenderQuotesMinExpiredAsc = 'LENDER_QUOTES_MIN_EXPIRED_ASC',
  LenderQuotesMinExpiredDesc = 'LENDER_QUOTES_MIN_EXPIRED_DESC',
  LenderQuotesMinLenderIdAsc = 'LENDER_QUOTES_MIN_LENDER_ID_ASC',
  LenderQuotesMinLenderIdDesc = 'LENDER_QUOTES_MIN_LENDER_ID_DESC',
  LenderQuotesMinCompanyIntegrationIdAsc = 'LENDER_QUOTES_MIN_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesMinCompanyIntegrationIdDesc = 'LENDER_QUOTES_MIN_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesMinStipsAsc = 'LENDER_QUOTES_MIN_STIPS_ASC',
  LenderQuotesMinStipsDesc = 'LENDER_QUOTES_MIN_STIPS_DESC',
  LenderQuotesMinApplicationLinkAsc = 'LENDER_QUOTES_MIN_APPLICATION_LINK_ASC',
  LenderQuotesMinApplicationLinkDesc = 'LENDER_QUOTES_MIN_APPLICATION_LINK_DESC',
  LenderQuotesMaxIdAsc = 'LENDER_QUOTES_MAX_ID_ASC',
  LenderQuotesMaxIdDesc = 'LENDER_QUOTES_MAX_ID_DESC',
  LenderQuotesMaxLeadIdAsc = 'LENDER_QUOTES_MAX_LEAD_ID_ASC',
  LenderQuotesMaxLeadIdDesc = 'LENDER_QUOTES_MAX_LEAD_ID_DESC',
  LenderQuotesMaxLenderAppIdAsc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_ASC',
  LenderQuotesMaxLenderAppIdDesc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_DESC',
  LenderQuotesMaxProviderAsc = 'LENDER_QUOTES_MAX_PROVIDER_ASC',
  LenderQuotesMaxProviderDesc = 'LENDER_QUOTES_MAX_PROVIDER_DESC',
  LenderQuotesMaxQuoteJsonAsc = 'LENDER_QUOTES_MAX_QUOTE_JSON_ASC',
  LenderQuotesMaxQuoteJsonDesc = 'LENDER_QUOTES_MAX_QUOTE_JSON_DESC',
  LenderQuotesMaxCreatedAtAsc = 'LENDER_QUOTES_MAX_CREATED_AT_ASC',
  LenderQuotesMaxCreatedAtDesc = 'LENDER_QUOTES_MAX_CREATED_AT_DESC',
  LenderQuotesMaxUpdatedAtAsc = 'LENDER_QUOTES_MAX_UPDATED_AT_ASC',
  LenderQuotesMaxUpdatedAtDesc = 'LENDER_QUOTES_MAX_UPDATED_AT_DESC',
  LenderQuotesMaxTypeAsc = 'LENDER_QUOTES_MAX_TYPE_ASC',
  LenderQuotesMaxTypeDesc = 'LENDER_QUOTES_MAX_TYPE_DESC',
  LenderQuotesMaxExpiredAsc = 'LENDER_QUOTES_MAX_EXPIRED_ASC',
  LenderQuotesMaxExpiredDesc = 'LENDER_QUOTES_MAX_EXPIRED_DESC',
  LenderQuotesMaxLenderIdAsc = 'LENDER_QUOTES_MAX_LENDER_ID_ASC',
  LenderQuotesMaxLenderIdDesc = 'LENDER_QUOTES_MAX_LENDER_ID_DESC',
  LenderQuotesMaxCompanyIntegrationIdAsc = 'LENDER_QUOTES_MAX_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesMaxCompanyIntegrationIdDesc = 'LENDER_QUOTES_MAX_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesMaxStipsAsc = 'LENDER_QUOTES_MAX_STIPS_ASC',
  LenderQuotesMaxStipsDesc = 'LENDER_QUOTES_MAX_STIPS_DESC',
  LenderQuotesMaxApplicationLinkAsc = 'LENDER_QUOTES_MAX_APPLICATION_LINK_ASC',
  LenderQuotesMaxApplicationLinkDesc = 'LENDER_QUOTES_MAX_APPLICATION_LINK_DESC',
  LenderQuotesAverageIdAsc = 'LENDER_QUOTES_AVERAGE_ID_ASC',
  LenderQuotesAverageIdDesc = 'LENDER_QUOTES_AVERAGE_ID_DESC',
  LenderQuotesAverageLeadIdAsc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_ASC',
  LenderQuotesAverageLeadIdDesc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_DESC',
  LenderQuotesAverageLenderAppIdAsc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_ASC',
  LenderQuotesAverageLenderAppIdDesc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_DESC',
  LenderQuotesAverageProviderAsc = 'LENDER_QUOTES_AVERAGE_PROVIDER_ASC',
  LenderQuotesAverageProviderDesc = 'LENDER_QUOTES_AVERAGE_PROVIDER_DESC',
  LenderQuotesAverageQuoteJsonAsc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_ASC',
  LenderQuotesAverageQuoteJsonDesc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_DESC',
  LenderQuotesAverageCreatedAtAsc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_ASC',
  LenderQuotesAverageCreatedAtDesc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_DESC',
  LenderQuotesAverageUpdatedAtAsc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_ASC',
  LenderQuotesAverageUpdatedAtDesc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_DESC',
  LenderQuotesAverageTypeAsc = 'LENDER_QUOTES_AVERAGE_TYPE_ASC',
  LenderQuotesAverageTypeDesc = 'LENDER_QUOTES_AVERAGE_TYPE_DESC',
  LenderQuotesAverageExpiredAsc = 'LENDER_QUOTES_AVERAGE_EXPIRED_ASC',
  LenderQuotesAverageExpiredDesc = 'LENDER_QUOTES_AVERAGE_EXPIRED_DESC',
  LenderQuotesAverageLenderIdAsc = 'LENDER_QUOTES_AVERAGE_LENDER_ID_ASC',
  LenderQuotesAverageLenderIdDesc = 'LENDER_QUOTES_AVERAGE_LENDER_ID_DESC',
  LenderQuotesAverageCompanyIntegrationIdAsc = 'LENDER_QUOTES_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesAverageCompanyIntegrationIdDesc = 'LENDER_QUOTES_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesAverageStipsAsc = 'LENDER_QUOTES_AVERAGE_STIPS_ASC',
  LenderQuotesAverageStipsDesc = 'LENDER_QUOTES_AVERAGE_STIPS_DESC',
  LenderQuotesAverageApplicationLinkAsc = 'LENDER_QUOTES_AVERAGE_APPLICATION_LINK_ASC',
  LenderQuotesAverageApplicationLinkDesc = 'LENDER_QUOTES_AVERAGE_APPLICATION_LINK_DESC',
  LenderQuotesStddevSampleIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_ASC',
  LenderQuotesStddevSampleIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_DESC',
  LenderQuotesStddevSampleLeadIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_ASC',
  LenderQuotesStddevSampleLeadIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_DESC',
  LenderQuotesStddevSampleLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesStddevSampleLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesStddevSampleProviderAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_ASC',
  LenderQuotesStddevSampleProviderDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_DESC',
  LenderQuotesStddevSampleQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesStddevSampleQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesStddevSampleCreatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderQuotesStddevSampleCreatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderQuotesStddevSampleUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesStddevSampleUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesStddevSampleTypeAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_ASC',
  LenderQuotesStddevSampleTypeDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_DESC',
  LenderQuotesStddevSampleExpiredAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_ASC',
  LenderQuotesStddevSampleExpiredDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_DESC',
  LenderQuotesStddevSampleLenderIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_ID_ASC',
  LenderQuotesStddevSampleLenderIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_ID_DESC',
  LenderQuotesStddevSampleCompanyIntegrationIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesStddevSampleCompanyIntegrationIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesStddevSampleStipsAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_STIPS_ASC',
  LenderQuotesStddevSampleStipsDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_STIPS_DESC',
  LenderQuotesStddevSampleApplicationLinkAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_APPLICATION_LINK_ASC',
  LenderQuotesStddevSampleApplicationLinkDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_APPLICATION_LINK_DESC',
  LenderQuotesStddevPopulationIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_ASC',
  LenderQuotesStddevPopulationIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_DESC',
  LenderQuotesStddevPopulationLeadIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_ASC',
  LenderQuotesStddevPopulationLeadIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_DESC',
  LenderQuotesStddevPopulationLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesStddevPopulationLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesStddevPopulationProviderAsc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_ASC',
  LenderQuotesStddevPopulationProviderDesc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_DESC',
  LenderQuotesStddevPopulationQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesStddevPopulationQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesStddevPopulationCreatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderQuotesStddevPopulationCreatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderQuotesStddevPopulationUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderQuotesStddevPopulationUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderQuotesStddevPopulationTypeAsc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_ASC',
  LenderQuotesStddevPopulationTypeDesc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_DESC',
  LenderQuotesStddevPopulationExpiredAsc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_ASC',
  LenderQuotesStddevPopulationExpiredDesc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_DESC',
  LenderQuotesStddevPopulationLenderIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_ID_ASC',
  LenderQuotesStddevPopulationLenderIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_ID_DESC',
  LenderQuotesStddevPopulationCompanyIntegrationIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesStddevPopulationCompanyIntegrationIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesStddevPopulationStipsAsc = 'LENDER_QUOTES_STDDEV_POPULATION_STIPS_ASC',
  LenderQuotesStddevPopulationStipsDesc = 'LENDER_QUOTES_STDDEV_POPULATION_STIPS_DESC',
  LenderQuotesStddevPopulationApplicationLinkAsc = 'LENDER_QUOTES_STDDEV_POPULATION_APPLICATION_LINK_ASC',
  LenderQuotesStddevPopulationApplicationLinkDesc = 'LENDER_QUOTES_STDDEV_POPULATION_APPLICATION_LINK_DESC',
  LenderQuotesVarianceSampleIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_ASC',
  LenderQuotesVarianceSampleIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_DESC',
  LenderQuotesVarianceSampleLeadIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LenderQuotesVarianceSampleLeadIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LenderQuotesVarianceSampleLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesVarianceSampleLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesVarianceSampleProviderAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_ASC',
  LenderQuotesVarianceSampleProviderDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_DESC',
  LenderQuotesVarianceSampleQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesVarianceSampleQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesVarianceSampleCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderQuotesVarianceSampleCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderQuotesVarianceSampleUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesVarianceSampleUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesVarianceSampleTypeAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_ASC',
  LenderQuotesVarianceSampleTypeDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_DESC',
  LenderQuotesVarianceSampleExpiredAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_ASC',
  LenderQuotesVarianceSampleExpiredDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_DESC',
  LenderQuotesVarianceSampleLenderIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_ID_ASC',
  LenderQuotesVarianceSampleLenderIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_ID_DESC',
  LenderQuotesVarianceSampleCompanyIntegrationIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesVarianceSampleCompanyIntegrationIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesVarianceSampleStipsAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_STIPS_ASC',
  LenderQuotesVarianceSampleStipsDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_STIPS_DESC',
  LenderQuotesVarianceSampleApplicationLinkAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_APPLICATION_LINK_ASC',
  LenderQuotesVarianceSampleApplicationLinkDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_APPLICATION_LINK_DESC',
  LenderQuotesVariancePopulationIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_ASC',
  LenderQuotesVariancePopulationIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_DESC',
  LenderQuotesVariancePopulationLeadIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_ASC',
  LenderQuotesVariancePopulationLeadIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_DESC',
  LenderQuotesVariancePopulationLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesVariancePopulationLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesVariancePopulationProviderAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_ASC',
  LenderQuotesVariancePopulationProviderDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_DESC',
  LenderQuotesVariancePopulationQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesVariancePopulationQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesVariancePopulationCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderQuotesVariancePopulationCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderQuotesVariancePopulationUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderQuotesVariancePopulationUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderQuotesVariancePopulationTypeAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_ASC',
  LenderQuotesVariancePopulationTypeDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_DESC',
  LenderQuotesVariancePopulationExpiredAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_ASC',
  LenderQuotesVariancePopulationExpiredDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_DESC',
  LenderQuotesVariancePopulationLenderIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_ID_ASC',
  LenderQuotesVariancePopulationLenderIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_ID_DESC',
  LenderQuotesVariancePopulationCompanyIntegrationIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesVariancePopulationCompanyIntegrationIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesVariancePopulationStipsAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_STIPS_ASC',
  LenderQuotesVariancePopulationStipsDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_STIPS_DESC',
  LenderQuotesVariancePopulationApplicationLinkAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_APPLICATION_LINK_ASC',
  LenderQuotesVariancePopulationApplicationLinkDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_APPLICATION_LINK_DESC'
}

export type LenderAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyIntegration` values, with data from `LenderQuote`. */
export type LenderCompanyIntegrationsByLenderQuoteLenderIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<LenderCompanyIntegrationsByLenderQuoteLenderIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `LenderQuote`. */
export type LenderCompanyIntegrationsByLenderQuoteLenderIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `LenderQuote`. */
export type LenderCompanyIntegrationsByLenderQuoteLenderIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `LenderQuote`. */
export type LenderCompanyIntegrationsByLenderQuoteLenderIdAndCompanyIntegrationIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

/** A condition to be used against `Lender` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LenderCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `bio` field. */
  bio?: Maybe<Scalars['String']>;
};

export type LenderCreditKey = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  lenderProgramId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  minScore?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `LenderProgram` that is related to this `LenderCreditKey`. */
  lenderProgram?: Maybe<LenderProgram>;
  /** Reads and enables pagination through a set of `Rate`. */
  rates: RatesConnection;
  /** Reads and enables pagination through a set of `LenderProgramBonus`. */
  lenderProgramBonuses: LenderProgramBonusesConnection;
  /** Reads and enables pagination through a set of `MaxAmortization`. */
  maxAmortizations: MaxAmortizationsConnection;
};


export type LenderCreditKeyRatesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<RatesOrderBy>>;
  condition?: Maybe<RateCondition>;
  filter?: Maybe<RateFilter>;
};


export type LenderCreditKeyLenderProgramBonusesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderProgramBonusesOrderBy>>;
  condition?: Maybe<LenderProgramBonusCondition>;
  filter?: Maybe<LenderProgramBonusFilter>;
};


export type LenderCreditKeyMaxAmortizationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MaxAmortizationsOrderBy>>;
  condition?: Maybe<MaxAmortizationCondition>;
  filter?: Maybe<MaxAmortizationFilter>;
};

export type LenderCreditKeyAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LenderCreditKeySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LenderCreditKeyDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LenderCreditKeyMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LenderCreditKeyMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LenderCreditKeyAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LenderCreditKeyStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LenderCreditKeyStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LenderCreditKeyVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LenderCreditKeyVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `LenderCreditKey` object types. */
export type LenderCreditKeyAggregatesFilter = {
  /** A filter that must pass for the relevant `LenderCreditKey` object to be included within the aggregate. */
  filter?: Maybe<LenderCreditKeyFilter>;
  /** Sum aggregate over matching `LenderCreditKey` objects. */
  sum?: Maybe<LenderCreditKeySumAggregateFilter>;
  /** Distinct count aggregate over matching `LenderCreditKey` objects. */
  distinctCount?: Maybe<LenderCreditKeyDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `LenderCreditKey` objects. */
  min?: Maybe<LenderCreditKeyMinAggregateFilter>;
  /** Maximum aggregate over matching `LenderCreditKey` objects. */
  max?: Maybe<LenderCreditKeyMaxAggregateFilter>;
  /** Mean average aggregate over matching `LenderCreditKey` objects. */
  average?: Maybe<LenderCreditKeyAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LenderCreditKey` objects. */
  stddevSample?: Maybe<LenderCreditKeyStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `LenderCreditKey` objects. */
  stddevPopulation?: Maybe<LenderCreditKeyStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LenderCreditKey` objects. */
  varianceSample?: Maybe<LenderCreditKeyVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `LenderCreditKey` objects. */
  variancePopulation?: Maybe<LenderCreditKeyVariancePopulationAggregateFilter>;
};

export type LenderCreditKeyAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
  minScore?: Maybe<BigFloatFilter>;
  maxScore?: Maybe<BigFloatFilter>;
};

export type LenderCreditKeyAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of minScore across the matching connection */
  minScore?: Maybe<Scalars['BigFloat']>;
  /** Mean average of maxScore across the matching connection */
  maxScore?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LenderCreditKey` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LenderCreditKeyCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lenderProgramId` field. */
  lenderProgramId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `minScore` field. */
  minScore?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxScore` field. */
  maxScore?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type LenderCreditKeyDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderProgramId?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  displayName?: Maybe<BigIntFilter>;
  minScore?: Maybe<BigIntFilter>;
  maxScore?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type LenderCreditKeyDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minScore across the matching connection */
  minScore?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxScore across the matching connection */
  maxScore?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LenderCreditKey` object types. All fields are combined with a logical ‘and.’ */
export type LenderCreditKeyFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `lenderProgramId` field. */
  lenderProgramId?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `minScore` field. */
  minScore?: Maybe<IntFilter>;
  /** Filter by the object’s `maxScore` field. */
  maxScore?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `rates` relation. */
  rates?: Maybe<LenderCreditKeyToManyRateFilter>;
  /** Some related `rates` exist. */
  ratesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lenderProgramBonuses` relation. */
  lenderProgramBonuses?: Maybe<LenderCreditKeyToManyLenderProgramBonusFilter>;
  /** Some related `lenderProgramBonuses` exist. */
  lenderProgramBonusesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `maxAmortizations` relation. */
  maxAmortizations?: Maybe<LenderCreditKeyToManyMaxAmortizationFilter>;
  /** Some related `maxAmortizations` exist. */
  maxAmortizationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lenderProgram` relation. */
  lenderProgram?: Maybe<LenderProgramFilter>;
  /** A related `lenderProgram` exists. */
  lenderProgramExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LenderCreditKeyFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LenderCreditKeyFilter>>;
  /** Negates the expression. */
  not?: Maybe<LenderCreditKeyFilter>;
};

/** Grouping methods for `LenderCreditKey` for usage during aggregation. */
export enum LenderCreditKeyGroupBy {
  LenderProgramId = 'LENDER_PROGRAM_ID',
  Name = 'NAME',
  DisplayName = 'DISPLAY_NAME',
  MinScore = 'MIN_SCORE',
  MaxScore = 'MAX_SCORE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type LenderCreditKeyHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minScore?: Maybe<HavingIntFilter>;
  maxScore?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderCreditKeyHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minScore?: Maybe<HavingIntFilter>;
  maxScore?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `LenderCreditKey` aggregates. */
export type LenderCreditKeyHavingInput = {
  AND?: Maybe<Array<LenderCreditKeyHavingInput>>;
  OR?: Maybe<Array<LenderCreditKeyHavingInput>>;
  sum?: Maybe<LenderCreditKeyHavingSumInput>;
  distinctCount?: Maybe<LenderCreditKeyHavingDistinctCountInput>;
  min?: Maybe<LenderCreditKeyHavingMinInput>;
  max?: Maybe<LenderCreditKeyHavingMaxInput>;
  average?: Maybe<LenderCreditKeyHavingAverageInput>;
  stddevSample?: Maybe<LenderCreditKeyHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LenderCreditKeyHavingStddevPopulationInput>;
  varianceSample?: Maybe<LenderCreditKeyHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LenderCreditKeyHavingVariancePopulationInput>;
};

export type LenderCreditKeyHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minScore?: Maybe<HavingIntFilter>;
  maxScore?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderCreditKeyHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minScore?: Maybe<HavingIntFilter>;
  maxScore?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderCreditKeyHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minScore?: Maybe<HavingIntFilter>;
  maxScore?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderCreditKeyHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minScore?: Maybe<HavingIntFilter>;
  maxScore?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderCreditKeyHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minScore?: Maybe<HavingIntFilter>;
  maxScore?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderCreditKeyHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minScore?: Maybe<HavingIntFilter>;
  maxScore?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderCreditKeyHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minScore?: Maybe<HavingIntFilter>;
  maxScore?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `LenderCreditKey` */
export type LenderCreditKeyInput = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  minScore?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInput>;
  ratesUsingId?: Maybe<RateLenderCreditKeyIdFkeyInverseInput>;
  lenderProgramBonusesUsingId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInverseInput>;
  maxAmortizationsUsingId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInverseInput>;
};

/** The fields on `lenderCreditKey` to look up the row to connect. */
export type LenderCreditKeyLenderCreditKeyPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `lenderCreditKey` to look up the row to delete. */
export type LenderCreditKeyLenderCreditKeyPkeyDelete = {
  id: Scalars['Int'];
};

/** Input for the nested mutation of `lenderProgram` in the `LenderCreditKeyInput` mutation. */
export type LenderCreditKeyLenderProgramIdFkeyInput = {
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  connectById?: Maybe<LenderProgramLenderProgramPkeyConnect>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  connectByNodeId?: Maybe<LenderProgramNodeIdConnect>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  deleteById?: Maybe<LenderProgramLenderProgramPkeyDelete>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LenderProgramNodeIdDelete>;
  /** The primary key(s) and patch data for `lenderProgram` for the far side of the relationship. */
  updateById?: Maybe<LenderProgramOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyUsingLenderProgramPkeyUpdate>;
  /** The primary key(s) and patch data for `lenderProgram` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderCreditKeyOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyNodeIdUpdate>;
  /** A `LenderProgramInput` object that will be created and connected to this object. */
  create?: Maybe<LenderCreditKeyLenderProgramIdFkeyLenderProgramCreateInput>;
};

/** Input for the nested mutation of `lenderCreditKey` in the `LenderProgramInput` mutation. */
export type LenderCreditKeyLenderProgramIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderCreditKey` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderCreditKeyLenderCreditKeyPkeyConnect>>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderCreditKeyNodeIdConnect>>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderCreditKeyLenderCreditKeyPkeyDelete>>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderCreditKeyNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderCreditKey` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderCreditKeyOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyUsingLenderCreditKeyPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderCreditKey` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LenderProgramOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyNodeIdUpdate>>;
  /** A `LenderCreditKeyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderCreditKeyLenderProgramIdFkeyLenderCreditKeyCreateInput>>;
};

/** The `lenderCreditKey` to be created by this mutation. */
export type LenderCreditKeyLenderProgramIdFkeyLenderCreditKeyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  minScore?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInput>;
  ratesUsingId?: Maybe<RateLenderCreditKeyIdFkeyInverseInput>;
  lenderProgramBonusesUsingId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInverseInput>;
  maxAmortizationsUsingId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInverseInput>;
};

/** The `lenderProgram` to be created by this mutation. */
export type LenderCreditKeyLenderProgramIdFkeyLenderProgramCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  applicationRequirements: Array<Maybe<Scalars['String']>>;
  vehicleRequirements: Array<Maybe<Scalars['String']>>;
  minDate?: Maybe<Scalars['Datetime']>;
  maxDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderToLenderId?: Maybe<LenderProgramLenderIdFkeyInput>;
  lenderCreditKeysUsingId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInverseInput>;
  vehicleBookingsUsingId?: Maybe<VehicleBookingLenderProgramIdFkeyInverseInput>;
  filesUsingId?: Maybe<FileLenderProgramIdFkeyInverseInput>;
};

export type LenderCreditKeyMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderProgramId?: Maybe<IntFilter>;
  minScore?: Maybe<IntFilter>;
  maxScore?: Maybe<IntFilter>;
};

export type LenderCreditKeyMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['Int']>;
  /** Maximum of minScore across the matching connection */
  minScore?: Maybe<Scalars['Int']>;
  /** Maximum of maxScore across the matching connection */
  maxScore?: Maybe<Scalars['Int']>;
};

export type LenderCreditKeyMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderProgramId?: Maybe<IntFilter>;
  minScore?: Maybe<IntFilter>;
  maxScore?: Maybe<IntFilter>;
};

export type LenderCreditKeyMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['Int']>;
  /** Minimum of minScore across the matching connection */
  minScore?: Maybe<Scalars['Int']>;
  /** Minimum of maxScore across the matching connection */
  maxScore?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LenderCreditKeyNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lenderCreditKey` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LenderCreditKeyNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lenderCreditKey` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderCreditKeyOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderProgram` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderProgram` being updated. */
  patch: LenderProgramPatch;
};

/** The fields on `lenderCreditKey` to look up the row to update. */
export type LenderCreditKeyOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyUsingLenderCreditKeyPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
  patch: UpdateLenderCreditKeyOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderCreditKeyOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderProgramBonus` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderProgramBonus` being updated. */
  patch: LenderProgramBonusPatch;
};

/** The fields on `lenderCreditKey` to look up the row to update. */
export type LenderCreditKeyOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyUsingLenderCreditKeyPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
  patch: UpdateLenderCreditKeyOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderCreditKeyOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `maxAmortization` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `maxAmortization` being updated. */
  patch: MaxAmortizationPatch;
};

/** The fields on `lenderCreditKey` to look up the row to update. */
export type LenderCreditKeyOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyUsingLenderCreditKeyPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
  patch: UpdateLenderCreditKeyOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderCreditKeyOnRateForRateLenderCreditKeyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `rate` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `rate` being updated. */
  patch: RatePatch;
};

/** The fields on `lenderCreditKey` to look up the row to update. */
export type LenderCreditKeyOnRateForRateLenderCreditKeyIdFkeyUsingLenderCreditKeyPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
  patch: UpdateLenderCreditKeyOnRateForRateLenderCreditKeyIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `LenderCreditKey`. Fields that are set will be updated. */
export type LenderCreditKeyPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  minScore?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInput>;
  ratesUsingId?: Maybe<RateLenderCreditKeyIdFkeyInverseInput>;
  lenderProgramBonusesUsingId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInverseInput>;
  maxAmortizationsUsingId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInverseInput>;
};

export type LenderCreditKeyStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
  minScore?: Maybe<BigFloatFilter>;
  maxScore?: Maybe<BigFloatFilter>;
};

export type LenderCreditKeyStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of minScore across the matching connection */
  minScore?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of maxScore across the matching connection */
  maxScore?: Maybe<Scalars['BigFloat']>;
};

export type LenderCreditKeyStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
  minScore?: Maybe<BigFloatFilter>;
  maxScore?: Maybe<BigFloatFilter>;
};

export type LenderCreditKeyStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of minScore across the matching connection */
  minScore?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of maxScore across the matching connection */
  maxScore?: Maybe<Scalars['BigFloat']>;
};

export type LenderCreditKeySumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderProgramId?: Maybe<BigIntFilter>;
  minScore?: Maybe<BigIntFilter>;
  maxScore?: Maybe<BigIntFilter>;
};

export type LenderCreditKeySumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lenderProgramId across the matching connection */
  lenderProgramId: Scalars['BigInt'];
  /** Sum of minScore across the matching connection */
  minScore: Scalars['BigInt'];
  /** Sum of maxScore across the matching connection */
  maxScore: Scalars['BigInt'];
};

/** A filter to be used against many `LenderProgramBonus` object types. All fields are combined with a logical ‘and.’ */
export type LenderCreditKeyToManyLenderProgramBonusFilter = {
  /** Every related `LenderProgramBonus` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderProgramBonusFilter>;
  /** Some related `LenderProgramBonus` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderProgramBonusFilter>;
  /** No related `LenderProgramBonus` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderProgramBonusFilter>;
  /** Aggregates across related `LenderProgramBonus` match the filter criteria. */
  aggregates?: Maybe<LenderProgramBonusAggregatesFilter>;
};

/** A filter to be used against many `MaxAmortization` object types. All fields are combined with a logical ‘and.’ */
export type LenderCreditKeyToManyMaxAmortizationFilter = {
  /** Every related `MaxAmortization` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MaxAmortizationFilter>;
  /** Some related `MaxAmortization` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MaxAmortizationFilter>;
  /** No related `MaxAmortization` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MaxAmortizationFilter>;
  /** Aggregates across related `MaxAmortization` match the filter criteria. */
  aggregates?: Maybe<MaxAmortizationAggregatesFilter>;
};

/** A filter to be used against many `Rate` object types. All fields are combined with a logical ‘and.’ */
export type LenderCreditKeyToManyRateFilter = {
  /** Every related `Rate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<RateFilter>;
  /** Some related `Rate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<RateFilter>;
  /** No related `Rate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<RateFilter>;
  /** Aggregates across related `Rate` match the filter criteria. */
  aggregates?: Maybe<RateAggregatesFilter>;
};

export type LenderCreditKeyVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
  minScore?: Maybe<BigFloatFilter>;
  maxScore?: Maybe<BigFloatFilter>;
};

export type LenderCreditKeyVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of minScore across the matching connection */
  minScore?: Maybe<Scalars['BigFloat']>;
  /** Population variance of maxScore across the matching connection */
  maxScore?: Maybe<Scalars['BigFloat']>;
};

export type LenderCreditKeyVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
  minScore?: Maybe<BigFloatFilter>;
  maxScore?: Maybe<BigFloatFilter>;
};

export type LenderCreditKeyVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of minScore across the matching connection */
  minScore?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of maxScore across the matching connection */
  maxScore?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LenderCreditKey` values. */
export type LenderCreditKeysConnection = {
  /** A list of `LenderCreditKey` objects. */
  nodes: Array<LenderCreditKey>;
  /** A list of edges which contains the `LenderCreditKey` and cursor to aid in pagination. */
  edges: Array<LenderCreditKeysEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LenderCreditKey` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderCreditKeyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderCreditKeyAggregates>>;
};


/** A connection to a list of `LenderCreditKey` values. */
export type LenderCreditKeysConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderCreditKeyGroupBy>;
  having?: Maybe<LenderCreditKeyHavingInput>;
};

/** A `LenderCreditKey` edge in the connection. */
export type LenderCreditKeysEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LenderCreditKey` at the end of the edge. */
  node: LenderCreditKey;
};

/** Methods to use when ordering `LenderCreditKey`. */
export enum LenderCreditKeysOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LenderProgramIdAsc = 'LENDER_PROGRAM_ID_ASC',
  LenderProgramIdDesc = 'LENDER_PROGRAM_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  MinScoreAsc = 'MIN_SCORE_ASC',
  MinScoreDesc = 'MIN_SCORE_DESC',
  MaxScoreAsc = 'MAX_SCORE_ASC',
  MaxScoreDesc = 'MAX_SCORE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RatesCountAsc = 'RATES_COUNT_ASC',
  RatesCountDesc = 'RATES_COUNT_DESC',
  RatesSumIdAsc = 'RATES_SUM_ID_ASC',
  RatesSumIdDesc = 'RATES_SUM_ID_DESC',
  RatesSumLenderCreditKeyIdAsc = 'RATES_SUM_LENDER_CREDIT_KEY_ID_ASC',
  RatesSumLenderCreditKeyIdDesc = 'RATES_SUM_LENDER_CREDIT_KEY_ID_DESC',
  RatesSumMinYearAsc = 'RATES_SUM_MIN_YEAR_ASC',
  RatesSumMinYearDesc = 'RATES_SUM_MIN_YEAR_DESC',
  RatesSumMaxYearAsc = 'RATES_SUM_MAX_YEAR_ASC',
  RatesSumMaxYearDesc = 'RATES_SUM_MAX_YEAR_DESC',
  RatesSumMinTermAsc = 'RATES_SUM_MIN_TERM_ASC',
  RatesSumMinTermDesc = 'RATES_SUM_MIN_TERM_DESC',
  RatesSumMaxTermAsc = 'RATES_SUM_MAX_TERM_ASC',
  RatesSumMaxTermDesc = 'RATES_SUM_MAX_TERM_DESC',
  RatesSumMinPriceAsc = 'RATES_SUM_MIN_PRICE_ASC',
  RatesSumMinPriceDesc = 'RATES_SUM_MIN_PRICE_DESC',
  RatesSumMaxPriceAsc = 'RATES_SUM_MAX_PRICE_ASC',
  RatesSumMaxPriceDesc = 'RATES_SUM_MAX_PRICE_DESC',
  RatesSumRateAsc = 'RATES_SUM_RATE_ASC',
  RatesSumRateDesc = 'RATES_SUM_RATE_DESC',
  RatesSumUsedCarAdvanceRateAsc = 'RATES_SUM_USED_CAR_ADVANCE_RATE_ASC',
  RatesSumUsedCarAdvanceRateDesc = 'RATES_SUM_USED_CAR_ADVANCE_RATE_DESC',
  RatesSumNewCarAdvanceRateAsc = 'RATES_SUM_NEW_CAR_ADVANCE_RATE_ASC',
  RatesSumNewCarAdvanceRateDesc = 'RATES_SUM_NEW_CAR_ADVANCE_RATE_DESC',
  RatesSumAddOnAsc = 'RATES_SUM_ADD_ON_ASC',
  RatesSumAddOnDesc = 'RATES_SUM_ADD_ON_DESC',
  RatesSumLenderAdminFeeAsc = 'RATES_SUM_LENDER_ADMIN_FEE_ASC',
  RatesSumLenderAdminFeeDesc = 'RATES_SUM_LENDER_ADMIN_FEE_DESC',
  RatesSumCreatedAtAsc = 'RATES_SUM_CREATED_AT_ASC',
  RatesSumCreatedAtDesc = 'RATES_SUM_CREATED_AT_DESC',
  RatesSumUpdatedAtAsc = 'RATES_SUM_UPDATED_AT_ASC',
  RatesSumUpdatedAtDesc = 'RATES_SUM_UPDATED_AT_DESC',
  RatesDistinctCountIdAsc = 'RATES_DISTINCT_COUNT_ID_ASC',
  RatesDistinctCountIdDesc = 'RATES_DISTINCT_COUNT_ID_DESC',
  RatesDistinctCountLenderCreditKeyIdAsc = 'RATES_DISTINCT_COUNT_LENDER_CREDIT_KEY_ID_ASC',
  RatesDistinctCountLenderCreditKeyIdDesc = 'RATES_DISTINCT_COUNT_LENDER_CREDIT_KEY_ID_DESC',
  RatesDistinctCountMinYearAsc = 'RATES_DISTINCT_COUNT_MIN_YEAR_ASC',
  RatesDistinctCountMinYearDesc = 'RATES_DISTINCT_COUNT_MIN_YEAR_DESC',
  RatesDistinctCountMaxYearAsc = 'RATES_DISTINCT_COUNT_MAX_YEAR_ASC',
  RatesDistinctCountMaxYearDesc = 'RATES_DISTINCT_COUNT_MAX_YEAR_DESC',
  RatesDistinctCountMinTermAsc = 'RATES_DISTINCT_COUNT_MIN_TERM_ASC',
  RatesDistinctCountMinTermDesc = 'RATES_DISTINCT_COUNT_MIN_TERM_DESC',
  RatesDistinctCountMaxTermAsc = 'RATES_DISTINCT_COUNT_MAX_TERM_ASC',
  RatesDistinctCountMaxTermDesc = 'RATES_DISTINCT_COUNT_MAX_TERM_DESC',
  RatesDistinctCountMinPriceAsc = 'RATES_DISTINCT_COUNT_MIN_PRICE_ASC',
  RatesDistinctCountMinPriceDesc = 'RATES_DISTINCT_COUNT_MIN_PRICE_DESC',
  RatesDistinctCountMaxPriceAsc = 'RATES_DISTINCT_COUNT_MAX_PRICE_ASC',
  RatesDistinctCountMaxPriceDesc = 'RATES_DISTINCT_COUNT_MAX_PRICE_DESC',
  RatesDistinctCountRateAsc = 'RATES_DISTINCT_COUNT_RATE_ASC',
  RatesDistinctCountRateDesc = 'RATES_DISTINCT_COUNT_RATE_DESC',
  RatesDistinctCountUsedCarAdvanceRateAsc = 'RATES_DISTINCT_COUNT_USED_CAR_ADVANCE_RATE_ASC',
  RatesDistinctCountUsedCarAdvanceRateDesc = 'RATES_DISTINCT_COUNT_USED_CAR_ADVANCE_RATE_DESC',
  RatesDistinctCountNewCarAdvanceRateAsc = 'RATES_DISTINCT_COUNT_NEW_CAR_ADVANCE_RATE_ASC',
  RatesDistinctCountNewCarAdvanceRateDesc = 'RATES_DISTINCT_COUNT_NEW_CAR_ADVANCE_RATE_DESC',
  RatesDistinctCountAddOnAsc = 'RATES_DISTINCT_COUNT_ADD_ON_ASC',
  RatesDistinctCountAddOnDesc = 'RATES_DISTINCT_COUNT_ADD_ON_DESC',
  RatesDistinctCountLenderAdminFeeAsc = 'RATES_DISTINCT_COUNT_LENDER_ADMIN_FEE_ASC',
  RatesDistinctCountLenderAdminFeeDesc = 'RATES_DISTINCT_COUNT_LENDER_ADMIN_FEE_DESC',
  RatesDistinctCountCreatedAtAsc = 'RATES_DISTINCT_COUNT_CREATED_AT_ASC',
  RatesDistinctCountCreatedAtDesc = 'RATES_DISTINCT_COUNT_CREATED_AT_DESC',
  RatesDistinctCountUpdatedAtAsc = 'RATES_DISTINCT_COUNT_UPDATED_AT_ASC',
  RatesDistinctCountUpdatedAtDesc = 'RATES_DISTINCT_COUNT_UPDATED_AT_DESC',
  RatesMinIdAsc = 'RATES_MIN_ID_ASC',
  RatesMinIdDesc = 'RATES_MIN_ID_DESC',
  RatesMinLenderCreditKeyIdAsc = 'RATES_MIN_LENDER_CREDIT_KEY_ID_ASC',
  RatesMinLenderCreditKeyIdDesc = 'RATES_MIN_LENDER_CREDIT_KEY_ID_DESC',
  RatesMinMinYearAsc = 'RATES_MIN_MIN_YEAR_ASC',
  RatesMinMinYearDesc = 'RATES_MIN_MIN_YEAR_DESC',
  RatesMinMaxYearAsc = 'RATES_MIN_MAX_YEAR_ASC',
  RatesMinMaxYearDesc = 'RATES_MIN_MAX_YEAR_DESC',
  RatesMinMinTermAsc = 'RATES_MIN_MIN_TERM_ASC',
  RatesMinMinTermDesc = 'RATES_MIN_MIN_TERM_DESC',
  RatesMinMaxTermAsc = 'RATES_MIN_MAX_TERM_ASC',
  RatesMinMaxTermDesc = 'RATES_MIN_MAX_TERM_DESC',
  RatesMinMinPriceAsc = 'RATES_MIN_MIN_PRICE_ASC',
  RatesMinMinPriceDesc = 'RATES_MIN_MIN_PRICE_DESC',
  RatesMinMaxPriceAsc = 'RATES_MIN_MAX_PRICE_ASC',
  RatesMinMaxPriceDesc = 'RATES_MIN_MAX_PRICE_DESC',
  RatesMinRateAsc = 'RATES_MIN_RATE_ASC',
  RatesMinRateDesc = 'RATES_MIN_RATE_DESC',
  RatesMinUsedCarAdvanceRateAsc = 'RATES_MIN_USED_CAR_ADVANCE_RATE_ASC',
  RatesMinUsedCarAdvanceRateDesc = 'RATES_MIN_USED_CAR_ADVANCE_RATE_DESC',
  RatesMinNewCarAdvanceRateAsc = 'RATES_MIN_NEW_CAR_ADVANCE_RATE_ASC',
  RatesMinNewCarAdvanceRateDesc = 'RATES_MIN_NEW_CAR_ADVANCE_RATE_DESC',
  RatesMinAddOnAsc = 'RATES_MIN_ADD_ON_ASC',
  RatesMinAddOnDesc = 'RATES_MIN_ADD_ON_DESC',
  RatesMinLenderAdminFeeAsc = 'RATES_MIN_LENDER_ADMIN_FEE_ASC',
  RatesMinLenderAdminFeeDesc = 'RATES_MIN_LENDER_ADMIN_FEE_DESC',
  RatesMinCreatedAtAsc = 'RATES_MIN_CREATED_AT_ASC',
  RatesMinCreatedAtDesc = 'RATES_MIN_CREATED_AT_DESC',
  RatesMinUpdatedAtAsc = 'RATES_MIN_UPDATED_AT_ASC',
  RatesMinUpdatedAtDesc = 'RATES_MIN_UPDATED_AT_DESC',
  RatesMaxIdAsc = 'RATES_MAX_ID_ASC',
  RatesMaxIdDesc = 'RATES_MAX_ID_DESC',
  RatesMaxLenderCreditKeyIdAsc = 'RATES_MAX_LENDER_CREDIT_KEY_ID_ASC',
  RatesMaxLenderCreditKeyIdDesc = 'RATES_MAX_LENDER_CREDIT_KEY_ID_DESC',
  RatesMaxMinYearAsc = 'RATES_MAX_MIN_YEAR_ASC',
  RatesMaxMinYearDesc = 'RATES_MAX_MIN_YEAR_DESC',
  RatesMaxMaxYearAsc = 'RATES_MAX_MAX_YEAR_ASC',
  RatesMaxMaxYearDesc = 'RATES_MAX_MAX_YEAR_DESC',
  RatesMaxMinTermAsc = 'RATES_MAX_MIN_TERM_ASC',
  RatesMaxMinTermDesc = 'RATES_MAX_MIN_TERM_DESC',
  RatesMaxMaxTermAsc = 'RATES_MAX_MAX_TERM_ASC',
  RatesMaxMaxTermDesc = 'RATES_MAX_MAX_TERM_DESC',
  RatesMaxMinPriceAsc = 'RATES_MAX_MIN_PRICE_ASC',
  RatesMaxMinPriceDesc = 'RATES_MAX_MIN_PRICE_DESC',
  RatesMaxMaxPriceAsc = 'RATES_MAX_MAX_PRICE_ASC',
  RatesMaxMaxPriceDesc = 'RATES_MAX_MAX_PRICE_DESC',
  RatesMaxRateAsc = 'RATES_MAX_RATE_ASC',
  RatesMaxRateDesc = 'RATES_MAX_RATE_DESC',
  RatesMaxUsedCarAdvanceRateAsc = 'RATES_MAX_USED_CAR_ADVANCE_RATE_ASC',
  RatesMaxUsedCarAdvanceRateDesc = 'RATES_MAX_USED_CAR_ADVANCE_RATE_DESC',
  RatesMaxNewCarAdvanceRateAsc = 'RATES_MAX_NEW_CAR_ADVANCE_RATE_ASC',
  RatesMaxNewCarAdvanceRateDesc = 'RATES_MAX_NEW_CAR_ADVANCE_RATE_DESC',
  RatesMaxAddOnAsc = 'RATES_MAX_ADD_ON_ASC',
  RatesMaxAddOnDesc = 'RATES_MAX_ADD_ON_DESC',
  RatesMaxLenderAdminFeeAsc = 'RATES_MAX_LENDER_ADMIN_FEE_ASC',
  RatesMaxLenderAdminFeeDesc = 'RATES_MAX_LENDER_ADMIN_FEE_DESC',
  RatesMaxCreatedAtAsc = 'RATES_MAX_CREATED_AT_ASC',
  RatesMaxCreatedAtDesc = 'RATES_MAX_CREATED_AT_DESC',
  RatesMaxUpdatedAtAsc = 'RATES_MAX_UPDATED_AT_ASC',
  RatesMaxUpdatedAtDesc = 'RATES_MAX_UPDATED_AT_DESC',
  RatesAverageIdAsc = 'RATES_AVERAGE_ID_ASC',
  RatesAverageIdDesc = 'RATES_AVERAGE_ID_DESC',
  RatesAverageLenderCreditKeyIdAsc = 'RATES_AVERAGE_LENDER_CREDIT_KEY_ID_ASC',
  RatesAverageLenderCreditKeyIdDesc = 'RATES_AVERAGE_LENDER_CREDIT_KEY_ID_DESC',
  RatesAverageMinYearAsc = 'RATES_AVERAGE_MIN_YEAR_ASC',
  RatesAverageMinYearDesc = 'RATES_AVERAGE_MIN_YEAR_DESC',
  RatesAverageMaxYearAsc = 'RATES_AVERAGE_MAX_YEAR_ASC',
  RatesAverageMaxYearDesc = 'RATES_AVERAGE_MAX_YEAR_DESC',
  RatesAverageMinTermAsc = 'RATES_AVERAGE_MIN_TERM_ASC',
  RatesAverageMinTermDesc = 'RATES_AVERAGE_MIN_TERM_DESC',
  RatesAverageMaxTermAsc = 'RATES_AVERAGE_MAX_TERM_ASC',
  RatesAverageMaxTermDesc = 'RATES_AVERAGE_MAX_TERM_DESC',
  RatesAverageMinPriceAsc = 'RATES_AVERAGE_MIN_PRICE_ASC',
  RatesAverageMinPriceDesc = 'RATES_AVERAGE_MIN_PRICE_DESC',
  RatesAverageMaxPriceAsc = 'RATES_AVERAGE_MAX_PRICE_ASC',
  RatesAverageMaxPriceDesc = 'RATES_AVERAGE_MAX_PRICE_DESC',
  RatesAverageRateAsc = 'RATES_AVERAGE_RATE_ASC',
  RatesAverageRateDesc = 'RATES_AVERAGE_RATE_DESC',
  RatesAverageUsedCarAdvanceRateAsc = 'RATES_AVERAGE_USED_CAR_ADVANCE_RATE_ASC',
  RatesAverageUsedCarAdvanceRateDesc = 'RATES_AVERAGE_USED_CAR_ADVANCE_RATE_DESC',
  RatesAverageNewCarAdvanceRateAsc = 'RATES_AVERAGE_NEW_CAR_ADVANCE_RATE_ASC',
  RatesAverageNewCarAdvanceRateDesc = 'RATES_AVERAGE_NEW_CAR_ADVANCE_RATE_DESC',
  RatesAverageAddOnAsc = 'RATES_AVERAGE_ADD_ON_ASC',
  RatesAverageAddOnDesc = 'RATES_AVERAGE_ADD_ON_DESC',
  RatesAverageLenderAdminFeeAsc = 'RATES_AVERAGE_LENDER_ADMIN_FEE_ASC',
  RatesAverageLenderAdminFeeDesc = 'RATES_AVERAGE_LENDER_ADMIN_FEE_DESC',
  RatesAverageCreatedAtAsc = 'RATES_AVERAGE_CREATED_AT_ASC',
  RatesAverageCreatedAtDesc = 'RATES_AVERAGE_CREATED_AT_DESC',
  RatesAverageUpdatedAtAsc = 'RATES_AVERAGE_UPDATED_AT_ASC',
  RatesAverageUpdatedAtDesc = 'RATES_AVERAGE_UPDATED_AT_DESC',
  RatesStddevSampleIdAsc = 'RATES_STDDEV_SAMPLE_ID_ASC',
  RatesStddevSampleIdDesc = 'RATES_STDDEV_SAMPLE_ID_DESC',
  RatesStddevSampleLenderCreditKeyIdAsc = 'RATES_STDDEV_SAMPLE_LENDER_CREDIT_KEY_ID_ASC',
  RatesStddevSampleLenderCreditKeyIdDesc = 'RATES_STDDEV_SAMPLE_LENDER_CREDIT_KEY_ID_DESC',
  RatesStddevSampleMinYearAsc = 'RATES_STDDEV_SAMPLE_MIN_YEAR_ASC',
  RatesStddevSampleMinYearDesc = 'RATES_STDDEV_SAMPLE_MIN_YEAR_DESC',
  RatesStddevSampleMaxYearAsc = 'RATES_STDDEV_SAMPLE_MAX_YEAR_ASC',
  RatesStddevSampleMaxYearDesc = 'RATES_STDDEV_SAMPLE_MAX_YEAR_DESC',
  RatesStddevSampleMinTermAsc = 'RATES_STDDEV_SAMPLE_MIN_TERM_ASC',
  RatesStddevSampleMinTermDesc = 'RATES_STDDEV_SAMPLE_MIN_TERM_DESC',
  RatesStddevSampleMaxTermAsc = 'RATES_STDDEV_SAMPLE_MAX_TERM_ASC',
  RatesStddevSampleMaxTermDesc = 'RATES_STDDEV_SAMPLE_MAX_TERM_DESC',
  RatesStddevSampleMinPriceAsc = 'RATES_STDDEV_SAMPLE_MIN_PRICE_ASC',
  RatesStddevSampleMinPriceDesc = 'RATES_STDDEV_SAMPLE_MIN_PRICE_DESC',
  RatesStddevSampleMaxPriceAsc = 'RATES_STDDEV_SAMPLE_MAX_PRICE_ASC',
  RatesStddevSampleMaxPriceDesc = 'RATES_STDDEV_SAMPLE_MAX_PRICE_DESC',
  RatesStddevSampleRateAsc = 'RATES_STDDEV_SAMPLE_RATE_ASC',
  RatesStddevSampleRateDesc = 'RATES_STDDEV_SAMPLE_RATE_DESC',
  RatesStddevSampleUsedCarAdvanceRateAsc = 'RATES_STDDEV_SAMPLE_USED_CAR_ADVANCE_RATE_ASC',
  RatesStddevSampleUsedCarAdvanceRateDesc = 'RATES_STDDEV_SAMPLE_USED_CAR_ADVANCE_RATE_DESC',
  RatesStddevSampleNewCarAdvanceRateAsc = 'RATES_STDDEV_SAMPLE_NEW_CAR_ADVANCE_RATE_ASC',
  RatesStddevSampleNewCarAdvanceRateDesc = 'RATES_STDDEV_SAMPLE_NEW_CAR_ADVANCE_RATE_DESC',
  RatesStddevSampleAddOnAsc = 'RATES_STDDEV_SAMPLE_ADD_ON_ASC',
  RatesStddevSampleAddOnDesc = 'RATES_STDDEV_SAMPLE_ADD_ON_DESC',
  RatesStddevSampleLenderAdminFeeAsc = 'RATES_STDDEV_SAMPLE_LENDER_ADMIN_FEE_ASC',
  RatesStddevSampleLenderAdminFeeDesc = 'RATES_STDDEV_SAMPLE_LENDER_ADMIN_FEE_DESC',
  RatesStddevSampleCreatedAtAsc = 'RATES_STDDEV_SAMPLE_CREATED_AT_ASC',
  RatesStddevSampleCreatedAtDesc = 'RATES_STDDEV_SAMPLE_CREATED_AT_DESC',
  RatesStddevSampleUpdatedAtAsc = 'RATES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  RatesStddevSampleUpdatedAtDesc = 'RATES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  RatesStddevPopulationIdAsc = 'RATES_STDDEV_POPULATION_ID_ASC',
  RatesStddevPopulationIdDesc = 'RATES_STDDEV_POPULATION_ID_DESC',
  RatesStddevPopulationLenderCreditKeyIdAsc = 'RATES_STDDEV_POPULATION_LENDER_CREDIT_KEY_ID_ASC',
  RatesStddevPopulationLenderCreditKeyIdDesc = 'RATES_STDDEV_POPULATION_LENDER_CREDIT_KEY_ID_DESC',
  RatesStddevPopulationMinYearAsc = 'RATES_STDDEV_POPULATION_MIN_YEAR_ASC',
  RatesStddevPopulationMinYearDesc = 'RATES_STDDEV_POPULATION_MIN_YEAR_DESC',
  RatesStddevPopulationMaxYearAsc = 'RATES_STDDEV_POPULATION_MAX_YEAR_ASC',
  RatesStddevPopulationMaxYearDesc = 'RATES_STDDEV_POPULATION_MAX_YEAR_DESC',
  RatesStddevPopulationMinTermAsc = 'RATES_STDDEV_POPULATION_MIN_TERM_ASC',
  RatesStddevPopulationMinTermDesc = 'RATES_STDDEV_POPULATION_MIN_TERM_DESC',
  RatesStddevPopulationMaxTermAsc = 'RATES_STDDEV_POPULATION_MAX_TERM_ASC',
  RatesStddevPopulationMaxTermDesc = 'RATES_STDDEV_POPULATION_MAX_TERM_DESC',
  RatesStddevPopulationMinPriceAsc = 'RATES_STDDEV_POPULATION_MIN_PRICE_ASC',
  RatesStddevPopulationMinPriceDesc = 'RATES_STDDEV_POPULATION_MIN_PRICE_DESC',
  RatesStddevPopulationMaxPriceAsc = 'RATES_STDDEV_POPULATION_MAX_PRICE_ASC',
  RatesStddevPopulationMaxPriceDesc = 'RATES_STDDEV_POPULATION_MAX_PRICE_DESC',
  RatesStddevPopulationRateAsc = 'RATES_STDDEV_POPULATION_RATE_ASC',
  RatesStddevPopulationRateDesc = 'RATES_STDDEV_POPULATION_RATE_DESC',
  RatesStddevPopulationUsedCarAdvanceRateAsc = 'RATES_STDDEV_POPULATION_USED_CAR_ADVANCE_RATE_ASC',
  RatesStddevPopulationUsedCarAdvanceRateDesc = 'RATES_STDDEV_POPULATION_USED_CAR_ADVANCE_RATE_DESC',
  RatesStddevPopulationNewCarAdvanceRateAsc = 'RATES_STDDEV_POPULATION_NEW_CAR_ADVANCE_RATE_ASC',
  RatesStddevPopulationNewCarAdvanceRateDesc = 'RATES_STDDEV_POPULATION_NEW_CAR_ADVANCE_RATE_DESC',
  RatesStddevPopulationAddOnAsc = 'RATES_STDDEV_POPULATION_ADD_ON_ASC',
  RatesStddevPopulationAddOnDesc = 'RATES_STDDEV_POPULATION_ADD_ON_DESC',
  RatesStddevPopulationLenderAdminFeeAsc = 'RATES_STDDEV_POPULATION_LENDER_ADMIN_FEE_ASC',
  RatesStddevPopulationLenderAdminFeeDesc = 'RATES_STDDEV_POPULATION_LENDER_ADMIN_FEE_DESC',
  RatesStddevPopulationCreatedAtAsc = 'RATES_STDDEV_POPULATION_CREATED_AT_ASC',
  RatesStddevPopulationCreatedAtDesc = 'RATES_STDDEV_POPULATION_CREATED_AT_DESC',
  RatesStddevPopulationUpdatedAtAsc = 'RATES_STDDEV_POPULATION_UPDATED_AT_ASC',
  RatesStddevPopulationUpdatedAtDesc = 'RATES_STDDEV_POPULATION_UPDATED_AT_DESC',
  RatesVarianceSampleIdAsc = 'RATES_VARIANCE_SAMPLE_ID_ASC',
  RatesVarianceSampleIdDesc = 'RATES_VARIANCE_SAMPLE_ID_DESC',
  RatesVarianceSampleLenderCreditKeyIdAsc = 'RATES_VARIANCE_SAMPLE_LENDER_CREDIT_KEY_ID_ASC',
  RatesVarianceSampleLenderCreditKeyIdDesc = 'RATES_VARIANCE_SAMPLE_LENDER_CREDIT_KEY_ID_DESC',
  RatesVarianceSampleMinYearAsc = 'RATES_VARIANCE_SAMPLE_MIN_YEAR_ASC',
  RatesVarianceSampleMinYearDesc = 'RATES_VARIANCE_SAMPLE_MIN_YEAR_DESC',
  RatesVarianceSampleMaxYearAsc = 'RATES_VARIANCE_SAMPLE_MAX_YEAR_ASC',
  RatesVarianceSampleMaxYearDesc = 'RATES_VARIANCE_SAMPLE_MAX_YEAR_DESC',
  RatesVarianceSampleMinTermAsc = 'RATES_VARIANCE_SAMPLE_MIN_TERM_ASC',
  RatesVarianceSampleMinTermDesc = 'RATES_VARIANCE_SAMPLE_MIN_TERM_DESC',
  RatesVarianceSampleMaxTermAsc = 'RATES_VARIANCE_SAMPLE_MAX_TERM_ASC',
  RatesVarianceSampleMaxTermDesc = 'RATES_VARIANCE_SAMPLE_MAX_TERM_DESC',
  RatesVarianceSampleMinPriceAsc = 'RATES_VARIANCE_SAMPLE_MIN_PRICE_ASC',
  RatesVarianceSampleMinPriceDesc = 'RATES_VARIANCE_SAMPLE_MIN_PRICE_DESC',
  RatesVarianceSampleMaxPriceAsc = 'RATES_VARIANCE_SAMPLE_MAX_PRICE_ASC',
  RatesVarianceSampleMaxPriceDesc = 'RATES_VARIANCE_SAMPLE_MAX_PRICE_DESC',
  RatesVarianceSampleRateAsc = 'RATES_VARIANCE_SAMPLE_RATE_ASC',
  RatesVarianceSampleRateDesc = 'RATES_VARIANCE_SAMPLE_RATE_DESC',
  RatesVarianceSampleUsedCarAdvanceRateAsc = 'RATES_VARIANCE_SAMPLE_USED_CAR_ADVANCE_RATE_ASC',
  RatesVarianceSampleUsedCarAdvanceRateDesc = 'RATES_VARIANCE_SAMPLE_USED_CAR_ADVANCE_RATE_DESC',
  RatesVarianceSampleNewCarAdvanceRateAsc = 'RATES_VARIANCE_SAMPLE_NEW_CAR_ADVANCE_RATE_ASC',
  RatesVarianceSampleNewCarAdvanceRateDesc = 'RATES_VARIANCE_SAMPLE_NEW_CAR_ADVANCE_RATE_DESC',
  RatesVarianceSampleAddOnAsc = 'RATES_VARIANCE_SAMPLE_ADD_ON_ASC',
  RatesVarianceSampleAddOnDesc = 'RATES_VARIANCE_SAMPLE_ADD_ON_DESC',
  RatesVarianceSampleLenderAdminFeeAsc = 'RATES_VARIANCE_SAMPLE_LENDER_ADMIN_FEE_ASC',
  RatesVarianceSampleLenderAdminFeeDesc = 'RATES_VARIANCE_SAMPLE_LENDER_ADMIN_FEE_DESC',
  RatesVarianceSampleCreatedAtAsc = 'RATES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  RatesVarianceSampleCreatedAtDesc = 'RATES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  RatesVarianceSampleUpdatedAtAsc = 'RATES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  RatesVarianceSampleUpdatedAtDesc = 'RATES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  RatesVariancePopulationIdAsc = 'RATES_VARIANCE_POPULATION_ID_ASC',
  RatesVariancePopulationIdDesc = 'RATES_VARIANCE_POPULATION_ID_DESC',
  RatesVariancePopulationLenderCreditKeyIdAsc = 'RATES_VARIANCE_POPULATION_LENDER_CREDIT_KEY_ID_ASC',
  RatesVariancePopulationLenderCreditKeyIdDesc = 'RATES_VARIANCE_POPULATION_LENDER_CREDIT_KEY_ID_DESC',
  RatesVariancePopulationMinYearAsc = 'RATES_VARIANCE_POPULATION_MIN_YEAR_ASC',
  RatesVariancePopulationMinYearDesc = 'RATES_VARIANCE_POPULATION_MIN_YEAR_DESC',
  RatesVariancePopulationMaxYearAsc = 'RATES_VARIANCE_POPULATION_MAX_YEAR_ASC',
  RatesVariancePopulationMaxYearDesc = 'RATES_VARIANCE_POPULATION_MAX_YEAR_DESC',
  RatesVariancePopulationMinTermAsc = 'RATES_VARIANCE_POPULATION_MIN_TERM_ASC',
  RatesVariancePopulationMinTermDesc = 'RATES_VARIANCE_POPULATION_MIN_TERM_DESC',
  RatesVariancePopulationMaxTermAsc = 'RATES_VARIANCE_POPULATION_MAX_TERM_ASC',
  RatesVariancePopulationMaxTermDesc = 'RATES_VARIANCE_POPULATION_MAX_TERM_DESC',
  RatesVariancePopulationMinPriceAsc = 'RATES_VARIANCE_POPULATION_MIN_PRICE_ASC',
  RatesVariancePopulationMinPriceDesc = 'RATES_VARIANCE_POPULATION_MIN_PRICE_DESC',
  RatesVariancePopulationMaxPriceAsc = 'RATES_VARIANCE_POPULATION_MAX_PRICE_ASC',
  RatesVariancePopulationMaxPriceDesc = 'RATES_VARIANCE_POPULATION_MAX_PRICE_DESC',
  RatesVariancePopulationRateAsc = 'RATES_VARIANCE_POPULATION_RATE_ASC',
  RatesVariancePopulationRateDesc = 'RATES_VARIANCE_POPULATION_RATE_DESC',
  RatesVariancePopulationUsedCarAdvanceRateAsc = 'RATES_VARIANCE_POPULATION_USED_CAR_ADVANCE_RATE_ASC',
  RatesVariancePopulationUsedCarAdvanceRateDesc = 'RATES_VARIANCE_POPULATION_USED_CAR_ADVANCE_RATE_DESC',
  RatesVariancePopulationNewCarAdvanceRateAsc = 'RATES_VARIANCE_POPULATION_NEW_CAR_ADVANCE_RATE_ASC',
  RatesVariancePopulationNewCarAdvanceRateDesc = 'RATES_VARIANCE_POPULATION_NEW_CAR_ADVANCE_RATE_DESC',
  RatesVariancePopulationAddOnAsc = 'RATES_VARIANCE_POPULATION_ADD_ON_ASC',
  RatesVariancePopulationAddOnDesc = 'RATES_VARIANCE_POPULATION_ADD_ON_DESC',
  RatesVariancePopulationLenderAdminFeeAsc = 'RATES_VARIANCE_POPULATION_LENDER_ADMIN_FEE_ASC',
  RatesVariancePopulationLenderAdminFeeDesc = 'RATES_VARIANCE_POPULATION_LENDER_ADMIN_FEE_DESC',
  RatesVariancePopulationCreatedAtAsc = 'RATES_VARIANCE_POPULATION_CREATED_AT_ASC',
  RatesVariancePopulationCreatedAtDesc = 'RATES_VARIANCE_POPULATION_CREATED_AT_DESC',
  RatesVariancePopulationUpdatedAtAsc = 'RATES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  RatesVariancePopulationUpdatedAtDesc = 'RATES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderProgramBonusesCountAsc = 'LENDER_PROGRAM_BONUSES_COUNT_ASC',
  LenderProgramBonusesCountDesc = 'LENDER_PROGRAM_BONUSES_COUNT_DESC',
  LenderProgramBonusesSumIdAsc = 'LENDER_PROGRAM_BONUSES_SUM_ID_ASC',
  LenderProgramBonusesSumIdDesc = 'LENDER_PROGRAM_BONUSES_SUM_ID_DESC',
  LenderProgramBonusesSumLenderCreditKeyIdAsc = 'LENDER_PROGRAM_BONUSES_SUM_LENDER_CREDIT_KEY_ID_ASC',
  LenderProgramBonusesSumLenderCreditKeyIdDesc = 'LENDER_PROGRAM_BONUSES_SUM_LENDER_CREDIT_KEY_ID_DESC',
  LenderProgramBonusesSumMinPriceAsc = 'LENDER_PROGRAM_BONUSES_SUM_MIN_PRICE_ASC',
  LenderProgramBonusesSumMinPriceDesc = 'LENDER_PROGRAM_BONUSES_SUM_MIN_PRICE_DESC',
  LenderProgramBonusesSumMaxPriceAsc = 'LENDER_PROGRAM_BONUSES_SUM_MAX_PRICE_ASC',
  LenderProgramBonusesSumMaxPriceDesc = 'LENDER_PROGRAM_BONUSES_SUM_MAX_PRICE_DESC',
  LenderProgramBonusesSumCreditQualityAsc = 'LENDER_PROGRAM_BONUSES_SUM_CREDIT_QUALITY_ASC',
  LenderProgramBonusesSumCreditQualityDesc = 'LENDER_PROGRAM_BONUSES_SUM_CREDIT_QUALITY_DESC',
  LenderProgramBonusesSumPersonalCoApplicantAsc = 'LENDER_PROGRAM_BONUSES_SUM_PERSONAL_CO_APPLICANT_ASC',
  LenderProgramBonusesSumPersonalCoApplicantDesc = 'LENDER_PROGRAM_BONUSES_SUM_PERSONAL_CO_APPLICANT_DESC',
  LenderProgramBonusesSumBonusAsc = 'LENDER_PROGRAM_BONUSES_SUM_BONUS_ASC',
  LenderProgramBonusesSumBonusDesc = 'LENDER_PROGRAM_BONUSES_SUM_BONUS_DESC',
  LenderProgramBonusesSumCreatedAtAsc = 'LENDER_PROGRAM_BONUSES_SUM_CREATED_AT_ASC',
  LenderProgramBonusesSumCreatedAtDesc = 'LENDER_PROGRAM_BONUSES_SUM_CREATED_AT_DESC',
  LenderProgramBonusesSumUpdatedAtAsc = 'LENDER_PROGRAM_BONUSES_SUM_UPDATED_AT_ASC',
  LenderProgramBonusesSumUpdatedAtDesc = 'LENDER_PROGRAM_BONUSES_SUM_UPDATED_AT_DESC',
  LenderProgramBonusesDistinctCountIdAsc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_ID_ASC',
  LenderProgramBonusesDistinctCountIdDesc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_ID_DESC',
  LenderProgramBonusesDistinctCountLenderCreditKeyIdAsc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_LENDER_CREDIT_KEY_ID_ASC',
  LenderProgramBonusesDistinctCountLenderCreditKeyIdDesc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_LENDER_CREDIT_KEY_ID_DESC',
  LenderProgramBonusesDistinctCountMinPriceAsc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_MIN_PRICE_ASC',
  LenderProgramBonusesDistinctCountMinPriceDesc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_MIN_PRICE_DESC',
  LenderProgramBonusesDistinctCountMaxPriceAsc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_MAX_PRICE_ASC',
  LenderProgramBonusesDistinctCountMaxPriceDesc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_MAX_PRICE_DESC',
  LenderProgramBonusesDistinctCountCreditQualityAsc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_CREDIT_QUALITY_ASC',
  LenderProgramBonusesDistinctCountCreditQualityDesc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_CREDIT_QUALITY_DESC',
  LenderProgramBonusesDistinctCountPersonalCoApplicantAsc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_PERSONAL_CO_APPLICANT_ASC',
  LenderProgramBonusesDistinctCountPersonalCoApplicantDesc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_PERSONAL_CO_APPLICANT_DESC',
  LenderProgramBonusesDistinctCountBonusAsc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_BONUS_ASC',
  LenderProgramBonusesDistinctCountBonusDesc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_BONUS_DESC',
  LenderProgramBonusesDistinctCountCreatedAtAsc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderProgramBonusesDistinctCountCreatedAtDesc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderProgramBonusesDistinctCountUpdatedAtAsc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderProgramBonusesDistinctCountUpdatedAtDesc = 'LENDER_PROGRAM_BONUSES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderProgramBonusesMinIdAsc = 'LENDER_PROGRAM_BONUSES_MIN_ID_ASC',
  LenderProgramBonusesMinIdDesc = 'LENDER_PROGRAM_BONUSES_MIN_ID_DESC',
  LenderProgramBonusesMinLenderCreditKeyIdAsc = 'LENDER_PROGRAM_BONUSES_MIN_LENDER_CREDIT_KEY_ID_ASC',
  LenderProgramBonusesMinLenderCreditKeyIdDesc = 'LENDER_PROGRAM_BONUSES_MIN_LENDER_CREDIT_KEY_ID_DESC',
  LenderProgramBonusesMinMinPriceAsc = 'LENDER_PROGRAM_BONUSES_MIN_MIN_PRICE_ASC',
  LenderProgramBonusesMinMinPriceDesc = 'LENDER_PROGRAM_BONUSES_MIN_MIN_PRICE_DESC',
  LenderProgramBonusesMinMaxPriceAsc = 'LENDER_PROGRAM_BONUSES_MIN_MAX_PRICE_ASC',
  LenderProgramBonusesMinMaxPriceDesc = 'LENDER_PROGRAM_BONUSES_MIN_MAX_PRICE_DESC',
  LenderProgramBonusesMinCreditQualityAsc = 'LENDER_PROGRAM_BONUSES_MIN_CREDIT_QUALITY_ASC',
  LenderProgramBonusesMinCreditQualityDesc = 'LENDER_PROGRAM_BONUSES_MIN_CREDIT_QUALITY_DESC',
  LenderProgramBonusesMinPersonalCoApplicantAsc = 'LENDER_PROGRAM_BONUSES_MIN_PERSONAL_CO_APPLICANT_ASC',
  LenderProgramBonusesMinPersonalCoApplicantDesc = 'LENDER_PROGRAM_BONUSES_MIN_PERSONAL_CO_APPLICANT_DESC',
  LenderProgramBonusesMinBonusAsc = 'LENDER_PROGRAM_BONUSES_MIN_BONUS_ASC',
  LenderProgramBonusesMinBonusDesc = 'LENDER_PROGRAM_BONUSES_MIN_BONUS_DESC',
  LenderProgramBonusesMinCreatedAtAsc = 'LENDER_PROGRAM_BONUSES_MIN_CREATED_AT_ASC',
  LenderProgramBonusesMinCreatedAtDesc = 'LENDER_PROGRAM_BONUSES_MIN_CREATED_AT_DESC',
  LenderProgramBonusesMinUpdatedAtAsc = 'LENDER_PROGRAM_BONUSES_MIN_UPDATED_AT_ASC',
  LenderProgramBonusesMinUpdatedAtDesc = 'LENDER_PROGRAM_BONUSES_MIN_UPDATED_AT_DESC',
  LenderProgramBonusesMaxIdAsc = 'LENDER_PROGRAM_BONUSES_MAX_ID_ASC',
  LenderProgramBonusesMaxIdDesc = 'LENDER_PROGRAM_BONUSES_MAX_ID_DESC',
  LenderProgramBonusesMaxLenderCreditKeyIdAsc = 'LENDER_PROGRAM_BONUSES_MAX_LENDER_CREDIT_KEY_ID_ASC',
  LenderProgramBonusesMaxLenderCreditKeyIdDesc = 'LENDER_PROGRAM_BONUSES_MAX_LENDER_CREDIT_KEY_ID_DESC',
  LenderProgramBonusesMaxMinPriceAsc = 'LENDER_PROGRAM_BONUSES_MAX_MIN_PRICE_ASC',
  LenderProgramBonusesMaxMinPriceDesc = 'LENDER_PROGRAM_BONUSES_MAX_MIN_PRICE_DESC',
  LenderProgramBonusesMaxMaxPriceAsc = 'LENDER_PROGRAM_BONUSES_MAX_MAX_PRICE_ASC',
  LenderProgramBonusesMaxMaxPriceDesc = 'LENDER_PROGRAM_BONUSES_MAX_MAX_PRICE_DESC',
  LenderProgramBonusesMaxCreditQualityAsc = 'LENDER_PROGRAM_BONUSES_MAX_CREDIT_QUALITY_ASC',
  LenderProgramBonusesMaxCreditQualityDesc = 'LENDER_PROGRAM_BONUSES_MAX_CREDIT_QUALITY_DESC',
  LenderProgramBonusesMaxPersonalCoApplicantAsc = 'LENDER_PROGRAM_BONUSES_MAX_PERSONAL_CO_APPLICANT_ASC',
  LenderProgramBonusesMaxPersonalCoApplicantDesc = 'LENDER_PROGRAM_BONUSES_MAX_PERSONAL_CO_APPLICANT_DESC',
  LenderProgramBonusesMaxBonusAsc = 'LENDER_PROGRAM_BONUSES_MAX_BONUS_ASC',
  LenderProgramBonusesMaxBonusDesc = 'LENDER_PROGRAM_BONUSES_MAX_BONUS_DESC',
  LenderProgramBonusesMaxCreatedAtAsc = 'LENDER_PROGRAM_BONUSES_MAX_CREATED_AT_ASC',
  LenderProgramBonusesMaxCreatedAtDesc = 'LENDER_PROGRAM_BONUSES_MAX_CREATED_AT_DESC',
  LenderProgramBonusesMaxUpdatedAtAsc = 'LENDER_PROGRAM_BONUSES_MAX_UPDATED_AT_ASC',
  LenderProgramBonusesMaxUpdatedAtDesc = 'LENDER_PROGRAM_BONUSES_MAX_UPDATED_AT_DESC',
  LenderProgramBonusesAverageIdAsc = 'LENDER_PROGRAM_BONUSES_AVERAGE_ID_ASC',
  LenderProgramBonusesAverageIdDesc = 'LENDER_PROGRAM_BONUSES_AVERAGE_ID_DESC',
  LenderProgramBonusesAverageLenderCreditKeyIdAsc = 'LENDER_PROGRAM_BONUSES_AVERAGE_LENDER_CREDIT_KEY_ID_ASC',
  LenderProgramBonusesAverageLenderCreditKeyIdDesc = 'LENDER_PROGRAM_BONUSES_AVERAGE_LENDER_CREDIT_KEY_ID_DESC',
  LenderProgramBonusesAverageMinPriceAsc = 'LENDER_PROGRAM_BONUSES_AVERAGE_MIN_PRICE_ASC',
  LenderProgramBonusesAverageMinPriceDesc = 'LENDER_PROGRAM_BONUSES_AVERAGE_MIN_PRICE_DESC',
  LenderProgramBonusesAverageMaxPriceAsc = 'LENDER_PROGRAM_BONUSES_AVERAGE_MAX_PRICE_ASC',
  LenderProgramBonusesAverageMaxPriceDesc = 'LENDER_PROGRAM_BONUSES_AVERAGE_MAX_PRICE_DESC',
  LenderProgramBonusesAverageCreditQualityAsc = 'LENDER_PROGRAM_BONUSES_AVERAGE_CREDIT_QUALITY_ASC',
  LenderProgramBonusesAverageCreditQualityDesc = 'LENDER_PROGRAM_BONUSES_AVERAGE_CREDIT_QUALITY_DESC',
  LenderProgramBonusesAveragePersonalCoApplicantAsc = 'LENDER_PROGRAM_BONUSES_AVERAGE_PERSONAL_CO_APPLICANT_ASC',
  LenderProgramBonusesAveragePersonalCoApplicantDesc = 'LENDER_PROGRAM_BONUSES_AVERAGE_PERSONAL_CO_APPLICANT_DESC',
  LenderProgramBonusesAverageBonusAsc = 'LENDER_PROGRAM_BONUSES_AVERAGE_BONUS_ASC',
  LenderProgramBonusesAverageBonusDesc = 'LENDER_PROGRAM_BONUSES_AVERAGE_BONUS_DESC',
  LenderProgramBonusesAverageCreatedAtAsc = 'LENDER_PROGRAM_BONUSES_AVERAGE_CREATED_AT_ASC',
  LenderProgramBonusesAverageCreatedAtDesc = 'LENDER_PROGRAM_BONUSES_AVERAGE_CREATED_AT_DESC',
  LenderProgramBonusesAverageUpdatedAtAsc = 'LENDER_PROGRAM_BONUSES_AVERAGE_UPDATED_AT_ASC',
  LenderProgramBonusesAverageUpdatedAtDesc = 'LENDER_PROGRAM_BONUSES_AVERAGE_UPDATED_AT_DESC',
  LenderProgramBonusesStddevSampleIdAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_ID_ASC',
  LenderProgramBonusesStddevSampleIdDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_ID_DESC',
  LenderProgramBonusesStddevSampleLenderCreditKeyIdAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_LENDER_CREDIT_KEY_ID_ASC',
  LenderProgramBonusesStddevSampleLenderCreditKeyIdDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_LENDER_CREDIT_KEY_ID_DESC',
  LenderProgramBonusesStddevSampleMinPriceAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_MIN_PRICE_ASC',
  LenderProgramBonusesStddevSampleMinPriceDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_MIN_PRICE_DESC',
  LenderProgramBonusesStddevSampleMaxPriceAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_MAX_PRICE_ASC',
  LenderProgramBonusesStddevSampleMaxPriceDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_MAX_PRICE_DESC',
  LenderProgramBonusesStddevSampleCreditQualityAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_CREDIT_QUALITY_ASC',
  LenderProgramBonusesStddevSampleCreditQualityDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_CREDIT_QUALITY_DESC',
  LenderProgramBonusesStddevSamplePersonalCoApplicantAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_PERSONAL_CO_APPLICANT_ASC',
  LenderProgramBonusesStddevSamplePersonalCoApplicantDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_PERSONAL_CO_APPLICANT_DESC',
  LenderProgramBonusesStddevSampleBonusAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_BONUS_ASC',
  LenderProgramBonusesStddevSampleBonusDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_BONUS_DESC',
  LenderProgramBonusesStddevSampleCreatedAtAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderProgramBonusesStddevSampleCreatedAtDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderProgramBonusesStddevSampleUpdatedAtAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderProgramBonusesStddevSampleUpdatedAtDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderProgramBonusesStddevPopulationIdAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_ID_ASC',
  LenderProgramBonusesStddevPopulationIdDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_ID_DESC',
  LenderProgramBonusesStddevPopulationLenderCreditKeyIdAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_LENDER_CREDIT_KEY_ID_ASC',
  LenderProgramBonusesStddevPopulationLenderCreditKeyIdDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_LENDER_CREDIT_KEY_ID_DESC',
  LenderProgramBonusesStddevPopulationMinPriceAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_MIN_PRICE_ASC',
  LenderProgramBonusesStddevPopulationMinPriceDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_MIN_PRICE_DESC',
  LenderProgramBonusesStddevPopulationMaxPriceAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_MAX_PRICE_ASC',
  LenderProgramBonusesStddevPopulationMaxPriceDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_MAX_PRICE_DESC',
  LenderProgramBonusesStddevPopulationCreditQualityAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_CREDIT_QUALITY_ASC',
  LenderProgramBonusesStddevPopulationCreditQualityDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_CREDIT_QUALITY_DESC',
  LenderProgramBonusesStddevPopulationPersonalCoApplicantAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_PERSONAL_CO_APPLICANT_ASC',
  LenderProgramBonusesStddevPopulationPersonalCoApplicantDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_PERSONAL_CO_APPLICANT_DESC',
  LenderProgramBonusesStddevPopulationBonusAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_BONUS_ASC',
  LenderProgramBonusesStddevPopulationBonusDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_BONUS_DESC',
  LenderProgramBonusesStddevPopulationCreatedAtAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderProgramBonusesStddevPopulationCreatedAtDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderProgramBonusesStddevPopulationUpdatedAtAsc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderProgramBonusesStddevPopulationUpdatedAtDesc = 'LENDER_PROGRAM_BONUSES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderProgramBonusesVarianceSampleIdAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_ID_ASC',
  LenderProgramBonusesVarianceSampleIdDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_ID_DESC',
  LenderProgramBonusesVarianceSampleLenderCreditKeyIdAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_LENDER_CREDIT_KEY_ID_ASC',
  LenderProgramBonusesVarianceSampleLenderCreditKeyIdDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_LENDER_CREDIT_KEY_ID_DESC',
  LenderProgramBonusesVarianceSampleMinPriceAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_MIN_PRICE_ASC',
  LenderProgramBonusesVarianceSampleMinPriceDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_MIN_PRICE_DESC',
  LenderProgramBonusesVarianceSampleMaxPriceAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_MAX_PRICE_ASC',
  LenderProgramBonusesVarianceSampleMaxPriceDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_MAX_PRICE_DESC',
  LenderProgramBonusesVarianceSampleCreditQualityAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_CREDIT_QUALITY_ASC',
  LenderProgramBonusesVarianceSampleCreditQualityDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_CREDIT_QUALITY_DESC',
  LenderProgramBonusesVarianceSamplePersonalCoApplicantAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_PERSONAL_CO_APPLICANT_ASC',
  LenderProgramBonusesVarianceSamplePersonalCoApplicantDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_PERSONAL_CO_APPLICANT_DESC',
  LenderProgramBonusesVarianceSampleBonusAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_BONUS_ASC',
  LenderProgramBonusesVarianceSampleBonusDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_BONUS_DESC',
  LenderProgramBonusesVarianceSampleCreatedAtAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderProgramBonusesVarianceSampleCreatedAtDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderProgramBonusesVarianceSampleUpdatedAtAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderProgramBonusesVarianceSampleUpdatedAtDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderProgramBonusesVariancePopulationIdAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_ID_ASC',
  LenderProgramBonusesVariancePopulationIdDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_ID_DESC',
  LenderProgramBonusesVariancePopulationLenderCreditKeyIdAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_LENDER_CREDIT_KEY_ID_ASC',
  LenderProgramBonusesVariancePopulationLenderCreditKeyIdDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_LENDER_CREDIT_KEY_ID_DESC',
  LenderProgramBonusesVariancePopulationMinPriceAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_MIN_PRICE_ASC',
  LenderProgramBonusesVariancePopulationMinPriceDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_MIN_PRICE_DESC',
  LenderProgramBonusesVariancePopulationMaxPriceAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_MAX_PRICE_ASC',
  LenderProgramBonusesVariancePopulationMaxPriceDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_MAX_PRICE_DESC',
  LenderProgramBonusesVariancePopulationCreditQualityAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_CREDIT_QUALITY_ASC',
  LenderProgramBonusesVariancePopulationCreditQualityDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_CREDIT_QUALITY_DESC',
  LenderProgramBonusesVariancePopulationPersonalCoApplicantAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_PERSONAL_CO_APPLICANT_ASC',
  LenderProgramBonusesVariancePopulationPersonalCoApplicantDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_PERSONAL_CO_APPLICANT_DESC',
  LenderProgramBonusesVariancePopulationBonusAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_BONUS_ASC',
  LenderProgramBonusesVariancePopulationBonusDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_BONUS_DESC',
  LenderProgramBonusesVariancePopulationCreatedAtAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderProgramBonusesVariancePopulationCreatedAtDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderProgramBonusesVariancePopulationUpdatedAtAsc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderProgramBonusesVariancePopulationUpdatedAtDesc = 'LENDER_PROGRAM_BONUSES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  MaxAmortizationsCountAsc = 'MAX_AMORTIZATIONS_COUNT_ASC',
  MaxAmortizationsCountDesc = 'MAX_AMORTIZATIONS_COUNT_DESC',
  MaxAmortizationsSumIdAsc = 'MAX_AMORTIZATIONS_SUM_ID_ASC',
  MaxAmortizationsSumIdDesc = 'MAX_AMORTIZATIONS_SUM_ID_DESC',
  MaxAmortizationsSumLenderCreditKeyIdAsc = 'MAX_AMORTIZATIONS_SUM_LENDER_CREDIT_KEY_ID_ASC',
  MaxAmortizationsSumLenderCreditKeyIdDesc = 'MAX_AMORTIZATIONS_SUM_LENDER_CREDIT_KEY_ID_DESC',
  MaxAmortizationsSumMinYearAsc = 'MAX_AMORTIZATIONS_SUM_MIN_YEAR_ASC',
  MaxAmortizationsSumMinYearDesc = 'MAX_AMORTIZATIONS_SUM_MIN_YEAR_DESC',
  MaxAmortizationsSumMaxYearAsc = 'MAX_AMORTIZATIONS_SUM_MAX_YEAR_ASC',
  MaxAmortizationsSumMaxYearDesc = 'MAX_AMORTIZATIONS_SUM_MAX_YEAR_DESC',
  MaxAmortizationsSumPersonalCoApplicantAsc = 'MAX_AMORTIZATIONS_SUM_PERSONAL_CO_APPLICANT_ASC',
  MaxAmortizationsSumPersonalCoApplicantDesc = 'MAX_AMORTIZATIONS_SUM_PERSONAL_CO_APPLICANT_DESC',
  MaxAmortizationsSumTermAsc = 'MAX_AMORTIZATIONS_SUM_TERM_ASC',
  MaxAmortizationsSumTermDesc = 'MAX_AMORTIZATIONS_SUM_TERM_DESC',
  MaxAmortizationsSumCreatedAtAsc = 'MAX_AMORTIZATIONS_SUM_CREATED_AT_ASC',
  MaxAmortizationsSumCreatedAtDesc = 'MAX_AMORTIZATIONS_SUM_CREATED_AT_DESC',
  MaxAmortizationsSumUpdatedAtAsc = 'MAX_AMORTIZATIONS_SUM_UPDATED_AT_ASC',
  MaxAmortizationsSumUpdatedAtDesc = 'MAX_AMORTIZATIONS_SUM_UPDATED_AT_DESC',
  MaxAmortizationsDistinctCountIdAsc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_ID_ASC',
  MaxAmortizationsDistinctCountIdDesc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_ID_DESC',
  MaxAmortizationsDistinctCountLenderCreditKeyIdAsc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_LENDER_CREDIT_KEY_ID_ASC',
  MaxAmortizationsDistinctCountLenderCreditKeyIdDesc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_LENDER_CREDIT_KEY_ID_DESC',
  MaxAmortizationsDistinctCountMinYearAsc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_MIN_YEAR_ASC',
  MaxAmortizationsDistinctCountMinYearDesc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_MIN_YEAR_DESC',
  MaxAmortizationsDistinctCountMaxYearAsc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_MAX_YEAR_ASC',
  MaxAmortizationsDistinctCountMaxYearDesc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_MAX_YEAR_DESC',
  MaxAmortizationsDistinctCountPersonalCoApplicantAsc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_PERSONAL_CO_APPLICANT_ASC',
  MaxAmortizationsDistinctCountPersonalCoApplicantDesc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_PERSONAL_CO_APPLICANT_DESC',
  MaxAmortizationsDistinctCountTermAsc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_TERM_ASC',
  MaxAmortizationsDistinctCountTermDesc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_TERM_DESC',
  MaxAmortizationsDistinctCountCreatedAtAsc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  MaxAmortizationsDistinctCountCreatedAtDesc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  MaxAmortizationsDistinctCountUpdatedAtAsc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  MaxAmortizationsDistinctCountUpdatedAtDesc = 'MAX_AMORTIZATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  MaxAmortizationsMinIdAsc = 'MAX_AMORTIZATIONS_MIN_ID_ASC',
  MaxAmortizationsMinIdDesc = 'MAX_AMORTIZATIONS_MIN_ID_DESC',
  MaxAmortizationsMinLenderCreditKeyIdAsc = 'MAX_AMORTIZATIONS_MIN_LENDER_CREDIT_KEY_ID_ASC',
  MaxAmortizationsMinLenderCreditKeyIdDesc = 'MAX_AMORTIZATIONS_MIN_LENDER_CREDIT_KEY_ID_DESC',
  MaxAmortizationsMinMinYearAsc = 'MAX_AMORTIZATIONS_MIN_MIN_YEAR_ASC',
  MaxAmortizationsMinMinYearDesc = 'MAX_AMORTIZATIONS_MIN_MIN_YEAR_DESC',
  MaxAmortizationsMinMaxYearAsc = 'MAX_AMORTIZATIONS_MIN_MAX_YEAR_ASC',
  MaxAmortizationsMinMaxYearDesc = 'MAX_AMORTIZATIONS_MIN_MAX_YEAR_DESC',
  MaxAmortizationsMinPersonalCoApplicantAsc = 'MAX_AMORTIZATIONS_MIN_PERSONAL_CO_APPLICANT_ASC',
  MaxAmortizationsMinPersonalCoApplicantDesc = 'MAX_AMORTIZATIONS_MIN_PERSONAL_CO_APPLICANT_DESC',
  MaxAmortizationsMinTermAsc = 'MAX_AMORTIZATIONS_MIN_TERM_ASC',
  MaxAmortizationsMinTermDesc = 'MAX_AMORTIZATIONS_MIN_TERM_DESC',
  MaxAmortizationsMinCreatedAtAsc = 'MAX_AMORTIZATIONS_MIN_CREATED_AT_ASC',
  MaxAmortizationsMinCreatedAtDesc = 'MAX_AMORTIZATIONS_MIN_CREATED_AT_DESC',
  MaxAmortizationsMinUpdatedAtAsc = 'MAX_AMORTIZATIONS_MIN_UPDATED_AT_ASC',
  MaxAmortizationsMinUpdatedAtDesc = 'MAX_AMORTIZATIONS_MIN_UPDATED_AT_DESC',
  MaxAmortizationsMaxIdAsc = 'MAX_AMORTIZATIONS_MAX_ID_ASC',
  MaxAmortizationsMaxIdDesc = 'MAX_AMORTIZATIONS_MAX_ID_DESC',
  MaxAmortizationsMaxLenderCreditKeyIdAsc = 'MAX_AMORTIZATIONS_MAX_LENDER_CREDIT_KEY_ID_ASC',
  MaxAmortizationsMaxLenderCreditKeyIdDesc = 'MAX_AMORTIZATIONS_MAX_LENDER_CREDIT_KEY_ID_DESC',
  MaxAmortizationsMaxMinYearAsc = 'MAX_AMORTIZATIONS_MAX_MIN_YEAR_ASC',
  MaxAmortizationsMaxMinYearDesc = 'MAX_AMORTIZATIONS_MAX_MIN_YEAR_DESC',
  MaxAmortizationsMaxMaxYearAsc = 'MAX_AMORTIZATIONS_MAX_MAX_YEAR_ASC',
  MaxAmortizationsMaxMaxYearDesc = 'MAX_AMORTIZATIONS_MAX_MAX_YEAR_DESC',
  MaxAmortizationsMaxPersonalCoApplicantAsc = 'MAX_AMORTIZATIONS_MAX_PERSONAL_CO_APPLICANT_ASC',
  MaxAmortizationsMaxPersonalCoApplicantDesc = 'MAX_AMORTIZATIONS_MAX_PERSONAL_CO_APPLICANT_DESC',
  MaxAmortizationsMaxTermAsc = 'MAX_AMORTIZATIONS_MAX_TERM_ASC',
  MaxAmortizationsMaxTermDesc = 'MAX_AMORTIZATIONS_MAX_TERM_DESC',
  MaxAmortizationsMaxCreatedAtAsc = 'MAX_AMORTIZATIONS_MAX_CREATED_AT_ASC',
  MaxAmortizationsMaxCreatedAtDesc = 'MAX_AMORTIZATIONS_MAX_CREATED_AT_DESC',
  MaxAmortizationsMaxUpdatedAtAsc = 'MAX_AMORTIZATIONS_MAX_UPDATED_AT_ASC',
  MaxAmortizationsMaxUpdatedAtDesc = 'MAX_AMORTIZATIONS_MAX_UPDATED_AT_DESC',
  MaxAmortizationsAverageIdAsc = 'MAX_AMORTIZATIONS_AVERAGE_ID_ASC',
  MaxAmortizationsAverageIdDesc = 'MAX_AMORTIZATIONS_AVERAGE_ID_DESC',
  MaxAmortizationsAverageLenderCreditKeyIdAsc = 'MAX_AMORTIZATIONS_AVERAGE_LENDER_CREDIT_KEY_ID_ASC',
  MaxAmortizationsAverageLenderCreditKeyIdDesc = 'MAX_AMORTIZATIONS_AVERAGE_LENDER_CREDIT_KEY_ID_DESC',
  MaxAmortizationsAverageMinYearAsc = 'MAX_AMORTIZATIONS_AVERAGE_MIN_YEAR_ASC',
  MaxAmortizationsAverageMinYearDesc = 'MAX_AMORTIZATIONS_AVERAGE_MIN_YEAR_DESC',
  MaxAmortizationsAverageMaxYearAsc = 'MAX_AMORTIZATIONS_AVERAGE_MAX_YEAR_ASC',
  MaxAmortizationsAverageMaxYearDesc = 'MAX_AMORTIZATIONS_AVERAGE_MAX_YEAR_DESC',
  MaxAmortizationsAveragePersonalCoApplicantAsc = 'MAX_AMORTIZATIONS_AVERAGE_PERSONAL_CO_APPLICANT_ASC',
  MaxAmortizationsAveragePersonalCoApplicantDesc = 'MAX_AMORTIZATIONS_AVERAGE_PERSONAL_CO_APPLICANT_DESC',
  MaxAmortizationsAverageTermAsc = 'MAX_AMORTIZATIONS_AVERAGE_TERM_ASC',
  MaxAmortizationsAverageTermDesc = 'MAX_AMORTIZATIONS_AVERAGE_TERM_DESC',
  MaxAmortizationsAverageCreatedAtAsc = 'MAX_AMORTIZATIONS_AVERAGE_CREATED_AT_ASC',
  MaxAmortizationsAverageCreatedAtDesc = 'MAX_AMORTIZATIONS_AVERAGE_CREATED_AT_DESC',
  MaxAmortizationsAverageUpdatedAtAsc = 'MAX_AMORTIZATIONS_AVERAGE_UPDATED_AT_ASC',
  MaxAmortizationsAverageUpdatedAtDesc = 'MAX_AMORTIZATIONS_AVERAGE_UPDATED_AT_DESC',
  MaxAmortizationsStddevSampleIdAsc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_ID_ASC',
  MaxAmortizationsStddevSampleIdDesc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_ID_DESC',
  MaxAmortizationsStddevSampleLenderCreditKeyIdAsc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_LENDER_CREDIT_KEY_ID_ASC',
  MaxAmortizationsStddevSampleLenderCreditKeyIdDesc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_LENDER_CREDIT_KEY_ID_DESC',
  MaxAmortizationsStddevSampleMinYearAsc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_MIN_YEAR_ASC',
  MaxAmortizationsStddevSampleMinYearDesc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_MIN_YEAR_DESC',
  MaxAmortizationsStddevSampleMaxYearAsc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_MAX_YEAR_ASC',
  MaxAmortizationsStddevSampleMaxYearDesc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_MAX_YEAR_DESC',
  MaxAmortizationsStddevSamplePersonalCoApplicantAsc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_PERSONAL_CO_APPLICANT_ASC',
  MaxAmortizationsStddevSamplePersonalCoApplicantDesc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_PERSONAL_CO_APPLICANT_DESC',
  MaxAmortizationsStddevSampleTermAsc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_TERM_ASC',
  MaxAmortizationsStddevSampleTermDesc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_TERM_DESC',
  MaxAmortizationsStddevSampleCreatedAtAsc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  MaxAmortizationsStddevSampleCreatedAtDesc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  MaxAmortizationsStddevSampleUpdatedAtAsc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  MaxAmortizationsStddevSampleUpdatedAtDesc = 'MAX_AMORTIZATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  MaxAmortizationsStddevPopulationIdAsc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_ID_ASC',
  MaxAmortizationsStddevPopulationIdDesc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_ID_DESC',
  MaxAmortizationsStddevPopulationLenderCreditKeyIdAsc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_LENDER_CREDIT_KEY_ID_ASC',
  MaxAmortizationsStddevPopulationLenderCreditKeyIdDesc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_LENDER_CREDIT_KEY_ID_DESC',
  MaxAmortizationsStddevPopulationMinYearAsc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_MIN_YEAR_ASC',
  MaxAmortizationsStddevPopulationMinYearDesc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_MIN_YEAR_DESC',
  MaxAmortizationsStddevPopulationMaxYearAsc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_MAX_YEAR_ASC',
  MaxAmortizationsStddevPopulationMaxYearDesc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_MAX_YEAR_DESC',
  MaxAmortizationsStddevPopulationPersonalCoApplicantAsc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_PERSONAL_CO_APPLICANT_ASC',
  MaxAmortizationsStddevPopulationPersonalCoApplicantDesc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_PERSONAL_CO_APPLICANT_DESC',
  MaxAmortizationsStddevPopulationTermAsc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_TERM_ASC',
  MaxAmortizationsStddevPopulationTermDesc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_TERM_DESC',
  MaxAmortizationsStddevPopulationCreatedAtAsc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  MaxAmortizationsStddevPopulationCreatedAtDesc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  MaxAmortizationsStddevPopulationUpdatedAtAsc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  MaxAmortizationsStddevPopulationUpdatedAtDesc = 'MAX_AMORTIZATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  MaxAmortizationsVarianceSampleIdAsc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_ID_ASC',
  MaxAmortizationsVarianceSampleIdDesc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_ID_DESC',
  MaxAmortizationsVarianceSampleLenderCreditKeyIdAsc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_LENDER_CREDIT_KEY_ID_ASC',
  MaxAmortizationsVarianceSampleLenderCreditKeyIdDesc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_LENDER_CREDIT_KEY_ID_DESC',
  MaxAmortizationsVarianceSampleMinYearAsc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_MIN_YEAR_ASC',
  MaxAmortizationsVarianceSampleMinYearDesc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_MIN_YEAR_DESC',
  MaxAmortizationsVarianceSampleMaxYearAsc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_MAX_YEAR_ASC',
  MaxAmortizationsVarianceSampleMaxYearDesc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_MAX_YEAR_DESC',
  MaxAmortizationsVarianceSamplePersonalCoApplicantAsc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_PERSONAL_CO_APPLICANT_ASC',
  MaxAmortizationsVarianceSamplePersonalCoApplicantDesc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_PERSONAL_CO_APPLICANT_DESC',
  MaxAmortizationsVarianceSampleTermAsc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_TERM_ASC',
  MaxAmortizationsVarianceSampleTermDesc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_TERM_DESC',
  MaxAmortizationsVarianceSampleCreatedAtAsc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  MaxAmortizationsVarianceSampleCreatedAtDesc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  MaxAmortizationsVarianceSampleUpdatedAtAsc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  MaxAmortizationsVarianceSampleUpdatedAtDesc = 'MAX_AMORTIZATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  MaxAmortizationsVariancePopulationIdAsc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_ID_ASC',
  MaxAmortizationsVariancePopulationIdDesc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_ID_DESC',
  MaxAmortizationsVariancePopulationLenderCreditKeyIdAsc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_LENDER_CREDIT_KEY_ID_ASC',
  MaxAmortizationsVariancePopulationLenderCreditKeyIdDesc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_LENDER_CREDIT_KEY_ID_DESC',
  MaxAmortizationsVariancePopulationMinYearAsc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_MIN_YEAR_ASC',
  MaxAmortizationsVariancePopulationMinYearDesc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_MIN_YEAR_DESC',
  MaxAmortizationsVariancePopulationMaxYearAsc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_MAX_YEAR_ASC',
  MaxAmortizationsVariancePopulationMaxYearDesc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_MAX_YEAR_DESC',
  MaxAmortizationsVariancePopulationPersonalCoApplicantAsc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_PERSONAL_CO_APPLICANT_ASC',
  MaxAmortizationsVariancePopulationPersonalCoApplicantDesc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_PERSONAL_CO_APPLICANT_DESC',
  MaxAmortizationsVariancePopulationTermAsc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_TERM_ASC',
  MaxAmortizationsVariancePopulationTermDesc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_TERM_DESC',
  MaxAmortizationsVariancePopulationCreatedAtAsc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  MaxAmortizationsVariancePopulationCreatedAtDesc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  MaxAmortizationsVariancePopulationUpdatedAtAsc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  MaxAmortizationsVariancePopulationUpdatedAtDesc = 'MAX_AMORTIZATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type LenderDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of bio across the matching connection */
  bio?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Lender` object types. All fields are combined with a logical ‘and.’ */
export type LenderFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `bio` field. */
  bio?: Maybe<StringFilter>;
  /** Filter by the object’s `equifaxLenders` relation. */
  equifaxLenders?: Maybe<LenderToManyEquifaxLenderFilter>;
  /** Some related `equifaxLenders` exist. */
  equifaxLendersExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lenderQuotes` relation. */
  lenderQuotes?: Maybe<LenderToManyLenderQuoteFilter>;
  /** Some related `lenderQuotes` exist. */
  lenderQuotesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lenderPrograms` relation. */
  lenderPrograms?: Maybe<LenderToManyLenderProgramFilter>;
  /** Some related `lenderPrograms` exist. */
  lenderProgramsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LenderFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LenderFilter>>;
  /** Negates the expression. */
  not?: Maybe<LenderFilter>;
};

/** Grouping methods for `Lender` for usage during aggregation. */
export enum LenderGroupBy {
  DisplayName = 'DISPLAY_NAME',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Bio = 'BIO'
}

export type LenderHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Lender` aggregates. */
export type LenderHavingInput = {
  AND?: Maybe<Array<LenderHavingInput>>;
  OR?: Maybe<Array<LenderHavingInput>>;
  sum?: Maybe<LenderHavingSumInput>;
  distinctCount?: Maybe<LenderHavingDistinctCountInput>;
  min?: Maybe<LenderHavingMinInput>;
  max?: Maybe<LenderHavingMaxInput>;
  average?: Maybe<LenderHavingAverageInput>;
  stddevSample?: Maybe<LenderHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LenderHavingStddevPopulationInput>;
  varianceSample?: Maybe<LenderHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LenderHavingVariancePopulationInput>;
};

export type LenderHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Lender` */
export type LenderInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  bio?: Maybe<Scalars['String']>;
  equifaxLendersUsingId?: Maybe<EquifaxLendersLenderIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderIdFkeyInverseInput>;
  lenderProgramsUsingId?: Maybe<LenderProgramLenderIdFkeyInverseInput>;
};

export enum LenderIntegrationStatus {
  Pending = 'Pending',
  Ready = 'Ready'
}

/** A connection to a list of `Lead` values, with data from `LenderQuote`. */
export type LenderLeadsByLenderQuoteLenderIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<LenderLeadsByLenderQuoteLenderIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `LenderQuote`. */
export type LenderLeadsByLenderQuoteLenderIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `LenderQuote`. */
export type LenderLeadsByLenderQuoteLenderIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `Lead` edge in the connection, with data from `LenderQuote`. */
export type LenderLeadsByLenderQuoteLenderIdAndLeadIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

/** A connection to a list of `LenderApp` values, with data from `LenderQuote`. */
export type LenderLenderAppsByLenderQuoteLenderIdAndLenderAppIdManyToManyConnection = {
  /** A list of `LenderApp` objects. */
  nodes: Array<LenderApp>;
  /** A list of edges which contains the `LenderApp`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<LenderLenderAppsByLenderQuoteLenderIdAndLenderAppIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LenderApp` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderAppAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderAppAggregates>>;
};


/** A connection to a list of `LenderApp` values, with data from `LenderQuote`. */
export type LenderLenderAppsByLenderQuoteLenderIdAndLenderAppIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderAppGroupBy>;
  having?: Maybe<LenderAppHavingInput>;
};

/** A `LenderApp` edge in the connection, with data from `LenderQuote`. */
export type LenderLenderAppsByLenderQuoteLenderIdAndLenderAppIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LenderApp` at the end of the edge. */
  node: LenderApp;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `LenderApp` edge in the connection, with data from `LenderQuote`. */
export type LenderLenderAppsByLenderQuoteLenderIdAndLenderAppIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

/** The fields on `lender` to look up the row to connect. */
export type LenderLenderNameKeyConnect = {
  name: Scalars['String'];
};

/** The fields on `lender` to look up the row to delete. */
export type LenderLenderNameKeyDelete = {
  name: Scalars['String'];
};

/** The fields on `lender` to look up the row to connect. */
export type LenderLenderPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `lender` to look up the row to delete. */
export type LenderLenderPkeyDelete = {
  id: Scalars['Int'];
};

export type LenderMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type LenderMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LenderNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lender` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LenderNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lender` to be deleted. */
  nodeId: Scalars['ID'];
};

export type LenderOffer = {
  offerId: Scalars['Int'];
  maxPti?: Maybe<Scalars['Float']>;
  maxTdsr?: Maybe<Scalars['Float']>;
  maxTerm?: Maybe<Scalars['Int']>;
  maxLtv?: Maybe<Scalars['Float']>;
  maxPayment?: Maybe<Scalars['Float']>;
  maxAmountFinanced?: Maybe<Scalars['Float']>;
  averageRate?: Maybe<Scalars['Float']>;
  estimatedMonthlyPayment?: Maybe<Scalars['Float']>;
  estimatedBiweeklyPayment?: Maybe<Scalars['Float']>;
  estimatedWeeklyPayment?: Maybe<Scalars['Float']>;
  maxPtiPassFail?: Maybe<Scalars['Int']>;
  maxTdsrPassFail?: Maybe<Scalars['Int']>;
  maxLtvPassFail?: Maybe<Scalars['Int']>;
  maxPaymentPassFail?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  lenderId?: Maybe<Scalars['String']>;
};

export type LenderOffers = {
  lenderAppId: Scalars['Int'];
  preferredOfferId?: Maybe<Scalars['Int']>;
  vehicleListing?: Maybe<VehicleListing>;
  offers?: Maybe<Array<Maybe<LenderOffer>>>;
};

/** The globally unique `ID` look up for the row to update. */
export type LenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `equifaxLender` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `equifaxLender` being updated. */
  patch: EquifaxLenderPatch;
};

/** The fields on `lender` to look up the row to update. */
export type LenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyUsingLenderNameKeyUpdate = {
  /** An object where the defined keys will be set on the `lender` being updated. */
  patch: UpdateLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `lender` to look up the row to update. */
export type LenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyUsingLenderPkeyUpdate = {
  /** An object where the defined keys will be set on the `lender` being updated. */
  patch: UpdateLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderOnLenderProgramForLenderProgramLenderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderProgram` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderProgram` being updated. */
  patch: LenderProgramPatch;
};

/** The fields on `lender` to look up the row to update. */
export type LenderOnLenderProgramForLenderProgramLenderIdFkeyUsingLenderNameKeyUpdate = {
  /** An object where the defined keys will be set on the `lender` being updated. */
  patch: UpdateLenderOnLenderProgramForLenderProgramLenderIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `lender` to look up the row to update. */
export type LenderOnLenderProgramForLenderProgramLenderIdFkeyUsingLenderPkeyUpdate = {
  /** An object where the defined keys will be set on the `lender` being updated. */
  patch: UpdateLenderOnLenderProgramForLenderProgramLenderIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderOnLenderQuoteForLenderQuotesLenderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderQuote` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: LenderQuotePatch;
};

/** The fields on `lender` to look up the row to update. */
export type LenderOnLenderQuoteForLenderQuotesLenderIdFkeyUsingLenderNameKeyUpdate = {
  /** An object where the defined keys will be set on the `lender` being updated. */
  patch: UpdateLenderOnLenderQuoteForLenderQuotesLenderIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `lender` to look up the row to update. */
export type LenderOnLenderQuoteForLenderQuotesLenderIdFkeyUsingLenderPkeyUpdate = {
  /** An object where the defined keys will be set on the `lender` being updated. */
  patch: UpdateLenderOnLenderQuoteForLenderQuotesLenderIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Lender`. Fields that are set will be updated. */
export type LenderPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  bio?: Maybe<Scalars['String']>;
  equifaxLendersUsingId?: Maybe<EquifaxLendersLenderIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderIdFkeyInverseInput>;
  lenderProgramsUsingId?: Maybe<LenderProgramLenderIdFkeyInverseInput>;
};

export type LenderProgram = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  lenderId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  enabled: Scalars['Boolean'];
  applicationRequirements: Array<Maybe<Scalars['String']>>;
  vehicleRequirements: Array<Maybe<Scalars['String']>>;
  minDate: Scalars['Datetime'];
  maxDate: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lender` that is related to this `LenderProgram`. */
  lender?: Maybe<Lender>;
  /** Reads and enables pagination through a set of `LenderCreditKey`. */
  lenderCreditKeys: LenderCreditKeysConnection;
  /** Reads and enables pagination through a set of `VehicleBooking`. */
  vehicleBookings: VehicleBookingsConnection;
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection;
};


export type LenderProgramLenderCreditKeysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderCreditKeysOrderBy>>;
  condition?: Maybe<LenderCreditKeyCondition>;
  filter?: Maybe<LenderCreditKeyFilter>;
};


export type LenderProgramVehicleBookingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleBookingsOrderBy>>;
  condition?: Maybe<VehicleBookingCondition>;
  filter?: Maybe<VehicleBookingFilter>;
};


export type LenderProgramFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};

export type LenderProgramAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LenderProgramSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LenderProgramDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LenderProgramMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LenderProgramMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LenderProgramAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LenderProgramStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LenderProgramStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LenderProgramVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LenderProgramVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `LenderProgram` object types. */
export type LenderProgramAggregatesFilter = {
  /** A filter that must pass for the relevant `LenderProgram` object to be included within the aggregate. */
  filter?: Maybe<LenderProgramFilter>;
  /** Sum aggregate over matching `LenderProgram` objects. */
  sum?: Maybe<LenderProgramSumAggregateFilter>;
  /** Distinct count aggregate over matching `LenderProgram` objects. */
  distinctCount?: Maybe<LenderProgramDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `LenderProgram` objects. */
  min?: Maybe<LenderProgramMinAggregateFilter>;
  /** Maximum aggregate over matching `LenderProgram` objects. */
  max?: Maybe<LenderProgramMaxAggregateFilter>;
  /** Mean average aggregate over matching `LenderProgram` objects. */
  average?: Maybe<LenderProgramAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LenderProgram` objects. */
  stddevSample?: Maybe<LenderProgramStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `LenderProgram` objects. */
  stddevPopulation?: Maybe<LenderProgramStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LenderProgram` objects. */
  varianceSample?: Maybe<LenderProgramVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `LenderProgram` objects. */
  variancePopulation?: Maybe<LenderProgramVariancePopulationAggregateFilter>;
};

export type LenderProgramAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
};

export type LenderProgramAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
};

export type LenderProgramBonus = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  creditQuality?: Maybe<Scalars['String']>;
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  bonus: Scalars['BigFloat'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `LenderCreditKey` that is related to this `LenderProgramBonus`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
};

export type LenderProgramBonusAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LenderProgramBonusSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LenderProgramBonusDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LenderProgramBonusMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LenderProgramBonusMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LenderProgramBonusAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LenderProgramBonusStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LenderProgramBonusStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LenderProgramBonusVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LenderProgramBonusVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `LenderProgramBonus` object types. */
export type LenderProgramBonusAggregatesFilter = {
  /** A filter that must pass for the relevant `LenderProgramBonus` object to be included within the aggregate. */
  filter?: Maybe<LenderProgramBonusFilter>;
  /** Sum aggregate over matching `LenderProgramBonus` objects. */
  sum?: Maybe<LenderProgramBonusSumAggregateFilter>;
  /** Distinct count aggregate over matching `LenderProgramBonus` objects. */
  distinctCount?: Maybe<LenderProgramBonusDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `LenderProgramBonus` objects. */
  min?: Maybe<LenderProgramBonusMinAggregateFilter>;
  /** Maximum aggregate over matching `LenderProgramBonus` objects. */
  max?: Maybe<LenderProgramBonusMaxAggregateFilter>;
  /** Mean average aggregate over matching `LenderProgramBonus` objects. */
  average?: Maybe<LenderProgramBonusAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LenderProgramBonus` objects. */
  stddevSample?: Maybe<LenderProgramBonusStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `LenderProgramBonus` objects. */
  stddevPopulation?: Maybe<LenderProgramBonusStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LenderProgramBonus` objects. */
  varianceSample?: Maybe<LenderProgramBonusVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `LenderProgramBonus` objects. */
  variancePopulation?: Maybe<LenderProgramBonusVariancePopulationAggregateFilter>;
};

export type LenderProgramBonusAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  bonus?: Maybe<BigFloatFilter>;
};

export type LenderProgramBonusAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Mean average of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Mean average of bonus across the matching connection */
  bonus?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LenderProgramBonus` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LenderProgramBonusCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lenderCreditKeyId` field. */
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minPrice` field. */
  minPrice?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxPrice` field. */
  maxPrice?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `creditQuality` field. */
  creditQuality?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `personalCoApplicant` field. */
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `bonus` field. */
  bonus?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type LenderProgramBonusDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderCreditKeyId?: Maybe<BigIntFilter>;
  minPrice?: Maybe<BigIntFilter>;
  maxPrice?: Maybe<BigIntFilter>;
  creditQuality?: Maybe<BigIntFilter>;
  personalCoApplicant?: Maybe<BigIntFilter>;
  bonus?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type LenderProgramBonusDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditQuality across the matching connection */
  creditQuality?: Maybe<Scalars['BigInt']>;
  /** Distinct count of personalCoApplicant across the matching connection */
  personalCoApplicant?: Maybe<Scalars['BigInt']>;
  /** Distinct count of bonus across the matching connection */
  bonus?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LenderProgramBonus` object types. All fields are combined with a logical ‘and.’ */
export type LenderProgramBonusFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `lenderCreditKeyId` field. */
  lenderCreditKeyId?: Maybe<IntFilter>;
  /** Filter by the object’s `minPrice` field. */
  minPrice?: Maybe<IntFilter>;
  /** Filter by the object’s `maxPrice` field. */
  maxPrice?: Maybe<IntFilter>;
  /** Filter by the object’s `creditQuality` field. */
  creditQuality?: Maybe<StringFilter>;
  /** Filter by the object’s `personalCoApplicant` field. */
  personalCoApplicant?: Maybe<BooleanFilter>;
  /** Filter by the object’s `bonus` field. */
  bonus?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lenderCreditKey` relation. */
  lenderCreditKey?: Maybe<LenderCreditKeyFilter>;
  /** A related `lenderCreditKey` exists. */
  lenderCreditKeyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LenderProgramBonusFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LenderProgramBonusFilter>>;
  /** Negates the expression. */
  not?: Maybe<LenderProgramBonusFilter>;
};

/** Grouping methods for `LenderProgramBonus` for usage during aggregation. */
export enum LenderProgramBonusGroupBy {
  LenderCreditKeyId = 'LENDER_CREDIT_KEY_ID',
  MinPrice = 'MIN_PRICE',
  MaxPrice = 'MAX_PRICE',
  CreditQuality = 'CREDIT_QUALITY',
  PersonalCoApplicant = 'PERSONAL_CO_APPLICANT',
  Bonus = 'BONUS',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type LenderProgramBonusHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  bonus?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramBonusHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  bonus?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `LenderProgramBonus` aggregates. */
export type LenderProgramBonusHavingInput = {
  AND?: Maybe<Array<LenderProgramBonusHavingInput>>;
  OR?: Maybe<Array<LenderProgramBonusHavingInput>>;
  sum?: Maybe<LenderProgramBonusHavingSumInput>;
  distinctCount?: Maybe<LenderProgramBonusHavingDistinctCountInput>;
  min?: Maybe<LenderProgramBonusHavingMinInput>;
  max?: Maybe<LenderProgramBonusHavingMaxInput>;
  average?: Maybe<LenderProgramBonusHavingAverageInput>;
  stddevSample?: Maybe<LenderProgramBonusHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LenderProgramBonusHavingStddevPopulationInput>;
  varianceSample?: Maybe<LenderProgramBonusHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LenderProgramBonusHavingVariancePopulationInput>;
};

export type LenderProgramBonusHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  bonus?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramBonusHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  bonus?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramBonusHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  bonus?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramBonusHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  bonus?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramBonusHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  bonus?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramBonusHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  bonus?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramBonusHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  bonus?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `LenderProgramBonus` */
export type LenderProgramBonusInput = {
  id?: Maybe<Scalars['Int']>;
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  creditQuality?: Maybe<Scalars['String']>;
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  bonus: Scalars['BigFloat'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInput>;
};

/** Input for the nested mutation of `lenderCreditKey` in the `LenderProgramBonusInput` mutation. */
export type LenderProgramBonusLenderCreditKeyIdFkeyInput = {
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  connectById?: Maybe<LenderCreditKeyLenderCreditKeyPkeyConnect>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  connectByNodeId?: Maybe<LenderCreditKeyNodeIdConnect>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  deleteById?: Maybe<LenderCreditKeyLenderCreditKeyPkeyDelete>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LenderCreditKeyNodeIdDelete>;
  /** The primary key(s) and patch data for `lenderCreditKey` for the far side of the relationship. */
  updateById?: Maybe<LenderCreditKeyOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyUsingLenderCreditKeyPkeyUpdate>;
  /** The primary key(s) and patch data for `lenderCreditKey` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderProgramBonusOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyNodeIdUpdate>;
  /** A `LenderCreditKeyInput` object that will be created and connected to this object. */
  create?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyLenderCreditKeyCreateInput>;
};

/** Input for the nested mutation of `lenderProgramBonus` in the `LenderCreditKeyInput` mutation. */
export type LenderProgramBonusLenderCreditKeyIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderProgramBonus` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderProgramBonus` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderProgramBonusLenderProgramBonusPkeyConnect>>;
  /** The primary key(s) for `lenderProgramBonus` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderProgramBonusNodeIdConnect>>;
  /** The primary key(s) for `lenderProgramBonus` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderProgramBonusLenderProgramBonusPkeyDelete>>;
  /** The primary key(s) for `lenderProgramBonus` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderProgramBonusNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderProgramBonus` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderProgramBonusOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyUsingLenderProgramBonusPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderProgramBonus` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LenderCreditKeyOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyNodeIdUpdate>>;
  /** A `LenderProgramBonusInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderProgramBonusLenderCreditKeyIdFkeyLenderProgramBonusCreateInput>>;
};

/** The `lenderCreditKey` to be created by this mutation. */
export type LenderProgramBonusLenderCreditKeyIdFkeyLenderCreditKeyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  minScore?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInput>;
  ratesUsingId?: Maybe<RateLenderCreditKeyIdFkeyInverseInput>;
  lenderProgramBonusesUsingId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInverseInput>;
  maxAmortizationsUsingId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInverseInput>;
};

/** The `lenderProgramBonus` to be created by this mutation. */
export type LenderProgramBonusLenderCreditKeyIdFkeyLenderProgramBonusCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  creditQuality?: Maybe<Scalars['String']>;
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  bonus: Scalars['BigFloat'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInput>;
};

/** The fields on `lenderProgramBonus` to look up the row to connect. */
export type LenderProgramBonusLenderProgramBonusPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `lenderProgramBonus` to look up the row to delete. */
export type LenderProgramBonusLenderProgramBonusPkeyDelete = {
  id: Scalars['Int'];
};

export type LenderProgramBonusMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderCreditKeyId?: Maybe<IntFilter>;
  minPrice?: Maybe<IntFilter>;
  maxPrice?: Maybe<IntFilter>;
  bonus?: Maybe<BigFloatFilter>;
};

export type LenderProgramBonusMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  /** Maximum of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['Int']>;
  /** Maximum of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['Int']>;
  /** Maximum of bonus across the matching connection */
  bonus?: Maybe<Scalars['BigFloat']>;
};

export type LenderProgramBonusMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderCreditKeyId?: Maybe<IntFilter>;
  minPrice?: Maybe<IntFilter>;
  maxPrice?: Maybe<IntFilter>;
  bonus?: Maybe<BigFloatFilter>;
};

export type LenderProgramBonusMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  /** Minimum of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['Int']>;
  /** Minimum of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['Int']>;
  /** Minimum of bonus across the matching connection */
  bonus?: Maybe<Scalars['BigFloat']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LenderProgramBonusNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lenderProgramBonus` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LenderProgramBonusNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lenderProgramBonus` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderProgramBonusOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderCreditKey` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
  patch: LenderCreditKeyPatch;
};

/** The fields on `lenderProgramBonus` to look up the row to update. */
export type LenderProgramBonusOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyUsingLenderProgramBonusPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderProgramBonus` being updated. */
  patch: UpdateLenderProgramBonusOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `LenderProgramBonus`. Fields that are set will be updated. */
export type LenderProgramBonusPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  creditQuality?: Maybe<Scalars['String']>;
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  bonus?: Maybe<Scalars['BigFloat']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInput>;
};

export type LenderProgramBonusStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  bonus?: Maybe<BigFloatFilter>;
};

export type LenderProgramBonusStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of bonus across the matching connection */
  bonus?: Maybe<Scalars['BigFloat']>;
};

export type LenderProgramBonusStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  bonus?: Maybe<BigFloatFilter>;
};

export type LenderProgramBonusStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of bonus across the matching connection */
  bonus?: Maybe<Scalars['BigFloat']>;
};

export type LenderProgramBonusSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderCreditKeyId?: Maybe<BigIntFilter>;
  minPrice?: Maybe<BigIntFilter>;
  maxPrice?: Maybe<BigIntFilter>;
  bonus?: Maybe<BigFloatFilter>;
};

export type LenderProgramBonusSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId: Scalars['BigInt'];
  /** Sum of minPrice across the matching connection */
  minPrice: Scalars['BigInt'];
  /** Sum of maxPrice across the matching connection */
  maxPrice: Scalars['BigInt'];
  /** Sum of bonus across the matching connection */
  bonus: Scalars['BigFloat'];
};

export type LenderProgramBonusVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  bonus?: Maybe<BigFloatFilter>;
};

export type LenderProgramBonusVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Population variance of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Population variance of bonus across the matching connection */
  bonus?: Maybe<Scalars['BigFloat']>;
};

export type LenderProgramBonusVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  bonus?: Maybe<BigFloatFilter>;
};

export type LenderProgramBonusVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of bonus across the matching connection */
  bonus?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LenderProgramBonus` values. */
export type LenderProgramBonusesConnection = {
  /** A list of `LenderProgramBonus` objects. */
  nodes: Array<LenderProgramBonus>;
  /** A list of edges which contains the `LenderProgramBonus` and cursor to aid in pagination. */
  edges: Array<LenderProgramBonusesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LenderProgramBonus` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderProgramBonusAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderProgramBonusAggregates>>;
};


/** A connection to a list of `LenderProgramBonus` values. */
export type LenderProgramBonusesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderProgramBonusGroupBy>;
  having?: Maybe<LenderProgramBonusHavingInput>;
};

/** A `LenderProgramBonus` edge in the connection. */
export type LenderProgramBonusesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LenderProgramBonus` at the end of the edge. */
  node: LenderProgramBonus;
};

/** Methods to use when ordering `LenderProgramBonus`. */
export enum LenderProgramBonusesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LenderCreditKeyIdAsc = 'LENDER_CREDIT_KEY_ID_ASC',
  LenderCreditKeyIdDesc = 'LENDER_CREDIT_KEY_ID_DESC',
  MinPriceAsc = 'MIN_PRICE_ASC',
  MinPriceDesc = 'MIN_PRICE_DESC',
  MaxPriceAsc = 'MAX_PRICE_ASC',
  MaxPriceDesc = 'MAX_PRICE_DESC',
  CreditQualityAsc = 'CREDIT_QUALITY_ASC',
  CreditQualityDesc = 'CREDIT_QUALITY_DESC',
  PersonalCoApplicantAsc = 'PERSONAL_CO_APPLICANT_ASC',
  PersonalCoApplicantDesc = 'PERSONAL_CO_APPLICANT_DESC',
  BonusAsc = 'BONUS_ASC',
  BonusDesc = 'BONUS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `LenderProgram` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LenderProgramCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lenderId` field. */
  lenderId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `applicationRequirements` field. */
  applicationRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `vehicleRequirements` field. */
  vehicleRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `minDate` field. */
  minDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `maxDate` field. */
  maxDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type LenderProgramDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderId?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  displayName?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  applicationRequirements?: Maybe<BigIntFilter>;
  vehicleRequirements?: Maybe<BigIntFilter>;
  minDate?: Maybe<BigIntFilter>;
  maxDate?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type LenderProgramDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of applicationRequirements across the matching connection */
  applicationRequirements?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleRequirements across the matching connection */
  vehicleRequirements?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minDate across the matching connection */
  minDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxDate across the matching connection */
  maxDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LenderProgram` object types. All fields are combined with a logical ‘and.’ */
export type LenderProgramFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `lenderId` field. */
  lenderId?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `applicationRequirements` field. */
  applicationRequirements?: Maybe<StringListFilter>;
  /** Filter by the object’s `vehicleRequirements` field. */
  vehicleRequirements?: Maybe<StringListFilter>;
  /** Filter by the object’s `minDate` field. */
  minDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `maxDate` field. */
  maxDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lenderCreditKeys` relation. */
  lenderCreditKeys?: Maybe<LenderProgramToManyLenderCreditKeyFilter>;
  /** Some related `lenderCreditKeys` exist. */
  lenderCreditKeysExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `vehicleBookings` relation. */
  vehicleBookings?: Maybe<LenderProgramToManyVehicleBookingFilter>;
  /** Some related `vehicleBookings` exist. */
  vehicleBookingsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `files` relation. */
  files?: Maybe<LenderProgramToManyFileFilter>;
  /** Some related `files` exist. */
  filesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lender` relation. */
  lender?: Maybe<LenderFilter>;
  /** A related `lender` exists. */
  lenderExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LenderProgramFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LenderProgramFilter>>;
  /** Negates the expression. */
  not?: Maybe<LenderProgramFilter>;
};

/** Grouping methods for `LenderProgram` for usage during aggregation. */
export enum LenderProgramGroupBy {
  LenderId = 'LENDER_ID',
  Name = 'NAME',
  DisplayName = 'DISPLAY_NAME',
  Enabled = 'ENABLED',
  ApplicationRequirements = 'APPLICATION_REQUIREMENTS',
  VehicleRequirements = 'VEHICLE_REQUIREMENTS',
  MinDate = 'MIN_DATE',
  MinDateTruncatedToHour = 'MIN_DATE_TRUNCATED_TO_HOUR',
  MinDateTruncatedToDay = 'MIN_DATE_TRUNCATED_TO_DAY',
  MaxDate = 'MAX_DATE',
  MaxDateTruncatedToHour = 'MAX_DATE_TRUNCATED_TO_HOUR',
  MaxDateTruncatedToDay = 'MAX_DATE_TRUNCATED_TO_DAY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type LenderProgramHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  minDate?: Maybe<HavingDatetimeFilter>;
  maxDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  minDate?: Maybe<HavingDatetimeFilter>;
  maxDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `LenderProgram` aggregates. */
export type LenderProgramHavingInput = {
  AND?: Maybe<Array<LenderProgramHavingInput>>;
  OR?: Maybe<Array<LenderProgramHavingInput>>;
  sum?: Maybe<LenderProgramHavingSumInput>;
  distinctCount?: Maybe<LenderProgramHavingDistinctCountInput>;
  min?: Maybe<LenderProgramHavingMinInput>;
  max?: Maybe<LenderProgramHavingMaxInput>;
  average?: Maybe<LenderProgramHavingAverageInput>;
  stddevSample?: Maybe<LenderProgramHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LenderProgramHavingStddevPopulationInput>;
  varianceSample?: Maybe<LenderProgramHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LenderProgramHavingVariancePopulationInput>;
};

export type LenderProgramHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  minDate?: Maybe<HavingDatetimeFilter>;
  maxDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  minDate?: Maybe<HavingDatetimeFilter>;
  maxDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  minDate?: Maybe<HavingDatetimeFilter>;
  maxDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  minDate?: Maybe<HavingDatetimeFilter>;
  maxDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  minDate?: Maybe<HavingDatetimeFilter>;
  maxDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  minDate?: Maybe<HavingDatetimeFilter>;
  maxDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderProgramHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  minDate?: Maybe<HavingDatetimeFilter>;
  maxDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `LenderProgram` */
export type LenderProgramInput = {
  id?: Maybe<Scalars['Int']>;
  lenderId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  applicationRequirements: Array<Maybe<Scalars['String']>>;
  vehicleRequirements: Array<Maybe<Scalars['String']>>;
  minDate?: Maybe<Scalars['Datetime']>;
  maxDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderToLenderId?: Maybe<LenderProgramLenderIdFkeyInput>;
  lenderCreditKeysUsingId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInverseInput>;
  vehicleBookingsUsingId?: Maybe<VehicleBookingLenderProgramIdFkeyInverseInput>;
  filesUsingId?: Maybe<FileLenderProgramIdFkeyInverseInput>;
};

/** Input for the nested mutation of `lender` in the `LenderProgramInput` mutation. */
export type LenderProgramLenderIdFkeyInput = {
  /** The primary key(s) for `lender` for the far side of the relationship. */
  connectById?: Maybe<LenderLenderPkeyConnect>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  connectByName?: Maybe<LenderLenderNameKeyConnect>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  connectByNodeId?: Maybe<LenderNodeIdConnect>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  deleteById?: Maybe<LenderLenderPkeyDelete>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  deleteByName?: Maybe<LenderLenderNameKeyDelete>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LenderNodeIdDelete>;
  /** The primary key(s) and patch data for `lender` for the far side of the relationship. */
  updateById?: Maybe<LenderOnLenderProgramForLenderProgramLenderIdFkeyUsingLenderPkeyUpdate>;
  /** The primary key(s) and patch data for `lender` for the far side of the relationship. */
  updateByName?: Maybe<LenderOnLenderProgramForLenderProgramLenderIdFkeyUsingLenderNameKeyUpdate>;
  /** The primary key(s) and patch data for `lender` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderProgramOnLenderProgramForLenderProgramLenderIdFkeyNodeIdUpdate>;
  /** A `LenderInput` object that will be created and connected to this object. */
  create?: Maybe<LenderProgramLenderIdFkeyLenderCreateInput>;
};

/** Input for the nested mutation of `lenderProgram` in the `LenderInput` mutation. */
export type LenderProgramLenderIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderProgram` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderProgramLenderProgramPkeyConnect>>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderProgramNodeIdConnect>>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderProgramLenderProgramPkeyDelete>>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderProgramNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderProgram` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderProgramOnLenderProgramForLenderProgramLenderIdFkeyUsingLenderProgramPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderProgram` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LenderOnLenderProgramForLenderProgramLenderIdFkeyNodeIdUpdate>>;
  /** A `LenderProgramInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderProgramLenderIdFkeyLenderProgramCreateInput>>;
};

/** The `lender` to be created by this mutation. */
export type LenderProgramLenderIdFkeyLenderCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  bio?: Maybe<Scalars['String']>;
  equifaxLendersUsingId?: Maybe<EquifaxLendersLenderIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderIdFkeyInverseInput>;
  lenderProgramsUsingId?: Maybe<LenderProgramLenderIdFkeyInverseInput>;
};

/** The `lenderProgram` to be created by this mutation. */
export type LenderProgramLenderIdFkeyLenderProgramCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  applicationRequirements: Array<Maybe<Scalars['String']>>;
  vehicleRequirements: Array<Maybe<Scalars['String']>>;
  minDate?: Maybe<Scalars['Datetime']>;
  maxDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderToLenderId?: Maybe<LenderProgramLenderIdFkeyInput>;
  lenderCreditKeysUsingId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInverseInput>;
  vehicleBookingsUsingId?: Maybe<VehicleBookingLenderProgramIdFkeyInverseInput>;
  filesUsingId?: Maybe<FileLenderProgramIdFkeyInverseInput>;
};

/** The fields on `lenderProgram` to look up the row to connect. */
export type LenderProgramLenderProgramPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `lenderProgram` to look up the row to delete. */
export type LenderProgramLenderProgramPkeyDelete = {
  id: Scalars['Int'];
};

export type LenderProgramMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderId?: Maybe<IntFilter>;
};

export type LenderProgramMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['Int']>;
};

export type LenderProgramMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderId?: Maybe<IntFilter>;
};

export type LenderProgramMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LenderProgramNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lenderProgram` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LenderProgramNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lenderProgram` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderProgramOnFileForFileLenderProgramIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `lenderProgram` to look up the row to update. */
export type LenderProgramOnFileForFileLenderProgramIdFkeyUsingLenderProgramPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderProgram` being updated. */
  patch: UpdateLenderProgramOnFileForFileLenderProgramIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderProgramOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderCreditKey` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
  patch: LenderCreditKeyPatch;
};

/** The fields on `lenderProgram` to look up the row to update. */
export type LenderProgramOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyUsingLenderProgramPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderProgram` being updated. */
  patch: UpdateLenderProgramOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderProgramOnLenderProgramForLenderProgramLenderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lender` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lender` being updated. */
  patch: LenderPatch;
};

/** The fields on `lenderProgram` to look up the row to update. */
export type LenderProgramOnLenderProgramForLenderProgramLenderIdFkeyUsingLenderProgramPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderProgram` being updated. */
  patch: UpdateLenderProgramOnLenderProgramForLenderProgramLenderIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderProgramOnVehicleBookingForVehicleBookingLenderProgramIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `vehicleBooking` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `vehicleBooking` being updated. */
  patch: VehicleBookingPatch;
};

/** The fields on `lenderProgram` to look up the row to update. */
export type LenderProgramOnVehicleBookingForVehicleBookingLenderProgramIdFkeyUsingLenderProgramPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderProgram` being updated. */
  patch: UpdateLenderProgramOnVehicleBookingForVehicleBookingLenderProgramIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `LenderProgram`. Fields that are set will be updated. */
export type LenderProgramPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  applicationRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  vehicleRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  minDate?: Maybe<Scalars['Datetime']>;
  maxDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderToLenderId?: Maybe<LenderProgramLenderIdFkeyInput>;
  lenderCreditKeysUsingId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInverseInput>;
  vehicleBookingsUsingId?: Maybe<VehicleBookingLenderProgramIdFkeyInverseInput>;
  filesUsingId?: Maybe<FileLenderProgramIdFkeyInverseInput>;
};

export type LenderProgramStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
};

export type LenderProgramStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
};

export type LenderProgramStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
};

export type LenderProgramStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
};

export type LenderProgramSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderId?: Maybe<BigIntFilter>;
};

export type LenderProgramSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lenderId across the matching connection */
  lenderId: Scalars['BigInt'];
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type LenderProgramToManyFileFilter = {
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FileFilter>;
  /** Aggregates across related `File` match the filter criteria. */
  aggregates?: Maybe<FileAggregatesFilter>;
};

/** A filter to be used against many `LenderCreditKey` object types. All fields are combined with a logical ‘and.’ */
export type LenderProgramToManyLenderCreditKeyFilter = {
  /** Every related `LenderCreditKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderCreditKeyFilter>;
  /** Some related `LenderCreditKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderCreditKeyFilter>;
  /** No related `LenderCreditKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderCreditKeyFilter>;
  /** Aggregates across related `LenderCreditKey` match the filter criteria. */
  aggregates?: Maybe<LenderCreditKeyAggregatesFilter>;
};

/** A filter to be used against many `VehicleBooking` object types. All fields are combined with a logical ‘and.’ */
export type LenderProgramToManyVehicleBookingFilter = {
  /** Every related `VehicleBooking` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<VehicleBookingFilter>;
  /** Some related `VehicleBooking` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<VehicleBookingFilter>;
  /** No related `VehicleBooking` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<VehicleBookingFilter>;
  /** Aggregates across related `VehicleBooking` match the filter criteria. */
  aggregates?: Maybe<VehicleBookingAggregatesFilter>;
};

export type LenderProgramVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
};

export type LenderProgramVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
};

export type LenderProgramVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
};

export type LenderProgramVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LenderProgram` values. */
export type LenderProgramsConnection = {
  /** A list of `LenderProgram` objects. */
  nodes: Array<LenderProgram>;
  /** A list of edges which contains the `LenderProgram` and cursor to aid in pagination. */
  edges: Array<LenderProgramsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LenderProgram` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderProgramAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderProgramAggregates>>;
};


/** A connection to a list of `LenderProgram` values. */
export type LenderProgramsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderProgramGroupBy>;
  having?: Maybe<LenderProgramHavingInput>;
};

/** A `LenderProgram` edge in the connection. */
export type LenderProgramsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LenderProgram` at the end of the edge. */
  node: LenderProgram;
};

/** Methods to use when ordering `LenderProgram`. */
export enum LenderProgramsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LenderIdAsc = 'LENDER_ID_ASC',
  LenderIdDesc = 'LENDER_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  ApplicationRequirementsAsc = 'APPLICATION_REQUIREMENTS_ASC',
  ApplicationRequirementsDesc = 'APPLICATION_REQUIREMENTS_DESC',
  VehicleRequirementsAsc = 'VEHICLE_REQUIREMENTS_ASC',
  VehicleRequirementsDesc = 'VEHICLE_REQUIREMENTS_DESC',
  MinDateAsc = 'MIN_DATE_ASC',
  MinDateDesc = 'MIN_DATE_DESC',
  MaxDateAsc = 'MAX_DATE_ASC',
  MaxDateDesc = 'MAX_DATE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  LenderCreditKeysCountAsc = 'LENDER_CREDIT_KEYS_COUNT_ASC',
  LenderCreditKeysCountDesc = 'LENDER_CREDIT_KEYS_COUNT_DESC',
  LenderCreditKeysSumIdAsc = 'LENDER_CREDIT_KEYS_SUM_ID_ASC',
  LenderCreditKeysSumIdDesc = 'LENDER_CREDIT_KEYS_SUM_ID_DESC',
  LenderCreditKeysSumLenderProgramIdAsc = 'LENDER_CREDIT_KEYS_SUM_LENDER_PROGRAM_ID_ASC',
  LenderCreditKeysSumLenderProgramIdDesc = 'LENDER_CREDIT_KEYS_SUM_LENDER_PROGRAM_ID_DESC',
  LenderCreditKeysSumNameAsc = 'LENDER_CREDIT_KEYS_SUM_NAME_ASC',
  LenderCreditKeysSumNameDesc = 'LENDER_CREDIT_KEYS_SUM_NAME_DESC',
  LenderCreditKeysSumDisplayNameAsc = 'LENDER_CREDIT_KEYS_SUM_DISPLAY_NAME_ASC',
  LenderCreditKeysSumDisplayNameDesc = 'LENDER_CREDIT_KEYS_SUM_DISPLAY_NAME_DESC',
  LenderCreditKeysSumMinScoreAsc = 'LENDER_CREDIT_KEYS_SUM_MIN_SCORE_ASC',
  LenderCreditKeysSumMinScoreDesc = 'LENDER_CREDIT_KEYS_SUM_MIN_SCORE_DESC',
  LenderCreditKeysSumMaxScoreAsc = 'LENDER_CREDIT_KEYS_SUM_MAX_SCORE_ASC',
  LenderCreditKeysSumMaxScoreDesc = 'LENDER_CREDIT_KEYS_SUM_MAX_SCORE_DESC',
  LenderCreditKeysSumCreatedAtAsc = 'LENDER_CREDIT_KEYS_SUM_CREATED_AT_ASC',
  LenderCreditKeysSumCreatedAtDesc = 'LENDER_CREDIT_KEYS_SUM_CREATED_AT_DESC',
  LenderCreditKeysSumUpdatedAtAsc = 'LENDER_CREDIT_KEYS_SUM_UPDATED_AT_ASC',
  LenderCreditKeysSumUpdatedAtDesc = 'LENDER_CREDIT_KEYS_SUM_UPDATED_AT_DESC',
  LenderCreditKeysDistinctCountIdAsc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_ID_ASC',
  LenderCreditKeysDistinctCountIdDesc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_ID_DESC',
  LenderCreditKeysDistinctCountLenderProgramIdAsc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_LENDER_PROGRAM_ID_ASC',
  LenderCreditKeysDistinctCountLenderProgramIdDesc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_LENDER_PROGRAM_ID_DESC',
  LenderCreditKeysDistinctCountNameAsc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_NAME_ASC',
  LenderCreditKeysDistinctCountNameDesc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_NAME_DESC',
  LenderCreditKeysDistinctCountDisplayNameAsc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_DISPLAY_NAME_ASC',
  LenderCreditKeysDistinctCountDisplayNameDesc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_DISPLAY_NAME_DESC',
  LenderCreditKeysDistinctCountMinScoreAsc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_MIN_SCORE_ASC',
  LenderCreditKeysDistinctCountMinScoreDesc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_MIN_SCORE_DESC',
  LenderCreditKeysDistinctCountMaxScoreAsc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_MAX_SCORE_ASC',
  LenderCreditKeysDistinctCountMaxScoreDesc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_MAX_SCORE_DESC',
  LenderCreditKeysDistinctCountCreatedAtAsc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderCreditKeysDistinctCountCreatedAtDesc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderCreditKeysDistinctCountUpdatedAtAsc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderCreditKeysDistinctCountUpdatedAtDesc = 'LENDER_CREDIT_KEYS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderCreditKeysMinIdAsc = 'LENDER_CREDIT_KEYS_MIN_ID_ASC',
  LenderCreditKeysMinIdDesc = 'LENDER_CREDIT_KEYS_MIN_ID_DESC',
  LenderCreditKeysMinLenderProgramIdAsc = 'LENDER_CREDIT_KEYS_MIN_LENDER_PROGRAM_ID_ASC',
  LenderCreditKeysMinLenderProgramIdDesc = 'LENDER_CREDIT_KEYS_MIN_LENDER_PROGRAM_ID_DESC',
  LenderCreditKeysMinNameAsc = 'LENDER_CREDIT_KEYS_MIN_NAME_ASC',
  LenderCreditKeysMinNameDesc = 'LENDER_CREDIT_KEYS_MIN_NAME_DESC',
  LenderCreditKeysMinDisplayNameAsc = 'LENDER_CREDIT_KEYS_MIN_DISPLAY_NAME_ASC',
  LenderCreditKeysMinDisplayNameDesc = 'LENDER_CREDIT_KEYS_MIN_DISPLAY_NAME_DESC',
  LenderCreditKeysMinMinScoreAsc = 'LENDER_CREDIT_KEYS_MIN_MIN_SCORE_ASC',
  LenderCreditKeysMinMinScoreDesc = 'LENDER_CREDIT_KEYS_MIN_MIN_SCORE_DESC',
  LenderCreditKeysMinMaxScoreAsc = 'LENDER_CREDIT_KEYS_MIN_MAX_SCORE_ASC',
  LenderCreditKeysMinMaxScoreDesc = 'LENDER_CREDIT_KEYS_MIN_MAX_SCORE_DESC',
  LenderCreditKeysMinCreatedAtAsc = 'LENDER_CREDIT_KEYS_MIN_CREATED_AT_ASC',
  LenderCreditKeysMinCreatedAtDesc = 'LENDER_CREDIT_KEYS_MIN_CREATED_AT_DESC',
  LenderCreditKeysMinUpdatedAtAsc = 'LENDER_CREDIT_KEYS_MIN_UPDATED_AT_ASC',
  LenderCreditKeysMinUpdatedAtDesc = 'LENDER_CREDIT_KEYS_MIN_UPDATED_AT_DESC',
  LenderCreditKeysMaxIdAsc = 'LENDER_CREDIT_KEYS_MAX_ID_ASC',
  LenderCreditKeysMaxIdDesc = 'LENDER_CREDIT_KEYS_MAX_ID_DESC',
  LenderCreditKeysMaxLenderProgramIdAsc = 'LENDER_CREDIT_KEYS_MAX_LENDER_PROGRAM_ID_ASC',
  LenderCreditKeysMaxLenderProgramIdDesc = 'LENDER_CREDIT_KEYS_MAX_LENDER_PROGRAM_ID_DESC',
  LenderCreditKeysMaxNameAsc = 'LENDER_CREDIT_KEYS_MAX_NAME_ASC',
  LenderCreditKeysMaxNameDesc = 'LENDER_CREDIT_KEYS_MAX_NAME_DESC',
  LenderCreditKeysMaxDisplayNameAsc = 'LENDER_CREDIT_KEYS_MAX_DISPLAY_NAME_ASC',
  LenderCreditKeysMaxDisplayNameDesc = 'LENDER_CREDIT_KEYS_MAX_DISPLAY_NAME_DESC',
  LenderCreditKeysMaxMinScoreAsc = 'LENDER_CREDIT_KEYS_MAX_MIN_SCORE_ASC',
  LenderCreditKeysMaxMinScoreDesc = 'LENDER_CREDIT_KEYS_MAX_MIN_SCORE_DESC',
  LenderCreditKeysMaxMaxScoreAsc = 'LENDER_CREDIT_KEYS_MAX_MAX_SCORE_ASC',
  LenderCreditKeysMaxMaxScoreDesc = 'LENDER_CREDIT_KEYS_MAX_MAX_SCORE_DESC',
  LenderCreditKeysMaxCreatedAtAsc = 'LENDER_CREDIT_KEYS_MAX_CREATED_AT_ASC',
  LenderCreditKeysMaxCreatedAtDesc = 'LENDER_CREDIT_KEYS_MAX_CREATED_AT_DESC',
  LenderCreditKeysMaxUpdatedAtAsc = 'LENDER_CREDIT_KEYS_MAX_UPDATED_AT_ASC',
  LenderCreditKeysMaxUpdatedAtDesc = 'LENDER_CREDIT_KEYS_MAX_UPDATED_AT_DESC',
  LenderCreditKeysAverageIdAsc = 'LENDER_CREDIT_KEYS_AVERAGE_ID_ASC',
  LenderCreditKeysAverageIdDesc = 'LENDER_CREDIT_KEYS_AVERAGE_ID_DESC',
  LenderCreditKeysAverageLenderProgramIdAsc = 'LENDER_CREDIT_KEYS_AVERAGE_LENDER_PROGRAM_ID_ASC',
  LenderCreditKeysAverageLenderProgramIdDesc = 'LENDER_CREDIT_KEYS_AVERAGE_LENDER_PROGRAM_ID_DESC',
  LenderCreditKeysAverageNameAsc = 'LENDER_CREDIT_KEYS_AVERAGE_NAME_ASC',
  LenderCreditKeysAverageNameDesc = 'LENDER_CREDIT_KEYS_AVERAGE_NAME_DESC',
  LenderCreditKeysAverageDisplayNameAsc = 'LENDER_CREDIT_KEYS_AVERAGE_DISPLAY_NAME_ASC',
  LenderCreditKeysAverageDisplayNameDesc = 'LENDER_CREDIT_KEYS_AVERAGE_DISPLAY_NAME_DESC',
  LenderCreditKeysAverageMinScoreAsc = 'LENDER_CREDIT_KEYS_AVERAGE_MIN_SCORE_ASC',
  LenderCreditKeysAverageMinScoreDesc = 'LENDER_CREDIT_KEYS_AVERAGE_MIN_SCORE_DESC',
  LenderCreditKeysAverageMaxScoreAsc = 'LENDER_CREDIT_KEYS_AVERAGE_MAX_SCORE_ASC',
  LenderCreditKeysAverageMaxScoreDesc = 'LENDER_CREDIT_KEYS_AVERAGE_MAX_SCORE_DESC',
  LenderCreditKeysAverageCreatedAtAsc = 'LENDER_CREDIT_KEYS_AVERAGE_CREATED_AT_ASC',
  LenderCreditKeysAverageCreatedAtDesc = 'LENDER_CREDIT_KEYS_AVERAGE_CREATED_AT_DESC',
  LenderCreditKeysAverageUpdatedAtAsc = 'LENDER_CREDIT_KEYS_AVERAGE_UPDATED_AT_ASC',
  LenderCreditKeysAverageUpdatedAtDesc = 'LENDER_CREDIT_KEYS_AVERAGE_UPDATED_AT_DESC',
  LenderCreditKeysStddevSampleIdAsc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_ID_ASC',
  LenderCreditKeysStddevSampleIdDesc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_ID_DESC',
  LenderCreditKeysStddevSampleLenderProgramIdAsc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_LENDER_PROGRAM_ID_ASC',
  LenderCreditKeysStddevSampleLenderProgramIdDesc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_LENDER_PROGRAM_ID_DESC',
  LenderCreditKeysStddevSampleNameAsc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_NAME_ASC',
  LenderCreditKeysStddevSampleNameDesc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_NAME_DESC',
  LenderCreditKeysStddevSampleDisplayNameAsc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_DISPLAY_NAME_ASC',
  LenderCreditKeysStddevSampleDisplayNameDesc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_DISPLAY_NAME_DESC',
  LenderCreditKeysStddevSampleMinScoreAsc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_MIN_SCORE_ASC',
  LenderCreditKeysStddevSampleMinScoreDesc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_MIN_SCORE_DESC',
  LenderCreditKeysStddevSampleMaxScoreAsc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_MAX_SCORE_ASC',
  LenderCreditKeysStddevSampleMaxScoreDesc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_MAX_SCORE_DESC',
  LenderCreditKeysStddevSampleCreatedAtAsc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderCreditKeysStddevSampleCreatedAtDesc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderCreditKeysStddevSampleUpdatedAtAsc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderCreditKeysStddevSampleUpdatedAtDesc = 'LENDER_CREDIT_KEYS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderCreditKeysStddevPopulationIdAsc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_ID_ASC',
  LenderCreditKeysStddevPopulationIdDesc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_ID_DESC',
  LenderCreditKeysStddevPopulationLenderProgramIdAsc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_LENDER_PROGRAM_ID_ASC',
  LenderCreditKeysStddevPopulationLenderProgramIdDesc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_LENDER_PROGRAM_ID_DESC',
  LenderCreditKeysStddevPopulationNameAsc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_NAME_ASC',
  LenderCreditKeysStddevPopulationNameDesc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_NAME_DESC',
  LenderCreditKeysStddevPopulationDisplayNameAsc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_DISPLAY_NAME_ASC',
  LenderCreditKeysStddevPopulationDisplayNameDesc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_DISPLAY_NAME_DESC',
  LenderCreditKeysStddevPopulationMinScoreAsc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_MIN_SCORE_ASC',
  LenderCreditKeysStddevPopulationMinScoreDesc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_MIN_SCORE_DESC',
  LenderCreditKeysStddevPopulationMaxScoreAsc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_MAX_SCORE_ASC',
  LenderCreditKeysStddevPopulationMaxScoreDesc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_MAX_SCORE_DESC',
  LenderCreditKeysStddevPopulationCreatedAtAsc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderCreditKeysStddevPopulationCreatedAtDesc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderCreditKeysStddevPopulationUpdatedAtAsc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderCreditKeysStddevPopulationUpdatedAtDesc = 'LENDER_CREDIT_KEYS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderCreditKeysVarianceSampleIdAsc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_ID_ASC',
  LenderCreditKeysVarianceSampleIdDesc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_ID_DESC',
  LenderCreditKeysVarianceSampleLenderProgramIdAsc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_LENDER_PROGRAM_ID_ASC',
  LenderCreditKeysVarianceSampleLenderProgramIdDesc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_LENDER_PROGRAM_ID_DESC',
  LenderCreditKeysVarianceSampleNameAsc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_NAME_ASC',
  LenderCreditKeysVarianceSampleNameDesc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_NAME_DESC',
  LenderCreditKeysVarianceSampleDisplayNameAsc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_DISPLAY_NAME_ASC',
  LenderCreditKeysVarianceSampleDisplayNameDesc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_DISPLAY_NAME_DESC',
  LenderCreditKeysVarianceSampleMinScoreAsc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_MIN_SCORE_ASC',
  LenderCreditKeysVarianceSampleMinScoreDesc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_MIN_SCORE_DESC',
  LenderCreditKeysVarianceSampleMaxScoreAsc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_MAX_SCORE_ASC',
  LenderCreditKeysVarianceSampleMaxScoreDesc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_MAX_SCORE_DESC',
  LenderCreditKeysVarianceSampleCreatedAtAsc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderCreditKeysVarianceSampleCreatedAtDesc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderCreditKeysVarianceSampleUpdatedAtAsc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderCreditKeysVarianceSampleUpdatedAtDesc = 'LENDER_CREDIT_KEYS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderCreditKeysVariancePopulationIdAsc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_ID_ASC',
  LenderCreditKeysVariancePopulationIdDesc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_ID_DESC',
  LenderCreditKeysVariancePopulationLenderProgramIdAsc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_LENDER_PROGRAM_ID_ASC',
  LenderCreditKeysVariancePopulationLenderProgramIdDesc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_LENDER_PROGRAM_ID_DESC',
  LenderCreditKeysVariancePopulationNameAsc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_NAME_ASC',
  LenderCreditKeysVariancePopulationNameDesc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_NAME_DESC',
  LenderCreditKeysVariancePopulationDisplayNameAsc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_DISPLAY_NAME_ASC',
  LenderCreditKeysVariancePopulationDisplayNameDesc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_DISPLAY_NAME_DESC',
  LenderCreditKeysVariancePopulationMinScoreAsc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_MIN_SCORE_ASC',
  LenderCreditKeysVariancePopulationMinScoreDesc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_MIN_SCORE_DESC',
  LenderCreditKeysVariancePopulationMaxScoreAsc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_MAX_SCORE_ASC',
  LenderCreditKeysVariancePopulationMaxScoreDesc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_MAX_SCORE_DESC',
  LenderCreditKeysVariancePopulationCreatedAtAsc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderCreditKeysVariancePopulationCreatedAtDesc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderCreditKeysVariancePopulationUpdatedAtAsc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderCreditKeysVariancePopulationUpdatedAtDesc = 'LENDER_CREDIT_KEYS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  VehicleBookingsCountAsc = 'VEHICLE_BOOKINGS_COUNT_ASC',
  VehicleBookingsCountDesc = 'VEHICLE_BOOKINGS_COUNT_DESC',
  VehicleBookingsSumIdAsc = 'VEHICLE_BOOKINGS_SUM_ID_ASC',
  VehicleBookingsSumIdDesc = 'VEHICLE_BOOKINGS_SUM_ID_DESC',
  VehicleBookingsSumLenderProgramIdAsc = 'VEHICLE_BOOKINGS_SUM_LENDER_PROGRAM_ID_ASC',
  VehicleBookingsSumLenderProgramIdDesc = 'VEHICLE_BOOKINGS_SUM_LENDER_PROGRAM_ID_DESC',
  VehicleBookingsSumMinKmAsc = 'VEHICLE_BOOKINGS_SUM_MIN_KM_ASC',
  VehicleBookingsSumMinKmDesc = 'VEHICLE_BOOKINGS_SUM_MIN_KM_DESC',
  VehicleBookingsSumMaxKmAsc = 'VEHICLE_BOOKINGS_SUM_MAX_KM_ASC',
  VehicleBookingsSumMaxKmDesc = 'VEHICLE_BOOKINGS_SUM_MAX_KM_DESC',
  VehicleBookingsSumMinYearAsc = 'VEHICLE_BOOKINGS_SUM_MIN_YEAR_ASC',
  VehicleBookingsSumMinYearDesc = 'VEHICLE_BOOKINGS_SUM_MIN_YEAR_DESC',
  VehicleBookingsSumMaxYearAsc = 'VEHICLE_BOOKINGS_SUM_MAX_YEAR_ASC',
  VehicleBookingsSumMaxYearDesc = 'VEHICLE_BOOKINGS_SUM_MAX_YEAR_DESC',
  VehicleBookingsSumIsNewAsc = 'VEHICLE_BOOKINGS_SUM_IS_NEW_ASC',
  VehicleBookingsSumIsNewDesc = 'VEHICLE_BOOKINGS_SUM_IS_NEW_DESC',
  VehicleBookingsSumCbbKeyAsc = 'VEHICLE_BOOKINGS_SUM_CBB_KEY_ASC',
  VehicleBookingsSumCbbKeyDesc = 'VEHICLE_BOOKINGS_SUM_CBB_KEY_DESC',
  VehicleBookingsSumTermAsc = 'VEHICLE_BOOKINGS_SUM_TERM_ASC',
  VehicleBookingsSumTermDesc = 'VEHICLE_BOOKINGS_SUM_TERM_DESC',
  VehicleBookingsSumCreatedAtAsc = 'VEHICLE_BOOKINGS_SUM_CREATED_AT_ASC',
  VehicleBookingsSumCreatedAtDesc = 'VEHICLE_BOOKINGS_SUM_CREATED_AT_DESC',
  VehicleBookingsSumUpdatedAtAsc = 'VEHICLE_BOOKINGS_SUM_UPDATED_AT_ASC',
  VehicleBookingsSumUpdatedAtDesc = 'VEHICLE_BOOKINGS_SUM_UPDATED_AT_DESC',
  VehicleBookingsDistinctCountIdAsc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_ID_ASC',
  VehicleBookingsDistinctCountIdDesc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_ID_DESC',
  VehicleBookingsDistinctCountLenderProgramIdAsc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_LENDER_PROGRAM_ID_ASC',
  VehicleBookingsDistinctCountLenderProgramIdDesc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_LENDER_PROGRAM_ID_DESC',
  VehicleBookingsDistinctCountMinKmAsc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_MIN_KM_ASC',
  VehicleBookingsDistinctCountMinKmDesc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_MIN_KM_DESC',
  VehicleBookingsDistinctCountMaxKmAsc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_MAX_KM_ASC',
  VehicleBookingsDistinctCountMaxKmDesc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_MAX_KM_DESC',
  VehicleBookingsDistinctCountMinYearAsc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_MIN_YEAR_ASC',
  VehicleBookingsDistinctCountMinYearDesc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_MIN_YEAR_DESC',
  VehicleBookingsDistinctCountMaxYearAsc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_MAX_YEAR_ASC',
  VehicleBookingsDistinctCountMaxYearDesc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_MAX_YEAR_DESC',
  VehicleBookingsDistinctCountIsNewAsc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_IS_NEW_ASC',
  VehicleBookingsDistinctCountIsNewDesc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_IS_NEW_DESC',
  VehicleBookingsDistinctCountCbbKeyAsc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_CBB_KEY_ASC',
  VehicleBookingsDistinctCountCbbKeyDesc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_CBB_KEY_DESC',
  VehicleBookingsDistinctCountTermAsc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_TERM_ASC',
  VehicleBookingsDistinctCountTermDesc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_TERM_DESC',
  VehicleBookingsDistinctCountCreatedAtAsc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_CREATED_AT_ASC',
  VehicleBookingsDistinctCountCreatedAtDesc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_CREATED_AT_DESC',
  VehicleBookingsDistinctCountUpdatedAtAsc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_UPDATED_AT_ASC',
  VehicleBookingsDistinctCountUpdatedAtDesc = 'VEHICLE_BOOKINGS_DISTINCT_COUNT_UPDATED_AT_DESC',
  VehicleBookingsMinIdAsc = 'VEHICLE_BOOKINGS_MIN_ID_ASC',
  VehicleBookingsMinIdDesc = 'VEHICLE_BOOKINGS_MIN_ID_DESC',
  VehicleBookingsMinLenderProgramIdAsc = 'VEHICLE_BOOKINGS_MIN_LENDER_PROGRAM_ID_ASC',
  VehicleBookingsMinLenderProgramIdDesc = 'VEHICLE_BOOKINGS_MIN_LENDER_PROGRAM_ID_DESC',
  VehicleBookingsMinMinKmAsc = 'VEHICLE_BOOKINGS_MIN_MIN_KM_ASC',
  VehicleBookingsMinMinKmDesc = 'VEHICLE_BOOKINGS_MIN_MIN_KM_DESC',
  VehicleBookingsMinMaxKmAsc = 'VEHICLE_BOOKINGS_MIN_MAX_KM_ASC',
  VehicleBookingsMinMaxKmDesc = 'VEHICLE_BOOKINGS_MIN_MAX_KM_DESC',
  VehicleBookingsMinMinYearAsc = 'VEHICLE_BOOKINGS_MIN_MIN_YEAR_ASC',
  VehicleBookingsMinMinYearDesc = 'VEHICLE_BOOKINGS_MIN_MIN_YEAR_DESC',
  VehicleBookingsMinMaxYearAsc = 'VEHICLE_BOOKINGS_MIN_MAX_YEAR_ASC',
  VehicleBookingsMinMaxYearDesc = 'VEHICLE_BOOKINGS_MIN_MAX_YEAR_DESC',
  VehicleBookingsMinIsNewAsc = 'VEHICLE_BOOKINGS_MIN_IS_NEW_ASC',
  VehicleBookingsMinIsNewDesc = 'VEHICLE_BOOKINGS_MIN_IS_NEW_DESC',
  VehicleBookingsMinCbbKeyAsc = 'VEHICLE_BOOKINGS_MIN_CBB_KEY_ASC',
  VehicleBookingsMinCbbKeyDesc = 'VEHICLE_BOOKINGS_MIN_CBB_KEY_DESC',
  VehicleBookingsMinTermAsc = 'VEHICLE_BOOKINGS_MIN_TERM_ASC',
  VehicleBookingsMinTermDesc = 'VEHICLE_BOOKINGS_MIN_TERM_DESC',
  VehicleBookingsMinCreatedAtAsc = 'VEHICLE_BOOKINGS_MIN_CREATED_AT_ASC',
  VehicleBookingsMinCreatedAtDesc = 'VEHICLE_BOOKINGS_MIN_CREATED_AT_DESC',
  VehicleBookingsMinUpdatedAtAsc = 'VEHICLE_BOOKINGS_MIN_UPDATED_AT_ASC',
  VehicleBookingsMinUpdatedAtDesc = 'VEHICLE_BOOKINGS_MIN_UPDATED_AT_DESC',
  VehicleBookingsMaxIdAsc = 'VEHICLE_BOOKINGS_MAX_ID_ASC',
  VehicleBookingsMaxIdDesc = 'VEHICLE_BOOKINGS_MAX_ID_DESC',
  VehicleBookingsMaxLenderProgramIdAsc = 'VEHICLE_BOOKINGS_MAX_LENDER_PROGRAM_ID_ASC',
  VehicleBookingsMaxLenderProgramIdDesc = 'VEHICLE_BOOKINGS_MAX_LENDER_PROGRAM_ID_DESC',
  VehicleBookingsMaxMinKmAsc = 'VEHICLE_BOOKINGS_MAX_MIN_KM_ASC',
  VehicleBookingsMaxMinKmDesc = 'VEHICLE_BOOKINGS_MAX_MIN_KM_DESC',
  VehicleBookingsMaxMaxKmAsc = 'VEHICLE_BOOKINGS_MAX_MAX_KM_ASC',
  VehicleBookingsMaxMaxKmDesc = 'VEHICLE_BOOKINGS_MAX_MAX_KM_DESC',
  VehicleBookingsMaxMinYearAsc = 'VEHICLE_BOOKINGS_MAX_MIN_YEAR_ASC',
  VehicleBookingsMaxMinYearDesc = 'VEHICLE_BOOKINGS_MAX_MIN_YEAR_DESC',
  VehicleBookingsMaxMaxYearAsc = 'VEHICLE_BOOKINGS_MAX_MAX_YEAR_ASC',
  VehicleBookingsMaxMaxYearDesc = 'VEHICLE_BOOKINGS_MAX_MAX_YEAR_DESC',
  VehicleBookingsMaxIsNewAsc = 'VEHICLE_BOOKINGS_MAX_IS_NEW_ASC',
  VehicleBookingsMaxIsNewDesc = 'VEHICLE_BOOKINGS_MAX_IS_NEW_DESC',
  VehicleBookingsMaxCbbKeyAsc = 'VEHICLE_BOOKINGS_MAX_CBB_KEY_ASC',
  VehicleBookingsMaxCbbKeyDesc = 'VEHICLE_BOOKINGS_MAX_CBB_KEY_DESC',
  VehicleBookingsMaxTermAsc = 'VEHICLE_BOOKINGS_MAX_TERM_ASC',
  VehicleBookingsMaxTermDesc = 'VEHICLE_BOOKINGS_MAX_TERM_DESC',
  VehicleBookingsMaxCreatedAtAsc = 'VEHICLE_BOOKINGS_MAX_CREATED_AT_ASC',
  VehicleBookingsMaxCreatedAtDesc = 'VEHICLE_BOOKINGS_MAX_CREATED_AT_DESC',
  VehicleBookingsMaxUpdatedAtAsc = 'VEHICLE_BOOKINGS_MAX_UPDATED_AT_ASC',
  VehicleBookingsMaxUpdatedAtDesc = 'VEHICLE_BOOKINGS_MAX_UPDATED_AT_DESC',
  VehicleBookingsAverageIdAsc = 'VEHICLE_BOOKINGS_AVERAGE_ID_ASC',
  VehicleBookingsAverageIdDesc = 'VEHICLE_BOOKINGS_AVERAGE_ID_DESC',
  VehicleBookingsAverageLenderProgramIdAsc = 'VEHICLE_BOOKINGS_AVERAGE_LENDER_PROGRAM_ID_ASC',
  VehicleBookingsAverageLenderProgramIdDesc = 'VEHICLE_BOOKINGS_AVERAGE_LENDER_PROGRAM_ID_DESC',
  VehicleBookingsAverageMinKmAsc = 'VEHICLE_BOOKINGS_AVERAGE_MIN_KM_ASC',
  VehicleBookingsAverageMinKmDesc = 'VEHICLE_BOOKINGS_AVERAGE_MIN_KM_DESC',
  VehicleBookingsAverageMaxKmAsc = 'VEHICLE_BOOKINGS_AVERAGE_MAX_KM_ASC',
  VehicleBookingsAverageMaxKmDesc = 'VEHICLE_BOOKINGS_AVERAGE_MAX_KM_DESC',
  VehicleBookingsAverageMinYearAsc = 'VEHICLE_BOOKINGS_AVERAGE_MIN_YEAR_ASC',
  VehicleBookingsAverageMinYearDesc = 'VEHICLE_BOOKINGS_AVERAGE_MIN_YEAR_DESC',
  VehicleBookingsAverageMaxYearAsc = 'VEHICLE_BOOKINGS_AVERAGE_MAX_YEAR_ASC',
  VehicleBookingsAverageMaxYearDesc = 'VEHICLE_BOOKINGS_AVERAGE_MAX_YEAR_DESC',
  VehicleBookingsAverageIsNewAsc = 'VEHICLE_BOOKINGS_AVERAGE_IS_NEW_ASC',
  VehicleBookingsAverageIsNewDesc = 'VEHICLE_BOOKINGS_AVERAGE_IS_NEW_DESC',
  VehicleBookingsAverageCbbKeyAsc = 'VEHICLE_BOOKINGS_AVERAGE_CBB_KEY_ASC',
  VehicleBookingsAverageCbbKeyDesc = 'VEHICLE_BOOKINGS_AVERAGE_CBB_KEY_DESC',
  VehicleBookingsAverageTermAsc = 'VEHICLE_BOOKINGS_AVERAGE_TERM_ASC',
  VehicleBookingsAverageTermDesc = 'VEHICLE_BOOKINGS_AVERAGE_TERM_DESC',
  VehicleBookingsAverageCreatedAtAsc = 'VEHICLE_BOOKINGS_AVERAGE_CREATED_AT_ASC',
  VehicleBookingsAverageCreatedAtDesc = 'VEHICLE_BOOKINGS_AVERAGE_CREATED_AT_DESC',
  VehicleBookingsAverageUpdatedAtAsc = 'VEHICLE_BOOKINGS_AVERAGE_UPDATED_AT_ASC',
  VehicleBookingsAverageUpdatedAtDesc = 'VEHICLE_BOOKINGS_AVERAGE_UPDATED_AT_DESC',
  VehicleBookingsStddevSampleIdAsc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_ID_ASC',
  VehicleBookingsStddevSampleIdDesc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_ID_DESC',
  VehicleBookingsStddevSampleLenderProgramIdAsc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_LENDER_PROGRAM_ID_ASC',
  VehicleBookingsStddevSampleLenderProgramIdDesc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_LENDER_PROGRAM_ID_DESC',
  VehicleBookingsStddevSampleMinKmAsc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_MIN_KM_ASC',
  VehicleBookingsStddevSampleMinKmDesc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_MIN_KM_DESC',
  VehicleBookingsStddevSampleMaxKmAsc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_MAX_KM_ASC',
  VehicleBookingsStddevSampleMaxKmDesc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_MAX_KM_DESC',
  VehicleBookingsStddevSampleMinYearAsc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_MIN_YEAR_ASC',
  VehicleBookingsStddevSampleMinYearDesc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_MIN_YEAR_DESC',
  VehicleBookingsStddevSampleMaxYearAsc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_MAX_YEAR_ASC',
  VehicleBookingsStddevSampleMaxYearDesc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_MAX_YEAR_DESC',
  VehicleBookingsStddevSampleIsNewAsc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_IS_NEW_ASC',
  VehicleBookingsStddevSampleIsNewDesc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_IS_NEW_DESC',
  VehicleBookingsStddevSampleCbbKeyAsc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_CBB_KEY_ASC',
  VehicleBookingsStddevSampleCbbKeyDesc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_CBB_KEY_DESC',
  VehicleBookingsStddevSampleTermAsc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_TERM_ASC',
  VehicleBookingsStddevSampleTermDesc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_TERM_DESC',
  VehicleBookingsStddevSampleCreatedAtAsc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_CREATED_AT_ASC',
  VehicleBookingsStddevSampleCreatedAtDesc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_CREATED_AT_DESC',
  VehicleBookingsStddevSampleUpdatedAtAsc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  VehicleBookingsStddevSampleUpdatedAtDesc = 'VEHICLE_BOOKINGS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  VehicleBookingsStddevPopulationIdAsc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_ID_ASC',
  VehicleBookingsStddevPopulationIdDesc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_ID_DESC',
  VehicleBookingsStddevPopulationLenderProgramIdAsc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_LENDER_PROGRAM_ID_ASC',
  VehicleBookingsStddevPopulationLenderProgramIdDesc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_LENDER_PROGRAM_ID_DESC',
  VehicleBookingsStddevPopulationMinKmAsc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_MIN_KM_ASC',
  VehicleBookingsStddevPopulationMinKmDesc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_MIN_KM_DESC',
  VehicleBookingsStddevPopulationMaxKmAsc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_MAX_KM_ASC',
  VehicleBookingsStddevPopulationMaxKmDesc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_MAX_KM_DESC',
  VehicleBookingsStddevPopulationMinYearAsc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_MIN_YEAR_ASC',
  VehicleBookingsStddevPopulationMinYearDesc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_MIN_YEAR_DESC',
  VehicleBookingsStddevPopulationMaxYearAsc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_MAX_YEAR_ASC',
  VehicleBookingsStddevPopulationMaxYearDesc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_MAX_YEAR_DESC',
  VehicleBookingsStddevPopulationIsNewAsc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_IS_NEW_ASC',
  VehicleBookingsStddevPopulationIsNewDesc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_IS_NEW_DESC',
  VehicleBookingsStddevPopulationCbbKeyAsc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_CBB_KEY_ASC',
  VehicleBookingsStddevPopulationCbbKeyDesc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_CBB_KEY_DESC',
  VehicleBookingsStddevPopulationTermAsc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_TERM_ASC',
  VehicleBookingsStddevPopulationTermDesc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_TERM_DESC',
  VehicleBookingsStddevPopulationCreatedAtAsc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_CREATED_AT_ASC',
  VehicleBookingsStddevPopulationCreatedAtDesc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_CREATED_AT_DESC',
  VehicleBookingsStddevPopulationUpdatedAtAsc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_UPDATED_AT_ASC',
  VehicleBookingsStddevPopulationUpdatedAtDesc = 'VEHICLE_BOOKINGS_STDDEV_POPULATION_UPDATED_AT_DESC',
  VehicleBookingsVarianceSampleIdAsc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_ID_ASC',
  VehicleBookingsVarianceSampleIdDesc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_ID_DESC',
  VehicleBookingsVarianceSampleLenderProgramIdAsc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_LENDER_PROGRAM_ID_ASC',
  VehicleBookingsVarianceSampleLenderProgramIdDesc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_LENDER_PROGRAM_ID_DESC',
  VehicleBookingsVarianceSampleMinKmAsc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_MIN_KM_ASC',
  VehicleBookingsVarianceSampleMinKmDesc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_MIN_KM_DESC',
  VehicleBookingsVarianceSampleMaxKmAsc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_MAX_KM_ASC',
  VehicleBookingsVarianceSampleMaxKmDesc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_MAX_KM_DESC',
  VehicleBookingsVarianceSampleMinYearAsc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_MIN_YEAR_ASC',
  VehicleBookingsVarianceSampleMinYearDesc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_MIN_YEAR_DESC',
  VehicleBookingsVarianceSampleMaxYearAsc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_MAX_YEAR_ASC',
  VehicleBookingsVarianceSampleMaxYearDesc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_MAX_YEAR_DESC',
  VehicleBookingsVarianceSampleIsNewAsc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_IS_NEW_ASC',
  VehicleBookingsVarianceSampleIsNewDesc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_IS_NEW_DESC',
  VehicleBookingsVarianceSampleCbbKeyAsc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_CBB_KEY_ASC',
  VehicleBookingsVarianceSampleCbbKeyDesc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_CBB_KEY_DESC',
  VehicleBookingsVarianceSampleTermAsc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_TERM_ASC',
  VehicleBookingsVarianceSampleTermDesc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_TERM_DESC',
  VehicleBookingsVarianceSampleCreatedAtAsc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  VehicleBookingsVarianceSampleCreatedAtDesc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  VehicleBookingsVarianceSampleUpdatedAtAsc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  VehicleBookingsVarianceSampleUpdatedAtDesc = 'VEHICLE_BOOKINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  VehicleBookingsVariancePopulationIdAsc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_ID_ASC',
  VehicleBookingsVariancePopulationIdDesc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_ID_DESC',
  VehicleBookingsVariancePopulationLenderProgramIdAsc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_LENDER_PROGRAM_ID_ASC',
  VehicleBookingsVariancePopulationLenderProgramIdDesc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_LENDER_PROGRAM_ID_DESC',
  VehicleBookingsVariancePopulationMinKmAsc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_MIN_KM_ASC',
  VehicleBookingsVariancePopulationMinKmDesc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_MIN_KM_DESC',
  VehicleBookingsVariancePopulationMaxKmAsc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_MAX_KM_ASC',
  VehicleBookingsVariancePopulationMaxKmDesc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_MAX_KM_DESC',
  VehicleBookingsVariancePopulationMinYearAsc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_MIN_YEAR_ASC',
  VehicleBookingsVariancePopulationMinYearDesc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_MIN_YEAR_DESC',
  VehicleBookingsVariancePopulationMaxYearAsc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_MAX_YEAR_ASC',
  VehicleBookingsVariancePopulationMaxYearDesc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_MAX_YEAR_DESC',
  VehicleBookingsVariancePopulationIsNewAsc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_IS_NEW_ASC',
  VehicleBookingsVariancePopulationIsNewDesc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_IS_NEW_DESC',
  VehicleBookingsVariancePopulationCbbKeyAsc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_CBB_KEY_ASC',
  VehicleBookingsVariancePopulationCbbKeyDesc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_CBB_KEY_DESC',
  VehicleBookingsVariancePopulationTermAsc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_TERM_ASC',
  VehicleBookingsVariancePopulationTermDesc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_TERM_DESC',
  VehicleBookingsVariancePopulationCreatedAtAsc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_CREATED_AT_ASC',
  VehicleBookingsVariancePopulationCreatedAtDesc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_CREATED_AT_DESC',
  VehicleBookingsVariancePopulationUpdatedAtAsc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  VehicleBookingsVariancePopulationUpdatedAtDesc = 'VEHICLE_BOOKINGS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesCountAsc = 'FILES_COUNT_ASC',
  FilesCountDesc = 'FILES_COUNT_DESC',
  FilesSumIdAsc = 'FILES_SUM_ID_ASC',
  FilesSumIdDesc = 'FILES_SUM_ID_DESC',
  FilesSumLenderProgramIdAsc = 'FILES_SUM_LENDER_PROGRAM_ID_ASC',
  FilesSumLenderProgramIdDesc = 'FILES_SUM_LENDER_PROGRAM_ID_DESC',
  FilesSumNameAsc = 'FILES_SUM_NAME_ASC',
  FilesSumNameDesc = 'FILES_SUM_NAME_DESC',
  FilesSumDisplayNameAsc = 'FILES_SUM_DISPLAY_NAME_ASC',
  FilesSumDisplayNameDesc = 'FILES_SUM_DISPLAY_NAME_DESC',
  FilesSumUrlAsc = 'FILES_SUM_URL_ASC',
  FilesSumUrlDesc = 'FILES_SUM_URL_DESC',
  FilesSumCreatedAtAsc = 'FILES_SUM_CREATED_AT_ASC',
  FilesSumCreatedAtDesc = 'FILES_SUM_CREATED_AT_DESC',
  FilesSumUpdatedAtAsc = 'FILES_SUM_UPDATED_AT_ASC',
  FilesSumUpdatedAtDesc = 'FILES_SUM_UPDATED_AT_DESC',
  FilesDistinctCountIdAsc = 'FILES_DISTINCT_COUNT_ID_ASC',
  FilesDistinctCountIdDesc = 'FILES_DISTINCT_COUNT_ID_DESC',
  FilesDistinctCountLenderProgramIdAsc = 'FILES_DISTINCT_COUNT_LENDER_PROGRAM_ID_ASC',
  FilesDistinctCountLenderProgramIdDesc = 'FILES_DISTINCT_COUNT_LENDER_PROGRAM_ID_DESC',
  FilesDistinctCountNameAsc = 'FILES_DISTINCT_COUNT_NAME_ASC',
  FilesDistinctCountNameDesc = 'FILES_DISTINCT_COUNT_NAME_DESC',
  FilesDistinctCountDisplayNameAsc = 'FILES_DISTINCT_COUNT_DISPLAY_NAME_ASC',
  FilesDistinctCountDisplayNameDesc = 'FILES_DISTINCT_COUNT_DISPLAY_NAME_DESC',
  FilesDistinctCountUrlAsc = 'FILES_DISTINCT_COUNT_URL_ASC',
  FilesDistinctCountUrlDesc = 'FILES_DISTINCT_COUNT_URL_DESC',
  FilesDistinctCountCreatedAtAsc = 'FILES_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesDistinctCountCreatedAtDesc = 'FILES_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesDistinctCountUpdatedAtAsc = 'FILES_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesDistinctCountUpdatedAtDesc = 'FILES_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesMinIdAsc = 'FILES_MIN_ID_ASC',
  FilesMinIdDesc = 'FILES_MIN_ID_DESC',
  FilesMinLenderProgramIdAsc = 'FILES_MIN_LENDER_PROGRAM_ID_ASC',
  FilesMinLenderProgramIdDesc = 'FILES_MIN_LENDER_PROGRAM_ID_DESC',
  FilesMinNameAsc = 'FILES_MIN_NAME_ASC',
  FilesMinNameDesc = 'FILES_MIN_NAME_DESC',
  FilesMinDisplayNameAsc = 'FILES_MIN_DISPLAY_NAME_ASC',
  FilesMinDisplayNameDesc = 'FILES_MIN_DISPLAY_NAME_DESC',
  FilesMinUrlAsc = 'FILES_MIN_URL_ASC',
  FilesMinUrlDesc = 'FILES_MIN_URL_DESC',
  FilesMinCreatedAtAsc = 'FILES_MIN_CREATED_AT_ASC',
  FilesMinCreatedAtDesc = 'FILES_MIN_CREATED_AT_DESC',
  FilesMinUpdatedAtAsc = 'FILES_MIN_UPDATED_AT_ASC',
  FilesMinUpdatedAtDesc = 'FILES_MIN_UPDATED_AT_DESC',
  FilesMaxIdAsc = 'FILES_MAX_ID_ASC',
  FilesMaxIdDesc = 'FILES_MAX_ID_DESC',
  FilesMaxLenderProgramIdAsc = 'FILES_MAX_LENDER_PROGRAM_ID_ASC',
  FilesMaxLenderProgramIdDesc = 'FILES_MAX_LENDER_PROGRAM_ID_DESC',
  FilesMaxNameAsc = 'FILES_MAX_NAME_ASC',
  FilesMaxNameDesc = 'FILES_MAX_NAME_DESC',
  FilesMaxDisplayNameAsc = 'FILES_MAX_DISPLAY_NAME_ASC',
  FilesMaxDisplayNameDesc = 'FILES_MAX_DISPLAY_NAME_DESC',
  FilesMaxUrlAsc = 'FILES_MAX_URL_ASC',
  FilesMaxUrlDesc = 'FILES_MAX_URL_DESC',
  FilesMaxCreatedAtAsc = 'FILES_MAX_CREATED_AT_ASC',
  FilesMaxCreatedAtDesc = 'FILES_MAX_CREATED_AT_DESC',
  FilesMaxUpdatedAtAsc = 'FILES_MAX_UPDATED_AT_ASC',
  FilesMaxUpdatedAtDesc = 'FILES_MAX_UPDATED_AT_DESC',
  FilesAverageIdAsc = 'FILES_AVERAGE_ID_ASC',
  FilesAverageIdDesc = 'FILES_AVERAGE_ID_DESC',
  FilesAverageLenderProgramIdAsc = 'FILES_AVERAGE_LENDER_PROGRAM_ID_ASC',
  FilesAverageLenderProgramIdDesc = 'FILES_AVERAGE_LENDER_PROGRAM_ID_DESC',
  FilesAverageNameAsc = 'FILES_AVERAGE_NAME_ASC',
  FilesAverageNameDesc = 'FILES_AVERAGE_NAME_DESC',
  FilesAverageDisplayNameAsc = 'FILES_AVERAGE_DISPLAY_NAME_ASC',
  FilesAverageDisplayNameDesc = 'FILES_AVERAGE_DISPLAY_NAME_DESC',
  FilesAverageUrlAsc = 'FILES_AVERAGE_URL_ASC',
  FilesAverageUrlDesc = 'FILES_AVERAGE_URL_DESC',
  FilesAverageCreatedAtAsc = 'FILES_AVERAGE_CREATED_AT_ASC',
  FilesAverageCreatedAtDesc = 'FILES_AVERAGE_CREATED_AT_DESC',
  FilesAverageUpdatedAtAsc = 'FILES_AVERAGE_UPDATED_AT_ASC',
  FilesAverageUpdatedAtDesc = 'FILES_AVERAGE_UPDATED_AT_DESC',
  FilesStddevSampleIdAsc = 'FILES_STDDEV_SAMPLE_ID_ASC',
  FilesStddevSampleIdDesc = 'FILES_STDDEV_SAMPLE_ID_DESC',
  FilesStddevSampleLenderProgramIdAsc = 'FILES_STDDEV_SAMPLE_LENDER_PROGRAM_ID_ASC',
  FilesStddevSampleLenderProgramIdDesc = 'FILES_STDDEV_SAMPLE_LENDER_PROGRAM_ID_DESC',
  FilesStddevSampleNameAsc = 'FILES_STDDEV_SAMPLE_NAME_ASC',
  FilesStddevSampleNameDesc = 'FILES_STDDEV_SAMPLE_NAME_DESC',
  FilesStddevSampleDisplayNameAsc = 'FILES_STDDEV_SAMPLE_DISPLAY_NAME_ASC',
  FilesStddevSampleDisplayNameDesc = 'FILES_STDDEV_SAMPLE_DISPLAY_NAME_DESC',
  FilesStddevSampleUrlAsc = 'FILES_STDDEV_SAMPLE_URL_ASC',
  FilesStddevSampleUrlDesc = 'FILES_STDDEV_SAMPLE_URL_DESC',
  FilesStddevSampleCreatedAtAsc = 'FILES_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesStddevSampleCreatedAtDesc = 'FILES_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesStddevSampleUpdatedAtAsc = 'FILES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesStddevSampleUpdatedAtDesc = 'FILES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesStddevPopulationIdAsc = 'FILES_STDDEV_POPULATION_ID_ASC',
  FilesStddevPopulationIdDesc = 'FILES_STDDEV_POPULATION_ID_DESC',
  FilesStddevPopulationLenderProgramIdAsc = 'FILES_STDDEV_POPULATION_LENDER_PROGRAM_ID_ASC',
  FilesStddevPopulationLenderProgramIdDesc = 'FILES_STDDEV_POPULATION_LENDER_PROGRAM_ID_DESC',
  FilesStddevPopulationNameAsc = 'FILES_STDDEV_POPULATION_NAME_ASC',
  FilesStddevPopulationNameDesc = 'FILES_STDDEV_POPULATION_NAME_DESC',
  FilesStddevPopulationDisplayNameAsc = 'FILES_STDDEV_POPULATION_DISPLAY_NAME_ASC',
  FilesStddevPopulationDisplayNameDesc = 'FILES_STDDEV_POPULATION_DISPLAY_NAME_DESC',
  FilesStddevPopulationUrlAsc = 'FILES_STDDEV_POPULATION_URL_ASC',
  FilesStddevPopulationUrlDesc = 'FILES_STDDEV_POPULATION_URL_DESC',
  FilesStddevPopulationCreatedAtAsc = 'FILES_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesStddevPopulationCreatedAtDesc = 'FILES_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesStddevPopulationUpdatedAtAsc = 'FILES_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesStddevPopulationUpdatedAtDesc = 'FILES_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesVarianceSampleIdAsc = 'FILES_VARIANCE_SAMPLE_ID_ASC',
  FilesVarianceSampleIdDesc = 'FILES_VARIANCE_SAMPLE_ID_DESC',
  FilesVarianceSampleLenderProgramIdAsc = 'FILES_VARIANCE_SAMPLE_LENDER_PROGRAM_ID_ASC',
  FilesVarianceSampleLenderProgramIdDesc = 'FILES_VARIANCE_SAMPLE_LENDER_PROGRAM_ID_DESC',
  FilesVarianceSampleNameAsc = 'FILES_VARIANCE_SAMPLE_NAME_ASC',
  FilesVarianceSampleNameDesc = 'FILES_VARIANCE_SAMPLE_NAME_DESC',
  FilesVarianceSampleDisplayNameAsc = 'FILES_VARIANCE_SAMPLE_DISPLAY_NAME_ASC',
  FilesVarianceSampleDisplayNameDesc = 'FILES_VARIANCE_SAMPLE_DISPLAY_NAME_DESC',
  FilesVarianceSampleUrlAsc = 'FILES_VARIANCE_SAMPLE_URL_ASC',
  FilesVarianceSampleUrlDesc = 'FILES_VARIANCE_SAMPLE_URL_DESC',
  FilesVarianceSampleCreatedAtAsc = 'FILES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesVarianceSampleCreatedAtDesc = 'FILES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesVarianceSampleUpdatedAtAsc = 'FILES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesVarianceSampleUpdatedAtDesc = 'FILES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilesVariancePopulationIdAsc = 'FILES_VARIANCE_POPULATION_ID_ASC',
  FilesVariancePopulationIdDesc = 'FILES_VARIANCE_POPULATION_ID_DESC',
  FilesVariancePopulationLenderProgramIdAsc = 'FILES_VARIANCE_POPULATION_LENDER_PROGRAM_ID_ASC',
  FilesVariancePopulationLenderProgramIdDesc = 'FILES_VARIANCE_POPULATION_LENDER_PROGRAM_ID_DESC',
  FilesVariancePopulationNameAsc = 'FILES_VARIANCE_POPULATION_NAME_ASC',
  FilesVariancePopulationNameDesc = 'FILES_VARIANCE_POPULATION_NAME_DESC',
  FilesVariancePopulationDisplayNameAsc = 'FILES_VARIANCE_POPULATION_DISPLAY_NAME_ASC',
  FilesVariancePopulationDisplayNameDesc = 'FILES_VARIANCE_POPULATION_DISPLAY_NAME_DESC',
  FilesVariancePopulationUrlAsc = 'FILES_VARIANCE_POPULATION_URL_ASC',
  FilesVariancePopulationUrlDesc = 'FILES_VARIANCE_POPULATION_URL_DESC',
  FilesVariancePopulationCreatedAtAsc = 'FILES_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesVariancePopulationCreatedAtDesc = 'FILES_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesVariancePopulationUpdatedAtAsc = 'FILES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesVariancePopulationUpdatedAtDesc = 'FILES_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type LenderProviderCreditAppConfig = {
  retailerId: Scalars['String'];
  retailerName?: Maybe<Scalars['String']>;
  status: LenderIntegrationStatus;
};

export type LenderQuote = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  type: LenderQuoteType;
  expired: Scalars['Boolean'];
  lenderId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  stips?: Maybe<Scalars['JSON']>;
  applicationLink?: Maybe<Scalars['String']>;
  /** Reads a single `Lead` that is related to this `LenderQuote`. */
  lead?: Maybe<Lead>;
  /** Reads a single `LenderApp` that is related to this `LenderQuote`. */
  lenderApp?: Maybe<LenderApp>;
  /** Reads a single `Lender` that is related to this `LenderQuote`. */
  lender?: Maybe<Lender>;
  /** Reads a single `CompanyIntegration` that is related to this `LenderQuote`. */
  companyIntegration?: Maybe<CompanyIntegration>;
};

export type LenderQuoteAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LenderQuoteSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LenderQuoteDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LenderQuoteMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LenderQuoteMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LenderQuoteAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LenderQuoteStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LenderQuoteStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LenderQuoteVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LenderQuoteVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `LenderQuote` object types. */
export type LenderQuoteAggregatesFilter = {
  /** A filter that must pass for the relevant `LenderQuote` object to be included within the aggregate. */
  filter?: Maybe<LenderQuoteFilter>;
  /** Sum aggregate over matching `LenderQuote` objects. */
  sum?: Maybe<LenderQuoteSumAggregateFilter>;
  /** Distinct count aggregate over matching `LenderQuote` objects. */
  distinctCount?: Maybe<LenderQuoteDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `LenderQuote` objects. */
  min?: Maybe<LenderQuoteMinAggregateFilter>;
  /** Maximum aggregate over matching `LenderQuote` objects. */
  max?: Maybe<LenderQuoteMaxAggregateFilter>;
  /** Mean average aggregate over matching `LenderQuote` objects. */
  average?: Maybe<LenderQuoteAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LenderQuote` objects. */
  stddevSample?: Maybe<LenderQuoteStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `LenderQuote` objects. */
  stddevPopulation?: Maybe<LenderQuoteStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LenderQuote` objects. */
  varianceSample?: Maybe<LenderQuoteVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `LenderQuote` objects. */
  variancePopulation?: Maybe<LenderQuoteVariancePopulationAggregateFilter>;
};

export type LenderQuoteAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderAppId?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
};

export type LenderQuoteAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LenderQuote` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LenderQuoteCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `lenderAppId` field. */
  lenderAppId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `quoteJson` field. */
  quoteJson?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<LenderQuoteType>;
  /** Checks for equality with the object’s `expired` field. */
  expired?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `lenderId` field. */
  lenderId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `stips` field. */
  stips?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `applicationLink` field. */
  applicationLink?: Maybe<Scalars['String']>;
};

export type LenderQuoteDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  lenderAppId?: Maybe<BigIntFilter>;
  provider?: Maybe<BigIntFilter>;
  quoteJson?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  expired?: Maybe<BigIntFilter>;
  lenderId?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  stips?: Maybe<BigIntFilter>;
  applicationLink?: Maybe<BigIntFilter>;
};

export type LenderQuoteDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
  /** Distinct count of quoteJson across the matching connection */
  quoteJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of expired across the matching connection */
  expired?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stips across the matching connection */
  stips?: Maybe<Scalars['BigInt']>;
  /** Distinct count of applicationLink across the matching connection */
  applicationLink?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LenderQuote` object types. All fields are combined with a logical ‘and.’ */
export type LenderQuoteFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `lenderAppId` field. */
  lenderAppId?: Maybe<IntFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: Maybe<StringFilter>;
  /** Filter by the object’s `quoteJson` field. */
  quoteJson?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<LenderQuoteTypeFilter>;
  /** Filter by the object’s `expired` field. */
  expired?: Maybe<BooleanFilter>;
  /** Filter by the object’s `lenderId` field. */
  lenderId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `stips` field. */
  stips?: Maybe<JsonFilter>;
  /** Filter by the object’s `applicationLink` field. */
  applicationLink?: Maybe<StringFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Filter by the object’s `lenderApp` relation. */
  lenderApp?: Maybe<LenderAppFilter>;
  /** A related `lenderApp` exists. */
  lenderAppExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lender` relation. */
  lender?: Maybe<LenderFilter>;
  /** A related `lender` exists. */
  lenderExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyIntegration` relation. */
  companyIntegration?: Maybe<CompanyIntegrationFilter>;
  /** A related `companyIntegration` exists. */
  companyIntegrationExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LenderQuoteFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LenderQuoteFilter>>;
  /** Negates the expression. */
  not?: Maybe<LenderQuoteFilter>;
};

/** An input for mutations affecting `LenderQuote` */
export type LenderQuoteInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  lenderId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  stips?: Maybe<Scalars['JSON']>;
  applicationLink?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
  lenderToLenderId?: Maybe<LenderQuotesLenderIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInput>;
};

/** The fields on `lenderQuote` to look up the row to connect. */
export type LenderQuoteLenderQuotesPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `lenderQuote` to look up the row to delete. */
export type LenderQuoteLenderQuotesPkeyDelete = {
  id: Scalars['Int'];
};

export type LenderQuoteMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderAppId?: Maybe<IntFilter>;
  lenderId?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
};

export type LenderQuoteMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['Int']>;
  /** Maximum of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['Int']>;
  /** Maximum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
};

export type LenderQuoteMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderAppId?: Maybe<IntFilter>;
  lenderId?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
};

export type LenderQuoteMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['Int']>;
  /** Minimum of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['Int']>;
  /** Minimum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LenderQuoteNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lenderQuote` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LenderQuoteNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lenderQuote` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** The fields on `lenderQuote` to look up the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyUsingLenderQuotesPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: UpdateLenderQuoteOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `lenderQuote` to look up the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyUsingLenderQuotesPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: UpdateLenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: LenderAppPatch;
};

/** The fields on `lenderQuote` to look up the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyUsingLenderQuotesPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: UpdateLenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesLenderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lender` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lender` being updated. */
  patch: LenderPatch;
};

/** The fields on `lenderQuote` to look up the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesLenderIdFkeyUsingLenderQuotesPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: UpdateLenderQuoteOnLenderQuoteForLenderQuotesLenderIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `LenderQuote`. Fields that are set will be updated. */
export type LenderQuotePatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  lenderId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  stips?: Maybe<Scalars['JSON']>;
  applicationLink?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
  lenderToLenderId?: Maybe<LenderQuotesLenderIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInput>;
};

export type LenderQuoteStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderAppId?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
};

export type LenderQuoteStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
};

export type LenderQuoteStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderAppId?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
};

export type LenderQuoteStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
};

export type LenderQuoteSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderAppId?: Maybe<BigIntFilter>;
  lenderId?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
};

export type LenderQuoteSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lenderAppId across the matching connection */
  lenderAppId: Scalars['BigInt'];
  /** Sum of lenderId across the matching connection */
  lenderId: Scalars['BigInt'];
  /** Sum of companyIntegrationId across the matching connection */
  companyIntegrationId: Scalars['BigInt'];
};

export enum LenderQuoteType {
  InstantDecision = 'INSTANT_DECISION',
  PassFail = 'PASS_FAIL',
  LenderProgram = 'LENDER_PROGRAM',
  LenderApplication = 'LENDER_APPLICATION'
}

/** A filter to be used against LenderQuoteType fields. All fields are combined with a logical ‘and.’ */
export type LenderQuoteTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<LenderQuoteType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<LenderQuoteType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<LenderQuoteType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<LenderQuoteType>;
  /** Included in the specified list. */
  in?: Maybe<Array<LenderQuoteType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<LenderQuoteType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<LenderQuoteType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<LenderQuoteType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<LenderQuoteType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<LenderQuoteType>;
};

export type LenderQuoteVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderAppId?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
};

export type LenderQuoteVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
};

export type LenderQuoteVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderAppId?: Maybe<BigFloatFilter>;
  lenderId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
};

export type LenderQuoteVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderId across the matching connection */
  lenderId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
};

/** The `companyIntegration` to be created by this mutation. */
export type LenderQuotesCompanyIntegrationIdFkeyCompanyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `companyIntegration` in the `LenderQuoteInput` mutation. */
export type LenderQuotesCompanyIntegrationIdFkeyInput = {
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyIntegrationNodeIdConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyDelete>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyIntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateById?: Maybe<CompanyIntegrationOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderQuoteOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyNodeIdUpdate>;
  /** A `CompanyIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<LenderQuotesCompanyIntegrationIdFkeyCompanyIntegrationCreateInput>;
};

/** Input for the nested mutation of `lenderQuote` in the `CompanyIntegrationInput` mutation. */
export type LenderQuotesCompanyIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderQuote` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderQuoteLenderQuotesPkeyConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderQuoteNodeIdConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderQuoteLenderQuotesPkeyDelete>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderQuoteNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderQuoteOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyUsingLenderQuotesPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyIntegrationOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyNodeIdUpdate>>;
  /** A `LenderQuoteInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderQuotesCompanyIntegrationIdFkeyLenderQuotesCreateInput>>;
};

/** The `lenderQuote` to be created by this mutation. */
export type LenderQuotesCompanyIntegrationIdFkeyLenderQuotesCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  lenderId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  stips?: Maybe<Scalars['JSON']>;
  applicationLink?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
  lenderToLenderId?: Maybe<LenderQuotesLenderIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInput>;
};

/** A connection to a list of `LenderQuote` values. */
export type LenderQuotesConnection = {
  /** A list of `LenderQuote` objects. */
  nodes: Array<LenderQuote>;
  /** A list of edges which contains the `LenderQuote` and cursor to aid in pagination. */
  edges: Array<LenderQuotesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LenderQuote` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderQuoteAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderQuoteAggregates>>;
};


/** A connection to a list of `LenderQuote` values. */
export type LenderQuotesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderQuotesGroupBy>;
  having?: Maybe<LenderQuotesHavingInput>;
};

/** A `LenderQuote` edge in the connection. */
export type LenderQuotesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LenderQuote` at the end of the edge. */
  node: LenderQuote;
};

/** Grouping methods for `LenderQuote` for usage during aggregation. */
export enum LenderQuotesGroupBy {
  LeadId = 'LEAD_ID',
  LenderAppId = 'LENDER_APP_ID',
  Provider = 'PROVIDER',
  QuoteJson = 'QUOTE_JSON',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Type = 'TYPE',
  Expired = 'EXPIRED',
  LenderId = 'LENDER_ID',
  CompanyIntegrationId = 'COMPANY_INTEGRATION_ID',
  Stips = 'STIPS',
  ApplicationLink = 'APPLICATION_LINK'
}

export type LenderQuotesHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
};

export type LenderQuotesHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
};

/** Conditions for `LenderQuote` aggregates. */
export type LenderQuotesHavingInput = {
  AND?: Maybe<Array<LenderQuotesHavingInput>>;
  OR?: Maybe<Array<LenderQuotesHavingInput>>;
  sum?: Maybe<LenderQuotesHavingSumInput>;
  distinctCount?: Maybe<LenderQuotesHavingDistinctCountInput>;
  min?: Maybe<LenderQuotesHavingMinInput>;
  max?: Maybe<LenderQuotesHavingMaxInput>;
  average?: Maybe<LenderQuotesHavingAverageInput>;
  stddevSample?: Maybe<LenderQuotesHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LenderQuotesHavingStddevPopulationInput>;
  varianceSample?: Maybe<LenderQuotesHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LenderQuotesHavingVariancePopulationInput>;
};

export type LenderQuotesHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
};

export type LenderQuotesHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
};

export type LenderQuotesHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
};

export type LenderQuotesHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
};

export type LenderQuotesHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
};

export type LenderQuotesHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
};

export type LenderQuotesHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  lenderId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
};

/** Input for the nested mutation of `lead` in the `LenderQuoteInput` mutation. */
export type LenderQuotesLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnLenderQuoteForLenderQuotesLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<LenderQuotesLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `lenderQuote` in the `LeadInput` mutation. */
export type LenderQuotesLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderQuote` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderQuoteLenderQuotesPkeyConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderQuoteNodeIdConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderQuoteLenderQuotesPkeyDelete>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderQuoteNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyUsingLenderQuotesPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnLenderQuoteForLenderQuotesLeadIdFkeyNodeIdUpdate>>;
  /** A `LenderQuoteInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderQuotesLeadIdFkeyLenderQuotesCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type LenderQuotesLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** The `lenderQuote` to be created by this mutation. */
export type LenderQuotesLeadIdFkeyLenderQuotesCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  lenderId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  stips?: Maybe<Scalars['JSON']>;
  applicationLink?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
  lenderToLenderId?: Maybe<LenderQuotesLenderIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInput>;
};

/** Input for the nested mutation of `lenderApp` in the `LenderQuoteInput` mutation. */
export type LenderQuotesLenderAppIdFkeyInput = {
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectById?: Maybe<LenderAppLenderAppPkeyConnect>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<LenderAppNodeIdConnect>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteById?: Maybe<LenderAppLenderAppPkeyDelete>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LenderAppNodeIdDelete>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateById?: Maybe<LenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyUsingLenderAppPkeyUpdate>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyNodeIdUpdate>;
  /** A `LenderAppInput` object that will be created and connected to this object. */
  create?: Maybe<LenderQuotesLenderAppIdFkeyLenderAppCreateInput>;
};

/** Input for the nested mutation of `lenderQuote` in the `LenderAppInput` mutation. */
export type LenderQuotesLenderAppIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderQuote` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderQuoteLenderQuotesPkeyConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderQuoteNodeIdConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderQuoteLenderQuotesPkeyDelete>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderQuoteNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyUsingLenderQuotesPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyNodeIdUpdate>>;
  /** A `LenderQuoteInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderQuotesLenderAppIdFkeyLenderQuotesCreateInput>>;
};

/** The `lenderApp` to be created by this mutation. */
export type LenderQuotesLenderAppIdFkeyLenderAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome: Scalars['Int'];
  employmentStatus: Scalars['String'];
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** The `lenderQuote` to be created by this mutation. */
export type LenderQuotesLenderAppIdFkeyLenderQuotesCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  lenderId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  stips?: Maybe<Scalars['JSON']>;
  applicationLink?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
  lenderToLenderId?: Maybe<LenderQuotesLenderIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInput>;
};

/** Input for the nested mutation of `lender` in the `LenderQuoteInput` mutation. */
export type LenderQuotesLenderIdFkeyInput = {
  /** The primary key(s) for `lender` for the far side of the relationship. */
  connectById?: Maybe<LenderLenderPkeyConnect>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  connectByName?: Maybe<LenderLenderNameKeyConnect>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  connectByNodeId?: Maybe<LenderNodeIdConnect>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  deleteById?: Maybe<LenderLenderPkeyDelete>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  deleteByName?: Maybe<LenderLenderNameKeyDelete>;
  /** The primary key(s) for `lender` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LenderNodeIdDelete>;
  /** The primary key(s) and patch data for `lender` for the far side of the relationship. */
  updateById?: Maybe<LenderOnLenderQuoteForLenderQuotesLenderIdFkeyUsingLenderPkeyUpdate>;
  /** The primary key(s) and patch data for `lender` for the far side of the relationship. */
  updateByName?: Maybe<LenderOnLenderQuoteForLenderQuotesLenderIdFkeyUsingLenderNameKeyUpdate>;
  /** The primary key(s) and patch data for `lender` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderQuoteOnLenderQuoteForLenderQuotesLenderIdFkeyNodeIdUpdate>;
  /** A `LenderInput` object that will be created and connected to this object. */
  create?: Maybe<LenderQuotesLenderIdFkeyLenderCreateInput>;
};

/** Input for the nested mutation of `lenderQuote` in the `LenderInput` mutation. */
export type LenderQuotesLenderIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderQuote` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderQuoteLenderQuotesPkeyConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderQuoteNodeIdConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderQuoteLenderQuotesPkeyDelete>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderQuoteNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderQuoteOnLenderQuoteForLenderQuotesLenderIdFkeyUsingLenderQuotesPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LenderOnLenderQuoteForLenderQuotesLenderIdFkeyNodeIdUpdate>>;
  /** A `LenderQuoteInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderQuotesLenderIdFkeyLenderQuotesCreateInput>>;
};

/** The `lender` to be created by this mutation. */
export type LenderQuotesLenderIdFkeyLenderCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  bio?: Maybe<Scalars['String']>;
  equifaxLendersUsingId?: Maybe<EquifaxLendersLenderIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderIdFkeyInverseInput>;
  lenderProgramsUsingId?: Maybe<LenderProgramLenderIdFkeyInverseInput>;
};

/** The `lenderQuote` to be created by this mutation. */
export type LenderQuotesLenderIdFkeyLenderQuotesCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  lenderId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  stips?: Maybe<Scalars['JSON']>;
  applicationLink?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
  lenderToLenderId?: Maybe<LenderQuotesLenderIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInput>;
};

/** Methods to use when ordering `LenderQuote`. */
export enum LenderQuotesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  LenderAppIdAsc = 'LENDER_APP_ID_ASC',
  LenderAppIdDesc = 'LENDER_APP_ID_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderDesc = 'PROVIDER_DESC',
  QuoteJsonAsc = 'QUOTE_JSON_ASC',
  QuoteJsonDesc = 'QUOTE_JSON_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  ExpiredAsc = 'EXPIRED_ASC',
  ExpiredDesc = 'EXPIRED_DESC',
  LenderIdAsc = 'LENDER_ID_ASC',
  LenderIdDesc = 'LENDER_ID_DESC',
  CompanyIntegrationIdAsc = 'COMPANY_INTEGRATION_ID_ASC',
  CompanyIntegrationIdDesc = 'COMPANY_INTEGRATION_ID_DESC',
  StipsAsc = 'STIPS_ASC',
  StipsDesc = 'STIPS_DESC',
  ApplicationLinkAsc = 'APPLICATION_LINK_ASC',
  ApplicationLinkDesc = 'APPLICATION_LINK_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type LenderStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type LenderStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type LenderSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `EquifaxLender` object types. All fields are combined with a logical ‘and.’ */
export type LenderToManyEquifaxLenderFilter = {
  /** Every related `EquifaxLender` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EquifaxLenderFilter>;
  /** Some related `EquifaxLender` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EquifaxLenderFilter>;
  /** No related `EquifaxLender` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EquifaxLenderFilter>;
  /** Aggregates across related `EquifaxLender` match the filter criteria. */
  aggregates?: Maybe<EquifaxLenderAggregatesFilter>;
};

/** A filter to be used against many `LenderProgram` object types. All fields are combined with a logical ‘and.’ */
export type LenderToManyLenderProgramFilter = {
  /** Every related `LenderProgram` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderProgramFilter>;
  /** Some related `LenderProgram` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderProgramFilter>;
  /** No related `LenderProgram` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderProgramFilter>;
  /** Aggregates across related `LenderProgram` match the filter criteria. */
  aggregates?: Maybe<LenderProgramAggregatesFilter>;
};

/** A filter to be used against many `LenderQuote` object types. All fields are combined with a logical ‘and.’ */
export type LenderToManyLenderQuoteFilter = {
  /** Every related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderQuoteFilter>;
  /** Some related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderQuoteFilter>;
  /** No related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderQuoteFilter>;
  /** Aggregates across related `LenderQuote` match the filter criteria. */
  aggregates?: Maybe<LenderQuoteAggregatesFilter>;
};

export enum LenderToolProvider {
  Axis = 'axis',
  Creditapp = 'creditapp',
  Inovatec = 'inovatec'
}

export type LenderValidationError = {
  field: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  provider: LenderToolProvider;
};

export type LenderVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type LenderVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Lender` values. */
export type LendersConnection = {
  /** A list of `Lender` objects. */
  nodes: Array<Lender>;
  /** A list of edges which contains the `Lender` and cursor to aid in pagination. */
  edges: Array<LendersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lender` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderAggregates>>;
};


/** A connection to a list of `Lender` values. */
export type LendersConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderGroupBy>;
  having?: Maybe<LenderHavingInput>;
};

/** A `Lender` edge in the connection. */
export type LendersEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lender` at the end of the edge. */
  node: Lender;
};

/** Methods to use when ordering `Lender`. */
export enum LendersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  BioAsc = 'BIO_ASC',
  BioDesc = 'BIO_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  EquifaxLendersCountAsc = 'EQUIFAX_LENDERS_COUNT_ASC',
  EquifaxLendersCountDesc = 'EQUIFAX_LENDERS_COUNT_DESC',
  EquifaxLendersSumIdAsc = 'EQUIFAX_LENDERS_SUM_ID_ASC',
  EquifaxLendersSumIdDesc = 'EQUIFAX_LENDERS_SUM_ID_DESC',
  EquifaxLendersSumEquifaxIdAsc = 'EQUIFAX_LENDERS_SUM_EQUIFAX_ID_ASC',
  EquifaxLendersSumEquifaxIdDesc = 'EQUIFAX_LENDERS_SUM_EQUIFAX_ID_DESC',
  EquifaxLendersSumNameAsc = 'EQUIFAX_LENDERS_SUM_NAME_ASC',
  EquifaxLendersSumNameDesc = 'EQUIFAX_LENDERS_SUM_NAME_DESC',
  EquifaxLendersSumDisplayAsc = 'EQUIFAX_LENDERS_SUM_DISPLAY_ASC',
  EquifaxLendersSumDisplayDesc = 'EQUIFAX_LENDERS_SUM_DISPLAY_DESC',
  EquifaxLendersSumReviewedAtAsc = 'EQUIFAX_LENDERS_SUM_REVIEWED_AT_ASC',
  EquifaxLendersSumReviewedAtDesc = 'EQUIFAX_LENDERS_SUM_REVIEWED_AT_DESC',
  EquifaxLendersSumCreatedAtAsc = 'EQUIFAX_LENDERS_SUM_CREATED_AT_ASC',
  EquifaxLendersSumCreatedAtDesc = 'EQUIFAX_LENDERS_SUM_CREATED_AT_DESC',
  EquifaxLendersSumUpdatedAtAsc = 'EQUIFAX_LENDERS_SUM_UPDATED_AT_ASC',
  EquifaxLendersSumUpdatedAtDesc = 'EQUIFAX_LENDERS_SUM_UPDATED_AT_DESC',
  EquifaxLendersSumLenderIdAsc = 'EQUIFAX_LENDERS_SUM_LENDER_ID_ASC',
  EquifaxLendersSumLenderIdDesc = 'EQUIFAX_LENDERS_SUM_LENDER_ID_DESC',
  EquifaxLendersSumPhoneAsc = 'EQUIFAX_LENDERS_SUM_PHONE_ASC',
  EquifaxLendersSumPhoneDesc = 'EQUIFAX_LENDERS_SUM_PHONE_DESC',
  EquifaxLendersDistinctCountIdAsc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_ID_ASC',
  EquifaxLendersDistinctCountIdDesc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_ID_DESC',
  EquifaxLendersDistinctCountEquifaxIdAsc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_EQUIFAX_ID_ASC',
  EquifaxLendersDistinctCountEquifaxIdDesc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_EQUIFAX_ID_DESC',
  EquifaxLendersDistinctCountNameAsc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_NAME_ASC',
  EquifaxLendersDistinctCountNameDesc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_NAME_DESC',
  EquifaxLendersDistinctCountDisplayAsc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_DISPLAY_ASC',
  EquifaxLendersDistinctCountDisplayDesc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_DISPLAY_DESC',
  EquifaxLendersDistinctCountReviewedAtAsc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_REVIEWED_AT_ASC',
  EquifaxLendersDistinctCountReviewedAtDesc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_REVIEWED_AT_DESC',
  EquifaxLendersDistinctCountCreatedAtAsc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_CREATED_AT_ASC',
  EquifaxLendersDistinctCountCreatedAtDesc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_CREATED_AT_DESC',
  EquifaxLendersDistinctCountUpdatedAtAsc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_UPDATED_AT_ASC',
  EquifaxLendersDistinctCountUpdatedAtDesc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_UPDATED_AT_DESC',
  EquifaxLendersDistinctCountLenderIdAsc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_LENDER_ID_ASC',
  EquifaxLendersDistinctCountLenderIdDesc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_LENDER_ID_DESC',
  EquifaxLendersDistinctCountPhoneAsc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_PHONE_ASC',
  EquifaxLendersDistinctCountPhoneDesc = 'EQUIFAX_LENDERS_DISTINCT_COUNT_PHONE_DESC',
  EquifaxLendersMinIdAsc = 'EQUIFAX_LENDERS_MIN_ID_ASC',
  EquifaxLendersMinIdDesc = 'EQUIFAX_LENDERS_MIN_ID_DESC',
  EquifaxLendersMinEquifaxIdAsc = 'EQUIFAX_LENDERS_MIN_EQUIFAX_ID_ASC',
  EquifaxLendersMinEquifaxIdDesc = 'EQUIFAX_LENDERS_MIN_EQUIFAX_ID_DESC',
  EquifaxLendersMinNameAsc = 'EQUIFAX_LENDERS_MIN_NAME_ASC',
  EquifaxLendersMinNameDesc = 'EQUIFAX_LENDERS_MIN_NAME_DESC',
  EquifaxLendersMinDisplayAsc = 'EQUIFAX_LENDERS_MIN_DISPLAY_ASC',
  EquifaxLendersMinDisplayDesc = 'EQUIFAX_LENDERS_MIN_DISPLAY_DESC',
  EquifaxLendersMinReviewedAtAsc = 'EQUIFAX_LENDERS_MIN_REVIEWED_AT_ASC',
  EquifaxLendersMinReviewedAtDesc = 'EQUIFAX_LENDERS_MIN_REVIEWED_AT_DESC',
  EquifaxLendersMinCreatedAtAsc = 'EQUIFAX_LENDERS_MIN_CREATED_AT_ASC',
  EquifaxLendersMinCreatedAtDesc = 'EQUIFAX_LENDERS_MIN_CREATED_AT_DESC',
  EquifaxLendersMinUpdatedAtAsc = 'EQUIFAX_LENDERS_MIN_UPDATED_AT_ASC',
  EquifaxLendersMinUpdatedAtDesc = 'EQUIFAX_LENDERS_MIN_UPDATED_AT_DESC',
  EquifaxLendersMinLenderIdAsc = 'EQUIFAX_LENDERS_MIN_LENDER_ID_ASC',
  EquifaxLendersMinLenderIdDesc = 'EQUIFAX_LENDERS_MIN_LENDER_ID_DESC',
  EquifaxLendersMinPhoneAsc = 'EQUIFAX_LENDERS_MIN_PHONE_ASC',
  EquifaxLendersMinPhoneDesc = 'EQUIFAX_LENDERS_MIN_PHONE_DESC',
  EquifaxLendersMaxIdAsc = 'EQUIFAX_LENDERS_MAX_ID_ASC',
  EquifaxLendersMaxIdDesc = 'EQUIFAX_LENDERS_MAX_ID_DESC',
  EquifaxLendersMaxEquifaxIdAsc = 'EQUIFAX_LENDERS_MAX_EQUIFAX_ID_ASC',
  EquifaxLendersMaxEquifaxIdDesc = 'EQUIFAX_LENDERS_MAX_EQUIFAX_ID_DESC',
  EquifaxLendersMaxNameAsc = 'EQUIFAX_LENDERS_MAX_NAME_ASC',
  EquifaxLendersMaxNameDesc = 'EQUIFAX_LENDERS_MAX_NAME_DESC',
  EquifaxLendersMaxDisplayAsc = 'EQUIFAX_LENDERS_MAX_DISPLAY_ASC',
  EquifaxLendersMaxDisplayDesc = 'EQUIFAX_LENDERS_MAX_DISPLAY_DESC',
  EquifaxLendersMaxReviewedAtAsc = 'EQUIFAX_LENDERS_MAX_REVIEWED_AT_ASC',
  EquifaxLendersMaxReviewedAtDesc = 'EQUIFAX_LENDERS_MAX_REVIEWED_AT_DESC',
  EquifaxLendersMaxCreatedAtAsc = 'EQUIFAX_LENDERS_MAX_CREATED_AT_ASC',
  EquifaxLendersMaxCreatedAtDesc = 'EQUIFAX_LENDERS_MAX_CREATED_AT_DESC',
  EquifaxLendersMaxUpdatedAtAsc = 'EQUIFAX_LENDERS_MAX_UPDATED_AT_ASC',
  EquifaxLendersMaxUpdatedAtDesc = 'EQUIFAX_LENDERS_MAX_UPDATED_AT_DESC',
  EquifaxLendersMaxLenderIdAsc = 'EQUIFAX_LENDERS_MAX_LENDER_ID_ASC',
  EquifaxLendersMaxLenderIdDesc = 'EQUIFAX_LENDERS_MAX_LENDER_ID_DESC',
  EquifaxLendersMaxPhoneAsc = 'EQUIFAX_LENDERS_MAX_PHONE_ASC',
  EquifaxLendersMaxPhoneDesc = 'EQUIFAX_LENDERS_MAX_PHONE_DESC',
  EquifaxLendersAverageIdAsc = 'EQUIFAX_LENDERS_AVERAGE_ID_ASC',
  EquifaxLendersAverageIdDesc = 'EQUIFAX_LENDERS_AVERAGE_ID_DESC',
  EquifaxLendersAverageEquifaxIdAsc = 'EQUIFAX_LENDERS_AVERAGE_EQUIFAX_ID_ASC',
  EquifaxLendersAverageEquifaxIdDesc = 'EQUIFAX_LENDERS_AVERAGE_EQUIFAX_ID_DESC',
  EquifaxLendersAverageNameAsc = 'EQUIFAX_LENDERS_AVERAGE_NAME_ASC',
  EquifaxLendersAverageNameDesc = 'EQUIFAX_LENDERS_AVERAGE_NAME_DESC',
  EquifaxLendersAverageDisplayAsc = 'EQUIFAX_LENDERS_AVERAGE_DISPLAY_ASC',
  EquifaxLendersAverageDisplayDesc = 'EQUIFAX_LENDERS_AVERAGE_DISPLAY_DESC',
  EquifaxLendersAverageReviewedAtAsc = 'EQUIFAX_LENDERS_AVERAGE_REVIEWED_AT_ASC',
  EquifaxLendersAverageReviewedAtDesc = 'EQUIFAX_LENDERS_AVERAGE_REVIEWED_AT_DESC',
  EquifaxLendersAverageCreatedAtAsc = 'EQUIFAX_LENDERS_AVERAGE_CREATED_AT_ASC',
  EquifaxLendersAverageCreatedAtDesc = 'EQUIFAX_LENDERS_AVERAGE_CREATED_AT_DESC',
  EquifaxLendersAverageUpdatedAtAsc = 'EQUIFAX_LENDERS_AVERAGE_UPDATED_AT_ASC',
  EquifaxLendersAverageUpdatedAtDesc = 'EQUIFAX_LENDERS_AVERAGE_UPDATED_AT_DESC',
  EquifaxLendersAverageLenderIdAsc = 'EQUIFAX_LENDERS_AVERAGE_LENDER_ID_ASC',
  EquifaxLendersAverageLenderIdDesc = 'EQUIFAX_LENDERS_AVERAGE_LENDER_ID_DESC',
  EquifaxLendersAveragePhoneAsc = 'EQUIFAX_LENDERS_AVERAGE_PHONE_ASC',
  EquifaxLendersAveragePhoneDesc = 'EQUIFAX_LENDERS_AVERAGE_PHONE_DESC',
  EquifaxLendersStddevSampleIdAsc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_ID_ASC',
  EquifaxLendersStddevSampleIdDesc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_ID_DESC',
  EquifaxLendersStddevSampleEquifaxIdAsc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_EQUIFAX_ID_ASC',
  EquifaxLendersStddevSampleEquifaxIdDesc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_EQUIFAX_ID_DESC',
  EquifaxLendersStddevSampleNameAsc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_NAME_ASC',
  EquifaxLendersStddevSampleNameDesc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_NAME_DESC',
  EquifaxLendersStddevSampleDisplayAsc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_DISPLAY_ASC',
  EquifaxLendersStddevSampleDisplayDesc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_DISPLAY_DESC',
  EquifaxLendersStddevSampleReviewedAtAsc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_REVIEWED_AT_ASC',
  EquifaxLendersStddevSampleReviewedAtDesc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_REVIEWED_AT_DESC',
  EquifaxLendersStddevSampleCreatedAtAsc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_CREATED_AT_ASC',
  EquifaxLendersStddevSampleCreatedAtDesc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_CREATED_AT_DESC',
  EquifaxLendersStddevSampleUpdatedAtAsc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  EquifaxLendersStddevSampleUpdatedAtDesc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  EquifaxLendersStddevSampleLenderIdAsc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_LENDER_ID_ASC',
  EquifaxLendersStddevSampleLenderIdDesc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_LENDER_ID_DESC',
  EquifaxLendersStddevSamplePhoneAsc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_PHONE_ASC',
  EquifaxLendersStddevSamplePhoneDesc = 'EQUIFAX_LENDERS_STDDEV_SAMPLE_PHONE_DESC',
  EquifaxLendersStddevPopulationIdAsc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_ID_ASC',
  EquifaxLendersStddevPopulationIdDesc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_ID_DESC',
  EquifaxLendersStddevPopulationEquifaxIdAsc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_EQUIFAX_ID_ASC',
  EquifaxLendersStddevPopulationEquifaxIdDesc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_EQUIFAX_ID_DESC',
  EquifaxLendersStddevPopulationNameAsc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_NAME_ASC',
  EquifaxLendersStddevPopulationNameDesc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_NAME_DESC',
  EquifaxLendersStddevPopulationDisplayAsc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_DISPLAY_ASC',
  EquifaxLendersStddevPopulationDisplayDesc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_DISPLAY_DESC',
  EquifaxLendersStddevPopulationReviewedAtAsc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_REVIEWED_AT_ASC',
  EquifaxLendersStddevPopulationReviewedAtDesc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_REVIEWED_AT_DESC',
  EquifaxLendersStddevPopulationCreatedAtAsc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_CREATED_AT_ASC',
  EquifaxLendersStddevPopulationCreatedAtDesc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_CREATED_AT_DESC',
  EquifaxLendersStddevPopulationUpdatedAtAsc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_UPDATED_AT_ASC',
  EquifaxLendersStddevPopulationUpdatedAtDesc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_UPDATED_AT_DESC',
  EquifaxLendersStddevPopulationLenderIdAsc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_LENDER_ID_ASC',
  EquifaxLendersStddevPopulationLenderIdDesc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_LENDER_ID_DESC',
  EquifaxLendersStddevPopulationPhoneAsc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_PHONE_ASC',
  EquifaxLendersStddevPopulationPhoneDesc = 'EQUIFAX_LENDERS_STDDEV_POPULATION_PHONE_DESC',
  EquifaxLendersVarianceSampleIdAsc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_ID_ASC',
  EquifaxLendersVarianceSampleIdDesc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_ID_DESC',
  EquifaxLendersVarianceSampleEquifaxIdAsc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_EQUIFAX_ID_ASC',
  EquifaxLendersVarianceSampleEquifaxIdDesc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_EQUIFAX_ID_DESC',
  EquifaxLendersVarianceSampleNameAsc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_NAME_ASC',
  EquifaxLendersVarianceSampleNameDesc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_NAME_DESC',
  EquifaxLendersVarianceSampleDisplayAsc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_DISPLAY_ASC',
  EquifaxLendersVarianceSampleDisplayDesc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_DISPLAY_DESC',
  EquifaxLendersVarianceSampleReviewedAtAsc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_REVIEWED_AT_ASC',
  EquifaxLendersVarianceSampleReviewedAtDesc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_REVIEWED_AT_DESC',
  EquifaxLendersVarianceSampleCreatedAtAsc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EquifaxLendersVarianceSampleCreatedAtDesc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EquifaxLendersVarianceSampleUpdatedAtAsc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  EquifaxLendersVarianceSampleUpdatedAtDesc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EquifaxLendersVarianceSampleLenderIdAsc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_LENDER_ID_ASC',
  EquifaxLendersVarianceSampleLenderIdDesc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_LENDER_ID_DESC',
  EquifaxLendersVarianceSamplePhoneAsc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_PHONE_ASC',
  EquifaxLendersVarianceSamplePhoneDesc = 'EQUIFAX_LENDERS_VARIANCE_SAMPLE_PHONE_DESC',
  EquifaxLendersVariancePopulationIdAsc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_ID_ASC',
  EquifaxLendersVariancePopulationIdDesc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_ID_DESC',
  EquifaxLendersVariancePopulationEquifaxIdAsc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_EQUIFAX_ID_ASC',
  EquifaxLendersVariancePopulationEquifaxIdDesc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_EQUIFAX_ID_DESC',
  EquifaxLendersVariancePopulationNameAsc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_NAME_ASC',
  EquifaxLendersVariancePopulationNameDesc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_NAME_DESC',
  EquifaxLendersVariancePopulationDisplayAsc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_DISPLAY_ASC',
  EquifaxLendersVariancePopulationDisplayDesc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_DISPLAY_DESC',
  EquifaxLendersVariancePopulationReviewedAtAsc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_REVIEWED_AT_ASC',
  EquifaxLendersVariancePopulationReviewedAtDesc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_REVIEWED_AT_DESC',
  EquifaxLendersVariancePopulationCreatedAtAsc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_CREATED_AT_ASC',
  EquifaxLendersVariancePopulationCreatedAtDesc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_CREATED_AT_DESC',
  EquifaxLendersVariancePopulationUpdatedAtAsc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  EquifaxLendersVariancePopulationUpdatedAtDesc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  EquifaxLendersVariancePopulationLenderIdAsc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_LENDER_ID_ASC',
  EquifaxLendersVariancePopulationLenderIdDesc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_LENDER_ID_DESC',
  EquifaxLendersVariancePopulationPhoneAsc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_PHONE_ASC',
  EquifaxLendersVariancePopulationPhoneDesc = 'EQUIFAX_LENDERS_VARIANCE_POPULATION_PHONE_DESC',
  LenderQuotesCountAsc = 'LENDER_QUOTES_COUNT_ASC',
  LenderQuotesCountDesc = 'LENDER_QUOTES_COUNT_DESC',
  LenderQuotesSumIdAsc = 'LENDER_QUOTES_SUM_ID_ASC',
  LenderQuotesSumIdDesc = 'LENDER_QUOTES_SUM_ID_DESC',
  LenderQuotesSumLeadIdAsc = 'LENDER_QUOTES_SUM_LEAD_ID_ASC',
  LenderQuotesSumLeadIdDesc = 'LENDER_QUOTES_SUM_LEAD_ID_DESC',
  LenderQuotesSumLenderAppIdAsc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_ASC',
  LenderQuotesSumLenderAppIdDesc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_DESC',
  LenderQuotesSumProviderAsc = 'LENDER_QUOTES_SUM_PROVIDER_ASC',
  LenderQuotesSumProviderDesc = 'LENDER_QUOTES_SUM_PROVIDER_DESC',
  LenderQuotesSumQuoteJsonAsc = 'LENDER_QUOTES_SUM_QUOTE_JSON_ASC',
  LenderQuotesSumQuoteJsonDesc = 'LENDER_QUOTES_SUM_QUOTE_JSON_DESC',
  LenderQuotesSumCreatedAtAsc = 'LENDER_QUOTES_SUM_CREATED_AT_ASC',
  LenderQuotesSumCreatedAtDesc = 'LENDER_QUOTES_SUM_CREATED_AT_DESC',
  LenderQuotesSumUpdatedAtAsc = 'LENDER_QUOTES_SUM_UPDATED_AT_ASC',
  LenderQuotesSumUpdatedAtDesc = 'LENDER_QUOTES_SUM_UPDATED_AT_DESC',
  LenderQuotesSumTypeAsc = 'LENDER_QUOTES_SUM_TYPE_ASC',
  LenderQuotesSumTypeDesc = 'LENDER_QUOTES_SUM_TYPE_DESC',
  LenderQuotesSumExpiredAsc = 'LENDER_QUOTES_SUM_EXPIRED_ASC',
  LenderQuotesSumExpiredDesc = 'LENDER_QUOTES_SUM_EXPIRED_DESC',
  LenderQuotesSumLenderIdAsc = 'LENDER_QUOTES_SUM_LENDER_ID_ASC',
  LenderQuotesSumLenderIdDesc = 'LENDER_QUOTES_SUM_LENDER_ID_DESC',
  LenderQuotesSumCompanyIntegrationIdAsc = 'LENDER_QUOTES_SUM_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesSumCompanyIntegrationIdDesc = 'LENDER_QUOTES_SUM_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesSumStipsAsc = 'LENDER_QUOTES_SUM_STIPS_ASC',
  LenderQuotesSumStipsDesc = 'LENDER_QUOTES_SUM_STIPS_DESC',
  LenderQuotesSumApplicationLinkAsc = 'LENDER_QUOTES_SUM_APPLICATION_LINK_ASC',
  LenderQuotesSumApplicationLinkDesc = 'LENDER_QUOTES_SUM_APPLICATION_LINK_DESC',
  LenderQuotesDistinctCountIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_ASC',
  LenderQuotesDistinctCountIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_DESC',
  LenderQuotesDistinctCountLeadIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_ASC',
  LenderQuotesDistinctCountLeadIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_DESC',
  LenderQuotesDistinctCountLenderAppIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_ASC',
  LenderQuotesDistinctCountLenderAppIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_DESC',
  LenderQuotesDistinctCountProviderAsc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_ASC',
  LenderQuotesDistinctCountProviderDesc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_DESC',
  LenderQuotesDistinctCountQuoteJsonAsc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_ASC',
  LenderQuotesDistinctCountQuoteJsonDesc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_DESC',
  LenderQuotesDistinctCountCreatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderQuotesDistinctCountCreatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderQuotesDistinctCountUpdatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderQuotesDistinctCountUpdatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderQuotesDistinctCountTypeAsc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_ASC',
  LenderQuotesDistinctCountTypeDesc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_DESC',
  LenderQuotesDistinctCountExpiredAsc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_ASC',
  LenderQuotesDistinctCountExpiredDesc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_DESC',
  LenderQuotesDistinctCountLenderIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_ID_ASC',
  LenderQuotesDistinctCountLenderIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_ID_DESC',
  LenderQuotesDistinctCountCompanyIntegrationIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesDistinctCountCompanyIntegrationIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesDistinctCountStipsAsc = 'LENDER_QUOTES_DISTINCT_COUNT_STIPS_ASC',
  LenderQuotesDistinctCountStipsDesc = 'LENDER_QUOTES_DISTINCT_COUNT_STIPS_DESC',
  LenderQuotesDistinctCountApplicationLinkAsc = 'LENDER_QUOTES_DISTINCT_COUNT_APPLICATION_LINK_ASC',
  LenderQuotesDistinctCountApplicationLinkDesc = 'LENDER_QUOTES_DISTINCT_COUNT_APPLICATION_LINK_DESC',
  LenderQuotesMinIdAsc = 'LENDER_QUOTES_MIN_ID_ASC',
  LenderQuotesMinIdDesc = 'LENDER_QUOTES_MIN_ID_DESC',
  LenderQuotesMinLeadIdAsc = 'LENDER_QUOTES_MIN_LEAD_ID_ASC',
  LenderQuotesMinLeadIdDesc = 'LENDER_QUOTES_MIN_LEAD_ID_DESC',
  LenderQuotesMinLenderAppIdAsc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_ASC',
  LenderQuotesMinLenderAppIdDesc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_DESC',
  LenderQuotesMinProviderAsc = 'LENDER_QUOTES_MIN_PROVIDER_ASC',
  LenderQuotesMinProviderDesc = 'LENDER_QUOTES_MIN_PROVIDER_DESC',
  LenderQuotesMinQuoteJsonAsc = 'LENDER_QUOTES_MIN_QUOTE_JSON_ASC',
  LenderQuotesMinQuoteJsonDesc = 'LENDER_QUOTES_MIN_QUOTE_JSON_DESC',
  LenderQuotesMinCreatedAtAsc = 'LENDER_QUOTES_MIN_CREATED_AT_ASC',
  LenderQuotesMinCreatedAtDesc = 'LENDER_QUOTES_MIN_CREATED_AT_DESC',
  LenderQuotesMinUpdatedAtAsc = 'LENDER_QUOTES_MIN_UPDATED_AT_ASC',
  LenderQuotesMinUpdatedAtDesc = 'LENDER_QUOTES_MIN_UPDATED_AT_DESC',
  LenderQuotesMinTypeAsc = 'LENDER_QUOTES_MIN_TYPE_ASC',
  LenderQuotesMinTypeDesc = 'LENDER_QUOTES_MIN_TYPE_DESC',
  LenderQuotesMinExpiredAsc = 'LENDER_QUOTES_MIN_EXPIRED_ASC',
  LenderQuotesMinExpiredDesc = 'LENDER_QUOTES_MIN_EXPIRED_DESC',
  LenderQuotesMinLenderIdAsc = 'LENDER_QUOTES_MIN_LENDER_ID_ASC',
  LenderQuotesMinLenderIdDesc = 'LENDER_QUOTES_MIN_LENDER_ID_DESC',
  LenderQuotesMinCompanyIntegrationIdAsc = 'LENDER_QUOTES_MIN_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesMinCompanyIntegrationIdDesc = 'LENDER_QUOTES_MIN_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesMinStipsAsc = 'LENDER_QUOTES_MIN_STIPS_ASC',
  LenderQuotesMinStipsDesc = 'LENDER_QUOTES_MIN_STIPS_DESC',
  LenderQuotesMinApplicationLinkAsc = 'LENDER_QUOTES_MIN_APPLICATION_LINK_ASC',
  LenderQuotesMinApplicationLinkDesc = 'LENDER_QUOTES_MIN_APPLICATION_LINK_DESC',
  LenderQuotesMaxIdAsc = 'LENDER_QUOTES_MAX_ID_ASC',
  LenderQuotesMaxIdDesc = 'LENDER_QUOTES_MAX_ID_DESC',
  LenderQuotesMaxLeadIdAsc = 'LENDER_QUOTES_MAX_LEAD_ID_ASC',
  LenderQuotesMaxLeadIdDesc = 'LENDER_QUOTES_MAX_LEAD_ID_DESC',
  LenderQuotesMaxLenderAppIdAsc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_ASC',
  LenderQuotesMaxLenderAppIdDesc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_DESC',
  LenderQuotesMaxProviderAsc = 'LENDER_QUOTES_MAX_PROVIDER_ASC',
  LenderQuotesMaxProviderDesc = 'LENDER_QUOTES_MAX_PROVIDER_DESC',
  LenderQuotesMaxQuoteJsonAsc = 'LENDER_QUOTES_MAX_QUOTE_JSON_ASC',
  LenderQuotesMaxQuoteJsonDesc = 'LENDER_QUOTES_MAX_QUOTE_JSON_DESC',
  LenderQuotesMaxCreatedAtAsc = 'LENDER_QUOTES_MAX_CREATED_AT_ASC',
  LenderQuotesMaxCreatedAtDesc = 'LENDER_QUOTES_MAX_CREATED_AT_DESC',
  LenderQuotesMaxUpdatedAtAsc = 'LENDER_QUOTES_MAX_UPDATED_AT_ASC',
  LenderQuotesMaxUpdatedAtDesc = 'LENDER_QUOTES_MAX_UPDATED_AT_DESC',
  LenderQuotesMaxTypeAsc = 'LENDER_QUOTES_MAX_TYPE_ASC',
  LenderQuotesMaxTypeDesc = 'LENDER_QUOTES_MAX_TYPE_DESC',
  LenderQuotesMaxExpiredAsc = 'LENDER_QUOTES_MAX_EXPIRED_ASC',
  LenderQuotesMaxExpiredDesc = 'LENDER_QUOTES_MAX_EXPIRED_DESC',
  LenderQuotesMaxLenderIdAsc = 'LENDER_QUOTES_MAX_LENDER_ID_ASC',
  LenderQuotesMaxLenderIdDesc = 'LENDER_QUOTES_MAX_LENDER_ID_DESC',
  LenderQuotesMaxCompanyIntegrationIdAsc = 'LENDER_QUOTES_MAX_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesMaxCompanyIntegrationIdDesc = 'LENDER_QUOTES_MAX_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesMaxStipsAsc = 'LENDER_QUOTES_MAX_STIPS_ASC',
  LenderQuotesMaxStipsDesc = 'LENDER_QUOTES_MAX_STIPS_DESC',
  LenderQuotesMaxApplicationLinkAsc = 'LENDER_QUOTES_MAX_APPLICATION_LINK_ASC',
  LenderQuotesMaxApplicationLinkDesc = 'LENDER_QUOTES_MAX_APPLICATION_LINK_DESC',
  LenderQuotesAverageIdAsc = 'LENDER_QUOTES_AVERAGE_ID_ASC',
  LenderQuotesAverageIdDesc = 'LENDER_QUOTES_AVERAGE_ID_DESC',
  LenderQuotesAverageLeadIdAsc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_ASC',
  LenderQuotesAverageLeadIdDesc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_DESC',
  LenderQuotesAverageLenderAppIdAsc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_ASC',
  LenderQuotesAverageLenderAppIdDesc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_DESC',
  LenderQuotesAverageProviderAsc = 'LENDER_QUOTES_AVERAGE_PROVIDER_ASC',
  LenderQuotesAverageProviderDesc = 'LENDER_QUOTES_AVERAGE_PROVIDER_DESC',
  LenderQuotesAverageQuoteJsonAsc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_ASC',
  LenderQuotesAverageQuoteJsonDesc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_DESC',
  LenderQuotesAverageCreatedAtAsc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_ASC',
  LenderQuotesAverageCreatedAtDesc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_DESC',
  LenderQuotesAverageUpdatedAtAsc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_ASC',
  LenderQuotesAverageUpdatedAtDesc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_DESC',
  LenderQuotesAverageTypeAsc = 'LENDER_QUOTES_AVERAGE_TYPE_ASC',
  LenderQuotesAverageTypeDesc = 'LENDER_QUOTES_AVERAGE_TYPE_DESC',
  LenderQuotesAverageExpiredAsc = 'LENDER_QUOTES_AVERAGE_EXPIRED_ASC',
  LenderQuotesAverageExpiredDesc = 'LENDER_QUOTES_AVERAGE_EXPIRED_DESC',
  LenderQuotesAverageLenderIdAsc = 'LENDER_QUOTES_AVERAGE_LENDER_ID_ASC',
  LenderQuotesAverageLenderIdDesc = 'LENDER_QUOTES_AVERAGE_LENDER_ID_DESC',
  LenderQuotesAverageCompanyIntegrationIdAsc = 'LENDER_QUOTES_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesAverageCompanyIntegrationIdDesc = 'LENDER_QUOTES_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesAverageStipsAsc = 'LENDER_QUOTES_AVERAGE_STIPS_ASC',
  LenderQuotesAverageStipsDesc = 'LENDER_QUOTES_AVERAGE_STIPS_DESC',
  LenderQuotesAverageApplicationLinkAsc = 'LENDER_QUOTES_AVERAGE_APPLICATION_LINK_ASC',
  LenderQuotesAverageApplicationLinkDesc = 'LENDER_QUOTES_AVERAGE_APPLICATION_LINK_DESC',
  LenderQuotesStddevSampleIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_ASC',
  LenderQuotesStddevSampleIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_DESC',
  LenderQuotesStddevSampleLeadIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_ASC',
  LenderQuotesStddevSampleLeadIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_DESC',
  LenderQuotesStddevSampleLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesStddevSampleLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesStddevSampleProviderAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_ASC',
  LenderQuotesStddevSampleProviderDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_DESC',
  LenderQuotesStddevSampleQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesStddevSampleQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesStddevSampleCreatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderQuotesStddevSampleCreatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderQuotesStddevSampleUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesStddevSampleUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesStddevSampleTypeAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_ASC',
  LenderQuotesStddevSampleTypeDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_DESC',
  LenderQuotesStddevSampleExpiredAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_ASC',
  LenderQuotesStddevSampleExpiredDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_DESC',
  LenderQuotesStddevSampleLenderIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_ID_ASC',
  LenderQuotesStddevSampleLenderIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_ID_DESC',
  LenderQuotesStddevSampleCompanyIntegrationIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesStddevSampleCompanyIntegrationIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesStddevSampleStipsAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_STIPS_ASC',
  LenderQuotesStddevSampleStipsDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_STIPS_DESC',
  LenderQuotesStddevSampleApplicationLinkAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_APPLICATION_LINK_ASC',
  LenderQuotesStddevSampleApplicationLinkDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_APPLICATION_LINK_DESC',
  LenderQuotesStddevPopulationIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_ASC',
  LenderQuotesStddevPopulationIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_DESC',
  LenderQuotesStddevPopulationLeadIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_ASC',
  LenderQuotesStddevPopulationLeadIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_DESC',
  LenderQuotesStddevPopulationLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesStddevPopulationLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesStddevPopulationProviderAsc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_ASC',
  LenderQuotesStddevPopulationProviderDesc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_DESC',
  LenderQuotesStddevPopulationQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesStddevPopulationQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesStddevPopulationCreatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderQuotesStddevPopulationCreatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderQuotesStddevPopulationUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderQuotesStddevPopulationUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderQuotesStddevPopulationTypeAsc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_ASC',
  LenderQuotesStddevPopulationTypeDesc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_DESC',
  LenderQuotesStddevPopulationExpiredAsc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_ASC',
  LenderQuotesStddevPopulationExpiredDesc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_DESC',
  LenderQuotesStddevPopulationLenderIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_ID_ASC',
  LenderQuotesStddevPopulationLenderIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_ID_DESC',
  LenderQuotesStddevPopulationCompanyIntegrationIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesStddevPopulationCompanyIntegrationIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesStddevPopulationStipsAsc = 'LENDER_QUOTES_STDDEV_POPULATION_STIPS_ASC',
  LenderQuotesStddevPopulationStipsDesc = 'LENDER_QUOTES_STDDEV_POPULATION_STIPS_DESC',
  LenderQuotesStddevPopulationApplicationLinkAsc = 'LENDER_QUOTES_STDDEV_POPULATION_APPLICATION_LINK_ASC',
  LenderQuotesStddevPopulationApplicationLinkDesc = 'LENDER_QUOTES_STDDEV_POPULATION_APPLICATION_LINK_DESC',
  LenderQuotesVarianceSampleIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_ASC',
  LenderQuotesVarianceSampleIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_DESC',
  LenderQuotesVarianceSampleLeadIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LenderQuotesVarianceSampleLeadIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LenderQuotesVarianceSampleLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesVarianceSampleLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesVarianceSampleProviderAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_ASC',
  LenderQuotesVarianceSampleProviderDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_DESC',
  LenderQuotesVarianceSampleQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesVarianceSampleQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesVarianceSampleCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderQuotesVarianceSampleCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderQuotesVarianceSampleUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesVarianceSampleUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesVarianceSampleTypeAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_ASC',
  LenderQuotesVarianceSampleTypeDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_DESC',
  LenderQuotesVarianceSampleExpiredAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_ASC',
  LenderQuotesVarianceSampleExpiredDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_DESC',
  LenderQuotesVarianceSampleLenderIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_ID_ASC',
  LenderQuotesVarianceSampleLenderIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_ID_DESC',
  LenderQuotesVarianceSampleCompanyIntegrationIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesVarianceSampleCompanyIntegrationIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesVarianceSampleStipsAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_STIPS_ASC',
  LenderQuotesVarianceSampleStipsDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_STIPS_DESC',
  LenderQuotesVarianceSampleApplicationLinkAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_APPLICATION_LINK_ASC',
  LenderQuotesVarianceSampleApplicationLinkDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_APPLICATION_LINK_DESC',
  LenderQuotesVariancePopulationIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_ASC',
  LenderQuotesVariancePopulationIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_DESC',
  LenderQuotesVariancePopulationLeadIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_ASC',
  LenderQuotesVariancePopulationLeadIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_DESC',
  LenderQuotesVariancePopulationLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesVariancePopulationLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesVariancePopulationProviderAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_ASC',
  LenderQuotesVariancePopulationProviderDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_DESC',
  LenderQuotesVariancePopulationQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesVariancePopulationQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesVariancePopulationCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderQuotesVariancePopulationCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderQuotesVariancePopulationUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderQuotesVariancePopulationUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderQuotesVariancePopulationTypeAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_ASC',
  LenderQuotesVariancePopulationTypeDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_DESC',
  LenderQuotesVariancePopulationExpiredAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_ASC',
  LenderQuotesVariancePopulationExpiredDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_DESC',
  LenderQuotesVariancePopulationLenderIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_ID_ASC',
  LenderQuotesVariancePopulationLenderIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_ID_DESC',
  LenderQuotesVariancePopulationCompanyIntegrationIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  LenderQuotesVariancePopulationCompanyIntegrationIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  LenderQuotesVariancePopulationStipsAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_STIPS_ASC',
  LenderQuotesVariancePopulationStipsDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_STIPS_DESC',
  LenderQuotesVariancePopulationApplicationLinkAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_APPLICATION_LINK_ASC',
  LenderQuotesVariancePopulationApplicationLinkDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_APPLICATION_LINK_DESC',
  LenderProgramsCountAsc = 'LENDER_PROGRAMS_COUNT_ASC',
  LenderProgramsCountDesc = 'LENDER_PROGRAMS_COUNT_DESC',
  LenderProgramsSumIdAsc = 'LENDER_PROGRAMS_SUM_ID_ASC',
  LenderProgramsSumIdDesc = 'LENDER_PROGRAMS_SUM_ID_DESC',
  LenderProgramsSumLenderIdAsc = 'LENDER_PROGRAMS_SUM_LENDER_ID_ASC',
  LenderProgramsSumLenderIdDesc = 'LENDER_PROGRAMS_SUM_LENDER_ID_DESC',
  LenderProgramsSumNameAsc = 'LENDER_PROGRAMS_SUM_NAME_ASC',
  LenderProgramsSumNameDesc = 'LENDER_PROGRAMS_SUM_NAME_DESC',
  LenderProgramsSumDisplayNameAsc = 'LENDER_PROGRAMS_SUM_DISPLAY_NAME_ASC',
  LenderProgramsSumDisplayNameDesc = 'LENDER_PROGRAMS_SUM_DISPLAY_NAME_DESC',
  LenderProgramsSumEnabledAsc = 'LENDER_PROGRAMS_SUM_ENABLED_ASC',
  LenderProgramsSumEnabledDesc = 'LENDER_PROGRAMS_SUM_ENABLED_DESC',
  LenderProgramsSumApplicationRequirementsAsc = 'LENDER_PROGRAMS_SUM_APPLICATION_REQUIREMENTS_ASC',
  LenderProgramsSumApplicationRequirementsDesc = 'LENDER_PROGRAMS_SUM_APPLICATION_REQUIREMENTS_DESC',
  LenderProgramsSumVehicleRequirementsAsc = 'LENDER_PROGRAMS_SUM_VEHICLE_REQUIREMENTS_ASC',
  LenderProgramsSumVehicleRequirementsDesc = 'LENDER_PROGRAMS_SUM_VEHICLE_REQUIREMENTS_DESC',
  LenderProgramsSumMinDateAsc = 'LENDER_PROGRAMS_SUM_MIN_DATE_ASC',
  LenderProgramsSumMinDateDesc = 'LENDER_PROGRAMS_SUM_MIN_DATE_DESC',
  LenderProgramsSumMaxDateAsc = 'LENDER_PROGRAMS_SUM_MAX_DATE_ASC',
  LenderProgramsSumMaxDateDesc = 'LENDER_PROGRAMS_SUM_MAX_DATE_DESC',
  LenderProgramsSumCreatedAtAsc = 'LENDER_PROGRAMS_SUM_CREATED_AT_ASC',
  LenderProgramsSumCreatedAtDesc = 'LENDER_PROGRAMS_SUM_CREATED_AT_DESC',
  LenderProgramsSumUpdatedAtAsc = 'LENDER_PROGRAMS_SUM_UPDATED_AT_ASC',
  LenderProgramsSumUpdatedAtDesc = 'LENDER_PROGRAMS_SUM_UPDATED_AT_DESC',
  LenderProgramsDistinctCountIdAsc = 'LENDER_PROGRAMS_DISTINCT_COUNT_ID_ASC',
  LenderProgramsDistinctCountIdDesc = 'LENDER_PROGRAMS_DISTINCT_COUNT_ID_DESC',
  LenderProgramsDistinctCountLenderIdAsc = 'LENDER_PROGRAMS_DISTINCT_COUNT_LENDER_ID_ASC',
  LenderProgramsDistinctCountLenderIdDesc = 'LENDER_PROGRAMS_DISTINCT_COUNT_LENDER_ID_DESC',
  LenderProgramsDistinctCountNameAsc = 'LENDER_PROGRAMS_DISTINCT_COUNT_NAME_ASC',
  LenderProgramsDistinctCountNameDesc = 'LENDER_PROGRAMS_DISTINCT_COUNT_NAME_DESC',
  LenderProgramsDistinctCountDisplayNameAsc = 'LENDER_PROGRAMS_DISTINCT_COUNT_DISPLAY_NAME_ASC',
  LenderProgramsDistinctCountDisplayNameDesc = 'LENDER_PROGRAMS_DISTINCT_COUNT_DISPLAY_NAME_DESC',
  LenderProgramsDistinctCountEnabledAsc = 'LENDER_PROGRAMS_DISTINCT_COUNT_ENABLED_ASC',
  LenderProgramsDistinctCountEnabledDesc = 'LENDER_PROGRAMS_DISTINCT_COUNT_ENABLED_DESC',
  LenderProgramsDistinctCountApplicationRequirementsAsc = 'LENDER_PROGRAMS_DISTINCT_COUNT_APPLICATION_REQUIREMENTS_ASC',
  LenderProgramsDistinctCountApplicationRequirementsDesc = 'LENDER_PROGRAMS_DISTINCT_COUNT_APPLICATION_REQUIREMENTS_DESC',
  LenderProgramsDistinctCountVehicleRequirementsAsc = 'LENDER_PROGRAMS_DISTINCT_COUNT_VEHICLE_REQUIREMENTS_ASC',
  LenderProgramsDistinctCountVehicleRequirementsDesc = 'LENDER_PROGRAMS_DISTINCT_COUNT_VEHICLE_REQUIREMENTS_DESC',
  LenderProgramsDistinctCountMinDateAsc = 'LENDER_PROGRAMS_DISTINCT_COUNT_MIN_DATE_ASC',
  LenderProgramsDistinctCountMinDateDesc = 'LENDER_PROGRAMS_DISTINCT_COUNT_MIN_DATE_DESC',
  LenderProgramsDistinctCountMaxDateAsc = 'LENDER_PROGRAMS_DISTINCT_COUNT_MAX_DATE_ASC',
  LenderProgramsDistinctCountMaxDateDesc = 'LENDER_PROGRAMS_DISTINCT_COUNT_MAX_DATE_DESC',
  LenderProgramsDistinctCountCreatedAtAsc = 'LENDER_PROGRAMS_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderProgramsDistinctCountCreatedAtDesc = 'LENDER_PROGRAMS_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderProgramsDistinctCountUpdatedAtAsc = 'LENDER_PROGRAMS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderProgramsDistinctCountUpdatedAtDesc = 'LENDER_PROGRAMS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderProgramsMinIdAsc = 'LENDER_PROGRAMS_MIN_ID_ASC',
  LenderProgramsMinIdDesc = 'LENDER_PROGRAMS_MIN_ID_DESC',
  LenderProgramsMinLenderIdAsc = 'LENDER_PROGRAMS_MIN_LENDER_ID_ASC',
  LenderProgramsMinLenderIdDesc = 'LENDER_PROGRAMS_MIN_LENDER_ID_DESC',
  LenderProgramsMinNameAsc = 'LENDER_PROGRAMS_MIN_NAME_ASC',
  LenderProgramsMinNameDesc = 'LENDER_PROGRAMS_MIN_NAME_DESC',
  LenderProgramsMinDisplayNameAsc = 'LENDER_PROGRAMS_MIN_DISPLAY_NAME_ASC',
  LenderProgramsMinDisplayNameDesc = 'LENDER_PROGRAMS_MIN_DISPLAY_NAME_DESC',
  LenderProgramsMinEnabledAsc = 'LENDER_PROGRAMS_MIN_ENABLED_ASC',
  LenderProgramsMinEnabledDesc = 'LENDER_PROGRAMS_MIN_ENABLED_DESC',
  LenderProgramsMinApplicationRequirementsAsc = 'LENDER_PROGRAMS_MIN_APPLICATION_REQUIREMENTS_ASC',
  LenderProgramsMinApplicationRequirementsDesc = 'LENDER_PROGRAMS_MIN_APPLICATION_REQUIREMENTS_DESC',
  LenderProgramsMinVehicleRequirementsAsc = 'LENDER_PROGRAMS_MIN_VEHICLE_REQUIREMENTS_ASC',
  LenderProgramsMinVehicleRequirementsDesc = 'LENDER_PROGRAMS_MIN_VEHICLE_REQUIREMENTS_DESC',
  LenderProgramsMinMinDateAsc = 'LENDER_PROGRAMS_MIN_MIN_DATE_ASC',
  LenderProgramsMinMinDateDesc = 'LENDER_PROGRAMS_MIN_MIN_DATE_DESC',
  LenderProgramsMinMaxDateAsc = 'LENDER_PROGRAMS_MIN_MAX_DATE_ASC',
  LenderProgramsMinMaxDateDesc = 'LENDER_PROGRAMS_MIN_MAX_DATE_DESC',
  LenderProgramsMinCreatedAtAsc = 'LENDER_PROGRAMS_MIN_CREATED_AT_ASC',
  LenderProgramsMinCreatedAtDesc = 'LENDER_PROGRAMS_MIN_CREATED_AT_DESC',
  LenderProgramsMinUpdatedAtAsc = 'LENDER_PROGRAMS_MIN_UPDATED_AT_ASC',
  LenderProgramsMinUpdatedAtDesc = 'LENDER_PROGRAMS_MIN_UPDATED_AT_DESC',
  LenderProgramsMaxIdAsc = 'LENDER_PROGRAMS_MAX_ID_ASC',
  LenderProgramsMaxIdDesc = 'LENDER_PROGRAMS_MAX_ID_DESC',
  LenderProgramsMaxLenderIdAsc = 'LENDER_PROGRAMS_MAX_LENDER_ID_ASC',
  LenderProgramsMaxLenderIdDesc = 'LENDER_PROGRAMS_MAX_LENDER_ID_DESC',
  LenderProgramsMaxNameAsc = 'LENDER_PROGRAMS_MAX_NAME_ASC',
  LenderProgramsMaxNameDesc = 'LENDER_PROGRAMS_MAX_NAME_DESC',
  LenderProgramsMaxDisplayNameAsc = 'LENDER_PROGRAMS_MAX_DISPLAY_NAME_ASC',
  LenderProgramsMaxDisplayNameDesc = 'LENDER_PROGRAMS_MAX_DISPLAY_NAME_DESC',
  LenderProgramsMaxEnabledAsc = 'LENDER_PROGRAMS_MAX_ENABLED_ASC',
  LenderProgramsMaxEnabledDesc = 'LENDER_PROGRAMS_MAX_ENABLED_DESC',
  LenderProgramsMaxApplicationRequirementsAsc = 'LENDER_PROGRAMS_MAX_APPLICATION_REQUIREMENTS_ASC',
  LenderProgramsMaxApplicationRequirementsDesc = 'LENDER_PROGRAMS_MAX_APPLICATION_REQUIREMENTS_DESC',
  LenderProgramsMaxVehicleRequirementsAsc = 'LENDER_PROGRAMS_MAX_VEHICLE_REQUIREMENTS_ASC',
  LenderProgramsMaxVehicleRequirementsDesc = 'LENDER_PROGRAMS_MAX_VEHICLE_REQUIREMENTS_DESC',
  LenderProgramsMaxMinDateAsc = 'LENDER_PROGRAMS_MAX_MIN_DATE_ASC',
  LenderProgramsMaxMinDateDesc = 'LENDER_PROGRAMS_MAX_MIN_DATE_DESC',
  LenderProgramsMaxMaxDateAsc = 'LENDER_PROGRAMS_MAX_MAX_DATE_ASC',
  LenderProgramsMaxMaxDateDesc = 'LENDER_PROGRAMS_MAX_MAX_DATE_DESC',
  LenderProgramsMaxCreatedAtAsc = 'LENDER_PROGRAMS_MAX_CREATED_AT_ASC',
  LenderProgramsMaxCreatedAtDesc = 'LENDER_PROGRAMS_MAX_CREATED_AT_DESC',
  LenderProgramsMaxUpdatedAtAsc = 'LENDER_PROGRAMS_MAX_UPDATED_AT_ASC',
  LenderProgramsMaxUpdatedAtDesc = 'LENDER_PROGRAMS_MAX_UPDATED_AT_DESC',
  LenderProgramsAverageIdAsc = 'LENDER_PROGRAMS_AVERAGE_ID_ASC',
  LenderProgramsAverageIdDesc = 'LENDER_PROGRAMS_AVERAGE_ID_DESC',
  LenderProgramsAverageLenderIdAsc = 'LENDER_PROGRAMS_AVERAGE_LENDER_ID_ASC',
  LenderProgramsAverageLenderIdDesc = 'LENDER_PROGRAMS_AVERAGE_LENDER_ID_DESC',
  LenderProgramsAverageNameAsc = 'LENDER_PROGRAMS_AVERAGE_NAME_ASC',
  LenderProgramsAverageNameDesc = 'LENDER_PROGRAMS_AVERAGE_NAME_DESC',
  LenderProgramsAverageDisplayNameAsc = 'LENDER_PROGRAMS_AVERAGE_DISPLAY_NAME_ASC',
  LenderProgramsAverageDisplayNameDesc = 'LENDER_PROGRAMS_AVERAGE_DISPLAY_NAME_DESC',
  LenderProgramsAverageEnabledAsc = 'LENDER_PROGRAMS_AVERAGE_ENABLED_ASC',
  LenderProgramsAverageEnabledDesc = 'LENDER_PROGRAMS_AVERAGE_ENABLED_DESC',
  LenderProgramsAverageApplicationRequirementsAsc = 'LENDER_PROGRAMS_AVERAGE_APPLICATION_REQUIREMENTS_ASC',
  LenderProgramsAverageApplicationRequirementsDesc = 'LENDER_PROGRAMS_AVERAGE_APPLICATION_REQUIREMENTS_DESC',
  LenderProgramsAverageVehicleRequirementsAsc = 'LENDER_PROGRAMS_AVERAGE_VEHICLE_REQUIREMENTS_ASC',
  LenderProgramsAverageVehicleRequirementsDesc = 'LENDER_PROGRAMS_AVERAGE_VEHICLE_REQUIREMENTS_DESC',
  LenderProgramsAverageMinDateAsc = 'LENDER_PROGRAMS_AVERAGE_MIN_DATE_ASC',
  LenderProgramsAverageMinDateDesc = 'LENDER_PROGRAMS_AVERAGE_MIN_DATE_DESC',
  LenderProgramsAverageMaxDateAsc = 'LENDER_PROGRAMS_AVERAGE_MAX_DATE_ASC',
  LenderProgramsAverageMaxDateDesc = 'LENDER_PROGRAMS_AVERAGE_MAX_DATE_DESC',
  LenderProgramsAverageCreatedAtAsc = 'LENDER_PROGRAMS_AVERAGE_CREATED_AT_ASC',
  LenderProgramsAverageCreatedAtDesc = 'LENDER_PROGRAMS_AVERAGE_CREATED_AT_DESC',
  LenderProgramsAverageUpdatedAtAsc = 'LENDER_PROGRAMS_AVERAGE_UPDATED_AT_ASC',
  LenderProgramsAverageUpdatedAtDesc = 'LENDER_PROGRAMS_AVERAGE_UPDATED_AT_DESC',
  LenderProgramsStddevSampleIdAsc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_ID_ASC',
  LenderProgramsStddevSampleIdDesc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_ID_DESC',
  LenderProgramsStddevSampleLenderIdAsc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_LENDER_ID_ASC',
  LenderProgramsStddevSampleLenderIdDesc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_LENDER_ID_DESC',
  LenderProgramsStddevSampleNameAsc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_NAME_ASC',
  LenderProgramsStddevSampleNameDesc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_NAME_DESC',
  LenderProgramsStddevSampleDisplayNameAsc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_DISPLAY_NAME_ASC',
  LenderProgramsStddevSampleDisplayNameDesc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_DISPLAY_NAME_DESC',
  LenderProgramsStddevSampleEnabledAsc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_ENABLED_ASC',
  LenderProgramsStddevSampleEnabledDesc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_ENABLED_DESC',
  LenderProgramsStddevSampleApplicationRequirementsAsc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_APPLICATION_REQUIREMENTS_ASC',
  LenderProgramsStddevSampleApplicationRequirementsDesc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_APPLICATION_REQUIREMENTS_DESC',
  LenderProgramsStddevSampleVehicleRequirementsAsc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_VEHICLE_REQUIREMENTS_ASC',
  LenderProgramsStddevSampleVehicleRequirementsDesc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_VEHICLE_REQUIREMENTS_DESC',
  LenderProgramsStddevSampleMinDateAsc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_MIN_DATE_ASC',
  LenderProgramsStddevSampleMinDateDesc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_MIN_DATE_DESC',
  LenderProgramsStddevSampleMaxDateAsc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_MAX_DATE_ASC',
  LenderProgramsStddevSampleMaxDateDesc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_MAX_DATE_DESC',
  LenderProgramsStddevSampleCreatedAtAsc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderProgramsStddevSampleCreatedAtDesc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderProgramsStddevSampleUpdatedAtAsc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderProgramsStddevSampleUpdatedAtDesc = 'LENDER_PROGRAMS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderProgramsStddevPopulationIdAsc = 'LENDER_PROGRAMS_STDDEV_POPULATION_ID_ASC',
  LenderProgramsStddevPopulationIdDesc = 'LENDER_PROGRAMS_STDDEV_POPULATION_ID_DESC',
  LenderProgramsStddevPopulationLenderIdAsc = 'LENDER_PROGRAMS_STDDEV_POPULATION_LENDER_ID_ASC',
  LenderProgramsStddevPopulationLenderIdDesc = 'LENDER_PROGRAMS_STDDEV_POPULATION_LENDER_ID_DESC',
  LenderProgramsStddevPopulationNameAsc = 'LENDER_PROGRAMS_STDDEV_POPULATION_NAME_ASC',
  LenderProgramsStddevPopulationNameDesc = 'LENDER_PROGRAMS_STDDEV_POPULATION_NAME_DESC',
  LenderProgramsStddevPopulationDisplayNameAsc = 'LENDER_PROGRAMS_STDDEV_POPULATION_DISPLAY_NAME_ASC',
  LenderProgramsStddevPopulationDisplayNameDesc = 'LENDER_PROGRAMS_STDDEV_POPULATION_DISPLAY_NAME_DESC',
  LenderProgramsStddevPopulationEnabledAsc = 'LENDER_PROGRAMS_STDDEV_POPULATION_ENABLED_ASC',
  LenderProgramsStddevPopulationEnabledDesc = 'LENDER_PROGRAMS_STDDEV_POPULATION_ENABLED_DESC',
  LenderProgramsStddevPopulationApplicationRequirementsAsc = 'LENDER_PROGRAMS_STDDEV_POPULATION_APPLICATION_REQUIREMENTS_ASC',
  LenderProgramsStddevPopulationApplicationRequirementsDesc = 'LENDER_PROGRAMS_STDDEV_POPULATION_APPLICATION_REQUIREMENTS_DESC',
  LenderProgramsStddevPopulationVehicleRequirementsAsc = 'LENDER_PROGRAMS_STDDEV_POPULATION_VEHICLE_REQUIREMENTS_ASC',
  LenderProgramsStddevPopulationVehicleRequirementsDesc = 'LENDER_PROGRAMS_STDDEV_POPULATION_VEHICLE_REQUIREMENTS_DESC',
  LenderProgramsStddevPopulationMinDateAsc = 'LENDER_PROGRAMS_STDDEV_POPULATION_MIN_DATE_ASC',
  LenderProgramsStddevPopulationMinDateDesc = 'LENDER_PROGRAMS_STDDEV_POPULATION_MIN_DATE_DESC',
  LenderProgramsStddevPopulationMaxDateAsc = 'LENDER_PROGRAMS_STDDEV_POPULATION_MAX_DATE_ASC',
  LenderProgramsStddevPopulationMaxDateDesc = 'LENDER_PROGRAMS_STDDEV_POPULATION_MAX_DATE_DESC',
  LenderProgramsStddevPopulationCreatedAtAsc = 'LENDER_PROGRAMS_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderProgramsStddevPopulationCreatedAtDesc = 'LENDER_PROGRAMS_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderProgramsStddevPopulationUpdatedAtAsc = 'LENDER_PROGRAMS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderProgramsStddevPopulationUpdatedAtDesc = 'LENDER_PROGRAMS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderProgramsVarianceSampleIdAsc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_ID_ASC',
  LenderProgramsVarianceSampleIdDesc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_ID_DESC',
  LenderProgramsVarianceSampleLenderIdAsc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_LENDER_ID_ASC',
  LenderProgramsVarianceSampleLenderIdDesc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_LENDER_ID_DESC',
  LenderProgramsVarianceSampleNameAsc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_NAME_ASC',
  LenderProgramsVarianceSampleNameDesc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_NAME_DESC',
  LenderProgramsVarianceSampleDisplayNameAsc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_DISPLAY_NAME_ASC',
  LenderProgramsVarianceSampleDisplayNameDesc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_DISPLAY_NAME_DESC',
  LenderProgramsVarianceSampleEnabledAsc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_ENABLED_ASC',
  LenderProgramsVarianceSampleEnabledDesc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_ENABLED_DESC',
  LenderProgramsVarianceSampleApplicationRequirementsAsc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_APPLICATION_REQUIREMENTS_ASC',
  LenderProgramsVarianceSampleApplicationRequirementsDesc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_APPLICATION_REQUIREMENTS_DESC',
  LenderProgramsVarianceSampleVehicleRequirementsAsc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_VEHICLE_REQUIREMENTS_ASC',
  LenderProgramsVarianceSampleVehicleRequirementsDesc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_VEHICLE_REQUIREMENTS_DESC',
  LenderProgramsVarianceSampleMinDateAsc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_MIN_DATE_ASC',
  LenderProgramsVarianceSampleMinDateDesc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_MIN_DATE_DESC',
  LenderProgramsVarianceSampleMaxDateAsc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_MAX_DATE_ASC',
  LenderProgramsVarianceSampleMaxDateDesc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_MAX_DATE_DESC',
  LenderProgramsVarianceSampleCreatedAtAsc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderProgramsVarianceSampleCreatedAtDesc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderProgramsVarianceSampleUpdatedAtAsc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderProgramsVarianceSampleUpdatedAtDesc = 'LENDER_PROGRAMS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderProgramsVariancePopulationIdAsc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_ID_ASC',
  LenderProgramsVariancePopulationIdDesc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_ID_DESC',
  LenderProgramsVariancePopulationLenderIdAsc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_LENDER_ID_ASC',
  LenderProgramsVariancePopulationLenderIdDesc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_LENDER_ID_DESC',
  LenderProgramsVariancePopulationNameAsc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_NAME_ASC',
  LenderProgramsVariancePopulationNameDesc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_NAME_DESC',
  LenderProgramsVariancePopulationDisplayNameAsc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_DISPLAY_NAME_ASC',
  LenderProgramsVariancePopulationDisplayNameDesc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_DISPLAY_NAME_DESC',
  LenderProgramsVariancePopulationEnabledAsc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_ENABLED_ASC',
  LenderProgramsVariancePopulationEnabledDesc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_ENABLED_DESC',
  LenderProgramsVariancePopulationApplicationRequirementsAsc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_APPLICATION_REQUIREMENTS_ASC',
  LenderProgramsVariancePopulationApplicationRequirementsDesc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_APPLICATION_REQUIREMENTS_DESC',
  LenderProgramsVariancePopulationVehicleRequirementsAsc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_VEHICLE_REQUIREMENTS_ASC',
  LenderProgramsVariancePopulationVehicleRequirementsDesc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_VEHICLE_REQUIREMENTS_DESC',
  LenderProgramsVariancePopulationMinDateAsc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_MIN_DATE_ASC',
  LenderProgramsVariancePopulationMinDateDesc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_MIN_DATE_DESC',
  LenderProgramsVariancePopulationMaxDateAsc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_MAX_DATE_ASC',
  LenderProgramsVariancePopulationMaxDateDesc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_MAX_DATE_DESC',
  LenderProgramsVariancePopulationCreatedAtAsc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderProgramsVariancePopulationCreatedAtDesc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderProgramsVariancePopulationUpdatedAtAsc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderProgramsVariancePopulationUpdatedAtDesc = 'LENDER_PROGRAMS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type LineItem = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  netTotal?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export type LineTax = {
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LoanAppInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  monthlyIncome?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Float']>;
  employmentStatus?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  incomeAmount?: Maybe<Scalars['Float']>;
  incomePeriod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  dateOfBirth: Scalars['String'];
  streetNumber: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  sourceUrl?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  lpTest?: Maybe<Scalars['String']>;
  campaign?: Maybe<LeadCampaignInputType>;
};

export type LoanAppResponse = {
  result?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['UUID']>;
  msg?: Maybe<Scalars['String']>;
  consumer: Scalars['ID'];
};

export type LoanElements = {
  overallAmount?: Maybe<Scalars['Float']>;
  automotive?: Maybe<Scalars['Float']>;
  mortgages?: Maybe<Scalars['Float']>;
  microLoans?: Maybe<Scalars['Float']>;
  studentLoans?: Maybe<Scalars['Float']>;
  otherLoans?: Maybe<Scalars['Float']>;
};

export type LoanStatusComponent = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ManageCtaSpecsInput = {
  create?: Maybe<CreateCtaSpecsInput>;
  update?: Maybe<UpdateCtaSpecsInput>;
  delete?: Maybe<DeleteCtaSpecInput>;
};

export type ManageCtaSpecsOverrideResponse = {
  id: Scalars['Int'];
  widgetIds?: Maybe<Array<Scalars['String']>>;
  ctaId: Scalars['String'];
  groupId: Scalars['Int'];
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme: Scalars['String'];
  /** User friendly name of this override. */
  name: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  compatibleMajor: Scalars['Int'];
  ctaDetails?: Maybe<Scalars['JSON']>;
};

export type ManageCtaSpecsResponse = {
  id: Scalars['String'];
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  ctaTree: Scalars['JSON'];
  ctaStyles?: Maybe<Scalars['String']>;
  ctaStylesStatic?: Maybe<Scalars['String']>;
  themeSpec: Scalars['JSON'];
  templateSpec: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  templateDef: Scalars['JSON'];
  pluginDef: Scalars['JSON'];
  companyOverride?: Maybe<ManageCtaSpecsOverrideResponse>;
  conflict: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type MaxAmortization = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  term: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `LenderCreditKey` that is related to this `MaxAmortization`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
};

export type MaxAmortizationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MaxAmortizationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MaxAmortizationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MaxAmortizationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MaxAmortizationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MaxAmortizationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MaxAmortizationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MaxAmortizationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MaxAmortizationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MaxAmortizationVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `MaxAmortization` object types. */
export type MaxAmortizationAggregatesFilter = {
  /** A filter that must pass for the relevant `MaxAmortization` object to be included within the aggregate. */
  filter?: Maybe<MaxAmortizationFilter>;
  /** Sum aggregate over matching `MaxAmortization` objects. */
  sum?: Maybe<MaxAmortizationSumAggregateFilter>;
  /** Distinct count aggregate over matching `MaxAmortization` objects. */
  distinctCount?: Maybe<MaxAmortizationDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `MaxAmortization` objects. */
  min?: Maybe<MaxAmortizationMinAggregateFilter>;
  /** Maximum aggregate over matching `MaxAmortization` objects. */
  max?: Maybe<MaxAmortizationMaxAggregateFilter>;
  /** Mean average aggregate over matching `MaxAmortization` objects. */
  average?: Maybe<MaxAmortizationAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `MaxAmortization` objects. */
  stddevSample?: Maybe<MaxAmortizationStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `MaxAmortization` objects. */
  stddevPopulation?: Maybe<MaxAmortizationStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `MaxAmortization` objects. */
  varianceSample?: Maybe<MaxAmortizationVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `MaxAmortization` objects. */
  variancePopulation?: Maybe<MaxAmortizationVariancePopulationAggregateFilter>;
};

export type MaxAmortizationAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
};

export type MaxAmortizationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `MaxAmortization` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MaxAmortizationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lenderCreditKeyId` field. */
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minYear` field. */
  minYear?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxYear` field. */
  maxYear?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `personalCoApplicant` field. */
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `term` field. */
  term?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type MaxAmortizationDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderCreditKeyId?: Maybe<BigIntFilter>;
  minYear?: Maybe<BigIntFilter>;
  maxYear?: Maybe<BigIntFilter>;
  personalCoApplicant?: Maybe<BigIntFilter>;
  term?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type MaxAmortizationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of personalCoApplicant across the matching connection */
  personalCoApplicant?: Maybe<Scalars['BigInt']>;
  /** Distinct count of term across the matching connection */
  term?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `MaxAmortization` object types. All fields are combined with a logical ‘and.’ */
export type MaxAmortizationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `lenderCreditKeyId` field. */
  lenderCreditKeyId?: Maybe<IntFilter>;
  /** Filter by the object’s `minYear` field. */
  minYear?: Maybe<IntFilter>;
  /** Filter by the object’s `maxYear` field. */
  maxYear?: Maybe<IntFilter>;
  /** Filter by the object’s `personalCoApplicant` field. */
  personalCoApplicant?: Maybe<BooleanFilter>;
  /** Filter by the object’s `term` field. */
  term?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lenderCreditKey` relation. */
  lenderCreditKey?: Maybe<LenderCreditKeyFilter>;
  /** A related `lenderCreditKey` exists. */
  lenderCreditKeyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MaxAmortizationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MaxAmortizationFilter>>;
  /** Negates the expression. */
  not?: Maybe<MaxAmortizationFilter>;
};

/** Grouping methods for `MaxAmortization` for usage during aggregation. */
export enum MaxAmortizationGroupBy {
  LenderCreditKeyId = 'LENDER_CREDIT_KEY_ID',
  MinYear = 'MIN_YEAR',
  MaxYear = 'MAX_YEAR',
  PersonalCoApplicant = 'PERSONAL_CO_APPLICANT',
  Term = 'TERM',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type MaxAmortizationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type MaxAmortizationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `MaxAmortization` aggregates. */
export type MaxAmortizationHavingInput = {
  AND?: Maybe<Array<MaxAmortizationHavingInput>>;
  OR?: Maybe<Array<MaxAmortizationHavingInput>>;
  sum?: Maybe<MaxAmortizationHavingSumInput>;
  distinctCount?: Maybe<MaxAmortizationHavingDistinctCountInput>;
  min?: Maybe<MaxAmortizationHavingMinInput>;
  max?: Maybe<MaxAmortizationHavingMaxInput>;
  average?: Maybe<MaxAmortizationHavingAverageInput>;
  stddevSample?: Maybe<MaxAmortizationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<MaxAmortizationHavingStddevPopulationInput>;
  varianceSample?: Maybe<MaxAmortizationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<MaxAmortizationHavingVariancePopulationInput>;
};

export type MaxAmortizationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type MaxAmortizationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type MaxAmortizationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type MaxAmortizationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type MaxAmortizationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type MaxAmortizationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type MaxAmortizationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `MaxAmortization` */
export type MaxAmortizationInput = {
  id?: Maybe<Scalars['Int']>;
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  term: Scalars['Int'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInput>;
};

/** Input for the nested mutation of `lenderCreditKey` in the `MaxAmortizationInput` mutation. */
export type MaxAmortizationLenderCreditKeyIdFkeyInput = {
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  connectById?: Maybe<LenderCreditKeyLenderCreditKeyPkeyConnect>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  connectByNodeId?: Maybe<LenderCreditKeyNodeIdConnect>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  deleteById?: Maybe<LenderCreditKeyLenderCreditKeyPkeyDelete>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LenderCreditKeyNodeIdDelete>;
  /** The primary key(s) and patch data for `lenderCreditKey` for the far side of the relationship. */
  updateById?: Maybe<LenderCreditKeyOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyUsingLenderCreditKeyPkeyUpdate>;
  /** The primary key(s) and patch data for `lenderCreditKey` for the far side of the relationship. */
  updateByNodeId?: Maybe<MaxAmortizationOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyNodeIdUpdate>;
  /** A `LenderCreditKeyInput` object that will be created and connected to this object. */
  create?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyLenderCreditKeyCreateInput>;
};

/** Input for the nested mutation of `maxAmortization` in the `LenderCreditKeyInput` mutation. */
export type MaxAmortizationLenderCreditKeyIdFkeyInverseInput = {
  /** Flag indicating whether all other `maxAmortization` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `maxAmortization` for the far side of the relationship. */
  connectById?: Maybe<Array<MaxAmortizationMaxAmortizationPkeyConnect>>;
  /** The primary key(s) for `maxAmortization` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<MaxAmortizationNodeIdConnect>>;
  /** The primary key(s) for `maxAmortization` for the far side of the relationship. */
  deleteById?: Maybe<Array<MaxAmortizationMaxAmortizationPkeyDelete>>;
  /** The primary key(s) for `maxAmortization` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<MaxAmortizationNodeIdDelete>>;
  /** The primary key(s) and patch data for `maxAmortization` for the far side of the relationship. */
  updateById?: Maybe<Array<MaxAmortizationOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyUsingMaxAmortizationPkeyUpdate>>;
  /** The primary key(s) and patch data for `maxAmortization` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LenderCreditKeyOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyNodeIdUpdate>>;
  /** A `MaxAmortizationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<MaxAmortizationLenderCreditKeyIdFkeyMaxAmortizationCreateInput>>;
};

/** The `lenderCreditKey` to be created by this mutation. */
export type MaxAmortizationLenderCreditKeyIdFkeyLenderCreditKeyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  minScore?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInput>;
  ratesUsingId?: Maybe<RateLenderCreditKeyIdFkeyInverseInput>;
  lenderProgramBonusesUsingId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInverseInput>;
  maxAmortizationsUsingId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInverseInput>;
};

/** The `maxAmortization` to be created by this mutation. */
export type MaxAmortizationLenderCreditKeyIdFkeyMaxAmortizationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  term: Scalars['Int'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInput>;
};

export type MaxAmortizationMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderCreditKeyId?: Maybe<IntFilter>;
  minYear?: Maybe<IntFilter>;
  maxYear?: Maybe<IntFilter>;
  term?: Maybe<IntFilter>;
};

export type MaxAmortizationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  /** Maximum of minYear across the matching connection */
  minYear?: Maybe<Scalars['Int']>;
  /** Maximum of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['Int']>;
  /** Maximum of term across the matching connection */
  term?: Maybe<Scalars['Int']>;
};

/** The fields on `maxAmortization` to look up the row to connect. */
export type MaxAmortizationMaxAmortizationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `maxAmortization` to look up the row to delete. */
export type MaxAmortizationMaxAmortizationPkeyDelete = {
  id: Scalars['Int'];
};

export type MaxAmortizationMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderCreditKeyId?: Maybe<IntFilter>;
  minYear?: Maybe<IntFilter>;
  maxYear?: Maybe<IntFilter>;
  term?: Maybe<IntFilter>;
};

export type MaxAmortizationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  /** Minimum of minYear across the matching connection */
  minYear?: Maybe<Scalars['Int']>;
  /** Minimum of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['Int']>;
  /** Minimum of term across the matching connection */
  term?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type MaxAmortizationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `maxAmortization` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type MaxAmortizationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `maxAmortization` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type MaxAmortizationOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderCreditKey` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
  patch: LenderCreditKeyPatch;
};

/** The fields on `maxAmortization` to look up the row to update. */
export type MaxAmortizationOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyUsingMaxAmortizationPkeyUpdate = {
  /** An object where the defined keys will be set on the `maxAmortization` being updated. */
  patch: UpdateMaxAmortizationOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `MaxAmortization`. Fields that are set will be updated. */
export type MaxAmortizationPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  term?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInput>;
};

export type MaxAmortizationStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
};

export type MaxAmortizationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
};

export type MaxAmortizationStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
};

export type MaxAmortizationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
};

export type MaxAmortizationSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderCreditKeyId?: Maybe<BigIntFilter>;
  minYear?: Maybe<BigIntFilter>;
  maxYear?: Maybe<BigIntFilter>;
  term?: Maybe<BigIntFilter>;
};

export type MaxAmortizationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId: Scalars['BigInt'];
  /** Sum of minYear across the matching connection */
  minYear: Scalars['BigInt'];
  /** Sum of maxYear across the matching connection */
  maxYear: Scalars['BigInt'];
  /** Sum of term across the matching connection */
  term: Scalars['BigInt'];
};

export type MaxAmortizationVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
};

export type MaxAmortizationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
};

export type MaxAmortizationVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
};

export type MaxAmortizationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `MaxAmortization` values. */
export type MaxAmortizationsConnection = {
  /** A list of `MaxAmortization` objects. */
  nodes: Array<MaxAmortization>;
  /** A list of edges which contains the `MaxAmortization` and cursor to aid in pagination. */
  edges: Array<MaxAmortizationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MaxAmortization` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MaxAmortizationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MaxAmortizationAggregates>>;
};


/** A connection to a list of `MaxAmortization` values. */
export type MaxAmortizationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<MaxAmortizationGroupBy>;
  having?: Maybe<MaxAmortizationHavingInput>;
};

/** A `MaxAmortization` edge in the connection. */
export type MaxAmortizationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MaxAmortization` at the end of the edge. */
  node: MaxAmortization;
};

/** Methods to use when ordering `MaxAmortization`. */
export enum MaxAmortizationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LenderCreditKeyIdAsc = 'LENDER_CREDIT_KEY_ID_ASC',
  LenderCreditKeyIdDesc = 'LENDER_CREDIT_KEY_ID_DESC',
  MinYearAsc = 'MIN_YEAR_ASC',
  MinYearDesc = 'MIN_YEAR_DESC',
  MaxYearAsc = 'MAX_YEAR_ASC',
  MaxYearDesc = 'MAX_YEAR_DESC',
  PersonalCoApplicantAsc = 'PERSONAL_CO_APPLICANT_ASC',
  PersonalCoApplicantDesc = 'PERSONAL_CO_APPLICANT_DESC',
  TermAsc = 'TERM_ASC',
  TermDesc = 'TERM_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Media = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  uploader?: Maybe<Scalars['Int']>;
  s3Key: Scalars['String'];
  parentId: Scalars['Int'];
  parentType: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  contentType?: Maybe<Scalars['String']>;
  uploadCompletedAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  fileName?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `Media`. */
  company?: Maybe<Company>;
  /** Reads a single `User` that is related to this `Media`. */
  userByUploader?: Maybe<User>;
  /** Reads and enables pagination through a set of `User`. */
  usersByPictureId: UsersConnection;
  /** Reads and enables pagination through a set of `CompanyChannelMedia`. */
  companyChannelMedias: CompanyChannelMediaConnection;
  /** Reads and enables pagination through a set of `IdMedia`. */
  idMedias: IdMediaConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByCompanyChannelMediaMediaIdAndCompanyChannelId: MediaCompanyChannelsByCompanyChannelMediaMediaIdAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByCompanyChannelMediaMediaIdAndAssigneeId: MediaUsersByCompanyChannelMediaMediaIdAndAssigneeIdManyToManyConnection;
  /** Reads and enables pagination through a set of `IdInquiryVerification`. */
  idInquiryVerificationsByIdMediaMediaIdAndVerificationId: MediaIdInquiryVerificationsByIdMediaMediaIdAndVerificationIdManyToManyConnection;
  signedUrl?: Maybe<Scalars['String']>;
};


export type MediaUsersByPictureIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type MediaCompanyChannelMediasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelMediaOrderBy>>;
  condition?: Maybe<CompanyChannelMediaCondition>;
  filter?: Maybe<CompanyChannelMediaFilter>;
};


export type MediaIdMediasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdMediaOrderBy>>;
  condition?: Maybe<IdMediaCondition>;
  filter?: Maybe<IdMediaFilter>;
};


export type MediaCompanyChannelsByCompanyChannelMediaMediaIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type MediaUsersByCompanyChannelMediaMediaIdAndAssigneeIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type MediaIdInquiryVerificationsByIdMediaMediaIdAndVerificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiryVerificationsOrderBy>>;
  condition?: Maybe<IdInquiryVerificationCondition>;
  filter?: Maybe<IdInquiryVerificationFilter>;
};

export type MediaAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MediaSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MediaDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MediaMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MediaMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MediaAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MediaStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MediaStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MediaVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MediaVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Media` object types. */
export type MediaAggregatesFilter = {
  /** A filter that must pass for the relevant `Media` object to be included within the aggregate. */
  filter?: Maybe<MediaFilter>;
  /** Sum aggregate over matching `Media` objects. */
  sum?: Maybe<MediaSumAggregateFilter>;
  /** Distinct count aggregate over matching `Media` objects. */
  distinctCount?: Maybe<MediaDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Media` objects. */
  min?: Maybe<MediaMinAggregateFilter>;
  /** Maximum aggregate over matching `Media` objects. */
  max?: Maybe<MediaMaxAggregateFilter>;
  /** Mean average aggregate over matching `Media` objects. */
  average?: Maybe<MediaAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Media` objects. */
  stddevSample?: Maybe<MediaStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Media` objects. */
  stddevPopulation?: Maybe<MediaStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Media` objects. */
  varianceSample?: Maybe<MediaVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Media` objects. */
  variancePopulation?: Maybe<MediaVariancePopulationAggregateFilter>;
};

export type MediaAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  uploader?: Maybe<BigFloatFilter>;
  parentId?: Maybe<BigFloatFilter>;
};

export type MediaAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of uploader across the matching connection */
  uploader?: Maybe<Scalars['BigFloat']>;
  /** Mean average of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyChannel` values, with data from `CompanyChannelMedia`. */
export type MediaCompanyChannelsByCompanyChannelMediaMediaIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `CompanyChannelMedia`, and the cursor to aid in pagination. */
  edges: Array<MediaCompanyChannelsByCompanyChannelMediaMediaIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `CompanyChannelMedia`. */
export type MediaCompanyChannelsByCompanyChannelMediaMediaIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `CompanyChannelMedia`. */
export type MediaCompanyChannelsByCompanyChannelMediaMediaIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  id: Scalars['Int'];
  assigneeId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
};

/** The `company` to be created by this mutation. */
export type MediaCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `MediaInput` mutation. */
export type MediaCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnMediaForMediaCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnMediaForMediaCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnMediaForMediaCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnMediaForMediaCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnMediaForMediaCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<MediaOnMediaForMediaCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<MediaCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `media` in the `CompanyInput` mutation. */
export type MediaCompanyIdFkeyInverseInput = {
  /** The primary key(s) for `media` for the far side of the relationship. */
  connectById?: Maybe<Array<MediaMediaPkeyConnect>>;
  /** The primary key(s) for `media` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<MediaNodeIdConnect>>;
  /** The primary key(s) and patch data for `media` for the far side of the relationship. */
  updateById?: Maybe<Array<MediaOnMediaForMediaCompanyIdFkeyUsingMediaPkeyUpdate>>;
  /** The primary key(s) and patch data for `media` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnMediaForMediaCompanyIdFkeyNodeIdUpdate>>;
};

/** A condition to be used against `Media` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MediaCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `label` field. */
  label?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `uploader` field. */
  uploader?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `s3Key` field. */
  s3Key?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `parentType` field. */
  parentType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `contentType` field. */
  contentType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `uploadCompletedAt` field. */
  uploadCompletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `fileName` field. */
  fileName?: Maybe<Scalars['String']>;
};

/** A connection to a list of `Media` values. */
export type MediaConnection = {
  /** A list of `Media` objects. */
  nodes: Array<Media>;
  /** A list of edges which contains the `Media` and cursor to aid in pagination. */
  edges: Array<MediaEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Media` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MediaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MediaAggregates>>;
};


/** A connection to a list of `Media` values. */
export type MediaConnectionGroupedAggregatesArgs = {
  groupBy: Array<MediaGroupBy>;
  having?: Maybe<MediaHavingInput>;
};

export type MediaDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  label?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  uploader?: Maybe<BigIntFilter>;
  s3Key?: Maybe<BigIntFilter>;
  parentId?: Maybe<BigIntFilter>;
  parentType?: Maybe<BigIntFilter>;
  meta?: Maybe<BigIntFilter>;
  contentType?: Maybe<BigIntFilter>;
  uploadCompletedAt?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  deletedAt?: Maybe<BigIntFilter>;
  fileName?: Maybe<BigIntFilter>;
};

export type MediaDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of label across the matching connection */
  label?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of uploader across the matching connection */
  uploader?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s3Key across the matching connection */
  s3Key?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parentType across the matching connection */
  parentType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meta across the matching connection */
  meta?: Maybe<Scalars['BigInt']>;
  /** Distinct count of contentType across the matching connection */
  contentType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of uploadCompletedAt across the matching connection */
  uploadCompletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fileName across the matching connection */
  fileName?: Maybe<Scalars['BigInt']>;
};

/** A `Media` edge in the connection. */
export type MediaEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Media` at the end of the edge. */
  node: Media;
};

/** A filter to be used against `Media` object types. All fields are combined with a logical ‘and.’ */
export type MediaFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `label` field. */
  label?: Maybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `uploader` field. */
  uploader?: Maybe<IntFilter>;
  /** Filter by the object’s `s3Key` field. */
  s3Key?: Maybe<StringFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: Maybe<IntFilter>;
  /** Filter by the object’s `parentType` field. */
  parentType?: Maybe<StringFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: Maybe<JsonFilter>;
  /** Filter by the object’s `contentType` field. */
  contentType?: Maybe<StringFilter>;
  /** Filter by the object’s `uploadCompletedAt` field. */
  uploadCompletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `fileName` field. */
  fileName?: Maybe<StringFilter>;
  /** Filter by the object’s `usersByPictureId` relation. */
  usersByPictureId?: Maybe<MediaToManyUserFilter>;
  /** Some related `usersByPictureId` exist. */
  usersByPictureIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyChannelMedias` relation. */
  companyChannelMedias?: Maybe<MediaToManyCompanyChannelMediaFilter>;
  /** Some related `companyChannelMedias` exist. */
  companyChannelMediasExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `idMedias` relation. */
  idMedias?: Maybe<MediaToManyIdMediaFilter>;
  /** Some related `idMedias` exist. */
  idMediasExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByUploader` relation. */
  userByUploader?: Maybe<UserFilter>;
  /** A related `userByUploader` exists. */
  userByUploaderExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MediaFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MediaFilter>>;
  /** Negates the expression. */
  not?: Maybe<MediaFilter>;
};

/** Grouping methods for `Media` for usage during aggregation. */
export enum MediaGroupBy {
  CompanyId = 'COMPANY_ID',
  Label = 'LABEL',
  Type = 'TYPE',
  Uploader = 'UPLOADER',
  S3Key = 'S3_KEY',
  ParentId = 'PARENT_ID',
  ParentType = 'PARENT_TYPE',
  Meta = 'META',
  ContentType = 'CONTENT_TYPE',
  UploadCompletedAt = 'UPLOAD_COMPLETED_AT',
  UploadCompletedAtTruncatedToHour = 'UPLOAD_COMPLETED_AT_TRUNCATED_TO_HOUR',
  UploadCompletedAtTruncatedToDay = 'UPLOAD_COMPLETED_AT_TRUNCATED_TO_DAY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  DeletedAt = 'DELETED_AT',
  DeletedAtTruncatedToHour = 'DELETED_AT_TRUNCATED_TO_HOUR',
  DeletedAtTruncatedToDay = 'DELETED_AT_TRUNCATED_TO_DAY',
  FileName = 'FILE_NAME'
}

export type MediaHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  uploader?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  uploadCompletedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type MediaHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  uploader?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  uploadCompletedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Media` aggregates. */
export type MediaHavingInput = {
  AND?: Maybe<Array<MediaHavingInput>>;
  OR?: Maybe<Array<MediaHavingInput>>;
  sum?: Maybe<MediaHavingSumInput>;
  distinctCount?: Maybe<MediaHavingDistinctCountInput>;
  min?: Maybe<MediaHavingMinInput>;
  max?: Maybe<MediaHavingMaxInput>;
  average?: Maybe<MediaHavingAverageInput>;
  stddevSample?: Maybe<MediaHavingStddevSampleInput>;
  stddevPopulation?: Maybe<MediaHavingStddevPopulationInput>;
  varianceSample?: Maybe<MediaHavingVarianceSampleInput>;
  variancePopulation?: Maybe<MediaHavingVariancePopulationInput>;
};

export type MediaHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  uploader?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  uploadCompletedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type MediaHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  uploader?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  uploadCompletedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type MediaHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  uploader?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  uploadCompletedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type MediaHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  uploader?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  uploadCompletedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type MediaHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  uploader?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  uploadCompletedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type MediaHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  uploader?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  uploadCompletedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type MediaHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  uploader?: Maybe<HavingIntFilter>;
  parentId?: Maybe<HavingIntFilter>;
  uploadCompletedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** A connection to a list of `IdInquiryVerification` values, with data from `IdMedia`. */
export type MediaIdInquiryVerificationsByIdMediaMediaIdAndVerificationIdManyToManyConnection = {
  /** A list of `IdInquiryVerification` objects. */
  nodes: Array<IdInquiryVerification>;
  /** A list of edges which contains the `IdInquiryVerification`, info from the `IdMedia`, and the cursor to aid in pagination. */
  edges: Array<MediaIdInquiryVerificationsByIdMediaMediaIdAndVerificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdInquiryVerification` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IdInquiryVerificationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IdInquiryVerificationAggregates>>;
};


/** A connection to a list of `IdInquiryVerification` values, with data from `IdMedia`. */
export type MediaIdInquiryVerificationsByIdMediaMediaIdAndVerificationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<IdInquiryVerificationGroupBy>;
  having?: Maybe<IdInquiryVerificationHavingInput>;
};

/** A `IdInquiryVerification` edge in the connection, with data from `IdMedia`. */
export type MediaIdInquiryVerificationsByIdMediaMediaIdAndVerificationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdInquiryVerification` at the end of the edge. */
  node: IdInquiryVerification;
  /** Reads and enables pagination through a set of `IdMedia`. */
  idMediasByVerificationId: IdMediaConnection;
};


/** A `IdInquiryVerification` edge in the connection, with data from `IdMedia`. */
export type MediaIdInquiryVerificationsByIdMediaMediaIdAndVerificationIdManyToManyEdgeIdMediasByVerificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdMediaOrderBy>>;
  condition?: Maybe<IdMediaCondition>;
  filter?: Maybe<IdMediaFilter>;
};

export type MediaMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  uploader?: Maybe<IntFilter>;
  parentId?: Maybe<IntFilter>;
};

export type MediaMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of uploader across the matching connection */
  uploader?: Maybe<Scalars['Int']>;
  /** Maximum of parentId across the matching connection */
  parentId?: Maybe<Scalars['Int']>;
};

/** The fields on `media` to look up the row to connect. */
export type MediaMediaPkeyConnect = {
  id: Scalars['Int'];
};

export type MediaMetaInput = {
  assigneeUserId?: Maybe<Scalars['Int']>;
};

export type MediaMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  uploader?: Maybe<IntFilter>;
  parentId?: Maybe<IntFilter>;
};

export type MediaMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of uploader across the matching connection */
  uploader?: Maybe<Scalars['Int']>;
  /** Minimum of parentId across the matching connection */
  parentId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type MediaNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `media` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type MediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannelMedia` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
  patch: CompanyChannelMediaPatch;
};

/** The fields on `media` to look up the row to update. */
export type MediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyUsingMediaPkeyUpdate = {
  /** An object where the defined keys will be set on the `media` being updated. */
  patch: UpdateMediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type MediaOnIdMediaForIdMediaMediaIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idMedia` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idMedia` being updated. */
  patch: IdMediaPatch;
};

/** The fields on `media` to look up the row to update. */
export type MediaOnIdMediaForIdMediaMediaIdFkeyUsingMediaPkeyUpdate = {
  /** An object where the defined keys will be set on the `media` being updated. */
  patch: UpdateMediaOnIdMediaForIdMediaMediaIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type MediaOnMediaForMediaCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `media` to look up the row to update. */
export type MediaOnMediaForMediaCompanyIdFkeyUsingMediaPkeyUpdate = {
  /** An object where the defined keys will be set on the `media` being updated. */
  patch: UpdateMediaOnMediaForMediaCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type MediaOnMediaForMediaUploaderFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `media` to look up the row to update. */
export type MediaOnMediaForMediaUploaderFkeyUsingMediaPkeyUpdate = {
  /** An object where the defined keys will be set on the `media` being updated. */
  patch: UpdateMediaOnMediaForMediaUploaderFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type MediaOnUserForUserPictureIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `media` to look up the row to update. */
export type MediaOnUserForUserPictureIdFkeyUsingMediaPkeyUpdate = {
  /** An object where the defined keys will be set on the `media` being updated. */
  patch: UpdateMediaOnUserForUserPictureIdFkeyPatch;
  id: Scalars['Int'];
};

/** Methods to use when ordering `Media`. */
export enum MediaOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UploaderAsc = 'UPLOADER_ASC',
  UploaderDesc = 'UPLOADER_DESC',
  S3KeyAsc = 'S3_KEY_ASC',
  S3KeyDesc = 'S3_KEY_DESC',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  ParentTypeAsc = 'PARENT_TYPE_ASC',
  ParentTypeDesc = 'PARENT_TYPE_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  ContentTypeAsc = 'CONTENT_TYPE_ASC',
  ContentTypeDesc = 'CONTENT_TYPE_DESC',
  UploadCompletedAtAsc = 'UPLOAD_COMPLETED_AT_ASC',
  UploadCompletedAtDesc = 'UPLOAD_COMPLETED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  FileNameAsc = 'FILE_NAME_ASC',
  FileNameDesc = 'FILE_NAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UsersByPictureIdCountAsc = 'USERS_BY_PICTURE_ID_COUNT_ASC',
  UsersByPictureIdCountDesc = 'USERS_BY_PICTURE_ID_COUNT_DESC',
  UsersByPictureIdSumIdAsc = 'USERS_BY_PICTURE_ID_SUM_ID_ASC',
  UsersByPictureIdSumIdDesc = 'USERS_BY_PICTURE_ID_SUM_ID_DESC',
  UsersByPictureIdSumUsernameAsc = 'USERS_BY_PICTURE_ID_SUM_USERNAME_ASC',
  UsersByPictureIdSumUsernameDesc = 'USERS_BY_PICTURE_ID_SUM_USERNAME_DESC',
  UsersByPictureIdSumEmailAsc = 'USERS_BY_PICTURE_ID_SUM_EMAIL_ASC',
  UsersByPictureIdSumEmailDesc = 'USERS_BY_PICTURE_ID_SUM_EMAIL_DESC',
  UsersByPictureIdSumPhoneNumberAsc = 'USERS_BY_PICTURE_ID_SUM_PHONE_NUMBER_ASC',
  UsersByPictureIdSumPhoneNumberDesc = 'USERS_BY_PICTURE_ID_SUM_PHONE_NUMBER_DESC',
  UsersByPictureIdSumFirstNameAsc = 'USERS_BY_PICTURE_ID_SUM_FIRST_NAME_ASC',
  UsersByPictureIdSumFirstNameDesc = 'USERS_BY_PICTURE_ID_SUM_FIRST_NAME_DESC',
  UsersByPictureIdSumLastNameAsc = 'USERS_BY_PICTURE_ID_SUM_LAST_NAME_ASC',
  UsersByPictureIdSumLastNameDesc = 'USERS_BY_PICTURE_ID_SUM_LAST_NAME_DESC',
  UsersByPictureIdSumTitleAsc = 'USERS_BY_PICTURE_ID_SUM_TITLE_ASC',
  UsersByPictureIdSumTitleDesc = 'USERS_BY_PICTURE_ID_SUM_TITLE_DESC',
  UsersByPictureIdSumPositionAsc = 'USERS_BY_PICTURE_ID_SUM_POSITION_ASC',
  UsersByPictureIdSumPositionDesc = 'USERS_BY_PICTURE_ID_SUM_POSITION_DESC',
  UsersByPictureIdSumRoleAsc = 'USERS_BY_PICTURE_ID_SUM_ROLE_ASC',
  UsersByPictureIdSumRoleDesc = 'USERS_BY_PICTURE_ID_SUM_ROLE_DESC',
  UsersByPictureIdSumStatusAsc = 'USERS_BY_PICTURE_ID_SUM_STATUS_ASC',
  UsersByPictureIdSumStatusDesc = 'USERS_BY_PICTURE_ID_SUM_STATUS_DESC',
  UsersByPictureIdSumLastLoginAsc = 'USERS_BY_PICTURE_ID_SUM_LAST_LOGIN_ASC',
  UsersByPictureIdSumLastLoginDesc = 'USERS_BY_PICTURE_ID_SUM_LAST_LOGIN_DESC',
  UsersByPictureIdSumEnabledAsc = 'USERS_BY_PICTURE_ID_SUM_ENABLED_ASC',
  UsersByPictureIdSumEnabledDesc = 'USERS_BY_PICTURE_ID_SUM_ENABLED_DESC',
  UsersByPictureIdSumCreatedAtAsc = 'USERS_BY_PICTURE_ID_SUM_CREATED_AT_ASC',
  UsersByPictureIdSumCreatedAtDesc = 'USERS_BY_PICTURE_ID_SUM_CREATED_AT_DESC',
  UsersByPictureIdSumUpdatedAtAsc = 'USERS_BY_PICTURE_ID_SUM_UPDATED_AT_ASC',
  UsersByPictureIdSumUpdatedAtDesc = 'USERS_BY_PICTURE_ID_SUM_UPDATED_AT_DESC',
  UsersByPictureIdSumPictureIdAsc = 'USERS_BY_PICTURE_ID_SUM_PICTURE_ID_ASC',
  UsersByPictureIdSumPictureIdDesc = 'USERS_BY_PICTURE_ID_SUM_PICTURE_ID_DESC',
  UsersByPictureIdSumConfirmedAsc = 'USERS_BY_PICTURE_ID_SUM_CONFIRMED_ASC',
  UsersByPictureIdSumConfirmedDesc = 'USERS_BY_PICTURE_ID_SUM_CONFIRMED_DESC',
  UsersByPictureIdSumSelectedCompanyIdAsc = 'USERS_BY_PICTURE_ID_SUM_SELECTED_COMPANY_ID_ASC',
  UsersByPictureIdSumSelectedCompanyIdDesc = 'USERS_BY_PICTURE_ID_SUM_SELECTED_COMPANY_ID_DESC',
  UsersByPictureIdSumIntercomIdAsc = 'USERS_BY_PICTURE_ID_SUM_INTERCOM_ID_ASC',
  UsersByPictureIdSumIntercomIdDesc = 'USERS_BY_PICTURE_ID_SUM_INTERCOM_ID_DESC',
  UsersByPictureIdDistinctCountIdAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_ID_ASC',
  UsersByPictureIdDistinctCountIdDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_ID_DESC',
  UsersByPictureIdDistinctCountUsernameAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_USERNAME_ASC',
  UsersByPictureIdDistinctCountUsernameDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_USERNAME_DESC',
  UsersByPictureIdDistinctCountEmailAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_EMAIL_ASC',
  UsersByPictureIdDistinctCountEmailDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_EMAIL_DESC',
  UsersByPictureIdDistinctCountPhoneNumberAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_PHONE_NUMBER_ASC',
  UsersByPictureIdDistinctCountPhoneNumberDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_PHONE_NUMBER_DESC',
  UsersByPictureIdDistinctCountFirstNameAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_FIRST_NAME_ASC',
  UsersByPictureIdDistinctCountFirstNameDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_FIRST_NAME_DESC',
  UsersByPictureIdDistinctCountLastNameAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_LAST_NAME_ASC',
  UsersByPictureIdDistinctCountLastNameDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_LAST_NAME_DESC',
  UsersByPictureIdDistinctCountTitleAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_TITLE_ASC',
  UsersByPictureIdDistinctCountTitleDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_TITLE_DESC',
  UsersByPictureIdDistinctCountPositionAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_POSITION_ASC',
  UsersByPictureIdDistinctCountPositionDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_POSITION_DESC',
  UsersByPictureIdDistinctCountRoleAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_ROLE_ASC',
  UsersByPictureIdDistinctCountRoleDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_ROLE_DESC',
  UsersByPictureIdDistinctCountStatusAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_STATUS_ASC',
  UsersByPictureIdDistinctCountStatusDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_STATUS_DESC',
  UsersByPictureIdDistinctCountLastLoginAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_LAST_LOGIN_ASC',
  UsersByPictureIdDistinctCountLastLoginDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_LAST_LOGIN_DESC',
  UsersByPictureIdDistinctCountEnabledAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_ENABLED_ASC',
  UsersByPictureIdDistinctCountEnabledDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_ENABLED_DESC',
  UsersByPictureIdDistinctCountCreatedAtAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  UsersByPictureIdDistinctCountCreatedAtDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  UsersByPictureIdDistinctCountUpdatedAtAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  UsersByPictureIdDistinctCountUpdatedAtDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  UsersByPictureIdDistinctCountPictureIdAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_PICTURE_ID_ASC',
  UsersByPictureIdDistinctCountPictureIdDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_PICTURE_ID_DESC',
  UsersByPictureIdDistinctCountConfirmedAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_CONFIRMED_ASC',
  UsersByPictureIdDistinctCountConfirmedDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_CONFIRMED_DESC',
  UsersByPictureIdDistinctCountSelectedCompanyIdAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_SELECTED_COMPANY_ID_ASC',
  UsersByPictureIdDistinctCountSelectedCompanyIdDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_SELECTED_COMPANY_ID_DESC',
  UsersByPictureIdDistinctCountIntercomIdAsc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_INTERCOM_ID_ASC',
  UsersByPictureIdDistinctCountIntercomIdDesc = 'USERS_BY_PICTURE_ID_DISTINCT_COUNT_INTERCOM_ID_DESC',
  UsersByPictureIdMinIdAsc = 'USERS_BY_PICTURE_ID_MIN_ID_ASC',
  UsersByPictureIdMinIdDesc = 'USERS_BY_PICTURE_ID_MIN_ID_DESC',
  UsersByPictureIdMinUsernameAsc = 'USERS_BY_PICTURE_ID_MIN_USERNAME_ASC',
  UsersByPictureIdMinUsernameDesc = 'USERS_BY_PICTURE_ID_MIN_USERNAME_DESC',
  UsersByPictureIdMinEmailAsc = 'USERS_BY_PICTURE_ID_MIN_EMAIL_ASC',
  UsersByPictureIdMinEmailDesc = 'USERS_BY_PICTURE_ID_MIN_EMAIL_DESC',
  UsersByPictureIdMinPhoneNumberAsc = 'USERS_BY_PICTURE_ID_MIN_PHONE_NUMBER_ASC',
  UsersByPictureIdMinPhoneNumberDesc = 'USERS_BY_PICTURE_ID_MIN_PHONE_NUMBER_DESC',
  UsersByPictureIdMinFirstNameAsc = 'USERS_BY_PICTURE_ID_MIN_FIRST_NAME_ASC',
  UsersByPictureIdMinFirstNameDesc = 'USERS_BY_PICTURE_ID_MIN_FIRST_NAME_DESC',
  UsersByPictureIdMinLastNameAsc = 'USERS_BY_PICTURE_ID_MIN_LAST_NAME_ASC',
  UsersByPictureIdMinLastNameDesc = 'USERS_BY_PICTURE_ID_MIN_LAST_NAME_DESC',
  UsersByPictureIdMinTitleAsc = 'USERS_BY_PICTURE_ID_MIN_TITLE_ASC',
  UsersByPictureIdMinTitleDesc = 'USERS_BY_PICTURE_ID_MIN_TITLE_DESC',
  UsersByPictureIdMinPositionAsc = 'USERS_BY_PICTURE_ID_MIN_POSITION_ASC',
  UsersByPictureIdMinPositionDesc = 'USERS_BY_PICTURE_ID_MIN_POSITION_DESC',
  UsersByPictureIdMinRoleAsc = 'USERS_BY_PICTURE_ID_MIN_ROLE_ASC',
  UsersByPictureIdMinRoleDesc = 'USERS_BY_PICTURE_ID_MIN_ROLE_DESC',
  UsersByPictureIdMinStatusAsc = 'USERS_BY_PICTURE_ID_MIN_STATUS_ASC',
  UsersByPictureIdMinStatusDesc = 'USERS_BY_PICTURE_ID_MIN_STATUS_DESC',
  UsersByPictureIdMinLastLoginAsc = 'USERS_BY_PICTURE_ID_MIN_LAST_LOGIN_ASC',
  UsersByPictureIdMinLastLoginDesc = 'USERS_BY_PICTURE_ID_MIN_LAST_LOGIN_DESC',
  UsersByPictureIdMinEnabledAsc = 'USERS_BY_PICTURE_ID_MIN_ENABLED_ASC',
  UsersByPictureIdMinEnabledDesc = 'USERS_BY_PICTURE_ID_MIN_ENABLED_DESC',
  UsersByPictureIdMinCreatedAtAsc = 'USERS_BY_PICTURE_ID_MIN_CREATED_AT_ASC',
  UsersByPictureIdMinCreatedAtDesc = 'USERS_BY_PICTURE_ID_MIN_CREATED_AT_DESC',
  UsersByPictureIdMinUpdatedAtAsc = 'USERS_BY_PICTURE_ID_MIN_UPDATED_AT_ASC',
  UsersByPictureIdMinUpdatedAtDesc = 'USERS_BY_PICTURE_ID_MIN_UPDATED_AT_DESC',
  UsersByPictureIdMinPictureIdAsc = 'USERS_BY_PICTURE_ID_MIN_PICTURE_ID_ASC',
  UsersByPictureIdMinPictureIdDesc = 'USERS_BY_PICTURE_ID_MIN_PICTURE_ID_DESC',
  UsersByPictureIdMinConfirmedAsc = 'USERS_BY_PICTURE_ID_MIN_CONFIRMED_ASC',
  UsersByPictureIdMinConfirmedDesc = 'USERS_BY_PICTURE_ID_MIN_CONFIRMED_DESC',
  UsersByPictureIdMinSelectedCompanyIdAsc = 'USERS_BY_PICTURE_ID_MIN_SELECTED_COMPANY_ID_ASC',
  UsersByPictureIdMinSelectedCompanyIdDesc = 'USERS_BY_PICTURE_ID_MIN_SELECTED_COMPANY_ID_DESC',
  UsersByPictureIdMinIntercomIdAsc = 'USERS_BY_PICTURE_ID_MIN_INTERCOM_ID_ASC',
  UsersByPictureIdMinIntercomIdDesc = 'USERS_BY_PICTURE_ID_MIN_INTERCOM_ID_DESC',
  UsersByPictureIdMaxIdAsc = 'USERS_BY_PICTURE_ID_MAX_ID_ASC',
  UsersByPictureIdMaxIdDesc = 'USERS_BY_PICTURE_ID_MAX_ID_DESC',
  UsersByPictureIdMaxUsernameAsc = 'USERS_BY_PICTURE_ID_MAX_USERNAME_ASC',
  UsersByPictureIdMaxUsernameDesc = 'USERS_BY_PICTURE_ID_MAX_USERNAME_DESC',
  UsersByPictureIdMaxEmailAsc = 'USERS_BY_PICTURE_ID_MAX_EMAIL_ASC',
  UsersByPictureIdMaxEmailDesc = 'USERS_BY_PICTURE_ID_MAX_EMAIL_DESC',
  UsersByPictureIdMaxPhoneNumberAsc = 'USERS_BY_PICTURE_ID_MAX_PHONE_NUMBER_ASC',
  UsersByPictureIdMaxPhoneNumberDesc = 'USERS_BY_PICTURE_ID_MAX_PHONE_NUMBER_DESC',
  UsersByPictureIdMaxFirstNameAsc = 'USERS_BY_PICTURE_ID_MAX_FIRST_NAME_ASC',
  UsersByPictureIdMaxFirstNameDesc = 'USERS_BY_PICTURE_ID_MAX_FIRST_NAME_DESC',
  UsersByPictureIdMaxLastNameAsc = 'USERS_BY_PICTURE_ID_MAX_LAST_NAME_ASC',
  UsersByPictureIdMaxLastNameDesc = 'USERS_BY_PICTURE_ID_MAX_LAST_NAME_DESC',
  UsersByPictureIdMaxTitleAsc = 'USERS_BY_PICTURE_ID_MAX_TITLE_ASC',
  UsersByPictureIdMaxTitleDesc = 'USERS_BY_PICTURE_ID_MAX_TITLE_DESC',
  UsersByPictureIdMaxPositionAsc = 'USERS_BY_PICTURE_ID_MAX_POSITION_ASC',
  UsersByPictureIdMaxPositionDesc = 'USERS_BY_PICTURE_ID_MAX_POSITION_DESC',
  UsersByPictureIdMaxRoleAsc = 'USERS_BY_PICTURE_ID_MAX_ROLE_ASC',
  UsersByPictureIdMaxRoleDesc = 'USERS_BY_PICTURE_ID_MAX_ROLE_DESC',
  UsersByPictureIdMaxStatusAsc = 'USERS_BY_PICTURE_ID_MAX_STATUS_ASC',
  UsersByPictureIdMaxStatusDesc = 'USERS_BY_PICTURE_ID_MAX_STATUS_DESC',
  UsersByPictureIdMaxLastLoginAsc = 'USERS_BY_PICTURE_ID_MAX_LAST_LOGIN_ASC',
  UsersByPictureIdMaxLastLoginDesc = 'USERS_BY_PICTURE_ID_MAX_LAST_LOGIN_DESC',
  UsersByPictureIdMaxEnabledAsc = 'USERS_BY_PICTURE_ID_MAX_ENABLED_ASC',
  UsersByPictureIdMaxEnabledDesc = 'USERS_BY_PICTURE_ID_MAX_ENABLED_DESC',
  UsersByPictureIdMaxCreatedAtAsc = 'USERS_BY_PICTURE_ID_MAX_CREATED_AT_ASC',
  UsersByPictureIdMaxCreatedAtDesc = 'USERS_BY_PICTURE_ID_MAX_CREATED_AT_DESC',
  UsersByPictureIdMaxUpdatedAtAsc = 'USERS_BY_PICTURE_ID_MAX_UPDATED_AT_ASC',
  UsersByPictureIdMaxUpdatedAtDesc = 'USERS_BY_PICTURE_ID_MAX_UPDATED_AT_DESC',
  UsersByPictureIdMaxPictureIdAsc = 'USERS_BY_PICTURE_ID_MAX_PICTURE_ID_ASC',
  UsersByPictureIdMaxPictureIdDesc = 'USERS_BY_PICTURE_ID_MAX_PICTURE_ID_DESC',
  UsersByPictureIdMaxConfirmedAsc = 'USERS_BY_PICTURE_ID_MAX_CONFIRMED_ASC',
  UsersByPictureIdMaxConfirmedDesc = 'USERS_BY_PICTURE_ID_MAX_CONFIRMED_DESC',
  UsersByPictureIdMaxSelectedCompanyIdAsc = 'USERS_BY_PICTURE_ID_MAX_SELECTED_COMPANY_ID_ASC',
  UsersByPictureIdMaxSelectedCompanyIdDesc = 'USERS_BY_PICTURE_ID_MAX_SELECTED_COMPANY_ID_DESC',
  UsersByPictureIdMaxIntercomIdAsc = 'USERS_BY_PICTURE_ID_MAX_INTERCOM_ID_ASC',
  UsersByPictureIdMaxIntercomIdDesc = 'USERS_BY_PICTURE_ID_MAX_INTERCOM_ID_DESC',
  UsersByPictureIdAverageIdAsc = 'USERS_BY_PICTURE_ID_AVERAGE_ID_ASC',
  UsersByPictureIdAverageIdDesc = 'USERS_BY_PICTURE_ID_AVERAGE_ID_DESC',
  UsersByPictureIdAverageUsernameAsc = 'USERS_BY_PICTURE_ID_AVERAGE_USERNAME_ASC',
  UsersByPictureIdAverageUsernameDesc = 'USERS_BY_PICTURE_ID_AVERAGE_USERNAME_DESC',
  UsersByPictureIdAverageEmailAsc = 'USERS_BY_PICTURE_ID_AVERAGE_EMAIL_ASC',
  UsersByPictureIdAverageEmailDesc = 'USERS_BY_PICTURE_ID_AVERAGE_EMAIL_DESC',
  UsersByPictureIdAveragePhoneNumberAsc = 'USERS_BY_PICTURE_ID_AVERAGE_PHONE_NUMBER_ASC',
  UsersByPictureIdAveragePhoneNumberDesc = 'USERS_BY_PICTURE_ID_AVERAGE_PHONE_NUMBER_DESC',
  UsersByPictureIdAverageFirstNameAsc = 'USERS_BY_PICTURE_ID_AVERAGE_FIRST_NAME_ASC',
  UsersByPictureIdAverageFirstNameDesc = 'USERS_BY_PICTURE_ID_AVERAGE_FIRST_NAME_DESC',
  UsersByPictureIdAverageLastNameAsc = 'USERS_BY_PICTURE_ID_AVERAGE_LAST_NAME_ASC',
  UsersByPictureIdAverageLastNameDesc = 'USERS_BY_PICTURE_ID_AVERAGE_LAST_NAME_DESC',
  UsersByPictureIdAverageTitleAsc = 'USERS_BY_PICTURE_ID_AVERAGE_TITLE_ASC',
  UsersByPictureIdAverageTitleDesc = 'USERS_BY_PICTURE_ID_AVERAGE_TITLE_DESC',
  UsersByPictureIdAveragePositionAsc = 'USERS_BY_PICTURE_ID_AVERAGE_POSITION_ASC',
  UsersByPictureIdAveragePositionDesc = 'USERS_BY_PICTURE_ID_AVERAGE_POSITION_DESC',
  UsersByPictureIdAverageRoleAsc = 'USERS_BY_PICTURE_ID_AVERAGE_ROLE_ASC',
  UsersByPictureIdAverageRoleDesc = 'USERS_BY_PICTURE_ID_AVERAGE_ROLE_DESC',
  UsersByPictureIdAverageStatusAsc = 'USERS_BY_PICTURE_ID_AVERAGE_STATUS_ASC',
  UsersByPictureIdAverageStatusDesc = 'USERS_BY_PICTURE_ID_AVERAGE_STATUS_DESC',
  UsersByPictureIdAverageLastLoginAsc = 'USERS_BY_PICTURE_ID_AVERAGE_LAST_LOGIN_ASC',
  UsersByPictureIdAverageLastLoginDesc = 'USERS_BY_PICTURE_ID_AVERAGE_LAST_LOGIN_DESC',
  UsersByPictureIdAverageEnabledAsc = 'USERS_BY_PICTURE_ID_AVERAGE_ENABLED_ASC',
  UsersByPictureIdAverageEnabledDesc = 'USERS_BY_PICTURE_ID_AVERAGE_ENABLED_DESC',
  UsersByPictureIdAverageCreatedAtAsc = 'USERS_BY_PICTURE_ID_AVERAGE_CREATED_AT_ASC',
  UsersByPictureIdAverageCreatedAtDesc = 'USERS_BY_PICTURE_ID_AVERAGE_CREATED_AT_DESC',
  UsersByPictureIdAverageUpdatedAtAsc = 'USERS_BY_PICTURE_ID_AVERAGE_UPDATED_AT_ASC',
  UsersByPictureIdAverageUpdatedAtDesc = 'USERS_BY_PICTURE_ID_AVERAGE_UPDATED_AT_DESC',
  UsersByPictureIdAveragePictureIdAsc = 'USERS_BY_PICTURE_ID_AVERAGE_PICTURE_ID_ASC',
  UsersByPictureIdAveragePictureIdDesc = 'USERS_BY_PICTURE_ID_AVERAGE_PICTURE_ID_DESC',
  UsersByPictureIdAverageConfirmedAsc = 'USERS_BY_PICTURE_ID_AVERAGE_CONFIRMED_ASC',
  UsersByPictureIdAverageConfirmedDesc = 'USERS_BY_PICTURE_ID_AVERAGE_CONFIRMED_DESC',
  UsersByPictureIdAverageSelectedCompanyIdAsc = 'USERS_BY_PICTURE_ID_AVERAGE_SELECTED_COMPANY_ID_ASC',
  UsersByPictureIdAverageSelectedCompanyIdDesc = 'USERS_BY_PICTURE_ID_AVERAGE_SELECTED_COMPANY_ID_DESC',
  UsersByPictureIdAverageIntercomIdAsc = 'USERS_BY_PICTURE_ID_AVERAGE_INTERCOM_ID_ASC',
  UsersByPictureIdAverageIntercomIdDesc = 'USERS_BY_PICTURE_ID_AVERAGE_INTERCOM_ID_DESC',
  UsersByPictureIdStddevSampleIdAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_ID_ASC',
  UsersByPictureIdStddevSampleIdDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_ID_DESC',
  UsersByPictureIdStddevSampleUsernameAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_USERNAME_ASC',
  UsersByPictureIdStddevSampleUsernameDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_USERNAME_DESC',
  UsersByPictureIdStddevSampleEmailAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_EMAIL_ASC',
  UsersByPictureIdStddevSampleEmailDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_EMAIL_DESC',
  UsersByPictureIdStddevSamplePhoneNumberAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_PHONE_NUMBER_ASC',
  UsersByPictureIdStddevSamplePhoneNumberDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_PHONE_NUMBER_DESC',
  UsersByPictureIdStddevSampleFirstNameAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_FIRST_NAME_ASC',
  UsersByPictureIdStddevSampleFirstNameDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_FIRST_NAME_DESC',
  UsersByPictureIdStddevSampleLastNameAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_LAST_NAME_ASC',
  UsersByPictureIdStddevSampleLastNameDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_LAST_NAME_DESC',
  UsersByPictureIdStddevSampleTitleAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_TITLE_ASC',
  UsersByPictureIdStddevSampleTitleDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_TITLE_DESC',
  UsersByPictureIdStddevSamplePositionAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_POSITION_ASC',
  UsersByPictureIdStddevSamplePositionDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_POSITION_DESC',
  UsersByPictureIdStddevSampleRoleAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_ROLE_ASC',
  UsersByPictureIdStddevSampleRoleDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_ROLE_DESC',
  UsersByPictureIdStddevSampleStatusAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_STATUS_ASC',
  UsersByPictureIdStddevSampleStatusDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_STATUS_DESC',
  UsersByPictureIdStddevSampleLastLoginAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_LAST_LOGIN_ASC',
  UsersByPictureIdStddevSampleLastLoginDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_LAST_LOGIN_DESC',
  UsersByPictureIdStddevSampleEnabledAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_ENABLED_ASC',
  UsersByPictureIdStddevSampleEnabledDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_ENABLED_DESC',
  UsersByPictureIdStddevSampleCreatedAtAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  UsersByPictureIdStddevSampleCreatedAtDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  UsersByPictureIdStddevSampleUpdatedAtAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  UsersByPictureIdStddevSampleUpdatedAtDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  UsersByPictureIdStddevSamplePictureIdAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_PICTURE_ID_ASC',
  UsersByPictureIdStddevSamplePictureIdDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_PICTURE_ID_DESC',
  UsersByPictureIdStddevSampleConfirmedAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_CONFIRMED_ASC',
  UsersByPictureIdStddevSampleConfirmedDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_CONFIRMED_DESC',
  UsersByPictureIdStddevSampleSelectedCompanyIdAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_SELECTED_COMPANY_ID_ASC',
  UsersByPictureIdStddevSampleSelectedCompanyIdDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_SELECTED_COMPANY_ID_DESC',
  UsersByPictureIdStddevSampleIntercomIdAsc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_INTERCOM_ID_ASC',
  UsersByPictureIdStddevSampleIntercomIdDesc = 'USERS_BY_PICTURE_ID_STDDEV_SAMPLE_INTERCOM_ID_DESC',
  UsersByPictureIdStddevPopulationIdAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_ID_ASC',
  UsersByPictureIdStddevPopulationIdDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_ID_DESC',
  UsersByPictureIdStddevPopulationUsernameAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_USERNAME_ASC',
  UsersByPictureIdStddevPopulationUsernameDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_USERNAME_DESC',
  UsersByPictureIdStddevPopulationEmailAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_EMAIL_ASC',
  UsersByPictureIdStddevPopulationEmailDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_EMAIL_DESC',
  UsersByPictureIdStddevPopulationPhoneNumberAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_PHONE_NUMBER_ASC',
  UsersByPictureIdStddevPopulationPhoneNumberDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_PHONE_NUMBER_DESC',
  UsersByPictureIdStddevPopulationFirstNameAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_FIRST_NAME_ASC',
  UsersByPictureIdStddevPopulationFirstNameDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_FIRST_NAME_DESC',
  UsersByPictureIdStddevPopulationLastNameAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_LAST_NAME_ASC',
  UsersByPictureIdStddevPopulationLastNameDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_LAST_NAME_DESC',
  UsersByPictureIdStddevPopulationTitleAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_TITLE_ASC',
  UsersByPictureIdStddevPopulationTitleDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_TITLE_DESC',
  UsersByPictureIdStddevPopulationPositionAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_POSITION_ASC',
  UsersByPictureIdStddevPopulationPositionDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_POSITION_DESC',
  UsersByPictureIdStddevPopulationRoleAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_ROLE_ASC',
  UsersByPictureIdStddevPopulationRoleDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_ROLE_DESC',
  UsersByPictureIdStddevPopulationStatusAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_STATUS_ASC',
  UsersByPictureIdStddevPopulationStatusDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_STATUS_DESC',
  UsersByPictureIdStddevPopulationLastLoginAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_LAST_LOGIN_ASC',
  UsersByPictureIdStddevPopulationLastLoginDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_LAST_LOGIN_DESC',
  UsersByPictureIdStddevPopulationEnabledAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_ENABLED_ASC',
  UsersByPictureIdStddevPopulationEnabledDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_ENABLED_DESC',
  UsersByPictureIdStddevPopulationCreatedAtAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  UsersByPictureIdStddevPopulationCreatedAtDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  UsersByPictureIdStddevPopulationUpdatedAtAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  UsersByPictureIdStddevPopulationUpdatedAtDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  UsersByPictureIdStddevPopulationPictureIdAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_PICTURE_ID_ASC',
  UsersByPictureIdStddevPopulationPictureIdDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_PICTURE_ID_DESC',
  UsersByPictureIdStddevPopulationConfirmedAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_CONFIRMED_ASC',
  UsersByPictureIdStddevPopulationConfirmedDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_CONFIRMED_DESC',
  UsersByPictureIdStddevPopulationSelectedCompanyIdAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_SELECTED_COMPANY_ID_ASC',
  UsersByPictureIdStddevPopulationSelectedCompanyIdDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_SELECTED_COMPANY_ID_DESC',
  UsersByPictureIdStddevPopulationIntercomIdAsc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_INTERCOM_ID_ASC',
  UsersByPictureIdStddevPopulationIntercomIdDesc = 'USERS_BY_PICTURE_ID_STDDEV_POPULATION_INTERCOM_ID_DESC',
  UsersByPictureIdVarianceSampleIdAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_ID_ASC',
  UsersByPictureIdVarianceSampleIdDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_ID_DESC',
  UsersByPictureIdVarianceSampleUsernameAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_USERNAME_ASC',
  UsersByPictureIdVarianceSampleUsernameDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_USERNAME_DESC',
  UsersByPictureIdVarianceSampleEmailAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_EMAIL_ASC',
  UsersByPictureIdVarianceSampleEmailDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_EMAIL_DESC',
  UsersByPictureIdVarianceSamplePhoneNumberAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_PHONE_NUMBER_ASC',
  UsersByPictureIdVarianceSamplePhoneNumberDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_PHONE_NUMBER_DESC',
  UsersByPictureIdVarianceSampleFirstNameAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  UsersByPictureIdVarianceSampleFirstNameDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  UsersByPictureIdVarianceSampleLastNameAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_LAST_NAME_ASC',
  UsersByPictureIdVarianceSampleLastNameDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_LAST_NAME_DESC',
  UsersByPictureIdVarianceSampleTitleAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_TITLE_ASC',
  UsersByPictureIdVarianceSampleTitleDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_TITLE_DESC',
  UsersByPictureIdVarianceSamplePositionAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_POSITION_ASC',
  UsersByPictureIdVarianceSamplePositionDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_POSITION_DESC',
  UsersByPictureIdVarianceSampleRoleAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_ROLE_ASC',
  UsersByPictureIdVarianceSampleRoleDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_ROLE_DESC',
  UsersByPictureIdVarianceSampleStatusAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_STATUS_ASC',
  UsersByPictureIdVarianceSampleStatusDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_STATUS_DESC',
  UsersByPictureIdVarianceSampleLastLoginAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_LAST_LOGIN_ASC',
  UsersByPictureIdVarianceSampleLastLoginDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_LAST_LOGIN_DESC',
  UsersByPictureIdVarianceSampleEnabledAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_ENABLED_ASC',
  UsersByPictureIdVarianceSampleEnabledDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_ENABLED_DESC',
  UsersByPictureIdVarianceSampleCreatedAtAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  UsersByPictureIdVarianceSampleCreatedAtDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  UsersByPictureIdVarianceSampleUpdatedAtAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  UsersByPictureIdVarianceSampleUpdatedAtDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UsersByPictureIdVarianceSamplePictureIdAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_PICTURE_ID_ASC',
  UsersByPictureIdVarianceSamplePictureIdDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_PICTURE_ID_DESC',
  UsersByPictureIdVarianceSampleConfirmedAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_CONFIRMED_ASC',
  UsersByPictureIdVarianceSampleConfirmedDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_CONFIRMED_DESC',
  UsersByPictureIdVarianceSampleSelectedCompanyIdAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_SELECTED_COMPANY_ID_ASC',
  UsersByPictureIdVarianceSampleSelectedCompanyIdDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_SELECTED_COMPANY_ID_DESC',
  UsersByPictureIdVarianceSampleIntercomIdAsc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_INTERCOM_ID_ASC',
  UsersByPictureIdVarianceSampleIntercomIdDesc = 'USERS_BY_PICTURE_ID_VARIANCE_SAMPLE_INTERCOM_ID_DESC',
  UsersByPictureIdVariancePopulationIdAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_ID_ASC',
  UsersByPictureIdVariancePopulationIdDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_ID_DESC',
  UsersByPictureIdVariancePopulationUsernameAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_USERNAME_ASC',
  UsersByPictureIdVariancePopulationUsernameDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_USERNAME_DESC',
  UsersByPictureIdVariancePopulationEmailAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_EMAIL_ASC',
  UsersByPictureIdVariancePopulationEmailDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_EMAIL_DESC',
  UsersByPictureIdVariancePopulationPhoneNumberAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_PHONE_NUMBER_ASC',
  UsersByPictureIdVariancePopulationPhoneNumberDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_PHONE_NUMBER_DESC',
  UsersByPictureIdVariancePopulationFirstNameAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_FIRST_NAME_ASC',
  UsersByPictureIdVariancePopulationFirstNameDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_FIRST_NAME_DESC',
  UsersByPictureIdVariancePopulationLastNameAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_LAST_NAME_ASC',
  UsersByPictureIdVariancePopulationLastNameDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_LAST_NAME_DESC',
  UsersByPictureIdVariancePopulationTitleAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_TITLE_ASC',
  UsersByPictureIdVariancePopulationTitleDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_TITLE_DESC',
  UsersByPictureIdVariancePopulationPositionAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_POSITION_ASC',
  UsersByPictureIdVariancePopulationPositionDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_POSITION_DESC',
  UsersByPictureIdVariancePopulationRoleAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_ROLE_ASC',
  UsersByPictureIdVariancePopulationRoleDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_ROLE_DESC',
  UsersByPictureIdVariancePopulationStatusAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_STATUS_ASC',
  UsersByPictureIdVariancePopulationStatusDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_STATUS_DESC',
  UsersByPictureIdVariancePopulationLastLoginAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_LAST_LOGIN_ASC',
  UsersByPictureIdVariancePopulationLastLoginDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_LAST_LOGIN_DESC',
  UsersByPictureIdVariancePopulationEnabledAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_ENABLED_ASC',
  UsersByPictureIdVariancePopulationEnabledDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_ENABLED_DESC',
  UsersByPictureIdVariancePopulationCreatedAtAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  UsersByPictureIdVariancePopulationCreatedAtDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  UsersByPictureIdVariancePopulationUpdatedAtAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  UsersByPictureIdVariancePopulationUpdatedAtDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  UsersByPictureIdVariancePopulationPictureIdAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_PICTURE_ID_ASC',
  UsersByPictureIdVariancePopulationPictureIdDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_PICTURE_ID_DESC',
  UsersByPictureIdVariancePopulationConfirmedAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_CONFIRMED_ASC',
  UsersByPictureIdVariancePopulationConfirmedDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_CONFIRMED_DESC',
  UsersByPictureIdVariancePopulationSelectedCompanyIdAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_SELECTED_COMPANY_ID_ASC',
  UsersByPictureIdVariancePopulationSelectedCompanyIdDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_SELECTED_COMPANY_ID_DESC',
  UsersByPictureIdVariancePopulationIntercomIdAsc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_INTERCOM_ID_ASC',
  UsersByPictureIdVariancePopulationIntercomIdDesc = 'USERS_BY_PICTURE_ID_VARIANCE_POPULATION_INTERCOM_ID_DESC',
  CompanyChannelMediasCountAsc = 'COMPANY_CHANNEL_MEDIAS_COUNT_ASC',
  CompanyChannelMediasCountDesc = 'COMPANY_CHANNEL_MEDIAS_COUNT_DESC',
  CompanyChannelMediasSumIdAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_ID_ASC',
  CompanyChannelMediasSumIdDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_ID_DESC',
  CompanyChannelMediasSumCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasSumCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasSumMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_MEDIA_ID_ASC',
  CompanyChannelMediasSumMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_MEDIA_ID_DESC',
  CompanyChannelMediasSumAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_ASSIGNEE_ID_ASC',
  CompanyChannelMediasSumAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_ASSIGNEE_ID_DESC',
  CompanyChannelMediasSumCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_CREATED_AT_ASC',
  CompanyChannelMediasSumCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_CREATED_AT_DESC',
  CompanyChannelMediasSumUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_UPDATED_AT_ASC',
  CompanyChannelMediasSumUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_UPDATED_AT_DESC',
  CompanyChannelMediasSumDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_SUM_DELETED_AT_ASC',
  CompanyChannelMediasSumDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_SUM_DELETED_AT_DESC',
  CompanyChannelMediasDistinctCountIdAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_ID_ASC',
  CompanyChannelMediasDistinctCountIdDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_ID_DESC',
  CompanyChannelMediasDistinctCountCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasDistinctCountCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasDistinctCountMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_MEDIA_ID_ASC',
  CompanyChannelMediasDistinctCountMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_MEDIA_ID_DESC',
  CompanyChannelMediasDistinctCountAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  CompanyChannelMediasDistinctCountAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  CompanyChannelMediasDistinctCountCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyChannelMediasDistinctCountCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyChannelMediasDistinctCountUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyChannelMediasDistinctCountUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyChannelMediasDistinctCountDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_DELETED_AT_ASC',
  CompanyChannelMediasDistinctCountDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_DISTINCT_COUNT_DELETED_AT_DESC',
  CompanyChannelMediasMinIdAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_ID_ASC',
  CompanyChannelMediasMinIdDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_ID_DESC',
  CompanyChannelMediasMinCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasMinCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasMinMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_MEDIA_ID_ASC',
  CompanyChannelMediasMinMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_MEDIA_ID_DESC',
  CompanyChannelMediasMinAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_ASSIGNEE_ID_ASC',
  CompanyChannelMediasMinAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_ASSIGNEE_ID_DESC',
  CompanyChannelMediasMinCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_CREATED_AT_ASC',
  CompanyChannelMediasMinCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_CREATED_AT_DESC',
  CompanyChannelMediasMinUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_UPDATED_AT_ASC',
  CompanyChannelMediasMinUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_UPDATED_AT_DESC',
  CompanyChannelMediasMinDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MIN_DELETED_AT_ASC',
  CompanyChannelMediasMinDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MIN_DELETED_AT_DESC',
  CompanyChannelMediasMaxIdAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_ID_ASC',
  CompanyChannelMediasMaxIdDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_ID_DESC',
  CompanyChannelMediasMaxCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasMaxCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasMaxMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_MEDIA_ID_ASC',
  CompanyChannelMediasMaxMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_MEDIA_ID_DESC',
  CompanyChannelMediasMaxAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_ASSIGNEE_ID_ASC',
  CompanyChannelMediasMaxAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_ASSIGNEE_ID_DESC',
  CompanyChannelMediasMaxCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_CREATED_AT_ASC',
  CompanyChannelMediasMaxCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_CREATED_AT_DESC',
  CompanyChannelMediasMaxUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_UPDATED_AT_ASC',
  CompanyChannelMediasMaxUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_UPDATED_AT_DESC',
  CompanyChannelMediasMaxDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_MAX_DELETED_AT_ASC',
  CompanyChannelMediasMaxDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_MAX_DELETED_AT_DESC',
  CompanyChannelMediasAverageIdAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_ID_ASC',
  CompanyChannelMediasAverageIdDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_ID_DESC',
  CompanyChannelMediasAverageCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasAverageCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasAverageMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_MEDIA_ID_ASC',
  CompanyChannelMediasAverageMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_MEDIA_ID_DESC',
  CompanyChannelMediasAverageAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_ASSIGNEE_ID_ASC',
  CompanyChannelMediasAverageAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_ASSIGNEE_ID_DESC',
  CompanyChannelMediasAverageCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_CREATED_AT_ASC',
  CompanyChannelMediasAverageCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_CREATED_AT_DESC',
  CompanyChannelMediasAverageUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_UPDATED_AT_ASC',
  CompanyChannelMediasAverageUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_UPDATED_AT_DESC',
  CompanyChannelMediasAverageDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_DELETED_AT_ASC',
  CompanyChannelMediasAverageDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_AVERAGE_DELETED_AT_DESC',
  CompanyChannelMediasStddevSampleIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_ID_ASC',
  CompanyChannelMediasStddevSampleIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_ID_DESC',
  CompanyChannelMediasStddevSampleCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasStddevSampleCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasStddevSampleMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_MEDIA_ID_ASC',
  CompanyChannelMediasStddevSampleMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_MEDIA_ID_DESC',
  CompanyChannelMediasStddevSampleAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  CompanyChannelMediasStddevSampleAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  CompanyChannelMediasStddevSampleCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyChannelMediasStddevSampleCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyChannelMediasStddevSampleUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelMediasStddevSampleUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelMediasStddevSampleDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_DELETED_AT_ASC',
  CompanyChannelMediasStddevSampleDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_SAMPLE_DELETED_AT_DESC',
  CompanyChannelMediasStddevPopulationIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_ID_ASC',
  CompanyChannelMediasStddevPopulationIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_ID_DESC',
  CompanyChannelMediasStddevPopulationCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasStddevPopulationCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasStddevPopulationMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_MEDIA_ID_ASC',
  CompanyChannelMediasStddevPopulationMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_MEDIA_ID_DESC',
  CompanyChannelMediasStddevPopulationAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  CompanyChannelMediasStddevPopulationAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  CompanyChannelMediasStddevPopulationCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyChannelMediasStddevPopulationCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyChannelMediasStddevPopulationUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyChannelMediasStddevPopulationUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyChannelMediasStddevPopulationDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_DELETED_AT_ASC',
  CompanyChannelMediasStddevPopulationDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_STDDEV_POPULATION_DELETED_AT_DESC',
  CompanyChannelMediasVarianceSampleIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_ID_ASC',
  CompanyChannelMediasVarianceSampleIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_ID_DESC',
  CompanyChannelMediasVarianceSampleCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasVarianceSampleCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasVarianceSampleMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_MEDIA_ID_ASC',
  CompanyChannelMediasVarianceSampleMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_MEDIA_ID_DESC',
  CompanyChannelMediasVarianceSampleAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  CompanyChannelMediasVarianceSampleAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  CompanyChannelMediasVarianceSampleCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyChannelMediasVarianceSampleCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyChannelMediasVarianceSampleUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelMediasVarianceSampleUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelMediasVarianceSampleDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  CompanyChannelMediasVarianceSampleDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  CompanyChannelMediasVariancePopulationIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_ID_ASC',
  CompanyChannelMediasVariancePopulationIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_ID_DESC',
  CompanyChannelMediasVariancePopulationCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasVariancePopulationCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasVariancePopulationMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_MEDIA_ID_ASC',
  CompanyChannelMediasVariancePopulationMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_MEDIA_ID_DESC',
  CompanyChannelMediasVariancePopulationAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  CompanyChannelMediasVariancePopulationAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  CompanyChannelMediasVariancePopulationCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyChannelMediasVariancePopulationCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyChannelMediasVariancePopulationUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyChannelMediasVariancePopulationUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyChannelMediasVariancePopulationDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_DELETED_AT_ASC',
  CompanyChannelMediasVariancePopulationDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_VARIANCE_POPULATION_DELETED_AT_DESC',
  IdMediasCountAsc = 'ID_MEDIAS_COUNT_ASC',
  IdMediasCountDesc = 'ID_MEDIAS_COUNT_DESC',
  IdMediasSumIdAsc = 'ID_MEDIAS_SUM_ID_ASC',
  IdMediasSumIdDesc = 'ID_MEDIAS_SUM_ID_DESC',
  IdMediasSumVerificationIdAsc = 'ID_MEDIAS_SUM_VERIFICATION_ID_ASC',
  IdMediasSumVerificationIdDesc = 'ID_MEDIAS_SUM_VERIFICATION_ID_DESC',
  IdMediasSumMediaIdAsc = 'ID_MEDIAS_SUM_MEDIA_ID_ASC',
  IdMediasSumMediaIdDesc = 'ID_MEDIAS_SUM_MEDIA_ID_DESC',
  IdMediasSumNameAsc = 'ID_MEDIAS_SUM_NAME_ASC',
  IdMediasSumNameDesc = 'ID_MEDIAS_SUM_NAME_DESC',
  IdMediasSumDisplayNameAsc = 'ID_MEDIAS_SUM_DISPLAY_NAME_ASC',
  IdMediasSumDisplayNameDesc = 'ID_MEDIAS_SUM_DISPLAY_NAME_DESC',
  IdMediasSumCreatedAtAsc = 'ID_MEDIAS_SUM_CREATED_AT_ASC',
  IdMediasSumCreatedAtDesc = 'ID_MEDIAS_SUM_CREATED_AT_DESC',
  IdMediasSumUpdatedAtAsc = 'ID_MEDIAS_SUM_UPDATED_AT_ASC',
  IdMediasSumUpdatedAtDesc = 'ID_MEDIAS_SUM_UPDATED_AT_DESC',
  IdMediasSumDeletedAtAsc = 'ID_MEDIAS_SUM_DELETED_AT_ASC',
  IdMediasSumDeletedAtDesc = 'ID_MEDIAS_SUM_DELETED_AT_DESC',
  IdMediasDistinctCountIdAsc = 'ID_MEDIAS_DISTINCT_COUNT_ID_ASC',
  IdMediasDistinctCountIdDesc = 'ID_MEDIAS_DISTINCT_COUNT_ID_DESC',
  IdMediasDistinctCountVerificationIdAsc = 'ID_MEDIAS_DISTINCT_COUNT_VERIFICATION_ID_ASC',
  IdMediasDistinctCountVerificationIdDesc = 'ID_MEDIAS_DISTINCT_COUNT_VERIFICATION_ID_DESC',
  IdMediasDistinctCountMediaIdAsc = 'ID_MEDIAS_DISTINCT_COUNT_MEDIA_ID_ASC',
  IdMediasDistinctCountMediaIdDesc = 'ID_MEDIAS_DISTINCT_COUNT_MEDIA_ID_DESC',
  IdMediasDistinctCountNameAsc = 'ID_MEDIAS_DISTINCT_COUNT_NAME_ASC',
  IdMediasDistinctCountNameDesc = 'ID_MEDIAS_DISTINCT_COUNT_NAME_DESC',
  IdMediasDistinctCountDisplayNameAsc = 'ID_MEDIAS_DISTINCT_COUNT_DISPLAY_NAME_ASC',
  IdMediasDistinctCountDisplayNameDesc = 'ID_MEDIAS_DISTINCT_COUNT_DISPLAY_NAME_DESC',
  IdMediasDistinctCountCreatedAtAsc = 'ID_MEDIAS_DISTINCT_COUNT_CREATED_AT_ASC',
  IdMediasDistinctCountCreatedAtDesc = 'ID_MEDIAS_DISTINCT_COUNT_CREATED_AT_DESC',
  IdMediasDistinctCountUpdatedAtAsc = 'ID_MEDIAS_DISTINCT_COUNT_UPDATED_AT_ASC',
  IdMediasDistinctCountUpdatedAtDesc = 'ID_MEDIAS_DISTINCT_COUNT_UPDATED_AT_DESC',
  IdMediasDistinctCountDeletedAtAsc = 'ID_MEDIAS_DISTINCT_COUNT_DELETED_AT_ASC',
  IdMediasDistinctCountDeletedAtDesc = 'ID_MEDIAS_DISTINCT_COUNT_DELETED_AT_DESC',
  IdMediasMinIdAsc = 'ID_MEDIAS_MIN_ID_ASC',
  IdMediasMinIdDesc = 'ID_MEDIAS_MIN_ID_DESC',
  IdMediasMinVerificationIdAsc = 'ID_MEDIAS_MIN_VERIFICATION_ID_ASC',
  IdMediasMinVerificationIdDesc = 'ID_MEDIAS_MIN_VERIFICATION_ID_DESC',
  IdMediasMinMediaIdAsc = 'ID_MEDIAS_MIN_MEDIA_ID_ASC',
  IdMediasMinMediaIdDesc = 'ID_MEDIAS_MIN_MEDIA_ID_DESC',
  IdMediasMinNameAsc = 'ID_MEDIAS_MIN_NAME_ASC',
  IdMediasMinNameDesc = 'ID_MEDIAS_MIN_NAME_DESC',
  IdMediasMinDisplayNameAsc = 'ID_MEDIAS_MIN_DISPLAY_NAME_ASC',
  IdMediasMinDisplayNameDesc = 'ID_MEDIAS_MIN_DISPLAY_NAME_DESC',
  IdMediasMinCreatedAtAsc = 'ID_MEDIAS_MIN_CREATED_AT_ASC',
  IdMediasMinCreatedAtDesc = 'ID_MEDIAS_MIN_CREATED_AT_DESC',
  IdMediasMinUpdatedAtAsc = 'ID_MEDIAS_MIN_UPDATED_AT_ASC',
  IdMediasMinUpdatedAtDesc = 'ID_MEDIAS_MIN_UPDATED_AT_DESC',
  IdMediasMinDeletedAtAsc = 'ID_MEDIAS_MIN_DELETED_AT_ASC',
  IdMediasMinDeletedAtDesc = 'ID_MEDIAS_MIN_DELETED_AT_DESC',
  IdMediasMaxIdAsc = 'ID_MEDIAS_MAX_ID_ASC',
  IdMediasMaxIdDesc = 'ID_MEDIAS_MAX_ID_DESC',
  IdMediasMaxVerificationIdAsc = 'ID_MEDIAS_MAX_VERIFICATION_ID_ASC',
  IdMediasMaxVerificationIdDesc = 'ID_MEDIAS_MAX_VERIFICATION_ID_DESC',
  IdMediasMaxMediaIdAsc = 'ID_MEDIAS_MAX_MEDIA_ID_ASC',
  IdMediasMaxMediaIdDesc = 'ID_MEDIAS_MAX_MEDIA_ID_DESC',
  IdMediasMaxNameAsc = 'ID_MEDIAS_MAX_NAME_ASC',
  IdMediasMaxNameDesc = 'ID_MEDIAS_MAX_NAME_DESC',
  IdMediasMaxDisplayNameAsc = 'ID_MEDIAS_MAX_DISPLAY_NAME_ASC',
  IdMediasMaxDisplayNameDesc = 'ID_MEDIAS_MAX_DISPLAY_NAME_DESC',
  IdMediasMaxCreatedAtAsc = 'ID_MEDIAS_MAX_CREATED_AT_ASC',
  IdMediasMaxCreatedAtDesc = 'ID_MEDIAS_MAX_CREATED_AT_DESC',
  IdMediasMaxUpdatedAtAsc = 'ID_MEDIAS_MAX_UPDATED_AT_ASC',
  IdMediasMaxUpdatedAtDesc = 'ID_MEDIAS_MAX_UPDATED_AT_DESC',
  IdMediasMaxDeletedAtAsc = 'ID_MEDIAS_MAX_DELETED_AT_ASC',
  IdMediasMaxDeletedAtDesc = 'ID_MEDIAS_MAX_DELETED_AT_DESC',
  IdMediasAverageIdAsc = 'ID_MEDIAS_AVERAGE_ID_ASC',
  IdMediasAverageIdDesc = 'ID_MEDIAS_AVERAGE_ID_DESC',
  IdMediasAverageVerificationIdAsc = 'ID_MEDIAS_AVERAGE_VERIFICATION_ID_ASC',
  IdMediasAverageVerificationIdDesc = 'ID_MEDIAS_AVERAGE_VERIFICATION_ID_DESC',
  IdMediasAverageMediaIdAsc = 'ID_MEDIAS_AVERAGE_MEDIA_ID_ASC',
  IdMediasAverageMediaIdDesc = 'ID_MEDIAS_AVERAGE_MEDIA_ID_DESC',
  IdMediasAverageNameAsc = 'ID_MEDIAS_AVERAGE_NAME_ASC',
  IdMediasAverageNameDesc = 'ID_MEDIAS_AVERAGE_NAME_DESC',
  IdMediasAverageDisplayNameAsc = 'ID_MEDIAS_AVERAGE_DISPLAY_NAME_ASC',
  IdMediasAverageDisplayNameDesc = 'ID_MEDIAS_AVERAGE_DISPLAY_NAME_DESC',
  IdMediasAverageCreatedAtAsc = 'ID_MEDIAS_AVERAGE_CREATED_AT_ASC',
  IdMediasAverageCreatedAtDesc = 'ID_MEDIAS_AVERAGE_CREATED_AT_DESC',
  IdMediasAverageUpdatedAtAsc = 'ID_MEDIAS_AVERAGE_UPDATED_AT_ASC',
  IdMediasAverageUpdatedAtDesc = 'ID_MEDIAS_AVERAGE_UPDATED_AT_DESC',
  IdMediasAverageDeletedAtAsc = 'ID_MEDIAS_AVERAGE_DELETED_AT_ASC',
  IdMediasAverageDeletedAtDesc = 'ID_MEDIAS_AVERAGE_DELETED_AT_DESC',
  IdMediasStddevSampleIdAsc = 'ID_MEDIAS_STDDEV_SAMPLE_ID_ASC',
  IdMediasStddevSampleIdDesc = 'ID_MEDIAS_STDDEV_SAMPLE_ID_DESC',
  IdMediasStddevSampleVerificationIdAsc = 'ID_MEDIAS_STDDEV_SAMPLE_VERIFICATION_ID_ASC',
  IdMediasStddevSampleVerificationIdDesc = 'ID_MEDIAS_STDDEV_SAMPLE_VERIFICATION_ID_DESC',
  IdMediasStddevSampleMediaIdAsc = 'ID_MEDIAS_STDDEV_SAMPLE_MEDIA_ID_ASC',
  IdMediasStddevSampleMediaIdDesc = 'ID_MEDIAS_STDDEV_SAMPLE_MEDIA_ID_DESC',
  IdMediasStddevSampleNameAsc = 'ID_MEDIAS_STDDEV_SAMPLE_NAME_ASC',
  IdMediasStddevSampleNameDesc = 'ID_MEDIAS_STDDEV_SAMPLE_NAME_DESC',
  IdMediasStddevSampleDisplayNameAsc = 'ID_MEDIAS_STDDEV_SAMPLE_DISPLAY_NAME_ASC',
  IdMediasStddevSampleDisplayNameDesc = 'ID_MEDIAS_STDDEV_SAMPLE_DISPLAY_NAME_DESC',
  IdMediasStddevSampleCreatedAtAsc = 'ID_MEDIAS_STDDEV_SAMPLE_CREATED_AT_ASC',
  IdMediasStddevSampleCreatedAtDesc = 'ID_MEDIAS_STDDEV_SAMPLE_CREATED_AT_DESC',
  IdMediasStddevSampleUpdatedAtAsc = 'ID_MEDIAS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  IdMediasStddevSampleUpdatedAtDesc = 'ID_MEDIAS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  IdMediasStddevSampleDeletedAtAsc = 'ID_MEDIAS_STDDEV_SAMPLE_DELETED_AT_ASC',
  IdMediasStddevSampleDeletedAtDesc = 'ID_MEDIAS_STDDEV_SAMPLE_DELETED_AT_DESC',
  IdMediasStddevPopulationIdAsc = 'ID_MEDIAS_STDDEV_POPULATION_ID_ASC',
  IdMediasStddevPopulationIdDesc = 'ID_MEDIAS_STDDEV_POPULATION_ID_DESC',
  IdMediasStddevPopulationVerificationIdAsc = 'ID_MEDIAS_STDDEV_POPULATION_VERIFICATION_ID_ASC',
  IdMediasStddevPopulationVerificationIdDesc = 'ID_MEDIAS_STDDEV_POPULATION_VERIFICATION_ID_DESC',
  IdMediasStddevPopulationMediaIdAsc = 'ID_MEDIAS_STDDEV_POPULATION_MEDIA_ID_ASC',
  IdMediasStddevPopulationMediaIdDesc = 'ID_MEDIAS_STDDEV_POPULATION_MEDIA_ID_DESC',
  IdMediasStddevPopulationNameAsc = 'ID_MEDIAS_STDDEV_POPULATION_NAME_ASC',
  IdMediasStddevPopulationNameDesc = 'ID_MEDIAS_STDDEV_POPULATION_NAME_DESC',
  IdMediasStddevPopulationDisplayNameAsc = 'ID_MEDIAS_STDDEV_POPULATION_DISPLAY_NAME_ASC',
  IdMediasStddevPopulationDisplayNameDesc = 'ID_MEDIAS_STDDEV_POPULATION_DISPLAY_NAME_DESC',
  IdMediasStddevPopulationCreatedAtAsc = 'ID_MEDIAS_STDDEV_POPULATION_CREATED_AT_ASC',
  IdMediasStddevPopulationCreatedAtDesc = 'ID_MEDIAS_STDDEV_POPULATION_CREATED_AT_DESC',
  IdMediasStddevPopulationUpdatedAtAsc = 'ID_MEDIAS_STDDEV_POPULATION_UPDATED_AT_ASC',
  IdMediasStddevPopulationUpdatedAtDesc = 'ID_MEDIAS_STDDEV_POPULATION_UPDATED_AT_DESC',
  IdMediasStddevPopulationDeletedAtAsc = 'ID_MEDIAS_STDDEV_POPULATION_DELETED_AT_ASC',
  IdMediasStddevPopulationDeletedAtDesc = 'ID_MEDIAS_STDDEV_POPULATION_DELETED_AT_DESC',
  IdMediasVarianceSampleIdAsc = 'ID_MEDIAS_VARIANCE_SAMPLE_ID_ASC',
  IdMediasVarianceSampleIdDesc = 'ID_MEDIAS_VARIANCE_SAMPLE_ID_DESC',
  IdMediasVarianceSampleVerificationIdAsc = 'ID_MEDIAS_VARIANCE_SAMPLE_VERIFICATION_ID_ASC',
  IdMediasVarianceSampleVerificationIdDesc = 'ID_MEDIAS_VARIANCE_SAMPLE_VERIFICATION_ID_DESC',
  IdMediasVarianceSampleMediaIdAsc = 'ID_MEDIAS_VARIANCE_SAMPLE_MEDIA_ID_ASC',
  IdMediasVarianceSampleMediaIdDesc = 'ID_MEDIAS_VARIANCE_SAMPLE_MEDIA_ID_DESC',
  IdMediasVarianceSampleNameAsc = 'ID_MEDIAS_VARIANCE_SAMPLE_NAME_ASC',
  IdMediasVarianceSampleNameDesc = 'ID_MEDIAS_VARIANCE_SAMPLE_NAME_DESC',
  IdMediasVarianceSampleDisplayNameAsc = 'ID_MEDIAS_VARIANCE_SAMPLE_DISPLAY_NAME_ASC',
  IdMediasVarianceSampleDisplayNameDesc = 'ID_MEDIAS_VARIANCE_SAMPLE_DISPLAY_NAME_DESC',
  IdMediasVarianceSampleCreatedAtAsc = 'ID_MEDIAS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  IdMediasVarianceSampleCreatedAtDesc = 'ID_MEDIAS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  IdMediasVarianceSampleUpdatedAtAsc = 'ID_MEDIAS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  IdMediasVarianceSampleUpdatedAtDesc = 'ID_MEDIAS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdMediasVarianceSampleDeletedAtAsc = 'ID_MEDIAS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  IdMediasVarianceSampleDeletedAtDesc = 'ID_MEDIAS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  IdMediasVariancePopulationIdAsc = 'ID_MEDIAS_VARIANCE_POPULATION_ID_ASC',
  IdMediasVariancePopulationIdDesc = 'ID_MEDIAS_VARIANCE_POPULATION_ID_DESC',
  IdMediasVariancePopulationVerificationIdAsc = 'ID_MEDIAS_VARIANCE_POPULATION_VERIFICATION_ID_ASC',
  IdMediasVariancePopulationVerificationIdDesc = 'ID_MEDIAS_VARIANCE_POPULATION_VERIFICATION_ID_DESC',
  IdMediasVariancePopulationMediaIdAsc = 'ID_MEDIAS_VARIANCE_POPULATION_MEDIA_ID_ASC',
  IdMediasVariancePopulationMediaIdDesc = 'ID_MEDIAS_VARIANCE_POPULATION_MEDIA_ID_DESC',
  IdMediasVariancePopulationNameAsc = 'ID_MEDIAS_VARIANCE_POPULATION_NAME_ASC',
  IdMediasVariancePopulationNameDesc = 'ID_MEDIAS_VARIANCE_POPULATION_NAME_DESC',
  IdMediasVariancePopulationDisplayNameAsc = 'ID_MEDIAS_VARIANCE_POPULATION_DISPLAY_NAME_ASC',
  IdMediasVariancePopulationDisplayNameDesc = 'ID_MEDIAS_VARIANCE_POPULATION_DISPLAY_NAME_DESC',
  IdMediasVariancePopulationCreatedAtAsc = 'ID_MEDIAS_VARIANCE_POPULATION_CREATED_AT_ASC',
  IdMediasVariancePopulationCreatedAtDesc = 'ID_MEDIAS_VARIANCE_POPULATION_CREATED_AT_DESC',
  IdMediasVariancePopulationUpdatedAtAsc = 'ID_MEDIAS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  IdMediasVariancePopulationUpdatedAtDesc = 'ID_MEDIAS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  IdMediasVariancePopulationDeletedAtAsc = 'ID_MEDIAS_VARIANCE_POPULATION_DELETED_AT_ASC',
  IdMediasVariancePopulationDeletedAtDesc = 'ID_MEDIAS_VARIANCE_POPULATION_DELETED_AT_DESC'
}

export enum MediaParentType {
  User = 'User',
  CompanyChannel = 'CompanyChannel',
  Company = 'Company',
  IdInquiry = 'IdInquiry'
}

/** Represents an update to a `Media`. Fields that are set will be updated. */
export type MediaPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uploader?: Maybe<Scalars['Int']>;
  s3Key?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  contentType?: Maybe<Scalars['String']>;
  uploadCompletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  fileName?: Maybe<Scalars['String']>;
  companyToCompanyId?: Maybe<MediaCompanyIdFkeyInput>;
  userToUploader?: Maybe<MediaUploaderFkeyInput>;
  usersUsingId?: Maybe<UserPictureIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaMediaIdFkeyInverseInput>;
  idMediaUsingId?: Maybe<IdMediaMediaIdFkeyInverseInput>;
};

export type MediaStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  uploader?: Maybe<BigFloatFilter>;
  parentId?: Maybe<BigFloatFilter>;
};

export type MediaStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of uploader across the matching connection */
  uploader?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type MediaStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  uploader?: Maybe<BigFloatFilter>;
  parentId?: Maybe<BigFloatFilter>;
};

export type MediaStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of uploader across the matching connection */
  uploader?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type MediaSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  uploader?: Maybe<BigIntFilter>;
  parentId?: Maybe<BigIntFilter>;
};

export type MediaSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of uploader across the matching connection */
  uploader: Scalars['BigInt'];
  /** Sum of parentId across the matching connection */
  parentId: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyChannelMedia` object types. All fields are combined with a logical ‘and.’ */
export type MediaToManyCompanyChannelMediaFilter = {
  /** Every related `CompanyChannelMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyChannelMediaFilter>;
  /** Some related `CompanyChannelMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyChannelMediaFilter>;
  /** No related `CompanyChannelMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyChannelMediaFilter>;
  /** Aggregates across related `CompanyChannelMedia` match the filter criteria. */
  aggregates?: Maybe<CompanyChannelMediaAggregatesFilter>;
};

/** A filter to be used against many `IdMedia` object types. All fields are combined with a logical ‘and.’ */
export type MediaToManyIdMediaFilter = {
  /** Every related `IdMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<IdMediaFilter>;
  /** Some related `IdMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<IdMediaFilter>;
  /** No related `IdMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<IdMediaFilter>;
  /** Aggregates across related `IdMedia` match the filter criteria. */
  aggregates?: Maybe<IdMediaAggregatesFilter>;
};

/** A filter to be used against many `User` object types. All fields are combined with a logical ‘and.’ */
export type MediaToManyUserFilter = {
  /** Every related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<UserFilter>;
  /** Some related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<UserFilter>;
  /** No related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<UserFilter>;
  /** Aggregates across related `User` match the filter criteria. */
  aggregates?: Maybe<UserAggregatesFilter>;
};

export enum MediaType {
  UserProfileImage = 'UserProfileImage',
  CompanyChannelAsset = 'CompanyChannelAsset',
  CompanyIcon = 'CompanyIcon',
  CompanyLogo = 'CompanyLogo',
  IdBack = 'IdBack',
  IdFront = 'IdFront',
  SelfieLeft = 'SelfieLeft',
  SelfieCenter = 'SelfieCenter',
  SelfieRight = 'SelfieRight'
}

/** Input for the nested mutation of `user` in the `MediaInput` mutation. */
export type MediaUploaderFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnMediaForMediaUploaderFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<MediaOnMediaForMediaUploaderFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `media` in the `UserInput` mutation. */
export type MediaUploaderFkeyInverseInput = {
  /** The primary key(s) for `media` for the far side of the relationship. */
  connectById?: Maybe<Array<MediaMediaPkeyConnect>>;
  /** The primary key(s) for `media` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<MediaNodeIdConnect>>;
  /** The primary key(s) and patch data for `media` for the far side of the relationship. */
  updateById?: Maybe<Array<MediaOnMediaForMediaUploaderFkeyUsingMediaPkeyUpdate>>;
  /** The primary key(s) and patch data for `media` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnMediaForMediaUploaderFkeyNodeIdUpdate>>;
};

/** A connection to a list of `User` values, with data from `CompanyChannelMedia`. */
export type MediaUsersByCompanyChannelMediaMediaIdAndAssigneeIdManyToManyConnection = {
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `CompanyChannelMedia`, and the cursor to aid in pagination. */
  edges: Array<MediaUsersByCompanyChannelMediaMediaIdAndAssigneeIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
};


/** A connection to a list of `User` values, with data from `CompanyChannelMedia`. */
export type MediaUsersByCompanyChannelMediaMediaIdAndAssigneeIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having?: Maybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `CompanyChannelMedia`. */
export type MediaUsersByCompanyChannelMediaMediaIdAndAssigneeIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `CompanyChannelMedia`. */
  companyChannelMediasByAssigneeId: CompanyChannelMediaConnection;
};


/** A `User` edge in the connection, with data from `CompanyChannelMedia`. */
export type MediaUsersByCompanyChannelMediaMediaIdAndAssigneeIdManyToManyEdgeCompanyChannelMediasByAssigneeIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelMediaOrderBy>>;
  condition?: Maybe<CompanyChannelMediaCondition>;
  filter?: Maybe<CompanyChannelMediaFilter>;
};

export type MediaVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  uploader?: Maybe<BigFloatFilter>;
  parentId?: Maybe<BigFloatFilter>;
};

export type MediaVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of uploader across the matching connection */
  uploader?: Maybe<Scalars['BigFloat']>;
  /** Population variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type MediaVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  uploader?: Maybe<BigFloatFilter>;
  parentId?: Maybe<BigFloatFilter>;
};

export type MediaVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of uploader across the matching connection */
  uploader?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  /** Creates a single `Analytic`. */
  createAnalytic?: Maybe<CreateAnalyticPayload>;
  /** Creates a single `AnalyticsCta`. */
  createAnalyticsCta?: Maybe<CreateAnalyticsCtaPayload>;
  /** Creates a single `Appointment`. */
  createAppointment?: Maybe<CreateAppointmentPayload>;
  /** Creates a single `AvaApp`. */
  createAvaApp?: Maybe<CreateAvaAppPayload>;
  /** Creates a single `BillableMetricLog`. */
  createBillableMetricLog?: Maybe<CreateBillableMetricLogPayload>;
  /** Creates a single `Channel`. */
  createChannel?: Maybe<CreateChannelPayload>;
  /** Creates a single `Company`. */
  createCompany?: Maybe<CreateCompanyPayload>;
  /** Creates a single `CompanyAnalytic`. */
  createCompanyAnalytic?: Maybe<CreateCompanyAnalyticPayload>;
  /** Creates a single `CompanyChannel`. */
  createCompanyChannel?: Maybe<CreateCompanyChannelPayload>;
  /** Creates a single `CompanyChannelMedia`. */
  createCompanyChannelMedia?: Maybe<CreateCompanyChannelMediaPayload>;
  /** Creates a single `CompanyCta`. */
  createCompanyCta?: Maybe<CreateCompanyCtaPayload>;
  /** Creates a single `CompanyDomain`. */
  createCompanyDomain?: Maybe<CreateCompanyDomainPayload>;
  /** Creates a single `CompanyDomainsApproved`. */
  createCompanyDomainsApproved?: Maybe<CreateCompanyDomainsApprovedPayload>;
  /** Creates a single `CompanyDomainsPath`. */
  createCompanyDomainsPath?: Maybe<CreateCompanyDomainsPathPayload>;
  /** Creates a single `CompanyGroup`. */
  createCompanyGroup?: Maybe<CreateCompanyGroupPayload>;
  /** Creates a single `CompanyIntegration`. */
  createCompanyIntegration?: Maybe<CreateCompanyIntegrationPayload>;
  /** Creates a single `CompanyLocation`. */
  createCompanyLocation?: Maybe<CreateCompanyLocationPayload>;
  /** Creates a single `CompanyProduct`. */
  createCompanyProduct?: Maybe<CreateCompanyProductPayload>;
  /** Creates a single `CompanyProductIntegration`. */
  createCompanyProductIntegration?: Maybe<CreateCompanyProductIntegrationPayload>;
  /** Creates a single `CompanyUser`. */
  createCompanyUser?: Maybe<CreateCompanyUserPayload>;
  /** Creates a single `CompanyWidget`. */
  createCompanyWidget?: Maybe<CreateCompanyWidgetPayload>;
  /** Creates a single `CreditBureau`. */
  createCreditBureau?: Maybe<CreateCreditBureauPayload>;
  /** Creates a single `CtaWidget`. */
  createCtaWidget?: Maybe<CreateCtaWidgetPayload>;
  /** Creates a single `Cta`. */
  createCta?: Maybe<CreateCtaPayload>;
  /** Creates a single `Deal`. */
  createDeal?: Maybe<CreateDealPayload>;
  /** Creates a single `DeliveryMethod`. */
  createDeliveryMethod?: Maybe<CreateDeliveryMethodPayload>;
  /** Creates a single `DeliveryMethodsInternal`. */
  createDeliveryMethodsInternal?: Maybe<CreateDeliveryMethodsInternalPayload>;
  /** Creates a single `DistributorRelationship`. */
  createDistributorRelationship?: Maybe<CreateDistributorRelationshipPayload>;
  /** Creates a single `EmailTemplate`. */
  createEmailTemplate?: Maybe<CreateEmailTemplatePayload>;
  /** Creates a single `EquifaxLender`. */
  createEquifaxLender?: Maybe<CreateEquifaxLenderPayload>;
  /** Creates a single `Field`. */
  createField?: Maybe<CreateFieldPayload>;
  /** Creates a single `FieldConfig`. */
  createFieldConfig?: Maybe<CreateFieldConfigPayload>;
  /** Creates a single `FieldKey`. */
  createFieldKey?: Maybe<CreateFieldKeyPayload>;
  /** Creates a single `File`. */
  createFile?: Maybe<CreateFilePayload>;
  /** Creates a single `Group`. */
  createGroup?: Maybe<CreateGroupPayload>;
  /** Creates a single `IdInquiry`. */
  createIdInquiry?: Maybe<CreateIdInquiryPayload>;
  /** Creates a single `IdInquiryVerification`. */
  createIdInquiryVerification?: Maybe<CreateIdInquiryVerificationPayload>;
  /** Creates a single `IdInquiryVerificationCheck`. */
  createIdInquiryVerificationCheck?: Maybe<CreateIdInquiryVerificationCheckPayload>;
  /** Creates a single `IdMedia`. */
  createIdMedia?: Maybe<CreateIdMediaPayload>;
  /** Creates a single `IdSession`. */
  createIdSession?: Maybe<CreateIdSessionPayload>;
  /** Creates a single `IdVerification`. */
  createIdVerification?: Maybe<CreateIdVerificationPayload>;
  /** Creates a single `IncomeVerification`. */
  createIncomeVerification?: Maybe<CreateIncomeVerificationPayload>;
  /** Creates a single `Integration`. */
  createIntegration?: Maybe<CreateIntegrationPayload>;
  /** Creates a single `Lead`. */
  createLead?: Maybe<CreateLeadPayload>;
  /** Creates a single `LeadCampaign`. */
  createLeadCampaign?: Maybe<CreateLeadCampaignPayload>;
  /** Creates a single `LeadJourney`. */
  createLeadJourney?: Maybe<CreateLeadJourneyPayload>;
  /** Creates a single `LeadRole`. */
  createLeadRole?: Maybe<CreateLeadRolePayload>;
  /** Creates a single `Lender`. */
  createLender?: Maybe<CreateLenderPayload>;
  /** Creates a single `LenderApp`. */
  createLenderApp?: Maybe<CreateLenderAppPayload>;
  /** Creates a single `LenderCreditKey`. */
  createLenderCreditKey?: Maybe<CreateLenderCreditKeyPayload>;
  /** Creates a single `LenderProgram`. */
  createLenderProgram?: Maybe<CreateLenderProgramPayload>;
  /** Creates a single `LenderProgramBonus`. */
  createLenderProgramBonus?: Maybe<CreateLenderProgramBonusPayload>;
  /** Creates a single `LenderQuote`. */
  createLenderQuote?: Maybe<CreateLenderQuotePayload>;
  /** Creates a single `MaxAmortization`. */
  createMaxAmortization?: Maybe<CreateMaxAmortizationPayload>;
  /** Creates a single `Option`. */
  createOption?: Maybe<CreateOptionPayload>;
  /** Creates a single `ProductIntegration`. */
  createProductIntegration?: Maybe<CreateProductIntegrationPayload>;
  /** Creates a single `Rate`. */
  createRate?: Maybe<CreateRatePayload>;
  /** Creates a single `Reserve`. */
  createReserve?: Maybe<CreateReservePayload>;
  /** Creates a single `Review`. */
  createReview?: Maybe<CreateReviewPayload>;
  /** Creates a single `TradeApp`. */
  createTradeApp?: Maybe<CreateTradeAppPayload>;
  /** Creates a single `TradeLine`. */
  createTradeLine?: Maybe<CreateTradeLinePayload>;
  /** Creates a single `UserActivity`. */
  createUserActivity?: Maybe<CreateUserActivityPayload>;
  /** Creates a single `VehicleBooking`. */
  createVehicleBooking?: Maybe<CreateVehicleBookingPayload>;
  /** Creates a single `VehicleListing`. */
  createVehicleListing?: Maybe<CreateVehicleListingPayload>;
  /** Creates a single `VehicleStock`. */
  createVehicleStock?: Maybe<CreateVehicleStockPayload>;
  /** Creates a single `VerificationCheck`. */
  createVerificationCheck?: Maybe<CreateVerificationCheckPayload>;
  /** Creates a single `WidgetSetting`. */
  createWidgetSetting?: Maybe<CreateWidgetSettingPayload>;
  /** Creates a single `Widget`. */
  createWidget?: Maybe<CreateWidgetPayload>;
  /** Updates a single `Analytic` using its globally unique id and a patch. */
  updateAnalyticByNodeId?: Maybe<UpdateAnalyticPayload>;
  /** Updates a single `Analytic` using a unique key and a patch. */
  updateAnalytic?: Maybe<UpdateAnalyticPayload>;
  /** Updates a single `AnalyticsCta` using its globally unique id and a patch. */
  updateAnalyticsCtaByNodeId?: Maybe<UpdateAnalyticsCtaPayload>;
  /** Updates a single `AnalyticsCta` using a unique key and a patch. */
  updateAnalyticsCta?: Maybe<UpdateAnalyticsCtaPayload>;
  /** Updates a single `Appointment` using its globally unique id and a patch. */
  updateAppointmentByNodeId?: Maybe<UpdateAppointmentPayload>;
  /** Updates a single `Appointment` using a unique key and a patch. */
  updateAppointment?: Maybe<UpdateAppointmentPayload>;
  /** Updates a single `AvaApp` using its globally unique id and a patch. */
  updateAvaAppByNodeId?: Maybe<UpdateAvaAppPayload>;
  /** Updates a single `AvaApp` using a unique key and a patch. */
  updateAvaApp?: Maybe<UpdateAvaAppPayload>;
  /** Updates a single `AvaApp` using a unique key and a patch. */
  updateAvaAppByLeadId?: Maybe<UpdateAvaAppPayload>;
  /** Updates a single `BillableMetricLog` using its globally unique id and a patch. */
  updateBillableMetricLogByNodeId?: Maybe<UpdateBillableMetricLogPayload>;
  /** Updates a single `BillableMetricLog` using a unique key and a patch. */
  updateBillableMetricLog?: Maybe<UpdateBillableMetricLogPayload>;
  /** Updates a single `Channel` using its globally unique id and a patch. */
  updateChannelByNodeId?: Maybe<UpdateChannelPayload>;
  /** Updates a single `Channel` using a unique key and a patch. */
  updateChannel?: Maybe<UpdateChannelPayload>;
  /** Updates a single `Channel` using a unique key and a patch. */
  updateChannelByName?: Maybe<UpdateChannelPayload>;
  /** Updates a single `Company` using its globally unique id and a patch. */
  updateCompanyByNodeId?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompany?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompanyByName?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompanyByExternalId?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompanyByPrimaryGroup?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompanyByLagoId?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `CompanyAnalytic` using its globally unique id and a patch. */
  updateCompanyAnalyticByNodeId?: Maybe<UpdateCompanyAnalyticPayload>;
  /** Updates a single `CompanyAnalytic` using a unique key and a patch. */
  updateCompanyAnalytic?: Maybe<UpdateCompanyAnalyticPayload>;
  /** Updates a single `CompanyChannel` using its globally unique id and a patch. */
  updateCompanyChannelByNodeId?: Maybe<UpdateCompanyChannelPayload>;
  /** Updates a single `CompanyChannel` using a unique key and a patch. */
  updateCompanyChannel?: Maybe<UpdateCompanyChannelPayload>;
  /** Updates a single `CompanyChannel` using a unique key and a patch. */
  updateCompanyChannelByCompanyIdAndNameAndChannelId?: Maybe<UpdateCompanyChannelPayload>;
  /** Updates a single `CompanyChannelMedia` using its globally unique id and a patch. */
  updateCompanyChannelMediaByNodeId?: Maybe<UpdateCompanyChannelMediaPayload>;
  /** Updates a single `CompanyChannelMedia` using a unique key and a patch. */
  updateCompanyChannelMedia?: Maybe<UpdateCompanyChannelMediaPayload>;
  /** Updates a single `CompanyChannelMedia` using a unique key and a patch. */
  updateCompanyChannelMediaByCompanyChannelIdAndMediaId?: Maybe<UpdateCompanyChannelMediaPayload>;
  /** Updates a single `CompanyCta` using its globally unique id and a patch. */
  updateCompanyCtaByNodeId?: Maybe<UpdateCompanyCtaPayload>;
  /** Updates a single `CompanyCta` using a unique key and a patch. */
  updateCompanyCta?: Maybe<UpdateCompanyCtaPayload>;
  /** Updates a single `CompanyDomain` using its globally unique id and a patch. */
  updateCompanyDomainByNodeId?: Maybe<UpdateCompanyDomainPayload>;
  /** Updates a single `CompanyDomain` using a unique key and a patch. */
  updateCompanyDomain?: Maybe<UpdateCompanyDomainPayload>;
  /** Updates a single `CompanyDomain` using a unique key and a patch. */
  updateCompanyDomainByDomain?: Maybe<UpdateCompanyDomainPayload>;
  /** Updates a single `CompanyDomainsApproved` using its globally unique id and a patch. */
  updateCompanyDomainsApprovedByNodeId?: Maybe<UpdateCompanyDomainsApprovedPayload>;
  /** Updates a single `CompanyDomainsApproved` using a unique key and a patch. */
  updateCompanyDomainsApproved?: Maybe<UpdateCompanyDomainsApprovedPayload>;
  /** Updates a single `CompanyDomainsPath` using its globally unique id and a patch. */
  updateCompanyDomainsPathByNodeId?: Maybe<UpdateCompanyDomainsPathPayload>;
  /** Updates a single `CompanyDomainsPath` using a unique key and a patch. */
  updateCompanyDomainsPath?: Maybe<UpdateCompanyDomainsPathPayload>;
  /** Updates a single `CompanyDomainsPath` using a unique key and a patch. */
  updateCompanyDomainsPathByDomainIdAndPath?: Maybe<UpdateCompanyDomainsPathPayload>;
  /** Updates a single `CompanyGroup` using its globally unique id and a patch. */
  updateCompanyGroupByNodeId?: Maybe<UpdateCompanyGroupPayload>;
  /** Updates a single `CompanyGroup` using a unique key and a patch. */
  updateCompanyGroup?: Maybe<UpdateCompanyGroupPayload>;
  /** Updates a single `CompanyIntegration` using its globally unique id and a patch. */
  updateCompanyIntegrationByNodeId?: Maybe<UpdateCompanyIntegrationPayload>;
  /** Updates a single `CompanyIntegration` using a unique key and a patch. */
  updateCompanyIntegration?: Maybe<UpdateCompanyIntegrationPayload>;
  /** Updates a single `CompanyLocation` using its globally unique id and a patch. */
  updateCompanyLocationByNodeId?: Maybe<UpdateCompanyLocationPayload>;
  /** Updates a single `CompanyLocation` using a unique key and a patch. */
  updateCompanyLocation?: Maybe<UpdateCompanyLocationPayload>;
  /** Updates a single `CompanyProduct` using its globally unique id and a patch. */
  updateCompanyProductByNodeId?: Maybe<UpdateCompanyProductPayload>;
  /** Updates a single `CompanyProduct` using a unique key and a patch. */
  updateCompanyProduct?: Maybe<UpdateCompanyProductPayload>;
  /** Updates a single `CompanyProductIntegration` using its globally unique id and a patch. */
  updateCompanyProductIntegrationByNodeId?: Maybe<UpdateCompanyProductIntegrationPayload>;
  /** Updates a single `CompanyProductIntegration` using a unique key and a patch. */
  updateCompanyProductIntegration?: Maybe<UpdateCompanyProductIntegrationPayload>;
  /** Updates a single `CompanyWidget` using its globally unique id and a patch. */
  updateCompanyWidgetByNodeId?: Maybe<UpdateCompanyWidgetPayload>;
  /** Updates a single `CompanyWidget` using a unique key and a patch. */
  updateCompanyWidget?: Maybe<UpdateCompanyWidgetPayload>;
  /** Updates a single `CreditBureau` using its globally unique id and a patch. */
  updateCreditBureauByNodeId?: Maybe<UpdateCreditBureauPayload>;
  /** Updates a single `CreditBureau` using a unique key and a patch. */
  updateCreditBureau?: Maybe<UpdateCreditBureauPayload>;
  /** Updates a single `CtaWidget` using its globally unique id and a patch. */
  updateCtaWidgetByNodeId?: Maybe<UpdateCtaWidgetPayload>;
  /** Updates a single `CtaWidget` using a unique key and a patch. */
  updateCtaWidget?: Maybe<UpdateCtaWidgetPayload>;
  /** Updates a single `Cta` using its globally unique id and a patch. */
  updateCtaByNodeId?: Maybe<UpdateCtaPayload>;
  /** Updates a single `Cta` using a unique key and a patch. */
  updateCta?: Maybe<UpdateCtaPayload>;
  /** Updates a single `Cta` using a unique key and a patch. */
  updateCtaByProductAndTypeAndVersion?: Maybe<UpdateCtaPayload>;
  /** Updates a single `Deal` using its globally unique id and a patch. */
  updateDealByNodeId?: Maybe<UpdateDealPayload>;
  /** Updates a single `Deal` using a unique key and a patch. */
  updateDeal?: Maybe<UpdateDealPayload>;
  /** Updates a single `DeliveryMethod` using its globally unique id and a patch. */
  updateDeliveryMethodByNodeId?: Maybe<UpdateDeliveryMethodPayload>;
  /** Updates a single `DeliveryMethod` using a unique key and a patch. */
  updateDeliveryMethod?: Maybe<UpdateDeliveryMethodPayload>;
  /** Updates a single `DistributorRelationship` using its globally unique id and a patch. */
  updateDistributorRelationshipByNodeId?: Maybe<UpdateDistributorRelationshipPayload>;
  /** Updates a single `DistributorRelationship` using a unique key and a patch. */
  updateDistributorRelationship?: Maybe<UpdateDistributorRelationshipPayload>;
  /** Updates a single `DistributorRelationship` using a unique key and a patch. */
  updateDistributorRelationshipByDistributorCompanyIdAndCompanyId?: Maybe<UpdateDistributorRelationshipPayload>;
  /** Updates a single `EmailTemplate` using its globally unique id and a patch. */
  updateEmailTemplateByNodeId?: Maybe<UpdateEmailTemplatePayload>;
  /** Updates a single `EmailTemplate` using a unique key and a patch. */
  updateEmailTemplate?: Maybe<UpdateEmailTemplatePayload>;
  /** Updates a single `EquifaxLender` using its globally unique id and a patch. */
  updateEquifaxLenderByNodeId?: Maybe<UpdateEquifaxLenderPayload>;
  /** Updates a single `EquifaxLender` using a unique key and a patch. */
  updateEquifaxLender?: Maybe<UpdateEquifaxLenderPayload>;
  /** Updates a single `EquifaxLender` using a unique key and a patch. */
  updateEquifaxLenderByEquifaxIdAndName?: Maybe<UpdateEquifaxLenderPayload>;
  /** Updates a single `Field` using its globally unique id and a patch. */
  updateFieldByNodeId?: Maybe<UpdateFieldPayload>;
  /** Updates a single `Field` using a unique key and a patch. */
  updateField?: Maybe<UpdateFieldPayload>;
  /** Updates a single `Field` using a unique key and a patch. */
  updateFieldByCompanyProductIdAndConfig?: Maybe<UpdateFieldPayload>;
  /** Updates a single `Field` using a unique key and a patch. */
  updateFieldByCompanyChannelIdAndConfig?: Maybe<UpdateFieldPayload>;
  /** Updates a single `Field` using a unique key and a patch. */
  updateFieldByCompanyIntegrationIdAndConfig?: Maybe<UpdateFieldPayload>;
  /** Updates a single `FieldConfig` using its globally unique id and a patch. */
  updateFieldConfigByNodeId?: Maybe<UpdateFieldConfigPayload>;
  /** Updates a single `FieldConfig` using a unique key and a patch. */
  updateFieldConfig?: Maybe<UpdateFieldConfigPayload>;
  /** Updates a single `FieldConfig` using a unique key and a patch. */
  updateFieldConfigByParentIdAndParentTypeAndKey?: Maybe<UpdateFieldConfigPayload>;
  /** Updates a single `FieldKey` using its globally unique id and a patch. */
  updateFieldKeyByNodeId?: Maybe<UpdateFieldKeyPayload>;
  /** Updates a single `FieldKey` using a unique key and a patch. */
  updateFieldKey?: Maybe<UpdateFieldKeyPayload>;
  /** Updates a single `FieldKey` using a unique key and a patch. */
  updateFieldKeyByKey?: Maybe<UpdateFieldKeyPayload>;
  /** Updates a single `File` using its globally unique id and a patch. */
  updateFileByNodeId?: Maybe<UpdateFilePayload>;
  /** Updates a single `File` using a unique key and a patch. */
  updateFile?: Maybe<UpdateFilePayload>;
  /** Updates a single `Group` using its globally unique id and a patch. */
  updateGroupByNodeId?: Maybe<UpdateGroupPayload>;
  /** Updates a single `Group` using a unique key and a patch. */
  updateGroup?: Maybe<UpdateGroupPayload>;
  /** Updates a single `Group` using a unique key and a patch. */
  updateGroupByCognitoName?: Maybe<UpdateGroupPayload>;
  /** Updates a single `IdInquiry` using its globally unique id and a patch. */
  updateIdInquiryByNodeId?: Maybe<UpdateIdInquiryPayload>;
  /** Updates a single `IdInquiry` using a unique key and a patch. */
  updateIdInquiry?: Maybe<UpdateIdInquiryPayload>;
  /** Updates a single `IdInquiryVerification` using its globally unique id and a patch. */
  updateIdInquiryVerificationByNodeId?: Maybe<UpdateIdInquiryVerificationPayload>;
  /** Updates a single `IdInquiryVerification` using a unique key and a patch. */
  updateIdInquiryVerification?: Maybe<UpdateIdInquiryVerificationPayload>;
  /** Updates a single `IdInquiryVerificationCheck` using its globally unique id and a patch. */
  updateIdInquiryVerificationCheckByNodeId?: Maybe<UpdateIdInquiryVerificationCheckPayload>;
  /** Updates a single `IdInquiryVerificationCheck` using a unique key and a patch. */
  updateIdInquiryVerificationCheck?: Maybe<UpdateIdInquiryVerificationCheckPayload>;
  /** Updates a single `IdMedia` using its globally unique id and a patch. */
  updateIdMediaByNodeId?: Maybe<UpdateIdMediaPayload>;
  /** Updates a single `IdMedia` using a unique key and a patch. */
  updateIdMedia?: Maybe<UpdateIdMediaPayload>;
  /** Updates a single `IdSession` using its globally unique id and a patch. */
  updateIdSessionByNodeId?: Maybe<UpdateIdSessionPayload>;
  /** Updates a single `IdSession` using a unique key and a patch. */
  updateIdSession?: Maybe<UpdateIdSessionPayload>;
  /** Updates a single `IdVerification` using its globally unique id and a patch. */
  updateIdVerificationByNodeId?: Maybe<UpdateIdVerificationPayload>;
  /** Updates a single `IdVerification` using a unique key and a patch. */
  updateIdVerification?: Maybe<UpdateIdVerificationPayload>;
  /** Updates a single `IdVerification` using a unique key and a patch. */
  updateIdVerificationByLeadId?: Maybe<UpdateIdVerificationPayload>;
  /** Updates a single `IncomeVerification` using its globally unique id and a patch. */
  updateIncomeVerificationByNodeId?: Maybe<UpdateIncomeVerificationPayload>;
  /** Updates a single `IncomeVerification` using a unique key and a patch. */
  updateIncomeVerification?: Maybe<UpdateIncomeVerificationPayload>;
  /** Updates a single `IncomeVerification` using a unique key and a patch. */
  updateIncomeVerificationByLeadId?: Maybe<UpdateIncomeVerificationPayload>;
  /** Updates a single `Integration` using its globally unique id and a patch. */
  updateIntegrationByNodeId?: Maybe<UpdateIntegrationPayload>;
  /** Updates a single `Integration` using a unique key and a patch. */
  updateIntegration?: Maybe<UpdateIntegrationPayload>;
  /** Updates a single `Integration` using a unique key and a patch. */
  updateIntegrationByName?: Maybe<UpdateIntegrationPayload>;
  /** Updates a single `Lead` using its globally unique id and a patch. */
  updateLeadByNodeId?: Maybe<UpdateLeadPayload>;
  /** Updates a single `Lead` using a unique key and a patch. */
  updateLead?: Maybe<UpdateLeadPayload>;
  /** Updates a single `LeadCampaign` using its globally unique id and a patch. */
  updateLeadCampaignByNodeId?: Maybe<UpdateLeadCampaignPayload>;
  /** Updates a single `LeadCampaign` using a unique key and a patch. */
  updateLeadCampaign?: Maybe<UpdateLeadCampaignPayload>;
  /** Updates a single `LeadCampaign` using a unique key and a patch. */
  updateLeadCampaignByLeadId?: Maybe<UpdateLeadCampaignPayload>;
  /** Updates a single `LeadRole` using its globally unique id and a patch. */
  updateLeadRoleByNodeId?: Maybe<UpdateLeadRolePayload>;
  /** Updates a single `LeadRole` using a unique key and a patch. */
  updateLeadRole?: Maybe<UpdateLeadRolePayload>;
  /** Updates a single `Lender` using its globally unique id and a patch. */
  updateLenderByNodeId?: Maybe<UpdateLenderPayload>;
  /** Updates a single `Lender` using a unique key and a patch. */
  updateLender?: Maybe<UpdateLenderPayload>;
  /** Updates a single `Lender` using a unique key and a patch. */
  updateLenderByName?: Maybe<UpdateLenderPayload>;
  /** Updates a single `LenderApp` using its globally unique id and a patch. */
  updateLenderAppByNodeId?: Maybe<UpdateLenderAppPayload>;
  /** Updates a single `LenderApp` using a unique key and a patch. */
  updateLenderApp?: Maybe<UpdateLenderAppPayload>;
  /** Updates a single `LenderCreditKey` using its globally unique id and a patch. */
  updateLenderCreditKeyByNodeId?: Maybe<UpdateLenderCreditKeyPayload>;
  /** Updates a single `LenderCreditKey` using a unique key and a patch. */
  updateLenderCreditKey?: Maybe<UpdateLenderCreditKeyPayload>;
  /** Updates a single `LenderProgram` using its globally unique id and a patch. */
  updateLenderProgramByNodeId?: Maybe<UpdateLenderProgramPayload>;
  /** Updates a single `LenderProgram` using a unique key and a patch. */
  updateLenderProgram?: Maybe<UpdateLenderProgramPayload>;
  /** Updates a single `LenderProgramBonus` using its globally unique id and a patch. */
  updateLenderProgramBonusByNodeId?: Maybe<UpdateLenderProgramBonusPayload>;
  /** Updates a single `LenderProgramBonus` using a unique key and a patch. */
  updateLenderProgramBonus?: Maybe<UpdateLenderProgramBonusPayload>;
  /** Updates a single `LenderQuote` using its globally unique id and a patch. */
  updateLenderQuoteByNodeId?: Maybe<UpdateLenderQuotePayload>;
  /** Updates a single `LenderQuote` using a unique key and a patch. */
  updateLenderQuote?: Maybe<UpdateLenderQuotePayload>;
  /** Updates a single `MaxAmortization` using its globally unique id and a patch. */
  updateMaxAmortizationByNodeId?: Maybe<UpdateMaxAmortizationPayload>;
  /** Updates a single `MaxAmortization` using a unique key and a patch. */
  updateMaxAmortization?: Maybe<UpdateMaxAmortizationPayload>;
  /** Updates a single `Option` using its globally unique id and a patch. */
  updateOptionByNodeId?: Maybe<UpdateOptionPayload>;
  /** Updates a single `Option` using a unique key and a patch. */
  updateOption?: Maybe<UpdateOptionPayload>;
  /** Updates a single `ProductIntegration` using its globally unique id and a patch. */
  updateProductIntegrationByNodeId?: Maybe<UpdateProductIntegrationPayload>;
  /** Updates a single `ProductIntegration` using a unique key and a patch. */
  updateProductIntegration?: Maybe<UpdateProductIntegrationPayload>;
  /** Updates a single `Rate` using its globally unique id and a patch. */
  updateRateByNodeId?: Maybe<UpdateRatePayload>;
  /** Updates a single `Rate` using a unique key and a patch. */
  updateRate?: Maybe<UpdateRatePayload>;
  /** Updates a single `Reserve` using its globally unique id and a patch. */
  updateReserveByNodeId?: Maybe<UpdateReservePayload>;
  /** Updates a single `Reserve` using a unique key and a patch. */
  updateReserve?: Maybe<UpdateReservePayload>;
  /** Updates a single `Review` using its globally unique id and a patch. */
  updateReviewByNodeId?: Maybe<UpdateReviewPayload>;
  /** Updates a single `Review` using a unique key and a patch. */
  updateReview?: Maybe<UpdateReviewPayload>;
  /** Updates a single `TradeApp` using its globally unique id and a patch. */
  updateTradeAppByNodeId?: Maybe<UpdateTradeAppPayload>;
  /** Updates a single `TradeApp` using a unique key and a patch. */
  updateTradeApp?: Maybe<UpdateTradeAppPayload>;
  /** Updates a single `TradeLine` using its globally unique id and a patch. */
  updateTradeLineByNodeId?: Maybe<UpdateTradeLinePayload>;
  /** Updates a single `TradeLine` using a unique key and a patch. */
  updateTradeLine?: Maybe<UpdateTradeLinePayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUserByNodeId?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `VehicleBooking` using its globally unique id and a patch. */
  updateVehicleBookingByNodeId?: Maybe<UpdateVehicleBookingPayload>;
  /** Updates a single `VehicleBooking` using a unique key and a patch. */
  updateVehicleBooking?: Maybe<UpdateVehicleBookingPayload>;
  /** Updates a single `VehicleListing` using its globally unique id and a patch. */
  updateVehicleListingByNodeId?: Maybe<UpdateVehicleListingPayload>;
  /** Updates a single `VehicleListing` using a unique key and a patch. */
  updateVehicleListing?: Maybe<UpdateVehicleListingPayload>;
  /** Updates a single `VehicleStock` using its globally unique id and a patch. */
  updateVehicleStockByNodeId?: Maybe<UpdateVehicleStockPayload>;
  /** Updates a single `VehicleStock` using a unique key and a patch. */
  updateVehicleStock?: Maybe<UpdateVehicleStockPayload>;
  /** Updates a single `VehicleStock` using a unique key and a patch. */
  updateVehicleStockByProviderId?: Maybe<UpdateVehicleStockPayload>;
  /** Updates a single `VehicleStock` using a unique key and a patch. */
  updateVehicleStockByVin?: Maybe<UpdateVehicleStockPayload>;
  /** Updates a single `VehicleStock` using a unique key and a patch. */
  updateVehicleStockByStockNumber?: Maybe<UpdateVehicleStockPayload>;
  /** Updates a single `VerificationCheck` using its globally unique id and a patch. */
  updateVerificationCheckByNodeId?: Maybe<UpdateVerificationCheckPayload>;
  /** Updates a single `VerificationCheck` using a unique key and a patch. */
  updateVerificationCheck?: Maybe<UpdateVerificationCheckPayload>;
  /** Updates a single `VerificationCheck` using a unique key and a patch. */
  updateVerificationCheckByName?: Maybe<UpdateVerificationCheckPayload>;
  /** Updates a single `WidgetSetting` using its globally unique id and a patch. */
  updateWidgetSettingByNodeId?: Maybe<UpdateWidgetSettingPayload>;
  /** Updates a single `WidgetSetting` using a unique key and a patch. */
  updateWidgetSetting?: Maybe<UpdateWidgetSettingPayload>;
  /** Updates a single `Widget` using its globally unique id and a patch. */
  updateWidgetByNodeId?: Maybe<UpdateWidgetPayload>;
  /** Updates a single `Widget` using a unique key and a patch. */
  updateWidget?: Maybe<UpdateWidgetPayload>;
  /** Updates a single `Widget` using a unique key and a patch. */
  updateWidgetByVariant?: Maybe<UpdateWidgetPayload>;
  /** Deletes a single `Analytic` using its globally unique id. */
  deleteAnalyticByNodeId?: Maybe<DeleteAnalyticPayload>;
  /** Deletes a single `Analytic` using a unique key. */
  deleteAnalytic?: Maybe<DeleteAnalyticPayload>;
  /** Deletes a single `AnalyticsCta` using its globally unique id. */
  deleteAnalyticsCtaByNodeId?: Maybe<DeleteAnalyticsCtaPayload>;
  /** Deletes a single `AnalyticsCta` using a unique key. */
  deleteAnalyticsCta?: Maybe<DeleteAnalyticsCtaPayload>;
  /** Deletes a single `Appointment` using its globally unique id. */
  deleteAppointmentByNodeId?: Maybe<DeleteAppointmentPayload>;
  /** Deletes a single `Appointment` using a unique key. */
  deleteAppointment?: Maybe<DeleteAppointmentPayload>;
  /** Deletes a single `BillableMetricLog` using its globally unique id. */
  deleteBillableMetricLogByNodeId?: Maybe<DeleteBillableMetricLogPayload>;
  /** Deletes a single `BillableMetricLog` using a unique key. */
  deleteBillableMetricLog?: Maybe<DeleteBillableMetricLogPayload>;
  /** Deletes a single `Channel` using its globally unique id. */
  deleteChannelByNodeId?: Maybe<DeleteChannelPayload>;
  /** Deletes a single `Channel` using a unique key. */
  deleteChannel?: Maybe<DeleteChannelPayload>;
  /** Deletes a single `Channel` using a unique key. */
  deleteChannelByName?: Maybe<DeleteChannelPayload>;
  /** Deletes a single `CompanyAnalytic` using its globally unique id. */
  deleteCompanyAnalyticByNodeId?: Maybe<DeleteCompanyAnalyticPayload>;
  /** Deletes a single `CompanyAnalytic` using a unique key. */
  deleteCompanyAnalytic?: Maybe<DeleteCompanyAnalyticPayload>;
  /** Deletes a single `CompanyChannelMedia` using its globally unique id. */
  deleteCompanyChannelMediaByNodeId?: Maybe<DeleteCompanyChannelMediaPayload>;
  /** Deletes a single `CompanyChannelMedia` using a unique key. */
  deleteCompanyChannelMedia?: Maybe<DeleteCompanyChannelMediaPayload>;
  /** Deletes a single `CompanyChannelMedia` using a unique key. */
  deleteCompanyChannelMediaByCompanyChannelIdAndMediaId?: Maybe<DeleteCompanyChannelMediaPayload>;
  /** Deletes a single `CompanyCta` using its globally unique id. */
  deleteCompanyCtaByNodeId?: Maybe<DeleteCompanyCtaPayload>;
  /** Deletes a single `CompanyCta` using a unique key. */
  deleteCompanyCta?: Maybe<DeleteCompanyCtaPayload>;
  /** Deletes a single `CompanyDomain` using its globally unique id. */
  deleteCompanyDomainByNodeId?: Maybe<DeleteCompanyDomainPayload>;
  /** Deletes a single `CompanyDomain` using a unique key. */
  deleteCompanyDomain?: Maybe<DeleteCompanyDomainPayload>;
  /** Deletes a single `CompanyDomain` using a unique key. */
  deleteCompanyDomainByDomain?: Maybe<DeleteCompanyDomainPayload>;
  /** Deletes a single `CompanyDomainsApproved` using its globally unique id. */
  deleteCompanyDomainsApprovedByNodeId?: Maybe<DeleteCompanyDomainsApprovedPayload>;
  /** Deletes a single `CompanyDomainsApproved` using a unique key. */
  deleteCompanyDomainsApproved?: Maybe<DeleteCompanyDomainsApprovedPayload>;
  /** Deletes a single `CompanyDomainsPath` using its globally unique id. */
  deleteCompanyDomainsPathByNodeId?: Maybe<DeleteCompanyDomainsPathPayload>;
  /** Deletes a single `CompanyDomainsPath` using a unique key. */
  deleteCompanyDomainsPath?: Maybe<DeleteCompanyDomainsPathPayload>;
  /** Deletes a single `CompanyDomainsPath` using a unique key. */
  deleteCompanyDomainsPathByDomainIdAndPath?: Maybe<DeleteCompanyDomainsPathPayload>;
  /** Deletes a single `CompanyGroup` using its globally unique id. */
  deleteCompanyGroupByNodeId?: Maybe<DeleteCompanyGroupPayload>;
  /** Deletes a single `CompanyGroup` using a unique key. */
  deleteCompanyGroup?: Maybe<DeleteCompanyGroupPayload>;
  /** Deletes a single `CompanyIntegration` using its globally unique id. */
  deleteCompanyIntegrationByNodeId?: Maybe<DeleteCompanyIntegrationPayload>;
  /** Deletes a single `CompanyIntegration` using a unique key. */
  deleteCompanyIntegration?: Maybe<DeleteCompanyIntegrationPayload>;
  /** Deletes a single `CompanyLocation` using its globally unique id. */
  deleteCompanyLocationByNodeId?: Maybe<DeleteCompanyLocationPayload>;
  /** Deletes a single `CompanyLocation` using a unique key. */
  deleteCompanyLocation?: Maybe<DeleteCompanyLocationPayload>;
  /** Deletes a single `CompanyProduct` using its globally unique id. */
  deleteCompanyProductByNodeId?: Maybe<DeleteCompanyProductPayload>;
  /** Deletes a single `CompanyProduct` using a unique key. */
  deleteCompanyProduct?: Maybe<DeleteCompanyProductPayload>;
  /** Deletes a single `CompanyProductIntegration` using its globally unique id. */
  deleteCompanyProductIntegrationByNodeId?: Maybe<DeleteCompanyProductIntegrationPayload>;
  /** Deletes a single `CompanyProductIntegration` using a unique key. */
  deleteCompanyProductIntegration?: Maybe<DeleteCompanyProductIntegrationPayload>;
  /** Deletes a single `CompanyWidget` using its globally unique id. */
  deleteCompanyWidgetByNodeId?: Maybe<DeleteCompanyWidgetPayload>;
  /** Deletes a single `CompanyWidget` using a unique key. */
  deleteCompanyWidget?: Maybe<DeleteCompanyWidgetPayload>;
  /** Deletes a single `CtaWidget` using its globally unique id. */
  deleteCtaWidgetByNodeId?: Maybe<DeleteCtaWidgetPayload>;
  /** Deletes a single `CtaWidget` using a unique key. */
  deleteCtaWidget?: Maybe<DeleteCtaWidgetPayload>;
  /** Deletes a single `Cta` using its globally unique id. */
  deleteCtaByNodeId?: Maybe<DeleteCtaPayload>;
  /** Deletes a single `Cta` using a unique key. */
  deleteCta?: Maybe<DeleteCtaPayload>;
  /** Deletes a single `Cta` using a unique key. */
  deleteCtaByProductAndTypeAndVersion?: Maybe<DeleteCtaPayload>;
  /** Deletes a single `Deal` using its globally unique id. */
  deleteDealByNodeId?: Maybe<DeleteDealPayload>;
  /** Deletes a single `Deal` using a unique key. */
  deleteDeal?: Maybe<DeleteDealPayload>;
  /** Deletes a single `DeliveryMethod` using its globally unique id. */
  deleteDeliveryMethodByNodeId?: Maybe<DeleteDeliveryMethodPayload>;
  /** Deletes a single `DeliveryMethod` using a unique key. */
  deleteDeliveryMethod?: Maybe<DeleteDeliveryMethodPayload>;
  /** Deletes a single `DistributorRelationship` using its globally unique id. */
  deleteDistributorRelationshipByNodeId?: Maybe<DeleteDistributorRelationshipPayload>;
  /** Deletes a single `DistributorRelationship` using a unique key. */
  deleteDistributorRelationship?: Maybe<DeleteDistributorRelationshipPayload>;
  /** Deletes a single `DistributorRelationship` using a unique key. */
  deleteDistributorRelationshipByDistributorCompanyIdAndCompanyId?: Maybe<DeleteDistributorRelationshipPayload>;
  /** Deletes a single `EmailTemplate` using its globally unique id. */
  deleteEmailTemplateByNodeId?: Maybe<DeleteEmailTemplatePayload>;
  /** Deletes a single `EmailTemplate` using a unique key. */
  deleteEmailTemplate?: Maybe<DeleteEmailTemplatePayload>;
  /** Deletes a single `EquifaxLender` using its globally unique id. */
  deleteEquifaxLenderByNodeId?: Maybe<DeleteEquifaxLenderPayload>;
  /** Deletes a single `EquifaxLender` using a unique key. */
  deleteEquifaxLender?: Maybe<DeleteEquifaxLenderPayload>;
  /** Deletes a single `EquifaxLender` using a unique key. */
  deleteEquifaxLenderByEquifaxIdAndName?: Maybe<DeleteEquifaxLenderPayload>;
  /** Deletes a single `Event` using its globally unique id. */
  deleteEventByNodeId?: Maybe<DeleteEventPayload>;
  /** Deletes a single `Event` using a unique key. */
  deleteEvent?: Maybe<DeleteEventPayload>;
  /** Deletes a single `Field` using its globally unique id. */
  deleteFieldByNodeId?: Maybe<DeleteFieldPayload>;
  /** Deletes a single `Field` using a unique key. */
  deleteField?: Maybe<DeleteFieldPayload>;
  /** Deletes a single `Field` using a unique key. */
  deleteFieldByCompanyProductIdAndConfig?: Maybe<DeleteFieldPayload>;
  /** Deletes a single `Field` using a unique key. */
  deleteFieldByCompanyChannelIdAndConfig?: Maybe<DeleteFieldPayload>;
  /** Deletes a single `Field` using a unique key. */
  deleteFieldByCompanyIntegrationIdAndConfig?: Maybe<DeleteFieldPayload>;
  /** Deletes a single `FieldConfig` using its globally unique id. */
  deleteFieldConfigByNodeId?: Maybe<DeleteFieldConfigPayload>;
  /** Deletes a single `FieldConfig` using a unique key. */
  deleteFieldConfig?: Maybe<DeleteFieldConfigPayload>;
  /** Deletes a single `FieldConfig` using a unique key. */
  deleteFieldConfigByParentIdAndParentTypeAndKey?: Maybe<DeleteFieldConfigPayload>;
  /** Deletes a single `FieldKey` using its globally unique id. */
  deleteFieldKeyByNodeId?: Maybe<DeleteFieldKeyPayload>;
  /** Deletes a single `FieldKey` using a unique key. */
  deleteFieldKey?: Maybe<DeleteFieldKeyPayload>;
  /** Deletes a single `FieldKey` using a unique key. */
  deleteFieldKeyByKey?: Maybe<DeleteFieldKeyPayload>;
  /** Deletes a single `File` using its globally unique id. */
  deleteFileByNodeId?: Maybe<DeleteFilePayload>;
  /** Deletes a single `File` using a unique key. */
  deleteFile?: Maybe<DeleteFilePayload>;
  /** Deletes a single `IdInquiry` using its globally unique id. */
  deleteIdInquiryByNodeId?: Maybe<DeleteIdInquiryPayload>;
  /** Deletes a single `IdInquiry` using a unique key. */
  deleteIdInquiry?: Maybe<DeleteIdInquiryPayload>;
  /** Deletes a single `IdInquiryVerification` using its globally unique id. */
  deleteIdInquiryVerificationByNodeId?: Maybe<DeleteIdInquiryVerificationPayload>;
  /** Deletes a single `IdInquiryVerification` using a unique key. */
  deleteIdInquiryVerification?: Maybe<DeleteIdInquiryVerificationPayload>;
  /** Deletes a single `IdInquiryVerificationCheck` using its globally unique id. */
  deleteIdInquiryVerificationCheckByNodeId?: Maybe<DeleteIdInquiryVerificationCheckPayload>;
  /** Deletes a single `IdInquiryVerificationCheck` using a unique key. */
  deleteIdInquiryVerificationCheck?: Maybe<DeleteIdInquiryVerificationCheckPayload>;
  /** Deletes a single `IdMedia` using its globally unique id. */
  deleteIdMediaByNodeId?: Maybe<DeleteIdMediaPayload>;
  /** Deletes a single `IdMedia` using a unique key. */
  deleteIdMedia?: Maybe<DeleteIdMediaPayload>;
  /** Deletes a single `IdSession` using its globally unique id. */
  deleteIdSessionByNodeId?: Maybe<DeleteIdSessionPayload>;
  /** Deletes a single `IdSession` using a unique key. */
  deleteIdSession?: Maybe<DeleteIdSessionPayload>;
  /** Deletes a single `IdVerification` using its globally unique id. */
  deleteIdVerificationByNodeId?: Maybe<DeleteIdVerificationPayload>;
  /** Deletes a single `IdVerification` using a unique key. */
  deleteIdVerification?: Maybe<DeleteIdVerificationPayload>;
  /** Deletes a single `IdVerification` using a unique key. */
  deleteIdVerificationByLeadId?: Maybe<DeleteIdVerificationPayload>;
  /** Deletes a single `IncomeVerification` using its globally unique id. */
  deleteIncomeVerificationByNodeId?: Maybe<DeleteIncomeVerificationPayload>;
  /** Deletes a single `IncomeVerification` using a unique key. */
  deleteIncomeVerification?: Maybe<DeleteIncomeVerificationPayload>;
  /** Deletes a single `IncomeVerification` using a unique key. */
  deleteIncomeVerificationByLeadId?: Maybe<DeleteIncomeVerificationPayload>;
  /** Deletes a single `Integration` using its globally unique id. */
  deleteIntegrationByNodeId?: Maybe<DeleteIntegrationPayload>;
  /** Deletes a single `Integration` using a unique key. */
  deleteIntegration?: Maybe<DeleteIntegrationPayload>;
  /** Deletes a single `Integration` using a unique key. */
  deleteIntegrationByName?: Maybe<DeleteIntegrationPayload>;
  /** Deletes a single `Lead` using its globally unique id. */
  deleteLeadByNodeId?: Maybe<DeleteLeadPayload>;
  /** Deletes a single `Lead` using a unique key. */
  deleteLead?: Maybe<DeleteLeadPayload>;
  /** Deletes a single `LeadCampaign` using its globally unique id. */
  deleteLeadCampaignByNodeId?: Maybe<DeleteLeadCampaignPayload>;
  /** Deletes a single `LeadCampaign` using a unique key. */
  deleteLeadCampaign?: Maybe<DeleteLeadCampaignPayload>;
  /** Deletes a single `LeadCampaign` using a unique key. */
  deleteLeadCampaignByLeadId?: Maybe<DeleteLeadCampaignPayload>;
  /** Deletes a single `LeadRole` using its globally unique id. */
  deleteLeadRoleByNodeId?: Maybe<DeleteLeadRolePayload>;
  /** Deletes a single `LeadRole` using a unique key. */
  deleteLeadRole?: Maybe<DeleteLeadRolePayload>;
  /** Deletes a single `Lender` using its globally unique id. */
  deleteLenderByNodeId?: Maybe<DeleteLenderPayload>;
  /** Deletes a single `Lender` using a unique key. */
  deleteLender?: Maybe<DeleteLenderPayload>;
  /** Deletes a single `Lender` using a unique key. */
  deleteLenderByName?: Maybe<DeleteLenderPayload>;
  /** Deletes a single `LenderApp` using its globally unique id. */
  deleteLenderAppByNodeId?: Maybe<DeleteLenderAppPayload>;
  /** Deletes a single `LenderApp` using a unique key. */
  deleteLenderApp?: Maybe<DeleteLenderAppPayload>;
  /** Deletes a single `LenderCreditKey` using its globally unique id. */
  deleteLenderCreditKeyByNodeId?: Maybe<DeleteLenderCreditKeyPayload>;
  /** Deletes a single `LenderCreditKey` using a unique key. */
  deleteLenderCreditKey?: Maybe<DeleteLenderCreditKeyPayload>;
  /** Deletes a single `LenderProgram` using its globally unique id. */
  deleteLenderProgramByNodeId?: Maybe<DeleteLenderProgramPayload>;
  /** Deletes a single `LenderProgram` using a unique key. */
  deleteLenderProgram?: Maybe<DeleteLenderProgramPayload>;
  /** Deletes a single `LenderProgramBonus` using its globally unique id. */
  deleteLenderProgramBonusByNodeId?: Maybe<DeleteLenderProgramBonusPayload>;
  /** Deletes a single `LenderProgramBonus` using a unique key. */
  deleteLenderProgramBonus?: Maybe<DeleteLenderProgramBonusPayload>;
  /** Deletes a single `LenderQuote` using its globally unique id. */
  deleteLenderQuoteByNodeId?: Maybe<DeleteLenderQuotePayload>;
  /** Deletes a single `LenderQuote` using a unique key. */
  deleteLenderQuote?: Maybe<DeleteLenderQuotePayload>;
  /** Deletes a single `MaxAmortization` using its globally unique id. */
  deleteMaxAmortizationByNodeId?: Maybe<DeleteMaxAmortizationPayload>;
  /** Deletes a single `MaxAmortization` using a unique key. */
  deleteMaxAmortization?: Maybe<DeleteMaxAmortizationPayload>;
  /** Deletes a single `Option` using its globally unique id. */
  deleteOptionByNodeId?: Maybe<DeleteOptionPayload>;
  /** Deletes a single `Option` using a unique key. */
  deleteOption?: Maybe<DeleteOptionPayload>;
  /** Deletes a single `ProductIntegration` using its globally unique id. */
  deleteProductIntegrationByNodeId?: Maybe<DeleteProductIntegrationPayload>;
  /** Deletes a single `ProductIntegration` using a unique key. */
  deleteProductIntegration?: Maybe<DeleteProductIntegrationPayload>;
  /** Deletes a single `Rate` using its globally unique id. */
  deleteRateByNodeId?: Maybe<DeleteRatePayload>;
  /** Deletes a single `Rate` using a unique key. */
  deleteRate?: Maybe<DeleteRatePayload>;
  /** Deletes a single `Reserve` using its globally unique id. */
  deleteReserveByNodeId?: Maybe<DeleteReservePayload>;
  /** Deletes a single `Reserve` using a unique key. */
  deleteReserve?: Maybe<DeleteReservePayload>;
  /** Deletes a single `Review` using its globally unique id. */
  deleteReviewByNodeId?: Maybe<DeleteReviewPayload>;
  /** Deletes a single `Review` using a unique key. */
  deleteReview?: Maybe<DeleteReviewPayload>;
  /** Deletes a single `TradeLine` using its globally unique id. */
  deleteTradeLineByNodeId?: Maybe<DeleteTradeLinePayload>;
  /** Deletes a single `TradeLine` using a unique key. */
  deleteTradeLine?: Maybe<DeleteTradeLinePayload>;
  /** Deletes a single `UserActivity` using its globally unique id. */
  deleteUserActivityByNodeId?: Maybe<DeleteUserActivityPayload>;
  /** Deletes a single `UserActivity` using a unique key. */
  deleteUserActivity?: Maybe<DeleteUserActivityPayload>;
  /** Deletes a single `VehicleBooking` using its globally unique id. */
  deleteVehicleBookingByNodeId?: Maybe<DeleteVehicleBookingPayload>;
  /** Deletes a single `VehicleBooking` using a unique key. */
  deleteVehicleBooking?: Maybe<DeleteVehicleBookingPayload>;
  /** Deletes a single `VehicleListing` using its globally unique id. */
  deleteVehicleListingByNodeId?: Maybe<DeleteVehicleListingPayload>;
  /** Deletes a single `VehicleListing` using a unique key. */
  deleteVehicleListing?: Maybe<DeleteVehicleListingPayload>;
  /** Deletes a single `VehicleStock` using its globally unique id. */
  deleteVehicleStockByNodeId?: Maybe<DeleteVehicleStockPayload>;
  /** Deletes a single `VehicleStock` using a unique key. */
  deleteVehicleStock?: Maybe<DeleteVehicleStockPayload>;
  /** Deletes a single `VehicleStock` using a unique key. */
  deleteVehicleStockByProviderId?: Maybe<DeleteVehicleStockPayload>;
  /** Deletes a single `VehicleStock` using a unique key. */
  deleteVehicleStockByVin?: Maybe<DeleteVehicleStockPayload>;
  /** Deletes a single `VehicleStock` using a unique key. */
  deleteVehicleStockByStockNumber?: Maybe<DeleteVehicleStockPayload>;
  /** Deletes a single `VerificationCheck` using its globally unique id. */
  deleteVerificationCheckByNodeId?: Maybe<DeleteVerificationCheckPayload>;
  /** Deletes a single `VerificationCheck` using a unique key. */
  deleteVerificationCheck?: Maybe<DeleteVerificationCheckPayload>;
  /** Deletes a single `VerificationCheck` using a unique key. */
  deleteVerificationCheckByName?: Maybe<DeleteVerificationCheckPayload>;
  /** Deletes a single `WidgetSetting` using its globally unique id. */
  deleteWidgetSettingByNodeId?: Maybe<DeleteWidgetSettingPayload>;
  /** Deletes a single `WidgetSetting` using a unique key. */
  deleteWidgetSetting?: Maybe<DeleteWidgetSettingPayload>;
  /** Deletes a single `Widget` using its globally unique id. */
  deleteWidgetByNodeId?: Maybe<DeleteWidgetPayload>;
  /** Deletes a single `Widget` using a unique key. */
  deleteWidget?: Maybe<DeleteWidgetPayload>;
  /** Deletes a single `Widget` using a unique key. */
  deleteWidgetByVariant?: Maybe<DeleteWidgetPayload>;
  deleteCompanyChannel?: Maybe<DeleteCompanyChannelPayload>;
  authSignUp: AuthPayload;
  confirmUserSignUp: ConfirmUserSignUpResponse;
  resendSignUpConfirmationCode: ResendSignUpConfirmationCodeResponse;
  setPreferredLenderOffer?: Maybe<LenderOffers>;
  fetchLenderQuotes?: Maybe<Array<Maybe<LenderQuote>>>;
  setCreditAppRetailerId: SetCreditAppRetailerIdResponse;
  /** @deprecated Use fetchLenderQuotes instead */
  createCreditAppApplication: CreateCreditAppApplicationResponse;
  /** @deprecated Use fetchLenderQuotes instead */
  submitCreditAppApplication: SubmitCreditAppApplicationResponse;
  creditAuth?: Maybe<CreditQuery>;
  creditQuery?: Maybe<CreditQuery>;
  creditConsent?: Maybe<CreditQuery>;
  fetchNeighborhoodView?: Maybe<PartialNeighborhoodView>;
  loanApp?: Maybe<LoanAppResponse>;
  createUser?: Maybe<CreateUserResponse>;
  updateUserField?: Maybe<User>;
  removeUser?: Maybe<UserResponse>;
  copyPermissions?: Maybe<CopyPermissionsResponse>;
  giveUserAccess?: Maybe<UserResponse>;
  revokeAccess?: Maybe<UserResponse>;
  resetUserPassword?: Maybe<ChangeUserPasswordResponse>;
  requestAccess: RequestAccessResponse;
  /** @deprecated Use the new 'userSignup()' resolver, and show the create/join company form. */
  signup: CreateCompanyWithUserResponse;
  createTradeAppLead?: Maybe<TradeAppCustomType>;
  notifyUserBySms?: Maybe<SmsUserNotificationResponse>;
  archiveCompany: ArchiveCompanyResponse;
  renameCompany: RenameCompanyResponse;
  createCompanyWithUser: CreateCompanyWithUserResponse;
  createCompanyByUser: Company;
  resendLeadNotification: NotificationResponse;
  triggerLeadNotification: NotificationResponse;
  updateCompanyMeta?: Maybe<CompanyMetaResponse>;
  updateDeliveryMethodPreferredSender: DeliveryMethodResponse;
  sendTextMessage: SendTextMessageResponse;
  enableProductsForCompany: EnableProductsForCompanyResponse;
  addDistributorRelationship: AddDistributorRelationshipResponse;
  distributeLead: DistributeLeadResponse;
  createTestLead?: Maybe<SubmitLeadResponse>;
  submitLead?: Maybe<SubmitLeadResponse>;
  adminSubmitLead?: Maybe<SubmitLeadResponse>;
  updateLeadMeta?: Maybe<LeadMetaResponse>;
  requestVerification?: Maybe<VerificationResponse>;
  confirmVerificationCode?: Maybe<VerificationResponse>;
  softDeleteLead?: Maybe<DeleteLeadResponse>;
  notifyUser?: Maybe<UserNotificationResponse>;
  notifyCompany?: Maybe<CompanyNotificationResponse>;
  manageCtaSpecs: ManageCtaSpecsResponse;
  /** @deprecated */
  trackAnalytics: AnalyticsEventResponse;
  trackAnalyticsBatch: AnalyticsResponse;
  zohoSubscriptionsWebhook: ZohoSubscriptionsResponse;
  requestIdVerification?: Maybe<RequestIdVerificationResponse>;
  retrieveIdVerification?: Maybe<RetrieveIdVerificationResponse>;
  uploadIdVerificationImage?: Maybe<UploadIdVerificationImagesResponse>;
  requestIncomeVerification?: Maybe<RequestIncomeVerificationResponse>;
  setIncomeLogin?: Maybe<SetIncomeLoginResponse>;
  setDirectDepositAccount?: Maybe<SetDirectDepositAccountResponse>;
  sendFeedback: SendFeedbackResponse;
  getMediaUploadUrl?: Maybe<GetMediaUploadUrlResponse>;
  recordLeadView: RecordLeadViewResponse;
  addAuditLog: AddAuditLogResponse;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAnalyticArgs = {
  input: CreateAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAnalyticsCtaArgs = {
  input: CreateAnalyticsCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppointmentArgs = {
  input: CreateAppointmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAvaAppArgs = {
  input: CreateAvaAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBillableMetricLogArgs = {
  input: CreateBillableMetricLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyAnalyticArgs = {
  input: CreateCompanyAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyChannelArgs = {
  input: CreateCompanyChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyChannelMediaArgs = {
  input: CreateCompanyChannelMediaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyCtaArgs = {
  input: CreateCompanyCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyDomainArgs = {
  input: CreateCompanyDomainInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyDomainsApprovedArgs = {
  input: CreateCompanyDomainsApprovedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyDomainsPathArgs = {
  input: CreateCompanyDomainsPathInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyGroupArgs = {
  input: CreateCompanyGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyIntegrationArgs = {
  input: CreateCompanyIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyLocationArgs = {
  input: CreateCompanyLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyProductArgs = {
  input: CreateCompanyProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyProductIntegrationArgs = {
  input: CreateCompanyProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyUserArgs = {
  input: CreateCompanyUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyWidgetArgs = {
  input: CreateCompanyWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCreditBureauArgs = {
  input: CreateCreditBureauInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCtaWidgetArgs = {
  input: CreateCtaWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCtaArgs = {
  input: CreateCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDealArgs = {
  input: CreateDealInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDeliveryMethodArgs = {
  input: CreateDeliveryMethodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDeliveryMethodsInternalArgs = {
  input: CreateDeliveryMethodsInternalInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDistributorRelationshipArgs = {
  input: CreateDistributorRelationshipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEmailTemplateArgs = {
  input: CreateEmailTemplateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEquifaxLenderArgs = {
  input: CreateEquifaxLenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFieldArgs = {
  input: CreateFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFieldConfigArgs = {
  input: CreateFieldConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFieldKeyArgs = {
  input: CreateFieldKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIdInquiryArgs = {
  input: CreateIdInquiryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIdInquiryVerificationArgs = {
  input: CreateIdInquiryVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIdInquiryVerificationCheckArgs = {
  input: CreateIdInquiryVerificationCheckInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIdMediaArgs = {
  input: CreateIdMediaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIdSessionArgs = {
  input: CreateIdSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIdVerificationArgs = {
  input: CreateIdVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIncomeVerificationArgs = {
  input: CreateIncomeVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIntegrationArgs = {
  input: CreateIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeadArgs = {
  input: CreateLeadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeadCampaignArgs = {
  input: CreateLeadCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeadJourneyArgs = {
  input: CreateLeadJourneyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeadRoleArgs = {
  input: CreateLeadRoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLenderArgs = {
  input: CreateLenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLenderAppArgs = {
  input: CreateLenderAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLenderCreditKeyArgs = {
  input: CreateLenderCreditKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLenderProgramArgs = {
  input: CreateLenderProgramInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLenderProgramBonusArgs = {
  input: CreateLenderProgramBonusInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLenderQuoteArgs = {
  input: CreateLenderQuoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMaxAmortizationArgs = {
  input: CreateMaxAmortizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOptionArgs = {
  input: CreateOptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProductIntegrationArgs = {
  input: CreateProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRateArgs = {
  input: CreateRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReserveArgs = {
  input: CreateReserveInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTradeAppArgs = {
  input: CreateTradeAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTradeLineArgs = {
  input: CreateTradeLineInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserActivityArgs = {
  input: CreateUserActivityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVehicleBookingArgs = {
  input: CreateVehicleBookingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVehicleListingArgs = {
  input: CreateVehicleListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVehicleStockArgs = {
  input: CreateVehicleStockInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVerificationCheckArgs = {
  input: CreateVerificationCheckInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWidgetSettingArgs = {
  input: CreateWidgetSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWidgetArgs = {
  input: CreateWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalyticByNodeIdArgs = {
  input: UpdateAnalyticByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalyticArgs = {
  input: UpdateAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalyticsCtaByNodeIdArgs = {
  input: UpdateAnalyticsCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalyticsCtaArgs = {
  input: UpdateAnalyticsCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppointmentByNodeIdArgs = {
  input: UpdateAppointmentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAvaAppByNodeIdArgs = {
  input: UpdateAvaAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAvaAppArgs = {
  input: UpdateAvaAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAvaAppByLeadIdArgs = {
  input: UpdateAvaAppByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBillableMetricLogByNodeIdArgs = {
  input: UpdateBillableMetricLogByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBillableMetricLogArgs = {
  input: UpdateBillableMetricLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelByNodeIdArgs = {
  input: UpdateChannelByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelByNameArgs = {
  input: UpdateChannelByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByNodeIdArgs = {
  input: UpdateCompanyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByNameArgs = {
  input: UpdateCompanyByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByExternalIdArgs = {
  input: UpdateCompanyByExternalIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByPrimaryGroupArgs = {
  input: UpdateCompanyByPrimaryGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByLagoIdArgs = {
  input: UpdateCompanyByLagoIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyAnalyticByNodeIdArgs = {
  input: UpdateCompanyAnalyticByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyAnalyticArgs = {
  input: UpdateCompanyAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyChannelByNodeIdArgs = {
  input: UpdateCompanyChannelByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyChannelArgs = {
  input: UpdateCompanyChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyChannelByCompanyIdAndNameAndChannelIdArgs = {
  input: UpdateCompanyChannelByCompanyIdAndNameAndChannelIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyChannelMediaByNodeIdArgs = {
  input: UpdateCompanyChannelMediaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyChannelMediaArgs = {
  input: UpdateCompanyChannelMediaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyChannelMediaByCompanyChannelIdAndMediaIdArgs = {
  input: UpdateCompanyChannelMediaByCompanyChannelIdAndMediaIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyCtaByNodeIdArgs = {
  input: UpdateCompanyCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyCtaArgs = {
  input: UpdateCompanyCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainByNodeIdArgs = {
  input: UpdateCompanyDomainByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainArgs = {
  input: UpdateCompanyDomainInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainByDomainArgs = {
  input: UpdateCompanyDomainByDomainInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainsApprovedByNodeIdArgs = {
  input: UpdateCompanyDomainsApprovedByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainsApprovedArgs = {
  input: UpdateCompanyDomainsApprovedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainsPathByNodeIdArgs = {
  input: UpdateCompanyDomainsPathByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainsPathArgs = {
  input: UpdateCompanyDomainsPathInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainsPathByDomainIdAndPathArgs = {
  input: UpdateCompanyDomainsPathByDomainIdAndPathInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyGroupByNodeIdArgs = {
  input: UpdateCompanyGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyGroupArgs = {
  input: UpdateCompanyGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyIntegrationByNodeIdArgs = {
  input: UpdateCompanyIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyIntegrationArgs = {
  input: UpdateCompanyIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyLocationByNodeIdArgs = {
  input: UpdateCompanyLocationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyLocationArgs = {
  input: UpdateCompanyLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyProductByNodeIdArgs = {
  input: UpdateCompanyProductByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyProductArgs = {
  input: UpdateCompanyProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyProductIntegrationByNodeIdArgs = {
  input: UpdateCompanyProductIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyProductIntegrationArgs = {
  input: UpdateCompanyProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyWidgetByNodeIdArgs = {
  input: UpdateCompanyWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyWidgetArgs = {
  input: UpdateCompanyWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCreditBureauByNodeIdArgs = {
  input: UpdateCreditBureauByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCreditBureauArgs = {
  input: UpdateCreditBureauInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCtaWidgetByNodeIdArgs = {
  input: UpdateCtaWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCtaWidgetArgs = {
  input: UpdateCtaWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCtaByNodeIdArgs = {
  input: UpdateCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCtaArgs = {
  input: UpdateCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCtaByProductAndTypeAndVersionArgs = {
  input: UpdateCtaByProductAndTypeAndVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDealByNodeIdArgs = {
  input: UpdateDealByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDealArgs = {
  input: UpdateDealInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDeliveryMethodByNodeIdArgs = {
  input: UpdateDeliveryMethodByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDeliveryMethodArgs = {
  input: UpdateDeliveryMethodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDistributorRelationshipByNodeIdArgs = {
  input: UpdateDistributorRelationshipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDistributorRelationshipArgs = {
  input: UpdateDistributorRelationshipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDistributorRelationshipByDistributorCompanyIdAndCompanyIdArgs = {
  input: UpdateDistributorRelationshipByDistributorCompanyIdAndCompanyIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmailTemplateByNodeIdArgs = {
  input: UpdateEmailTemplateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmailTemplateArgs = {
  input: UpdateEmailTemplateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquifaxLenderByNodeIdArgs = {
  input: UpdateEquifaxLenderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquifaxLenderArgs = {
  input: UpdateEquifaxLenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquifaxLenderByEquifaxIdAndNameArgs = {
  input: UpdateEquifaxLenderByEquifaxIdAndNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldByNodeIdArgs = {
  input: UpdateFieldByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldArgs = {
  input: UpdateFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldByCompanyProductIdAndConfigArgs = {
  input: UpdateFieldByCompanyProductIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldByCompanyChannelIdAndConfigArgs = {
  input: UpdateFieldByCompanyChannelIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldByCompanyIntegrationIdAndConfigArgs = {
  input: UpdateFieldByCompanyIntegrationIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldConfigByNodeIdArgs = {
  input: UpdateFieldConfigByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldConfigArgs = {
  input: UpdateFieldConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldConfigByParentIdAndParentTypeAndKeyArgs = {
  input: UpdateFieldConfigByParentIdAndParentTypeAndKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldKeyByNodeIdArgs = {
  input: UpdateFieldKeyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldKeyArgs = {
  input: UpdateFieldKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldKeyByKeyArgs = {
  input: UpdateFieldKeyByKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileByNodeIdArgs = {
  input: UpdateFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupByNodeIdArgs = {
  input: UpdateGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupByCognitoNameArgs = {
  input: UpdateGroupByCognitoNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdInquiryByNodeIdArgs = {
  input: UpdateIdInquiryByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdInquiryArgs = {
  input: UpdateIdInquiryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdInquiryVerificationByNodeIdArgs = {
  input: UpdateIdInquiryVerificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdInquiryVerificationArgs = {
  input: UpdateIdInquiryVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdInquiryVerificationCheckByNodeIdArgs = {
  input: UpdateIdInquiryVerificationCheckByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdInquiryVerificationCheckArgs = {
  input: UpdateIdInquiryVerificationCheckInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdMediaByNodeIdArgs = {
  input: UpdateIdMediaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdMediaArgs = {
  input: UpdateIdMediaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdSessionByNodeIdArgs = {
  input: UpdateIdSessionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdSessionArgs = {
  input: UpdateIdSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdVerificationByNodeIdArgs = {
  input: UpdateIdVerificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdVerificationArgs = {
  input: UpdateIdVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdVerificationByLeadIdArgs = {
  input: UpdateIdVerificationByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIncomeVerificationByNodeIdArgs = {
  input: UpdateIncomeVerificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIncomeVerificationArgs = {
  input: UpdateIncomeVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIncomeVerificationByLeadIdArgs = {
  input: UpdateIncomeVerificationByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIntegrationByNodeIdArgs = {
  input: UpdateIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIntegrationArgs = {
  input: UpdateIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIntegrationByNameArgs = {
  input: UpdateIntegrationByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadByNodeIdArgs = {
  input: UpdateLeadByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadArgs = {
  input: UpdateLeadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadCampaignByNodeIdArgs = {
  input: UpdateLeadCampaignByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadCampaignArgs = {
  input: UpdateLeadCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadCampaignByLeadIdArgs = {
  input: UpdateLeadCampaignByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadRoleByNodeIdArgs = {
  input: UpdateLeadRoleByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadRoleArgs = {
  input: UpdateLeadRoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderByNodeIdArgs = {
  input: UpdateLenderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderArgs = {
  input: UpdateLenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderByNameArgs = {
  input: UpdateLenderByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderAppByNodeIdArgs = {
  input: UpdateLenderAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderAppArgs = {
  input: UpdateLenderAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderCreditKeyByNodeIdArgs = {
  input: UpdateLenderCreditKeyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderCreditKeyArgs = {
  input: UpdateLenderCreditKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderProgramByNodeIdArgs = {
  input: UpdateLenderProgramByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderProgramArgs = {
  input: UpdateLenderProgramInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderProgramBonusByNodeIdArgs = {
  input: UpdateLenderProgramBonusByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderProgramBonusArgs = {
  input: UpdateLenderProgramBonusInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderQuoteByNodeIdArgs = {
  input: UpdateLenderQuoteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderQuoteArgs = {
  input: UpdateLenderQuoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMaxAmortizationByNodeIdArgs = {
  input: UpdateMaxAmortizationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMaxAmortizationArgs = {
  input: UpdateMaxAmortizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOptionByNodeIdArgs = {
  input: UpdateOptionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOptionArgs = {
  input: UpdateOptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductIntegrationByNodeIdArgs = {
  input: UpdateProductIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductIntegrationArgs = {
  input: UpdateProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRateByNodeIdArgs = {
  input: UpdateRateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRateArgs = {
  input: UpdateRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReserveByNodeIdArgs = {
  input: UpdateReserveByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReserveArgs = {
  input: UpdateReserveInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReviewByNodeIdArgs = {
  input: UpdateReviewByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReviewArgs = {
  input: UpdateReviewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTradeAppByNodeIdArgs = {
  input: UpdateTradeAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTradeAppArgs = {
  input: UpdateTradeAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTradeLineByNodeIdArgs = {
  input: UpdateTradeLineByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTradeLineArgs = {
  input: UpdateTradeLineInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByNodeIdArgs = {
  input: UpdateUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleBookingByNodeIdArgs = {
  input: UpdateVehicleBookingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleBookingArgs = {
  input: UpdateVehicleBookingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleListingByNodeIdArgs = {
  input: UpdateVehicleListingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleListingArgs = {
  input: UpdateVehicleListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleStockByNodeIdArgs = {
  input: UpdateVehicleStockByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleStockArgs = {
  input: UpdateVehicleStockInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleStockByProviderIdArgs = {
  input: UpdateVehicleStockByProviderIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleStockByVinArgs = {
  input: UpdateVehicleStockByVinInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleStockByStockNumberArgs = {
  input: UpdateVehicleStockByStockNumberInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVerificationCheckByNodeIdArgs = {
  input: UpdateVerificationCheckByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVerificationCheckArgs = {
  input: UpdateVerificationCheckInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVerificationCheckByNameArgs = {
  input: UpdateVerificationCheckByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWidgetSettingByNodeIdArgs = {
  input: UpdateWidgetSettingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWidgetSettingArgs = {
  input: UpdateWidgetSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWidgetByNodeIdArgs = {
  input: UpdateWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWidgetArgs = {
  input: UpdateWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWidgetByVariantArgs = {
  input: UpdateWidgetByVariantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalyticByNodeIdArgs = {
  input: DeleteAnalyticByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalyticArgs = {
  input: DeleteAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalyticsCtaByNodeIdArgs = {
  input: DeleteAnalyticsCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalyticsCtaArgs = {
  input: DeleteAnalyticsCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppointmentByNodeIdArgs = {
  input: DeleteAppointmentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppointmentArgs = {
  input: DeleteAppointmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBillableMetricLogByNodeIdArgs = {
  input: DeleteBillableMetricLogByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBillableMetricLogArgs = {
  input: DeleteBillableMetricLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChannelByNodeIdArgs = {
  input: DeleteChannelByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChannelArgs = {
  input: DeleteChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChannelByNameArgs = {
  input: DeleteChannelByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyAnalyticByNodeIdArgs = {
  input: DeleteCompanyAnalyticByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyAnalyticArgs = {
  input: DeleteCompanyAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyChannelMediaByNodeIdArgs = {
  input: DeleteCompanyChannelMediaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyChannelMediaArgs = {
  input: DeleteCompanyChannelMediaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyChannelMediaByCompanyChannelIdAndMediaIdArgs = {
  input: DeleteCompanyChannelMediaByCompanyChannelIdAndMediaIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyCtaByNodeIdArgs = {
  input: DeleteCompanyCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyCtaArgs = {
  input: DeleteCompanyCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainByNodeIdArgs = {
  input: DeleteCompanyDomainByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainArgs = {
  input: DeleteCompanyDomainInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainByDomainArgs = {
  input: DeleteCompanyDomainByDomainInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainsApprovedByNodeIdArgs = {
  input: DeleteCompanyDomainsApprovedByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainsApprovedArgs = {
  input: DeleteCompanyDomainsApprovedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainsPathByNodeIdArgs = {
  input: DeleteCompanyDomainsPathByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainsPathArgs = {
  input: DeleteCompanyDomainsPathInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainsPathByDomainIdAndPathArgs = {
  input: DeleteCompanyDomainsPathByDomainIdAndPathInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyGroupByNodeIdArgs = {
  input: DeleteCompanyGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyGroupArgs = {
  input: DeleteCompanyGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyIntegrationByNodeIdArgs = {
  input: DeleteCompanyIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyIntegrationArgs = {
  input: DeleteCompanyIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyLocationByNodeIdArgs = {
  input: DeleteCompanyLocationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyLocationArgs = {
  input: DeleteCompanyLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyProductByNodeIdArgs = {
  input: DeleteCompanyProductByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyProductArgs = {
  input: DeleteCompanyProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyProductIntegrationByNodeIdArgs = {
  input: DeleteCompanyProductIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyProductIntegrationArgs = {
  input: DeleteCompanyProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyWidgetByNodeIdArgs = {
  input: DeleteCompanyWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyWidgetArgs = {
  input: DeleteCompanyWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCtaWidgetByNodeIdArgs = {
  input: DeleteCtaWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCtaWidgetArgs = {
  input: DeleteCtaWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCtaByNodeIdArgs = {
  input: DeleteCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCtaArgs = {
  input: DeleteCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCtaByProductAndTypeAndVersionArgs = {
  input: DeleteCtaByProductAndTypeAndVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDealByNodeIdArgs = {
  input: DeleteDealByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDealArgs = {
  input: DeleteDealInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDeliveryMethodByNodeIdArgs = {
  input: DeleteDeliveryMethodByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDeliveryMethodArgs = {
  input: DeleteDeliveryMethodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDistributorRelationshipByNodeIdArgs = {
  input: DeleteDistributorRelationshipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDistributorRelationshipArgs = {
  input: DeleteDistributorRelationshipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDistributorRelationshipByDistributorCompanyIdAndCompanyIdArgs = {
  input: DeleteDistributorRelationshipByDistributorCompanyIdAndCompanyIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmailTemplateByNodeIdArgs = {
  input: DeleteEmailTemplateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmailTemplateArgs = {
  input: DeleteEmailTemplateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquifaxLenderByNodeIdArgs = {
  input: DeleteEquifaxLenderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquifaxLenderArgs = {
  input: DeleteEquifaxLenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquifaxLenderByEquifaxIdAndNameArgs = {
  input: DeleteEquifaxLenderByEquifaxIdAndNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventByNodeIdArgs = {
  input: DeleteEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldByNodeIdArgs = {
  input: DeleteFieldByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldArgs = {
  input: DeleteFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldByCompanyProductIdAndConfigArgs = {
  input: DeleteFieldByCompanyProductIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldByCompanyChannelIdAndConfigArgs = {
  input: DeleteFieldByCompanyChannelIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldByCompanyIntegrationIdAndConfigArgs = {
  input: DeleteFieldByCompanyIntegrationIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldConfigByNodeIdArgs = {
  input: DeleteFieldConfigByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldConfigArgs = {
  input: DeleteFieldConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldConfigByParentIdAndParentTypeAndKeyArgs = {
  input: DeleteFieldConfigByParentIdAndParentTypeAndKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldKeyByNodeIdArgs = {
  input: DeleteFieldKeyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldKeyArgs = {
  input: DeleteFieldKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldKeyByKeyArgs = {
  input: DeleteFieldKeyByKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileByNodeIdArgs = {
  input: DeleteFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdInquiryByNodeIdArgs = {
  input: DeleteIdInquiryByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdInquiryArgs = {
  input: DeleteIdInquiryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdInquiryVerificationByNodeIdArgs = {
  input: DeleteIdInquiryVerificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdInquiryVerificationArgs = {
  input: DeleteIdInquiryVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdInquiryVerificationCheckByNodeIdArgs = {
  input: DeleteIdInquiryVerificationCheckByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdInquiryVerificationCheckArgs = {
  input: DeleteIdInquiryVerificationCheckInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdMediaByNodeIdArgs = {
  input: DeleteIdMediaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdMediaArgs = {
  input: DeleteIdMediaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdSessionByNodeIdArgs = {
  input: DeleteIdSessionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdSessionArgs = {
  input: DeleteIdSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdVerificationByNodeIdArgs = {
  input: DeleteIdVerificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdVerificationArgs = {
  input: DeleteIdVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdVerificationByLeadIdArgs = {
  input: DeleteIdVerificationByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIncomeVerificationByNodeIdArgs = {
  input: DeleteIncomeVerificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIncomeVerificationArgs = {
  input: DeleteIncomeVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIncomeVerificationByLeadIdArgs = {
  input: DeleteIncomeVerificationByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIntegrationByNodeIdArgs = {
  input: DeleteIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIntegrationArgs = {
  input: DeleteIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIntegrationByNameArgs = {
  input: DeleteIntegrationByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadByNodeIdArgs = {
  input: DeleteLeadByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadArgs = {
  input: DeleteLeadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadCampaignByNodeIdArgs = {
  input: DeleteLeadCampaignByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadCampaignArgs = {
  input: DeleteLeadCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadCampaignByLeadIdArgs = {
  input: DeleteLeadCampaignByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadRoleByNodeIdArgs = {
  input: DeleteLeadRoleByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadRoleArgs = {
  input: DeleteLeadRoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderByNodeIdArgs = {
  input: DeleteLenderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderArgs = {
  input: DeleteLenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderByNameArgs = {
  input: DeleteLenderByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderAppByNodeIdArgs = {
  input: DeleteLenderAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderAppArgs = {
  input: DeleteLenderAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderCreditKeyByNodeIdArgs = {
  input: DeleteLenderCreditKeyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderCreditKeyArgs = {
  input: DeleteLenderCreditKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderProgramByNodeIdArgs = {
  input: DeleteLenderProgramByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderProgramArgs = {
  input: DeleteLenderProgramInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderProgramBonusByNodeIdArgs = {
  input: DeleteLenderProgramBonusByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderProgramBonusArgs = {
  input: DeleteLenderProgramBonusInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderQuoteByNodeIdArgs = {
  input: DeleteLenderQuoteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderQuoteArgs = {
  input: DeleteLenderQuoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMaxAmortizationByNodeIdArgs = {
  input: DeleteMaxAmortizationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMaxAmortizationArgs = {
  input: DeleteMaxAmortizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOptionByNodeIdArgs = {
  input: DeleteOptionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOptionArgs = {
  input: DeleteOptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProductIntegrationByNodeIdArgs = {
  input: DeleteProductIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProductIntegrationArgs = {
  input: DeleteProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRateByNodeIdArgs = {
  input: DeleteRateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRateArgs = {
  input: DeleteRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReserveByNodeIdArgs = {
  input: DeleteReserveByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReserveArgs = {
  input: DeleteReserveInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReviewByNodeIdArgs = {
  input: DeleteReviewByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReviewArgs = {
  input: DeleteReviewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTradeLineByNodeIdArgs = {
  input: DeleteTradeLineByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTradeLineArgs = {
  input: DeleteTradeLineInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserActivityByNodeIdArgs = {
  input: DeleteUserActivityByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserActivityArgs = {
  input: DeleteUserActivityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVehicleBookingByNodeIdArgs = {
  input: DeleteVehicleBookingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVehicleBookingArgs = {
  input: DeleteVehicleBookingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVehicleListingByNodeIdArgs = {
  input: DeleteVehicleListingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVehicleListingArgs = {
  input: DeleteVehicleListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVehicleStockByNodeIdArgs = {
  input: DeleteVehicleStockByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVehicleStockArgs = {
  input: DeleteVehicleStockInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVehicleStockByProviderIdArgs = {
  input: DeleteVehicleStockByProviderIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVehicleStockByVinArgs = {
  input: DeleteVehicleStockByVinInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVehicleStockByStockNumberArgs = {
  input: DeleteVehicleStockByStockNumberInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVerificationCheckByNodeIdArgs = {
  input: DeleteVerificationCheckByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVerificationCheckArgs = {
  input: DeleteVerificationCheckInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVerificationCheckByNameArgs = {
  input: DeleteVerificationCheckByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWidgetSettingByNodeIdArgs = {
  input: DeleteWidgetSettingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWidgetSettingArgs = {
  input: DeleteWidgetSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWidgetByNodeIdArgs = {
  input: DeleteWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWidgetArgs = {
  input: DeleteWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWidgetByVariantArgs = {
  input: DeleteWidgetByVariantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyChannelArgs = {
  input: DeleteCompanyChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAuthSignUpArgs = {
  input: AuthSignUpInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationConfirmUserSignUpArgs = {
  input: ConfirmUserSignUpInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResendSignUpConfirmationCodeArgs = {
  input: ResendSignUpConfirmationCodeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetPreferredLenderOfferArgs = {
  input: SetPreferredLenderOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationFetchLenderQuotesArgs = {
  input: FetchLenderQuotesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetCreditAppRetailerIdArgs = {
  companyId: Scalars['Int'];
  retailerId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCreditAppApplicationArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubmitCreditAppApplicationArgs = {
  leadId: Scalars['UUID'];
  creditorIds: Array<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreditAuthArgs = {
  widgetId: Scalars['String'];
  companyId?: Maybe<Scalars['Int']>;
  referrer?: Maybe<Scalars['String']>;
  input: CreditAuthInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreditQueryArgs = {
  input: CreditQueryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreditConsentArgs = {
  accessToken: Scalars['String'];
  dealerCreditConsent: Scalars['Boolean'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationFetchNeighborhoodViewArgs = {
  leadId: Scalars['UUID'];
  bypassExistingCreditBureau?: Maybe<Scalars['Boolean']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoanAppArgs = {
  companyId?: Maybe<Scalars['Int']>;
  referrer?: Maybe<Scalars['String']>;
  input: LoanAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserFieldArgs = {
  id: Scalars['Int'];
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCopyPermissionsArgs = {
  input: CopyPermissionsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGiveUserAccessArgs = {
  input: GiveUserAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRevokeAccessArgs = {
  input: RevokeUserAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetUserPasswordArgs = {
  username: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRequestAccessArgs = {
  companyId: Scalars['Int'];
  topic: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSignupArgs = {
  input: SignupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTradeAppLeadArgs = {
  widgetId: Scalars['String'];
  companyId?: Maybe<Scalars['Int']>;
  referrer?: Maybe<Scalars['String']>;
  input: TradeAppCustomInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationNotifyUserBySmsArgs = {
  input: SmsUserNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationArchiveCompanyArgs = {
  input: ArchiveCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRenameCompanyArgs = {
  input: RenameCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyWithUserArgs = {
  input: CreateCompanyWithUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyByUserArgs = {
  input: CreateCompanyByUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResendLeadNotificationArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTriggerLeadNotificationArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyMetaArgs = {
  companyId: Scalars['Int'];
  meta: Scalars['JSON'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDeliveryMethodPreferredSenderArgs = {
  deliveryMethodId: Scalars['Int'];
  preferredSender: Scalars['String'];
  subjectOverride?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendTextMessageArgs = {
  input: SendTextMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationEnableProductsForCompanyArgs = {
  input: EnableProductsForCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddDistributorRelationshipArgs = {
  input: AddDistributorRelationshipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDistributeLeadArgs = {
  input: DistributeLeadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTestLeadArgs = {
  companyId: Scalars['Int'];
  sourceUrl: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubmitLeadArgs = {
  widgetId: Scalars['String'];
  companyId?: Maybe<Scalars['Int']>;
  referrer?: Maybe<Scalars['String']>;
  input: LeadInput;
  productType?: Maybe<ProductType>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminSubmitLeadArgs = {
  companyId: Scalars['Int'];
  input: LeadInput;
  productTypes?: Maybe<Array<Maybe<ProductType>>>;
  messageOverride?: Maybe<Scalars['String']>;
  creditConsentLead?: Maybe<Scalars['Boolean']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadMetaArgs = {
  leadId: Scalars['UUID'];
  companyId: Scalars['Int'];
  meta: Scalars['JSON'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRequestVerificationArgs = {
  leadId: Scalars['UUID'];
  type: VerificationChannel;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationConfirmVerificationCodeArgs = {
  leadId: Scalars['UUID'];
  type: VerificationChannel;
  code: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSoftDeleteLeadArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationNotifyUserArgs = {
  input: UserNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationNotifyCompanyArgs = {
  input: CompanyNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationManageCtaSpecsArgs = {
  input: ManageCtaSpecsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackAnalyticsArgs = {
  input: AnalyticsParamsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackAnalyticsBatchArgs = {
  input: AnalyticsParamsInput2;
  events: Array<AnalyticsParamsEvents>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationZohoSubscriptionsWebhookArgs = {
  payload?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRequestIdVerificationArgs = {
  input: RequestIdVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRetrieveIdVerificationArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUploadIdVerificationImageArgs = {
  input: UploadAutocorpIdImageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRequestIncomeVerificationArgs = {
  input: RequestIncomeVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetIncomeLoginArgs = {
  leadId: Scalars['UUID'];
  loginId: Scalars['String'];
  directDepositAccountId?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetDirectDepositAccountArgs = {
  leadId: Scalars['UUID'];
  accountId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendFeedbackArgs = {
  leadId: Scalars['UUID'];
  message: Scalars['String'];
  type: FeedbackType;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetMediaUploadUrlArgs = {
  input: GetMediaUploadUrlInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRecordLeadViewArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddAuditLogArgs = {
  input: AddAuditLogInput;
};

export type NeighborhoodView = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  postalCode: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  score: Scalars['Float'];
  equifaxRiskScore?: Maybe<Scalars['Float']>;
  consumerRiskPredictor?: Maybe<Scalars['Float']>;
  rawJson?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `Lead`. */
  leads: LeadsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByLeadNeighborhoodViewIdAndCompanyId: NeighborhoodViewCompaniesByLeadNeighborhoodViewIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByLeadNeighborhoodViewIdAndCompanyChannelId: NeighborhoodViewCompanyChannelsByLeadNeighborhoodViewIdAndCompanyChannelIdManyToManyConnection;
};


export type NeighborhoodViewLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type NeighborhoodViewCompaniesByLeadNeighborhoodViewIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type NeighborhoodViewCompanyChannelsByLeadNeighborhoodViewIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};

export type NeighborhoodViewAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<NeighborhoodViewSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<NeighborhoodViewDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<NeighborhoodViewMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<NeighborhoodViewMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<NeighborhoodViewAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<NeighborhoodViewStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<NeighborhoodViewStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<NeighborhoodViewVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<NeighborhoodViewVariancePopulationAggregates>;
};

export type NeighborhoodViewAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of score across the matching connection */
  score?: Maybe<Scalars['Float']>;
  /** Mean average of equifaxRiskScore across the matching connection */
  equifaxRiskScore?: Maybe<Scalars['Float']>;
  /** Mean average of consumerRiskPredictor across the matching connection */
  consumerRiskPredictor?: Maybe<Scalars['Float']>;
};

/** A connection to a list of `Company` values, with data from `Lead`. */
export type NeighborhoodViewCompaniesByLeadNeighborhoodViewIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `Lead`, and the cursor to aid in pagination. */
  edges: Array<NeighborhoodViewCompaniesByLeadNeighborhoodViewIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `Lead`. */
export type NeighborhoodViewCompaniesByLeadNeighborhoodViewIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `Lead`. */
export type NeighborhoodViewCompaniesByLeadNeighborhoodViewIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `Lead`. */
  leads: LeadsConnection;
};


/** A `Company` edge in the connection, with data from `Lead`. */
export type NeighborhoodViewCompaniesByLeadNeighborhoodViewIdAndCompanyIdManyToManyEdgeLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `Lead`. */
export type NeighborhoodViewCompanyChannelsByLeadNeighborhoodViewIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `Lead`, and the cursor to aid in pagination. */
  edges: Array<NeighborhoodViewCompanyChannelsByLeadNeighborhoodViewIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `Lead`. */
export type NeighborhoodViewCompanyChannelsByLeadNeighborhoodViewIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `Lead`. */
export type NeighborhoodViewCompanyChannelsByLeadNeighborhoodViewIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `Lead`. */
  leads: LeadsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `Lead`. */
export type NeighborhoodViewCompanyChannelsByLeadNeighborhoodViewIdAndCompanyChannelIdManyToManyEdgeLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

/**
 * A condition to be used against `NeighborhoodView` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NeighborhoodViewCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `postalCode` field. */
  postalCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `street` field. */
  street?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `score` field. */
  score?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `equifaxRiskScore` field. */
  equifaxRiskScore?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `consumerRiskPredictor` field. */
  consumerRiskPredictor?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `rawJson` field. */
  rawJson?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type NeighborhoodViewDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of postalCode across the matching connection */
  postalCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of street across the matching connection */
  street?: Maybe<Scalars['BigInt']>;
  /** Distinct count of score across the matching connection */
  score?: Maybe<Scalars['BigInt']>;
  /** Distinct count of equifaxRiskScore across the matching connection */
  equifaxRiskScore?: Maybe<Scalars['BigInt']>;
  /** Distinct count of consumerRiskPredictor across the matching connection */
  consumerRiskPredictor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawJson across the matching connection */
  rawJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `NeighborhoodView` object types. All fields are combined with a logical ‘and.’ */
export type NeighborhoodViewFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `postalCode` field. */
  postalCode?: Maybe<StringFilter>;
  /** Filter by the object’s `street` field. */
  street?: Maybe<StringFilter>;
  /** Filter by the object’s `score` field. */
  score?: Maybe<FloatFilter>;
  /** Filter by the object’s `equifaxRiskScore` field. */
  equifaxRiskScore?: Maybe<FloatFilter>;
  /** Filter by the object’s `consumerRiskPredictor` field. */
  consumerRiskPredictor?: Maybe<FloatFilter>;
  /** Filter by the object’s `rawJson` field. */
  rawJson?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `leads` relation. */
  leads?: Maybe<NeighborhoodViewToManyLeadFilter>;
  /** Some related `leads` exist. */
  leadsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<NeighborhoodViewFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<NeighborhoodViewFilter>>;
  /** Negates the expression. */
  not?: Maybe<NeighborhoodViewFilter>;
};

/** Grouping methods for `NeighborhoodView` for usage during aggregation. */
export enum NeighborhoodViewGroupBy {
  PostalCode = 'POSTAL_CODE',
  Street = 'STREET',
  Score = 'SCORE',
  EquifaxRiskScore = 'EQUIFAX_RISK_SCORE',
  ConsumerRiskPredictor = 'CONSUMER_RISK_PREDICTOR',
  RawJson = 'RAW_JSON',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type NeighborhoodViewHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  score?: Maybe<HavingFloatFilter>;
  equifaxRiskScore?: Maybe<HavingFloatFilter>;
  consumerRiskPredictor?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type NeighborhoodViewHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  score?: Maybe<HavingFloatFilter>;
  equifaxRiskScore?: Maybe<HavingFloatFilter>;
  consumerRiskPredictor?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `NeighborhoodView` aggregates. */
export type NeighborhoodViewHavingInput = {
  AND?: Maybe<Array<NeighborhoodViewHavingInput>>;
  OR?: Maybe<Array<NeighborhoodViewHavingInput>>;
  sum?: Maybe<NeighborhoodViewHavingSumInput>;
  distinctCount?: Maybe<NeighborhoodViewHavingDistinctCountInput>;
  min?: Maybe<NeighborhoodViewHavingMinInput>;
  max?: Maybe<NeighborhoodViewHavingMaxInput>;
  average?: Maybe<NeighborhoodViewHavingAverageInput>;
  stddevSample?: Maybe<NeighborhoodViewHavingStddevSampleInput>;
  stddevPopulation?: Maybe<NeighborhoodViewHavingStddevPopulationInput>;
  varianceSample?: Maybe<NeighborhoodViewHavingVarianceSampleInput>;
  variancePopulation?: Maybe<NeighborhoodViewHavingVariancePopulationInput>;
};

export type NeighborhoodViewHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  score?: Maybe<HavingFloatFilter>;
  equifaxRiskScore?: Maybe<HavingFloatFilter>;
  consumerRiskPredictor?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type NeighborhoodViewHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  score?: Maybe<HavingFloatFilter>;
  equifaxRiskScore?: Maybe<HavingFloatFilter>;
  consumerRiskPredictor?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type NeighborhoodViewHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  score?: Maybe<HavingFloatFilter>;
  equifaxRiskScore?: Maybe<HavingFloatFilter>;
  consumerRiskPredictor?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type NeighborhoodViewHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  score?: Maybe<HavingFloatFilter>;
  equifaxRiskScore?: Maybe<HavingFloatFilter>;
  consumerRiskPredictor?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type NeighborhoodViewHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  score?: Maybe<HavingFloatFilter>;
  equifaxRiskScore?: Maybe<HavingFloatFilter>;
  consumerRiskPredictor?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type NeighborhoodViewHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  score?: Maybe<HavingFloatFilter>;
  equifaxRiskScore?: Maybe<HavingFloatFilter>;
  consumerRiskPredictor?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type NeighborhoodViewHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  score?: Maybe<HavingFloatFilter>;
  equifaxRiskScore?: Maybe<HavingFloatFilter>;
  consumerRiskPredictor?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type NeighborhoodViewMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of score across the matching connection */
  score?: Maybe<Scalars['Float']>;
  /** Maximum of equifaxRiskScore across the matching connection */
  equifaxRiskScore?: Maybe<Scalars['Float']>;
  /** Maximum of consumerRiskPredictor across the matching connection */
  consumerRiskPredictor?: Maybe<Scalars['Float']>;
};

export type NeighborhoodViewMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of score across the matching connection */
  score?: Maybe<Scalars['Float']>;
  /** Minimum of equifaxRiskScore across the matching connection */
  equifaxRiskScore?: Maybe<Scalars['Float']>;
  /** Minimum of consumerRiskPredictor across the matching connection */
  consumerRiskPredictor?: Maybe<Scalars['Float']>;
};

/** The fields on `neighborhoodView` to look up the row to connect. */
export type NeighborhoodViewNeighborhoodViewPkeyConnect = {
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to connect. */
export type NeighborhoodViewNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `neighborhoodView` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NeighborhoodViewOnLeadForLeadNeighborhoodViewIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `neighborhoodView` to look up the row to update. */
export type NeighborhoodViewOnLeadForLeadNeighborhoodViewIdFkeyUsingNeighborhoodViewPkeyUpdate = {
  /** An object where the defined keys will be set on the `neighborhoodView` being updated. */
  patch: UpdateNeighborhoodViewOnLeadForLeadNeighborhoodViewIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `NeighborhoodView`. Fields that are set will be updated. */
export type NeighborhoodViewPatch = {
  id?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  equifaxRiskScore?: Maybe<Scalars['Float']>;
  consumerRiskPredictor?: Maybe<Scalars['Float']>;
  rawJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadsUsingId?: Maybe<LeadNeighborhoodViewIdFkeyInverseInput>;
};

export type NeighborhoodViewStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of score across the matching connection */
  score?: Maybe<Scalars['Float']>;
  /** Population standard deviation of equifaxRiskScore across the matching connection */
  equifaxRiskScore?: Maybe<Scalars['Float']>;
  /** Population standard deviation of consumerRiskPredictor across the matching connection */
  consumerRiskPredictor?: Maybe<Scalars['Float']>;
};

export type NeighborhoodViewStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of score across the matching connection */
  score?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of equifaxRiskScore across the matching connection */
  equifaxRiskScore?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of consumerRiskPredictor across the matching connection */
  consumerRiskPredictor?: Maybe<Scalars['Float']>;
};

export type NeighborhoodViewSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of score across the matching connection */
  score: Scalars['Float'];
  /** Sum of equifaxRiskScore across the matching connection */
  equifaxRiskScore: Scalars['Float'];
  /** Sum of consumerRiskPredictor across the matching connection */
  consumerRiskPredictor: Scalars['Float'];
};

/** A filter to be used against many `Lead` object types. All fields are combined with a logical ‘and.’ */
export type NeighborhoodViewToManyLeadFilter = {
  /** Every related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeadFilter>;
  /** Some related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeadFilter>;
  /** No related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeadFilter>;
  /** Aggregates across related `Lead` match the filter criteria. */
  aggregates?: Maybe<LeadAggregatesFilter>;
};

export type NeighborhoodViewVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of score across the matching connection */
  score?: Maybe<Scalars['Float']>;
  /** Population variance of equifaxRiskScore across the matching connection */
  equifaxRiskScore?: Maybe<Scalars['Float']>;
  /** Population variance of consumerRiskPredictor across the matching connection */
  consumerRiskPredictor?: Maybe<Scalars['Float']>;
};

export type NeighborhoodViewVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of score across the matching connection */
  score?: Maybe<Scalars['Float']>;
  /** Sample variance of equifaxRiskScore across the matching connection */
  equifaxRiskScore?: Maybe<Scalars['Float']>;
  /** Sample variance of consumerRiskPredictor across the matching connection */
  consumerRiskPredictor?: Maybe<Scalars['Float']>;
};

/** A connection to a list of `NeighborhoodView` values. */
export type NeighborhoodViewsConnection = {
  /** A list of `NeighborhoodView` objects. */
  nodes: Array<NeighborhoodView>;
  /** A list of edges which contains the `NeighborhoodView` and cursor to aid in pagination. */
  edges: Array<NeighborhoodViewsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NeighborhoodView` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NeighborhoodViewAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NeighborhoodViewAggregates>>;
};


/** A connection to a list of `NeighborhoodView` values. */
export type NeighborhoodViewsConnectionGroupedAggregatesArgs = {
  groupBy: Array<NeighborhoodViewGroupBy>;
  having?: Maybe<NeighborhoodViewHavingInput>;
};

/** A `NeighborhoodView` edge in the connection. */
export type NeighborhoodViewsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NeighborhoodView` at the end of the edge. */
  node: NeighborhoodView;
};

/** Methods to use when ordering `NeighborhoodView`. */
export enum NeighborhoodViewsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PostalCodeAsc = 'POSTAL_CODE_ASC',
  PostalCodeDesc = 'POSTAL_CODE_DESC',
  StreetAsc = 'STREET_ASC',
  StreetDesc = 'STREET_DESC',
  ScoreAsc = 'SCORE_ASC',
  ScoreDesc = 'SCORE_DESC',
  EquifaxRiskScoreAsc = 'EQUIFAX_RISK_SCORE_ASC',
  EquifaxRiskScoreDesc = 'EQUIFAX_RISK_SCORE_DESC',
  ConsumerRiskPredictorAsc = 'CONSUMER_RISK_PREDICTOR_ASC',
  ConsumerRiskPredictorDesc = 'CONSUMER_RISK_PREDICTOR_DESC',
  RawJsonAsc = 'RAW_JSON_ASC',
  RawJsonDesc = 'RAW_JSON_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  LeadsCountAsc = 'LEADS_COUNT_ASC',
  LeadsCountDesc = 'LEADS_COUNT_DESC',
  LeadsSumIdAsc = 'LEADS_SUM_ID_ASC',
  LeadsSumIdDesc = 'LEADS_SUM_ID_DESC',
  LeadsSumCompanyIdAsc = 'LEADS_SUM_COMPANY_ID_ASC',
  LeadsSumCompanyIdDesc = 'LEADS_SUM_COMPANY_ID_DESC',
  LeadsSumCreatedAtAsc = 'LEADS_SUM_CREATED_AT_ASC',
  LeadsSumCreatedAtDesc = 'LEADS_SUM_CREATED_AT_DESC',
  LeadsSumFirstNameAsc = 'LEADS_SUM_FIRST_NAME_ASC',
  LeadsSumFirstNameDesc = 'LEADS_SUM_FIRST_NAME_DESC',
  LeadsSumLastNameAsc = 'LEADS_SUM_LAST_NAME_ASC',
  LeadsSumLastNameDesc = 'LEADS_SUM_LAST_NAME_DESC',
  LeadsSumDateOfBirthAsc = 'LEADS_SUM_DATE_OF_BIRTH_ASC',
  LeadsSumDateOfBirthDesc = 'LEADS_SUM_DATE_OF_BIRTH_DESC',
  LeadsSumStreetAsc = 'LEADS_SUM_STREET_ASC',
  LeadsSumStreetDesc = 'LEADS_SUM_STREET_DESC',
  LeadsSumStreetNumberAsc = 'LEADS_SUM_STREET_NUMBER_ASC',
  LeadsSumStreetNumberDesc = 'LEADS_SUM_STREET_NUMBER_DESC',
  LeadsSumUnitAsc = 'LEADS_SUM_UNIT_ASC',
  LeadsSumUnitDesc = 'LEADS_SUM_UNIT_DESC',
  LeadsSumCityAsc = 'LEADS_SUM_CITY_ASC',
  LeadsSumCityDesc = 'LEADS_SUM_CITY_DESC',
  LeadsSumCountryAsc = 'LEADS_SUM_COUNTRY_ASC',
  LeadsSumCountryDesc = 'LEADS_SUM_COUNTRY_DESC',
  LeadsSumPostalCodeAsc = 'LEADS_SUM_POSTAL_CODE_ASC',
  LeadsSumPostalCodeDesc = 'LEADS_SUM_POSTAL_CODE_DESC',
  LeadsSumProvinceCodeAsc = 'LEADS_SUM_PROVINCE_CODE_ASC',
  LeadsSumProvinceCodeDesc = 'LEADS_SUM_PROVINCE_CODE_DESC',
  LeadsSumEmailAddressAsc = 'LEADS_SUM_EMAIL_ADDRESS_ASC',
  LeadsSumEmailAddressDesc = 'LEADS_SUM_EMAIL_ADDRESS_DESC',
  LeadsSumPhoneNumberAsc = 'LEADS_SUM_PHONE_NUMBER_ASC',
  LeadsSumPhoneNumberDesc = 'LEADS_SUM_PHONE_NUMBER_DESC',
  LeadsSumSourceUrlAsc = 'LEADS_SUM_SOURCE_URL_ASC',
  LeadsSumSourceUrlDesc = 'LEADS_SUM_SOURCE_URL_DESC',
  LeadsSumTransactionKeyAsc = 'LEADS_SUM_TRANSACTION_KEY_ASC',
  LeadsSumTransactionKeyDesc = 'LEADS_SUM_TRANSACTION_KEY_DESC',
  LeadsSumLeadspediaIdAsc = 'LEADS_SUM_LEADSPEDIA_ID_ASC',
  LeadsSumLeadspediaIdDesc = 'LEADS_SUM_LEADSPEDIA_ID_DESC',
  LeadsSumUpdatedAtAsc = 'LEADS_SUM_UPDATED_AT_ASC',
  LeadsSumUpdatedAtDesc = 'LEADS_SUM_UPDATED_AT_DESC',
  LeadsSumSearchTextAsc = 'LEADS_SUM_SEARCH_TEXT_ASC',
  LeadsSumSearchTextDesc = 'LEADS_SUM_SEARCH_TEXT_DESC',
  LeadsSumCommentAsc = 'LEADS_SUM_COMMENT_ASC',
  LeadsSumCommentDesc = 'LEADS_SUM_COMMENT_DESC',
  LeadsSumStateAsc = 'LEADS_SUM_STATE_ASC',
  LeadsSumStateDesc = 'LEADS_SUM_STATE_DESC',
  LeadsSumReadAsc = 'LEADS_SUM_READ_ASC',
  LeadsSumReadDesc = 'LEADS_SUM_READ_DESC',
  LeadsSumExternalIdsAsc = 'LEADS_SUM_EXTERNAL_IDS_ASC',
  LeadsSumExternalIdsDesc = 'LEADS_SUM_EXTERNAL_IDS_DESC',
  LeadsSumMetaAsc = 'LEADS_SUM_META_ASC',
  LeadsSumMetaDesc = 'LEADS_SUM_META_DESC',
  LeadsSumAdditionalInformationAsc = 'LEADS_SUM_ADDITIONAL_INFORMATION_ASC',
  LeadsSumAdditionalInformationDesc = 'LEADS_SUM_ADDITIONAL_INFORMATION_DESC',
  LeadsSumPhoneVerifiedAtAsc = 'LEADS_SUM_PHONE_VERIFIED_AT_ASC',
  LeadsSumPhoneVerifiedAtDesc = 'LEADS_SUM_PHONE_VERIFIED_AT_DESC',
  LeadsSumIpAddressAsc = 'LEADS_SUM_IP_ADDRESS_ASC',
  LeadsSumIpAddressDesc = 'LEADS_SUM_IP_ADDRESS_DESC',
  LeadsSumClientIdAsc = 'LEADS_SUM_CLIENT_ID_ASC',
  LeadsSumClientIdDesc = 'LEADS_SUM_CLIENT_ID_DESC',
  LeadsSumDeletedAsc = 'LEADS_SUM_DELETED_ASC',
  LeadsSumDeletedDesc = 'LEADS_SUM_DELETED_DESC',
  LeadsSumPriorityAsc = 'LEADS_SUM_PRIORITY_ASC',
  LeadsSumPriorityDesc = 'LEADS_SUM_PRIORITY_DESC',
  LeadsSumAssigneeAsc = 'LEADS_SUM_ASSIGNEE_ASC',
  LeadsSumAssigneeDesc = 'LEADS_SUM_ASSIGNEE_DESC',
  LeadsSumShortIdAsc = 'LEADS_SUM_SHORT_ID_ASC',
  LeadsSumShortIdDesc = 'LEADS_SUM_SHORT_ID_DESC',
  LeadsSumCompanyChannelIdAsc = 'LEADS_SUM_COMPANY_CHANNEL_ID_ASC',
  LeadsSumCompanyChannelIdDesc = 'LEADS_SUM_COMPANY_CHANNEL_ID_DESC',
  LeadsSumNeighborhoodViewIdAsc = 'LEADS_SUM_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsSumNeighborhoodViewIdDesc = 'LEADS_SUM_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsSumMaritalStatusAsc = 'LEADS_SUM_MARITAL_STATUS_ASC',
  LeadsSumMaritalStatusDesc = 'LEADS_SUM_MARITAL_STATUS_DESC',
  LeadsSumDesiredVehicleAsc = 'LEADS_SUM_DESIRED_VEHICLE_ASC',
  LeadsSumDesiredVehicleDesc = 'LEADS_SUM_DESIRED_VEHICLE_DESC',
  LeadsSumJobTitleAsc = 'LEADS_SUM_JOB_TITLE_ASC',
  LeadsSumJobTitleDesc = 'LEADS_SUM_JOB_TITLE_DESC',
  LeadsSumIncomePeriodAsc = 'LEADS_SUM_INCOME_PERIOD_ASC',
  LeadsSumIncomePeriodDesc = 'LEADS_SUM_INCOME_PERIOD_DESC',
  LeadsSumSalaryHourlyAsc = 'LEADS_SUM_SALARY_HOURLY_ASC',
  LeadsSumSalaryHourlyDesc = 'LEADS_SUM_SALARY_HOURLY_DESC',
  LeadsSumIncomeSourceAsc = 'LEADS_SUM_INCOME_SOURCE_ASC',
  LeadsSumIncomeSourceDesc = 'LEADS_SUM_INCOME_SOURCE_DESC',
  LeadsSumIncomeTimeAsc = 'LEADS_SUM_INCOME_TIME_ASC',
  LeadsSumIncomeTimeDesc = 'LEADS_SUM_INCOME_TIME_DESC',
  LeadsSumPreferredContactMethodAsc = 'LEADS_SUM_PREFERRED_CONTACT_METHOD_ASC',
  LeadsSumPreferredContactMethodDesc = 'LEADS_SUM_PREFERRED_CONTACT_METHOD_DESC',
  LeadsSumCompanyNameAsc = 'LEADS_SUM_COMPANY_NAME_ASC',
  LeadsSumCompanyNameDesc = 'LEADS_SUM_COMPANY_NAME_DESC',
  LeadsSumCreditConcernAsc = 'LEADS_SUM_CREDIT_CONCERN_ASC',
  LeadsSumCreditConcernDesc = 'LEADS_SUM_CREDIT_CONCERN_DESC',
  LeadsSumResidenceTimeAsc = 'LEADS_SUM_RESIDENCE_TIME_ASC',
  LeadsSumResidenceTimeDesc = 'LEADS_SUM_RESIDENCE_TIME_DESC',
  LeadsSumRentOrOwnAsc = 'LEADS_SUM_RENT_OR_OWN_ASC',
  LeadsSumRentOrOwnDesc = 'LEADS_SUM_RENT_OR_OWN_DESC',
  LeadsSumEmploymentStatusAsc = 'LEADS_SUM_EMPLOYMENT_STATUS_ASC',
  LeadsSumEmploymentStatusDesc = 'LEADS_SUM_EMPLOYMENT_STATUS_DESC',
  LeadsSumIncomeAmountAsc = 'LEADS_SUM_INCOME_AMOUNT_ASC',
  LeadsSumIncomeAmountDesc = 'LEADS_SUM_INCOME_AMOUNT_DESC',
  LeadsSumMonthlyHomePaymentAsc = 'LEADS_SUM_MONTHLY_HOME_PAYMENT_ASC',
  LeadsSumMonthlyHomePaymentDesc = 'LEADS_SUM_MONTHLY_HOME_PAYMENT_DESC',
  LeadsSumSmsConsentAsc = 'LEADS_SUM_SMS_CONSENT_ASC',
  LeadsSumSmsConsentDesc = 'LEADS_SUM_SMS_CONSENT_DESC',
  LeadsSumIncomeTimeYearsAsc = 'LEADS_SUM_INCOME_TIME_YEARS_ASC',
  LeadsSumIncomeTimeYearsDesc = 'LEADS_SUM_INCOME_TIME_YEARS_DESC',
  LeadsSumIncomeTimeMonthsAsc = 'LEADS_SUM_INCOME_TIME_MONTHS_ASC',
  LeadsSumIncomeTimeMonthsDesc = 'LEADS_SUM_INCOME_TIME_MONTHS_DESC',
  LeadsSumResidenceTimeYearsAsc = 'LEADS_SUM_RESIDENCE_TIME_YEARS_ASC',
  LeadsSumResidenceTimeYearsDesc = 'LEADS_SUM_RESIDENCE_TIME_YEARS_DESC',
  LeadsSumResidenceTimeMonthsAsc = 'LEADS_SUM_RESIDENCE_TIME_MONTHS_ASC',
  LeadsSumResidenceTimeMonthsDesc = 'LEADS_SUM_RESIDENCE_TIME_MONTHS_DESC',
  LeadsDistinctCountIdAsc = 'LEADS_DISTINCT_COUNT_ID_ASC',
  LeadsDistinctCountIdDesc = 'LEADS_DISTINCT_COUNT_ID_DESC',
  LeadsDistinctCountCompanyIdAsc = 'LEADS_DISTINCT_COUNT_COMPANY_ID_ASC',
  LeadsDistinctCountCompanyIdDesc = 'LEADS_DISTINCT_COUNT_COMPANY_ID_DESC',
  LeadsDistinctCountCreatedAtAsc = 'LEADS_DISTINCT_COUNT_CREATED_AT_ASC',
  LeadsDistinctCountCreatedAtDesc = 'LEADS_DISTINCT_COUNT_CREATED_AT_DESC',
  LeadsDistinctCountFirstNameAsc = 'LEADS_DISTINCT_COUNT_FIRST_NAME_ASC',
  LeadsDistinctCountFirstNameDesc = 'LEADS_DISTINCT_COUNT_FIRST_NAME_DESC',
  LeadsDistinctCountLastNameAsc = 'LEADS_DISTINCT_COUNT_LAST_NAME_ASC',
  LeadsDistinctCountLastNameDesc = 'LEADS_DISTINCT_COUNT_LAST_NAME_DESC',
  LeadsDistinctCountDateOfBirthAsc = 'LEADS_DISTINCT_COUNT_DATE_OF_BIRTH_ASC',
  LeadsDistinctCountDateOfBirthDesc = 'LEADS_DISTINCT_COUNT_DATE_OF_BIRTH_DESC',
  LeadsDistinctCountStreetAsc = 'LEADS_DISTINCT_COUNT_STREET_ASC',
  LeadsDistinctCountStreetDesc = 'LEADS_DISTINCT_COUNT_STREET_DESC',
  LeadsDistinctCountStreetNumberAsc = 'LEADS_DISTINCT_COUNT_STREET_NUMBER_ASC',
  LeadsDistinctCountStreetNumberDesc = 'LEADS_DISTINCT_COUNT_STREET_NUMBER_DESC',
  LeadsDistinctCountUnitAsc = 'LEADS_DISTINCT_COUNT_UNIT_ASC',
  LeadsDistinctCountUnitDesc = 'LEADS_DISTINCT_COUNT_UNIT_DESC',
  LeadsDistinctCountCityAsc = 'LEADS_DISTINCT_COUNT_CITY_ASC',
  LeadsDistinctCountCityDesc = 'LEADS_DISTINCT_COUNT_CITY_DESC',
  LeadsDistinctCountCountryAsc = 'LEADS_DISTINCT_COUNT_COUNTRY_ASC',
  LeadsDistinctCountCountryDesc = 'LEADS_DISTINCT_COUNT_COUNTRY_DESC',
  LeadsDistinctCountPostalCodeAsc = 'LEADS_DISTINCT_COUNT_POSTAL_CODE_ASC',
  LeadsDistinctCountPostalCodeDesc = 'LEADS_DISTINCT_COUNT_POSTAL_CODE_DESC',
  LeadsDistinctCountProvinceCodeAsc = 'LEADS_DISTINCT_COUNT_PROVINCE_CODE_ASC',
  LeadsDistinctCountProvinceCodeDesc = 'LEADS_DISTINCT_COUNT_PROVINCE_CODE_DESC',
  LeadsDistinctCountEmailAddressAsc = 'LEADS_DISTINCT_COUNT_EMAIL_ADDRESS_ASC',
  LeadsDistinctCountEmailAddressDesc = 'LEADS_DISTINCT_COUNT_EMAIL_ADDRESS_DESC',
  LeadsDistinctCountPhoneNumberAsc = 'LEADS_DISTINCT_COUNT_PHONE_NUMBER_ASC',
  LeadsDistinctCountPhoneNumberDesc = 'LEADS_DISTINCT_COUNT_PHONE_NUMBER_DESC',
  LeadsDistinctCountSourceUrlAsc = 'LEADS_DISTINCT_COUNT_SOURCE_URL_ASC',
  LeadsDistinctCountSourceUrlDesc = 'LEADS_DISTINCT_COUNT_SOURCE_URL_DESC',
  LeadsDistinctCountTransactionKeyAsc = 'LEADS_DISTINCT_COUNT_TRANSACTION_KEY_ASC',
  LeadsDistinctCountTransactionKeyDesc = 'LEADS_DISTINCT_COUNT_TRANSACTION_KEY_DESC',
  LeadsDistinctCountLeadspediaIdAsc = 'LEADS_DISTINCT_COUNT_LEADSPEDIA_ID_ASC',
  LeadsDistinctCountLeadspediaIdDesc = 'LEADS_DISTINCT_COUNT_LEADSPEDIA_ID_DESC',
  LeadsDistinctCountUpdatedAtAsc = 'LEADS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LeadsDistinctCountUpdatedAtDesc = 'LEADS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LeadsDistinctCountSearchTextAsc = 'LEADS_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  LeadsDistinctCountSearchTextDesc = 'LEADS_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  LeadsDistinctCountCommentAsc = 'LEADS_DISTINCT_COUNT_COMMENT_ASC',
  LeadsDistinctCountCommentDesc = 'LEADS_DISTINCT_COUNT_COMMENT_DESC',
  LeadsDistinctCountStateAsc = 'LEADS_DISTINCT_COUNT_STATE_ASC',
  LeadsDistinctCountStateDesc = 'LEADS_DISTINCT_COUNT_STATE_DESC',
  LeadsDistinctCountReadAsc = 'LEADS_DISTINCT_COUNT_READ_ASC',
  LeadsDistinctCountReadDesc = 'LEADS_DISTINCT_COUNT_READ_DESC',
  LeadsDistinctCountExternalIdsAsc = 'LEADS_DISTINCT_COUNT_EXTERNAL_IDS_ASC',
  LeadsDistinctCountExternalIdsDesc = 'LEADS_DISTINCT_COUNT_EXTERNAL_IDS_DESC',
  LeadsDistinctCountMetaAsc = 'LEADS_DISTINCT_COUNT_META_ASC',
  LeadsDistinctCountMetaDesc = 'LEADS_DISTINCT_COUNT_META_DESC',
  LeadsDistinctCountAdditionalInformationAsc = 'LEADS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_ASC',
  LeadsDistinctCountAdditionalInformationDesc = 'LEADS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_DESC',
  LeadsDistinctCountPhoneVerifiedAtAsc = 'LEADS_DISTINCT_COUNT_PHONE_VERIFIED_AT_ASC',
  LeadsDistinctCountPhoneVerifiedAtDesc = 'LEADS_DISTINCT_COUNT_PHONE_VERIFIED_AT_DESC',
  LeadsDistinctCountIpAddressAsc = 'LEADS_DISTINCT_COUNT_IP_ADDRESS_ASC',
  LeadsDistinctCountIpAddressDesc = 'LEADS_DISTINCT_COUNT_IP_ADDRESS_DESC',
  LeadsDistinctCountClientIdAsc = 'LEADS_DISTINCT_COUNT_CLIENT_ID_ASC',
  LeadsDistinctCountClientIdDesc = 'LEADS_DISTINCT_COUNT_CLIENT_ID_DESC',
  LeadsDistinctCountDeletedAsc = 'LEADS_DISTINCT_COUNT_DELETED_ASC',
  LeadsDistinctCountDeletedDesc = 'LEADS_DISTINCT_COUNT_DELETED_DESC',
  LeadsDistinctCountPriorityAsc = 'LEADS_DISTINCT_COUNT_PRIORITY_ASC',
  LeadsDistinctCountPriorityDesc = 'LEADS_DISTINCT_COUNT_PRIORITY_DESC',
  LeadsDistinctCountAssigneeAsc = 'LEADS_DISTINCT_COUNT_ASSIGNEE_ASC',
  LeadsDistinctCountAssigneeDesc = 'LEADS_DISTINCT_COUNT_ASSIGNEE_DESC',
  LeadsDistinctCountShortIdAsc = 'LEADS_DISTINCT_COUNT_SHORT_ID_ASC',
  LeadsDistinctCountShortIdDesc = 'LEADS_DISTINCT_COUNT_SHORT_ID_DESC',
  LeadsDistinctCountCompanyChannelIdAsc = 'LEADS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  LeadsDistinctCountCompanyChannelIdDesc = 'LEADS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  LeadsDistinctCountNeighborhoodViewIdAsc = 'LEADS_DISTINCT_COUNT_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsDistinctCountNeighborhoodViewIdDesc = 'LEADS_DISTINCT_COUNT_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsDistinctCountMaritalStatusAsc = 'LEADS_DISTINCT_COUNT_MARITAL_STATUS_ASC',
  LeadsDistinctCountMaritalStatusDesc = 'LEADS_DISTINCT_COUNT_MARITAL_STATUS_DESC',
  LeadsDistinctCountDesiredVehicleAsc = 'LEADS_DISTINCT_COUNT_DESIRED_VEHICLE_ASC',
  LeadsDistinctCountDesiredVehicleDesc = 'LEADS_DISTINCT_COUNT_DESIRED_VEHICLE_DESC',
  LeadsDistinctCountJobTitleAsc = 'LEADS_DISTINCT_COUNT_JOB_TITLE_ASC',
  LeadsDistinctCountJobTitleDesc = 'LEADS_DISTINCT_COUNT_JOB_TITLE_DESC',
  LeadsDistinctCountIncomePeriodAsc = 'LEADS_DISTINCT_COUNT_INCOME_PERIOD_ASC',
  LeadsDistinctCountIncomePeriodDesc = 'LEADS_DISTINCT_COUNT_INCOME_PERIOD_DESC',
  LeadsDistinctCountSalaryHourlyAsc = 'LEADS_DISTINCT_COUNT_SALARY_HOURLY_ASC',
  LeadsDistinctCountSalaryHourlyDesc = 'LEADS_DISTINCT_COUNT_SALARY_HOURLY_DESC',
  LeadsDistinctCountIncomeSourceAsc = 'LEADS_DISTINCT_COUNT_INCOME_SOURCE_ASC',
  LeadsDistinctCountIncomeSourceDesc = 'LEADS_DISTINCT_COUNT_INCOME_SOURCE_DESC',
  LeadsDistinctCountIncomeTimeAsc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_ASC',
  LeadsDistinctCountIncomeTimeDesc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_DESC',
  LeadsDistinctCountPreferredContactMethodAsc = 'LEADS_DISTINCT_COUNT_PREFERRED_CONTACT_METHOD_ASC',
  LeadsDistinctCountPreferredContactMethodDesc = 'LEADS_DISTINCT_COUNT_PREFERRED_CONTACT_METHOD_DESC',
  LeadsDistinctCountCompanyNameAsc = 'LEADS_DISTINCT_COUNT_COMPANY_NAME_ASC',
  LeadsDistinctCountCompanyNameDesc = 'LEADS_DISTINCT_COUNT_COMPANY_NAME_DESC',
  LeadsDistinctCountCreditConcernAsc = 'LEADS_DISTINCT_COUNT_CREDIT_CONCERN_ASC',
  LeadsDistinctCountCreditConcernDesc = 'LEADS_DISTINCT_COUNT_CREDIT_CONCERN_DESC',
  LeadsDistinctCountResidenceTimeAsc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_ASC',
  LeadsDistinctCountResidenceTimeDesc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_DESC',
  LeadsDistinctCountRentOrOwnAsc = 'LEADS_DISTINCT_COUNT_RENT_OR_OWN_ASC',
  LeadsDistinctCountRentOrOwnDesc = 'LEADS_DISTINCT_COUNT_RENT_OR_OWN_DESC',
  LeadsDistinctCountEmploymentStatusAsc = 'LEADS_DISTINCT_COUNT_EMPLOYMENT_STATUS_ASC',
  LeadsDistinctCountEmploymentStatusDesc = 'LEADS_DISTINCT_COUNT_EMPLOYMENT_STATUS_DESC',
  LeadsDistinctCountIncomeAmountAsc = 'LEADS_DISTINCT_COUNT_INCOME_AMOUNT_ASC',
  LeadsDistinctCountIncomeAmountDesc = 'LEADS_DISTINCT_COUNT_INCOME_AMOUNT_DESC',
  LeadsDistinctCountMonthlyHomePaymentAsc = 'LEADS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_ASC',
  LeadsDistinctCountMonthlyHomePaymentDesc = 'LEADS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_DESC',
  LeadsDistinctCountSmsConsentAsc = 'LEADS_DISTINCT_COUNT_SMS_CONSENT_ASC',
  LeadsDistinctCountSmsConsentDesc = 'LEADS_DISTINCT_COUNT_SMS_CONSENT_DESC',
  LeadsDistinctCountIncomeTimeYearsAsc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_YEARS_ASC',
  LeadsDistinctCountIncomeTimeYearsDesc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_YEARS_DESC',
  LeadsDistinctCountIncomeTimeMonthsAsc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_MONTHS_ASC',
  LeadsDistinctCountIncomeTimeMonthsDesc = 'LEADS_DISTINCT_COUNT_INCOME_TIME_MONTHS_DESC',
  LeadsDistinctCountResidenceTimeYearsAsc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_YEARS_ASC',
  LeadsDistinctCountResidenceTimeYearsDesc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_YEARS_DESC',
  LeadsDistinctCountResidenceTimeMonthsAsc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_MONTHS_ASC',
  LeadsDistinctCountResidenceTimeMonthsDesc = 'LEADS_DISTINCT_COUNT_RESIDENCE_TIME_MONTHS_DESC',
  LeadsMinIdAsc = 'LEADS_MIN_ID_ASC',
  LeadsMinIdDesc = 'LEADS_MIN_ID_DESC',
  LeadsMinCompanyIdAsc = 'LEADS_MIN_COMPANY_ID_ASC',
  LeadsMinCompanyIdDesc = 'LEADS_MIN_COMPANY_ID_DESC',
  LeadsMinCreatedAtAsc = 'LEADS_MIN_CREATED_AT_ASC',
  LeadsMinCreatedAtDesc = 'LEADS_MIN_CREATED_AT_DESC',
  LeadsMinFirstNameAsc = 'LEADS_MIN_FIRST_NAME_ASC',
  LeadsMinFirstNameDesc = 'LEADS_MIN_FIRST_NAME_DESC',
  LeadsMinLastNameAsc = 'LEADS_MIN_LAST_NAME_ASC',
  LeadsMinLastNameDesc = 'LEADS_MIN_LAST_NAME_DESC',
  LeadsMinDateOfBirthAsc = 'LEADS_MIN_DATE_OF_BIRTH_ASC',
  LeadsMinDateOfBirthDesc = 'LEADS_MIN_DATE_OF_BIRTH_DESC',
  LeadsMinStreetAsc = 'LEADS_MIN_STREET_ASC',
  LeadsMinStreetDesc = 'LEADS_MIN_STREET_DESC',
  LeadsMinStreetNumberAsc = 'LEADS_MIN_STREET_NUMBER_ASC',
  LeadsMinStreetNumberDesc = 'LEADS_MIN_STREET_NUMBER_DESC',
  LeadsMinUnitAsc = 'LEADS_MIN_UNIT_ASC',
  LeadsMinUnitDesc = 'LEADS_MIN_UNIT_DESC',
  LeadsMinCityAsc = 'LEADS_MIN_CITY_ASC',
  LeadsMinCityDesc = 'LEADS_MIN_CITY_DESC',
  LeadsMinCountryAsc = 'LEADS_MIN_COUNTRY_ASC',
  LeadsMinCountryDesc = 'LEADS_MIN_COUNTRY_DESC',
  LeadsMinPostalCodeAsc = 'LEADS_MIN_POSTAL_CODE_ASC',
  LeadsMinPostalCodeDesc = 'LEADS_MIN_POSTAL_CODE_DESC',
  LeadsMinProvinceCodeAsc = 'LEADS_MIN_PROVINCE_CODE_ASC',
  LeadsMinProvinceCodeDesc = 'LEADS_MIN_PROVINCE_CODE_DESC',
  LeadsMinEmailAddressAsc = 'LEADS_MIN_EMAIL_ADDRESS_ASC',
  LeadsMinEmailAddressDesc = 'LEADS_MIN_EMAIL_ADDRESS_DESC',
  LeadsMinPhoneNumberAsc = 'LEADS_MIN_PHONE_NUMBER_ASC',
  LeadsMinPhoneNumberDesc = 'LEADS_MIN_PHONE_NUMBER_DESC',
  LeadsMinSourceUrlAsc = 'LEADS_MIN_SOURCE_URL_ASC',
  LeadsMinSourceUrlDesc = 'LEADS_MIN_SOURCE_URL_DESC',
  LeadsMinTransactionKeyAsc = 'LEADS_MIN_TRANSACTION_KEY_ASC',
  LeadsMinTransactionKeyDesc = 'LEADS_MIN_TRANSACTION_KEY_DESC',
  LeadsMinLeadspediaIdAsc = 'LEADS_MIN_LEADSPEDIA_ID_ASC',
  LeadsMinLeadspediaIdDesc = 'LEADS_MIN_LEADSPEDIA_ID_DESC',
  LeadsMinUpdatedAtAsc = 'LEADS_MIN_UPDATED_AT_ASC',
  LeadsMinUpdatedAtDesc = 'LEADS_MIN_UPDATED_AT_DESC',
  LeadsMinSearchTextAsc = 'LEADS_MIN_SEARCH_TEXT_ASC',
  LeadsMinSearchTextDesc = 'LEADS_MIN_SEARCH_TEXT_DESC',
  LeadsMinCommentAsc = 'LEADS_MIN_COMMENT_ASC',
  LeadsMinCommentDesc = 'LEADS_MIN_COMMENT_DESC',
  LeadsMinStateAsc = 'LEADS_MIN_STATE_ASC',
  LeadsMinStateDesc = 'LEADS_MIN_STATE_DESC',
  LeadsMinReadAsc = 'LEADS_MIN_READ_ASC',
  LeadsMinReadDesc = 'LEADS_MIN_READ_DESC',
  LeadsMinExternalIdsAsc = 'LEADS_MIN_EXTERNAL_IDS_ASC',
  LeadsMinExternalIdsDesc = 'LEADS_MIN_EXTERNAL_IDS_DESC',
  LeadsMinMetaAsc = 'LEADS_MIN_META_ASC',
  LeadsMinMetaDesc = 'LEADS_MIN_META_DESC',
  LeadsMinAdditionalInformationAsc = 'LEADS_MIN_ADDITIONAL_INFORMATION_ASC',
  LeadsMinAdditionalInformationDesc = 'LEADS_MIN_ADDITIONAL_INFORMATION_DESC',
  LeadsMinPhoneVerifiedAtAsc = 'LEADS_MIN_PHONE_VERIFIED_AT_ASC',
  LeadsMinPhoneVerifiedAtDesc = 'LEADS_MIN_PHONE_VERIFIED_AT_DESC',
  LeadsMinIpAddressAsc = 'LEADS_MIN_IP_ADDRESS_ASC',
  LeadsMinIpAddressDesc = 'LEADS_MIN_IP_ADDRESS_DESC',
  LeadsMinClientIdAsc = 'LEADS_MIN_CLIENT_ID_ASC',
  LeadsMinClientIdDesc = 'LEADS_MIN_CLIENT_ID_DESC',
  LeadsMinDeletedAsc = 'LEADS_MIN_DELETED_ASC',
  LeadsMinDeletedDesc = 'LEADS_MIN_DELETED_DESC',
  LeadsMinPriorityAsc = 'LEADS_MIN_PRIORITY_ASC',
  LeadsMinPriorityDesc = 'LEADS_MIN_PRIORITY_DESC',
  LeadsMinAssigneeAsc = 'LEADS_MIN_ASSIGNEE_ASC',
  LeadsMinAssigneeDesc = 'LEADS_MIN_ASSIGNEE_DESC',
  LeadsMinShortIdAsc = 'LEADS_MIN_SHORT_ID_ASC',
  LeadsMinShortIdDesc = 'LEADS_MIN_SHORT_ID_DESC',
  LeadsMinCompanyChannelIdAsc = 'LEADS_MIN_COMPANY_CHANNEL_ID_ASC',
  LeadsMinCompanyChannelIdDesc = 'LEADS_MIN_COMPANY_CHANNEL_ID_DESC',
  LeadsMinNeighborhoodViewIdAsc = 'LEADS_MIN_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsMinNeighborhoodViewIdDesc = 'LEADS_MIN_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsMinMaritalStatusAsc = 'LEADS_MIN_MARITAL_STATUS_ASC',
  LeadsMinMaritalStatusDesc = 'LEADS_MIN_MARITAL_STATUS_DESC',
  LeadsMinDesiredVehicleAsc = 'LEADS_MIN_DESIRED_VEHICLE_ASC',
  LeadsMinDesiredVehicleDesc = 'LEADS_MIN_DESIRED_VEHICLE_DESC',
  LeadsMinJobTitleAsc = 'LEADS_MIN_JOB_TITLE_ASC',
  LeadsMinJobTitleDesc = 'LEADS_MIN_JOB_TITLE_DESC',
  LeadsMinIncomePeriodAsc = 'LEADS_MIN_INCOME_PERIOD_ASC',
  LeadsMinIncomePeriodDesc = 'LEADS_MIN_INCOME_PERIOD_DESC',
  LeadsMinSalaryHourlyAsc = 'LEADS_MIN_SALARY_HOURLY_ASC',
  LeadsMinSalaryHourlyDesc = 'LEADS_MIN_SALARY_HOURLY_DESC',
  LeadsMinIncomeSourceAsc = 'LEADS_MIN_INCOME_SOURCE_ASC',
  LeadsMinIncomeSourceDesc = 'LEADS_MIN_INCOME_SOURCE_DESC',
  LeadsMinIncomeTimeAsc = 'LEADS_MIN_INCOME_TIME_ASC',
  LeadsMinIncomeTimeDesc = 'LEADS_MIN_INCOME_TIME_DESC',
  LeadsMinPreferredContactMethodAsc = 'LEADS_MIN_PREFERRED_CONTACT_METHOD_ASC',
  LeadsMinPreferredContactMethodDesc = 'LEADS_MIN_PREFERRED_CONTACT_METHOD_DESC',
  LeadsMinCompanyNameAsc = 'LEADS_MIN_COMPANY_NAME_ASC',
  LeadsMinCompanyNameDesc = 'LEADS_MIN_COMPANY_NAME_DESC',
  LeadsMinCreditConcernAsc = 'LEADS_MIN_CREDIT_CONCERN_ASC',
  LeadsMinCreditConcernDesc = 'LEADS_MIN_CREDIT_CONCERN_DESC',
  LeadsMinResidenceTimeAsc = 'LEADS_MIN_RESIDENCE_TIME_ASC',
  LeadsMinResidenceTimeDesc = 'LEADS_MIN_RESIDENCE_TIME_DESC',
  LeadsMinRentOrOwnAsc = 'LEADS_MIN_RENT_OR_OWN_ASC',
  LeadsMinRentOrOwnDesc = 'LEADS_MIN_RENT_OR_OWN_DESC',
  LeadsMinEmploymentStatusAsc = 'LEADS_MIN_EMPLOYMENT_STATUS_ASC',
  LeadsMinEmploymentStatusDesc = 'LEADS_MIN_EMPLOYMENT_STATUS_DESC',
  LeadsMinIncomeAmountAsc = 'LEADS_MIN_INCOME_AMOUNT_ASC',
  LeadsMinIncomeAmountDesc = 'LEADS_MIN_INCOME_AMOUNT_DESC',
  LeadsMinMonthlyHomePaymentAsc = 'LEADS_MIN_MONTHLY_HOME_PAYMENT_ASC',
  LeadsMinMonthlyHomePaymentDesc = 'LEADS_MIN_MONTHLY_HOME_PAYMENT_DESC',
  LeadsMinSmsConsentAsc = 'LEADS_MIN_SMS_CONSENT_ASC',
  LeadsMinSmsConsentDesc = 'LEADS_MIN_SMS_CONSENT_DESC',
  LeadsMinIncomeTimeYearsAsc = 'LEADS_MIN_INCOME_TIME_YEARS_ASC',
  LeadsMinIncomeTimeYearsDesc = 'LEADS_MIN_INCOME_TIME_YEARS_DESC',
  LeadsMinIncomeTimeMonthsAsc = 'LEADS_MIN_INCOME_TIME_MONTHS_ASC',
  LeadsMinIncomeTimeMonthsDesc = 'LEADS_MIN_INCOME_TIME_MONTHS_DESC',
  LeadsMinResidenceTimeYearsAsc = 'LEADS_MIN_RESIDENCE_TIME_YEARS_ASC',
  LeadsMinResidenceTimeYearsDesc = 'LEADS_MIN_RESIDENCE_TIME_YEARS_DESC',
  LeadsMinResidenceTimeMonthsAsc = 'LEADS_MIN_RESIDENCE_TIME_MONTHS_ASC',
  LeadsMinResidenceTimeMonthsDesc = 'LEADS_MIN_RESIDENCE_TIME_MONTHS_DESC',
  LeadsMaxIdAsc = 'LEADS_MAX_ID_ASC',
  LeadsMaxIdDesc = 'LEADS_MAX_ID_DESC',
  LeadsMaxCompanyIdAsc = 'LEADS_MAX_COMPANY_ID_ASC',
  LeadsMaxCompanyIdDesc = 'LEADS_MAX_COMPANY_ID_DESC',
  LeadsMaxCreatedAtAsc = 'LEADS_MAX_CREATED_AT_ASC',
  LeadsMaxCreatedAtDesc = 'LEADS_MAX_CREATED_AT_DESC',
  LeadsMaxFirstNameAsc = 'LEADS_MAX_FIRST_NAME_ASC',
  LeadsMaxFirstNameDesc = 'LEADS_MAX_FIRST_NAME_DESC',
  LeadsMaxLastNameAsc = 'LEADS_MAX_LAST_NAME_ASC',
  LeadsMaxLastNameDesc = 'LEADS_MAX_LAST_NAME_DESC',
  LeadsMaxDateOfBirthAsc = 'LEADS_MAX_DATE_OF_BIRTH_ASC',
  LeadsMaxDateOfBirthDesc = 'LEADS_MAX_DATE_OF_BIRTH_DESC',
  LeadsMaxStreetAsc = 'LEADS_MAX_STREET_ASC',
  LeadsMaxStreetDesc = 'LEADS_MAX_STREET_DESC',
  LeadsMaxStreetNumberAsc = 'LEADS_MAX_STREET_NUMBER_ASC',
  LeadsMaxStreetNumberDesc = 'LEADS_MAX_STREET_NUMBER_DESC',
  LeadsMaxUnitAsc = 'LEADS_MAX_UNIT_ASC',
  LeadsMaxUnitDesc = 'LEADS_MAX_UNIT_DESC',
  LeadsMaxCityAsc = 'LEADS_MAX_CITY_ASC',
  LeadsMaxCityDesc = 'LEADS_MAX_CITY_DESC',
  LeadsMaxCountryAsc = 'LEADS_MAX_COUNTRY_ASC',
  LeadsMaxCountryDesc = 'LEADS_MAX_COUNTRY_DESC',
  LeadsMaxPostalCodeAsc = 'LEADS_MAX_POSTAL_CODE_ASC',
  LeadsMaxPostalCodeDesc = 'LEADS_MAX_POSTAL_CODE_DESC',
  LeadsMaxProvinceCodeAsc = 'LEADS_MAX_PROVINCE_CODE_ASC',
  LeadsMaxProvinceCodeDesc = 'LEADS_MAX_PROVINCE_CODE_DESC',
  LeadsMaxEmailAddressAsc = 'LEADS_MAX_EMAIL_ADDRESS_ASC',
  LeadsMaxEmailAddressDesc = 'LEADS_MAX_EMAIL_ADDRESS_DESC',
  LeadsMaxPhoneNumberAsc = 'LEADS_MAX_PHONE_NUMBER_ASC',
  LeadsMaxPhoneNumberDesc = 'LEADS_MAX_PHONE_NUMBER_DESC',
  LeadsMaxSourceUrlAsc = 'LEADS_MAX_SOURCE_URL_ASC',
  LeadsMaxSourceUrlDesc = 'LEADS_MAX_SOURCE_URL_DESC',
  LeadsMaxTransactionKeyAsc = 'LEADS_MAX_TRANSACTION_KEY_ASC',
  LeadsMaxTransactionKeyDesc = 'LEADS_MAX_TRANSACTION_KEY_DESC',
  LeadsMaxLeadspediaIdAsc = 'LEADS_MAX_LEADSPEDIA_ID_ASC',
  LeadsMaxLeadspediaIdDesc = 'LEADS_MAX_LEADSPEDIA_ID_DESC',
  LeadsMaxUpdatedAtAsc = 'LEADS_MAX_UPDATED_AT_ASC',
  LeadsMaxUpdatedAtDesc = 'LEADS_MAX_UPDATED_AT_DESC',
  LeadsMaxSearchTextAsc = 'LEADS_MAX_SEARCH_TEXT_ASC',
  LeadsMaxSearchTextDesc = 'LEADS_MAX_SEARCH_TEXT_DESC',
  LeadsMaxCommentAsc = 'LEADS_MAX_COMMENT_ASC',
  LeadsMaxCommentDesc = 'LEADS_MAX_COMMENT_DESC',
  LeadsMaxStateAsc = 'LEADS_MAX_STATE_ASC',
  LeadsMaxStateDesc = 'LEADS_MAX_STATE_DESC',
  LeadsMaxReadAsc = 'LEADS_MAX_READ_ASC',
  LeadsMaxReadDesc = 'LEADS_MAX_READ_DESC',
  LeadsMaxExternalIdsAsc = 'LEADS_MAX_EXTERNAL_IDS_ASC',
  LeadsMaxExternalIdsDesc = 'LEADS_MAX_EXTERNAL_IDS_DESC',
  LeadsMaxMetaAsc = 'LEADS_MAX_META_ASC',
  LeadsMaxMetaDesc = 'LEADS_MAX_META_DESC',
  LeadsMaxAdditionalInformationAsc = 'LEADS_MAX_ADDITIONAL_INFORMATION_ASC',
  LeadsMaxAdditionalInformationDesc = 'LEADS_MAX_ADDITIONAL_INFORMATION_DESC',
  LeadsMaxPhoneVerifiedAtAsc = 'LEADS_MAX_PHONE_VERIFIED_AT_ASC',
  LeadsMaxPhoneVerifiedAtDesc = 'LEADS_MAX_PHONE_VERIFIED_AT_DESC',
  LeadsMaxIpAddressAsc = 'LEADS_MAX_IP_ADDRESS_ASC',
  LeadsMaxIpAddressDesc = 'LEADS_MAX_IP_ADDRESS_DESC',
  LeadsMaxClientIdAsc = 'LEADS_MAX_CLIENT_ID_ASC',
  LeadsMaxClientIdDesc = 'LEADS_MAX_CLIENT_ID_DESC',
  LeadsMaxDeletedAsc = 'LEADS_MAX_DELETED_ASC',
  LeadsMaxDeletedDesc = 'LEADS_MAX_DELETED_DESC',
  LeadsMaxPriorityAsc = 'LEADS_MAX_PRIORITY_ASC',
  LeadsMaxPriorityDesc = 'LEADS_MAX_PRIORITY_DESC',
  LeadsMaxAssigneeAsc = 'LEADS_MAX_ASSIGNEE_ASC',
  LeadsMaxAssigneeDesc = 'LEADS_MAX_ASSIGNEE_DESC',
  LeadsMaxShortIdAsc = 'LEADS_MAX_SHORT_ID_ASC',
  LeadsMaxShortIdDesc = 'LEADS_MAX_SHORT_ID_DESC',
  LeadsMaxCompanyChannelIdAsc = 'LEADS_MAX_COMPANY_CHANNEL_ID_ASC',
  LeadsMaxCompanyChannelIdDesc = 'LEADS_MAX_COMPANY_CHANNEL_ID_DESC',
  LeadsMaxNeighborhoodViewIdAsc = 'LEADS_MAX_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsMaxNeighborhoodViewIdDesc = 'LEADS_MAX_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsMaxMaritalStatusAsc = 'LEADS_MAX_MARITAL_STATUS_ASC',
  LeadsMaxMaritalStatusDesc = 'LEADS_MAX_MARITAL_STATUS_DESC',
  LeadsMaxDesiredVehicleAsc = 'LEADS_MAX_DESIRED_VEHICLE_ASC',
  LeadsMaxDesiredVehicleDesc = 'LEADS_MAX_DESIRED_VEHICLE_DESC',
  LeadsMaxJobTitleAsc = 'LEADS_MAX_JOB_TITLE_ASC',
  LeadsMaxJobTitleDesc = 'LEADS_MAX_JOB_TITLE_DESC',
  LeadsMaxIncomePeriodAsc = 'LEADS_MAX_INCOME_PERIOD_ASC',
  LeadsMaxIncomePeriodDesc = 'LEADS_MAX_INCOME_PERIOD_DESC',
  LeadsMaxSalaryHourlyAsc = 'LEADS_MAX_SALARY_HOURLY_ASC',
  LeadsMaxSalaryHourlyDesc = 'LEADS_MAX_SALARY_HOURLY_DESC',
  LeadsMaxIncomeSourceAsc = 'LEADS_MAX_INCOME_SOURCE_ASC',
  LeadsMaxIncomeSourceDesc = 'LEADS_MAX_INCOME_SOURCE_DESC',
  LeadsMaxIncomeTimeAsc = 'LEADS_MAX_INCOME_TIME_ASC',
  LeadsMaxIncomeTimeDesc = 'LEADS_MAX_INCOME_TIME_DESC',
  LeadsMaxPreferredContactMethodAsc = 'LEADS_MAX_PREFERRED_CONTACT_METHOD_ASC',
  LeadsMaxPreferredContactMethodDesc = 'LEADS_MAX_PREFERRED_CONTACT_METHOD_DESC',
  LeadsMaxCompanyNameAsc = 'LEADS_MAX_COMPANY_NAME_ASC',
  LeadsMaxCompanyNameDesc = 'LEADS_MAX_COMPANY_NAME_DESC',
  LeadsMaxCreditConcernAsc = 'LEADS_MAX_CREDIT_CONCERN_ASC',
  LeadsMaxCreditConcernDesc = 'LEADS_MAX_CREDIT_CONCERN_DESC',
  LeadsMaxResidenceTimeAsc = 'LEADS_MAX_RESIDENCE_TIME_ASC',
  LeadsMaxResidenceTimeDesc = 'LEADS_MAX_RESIDENCE_TIME_DESC',
  LeadsMaxRentOrOwnAsc = 'LEADS_MAX_RENT_OR_OWN_ASC',
  LeadsMaxRentOrOwnDesc = 'LEADS_MAX_RENT_OR_OWN_DESC',
  LeadsMaxEmploymentStatusAsc = 'LEADS_MAX_EMPLOYMENT_STATUS_ASC',
  LeadsMaxEmploymentStatusDesc = 'LEADS_MAX_EMPLOYMENT_STATUS_DESC',
  LeadsMaxIncomeAmountAsc = 'LEADS_MAX_INCOME_AMOUNT_ASC',
  LeadsMaxIncomeAmountDesc = 'LEADS_MAX_INCOME_AMOUNT_DESC',
  LeadsMaxMonthlyHomePaymentAsc = 'LEADS_MAX_MONTHLY_HOME_PAYMENT_ASC',
  LeadsMaxMonthlyHomePaymentDesc = 'LEADS_MAX_MONTHLY_HOME_PAYMENT_DESC',
  LeadsMaxSmsConsentAsc = 'LEADS_MAX_SMS_CONSENT_ASC',
  LeadsMaxSmsConsentDesc = 'LEADS_MAX_SMS_CONSENT_DESC',
  LeadsMaxIncomeTimeYearsAsc = 'LEADS_MAX_INCOME_TIME_YEARS_ASC',
  LeadsMaxIncomeTimeYearsDesc = 'LEADS_MAX_INCOME_TIME_YEARS_DESC',
  LeadsMaxIncomeTimeMonthsAsc = 'LEADS_MAX_INCOME_TIME_MONTHS_ASC',
  LeadsMaxIncomeTimeMonthsDesc = 'LEADS_MAX_INCOME_TIME_MONTHS_DESC',
  LeadsMaxResidenceTimeYearsAsc = 'LEADS_MAX_RESIDENCE_TIME_YEARS_ASC',
  LeadsMaxResidenceTimeYearsDesc = 'LEADS_MAX_RESIDENCE_TIME_YEARS_DESC',
  LeadsMaxResidenceTimeMonthsAsc = 'LEADS_MAX_RESIDENCE_TIME_MONTHS_ASC',
  LeadsMaxResidenceTimeMonthsDesc = 'LEADS_MAX_RESIDENCE_TIME_MONTHS_DESC',
  LeadsAverageIdAsc = 'LEADS_AVERAGE_ID_ASC',
  LeadsAverageIdDesc = 'LEADS_AVERAGE_ID_DESC',
  LeadsAverageCompanyIdAsc = 'LEADS_AVERAGE_COMPANY_ID_ASC',
  LeadsAverageCompanyIdDesc = 'LEADS_AVERAGE_COMPANY_ID_DESC',
  LeadsAverageCreatedAtAsc = 'LEADS_AVERAGE_CREATED_AT_ASC',
  LeadsAverageCreatedAtDesc = 'LEADS_AVERAGE_CREATED_AT_DESC',
  LeadsAverageFirstNameAsc = 'LEADS_AVERAGE_FIRST_NAME_ASC',
  LeadsAverageFirstNameDesc = 'LEADS_AVERAGE_FIRST_NAME_DESC',
  LeadsAverageLastNameAsc = 'LEADS_AVERAGE_LAST_NAME_ASC',
  LeadsAverageLastNameDesc = 'LEADS_AVERAGE_LAST_NAME_DESC',
  LeadsAverageDateOfBirthAsc = 'LEADS_AVERAGE_DATE_OF_BIRTH_ASC',
  LeadsAverageDateOfBirthDesc = 'LEADS_AVERAGE_DATE_OF_BIRTH_DESC',
  LeadsAverageStreetAsc = 'LEADS_AVERAGE_STREET_ASC',
  LeadsAverageStreetDesc = 'LEADS_AVERAGE_STREET_DESC',
  LeadsAverageStreetNumberAsc = 'LEADS_AVERAGE_STREET_NUMBER_ASC',
  LeadsAverageStreetNumberDesc = 'LEADS_AVERAGE_STREET_NUMBER_DESC',
  LeadsAverageUnitAsc = 'LEADS_AVERAGE_UNIT_ASC',
  LeadsAverageUnitDesc = 'LEADS_AVERAGE_UNIT_DESC',
  LeadsAverageCityAsc = 'LEADS_AVERAGE_CITY_ASC',
  LeadsAverageCityDesc = 'LEADS_AVERAGE_CITY_DESC',
  LeadsAverageCountryAsc = 'LEADS_AVERAGE_COUNTRY_ASC',
  LeadsAverageCountryDesc = 'LEADS_AVERAGE_COUNTRY_DESC',
  LeadsAveragePostalCodeAsc = 'LEADS_AVERAGE_POSTAL_CODE_ASC',
  LeadsAveragePostalCodeDesc = 'LEADS_AVERAGE_POSTAL_CODE_DESC',
  LeadsAverageProvinceCodeAsc = 'LEADS_AVERAGE_PROVINCE_CODE_ASC',
  LeadsAverageProvinceCodeDesc = 'LEADS_AVERAGE_PROVINCE_CODE_DESC',
  LeadsAverageEmailAddressAsc = 'LEADS_AVERAGE_EMAIL_ADDRESS_ASC',
  LeadsAverageEmailAddressDesc = 'LEADS_AVERAGE_EMAIL_ADDRESS_DESC',
  LeadsAveragePhoneNumberAsc = 'LEADS_AVERAGE_PHONE_NUMBER_ASC',
  LeadsAveragePhoneNumberDesc = 'LEADS_AVERAGE_PHONE_NUMBER_DESC',
  LeadsAverageSourceUrlAsc = 'LEADS_AVERAGE_SOURCE_URL_ASC',
  LeadsAverageSourceUrlDesc = 'LEADS_AVERAGE_SOURCE_URL_DESC',
  LeadsAverageTransactionKeyAsc = 'LEADS_AVERAGE_TRANSACTION_KEY_ASC',
  LeadsAverageTransactionKeyDesc = 'LEADS_AVERAGE_TRANSACTION_KEY_DESC',
  LeadsAverageLeadspediaIdAsc = 'LEADS_AVERAGE_LEADSPEDIA_ID_ASC',
  LeadsAverageLeadspediaIdDesc = 'LEADS_AVERAGE_LEADSPEDIA_ID_DESC',
  LeadsAverageUpdatedAtAsc = 'LEADS_AVERAGE_UPDATED_AT_ASC',
  LeadsAverageUpdatedAtDesc = 'LEADS_AVERAGE_UPDATED_AT_DESC',
  LeadsAverageSearchTextAsc = 'LEADS_AVERAGE_SEARCH_TEXT_ASC',
  LeadsAverageSearchTextDesc = 'LEADS_AVERAGE_SEARCH_TEXT_DESC',
  LeadsAverageCommentAsc = 'LEADS_AVERAGE_COMMENT_ASC',
  LeadsAverageCommentDesc = 'LEADS_AVERAGE_COMMENT_DESC',
  LeadsAverageStateAsc = 'LEADS_AVERAGE_STATE_ASC',
  LeadsAverageStateDesc = 'LEADS_AVERAGE_STATE_DESC',
  LeadsAverageReadAsc = 'LEADS_AVERAGE_READ_ASC',
  LeadsAverageReadDesc = 'LEADS_AVERAGE_READ_DESC',
  LeadsAverageExternalIdsAsc = 'LEADS_AVERAGE_EXTERNAL_IDS_ASC',
  LeadsAverageExternalIdsDesc = 'LEADS_AVERAGE_EXTERNAL_IDS_DESC',
  LeadsAverageMetaAsc = 'LEADS_AVERAGE_META_ASC',
  LeadsAverageMetaDesc = 'LEADS_AVERAGE_META_DESC',
  LeadsAverageAdditionalInformationAsc = 'LEADS_AVERAGE_ADDITIONAL_INFORMATION_ASC',
  LeadsAverageAdditionalInformationDesc = 'LEADS_AVERAGE_ADDITIONAL_INFORMATION_DESC',
  LeadsAveragePhoneVerifiedAtAsc = 'LEADS_AVERAGE_PHONE_VERIFIED_AT_ASC',
  LeadsAveragePhoneVerifiedAtDesc = 'LEADS_AVERAGE_PHONE_VERIFIED_AT_DESC',
  LeadsAverageIpAddressAsc = 'LEADS_AVERAGE_IP_ADDRESS_ASC',
  LeadsAverageIpAddressDesc = 'LEADS_AVERAGE_IP_ADDRESS_DESC',
  LeadsAverageClientIdAsc = 'LEADS_AVERAGE_CLIENT_ID_ASC',
  LeadsAverageClientIdDesc = 'LEADS_AVERAGE_CLIENT_ID_DESC',
  LeadsAverageDeletedAsc = 'LEADS_AVERAGE_DELETED_ASC',
  LeadsAverageDeletedDesc = 'LEADS_AVERAGE_DELETED_DESC',
  LeadsAveragePriorityAsc = 'LEADS_AVERAGE_PRIORITY_ASC',
  LeadsAveragePriorityDesc = 'LEADS_AVERAGE_PRIORITY_DESC',
  LeadsAverageAssigneeAsc = 'LEADS_AVERAGE_ASSIGNEE_ASC',
  LeadsAverageAssigneeDesc = 'LEADS_AVERAGE_ASSIGNEE_DESC',
  LeadsAverageShortIdAsc = 'LEADS_AVERAGE_SHORT_ID_ASC',
  LeadsAverageShortIdDesc = 'LEADS_AVERAGE_SHORT_ID_DESC',
  LeadsAverageCompanyChannelIdAsc = 'LEADS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  LeadsAverageCompanyChannelIdDesc = 'LEADS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  LeadsAverageNeighborhoodViewIdAsc = 'LEADS_AVERAGE_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsAverageNeighborhoodViewIdDesc = 'LEADS_AVERAGE_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsAverageMaritalStatusAsc = 'LEADS_AVERAGE_MARITAL_STATUS_ASC',
  LeadsAverageMaritalStatusDesc = 'LEADS_AVERAGE_MARITAL_STATUS_DESC',
  LeadsAverageDesiredVehicleAsc = 'LEADS_AVERAGE_DESIRED_VEHICLE_ASC',
  LeadsAverageDesiredVehicleDesc = 'LEADS_AVERAGE_DESIRED_VEHICLE_DESC',
  LeadsAverageJobTitleAsc = 'LEADS_AVERAGE_JOB_TITLE_ASC',
  LeadsAverageJobTitleDesc = 'LEADS_AVERAGE_JOB_TITLE_DESC',
  LeadsAverageIncomePeriodAsc = 'LEADS_AVERAGE_INCOME_PERIOD_ASC',
  LeadsAverageIncomePeriodDesc = 'LEADS_AVERAGE_INCOME_PERIOD_DESC',
  LeadsAverageSalaryHourlyAsc = 'LEADS_AVERAGE_SALARY_HOURLY_ASC',
  LeadsAverageSalaryHourlyDesc = 'LEADS_AVERAGE_SALARY_HOURLY_DESC',
  LeadsAverageIncomeSourceAsc = 'LEADS_AVERAGE_INCOME_SOURCE_ASC',
  LeadsAverageIncomeSourceDesc = 'LEADS_AVERAGE_INCOME_SOURCE_DESC',
  LeadsAverageIncomeTimeAsc = 'LEADS_AVERAGE_INCOME_TIME_ASC',
  LeadsAverageIncomeTimeDesc = 'LEADS_AVERAGE_INCOME_TIME_DESC',
  LeadsAveragePreferredContactMethodAsc = 'LEADS_AVERAGE_PREFERRED_CONTACT_METHOD_ASC',
  LeadsAveragePreferredContactMethodDesc = 'LEADS_AVERAGE_PREFERRED_CONTACT_METHOD_DESC',
  LeadsAverageCompanyNameAsc = 'LEADS_AVERAGE_COMPANY_NAME_ASC',
  LeadsAverageCompanyNameDesc = 'LEADS_AVERAGE_COMPANY_NAME_DESC',
  LeadsAverageCreditConcernAsc = 'LEADS_AVERAGE_CREDIT_CONCERN_ASC',
  LeadsAverageCreditConcernDesc = 'LEADS_AVERAGE_CREDIT_CONCERN_DESC',
  LeadsAverageResidenceTimeAsc = 'LEADS_AVERAGE_RESIDENCE_TIME_ASC',
  LeadsAverageResidenceTimeDesc = 'LEADS_AVERAGE_RESIDENCE_TIME_DESC',
  LeadsAverageRentOrOwnAsc = 'LEADS_AVERAGE_RENT_OR_OWN_ASC',
  LeadsAverageRentOrOwnDesc = 'LEADS_AVERAGE_RENT_OR_OWN_DESC',
  LeadsAverageEmploymentStatusAsc = 'LEADS_AVERAGE_EMPLOYMENT_STATUS_ASC',
  LeadsAverageEmploymentStatusDesc = 'LEADS_AVERAGE_EMPLOYMENT_STATUS_DESC',
  LeadsAverageIncomeAmountAsc = 'LEADS_AVERAGE_INCOME_AMOUNT_ASC',
  LeadsAverageIncomeAmountDesc = 'LEADS_AVERAGE_INCOME_AMOUNT_DESC',
  LeadsAverageMonthlyHomePaymentAsc = 'LEADS_AVERAGE_MONTHLY_HOME_PAYMENT_ASC',
  LeadsAverageMonthlyHomePaymentDesc = 'LEADS_AVERAGE_MONTHLY_HOME_PAYMENT_DESC',
  LeadsAverageSmsConsentAsc = 'LEADS_AVERAGE_SMS_CONSENT_ASC',
  LeadsAverageSmsConsentDesc = 'LEADS_AVERAGE_SMS_CONSENT_DESC',
  LeadsAverageIncomeTimeYearsAsc = 'LEADS_AVERAGE_INCOME_TIME_YEARS_ASC',
  LeadsAverageIncomeTimeYearsDesc = 'LEADS_AVERAGE_INCOME_TIME_YEARS_DESC',
  LeadsAverageIncomeTimeMonthsAsc = 'LEADS_AVERAGE_INCOME_TIME_MONTHS_ASC',
  LeadsAverageIncomeTimeMonthsDesc = 'LEADS_AVERAGE_INCOME_TIME_MONTHS_DESC',
  LeadsAverageResidenceTimeYearsAsc = 'LEADS_AVERAGE_RESIDENCE_TIME_YEARS_ASC',
  LeadsAverageResidenceTimeYearsDesc = 'LEADS_AVERAGE_RESIDENCE_TIME_YEARS_DESC',
  LeadsAverageResidenceTimeMonthsAsc = 'LEADS_AVERAGE_RESIDENCE_TIME_MONTHS_ASC',
  LeadsAverageResidenceTimeMonthsDesc = 'LEADS_AVERAGE_RESIDENCE_TIME_MONTHS_DESC',
  LeadsStddevSampleIdAsc = 'LEADS_STDDEV_SAMPLE_ID_ASC',
  LeadsStddevSampleIdDesc = 'LEADS_STDDEV_SAMPLE_ID_DESC',
  LeadsStddevSampleCompanyIdAsc = 'LEADS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  LeadsStddevSampleCompanyIdDesc = 'LEADS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  LeadsStddevSampleCreatedAtAsc = 'LEADS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LeadsStddevSampleCreatedAtDesc = 'LEADS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LeadsStddevSampleFirstNameAsc = 'LEADS_STDDEV_SAMPLE_FIRST_NAME_ASC',
  LeadsStddevSampleFirstNameDesc = 'LEADS_STDDEV_SAMPLE_FIRST_NAME_DESC',
  LeadsStddevSampleLastNameAsc = 'LEADS_STDDEV_SAMPLE_LAST_NAME_ASC',
  LeadsStddevSampleLastNameDesc = 'LEADS_STDDEV_SAMPLE_LAST_NAME_DESC',
  LeadsStddevSampleDateOfBirthAsc = 'LEADS_STDDEV_SAMPLE_DATE_OF_BIRTH_ASC',
  LeadsStddevSampleDateOfBirthDesc = 'LEADS_STDDEV_SAMPLE_DATE_OF_BIRTH_DESC',
  LeadsStddevSampleStreetAsc = 'LEADS_STDDEV_SAMPLE_STREET_ASC',
  LeadsStddevSampleStreetDesc = 'LEADS_STDDEV_SAMPLE_STREET_DESC',
  LeadsStddevSampleStreetNumberAsc = 'LEADS_STDDEV_SAMPLE_STREET_NUMBER_ASC',
  LeadsStddevSampleStreetNumberDesc = 'LEADS_STDDEV_SAMPLE_STREET_NUMBER_DESC',
  LeadsStddevSampleUnitAsc = 'LEADS_STDDEV_SAMPLE_UNIT_ASC',
  LeadsStddevSampleUnitDesc = 'LEADS_STDDEV_SAMPLE_UNIT_DESC',
  LeadsStddevSampleCityAsc = 'LEADS_STDDEV_SAMPLE_CITY_ASC',
  LeadsStddevSampleCityDesc = 'LEADS_STDDEV_SAMPLE_CITY_DESC',
  LeadsStddevSampleCountryAsc = 'LEADS_STDDEV_SAMPLE_COUNTRY_ASC',
  LeadsStddevSampleCountryDesc = 'LEADS_STDDEV_SAMPLE_COUNTRY_DESC',
  LeadsStddevSamplePostalCodeAsc = 'LEADS_STDDEV_SAMPLE_POSTAL_CODE_ASC',
  LeadsStddevSamplePostalCodeDesc = 'LEADS_STDDEV_SAMPLE_POSTAL_CODE_DESC',
  LeadsStddevSampleProvinceCodeAsc = 'LEADS_STDDEV_SAMPLE_PROVINCE_CODE_ASC',
  LeadsStddevSampleProvinceCodeDesc = 'LEADS_STDDEV_SAMPLE_PROVINCE_CODE_DESC',
  LeadsStddevSampleEmailAddressAsc = 'LEADS_STDDEV_SAMPLE_EMAIL_ADDRESS_ASC',
  LeadsStddevSampleEmailAddressDesc = 'LEADS_STDDEV_SAMPLE_EMAIL_ADDRESS_DESC',
  LeadsStddevSamplePhoneNumberAsc = 'LEADS_STDDEV_SAMPLE_PHONE_NUMBER_ASC',
  LeadsStddevSamplePhoneNumberDesc = 'LEADS_STDDEV_SAMPLE_PHONE_NUMBER_DESC',
  LeadsStddevSampleSourceUrlAsc = 'LEADS_STDDEV_SAMPLE_SOURCE_URL_ASC',
  LeadsStddevSampleSourceUrlDesc = 'LEADS_STDDEV_SAMPLE_SOURCE_URL_DESC',
  LeadsStddevSampleTransactionKeyAsc = 'LEADS_STDDEV_SAMPLE_TRANSACTION_KEY_ASC',
  LeadsStddevSampleTransactionKeyDesc = 'LEADS_STDDEV_SAMPLE_TRANSACTION_KEY_DESC',
  LeadsStddevSampleLeadspediaIdAsc = 'LEADS_STDDEV_SAMPLE_LEADSPEDIA_ID_ASC',
  LeadsStddevSampleLeadspediaIdDesc = 'LEADS_STDDEV_SAMPLE_LEADSPEDIA_ID_DESC',
  LeadsStddevSampleUpdatedAtAsc = 'LEADS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LeadsStddevSampleUpdatedAtDesc = 'LEADS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LeadsStddevSampleSearchTextAsc = 'LEADS_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  LeadsStddevSampleSearchTextDesc = 'LEADS_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  LeadsStddevSampleCommentAsc = 'LEADS_STDDEV_SAMPLE_COMMENT_ASC',
  LeadsStddevSampleCommentDesc = 'LEADS_STDDEV_SAMPLE_COMMENT_DESC',
  LeadsStddevSampleStateAsc = 'LEADS_STDDEV_SAMPLE_STATE_ASC',
  LeadsStddevSampleStateDesc = 'LEADS_STDDEV_SAMPLE_STATE_DESC',
  LeadsStddevSampleReadAsc = 'LEADS_STDDEV_SAMPLE_READ_ASC',
  LeadsStddevSampleReadDesc = 'LEADS_STDDEV_SAMPLE_READ_DESC',
  LeadsStddevSampleExternalIdsAsc = 'LEADS_STDDEV_SAMPLE_EXTERNAL_IDS_ASC',
  LeadsStddevSampleExternalIdsDesc = 'LEADS_STDDEV_SAMPLE_EXTERNAL_IDS_DESC',
  LeadsStddevSampleMetaAsc = 'LEADS_STDDEV_SAMPLE_META_ASC',
  LeadsStddevSampleMetaDesc = 'LEADS_STDDEV_SAMPLE_META_DESC',
  LeadsStddevSampleAdditionalInformationAsc = 'LEADS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  LeadsStddevSampleAdditionalInformationDesc = 'LEADS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  LeadsStddevSamplePhoneVerifiedAtAsc = 'LEADS_STDDEV_SAMPLE_PHONE_VERIFIED_AT_ASC',
  LeadsStddevSamplePhoneVerifiedAtDesc = 'LEADS_STDDEV_SAMPLE_PHONE_VERIFIED_AT_DESC',
  LeadsStddevSampleIpAddressAsc = 'LEADS_STDDEV_SAMPLE_IP_ADDRESS_ASC',
  LeadsStddevSampleIpAddressDesc = 'LEADS_STDDEV_SAMPLE_IP_ADDRESS_DESC',
  LeadsStddevSampleClientIdAsc = 'LEADS_STDDEV_SAMPLE_CLIENT_ID_ASC',
  LeadsStddevSampleClientIdDesc = 'LEADS_STDDEV_SAMPLE_CLIENT_ID_DESC',
  LeadsStddevSampleDeletedAsc = 'LEADS_STDDEV_SAMPLE_DELETED_ASC',
  LeadsStddevSampleDeletedDesc = 'LEADS_STDDEV_SAMPLE_DELETED_DESC',
  LeadsStddevSamplePriorityAsc = 'LEADS_STDDEV_SAMPLE_PRIORITY_ASC',
  LeadsStddevSamplePriorityDesc = 'LEADS_STDDEV_SAMPLE_PRIORITY_DESC',
  LeadsStddevSampleAssigneeAsc = 'LEADS_STDDEV_SAMPLE_ASSIGNEE_ASC',
  LeadsStddevSampleAssigneeDesc = 'LEADS_STDDEV_SAMPLE_ASSIGNEE_DESC',
  LeadsStddevSampleShortIdAsc = 'LEADS_STDDEV_SAMPLE_SHORT_ID_ASC',
  LeadsStddevSampleShortIdDesc = 'LEADS_STDDEV_SAMPLE_SHORT_ID_DESC',
  LeadsStddevSampleCompanyChannelIdAsc = 'LEADS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  LeadsStddevSampleCompanyChannelIdDesc = 'LEADS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  LeadsStddevSampleNeighborhoodViewIdAsc = 'LEADS_STDDEV_SAMPLE_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsStddevSampleNeighborhoodViewIdDesc = 'LEADS_STDDEV_SAMPLE_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsStddevSampleMaritalStatusAsc = 'LEADS_STDDEV_SAMPLE_MARITAL_STATUS_ASC',
  LeadsStddevSampleMaritalStatusDesc = 'LEADS_STDDEV_SAMPLE_MARITAL_STATUS_DESC',
  LeadsStddevSampleDesiredVehicleAsc = 'LEADS_STDDEV_SAMPLE_DESIRED_VEHICLE_ASC',
  LeadsStddevSampleDesiredVehicleDesc = 'LEADS_STDDEV_SAMPLE_DESIRED_VEHICLE_DESC',
  LeadsStddevSampleJobTitleAsc = 'LEADS_STDDEV_SAMPLE_JOB_TITLE_ASC',
  LeadsStddevSampleJobTitleDesc = 'LEADS_STDDEV_SAMPLE_JOB_TITLE_DESC',
  LeadsStddevSampleIncomePeriodAsc = 'LEADS_STDDEV_SAMPLE_INCOME_PERIOD_ASC',
  LeadsStddevSampleIncomePeriodDesc = 'LEADS_STDDEV_SAMPLE_INCOME_PERIOD_DESC',
  LeadsStddevSampleSalaryHourlyAsc = 'LEADS_STDDEV_SAMPLE_SALARY_HOURLY_ASC',
  LeadsStddevSampleSalaryHourlyDesc = 'LEADS_STDDEV_SAMPLE_SALARY_HOURLY_DESC',
  LeadsStddevSampleIncomeSourceAsc = 'LEADS_STDDEV_SAMPLE_INCOME_SOURCE_ASC',
  LeadsStddevSampleIncomeSourceDesc = 'LEADS_STDDEV_SAMPLE_INCOME_SOURCE_DESC',
  LeadsStddevSampleIncomeTimeAsc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_ASC',
  LeadsStddevSampleIncomeTimeDesc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_DESC',
  LeadsStddevSamplePreferredContactMethodAsc = 'LEADS_STDDEV_SAMPLE_PREFERRED_CONTACT_METHOD_ASC',
  LeadsStddevSamplePreferredContactMethodDesc = 'LEADS_STDDEV_SAMPLE_PREFERRED_CONTACT_METHOD_DESC',
  LeadsStddevSampleCompanyNameAsc = 'LEADS_STDDEV_SAMPLE_COMPANY_NAME_ASC',
  LeadsStddevSampleCompanyNameDesc = 'LEADS_STDDEV_SAMPLE_COMPANY_NAME_DESC',
  LeadsStddevSampleCreditConcernAsc = 'LEADS_STDDEV_SAMPLE_CREDIT_CONCERN_ASC',
  LeadsStddevSampleCreditConcernDesc = 'LEADS_STDDEV_SAMPLE_CREDIT_CONCERN_DESC',
  LeadsStddevSampleResidenceTimeAsc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_ASC',
  LeadsStddevSampleResidenceTimeDesc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_DESC',
  LeadsStddevSampleRentOrOwnAsc = 'LEADS_STDDEV_SAMPLE_RENT_OR_OWN_ASC',
  LeadsStddevSampleRentOrOwnDesc = 'LEADS_STDDEV_SAMPLE_RENT_OR_OWN_DESC',
  LeadsStddevSampleEmploymentStatusAsc = 'LEADS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LeadsStddevSampleEmploymentStatusDesc = 'LEADS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LeadsStddevSampleIncomeAmountAsc = 'LEADS_STDDEV_SAMPLE_INCOME_AMOUNT_ASC',
  LeadsStddevSampleIncomeAmountDesc = 'LEADS_STDDEV_SAMPLE_INCOME_AMOUNT_DESC',
  LeadsStddevSampleMonthlyHomePaymentAsc = 'LEADS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LeadsStddevSampleMonthlyHomePaymentDesc = 'LEADS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LeadsStddevSampleSmsConsentAsc = 'LEADS_STDDEV_SAMPLE_SMS_CONSENT_ASC',
  LeadsStddevSampleSmsConsentDesc = 'LEADS_STDDEV_SAMPLE_SMS_CONSENT_DESC',
  LeadsStddevSampleIncomeTimeYearsAsc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_YEARS_ASC',
  LeadsStddevSampleIncomeTimeYearsDesc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_YEARS_DESC',
  LeadsStddevSampleIncomeTimeMonthsAsc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_MONTHS_ASC',
  LeadsStddevSampleIncomeTimeMonthsDesc = 'LEADS_STDDEV_SAMPLE_INCOME_TIME_MONTHS_DESC',
  LeadsStddevSampleResidenceTimeYearsAsc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_YEARS_ASC',
  LeadsStddevSampleResidenceTimeYearsDesc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_YEARS_DESC',
  LeadsStddevSampleResidenceTimeMonthsAsc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_MONTHS_ASC',
  LeadsStddevSampleResidenceTimeMonthsDesc = 'LEADS_STDDEV_SAMPLE_RESIDENCE_TIME_MONTHS_DESC',
  LeadsStddevPopulationIdAsc = 'LEADS_STDDEV_POPULATION_ID_ASC',
  LeadsStddevPopulationIdDesc = 'LEADS_STDDEV_POPULATION_ID_DESC',
  LeadsStddevPopulationCompanyIdAsc = 'LEADS_STDDEV_POPULATION_COMPANY_ID_ASC',
  LeadsStddevPopulationCompanyIdDesc = 'LEADS_STDDEV_POPULATION_COMPANY_ID_DESC',
  LeadsStddevPopulationCreatedAtAsc = 'LEADS_STDDEV_POPULATION_CREATED_AT_ASC',
  LeadsStddevPopulationCreatedAtDesc = 'LEADS_STDDEV_POPULATION_CREATED_AT_DESC',
  LeadsStddevPopulationFirstNameAsc = 'LEADS_STDDEV_POPULATION_FIRST_NAME_ASC',
  LeadsStddevPopulationFirstNameDesc = 'LEADS_STDDEV_POPULATION_FIRST_NAME_DESC',
  LeadsStddevPopulationLastNameAsc = 'LEADS_STDDEV_POPULATION_LAST_NAME_ASC',
  LeadsStddevPopulationLastNameDesc = 'LEADS_STDDEV_POPULATION_LAST_NAME_DESC',
  LeadsStddevPopulationDateOfBirthAsc = 'LEADS_STDDEV_POPULATION_DATE_OF_BIRTH_ASC',
  LeadsStddevPopulationDateOfBirthDesc = 'LEADS_STDDEV_POPULATION_DATE_OF_BIRTH_DESC',
  LeadsStddevPopulationStreetAsc = 'LEADS_STDDEV_POPULATION_STREET_ASC',
  LeadsStddevPopulationStreetDesc = 'LEADS_STDDEV_POPULATION_STREET_DESC',
  LeadsStddevPopulationStreetNumberAsc = 'LEADS_STDDEV_POPULATION_STREET_NUMBER_ASC',
  LeadsStddevPopulationStreetNumberDesc = 'LEADS_STDDEV_POPULATION_STREET_NUMBER_DESC',
  LeadsStddevPopulationUnitAsc = 'LEADS_STDDEV_POPULATION_UNIT_ASC',
  LeadsStddevPopulationUnitDesc = 'LEADS_STDDEV_POPULATION_UNIT_DESC',
  LeadsStddevPopulationCityAsc = 'LEADS_STDDEV_POPULATION_CITY_ASC',
  LeadsStddevPopulationCityDesc = 'LEADS_STDDEV_POPULATION_CITY_DESC',
  LeadsStddevPopulationCountryAsc = 'LEADS_STDDEV_POPULATION_COUNTRY_ASC',
  LeadsStddevPopulationCountryDesc = 'LEADS_STDDEV_POPULATION_COUNTRY_DESC',
  LeadsStddevPopulationPostalCodeAsc = 'LEADS_STDDEV_POPULATION_POSTAL_CODE_ASC',
  LeadsStddevPopulationPostalCodeDesc = 'LEADS_STDDEV_POPULATION_POSTAL_CODE_DESC',
  LeadsStddevPopulationProvinceCodeAsc = 'LEADS_STDDEV_POPULATION_PROVINCE_CODE_ASC',
  LeadsStddevPopulationProvinceCodeDesc = 'LEADS_STDDEV_POPULATION_PROVINCE_CODE_DESC',
  LeadsStddevPopulationEmailAddressAsc = 'LEADS_STDDEV_POPULATION_EMAIL_ADDRESS_ASC',
  LeadsStddevPopulationEmailAddressDesc = 'LEADS_STDDEV_POPULATION_EMAIL_ADDRESS_DESC',
  LeadsStddevPopulationPhoneNumberAsc = 'LEADS_STDDEV_POPULATION_PHONE_NUMBER_ASC',
  LeadsStddevPopulationPhoneNumberDesc = 'LEADS_STDDEV_POPULATION_PHONE_NUMBER_DESC',
  LeadsStddevPopulationSourceUrlAsc = 'LEADS_STDDEV_POPULATION_SOURCE_URL_ASC',
  LeadsStddevPopulationSourceUrlDesc = 'LEADS_STDDEV_POPULATION_SOURCE_URL_DESC',
  LeadsStddevPopulationTransactionKeyAsc = 'LEADS_STDDEV_POPULATION_TRANSACTION_KEY_ASC',
  LeadsStddevPopulationTransactionKeyDesc = 'LEADS_STDDEV_POPULATION_TRANSACTION_KEY_DESC',
  LeadsStddevPopulationLeadspediaIdAsc = 'LEADS_STDDEV_POPULATION_LEADSPEDIA_ID_ASC',
  LeadsStddevPopulationLeadspediaIdDesc = 'LEADS_STDDEV_POPULATION_LEADSPEDIA_ID_DESC',
  LeadsStddevPopulationUpdatedAtAsc = 'LEADS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LeadsStddevPopulationUpdatedAtDesc = 'LEADS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LeadsStddevPopulationSearchTextAsc = 'LEADS_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  LeadsStddevPopulationSearchTextDesc = 'LEADS_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  LeadsStddevPopulationCommentAsc = 'LEADS_STDDEV_POPULATION_COMMENT_ASC',
  LeadsStddevPopulationCommentDesc = 'LEADS_STDDEV_POPULATION_COMMENT_DESC',
  LeadsStddevPopulationStateAsc = 'LEADS_STDDEV_POPULATION_STATE_ASC',
  LeadsStddevPopulationStateDesc = 'LEADS_STDDEV_POPULATION_STATE_DESC',
  LeadsStddevPopulationReadAsc = 'LEADS_STDDEV_POPULATION_READ_ASC',
  LeadsStddevPopulationReadDesc = 'LEADS_STDDEV_POPULATION_READ_DESC',
  LeadsStddevPopulationExternalIdsAsc = 'LEADS_STDDEV_POPULATION_EXTERNAL_IDS_ASC',
  LeadsStddevPopulationExternalIdsDesc = 'LEADS_STDDEV_POPULATION_EXTERNAL_IDS_DESC',
  LeadsStddevPopulationMetaAsc = 'LEADS_STDDEV_POPULATION_META_ASC',
  LeadsStddevPopulationMetaDesc = 'LEADS_STDDEV_POPULATION_META_DESC',
  LeadsStddevPopulationAdditionalInformationAsc = 'LEADS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_ASC',
  LeadsStddevPopulationAdditionalInformationDesc = 'LEADS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_DESC',
  LeadsStddevPopulationPhoneVerifiedAtAsc = 'LEADS_STDDEV_POPULATION_PHONE_VERIFIED_AT_ASC',
  LeadsStddevPopulationPhoneVerifiedAtDesc = 'LEADS_STDDEV_POPULATION_PHONE_VERIFIED_AT_DESC',
  LeadsStddevPopulationIpAddressAsc = 'LEADS_STDDEV_POPULATION_IP_ADDRESS_ASC',
  LeadsStddevPopulationIpAddressDesc = 'LEADS_STDDEV_POPULATION_IP_ADDRESS_DESC',
  LeadsStddevPopulationClientIdAsc = 'LEADS_STDDEV_POPULATION_CLIENT_ID_ASC',
  LeadsStddevPopulationClientIdDesc = 'LEADS_STDDEV_POPULATION_CLIENT_ID_DESC',
  LeadsStddevPopulationDeletedAsc = 'LEADS_STDDEV_POPULATION_DELETED_ASC',
  LeadsStddevPopulationDeletedDesc = 'LEADS_STDDEV_POPULATION_DELETED_DESC',
  LeadsStddevPopulationPriorityAsc = 'LEADS_STDDEV_POPULATION_PRIORITY_ASC',
  LeadsStddevPopulationPriorityDesc = 'LEADS_STDDEV_POPULATION_PRIORITY_DESC',
  LeadsStddevPopulationAssigneeAsc = 'LEADS_STDDEV_POPULATION_ASSIGNEE_ASC',
  LeadsStddevPopulationAssigneeDesc = 'LEADS_STDDEV_POPULATION_ASSIGNEE_DESC',
  LeadsStddevPopulationShortIdAsc = 'LEADS_STDDEV_POPULATION_SHORT_ID_ASC',
  LeadsStddevPopulationShortIdDesc = 'LEADS_STDDEV_POPULATION_SHORT_ID_DESC',
  LeadsStddevPopulationCompanyChannelIdAsc = 'LEADS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  LeadsStddevPopulationCompanyChannelIdDesc = 'LEADS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  LeadsStddevPopulationNeighborhoodViewIdAsc = 'LEADS_STDDEV_POPULATION_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsStddevPopulationNeighborhoodViewIdDesc = 'LEADS_STDDEV_POPULATION_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsStddevPopulationMaritalStatusAsc = 'LEADS_STDDEV_POPULATION_MARITAL_STATUS_ASC',
  LeadsStddevPopulationMaritalStatusDesc = 'LEADS_STDDEV_POPULATION_MARITAL_STATUS_DESC',
  LeadsStddevPopulationDesiredVehicleAsc = 'LEADS_STDDEV_POPULATION_DESIRED_VEHICLE_ASC',
  LeadsStddevPopulationDesiredVehicleDesc = 'LEADS_STDDEV_POPULATION_DESIRED_VEHICLE_DESC',
  LeadsStddevPopulationJobTitleAsc = 'LEADS_STDDEV_POPULATION_JOB_TITLE_ASC',
  LeadsStddevPopulationJobTitleDesc = 'LEADS_STDDEV_POPULATION_JOB_TITLE_DESC',
  LeadsStddevPopulationIncomePeriodAsc = 'LEADS_STDDEV_POPULATION_INCOME_PERIOD_ASC',
  LeadsStddevPopulationIncomePeriodDesc = 'LEADS_STDDEV_POPULATION_INCOME_PERIOD_DESC',
  LeadsStddevPopulationSalaryHourlyAsc = 'LEADS_STDDEV_POPULATION_SALARY_HOURLY_ASC',
  LeadsStddevPopulationSalaryHourlyDesc = 'LEADS_STDDEV_POPULATION_SALARY_HOURLY_DESC',
  LeadsStddevPopulationIncomeSourceAsc = 'LEADS_STDDEV_POPULATION_INCOME_SOURCE_ASC',
  LeadsStddevPopulationIncomeSourceDesc = 'LEADS_STDDEV_POPULATION_INCOME_SOURCE_DESC',
  LeadsStddevPopulationIncomeTimeAsc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_ASC',
  LeadsStddevPopulationIncomeTimeDesc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_DESC',
  LeadsStddevPopulationPreferredContactMethodAsc = 'LEADS_STDDEV_POPULATION_PREFERRED_CONTACT_METHOD_ASC',
  LeadsStddevPopulationPreferredContactMethodDesc = 'LEADS_STDDEV_POPULATION_PREFERRED_CONTACT_METHOD_DESC',
  LeadsStddevPopulationCompanyNameAsc = 'LEADS_STDDEV_POPULATION_COMPANY_NAME_ASC',
  LeadsStddevPopulationCompanyNameDesc = 'LEADS_STDDEV_POPULATION_COMPANY_NAME_DESC',
  LeadsStddevPopulationCreditConcernAsc = 'LEADS_STDDEV_POPULATION_CREDIT_CONCERN_ASC',
  LeadsStddevPopulationCreditConcernDesc = 'LEADS_STDDEV_POPULATION_CREDIT_CONCERN_DESC',
  LeadsStddevPopulationResidenceTimeAsc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_ASC',
  LeadsStddevPopulationResidenceTimeDesc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_DESC',
  LeadsStddevPopulationRentOrOwnAsc = 'LEADS_STDDEV_POPULATION_RENT_OR_OWN_ASC',
  LeadsStddevPopulationRentOrOwnDesc = 'LEADS_STDDEV_POPULATION_RENT_OR_OWN_DESC',
  LeadsStddevPopulationEmploymentStatusAsc = 'LEADS_STDDEV_POPULATION_EMPLOYMENT_STATUS_ASC',
  LeadsStddevPopulationEmploymentStatusDesc = 'LEADS_STDDEV_POPULATION_EMPLOYMENT_STATUS_DESC',
  LeadsStddevPopulationIncomeAmountAsc = 'LEADS_STDDEV_POPULATION_INCOME_AMOUNT_ASC',
  LeadsStddevPopulationIncomeAmountDesc = 'LEADS_STDDEV_POPULATION_INCOME_AMOUNT_DESC',
  LeadsStddevPopulationMonthlyHomePaymentAsc = 'LEADS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LeadsStddevPopulationMonthlyHomePaymentDesc = 'LEADS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LeadsStddevPopulationSmsConsentAsc = 'LEADS_STDDEV_POPULATION_SMS_CONSENT_ASC',
  LeadsStddevPopulationSmsConsentDesc = 'LEADS_STDDEV_POPULATION_SMS_CONSENT_DESC',
  LeadsStddevPopulationIncomeTimeYearsAsc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_YEARS_ASC',
  LeadsStddevPopulationIncomeTimeYearsDesc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_YEARS_DESC',
  LeadsStddevPopulationIncomeTimeMonthsAsc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_MONTHS_ASC',
  LeadsStddevPopulationIncomeTimeMonthsDesc = 'LEADS_STDDEV_POPULATION_INCOME_TIME_MONTHS_DESC',
  LeadsStddevPopulationResidenceTimeYearsAsc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_YEARS_ASC',
  LeadsStddevPopulationResidenceTimeYearsDesc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_YEARS_DESC',
  LeadsStddevPopulationResidenceTimeMonthsAsc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_MONTHS_ASC',
  LeadsStddevPopulationResidenceTimeMonthsDesc = 'LEADS_STDDEV_POPULATION_RESIDENCE_TIME_MONTHS_DESC',
  LeadsVarianceSampleIdAsc = 'LEADS_VARIANCE_SAMPLE_ID_ASC',
  LeadsVarianceSampleIdDesc = 'LEADS_VARIANCE_SAMPLE_ID_DESC',
  LeadsVarianceSampleCompanyIdAsc = 'LEADS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  LeadsVarianceSampleCompanyIdDesc = 'LEADS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  LeadsVarianceSampleCreatedAtAsc = 'LEADS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LeadsVarianceSampleCreatedAtDesc = 'LEADS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LeadsVarianceSampleFirstNameAsc = 'LEADS_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  LeadsVarianceSampleFirstNameDesc = 'LEADS_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  LeadsVarianceSampleLastNameAsc = 'LEADS_VARIANCE_SAMPLE_LAST_NAME_ASC',
  LeadsVarianceSampleLastNameDesc = 'LEADS_VARIANCE_SAMPLE_LAST_NAME_DESC',
  LeadsVarianceSampleDateOfBirthAsc = 'LEADS_VARIANCE_SAMPLE_DATE_OF_BIRTH_ASC',
  LeadsVarianceSampleDateOfBirthDesc = 'LEADS_VARIANCE_SAMPLE_DATE_OF_BIRTH_DESC',
  LeadsVarianceSampleStreetAsc = 'LEADS_VARIANCE_SAMPLE_STREET_ASC',
  LeadsVarianceSampleStreetDesc = 'LEADS_VARIANCE_SAMPLE_STREET_DESC',
  LeadsVarianceSampleStreetNumberAsc = 'LEADS_VARIANCE_SAMPLE_STREET_NUMBER_ASC',
  LeadsVarianceSampleStreetNumberDesc = 'LEADS_VARIANCE_SAMPLE_STREET_NUMBER_DESC',
  LeadsVarianceSampleUnitAsc = 'LEADS_VARIANCE_SAMPLE_UNIT_ASC',
  LeadsVarianceSampleUnitDesc = 'LEADS_VARIANCE_SAMPLE_UNIT_DESC',
  LeadsVarianceSampleCityAsc = 'LEADS_VARIANCE_SAMPLE_CITY_ASC',
  LeadsVarianceSampleCityDesc = 'LEADS_VARIANCE_SAMPLE_CITY_DESC',
  LeadsVarianceSampleCountryAsc = 'LEADS_VARIANCE_SAMPLE_COUNTRY_ASC',
  LeadsVarianceSampleCountryDesc = 'LEADS_VARIANCE_SAMPLE_COUNTRY_DESC',
  LeadsVarianceSamplePostalCodeAsc = 'LEADS_VARIANCE_SAMPLE_POSTAL_CODE_ASC',
  LeadsVarianceSamplePostalCodeDesc = 'LEADS_VARIANCE_SAMPLE_POSTAL_CODE_DESC',
  LeadsVarianceSampleProvinceCodeAsc = 'LEADS_VARIANCE_SAMPLE_PROVINCE_CODE_ASC',
  LeadsVarianceSampleProvinceCodeDesc = 'LEADS_VARIANCE_SAMPLE_PROVINCE_CODE_DESC',
  LeadsVarianceSampleEmailAddressAsc = 'LEADS_VARIANCE_SAMPLE_EMAIL_ADDRESS_ASC',
  LeadsVarianceSampleEmailAddressDesc = 'LEADS_VARIANCE_SAMPLE_EMAIL_ADDRESS_DESC',
  LeadsVarianceSamplePhoneNumberAsc = 'LEADS_VARIANCE_SAMPLE_PHONE_NUMBER_ASC',
  LeadsVarianceSamplePhoneNumberDesc = 'LEADS_VARIANCE_SAMPLE_PHONE_NUMBER_DESC',
  LeadsVarianceSampleSourceUrlAsc = 'LEADS_VARIANCE_SAMPLE_SOURCE_URL_ASC',
  LeadsVarianceSampleSourceUrlDesc = 'LEADS_VARIANCE_SAMPLE_SOURCE_URL_DESC',
  LeadsVarianceSampleTransactionKeyAsc = 'LEADS_VARIANCE_SAMPLE_TRANSACTION_KEY_ASC',
  LeadsVarianceSampleTransactionKeyDesc = 'LEADS_VARIANCE_SAMPLE_TRANSACTION_KEY_DESC',
  LeadsVarianceSampleLeadspediaIdAsc = 'LEADS_VARIANCE_SAMPLE_LEADSPEDIA_ID_ASC',
  LeadsVarianceSampleLeadspediaIdDesc = 'LEADS_VARIANCE_SAMPLE_LEADSPEDIA_ID_DESC',
  LeadsVarianceSampleUpdatedAtAsc = 'LEADS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LeadsVarianceSampleUpdatedAtDesc = 'LEADS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LeadsVarianceSampleSearchTextAsc = 'LEADS_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  LeadsVarianceSampleSearchTextDesc = 'LEADS_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  LeadsVarianceSampleCommentAsc = 'LEADS_VARIANCE_SAMPLE_COMMENT_ASC',
  LeadsVarianceSampleCommentDesc = 'LEADS_VARIANCE_SAMPLE_COMMENT_DESC',
  LeadsVarianceSampleStateAsc = 'LEADS_VARIANCE_SAMPLE_STATE_ASC',
  LeadsVarianceSampleStateDesc = 'LEADS_VARIANCE_SAMPLE_STATE_DESC',
  LeadsVarianceSampleReadAsc = 'LEADS_VARIANCE_SAMPLE_READ_ASC',
  LeadsVarianceSampleReadDesc = 'LEADS_VARIANCE_SAMPLE_READ_DESC',
  LeadsVarianceSampleExternalIdsAsc = 'LEADS_VARIANCE_SAMPLE_EXTERNAL_IDS_ASC',
  LeadsVarianceSampleExternalIdsDesc = 'LEADS_VARIANCE_SAMPLE_EXTERNAL_IDS_DESC',
  LeadsVarianceSampleMetaAsc = 'LEADS_VARIANCE_SAMPLE_META_ASC',
  LeadsVarianceSampleMetaDesc = 'LEADS_VARIANCE_SAMPLE_META_DESC',
  LeadsVarianceSampleAdditionalInformationAsc = 'LEADS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  LeadsVarianceSampleAdditionalInformationDesc = 'LEADS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  LeadsVarianceSamplePhoneVerifiedAtAsc = 'LEADS_VARIANCE_SAMPLE_PHONE_VERIFIED_AT_ASC',
  LeadsVarianceSamplePhoneVerifiedAtDesc = 'LEADS_VARIANCE_SAMPLE_PHONE_VERIFIED_AT_DESC',
  LeadsVarianceSampleIpAddressAsc = 'LEADS_VARIANCE_SAMPLE_IP_ADDRESS_ASC',
  LeadsVarianceSampleIpAddressDesc = 'LEADS_VARIANCE_SAMPLE_IP_ADDRESS_DESC',
  LeadsVarianceSampleClientIdAsc = 'LEADS_VARIANCE_SAMPLE_CLIENT_ID_ASC',
  LeadsVarianceSampleClientIdDesc = 'LEADS_VARIANCE_SAMPLE_CLIENT_ID_DESC',
  LeadsVarianceSampleDeletedAsc = 'LEADS_VARIANCE_SAMPLE_DELETED_ASC',
  LeadsVarianceSampleDeletedDesc = 'LEADS_VARIANCE_SAMPLE_DELETED_DESC',
  LeadsVarianceSamplePriorityAsc = 'LEADS_VARIANCE_SAMPLE_PRIORITY_ASC',
  LeadsVarianceSamplePriorityDesc = 'LEADS_VARIANCE_SAMPLE_PRIORITY_DESC',
  LeadsVarianceSampleAssigneeAsc = 'LEADS_VARIANCE_SAMPLE_ASSIGNEE_ASC',
  LeadsVarianceSampleAssigneeDesc = 'LEADS_VARIANCE_SAMPLE_ASSIGNEE_DESC',
  LeadsVarianceSampleShortIdAsc = 'LEADS_VARIANCE_SAMPLE_SHORT_ID_ASC',
  LeadsVarianceSampleShortIdDesc = 'LEADS_VARIANCE_SAMPLE_SHORT_ID_DESC',
  LeadsVarianceSampleCompanyChannelIdAsc = 'LEADS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  LeadsVarianceSampleCompanyChannelIdDesc = 'LEADS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  LeadsVarianceSampleNeighborhoodViewIdAsc = 'LEADS_VARIANCE_SAMPLE_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsVarianceSampleNeighborhoodViewIdDesc = 'LEADS_VARIANCE_SAMPLE_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsVarianceSampleMaritalStatusAsc = 'LEADS_VARIANCE_SAMPLE_MARITAL_STATUS_ASC',
  LeadsVarianceSampleMaritalStatusDesc = 'LEADS_VARIANCE_SAMPLE_MARITAL_STATUS_DESC',
  LeadsVarianceSampleDesiredVehicleAsc = 'LEADS_VARIANCE_SAMPLE_DESIRED_VEHICLE_ASC',
  LeadsVarianceSampleDesiredVehicleDesc = 'LEADS_VARIANCE_SAMPLE_DESIRED_VEHICLE_DESC',
  LeadsVarianceSampleJobTitleAsc = 'LEADS_VARIANCE_SAMPLE_JOB_TITLE_ASC',
  LeadsVarianceSampleJobTitleDesc = 'LEADS_VARIANCE_SAMPLE_JOB_TITLE_DESC',
  LeadsVarianceSampleIncomePeriodAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_PERIOD_ASC',
  LeadsVarianceSampleIncomePeriodDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_PERIOD_DESC',
  LeadsVarianceSampleSalaryHourlyAsc = 'LEADS_VARIANCE_SAMPLE_SALARY_HOURLY_ASC',
  LeadsVarianceSampleSalaryHourlyDesc = 'LEADS_VARIANCE_SAMPLE_SALARY_HOURLY_DESC',
  LeadsVarianceSampleIncomeSourceAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_SOURCE_ASC',
  LeadsVarianceSampleIncomeSourceDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_SOURCE_DESC',
  LeadsVarianceSampleIncomeTimeAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_ASC',
  LeadsVarianceSampleIncomeTimeDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_DESC',
  LeadsVarianceSamplePreferredContactMethodAsc = 'LEADS_VARIANCE_SAMPLE_PREFERRED_CONTACT_METHOD_ASC',
  LeadsVarianceSamplePreferredContactMethodDesc = 'LEADS_VARIANCE_SAMPLE_PREFERRED_CONTACT_METHOD_DESC',
  LeadsVarianceSampleCompanyNameAsc = 'LEADS_VARIANCE_SAMPLE_COMPANY_NAME_ASC',
  LeadsVarianceSampleCompanyNameDesc = 'LEADS_VARIANCE_SAMPLE_COMPANY_NAME_DESC',
  LeadsVarianceSampleCreditConcernAsc = 'LEADS_VARIANCE_SAMPLE_CREDIT_CONCERN_ASC',
  LeadsVarianceSampleCreditConcernDesc = 'LEADS_VARIANCE_SAMPLE_CREDIT_CONCERN_DESC',
  LeadsVarianceSampleResidenceTimeAsc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_ASC',
  LeadsVarianceSampleResidenceTimeDesc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_DESC',
  LeadsVarianceSampleRentOrOwnAsc = 'LEADS_VARIANCE_SAMPLE_RENT_OR_OWN_ASC',
  LeadsVarianceSampleRentOrOwnDesc = 'LEADS_VARIANCE_SAMPLE_RENT_OR_OWN_DESC',
  LeadsVarianceSampleEmploymentStatusAsc = 'LEADS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LeadsVarianceSampleEmploymentStatusDesc = 'LEADS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LeadsVarianceSampleIncomeAmountAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_AMOUNT_ASC',
  LeadsVarianceSampleIncomeAmountDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_AMOUNT_DESC',
  LeadsVarianceSampleMonthlyHomePaymentAsc = 'LEADS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LeadsVarianceSampleMonthlyHomePaymentDesc = 'LEADS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LeadsVarianceSampleSmsConsentAsc = 'LEADS_VARIANCE_SAMPLE_SMS_CONSENT_ASC',
  LeadsVarianceSampleSmsConsentDesc = 'LEADS_VARIANCE_SAMPLE_SMS_CONSENT_DESC',
  LeadsVarianceSampleIncomeTimeYearsAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_YEARS_ASC',
  LeadsVarianceSampleIncomeTimeYearsDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_YEARS_DESC',
  LeadsVarianceSampleIncomeTimeMonthsAsc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_MONTHS_ASC',
  LeadsVarianceSampleIncomeTimeMonthsDesc = 'LEADS_VARIANCE_SAMPLE_INCOME_TIME_MONTHS_DESC',
  LeadsVarianceSampleResidenceTimeYearsAsc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_YEARS_ASC',
  LeadsVarianceSampleResidenceTimeYearsDesc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_YEARS_DESC',
  LeadsVarianceSampleResidenceTimeMonthsAsc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_MONTHS_ASC',
  LeadsVarianceSampleResidenceTimeMonthsDesc = 'LEADS_VARIANCE_SAMPLE_RESIDENCE_TIME_MONTHS_DESC',
  LeadsVariancePopulationIdAsc = 'LEADS_VARIANCE_POPULATION_ID_ASC',
  LeadsVariancePopulationIdDesc = 'LEADS_VARIANCE_POPULATION_ID_DESC',
  LeadsVariancePopulationCompanyIdAsc = 'LEADS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  LeadsVariancePopulationCompanyIdDesc = 'LEADS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  LeadsVariancePopulationCreatedAtAsc = 'LEADS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LeadsVariancePopulationCreatedAtDesc = 'LEADS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LeadsVariancePopulationFirstNameAsc = 'LEADS_VARIANCE_POPULATION_FIRST_NAME_ASC',
  LeadsVariancePopulationFirstNameDesc = 'LEADS_VARIANCE_POPULATION_FIRST_NAME_DESC',
  LeadsVariancePopulationLastNameAsc = 'LEADS_VARIANCE_POPULATION_LAST_NAME_ASC',
  LeadsVariancePopulationLastNameDesc = 'LEADS_VARIANCE_POPULATION_LAST_NAME_DESC',
  LeadsVariancePopulationDateOfBirthAsc = 'LEADS_VARIANCE_POPULATION_DATE_OF_BIRTH_ASC',
  LeadsVariancePopulationDateOfBirthDesc = 'LEADS_VARIANCE_POPULATION_DATE_OF_BIRTH_DESC',
  LeadsVariancePopulationStreetAsc = 'LEADS_VARIANCE_POPULATION_STREET_ASC',
  LeadsVariancePopulationStreetDesc = 'LEADS_VARIANCE_POPULATION_STREET_DESC',
  LeadsVariancePopulationStreetNumberAsc = 'LEADS_VARIANCE_POPULATION_STREET_NUMBER_ASC',
  LeadsVariancePopulationStreetNumberDesc = 'LEADS_VARIANCE_POPULATION_STREET_NUMBER_DESC',
  LeadsVariancePopulationUnitAsc = 'LEADS_VARIANCE_POPULATION_UNIT_ASC',
  LeadsVariancePopulationUnitDesc = 'LEADS_VARIANCE_POPULATION_UNIT_DESC',
  LeadsVariancePopulationCityAsc = 'LEADS_VARIANCE_POPULATION_CITY_ASC',
  LeadsVariancePopulationCityDesc = 'LEADS_VARIANCE_POPULATION_CITY_DESC',
  LeadsVariancePopulationCountryAsc = 'LEADS_VARIANCE_POPULATION_COUNTRY_ASC',
  LeadsVariancePopulationCountryDesc = 'LEADS_VARIANCE_POPULATION_COUNTRY_DESC',
  LeadsVariancePopulationPostalCodeAsc = 'LEADS_VARIANCE_POPULATION_POSTAL_CODE_ASC',
  LeadsVariancePopulationPostalCodeDesc = 'LEADS_VARIANCE_POPULATION_POSTAL_CODE_DESC',
  LeadsVariancePopulationProvinceCodeAsc = 'LEADS_VARIANCE_POPULATION_PROVINCE_CODE_ASC',
  LeadsVariancePopulationProvinceCodeDesc = 'LEADS_VARIANCE_POPULATION_PROVINCE_CODE_DESC',
  LeadsVariancePopulationEmailAddressAsc = 'LEADS_VARIANCE_POPULATION_EMAIL_ADDRESS_ASC',
  LeadsVariancePopulationEmailAddressDesc = 'LEADS_VARIANCE_POPULATION_EMAIL_ADDRESS_DESC',
  LeadsVariancePopulationPhoneNumberAsc = 'LEADS_VARIANCE_POPULATION_PHONE_NUMBER_ASC',
  LeadsVariancePopulationPhoneNumberDesc = 'LEADS_VARIANCE_POPULATION_PHONE_NUMBER_DESC',
  LeadsVariancePopulationSourceUrlAsc = 'LEADS_VARIANCE_POPULATION_SOURCE_URL_ASC',
  LeadsVariancePopulationSourceUrlDesc = 'LEADS_VARIANCE_POPULATION_SOURCE_URL_DESC',
  LeadsVariancePopulationTransactionKeyAsc = 'LEADS_VARIANCE_POPULATION_TRANSACTION_KEY_ASC',
  LeadsVariancePopulationTransactionKeyDesc = 'LEADS_VARIANCE_POPULATION_TRANSACTION_KEY_DESC',
  LeadsVariancePopulationLeadspediaIdAsc = 'LEADS_VARIANCE_POPULATION_LEADSPEDIA_ID_ASC',
  LeadsVariancePopulationLeadspediaIdDesc = 'LEADS_VARIANCE_POPULATION_LEADSPEDIA_ID_DESC',
  LeadsVariancePopulationUpdatedAtAsc = 'LEADS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LeadsVariancePopulationUpdatedAtDesc = 'LEADS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LeadsVariancePopulationSearchTextAsc = 'LEADS_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  LeadsVariancePopulationSearchTextDesc = 'LEADS_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  LeadsVariancePopulationCommentAsc = 'LEADS_VARIANCE_POPULATION_COMMENT_ASC',
  LeadsVariancePopulationCommentDesc = 'LEADS_VARIANCE_POPULATION_COMMENT_DESC',
  LeadsVariancePopulationStateAsc = 'LEADS_VARIANCE_POPULATION_STATE_ASC',
  LeadsVariancePopulationStateDesc = 'LEADS_VARIANCE_POPULATION_STATE_DESC',
  LeadsVariancePopulationReadAsc = 'LEADS_VARIANCE_POPULATION_READ_ASC',
  LeadsVariancePopulationReadDesc = 'LEADS_VARIANCE_POPULATION_READ_DESC',
  LeadsVariancePopulationExternalIdsAsc = 'LEADS_VARIANCE_POPULATION_EXTERNAL_IDS_ASC',
  LeadsVariancePopulationExternalIdsDesc = 'LEADS_VARIANCE_POPULATION_EXTERNAL_IDS_DESC',
  LeadsVariancePopulationMetaAsc = 'LEADS_VARIANCE_POPULATION_META_ASC',
  LeadsVariancePopulationMetaDesc = 'LEADS_VARIANCE_POPULATION_META_DESC',
  LeadsVariancePopulationAdditionalInformationAsc = 'LEADS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_ASC',
  LeadsVariancePopulationAdditionalInformationDesc = 'LEADS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_DESC',
  LeadsVariancePopulationPhoneVerifiedAtAsc = 'LEADS_VARIANCE_POPULATION_PHONE_VERIFIED_AT_ASC',
  LeadsVariancePopulationPhoneVerifiedAtDesc = 'LEADS_VARIANCE_POPULATION_PHONE_VERIFIED_AT_DESC',
  LeadsVariancePopulationIpAddressAsc = 'LEADS_VARIANCE_POPULATION_IP_ADDRESS_ASC',
  LeadsVariancePopulationIpAddressDesc = 'LEADS_VARIANCE_POPULATION_IP_ADDRESS_DESC',
  LeadsVariancePopulationClientIdAsc = 'LEADS_VARIANCE_POPULATION_CLIENT_ID_ASC',
  LeadsVariancePopulationClientIdDesc = 'LEADS_VARIANCE_POPULATION_CLIENT_ID_DESC',
  LeadsVariancePopulationDeletedAsc = 'LEADS_VARIANCE_POPULATION_DELETED_ASC',
  LeadsVariancePopulationDeletedDesc = 'LEADS_VARIANCE_POPULATION_DELETED_DESC',
  LeadsVariancePopulationPriorityAsc = 'LEADS_VARIANCE_POPULATION_PRIORITY_ASC',
  LeadsVariancePopulationPriorityDesc = 'LEADS_VARIANCE_POPULATION_PRIORITY_DESC',
  LeadsVariancePopulationAssigneeAsc = 'LEADS_VARIANCE_POPULATION_ASSIGNEE_ASC',
  LeadsVariancePopulationAssigneeDesc = 'LEADS_VARIANCE_POPULATION_ASSIGNEE_DESC',
  LeadsVariancePopulationShortIdAsc = 'LEADS_VARIANCE_POPULATION_SHORT_ID_ASC',
  LeadsVariancePopulationShortIdDesc = 'LEADS_VARIANCE_POPULATION_SHORT_ID_DESC',
  LeadsVariancePopulationCompanyChannelIdAsc = 'LEADS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  LeadsVariancePopulationCompanyChannelIdDesc = 'LEADS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  LeadsVariancePopulationNeighborhoodViewIdAsc = 'LEADS_VARIANCE_POPULATION_NEIGHBORHOOD_VIEW_ID_ASC',
  LeadsVariancePopulationNeighborhoodViewIdDesc = 'LEADS_VARIANCE_POPULATION_NEIGHBORHOOD_VIEW_ID_DESC',
  LeadsVariancePopulationMaritalStatusAsc = 'LEADS_VARIANCE_POPULATION_MARITAL_STATUS_ASC',
  LeadsVariancePopulationMaritalStatusDesc = 'LEADS_VARIANCE_POPULATION_MARITAL_STATUS_DESC',
  LeadsVariancePopulationDesiredVehicleAsc = 'LEADS_VARIANCE_POPULATION_DESIRED_VEHICLE_ASC',
  LeadsVariancePopulationDesiredVehicleDesc = 'LEADS_VARIANCE_POPULATION_DESIRED_VEHICLE_DESC',
  LeadsVariancePopulationJobTitleAsc = 'LEADS_VARIANCE_POPULATION_JOB_TITLE_ASC',
  LeadsVariancePopulationJobTitleDesc = 'LEADS_VARIANCE_POPULATION_JOB_TITLE_DESC',
  LeadsVariancePopulationIncomePeriodAsc = 'LEADS_VARIANCE_POPULATION_INCOME_PERIOD_ASC',
  LeadsVariancePopulationIncomePeriodDesc = 'LEADS_VARIANCE_POPULATION_INCOME_PERIOD_DESC',
  LeadsVariancePopulationSalaryHourlyAsc = 'LEADS_VARIANCE_POPULATION_SALARY_HOURLY_ASC',
  LeadsVariancePopulationSalaryHourlyDesc = 'LEADS_VARIANCE_POPULATION_SALARY_HOURLY_DESC',
  LeadsVariancePopulationIncomeSourceAsc = 'LEADS_VARIANCE_POPULATION_INCOME_SOURCE_ASC',
  LeadsVariancePopulationIncomeSourceDesc = 'LEADS_VARIANCE_POPULATION_INCOME_SOURCE_DESC',
  LeadsVariancePopulationIncomeTimeAsc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_ASC',
  LeadsVariancePopulationIncomeTimeDesc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_DESC',
  LeadsVariancePopulationPreferredContactMethodAsc = 'LEADS_VARIANCE_POPULATION_PREFERRED_CONTACT_METHOD_ASC',
  LeadsVariancePopulationPreferredContactMethodDesc = 'LEADS_VARIANCE_POPULATION_PREFERRED_CONTACT_METHOD_DESC',
  LeadsVariancePopulationCompanyNameAsc = 'LEADS_VARIANCE_POPULATION_COMPANY_NAME_ASC',
  LeadsVariancePopulationCompanyNameDesc = 'LEADS_VARIANCE_POPULATION_COMPANY_NAME_DESC',
  LeadsVariancePopulationCreditConcernAsc = 'LEADS_VARIANCE_POPULATION_CREDIT_CONCERN_ASC',
  LeadsVariancePopulationCreditConcernDesc = 'LEADS_VARIANCE_POPULATION_CREDIT_CONCERN_DESC',
  LeadsVariancePopulationResidenceTimeAsc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_ASC',
  LeadsVariancePopulationResidenceTimeDesc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_DESC',
  LeadsVariancePopulationRentOrOwnAsc = 'LEADS_VARIANCE_POPULATION_RENT_OR_OWN_ASC',
  LeadsVariancePopulationRentOrOwnDesc = 'LEADS_VARIANCE_POPULATION_RENT_OR_OWN_DESC',
  LeadsVariancePopulationEmploymentStatusAsc = 'LEADS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_ASC',
  LeadsVariancePopulationEmploymentStatusDesc = 'LEADS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_DESC',
  LeadsVariancePopulationIncomeAmountAsc = 'LEADS_VARIANCE_POPULATION_INCOME_AMOUNT_ASC',
  LeadsVariancePopulationIncomeAmountDesc = 'LEADS_VARIANCE_POPULATION_INCOME_AMOUNT_DESC',
  LeadsVariancePopulationMonthlyHomePaymentAsc = 'LEADS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LeadsVariancePopulationMonthlyHomePaymentDesc = 'LEADS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LeadsVariancePopulationSmsConsentAsc = 'LEADS_VARIANCE_POPULATION_SMS_CONSENT_ASC',
  LeadsVariancePopulationSmsConsentDesc = 'LEADS_VARIANCE_POPULATION_SMS_CONSENT_DESC',
  LeadsVariancePopulationIncomeTimeYearsAsc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_YEARS_ASC',
  LeadsVariancePopulationIncomeTimeYearsDesc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_YEARS_DESC',
  LeadsVariancePopulationIncomeTimeMonthsAsc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_MONTHS_ASC',
  LeadsVariancePopulationIncomeTimeMonthsDesc = 'LEADS_VARIANCE_POPULATION_INCOME_TIME_MONTHS_DESC',
  LeadsVariancePopulationResidenceTimeYearsAsc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_YEARS_ASC',
  LeadsVariancePopulationResidenceTimeYearsDesc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_YEARS_DESC',
  LeadsVariancePopulationResidenceTimeMonthsAsc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_MONTHS_ASC',
  LeadsVariancePopulationResidenceTimeMonthsDesc = 'LEADS_VARIANCE_POPULATION_RESIDENCE_TIME_MONTHS_DESC'
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type NotificationResponse = {
  leadId: Scalars['UUID'];
  success: Scalars['Boolean'];
};

export type Option = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  fieldConfigId?: Maybe<Scalars['Int']>;
  display: Scalars['String'];
  value: Scalars['String'];
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `FieldConfig` that is related to this `Option`. */
  fieldConfig?: Maybe<FieldConfig>;
};

export type OptionAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<OptionSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OptionDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<OptionMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<OptionMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<OptionAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<OptionStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<OptionStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<OptionVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<OptionVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Option` object types. */
export type OptionAggregatesFilter = {
  /** A filter that must pass for the relevant `Option` object to be included within the aggregate. */
  filter?: Maybe<OptionFilter>;
  /** Sum aggregate over matching `Option` objects. */
  sum?: Maybe<OptionSumAggregateFilter>;
  /** Distinct count aggregate over matching `Option` objects. */
  distinctCount?: Maybe<OptionDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Option` objects. */
  min?: Maybe<OptionMinAggregateFilter>;
  /** Maximum aggregate over matching `Option` objects. */
  max?: Maybe<OptionMaxAggregateFilter>;
  /** Mean average aggregate over matching `Option` objects. */
  average?: Maybe<OptionAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Option` objects. */
  stddevSample?: Maybe<OptionStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Option` objects. */
  stddevPopulation?: Maybe<OptionStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Option` objects. */
  varianceSample?: Maybe<OptionVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Option` objects. */
  variancePopulation?: Maybe<OptionVariancePopulationAggregateFilter>;
};

export type OptionAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldConfigId?: Maybe<BigFloatFilter>;
};

export type OptionAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Option` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type OptionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `fieldConfigId` field. */
  fieldConfigId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `display` field. */
  display?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type OptionDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  fieldConfigId?: Maybe<BigIntFilter>;
  display?: Maybe<BigIntFilter>;
  value?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type OptionDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of display across the matching connection */
  display?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The `fieldConfig` to be created by this mutation. */
export type OptionFieldConfigIdFkeyFieldConfigCreateInput = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  userEditable?: Maybe<Scalars['Boolean']>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** Input for the nested mutation of `fieldConfig` in the `OptionInput` mutation. */
export type OptionFieldConfigIdFkeyInput = {
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectById?: Maybe<FieldConfigFieldConfigPkeyConnect>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectByParentIdAndParentTypeAndKey?: Maybe<FieldConfigUniqueParentIdParentTypeKeyConnect>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectByNodeId?: Maybe<FieldConfigNodeIdConnect>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteById?: Maybe<FieldConfigFieldConfigPkeyDelete>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteByParentIdAndParentTypeAndKey?: Maybe<FieldConfigUniqueParentIdParentTypeKeyDelete>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FieldConfigNodeIdDelete>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateById?: Maybe<FieldConfigOnOptionForOptionFieldConfigIdFkeyUsingFieldConfigPkeyUpdate>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateByParentIdAndParentTypeAndKey?: Maybe<FieldConfigOnOptionForOptionFieldConfigIdFkeyUsingUniqueParentIdParentTypeKeyUpdate>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateByNodeId?: Maybe<OptionOnOptionForOptionFieldConfigIdFkeyNodeIdUpdate>;
  /** A `FieldConfigInput` object that will be created and connected to this object. */
  create?: Maybe<OptionFieldConfigIdFkeyFieldConfigCreateInput>;
};

/** Input for the nested mutation of `option` in the `FieldConfigInput` mutation. */
export type OptionFieldConfigIdFkeyInverseInput = {
  /** Flag indicating whether all other `option` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `option` for the far side of the relationship. */
  connectById?: Maybe<Array<OptionOptionPkeyConnect>>;
  /** The primary key(s) for `option` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<OptionNodeIdConnect>>;
  /** The primary key(s) for `option` for the far side of the relationship. */
  deleteById?: Maybe<Array<OptionOptionPkeyDelete>>;
  /** The primary key(s) for `option` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<OptionNodeIdDelete>>;
  /** The primary key(s) and patch data for `option` for the far side of the relationship. */
  updateById?: Maybe<Array<OptionOnOptionForOptionFieldConfigIdFkeyUsingOptionPkeyUpdate>>;
  /** The primary key(s) and patch data for `option` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FieldConfigOnOptionForOptionFieldConfigIdFkeyNodeIdUpdate>>;
  /** A `OptionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<OptionFieldConfigIdFkeyOptionCreateInput>>;
};

/** The `option` to be created by this mutation. */
export type OptionFieldConfigIdFkeyOptionCreateInput = {
  id?: Maybe<Scalars['Int']>;
  fieldConfigId?: Maybe<Scalars['Int']>;
  display: Scalars['String'];
  value: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  fieldConfigToFieldConfigId?: Maybe<OptionFieldConfigIdFkeyInput>;
};

/** A filter to be used against `Option` object types. All fields are combined with a logical ‘and.’ */
export type OptionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `fieldConfigId` field. */
  fieldConfigId?: Maybe<IntFilter>;
  /** Filter by the object’s `display` field. */
  display?: Maybe<StringFilter>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `fieldConfig` relation. */
  fieldConfig?: Maybe<FieldConfigFilter>;
  /** A related `fieldConfig` exists. */
  fieldConfigExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<OptionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<OptionFilter>>;
  /** Negates the expression. */
  not?: Maybe<OptionFilter>;
};

/** Grouping methods for `Option` for usage during aggregation. */
export enum OptionGroupBy {
  FieldConfigId = 'FIELD_CONFIG_ID',
  Display = 'DISPLAY',
  Value = 'VALUE',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type OptionHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Option` aggregates. */
export type OptionHavingInput = {
  AND?: Maybe<Array<OptionHavingInput>>;
  OR?: Maybe<Array<OptionHavingInput>>;
  sum?: Maybe<OptionHavingSumInput>;
  distinctCount?: Maybe<OptionHavingDistinctCountInput>;
  min?: Maybe<OptionHavingMinInput>;
  max?: Maybe<OptionHavingMaxInput>;
  average?: Maybe<OptionHavingAverageInput>;
  stddevSample?: Maybe<OptionHavingStddevSampleInput>;
  stddevPopulation?: Maybe<OptionHavingStddevPopulationInput>;
  varianceSample?: Maybe<OptionHavingVarianceSampleInput>;
  variancePopulation?: Maybe<OptionHavingVariancePopulationInput>;
};

export type OptionHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Option` */
export type OptionInput = {
  id?: Maybe<Scalars['Int']>;
  fieldConfigId?: Maybe<Scalars['Int']>;
  display: Scalars['String'];
  value: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  fieldConfigToFieldConfigId?: Maybe<OptionFieldConfigIdFkeyInput>;
};

export type OptionMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  fieldConfigId?: Maybe<IntFilter>;
};

export type OptionMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['Int']>;
};

export type OptionMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  fieldConfigId?: Maybe<IntFilter>;
};

export type OptionMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type OptionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `option` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type OptionNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `option` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type OptionOnOptionForOptionFieldConfigIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `fieldConfig` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: FieldConfigPatch;
};

/** The fields on `option` to look up the row to update. */
export type OptionOnOptionForOptionFieldConfigIdFkeyUsingOptionPkeyUpdate = {
  /** An object where the defined keys will be set on the `option` being updated. */
  patch: UpdateOptionOnOptionForOptionFieldConfigIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `option` to look up the row to connect. */
export type OptionOptionPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `option` to look up the row to delete. */
export type OptionOptionPkeyDelete = {
  id: Scalars['Int'];
};

/** Represents an update to a `Option`. Fields that are set will be updated. */
export type OptionPatch = {
  id?: Maybe<Scalars['Int']>;
  fieldConfigId?: Maybe<Scalars['Int']>;
  display?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  fieldConfigToFieldConfigId?: Maybe<OptionFieldConfigIdFkeyInput>;
};

export type OptionStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldConfigId?: Maybe<BigFloatFilter>;
};

export type OptionStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigFloat']>;
};

export type OptionStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldConfigId?: Maybe<BigFloatFilter>;
};

export type OptionStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigFloat']>;
};

export type OptionSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  fieldConfigId?: Maybe<BigIntFilter>;
};

export type OptionSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of fieldConfigId across the matching connection */
  fieldConfigId: Scalars['BigInt'];
};

export type OptionVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldConfigId?: Maybe<BigFloatFilter>;
};

export type OptionVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigFloat']>;
};

export type OptionVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldConfigId?: Maybe<BigFloatFilter>;
};

export type OptionVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Option` values. */
export type OptionsConnection = {
  /** A list of `Option` objects. */
  nodes: Array<Option>;
  /** A list of edges which contains the `Option` and cursor to aid in pagination. */
  edges: Array<OptionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Option` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OptionAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OptionAggregates>>;
};


/** A connection to a list of `Option` values. */
export type OptionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<OptionGroupBy>;
  having?: Maybe<OptionHavingInput>;
};

/** A `Option` edge in the connection. */
export type OptionsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Option` at the end of the edge. */
  node: Option;
};

/** Methods to use when ordering `Option`. */
export enum OptionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  FieldConfigIdAsc = 'FIELD_CONFIG_ID_ASC',
  FieldConfigIdDesc = 'FIELD_CONFIG_ID_DESC',
  DisplayAsc = 'DISPLAY_ASC',
  DisplayDesc = 'DISPLAY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type OverviewResponse = {
  creditScoreAverage?: Maybe<Scalars['Float']>;
  sources?: Maybe<Array<Maybe<SourceUrlLeadCount>>>;
  tradeSources?: Maybe<Array<Maybe<SourceUrlLeadCount>>>;
  creditSources?: Maybe<Array<Maybe<SourceUrlLeadCount>>>;
  allApplications?: Maybe<Scalars['Int']>;
  openedApplications?: Maybe<Scalars['Int']>;
  creditApplications?: Maybe<Scalars['Int']>;
  tradeApplications?: Maybe<Scalars['Int']>;
  openedCreditApplications?: Maybe<Scalars['Int']>;
  openedTradeApplications?: Maybe<Scalars['Int']>;
  nonVerifiedCreditApplications?: Maybe<Scalars['Int']>;
  driveBookings?: Maybe<Scalars['Int']>;
  idApplications?: Maybe<Scalars['Int']>;
  fullAppApplications?: Maybe<Scalars['Int']>;
  newStatusApplications?: Maybe<Scalars['Int']>;
  workingStatusApplications?: Maybe<Scalars['Int']>;
  soldStatusApplications?: Maybe<Scalars['Int']>;
  contactedStatusApplications?: Maybe<Scalars['Int']>;
  appointmentBookedStatusApplications?: Maybe<Scalars['Int']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

export type PartialNeighborhoodView = {
  postalCode: Scalars['String'];
  equifaxRiskScore: Scalars['Float'];
  street?: Maybe<Scalars['String']>;
};

export type PartialTradeApp = {
  condition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  make?: Maybe<Scalars['String']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  mileage?: Maybe<Scalars['Int']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  tradeInValue?: Maybe<Scalars['Float']>;
  trim?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type PaymentCard = {
  brand?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
};

export type PaymentProvider = {
  provider: Scalars['String'];
  cards: Array<PaymentCard>;
  newCardSecret: Scalars['String'];
};

export enum Permissions {
  ManageUsers = 'MANAGE_USERS',
  ViewLeads = 'VIEW_LEADS',
  Admin = 'ADMIN',
  ManageDeliveries = 'MANAGE_DELIVERIES'
}

export type Product = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  displayName: Scalars['String'];
  name: ProductType;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProducts: CompanyProductsConnection;
  /** Reads and enables pagination through a set of `LeadJourney`. */
  leadJourneys: LeadJourneysConnection;
  /** Reads and enables pagination through a set of `ProductIntegration`. */
  productIntegrations: ProductIntegrationsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyProductProductIdAndCompanyId: ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByLeadJourneyProductIdAndLeadId: ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Integration`. */
  integrationsByProductIntegrationProductIdAndIntegrationId: ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyConnection;
  fieldConfigs?: Maybe<FieldConfigsConnection>;
};


export type ProductCompanyProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type ProductLeadJourneysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
  condition?: Maybe<LeadJourneyCondition>;
  filter?: Maybe<LeadJourneyFilter>;
};


export type ProductProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
  condition?: Maybe<ProductIntegrationCondition>;
  filter?: Maybe<ProductIntegrationFilter>;
};


export type ProductCompaniesByCompanyProductProductIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type ProductLeadsByLeadJourneyProductIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
  condition?: Maybe<IntegrationCondition>;
  filter?: Maybe<IntegrationFilter>;
};


export type ProductFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

export type ProductAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ProductSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProductDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ProductMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ProductMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ProductAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ProductStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ProductStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ProductVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ProductVariancePopulationAggregates>;
};

export type ProductAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Company` values, with data from `CompanyProduct`. */
export type ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyProduct`, and the cursor to aid in pagination. */
  edges: Array<ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyProduct`. */
export type ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyProduct`. */
export type ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProducts: CompanyProductsConnection;
};


/** A `Company` edge in the connection, with data from `CompanyProduct`. */
export type ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyEdgeCompanyProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};

/** A condition to be used against `Product` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProductCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<ProductType>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type ProductDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Product` object types. All fields are combined with a logical ‘and.’ */
export type ProductFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<ProductTypeFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `companyProducts` relation. */
  companyProducts?: Maybe<ProductToManyCompanyProductFilter>;
  /** Some related `companyProducts` exist. */
  companyProductsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `leadJourneys` relation. */
  leadJourneys?: Maybe<ProductToManyLeadJourneyFilter>;
  /** Some related `leadJourneys` exist. */
  leadJourneysExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `productIntegrations` relation. */
  productIntegrations?: Maybe<ProductToManyProductIntegrationFilter>;
  /** Some related `productIntegrations` exist. */
  productIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ProductFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ProductFilter>>;
  /** Negates the expression. */
  not?: Maybe<ProductFilter>;
};

/** Grouping methods for `Product` for usage during aggregation. */
export enum ProductGroupBy {
  DisplayName = 'DISPLAY_NAME',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type ProductHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Product` aggregates. */
export type ProductHavingInput = {
  AND?: Maybe<Array<ProductHavingInput>>;
  OR?: Maybe<Array<ProductHavingInput>>;
  sum?: Maybe<ProductHavingSumInput>;
  distinctCount?: Maybe<ProductHavingDistinctCountInput>;
  min?: Maybe<ProductHavingMinInput>;
  max?: Maybe<ProductHavingMaxInput>;
  average?: Maybe<ProductHavingAverageInput>;
  stddevSample?: Maybe<ProductHavingStddevSampleInput>;
  stddevPopulation?: Maybe<ProductHavingStddevPopulationInput>;
  varianceSample?: Maybe<ProductHavingVarianceSampleInput>;
  variancePopulation?: Maybe<ProductHavingVariancePopulationInput>;
};

export type ProductHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegration = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  integrationId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Integration` that is related to this `ProductIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Product` that is related to this `ProductIntegration`. */
  product?: Maybe<Product>;
};

export type ProductIntegrationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ProductIntegrationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProductIntegrationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ProductIntegrationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ProductIntegrationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ProductIntegrationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ProductIntegrationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ProductIntegrationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ProductIntegrationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ProductIntegrationVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `ProductIntegration` object types. */
export type ProductIntegrationAggregatesFilter = {
  /** A filter that must pass for the relevant `ProductIntegration` object to be included within the aggregate. */
  filter?: Maybe<ProductIntegrationFilter>;
  /** Sum aggregate over matching `ProductIntegration` objects. */
  sum?: Maybe<ProductIntegrationSumAggregateFilter>;
  /** Distinct count aggregate over matching `ProductIntegration` objects. */
  distinctCount?: Maybe<ProductIntegrationDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `ProductIntegration` objects. */
  min?: Maybe<ProductIntegrationMinAggregateFilter>;
  /** Maximum aggregate over matching `ProductIntegration` objects. */
  max?: Maybe<ProductIntegrationMaxAggregateFilter>;
  /** Mean average aggregate over matching `ProductIntegration` objects. */
  average?: Maybe<ProductIntegrationAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProductIntegration` objects. */
  stddevSample?: Maybe<ProductIntegrationStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProductIntegration` objects. */
  stddevPopulation?: Maybe<ProductIntegrationStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProductIntegration` objects. */
  varianceSample?: Maybe<ProductIntegrationVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `ProductIntegration` objects. */
  variancePopulation?: Maybe<ProductIntegrationVariancePopulationAggregateFilter>;
};

export type ProductIntegrationAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
};

export type ProductIntegrationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ProductIntegration` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProductIntegrationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type ProductIntegrationDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type ProductIntegrationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of productId across the matching connection */
  productId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `ProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type ProductIntegrationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `integrationId` field. */
  integrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `integration` relation. */
  integration?: Maybe<IntegrationFilter>;
  /** A related `integration` exists. */
  integrationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `product` relation. */
  product?: Maybe<ProductFilter>;
  /** A related `product` exists. */
  productExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ProductIntegrationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ProductIntegrationFilter>>;
  /** Negates the expression. */
  not?: Maybe<ProductIntegrationFilter>;
};

/** Grouping methods for `ProductIntegration` for usage during aggregation. */
export enum ProductIntegrationGroupBy {
  IntegrationId = 'INTEGRATION_ID',
  ProductId = 'PRODUCT_ID',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type ProductIntegrationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `ProductIntegration` aggregates. */
export type ProductIntegrationHavingInput = {
  AND?: Maybe<Array<ProductIntegrationHavingInput>>;
  OR?: Maybe<Array<ProductIntegrationHavingInput>>;
  sum?: Maybe<ProductIntegrationHavingSumInput>;
  distinctCount?: Maybe<ProductIntegrationHavingDistinctCountInput>;
  min?: Maybe<ProductIntegrationHavingMinInput>;
  max?: Maybe<ProductIntegrationHavingMaxInput>;
  average?: Maybe<ProductIntegrationHavingAverageInput>;
  stddevSample?: Maybe<ProductIntegrationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<ProductIntegrationHavingStddevPopulationInput>;
  varianceSample?: Maybe<ProductIntegrationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<ProductIntegrationHavingVariancePopulationInput>;
};

export type ProductIntegrationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `ProductIntegration` */
export type ProductIntegrationInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

/** Input for the nested mutation of `integration` in the `ProductIntegrationInput` mutation. */
export type ProductIntegrationIntegrationIdFkeyInput = {
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectById?: Maybe<IntegrationIntegrationPkeyConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectByName?: Maybe<IntegrationIntegrationNameUniqueConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectByNodeId?: Maybe<IntegrationNodeIdConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteById?: Maybe<IntegrationIntegrationPkeyDelete>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteByName?: Maybe<IntegrationIntegrationNameUniqueDelete>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateById?: Maybe<IntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyUsingIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateByName?: Maybe<IntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyUsingIntegrationNameUniqueUpdate>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateByNodeId?: Maybe<ProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyNodeIdUpdate>;
  /** A `IntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<ProductIntegrationIntegrationIdFkeyIntegrationCreateInput>;
};

/** The `integration` to be created by this mutation. */
export type ProductIntegrationIntegrationIdFkeyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `productIntegration` in the `IntegrationInput` mutation. */
export type ProductIntegrationIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `productIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<ProductIntegrationProductIntegrationPkeyConnect>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ProductIntegrationNodeIdConnect>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<ProductIntegrationProductIntegrationPkeyDelete>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ProductIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `productIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<ProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyUsingProductIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `productIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<IntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyNodeIdUpdate>>;
  /** A `ProductIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ProductIntegrationIntegrationIdFkeyProductIntegrationCreateInput>>;
};

/** The `productIntegration` to be created by this mutation. */
export type ProductIntegrationIntegrationIdFkeyProductIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

export type ProductIntegrationMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
  productId?: Maybe<IntFilter>;
};

export type ProductIntegrationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
  /** Maximum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
};

export type ProductIntegrationMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
  productId?: Maybe<IntFilter>;
};

export type ProductIntegrationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
  /** Minimum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProductIntegrationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `productIntegration` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ProductIntegrationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `productIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `integration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: IntegrationPatch;
};

/** The fields on `productIntegration` to look up the row to update. */
export type ProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyUsingProductIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `productIntegration` being updated. */
  patch: UpdateProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** The fields on `productIntegration` to look up the row to update. */
export type ProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `productIntegration` being updated. */
  patch: UpdateProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `ProductIntegration`. Fields that are set will be updated. */
export type ProductIntegrationPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

/** Input for the nested mutation of `product` in the `ProductIntegrationInput` mutation. */
export type ProductIntegrationProductIdFkeyInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectById?: Maybe<ProductProductPkeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByName?: Maybe<ProductProductNameKeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: Maybe<ProductNodeIdConnect>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateById?: Maybe<ProductOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductPkeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByName?: Maybe<ProductOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductNameKeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: Maybe<ProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `productIntegration` in the `ProductInput` mutation. */
export type ProductIntegrationProductIdFkeyInverseInput = {
  /** Flag indicating whether all other `productIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<ProductIntegrationProductIntegrationPkeyConnect>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ProductIntegrationNodeIdConnect>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<ProductIntegrationProductIntegrationPkeyDelete>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ProductIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `productIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<ProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `productIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<ProductOnProductIntegrationForProductIntegrationProductIdFkeyNodeIdUpdate>>;
  /** A `ProductIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ProductIntegrationProductIdFkeyProductIntegrationCreateInput>>;
};

/** The `productIntegration` to be created by this mutation. */
export type ProductIntegrationProductIdFkeyProductIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

/** The fields on `productIntegration` to look up the row to connect. */
export type ProductIntegrationProductIntegrationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `productIntegration` to look up the row to delete. */
export type ProductIntegrationProductIntegrationPkeyDelete = {
  id: Scalars['Int'];
};

export type ProductIntegrationStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
};

export type ProductIntegrationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type ProductIntegrationStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
};

export type ProductIntegrationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type ProductIntegrationSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
};

export type ProductIntegrationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of integrationId across the matching connection */
  integrationId: Scalars['BigInt'];
  /** Sum of productId across the matching connection */
  productId: Scalars['BigInt'];
};

export type ProductIntegrationVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
};

export type ProductIntegrationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type ProductIntegrationVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
};

export type ProductIntegrationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Integration` values, with data from `ProductIntegration`. */
export type ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyConnection = {
  /** A list of `Integration` objects. */
  nodes: Array<Integration>;
  /** A list of edges which contains the `Integration`, info from the `ProductIntegration`, and the cursor to aid in pagination. */
  edges: Array<ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Integration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationAggregates>>;
};


/** A connection to a list of `Integration` values, with data from `ProductIntegration`. */
export type ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationGroupBy>;
  having?: Maybe<IntegrationHavingInput>;
};

/** A `Integration` edge in the connection, with data from `ProductIntegration`. */
export type ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Integration` at the end of the edge. */
  node: Integration;
  /** Reads and enables pagination through a set of `ProductIntegration`. */
  productIntegrations: ProductIntegrationsConnection;
};


/** A `Integration` edge in the connection, with data from `ProductIntegration`. */
export type ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyEdgeProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
  condition?: Maybe<ProductIntegrationCondition>;
  filter?: Maybe<ProductIntegrationFilter>;
};

/** A connection to a list of `ProductIntegration` values. */
export type ProductIntegrationsConnection = {
  /** A list of `ProductIntegration` objects. */
  nodes: Array<ProductIntegration>;
  /** A list of edges which contains the `ProductIntegration` and cursor to aid in pagination. */
  edges: Array<ProductIntegrationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProductIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductIntegrationAggregates>>;
};


/** A connection to a list of `ProductIntegration` values. */
export type ProductIntegrationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductIntegrationGroupBy>;
  having?: Maybe<ProductIntegrationHavingInput>;
};

/** A `ProductIntegration` edge in the connection. */
export type ProductIntegrationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProductIntegration` at the end of the edge. */
  node: ProductIntegration;
};

/** Methods to use when ordering `ProductIntegration`. */
export enum ProductIntegrationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ProductLeadCount = {
  productType: ProductType;
  count: Scalars['Int'];
  productDisplayName: Scalars['String'];
};

/** A connection to a list of `Lead` values, with data from `LeadJourney`. */
export type ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `LeadJourney`, and the cursor to aid in pagination. */
  edges: Array<ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `LeadJourney`. */
export type ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `LeadJourney`. */
export type ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `LeadJourney`. */
  leadJourneys: LeadJourneysConnection;
};


/** A `Lead` edge in the connection, with data from `LeadJourney`. */
export type ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyEdgeLeadJourneysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
  condition?: Maybe<LeadJourneyCondition>;
  filter?: Maybe<LeadJourneyFilter>;
};

export type ProductMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type ProductMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProductNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductOnCompanyProductForCompanyProductProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: CompanyProductPatch;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnCompanyProductForCompanyProductProductIdFkeyUsingProductNameKeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnCompanyProductForCompanyProductProductIdFkeyPatch;
  name: ProductType;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnCompanyProductForCompanyProductProductIdFkeyUsingProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnCompanyProductForCompanyProductProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `product` to look up the row to update. */
export type ProductOnLeadJourneyForLeadJourneyProductIdFkeyUsingProductNameKeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnLeadJourneyForLeadJourneyProductIdFkeyPatch;
  name: ProductType;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnLeadJourneyForLeadJourneyProductIdFkeyUsingProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnLeadJourneyForLeadJourneyProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductOnProductIntegrationForProductIntegrationProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `productIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `productIntegration` being updated. */
  patch: ProductIntegrationPatch;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductNameKeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnProductIntegrationForProductIntegrationProductIdFkeyPatch;
  name: ProductType;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnProductIntegrationForProductIntegrationProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Product`. Fields that are set will be updated. */
export type ProductPatch = {
  id?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<ProductType>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyProductsUsingId?: Maybe<CompanyProductProductIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyProductIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationProductIdFkeyInverseInput>;
};

/** The fields on `product` to look up the row to connect. */
export type ProductProductNameKeyConnect = {
  name: ProductType;
};

/** The fields on `product` to look up the row to connect. */
export type ProductProductPkeyConnect = {
  id: Scalars['Int'];
};

export type ProductStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ProductStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ProductSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyProduct` object types. All fields are combined with a logical ‘and.’ */
export type ProductToManyCompanyProductFilter = {
  /** Every related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyProductFilter>;
  /** Some related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyProductFilter>;
  /** No related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyProductFilter>;
  /** Aggregates across related `CompanyProduct` match the filter criteria. */
  aggregates?: Maybe<CompanyProductAggregatesFilter>;
};

/** A filter to be used against many `LeadJourney` object types. All fields are combined with a logical ‘and.’ */
export type ProductToManyLeadJourneyFilter = {
  /** Every related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeadJourneyFilter>;
  /** Some related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeadJourneyFilter>;
  /** No related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeadJourneyFilter>;
  /** Aggregates across related `LeadJourney` match the filter criteria. */
  aggregates?: Maybe<LeadJourneyAggregatesFilter>;
};

/** A filter to be used against many `ProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type ProductToManyProductIntegrationFilter = {
  /** Every related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ProductIntegrationFilter>;
  /** Some related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ProductIntegrationFilter>;
  /** No related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ProductIntegrationFilter>;
  /** Aggregates across related `ProductIntegration` match the filter criteria. */
  aggregates?: Maybe<ProductIntegrationAggregatesFilter>;
};

export enum ProductType {
  CreditTool = 'CREDIT_TOOL',
  TradeTool = 'TRADE_TOOL',
  LenderTool = 'LENDER_TOOL',
  TradePro = 'TRADE_PRO',
  BuyTool = 'BUY_TOOL',
  IdTool = 'ID_TOOL',
  ReviewTool = 'REVIEW_TOOL',
  MsaTool = 'MSA_TOOL',
  DriveTool = 'DRIVE_TOOL',
  IncomeTool = 'INCOME_TOOL',
  NeighborhoodTool = 'NEIGHBORHOOD_TOOL',
  CreditLiteTool = 'CREDIT_LITE_TOOL',
  InventoryTool = 'INVENTORY_TOOL',
  RatesTool = 'RATES_TOOL'
}

/** A filter to be used against ProductType fields. All fields are combined with a logical ‘and.’ */
export type ProductTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ProductType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ProductType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ProductType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ProductType>;
  /** Included in the specified list. */
  in?: Maybe<Array<ProductType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ProductType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ProductType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ProductType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ProductType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ProductType>;
};

export type ProductVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ProductVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Product` values. */
export type ProductsConnection = {
  /** A list of `Product` objects. */
  nodes: Array<Product>;
  /** A list of edges which contains the `Product` and cursor to aid in pagination. */
  edges: Array<ProductsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductAggregates>>;
};


/** A connection to a list of `Product` values. */
export type ProductsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductGroupBy>;
  having?: Maybe<ProductHavingInput>;
};

/** A `Product` edge in the connection. */
export type ProductsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node: Product;
};

/** Methods to use when ordering `Product`. */
export enum ProductsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyProductsCountAsc = 'COMPANY_PRODUCTS_COUNT_ASC',
  CompanyProductsCountDesc = 'COMPANY_PRODUCTS_COUNT_DESC',
  CompanyProductsSumCompanyIdAsc = 'COMPANY_PRODUCTS_SUM_COMPANY_ID_ASC',
  CompanyProductsSumCompanyIdDesc = 'COMPANY_PRODUCTS_SUM_COMPANY_ID_DESC',
  CompanyProductsSumProductIdAsc = 'COMPANY_PRODUCTS_SUM_PRODUCT_ID_ASC',
  CompanyProductsSumProductIdDesc = 'COMPANY_PRODUCTS_SUM_PRODUCT_ID_DESC',
  CompanyProductsSumCreatedAtAsc = 'COMPANY_PRODUCTS_SUM_CREATED_AT_ASC',
  CompanyProductsSumCreatedAtDesc = 'COMPANY_PRODUCTS_SUM_CREATED_AT_DESC',
  CompanyProductsSumUpdatedAtAsc = 'COMPANY_PRODUCTS_SUM_UPDATED_AT_ASC',
  CompanyProductsSumUpdatedAtDesc = 'COMPANY_PRODUCTS_SUM_UPDATED_AT_DESC',
  CompanyProductsSumIdAsc = 'COMPANY_PRODUCTS_SUM_ID_ASC',
  CompanyProductsSumIdDesc = 'COMPANY_PRODUCTS_SUM_ID_DESC',
  CompanyProductsSumEnabledAsc = 'COMPANY_PRODUCTS_SUM_ENABLED_ASC',
  CompanyProductsSumEnabledDesc = 'COMPANY_PRODUCTS_SUM_ENABLED_DESC',
  CompanyProductsDistinctCountCompanyIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyProductsDistinctCountCompanyIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyProductsDistinctCountProductIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  CompanyProductsDistinctCountProductIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  CompanyProductsDistinctCountCreatedAtAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyProductsDistinctCountCreatedAtDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyProductsDistinctCountUpdatedAtAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyProductsDistinctCountUpdatedAtDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyProductsDistinctCountIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ID_ASC',
  CompanyProductsDistinctCountIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ID_DESC',
  CompanyProductsDistinctCountEnabledAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyProductsDistinctCountEnabledDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyProductsMinCompanyIdAsc = 'COMPANY_PRODUCTS_MIN_COMPANY_ID_ASC',
  CompanyProductsMinCompanyIdDesc = 'COMPANY_PRODUCTS_MIN_COMPANY_ID_DESC',
  CompanyProductsMinProductIdAsc = 'COMPANY_PRODUCTS_MIN_PRODUCT_ID_ASC',
  CompanyProductsMinProductIdDesc = 'COMPANY_PRODUCTS_MIN_PRODUCT_ID_DESC',
  CompanyProductsMinCreatedAtAsc = 'COMPANY_PRODUCTS_MIN_CREATED_AT_ASC',
  CompanyProductsMinCreatedAtDesc = 'COMPANY_PRODUCTS_MIN_CREATED_AT_DESC',
  CompanyProductsMinUpdatedAtAsc = 'COMPANY_PRODUCTS_MIN_UPDATED_AT_ASC',
  CompanyProductsMinUpdatedAtDesc = 'COMPANY_PRODUCTS_MIN_UPDATED_AT_DESC',
  CompanyProductsMinIdAsc = 'COMPANY_PRODUCTS_MIN_ID_ASC',
  CompanyProductsMinIdDesc = 'COMPANY_PRODUCTS_MIN_ID_DESC',
  CompanyProductsMinEnabledAsc = 'COMPANY_PRODUCTS_MIN_ENABLED_ASC',
  CompanyProductsMinEnabledDesc = 'COMPANY_PRODUCTS_MIN_ENABLED_DESC',
  CompanyProductsMaxCompanyIdAsc = 'COMPANY_PRODUCTS_MAX_COMPANY_ID_ASC',
  CompanyProductsMaxCompanyIdDesc = 'COMPANY_PRODUCTS_MAX_COMPANY_ID_DESC',
  CompanyProductsMaxProductIdAsc = 'COMPANY_PRODUCTS_MAX_PRODUCT_ID_ASC',
  CompanyProductsMaxProductIdDesc = 'COMPANY_PRODUCTS_MAX_PRODUCT_ID_DESC',
  CompanyProductsMaxCreatedAtAsc = 'COMPANY_PRODUCTS_MAX_CREATED_AT_ASC',
  CompanyProductsMaxCreatedAtDesc = 'COMPANY_PRODUCTS_MAX_CREATED_AT_DESC',
  CompanyProductsMaxUpdatedAtAsc = 'COMPANY_PRODUCTS_MAX_UPDATED_AT_ASC',
  CompanyProductsMaxUpdatedAtDesc = 'COMPANY_PRODUCTS_MAX_UPDATED_AT_DESC',
  CompanyProductsMaxIdAsc = 'COMPANY_PRODUCTS_MAX_ID_ASC',
  CompanyProductsMaxIdDesc = 'COMPANY_PRODUCTS_MAX_ID_DESC',
  CompanyProductsMaxEnabledAsc = 'COMPANY_PRODUCTS_MAX_ENABLED_ASC',
  CompanyProductsMaxEnabledDesc = 'COMPANY_PRODUCTS_MAX_ENABLED_DESC',
  CompanyProductsAverageCompanyIdAsc = 'COMPANY_PRODUCTS_AVERAGE_COMPANY_ID_ASC',
  CompanyProductsAverageCompanyIdDesc = 'COMPANY_PRODUCTS_AVERAGE_COMPANY_ID_DESC',
  CompanyProductsAverageProductIdAsc = 'COMPANY_PRODUCTS_AVERAGE_PRODUCT_ID_ASC',
  CompanyProductsAverageProductIdDesc = 'COMPANY_PRODUCTS_AVERAGE_PRODUCT_ID_DESC',
  CompanyProductsAverageCreatedAtAsc = 'COMPANY_PRODUCTS_AVERAGE_CREATED_AT_ASC',
  CompanyProductsAverageCreatedAtDesc = 'COMPANY_PRODUCTS_AVERAGE_CREATED_AT_DESC',
  CompanyProductsAverageUpdatedAtAsc = 'COMPANY_PRODUCTS_AVERAGE_UPDATED_AT_ASC',
  CompanyProductsAverageUpdatedAtDesc = 'COMPANY_PRODUCTS_AVERAGE_UPDATED_AT_DESC',
  CompanyProductsAverageIdAsc = 'COMPANY_PRODUCTS_AVERAGE_ID_ASC',
  CompanyProductsAverageIdDesc = 'COMPANY_PRODUCTS_AVERAGE_ID_DESC',
  CompanyProductsAverageEnabledAsc = 'COMPANY_PRODUCTS_AVERAGE_ENABLED_ASC',
  CompanyProductsAverageEnabledDesc = 'COMPANY_PRODUCTS_AVERAGE_ENABLED_DESC',
  CompanyProductsStddevSampleCompanyIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyProductsStddevSampleCompanyIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyProductsStddevSampleProductIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  CompanyProductsStddevSampleProductIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  CompanyProductsStddevSampleCreatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyProductsStddevSampleCreatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyProductsStddevSampleUpdatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyProductsStddevSampleUpdatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyProductsStddevSampleIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ID_ASC',
  CompanyProductsStddevSampleIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ID_DESC',
  CompanyProductsStddevSampleEnabledAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyProductsStddevSampleEnabledDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyProductsStddevPopulationCompanyIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyProductsStddevPopulationCompanyIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyProductsStddevPopulationProductIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  CompanyProductsStddevPopulationProductIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  CompanyProductsStddevPopulationCreatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyProductsStddevPopulationCreatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyProductsStddevPopulationUpdatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyProductsStddevPopulationUpdatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyProductsStddevPopulationIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ID_ASC',
  CompanyProductsStddevPopulationIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ID_DESC',
  CompanyProductsStddevPopulationEnabledAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyProductsStddevPopulationEnabledDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyProductsVarianceSampleCompanyIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyProductsVarianceSampleCompanyIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyProductsVarianceSampleProductIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  CompanyProductsVarianceSampleProductIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  CompanyProductsVarianceSampleCreatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyProductsVarianceSampleCreatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyProductsVarianceSampleUpdatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyProductsVarianceSampleUpdatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyProductsVarianceSampleIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ID_ASC',
  CompanyProductsVarianceSampleIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ID_DESC',
  CompanyProductsVarianceSampleEnabledAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyProductsVarianceSampleEnabledDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyProductsVariancePopulationCompanyIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyProductsVariancePopulationCompanyIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyProductsVariancePopulationProductIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  CompanyProductsVariancePopulationProductIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  CompanyProductsVariancePopulationCreatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyProductsVariancePopulationCreatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyProductsVariancePopulationUpdatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyProductsVariancePopulationUpdatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyProductsVariancePopulationIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ID_ASC',
  CompanyProductsVariancePopulationIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ID_DESC',
  CompanyProductsVariancePopulationEnabledAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyProductsVariancePopulationEnabledDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ENABLED_DESC',
  LeadJourneysCountAsc = 'LEAD_JOURNEYS_COUNT_ASC',
  LeadJourneysCountDesc = 'LEAD_JOURNEYS_COUNT_DESC',
  LeadJourneysSumLeadIdAsc = 'LEAD_JOURNEYS_SUM_LEAD_ID_ASC',
  LeadJourneysSumLeadIdDesc = 'LEAD_JOURNEYS_SUM_LEAD_ID_DESC',
  LeadJourneysSumStageAsc = 'LEAD_JOURNEYS_SUM_STAGE_ASC',
  LeadJourneysSumStageDesc = 'LEAD_JOURNEYS_SUM_STAGE_DESC',
  LeadJourneysSumProductIdAsc = 'LEAD_JOURNEYS_SUM_PRODUCT_ID_ASC',
  LeadJourneysSumProductIdDesc = 'LEAD_JOURNEYS_SUM_PRODUCT_ID_DESC',
  LeadJourneysSumCreatedAtAsc = 'LEAD_JOURNEYS_SUM_CREATED_AT_ASC',
  LeadJourneysSumCreatedAtDesc = 'LEAD_JOURNEYS_SUM_CREATED_AT_DESC',
  LeadJourneysSumUpdatedAtAsc = 'LEAD_JOURNEYS_SUM_UPDATED_AT_ASC',
  LeadJourneysSumUpdatedAtDesc = 'LEAD_JOURNEYS_SUM_UPDATED_AT_DESC',
  LeadJourneysDistinctCountLeadIdAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_LEAD_ID_ASC',
  LeadJourneysDistinctCountLeadIdDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_LEAD_ID_DESC',
  LeadJourneysDistinctCountStageAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_STAGE_ASC',
  LeadJourneysDistinctCountStageDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_STAGE_DESC',
  LeadJourneysDistinctCountProductIdAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  LeadJourneysDistinctCountProductIdDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  LeadJourneysDistinctCountCreatedAtAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_CREATED_AT_ASC',
  LeadJourneysDistinctCountCreatedAtDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_CREATED_AT_DESC',
  LeadJourneysDistinctCountUpdatedAtAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LeadJourneysDistinctCountUpdatedAtDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LeadJourneysMinLeadIdAsc = 'LEAD_JOURNEYS_MIN_LEAD_ID_ASC',
  LeadJourneysMinLeadIdDesc = 'LEAD_JOURNEYS_MIN_LEAD_ID_DESC',
  LeadJourneysMinStageAsc = 'LEAD_JOURNEYS_MIN_STAGE_ASC',
  LeadJourneysMinStageDesc = 'LEAD_JOURNEYS_MIN_STAGE_DESC',
  LeadJourneysMinProductIdAsc = 'LEAD_JOURNEYS_MIN_PRODUCT_ID_ASC',
  LeadJourneysMinProductIdDesc = 'LEAD_JOURNEYS_MIN_PRODUCT_ID_DESC',
  LeadJourneysMinCreatedAtAsc = 'LEAD_JOURNEYS_MIN_CREATED_AT_ASC',
  LeadJourneysMinCreatedAtDesc = 'LEAD_JOURNEYS_MIN_CREATED_AT_DESC',
  LeadJourneysMinUpdatedAtAsc = 'LEAD_JOURNEYS_MIN_UPDATED_AT_ASC',
  LeadJourneysMinUpdatedAtDesc = 'LEAD_JOURNEYS_MIN_UPDATED_AT_DESC',
  LeadJourneysMaxLeadIdAsc = 'LEAD_JOURNEYS_MAX_LEAD_ID_ASC',
  LeadJourneysMaxLeadIdDesc = 'LEAD_JOURNEYS_MAX_LEAD_ID_DESC',
  LeadJourneysMaxStageAsc = 'LEAD_JOURNEYS_MAX_STAGE_ASC',
  LeadJourneysMaxStageDesc = 'LEAD_JOURNEYS_MAX_STAGE_DESC',
  LeadJourneysMaxProductIdAsc = 'LEAD_JOURNEYS_MAX_PRODUCT_ID_ASC',
  LeadJourneysMaxProductIdDesc = 'LEAD_JOURNEYS_MAX_PRODUCT_ID_DESC',
  LeadJourneysMaxCreatedAtAsc = 'LEAD_JOURNEYS_MAX_CREATED_AT_ASC',
  LeadJourneysMaxCreatedAtDesc = 'LEAD_JOURNEYS_MAX_CREATED_AT_DESC',
  LeadJourneysMaxUpdatedAtAsc = 'LEAD_JOURNEYS_MAX_UPDATED_AT_ASC',
  LeadJourneysMaxUpdatedAtDesc = 'LEAD_JOURNEYS_MAX_UPDATED_AT_DESC',
  LeadJourneysAverageLeadIdAsc = 'LEAD_JOURNEYS_AVERAGE_LEAD_ID_ASC',
  LeadJourneysAverageLeadIdDesc = 'LEAD_JOURNEYS_AVERAGE_LEAD_ID_DESC',
  LeadJourneysAverageStageAsc = 'LEAD_JOURNEYS_AVERAGE_STAGE_ASC',
  LeadJourneysAverageStageDesc = 'LEAD_JOURNEYS_AVERAGE_STAGE_DESC',
  LeadJourneysAverageProductIdAsc = 'LEAD_JOURNEYS_AVERAGE_PRODUCT_ID_ASC',
  LeadJourneysAverageProductIdDesc = 'LEAD_JOURNEYS_AVERAGE_PRODUCT_ID_DESC',
  LeadJourneysAverageCreatedAtAsc = 'LEAD_JOURNEYS_AVERAGE_CREATED_AT_ASC',
  LeadJourneysAverageCreatedAtDesc = 'LEAD_JOURNEYS_AVERAGE_CREATED_AT_DESC',
  LeadJourneysAverageUpdatedAtAsc = 'LEAD_JOURNEYS_AVERAGE_UPDATED_AT_ASC',
  LeadJourneysAverageUpdatedAtDesc = 'LEAD_JOURNEYS_AVERAGE_UPDATED_AT_DESC',
  LeadJourneysStddevSampleLeadIdAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_LEAD_ID_ASC',
  LeadJourneysStddevSampleLeadIdDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_LEAD_ID_DESC',
  LeadJourneysStddevSampleStageAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_STAGE_ASC',
  LeadJourneysStddevSampleStageDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_STAGE_DESC',
  LeadJourneysStddevSampleProductIdAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  LeadJourneysStddevSampleProductIdDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  LeadJourneysStddevSampleCreatedAtAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LeadJourneysStddevSampleCreatedAtDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LeadJourneysStddevSampleUpdatedAtAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LeadJourneysStddevSampleUpdatedAtDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LeadJourneysStddevPopulationLeadIdAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_LEAD_ID_ASC',
  LeadJourneysStddevPopulationLeadIdDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_LEAD_ID_DESC',
  LeadJourneysStddevPopulationStageAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_STAGE_ASC',
  LeadJourneysStddevPopulationStageDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_STAGE_DESC',
  LeadJourneysStddevPopulationProductIdAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  LeadJourneysStddevPopulationProductIdDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  LeadJourneysStddevPopulationCreatedAtAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_CREATED_AT_ASC',
  LeadJourneysStddevPopulationCreatedAtDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_CREATED_AT_DESC',
  LeadJourneysStddevPopulationUpdatedAtAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LeadJourneysStddevPopulationUpdatedAtDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LeadJourneysVarianceSampleLeadIdAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LeadJourneysVarianceSampleLeadIdDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LeadJourneysVarianceSampleStageAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_STAGE_ASC',
  LeadJourneysVarianceSampleStageDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_STAGE_DESC',
  LeadJourneysVarianceSampleProductIdAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  LeadJourneysVarianceSampleProductIdDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  LeadJourneysVarianceSampleCreatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LeadJourneysVarianceSampleCreatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LeadJourneysVarianceSampleUpdatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LeadJourneysVarianceSampleUpdatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LeadJourneysVariancePopulationLeadIdAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_LEAD_ID_ASC',
  LeadJourneysVariancePopulationLeadIdDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_LEAD_ID_DESC',
  LeadJourneysVariancePopulationStageAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_STAGE_ASC',
  LeadJourneysVariancePopulationStageDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_STAGE_DESC',
  LeadJourneysVariancePopulationProductIdAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  LeadJourneysVariancePopulationProductIdDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  LeadJourneysVariancePopulationCreatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LeadJourneysVariancePopulationCreatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LeadJourneysVariancePopulationUpdatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LeadJourneysVariancePopulationUpdatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProductIntegrationsCountAsc = 'PRODUCT_INTEGRATIONS_COUNT_ASC',
  ProductIntegrationsCountDesc = 'PRODUCT_INTEGRATIONS_COUNT_DESC',
  ProductIntegrationsSumIdAsc = 'PRODUCT_INTEGRATIONS_SUM_ID_ASC',
  ProductIntegrationsSumIdDesc = 'PRODUCT_INTEGRATIONS_SUM_ID_DESC',
  ProductIntegrationsSumIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_SUM_INTEGRATION_ID_ASC',
  ProductIntegrationsSumIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_SUM_INTEGRATION_ID_DESC',
  ProductIntegrationsSumProductIdAsc = 'PRODUCT_INTEGRATIONS_SUM_PRODUCT_ID_ASC',
  ProductIntegrationsSumProductIdDesc = 'PRODUCT_INTEGRATIONS_SUM_PRODUCT_ID_DESC',
  ProductIntegrationsSumEnabledAsc = 'PRODUCT_INTEGRATIONS_SUM_ENABLED_ASC',
  ProductIntegrationsSumEnabledDesc = 'PRODUCT_INTEGRATIONS_SUM_ENABLED_DESC',
  ProductIntegrationsSumCreatedAtAsc = 'PRODUCT_INTEGRATIONS_SUM_CREATED_AT_ASC',
  ProductIntegrationsSumCreatedAtDesc = 'PRODUCT_INTEGRATIONS_SUM_CREATED_AT_DESC',
  ProductIntegrationsSumUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  ProductIntegrationsSumUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  ProductIntegrationsDistinctCountIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  ProductIntegrationsDistinctCountIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  ProductIntegrationsDistinctCountIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  ProductIntegrationsDistinctCountIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  ProductIntegrationsDistinctCountProductIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  ProductIntegrationsDistinctCountProductIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  ProductIntegrationsDistinctCountEnabledAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  ProductIntegrationsDistinctCountEnabledDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  ProductIntegrationsDistinctCountCreatedAtAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  ProductIntegrationsDistinctCountCreatedAtDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  ProductIntegrationsDistinctCountUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProductIntegrationsDistinctCountUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProductIntegrationsMinIdAsc = 'PRODUCT_INTEGRATIONS_MIN_ID_ASC',
  ProductIntegrationsMinIdDesc = 'PRODUCT_INTEGRATIONS_MIN_ID_DESC',
  ProductIntegrationsMinIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_MIN_INTEGRATION_ID_ASC',
  ProductIntegrationsMinIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_MIN_INTEGRATION_ID_DESC',
  ProductIntegrationsMinProductIdAsc = 'PRODUCT_INTEGRATIONS_MIN_PRODUCT_ID_ASC',
  ProductIntegrationsMinProductIdDesc = 'PRODUCT_INTEGRATIONS_MIN_PRODUCT_ID_DESC',
  ProductIntegrationsMinEnabledAsc = 'PRODUCT_INTEGRATIONS_MIN_ENABLED_ASC',
  ProductIntegrationsMinEnabledDesc = 'PRODUCT_INTEGRATIONS_MIN_ENABLED_DESC',
  ProductIntegrationsMinCreatedAtAsc = 'PRODUCT_INTEGRATIONS_MIN_CREATED_AT_ASC',
  ProductIntegrationsMinCreatedAtDesc = 'PRODUCT_INTEGRATIONS_MIN_CREATED_AT_DESC',
  ProductIntegrationsMinUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  ProductIntegrationsMinUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  ProductIntegrationsMaxIdAsc = 'PRODUCT_INTEGRATIONS_MAX_ID_ASC',
  ProductIntegrationsMaxIdDesc = 'PRODUCT_INTEGRATIONS_MAX_ID_DESC',
  ProductIntegrationsMaxIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_MAX_INTEGRATION_ID_ASC',
  ProductIntegrationsMaxIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_MAX_INTEGRATION_ID_DESC',
  ProductIntegrationsMaxProductIdAsc = 'PRODUCT_INTEGRATIONS_MAX_PRODUCT_ID_ASC',
  ProductIntegrationsMaxProductIdDesc = 'PRODUCT_INTEGRATIONS_MAX_PRODUCT_ID_DESC',
  ProductIntegrationsMaxEnabledAsc = 'PRODUCT_INTEGRATIONS_MAX_ENABLED_ASC',
  ProductIntegrationsMaxEnabledDesc = 'PRODUCT_INTEGRATIONS_MAX_ENABLED_DESC',
  ProductIntegrationsMaxCreatedAtAsc = 'PRODUCT_INTEGRATIONS_MAX_CREATED_AT_ASC',
  ProductIntegrationsMaxCreatedAtDesc = 'PRODUCT_INTEGRATIONS_MAX_CREATED_AT_DESC',
  ProductIntegrationsMaxUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  ProductIntegrationsMaxUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  ProductIntegrationsAverageIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_ID_ASC',
  ProductIntegrationsAverageIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_ID_DESC',
  ProductIntegrationsAverageIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_INTEGRATION_ID_ASC',
  ProductIntegrationsAverageIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_INTEGRATION_ID_DESC',
  ProductIntegrationsAverageProductIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_PRODUCT_ID_ASC',
  ProductIntegrationsAverageProductIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_PRODUCT_ID_DESC',
  ProductIntegrationsAverageEnabledAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  ProductIntegrationsAverageEnabledDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  ProductIntegrationsAverageCreatedAtAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  ProductIntegrationsAverageCreatedAtDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  ProductIntegrationsAverageUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  ProductIntegrationsAverageUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  ProductIntegrationsStddevSampleIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  ProductIntegrationsStddevSampleIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  ProductIntegrationsStddevSampleIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  ProductIntegrationsStddevSampleIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  ProductIntegrationsStddevSampleProductIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  ProductIntegrationsStddevSampleProductIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  ProductIntegrationsStddevSampleEnabledAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  ProductIntegrationsStddevSampleEnabledDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  ProductIntegrationsStddevSampleCreatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProductIntegrationsStddevSampleCreatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProductIntegrationsStddevSampleUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProductIntegrationsStddevSampleUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProductIntegrationsStddevPopulationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  ProductIntegrationsStddevPopulationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  ProductIntegrationsStddevPopulationIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  ProductIntegrationsStddevPopulationIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  ProductIntegrationsStddevPopulationProductIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  ProductIntegrationsStddevPopulationProductIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  ProductIntegrationsStddevPopulationEnabledAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  ProductIntegrationsStddevPopulationEnabledDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  ProductIntegrationsStddevPopulationCreatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  ProductIntegrationsStddevPopulationCreatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  ProductIntegrationsStddevPopulationUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProductIntegrationsStddevPopulationUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProductIntegrationsVarianceSampleIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  ProductIntegrationsVarianceSampleIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  ProductIntegrationsVarianceSampleIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  ProductIntegrationsVarianceSampleIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  ProductIntegrationsVarianceSampleProductIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  ProductIntegrationsVarianceSampleProductIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  ProductIntegrationsVarianceSampleEnabledAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  ProductIntegrationsVarianceSampleEnabledDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  ProductIntegrationsVarianceSampleCreatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProductIntegrationsVarianceSampleCreatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProductIntegrationsVarianceSampleUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProductIntegrationsVarianceSampleUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProductIntegrationsVariancePopulationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  ProductIntegrationsVariancePopulationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  ProductIntegrationsVariancePopulationIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  ProductIntegrationsVariancePopulationIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  ProductIntegrationsVariancePopulationProductIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  ProductIntegrationsVariancePopulationProductIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  ProductIntegrationsVariancePopulationEnabledAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  ProductIntegrationsVariancePopulationEnabledDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  ProductIntegrationsVariancePopulationCreatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProductIntegrationsVariancePopulationCreatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProductIntegrationsVariancePopulationUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProductIntegrationsVariancePopulationUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type PublicAccessTokenForLeadResponse = {
  accessToken?: Maybe<Scalars['String']>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** Reads and enables pagination through a set of `Analytic`. */
  analytics?: Maybe<AnalyticsConnection>;
  /** Reads and enables pagination through a set of `AnalyticsCta`. */
  analyticsCtas?: Maybe<AnalyticsCtasConnection>;
  /** Reads and enables pagination through a set of `AnalyticsCtaSummary`. */
  analyticsCtaSummaries?: Maybe<AnalyticsCtaSummariesConnection>;
  /** Reads and enables pagination through a set of `Appointment`. */
  appointments?: Maybe<AppointmentsConnection>;
  /** Reads and enables pagination through a set of `AuditLog`. */
  auditLogs?: Maybe<AuditLogsConnection>;
  /** Reads and enables pagination through a set of `AvaApp`. */
  avaApps?: Maybe<AvaAppsConnection>;
  /** Reads and enables pagination through a set of `BillableMetricLog`. */
  billableMetricLogs?: Maybe<BillableMetricLogsConnection>;
  /** Reads and enables pagination through a set of `Channel`. */
  channels?: Maybe<ChannelsConnection>;
  /** Reads and enables pagination through a set of `Company`. */
  companies?: Maybe<CompaniesConnection>;
  /** Reads and enables pagination through a set of `CompanyAnalytic`. */
  companyAnalytics?: Maybe<CompanyAnalyticsConnection>;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannels?: Maybe<CompanyChannelsConnection>;
  /** Reads and enables pagination through a set of `CompanyChannelMedia`. */
  companyChannelMedias?: Maybe<CompanyChannelMediaConnection>;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyCtas?: Maybe<CompanyCtasConnection>;
  /** Reads and enables pagination through a set of `CompanyDomain`. */
  companyDomains?: Maybe<CompanyDomainsConnection>;
  /** Reads and enables pagination through a set of `CompanyDomainsApproved`. */
  companyDomainsApproveds?: Maybe<CompanyDomainsApprovedsConnection>;
  /** Reads and enables pagination through a set of `CompanyDomainsPath`. */
  companyDomainsPaths?: Maybe<CompanyDomainsPathsConnection>;
  /** Reads and enables pagination through a set of `CompanyGroup`. */
  companyGroups?: Maybe<CompanyGroupsConnection>;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrations?: Maybe<CompanyIntegrationsConnection>;
  /** Reads and enables pagination through a set of `CompanyLocation`. */
  companyLocations?: Maybe<CompanyLocationsConnection>;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProducts?: Maybe<CompanyProductsConnection>;
  /** Reads and enables pagination through a set of `CompanyProductIntegration`. */
  companyProductIntegrations?: Maybe<CompanyProductIntegrationsConnection>;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsers?: Maybe<CompanyUsersConnection>;
  /** Reads and enables pagination through a set of `CompanyWidget`. */
  companyWidgets?: Maybe<CompanyWidgetsConnection>;
  /** Reads and enables pagination through a set of `CreditBureau`. */
  creditBureaus?: Maybe<CreditBureausConnection>;
  /** Reads and enables pagination through a set of `CtaAsset`. */
  ctaAssets?: Maybe<CtaAssetsConnection>;
  /** Reads and enables pagination through a set of `CtaWidget`. */
  ctaWidgets?: Maybe<CtaWidgetsConnection>;
  /** Reads and enables pagination through a set of `Cta`. */
  ctas?: Maybe<CtasConnection>;
  /** Reads and enables pagination through a set of `Deal`. */
  deals?: Maybe<DealsConnection>;
  /** Reads and enables pagination through a set of `DeliveryMethod`. */
  deliveryMethods?: Maybe<DeliveryMethodsConnection>;
  /** Reads and enables pagination through a set of `DeliveryMethodsInternal`. */
  deliveryMethodsInternals?: Maybe<DeliveryMethodsInternalsConnection>;
  /** Reads and enables pagination through a set of `DistributedLead`. */
  distributedLeads?: Maybe<DistributedLeadsConnection>;
  /** Reads and enables pagination through a set of `DistributorRelationship`. */
  distributorRelationships?: Maybe<DistributorRelationshipsConnection>;
  /** Reads and enables pagination through a set of `EmailTemplate`. */
  emailTemplates?: Maybe<EmailTemplatesConnection>;
  /** Reads and enables pagination through a set of `EquifaxLender`. */
  equifaxLenders?: Maybe<EquifaxLendersConnection>;
  /** Reads and enables pagination through a set of `Event`. */
  events?: Maybe<EventsConnection>;
  /** Reads and enables pagination through a set of `Field`. */
  fields?: Maybe<FieldsConnection>;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigs?: Maybe<FieldConfigsConnection>;
  /** Reads and enables pagination through a set of `FieldKey`. */
  fieldKeys?: Maybe<FieldKeysConnection>;
  /** Reads and enables pagination through a set of `File`. */
  files?: Maybe<FilesConnection>;
  /** Reads and enables pagination through a set of `Group`. */
  groups?: Maybe<GroupsConnection>;
  /** Reads and enables pagination through a set of `IdInquiry`. */
  idInquiries?: Maybe<IdInquiriesConnection>;
  /** Reads and enables pagination through a set of `IdInquiryVerification`. */
  idInquiryVerifications?: Maybe<IdInquiryVerificationsConnection>;
  /** Reads and enables pagination through a set of `IdInquiryVerificationCheck`. */
  idInquiryVerificationChecks?: Maybe<IdInquiryVerificationChecksConnection>;
  /** Reads and enables pagination through a set of `IdMedia`. */
  idMedias?: Maybe<IdMediaConnection>;
  /** Reads and enables pagination through a set of `IdSession`. */
  idSessions?: Maybe<IdSessionsConnection>;
  /** Reads and enables pagination through a set of `IdVerification`. */
  idVerifications?: Maybe<IdVerificationsConnection>;
  /** Reads and enables pagination through a set of `IncomeVerification`. */
  incomeVerifications?: Maybe<IncomeVerificationsConnection>;
  /** Reads and enables pagination through a set of `Integration`. */
  integrations?: Maybe<IntegrationsConnection>;
  /** Reads and enables pagination through a set of `Lead`. */
  leads?: Maybe<LeadsConnection>;
  /** Reads and enables pagination through a set of `LeadCampaign`. */
  leadCampaigns?: Maybe<LeadCampaignsConnection>;
  /** Reads and enables pagination through a set of `LeadJourney`. */
  leadJourneys?: Maybe<LeadJourneysConnection>;
  /** Reads and enables pagination through a set of `LeadRole`. */
  leadRoles?: Maybe<LeadRolesConnection>;
  /** Reads and enables pagination through a set of `Lender`. */
  lenders?: Maybe<LendersConnection>;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderApps?: Maybe<LenderAppsConnection>;
  /** Reads and enables pagination through a set of `LenderCreditKey`. */
  lenderCreditKeys?: Maybe<LenderCreditKeysConnection>;
  /** Reads and enables pagination through a set of `LenderProgram`. */
  lenderPrograms?: Maybe<LenderProgramsConnection>;
  /** Reads and enables pagination through a set of `LenderProgramBonus`. */
  lenderProgramBonuses?: Maybe<LenderProgramBonusesConnection>;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes?: Maybe<LenderQuotesConnection>;
  /** Reads and enables pagination through a set of `MaxAmortization`. */
  maxAmortizations?: Maybe<MaxAmortizationsConnection>;
  /** Reads and enables pagination through a set of `Media`. */
  medias?: Maybe<MediaConnection>;
  /** Reads and enables pagination through a set of `NeighborhoodView`. */
  neighborhoodViews?: Maybe<NeighborhoodViewsConnection>;
  /** Reads and enables pagination through a set of `Option`. */
  options?: Maybe<OptionsConnection>;
  /** Reads and enables pagination through a set of `Product`. */
  products?: Maybe<ProductsConnection>;
  /** Reads and enables pagination through a set of `ProductIntegration`. */
  productIntegrations?: Maybe<ProductIntegrationsConnection>;
  /** Reads and enables pagination through a set of `Rate`. */
  rates?: Maybe<RatesConnection>;
  /** Reads and enables pagination through a set of `Reserve`. */
  reserves?: Maybe<ReservesConnection>;
  /** Reads and enables pagination through a set of `Review`. */
  reviews?: Maybe<ReviewsConnection>;
  /** Reads and enables pagination through a set of `TradeApp`. */
  tradeApps?: Maybe<TradeAppsConnection>;
  /** Reads and enables pagination through a set of `TradeLine`. */
  tradeLines?: Maybe<TradeLinesConnection>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
  /** Reads and enables pagination through a set of `UserActivity`. */
  userActivities?: Maybe<UserActivitiesConnection>;
  /** Reads and enables pagination through a set of `VehicleBooking`. */
  vehicleBookings?: Maybe<VehicleBookingsConnection>;
  /** Reads and enables pagination through a set of `VehicleListing`. */
  vehicleListings?: Maybe<VehicleListingsConnection>;
  /** Reads and enables pagination through a set of `VehicleStock`. */
  vehicleStocks?: Maybe<VehicleStocksConnection>;
  /** Reads and enables pagination through a set of `VerificationCheck`. */
  verificationChecks?: Maybe<VerificationChecksConnection>;
  /** Reads and enables pagination through a set of `WidgetSetting`. */
  widgetSettings?: Maybe<WidgetSettingsConnection>;
  /** Reads and enables pagination through a set of `Widget`. */
  widgets?: Maybe<WidgetsConnection>;
  analytic?: Maybe<Analytic>;
  analyticsCta?: Maybe<AnalyticsCta>;
  appointment?: Maybe<Appointment>;
  auditLog?: Maybe<AuditLog>;
  avaApp?: Maybe<AvaApp>;
  avaAppByLeadId?: Maybe<AvaApp>;
  billableMetricLog?: Maybe<BillableMetricLog>;
  channel?: Maybe<Channel>;
  channelByName?: Maybe<Channel>;
  company?: Maybe<Company>;
  companyByName?: Maybe<Company>;
  companyByExternalId?: Maybe<Company>;
  companyByPrimaryGroup?: Maybe<Company>;
  companyByLagoId?: Maybe<Company>;
  companyAnalytic?: Maybe<CompanyAnalytic>;
  companyChannel?: Maybe<CompanyChannel>;
  companyChannelByCompanyIdAndNameAndChannelId?: Maybe<CompanyChannel>;
  companyChannelMedia?: Maybe<CompanyChannelMedia>;
  companyChannelMediaByCompanyChannelIdAndMediaId?: Maybe<CompanyChannelMedia>;
  companyCta?: Maybe<CompanyCta>;
  companyDomain?: Maybe<CompanyDomain>;
  companyDomainByDomain?: Maybe<CompanyDomain>;
  companyDomainsApproved?: Maybe<CompanyDomainsApproved>;
  companyDomainsPath?: Maybe<CompanyDomainsPath>;
  companyDomainsPathByDomainIdAndPath?: Maybe<CompanyDomainsPath>;
  companyGroup?: Maybe<CompanyGroup>;
  companyIntegration?: Maybe<CompanyIntegration>;
  companyLocation?: Maybe<CompanyLocation>;
  companyProduct?: Maybe<CompanyProduct>;
  companyProductIntegration?: Maybe<CompanyProductIntegration>;
  companyUser?: Maybe<CompanyUser>;
  companyWidget?: Maybe<CompanyWidget>;
  creditBureau?: Maybe<CreditBureau>;
  ctaAsset?: Maybe<CtaAsset>;
  ctaAssetByGroupIdAndName?: Maybe<CtaAsset>;
  ctaWidget?: Maybe<CtaWidget>;
  cta?: Maybe<Cta>;
  ctaByProductAndTypeAndVersion?: Maybe<Cta>;
  deal?: Maybe<Deal>;
  deliveryMethod?: Maybe<DeliveryMethod>;
  distributedLead?: Maybe<DistributedLead>;
  distributorRelationship?: Maybe<DistributorRelationship>;
  distributorRelationshipByDistributorCompanyIdAndCompanyId?: Maybe<DistributorRelationship>;
  emailTemplate?: Maybe<EmailTemplate>;
  equifaxLender?: Maybe<EquifaxLender>;
  equifaxLenderByEquifaxIdAndName?: Maybe<EquifaxLender>;
  event?: Maybe<Event>;
  field?: Maybe<Field>;
  fieldByCompanyProductIdAndConfig?: Maybe<Field>;
  fieldByCompanyChannelIdAndConfig?: Maybe<Field>;
  fieldByCompanyIntegrationIdAndConfig?: Maybe<Field>;
  fieldConfig?: Maybe<FieldConfig>;
  fieldConfigByParentIdAndParentTypeAndKey?: Maybe<FieldConfig>;
  fieldKey?: Maybe<FieldKey>;
  fieldKeyByKey?: Maybe<FieldKey>;
  file?: Maybe<File>;
  group?: Maybe<Group>;
  groupByCognitoName?: Maybe<Group>;
  idInquiry?: Maybe<IdInquiry>;
  idInquiryVerification?: Maybe<IdInquiryVerification>;
  idInquiryVerificationCheck?: Maybe<IdInquiryVerificationCheck>;
  idMedia?: Maybe<IdMedia>;
  idSession?: Maybe<IdSession>;
  idVerification?: Maybe<IdVerification>;
  idVerificationByLeadId?: Maybe<IdVerification>;
  incomeVerification?: Maybe<IncomeVerification>;
  incomeVerificationByLeadId?: Maybe<IncomeVerification>;
  integration?: Maybe<Integration>;
  integrationByName?: Maybe<Integration>;
  lead?: Maybe<Lead>;
  leadCampaign?: Maybe<LeadCampaign>;
  leadCampaignByLeadId?: Maybe<LeadCampaign>;
  leadRole?: Maybe<LeadRole>;
  lender?: Maybe<Lender>;
  lenderByName?: Maybe<Lender>;
  lenderApp?: Maybe<LenderApp>;
  lenderCreditKey?: Maybe<LenderCreditKey>;
  lenderProgram?: Maybe<LenderProgram>;
  lenderProgramBonus?: Maybe<LenderProgramBonus>;
  lenderQuote?: Maybe<LenderQuote>;
  maxAmortization?: Maybe<MaxAmortization>;
  media?: Maybe<Media>;
  neighborhoodView?: Maybe<NeighborhoodView>;
  option?: Maybe<Option>;
  product?: Maybe<Product>;
  productByName?: Maybe<Product>;
  productIntegration?: Maybe<ProductIntegration>;
  rate?: Maybe<Rate>;
  reserve?: Maybe<Reserve>;
  review?: Maybe<Review>;
  tradeApp?: Maybe<TradeApp>;
  tradeLine?: Maybe<TradeLine>;
  user?: Maybe<User>;
  userActivity?: Maybe<UserActivity>;
  vehicleBooking?: Maybe<VehicleBooking>;
  vehicleListing?: Maybe<VehicleListing>;
  vehicleStock?: Maybe<VehicleStock>;
  vehicleStockByProviderId?: Maybe<VehicleStock>;
  vehicleStockByVin?: Maybe<VehicleStock>;
  vehicleStockByStockNumber?: Maybe<VehicleStock>;
  verificationCheck?: Maybe<VerificationCheck>;
  verificationCheckByName?: Maybe<VerificationCheck>;
  widgetSetting?: Maybe<WidgetSetting>;
  widget?: Maybe<Widget>;
  widgetByVariant?: Maybe<Widget>;
  acComputeGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  acGetAdminCompanyIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  acGetAdminGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  acGetDistributorCompanies?: Maybe<Array<Maybe<Scalars['Int']>>>;
  acHasAccess2?: Maybe<Scalars['Boolean']>;
  acHasAdminAccess?: Maybe<Scalars['Boolean']>;
  acUtilGetAdminGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  acUtilGetNormalGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  avaAppCreditRange?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Company`. */
  companyDetails?: Maybe<CompaniesConnection>;
  /** Reads and enables pagination through a set of `Company`. */
  getCompanyByWidget?: Maybe<CompaniesConnection>;
  getCompanyIdsByCognitoGroups?: Maybe<GetCompanyIdsByCognitoGroupsRecord>;
  getCompanyIdsForUser?: Maybe<GetCompanyIdsForUserRecord>;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  getCompatibleCtaOverrides?: Maybe<CompanyCtasConnection>;
  /** Reads and enables pagination through a set of `Group`. */
  getGroupByWidget?: Maybe<GroupsConnection>;
  getKeysOfObject?: Maybe<Array<Maybe<Scalars['String']>>>;
  getMajorVersion?: Maybe<Scalars['Int']>;
  pseudoEncrypt?: Maybe<Scalars['Int']>;
  snakeToCapitalCase?: Maybe<Scalars['String']>;
  toKebabCase?: Maybe<Scalars['String']>;
  /** Reads a single `Analytic` using its globally unique `ID`. */
  analyticByNodeId?: Maybe<Analytic>;
  /** Reads a single `AnalyticsCta` using its globally unique `ID`. */
  analyticsCtaByNodeId?: Maybe<AnalyticsCta>;
  /** Reads a single `Appointment` using its globally unique `ID`. */
  appointmentByNodeId?: Maybe<Appointment>;
  /** Reads a single `AuditLog` using its globally unique `ID`. */
  auditLogByNodeId?: Maybe<AuditLog>;
  /** Reads a single `AvaApp` using its globally unique `ID`. */
  avaAppByNodeId?: Maybe<AvaApp>;
  /** Reads a single `BillableMetricLog` using its globally unique `ID`. */
  billableMetricLogByNodeId?: Maybe<BillableMetricLog>;
  /** Reads a single `Channel` using its globally unique `ID`. */
  channelByNodeId?: Maybe<Channel>;
  /** Reads a single `Company` using its globally unique `ID`. */
  companyByNodeId?: Maybe<Company>;
  /** Reads a single `CompanyAnalytic` using its globally unique `ID`. */
  companyAnalyticByNodeId?: Maybe<CompanyAnalytic>;
  /** Reads a single `CompanyChannel` using its globally unique `ID`. */
  companyChannelByNodeId?: Maybe<CompanyChannel>;
  /** Reads a single `CompanyChannelMedia` using its globally unique `ID`. */
  companyChannelMediaByNodeId?: Maybe<CompanyChannelMedia>;
  /** Reads a single `CompanyCta` using its globally unique `ID`. */
  companyCtaByNodeId?: Maybe<CompanyCta>;
  /** Reads a single `CompanyDomain` using its globally unique `ID`. */
  companyDomainByNodeId?: Maybe<CompanyDomain>;
  /** Reads a single `CompanyDomainsApproved` using its globally unique `ID`. */
  companyDomainsApprovedByNodeId?: Maybe<CompanyDomainsApproved>;
  /** Reads a single `CompanyDomainsPath` using its globally unique `ID`. */
  companyDomainsPathByNodeId?: Maybe<CompanyDomainsPath>;
  /** Reads a single `CompanyGroup` using its globally unique `ID`. */
  companyGroupByNodeId?: Maybe<CompanyGroup>;
  /** Reads a single `CompanyIntegration` using its globally unique `ID`. */
  companyIntegrationByNodeId?: Maybe<CompanyIntegration>;
  /** Reads a single `CompanyLocation` using its globally unique `ID`. */
  companyLocationByNodeId?: Maybe<CompanyLocation>;
  /** Reads a single `CompanyProduct` using its globally unique `ID`. */
  companyProductByNodeId?: Maybe<CompanyProduct>;
  /** Reads a single `CompanyProductIntegration` using its globally unique `ID`. */
  companyProductIntegrationByNodeId?: Maybe<CompanyProductIntegration>;
  /** Reads a single `CompanyUser` using its globally unique `ID`. */
  companyUserByNodeId?: Maybe<CompanyUser>;
  /** Reads a single `CompanyWidget` using its globally unique `ID`. */
  companyWidgetByNodeId?: Maybe<CompanyWidget>;
  /** Reads a single `CreditBureau` using its globally unique `ID`. */
  creditBureauByNodeId?: Maybe<CreditBureau>;
  /** Reads a single `CtaAsset` using its globally unique `ID`. */
  ctaAssetByNodeId?: Maybe<CtaAsset>;
  /** Reads a single `CtaWidget` using its globally unique `ID`. */
  ctaWidgetByNodeId?: Maybe<CtaWidget>;
  /** Reads a single `Cta` using its globally unique `ID`. */
  ctaByNodeId?: Maybe<Cta>;
  /** Reads a single `Deal` using its globally unique `ID`. */
  dealByNodeId?: Maybe<Deal>;
  /** Reads a single `DeliveryMethod` using its globally unique `ID`. */
  deliveryMethodByNodeId?: Maybe<DeliveryMethod>;
  /** Reads a single `DistributedLead` using its globally unique `ID`. */
  distributedLeadByNodeId?: Maybe<DistributedLead>;
  /** Reads a single `DistributorRelationship` using its globally unique `ID`. */
  distributorRelationshipByNodeId?: Maybe<DistributorRelationship>;
  /** Reads a single `EmailTemplate` using its globally unique `ID`. */
  emailTemplateByNodeId?: Maybe<EmailTemplate>;
  /** Reads a single `EquifaxLender` using its globally unique `ID`. */
  equifaxLenderByNodeId?: Maybe<EquifaxLender>;
  /** Reads a single `Event` using its globally unique `ID`. */
  eventByNodeId?: Maybe<Event>;
  /** Reads a single `Field` using its globally unique `ID`. */
  fieldByNodeId?: Maybe<Field>;
  /** Reads a single `FieldConfig` using its globally unique `ID`. */
  fieldConfigByNodeId?: Maybe<FieldConfig>;
  /** Reads a single `FieldKey` using its globally unique `ID`. */
  fieldKeyByNodeId?: Maybe<FieldKey>;
  /** Reads a single `File` using its globally unique `ID`. */
  fileByNodeId?: Maybe<File>;
  /** Reads a single `Group` using its globally unique `ID`. */
  groupByNodeId?: Maybe<Group>;
  /** Reads a single `IdInquiry` using its globally unique `ID`. */
  idInquiryByNodeId?: Maybe<IdInquiry>;
  /** Reads a single `IdInquiryVerification` using its globally unique `ID`. */
  idInquiryVerificationByNodeId?: Maybe<IdInquiryVerification>;
  /** Reads a single `IdInquiryVerificationCheck` using its globally unique `ID`. */
  idInquiryVerificationCheckByNodeId?: Maybe<IdInquiryVerificationCheck>;
  /** Reads a single `IdMedia` using its globally unique `ID`. */
  idMediaByNodeId?: Maybe<IdMedia>;
  /** Reads a single `IdSession` using its globally unique `ID`. */
  idSessionByNodeId?: Maybe<IdSession>;
  /** Reads a single `IdVerification` using its globally unique `ID`. */
  idVerificationByNodeId?: Maybe<IdVerification>;
  /** Reads a single `IncomeVerification` using its globally unique `ID`. */
  incomeVerificationByNodeId?: Maybe<IncomeVerification>;
  /** Reads a single `Integration` using its globally unique `ID`. */
  integrationByNodeId?: Maybe<Integration>;
  /** Reads a single `Lead` using its globally unique `ID`. */
  leadByNodeId?: Maybe<Lead>;
  /** Reads a single `LeadCampaign` using its globally unique `ID`. */
  leadCampaignByNodeId?: Maybe<LeadCampaign>;
  /** Reads a single `LeadRole` using its globally unique `ID`. */
  leadRoleByNodeId?: Maybe<LeadRole>;
  /** Reads a single `Lender` using its globally unique `ID`. */
  lenderByNodeId?: Maybe<Lender>;
  /** Reads a single `LenderApp` using its globally unique `ID`. */
  lenderAppByNodeId?: Maybe<LenderApp>;
  /** Reads a single `LenderCreditKey` using its globally unique `ID`. */
  lenderCreditKeyByNodeId?: Maybe<LenderCreditKey>;
  /** Reads a single `LenderProgram` using its globally unique `ID`. */
  lenderProgramByNodeId?: Maybe<LenderProgram>;
  /** Reads a single `LenderProgramBonus` using its globally unique `ID`. */
  lenderProgramBonusByNodeId?: Maybe<LenderProgramBonus>;
  /** Reads a single `LenderQuote` using its globally unique `ID`. */
  lenderQuoteByNodeId?: Maybe<LenderQuote>;
  /** Reads a single `MaxAmortization` using its globally unique `ID`. */
  maxAmortizationByNodeId?: Maybe<MaxAmortization>;
  /** Reads a single `Media` using its globally unique `ID`. */
  mediaByNodeId?: Maybe<Media>;
  /** Reads a single `NeighborhoodView` using its globally unique `ID`. */
  neighborhoodViewByNodeId?: Maybe<NeighborhoodView>;
  /** Reads a single `Option` using its globally unique `ID`. */
  optionByNodeId?: Maybe<Option>;
  /** Reads a single `Product` using its globally unique `ID`. */
  productByNodeId?: Maybe<Product>;
  /** Reads a single `ProductIntegration` using its globally unique `ID`. */
  productIntegrationByNodeId?: Maybe<ProductIntegration>;
  /** Reads a single `Rate` using its globally unique `ID`. */
  rateByNodeId?: Maybe<Rate>;
  /** Reads a single `Reserve` using its globally unique `ID`. */
  reserveByNodeId?: Maybe<Reserve>;
  /** Reads a single `Review` using its globally unique `ID`. */
  reviewByNodeId?: Maybe<Review>;
  /** Reads a single `TradeApp` using its globally unique `ID`. */
  tradeAppByNodeId?: Maybe<TradeApp>;
  /** Reads a single `TradeLine` using its globally unique `ID`. */
  tradeLineByNodeId?: Maybe<TradeLine>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  /** Reads a single `UserActivity` using its globally unique `ID`. */
  userActivityByNodeId?: Maybe<UserActivity>;
  /** Reads a single `VehicleBooking` using its globally unique `ID`. */
  vehicleBookingByNodeId?: Maybe<VehicleBooking>;
  /** Reads a single `VehicleListing` using its globally unique `ID`. */
  vehicleListingByNodeId?: Maybe<VehicleListing>;
  /** Reads a single `VehicleStock` using its globally unique `ID`. */
  vehicleStockByNodeId?: Maybe<VehicleStock>;
  /** Reads a single `VerificationCheck` using its globally unique `ID`. */
  verificationCheckByNodeId?: Maybe<VerificationCheck>;
  /** Reads a single `WidgetSetting` using its globally unique `ID`. */
  widgetSettingByNodeId?: Maybe<WidgetSetting>;
  /** Reads a single `Widget` using its globally unique `ID`. */
  widgetByNodeId?: Maybe<Widget>;
  /** Sign in with username and password */
  authSignIn: AuthPayload;
  /** Generate new tokens with refresh token in cookie */
  authRefresh: AuthPayload;
  /** Allows users to start forgot password */
  authForgotPassword: AuthForgotPasswordResult;
  /** Generate new tokens with refresh token in cookie */
  authConfirmForgotPassword: AuthPayload;
  /** Respond to an auth challenge */
  authChallenge: AuthPayload;
  /** Sign out, clear tokens, cookie */
  authSignOut: AuthSignOutResult;
  /** @deprecated Use fetchLenderQuotes instead */
  validateCreditAppApplication: ValidateCreditAppApplicationResponse;
  validatePrequalApplication: ValidatePrequalApplicationResponse;
  getCreditAppCreditors: Array<GetCreditAppCreditorsResponse>;
  getCreditAppApplication?: Maybe<CreditAppApplication>;
  creditConsentStatus: CreditConsentStatus;
  neighborhoodScoreForPostalCode?: Maybe<PartialNeighborhoodView>;
  currentUserPermissions?: Maybe<UserPermissions>;
  /** @deprecated Use the new 'users()' resolver. */
  listUsers?: Maybe<GroupMemberships>;
  me?: Maybe<User>;
  /** Search vehicle to trade-in */
  tradeAppSearch?: Maybe<TradeAppSearchResults>;
  /** Get the trade-in value of the specified vehicle. */
  tradeAppGetTradeValue?: Maybe<TradeAppTradeValue>;
  /** Get the trade-in value of the specified vehicle. */
  tradeAppGetPrices: TradeAppPayload;
  /** Get supported model years. */
  tradeAppGetSupportedYears: Array<Scalars['Int']>;
  /** Get all model years. */
  tradeAppGetAllYears?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Get all vehicle makes id supported in a model year. */
  tradeAppGetSupportedMakes: Array<TradeAppYearMakes>;
  /** Get all vehicle makes. */
  tradeAppGetAllMakes?: Maybe<Array<Maybe<TradeAppMake>>>;
  /** Get all vehicle models from the specified make. */
  tradeAppGetAllModels?: Maybe<Array<Maybe<TradeAppModel>>>;
  /** Get all trims or variant of the specified model name and model year. */
  tradeAppGetAllTrims?: Maybe<Array<Maybe<TradeAppTrim>>>;
  /** Get information about the vehicle. */
  tradeAppGetVehicleInfo?: Maybe<TradeAppVehicleInfo>;
  /** Get vehicles by VIN . */
  tradeAppGetVehiclesByVIN: Array<TradeAppVehicleInfo>;
  /** CBB API */
  vehicleLookup: VehicleLookupResponse;
  /** Get a vehicle configuration by chrome style Id. */
  getAutoConfiguration?: Maybe<AutoConfiguration>;
  /** Get a vehicle images by chrome style Id. */
  getAutoImages?: Maybe<AutoImages>;
  getUnreadLeadCountPerProduct: UnreadLeadCountResponse;
  /** @deprecated Use getCompanyBilling instead */
  getCompanyInvoices: Array<CompanyInvoice>;
  getCompanyBilling?: Maybe<Billing>;
  getCompanyOverview?: Maybe<OverviewResponse>;
  getWidgetDetails: WidgetDetailsResponse;
  getLeadInfo?: Maybe<LeadInfoResponse>;
  getPublicAccessTokenForLead?: Maybe<PublicAccessTokenForLeadResponse>;
  getCtaSpecs: CtaSpecsResponse;
  getCtaCatalog: CtaCatalogResults;
  getCompanyCtaAnalytics?: Maybe<CtaAnalyticsResponse>;
  getGlobalCtaAnalytics?: Maybe<CtaAnalyticsResponse>;
  idVerificationTemplate?: Maybe<IdVerificationTemplateResponse>;
  retrieveIncomeVerification?: Maybe<RetrieveIncomeVerificationResponse>;
  getReviewConfigForLead?: Maybe<ReviewConfigForLeadResponse>;
  getQRCodeImage?: Maybe<GetQrCodeImageResponse>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AnalyticsOrderBy>>;
  condition?: Maybe<AnalyticCondition>;
  filter?: Maybe<AnalyticFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticsCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AnalyticsCtasOrderBy>>;
  condition?: Maybe<AnalyticsCtaCondition>;
  filter?: Maybe<AnalyticsCtaFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticsCtaSummariesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AnalyticsCtaSummariesOrderBy>>;
  condition?: Maybe<AnalyticsCtaSummaryCondition>;
  filter?: Maybe<AnalyticsCtaSummaryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
  condition?: Maybe<AppointmentCondition>;
  filter?: Maybe<AppointmentFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAuditLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuditLogsOrderBy>>;
  condition?: Maybe<AuditLogCondition>;
  filter?: Maybe<AuditLogFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAvaAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AvaAppsOrderBy>>;
  condition?: Maybe<AvaAppCondition>;
  filter?: Maybe<AvaAppFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBillableMetricLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BillableMetricLogsOrderBy>>;
  condition?: Maybe<BillableMetricLogCondition>;
  filter?: Maybe<BillableMetricLogFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
  condition?: Maybe<ChannelCondition>;
  filter?: Maybe<ChannelFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompaniesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyAnalyticsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyAnalyticsOrderBy>>;
  condition?: Maybe<CompanyAnalyticCondition>;
  filter?: Maybe<CompanyAnalyticFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyChannelMediasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelMediaOrderBy>>;
  condition?: Maybe<CompanyChannelMediaCondition>;
  filter?: Maybe<CompanyChannelMediaFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
  condition?: Maybe<CompanyCtaCondition>;
  filter?: Maybe<CompanyCtaFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
  condition?: Maybe<CompanyDomainCondition>;
  filter?: Maybe<CompanyDomainFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsApprovedsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
  condition?: Maybe<CompanyDomainsApprovedCondition>;
  filter?: Maybe<CompanyDomainsApprovedFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsPathsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
  condition?: Maybe<CompanyDomainsPathCondition>;
  filter?: Maybe<CompanyDomainsPathFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
  condition?: Maybe<CompanyGroupCondition>;
  filter?: Maybe<CompanyGroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyLocationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyLocationsOrderBy>>;
  condition?: Maybe<CompanyLocationCondition>;
  filter?: Maybe<CompanyLocationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
  condition?: Maybe<CompanyProductIntegrationCondition>;
  filter?: Maybe<CompanyProductIntegrationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyUsersOrderBy>>;
  condition?: Maybe<CompanyUserCondition>;
  filter?: Maybe<CompanyUserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
  condition?: Maybe<CompanyWidgetCondition>;
  filter?: Maybe<CompanyWidgetFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditBureausArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CreditBureausOrderBy>>;
  condition?: Maybe<CreditBureauCondition>;
  filter?: Maybe<CreditBureauFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaAssetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaAssetsOrderBy>>;
  condition?: Maybe<CtaAssetCondition>;
  filter?: Maybe<CtaAssetFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
  condition?: Maybe<CtaWidgetCondition>;
  filter?: Maybe<CtaWidgetFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtasOrderBy>>;
  condition?: Maybe<CtaCondition>;
  filter?: Maybe<CtaFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDealsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DealsOrderBy>>;
  condition?: Maybe<DealCondition>;
  filter?: Maybe<DealFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDeliveryMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DeliveryMethodsOrderBy>>;
  condition?: Maybe<DeliveryMethodCondition>;
  filter?: Maybe<DeliveryMethodFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDeliveryMethodsInternalsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DeliveryMethodsInternalsOrderBy>>;
  condition?: Maybe<DeliveryMethodsInternalCondition>;
  filter?: Maybe<DeliveryMethodsInternalFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDistributedLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributedLeadsOrderBy>>;
  condition?: Maybe<DistributedLeadCondition>;
  filter?: Maybe<DistributedLeadFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDistributorRelationshipsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DistributorRelationshipsOrderBy>>;
  condition?: Maybe<DistributorRelationshipCondition>;
  filter?: Maybe<DistributorRelationshipFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailTemplatesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
  condition?: Maybe<EmailTemplateCondition>;
  filter?: Maybe<EmailTemplateFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEquifaxLendersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EquifaxLendersOrderBy>>;
  condition?: Maybe<EquifaxLenderCondition>;
  filter?: Maybe<EquifaxLenderFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldKeysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldKeysOrderBy>>;
  condition?: Maybe<FieldKeyCondition>;
  filter?: Maybe<FieldKeyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIdInquiriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiriesOrderBy>>;
  condition?: Maybe<IdInquiryCondition>;
  filter?: Maybe<IdInquiryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIdInquiryVerificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiryVerificationsOrderBy>>;
  condition?: Maybe<IdInquiryVerificationCondition>;
  filter?: Maybe<IdInquiryVerificationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIdInquiryVerificationChecksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiryVerificationChecksOrderBy>>;
  condition?: Maybe<IdInquiryVerificationCheckCondition>;
  filter?: Maybe<IdInquiryVerificationCheckFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIdMediasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdMediaOrderBy>>;
  condition?: Maybe<IdMediaCondition>;
  filter?: Maybe<IdMediaFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIdSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdSessionsOrderBy>>;
  condition?: Maybe<IdSessionCondition>;
  filter?: Maybe<IdSessionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIdVerificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdVerificationsOrderBy>>;
  condition?: Maybe<IdVerificationCondition>;
  filter?: Maybe<IdVerificationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeVerificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IncomeVerificationsOrderBy>>;
  condition?: Maybe<IncomeVerificationCondition>;
  filter?: Maybe<IncomeVerificationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
  condition?: Maybe<IntegrationCondition>;
  filter?: Maybe<IntegrationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadCampaignsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadCampaignsOrderBy>>;
  condition?: Maybe<LeadCampaignCondition>;
  filter?: Maybe<LeadCampaignFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadJourneysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
  condition?: Maybe<LeadJourneyCondition>;
  filter?: Maybe<LeadJourneyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadRolesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadRolesOrderBy>>;
  condition?: Maybe<LeadRoleCondition>;
  filter?: Maybe<LeadRoleFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLendersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LendersOrderBy>>;
  condition?: Maybe<LenderCondition>;
  filter?: Maybe<LenderFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderCreditKeysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderCreditKeysOrderBy>>;
  condition?: Maybe<LenderCreditKeyCondition>;
  filter?: Maybe<LenderCreditKeyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderProgramsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderProgramsOrderBy>>;
  condition?: Maybe<LenderProgramCondition>;
  filter?: Maybe<LenderProgramFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderProgramBonusesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderProgramBonusesOrderBy>>;
  condition?: Maybe<LenderProgramBonusCondition>;
  filter?: Maybe<LenderProgramBonusFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMaxAmortizationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MaxAmortizationsOrderBy>>;
  condition?: Maybe<MaxAmortizationCondition>;
  filter?: Maybe<MaxAmortizationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMediasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaOrderBy>>;
  condition?: Maybe<MediaCondition>;
  filter?: Maybe<MediaFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNeighborhoodViewsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NeighborhoodViewsOrderBy>>;
  condition?: Maybe<NeighborhoodViewCondition>;
  filter?: Maybe<NeighborhoodViewFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<OptionsOrderBy>>;
  condition?: Maybe<OptionCondition>;
  filter?: Maybe<OptionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
  condition?: Maybe<ProductIntegrationCondition>;
  filter?: Maybe<ProductIntegrationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRatesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<RatesOrderBy>>;
  condition?: Maybe<RateCondition>;
  filter?: Maybe<RateFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReservesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReservesOrderBy>>;
  condition?: Maybe<ReserveCondition>;
  filter?: Maybe<ReserveFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReviewsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReviewsOrderBy>>;
  condition?: Maybe<ReviewCondition>;
  filter?: Maybe<ReviewFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TradeAppsOrderBy>>;
  condition?: Maybe<TradeAppCondition>;
  filter?: Maybe<TradeAppFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeLinesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TradeLinesOrderBy>>;
  condition?: Maybe<TradeLineCondition>;
  filter?: Maybe<TradeLineFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserActivitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserActivitiesOrderBy>>;
  condition?: Maybe<UserActivityCondition>;
  filter?: Maybe<UserActivityFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleBookingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleBookingsOrderBy>>;
  condition?: Maybe<VehicleBookingCondition>;
  filter?: Maybe<VehicleBookingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
  condition?: Maybe<VehicleListingCondition>;
  filter?: Maybe<VehicleListingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleStocksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleStocksOrderBy>>;
  condition?: Maybe<VehicleStockCondition>;
  filter?: Maybe<VehicleStockFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVerificationChecksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VerificationChecksOrderBy>>;
  condition?: Maybe<VerificationCheckCondition>;
  filter?: Maybe<VerificationCheckFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetSettingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
  condition?: Maybe<WidgetSettingCondition>;
  filter?: Maybe<WidgetSettingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
  condition?: Maybe<WidgetCondition>;
  filter?: Maybe<WidgetFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticsCtaArgs = {
  eventId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppointmentArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuditLogArgs = {
  eventId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAvaAppArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAvaAppByLeadIdArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBillableMetricLogArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChannelArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChannelByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByExternalIdArgs = {
  externalId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByPrimaryGroupArgs = {
  primaryGroup: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByLagoIdArgs = {
  lagoId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyAnalyticArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyChannelArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyChannelByCompanyIdAndNameAndChannelIdArgs = {
  companyId: Scalars['Int'];
  name: Scalars['String'];
  channelId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyChannelMediaArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyChannelMediaByCompanyChannelIdAndMediaIdArgs = {
  companyChannelId: Scalars['Int'];
  mediaId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyCtaArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainByDomainArgs = {
  domain: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsApprovedArgs = {
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsPathArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsPathByDomainIdAndPathArgs = {
  domainId: Scalars['Int'];
  path: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyGroupArgs = {
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyIntegrationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyLocationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductIntegrationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyUserArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyWidgetArgs = {
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditBureauArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaAssetArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaAssetByGroupIdAndNameArgs = {
  groupId: Scalars['Int'];
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaWidgetArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaByProductAndTypeAndVersionArgs = {
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDealArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDeliveryMethodArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDistributedLeadArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDistributorRelationshipArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDistributorRelationshipByDistributorCompanyIdAndCompanyIdArgs = {
  distributorCompanyId: Scalars['Int'];
  companyId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailTemplateArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquifaxLenderArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquifaxLenderByEquifaxIdAndNameArgs = {
  equifaxId: Scalars['String'];
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldByCompanyProductIdAndConfigArgs = {
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldByCompanyChannelIdAndConfigArgs = {
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldByCompanyIntegrationIdAndConfigArgs = {
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldConfigArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldConfigByParentIdAndParentTypeAndKeyArgs = {
  parentId: Scalars['Int'];
  parentType: Scalars['String'];
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldKeyArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldKeyByKeyArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupByCognitoNameArgs = {
  cognitoName: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdInquiryArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdInquiryVerificationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdInquiryVerificationCheckArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdMediaArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdSessionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdVerificationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdVerificationByLeadIdArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeVerificationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeVerificationByLeadIdArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadCampaignArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadCampaignByLeadIdArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadRoleArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderAppArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderCreditKeyArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderProgramArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderProgramBonusArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderQuoteArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMaxAmortizationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNeighborhoodViewArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOptionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductByNameArgs = {
  name: ProductType;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductIntegrationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRateArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReserveArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReviewArgs = {
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeLineArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserActivityArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleBookingArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleListingArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleStockArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleStockByProviderIdArgs = {
  providerId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleStockByVinArgs = {
  vin: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleStockByStockNumberArgs = {
  stockNumber: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVerificationCheckArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVerificationCheckByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetSettingArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetByVariantArgs = {
  variant: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAcComputeGroupsArgs = {
  groupList?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAcGetDistributorCompaniesArgs = {
  groupList?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAcHasAccess2Args = {
  recordCompanyId?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAcHasAdminAccessArgs = {
  leadId?: Maybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAvaAppCreditRangeArgs = {
  score?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDetailsArgs = {
  getCompanyId?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CompanyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompanyByWidgetArgs = {
  widget?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CompanyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompanyIdsByCognitoGroupsArgs = {
  adminGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  normalGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAutocorpAdmin?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompanyIdsForUserArgs = {
  isAutocorpAdmin?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompatibleCtaOverridesArgs = {
  inputCta: Scalars['String'];
  inputGroup?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CompanyCtaFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGroupByWidgetArgs = {
  widget?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<GroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetKeysOfObjectArgs = {
  obj: Scalars['JSON'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetMajorVersionArgs = {
  version?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPseudoEncryptArgs = {
  value: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySnakeToCapitalCaseArgs = {
  inputText?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryToKebabCaseArgs = {
  tValue?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticsCtaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppointmentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuditLogByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAvaAppByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBillableMetricLogByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChannelByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyAnalyticByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyChannelByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyChannelMediaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyCtaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsApprovedByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsPathByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyIntegrationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyLocationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductIntegrationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyWidgetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditBureauByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaAssetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaWidgetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDealByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDeliveryMethodByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDistributedLeadByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDistributorRelationshipByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailTemplateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquifaxLenderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldConfigByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldKeyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdInquiryByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdInquiryVerificationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdInquiryVerificationCheckByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdMediaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdSessionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdVerificationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeVerificationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadCampaignByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadRoleByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderAppByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderCreditKeyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderProgramByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderProgramBonusByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderQuoteByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMaxAmortizationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNeighborhoodViewByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOptionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductIntegrationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReserveByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReviewByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeLineByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserActivityByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleBookingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleListingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleStockByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVerificationCheckByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetSettingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthSignInArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthRefreshArgs = {
  refreshToken?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthForgotPasswordArgs = {
  username: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthConfirmForgotPasswordArgs = {
  username: Scalars['String'];
  verificationCode: Scalars['String'];
  password: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthChallengeArgs = {
  session: Scalars['String'];
  challenge: AuthChallengeResponse;
};


/** The root query type which gives access points into the data universe. */
export type QueryValidateCreditAppApplicationArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryValidatePrequalApplicationArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCreditAppCreditorsArgs = {
  companyId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCreditAppApplicationArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditConsentStatusArgs = {
  accessToken: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNeighborhoodScoreForPostalCodeArgs = {
  postalCode: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryListUsersArgs = {
  groupId?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppSearchArgs = {
  phrase: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetTradeValueArgs = {
  vehicleId: Scalars['String'];
  mileage: Scalars['Int'];
  condition: TradeAppVehicleCondition;
  location?: Maybe<TradeAppLocation>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetPricesArgs = {
  vehicleId: Scalars['String'];
  mileage: Scalars['Int'];
  condition: TradeAppVehicleCondition;
  location?: Maybe<TradeAppLocation>;
  provider?: Maybe<TradeProvider>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetSupportedMakesArgs = {
  year?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetAllMakesArgs = {
  year?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetAllModelsArgs = {
  year?: Maybe<Scalars['Int']>;
  makeId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetAllTrimsArgs = {
  year: Scalars['Int'];
  modelId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetVehicleInfoArgs = {
  vehicleId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetVehiclesByVinArgs = {
  vin: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleLookupArgs = {
  widgetId: Scalars['String'];
  keyword: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAutoConfigurationArgs = {
  styleId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAutoImagesArgs = {
  styleId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetUnreadLeadCountPerProductArgs = {
  companyId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompanyInvoicesArgs = {
  companyId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompanyBillingArgs = {
  companyId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompanyOverviewArgs = {
  companyId: Scalars['Int'];
  from?: Maybe<Scalars['Datetime']>;
  to?: Maybe<Scalars['Datetime']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetWidgetDetailsArgs = {
  widgetId: Scalars['String'];
  ctaId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLeadInfoArgs = {
  accessToken: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPublicAccessTokenForLeadArgs = {
  leadId?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCtaSpecsArgs = {
  input: CtaSpecsInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCtaCatalogArgs = {
  widgetId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompanyCtaAnalyticsArgs = {
  filter?: Maybe<CtaAnalyticsFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGlobalCtaAnalyticsArgs = {
  filter?: Maybe<CtaAnalyticsFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIdVerificationTemplateArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRetrieveIncomeVerificationArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetReviewConfigForLeadArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetQrCodeImageArgs = {
  input: GetQrCodeImageInput;
};

export type Rate = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  minTerm?: Maybe<Scalars['Int']>;
  maxTerm?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['BigFloat']>;
  usedCarAdvanceRate: Scalars['Int'];
  newCarAdvanceRate: Scalars['Int'];
  addOn?: Maybe<Scalars['Int']>;
  lenderAdminFee?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `LenderCreditKey` that is related to this `Rate`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** Reads and enables pagination through a set of `Reserve`. */
  reserves: ReservesConnection;
};


export type RateReservesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReservesOrderBy>>;
  condition?: Maybe<ReserveCondition>;
  filter?: Maybe<ReserveFilter>;
};

export type RateAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<RateSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<RateDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<RateMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<RateMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<RateAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<RateStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<RateStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<RateVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<RateVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Rate` object types. */
export type RateAggregatesFilter = {
  /** A filter that must pass for the relevant `Rate` object to be included within the aggregate. */
  filter?: Maybe<RateFilter>;
  /** Sum aggregate over matching `Rate` objects. */
  sum?: Maybe<RateSumAggregateFilter>;
  /** Distinct count aggregate over matching `Rate` objects. */
  distinctCount?: Maybe<RateDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Rate` objects. */
  min?: Maybe<RateMinAggregateFilter>;
  /** Maximum aggregate over matching `Rate` objects. */
  max?: Maybe<RateMaxAggregateFilter>;
  /** Mean average aggregate over matching `Rate` objects. */
  average?: Maybe<RateAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Rate` objects. */
  stddevSample?: Maybe<RateStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Rate` objects. */
  stddevPopulation?: Maybe<RateStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Rate` objects. */
  varianceSample?: Maybe<RateVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Rate` objects. */
  variancePopulation?: Maybe<RateVariancePopulationAggregateFilter>;
};

export type RateAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  minTerm?: Maybe<BigFloatFilter>;
  maxTerm?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  rate?: Maybe<BigFloatFilter>;
  usedCarAdvanceRate?: Maybe<BigFloatFilter>;
  newCarAdvanceRate?: Maybe<BigFloatFilter>;
  addOn?: Maybe<BigFloatFilter>;
  lenderAdminFee?: Maybe<BigFloatFilter>;
};

export type RateAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of minTerm across the matching connection */
  minTerm?: Maybe<Scalars['BigFloat']>;
  /** Mean average of maxTerm across the matching connection */
  maxTerm?: Maybe<Scalars['BigFloat']>;
  /** Mean average of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Mean average of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Mean average of rate across the matching connection */
  rate?: Maybe<Scalars['BigFloat']>;
  /** Mean average of usedCarAdvanceRate across the matching connection */
  usedCarAdvanceRate?: Maybe<Scalars['BigFloat']>;
  /** Mean average of newCarAdvanceRate across the matching connection */
  newCarAdvanceRate?: Maybe<Scalars['BigFloat']>;
  /** Mean average of addOn across the matching connection */
  addOn?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderAdminFee across the matching connection */
  lenderAdminFee?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Rate` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RateCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lenderCreditKeyId` field. */
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minYear` field. */
  minYear?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxYear` field. */
  maxYear?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minTerm` field. */
  minTerm?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxTerm` field. */
  maxTerm?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minPrice` field. */
  minPrice?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxPrice` field. */
  maxPrice?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rate` field. */
  rate?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `usedCarAdvanceRate` field. */
  usedCarAdvanceRate?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `newCarAdvanceRate` field. */
  newCarAdvanceRate?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `addOn` field. */
  addOn?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lenderAdminFee` field. */
  lenderAdminFee?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type RateDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderCreditKeyId?: Maybe<BigIntFilter>;
  minYear?: Maybe<BigIntFilter>;
  maxYear?: Maybe<BigIntFilter>;
  minTerm?: Maybe<BigIntFilter>;
  maxTerm?: Maybe<BigIntFilter>;
  minPrice?: Maybe<BigIntFilter>;
  maxPrice?: Maybe<BigIntFilter>;
  rate?: Maybe<BigIntFilter>;
  usedCarAdvanceRate?: Maybe<BigIntFilter>;
  newCarAdvanceRate?: Maybe<BigIntFilter>;
  addOn?: Maybe<BigIntFilter>;
  lenderAdminFee?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type RateDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minTerm across the matching connection */
  minTerm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxTerm across the matching connection */
  maxTerm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rate across the matching connection */
  rate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of usedCarAdvanceRate across the matching connection */
  usedCarAdvanceRate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of newCarAdvanceRate across the matching connection */
  newCarAdvanceRate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of addOn across the matching connection */
  addOn?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderAdminFee across the matching connection */
  lenderAdminFee?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Rate` object types. All fields are combined with a logical ‘and.’ */
export type RateFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `lenderCreditKeyId` field. */
  lenderCreditKeyId?: Maybe<IntFilter>;
  /** Filter by the object’s `minYear` field. */
  minYear?: Maybe<IntFilter>;
  /** Filter by the object’s `maxYear` field. */
  maxYear?: Maybe<IntFilter>;
  /** Filter by the object’s `minTerm` field. */
  minTerm?: Maybe<IntFilter>;
  /** Filter by the object’s `maxTerm` field. */
  maxTerm?: Maybe<IntFilter>;
  /** Filter by the object’s `minPrice` field. */
  minPrice?: Maybe<IntFilter>;
  /** Filter by the object’s `maxPrice` field. */
  maxPrice?: Maybe<IntFilter>;
  /** Filter by the object’s `rate` field. */
  rate?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `usedCarAdvanceRate` field. */
  usedCarAdvanceRate?: Maybe<IntFilter>;
  /** Filter by the object’s `newCarAdvanceRate` field. */
  newCarAdvanceRate?: Maybe<IntFilter>;
  /** Filter by the object’s `addOn` field. */
  addOn?: Maybe<IntFilter>;
  /** Filter by the object’s `lenderAdminFee` field. */
  lenderAdminFee?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `reserves` relation. */
  reserves?: Maybe<RateToManyReserveFilter>;
  /** Some related `reserves` exist. */
  reservesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lenderCreditKey` relation. */
  lenderCreditKey?: Maybe<LenderCreditKeyFilter>;
  /** A related `lenderCreditKey` exists. */
  lenderCreditKeyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<RateFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<RateFilter>>;
  /** Negates the expression. */
  not?: Maybe<RateFilter>;
};

/** Grouping methods for `Rate` for usage during aggregation. */
export enum RateGroupBy {
  LenderCreditKeyId = 'LENDER_CREDIT_KEY_ID',
  MinYear = 'MIN_YEAR',
  MaxYear = 'MAX_YEAR',
  MinTerm = 'MIN_TERM',
  MaxTerm = 'MAX_TERM',
  MinPrice = 'MIN_PRICE',
  MaxPrice = 'MAX_PRICE',
  Rate = 'RATE',
  UsedCarAdvanceRate = 'USED_CAR_ADVANCE_RATE',
  NewCarAdvanceRate = 'NEW_CAR_ADVANCE_RATE',
  AddOn = 'ADD_ON',
  LenderAdminFee = 'LENDER_ADMIN_FEE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type RateHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  minTerm?: Maybe<HavingIntFilter>;
  maxTerm?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  rate?: Maybe<HavingBigfloatFilter>;
  usedCarAdvanceRate?: Maybe<HavingIntFilter>;
  newCarAdvanceRate?: Maybe<HavingIntFilter>;
  addOn?: Maybe<HavingIntFilter>;
  lenderAdminFee?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type RateHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  minTerm?: Maybe<HavingIntFilter>;
  maxTerm?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  rate?: Maybe<HavingBigfloatFilter>;
  usedCarAdvanceRate?: Maybe<HavingIntFilter>;
  newCarAdvanceRate?: Maybe<HavingIntFilter>;
  addOn?: Maybe<HavingIntFilter>;
  lenderAdminFee?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Rate` aggregates. */
export type RateHavingInput = {
  AND?: Maybe<Array<RateHavingInput>>;
  OR?: Maybe<Array<RateHavingInput>>;
  sum?: Maybe<RateHavingSumInput>;
  distinctCount?: Maybe<RateHavingDistinctCountInput>;
  min?: Maybe<RateHavingMinInput>;
  max?: Maybe<RateHavingMaxInput>;
  average?: Maybe<RateHavingAverageInput>;
  stddevSample?: Maybe<RateHavingStddevSampleInput>;
  stddevPopulation?: Maybe<RateHavingStddevPopulationInput>;
  varianceSample?: Maybe<RateHavingVarianceSampleInput>;
  variancePopulation?: Maybe<RateHavingVariancePopulationInput>;
};

export type RateHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  minTerm?: Maybe<HavingIntFilter>;
  maxTerm?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  rate?: Maybe<HavingBigfloatFilter>;
  usedCarAdvanceRate?: Maybe<HavingIntFilter>;
  newCarAdvanceRate?: Maybe<HavingIntFilter>;
  addOn?: Maybe<HavingIntFilter>;
  lenderAdminFee?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type RateHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  minTerm?: Maybe<HavingIntFilter>;
  maxTerm?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  rate?: Maybe<HavingBigfloatFilter>;
  usedCarAdvanceRate?: Maybe<HavingIntFilter>;
  newCarAdvanceRate?: Maybe<HavingIntFilter>;
  addOn?: Maybe<HavingIntFilter>;
  lenderAdminFee?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type RateHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  minTerm?: Maybe<HavingIntFilter>;
  maxTerm?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  rate?: Maybe<HavingBigfloatFilter>;
  usedCarAdvanceRate?: Maybe<HavingIntFilter>;
  newCarAdvanceRate?: Maybe<HavingIntFilter>;
  addOn?: Maybe<HavingIntFilter>;
  lenderAdminFee?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type RateHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  minTerm?: Maybe<HavingIntFilter>;
  maxTerm?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  rate?: Maybe<HavingBigfloatFilter>;
  usedCarAdvanceRate?: Maybe<HavingIntFilter>;
  newCarAdvanceRate?: Maybe<HavingIntFilter>;
  addOn?: Maybe<HavingIntFilter>;
  lenderAdminFee?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type RateHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  minTerm?: Maybe<HavingIntFilter>;
  maxTerm?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  rate?: Maybe<HavingBigfloatFilter>;
  usedCarAdvanceRate?: Maybe<HavingIntFilter>;
  newCarAdvanceRate?: Maybe<HavingIntFilter>;
  addOn?: Maybe<HavingIntFilter>;
  lenderAdminFee?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type RateHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  minTerm?: Maybe<HavingIntFilter>;
  maxTerm?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  rate?: Maybe<HavingBigfloatFilter>;
  usedCarAdvanceRate?: Maybe<HavingIntFilter>;
  newCarAdvanceRate?: Maybe<HavingIntFilter>;
  addOn?: Maybe<HavingIntFilter>;
  lenderAdminFee?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type RateHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderCreditKeyId?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  minTerm?: Maybe<HavingIntFilter>;
  maxTerm?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  rate?: Maybe<HavingBigfloatFilter>;
  usedCarAdvanceRate?: Maybe<HavingIntFilter>;
  newCarAdvanceRate?: Maybe<HavingIntFilter>;
  addOn?: Maybe<HavingIntFilter>;
  lenderAdminFee?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Rate` */
export type RateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  minTerm?: Maybe<Scalars['Int']>;
  maxTerm?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['BigFloat']>;
  usedCarAdvanceRate?: Maybe<Scalars['Int']>;
  newCarAdvanceRate?: Maybe<Scalars['Int']>;
  addOn?: Maybe<Scalars['Int']>;
  lenderAdminFee?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<RateLenderCreditKeyIdFkeyInput>;
  reservesUsingId?: Maybe<ReserveRateIdFkeyInverseInput>;
};

/** Input for the nested mutation of `lenderCreditKey` in the `RateInput` mutation. */
export type RateLenderCreditKeyIdFkeyInput = {
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  connectById?: Maybe<LenderCreditKeyLenderCreditKeyPkeyConnect>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  connectByNodeId?: Maybe<LenderCreditKeyNodeIdConnect>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  deleteById?: Maybe<LenderCreditKeyLenderCreditKeyPkeyDelete>;
  /** The primary key(s) for `lenderCreditKey` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LenderCreditKeyNodeIdDelete>;
  /** The primary key(s) and patch data for `lenderCreditKey` for the far side of the relationship. */
  updateById?: Maybe<LenderCreditKeyOnRateForRateLenderCreditKeyIdFkeyUsingLenderCreditKeyPkeyUpdate>;
  /** The primary key(s) and patch data for `lenderCreditKey` for the far side of the relationship. */
  updateByNodeId?: Maybe<RateOnRateForRateLenderCreditKeyIdFkeyNodeIdUpdate>;
  /** A `LenderCreditKeyInput` object that will be created and connected to this object. */
  create?: Maybe<RateLenderCreditKeyIdFkeyLenderCreditKeyCreateInput>;
};

/** Input for the nested mutation of `rate` in the `LenderCreditKeyInput` mutation. */
export type RateLenderCreditKeyIdFkeyInverseInput = {
  /** Flag indicating whether all other `rate` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `rate` for the far side of the relationship. */
  connectById?: Maybe<Array<RateRatePkeyConnect>>;
  /** The primary key(s) for `rate` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<RateNodeIdConnect>>;
  /** The primary key(s) for `rate` for the far side of the relationship. */
  deleteById?: Maybe<Array<RateRatePkeyDelete>>;
  /** The primary key(s) for `rate` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<RateNodeIdDelete>>;
  /** The primary key(s) and patch data for `rate` for the far side of the relationship. */
  updateById?: Maybe<Array<RateOnRateForRateLenderCreditKeyIdFkeyUsingRatePkeyUpdate>>;
  /** The primary key(s) and patch data for `rate` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LenderCreditKeyOnRateForRateLenderCreditKeyIdFkeyNodeIdUpdate>>;
  /** A `RateInput` object that will be created and connected to this object. */
  create?: Maybe<Array<RateLenderCreditKeyIdFkeyRateCreateInput>>;
};

/** The `lenderCreditKey` to be created by this mutation. */
export type RateLenderCreditKeyIdFkeyLenderCreditKeyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  minScore?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInput>;
  ratesUsingId?: Maybe<RateLenderCreditKeyIdFkeyInverseInput>;
  lenderProgramBonusesUsingId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInverseInput>;
  maxAmortizationsUsingId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInverseInput>;
};

/** The `rate` to be created by this mutation. */
export type RateLenderCreditKeyIdFkeyRateCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  minTerm?: Maybe<Scalars['Int']>;
  maxTerm?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['BigFloat']>;
  usedCarAdvanceRate?: Maybe<Scalars['Int']>;
  newCarAdvanceRate?: Maybe<Scalars['Int']>;
  addOn?: Maybe<Scalars['Int']>;
  lenderAdminFee?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<RateLenderCreditKeyIdFkeyInput>;
  reservesUsingId?: Maybe<ReserveRateIdFkeyInverseInput>;
};

export type RateMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderCreditKeyId?: Maybe<IntFilter>;
  minYear?: Maybe<IntFilter>;
  maxYear?: Maybe<IntFilter>;
  minTerm?: Maybe<IntFilter>;
  maxTerm?: Maybe<IntFilter>;
  minPrice?: Maybe<IntFilter>;
  maxPrice?: Maybe<IntFilter>;
  rate?: Maybe<BigFloatFilter>;
  usedCarAdvanceRate?: Maybe<IntFilter>;
  newCarAdvanceRate?: Maybe<IntFilter>;
  addOn?: Maybe<IntFilter>;
  lenderAdminFee?: Maybe<IntFilter>;
};

export type RateMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  /** Maximum of minYear across the matching connection */
  minYear?: Maybe<Scalars['Int']>;
  /** Maximum of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['Int']>;
  /** Maximum of minTerm across the matching connection */
  minTerm?: Maybe<Scalars['Int']>;
  /** Maximum of maxTerm across the matching connection */
  maxTerm?: Maybe<Scalars['Int']>;
  /** Maximum of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['Int']>;
  /** Maximum of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['Int']>;
  /** Maximum of rate across the matching connection */
  rate?: Maybe<Scalars['BigFloat']>;
  /** Maximum of usedCarAdvanceRate across the matching connection */
  usedCarAdvanceRate?: Maybe<Scalars['Int']>;
  /** Maximum of newCarAdvanceRate across the matching connection */
  newCarAdvanceRate?: Maybe<Scalars['Int']>;
  /** Maximum of addOn across the matching connection */
  addOn?: Maybe<Scalars['Int']>;
  /** Maximum of lenderAdminFee across the matching connection */
  lenderAdminFee?: Maybe<Scalars['Int']>;
};

export type RateMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderCreditKeyId?: Maybe<IntFilter>;
  minYear?: Maybe<IntFilter>;
  maxYear?: Maybe<IntFilter>;
  minTerm?: Maybe<IntFilter>;
  maxTerm?: Maybe<IntFilter>;
  minPrice?: Maybe<IntFilter>;
  maxPrice?: Maybe<IntFilter>;
  rate?: Maybe<BigFloatFilter>;
  usedCarAdvanceRate?: Maybe<IntFilter>;
  newCarAdvanceRate?: Maybe<IntFilter>;
  addOn?: Maybe<IntFilter>;
  lenderAdminFee?: Maybe<IntFilter>;
};

export type RateMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  /** Minimum of minYear across the matching connection */
  minYear?: Maybe<Scalars['Int']>;
  /** Minimum of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['Int']>;
  /** Minimum of minTerm across the matching connection */
  minTerm?: Maybe<Scalars['Int']>;
  /** Minimum of maxTerm across the matching connection */
  maxTerm?: Maybe<Scalars['Int']>;
  /** Minimum of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['Int']>;
  /** Minimum of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['Int']>;
  /** Minimum of rate across the matching connection */
  rate?: Maybe<Scalars['BigFloat']>;
  /** Minimum of usedCarAdvanceRate across the matching connection */
  usedCarAdvanceRate?: Maybe<Scalars['Int']>;
  /** Minimum of newCarAdvanceRate across the matching connection */
  newCarAdvanceRate?: Maybe<Scalars['Int']>;
  /** Minimum of addOn across the matching connection */
  addOn?: Maybe<Scalars['Int']>;
  /** Minimum of lenderAdminFee across the matching connection */
  lenderAdminFee?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type RateNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `rate` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type RateNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `rate` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type RateOnRateForRateLenderCreditKeyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderCreditKey` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
  patch: LenderCreditKeyPatch;
};

/** The fields on `rate` to look up the row to update. */
export type RateOnRateForRateLenderCreditKeyIdFkeyUsingRatePkeyUpdate = {
  /** An object where the defined keys will be set on the `rate` being updated. */
  patch: UpdateRateOnRateForRateLenderCreditKeyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type RateOnReserveForReserveRateIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `reserve` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `reserve` being updated. */
  patch: ReservePatch;
};

/** The fields on `rate` to look up the row to update. */
export type RateOnReserveForReserveRateIdFkeyUsingRatePkeyUpdate = {
  /** An object where the defined keys will be set on the `rate` being updated. */
  patch: UpdateRateOnReserveForReserveRateIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Rate`. Fields that are set will be updated. */
export type RatePatch = {
  id?: Maybe<Scalars['Int']>;
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  minTerm?: Maybe<Scalars['Int']>;
  maxTerm?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['BigFloat']>;
  usedCarAdvanceRate?: Maybe<Scalars['Int']>;
  newCarAdvanceRate?: Maybe<Scalars['Int']>;
  addOn?: Maybe<Scalars['Int']>;
  lenderAdminFee?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<RateLenderCreditKeyIdFkeyInput>;
  reservesUsingId?: Maybe<ReserveRateIdFkeyInverseInput>;
};

/** The fields on `rate` to look up the row to connect. */
export type RateRatePkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `rate` to look up the row to delete. */
export type RateRatePkeyDelete = {
  id: Scalars['Int'];
};

export type RateStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  minTerm?: Maybe<BigFloatFilter>;
  maxTerm?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  rate?: Maybe<BigFloatFilter>;
  usedCarAdvanceRate?: Maybe<BigFloatFilter>;
  newCarAdvanceRate?: Maybe<BigFloatFilter>;
  addOn?: Maybe<BigFloatFilter>;
  lenderAdminFee?: Maybe<BigFloatFilter>;
};

export type RateStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of minTerm across the matching connection */
  minTerm?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of maxTerm across the matching connection */
  maxTerm?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of rate across the matching connection */
  rate?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of usedCarAdvanceRate across the matching connection */
  usedCarAdvanceRate?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of newCarAdvanceRate across the matching connection */
  newCarAdvanceRate?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of addOn across the matching connection */
  addOn?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderAdminFee across the matching connection */
  lenderAdminFee?: Maybe<Scalars['BigFloat']>;
};

export type RateStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  minTerm?: Maybe<BigFloatFilter>;
  maxTerm?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  rate?: Maybe<BigFloatFilter>;
  usedCarAdvanceRate?: Maybe<BigFloatFilter>;
  newCarAdvanceRate?: Maybe<BigFloatFilter>;
  addOn?: Maybe<BigFloatFilter>;
  lenderAdminFee?: Maybe<BigFloatFilter>;
};

export type RateStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of minTerm across the matching connection */
  minTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of maxTerm across the matching connection */
  maxTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of rate across the matching connection */
  rate?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of usedCarAdvanceRate across the matching connection */
  usedCarAdvanceRate?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of newCarAdvanceRate across the matching connection */
  newCarAdvanceRate?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of addOn across the matching connection */
  addOn?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderAdminFee across the matching connection */
  lenderAdminFee?: Maybe<Scalars['BigFloat']>;
};

export type RateSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderCreditKeyId?: Maybe<BigIntFilter>;
  minYear?: Maybe<BigIntFilter>;
  maxYear?: Maybe<BigIntFilter>;
  minTerm?: Maybe<BigIntFilter>;
  maxTerm?: Maybe<BigIntFilter>;
  minPrice?: Maybe<BigIntFilter>;
  maxPrice?: Maybe<BigIntFilter>;
  rate?: Maybe<BigFloatFilter>;
  usedCarAdvanceRate?: Maybe<BigIntFilter>;
  newCarAdvanceRate?: Maybe<BigIntFilter>;
  addOn?: Maybe<BigIntFilter>;
  lenderAdminFee?: Maybe<BigIntFilter>;
};

export type RateSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId: Scalars['BigInt'];
  /** Sum of minYear across the matching connection */
  minYear: Scalars['BigInt'];
  /** Sum of maxYear across the matching connection */
  maxYear: Scalars['BigInt'];
  /** Sum of minTerm across the matching connection */
  minTerm: Scalars['BigInt'];
  /** Sum of maxTerm across the matching connection */
  maxTerm: Scalars['BigInt'];
  /** Sum of minPrice across the matching connection */
  minPrice: Scalars['BigInt'];
  /** Sum of maxPrice across the matching connection */
  maxPrice: Scalars['BigInt'];
  /** Sum of rate across the matching connection */
  rate: Scalars['BigFloat'];
  /** Sum of usedCarAdvanceRate across the matching connection */
  usedCarAdvanceRate: Scalars['BigInt'];
  /** Sum of newCarAdvanceRate across the matching connection */
  newCarAdvanceRate: Scalars['BigInt'];
  /** Sum of addOn across the matching connection */
  addOn: Scalars['BigInt'];
  /** Sum of lenderAdminFee across the matching connection */
  lenderAdminFee: Scalars['BigInt'];
};

/** A filter to be used against many `Reserve` object types. All fields are combined with a logical ‘and.’ */
export type RateToManyReserveFilter = {
  /** Every related `Reserve` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ReserveFilter>;
  /** Some related `Reserve` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ReserveFilter>;
  /** No related `Reserve` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ReserveFilter>;
  /** Aggregates across related `Reserve` match the filter criteria. */
  aggregates?: Maybe<ReserveAggregatesFilter>;
};

export type RateVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  minTerm?: Maybe<BigFloatFilter>;
  maxTerm?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  rate?: Maybe<BigFloatFilter>;
  usedCarAdvanceRate?: Maybe<BigFloatFilter>;
  newCarAdvanceRate?: Maybe<BigFloatFilter>;
  addOn?: Maybe<BigFloatFilter>;
  lenderAdminFee?: Maybe<BigFloatFilter>;
};

export type RateVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of minTerm across the matching connection */
  minTerm?: Maybe<Scalars['BigFloat']>;
  /** Population variance of maxTerm across the matching connection */
  maxTerm?: Maybe<Scalars['BigFloat']>;
  /** Population variance of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Population variance of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Population variance of rate across the matching connection */
  rate?: Maybe<Scalars['BigFloat']>;
  /** Population variance of usedCarAdvanceRate across the matching connection */
  usedCarAdvanceRate?: Maybe<Scalars['BigFloat']>;
  /** Population variance of newCarAdvanceRate across the matching connection */
  newCarAdvanceRate?: Maybe<Scalars['BigFloat']>;
  /** Population variance of addOn across the matching connection */
  addOn?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderAdminFee across the matching connection */
  lenderAdminFee?: Maybe<Scalars['BigFloat']>;
};

export type RateVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderCreditKeyId?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  minTerm?: Maybe<BigFloatFilter>;
  maxTerm?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  rate?: Maybe<BigFloatFilter>;
  usedCarAdvanceRate?: Maybe<BigFloatFilter>;
  newCarAdvanceRate?: Maybe<BigFloatFilter>;
  addOn?: Maybe<BigFloatFilter>;
  lenderAdminFee?: Maybe<BigFloatFilter>;
};

export type RateVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderCreditKeyId across the matching connection */
  lenderCreditKeyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of minTerm across the matching connection */
  minTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of maxTerm across the matching connection */
  maxTerm?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of rate across the matching connection */
  rate?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of usedCarAdvanceRate across the matching connection */
  usedCarAdvanceRate?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of newCarAdvanceRate across the matching connection */
  newCarAdvanceRate?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of addOn across the matching connection */
  addOn?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderAdminFee across the matching connection */
  lenderAdminFee?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Rate` values. */
export type RatesConnection = {
  /** A list of `Rate` objects. */
  nodes: Array<Rate>;
  /** A list of edges which contains the `Rate` and cursor to aid in pagination. */
  edges: Array<RatesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Rate` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<RateAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<RateAggregates>>;
};


/** A connection to a list of `Rate` values. */
export type RatesConnectionGroupedAggregatesArgs = {
  groupBy: Array<RateGroupBy>;
  having?: Maybe<RateHavingInput>;
};

/** A `Rate` edge in the connection. */
export type RatesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Rate` at the end of the edge. */
  node: Rate;
};

/** Methods to use when ordering `Rate`. */
export enum RatesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LenderCreditKeyIdAsc = 'LENDER_CREDIT_KEY_ID_ASC',
  LenderCreditKeyIdDesc = 'LENDER_CREDIT_KEY_ID_DESC',
  MinYearAsc = 'MIN_YEAR_ASC',
  MinYearDesc = 'MIN_YEAR_DESC',
  MaxYearAsc = 'MAX_YEAR_ASC',
  MaxYearDesc = 'MAX_YEAR_DESC',
  MinTermAsc = 'MIN_TERM_ASC',
  MinTermDesc = 'MIN_TERM_DESC',
  MaxTermAsc = 'MAX_TERM_ASC',
  MaxTermDesc = 'MAX_TERM_DESC',
  MinPriceAsc = 'MIN_PRICE_ASC',
  MinPriceDesc = 'MIN_PRICE_DESC',
  MaxPriceAsc = 'MAX_PRICE_ASC',
  MaxPriceDesc = 'MAX_PRICE_DESC',
  RateAsc = 'RATE_ASC',
  RateDesc = 'RATE_DESC',
  UsedCarAdvanceRateAsc = 'USED_CAR_ADVANCE_RATE_ASC',
  UsedCarAdvanceRateDesc = 'USED_CAR_ADVANCE_RATE_DESC',
  NewCarAdvanceRateAsc = 'NEW_CAR_ADVANCE_RATE_ASC',
  NewCarAdvanceRateDesc = 'NEW_CAR_ADVANCE_RATE_DESC',
  AddOnAsc = 'ADD_ON_ASC',
  AddOnDesc = 'ADD_ON_DESC',
  LenderAdminFeeAsc = 'LENDER_ADMIN_FEE_ASC',
  LenderAdminFeeDesc = 'LENDER_ADMIN_FEE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReservesCountAsc = 'RESERVES_COUNT_ASC',
  ReservesCountDesc = 'RESERVES_COUNT_DESC',
  ReservesSumIdAsc = 'RESERVES_SUM_ID_ASC',
  ReservesSumIdDesc = 'RESERVES_SUM_ID_DESC',
  ReservesSumRateIdAsc = 'RESERVES_SUM_RATE_ID_ASC',
  ReservesSumRateIdDesc = 'RESERVES_SUM_RATE_ID_DESC',
  ReservesSumMinPriceAsc = 'RESERVES_SUM_MIN_PRICE_ASC',
  ReservesSumMinPriceDesc = 'RESERVES_SUM_MIN_PRICE_DESC',
  ReservesSumMaxPriceAsc = 'RESERVES_SUM_MAX_PRICE_ASC',
  ReservesSumMaxPriceDesc = 'RESERVES_SUM_MAX_PRICE_DESC',
  ReservesSumCreditQualityAsc = 'RESERVES_SUM_CREDIT_QUALITY_ASC',
  ReservesSumCreditQualityDesc = 'RESERVES_SUM_CREDIT_QUALITY_DESC',
  ReservesSumReserveAsc = 'RESERVES_SUM_RESERVE_ASC',
  ReservesSumReserveDesc = 'RESERVES_SUM_RESERVE_DESC',
  ReservesSumCreatedAtAsc = 'RESERVES_SUM_CREATED_AT_ASC',
  ReservesSumCreatedAtDesc = 'RESERVES_SUM_CREATED_AT_DESC',
  ReservesSumUpdatedAtAsc = 'RESERVES_SUM_UPDATED_AT_ASC',
  ReservesSumUpdatedAtDesc = 'RESERVES_SUM_UPDATED_AT_DESC',
  ReservesDistinctCountIdAsc = 'RESERVES_DISTINCT_COUNT_ID_ASC',
  ReservesDistinctCountIdDesc = 'RESERVES_DISTINCT_COUNT_ID_DESC',
  ReservesDistinctCountRateIdAsc = 'RESERVES_DISTINCT_COUNT_RATE_ID_ASC',
  ReservesDistinctCountRateIdDesc = 'RESERVES_DISTINCT_COUNT_RATE_ID_DESC',
  ReservesDistinctCountMinPriceAsc = 'RESERVES_DISTINCT_COUNT_MIN_PRICE_ASC',
  ReservesDistinctCountMinPriceDesc = 'RESERVES_DISTINCT_COUNT_MIN_PRICE_DESC',
  ReservesDistinctCountMaxPriceAsc = 'RESERVES_DISTINCT_COUNT_MAX_PRICE_ASC',
  ReservesDistinctCountMaxPriceDesc = 'RESERVES_DISTINCT_COUNT_MAX_PRICE_DESC',
  ReservesDistinctCountCreditQualityAsc = 'RESERVES_DISTINCT_COUNT_CREDIT_QUALITY_ASC',
  ReservesDistinctCountCreditQualityDesc = 'RESERVES_DISTINCT_COUNT_CREDIT_QUALITY_DESC',
  ReservesDistinctCountReserveAsc = 'RESERVES_DISTINCT_COUNT_RESERVE_ASC',
  ReservesDistinctCountReserveDesc = 'RESERVES_DISTINCT_COUNT_RESERVE_DESC',
  ReservesDistinctCountCreatedAtAsc = 'RESERVES_DISTINCT_COUNT_CREATED_AT_ASC',
  ReservesDistinctCountCreatedAtDesc = 'RESERVES_DISTINCT_COUNT_CREATED_AT_DESC',
  ReservesDistinctCountUpdatedAtAsc = 'RESERVES_DISTINCT_COUNT_UPDATED_AT_ASC',
  ReservesDistinctCountUpdatedAtDesc = 'RESERVES_DISTINCT_COUNT_UPDATED_AT_DESC',
  ReservesMinIdAsc = 'RESERVES_MIN_ID_ASC',
  ReservesMinIdDesc = 'RESERVES_MIN_ID_DESC',
  ReservesMinRateIdAsc = 'RESERVES_MIN_RATE_ID_ASC',
  ReservesMinRateIdDesc = 'RESERVES_MIN_RATE_ID_DESC',
  ReservesMinMinPriceAsc = 'RESERVES_MIN_MIN_PRICE_ASC',
  ReservesMinMinPriceDesc = 'RESERVES_MIN_MIN_PRICE_DESC',
  ReservesMinMaxPriceAsc = 'RESERVES_MIN_MAX_PRICE_ASC',
  ReservesMinMaxPriceDesc = 'RESERVES_MIN_MAX_PRICE_DESC',
  ReservesMinCreditQualityAsc = 'RESERVES_MIN_CREDIT_QUALITY_ASC',
  ReservesMinCreditQualityDesc = 'RESERVES_MIN_CREDIT_QUALITY_DESC',
  ReservesMinReserveAsc = 'RESERVES_MIN_RESERVE_ASC',
  ReservesMinReserveDesc = 'RESERVES_MIN_RESERVE_DESC',
  ReservesMinCreatedAtAsc = 'RESERVES_MIN_CREATED_AT_ASC',
  ReservesMinCreatedAtDesc = 'RESERVES_MIN_CREATED_AT_DESC',
  ReservesMinUpdatedAtAsc = 'RESERVES_MIN_UPDATED_AT_ASC',
  ReservesMinUpdatedAtDesc = 'RESERVES_MIN_UPDATED_AT_DESC',
  ReservesMaxIdAsc = 'RESERVES_MAX_ID_ASC',
  ReservesMaxIdDesc = 'RESERVES_MAX_ID_DESC',
  ReservesMaxRateIdAsc = 'RESERVES_MAX_RATE_ID_ASC',
  ReservesMaxRateIdDesc = 'RESERVES_MAX_RATE_ID_DESC',
  ReservesMaxMinPriceAsc = 'RESERVES_MAX_MIN_PRICE_ASC',
  ReservesMaxMinPriceDesc = 'RESERVES_MAX_MIN_PRICE_DESC',
  ReservesMaxMaxPriceAsc = 'RESERVES_MAX_MAX_PRICE_ASC',
  ReservesMaxMaxPriceDesc = 'RESERVES_MAX_MAX_PRICE_DESC',
  ReservesMaxCreditQualityAsc = 'RESERVES_MAX_CREDIT_QUALITY_ASC',
  ReservesMaxCreditQualityDesc = 'RESERVES_MAX_CREDIT_QUALITY_DESC',
  ReservesMaxReserveAsc = 'RESERVES_MAX_RESERVE_ASC',
  ReservesMaxReserveDesc = 'RESERVES_MAX_RESERVE_DESC',
  ReservesMaxCreatedAtAsc = 'RESERVES_MAX_CREATED_AT_ASC',
  ReservesMaxCreatedAtDesc = 'RESERVES_MAX_CREATED_AT_DESC',
  ReservesMaxUpdatedAtAsc = 'RESERVES_MAX_UPDATED_AT_ASC',
  ReservesMaxUpdatedAtDesc = 'RESERVES_MAX_UPDATED_AT_DESC',
  ReservesAverageIdAsc = 'RESERVES_AVERAGE_ID_ASC',
  ReservesAverageIdDesc = 'RESERVES_AVERAGE_ID_DESC',
  ReservesAverageRateIdAsc = 'RESERVES_AVERAGE_RATE_ID_ASC',
  ReservesAverageRateIdDesc = 'RESERVES_AVERAGE_RATE_ID_DESC',
  ReservesAverageMinPriceAsc = 'RESERVES_AVERAGE_MIN_PRICE_ASC',
  ReservesAverageMinPriceDesc = 'RESERVES_AVERAGE_MIN_PRICE_DESC',
  ReservesAverageMaxPriceAsc = 'RESERVES_AVERAGE_MAX_PRICE_ASC',
  ReservesAverageMaxPriceDesc = 'RESERVES_AVERAGE_MAX_PRICE_DESC',
  ReservesAverageCreditQualityAsc = 'RESERVES_AVERAGE_CREDIT_QUALITY_ASC',
  ReservesAverageCreditQualityDesc = 'RESERVES_AVERAGE_CREDIT_QUALITY_DESC',
  ReservesAverageReserveAsc = 'RESERVES_AVERAGE_RESERVE_ASC',
  ReservesAverageReserveDesc = 'RESERVES_AVERAGE_RESERVE_DESC',
  ReservesAverageCreatedAtAsc = 'RESERVES_AVERAGE_CREATED_AT_ASC',
  ReservesAverageCreatedAtDesc = 'RESERVES_AVERAGE_CREATED_AT_DESC',
  ReservesAverageUpdatedAtAsc = 'RESERVES_AVERAGE_UPDATED_AT_ASC',
  ReservesAverageUpdatedAtDesc = 'RESERVES_AVERAGE_UPDATED_AT_DESC',
  ReservesStddevSampleIdAsc = 'RESERVES_STDDEV_SAMPLE_ID_ASC',
  ReservesStddevSampleIdDesc = 'RESERVES_STDDEV_SAMPLE_ID_DESC',
  ReservesStddevSampleRateIdAsc = 'RESERVES_STDDEV_SAMPLE_RATE_ID_ASC',
  ReservesStddevSampleRateIdDesc = 'RESERVES_STDDEV_SAMPLE_RATE_ID_DESC',
  ReservesStddevSampleMinPriceAsc = 'RESERVES_STDDEV_SAMPLE_MIN_PRICE_ASC',
  ReservesStddevSampleMinPriceDesc = 'RESERVES_STDDEV_SAMPLE_MIN_PRICE_DESC',
  ReservesStddevSampleMaxPriceAsc = 'RESERVES_STDDEV_SAMPLE_MAX_PRICE_ASC',
  ReservesStddevSampleMaxPriceDesc = 'RESERVES_STDDEV_SAMPLE_MAX_PRICE_DESC',
  ReservesStddevSampleCreditQualityAsc = 'RESERVES_STDDEV_SAMPLE_CREDIT_QUALITY_ASC',
  ReservesStddevSampleCreditQualityDesc = 'RESERVES_STDDEV_SAMPLE_CREDIT_QUALITY_DESC',
  ReservesStddevSampleReserveAsc = 'RESERVES_STDDEV_SAMPLE_RESERVE_ASC',
  ReservesStddevSampleReserveDesc = 'RESERVES_STDDEV_SAMPLE_RESERVE_DESC',
  ReservesStddevSampleCreatedAtAsc = 'RESERVES_STDDEV_SAMPLE_CREATED_AT_ASC',
  ReservesStddevSampleCreatedAtDesc = 'RESERVES_STDDEV_SAMPLE_CREATED_AT_DESC',
  ReservesStddevSampleUpdatedAtAsc = 'RESERVES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ReservesStddevSampleUpdatedAtDesc = 'RESERVES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ReservesStddevPopulationIdAsc = 'RESERVES_STDDEV_POPULATION_ID_ASC',
  ReservesStddevPopulationIdDesc = 'RESERVES_STDDEV_POPULATION_ID_DESC',
  ReservesStddevPopulationRateIdAsc = 'RESERVES_STDDEV_POPULATION_RATE_ID_ASC',
  ReservesStddevPopulationRateIdDesc = 'RESERVES_STDDEV_POPULATION_RATE_ID_DESC',
  ReservesStddevPopulationMinPriceAsc = 'RESERVES_STDDEV_POPULATION_MIN_PRICE_ASC',
  ReservesStddevPopulationMinPriceDesc = 'RESERVES_STDDEV_POPULATION_MIN_PRICE_DESC',
  ReservesStddevPopulationMaxPriceAsc = 'RESERVES_STDDEV_POPULATION_MAX_PRICE_ASC',
  ReservesStddevPopulationMaxPriceDesc = 'RESERVES_STDDEV_POPULATION_MAX_PRICE_DESC',
  ReservesStddevPopulationCreditQualityAsc = 'RESERVES_STDDEV_POPULATION_CREDIT_QUALITY_ASC',
  ReservesStddevPopulationCreditQualityDesc = 'RESERVES_STDDEV_POPULATION_CREDIT_QUALITY_DESC',
  ReservesStddevPopulationReserveAsc = 'RESERVES_STDDEV_POPULATION_RESERVE_ASC',
  ReservesStddevPopulationReserveDesc = 'RESERVES_STDDEV_POPULATION_RESERVE_DESC',
  ReservesStddevPopulationCreatedAtAsc = 'RESERVES_STDDEV_POPULATION_CREATED_AT_ASC',
  ReservesStddevPopulationCreatedAtDesc = 'RESERVES_STDDEV_POPULATION_CREATED_AT_DESC',
  ReservesStddevPopulationUpdatedAtAsc = 'RESERVES_STDDEV_POPULATION_UPDATED_AT_ASC',
  ReservesStddevPopulationUpdatedAtDesc = 'RESERVES_STDDEV_POPULATION_UPDATED_AT_DESC',
  ReservesVarianceSampleIdAsc = 'RESERVES_VARIANCE_SAMPLE_ID_ASC',
  ReservesVarianceSampleIdDesc = 'RESERVES_VARIANCE_SAMPLE_ID_DESC',
  ReservesVarianceSampleRateIdAsc = 'RESERVES_VARIANCE_SAMPLE_RATE_ID_ASC',
  ReservesVarianceSampleRateIdDesc = 'RESERVES_VARIANCE_SAMPLE_RATE_ID_DESC',
  ReservesVarianceSampleMinPriceAsc = 'RESERVES_VARIANCE_SAMPLE_MIN_PRICE_ASC',
  ReservesVarianceSampleMinPriceDesc = 'RESERVES_VARIANCE_SAMPLE_MIN_PRICE_DESC',
  ReservesVarianceSampleMaxPriceAsc = 'RESERVES_VARIANCE_SAMPLE_MAX_PRICE_ASC',
  ReservesVarianceSampleMaxPriceDesc = 'RESERVES_VARIANCE_SAMPLE_MAX_PRICE_DESC',
  ReservesVarianceSampleCreditQualityAsc = 'RESERVES_VARIANCE_SAMPLE_CREDIT_QUALITY_ASC',
  ReservesVarianceSampleCreditQualityDesc = 'RESERVES_VARIANCE_SAMPLE_CREDIT_QUALITY_DESC',
  ReservesVarianceSampleReserveAsc = 'RESERVES_VARIANCE_SAMPLE_RESERVE_ASC',
  ReservesVarianceSampleReserveDesc = 'RESERVES_VARIANCE_SAMPLE_RESERVE_DESC',
  ReservesVarianceSampleCreatedAtAsc = 'RESERVES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ReservesVarianceSampleCreatedAtDesc = 'RESERVES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ReservesVarianceSampleUpdatedAtAsc = 'RESERVES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ReservesVarianceSampleUpdatedAtDesc = 'RESERVES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ReservesVariancePopulationIdAsc = 'RESERVES_VARIANCE_POPULATION_ID_ASC',
  ReservesVariancePopulationIdDesc = 'RESERVES_VARIANCE_POPULATION_ID_DESC',
  ReservesVariancePopulationRateIdAsc = 'RESERVES_VARIANCE_POPULATION_RATE_ID_ASC',
  ReservesVariancePopulationRateIdDesc = 'RESERVES_VARIANCE_POPULATION_RATE_ID_DESC',
  ReservesVariancePopulationMinPriceAsc = 'RESERVES_VARIANCE_POPULATION_MIN_PRICE_ASC',
  ReservesVariancePopulationMinPriceDesc = 'RESERVES_VARIANCE_POPULATION_MIN_PRICE_DESC',
  ReservesVariancePopulationMaxPriceAsc = 'RESERVES_VARIANCE_POPULATION_MAX_PRICE_ASC',
  ReservesVariancePopulationMaxPriceDesc = 'RESERVES_VARIANCE_POPULATION_MAX_PRICE_DESC',
  ReservesVariancePopulationCreditQualityAsc = 'RESERVES_VARIANCE_POPULATION_CREDIT_QUALITY_ASC',
  ReservesVariancePopulationCreditQualityDesc = 'RESERVES_VARIANCE_POPULATION_CREDIT_QUALITY_DESC',
  ReservesVariancePopulationReserveAsc = 'RESERVES_VARIANCE_POPULATION_RESERVE_ASC',
  ReservesVariancePopulationReserveDesc = 'RESERVES_VARIANCE_POPULATION_RESERVE_DESC',
  ReservesVariancePopulationCreatedAtAsc = 'RESERVES_VARIANCE_POPULATION_CREATED_AT_ASC',
  ReservesVariancePopulationCreatedAtDesc = 'RESERVES_VARIANCE_POPULATION_CREATED_AT_DESC',
  ReservesVariancePopulationUpdatedAtAsc = 'RESERVES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ReservesVariancePopulationUpdatedAtDesc = 'RESERVES_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

/** The payload of the recordLeadView mutation */
export type RecordLeadViewResponse = {
  /** The lead ID */
  leadId?: Maybe<Scalars['UUID']>;
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type RemoveUserInput = {
  username: Scalars['String'];
};

export type RenameCompanyInput = {
  companyId: Scalars['Int'];
  newName: Scalars['String'];
};

export type RenameCompanyResponse = {
  success: Scalars['Boolean'];
  oldName: Scalars['String'];
  newName: Scalars['String'];
  oldCognitoName: Scalars['String'];
  newCognitoName: Scalars['String'];
  primaryGroupId: Scalars['Int'];
  usersMoved: Array<UserDetails>;
};

export type RequestAccessResponse = {
  success: Scalars['Boolean'];
  topic: Scalars['String'];
  requestedAccess?: Maybe<Array<CompanyRequestedAccess>>;
};

export type RequestIdVerificationInput = {
  leadId: Scalars['UUID'];
  messageOverride?: Maybe<Scalars['String']>;
  idRequestDeliveryType?: Maybe<IdRequestDeliveryType>;
  phoneNumberOverride?: Maybe<Scalars['String']>;
  emailAddressOverride?: Maybe<Scalars['String']>;
};

export type RequestIdVerificationResponse = {
  success?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use initiateIdVerification from ava-mobile instead, this will be removed after 9th Feb 2023 */
  firstStep?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  /** @deprecated Use initiateIdVerification from ava-mobile instead, this will be removed after 9th Feb 2023 */
  clientToken?: Maybe<Scalars['String']>;
};

export type RequestIncomeVerificationInput = {
  leadId: Scalars['UUID'];
  messageOverride?: Maybe<Scalars['String']>;
};

export type RequestIncomeVerificationResponse = {
  success?: Maybe<Scalars['Boolean']>;
  verificationUrl?: Maybe<Scalars['String']>;
};

export type ResendSignUpConfirmationCodeInput = {
  username: Scalars['String'];
};

export type ResendSignUpConfirmationCodeResponse = {
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type Reserve = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  rateId?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  creditQuality?: Maybe<Scalars['String']>;
  reserve?: Maybe<Scalars['BigFloat']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Rate` that is related to this `Reserve`. */
  rate?: Maybe<Rate>;
};

export type ReserveAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ReserveSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ReserveDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ReserveMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ReserveMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ReserveAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ReserveStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ReserveStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ReserveVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ReserveVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Reserve` object types. */
export type ReserveAggregatesFilter = {
  /** A filter that must pass for the relevant `Reserve` object to be included within the aggregate. */
  filter?: Maybe<ReserveFilter>;
  /** Sum aggregate over matching `Reserve` objects. */
  sum?: Maybe<ReserveSumAggregateFilter>;
  /** Distinct count aggregate over matching `Reserve` objects. */
  distinctCount?: Maybe<ReserveDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Reserve` objects. */
  min?: Maybe<ReserveMinAggregateFilter>;
  /** Maximum aggregate over matching `Reserve` objects. */
  max?: Maybe<ReserveMaxAggregateFilter>;
  /** Mean average aggregate over matching `Reserve` objects. */
  average?: Maybe<ReserveAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Reserve` objects. */
  stddevSample?: Maybe<ReserveStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Reserve` objects. */
  stddevPopulation?: Maybe<ReserveStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Reserve` objects. */
  varianceSample?: Maybe<ReserveVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Reserve` objects. */
  variancePopulation?: Maybe<ReserveVariancePopulationAggregateFilter>;
};

export type ReserveAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  rateId?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  reserve?: Maybe<BigFloatFilter>;
};

export type ReserveAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of rateId across the matching connection */
  rateId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Mean average of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Mean average of reserve across the matching connection */
  reserve?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Reserve` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ReserveCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rateId` field. */
  rateId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minPrice` field. */
  minPrice?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxPrice` field. */
  maxPrice?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `creditQuality` field. */
  creditQuality?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `reserve` field. */
  reserve?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type ReserveDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  rateId?: Maybe<BigIntFilter>;
  minPrice?: Maybe<BigIntFilter>;
  maxPrice?: Maybe<BigIntFilter>;
  creditQuality?: Maybe<BigIntFilter>;
  reserve?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type ReserveDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rateId across the matching connection */
  rateId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditQuality across the matching connection */
  creditQuality?: Maybe<Scalars['BigInt']>;
  /** Distinct count of reserve across the matching connection */
  reserve?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Reserve` object types. All fields are combined with a logical ‘and.’ */
export type ReserveFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `rateId` field. */
  rateId?: Maybe<IntFilter>;
  /** Filter by the object’s `minPrice` field. */
  minPrice?: Maybe<IntFilter>;
  /** Filter by the object’s `maxPrice` field. */
  maxPrice?: Maybe<IntFilter>;
  /** Filter by the object’s `creditQuality` field. */
  creditQuality?: Maybe<StringFilter>;
  /** Filter by the object’s `reserve` field. */
  reserve?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `rate` relation. */
  rate?: Maybe<RateFilter>;
  /** A related `rate` exists. */
  rateExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ReserveFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ReserveFilter>>;
  /** Negates the expression. */
  not?: Maybe<ReserveFilter>;
};

/** Grouping methods for `Reserve` for usage during aggregation. */
export enum ReserveGroupBy {
  RateId = 'RATE_ID',
  MinPrice = 'MIN_PRICE',
  MaxPrice = 'MAX_PRICE',
  CreditQuality = 'CREDIT_QUALITY',
  Reserve = 'RESERVE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type ReserveHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  rateId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  reserve?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReserveHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  rateId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  reserve?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Reserve` aggregates. */
export type ReserveHavingInput = {
  AND?: Maybe<Array<ReserveHavingInput>>;
  OR?: Maybe<Array<ReserveHavingInput>>;
  sum?: Maybe<ReserveHavingSumInput>;
  distinctCount?: Maybe<ReserveHavingDistinctCountInput>;
  min?: Maybe<ReserveHavingMinInput>;
  max?: Maybe<ReserveHavingMaxInput>;
  average?: Maybe<ReserveHavingAverageInput>;
  stddevSample?: Maybe<ReserveHavingStddevSampleInput>;
  stddevPopulation?: Maybe<ReserveHavingStddevPopulationInput>;
  varianceSample?: Maybe<ReserveHavingVarianceSampleInput>;
  variancePopulation?: Maybe<ReserveHavingVariancePopulationInput>;
};

export type ReserveHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  rateId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  reserve?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReserveHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  rateId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  reserve?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReserveHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  rateId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  reserve?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReserveHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  rateId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  reserve?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReserveHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  rateId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  reserve?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReserveHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  rateId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  reserve?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReserveHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  rateId?: Maybe<HavingIntFilter>;
  minPrice?: Maybe<HavingIntFilter>;
  maxPrice?: Maybe<HavingIntFilter>;
  reserve?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Reserve` */
export type ReserveInput = {
  id?: Maybe<Scalars['Int']>;
  rateId?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  creditQuality?: Maybe<Scalars['String']>;
  reserve?: Maybe<Scalars['BigFloat']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rateToRateId?: Maybe<ReserveRateIdFkeyInput>;
};

export type ReserveMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  rateId?: Maybe<IntFilter>;
  minPrice?: Maybe<IntFilter>;
  maxPrice?: Maybe<IntFilter>;
  reserve?: Maybe<BigFloatFilter>;
};

export type ReserveMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of rateId across the matching connection */
  rateId?: Maybe<Scalars['Int']>;
  /** Maximum of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['Int']>;
  /** Maximum of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['Int']>;
  /** Maximum of reserve across the matching connection */
  reserve?: Maybe<Scalars['BigFloat']>;
};

export type ReserveMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  rateId?: Maybe<IntFilter>;
  minPrice?: Maybe<IntFilter>;
  maxPrice?: Maybe<IntFilter>;
  reserve?: Maybe<BigFloatFilter>;
};

export type ReserveMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of rateId across the matching connection */
  rateId?: Maybe<Scalars['Int']>;
  /** Minimum of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['Int']>;
  /** Minimum of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['Int']>;
  /** Minimum of reserve across the matching connection */
  reserve?: Maybe<Scalars['BigFloat']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ReserveNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `reserve` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ReserveNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `reserve` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ReserveOnReserveForReserveRateIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `rate` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `rate` being updated. */
  patch: RatePatch;
};

/** The fields on `reserve` to look up the row to update. */
export type ReserveOnReserveForReserveRateIdFkeyUsingReservePkeyUpdate = {
  /** An object where the defined keys will be set on the `reserve` being updated. */
  patch: UpdateReserveOnReserveForReserveRateIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Reserve`. Fields that are set will be updated. */
export type ReservePatch = {
  id?: Maybe<Scalars['Int']>;
  rateId?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  creditQuality?: Maybe<Scalars['String']>;
  reserve?: Maybe<Scalars['BigFloat']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rateToRateId?: Maybe<ReserveRateIdFkeyInput>;
};

/** Input for the nested mutation of `rate` in the `ReserveInput` mutation. */
export type ReserveRateIdFkeyInput = {
  /** The primary key(s) for `rate` for the far side of the relationship. */
  connectById?: Maybe<RateRatePkeyConnect>;
  /** The primary key(s) for `rate` for the far side of the relationship. */
  connectByNodeId?: Maybe<RateNodeIdConnect>;
  /** The primary key(s) for `rate` for the far side of the relationship. */
  deleteById?: Maybe<RateRatePkeyDelete>;
  /** The primary key(s) for `rate` for the far side of the relationship. */
  deleteByNodeId?: Maybe<RateNodeIdDelete>;
  /** The primary key(s) and patch data for `rate` for the far side of the relationship. */
  updateById?: Maybe<RateOnReserveForReserveRateIdFkeyUsingRatePkeyUpdate>;
  /** The primary key(s) and patch data for `rate` for the far side of the relationship. */
  updateByNodeId?: Maybe<ReserveOnReserveForReserveRateIdFkeyNodeIdUpdate>;
  /** A `RateInput` object that will be created and connected to this object. */
  create?: Maybe<ReserveRateIdFkeyRateCreateInput>;
};

/** Input for the nested mutation of `reserve` in the `RateInput` mutation. */
export type ReserveRateIdFkeyInverseInput = {
  /** Flag indicating whether all other `reserve` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `reserve` for the far side of the relationship. */
  connectById?: Maybe<Array<ReserveReservePkeyConnect>>;
  /** The primary key(s) for `reserve` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ReserveNodeIdConnect>>;
  /** The primary key(s) for `reserve` for the far side of the relationship. */
  deleteById?: Maybe<Array<ReserveReservePkeyDelete>>;
  /** The primary key(s) for `reserve` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ReserveNodeIdDelete>>;
  /** The primary key(s) and patch data for `reserve` for the far side of the relationship. */
  updateById?: Maybe<Array<ReserveOnReserveForReserveRateIdFkeyUsingReservePkeyUpdate>>;
  /** The primary key(s) and patch data for `reserve` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<RateOnReserveForReserveRateIdFkeyNodeIdUpdate>>;
  /** A `ReserveInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ReserveRateIdFkeyReserveCreateInput>>;
};

/** The `rate` to be created by this mutation. */
export type ReserveRateIdFkeyRateCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  minTerm?: Maybe<Scalars['Int']>;
  maxTerm?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['BigFloat']>;
  usedCarAdvanceRate?: Maybe<Scalars['Int']>;
  newCarAdvanceRate?: Maybe<Scalars['Int']>;
  addOn?: Maybe<Scalars['Int']>;
  lenderAdminFee?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<RateLenderCreditKeyIdFkeyInput>;
  reservesUsingId?: Maybe<ReserveRateIdFkeyInverseInput>;
};

/** The `reserve` to be created by this mutation. */
export type ReserveRateIdFkeyReserveCreateInput = {
  id?: Maybe<Scalars['Int']>;
  rateId?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  creditQuality?: Maybe<Scalars['String']>;
  reserve?: Maybe<Scalars['BigFloat']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rateToRateId?: Maybe<ReserveRateIdFkeyInput>;
};

/** The fields on `reserve` to look up the row to connect. */
export type ReserveReservePkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `reserve` to look up the row to delete. */
export type ReserveReservePkeyDelete = {
  id: Scalars['Int'];
};

export type ReserveStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  rateId?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  reserve?: Maybe<BigFloatFilter>;
};

export type ReserveStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of rateId across the matching connection */
  rateId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of reserve across the matching connection */
  reserve?: Maybe<Scalars['BigFloat']>;
};

export type ReserveStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  rateId?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  reserve?: Maybe<BigFloatFilter>;
};

export type ReserveStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of rateId across the matching connection */
  rateId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of reserve across the matching connection */
  reserve?: Maybe<Scalars['BigFloat']>;
};

export type ReserveSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  rateId?: Maybe<BigIntFilter>;
  minPrice?: Maybe<BigIntFilter>;
  maxPrice?: Maybe<BigIntFilter>;
  reserve?: Maybe<BigFloatFilter>;
};

export type ReserveSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of rateId across the matching connection */
  rateId: Scalars['BigInt'];
  /** Sum of minPrice across the matching connection */
  minPrice: Scalars['BigInt'];
  /** Sum of maxPrice across the matching connection */
  maxPrice: Scalars['BigInt'];
  /** Sum of reserve across the matching connection */
  reserve: Scalars['BigFloat'];
};

export type ReserveVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  rateId?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  reserve?: Maybe<BigFloatFilter>;
};

export type ReserveVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of rateId across the matching connection */
  rateId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Population variance of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Population variance of reserve across the matching connection */
  reserve?: Maybe<Scalars['BigFloat']>;
};

export type ReserveVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  rateId?: Maybe<BigFloatFilter>;
  minPrice?: Maybe<BigFloatFilter>;
  maxPrice?: Maybe<BigFloatFilter>;
  reserve?: Maybe<BigFloatFilter>;
};

export type ReserveVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of rateId across the matching connection */
  rateId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of minPrice across the matching connection */
  minPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of maxPrice across the matching connection */
  maxPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of reserve across the matching connection */
  reserve?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Reserve` values. */
export type ReservesConnection = {
  /** A list of `Reserve` objects. */
  nodes: Array<Reserve>;
  /** A list of edges which contains the `Reserve` and cursor to aid in pagination. */
  edges: Array<ReservesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reserve` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ReserveAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ReserveAggregates>>;
};


/** A connection to a list of `Reserve` values. */
export type ReservesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReserveGroupBy>;
  having?: Maybe<ReserveHavingInput>;
};

/** A `Reserve` edge in the connection. */
export type ReservesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Reserve` at the end of the edge. */
  node: Reserve;
};

/** Methods to use when ordering `Reserve`. */
export enum ReservesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  RateIdAsc = 'RATE_ID_ASC',
  RateIdDesc = 'RATE_ID_DESC',
  MinPriceAsc = 'MIN_PRICE_ASC',
  MinPriceDesc = 'MIN_PRICE_DESC',
  MaxPriceAsc = 'MAX_PRICE_ASC',
  MaxPriceDesc = 'MAX_PRICE_DESC',
  CreditQualityAsc = 'CREDIT_QUALITY_ASC',
  CreditQualityDesc = 'CREDIT_QUALITY_DESC',
  ReserveAsc = 'RESERVE_ASC',
  ReserveDesc = 'RESERVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type RetrieveIdVerificationResponse = {
  success?: Maybe<Scalars['Boolean']>;
  data?: Maybe<TransactionResponse>;
};

export type RetrieveIncomeVerificationResponse = {
  success?: Maybe<Scalars['Boolean']>;
  incomeVerifiedAt?: Maybe<Scalars['Date']>;
};

export type Review = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  companyId: Scalars['Int'];
  provider: ReviewProvider;
  authorName: Scalars['String'];
  authorPhotoUrl?: Maybe<Scalars['String']>;
  authorUrl: Scalars['String'];
  language: Scalars['String'];
  rating: Scalars['Int'];
  text: Scalars['String'];
  timestamp: Scalars['BigInt'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `Review`. */
  company?: Maybe<Company>;
};

export type ReviewAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ReviewSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ReviewDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ReviewMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ReviewMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ReviewAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ReviewStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ReviewStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ReviewVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ReviewVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Review` object types. */
export type ReviewAggregatesFilter = {
  /** A filter that must pass for the relevant `Review` object to be included within the aggregate. */
  filter?: Maybe<ReviewFilter>;
  /** Sum aggregate over matching `Review` objects. */
  sum?: Maybe<ReviewSumAggregateFilter>;
  /** Distinct count aggregate over matching `Review` objects. */
  distinctCount?: Maybe<ReviewDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Review` objects. */
  min?: Maybe<ReviewMinAggregateFilter>;
  /** Maximum aggregate over matching `Review` objects. */
  max?: Maybe<ReviewMaxAggregateFilter>;
  /** Mean average aggregate over matching `Review` objects. */
  average?: Maybe<ReviewAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Review` objects. */
  stddevSample?: Maybe<ReviewStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Review` objects. */
  stddevPopulation?: Maybe<ReviewStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Review` objects. */
  varianceSample?: Maybe<ReviewVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Review` objects. */
  variancePopulation?: Maybe<ReviewVariancePopulationAggregateFilter>;
};

export type ReviewAverageAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  rating?: Maybe<BigFloatFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of rating across the matching connection */
  rating?: Maybe<Scalars['BigFloat']>;
  /** Mean average of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Review` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ReviewCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: Maybe<ReviewProvider>;
  /** Checks for equality with the object’s `authorName` field. */
  authorName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `authorPhotoUrl` field. */
  authorPhotoUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `authorUrl` field. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `language` field. */
  language?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rating` field. */
  rating?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `text` field. */
  text?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type ReviewConfigForLeadResponse = {
  companyName: Scalars['String'];
  reviewConfig: CompanyReviewConfig;
};

export type ReviewDistinctCountAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  provider?: Maybe<BigIntFilter>;
  authorName?: Maybe<BigIntFilter>;
  authorPhotoUrl?: Maybe<BigIntFilter>;
  authorUrl?: Maybe<BigIntFilter>;
  language?: Maybe<BigIntFilter>;
  rating?: Maybe<BigIntFilter>;
  text?: Maybe<BigIntFilter>;
  timestamp?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type ReviewDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
  /** Distinct count of authorName across the matching connection */
  authorName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of authorPhotoUrl across the matching connection */
  authorPhotoUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of authorUrl across the matching connection */
  authorUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of language across the matching connection */
  language?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rating across the matching connection */
  rating?: Maybe<Scalars['BigInt']>;
  /** Distinct count of text across the matching connection */
  text?: Maybe<Scalars['BigInt']>;
  /** Distinct count of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Review` object types. All fields are combined with a logical ‘and.’ */
export type ReviewFilter = {
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: Maybe<ReviewProviderFilter>;
  /** Filter by the object’s `authorName` field. */
  authorName?: Maybe<StringFilter>;
  /** Filter by the object’s `authorPhotoUrl` field. */
  authorPhotoUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `authorUrl` field. */
  authorUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `language` field. */
  language?: Maybe<StringFilter>;
  /** Filter by the object’s `rating` field. */
  rating?: Maybe<IntFilter>;
  /** Filter by the object’s `text` field. */
  text?: Maybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: Maybe<BigIntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ReviewFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ReviewFilter>>;
  /** Negates the expression. */
  not?: Maybe<ReviewFilter>;
};

/** An input for mutations affecting `Review` */
export type ReviewInput = {
  companyId?: Maybe<Scalars['Int']>;
  provider?: Maybe<ReviewProvider>;
  authorName: Scalars['String'];
  authorPhotoUrl?: Maybe<Scalars['String']>;
  authorUrl: Scalars['String'];
  language: Scalars['String'];
  rating: Scalars['Int'];
  text: Scalars['String'];
  timestamp: Scalars['BigInt'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<ReviewsCompanyIdFkeyInput>;
};

export type ReviewMaxAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  rating?: Maybe<IntFilter>;
  timestamp?: Maybe<BigIntFilter>;
};

export type ReviewMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of rating across the matching connection */
  rating?: Maybe<Scalars['Int']>;
  /** Maximum of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigInt']>;
};

export type ReviewMinAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  rating?: Maybe<IntFilter>;
  timestamp?: Maybe<BigIntFilter>;
};

export type ReviewMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of rating across the matching connection */
  rating?: Maybe<Scalars['Int']>;
  /** Minimum of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigInt']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ReviewNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `review` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ReviewNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `review` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ReviewOnReviewForReviewsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `review` to look up the row to update. */
export type ReviewOnReviewForReviewsCompanyIdFkeyUsingReviewsPkeyUpdate = {
  /** An object where the defined keys will be set on the `review` being updated. */
  patch: UpdateReviewOnReviewForReviewsCompanyIdFkeyPatch;
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};

/** Represents an update to a `Review`. Fields that are set will be updated. */
export type ReviewPatch = {
  companyId?: Maybe<Scalars['Int']>;
  provider?: Maybe<ReviewProvider>;
  authorName?: Maybe<Scalars['String']>;
  authorPhotoUrl?: Maybe<Scalars['String']>;
  authorUrl?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<ReviewsCompanyIdFkeyInput>;
};

export enum ReviewProvider {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK'
}

/** A filter to be used against ReviewProvider fields. All fields are combined with a logical ‘and.’ */
export type ReviewProviderFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ReviewProvider>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ReviewProvider>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ReviewProvider>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ReviewProvider>;
  /** Included in the specified list. */
  in?: Maybe<Array<ReviewProvider>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ReviewProvider>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ReviewProvider>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ReviewProvider>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ReviewProvider>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ReviewProvider>;
};

/** The fields on `review` to look up the row to connect. */
export type ReviewReviewsPkeyConnect = {
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};

/** The fields on `review` to look up the row to delete. */
export type ReviewReviewsPkeyDelete = {
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};

export type ReviewStddevPopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  rating?: Maybe<BigFloatFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of rating across the matching connection */
  rating?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigFloat']>;
};

export type ReviewStddevSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  rating?: Maybe<BigFloatFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of rating across the matching connection */
  rating?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigFloat']>;
};

export type ReviewSumAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  rating?: Maybe<BigIntFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of rating across the matching connection */
  rating: Scalars['BigInt'];
  /** Sum of timestamp across the matching connection */
  timestamp: Scalars['BigFloat'];
};

export type ReviewVariancePopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  rating?: Maybe<BigFloatFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of rating across the matching connection */
  rating?: Maybe<Scalars['BigFloat']>;
  /** Population variance of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigFloat']>;
};

export type ReviewVarianceSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  rating?: Maybe<BigFloatFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of rating across the matching connection */
  rating?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigFloat']>;
};

/** The `company` to be created by this mutation. */
export type ReviewsCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `ReviewInput` mutation. */
export type ReviewsCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<ReviewOnReviewForReviewsCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<ReviewsCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `review` in the `CompanyInput` mutation. */
export type ReviewsCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `review` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `review` for the far side of the relationship. */
  connectByCompanyIdAndAuthorUrl?: Maybe<Array<ReviewReviewsPkeyConnect>>;
  /** The primary key(s) for `review` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ReviewNodeIdConnect>>;
  /** The primary key(s) for `review` for the far side of the relationship. */
  deleteByCompanyIdAndAuthorUrl?: Maybe<Array<ReviewReviewsPkeyDelete>>;
  /** The primary key(s) for `review` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ReviewNodeIdDelete>>;
  /** The primary key(s) and patch data for `review` for the far side of the relationship. */
  updateByCompanyIdAndAuthorUrl?: Maybe<Array<ReviewOnReviewForReviewsCompanyIdFkeyUsingReviewsPkeyUpdate>>;
  /** The primary key(s) and patch data for `review` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnReviewForReviewsCompanyIdFkeyNodeIdUpdate>>;
  /** A `ReviewInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ReviewsCompanyIdFkeyReviewsCreateInput>>;
};

/** The `review` to be created by this mutation. */
export type ReviewsCompanyIdFkeyReviewsCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  provider?: Maybe<ReviewProvider>;
  authorName: Scalars['String'];
  authorPhotoUrl?: Maybe<Scalars['String']>;
  authorUrl: Scalars['String'];
  language: Scalars['String'];
  rating: Scalars['Int'];
  text: Scalars['String'];
  timestamp: Scalars['BigInt'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<ReviewsCompanyIdFkeyInput>;
};

/** A connection to a list of `Review` values. */
export type ReviewsConnection = {
  /** A list of `Review` objects. */
  nodes: Array<Review>;
  /** A list of edges which contains the `Review` and cursor to aid in pagination. */
  edges: Array<ReviewsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Review` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ReviewAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ReviewAggregates>>;
};


/** A connection to a list of `Review` values. */
export type ReviewsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReviewsGroupBy>;
  having?: Maybe<ReviewsHavingInput>;
};

/** A `Review` edge in the connection. */
export type ReviewsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Review` at the end of the edge. */
  node: Review;
};

/** Grouping methods for `Review` for usage during aggregation. */
export enum ReviewsGroupBy {
  CompanyId = 'COMPANY_ID',
  Provider = 'PROVIDER',
  AuthorName = 'AUTHOR_NAME',
  AuthorPhotoUrl = 'AUTHOR_PHOTO_URL',
  AuthorUrl = 'AUTHOR_URL',
  Language = 'LANGUAGE',
  Rating = 'RATING',
  Text = 'TEXT',
  Timestamp = 'TIMESTAMP',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type ReviewsHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Review` aggregates. */
export type ReviewsHavingInput = {
  AND?: Maybe<Array<ReviewsHavingInput>>;
  OR?: Maybe<Array<ReviewsHavingInput>>;
  sum?: Maybe<ReviewsHavingSumInput>;
  distinctCount?: Maybe<ReviewsHavingDistinctCountInput>;
  min?: Maybe<ReviewsHavingMinInput>;
  max?: Maybe<ReviewsHavingMaxInput>;
  average?: Maybe<ReviewsHavingAverageInput>;
  stddevSample?: Maybe<ReviewsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<ReviewsHavingStddevPopulationInput>;
  varianceSample?: Maybe<ReviewsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<ReviewsHavingVariancePopulationInput>;
};

export type ReviewsHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Review`. */
export enum ReviewsOrderBy {
  Natural = 'NATURAL',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderDesc = 'PROVIDER_DESC',
  AuthorNameAsc = 'AUTHOR_NAME_ASC',
  AuthorNameDesc = 'AUTHOR_NAME_DESC',
  AuthorPhotoUrlAsc = 'AUTHOR_PHOTO_URL_ASC',
  AuthorPhotoUrlDesc = 'AUTHOR_PHOTO_URL_DESC',
  AuthorUrlAsc = 'AUTHOR_URL_ASC',
  AuthorUrlDesc = 'AUTHOR_URL_DESC',
  LanguageAsc = 'LANGUAGE_ASC',
  LanguageDesc = 'LANGUAGE_DESC',
  RatingAsc = 'RATING_ASC',
  RatingDesc = 'RATING_DESC',
  TextAsc = 'TEXT_ASC',
  TextDesc = 'TEXT_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type RevokeUserAccessInput = {
  username: Scalars['String'];
  groupId: Scalars['Int'];
  retainNonAdminAccess?: Maybe<Scalars['Boolean']>;
};

export type RiskElements = {
  balanceMin?: Maybe<Scalars['Float']>;
  balanceMax?: Maybe<Scalars['Float']>;
  daysWithNegativeBalance90Days?: Maybe<Scalars['Int']>;
  balanceTrend?: Maybe<Scalars['String']>;
};

export type SalesOrderOwner = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type Score = {
  value?: Maybe<Scalars['Int']>;
  reasons: Array<Scalars['String']>;
};

export type SecuredLoan = {
  dateFiled?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  assignedDate?: Maybe<Scalars['String']>;
  court?: Maybe<IdName>;
  SecuredLoanCreditorId?: Maybe<Scalars['String']>;
  narratives?: Maybe<Array<CodeDescription>>;
};

export type SecurityCheck = {
  enabled: Scalars['Boolean'];
  ipWhitelist?: Maybe<Array<Scalars['String']>>;
};

export type SendFeedbackResponse = {
  success: Scalars['Boolean'];
  type: FeedbackType;
  leadId: Scalars['UUID'];
};

export type SendTextMessageInput = {
  leadId: Scalars['UUID'];
  message: Scalars['String'];
};

export type SendTextMessageResponse = {
  success: Scalars['Boolean'];
  message: Scalars['String'];
  messageId: Scalars['String'];
  status: Scalars['String'];
  error?: Maybe<Scalars['String']>;
};

export type SetCreditAppRetailerIdResponse = {
  retailerId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetDirectDepositAccountResponse = {
  success?: Maybe<Scalars['Boolean']>;
  directDepositAccountId?: Maybe<Scalars['String']>;
};

export type SetIncomeLoginResponse = {
  success?: Maybe<Scalars['Boolean']>;
  loginId?: Maybe<Scalars['String']>;
};

export type SetPreferredLenderOfferInput = {
  offerId: Scalars['Int'];
  lenderAppId: Scalars['Int'];
  leadId: Scalars['UUID'];
};

export type SignupInput = {
  company: CompanyInput;
  user: CreateUserInput;
};

export type SmsUserNotificationInput = {
  leadId: Scalars['UUID'];
  tradeAppId: Scalars['Int'];
  phoneNumber: Scalars['String'];
};

export type SmsUserNotificationResponse = {
  success: Scalars['Boolean'];
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  smsBody?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type SourceUrlLeadCount = {
  sourceUrl: Scalars['String'];
  count: Scalars['Int'];
};

export enum Status {
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
  Pending = 'PENDING',
  NotRun = 'NOT_RUN'
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contains the specified list of values. */
  contains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contained by the specified list of values. */
  containedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any array item is equal to the specified value. */
  anyEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: Maybe<Scalars['String']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: Maybe<Scalars['String']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: Maybe<Scalars['String']>;
};

export type StripeIdentityDocumentOptions = {
  allowed_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  require_live_capture?: Maybe<Scalars['Boolean']>;
  require_matching_selfie?: Maybe<Scalars['Boolean']>;
};

export type SubmitCreditAppApplicationResponse = {
  success: Scalars['Boolean'];
};

export type SubmitLeadResponse = {
  success: Scalars['Boolean'];
  deduplicated: Scalars['Boolean'];
  leadId: Scalars['ID'];
};

export type SubscriptionUsage = {
  amount?: Maybe<Scalars['Float']>;
  chargesUsage: Array<ChargeUsage>;
  currency?: Maybe<Scalars['String']>;
  fromDatetime?: Maybe<Scalars['Datetime']>;
  toDatetime?: Maybe<Scalars['Datetime']>;
  issuingDate?: Maybe<Scalars['Datetime']>;
  providerInvoiceId?: Maybe<Scalars['String']>;
  taxesAmount?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type Trade = {
  date?: Maybe<Scalars['String']>;
  creditor?: Maybe<EquifaxEntity>;
  accountNumber?: Maybe<Scalars['String']>;
  association?: Maybe<Scalars['String']>;
  updateSource?: Maybe<Scalars['String']>;
  monthsReviewed?: Maybe<Scalars['String']>;
  dateOpened?: Maybe<Scalars['String']>;
  dateLastActivityOrPayment?: Maybe<Scalars['String']>;
  portfolioType?: Maybe<LoanStatusComponent>;
  paymentRate?: Maybe<LoanStatusComponent>;
  narratives: Array<Scalars['String']>;
  fields: Array<Scalars['String']>;
  highCreditAmount?: Maybe<Scalars['Float']>;
  paymentTerm?: Maybe<Scalars['String']>;
  paymentTermAmount?: Maybe<Scalars['Float']>;
  balanceAmount?: Maybe<Scalars['Float']>;
  pastDueAmount?: Maybe<Scalars['Float']>;
  derogatoryCounters?: Maybe<DerogatoryCounters>;
  lastSlowPayment?: Maybe<Scalars['String']>;
};

export type TradeApp = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  condition?: Maybe<VehicleCondition>;
  tradeInValue?: Maybe<Scalars['Float']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `TradeApp`. */
  lead?: Maybe<Lead>;
};

export type TradeAppAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<TradeAppSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TradeAppDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<TradeAppMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<TradeAppMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<TradeAppAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<TradeAppStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<TradeAppStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<TradeAppVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<TradeAppVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `TradeApp` object types. */
export type TradeAppAggregatesFilter = {
  /** A filter that must pass for the relevant `TradeApp` object to be included within the aggregate. */
  filter?: Maybe<TradeAppFilter>;
  /** Sum aggregate over matching `TradeApp` objects. */
  sum?: Maybe<TradeAppSumAggregateFilter>;
  /** Distinct count aggregate over matching `TradeApp` objects. */
  distinctCount?: Maybe<TradeAppDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `TradeApp` objects. */
  min?: Maybe<TradeAppMinAggregateFilter>;
  /** Maximum aggregate over matching `TradeApp` objects. */
  max?: Maybe<TradeAppMaxAggregateFilter>;
  /** Mean average aggregate over matching `TradeApp` objects. */
  average?: Maybe<TradeAppAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `TradeApp` objects. */
  stddevSample?: Maybe<TradeAppStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `TradeApp` objects. */
  stddevPopulation?: Maybe<TradeAppStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `TradeApp` objects. */
  varianceSample?: Maybe<TradeAppVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `TradeApp` objects. */
  variancePopulation?: Maybe<TradeAppVariancePopulationAggregateFilter>;
};

export type TradeAppAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Mean average of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Mean average of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Mean average of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Mean average of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `TradeApp` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TradeAppCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `make` field. */
  make?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `model` field. */
  model?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `trim` field. */
  trim?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `mileage` field. */
  mileage?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `condition` field. */
  condition?: Maybe<VehicleCondition>;
  /** Checks for equality with the object’s `tradeInValue` field. */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `maxTradeInValue` field. */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `minTradeInValue` field. */
  minTradeInValue?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type TradeAppCustomInput = {
  lead: LeadInput;
  tradeApp: TradeAppInput;
};

export type TradeAppCustomType = {
  leadId?: Maybe<Scalars['UUID']>;
  tradeApp?: Maybe<PartialTradeApp>;
  error?: Maybe<TradeAppError>;
};

export type TradeAppDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  year?: Maybe<BigIntFilter>;
  make?: Maybe<BigIntFilter>;
  model?: Maybe<BigIntFilter>;
  trim?: Maybe<BigIntFilter>;
  mileage?: Maybe<BigIntFilter>;
  condition?: Maybe<BigIntFilter>;
  tradeInValue?: Maybe<BigIntFilter>;
  maxTradeInValue?: Maybe<BigIntFilter>;
  minTradeInValue?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type TradeAppDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of year across the matching connection */
  year?: Maybe<Scalars['BigInt']>;
  /** Distinct count of make across the matching connection */
  make?: Maybe<Scalars['BigInt']>;
  /** Distinct count of model across the matching connection */
  model?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trim across the matching connection */
  trim?: Maybe<Scalars['BigInt']>;
  /** Distinct count of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of condition across the matching connection */
  condition?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

export type TradeAppError = {
  code?: Maybe<TradeAppErrorCode>;
  message: Scalars['String'];
};

export enum TradeAppErrorCode {
  KbbServerErrorException = 'KbbServerErrorException',
  KbbOutOfRangeException = 'KbbOutOfRangeException',
  KbbDisabledByDealer = 'KbbDisabledByDealer',
  ParameterException = 'ParameterException',
  InternalErrorException = 'InternalErrorException'
}

/** A filter to be used against `TradeApp` object types. All fields are combined with a logical ‘and.’ */
export type TradeAppFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `year` field. */
  year?: Maybe<IntFilter>;
  /** Filter by the object’s `make` field. */
  make?: Maybe<StringFilter>;
  /** Filter by the object’s `model` field. */
  model?: Maybe<StringFilter>;
  /** Filter by the object’s `trim` field. */
  trim?: Maybe<StringFilter>;
  /** Filter by the object’s `mileage` field. */
  mileage?: Maybe<IntFilter>;
  /** Filter by the object’s `condition` field. */
  condition?: Maybe<VehicleConditionFilter>;
  /** Filter by the object’s `tradeInValue` field. */
  tradeInValue?: Maybe<FloatFilter>;
  /** Filter by the object’s `maxTradeInValue` field. */
  maxTradeInValue?: Maybe<FloatFilter>;
  /** Filter by the object’s `minTradeInValue` field. */
  minTradeInValue?: Maybe<FloatFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TradeAppFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TradeAppFilter>>;
  /** Negates the expression. */
  not?: Maybe<TradeAppFilter>;
};

/** Grouping methods for `TradeApp` for usage during aggregation. */
export enum TradeAppGroupBy {
  LeadId = 'LEAD_ID',
  Year = 'YEAR',
  Make = 'MAKE',
  Model = 'MODEL',
  Trim = 'TRIM',
  Mileage = 'MILEAGE',
  Condition = 'CONDITION',
  TradeInValue = 'TRADE_IN_VALUE',
  MaxTradeInValue = 'MAX_TRADE_IN_VALUE',
  MinTradeInValue = 'MIN_TRADE_IN_VALUE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type TradeAppHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `TradeApp` aggregates. */
export type TradeAppHavingInput = {
  AND?: Maybe<Array<TradeAppHavingInput>>;
  OR?: Maybe<Array<TradeAppHavingInput>>;
  sum?: Maybe<TradeAppHavingSumInput>;
  distinctCount?: Maybe<TradeAppHavingDistinctCountInput>;
  min?: Maybe<TradeAppHavingMinInput>;
  max?: Maybe<TradeAppHavingMaxInput>;
  average?: Maybe<TradeAppHavingAverageInput>;
  stddevSample?: Maybe<TradeAppHavingStddevSampleInput>;
  stddevPopulation?: Maybe<TradeAppHavingStddevPopulationInput>;
  varianceSample?: Maybe<TradeAppHavingVarianceSampleInput>;
  variancePopulation?: Maybe<TradeAppHavingVariancePopulationInput>;
};

export type TradeAppHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `TradeApp` */
export type TradeAppInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  condition?: Maybe<VehicleCondition>;
  tradeInValue?: Maybe<Scalars['Float']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<TradeAppLeadIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `TradeAppInput` mutation. */
export type TradeAppLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnTradeAppForTradeAppLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<TradeAppOnTradeAppForTradeAppLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<TradeAppLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `tradeApp` in the `LeadInput` mutation. */
export type TradeAppLeadIdFkeyInverseInput = {
  /** The primary key(s) for `tradeApp` for the far side of the relationship. */
  connectById?: Maybe<Array<TradeAppTradeAppPkeyConnect>>;
  /** The primary key(s) for `tradeApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<TradeAppNodeIdConnect>>;
  /** The primary key(s) and patch data for `tradeApp` for the far side of the relationship. */
  updateById?: Maybe<Array<TradeAppOnTradeAppForTradeAppLeadIdFkeyUsingTradeAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `tradeApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnTradeAppForTradeAppLeadIdFkeyNodeIdUpdate>>;
  /** A `TradeAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<TradeAppLeadIdFkeyTradeAppCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type TradeAppLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** The `tradeApp` to be created by this mutation. */
export type TradeAppLeadIdFkeyTradeAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  condition?: Maybe<VehicleCondition>;
  tradeInValue?: Maybe<Scalars['Float']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<TradeAppLeadIdFkeyInput>;
};

/** Location options (province, territories) */
export enum TradeAppLocation {
  /** Newfoundland and Labrador */
  Nl = 'NL',
  /** Nova Scotia */
  Ns = 'NS',
  /** Prince Edward Island */
  Pe = 'PE',
  /** New Brunswick */
  Nb = 'NB',
  /** Quebec */
  Qc = 'QC',
  /** Ontario */
  On = 'ON',
  /** Manitoba */
  Mb = 'MB',
  /** Saskatchewan */
  Sk = 'SK',
  /** Alberta */
  Ab = 'AB',
  /** British Columbia */
  Bc = 'BC',
  /** Northwest Territories */
  Nt = 'NT',
  /** Nunavut */
  Nu = 'NU',
  /** Yukon Territory */
  Yt = 'YT'
}

/** Vehicle make. */
export type TradeAppMake = {
  /** Vehicle make identification. */
  makeId?: Maybe<Scalars['Int']>;
  /** Vehicle make name. */
  makeName?: Maybe<Scalars['String']>;
};

export type TradeAppMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  year?: Maybe<IntFilter>;
  mileage?: Maybe<IntFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of year across the matching connection */
  year?: Maybe<Scalars['Int']>;
  /** Maximum of mileage across the matching connection */
  mileage?: Maybe<Scalars['Int']>;
  /** Maximum of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Maximum of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Maximum of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

export type TradeAppMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  year?: Maybe<IntFilter>;
  mileage?: Maybe<IntFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of year across the matching connection */
  year?: Maybe<Scalars['Int']>;
  /** Minimum of mileage across the matching connection */
  mileage?: Maybe<Scalars['Int']>;
  /** Minimum of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Minimum of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Minimum of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

/** Vehicle model. */
export type TradeAppModel = {
  /** Vehicle model identification. */
  modelId?: Maybe<Scalars['Int']>;
  /** Vehicle model name. */
  modelName?: Maybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type TradeAppNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `tradeApp` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type TradeAppOnTradeAppForTradeAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `tradeApp` to look up the row to update. */
export type TradeAppOnTradeAppForTradeAppLeadIdFkeyUsingTradeAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `tradeApp` being updated. */
  patch: UpdateTradeAppOnTradeAppForTradeAppLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `TradeApp`. Fields that are set will be updated. */
export type TradeAppPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  condition?: Maybe<VehicleCondition>;
  tradeInValue?: Maybe<Scalars['Float']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<TradeAppLeadIdFkeyInput>;
};

export type TradeAppPayload = TradeAppError | TradeAppTradeValue;

/** Search result item. */
export type TradeAppSearchResultItem = {
  /** Vehicle model year. */
  year?: Maybe<Scalars['String']>;
  /** Vehicle make name. */
  make?: Maybe<Scalars['String']>;
  /** Vehicle make identification. */
  makeId?: Maybe<Scalars['Int']>;
  /** Vehicle model name. */
  model?: Maybe<Scalars['String']>;
  /** Vehicle model identification. */
  modelId?: Maybe<Scalars['Int']>;
  /** Vehicle trim or variant name. */
  trim?: Maybe<Scalars['String']>;
  /** Vehicle market class. */
  class?: Maybe<Scalars['String']>;
  /** Vehicle identification. */
  vehicleId?: Maybe<Scalars['String']>;
  /** Vehicle msrp. */
  msrp?: Maybe<Scalars['Int']>;
  /** Vehicle chrome style Id. */
  styleId?: Maybe<Scalars['String']>;
};

/** Search results list. */
export type TradeAppSearchResults = {
  /** Total number of search result of the search query. */
  total?: Maybe<Scalars['Int']>;
  /** List of results. */
  results?: Maybe<Array<Maybe<TradeAppSearchResultItem>>>;
};

export type TradeAppStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Population standard deviation of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Population standard deviation of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

export type TradeAppStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

export type TradeAppSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  year?: Maybe<BigIntFilter>;
  mileage?: Maybe<BigIntFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of year across the matching connection */
  year: Scalars['BigInt'];
  /** Sum of mileage across the matching connection */
  mileage: Scalars['BigInt'];
  /** Sum of tradeInValue across the matching connection */
  tradeInValue: Scalars['Float'];
  /** Sum of maxTradeInValue across the matching connection */
  maxTradeInValue: Scalars['Float'];
  /** Sum of minTradeInValue across the matching connection */
  minTradeInValue: Scalars['Float'];
};

/** The fields on `tradeApp` to look up the row to connect. */
export type TradeAppTradeAppPkeyConnect = {
  id: Scalars['Int'];
};

/** Vehicle trade-in value according to vehicle condition. */
export type TradeAppTradeValue = {
  /** Vehicle fair purchase price. */
  fairPurchasePrice?: Maybe<Scalars['Float']>;
  /** Vehicle lowest trade-in price within the price range. */
  priceLow?: Maybe<Scalars['Float']>;
  /** Vehicle highest trade-in price within the price range. */
  priceHigh?: Maybe<Scalars['Float']>;
  /** Vehicle bse discounted price. */
  baseDiscountedPrice?: Maybe<Scalars['Float']>;
  /** HTML for rendering price advisor. */
  priceAdvisor?: Maybe<Scalars['String']>;
};

/** Vehicle trim or variant. */
export type TradeAppTrim = {
  /** Vehicle identification. */
  vehicleId?: Maybe<Scalars['String']>;
  /** Vehicle model year. */
  year?: Maybe<Scalars['Int']>;
  /** Vehicle model identification. */
  modelId?: Maybe<Scalars['Int']>;
  /** Vehicle trim name. */
  trimName?: Maybe<Scalars['String']>;
};

export type TradeAppVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Population variance of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Population variance of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Population variance of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Population variance of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

export type TradeAppVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Sample variance of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Sample variance of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

/** Condition options (defined by KBB as Vehicle Grade) */
export enum TradeAppVehicleCondition {
  Excellent = 'EXCELLENT',
  VeryGood = 'VERY_GOOD',
  Good = 'GOOD',
  Fair = 'FAIR'
}

/** Vehicle information. */
export type TradeAppVehicleInfo = {
  /** Vehicle manufacturer. */
  makeName?: Maybe<Scalars['String']>;
  /** Vehicle model name. */
  modelName?: Maybe<Scalars['String']>;
  /** Vehicle variant name. */
  variantName?: Maybe<Scalars['String']>;
  /** Vehicle model year. */
  year?: Maybe<Scalars['Int']>;
  /** Vehicle body type. */
  bodyTypeName?: Maybe<Scalars['String']>;
  /** Number of doors. */
  nrDoors?: Maybe<Scalars['Int']>;
  /** Number of seats. */
  nrSeats?: Maybe<Scalars['Int']>;
  /** Transmission type. */
  transmissionTypeName?: Maybe<Scalars['String']>;
  /** Fuel type. */
  fuelTypeName?: Maybe<Scalars['String']>;
  /** Engine type. */
  engineTypeName?: Maybe<Scalars['String']>;
  /** Drive train type. */
  driveTrainTypeName?: Maybe<Scalars['String']>;
  /** Large image url. */
  mediaUrlLarge?: Maybe<Scalars['String']>;
  /** Medium image url. */
  mediaUrlMedium?: Maybe<Scalars['String']>;
  /** Small image url. */
  mediaUrlSmall?: Maybe<Scalars['String']>;
};

/** Vehicle year makes. */
export type TradeAppYearMakes = {
  /** Vehicle model year. */
  modelYear: Scalars['Int'];
  /** Vehicle make Ids. */
  makeIds: Array<Scalars['Int']>;
};

/** A connection to a list of `TradeApp` values. */
export type TradeAppsConnection = {
  /** A list of `TradeApp` objects. */
  nodes: Array<TradeApp>;
  /** A list of edges which contains the `TradeApp` and cursor to aid in pagination. */
  edges: Array<TradeAppsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TradeApp` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TradeAppAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TradeAppAggregates>>;
};


/** A connection to a list of `TradeApp` values. */
export type TradeAppsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TradeAppGroupBy>;
  having?: Maybe<TradeAppHavingInput>;
};

/** A `TradeApp` edge in the connection. */
export type TradeAppsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TradeApp` at the end of the edge. */
  node: TradeApp;
};

/** Methods to use when ordering `TradeApp`. */
export enum TradeAppsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MakeAsc = 'MAKE_ASC',
  MakeDesc = 'MAKE_DESC',
  ModelAsc = 'MODEL_ASC',
  ModelDesc = 'MODEL_DESC',
  TrimAsc = 'TRIM_ASC',
  TrimDesc = 'TRIM_DESC',
  MileageAsc = 'MILEAGE_ASC',
  MileageDesc = 'MILEAGE_DESC',
  ConditionAsc = 'CONDITION_ASC',
  ConditionDesc = 'CONDITION_DESC',
  TradeInValueAsc = 'TRADE_IN_VALUE_ASC',
  TradeInValueDesc = 'TRADE_IN_VALUE_DESC',
  MaxTradeInValueAsc = 'MAX_TRADE_IN_VALUE_ASC',
  MaxTradeInValueDesc = 'MAX_TRADE_IN_VALUE_DESC',
  MinTradeInValueAsc = 'MIN_TRADE_IN_VALUE_ASC',
  MinTradeInValueDesc = 'MIN_TRADE_IN_VALUE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type TradeLine = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  bureauId: Scalars['Int'];
  equifaxLenderId: Scalars['Int'];
  tradeDate?: Maybe<Scalars['String']>;
  creditorId?: Maybe<Scalars['String']>;
  creditorName?: Maybe<Scalars['String']>;
  creditorPhone?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  association?: Maybe<Scalars['String']>;
  updateSource?: Maybe<Scalars['String']>;
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  paymentTerm?: Maybe<Scalars['String']>;
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  dateOpened?: Maybe<Scalars['String']>;
  dateLastActivityOrPayment?: Maybe<Scalars['String']>;
  portfolioTypeDescription?: Maybe<Scalars['String']>;
  portfolioTypeCode?: Maybe<Scalars['String']>;
  paymentRateDescription?: Maybe<Scalars['String']>;
  paymentRateCode?: Maybe<Scalars['String']>;
  past30?: Maybe<Scalars['BigFloat']>;
  past60?: Maybe<Scalars['BigFloat']>;
  past90?: Maybe<Scalars['BigFloat']>;
  lastSlowPayment?: Maybe<Scalars['String']>;
  narratives?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `CreditBureau` that is related to this `TradeLine`. */
  bureau?: Maybe<CreditBureau>;
  /** Reads a single `EquifaxLender` that is related to this `TradeLine`. */
  equifaxLender?: Maybe<EquifaxLender>;
};

export type TradeLineAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<TradeLineSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TradeLineDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<TradeLineMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<TradeLineMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<TradeLineAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<TradeLineStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<TradeLineStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<TradeLineVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<TradeLineVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `TradeLine` object types. */
export type TradeLineAggregatesFilter = {
  /** A filter that must pass for the relevant `TradeLine` object to be included within the aggregate. */
  filter?: Maybe<TradeLineFilter>;
  /** Sum aggregate over matching `TradeLine` objects. */
  sum?: Maybe<TradeLineSumAggregateFilter>;
  /** Distinct count aggregate over matching `TradeLine` objects. */
  distinctCount?: Maybe<TradeLineDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `TradeLine` objects. */
  min?: Maybe<TradeLineMinAggregateFilter>;
  /** Maximum aggregate over matching `TradeLine` objects. */
  max?: Maybe<TradeLineMaxAggregateFilter>;
  /** Mean average aggregate over matching `TradeLine` objects. */
  average?: Maybe<TradeLineAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `TradeLine` objects. */
  stddevSample?: Maybe<TradeLineStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `TradeLine` objects. */
  stddevPopulation?: Maybe<TradeLineStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `TradeLine` objects. */
  varianceSample?: Maybe<TradeLineVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `TradeLine` objects. */
  variancePopulation?: Maybe<TradeLineVariancePopulationAggregateFilter>;
};

export type TradeLineAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  bureauId?: Maybe<BigFloatFilter>;
  equifaxLenderId?: Maybe<BigFloatFilter>;
  highCreditAmount?: Maybe<BigFloatFilter>;
  balanceAmount?: Maybe<BigFloatFilter>;
  paymentTermAmount?: Maybe<BigFloatFilter>;
  pastDueAmount?: Maybe<BigFloatFilter>;
  monthsReviewed?: Maybe<BigFloatFilter>;
  past30?: Maybe<BigFloatFilter>;
  past60?: Maybe<BigFloatFilter>;
  past90?: Maybe<BigFloatFilter>;
};

export type TradeLineAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of bureauId across the matching connection */
  bureauId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of equifaxLenderId across the matching connection */
  equifaxLenderId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of highCreditAmount across the matching connection */
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of balanceAmount across the matching connection */
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of paymentTermAmount across the matching connection */
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of pastDueAmount across the matching connection */
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of monthsReviewed across the matching connection */
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  /** Mean average of past30 across the matching connection */
  past30?: Maybe<Scalars['BigFloat']>;
  /** Mean average of past60 across the matching connection */
  past60?: Maybe<Scalars['BigFloat']>;
  /** Mean average of past90 across the matching connection */
  past90?: Maybe<Scalars['BigFloat']>;
};

/** The `creditBureau` to be created by this mutation. */
export type TradeLineBureauIdFkeyCreditBureauCreateInput = {
  id?: Maybe<Scalars['Int']>;
  avaAppId?: Maybe<Scalars['Int']>;
  /** company | lead */
  type: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  avaAppToAvaAppId?: Maybe<CreditBureauAvaAppIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineBureauIdFkeyInverseInput>;
};

/** Input for the nested mutation of `creditBureau` in the `TradeLineInput` mutation. */
export type TradeLineBureauIdFkeyInput = {
  /** The primary key(s) for `creditBureau` for the far side of the relationship. */
  connectById?: Maybe<CreditBureauCreditBureauPkeyConnect>;
  /** The primary key(s) for `creditBureau` for the far side of the relationship. */
  connectByNodeId?: Maybe<CreditBureauNodeIdConnect>;
  /** The primary key(s) and patch data for `creditBureau` for the far side of the relationship. */
  updateById?: Maybe<CreditBureauOnTradeLineForTradeLineBureauIdFkeyUsingCreditBureauPkeyUpdate>;
  /** The primary key(s) and patch data for `creditBureau` for the far side of the relationship. */
  updateByNodeId?: Maybe<TradeLineOnTradeLineForTradeLineBureauIdFkeyNodeIdUpdate>;
  /** A `CreditBureauInput` object that will be created and connected to this object. */
  create?: Maybe<TradeLineBureauIdFkeyCreditBureauCreateInput>;
};

/** Input for the nested mutation of `tradeLine` in the `CreditBureauInput` mutation. */
export type TradeLineBureauIdFkeyInverseInput = {
  /** Flag indicating whether all other `tradeLine` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `tradeLine` for the far side of the relationship. */
  connectById?: Maybe<Array<TradeLineTradeLinePkeyConnect>>;
  /** The primary key(s) for `tradeLine` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<TradeLineNodeIdConnect>>;
  /** The primary key(s) for `tradeLine` for the far side of the relationship. */
  deleteById?: Maybe<Array<TradeLineTradeLinePkeyDelete>>;
  /** The primary key(s) for `tradeLine` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<TradeLineNodeIdDelete>>;
  /** The primary key(s) and patch data for `tradeLine` for the far side of the relationship. */
  updateById?: Maybe<Array<TradeLineOnTradeLineForTradeLineBureauIdFkeyUsingTradeLinePkeyUpdate>>;
  /** The primary key(s) and patch data for `tradeLine` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CreditBureauOnTradeLineForTradeLineBureauIdFkeyNodeIdUpdate>>;
  /** A `TradeLineInput` object that will be created and connected to this object. */
  create?: Maybe<Array<TradeLineBureauIdFkeyTradeLineCreateInput>>;
};

/** The `tradeLine` to be created by this mutation. */
export type TradeLineBureauIdFkeyTradeLineCreateInput = {
  id?: Maybe<Scalars['Int']>;
  bureauId?: Maybe<Scalars['Int']>;
  equifaxLenderId?: Maybe<Scalars['Int']>;
  tradeDate?: Maybe<Scalars['String']>;
  creditorId?: Maybe<Scalars['String']>;
  creditorName?: Maybe<Scalars['String']>;
  creditorPhone?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  association?: Maybe<Scalars['String']>;
  updateSource?: Maybe<Scalars['String']>;
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  paymentTerm?: Maybe<Scalars['String']>;
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  dateOpened?: Maybe<Scalars['String']>;
  dateLastActivityOrPayment?: Maybe<Scalars['String']>;
  portfolioTypeDescription?: Maybe<Scalars['String']>;
  portfolioTypeCode?: Maybe<Scalars['String']>;
  paymentRateDescription?: Maybe<Scalars['String']>;
  paymentRateCode?: Maybe<Scalars['String']>;
  past30?: Maybe<Scalars['BigFloat']>;
  past60?: Maybe<Scalars['BigFloat']>;
  past90?: Maybe<Scalars['BigFloat']>;
  lastSlowPayment?: Maybe<Scalars['String']>;
  narratives?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditBureauToBureauId?: Maybe<TradeLineBureauIdFkeyInput>;
  equifaxLenderToEquifaxLenderId?: Maybe<TradeLineEquifaxLenderIdFkeyInput>;
};

/**
 * A condition to be used against `TradeLine` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TradeLineCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bureauId` field. */
  bureauId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `equifaxLenderId` field. */
  equifaxLenderId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `tradeDate` field. */
  tradeDate?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `creditorId` field. */
  creditorId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `creditorName` field. */
  creditorName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `creditorPhone` field. */
  creditorPhone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `accountNumber` field. */
  accountNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `association` field. */
  association?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updateSource` field. */
  updateSource?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `highCreditAmount` field. */
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `balanceAmount` field. */
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `paymentTerm` field. */
  paymentTerm?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `paymentTermAmount` field. */
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `pastDueAmount` field. */
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `monthsReviewed` field. */
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `dateOpened` field. */
  dateOpened?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dateLastActivityOrPayment` field. */
  dateLastActivityOrPayment?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `portfolioTypeDescription` field. */
  portfolioTypeDescription?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `portfolioTypeCode` field. */
  portfolioTypeCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `paymentRateDescription` field. */
  paymentRateDescription?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `paymentRateCode` field. */
  paymentRateCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `past30` field. */
  past30?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `past60` field. */
  past60?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `past90` field. */
  past90?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `lastSlowPayment` field. */
  lastSlowPayment?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `narratives` field. */
  narratives?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type TradeLineDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  bureauId?: Maybe<BigIntFilter>;
  equifaxLenderId?: Maybe<BigIntFilter>;
  tradeDate?: Maybe<BigIntFilter>;
  creditorId?: Maybe<BigIntFilter>;
  creditorName?: Maybe<BigIntFilter>;
  creditorPhone?: Maybe<BigIntFilter>;
  accountNumber?: Maybe<BigIntFilter>;
  association?: Maybe<BigIntFilter>;
  updateSource?: Maybe<BigIntFilter>;
  highCreditAmount?: Maybe<BigIntFilter>;
  balanceAmount?: Maybe<BigIntFilter>;
  paymentTerm?: Maybe<BigIntFilter>;
  paymentTermAmount?: Maybe<BigIntFilter>;
  pastDueAmount?: Maybe<BigIntFilter>;
  monthsReviewed?: Maybe<BigIntFilter>;
  dateOpened?: Maybe<BigIntFilter>;
  dateLastActivityOrPayment?: Maybe<BigIntFilter>;
  portfolioTypeDescription?: Maybe<BigIntFilter>;
  portfolioTypeCode?: Maybe<BigIntFilter>;
  paymentRateDescription?: Maybe<BigIntFilter>;
  paymentRateCode?: Maybe<BigIntFilter>;
  past30?: Maybe<BigIntFilter>;
  past60?: Maybe<BigIntFilter>;
  past90?: Maybe<BigIntFilter>;
  lastSlowPayment?: Maybe<BigIntFilter>;
  narratives?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type TradeLineDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of bureauId across the matching connection */
  bureauId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of equifaxLenderId across the matching connection */
  equifaxLenderId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tradeDate across the matching connection */
  tradeDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditorId across the matching connection */
  creditorId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditorName across the matching connection */
  creditorName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditorPhone across the matching connection */
  creditorPhone?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accountNumber across the matching connection */
  accountNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of association across the matching connection */
  association?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updateSource across the matching connection */
  updateSource?: Maybe<Scalars['BigInt']>;
  /** Distinct count of highCreditAmount across the matching connection */
  highCreditAmount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of balanceAmount across the matching connection */
  balanceAmount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of paymentTerm across the matching connection */
  paymentTerm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of paymentTermAmount across the matching connection */
  paymentTermAmount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of pastDueAmount across the matching connection */
  pastDueAmount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of monthsReviewed across the matching connection */
  monthsReviewed?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dateOpened across the matching connection */
  dateOpened?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dateLastActivityOrPayment across the matching connection */
  dateLastActivityOrPayment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of portfolioTypeDescription across the matching connection */
  portfolioTypeDescription?: Maybe<Scalars['BigInt']>;
  /** Distinct count of portfolioTypeCode across the matching connection */
  portfolioTypeCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of paymentRateDescription across the matching connection */
  paymentRateDescription?: Maybe<Scalars['BigInt']>;
  /** Distinct count of paymentRateCode across the matching connection */
  paymentRateCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of past30 across the matching connection */
  past30?: Maybe<Scalars['BigInt']>;
  /** Distinct count of past60 across the matching connection */
  past60?: Maybe<Scalars['BigInt']>;
  /** Distinct count of past90 across the matching connection */
  past90?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastSlowPayment across the matching connection */
  lastSlowPayment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of narratives across the matching connection */
  narratives?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The `equifaxLender` to be created by this mutation. */
export type TradeLineEquifaxLenderIdFkeyEquifaxLendersCreateInput = {
  id?: Maybe<Scalars['Int']>;
  equifaxId: Scalars['String'];
  name: Scalars['String'];
  display?: Maybe<Scalars['Boolean']>;
  reviewedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  lenderToLenderId?: Maybe<EquifaxLendersLenderIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineEquifaxLenderIdFkeyInverseInput>;
};

/** Input for the nested mutation of `equifaxLender` in the `TradeLineInput` mutation. */
export type TradeLineEquifaxLenderIdFkeyInput = {
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  connectById?: Maybe<EquifaxLenderEquifaxLendersPkeyConnect>;
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  connectByEquifaxIdAndName?: Maybe<EquifaxLenderEquifaxLendersEquifaxIdNameKeyConnect>;
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  connectByNodeId?: Maybe<EquifaxLenderNodeIdConnect>;
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  deleteById?: Maybe<EquifaxLenderEquifaxLendersPkeyDelete>;
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  deleteByEquifaxIdAndName?: Maybe<EquifaxLenderEquifaxLendersEquifaxIdNameKeyDelete>;
  /** The primary key(s) for `equifaxLender` for the far side of the relationship. */
  deleteByNodeId?: Maybe<EquifaxLenderNodeIdDelete>;
  /** The primary key(s) and patch data for `equifaxLender` for the far side of the relationship. */
  updateById?: Maybe<EquifaxLenderOnTradeLineForTradeLineEquifaxLenderIdFkeyUsingEquifaxLendersPkeyUpdate>;
  /** The primary key(s) and patch data for `equifaxLender` for the far side of the relationship. */
  updateByEquifaxIdAndName?: Maybe<EquifaxLenderOnTradeLineForTradeLineEquifaxLenderIdFkeyUsingEquifaxLendersEquifaxIdNameKeyUpdate>;
  /** The primary key(s) and patch data for `equifaxLender` for the far side of the relationship. */
  updateByNodeId?: Maybe<TradeLineOnTradeLineForTradeLineEquifaxLenderIdFkeyNodeIdUpdate>;
  /** A `EquifaxLenderInput` object that will be created and connected to this object. */
  create?: Maybe<TradeLineEquifaxLenderIdFkeyEquifaxLendersCreateInput>;
};

/** Input for the nested mutation of `tradeLine` in the `EquifaxLenderInput` mutation. */
export type TradeLineEquifaxLenderIdFkeyInverseInput = {
  /** Flag indicating whether all other `tradeLine` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `tradeLine` for the far side of the relationship. */
  connectById?: Maybe<Array<TradeLineTradeLinePkeyConnect>>;
  /** The primary key(s) for `tradeLine` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<TradeLineNodeIdConnect>>;
  /** The primary key(s) for `tradeLine` for the far side of the relationship. */
  deleteById?: Maybe<Array<TradeLineTradeLinePkeyDelete>>;
  /** The primary key(s) for `tradeLine` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<TradeLineNodeIdDelete>>;
  /** The primary key(s) and patch data for `tradeLine` for the far side of the relationship. */
  updateById?: Maybe<Array<TradeLineOnTradeLineForTradeLineEquifaxLenderIdFkeyUsingTradeLinePkeyUpdate>>;
  /** The primary key(s) and patch data for `tradeLine` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<EquifaxLenderOnTradeLineForTradeLineEquifaxLenderIdFkeyNodeIdUpdate>>;
  /** A `TradeLineInput` object that will be created and connected to this object. */
  create?: Maybe<Array<TradeLineEquifaxLenderIdFkeyTradeLineCreateInput>>;
};

/** The `tradeLine` to be created by this mutation. */
export type TradeLineEquifaxLenderIdFkeyTradeLineCreateInput = {
  id?: Maybe<Scalars['Int']>;
  bureauId?: Maybe<Scalars['Int']>;
  equifaxLenderId?: Maybe<Scalars['Int']>;
  tradeDate?: Maybe<Scalars['String']>;
  creditorId?: Maybe<Scalars['String']>;
  creditorName?: Maybe<Scalars['String']>;
  creditorPhone?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  association?: Maybe<Scalars['String']>;
  updateSource?: Maybe<Scalars['String']>;
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  paymentTerm?: Maybe<Scalars['String']>;
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  dateOpened?: Maybe<Scalars['String']>;
  dateLastActivityOrPayment?: Maybe<Scalars['String']>;
  portfolioTypeDescription?: Maybe<Scalars['String']>;
  portfolioTypeCode?: Maybe<Scalars['String']>;
  paymentRateDescription?: Maybe<Scalars['String']>;
  paymentRateCode?: Maybe<Scalars['String']>;
  past30?: Maybe<Scalars['BigFloat']>;
  past60?: Maybe<Scalars['BigFloat']>;
  past90?: Maybe<Scalars['BigFloat']>;
  lastSlowPayment?: Maybe<Scalars['String']>;
  narratives?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditBureauToBureauId?: Maybe<TradeLineBureauIdFkeyInput>;
  equifaxLenderToEquifaxLenderId?: Maybe<TradeLineEquifaxLenderIdFkeyInput>;
};

/** A filter to be used against `TradeLine` object types. All fields are combined with a logical ‘and.’ */
export type TradeLineFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `bureauId` field. */
  bureauId?: Maybe<IntFilter>;
  /** Filter by the object’s `equifaxLenderId` field. */
  equifaxLenderId?: Maybe<IntFilter>;
  /** Filter by the object’s `tradeDate` field. */
  tradeDate?: Maybe<StringFilter>;
  /** Filter by the object’s `creditorId` field. */
  creditorId?: Maybe<StringFilter>;
  /** Filter by the object’s `creditorName` field. */
  creditorName?: Maybe<StringFilter>;
  /** Filter by the object’s `creditorPhone` field. */
  creditorPhone?: Maybe<StringFilter>;
  /** Filter by the object’s `accountNumber` field. */
  accountNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `association` field. */
  association?: Maybe<StringFilter>;
  /** Filter by the object’s `updateSource` field. */
  updateSource?: Maybe<StringFilter>;
  /** Filter by the object’s `highCreditAmount` field. */
  highCreditAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `balanceAmount` field. */
  balanceAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `paymentTerm` field. */
  paymentTerm?: Maybe<StringFilter>;
  /** Filter by the object’s `paymentTermAmount` field. */
  paymentTermAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `pastDueAmount` field. */
  pastDueAmount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `monthsReviewed` field. */
  monthsReviewed?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `dateOpened` field. */
  dateOpened?: Maybe<StringFilter>;
  /** Filter by the object’s `dateLastActivityOrPayment` field. */
  dateLastActivityOrPayment?: Maybe<StringFilter>;
  /** Filter by the object’s `portfolioTypeDescription` field. */
  portfolioTypeDescription?: Maybe<StringFilter>;
  /** Filter by the object’s `portfolioTypeCode` field. */
  portfolioTypeCode?: Maybe<StringFilter>;
  /** Filter by the object’s `paymentRateDescription` field. */
  paymentRateDescription?: Maybe<StringFilter>;
  /** Filter by the object’s `paymentRateCode` field. */
  paymentRateCode?: Maybe<StringFilter>;
  /** Filter by the object’s `past30` field. */
  past30?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `past60` field. */
  past60?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `past90` field. */
  past90?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `lastSlowPayment` field. */
  lastSlowPayment?: Maybe<StringFilter>;
  /** Filter by the object’s `narratives` field. */
  narratives?: Maybe<StringListFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `bureau` relation. */
  bureau?: Maybe<CreditBureauFilter>;
  /** Filter by the object’s `equifaxLender` relation. */
  equifaxLender?: Maybe<EquifaxLenderFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TradeLineFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TradeLineFilter>>;
  /** Negates the expression. */
  not?: Maybe<TradeLineFilter>;
};

/** Grouping methods for `TradeLine` for usage during aggregation. */
export enum TradeLineGroupBy {
  BureauId = 'BUREAU_ID',
  EquifaxLenderId = 'EQUIFAX_LENDER_ID',
  TradeDate = 'TRADE_DATE',
  CreditorId = 'CREDITOR_ID',
  CreditorName = 'CREDITOR_NAME',
  CreditorPhone = 'CREDITOR_PHONE',
  AccountNumber = 'ACCOUNT_NUMBER',
  Association = 'ASSOCIATION',
  UpdateSource = 'UPDATE_SOURCE',
  HighCreditAmount = 'HIGH_CREDIT_AMOUNT',
  BalanceAmount = 'BALANCE_AMOUNT',
  PaymentTerm = 'PAYMENT_TERM',
  PaymentTermAmount = 'PAYMENT_TERM_AMOUNT',
  PastDueAmount = 'PAST_DUE_AMOUNT',
  MonthsReviewed = 'MONTHS_REVIEWED',
  DateOpened = 'DATE_OPENED',
  DateLastActivityOrPayment = 'DATE_LAST_ACTIVITY_OR_PAYMENT',
  PortfolioTypeDescription = 'PORTFOLIO_TYPE_DESCRIPTION',
  PortfolioTypeCode = 'PORTFOLIO_TYPE_CODE',
  PaymentRateDescription = 'PAYMENT_RATE_DESCRIPTION',
  PaymentRateCode = 'PAYMENT_RATE_CODE',
  Past_30 = 'PAST_30',
  Past_60 = 'PAST_60',
  Past_90 = 'PAST_90',
  LastSlowPayment = 'LAST_SLOW_PAYMENT',
  Narratives = 'NARRATIVES',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type TradeLineHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  bureauId?: Maybe<HavingIntFilter>;
  equifaxLenderId?: Maybe<HavingIntFilter>;
  highCreditAmount?: Maybe<HavingBigfloatFilter>;
  balanceAmount?: Maybe<HavingBigfloatFilter>;
  paymentTermAmount?: Maybe<HavingBigfloatFilter>;
  pastDueAmount?: Maybe<HavingBigfloatFilter>;
  monthsReviewed?: Maybe<HavingBigfloatFilter>;
  past30?: Maybe<HavingBigfloatFilter>;
  past60?: Maybe<HavingBigfloatFilter>;
  past90?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeLineHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  bureauId?: Maybe<HavingIntFilter>;
  equifaxLenderId?: Maybe<HavingIntFilter>;
  highCreditAmount?: Maybe<HavingBigfloatFilter>;
  balanceAmount?: Maybe<HavingBigfloatFilter>;
  paymentTermAmount?: Maybe<HavingBigfloatFilter>;
  pastDueAmount?: Maybe<HavingBigfloatFilter>;
  monthsReviewed?: Maybe<HavingBigfloatFilter>;
  past30?: Maybe<HavingBigfloatFilter>;
  past60?: Maybe<HavingBigfloatFilter>;
  past90?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `TradeLine` aggregates. */
export type TradeLineHavingInput = {
  AND?: Maybe<Array<TradeLineHavingInput>>;
  OR?: Maybe<Array<TradeLineHavingInput>>;
  sum?: Maybe<TradeLineHavingSumInput>;
  distinctCount?: Maybe<TradeLineHavingDistinctCountInput>;
  min?: Maybe<TradeLineHavingMinInput>;
  max?: Maybe<TradeLineHavingMaxInput>;
  average?: Maybe<TradeLineHavingAverageInput>;
  stddevSample?: Maybe<TradeLineHavingStddevSampleInput>;
  stddevPopulation?: Maybe<TradeLineHavingStddevPopulationInput>;
  varianceSample?: Maybe<TradeLineHavingVarianceSampleInput>;
  variancePopulation?: Maybe<TradeLineHavingVariancePopulationInput>;
};

export type TradeLineHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  bureauId?: Maybe<HavingIntFilter>;
  equifaxLenderId?: Maybe<HavingIntFilter>;
  highCreditAmount?: Maybe<HavingBigfloatFilter>;
  balanceAmount?: Maybe<HavingBigfloatFilter>;
  paymentTermAmount?: Maybe<HavingBigfloatFilter>;
  pastDueAmount?: Maybe<HavingBigfloatFilter>;
  monthsReviewed?: Maybe<HavingBigfloatFilter>;
  past30?: Maybe<HavingBigfloatFilter>;
  past60?: Maybe<HavingBigfloatFilter>;
  past90?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeLineHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  bureauId?: Maybe<HavingIntFilter>;
  equifaxLenderId?: Maybe<HavingIntFilter>;
  highCreditAmount?: Maybe<HavingBigfloatFilter>;
  balanceAmount?: Maybe<HavingBigfloatFilter>;
  paymentTermAmount?: Maybe<HavingBigfloatFilter>;
  pastDueAmount?: Maybe<HavingBigfloatFilter>;
  monthsReviewed?: Maybe<HavingBigfloatFilter>;
  past30?: Maybe<HavingBigfloatFilter>;
  past60?: Maybe<HavingBigfloatFilter>;
  past90?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeLineHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  bureauId?: Maybe<HavingIntFilter>;
  equifaxLenderId?: Maybe<HavingIntFilter>;
  highCreditAmount?: Maybe<HavingBigfloatFilter>;
  balanceAmount?: Maybe<HavingBigfloatFilter>;
  paymentTermAmount?: Maybe<HavingBigfloatFilter>;
  pastDueAmount?: Maybe<HavingBigfloatFilter>;
  monthsReviewed?: Maybe<HavingBigfloatFilter>;
  past30?: Maybe<HavingBigfloatFilter>;
  past60?: Maybe<HavingBigfloatFilter>;
  past90?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeLineHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  bureauId?: Maybe<HavingIntFilter>;
  equifaxLenderId?: Maybe<HavingIntFilter>;
  highCreditAmount?: Maybe<HavingBigfloatFilter>;
  balanceAmount?: Maybe<HavingBigfloatFilter>;
  paymentTermAmount?: Maybe<HavingBigfloatFilter>;
  pastDueAmount?: Maybe<HavingBigfloatFilter>;
  monthsReviewed?: Maybe<HavingBigfloatFilter>;
  past30?: Maybe<HavingBigfloatFilter>;
  past60?: Maybe<HavingBigfloatFilter>;
  past90?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeLineHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  bureauId?: Maybe<HavingIntFilter>;
  equifaxLenderId?: Maybe<HavingIntFilter>;
  highCreditAmount?: Maybe<HavingBigfloatFilter>;
  balanceAmount?: Maybe<HavingBigfloatFilter>;
  paymentTermAmount?: Maybe<HavingBigfloatFilter>;
  pastDueAmount?: Maybe<HavingBigfloatFilter>;
  monthsReviewed?: Maybe<HavingBigfloatFilter>;
  past30?: Maybe<HavingBigfloatFilter>;
  past60?: Maybe<HavingBigfloatFilter>;
  past90?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeLineHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  bureauId?: Maybe<HavingIntFilter>;
  equifaxLenderId?: Maybe<HavingIntFilter>;
  highCreditAmount?: Maybe<HavingBigfloatFilter>;
  balanceAmount?: Maybe<HavingBigfloatFilter>;
  paymentTermAmount?: Maybe<HavingBigfloatFilter>;
  pastDueAmount?: Maybe<HavingBigfloatFilter>;
  monthsReviewed?: Maybe<HavingBigfloatFilter>;
  past30?: Maybe<HavingBigfloatFilter>;
  past60?: Maybe<HavingBigfloatFilter>;
  past90?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeLineHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  bureauId?: Maybe<HavingIntFilter>;
  equifaxLenderId?: Maybe<HavingIntFilter>;
  highCreditAmount?: Maybe<HavingBigfloatFilter>;
  balanceAmount?: Maybe<HavingBigfloatFilter>;
  paymentTermAmount?: Maybe<HavingBigfloatFilter>;
  pastDueAmount?: Maybe<HavingBigfloatFilter>;
  monthsReviewed?: Maybe<HavingBigfloatFilter>;
  past30?: Maybe<HavingBigfloatFilter>;
  past60?: Maybe<HavingBigfloatFilter>;
  past90?: Maybe<HavingBigfloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `TradeLine` */
export type TradeLineInput = {
  id?: Maybe<Scalars['Int']>;
  bureauId?: Maybe<Scalars['Int']>;
  equifaxLenderId?: Maybe<Scalars['Int']>;
  tradeDate?: Maybe<Scalars['String']>;
  creditorId?: Maybe<Scalars['String']>;
  creditorName?: Maybe<Scalars['String']>;
  creditorPhone?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  association?: Maybe<Scalars['String']>;
  updateSource?: Maybe<Scalars['String']>;
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  paymentTerm?: Maybe<Scalars['String']>;
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  dateOpened?: Maybe<Scalars['String']>;
  dateLastActivityOrPayment?: Maybe<Scalars['String']>;
  portfolioTypeDescription?: Maybe<Scalars['String']>;
  portfolioTypeCode?: Maybe<Scalars['String']>;
  paymentRateDescription?: Maybe<Scalars['String']>;
  paymentRateCode?: Maybe<Scalars['String']>;
  past30?: Maybe<Scalars['BigFloat']>;
  past60?: Maybe<Scalars['BigFloat']>;
  past90?: Maybe<Scalars['BigFloat']>;
  lastSlowPayment?: Maybe<Scalars['String']>;
  narratives?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditBureauToBureauId?: Maybe<TradeLineBureauIdFkeyInput>;
  equifaxLenderToEquifaxLenderId?: Maybe<TradeLineEquifaxLenderIdFkeyInput>;
};

export type TradeLineMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  bureauId?: Maybe<IntFilter>;
  equifaxLenderId?: Maybe<IntFilter>;
  highCreditAmount?: Maybe<BigFloatFilter>;
  balanceAmount?: Maybe<BigFloatFilter>;
  paymentTermAmount?: Maybe<BigFloatFilter>;
  pastDueAmount?: Maybe<BigFloatFilter>;
  monthsReviewed?: Maybe<BigFloatFilter>;
  past30?: Maybe<BigFloatFilter>;
  past60?: Maybe<BigFloatFilter>;
  past90?: Maybe<BigFloatFilter>;
};

export type TradeLineMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of bureauId across the matching connection */
  bureauId?: Maybe<Scalars['Int']>;
  /** Maximum of equifaxLenderId across the matching connection */
  equifaxLenderId?: Maybe<Scalars['Int']>;
  /** Maximum of highCreditAmount across the matching connection */
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  /** Maximum of balanceAmount across the matching connection */
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  /** Maximum of paymentTermAmount across the matching connection */
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  /** Maximum of pastDueAmount across the matching connection */
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  /** Maximum of monthsReviewed across the matching connection */
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  /** Maximum of past30 across the matching connection */
  past30?: Maybe<Scalars['BigFloat']>;
  /** Maximum of past60 across the matching connection */
  past60?: Maybe<Scalars['BigFloat']>;
  /** Maximum of past90 across the matching connection */
  past90?: Maybe<Scalars['BigFloat']>;
};

export type TradeLineMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  bureauId?: Maybe<IntFilter>;
  equifaxLenderId?: Maybe<IntFilter>;
  highCreditAmount?: Maybe<BigFloatFilter>;
  balanceAmount?: Maybe<BigFloatFilter>;
  paymentTermAmount?: Maybe<BigFloatFilter>;
  pastDueAmount?: Maybe<BigFloatFilter>;
  monthsReviewed?: Maybe<BigFloatFilter>;
  past30?: Maybe<BigFloatFilter>;
  past60?: Maybe<BigFloatFilter>;
  past90?: Maybe<BigFloatFilter>;
};

export type TradeLineMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of bureauId across the matching connection */
  bureauId?: Maybe<Scalars['Int']>;
  /** Minimum of equifaxLenderId across the matching connection */
  equifaxLenderId?: Maybe<Scalars['Int']>;
  /** Minimum of highCreditAmount across the matching connection */
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  /** Minimum of balanceAmount across the matching connection */
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  /** Minimum of paymentTermAmount across the matching connection */
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  /** Minimum of pastDueAmount across the matching connection */
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  /** Minimum of monthsReviewed across the matching connection */
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  /** Minimum of past30 across the matching connection */
  past30?: Maybe<Scalars['BigFloat']>;
  /** Minimum of past60 across the matching connection */
  past60?: Maybe<Scalars['BigFloat']>;
  /** Minimum of past90 across the matching connection */
  past90?: Maybe<Scalars['BigFloat']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type TradeLineNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `tradeLine` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type TradeLineNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `tradeLine` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type TradeLineOnTradeLineForTradeLineBureauIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `creditBureau` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `creditBureau` being updated. */
  patch: CreditBureauPatch;
};

/** The fields on `tradeLine` to look up the row to update. */
export type TradeLineOnTradeLineForTradeLineBureauIdFkeyUsingTradeLinePkeyUpdate = {
  /** An object where the defined keys will be set on the `tradeLine` being updated. */
  patch: UpdateTradeLineOnTradeLineForTradeLineBureauIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type TradeLineOnTradeLineForTradeLineEquifaxLenderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `equifaxLender` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `equifaxLender` being updated. */
  patch: EquifaxLenderPatch;
};

/** The fields on `tradeLine` to look up the row to update. */
export type TradeLineOnTradeLineForTradeLineEquifaxLenderIdFkeyUsingTradeLinePkeyUpdate = {
  /** An object where the defined keys will be set on the `tradeLine` being updated. */
  patch: UpdateTradeLineOnTradeLineForTradeLineEquifaxLenderIdFkeyPatch;
  id: Scalars['Int'];
};

export type TradeLineOverview = {
  totalBalance: Scalars['Float'];
  currentAutoPayment: Scalars['Float'];
  autoTradeLinesQuantity: Scalars['Int'];
  autoTradeLinesBalance: Scalars['Float'];
  autoTradeLinesOver1500Quantity: Scalars['Int'];
  autoTradeLinesOver1500Balance: Scalars['Float'];
  autoTradeLinesUnder1500Quantity: Scalars['Int'];
  autoTradeLinesUnder1500Balance: Scalars['Float'];
  nonAutoTradeLinesQuantity: Scalars['Int'];
  nonAutoTradeLinesBalance: Scalars['Float'];
};

/** Represents an update to a `TradeLine`. Fields that are set will be updated. */
export type TradeLinePatch = {
  id?: Maybe<Scalars['Int']>;
  bureauId?: Maybe<Scalars['Int']>;
  equifaxLenderId?: Maybe<Scalars['Int']>;
  tradeDate?: Maybe<Scalars['String']>;
  creditorId?: Maybe<Scalars['String']>;
  creditorName?: Maybe<Scalars['String']>;
  creditorPhone?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  association?: Maybe<Scalars['String']>;
  updateSource?: Maybe<Scalars['String']>;
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  paymentTerm?: Maybe<Scalars['String']>;
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  dateOpened?: Maybe<Scalars['String']>;
  dateLastActivityOrPayment?: Maybe<Scalars['String']>;
  portfolioTypeDescription?: Maybe<Scalars['String']>;
  portfolioTypeCode?: Maybe<Scalars['String']>;
  paymentRateDescription?: Maybe<Scalars['String']>;
  paymentRateCode?: Maybe<Scalars['String']>;
  past30?: Maybe<Scalars['BigFloat']>;
  past60?: Maybe<Scalars['BigFloat']>;
  past90?: Maybe<Scalars['BigFloat']>;
  lastSlowPayment?: Maybe<Scalars['String']>;
  narratives?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditBureauToBureauId?: Maybe<TradeLineBureauIdFkeyInput>;
  equifaxLenderToEquifaxLenderId?: Maybe<TradeLineEquifaxLenderIdFkeyInput>;
};

export type TradeLineStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  bureauId?: Maybe<BigFloatFilter>;
  equifaxLenderId?: Maybe<BigFloatFilter>;
  highCreditAmount?: Maybe<BigFloatFilter>;
  balanceAmount?: Maybe<BigFloatFilter>;
  paymentTermAmount?: Maybe<BigFloatFilter>;
  pastDueAmount?: Maybe<BigFloatFilter>;
  monthsReviewed?: Maybe<BigFloatFilter>;
  past30?: Maybe<BigFloatFilter>;
  past60?: Maybe<BigFloatFilter>;
  past90?: Maybe<BigFloatFilter>;
};

export type TradeLineStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of bureauId across the matching connection */
  bureauId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of equifaxLenderId across the matching connection */
  equifaxLenderId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of highCreditAmount across the matching connection */
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of balanceAmount across the matching connection */
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of paymentTermAmount across the matching connection */
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of pastDueAmount across the matching connection */
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of monthsReviewed across the matching connection */
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of past30 across the matching connection */
  past30?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of past60 across the matching connection */
  past60?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of past90 across the matching connection */
  past90?: Maybe<Scalars['BigFloat']>;
};

export type TradeLineStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  bureauId?: Maybe<BigFloatFilter>;
  equifaxLenderId?: Maybe<BigFloatFilter>;
  highCreditAmount?: Maybe<BigFloatFilter>;
  balanceAmount?: Maybe<BigFloatFilter>;
  paymentTermAmount?: Maybe<BigFloatFilter>;
  pastDueAmount?: Maybe<BigFloatFilter>;
  monthsReviewed?: Maybe<BigFloatFilter>;
  past30?: Maybe<BigFloatFilter>;
  past60?: Maybe<BigFloatFilter>;
  past90?: Maybe<BigFloatFilter>;
};

export type TradeLineStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of bureauId across the matching connection */
  bureauId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of equifaxLenderId across the matching connection */
  equifaxLenderId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of highCreditAmount across the matching connection */
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of balanceAmount across the matching connection */
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of paymentTermAmount across the matching connection */
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of pastDueAmount across the matching connection */
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of monthsReviewed across the matching connection */
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of past30 across the matching connection */
  past30?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of past60 across the matching connection */
  past60?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of past90 across the matching connection */
  past90?: Maybe<Scalars['BigFloat']>;
};

export type TradeLineSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  bureauId?: Maybe<BigIntFilter>;
  equifaxLenderId?: Maybe<BigIntFilter>;
  highCreditAmount?: Maybe<BigFloatFilter>;
  balanceAmount?: Maybe<BigFloatFilter>;
  paymentTermAmount?: Maybe<BigFloatFilter>;
  pastDueAmount?: Maybe<BigFloatFilter>;
  monthsReviewed?: Maybe<BigFloatFilter>;
  past30?: Maybe<BigFloatFilter>;
  past60?: Maybe<BigFloatFilter>;
  past90?: Maybe<BigFloatFilter>;
};

export type TradeLineSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of bureauId across the matching connection */
  bureauId: Scalars['BigInt'];
  /** Sum of equifaxLenderId across the matching connection */
  equifaxLenderId: Scalars['BigInt'];
  /** Sum of highCreditAmount across the matching connection */
  highCreditAmount: Scalars['BigFloat'];
  /** Sum of balanceAmount across the matching connection */
  balanceAmount: Scalars['BigFloat'];
  /** Sum of paymentTermAmount across the matching connection */
  paymentTermAmount: Scalars['BigFloat'];
  /** Sum of pastDueAmount across the matching connection */
  pastDueAmount: Scalars['BigFloat'];
  /** Sum of monthsReviewed across the matching connection */
  monthsReviewed: Scalars['BigFloat'];
  /** Sum of past30 across the matching connection */
  past30: Scalars['BigFloat'];
  /** Sum of past60 across the matching connection */
  past60: Scalars['BigFloat'];
  /** Sum of past90 across the matching connection */
  past90: Scalars['BigFloat'];
};

/** The fields on `tradeLine` to look up the row to connect. */
export type TradeLineTradeLinePkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `tradeLine` to look up the row to delete. */
export type TradeLineTradeLinePkeyDelete = {
  id: Scalars['Int'];
};

export type TradeLineVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  bureauId?: Maybe<BigFloatFilter>;
  equifaxLenderId?: Maybe<BigFloatFilter>;
  highCreditAmount?: Maybe<BigFloatFilter>;
  balanceAmount?: Maybe<BigFloatFilter>;
  paymentTermAmount?: Maybe<BigFloatFilter>;
  pastDueAmount?: Maybe<BigFloatFilter>;
  monthsReviewed?: Maybe<BigFloatFilter>;
  past30?: Maybe<BigFloatFilter>;
  past60?: Maybe<BigFloatFilter>;
  past90?: Maybe<BigFloatFilter>;
};

export type TradeLineVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of bureauId across the matching connection */
  bureauId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of equifaxLenderId across the matching connection */
  equifaxLenderId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of highCreditAmount across the matching connection */
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of balanceAmount across the matching connection */
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of paymentTermAmount across the matching connection */
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of pastDueAmount across the matching connection */
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of monthsReviewed across the matching connection */
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  /** Population variance of past30 across the matching connection */
  past30?: Maybe<Scalars['BigFloat']>;
  /** Population variance of past60 across the matching connection */
  past60?: Maybe<Scalars['BigFloat']>;
  /** Population variance of past90 across the matching connection */
  past90?: Maybe<Scalars['BigFloat']>;
};

export type TradeLineVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  bureauId?: Maybe<BigFloatFilter>;
  equifaxLenderId?: Maybe<BigFloatFilter>;
  highCreditAmount?: Maybe<BigFloatFilter>;
  balanceAmount?: Maybe<BigFloatFilter>;
  paymentTermAmount?: Maybe<BigFloatFilter>;
  pastDueAmount?: Maybe<BigFloatFilter>;
  monthsReviewed?: Maybe<BigFloatFilter>;
  past30?: Maybe<BigFloatFilter>;
  past60?: Maybe<BigFloatFilter>;
  past90?: Maybe<BigFloatFilter>;
};

export type TradeLineVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of bureauId across the matching connection */
  bureauId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of equifaxLenderId across the matching connection */
  equifaxLenderId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of highCreditAmount across the matching connection */
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of balanceAmount across the matching connection */
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of paymentTermAmount across the matching connection */
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of pastDueAmount across the matching connection */
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of monthsReviewed across the matching connection */
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of past30 across the matching connection */
  past30?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of past60 across the matching connection */
  past60?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of past90 across the matching connection */
  past90?: Maybe<Scalars['BigFloat']>;
};

export type TradeLines = {
  overview: TradeLineOverview;
  all: Array<Trade>;
  autoOver1500: Array<Trade>;
  autoUnder1500: Array<Trade>;
  nonAuto: Array<Trade>;
};

/** A connection to a list of `TradeLine` values. */
export type TradeLinesConnection = {
  /** A list of `TradeLine` objects. */
  nodes: Array<TradeLine>;
  /** A list of edges which contains the `TradeLine` and cursor to aid in pagination. */
  edges: Array<TradeLinesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TradeLine` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TradeLineAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TradeLineAggregates>>;
};


/** A connection to a list of `TradeLine` values. */
export type TradeLinesConnectionGroupedAggregatesArgs = {
  groupBy: Array<TradeLineGroupBy>;
  having?: Maybe<TradeLineHavingInput>;
};

/** A `TradeLine` edge in the connection. */
export type TradeLinesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TradeLine` at the end of the edge. */
  node: TradeLine;
};

/** Methods to use when ordering `TradeLine`. */
export enum TradeLinesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BureauIdAsc = 'BUREAU_ID_ASC',
  BureauIdDesc = 'BUREAU_ID_DESC',
  EquifaxLenderIdAsc = 'EQUIFAX_LENDER_ID_ASC',
  EquifaxLenderIdDesc = 'EQUIFAX_LENDER_ID_DESC',
  TradeDateAsc = 'TRADE_DATE_ASC',
  TradeDateDesc = 'TRADE_DATE_DESC',
  CreditorIdAsc = 'CREDITOR_ID_ASC',
  CreditorIdDesc = 'CREDITOR_ID_DESC',
  CreditorNameAsc = 'CREDITOR_NAME_ASC',
  CreditorNameDesc = 'CREDITOR_NAME_DESC',
  CreditorPhoneAsc = 'CREDITOR_PHONE_ASC',
  CreditorPhoneDesc = 'CREDITOR_PHONE_DESC',
  AccountNumberAsc = 'ACCOUNT_NUMBER_ASC',
  AccountNumberDesc = 'ACCOUNT_NUMBER_DESC',
  AssociationAsc = 'ASSOCIATION_ASC',
  AssociationDesc = 'ASSOCIATION_DESC',
  UpdateSourceAsc = 'UPDATE_SOURCE_ASC',
  UpdateSourceDesc = 'UPDATE_SOURCE_DESC',
  HighCreditAmountAsc = 'HIGH_CREDIT_AMOUNT_ASC',
  HighCreditAmountDesc = 'HIGH_CREDIT_AMOUNT_DESC',
  BalanceAmountAsc = 'BALANCE_AMOUNT_ASC',
  BalanceAmountDesc = 'BALANCE_AMOUNT_DESC',
  PaymentTermAsc = 'PAYMENT_TERM_ASC',
  PaymentTermDesc = 'PAYMENT_TERM_DESC',
  PaymentTermAmountAsc = 'PAYMENT_TERM_AMOUNT_ASC',
  PaymentTermAmountDesc = 'PAYMENT_TERM_AMOUNT_DESC',
  PastDueAmountAsc = 'PAST_DUE_AMOUNT_ASC',
  PastDueAmountDesc = 'PAST_DUE_AMOUNT_DESC',
  MonthsReviewedAsc = 'MONTHS_REVIEWED_ASC',
  MonthsReviewedDesc = 'MONTHS_REVIEWED_DESC',
  DateOpenedAsc = 'DATE_OPENED_ASC',
  DateOpenedDesc = 'DATE_OPENED_DESC',
  DateLastActivityOrPaymentAsc = 'DATE_LAST_ACTIVITY_OR_PAYMENT_ASC',
  DateLastActivityOrPaymentDesc = 'DATE_LAST_ACTIVITY_OR_PAYMENT_DESC',
  PortfolioTypeDescriptionAsc = 'PORTFOLIO_TYPE_DESCRIPTION_ASC',
  PortfolioTypeDescriptionDesc = 'PORTFOLIO_TYPE_DESCRIPTION_DESC',
  PortfolioTypeCodeAsc = 'PORTFOLIO_TYPE_CODE_ASC',
  PortfolioTypeCodeDesc = 'PORTFOLIO_TYPE_CODE_DESC',
  PaymentRateDescriptionAsc = 'PAYMENT_RATE_DESCRIPTION_ASC',
  PaymentRateDescriptionDesc = 'PAYMENT_RATE_DESCRIPTION_DESC',
  PaymentRateCodeAsc = 'PAYMENT_RATE_CODE_ASC',
  PaymentRateCodeDesc = 'PAYMENT_RATE_CODE_DESC',
  Past_30Asc = 'PAST_30_ASC',
  Past_30Desc = 'PAST_30_DESC',
  Past_60Asc = 'PAST_60_ASC',
  Past_60Desc = 'PAST_60_DESC',
  Past_90Asc = 'PAST_90_ASC',
  Past_90Desc = 'PAST_90_DESC',
  LastSlowPaymentAsc = 'LAST_SLOW_PAYMENT_ASC',
  LastSlowPaymentDesc = 'LAST_SLOW_PAYMENT_DESC',
  NarrativesAsc = 'NARRATIVES_ASC',
  NarrativesDesc = 'NARRATIVES_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum TradeProvider {
  Cbb = 'cbb',
  Kbb = 'kbb'
}

export type TransactionResponse = {
  entity?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  flags?: Maybe<Array<Scalars['String']>>;
  fields?: Maybe<IdFields>;
  images?: Maybe<Images>;
  action?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customerUid?: Maybe<Scalars['String']>;
  duplicates?: Maybe<Array<Maybe<IdDuplicate>>>;
  dashboardUrl?: Maybe<Scalars['String']>;
  firstStep?: Maybe<Scalars['String']>;
  nextStep?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  shortUrl?: Maybe<Scalars['String']>;
};


/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['UUID']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['UUID']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

export type UnreadLeadCountResponse = {
  companyId: Scalars['Int'];
  unreadCounts: Array<Maybe<ProductLeadCount>>;
};

/** All input for the `updateAnalyticByNodeId` mutation. */
export type UpdateAnalyticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Analytic` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Analytic` being updated. */
  patch: AnalyticPatch;
};

/** All input for the `updateAnalytic` mutation. */
export type UpdateAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Analytic` being updated. */
  patch: AnalyticPatch;
  id: Scalars['Int'];
};

/** The output of our update `Analytic` mutation. */
export type UpdateAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Analytic` that was updated by this mutation. */
  analytic?: Maybe<Analytic>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Analytic`. */
  company?: Maybe<Company>;
  /** An edge for our `Analytic`. May be used by Relay 1. */
  analyticEdge?: Maybe<AnalyticsEdge>;
};


/** The output of our update `Analytic` mutation. */
export type UpdateAnalyticPayloadAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsOrderBy>>;
};

/** All input for the `updateAnalyticsCtaByNodeId` mutation. */
export type UpdateAnalyticsCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AnalyticsCta` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AnalyticsCta` being updated. */
  patch: AnalyticsCtaPatch;
};

/** All input for the `updateAnalyticsCta` mutation. */
export type UpdateAnalyticsCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AnalyticsCta` being updated. */
  patch: AnalyticsCtaPatch;
  eventId: Scalars['Int'];
};

/** The output of our update `AnalyticsCta` mutation. */
export type UpdateAnalyticsCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AnalyticsCta` that was updated by this mutation. */
  analyticsCta?: Maybe<AnalyticsCta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AnalyticsCta`. May be used by Relay 1. */
  analyticsCtaEdge?: Maybe<AnalyticsCtasEdge>;
};


/** The output of our update `AnalyticsCta` mutation. */
export type UpdateAnalyticsCtaPayloadAnalyticsCtaEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsCtasOrderBy>>;
};

/** All input for the `updateAppointmentByNodeId` mutation. */
export type UpdateAppointmentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Appointment` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Appointment` being updated. */
  patch: AppointmentPatch;
};

/** All input for the `updateAppointment` mutation. */
export type UpdateAppointmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Appointment` being updated. */
  patch: AppointmentPatch;
  id: Scalars['Int'];
};

/** The output of our update `Appointment` mutation. */
export type UpdateAppointmentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Appointment` that was updated by this mutation. */
  appointment?: Maybe<Appointment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `Appointment`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `Appointment`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `Appointment`. May be used by Relay 1. */
  appointmentEdge?: Maybe<AppointmentsEdge>;
};


/** The output of our update `Appointment` mutation. */
export type UpdateAppointmentPayloadAppointmentEdgeArgs = {
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
};

/** All input for the `updateAvaAppByLeadId` mutation. */
export type UpdateAvaAppByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AvaApp` being updated. */
  patch: AvaAppPatch;
  leadId: Scalars['UUID'];
};

/** All input for the `updateAvaAppByNodeId` mutation. */
export type UpdateAvaAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AvaApp` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AvaApp` being updated. */
  patch: AvaAppPatch;
};

/** All input for the `updateAvaApp` mutation. */
export type UpdateAvaAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AvaApp` being updated. */
  patch: AvaAppPatch;
  id: Scalars['Int'];
};

/** The output of our update `AvaApp` mutation. */
export type UpdateAvaAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AvaApp` that was updated by this mutation. */
  avaApp?: Maybe<AvaApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `AvaApp`. */
  lead?: Maybe<Lead>;
  /** An edge for our `AvaApp`. May be used by Relay 1. */
  avaAppEdge?: Maybe<AvaAppsEdge>;
};


/** The output of our update `AvaApp` mutation. */
export type UpdateAvaAppPayloadAvaAppEdgeArgs = {
  orderBy?: Maybe<Array<AvaAppsOrderBy>>;
};

/** All input for the `updateBillableMetricLogByNodeId` mutation. */
export type UpdateBillableMetricLogByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BillableMetricLog` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BillableMetricLog` being updated. */
  patch: BillableMetricLogPatch;
};

/** All input for the `updateBillableMetricLog` mutation. */
export type UpdateBillableMetricLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `BillableMetricLog` being updated. */
  patch: BillableMetricLogPatch;
  id: Scalars['Int'];
};

/** The output of our update `BillableMetricLog` mutation. */
export type UpdateBillableMetricLogPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BillableMetricLog` that was updated by this mutation. */
  billableMetricLog?: Maybe<BillableMetricLog>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `BillableMetricLog`. May be used by Relay 1. */
  billableMetricLogEdge?: Maybe<BillableMetricLogsEdge>;
};


/** The output of our update `BillableMetricLog` mutation. */
export type UpdateBillableMetricLogPayloadBillableMetricLogEdgeArgs = {
  orderBy?: Maybe<Array<BillableMetricLogsOrderBy>>;
};

/** All input for the `updateChannelByName` mutation. */
export type UpdateChannelByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Channel` being updated. */
  patch: ChannelPatch;
  name: Scalars['String'];
};

/** All input for the `updateChannelByNodeId` mutation. */
export type UpdateChannelByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Channel` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Channel` being updated. */
  patch: ChannelPatch;
};

/** All input for the `updateChannel` mutation. */
export type UpdateChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Channel` being updated. */
  patch: ChannelPatch;
  id: Scalars['Int'];
};

/** The output of our update `Channel` mutation. */
export type UpdateChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Channel` that was updated by this mutation. */
  channel?: Maybe<Channel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge?: Maybe<ChannelsEdge>;
};


/** The output of our update `Channel` mutation. */
export type UpdateChannelPayloadChannelEdgeArgs = {
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateCompanyAnalyticByNodeId` mutation. */
export type UpdateCompanyAnalyticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyAnalytic` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyAnalytic` being updated. */
  patch: CompanyAnalyticPatch;
};

/** All input for the `updateCompanyAnalytic` mutation. */
export type UpdateCompanyAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyAnalytic` being updated. */
  patch: CompanyAnalyticPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyAnalytic` mutation. */
export type UpdateCompanyAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyAnalytic` that was updated by this mutation. */
  companyAnalytic?: Maybe<CompanyAnalytic>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyAnalytic`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyAnalytic`. May be used by Relay 1. */
  companyAnalyticEdge?: Maybe<CompanyAnalyticsEdge>;
};


/** The output of our update `CompanyAnalytic` mutation. */
export type UpdateCompanyAnalyticPayloadCompanyAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<CompanyAnalyticsOrderBy>>;
};

/** All input for the `updateCompanyByExternalId` mutation. */
export type UpdateCompanyByExternalIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
  externalId: Scalars['String'];
};

/** All input for the `updateCompanyByLagoId` mutation. */
export type UpdateCompanyByLagoIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
  lagoId: Scalars['UUID'];
};

/** All input for the `updateCompanyByName` mutation. */
export type UpdateCompanyByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
  name: Scalars['String'];
};

/** All input for the `updateCompanyByNodeId` mutation. */
export type UpdateCompanyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Company` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
};

/** All input for the `updateCompanyByPrimaryGroup` mutation. */
export type UpdateCompanyByPrimaryGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
  primaryGroup: Scalars['Int'];
};

/** All input for the `updateCompanyChannelByCompanyIdAndNameAndChannelId` mutation. */
export type UpdateCompanyChannelByCompanyIdAndNameAndChannelIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyChannel` being updated. */
  patch: CompanyChannelPatch;
  companyId: Scalars['Int'];
  name: Scalars['String'];
  channelId: Scalars['Int'];
};

/** All input for the `updateCompanyChannelByNodeId` mutation. */
export type UpdateCompanyChannelByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyChannel` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** All input for the `updateCompanyChannel` mutation. */
export type UpdateCompanyChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyChannel` being updated. */
  patch: CompanyChannelPatch;
  id: Scalars['Int'];
};

/** All input for the `updateCompanyChannelMediaByCompanyChannelIdAndMediaId` mutation. */
export type UpdateCompanyChannelMediaByCompanyChannelIdAndMediaIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyChannelMedia` being updated. */
  patch: CompanyChannelMediaPatch;
  companyChannelId: Scalars['Int'];
  mediaId: Scalars['Int'];
};

/** All input for the `updateCompanyChannelMediaByNodeId` mutation. */
export type UpdateCompanyChannelMediaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyChannelMedia` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyChannelMedia` being updated. */
  patch: CompanyChannelMediaPatch;
};

/** All input for the `updateCompanyChannelMedia` mutation. */
export type UpdateCompanyChannelMediaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyChannelMedia` being updated. */
  patch: CompanyChannelMediaPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyChannelMedia` mutation. */
export type UpdateCompanyChannelMediaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyChannelMedia` that was updated by this mutation. */
  companyChannelMedia?: Maybe<CompanyChannelMedia>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CompanyChannel` that is related to this `CompanyChannelMedia`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `Media` that is related to this `CompanyChannelMedia`. */
  media?: Maybe<Media>;
  /** Reads a single `User` that is related to this `CompanyChannelMedia`. */
  assignee?: Maybe<User>;
  /** An edge for our `CompanyChannelMedia`. May be used by Relay 1. */
  companyChannelMediaEdge?: Maybe<CompanyChannelMediaEdge>;
};


/** The output of our update `CompanyChannelMedia` mutation. */
export type UpdateCompanyChannelMediaPayloadCompanyChannelMediaEdgeArgs = {
  orderBy?: Maybe<Array<CompanyChannelMediaOrderBy>>;
};

/** The output of our update `CompanyChannel` mutation. */
export type UpdateCompanyChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyChannel` that was updated by this mutation. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Channel` that is related to this `CompanyChannel`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Company` that is related to this `CompanyChannel`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyChannel`. May be used by Relay 1. */
  companyChannelEdge?: Maybe<CompanyChannelsEdge>;
};


/** The output of our update `CompanyChannel` mutation. */
export type UpdateCompanyChannelPayloadCompanyChannelEdgeArgs = {
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
};

/** All input for the `updateCompanyCtaByNodeId` mutation. */
export type UpdateCompanyCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyCta` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyCta` being updated. */
  patch: CompanyCtaPatch;
};

/** All input for the `updateCompanyCta` mutation. */
export type UpdateCompanyCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyCta` being updated. */
  patch: CompanyCtaPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyCta` mutation. */
export type UpdateCompanyCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyCta` that was updated by this mutation. */
  companyCta?: Maybe<CompanyCta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CompanyCta`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Group` that is related to this `CompanyCta`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyCta`. May be used by Relay 1. */
  companyCtaEdge?: Maybe<CompanyCtasEdge>;
};


/** The output of our update `CompanyCta` mutation. */
export type UpdateCompanyCtaPayloadCompanyCtaEdgeArgs = {
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
};

/** All input for the `updateCompanyDomainByDomain` mutation. */
export type UpdateCompanyDomainByDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyDomain` being updated. */
  patch: CompanyDomainPatch;
  domain: Scalars['String'];
};

/** All input for the `updateCompanyDomainByNodeId` mutation. */
export type UpdateCompanyDomainByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomain` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyDomain` being updated. */
  patch: CompanyDomainPatch;
};

/** All input for the `updateCompanyDomain` mutation. */
export type UpdateCompanyDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyDomain` being updated. */
  patch: CompanyDomainPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyDomain` mutation. */
export type UpdateCompanyDomainPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomain` that was updated by this mutation. */
  companyDomain?: Maybe<CompanyDomain>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomain`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyDomain`. May be used by Relay 1. */
  companyDomainEdge?: Maybe<CompanyDomainsEdge>;
};


/** The output of our update `CompanyDomain` mutation. */
export type UpdateCompanyDomainPayloadCompanyDomainEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
};

/** All input for the `updateCompanyDomainsApprovedByNodeId` mutation. */
export type UpdateCompanyDomainsApprovedByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomainsApproved` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyDomainsApproved` being updated. */
  patch: CompanyDomainsApprovedPatch;
};

/** All input for the `updateCompanyDomainsApproved` mutation. */
export type UpdateCompanyDomainsApprovedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyDomainsApproved` being updated. */
  patch: CompanyDomainsApprovedPatch;
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** The output of our update `CompanyDomainsApproved` mutation. */
export type UpdateCompanyDomainsApprovedPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsApproved` that was updated by this mutation. */
  companyDomainsApproved?: Maybe<CompanyDomainsApproved>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsApproved`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsApproved`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsApproved`. May be used by Relay 1. */
  companyDomainsApprovedEdge?: Maybe<CompanyDomainsApprovedsEdge>;
};


/** The output of our update `CompanyDomainsApproved` mutation. */
export type UpdateCompanyDomainsApprovedPayloadCompanyDomainsApprovedEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
};

/** All input for the `updateCompanyDomainsPathByDomainIdAndPath` mutation. */
export type UpdateCompanyDomainsPathByDomainIdAndPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyDomainsPath` being updated. */
  patch: CompanyDomainsPathPatch;
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** All input for the `updateCompanyDomainsPathByNodeId` mutation. */
export type UpdateCompanyDomainsPathByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomainsPath` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyDomainsPath` being updated. */
  patch: CompanyDomainsPathPatch;
};

/** All input for the `updateCompanyDomainsPath` mutation. */
export type UpdateCompanyDomainsPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyDomainsPath` being updated. */
  patch: CompanyDomainsPathPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyDomainsPath` mutation. */
export type UpdateCompanyDomainsPathPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsPath` that was updated by this mutation. */
  companyDomainsPath?: Maybe<CompanyDomainsPath>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsPath`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsPath`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsPath`. May be used by Relay 1. */
  companyDomainsPathEdge?: Maybe<CompanyDomainsPathsEdge>;
};


/** The output of our update `CompanyDomainsPath` mutation. */
export type UpdateCompanyDomainsPathPayloadCompanyDomainsPathEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
};

/** All input for the `updateCompanyGroupByNodeId` mutation. */
export type UpdateCompanyGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyGroup` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyGroup` being updated. */
  patch: CompanyGroupPatch;
};

/** All input for the `updateCompanyGroup` mutation. */
export type UpdateCompanyGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyGroup` being updated. */
  patch: CompanyGroupPatch;
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** The output of our update `CompanyGroup` mutation. */
export type UpdateCompanyGroupPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyGroup` that was updated by this mutation. */
  companyGroup?: Maybe<CompanyGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyGroup`. */
  company?: Maybe<Company>;
  /** Reads a single `Group` that is related to this `CompanyGroup`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyGroup`. May be used by Relay 1. */
  companyGroupEdge?: Maybe<CompanyGroupsEdge>;
};


/** The output of our update `CompanyGroup` mutation. */
export type UpdateCompanyGroupPayloadCompanyGroupEdgeArgs = {
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
};

/** All input for the `updateCompany` mutation. */
export type UpdateCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
  id: Scalars['Int'];
};

/** All input for the `updateCompanyIntegrationByNodeId` mutation. */
export type UpdateCompanyIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyIntegration` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** All input for the `updateCompanyIntegration` mutation. */
export type UpdateCompanyIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyIntegration` mutation. */
export type UpdateCompanyIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyIntegration` that was updated by this mutation. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `CompanyIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Company` that is related to this `CompanyIntegration`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyIntegration`. May be used by Relay 1. */
  companyIntegrationEdge?: Maybe<CompanyIntegrationsEdge>;
};


/** The output of our update `CompanyIntegration` mutation. */
export type UpdateCompanyIntegrationPayloadCompanyIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
};

/** All input for the `updateCompanyLocationByNodeId` mutation. */
export type UpdateCompanyLocationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyLocation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyLocation` being updated. */
  patch: CompanyLocationPatch;
};

/** All input for the `updateCompanyLocation` mutation. */
export type UpdateCompanyLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyLocation` being updated. */
  patch: CompanyLocationPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyLocation` mutation. */
export type UpdateCompanyLocationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyLocation` that was updated by this mutation. */
  companyLocation?: Maybe<CompanyLocation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyLocation`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyLocation`. May be used by Relay 1. */
  companyLocationEdge?: Maybe<CompanyLocationsEdge>;
};


/** The output of our update `CompanyLocation` mutation. */
export type UpdateCompanyLocationPayloadCompanyLocationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyLocationsOrderBy>>;
};

export type UpdateCompanyOverride = {
  groupId: Scalars['Int'];
  /** Will be used to ensure that there are no sync conflicts */
  updatedAt: Scalars['Datetime'];
  /** User friendly name of this override. */
  name: Scalars['String'];
  /** Actual theme name that exists on the CTA */
  theme?: Maybe<Scalars['String']>;
  /** Should the override be deleted? */
  delete?: Maybe<Scalars['Boolean']>;
  /**
   * Whether this override should be locked to the specific minor/patch
   * version number.  If true, this override will not be applied on any
   * compatible major version other than this specific CTA.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this
   * override will be used.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef?: Maybe<Scalars['JSON']>;
  themeDef?: Maybe<Scalars['JSON']>;
};

/** The output of our update `Company` mutation. */
export type UpdateCompanyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Company` that was updated by this mutation. */
  company?: Maybe<Company>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Company`. */
  groupByPrimaryGroup?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Company`. */
  userByAccountManager?: Maybe<User>;
  /** Reads a single `User` that is related to this `Company`. */
  userBySupportAgent?: Maybe<User>;
  /** An edge for our `Company`. May be used by Relay 1. */
  companyEdge?: Maybe<CompaniesEdge>;
};


/** The output of our update `Company` mutation. */
export type UpdateCompanyPayloadCompanyEdgeArgs = {
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
};

/** All input for the `updateCompanyProductByNodeId` mutation. */
export type UpdateCompanyProductByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyProduct` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyProduct` being updated. */
  patch: CompanyProductPatch;
};

/** All input for the `updateCompanyProduct` mutation. */
export type UpdateCompanyProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyProduct` being updated. */
  patch: CompanyProductPatch;
  id: Scalars['Int'];
};

/** All input for the `updateCompanyProductIntegrationByNodeId` mutation. */
export type UpdateCompanyProductIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyProductIntegration` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyProductIntegration` being updated. */
  patch: CompanyProductIntegrationPatch;
};

/** All input for the `updateCompanyProductIntegration` mutation. */
export type UpdateCompanyProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyProductIntegration` being updated. */
  patch: CompanyProductIntegrationPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyProductIntegration` mutation. */
export type UpdateCompanyProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProductIntegration` that was updated by this mutation. */
  companyProductIntegration?: Maybe<CompanyProductIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CompanyIntegration` that is related to this `CompanyProductIntegration`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `CompanyProduct` that is related to this `CompanyProductIntegration`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** An edge for our `CompanyProductIntegration`. May be used by Relay 1. */
  companyProductIntegrationEdge?: Maybe<CompanyProductIntegrationsEdge>;
};


/** The output of our update `CompanyProductIntegration` mutation. */
export type UpdateCompanyProductIntegrationPayloadCompanyProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
};

/** The output of our update `CompanyProduct` mutation. */
export type UpdateCompanyProductPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProduct` that was updated by this mutation. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyProduct`. */
  company?: Maybe<Company>;
  /** Reads a single `Product` that is related to this `CompanyProduct`. */
  product?: Maybe<Product>;
  /** An edge for our `CompanyProduct`. May be used by Relay 1. */
  companyProductEdge?: Maybe<CompanyProductsEdge>;
};


/** The output of our update `CompanyProduct` mutation. */
export type UpdateCompanyProductPayloadCompanyProductEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
};

/** All input for the `updateCompanyWidgetByNodeId` mutation. */
export type UpdateCompanyWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyWidget` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyWidget` being updated. */
  patch: CompanyWidgetPatch;
};

/** All input for the `updateCompanyWidget` mutation. */
export type UpdateCompanyWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyWidget` being updated. */
  patch: CompanyWidgetPatch;
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/** The output of our update `CompanyWidget` mutation. */
export type UpdateCompanyWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyWidget` that was updated by this mutation. */
  companyWidget?: Maybe<CompanyWidget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `CompanyWidget`. */
  group?: Maybe<Group>;
  /** Reads a single `Widget` that is related to this `CompanyWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CompanyWidget`. May be used by Relay 1. */
  companyWidgetEdge?: Maybe<CompanyWidgetsEdge>;
};


/** The output of our update `CompanyWidget` mutation. */
export type UpdateCompanyWidgetPayloadCompanyWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
};

/** All input for the `updateCreditBureauByNodeId` mutation. */
export type UpdateCreditBureauByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CreditBureau` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CreditBureau` being updated. */
  patch: CreditBureauPatch;
};

/** All input for the `updateCreditBureau` mutation. */
export type UpdateCreditBureauInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CreditBureau` being updated. */
  patch: CreditBureauPatch;
  id: Scalars['Int'];
};

/** The output of our update `CreditBureau` mutation. */
export type UpdateCreditBureauPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CreditBureau` that was updated by this mutation. */
  creditBureau?: Maybe<CreditBureau>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AvaApp` that is related to this `CreditBureau`. */
  avaApp?: Maybe<AvaApp>;
  /** An edge for our `CreditBureau`. May be used by Relay 1. */
  creditBureauEdge?: Maybe<CreditBureausEdge>;
};


/** The output of our update `CreditBureau` mutation. */
export type UpdateCreditBureauPayloadCreditBureauEdgeArgs = {
  orderBy?: Maybe<Array<CreditBureausOrderBy>>;
};

/** All input for the `updateCtaByNodeId` mutation. */
export type UpdateCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Cta` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Cta` being updated. */
  patch: CtaPatch;
};

/** All input for the `updateCtaByProductAndTypeAndVersion` mutation. */
export type UpdateCtaByProductAndTypeAndVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Cta` being updated. */
  patch: CtaPatch;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

/** All input for the `updateCta` mutation. */
export type UpdateCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Cta` being updated. */
  patch: CtaPatch;
  id: Scalars['String'];
};

/** The output of our update `Cta` mutation. */
export type UpdateCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Cta` that was updated by this mutation. */
  cta?: Maybe<Cta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Cta`. May be used by Relay 1. */
  ctaEdge?: Maybe<CtasEdge>;
};


/** The output of our update `Cta` mutation. */
export type UpdateCtaPayloadCtaEdgeArgs = {
  orderBy?: Maybe<Array<CtasOrderBy>>;
};

export type UpdateCtaSpecsAndDefs = {
  /** What theme on the CTA to update */
  theme: Scalars['String'];
  /** Should the theme be deleted? */
  delete?: Maybe<Scalars['Boolean']>;
  /** Use when replacing the theme specifications */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Use when replacing the template specifications */
  templateSpec?: Maybe<Scalars['JSON']>;
  /**
   * Use when redefining the theme definitions for the requested
   * theme.
   */
  themeDef?: Maybe<Scalars['JSON']>;
  /**
   * Use when redefining template definitions for the requested
   * theme.
   */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Use when redefining the plugin value definitions. */
  pluginDef?: Maybe<Scalars['JSON']>;
};

export type UpdateCtaSpecsInput = {
  id: Scalars['String'];
  /** Will be used to ensure that there are no sync conflicts */
  updatedAt: Scalars['Datetime'];
  published?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  ctaStyles?: Maybe<Scalars['String']>;
  ctaStylesStatic?: Maybe<Scalars['String']>;
  ctaTree?: Maybe<Scalars['JSON']>;
  specsDefs?: Maybe<UpdateCtaSpecsAndDefs>;
  companyOverride?: Maybe<UpdateCompanyOverride>;
};

/** All input for the `updateCtaWidgetByNodeId` mutation. */
export type UpdateCtaWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CtaWidget` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CtaWidget` being updated. */
  patch: CtaWidgetPatch;
};

/** All input for the `updateCtaWidget` mutation. */
export type UpdateCtaWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CtaWidget` being updated. */
  patch: CtaWidgetPatch;
  id: Scalars['Int'];
};

/** The output of our update `CtaWidget` mutation. */
export type UpdateCtaWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CtaWidget` that was updated by this mutation. */
  ctaWidget?: Maybe<CtaWidget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CtaWidget`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Widget` that is related to this `CtaWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CtaWidget`. May be used by Relay 1. */
  ctaWidgetEdge?: Maybe<CtaWidgetsEdge>;
};


/** The output of our update `CtaWidget` mutation. */
export type UpdateCtaWidgetPayloadCtaWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
};

/** All input for the `updateDealByNodeId` mutation. */
export type UpdateDealByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Deal` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Deal` being updated. */
  patch: DealPatch;
};

/** All input for the `updateDeal` mutation. */
export type UpdateDealInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Deal` being updated. */
  patch: DealPatch;
  id: Scalars['Int'];
};

/** The output of our update `Deal` mutation. */
export type UpdateDealPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Deal` that was updated by this mutation. */
  deal?: Maybe<Deal>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `Deal`. */
  lead?: Maybe<Lead>;
  /** Reads a single `CompanyIntegration` that is related to this `Deal`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** An edge for our `Deal`. May be used by Relay 1. */
  dealEdge?: Maybe<DealsEdge>;
};


/** The output of our update `Deal` mutation. */
export type UpdateDealPayloadDealEdgeArgs = {
  orderBy?: Maybe<Array<DealsOrderBy>>;
};

/** All input for the `updateDeliveryMethodByNodeId` mutation. */
export type UpdateDeliveryMethodByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DeliveryMethod` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `DeliveryMethod` being updated. */
  patch: DeliveryMethodPatch;
};

/** All input for the `updateDeliveryMethod` mutation. */
export type UpdateDeliveryMethodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `DeliveryMethod` being updated. */
  patch: DeliveryMethodPatch;
  id: Scalars['Int'];
};

/** The output of our update `DeliveryMethod` mutation. */
export type UpdateDeliveryMethodPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DeliveryMethod` that was updated by this mutation. */
  deliveryMethod?: Maybe<DeliveryMethod>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `DeliveryMethod`. */
  group?: Maybe<Group>;
  /** An edge for our `DeliveryMethod`. May be used by Relay 1. */
  deliveryMethodEdge?: Maybe<DeliveryMethodsEdge>;
};


/** The output of our update `DeliveryMethod` mutation. */
export type UpdateDeliveryMethodPayloadDeliveryMethodEdgeArgs = {
  orderBy?: Maybe<Array<DeliveryMethodsOrderBy>>;
};

/** All input for the `updateDistributorRelationshipByDistributorCompanyIdAndCompanyId` mutation. */
export type UpdateDistributorRelationshipByDistributorCompanyIdAndCompanyIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `DistributorRelationship` being updated. */
  patch: DistributorRelationshipPatch;
  distributorCompanyId: Scalars['Int'];
  companyId: Scalars['Int'];
};

/** All input for the `updateDistributorRelationshipByNodeId` mutation. */
export type UpdateDistributorRelationshipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DistributorRelationship` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `DistributorRelationship` being updated. */
  patch: DistributorRelationshipPatch;
};

/** All input for the `updateDistributorRelationship` mutation. */
export type UpdateDistributorRelationshipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `DistributorRelationship` being updated. */
  patch: DistributorRelationshipPatch;
  id: Scalars['Int'];
};

/** The output of our update `DistributorRelationship` mutation. */
export type UpdateDistributorRelationshipPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DistributorRelationship` that was updated by this mutation. */
  distributorRelationship?: Maybe<DistributorRelationship>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `DistributorRelationship`. */
  distributorCompany?: Maybe<Company>;
  /** Reads a single `Company` that is related to this `DistributorRelationship`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyChannel` that is related to this `DistributorRelationship`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** An edge for our `DistributorRelationship`. May be used by Relay 1. */
  distributorRelationshipEdge?: Maybe<DistributorRelationshipsEdge>;
};


/** The output of our update `DistributorRelationship` mutation. */
export type UpdateDistributorRelationshipPayloadDistributorRelationshipEdgeArgs = {
  orderBy?: Maybe<Array<DistributorRelationshipsOrderBy>>;
};

/** All input for the `updateEmailTemplateByNodeId` mutation. */
export type UpdateEmailTemplateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EmailTemplate` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `EmailTemplate` being updated. */
  patch: EmailTemplatePatch;
};

/** All input for the `updateEmailTemplate` mutation. */
export type UpdateEmailTemplateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EmailTemplate` being updated. */
  patch: EmailTemplatePatch;
  name: Scalars['String'];
};

/** The output of our update `EmailTemplate` mutation. */
export type UpdateEmailTemplatePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmailTemplate` that was updated by this mutation. */
  emailTemplate?: Maybe<EmailTemplate>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `EmailTemplate`. May be used by Relay 1. */
  emailTemplateEdge?: Maybe<EmailTemplatesEdge>;
};


/** The output of our update `EmailTemplate` mutation. */
export type UpdateEmailTemplatePayloadEmailTemplateEdgeArgs = {
  orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
};

/** All input for the `updateEquifaxLenderByEquifaxIdAndName` mutation. */
export type UpdateEquifaxLenderByEquifaxIdAndNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EquifaxLender` being updated. */
  patch: EquifaxLenderPatch;
  equifaxId: Scalars['String'];
  name: Scalars['String'];
};

/** All input for the `updateEquifaxLenderByNodeId` mutation. */
export type UpdateEquifaxLenderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EquifaxLender` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `EquifaxLender` being updated. */
  patch: EquifaxLenderPatch;
};

/** All input for the `updateEquifaxLender` mutation. */
export type UpdateEquifaxLenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EquifaxLender` being updated. */
  patch: EquifaxLenderPatch;
  id: Scalars['Int'];
};

/** The output of our update `EquifaxLender` mutation. */
export type UpdateEquifaxLenderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquifaxLender` that was updated by this mutation. */
  equifaxLender?: Maybe<EquifaxLender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lender` that is related to this `EquifaxLender`. */
  lender?: Maybe<Lender>;
  /** An edge for our `EquifaxLender`. May be used by Relay 1. */
  equifaxLenderEdge?: Maybe<EquifaxLendersEdge>;
};


/** The output of our update `EquifaxLender` mutation. */
export type UpdateEquifaxLenderPayloadEquifaxLenderEdgeArgs = {
  orderBy?: Maybe<Array<EquifaxLendersOrderBy>>;
};

/** All input for the `updateFieldByCompanyChannelIdAndConfig` mutation. */
export type UpdateFieldByCompanyChannelIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Field` being updated. */
  patch: FieldPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `updateFieldByCompanyIntegrationIdAndConfig` mutation. */
export type UpdateFieldByCompanyIntegrationIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Field` being updated. */
  patch: FieldPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `updateFieldByCompanyProductIdAndConfig` mutation. */
export type UpdateFieldByCompanyProductIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Field` being updated. */
  patch: FieldPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `updateFieldByNodeId` mutation. */
export type UpdateFieldByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Field` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Field` being updated. */
  patch: FieldPatch;
};

/** All input for the `updateFieldConfigByNodeId` mutation. */
export type UpdateFieldConfigByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FieldConfig` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `FieldConfig` being updated. */
  patch: FieldConfigPatch;
};

/** All input for the `updateFieldConfigByParentIdAndParentTypeAndKey` mutation. */
export type UpdateFieldConfigByParentIdAndParentTypeAndKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FieldConfig` being updated. */
  patch: FieldConfigPatch;
  parentId: Scalars['Int'];
  parentType: Scalars['String'];
  key: Scalars['String'];
};

/** All input for the `updateFieldConfig` mutation. */
export type UpdateFieldConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FieldConfig` being updated. */
  patch: FieldConfigPatch;
  id: Scalars['Int'];
};

/** The output of our update `FieldConfig` mutation. */
export type UpdateFieldConfigPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldConfig` that was updated by this mutation. */
  fieldConfig?: Maybe<FieldConfig>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `FieldConfig`. May be used by Relay 1. */
  fieldConfigEdge?: Maybe<FieldConfigsEdge>;
};


/** The output of our update `FieldConfig` mutation. */
export type UpdateFieldConfigPayloadFieldConfigEdgeArgs = {
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
};

/** All input for the `updateField` mutation. */
export type UpdateFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Field` being updated. */
  patch: FieldPatch;
  id: Scalars['Int'];
};

/** All input for the `updateFieldKeyByKey` mutation. */
export type UpdateFieldKeyByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FieldKey` being updated. */
  patch: FieldKeyPatch;
  key: Scalars['String'];
};

/** All input for the `updateFieldKeyByNodeId` mutation. */
export type UpdateFieldKeyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FieldKey` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `FieldKey` being updated. */
  patch: FieldKeyPatch;
};

/** All input for the `updateFieldKey` mutation. */
export type UpdateFieldKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FieldKey` being updated. */
  patch: FieldKeyPatch;
  id: Scalars['Int'];
};

/** The output of our update `FieldKey` mutation. */
export type UpdateFieldKeyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldKey` that was updated by this mutation. */
  fieldKey?: Maybe<FieldKey>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `FieldKey`. May be used by Relay 1. */
  fieldKeyEdge?: Maybe<FieldKeysEdge>;
};


/** The output of our update `FieldKey` mutation. */
export type UpdateFieldKeyPayloadFieldKeyEdgeArgs = {
  orderBy?: Maybe<Array<FieldKeysOrderBy>>;
};

/** The output of our update `Field` mutation. */
export type UpdateFieldPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Field` that was updated by this mutation. */
  field?: Maybe<Field>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Field`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyProduct` that is related to this `Field`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Reads a single `CompanyChannel` that is related to this `Field`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `CompanyIntegration` that is related to this `Field`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `FieldConfig` that is related to this `Field`. */
  fieldConfigByConfig?: Maybe<FieldConfig>;
  /** An edge for our `Field`. May be used by Relay 1. */
  fieldEdge?: Maybe<FieldsEdge>;
};


/** The output of our update `Field` mutation. */
export type UpdateFieldPayloadFieldEdgeArgs = {
  orderBy?: Maybe<Array<FieldsOrderBy>>;
};

/** All input for the `updateFileByNodeId` mutation. */
export type UpdateFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `File` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `File` being updated. */
  patch: FilePatch;
};

/** All input for the `updateFile` mutation. */
export type UpdateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `File` being updated. */
  patch: FilePatch;
  id: Scalars['Int'];
};

/** The output of our update `File` mutation. */
export type UpdateFilePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `File` that was updated by this mutation. */
  file?: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderProgram` that is related to this `File`. */
  lenderProgram?: Maybe<LenderProgram>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
};


/** The output of our update `File` mutation. */
export type UpdateFilePayloadFileEdgeArgs = {
  orderBy?: Maybe<Array<FilesOrderBy>>;
};

/** All input for the `updateGroupByCognitoName` mutation. */
export type UpdateGroupByCognitoNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
  cognitoName: Scalars['String'];
};

/** All input for the `updateGroupByNodeId` mutation. */
export type UpdateGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Group` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
};

/** All input for the `updateGroup` mutation. */
export type UpdateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
  id: Scalars['Int'];
};

/** The output of our update `Group` mutation. */
export type UpdateGroupPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` that was updated by this mutation. */
  group?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
};


/** The output of our update `Group` mutation. */
export type UpdateGroupPayloadGroupEdgeArgs = {
  orderBy?: Maybe<Array<GroupsOrderBy>>;
};

/** All input for the `updateIdInquiryByNodeId` mutation. */
export type UpdateIdInquiryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdInquiry` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `IdInquiry` being updated. */
  patch: IdInquiryPatch;
};

/** All input for the `updateIdInquiry` mutation. */
export type UpdateIdInquiryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IdInquiry` being updated. */
  patch: IdInquiryPatch;
  id: Scalars['Int'];
};

/** The output of our update `IdInquiry` mutation. */
export type UpdateIdInquiryPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiry` that was updated by this mutation. */
  idInquiry?: Maybe<IdInquiry>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IdInquiry`. */
  lead?: Maybe<Lead>;
  /** Reads a single `Integration` that is related to this `IdInquiry`. */
  integration?: Maybe<Integration>;
  /** An edge for our `IdInquiry`. May be used by Relay 1. */
  idInquiryEdge?: Maybe<IdInquiriesEdge>;
};


/** The output of our update `IdInquiry` mutation. */
export type UpdateIdInquiryPayloadIdInquiryEdgeArgs = {
  orderBy?: Maybe<Array<IdInquiriesOrderBy>>;
};

/** All input for the `updateIdInquiryVerificationByNodeId` mutation. */
export type UpdateIdInquiryVerificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdInquiryVerification` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `IdInquiryVerification` being updated. */
  patch: IdInquiryVerificationPatch;
};

/** All input for the `updateIdInquiryVerificationCheckByNodeId` mutation. */
export type UpdateIdInquiryVerificationCheckByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdInquiryVerificationCheck` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `IdInquiryVerificationCheck` being updated. */
  patch: IdInquiryVerificationCheckPatch;
};

/** All input for the `updateIdInquiryVerificationCheck` mutation. */
export type UpdateIdInquiryVerificationCheckInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IdInquiryVerificationCheck` being updated. */
  patch: IdInquiryVerificationCheckPatch;
  id: Scalars['Int'];
};

/** The output of our update `IdInquiryVerificationCheck` mutation. */
export type UpdateIdInquiryVerificationCheckPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiryVerificationCheck` that was updated by this mutation. */
  idInquiryVerificationCheck?: Maybe<IdInquiryVerificationCheck>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiryVerification` that is related to this `IdInquiryVerificationCheck`. */
  verification?: Maybe<IdInquiryVerification>;
  /** Reads a single `VerificationCheck` that is related to this `IdInquiryVerificationCheck`. */
  verificationCheck?: Maybe<VerificationCheck>;
  /** An edge for our `IdInquiryVerificationCheck`. May be used by Relay 1. */
  idInquiryVerificationCheckEdge?: Maybe<IdInquiryVerificationChecksEdge>;
};


/** The output of our update `IdInquiryVerificationCheck` mutation. */
export type UpdateIdInquiryVerificationCheckPayloadIdInquiryVerificationCheckEdgeArgs = {
  orderBy?: Maybe<Array<IdInquiryVerificationChecksOrderBy>>;
};

/** All input for the `updateIdInquiryVerification` mutation. */
export type UpdateIdInquiryVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IdInquiryVerification` being updated. */
  patch: IdInquiryVerificationPatch;
  id: Scalars['Int'];
};

/** The output of our update `IdInquiryVerification` mutation. */
export type UpdateIdInquiryVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdInquiryVerification` that was updated by this mutation. */
  idInquiryVerification?: Maybe<IdInquiryVerification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiry` that is related to this `IdInquiryVerification`. */
  inquiry?: Maybe<IdInquiry>;
  /** An edge for our `IdInquiryVerification`. May be used by Relay 1. */
  idInquiryVerificationEdge?: Maybe<IdInquiryVerificationsEdge>;
};


/** The output of our update `IdInquiryVerification` mutation. */
export type UpdateIdInquiryVerificationPayloadIdInquiryVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IdInquiryVerificationsOrderBy>>;
};

/** All input for the `updateIdMediaByNodeId` mutation. */
export type UpdateIdMediaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdMedia` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `IdMedia` being updated. */
  patch: IdMediaPatch;
};

/** All input for the `updateIdMedia` mutation. */
export type UpdateIdMediaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IdMedia` being updated. */
  patch: IdMediaPatch;
  id: Scalars['Int'];
};

/** The output of our update `IdMedia` mutation. */
export type UpdateIdMediaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdMedia` that was updated by this mutation. */
  idMedia?: Maybe<IdMedia>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiryVerification` that is related to this `IdMedia`. */
  verification?: Maybe<IdInquiryVerification>;
  /** Reads a single `Media` that is related to this `IdMedia`. */
  media?: Maybe<Media>;
  /** An edge for our `IdMedia`. May be used by Relay 1. */
  idMediaEdge?: Maybe<IdMediaEdge>;
};


/** The output of our update `IdMedia` mutation. */
export type UpdateIdMediaPayloadIdMediaEdgeArgs = {
  orderBy?: Maybe<Array<IdMediaOrderBy>>;
};

/** All input for the `updateIdSessionByNodeId` mutation. */
export type UpdateIdSessionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdSession` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `IdSession` being updated. */
  patch: IdSessionPatch;
};

/** All input for the `updateIdSession` mutation. */
export type UpdateIdSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IdSession` being updated. */
  patch: IdSessionPatch;
  id: Scalars['Int'];
};

/** The output of our update `IdSession` mutation. */
export type UpdateIdSessionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdSession` that was updated by this mutation. */
  idSession?: Maybe<IdSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IdInquiry` that is related to this `IdSession`. */
  inquiry?: Maybe<IdInquiry>;
  /** An edge for our `IdSession`. May be used by Relay 1. */
  idSessionEdge?: Maybe<IdSessionsEdge>;
};


/** The output of our update `IdSession` mutation. */
export type UpdateIdSessionPayloadIdSessionEdgeArgs = {
  orderBy?: Maybe<Array<IdSessionsOrderBy>>;
};

/** All input for the `updateIdVerificationByLeadId` mutation. */
export type UpdateIdVerificationByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IdVerification` being updated. */
  patch: IdVerificationPatch;
  leadId: Scalars['UUID'];
};

/** All input for the `updateIdVerificationByNodeId` mutation. */
export type UpdateIdVerificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdVerification` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `IdVerification` being updated. */
  patch: IdVerificationPatch;
};

/** All input for the `updateIdVerification` mutation. */
export type UpdateIdVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IdVerification` being updated. */
  patch: IdVerificationPatch;
  id: Scalars['Int'];
};

/** The output of our update `IdVerification` mutation. */
export type UpdateIdVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdVerification` that was updated by this mutation. */
  idVerification?: Maybe<IdVerification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IdVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IdVerification`. May be used by Relay 1. */
  idVerificationEdge?: Maybe<IdVerificationsEdge>;
};


/** The output of our update `IdVerification` mutation. */
export type UpdateIdVerificationPayloadIdVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IdVerificationsOrderBy>>;
};

/** All input for the `updateIncomeVerificationByLeadId` mutation. */
export type UpdateIncomeVerificationByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IncomeVerification` being updated. */
  patch: IncomeVerificationPatch;
  leadId: Scalars['UUID'];
};

/** All input for the `updateIncomeVerificationByNodeId` mutation. */
export type UpdateIncomeVerificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IncomeVerification` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `IncomeVerification` being updated. */
  patch: IncomeVerificationPatch;
};

/** All input for the `updateIncomeVerification` mutation. */
export type UpdateIncomeVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IncomeVerification` being updated. */
  patch: IncomeVerificationPatch;
  id: Scalars['Int'];
};

/** The output of our update `IncomeVerification` mutation. */
export type UpdateIncomeVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IncomeVerification` that was updated by this mutation. */
  incomeVerification?: Maybe<IncomeVerification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IncomeVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IncomeVerification`. May be used by Relay 1. */
  incomeVerificationEdge?: Maybe<IncomeVerificationsEdge>;
};


/** The output of our update `IncomeVerification` mutation. */
export type UpdateIncomeVerificationPayloadIncomeVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IncomeVerificationsOrderBy>>;
};

/** All input for the `updateIntegrationByName` mutation. */
export type UpdateIntegrationByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Integration` being updated. */
  patch: IntegrationPatch;
  name: Scalars['String'];
};

/** All input for the `updateIntegrationByNodeId` mutation. */
export type UpdateIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Integration` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Integration` being updated. */
  patch: IntegrationPatch;
};

/** All input for the `updateIntegration` mutation. */
export type UpdateIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Integration` being updated. */
  patch: IntegrationPatch;
  id: Scalars['Int'];
};

/** The output of our update `Integration` mutation. */
export type UpdateIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Integration` that was updated by this mutation. */
  integration?: Maybe<Integration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Integration`. May be used by Relay 1. */
  integrationEdge?: Maybe<IntegrationsEdge>;
};


/** The output of our update `Integration` mutation. */
export type UpdateIntegrationPayloadIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
};

/** All input for the `updateLeadByNodeId` mutation. */
export type UpdateLeadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lead` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Lead` being updated. */
  patch: LeadPatch;
};

/** All input for the `updateLeadCampaignByLeadId` mutation. */
export type UpdateLeadCampaignByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeadCampaign` being updated. */
  patch: LeadCampaignPatch;
  leadId: Scalars['UUID'];
};

/** All input for the `updateLeadCampaignByNodeId` mutation. */
export type UpdateLeadCampaignByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeadCampaign` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LeadCampaign` being updated. */
  patch: LeadCampaignPatch;
};

/** All input for the `updateLeadCampaign` mutation. */
export type UpdateLeadCampaignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeadCampaign` being updated. */
  patch: LeadCampaignPatch;
  id: Scalars['Int'];
};

/** The output of our update `LeadCampaign` mutation. */
export type UpdateLeadCampaignPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadCampaign` that was updated by this mutation. */
  leadCampaign?: Maybe<LeadCampaign>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LeadCampaign`. */
  lead?: Maybe<Lead>;
  /** An edge for our `LeadCampaign`. May be used by Relay 1. */
  leadCampaignEdge?: Maybe<LeadCampaignsEdge>;
};


/** The output of our update `LeadCampaign` mutation. */
export type UpdateLeadCampaignPayloadLeadCampaignEdgeArgs = {
  orderBy?: Maybe<Array<LeadCampaignsOrderBy>>;
};

/** All input for the `updateLead` mutation. */
export type UpdateLeadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Lead` being updated. */
  patch: LeadPatch;
  id: Scalars['UUID'];
};

/** The output of our update `Lead` mutation. */
export type UpdateLeadPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` that was updated by this mutation. */
  lead?: Maybe<Lead>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Lead`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyChannel` that is related to this `Lead`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `NeighborhoodView` that is related to this `Lead`. */
  neighborhoodView?: Maybe<NeighborhoodView>;
  /** An edge for our `Lead`. May be used by Relay 1. */
  leadEdge?: Maybe<LeadsEdge>;
};


/** The output of our update `Lead` mutation. */
export type UpdateLeadPayloadLeadEdgeArgs = {
  orderBy?: Maybe<Array<LeadsOrderBy>>;
};

/** All input for the `updateLeadRoleByNodeId` mutation. */
export type UpdateLeadRoleByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeadRole` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LeadRole` being updated. */
  patch: LeadRolePatch;
};

/** All input for the `updateLeadRole` mutation. */
export type UpdateLeadRoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeadRole` being updated. */
  patch: LeadRolePatch;
  id: Scalars['Int'];
};

/** The output of our update `LeadRole` mutation. */
export type UpdateLeadRolePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadRole` that was updated by this mutation. */
  leadRole?: Maybe<LeadRole>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LeadRole`. */
  lead?: Maybe<Lead>;
  /** Reads a single `User` that is related to this `LeadRole`. */
  user?: Maybe<User>;
  /** An edge for our `LeadRole`. May be used by Relay 1. */
  leadRoleEdge?: Maybe<LeadRolesEdge>;
};


/** The output of our update `LeadRole` mutation. */
export type UpdateLeadRolePayloadLeadRoleEdgeArgs = {
  orderBy?: Maybe<Array<LeadRolesOrderBy>>;
};

/** All input for the `updateLenderAppByNodeId` mutation. */
export type UpdateLenderAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderApp` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LenderApp` being updated. */
  patch: LenderAppPatch;
};

/** All input for the `updateLenderApp` mutation. */
export type UpdateLenderAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LenderApp` being updated. */
  patch: LenderAppPatch;
  id: Scalars['Int'];
};

/** The output of our update `LenderApp` mutation. */
export type UpdateLenderAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderApp` that was updated by this mutation. */
  lenderApp?: Maybe<LenderApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderApp`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `LenderApp`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `LenderApp`. May be used by Relay 1. */
  lenderAppEdge?: Maybe<LenderAppsEdge>;
};


/** The output of our update `LenderApp` mutation. */
export type UpdateLenderAppPayloadLenderAppEdgeArgs = {
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
};

/** All input for the `updateLenderByName` mutation. */
export type UpdateLenderByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Lender` being updated. */
  patch: LenderPatch;
  name: Scalars['String'];
};

/** All input for the `updateLenderByNodeId` mutation. */
export type UpdateLenderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lender` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Lender` being updated. */
  patch: LenderPatch;
};

/** All input for the `updateLenderCreditKeyByNodeId` mutation. */
export type UpdateLenderCreditKeyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderCreditKey` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LenderCreditKey` being updated. */
  patch: LenderCreditKeyPatch;
};

/** All input for the `updateLenderCreditKey` mutation. */
export type UpdateLenderCreditKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LenderCreditKey` being updated. */
  patch: LenderCreditKeyPatch;
  id: Scalars['Int'];
};

/** The output of our update `LenderCreditKey` mutation. */
export type UpdateLenderCreditKeyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderCreditKey` that was updated by this mutation. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderProgram` that is related to this `LenderCreditKey`. */
  lenderProgram?: Maybe<LenderProgram>;
  /** An edge for our `LenderCreditKey`. May be used by Relay 1. */
  lenderCreditKeyEdge?: Maybe<LenderCreditKeysEdge>;
};


/** The output of our update `LenderCreditKey` mutation. */
export type UpdateLenderCreditKeyPayloadLenderCreditKeyEdgeArgs = {
  orderBy?: Maybe<Array<LenderCreditKeysOrderBy>>;
};

/** All input for the `updateLender` mutation. */
export type UpdateLenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Lender` being updated. */
  patch: LenderPatch;
  id: Scalars['Int'];
};

/** The output of our update `Lender` mutation. */
export type UpdateLenderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lender` that was updated by this mutation. */
  lender?: Maybe<Lender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Lender`. May be used by Relay 1. */
  lenderEdge?: Maybe<LendersEdge>;
};


/** The output of our update `Lender` mutation. */
export type UpdateLenderPayloadLenderEdgeArgs = {
  orderBy?: Maybe<Array<LendersOrderBy>>;
};

/** All input for the `updateLenderProgramBonusByNodeId` mutation. */
export type UpdateLenderProgramBonusByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderProgramBonus` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LenderProgramBonus` being updated. */
  patch: LenderProgramBonusPatch;
};

/** All input for the `updateLenderProgramBonus` mutation. */
export type UpdateLenderProgramBonusInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LenderProgramBonus` being updated. */
  patch: LenderProgramBonusPatch;
  id: Scalars['Int'];
};

/** The output of our update `LenderProgramBonus` mutation. */
export type UpdateLenderProgramBonusPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderProgramBonus` that was updated by this mutation. */
  lenderProgramBonus?: Maybe<LenderProgramBonus>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderCreditKey` that is related to this `LenderProgramBonus`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** An edge for our `LenderProgramBonus`. May be used by Relay 1. */
  lenderProgramBonusEdge?: Maybe<LenderProgramBonusesEdge>;
};


/** The output of our update `LenderProgramBonus` mutation. */
export type UpdateLenderProgramBonusPayloadLenderProgramBonusEdgeArgs = {
  orderBy?: Maybe<Array<LenderProgramBonusesOrderBy>>;
};

/** All input for the `updateLenderProgramByNodeId` mutation. */
export type UpdateLenderProgramByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderProgram` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LenderProgram` being updated. */
  patch: LenderProgramPatch;
};

/** All input for the `updateLenderProgram` mutation. */
export type UpdateLenderProgramInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LenderProgram` being updated. */
  patch: LenderProgramPatch;
  id: Scalars['Int'];
};

/** The output of our update `LenderProgram` mutation. */
export type UpdateLenderProgramPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderProgram` that was updated by this mutation. */
  lenderProgram?: Maybe<LenderProgram>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lender` that is related to this `LenderProgram`. */
  lender?: Maybe<Lender>;
  /** An edge for our `LenderProgram`. May be used by Relay 1. */
  lenderProgramEdge?: Maybe<LenderProgramsEdge>;
};


/** The output of our update `LenderProgram` mutation. */
export type UpdateLenderProgramPayloadLenderProgramEdgeArgs = {
  orderBy?: Maybe<Array<LenderProgramsOrderBy>>;
};

/** All input for the `updateLenderQuoteByNodeId` mutation. */
export type UpdateLenderQuoteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderQuote` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LenderQuote` being updated. */
  patch: LenderQuotePatch;
};

/** All input for the `updateLenderQuote` mutation. */
export type UpdateLenderQuoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LenderQuote` being updated. */
  patch: LenderQuotePatch;
  id: Scalars['Int'];
};

/** The output of our update `LenderQuote` mutation. */
export type UpdateLenderQuotePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderQuote` that was updated by this mutation. */
  lenderQuote?: Maybe<LenderQuote>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderQuote`. */
  lead?: Maybe<Lead>;
  /** Reads a single `LenderApp` that is related to this `LenderQuote`. */
  lenderApp?: Maybe<LenderApp>;
  /** Reads a single `Lender` that is related to this `LenderQuote`. */
  lender?: Maybe<Lender>;
  /** Reads a single `CompanyIntegration` that is related to this `LenderQuote`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** An edge for our `LenderQuote`. May be used by Relay 1. */
  lenderQuoteEdge?: Maybe<LenderQuotesEdge>;
};


/** The output of our update `LenderQuote` mutation. */
export type UpdateLenderQuotePayloadLenderQuoteEdgeArgs = {
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
};

/** All input for the `updateMaxAmortizationByNodeId` mutation. */
export type UpdateMaxAmortizationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MaxAmortization` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `MaxAmortization` being updated. */
  patch: MaxAmortizationPatch;
};

/** All input for the `updateMaxAmortization` mutation. */
export type UpdateMaxAmortizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `MaxAmortization` being updated. */
  patch: MaxAmortizationPatch;
  id: Scalars['Int'];
};

/** The output of our update `MaxAmortization` mutation. */
export type UpdateMaxAmortizationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MaxAmortization` that was updated by this mutation. */
  maxAmortization?: Maybe<MaxAmortization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderCreditKey` that is related to this `MaxAmortization`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** An edge for our `MaxAmortization`. May be used by Relay 1. */
  maxAmortizationEdge?: Maybe<MaxAmortizationsEdge>;
};


/** The output of our update `MaxAmortization` mutation. */
export type UpdateMaxAmortizationPayloadMaxAmortizationEdgeArgs = {
  orderBy?: Maybe<Array<MaxAmortizationsOrderBy>>;
};

/** All input for the `updateOptionByNodeId` mutation. */
export type UpdateOptionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Option` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Option` being updated. */
  patch: OptionPatch;
};

/** All input for the `updateOption` mutation. */
export type UpdateOptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Option` being updated. */
  patch: OptionPatch;
  id: Scalars['Int'];
};

/** The output of our update `Option` mutation. */
export type UpdateOptionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Option` that was updated by this mutation. */
  option?: Maybe<Option>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `FieldConfig` that is related to this `Option`. */
  fieldConfig?: Maybe<FieldConfig>;
  /** An edge for our `Option`. May be used by Relay 1. */
  optionEdge?: Maybe<OptionsEdge>;
};


/** The output of our update `Option` mutation. */
export type UpdateOptionPayloadOptionEdgeArgs = {
  orderBy?: Maybe<Array<OptionsOrderBy>>;
};

/** All input for the `updateProductIntegrationByNodeId` mutation. */
export type UpdateProductIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProductIntegration` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProductIntegration` being updated. */
  patch: ProductIntegrationPatch;
};

/** All input for the `updateProductIntegration` mutation. */
export type UpdateProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProductIntegration` being updated. */
  patch: ProductIntegrationPatch;
  id: Scalars['Int'];
};

/** The output of our update `ProductIntegration` mutation. */
export type UpdateProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProductIntegration` that was updated by this mutation. */
  productIntegration?: Maybe<ProductIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `ProductIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Product` that is related to this `ProductIntegration`. */
  product?: Maybe<Product>;
  /** An edge for our `ProductIntegration`. May be used by Relay 1. */
  productIntegrationEdge?: Maybe<ProductIntegrationsEdge>;
};


/** The output of our update `ProductIntegration` mutation. */
export type UpdateProductIntegrationPayloadProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
};

/** All input for the `updateRateByNodeId` mutation. */
export type UpdateRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Rate` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Rate` being updated. */
  patch: RatePatch;
};

/** All input for the `updateRate` mutation. */
export type UpdateRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Rate` being updated. */
  patch: RatePatch;
  id: Scalars['Int'];
};

/** The output of our update `Rate` mutation. */
export type UpdateRatePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Rate` that was updated by this mutation. */
  rate?: Maybe<Rate>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderCreditKey` that is related to this `Rate`. */
  lenderCreditKey?: Maybe<LenderCreditKey>;
  /** An edge for our `Rate`. May be used by Relay 1. */
  rateEdge?: Maybe<RatesEdge>;
};


/** The output of our update `Rate` mutation. */
export type UpdateRatePayloadRateEdgeArgs = {
  orderBy?: Maybe<Array<RatesOrderBy>>;
};

/** All input for the `updateReserveByNodeId` mutation. */
export type UpdateReserveByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Reserve` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Reserve` being updated. */
  patch: ReservePatch;
};

/** All input for the `updateReserve` mutation. */
export type UpdateReserveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Reserve` being updated. */
  patch: ReservePatch;
  id: Scalars['Int'];
};

/** The output of our update `Reserve` mutation. */
export type UpdateReservePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Reserve` that was updated by this mutation. */
  reserve?: Maybe<Reserve>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Rate` that is related to this `Reserve`. */
  rate?: Maybe<Rate>;
  /** An edge for our `Reserve`. May be used by Relay 1. */
  reserveEdge?: Maybe<ReservesEdge>;
};


/** The output of our update `Reserve` mutation. */
export type UpdateReservePayloadReserveEdgeArgs = {
  orderBy?: Maybe<Array<ReservesOrderBy>>;
};

/** All input for the `updateReviewByNodeId` mutation. */
export type UpdateReviewByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Review` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Review` being updated. */
  patch: ReviewPatch;
};

/** All input for the `updateReview` mutation. */
export type UpdateReviewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Review` being updated. */
  patch: ReviewPatch;
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};

/** The output of our update `Review` mutation. */
export type UpdateReviewPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Review` that was updated by this mutation. */
  review?: Maybe<Review>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Review`. */
  company?: Maybe<Company>;
  /** An edge for our `Review`. May be used by Relay 1. */
  reviewEdge?: Maybe<ReviewsEdge>;
};


/** The output of our update `Review` mutation. */
export type UpdateReviewPayloadReviewEdgeArgs = {
  orderBy?: Maybe<Array<ReviewsOrderBy>>;
};

/** All input for the `updateTradeAppByNodeId` mutation. */
export type UpdateTradeAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TradeApp` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TradeApp` being updated. */
  patch: TradeAppPatch;
};

/** All input for the `updateTradeApp` mutation. */
export type UpdateTradeAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TradeApp` being updated. */
  patch: TradeAppPatch;
  id: Scalars['Int'];
};

/** The output of our update `TradeApp` mutation. */
export type UpdateTradeAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TradeApp` that was updated by this mutation. */
  tradeApp?: Maybe<TradeApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `TradeApp`. */
  lead?: Maybe<Lead>;
  /** An edge for our `TradeApp`. May be used by Relay 1. */
  tradeAppEdge?: Maybe<TradeAppsEdge>;
};


/** The output of our update `TradeApp` mutation. */
export type UpdateTradeAppPayloadTradeAppEdgeArgs = {
  orderBy?: Maybe<Array<TradeAppsOrderBy>>;
};

/** All input for the `updateTradeLineByNodeId` mutation. */
export type UpdateTradeLineByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TradeLine` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TradeLine` being updated. */
  patch: TradeLinePatch;
};

/** All input for the `updateTradeLine` mutation. */
export type UpdateTradeLineInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TradeLine` being updated. */
  patch: TradeLinePatch;
  id: Scalars['Int'];
};

/** The output of our update `TradeLine` mutation. */
export type UpdateTradeLinePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TradeLine` that was updated by this mutation. */
  tradeLine?: Maybe<TradeLine>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CreditBureau` that is related to this `TradeLine`. */
  bureau?: Maybe<CreditBureau>;
  /** Reads a single `EquifaxLender` that is related to this `TradeLine`. */
  equifaxLender?: Maybe<EquifaxLender>;
  /** An edge for our `TradeLine`. May be used by Relay 1. */
  tradeLineEdge?: Maybe<TradeLinesEdge>;
};


/** The output of our update `TradeLine` mutation. */
export type UpdateTradeLinePayloadTradeLineEdgeArgs = {
  orderBy?: Maybe<Array<TradeLinesOrderBy>>;
};

/** All input for the `updateUserByNodeId` mutation. */
export type UpdateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
  id: Scalars['Int'];
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Media` that is related to this `User`. */
  picture?: Maybe<Media>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the `updateVehicleBookingByNodeId` mutation. */
export type UpdateVehicleBookingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleBooking` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VehicleBooking` being updated. */
  patch: VehicleBookingPatch;
};

/** All input for the `updateVehicleBooking` mutation. */
export type UpdateVehicleBookingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `VehicleBooking` being updated. */
  patch: VehicleBookingPatch;
  id: Scalars['Int'];
};

/** The output of our update `VehicleBooking` mutation. */
export type UpdateVehicleBookingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleBooking` that was updated by this mutation. */
  vehicleBooking?: Maybe<VehicleBooking>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `LenderProgram` that is related to this `VehicleBooking`. */
  lenderProgram?: Maybe<LenderProgram>;
  /** An edge for our `VehicleBooking`. May be used by Relay 1. */
  vehicleBookingEdge?: Maybe<VehicleBookingsEdge>;
};


/** The output of our update `VehicleBooking` mutation. */
export type UpdateVehicleBookingPayloadVehicleBookingEdgeArgs = {
  orderBy?: Maybe<Array<VehicleBookingsOrderBy>>;
};

/** All input for the `updateVehicleListingByNodeId` mutation. */
export type UpdateVehicleListingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleListing` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VehicleListing` being updated. */
  patch: VehicleListingPatch;
};

/** All input for the `updateVehicleListing` mutation. */
export type UpdateVehicleListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `VehicleListing` being updated. */
  patch: VehicleListingPatch;
  id: Scalars['Int'];
};

/** The output of our update `VehicleListing` mutation. */
export type UpdateVehicleListingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleListing` that was updated by this mutation. */
  vehicleListing?: Maybe<VehicleListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `VehicleListing`. May be used by Relay 1. */
  vehicleListingEdge?: Maybe<VehicleListingsEdge>;
};


/** The output of our update `VehicleListing` mutation. */
export type UpdateVehicleListingPayloadVehicleListingEdgeArgs = {
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
};

/** All input for the `updateVehicleStockByNodeId` mutation. */
export type UpdateVehicleStockByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleStock` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VehicleStock` being updated. */
  patch: VehicleStockPatch;
};

/** All input for the `updateVehicleStockByProviderId` mutation. */
export type UpdateVehicleStockByProviderIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `VehicleStock` being updated. */
  patch: VehicleStockPatch;
  providerId: Scalars['String'];
};

/** All input for the `updateVehicleStockByStockNumber` mutation. */
export type UpdateVehicleStockByStockNumberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `VehicleStock` being updated. */
  patch: VehicleStockPatch;
  stockNumber: Scalars['String'];
};

/** All input for the `updateVehicleStockByVin` mutation. */
export type UpdateVehicleStockByVinInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `VehicleStock` being updated. */
  patch: VehicleStockPatch;
  vin: Scalars['String'];
};

/** All input for the `updateVehicleStock` mutation. */
export type UpdateVehicleStockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `VehicleStock` being updated. */
  patch: VehicleStockPatch;
  id: Scalars['Int'];
};

/** The output of our update `VehicleStock` mutation. */
export type UpdateVehicleStockPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleStock` that was updated by this mutation. */
  vehicleStock?: Maybe<VehicleStock>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `VehicleStock`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyIntegration` that is related to this `VehicleStock`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** An edge for our `VehicleStock`. May be used by Relay 1. */
  vehicleStockEdge?: Maybe<VehicleStocksEdge>;
};


/** The output of our update `VehicleStock` mutation. */
export type UpdateVehicleStockPayloadVehicleStockEdgeArgs = {
  orderBy?: Maybe<Array<VehicleStocksOrderBy>>;
};

/** All input for the `updateVerificationCheckByName` mutation. */
export type UpdateVerificationCheckByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `VerificationCheck` being updated. */
  patch: VerificationCheckPatch;
  name: Scalars['String'];
};

/** All input for the `updateVerificationCheckByNodeId` mutation. */
export type UpdateVerificationCheckByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VerificationCheck` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VerificationCheck` being updated. */
  patch: VerificationCheckPatch;
};

/** All input for the `updateVerificationCheck` mutation. */
export type UpdateVerificationCheckInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `VerificationCheck` being updated. */
  patch: VerificationCheckPatch;
  id: Scalars['Int'];
};

/** The output of our update `VerificationCheck` mutation. */
export type UpdateVerificationCheckPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VerificationCheck` that was updated by this mutation. */
  verificationCheck?: Maybe<VerificationCheck>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `VerificationCheck`. May be used by Relay 1. */
  verificationCheckEdge?: Maybe<VerificationChecksEdge>;
};


/** The output of our update `VerificationCheck` mutation. */
export type UpdateVerificationCheckPayloadVerificationCheckEdgeArgs = {
  orderBy?: Maybe<Array<VerificationChecksOrderBy>>;
};

/** All input for the `updateWidgetByNodeId` mutation. */
export type UpdateWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Widget` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Widget` being updated. */
  patch: WidgetPatch;
};

/** All input for the `updateWidgetByVariant` mutation. */
export type UpdateWidgetByVariantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Widget` being updated. */
  patch: WidgetPatch;
  variant: Scalars['String'];
};

/** All input for the `updateWidget` mutation. */
export type UpdateWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Widget` being updated. */
  patch: WidgetPatch;
  id: Scalars['String'];
};

/** The output of our update `Widget` mutation. */
export type UpdateWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Widget` that was updated by this mutation. */
  widget?: Maybe<Widget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Widget`. May be used by Relay 1. */
  widgetEdge?: Maybe<WidgetsEdge>;
};


/** The output of our update `Widget` mutation. */
export type UpdateWidgetPayloadWidgetEdgeArgs = {
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
};

/** All input for the `updateWidgetSettingByNodeId` mutation. */
export type UpdateWidgetSettingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `WidgetSetting` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `WidgetSetting` being updated. */
  patch: WidgetSettingPatch;
};

/** All input for the `updateWidgetSetting` mutation. */
export type UpdateWidgetSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `WidgetSetting` being updated. */
  patch: WidgetSettingPatch;
  id: Scalars['Int'];
};

/** The output of our update `WidgetSetting` mutation. */
export type UpdateWidgetSettingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WidgetSetting` that was updated by this mutation. */
  widgetSetting?: Maybe<WidgetSetting>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Widget` that is related to this `WidgetSetting`. */
  widget?: Maybe<Widget>;
  /** Reads a single `FieldKey` that is related to this `WidgetSetting`. */
  fieldKeyByFieldKey?: Maybe<FieldKey>;
  /** An edge for our `WidgetSetting`. May be used by Relay 1. */
  widgetSettingEdge?: Maybe<WidgetSettingsEdge>;
};


/** The output of our update `WidgetSetting` mutation. */
export type UpdateWidgetSettingPayloadWidgetSettingEdgeArgs = {
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
};

export type UploadAutocorpIdImageInput = {
  leadId: Scalars['UUID'];
  image: IdImageInput;
  imageType: IdImageType;
};

export type UploadIdVerificationImagesResponse = {
  success?: Maybe<Scalars['Boolean']>;
  issueDetails?: Maybe<Scalars['JSON']>;
  issues?: Maybe<Array<Maybe<Scalars['String']>>>;
  nextStep?: Maybe<Scalars['String']>;
};

export type User = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  username: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  status: Scalars['String'];
  lastLogin?: Maybe<Scalars['Datetime']>;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  pictureId?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['Datetime']>;
  selectedCompanyId?: Maybe<Scalars['Int']>;
  intercomId?: Maybe<Scalars['String']>;
  /** Reads a single `Media` that is related to this `User`. */
  picture?: Maybe<Media>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByAccountManager: CompaniesConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesBySupportAgent: CompaniesConnection;
  /** Reads and enables pagination through a set of `UserActivity`. */
  userActivities: UserActivitiesConnection;
  /** Reads and enables pagination through a set of `LeadRole`. */
  leadRoles: LeadRolesConnection;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsers: CompanyUsersConnection;
  /** Reads and enables pagination through a set of `Media`. */
  mediaByUploader: MediaConnection;
  /** Reads and enables pagination through a set of `CompanyChannelMedia`. */
  companyChannelMediasByAssigneeId: CompanyChannelMediaConnection;
  /** Reads and enables pagination through a set of `AuditLog`. */
  auditLogs: AuditLogsConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByCompanyAccountManagerAndSupportAgent: UserUsersByCompanyAccountManagerAndSupportAgentManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByCompanySupportAgentAndAccountManager: UserUsersByCompanySupportAgentAndAccountManagerManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByLeadRoleUserIdAndLeadId: UserLeadsByLeadRoleUserIdAndLeadIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyUserUserIdAndCompanyId: UserCompaniesByCompanyUserUserIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCompanyUserUserIdAndGroupId: UserGroupsByCompanyUserUserIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByMediaUploaderAndCompanyId: UserCompaniesByMediaUploaderAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByCompanyChannelMediaAssigneeIdAndCompanyChannelId: UserCompanyChannelsByCompanyChannelMediaAssigneeIdAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Media`. */
  mediaByCompanyChannelMediaAssigneeIdAndMediaId: UserMediaByCompanyChannelMediaAssigneeIdAndMediaIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByAuditLogUserIdAndLeadId: UserLeadsByAuditLogUserIdAndLeadIdManyToManyConnection;
  intercomHash?: Maybe<Scalars['String']>;
};


export type UserCompaniesByAccountManagerArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type UserCompaniesBySupportAgentArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type UserUserActivitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserActivitiesOrderBy>>;
  condition?: Maybe<UserActivityCondition>;
  filter?: Maybe<UserActivityFilter>;
};


export type UserLeadRolesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadRolesOrderBy>>;
  condition?: Maybe<LeadRoleCondition>;
  filter?: Maybe<LeadRoleFilter>;
};


export type UserCompanyUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyUsersOrderBy>>;
  condition?: Maybe<CompanyUserCondition>;
  filter?: Maybe<CompanyUserFilter>;
};


export type UserMediaByUploaderArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaOrderBy>>;
  condition?: Maybe<MediaCondition>;
  filter?: Maybe<MediaFilter>;
};


export type UserCompanyChannelMediasByAssigneeIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelMediaOrderBy>>;
  condition?: Maybe<CompanyChannelMediaCondition>;
  filter?: Maybe<CompanyChannelMediaFilter>;
};


export type UserAuditLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuditLogsOrderBy>>;
  condition?: Maybe<AuditLogCondition>;
  filter?: Maybe<AuditLogFilter>;
};


export type UserUsersByCompanyAccountManagerAndSupportAgentArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByCompanySupportAgentAndAccountManagerArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserLeadsByLeadRoleUserIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type UserCompaniesByCompanyUserUserIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type UserGroupsByCompanyUserUserIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


export type UserCompaniesByMediaUploaderAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type UserCompanyChannelsByCompanyChannelMediaAssigneeIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type UserMediaByCompanyChannelMediaAssigneeIdAndMediaIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaOrderBy>>;
  condition?: Maybe<MediaCondition>;
  filter?: Maybe<MediaFilter>;
};


export type UserLeadsByAuditLogUserIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

/** A connection to a list of `UserActivity` values. */
export type UserActivitiesConnection = {
  /** A list of `UserActivity` objects. */
  nodes: Array<UserActivity>;
  /** A list of edges which contains the `UserActivity` and cursor to aid in pagination. */
  edges: Array<UserActivitiesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserActivity` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserActivityAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserActivityAggregates>>;
};


/** A connection to a list of `UserActivity` values. */
export type UserActivitiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserActivityGroupBy>;
  having?: Maybe<UserActivityHavingInput>;
};

/** A `UserActivity` edge in the connection. */
export type UserActivitiesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserActivity` at the end of the edge. */
  node: UserActivity;
};

/** Methods to use when ordering `UserActivity`. */
export enum UserActivitiesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  ClaimsAsc = 'CLAIMS_ASC',
  ClaimsDesc = 'CLAIMS_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  SearchTextAsc = 'SEARCH_TEXT_ASC',
  SearchTextDesc = 'SEARCH_TEXT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SearchTextRankAsc = 'SEARCH_TEXT_RANK_ASC',
  SearchTextRankDesc = 'SEARCH_TEXT_RANK_DESC'
}

export type UserActivity = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  username: Scalars['String'];
  claims: Array<Maybe<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  searchText?: Maybe<Scalars['FullText']>;
  userId?: Maybe<Scalars['Int']>;
  /** Reads a single `User` that is related to this `UserActivity`. */
  user?: Maybe<User>;
  /** Full-text search ranking when filtered by `searchText`. */
  searchTextRank?: Maybe<Scalars['Float']>;
};

export type UserActivityAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<UserActivitySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserActivityDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<UserActivityMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<UserActivityMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<UserActivityAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<UserActivityStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<UserActivityStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<UserActivityVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<UserActivityVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `UserActivity` object types. */
export type UserActivityAggregatesFilter = {
  /** A filter that must pass for the relevant `UserActivity` object to be included within the aggregate. */
  filter?: Maybe<UserActivityFilter>;
  /** Sum aggregate over matching `UserActivity` objects. */
  sum?: Maybe<UserActivitySumAggregateFilter>;
  /** Distinct count aggregate over matching `UserActivity` objects. */
  distinctCount?: Maybe<UserActivityDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `UserActivity` objects. */
  min?: Maybe<UserActivityMinAggregateFilter>;
  /** Maximum aggregate over matching `UserActivity` objects. */
  max?: Maybe<UserActivityMaxAggregateFilter>;
  /** Mean average aggregate over matching `UserActivity` objects. */
  average?: Maybe<UserActivityAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `UserActivity` objects. */
  stddevSample?: Maybe<UserActivityStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `UserActivity` objects. */
  stddevPopulation?: Maybe<UserActivityStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `UserActivity` objects. */
  varianceSample?: Maybe<UserActivityVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `UserActivity` objects. */
  variancePopulation?: Maybe<UserActivityVariancePopulationAggregateFilter>;
};

export type UserActivityAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type UserActivityAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `UserActivity` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserActivityCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `username` field. */
  username?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `claims` field. */
  claims?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `searchText` field. */
  searchText?: Maybe<Scalars['FullText']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['Int']>;
};

export type UserActivityDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  username?: Maybe<BigIntFilter>;
  claims?: Maybe<BigIntFilter>;
  meta?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  searchText?: Maybe<BigIntFilter>;
  userId?: Maybe<BigIntFilter>;
};

export type UserActivityDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of username across the matching connection */
  username?: Maybe<Scalars['BigInt']>;
  /** Distinct count of claims across the matching connection */
  claims?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meta across the matching connection */
  meta?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of searchText across the matching connection */
  searchText?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UserActivity` object types. All fields are combined with a logical ‘and.’ */
export type UserActivityFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `username` field. */
  username?: Maybe<StringFilter>;
  /** Filter by the object’s `claims` field. */
  claims?: Maybe<StringListFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: Maybe<JsonFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `searchText` field. */
  searchText?: Maybe<FullTextFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<IntFilter>;
  /** Filter by the object’s `user` relation. */
  user?: Maybe<UserFilter>;
  /** A related `user` exists. */
  userExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserActivityFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserActivityFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserActivityFilter>;
};

/** Grouping methods for `UserActivity` for usage during aggregation. */
export enum UserActivityGroupBy {
  Username = 'USERNAME',
  Claims = 'CLAIMS',
  Meta = 'META',
  Type = 'TYPE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  SearchText = 'SEARCH_TEXT',
  UserId = 'USER_ID'
}

export type UserActivityHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  userId?: Maybe<HavingIntFilter>;
};

export type UserActivityHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  userId?: Maybe<HavingIntFilter>;
};

/** Conditions for `UserActivity` aggregates. */
export type UserActivityHavingInput = {
  AND?: Maybe<Array<UserActivityHavingInput>>;
  OR?: Maybe<Array<UserActivityHavingInput>>;
  sum?: Maybe<UserActivityHavingSumInput>;
  distinctCount?: Maybe<UserActivityHavingDistinctCountInput>;
  min?: Maybe<UserActivityHavingMinInput>;
  max?: Maybe<UserActivityHavingMaxInput>;
  average?: Maybe<UserActivityHavingAverageInput>;
  stddevSample?: Maybe<UserActivityHavingStddevSampleInput>;
  stddevPopulation?: Maybe<UserActivityHavingStddevPopulationInput>;
  varianceSample?: Maybe<UserActivityHavingVarianceSampleInput>;
  variancePopulation?: Maybe<UserActivityHavingVariancePopulationInput>;
};

export type UserActivityHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  userId?: Maybe<HavingIntFilter>;
};

export type UserActivityHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  userId?: Maybe<HavingIntFilter>;
};

export type UserActivityHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  userId?: Maybe<HavingIntFilter>;
};

export type UserActivityHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  userId?: Maybe<HavingIntFilter>;
};

export type UserActivityHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  userId?: Maybe<HavingIntFilter>;
};

export type UserActivityHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  userId?: Maybe<HavingIntFilter>;
};

export type UserActivityHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  userId?: Maybe<HavingIntFilter>;
};

/** An input for mutations affecting `UserActivity` */
export type UserActivityInput = {
  id?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
  claims: Array<Maybe<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  userId?: Maybe<Scalars['Int']>;
  userToUserId?: Maybe<FkUserActivityUserIdInput>;
};

export type UserActivityMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  userId?: Maybe<IntFilter>;
};

export type UserActivityMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
};

export type UserActivityMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  userId?: Maybe<IntFilter>;
};

export type UserActivityMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserActivityNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `userActivity` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserActivityNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `userActivity` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserActivityOnUserActivityForFkUserActivityUserIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `userActivity` to look up the row to update. */
export type UserActivityOnUserActivityForFkUserActivityUserIdUsingUserActivityPkeyUpdate = {
  /** An object where the defined keys will be set on the `userActivity` being updated. */
  patch: UpdateUserActivityOnUserActivityForFkUserActivityUserIdPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `UserActivity`. Fields that are set will be updated. */
export type UserActivityPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  claims?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  userId?: Maybe<Scalars['Int']>;
  userToUserId?: Maybe<FkUserActivityUserIdInput>;
};

export type UserActivityStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type UserActivityStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type UserActivityStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type UserActivityStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type UserActivitySumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  userId?: Maybe<BigIntFilter>;
};

export type UserActivitySumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt'];
};

/** The fields on `userActivity` to look up the row to connect. */
export type UserActivityUserActivityPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `userActivity` to look up the row to delete. */
export type UserActivityUserActivityPkeyDelete = {
  id: Scalars['Int'];
};

export type UserActivityVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type UserActivityVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type UserActivityVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  userId?: Maybe<BigFloatFilter>;
};

export type UserActivityVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
};

export type UserAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<UserSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<UserMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<UserMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<UserAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<UserStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<UserStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<UserVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<UserVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `User` object types. */
export type UserAggregatesFilter = {
  /** A filter that must pass for the relevant `User` object to be included within the aggregate. */
  filter?: Maybe<UserFilter>;
  /** Sum aggregate over matching `User` objects. */
  sum?: Maybe<UserSumAggregateFilter>;
  /** Distinct count aggregate over matching `User` objects. */
  distinctCount?: Maybe<UserDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `User` objects. */
  min?: Maybe<UserMinAggregateFilter>;
  /** Maximum aggregate over matching `User` objects. */
  max?: Maybe<UserMaxAggregateFilter>;
  /** Mean average aggregate over matching `User` objects. */
  average?: Maybe<UserAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `User` objects. */
  stddevSample?: Maybe<UserStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `User` objects. */
  stddevPopulation?: Maybe<UserStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `User` objects. */
  varianceSample?: Maybe<UserVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `User` objects. */
  variancePopulation?: Maybe<UserVariancePopulationAggregateFilter>;
};

export type UserAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  pictureId?: Maybe<BigFloatFilter>;
  selectedCompanyId?: Maybe<BigFloatFilter>;
};

export type UserAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of pictureId across the matching connection */
  pictureId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of selectedCompanyId across the matching connection */
  selectedCompanyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Company` values, with data from `CompanyUser`. */
export type UserCompaniesByCompanyUserUserIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyUser`, and the cursor to aid in pagination. */
  edges: Array<UserCompaniesByCompanyUserUserIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyUser`. */
export type UserCompaniesByCompanyUserUserIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyUser`. */
export type UserCompaniesByCompanyUserUserIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsers: CompanyUsersConnection;
};


/** A `Company` edge in the connection, with data from `CompanyUser`. */
export type UserCompaniesByCompanyUserUserIdAndCompanyIdManyToManyEdgeCompanyUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyUsersOrderBy>>;
  condition?: Maybe<CompanyUserCondition>;
  filter?: Maybe<CompanyUserFilter>;
};

/** A connection to a list of `Company` values, with data from `Media`. */
export type UserCompaniesByMediaUploaderAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `Media`, and the cursor to aid in pagination. */
  edges: Array<UserCompaniesByMediaUploaderAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `Media`. */
export type UserCompaniesByMediaUploaderAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `Media`. */
export type UserCompaniesByMediaUploaderAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `Media`. */
  medias: MediaConnection;
};


/** A `Company` edge in the connection, with data from `Media`. */
export type UserCompaniesByMediaUploaderAndCompanyIdManyToManyEdgeMediasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaOrderBy>>;
  condition?: Maybe<MediaCondition>;
  filter?: Maybe<MediaFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `CompanyChannelMedia`. */
export type UserCompanyChannelsByCompanyChannelMediaAssigneeIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `CompanyChannelMedia`, and the cursor to aid in pagination. */
  edges: Array<UserCompanyChannelsByCompanyChannelMediaAssigneeIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `CompanyChannelMedia`. */
export type UserCompanyChannelsByCompanyChannelMediaAssigneeIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `CompanyChannelMedia`. */
export type UserCompanyChannelsByCompanyChannelMediaAssigneeIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `CompanyChannelMedia`. */
  companyChannelMedias: CompanyChannelMediaConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `CompanyChannelMedia`. */
export type UserCompanyChannelsByCompanyChannelMediaAssigneeIdAndCompanyChannelIdManyToManyEdgeCompanyChannelMediasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelMediaOrderBy>>;
  condition?: Maybe<CompanyChannelMediaCondition>;
  filter?: Maybe<CompanyChannelMediaFilter>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `username` field. */
  username?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email` field. */
  email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `position` field. */
  position?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `role` field. */
  role?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastLogin` field. */
  lastLogin?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `pictureId` field. */
  pictureId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `confirmed` field. */
  confirmed?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `selectedCompanyId` field. */
  selectedCompanyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `intercomId` field. */
  intercomId?: Maybe<Scalars['String']>;
};

export type UserDetails = {
  nodeId: Scalars['String'];
  groupId?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
  email: Scalars['String'];
  role: UserRole;
  status: UserStatus;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['String']>;
};

export type UserDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  username?: Maybe<BigIntFilter>;
  email?: Maybe<BigIntFilter>;
  phoneNumber?: Maybe<BigIntFilter>;
  firstName?: Maybe<BigIntFilter>;
  lastName?: Maybe<BigIntFilter>;
  title?: Maybe<BigIntFilter>;
  position?: Maybe<BigIntFilter>;
  role?: Maybe<BigIntFilter>;
  status?: Maybe<BigIntFilter>;
  lastLogin?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  pictureId?: Maybe<BigIntFilter>;
  confirmed?: Maybe<BigIntFilter>;
  selectedCompanyId?: Maybe<BigIntFilter>;
  intercomId?: Maybe<BigIntFilter>;
};

export type UserDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of username across the matching connection */
  username?: Maybe<Scalars['BigInt']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']>;
  /** Distinct count of phoneNumber across the matching connection */
  phoneNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars['BigInt']>;
  /** Distinct count of position across the matching connection */
  position?: Maybe<Scalars['BigInt']>;
  /** Distinct count of role across the matching connection */
  role?: Maybe<Scalars['BigInt']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastLogin across the matching connection */
  lastLogin?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of pictureId across the matching connection */
  pictureId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of confirmed across the matching connection */
  confirmed?: Maybe<Scalars['BigInt']>;
  /** Distinct count of selectedCompanyId across the matching connection */
  selectedCompanyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of intercomId across the matching connection */
  intercomId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `username` field. */
  username?: Maybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: Maybe<StringFilter>;
  /** Filter by the object’s `phoneNumber` field. */
  phoneNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `position` field. */
  position?: Maybe<StringFilter>;
  /** Filter by the object’s `role` field. */
  role?: Maybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<StringFilter>;
  /** Filter by the object’s `lastLogin` field. */
  lastLogin?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `pictureId` field. */
  pictureId?: Maybe<IntFilter>;
  /** Filter by the object’s `confirmed` field. */
  confirmed?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `selectedCompanyId` field. */
  selectedCompanyId?: Maybe<IntFilter>;
  /** Filter by the object’s `intercomId` field. */
  intercomId?: Maybe<StringFilter>;
  /** Filter by the object’s `companiesByAccountManager` relation. */
  companiesByAccountManager?: Maybe<UserToManyCompanyFilter>;
  /** Some related `companiesByAccountManager` exist. */
  companiesByAccountManagerExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companiesBySupportAgent` relation. */
  companiesBySupportAgent?: Maybe<UserToManyCompanyFilter>;
  /** Some related `companiesBySupportAgent` exist. */
  companiesBySupportAgentExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userActivities` relation. */
  userActivities?: Maybe<UserToManyUserActivityFilter>;
  /** Some related `userActivities` exist. */
  userActivitiesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `leadRoles` relation. */
  leadRoles?: Maybe<UserToManyLeadRoleFilter>;
  /** Some related `leadRoles` exist. */
  leadRolesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyUsers` relation. */
  companyUsers?: Maybe<UserToManyCompanyUserFilter>;
  /** Some related `companyUsers` exist. */
  companyUsersExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `mediaByUploader` relation. */
  mediaByUploader?: Maybe<UserToManyMediaFilter>;
  /** Some related `mediaByUploader` exist. */
  mediaByUploaderExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyChannelMediasByAssigneeId` relation. */
  companyChannelMediasByAssigneeId?: Maybe<UserToManyCompanyChannelMediaFilter>;
  /** Some related `companyChannelMediasByAssigneeId` exist. */
  companyChannelMediasByAssigneeIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `auditLogs` relation. */
  auditLogs?: Maybe<UserToManyAuditLogFilter>;
  /** Some related `auditLogs` exist. */
  auditLogsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `picture` relation. */
  picture?: Maybe<MediaFilter>;
  /** A related `picture` exists. */
  pictureExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserFilter>;
};

/** Grouping methods for `User` for usage during aggregation. */
export enum UserGroupBy {
  Username = 'USERNAME',
  PhoneNumber = 'PHONE_NUMBER',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Title = 'TITLE',
  Position = 'POSITION',
  Role = 'ROLE',
  Status = 'STATUS',
  LastLogin = 'LAST_LOGIN',
  LastLoginTruncatedToHour = 'LAST_LOGIN_TRUNCATED_TO_HOUR',
  LastLoginTruncatedToDay = 'LAST_LOGIN_TRUNCATED_TO_DAY',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  PictureId = 'PICTURE_ID',
  Confirmed = 'CONFIRMED',
  ConfirmedTruncatedToHour = 'CONFIRMED_TRUNCATED_TO_HOUR',
  ConfirmedTruncatedToDay = 'CONFIRMED_TRUNCATED_TO_DAY',
  SelectedCompanyId = 'SELECTED_COMPANY_ID',
  IntercomId = 'INTERCOM_ID'
}

/** A connection to a list of `Group` values, with data from `CompanyUser`. */
export type UserGroupsByCompanyUserUserIdAndGroupIdManyToManyConnection = {
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `CompanyUser`, and the cursor to aid in pagination. */
  edges: Array<UserGroupsByCompanyUserUserIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GroupAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GroupAggregates>>;
};


/** A connection to a list of `Group` values, with data from `CompanyUser`. */
export type UserGroupsByCompanyUserUserIdAndGroupIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<GroupGroupBy>;
  having?: Maybe<GroupHavingInput>;
};

/** A `Group` edge in the connection, with data from `CompanyUser`. */
export type UserGroupsByCompanyUserUserIdAndGroupIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsers: CompanyUsersConnection;
};


/** A `Group` edge in the connection, with data from `CompanyUser`. */
export type UserGroupsByCompanyUserUserIdAndGroupIdManyToManyEdgeCompanyUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyUsersOrderBy>>;
  condition?: Maybe<CompanyUserCondition>;
  filter?: Maybe<CompanyUserFilter>;
};

export type UserHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  lastLogin?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  pictureId?: Maybe<HavingIntFilter>;
  confirmed?: Maybe<HavingDatetimeFilter>;
  selectedCompanyId?: Maybe<HavingIntFilter>;
};

export type UserHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  lastLogin?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  pictureId?: Maybe<HavingIntFilter>;
  confirmed?: Maybe<HavingDatetimeFilter>;
  selectedCompanyId?: Maybe<HavingIntFilter>;
};

/** Conditions for `User` aggregates. */
export type UserHavingInput = {
  AND?: Maybe<Array<UserHavingInput>>;
  OR?: Maybe<Array<UserHavingInput>>;
  sum?: Maybe<UserHavingSumInput>;
  distinctCount?: Maybe<UserHavingDistinctCountInput>;
  min?: Maybe<UserHavingMinInput>;
  max?: Maybe<UserHavingMaxInput>;
  average?: Maybe<UserHavingAverageInput>;
  stddevSample?: Maybe<UserHavingStddevSampleInput>;
  stddevPopulation?: Maybe<UserHavingStddevPopulationInput>;
  varianceSample?: Maybe<UserHavingVarianceSampleInput>;
  variancePopulation?: Maybe<UserHavingVariancePopulationInput>;
};

export type UserHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  lastLogin?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  pictureId?: Maybe<HavingIntFilter>;
  confirmed?: Maybe<HavingDatetimeFilter>;
  selectedCompanyId?: Maybe<HavingIntFilter>;
};

export type UserHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  lastLogin?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  pictureId?: Maybe<HavingIntFilter>;
  confirmed?: Maybe<HavingDatetimeFilter>;
  selectedCompanyId?: Maybe<HavingIntFilter>;
};

export type UserHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lastLogin?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  pictureId?: Maybe<HavingIntFilter>;
  confirmed?: Maybe<HavingDatetimeFilter>;
  selectedCompanyId?: Maybe<HavingIntFilter>;
};

export type UserHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lastLogin?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  pictureId?: Maybe<HavingIntFilter>;
  confirmed?: Maybe<HavingDatetimeFilter>;
  selectedCompanyId?: Maybe<HavingIntFilter>;
};

export type UserHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  lastLogin?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  pictureId?: Maybe<HavingIntFilter>;
  confirmed?: Maybe<HavingDatetimeFilter>;
  selectedCompanyId?: Maybe<HavingIntFilter>;
};

export type UserHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lastLogin?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  pictureId?: Maybe<HavingIntFilter>;
  confirmed?: Maybe<HavingDatetimeFilter>;
  selectedCompanyId?: Maybe<HavingIntFilter>;
};

export type UserHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lastLogin?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  pictureId?: Maybe<HavingIntFilter>;
  confirmed?: Maybe<HavingDatetimeFilter>;
  selectedCompanyId?: Maybe<HavingIntFilter>;
};

/** A connection to a list of `Lead` values, with data from `AuditLog`. */
export type UserLeadsByAuditLogUserIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `AuditLog`, and the cursor to aid in pagination. */
  edges: Array<UserLeadsByAuditLogUserIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `AuditLog`. */
export type UserLeadsByAuditLogUserIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `AuditLog`. */
export type UserLeadsByAuditLogUserIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `AuditLog`. */
  auditLogs: AuditLogsConnection;
};


/** A `Lead` edge in the connection, with data from `AuditLog`. */
export type UserLeadsByAuditLogUserIdAndLeadIdManyToManyEdgeAuditLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuditLogsOrderBy>>;
  condition?: Maybe<AuditLogCondition>;
  filter?: Maybe<AuditLogFilter>;
};

/** A connection to a list of `Lead` values, with data from `LeadRole`. */
export type UserLeadsByLeadRoleUserIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `LeadRole`, and the cursor to aid in pagination. */
  edges: Array<UserLeadsByLeadRoleUserIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `LeadRole`. */
export type UserLeadsByLeadRoleUserIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `LeadRole`. */
export type UserLeadsByLeadRoleUserIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `LeadRole`. */
  leadRoles: LeadRolesConnection;
};


/** A `Lead` edge in the connection, with data from `LeadRole`. */
export type UserLeadsByLeadRoleUserIdAndLeadIdManyToManyEdgeLeadRolesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadRolesOrderBy>>;
  condition?: Maybe<LeadRoleCondition>;
  filter?: Maybe<LeadRoleFilter>;
};

export type UserMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  pictureId?: Maybe<IntFilter>;
  selectedCompanyId?: Maybe<IntFilter>;
};

export type UserMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of pictureId across the matching connection */
  pictureId?: Maybe<Scalars['Int']>;
  /** Maximum of selectedCompanyId across the matching connection */
  selectedCompanyId?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `Media` values, with data from `CompanyChannelMedia`. */
export type UserMediaByCompanyChannelMediaAssigneeIdAndMediaIdManyToManyConnection = {
  /** A list of `Media` objects. */
  nodes: Array<Media>;
  /** A list of edges which contains the `Media`, info from the `CompanyChannelMedia`, and the cursor to aid in pagination. */
  edges: Array<UserMediaByCompanyChannelMediaAssigneeIdAndMediaIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Media` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MediaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MediaAggregates>>;
};


/** A connection to a list of `Media` values, with data from `CompanyChannelMedia`. */
export type UserMediaByCompanyChannelMediaAssigneeIdAndMediaIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<MediaGroupBy>;
  having?: Maybe<MediaHavingInput>;
};

/** A `Media` edge in the connection, with data from `CompanyChannelMedia`. */
export type UserMediaByCompanyChannelMediaAssigneeIdAndMediaIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Media` at the end of the edge. */
  node: Media;
  /** Reads and enables pagination through a set of `CompanyChannelMedia`. */
  companyChannelMedias: CompanyChannelMediaConnection;
};


/** A `Media` edge in the connection, with data from `CompanyChannelMedia`. */
export type UserMediaByCompanyChannelMediaAssigneeIdAndMediaIdManyToManyEdgeCompanyChannelMediasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelMediaOrderBy>>;
  condition?: Maybe<CompanyChannelMediaCondition>;
  filter?: Maybe<CompanyChannelMediaFilter>;
};

export type UserMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  pictureId?: Maybe<IntFilter>;
  selectedCompanyId?: Maybe<IntFilter>;
};

export type UserMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of pictureId across the matching connection */
  pictureId?: Maybe<Scalars['Int']>;
  /** Minimum of selectedCompanyId across the matching connection */
  selectedCompanyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
};

export type UserNotificationInput = {
  emailAddress: Scalars['String'];
  companyId: Scalars['Int'];
  type: UserNotificationType;
};

export type UserNotificationResponse = {
  success: Scalars['Boolean'];
};

export enum UserNotificationType {
  OfferAccepted = 'OfferAccepted'
}

/** The globally unique `ID` look up for the row to update. */
export type UserOnAuditLogForAuditLogUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `auditLog` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `auditLog` being updated. */
  patch: AuditLogPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnAuditLogForAuditLogUserIdFkeyUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnAuditLogForAuditLogUserIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannelMedia` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
  patch: CompanyChannelMediaPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnCompanyForCompanyAccountManagerFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnCompanyForCompanyAccountManagerFkeyUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnCompanyForCompanyAccountManagerFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnCompanyForCompanySupportAgentFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnCompanyForCompanySupportAgentFkeyUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnCompanyForCompanySupportAgentFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnCompanyUserForCompanyUserUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyUser` being updated. */
  patch: CompanyUserPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnCompanyUserForCompanyUserUserIdFkeyUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnCompanyUserForCompanyUserUserIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnLeadRoleForLeadRoleUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `leadRole` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `leadRole` being updated. */
  patch: LeadRolePatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnLeadRoleForLeadRoleUserIdFkeyUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnLeadRoleForLeadRoleUserIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnMediaForMediaUploaderFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `media` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `media` being updated. */
  patch: MediaPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnMediaForMediaUploaderFkeyUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnMediaForMediaUploaderFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnUserActivityForFkUserActivityUserIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `userActivity` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `userActivity` being updated. */
  patch: UserActivityPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnUserActivityForFkUserActivityUserIdUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnUserActivityForFkUserActivityUserIdPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnUserForUserPictureIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `media` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `media` being updated. */
  patch: MediaPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnUserForUserPictureIdFkeyUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnUserForUserPictureIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pictureId?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['Datetime']>;
  selectedCompanyId?: Maybe<Scalars['Int']>;
  intercomId?: Maybe<Scalars['String']>;
  mediaToPictureId?: Maybe<UserPictureIdFkeyInput>;
  companiesToAccountManagerUsingId?: Maybe<CompanyAccountManagerFkeyInverseInput>;
  companiesToSupportAgentUsingId?: Maybe<CompanySupportAgentFkeyInverseInput>;
  userActivitiesUsingId?: Maybe<FkUserActivityUserIdInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleUserIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserUserIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaUploaderFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogUserIdFkeyInverseInput>;
};

export type UserPermissions = {
  autocorp: AutocorpPermissions;
  companies: Array<CompanyPermissions>;
};

/** Input for the nested mutation of `media` in the `UserInput` mutation. */
export type UserPictureIdFkeyInput = {
  /** The primary key(s) for `media` for the far side of the relationship. */
  connectById?: Maybe<MediaMediaPkeyConnect>;
  /** The primary key(s) for `media` for the far side of the relationship. */
  connectByNodeId?: Maybe<MediaNodeIdConnect>;
  /** The primary key(s) and patch data for `media` for the far side of the relationship. */
  updateById?: Maybe<MediaOnUserForUserPictureIdFkeyUsingMediaPkeyUpdate>;
  /** The primary key(s) and patch data for `media` for the far side of the relationship. */
  updateByNodeId?: Maybe<UserOnUserForUserPictureIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `user` in the `MediaInput` mutation. */
export type UserPictureIdFkeyInverseInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<Array<UserUserPkeyConnect>>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<UserNodeIdConnect>>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<Array<UserOnUserForUserPictureIdFkeyUsingUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<MediaOnUserForUserPictureIdFkeyNodeIdUpdate>>;
};

export type UserResponse = {
  success?: Maybe<Scalars['Boolean']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export enum UserStatus {
  Disabled = 'DISABLED',
  Pending = 'PENDING',
  Ready = 'READY'
}

export type UserStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  pictureId?: Maybe<BigFloatFilter>;
  selectedCompanyId?: Maybe<BigFloatFilter>;
};

export type UserStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of pictureId across the matching connection */
  pictureId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of selectedCompanyId across the matching connection */
  selectedCompanyId?: Maybe<Scalars['BigFloat']>;
};

export type UserStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  pictureId?: Maybe<BigFloatFilter>;
  selectedCompanyId?: Maybe<BigFloatFilter>;
};

export type UserStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of pictureId across the matching connection */
  pictureId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of selectedCompanyId across the matching connection */
  selectedCompanyId?: Maybe<Scalars['BigFloat']>;
};

export type UserSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  pictureId?: Maybe<BigIntFilter>;
  selectedCompanyId?: Maybe<BigIntFilter>;
};

export type UserSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of pictureId across the matching connection */
  pictureId: Scalars['BigInt'];
  /** Sum of selectedCompanyId across the matching connection */
  selectedCompanyId: Scalars['BigInt'];
};

/** A filter to be used against many `AuditLog` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyAuditLogFilter = {
  /** Every related `AuditLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AuditLogFilter>;
  /** Some related `AuditLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AuditLogFilter>;
  /** No related `AuditLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AuditLogFilter>;
  /** Aggregates across related `AuditLog` match the filter criteria. */
  aggregates?: Maybe<AuditLogAggregatesFilter>;
};

/** A filter to be used against many `CompanyChannelMedia` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyCompanyChannelMediaFilter = {
  /** Every related `CompanyChannelMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyChannelMediaFilter>;
  /** Some related `CompanyChannelMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyChannelMediaFilter>;
  /** No related `CompanyChannelMedia` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyChannelMediaFilter>;
  /** Aggregates across related `CompanyChannelMedia` match the filter criteria. */
  aggregates?: Maybe<CompanyChannelMediaAggregatesFilter>;
};

/** A filter to be used against many `Company` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyCompanyFilter = {
  /** Every related `Company` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyFilter>;
  /** Some related `Company` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyFilter>;
  /** No related `Company` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyFilter>;
  /** Aggregates across related `Company` match the filter criteria. */
  aggregates?: Maybe<CompanyAggregatesFilter>;
};

/** A filter to be used against many `CompanyUser` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyCompanyUserFilter = {
  /** Every related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyUserFilter>;
  /** Some related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyUserFilter>;
  /** No related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyUserFilter>;
  /** Aggregates across related `CompanyUser` match the filter criteria. */
  aggregates?: Maybe<CompanyUserAggregatesFilter>;
};

/** A filter to be used against many `LeadRole` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyLeadRoleFilter = {
  /** Every related `LeadRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeadRoleFilter>;
  /** Some related `LeadRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeadRoleFilter>;
  /** No related `LeadRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeadRoleFilter>;
  /** Aggregates across related `LeadRole` match the filter criteria. */
  aggregates?: Maybe<LeadRoleAggregatesFilter>;
};

/** A filter to be used against many `Media` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyMediaFilter = {
  /** Every related `Media` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MediaFilter>;
  /** Some related `Media` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MediaFilter>;
  /** No related `Media` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MediaFilter>;
  /** Aggregates across related `Media` match the filter criteria. */
  aggregates?: Maybe<MediaAggregatesFilter>;
};

/** A filter to be used against many `UserActivity` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyUserActivityFilter = {
  /** Every related `UserActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<UserActivityFilter>;
  /** Some related `UserActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<UserActivityFilter>;
  /** No related `UserActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<UserActivityFilter>;
  /** Aggregates across related `UserActivity` match the filter criteria. */
  aggregates?: Maybe<UserActivityAggregatesFilter>;
};

/** The fields on `user` to look up the row to connect. */
export type UserUserPkeyConnect = {
  id: Scalars['Int'];
};

/** A connection to a list of `User` values, with data from `Company`. */
export type UserUsersByCompanyAccountManagerAndSupportAgentManyToManyConnection = {
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Company`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByCompanyAccountManagerAndSupportAgentManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
};


/** A connection to a list of `User` values, with data from `Company`. */
export type UserUsersByCompanyAccountManagerAndSupportAgentManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having?: Maybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Company`. */
export type UserUsersByCompanyAccountManagerAndSupportAgentManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Company`. */
  companiesBySupportAgent: CompaniesConnection;
};


/** A `User` edge in the connection, with data from `Company`. */
export type UserUsersByCompanyAccountManagerAndSupportAgentManyToManyEdgeCompaniesBySupportAgentArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};

/** A connection to a list of `User` values, with data from `Company`. */
export type UserUsersByCompanySupportAgentAndAccountManagerManyToManyConnection = {
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Company`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByCompanySupportAgentAndAccountManagerManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
};


/** A connection to a list of `User` values, with data from `Company`. */
export type UserUsersByCompanySupportAgentAndAccountManagerManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having?: Maybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Company`. */
export type UserUsersByCompanySupportAgentAndAccountManagerManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByAccountManager: CompaniesConnection;
};


/** A `User` edge in the connection, with data from `Company`. */
export type UserUsersByCompanySupportAgentAndAccountManagerManyToManyEdgeCompaniesByAccountManagerArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};

export type UserVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  pictureId?: Maybe<BigFloatFilter>;
  selectedCompanyId?: Maybe<BigFloatFilter>;
};

export type UserVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of pictureId across the matching connection */
  pictureId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of selectedCompanyId across the matching connection */
  selectedCompanyId?: Maybe<Scalars['BigFloat']>;
};

export type UserVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  pictureId?: Maybe<BigFloatFilter>;
  selectedCompanyId?: Maybe<BigFloatFilter>;
};

export type UserVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of pictureId across the matching connection */
  pictureId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of selectedCompanyId across the matching connection */
  selectedCompanyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
};


/** A connection to a list of `User` values. */
export type UsersConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having?: Maybe<UserHavingInput>;
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  RoleAsc = 'ROLE_ASC',
  RoleDesc = 'ROLE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  LastLoginAsc = 'LAST_LOGIN_ASC',
  LastLoginDesc = 'LAST_LOGIN_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PictureIdAsc = 'PICTURE_ID_ASC',
  PictureIdDesc = 'PICTURE_ID_DESC',
  ConfirmedAsc = 'CONFIRMED_ASC',
  ConfirmedDesc = 'CONFIRMED_DESC',
  SelectedCompanyIdAsc = 'SELECTED_COMPANY_ID_ASC',
  SelectedCompanyIdDesc = 'SELECTED_COMPANY_ID_DESC',
  IntercomIdAsc = 'INTERCOM_ID_ASC',
  IntercomIdDesc = 'INTERCOM_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompaniesByAccountManagerCountAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_COUNT_ASC',
  CompaniesByAccountManagerCountDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_COUNT_DESC',
  CompaniesByAccountManagerSumIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_ID_ASC',
  CompaniesByAccountManagerSumIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_ID_DESC',
  CompaniesByAccountManagerSumCreatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_CREATED_AT_ASC',
  CompaniesByAccountManagerSumCreatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_CREATED_AT_DESC',
  CompaniesByAccountManagerSumNameAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_NAME_ASC',
  CompaniesByAccountManagerSumNameDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_NAME_DESC',
  CompaniesByAccountManagerSumEquifaxCustomerNumberAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesByAccountManagerSumEquifaxCustomerNumberDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesByAccountManagerSumSecurityCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_SECURITY_CODE_ASC',
  CompaniesByAccountManagerSumSecurityCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_SECURITY_CODE_DESC',
  CompaniesByAccountManagerSumExternalIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_EXTERNAL_ID_ASC',
  CompaniesByAccountManagerSumExternalIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_EXTERNAL_ID_DESC',
  CompaniesByAccountManagerSumUpdatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_UPDATED_AT_ASC',
  CompaniesByAccountManagerSumUpdatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_UPDATED_AT_DESC',
  CompaniesByAccountManagerSumArchivedAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_ARCHIVED_ASC',
  CompaniesByAccountManagerSumArchivedDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_ARCHIVED_DESC',
  CompaniesByAccountManagerSumZohoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_ZOHO_ID_ASC',
  CompaniesByAccountManagerSumZohoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_ZOHO_ID_DESC',
  CompaniesByAccountManagerSumDeliverToAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_DELIVER_TO_ASC',
  CompaniesByAccountManagerSumDeliverToDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_DELIVER_TO_DESC',
  CompaniesByAccountManagerSumPrimaryGroupAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_PRIMARY_GROUP_ASC',
  CompaniesByAccountManagerSumPrimaryGroupDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_PRIMARY_GROUP_DESC',
  CompaniesByAccountManagerSumRawMetaAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_RAW_META_ASC',
  CompaniesByAccountManagerSumRawMetaDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_RAW_META_DESC',
  CompaniesByAccountManagerSumBillableCompanyIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_BILLABLE_COMPANY_ID_ASC',
  CompaniesByAccountManagerSumBillableCompanyIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_BILLABLE_COMPANY_ID_DESC',
  CompaniesByAccountManagerSumRawRequestedAccessAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_RAW_REQUESTED_ACCESS_ASC',
  CompaniesByAccountManagerSumRawRequestedAccessDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_RAW_REQUESTED_ACCESS_DESC',
  CompaniesByAccountManagerSumLenderDealerCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_LENDER_DEALER_CODE_ASC',
  CompaniesByAccountManagerSumLenderDealerCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_LENDER_DEALER_CODE_DESC',
  CompaniesByAccountManagerSumGooglePlacesIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_GOOGLE_PLACES_ID_ASC',
  CompaniesByAccountManagerSumGooglePlacesIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_GOOGLE_PLACES_ID_DESC',
  CompaniesByAccountManagerSumLegalConsentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_LEGAL_CONSENT_ASC',
  CompaniesByAccountManagerSumLegalConsentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_LEGAL_CONSENT_DESC',
  CompaniesByAccountManagerSumLagoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_LAGO_ID_ASC',
  CompaniesByAccountManagerSumLagoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_LAGO_ID_DESC',
  CompaniesByAccountManagerSumStripeCustomerIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_STRIPE_CUSTOMER_ID_ASC',
  CompaniesByAccountManagerSumStripeCustomerIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_STRIPE_CUSTOMER_ID_DESC',
  CompaniesByAccountManagerSumSalesOrderIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_SALES_ORDER_ID_ASC',
  CompaniesByAccountManagerSumSalesOrderIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_SALES_ORDER_ID_DESC',
  CompaniesByAccountManagerSumIndustryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_INDUSTRY_ASC',
  CompaniesByAccountManagerSumIndustryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_INDUSTRY_DESC',
  CompaniesByAccountManagerSumManufacturerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_MANUFACTURER_ASC',
  CompaniesByAccountManagerSumManufacturerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_MANUFACTURER_DESC',
  CompaniesByAccountManagerSumCrmAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_CRM_ASC',
  CompaniesByAccountManagerSumCrmDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_CRM_DESC',
  CompaniesByAccountManagerSumAccountManagerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_ACCOUNT_MANAGER_ASC',
  CompaniesByAccountManagerSumAccountManagerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_ACCOUNT_MANAGER_DESC',
  CompaniesByAccountManagerSumSupportAgentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_SUPPORT_AGENT_ASC',
  CompaniesByAccountManagerSumSupportAgentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_SUPPORT_AGENT_DESC',
  CompaniesByAccountManagerSumOpportunityTypeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_OPPORTUNITY_TYPE_ASC',
  CompaniesByAccountManagerSumOpportunityTypeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_OPPORTUNITY_TYPE_DESC',
  CompaniesByAccountManagerSumNumberOfLocationsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_NUMBER_OF_LOCATIONS_ASC',
  CompaniesByAccountManagerSumNumberOfLocationsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_NUMBER_OF_LOCATIONS_DESC',
  CompaniesByAccountManagerSumWebsiteAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_WEBSITE_ASC',
  CompaniesByAccountManagerSumWebsiteDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_WEBSITE_DESC',
  CompaniesByAccountManagerSumDmsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_DMS_ASC',
  CompaniesByAccountManagerSumDmsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_DMS_DESC',
  CompaniesByAccountManagerSumWebProviderAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_WEB_PROVIDER_ASC',
  CompaniesByAccountManagerSumWebProviderDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_WEB_PROVIDER_DESC',
  CompaniesByAccountManagerSumCountryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_COUNTRY_ASC',
  CompaniesByAccountManagerSumCountryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_SUM_COUNTRY_DESC',
  CompaniesByAccountManagerDistinctCountIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_ID_ASC',
  CompaniesByAccountManagerDistinctCountIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_ID_DESC',
  CompaniesByAccountManagerDistinctCountCreatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_CREATED_AT_ASC',
  CompaniesByAccountManagerDistinctCountCreatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_CREATED_AT_DESC',
  CompaniesByAccountManagerDistinctCountNameAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_NAME_ASC',
  CompaniesByAccountManagerDistinctCountNameDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_NAME_DESC',
  CompaniesByAccountManagerDistinctCountEquifaxCustomerNumberAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesByAccountManagerDistinctCountEquifaxCustomerNumberDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesByAccountManagerDistinctCountSecurityCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_SECURITY_CODE_ASC',
  CompaniesByAccountManagerDistinctCountSecurityCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_SECURITY_CODE_DESC',
  CompaniesByAccountManagerDistinctCountExternalIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_EXTERNAL_ID_ASC',
  CompaniesByAccountManagerDistinctCountExternalIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_EXTERNAL_ID_DESC',
  CompaniesByAccountManagerDistinctCountUpdatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompaniesByAccountManagerDistinctCountUpdatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompaniesByAccountManagerDistinctCountArchivedAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_ARCHIVED_ASC',
  CompaniesByAccountManagerDistinctCountArchivedDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_ARCHIVED_DESC',
  CompaniesByAccountManagerDistinctCountZohoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_ZOHO_ID_ASC',
  CompaniesByAccountManagerDistinctCountZohoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_ZOHO_ID_DESC',
  CompaniesByAccountManagerDistinctCountDeliverToAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_DELIVER_TO_ASC',
  CompaniesByAccountManagerDistinctCountDeliverToDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_DELIVER_TO_DESC',
  CompaniesByAccountManagerDistinctCountPrimaryGroupAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_PRIMARY_GROUP_ASC',
  CompaniesByAccountManagerDistinctCountPrimaryGroupDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_PRIMARY_GROUP_DESC',
  CompaniesByAccountManagerDistinctCountRawMetaAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_RAW_META_ASC',
  CompaniesByAccountManagerDistinctCountRawMetaDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_RAW_META_DESC',
  CompaniesByAccountManagerDistinctCountBillableCompanyIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_BILLABLE_COMPANY_ID_ASC',
  CompaniesByAccountManagerDistinctCountBillableCompanyIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_BILLABLE_COMPANY_ID_DESC',
  CompaniesByAccountManagerDistinctCountRawRequestedAccessAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_RAW_REQUESTED_ACCESS_ASC',
  CompaniesByAccountManagerDistinctCountRawRequestedAccessDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_RAW_REQUESTED_ACCESS_DESC',
  CompaniesByAccountManagerDistinctCountLenderDealerCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_LENDER_DEALER_CODE_ASC',
  CompaniesByAccountManagerDistinctCountLenderDealerCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_LENDER_DEALER_CODE_DESC',
  CompaniesByAccountManagerDistinctCountGooglePlacesIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_GOOGLE_PLACES_ID_ASC',
  CompaniesByAccountManagerDistinctCountGooglePlacesIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_GOOGLE_PLACES_ID_DESC',
  CompaniesByAccountManagerDistinctCountLegalConsentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_LEGAL_CONSENT_ASC',
  CompaniesByAccountManagerDistinctCountLegalConsentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_LEGAL_CONSENT_DESC',
  CompaniesByAccountManagerDistinctCountLagoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_LAGO_ID_ASC',
  CompaniesByAccountManagerDistinctCountLagoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_LAGO_ID_DESC',
  CompaniesByAccountManagerDistinctCountStripeCustomerIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_STRIPE_CUSTOMER_ID_ASC',
  CompaniesByAccountManagerDistinctCountStripeCustomerIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_STRIPE_CUSTOMER_ID_DESC',
  CompaniesByAccountManagerDistinctCountSalesOrderIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_SALES_ORDER_ID_ASC',
  CompaniesByAccountManagerDistinctCountSalesOrderIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_SALES_ORDER_ID_DESC',
  CompaniesByAccountManagerDistinctCountIndustryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_INDUSTRY_ASC',
  CompaniesByAccountManagerDistinctCountIndustryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_INDUSTRY_DESC',
  CompaniesByAccountManagerDistinctCountManufacturerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_MANUFACTURER_ASC',
  CompaniesByAccountManagerDistinctCountManufacturerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_MANUFACTURER_DESC',
  CompaniesByAccountManagerDistinctCountCrmAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_CRM_ASC',
  CompaniesByAccountManagerDistinctCountCrmDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_CRM_DESC',
  CompaniesByAccountManagerDistinctCountAccountManagerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_ACCOUNT_MANAGER_ASC',
  CompaniesByAccountManagerDistinctCountAccountManagerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_ACCOUNT_MANAGER_DESC',
  CompaniesByAccountManagerDistinctCountSupportAgentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_SUPPORT_AGENT_ASC',
  CompaniesByAccountManagerDistinctCountSupportAgentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_SUPPORT_AGENT_DESC',
  CompaniesByAccountManagerDistinctCountOpportunityTypeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_OPPORTUNITY_TYPE_ASC',
  CompaniesByAccountManagerDistinctCountOpportunityTypeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_OPPORTUNITY_TYPE_DESC',
  CompaniesByAccountManagerDistinctCountNumberOfLocationsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_NUMBER_OF_LOCATIONS_ASC',
  CompaniesByAccountManagerDistinctCountNumberOfLocationsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_NUMBER_OF_LOCATIONS_DESC',
  CompaniesByAccountManagerDistinctCountWebsiteAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_WEBSITE_ASC',
  CompaniesByAccountManagerDistinctCountWebsiteDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_WEBSITE_DESC',
  CompaniesByAccountManagerDistinctCountDmsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_DMS_ASC',
  CompaniesByAccountManagerDistinctCountDmsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_DMS_DESC',
  CompaniesByAccountManagerDistinctCountWebProviderAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_WEB_PROVIDER_ASC',
  CompaniesByAccountManagerDistinctCountWebProviderDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_WEB_PROVIDER_DESC',
  CompaniesByAccountManagerDistinctCountCountryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_COUNTRY_ASC',
  CompaniesByAccountManagerDistinctCountCountryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_DISTINCT_COUNT_COUNTRY_DESC',
  CompaniesByAccountManagerMinIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_ID_ASC',
  CompaniesByAccountManagerMinIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_ID_DESC',
  CompaniesByAccountManagerMinCreatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_CREATED_AT_ASC',
  CompaniesByAccountManagerMinCreatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_CREATED_AT_DESC',
  CompaniesByAccountManagerMinNameAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_NAME_ASC',
  CompaniesByAccountManagerMinNameDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_NAME_DESC',
  CompaniesByAccountManagerMinEquifaxCustomerNumberAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesByAccountManagerMinEquifaxCustomerNumberDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesByAccountManagerMinSecurityCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_SECURITY_CODE_ASC',
  CompaniesByAccountManagerMinSecurityCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_SECURITY_CODE_DESC',
  CompaniesByAccountManagerMinExternalIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_EXTERNAL_ID_ASC',
  CompaniesByAccountManagerMinExternalIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_EXTERNAL_ID_DESC',
  CompaniesByAccountManagerMinUpdatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_UPDATED_AT_ASC',
  CompaniesByAccountManagerMinUpdatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_UPDATED_AT_DESC',
  CompaniesByAccountManagerMinArchivedAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_ARCHIVED_ASC',
  CompaniesByAccountManagerMinArchivedDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_ARCHIVED_DESC',
  CompaniesByAccountManagerMinZohoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_ZOHO_ID_ASC',
  CompaniesByAccountManagerMinZohoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_ZOHO_ID_DESC',
  CompaniesByAccountManagerMinDeliverToAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_DELIVER_TO_ASC',
  CompaniesByAccountManagerMinDeliverToDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_DELIVER_TO_DESC',
  CompaniesByAccountManagerMinPrimaryGroupAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_PRIMARY_GROUP_ASC',
  CompaniesByAccountManagerMinPrimaryGroupDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_PRIMARY_GROUP_DESC',
  CompaniesByAccountManagerMinRawMetaAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_RAW_META_ASC',
  CompaniesByAccountManagerMinRawMetaDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_RAW_META_DESC',
  CompaniesByAccountManagerMinBillableCompanyIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_BILLABLE_COMPANY_ID_ASC',
  CompaniesByAccountManagerMinBillableCompanyIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_BILLABLE_COMPANY_ID_DESC',
  CompaniesByAccountManagerMinRawRequestedAccessAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_RAW_REQUESTED_ACCESS_ASC',
  CompaniesByAccountManagerMinRawRequestedAccessDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_RAW_REQUESTED_ACCESS_DESC',
  CompaniesByAccountManagerMinLenderDealerCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_LENDER_DEALER_CODE_ASC',
  CompaniesByAccountManagerMinLenderDealerCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_LENDER_DEALER_CODE_DESC',
  CompaniesByAccountManagerMinGooglePlacesIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_GOOGLE_PLACES_ID_ASC',
  CompaniesByAccountManagerMinGooglePlacesIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_GOOGLE_PLACES_ID_DESC',
  CompaniesByAccountManagerMinLegalConsentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_LEGAL_CONSENT_ASC',
  CompaniesByAccountManagerMinLegalConsentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_LEGAL_CONSENT_DESC',
  CompaniesByAccountManagerMinLagoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_LAGO_ID_ASC',
  CompaniesByAccountManagerMinLagoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_LAGO_ID_DESC',
  CompaniesByAccountManagerMinStripeCustomerIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_STRIPE_CUSTOMER_ID_ASC',
  CompaniesByAccountManagerMinStripeCustomerIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_STRIPE_CUSTOMER_ID_DESC',
  CompaniesByAccountManagerMinSalesOrderIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_SALES_ORDER_ID_ASC',
  CompaniesByAccountManagerMinSalesOrderIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_SALES_ORDER_ID_DESC',
  CompaniesByAccountManagerMinIndustryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_INDUSTRY_ASC',
  CompaniesByAccountManagerMinIndustryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_INDUSTRY_DESC',
  CompaniesByAccountManagerMinManufacturerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_MANUFACTURER_ASC',
  CompaniesByAccountManagerMinManufacturerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_MANUFACTURER_DESC',
  CompaniesByAccountManagerMinCrmAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_CRM_ASC',
  CompaniesByAccountManagerMinCrmDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_CRM_DESC',
  CompaniesByAccountManagerMinAccountManagerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_ACCOUNT_MANAGER_ASC',
  CompaniesByAccountManagerMinAccountManagerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_ACCOUNT_MANAGER_DESC',
  CompaniesByAccountManagerMinSupportAgentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_SUPPORT_AGENT_ASC',
  CompaniesByAccountManagerMinSupportAgentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_SUPPORT_AGENT_DESC',
  CompaniesByAccountManagerMinOpportunityTypeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_OPPORTUNITY_TYPE_ASC',
  CompaniesByAccountManagerMinOpportunityTypeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_OPPORTUNITY_TYPE_DESC',
  CompaniesByAccountManagerMinNumberOfLocationsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_NUMBER_OF_LOCATIONS_ASC',
  CompaniesByAccountManagerMinNumberOfLocationsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_NUMBER_OF_LOCATIONS_DESC',
  CompaniesByAccountManagerMinWebsiteAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_WEBSITE_ASC',
  CompaniesByAccountManagerMinWebsiteDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_WEBSITE_DESC',
  CompaniesByAccountManagerMinDmsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_DMS_ASC',
  CompaniesByAccountManagerMinDmsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_DMS_DESC',
  CompaniesByAccountManagerMinWebProviderAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_WEB_PROVIDER_ASC',
  CompaniesByAccountManagerMinWebProviderDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_WEB_PROVIDER_DESC',
  CompaniesByAccountManagerMinCountryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_COUNTRY_ASC',
  CompaniesByAccountManagerMinCountryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MIN_COUNTRY_DESC',
  CompaniesByAccountManagerMaxIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_ID_ASC',
  CompaniesByAccountManagerMaxIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_ID_DESC',
  CompaniesByAccountManagerMaxCreatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_CREATED_AT_ASC',
  CompaniesByAccountManagerMaxCreatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_CREATED_AT_DESC',
  CompaniesByAccountManagerMaxNameAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_NAME_ASC',
  CompaniesByAccountManagerMaxNameDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_NAME_DESC',
  CompaniesByAccountManagerMaxEquifaxCustomerNumberAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesByAccountManagerMaxEquifaxCustomerNumberDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesByAccountManagerMaxSecurityCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_SECURITY_CODE_ASC',
  CompaniesByAccountManagerMaxSecurityCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_SECURITY_CODE_DESC',
  CompaniesByAccountManagerMaxExternalIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_EXTERNAL_ID_ASC',
  CompaniesByAccountManagerMaxExternalIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_EXTERNAL_ID_DESC',
  CompaniesByAccountManagerMaxUpdatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_UPDATED_AT_ASC',
  CompaniesByAccountManagerMaxUpdatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_UPDATED_AT_DESC',
  CompaniesByAccountManagerMaxArchivedAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_ARCHIVED_ASC',
  CompaniesByAccountManagerMaxArchivedDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_ARCHIVED_DESC',
  CompaniesByAccountManagerMaxZohoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_ZOHO_ID_ASC',
  CompaniesByAccountManagerMaxZohoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_ZOHO_ID_DESC',
  CompaniesByAccountManagerMaxDeliverToAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_DELIVER_TO_ASC',
  CompaniesByAccountManagerMaxDeliverToDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_DELIVER_TO_DESC',
  CompaniesByAccountManagerMaxPrimaryGroupAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_PRIMARY_GROUP_ASC',
  CompaniesByAccountManagerMaxPrimaryGroupDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_PRIMARY_GROUP_DESC',
  CompaniesByAccountManagerMaxRawMetaAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_RAW_META_ASC',
  CompaniesByAccountManagerMaxRawMetaDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_RAW_META_DESC',
  CompaniesByAccountManagerMaxBillableCompanyIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_BILLABLE_COMPANY_ID_ASC',
  CompaniesByAccountManagerMaxBillableCompanyIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_BILLABLE_COMPANY_ID_DESC',
  CompaniesByAccountManagerMaxRawRequestedAccessAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_RAW_REQUESTED_ACCESS_ASC',
  CompaniesByAccountManagerMaxRawRequestedAccessDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_RAW_REQUESTED_ACCESS_DESC',
  CompaniesByAccountManagerMaxLenderDealerCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_LENDER_DEALER_CODE_ASC',
  CompaniesByAccountManagerMaxLenderDealerCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_LENDER_DEALER_CODE_DESC',
  CompaniesByAccountManagerMaxGooglePlacesIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_GOOGLE_PLACES_ID_ASC',
  CompaniesByAccountManagerMaxGooglePlacesIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_GOOGLE_PLACES_ID_DESC',
  CompaniesByAccountManagerMaxLegalConsentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_LEGAL_CONSENT_ASC',
  CompaniesByAccountManagerMaxLegalConsentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_LEGAL_CONSENT_DESC',
  CompaniesByAccountManagerMaxLagoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_LAGO_ID_ASC',
  CompaniesByAccountManagerMaxLagoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_LAGO_ID_DESC',
  CompaniesByAccountManagerMaxStripeCustomerIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_STRIPE_CUSTOMER_ID_ASC',
  CompaniesByAccountManagerMaxStripeCustomerIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_STRIPE_CUSTOMER_ID_DESC',
  CompaniesByAccountManagerMaxSalesOrderIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_SALES_ORDER_ID_ASC',
  CompaniesByAccountManagerMaxSalesOrderIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_SALES_ORDER_ID_DESC',
  CompaniesByAccountManagerMaxIndustryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_INDUSTRY_ASC',
  CompaniesByAccountManagerMaxIndustryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_INDUSTRY_DESC',
  CompaniesByAccountManagerMaxManufacturerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_MANUFACTURER_ASC',
  CompaniesByAccountManagerMaxManufacturerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_MANUFACTURER_DESC',
  CompaniesByAccountManagerMaxCrmAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_CRM_ASC',
  CompaniesByAccountManagerMaxCrmDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_CRM_DESC',
  CompaniesByAccountManagerMaxAccountManagerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_ACCOUNT_MANAGER_ASC',
  CompaniesByAccountManagerMaxAccountManagerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_ACCOUNT_MANAGER_DESC',
  CompaniesByAccountManagerMaxSupportAgentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_SUPPORT_AGENT_ASC',
  CompaniesByAccountManagerMaxSupportAgentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_SUPPORT_AGENT_DESC',
  CompaniesByAccountManagerMaxOpportunityTypeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_OPPORTUNITY_TYPE_ASC',
  CompaniesByAccountManagerMaxOpportunityTypeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_OPPORTUNITY_TYPE_DESC',
  CompaniesByAccountManagerMaxNumberOfLocationsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_NUMBER_OF_LOCATIONS_ASC',
  CompaniesByAccountManagerMaxNumberOfLocationsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_NUMBER_OF_LOCATIONS_DESC',
  CompaniesByAccountManagerMaxWebsiteAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_WEBSITE_ASC',
  CompaniesByAccountManagerMaxWebsiteDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_WEBSITE_DESC',
  CompaniesByAccountManagerMaxDmsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_DMS_ASC',
  CompaniesByAccountManagerMaxDmsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_DMS_DESC',
  CompaniesByAccountManagerMaxWebProviderAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_WEB_PROVIDER_ASC',
  CompaniesByAccountManagerMaxWebProviderDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_WEB_PROVIDER_DESC',
  CompaniesByAccountManagerMaxCountryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_COUNTRY_ASC',
  CompaniesByAccountManagerMaxCountryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_MAX_COUNTRY_DESC',
  CompaniesByAccountManagerAverageIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_ID_ASC',
  CompaniesByAccountManagerAverageIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_ID_DESC',
  CompaniesByAccountManagerAverageCreatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_CREATED_AT_ASC',
  CompaniesByAccountManagerAverageCreatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_CREATED_AT_DESC',
  CompaniesByAccountManagerAverageNameAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_NAME_ASC',
  CompaniesByAccountManagerAverageNameDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_NAME_DESC',
  CompaniesByAccountManagerAverageEquifaxCustomerNumberAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesByAccountManagerAverageEquifaxCustomerNumberDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesByAccountManagerAverageSecurityCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_SECURITY_CODE_ASC',
  CompaniesByAccountManagerAverageSecurityCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_SECURITY_CODE_DESC',
  CompaniesByAccountManagerAverageExternalIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_EXTERNAL_ID_ASC',
  CompaniesByAccountManagerAverageExternalIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_EXTERNAL_ID_DESC',
  CompaniesByAccountManagerAverageUpdatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_UPDATED_AT_ASC',
  CompaniesByAccountManagerAverageUpdatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_UPDATED_AT_DESC',
  CompaniesByAccountManagerAverageArchivedAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_ARCHIVED_ASC',
  CompaniesByAccountManagerAverageArchivedDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_ARCHIVED_DESC',
  CompaniesByAccountManagerAverageZohoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_ZOHO_ID_ASC',
  CompaniesByAccountManagerAverageZohoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_ZOHO_ID_DESC',
  CompaniesByAccountManagerAverageDeliverToAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_DELIVER_TO_ASC',
  CompaniesByAccountManagerAverageDeliverToDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_DELIVER_TO_DESC',
  CompaniesByAccountManagerAveragePrimaryGroupAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_PRIMARY_GROUP_ASC',
  CompaniesByAccountManagerAveragePrimaryGroupDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_PRIMARY_GROUP_DESC',
  CompaniesByAccountManagerAverageRawMetaAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_RAW_META_ASC',
  CompaniesByAccountManagerAverageRawMetaDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_RAW_META_DESC',
  CompaniesByAccountManagerAverageBillableCompanyIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_BILLABLE_COMPANY_ID_ASC',
  CompaniesByAccountManagerAverageBillableCompanyIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_BILLABLE_COMPANY_ID_DESC',
  CompaniesByAccountManagerAverageRawRequestedAccessAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_RAW_REQUESTED_ACCESS_ASC',
  CompaniesByAccountManagerAverageRawRequestedAccessDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_RAW_REQUESTED_ACCESS_DESC',
  CompaniesByAccountManagerAverageLenderDealerCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_LENDER_DEALER_CODE_ASC',
  CompaniesByAccountManagerAverageLenderDealerCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_LENDER_DEALER_CODE_DESC',
  CompaniesByAccountManagerAverageGooglePlacesIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_GOOGLE_PLACES_ID_ASC',
  CompaniesByAccountManagerAverageGooglePlacesIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_GOOGLE_PLACES_ID_DESC',
  CompaniesByAccountManagerAverageLegalConsentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_LEGAL_CONSENT_ASC',
  CompaniesByAccountManagerAverageLegalConsentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_LEGAL_CONSENT_DESC',
  CompaniesByAccountManagerAverageLagoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_LAGO_ID_ASC',
  CompaniesByAccountManagerAverageLagoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_LAGO_ID_DESC',
  CompaniesByAccountManagerAverageStripeCustomerIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_STRIPE_CUSTOMER_ID_ASC',
  CompaniesByAccountManagerAverageStripeCustomerIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_STRIPE_CUSTOMER_ID_DESC',
  CompaniesByAccountManagerAverageSalesOrderIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_SALES_ORDER_ID_ASC',
  CompaniesByAccountManagerAverageSalesOrderIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_SALES_ORDER_ID_DESC',
  CompaniesByAccountManagerAverageIndustryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_INDUSTRY_ASC',
  CompaniesByAccountManagerAverageIndustryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_INDUSTRY_DESC',
  CompaniesByAccountManagerAverageManufacturerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_MANUFACTURER_ASC',
  CompaniesByAccountManagerAverageManufacturerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_MANUFACTURER_DESC',
  CompaniesByAccountManagerAverageCrmAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_CRM_ASC',
  CompaniesByAccountManagerAverageCrmDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_CRM_DESC',
  CompaniesByAccountManagerAverageAccountManagerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_ACCOUNT_MANAGER_ASC',
  CompaniesByAccountManagerAverageAccountManagerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_ACCOUNT_MANAGER_DESC',
  CompaniesByAccountManagerAverageSupportAgentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_SUPPORT_AGENT_ASC',
  CompaniesByAccountManagerAverageSupportAgentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_SUPPORT_AGENT_DESC',
  CompaniesByAccountManagerAverageOpportunityTypeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_OPPORTUNITY_TYPE_ASC',
  CompaniesByAccountManagerAverageOpportunityTypeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_OPPORTUNITY_TYPE_DESC',
  CompaniesByAccountManagerAverageNumberOfLocationsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_NUMBER_OF_LOCATIONS_ASC',
  CompaniesByAccountManagerAverageNumberOfLocationsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_NUMBER_OF_LOCATIONS_DESC',
  CompaniesByAccountManagerAverageWebsiteAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_WEBSITE_ASC',
  CompaniesByAccountManagerAverageWebsiteDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_WEBSITE_DESC',
  CompaniesByAccountManagerAverageDmsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_DMS_ASC',
  CompaniesByAccountManagerAverageDmsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_DMS_DESC',
  CompaniesByAccountManagerAverageWebProviderAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_WEB_PROVIDER_ASC',
  CompaniesByAccountManagerAverageWebProviderDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_WEB_PROVIDER_DESC',
  CompaniesByAccountManagerAverageCountryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_COUNTRY_ASC',
  CompaniesByAccountManagerAverageCountryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_AVERAGE_COUNTRY_DESC',
  CompaniesByAccountManagerStddevSampleIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_ID_ASC',
  CompaniesByAccountManagerStddevSampleIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_ID_DESC',
  CompaniesByAccountManagerStddevSampleCreatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompaniesByAccountManagerStddevSampleCreatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompaniesByAccountManagerStddevSampleNameAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_NAME_ASC',
  CompaniesByAccountManagerStddevSampleNameDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_NAME_DESC',
  CompaniesByAccountManagerStddevSampleEquifaxCustomerNumberAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesByAccountManagerStddevSampleEquifaxCustomerNumberDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesByAccountManagerStddevSampleSecurityCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_SECURITY_CODE_ASC',
  CompaniesByAccountManagerStddevSampleSecurityCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_SECURITY_CODE_DESC',
  CompaniesByAccountManagerStddevSampleExternalIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_EXTERNAL_ID_ASC',
  CompaniesByAccountManagerStddevSampleExternalIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_EXTERNAL_ID_DESC',
  CompaniesByAccountManagerStddevSampleUpdatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompaniesByAccountManagerStddevSampleUpdatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompaniesByAccountManagerStddevSampleArchivedAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_ARCHIVED_ASC',
  CompaniesByAccountManagerStddevSampleArchivedDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_ARCHIVED_DESC',
  CompaniesByAccountManagerStddevSampleZohoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_ZOHO_ID_ASC',
  CompaniesByAccountManagerStddevSampleZohoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_ZOHO_ID_DESC',
  CompaniesByAccountManagerStddevSampleDeliverToAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_DELIVER_TO_ASC',
  CompaniesByAccountManagerStddevSampleDeliverToDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_DELIVER_TO_DESC',
  CompaniesByAccountManagerStddevSamplePrimaryGroupAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_PRIMARY_GROUP_ASC',
  CompaniesByAccountManagerStddevSamplePrimaryGroupDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_PRIMARY_GROUP_DESC',
  CompaniesByAccountManagerStddevSampleRawMetaAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_RAW_META_ASC',
  CompaniesByAccountManagerStddevSampleRawMetaDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_RAW_META_DESC',
  CompaniesByAccountManagerStddevSampleBillableCompanyIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_BILLABLE_COMPANY_ID_ASC',
  CompaniesByAccountManagerStddevSampleBillableCompanyIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_BILLABLE_COMPANY_ID_DESC',
  CompaniesByAccountManagerStddevSampleRawRequestedAccessAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_RAW_REQUESTED_ACCESS_ASC',
  CompaniesByAccountManagerStddevSampleRawRequestedAccessDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_RAW_REQUESTED_ACCESS_DESC',
  CompaniesByAccountManagerStddevSampleLenderDealerCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_LENDER_DEALER_CODE_ASC',
  CompaniesByAccountManagerStddevSampleLenderDealerCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_LENDER_DEALER_CODE_DESC',
  CompaniesByAccountManagerStddevSampleGooglePlacesIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_GOOGLE_PLACES_ID_ASC',
  CompaniesByAccountManagerStddevSampleGooglePlacesIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_GOOGLE_PLACES_ID_DESC',
  CompaniesByAccountManagerStddevSampleLegalConsentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_LEGAL_CONSENT_ASC',
  CompaniesByAccountManagerStddevSampleLegalConsentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_LEGAL_CONSENT_DESC',
  CompaniesByAccountManagerStddevSampleLagoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_LAGO_ID_ASC',
  CompaniesByAccountManagerStddevSampleLagoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_LAGO_ID_DESC',
  CompaniesByAccountManagerStddevSampleStripeCustomerIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_STRIPE_CUSTOMER_ID_ASC',
  CompaniesByAccountManagerStddevSampleStripeCustomerIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_STRIPE_CUSTOMER_ID_DESC',
  CompaniesByAccountManagerStddevSampleSalesOrderIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_SALES_ORDER_ID_ASC',
  CompaniesByAccountManagerStddevSampleSalesOrderIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_SALES_ORDER_ID_DESC',
  CompaniesByAccountManagerStddevSampleIndustryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_INDUSTRY_ASC',
  CompaniesByAccountManagerStddevSampleIndustryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_INDUSTRY_DESC',
  CompaniesByAccountManagerStddevSampleManufacturerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_MANUFACTURER_ASC',
  CompaniesByAccountManagerStddevSampleManufacturerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_MANUFACTURER_DESC',
  CompaniesByAccountManagerStddevSampleCrmAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_CRM_ASC',
  CompaniesByAccountManagerStddevSampleCrmDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_CRM_DESC',
  CompaniesByAccountManagerStddevSampleAccountManagerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_ACCOUNT_MANAGER_ASC',
  CompaniesByAccountManagerStddevSampleAccountManagerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_ACCOUNT_MANAGER_DESC',
  CompaniesByAccountManagerStddevSampleSupportAgentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_SUPPORT_AGENT_ASC',
  CompaniesByAccountManagerStddevSampleSupportAgentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_SUPPORT_AGENT_DESC',
  CompaniesByAccountManagerStddevSampleOpportunityTypeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_OPPORTUNITY_TYPE_ASC',
  CompaniesByAccountManagerStddevSampleOpportunityTypeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_OPPORTUNITY_TYPE_DESC',
  CompaniesByAccountManagerStddevSampleNumberOfLocationsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_NUMBER_OF_LOCATIONS_ASC',
  CompaniesByAccountManagerStddevSampleNumberOfLocationsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_NUMBER_OF_LOCATIONS_DESC',
  CompaniesByAccountManagerStddevSampleWebsiteAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_WEBSITE_ASC',
  CompaniesByAccountManagerStddevSampleWebsiteDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_WEBSITE_DESC',
  CompaniesByAccountManagerStddevSampleDmsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_DMS_ASC',
  CompaniesByAccountManagerStddevSampleDmsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_DMS_DESC',
  CompaniesByAccountManagerStddevSampleWebProviderAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_WEB_PROVIDER_ASC',
  CompaniesByAccountManagerStddevSampleWebProviderDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_WEB_PROVIDER_DESC',
  CompaniesByAccountManagerStddevSampleCountryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_COUNTRY_ASC',
  CompaniesByAccountManagerStddevSampleCountryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_SAMPLE_COUNTRY_DESC',
  CompaniesByAccountManagerStddevPopulationIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_ID_ASC',
  CompaniesByAccountManagerStddevPopulationIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_ID_DESC',
  CompaniesByAccountManagerStddevPopulationCreatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_CREATED_AT_ASC',
  CompaniesByAccountManagerStddevPopulationCreatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_CREATED_AT_DESC',
  CompaniesByAccountManagerStddevPopulationNameAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_NAME_ASC',
  CompaniesByAccountManagerStddevPopulationNameDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_NAME_DESC',
  CompaniesByAccountManagerStddevPopulationEquifaxCustomerNumberAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesByAccountManagerStddevPopulationEquifaxCustomerNumberDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesByAccountManagerStddevPopulationSecurityCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_SECURITY_CODE_ASC',
  CompaniesByAccountManagerStddevPopulationSecurityCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_SECURITY_CODE_DESC',
  CompaniesByAccountManagerStddevPopulationExternalIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_EXTERNAL_ID_ASC',
  CompaniesByAccountManagerStddevPopulationExternalIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_EXTERNAL_ID_DESC',
  CompaniesByAccountManagerStddevPopulationUpdatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompaniesByAccountManagerStddevPopulationUpdatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompaniesByAccountManagerStddevPopulationArchivedAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_ARCHIVED_ASC',
  CompaniesByAccountManagerStddevPopulationArchivedDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_ARCHIVED_DESC',
  CompaniesByAccountManagerStddevPopulationZohoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_ZOHO_ID_ASC',
  CompaniesByAccountManagerStddevPopulationZohoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_ZOHO_ID_DESC',
  CompaniesByAccountManagerStddevPopulationDeliverToAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_DELIVER_TO_ASC',
  CompaniesByAccountManagerStddevPopulationDeliverToDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_DELIVER_TO_DESC',
  CompaniesByAccountManagerStddevPopulationPrimaryGroupAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_PRIMARY_GROUP_ASC',
  CompaniesByAccountManagerStddevPopulationPrimaryGroupDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_PRIMARY_GROUP_DESC',
  CompaniesByAccountManagerStddevPopulationRawMetaAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_RAW_META_ASC',
  CompaniesByAccountManagerStddevPopulationRawMetaDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_RAW_META_DESC',
  CompaniesByAccountManagerStddevPopulationBillableCompanyIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_BILLABLE_COMPANY_ID_ASC',
  CompaniesByAccountManagerStddevPopulationBillableCompanyIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_BILLABLE_COMPANY_ID_DESC',
  CompaniesByAccountManagerStddevPopulationRawRequestedAccessAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_RAW_REQUESTED_ACCESS_ASC',
  CompaniesByAccountManagerStddevPopulationRawRequestedAccessDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_RAW_REQUESTED_ACCESS_DESC',
  CompaniesByAccountManagerStddevPopulationLenderDealerCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_LENDER_DEALER_CODE_ASC',
  CompaniesByAccountManagerStddevPopulationLenderDealerCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_LENDER_DEALER_CODE_DESC',
  CompaniesByAccountManagerStddevPopulationGooglePlacesIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_GOOGLE_PLACES_ID_ASC',
  CompaniesByAccountManagerStddevPopulationGooglePlacesIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_GOOGLE_PLACES_ID_DESC',
  CompaniesByAccountManagerStddevPopulationLegalConsentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_LEGAL_CONSENT_ASC',
  CompaniesByAccountManagerStddevPopulationLegalConsentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_LEGAL_CONSENT_DESC',
  CompaniesByAccountManagerStddevPopulationLagoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_LAGO_ID_ASC',
  CompaniesByAccountManagerStddevPopulationLagoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_LAGO_ID_DESC',
  CompaniesByAccountManagerStddevPopulationStripeCustomerIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_STRIPE_CUSTOMER_ID_ASC',
  CompaniesByAccountManagerStddevPopulationStripeCustomerIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_STRIPE_CUSTOMER_ID_DESC',
  CompaniesByAccountManagerStddevPopulationSalesOrderIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_SALES_ORDER_ID_ASC',
  CompaniesByAccountManagerStddevPopulationSalesOrderIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_SALES_ORDER_ID_DESC',
  CompaniesByAccountManagerStddevPopulationIndustryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_INDUSTRY_ASC',
  CompaniesByAccountManagerStddevPopulationIndustryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_INDUSTRY_DESC',
  CompaniesByAccountManagerStddevPopulationManufacturerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_MANUFACTURER_ASC',
  CompaniesByAccountManagerStddevPopulationManufacturerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_MANUFACTURER_DESC',
  CompaniesByAccountManagerStddevPopulationCrmAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_CRM_ASC',
  CompaniesByAccountManagerStddevPopulationCrmDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_CRM_DESC',
  CompaniesByAccountManagerStddevPopulationAccountManagerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_ACCOUNT_MANAGER_ASC',
  CompaniesByAccountManagerStddevPopulationAccountManagerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_ACCOUNT_MANAGER_DESC',
  CompaniesByAccountManagerStddevPopulationSupportAgentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_SUPPORT_AGENT_ASC',
  CompaniesByAccountManagerStddevPopulationSupportAgentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_SUPPORT_AGENT_DESC',
  CompaniesByAccountManagerStddevPopulationOpportunityTypeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_OPPORTUNITY_TYPE_ASC',
  CompaniesByAccountManagerStddevPopulationOpportunityTypeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_OPPORTUNITY_TYPE_DESC',
  CompaniesByAccountManagerStddevPopulationNumberOfLocationsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_NUMBER_OF_LOCATIONS_ASC',
  CompaniesByAccountManagerStddevPopulationNumberOfLocationsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_NUMBER_OF_LOCATIONS_DESC',
  CompaniesByAccountManagerStddevPopulationWebsiteAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_WEBSITE_ASC',
  CompaniesByAccountManagerStddevPopulationWebsiteDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_WEBSITE_DESC',
  CompaniesByAccountManagerStddevPopulationDmsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_DMS_ASC',
  CompaniesByAccountManagerStddevPopulationDmsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_DMS_DESC',
  CompaniesByAccountManagerStddevPopulationWebProviderAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_WEB_PROVIDER_ASC',
  CompaniesByAccountManagerStddevPopulationWebProviderDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_WEB_PROVIDER_DESC',
  CompaniesByAccountManagerStddevPopulationCountryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_COUNTRY_ASC',
  CompaniesByAccountManagerStddevPopulationCountryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_STDDEV_POPULATION_COUNTRY_DESC',
  CompaniesByAccountManagerVarianceSampleIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_ID_ASC',
  CompaniesByAccountManagerVarianceSampleIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_ID_DESC',
  CompaniesByAccountManagerVarianceSampleCreatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompaniesByAccountManagerVarianceSampleCreatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompaniesByAccountManagerVarianceSampleNameAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_NAME_ASC',
  CompaniesByAccountManagerVarianceSampleNameDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_NAME_DESC',
  CompaniesByAccountManagerVarianceSampleEquifaxCustomerNumberAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesByAccountManagerVarianceSampleEquifaxCustomerNumberDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesByAccountManagerVarianceSampleSecurityCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_SECURITY_CODE_ASC',
  CompaniesByAccountManagerVarianceSampleSecurityCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_SECURITY_CODE_DESC',
  CompaniesByAccountManagerVarianceSampleExternalIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_EXTERNAL_ID_ASC',
  CompaniesByAccountManagerVarianceSampleExternalIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_EXTERNAL_ID_DESC',
  CompaniesByAccountManagerVarianceSampleUpdatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompaniesByAccountManagerVarianceSampleUpdatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompaniesByAccountManagerVarianceSampleArchivedAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_ARCHIVED_ASC',
  CompaniesByAccountManagerVarianceSampleArchivedDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_ARCHIVED_DESC',
  CompaniesByAccountManagerVarianceSampleZohoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_ZOHO_ID_ASC',
  CompaniesByAccountManagerVarianceSampleZohoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_ZOHO_ID_DESC',
  CompaniesByAccountManagerVarianceSampleDeliverToAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_DELIVER_TO_ASC',
  CompaniesByAccountManagerVarianceSampleDeliverToDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_DELIVER_TO_DESC',
  CompaniesByAccountManagerVarianceSamplePrimaryGroupAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_PRIMARY_GROUP_ASC',
  CompaniesByAccountManagerVarianceSamplePrimaryGroupDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_PRIMARY_GROUP_DESC',
  CompaniesByAccountManagerVarianceSampleRawMetaAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_RAW_META_ASC',
  CompaniesByAccountManagerVarianceSampleRawMetaDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_RAW_META_DESC',
  CompaniesByAccountManagerVarianceSampleBillableCompanyIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_BILLABLE_COMPANY_ID_ASC',
  CompaniesByAccountManagerVarianceSampleBillableCompanyIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_BILLABLE_COMPANY_ID_DESC',
  CompaniesByAccountManagerVarianceSampleRawRequestedAccessAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_RAW_REQUESTED_ACCESS_ASC',
  CompaniesByAccountManagerVarianceSampleRawRequestedAccessDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_RAW_REQUESTED_ACCESS_DESC',
  CompaniesByAccountManagerVarianceSampleLenderDealerCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_LENDER_DEALER_CODE_ASC',
  CompaniesByAccountManagerVarianceSampleLenderDealerCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_LENDER_DEALER_CODE_DESC',
  CompaniesByAccountManagerVarianceSampleGooglePlacesIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_GOOGLE_PLACES_ID_ASC',
  CompaniesByAccountManagerVarianceSampleGooglePlacesIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_GOOGLE_PLACES_ID_DESC',
  CompaniesByAccountManagerVarianceSampleLegalConsentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_LEGAL_CONSENT_ASC',
  CompaniesByAccountManagerVarianceSampleLegalConsentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_LEGAL_CONSENT_DESC',
  CompaniesByAccountManagerVarianceSampleLagoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_LAGO_ID_ASC',
  CompaniesByAccountManagerVarianceSampleLagoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_LAGO_ID_DESC',
  CompaniesByAccountManagerVarianceSampleStripeCustomerIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_STRIPE_CUSTOMER_ID_ASC',
  CompaniesByAccountManagerVarianceSampleStripeCustomerIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_STRIPE_CUSTOMER_ID_DESC',
  CompaniesByAccountManagerVarianceSampleSalesOrderIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_SALES_ORDER_ID_ASC',
  CompaniesByAccountManagerVarianceSampleSalesOrderIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_SALES_ORDER_ID_DESC',
  CompaniesByAccountManagerVarianceSampleIndustryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_INDUSTRY_ASC',
  CompaniesByAccountManagerVarianceSampleIndustryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_INDUSTRY_DESC',
  CompaniesByAccountManagerVarianceSampleManufacturerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_MANUFACTURER_ASC',
  CompaniesByAccountManagerVarianceSampleManufacturerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_MANUFACTURER_DESC',
  CompaniesByAccountManagerVarianceSampleCrmAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_CRM_ASC',
  CompaniesByAccountManagerVarianceSampleCrmDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_CRM_DESC',
  CompaniesByAccountManagerVarianceSampleAccountManagerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_ACCOUNT_MANAGER_ASC',
  CompaniesByAccountManagerVarianceSampleAccountManagerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_ACCOUNT_MANAGER_DESC',
  CompaniesByAccountManagerVarianceSampleSupportAgentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_SUPPORT_AGENT_ASC',
  CompaniesByAccountManagerVarianceSampleSupportAgentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_SUPPORT_AGENT_DESC',
  CompaniesByAccountManagerVarianceSampleOpportunityTypeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_OPPORTUNITY_TYPE_ASC',
  CompaniesByAccountManagerVarianceSampleOpportunityTypeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_OPPORTUNITY_TYPE_DESC',
  CompaniesByAccountManagerVarianceSampleNumberOfLocationsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_NUMBER_OF_LOCATIONS_ASC',
  CompaniesByAccountManagerVarianceSampleNumberOfLocationsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_NUMBER_OF_LOCATIONS_DESC',
  CompaniesByAccountManagerVarianceSampleWebsiteAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_WEBSITE_ASC',
  CompaniesByAccountManagerVarianceSampleWebsiteDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_WEBSITE_DESC',
  CompaniesByAccountManagerVarianceSampleDmsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_DMS_ASC',
  CompaniesByAccountManagerVarianceSampleDmsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_DMS_DESC',
  CompaniesByAccountManagerVarianceSampleWebProviderAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_WEB_PROVIDER_ASC',
  CompaniesByAccountManagerVarianceSampleWebProviderDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_WEB_PROVIDER_DESC',
  CompaniesByAccountManagerVarianceSampleCountryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_COUNTRY_ASC',
  CompaniesByAccountManagerVarianceSampleCountryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_SAMPLE_COUNTRY_DESC',
  CompaniesByAccountManagerVariancePopulationIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_ID_ASC',
  CompaniesByAccountManagerVariancePopulationIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_ID_DESC',
  CompaniesByAccountManagerVariancePopulationCreatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompaniesByAccountManagerVariancePopulationCreatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompaniesByAccountManagerVariancePopulationNameAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_NAME_ASC',
  CompaniesByAccountManagerVariancePopulationNameDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_NAME_DESC',
  CompaniesByAccountManagerVariancePopulationEquifaxCustomerNumberAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesByAccountManagerVariancePopulationEquifaxCustomerNumberDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesByAccountManagerVariancePopulationSecurityCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_SECURITY_CODE_ASC',
  CompaniesByAccountManagerVariancePopulationSecurityCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_SECURITY_CODE_DESC',
  CompaniesByAccountManagerVariancePopulationExternalIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_EXTERNAL_ID_ASC',
  CompaniesByAccountManagerVariancePopulationExternalIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_EXTERNAL_ID_DESC',
  CompaniesByAccountManagerVariancePopulationUpdatedAtAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompaniesByAccountManagerVariancePopulationUpdatedAtDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompaniesByAccountManagerVariancePopulationArchivedAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_ARCHIVED_ASC',
  CompaniesByAccountManagerVariancePopulationArchivedDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_ARCHIVED_DESC',
  CompaniesByAccountManagerVariancePopulationZohoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_ZOHO_ID_ASC',
  CompaniesByAccountManagerVariancePopulationZohoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_ZOHO_ID_DESC',
  CompaniesByAccountManagerVariancePopulationDeliverToAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_DELIVER_TO_ASC',
  CompaniesByAccountManagerVariancePopulationDeliverToDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_DELIVER_TO_DESC',
  CompaniesByAccountManagerVariancePopulationPrimaryGroupAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_PRIMARY_GROUP_ASC',
  CompaniesByAccountManagerVariancePopulationPrimaryGroupDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_PRIMARY_GROUP_DESC',
  CompaniesByAccountManagerVariancePopulationRawMetaAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_RAW_META_ASC',
  CompaniesByAccountManagerVariancePopulationRawMetaDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_RAW_META_DESC',
  CompaniesByAccountManagerVariancePopulationBillableCompanyIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_BILLABLE_COMPANY_ID_ASC',
  CompaniesByAccountManagerVariancePopulationBillableCompanyIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_BILLABLE_COMPANY_ID_DESC',
  CompaniesByAccountManagerVariancePopulationRawRequestedAccessAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_RAW_REQUESTED_ACCESS_ASC',
  CompaniesByAccountManagerVariancePopulationRawRequestedAccessDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_RAW_REQUESTED_ACCESS_DESC',
  CompaniesByAccountManagerVariancePopulationLenderDealerCodeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_LENDER_DEALER_CODE_ASC',
  CompaniesByAccountManagerVariancePopulationLenderDealerCodeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_LENDER_DEALER_CODE_DESC',
  CompaniesByAccountManagerVariancePopulationGooglePlacesIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_GOOGLE_PLACES_ID_ASC',
  CompaniesByAccountManagerVariancePopulationGooglePlacesIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_GOOGLE_PLACES_ID_DESC',
  CompaniesByAccountManagerVariancePopulationLegalConsentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_LEGAL_CONSENT_ASC',
  CompaniesByAccountManagerVariancePopulationLegalConsentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_LEGAL_CONSENT_DESC',
  CompaniesByAccountManagerVariancePopulationLagoIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_LAGO_ID_ASC',
  CompaniesByAccountManagerVariancePopulationLagoIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_LAGO_ID_DESC',
  CompaniesByAccountManagerVariancePopulationStripeCustomerIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_STRIPE_CUSTOMER_ID_ASC',
  CompaniesByAccountManagerVariancePopulationStripeCustomerIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_STRIPE_CUSTOMER_ID_DESC',
  CompaniesByAccountManagerVariancePopulationSalesOrderIdAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_SALES_ORDER_ID_ASC',
  CompaniesByAccountManagerVariancePopulationSalesOrderIdDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_SALES_ORDER_ID_DESC',
  CompaniesByAccountManagerVariancePopulationIndustryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_INDUSTRY_ASC',
  CompaniesByAccountManagerVariancePopulationIndustryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_INDUSTRY_DESC',
  CompaniesByAccountManagerVariancePopulationManufacturerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_MANUFACTURER_ASC',
  CompaniesByAccountManagerVariancePopulationManufacturerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_MANUFACTURER_DESC',
  CompaniesByAccountManagerVariancePopulationCrmAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_CRM_ASC',
  CompaniesByAccountManagerVariancePopulationCrmDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_CRM_DESC',
  CompaniesByAccountManagerVariancePopulationAccountManagerAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_ACCOUNT_MANAGER_ASC',
  CompaniesByAccountManagerVariancePopulationAccountManagerDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_ACCOUNT_MANAGER_DESC',
  CompaniesByAccountManagerVariancePopulationSupportAgentAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_SUPPORT_AGENT_ASC',
  CompaniesByAccountManagerVariancePopulationSupportAgentDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_SUPPORT_AGENT_DESC',
  CompaniesByAccountManagerVariancePopulationOpportunityTypeAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_OPPORTUNITY_TYPE_ASC',
  CompaniesByAccountManagerVariancePopulationOpportunityTypeDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_OPPORTUNITY_TYPE_DESC',
  CompaniesByAccountManagerVariancePopulationNumberOfLocationsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_NUMBER_OF_LOCATIONS_ASC',
  CompaniesByAccountManagerVariancePopulationNumberOfLocationsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_NUMBER_OF_LOCATIONS_DESC',
  CompaniesByAccountManagerVariancePopulationWebsiteAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_WEBSITE_ASC',
  CompaniesByAccountManagerVariancePopulationWebsiteDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_WEBSITE_DESC',
  CompaniesByAccountManagerVariancePopulationDmsAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_DMS_ASC',
  CompaniesByAccountManagerVariancePopulationDmsDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_DMS_DESC',
  CompaniesByAccountManagerVariancePopulationWebProviderAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_WEB_PROVIDER_ASC',
  CompaniesByAccountManagerVariancePopulationWebProviderDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_WEB_PROVIDER_DESC',
  CompaniesByAccountManagerVariancePopulationCountryAsc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_COUNTRY_ASC',
  CompaniesByAccountManagerVariancePopulationCountryDesc = 'COMPANIES_BY_ACCOUNT_MANAGER_VARIANCE_POPULATION_COUNTRY_DESC',
  CompaniesBySupportAgentCountAsc = 'COMPANIES_BY_SUPPORT_AGENT_COUNT_ASC',
  CompaniesBySupportAgentCountDesc = 'COMPANIES_BY_SUPPORT_AGENT_COUNT_DESC',
  CompaniesBySupportAgentSumIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_ID_ASC',
  CompaniesBySupportAgentSumIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_ID_DESC',
  CompaniesBySupportAgentSumCreatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_CREATED_AT_ASC',
  CompaniesBySupportAgentSumCreatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_CREATED_AT_DESC',
  CompaniesBySupportAgentSumNameAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_NAME_ASC',
  CompaniesBySupportAgentSumNameDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_NAME_DESC',
  CompaniesBySupportAgentSumEquifaxCustomerNumberAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesBySupportAgentSumEquifaxCustomerNumberDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesBySupportAgentSumSecurityCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_SECURITY_CODE_ASC',
  CompaniesBySupportAgentSumSecurityCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_SECURITY_CODE_DESC',
  CompaniesBySupportAgentSumExternalIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_EXTERNAL_ID_ASC',
  CompaniesBySupportAgentSumExternalIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_EXTERNAL_ID_DESC',
  CompaniesBySupportAgentSumUpdatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_UPDATED_AT_ASC',
  CompaniesBySupportAgentSumUpdatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_UPDATED_AT_DESC',
  CompaniesBySupportAgentSumArchivedAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_ARCHIVED_ASC',
  CompaniesBySupportAgentSumArchivedDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_ARCHIVED_DESC',
  CompaniesBySupportAgentSumZohoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_ZOHO_ID_ASC',
  CompaniesBySupportAgentSumZohoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_ZOHO_ID_DESC',
  CompaniesBySupportAgentSumDeliverToAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_DELIVER_TO_ASC',
  CompaniesBySupportAgentSumDeliverToDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_DELIVER_TO_DESC',
  CompaniesBySupportAgentSumPrimaryGroupAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_PRIMARY_GROUP_ASC',
  CompaniesBySupportAgentSumPrimaryGroupDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_PRIMARY_GROUP_DESC',
  CompaniesBySupportAgentSumRawMetaAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_RAW_META_ASC',
  CompaniesBySupportAgentSumRawMetaDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_RAW_META_DESC',
  CompaniesBySupportAgentSumBillableCompanyIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_BILLABLE_COMPANY_ID_ASC',
  CompaniesBySupportAgentSumBillableCompanyIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_BILLABLE_COMPANY_ID_DESC',
  CompaniesBySupportAgentSumRawRequestedAccessAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_RAW_REQUESTED_ACCESS_ASC',
  CompaniesBySupportAgentSumRawRequestedAccessDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_RAW_REQUESTED_ACCESS_DESC',
  CompaniesBySupportAgentSumLenderDealerCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_LENDER_DEALER_CODE_ASC',
  CompaniesBySupportAgentSumLenderDealerCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_LENDER_DEALER_CODE_DESC',
  CompaniesBySupportAgentSumGooglePlacesIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_GOOGLE_PLACES_ID_ASC',
  CompaniesBySupportAgentSumGooglePlacesIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_GOOGLE_PLACES_ID_DESC',
  CompaniesBySupportAgentSumLegalConsentAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_LEGAL_CONSENT_ASC',
  CompaniesBySupportAgentSumLegalConsentDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_LEGAL_CONSENT_DESC',
  CompaniesBySupportAgentSumLagoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_LAGO_ID_ASC',
  CompaniesBySupportAgentSumLagoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_LAGO_ID_DESC',
  CompaniesBySupportAgentSumStripeCustomerIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_STRIPE_CUSTOMER_ID_ASC',
  CompaniesBySupportAgentSumStripeCustomerIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_STRIPE_CUSTOMER_ID_DESC',
  CompaniesBySupportAgentSumSalesOrderIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_SALES_ORDER_ID_ASC',
  CompaniesBySupportAgentSumSalesOrderIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_SALES_ORDER_ID_DESC',
  CompaniesBySupportAgentSumIndustryAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_INDUSTRY_ASC',
  CompaniesBySupportAgentSumIndustryDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_INDUSTRY_DESC',
  CompaniesBySupportAgentSumManufacturerAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_MANUFACTURER_ASC',
  CompaniesBySupportAgentSumManufacturerDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_MANUFACTURER_DESC',
  CompaniesBySupportAgentSumCrmAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_CRM_ASC',
  CompaniesBySupportAgentSumCrmDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_CRM_DESC',
  CompaniesBySupportAgentSumAccountManagerAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_ACCOUNT_MANAGER_ASC',
  CompaniesBySupportAgentSumAccountManagerDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_ACCOUNT_MANAGER_DESC',
  CompaniesBySupportAgentSumSupportAgentAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_SUPPORT_AGENT_ASC',
  CompaniesBySupportAgentSumSupportAgentDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_SUPPORT_AGENT_DESC',
  CompaniesBySupportAgentSumOpportunityTypeAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_OPPORTUNITY_TYPE_ASC',
  CompaniesBySupportAgentSumOpportunityTypeDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_OPPORTUNITY_TYPE_DESC',
  CompaniesBySupportAgentSumNumberOfLocationsAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_NUMBER_OF_LOCATIONS_ASC',
  CompaniesBySupportAgentSumNumberOfLocationsDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_NUMBER_OF_LOCATIONS_DESC',
  CompaniesBySupportAgentSumWebsiteAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_WEBSITE_ASC',
  CompaniesBySupportAgentSumWebsiteDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_WEBSITE_DESC',
  CompaniesBySupportAgentSumDmsAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_DMS_ASC',
  CompaniesBySupportAgentSumDmsDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_DMS_DESC',
  CompaniesBySupportAgentSumWebProviderAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_WEB_PROVIDER_ASC',
  CompaniesBySupportAgentSumWebProviderDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_WEB_PROVIDER_DESC',
  CompaniesBySupportAgentSumCountryAsc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_COUNTRY_ASC',
  CompaniesBySupportAgentSumCountryDesc = 'COMPANIES_BY_SUPPORT_AGENT_SUM_COUNTRY_DESC',
  CompaniesBySupportAgentDistinctCountIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_ID_ASC',
  CompaniesBySupportAgentDistinctCountIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_ID_DESC',
  CompaniesBySupportAgentDistinctCountCreatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_CREATED_AT_ASC',
  CompaniesBySupportAgentDistinctCountCreatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_CREATED_AT_DESC',
  CompaniesBySupportAgentDistinctCountNameAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_NAME_ASC',
  CompaniesBySupportAgentDistinctCountNameDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_NAME_DESC',
  CompaniesBySupportAgentDistinctCountEquifaxCustomerNumberAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesBySupportAgentDistinctCountEquifaxCustomerNumberDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesBySupportAgentDistinctCountSecurityCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_SECURITY_CODE_ASC',
  CompaniesBySupportAgentDistinctCountSecurityCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_SECURITY_CODE_DESC',
  CompaniesBySupportAgentDistinctCountExternalIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_EXTERNAL_ID_ASC',
  CompaniesBySupportAgentDistinctCountExternalIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_EXTERNAL_ID_DESC',
  CompaniesBySupportAgentDistinctCountUpdatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompaniesBySupportAgentDistinctCountUpdatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompaniesBySupportAgentDistinctCountArchivedAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_ARCHIVED_ASC',
  CompaniesBySupportAgentDistinctCountArchivedDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_ARCHIVED_DESC',
  CompaniesBySupportAgentDistinctCountZohoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_ZOHO_ID_ASC',
  CompaniesBySupportAgentDistinctCountZohoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_ZOHO_ID_DESC',
  CompaniesBySupportAgentDistinctCountDeliverToAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_DELIVER_TO_ASC',
  CompaniesBySupportAgentDistinctCountDeliverToDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_DELIVER_TO_DESC',
  CompaniesBySupportAgentDistinctCountPrimaryGroupAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_PRIMARY_GROUP_ASC',
  CompaniesBySupportAgentDistinctCountPrimaryGroupDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_PRIMARY_GROUP_DESC',
  CompaniesBySupportAgentDistinctCountRawMetaAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_RAW_META_ASC',
  CompaniesBySupportAgentDistinctCountRawMetaDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_RAW_META_DESC',
  CompaniesBySupportAgentDistinctCountBillableCompanyIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_BILLABLE_COMPANY_ID_ASC',
  CompaniesBySupportAgentDistinctCountBillableCompanyIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_BILLABLE_COMPANY_ID_DESC',
  CompaniesBySupportAgentDistinctCountRawRequestedAccessAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_RAW_REQUESTED_ACCESS_ASC',
  CompaniesBySupportAgentDistinctCountRawRequestedAccessDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_RAW_REQUESTED_ACCESS_DESC',
  CompaniesBySupportAgentDistinctCountLenderDealerCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_LENDER_DEALER_CODE_ASC',
  CompaniesBySupportAgentDistinctCountLenderDealerCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_LENDER_DEALER_CODE_DESC',
  CompaniesBySupportAgentDistinctCountGooglePlacesIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_GOOGLE_PLACES_ID_ASC',
  CompaniesBySupportAgentDistinctCountGooglePlacesIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_GOOGLE_PLACES_ID_DESC',
  CompaniesBySupportAgentDistinctCountLegalConsentAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_LEGAL_CONSENT_ASC',
  CompaniesBySupportAgentDistinctCountLegalConsentDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_LEGAL_CONSENT_DESC',
  CompaniesBySupportAgentDistinctCountLagoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_LAGO_ID_ASC',
  CompaniesBySupportAgentDistinctCountLagoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_LAGO_ID_DESC',
  CompaniesBySupportAgentDistinctCountStripeCustomerIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_STRIPE_CUSTOMER_ID_ASC',
  CompaniesBySupportAgentDistinctCountStripeCustomerIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_STRIPE_CUSTOMER_ID_DESC',
  CompaniesBySupportAgentDistinctCountSalesOrderIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_SALES_ORDER_ID_ASC',
  CompaniesBySupportAgentDistinctCountSalesOrderIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_SALES_ORDER_ID_DESC',
  CompaniesBySupportAgentDistinctCountIndustryAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_INDUSTRY_ASC',
  CompaniesBySupportAgentDistinctCountIndustryDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_INDUSTRY_DESC',
  CompaniesBySupportAgentDistinctCountManufacturerAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_MANUFACTURER_ASC',
  CompaniesBySupportAgentDistinctCountManufacturerDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_MANUFACTURER_DESC',
  CompaniesBySupportAgentDistinctCountCrmAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_CRM_ASC',
  CompaniesBySupportAgentDistinctCountCrmDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_CRM_DESC',
  CompaniesBySupportAgentDistinctCountAccountManagerAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_ACCOUNT_MANAGER_ASC',
  CompaniesBySupportAgentDistinctCountAccountManagerDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_ACCOUNT_MANAGER_DESC',
  CompaniesBySupportAgentDistinctCountSupportAgentAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_SUPPORT_AGENT_ASC',
  CompaniesBySupportAgentDistinctCountSupportAgentDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_SUPPORT_AGENT_DESC',
  CompaniesBySupportAgentDistinctCountOpportunityTypeAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_OPPORTUNITY_TYPE_ASC',
  CompaniesBySupportAgentDistinctCountOpportunityTypeDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_OPPORTUNITY_TYPE_DESC',
  CompaniesBySupportAgentDistinctCountNumberOfLocationsAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_NUMBER_OF_LOCATIONS_ASC',
  CompaniesBySupportAgentDistinctCountNumberOfLocationsDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_NUMBER_OF_LOCATIONS_DESC',
  CompaniesBySupportAgentDistinctCountWebsiteAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_WEBSITE_ASC',
  CompaniesBySupportAgentDistinctCountWebsiteDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_WEBSITE_DESC',
  CompaniesBySupportAgentDistinctCountDmsAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_DMS_ASC',
  CompaniesBySupportAgentDistinctCountDmsDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_DMS_DESC',
  CompaniesBySupportAgentDistinctCountWebProviderAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_WEB_PROVIDER_ASC',
  CompaniesBySupportAgentDistinctCountWebProviderDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_WEB_PROVIDER_DESC',
  CompaniesBySupportAgentDistinctCountCountryAsc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_COUNTRY_ASC',
  CompaniesBySupportAgentDistinctCountCountryDesc = 'COMPANIES_BY_SUPPORT_AGENT_DISTINCT_COUNT_COUNTRY_DESC',
  CompaniesBySupportAgentMinIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_ID_ASC',
  CompaniesBySupportAgentMinIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_ID_DESC',
  CompaniesBySupportAgentMinCreatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_CREATED_AT_ASC',
  CompaniesBySupportAgentMinCreatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_CREATED_AT_DESC',
  CompaniesBySupportAgentMinNameAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_NAME_ASC',
  CompaniesBySupportAgentMinNameDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_NAME_DESC',
  CompaniesBySupportAgentMinEquifaxCustomerNumberAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesBySupportAgentMinEquifaxCustomerNumberDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesBySupportAgentMinSecurityCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_SECURITY_CODE_ASC',
  CompaniesBySupportAgentMinSecurityCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_SECURITY_CODE_DESC',
  CompaniesBySupportAgentMinExternalIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_EXTERNAL_ID_ASC',
  CompaniesBySupportAgentMinExternalIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_EXTERNAL_ID_DESC',
  CompaniesBySupportAgentMinUpdatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_UPDATED_AT_ASC',
  CompaniesBySupportAgentMinUpdatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_UPDATED_AT_DESC',
  CompaniesBySupportAgentMinArchivedAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_ARCHIVED_ASC',
  CompaniesBySupportAgentMinArchivedDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_ARCHIVED_DESC',
  CompaniesBySupportAgentMinZohoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_ZOHO_ID_ASC',
  CompaniesBySupportAgentMinZohoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_ZOHO_ID_DESC',
  CompaniesBySupportAgentMinDeliverToAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_DELIVER_TO_ASC',
  CompaniesBySupportAgentMinDeliverToDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_DELIVER_TO_DESC',
  CompaniesBySupportAgentMinPrimaryGroupAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_PRIMARY_GROUP_ASC',
  CompaniesBySupportAgentMinPrimaryGroupDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_PRIMARY_GROUP_DESC',
  CompaniesBySupportAgentMinRawMetaAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_RAW_META_ASC',
  CompaniesBySupportAgentMinRawMetaDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_RAW_META_DESC',
  CompaniesBySupportAgentMinBillableCompanyIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_BILLABLE_COMPANY_ID_ASC',
  CompaniesBySupportAgentMinBillableCompanyIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_BILLABLE_COMPANY_ID_DESC',
  CompaniesBySupportAgentMinRawRequestedAccessAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_RAW_REQUESTED_ACCESS_ASC',
  CompaniesBySupportAgentMinRawRequestedAccessDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_RAW_REQUESTED_ACCESS_DESC',
  CompaniesBySupportAgentMinLenderDealerCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_LENDER_DEALER_CODE_ASC',
  CompaniesBySupportAgentMinLenderDealerCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_LENDER_DEALER_CODE_DESC',
  CompaniesBySupportAgentMinGooglePlacesIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_GOOGLE_PLACES_ID_ASC',
  CompaniesBySupportAgentMinGooglePlacesIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_GOOGLE_PLACES_ID_DESC',
  CompaniesBySupportAgentMinLegalConsentAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_LEGAL_CONSENT_ASC',
  CompaniesBySupportAgentMinLegalConsentDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_LEGAL_CONSENT_DESC',
  CompaniesBySupportAgentMinLagoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_LAGO_ID_ASC',
  CompaniesBySupportAgentMinLagoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_LAGO_ID_DESC',
  CompaniesBySupportAgentMinStripeCustomerIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_STRIPE_CUSTOMER_ID_ASC',
  CompaniesBySupportAgentMinStripeCustomerIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_STRIPE_CUSTOMER_ID_DESC',
  CompaniesBySupportAgentMinSalesOrderIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_SALES_ORDER_ID_ASC',
  CompaniesBySupportAgentMinSalesOrderIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_SALES_ORDER_ID_DESC',
  CompaniesBySupportAgentMinIndustryAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_INDUSTRY_ASC',
  CompaniesBySupportAgentMinIndustryDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_INDUSTRY_DESC',
  CompaniesBySupportAgentMinManufacturerAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_MANUFACTURER_ASC',
  CompaniesBySupportAgentMinManufacturerDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_MANUFACTURER_DESC',
  CompaniesBySupportAgentMinCrmAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_CRM_ASC',
  CompaniesBySupportAgentMinCrmDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_CRM_DESC',
  CompaniesBySupportAgentMinAccountManagerAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_ACCOUNT_MANAGER_ASC',
  CompaniesBySupportAgentMinAccountManagerDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_ACCOUNT_MANAGER_DESC',
  CompaniesBySupportAgentMinSupportAgentAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_SUPPORT_AGENT_ASC',
  CompaniesBySupportAgentMinSupportAgentDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_SUPPORT_AGENT_DESC',
  CompaniesBySupportAgentMinOpportunityTypeAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_OPPORTUNITY_TYPE_ASC',
  CompaniesBySupportAgentMinOpportunityTypeDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_OPPORTUNITY_TYPE_DESC',
  CompaniesBySupportAgentMinNumberOfLocationsAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_NUMBER_OF_LOCATIONS_ASC',
  CompaniesBySupportAgentMinNumberOfLocationsDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_NUMBER_OF_LOCATIONS_DESC',
  CompaniesBySupportAgentMinWebsiteAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_WEBSITE_ASC',
  CompaniesBySupportAgentMinWebsiteDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_WEBSITE_DESC',
  CompaniesBySupportAgentMinDmsAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_DMS_ASC',
  CompaniesBySupportAgentMinDmsDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_DMS_DESC',
  CompaniesBySupportAgentMinWebProviderAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_WEB_PROVIDER_ASC',
  CompaniesBySupportAgentMinWebProviderDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_WEB_PROVIDER_DESC',
  CompaniesBySupportAgentMinCountryAsc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_COUNTRY_ASC',
  CompaniesBySupportAgentMinCountryDesc = 'COMPANIES_BY_SUPPORT_AGENT_MIN_COUNTRY_DESC',
  CompaniesBySupportAgentMaxIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_ID_ASC',
  CompaniesBySupportAgentMaxIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_ID_DESC',
  CompaniesBySupportAgentMaxCreatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_CREATED_AT_ASC',
  CompaniesBySupportAgentMaxCreatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_CREATED_AT_DESC',
  CompaniesBySupportAgentMaxNameAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_NAME_ASC',
  CompaniesBySupportAgentMaxNameDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_NAME_DESC',
  CompaniesBySupportAgentMaxEquifaxCustomerNumberAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesBySupportAgentMaxEquifaxCustomerNumberDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesBySupportAgentMaxSecurityCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_SECURITY_CODE_ASC',
  CompaniesBySupportAgentMaxSecurityCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_SECURITY_CODE_DESC',
  CompaniesBySupportAgentMaxExternalIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_EXTERNAL_ID_ASC',
  CompaniesBySupportAgentMaxExternalIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_EXTERNAL_ID_DESC',
  CompaniesBySupportAgentMaxUpdatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_UPDATED_AT_ASC',
  CompaniesBySupportAgentMaxUpdatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_UPDATED_AT_DESC',
  CompaniesBySupportAgentMaxArchivedAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_ARCHIVED_ASC',
  CompaniesBySupportAgentMaxArchivedDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_ARCHIVED_DESC',
  CompaniesBySupportAgentMaxZohoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_ZOHO_ID_ASC',
  CompaniesBySupportAgentMaxZohoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_ZOHO_ID_DESC',
  CompaniesBySupportAgentMaxDeliverToAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_DELIVER_TO_ASC',
  CompaniesBySupportAgentMaxDeliverToDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_DELIVER_TO_DESC',
  CompaniesBySupportAgentMaxPrimaryGroupAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_PRIMARY_GROUP_ASC',
  CompaniesBySupportAgentMaxPrimaryGroupDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_PRIMARY_GROUP_DESC',
  CompaniesBySupportAgentMaxRawMetaAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_RAW_META_ASC',
  CompaniesBySupportAgentMaxRawMetaDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_RAW_META_DESC',
  CompaniesBySupportAgentMaxBillableCompanyIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_BILLABLE_COMPANY_ID_ASC',
  CompaniesBySupportAgentMaxBillableCompanyIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_BILLABLE_COMPANY_ID_DESC',
  CompaniesBySupportAgentMaxRawRequestedAccessAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_RAW_REQUESTED_ACCESS_ASC',
  CompaniesBySupportAgentMaxRawRequestedAccessDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_RAW_REQUESTED_ACCESS_DESC',
  CompaniesBySupportAgentMaxLenderDealerCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_LENDER_DEALER_CODE_ASC',
  CompaniesBySupportAgentMaxLenderDealerCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_LENDER_DEALER_CODE_DESC',
  CompaniesBySupportAgentMaxGooglePlacesIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_GOOGLE_PLACES_ID_ASC',
  CompaniesBySupportAgentMaxGooglePlacesIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_GOOGLE_PLACES_ID_DESC',
  CompaniesBySupportAgentMaxLegalConsentAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_LEGAL_CONSENT_ASC',
  CompaniesBySupportAgentMaxLegalConsentDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_LEGAL_CONSENT_DESC',
  CompaniesBySupportAgentMaxLagoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_LAGO_ID_ASC',
  CompaniesBySupportAgentMaxLagoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_LAGO_ID_DESC',
  CompaniesBySupportAgentMaxStripeCustomerIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_STRIPE_CUSTOMER_ID_ASC',
  CompaniesBySupportAgentMaxStripeCustomerIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_STRIPE_CUSTOMER_ID_DESC',
  CompaniesBySupportAgentMaxSalesOrderIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_SALES_ORDER_ID_ASC',
  CompaniesBySupportAgentMaxSalesOrderIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_SALES_ORDER_ID_DESC',
  CompaniesBySupportAgentMaxIndustryAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_INDUSTRY_ASC',
  CompaniesBySupportAgentMaxIndustryDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_INDUSTRY_DESC',
  CompaniesBySupportAgentMaxManufacturerAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_MANUFACTURER_ASC',
  CompaniesBySupportAgentMaxManufacturerDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_MANUFACTURER_DESC',
  CompaniesBySupportAgentMaxCrmAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_CRM_ASC',
  CompaniesBySupportAgentMaxCrmDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_CRM_DESC',
  CompaniesBySupportAgentMaxAccountManagerAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_ACCOUNT_MANAGER_ASC',
  CompaniesBySupportAgentMaxAccountManagerDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_ACCOUNT_MANAGER_DESC',
  CompaniesBySupportAgentMaxSupportAgentAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_SUPPORT_AGENT_ASC',
  CompaniesBySupportAgentMaxSupportAgentDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_SUPPORT_AGENT_DESC',
  CompaniesBySupportAgentMaxOpportunityTypeAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_OPPORTUNITY_TYPE_ASC',
  CompaniesBySupportAgentMaxOpportunityTypeDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_OPPORTUNITY_TYPE_DESC',
  CompaniesBySupportAgentMaxNumberOfLocationsAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_NUMBER_OF_LOCATIONS_ASC',
  CompaniesBySupportAgentMaxNumberOfLocationsDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_NUMBER_OF_LOCATIONS_DESC',
  CompaniesBySupportAgentMaxWebsiteAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_WEBSITE_ASC',
  CompaniesBySupportAgentMaxWebsiteDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_WEBSITE_DESC',
  CompaniesBySupportAgentMaxDmsAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_DMS_ASC',
  CompaniesBySupportAgentMaxDmsDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_DMS_DESC',
  CompaniesBySupportAgentMaxWebProviderAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_WEB_PROVIDER_ASC',
  CompaniesBySupportAgentMaxWebProviderDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_WEB_PROVIDER_DESC',
  CompaniesBySupportAgentMaxCountryAsc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_COUNTRY_ASC',
  CompaniesBySupportAgentMaxCountryDesc = 'COMPANIES_BY_SUPPORT_AGENT_MAX_COUNTRY_DESC',
  CompaniesBySupportAgentAverageIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_ID_ASC',
  CompaniesBySupportAgentAverageIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_ID_DESC',
  CompaniesBySupportAgentAverageCreatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_CREATED_AT_ASC',
  CompaniesBySupportAgentAverageCreatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_CREATED_AT_DESC',
  CompaniesBySupportAgentAverageNameAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_NAME_ASC',
  CompaniesBySupportAgentAverageNameDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_NAME_DESC',
  CompaniesBySupportAgentAverageEquifaxCustomerNumberAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesBySupportAgentAverageEquifaxCustomerNumberDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesBySupportAgentAverageSecurityCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_SECURITY_CODE_ASC',
  CompaniesBySupportAgentAverageSecurityCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_SECURITY_CODE_DESC',
  CompaniesBySupportAgentAverageExternalIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_EXTERNAL_ID_ASC',
  CompaniesBySupportAgentAverageExternalIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_EXTERNAL_ID_DESC',
  CompaniesBySupportAgentAverageUpdatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_UPDATED_AT_ASC',
  CompaniesBySupportAgentAverageUpdatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_UPDATED_AT_DESC',
  CompaniesBySupportAgentAverageArchivedAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_ARCHIVED_ASC',
  CompaniesBySupportAgentAverageArchivedDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_ARCHIVED_DESC',
  CompaniesBySupportAgentAverageZohoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_ZOHO_ID_ASC',
  CompaniesBySupportAgentAverageZohoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_ZOHO_ID_DESC',
  CompaniesBySupportAgentAverageDeliverToAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_DELIVER_TO_ASC',
  CompaniesBySupportAgentAverageDeliverToDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_DELIVER_TO_DESC',
  CompaniesBySupportAgentAveragePrimaryGroupAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_PRIMARY_GROUP_ASC',
  CompaniesBySupportAgentAveragePrimaryGroupDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_PRIMARY_GROUP_DESC',
  CompaniesBySupportAgentAverageRawMetaAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_RAW_META_ASC',
  CompaniesBySupportAgentAverageRawMetaDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_RAW_META_DESC',
  CompaniesBySupportAgentAverageBillableCompanyIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_BILLABLE_COMPANY_ID_ASC',
  CompaniesBySupportAgentAverageBillableCompanyIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_BILLABLE_COMPANY_ID_DESC',
  CompaniesBySupportAgentAverageRawRequestedAccessAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_RAW_REQUESTED_ACCESS_ASC',
  CompaniesBySupportAgentAverageRawRequestedAccessDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_RAW_REQUESTED_ACCESS_DESC',
  CompaniesBySupportAgentAverageLenderDealerCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_LENDER_DEALER_CODE_ASC',
  CompaniesBySupportAgentAverageLenderDealerCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_LENDER_DEALER_CODE_DESC',
  CompaniesBySupportAgentAverageGooglePlacesIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_GOOGLE_PLACES_ID_ASC',
  CompaniesBySupportAgentAverageGooglePlacesIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_GOOGLE_PLACES_ID_DESC',
  CompaniesBySupportAgentAverageLegalConsentAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_LEGAL_CONSENT_ASC',
  CompaniesBySupportAgentAverageLegalConsentDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_LEGAL_CONSENT_DESC',
  CompaniesBySupportAgentAverageLagoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_LAGO_ID_ASC',
  CompaniesBySupportAgentAverageLagoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_LAGO_ID_DESC',
  CompaniesBySupportAgentAverageStripeCustomerIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_STRIPE_CUSTOMER_ID_ASC',
  CompaniesBySupportAgentAverageStripeCustomerIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_STRIPE_CUSTOMER_ID_DESC',
  CompaniesBySupportAgentAverageSalesOrderIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_SALES_ORDER_ID_ASC',
  CompaniesBySupportAgentAverageSalesOrderIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_SALES_ORDER_ID_DESC',
  CompaniesBySupportAgentAverageIndustryAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_INDUSTRY_ASC',
  CompaniesBySupportAgentAverageIndustryDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_INDUSTRY_DESC',
  CompaniesBySupportAgentAverageManufacturerAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_MANUFACTURER_ASC',
  CompaniesBySupportAgentAverageManufacturerDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_MANUFACTURER_DESC',
  CompaniesBySupportAgentAverageCrmAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_CRM_ASC',
  CompaniesBySupportAgentAverageCrmDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_CRM_DESC',
  CompaniesBySupportAgentAverageAccountManagerAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_ACCOUNT_MANAGER_ASC',
  CompaniesBySupportAgentAverageAccountManagerDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_ACCOUNT_MANAGER_DESC',
  CompaniesBySupportAgentAverageSupportAgentAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_SUPPORT_AGENT_ASC',
  CompaniesBySupportAgentAverageSupportAgentDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_SUPPORT_AGENT_DESC',
  CompaniesBySupportAgentAverageOpportunityTypeAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_OPPORTUNITY_TYPE_ASC',
  CompaniesBySupportAgentAverageOpportunityTypeDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_OPPORTUNITY_TYPE_DESC',
  CompaniesBySupportAgentAverageNumberOfLocationsAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_NUMBER_OF_LOCATIONS_ASC',
  CompaniesBySupportAgentAverageNumberOfLocationsDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_NUMBER_OF_LOCATIONS_DESC',
  CompaniesBySupportAgentAverageWebsiteAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_WEBSITE_ASC',
  CompaniesBySupportAgentAverageWebsiteDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_WEBSITE_DESC',
  CompaniesBySupportAgentAverageDmsAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_DMS_ASC',
  CompaniesBySupportAgentAverageDmsDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_DMS_DESC',
  CompaniesBySupportAgentAverageWebProviderAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_WEB_PROVIDER_ASC',
  CompaniesBySupportAgentAverageWebProviderDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_WEB_PROVIDER_DESC',
  CompaniesBySupportAgentAverageCountryAsc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_COUNTRY_ASC',
  CompaniesBySupportAgentAverageCountryDesc = 'COMPANIES_BY_SUPPORT_AGENT_AVERAGE_COUNTRY_DESC',
  CompaniesBySupportAgentStddevSampleIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_ID_ASC',
  CompaniesBySupportAgentStddevSampleIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_ID_DESC',
  CompaniesBySupportAgentStddevSampleCreatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompaniesBySupportAgentStddevSampleCreatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompaniesBySupportAgentStddevSampleNameAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_NAME_ASC',
  CompaniesBySupportAgentStddevSampleNameDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_NAME_DESC',
  CompaniesBySupportAgentStddevSampleEquifaxCustomerNumberAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesBySupportAgentStddevSampleEquifaxCustomerNumberDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesBySupportAgentStddevSampleSecurityCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_SECURITY_CODE_ASC',
  CompaniesBySupportAgentStddevSampleSecurityCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_SECURITY_CODE_DESC',
  CompaniesBySupportAgentStddevSampleExternalIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_EXTERNAL_ID_ASC',
  CompaniesBySupportAgentStddevSampleExternalIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_EXTERNAL_ID_DESC',
  CompaniesBySupportAgentStddevSampleUpdatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompaniesBySupportAgentStddevSampleUpdatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompaniesBySupportAgentStddevSampleArchivedAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_ARCHIVED_ASC',
  CompaniesBySupportAgentStddevSampleArchivedDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_ARCHIVED_DESC',
  CompaniesBySupportAgentStddevSampleZohoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_ZOHO_ID_ASC',
  CompaniesBySupportAgentStddevSampleZohoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_ZOHO_ID_DESC',
  CompaniesBySupportAgentStddevSampleDeliverToAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_DELIVER_TO_ASC',
  CompaniesBySupportAgentStddevSampleDeliverToDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_DELIVER_TO_DESC',
  CompaniesBySupportAgentStddevSamplePrimaryGroupAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_PRIMARY_GROUP_ASC',
  CompaniesBySupportAgentStddevSamplePrimaryGroupDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_PRIMARY_GROUP_DESC',
  CompaniesBySupportAgentStddevSampleRawMetaAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_RAW_META_ASC',
  CompaniesBySupportAgentStddevSampleRawMetaDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_RAW_META_DESC',
  CompaniesBySupportAgentStddevSampleBillableCompanyIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_BILLABLE_COMPANY_ID_ASC',
  CompaniesBySupportAgentStddevSampleBillableCompanyIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_BILLABLE_COMPANY_ID_DESC',
  CompaniesBySupportAgentStddevSampleRawRequestedAccessAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_RAW_REQUESTED_ACCESS_ASC',
  CompaniesBySupportAgentStddevSampleRawRequestedAccessDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_RAW_REQUESTED_ACCESS_DESC',
  CompaniesBySupportAgentStddevSampleLenderDealerCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_LENDER_DEALER_CODE_ASC',
  CompaniesBySupportAgentStddevSampleLenderDealerCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_LENDER_DEALER_CODE_DESC',
  CompaniesBySupportAgentStddevSampleGooglePlacesIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_GOOGLE_PLACES_ID_ASC',
  CompaniesBySupportAgentStddevSampleGooglePlacesIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_GOOGLE_PLACES_ID_DESC',
  CompaniesBySupportAgentStddevSampleLegalConsentAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_LEGAL_CONSENT_ASC',
  CompaniesBySupportAgentStddevSampleLegalConsentDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_LEGAL_CONSENT_DESC',
  CompaniesBySupportAgentStddevSampleLagoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_LAGO_ID_ASC',
  CompaniesBySupportAgentStddevSampleLagoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_LAGO_ID_DESC',
  CompaniesBySupportAgentStddevSampleStripeCustomerIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_STRIPE_CUSTOMER_ID_ASC',
  CompaniesBySupportAgentStddevSampleStripeCustomerIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_STRIPE_CUSTOMER_ID_DESC',
  CompaniesBySupportAgentStddevSampleSalesOrderIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_SALES_ORDER_ID_ASC',
  CompaniesBySupportAgentStddevSampleSalesOrderIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_SALES_ORDER_ID_DESC',
  CompaniesBySupportAgentStddevSampleIndustryAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_INDUSTRY_ASC',
  CompaniesBySupportAgentStddevSampleIndustryDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_INDUSTRY_DESC',
  CompaniesBySupportAgentStddevSampleManufacturerAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_MANUFACTURER_ASC',
  CompaniesBySupportAgentStddevSampleManufacturerDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_MANUFACTURER_DESC',
  CompaniesBySupportAgentStddevSampleCrmAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_CRM_ASC',
  CompaniesBySupportAgentStddevSampleCrmDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_CRM_DESC',
  CompaniesBySupportAgentStddevSampleAccountManagerAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_ACCOUNT_MANAGER_ASC',
  CompaniesBySupportAgentStddevSampleAccountManagerDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_ACCOUNT_MANAGER_DESC',
  CompaniesBySupportAgentStddevSampleSupportAgentAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_SUPPORT_AGENT_ASC',
  CompaniesBySupportAgentStddevSampleSupportAgentDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_SUPPORT_AGENT_DESC',
  CompaniesBySupportAgentStddevSampleOpportunityTypeAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_OPPORTUNITY_TYPE_ASC',
  CompaniesBySupportAgentStddevSampleOpportunityTypeDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_OPPORTUNITY_TYPE_DESC',
  CompaniesBySupportAgentStddevSampleNumberOfLocationsAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_NUMBER_OF_LOCATIONS_ASC',
  CompaniesBySupportAgentStddevSampleNumberOfLocationsDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_NUMBER_OF_LOCATIONS_DESC',
  CompaniesBySupportAgentStddevSampleWebsiteAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_WEBSITE_ASC',
  CompaniesBySupportAgentStddevSampleWebsiteDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_WEBSITE_DESC',
  CompaniesBySupportAgentStddevSampleDmsAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_DMS_ASC',
  CompaniesBySupportAgentStddevSampleDmsDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_DMS_DESC',
  CompaniesBySupportAgentStddevSampleWebProviderAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_WEB_PROVIDER_ASC',
  CompaniesBySupportAgentStddevSampleWebProviderDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_WEB_PROVIDER_DESC',
  CompaniesBySupportAgentStddevSampleCountryAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_COUNTRY_ASC',
  CompaniesBySupportAgentStddevSampleCountryDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_SAMPLE_COUNTRY_DESC',
  CompaniesBySupportAgentStddevPopulationIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_ID_ASC',
  CompaniesBySupportAgentStddevPopulationIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_ID_DESC',
  CompaniesBySupportAgentStddevPopulationCreatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_CREATED_AT_ASC',
  CompaniesBySupportAgentStddevPopulationCreatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_CREATED_AT_DESC',
  CompaniesBySupportAgentStddevPopulationNameAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_NAME_ASC',
  CompaniesBySupportAgentStddevPopulationNameDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_NAME_DESC',
  CompaniesBySupportAgentStddevPopulationEquifaxCustomerNumberAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesBySupportAgentStddevPopulationEquifaxCustomerNumberDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesBySupportAgentStddevPopulationSecurityCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_SECURITY_CODE_ASC',
  CompaniesBySupportAgentStddevPopulationSecurityCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_SECURITY_CODE_DESC',
  CompaniesBySupportAgentStddevPopulationExternalIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_EXTERNAL_ID_ASC',
  CompaniesBySupportAgentStddevPopulationExternalIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_EXTERNAL_ID_DESC',
  CompaniesBySupportAgentStddevPopulationUpdatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompaniesBySupportAgentStddevPopulationUpdatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompaniesBySupportAgentStddevPopulationArchivedAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_ARCHIVED_ASC',
  CompaniesBySupportAgentStddevPopulationArchivedDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_ARCHIVED_DESC',
  CompaniesBySupportAgentStddevPopulationZohoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_ZOHO_ID_ASC',
  CompaniesBySupportAgentStddevPopulationZohoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_ZOHO_ID_DESC',
  CompaniesBySupportAgentStddevPopulationDeliverToAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_DELIVER_TO_ASC',
  CompaniesBySupportAgentStddevPopulationDeliverToDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_DELIVER_TO_DESC',
  CompaniesBySupportAgentStddevPopulationPrimaryGroupAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_PRIMARY_GROUP_ASC',
  CompaniesBySupportAgentStddevPopulationPrimaryGroupDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_PRIMARY_GROUP_DESC',
  CompaniesBySupportAgentStddevPopulationRawMetaAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_RAW_META_ASC',
  CompaniesBySupportAgentStddevPopulationRawMetaDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_RAW_META_DESC',
  CompaniesBySupportAgentStddevPopulationBillableCompanyIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_BILLABLE_COMPANY_ID_ASC',
  CompaniesBySupportAgentStddevPopulationBillableCompanyIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_BILLABLE_COMPANY_ID_DESC',
  CompaniesBySupportAgentStddevPopulationRawRequestedAccessAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_RAW_REQUESTED_ACCESS_ASC',
  CompaniesBySupportAgentStddevPopulationRawRequestedAccessDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_RAW_REQUESTED_ACCESS_DESC',
  CompaniesBySupportAgentStddevPopulationLenderDealerCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_LENDER_DEALER_CODE_ASC',
  CompaniesBySupportAgentStddevPopulationLenderDealerCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_LENDER_DEALER_CODE_DESC',
  CompaniesBySupportAgentStddevPopulationGooglePlacesIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_GOOGLE_PLACES_ID_ASC',
  CompaniesBySupportAgentStddevPopulationGooglePlacesIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_GOOGLE_PLACES_ID_DESC',
  CompaniesBySupportAgentStddevPopulationLegalConsentAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_LEGAL_CONSENT_ASC',
  CompaniesBySupportAgentStddevPopulationLegalConsentDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_LEGAL_CONSENT_DESC',
  CompaniesBySupportAgentStddevPopulationLagoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_LAGO_ID_ASC',
  CompaniesBySupportAgentStddevPopulationLagoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_LAGO_ID_DESC',
  CompaniesBySupportAgentStddevPopulationStripeCustomerIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_STRIPE_CUSTOMER_ID_ASC',
  CompaniesBySupportAgentStddevPopulationStripeCustomerIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_STRIPE_CUSTOMER_ID_DESC',
  CompaniesBySupportAgentStddevPopulationSalesOrderIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_SALES_ORDER_ID_ASC',
  CompaniesBySupportAgentStddevPopulationSalesOrderIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_SALES_ORDER_ID_DESC',
  CompaniesBySupportAgentStddevPopulationIndustryAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_INDUSTRY_ASC',
  CompaniesBySupportAgentStddevPopulationIndustryDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_INDUSTRY_DESC',
  CompaniesBySupportAgentStddevPopulationManufacturerAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_MANUFACTURER_ASC',
  CompaniesBySupportAgentStddevPopulationManufacturerDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_MANUFACTURER_DESC',
  CompaniesBySupportAgentStddevPopulationCrmAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_CRM_ASC',
  CompaniesBySupportAgentStddevPopulationCrmDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_CRM_DESC',
  CompaniesBySupportAgentStddevPopulationAccountManagerAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_ACCOUNT_MANAGER_ASC',
  CompaniesBySupportAgentStddevPopulationAccountManagerDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_ACCOUNT_MANAGER_DESC',
  CompaniesBySupportAgentStddevPopulationSupportAgentAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_SUPPORT_AGENT_ASC',
  CompaniesBySupportAgentStddevPopulationSupportAgentDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_SUPPORT_AGENT_DESC',
  CompaniesBySupportAgentStddevPopulationOpportunityTypeAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_OPPORTUNITY_TYPE_ASC',
  CompaniesBySupportAgentStddevPopulationOpportunityTypeDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_OPPORTUNITY_TYPE_DESC',
  CompaniesBySupportAgentStddevPopulationNumberOfLocationsAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_NUMBER_OF_LOCATIONS_ASC',
  CompaniesBySupportAgentStddevPopulationNumberOfLocationsDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_NUMBER_OF_LOCATIONS_DESC',
  CompaniesBySupportAgentStddevPopulationWebsiteAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_WEBSITE_ASC',
  CompaniesBySupportAgentStddevPopulationWebsiteDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_WEBSITE_DESC',
  CompaniesBySupportAgentStddevPopulationDmsAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_DMS_ASC',
  CompaniesBySupportAgentStddevPopulationDmsDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_DMS_DESC',
  CompaniesBySupportAgentStddevPopulationWebProviderAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_WEB_PROVIDER_ASC',
  CompaniesBySupportAgentStddevPopulationWebProviderDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_WEB_PROVIDER_DESC',
  CompaniesBySupportAgentStddevPopulationCountryAsc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_COUNTRY_ASC',
  CompaniesBySupportAgentStddevPopulationCountryDesc = 'COMPANIES_BY_SUPPORT_AGENT_STDDEV_POPULATION_COUNTRY_DESC',
  CompaniesBySupportAgentVarianceSampleIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_ID_ASC',
  CompaniesBySupportAgentVarianceSampleIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_ID_DESC',
  CompaniesBySupportAgentVarianceSampleCreatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompaniesBySupportAgentVarianceSampleCreatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompaniesBySupportAgentVarianceSampleNameAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_NAME_ASC',
  CompaniesBySupportAgentVarianceSampleNameDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_NAME_DESC',
  CompaniesBySupportAgentVarianceSampleEquifaxCustomerNumberAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesBySupportAgentVarianceSampleEquifaxCustomerNumberDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesBySupportAgentVarianceSampleSecurityCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_SECURITY_CODE_ASC',
  CompaniesBySupportAgentVarianceSampleSecurityCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_SECURITY_CODE_DESC',
  CompaniesBySupportAgentVarianceSampleExternalIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_EXTERNAL_ID_ASC',
  CompaniesBySupportAgentVarianceSampleExternalIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_EXTERNAL_ID_DESC',
  CompaniesBySupportAgentVarianceSampleUpdatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompaniesBySupportAgentVarianceSampleUpdatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompaniesBySupportAgentVarianceSampleArchivedAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_ARCHIVED_ASC',
  CompaniesBySupportAgentVarianceSampleArchivedDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_ARCHIVED_DESC',
  CompaniesBySupportAgentVarianceSampleZohoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_ZOHO_ID_ASC',
  CompaniesBySupportAgentVarianceSampleZohoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_ZOHO_ID_DESC',
  CompaniesBySupportAgentVarianceSampleDeliverToAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_DELIVER_TO_ASC',
  CompaniesBySupportAgentVarianceSampleDeliverToDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_DELIVER_TO_DESC',
  CompaniesBySupportAgentVarianceSamplePrimaryGroupAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_PRIMARY_GROUP_ASC',
  CompaniesBySupportAgentVarianceSamplePrimaryGroupDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_PRIMARY_GROUP_DESC',
  CompaniesBySupportAgentVarianceSampleRawMetaAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_RAW_META_ASC',
  CompaniesBySupportAgentVarianceSampleRawMetaDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_RAW_META_DESC',
  CompaniesBySupportAgentVarianceSampleBillableCompanyIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_BILLABLE_COMPANY_ID_ASC',
  CompaniesBySupportAgentVarianceSampleBillableCompanyIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_BILLABLE_COMPANY_ID_DESC',
  CompaniesBySupportAgentVarianceSampleRawRequestedAccessAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_RAW_REQUESTED_ACCESS_ASC',
  CompaniesBySupportAgentVarianceSampleRawRequestedAccessDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_RAW_REQUESTED_ACCESS_DESC',
  CompaniesBySupportAgentVarianceSampleLenderDealerCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_LENDER_DEALER_CODE_ASC',
  CompaniesBySupportAgentVarianceSampleLenderDealerCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_LENDER_DEALER_CODE_DESC',
  CompaniesBySupportAgentVarianceSampleGooglePlacesIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_GOOGLE_PLACES_ID_ASC',
  CompaniesBySupportAgentVarianceSampleGooglePlacesIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_GOOGLE_PLACES_ID_DESC',
  CompaniesBySupportAgentVarianceSampleLegalConsentAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_LEGAL_CONSENT_ASC',
  CompaniesBySupportAgentVarianceSampleLegalConsentDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_LEGAL_CONSENT_DESC',
  CompaniesBySupportAgentVarianceSampleLagoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_LAGO_ID_ASC',
  CompaniesBySupportAgentVarianceSampleLagoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_LAGO_ID_DESC',
  CompaniesBySupportAgentVarianceSampleStripeCustomerIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_STRIPE_CUSTOMER_ID_ASC',
  CompaniesBySupportAgentVarianceSampleStripeCustomerIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_STRIPE_CUSTOMER_ID_DESC',
  CompaniesBySupportAgentVarianceSampleSalesOrderIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_SALES_ORDER_ID_ASC',
  CompaniesBySupportAgentVarianceSampleSalesOrderIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_SALES_ORDER_ID_DESC',
  CompaniesBySupportAgentVarianceSampleIndustryAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_INDUSTRY_ASC',
  CompaniesBySupportAgentVarianceSampleIndustryDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_INDUSTRY_DESC',
  CompaniesBySupportAgentVarianceSampleManufacturerAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_MANUFACTURER_ASC',
  CompaniesBySupportAgentVarianceSampleManufacturerDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_MANUFACTURER_DESC',
  CompaniesBySupportAgentVarianceSampleCrmAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_CRM_ASC',
  CompaniesBySupportAgentVarianceSampleCrmDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_CRM_DESC',
  CompaniesBySupportAgentVarianceSampleAccountManagerAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_ACCOUNT_MANAGER_ASC',
  CompaniesBySupportAgentVarianceSampleAccountManagerDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_ACCOUNT_MANAGER_DESC',
  CompaniesBySupportAgentVarianceSampleSupportAgentAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_SUPPORT_AGENT_ASC',
  CompaniesBySupportAgentVarianceSampleSupportAgentDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_SUPPORT_AGENT_DESC',
  CompaniesBySupportAgentVarianceSampleOpportunityTypeAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_OPPORTUNITY_TYPE_ASC',
  CompaniesBySupportAgentVarianceSampleOpportunityTypeDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_OPPORTUNITY_TYPE_DESC',
  CompaniesBySupportAgentVarianceSampleNumberOfLocationsAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_NUMBER_OF_LOCATIONS_ASC',
  CompaniesBySupportAgentVarianceSampleNumberOfLocationsDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_NUMBER_OF_LOCATIONS_DESC',
  CompaniesBySupportAgentVarianceSampleWebsiteAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_WEBSITE_ASC',
  CompaniesBySupportAgentVarianceSampleWebsiteDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_WEBSITE_DESC',
  CompaniesBySupportAgentVarianceSampleDmsAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_DMS_ASC',
  CompaniesBySupportAgentVarianceSampleDmsDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_DMS_DESC',
  CompaniesBySupportAgentVarianceSampleWebProviderAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_WEB_PROVIDER_ASC',
  CompaniesBySupportAgentVarianceSampleWebProviderDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_WEB_PROVIDER_DESC',
  CompaniesBySupportAgentVarianceSampleCountryAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_COUNTRY_ASC',
  CompaniesBySupportAgentVarianceSampleCountryDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_SAMPLE_COUNTRY_DESC',
  CompaniesBySupportAgentVariancePopulationIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_ID_ASC',
  CompaniesBySupportAgentVariancePopulationIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_ID_DESC',
  CompaniesBySupportAgentVariancePopulationCreatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompaniesBySupportAgentVariancePopulationCreatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompaniesBySupportAgentVariancePopulationNameAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_NAME_ASC',
  CompaniesBySupportAgentVariancePopulationNameDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_NAME_DESC',
  CompaniesBySupportAgentVariancePopulationEquifaxCustomerNumberAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_EQUIFAX_CUSTOMER_NUMBER_ASC',
  CompaniesBySupportAgentVariancePopulationEquifaxCustomerNumberDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_EQUIFAX_CUSTOMER_NUMBER_DESC',
  CompaniesBySupportAgentVariancePopulationSecurityCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_SECURITY_CODE_ASC',
  CompaniesBySupportAgentVariancePopulationSecurityCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_SECURITY_CODE_DESC',
  CompaniesBySupportAgentVariancePopulationExternalIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_EXTERNAL_ID_ASC',
  CompaniesBySupportAgentVariancePopulationExternalIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_EXTERNAL_ID_DESC',
  CompaniesBySupportAgentVariancePopulationUpdatedAtAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompaniesBySupportAgentVariancePopulationUpdatedAtDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompaniesBySupportAgentVariancePopulationArchivedAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_ARCHIVED_ASC',
  CompaniesBySupportAgentVariancePopulationArchivedDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_ARCHIVED_DESC',
  CompaniesBySupportAgentVariancePopulationZohoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_ZOHO_ID_ASC',
  CompaniesBySupportAgentVariancePopulationZohoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_ZOHO_ID_DESC',
  CompaniesBySupportAgentVariancePopulationDeliverToAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_DELIVER_TO_ASC',
  CompaniesBySupportAgentVariancePopulationDeliverToDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_DELIVER_TO_DESC',
  CompaniesBySupportAgentVariancePopulationPrimaryGroupAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_PRIMARY_GROUP_ASC',
  CompaniesBySupportAgentVariancePopulationPrimaryGroupDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_PRIMARY_GROUP_DESC',
  CompaniesBySupportAgentVariancePopulationRawMetaAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_RAW_META_ASC',
  CompaniesBySupportAgentVariancePopulationRawMetaDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_RAW_META_DESC',
  CompaniesBySupportAgentVariancePopulationBillableCompanyIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_BILLABLE_COMPANY_ID_ASC',
  CompaniesBySupportAgentVariancePopulationBillableCompanyIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_BILLABLE_COMPANY_ID_DESC',
  CompaniesBySupportAgentVariancePopulationRawRequestedAccessAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_RAW_REQUESTED_ACCESS_ASC',
  CompaniesBySupportAgentVariancePopulationRawRequestedAccessDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_RAW_REQUESTED_ACCESS_DESC',
  CompaniesBySupportAgentVariancePopulationLenderDealerCodeAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_LENDER_DEALER_CODE_ASC',
  CompaniesBySupportAgentVariancePopulationLenderDealerCodeDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_LENDER_DEALER_CODE_DESC',
  CompaniesBySupportAgentVariancePopulationGooglePlacesIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_GOOGLE_PLACES_ID_ASC',
  CompaniesBySupportAgentVariancePopulationGooglePlacesIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_GOOGLE_PLACES_ID_DESC',
  CompaniesBySupportAgentVariancePopulationLegalConsentAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_LEGAL_CONSENT_ASC',
  CompaniesBySupportAgentVariancePopulationLegalConsentDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_LEGAL_CONSENT_DESC',
  CompaniesBySupportAgentVariancePopulationLagoIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_LAGO_ID_ASC',
  CompaniesBySupportAgentVariancePopulationLagoIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_LAGO_ID_DESC',
  CompaniesBySupportAgentVariancePopulationStripeCustomerIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_STRIPE_CUSTOMER_ID_ASC',
  CompaniesBySupportAgentVariancePopulationStripeCustomerIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_STRIPE_CUSTOMER_ID_DESC',
  CompaniesBySupportAgentVariancePopulationSalesOrderIdAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_SALES_ORDER_ID_ASC',
  CompaniesBySupportAgentVariancePopulationSalesOrderIdDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_SALES_ORDER_ID_DESC',
  CompaniesBySupportAgentVariancePopulationIndustryAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_INDUSTRY_ASC',
  CompaniesBySupportAgentVariancePopulationIndustryDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_INDUSTRY_DESC',
  CompaniesBySupportAgentVariancePopulationManufacturerAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_MANUFACTURER_ASC',
  CompaniesBySupportAgentVariancePopulationManufacturerDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_MANUFACTURER_DESC',
  CompaniesBySupportAgentVariancePopulationCrmAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_CRM_ASC',
  CompaniesBySupportAgentVariancePopulationCrmDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_CRM_DESC',
  CompaniesBySupportAgentVariancePopulationAccountManagerAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_ACCOUNT_MANAGER_ASC',
  CompaniesBySupportAgentVariancePopulationAccountManagerDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_ACCOUNT_MANAGER_DESC',
  CompaniesBySupportAgentVariancePopulationSupportAgentAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_SUPPORT_AGENT_ASC',
  CompaniesBySupportAgentVariancePopulationSupportAgentDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_SUPPORT_AGENT_DESC',
  CompaniesBySupportAgentVariancePopulationOpportunityTypeAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_OPPORTUNITY_TYPE_ASC',
  CompaniesBySupportAgentVariancePopulationOpportunityTypeDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_OPPORTUNITY_TYPE_DESC',
  CompaniesBySupportAgentVariancePopulationNumberOfLocationsAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_NUMBER_OF_LOCATIONS_ASC',
  CompaniesBySupportAgentVariancePopulationNumberOfLocationsDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_NUMBER_OF_LOCATIONS_DESC',
  CompaniesBySupportAgentVariancePopulationWebsiteAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_WEBSITE_ASC',
  CompaniesBySupportAgentVariancePopulationWebsiteDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_WEBSITE_DESC',
  CompaniesBySupportAgentVariancePopulationDmsAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_DMS_ASC',
  CompaniesBySupportAgentVariancePopulationDmsDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_DMS_DESC',
  CompaniesBySupportAgentVariancePopulationWebProviderAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_WEB_PROVIDER_ASC',
  CompaniesBySupportAgentVariancePopulationWebProviderDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_WEB_PROVIDER_DESC',
  CompaniesBySupportAgentVariancePopulationCountryAsc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_COUNTRY_ASC',
  CompaniesBySupportAgentVariancePopulationCountryDesc = 'COMPANIES_BY_SUPPORT_AGENT_VARIANCE_POPULATION_COUNTRY_DESC',
  UserActivitiesCountAsc = 'USER_ACTIVITIES_COUNT_ASC',
  UserActivitiesCountDesc = 'USER_ACTIVITIES_COUNT_DESC',
  UserActivitiesSumIdAsc = 'USER_ACTIVITIES_SUM_ID_ASC',
  UserActivitiesSumIdDesc = 'USER_ACTIVITIES_SUM_ID_DESC',
  UserActivitiesSumUsernameAsc = 'USER_ACTIVITIES_SUM_USERNAME_ASC',
  UserActivitiesSumUsernameDesc = 'USER_ACTIVITIES_SUM_USERNAME_DESC',
  UserActivitiesSumClaimsAsc = 'USER_ACTIVITIES_SUM_CLAIMS_ASC',
  UserActivitiesSumClaimsDesc = 'USER_ACTIVITIES_SUM_CLAIMS_DESC',
  UserActivitiesSumMetaAsc = 'USER_ACTIVITIES_SUM_META_ASC',
  UserActivitiesSumMetaDesc = 'USER_ACTIVITIES_SUM_META_DESC',
  UserActivitiesSumTypeAsc = 'USER_ACTIVITIES_SUM_TYPE_ASC',
  UserActivitiesSumTypeDesc = 'USER_ACTIVITIES_SUM_TYPE_DESC',
  UserActivitiesSumCreatedAtAsc = 'USER_ACTIVITIES_SUM_CREATED_AT_ASC',
  UserActivitiesSumCreatedAtDesc = 'USER_ACTIVITIES_SUM_CREATED_AT_DESC',
  UserActivitiesSumUpdatedAtAsc = 'USER_ACTIVITIES_SUM_UPDATED_AT_ASC',
  UserActivitiesSumUpdatedAtDesc = 'USER_ACTIVITIES_SUM_UPDATED_AT_DESC',
  UserActivitiesSumSearchTextAsc = 'USER_ACTIVITIES_SUM_SEARCH_TEXT_ASC',
  UserActivitiesSumSearchTextDesc = 'USER_ACTIVITIES_SUM_SEARCH_TEXT_DESC',
  UserActivitiesSumUserIdAsc = 'USER_ACTIVITIES_SUM_USER_ID_ASC',
  UserActivitiesSumUserIdDesc = 'USER_ACTIVITIES_SUM_USER_ID_DESC',
  UserActivitiesDistinctCountIdAsc = 'USER_ACTIVITIES_DISTINCT_COUNT_ID_ASC',
  UserActivitiesDistinctCountIdDesc = 'USER_ACTIVITIES_DISTINCT_COUNT_ID_DESC',
  UserActivitiesDistinctCountUsernameAsc = 'USER_ACTIVITIES_DISTINCT_COUNT_USERNAME_ASC',
  UserActivitiesDistinctCountUsernameDesc = 'USER_ACTIVITIES_DISTINCT_COUNT_USERNAME_DESC',
  UserActivitiesDistinctCountClaimsAsc = 'USER_ACTIVITIES_DISTINCT_COUNT_CLAIMS_ASC',
  UserActivitiesDistinctCountClaimsDesc = 'USER_ACTIVITIES_DISTINCT_COUNT_CLAIMS_DESC',
  UserActivitiesDistinctCountMetaAsc = 'USER_ACTIVITIES_DISTINCT_COUNT_META_ASC',
  UserActivitiesDistinctCountMetaDesc = 'USER_ACTIVITIES_DISTINCT_COUNT_META_DESC',
  UserActivitiesDistinctCountTypeAsc = 'USER_ACTIVITIES_DISTINCT_COUNT_TYPE_ASC',
  UserActivitiesDistinctCountTypeDesc = 'USER_ACTIVITIES_DISTINCT_COUNT_TYPE_DESC',
  UserActivitiesDistinctCountCreatedAtAsc = 'USER_ACTIVITIES_DISTINCT_COUNT_CREATED_AT_ASC',
  UserActivitiesDistinctCountCreatedAtDesc = 'USER_ACTIVITIES_DISTINCT_COUNT_CREATED_AT_DESC',
  UserActivitiesDistinctCountUpdatedAtAsc = 'USER_ACTIVITIES_DISTINCT_COUNT_UPDATED_AT_ASC',
  UserActivitiesDistinctCountUpdatedAtDesc = 'USER_ACTIVITIES_DISTINCT_COUNT_UPDATED_AT_DESC',
  UserActivitiesDistinctCountSearchTextAsc = 'USER_ACTIVITIES_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  UserActivitiesDistinctCountSearchTextDesc = 'USER_ACTIVITIES_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  UserActivitiesDistinctCountUserIdAsc = 'USER_ACTIVITIES_DISTINCT_COUNT_USER_ID_ASC',
  UserActivitiesDistinctCountUserIdDesc = 'USER_ACTIVITIES_DISTINCT_COUNT_USER_ID_DESC',
  UserActivitiesMinIdAsc = 'USER_ACTIVITIES_MIN_ID_ASC',
  UserActivitiesMinIdDesc = 'USER_ACTIVITIES_MIN_ID_DESC',
  UserActivitiesMinUsernameAsc = 'USER_ACTIVITIES_MIN_USERNAME_ASC',
  UserActivitiesMinUsernameDesc = 'USER_ACTIVITIES_MIN_USERNAME_DESC',
  UserActivitiesMinClaimsAsc = 'USER_ACTIVITIES_MIN_CLAIMS_ASC',
  UserActivitiesMinClaimsDesc = 'USER_ACTIVITIES_MIN_CLAIMS_DESC',
  UserActivitiesMinMetaAsc = 'USER_ACTIVITIES_MIN_META_ASC',
  UserActivitiesMinMetaDesc = 'USER_ACTIVITIES_MIN_META_DESC',
  UserActivitiesMinTypeAsc = 'USER_ACTIVITIES_MIN_TYPE_ASC',
  UserActivitiesMinTypeDesc = 'USER_ACTIVITIES_MIN_TYPE_DESC',
  UserActivitiesMinCreatedAtAsc = 'USER_ACTIVITIES_MIN_CREATED_AT_ASC',
  UserActivitiesMinCreatedAtDesc = 'USER_ACTIVITIES_MIN_CREATED_AT_DESC',
  UserActivitiesMinUpdatedAtAsc = 'USER_ACTIVITIES_MIN_UPDATED_AT_ASC',
  UserActivitiesMinUpdatedAtDesc = 'USER_ACTIVITIES_MIN_UPDATED_AT_DESC',
  UserActivitiesMinSearchTextAsc = 'USER_ACTIVITIES_MIN_SEARCH_TEXT_ASC',
  UserActivitiesMinSearchTextDesc = 'USER_ACTIVITIES_MIN_SEARCH_TEXT_DESC',
  UserActivitiesMinUserIdAsc = 'USER_ACTIVITIES_MIN_USER_ID_ASC',
  UserActivitiesMinUserIdDesc = 'USER_ACTIVITIES_MIN_USER_ID_DESC',
  UserActivitiesMaxIdAsc = 'USER_ACTIVITIES_MAX_ID_ASC',
  UserActivitiesMaxIdDesc = 'USER_ACTIVITIES_MAX_ID_DESC',
  UserActivitiesMaxUsernameAsc = 'USER_ACTIVITIES_MAX_USERNAME_ASC',
  UserActivitiesMaxUsernameDesc = 'USER_ACTIVITIES_MAX_USERNAME_DESC',
  UserActivitiesMaxClaimsAsc = 'USER_ACTIVITIES_MAX_CLAIMS_ASC',
  UserActivitiesMaxClaimsDesc = 'USER_ACTIVITIES_MAX_CLAIMS_DESC',
  UserActivitiesMaxMetaAsc = 'USER_ACTIVITIES_MAX_META_ASC',
  UserActivitiesMaxMetaDesc = 'USER_ACTIVITIES_MAX_META_DESC',
  UserActivitiesMaxTypeAsc = 'USER_ACTIVITIES_MAX_TYPE_ASC',
  UserActivitiesMaxTypeDesc = 'USER_ACTIVITIES_MAX_TYPE_DESC',
  UserActivitiesMaxCreatedAtAsc = 'USER_ACTIVITIES_MAX_CREATED_AT_ASC',
  UserActivitiesMaxCreatedAtDesc = 'USER_ACTIVITIES_MAX_CREATED_AT_DESC',
  UserActivitiesMaxUpdatedAtAsc = 'USER_ACTIVITIES_MAX_UPDATED_AT_ASC',
  UserActivitiesMaxUpdatedAtDesc = 'USER_ACTIVITIES_MAX_UPDATED_AT_DESC',
  UserActivitiesMaxSearchTextAsc = 'USER_ACTIVITIES_MAX_SEARCH_TEXT_ASC',
  UserActivitiesMaxSearchTextDesc = 'USER_ACTIVITIES_MAX_SEARCH_TEXT_DESC',
  UserActivitiesMaxUserIdAsc = 'USER_ACTIVITIES_MAX_USER_ID_ASC',
  UserActivitiesMaxUserIdDesc = 'USER_ACTIVITIES_MAX_USER_ID_DESC',
  UserActivitiesAverageIdAsc = 'USER_ACTIVITIES_AVERAGE_ID_ASC',
  UserActivitiesAverageIdDesc = 'USER_ACTIVITIES_AVERAGE_ID_DESC',
  UserActivitiesAverageUsernameAsc = 'USER_ACTIVITIES_AVERAGE_USERNAME_ASC',
  UserActivitiesAverageUsernameDesc = 'USER_ACTIVITIES_AVERAGE_USERNAME_DESC',
  UserActivitiesAverageClaimsAsc = 'USER_ACTIVITIES_AVERAGE_CLAIMS_ASC',
  UserActivitiesAverageClaimsDesc = 'USER_ACTIVITIES_AVERAGE_CLAIMS_DESC',
  UserActivitiesAverageMetaAsc = 'USER_ACTIVITIES_AVERAGE_META_ASC',
  UserActivitiesAverageMetaDesc = 'USER_ACTIVITIES_AVERAGE_META_DESC',
  UserActivitiesAverageTypeAsc = 'USER_ACTIVITIES_AVERAGE_TYPE_ASC',
  UserActivitiesAverageTypeDesc = 'USER_ACTIVITIES_AVERAGE_TYPE_DESC',
  UserActivitiesAverageCreatedAtAsc = 'USER_ACTIVITIES_AVERAGE_CREATED_AT_ASC',
  UserActivitiesAverageCreatedAtDesc = 'USER_ACTIVITIES_AVERAGE_CREATED_AT_DESC',
  UserActivitiesAverageUpdatedAtAsc = 'USER_ACTIVITIES_AVERAGE_UPDATED_AT_ASC',
  UserActivitiesAverageUpdatedAtDesc = 'USER_ACTIVITIES_AVERAGE_UPDATED_AT_DESC',
  UserActivitiesAverageSearchTextAsc = 'USER_ACTIVITIES_AVERAGE_SEARCH_TEXT_ASC',
  UserActivitiesAverageSearchTextDesc = 'USER_ACTIVITIES_AVERAGE_SEARCH_TEXT_DESC',
  UserActivitiesAverageUserIdAsc = 'USER_ACTIVITIES_AVERAGE_USER_ID_ASC',
  UserActivitiesAverageUserIdDesc = 'USER_ACTIVITIES_AVERAGE_USER_ID_DESC',
  UserActivitiesStddevSampleIdAsc = 'USER_ACTIVITIES_STDDEV_SAMPLE_ID_ASC',
  UserActivitiesStddevSampleIdDesc = 'USER_ACTIVITIES_STDDEV_SAMPLE_ID_DESC',
  UserActivitiesStddevSampleUsernameAsc = 'USER_ACTIVITIES_STDDEV_SAMPLE_USERNAME_ASC',
  UserActivitiesStddevSampleUsernameDesc = 'USER_ACTIVITIES_STDDEV_SAMPLE_USERNAME_DESC',
  UserActivitiesStddevSampleClaimsAsc = 'USER_ACTIVITIES_STDDEV_SAMPLE_CLAIMS_ASC',
  UserActivitiesStddevSampleClaimsDesc = 'USER_ACTIVITIES_STDDEV_SAMPLE_CLAIMS_DESC',
  UserActivitiesStddevSampleMetaAsc = 'USER_ACTIVITIES_STDDEV_SAMPLE_META_ASC',
  UserActivitiesStddevSampleMetaDesc = 'USER_ACTIVITIES_STDDEV_SAMPLE_META_DESC',
  UserActivitiesStddevSampleTypeAsc = 'USER_ACTIVITIES_STDDEV_SAMPLE_TYPE_ASC',
  UserActivitiesStddevSampleTypeDesc = 'USER_ACTIVITIES_STDDEV_SAMPLE_TYPE_DESC',
  UserActivitiesStddevSampleCreatedAtAsc = 'USER_ACTIVITIES_STDDEV_SAMPLE_CREATED_AT_ASC',
  UserActivitiesStddevSampleCreatedAtDesc = 'USER_ACTIVITIES_STDDEV_SAMPLE_CREATED_AT_DESC',
  UserActivitiesStddevSampleUpdatedAtAsc = 'USER_ACTIVITIES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  UserActivitiesStddevSampleUpdatedAtDesc = 'USER_ACTIVITIES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  UserActivitiesStddevSampleSearchTextAsc = 'USER_ACTIVITIES_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  UserActivitiesStddevSampleSearchTextDesc = 'USER_ACTIVITIES_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  UserActivitiesStddevSampleUserIdAsc = 'USER_ACTIVITIES_STDDEV_SAMPLE_USER_ID_ASC',
  UserActivitiesStddevSampleUserIdDesc = 'USER_ACTIVITIES_STDDEV_SAMPLE_USER_ID_DESC',
  UserActivitiesStddevPopulationIdAsc = 'USER_ACTIVITIES_STDDEV_POPULATION_ID_ASC',
  UserActivitiesStddevPopulationIdDesc = 'USER_ACTIVITIES_STDDEV_POPULATION_ID_DESC',
  UserActivitiesStddevPopulationUsernameAsc = 'USER_ACTIVITIES_STDDEV_POPULATION_USERNAME_ASC',
  UserActivitiesStddevPopulationUsernameDesc = 'USER_ACTIVITIES_STDDEV_POPULATION_USERNAME_DESC',
  UserActivitiesStddevPopulationClaimsAsc = 'USER_ACTIVITIES_STDDEV_POPULATION_CLAIMS_ASC',
  UserActivitiesStddevPopulationClaimsDesc = 'USER_ACTIVITIES_STDDEV_POPULATION_CLAIMS_DESC',
  UserActivitiesStddevPopulationMetaAsc = 'USER_ACTIVITIES_STDDEV_POPULATION_META_ASC',
  UserActivitiesStddevPopulationMetaDesc = 'USER_ACTIVITIES_STDDEV_POPULATION_META_DESC',
  UserActivitiesStddevPopulationTypeAsc = 'USER_ACTIVITIES_STDDEV_POPULATION_TYPE_ASC',
  UserActivitiesStddevPopulationTypeDesc = 'USER_ACTIVITIES_STDDEV_POPULATION_TYPE_DESC',
  UserActivitiesStddevPopulationCreatedAtAsc = 'USER_ACTIVITIES_STDDEV_POPULATION_CREATED_AT_ASC',
  UserActivitiesStddevPopulationCreatedAtDesc = 'USER_ACTIVITIES_STDDEV_POPULATION_CREATED_AT_DESC',
  UserActivitiesStddevPopulationUpdatedAtAsc = 'USER_ACTIVITIES_STDDEV_POPULATION_UPDATED_AT_ASC',
  UserActivitiesStddevPopulationUpdatedAtDesc = 'USER_ACTIVITIES_STDDEV_POPULATION_UPDATED_AT_DESC',
  UserActivitiesStddevPopulationSearchTextAsc = 'USER_ACTIVITIES_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  UserActivitiesStddevPopulationSearchTextDesc = 'USER_ACTIVITIES_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  UserActivitiesStddevPopulationUserIdAsc = 'USER_ACTIVITIES_STDDEV_POPULATION_USER_ID_ASC',
  UserActivitiesStddevPopulationUserIdDesc = 'USER_ACTIVITIES_STDDEV_POPULATION_USER_ID_DESC',
  UserActivitiesVarianceSampleIdAsc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_ID_ASC',
  UserActivitiesVarianceSampleIdDesc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_ID_DESC',
  UserActivitiesVarianceSampleUsernameAsc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_USERNAME_ASC',
  UserActivitiesVarianceSampleUsernameDesc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_USERNAME_DESC',
  UserActivitiesVarianceSampleClaimsAsc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_CLAIMS_ASC',
  UserActivitiesVarianceSampleClaimsDesc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_CLAIMS_DESC',
  UserActivitiesVarianceSampleMetaAsc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_META_ASC',
  UserActivitiesVarianceSampleMetaDesc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_META_DESC',
  UserActivitiesVarianceSampleTypeAsc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_TYPE_ASC',
  UserActivitiesVarianceSampleTypeDesc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_TYPE_DESC',
  UserActivitiesVarianceSampleCreatedAtAsc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  UserActivitiesVarianceSampleCreatedAtDesc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  UserActivitiesVarianceSampleUpdatedAtAsc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  UserActivitiesVarianceSampleUpdatedAtDesc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UserActivitiesVarianceSampleSearchTextAsc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  UserActivitiesVarianceSampleSearchTextDesc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  UserActivitiesVarianceSampleUserIdAsc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_USER_ID_ASC',
  UserActivitiesVarianceSampleUserIdDesc = 'USER_ACTIVITIES_VARIANCE_SAMPLE_USER_ID_DESC',
  UserActivitiesVariancePopulationIdAsc = 'USER_ACTIVITIES_VARIANCE_POPULATION_ID_ASC',
  UserActivitiesVariancePopulationIdDesc = 'USER_ACTIVITIES_VARIANCE_POPULATION_ID_DESC',
  UserActivitiesVariancePopulationUsernameAsc = 'USER_ACTIVITIES_VARIANCE_POPULATION_USERNAME_ASC',
  UserActivitiesVariancePopulationUsernameDesc = 'USER_ACTIVITIES_VARIANCE_POPULATION_USERNAME_DESC',
  UserActivitiesVariancePopulationClaimsAsc = 'USER_ACTIVITIES_VARIANCE_POPULATION_CLAIMS_ASC',
  UserActivitiesVariancePopulationClaimsDesc = 'USER_ACTIVITIES_VARIANCE_POPULATION_CLAIMS_DESC',
  UserActivitiesVariancePopulationMetaAsc = 'USER_ACTIVITIES_VARIANCE_POPULATION_META_ASC',
  UserActivitiesVariancePopulationMetaDesc = 'USER_ACTIVITIES_VARIANCE_POPULATION_META_DESC',
  UserActivitiesVariancePopulationTypeAsc = 'USER_ACTIVITIES_VARIANCE_POPULATION_TYPE_ASC',
  UserActivitiesVariancePopulationTypeDesc = 'USER_ACTIVITIES_VARIANCE_POPULATION_TYPE_DESC',
  UserActivitiesVariancePopulationCreatedAtAsc = 'USER_ACTIVITIES_VARIANCE_POPULATION_CREATED_AT_ASC',
  UserActivitiesVariancePopulationCreatedAtDesc = 'USER_ACTIVITIES_VARIANCE_POPULATION_CREATED_AT_DESC',
  UserActivitiesVariancePopulationUpdatedAtAsc = 'USER_ACTIVITIES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  UserActivitiesVariancePopulationUpdatedAtDesc = 'USER_ACTIVITIES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  UserActivitiesVariancePopulationSearchTextAsc = 'USER_ACTIVITIES_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  UserActivitiesVariancePopulationSearchTextDesc = 'USER_ACTIVITIES_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  UserActivitiesVariancePopulationUserIdAsc = 'USER_ACTIVITIES_VARIANCE_POPULATION_USER_ID_ASC',
  UserActivitiesVariancePopulationUserIdDesc = 'USER_ACTIVITIES_VARIANCE_POPULATION_USER_ID_DESC',
  LeadRolesCountAsc = 'LEAD_ROLES_COUNT_ASC',
  LeadRolesCountDesc = 'LEAD_ROLES_COUNT_DESC',
  LeadRolesSumIdAsc = 'LEAD_ROLES_SUM_ID_ASC',
  LeadRolesSumIdDesc = 'LEAD_ROLES_SUM_ID_DESC',
  LeadRolesSumLeadIdAsc = 'LEAD_ROLES_SUM_LEAD_ID_ASC',
  LeadRolesSumLeadIdDesc = 'LEAD_ROLES_SUM_LEAD_ID_DESC',
  LeadRolesSumUserIdAsc = 'LEAD_ROLES_SUM_USER_ID_ASC',
  LeadRolesSumUserIdDesc = 'LEAD_ROLES_SUM_USER_ID_DESC',
  LeadRolesSumRoleAsc = 'LEAD_ROLES_SUM_ROLE_ASC',
  LeadRolesSumRoleDesc = 'LEAD_ROLES_SUM_ROLE_DESC',
  LeadRolesSumCreatedAtAsc = 'LEAD_ROLES_SUM_CREATED_AT_ASC',
  LeadRolesSumCreatedAtDesc = 'LEAD_ROLES_SUM_CREATED_AT_DESC',
  LeadRolesSumUpdatedAtAsc = 'LEAD_ROLES_SUM_UPDATED_AT_ASC',
  LeadRolesSumUpdatedAtDesc = 'LEAD_ROLES_SUM_UPDATED_AT_DESC',
  LeadRolesDistinctCountIdAsc = 'LEAD_ROLES_DISTINCT_COUNT_ID_ASC',
  LeadRolesDistinctCountIdDesc = 'LEAD_ROLES_DISTINCT_COUNT_ID_DESC',
  LeadRolesDistinctCountLeadIdAsc = 'LEAD_ROLES_DISTINCT_COUNT_LEAD_ID_ASC',
  LeadRolesDistinctCountLeadIdDesc = 'LEAD_ROLES_DISTINCT_COUNT_LEAD_ID_DESC',
  LeadRolesDistinctCountUserIdAsc = 'LEAD_ROLES_DISTINCT_COUNT_USER_ID_ASC',
  LeadRolesDistinctCountUserIdDesc = 'LEAD_ROLES_DISTINCT_COUNT_USER_ID_DESC',
  LeadRolesDistinctCountRoleAsc = 'LEAD_ROLES_DISTINCT_COUNT_ROLE_ASC',
  LeadRolesDistinctCountRoleDesc = 'LEAD_ROLES_DISTINCT_COUNT_ROLE_DESC',
  LeadRolesDistinctCountCreatedAtAsc = 'LEAD_ROLES_DISTINCT_COUNT_CREATED_AT_ASC',
  LeadRolesDistinctCountCreatedAtDesc = 'LEAD_ROLES_DISTINCT_COUNT_CREATED_AT_DESC',
  LeadRolesDistinctCountUpdatedAtAsc = 'LEAD_ROLES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LeadRolesDistinctCountUpdatedAtDesc = 'LEAD_ROLES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LeadRolesMinIdAsc = 'LEAD_ROLES_MIN_ID_ASC',
  LeadRolesMinIdDesc = 'LEAD_ROLES_MIN_ID_DESC',
  LeadRolesMinLeadIdAsc = 'LEAD_ROLES_MIN_LEAD_ID_ASC',
  LeadRolesMinLeadIdDesc = 'LEAD_ROLES_MIN_LEAD_ID_DESC',
  LeadRolesMinUserIdAsc = 'LEAD_ROLES_MIN_USER_ID_ASC',
  LeadRolesMinUserIdDesc = 'LEAD_ROLES_MIN_USER_ID_DESC',
  LeadRolesMinRoleAsc = 'LEAD_ROLES_MIN_ROLE_ASC',
  LeadRolesMinRoleDesc = 'LEAD_ROLES_MIN_ROLE_DESC',
  LeadRolesMinCreatedAtAsc = 'LEAD_ROLES_MIN_CREATED_AT_ASC',
  LeadRolesMinCreatedAtDesc = 'LEAD_ROLES_MIN_CREATED_AT_DESC',
  LeadRolesMinUpdatedAtAsc = 'LEAD_ROLES_MIN_UPDATED_AT_ASC',
  LeadRolesMinUpdatedAtDesc = 'LEAD_ROLES_MIN_UPDATED_AT_DESC',
  LeadRolesMaxIdAsc = 'LEAD_ROLES_MAX_ID_ASC',
  LeadRolesMaxIdDesc = 'LEAD_ROLES_MAX_ID_DESC',
  LeadRolesMaxLeadIdAsc = 'LEAD_ROLES_MAX_LEAD_ID_ASC',
  LeadRolesMaxLeadIdDesc = 'LEAD_ROLES_MAX_LEAD_ID_DESC',
  LeadRolesMaxUserIdAsc = 'LEAD_ROLES_MAX_USER_ID_ASC',
  LeadRolesMaxUserIdDesc = 'LEAD_ROLES_MAX_USER_ID_DESC',
  LeadRolesMaxRoleAsc = 'LEAD_ROLES_MAX_ROLE_ASC',
  LeadRolesMaxRoleDesc = 'LEAD_ROLES_MAX_ROLE_DESC',
  LeadRolesMaxCreatedAtAsc = 'LEAD_ROLES_MAX_CREATED_AT_ASC',
  LeadRolesMaxCreatedAtDesc = 'LEAD_ROLES_MAX_CREATED_AT_DESC',
  LeadRolesMaxUpdatedAtAsc = 'LEAD_ROLES_MAX_UPDATED_AT_ASC',
  LeadRolesMaxUpdatedAtDesc = 'LEAD_ROLES_MAX_UPDATED_AT_DESC',
  LeadRolesAverageIdAsc = 'LEAD_ROLES_AVERAGE_ID_ASC',
  LeadRolesAverageIdDesc = 'LEAD_ROLES_AVERAGE_ID_DESC',
  LeadRolesAverageLeadIdAsc = 'LEAD_ROLES_AVERAGE_LEAD_ID_ASC',
  LeadRolesAverageLeadIdDesc = 'LEAD_ROLES_AVERAGE_LEAD_ID_DESC',
  LeadRolesAverageUserIdAsc = 'LEAD_ROLES_AVERAGE_USER_ID_ASC',
  LeadRolesAverageUserIdDesc = 'LEAD_ROLES_AVERAGE_USER_ID_DESC',
  LeadRolesAverageRoleAsc = 'LEAD_ROLES_AVERAGE_ROLE_ASC',
  LeadRolesAverageRoleDesc = 'LEAD_ROLES_AVERAGE_ROLE_DESC',
  LeadRolesAverageCreatedAtAsc = 'LEAD_ROLES_AVERAGE_CREATED_AT_ASC',
  LeadRolesAverageCreatedAtDesc = 'LEAD_ROLES_AVERAGE_CREATED_AT_DESC',
  LeadRolesAverageUpdatedAtAsc = 'LEAD_ROLES_AVERAGE_UPDATED_AT_ASC',
  LeadRolesAverageUpdatedAtDesc = 'LEAD_ROLES_AVERAGE_UPDATED_AT_DESC',
  LeadRolesStddevSampleIdAsc = 'LEAD_ROLES_STDDEV_SAMPLE_ID_ASC',
  LeadRolesStddevSampleIdDesc = 'LEAD_ROLES_STDDEV_SAMPLE_ID_DESC',
  LeadRolesStddevSampleLeadIdAsc = 'LEAD_ROLES_STDDEV_SAMPLE_LEAD_ID_ASC',
  LeadRolesStddevSampleLeadIdDesc = 'LEAD_ROLES_STDDEV_SAMPLE_LEAD_ID_DESC',
  LeadRolesStddevSampleUserIdAsc = 'LEAD_ROLES_STDDEV_SAMPLE_USER_ID_ASC',
  LeadRolesStddevSampleUserIdDesc = 'LEAD_ROLES_STDDEV_SAMPLE_USER_ID_DESC',
  LeadRolesStddevSampleRoleAsc = 'LEAD_ROLES_STDDEV_SAMPLE_ROLE_ASC',
  LeadRolesStddevSampleRoleDesc = 'LEAD_ROLES_STDDEV_SAMPLE_ROLE_DESC',
  LeadRolesStddevSampleCreatedAtAsc = 'LEAD_ROLES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LeadRolesStddevSampleCreatedAtDesc = 'LEAD_ROLES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LeadRolesStddevSampleUpdatedAtAsc = 'LEAD_ROLES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LeadRolesStddevSampleUpdatedAtDesc = 'LEAD_ROLES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LeadRolesStddevPopulationIdAsc = 'LEAD_ROLES_STDDEV_POPULATION_ID_ASC',
  LeadRolesStddevPopulationIdDesc = 'LEAD_ROLES_STDDEV_POPULATION_ID_DESC',
  LeadRolesStddevPopulationLeadIdAsc = 'LEAD_ROLES_STDDEV_POPULATION_LEAD_ID_ASC',
  LeadRolesStddevPopulationLeadIdDesc = 'LEAD_ROLES_STDDEV_POPULATION_LEAD_ID_DESC',
  LeadRolesStddevPopulationUserIdAsc = 'LEAD_ROLES_STDDEV_POPULATION_USER_ID_ASC',
  LeadRolesStddevPopulationUserIdDesc = 'LEAD_ROLES_STDDEV_POPULATION_USER_ID_DESC',
  LeadRolesStddevPopulationRoleAsc = 'LEAD_ROLES_STDDEV_POPULATION_ROLE_ASC',
  LeadRolesStddevPopulationRoleDesc = 'LEAD_ROLES_STDDEV_POPULATION_ROLE_DESC',
  LeadRolesStddevPopulationCreatedAtAsc = 'LEAD_ROLES_STDDEV_POPULATION_CREATED_AT_ASC',
  LeadRolesStddevPopulationCreatedAtDesc = 'LEAD_ROLES_STDDEV_POPULATION_CREATED_AT_DESC',
  LeadRolesStddevPopulationUpdatedAtAsc = 'LEAD_ROLES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LeadRolesStddevPopulationUpdatedAtDesc = 'LEAD_ROLES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LeadRolesVarianceSampleIdAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_ID_ASC',
  LeadRolesVarianceSampleIdDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_ID_DESC',
  LeadRolesVarianceSampleLeadIdAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LeadRolesVarianceSampleLeadIdDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LeadRolesVarianceSampleUserIdAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_USER_ID_ASC',
  LeadRolesVarianceSampleUserIdDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_USER_ID_DESC',
  LeadRolesVarianceSampleRoleAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_ROLE_ASC',
  LeadRolesVarianceSampleRoleDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_ROLE_DESC',
  LeadRolesVarianceSampleCreatedAtAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LeadRolesVarianceSampleCreatedAtDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LeadRolesVarianceSampleUpdatedAtAsc = 'LEAD_ROLES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LeadRolesVarianceSampleUpdatedAtDesc = 'LEAD_ROLES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LeadRolesVariancePopulationIdAsc = 'LEAD_ROLES_VARIANCE_POPULATION_ID_ASC',
  LeadRolesVariancePopulationIdDesc = 'LEAD_ROLES_VARIANCE_POPULATION_ID_DESC',
  LeadRolesVariancePopulationLeadIdAsc = 'LEAD_ROLES_VARIANCE_POPULATION_LEAD_ID_ASC',
  LeadRolesVariancePopulationLeadIdDesc = 'LEAD_ROLES_VARIANCE_POPULATION_LEAD_ID_DESC',
  LeadRolesVariancePopulationUserIdAsc = 'LEAD_ROLES_VARIANCE_POPULATION_USER_ID_ASC',
  LeadRolesVariancePopulationUserIdDesc = 'LEAD_ROLES_VARIANCE_POPULATION_USER_ID_DESC',
  LeadRolesVariancePopulationRoleAsc = 'LEAD_ROLES_VARIANCE_POPULATION_ROLE_ASC',
  LeadRolesVariancePopulationRoleDesc = 'LEAD_ROLES_VARIANCE_POPULATION_ROLE_DESC',
  LeadRolesVariancePopulationCreatedAtAsc = 'LEAD_ROLES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LeadRolesVariancePopulationCreatedAtDesc = 'LEAD_ROLES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LeadRolesVariancePopulationUpdatedAtAsc = 'LEAD_ROLES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LeadRolesVariancePopulationUpdatedAtDesc = 'LEAD_ROLES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyUsersCountAsc = 'COMPANY_USERS_COUNT_ASC',
  CompanyUsersCountDesc = 'COMPANY_USERS_COUNT_DESC',
  CompanyUsersSumIdAsc = 'COMPANY_USERS_SUM_ID_ASC',
  CompanyUsersSumIdDesc = 'COMPANY_USERS_SUM_ID_DESC',
  CompanyUsersSumCompanyIdAsc = 'COMPANY_USERS_SUM_COMPANY_ID_ASC',
  CompanyUsersSumCompanyIdDesc = 'COMPANY_USERS_SUM_COMPANY_ID_DESC',
  CompanyUsersSumGroupIdAsc = 'COMPANY_USERS_SUM_GROUP_ID_ASC',
  CompanyUsersSumGroupIdDesc = 'COMPANY_USERS_SUM_GROUP_ID_DESC',
  CompanyUsersSumUserIdAsc = 'COMPANY_USERS_SUM_USER_ID_ASC',
  CompanyUsersSumUserIdDesc = 'COMPANY_USERS_SUM_USER_ID_DESC',
  CompanyUsersSumRoleAsc = 'COMPANY_USERS_SUM_ROLE_ASC',
  CompanyUsersSumRoleDesc = 'COMPANY_USERS_SUM_ROLE_DESC',
  CompanyUsersSumCreatedAtAsc = 'COMPANY_USERS_SUM_CREATED_AT_ASC',
  CompanyUsersSumCreatedAtDesc = 'COMPANY_USERS_SUM_CREATED_AT_DESC',
  CompanyUsersSumUpdatedAtAsc = 'COMPANY_USERS_SUM_UPDATED_AT_ASC',
  CompanyUsersSumUpdatedAtDesc = 'COMPANY_USERS_SUM_UPDATED_AT_DESC',
  CompanyUsersDistinctCountIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_ID_ASC',
  CompanyUsersDistinctCountIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_ID_DESC',
  CompanyUsersDistinctCountCompanyIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyUsersDistinctCountCompanyIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyUsersDistinctCountGroupIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyUsersDistinctCountGroupIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyUsersDistinctCountUserIdAsc = 'COMPANY_USERS_DISTINCT_COUNT_USER_ID_ASC',
  CompanyUsersDistinctCountUserIdDesc = 'COMPANY_USERS_DISTINCT_COUNT_USER_ID_DESC',
  CompanyUsersDistinctCountRoleAsc = 'COMPANY_USERS_DISTINCT_COUNT_ROLE_ASC',
  CompanyUsersDistinctCountRoleDesc = 'COMPANY_USERS_DISTINCT_COUNT_ROLE_DESC',
  CompanyUsersDistinctCountCreatedAtAsc = 'COMPANY_USERS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyUsersDistinctCountCreatedAtDesc = 'COMPANY_USERS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyUsersDistinctCountUpdatedAtAsc = 'COMPANY_USERS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyUsersDistinctCountUpdatedAtDesc = 'COMPANY_USERS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyUsersMinIdAsc = 'COMPANY_USERS_MIN_ID_ASC',
  CompanyUsersMinIdDesc = 'COMPANY_USERS_MIN_ID_DESC',
  CompanyUsersMinCompanyIdAsc = 'COMPANY_USERS_MIN_COMPANY_ID_ASC',
  CompanyUsersMinCompanyIdDesc = 'COMPANY_USERS_MIN_COMPANY_ID_DESC',
  CompanyUsersMinGroupIdAsc = 'COMPANY_USERS_MIN_GROUP_ID_ASC',
  CompanyUsersMinGroupIdDesc = 'COMPANY_USERS_MIN_GROUP_ID_DESC',
  CompanyUsersMinUserIdAsc = 'COMPANY_USERS_MIN_USER_ID_ASC',
  CompanyUsersMinUserIdDesc = 'COMPANY_USERS_MIN_USER_ID_DESC',
  CompanyUsersMinRoleAsc = 'COMPANY_USERS_MIN_ROLE_ASC',
  CompanyUsersMinRoleDesc = 'COMPANY_USERS_MIN_ROLE_DESC',
  CompanyUsersMinCreatedAtAsc = 'COMPANY_USERS_MIN_CREATED_AT_ASC',
  CompanyUsersMinCreatedAtDesc = 'COMPANY_USERS_MIN_CREATED_AT_DESC',
  CompanyUsersMinUpdatedAtAsc = 'COMPANY_USERS_MIN_UPDATED_AT_ASC',
  CompanyUsersMinUpdatedAtDesc = 'COMPANY_USERS_MIN_UPDATED_AT_DESC',
  CompanyUsersMaxIdAsc = 'COMPANY_USERS_MAX_ID_ASC',
  CompanyUsersMaxIdDesc = 'COMPANY_USERS_MAX_ID_DESC',
  CompanyUsersMaxCompanyIdAsc = 'COMPANY_USERS_MAX_COMPANY_ID_ASC',
  CompanyUsersMaxCompanyIdDesc = 'COMPANY_USERS_MAX_COMPANY_ID_DESC',
  CompanyUsersMaxGroupIdAsc = 'COMPANY_USERS_MAX_GROUP_ID_ASC',
  CompanyUsersMaxGroupIdDesc = 'COMPANY_USERS_MAX_GROUP_ID_DESC',
  CompanyUsersMaxUserIdAsc = 'COMPANY_USERS_MAX_USER_ID_ASC',
  CompanyUsersMaxUserIdDesc = 'COMPANY_USERS_MAX_USER_ID_DESC',
  CompanyUsersMaxRoleAsc = 'COMPANY_USERS_MAX_ROLE_ASC',
  CompanyUsersMaxRoleDesc = 'COMPANY_USERS_MAX_ROLE_DESC',
  CompanyUsersMaxCreatedAtAsc = 'COMPANY_USERS_MAX_CREATED_AT_ASC',
  CompanyUsersMaxCreatedAtDesc = 'COMPANY_USERS_MAX_CREATED_AT_DESC',
  CompanyUsersMaxUpdatedAtAsc = 'COMPANY_USERS_MAX_UPDATED_AT_ASC',
  CompanyUsersMaxUpdatedAtDesc = 'COMPANY_USERS_MAX_UPDATED_AT_DESC',
  CompanyUsersAverageIdAsc = 'COMPANY_USERS_AVERAGE_ID_ASC',
  CompanyUsersAverageIdDesc = 'COMPANY_USERS_AVERAGE_ID_DESC',
  CompanyUsersAverageCompanyIdAsc = 'COMPANY_USERS_AVERAGE_COMPANY_ID_ASC',
  CompanyUsersAverageCompanyIdDesc = 'COMPANY_USERS_AVERAGE_COMPANY_ID_DESC',
  CompanyUsersAverageGroupIdAsc = 'COMPANY_USERS_AVERAGE_GROUP_ID_ASC',
  CompanyUsersAverageGroupIdDesc = 'COMPANY_USERS_AVERAGE_GROUP_ID_DESC',
  CompanyUsersAverageUserIdAsc = 'COMPANY_USERS_AVERAGE_USER_ID_ASC',
  CompanyUsersAverageUserIdDesc = 'COMPANY_USERS_AVERAGE_USER_ID_DESC',
  CompanyUsersAverageRoleAsc = 'COMPANY_USERS_AVERAGE_ROLE_ASC',
  CompanyUsersAverageRoleDesc = 'COMPANY_USERS_AVERAGE_ROLE_DESC',
  CompanyUsersAverageCreatedAtAsc = 'COMPANY_USERS_AVERAGE_CREATED_AT_ASC',
  CompanyUsersAverageCreatedAtDesc = 'COMPANY_USERS_AVERAGE_CREATED_AT_DESC',
  CompanyUsersAverageUpdatedAtAsc = 'COMPANY_USERS_AVERAGE_UPDATED_AT_ASC',
  CompanyUsersAverageUpdatedAtDesc = 'COMPANY_USERS_AVERAGE_UPDATED_AT_DESC',
  CompanyUsersStddevSampleIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_ID_ASC',
  CompanyUsersStddevSampleIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_ID_DESC',
  CompanyUsersStddevSampleCompanyIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyUsersStddevSampleCompanyIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyUsersStddevSampleGroupIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyUsersStddevSampleGroupIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyUsersStddevSampleUserIdAsc = 'COMPANY_USERS_STDDEV_SAMPLE_USER_ID_ASC',
  CompanyUsersStddevSampleUserIdDesc = 'COMPANY_USERS_STDDEV_SAMPLE_USER_ID_DESC',
  CompanyUsersStddevSampleRoleAsc = 'COMPANY_USERS_STDDEV_SAMPLE_ROLE_ASC',
  CompanyUsersStddevSampleRoleDesc = 'COMPANY_USERS_STDDEV_SAMPLE_ROLE_DESC',
  CompanyUsersStddevSampleCreatedAtAsc = 'COMPANY_USERS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyUsersStddevSampleCreatedAtDesc = 'COMPANY_USERS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyUsersStddevSampleUpdatedAtAsc = 'COMPANY_USERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyUsersStddevSampleUpdatedAtDesc = 'COMPANY_USERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyUsersStddevPopulationIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_ID_ASC',
  CompanyUsersStddevPopulationIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_ID_DESC',
  CompanyUsersStddevPopulationCompanyIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyUsersStddevPopulationCompanyIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyUsersStddevPopulationGroupIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyUsersStddevPopulationGroupIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyUsersStddevPopulationUserIdAsc = 'COMPANY_USERS_STDDEV_POPULATION_USER_ID_ASC',
  CompanyUsersStddevPopulationUserIdDesc = 'COMPANY_USERS_STDDEV_POPULATION_USER_ID_DESC',
  CompanyUsersStddevPopulationRoleAsc = 'COMPANY_USERS_STDDEV_POPULATION_ROLE_ASC',
  CompanyUsersStddevPopulationRoleDesc = 'COMPANY_USERS_STDDEV_POPULATION_ROLE_DESC',
  CompanyUsersStddevPopulationCreatedAtAsc = 'COMPANY_USERS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyUsersStddevPopulationCreatedAtDesc = 'COMPANY_USERS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyUsersStddevPopulationUpdatedAtAsc = 'COMPANY_USERS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyUsersStddevPopulationUpdatedAtDesc = 'COMPANY_USERS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyUsersVarianceSampleIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_ID_ASC',
  CompanyUsersVarianceSampleIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_ID_DESC',
  CompanyUsersVarianceSampleCompanyIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyUsersVarianceSampleCompanyIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyUsersVarianceSampleGroupIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyUsersVarianceSampleGroupIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyUsersVarianceSampleUserIdAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_USER_ID_ASC',
  CompanyUsersVarianceSampleUserIdDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_USER_ID_DESC',
  CompanyUsersVarianceSampleRoleAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_ROLE_ASC',
  CompanyUsersVarianceSampleRoleDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_ROLE_DESC',
  CompanyUsersVarianceSampleCreatedAtAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyUsersVarianceSampleCreatedAtDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyUsersVarianceSampleUpdatedAtAsc = 'COMPANY_USERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyUsersVarianceSampleUpdatedAtDesc = 'COMPANY_USERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyUsersVariancePopulationIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_ID_ASC',
  CompanyUsersVariancePopulationIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_ID_DESC',
  CompanyUsersVariancePopulationCompanyIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyUsersVariancePopulationCompanyIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyUsersVariancePopulationGroupIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyUsersVariancePopulationGroupIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyUsersVariancePopulationUserIdAsc = 'COMPANY_USERS_VARIANCE_POPULATION_USER_ID_ASC',
  CompanyUsersVariancePopulationUserIdDesc = 'COMPANY_USERS_VARIANCE_POPULATION_USER_ID_DESC',
  CompanyUsersVariancePopulationRoleAsc = 'COMPANY_USERS_VARIANCE_POPULATION_ROLE_ASC',
  CompanyUsersVariancePopulationRoleDesc = 'COMPANY_USERS_VARIANCE_POPULATION_ROLE_DESC',
  CompanyUsersVariancePopulationCreatedAtAsc = 'COMPANY_USERS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyUsersVariancePopulationCreatedAtDesc = 'COMPANY_USERS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyUsersVariancePopulationUpdatedAtAsc = 'COMPANY_USERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyUsersVariancePopulationUpdatedAtDesc = 'COMPANY_USERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  MediaByUploaderCountAsc = 'MEDIA_BY_UPLOADER_COUNT_ASC',
  MediaByUploaderCountDesc = 'MEDIA_BY_UPLOADER_COUNT_DESC',
  MediaByUploaderSumIdAsc = 'MEDIA_BY_UPLOADER_SUM_ID_ASC',
  MediaByUploaderSumIdDesc = 'MEDIA_BY_UPLOADER_SUM_ID_DESC',
  MediaByUploaderSumCompanyIdAsc = 'MEDIA_BY_UPLOADER_SUM_COMPANY_ID_ASC',
  MediaByUploaderSumCompanyIdDesc = 'MEDIA_BY_UPLOADER_SUM_COMPANY_ID_DESC',
  MediaByUploaderSumLabelAsc = 'MEDIA_BY_UPLOADER_SUM_LABEL_ASC',
  MediaByUploaderSumLabelDesc = 'MEDIA_BY_UPLOADER_SUM_LABEL_DESC',
  MediaByUploaderSumTypeAsc = 'MEDIA_BY_UPLOADER_SUM_TYPE_ASC',
  MediaByUploaderSumTypeDesc = 'MEDIA_BY_UPLOADER_SUM_TYPE_DESC',
  MediaByUploaderSumUploaderAsc = 'MEDIA_BY_UPLOADER_SUM_UPLOADER_ASC',
  MediaByUploaderSumUploaderDesc = 'MEDIA_BY_UPLOADER_SUM_UPLOADER_DESC',
  MediaByUploaderSumS3KeyAsc = 'MEDIA_BY_UPLOADER_SUM_S3_KEY_ASC',
  MediaByUploaderSumS3KeyDesc = 'MEDIA_BY_UPLOADER_SUM_S3_KEY_DESC',
  MediaByUploaderSumParentIdAsc = 'MEDIA_BY_UPLOADER_SUM_PARENT_ID_ASC',
  MediaByUploaderSumParentIdDesc = 'MEDIA_BY_UPLOADER_SUM_PARENT_ID_DESC',
  MediaByUploaderSumParentTypeAsc = 'MEDIA_BY_UPLOADER_SUM_PARENT_TYPE_ASC',
  MediaByUploaderSumParentTypeDesc = 'MEDIA_BY_UPLOADER_SUM_PARENT_TYPE_DESC',
  MediaByUploaderSumMetaAsc = 'MEDIA_BY_UPLOADER_SUM_META_ASC',
  MediaByUploaderSumMetaDesc = 'MEDIA_BY_UPLOADER_SUM_META_DESC',
  MediaByUploaderSumContentTypeAsc = 'MEDIA_BY_UPLOADER_SUM_CONTENT_TYPE_ASC',
  MediaByUploaderSumContentTypeDesc = 'MEDIA_BY_UPLOADER_SUM_CONTENT_TYPE_DESC',
  MediaByUploaderSumUploadCompletedAtAsc = 'MEDIA_BY_UPLOADER_SUM_UPLOAD_COMPLETED_AT_ASC',
  MediaByUploaderSumUploadCompletedAtDesc = 'MEDIA_BY_UPLOADER_SUM_UPLOAD_COMPLETED_AT_DESC',
  MediaByUploaderSumCreatedAtAsc = 'MEDIA_BY_UPLOADER_SUM_CREATED_AT_ASC',
  MediaByUploaderSumCreatedAtDesc = 'MEDIA_BY_UPLOADER_SUM_CREATED_AT_DESC',
  MediaByUploaderSumUpdatedAtAsc = 'MEDIA_BY_UPLOADER_SUM_UPDATED_AT_ASC',
  MediaByUploaderSumUpdatedAtDesc = 'MEDIA_BY_UPLOADER_SUM_UPDATED_AT_DESC',
  MediaByUploaderSumDeletedAtAsc = 'MEDIA_BY_UPLOADER_SUM_DELETED_AT_ASC',
  MediaByUploaderSumDeletedAtDesc = 'MEDIA_BY_UPLOADER_SUM_DELETED_AT_DESC',
  MediaByUploaderSumFileNameAsc = 'MEDIA_BY_UPLOADER_SUM_FILE_NAME_ASC',
  MediaByUploaderSumFileNameDesc = 'MEDIA_BY_UPLOADER_SUM_FILE_NAME_DESC',
  MediaByUploaderDistinctCountIdAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_ID_ASC',
  MediaByUploaderDistinctCountIdDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_ID_DESC',
  MediaByUploaderDistinctCountCompanyIdAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_COMPANY_ID_ASC',
  MediaByUploaderDistinctCountCompanyIdDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_COMPANY_ID_DESC',
  MediaByUploaderDistinctCountLabelAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_LABEL_ASC',
  MediaByUploaderDistinctCountLabelDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_LABEL_DESC',
  MediaByUploaderDistinctCountTypeAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_TYPE_ASC',
  MediaByUploaderDistinctCountTypeDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_TYPE_DESC',
  MediaByUploaderDistinctCountUploaderAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_UPLOADER_ASC',
  MediaByUploaderDistinctCountUploaderDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_UPLOADER_DESC',
  MediaByUploaderDistinctCountS3KeyAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_S3_KEY_ASC',
  MediaByUploaderDistinctCountS3KeyDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_S3_KEY_DESC',
  MediaByUploaderDistinctCountParentIdAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_PARENT_ID_ASC',
  MediaByUploaderDistinctCountParentIdDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_PARENT_ID_DESC',
  MediaByUploaderDistinctCountParentTypeAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_PARENT_TYPE_ASC',
  MediaByUploaderDistinctCountParentTypeDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_PARENT_TYPE_DESC',
  MediaByUploaderDistinctCountMetaAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_META_ASC',
  MediaByUploaderDistinctCountMetaDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_META_DESC',
  MediaByUploaderDistinctCountContentTypeAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_CONTENT_TYPE_ASC',
  MediaByUploaderDistinctCountContentTypeDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_CONTENT_TYPE_DESC',
  MediaByUploaderDistinctCountUploadCompletedAtAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_UPLOAD_COMPLETED_AT_ASC',
  MediaByUploaderDistinctCountUploadCompletedAtDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_UPLOAD_COMPLETED_AT_DESC',
  MediaByUploaderDistinctCountCreatedAtAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_CREATED_AT_ASC',
  MediaByUploaderDistinctCountCreatedAtDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_CREATED_AT_DESC',
  MediaByUploaderDistinctCountUpdatedAtAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_UPDATED_AT_ASC',
  MediaByUploaderDistinctCountUpdatedAtDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_UPDATED_AT_DESC',
  MediaByUploaderDistinctCountDeletedAtAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_DELETED_AT_ASC',
  MediaByUploaderDistinctCountDeletedAtDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_DELETED_AT_DESC',
  MediaByUploaderDistinctCountFileNameAsc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_FILE_NAME_ASC',
  MediaByUploaderDistinctCountFileNameDesc = 'MEDIA_BY_UPLOADER_DISTINCT_COUNT_FILE_NAME_DESC',
  MediaByUploaderMinIdAsc = 'MEDIA_BY_UPLOADER_MIN_ID_ASC',
  MediaByUploaderMinIdDesc = 'MEDIA_BY_UPLOADER_MIN_ID_DESC',
  MediaByUploaderMinCompanyIdAsc = 'MEDIA_BY_UPLOADER_MIN_COMPANY_ID_ASC',
  MediaByUploaderMinCompanyIdDesc = 'MEDIA_BY_UPLOADER_MIN_COMPANY_ID_DESC',
  MediaByUploaderMinLabelAsc = 'MEDIA_BY_UPLOADER_MIN_LABEL_ASC',
  MediaByUploaderMinLabelDesc = 'MEDIA_BY_UPLOADER_MIN_LABEL_DESC',
  MediaByUploaderMinTypeAsc = 'MEDIA_BY_UPLOADER_MIN_TYPE_ASC',
  MediaByUploaderMinTypeDesc = 'MEDIA_BY_UPLOADER_MIN_TYPE_DESC',
  MediaByUploaderMinUploaderAsc = 'MEDIA_BY_UPLOADER_MIN_UPLOADER_ASC',
  MediaByUploaderMinUploaderDesc = 'MEDIA_BY_UPLOADER_MIN_UPLOADER_DESC',
  MediaByUploaderMinS3KeyAsc = 'MEDIA_BY_UPLOADER_MIN_S3_KEY_ASC',
  MediaByUploaderMinS3KeyDesc = 'MEDIA_BY_UPLOADER_MIN_S3_KEY_DESC',
  MediaByUploaderMinParentIdAsc = 'MEDIA_BY_UPLOADER_MIN_PARENT_ID_ASC',
  MediaByUploaderMinParentIdDesc = 'MEDIA_BY_UPLOADER_MIN_PARENT_ID_DESC',
  MediaByUploaderMinParentTypeAsc = 'MEDIA_BY_UPLOADER_MIN_PARENT_TYPE_ASC',
  MediaByUploaderMinParentTypeDesc = 'MEDIA_BY_UPLOADER_MIN_PARENT_TYPE_DESC',
  MediaByUploaderMinMetaAsc = 'MEDIA_BY_UPLOADER_MIN_META_ASC',
  MediaByUploaderMinMetaDesc = 'MEDIA_BY_UPLOADER_MIN_META_DESC',
  MediaByUploaderMinContentTypeAsc = 'MEDIA_BY_UPLOADER_MIN_CONTENT_TYPE_ASC',
  MediaByUploaderMinContentTypeDesc = 'MEDIA_BY_UPLOADER_MIN_CONTENT_TYPE_DESC',
  MediaByUploaderMinUploadCompletedAtAsc = 'MEDIA_BY_UPLOADER_MIN_UPLOAD_COMPLETED_AT_ASC',
  MediaByUploaderMinUploadCompletedAtDesc = 'MEDIA_BY_UPLOADER_MIN_UPLOAD_COMPLETED_AT_DESC',
  MediaByUploaderMinCreatedAtAsc = 'MEDIA_BY_UPLOADER_MIN_CREATED_AT_ASC',
  MediaByUploaderMinCreatedAtDesc = 'MEDIA_BY_UPLOADER_MIN_CREATED_AT_DESC',
  MediaByUploaderMinUpdatedAtAsc = 'MEDIA_BY_UPLOADER_MIN_UPDATED_AT_ASC',
  MediaByUploaderMinUpdatedAtDesc = 'MEDIA_BY_UPLOADER_MIN_UPDATED_AT_DESC',
  MediaByUploaderMinDeletedAtAsc = 'MEDIA_BY_UPLOADER_MIN_DELETED_AT_ASC',
  MediaByUploaderMinDeletedAtDesc = 'MEDIA_BY_UPLOADER_MIN_DELETED_AT_DESC',
  MediaByUploaderMinFileNameAsc = 'MEDIA_BY_UPLOADER_MIN_FILE_NAME_ASC',
  MediaByUploaderMinFileNameDesc = 'MEDIA_BY_UPLOADER_MIN_FILE_NAME_DESC',
  MediaByUploaderMaxIdAsc = 'MEDIA_BY_UPLOADER_MAX_ID_ASC',
  MediaByUploaderMaxIdDesc = 'MEDIA_BY_UPLOADER_MAX_ID_DESC',
  MediaByUploaderMaxCompanyIdAsc = 'MEDIA_BY_UPLOADER_MAX_COMPANY_ID_ASC',
  MediaByUploaderMaxCompanyIdDesc = 'MEDIA_BY_UPLOADER_MAX_COMPANY_ID_DESC',
  MediaByUploaderMaxLabelAsc = 'MEDIA_BY_UPLOADER_MAX_LABEL_ASC',
  MediaByUploaderMaxLabelDesc = 'MEDIA_BY_UPLOADER_MAX_LABEL_DESC',
  MediaByUploaderMaxTypeAsc = 'MEDIA_BY_UPLOADER_MAX_TYPE_ASC',
  MediaByUploaderMaxTypeDesc = 'MEDIA_BY_UPLOADER_MAX_TYPE_DESC',
  MediaByUploaderMaxUploaderAsc = 'MEDIA_BY_UPLOADER_MAX_UPLOADER_ASC',
  MediaByUploaderMaxUploaderDesc = 'MEDIA_BY_UPLOADER_MAX_UPLOADER_DESC',
  MediaByUploaderMaxS3KeyAsc = 'MEDIA_BY_UPLOADER_MAX_S3_KEY_ASC',
  MediaByUploaderMaxS3KeyDesc = 'MEDIA_BY_UPLOADER_MAX_S3_KEY_DESC',
  MediaByUploaderMaxParentIdAsc = 'MEDIA_BY_UPLOADER_MAX_PARENT_ID_ASC',
  MediaByUploaderMaxParentIdDesc = 'MEDIA_BY_UPLOADER_MAX_PARENT_ID_DESC',
  MediaByUploaderMaxParentTypeAsc = 'MEDIA_BY_UPLOADER_MAX_PARENT_TYPE_ASC',
  MediaByUploaderMaxParentTypeDesc = 'MEDIA_BY_UPLOADER_MAX_PARENT_TYPE_DESC',
  MediaByUploaderMaxMetaAsc = 'MEDIA_BY_UPLOADER_MAX_META_ASC',
  MediaByUploaderMaxMetaDesc = 'MEDIA_BY_UPLOADER_MAX_META_DESC',
  MediaByUploaderMaxContentTypeAsc = 'MEDIA_BY_UPLOADER_MAX_CONTENT_TYPE_ASC',
  MediaByUploaderMaxContentTypeDesc = 'MEDIA_BY_UPLOADER_MAX_CONTENT_TYPE_DESC',
  MediaByUploaderMaxUploadCompletedAtAsc = 'MEDIA_BY_UPLOADER_MAX_UPLOAD_COMPLETED_AT_ASC',
  MediaByUploaderMaxUploadCompletedAtDesc = 'MEDIA_BY_UPLOADER_MAX_UPLOAD_COMPLETED_AT_DESC',
  MediaByUploaderMaxCreatedAtAsc = 'MEDIA_BY_UPLOADER_MAX_CREATED_AT_ASC',
  MediaByUploaderMaxCreatedAtDesc = 'MEDIA_BY_UPLOADER_MAX_CREATED_AT_DESC',
  MediaByUploaderMaxUpdatedAtAsc = 'MEDIA_BY_UPLOADER_MAX_UPDATED_AT_ASC',
  MediaByUploaderMaxUpdatedAtDesc = 'MEDIA_BY_UPLOADER_MAX_UPDATED_AT_DESC',
  MediaByUploaderMaxDeletedAtAsc = 'MEDIA_BY_UPLOADER_MAX_DELETED_AT_ASC',
  MediaByUploaderMaxDeletedAtDesc = 'MEDIA_BY_UPLOADER_MAX_DELETED_AT_DESC',
  MediaByUploaderMaxFileNameAsc = 'MEDIA_BY_UPLOADER_MAX_FILE_NAME_ASC',
  MediaByUploaderMaxFileNameDesc = 'MEDIA_BY_UPLOADER_MAX_FILE_NAME_DESC',
  MediaByUploaderAverageIdAsc = 'MEDIA_BY_UPLOADER_AVERAGE_ID_ASC',
  MediaByUploaderAverageIdDesc = 'MEDIA_BY_UPLOADER_AVERAGE_ID_DESC',
  MediaByUploaderAverageCompanyIdAsc = 'MEDIA_BY_UPLOADER_AVERAGE_COMPANY_ID_ASC',
  MediaByUploaderAverageCompanyIdDesc = 'MEDIA_BY_UPLOADER_AVERAGE_COMPANY_ID_DESC',
  MediaByUploaderAverageLabelAsc = 'MEDIA_BY_UPLOADER_AVERAGE_LABEL_ASC',
  MediaByUploaderAverageLabelDesc = 'MEDIA_BY_UPLOADER_AVERAGE_LABEL_DESC',
  MediaByUploaderAverageTypeAsc = 'MEDIA_BY_UPLOADER_AVERAGE_TYPE_ASC',
  MediaByUploaderAverageTypeDesc = 'MEDIA_BY_UPLOADER_AVERAGE_TYPE_DESC',
  MediaByUploaderAverageUploaderAsc = 'MEDIA_BY_UPLOADER_AVERAGE_UPLOADER_ASC',
  MediaByUploaderAverageUploaderDesc = 'MEDIA_BY_UPLOADER_AVERAGE_UPLOADER_DESC',
  MediaByUploaderAverageS3KeyAsc = 'MEDIA_BY_UPLOADER_AVERAGE_S3_KEY_ASC',
  MediaByUploaderAverageS3KeyDesc = 'MEDIA_BY_UPLOADER_AVERAGE_S3_KEY_DESC',
  MediaByUploaderAverageParentIdAsc = 'MEDIA_BY_UPLOADER_AVERAGE_PARENT_ID_ASC',
  MediaByUploaderAverageParentIdDesc = 'MEDIA_BY_UPLOADER_AVERAGE_PARENT_ID_DESC',
  MediaByUploaderAverageParentTypeAsc = 'MEDIA_BY_UPLOADER_AVERAGE_PARENT_TYPE_ASC',
  MediaByUploaderAverageParentTypeDesc = 'MEDIA_BY_UPLOADER_AVERAGE_PARENT_TYPE_DESC',
  MediaByUploaderAverageMetaAsc = 'MEDIA_BY_UPLOADER_AVERAGE_META_ASC',
  MediaByUploaderAverageMetaDesc = 'MEDIA_BY_UPLOADER_AVERAGE_META_DESC',
  MediaByUploaderAverageContentTypeAsc = 'MEDIA_BY_UPLOADER_AVERAGE_CONTENT_TYPE_ASC',
  MediaByUploaderAverageContentTypeDesc = 'MEDIA_BY_UPLOADER_AVERAGE_CONTENT_TYPE_DESC',
  MediaByUploaderAverageUploadCompletedAtAsc = 'MEDIA_BY_UPLOADER_AVERAGE_UPLOAD_COMPLETED_AT_ASC',
  MediaByUploaderAverageUploadCompletedAtDesc = 'MEDIA_BY_UPLOADER_AVERAGE_UPLOAD_COMPLETED_AT_DESC',
  MediaByUploaderAverageCreatedAtAsc = 'MEDIA_BY_UPLOADER_AVERAGE_CREATED_AT_ASC',
  MediaByUploaderAverageCreatedAtDesc = 'MEDIA_BY_UPLOADER_AVERAGE_CREATED_AT_DESC',
  MediaByUploaderAverageUpdatedAtAsc = 'MEDIA_BY_UPLOADER_AVERAGE_UPDATED_AT_ASC',
  MediaByUploaderAverageUpdatedAtDesc = 'MEDIA_BY_UPLOADER_AVERAGE_UPDATED_AT_DESC',
  MediaByUploaderAverageDeletedAtAsc = 'MEDIA_BY_UPLOADER_AVERAGE_DELETED_AT_ASC',
  MediaByUploaderAverageDeletedAtDesc = 'MEDIA_BY_UPLOADER_AVERAGE_DELETED_AT_DESC',
  MediaByUploaderAverageFileNameAsc = 'MEDIA_BY_UPLOADER_AVERAGE_FILE_NAME_ASC',
  MediaByUploaderAverageFileNameDesc = 'MEDIA_BY_UPLOADER_AVERAGE_FILE_NAME_DESC',
  MediaByUploaderStddevSampleIdAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_ID_ASC',
  MediaByUploaderStddevSampleIdDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_ID_DESC',
  MediaByUploaderStddevSampleCompanyIdAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_COMPANY_ID_ASC',
  MediaByUploaderStddevSampleCompanyIdDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_COMPANY_ID_DESC',
  MediaByUploaderStddevSampleLabelAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_LABEL_ASC',
  MediaByUploaderStddevSampleLabelDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_LABEL_DESC',
  MediaByUploaderStddevSampleTypeAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_TYPE_ASC',
  MediaByUploaderStddevSampleTypeDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_TYPE_DESC',
  MediaByUploaderStddevSampleUploaderAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_UPLOADER_ASC',
  MediaByUploaderStddevSampleUploaderDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_UPLOADER_DESC',
  MediaByUploaderStddevSampleS3KeyAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_S3_KEY_ASC',
  MediaByUploaderStddevSampleS3KeyDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_S3_KEY_DESC',
  MediaByUploaderStddevSampleParentIdAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_PARENT_ID_ASC',
  MediaByUploaderStddevSampleParentIdDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_PARENT_ID_DESC',
  MediaByUploaderStddevSampleParentTypeAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_PARENT_TYPE_ASC',
  MediaByUploaderStddevSampleParentTypeDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_PARENT_TYPE_DESC',
  MediaByUploaderStddevSampleMetaAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_META_ASC',
  MediaByUploaderStddevSampleMetaDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_META_DESC',
  MediaByUploaderStddevSampleContentTypeAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_CONTENT_TYPE_ASC',
  MediaByUploaderStddevSampleContentTypeDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_CONTENT_TYPE_DESC',
  MediaByUploaderStddevSampleUploadCompletedAtAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_UPLOAD_COMPLETED_AT_ASC',
  MediaByUploaderStddevSampleUploadCompletedAtDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_UPLOAD_COMPLETED_AT_DESC',
  MediaByUploaderStddevSampleCreatedAtAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_CREATED_AT_ASC',
  MediaByUploaderStddevSampleCreatedAtDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_CREATED_AT_DESC',
  MediaByUploaderStddevSampleUpdatedAtAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_UPDATED_AT_ASC',
  MediaByUploaderStddevSampleUpdatedAtDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_UPDATED_AT_DESC',
  MediaByUploaderStddevSampleDeletedAtAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_DELETED_AT_ASC',
  MediaByUploaderStddevSampleDeletedAtDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_DELETED_AT_DESC',
  MediaByUploaderStddevSampleFileNameAsc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_FILE_NAME_ASC',
  MediaByUploaderStddevSampleFileNameDesc = 'MEDIA_BY_UPLOADER_STDDEV_SAMPLE_FILE_NAME_DESC',
  MediaByUploaderStddevPopulationIdAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_ID_ASC',
  MediaByUploaderStddevPopulationIdDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_ID_DESC',
  MediaByUploaderStddevPopulationCompanyIdAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_COMPANY_ID_ASC',
  MediaByUploaderStddevPopulationCompanyIdDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_COMPANY_ID_DESC',
  MediaByUploaderStddevPopulationLabelAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_LABEL_ASC',
  MediaByUploaderStddevPopulationLabelDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_LABEL_DESC',
  MediaByUploaderStddevPopulationTypeAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_TYPE_ASC',
  MediaByUploaderStddevPopulationTypeDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_TYPE_DESC',
  MediaByUploaderStddevPopulationUploaderAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_UPLOADER_ASC',
  MediaByUploaderStddevPopulationUploaderDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_UPLOADER_DESC',
  MediaByUploaderStddevPopulationS3KeyAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_S3_KEY_ASC',
  MediaByUploaderStddevPopulationS3KeyDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_S3_KEY_DESC',
  MediaByUploaderStddevPopulationParentIdAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_PARENT_ID_ASC',
  MediaByUploaderStddevPopulationParentIdDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_PARENT_ID_DESC',
  MediaByUploaderStddevPopulationParentTypeAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_PARENT_TYPE_ASC',
  MediaByUploaderStddevPopulationParentTypeDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_PARENT_TYPE_DESC',
  MediaByUploaderStddevPopulationMetaAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_META_ASC',
  MediaByUploaderStddevPopulationMetaDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_META_DESC',
  MediaByUploaderStddevPopulationContentTypeAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_CONTENT_TYPE_ASC',
  MediaByUploaderStddevPopulationContentTypeDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_CONTENT_TYPE_DESC',
  MediaByUploaderStddevPopulationUploadCompletedAtAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_UPLOAD_COMPLETED_AT_ASC',
  MediaByUploaderStddevPopulationUploadCompletedAtDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_UPLOAD_COMPLETED_AT_DESC',
  MediaByUploaderStddevPopulationCreatedAtAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_CREATED_AT_ASC',
  MediaByUploaderStddevPopulationCreatedAtDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_CREATED_AT_DESC',
  MediaByUploaderStddevPopulationUpdatedAtAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_UPDATED_AT_ASC',
  MediaByUploaderStddevPopulationUpdatedAtDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_UPDATED_AT_DESC',
  MediaByUploaderStddevPopulationDeletedAtAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_DELETED_AT_ASC',
  MediaByUploaderStddevPopulationDeletedAtDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_DELETED_AT_DESC',
  MediaByUploaderStddevPopulationFileNameAsc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_FILE_NAME_ASC',
  MediaByUploaderStddevPopulationFileNameDesc = 'MEDIA_BY_UPLOADER_STDDEV_POPULATION_FILE_NAME_DESC',
  MediaByUploaderVarianceSampleIdAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_ID_ASC',
  MediaByUploaderVarianceSampleIdDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_ID_DESC',
  MediaByUploaderVarianceSampleCompanyIdAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  MediaByUploaderVarianceSampleCompanyIdDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  MediaByUploaderVarianceSampleLabelAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_LABEL_ASC',
  MediaByUploaderVarianceSampleLabelDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_LABEL_DESC',
  MediaByUploaderVarianceSampleTypeAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_TYPE_ASC',
  MediaByUploaderVarianceSampleTypeDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_TYPE_DESC',
  MediaByUploaderVarianceSampleUploaderAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_UPLOADER_ASC',
  MediaByUploaderVarianceSampleUploaderDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_UPLOADER_DESC',
  MediaByUploaderVarianceSampleS3KeyAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_S3_KEY_ASC',
  MediaByUploaderVarianceSampleS3KeyDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_S3_KEY_DESC',
  MediaByUploaderVarianceSampleParentIdAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_PARENT_ID_ASC',
  MediaByUploaderVarianceSampleParentIdDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_PARENT_ID_DESC',
  MediaByUploaderVarianceSampleParentTypeAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_PARENT_TYPE_ASC',
  MediaByUploaderVarianceSampleParentTypeDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_PARENT_TYPE_DESC',
  MediaByUploaderVarianceSampleMetaAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_META_ASC',
  MediaByUploaderVarianceSampleMetaDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_META_DESC',
  MediaByUploaderVarianceSampleContentTypeAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_CONTENT_TYPE_ASC',
  MediaByUploaderVarianceSampleContentTypeDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_CONTENT_TYPE_DESC',
  MediaByUploaderVarianceSampleUploadCompletedAtAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_UPLOAD_COMPLETED_AT_ASC',
  MediaByUploaderVarianceSampleUploadCompletedAtDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_UPLOAD_COMPLETED_AT_DESC',
  MediaByUploaderVarianceSampleCreatedAtAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_CREATED_AT_ASC',
  MediaByUploaderVarianceSampleCreatedAtDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_CREATED_AT_DESC',
  MediaByUploaderVarianceSampleUpdatedAtAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  MediaByUploaderVarianceSampleUpdatedAtDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  MediaByUploaderVarianceSampleDeletedAtAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_DELETED_AT_ASC',
  MediaByUploaderVarianceSampleDeletedAtDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_DELETED_AT_DESC',
  MediaByUploaderVarianceSampleFileNameAsc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_FILE_NAME_ASC',
  MediaByUploaderVarianceSampleFileNameDesc = 'MEDIA_BY_UPLOADER_VARIANCE_SAMPLE_FILE_NAME_DESC',
  MediaByUploaderVariancePopulationIdAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_ID_ASC',
  MediaByUploaderVariancePopulationIdDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_ID_DESC',
  MediaByUploaderVariancePopulationCompanyIdAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_COMPANY_ID_ASC',
  MediaByUploaderVariancePopulationCompanyIdDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_COMPANY_ID_DESC',
  MediaByUploaderVariancePopulationLabelAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_LABEL_ASC',
  MediaByUploaderVariancePopulationLabelDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_LABEL_DESC',
  MediaByUploaderVariancePopulationTypeAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_TYPE_ASC',
  MediaByUploaderVariancePopulationTypeDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_TYPE_DESC',
  MediaByUploaderVariancePopulationUploaderAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_UPLOADER_ASC',
  MediaByUploaderVariancePopulationUploaderDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_UPLOADER_DESC',
  MediaByUploaderVariancePopulationS3KeyAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_S3_KEY_ASC',
  MediaByUploaderVariancePopulationS3KeyDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_S3_KEY_DESC',
  MediaByUploaderVariancePopulationParentIdAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_PARENT_ID_ASC',
  MediaByUploaderVariancePopulationParentIdDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_PARENT_ID_DESC',
  MediaByUploaderVariancePopulationParentTypeAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_PARENT_TYPE_ASC',
  MediaByUploaderVariancePopulationParentTypeDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_PARENT_TYPE_DESC',
  MediaByUploaderVariancePopulationMetaAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_META_ASC',
  MediaByUploaderVariancePopulationMetaDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_META_DESC',
  MediaByUploaderVariancePopulationContentTypeAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_CONTENT_TYPE_ASC',
  MediaByUploaderVariancePopulationContentTypeDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_CONTENT_TYPE_DESC',
  MediaByUploaderVariancePopulationUploadCompletedAtAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_UPLOAD_COMPLETED_AT_ASC',
  MediaByUploaderVariancePopulationUploadCompletedAtDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_UPLOAD_COMPLETED_AT_DESC',
  MediaByUploaderVariancePopulationCreatedAtAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_CREATED_AT_ASC',
  MediaByUploaderVariancePopulationCreatedAtDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_CREATED_AT_DESC',
  MediaByUploaderVariancePopulationUpdatedAtAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_UPDATED_AT_ASC',
  MediaByUploaderVariancePopulationUpdatedAtDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_UPDATED_AT_DESC',
  MediaByUploaderVariancePopulationDeletedAtAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_DELETED_AT_ASC',
  MediaByUploaderVariancePopulationDeletedAtDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_DELETED_AT_DESC',
  MediaByUploaderVariancePopulationFileNameAsc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_FILE_NAME_ASC',
  MediaByUploaderVariancePopulationFileNameDesc = 'MEDIA_BY_UPLOADER_VARIANCE_POPULATION_FILE_NAME_DESC',
  CompanyChannelMediasByAssigneeIdCountAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_COUNT_ASC',
  CompanyChannelMediasByAssigneeIdCountDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_COUNT_DESC',
  CompanyChannelMediasByAssigneeIdSumIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_ID_ASC',
  CompanyChannelMediasByAssigneeIdSumIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_ID_DESC',
  CompanyChannelMediasByAssigneeIdSumCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasByAssigneeIdSumCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasByAssigneeIdSumMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_MEDIA_ID_ASC',
  CompanyChannelMediasByAssigneeIdSumMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_MEDIA_ID_DESC',
  CompanyChannelMediasByAssigneeIdSumAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_ASSIGNEE_ID_ASC',
  CompanyChannelMediasByAssigneeIdSumAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_ASSIGNEE_ID_DESC',
  CompanyChannelMediasByAssigneeIdSumCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_CREATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdSumCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_CREATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdSumUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_UPDATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdSumUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_UPDATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdSumDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_DELETED_AT_ASC',
  CompanyChannelMediasByAssigneeIdSumDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_SUM_DELETED_AT_DESC',
  CompanyChannelMediasByAssigneeIdDistinctCountIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ID_ASC',
  CompanyChannelMediasByAssigneeIdDistinctCountIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ID_DESC',
  CompanyChannelMediasByAssigneeIdDistinctCountCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasByAssigneeIdDistinctCountCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasByAssigneeIdDistinctCountMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_MEDIA_ID_ASC',
  CompanyChannelMediasByAssigneeIdDistinctCountMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_MEDIA_ID_DESC',
  CompanyChannelMediasByAssigneeIdDistinctCountAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  CompanyChannelMediasByAssigneeIdDistinctCountAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  CompanyChannelMediasByAssigneeIdDistinctCountCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdDistinctCountCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdDistinctCountUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdDistinctCountUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdDistinctCountDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_DELETED_AT_ASC',
  CompanyChannelMediasByAssigneeIdDistinctCountDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_DISTINCT_COUNT_DELETED_AT_DESC',
  CompanyChannelMediasByAssigneeIdMinIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_ID_ASC',
  CompanyChannelMediasByAssigneeIdMinIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_ID_DESC',
  CompanyChannelMediasByAssigneeIdMinCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasByAssigneeIdMinCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasByAssigneeIdMinMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_MEDIA_ID_ASC',
  CompanyChannelMediasByAssigneeIdMinMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_MEDIA_ID_DESC',
  CompanyChannelMediasByAssigneeIdMinAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_ASSIGNEE_ID_ASC',
  CompanyChannelMediasByAssigneeIdMinAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_ASSIGNEE_ID_DESC',
  CompanyChannelMediasByAssigneeIdMinCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_CREATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdMinCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_CREATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdMinUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_UPDATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdMinUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_UPDATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdMinDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_DELETED_AT_ASC',
  CompanyChannelMediasByAssigneeIdMinDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MIN_DELETED_AT_DESC',
  CompanyChannelMediasByAssigneeIdMaxIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_ID_ASC',
  CompanyChannelMediasByAssigneeIdMaxIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_ID_DESC',
  CompanyChannelMediasByAssigneeIdMaxCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasByAssigneeIdMaxCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasByAssigneeIdMaxMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_MEDIA_ID_ASC',
  CompanyChannelMediasByAssigneeIdMaxMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_MEDIA_ID_DESC',
  CompanyChannelMediasByAssigneeIdMaxAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_ASSIGNEE_ID_ASC',
  CompanyChannelMediasByAssigneeIdMaxAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_ASSIGNEE_ID_DESC',
  CompanyChannelMediasByAssigneeIdMaxCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_CREATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdMaxCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_CREATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdMaxUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_UPDATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdMaxUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_UPDATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdMaxDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_DELETED_AT_ASC',
  CompanyChannelMediasByAssigneeIdMaxDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_MAX_DELETED_AT_DESC',
  CompanyChannelMediasByAssigneeIdAverageIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_ID_ASC',
  CompanyChannelMediasByAssigneeIdAverageIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_ID_DESC',
  CompanyChannelMediasByAssigneeIdAverageCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasByAssigneeIdAverageCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasByAssigneeIdAverageMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_MEDIA_ID_ASC',
  CompanyChannelMediasByAssigneeIdAverageMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_MEDIA_ID_DESC',
  CompanyChannelMediasByAssigneeIdAverageAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_ASSIGNEE_ID_ASC',
  CompanyChannelMediasByAssigneeIdAverageAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_ASSIGNEE_ID_DESC',
  CompanyChannelMediasByAssigneeIdAverageCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_CREATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdAverageCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_CREATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdAverageUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_UPDATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdAverageUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_UPDATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdAverageDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_DELETED_AT_ASC',
  CompanyChannelMediasByAssigneeIdAverageDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_AVERAGE_DELETED_AT_DESC',
  CompanyChannelMediasByAssigneeIdStddevSampleIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ID_ASC',
  CompanyChannelMediasByAssigneeIdStddevSampleIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ID_DESC',
  CompanyChannelMediasByAssigneeIdStddevSampleCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasByAssigneeIdStddevSampleCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasByAssigneeIdStddevSampleMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_MEDIA_ID_ASC',
  CompanyChannelMediasByAssigneeIdStddevSampleMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_MEDIA_ID_DESC',
  CompanyChannelMediasByAssigneeIdStddevSampleAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  CompanyChannelMediasByAssigneeIdStddevSampleAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  CompanyChannelMediasByAssigneeIdStddevSampleCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdStddevSampleCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdStddevSampleUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdStddevSampleUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdStddevSampleDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_DELETED_AT_ASC',
  CompanyChannelMediasByAssigneeIdStddevSampleDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_DELETED_AT_DESC',
  CompanyChannelMediasByAssigneeIdStddevPopulationIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ID_ASC',
  CompanyChannelMediasByAssigneeIdStddevPopulationIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ID_DESC',
  CompanyChannelMediasByAssigneeIdStddevPopulationCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasByAssigneeIdStddevPopulationCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasByAssigneeIdStddevPopulationMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_MEDIA_ID_ASC',
  CompanyChannelMediasByAssigneeIdStddevPopulationMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_MEDIA_ID_DESC',
  CompanyChannelMediasByAssigneeIdStddevPopulationAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  CompanyChannelMediasByAssigneeIdStddevPopulationAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  CompanyChannelMediasByAssigneeIdStddevPopulationCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdStddevPopulationCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdStddevPopulationUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdStddevPopulationUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdStddevPopulationDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_DELETED_AT_ASC',
  CompanyChannelMediasByAssigneeIdStddevPopulationDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_STDDEV_POPULATION_DELETED_AT_DESC',
  CompanyChannelMediasByAssigneeIdVarianceSampleIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ID_ASC',
  CompanyChannelMediasByAssigneeIdVarianceSampleIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ID_DESC',
  CompanyChannelMediasByAssigneeIdVarianceSampleCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasByAssigneeIdVarianceSampleCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasByAssigneeIdVarianceSampleMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_MEDIA_ID_ASC',
  CompanyChannelMediasByAssigneeIdVarianceSampleMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_MEDIA_ID_DESC',
  CompanyChannelMediasByAssigneeIdVarianceSampleAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  CompanyChannelMediasByAssigneeIdVarianceSampleAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  CompanyChannelMediasByAssigneeIdVarianceSampleCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdVarianceSampleCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdVarianceSampleUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdVarianceSampleUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdVarianceSampleDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_DELETED_AT_ASC',
  CompanyChannelMediasByAssigneeIdVarianceSampleDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_DELETED_AT_DESC',
  CompanyChannelMediasByAssigneeIdVariancePopulationIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ID_ASC',
  CompanyChannelMediasByAssigneeIdVariancePopulationIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ID_DESC',
  CompanyChannelMediasByAssigneeIdVariancePopulationCompanyChannelIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  CompanyChannelMediasByAssigneeIdVariancePopulationCompanyChannelIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  CompanyChannelMediasByAssigneeIdVariancePopulationMediaIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_MEDIA_ID_ASC',
  CompanyChannelMediasByAssigneeIdVariancePopulationMediaIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_MEDIA_ID_DESC',
  CompanyChannelMediasByAssigneeIdVariancePopulationAssigneeIdAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  CompanyChannelMediasByAssigneeIdVariancePopulationAssigneeIdDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  CompanyChannelMediasByAssigneeIdVariancePopulationCreatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdVariancePopulationCreatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdVariancePopulationUpdatedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyChannelMediasByAssigneeIdVariancePopulationUpdatedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyChannelMediasByAssigneeIdVariancePopulationDeletedAtAsc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_DELETED_AT_ASC',
  CompanyChannelMediasByAssigneeIdVariancePopulationDeletedAtDesc = 'COMPANY_CHANNEL_MEDIAS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_DELETED_AT_DESC',
  AuditLogsCountAsc = 'AUDIT_LOGS_COUNT_ASC',
  AuditLogsCountDesc = 'AUDIT_LOGS_COUNT_DESC',
  AuditLogsSumEventIdAsc = 'AUDIT_LOGS_SUM_EVENT_ID_ASC',
  AuditLogsSumEventIdDesc = 'AUDIT_LOGS_SUM_EVENT_ID_DESC',
  AuditLogsSumParentTypeAsc = 'AUDIT_LOGS_SUM_PARENT_TYPE_ASC',
  AuditLogsSumParentTypeDesc = 'AUDIT_LOGS_SUM_PARENT_TYPE_DESC',
  AuditLogsSumParentIdAsc = 'AUDIT_LOGS_SUM_PARENT_ID_ASC',
  AuditLogsSumParentIdDesc = 'AUDIT_LOGS_SUM_PARENT_ID_DESC',
  AuditLogsSumEventTypeAsc = 'AUDIT_LOGS_SUM_EVENT_TYPE_ASC',
  AuditLogsSumEventTypeDesc = 'AUDIT_LOGS_SUM_EVENT_TYPE_DESC',
  AuditLogsSumDbRoleAsc = 'AUDIT_LOGS_SUM_DB_ROLE_ASC',
  AuditLogsSumDbRoleDesc = 'AUDIT_LOGS_SUM_DB_ROLE_DESC',
  AuditLogsSumUserIdAsc = 'AUDIT_LOGS_SUM_USER_ID_ASC',
  AuditLogsSumUserIdDesc = 'AUDIT_LOGS_SUM_USER_ID_DESC',
  AuditLogsSumEmailAsc = 'AUDIT_LOGS_SUM_EMAIL_ASC',
  AuditLogsSumEmailDesc = 'AUDIT_LOGS_SUM_EMAIL_DESC',
  AuditLogsSumOldValueAsc = 'AUDIT_LOGS_SUM_OLD_VALUE_ASC',
  AuditLogsSumOldValueDesc = 'AUDIT_LOGS_SUM_OLD_VALUE_DESC',
  AuditLogsSumNewValueAsc = 'AUDIT_LOGS_SUM_NEW_VALUE_ASC',
  AuditLogsSumNewValueDesc = 'AUDIT_LOGS_SUM_NEW_VALUE_DESC',
  AuditLogsSumEventTimestampAsc = 'AUDIT_LOGS_SUM_EVENT_TIMESTAMP_ASC',
  AuditLogsSumEventTimestampDesc = 'AUDIT_LOGS_SUM_EVENT_TIMESTAMP_DESC',
  AuditLogsSumLeadIdAsc = 'AUDIT_LOGS_SUM_LEAD_ID_ASC',
  AuditLogsSumLeadIdDesc = 'AUDIT_LOGS_SUM_LEAD_ID_DESC',
  AuditLogsDistinctCountEventIdAsc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_ID_ASC',
  AuditLogsDistinctCountEventIdDesc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_ID_DESC',
  AuditLogsDistinctCountParentTypeAsc = 'AUDIT_LOGS_DISTINCT_COUNT_PARENT_TYPE_ASC',
  AuditLogsDistinctCountParentTypeDesc = 'AUDIT_LOGS_DISTINCT_COUNT_PARENT_TYPE_DESC',
  AuditLogsDistinctCountParentIdAsc = 'AUDIT_LOGS_DISTINCT_COUNT_PARENT_ID_ASC',
  AuditLogsDistinctCountParentIdDesc = 'AUDIT_LOGS_DISTINCT_COUNT_PARENT_ID_DESC',
  AuditLogsDistinctCountEventTypeAsc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_TYPE_ASC',
  AuditLogsDistinctCountEventTypeDesc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_TYPE_DESC',
  AuditLogsDistinctCountDbRoleAsc = 'AUDIT_LOGS_DISTINCT_COUNT_DB_ROLE_ASC',
  AuditLogsDistinctCountDbRoleDesc = 'AUDIT_LOGS_DISTINCT_COUNT_DB_ROLE_DESC',
  AuditLogsDistinctCountUserIdAsc = 'AUDIT_LOGS_DISTINCT_COUNT_USER_ID_ASC',
  AuditLogsDistinctCountUserIdDesc = 'AUDIT_LOGS_DISTINCT_COUNT_USER_ID_DESC',
  AuditLogsDistinctCountEmailAsc = 'AUDIT_LOGS_DISTINCT_COUNT_EMAIL_ASC',
  AuditLogsDistinctCountEmailDesc = 'AUDIT_LOGS_DISTINCT_COUNT_EMAIL_DESC',
  AuditLogsDistinctCountOldValueAsc = 'AUDIT_LOGS_DISTINCT_COUNT_OLD_VALUE_ASC',
  AuditLogsDistinctCountOldValueDesc = 'AUDIT_LOGS_DISTINCT_COUNT_OLD_VALUE_DESC',
  AuditLogsDistinctCountNewValueAsc = 'AUDIT_LOGS_DISTINCT_COUNT_NEW_VALUE_ASC',
  AuditLogsDistinctCountNewValueDesc = 'AUDIT_LOGS_DISTINCT_COUNT_NEW_VALUE_DESC',
  AuditLogsDistinctCountEventTimestampAsc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_TIMESTAMP_ASC',
  AuditLogsDistinctCountEventTimestampDesc = 'AUDIT_LOGS_DISTINCT_COUNT_EVENT_TIMESTAMP_DESC',
  AuditLogsDistinctCountLeadIdAsc = 'AUDIT_LOGS_DISTINCT_COUNT_LEAD_ID_ASC',
  AuditLogsDistinctCountLeadIdDesc = 'AUDIT_LOGS_DISTINCT_COUNT_LEAD_ID_DESC',
  AuditLogsMinEventIdAsc = 'AUDIT_LOGS_MIN_EVENT_ID_ASC',
  AuditLogsMinEventIdDesc = 'AUDIT_LOGS_MIN_EVENT_ID_DESC',
  AuditLogsMinParentTypeAsc = 'AUDIT_LOGS_MIN_PARENT_TYPE_ASC',
  AuditLogsMinParentTypeDesc = 'AUDIT_LOGS_MIN_PARENT_TYPE_DESC',
  AuditLogsMinParentIdAsc = 'AUDIT_LOGS_MIN_PARENT_ID_ASC',
  AuditLogsMinParentIdDesc = 'AUDIT_LOGS_MIN_PARENT_ID_DESC',
  AuditLogsMinEventTypeAsc = 'AUDIT_LOGS_MIN_EVENT_TYPE_ASC',
  AuditLogsMinEventTypeDesc = 'AUDIT_LOGS_MIN_EVENT_TYPE_DESC',
  AuditLogsMinDbRoleAsc = 'AUDIT_LOGS_MIN_DB_ROLE_ASC',
  AuditLogsMinDbRoleDesc = 'AUDIT_LOGS_MIN_DB_ROLE_DESC',
  AuditLogsMinUserIdAsc = 'AUDIT_LOGS_MIN_USER_ID_ASC',
  AuditLogsMinUserIdDesc = 'AUDIT_LOGS_MIN_USER_ID_DESC',
  AuditLogsMinEmailAsc = 'AUDIT_LOGS_MIN_EMAIL_ASC',
  AuditLogsMinEmailDesc = 'AUDIT_LOGS_MIN_EMAIL_DESC',
  AuditLogsMinOldValueAsc = 'AUDIT_LOGS_MIN_OLD_VALUE_ASC',
  AuditLogsMinOldValueDesc = 'AUDIT_LOGS_MIN_OLD_VALUE_DESC',
  AuditLogsMinNewValueAsc = 'AUDIT_LOGS_MIN_NEW_VALUE_ASC',
  AuditLogsMinNewValueDesc = 'AUDIT_LOGS_MIN_NEW_VALUE_DESC',
  AuditLogsMinEventTimestampAsc = 'AUDIT_LOGS_MIN_EVENT_TIMESTAMP_ASC',
  AuditLogsMinEventTimestampDesc = 'AUDIT_LOGS_MIN_EVENT_TIMESTAMP_DESC',
  AuditLogsMinLeadIdAsc = 'AUDIT_LOGS_MIN_LEAD_ID_ASC',
  AuditLogsMinLeadIdDesc = 'AUDIT_LOGS_MIN_LEAD_ID_DESC',
  AuditLogsMaxEventIdAsc = 'AUDIT_LOGS_MAX_EVENT_ID_ASC',
  AuditLogsMaxEventIdDesc = 'AUDIT_LOGS_MAX_EVENT_ID_DESC',
  AuditLogsMaxParentTypeAsc = 'AUDIT_LOGS_MAX_PARENT_TYPE_ASC',
  AuditLogsMaxParentTypeDesc = 'AUDIT_LOGS_MAX_PARENT_TYPE_DESC',
  AuditLogsMaxParentIdAsc = 'AUDIT_LOGS_MAX_PARENT_ID_ASC',
  AuditLogsMaxParentIdDesc = 'AUDIT_LOGS_MAX_PARENT_ID_DESC',
  AuditLogsMaxEventTypeAsc = 'AUDIT_LOGS_MAX_EVENT_TYPE_ASC',
  AuditLogsMaxEventTypeDesc = 'AUDIT_LOGS_MAX_EVENT_TYPE_DESC',
  AuditLogsMaxDbRoleAsc = 'AUDIT_LOGS_MAX_DB_ROLE_ASC',
  AuditLogsMaxDbRoleDesc = 'AUDIT_LOGS_MAX_DB_ROLE_DESC',
  AuditLogsMaxUserIdAsc = 'AUDIT_LOGS_MAX_USER_ID_ASC',
  AuditLogsMaxUserIdDesc = 'AUDIT_LOGS_MAX_USER_ID_DESC',
  AuditLogsMaxEmailAsc = 'AUDIT_LOGS_MAX_EMAIL_ASC',
  AuditLogsMaxEmailDesc = 'AUDIT_LOGS_MAX_EMAIL_DESC',
  AuditLogsMaxOldValueAsc = 'AUDIT_LOGS_MAX_OLD_VALUE_ASC',
  AuditLogsMaxOldValueDesc = 'AUDIT_LOGS_MAX_OLD_VALUE_DESC',
  AuditLogsMaxNewValueAsc = 'AUDIT_LOGS_MAX_NEW_VALUE_ASC',
  AuditLogsMaxNewValueDesc = 'AUDIT_LOGS_MAX_NEW_VALUE_DESC',
  AuditLogsMaxEventTimestampAsc = 'AUDIT_LOGS_MAX_EVENT_TIMESTAMP_ASC',
  AuditLogsMaxEventTimestampDesc = 'AUDIT_LOGS_MAX_EVENT_TIMESTAMP_DESC',
  AuditLogsMaxLeadIdAsc = 'AUDIT_LOGS_MAX_LEAD_ID_ASC',
  AuditLogsMaxLeadIdDesc = 'AUDIT_LOGS_MAX_LEAD_ID_DESC',
  AuditLogsAverageEventIdAsc = 'AUDIT_LOGS_AVERAGE_EVENT_ID_ASC',
  AuditLogsAverageEventIdDesc = 'AUDIT_LOGS_AVERAGE_EVENT_ID_DESC',
  AuditLogsAverageParentTypeAsc = 'AUDIT_LOGS_AVERAGE_PARENT_TYPE_ASC',
  AuditLogsAverageParentTypeDesc = 'AUDIT_LOGS_AVERAGE_PARENT_TYPE_DESC',
  AuditLogsAverageParentIdAsc = 'AUDIT_LOGS_AVERAGE_PARENT_ID_ASC',
  AuditLogsAverageParentIdDesc = 'AUDIT_LOGS_AVERAGE_PARENT_ID_DESC',
  AuditLogsAverageEventTypeAsc = 'AUDIT_LOGS_AVERAGE_EVENT_TYPE_ASC',
  AuditLogsAverageEventTypeDesc = 'AUDIT_LOGS_AVERAGE_EVENT_TYPE_DESC',
  AuditLogsAverageDbRoleAsc = 'AUDIT_LOGS_AVERAGE_DB_ROLE_ASC',
  AuditLogsAverageDbRoleDesc = 'AUDIT_LOGS_AVERAGE_DB_ROLE_DESC',
  AuditLogsAverageUserIdAsc = 'AUDIT_LOGS_AVERAGE_USER_ID_ASC',
  AuditLogsAverageUserIdDesc = 'AUDIT_LOGS_AVERAGE_USER_ID_DESC',
  AuditLogsAverageEmailAsc = 'AUDIT_LOGS_AVERAGE_EMAIL_ASC',
  AuditLogsAverageEmailDesc = 'AUDIT_LOGS_AVERAGE_EMAIL_DESC',
  AuditLogsAverageOldValueAsc = 'AUDIT_LOGS_AVERAGE_OLD_VALUE_ASC',
  AuditLogsAverageOldValueDesc = 'AUDIT_LOGS_AVERAGE_OLD_VALUE_DESC',
  AuditLogsAverageNewValueAsc = 'AUDIT_LOGS_AVERAGE_NEW_VALUE_ASC',
  AuditLogsAverageNewValueDesc = 'AUDIT_LOGS_AVERAGE_NEW_VALUE_DESC',
  AuditLogsAverageEventTimestampAsc = 'AUDIT_LOGS_AVERAGE_EVENT_TIMESTAMP_ASC',
  AuditLogsAverageEventTimestampDesc = 'AUDIT_LOGS_AVERAGE_EVENT_TIMESTAMP_DESC',
  AuditLogsAverageLeadIdAsc = 'AUDIT_LOGS_AVERAGE_LEAD_ID_ASC',
  AuditLogsAverageLeadIdDesc = 'AUDIT_LOGS_AVERAGE_LEAD_ID_DESC',
  AuditLogsStddevSampleEventIdAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_ID_ASC',
  AuditLogsStddevSampleEventIdDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_ID_DESC',
  AuditLogsStddevSampleParentTypeAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_PARENT_TYPE_ASC',
  AuditLogsStddevSampleParentTypeDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_PARENT_TYPE_DESC',
  AuditLogsStddevSampleParentIdAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_PARENT_ID_ASC',
  AuditLogsStddevSampleParentIdDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_PARENT_ID_DESC',
  AuditLogsStddevSampleEventTypeAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  AuditLogsStddevSampleEventTypeDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  AuditLogsStddevSampleDbRoleAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_DB_ROLE_ASC',
  AuditLogsStddevSampleDbRoleDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_DB_ROLE_DESC',
  AuditLogsStddevSampleUserIdAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_USER_ID_ASC',
  AuditLogsStddevSampleUserIdDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_USER_ID_DESC',
  AuditLogsStddevSampleEmailAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_EMAIL_ASC',
  AuditLogsStddevSampleEmailDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_EMAIL_DESC',
  AuditLogsStddevSampleOldValueAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_OLD_VALUE_ASC',
  AuditLogsStddevSampleOldValueDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_OLD_VALUE_DESC',
  AuditLogsStddevSampleNewValueAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_NEW_VALUE_ASC',
  AuditLogsStddevSampleNewValueDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_NEW_VALUE_DESC',
  AuditLogsStddevSampleEventTimestampAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_TIMESTAMP_ASC',
  AuditLogsStddevSampleEventTimestampDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_EVENT_TIMESTAMP_DESC',
  AuditLogsStddevSampleLeadIdAsc = 'AUDIT_LOGS_STDDEV_SAMPLE_LEAD_ID_ASC',
  AuditLogsStddevSampleLeadIdDesc = 'AUDIT_LOGS_STDDEV_SAMPLE_LEAD_ID_DESC',
  AuditLogsStddevPopulationEventIdAsc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_ID_ASC',
  AuditLogsStddevPopulationEventIdDesc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_ID_DESC',
  AuditLogsStddevPopulationParentTypeAsc = 'AUDIT_LOGS_STDDEV_POPULATION_PARENT_TYPE_ASC',
  AuditLogsStddevPopulationParentTypeDesc = 'AUDIT_LOGS_STDDEV_POPULATION_PARENT_TYPE_DESC',
  AuditLogsStddevPopulationParentIdAsc = 'AUDIT_LOGS_STDDEV_POPULATION_PARENT_ID_ASC',
  AuditLogsStddevPopulationParentIdDesc = 'AUDIT_LOGS_STDDEV_POPULATION_PARENT_ID_DESC',
  AuditLogsStddevPopulationEventTypeAsc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_TYPE_ASC',
  AuditLogsStddevPopulationEventTypeDesc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_TYPE_DESC',
  AuditLogsStddevPopulationDbRoleAsc = 'AUDIT_LOGS_STDDEV_POPULATION_DB_ROLE_ASC',
  AuditLogsStddevPopulationDbRoleDesc = 'AUDIT_LOGS_STDDEV_POPULATION_DB_ROLE_DESC',
  AuditLogsStddevPopulationUserIdAsc = 'AUDIT_LOGS_STDDEV_POPULATION_USER_ID_ASC',
  AuditLogsStddevPopulationUserIdDesc = 'AUDIT_LOGS_STDDEV_POPULATION_USER_ID_DESC',
  AuditLogsStddevPopulationEmailAsc = 'AUDIT_LOGS_STDDEV_POPULATION_EMAIL_ASC',
  AuditLogsStddevPopulationEmailDesc = 'AUDIT_LOGS_STDDEV_POPULATION_EMAIL_DESC',
  AuditLogsStddevPopulationOldValueAsc = 'AUDIT_LOGS_STDDEV_POPULATION_OLD_VALUE_ASC',
  AuditLogsStddevPopulationOldValueDesc = 'AUDIT_LOGS_STDDEV_POPULATION_OLD_VALUE_DESC',
  AuditLogsStddevPopulationNewValueAsc = 'AUDIT_LOGS_STDDEV_POPULATION_NEW_VALUE_ASC',
  AuditLogsStddevPopulationNewValueDesc = 'AUDIT_LOGS_STDDEV_POPULATION_NEW_VALUE_DESC',
  AuditLogsStddevPopulationEventTimestampAsc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_TIMESTAMP_ASC',
  AuditLogsStddevPopulationEventTimestampDesc = 'AUDIT_LOGS_STDDEV_POPULATION_EVENT_TIMESTAMP_DESC',
  AuditLogsStddevPopulationLeadIdAsc = 'AUDIT_LOGS_STDDEV_POPULATION_LEAD_ID_ASC',
  AuditLogsStddevPopulationLeadIdDesc = 'AUDIT_LOGS_STDDEV_POPULATION_LEAD_ID_DESC',
  AuditLogsVarianceSampleEventIdAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_ID_ASC',
  AuditLogsVarianceSampleEventIdDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_ID_DESC',
  AuditLogsVarianceSampleParentTypeAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_PARENT_TYPE_ASC',
  AuditLogsVarianceSampleParentTypeDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_PARENT_TYPE_DESC',
  AuditLogsVarianceSampleParentIdAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_PARENT_ID_ASC',
  AuditLogsVarianceSampleParentIdDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_PARENT_ID_DESC',
  AuditLogsVarianceSampleEventTypeAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  AuditLogsVarianceSampleEventTypeDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  AuditLogsVarianceSampleDbRoleAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_DB_ROLE_ASC',
  AuditLogsVarianceSampleDbRoleDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_DB_ROLE_DESC',
  AuditLogsVarianceSampleUserIdAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_USER_ID_ASC',
  AuditLogsVarianceSampleUserIdDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_USER_ID_DESC',
  AuditLogsVarianceSampleEmailAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EMAIL_ASC',
  AuditLogsVarianceSampleEmailDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EMAIL_DESC',
  AuditLogsVarianceSampleOldValueAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_OLD_VALUE_ASC',
  AuditLogsVarianceSampleOldValueDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_OLD_VALUE_DESC',
  AuditLogsVarianceSampleNewValueAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_NEW_VALUE_ASC',
  AuditLogsVarianceSampleNewValueDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_NEW_VALUE_DESC',
  AuditLogsVarianceSampleEventTimestampAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_TIMESTAMP_ASC',
  AuditLogsVarianceSampleEventTimestampDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_EVENT_TIMESTAMP_DESC',
  AuditLogsVarianceSampleLeadIdAsc = 'AUDIT_LOGS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  AuditLogsVarianceSampleLeadIdDesc = 'AUDIT_LOGS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  AuditLogsVariancePopulationEventIdAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_ID_ASC',
  AuditLogsVariancePopulationEventIdDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_ID_DESC',
  AuditLogsVariancePopulationParentTypeAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_PARENT_TYPE_ASC',
  AuditLogsVariancePopulationParentTypeDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_PARENT_TYPE_DESC',
  AuditLogsVariancePopulationParentIdAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_PARENT_ID_ASC',
  AuditLogsVariancePopulationParentIdDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_PARENT_ID_DESC',
  AuditLogsVariancePopulationEventTypeAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  AuditLogsVariancePopulationEventTypeDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  AuditLogsVariancePopulationDbRoleAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_DB_ROLE_ASC',
  AuditLogsVariancePopulationDbRoleDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_DB_ROLE_DESC',
  AuditLogsVariancePopulationUserIdAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_USER_ID_ASC',
  AuditLogsVariancePopulationUserIdDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_USER_ID_DESC',
  AuditLogsVariancePopulationEmailAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_EMAIL_ASC',
  AuditLogsVariancePopulationEmailDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_EMAIL_DESC',
  AuditLogsVariancePopulationOldValueAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_OLD_VALUE_ASC',
  AuditLogsVariancePopulationOldValueDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_OLD_VALUE_DESC',
  AuditLogsVariancePopulationNewValueAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_NEW_VALUE_ASC',
  AuditLogsVariancePopulationNewValueDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_NEW_VALUE_DESC',
  AuditLogsVariancePopulationEventTimestampAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_TIMESTAMP_ASC',
  AuditLogsVariancePopulationEventTimestampDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_EVENT_TIMESTAMP_DESC',
  AuditLogsVariancePopulationLeadIdAsc = 'AUDIT_LOGS_VARIANCE_POPULATION_LEAD_ID_ASC',
  AuditLogsVariancePopulationLeadIdDesc = 'AUDIT_LOGS_VARIANCE_POPULATION_LEAD_ID_DESC'
}

export type ValidateCreditAppApplicationResponse = {
  creditorId: Scalars['String'];
  applicationId: Scalars['String'];
  status: Status;
  advancedStatus: AdvancedStatus;
  failureReasons: Array<Scalars['String']>;
  advancedFailureReasons: Array<Scalars['String']>;
};

export type ValidatePrequalApplicationResponse = {
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<LenderValidationError>>>;
};

export type VehicleBooking = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  lenderProgramId?: Maybe<Scalars['Int']>;
  minKm?: Maybe<Scalars['Int']>;
  maxKm?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  isNew: Scalars['Boolean'];
  cbbKey: Scalars['String'];
  term: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `LenderProgram` that is related to this `VehicleBooking`. */
  lenderProgram?: Maybe<LenderProgram>;
};

export type VehicleBookingAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<VehicleBookingSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<VehicleBookingDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<VehicleBookingMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<VehicleBookingMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<VehicleBookingAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<VehicleBookingStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<VehicleBookingStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<VehicleBookingVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<VehicleBookingVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `VehicleBooking` object types. */
export type VehicleBookingAggregatesFilter = {
  /** A filter that must pass for the relevant `VehicleBooking` object to be included within the aggregate. */
  filter?: Maybe<VehicleBookingFilter>;
  /** Sum aggregate over matching `VehicleBooking` objects. */
  sum?: Maybe<VehicleBookingSumAggregateFilter>;
  /** Distinct count aggregate over matching `VehicleBooking` objects. */
  distinctCount?: Maybe<VehicleBookingDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `VehicleBooking` objects. */
  min?: Maybe<VehicleBookingMinAggregateFilter>;
  /** Maximum aggregate over matching `VehicleBooking` objects. */
  max?: Maybe<VehicleBookingMaxAggregateFilter>;
  /** Mean average aggregate over matching `VehicleBooking` objects. */
  average?: Maybe<VehicleBookingAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `VehicleBooking` objects. */
  stddevSample?: Maybe<VehicleBookingStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `VehicleBooking` objects. */
  stddevPopulation?: Maybe<VehicleBookingStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `VehicleBooking` objects. */
  varianceSample?: Maybe<VehicleBookingVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `VehicleBooking` objects. */
  variancePopulation?: Maybe<VehicleBookingVariancePopulationAggregateFilter>;
};

export type VehicleBookingAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
  minKm?: Maybe<BigFloatFilter>;
  maxKm?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
};

export type VehicleBookingAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of minKm across the matching connection */
  minKm?: Maybe<Scalars['BigFloat']>;
  /** Mean average of maxKm across the matching connection */
  maxKm?: Maybe<Scalars['BigFloat']>;
  /** Mean average of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `VehicleBooking` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VehicleBookingCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lenderProgramId` field. */
  lenderProgramId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minKm` field. */
  minKm?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxKm` field. */
  maxKm?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minYear` field. */
  minYear?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxYear` field. */
  maxYear?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isNew` field. */
  isNew?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `cbbKey` field. */
  cbbKey?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `term` field. */
  term?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type VehicleBookingDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderProgramId?: Maybe<BigIntFilter>;
  minKm?: Maybe<BigIntFilter>;
  maxKm?: Maybe<BigIntFilter>;
  minYear?: Maybe<BigIntFilter>;
  maxYear?: Maybe<BigIntFilter>;
  isNew?: Maybe<BigIntFilter>;
  cbbKey?: Maybe<BigIntFilter>;
  term?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type VehicleBookingDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minKm across the matching connection */
  minKm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxKm across the matching connection */
  maxKm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isNew across the matching connection */
  isNew?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cbbKey across the matching connection */
  cbbKey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of term across the matching connection */
  term?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `VehicleBooking` object types. All fields are combined with a logical ‘and.’ */
export type VehicleBookingFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `lenderProgramId` field. */
  lenderProgramId?: Maybe<IntFilter>;
  /** Filter by the object’s `minKm` field. */
  minKm?: Maybe<IntFilter>;
  /** Filter by the object’s `maxKm` field. */
  maxKm?: Maybe<IntFilter>;
  /** Filter by the object’s `minYear` field. */
  minYear?: Maybe<IntFilter>;
  /** Filter by the object’s `maxYear` field. */
  maxYear?: Maybe<IntFilter>;
  /** Filter by the object’s `isNew` field. */
  isNew?: Maybe<BooleanFilter>;
  /** Filter by the object’s `cbbKey` field. */
  cbbKey?: Maybe<StringFilter>;
  /** Filter by the object’s `term` field. */
  term?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lenderProgram` relation. */
  lenderProgram?: Maybe<LenderProgramFilter>;
  /** A related `lenderProgram` exists. */
  lenderProgramExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<VehicleBookingFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<VehicleBookingFilter>>;
  /** Negates the expression. */
  not?: Maybe<VehicleBookingFilter>;
};

/** Grouping methods for `VehicleBooking` for usage during aggregation. */
export enum VehicleBookingGroupBy {
  LenderProgramId = 'LENDER_PROGRAM_ID',
  MinKm = 'MIN_KM',
  MaxKm = 'MAX_KM',
  MinYear = 'MIN_YEAR',
  MaxYear = 'MAX_YEAR',
  IsNew = 'IS_NEW',
  CbbKey = 'CBB_KEY',
  Term = 'TERM',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type VehicleBookingHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minKm?: Maybe<HavingIntFilter>;
  maxKm?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleBookingHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minKm?: Maybe<HavingIntFilter>;
  maxKm?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `VehicleBooking` aggregates. */
export type VehicleBookingHavingInput = {
  AND?: Maybe<Array<VehicleBookingHavingInput>>;
  OR?: Maybe<Array<VehicleBookingHavingInput>>;
  sum?: Maybe<VehicleBookingHavingSumInput>;
  distinctCount?: Maybe<VehicleBookingHavingDistinctCountInput>;
  min?: Maybe<VehicleBookingHavingMinInput>;
  max?: Maybe<VehicleBookingHavingMaxInput>;
  average?: Maybe<VehicleBookingHavingAverageInput>;
  stddevSample?: Maybe<VehicleBookingHavingStddevSampleInput>;
  stddevPopulation?: Maybe<VehicleBookingHavingStddevPopulationInput>;
  varianceSample?: Maybe<VehicleBookingHavingVarianceSampleInput>;
  variancePopulation?: Maybe<VehicleBookingHavingVariancePopulationInput>;
};

export type VehicleBookingHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minKm?: Maybe<HavingIntFilter>;
  maxKm?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleBookingHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minKm?: Maybe<HavingIntFilter>;
  maxKm?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleBookingHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minKm?: Maybe<HavingIntFilter>;
  maxKm?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleBookingHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minKm?: Maybe<HavingIntFilter>;
  maxKm?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleBookingHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minKm?: Maybe<HavingIntFilter>;
  maxKm?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleBookingHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minKm?: Maybe<HavingIntFilter>;
  maxKm?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleBookingHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderProgramId?: Maybe<HavingIntFilter>;
  minKm?: Maybe<HavingIntFilter>;
  maxKm?: Maybe<HavingIntFilter>;
  minYear?: Maybe<HavingIntFilter>;
  maxYear?: Maybe<HavingIntFilter>;
  term?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `VehicleBooking` */
export type VehicleBookingInput = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  minKm?: Maybe<Scalars['Int']>;
  maxKm?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  isNew?: Maybe<Scalars['Boolean']>;
  cbbKey: Scalars['String'];
  term: Scalars['Int'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<VehicleBookingLenderProgramIdFkeyInput>;
};

/** Input for the nested mutation of `lenderProgram` in the `VehicleBookingInput` mutation. */
export type VehicleBookingLenderProgramIdFkeyInput = {
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  connectById?: Maybe<LenderProgramLenderProgramPkeyConnect>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  connectByNodeId?: Maybe<LenderProgramNodeIdConnect>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  deleteById?: Maybe<LenderProgramLenderProgramPkeyDelete>;
  /** The primary key(s) for `lenderProgram` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LenderProgramNodeIdDelete>;
  /** The primary key(s) and patch data for `lenderProgram` for the far side of the relationship. */
  updateById?: Maybe<LenderProgramOnVehicleBookingForVehicleBookingLenderProgramIdFkeyUsingLenderProgramPkeyUpdate>;
  /** The primary key(s) and patch data for `lenderProgram` for the far side of the relationship. */
  updateByNodeId?: Maybe<VehicleBookingOnVehicleBookingForVehicleBookingLenderProgramIdFkeyNodeIdUpdate>;
  /** A `LenderProgramInput` object that will be created and connected to this object. */
  create?: Maybe<VehicleBookingLenderProgramIdFkeyLenderProgramCreateInput>;
};

/** Input for the nested mutation of `vehicleBooking` in the `LenderProgramInput` mutation. */
export type VehicleBookingLenderProgramIdFkeyInverseInput = {
  /** Flag indicating whether all other `vehicleBooking` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `vehicleBooking` for the far side of the relationship. */
  connectById?: Maybe<Array<VehicleBookingVehicleBookingPkeyConnect>>;
  /** The primary key(s) for `vehicleBooking` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<VehicleBookingNodeIdConnect>>;
  /** The primary key(s) for `vehicleBooking` for the far side of the relationship. */
  deleteById?: Maybe<Array<VehicleBookingVehicleBookingPkeyDelete>>;
  /** The primary key(s) for `vehicleBooking` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<VehicleBookingNodeIdDelete>>;
  /** The primary key(s) and patch data for `vehicleBooking` for the far side of the relationship. */
  updateById?: Maybe<Array<VehicleBookingOnVehicleBookingForVehicleBookingLenderProgramIdFkeyUsingVehicleBookingPkeyUpdate>>;
  /** The primary key(s) and patch data for `vehicleBooking` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LenderProgramOnVehicleBookingForVehicleBookingLenderProgramIdFkeyNodeIdUpdate>>;
  /** A `VehicleBookingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<VehicleBookingLenderProgramIdFkeyVehicleBookingCreateInput>>;
};

/** The `lenderProgram` to be created by this mutation. */
export type VehicleBookingLenderProgramIdFkeyLenderProgramCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  applicationRequirements: Array<Maybe<Scalars['String']>>;
  vehicleRequirements: Array<Maybe<Scalars['String']>>;
  minDate?: Maybe<Scalars['Datetime']>;
  maxDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderToLenderId?: Maybe<LenderProgramLenderIdFkeyInput>;
  lenderCreditKeysUsingId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInverseInput>;
  vehicleBookingsUsingId?: Maybe<VehicleBookingLenderProgramIdFkeyInverseInput>;
  filesUsingId?: Maybe<FileLenderProgramIdFkeyInverseInput>;
};

/** The `vehicleBooking` to be created by this mutation. */
export type VehicleBookingLenderProgramIdFkeyVehicleBookingCreateInput = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  minKm?: Maybe<Scalars['Int']>;
  maxKm?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  isNew?: Maybe<Scalars['Boolean']>;
  cbbKey: Scalars['String'];
  term: Scalars['Int'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<VehicleBookingLenderProgramIdFkeyInput>;
};

export type VehicleBookingMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderProgramId?: Maybe<IntFilter>;
  minKm?: Maybe<IntFilter>;
  maxKm?: Maybe<IntFilter>;
  minYear?: Maybe<IntFilter>;
  maxYear?: Maybe<IntFilter>;
  term?: Maybe<IntFilter>;
};

export type VehicleBookingMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['Int']>;
  /** Maximum of minKm across the matching connection */
  minKm?: Maybe<Scalars['Int']>;
  /** Maximum of maxKm across the matching connection */
  maxKm?: Maybe<Scalars['Int']>;
  /** Maximum of minYear across the matching connection */
  minYear?: Maybe<Scalars['Int']>;
  /** Maximum of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['Int']>;
  /** Maximum of term across the matching connection */
  term?: Maybe<Scalars['Int']>;
};

export type VehicleBookingMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderProgramId?: Maybe<IntFilter>;
  minKm?: Maybe<IntFilter>;
  maxKm?: Maybe<IntFilter>;
  minYear?: Maybe<IntFilter>;
  maxYear?: Maybe<IntFilter>;
  term?: Maybe<IntFilter>;
};

export type VehicleBookingMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['Int']>;
  /** Minimum of minKm across the matching connection */
  minKm?: Maybe<Scalars['Int']>;
  /** Minimum of maxKm across the matching connection */
  maxKm?: Maybe<Scalars['Int']>;
  /** Minimum of minYear across the matching connection */
  minYear?: Maybe<Scalars['Int']>;
  /** Minimum of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['Int']>;
  /** Minimum of term across the matching connection */
  term?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type VehicleBookingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `vehicleBooking` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type VehicleBookingNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `vehicleBooking` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type VehicleBookingOnVehicleBookingForVehicleBookingLenderProgramIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderProgram` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderProgram` being updated. */
  patch: LenderProgramPatch;
};

/** The fields on `vehicleBooking` to look up the row to update. */
export type VehicleBookingOnVehicleBookingForVehicleBookingLenderProgramIdFkeyUsingVehicleBookingPkeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleBooking` being updated. */
  patch: UpdateVehicleBookingOnVehicleBookingForVehicleBookingLenderProgramIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `VehicleBooking`. Fields that are set will be updated. */
export type VehicleBookingPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  minKm?: Maybe<Scalars['Int']>;
  maxKm?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  isNew?: Maybe<Scalars['Boolean']>;
  cbbKey?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<VehicleBookingLenderProgramIdFkeyInput>;
};

export type VehicleBookingStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
  minKm?: Maybe<BigFloatFilter>;
  maxKm?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
};

export type VehicleBookingStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of minKm across the matching connection */
  minKm?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of maxKm across the matching connection */
  maxKm?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
};

export type VehicleBookingStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
  minKm?: Maybe<BigFloatFilter>;
  maxKm?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
};

export type VehicleBookingStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of minKm across the matching connection */
  minKm?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of maxKm across the matching connection */
  maxKm?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
};

export type VehicleBookingSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderProgramId?: Maybe<BigIntFilter>;
  minKm?: Maybe<BigIntFilter>;
  maxKm?: Maybe<BigIntFilter>;
  minYear?: Maybe<BigIntFilter>;
  maxYear?: Maybe<BigIntFilter>;
  term?: Maybe<BigIntFilter>;
};

export type VehicleBookingSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lenderProgramId across the matching connection */
  lenderProgramId: Scalars['BigInt'];
  /** Sum of minKm across the matching connection */
  minKm: Scalars['BigInt'];
  /** Sum of maxKm across the matching connection */
  maxKm: Scalars['BigInt'];
  /** Sum of minYear across the matching connection */
  minYear: Scalars['BigInt'];
  /** Sum of maxYear across the matching connection */
  maxYear: Scalars['BigInt'];
  /** Sum of term across the matching connection */
  term: Scalars['BigInt'];
};

export type VehicleBookingVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
  minKm?: Maybe<BigFloatFilter>;
  maxKm?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
};

export type VehicleBookingVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of minKm across the matching connection */
  minKm?: Maybe<Scalars['BigFloat']>;
  /** Population variance of maxKm across the matching connection */
  maxKm?: Maybe<Scalars['BigFloat']>;
  /** Population variance of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
};

export type VehicleBookingVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderProgramId?: Maybe<BigFloatFilter>;
  minKm?: Maybe<BigFloatFilter>;
  maxKm?: Maybe<BigFloatFilter>;
  minYear?: Maybe<BigFloatFilter>;
  maxYear?: Maybe<BigFloatFilter>;
  term?: Maybe<BigFloatFilter>;
};

export type VehicleBookingVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderProgramId across the matching connection */
  lenderProgramId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of minKm across the matching connection */
  minKm?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of maxKm across the matching connection */
  maxKm?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of minYear across the matching connection */
  minYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of maxYear across the matching connection */
  maxYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of term across the matching connection */
  term?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `vehicleBooking` to look up the row to connect. */
export type VehicleBookingVehicleBookingPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `vehicleBooking` to look up the row to delete. */
export type VehicleBookingVehicleBookingPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `VehicleBooking` values. */
export type VehicleBookingsConnection = {
  /** A list of `VehicleBooking` objects. */
  nodes: Array<VehicleBooking>;
  /** A list of edges which contains the `VehicleBooking` and cursor to aid in pagination. */
  edges: Array<VehicleBookingsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VehicleBooking` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<VehicleBookingAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<VehicleBookingAggregates>>;
};


/** A connection to a list of `VehicleBooking` values. */
export type VehicleBookingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<VehicleBookingGroupBy>;
  having?: Maybe<VehicleBookingHavingInput>;
};

/** A `VehicleBooking` edge in the connection. */
export type VehicleBookingsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `VehicleBooking` at the end of the edge. */
  node: VehicleBooking;
};

/** Methods to use when ordering `VehicleBooking`. */
export enum VehicleBookingsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LenderProgramIdAsc = 'LENDER_PROGRAM_ID_ASC',
  LenderProgramIdDesc = 'LENDER_PROGRAM_ID_DESC',
  MinKmAsc = 'MIN_KM_ASC',
  MinKmDesc = 'MIN_KM_DESC',
  MaxKmAsc = 'MAX_KM_ASC',
  MaxKmDesc = 'MAX_KM_DESC',
  MinYearAsc = 'MIN_YEAR_ASC',
  MinYearDesc = 'MIN_YEAR_DESC',
  MaxYearAsc = 'MAX_YEAR_ASC',
  MaxYearDesc = 'MAX_YEAR_DESC',
  IsNewAsc = 'IS_NEW_ASC',
  IsNewDesc = 'IS_NEW_DESC',
  CbbKeyAsc = 'CBB_KEY_ASC',
  CbbKeyDesc = 'CBB_KEY_DESC',
  TermAsc = 'TERM_ASC',
  TermDesc = 'TERM_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum VehicleCondition {
  Excellent = 'EXCELLENT',
  VeryGood = 'VERY_GOOD',
  Good = 'GOOD',
  Fair = 'FAIR'
}

/** A filter to be used against VehicleCondition fields. All fields are combined with a logical ‘and.’ */
export type VehicleConditionFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<VehicleCondition>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<VehicleCondition>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<VehicleCondition>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<VehicleCondition>;
  /** Included in the specified list. */
  in?: Maybe<Array<VehicleCondition>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<VehicleCondition>>;
  /** Less than the specified value. */
  lessThan?: Maybe<VehicleCondition>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<VehicleCondition>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<VehicleCondition>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<VehicleCondition>;
};

export type VehicleListing = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderApps: LenderAppsConnection;
  /** Reads and enables pagination through a set of `Appointment`. */
  appointments: AppointmentsConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByLenderAppVehicleListingIdAndLeadId: VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByAppointmentVehicleListingIdAndLeadId: VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyConnection;
};


export type VehicleListingLenderAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};


export type VehicleListingAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
  condition?: Maybe<AppointmentCondition>;
  filter?: Maybe<AppointmentFilter>;
};


export type VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

export type VehicleListingAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<VehicleListingSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<VehicleListingDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<VehicleListingMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<VehicleListingMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<VehicleListingAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<VehicleListingStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<VehicleListingStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<VehicleListingVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<VehicleListingVariancePopulationAggregates>;
};

export type VehicleListingAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Mean average of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Mean average of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `VehicleListing` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VehicleListingCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `listingMsrp` field. */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `listingPrice` field. */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `listingUrl` field. */
  listingUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `listingTitle` field. */
  listingTitle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleVin` field. */
  vehicleVin?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleYear` field. */
  vehicleYear?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `vehicleMake` field. */
  vehicleMake?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleModel` field. */
  vehicleModel?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleTrim` field. */
  vehicleTrim?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleMileage` field. */
  vehicleMileage?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `vehicleCondition` field. */
  vehicleCondition?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `listingImageUrl` field. */
  listingImageUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleEngine` field. */
  vehicleEngine?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleTransmission` field. */
  vehicleTransmission?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleExteriorColor` field. */
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleInteriorColor` field. */
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleStatus` field. */
  vehicleStatus?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleStockNumber` field. */
  vehicleStockNumber?: Maybe<Scalars['String']>;
};

export type VehicleListingDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigInt']>;
  /** Distinct count of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigInt']>;
  /** Distinct count of listingUrl across the matching connection */
  listingUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of listingTitle across the matching connection */
  listingTitle?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleVin across the matching connection */
  vehicleVin?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleMake across the matching connection */
  vehicleMake?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleModel across the matching connection */
  vehicleModel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleTrim across the matching connection */
  vehicleTrim?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleCondition across the matching connection */
  vehicleCondition?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of listingImageUrl across the matching connection */
  listingImageUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleEngine across the matching connection */
  vehicleEngine?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleTransmission across the matching connection */
  vehicleTransmission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleExteriorColor across the matching connection */
  vehicleExteriorColor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleInteriorColor across the matching connection */
  vehicleInteriorColor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleStatus across the matching connection */
  vehicleStatus?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleStockNumber across the matching connection */
  vehicleStockNumber?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `VehicleListing` object types. All fields are combined with a logical ‘and.’ */
export type VehicleListingFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `listingMsrp` field. */
  listingMsrp?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `listingPrice` field. */
  listingPrice?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `listingUrl` field. */
  listingUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `listingTitle` field. */
  listingTitle?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleVin` field. */
  vehicleVin?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleYear` field. */
  vehicleYear?: Maybe<IntFilter>;
  /** Filter by the object’s `vehicleMake` field. */
  vehicleMake?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleModel` field. */
  vehicleModel?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleTrim` field. */
  vehicleTrim?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleMileage` field. */
  vehicleMileage?: Maybe<IntFilter>;
  /** Filter by the object’s `vehicleCondition` field. */
  vehicleCondition?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `listingImageUrl` field. */
  listingImageUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleEngine` field. */
  vehicleEngine?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleTransmission` field. */
  vehicleTransmission?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleExteriorColor` field. */
  vehicleExteriorColor?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleInteriorColor` field. */
  vehicleInteriorColor?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleStatus` field. */
  vehicleStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleStockNumber` field. */
  vehicleStockNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `lenderApps` relation. */
  lenderApps?: Maybe<VehicleListingToManyLenderAppFilter>;
  /** Some related `lenderApps` exist. */
  lenderAppsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `appointments` relation. */
  appointments?: Maybe<VehicleListingToManyAppointmentFilter>;
  /** Some related `appointments` exist. */
  appointmentsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<VehicleListingFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<VehicleListingFilter>>;
  /** Negates the expression. */
  not?: Maybe<VehicleListingFilter>;
};

/** Grouping methods for `VehicleListing` for usage during aggregation. */
export enum VehicleListingGroupBy {
  ListingMsrp = 'LISTING_MSRP',
  ListingPrice = 'LISTING_PRICE',
  ListingUrl = 'LISTING_URL',
  ListingTitle = 'LISTING_TITLE',
  VehicleVin = 'VEHICLE_VIN',
  VehicleYear = 'VEHICLE_YEAR',
  VehicleMake = 'VEHICLE_MAKE',
  VehicleModel = 'VEHICLE_MODEL',
  VehicleTrim = 'VEHICLE_TRIM',
  VehicleMileage = 'VEHICLE_MILEAGE',
  VehicleCondition = 'VEHICLE_CONDITION',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  ListingImageUrl = 'LISTING_IMAGE_URL',
  VehicleEngine = 'VEHICLE_ENGINE',
  VehicleTransmission = 'VEHICLE_TRANSMISSION',
  VehicleExteriorColor = 'VEHICLE_EXTERIOR_COLOR',
  VehicleInteriorColor = 'VEHICLE_INTERIOR_COLOR',
  VehicleStatus = 'VEHICLE_STATUS',
  VehicleStockNumber = 'VEHICLE_STOCK_NUMBER'
}

export type VehicleListingHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `VehicleListing` aggregates. */
export type VehicleListingHavingInput = {
  AND?: Maybe<Array<VehicleListingHavingInput>>;
  OR?: Maybe<Array<VehicleListingHavingInput>>;
  sum?: Maybe<VehicleListingHavingSumInput>;
  distinctCount?: Maybe<VehicleListingHavingDistinctCountInput>;
  min?: Maybe<VehicleListingHavingMinInput>;
  max?: Maybe<VehicleListingHavingMaxInput>;
  average?: Maybe<VehicleListingHavingAverageInput>;
  stddevSample?: Maybe<VehicleListingHavingStddevSampleInput>;
  stddevPopulation?: Maybe<VehicleListingHavingStddevPopulationInput>;
  varianceSample?: Maybe<VehicleListingHavingVarianceSampleInput>;
  variancePopulation?: Maybe<VehicleListingHavingVariancePopulationInput>;
};

export type VehicleListingHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `VehicleListing` */
export type VehicleListingInput = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

/** A connection to a list of `Lead` values, with data from `Appointment`. */
export type VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `Appointment`, and the cursor to aid in pagination. */
  edges: Array<VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `Appointment`. */
export type VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `Appointment`. */
export type VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `Appointment`. */
  appointments: AppointmentsConnection;
};


/** A `Lead` edge in the connection, with data from `Appointment`. */
export type VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyEdgeAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
  condition?: Maybe<AppointmentCondition>;
  filter?: Maybe<AppointmentFilter>;
};

/** A connection to a list of `Lead` values, with data from `LenderApp`. */
export type VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `LenderApp`, and the cursor to aid in pagination. */
  edges: Array<VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `LenderApp`. */
export type VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `LenderApp`. */
export type VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderApps: LenderAppsConnection;
};


/** A `Lead` edge in the connection, with data from `LenderApp`. */
export type VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyEdgeLenderAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};

export type VehicleListingMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Maximum of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Maximum of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['Int']>;
  /** Maximum of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['Int']>;
};

export type VehicleListingMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Minimum of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Minimum of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['Int']>;
  /** Minimum of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type VehicleListingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `vehicleListing` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type VehicleListingNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `vehicleListing` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type VehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appointment` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appointment` being updated. */
  patch: AppointmentPatch;
};

/** The fields on `vehicleListing` to look up the row to update. */
export type VehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyUsingVehicleListingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleListing` being updated. */
  patch: UpdateVehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type VehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: LenderAppPatch;
};

/** The fields on `vehicleListing` to look up the row to update. */
export type VehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyUsingVehicleListingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleListing` being updated. */
  patch: UpdateVehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `VehicleListing`. Fields that are set will be updated. */
export type VehicleListingPatch = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

export type VehicleListingStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigFloat']>;
};

export type VehicleListingStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigFloat']>;
};

export type VehicleListingSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of listingMsrp across the matching connection */
  listingMsrp: Scalars['BigFloat'];
  /** Sum of listingPrice across the matching connection */
  listingPrice: Scalars['BigFloat'];
  /** Sum of vehicleYear across the matching connection */
  vehicleYear: Scalars['BigInt'];
  /** Sum of vehicleMileage across the matching connection */
  vehicleMileage: Scalars['BigInt'];
};

/** A filter to be used against many `Appointment` object types. All fields are combined with a logical ‘and.’ */
export type VehicleListingToManyAppointmentFilter = {
  /** Every related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AppointmentFilter>;
  /** Some related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AppointmentFilter>;
  /** No related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AppointmentFilter>;
  /** Aggregates across related `Appointment` match the filter criteria. */
  aggregates?: Maybe<AppointmentAggregatesFilter>;
};

/** A filter to be used against many `LenderApp` object types. All fields are combined with a logical ‘and.’ */
export type VehicleListingToManyLenderAppFilter = {
  /** Every related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderAppFilter>;
  /** Some related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderAppFilter>;
  /** No related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderAppFilter>;
  /** Aggregates across related `LenderApp` match the filter criteria. */
  aggregates?: Maybe<LenderAppAggregatesFilter>;
};

export type VehicleListingVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Population variance of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Population variance of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigFloat']>;
};

export type VehicleListingVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `vehicleListing` to look up the row to connect. */
export type VehicleListingVehicleListingsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `vehicleListing` to look up the row to delete. */
export type VehicleListingVehicleListingsPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `VehicleListing` values. */
export type VehicleListingsConnection = {
  /** A list of `VehicleListing` objects. */
  nodes: Array<VehicleListing>;
  /** A list of edges which contains the `VehicleListing` and cursor to aid in pagination. */
  edges: Array<VehicleListingsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VehicleListing` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<VehicleListingAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<VehicleListingAggregates>>;
};


/** A connection to a list of `VehicleListing` values. */
export type VehicleListingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<VehicleListingGroupBy>;
  having?: Maybe<VehicleListingHavingInput>;
};

/** A `VehicleListing` edge in the connection. */
export type VehicleListingsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `VehicleListing` at the end of the edge. */
  node: VehicleListing;
};

/** Methods to use when ordering `VehicleListing`. */
export enum VehicleListingsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ListingMsrpAsc = 'LISTING_MSRP_ASC',
  ListingMsrpDesc = 'LISTING_MSRP_DESC',
  ListingPriceAsc = 'LISTING_PRICE_ASC',
  ListingPriceDesc = 'LISTING_PRICE_DESC',
  ListingUrlAsc = 'LISTING_URL_ASC',
  ListingUrlDesc = 'LISTING_URL_DESC',
  ListingTitleAsc = 'LISTING_TITLE_ASC',
  ListingTitleDesc = 'LISTING_TITLE_DESC',
  VehicleVinAsc = 'VEHICLE_VIN_ASC',
  VehicleVinDesc = 'VEHICLE_VIN_DESC',
  VehicleYearAsc = 'VEHICLE_YEAR_ASC',
  VehicleYearDesc = 'VEHICLE_YEAR_DESC',
  VehicleMakeAsc = 'VEHICLE_MAKE_ASC',
  VehicleMakeDesc = 'VEHICLE_MAKE_DESC',
  VehicleModelAsc = 'VEHICLE_MODEL_ASC',
  VehicleModelDesc = 'VEHICLE_MODEL_DESC',
  VehicleTrimAsc = 'VEHICLE_TRIM_ASC',
  VehicleTrimDesc = 'VEHICLE_TRIM_DESC',
  VehicleMileageAsc = 'VEHICLE_MILEAGE_ASC',
  VehicleMileageDesc = 'VEHICLE_MILEAGE_DESC',
  VehicleConditionAsc = 'VEHICLE_CONDITION_ASC',
  VehicleConditionDesc = 'VEHICLE_CONDITION_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ListingImageUrlAsc = 'LISTING_IMAGE_URL_ASC',
  ListingImageUrlDesc = 'LISTING_IMAGE_URL_DESC',
  VehicleEngineAsc = 'VEHICLE_ENGINE_ASC',
  VehicleEngineDesc = 'VEHICLE_ENGINE_DESC',
  VehicleTransmissionAsc = 'VEHICLE_TRANSMISSION_ASC',
  VehicleTransmissionDesc = 'VEHICLE_TRANSMISSION_DESC',
  VehicleExteriorColorAsc = 'VEHICLE_EXTERIOR_COLOR_ASC',
  VehicleExteriorColorDesc = 'VEHICLE_EXTERIOR_COLOR_DESC',
  VehicleInteriorColorAsc = 'VEHICLE_INTERIOR_COLOR_ASC',
  VehicleInteriorColorDesc = 'VEHICLE_INTERIOR_COLOR_DESC',
  VehicleStatusAsc = 'VEHICLE_STATUS_ASC',
  VehicleStatusDesc = 'VEHICLE_STATUS_DESC',
  VehicleStockNumberAsc = 'VEHICLE_STOCK_NUMBER_ASC',
  VehicleStockNumberDesc = 'VEHICLE_STOCK_NUMBER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  LenderAppsCountAsc = 'LENDER_APPS_COUNT_ASC',
  LenderAppsCountDesc = 'LENDER_APPS_COUNT_DESC',
  LenderAppsSumIdAsc = 'LENDER_APPS_SUM_ID_ASC',
  LenderAppsSumIdDesc = 'LENDER_APPS_SUM_ID_DESC',
  LenderAppsSumLeadIdAsc = 'LENDER_APPS_SUM_LEAD_ID_ASC',
  LenderAppsSumLeadIdDesc = 'LENDER_APPS_SUM_LEAD_ID_DESC',
  LenderAppsSumInovatecApplicationNumberAsc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsSumInovatecApplicationNumberDesc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsSumInovatecApplicationIdAsc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsSumInovatecApplicationIdDesc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsSumYearlyIncomeAsc = 'LENDER_APPS_SUM_YEARLY_INCOME_ASC',
  LenderAppsSumYearlyIncomeDesc = 'LENDER_APPS_SUM_YEARLY_INCOME_DESC',
  LenderAppsSumEmploymentStatusAsc = 'LENDER_APPS_SUM_EMPLOYMENT_STATUS_ASC',
  LenderAppsSumEmploymentStatusDesc = 'LENDER_APPS_SUM_EMPLOYMENT_STATUS_DESC',
  LenderAppsSumMonthlyHomePaymentAsc = 'LENDER_APPS_SUM_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsSumMonthlyHomePaymentDesc = 'LENDER_APPS_SUM_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsSumPreferredOfferIdAsc = 'LENDER_APPS_SUM_PREFERRED_OFFER_ID_ASC',
  LenderAppsSumPreferredOfferIdDesc = 'LENDER_APPS_SUM_PREFERRED_OFFER_ID_DESC',
  LenderAppsSumOffersAsc = 'LENDER_APPS_SUM_OFFERS_ASC',
  LenderAppsSumOffersDesc = 'LENDER_APPS_SUM_OFFERS_DESC',
  LenderAppsSumCreatedAtAsc = 'LENDER_APPS_SUM_CREATED_AT_ASC',
  LenderAppsSumCreatedAtDesc = 'LENDER_APPS_SUM_CREATED_AT_DESC',
  LenderAppsSumUpdatedAtAsc = 'LENDER_APPS_SUM_UPDATED_AT_ASC',
  LenderAppsSumUpdatedAtDesc = 'LENDER_APPS_SUM_UPDATED_AT_DESC',
  LenderAppsSumVehicleListingIdAsc = 'LENDER_APPS_SUM_VEHICLE_LISTING_ID_ASC',
  LenderAppsSumVehicleListingIdDesc = 'LENDER_APPS_SUM_VEHICLE_LISTING_ID_DESC',
  LenderAppsSumMaritalStatusAsc = 'LENDER_APPS_SUM_MARITAL_STATUS_ASC',
  LenderAppsSumMaritalStatusDesc = 'LENDER_APPS_SUM_MARITAL_STATUS_DESC',
  LenderAppsDistinctCountIdAsc = 'LENDER_APPS_DISTINCT_COUNT_ID_ASC',
  LenderAppsDistinctCountIdDesc = 'LENDER_APPS_DISTINCT_COUNT_ID_DESC',
  LenderAppsDistinctCountLeadIdAsc = 'LENDER_APPS_DISTINCT_COUNT_LEAD_ID_ASC',
  LenderAppsDistinctCountLeadIdDesc = 'LENDER_APPS_DISTINCT_COUNT_LEAD_ID_DESC',
  LenderAppsDistinctCountInovatecApplicationNumberAsc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsDistinctCountInovatecApplicationNumberDesc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsDistinctCountInovatecApplicationIdAsc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsDistinctCountInovatecApplicationIdDesc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsDistinctCountYearlyIncomeAsc = 'LENDER_APPS_DISTINCT_COUNT_YEARLY_INCOME_ASC',
  LenderAppsDistinctCountYearlyIncomeDesc = 'LENDER_APPS_DISTINCT_COUNT_YEARLY_INCOME_DESC',
  LenderAppsDistinctCountEmploymentStatusAsc = 'LENDER_APPS_DISTINCT_COUNT_EMPLOYMENT_STATUS_ASC',
  LenderAppsDistinctCountEmploymentStatusDesc = 'LENDER_APPS_DISTINCT_COUNT_EMPLOYMENT_STATUS_DESC',
  LenderAppsDistinctCountMonthlyHomePaymentAsc = 'LENDER_APPS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsDistinctCountMonthlyHomePaymentDesc = 'LENDER_APPS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsDistinctCountPreferredOfferIdAsc = 'LENDER_APPS_DISTINCT_COUNT_PREFERRED_OFFER_ID_ASC',
  LenderAppsDistinctCountPreferredOfferIdDesc = 'LENDER_APPS_DISTINCT_COUNT_PREFERRED_OFFER_ID_DESC',
  LenderAppsDistinctCountOffersAsc = 'LENDER_APPS_DISTINCT_COUNT_OFFERS_ASC',
  LenderAppsDistinctCountOffersDesc = 'LENDER_APPS_DISTINCT_COUNT_OFFERS_DESC',
  LenderAppsDistinctCountCreatedAtAsc = 'LENDER_APPS_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderAppsDistinctCountCreatedAtDesc = 'LENDER_APPS_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderAppsDistinctCountUpdatedAtAsc = 'LENDER_APPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderAppsDistinctCountUpdatedAtDesc = 'LENDER_APPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderAppsDistinctCountVehicleListingIdAsc = 'LENDER_APPS_DISTINCT_COUNT_VEHICLE_LISTING_ID_ASC',
  LenderAppsDistinctCountVehicleListingIdDesc = 'LENDER_APPS_DISTINCT_COUNT_VEHICLE_LISTING_ID_DESC',
  LenderAppsDistinctCountMaritalStatusAsc = 'LENDER_APPS_DISTINCT_COUNT_MARITAL_STATUS_ASC',
  LenderAppsDistinctCountMaritalStatusDesc = 'LENDER_APPS_DISTINCT_COUNT_MARITAL_STATUS_DESC',
  LenderAppsMinIdAsc = 'LENDER_APPS_MIN_ID_ASC',
  LenderAppsMinIdDesc = 'LENDER_APPS_MIN_ID_DESC',
  LenderAppsMinLeadIdAsc = 'LENDER_APPS_MIN_LEAD_ID_ASC',
  LenderAppsMinLeadIdDesc = 'LENDER_APPS_MIN_LEAD_ID_DESC',
  LenderAppsMinInovatecApplicationNumberAsc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsMinInovatecApplicationNumberDesc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsMinInovatecApplicationIdAsc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsMinInovatecApplicationIdDesc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsMinYearlyIncomeAsc = 'LENDER_APPS_MIN_YEARLY_INCOME_ASC',
  LenderAppsMinYearlyIncomeDesc = 'LENDER_APPS_MIN_YEARLY_INCOME_DESC',
  LenderAppsMinEmploymentStatusAsc = 'LENDER_APPS_MIN_EMPLOYMENT_STATUS_ASC',
  LenderAppsMinEmploymentStatusDesc = 'LENDER_APPS_MIN_EMPLOYMENT_STATUS_DESC',
  LenderAppsMinMonthlyHomePaymentAsc = 'LENDER_APPS_MIN_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsMinMonthlyHomePaymentDesc = 'LENDER_APPS_MIN_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsMinPreferredOfferIdAsc = 'LENDER_APPS_MIN_PREFERRED_OFFER_ID_ASC',
  LenderAppsMinPreferredOfferIdDesc = 'LENDER_APPS_MIN_PREFERRED_OFFER_ID_DESC',
  LenderAppsMinOffersAsc = 'LENDER_APPS_MIN_OFFERS_ASC',
  LenderAppsMinOffersDesc = 'LENDER_APPS_MIN_OFFERS_DESC',
  LenderAppsMinCreatedAtAsc = 'LENDER_APPS_MIN_CREATED_AT_ASC',
  LenderAppsMinCreatedAtDesc = 'LENDER_APPS_MIN_CREATED_AT_DESC',
  LenderAppsMinUpdatedAtAsc = 'LENDER_APPS_MIN_UPDATED_AT_ASC',
  LenderAppsMinUpdatedAtDesc = 'LENDER_APPS_MIN_UPDATED_AT_DESC',
  LenderAppsMinVehicleListingIdAsc = 'LENDER_APPS_MIN_VEHICLE_LISTING_ID_ASC',
  LenderAppsMinVehicleListingIdDesc = 'LENDER_APPS_MIN_VEHICLE_LISTING_ID_DESC',
  LenderAppsMinMaritalStatusAsc = 'LENDER_APPS_MIN_MARITAL_STATUS_ASC',
  LenderAppsMinMaritalStatusDesc = 'LENDER_APPS_MIN_MARITAL_STATUS_DESC',
  LenderAppsMaxIdAsc = 'LENDER_APPS_MAX_ID_ASC',
  LenderAppsMaxIdDesc = 'LENDER_APPS_MAX_ID_DESC',
  LenderAppsMaxLeadIdAsc = 'LENDER_APPS_MAX_LEAD_ID_ASC',
  LenderAppsMaxLeadIdDesc = 'LENDER_APPS_MAX_LEAD_ID_DESC',
  LenderAppsMaxInovatecApplicationNumberAsc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsMaxInovatecApplicationNumberDesc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsMaxInovatecApplicationIdAsc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsMaxInovatecApplicationIdDesc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsMaxYearlyIncomeAsc = 'LENDER_APPS_MAX_YEARLY_INCOME_ASC',
  LenderAppsMaxYearlyIncomeDesc = 'LENDER_APPS_MAX_YEARLY_INCOME_DESC',
  LenderAppsMaxEmploymentStatusAsc = 'LENDER_APPS_MAX_EMPLOYMENT_STATUS_ASC',
  LenderAppsMaxEmploymentStatusDesc = 'LENDER_APPS_MAX_EMPLOYMENT_STATUS_DESC',
  LenderAppsMaxMonthlyHomePaymentAsc = 'LENDER_APPS_MAX_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsMaxMonthlyHomePaymentDesc = 'LENDER_APPS_MAX_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsMaxPreferredOfferIdAsc = 'LENDER_APPS_MAX_PREFERRED_OFFER_ID_ASC',
  LenderAppsMaxPreferredOfferIdDesc = 'LENDER_APPS_MAX_PREFERRED_OFFER_ID_DESC',
  LenderAppsMaxOffersAsc = 'LENDER_APPS_MAX_OFFERS_ASC',
  LenderAppsMaxOffersDesc = 'LENDER_APPS_MAX_OFFERS_DESC',
  LenderAppsMaxCreatedAtAsc = 'LENDER_APPS_MAX_CREATED_AT_ASC',
  LenderAppsMaxCreatedAtDesc = 'LENDER_APPS_MAX_CREATED_AT_DESC',
  LenderAppsMaxUpdatedAtAsc = 'LENDER_APPS_MAX_UPDATED_AT_ASC',
  LenderAppsMaxUpdatedAtDesc = 'LENDER_APPS_MAX_UPDATED_AT_DESC',
  LenderAppsMaxVehicleListingIdAsc = 'LENDER_APPS_MAX_VEHICLE_LISTING_ID_ASC',
  LenderAppsMaxVehicleListingIdDesc = 'LENDER_APPS_MAX_VEHICLE_LISTING_ID_DESC',
  LenderAppsMaxMaritalStatusAsc = 'LENDER_APPS_MAX_MARITAL_STATUS_ASC',
  LenderAppsMaxMaritalStatusDesc = 'LENDER_APPS_MAX_MARITAL_STATUS_DESC',
  LenderAppsAverageIdAsc = 'LENDER_APPS_AVERAGE_ID_ASC',
  LenderAppsAverageIdDesc = 'LENDER_APPS_AVERAGE_ID_DESC',
  LenderAppsAverageLeadIdAsc = 'LENDER_APPS_AVERAGE_LEAD_ID_ASC',
  LenderAppsAverageLeadIdDesc = 'LENDER_APPS_AVERAGE_LEAD_ID_DESC',
  LenderAppsAverageInovatecApplicationNumberAsc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsAverageInovatecApplicationNumberDesc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsAverageInovatecApplicationIdAsc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsAverageInovatecApplicationIdDesc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsAverageYearlyIncomeAsc = 'LENDER_APPS_AVERAGE_YEARLY_INCOME_ASC',
  LenderAppsAverageYearlyIncomeDesc = 'LENDER_APPS_AVERAGE_YEARLY_INCOME_DESC',
  LenderAppsAverageEmploymentStatusAsc = 'LENDER_APPS_AVERAGE_EMPLOYMENT_STATUS_ASC',
  LenderAppsAverageEmploymentStatusDesc = 'LENDER_APPS_AVERAGE_EMPLOYMENT_STATUS_DESC',
  LenderAppsAverageMonthlyHomePaymentAsc = 'LENDER_APPS_AVERAGE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsAverageMonthlyHomePaymentDesc = 'LENDER_APPS_AVERAGE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsAveragePreferredOfferIdAsc = 'LENDER_APPS_AVERAGE_PREFERRED_OFFER_ID_ASC',
  LenderAppsAveragePreferredOfferIdDesc = 'LENDER_APPS_AVERAGE_PREFERRED_OFFER_ID_DESC',
  LenderAppsAverageOffersAsc = 'LENDER_APPS_AVERAGE_OFFERS_ASC',
  LenderAppsAverageOffersDesc = 'LENDER_APPS_AVERAGE_OFFERS_DESC',
  LenderAppsAverageCreatedAtAsc = 'LENDER_APPS_AVERAGE_CREATED_AT_ASC',
  LenderAppsAverageCreatedAtDesc = 'LENDER_APPS_AVERAGE_CREATED_AT_DESC',
  LenderAppsAverageUpdatedAtAsc = 'LENDER_APPS_AVERAGE_UPDATED_AT_ASC',
  LenderAppsAverageUpdatedAtDesc = 'LENDER_APPS_AVERAGE_UPDATED_AT_DESC',
  LenderAppsAverageVehicleListingIdAsc = 'LENDER_APPS_AVERAGE_VEHICLE_LISTING_ID_ASC',
  LenderAppsAverageVehicleListingIdDesc = 'LENDER_APPS_AVERAGE_VEHICLE_LISTING_ID_DESC',
  LenderAppsAverageMaritalStatusAsc = 'LENDER_APPS_AVERAGE_MARITAL_STATUS_ASC',
  LenderAppsAverageMaritalStatusDesc = 'LENDER_APPS_AVERAGE_MARITAL_STATUS_DESC',
  LenderAppsStddevSampleIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_ID_ASC',
  LenderAppsStddevSampleIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_ID_DESC',
  LenderAppsStddevSampleLeadIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_LEAD_ID_ASC',
  LenderAppsStddevSampleLeadIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_LEAD_ID_DESC',
  LenderAppsStddevSampleInovatecApplicationNumberAsc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsStddevSampleInovatecApplicationNumberDesc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsStddevSampleInovatecApplicationIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsStddevSampleInovatecApplicationIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsStddevSampleYearlyIncomeAsc = 'LENDER_APPS_STDDEV_SAMPLE_YEARLY_INCOME_ASC',
  LenderAppsStddevSampleYearlyIncomeDesc = 'LENDER_APPS_STDDEV_SAMPLE_YEARLY_INCOME_DESC',
  LenderAppsStddevSampleEmploymentStatusAsc = 'LENDER_APPS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LenderAppsStddevSampleEmploymentStatusDesc = 'LENDER_APPS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LenderAppsStddevSampleMonthlyHomePaymentAsc = 'LENDER_APPS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsStddevSampleMonthlyHomePaymentDesc = 'LENDER_APPS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsStddevSamplePreferredOfferIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_PREFERRED_OFFER_ID_ASC',
  LenderAppsStddevSamplePreferredOfferIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_PREFERRED_OFFER_ID_DESC',
  LenderAppsStddevSampleOffersAsc = 'LENDER_APPS_STDDEV_SAMPLE_OFFERS_ASC',
  LenderAppsStddevSampleOffersDesc = 'LENDER_APPS_STDDEV_SAMPLE_OFFERS_DESC',
  LenderAppsStddevSampleCreatedAtAsc = 'LENDER_APPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderAppsStddevSampleCreatedAtDesc = 'LENDER_APPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderAppsStddevSampleUpdatedAtAsc = 'LENDER_APPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderAppsStddevSampleUpdatedAtDesc = 'LENDER_APPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderAppsStddevSampleVehicleListingIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_ASC',
  LenderAppsStddevSampleVehicleListingIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_DESC',
  LenderAppsStddevSampleMaritalStatusAsc = 'LENDER_APPS_STDDEV_SAMPLE_MARITAL_STATUS_ASC',
  LenderAppsStddevSampleMaritalStatusDesc = 'LENDER_APPS_STDDEV_SAMPLE_MARITAL_STATUS_DESC',
  LenderAppsStddevPopulationIdAsc = 'LENDER_APPS_STDDEV_POPULATION_ID_ASC',
  LenderAppsStddevPopulationIdDesc = 'LENDER_APPS_STDDEV_POPULATION_ID_DESC',
  LenderAppsStddevPopulationLeadIdAsc = 'LENDER_APPS_STDDEV_POPULATION_LEAD_ID_ASC',
  LenderAppsStddevPopulationLeadIdDesc = 'LENDER_APPS_STDDEV_POPULATION_LEAD_ID_DESC',
  LenderAppsStddevPopulationInovatecApplicationNumberAsc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsStddevPopulationInovatecApplicationNumberDesc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsStddevPopulationInovatecApplicationIdAsc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsStddevPopulationInovatecApplicationIdDesc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsStddevPopulationYearlyIncomeAsc = 'LENDER_APPS_STDDEV_POPULATION_YEARLY_INCOME_ASC',
  LenderAppsStddevPopulationYearlyIncomeDesc = 'LENDER_APPS_STDDEV_POPULATION_YEARLY_INCOME_DESC',
  LenderAppsStddevPopulationEmploymentStatusAsc = 'LENDER_APPS_STDDEV_POPULATION_EMPLOYMENT_STATUS_ASC',
  LenderAppsStddevPopulationEmploymentStatusDesc = 'LENDER_APPS_STDDEV_POPULATION_EMPLOYMENT_STATUS_DESC',
  LenderAppsStddevPopulationMonthlyHomePaymentAsc = 'LENDER_APPS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsStddevPopulationMonthlyHomePaymentDesc = 'LENDER_APPS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsStddevPopulationPreferredOfferIdAsc = 'LENDER_APPS_STDDEV_POPULATION_PREFERRED_OFFER_ID_ASC',
  LenderAppsStddevPopulationPreferredOfferIdDesc = 'LENDER_APPS_STDDEV_POPULATION_PREFERRED_OFFER_ID_DESC',
  LenderAppsStddevPopulationOffersAsc = 'LENDER_APPS_STDDEV_POPULATION_OFFERS_ASC',
  LenderAppsStddevPopulationOffersDesc = 'LENDER_APPS_STDDEV_POPULATION_OFFERS_DESC',
  LenderAppsStddevPopulationCreatedAtAsc = 'LENDER_APPS_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderAppsStddevPopulationCreatedAtDesc = 'LENDER_APPS_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderAppsStddevPopulationUpdatedAtAsc = 'LENDER_APPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderAppsStddevPopulationUpdatedAtDesc = 'LENDER_APPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderAppsStddevPopulationVehicleListingIdAsc = 'LENDER_APPS_STDDEV_POPULATION_VEHICLE_LISTING_ID_ASC',
  LenderAppsStddevPopulationVehicleListingIdDesc = 'LENDER_APPS_STDDEV_POPULATION_VEHICLE_LISTING_ID_DESC',
  LenderAppsStddevPopulationMaritalStatusAsc = 'LENDER_APPS_STDDEV_POPULATION_MARITAL_STATUS_ASC',
  LenderAppsStddevPopulationMaritalStatusDesc = 'LENDER_APPS_STDDEV_POPULATION_MARITAL_STATUS_DESC',
  LenderAppsVarianceSampleIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_ID_ASC',
  LenderAppsVarianceSampleIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_ID_DESC',
  LenderAppsVarianceSampleLeadIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LenderAppsVarianceSampleLeadIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LenderAppsVarianceSampleInovatecApplicationNumberAsc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsVarianceSampleInovatecApplicationNumberDesc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsVarianceSampleInovatecApplicationIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsVarianceSampleInovatecApplicationIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsVarianceSampleYearlyIncomeAsc = 'LENDER_APPS_VARIANCE_SAMPLE_YEARLY_INCOME_ASC',
  LenderAppsVarianceSampleYearlyIncomeDesc = 'LENDER_APPS_VARIANCE_SAMPLE_YEARLY_INCOME_DESC',
  LenderAppsVarianceSampleEmploymentStatusAsc = 'LENDER_APPS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LenderAppsVarianceSampleEmploymentStatusDesc = 'LENDER_APPS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LenderAppsVarianceSampleMonthlyHomePaymentAsc = 'LENDER_APPS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsVarianceSampleMonthlyHomePaymentDesc = 'LENDER_APPS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsVarianceSamplePreferredOfferIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_PREFERRED_OFFER_ID_ASC',
  LenderAppsVarianceSamplePreferredOfferIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_PREFERRED_OFFER_ID_DESC',
  LenderAppsVarianceSampleOffersAsc = 'LENDER_APPS_VARIANCE_SAMPLE_OFFERS_ASC',
  LenderAppsVarianceSampleOffersDesc = 'LENDER_APPS_VARIANCE_SAMPLE_OFFERS_DESC',
  LenderAppsVarianceSampleCreatedAtAsc = 'LENDER_APPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderAppsVarianceSampleCreatedAtDesc = 'LENDER_APPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderAppsVarianceSampleUpdatedAtAsc = 'LENDER_APPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderAppsVarianceSampleUpdatedAtDesc = 'LENDER_APPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderAppsVarianceSampleVehicleListingIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_ASC',
  LenderAppsVarianceSampleVehicleListingIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_DESC',
  LenderAppsVarianceSampleMaritalStatusAsc = 'LENDER_APPS_VARIANCE_SAMPLE_MARITAL_STATUS_ASC',
  LenderAppsVarianceSampleMaritalStatusDesc = 'LENDER_APPS_VARIANCE_SAMPLE_MARITAL_STATUS_DESC',
  LenderAppsVariancePopulationIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_ID_ASC',
  LenderAppsVariancePopulationIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_ID_DESC',
  LenderAppsVariancePopulationLeadIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_LEAD_ID_ASC',
  LenderAppsVariancePopulationLeadIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_LEAD_ID_DESC',
  LenderAppsVariancePopulationInovatecApplicationNumberAsc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsVariancePopulationInovatecApplicationNumberDesc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsVariancePopulationInovatecApplicationIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsVariancePopulationInovatecApplicationIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsVariancePopulationYearlyIncomeAsc = 'LENDER_APPS_VARIANCE_POPULATION_YEARLY_INCOME_ASC',
  LenderAppsVariancePopulationYearlyIncomeDesc = 'LENDER_APPS_VARIANCE_POPULATION_YEARLY_INCOME_DESC',
  LenderAppsVariancePopulationEmploymentStatusAsc = 'LENDER_APPS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_ASC',
  LenderAppsVariancePopulationEmploymentStatusDesc = 'LENDER_APPS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_DESC',
  LenderAppsVariancePopulationMonthlyHomePaymentAsc = 'LENDER_APPS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsVariancePopulationMonthlyHomePaymentDesc = 'LENDER_APPS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsVariancePopulationPreferredOfferIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_PREFERRED_OFFER_ID_ASC',
  LenderAppsVariancePopulationPreferredOfferIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_PREFERRED_OFFER_ID_DESC',
  LenderAppsVariancePopulationOffersAsc = 'LENDER_APPS_VARIANCE_POPULATION_OFFERS_ASC',
  LenderAppsVariancePopulationOffersDesc = 'LENDER_APPS_VARIANCE_POPULATION_OFFERS_DESC',
  LenderAppsVariancePopulationCreatedAtAsc = 'LENDER_APPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderAppsVariancePopulationCreatedAtDesc = 'LENDER_APPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderAppsVariancePopulationUpdatedAtAsc = 'LENDER_APPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderAppsVariancePopulationUpdatedAtDesc = 'LENDER_APPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderAppsVariancePopulationVehicleListingIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_ASC',
  LenderAppsVariancePopulationVehicleListingIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_DESC',
  LenderAppsVariancePopulationMaritalStatusAsc = 'LENDER_APPS_VARIANCE_POPULATION_MARITAL_STATUS_ASC',
  LenderAppsVariancePopulationMaritalStatusDesc = 'LENDER_APPS_VARIANCE_POPULATION_MARITAL_STATUS_DESC',
  AppointmentsCountAsc = 'APPOINTMENTS_COUNT_ASC',
  AppointmentsCountDesc = 'APPOINTMENTS_COUNT_DESC',
  AppointmentsSumIdAsc = 'APPOINTMENTS_SUM_ID_ASC',
  AppointmentsSumIdDesc = 'APPOINTMENTS_SUM_ID_DESC',
  AppointmentsSumLeadIdAsc = 'APPOINTMENTS_SUM_LEAD_ID_ASC',
  AppointmentsSumLeadIdDesc = 'APPOINTMENTS_SUM_LEAD_ID_DESC',
  AppointmentsSumVehicleListingIdAsc = 'APPOINTMENTS_SUM_VEHICLE_LISTING_ID_ASC',
  AppointmentsSumVehicleListingIdDesc = 'APPOINTMENTS_SUM_VEHICLE_LISTING_ID_DESC',
  AppointmentsSumAdditionalInformationAsc = 'APPOINTMENTS_SUM_ADDITIONAL_INFORMATION_ASC',
  AppointmentsSumAdditionalInformationDesc = 'APPOINTMENTS_SUM_ADDITIONAL_INFORMATION_DESC',
  AppointmentsSumAppointmentAtAsc = 'APPOINTMENTS_SUM_APPOINTMENT_AT_ASC',
  AppointmentsSumAppointmentAtDesc = 'APPOINTMENTS_SUM_APPOINTMENT_AT_DESC',
  AppointmentsSumConfirmedAtAsc = 'APPOINTMENTS_SUM_CONFIRMED_AT_ASC',
  AppointmentsSumConfirmedAtDesc = 'APPOINTMENTS_SUM_CONFIRMED_AT_DESC',
  AppointmentsSumCreatedAtAsc = 'APPOINTMENTS_SUM_CREATED_AT_ASC',
  AppointmentsSumCreatedAtDesc = 'APPOINTMENTS_SUM_CREATED_AT_DESC',
  AppointmentsSumUpdatedAtAsc = 'APPOINTMENTS_SUM_UPDATED_AT_ASC',
  AppointmentsSumUpdatedAtDesc = 'APPOINTMENTS_SUM_UPDATED_AT_DESC',
  AppointmentsDistinctCountIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_ID_ASC',
  AppointmentsDistinctCountIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_ID_DESC',
  AppointmentsDistinctCountLeadIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_LEAD_ID_ASC',
  AppointmentsDistinctCountLeadIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_LEAD_ID_DESC',
  AppointmentsDistinctCountVehicleListingIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_VEHICLE_LISTING_ID_ASC',
  AppointmentsDistinctCountVehicleListingIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_VEHICLE_LISTING_ID_DESC',
  AppointmentsDistinctCountAdditionalInformationAsc = 'APPOINTMENTS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_ASC',
  AppointmentsDistinctCountAdditionalInformationDesc = 'APPOINTMENTS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_DESC',
  AppointmentsDistinctCountAppointmentAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_APPOINTMENT_AT_ASC',
  AppointmentsDistinctCountAppointmentAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_APPOINTMENT_AT_DESC',
  AppointmentsDistinctCountConfirmedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_CONFIRMED_AT_ASC',
  AppointmentsDistinctCountConfirmedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_CONFIRMED_AT_DESC',
  AppointmentsDistinctCountCreatedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  AppointmentsDistinctCountCreatedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  AppointmentsDistinctCountUpdatedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  AppointmentsDistinctCountUpdatedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  AppointmentsMinIdAsc = 'APPOINTMENTS_MIN_ID_ASC',
  AppointmentsMinIdDesc = 'APPOINTMENTS_MIN_ID_DESC',
  AppointmentsMinLeadIdAsc = 'APPOINTMENTS_MIN_LEAD_ID_ASC',
  AppointmentsMinLeadIdDesc = 'APPOINTMENTS_MIN_LEAD_ID_DESC',
  AppointmentsMinVehicleListingIdAsc = 'APPOINTMENTS_MIN_VEHICLE_LISTING_ID_ASC',
  AppointmentsMinVehicleListingIdDesc = 'APPOINTMENTS_MIN_VEHICLE_LISTING_ID_DESC',
  AppointmentsMinAdditionalInformationAsc = 'APPOINTMENTS_MIN_ADDITIONAL_INFORMATION_ASC',
  AppointmentsMinAdditionalInformationDesc = 'APPOINTMENTS_MIN_ADDITIONAL_INFORMATION_DESC',
  AppointmentsMinAppointmentAtAsc = 'APPOINTMENTS_MIN_APPOINTMENT_AT_ASC',
  AppointmentsMinAppointmentAtDesc = 'APPOINTMENTS_MIN_APPOINTMENT_AT_DESC',
  AppointmentsMinConfirmedAtAsc = 'APPOINTMENTS_MIN_CONFIRMED_AT_ASC',
  AppointmentsMinConfirmedAtDesc = 'APPOINTMENTS_MIN_CONFIRMED_AT_DESC',
  AppointmentsMinCreatedAtAsc = 'APPOINTMENTS_MIN_CREATED_AT_ASC',
  AppointmentsMinCreatedAtDesc = 'APPOINTMENTS_MIN_CREATED_AT_DESC',
  AppointmentsMinUpdatedAtAsc = 'APPOINTMENTS_MIN_UPDATED_AT_ASC',
  AppointmentsMinUpdatedAtDesc = 'APPOINTMENTS_MIN_UPDATED_AT_DESC',
  AppointmentsMaxIdAsc = 'APPOINTMENTS_MAX_ID_ASC',
  AppointmentsMaxIdDesc = 'APPOINTMENTS_MAX_ID_DESC',
  AppointmentsMaxLeadIdAsc = 'APPOINTMENTS_MAX_LEAD_ID_ASC',
  AppointmentsMaxLeadIdDesc = 'APPOINTMENTS_MAX_LEAD_ID_DESC',
  AppointmentsMaxVehicleListingIdAsc = 'APPOINTMENTS_MAX_VEHICLE_LISTING_ID_ASC',
  AppointmentsMaxVehicleListingIdDesc = 'APPOINTMENTS_MAX_VEHICLE_LISTING_ID_DESC',
  AppointmentsMaxAdditionalInformationAsc = 'APPOINTMENTS_MAX_ADDITIONAL_INFORMATION_ASC',
  AppointmentsMaxAdditionalInformationDesc = 'APPOINTMENTS_MAX_ADDITIONAL_INFORMATION_DESC',
  AppointmentsMaxAppointmentAtAsc = 'APPOINTMENTS_MAX_APPOINTMENT_AT_ASC',
  AppointmentsMaxAppointmentAtDesc = 'APPOINTMENTS_MAX_APPOINTMENT_AT_DESC',
  AppointmentsMaxConfirmedAtAsc = 'APPOINTMENTS_MAX_CONFIRMED_AT_ASC',
  AppointmentsMaxConfirmedAtDesc = 'APPOINTMENTS_MAX_CONFIRMED_AT_DESC',
  AppointmentsMaxCreatedAtAsc = 'APPOINTMENTS_MAX_CREATED_AT_ASC',
  AppointmentsMaxCreatedAtDesc = 'APPOINTMENTS_MAX_CREATED_AT_DESC',
  AppointmentsMaxUpdatedAtAsc = 'APPOINTMENTS_MAX_UPDATED_AT_ASC',
  AppointmentsMaxUpdatedAtDesc = 'APPOINTMENTS_MAX_UPDATED_AT_DESC',
  AppointmentsAverageIdAsc = 'APPOINTMENTS_AVERAGE_ID_ASC',
  AppointmentsAverageIdDesc = 'APPOINTMENTS_AVERAGE_ID_DESC',
  AppointmentsAverageLeadIdAsc = 'APPOINTMENTS_AVERAGE_LEAD_ID_ASC',
  AppointmentsAverageLeadIdDesc = 'APPOINTMENTS_AVERAGE_LEAD_ID_DESC',
  AppointmentsAverageVehicleListingIdAsc = 'APPOINTMENTS_AVERAGE_VEHICLE_LISTING_ID_ASC',
  AppointmentsAverageVehicleListingIdDesc = 'APPOINTMENTS_AVERAGE_VEHICLE_LISTING_ID_DESC',
  AppointmentsAverageAdditionalInformationAsc = 'APPOINTMENTS_AVERAGE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsAverageAdditionalInformationDesc = 'APPOINTMENTS_AVERAGE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsAverageAppointmentAtAsc = 'APPOINTMENTS_AVERAGE_APPOINTMENT_AT_ASC',
  AppointmentsAverageAppointmentAtDesc = 'APPOINTMENTS_AVERAGE_APPOINTMENT_AT_DESC',
  AppointmentsAverageConfirmedAtAsc = 'APPOINTMENTS_AVERAGE_CONFIRMED_AT_ASC',
  AppointmentsAverageConfirmedAtDesc = 'APPOINTMENTS_AVERAGE_CONFIRMED_AT_DESC',
  AppointmentsAverageCreatedAtAsc = 'APPOINTMENTS_AVERAGE_CREATED_AT_ASC',
  AppointmentsAverageCreatedAtDesc = 'APPOINTMENTS_AVERAGE_CREATED_AT_DESC',
  AppointmentsAverageUpdatedAtAsc = 'APPOINTMENTS_AVERAGE_UPDATED_AT_ASC',
  AppointmentsAverageUpdatedAtDesc = 'APPOINTMENTS_AVERAGE_UPDATED_AT_DESC',
  AppointmentsStddevSampleIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_ID_ASC',
  AppointmentsStddevSampleIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_ID_DESC',
  AppointmentsStddevSampleLeadIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_LEAD_ID_ASC',
  AppointmentsStddevSampleLeadIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_LEAD_ID_DESC',
  AppointmentsStddevSampleVehicleListingIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_ASC',
  AppointmentsStddevSampleVehicleListingIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_DESC',
  AppointmentsStddevSampleAdditionalInformationAsc = 'APPOINTMENTS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsStddevSampleAdditionalInformationDesc = 'APPOINTMENTS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsStddevSampleAppointmentAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_APPOINTMENT_AT_ASC',
  AppointmentsStddevSampleAppointmentAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_APPOINTMENT_AT_DESC',
  AppointmentsStddevSampleConfirmedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_CONFIRMED_AT_ASC',
  AppointmentsStddevSampleConfirmedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_CONFIRMED_AT_DESC',
  AppointmentsStddevSampleCreatedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  AppointmentsStddevSampleCreatedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  AppointmentsStddevSampleUpdatedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  AppointmentsStddevSampleUpdatedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  AppointmentsStddevPopulationIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_ID_ASC',
  AppointmentsStddevPopulationIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_ID_DESC',
  AppointmentsStddevPopulationLeadIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_LEAD_ID_ASC',
  AppointmentsStddevPopulationLeadIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_LEAD_ID_DESC',
  AppointmentsStddevPopulationVehicleListingIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_VEHICLE_LISTING_ID_ASC',
  AppointmentsStddevPopulationVehicleListingIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_VEHICLE_LISTING_ID_DESC',
  AppointmentsStddevPopulationAdditionalInformationAsc = 'APPOINTMENTS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_ASC',
  AppointmentsStddevPopulationAdditionalInformationDesc = 'APPOINTMENTS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_DESC',
  AppointmentsStddevPopulationAppointmentAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_APPOINTMENT_AT_ASC',
  AppointmentsStddevPopulationAppointmentAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_APPOINTMENT_AT_DESC',
  AppointmentsStddevPopulationConfirmedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_CONFIRMED_AT_ASC',
  AppointmentsStddevPopulationConfirmedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_CONFIRMED_AT_DESC',
  AppointmentsStddevPopulationCreatedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  AppointmentsStddevPopulationCreatedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  AppointmentsStddevPopulationUpdatedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  AppointmentsStddevPopulationUpdatedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  AppointmentsVarianceSampleIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_ID_ASC',
  AppointmentsVarianceSampleIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_ID_DESC',
  AppointmentsVarianceSampleLeadIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  AppointmentsVarianceSampleLeadIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  AppointmentsVarianceSampleVehicleListingIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_ASC',
  AppointmentsVarianceSampleVehicleListingIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_DESC',
  AppointmentsVarianceSampleAdditionalInformationAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsVarianceSampleAdditionalInformationDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsVarianceSampleAppointmentAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_APPOINTMENT_AT_ASC',
  AppointmentsVarianceSampleAppointmentAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_APPOINTMENT_AT_DESC',
  AppointmentsVarianceSampleConfirmedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_CONFIRMED_AT_ASC',
  AppointmentsVarianceSampleConfirmedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_CONFIRMED_AT_DESC',
  AppointmentsVarianceSampleCreatedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  AppointmentsVarianceSampleCreatedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  AppointmentsVarianceSampleUpdatedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  AppointmentsVarianceSampleUpdatedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  AppointmentsVariancePopulationIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_ID_ASC',
  AppointmentsVariancePopulationIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_ID_DESC',
  AppointmentsVariancePopulationLeadIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_LEAD_ID_ASC',
  AppointmentsVariancePopulationLeadIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_LEAD_ID_DESC',
  AppointmentsVariancePopulationVehicleListingIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_ASC',
  AppointmentsVariancePopulationVehicleListingIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_DESC',
  AppointmentsVariancePopulationAdditionalInformationAsc = 'APPOINTMENTS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_ASC',
  AppointmentsVariancePopulationAdditionalInformationDesc = 'APPOINTMENTS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_DESC',
  AppointmentsVariancePopulationAppointmentAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_APPOINTMENT_AT_ASC',
  AppointmentsVariancePopulationAppointmentAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_APPOINTMENT_AT_DESC',
  AppointmentsVariancePopulationConfirmedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_CONFIRMED_AT_ASC',
  AppointmentsVariancePopulationConfirmedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_CONFIRMED_AT_DESC',
  AppointmentsVariancePopulationCreatedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  AppointmentsVariancePopulationCreatedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  AppointmentsVariancePopulationUpdatedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  AppointmentsVariancePopulationUpdatedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type VehicleLookupEntry = {
  make?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  matchCode?: Maybe<Scalars['String']>;
  matchDescription?: Maybe<Scalars['String']>;
};

export type VehicleLookupResponse = {
  results?: Maybe<Array<Maybe<VehicleLookupEntry>>>;
};

export type VehicleStock = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  providerId: Scalars['String'];
  vin: Scalars['String'];
  stockNumber: Scalars['String'];
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim: Scalars['String'];
  bodyStyle: Scalars['String'];
  exteriorColour: Scalars['String'];
  interiorColour: Scalars['String'];
  mileage: Scalars['Int'];
  doors: Scalars['Int'];
  seats: Scalars['Int'];
  engineCapacity: Scalars['String'];
  transmission: Scalars['String'];
  cylinders: Scalars['String'];
  description: Scalars['String'];
  warrantyText: Scalars['String'];
  carProof: Scalars['String'];
  isNew: Scalars['Boolean'];
  certified?: Maybe<Scalars['String']>;
  special?: Maybe<Scalars['String']>;
  driveTrain?: Maybe<Scalars['String']>;
  fuelType?: Maybe<Scalars['String']>;
  mainImage: Scalars['String'];
  images: Array<Maybe<Scalars['String']>>;
  videos: Array<Maybe<Scalars['String']>>;
  features: Scalars['JSON'];
  stockStatus: Scalars['String'];
  price: Scalars['Int'];
  cost: Scalars['Int'];
  entryDate: Scalars['Datetime'];
  lastChangeDate: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  searchText?: Maybe<Scalars['FullText']>;
  /** Reads a single `Company` that is related to this `VehicleStock`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyIntegration` that is related to this `VehicleStock`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Full-text search ranking when filtered by `searchText`. */
  searchTextRank?: Maybe<Scalars['Float']>;
};

export type VehicleStockAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<VehicleStockSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<VehicleStockDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<VehicleStockMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<VehicleStockMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<VehicleStockAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<VehicleStockStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<VehicleStockStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<VehicleStockVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<VehicleStockVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `VehicleStock` object types. */
export type VehicleStockAggregatesFilter = {
  /** A filter that must pass for the relevant `VehicleStock` object to be included within the aggregate. */
  filter?: Maybe<VehicleStockFilter>;
  /** Sum aggregate over matching `VehicleStock` objects. */
  sum?: Maybe<VehicleStockSumAggregateFilter>;
  /** Distinct count aggregate over matching `VehicleStock` objects. */
  distinctCount?: Maybe<VehicleStockDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `VehicleStock` objects. */
  min?: Maybe<VehicleStockMinAggregateFilter>;
  /** Maximum aggregate over matching `VehicleStock` objects. */
  max?: Maybe<VehicleStockMaxAggregateFilter>;
  /** Mean average aggregate over matching `VehicleStock` objects. */
  average?: Maybe<VehicleStockAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `VehicleStock` objects. */
  stddevSample?: Maybe<VehicleStockStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `VehicleStock` objects. */
  stddevPopulation?: Maybe<VehicleStockStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `VehicleStock` objects. */
  varianceSample?: Maybe<VehicleStockVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `VehicleStock` objects. */
  variancePopulation?: Maybe<VehicleStockVariancePopulationAggregateFilter>;
};

export type VehicleStockAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  doors?: Maybe<BigFloatFilter>;
  seats?: Maybe<BigFloatFilter>;
  price?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
};

export type VehicleStockAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Mean average of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Mean average of doors across the matching connection */
  doors?: Maybe<Scalars['BigFloat']>;
  /** Mean average of seats across the matching connection */
  seats?: Maybe<Scalars['BigFloat']>;
  /** Mean average of price across the matching connection */
  price?: Maybe<Scalars['BigFloat']>;
  /** Mean average of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
};

/** The `company` to be created by this mutation. */
export type VehicleStockCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `VehicleStockInput` mutation. */
export type VehicleStockCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByLagoId?: Maybe<CompanyCompanyLagoIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnVehicleStockForVehicleStockCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnVehicleStockForVehicleStockCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnVehicleStockForVehicleStockCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnVehicleStockForVehicleStockCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByLagoId?: Maybe<CompanyOnVehicleStockForVehicleStockCompanyIdFkeyUsingCompanyLagoIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<VehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<VehicleStockCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `vehicleStock` in the `CompanyInput` mutation. */
export type VehicleStockCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `vehicleStock` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  connectById?: Maybe<Array<VehicleStockVehicleStockPkeyConnect>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  connectByProviderId?: Maybe<Array<VehicleStockVehicleStockProviderIdKeyConnect>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  connectByVin?: Maybe<Array<VehicleStockVehicleStockVinKeyConnect>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  connectByStockNumber?: Maybe<Array<VehicleStockVehicleStockStockNumberKeyConnect>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<VehicleStockNodeIdConnect>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  deleteById?: Maybe<Array<VehicleStockVehicleStockPkeyDelete>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  deleteByProviderId?: Maybe<Array<VehicleStockVehicleStockProviderIdKeyDelete>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  deleteByVin?: Maybe<Array<VehicleStockVehicleStockVinKeyDelete>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  deleteByStockNumber?: Maybe<Array<VehicleStockVehicleStockStockNumberKeyDelete>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<VehicleStockNodeIdDelete>>;
  /** The primary key(s) and patch data for `vehicleStock` for the far side of the relationship. */
  updateById?: Maybe<Array<VehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyUsingVehicleStockPkeyUpdate>>;
  /** The primary key(s) and patch data for `vehicleStock` for the far side of the relationship. */
  updateByProviderId?: Maybe<Array<VehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyUsingVehicleStockProviderIdKeyUpdate>>;
  /** The primary key(s) and patch data for `vehicleStock` for the far side of the relationship. */
  updateByVin?: Maybe<Array<VehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyUsingVehicleStockVinKeyUpdate>>;
  /** The primary key(s) and patch data for `vehicleStock` for the far side of the relationship. */
  updateByStockNumber?: Maybe<Array<VehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyUsingVehicleStockStockNumberKeyUpdate>>;
  /** The primary key(s) and patch data for `vehicleStock` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnVehicleStockForVehicleStockCompanyIdFkeyNodeIdUpdate>>;
  /** A `VehicleStockInput` object that will be created and connected to this object. */
  create?: Maybe<Array<VehicleStockCompanyIdFkeyVehicleStockCreateInput>>;
};

/** The `vehicleStock` to be created by this mutation. */
export type VehicleStockCompanyIdFkeyVehicleStockCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  providerId: Scalars['String'];
  vin: Scalars['String'];
  stockNumber: Scalars['String'];
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim: Scalars['String'];
  bodyStyle: Scalars['String'];
  exteriorColour: Scalars['String'];
  interiorColour: Scalars['String'];
  mileage: Scalars['Int'];
  doors: Scalars['Int'];
  seats: Scalars['Int'];
  engineCapacity: Scalars['String'];
  transmission: Scalars['String'];
  cylinders: Scalars['String'];
  description: Scalars['String'];
  warrantyText: Scalars['String'];
  carProof: Scalars['String'];
  isNew: Scalars['Boolean'];
  certified?: Maybe<Scalars['String']>;
  special?: Maybe<Scalars['String']>;
  driveTrain?: Maybe<Scalars['String']>;
  fuelType?: Maybe<Scalars['String']>;
  mainImage: Scalars['String'];
  images: Array<Maybe<Scalars['String']>>;
  videos: Array<Maybe<Scalars['String']>>;
  features: Scalars['JSON'];
  stockStatus: Scalars['String'];
  price: Scalars['Int'];
  cost: Scalars['Int'];
  entryDate?: Maybe<Scalars['Datetime']>;
  lastChangeDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  companyToCompanyId?: Maybe<VehicleStockCompanyIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInput>;
};

/** The `companyIntegration` to be created by this mutation. */
export type VehicleStockCompanyIntegrationIdFkeyCompanyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `companyIntegration` in the `VehicleStockInput` mutation. */
export type VehicleStockCompanyIntegrationIdFkeyInput = {
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyIntegrationNodeIdConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyDelete>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyIntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateById?: Maybe<CompanyIntegrationOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<VehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyNodeIdUpdate>;
  /** A `CompanyIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<VehicleStockCompanyIntegrationIdFkeyCompanyIntegrationCreateInput>;
};

/** Input for the nested mutation of `vehicleStock` in the `CompanyIntegrationInput` mutation. */
export type VehicleStockCompanyIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `vehicleStock` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  connectById?: Maybe<Array<VehicleStockVehicleStockPkeyConnect>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  connectByProviderId?: Maybe<Array<VehicleStockVehicleStockProviderIdKeyConnect>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  connectByVin?: Maybe<Array<VehicleStockVehicleStockVinKeyConnect>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  connectByStockNumber?: Maybe<Array<VehicleStockVehicleStockStockNumberKeyConnect>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<VehicleStockNodeIdConnect>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  deleteById?: Maybe<Array<VehicleStockVehicleStockPkeyDelete>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  deleteByProviderId?: Maybe<Array<VehicleStockVehicleStockProviderIdKeyDelete>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  deleteByVin?: Maybe<Array<VehicleStockVehicleStockVinKeyDelete>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  deleteByStockNumber?: Maybe<Array<VehicleStockVehicleStockStockNumberKeyDelete>>;
  /** The primary key(s) for `vehicleStock` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<VehicleStockNodeIdDelete>>;
  /** The primary key(s) and patch data for `vehicleStock` for the far side of the relationship. */
  updateById?: Maybe<Array<VehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyUsingVehicleStockPkeyUpdate>>;
  /** The primary key(s) and patch data for `vehicleStock` for the far side of the relationship. */
  updateByProviderId?: Maybe<Array<VehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyUsingVehicleStockProviderIdKeyUpdate>>;
  /** The primary key(s) and patch data for `vehicleStock` for the far side of the relationship. */
  updateByVin?: Maybe<Array<VehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyUsingVehicleStockVinKeyUpdate>>;
  /** The primary key(s) and patch data for `vehicleStock` for the far side of the relationship. */
  updateByStockNumber?: Maybe<Array<VehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyUsingVehicleStockStockNumberKeyUpdate>>;
  /** The primary key(s) and patch data for `vehicleStock` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyIntegrationOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyNodeIdUpdate>>;
  /** A `VehicleStockInput` object that will be created and connected to this object. */
  create?: Maybe<Array<VehicleStockCompanyIntegrationIdFkeyVehicleStockCreateInput>>;
};

/** The `vehicleStock` to be created by this mutation. */
export type VehicleStockCompanyIntegrationIdFkeyVehicleStockCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  providerId: Scalars['String'];
  vin: Scalars['String'];
  stockNumber: Scalars['String'];
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim: Scalars['String'];
  bodyStyle: Scalars['String'];
  exteriorColour: Scalars['String'];
  interiorColour: Scalars['String'];
  mileage: Scalars['Int'];
  doors: Scalars['Int'];
  seats: Scalars['Int'];
  engineCapacity: Scalars['String'];
  transmission: Scalars['String'];
  cylinders: Scalars['String'];
  description: Scalars['String'];
  warrantyText: Scalars['String'];
  carProof: Scalars['String'];
  isNew: Scalars['Boolean'];
  certified?: Maybe<Scalars['String']>;
  special?: Maybe<Scalars['String']>;
  driveTrain?: Maybe<Scalars['String']>;
  fuelType?: Maybe<Scalars['String']>;
  mainImage: Scalars['String'];
  images: Array<Maybe<Scalars['String']>>;
  videos: Array<Maybe<Scalars['String']>>;
  features: Scalars['JSON'];
  stockStatus: Scalars['String'];
  price: Scalars['Int'];
  cost: Scalars['Int'];
  entryDate?: Maybe<Scalars['Datetime']>;
  lastChangeDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  companyToCompanyId?: Maybe<VehicleStockCompanyIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInput>;
};

/**
 * A condition to be used against `VehicleStock` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VehicleStockCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `providerId` field. */
  providerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vin` field. */
  vin?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `stockNumber` field. */
  stockNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `make` field. */
  make?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `model` field. */
  model?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `trim` field. */
  trim?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bodyStyle` field. */
  bodyStyle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `exteriorColour` field. */
  exteriorColour?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `interiorColour` field. */
  interiorColour?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `mileage` field. */
  mileage?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `doors` field. */
  doors?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `seats` field. */
  seats?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `engineCapacity` field. */
  engineCapacity?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `transmission` field. */
  transmission?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cylinders` field. */
  cylinders?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `warrantyText` field. */
  warrantyText?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `carProof` field. */
  carProof?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isNew` field. */
  isNew?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `certified` field. */
  certified?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `special` field. */
  special?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `driveTrain` field. */
  driveTrain?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `fuelType` field. */
  fuelType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `mainImage` field. */
  mainImage?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `images` field. */
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `videos` field. */
  videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `features` field. */
  features?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `stockStatus` field. */
  stockStatus?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `price` field. */
  price?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `cost` field. */
  cost?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `entryDate` field. */
  entryDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `lastChangeDate` field. */
  lastChangeDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `searchText` field. */
  searchText?: Maybe<Scalars['FullText']>;
};

export type VehicleStockDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  providerId?: Maybe<BigIntFilter>;
  vin?: Maybe<BigIntFilter>;
  stockNumber?: Maybe<BigIntFilter>;
  year?: Maybe<BigIntFilter>;
  make?: Maybe<BigIntFilter>;
  model?: Maybe<BigIntFilter>;
  trim?: Maybe<BigIntFilter>;
  bodyStyle?: Maybe<BigIntFilter>;
  exteriorColour?: Maybe<BigIntFilter>;
  interiorColour?: Maybe<BigIntFilter>;
  mileage?: Maybe<BigIntFilter>;
  doors?: Maybe<BigIntFilter>;
  seats?: Maybe<BigIntFilter>;
  engineCapacity?: Maybe<BigIntFilter>;
  transmission?: Maybe<BigIntFilter>;
  cylinders?: Maybe<BigIntFilter>;
  description?: Maybe<BigIntFilter>;
  warrantyText?: Maybe<BigIntFilter>;
  carProof?: Maybe<BigIntFilter>;
  isNew?: Maybe<BigIntFilter>;
  certified?: Maybe<BigIntFilter>;
  special?: Maybe<BigIntFilter>;
  driveTrain?: Maybe<BigIntFilter>;
  fuelType?: Maybe<BigIntFilter>;
  mainImage?: Maybe<BigIntFilter>;
  images?: Maybe<BigIntFilter>;
  videos?: Maybe<BigIntFilter>;
  features?: Maybe<BigIntFilter>;
  stockStatus?: Maybe<BigIntFilter>;
  price?: Maybe<BigIntFilter>;
  cost?: Maybe<BigIntFilter>;
  entryDate?: Maybe<BigIntFilter>;
  lastChangeDate?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  searchText?: Maybe<BigIntFilter>;
};

export type VehicleStockDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of providerId across the matching connection */
  providerId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vin across the matching connection */
  vin?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stockNumber across the matching connection */
  stockNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of year across the matching connection */
  year?: Maybe<Scalars['BigInt']>;
  /** Distinct count of make across the matching connection */
  make?: Maybe<Scalars['BigInt']>;
  /** Distinct count of model across the matching connection */
  model?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trim across the matching connection */
  trim?: Maybe<Scalars['BigInt']>;
  /** Distinct count of bodyStyle across the matching connection */
  bodyStyle?: Maybe<Scalars['BigInt']>;
  /** Distinct count of exteriorColour across the matching connection */
  exteriorColour?: Maybe<Scalars['BigInt']>;
  /** Distinct count of interiorColour across the matching connection */
  interiorColour?: Maybe<Scalars['BigInt']>;
  /** Distinct count of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of doors across the matching connection */
  doors?: Maybe<Scalars['BigInt']>;
  /** Distinct count of seats across the matching connection */
  seats?: Maybe<Scalars['BigInt']>;
  /** Distinct count of engineCapacity across the matching connection */
  engineCapacity?: Maybe<Scalars['BigInt']>;
  /** Distinct count of transmission across the matching connection */
  transmission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cylinders across the matching connection */
  cylinders?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of warrantyText across the matching connection */
  warrantyText?: Maybe<Scalars['BigInt']>;
  /** Distinct count of carProof across the matching connection */
  carProof?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isNew across the matching connection */
  isNew?: Maybe<Scalars['BigInt']>;
  /** Distinct count of certified across the matching connection */
  certified?: Maybe<Scalars['BigInt']>;
  /** Distinct count of special across the matching connection */
  special?: Maybe<Scalars['BigInt']>;
  /** Distinct count of driveTrain across the matching connection */
  driveTrain?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fuelType across the matching connection */
  fuelType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of mainImage across the matching connection */
  mainImage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of images across the matching connection */
  images?: Maybe<Scalars['BigInt']>;
  /** Distinct count of videos across the matching connection */
  videos?: Maybe<Scalars['BigInt']>;
  /** Distinct count of features across the matching connection */
  features?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stockStatus across the matching connection */
  stockStatus?: Maybe<Scalars['BigInt']>;
  /** Distinct count of price across the matching connection */
  price?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cost across the matching connection */
  cost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of entryDate across the matching connection */
  entryDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastChangeDate across the matching connection */
  lastChangeDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of searchText across the matching connection */
  searchText?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `VehicleStock` object types. All fields are combined with a logical ‘and.’ */
export type VehicleStockFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `providerId` field. */
  providerId?: Maybe<StringFilter>;
  /** Filter by the object’s `vin` field. */
  vin?: Maybe<StringFilter>;
  /** Filter by the object’s `stockNumber` field. */
  stockNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `year` field. */
  year?: Maybe<IntFilter>;
  /** Filter by the object’s `make` field. */
  make?: Maybe<StringFilter>;
  /** Filter by the object’s `model` field. */
  model?: Maybe<StringFilter>;
  /** Filter by the object’s `trim` field. */
  trim?: Maybe<StringFilter>;
  /** Filter by the object’s `bodyStyle` field. */
  bodyStyle?: Maybe<StringFilter>;
  /** Filter by the object’s `exteriorColour` field. */
  exteriorColour?: Maybe<StringFilter>;
  /** Filter by the object’s `interiorColour` field. */
  interiorColour?: Maybe<StringFilter>;
  /** Filter by the object’s `mileage` field. */
  mileage?: Maybe<IntFilter>;
  /** Filter by the object’s `doors` field. */
  doors?: Maybe<IntFilter>;
  /** Filter by the object’s `seats` field. */
  seats?: Maybe<IntFilter>;
  /** Filter by the object’s `engineCapacity` field. */
  engineCapacity?: Maybe<StringFilter>;
  /** Filter by the object’s `transmission` field. */
  transmission?: Maybe<StringFilter>;
  /** Filter by the object’s `cylinders` field. */
  cylinders?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `warrantyText` field. */
  warrantyText?: Maybe<StringFilter>;
  /** Filter by the object’s `carProof` field. */
  carProof?: Maybe<StringFilter>;
  /** Filter by the object’s `isNew` field. */
  isNew?: Maybe<BooleanFilter>;
  /** Filter by the object’s `certified` field. */
  certified?: Maybe<StringFilter>;
  /** Filter by the object’s `special` field. */
  special?: Maybe<StringFilter>;
  /** Filter by the object’s `driveTrain` field. */
  driveTrain?: Maybe<StringFilter>;
  /** Filter by the object’s `fuelType` field. */
  fuelType?: Maybe<StringFilter>;
  /** Filter by the object’s `mainImage` field. */
  mainImage?: Maybe<StringFilter>;
  /** Filter by the object’s `images` field. */
  images?: Maybe<StringListFilter>;
  /** Filter by the object’s `videos` field. */
  videos?: Maybe<StringListFilter>;
  /** Filter by the object’s `stockStatus` field. */
  stockStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `price` field. */
  price?: Maybe<IntFilter>;
  /** Filter by the object’s `cost` field. */
  cost?: Maybe<IntFilter>;
  /** Filter by the object’s `entryDate` field. */
  entryDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lastChangeDate` field. */
  lastChangeDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `searchText` field. */
  searchText?: Maybe<FullTextFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyIntegration` relation. */
  companyIntegration?: Maybe<CompanyIntegrationFilter>;
  /** A related `companyIntegration` exists. */
  companyIntegrationExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<VehicleStockFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<VehicleStockFilter>>;
  /** Negates the expression. */
  not?: Maybe<VehicleStockFilter>;
};

/** Grouping methods for `VehicleStock` for usage during aggregation. */
export enum VehicleStockGroupBy {
  CompanyId = 'COMPANY_ID',
  CompanyIntegrationId = 'COMPANY_INTEGRATION_ID',
  Year = 'YEAR',
  Make = 'MAKE',
  Model = 'MODEL',
  Trim = 'TRIM',
  BodyStyle = 'BODY_STYLE',
  ExteriorColour = 'EXTERIOR_COLOUR',
  InteriorColour = 'INTERIOR_COLOUR',
  Mileage = 'MILEAGE',
  Doors = 'DOORS',
  Seats = 'SEATS',
  EngineCapacity = 'ENGINE_CAPACITY',
  Transmission = 'TRANSMISSION',
  Cylinders = 'CYLINDERS',
  Description = 'DESCRIPTION',
  WarrantyText = 'WARRANTY_TEXT',
  CarProof = 'CAR_PROOF',
  IsNew = 'IS_NEW',
  Certified = 'CERTIFIED',
  Special = 'SPECIAL',
  DriveTrain = 'DRIVE_TRAIN',
  FuelType = 'FUEL_TYPE',
  MainImage = 'MAIN_IMAGE',
  Images = 'IMAGES',
  Videos = 'VIDEOS',
  Features = 'FEATURES',
  StockStatus = 'STOCK_STATUS',
  Price = 'PRICE',
  Cost = 'COST',
  EntryDate = 'ENTRY_DATE',
  EntryDateTruncatedToHour = 'ENTRY_DATE_TRUNCATED_TO_HOUR',
  EntryDateTruncatedToDay = 'ENTRY_DATE_TRUNCATED_TO_DAY',
  LastChangeDate = 'LAST_CHANGE_DATE',
  LastChangeDateTruncatedToHour = 'LAST_CHANGE_DATE_TRUNCATED_TO_HOUR',
  LastChangeDateTruncatedToDay = 'LAST_CHANGE_DATE_TRUNCATED_TO_DAY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  SearchText = 'SEARCH_TEXT'
}

export type VehicleStockHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  doors?: Maybe<HavingIntFilter>;
  seats?: Maybe<HavingIntFilter>;
  price?: Maybe<HavingIntFilter>;
  cost?: Maybe<HavingIntFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  lastChangeDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleStockHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  doors?: Maybe<HavingIntFilter>;
  seats?: Maybe<HavingIntFilter>;
  price?: Maybe<HavingIntFilter>;
  cost?: Maybe<HavingIntFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  lastChangeDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `VehicleStock` aggregates. */
export type VehicleStockHavingInput = {
  AND?: Maybe<Array<VehicleStockHavingInput>>;
  OR?: Maybe<Array<VehicleStockHavingInput>>;
  sum?: Maybe<VehicleStockHavingSumInput>;
  distinctCount?: Maybe<VehicleStockHavingDistinctCountInput>;
  min?: Maybe<VehicleStockHavingMinInput>;
  max?: Maybe<VehicleStockHavingMaxInput>;
  average?: Maybe<VehicleStockHavingAverageInput>;
  stddevSample?: Maybe<VehicleStockHavingStddevSampleInput>;
  stddevPopulation?: Maybe<VehicleStockHavingStddevPopulationInput>;
  varianceSample?: Maybe<VehicleStockHavingVarianceSampleInput>;
  variancePopulation?: Maybe<VehicleStockHavingVariancePopulationInput>;
};

export type VehicleStockHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  doors?: Maybe<HavingIntFilter>;
  seats?: Maybe<HavingIntFilter>;
  price?: Maybe<HavingIntFilter>;
  cost?: Maybe<HavingIntFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  lastChangeDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleStockHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  doors?: Maybe<HavingIntFilter>;
  seats?: Maybe<HavingIntFilter>;
  price?: Maybe<HavingIntFilter>;
  cost?: Maybe<HavingIntFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  lastChangeDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleStockHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  doors?: Maybe<HavingIntFilter>;
  seats?: Maybe<HavingIntFilter>;
  price?: Maybe<HavingIntFilter>;
  cost?: Maybe<HavingIntFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  lastChangeDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleStockHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  doors?: Maybe<HavingIntFilter>;
  seats?: Maybe<HavingIntFilter>;
  price?: Maybe<HavingIntFilter>;
  cost?: Maybe<HavingIntFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  lastChangeDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleStockHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  doors?: Maybe<HavingIntFilter>;
  seats?: Maybe<HavingIntFilter>;
  price?: Maybe<HavingIntFilter>;
  cost?: Maybe<HavingIntFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  lastChangeDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleStockHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  doors?: Maybe<HavingIntFilter>;
  seats?: Maybe<HavingIntFilter>;
  price?: Maybe<HavingIntFilter>;
  cost?: Maybe<HavingIntFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  lastChangeDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleStockHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  doors?: Maybe<HavingIntFilter>;
  seats?: Maybe<HavingIntFilter>;
  price?: Maybe<HavingIntFilter>;
  cost?: Maybe<HavingIntFilter>;
  entryDate?: Maybe<HavingDatetimeFilter>;
  lastChangeDate?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `VehicleStock` */
export type VehicleStockInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  providerId: Scalars['String'];
  vin: Scalars['String'];
  stockNumber: Scalars['String'];
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim: Scalars['String'];
  bodyStyle: Scalars['String'];
  exteriorColour: Scalars['String'];
  interiorColour: Scalars['String'];
  mileage: Scalars['Int'];
  doors: Scalars['Int'];
  seats: Scalars['Int'];
  engineCapacity: Scalars['String'];
  transmission: Scalars['String'];
  cylinders: Scalars['String'];
  description: Scalars['String'];
  warrantyText: Scalars['String'];
  carProof: Scalars['String'];
  isNew: Scalars['Boolean'];
  certified?: Maybe<Scalars['String']>;
  special?: Maybe<Scalars['String']>;
  driveTrain?: Maybe<Scalars['String']>;
  fuelType?: Maybe<Scalars['String']>;
  mainImage: Scalars['String'];
  images: Array<Maybe<Scalars['String']>>;
  videos: Array<Maybe<Scalars['String']>>;
  features: Scalars['JSON'];
  stockStatus: Scalars['String'];
  price: Scalars['Int'];
  cost: Scalars['Int'];
  entryDate?: Maybe<Scalars['Datetime']>;
  lastChangeDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  companyToCompanyId?: Maybe<VehicleStockCompanyIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInput>;
};

export type VehicleStockMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  year?: Maybe<IntFilter>;
  mileage?: Maybe<IntFilter>;
  doors?: Maybe<IntFilter>;
  seats?: Maybe<IntFilter>;
  price?: Maybe<IntFilter>;
  cost?: Maybe<IntFilter>;
};

export type VehicleStockMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Maximum of year across the matching connection */
  year?: Maybe<Scalars['Int']>;
  /** Maximum of mileage across the matching connection */
  mileage?: Maybe<Scalars['Int']>;
  /** Maximum of doors across the matching connection */
  doors?: Maybe<Scalars['Int']>;
  /** Maximum of seats across the matching connection */
  seats?: Maybe<Scalars['Int']>;
  /** Maximum of price across the matching connection */
  price?: Maybe<Scalars['Int']>;
  /** Maximum of cost across the matching connection */
  cost?: Maybe<Scalars['Int']>;
};

export type VehicleStockMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  year?: Maybe<IntFilter>;
  mileage?: Maybe<IntFilter>;
  doors?: Maybe<IntFilter>;
  seats?: Maybe<IntFilter>;
  price?: Maybe<IntFilter>;
  cost?: Maybe<IntFilter>;
};

export type VehicleStockMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Minimum of year across the matching connection */
  year?: Maybe<Scalars['Int']>;
  /** Minimum of mileage across the matching connection */
  mileage?: Maybe<Scalars['Int']>;
  /** Minimum of doors across the matching connection */
  doors?: Maybe<Scalars['Int']>;
  /** Minimum of seats across the matching connection */
  seats?: Maybe<Scalars['Int']>;
  /** Minimum of price across the matching connection */
  price?: Maybe<Scalars['Int']>;
  /** Minimum of cost across the matching connection */
  cost?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type VehicleStockNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `vehicleStock` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type VehicleStockNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `vehicleStock` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type VehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `vehicleStock` to look up the row to update. */
export type VehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyUsingVehicleStockPkeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleStock` being updated. */
  patch: UpdateVehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `vehicleStock` to look up the row to update. */
export type VehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyUsingVehicleStockProviderIdKeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleStock` being updated. */
  patch: UpdateVehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyPatch;
  providerId: Scalars['String'];
};

/** The fields on `vehicleStock` to look up the row to update. */
export type VehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyUsingVehicleStockStockNumberKeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleStock` being updated. */
  patch: UpdateVehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyPatch;
  stockNumber: Scalars['String'];
};

/** The fields on `vehicleStock` to look up the row to update. */
export type VehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyUsingVehicleStockVinKeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleStock` being updated. */
  patch: UpdateVehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyPatch;
  vin: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type VehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** The fields on `vehicleStock` to look up the row to update. */
export type VehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyUsingVehicleStockPkeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleStock` being updated. */
  patch: UpdateVehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `vehicleStock` to look up the row to update. */
export type VehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyUsingVehicleStockProviderIdKeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleStock` being updated. */
  patch: UpdateVehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyPatch;
  providerId: Scalars['String'];
};

/** The fields on `vehicleStock` to look up the row to update. */
export type VehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyUsingVehicleStockStockNumberKeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleStock` being updated. */
  patch: UpdateVehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyPatch;
  stockNumber: Scalars['String'];
};

/** The fields on `vehicleStock` to look up the row to update. */
export type VehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyUsingVehicleStockVinKeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleStock` being updated. */
  patch: UpdateVehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyPatch;
  vin: Scalars['String'];
};

/** Represents an update to a `VehicleStock`. Fields that are set will be updated. */
export type VehicleStockPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  bodyStyle?: Maybe<Scalars['String']>;
  exteriorColour?: Maybe<Scalars['String']>;
  interiorColour?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  doors?: Maybe<Scalars['Int']>;
  seats?: Maybe<Scalars['Int']>;
  engineCapacity?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  cylinders?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  warrantyText?: Maybe<Scalars['String']>;
  carProof?: Maybe<Scalars['String']>;
  isNew?: Maybe<Scalars['Boolean']>;
  certified?: Maybe<Scalars['String']>;
  special?: Maybe<Scalars['String']>;
  driveTrain?: Maybe<Scalars['String']>;
  fuelType?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  features?: Maybe<Scalars['JSON']>;
  stockStatus?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  entryDate?: Maybe<Scalars['Datetime']>;
  lastChangeDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  companyToCompanyId?: Maybe<VehicleStockCompanyIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInput>;
};

export type VehicleStockStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  doors?: Maybe<BigFloatFilter>;
  seats?: Maybe<BigFloatFilter>;
  price?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
};

export type VehicleStockStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of doors across the matching connection */
  doors?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of seats across the matching connection */
  seats?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of price across the matching connection */
  price?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
};

export type VehicleStockStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  doors?: Maybe<BigFloatFilter>;
  seats?: Maybe<BigFloatFilter>;
  price?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
};

export type VehicleStockStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of doors across the matching connection */
  doors?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of seats across the matching connection */
  seats?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of price across the matching connection */
  price?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
};

export type VehicleStockSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  year?: Maybe<BigIntFilter>;
  mileage?: Maybe<BigIntFilter>;
  doors?: Maybe<BigIntFilter>;
  seats?: Maybe<BigIntFilter>;
  price?: Maybe<BigIntFilter>;
  cost?: Maybe<BigIntFilter>;
};

export type VehicleStockSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of companyIntegrationId across the matching connection */
  companyIntegrationId: Scalars['BigInt'];
  /** Sum of year across the matching connection */
  year: Scalars['BigInt'];
  /** Sum of mileage across the matching connection */
  mileage: Scalars['BigInt'];
  /** Sum of doors across the matching connection */
  doors: Scalars['BigInt'];
  /** Sum of seats across the matching connection */
  seats: Scalars['BigInt'];
  /** Sum of price across the matching connection */
  price: Scalars['BigInt'];
  /** Sum of cost across the matching connection */
  cost: Scalars['BigInt'];
};

export type VehicleStockVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  doors?: Maybe<BigFloatFilter>;
  seats?: Maybe<BigFloatFilter>;
  price?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
};

export type VehicleStockVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Population variance of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Population variance of doors across the matching connection */
  doors?: Maybe<Scalars['BigFloat']>;
  /** Population variance of seats across the matching connection */
  seats?: Maybe<Scalars['BigFloat']>;
  /** Population variance of price across the matching connection */
  price?: Maybe<Scalars['BigFloat']>;
  /** Population variance of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
};

export type VehicleStockVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  doors?: Maybe<BigFloatFilter>;
  seats?: Maybe<BigFloatFilter>;
  price?: Maybe<BigFloatFilter>;
  cost?: Maybe<BigFloatFilter>;
};

export type VehicleStockVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of doors across the matching connection */
  doors?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of seats across the matching connection */
  seats?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of price across the matching connection */
  price?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of cost across the matching connection */
  cost?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `vehicleStock` to look up the row to connect. */
export type VehicleStockVehicleStockPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `vehicleStock` to look up the row to delete. */
export type VehicleStockVehicleStockPkeyDelete = {
  id: Scalars['Int'];
};

/** The fields on `vehicleStock` to look up the row to connect. */
export type VehicleStockVehicleStockProviderIdKeyConnect = {
  providerId: Scalars['String'];
};

/** The fields on `vehicleStock` to look up the row to delete. */
export type VehicleStockVehicleStockProviderIdKeyDelete = {
  providerId: Scalars['String'];
};

/** The fields on `vehicleStock` to look up the row to connect. */
export type VehicleStockVehicleStockStockNumberKeyConnect = {
  stockNumber: Scalars['String'];
};

/** The fields on `vehicleStock` to look up the row to delete. */
export type VehicleStockVehicleStockStockNumberKeyDelete = {
  stockNumber: Scalars['String'];
};

/** The fields on `vehicleStock` to look up the row to connect. */
export type VehicleStockVehicleStockVinKeyConnect = {
  vin: Scalars['String'];
};

/** The fields on `vehicleStock` to look up the row to delete. */
export type VehicleStockVehicleStockVinKeyDelete = {
  vin: Scalars['String'];
};

/** A connection to a list of `VehicleStock` values. */
export type VehicleStocksConnection = {
  /** A list of `VehicleStock` objects. */
  nodes: Array<VehicleStock>;
  /** A list of edges which contains the `VehicleStock` and cursor to aid in pagination. */
  edges: Array<VehicleStocksEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VehicleStock` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<VehicleStockAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<VehicleStockAggregates>>;
};


/** A connection to a list of `VehicleStock` values. */
export type VehicleStocksConnectionGroupedAggregatesArgs = {
  groupBy: Array<VehicleStockGroupBy>;
  having?: Maybe<VehicleStockHavingInput>;
};

/** A `VehicleStock` edge in the connection. */
export type VehicleStocksEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `VehicleStock` at the end of the edge. */
  node: VehicleStock;
};

/** Methods to use when ordering `VehicleStock`. */
export enum VehicleStocksOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompanyIntegrationIdAsc = 'COMPANY_INTEGRATION_ID_ASC',
  CompanyIntegrationIdDesc = 'COMPANY_INTEGRATION_ID_DESC',
  ProviderIdAsc = 'PROVIDER_ID_ASC',
  ProviderIdDesc = 'PROVIDER_ID_DESC',
  VinAsc = 'VIN_ASC',
  VinDesc = 'VIN_DESC',
  StockNumberAsc = 'STOCK_NUMBER_ASC',
  StockNumberDesc = 'STOCK_NUMBER_DESC',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MakeAsc = 'MAKE_ASC',
  MakeDesc = 'MAKE_DESC',
  ModelAsc = 'MODEL_ASC',
  ModelDesc = 'MODEL_DESC',
  TrimAsc = 'TRIM_ASC',
  TrimDesc = 'TRIM_DESC',
  BodyStyleAsc = 'BODY_STYLE_ASC',
  BodyStyleDesc = 'BODY_STYLE_DESC',
  ExteriorColourAsc = 'EXTERIOR_COLOUR_ASC',
  ExteriorColourDesc = 'EXTERIOR_COLOUR_DESC',
  InteriorColourAsc = 'INTERIOR_COLOUR_ASC',
  InteriorColourDesc = 'INTERIOR_COLOUR_DESC',
  MileageAsc = 'MILEAGE_ASC',
  MileageDesc = 'MILEAGE_DESC',
  DoorsAsc = 'DOORS_ASC',
  DoorsDesc = 'DOORS_DESC',
  SeatsAsc = 'SEATS_ASC',
  SeatsDesc = 'SEATS_DESC',
  EngineCapacityAsc = 'ENGINE_CAPACITY_ASC',
  EngineCapacityDesc = 'ENGINE_CAPACITY_DESC',
  TransmissionAsc = 'TRANSMISSION_ASC',
  TransmissionDesc = 'TRANSMISSION_DESC',
  CylindersAsc = 'CYLINDERS_ASC',
  CylindersDesc = 'CYLINDERS_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  WarrantyTextAsc = 'WARRANTY_TEXT_ASC',
  WarrantyTextDesc = 'WARRANTY_TEXT_DESC',
  CarProofAsc = 'CAR_PROOF_ASC',
  CarProofDesc = 'CAR_PROOF_DESC',
  IsNewAsc = 'IS_NEW_ASC',
  IsNewDesc = 'IS_NEW_DESC',
  CertifiedAsc = 'CERTIFIED_ASC',
  CertifiedDesc = 'CERTIFIED_DESC',
  SpecialAsc = 'SPECIAL_ASC',
  SpecialDesc = 'SPECIAL_DESC',
  DriveTrainAsc = 'DRIVE_TRAIN_ASC',
  DriveTrainDesc = 'DRIVE_TRAIN_DESC',
  FuelTypeAsc = 'FUEL_TYPE_ASC',
  FuelTypeDesc = 'FUEL_TYPE_DESC',
  MainImageAsc = 'MAIN_IMAGE_ASC',
  MainImageDesc = 'MAIN_IMAGE_DESC',
  ImagesAsc = 'IMAGES_ASC',
  ImagesDesc = 'IMAGES_DESC',
  VideosAsc = 'VIDEOS_ASC',
  VideosDesc = 'VIDEOS_DESC',
  FeaturesAsc = 'FEATURES_ASC',
  FeaturesDesc = 'FEATURES_DESC',
  StockStatusAsc = 'STOCK_STATUS_ASC',
  StockStatusDesc = 'STOCK_STATUS_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  CostAsc = 'COST_ASC',
  CostDesc = 'COST_DESC',
  EntryDateAsc = 'ENTRY_DATE_ASC',
  EntryDateDesc = 'ENTRY_DATE_DESC',
  LastChangeDateAsc = 'LAST_CHANGE_DATE_ASC',
  LastChangeDateDesc = 'LAST_CHANGE_DATE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  SearchTextAsc = 'SEARCH_TEXT_ASC',
  SearchTextDesc = 'SEARCH_TEXT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SearchTextRankAsc = 'SEARCH_TEXT_RANK_ASC',
  SearchTextRankDesc = 'SEARCH_TEXT_RANK_DESC'
}

export enum VerificationChannel {
  Phone = 'Phone'
}

export type VerificationCheck = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  type: Scalars['String'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  description: Scalars['String'];
  reasons?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `IdInquiryVerificationCheck`. */
  idInquiryVerificationChecks: IdInquiryVerificationChecksConnection;
  /** Reads and enables pagination through a set of `IdInquiryVerification`. */
  idInquiryVerificationsByIdInquiryVerificationCheckVerificationCheckIdAndVerificationId: VerificationCheckIdInquiryVerificationsByIdInquiryVerificationCheckVerificationCheckIdAndVerificationIdManyToManyConnection;
};


export type VerificationCheckIdInquiryVerificationChecksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiryVerificationChecksOrderBy>>;
  condition?: Maybe<IdInquiryVerificationCheckCondition>;
  filter?: Maybe<IdInquiryVerificationCheckFilter>;
};


export type VerificationCheckIdInquiryVerificationsByIdInquiryVerificationCheckVerificationCheckIdAndVerificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiryVerificationsOrderBy>>;
  condition?: Maybe<IdInquiryVerificationCondition>;
  filter?: Maybe<IdInquiryVerificationFilter>;
};

export type VerificationCheckAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<VerificationCheckSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<VerificationCheckDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<VerificationCheckMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<VerificationCheckMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<VerificationCheckAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<VerificationCheckStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<VerificationCheckStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<VerificationCheckVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<VerificationCheckVariancePopulationAggregates>;
};

export type VerificationCheckAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `VerificationCheck` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VerificationCheckCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `reasons` field. */
  reasons?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
};

export type VerificationCheckDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of reasons across the matching connection */
  reasons?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `VerificationCheck` object types. All fields are combined with a logical ‘and.’ */
export type VerificationCheckFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `idInquiryVerificationChecks` relation. */
  idInquiryVerificationChecks?: Maybe<VerificationCheckToManyIdInquiryVerificationCheckFilter>;
  /** Some related `idInquiryVerificationChecks` exist. */
  idInquiryVerificationChecksExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<VerificationCheckFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<VerificationCheckFilter>>;
  /** Negates the expression. */
  not?: Maybe<VerificationCheckFilter>;
};

/** Grouping methods for `VerificationCheck` for usage during aggregation. */
export enum VerificationCheckGroupBy {
  Type = 'TYPE',
  DisplayName = 'DISPLAY_NAME',
  Description = 'DESCRIPTION',
  Reasons = 'REASONS',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  DeletedAt = 'DELETED_AT',
  DeletedAtTruncatedToHour = 'DELETED_AT_TRUNCATED_TO_HOUR',
  DeletedAtTruncatedToDay = 'DELETED_AT_TRUNCATED_TO_DAY'
}

export type VerificationCheckHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type VerificationCheckHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `VerificationCheck` aggregates. */
export type VerificationCheckHavingInput = {
  AND?: Maybe<Array<VerificationCheckHavingInput>>;
  OR?: Maybe<Array<VerificationCheckHavingInput>>;
  sum?: Maybe<VerificationCheckHavingSumInput>;
  distinctCount?: Maybe<VerificationCheckHavingDistinctCountInput>;
  min?: Maybe<VerificationCheckHavingMinInput>;
  max?: Maybe<VerificationCheckHavingMaxInput>;
  average?: Maybe<VerificationCheckHavingAverageInput>;
  stddevSample?: Maybe<VerificationCheckHavingStddevSampleInput>;
  stddevPopulation?: Maybe<VerificationCheckHavingStddevPopulationInput>;
  varianceSample?: Maybe<VerificationCheckHavingVarianceSampleInput>;
  variancePopulation?: Maybe<VerificationCheckHavingVariancePopulationInput>;
};

export type VerificationCheckHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type VerificationCheckHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type VerificationCheckHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type VerificationCheckHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type VerificationCheckHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type VerificationCheckHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

export type VerificationCheckHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  deletedAt?: Maybe<HavingDatetimeFilter>;
};

/** A connection to a list of `IdInquiryVerification` values, with data from `IdInquiryVerificationCheck`. */
export type VerificationCheckIdInquiryVerificationsByIdInquiryVerificationCheckVerificationCheckIdAndVerificationIdManyToManyConnection = {
  /** A list of `IdInquiryVerification` objects. */
  nodes: Array<IdInquiryVerification>;
  /** A list of edges which contains the `IdInquiryVerification`, info from the `IdInquiryVerificationCheck`, and the cursor to aid in pagination. */
  edges: Array<VerificationCheckIdInquiryVerificationsByIdInquiryVerificationCheckVerificationCheckIdAndVerificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdInquiryVerification` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IdInquiryVerificationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IdInquiryVerificationAggregates>>;
};


/** A connection to a list of `IdInquiryVerification` values, with data from `IdInquiryVerificationCheck`. */
export type VerificationCheckIdInquiryVerificationsByIdInquiryVerificationCheckVerificationCheckIdAndVerificationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<IdInquiryVerificationGroupBy>;
  having?: Maybe<IdInquiryVerificationHavingInput>;
};

/** A `IdInquiryVerification` edge in the connection, with data from `IdInquiryVerificationCheck`. */
export type VerificationCheckIdInquiryVerificationsByIdInquiryVerificationCheckVerificationCheckIdAndVerificationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdInquiryVerification` at the end of the edge. */
  node: IdInquiryVerification;
  /** Reads and enables pagination through a set of `IdInquiryVerificationCheck`. */
  idInquiryVerificationChecksByVerificationId: IdInquiryVerificationChecksConnection;
};


/** A `IdInquiryVerification` edge in the connection, with data from `IdInquiryVerificationCheck`. */
export type VerificationCheckIdInquiryVerificationsByIdInquiryVerificationCheckVerificationCheckIdAndVerificationIdManyToManyEdgeIdInquiryVerificationChecksByVerificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdInquiryVerificationChecksOrderBy>>;
  condition?: Maybe<IdInquiryVerificationCheckCondition>;
  filter?: Maybe<IdInquiryVerificationCheckFilter>;
};

/** An input for mutations affecting `VerificationCheck` */
export type VerificationCheckInput = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationCheckIdFkeyInverseInput>;
};

export type VerificationCheckMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type VerificationCheckMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type VerificationCheckNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `verificationCheck` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type VerificationCheckNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `verificationCheck` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type VerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idInquiryVerificationCheck` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idInquiryVerificationCheck` being updated. */
  patch: IdInquiryVerificationCheckPatch;
};

/** The fields on `verificationCheck` to look up the row to update. */
export type VerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyUsingVerificationCheckPkeyUpdate = {
  /** An object where the defined keys will be set on the `verificationCheck` being updated. */
  patch: UpdateVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `verificationCheck` to look up the row to update. */
export type VerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyUsingVerificationCheckUniqueNameUpdate = {
  /** An object where the defined keys will be set on the `verificationCheck` being updated. */
  patch: UpdateVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyPatch;
  name: Scalars['String'];
};

/** Represents an update to a `VerificationCheck`. Fields that are set will be updated. */
export type VerificationCheckPatch = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationCheckIdFkeyInverseInput>;
};

export type VerificationCheckStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type VerificationCheckStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type VerificationCheckSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `IdInquiryVerificationCheck` object types. All fields are combined with a logical ‘and.’ */
export type VerificationCheckToManyIdInquiryVerificationCheckFilter = {
  /** Every related `IdInquiryVerificationCheck` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<IdInquiryVerificationCheckFilter>;
  /** Some related `IdInquiryVerificationCheck` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<IdInquiryVerificationCheckFilter>;
  /** No related `IdInquiryVerificationCheck` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<IdInquiryVerificationCheckFilter>;
  /** Aggregates across related `IdInquiryVerificationCheck` match the filter criteria. */
  aggregates?: Maybe<IdInquiryVerificationCheckAggregatesFilter>;
};

export type VerificationCheckVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type VerificationCheckVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `verificationCheck` to look up the row to connect. */
export type VerificationCheckVerificationCheckPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `verificationCheck` to look up the row to delete. */
export type VerificationCheckVerificationCheckPkeyDelete = {
  id: Scalars['Int'];
};

/** The fields on `verificationCheck` to look up the row to connect. */
export type VerificationCheckVerificationCheckUniqueNameConnect = {
  name: Scalars['String'];
};

/** The fields on `verificationCheck` to look up the row to delete. */
export type VerificationCheckVerificationCheckUniqueNameDelete = {
  name: Scalars['String'];
};

/** A connection to a list of `VerificationCheck` values. */
export type VerificationChecksConnection = {
  /** A list of `VerificationCheck` objects. */
  nodes: Array<VerificationCheck>;
  /** A list of edges which contains the `VerificationCheck` and cursor to aid in pagination. */
  edges: Array<VerificationChecksEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VerificationCheck` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<VerificationCheckAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<VerificationCheckAggregates>>;
};


/** A connection to a list of `VerificationCheck` values. */
export type VerificationChecksConnectionGroupedAggregatesArgs = {
  groupBy: Array<VerificationCheckGroupBy>;
  having?: Maybe<VerificationCheckHavingInput>;
};

/** A `VerificationCheck` edge in the connection. */
export type VerificationChecksEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `VerificationCheck` at the end of the edge. */
  node: VerificationCheck;
};

/** Methods to use when ordering `VerificationCheck`. */
export enum VerificationChecksOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  ReasonsAsc = 'REASONS_ASC',
  ReasonsDesc = 'REASONS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  IdInquiryVerificationChecksCountAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_COUNT_ASC',
  IdInquiryVerificationChecksCountDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_COUNT_DESC',
  IdInquiryVerificationChecksSumIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_ID_ASC',
  IdInquiryVerificationChecksSumIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_ID_DESC',
  IdInquiryVerificationChecksSumVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksSumVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksSumStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_STATUS_ASC',
  IdInquiryVerificationChecksSumStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_STATUS_DESC',
  IdInquiryVerificationChecksSumReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_REASONS_ASC',
  IdInquiryVerificationChecksSumReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_REASONS_DESC',
  IdInquiryVerificationChecksSumRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_REQUIRED_ASC',
  IdInquiryVerificationChecksSumRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_REQUIRED_DESC',
  IdInquiryVerificationChecksSumMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_METADATA_ASC',
  IdInquiryVerificationChecksSumMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_METADATA_DESC',
  IdInquiryVerificationChecksSumCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_CREATED_AT_ASC',
  IdInquiryVerificationChecksSumCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_CREATED_AT_DESC',
  IdInquiryVerificationChecksSumUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_UPDATED_AT_ASC',
  IdInquiryVerificationChecksSumUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_UPDATED_AT_DESC',
  IdInquiryVerificationChecksSumDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_DELETED_AT_ASC',
  IdInquiryVerificationChecksSumDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_DELETED_AT_DESC',
  IdInquiryVerificationChecksSumVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksSumVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_SUM_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksDistinctCountIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_ID_ASC',
  IdInquiryVerificationChecksDistinctCountIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_ID_DESC',
  IdInquiryVerificationChecksDistinctCountVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksDistinctCountVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksDistinctCountStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_STATUS_ASC',
  IdInquiryVerificationChecksDistinctCountStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_STATUS_DESC',
  IdInquiryVerificationChecksDistinctCountReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_REASONS_ASC',
  IdInquiryVerificationChecksDistinctCountReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_REASONS_DESC',
  IdInquiryVerificationChecksDistinctCountRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_REQUIRED_ASC',
  IdInquiryVerificationChecksDistinctCountRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_REQUIRED_DESC',
  IdInquiryVerificationChecksDistinctCountMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_METADATA_ASC',
  IdInquiryVerificationChecksDistinctCountMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_METADATA_DESC',
  IdInquiryVerificationChecksDistinctCountCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_CREATED_AT_ASC',
  IdInquiryVerificationChecksDistinctCountCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_CREATED_AT_DESC',
  IdInquiryVerificationChecksDistinctCountUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_UPDATED_AT_ASC',
  IdInquiryVerificationChecksDistinctCountUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_UPDATED_AT_DESC',
  IdInquiryVerificationChecksDistinctCountDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_DELETED_AT_ASC',
  IdInquiryVerificationChecksDistinctCountDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_DELETED_AT_DESC',
  IdInquiryVerificationChecksDistinctCountVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksDistinctCountVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_DISTINCT_COUNT_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksMinIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_ID_ASC',
  IdInquiryVerificationChecksMinIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_ID_DESC',
  IdInquiryVerificationChecksMinVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksMinVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksMinStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_STATUS_ASC',
  IdInquiryVerificationChecksMinStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_STATUS_DESC',
  IdInquiryVerificationChecksMinReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_REASONS_ASC',
  IdInquiryVerificationChecksMinReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_REASONS_DESC',
  IdInquiryVerificationChecksMinRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_REQUIRED_ASC',
  IdInquiryVerificationChecksMinRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_REQUIRED_DESC',
  IdInquiryVerificationChecksMinMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_METADATA_ASC',
  IdInquiryVerificationChecksMinMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_METADATA_DESC',
  IdInquiryVerificationChecksMinCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_CREATED_AT_ASC',
  IdInquiryVerificationChecksMinCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_CREATED_AT_DESC',
  IdInquiryVerificationChecksMinUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_UPDATED_AT_ASC',
  IdInquiryVerificationChecksMinUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_UPDATED_AT_DESC',
  IdInquiryVerificationChecksMinDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_DELETED_AT_ASC',
  IdInquiryVerificationChecksMinDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_DELETED_AT_DESC',
  IdInquiryVerificationChecksMinVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksMinVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MIN_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksMaxIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_ID_ASC',
  IdInquiryVerificationChecksMaxIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_ID_DESC',
  IdInquiryVerificationChecksMaxVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksMaxVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksMaxStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_STATUS_ASC',
  IdInquiryVerificationChecksMaxStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_STATUS_DESC',
  IdInquiryVerificationChecksMaxReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_REASONS_ASC',
  IdInquiryVerificationChecksMaxReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_REASONS_DESC',
  IdInquiryVerificationChecksMaxRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_REQUIRED_ASC',
  IdInquiryVerificationChecksMaxRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_REQUIRED_DESC',
  IdInquiryVerificationChecksMaxMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_METADATA_ASC',
  IdInquiryVerificationChecksMaxMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_METADATA_DESC',
  IdInquiryVerificationChecksMaxCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_CREATED_AT_ASC',
  IdInquiryVerificationChecksMaxCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_CREATED_AT_DESC',
  IdInquiryVerificationChecksMaxUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_UPDATED_AT_ASC',
  IdInquiryVerificationChecksMaxUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_UPDATED_AT_DESC',
  IdInquiryVerificationChecksMaxDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_DELETED_AT_ASC',
  IdInquiryVerificationChecksMaxDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_DELETED_AT_DESC',
  IdInquiryVerificationChecksMaxVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksMaxVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_MAX_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksAverageIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_ID_ASC',
  IdInquiryVerificationChecksAverageIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_ID_DESC',
  IdInquiryVerificationChecksAverageVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksAverageVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksAverageStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_STATUS_ASC',
  IdInquiryVerificationChecksAverageStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_STATUS_DESC',
  IdInquiryVerificationChecksAverageReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_REASONS_ASC',
  IdInquiryVerificationChecksAverageReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_REASONS_DESC',
  IdInquiryVerificationChecksAverageRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_REQUIRED_ASC',
  IdInquiryVerificationChecksAverageRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_REQUIRED_DESC',
  IdInquiryVerificationChecksAverageMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_METADATA_ASC',
  IdInquiryVerificationChecksAverageMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_METADATA_DESC',
  IdInquiryVerificationChecksAverageCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_CREATED_AT_ASC',
  IdInquiryVerificationChecksAverageCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_CREATED_AT_DESC',
  IdInquiryVerificationChecksAverageUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_UPDATED_AT_ASC',
  IdInquiryVerificationChecksAverageUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_UPDATED_AT_DESC',
  IdInquiryVerificationChecksAverageDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_DELETED_AT_ASC',
  IdInquiryVerificationChecksAverageDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_DELETED_AT_DESC',
  IdInquiryVerificationChecksAverageVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksAverageVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_AVERAGE_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksStddevSampleIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_ID_ASC',
  IdInquiryVerificationChecksStddevSampleIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_ID_DESC',
  IdInquiryVerificationChecksStddevSampleVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksStddevSampleVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksStddevSampleStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_STATUS_ASC',
  IdInquiryVerificationChecksStddevSampleStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_STATUS_DESC',
  IdInquiryVerificationChecksStddevSampleReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_REASONS_ASC',
  IdInquiryVerificationChecksStddevSampleReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_REASONS_DESC',
  IdInquiryVerificationChecksStddevSampleRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_REQUIRED_ASC',
  IdInquiryVerificationChecksStddevSampleRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_REQUIRED_DESC',
  IdInquiryVerificationChecksStddevSampleMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_METADATA_ASC',
  IdInquiryVerificationChecksStddevSampleMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_METADATA_DESC',
  IdInquiryVerificationChecksStddevSampleCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_CREATED_AT_ASC',
  IdInquiryVerificationChecksStddevSampleCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_CREATED_AT_DESC',
  IdInquiryVerificationChecksStddevSampleUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  IdInquiryVerificationChecksStddevSampleUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  IdInquiryVerificationChecksStddevSampleDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_DELETED_AT_ASC',
  IdInquiryVerificationChecksStddevSampleDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_DELETED_AT_DESC',
  IdInquiryVerificationChecksStddevSampleVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksStddevSampleVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_SAMPLE_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksStddevPopulationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_ID_ASC',
  IdInquiryVerificationChecksStddevPopulationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_ID_DESC',
  IdInquiryVerificationChecksStddevPopulationVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksStddevPopulationVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksStddevPopulationStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_STATUS_ASC',
  IdInquiryVerificationChecksStddevPopulationStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_STATUS_DESC',
  IdInquiryVerificationChecksStddevPopulationReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_REASONS_ASC',
  IdInquiryVerificationChecksStddevPopulationReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_REASONS_DESC',
  IdInquiryVerificationChecksStddevPopulationRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_REQUIRED_ASC',
  IdInquiryVerificationChecksStddevPopulationRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_REQUIRED_DESC',
  IdInquiryVerificationChecksStddevPopulationMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_METADATA_ASC',
  IdInquiryVerificationChecksStddevPopulationMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_METADATA_DESC',
  IdInquiryVerificationChecksStddevPopulationCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_CREATED_AT_ASC',
  IdInquiryVerificationChecksStddevPopulationCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_CREATED_AT_DESC',
  IdInquiryVerificationChecksStddevPopulationUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_UPDATED_AT_ASC',
  IdInquiryVerificationChecksStddevPopulationUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_UPDATED_AT_DESC',
  IdInquiryVerificationChecksStddevPopulationDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_DELETED_AT_ASC',
  IdInquiryVerificationChecksStddevPopulationDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_DELETED_AT_DESC',
  IdInquiryVerificationChecksStddevPopulationVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksStddevPopulationVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_STDDEV_POPULATION_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksVarianceSampleIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_ID_ASC',
  IdInquiryVerificationChecksVarianceSampleIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_ID_DESC',
  IdInquiryVerificationChecksVarianceSampleVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksVarianceSampleVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksVarianceSampleStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_STATUS_ASC',
  IdInquiryVerificationChecksVarianceSampleStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_STATUS_DESC',
  IdInquiryVerificationChecksVarianceSampleReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_REASONS_ASC',
  IdInquiryVerificationChecksVarianceSampleReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_REASONS_DESC',
  IdInquiryVerificationChecksVarianceSampleRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_REQUIRED_ASC',
  IdInquiryVerificationChecksVarianceSampleRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_REQUIRED_DESC',
  IdInquiryVerificationChecksVarianceSampleMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_METADATA_ASC',
  IdInquiryVerificationChecksVarianceSampleMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_METADATA_DESC',
  IdInquiryVerificationChecksVarianceSampleCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  IdInquiryVerificationChecksVarianceSampleCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  IdInquiryVerificationChecksVarianceSampleUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  IdInquiryVerificationChecksVarianceSampleUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdInquiryVerificationChecksVarianceSampleDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  IdInquiryVerificationChecksVarianceSampleDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  IdInquiryVerificationChecksVarianceSampleVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksVarianceSampleVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_SAMPLE_VERIFICATION_CHECK_ID_DESC',
  IdInquiryVerificationChecksVariancePopulationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_ID_ASC',
  IdInquiryVerificationChecksVariancePopulationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_ID_DESC',
  IdInquiryVerificationChecksVariancePopulationVerificationIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_VERIFICATION_ID_ASC',
  IdInquiryVerificationChecksVariancePopulationVerificationIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_VERIFICATION_ID_DESC',
  IdInquiryVerificationChecksVariancePopulationStatusAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_STATUS_ASC',
  IdInquiryVerificationChecksVariancePopulationStatusDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_STATUS_DESC',
  IdInquiryVerificationChecksVariancePopulationReasonsAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_REASONS_ASC',
  IdInquiryVerificationChecksVariancePopulationReasonsDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_REASONS_DESC',
  IdInquiryVerificationChecksVariancePopulationRequiredAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_REQUIRED_ASC',
  IdInquiryVerificationChecksVariancePopulationRequiredDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_REQUIRED_DESC',
  IdInquiryVerificationChecksVariancePopulationMetadataAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_METADATA_ASC',
  IdInquiryVerificationChecksVariancePopulationMetadataDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_METADATA_DESC',
  IdInquiryVerificationChecksVariancePopulationCreatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_CREATED_AT_ASC',
  IdInquiryVerificationChecksVariancePopulationCreatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_CREATED_AT_DESC',
  IdInquiryVerificationChecksVariancePopulationUpdatedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  IdInquiryVerificationChecksVariancePopulationUpdatedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  IdInquiryVerificationChecksVariancePopulationDeletedAtAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_DELETED_AT_ASC',
  IdInquiryVerificationChecksVariancePopulationDeletedAtDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_DELETED_AT_DESC',
  IdInquiryVerificationChecksVariancePopulationVerificationCheckIdAsc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_VERIFICATION_CHECK_ID_ASC',
  IdInquiryVerificationChecksVariancePopulationVerificationCheckIdDesc = 'ID_INQUIRY_VERIFICATION_CHECKS_VARIANCE_POPULATION_VERIFICATION_CHECK_ID_DESC'
}

export type VerificationResponse = {
  success: Scalars['Boolean'];
  leadId: Scalars['ID'];
  phoneVerifiedAt?: Maybe<Scalars['String']>;
};

export type Widget = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['String'];
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `CompanyWidget`. */
  companyWidgets: CompanyWidgetsConnection;
  /** Reads and enables pagination through a set of `CtaWidget`. */
  ctaWidgets: CtaWidgetsConnection;
  /** Reads and enables pagination through a set of `WidgetSetting`. */
  widgetSettings: WidgetSettingsConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCompanyWidgetWidgetIdAndGroupId: WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Cta`. */
  ctasByCtaWidgetWidgetIdAndCtaId: WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FieldKey`. */
  fieldKeysByWidgetSettingWidgetIdAndFieldKey: WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyConnection;
  config?: Maybe<WidgetConfig>;
};


export type WidgetCompanyWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
  condition?: Maybe<CompanyWidgetCondition>;
  filter?: Maybe<CompanyWidgetFilter>;
};


export type WidgetCtaWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
  condition?: Maybe<CtaWidgetCondition>;
  filter?: Maybe<CtaWidgetFilter>;
};


export type WidgetWidgetSettingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
  condition?: Maybe<WidgetSettingCondition>;
  filter?: Maybe<WidgetSettingFilter>;
};


export type WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


export type WidgetCtasByCtaWidgetWidgetIdAndCtaIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtasOrderBy>>;
  condition?: Maybe<CtaCondition>;
  filter?: Maybe<CtaFilter>;
};


export type WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldKeysOrderBy>>;
  condition?: Maybe<FieldKeyCondition>;
  filter?: Maybe<FieldKeyFilter>;
};

export type WidgetAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<WidgetDistinctCountAggregates>;
};

export type WidgetCompanyDetails = {
  id: Scalars['Int'];
  name: Scalars['String'];
  widgetId: Scalars['String'];
  archived: Scalars['Boolean'];
  products: Array<ProductType>;
  meta?: Maybe<WidgetCompanyMeta>;
};

export type WidgetCompanyMeta = {
  tradeTool?: Maybe<WidgetCompanyTradeToolMeta>;
};

export type WidgetCompanyTradeToolMeta = {
  provider?: Maybe<Scalars['String']>;
};

/** A condition to be used against `Widget` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type WidgetCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `widgetSpec` field. */
  widgetSpec?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `ctaTheme` field. */
  ctaTheme?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `variant` field. */
  variant?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rawConfig` field. */
  rawConfig?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type WidgetConfig = {
  language?: Maybe<WidgetLanguage>;
  inventoryVertical?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  buttonBg?: Maybe<Scalars['String']>;
};

/** A connection to a list of `Cta` values, with data from `CtaWidget`. */
export type WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyConnection = {
  /** A list of `Cta` objects. */
  nodes: Array<Cta>;
  /** A list of edges which contains the `Cta`, info from the `CtaWidget`, and the cursor to aid in pagination. */
  edges: Array<WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Cta` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CtaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CtaAggregates>>;
};


/** A connection to a list of `Cta` values, with data from `CtaWidget`. */
export type WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CtasGroupBy>;
  having?: Maybe<CtasHavingInput>;
};

/** A `Cta` edge in the connection, with data from `CtaWidget`. */
export type WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Cta` at the end of the edge. */
  node: Cta;
  /** Reads and enables pagination through a set of `CtaWidget`. */
  ctaWidgets: CtaWidgetsConnection;
};


/** A `Cta` edge in the connection, with data from `CtaWidget`. */
export type WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyEdgeCtaWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
  condition?: Maybe<CtaWidgetCondition>;
  filter?: Maybe<CtaWidgetFilter>;
};

export type WidgetDetailsResponse = {
  company: WidgetCompanyDetails;
  group: WidgetGroupDetails;
  memberCompanies: Array<WidgetCompanyDetails>;
  config?: Maybe<WidgetConfig>;
  /** Content overrides defined for the input cta and/or the selected company */
  widgetOverrides?: Maybe<Scalars['JSON']>;
  settings: Array<WidgetSettingType>;
  fields: Array<WidgetField>;
  productIntegrations: Array<WidgetProductIntegration>;
};

export type WidgetDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetSpec across the matching connection */
  widgetSpec?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaTheme across the matching connection */
  ctaTheme?: Maybe<Scalars['BigInt']>;
  /** Distinct count of variant across the matching connection */
  variant?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawConfig across the matching connection */
  rawConfig?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

export type WidgetField = {
  product?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** A connection to a list of `FieldKey` values, with data from `WidgetSetting`. */
export type WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyConnection = {
  /** A list of `FieldKey` objects. */
  nodes: Array<FieldKey>;
  /** A list of edges which contains the `FieldKey`, info from the `WidgetSetting`, and the cursor to aid in pagination. */
  edges: Array<WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldKey` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldKeyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldKeyAggregates>>;
};


/** A connection to a list of `FieldKey` values, with data from `WidgetSetting`. */
export type WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldKeyGroupBy>;
  having?: Maybe<FieldKeyHavingInput>;
};

/** A `FieldKey` edge in the connection, with data from `WidgetSetting`. */
export type WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldKey` at the end of the edge. */
  node: FieldKey;
  /** Reads and enables pagination through a set of `WidgetSetting`. */
  widgetSettingsByFieldKey: WidgetSettingsConnection;
};


/** A `FieldKey` edge in the connection, with data from `WidgetSetting`. */
export type WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyEdgeWidgetSettingsByFieldKeyArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
  condition?: Maybe<WidgetSettingCondition>;
  filter?: Maybe<WidgetSettingFilter>;
};

/** A filter to be used against `Widget` object types. All fields are combined with a logical ‘and.’ */
export type WidgetFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<StringFilter>;
  /** Filter by the object’s `ctaTheme` field. */
  ctaTheme?: Maybe<StringFilter>;
  /** Filter by the object’s `variant` field. */
  variant?: Maybe<StringFilter>;
  /** Filter by the object’s `rawConfig` field. */
  rawConfig?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `companyWidgets` relation. */
  companyWidgets?: Maybe<WidgetToManyCompanyWidgetFilter>;
  /** Some related `companyWidgets` exist. */
  companyWidgetsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `ctaWidgets` relation. */
  ctaWidgets?: Maybe<WidgetToManyCtaWidgetFilter>;
  /** Some related `ctaWidgets` exist. */
  ctaWidgetsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `widgetSettings` relation. */
  widgetSettings?: Maybe<WidgetToManyWidgetSettingFilter>;
  /** Some related `widgetSettings` exist. */
  widgetSettingsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<WidgetFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<WidgetFilter>>;
  /** Negates the expression. */
  not?: Maybe<WidgetFilter>;
};

export type WidgetGroupDetails = {
  id: Scalars['Int'];
  name: Scalars['String'];
  type: Scalars['String'];
  legalDesc?: Maybe<Scalars['String']>;
  distributionType: GroupDistType;
};

/** A connection to a list of `Group` values, with data from `CompanyWidget`. */
export type WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdManyToManyConnection = {
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `CompanyWidget`, and the cursor to aid in pagination. */
  edges: Array<WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GroupAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GroupAggregates>>;
};


/** A connection to a list of `Group` values, with data from `CompanyWidget`. */
export type WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<GroupGroupBy>;
  having?: Maybe<GroupHavingInput>;
};

/** A `Group` edge in the connection, with data from `CompanyWidget`. */
export type WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  specOverride?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** An input for mutations affecting `Widget` */
export type WidgetInput = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

export enum WidgetLanguage {
  En = 'En',
  Es = 'Es',
  Fr = 'Fr'
}

/** The globally unique `ID` look up for the row to connect. */
export type WidgetNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `widget` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type WidgetNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `widget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyWidget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyWidget` being updated. */
  patch: CompanyWidgetPatch;
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkPatch;
  id: Scalars['String'];
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingWidgetsUqVariantUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkPatch;
  variant: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `ctaWidget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ctaWidget` being updated. */
  patch: CtaWidgetPatch;
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkPatch;
  id: Scalars['String'];
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingWidgetsUqVariantUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkPatch;
  variant: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `widgetSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `widgetSetting` being updated. */
  patch: WidgetSettingPatch;
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyPatch;
  id: Scalars['String'];
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetsUqVariantUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyPatch;
  variant: Scalars['String'];
};

/** Represents an update to a `Widget`. Fields that are set will be updated. */
export type WidgetPatch = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

export type WidgetProductIntegration = {
  product?: Maybe<Scalars['String']>;
  integrations?: Maybe<Array<Scalars['String']>>;
};

export type WidgetSetting = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Widget` that is related to this `WidgetSetting`. */
  widget?: Maybe<Widget>;
  /** Reads a single `FieldKey` that is related to this `WidgetSetting`. */
  fieldKeyByFieldKey?: Maybe<FieldKey>;
};

export type WidgetSettingAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<WidgetSettingSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<WidgetSettingDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<WidgetSettingMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<WidgetSettingMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<WidgetSettingAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<WidgetSettingStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<WidgetSettingStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<WidgetSettingVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<WidgetSettingVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `WidgetSetting` object types. */
export type WidgetSettingAggregatesFilter = {
  /** A filter that must pass for the relevant `WidgetSetting` object to be included within the aggregate. */
  filter?: Maybe<WidgetSettingFilter>;
  /** Sum aggregate over matching `WidgetSetting` objects. */
  sum?: Maybe<WidgetSettingSumAggregateFilter>;
  /** Distinct count aggregate over matching `WidgetSetting` objects. */
  distinctCount?: Maybe<WidgetSettingDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `WidgetSetting` objects. */
  min?: Maybe<WidgetSettingMinAggregateFilter>;
  /** Maximum aggregate over matching `WidgetSetting` objects. */
  max?: Maybe<WidgetSettingMaxAggregateFilter>;
  /** Mean average aggregate over matching `WidgetSetting` objects. */
  average?: Maybe<WidgetSettingAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `WidgetSetting` objects. */
  stddevSample?: Maybe<WidgetSettingStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `WidgetSetting` objects. */
  stddevPopulation?: Maybe<WidgetSettingStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `WidgetSetting` objects. */
  varianceSample?: Maybe<WidgetSettingVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `WidgetSetting` objects. */
  variancePopulation?: Maybe<WidgetSettingVariancePopulationAggregateFilter>;
};

export type WidgetSettingAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldKey?: Maybe<BigFloatFilter>;
};

export type WidgetSettingAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `WidgetSetting` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type WidgetSettingCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `widgetId` field. */
  widgetId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `topic` field. */
  topic?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `fieldKey` field. */
  fieldKey?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type WidgetSettingDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  widgetId?: Maybe<BigIntFilter>;
  topic?: Maybe<BigIntFilter>;
  fieldKey?: Maybe<BigIntFilter>;
  value?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type WidgetSettingDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetId across the matching connection */
  widgetId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of topic across the matching connection */
  topic?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `WidgetSetting` object types. All fields are combined with a logical ‘and.’ */
export type WidgetSettingFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `widgetId` field. */
  widgetId?: Maybe<StringFilter>;
  /** Filter by the object’s `topic` field. */
  topic?: Maybe<StringFilter>;
  /** Filter by the object’s `fieldKey` field. */
  fieldKey?: Maybe<IntFilter>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `widget` relation. */
  widget?: Maybe<WidgetFilter>;
  /** A related `widget` exists. */
  widgetExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fieldKeyByFieldKey` relation. */
  fieldKeyByFieldKey?: Maybe<FieldKeyFilter>;
  /** A related `fieldKeyByFieldKey` exists. */
  fieldKeyByFieldKeyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<WidgetSettingFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<WidgetSettingFilter>>;
  /** Negates the expression. */
  not?: Maybe<WidgetSettingFilter>;
};

/** An input for mutations affecting `WidgetSetting` */
export type WidgetSettingInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

export type WidgetSettingMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  fieldKey?: Maybe<IntFilter>;
};

export type WidgetSettingMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['Int']>;
};

export type WidgetSettingMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  fieldKey?: Maybe<IntFilter>;
};

export type WidgetSettingMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type WidgetSettingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `widgetSetting` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type WidgetSettingNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `widgetSetting` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type WidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `fieldKey` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `fieldKey` being updated. */
  patch: FieldKeyPatch;
};

/** The fields on `widgetSetting` to look up the row to update. */
export type WidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingWidgetSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `widgetSetting` being updated. */
  patch: UpdateWidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type WidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `widget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: WidgetPatch;
};

/** The fields on `widgetSetting` to look up the row to update. */
export type WidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `widgetSetting` being updated. */
  patch: UpdateWidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `WidgetSetting`. Fields that are set will be updated. */
export type WidgetSettingPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

export type WidgetSettingStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldKey?: Maybe<BigFloatFilter>;
};

export type WidgetSettingStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigFloat']>;
};

export type WidgetSettingStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldKey?: Maybe<BigFloatFilter>;
};

export type WidgetSettingStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigFloat']>;
};

export type WidgetSettingSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  fieldKey?: Maybe<BigIntFilter>;
};

export type WidgetSettingSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of fieldKey across the matching connection */
  fieldKey: Scalars['BigInt'];
};

export type WidgetSettingType = {
  id: Scalars['Int'];
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  field?: Maybe<FieldKeyType>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type WidgetSettingVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldKey?: Maybe<BigFloatFilter>;
};

export type WidgetSettingVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigFloat']>;
};

export type WidgetSettingVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldKey?: Maybe<BigFloatFilter>;
};

export type WidgetSettingVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `widgetSetting` to look up the row to connect. */
export type WidgetSettingWidgetSettingsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `widgetSetting` to look up the row to delete. */
export type WidgetSettingWidgetSettingsPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `WidgetSetting` values. */
export type WidgetSettingsConnection = {
  /** A list of `WidgetSetting` objects. */
  nodes: Array<WidgetSetting>;
  /** A list of edges which contains the `WidgetSetting` and cursor to aid in pagination. */
  edges: Array<WidgetSettingsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WidgetSetting` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WidgetSettingAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WidgetSettingAggregates>>;
};


/** A connection to a list of `WidgetSetting` values. */
export type WidgetSettingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<WidgetSettingsGroupBy>;
  having?: Maybe<WidgetSettingsHavingInput>;
};

/** A `WidgetSetting` edge in the connection. */
export type WidgetSettingsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `WidgetSetting` at the end of the edge. */
  node: WidgetSetting;
};

/** The `fieldKey` to be created by this mutation. */
export type WidgetSettingsFieldKeyFkeyFieldKeyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  type: FieldType;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  key: Scalars['String'];
  widgetSettingsUsingId?: Maybe<WidgetSettingsFieldKeyFkeyInverseInput>;
};

/** Input for the nested mutation of `fieldKey` in the `WidgetSettingInput` mutation. */
export type WidgetSettingsFieldKeyFkeyInput = {
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  connectById?: Maybe<FieldKeyFieldKeyPkeyConnect>;
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  connectByKey?: Maybe<FieldKeyFieldKeyKeyKeyConnect>;
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  connectByNodeId?: Maybe<FieldKeyNodeIdConnect>;
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  deleteById?: Maybe<FieldKeyFieldKeyPkeyDelete>;
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  deleteByKey?: Maybe<FieldKeyFieldKeyKeyKeyDelete>;
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FieldKeyNodeIdDelete>;
  /** The primary key(s) and patch data for `fieldKey` for the far side of the relationship. */
  updateById?: Maybe<FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingFieldKeyPkeyUpdate>;
  /** The primary key(s) and patch data for `fieldKey` for the far side of the relationship. */
  updateByKey?: Maybe<FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingFieldKeyKeyKeyUpdate>;
  /** The primary key(s) and patch data for `fieldKey` for the far side of the relationship. */
  updateByNodeId?: Maybe<WidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyNodeIdUpdate>;
  /** A `FieldKeyInput` object that will be created and connected to this object. */
  create?: Maybe<WidgetSettingsFieldKeyFkeyFieldKeyCreateInput>;
};

/** Input for the nested mutation of `widgetSetting` in the `FieldKeyInput` mutation. */
export type WidgetSettingsFieldKeyFkeyInverseInput = {
  /** Flag indicating whether all other `widgetSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  connectById?: Maybe<Array<WidgetSettingWidgetSettingsPkeyConnect>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<WidgetSettingNodeIdConnect>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  deleteById?: Maybe<Array<WidgetSettingWidgetSettingsPkeyDelete>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<WidgetSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `widgetSetting` for the far side of the relationship. */
  updateById?: Maybe<Array<WidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingWidgetSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `widgetSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyNodeIdUpdate>>;
  /** A `WidgetSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<WidgetSettingsFieldKeyFkeyWidgetSettingsCreateInput>>;
};

/** The `widgetSetting` to be created by this mutation. */
export type WidgetSettingsFieldKeyFkeyWidgetSettingsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

/** Grouping methods for `WidgetSetting` for usage during aggregation. */
export enum WidgetSettingsGroupBy {
  WidgetId = 'WIDGET_ID',
  Topic = 'TOPIC',
  FieldKey = 'FIELD_KEY',
  Value = 'VALUE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type WidgetSettingsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `WidgetSetting` aggregates. */
export type WidgetSettingsHavingInput = {
  AND?: Maybe<Array<WidgetSettingsHavingInput>>;
  OR?: Maybe<Array<WidgetSettingsHavingInput>>;
  sum?: Maybe<WidgetSettingsHavingSumInput>;
  distinctCount?: Maybe<WidgetSettingsHavingDistinctCountInput>;
  min?: Maybe<WidgetSettingsHavingMinInput>;
  max?: Maybe<WidgetSettingsHavingMaxInput>;
  average?: Maybe<WidgetSettingsHavingAverageInput>;
  stddevSample?: Maybe<WidgetSettingsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<WidgetSettingsHavingStddevPopulationInput>;
  varianceSample?: Maybe<WidgetSettingsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<WidgetSettingsHavingVariancePopulationInput>;
};

export type WidgetSettingsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `WidgetSetting`. */
export enum WidgetSettingsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  WidgetIdAsc = 'WIDGET_ID_ASC',
  WidgetIdDesc = 'WIDGET_ID_DESC',
  TopicAsc = 'TOPIC_ASC',
  TopicDesc = 'TOPIC_DESC',
  FieldKeyAsc = 'FIELD_KEY_ASC',
  FieldKeyDesc = 'FIELD_KEY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Input for the nested mutation of `widget` in the `WidgetSettingInput` mutation. */
export type WidgetSettingsWidgetIdFkeyInput = {
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectById?: Maybe<WidgetWidgetsPkeyConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByVariant?: Maybe<WidgetWidgetsUqVariantConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByNodeId?: Maybe<WidgetNodeIdConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteById?: Maybe<WidgetWidgetsPkeyDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByVariant?: Maybe<WidgetWidgetsUqVariantDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<WidgetNodeIdDelete>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateById?: Maybe<WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetsPkeyUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByVariant?: Maybe<WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetsUqVariantUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByNodeId?: Maybe<WidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyNodeIdUpdate>;
  /** A `WidgetInput` object that will be created and connected to this object. */
  create?: Maybe<WidgetSettingsWidgetIdFkeyWidgetsCreateInput>;
};

/** Input for the nested mutation of `widgetSetting` in the `WidgetInput` mutation. */
export type WidgetSettingsWidgetIdFkeyInverseInput = {
  /** Flag indicating whether all other `widgetSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  connectById?: Maybe<Array<WidgetSettingWidgetSettingsPkeyConnect>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<WidgetSettingNodeIdConnect>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  deleteById?: Maybe<Array<WidgetSettingWidgetSettingsPkeyDelete>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<WidgetSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `widgetSetting` for the far side of the relationship. */
  updateById?: Maybe<Array<WidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `widgetSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyNodeIdUpdate>>;
  /** A `WidgetSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<WidgetSettingsWidgetIdFkeyWidgetSettingsCreateInput>>;
};

/** The `widgetSetting` to be created by this mutation. */
export type WidgetSettingsWidgetIdFkeyWidgetSettingsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

/** The `widget` to be created by this mutation. */
export type WidgetSettingsWidgetIdFkeyWidgetsCreateInput = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

/** A filter to be used against many `CompanyWidget` object types. All fields are combined with a logical ‘and.’ */
export type WidgetToManyCompanyWidgetFilter = {
  /** Every related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyWidgetFilter>;
  /** Some related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyWidgetFilter>;
  /** No related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyWidgetFilter>;
  /** Aggregates across related `CompanyWidget` match the filter criteria. */
  aggregates?: Maybe<CompanyWidgetAggregatesFilter>;
};

/** A filter to be used against many `CtaWidget` object types. All fields are combined with a logical ‘and.’ */
export type WidgetToManyCtaWidgetFilter = {
  /** Every related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CtaWidgetFilter>;
  /** Some related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CtaWidgetFilter>;
  /** No related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CtaWidgetFilter>;
  /** Aggregates across related `CtaWidget` match the filter criteria. */
  aggregates?: Maybe<CtaWidgetAggregatesFilter>;
};

/** A filter to be used against many `WidgetSetting` object types. All fields are combined with a logical ‘and.’ */
export type WidgetToManyWidgetSettingFilter = {
  /** Every related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<WidgetSettingFilter>;
  /** Some related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<WidgetSettingFilter>;
  /** No related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<WidgetSettingFilter>;
  /** Aggregates across related `WidgetSetting` match the filter criteria. */
  aggregates?: Maybe<WidgetSettingAggregatesFilter>;
};

/** The fields on `widget` to look up the row to connect. */
export type WidgetWidgetsPkeyConnect = {
  id: Scalars['String'];
};

/** The fields on `widget` to look up the row to delete. */
export type WidgetWidgetsPkeyDelete = {
  id: Scalars['String'];
};

/** The fields on `widget` to look up the row to connect. */
export type WidgetWidgetsUqVariantConnect = {
  variant: Scalars['String'];
};

/** The fields on `widget` to look up the row to delete. */
export type WidgetWidgetsUqVariantDelete = {
  variant: Scalars['String'];
};

/** A connection to a list of `Widget` values. */
export type WidgetsConnection = {
  /** A list of `Widget` objects. */
  nodes: Array<Widget>;
  /** A list of edges which contains the `Widget` and cursor to aid in pagination. */
  edges: Array<WidgetsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Widget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WidgetAggregates>>;
};


/** A connection to a list of `Widget` values. */
export type WidgetsConnectionGroupedAggregatesArgs = {
  groupBy: Array<WidgetsGroupBy>;
  having?: Maybe<WidgetsHavingInput>;
};

/** A `Widget` edge in the connection. */
export type WidgetsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Widget` at the end of the edge. */
  node: Widget;
};

/** Grouping methods for `Widget` for usage during aggregation. */
export enum WidgetsGroupBy {
  WidgetSpec = 'WIDGET_SPEC',
  CtaTheme = 'CTA_THEME',
  RawConfig = 'RAW_CONFIG',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type WidgetsHavingAverageInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetsHavingDistinctCountInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Widget` aggregates. */
export type WidgetsHavingInput = {
  AND?: Maybe<Array<WidgetsHavingInput>>;
  OR?: Maybe<Array<WidgetsHavingInput>>;
  sum?: Maybe<WidgetsHavingSumInput>;
  distinctCount?: Maybe<WidgetsHavingDistinctCountInput>;
  min?: Maybe<WidgetsHavingMinInput>;
  max?: Maybe<WidgetsHavingMaxInput>;
  average?: Maybe<WidgetsHavingAverageInput>;
  stddevSample?: Maybe<WidgetsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<WidgetsHavingStddevPopulationInput>;
  varianceSample?: Maybe<WidgetsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<WidgetsHavingVariancePopulationInput>;
};

export type WidgetsHavingMaxInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetsHavingMinInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetsHavingStddevPopulationInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetsHavingStddevSampleInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetsHavingSumInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetsHavingVariancePopulationInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetsHavingVarianceSampleInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Widget`. */
export enum WidgetsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  WidgetSpecAsc = 'WIDGET_SPEC_ASC',
  WidgetSpecDesc = 'WIDGET_SPEC_DESC',
  CtaThemeAsc = 'CTA_THEME_ASC',
  CtaThemeDesc = 'CTA_THEME_DESC',
  VariantAsc = 'VARIANT_ASC',
  VariantDesc = 'VARIANT_DESC',
  RawConfigAsc = 'RAW_CONFIG_ASC',
  RawConfigDesc = 'RAW_CONFIG_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyWidgetsCountAsc = 'COMPANY_WIDGETS_COUNT_ASC',
  CompanyWidgetsCountDesc = 'COMPANY_WIDGETS_COUNT_DESC',
  CompanyWidgetsSumGroupIdAsc = 'COMPANY_WIDGETS_SUM_GROUP_ID_ASC',
  CompanyWidgetsSumGroupIdDesc = 'COMPANY_WIDGETS_SUM_GROUP_ID_DESC',
  CompanyWidgetsSumWidgetIdAsc = 'COMPANY_WIDGETS_SUM_WIDGET_ID_ASC',
  CompanyWidgetsSumWidgetIdDesc = 'COMPANY_WIDGETS_SUM_WIDGET_ID_DESC',
  CompanyWidgetsSumSpecOverrideAsc = 'COMPANY_WIDGETS_SUM_SPEC_OVERRIDE_ASC',
  CompanyWidgetsSumSpecOverrideDesc = 'COMPANY_WIDGETS_SUM_SPEC_OVERRIDE_DESC',
  CompanyWidgetsSumCreatedAtAsc = 'COMPANY_WIDGETS_SUM_CREATED_AT_ASC',
  CompanyWidgetsSumCreatedAtDesc = 'COMPANY_WIDGETS_SUM_CREATED_AT_DESC',
  CompanyWidgetsSumUpdatedAtAsc = 'COMPANY_WIDGETS_SUM_UPDATED_AT_ASC',
  CompanyWidgetsSumUpdatedAtDesc = 'COMPANY_WIDGETS_SUM_UPDATED_AT_DESC',
  CompanyWidgetsDistinctCountGroupIdAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyWidgetsDistinctCountGroupIdDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyWidgetsDistinctCountWidgetIdAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CompanyWidgetsDistinctCountWidgetIdDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CompanyWidgetsDistinctCountSpecOverrideAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_SPEC_OVERRIDE_ASC',
  CompanyWidgetsDistinctCountSpecOverrideDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_SPEC_OVERRIDE_DESC',
  CompanyWidgetsDistinctCountCreatedAtAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyWidgetsDistinctCountCreatedAtDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyWidgetsDistinctCountUpdatedAtAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyWidgetsDistinctCountUpdatedAtDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyWidgetsMinGroupIdAsc = 'COMPANY_WIDGETS_MIN_GROUP_ID_ASC',
  CompanyWidgetsMinGroupIdDesc = 'COMPANY_WIDGETS_MIN_GROUP_ID_DESC',
  CompanyWidgetsMinWidgetIdAsc = 'COMPANY_WIDGETS_MIN_WIDGET_ID_ASC',
  CompanyWidgetsMinWidgetIdDesc = 'COMPANY_WIDGETS_MIN_WIDGET_ID_DESC',
  CompanyWidgetsMinSpecOverrideAsc = 'COMPANY_WIDGETS_MIN_SPEC_OVERRIDE_ASC',
  CompanyWidgetsMinSpecOverrideDesc = 'COMPANY_WIDGETS_MIN_SPEC_OVERRIDE_DESC',
  CompanyWidgetsMinCreatedAtAsc = 'COMPANY_WIDGETS_MIN_CREATED_AT_ASC',
  CompanyWidgetsMinCreatedAtDesc = 'COMPANY_WIDGETS_MIN_CREATED_AT_DESC',
  CompanyWidgetsMinUpdatedAtAsc = 'COMPANY_WIDGETS_MIN_UPDATED_AT_ASC',
  CompanyWidgetsMinUpdatedAtDesc = 'COMPANY_WIDGETS_MIN_UPDATED_AT_DESC',
  CompanyWidgetsMaxGroupIdAsc = 'COMPANY_WIDGETS_MAX_GROUP_ID_ASC',
  CompanyWidgetsMaxGroupIdDesc = 'COMPANY_WIDGETS_MAX_GROUP_ID_DESC',
  CompanyWidgetsMaxWidgetIdAsc = 'COMPANY_WIDGETS_MAX_WIDGET_ID_ASC',
  CompanyWidgetsMaxWidgetIdDesc = 'COMPANY_WIDGETS_MAX_WIDGET_ID_DESC',
  CompanyWidgetsMaxSpecOverrideAsc = 'COMPANY_WIDGETS_MAX_SPEC_OVERRIDE_ASC',
  CompanyWidgetsMaxSpecOverrideDesc = 'COMPANY_WIDGETS_MAX_SPEC_OVERRIDE_DESC',
  CompanyWidgetsMaxCreatedAtAsc = 'COMPANY_WIDGETS_MAX_CREATED_AT_ASC',
  CompanyWidgetsMaxCreatedAtDesc = 'COMPANY_WIDGETS_MAX_CREATED_AT_DESC',
  CompanyWidgetsMaxUpdatedAtAsc = 'COMPANY_WIDGETS_MAX_UPDATED_AT_ASC',
  CompanyWidgetsMaxUpdatedAtDesc = 'COMPANY_WIDGETS_MAX_UPDATED_AT_DESC',
  CompanyWidgetsAverageGroupIdAsc = 'COMPANY_WIDGETS_AVERAGE_GROUP_ID_ASC',
  CompanyWidgetsAverageGroupIdDesc = 'COMPANY_WIDGETS_AVERAGE_GROUP_ID_DESC',
  CompanyWidgetsAverageWidgetIdAsc = 'COMPANY_WIDGETS_AVERAGE_WIDGET_ID_ASC',
  CompanyWidgetsAverageWidgetIdDesc = 'COMPANY_WIDGETS_AVERAGE_WIDGET_ID_DESC',
  CompanyWidgetsAverageSpecOverrideAsc = 'COMPANY_WIDGETS_AVERAGE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsAverageSpecOverrideDesc = 'COMPANY_WIDGETS_AVERAGE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsAverageCreatedAtAsc = 'COMPANY_WIDGETS_AVERAGE_CREATED_AT_ASC',
  CompanyWidgetsAverageCreatedAtDesc = 'COMPANY_WIDGETS_AVERAGE_CREATED_AT_DESC',
  CompanyWidgetsAverageUpdatedAtAsc = 'COMPANY_WIDGETS_AVERAGE_UPDATED_AT_ASC',
  CompanyWidgetsAverageUpdatedAtDesc = 'COMPANY_WIDGETS_AVERAGE_UPDATED_AT_DESC',
  CompanyWidgetsStddevSampleGroupIdAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyWidgetsStddevSampleGroupIdDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyWidgetsStddevSampleWidgetIdAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CompanyWidgetsStddevSampleWidgetIdDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CompanyWidgetsStddevSampleSpecOverrideAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsStddevSampleSpecOverrideDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsStddevSampleCreatedAtAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyWidgetsStddevSampleCreatedAtDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyWidgetsStddevSampleUpdatedAtAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyWidgetsStddevSampleUpdatedAtDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyWidgetsStddevPopulationGroupIdAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyWidgetsStddevPopulationGroupIdDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyWidgetsStddevPopulationWidgetIdAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CompanyWidgetsStddevPopulationWidgetIdDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CompanyWidgetsStddevPopulationSpecOverrideAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_SPEC_OVERRIDE_ASC',
  CompanyWidgetsStddevPopulationSpecOverrideDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_SPEC_OVERRIDE_DESC',
  CompanyWidgetsStddevPopulationCreatedAtAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyWidgetsStddevPopulationCreatedAtDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyWidgetsStddevPopulationUpdatedAtAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyWidgetsStddevPopulationUpdatedAtDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyWidgetsVarianceSampleGroupIdAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyWidgetsVarianceSampleGroupIdDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyWidgetsVarianceSampleWidgetIdAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CompanyWidgetsVarianceSampleWidgetIdDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CompanyWidgetsVarianceSampleSpecOverrideAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsVarianceSampleSpecOverrideDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsVarianceSampleCreatedAtAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyWidgetsVarianceSampleCreatedAtDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyWidgetsVarianceSampleUpdatedAtAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyWidgetsVarianceSampleUpdatedAtDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyWidgetsVariancePopulationGroupIdAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyWidgetsVariancePopulationGroupIdDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyWidgetsVariancePopulationWidgetIdAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CompanyWidgetsVariancePopulationWidgetIdDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CompanyWidgetsVariancePopulationSpecOverrideAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_SPEC_OVERRIDE_ASC',
  CompanyWidgetsVariancePopulationSpecOverrideDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_SPEC_OVERRIDE_DESC',
  CompanyWidgetsVariancePopulationCreatedAtAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyWidgetsVariancePopulationCreatedAtDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyWidgetsVariancePopulationUpdatedAtAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyWidgetsVariancePopulationUpdatedAtDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CtaWidgetsCountAsc = 'CTA_WIDGETS_COUNT_ASC',
  CtaWidgetsCountDesc = 'CTA_WIDGETS_COUNT_DESC',
  CtaWidgetsSumIdAsc = 'CTA_WIDGETS_SUM_ID_ASC',
  CtaWidgetsSumIdDesc = 'CTA_WIDGETS_SUM_ID_DESC',
  CtaWidgetsSumCtaIdAsc = 'CTA_WIDGETS_SUM_CTA_ID_ASC',
  CtaWidgetsSumCtaIdDesc = 'CTA_WIDGETS_SUM_CTA_ID_DESC',
  CtaWidgetsSumStaticIdAsc = 'CTA_WIDGETS_SUM_STATIC_ID_ASC',
  CtaWidgetsSumStaticIdDesc = 'CTA_WIDGETS_SUM_STATIC_ID_DESC',
  CtaWidgetsSumWidgetIdAsc = 'CTA_WIDGETS_SUM_WIDGET_ID_ASC',
  CtaWidgetsSumWidgetIdDesc = 'CTA_WIDGETS_SUM_WIDGET_ID_DESC',
  CtaWidgetsSumCreatedAtAsc = 'CTA_WIDGETS_SUM_CREATED_AT_ASC',
  CtaWidgetsSumCreatedAtDesc = 'CTA_WIDGETS_SUM_CREATED_AT_DESC',
  CtaWidgetsSumUpdatedAtAsc = 'CTA_WIDGETS_SUM_UPDATED_AT_ASC',
  CtaWidgetsSumUpdatedAtDesc = 'CTA_WIDGETS_SUM_UPDATED_AT_DESC',
  CtaWidgetsDistinctCountIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_ID_ASC',
  CtaWidgetsDistinctCountIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_ID_DESC',
  CtaWidgetsDistinctCountCtaIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_CTA_ID_ASC',
  CtaWidgetsDistinctCountCtaIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_CTA_ID_DESC',
  CtaWidgetsDistinctCountStaticIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_STATIC_ID_ASC',
  CtaWidgetsDistinctCountStaticIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_STATIC_ID_DESC',
  CtaWidgetsDistinctCountWidgetIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CtaWidgetsDistinctCountWidgetIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CtaWidgetsDistinctCountCreatedAtAsc = 'CTA_WIDGETS_DISTINCT_COUNT_CREATED_AT_ASC',
  CtaWidgetsDistinctCountCreatedAtDesc = 'CTA_WIDGETS_DISTINCT_COUNT_CREATED_AT_DESC',
  CtaWidgetsDistinctCountUpdatedAtAsc = 'CTA_WIDGETS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CtaWidgetsDistinctCountUpdatedAtDesc = 'CTA_WIDGETS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CtaWidgetsMinIdAsc = 'CTA_WIDGETS_MIN_ID_ASC',
  CtaWidgetsMinIdDesc = 'CTA_WIDGETS_MIN_ID_DESC',
  CtaWidgetsMinCtaIdAsc = 'CTA_WIDGETS_MIN_CTA_ID_ASC',
  CtaWidgetsMinCtaIdDesc = 'CTA_WIDGETS_MIN_CTA_ID_DESC',
  CtaWidgetsMinStaticIdAsc = 'CTA_WIDGETS_MIN_STATIC_ID_ASC',
  CtaWidgetsMinStaticIdDesc = 'CTA_WIDGETS_MIN_STATIC_ID_DESC',
  CtaWidgetsMinWidgetIdAsc = 'CTA_WIDGETS_MIN_WIDGET_ID_ASC',
  CtaWidgetsMinWidgetIdDesc = 'CTA_WIDGETS_MIN_WIDGET_ID_DESC',
  CtaWidgetsMinCreatedAtAsc = 'CTA_WIDGETS_MIN_CREATED_AT_ASC',
  CtaWidgetsMinCreatedAtDesc = 'CTA_WIDGETS_MIN_CREATED_AT_DESC',
  CtaWidgetsMinUpdatedAtAsc = 'CTA_WIDGETS_MIN_UPDATED_AT_ASC',
  CtaWidgetsMinUpdatedAtDesc = 'CTA_WIDGETS_MIN_UPDATED_AT_DESC',
  CtaWidgetsMaxIdAsc = 'CTA_WIDGETS_MAX_ID_ASC',
  CtaWidgetsMaxIdDesc = 'CTA_WIDGETS_MAX_ID_DESC',
  CtaWidgetsMaxCtaIdAsc = 'CTA_WIDGETS_MAX_CTA_ID_ASC',
  CtaWidgetsMaxCtaIdDesc = 'CTA_WIDGETS_MAX_CTA_ID_DESC',
  CtaWidgetsMaxStaticIdAsc = 'CTA_WIDGETS_MAX_STATIC_ID_ASC',
  CtaWidgetsMaxStaticIdDesc = 'CTA_WIDGETS_MAX_STATIC_ID_DESC',
  CtaWidgetsMaxWidgetIdAsc = 'CTA_WIDGETS_MAX_WIDGET_ID_ASC',
  CtaWidgetsMaxWidgetIdDesc = 'CTA_WIDGETS_MAX_WIDGET_ID_DESC',
  CtaWidgetsMaxCreatedAtAsc = 'CTA_WIDGETS_MAX_CREATED_AT_ASC',
  CtaWidgetsMaxCreatedAtDesc = 'CTA_WIDGETS_MAX_CREATED_AT_DESC',
  CtaWidgetsMaxUpdatedAtAsc = 'CTA_WIDGETS_MAX_UPDATED_AT_ASC',
  CtaWidgetsMaxUpdatedAtDesc = 'CTA_WIDGETS_MAX_UPDATED_AT_DESC',
  CtaWidgetsAverageIdAsc = 'CTA_WIDGETS_AVERAGE_ID_ASC',
  CtaWidgetsAverageIdDesc = 'CTA_WIDGETS_AVERAGE_ID_DESC',
  CtaWidgetsAverageCtaIdAsc = 'CTA_WIDGETS_AVERAGE_CTA_ID_ASC',
  CtaWidgetsAverageCtaIdDesc = 'CTA_WIDGETS_AVERAGE_CTA_ID_DESC',
  CtaWidgetsAverageStaticIdAsc = 'CTA_WIDGETS_AVERAGE_STATIC_ID_ASC',
  CtaWidgetsAverageStaticIdDesc = 'CTA_WIDGETS_AVERAGE_STATIC_ID_DESC',
  CtaWidgetsAverageWidgetIdAsc = 'CTA_WIDGETS_AVERAGE_WIDGET_ID_ASC',
  CtaWidgetsAverageWidgetIdDesc = 'CTA_WIDGETS_AVERAGE_WIDGET_ID_DESC',
  CtaWidgetsAverageCreatedAtAsc = 'CTA_WIDGETS_AVERAGE_CREATED_AT_ASC',
  CtaWidgetsAverageCreatedAtDesc = 'CTA_WIDGETS_AVERAGE_CREATED_AT_DESC',
  CtaWidgetsAverageUpdatedAtAsc = 'CTA_WIDGETS_AVERAGE_UPDATED_AT_ASC',
  CtaWidgetsAverageUpdatedAtDesc = 'CTA_WIDGETS_AVERAGE_UPDATED_AT_DESC',
  CtaWidgetsStddevSampleIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_ID_ASC',
  CtaWidgetsStddevSampleIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_ID_DESC',
  CtaWidgetsStddevSampleCtaIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_CTA_ID_ASC',
  CtaWidgetsStddevSampleCtaIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_CTA_ID_DESC',
  CtaWidgetsStddevSampleStaticIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_STATIC_ID_ASC',
  CtaWidgetsStddevSampleStaticIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_STATIC_ID_DESC',
  CtaWidgetsStddevSampleWidgetIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CtaWidgetsStddevSampleWidgetIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CtaWidgetsStddevSampleCreatedAtAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CtaWidgetsStddevSampleCreatedAtDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CtaWidgetsStddevSampleUpdatedAtAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CtaWidgetsStddevSampleUpdatedAtDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CtaWidgetsStddevPopulationIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_ID_ASC',
  CtaWidgetsStddevPopulationIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_ID_DESC',
  CtaWidgetsStddevPopulationCtaIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_CTA_ID_ASC',
  CtaWidgetsStddevPopulationCtaIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_CTA_ID_DESC',
  CtaWidgetsStddevPopulationStaticIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_STATIC_ID_ASC',
  CtaWidgetsStddevPopulationStaticIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_STATIC_ID_DESC',
  CtaWidgetsStddevPopulationWidgetIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CtaWidgetsStddevPopulationWidgetIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CtaWidgetsStddevPopulationCreatedAtAsc = 'CTA_WIDGETS_STDDEV_POPULATION_CREATED_AT_ASC',
  CtaWidgetsStddevPopulationCreatedAtDesc = 'CTA_WIDGETS_STDDEV_POPULATION_CREATED_AT_DESC',
  CtaWidgetsStddevPopulationUpdatedAtAsc = 'CTA_WIDGETS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CtaWidgetsStddevPopulationUpdatedAtDesc = 'CTA_WIDGETS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CtaWidgetsVarianceSampleIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_ID_ASC',
  CtaWidgetsVarianceSampleIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_ID_DESC',
  CtaWidgetsVarianceSampleCtaIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CTA_ID_ASC',
  CtaWidgetsVarianceSampleCtaIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CTA_ID_DESC',
  CtaWidgetsVarianceSampleStaticIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_STATIC_ID_ASC',
  CtaWidgetsVarianceSampleStaticIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_STATIC_ID_DESC',
  CtaWidgetsVarianceSampleWidgetIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CtaWidgetsVarianceSampleWidgetIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CtaWidgetsVarianceSampleCreatedAtAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CtaWidgetsVarianceSampleCreatedAtDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CtaWidgetsVarianceSampleUpdatedAtAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CtaWidgetsVarianceSampleUpdatedAtDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CtaWidgetsVariancePopulationIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_ID_ASC',
  CtaWidgetsVariancePopulationIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_ID_DESC',
  CtaWidgetsVariancePopulationCtaIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_CTA_ID_ASC',
  CtaWidgetsVariancePopulationCtaIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_CTA_ID_DESC',
  CtaWidgetsVariancePopulationStaticIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_STATIC_ID_ASC',
  CtaWidgetsVariancePopulationStaticIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_STATIC_ID_DESC',
  CtaWidgetsVariancePopulationWidgetIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CtaWidgetsVariancePopulationWidgetIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CtaWidgetsVariancePopulationCreatedAtAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CtaWidgetsVariancePopulationCreatedAtDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CtaWidgetsVariancePopulationUpdatedAtAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CtaWidgetsVariancePopulationUpdatedAtDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  WidgetSettingsCountAsc = 'WIDGET_SETTINGS_COUNT_ASC',
  WidgetSettingsCountDesc = 'WIDGET_SETTINGS_COUNT_DESC',
  WidgetSettingsSumIdAsc = 'WIDGET_SETTINGS_SUM_ID_ASC',
  WidgetSettingsSumIdDesc = 'WIDGET_SETTINGS_SUM_ID_DESC',
  WidgetSettingsSumWidgetIdAsc = 'WIDGET_SETTINGS_SUM_WIDGET_ID_ASC',
  WidgetSettingsSumWidgetIdDesc = 'WIDGET_SETTINGS_SUM_WIDGET_ID_DESC',
  WidgetSettingsSumTopicAsc = 'WIDGET_SETTINGS_SUM_TOPIC_ASC',
  WidgetSettingsSumTopicDesc = 'WIDGET_SETTINGS_SUM_TOPIC_DESC',
  WidgetSettingsSumFieldKeyAsc = 'WIDGET_SETTINGS_SUM_FIELD_KEY_ASC',
  WidgetSettingsSumFieldKeyDesc = 'WIDGET_SETTINGS_SUM_FIELD_KEY_DESC',
  WidgetSettingsSumValueAsc = 'WIDGET_SETTINGS_SUM_VALUE_ASC',
  WidgetSettingsSumValueDesc = 'WIDGET_SETTINGS_SUM_VALUE_DESC',
  WidgetSettingsSumCreatedAtAsc = 'WIDGET_SETTINGS_SUM_CREATED_AT_ASC',
  WidgetSettingsSumCreatedAtDesc = 'WIDGET_SETTINGS_SUM_CREATED_AT_DESC',
  WidgetSettingsSumUpdatedAtAsc = 'WIDGET_SETTINGS_SUM_UPDATED_AT_ASC',
  WidgetSettingsSumUpdatedAtDesc = 'WIDGET_SETTINGS_SUM_UPDATED_AT_DESC',
  WidgetSettingsDistinctCountIdAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_ID_ASC',
  WidgetSettingsDistinctCountIdDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_ID_DESC',
  WidgetSettingsDistinctCountWidgetIdAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_WIDGET_ID_ASC',
  WidgetSettingsDistinctCountWidgetIdDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_WIDGET_ID_DESC',
  WidgetSettingsDistinctCountTopicAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_TOPIC_ASC',
  WidgetSettingsDistinctCountTopicDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_TOPIC_DESC',
  WidgetSettingsDistinctCountFieldKeyAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_FIELD_KEY_ASC',
  WidgetSettingsDistinctCountFieldKeyDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_FIELD_KEY_DESC',
  WidgetSettingsDistinctCountValueAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_VALUE_ASC',
  WidgetSettingsDistinctCountValueDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_VALUE_DESC',
  WidgetSettingsDistinctCountCreatedAtAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_CREATED_AT_ASC',
  WidgetSettingsDistinctCountCreatedAtDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_CREATED_AT_DESC',
  WidgetSettingsDistinctCountUpdatedAtAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_UPDATED_AT_ASC',
  WidgetSettingsDistinctCountUpdatedAtDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_UPDATED_AT_DESC',
  WidgetSettingsMinIdAsc = 'WIDGET_SETTINGS_MIN_ID_ASC',
  WidgetSettingsMinIdDesc = 'WIDGET_SETTINGS_MIN_ID_DESC',
  WidgetSettingsMinWidgetIdAsc = 'WIDGET_SETTINGS_MIN_WIDGET_ID_ASC',
  WidgetSettingsMinWidgetIdDesc = 'WIDGET_SETTINGS_MIN_WIDGET_ID_DESC',
  WidgetSettingsMinTopicAsc = 'WIDGET_SETTINGS_MIN_TOPIC_ASC',
  WidgetSettingsMinTopicDesc = 'WIDGET_SETTINGS_MIN_TOPIC_DESC',
  WidgetSettingsMinFieldKeyAsc = 'WIDGET_SETTINGS_MIN_FIELD_KEY_ASC',
  WidgetSettingsMinFieldKeyDesc = 'WIDGET_SETTINGS_MIN_FIELD_KEY_DESC',
  WidgetSettingsMinValueAsc = 'WIDGET_SETTINGS_MIN_VALUE_ASC',
  WidgetSettingsMinValueDesc = 'WIDGET_SETTINGS_MIN_VALUE_DESC',
  WidgetSettingsMinCreatedAtAsc = 'WIDGET_SETTINGS_MIN_CREATED_AT_ASC',
  WidgetSettingsMinCreatedAtDesc = 'WIDGET_SETTINGS_MIN_CREATED_AT_DESC',
  WidgetSettingsMinUpdatedAtAsc = 'WIDGET_SETTINGS_MIN_UPDATED_AT_ASC',
  WidgetSettingsMinUpdatedAtDesc = 'WIDGET_SETTINGS_MIN_UPDATED_AT_DESC',
  WidgetSettingsMaxIdAsc = 'WIDGET_SETTINGS_MAX_ID_ASC',
  WidgetSettingsMaxIdDesc = 'WIDGET_SETTINGS_MAX_ID_DESC',
  WidgetSettingsMaxWidgetIdAsc = 'WIDGET_SETTINGS_MAX_WIDGET_ID_ASC',
  WidgetSettingsMaxWidgetIdDesc = 'WIDGET_SETTINGS_MAX_WIDGET_ID_DESC',
  WidgetSettingsMaxTopicAsc = 'WIDGET_SETTINGS_MAX_TOPIC_ASC',
  WidgetSettingsMaxTopicDesc = 'WIDGET_SETTINGS_MAX_TOPIC_DESC',
  WidgetSettingsMaxFieldKeyAsc = 'WIDGET_SETTINGS_MAX_FIELD_KEY_ASC',
  WidgetSettingsMaxFieldKeyDesc = 'WIDGET_SETTINGS_MAX_FIELD_KEY_DESC',
  WidgetSettingsMaxValueAsc = 'WIDGET_SETTINGS_MAX_VALUE_ASC',
  WidgetSettingsMaxValueDesc = 'WIDGET_SETTINGS_MAX_VALUE_DESC',
  WidgetSettingsMaxCreatedAtAsc = 'WIDGET_SETTINGS_MAX_CREATED_AT_ASC',
  WidgetSettingsMaxCreatedAtDesc = 'WIDGET_SETTINGS_MAX_CREATED_AT_DESC',
  WidgetSettingsMaxUpdatedAtAsc = 'WIDGET_SETTINGS_MAX_UPDATED_AT_ASC',
  WidgetSettingsMaxUpdatedAtDesc = 'WIDGET_SETTINGS_MAX_UPDATED_AT_DESC',
  WidgetSettingsAverageIdAsc = 'WIDGET_SETTINGS_AVERAGE_ID_ASC',
  WidgetSettingsAverageIdDesc = 'WIDGET_SETTINGS_AVERAGE_ID_DESC',
  WidgetSettingsAverageWidgetIdAsc = 'WIDGET_SETTINGS_AVERAGE_WIDGET_ID_ASC',
  WidgetSettingsAverageWidgetIdDesc = 'WIDGET_SETTINGS_AVERAGE_WIDGET_ID_DESC',
  WidgetSettingsAverageTopicAsc = 'WIDGET_SETTINGS_AVERAGE_TOPIC_ASC',
  WidgetSettingsAverageTopicDesc = 'WIDGET_SETTINGS_AVERAGE_TOPIC_DESC',
  WidgetSettingsAverageFieldKeyAsc = 'WIDGET_SETTINGS_AVERAGE_FIELD_KEY_ASC',
  WidgetSettingsAverageFieldKeyDesc = 'WIDGET_SETTINGS_AVERAGE_FIELD_KEY_DESC',
  WidgetSettingsAverageValueAsc = 'WIDGET_SETTINGS_AVERAGE_VALUE_ASC',
  WidgetSettingsAverageValueDesc = 'WIDGET_SETTINGS_AVERAGE_VALUE_DESC',
  WidgetSettingsAverageCreatedAtAsc = 'WIDGET_SETTINGS_AVERAGE_CREATED_AT_ASC',
  WidgetSettingsAverageCreatedAtDesc = 'WIDGET_SETTINGS_AVERAGE_CREATED_AT_DESC',
  WidgetSettingsAverageUpdatedAtAsc = 'WIDGET_SETTINGS_AVERAGE_UPDATED_AT_ASC',
  WidgetSettingsAverageUpdatedAtDesc = 'WIDGET_SETTINGS_AVERAGE_UPDATED_AT_DESC',
  WidgetSettingsStddevSampleIdAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_ID_ASC',
  WidgetSettingsStddevSampleIdDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_ID_DESC',
  WidgetSettingsStddevSampleWidgetIdAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  WidgetSettingsStddevSampleWidgetIdDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  WidgetSettingsStddevSampleTopicAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_TOPIC_ASC',
  WidgetSettingsStddevSampleTopicDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_TOPIC_DESC',
  WidgetSettingsStddevSampleFieldKeyAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_FIELD_KEY_ASC',
  WidgetSettingsStddevSampleFieldKeyDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_FIELD_KEY_DESC',
  WidgetSettingsStddevSampleValueAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_VALUE_ASC',
  WidgetSettingsStddevSampleValueDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_VALUE_DESC',
  WidgetSettingsStddevSampleCreatedAtAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_CREATED_AT_ASC',
  WidgetSettingsStddevSampleCreatedAtDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_CREATED_AT_DESC',
  WidgetSettingsStddevSampleUpdatedAtAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  WidgetSettingsStddevSampleUpdatedAtDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  WidgetSettingsStddevPopulationIdAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_ID_ASC',
  WidgetSettingsStddevPopulationIdDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_ID_DESC',
  WidgetSettingsStddevPopulationWidgetIdAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_WIDGET_ID_ASC',
  WidgetSettingsStddevPopulationWidgetIdDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_WIDGET_ID_DESC',
  WidgetSettingsStddevPopulationTopicAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_TOPIC_ASC',
  WidgetSettingsStddevPopulationTopicDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_TOPIC_DESC',
  WidgetSettingsStddevPopulationFieldKeyAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_FIELD_KEY_ASC',
  WidgetSettingsStddevPopulationFieldKeyDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_FIELD_KEY_DESC',
  WidgetSettingsStddevPopulationValueAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_VALUE_ASC',
  WidgetSettingsStddevPopulationValueDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_VALUE_DESC',
  WidgetSettingsStddevPopulationCreatedAtAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_CREATED_AT_ASC',
  WidgetSettingsStddevPopulationCreatedAtDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_CREATED_AT_DESC',
  WidgetSettingsStddevPopulationUpdatedAtAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_UPDATED_AT_ASC',
  WidgetSettingsStddevPopulationUpdatedAtDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_UPDATED_AT_DESC',
  WidgetSettingsVarianceSampleIdAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_ID_ASC',
  WidgetSettingsVarianceSampleIdDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_ID_DESC',
  WidgetSettingsVarianceSampleWidgetIdAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  WidgetSettingsVarianceSampleWidgetIdDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  WidgetSettingsVarianceSampleTopicAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_TOPIC_ASC',
  WidgetSettingsVarianceSampleTopicDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_TOPIC_DESC',
  WidgetSettingsVarianceSampleFieldKeyAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_FIELD_KEY_ASC',
  WidgetSettingsVarianceSampleFieldKeyDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_FIELD_KEY_DESC',
  WidgetSettingsVarianceSampleValueAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_VALUE_ASC',
  WidgetSettingsVarianceSampleValueDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_VALUE_DESC',
  WidgetSettingsVarianceSampleCreatedAtAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  WidgetSettingsVarianceSampleCreatedAtDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  WidgetSettingsVarianceSampleUpdatedAtAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  WidgetSettingsVarianceSampleUpdatedAtDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  WidgetSettingsVariancePopulationIdAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_ID_ASC',
  WidgetSettingsVariancePopulationIdDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_ID_DESC',
  WidgetSettingsVariancePopulationWidgetIdAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  WidgetSettingsVariancePopulationWidgetIdDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  WidgetSettingsVariancePopulationTopicAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_TOPIC_ASC',
  WidgetSettingsVariancePopulationTopicDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_TOPIC_DESC',
  WidgetSettingsVariancePopulationFieldKeyAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_FIELD_KEY_ASC',
  WidgetSettingsVariancePopulationFieldKeyDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_FIELD_KEY_DESC',
  WidgetSettingsVariancePopulationValueAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_VALUE_ASC',
  WidgetSettingsVariancePopulationValueDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_VALUE_DESC',
  WidgetSettingsVariancePopulationCreatedAtAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_CREATED_AT_ASC',
  WidgetSettingsVariancePopulationCreatedAtDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_CREATED_AT_DESC',
  WidgetSettingsVariancePopulationUpdatedAtAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  WidgetSettingsVariancePopulationUpdatedAtDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type ZohoSubscriptionsResponse = {
  code: Scalars['Int'];
  message: Scalars['String'];
  response: Scalars['String'];
};

/** An object where the defined keys will be set on the `analytic` being updated. */
export type UpdateAnalyticOnAnalyticForAnalyticsCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  referrerUrl?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<AnalyticsCompanyIdFkeyInput>;
};

/** An object where the defined keys will be set on the `appointment` being updated. */
export type UpdateAppointmentOnAppointmentForAppointmentsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

/** An object where the defined keys will be set on the `appointment` being updated. */
export type UpdateAppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

/** An object where the defined keys will be set on the `auditLog` being updated. */
export type UpdateAuditLogOnAuditLogForAuditLogLeadIdFkeyPatch = {
  eventId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  eventType?: Maybe<AuditEventType>;
  dbRole?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['JSON']>;
  newValue?: Maybe<Scalars['JSON']>;
  eventTimestamp?: Maybe<Scalars['Datetime']>;
  userToUserId?: Maybe<AuditLogUserIdFkeyInput>;
  leadToLeadId?: Maybe<AuditLogLeadIdFkeyInput>;
};

/** An object where the defined keys will be set on the `auditLog` being updated. */
export type UpdateAuditLogOnAuditLogForAuditLogUserIdFkeyPatch = {
  eventId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  eventType?: Maybe<AuditEventType>;
  dbRole?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['JSON']>;
  newValue?: Maybe<Scalars['JSON']>;
  eventTimestamp?: Maybe<Scalars['Datetime']>;
  leadId?: Maybe<Scalars['UUID']>;
  userToUserId?: Maybe<AuditLogUserIdFkeyInput>;
  leadToLeadId?: Maybe<AuditLogLeadIdFkeyInput>;
};

/** An object where the defined keys will be set on the `avaApp` being updated. */
export type UpdateAvaAppOnAvaAppForAvaAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `avaApp` being updated. */
export type UpdateAvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `channel` being updated. */
export type UpdateChannelOnCompanyChannelForCompanyChannelChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyChannelsUsingId?: Maybe<CompanyChannelChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyAnalytic` being updated. */
export type UpdateCompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<AnalyticsType>;
  analyticsId?: Maybe<Scalars['String']>;
  analyticsSecret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FkCompanyAnalyticsCompanyIdInput>;
};

/** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
export type UpdateCompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  companyChannelToCompanyChannelId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInput>;
  mediaToMediaId?: Maybe<CompanyChannelMediaMediaIdFkeyInput>;
  userToAssigneeId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
export type UpdateCompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  assigneeId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  companyChannelToCompanyChannelId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInput>;
  mediaToMediaId?: Maybe<CompanyChannelMediaMediaIdFkeyInput>;
  userToAssigneeId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyChannelMedia` being updated. */
export type UpdateCompanyChannelMediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  assigneeId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  companyChannelToCompanyChannelId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInput>;
  mediaToMediaId?: Maybe<CompanyChannelMediaMediaIdFkeyInput>;
  userToAssigneeId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyChannel` being updated. */
export type UpdateCompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyChannel` being updated. */
export type UpdateCompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyChannel` being updated. */
export type UpdateCompanyChannelOnCompanyChannelMediaForCompanyChannelMediaCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyChannel` being updated. */
export type UpdateCompanyChannelOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyChannel` being updated. */
export type UpdateCompanyChannelOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyChannel` being updated. */
export type UpdateCompanyChannelOnFieldForFieldCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyChannel` being updated. */
export type UpdateCompanyChannelOnLeadForLeadCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyChannelIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaCompanyChannelIdFkeyInverseInput>;
  distributorRelationshipsUsingId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyCta` being updated. */
export type UpdateCompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name?: Maybe<Scalars['String']>;
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef?: Maybe<Scalars['JSON']>;
  themeDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor?: Maybe<Scalars['Int']>;
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyCta` being updated. */
export type UpdateCompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name?: Maybe<Scalars['String']>;
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef?: Maybe<Scalars['JSON']>;
  themeDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor?: Maybe<Scalars['Int']>;
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyDomain` being updated. */
export type UpdateCompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyDomain` being updated. */
export type UpdateCompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyDomain` being updated. */
export type UpdateCompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
export type UpdateCompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch = {
  domainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
export type UpdateCompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyPatch = {
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
export type UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
export type UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyGroup` being updated. */
export type UpdateCompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch = {
  groupId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyGroup` being updated. */
export type UpdateCompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyPatch = {
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyIntegration` being updated. */
export type UpdateCompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyIntegration` being updated. */
export type UpdateCompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyIntegration` being updated. */
export type UpdateCompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyIntegration` being updated. */
export type UpdateCompanyIntegrationOnDealForDealsCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyIntegration` being updated. */
export type UpdateCompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyIntegration` being updated. */
export type UpdateCompanyIntegrationOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyIntegration` being updated. */
export type UpdateCompanyIntegrationOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInverseInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsCompanyIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyLocation` being updated. */
export type UpdateCompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyLocationCompanyIdFkeyInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnAnalyticForAnalyticsCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyForCompanyAccountManagerFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyForCompanyPrimaryGroupFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyForCompanySupportAgentFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyProductForCompanyProductCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyUserForCompanyUserCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnDeliveryMethodsInternalForDeliveryMethodsInternalCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnDistributedLeadForDistributedLeadsToCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnFieldForFieldCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnLeadForLeadCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnMediaForMediaCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnReviewForReviewsCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnVehicleStockForVehicleStockCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  lagoId?: Maybe<Scalars['UUID']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  accountManager?: Maybe<Scalars['Int']>;
  supportAgent?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  numberOfLocations?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  webProvider?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  userToAccountManager?: Maybe<CompanyAccountManagerFkeyInput>;
  userToSupportAgent?: Maybe<CompanySupportAgentFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
  vehicleStocksUsingId?: Maybe<VehicleStockCompanyIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserCompanyIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaCompanyIdFkeyInverseInput>;
  distributorRelationshipsToDistributorCompanyIdUsingId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInverseInput>;
  distributorRelationshipsToCompanyIdUsingId?: Maybe<DistributorRelationshipCompanyIdFkeyInverseInput>;
  distributedLeadsToFromCompanyIdUsingId?: Maybe<DistributedLeadsFromCompanyIdFkeyInverseInput>;
  distributedLeadsToToCompanyIdUsingId?: Maybe<DistributedLeadsToCompanyIdFkeyInverseInput>;
  deliveryMethodsInternalsUsingId?: Maybe<DeliveryMethodsInternalCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
export type UpdateCompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
export type UpdateCompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyProduct` being updated. */
export type UpdateCompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyPatch = {
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyProduct` being updated. */
export type UpdateCompanyProductOnCompanyProductForCompanyProductProductIdFkeyPatch = {
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyProduct` being updated. */
export type UpdateCompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyPatch = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyProduct` being updated. */
export type UpdateCompanyProductOnFieldForFieldCompanyProductIdFkeyPatch = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyUser` being updated. */
export type UpdateCompanyUserOnCompanyUserForCompanyUserCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyUserCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyUserGroupIdFkeyInput>;
  userToUserId?: Maybe<CompanyUserUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyUser` being updated. */
export type UpdateCompanyUserOnCompanyUserForCompanyUserGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyUserCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyUserGroupIdFkeyInput>;
  userToUserId?: Maybe<CompanyUserUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyUser` being updated. */
export type UpdateCompanyUserOnCompanyUserForCompanyUserUserIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyUserCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyUserGroupIdFkeyInput>;
  userToUserId?: Maybe<CompanyUserUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyWidget` being updated. */
export type UpdateCompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkPatch = {
  widgetId?: Maybe<Scalars['String']>;
  specOverride?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

/** An object where the defined keys will be set on the `companyWidget` being updated. */
export type UpdateCompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkPatch = {
  groupId?: Maybe<Scalars['Int']>;
  specOverride?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

/** An object where the defined keys will be set on the `creditBureau` being updated. */
export type UpdateCreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  /** company | lead */
  type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  avaAppToAvaAppId?: Maybe<CreditBureauAvaAppIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineBureauIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `creditBureau` being updated. */
export type UpdateCreditBureauOnTradeLineForTradeLineBureauIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  avaAppId?: Maybe<Scalars['Int']>;
  /** company | lead */
  type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  avaAppToAvaAppId?: Maybe<CreditBureauAvaAppIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineBureauIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `ctaAsset` being updated. */
export type UpdateCtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<CtaAssetsGroupIdFkeyInput>;
};

/** An object where the defined keys will be set on the `cta` being updated. */
export type UpdateCtaOnCompanyCtaForCompanyCtasCtaIdFkeyPatch = {
  id?: Maybe<Scalars['String']>;
  product?: Maybe<ProductType>;
  type?: Maybe<CtaType>;
  version?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `cta` being updated. */
export type UpdateCtaOnCtaWidgetForCtaWidgetsCtaIdFkPatch = {
  id?: Maybe<Scalars['String']>;
  product?: Maybe<ProductType>;
  type?: Maybe<CtaType>;
  version?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `ctaWidget` being updated. */
export type UpdateCtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkPatch = {
  id?: Maybe<Scalars['Int']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

/** An object where the defined keys will be set on the `ctaWidget` being updated. */
export type UpdateCtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkPatch = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

/** An object where the defined keys will be set on the `deal` being updated. */
export type UpdateDealOnDealForDealsCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  fileType?: Maybe<Scalars['String']>;
  dvDealerId?: Maybe<Scalars['String']>;
  vendorDealerId?: Maybe<Scalars['String']>;
  dmsType?: Maybe<Scalars['String']>;
  dealNumber?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  workExtension?: Maybe<Scalars['String']>;
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  email3?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  individualBusinessFlag?: Maybe<Scalars['String']>;
  optOut?: Maybe<Scalars['Boolean']>;
  blockEmail?: Maybe<Scalars['Boolean']>;
  blockPhone?: Maybe<Scalars['Boolean']>;
  blockMail?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  customerCreateDate?: Maybe<Scalars['Date']>;
  customerLastActivityDate?: Maybe<Scalars['Date']>;
  coBuyerCustomerNumber?: Maybe<Scalars['String']>;
  coBuyerFullName?: Maybe<Scalars['String']>;
  coBuyerSalutation?: Maybe<Scalars['String']>;
  coBuyerFirstName?: Maybe<Scalars['String']>;
  coBuyerMiddleName?: Maybe<Scalars['String']>;
  coBuyerLastName?: Maybe<Scalars['String']>;
  coBuyerSuffix?: Maybe<Scalars['String']>;
  coBuyerAddressLine1?: Maybe<Scalars['String']>;
  coBuyerAddressLine2?: Maybe<Scalars['String']>;
  coBuyerCity?: Maybe<Scalars['String']>;
  coBuyerState?: Maybe<Scalars['String']>;
  coBuyerZip?: Maybe<Scalars['String']>;
  coBuyerCounty?: Maybe<Scalars['String']>;
  coBuyerHomePhone?: Maybe<Scalars['String']>;
  coBuyerCellPhone?: Maybe<Scalars['String']>;
  coBuyerWorkPhone?: Maybe<Scalars['String']>;
  coBuyerWorkExtension?: Maybe<Scalars['String']>;
  coBuyerEmail1?: Maybe<Scalars['String']>;
  coBuyerEmail2?: Maybe<Scalars['String']>;
  coBuyerEmail3?: Maybe<Scalars['String']>;
  coBuyerBirthDate?: Maybe<Scalars['Date']>;
  coBuyerIndividualBusinessFlag?: Maybe<Scalars['String']>;
  coBuyerOptOut?: Maybe<Scalars['Boolean']>;
  coBuyerBlockEmail?: Maybe<Scalars['Boolean']>;
  coBuyerBlockPhone?: Maybe<Scalars['Boolean']>;
  coBuyerBlockMail?: Maybe<Scalars['Boolean']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  newUsed?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  engineConfiguration?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  engineNumber?: Maybe<Scalars['String']>;
  chassisNumber?: Maybe<Scalars['String']>;
  licensePlateNumber?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  deliveryMileage?: Maybe<Scalars['Int']>;
  inventoryDate?: Maybe<Scalars['Date']>;
  inServiceDate?: Maybe<Scalars['Date']>;
  vinExplosionYear?: Maybe<Scalars['Int']>;
  vinExplosionMake?: Maybe<Scalars['String']>;
  vinExplosionModel?: Maybe<Scalars['String']>;
  vinExplosionTrim?: Maybe<Scalars['String']>;
  vinExplosionTransmissionType?: Maybe<Scalars['String']>;
  vinExplosionFuelType?: Maybe<Scalars['String']>;
  vinExplosionEngineSize?: Maybe<Scalars['String']>;
  vinExplosionGvwRange?: Maybe<Scalars['String']>;
  trade1Vin?: Maybe<Scalars['String']>;
  trade1Year?: Maybe<Scalars['Int']>;
  trade1Make?: Maybe<Scalars['String']>;
  trade1Model?: Maybe<Scalars['String']>;
  trade1Odometer?: Maybe<Scalars['Int']>;
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  trade2Vin?: Maybe<Scalars['String']>;
  trade2Year?: Maybe<Scalars['Int']>;
  trade2Make?: Maybe<Scalars['String']>;
  trade2Model?: Maybe<Scalars['String']>;
  trade2Odometer?: Maybe<Scalars['Int']>;
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  salesman1Number?: Maybe<Scalars['String']>;
  salesman1Name?: Maybe<Scalars['String']>;
  salesman2Number?: Maybe<Scalars['String']>;
  salesman2Name?: Maybe<Scalars['String']>;
  salesman3Number?: Maybe<Scalars['String']>;
  salesman3Name?: Maybe<Scalars['String']>;
  closingManagerNumber?: Maybe<Scalars['String']>;
  closingManagerName?: Maybe<Scalars['String']>;
  financeManagerNumber?: Maybe<Scalars['String']>;
  financeManagerName?: Maybe<Scalars['String']>;
  salesmanManagerNumber?: Maybe<Scalars['String']>;
  salesmanManagerName?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['BigFloat']>;
  listPrice?: Maybe<Scalars['BigFloat']>;
  salesPrice?: Maybe<Scalars['BigFloat']>;
  journalPrice?: Maybe<Scalars['BigFloat']>;
  cost?: Maybe<Scalars['BigFloat']>;
  journalCost?: Maybe<Scalars['BigFloat']>;
  adjustments?: Maybe<Scalars['BigFloat']>;
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  incentives?: Maybe<Scalars['BigFloat']>;
  packAmount?: Maybe<Scalars['BigFloat']>;
  saleNet?: Maybe<Scalars['BigFloat']>;
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  weOweFront?: Maybe<Scalars['BigFloat']>;
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  frontGross?: Maybe<Scalars['BigFloat']>;
  financeProfit?: Maybe<Scalars['BigFloat']>;
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  weOweBack?: Maybe<Scalars['BigFloat']>;
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  financeReserve?: Maybe<Scalars['BigFloat']>;
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  backGross?: Maybe<Scalars['BigFloat']>;
  totalProfit?: Maybe<Scalars['BigFloat']>;
  grossProfit?: Maybe<Scalars['BigFloat']>;
  grossPayable?: Maybe<Scalars['BigFloat']>;
  dealStatus?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  bookedDate?: Maybe<Scalars['Date']>;
  finalizedDate?: Maybe<Scalars['Date']>;
  contractDate?: Maybe<Scalars['Date']>;
  accountingDate?: Maybe<Scalars['Date']>;
  statusChangeDate?: Maybe<Scalars['Date']>;
  firstPayDate?: Maybe<Scalars['Date']>;
  dealType?: Maybe<Scalars['String']>;
  saleType?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  apr?: Maybe<Scalars['BigFloat']>;
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  rebates?: Maybe<Scalars['BigFloat']>;
  deposit?: Maybe<Scalars['BigFloat']>;
  downPayment?: Maybe<Scalars['BigFloat']>;
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  totalDown?: Maybe<Scalars['BigFloat']>;
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  licenseFee?: Maybe<Scalars['BigFloat']>;
  registrationFee?: Maybe<Scalars['BigFloat']>;
  documentationFee?: Maybe<Scalars['BigFloat']>;
  financeCharge?: Maybe<Scalars['BigFloat']>;
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  sellRate?: Maybe<Scalars['BigFloat']>;
  buyRate?: Maybe<Scalars['BigFloat']>;
  residualRate?: Maybe<Scalars['BigFloat']>;
  residualAmount?: Maybe<Scalars['BigFloat']>;
  allowedMiles?: Maybe<Scalars['Int']>;
  estimatedMiles?: Maybe<Scalars['Int']>;
  mileageRate?: Maybe<Scalars['BigFloat']>;
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  basePayment?: Maybe<Scalars['BigFloat']>;
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  dealerFees?: Maybe<Scalars['BigFloat']>;
  governmentFees?: Maybe<Scalars['BigFloat']>;
  totalTax?: Maybe<Scalars['BigFloat']>;
  registrationState?: Maybe<Scalars['String']>;
  reportOfSaleNumber?: Maybe<Scalars['String']>;
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  warranty1Name?: Maybe<Scalars['String']>;
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  warranty1Miles?: Maybe<Scalars['Int']>;
  warranty1Term?: Maybe<Scalars['Int']>;
  warranty2Name?: Maybe<Scalars['String']>;
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  warranty2Miles?: Maybe<Scalars['Int']>;
  warranty2Term?: Maybe<Scalars['Int']>;
  warranty3Name?: Maybe<Scalars['String']>;
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  warranty3Miles?: Maybe<Scalars['Int']>;
  warranty3Term?: Maybe<Scalars['Int']>;
  warranty4Name?: Maybe<Scalars['String']>;
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  warranty4Miles?: Maybe<Scalars['Int']>;
  warranty4Term?: Maybe<Scalars['Int']>;
  warranty5Name?: Maybe<Scalars['String']>;
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  warranty5Miles?: Maybe<Scalars['Int']>;
  warranty5Term?: Maybe<Scalars['Int']>;
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Name?: Maybe<Scalars['String']>;
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket2Name?: Maybe<Scalars['String']>;
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket3Name?: Maybe<Scalars['String']>;
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket4Name?: Maybe<Scalars['String']>;
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket5Name?: Maybe<Scalars['String']>;
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket6Name?: Maybe<Scalars['String']>;
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket7Name?: Maybe<Scalars['String']>;
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket8Name?: Maybe<Scalars['String']>;
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket9Name?: Maybe<Scalars['String']>;
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket10Name?: Maybe<Scalars['String']>;
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket11Name?: Maybe<Scalars['String']>;
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket12Name?: Maybe<Scalars['String']>;
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket13Name?: Maybe<Scalars['String']>;
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket14Name?: Maybe<Scalars['String']>;
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket15Name?: Maybe<Scalars['String']>;
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket16Name?: Maybe<Scalars['String']>;
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket17Name?: Maybe<Scalars['String']>;
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket18Name?: Maybe<Scalars['String']>;
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket19Name?: Maybe<Scalars['String']>;
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket20Name?: Maybe<Scalars['String']>;
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20ProfitIndicator?: Maybe<Scalars['Boolean']>;
  insurance1Type?: Maybe<Scalars['String']>;
  insurance1Name?: Maybe<Scalars['String']>;
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  insurance1Term?: Maybe<Scalars['Int']>;
  insurance2Type?: Maybe<Scalars['String']>;
  insurance2Name?: Maybe<Scalars['String']>;
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  insurance2Term?: Maybe<Scalars['Int']>;
  insurance3Type?: Maybe<Scalars['String']>;
  insurance3Name?: Maybe<Scalars['String']>;
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  insurance3Term?: Maybe<Scalars['Int']>;
  accidentalHealthType?: Maybe<Scalars['String']>;
  accidentalHealthName?: Maybe<Scalars['String']>;
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  accidentalHealthTerm?: Maybe<Scalars['Int']>;
  creditLifeType?: Maybe<Scalars['String']>;
  creditLifeName?: Maybe<Scalars['String']>;
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  creditLifeTerm?: Maybe<Scalars['Int']>;
  levelizedLifeType?: Maybe<Scalars['String']>;
  levelizedLifeName?: Maybe<Scalars['String']>;
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  levelizedLifeTerm?: Maybe<Scalars['Int']>;
  lossOfEmploymentType?: Maybe<Scalars['String']>;
  lossOfEmploymentName?: Maybe<Scalars['String']>;
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentTerm?: Maybe<Scalars['Int']>;
  guaranteedAutoProtectionType?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionName?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionTerm?: Maybe<Scalars['Int']>;
  saleComments?: Maybe<Scalars['String']>;
  cassStdLine1?: Maybe<Scalars['String']>;
  cassStdLine2?: Maybe<Scalars['String']>;
  cassStdCity?: Maybe<Scalars['String']>;
  cassStdState?: Maybe<Scalars['String']>;
  cassStdZip?: Maybe<Scalars['String']>;
  cassStdZip4?: Maybe<Scalars['String']>;
  cassStdDpbc?: Maybe<Scalars['String']>;
  cassStdChkdgt?: Maybe<Scalars['String']>;
  cassStdCart?: Maybe<Scalars['String']>;
  cassStdLot?: Maybe<Scalars['String']>;
  cassStdLotord?: Maybe<Scalars['String']>;
  cassStdUrb?: Maybe<Scalars['String']>;
  cassStdFips?: Maybe<Scalars['String']>;
  cassStdEws?: Maybe<Scalars['String']>;
  cassStdLacs?: Maybe<Scalars['String']>;
  cassStdZipmov?: Maybe<Scalars['String']>;
  cassStdZ4Lom?: Maybe<Scalars['String']>;
  cassStdNdiapt?: Maybe<Scalars['String']>;
  cassStdNdirr?: Maybe<Scalars['String']>;
  cassStdLacsrt?: Maybe<Scalars['String']>;
  cassStdErrorCd?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<DealsLeadIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<DealsCompanyIntegrationIdFkeyInput>;
};

/** An object where the defined keys will be set on the `deal` being updated. */
export type UpdateDealOnDealForDealsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  fileType?: Maybe<Scalars['String']>;
  dvDealerId?: Maybe<Scalars['String']>;
  vendorDealerId?: Maybe<Scalars['String']>;
  dmsType?: Maybe<Scalars['String']>;
  dealNumber?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  workExtension?: Maybe<Scalars['String']>;
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  email3?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  individualBusinessFlag?: Maybe<Scalars['String']>;
  optOut?: Maybe<Scalars['Boolean']>;
  blockEmail?: Maybe<Scalars['Boolean']>;
  blockPhone?: Maybe<Scalars['Boolean']>;
  blockMail?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  customerCreateDate?: Maybe<Scalars['Date']>;
  customerLastActivityDate?: Maybe<Scalars['Date']>;
  coBuyerCustomerNumber?: Maybe<Scalars['String']>;
  coBuyerFullName?: Maybe<Scalars['String']>;
  coBuyerSalutation?: Maybe<Scalars['String']>;
  coBuyerFirstName?: Maybe<Scalars['String']>;
  coBuyerMiddleName?: Maybe<Scalars['String']>;
  coBuyerLastName?: Maybe<Scalars['String']>;
  coBuyerSuffix?: Maybe<Scalars['String']>;
  coBuyerAddressLine1?: Maybe<Scalars['String']>;
  coBuyerAddressLine2?: Maybe<Scalars['String']>;
  coBuyerCity?: Maybe<Scalars['String']>;
  coBuyerState?: Maybe<Scalars['String']>;
  coBuyerZip?: Maybe<Scalars['String']>;
  coBuyerCounty?: Maybe<Scalars['String']>;
  coBuyerHomePhone?: Maybe<Scalars['String']>;
  coBuyerCellPhone?: Maybe<Scalars['String']>;
  coBuyerWorkPhone?: Maybe<Scalars['String']>;
  coBuyerWorkExtension?: Maybe<Scalars['String']>;
  coBuyerEmail1?: Maybe<Scalars['String']>;
  coBuyerEmail2?: Maybe<Scalars['String']>;
  coBuyerEmail3?: Maybe<Scalars['String']>;
  coBuyerBirthDate?: Maybe<Scalars['Date']>;
  coBuyerIndividualBusinessFlag?: Maybe<Scalars['String']>;
  coBuyerOptOut?: Maybe<Scalars['Boolean']>;
  coBuyerBlockEmail?: Maybe<Scalars['Boolean']>;
  coBuyerBlockPhone?: Maybe<Scalars['Boolean']>;
  coBuyerBlockMail?: Maybe<Scalars['Boolean']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  newUsed?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  engineConfiguration?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  engineNumber?: Maybe<Scalars['String']>;
  chassisNumber?: Maybe<Scalars['String']>;
  licensePlateNumber?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  deliveryMileage?: Maybe<Scalars['Int']>;
  inventoryDate?: Maybe<Scalars['Date']>;
  inServiceDate?: Maybe<Scalars['Date']>;
  vinExplosionYear?: Maybe<Scalars['Int']>;
  vinExplosionMake?: Maybe<Scalars['String']>;
  vinExplosionModel?: Maybe<Scalars['String']>;
  vinExplosionTrim?: Maybe<Scalars['String']>;
  vinExplosionTransmissionType?: Maybe<Scalars['String']>;
  vinExplosionFuelType?: Maybe<Scalars['String']>;
  vinExplosionEngineSize?: Maybe<Scalars['String']>;
  vinExplosionGvwRange?: Maybe<Scalars['String']>;
  trade1Vin?: Maybe<Scalars['String']>;
  trade1Year?: Maybe<Scalars['Int']>;
  trade1Make?: Maybe<Scalars['String']>;
  trade1Model?: Maybe<Scalars['String']>;
  trade1Odometer?: Maybe<Scalars['Int']>;
  trade1ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade1Gross?: Maybe<Scalars['BigFloat']>;
  trade1Payoff?: Maybe<Scalars['BigFloat']>;
  trade2Vin?: Maybe<Scalars['String']>;
  trade2Year?: Maybe<Scalars['Int']>;
  trade2Make?: Maybe<Scalars['String']>;
  trade2Model?: Maybe<Scalars['String']>;
  trade2Odometer?: Maybe<Scalars['Int']>;
  trade2ActualCashValue?: Maybe<Scalars['BigFloat']>;
  trade2Gross?: Maybe<Scalars['BigFloat']>;
  trade2Payoff?: Maybe<Scalars['BigFloat']>;
  salesman1Number?: Maybe<Scalars['String']>;
  salesman1Name?: Maybe<Scalars['String']>;
  salesman2Number?: Maybe<Scalars['String']>;
  salesman2Name?: Maybe<Scalars['String']>;
  salesman3Number?: Maybe<Scalars['String']>;
  salesman3Name?: Maybe<Scalars['String']>;
  closingManagerNumber?: Maybe<Scalars['String']>;
  closingManagerName?: Maybe<Scalars['String']>;
  financeManagerNumber?: Maybe<Scalars['String']>;
  financeManagerName?: Maybe<Scalars['String']>;
  salesmanManagerNumber?: Maybe<Scalars['String']>;
  salesmanManagerName?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['BigFloat']>;
  listPrice?: Maybe<Scalars['BigFloat']>;
  salesPrice?: Maybe<Scalars['BigFloat']>;
  journalPrice?: Maybe<Scalars['BigFloat']>;
  cost?: Maybe<Scalars['BigFloat']>;
  journalCost?: Maybe<Scalars['BigFloat']>;
  adjustments?: Maybe<Scalars['BigFloat']>;
  adjustedCost?: Maybe<Scalars['BigFloat']>;
  incentives?: Maybe<Scalars['BigFloat']>;
  packAmount?: Maybe<Scalars['BigFloat']>;
  saleNet?: Maybe<Scalars['BigFloat']>;
  totalTradeActualCashValue?: Maybe<Scalars['BigFloat']>;
  totalTradeGross?: Maybe<Scalars['BigFloat']>;
  weOweFront?: Maybe<Scalars['BigFloat']>;
  totalFrontFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  totalFrontCommission?: Maybe<Scalars['BigFloat']>;
  totalFrontSales?: Maybe<Scalars['BigFloat']>;
  totalFrontCost?: Maybe<Scalars['BigFloat']>;
  frontGross?: Maybe<Scalars['BigFloat']>;
  financeProfit?: Maybe<Scalars['BigFloat']>;
  totalWarrantyProfit?: Maybe<Scalars['BigFloat']>;
  weOweBack?: Maybe<Scalars['BigFloat']>;
  insuranceProfit?: Maybe<Scalars['BigFloat']>;
  totalBackFeesAftermarketProfit?: Maybe<Scalars['BigFloat']>;
  financeReserve?: Maybe<Scalars['BigFloat']>;
  totalBackCommission?: Maybe<Scalars['BigFloat']>;
  totalBackSales?: Maybe<Scalars['BigFloat']>;
  totalBackCost?: Maybe<Scalars['BigFloat']>;
  backGross?: Maybe<Scalars['BigFloat']>;
  totalProfit?: Maybe<Scalars['BigFloat']>;
  grossProfit?: Maybe<Scalars['BigFloat']>;
  grossPayable?: Maybe<Scalars['BigFloat']>;
  dealStatus?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  bookedDate?: Maybe<Scalars['Date']>;
  finalizedDate?: Maybe<Scalars['Date']>;
  contractDate?: Maybe<Scalars['Date']>;
  accountingDate?: Maybe<Scalars['Date']>;
  statusChangeDate?: Maybe<Scalars['Date']>;
  firstPayDate?: Maybe<Scalars['Date']>;
  dealType?: Maybe<Scalars['String']>;
  saleType?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  amountFinanced?: Maybe<Scalars['BigFloat']>;
  apr?: Maybe<Scalars['BigFloat']>;
  monthlyPayment?: Maybe<Scalars['BigFloat']>;
  paymentTotal?: Maybe<Scalars['BigFloat']>;
  rebates?: Maybe<Scalars['BigFloat']>;
  deposit?: Maybe<Scalars['BigFloat']>;
  downPayment?: Maybe<Scalars['BigFloat']>;
  totalNetTrades?: Maybe<Scalars['BigFloat']>;
  totalDown?: Maybe<Scalars['BigFloat']>;
  balloonAmount?: Maybe<Scalars['BigFloat']>;
  adjustedBalloonAmount?: Maybe<Scalars['BigFloat']>;
  holdbackAmount?: Maybe<Scalars['BigFloat']>;
  totalDriveOffAmount?: Maybe<Scalars['BigFloat']>;
  licenseFee?: Maybe<Scalars['BigFloat']>;
  registrationFee?: Maybe<Scalars['BigFloat']>;
  documentationFee?: Maybe<Scalars['BigFloat']>;
  financeCharge?: Maybe<Scalars['BigFloat']>;
  totalPickupPayments?: Maybe<Scalars['BigFloat']>;
  sellRate?: Maybe<Scalars['BigFloat']>;
  buyRate?: Maybe<Scalars['BigFloat']>;
  residualRate?: Maybe<Scalars['BigFloat']>;
  residualAmount?: Maybe<Scalars['BigFloat']>;
  allowedMiles?: Maybe<Scalars['Int']>;
  estimatedMiles?: Maybe<Scalars['Int']>;
  mileageRate?: Maybe<Scalars['BigFloat']>;
  acquisitionFee?: Maybe<Scalars['BigFloat']>;
  basePayment?: Maybe<Scalars['BigFloat']>;
  securityDeposit?: Maybe<Scalars['BigFloat']>;
  totalCapitalReduction?: Maybe<Scalars['BigFloat']>;
  netCapitalCost?: Maybe<Scalars['BigFloat']>;
  leaseDepreciationValue?: Maybe<Scalars['BigFloat']>;
  dealerFees?: Maybe<Scalars['BigFloat']>;
  governmentFees?: Maybe<Scalars['BigFloat']>;
  totalTax?: Maybe<Scalars['BigFloat']>;
  registrationState?: Maybe<Scalars['String']>;
  reportOfSaleNumber?: Maybe<Scalars['String']>;
  salesman1TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman1FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman1BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman2TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman2FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman2BackCommission?: Maybe<Scalars['BigFloat']>;
  salesman3TotalCommission?: Maybe<Scalars['BigFloat']>;
  salesman3FrontCommission?: Maybe<Scalars['BigFloat']>;
  salesman3BackCommission?: Maybe<Scalars['BigFloat']>;
  warranty1Name?: Maybe<Scalars['String']>;
  warranty1Sale?: Maybe<Scalars['BigFloat']>;
  warranty1Cost?: Maybe<Scalars['BigFloat']>;
  warranty1Miles?: Maybe<Scalars['Int']>;
  warranty1Term?: Maybe<Scalars['Int']>;
  warranty2Name?: Maybe<Scalars['String']>;
  warranty2Sale?: Maybe<Scalars['BigFloat']>;
  warranty2Cost?: Maybe<Scalars['BigFloat']>;
  warranty2Miles?: Maybe<Scalars['Int']>;
  warranty2Term?: Maybe<Scalars['Int']>;
  warranty3Name?: Maybe<Scalars['String']>;
  warranty3Sale?: Maybe<Scalars['BigFloat']>;
  warranty3Cost?: Maybe<Scalars['BigFloat']>;
  warranty3Miles?: Maybe<Scalars['Int']>;
  warranty3Term?: Maybe<Scalars['Int']>;
  warranty4Name?: Maybe<Scalars['String']>;
  warranty4Sale?: Maybe<Scalars['BigFloat']>;
  warranty4Cost?: Maybe<Scalars['BigFloat']>;
  warranty4Miles?: Maybe<Scalars['Int']>;
  warranty4Term?: Maybe<Scalars['Int']>;
  warranty5Name?: Maybe<Scalars['String']>;
  warranty5Sale?: Maybe<Scalars['BigFloat']>;
  warranty5Cost?: Maybe<Scalars['BigFloat']>;
  warranty5Miles?: Maybe<Scalars['Int']>;
  warranty5Term?: Maybe<Scalars['Int']>;
  totalFeeAftermarketSale?: Maybe<Scalars['BigFloat']>;
  totalFeeAftermarketCost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Name?: Maybe<Scalars['String']>;
  feeAftermarket1Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket1ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket2Name?: Maybe<Scalars['String']>;
  feeAftermarket2Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket2ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket3Name?: Maybe<Scalars['String']>;
  feeAftermarket3Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket3ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket4Name?: Maybe<Scalars['String']>;
  feeAftermarket4Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket4ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket5Name?: Maybe<Scalars['String']>;
  feeAftermarket5Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket5ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket6Name?: Maybe<Scalars['String']>;
  feeAftermarket6Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket6ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket7Name?: Maybe<Scalars['String']>;
  feeAftermarket7Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket7ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket8Name?: Maybe<Scalars['String']>;
  feeAftermarket8Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket8ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket9Name?: Maybe<Scalars['String']>;
  feeAftermarket9Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket9ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket10Name?: Maybe<Scalars['String']>;
  feeAftermarket10Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket10ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket11Name?: Maybe<Scalars['String']>;
  feeAftermarket11Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket11ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket12Name?: Maybe<Scalars['String']>;
  feeAftermarket12Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket12ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket13Name?: Maybe<Scalars['String']>;
  feeAftermarket13Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket13ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket14Name?: Maybe<Scalars['String']>;
  feeAftermarket14Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket14ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket15Name?: Maybe<Scalars['String']>;
  feeAftermarket15Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket15ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket16Name?: Maybe<Scalars['String']>;
  feeAftermarket16Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket16ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket17Name?: Maybe<Scalars['String']>;
  feeAftermarket17Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket17ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket18Name?: Maybe<Scalars['String']>;
  feeAftermarket18Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket18ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket19Name?: Maybe<Scalars['String']>;
  feeAftermarket19Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket19ProfitIndicator?: Maybe<Scalars['Boolean']>;
  feeAftermarket20Name?: Maybe<Scalars['String']>;
  feeAftermarket20Sale?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20Cost?: Maybe<Scalars['BigFloat']>;
  feeAftermarket20ProfitIndicator?: Maybe<Scalars['Boolean']>;
  insurance1Type?: Maybe<Scalars['String']>;
  insurance1Name?: Maybe<Scalars['String']>;
  insurance1Sale?: Maybe<Scalars['BigFloat']>;
  insurance1Cost?: Maybe<Scalars['BigFloat']>;
  insurance1Term?: Maybe<Scalars['Int']>;
  insurance2Type?: Maybe<Scalars['String']>;
  insurance2Name?: Maybe<Scalars['String']>;
  insurance2Sale?: Maybe<Scalars['BigFloat']>;
  insurance2Cost?: Maybe<Scalars['BigFloat']>;
  insurance2Term?: Maybe<Scalars['Int']>;
  insurance3Type?: Maybe<Scalars['String']>;
  insurance3Name?: Maybe<Scalars['String']>;
  insurance3Sale?: Maybe<Scalars['BigFloat']>;
  insurance3Cost?: Maybe<Scalars['BigFloat']>;
  insurance3Term?: Maybe<Scalars['Int']>;
  accidentalHealthType?: Maybe<Scalars['String']>;
  accidentalHealthName?: Maybe<Scalars['String']>;
  accidentalHealthSale?: Maybe<Scalars['BigFloat']>;
  accidentalHealthCost?: Maybe<Scalars['BigFloat']>;
  accidentalHealthTerm?: Maybe<Scalars['Int']>;
  creditLifeType?: Maybe<Scalars['String']>;
  creditLifeName?: Maybe<Scalars['String']>;
  creditLifeSale?: Maybe<Scalars['BigFloat']>;
  creditLifeCost?: Maybe<Scalars['BigFloat']>;
  creditLifeTerm?: Maybe<Scalars['Int']>;
  levelizedLifeType?: Maybe<Scalars['String']>;
  levelizedLifeName?: Maybe<Scalars['String']>;
  levelizedLifeSale?: Maybe<Scalars['BigFloat']>;
  levelizedLifeCost?: Maybe<Scalars['BigFloat']>;
  levelizedLifeTerm?: Maybe<Scalars['Int']>;
  lossOfEmploymentType?: Maybe<Scalars['String']>;
  lossOfEmploymentName?: Maybe<Scalars['String']>;
  lossOfEmploymentSale?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentCost?: Maybe<Scalars['BigFloat']>;
  lossOfEmploymentTerm?: Maybe<Scalars['Int']>;
  guaranteedAutoProtectionType?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionName?: Maybe<Scalars['String']>;
  guaranteedAutoProtectionSale?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionCost?: Maybe<Scalars['BigFloat']>;
  guaranteedAutoProtectionTerm?: Maybe<Scalars['Int']>;
  saleComments?: Maybe<Scalars['String']>;
  cassStdLine1?: Maybe<Scalars['String']>;
  cassStdLine2?: Maybe<Scalars['String']>;
  cassStdCity?: Maybe<Scalars['String']>;
  cassStdState?: Maybe<Scalars['String']>;
  cassStdZip?: Maybe<Scalars['String']>;
  cassStdZip4?: Maybe<Scalars['String']>;
  cassStdDpbc?: Maybe<Scalars['String']>;
  cassStdChkdgt?: Maybe<Scalars['String']>;
  cassStdCart?: Maybe<Scalars['String']>;
  cassStdLot?: Maybe<Scalars['String']>;
  cassStdLotord?: Maybe<Scalars['String']>;
  cassStdUrb?: Maybe<Scalars['String']>;
  cassStdFips?: Maybe<Scalars['String']>;
  cassStdEws?: Maybe<Scalars['String']>;
  cassStdLacs?: Maybe<Scalars['String']>;
  cassStdZipmov?: Maybe<Scalars['String']>;
  cassStdZ4Lom?: Maybe<Scalars['String']>;
  cassStdNdiapt?: Maybe<Scalars['String']>;
  cassStdNdirr?: Maybe<Scalars['String']>;
  cassStdLacsrt?: Maybe<Scalars['String']>;
  cassStdErrorCd?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<DealsLeadIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<DealsCompanyIntegrationIdFkeyInput>;
};

/** An object where the defined keys will be set on the `deliveryMethod` being updated. */
export type UpdateDeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkPatch = {
  type?: Maybe<DeliveryMethodType>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  rule?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<DeliveryMethodGroupIdFkInput>;
};

/** An object where the defined keys will be set on the `distributedLead` being updated. */
export type UpdateDistributedLeadOnDistributedLeadForDistributedLeadsFromCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  toCompanyId?: Maybe<Scalars['Int']>;
  originalCompanyChannelId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<DistributedLeadsLeadIdFkeyInput>;
  companyToFromCompanyId?: Maybe<DistributedLeadsFromCompanyIdFkeyInput>;
  companyToToCompanyId?: Maybe<DistributedLeadsToCompanyIdFkeyInput>;
  companyChannelToOriginalCompanyChannelId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInput>;
};

/** An object where the defined keys will be set on the `distributedLead` being updated. */
export type UpdateDistributedLeadOnDistributedLeadForDistributedLeadsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  fromCompanyId?: Maybe<Scalars['Int']>;
  toCompanyId?: Maybe<Scalars['Int']>;
  originalCompanyChannelId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<DistributedLeadsLeadIdFkeyInput>;
  companyToFromCompanyId?: Maybe<DistributedLeadsFromCompanyIdFkeyInput>;
  companyToToCompanyId?: Maybe<DistributedLeadsToCompanyIdFkeyInput>;
  companyChannelToOriginalCompanyChannelId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInput>;
};

/** An object where the defined keys will be set on the `distributedLead` being updated. */
export type UpdateDistributedLeadOnDistributedLeadForDistributedLeadsOriginalCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  fromCompanyId?: Maybe<Scalars['Int']>;
  toCompanyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<DistributedLeadsLeadIdFkeyInput>;
  companyToFromCompanyId?: Maybe<DistributedLeadsFromCompanyIdFkeyInput>;
  companyToToCompanyId?: Maybe<DistributedLeadsToCompanyIdFkeyInput>;
  companyChannelToOriginalCompanyChannelId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInput>;
};

/** An object where the defined keys will be set on the `distributedLead` being updated. */
export type UpdateDistributedLeadOnDistributedLeadForDistributedLeadsToCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  fromCompanyId?: Maybe<Scalars['Int']>;
  originalCompanyChannelId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<DistributedLeadsLeadIdFkeyInput>;
  companyToFromCompanyId?: Maybe<DistributedLeadsFromCompanyIdFkeyInput>;
  companyToToCompanyId?: Maybe<DistributedLeadsToCompanyIdFkeyInput>;
  companyChannelToOriginalCompanyChannelId?: Maybe<DistributedLeadsOriginalCompanyChannelIdFkeyInput>;
};

/** An object where the defined keys will be set on the `distributorRelationship` being updated. */
export type UpdateDistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  distributorCompanyId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  strategy?: Maybe<DistributionStrategy>;
  maxDistanceKm?: Maybe<Scalars['Int']>;
  companyToDistributorCompanyId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInput>;
  companyToCompanyId?: Maybe<DistributorRelationshipCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInput>;
};

/** An object where the defined keys will be set on the `distributorRelationship` being updated. */
export type UpdateDistributorRelationshipOnDistributorRelationshipForDistributorRelationshipCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  distributorCompanyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  strategy?: Maybe<DistributionStrategy>;
  maxDistanceKm?: Maybe<Scalars['Int']>;
  companyToDistributorCompanyId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInput>;
  companyToCompanyId?: Maybe<DistributorRelationshipCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInput>;
};

/** An object where the defined keys will be set on the `distributorRelationship` being updated. */
export type UpdateDistributorRelationshipOnDistributorRelationshipForDistributorRelationshipDistributorCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  strategy?: Maybe<DistributionStrategy>;
  maxDistanceKm?: Maybe<Scalars['Int']>;
  companyToDistributorCompanyId?: Maybe<DistributorRelationshipDistributorCompanyIdFkeyInput>;
  companyToCompanyId?: Maybe<DistributorRelationshipCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<DistributorRelationshipCompanyChannelIdFkeyInput>;
};

/** An object where the defined keys will be set on the `equifaxLender` being updated. */
export type UpdateEquifaxLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  equifaxId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['Boolean']>;
  reviewedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  phone?: Maybe<Scalars['String']>;
  lenderToLenderId?: Maybe<EquifaxLendersLenderIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineEquifaxLenderIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `equifaxLender` being updated. */
export type UpdateEquifaxLenderOnTradeLineForTradeLineEquifaxLenderIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  equifaxId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['Boolean']>;
  reviewedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  lenderToLenderId?: Maybe<EquifaxLendersLenderIdFkeyInput>;
  tradeLinesUsingId?: Maybe<TradeLineEquifaxLenderIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `event` being updated. */
export type UpdateEventOnEventForFakePublicEventsForeignKey0Patch = {
  id?: Maybe<Scalars['Int']>;
  requestId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<FakePublicEventsForeignKey0Input>;
};

/** An object where the defined keys will be set on the `fieldConfig` being updated. */
export type UpdateFieldConfigOnFieldForFieldConfigFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  userEditable?: Maybe<Scalars['Boolean']>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `fieldConfig` being updated. */
export type UpdateFieldConfigOnOptionForOptionFieldConfigIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  userEditable?: Maybe<Scalars['Boolean']>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `fieldKey` being updated. */
export type UpdateFieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  type?: Maybe<FieldType>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsFieldKeyFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `field` being updated. */
export type UpdateFieldOnFieldForFieldCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** An object where the defined keys will be set on the `field` being updated. */
export type UpdateFieldOnFieldForFieldCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** An object where the defined keys will be set on the `field` being updated. */
export type UpdateFieldOnFieldForFieldCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** An object where the defined keys will be set on the `field` being updated. */
export type UpdateFieldOnFieldForFieldCompanyProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** An object where the defined keys will be set on the `field` being updated. */
export type UpdateFieldOnFieldForFieldConfigFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnFileForFileLenderProgramIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<FileLenderProgramIdFkeyInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCompanyCtaForCompanyCtasGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCompanyForCompanyPrimaryGroupFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCompanyGroupForCompanyGroupGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCompanyUserForCompanyUserGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCompanyWidgetForCompanyWidgetsGroupIdFkPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCtaAssetForCtaAssetsGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnDeliveryMethodForDeliveryMethodGroupIdFkPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `idInquiry` being updated. */
export type UpdateIdInquiryOnIdInquiryForIdInquiryIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  status?: Maybe<IdStatus>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawInquiry?: Maybe<Scalars['JSON']>;
  inquiryId?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IdInquiryLeadIdFkeyInput>;
  integrationToIntegrationId?: Maybe<IdInquiryIntegrationIdFkeyInput>;
  idInquiryVerificationsUsingId?: Maybe<IdInquiryVerificationInquiryIdFkeyInverseInput>;
  idSessionsUsingId?: Maybe<IdSessionInquiryIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `idInquiry` being updated. */
export type UpdateIdInquiryOnIdInquiryForIdInquiryLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawInquiry?: Maybe<Scalars['JSON']>;
  inquiryId?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IdInquiryLeadIdFkeyInput>;
  integrationToIntegrationId?: Maybe<IdInquiryIntegrationIdFkeyInput>;
  idInquiryVerificationsUsingId?: Maybe<IdInquiryVerificationInquiryIdFkeyInverseInput>;
  idSessionsUsingId?: Maybe<IdSessionInquiryIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `idInquiry` being updated. */
export type UpdateIdInquiryOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  integrationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawInquiry?: Maybe<Scalars['JSON']>;
  leadToLeadId?: Maybe<IdInquiryLeadIdFkeyInput>;
  integrationToIntegrationId?: Maybe<IdInquiryIntegrationIdFkeyInput>;
  idInquiryVerificationsUsingId?: Maybe<IdInquiryVerificationInquiryIdFkeyInverseInput>;
  idSessionsUsingId?: Maybe<IdSessionInquiryIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `idInquiry` being updated. */
export type UpdateIdInquiryOnIdSessionForIdSessionInquiryIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  integrationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawInquiry?: Maybe<Scalars['JSON']>;
  leadToLeadId?: Maybe<IdInquiryLeadIdFkeyInput>;
  integrationToIntegrationId?: Maybe<IdInquiryIntegrationIdFkeyInput>;
  idInquiryVerificationsUsingId?: Maybe<IdInquiryVerificationInquiryIdFkeyInverseInput>;
  idSessionsUsingId?: Maybe<IdSessionInquiryIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `idInquiryVerificationCheck` being updated. */
export type UpdateIdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  required?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationToVerificationId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInput>;
  verificationCheckToVerificationCheckId?: Maybe<IdInquiryVerificationCheckVerificationCheckIdFkeyInput>;
};

/** An object where the defined keys will be set on the `idInquiryVerificationCheck` being updated. */
export type UpdateIdInquiryVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  required?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  verificationCheckId?: Maybe<Scalars['Int']>;
  idInquiryVerificationToVerificationId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInput>;
  verificationCheckToVerificationCheckId?: Maybe<IdInquiryVerificationCheckVerificationCheckIdFkeyInput>;
};

/** An object where the defined keys will be set on the `idInquiryVerification` being updated. */
export type UpdateIdInquiryVerificationOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  inquiryId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  issueAuthority?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdInquiryVerificationInquiryIdFkeyInput>;
  idMediaUsingId?: Maybe<IdMediaVerificationIdFkeyInverseInput>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `idInquiryVerification` being updated. */
export type UpdateIdInquiryVerificationOnIdInquiryVerificationForIdInquiryVerificationInquiryIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  issueAuthority?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdInquiryVerificationInquiryIdFkeyInput>;
  idMediaUsingId?: Maybe<IdMediaVerificationIdFkeyInverseInput>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `idInquiryVerification` being updated. */
export type UpdateIdInquiryVerificationOnIdMediaForIdMediaVerificationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  inquiryId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<IdStatus>;
  reasons?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  issueAuthority?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdInquiryVerificationInquiryIdFkeyInput>;
  idMediaUsingId?: Maybe<IdMediaVerificationIdFkeyInverseInput>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `idMedia` being updated. */
export type UpdateIdMediaOnIdMediaForIdMediaMediaIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationToVerificationId?: Maybe<IdMediaVerificationIdFkeyInput>;
  mediaToMediaId?: Maybe<IdMediaMediaIdFkeyInput>;
};

/** An object where the defined keys will be set on the `idMedia` being updated. */
export type UpdateIdMediaOnIdMediaForIdMediaVerificationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationToVerificationId?: Maybe<IdMediaVerificationIdFkeyInput>;
  mediaToMediaId?: Maybe<IdMediaMediaIdFkeyInput>;
};

/** An object where the defined keys will be set on the `idSession` being updated. */
export type UpdateIdSessionOnIdSessionForIdSessionInquiryIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  threat?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  maps?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Scalars['String']>>>;
  startedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idSessionId?: Maybe<Scalars['String']>;
  idInquiryToInquiryId?: Maybe<IdSessionInquiryIdFkeyInput>;
};

/** An object where the defined keys will be set on the `idVerification` being updated. */
export type UpdateIdVerificationOnIdVerificationForIdVerificationLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
  verificationUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  firstStep?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['JSON']>;
  nextStep?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IdVerificationLeadIdFkeyInput>;
};

/** An object where the defined keys will be set on the `incomeVerification` being updated. */
export type UpdateIncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  loginToken?: Maybe<Scalars['String']>;
  directDepositAccountId?: Maybe<Scalars['String']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  rawAccounts?: Maybe<Scalars['JSON']>;
  report?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  verifiedAt?: Maybe<Scalars['Datetime']>;
  provider?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IncomeVerificationLeadIdFkeyInput>;
};

/** An object where the defined keys will be set on the `integration` being updated. */
export type UpdateIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `integration` being updated. */
export type UpdateIntegrationOnIdInquiryForIdInquiryIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `integration` being updated. */
export type UpdateIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `leadCampaign` being updated. */
export type UpdateLeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadCampaignsLeadIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnAppointmentForAppointmentsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnAuditLogForAuditLogLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnAvaAppForAvaAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnDealForDealsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnDistributedLeadForDistributedLeadsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnEventForFakePublicEventsForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnIdInquiryForIdInquiryLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnIdVerificationForIdVerificationLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLeadCampaignForLeadCampaignsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLeadForLeadCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLeadForLeadCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLeadForLeadNeighborhoodViewIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLeadJourneyForLeadJourneyLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLeadRoleForLeadRoleLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLenderAppForLenderAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLenderQuoteForLenderQuotesLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnTradeAppForTradeAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  neighborhoodViewId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  incomePeriod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  incomeAmount?: Maybe<Scalars['BigFloat']>;
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  smsConsent?: Maybe<Scalars['Boolean']>;
  incomeTimeYears?: Maybe<Scalars['Int']>;
  incomeTimeMonths?: Maybe<Scalars['Int']>;
  residenceTimeYears?: Maybe<Scalars['Int']>;
  residenceTimeMonths?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<LeadCompanyChannelIdFkeyInput>;
  neighborhoodViewToNeighborhoodViewId?: Maybe<LeadNeighborhoodViewIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleLeadIdFkeyInverseInput>;
  distributedLeadsUsingId?: Maybe<DistributedLeadsLeadIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogLeadIdFkeyInverseInput>;
  idInquiriesUsingId?: Maybe<IdInquiryLeadIdFkeyInverseInput>;
  dealsUsingId?: Maybe<DealsLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `leadRole` being updated. */
export type UpdateLeadRoleOnLeadRoleForLeadRoleLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadRoleLeadIdFkeyInput>;
  userToUserId?: Maybe<LeadRoleUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `leadRole` being updated. */
export type UpdateLeadRoleOnLeadRoleForLeadRoleUserIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  role?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadRoleLeadIdFkeyInput>;
  userToUserId?: Maybe<LeadRoleUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lenderApp` being updated. */
export type UpdateLenderAppOnLenderAppForLenderAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome?: Maybe<Scalars['Int']>;
  employmentStatus?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderApp` being updated. */
export type UpdateLenderAppOnLenderAppForLenderAppVehicleListingIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome?: Maybe<Scalars['Int']>;
  employmentStatus?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderApp` being updated. */
export type UpdateLenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome?: Maybe<Scalars['Int']>;
  employmentStatus?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
export type UpdateLenderCreditKeyOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  minScore?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInput>;
  ratesUsingId?: Maybe<RateLenderCreditKeyIdFkeyInverseInput>;
  lenderProgramBonusesUsingId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInverseInput>;
  maxAmortizationsUsingId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
export type UpdateLenderCreditKeyOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  minScore?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInput>;
  ratesUsingId?: Maybe<RateLenderCreditKeyIdFkeyInverseInput>;
  lenderProgramBonusesUsingId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInverseInput>;
  maxAmortizationsUsingId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
export type UpdateLenderCreditKeyOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  minScore?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInput>;
  ratesUsingId?: Maybe<RateLenderCreditKeyIdFkeyInverseInput>;
  lenderProgramBonusesUsingId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInverseInput>;
  maxAmortizationsUsingId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderCreditKey` being updated. */
export type UpdateLenderCreditKeyOnRateForRateLenderCreditKeyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderProgramId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  minScore?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInput>;
  ratesUsingId?: Maybe<RateLenderCreditKeyIdFkeyInverseInput>;
  lenderProgramBonusesUsingId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInverseInput>;
  maxAmortizationsUsingId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lender` being updated. */
export type UpdateLenderOnEquifaxLenderForEquifaxLendersLenderIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  bio?: Maybe<Scalars['String']>;
  equifaxLendersUsingId?: Maybe<EquifaxLendersLenderIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderIdFkeyInverseInput>;
  lenderProgramsUsingId?: Maybe<LenderProgramLenderIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lender` being updated. */
export type UpdateLenderOnLenderProgramForLenderProgramLenderIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  bio?: Maybe<Scalars['String']>;
  equifaxLendersUsingId?: Maybe<EquifaxLendersLenderIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderIdFkeyInverseInput>;
  lenderProgramsUsingId?: Maybe<LenderProgramLenderIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lender` being updated. */
export type UpdateLenderOnLenderQuoteForLenderQuotesLenderIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  bio?: Maybe<Scalars['String']>;
  equifaxLendersUsingId?: Maybe<EquifaxLendersLenderIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderIdFkeyInverseInput>;
  lenderProgramsUsingId?: Maybe<LenderProgramLenderIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderProgramBonus` being updated. */
export type UpdateLenderProgramBonusOnLenderProgramBonusForLenderProgramBonusLenderCreditKeyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  creditQuality?: Maybe<Scalars['String']>;
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  bonus?: Maybe<Scalars['BigFloat']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<LenderProgramBonusLenderCreditKeyIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lenderProgram` being updated. */
export type UpdateLenderProgramOnFileForFileLenderProgramIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  applicationRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  vehicleRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  minDate?: Maybe<Scalars['Datetime']>;
  maxDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderToLenderId?: Maybe<LenderProgramLenderIdFkeyInput>;
  lenderCreditKeysUsingId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInverseInput>;
  vehicleBookingsUsingId?: Maybe<VehicleBookingLenderProgramIdFkeyInverseInput>;
  filesUsingId?: Maybe<FileLenderProgramIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderProgram` being updated. */
export type UpdateLenderProgramOnLenderCreditKeyForLenderCreditKeyLenderProgramIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  applicationRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  vehicleRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  minDate?: Maybe<Scalars['Datetime']>;
  maxDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderToLenderId?: Maybe<LenderProgramLenderIdFkeyInput>;
  lenderCreditKeysUsingId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInverseInput>;
  vehicleBookingsUsingId?: Maybe<VehicleBookingLenderProgramIdFkeyInverseInput>;
  filesUsingId?: Maybe<FileLenderProgramIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderProgram` being updated. */
export type UpdateLenderProgramOnLenderProgramForLenderProgramLenderIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  applicationRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  vehicleRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  minDate?: Maybe<Scalars['Datetime']>;
  maxDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderToLenderId?: Maybe<LenderProgramLenderIdFkeyInput>;
  lenderCreditKeysUsingId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInverseInput>;
  vehicleBookingsUsingId?: Maybe<VehicleBookingLenderProgramIdFkeyInverseInput>;
  filesUsingId?: Maybe<FileLenderProgramIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderProgram` being updated. */
export type UpdateLenderProgramOnVehicleBookingForVehicleBookingLenderProgramIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  applicationRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  vehicleRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  minDate?: Maybe<Scalars['Datetime']>;
  maxDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderToLenderId?: Maybe<LenderProgramLenderIdFkeyInput>;
  lenderCreditKeysUsingId?: Maybe<LenderCreditKeyLenderProgramIdFkeyInverseInput>;
  vehicleBookingsUsingId?: Maybe<VehicleBookingLenderProgramIdFkeyInverseInput>;
  filesUsingId?: Maybe<FileLenderProgramIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderQuote` being updated. */
export type UpdateLenderQuoteOnLenderQuoteForLenderQuotesCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  lenderId?: Maybe<Scalars['Int']>;
  stips?: Maybe<Scalars['JSON']>;
  applicationLink?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
  lenderToLenderId?: Maybe<LenderQuotesLenderIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lenderQuote` being updated. */
export type UpdateLenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  lenderId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  stips?: Maybe<Scalars['JSON']>;
  applicationLink?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
  lenderToLenderId?: Maybe<LenderQuotesLenderIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lenderQuote` being updated. */
export type UpdateLenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  lenderId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  stips?: Maybe<Scalars['JSON']>;
  applicationLink?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
  lenderToLenderId?: Maybe<LenderQuotesLenderIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lenderQuote` being updated. */
export type UpdateLenderQuoteOnLenderQuoteForLenderQuotesLenderIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  stips?: Maybe<Scalars['JSON']>;
  applicationLink?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
  lenderToLenderId?: Maybe<LenderQuotesLenderIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<LenderQuotesCompanyIntegrationIdFkeyInput>;
};

/** An object where the defined keys will be set on the `maxAmortization` being updated. */
export type UpdateMaxAmortizationOnMaxAmortizationForMaxAmortizationLenderCreditKeyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  personalCoApplicant?: Maybe<Scalars['Boolean']>;
  term?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<MaxAmortizationLenderCreditKeyIdFkeyInput>;
};

/** An object where the defined keys will be set on the `media` being updated. */
export type UpdateMediaOnCompanyChannelMediaForCompanyChannelMediaMediaIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uploader?: Maybe<Scalars['Int']>;
  s3Key?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  contentType?: Maybe<Scalars['String']>;
  uploadCompletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  fileName?: Maybe<Scalars['String']>;
  companyToCompanyId?: Maybe<MediaCompanyIdFkeyInput>;
  userToUploader?: Maybe<MediaUploaderFkeyInput>;
  usersUsingId?: Maybe<UserPictureIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaMediaIdFkeyInverseInput>;
  idMediaUsingId?: Maybe<IdMediaMediaIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `media` being updated. */
export type UpdateMediaOnIdMediaForIdMediaMediaIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uploader?: Maybe<Scalars['Int']>;
  s3Key?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  contentType?: Maybe<Scalars['String']>;
  uploadCompletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  fileName?: Maybe<Scalars['String']>;
  companyToCompanyId?: Maybe<MediaCompanyIdFkeyInput>;
  userToUploader?: Maybe<MediaUploaderFkeyInput>;
  usersUsingId?: Maybe<UserPictureIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaMediaIdFkeyInverseInput>;
  idMediaUsingId?: Maybe<IdMediaMediaIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `media` being updated. */
export type UpdateMediaOnMediaForMediaCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uploader?: Maybe<Scalars['Int']>;
  s3Key?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  contentType?: Maybe<Scalars['String']>;
  uploadCompletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  fileName?: Maybe<Scalars['String']>;
  companyToCompanyId?: Maybe<MediaCompanyIdFkeyInput>;
  userToUploader?: Maybe<MediaUploaderFkeyInput>;
  usersUsingId?: Maybe<UserPictureIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaMediaIdFkeyInverseInput>;
  idMediaUsingId?: Maybe<IdMediaMediaIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `media` being updated. */
export type UpdateMediaOnMediaForMediaUploaderFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  contentType?: Maybe<Scalars['String']>;
  uploadCompletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  fileName?: Maybe<Scalars['String']>;
  companyToCompanyId?: Maybe<MediaCompanyIdFkeyInput>;
  userToUploader?: Maybe<MediaUploaderFkeyInput>;
  usersUsingId?: Maybe<UserPictureIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaMediaIdFkeyInverseInput>;
  idMediaUsingId?: Maybe<IdMediaMediaIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `media` being updated. */
export type UpdateMediaOnUserForUserPictureIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uploader?: Maybe<Scalars['Int']>;
  s3Key?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  parentType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  contentType?: Maybe<Scalars['String']>;
  uploadCompletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  fileName?: Maybe<Scalars['String']>;
  companyToCompanyId?: Maybe<MediaCompanyIdFkeyInput>;
  userToUploader?: Maybe<MediaUploaderFkeyInput>;
  usersUsingId?: Maybe<UserPictureIdFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaMediaIdFkeyInverseInput>;
  idMediaUsingId?: Maybe<IdMediaMediaIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `neighborhoodView` being updated. */
export type UpdateNeighborhoodViewOnLeadForLeadNeighborhoodViewIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  equifaxRiskScore?: Maybe<Scalars['Float']>;
  consumerRiskPredictor?: Maybe<Scalars['Float']>;
  rawJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadsUsingId?: Maybe<LeadNeighborhoodViewIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `option` being updated. */
export type UpdateOptionOnOptionForOptionFieldConfigIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  display?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  fieldConfigToFieldConfigId?: Maybe<OptionFieldConfigIdFkeyInput>;
};

/** An object where the defined keys will be set on the `productIntegration` being updated. */
export type UpdateProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

/** An object where the defined keys will be set on the `productIntegration` being updated. */
export type UpdateProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnCompanyProductForCompanyProductProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<ProductType>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyProductsUsingId?: Maybe<CompanyProductProductIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyProductIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnLeadJourneyForLeadJourneyProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<ProductType>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyProductsUsingId?: Maybe<CompanyProductProductIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyProductIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnProductIntegrationForProductIntegrationProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<ProductType>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyProductsUsingId?: Maybe<CompanyProductProductIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyProductIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `rate` being updated. */
export type UpdateRateOnRateForRateLenderCreditKeyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  minTerm?: Maybe<Scalars['Int']>;
  maxTerm?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['BigFloat']>;
  usedCarAdvanceRate?: Maybe<Scalars['Int']>;
  newCarAdvanceRate?: Maybe<Scalars['Int']>;
  addOn?: Maybe<Scalars['Int']>;
  lenderAdminFee?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<RateLenderCreditKeyIdFkeyInput>;
  reservesUsingId?: Maybe<ReserveRateIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `rate` being updated. */
export type UpdateRateOnReserveForReserveRateIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderCreditKeyId?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  minTerm?: Maybe<Scalars['Int']>;
  maxTerm?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['BigFloat']>;
  usedCarAdvanceRate?: Maybe<Scalars['Int']>;
  newCarAdvanceRate?: Maybe<Scalars['Int']>;
  addOn?: Maybe<Scalars['Int']>;
  lenderAdminFee?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderCreditKeyToLenderCreditKeyId?: Maybe<RateLenderCreditKeyIdFkeyInput>;
  reservesUsingId?: Maybe<ReserveRateIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `reserve` being updated. */
export type UpdateReserveOnReserveForReserveRateIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  creditQuality?: Maybe<Scalars['String']>;
  reserve?: Maybe<Scalars['BigFloat']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rateToRateId?: Maybe<ReserveRateIdFkeyInput>;
};

/** An object where the defined keys will be set on the `review` being updated. */
export type UpdateReviewOnReviewForReviewsCompanyIdFkeyPatch = {
  provider?: Maybe<ReviewProvider>;
  authorName?: Maybe<Scalars['String']>;
  authorPhotoUrl?: Maybe<Scalars['String']>;
  authorUrl?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<ReviewsCompanyIdFkeyInput>;
};

/** An object where the defined keys will be set on the `tradeApp` being updated. */
export type UpdateTradeAppOnTradeAppForTradeAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  condition?: Maybe<VehicleCondition>;
  tradeInValue?: Maybe<Scalars['Float']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<TradeAppLeadIdFkeyInput>;
};

/** An object where the defined keys will be set on the `tradeLine` being updated. */
export type UpdateTradeLineOnTradeLineForTradeLineBureauIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  equifaxLenderId?: Maybe<Scalars['Int']>;
  tradeDate?: Maybe<Scalars['String']>;
  creditorId?: Maybe<Scalars['String']>;
  creditorName?: Maybe<Scalars['String']>;
  creditorPhone?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  association?: Maybe<Scalars['String']>;
  updateSource?: Maybe<Scalars['String']>;
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  paymentTerm?: Maybe<Scalars['String']>;
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  dateOpened?: Maybe<Scalars['String']>;
  dateLastActivityOrPayment?: Maybe<Scalars['String']>;
  portfolioTypeDescription?: Maybe<Scalars['String']>;
  portfolioTypeCode?: Maybe<Scalars['String']>;
  paymentRateDescription?: Maybe<Scalars['String']>;
  paymentRateCode?: Maybe<Scalars['String']>;
  past30?: Maybe<Scalars['BigFloat']>;
  past60?: Maybe<Scalars['BigFloat']>;
  past90?: Maybe<Scalars['BigFloat']>;
  lastSlowPayment?: Maybe<Scalars['String']>;
  narratives?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditBureauToBureauId?: Maybe<TradeLineBureauIdFkeyInput>;
  equifaxLenderToEquifaxLenderId?: Maybe<TradeLineEquifaxLenderIdFkeyInput>;
};

/** An object where the defined keys will be set on the `tradeLine` being updated. */
export type UpdateTradeLineOnTradeLineForTradeLineEquifaxLenderIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  bureauId?: Maybe<Scalars['Int']>;
  tradeDate?: Maybe<Scalars['String']>;
  creditorId?: Maybe<Scalars['String']>;
  creditorName?: Maybe<Scalars['String']>;
  creditorPhone?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  association?: Maybe<Scalars['String']>;
  updateSource?: Maybe<Scalars['String']>;
  highCreditAmount?: Maybe<Scalars['BigFloat']>;
  balanceAmount?: Maybe<Scalars['BigFloat']>;
  paymentTerm?: Maybe<Scalars['String']>;
  paymentTermAmount?: Maybe<Scalars['BigFloat']>;
  pastDueAmount?: Maybe<Scalars['BigFloat']>;
  monthsReviewed?: Maybe<Scalars['BigFloat']>;
  dateOpened?: Maybe<Scalars['String']>;
  dateLastActivityOrPayment?: Maybe<Scalars['String']>;
  portfolioTypeDescription?: Maybe<Scalars['String']>;
  portfolioTypeCode?: Maybe<Scalars['String']>;
  paymentRateDescription?: Maybe<Scalars['String']>;
  paymentRateCode?: Maybe<Scalars['String']>;
  past30?: Maybe<Scalars['BigFloat']>;
  past60?: Maybe<Scalars['BigFloat']>;
  past90?: Maybe<Scalars['BigFloat']>;
  lastSlowPayment?: Maybe<Scalars['String']>;
  narratives?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditBureauToBureauId?: Maybe<TradeLineBureauIdFkeyInput>;
  equifaxLenderToEquifaxLenderId?: Maybe<TradeLineEquifaxLenderIdFkeyInput>;
};

/** An object where the defined keys will be set on the `userActivity` being updated. */
export type UpdateUserActivityOnUserActivityForFkUserActivityUserIdPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  claims?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  userToUserId?: Maybe<FkUserActivityUserIdInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnAuditLogForAuditLogUserIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pictureId?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['Datetime']>;
  selectedCompanyId?: Maybe<Scalars['Int']>;
  intercomId?: Maybe<Scalars['String']>;
  mediaToPictureId?: Maybe<UserPictureIdFkeyInput>;
  companiesToAccountManagerUsingId?: Maybe<CompanyAccountManagerFkeyInverseInput>;
  companiesToSupportAgentUsingId?: Maybe<CompanySupportAgentFkeyInverseInput>;
  userActivitiesUsingId?: Maybe<FkUserActivityUserIdInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleUserIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserUserIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaUploaderFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnCompanyChannelMediaForCompanyChannelMediaAssigneeIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pictureId?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['Datetime']>;
  selectedCompanyId?: Maybe<Scalars['Int']>;
  intercomId?: Maybe<Scalars['String']>;
  mediaToPictureId?: Maybe<UserPictureIdFkeyInput>;
  companiesToAccountManagerUsingId?: Maybe<CompanyAccountManagerFkeyInverseInput>;
  companiesToSupportAgentUsingId?: Maybe<CompanySupportAgentFkeyInverseInput>;
  userActivitiesUsingId?: Maybe<FkUserActivityUserIdInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleUserIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserUserIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaUploaderFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnCompanyForCompanyAccountManagerFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pictureId?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['Datetime']>;
  selectedCompanyId?: Maybe<Scalars['Int']>;
  intercomId?: Maybe<Scalars['String']>;
  mediaToPictureId?: Maybe<UserPictureIdFkeyInput>;
  companiesToAccountManagerUsingId?: Maybe<CompanyAccountManagerFkeyInverseInput>;
  companiesToSupportAgentUsingId?: Maybe<CompanySupportAgentFkeyInverseInput>;
  userActivitiesUsingId?: Maybe<FkUserActivityUserIdInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleUserIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserUserIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaUploaderFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnCompanyForCompanySupportAgentFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pictureId?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['Datetime']>;
  selectedCompanyId?: Maybe<Scalars['Int']>;
  intercomId?: Maybe<Scalars['String']>;
  mediaToPictureId?: Maybe<UserPictureIdFkeyInput>;
  companiesToAccountManagerUsingId?: Maybe<CompanyAccountManagerFkeyInverseInput>;
  companiesToSupportAgentUsingId?: Maybe<CompanySupportAgentFkeyInverseInput>;
  userActivitiesUsingId?: Maybe<FkUserActivityUserIdInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleUserIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserUserIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaUploaderFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnCompanyUserForCompanyUserUserIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pictureId?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['Datetime']>;
  selectedCompanyId?: Maybe<Scalars['Int']>;
  intercomId?: Maybe<Scalars['String']>;
  mediaToPictureId?: Maybe<UserPictureIdFkeyInput>;
  companiesToAccountManagerUsingId?: Maybe<CompanyAccountManagerFkeyInverseInput>;
  companiesToSupportAgentUsingId?: Maybe<CompanySupportAgentFkeyInverseInput>;
  userActivitiesUsingId?: Maybe<FkUserActivityUserIdInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleUserIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserUserIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaUploaderFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnLeadRoleForLeadRoleUserIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pictureId?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['Datetime']>;
  selectedCompanyId?: Maybe<Scalars['Int']>;
  intercomId?: Maybe<Scalars['String']>;
  mediaToPictureId?: Maybe<UserPictureIdFkeyInput>;
  companiesToAccountManagerUsingId?: Maybe<CompanyAccountManagerFkeyInverseInput>;
  companiesToSupportAgentUsingId?: Maybe<CompanySupportAgentFkeyInverseInput>;
  userActivitiesUsingId?: Maybe<FkUserActivityUserIdInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleUserIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserUserIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaUploaderFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnMediaForMediaUploaderFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pictureId?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['Datetime']>;
  selectedCompanyId?: Maybe<Scalars['Int']>;
  intercomId?: Maybe<Scalars['String']>;
  mediaToPictureId?: Maybe<UserPictureIdFkeyInput>;
  companiesToAccountManagerUsingId?: Maybe<CompanyAccountManagerFkeyInverseInput>;
  companiesToSupportAgentUsingId?: Maybe<CompanySupportAgentFkeyInverseInput>;
  userActivitiesUsingId?: Maybe<FkUserActivityUserIdInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleUserIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserUserIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaUploaderFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnUserActivityForFkUserActivityUserIdPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pictureId?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['Datetime']>;
  selectedCompanyId?: Maybe<Scalars['Int']>;
  intercomId?: Maybe<Scalars['String']>;
  mediaToPictureId?: Maybe<UserPictureIdFkeyInput>;
  companiesToAccountManagerUsingId?: Maybe<CompanyAccountManagerFkeyInverseInput>;
  companiesToSupportAgentUsingId?: Maybe<CompanySupportAgentFkeyInverseInput>;
  userActivitiesUsingId?: Maybe<FkUserActivityUserIdInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleUserIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserUserIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaUploaderFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnUserForUserPictureIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['Datetime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  confirmed?: Maybe<Scalars['Datetime']>;
  selectedCompanyId?: Maybe<Scalars['Int']>;
  intercomId?: Maybe<Scalars['String']>;
  mediaToPictureId?: Maybe<UserPictureIdFkeyInput>;
  companiesToAccountManagerUsingId?: Maybe<CompanyAccountManagerFkeyInverseInput>;
  companiesToSupportAgentUsingId?: Maybe<CompanySupportAgentFkeyInverseInput>;
  userActivitiesUsingId?: Maybe<FkUserActivityUserIdInverseInput>;
  leadRolesUsingId?: Maybe<LeadRoleUserIdFkeyInverseInput>;
  companyUsersUsingId?: Maybe<CompanyUserUserIdFkeyInverseInput>;
  mediaUsingId?: Maybe<MediaUploaderFkeyInverseInput>;
  companyChannelMediaUsingId?: Maybe<CompanyChannelMediaAssigneeIdFkeyInverseInput>;
  auditLogsUsingId?: Maybe<AuditLogUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `vehicleBooking` being updated. */
export type UpdateVehicleBookingOnVehicleBookingForVehicleBookingLenderProgramIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  minKm?: Maybe<Scalars['Int']>;
  maxKm?: Maybe<Scalars['Int']>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  isNew?: Maybe<Scalars['Boolean']>;
  cbbKey?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  lenderProgramToLenderProgramId?: Maybe<VehicleBookingLenderProgramIdFkeyInput>;
};

/** An object where the defined keys will be set on the `vehicleListing` being updated. */
export type UpdateVehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `vehicleListing` being updated. */
export type UpdateVehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `vehicleStock` being updated. */
export type UpdateVehicleStockOnVehicleStockForVehicleStockCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  bodyStyle?: Maybe<Scalars['String']>;
  exteriorColour?: Maybe<Scalars['String']>;
  interiorColour?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  doors?: Maybe<Scalars['Int']>;
  seats?: Maybe<Scalars['Int']>;
  engineCapacity?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  cylinders?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  warrantyText?: Maybe<Scalars['String']>;
  carProof?: Maybe<Scalars['String']>;
  isNew?: Maybe<Scalars['Boolean']>;
  certified?: Maybe<Scalars['String']>;
  special?: Maybe<Scalars['String']>;
  driveTrain?: Maybe<Scalars['String']>;
  fuelType?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  features?: Maybe<Scalars['JSON']>;
  stockStatus?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  entryDate?: Maybe<Scalars['Datetime']>;
  lastChangeDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  companyToCompanyId?: Maybe<VehicleStockCompanyIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInput>;
};

/** An object where the defined keys will be set on the `vehicleStock` being updated. */
export type UpdateVehicleStockOnVehicleStockForVehicleStockCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  bodyStyle?: Maybe<Scalars['String']>;
  exteriorColour?: Maybe<Scalars['String']>;
  interiorColour?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  doors?: Maybe<Scalars['Int']>;
  seats?: Maybe<Scalars['Int']>;
  engineCapacity?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  cylinders?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  warrantyText?: Maybe<Scalars['String']>;
  carProof?: Maybe<Scalars['String']>;
  isNew?: Maybe<Scalars['Boolean']>;
  certified?: Maybe<Scalars['String']>;
  special?: Maybe<Scalars['String']>;
  driveTrain?: Maybe<Scalars['String']>;
  fuelType?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  features?: Maybe<Scalars['JSON']>;
  stockStatus?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  entryDate?: Maybe<Scalars['Datetime']>;
  lastChangeDate?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  companyToCompanyId?: Maybe<VehicleStockCompanyIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<VehicleStockCompanyIntegrationIdFkeyInput>;
};

/** An object where the defined keys will be set on the `verificationCheck` being updated. */
export type UpdateVerificationCheckOnIdInquiryVerificationCheckForIdInquiryVerificationCheckVerificationCheckIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  idInquiryVerificationChecksUsingId?: Maybe<IdInquiryVerificationCheckVerificationCheckIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `widget` being updated. */
export type UpdateWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkPatch = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `widget` being updated. */
export type UpdateWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkPatch = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `widget` being updated. */
export type UpdateWidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyPatch = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `widgetSetting` being updated. */
export type UpdateWidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

/** An object where the defined keys will be set on the `widgetSetting` being updated. */
export type UpdateWidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

export type GetCompanyChannelByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCompanyChannelByIdQuery = { companyChannel?: Maybe<{ companyId?: Maybe<number>, displayName: string, enabled: boolean, createdAt: any, channel?: Maybe<{ createdAt: any, name: string, displayName: string, enabled: boolean }>, company?: Maybe<{ name: string }>, fields: { nodes: Array<{ companyProductId?: Maybe<number>, config?: Maybe<number>, createdAt: any, id: number, nodeId: string, topic?: Maybe<string>, updatedAt: any, value: string, companyChannelId?: Maybe<number>, companyId?: Maybe<number>, companyIntegrationId?: Maybe<number>, fieldConfigByConfig?: Maybe<{ id: number, key: string, label: string, public: boolean, type: FieldType }> }> } }> };

export type SendNotificationMutationVariables = Exact<{
  leadId: Scalars['UUID'];
}>;


export type SendNotificationMutation = { triggerLeadNotification: { leadId: any, success: boolean } };

export type RequestVerificationMutationVariables = Exact<{
  leadId: Scalars['UUID'];
  type: VerificationChannel;
}>;


export type RequestVerificationMutation = { requestVerification?: Maybe<{ success: boolean, leadId: string, phoneVerifiedAt?: Maybe<string> }> };

export type ConfirmVerificationCodeMutationVariables = Exact<{
  leadId: Scalars['UUID'];
  type: VerificationChannel;
  code: Scalars['String'];
}>;


export type ConfirmVerificationCodeMutation = { confirmVerificationCode?: Maybe<{ success: boolean, leadId: string, phoneVerifiedAt?: Maybe<string> }> };

export type UpdateLeadMetaMutationVariables = Exact<{
  leadId: Scalars['UUID'];
  companyId: Scalars['Int'];
  meta: Scalars['JSON'];
}>;


export type UpdateLeadMetaMutation = { updateLeadMeta?: Maybe<{ leadId: any, meta?: Maybe<any> }> };

export type GetLeadInfoQueryVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type GetLeadInfoQuery = { getLeadInfo?: Maybe<{ id: any, firstName?: Maybe<string>, lastName?: Maybe<string>, dateOfBirth?: Maybe<string>, phoneNumber?: Maybe<string>, emailAddress?: Maybe<string>, postalCode?: Maybe<string>, provinceCode?: Maybe<string>, streetNumber?: Maybe<string>, street?: Maybe<string>, city?: Maybe<string>, additionalInformation?: Maybe<any>, employmentStatus?: Maybe<string>, incomeAmount?: Maybe<number>, incomePeriod?: Maybe<string>, company?: Maybe<{ id?: Maybe<number>, name?: Maybe<string> }> }> };

export type GetWidgetDetailsQueryVariables = Exact<{
  widget: Scalars['String'];
  ctaId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
}>;


export type GetWidgetDetailsQuery = { getWidgetDetails: { company: { id: number, name: string, widgetId: string, archived: boolean, products: Array<ProductType> }, group: { id: number, name: string, legalDesc?: Maybe<string>, distributionType: GroupDistType }, memberCompanies: Array<{ id: number, name: string, widgetId: string, archived: boolean, products: Array<ProductType> }>, fields: Array<{ product?: Maybe<string>, integration?: Maybe<string>, channel?: Maybe<string>, topic?: Maybe<string>, key?: Maybe<string>, value?: Maybe<string> }>, productIntegrations: Array<{ product?: Maybe<string>, integrations?: Maybe<Array<string>> }> } };

export type AnalyticsTrackingMutationVariables = Exact<{
  input: AnalyticsParamsInput;
}>;


export type AnalyticsTrackingMutation = { trackAnalytics: { success: boolean, eventType?: Maybe<string>, analyticsPayload?: Maybe<Array<any>> } };

export type AnswerMutationVariables = Exact<{
  input: CreditQueryInput;
}>;


export type AnswerMutation = { creditQuery?: Maybe<{ transactionKey?: Maybe<string>, equifaxServerError?: Maybe<boolean>, equifaxErrorMessages?: Maybe<Array<Maybe<string>>>, fraudCheckFailed?: Maybe<boolean>, interactiveQuery?: Maybe<{ queryId?: Maybe<string>, questions?: Maybe<Array<{ questionId: string, questionText: string, answers?: Maybe<Array<{ answerId: string, value: string }>> }>> }>, report?: Maybe<{ score?: Maybe<{ value?: Maybe<number> }>, trades: Array<{ dateOpened?: Maybe<string>, balanceAmount?: Maybe<number>, paymentTermAmount?: Maybe<number>, association?: Maybe<string>, highCreditAmount?: Maybe<number>, dateLastActivityOrPayment?: Maybe<string>, monthsReviewed?: Maybe<string>, paymentTerm?: Maybe<string>, lastSlowPayment?: Maybe<string>, creditor?: Maybe<{ name?: Maybe<string> }>, portfolioType?: Maybe<{ code?: Maybe<string>, description?: Maybe<string> }>, paymentRate?: Maybe<{ code?: Maybe<string>, description?: Maybe<string> }>, derogatoryCounters?: Maybe<{ past30: number, past60: number, past90: number }> }>, inquiries: Array<{ date?: Maybe<string>, inquirer?: Maybe<{ id?: Maybe<string>, name?: Maybe<string>, phone?: Maybe<string> }> }> }> }> };

export type AuthMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: CreditAuthInput;
}>;


export type AuthMutation = { creditAuth?: Maybe<{ transactionKey?: Maybe<string>, equifaxServerError?: Maybe<boolean>, equifaxErrorMessages?: Maybe<Array<Maybe<string>>>, fraudCheckFailed?: Maybe<boolean>, consumer: string, interactiveQuery?: Maybe<{ queryId?: Maybe<string>, questions?: Maybe<Array<{ questionId: string, questionText: string, answers?: Maybe<Array<{ answerId: string, value: string }>> }>> }> }> };

export type SubmitLoanAppMutationVariables = Exact<{
  input: LoanAppInput;
}>;


export type SubmitLoanAppMutation = { loanApp?: Maybe<{ leadId?: Maybe<any>, msg?: Maybe<string>, result?: Maybe<string> }> };

export type SubmitPartialLeadMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: LeadInput;
  product: ProductType;
}>;


export type SubmitPartialLeadMutation = { submitLead?: Maybe<{ leadId: string, success: boolean }> };

export type FetchNeighborhoodViewMutationVariables = Exact<{
  leadId: Scalars['UUID'];
}>;


export type FetchNeighborhoodViewMutation = { fetchNeighborhoodView?: Maybe<{ postalCode: string, equifaxRiskScore: number, street?: Maybe<string> }> };

export type SubmitDriveLeadMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: LeadInput;
  product: ProductType;
}>;


export type SubmitDriveLeadMutation = { submitLead?: Maybe<{ leadId: string, success: boolean }> };

export type GetLenderAnswerMutationVariables = Exact<{
  input: CreditQueryInput;
}>;


export type GetLenderAnswerMutation = { creditQuery?: Maybe<{ transactionKey?: Maybe<string>, equifaxServerError?: Maybe<boolean>, equifaxErrorMessages?: Maybe<Array<Maybe<string>>>, fraudCheckFailed?: Maybe<boolean>, interactiveQuery?: Maybe<{ queryId?: Maybe<string>, questions?: Maybe<Array<{ questionId: string, questionText: string, answers?: Maybe<Array<{ answerId: string, value: string }>> }>> }>, report?: Maybe<{ score?: Maybe<{ value?: Maybe<number> }>, trades: Array<{ dateOpened?: Maybe<string>, balanceAmount?: Maybe<number>, paymentTermAmount?: Maybe<number>, association?: Maybe<string>, highCreditAmount?: Maybe<number>, dateLastActivityOrPayment?: Maybe<string>, monthsReviewed?: Maybe<string>, paymentTerm?: Maybe<string>, lastSlowPayment?: Maybe<string>, creditor?: Maybe<{ name?: Maybe<string> }>, portfolioType?: Maybe<{ code?: Maybe<string>, description?: Maybe<string> }>, paymentRate?: Maybe<{ code?: Maybe<string>, description?: Maybe<string> }>, derogatoryCounters?: Maybe<{ past30: number, past60: number, past90: number }> }>, inquiries: Array<{ date?: Maybe<string>, inquirer?: Maybe<{ id?: Maybe<string>, name?: Maybe<string>, phone?: Maybe<string> }> }> }> }> };

export type FetchLenderQuotesMutationVariables = Exact<{
  input: FetchLenderQuotesInput;
}>;


export type FetchLenderQuotesMutation = { fetchLenderQuotes?: Maybe<Array<Maybe<{ id: number, type: LenderQuoteType, provider?: Maybe<string>, quoteJson?: Maybe<any>, createdAt: any, updatedAt: any, lenderApp?: Maybe<{ id: number, yearlyIncome: number, employmentStatus: string, monthlyHomePayment?: Maybe<number>, createdAt: any, updatedAt: any, vehicleListing?: Maybe<{ id: number, listingMsrp?: Maybe<any>, listingPrice?: Maybe<any>, listingUrl?: Maybe<string>, listingImageUrl?: Maybe<string>, listingTitle?: Maybe<string>, vehicleVin?: Maybe<string>, vehicleYear?: Maybe<number>, vehicleMake?: Maybe<string>, vehicleModel?: Maybe<string>, vehicleTrim?: Maybe<string>, vehicleMileage?: Maybe<number>, vehicleCondition?: Maybe<string>, createdAt: any, updatedAt: any, vehicleEngine?: Maybe<string>, vehicleTransmission?: Maybe<string>, vehicleExteriorColor?: Maybe<string>, vehicleInteriorColor?: Maybe<string>, vehicleStatus?: Maybe<string>, vehicleStockNumber?: Maybe<string> }> }> }>>> };

export type LenderAuthMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: CreditAuthInput;
}>;


export type LenderAuthMutation = { creditAuth?: Maybe<{ transactionKey?: Maybe<string>, equifaxServerError?: Maybe<boolean>, equifaxErrorMessages?: Maybe<Array<Maybe<string>>>, fraudCheckFailed?: Maybe<boolean>, consumer: string, interactiveQuery?: Maybe<{ queryId?: Maybe<string>, questions?: Maybe<Array<{ questionId: string, questionText: string, answers?: Maybe<Array<{ answerId: string, value: string }>> }>> }> }> };

export type SetPreferredLenderOfferMutationVariables = Exact<{
  input: SetPreferredLenderOfferInput;
}>;


export type SetPreferredLenderOfferMutation = { setPreferredLenderOffer?: Maybe<{ preferredOfferId?: Maybe<number>, offers?: Maybe<Array<Maybe<{ offerId: number, averageRate?: Maybe<number>, estimatedBiweeklyPayment?: Maybe<number>, estimatedMonthlyPayment?: Maybe<number>, estimatedWeeklyPayment?: Maybe<number>, maxAmountFinanced?: Maybe<number>, maxLtvPassFail?: Maybe<number>, maxLtv?: Maybe<number>, maxPayment?: Maybe<number>, maxPti?: Maybe<number>, maxPaymentPassFail?: Maybe<number>, maxPtiPassFail?: Maybe<number>, maxTdsr?: Maybe<number>, maxTerm?: Maybe<number>, maxTdsrPassFail?: Maybe<number>, lenderId?: Maybe<string>, name?: Maybe<string> }>>> }> };

export type SubmitLenderPartialMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: LeadInput;
  product: ProductType;
}>;


export type SubmitLenderPartialMutation = { submitLead?: Maybe<{ leadId: string, success: boolean }> };

export type SubmitMsaLeadMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: LeadInput;
  product: ProductType;
}>;


export type SubmitMsaLeadMutation = { submitLead?: Maybe<{ leadId: string, success: boolean }> };

export type NotifyUserBySmsMutationVariables = Exact<{
  input: SmsUserNotificationInput;
}>;


export type NotifyUserBySmsMutation = { notifyUserBySms?: Maybe<{ success: boolean, from?: Maybe<string>, to?: Maybe<string>, smsBody?: Maybe<string>, error?: Maybe<string> }> };

export type SubmitTradeAppLeadMutationVariables = Exact<{
  input: TradeAppCustomInput;
  widgetId: Scalars['String'];
}>;


export type SubmitTradeAppLeadMutation = { createTradeAppLead?: Maybe<{ leadId?: Maybe<any>, tradeApp?: Maybe<{ id?: Maybe<number> }>, error?: Maybe<{ code?: Maybe<TradeAppErrorCode>, message: string }> }> };

export type GetVehiclePricesQueryVariables = Exact<{
  vehicleId: Scalars['String'];
  condition: TradeAppVehicleCondition;
  mileage: Scalars['Int'];
  provider?: Maybe<TradeProvider>;
}>;


export type GetVehiclePricesQuery = { tradeAppGetPrices: { __typename: 'TradeAppError', code?: Maybe<TradeAppErrorCode>, message: string } | { __typename: 'TradeAppTradeValue', fairPurchasePrice?: Maybe<number>, priceLow?: Maybe<number>, baseDiscountedPrice?: Maybe<number>, priceAdvisor?: Maybe<string>, priceHigh?: Maybe<number> } };

export type GetTradeInValueQueryVariables = Exact<{
  vehicleId: Scalars['String'];
  condition: TradeAppVehicleCondition;
  mileage: Scalars['Int'];
  location?: Maybe<TradeAppLocation>;
}>;


export type GetTradeInValueQuery = { tradeAppGetTradeValue?: Maybe<{ priceLow?: Maybe<number>, priceHigh?: Maybe<number>, fairPurchasePrice?: Maybe<number>, baseDiscountedPrice?: Maybe<number>, priceAdvisor?: Maybe<string> }> };

export type VehicleLookupQueryVariables = Exact<{
  widgetId: Scalars['String'];
  keyword: Scalars['String'];
}>;


export type VehicleLookupQuery = { vehicleLookup: { results?: Maybe<Array<Maybe<{ description?: Maybe<string>, make?: Maybe<string>, model?: Maybe<string>, series?: Maybe<string>, style?: Maybe<string>, vehicleId?: Maybe<string>, year?: Maybe<string> }>>> } };

export type GetAllModelsQueryVariables = Exact<{
  makeId: Scalars['Int'];
  year?: Maybe<Scalars['Int']>;
}>;


export type GetAllModelsQuery = { tradeAppGetAllModels?: Maybe<Array<Maybe<{ modelId?: Maybe<number>, modelName?: Maybe<string> }>>> };

export type GetAllTrimsQueryVariables = Exact<{
  modelId: Scalars['String'];
  year: Scalars['Int'];
}>;


export type GetAllTrimsQuery = { tradeAppGetAllTrims?: Maybe<Array<Maybe<{ vehicleId?: Maybe<string>, trimName?: Maybe<string> }>>> };

export type GetAllYearsMakesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllYearsMakesQuery = { tradeAppGetSupportedMakes: Array<{ makeIds: Array<number>, modelYear: number }> };

export type SearchModelsTrimsQueryVariables = Exact<{
  yearMake: Scalars['String'];
}>;


export type SearchModelsTrimsQuery = { tradeAppSearch?: Maybe<{ total?: Maybe<number>, results?: Maybe<Array<Maybe<{ trim?: Maybe<string>, vehicleId?: Maybe<string>, modelId?: Maybe<number>, model?: Maybe<string>, makeId?: Maybe<number>, msrp?: Maybe<number> }>>> }> };
